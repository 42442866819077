import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { StructurePictureAreaUtils } from '../../classes/EntityManager/entities/StructurePictureArea/StructurePictureAreaUtils';
import { EditStructurePictureAreaPathDialog } from '../edit-structure-picture-area-path-dialog/edit-structure-picture-area-path-dialog';

/**
 * @event {StructurePictureAreaPathInputPathChangedEvent} path-changed
 */
@autoinject()
export class StructurePictureAreaPathInput {
  @bindable()
  public path: Array<string> = [];

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public stackedStyle: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly i18n: I18N
  ) {}

  @computedFrom('path', 'path.length')
  protected get inputText(): string {
    const pathText = StructurePictureAreaUtils.formatPath(this.path);

    if (pathText) {
      return pathText;
    }

    return this.i18n.tr(
      'structurePictureAreaComponents.structurePictureaAreaPathInput.fallbackInputText'
    );
  }

  protected handleInputClicked(): void {
    this.openPathEditorIfEnabled();
  }

  protected handleEditPathClicked(): void {
    this.openPathEditorIfEnabled();
  }

  protected openPathEditorIfEnabled(): void {
    if (!this.enabled) {
      return;
    }

    void EditStructurePictureAreaPathDialog.open({
      onNewPath: (path) => {
        this.path = path;

        setTimeout(() => {
          DomEventHelper.fireEvent<StructurePictureAreaPathInputPathChangedEvent>(
            this.element,
            {
              name: 'path-changed',
              detail: {
                path
              }
            }
          );
        });
      }
    });
  }
}

export type StructurePictureAreaPathInputPathChangedEvent = NamedCustomEvent<
  'path-changed',
  { path: Array<string> }
>;
