import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationFormDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  @subscribableLifecycle()
  protected readonly formPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationForm];

  private form: ProcessConfigurationForm | null = null;
  private onDialogClosed: (() => void) | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.formPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationForm,
        context: this,
        expression: 'form'
      });
  }

  public open(options: OpenOptions): void {
    this.form = options.processConfigurationForm;
    this.onDialogClosed = options.onDialogClosed;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    this.form = null;

    this.onDialogClosed?.();
    this.onDialogClosed = null;
  }

  protected handleFormChanged(): void {
    assertNotNullOrUndefined(
      this.form,
      "can't EditProcessConfigurationFormDialog.handleFormChanged without form"
    );
    this.entityManager.processConfigurationFormRepository.update(this.form);
  }
}

export type OpenOptions = {
  processConfigurationForm: ProcessConfigurationForm;
  onDialogClosed: (() => void) | null;
};
/**
 * @typedef {Object} TOpenOptions
 * @property {import('../../classes/EntityManager/entities/ProcessConfigurationForm/types').ProcessConfigurationForm} processConfigurationForm
 * @property {function|null} [onDialogClosed]
 */
