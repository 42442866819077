import { autoinject, bindable } from 'aurelia-framework';

import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Vector } from '../../../../common/src/Geometry/Vector';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-thing-group-clicked - fired when the edit button has been clicked
 * @event enter-thing-group-clicked - fired when the enter button has been clicked
 */
@autoinject()
export class ThingGroupListItem {
  @bindable()
  public thingGroup: ThingGroup | null = null;

  @bindable()
  public editable: boolean = false;

  private panelOpened: boolean = false;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  protected readonly ThingGroupHelper = ThingGroupHelper;

  protected listItemElement: HTMLElement | null = null;
  protected moduleNamesTooltipContentTargetAlignment = new Vector(0.5, 0.5);
  protected readonly clientCoordinates =
    CoordinateHelper.getClientCoordinates();

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.ThingGroup,
        context: this as ThingGroupListItem,
        propertyName: 'thingGroup'
      }
    );
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't ThingGroupListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleDeleteThingGroupClick(): void {
    const thingGroup = this.thingGroup;
    if (!thingGroup) return;

    const things = this.entityManager.thingRepository.getByThingGroupId(
      thingGroup.id
    );
    const textTk = things.length
      ? 'listItems.thingGroupListItem.deleteDialogWithThingsText'
      : 'listItems.thingGroupListItem.deleteDialogText';
    const textTkParams = {
      entityName: thingGroup.name || EntityName.ThingGroup,
      thingCount: things.length
    };

    void Dialogs.deleteDialogTk(textTk, textTkParams).then(() => {
      things.forEach((thing) => {
        thing.thingGroupId = null;
        this.entityManager.thingRepository.update(thing);
      });

      this.entityManager.thingGroupRepository.delete(thingGroup);
    });
  }

  protected handleEditThingGroupClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-thing-group-clicked',
      detail: null
    });
  }

  protected handleEnterThingGroupClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'enter-thing-group-clicked',
      detail: null
    });
  }

  protected handleUpdateThingGroupCoordinatesClick(
    thingGroup: ThingGroup
  ): void {
    if (this.clientCoordinates?.latitude == null) {
      NotificationHelper.notifyDanger('Position konnte nicht bestimmt werden!');
      return;
    }

    thingGroup.latitude = this.clientCoordinates.latitude;
    thingGroup.longitude = this.clientCoordinates.longitude;

    this.entityManager.thingGroupRepository.update(thingGroup);
  }

  @computed(
    expression('thingGroup.latitude'),
    expression('thingGroup.latitude'),
    expression('clientCoordinates.latitude'),
    expression('clientCoordinates.longitude')
  )
  protected get distance(): string | null {
    if (
      this.thingGroup?.latitude == null ||
      this.thingGroup.longitude == null ||
      this.clientCoordinates.latitude == null ||
      this.clientCoordinates.longitude == null
    ) {
      return null;
    }

    return CoordinateHelper.calculateFormattedDistance(
      this.thingGroup.latitude,
      this.thingGroup.longitude,
      this.clientCoordinates.latitude,
      this.clientCoordinates.longitude
    );
  }
}
