import { Vector } from 'common/Geometry/Vector';
import {
  OriginatedFromPictureInfo,
  TPictureAdditionalMarking
} from 'common/Types/Entities/Picture/PictureDto';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';

export class PictureMarkingsCalculator {
  public getPictureMarkings({
    picture,
    relativeMarkingPosition,
    relativeBoxTopLeft,
    relativeBoxBottomRight
  }: GetPictureMarkingsOptions): GetPictureMarkingsResult {
    const pictureMarking = this.getAdditionalMarking({
      parentPictureId: picture.id,
      relativeMarkingPosition,
      relativeBoxBottomRight,
      relativeBoxTopLeft,
      viewPort: this.getFullMarkingTargetViewPort()
    });

    const originatedFromPictureMarking = picture.originatedFromPictureInfo
      ? this.getAdditionalMarking({
          parentPictureId: picture.originatedFromPictureInfo.pictureId,
          relativeMarkingPosition,
          relativeBoxBottomRight,
          relativeBoxTopLeft,
          viewPort: this.getMarkingTargetViewPortForOriginatedFrom({
            originatedFrom: picture.originatedFromPictureInfo
          })
        })
      : null;

    return {
      pictureMarking,
      originatedFromPictureMarking
    };
  }

  private getAdditionalMarking({
    parentPictureId,
    relativeMarkingPosition,
    relativeBoxTopLeft,
    relativeBoxBottomRight,
    viewPort
  }: GetAdditionalMarkingOptions): TPictureAdditionalMarking {
    const { percentMarkingPosition, percentBoxTopLeft, percentBoxBottomRight } =
      this.getPercentPositionsForMarkingTargetViewPort({
        relativeMarkingPosition,
        relativeBoxBottomRight,
        relativeBoxTopLeft,
        viewPort
      });

    return {
      picture_id: parentPictureId,
      left: `${percentMarkingPosition.getX()}%`,
      top: `${percentMarkingPosition.getY()}%`,
      boxTop: percentBoxTopLeft.getY(),
      boxLeft: percentBoxTopLeft.getX(),
      boxBottom: percentBoxBottomRight.getY(),
      boxRight: percentBoxBottomRight.getX()
    };
  }

  private getFullMarkingTargetViewPort(): MarkingTargetViewPort {
    return {
      topLeft: new Vector(0, 0),
      bottomRight: new Vector(1, 1)
    };
  }

  private getMarkingTargetViewPortForOriginatedFrom({
    originatedFrom
  }: {
    originatedFrom: OriginatedFromPictureInfo;
  }): MarkingTargetViewPort {
    return {
      topLeft: new Vector(
        originatedFrom.topLeft.x,
        originatedFrom.topLeft.y
      ).scale(1 / 100),
      bottomRight: new Vector(
        originatedFrom.bottomRight.x,
        originatedFrom.bottomRight.y
      ).scale(1 / 100)
    };
  }

  private getPercentPositionsForMarkingTargetViewPort({
    viewPort,
    relativeMarkingPosition,
    relativeBoxTopLeft,
    relativeBoxBottomRight
  }: {
    viewPort: MarkingTargetViewPort;
    relativeMarkingPosition: Vector;
    relativeBoxTopLeft: Vector;
    relativeBoxBottomRight: Vector;
  }): {
    percentMarkingPosition: Vector;
    percentBoxTopLeft: Vector;
    percentBoxBottomRight: Vector;
  } {
    const viewPortRelativeMarkingPosition =
      this.getRelativePositionBasedOnViewPort({
        viewPort,
        relativePosition: relativeMarkingPosition
      });

    const viewPortRelativeBoxTopLeft = this.getRelativePositionBasedOnViewPort({
      viewPort,
      relativePosition: relativeBoxTopLeft
    });

    const viewPortRelativeBoxBottomRight =
      this.getRelativePositionBasedOnViewPort({
        viewPort,
        relativePosition: relativeBoxBottomRight
      });

    return {
      percentMarkingPosition: viewPortRelativeMarkingPosition
        .clone()
        .scale(100),
      percentBoxTopLeft: viewPortRelativeBoxTopLeft.clone().scale(100),
      percentBoxBottomRight: viewPortRelativeBoxBottomRight.clone().scale(100)
    };
  }

  private getRelativePositionBasedOnViewPort({
    viewPort,
    relativePosition
  }: {
    viewPort: MarkingTargetViewPort;
    relativePosition: Vector;
  }): Vector {
    const relativeViewPortSize = viewPort.bottomRight
      .clone()
      .substractVector(viewPort.topLeft);

    return viewPort.topLeft
      .clone()
      .addVector(relativePosition.clone().multiplyVector(relativeViewPortSize));
  }
}

export type GetPictureMarkingsOptions = {
  /**
   * picture that is being marked on
   */
  picture: Picture;
  relativeMarkingPosition: Vector;
  relativeBoxTopLeft: Vector;
  relativeBoxBottomRight: Vector;
};

export type GetPictureMarkingsResult = {
  pictureMarking: TPictureAdditionalMarking;
  originatedFromPictureMarking: TPictureAdditionalMarking | null;
};

type GetAdditionalMarkingOptions = {
  parentPictureId: string;
  relativeMarkingPosition: Vector;
  relativeBoxTopLeft: Vector;
  relativeBoxBottomRight: Vector;
  viewPort: MarkingTargetViewPort;
};

/**
 * This describes the ViewPort the currently displayed picture is on the marking target.
 * This is necessary because the marking should also be created for the picture where the marked picture is derived from (e.g. cutouts of a plan).
 *
 * If the marking is directly on the displayed picture, topLeft should be 0,0 and bottomRight 1,1.
 */
type MarkingTargetViewPort = {
  /**
   * relative, 0 - 1
   */
  topLeft: Vector;
  /**
   * relative, 0 - 1
   */
  bottomRight: Vector;
};
