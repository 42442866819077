import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';

@autoinject()
export class UserDefinedEntityConfigListItem {
  @bindable public userDefinedEntityConfig: UserDefinedEntityConfig | null =
    null;

  @bindable public editable = false;

  private domElement: HTMLElement;

  private listItemElement: HTMLElement | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    element: Element
  ) {
    this.domElement = element as HTMLElement;
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't UserDefinedEntityConfigListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleEditUserDefinedEntityConfigClick(): void {
    DomEventHelper.fireEvent<EditUserDefinedEntityConfigEvent>(
      this.domElement,
      {
        name: 'edit-user-defined-entity-config-clicked',
        detail: null
      }
    );
  }

  protected handleDeleteUserDefinedEntityConfig(): void {
    const entity = this.userDefinedEntityConfig;
    assertNotNullOrUndefined(
      entity,
      "can't UserDefinedEntityConfigListItem.handleDeleteUserDefinedEntityConfig without an entity"
    );

    void Dialogs.deleteEntityDialog(entity).then(() => {
      this.entityManager.userDefinedEntityConfigRepository.delete(entity);
    });
  }

  protected getUserGroupNameById(usergroupId: string): string {
    const userGroup =
      this.entityManager.userGroupRepository.getById(usergroupId);
    return userGroup?.name ? userGroup.name : '';
  }
}

export type EditUserDefinedEntityConfigEvent = NamedCustomEvent<
  'edit-user-defined-entity-config-clicked',
  null
>;
