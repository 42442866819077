import { Key } from '../Key';

export class KeyboardFocusDetector {
  /** @type {boolean} */
  _tabIsPressed = false;

  /** @type {string} */
  _keyboardFocusClassName = 'keyboard-focus';

  constructor() {
    this._boundHandleKeyDown = this._handleKeyDown.bind(this);
    this._boundHandleKeyUp = this._handleKeyUp.bind(this);
    this._boundHandleFocusin = this._handleFocusin.bind(this);
    this._boundHandleFocusout = this._handleFocusout.bind(this);
    this._boundHandleMousedown = this._handleMousedown.bind(this);
  }

  init() {
    document.body.addEventListener('keydown', this._boundHandleKeyDown);
    document.body.addEventListener('keyup', this._boundHandleKeyUp);
    document.body.addEventListener('focusin', this._boundHandleFocusin);
    document.body.addEventListener('focusout', this._boundHandleFocusout);
    document.body.addEventListener('mousedown', this._boundHandleMousedown);
  }

  destroy() {
    document.body.removeEventListener('keydown', this._boundHandleKeyDown);
    document.body.removeEventListener('keyup', this._boundHandleKeyUp);
    document.body.removeEventListener('focusin', this._boundHandleFocusin);
    document.body.removeEventListener('focusout', this._boundHandleFocusout);
    document.body.removeEventListener('mousedown', this._boundHandleMousedown);
  }

  /**
   * @param {KeyboardEvent} event
   */
  _handleKeyDown(event) {
    if (event.key === Key.TAB) {
      this._tabIsPressed = true;
    }
  }

  /**
   * @param {KeyboardEvent} event
   */
  _handleKeyUp(event) {
    if (event.key === Key.TAB) {
      this._tabIsPressed = false;
    }
  }

  /**
   * @param {Event} event
   */
  _handleFocusin(event) {
    if (event.target && this._tabIsPressed) {
      /** @type {HTMLElement} */ (event.target).classList.add(
        this._keyboardFocusClassName
      );
    }
  }

  /**
   * @param {Event} event
   */
  _handleFocusout(event) {
    if (event.target) {
      /** @type {HTMLElement} */ (event.target).classList.remove(
        this._keyboardFocusClassName
      );
    }
  }

  /**
   * @param {MouseEvent} event
   */
  _handleMousedown(event) {
    if (!event.target) {
      return;
    }

    let currentElement = /** @type {HTMLElement|null} */ (event.target);

    while (currentElement) {
      if (currentElement.classList.contains(this._keyboardFocusClassName)) {
        currentElement.classList.remove(this._keyboardFocusClassName); // not keyboard focused anymore
      }

      const parent = /** @type {HTMLElement} */ (currentElement.parentNode);
      currentElement = parent !== document.documentElement ? parent : null;
    }
  }
}
