import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ThingAuthorization } from '../../classes/EntityManager/entities/ThingAuthorization/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { Dialogs } from '../../classes/Dialogs';

@autoinject()
export class ThingAuthorizationListItem {
  @bindable public thingAuthorization: ThingAuthorization | null = null;

  @subscribableLifecycle()
  protected readonly thingAuthorizationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingAuthorization];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.thingAuthorizationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ThingAuthorization,
        context: this as ThingAuthorizationListItem,
        propertyName: 'thingAuthorization'
      });
  }

  protected handleDeleteClick(): void {
    const thingAuthorization = this.thingAuthorization;
    assertNotNullOrUndefined(
      thingAuthorization,
      "can't ThingAuthorizationListItem.handleDeleteClick without thingAuthorization"
    );
    void Dialogs.deleteEntityDialog(thingAuthorization).then(() => {
      this.entityManager.thingAuthorizationRepository.delete(
        thingAuthorization
      );
    });
  }

  @computed(expression('thingAuthorization.userId'))
  protected get user(): User | null {
    return this.thingAuthorization
      ? this.entityManager.userRepository.getById(
          this.thingAuthorization.userId
        )
      : null;
  }
}
