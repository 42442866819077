import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessTaskGroupDto } from './ProcessTaskGroupDto';

export function createProcessTaskGroupEntityInfo<
  TEntity extends ProcessTaskGroupDto<string, string>
>(
  options: CreateEntityInfoOptions<ProcessTaskGroupDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskGroup,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskGroup,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskGroupDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        thingGroupId: {
          name: 'thingGroupId',
          ref: EntityName.ThingGroup
        },
        thingGroupOwnerPersonId: {
          name: 'thingGroupOwnerPersonId',
          ref: EntityName.Person,
          defaultValue: null
        },
        processConfigurationId: {
          name: 'processConfigurationId',
          ref: EntityName.ProcessConfiguration
        },
        assigneeUserId: {
          name: 'assigneeUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        assignedFromUserId: {
          name: 'assignedFromUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        assignedProcessTaskName: {
          name: 'assignedProcessTaskName',
          defaultValue: null
        },
        offerReceiverPersonId: {
          name: 'offerReceiverPersonId',
          ref: EntityName.Person,
          defaultValue: null
        },
        invoiceReceiverPersonId: {
          name: 'invoiceReceiverPersonId',
          ref: EntityName.Person,
          defaultValue: null
        },
        offerReceiverIsInvoiceReceiver: {
          name: 'offerReceiverIsInvoiceReceiver',
          defaultValue: false
        },
        referenceCode: {
          name: 'referenceCode',
          defaultValue: null
        },
        sequenceNumber: {
          name: 'sequenceNumber',
          defaultValue: null
        },
        assigneeHint: {
          name: 'assigneeHint',
          defaultValue: null
        },
        previousAssigneeHint: {
          name: 'previousAssigneeHint',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        officeNote: {
          name: 'officeNote',
          defaultValue: null
        },
        contactPersons: {
          name: 'contactPersons',
          defaultValue: () => [],
          innerInfo: [
            {
              personId: {
                name: 'personId',
                ref: EntityName.Person
              },
              purposeOfPerson: {
                name: 'purposeOfPerson',
                defaultValue: null
              }
            }
          ]
        },
        customId: {
          name: 'customId',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
