export class XmlImporterUtils {
  /**
   *
   * @param {string} xmlString
   * @returns {Document}
   */
  static parseXmlString(xmlString) {
    const parser = new DOMParser();
    return parser.parseFromString(xmlString, 'text/xml');
  }

  /**
   *
   * @param {Element} parentElement - parentElement which the elementPath should be stripped down to
   * @param {Array<string>} elementPath - full path to the subElement
   * @returns {Array<string>} - elementPath which is relative to the parentElement
   */
  static getRelativeElementPath(parentElement, elementPath) {
    const parentPath = this.getElementPath(parentElement);
    const path = elementPath.slice();

    for (let key = 0; key < parentPath.length; key++) {
      const entry = parentPath[key];

      if (elementPath[0] === entry) {
        elementPath.shift();
      } else {
        break;
      }
    }

    return path;
  }

  /**
   *
   * @param {Element} element
   * @returns {Array<string>} - an array of the parent tag names
   */
  static getElementPath(element) {
    const parents = [];

    let currentElement = element;
    while (currentElement && currentElement.constructor !== XMLDocument) {
      parents.push(currentElement.tagName);
      currentElement = currentElement.parentNode;
    }

    return parents.reverse();
  }
}
