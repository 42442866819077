import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskAppointmentToProcessTaskDevice } from './types';

export class ProcessTaskAppointmentToProcessTaskDeviceRepository extends AppEntityRepository<EntityName.ProcessTaskAppointmentToProcessTaskDevice> {
  public getByProcessTaskDeviceId(
    processTaskDeviceId: string
  ): Array<ProcessTaskAppointmentToProcessTaskDevice> {
    return this.getAll().filter(
      (relation) => relation.processTaskDeviceId === processTaskDeviceId
    );
  }

  public getByProcessTaskDeviceIds(
    processTaskDeviceIds: Array<string>
  ): Array<ProcessTaskAppointmentToProcessTaskDevice> {
    return this.getAll().filter((relation) =>
      processTaskDeviceIds.includes(relation.processTaskDeviceId)
    );
  }

  public getByProcessTaskAppointmentIds(
    processTaskAppointmentIds: Array<string>
  ): Array<ProcessTaskAppointmentToProcessTaskDevice> {
    return this.getAll().filter((relation) =>
      processTaskAppointmentIds.includes(relation.processTaskAppointmentId)
    );
  }

  public getByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentToProcessTaskDevice> {
    return this.getAll().filter(
      (relation) =>
        relation.processTaskAppointmentId === processTaskAppointmentId
    );
  }

  public getByProcessTaskAppointmentAndProcessTaskDeviceId(
    processTaskAppointmentId: string,
    processTaskDeviceId: string
  ): ProcessTaskAppointmentToProcessTaskDevice | null {
    return (
      this.getAll().find((relation) => {
        return (
          relation.processTaskAppointmentId === processTaskAppointmentId &&
          relation.processTaskDeviceId === processTaskDeviceId
        );
      }) ?? null
    );
  }
}
