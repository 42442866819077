import { MomentInput } from 'moment';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntryUtils } from '../../classes/EntityManager/entities/Entry/EntryUtils';
import { EntryDeletionService } from '../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-clicked - the edit button has been clicked and the container should handle it (the edit functionality is NOT integrated in the structure-list-item)
 * @event enter-clicked - the enter button or the preview picture has been clicked, the container should handle the functionaliy
 */
@autoinject()
export class StructureListItem {
  @bindable public entry: Entry | null = null;
  @bindable public project: Project | null = null;

  @bindable public createSubEntryEnabled = true;

  @bindable public entryFilterString = '';

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  @subscribableLifecycle()
  protected readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  protected ratingEnabled = false;

  private domElement: HTMLElement;
  private listItemElement: HTMLElement | null = null;

  private panelOpen = false;

  private router: Router;

  protected StructureListItem: typeof StructureListItem = StructureListItem;

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.domElement = element as HTMLElement;
    this.router = router;

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as StructureListItem,
        propertyName: 'entry'
      });

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Project,
        context: this as StructureListItem,
        propertyName: 'project'
      });

    this.projectPermissionsHandle.canCreateEntries;
  }

  public attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      this.updateRatingEnabled.bind(this)
    );
    this.updateRatingEnabled();
  }

  public detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * highlight the dom element for a short time
   */
  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  private updateRatingEnabled(): void {
    if (!this.project || !this.project.structureTemplateId) {
      this.ratingEnabled = false;
      return;
    }

    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        this.project.structureTemplateId
      );
    this.ratingEnabled =
      structureTemplate?.ratingEnabledOnStructureListItems ?? false;
  }

  protected projectChanged(): void {
    this.updateRatingEnabled();
  }

  protected handleMoreButtonClick(event: MouseEvent): void {
    event.preventDefault();
    this.panelOpen = !this.panelOpen;
  }

  protected handleNavigateToEntryClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'enter-clicked',
      bubbles: true,
      detail: null
    });
  }

  protected getPictureOfEntry(entry: Entry): Picture | null {
    return this.entityManager.pictureRepository.getSelectedEntryPicture(
      entry.id
    );
  }

  protected getPageDepthIndexOfEntry(entry: Entry): string {
    return EntryUtils.getPageDepthIndex(
      this.entityManager.entryRepository.getPathByEntryId(entry.id)
    );
  }

  protected handleDeleteEntryClicked(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't StructureListItem.handleDeleteEntry without entry"
    );

    void this.entryDeletionService.deleteEntryWithDialog(this.entry);
  }

  protected handleCreateNewSubEntryClicked(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't structureListItem.handleCreateNewSubEntryClicked without entry"
    );

    const entry = this.createEntry({
      pageDepthParent: this.entry.id,
      listPosition: null
    });

    this.router.navigateToRoute('project', {
      project_id: this.entry.project,
      entry_id: entry.id,
      edit_entry: true
    });
  }

  protected handleCreateNextEntryClicked(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't structureListItem.handleCreateNextEntryClicked without entry"
    );

    this.createEntry({
      pageDepthParent: this.entry.page_depth_parent,
      listPosition: this.entry.list_position + 1
    });
  }

  private createEntry({
    pageDepthParent,
    listPosition
  }: {
    pageDepthParent: string | null;
    listPosition: number | null;
  }): Entry {
    assertNotNullOrUndefined(this.project, 'no project available');

    return this.entityManager.entryRepository.create({
      project: this.project.id,
      ownerProjectId: this.project.id,
      page_depth_parent: pageDepthParent,
      ...(listPosition ? { list_position: listPosition } : {}),
      ownerUserGroupId: this.project.usergroup
    });
  }

  protected handleToggleExcludeFromExportClicked(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't StructureListItem.handleToggleExcludeFromExport without entry"
    );

    this.entry.exclude_from_export = !this.entry.exclude_from_export;
    this.entityManager.entryRepository.update(this.entry);
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleDroppedOnChildren(
    viewModel: StructureListItem,
    newPageDepthParentId: string
  ): void {
    const entry = viewModel.entry;
    assertNotNullOrUndefined(entry, 'cannot drop item without entry');

    this.entityManager.entryRepository.setParentIdOfEntry(
      entry,
      newPageDepthParentId
    );
    DomEventHelper.fireEvent(this.domElement, {
      name: 'dropped-on-children',
      detail: null
    });
  }

  protected handleClickOnItem(event: MouseEvent): void {
    if (event.defaultPrevented) return;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-clicked',
      detail: null
    });
  }
}
