import { computedFrom } from 'aurelia-framework';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';

export class DisplayedEntrySelect {
  private readonly onEntrySelected: OnEntrySelected;

  private internalSelectedEntry: Entry | null;
  private internalEntriesToSelect: Array<Entry>;
  private internalParentEntry: Entry | null;

  constructor(options: DisplayedEntrySelectOptions) {
    this.onEntrySelected = options.onEntrySelected;
    this.internalSelectedEntry = options.selectedEntry;
    this.internalEntriesToSelect = options.entriesToSelect;
    this.internalParentEntry = options.parentEntry;
  }

  @computedFrom('internalSelectedEntry')
  public get selectedEntry(): Entry | null {
    return this.internalSelectedEntry;
  }

  public set selectedEntry(selectedEntry: Entry | null) {
    this.internalSelectedEntry = selectedEntry;

    this.onEntrySelected({ displayedEntrySelect: this });
  }

  @computedFrom('internalParentEntry')
  public get parentEntry(): Entry | null {
    return this.internalParentEntry;
  }

  @computedFrom('internalEntriesToSelect')
  public get entriesToSelect(): Array<Entry> {
    return this.internalEntriesToSelect;
  }

  public setEntriesToSelect(entriesToSelect: Array<Entry>): void {
    this.internalEntriesToSelect = entriesToSelect;
  }
}

export type DisplayedEntrySelectOptions = {
  selectedEntry: Entry | null;
  entriesToSelect: Array<Entry>;
  parentEntry: Entry | null;
  onEntrySelected: OnEntrySelected;
};

export type OnEntrySelected = (options: {
  displayedEntrySelect: DisplayedEntrySelect;
}) => void;
