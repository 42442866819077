import moment from 'moment';

import { computedFrom } from 'aurelia-binding';

import { DateUtils } from 'common/DateUtils';

import { FullScreenOverlay } from '../full-screen-overlay/full-screen-overlay';
import { GlobalElements } from '../global-elements/global-elements';

export class NotificationWidget {
  protected fullScreenOverlayViewModel: FullScreenOverlay | null = null;

  protected options: NotificationWidgetOptions | null = null;

  protected notifications: Array<Notification> = [];

  protected currentNotification: Notification | null = null;

  protected contentContainerElement: HTMLElement | null = null;

  protected DateUtils = DateUtils;

  public static async open(options: NotificationWidgetOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: NotificationWidgetOptions): void {
    this.options = options;

    this.updateNotifications(options.notifications, options.newerThan);
    this.currentNotification = this.notifications[0] ?? null;

    if (this.currentNotification) this.fullScreenOverlayViewModel?.open();
  }

  @computedFrom('options', 'currentNotification')
  protected get hasPreviousNotification(): boolean {
    return this.getCurrentNotificationIndex() > 0;
  }

  @computedFrom('options', 'currentNotification')
  protected get hasNextNotification(): boolean {
    const numberOfNotifications = this.notifications.length ?? 0;
    return this.getCurrentNotificationIndex() < numberOfNotifications - 1;
  }

  private updateNotifications(
    notifications: Array<Notification>,
    newerThan?: Date
  ): void {
    const newNotifications = newerThan
      ? this.filterNotificationsByDate(notifications, newerThan)
      : notifications;
    this.notifications = newNotifications.sort(
      (n1, n2) => n1.date.getTime() - n2.date.getTime()
    );
  }

  protected handlePreviousClick(): void {
    const index = this.getCurrentNotificationIndex();
    this.currentNotification = this.notifications[index - 1] ?? null;
    this.resetScroll();
  }

  protected handleNextClick(): void {
    const index = this.getCurrentNotificationIndex();
    this.currentNotification = this.notifications[index + 1] ?? null;
    this.resetScroll();
  }

  protected handleFinishClick(): void {
    this.fullScreenOverlayViewModel?.close();
    this.options?.notificationClosed?.(this.currentNotification?.date);

    this.notifications = [];
    this.currentNotification = null;
  }

  private filterNotificationsByDate(
    notifications: Array<Notification>,
    newerThan: Date
  ): Array<Notification> {
    return notifications.filter((notification) =>
      moment(notification.date).isAfter(newerThan)
    );
  }

  private getCurrentNotificationIndex(): number {
    return (
      this.notifications.findIndex((n) => n === this.currentNotification) ?? -1
    );
  }

  private resetScroll(): void {
    this.contentContainerElement?.scroll(0, 0);
  }
}

type NotificationWidgetOptions = {
  notifications: Array<Notification>;
  newerThan?: Date;
  notificationClosed?: ((date?: Date) => void) | null;
};

export type Notification = {
  date: Date;
  title: string;
  message: string;
  imgSrc?: string;
};
