import { inject, bindable } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { DateUtils } from 'common/DateUtils';
import { GalleryThingHelper } from 'common/GalleryThing/GalleryThingHelper';

import { GlobalData } from '../../classes/GlobalData';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';

@inject(AppEntityManager)
export class MapSidebarThingItem {
  /** @type {import('../../classes/EntityManager/entities/Thing/types').Thing|null} */
  @bindable thing = null;

  /** @type {import('../../classes/EntityManager/entities/Project/types').Project|null} */
  _project = null;

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  thingChanged() {
    if (this.thing) {
      this._project = this._getLatestProjectOfThingId(this.thing.id);
    } else {
      this._project = null;
    }
  }

  /**
   * @param {string} thingId
   * @returns {import('../../classes/EntityManager/entities/Project/types').Project|null}
   */
  _getLatestProjectOfThingId(thingId) {
    const projects = this._entityManager.projectRepository
      .getByThingId(thingId)
      .filter((p) => p.projectType !== ProjectType.GALLERY)
      .sort(GlobalData.projectSortOptions.updatedAt.sortFunction);
    return projects[0] ?? null;
  }

  /**
   * @param {import('../../classes/EntityManager/entities/Project/types').Project} project
   */
  _getProjectName(project) {
    let projectName = project.name;
    if (project.projectType === ProjectType.GALLERY && project.name) {
      const date = GalleryThingHelper.getDateFromProjectName(project.name);
      projectName = DateUtils.formatToDateString(date);
    }
    return projectName;
  }

  /**
   * @param {string} thingId
   * @returns {string}
   */
  _getNameOfMainPersonForThing(thingId) {
    const thingToPerson =
      this._entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
        thingId
      );
    if (!thingToPerson) return '';

    const person = this._entityManager.personRepository.getById(
      thingToPerson.personId
    );
    if (person) {
      return PersonUtils.getPersonDisplayNameForPerson(person);
    } else {
      return '';
    }
  }
}
