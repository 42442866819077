import { MomentInput } from 'moment';

import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingType } from '../../classes/EntityManager/entities/ThingType/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ManagePropertyDefinitionsWidgetThingTypeAdapter } from '../../propertyComponents/manage-property-definitions-widget/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetThingTypeAdapter/ManagePropertyDefinitionsWidgetThingTypeAdapter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ManageValueCalculationConfigDefinitionsWidgetThingTypeThingAdapter } from '../../valueCalculationComponents/manage-value-calculation-config-definitions-widget/ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetThingTypeThingAdapter/ManageValueCalculationConfigDefinitionsWidgetThingTypeThingAdapter';
import { ManageValueCalculationConfigDefinitionsWidgetThingTypeProjectAdapter } from '../../valueCalculationComponents/manage-value-calculation-config-definitions-widget/ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetThingTypeProjectAdapter/ManageValueCalculationConfigDefinitionsWidgetThingTypeProjectAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditThingTypeDialog {
  protected thingType: ThingType | null = null;

  private onDialogClosed: OnDialogClosedCallback | null = null;

  private dialog: RecordItDialog | null = null;

  protected managePropertyDefinitionsWidgetAdapter: ManagePropertyDefinitionsWidgetThingTypeAdapter | null =
    null;
  protected valueCalculationsThingAdapter: ManageValueCalculationConfigDefinitionsWidgetThingTypeThingAdapter | null =
    null;
  protected valueCalculationsProjectAdapter: ManageValueCalculationConfigDefinitionsWidgetThingTypeProjectAdapter | null =
    null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingType];

  public static async open(options: EditThingTypeDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingType,
        context: this,
        expression: 'thingType'
      });
  }

  public open(options: EditThingTypeDialogOptions): void {
    this.thingType = options.thingType;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.managePropertyDefinitionsWidgetAdapter =
      new ManagePropertyDefinitionsWidgetThingTypeAdapter({
        entityManager: this.entityManager,
        subscriptionManagerService: this.subscriptionManagerService,
        permissionsService: this.permissionsService,
        thingType: options.thingType
      });

    this.valueCalculationsThingAdapter =
      new ManageValueCalculationConfigDefinitionsWidgetThingTypeThingAdapter({
        entityManager: this.entityManager,
        subscriptionManagerService: this.subscriptionManagerService,
        permissionsService: this.permissionsService,
        thingType: this.thingType
      });

    this.valueCalculationsProjectAdapter =
      new ManageValueCalculationConfigDefinitionsWidgetThingTypeProjectAdapter({
        entityManager: this.entityManager,
        subscriptionManagerService: this.subscriptionManagerService,
        permissionsService: this.permissionsService,
        thingType: this.thingType
      });

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
    if (this.thingType) this.onDialogClosed?.(this.thingType);
    this.managePropertyDefinitionsWidgetAdapter = null;
    this.valueCalculationsProjectAdapter = null;
    this.valueCalculationsThingAdapter = null;
  }

  protected handleThingTypeChanged(): void {
    assertNotNullOrUndefined(
      this.thingType,
      "can't EditThingTypeDialog.handleThingTypeChanged without a thingType"
    );
    this.entityManager.thingTypeRepository.update(this.thingType);
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected getUsergroupNameById(usergroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(usergroupId);
    return group?.name ?? '';
  }
}

type EditThingTypeDialogOptions = {
  thingType: ThingType;
  onDialogClosed?: OnDialogClosedCallback;
};

type OnDialogClosedCallback = (thingType: ThingType) => void;
