import { computedFrom } from 'aurelia-framework';
import {
  ProcessConfigurationProcessTaskPropertiesConfiguration,
  ProcessTaskDefaultPropertyConfig
} from 'common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskProperty } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  CreationConfig,
  PropertyAdapter,
  SubscribeOptions
} from './PropertyAdapter';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';

export class ProcessTaskPropertyAdapter
  implements
    PropertyAdapter<ProcessTaskProperty, ProcessTaskDefaultPropertyConfig>
{
  private readonly processTask: ProcessTask;
  private readonly entityManager: AppEntityManager;
  private readonly permissionsService: PermissionsService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly processTaskLoggingService: ProcessTaskLoggingService;

  constructor(options: {
    processTask: ProcessTask;
    subscriptionManagerService: SubscriptionManagerService;
    entityManager: AppEntityManager;
    processTaskLoggingService: ProcessTaskLoggingService;
    permissionsService: PermissionsService;
  }) {
    this.processTask = options.processTask;
    this.entityManager = options.entityManager;
    this.permissionsService = options.permissionsService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.processTaskLoggingService = options.processTaskLoggingService;
  }

  public subscribe(
    options: SubscribeOptions<
      ProcessTaskProperty,
      ProcessTaskDefaultPropertyConfig
    >
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(
        this.entityManager.propertyRepository.getByProcessTaskId(
          this.processTask.id
        )
      );
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );
    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateConfigs.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateConfigs.bind(this)
    );
    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.ProcessTask,
      entity: this.processTask,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public createPropertyFromConfig(
    config: CreationConfig<ProcessTaskDefaultPropertyConfig>
  ): ProcessTaskProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      ownerUserGroupId: this.processTask.ownerUserGroupId,
      ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: this.processTask.id,
      processTaskId: this.processTask.id
    }) as ProcessTaskProperty;
  }

  public afterPropertyModified(property: ProcessTaskProperty): void {
    void this.processTaskLoggingService.logProcessTaskPropertyModified(
      this.processTask,
      property
    );
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }

  private getConfigs(): Array<ProcessTaskDefaultPropertyConfig> {
    const processTaskGroup = this.processTask
      ? this.entityManager.processTaskGroupRepository.getById(
          this.processTask.ownerProcessTaskGroupId
        )
      : null;
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const config: ProcessConfigurationProcessTaskPropertiesConfiguration | null =
      processConfiguration?.processTaskPropertiesConfigurationJson
        ? JSON.parse(
            processConfiguration.processTaskPropertiesConfigurationJson
          )
        : null;

    return config ? config.defaultProperties : [];
  }
}
