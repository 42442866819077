import { computedFrom } from 'aurelia-framework';

import { Picture } from '../../../classes/EntityManager/entities/Picture/types';
import { PictureAdapter } from '../EntityAdapter/PictureAdapter/PictureAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class PicturePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<PictureAdapter>,
  Picture
> {
  @computedFrom('combinedRevision')
  public get canSketch(): boolean {
    return this.checkAdapterSpecificPermissionByName('canSketch');
  }

  @computedFrom('combinedRevision')
  public get canResize(): boolean {
    return this.checkAdapterSpecificPermissionByName('canResize');
  }

  @computedFrom('combinedRevision')
  public get canMark(): boolean {
    return this.checkAdapterSpecificPermissionByName('canMark');
  }
}
