import { Disposable } from '../Utils/DisposableContainer';

export class EventDispatcher<TEventConfig> {
  private eventListeners: Array<IEventListener<TEventConfig>> = [];

  public addDisposableEventListener<T extends keyof TEventConfig>(
    eventName: T,
    callback: (payload: TEventConfig[T]) => void
  ): Disposable {
    const context = Symbol('context');
    this.addEventListener(context, eventName, callback);

    return {
      dispose: () => {
        this.removeEventListenersByContext(context);
      }
    };
  }

  public addEventListener<T extends keyof TEventConfig>(
    context: any,
    eventName: T,
    callback: (payload: TEventConfig[T]) => void
  ): void {
    this.eventListeners.push({
      context: context,
      eventName: eventName,
      callback: callback
    });
  }

  public removeEventListenersByContext(context: any): void {
    this.removeEventListenerWithCallback((listener) => {
      return listener.context === context;
    });
  }

  public dispatchEvent<T extends keyof TEventConfig>(
    eventName: T,
    payload: TEventConfig[T]
  ): void {
    for (const listener of this.eventListeners) {
      if (listener.eventName === eventName) {
        // TODO apply context
        listener.callback(payload);
      }
    }
  }

  /**
   * callback gets called for every listener
   * if the callback returns true the event listener will get removed
   */
  private removeEventListenerWithCallback(
    callback: CallbackFunction<TEventConfig>
  ): void {
    const eventListenerInfos = this.eventListeners
      .map((eventListener, index) => ({ eventListener, index }))
      .reverse();

    for (const { eventListener, index } of eventListenerInfos) {
      if (callback(eventListener)) {
        this.eventListeners.splice(index, 1);
      }
    }
  }
}

interface IEventListener<TEventConfig, TKey extends keyof TEventConfig = any> {
  context: any;
  eventName: TKey;
  callback: (payload: TEventConfig[TKey]) => void;
}

type CallbackFunction<T> = (listener: IEventListener<T>) => boolean;
