import {
  autoinject,
  bindable,
  computedFrom,
  containerless
} from 'aurelia-framework';
import { PersonContactType } from 'common/Enums/PersonContactType';
import { PersonContactHelper } from 'common/EntityHelper/PersonContactHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { PersonContact } from '../../../classes/EntityManager/entities/PersonContact/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@containerless()
@autoinject()
export class ShowProcessAppointmentPhoneButtons {
  @bindable()
  public processTaskContactPerson: Person | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected personContacts: Array<PersonContact> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updatePersonContacts.bind(this)
    );
    this.updatePersonContacts();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskContactPersonChanged(): void {
    if (this.isAttached) {
      this.updatePersonContacts();
    }
  }

  private updatePersonContacts(): void {
    if (this.processTaskContactPerson) {
      const contacts = this.entityManager.personContactRepository.getByPersonId(
        this.processTaskContactPerson.id
      );
      const phoneContactsWithNames = contacts.filter(
        (contact) =>
          !!(contact.contactType === PersonContactType.PHONE && contact.name)
      );
      this.personContacts = PersonContactHelper.highlightedToFront(
        phoneContactsWithNames
      );
    } else {
      this.personContacts = [];
    }
  }

  @computedFrom(
    'processTaskContactPerson.company',
    'processTaskContactPerson.companyName',
    'processTaskContactPerson.firstName',
    'processTaskContactPerson.lastName'
  )
  protected get personDisplayName(): string {
    if (!this.processTaskContactPerson) {
      return '';
    }

    return PersonUtils.getPersonDisplayName(
      this.processTaskContactPerson.company,
      this.processTaskContactPerson.companyName,
      this.processTaskContactPerson.title,
      this.processTaskContactPerson.firstName,
      this.processTaskContactPerson.lastName
    );
  }
}
