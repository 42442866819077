import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { FullScreenContent } from '../../aureliaComponents/full-screen-content/full-screen-content';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Dialogs } from '../../classes/Dialogs';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { DrawingArea } from '../drawing-area/drawing-area';
import { ToolGrid } from '../drawing-area/tools/ToolGrid';
import { SketcherOverlayToolBar } from '../sketcher-overlay-tool-bar/sketcher-overlay-tool-bar';
import {
  Action,
  ActionTriggeredEvent
} from '../sketcher-overlay-tool-bar/sketcher-overlay-tool-bar-action-buttons/sketcher-overlay-tool-bar-action-buttons';
import { DrawingHistoryManager } from './DrawingHistoryManager';
import {
  SketcherOverlaySketchArea,
  TLoadErrorEvent
} from './sketcher-overlay-sketch-area/sketcher-overlay-sketch-area';
import { SketcherOverlayImageHandler } from './SketcherOverlayImageHandler';

@autoinject()
export class SketcherOverlay {
  public static async open(
    imageHandler: SketcherOverlayImageHandler,
    options?: DialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(imageHandler, options);
  }

  private domElement: HTMLElement;
  private fullScreenContent: FullScreenContent | null = null;
  private opened: boolean = false;
  private drawingArea: DrawingArea | null = null;
  private historyManager: DrawingHistoryManager | null = null;
  private grid: ToolGrid | null = null;
  private toolBar: SketcherOverlayToolBar | null = null;
  private toolIsActive: boolean = false;
  private imageHandler: SketcherOverlayImageHandler | null = null;
  private sketchArea: SketcherOverlaySketchArea | null = null;
  private options: DialogOptions | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  private open(
    imageHandler: SketcherOverlayImageHandler,
    options?: DialogOptions
  ): void {
    assertNotNullOrUndefined(
      this.fullScreenContent,
      "can't SketcherOverlay.open without a fullScreenContent"
    );
    assertNotNullOrUndefined(
      this.sketchArea,
      "can't SketcherOverlay.open without a sketchArea"
    );
    this.setImageHandler(imageHandler);

    this.fullScreenContent.open();
    this.opened = true;
    this.options = options ?? null;

    this.sketchArea.load(imageHandler);
    SiteScrollLocker.lockScrolling('sketcher-overlay');
  }

  private close(): void {
    SiteScrollLocker.unlockScrolling('sketcher-overlay');
    this.opened = false;
    this.setImageHandler(null);
    this.sketchArea?.destroy();
  }

  private handleSketchAreaLoadError(event: TLoadErrorEvent): void {
    void Dialogs.errorDialogTk(
      'drawingComponents.sketcherOverlay.pictureLoadingError'
    );
    console.error(event.detail.error);
    this.close();
  }

  private async handleActionTriggered(
    event: ActionTriggeredEvent
  ): Promise<void> {
    switch (event.detail.action) {
      case Action.DONE:
        await this.save();
        this.close();
        break;

      case Action.RESET:
        await this.resetSketch();
        break;

      case Action.CANCEL:
        await this.cancel();
        break;

      default:
        throw new Error(`unhandled action "${event.detail.action}"`);
    }
  }

  private async save(): Promise<void> {
    assertNotNullOrUndefined(
      this.imageHandler,
      "can't SketcherOverlay.save without a imageHandler"
    );
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlay.save without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.toolBar,
      "can't SketcherOverlay.save without a toolBar"
    );
    this.toolBar.cancelTool();
    this.options?.onDone?.();
    await this.imageHandler.save(
      this.drawingArea.exportSvgAsDataUrl(),
      this.drawingArea.exportSvgAsDataUrl(true)
    );
  }

  private async resetSketch(): Promise<void> {
    await Dialogs.deleteDialogTk(
      'drawingComponents.sketcherOverlay.resetDialogText',
      null,
      'drawingComponents.sketcherOverlay.resetDialogButtonText'
    );

    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlay.resetSketch without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlay.resetSketch without a historyManager"
    );
    assertNotNullOrUndefined(
      this.toolBar,
      "can't SketcherOverlay.resetSketch without a toolBar"
    );

    this.options?.onReset?.();
    this.toolBar.reset();
    this.drawingArea.empty();
    this.historyManager.push();
  }

  private async cancel(): Promise<void> {
    await Dialogs.cancelDialogTk(
      'drawingComponents.sketcherOverlay.cancelDialogText'
    );

    assertNotNullOrUndefined(
      this.toolBar,
      "can't SketcherOverlay.cancel without a toolBar"
    );

    this.options?.onCancel?.();
    this.toolBar.reset();
    this.close();
  }

  private setImageHandler(
    imageHandler: SketcherOverlayImageHandler | null
  ): void {
    this.imageHandler?.destroy();
    this.imageHandler = imageHandler;
  }
}

export interface DialogOptions {
  /**
   * Called whenever the dialog is saved & closed, e.g. when the user clicks on "Fertig"
   */
  onDone?: () => void;
  /**
   * Called whenever editing is canceled, e.g. when the user clicks on "Abbrechen"
   */
  onCancel?: () => void;
  /**
   * Called whenever the dialog is reset, e.g. when the user clicks on "Zurücksetzen"
   */
  onReset?: () => void;
}
