/**
 * Replaces all binary data with placeholders and puts them into a separate attachments array.
 * The code is (shamelessly) stolen from https://github.com/socketio/socket.io-parser/blob/4.2.2/lib/binary.ts and adapted.
 */
export class ObjectDeconstructor {
  private attachments: Array<Uint8Array> = [];

  /**
   * For performance reasons, data will be returned in the return value, if no attachment was found.
   * This is because creating so many new objects on the fly has really bad performance, we try to reuse as many objects as possible.
   * This makes the algorithm a little bit slower for data with attachments, but like 99% of our request have no binary data
   */
  public deconstruct(data: Record<any, any>): {
    deconstructedObject: Record<any, any>;
    attachments: Array<Uint8Array>;
  } {
    this.attachments = [];

    const result = this.deconstructObjectRecursive(data);

    return {
      deconstructedObject: result ? result.newData : data,
      attachments: this.attachments
    };
  }

  /**
   * this function is quite hard to read but if we extract the code, it will become significantly slower (like 10-20%)
   */
  private deconstructObjectRecursive(data: any): OptionalDeconstructDataResult {
    if (!data) return data;

    const jsonValue =
      typeof data.toJSON === 'function' && !(data instanceof Date)
        ? data.toJSON()
        : data;

    if (jsonValue instanceof Uint8Array) {
      const placeholder = { _placeholder: true, num: this.attachments.length };
      this.attachments.push(jsonValue);
      return { newData: placeholder };
    } else if (Array.isArray(jsonValue)) {
      let newData: Array<any> | null = null;

      let i = jsonValue.length;
      while (i--) {
        const value = jsonValue[i];

        const result = this.deconstructObjectRecursive(value);

        if (newData && result) {
          newData[i] = result.newData;
        } else if (result) {
          newData = jsonValue.slice();
          newData[i] = result.newData;
        }
      }

      return newData ? { newData } : null;
    } else if (typeof jsonValue === 'object' && !(jsonValue instanceof Date)) {
      let newData: Record<any, any> | null = null;

      for (const key in jsonValue) {
        if (Object.prototype.hasOwnProperty.call(jsonValue, key)) {
          const result = this.deconstructObjectRecursive(jsonValue[key]);
          if (newData && result) {
            newData[key] = result.newData;
          } else if (result) {
            newData = Object.assign({}, jsonValue) as Record<any, any>;
            newData[key] = result.newData;
          }
        }
      }

      return newData ? { newData } : null;
    }

    return null;
  }
}

type OptionalDeconstructDataResult = { newData: any } | null;
