import { Subscription } from 'rxjs';
import { Disposable } from './DisposableContainer';

export class RxjsUtils {
  private constructor() {}

  public static subscriptionToDisposable(
    subscription: Subscription
  ): Disposable {
    return {
      dispose: () => {
        subscription.unsubscribe();
      }
    };
  }
}
