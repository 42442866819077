import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationFormDto } from './ProcessConfigurationFormDto';

export function createProcessConfigurationFormEntityInfo<
  TEntity extends ProcessConfigurationFormDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationFormDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationForm,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationForm,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationFormDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        reportTypeId: {
          name: 'reportTypeId',
          ref: EntityName.ReportType,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
