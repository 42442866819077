import { autoinject, observable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  GetProcessTaskGroupRelationInfoRequest,
  GetProcessTaskGroupRelationInfoSuccessResponse,
  ProcessTaskGroupRelationInfo,
  ProcessTaskGroupRelationInfoRequestType
} from 'common/EndpointTypes/ProcessTaskGroupRelationInfoEndpointsHandler';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { interval } from '../../hooks/dependencies';
import { configureHooks } from '../../hooks/configureHooks';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGroupRelationInfoListItem } from '../../aureliaComponents/process-task-group-relation-info-list-item/process-task-group-relation-info-list-item';
import {
  SingleSocketRequest,
  SingleSocketRequestService,
  SingleSocketRequestSkippedError
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../services/SocketService';
import { watch } from '../../hooks/watch';
import {
  CurrentPageIndexChangedEvent,
  Pagination
} from '../../aureliaComponents/pagination/pagination';
import { IUtilsDebouncedFunction, Utils } from '../../classes/Utils/Utils';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class ManageProcessTaskGroupRelationsDialog {
  protected dialog: RecordItDialog | null = null;

  private onNewRelationInfos: UpdateProcessTaskGroupRelationInfosCallback | null =
    null;

  protected availableProcessTaskGroupRelationInfos: Array<ProcessTaskGroupRelationInfo> =
    [];

  protected pagination: Pagination<ProcessTaskGroupRelationInfoListItem> | null =
    null;

  @observable private filterString: string = '';

  protected maxPageIndex = 1;
  @observable protected currentPageSize = 10;
  @observable protected currentPageIndex = 1;

  private currentProcessTaskGroup: ProcessTaskGroup | null = null;

  private getProcessTaskGroupRelationInfosRequest: SingleSocketRequest<
    GetProcessTaskGroupRelationInfoRequest<ProcessTaskGroupRelationInfoRequestType.ALL>,
    GetProcessTaskGroupRelationInfoSuccessResponse
  >;

  private showLoadingAnimation = false;

  private updateProcessTaskGroupRelationInfosDebounced: IUtilsDebouncedFunction | null =
    null;

  constructor(
    private readonly socketService: SocketService,
    private readonly singleSocketRequestService: SingleSocketRequestService
  ) {
    this.getProcessTaskGroupRelationInfosRequest =
      this.singleSocketRequestService.createAfterFirstActualizationRequest({
        requestCallback: ({ data }) => {
          return new Promise<GetProcessTaskGroupRelationInfoSuccessResponse>(
            (resolve, reject) => {
              this.socketService.getProcessTaskGroupRelationInfos(data, (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(
                    new Error("couldn't fetch process task group context infos")
                  );
                }
              });
            }
          );
        }
      });
  }

  public open(options: AddProcessTaskGroupRelationsDialogOptions): void {
    this.updateProcessTaskGroupRelationInfosDebounced = Utils.debounceFunction(
      () => {
        void this.updateAvailableProcessTaskGroupRelationInfos({
          showOverlayForLongResponseTimes: false,
          showOverlayImmediately: true
        });
      },
      500
    );

    this.currentProcessTaskGroup = options.currentProcessTaskGroup;
    this.onNewRelationInfos = options.onNewRelationInfos;

    void this.updateAvailableProcessTaskGroupRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
    this.showLoadingAnimation = false;
    this.pagination?.goToFirstPage();
    this.dialog?.open();
    this.currentPageIndex = 1;
  }

  public static async open(
    options: AddProcessTaskGroupRelationsDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  @watch(interval(10000))
  protected pollProcessTaskGroupRelationInfos(): void {
    if (!this.getProcessTaskGroupRelationInfosRequest.isPending) {
      void this.updateAvailableProcessTaskGroupRelationInfos({
        showOverlayForLongResponseTimes: true,
        showOverlayImmediately: false
      });
    }
  }

  protected async updateAvailableProcessTaskGroupRelationInfos(
    config: UpdateSettings
  ): Promise<void> {
    if (
      !this.socketService?.isAuthenticated() ||
      !this.socketService.isConnected() ||
      !this.currentProcessTaskGroup
    )
      return;
    const delayedLoadingOverlay = !config.showOverlayForLongResponseTimes
      ? setTimeout(() => {
          this.showLoadingAnimation = true;
        }, 1000)
      : null;

    try {
      if (config.showOverlayImmediately) {
        this.showLoadingAnimation = true;
      }

      const response = await this.getProcessTaskGroupRelationInfosRequest.send({
        filterString: this.filterString,
        paginationInfo: {
          currentPageNumber: this.currentPageIndex,
          currentPageSize: this.currentPageSize
        },
        activeProcessTaskGroupId: this.currentProcessTaskGroup.id,
        type: ProcessTaskGroupRelationInfoRequestType.ALL
      });

      const oldRelationInfos = this.availableProcessTaskGroupRelationInfos;
      this.availableProcessTaskGroupRelationInfos =
        InstancePreserver.createNewArray({
          originalArray: oldRelationInfos,
          newArray: response.processTaskGroupInfos,
          getTrackingValue: (item) => item.processTaskGroupId
        });
      this.updateMaxPageIndex(response.totalResultLength);
    } catch (e) {
      if (!(e instanceof SingleSocketRequestSkippedError)) {
        throw e;
      }
    } finally {
      if (delayedLoadingOverlay) clearTimeout(delayedLoadingOverlay);
      this.showLoadingAnimation = false;
    }
  }

  protected handleDialogClosed(): void {
    this.resetFilters();
  }

  protected currentPageSizeChanged(): void {
    void this.updateAvailableProcessTaskGroupRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected currentPageIndexChanged(): void {
    void this.updateAvailableProcessTaskGroupRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected filterStringChanged(): void {
    this.pagination?.goToFirstPage();
    if (this.updateProcessTaskGroupRelationInfosDebounced)
      this.updateProcessTaskGroupRelationInfosDebounced();
  }

  protected handleCurrentPageIndexChanged(
    event: CurrentPageIndexChangedEvent
  ): void {
    this.currentPageIndex = event.detail.page;
  }

  protected updateProcessTaskRelationInfosOnPageAndInDialog(): void {
    void this.updateAvailableProcessTaskGroupRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
    this.onNewRelationInfos && this.onNewRelationInfos();
  }

  private updateMaxPageIndex(resultCount: number): void {
    this.maxPageIndex = Math.ceil(resultCount / this.currentPageSize);
  }

  private resetFilters(): void {
    this.filterString = '';
  }
}

type AddProcessTaskGroupRelationsDialogOptions = {
  currentProcessTaskGroup: ProcessTaskGroup;
  onNewRelationInfos: UpdateProcessTaskGroupRelationInfosCallback | null;
};

type UpdateProcessTaskGroupRelationInfosCallback = () => void;

type UpdateSettings = {
  showOverlayImmediately: boolean;
  showOverlayForLongResponseTimes: boolean;
};
