import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProjectQuestionEntityInfo } from 'common/Types/Entities/ProjectQuestion/createProjectQuestionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProjectQuestion } from './types';

export const projectQuestionEntityInfo =
  createProjectQuestionEntityInfo<ProjectQuestion>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
