import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { Property } from '../EntityManager/entities/Property/types';
import {
  createPropertyImportHandlersByType,
  PropertyImportHandlersByType
} from './createPropertyImportHandlersByType';

/**
 * A class which handles importing properties from e.g. a csv file.
 * Currently this class only imports the values to already existing properties and also only a subset of propertyTypes are supported.
 */
export class PropertyImporter {
  private readonly propertyImportHandlersByType: PropertyImportHandlersByType;

  constructor({ entityManager }: { entityManager: AppEntityManager }) {
    this.propertyImportHandlersByType = createPropertyImportHandlersByType({
      entityManager
    });
  }

  public import({
    properties,
    propertyValues,
    decimalSeparator
  }: {
    properties: Array<Property>;
    propertyValues: PropertyValues;
    decimalSeparator: string;
  }): void {
    for (const property of properties) {
      if (!property.name) {
        continue;
      }

      if (!propertyValues.hasOwnProperty(property.name)) {
        continue;
      }

      this.propertyImportHandlersByType[
        PropertyHelper.getTypeOrDefault(property.type)
      ]({
        property,
        importValue: propertyValues[property.name] as PropertyValues[string],
        decimalSeparator
      });
    }
  }
}

export type PropertyImporterOptions = {
  entityManager: AppEntityManager;
};

export type PropertyValues = Record<string, string | number | null>;
