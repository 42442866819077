/* global $ */

import { environment } from '../../environment';
import { DataStorageHelper } from '../DataStorageHelper/DataStorageHelper';
import { StoreInfoUtils } from '../DataStorageHelper/StoreInfoUtils';
import { DeviceInfoHelper } from '../DeviceInfoHelper';
import { CurrentUserService } from '../EntityManager/entities/User/CurrentUserService';
import { User } from '../EntityManager/entities/User/types';
import { UrlManager } from '../UrlManager';

export class DataStorageDumpSaver {
  private readonly user: User;

  constructor({
    currentUserService
  }: {
    currentUserService: CurrentUserService;
  }) {
    this.user = currentUserService.getRequiredCurrentUser();
  }

  public async createAndUploadDump(): Promise<void> {
    const dump = await this.createDump();
    await this.uploadDump(dump);
  }

  private async createDump(): Promise<Record<string, any>> {
    const tableNames = StoreInfoUtils.getAllTableNames();
    const data: Record<string, any> = {};

    for (const tableName of tableNames) {
      const tableData: Record<string, any> = {};
      const tableItemsWithKeys =
        await DataStorageHelper.getItemsWithKeys(tableName);

      for (const item of tableItemsWithKeys) {
        tableData[item.key] = item.value;
      }

      data[tableName] = tableData;
    }

    return data;
  }

  private async uploadDump(dump: Record<string, any>): Promise<void> {
    return new Promise((resolve, reject) => {
      void $.ajax({
        url: UrlManager.webFolder + '/api/save-dump',
        method: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({
          user: this.user,
          data: JSON.stringify({
            environmentInfo: {
              version: environment.lastCommitHash,
              isIOS: DeviceInfoHelper.isIOSDevice(),
              isAndroid: DeviceInfoHelper.isAndroidDevice(),
              isWeb: !DeviceInfoHelper.isApp(),
              UUID: DeviceInfoHelper.getUUID()
            },
            dump
          })
        }),
        success: (data, _, req) => {
          if (data && data.success) {
            resolve();
          } else {
            reject(
              new Error(
                `Failed to upload dump. Statuscode: ${req.statusCode}. ResponseText: ${req.responseText}`
              )
            );
          }
        },
        error: (req) => {
          reject(
            new Error(
              `Failed to upload dump. Statuscode: ${req.statusCode}. ResponseText: ${req.responseText}`
            )
          );
        }
      });
    });
  }
}
