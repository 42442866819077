import { ProcessTaskDevice } from '../ProcessTaskDevice/types';
import { ProcessConfigurationDeviceExport } from './types';

export class ProcessConfigurationDeviceExportUtils {
  private constructor() {}

  public static getAvailableProcessConfigurationDeviceExports(
    devices: Array<ProcessTaskDevice>,
    exportConfigs: Array<ProcessConfigurationDeviceExport>
  ): Array<ProcessConfigurationDeviceExport> {
    const usedConfigIds = new Set(
      devices.map((d) => d.processConfigurationDeviceExportId)
    );
    return exportConfigs.filter((c) => {
      if (c.includeUnspecifiedDevices && usedConfigIds.has(null)) {
        return true;
      }

      return usedConfigIds.has(c.id);
    });
  }
}
