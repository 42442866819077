import { autoinject } from 'aurelia-framework';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';

@autoinject()
export class ProcessTaskPositionsByProcessTaskIdComputer extends ValueComputer<
  ComputeData,
  ProcessTaskPositionsByProcessTaskId
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskPositionsByProcessTaskId {
    return Utils.groupBy(
      this.entityManager.processTaskPositionRepository.getAll(),
      (p) => p.ownerProcessTaskId
    );
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ComputeData = {};

export type ProcessTaskPositionsByProcessTaskId = Map<
  string,
  Array<ProcessTaskPosition>
>;
