import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { StructureTemplateRatingCategoryDto } from './StructureTemplateRatingCategoryDto';

export function createStructureTemplateRatingCategoryEntityInfo<
  TEntity extends StructureTemplateRatingCategoryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructureTemplateRatingCategoryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplateRatingCategory,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplateRatingCategory,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateRatingCategoryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        iconName: {
          name: 'iconName',
          defaultValue: null
        },
        iconType: {
          name: 'iconType',
          defaultValue: null
        },
        structureTemplateEntryPropertyId: {
          name: 'structureTemplateEntryPropertyId',
          ref: EntityName.StructureTemplateEntryProperty,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        ownerStructureTemplateId: {
          name: 'ownerStructureTemplateId',
          ref: EntityName.StructureTemplate,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
