import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { PictureDisplayInfo } from '../SelectPictureDialogAdapter/SelectPictureDialogAdapter';

export class PictureDisplayInfoUtils {
  private constructor() {}

  public static createDefaultPictureDisplayInfos({
    pictures,
    pictureIsEnabled = () => true
  }: CreateDefaultPictureDisplayInfosOptions): Array<PictureDisplayInfo> {
    return pictures.map((picture) => {
      return this.createDefaultPictureDisplayInfo({
        picture,
        enabled: pictureIsEnabled({ picture }),
        relatedPictures: pictures
      });
    });
  }

  public static createDefaultPictureDisplayInfo({
    picture,
    enabled,
    relatedPictures
  }: CreateDefaultPictureDisplayInfoOptions): PictureDisplayInfo {
    return {
      picture,
      subText: picture.description,
      enabled: enabled ?? true,
      relatedPictures
    };
  }
}

export type CreateDefaultPictureDisplayInfosOptions = {
  pictures: Array<Picture>;
  /**
   * default: () => true
   */
  pictureIsEnabled?: (optins: { picture: Picture }) => boolean;
};

export type CreateDefaultPictureDisplayInfoOptions = {
  picture: Picture;

  /**
   * default: true
   */
  enabled?: boolean;

  relatedPictures: Array<Picture>;
};
