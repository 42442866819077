import { computedFrom } from 'aurelia-framework';
import { Observable } from 'rxjs';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyWidgetBinding,
  PropertyWidgetBindingFeatureMap,
  PropertyWidgetBindingValueData
} from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetConfiguration } from '../PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';

export function createStructureTemplateEntryPropertyDefinitionWidgetConfiguration({
  enabled$
}: {
  enabled$: Observable<boolean>;
}): StructureTemplateEntryPropertyDefinitionWidgetConfiguration {
  return {
    bindingConfiguration: {
      features: {
        default: true,
        person: false,
        generalFile: false,
        picture: false,
        position: false,
        projectId: false
      },
      createBinding: ({ container, context }) => {
        return new PropertyBinding({
          entityManager: container.get(AppEntityManager),
          subscriptionManagerService: container.get(SubscriptionManagerService),
          permissionsService: container.get(PermissionsService),
          property: context,
          enabled$
        });
      }
    }
  };
}

export type StructureTemplateEntryPropertyDefinitionWidgetConfiguration =
  PropertyWidgetConfiguration<
    StructureTemplateEntryPropertyDefinitionFeatures,
    StructureTemplateEntryProperty
  >;

class PropertyBinding
  implements
    PropertyWidgetBinding<StructureTemplateEntryPropertyDefinitionFeatures>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: StructureTemplateEntryProperty;
  private readonly subscriptionManager: SubscriptionManager;

  private valueCanBeEdited: boolean = false;

  constructor(options: PropertyBindingOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;

    this.subscriptionManager = options.subscriptionManagerService.create();

    this.subscriptionManager.addRxjsSubscription(
      options.enabled$.subscribe((enabled) => {
        this.valueCanBeEdited = enabled;
      })
    );
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  @computedFrom('property.value')
  public get value(): string | null {
    return this.property.value;
  }

  @computedFrom()
  public get customChoice(): string | null {
    return null;
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options ?? [];
  }

  @computedFrom('property.ownerUserGroupId')
  public get userGroupId(): string {
    return this.property.ownerUserGroupId;
  }

  @computedFrom()
  public get children(): Array<never> {
    return [];
  }

  @computedFrom('valueCanBeEdited')
  public get canSetValueData(): boolean {
    return this.valueCanBeEdited;
  }

  @computedFrom()
  public get forceDisabled(): boolean {
    return false;
  }

  @computedFrom()
  public get features(): PropertyWidgetBindingFeatureMap<StructureTemplateEntryPropertyDefinitionFeatures> {
    return {
      default: true,
      generalFile: false,
      person: false,
      picture: false,
      position: false,
      projectId: false
    };
  }

  public setValueData(valueData: PropertyWidgetBindingValueData): void {
    this.property.value = valueData.value;

    if (valueData.customChoice) {
      console.error('customChoice is not supported yet');
    }

    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.property
    );
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}

type PropertyBindingOptions = {
  entityManager: AppEntityManager;
  property: StructureTemplateEntryProperty;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  enabled$: Observable<boolean>;
};

type StructureTemplateEntryPropertyDefinitionFeatures = 'default';
