import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';

/**
 * @slot top-row - the content is placed inside a flex row
 * @slot bottom-row - the content is placed inside a flex row
 * @slot more-panel - the content is placed inside the entity-list-item-more-panel
 *
 * @event {CheckboxChangedEvent} checkbox-checked-changed
 */
@autoinject()
export class ExpandableDualRowCompactListItem {
  @bindable()
  public hasCheckbox: boolean = false;

  @bindable()
  public checkboxEnabled: boolean = false;

  @bindable()
  public checked: boolean = false;

  @bindable()
  public colorBarColor: string | null = null;

  @bindable()
  public listItemBackgroundColor: string | null = null;

  @bindable()
  public morePanelDefaultStyling: boolean = true;

  private panelOpened: boolean = false;
  private toggleExpandedEventToIgnore: Event | null = null;
  protected listItemElement: HTMLElement | null = null;

  constructor(private readonly domElement: Element) {}

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleCheckboxClick(event: Event): boolean {
    this.toggleExpandedEventToIgnore = event;
    return true;
  }

  protected handleCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    DomEventHelper.fireEvent<CheckboxCheckedChangedEvent>(this.domElement, {
      name: 'checkbox-checked-changed',
      detail: event.detail
    });
  }

  protected handleToggleExpandedClick(event: Event): boolean {
    if (event !== this.toggleExpandedEventToIgnore) {
      this.panelOpened = !this.panelOpened;
    }

    this.toggleExpandedEventToIgnore = null;
    return true;
  }
}

export type CheckboxCheckedChangedEvent = NamedCustomEvent<
  'checkbox-checked-changed',
  { checked: boolean }
>;
