import { assertNotNullOrUndefined } from 'common/Asserts';
import _ from 'lodash';
import { AbstractStorageStrategy, KeyValue } from '../AbstractStorageStrategy';
import { JsonSerializer } from '../JsonSerializer';
import {
  StorageTableConfig,
  StoreInfoConstraint,
  storeInfo
} from '../storeInfo';
import {
  IndexedDBSplitDataInSeparateStoresMigrator,
  MigrationStatus
} from './IndexedDBSplitDataInSeparateStoresMigrator';

export class IndexedDBDataStorageStrategy extends AbstractStorageStrategy {
  private readonly serializer = new JsonSerializer();
  private mainStore: IDBDatabase | null = null;
  private permanentStore: IDBDatabase | null = null;

  private mainStoreInfo: StoreInfoConstraint = {
    ...storeInfo,
    applicationTables: storeInfo.applicationTables.filter(
      (t) => !('keepOnClearAll' in t)
    )
  };

  private permanentStoreInfo: StoreInfoConstraint = {
    ...storeInfo,
    name: storeInfo.name + '_permanent',
    applicationTables: storeInfo.applicationTables.filter(
      (t) => 'keepOnClearAll' in t
    )
  };

  public async init(): Promise<void> {
    this.mainStore = await this.initStore(this.mainStoreInfo, {
      needsDefaultAndMigrationTable: true
    });
    this.permanentStore = await this.initStore(this.permanentStoreInfo, {
      needsDefaultAndMigrationTable: false
    });
  }

  public async migrate(): Promise<void> {
    assertNotNullOrUndefined(
      this.mainStore,
      'cannot migrate data without old store ' + this.mainStoreInfo.name
    );
    assertNotNullOrUndefined(
      this.permanentStore,
      'cannot migrate data without new store ' + this.permanentStoreInfo.name
    );
    const migrator = new IndexedDBSplitDataInSeparateStoresMigrator({
      oldStore: this.mainStore,
      newStore: this.permanentStore,
      tableNamesToMigrate: this.permanentStoreInfo.applicationTables.map(
        (t) => t.name
      )
    });
    const result = await migrator.migrate();
    if (result.status === MigrationStatus.ERROR) {
      throw new Error(result.error);
    } else {
      this.mainStore = result.oldStore;
    }
  }

  public async getItem(key: string, tableName: string): Promise<any> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readonly'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.get(key);

    return await new Promise((res, rej) => {
      req.onsuccess = () => {
        res(this.serializer.deserialize(req.result));
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async getItems(tableName: string): Promise<Array<any>> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readonly'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.getAll();

    return await new Promise((res, rej) => {
      req.onsuccess = () => {
        res(req.result.map((value) => this.serializer.deserialize(value)));
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async getItemsWithKeys(tableName: string): Promise<Array<KeyValue>> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readonly'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.openCursor();

    return await new Promise((res, rej) => {
      const itemsWithKeys: Array<KeyValue> = [];

      req.onsuccess = () => {
        const cursor = req.result;
        if (cursor) {
          itemsWithKeys.push({
            key: cursor.key.toString(),
            value: this.serializer.deserialize(cursor.value)
          });
          cursor.continue();
        } else {
          res(itemsWithKeys);
        }
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async setItem(
    key: string,
    value: any,
    tableName: string
  ): Promise<void> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readwrite'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.put(this.serializer.serialize(value), key);

    return new Promise((res, rej) => {
      req.onsuccess = () => {
        res();
      };
      req.onerror = (event) => {
        console.error(event);
        rej(new Error('An error occured while setting an item.'));
      };
      tx.onabort = (event) => {
        rej(
          event.srcElement
            ? (event.srcElement as any).error
            : new Error('unspecified error')
        );
      };
    });
  }

  public async setItems(
    items: Array<KeyValue>,
    tableName: string
  ): Promise<void> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readwrite'
    );
    const objectStore = tx.objectStore(tableName);

    items.forEach((item) => {
      objectStore.put(this.serializer.serialize(item.value), item.key);
    });

    return new Promise((res, rej) => {
      tx.oncomplete = () => {
        res();
      };
      tx.onerror = (event) => {
        console.error(event);
        rej(new Error('An error occured while setting items.'));
      };
      tx.onabort = (event) => {
        console.error(event);
        rej(new Error('Setting items was aborted.'));
      };
    });
  }

  public async removeItem(key: string, tableName: string): Promise<any> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readwrite'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.delete(key);

    await new Promise<void>((res, rej) => {
      req.onsuccess = () => {
        res();
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async removeItems(
    keys: Array<string>,
    tableName: string
  ): Promise<any> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readwrite'
    );
    const objectStore = tx.objectStore(tableName);

    for (const key of keys) {
      objectStore.delete(key);
    }

    await new Promise<void>((res, rej) => {
      tx.oncomplete = () => {
        res();
      };
      tx.onerror = () => {
        rej(new Error('An error occured while removing items.'));
      };
      tx.onabort = () => {
        rej(new Error('Removing items was aborted.'));
      };
    });
  }

  /**
   * returns all stored keys inside the table
   */
  public async getKeys(tableName: string): Promise<Array<string>> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readonly'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.getAllKeys();

    return new Promise((res, rej) => {
      req.onsuccess = () => {
        res(req.result.map((r) => r.toString()));
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async clear(tableName: string): Promise<void> {
    const tx = this.getRequiredStore(tableName).transaction(
      tableName,
      'readwrite'
    );
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.clear();

    return new Promise((res, rej) => {
      req.onsuccess = () => {
        res();
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  public async clearMany(
    tables: Array<StorageTableConfig>,
    ignoreKeepOnClearAll: boolean
  ): Promise<void> {
    const allTablesBelongToMainStore = tables.every((t) => !t.keepOnClearAll);
    if (!ignoreKeepOnClearAll && allTablesBelongToMainStore) {
      assertNotNullOrUndefined(
        this.mainStore,
        'cannot clearMany in mainStore without mainStore'
      );
      await this.deleteStore(this.mainStore);
      this.mainStore = await this.initStore(this.mainStoreInfo, {
        needsDefaultAndMigrationTable: true
      });
    } else {
      await super.clearMany(tables, ignoreKeepOnClearAll);
    }
  }

  // ********** Private Functions **********

  private async openStore(storeName: string): Promise<IDBDatabase> {
    return new Promise((res, rej) => {
      const openHandler = indexedDB.open(storeName);

      openHandler.onsuccess = () => {
        res(openHandler.result);
      };

      openHandler.onerror = () => {
        rej();
      };
    });
  }

  private async initStore(
    localStoreInfo: StoreInfoConstraint,
    { needsDefaultAndMigrationTable }: InitStoreOptions
  ): Promise<IDBDatabase> {
    const storeName = localStoreInfo.name;
    const desiredTableNames = localStoreInfo.applicationTables.map(
      (t) => t.name
    );
    if (needsDefaultAndMigrationTable)
      desiredTableNames.push(
        storeInfo.defaultTableName,
        storeInfo.migrationsTableName
      );
    let store = await this.openStore(storeName);

    const existingTableNames = store.objectStoreNames;
    const tableNamesToCreate = _.difference(
      desiredTableNames,
      existingTableNames
    );
    if (tableNamesToCreate.length) {
      store = await this.createTables(tableNamesToCreate, store);
    }
    return store;
  }

  private async createTables(
    tableNames: Array<string>,
    oldStore: IDBDatabase
  ): Promise<IDBDatabase> {
    const oldVersion = oldStore.version;
    const storeName = oldStore.name;

    oldStore.close();

    return new Promise((res, rej) => {
      const newStoreOpenHandler = indexedDB.open(storeName, oldVersion + 1);

      newStoreOpenHandler.onupgradeneeded = (event) => {
        const db = newStoreOpenHandler.result;
        tableNames.forEach((tableName) => {
          try {
            db.createObjectStore(tableName);
          } catch (error) {
            if (error instanceof Error && error.name === 'ConstraintError') {
              console.warn(
                `The database ${storeName} has been upgraded from version ${event.oldVersion} to version ${event.newVersion} but the storage ${tableName} already exists.`
              );
            } else {
              throw error;
            }
          }
        });
      };

      newStoreOpenHandler.onsuccess = () => {
        res(newStoreOpenHandler.result);
      };

      newStoreOpenHandler.onerror = () => {
        rej();
      };
    });
  }

  private async deleteStore(store: IDBDatabase): Promise<void> {
    store.close();

    return new Promise((res, rej) => {
      const indexedDbDeletionRequest = indexedDB.deleteDatabase(store.name);
      indexedDbDeletionRequest.onsuccess = async () => {
        res();
      };
      indexedDbDeletionRequest.onerror = async (event: any) => {
        rej(
          new Error(
            `Could not delete indexed DB store ${store.name}.` +
              JSON.stringify(event)
          )
        );
      };
    });
  }

  private getRequiredStore(tableName: string): IDBDatabase {
    if (
      this.permanentStoreInfo.applicationTables.find(
        (t) => t.name === tableName
      )
    ) {
      assertNotNullOrUndefined(
        this.permanentStore,
        'no store is available, is the IndexedDBDataStorageStrategy initialized?'
      );
      return this.permanentStore;
    }
    assertNotNullOrUndefined(
      this.mainStore,
      'no store is available, is the IndexedDBDataStorageStrategy initialized?'
    );
    return this.mainStore;
  }
}

type InitStoreOptions = {
  needsDefaultAndMigrationTable: boolean;
};
