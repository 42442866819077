import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskDevice } from './types';

export class ProcessTaskDeviceRepository extends AppEntityRepository<EntityName.ProcessTaskDevice> {
  public getByProcessTaskIdWithoutSnapshots(
    processTaskId: string
  ): Array<ProcessTaskDevice> {
    return this.getAll().filter(
      (d) =>
        d.ownerProcessTaskId === processTaskId &&
        d.snapshotOfProcessTaskDeviceId == null
    );
  }
}
