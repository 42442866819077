import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { PersonDto } from './PersonDto';

export function createPersonEntityInfo<
  TEntity extends PersonDto<string, string>
>(
  options: CreateEntityInfoOptions<PersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Person,
  TEntity
> {
  return {
    entityName: EntityName.Person,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PersonDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        customId: {
          name: 'customId',
          defaultValue: null
        },
        company: {
          name: 'company',
          defaultValue: false
        },
        companyName: {
          name: 'companyName',
          defaultValue: null
        },
        salutation: {
          name: 'salutation',
          defaultValue: null
        },
        title: {
          name: 'title',
          defaultValue: null
        },
        firstName: {
          name: 'firstName',
          defaultValue: null
        },
        lastName: {
          name: 'lastName',
          defaultValue: null
        },
        streetName: {
          name: 'streetName',
          defaultValue: null
        },
        floor: {
          name: 'floor',
          defaultValue: null
        },
        staircase: {
          name: 'staircase',
          defaultValue: null
        },
        doorNumber: {
          name: 'doorNumber',
          defaultValue: null
        },
        zip: {
          name: 'zip',
          defaultValue: null
        },
        municipality: {
          name: 'municipality',
          defaultValue: null
        },
        addressAppendix: {
          name: 'addressAppendix',
          defaultValue: null
        },
        iban: {
          name: 'iban',
          defaultValue: null
        },
        bic: {
          name: 'bic',
          defaultValue: null
        },
        bankAccountHolder: {
          name: 'bankAccountHolder',
          defaultValue: null
        },
        bankName: {
          name: 'bankName',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        categoryName: {
          name: 'categoryName',
          defaultValue: null
        },
        vatIdentificationNumber: {
          name: 'vatIdentificationNumber',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
