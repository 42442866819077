import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskDevice } from '../../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  ProcessTaskInvoiceMapComputer,
  ProcessTaskInvoicesByProcessTaskDeviceId
} from '../../../../computedValues/computers/ProcessTaskInvoiceMapComputer/ProcessTaskInvoiceMapComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  ListItemRelatedEntityCountsAdapter,
  SubscribeOptions
} from '../ListItemRelatedEntityCountsAdapter';
import { ListItemRelatedEntityCountsUtils } from '../ListItemRelatedEntityCountsUtils/ListItemRelatedEntityCountsUtils';

export class ProcessTaskDeviceAdapter
  implements ListItemRelatedEntityCountsAdapter
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly processTaskDevice: ProcessTaskDevice;

  private processTaskInvoicesByProcessTaskDeviceId: ProcessTaskInvoicesByProcessTaskDeviceId =
    new Map();

  private offerCount: number = 0;
  private invoiceCount: number = 0;
  private appointmentCount: number = 0;

  constructor(options: ProcessTaskDeviceAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.processTaskDevice = options.processTaskDevice;
  }

  public subscribe(options: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const emitCounts = (): void => {
      options.onNewCounts([
        ListItemRelatedEntityCountsUtils.createOfferCount(this.offerCount),
        ListItemRelatedEntityCountsUtils.createAppointmentCount(
          this.appointmentCount
        ),
        ListItemRelatedEntityCountsUtils.createInvoiceCount(this.invoiceCount)
      ]);
    };

    const updateOfferCountAndEmitCounts = (): void => {
      this.updateOfferCount();
      emitCounts();
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      updateOfferCountAndEmitCounts
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskDevice,
      updateOfferCountAndEmitCounts
    );
    this.updateOfferCount();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      () => {
        this.updateAppointmentCount();
        emitCounts();
      }
    );
    this.updateAppointmentCount();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskInvoiceMapComputer,
        computeData: {},
        callback: (processTaskInvoiceMapComputeResult) => {
          this.processTaskInvoicesByProcessTaskDeviceId =
            processTaskInvoiceMapComputeResult.processTaskInvoicesByProcessTaskDeviceId;
          this.updatedInvoiceCount();
          emitCounts();
        }
      })
    );

    emitCounts();

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  private updateOfferCount(): void {
    const offerRelations =
      this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskDeviceId(
        this.processTaskDevice.id
      );
    const activeOfferRelations = offerRelations.filter((or) => {
      return (
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
          or.ownerProcessTaskId
        ).length > 0
      );
    });
    this.offerCount = activeOfferRelations.length;
  }

  private updateAppointmentCount(): void {
    const appointmentRelations =
      this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskDeviceId(
        this.processTaskDevice.id
      );
    this.appointmentCount = appointmentRelations.length;
  }

  private updatedInvoiceCount(): void {
    const invoices =
      this.processTaskInvoicesByProcessTaskDeviceId.get(
        this.processTaskDevice.id
      ) ?? [];
    this.invoiceCount = invoices.length;
  }
}

export type ProcessTaskDeviceAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  processTaskDevice: ProcessTaskDevice;
};
