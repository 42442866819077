import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { assertNotNullOrUndefined } from 'common/Asserts';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class ChecklistManageQuestionPicturesDialog {
  protected dialog: RecordItDialog | null = null;
  protected question: ProjectQuestion | null = null;

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open ChecklistManageQuestionRecommendationDialog without a RecordItDialog.'
    );

    this.question = options.question;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.question = null;
  }
}

type OpenOptions = {
  question: ProjectQuestion;
};
