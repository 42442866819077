import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationAuthorizationType } from '../../classes/EntityManager/entities/ProcessConfigurationAuthorizationType/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationAuthorizationTypesWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationAuthorizationTypesWidget,
        propertyName: 'processConfiguration'
      });
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      "can't ProcessConfigurationAuthorizationTypesWidget.handleAddButtonClick without processConfiguration"
    );

    this.entityManager.processConfigurationAuthorizationTypeRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId
    });
  }

  @computed(
    expression('processConfiguration.id'),
    model(EntityName.ProcessConfigurationAuthorizationType)
  )
  protected get authorizationTypes(): Array<ProcessConfigurationAuthorizationType> {
    if (!this.processConfiguration) {
      return [];
    }

    return this.entityManager.processConfigurationAuthorizationTypeRepository.getByProcessConfigurationId(
      this.processConfiguration.id
    );
  }
}
