import { AngleHelper } from './AngleHelper';

export class TransformHelper {
  /**
   * @param {string} matrix
   * @returns {number|null}
   */
  static getRotationFromTransformMatrix(matrix) {
    const values = this._getMatrixElements(matrix);
    if (!values) {
      return null;
    }

    return AngleHelper.radToDeg(Math.atan2(values.b, values.a));
  }

  /**
   * @param {string} matrix
   * @returns {TScale|null}
   */
  static getScaleFromTransformMatrix(matrix) {
    const values = this._getMatrixElements(matrix);
    if (!values) return null;

    const transformParameters = this._decomposeMatrix(values);

    return {
      scaleX: transformParameters.scaleX,
      scaleY: transformParameters.scaleY
    };
  }

  /**
   * @param {string} matrix
   * @returns {TMatrixElements|null}
   */
  static _getMatrixElements(matrix) {
    const values = this._parseMatrixString(matrix);
    if (!values) return null;

    return {
      a: parseFloat(values[1]),
      b: parseFloat(values[2]),
      c: parseFloat(values[3]),
      d: parseFloat(values[4]),
      tx: parseFloat(values[5]),
      ty: parseFloat(values[6])
    };
  }

  /**
   * @param {string} matrix
   * @returns {RegExpExecArray|null}
   */
  static _parseMatrixString(matrix) {
    return /matrix\(([^,]*), ([^,]*), ([^,]*), ([^,]*), ([^,]*), ([^,]*)\)/.exec(
      matrix
    );
  }

  /**
   * @param {TMatrixElements} matrix
   * @param {TPoint} point
   * @returns {TPoint}
   */
  static _deltaTransformPoint(matrix, point) {
    const dx = point.x * matrix.a + point.y * matrix.c + 0;
    const dy = point.x * matrix.b + point.y * matrix.d + 0;
    return { x: dx, y: dy };
  }

  /**
   * @see https://gist.github.com/2052247
   *
   * @param {TMatrixElements} matrix
   * @returns {TTransformParameters}
   */
  static _decomposeMatrix(matrix) {
    // calculate delta transform point
    var px = this._deltaTransformPoint(matrix, { x: 0, y: 1 });
    var py = this._deltaTransformPoint(matrix, { x: 1, y: 0 });

    // calculate skew
    var skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
    var skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

    return {
      translateX: matrix.tx,
      translateY: matrix.ty,
      scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
      scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
      skewX: skewX,
      skewY: skewY
    };
  }
}

/**
 * @typedef {Object} TMatrixElements
 * @property {number} a
 * @property {number} b
 * @property {number} c
 * @property {number} d
 * @property {number} tx
 * @property {number} ty
 */

/**
 * @typedef {Object} TScale
 * @property {number} scaleX
 * @property {number} scaleY
 */

/**
 * @typedef {Object} TSkew
 * @property {number} skewX
 * @property {number} skewY
 */

/**
 * @typedef {Object} TTranslate
 * @property {number} translateX
 * @property {number} translateY
 */

/**
 * @typedef {Object} TPoint
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef {TScale & TSkew & TTranslate} TTransformParameters
 */
