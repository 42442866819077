import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskAppointmentToUserEntityInfo } from 'common/Types/Entities/ProcessTaskAppointmentToUser/createProcessTaskAppointmentToUserEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskAppointmentToUser } from './types';

export const processTaskAppointmentToUserEntityInfo =
  createProcessTaskAppointmentToUserEntityInfo<ProcessTaskAppointmentToUser>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
