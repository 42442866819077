import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createTextBrickTemplateEntityInfo } from 'common/Types/Entities/TextBrickTemplate/createTextBrickTemplateEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { TextBrickTemplate } from './types';

export const textBrickTemplateEntityInfo =
  createTextBrickTemplateEntityInfo<TextBrickTemplate>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
