import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { DialogIconType } from 'common/Enums/DialogIconType';
import { RecurrenceCalculator } from 'common/Operations/Calendar/RecurrenceCalculator';
import {
  Calendar,
  OnChangedEvent
} from '../../../inputComponents/calendar/calendar';
import { ProcessTaskRecurringAppointment } from '../../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { TTextChangedEvent } from '../../../inputComponents/clickable-text-input/clickable-text-input';
import { ValueChangedEvent } from '../../../inputComponents/date-time-picker/date-time-picker';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../../dialogs/global-custom-dialog/global-custom-dialog';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToUser } from '../../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { ProcessTaskRecurringAppointmentHelper } from 'common/EntityHelper/ProcessTaskRecurringAppointmentHelper';
import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';

/**
 * @event on-appointment-split-off triggered whenever the user splits off a new appointment by changing the value of dateFrom, dateTo or name of a calendar entry
 */
@autoinject()
export class CorrespondingAppointmentsWidget {
  @bindable public appointment: ProcessTaskRecurringAppointment | null = null;

  protected calendar: Calendar | null = null;
  protected enabledDates: Array<Date> = [];
  protected selectedDate: Date | null = null;
  protected appointmentToUsers: Array<ProcessTaskAppointmentToUser> = [];
  protected processTaskAppointmentToUserOfSelectedDate: ProcessTaskAppointmentToUser | null =
    null;

  private readonly subscriptionManager: SubscriptionManager;

  private readonly element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public setSelectedDate(date: Date): void {
    // Timeout because we need to wait for the calendar viewmodel to become available
    setTimeout(() => {
      assertNotNullOrUndefined(
        this.calendar,
        'cannot set selectedDate without calendar'
      );

      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      this.calendar?.getFlatpickrInstance().setDate(date);

      this.selectedDate = date;
      this.updateProcessTaskAppointmentToUserOfSelectedDate();
    });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'appointment.dateFrom',
      this.updateEnabledDates.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'appointment.startTime',
      this.updateEnabledDates.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'appointment.durationInMs',
      this.updateEnabledDates.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'appointment.recurrence',
      this.updateEnabledDates.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointmentToUsersAndDates.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToUser,
      this.updateAppointmentToUsersAndDates.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateAppointmentToUsersAndDates(): void {
    this.updateAppointmentToUsers();
    this.updateEnabledDates();
  }

  private updateAppointmentToUsers(): void {
    if (!this.appointment) {
      this.appointmentToUsers = [];
      return;
    }

    const appointments =
      this.entityManager.processTaskAppointmentRepository.getByRecurringAppointmentId(
        this.appointment.id
      );
    this.appointmentToUsers = [];
    for (const appointment of appointments) {
      const appointmentToUsers =
        this.entityManager.processTaskAppointmentToUserRepository.getByProcessTaskAppointmentId(
          appointment.id
        );
      const sortedAppointmentToUsers =
        BaseEntityUtils.sortByCreationOrder(appointmentToUsers);
      const firstAppointment = sortedAppointmentToUsers[0];
      if (firstAppointment) {
        this.appointmentToUsers.push(firstAppointment);
      }
    }
    this.updateProcessTaskAppointmentToUserOfSelectedDate();
  }

  private updateProcessTaskAppointmentToUserOfSelectedDate(): void {
    if (!this.selectedDate) {
      this.processTaskAppointmentToUserOfSelectedDate = null;
      return;
    }

    this.processTaskAppointmentToUserOfSelectedDate =
      this.appointmentToUsers.find(
        (a) =>
          a.dateFrom && DateUtils.isOnSameDay(a.dateFrom, this.selectedDate)
      ) ?? null;
  }

  private updateEnabledDates(): void {
    if (!this.appointment || !this.calendar) return;

    const startDate =
      ProcessTaskRecurringAppointmentHelper.getCompleteStartDate(
        this.appointment
      );
    const endDate = ProcessTaskRecurringAppointmentHelper.getCompleteEndDate(
      this.appointment
    );
    const currentCalendarDate = DateUtils.createDate(
      1,
      this.calendar.getFlatpickrInstance().currentMonth + 1,
      this.calendar.getFlatpickrInstance().currentYear,
      0,
      0
    );
    const calculatorStartDate = DateUtils.getDateOneYearBefore(
      DateUtils.getStartDateOfMonth(currentCalendarDate)
    );
    const calculatorEndDate = DateUtils.getDateOneYearAfter(
      DateUtils.getEndDateOfMonth(currentCalendarDate)
    );

    if (startDate && endDate) {
      const calculator = new RecurrenceCalculator();
      const items = calculator.get(
        {
          startDate,
          endDate,
          recurrence: this.appointment.recurrence
        },
        calculatorStartDate,
        calculatorEndDate
      );

      // All dates enabled on the calendar are dates calculated from the recurrence ...
      const calculatedDates = items
        .map((i) => i.startDate)
        .filter((date) => !this.isExcludedDate(date));

      // ... plus dates of split off appointments.
      const splitOffDates = this.getAllDatesFromAppointmentToUsers();

      this.enabledDates = [...calculatedDates, ...splitOffDates];
    } else {
      this.enabledDates = [];
    }

    this.selectedDate = null;
    this.updateProcessTaskAppointmentToUserOfSelectedDate();
  }

  private getAllDatesFromAppointmentToUsers(): Array<Date> {
    return this.appointmentToUsers
      .map((i) => (i.dateFrom ? new Date(i.dateFrom) : null))
      .filter((date): date is Date => !!date);
  }

  private getProcessTaskAppointmentFromAppointmentToUser(
    appointmentToUser: ProcessTaskAppointmentToUser
  ): ProcessTaskAppointment | null {
    return this.entityManager.processTaskAppointmentRepository.getById(
      appointmentToUser.processTaskAppointmentId
    );
  }

  private isExcludedDate(date: Date): boolean {
    if (!this.appointment?.excludedDates) return false;
    return this.appointment.excludedDates.some((d) =>
      DateUtils.isOnSameDay(date, d)
    );
  }

  private createProcessTaskAppointment(name: string): ProcessTaskAppointment {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot createProcessTaskAppointment without appointment'
    );

    return this.entityManager.processTaskAppointmentRepository.create({
      coordinatorUserId: this.appointment.coordinatorUserId,
      processConfigurationStepId: this.appointment.processConfigurationStepId,
      name: name,
      recurringAppointmentId: this.appointment.id,
      ownerUserGroupId: this.appointment.ownerUserGroupId,
      ownerProcessTaskId: this.appointment.ownerProcessTaskId,
      ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId
    });
  }

  private createProcessTaskAppointmentToUser(
    processTaskAppointmentId: string,
    dateFrom: Date,
    dateTo: Date
  ): ProcessTaskAppointmentToUser {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot createProcessTaskAppointmentToUser without appointment'
    );

    return this.entityManager.processTaskAppointmentToUserRepository.create({
      processTaskAppointmentId: processTaskAppointmentId,
      userId:
        this.appointment.assignedUserId ?? this.appointment.coordinatorUserId,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      ownerUserGroupId: this.appointment.ownerUserGroupId,
      ownerProcessTaskId: this.appointment.ownerProcessTaskId,
      ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId
    });
  }

  private async splitOffSingleAppointment(
    appointmentName: string,
    dateFrom: Date,
    dateTo: Date
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot splitOffSingleAppointment without an appointment'
    );

    // Ask the user to confirm creating a new appointment
    try {
      await GlobalCustomDialog.open({
        textTk:
          'operationsComponents.editProcessTaskRecurringAppointmentWidget.correspondingAppointmentWidget.dialog.text',
        icon: DialogIconType.WARNING,
        buttons: [
          {
            textTk: 'general.yes',
            className: 'record-it-button-primary'
          },
          {
            textTk: 'general.no',
            type: ButtonType.CANCEL
          }
        ]
      });
    } catch (err) {
      if (err instanceof Error && err.message === 'DialogCanceled') {
        return;
      }
      throw err;
    }

    // Add the current selected date to the exclusion list
    this.appointment.excludedDates = this.appointment.excludedDates ?? [];
    this.appointment.excludedDates.push(dateFrom.toISOString());
    this.entityManager.processTaskRecurringAppointmentRepository.update(
      this.appointment
    );

    // Create the new appointment
    const processTaskAppointment =
      this.createProcessTaskAppointment(appointmentName);
    this.createProcessTaskAppointmentToUser(
      processTaskAppointment.id,
      dateFrom,
      dateTo
    );
    DomEventHelper.fireEvent<OnAppointmentSplitOffEvent>(this.element, {
      name: 'on-appointment-split-off',
      detail: { processTaskAppointment }
    });
  }

  protected appointmentChanged(): void {
    // Timeout because we need to wait for the calendar viewmodel to become available
    setTimeout(() => {
      this.updateAppointmentToUsers();
      this.updateEnabledDates();
    });
  }

  protected handleCalendarCurrentMonthChanged(): void {
    this.updateEnabledDates();
  }

  protected handleSelectedDateChanged(evt: OnChangedEvent): void {
    this.selectedDate = evt.detail.dates[0] ?? null;
    this.updateProcessTaskAppointmentToUserOfSelectedDate();
  }

  protected handleAppointmentTitleChanged(evt: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot handleAppointmentTitleChanged without appointment'
    );
    assertNotNullOrUndefined(
      this.selectedDate,
      'cannot handleAppointmentTitleChanged without selectedDate'
    );

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      const relatedProcessTaskAppointment =
        this.getProcessTaskAppointmentFromAppointmentToUser(
          this.processTaskAppointmentToUserOfSelectedDate
        );
      if (relatedProcessTaskAppointment) {
        relatedProcessTaskAppointment.name = evt.detail.value as string | null;
        this.entityManager.processTaskAppointmentRepository.update(
          relatedProcessTaskAppointment
        );
      }
    } else {
      const dateFrom = this.dateFrom;
      const dateTo = this.dateTo;

      if (dateFrom && dateTo) {
        void this.splitOffSingleAppointment(
          evt.detail.value as string,
          dateFrom,
          dateTo
        );
      }
    }
  }

  protected handleAppointmentDateFromChanged(evt: ValueChangedEvent): void {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot handleAppointmentDateFromChanged without appointment'
    );
    assertNotNullOrUndefined(
      this.selectedDate,
      'cannot handleAppointmentDateFromChanged without selectedDate'
    );

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      this.processTaskAppointmentToUserOfSelectedDate.dateFrom =
        evt.detail.value;
      this.entityManager.processTaskAppointmentToUserRepository.update(
        this.processTaskAppointmentToUserOfSelectedDate
      );
    } else {
      const dateTo = this.dateTo;

      if (dateTo && evt.detail.value) {
        void this.splitOffSingleAppointment(
          this.appointment.name ?? '',
          new Date(evt.detail.value),
          dateTo
        );
      }
    }
  }

  protected handleAppointmentDateToChanged(evt: ValueChangedEvent): void {
    assertNotNullOrUndefined(
      this.appointment,
      'cannot handleAppointmentDateToChanged without appointment'
    );
    assertNotNullOrUndefined(
      this.selectedDate,
      'cannot handleAppointmentDateToChanged without selectedDate'
    );

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      this.processTaskAppointmentToUserOfSelectedDate.dateTo = evt.detail.value;
      this.entityManager.processTaskAppointmentToUserRepository.update(
        this.processTaskAppointmentToUserOfSelectedDate
      );
    } else {
      const dateFrom = this.dateFrom;

      if (dateFrom && evt.detail.value) {
        void this.splitOffSingleAppointment(
          this.appointment.name ?? '',
          dateFrom,
          new Date(evt.detail.value)
        );
      }
    }
  }

  @computedFrom('appointment.name', 'selectedDate', 'appointmentToUsers')
  protected get appointmentName(): string | null {
    if (!this.selectedDate) return null;

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      const relatedProcessTaskAppointment =
        this.getProcessTaskAppointmentFromAppointmentToUser(
          this.processTaskAppointmentToUserOfSelectedDate
        );
      return relatedProcessTaskAppointment?.name ?? null;
    } else {
      return this.appointment?.name ?? null;
    }
  }

  @computedFrom('appointment.startTime', 'selectedDate', 'appointmentToUsers')
  protected get dateFrom(): Date | null {
    if (!this.selectedDate) return null;

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      return this.processTaskAppointmentToUserOfSelectedDate.dateFrom
        ? new Date(this.processTaskAppointmentToUserOfSelectedDate.dateFrom)
        : null;
    } else {
      if (!this.appointment?.startTime) {
        return null;
      }

      const time = new Date(this.appointment.startTime);
      return DateUtils.mergeDateAndTime(this.selectedDate, time);
    }
  }

  @computedFrom(
    'dateFrom',
    'appointment.durationInMs',
    'selectedDate',
    'appointmentToUsers'
  )
  protected get dateTo(): Date | null {
    if (!this.selectedDate) return null;

    if (this.processTaskAppointmentToUserOfSelectedDate) {
      return this.processTaskAppointmentToUserOfSelectedDate.dateTo
        ? new Date(this.processTaskAppointmentToUserOfSelectedDate.dateTo)
        : null;
    } else {
      if (
        !this.selectedDate ||
        !this.dateFrom ||
        !this.appointment?.durationInMs
      ) {
        return null;
      }

      return DateUtils.getDatePlusMilliseconds(
        this.dateFrom,
        this.appointment.durationInMs
      );
    }
  }
}

export type OnAppointmentSplitOffEvent = NamedCustomEvent<
  'on-appointment-split-off',
  { processTaskAppointment: ProcessTaskAppointment }
>;
