import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationEntityInfo } from 'common/Types/Entities/ProcessConfiguration/createProcessConfigurationEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfiguration } from './types';

export const processConfigurationEntityInfo =
  createProcessConfigurationEntityInfo<ProcessConfiguration>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
