/**
 * adding a new module:
 * * define an enum value ${nameofthemodule}
 * * add a module configuration to the MODULE_NAME_TO_CONFIGURATION_MAP
 */

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

export enum ModuleName {
  BASIC = 'basic',
  VIA = 'via',
  B1300 = 'b1300',
  INSPECT = 'inspect',
  OPERATIONS = 'operations',
  OPERATIONS_FIELD_USE = 'operationsFieldUse',
  GENERAL = 'general',
  CHECKLIST = 'checklist',
  KUK = 'KuK' // value is case sensitive here so module permission can be named `canUseKuK`
}

export class RecordItModuleHelper {
  public static MODULE_NAME_TO_CONFIGURATION_MAP: Record<
    ModuleName,
    ModuleConfiguration
  > = {
    [ModuleName.BASIC]: {
      moduleName: ModuleName.BASIC,
      fullDisplayNameTk: 'aureliaComponents.mainNavigation.basicMainMenuLabel',
      shortDisplayName: 'basic',
      overviewPageRouteName: 'edit_objects',
      projectType: ProjectType.BASIC,
      projectViewPage: 'project'
    },
    [ModuleName.VIA]: {
      moduleName: ModuleName.VIA,
      fullDisplayNameTk: 'aureliaComponents.mainNavigation.viaMainMenuLabel',
      shortDisplayName: 'via',
      overviewPageRouteName: 'edit_objects',
      projectType: ProjectType.GALLERY,
      projectViewPage: null
    },
    [ModuleName.B1300]: {
      moduleName: ModuleName.B1300,
      fullDisplayNameTk: 'aureliaComponents.mainNavigation.b1300MainMenuLabel',
      shortDisplayName: 'B1300/B1301',
      overviewPageRouteName: 'edit_objects',
      projectType: ProjectType.B1300,
      projectViewPage: 'edit_b1300_project'
    },
    [ModuleName.INSPECT]: {
      moduleName: ModuleName.INSPECT,
      fullDisplayNameTk:
        'aureliaComponents.mainNavigation.inspectMainMenuLabel',
      shortDisplayName: 'inspect',
      overviewPageRouteName: 'edit_objects',
      projectType: ProjectType.INSPECT,
      projectViewPage: 'edit_inspect_project'
    },
    [ModuleName.OPERATIONS]: {
      moduleName: ModuleName.OPERATIONS,
      fullDisplayNameTk:
        'aureliaComponents.mainNavigation.operationsMainMenuLabel',
      shortDisplayName: 'operations',
      overviewPageRouteName: 'edit_process_task_groups',
      projectType: null,
      projectViewPage: null
    },
    [ModuleName.OPERATIONS_FIELD_USE]: {
      moduleName: ModuleName.OPERATIONS_FIELD_USE,
      fullDisplayNameTk:
        'aureliaComponents.mainNavigation.operationsFieldUseMainMenuLabel',
      shortDisplayName: 'field use',
      overviewPageRouteName: 'show_user_calendar',
      projectType: null,
      projectViewPage: null
    },
    [ModuleName.GENERAL]: {
      moduleName: ModuleName.GENERAL,
      fullDisplayNameTk:
        'aureliaComponents.mainNavigation.generalMainMenuLabel',
      shortDisplayName: 'Allgemein',
      overviewPageRouteName: 'edit_objects',
      projectType: null,
      projectViewPage: null
    },
    [ModuleName.CHECKLIST]: {
      moduleName: ModuleName.CHECKLIST,
      fullDisplayNameTk: 'checklistComponents.moduleTitle',
      shortDisplayName: 'Checklist',
      overviewPageRouteName: 'edit_objects',
      projectType: null,
      projectViewPage: null
    },
    [ModuleName.KUK]: {
      moduleName: ModuleName.KUK,
      fullDisplayNameTk: 'kukComponents.moduleTitle',
      shortDisplayName: 'KuK',
      overviewPageRouteName: 'edit_objects',
      projectType: null,
      projectViewPage: null
    }
  };

  /**
   * returns null if the module has no specific overviewPageRoute
   */
  public static getOverviewPageRouteForModuleName(
    moduleName: ModuleName
  ): string | null {
    const config = this.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
    assertNotNullOrUndefined(config, 'module is not defined');

    return config.overviewPageRouteName;
  }

  public static getFullDisplayNameTkForModuleName(
    moduleName: ModuleName
  ): string {
    const config = this.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
    assertNotNullOrUndefined(config, 'module is not defined');

    return config.fullDisplayNameTk;
  }

  public static getShortDisplayNameForModuleName(
    moduleName: ModuleName
  ): string {
    const config = this.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
    assertNotNullOrUndefined(config, 'module is not defined');

    return config.shortDisplayName;
  }

  public static getProjectTypeForModuleName(
    moduleName: ModuleName
  ): ProjectType | null {
    const config = this.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
    assertNotNullOrUndefined(config, 'module is not defined');

    return config.projectType;
  }

  public static getProjectViewPageForModuleName(
    moduleName: ModuleName
  ): string | null {
    const config = this.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
    assertNotNullOrUndefined(config, 'module is not defined');

    return config.projectViewPage;
  }
}

type ModuleConfiguration = {
  moduleName: string;
  fullDisplayNameTk: string;
  shortDisplayName: string;
  overviewPageRouteName: string | null;
  projectType: ProjectType | null;
  projectViewPage: string | null;
};
