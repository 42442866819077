import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createUserRoleToUserEntityInfo } from 'common/Types/Entities/UserRoleToUser/createUserRoleToUserEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { UserRoleToUser } from './types';

export const userRoleToUserEntityInfo =
  createUserRoleToUserEntityInfo<UserRoleToUser>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
