import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationFollowUpAppointment } from '../../../classes/EntityManager/entities/ProcessConfigurationFollowUpAppointment/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationFollowUpAppointmentsWidgetListItem {
  @bindable()
  public processConfigurationFollowUpAppointment: ProcessConfigurationFollowUpAppointment | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationFollowUpAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationFollowUpAppointment];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationFollowUpAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationFollowUpAppointment,
        context: this as ProcessConfigurationFollowUpAppointmentsWidgetListItem,
        propertyName: 'processConfigurationFollowUpAppointment'
      });
  }

  protected handleFollowUpAppointmentChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationFollowUpAppointment,
      "can't ProcessConfigurationFollowUpAppointmentsWidget.handleFollowUpAppointmentChanged without processConfigurationFollowUpAppointment"
    );

    this.entityManager.processConfigurationFollowUpAppointmentRepository.update(
      this.processConfigurationFollowUpAppointment
    );
  }

  protected handleDeleteFollowUpAppointmentClick(): void {
    const processConfigurationFollowUpAppointment =
      this.processConfigurationFollowUpAppointment;
    assertNotNullOrUndefined(
      processConfigurationFollowUpAppointment,
      "can't ProcessConfigurationFollowUpAppointmentsWidget.handleFollowUpAppointmentChanged without processConfigurationFollowUpAppointment"
    );

    void Dialogs.deleteEntityDialog(
      processConfigurationFollowUpAppointment
    ).then(() => {
      this.entityManager.processConfigurationFollowUpAppointmentRepository.delete(
        processConfigurationFollowUpAppointment
      );
    });
  }
}

export class OffsetValueConverter {
  private static msPerDay = 24 * 60 * 60 * 1000;

  public toView(offset: number | null): number {
    return (offset || 0) / OffsetValueConverter.msPerDay;
  }

  public fromView(days: number): number {
    return days * OffsetValueConverter.msPerDay;
  }
}
