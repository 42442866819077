import {
  GalleryThingPictureCaptureWidget,
  GalleryThingPictureCaptureWidgetStartOptions
} from '../../galleryThing/gallery-thing-picture-capture-widget/gallery-thing-picture-capture-widget';

export class GalleryThingPictureCaptureService {
  public capturePictureWithCaptureWidget(
    pictureData: GalleryThingPictureCaptureWidgetStartOptions,
    projectId?: string
  ): void {
    void GalleryThingPictureCaptureWidget.start({
      thing: pictureData.thing,
      projectId: projectId,
      regionId: pictureData.regionId,
      properties: pictureData.properties || []
    });
  }
}
