import { ValueCalculationConfigType } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  ManageValueCalculationConfigDefinitionsWidgetAdapter,
  ManageValueCalculationConfigDefinitionsWidgetAdapterDeleteOptions,
  ManageValueCalculationConfigDefinitionsWidgetAdapterSubscribeOptions
} from '../ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetAdapter';
import { ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCache } from '../ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCache';
import { ProjectValueCalculationConfig } from '../../../../classes/EntityManager/entities/ValueCalculationConfig/types';
import { ProjectValueCalculationConfigDefinitionHandle } from '../../../value-calculation-config-definition-widget/ValueCalculationConfigDefinitionWidgetHandle/ProjectValueCalculationConfigDefinitionHandle/ProjectValueCalculationConfigDefinitionHandle';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';

export class ManageValueCalculationConfigDefinitionsWidgetProjectAdapter
  implements
    ManageValueCalculationConfigDefinitionsWidgetAdapter<ProjectValueCalculationConfig>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly handleCache: ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCache<
    ProjectValueCalculationConfig,
    ProjectValueCalculationConfigDefinitionHandle
  >;
  private readonly thing: Thing;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(options: ManageDefinedPropertiesWidgetThingAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.thing = options.thing;

    this.handleCache =
      new ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCache({
        createValueCalculationConfigDefinitionHandle: ({
          valueCalculationConfigDefinition
        }) => {
          return new ProjectValueCalculationConfigDefinitionHandle({
            entityManager: options.entityManager,
            permissionsService: options.permissionsService,
            valueCalculationConfig: valueCalculationConfigDefinition
          });
        }
      });

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Thing,
        entity: options.thing
      });
  }

  public subscribe({
    setValueCalculationConfigDefinitionHandles,
    setReportTypes,
    setCanCreateValueCalculationConfigDefinitions
  }: ManageValueCalculationConfigDefinitionsWidgetAdapterSubscribeOptions<ProjectValueCalculationConfig>): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updateValueCalculationConfigDefinitions } =
      this.handleCache.createUpdateValueCalculationConfigDefinitionsFunction({
        setValueCalculationConfigDefinitionHandles,
        getValueCalculationConfigDefinitions: () => {
          return this.entityManager.valueCalculationConfigRepository.getByThingIdAndType(
            this.thing.id,
            ValueCalculationConfigType.PROJECT
          );
        }
      });

    const updateReportTypes = (): void => {
      setReportTypes(this.entityManager.reportTypeRepository.getAll());
    };
    subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      updateReportTypes
    );
    updateReportTypes();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ValueCalculationConfig,
      updateValueCalculationConfigDefinitions
    );
    updateValueCalculationConfigDefinitions();

    subscriptionManager.addDisposable(this.permissionsHandle.subscribe());

    subscriptionManager.subscribeToExpression(
      this.permissionsHandle,
      'canAdministerValueCalculations',
      () => {
        setCanCreateValueCalculationConfigDefinitions(
          this.permissionsHandle.canAdministerValueCalculations
        );
      }
    );
    setCanCreateValueCalculationConfigDefinitions(
      this.permissionsHandle.canAdministerValueCalculations
    );

    return subscriptionManager.toDisposable();
  }

  public createValueCalculationConfigDefinition(): ProjectValueCalculationConfigDefinitionHandle {
    const valueCalculationConfig =
      this.entityManager.valueCalculationConfigRepository.create({
        ownerThingId: this.thing.id,
        reportTypeId: null,
        type: ValueCalculationConfigType.PROJECT,
        ownerUserGroupId: this.thing.ownerUserGroupId
      }) as ProjectValueCalculationConfig;

    return this.handleCache.createCachedValueCalculationConfigDefinitionHandle({
      valueCalculationConfigDefinition: valueCalculationConfig
    });
  }

  public async deleteValueCalculationConfigDefinition({
    valueCalculationConfigDefinition
  }: ManageValueCalculationConfigDefinitionsWidgetAdapterDeleteOptions<ProjectValueCalculationConfig>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      valueCalculationConfigDefinition,
      EntityName.ValueCalculationConfig
    ).then(() => {
      this.entityManager.valueCalculationConfigRepository.delete(
        valueCalculationConfigDefinition
      );
    });
  }
}

export type ManageDefinedPropertiesWidgetThingAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  thing: Thing;
};
