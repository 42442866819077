import { bindable } from 'aurelia-framework';

/**
 * @attribute flex-layout scales the content automaticall with the width of the settings bar
 */
export class DrawingSettingsBar {
  @bindable availableSizes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20];

  @bindable availableColors = [
    '#000', // black
    '#888', // grey
    '#fff', // white
    '#f00', // red
    '#f57d00', // recordIT
    '#ff0', // yellow
    '#0f0', // green
    '#00f' // blue
  ];

  /** @type {Array<Array<number>>} */
  @bindable availableStrokeDashArrays = [[0], [1], [2, 1], [3, 1, 1, 1]];

  @bindable availableGridSizes = [0, 10, 25, 50, 75, 100];

  @bindable size = 5;
  @bindable color = '#000';
  @bindable gridSize = 0;
  @bindable strokeDashArray = [0];

  /** @type {TooltipContent|null} */
  _colorSelectionTooltipContent = null;
  /** @type {TooltipContent|null} */
  _sizeSelectionTooltipContent = null;
  /** @type {TooltipContent|null} */
  _gridSizeSelectionTooltipContent = null;
  /** @type {TooltipContent|null} */
  _strokeDashArraySelectionTooltipContent = null;

  /**
   *
   * @param {number} size
   */
  setSize(size) {
    this.size = size;

    if (
      this._sizeSelectionTooltipContent &&
      this._sizeSelectionTooltipContent.isOpen()
    ) {
      this._sizeSelectionTooltipContent.close();
    }
  }

  /**
   *
   * @param {string} color
   */
  setColor(color) {
    this.color = color;

    if (
      this._colorSelectionTooltipContent &&
      this._colorSelectionTooltipContent.isOpen()
    ) {
      this._colorSelectionTooltipContent.close();
    }
  }

  /**
   *
   * @param {number} gridSize
   */
  setGridSize(gridSize) {
    this.gridSize = gridSize;

    if (
      this._gridSizeSelectionTooltipContent &&
      this._gridSizeSelectionTooltipContent.isOpen()
    ) {
      this._gridSizeSelectionTooltipContent.close();
    }
  }

  /**
   * @param {Array<number>} strokeDashArray
   */
  setStrokeDashArray(strokeDashArray) {
    this.strokeDashArray = strokeDashArray;

    if (
      this._strokeDashArraySelectionTooltipContent &&
      this._strokeDashArraySelectionTooltipContent.isOpen()
    ) {
      this._strokeDashArraySelectionTooltipContent.close();
    }
  }

  /**
   * @param {number} gridSize
   * @returns {string}
   * @private
   */
  _getGridSizeName(gridSize) {
    return gridSize > 0 ? gridSize.toString() : 'Kein Raster';
  }

  /**
   * @param {TooltipContent} tooltipContent
   * @param {MouseEvent} event
   * @private
   */
  _handleOpenDropdownClick(tooltipContent, event) {
    tooltipContent.ignoreClickEvent(event);
    if (!tooltipContent.isOpen()) {
      tooltipContent.open();
    }
  }
}

/** @typedef {import('../../aureliaComponents/tooltip-content/tooltip-content').TooltipContent} TooltipContent */
