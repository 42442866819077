import { autoinject, bindable } from 'aurelia-framework';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ChecklistProject } from '../../classes/EntityManager/entities/Project/types';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EnterProjectClickedEvent } from '../checklist-project-list-item/checklist-project-list-item';

/**
 * @event project-clicked - triggered when the user tries to open a project
 */
@autoinject()
export class ChecklistProjectList {
  @bindable public thing: Thing | null = null;

  /**
   * Whether to display hours and minutes from the project name in project list items.
   */
  @bindable public showTime: boolean = false;

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager
  ) {
    this.element = element as HTMLElement;
  }

  protected handleEnterProjectClick(evt: EnterProjectClickedEvent): void {
    DomEventHelper.fireEvent<EnterProjectClickedEvent>(this.element, {
      name: evt.type,
      detail: evt.detail
    });
  }

  @computed(expression('thing'), model(EntityName.Project))
  protected get projects(): Array<ChecklistProject> | null {
    if (!this.thing) return [];

    return this.entityManager.projectRepository
      .getByThingId(this.thing.id)
      .filter(
        (p): p is ChecklistProject => p.projectType === ProjectType.CHECKLIST
      );
  }
}
