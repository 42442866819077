/**
 * helps the embedded-camera to restore it's state when the app got suspended while taking a picture
 *
 * call init as soon as as the embedded-camera is set up
 */

import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';

export class NativeCameraSuspendHelper<T> {
  private static STATE_STORAGE_NAME = 'NativeCameraSuspendHelper_state';

  private resumeListener: PluginListenerHandle | null = null;

  constructor(
    private options: {
      successCallback: (fileUri: string) => void;
      getCameraOptions: () => T;
      setCameraOptions: (options: T) => void;
    }
  ) {}

  public async init(): Promise<void> {
    this.resumeListener = await App.addListener(
      'appRestoredResult',
      (event) => {
        if (event.pluginId === 'Camera' && event.success && event.data) {
          void this.restoreState(event.data.path);
        }
      }
    );
  }

  public async deInit(): Promise<void> {
    if (this.resumeListener) await this.resumeListener.remove();
  }

  public async saveState(): Promise<void> {
    const cameraOptions = this.options.getCameraOptions();
    await DataStorageHelper.setItem(
      NativeCameraSuspendHelper.STATE_STORAGE_NAME,
      cameraOptions
    );
  }

  public async deleteState(): Promise<void> {
    await DataStorageHelper.setItem(
      NativeCameraSuspendHelper.STATE_STORAGE_NAME,
      null
    );
  }

  public async restoreState(fileUri: string): Promise<void> {
    const cameraOptions = await DataStorageHelper.getItem(
      NativeCameraSuspendHelper.STATE_STORAGE_NAME
    );

    if (!cameraOptions) {
      return;
    }

    this.options.setCameraOptions(cameraOptions);
    this.options.successCallback(fileUri);
  }
}
