import { Utils } from '../../Utils/Utils';

export class Uint8ArrayReader {
  public readDataUrl(dataUrl: string): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(new Uint8Array(reader.result));
        } else {
          reject(
            new Error(`received wrong result type: "${typeof reader.result}"`)
          );
        }
      };

      reader.onerror = () => {
        reject(new Error(reader.error?.message));
      };

      reader.onabort = () => {
        reject(new Error('reader aborted'));
      };

      reader.readAsArrayBuffer(Utils.b64toBlob(dataUrl));
    });
  }
}
