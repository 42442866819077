export class GeneralFileHelper {
  static MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

  /**
   * @param {Object} generalFileInfo
   * @param {idType|null} [generalFileInfo.ownerProcessTaskGroupId]
   * @param {idType|null} [generalFileInfo.ownerProjectId]
   * @param {idType|null} [generalFileInfo.ownerUserGroupId]
   * @template idType
   *
   * @returns {TMainEntityInfo<idType>}
   */
  static getMainEntityInfo(generalFileInfo) {
    if (generalFileInfo.ownerProcessTaskGroupId) {
      return {
        id: generalFileInfo.ownerProcessTaskGroupId,
        folderName: 'ProcessTaskGroup'
      };
    } else if (generalFileInfo.ownerProjectId) {
      return {
        id: generalFileInfo.ownerProjectId,
        folderName: 'project'
      };
    } else if (generalFileInfo.ownerUserGroupId) {
      return {
        id: generalFileInfo.ownerUserGroupId,
        folderName: 'UserGroup'
      };
    } else {
      throw new Error(
        `The general file info passed has no main entity! ${JSON.stringify(generalFileInfo)}`
      );
    }
  }

  /**
   * @param {string|null} name
   * @param {string|null} extension
   */
  static getFullFileName(name, extension) {
    const parts = [];

    if (name) {
      parts.push(name);
    }

    if (extension) {
      parts.push(extension);
    }

    return parts.join('.');
  }
}

/**
 * @typedef {Object} TMainEntityInfo
 * @property {idType} id
 * @property {string} folderName
 * @template {any} idType
 */
