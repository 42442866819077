import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { DefectComment } from '../../../../classes/EntityManager/entities/DefectComment/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { defectCommentEntityInfo } from '../../../../classes/EntityManager/entities/DefectComment/defectCommentEntityInfo';

export class DefectCommentAdapter implements EntityAdapter<DefectComment> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> = new Set();

  constructor(options: DefectCommentAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(defectComment: DefectComment): boolean {
    return this.entityIsEditableUserGroup(defectComment);
  }

  public canEditField(defectComment: DefectComment): boolean {
    return this.entityIsEditableUserGroup(defectComment);
  }

  public canEditGeneralFiles(defectComment: DefectComment): boolean {
    return this.entityIsEditableUserGroup(defectComment);
  }

  public canEditPictures(defectComment: DefectComment): boolean {
    return this.entityIsEditableUserGroup(defectComment);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.DefectComment,
    DefectComment
  > {
    return defectCommentEntityInfo;
  }

  private entityIsEditableUserGroup(entity: DefectComment): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type DefectCommentAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
