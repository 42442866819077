import { inject, bindable } from 'aurelia-framework';

import { SocketService } from '../../services/SocketService';

import { DateUtils } from '../../../../common/src/DateUtils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event edit-report-type-dialog-closed
 */
@inject(AppEntityManager, SocketService, SubscriptionManagerService)
export class EditReportTypeDialog {
  @bindable reportType;
  @bindable editable = false;

  _dialog;
  /**@type {HTMLElement}*/
  _domElement;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SocketService} socketService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, socketService, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._socketService = socketService;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.addDisposable(
      this._socketService.registerBinding('isConnected', (isConnected) => {
        this._isConnected = isConnected;
      })
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  open() {
    this._dialog.open();
  }

  close() {
    this._dialog.close();
  }

  _handleReportTypeChanged() {
    this._entityManager.reportTypeRepository.update(this.reportType);
  }

  _formatToDateString(date) {
    return DateUtils.formatToDateString(date);
  }

  _formatToTimeString(date) {
    return DateUtils.formatToTimeString(date);
  }

  _getUsergroupNameById(usergroupId) {
    const group = this._entityManager.userGroupRepository.getById(usergroupId);
    return group ? group.name : '';
  }

  _handleRecordItDialogClosed() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-report-type-dialog-closed',
      detail: null
    });
  }
}
