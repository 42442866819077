import { z } from 'zod';

import { SettingsUnion, SettingsZodSchema } from 'common/Settings/Settings';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { TextChangedEvent } from '../../inputComponents/json-input/json-input';

export class EditSettingDialog {
  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected dialog: RecordItDialog | null = null;

  protected options: Options | null = null;

  protected settingValueString = '';
  protected zodType: z.ZodType | null = null;

  public open(options: Options): void {
    this.options = options;

    this.settingValueString = JSON.stringify(this.options.setting.value);
    this.zodType = SettingsZodSchema.shape[this.options.setting.key];

    this.dialog?.open();
  }

  protected async handleTextChanged(event: TextChangedEvent): Promise<void> {
    this.options?.onSettingChanged({
      key: this.options.setting.key,
      value: event.detail
    });
  }
}

type Options = {
  setting: SettingsUnion;
  onSettingChanged: (setting: SettingsUnion) => void;
};
