import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { RequestWithStatus, Status } from '../../classes/RequestWithStatus';
import { expression } from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';

export class RequestWithStatusWidget {
  @bindable public requestWithStatus: RequestWithStatus | null = null;
  @bindable public fadeOutFinalStatusTimeout: number | null = 3000;

  private statusIcon: HTMLElement | null = null;
  private showHideAnimator: ShowHideAnimator | null = null;

  protected attached(): void {
    assertNotNullOrUndefined(
      this.statusIcon,
      "can't RequestWithStatusWidget.attached without a statusIcon"
    );
    this.showHideAnimator = new ShowHideAnimator(this.statusIcon);
  }

  @watch(expression('requestWithStatus.status'))
  protected fadeOutFinalState(): void {
    if (this.fadeOutFinalStatusTimeout === null) return;

    this.showHideAnimator?.resetHiddenStatus();

    if (this.requestWithStatus?.status === Status.OK) {
      setTimeout(() => {
        void this.showHideAnimator?.fadeOut();
      }, this.fadeOutFinalStatusTimeout);
    }
  }
}
