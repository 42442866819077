import { ProcessTaskLogEntryLogDataConverterType } from 'common/processTaskLogEntryActions';

import { IdLookupLogDataConverter } from './IdLookupLogDataConverter';
import { ProcessConfigurationActionStatusDataConverter } from './ProcessConfigurationActionStatusDataConverter';
import { PersonIdLogDataConverter } from './PersonIdLogDataConverter';
import { AbstractLogDataConverter } from './AbstractLogDataConverter';
import { ProcessTaskChecklistEntryIdLogDataConverter } from './ProcessTaskChecklistEntryIdLogDataConverter';
import { DateDataConverter } from './DateDataConverter';
import { TimespanDataConverter } from './TimespanDataConverter';
import { ProcessTaskGroupAssigneeChangedDataChangeFormatter } from './ProcessTaskGroupAssigneeChangedDataChangeFormatter';
import { DisplayNameOfEntityConverter } from './DisplayNameOfEntityConverter';
import { MostRecentSubEntityDisplayNameAtLogTime } from './MostRecentSubEntityDisplayNameAtLogTime';

export const logDataConverterTypeToConstructorMap: Record<
  ProcessTaskLogEntryLogDataConverterType,
  new (...args: Array<any>) => AbstractLogDataConverter
> = {
  [ProcessTaskLogEntryLogDataConverterType.DISPLAY_NAME_OF_ENTITY]:
    DisplayNameOfEntityConverter,
  [ProcessTaskLogEntryLogDataConverterType.ID_LOOKUP]: IdLookupLogDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.MOST_RECENT_SUB_ENTITY_DISPLAY_NAME_OF_ENTITY]:
    MostRecentSubEntityDisplayNameAtLogTime,
  [ProcessTaskLogEntryLogDataConverterType.PROCESS_CONFIGURATION_ACTION_STATUS]:
    ProcessConfigurationActionStatusDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.PERSON_ID]: PersonIdLogDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.PROCESS_TASK_CHECKLIST_ENTRY_ID]:
    ProcessTaskChecklistEntryIdLogDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.DATE]: DateDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.TIME_SPAN]: TimespanDataConverter,
  [ProcessTaskLogEntryLogDataConverterType.PROCESS_TASK_GROUP_ASSIGNEE_CHANGED_DATA_CHANGE_FORMATTER]:
    ProcessTaskGroupAssigneeChangedDataChangeFormatter
};
