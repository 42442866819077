import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExportType } from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';

import { SocketService } from '../../services/SocketService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

@autoinject()
export class GalleryThingExportButtonWidget {
  @bindable public thing: Thing | null = null;

  private subscriptionManager: SubscriptionManager;

  protected isOnline = false;

  protected chooseGalleryThingExportTypeDropdownModel: TooltipContent | null =
    null;

  protected ExportType = ExportType;

  constructor(
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleExportGalleryThingClick(exportType: ExportType): void {
    assertNotNullOrUndefined(
      this.chooseGalleryThingExportTypeDropdownModel,
      'cannot handleExportGalleryThingCLick without exportTypeDropdownModel'
    );

    assertNotNullOrUndefined(this.thing, 'cannot export without a thing');

    this.chooseGalleryThingExportTypeDropdownModel.close();

    this.socketService.exportGalleryThing(
      {
        exportType,
        thingId: this.thing.id,
        pictureFilter: {
          isActive: false,
          ownerUserGroupId: this.thing.ownerUserGroupId,
          thingId: this.thing.id
        }
      },
      () => {}
    );
  }

  protected handleExportGalleryThingPicturesAsZipClick(): void {
    assertNotNullOrUndefined(
      this.chooseGalleryThingExportTypeDropdownModel,
      'cannot handleExportGalleryThingPicturesAsZipCLick without exportTypeDropdownModel'
    );

    assertNotNullOrUndefined(this.thing, 'cannot export without a thing');

    this.chooseGalleryThingExportTypeDropdownModel.close();

    this.socketService.exportPicturesOfGalleryThingAsZipFile(
      {
        thingId: this.thing.id,
        pictureFilter: {
          isActive: false,
          ownerUserGroupId: this.thing.ownerUserGroupId,
          thingId: this.thing.id
        }
      },
      () => {}
    );
  }
}
