import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskAppointmentToUser } from './types';

export class ProcessTaskAppointmentToUserRepository extends AppEntityRepository<EntityName.ProcessTaskAppointmentToUser> {
  public getByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentToUser> {
    return this.getAll().filter(
      (relation) =>
        relation.processTaskAppointmentId === processTaskAppointmentId
    );
  }

  public getByProcessTaskAppointmentIds(
    processTaskAppointmentIds: Array<string>
  ): Array<ProcessTaskAppointmentToUser> {
    return this.getAll().filter((r) =>
      processTaskAppointmentIds.includes(r.processTaskAppointmentId)
    );
  }

  public getByUserId(userId: string): Array<ProcessTaskAppointmentToUser> {
    return this.getAll().filter((relation) => relation.userId === userId);
  }
}
