import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import { CapturedProcessTaskPictureHandler } from './CapturedProcessTaskPictureHandler/CapturedProcessTaskPictureHandler';
import {
  PictureCapturedHandler,
  PictureCapturedHandlerData,
  PictureCapturedHandlerOptions
} from './PictureCapturedHandler';

export const pictureCapturedHandlersByName =
  createTypeExtendsValidator<PictureCapturedHandlersByNameConstraint>()({
    capturedProcessTaskPicture: CapturedProcessTaskPictureHandler
  });

export type PictureCapturedHandlerName =
  keyof typeof pictureCapturedHandlersByName;

type PictureCapturedHandlersByNameConstraint = Record<
  string,
  PictureCapturedHandlerConstructor<any>
>;

export type PictureCapturedHandlerConstructor<
  TData extends PictureCapturedHandlerData
> = {
  new (options: PictureCapturedHandlerOptions<TData>): PictureCapturedHandler;
};

export type PictureCapturedHandlerDataForName<
  TName extends PictureCapturedHandlerName
> = (typeof pictureCapturedHandlersByName)[TName] extends new (
  data: infer U
) => any
  ? U extends { data: any }
    ? U['data']
    : never
  : never;
