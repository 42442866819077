import { DtoUtils, ParentEntityInfo } from '../../BaseEntities/DtoUtils';
import { createPropertyEntityInfo } from './createPropertyEntityInfo';
import { PropertyDto } from './PropertyDto';

export class PropertyDtoUtils {
  private static readonly propertyEntityInfo = createPropertyEntityInfo({
    additionalFieldInfos: []
  });

  private constructor() {}

  public static getParentEntityInfo({
    propertyDto
  }: {
    propertyDto: PropertyDto<string, string>;
  }): ParentEntityInfo {
    return DtoUtils.getParentEntityInfo<PropertyDto<string, string>>({
      dto: propertyDto,
      parentEntityIdFieldNames: [
        'thingType',
        'defect',
        'regionId',
        'userDefinedEntityId',
        'thingThingSectionId',
        'projectThingSectionId',
        'processTaskMeasurePointReadingId',
        'processTaskDeviceId',
        'processTaskPositionId',
        'processTaskPositionDetailEntryId',
        'processTaskAppointmentId',
        'processTaskId',
        'processConfigurationDeviceId',
        'processConfigurationStepPositionId',
        'thing',
        'thingGroupId',
        'project',
        'entry'
      ],
      entityInfo: this.propertyEntityInfo
    });
  }
}
