import { autoinject } from 'aurelia-framework';

import { EmbeddedCamera } from '../embedded-camera/embedded-camera';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { Dialogs } from '../../classes/Dialogs';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { CirclePreloader } from '../circle-preloader/circle-preloader';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  Picture,
  PictureAdditionalMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureCreatorService } from '../../classes/Picture/PictureCreatorService';

/**
 * this is meant to be a single global instance
 */
@autoinject()
export class EntryRapidFireWidget {
  private static instance: EntryRapidFireWidget | null = null;
  private static readonly LAST_PROJECT_ID_STORAGE_KEY =
    'EntryRapidFireWidget._lastProjectId';
  private static readonly SAVED_ENTRY_IDS_STORAGE_KEY =
    'EntryRapidFireWidget._savedEntryIdsStorageKey';

  public static start(projectId: string): void {
    assertNotNullOrUndefined(
      this.instance,
      "can't EntryRapidFireWidget.start without an instance"
    );
    void this.instance.start(projectId);
  }

  private readonly subscriptionManager: SubscriptionManager;

  private dialog: RecordItDialog | null = null;
  private circlePreloader: CirclePreloader | null = null;
  private pictureData: PictureData | null = null;
  private projectId: string | null = null;

  private selectedEntries: Array<Entry> = [];
  private currentlySelectedEntry: Entry | null = null;
  private entryToCreate = {}; // TODO: typing
  private pictureDescription: string | null = null;
  private additionalMarkings: Array<PictureAdditionalMarking> = [];
  private saveSelectedEntries: boolean = false;

  /**
   * aurelia doesn't correctly when we select a new selected last entry (it's only listening on the length, and in this case the length stays the same)
   * this is an workaround (not using the signaler here since it's global)
   */
  private selectedEntriesChangedCounter: number = 0;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly pictureCreatorService: PictureCreatorService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    if (EntryRapidFireWidget.instance) {
      console.error('an instance was already attached, replacing it...');
    }
    EntryRapidFireWidget.instance = this;

    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:data-url-picture-available',
      this.handleDataUrlPictureAvailable.bind(this)
    );
    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:camera-closed',
      this.handleCameraClosed.bind(this)
    );
    if (EmbeddedCamera.hasLastDataUrlPicture()) {
      void this.handleDataUrlPictureAvailable();
    }
  }

  protected detached(): void {
    EntryRapidFireWidget.instance = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  private async start(projectId: string): Promise<void> {
    this.reset();
    this.projectId = projectId;
    await DataStorageHelper.setItem(
      EntryRapidFireWidget.LAST_PROJECT_ID_STORAGE_KEY,
      projectId
    );
    await EmbeddedCamera.capturePicture({});
  }

  private async handleDataUrlPictureAvailable(): Promise<void> {
    const projectId = await DataStorageHelper.getItem(
      EntryRapidFireWidget.LAST_PROJECT_ID_STORAGE_KEY
    );
    if (!projectId) {
      return; // not our event
    }

    const uri = EmbeddedCamera.getLastDataUriPictureOnce();
    if (!uri) {
      void Dialogs.errorDialogTk(
        'aureliaComponents.entryRapidFireWidget.noDataUriDialogTitle',
        'aureliaComponents.entryRapidFireWidget.noDataUriDialogDescription'
      );
      await this.deleteLastProjectId();
    } else {
      this.reset();
      await this.restoreSelectedEntries();
      this.projectId = projectId;
      this.pictureData = {
        originalDataUrl: uri
      };

      assertNotNullOrUndefined(
        this.dialog,
        "can't EntryRapidFireWidget.handleDataUrlPictureAvailable without a dialog"
      );
      this.dialog.open();
    }
  }

  private handleCameraClosed(): void {
    void DataStorageHelper.setItem(
      EntryRapidFireWidget.SAVED_ENTRY_IDS_STORAGE_KEY,
      null
    );
    void this.deleteLastProjectId();
  }

  private reset(): void {
    this.projectId = null;
    this.pictureData = null;
    this.currentlySelectedEntry = null;
    this.selectedEntries = [];
    this.selectedEntriesChangedCounter = 0;
    this.entryToCreate = {};
    this.pictureDescription = null;
    this.additionalMarkings = [];
  }

  private getSiblingsOfEntry(entry: Entry): Array<Entry> {
    return this.entityManager.entryRepository.getSiblingsOfEntry(entry);
  }

  private getSubEntriesOfEntryOrProject(
    projectId: string,
    entry: Entry | null
  ): Array<Entry> {
    if (entry) {
      return this.entityManager.entryRepository.getSubEntriesOfEntry(entry);
    } else {
      return this.entityManager.entryRepository.getByParentId(
        projectId,
        null,
        null
      );
    }
  }

  private handleSelectedEntryChanged(
    oldEntry: Entry,
    replacementEntry: Entry
  ): void {
    this.replaceSelectedEntry(oldEntry, replacementEntry);
  }

  private handleCurrentlySelectedEntryChanged(): void {
    if (this.currentlySelectedEntry) {
      this.selectedEntries.push(this.currentlySelectedEntry);
      this.selectedEntriesChangedCounter++;
      this.currentlySelectedEntry = null;

      setTimeout(() => {
        assertNotNullOrUndefined(
          this.dialog,
          "can't EntryRapidFireWidget.handleCurrentlySelectedEntryChanged without a dialog"
        );
        this.dialog.scrollToBottom();
      }, 10);
    }
  }

  private handleRemoveSelectedEntryClick(entry: Entry): void {
    this.replaceSelectedEntry(entry, null);
  }

  private replaceSelectedEntry(
    entry: Entry,
    replacementEntry: Entry | null
  ): void {
    const index = this.selectedEntries.indexOf(entry);
    if (index >= 0) {
      const deleteCount = this.selectedEntries.length - index;

      if (replacementEntry) {
        this.selectedEntries.splice(index, deleteCount, replacementEntry);
      } else {
        this.selectedEntries.splice(index, deleteCount);
      }
      this.selectedEntriesChangedCounter++;
    }
  }

  private async handleAddNewEntryClick(): Promise<void> {
    const parentEntry = this.selectedEntries[this.selectedEntries.length - 1];
    const result = await Dialogs.addEntryDialogTk(
      'aureliaComponents.entryRapidFireWidget.addEntryDialogText',
      { parentEntryName: parentEntry.name }
    );
    assertNotNullOrUndefined(
      this.projectId,
      "can't EntryRapidFireWidget.handleAddNewEntryClick without a projectId"
    );
    const project = this.entityManager.projectRepository.getById(
      this.projectId
    );
    assertNotNullOrUndefined(
      project,
      `no project found for "${this.projectId}"`
    );

    const entry = this.entityManager.entryRepository.create({
      name: result.inputValue,
      project: this.projectId,
      page_depth_parent: parentEntry ? parentEntry.id : null,
      ownerProjectId: this.projectId,
      ownerUserGroupId: project.usergroup
    });

    this.selectedEntries.push(entry);
  }

  private async handleAcceptButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.projectId,
      "can't EntryRapidFireWidget.handleAcceptButtonClicked without a projectId"
    );
    const project = this.entityManager.projectRepository.getById(
      this.projectId
    );
    assertNotNullOrUndefined(
      project,
      `no project found for "${this.projectId}"`
    );
    const parentEntry = this.selectedEntries[this.selectedEntries.length - 1];

    const entry = this.entityManager.entryRepository.create({
      ...this.entryToCreate,
      project: this.projectId,
      page_depth_parent: parentEntry ? parentEntry.id : null,
      ownerProjectId: this.projectId,
      ownerUserGroupId: project.ownerUserGroupId
    });

    const picture = this.pictureCreatorService
      .withEntityInfos(() => ({
        mainEntityIdField: 'ownerProjectId',
        mainEntityId: project.id,
        subEntityField: 'entry',
        subEntityValue: entry.id,
        ownerProjectId: entry.ownerProjectId,
        ownerUserGroupId: entry.ownerUserGroupId
      }))
      .createPicture({
        description: this.pictureDescription,
        additional_markings: this.additionalMarkings,
        selected: true // it's the first picture in the entry, so automatically select it
      });
    this.createPictureFiles(picture);
    await this.saveSelectedEntriesToStorage();

    this.waitAndStartCapturingPicture();
  }

  private createPictureFiles(picture: Picture): void {
    assertNotNullOrUndefined(
      this.pictureData,
      "can't EntryRapidFireWidget.createPictureFiles without a pictureData"
    );

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      this.pictureData.originalDataUrl
    );

    if (this.pictureData.editedDataUrl) {
      this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
        picture,
        this.pictureData.editedDataUrl
      );
    }

    if (this.pictureData.sketchDataUrl) {
      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        this.pictureData.sketchDataUrl
      );
    }
  }

  private waitAndStartCapturingPicture(): void {
    const circlePreloader = this.circlePreloader;
    const dialog = this.dialog;
    assertNotNullOrUndefined(
      circlePreloader,
      "can't EntryRapidFireWidget.waitAndStartCapturingPicture without a circlePreloader"
    );
    assertNotNullOrUndefined(
      dialog,
      "can't EntryRapidFireWidget.waitAndStartCapturingPicture without a dialog"
    );

    circlePreloader.start();
    setTimeout(() => {
      circlePreloader.stop();
      this.reset();
      dialog.close();
      void EmbeddedCamera.capturePicture({});
    }, 700); // fake loading for the user to feel better
  }

  private async handleDeclineButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EntryRapidFireWidget.handleDeclineButtonClicked without a dialog"
    );

    this.reset();
    this.dialog.close();
    await EmbeddedCamera.capturePicture({});
  }

  private async saveSelectedEntriesToStorage(): Promise<void> {
    const selectedEntries = this.saveSelectedEntries
      ? this.selectedEntries
      : [];
    const entryIds = selectedEntries.map((e) => e.id);
    await DataStorageHelper.setItem(
      EntryRapidFireWidget.SAVED_ENTRY_IDS_STORAGE_KEY,
      entryIds
    );
  }

  private deleteLastProjectId(): Promise<void> {
    return DataStorageHelper.removeItem(
      EntryRapidFireWidget.LAST_PROJECT_ID_STORAGE_KEY
    );
  }

  private async restoreSelectedEntries(): Promise<void> {
    const entryIds = await DataStorageHelper.getItem(
      EntryRapidFireWidget.SAVED_ENTRY_IDS_STORAGE_KEY
    );
    if (!entryIds) {
      this.saveSelectedEntries = false;
      return;
    }

    const selectedEntries = [];
    for (let key = 0; key < entryIds.length; key++) {
      const entry = this.entityManager.entryRepository.getById(entryIds[key]);
      if (entry) {
        selectedEntries.push(entry);
      } else {
        break;
      }
    }

    this.saveSelectedEntries = entryIds.length > 0;
    this.selectedEntries = selectedEntries;
  }
}

export type PictureData = {
  originalDataUrl: string;
  sketchDataUrl?: string;
  editedDataUrl?: string;
};
