import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {YesNoInputValueChanged} value-changed
 */
@autoinject()
export class YesNoInput {
  /**
   * true = yes
   * false = no
   * null = nothing selected
   */
  @bindable()
  public value: boolean | null = null;

  @bindable()
  public label: string | null = null;

  @bindable()
  public noLabel: boolean = false;

  @bindable()
  public enabled: boolean = false;

  @bindable public stackedStyle = false;

  protected choices: Array<Choice> = [
    {
      value: true,
      labelTk: 'inputComponents.yesNoInput.choices.true'
    },
    {
      value: false,
      labelTk: 'inputComponents.yesNoInput.choices.false'
    }
  ];

  constructor(private readonly element: Element) {}

  protected handleChoiceChanged(): void {
    DomEventHelper.fireEvent<YesNoInputValueChanged>(this.element, {
      name: 'value-changed',
      detail: {
        value: this.value
      }
    });
  }
}

export type YesNoInputValueChanged = NamedCustomEvent<
  'value-changed',
  { value: boolean | null }
>;

type Choice = {
  value: boolean;
  labelTk: string;
};
