import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { Logger } from '../../classes/Logger/Logger';
import { CameraStreamService } from '../../services/CameraStreamService/CameraStreamService';
import {
  MediaStreamError,
  MediaStreamErrorName
} from '../../services/CameraStreamService/MediaDevicesCameraStreamStrategy';
import { FullScreenContent } from '../full-screen-content/full-screen-content';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { FocusSetHintResult } from '../../services/CameraStreamService/AbstractCameraStreamStrategy';
import { FocusSetFeedback } from './focus-set-feedback/focus-set-feedback';

@autoinject()
export class CameraOverlay {
  /** read-only */
  @bindable public streamIsActive = false;

  protected fullScreenContent: FullScreenContent | null = null;

  protected slotWrapperElement: HTMLElement | null = null;

  protected focusSetFeedback: FocusSetFeedback | null = null;

  private errorTextKey: string | null = null;

  private eventDispatcher: EventDispatcher<{
    availableFlashModesChanged: {
      availableFlashModes: Array<CameraPreviewFlashMode>;
    };
    currentFlashModeChanged: {
      currentFlashMode: CameraPreviewFlashMode | null;
    };
  }> = new EventDispatcher();

  private availableFlashModes: Array<CameraPreviewFlashMode> = [];
  private currentFlashMode: CameraPreviewFlashMode | null = null;

  constructor(
    private readonly i18n: I18N,
    private readonly cameraStreamService: CameraStreamService
  ) {}

  public async open(): Promise<void> {
    try {
      SiteScrollLocker.lockScrolling('camera-overlay');

      await this.startStream();

      this.fullScreenContent?.open();
      this.streamIsActive = true;
    } catch (error) {
      if (this.isMediaDeviceStreamError(error)) {
        this.handleMediaDeviceCameraError(error);
      } else {
        Logger.logError({ error });
      }
      void this.close();
    }
  }

  public async close(): Promise<void> {
    this.fullScreenContent?.close();

    await this.cameraStreamService.stopStream(this);
    this.streamIsActive = false;

    SiteScrollLocker.unlockScrolling('camera-overlay');
  }

  public async switchStream(): Promise<void> {
    await this.cameraStreamService.switchStream();

    await this.updateAvailableFlashModes();
    await this.updateCurrentFlashMode();
  }

  public takePicture(): Promise<string> {
    return this.cameraStreamService.takePicture();
  }

  public subscribeToAvailableFlashModeChanges(
    callback: (payload: {
      availableFlashModes: Array<CameraPreviewFlashMode>;
    }) => void
  ): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'availableFlashModesChanged',
      callback
    );
  }

  public subscribeToCurrentFlashModeChanges(
    callback: (payload: {
      currentFlashMode: CameraPreviewFlashMode | null;
    }) => void
  ): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'currentFlashModeChanged',
      callback
    );
  }

  public async switchFlashMode(): Promise<void> {
    const currentIndex = this.availableFlashModes.findIndex(
      (fM) => fM === this.currentFlashMode
    );

    const nextFlashMode =
      this.availableFlashModes[currentIndex + 1] ?? this.availableFlashModes[0];
    if (nextFlashMode) {
      await this.cameraStreamService.setFlashMode(nextFlashMode);
    }

    await this.updateCurrentFlashMode();
  }

  protected handleClick(event: MouseEvent): void {
    if (event.target !== this.slotWrapperElement) return;

    void this.cameraStreamService
      .setFocusPoint(event.clientX, event.clientY)
      .catch((error) => {
        console.warn('could not set focus point', error);
      });
  }

  private showFocusSetHint(result: FocusSetHintResult): void {
    assertNotNullOrUndefined(
      this.focusSetFeedback,
      'cannot show hint without focusSetFeedback'
    );

    this.focusSetFeedback.showFocusSetHint(result);
  }

  private handleMediaDeviceCameraError(e: MediaStreamError): void {
    if (e.name === MediaStreamErrorName.NotAllowedError) {
      void Dialogs.errorDialogTk(
        'aureliaComponents.cameraOverlay.cameraGeneralErrorTitle',
        'aureliaComponents.cameraOverlay.cameraNotAllowedError'
      );
    } else {
      void Dialogs.errorDialog(
        this.i18n.tr('aureliaComponents.cameraOverlay.cameraGeneralErrorTitle'),
        this.i18n.tr('aureliaComponents.cameraOverlay.cameraOtherError', {
          errorType: e.name,
          errorText: e.message
        })
      );
    }
  }

  private isMediaDeviceStreamError(e: any): e is MediaStreamError {
    return Object.values(MediaStreamErrorName).includes(e.name);
  }

  private async updateAvailableFlashModes(): Promise<void> {
    this.availableFlashModes =
      await this.cameraStreamService.getAvailableFlashModes();
    this.eventDispatcher.dispatchEvent('availableFlashModesChanged', {
      availableFlashModes: this.availableFlashModes
    });
  }

  private async updateCurrentFlashMode(): Promise<void> {
    this.currentFlashMode = await this.cameraStreamService.getFlashMode();
    this.eventDispatcher.dispatchEvent('currentFlashModeChanged', {
      currentFlashMode: this.currentFlashMode
    });
  }

  private async startStream(): Promise<void> {
    await this.cameraStreamService.startStream(
      this,
      this.showFocusSetHint.bind(this)
    );
    await this.updateAvailableFlashModes();

    if (this.currentFlashMode) {
      await this.cameraStreamService.setFlashMode(this.currentFlashMode);
    } else {
      await this.updateCurrentFlashMode();
    }
  }
}
