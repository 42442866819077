import { autoinject, bindable } from 'aurelia-framework';

import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ProcessConfigurationStepPositionUtils } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionUtils';
import { ProcessConfigurationStepPosition } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';

/**
 * @event edit-process-configuration-step-position-clicked
 */
@autoinject()
export class ProcessConfigurationStepPositionListItem {
  @bindable()
  public processConfigurationStepPosition: ProcessConfigurationStepPosition | null =
    null;

  protected listItemElement: HTMLElement | null = null;

  protected readonly ProcessConfigurationStepPositionUtils =
    ProcessConfigurationStepPositionUtils;

  constructor(private readonly element: Element) {}

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleEditProcessConfigurationStepPositionClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-process-configuration-step-position-clicked',
      detail: null
    });
  }
}
