import { autoinject, TaskQueue } from 'aurelia-framework';
import { NavigationInstruction, RouteConfig, Router } from 'aurelia-router';
import $ from 'jquery';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { Utils } from '../../classes/Utils/Utils';
import { ScrollHelper } from '../../classes/ScrollHelper';
import {
  ModuleName,
  RecordItModuleHelper
} from '../../classes/RecordItModuleHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { HistoryService } from '../../services/HistoryService/HistoryService';
import { NavigationService } from '../../services/NavigationService';
import { ProjectQuestionCategory } from '../../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';

@autoinject()
export class MainBreadcrumb {
  protected crumbs: Array<Crumb> = [];
  protected isScrollable = false;
  protected showShadow = true;

  private activeThing: Thing | null = null;
  private activeProject: Project | null = null;
  private activeEntry: Entry | null = null;
  private activePerson: Person | null = null;
  private activeProcessConfiguration: ProcessConfiguration | null = null;
  private activeProcessConfigurationStep: ProcessConfigurationStep | null =
    null;

  private activeChecklistInspection: Project | null = null;
  private activeChecklistInspectionCategory: ProjectQuestionCategory | null =
    null;

  private activeChecklistInspectionQuestion: ProjectQuestion | null = null;

  private activeRouteConfig: RouteConfig | null = null;

  private availableModules: Array<string> = [];
  private scrollContainerElement: HTMLElement | null = null;
  private scrollContentSpacerElement: HTMLElement | null = null;
  private isAttached = false;
  protected canNavigateBack = false;

  private subscriptionManager: SubscriptionManager;

  private boundHandleWindowResize = this.handleWindowResize.bind(this);
  private updateCrumbsRateLimited = Utils.rateLimitFunction(
    this.updateCrumbs.bind(this),
    20
  );

  protected PersonUtils = PersonUtils; // for availablity in the view

  constructor(
    private router: Router,
    private taskQueue: TaskQueue,
    private activeEntitiesService: ActiveEntitiesService,
    private entityManager: AppEntityManager,
    private currentUserService: CurrentUserService,
    private readonly historyService: HistoryService,
    private readonly navigationService: NavigationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeThing',
        (activeThing) => {
          this.activeThing = activeThing;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProject',
        (activeProject) => {
          this.activeProject = activeProject;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeEntry',
        (activeEntry) => {
          this.activeEntry = activeEntry;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activePerson',
        (activePerson) => {
          this.activePerson = activePerson;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessConfiguration',
        (activeProcesConfiguration) => {
          this.activeProcessConfiguration = activeProcesConfiguration;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessConfigurationStep',
        (activeProcessConfigurationStep) => {
          this.activeProcessConfigurationStep = activeProcessConfigurationStep;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeChecklistInspection',
        (activeChecklistInspection) => {
          this.activeChecklistInspection = activeChecklistInspection;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeChecklistInspectionCategory',
        (activeChecklistInspectionCategory) => {
          this.activeChecklistInspectionCategory =
            activeChecklistInspectionCategory;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeChecklistInspectionQuestion',
        (activeChecklistInspectionQuestion) => {
          this.activeChecklistInspectionQuestion =
            activeChecklistInspectionQuestion;
        }
      )
    );

    this.subscriptionManager.subscribeToEvent(
      'router:navigation:processing',
      (state: TNavigationProcessingState) => {
        this.activeRouteConfig = state.instruction.config;
        this.updateCrumbsRateLimited();
      }
    );
    this.activeRouteConfig = this.router.currentInstruction
      ? this.router.currentInstruction.config
      : null;

    // we don't want to update the breadcrumbs multiple times when the activeProject gets unset and set again in the next route
    this.subscriptionManager.subscribeToMultiplePropertyChanges(
      this,
      [
        'activeThing',
        'activeProject',
        'activeEntry',
        'activePerson',
        'activeProcessConfiguration',
        'activeProcessConfigurationStep',
        'availableModules'
      ],
      this.updateCrumbsRateLimited
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateAvailableModules.bind(this)
      )
    );
    this.updateAvailableModules();

    this.subscriptionManager.addDisposable(
      this.historyService.bindCanNavigateBack((canNavigateBack) => {
        this.canNavigateBack = canNavigateBack;
      })
    );

    this.updateCrumbs();

    window.addEventListener('resize', this.boundHandleWindowResize);
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    window.removeEventListener('resize', this.boundHandleWindowResize);
  }

  protected handleBackButtonClick(): void {
    this.historyService.navigateBack();
  }

  private updateAvailableModules(): void {
    const user = this.currentUserService.getCurrentUser();
    this.availableModules = user
      ? PermissionHelper.getAvailableModulesForUser(user)
      : [];
  }

  private updateCrumbs(): void {
    if (!this.isAttached || !this.scrollContainerElement) {
      return;
    }

    const oldScrollWidth = this.scrollContainerElement.scrollWidth;

    let crumbs: Array<Crumb> = [];

    if (this.activeChecklistInspectionQuestion) {
      crumbs = this.generateChecklistInspectionQuestionCrumbs();
    } else if (this.activeChecklistInspectionCategory) {
      crumbs = this.generateChecklistInspectionCategoryCrumbs();
    } else if (this.activeChecklistInspection) {
      crumbs = this.generateChecklistInspectionCrumbs();
    } else if (this.activeEntry) {
      crumbs = this.generateEntryCrumbs();
    } else if (this.activeProject) {
      crumbs = this.generateProjectCrumbs();
    } else if (this.activeThing) {
      crumbs = this.generateThingCrumbs();
    } else if (this.activePerson) {
      crumbs = this.generatePersonCrumbs();
    } else if (this.activeProcessConfigurationStep) {
      crumbs = this.generateProcessConfigurationStepCrumbs();
    } else if (this.activeProcessConfiguration) {
      crumbs = this.generateProcessConfigurationCrumbs();
    }

    this.crumbs = this.getOverviewCrumbsForActiveRouteConfig().concat(crumbs);

    this.taskQueue.queueMicroTask(() => {
      if (!this.scrollContainerElement) {
        return;
      }

      this.updateIsScrollable();
      if (oldScrollWidth < this.scrollContainerElement.scrollWidth) {
        this.scrollToTheRight();
      } else {
        this.animateScrollWidthShrinking(oldScrollWidth);
      }
    });
  }

  private generateEntryCrumbs(): Array<Crumb> {
    if (!this.activeEntry) {
      return [];
    }

    const entries = this.entityManager.entryRepository.getPathByEntryId(
      this.activeEntry.id
    );
    entries.reverse();
    const crumbs = entries.map((entry) => {
      return new Crumb({
        routeName: 'project',
        routeParams: { project_id: entry.project, entry_id: entry.id },
        entity: entry
      });
    });

    const project = this.entityManager.projectRepository.getById(
      this.activeEntry.project
    );
    return this.generateProjectCrumbs(project).concat(crumbs);
  }

  private generateProjectCrumbs(
    project?: Project | null,
    showThingIcon?: boolean | null
  ): Array<Crumb> {
    project = project || this.activeProject;
    const moduleName =
      this.activeRouteConfig?.settings.recordItModuleName ?? ModuleName.BASIC;

    if (!project) {
      return [];
    }

    const thing = this.entityManager.thingRepository.getById(project.thing);

    if (!thing) {
      throw new Error(`no thing found for project ${project.id}`);
    }

    return this.generateThingCrumbs(thing, true).concat([
      new Crumb({
        routeName:
          RecordItModuleHelper.getProjectViewPageForModuleName(moduleName) ??
          'project',
        routeParams: { project_id: project.id },
        entity: project
      })
    ]);
  }

  private generateThingCrumbs(
    thing?: Thing | null,
    showIconOnly?: boolean | null
  ): Array<Crumb> {
    thing = thing || this.activeThing;

    // while the app is setting up, it's possible that the thing is not yet available
    if (thing) {
      return [
        new Crumb({
          routeName: this.navigationService.getThingRouteName(),
          routeParams: { thing_id: thing.id },
          entity: thing,
          iconType: showIconOnly ? 'custom' : null,
          iconName: showIconOnly ? 'object' : null
        })
      ];
    } else {
      return [];
    }
  }

  private generatePersonCrumbs(person?: Person | null): Array<Crumb> {
    person = person || this.activePerson;

    if (!person) {
      return [];
    }

    return [
      new Crumb({
        routeName: 'edit_persons',
        routeParams: {},
        entity: null,
        iconType: 'custom',
        iconName: 'person-list'
      }),
      new Crumb({
        routeName: 'edit_person',
        routeParams: { person_id: person.id },
        entity: person,
        displayName: PersonUtils.getPersonDisplayNameForPerson(person)
      })
    ];
  }

  private generateProcessConfigurationStepCrumbs(
    processConfigurationStep?: ProcessConfigurationStep | null
  ): Array<Crumb> {
    processConfigurationStep =
      processConfigurationStep || this.activeProcessConfigurationStep;

    if (!processConfigurationStep) {
      return [];
    }

    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processConfigurationStep.ownerProcessConfigurationId
      );
    let processConfigurationCrumbs: Array<Crumb> = [];

    if (processConfiguration) {
      processConfigurationCrumbs =
        this.generateProcessConfigurationCrumbs(processConfiguration);
    }

    return processConfigurationCrumbs.concat([
      new Crumb({
        routeName: 'edit_process_configuration_step_positions',
        entity: processConfigurationStep,
        routeParams: {
          process_configuration_step_id: processConfigurationStep.id
        }
      })
    ]);
  }

  private generateProcessConfigurationCrumbs(
    processConfiguration?: ProcessConfiguration | null
  ): Array<Crumb> {
    processConfiguration =
      processConfiguration || this.activeProcessConfiguration;

    if (!processConfiguration) {
      return [];
    }

    return [
      new Crumb({
        routeName: 'edit_process_configurations',
        routeParams: {},
        entity: null,
        iconType: 'custom',
        iconName: 'process-configuration-list'
      }),
      new Crumb({
        routeName: 'edit_process_configuration_steps',
        routeParams: { process_configuration_id: processConfiguration.id },
        entity: processConfiguration
      })
    ];
  }

  private getOverviewCrumbsForActiveRouteConfig(): Array<Crumb> {
    const crumbs: Array<Crumb> = [];

    // we don't need to show the overview crumb if there is only 0/1 module available, the home button will handle the navigation then
    if (
      !this.activeRouteConfig ||
      !this.activeRouteConfig.settings ||
      this.availableModules.length < 2
    ) {
      return crumbs;
    }

    const moduleName =
      this.activeRouteConfig.settings.recordItModuleName || ModuleName.BASIC;
    const overviewPageRoute =
      RecordItModuleHelper.getOverviewPageRouteForModuleName(moduleName);

    if (overviewPageRoute) {
      crumbs.push(
        new Crumb({
          entity: null,
          iconType: 'custom',
          iconName: 'object-list',
          routeName: overviewPageRoute,
          routeParams: {}
        })
      );
    }

    return crumbs;
  }

  private generateChecklistInspectionCrumbs(
    project?: Project | null
  ): Array<Crumb> {
    project = project ?? this.activeChecklistInspection;
    if (!project) return [];

    const thing = this.entityManager.thingRepository.getById(project.thing);
    return [
      ...this.generateThingCrumbs(thing, true),
      new Crumb({
        entity: project,
        displayName: project.name
          ? ChecklistProjectNameUtils.toDateString(project.name)
          : null,
        routeName: 'checklist_inspection',
        routeParams: { project_id: project.id }
      })
    ];
  }

  private generateChecklistInspectionCategoryCrumbs(
    projectQuestionCategory?: ProjectQuestionCategory | null
  ): Array<Crumb> {
    projectQuestionCategory =
      projectQuestionCategory ?? this.activeChecklistInspectionCategory;
    const projectId = projectQuestionCategory?.ownerProjectId;
    const project = projectId
      ? this.entityManager.projectRepository.getById(projectId)
      : null;
    if (!projectQuestionCategory || !project) return [];

    return [
      ...this.generateChecklistInspectionCrumbs(project),
      new Crumb({
        entity: projectQuestionCategory,
        routeName: 'checklist_inspection_category',
        routeParams: {
          project_id: project.id,
          project_category_id: projectQuestionCategory.id
        }
      })
    ];
  }

  private generateChecklistInspectionQuestionCrumbs(
    projectQuestion?: ProjectQuestion | null
  ): Array<Crumb> {
    projectQuestion = projectQuestion ?? this.activeChecklistInspectionQuestion;
    const projectQuestionCategoryId =
      projectQuestion?.projectQuestionCategoryId;
    const projectQuestionCategory = projectQuestionCategoryId
      ? this.entityManager.projectQuestionCategoryRepository.getById(
          projectQuestionCategoryId
        )
      : null;
    const projectId = projectQuestionCategory?.ownerProjectId;
    if (!projectQuestion || !projectQuestionCategory || !projectId) return [];

    return [
      ...this.generateChecklistInspectionCategoryCrumbs(
        projectQuestionCategory
      ),
      new Crumb({
        entity: projectQuestion,
        routeName: 'checklist_inspection_question',
        displayName: projectQuestion.text,
        routeParams: {
          project_id: projectId,
          project_category_id: projectQuestionCategory.id,
          project_question_id: projectQuestion.id
        }
      })
    ];
  }

  private updateIsScrollable(): void {
    if (!this.scrollContainerElement) {
      return;
    }

    const computed = window.getComputedStyle(this.scrollContainerElement);
    const width = computed.width != null ? parseInt(computed.width) : NaN;
    this.isScrollable = this.scrollContainerElement.scrollWidth > width;
  }

  private scrollToTheRight(): void {
    if (!this.scrollContainerElement) {
      return;
    }

    const computed = window.getComputedStyle(this.scrollContainerElement);
    const width = computed.width != null ? parseInt(computed.width) : NaN;
    const maxScrollLeft = Math.max(
      this.scrollContainerElement.scrollWidth - width,
      0
    );
    if (maxScrollLeft > 0) {
      ScrollHelper.scrollElement(
        this.scrollContainerElement,
        'left',
        maxScrollLeft
      );
    }
  }

  private animateScrollWidthShrinking(oldScrollWidth: number): void {
    if (!this.scrollContainerElement || !this.scrollContentSpacerElement) {
      return;
    }

    const diff = oldScrollWidth - this.scrollContainerElement.scrollWidth;

    if (!isNaN(diff)) {
      this.scrollContentSpacerElement.style.width = 15 + diff + 'px';
      this.scrollContainerElement.scrollLeft = 99999999999;
      setTimeout(() => {
        if (!this.scrollContentSpacerElement) {
          return;
        }

        // eslint-disable-next-line new-cap
        $.Velocity.animate(
          this.scrollContentSpacerElement,
          {
            width: 15
          },
          {
            duration: 500
          }
        );
      }, 20);
    }
  }

  protected getEntityDisplayName(name: string): string {
    return name ? name : 'Name...';
  }

  protected createRoute(routeName: string, params: Object): string {
    return this.router.generate(routeName, params);
  }

  private handleWindowResize(): void {
    this.scrollToTheRight();
    this.updateIsScrollable();
  }

  protected handleScrollContainerScroll(): void {
    if (!this.scrollContainerElement) {
      return;
    }

    if (this.scrollContainerElement.scrollLeft > 0) {
      this.showShadow = true;
    } else {
      this.showShadow = false;
    }
  }

  protected getHomeButtonRoute(availableModules: Array<ModuleName>): string {
    const firstModule = availableModules[0];
    if (firstModule && availableModules.length === 1) {
      const route =
        RecordItModuleHelper.getOverviewPageRouteForModuleName(firstModule);
      return route != null ? route : 'home';
    } else {
      return 'home';
    }
  }
}

class Crumb {
  public routeName: string;
  public routeParams: Object;
  public entity: Object | null;
  public iconType: string | null;
  public iconName: string | null;
  public displayName: string | null;

  constructor(options: {
    routeName: string;
    routeParams: Object | null;
    entity: Object | null;
    iconType?: string | null;
    iconName?: string | null;
    displayName?: string | null;
  }) {
    this.routeName = options.routeName;
    this.routeParams = options.routeParams != null ? options.routeParams : {};
    this.entity = options.entity;
    this.iconType = options.iconType ?? null;
    this.iconName = options.iconName ?? null;
    this.displayName = options.displayName ?? null;
  }
}

type TNavigationProcessingState = {
  instruction: NavigationInstruction;
};
