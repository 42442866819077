/* global L */

import { CoordinateHelper } from './CoordinateHelper';

/**
 * provides a class which injects a map into an element and show the position of entities
 *
 * you need to call init before any other function
 *
 * also call deInit when the map is not needed anymore
 */
export class EntityMap {
  /** @type {import('../map/basemap-map/basemap-map').BasemapMap} */
  _basemapMap;

  /** @type {Array<EntityMapMarker>} */
  _markers = [];

  _trackingLocation = false;

  /** @type {(function(TEntityMapEntityInfo): void)|null} */
  _markerClickedCallback = null;

  /** @type {(function(TEntityMapEntityInfo): void)|null} */
  _markerPopupClosedCallback = null;

  /**
   * @param {import('../map/basemap-map/basemap-map').BasemapMap} basemapMap
   */
  constructor(basemapMap) {
    this._basemapMap = basemapMap;
  }

  /**
   * @param {Array<TEntityMapEntityInfo>} entityInfos
   */
  showEntitiesOnMap(entityInfos) {
    this._removeUnnecessaryMarkers(entityInfos);
    this._addOrUpdateMarkers(entityInfos);
  }

  /**
   * "greys out" all markers which are not in this array
   *
   * doesn't update the markers itself, to do this use the `showEntitiesOnMap` method
   *
   * @param {Array<TEntityMapEntityInfo>} entityInfos
   */
  enableEntities(entityInfos) {
    this._markers.forEach((marker) => {
      marker.setActive(
        entityInfos.findIndex((eI) => this._entityInfoIsEqual(marker, eI)) >= 0
      );
    });
  }

  /**
   * @param {(function(TEntityMapEntityInfo): void)|null} callback
   */
  setMarkerClickedCallback(callback) {
    this._markerClickedCallback = callback;
  }

  /**
   * @param {(function(TEntityMapEntityInfo): void)|null} callback
   */
  setMarkerPopupClosedCallback(callback) {
    this._markerPopupClosedCallback = callback;
  }

  /**
   * @param {Array<TEntityMapEntityInfo>} entityInfos - all entities which are shown on the map
   * @private
   */
  _removeUnnecessaryMarkers(entityInfos) {
    for (let key = this._markers.length - 1; key >= 0; key--) {
      const marker = this._markers[key];
      if (
        entityInfos.findIndex((eI) => this._entityInfoIsEqual(marker, eI)) ===
        -1
      ) {
        marker.remove();
        this._markers.splice(key, 1);
      }
    }
  }

  /**
   * @param {EntityMapMarker} marker
   * @param {TEntityMapEntityInfo} entityInfo
   * @returns {boolean}
   */
  _entityInfoIsEqual(marker, entityInfo) {
    const markerEntityInfo = marker.getEntityInfo();
    return markerEntityInfo.id === entityInfo.id;
  }

  /* ************** Marker adding/rendering ************** */

  /**
   * adds markers (if necessary) for the entities
   *
   * @param {Array<TEntityMapEntityInfo>} entityInfos
   * @private
   */
  _addOrUpdateMarkers(entityInfos) {
    for (let key = 0; key < entityInfos.length; key++) {
      const entityInfo = entityInfos[key];

      const markerIndex = this._getMarkerIndexForEntityInfo(entityInfo);

      if (this._validateEntityInfoPosition(entityInfo) && markerIndex === -1) {
        this._markers.push(this._generateEntityMarker(entityInfo));
      }

      if (markerIndex >= 0) {
        const marker = this._markers[markerIndex];
        marker.setLabel(entityInfo.label);
        marker.setColor(entityInfo.markerColor);
      }
    }
  }

  /**
   * @param {TEntityMapEntityInfo} entityInfo
   * @returns {EntityMapMarker}
   * @private
   */
  _generateEntityMarker(entityInfo) {
    const marker = new EntityMapMarker(entityInfo);

    marker.on('click', this._handleMarkerClick.bind(this));
    marker.on('popupclose', this._handleMarkerPopupClosed.bind(this));

    marker.appendToMap(this._basemapMap.getMapInstance());
    return marker;
  }

  /**
   * @param {TEntityMapEntityInfo} entityInfo
   * @returns {number}
   * @private
   */
  _getMarkerIndexForEntityInfo(entityInfo) {
    return this._markers.findIndex((m) =>
      this._entityInfoIsEqual(m, entityInfo)
    );
  }

  /**
   * @param {TEntityMapEntityInfo} entityInfo
   * @returns {boolean}
   * @private
   */
  _validateEntityInfoPosition(entityInfo) {
    return entityInfo.latitude != null && entityInfo.longitude != null;
  }

  /**
   * @param {import('leaflet').LeafletEvent} event
   */
  _handleMarkerClick(event) {
    /** @type {EntityMapMarker} */
    const entityMapMarker = event.target.entityMapMarker;

    entityMapMarker.setSelected(true);
    if (this._markerClickedCallback) {
      this._markerClickedCallback(entityMapMarker.getEntityInfo());
    }
  }

  /**
   * @param {import('leaflet').LeafletEvent} event
   */
  _handleMarkerPopupClosed(event) {
    /** @type {EntityMapMarker} */
    const entityMapMarker = event.target.entityMapMarker;

    entityMapMarker.setSelected(false);
    if (this._markerPopupClosedCallback) {
      this._markerPopupClosedCallback(entityMapMarker.getEntityInfo());
    }
  }
}

class EntityMapMarker {
  /**
   * @param {TEntityMapEntityInfo} entityInfo
   */
  constructor(entityInfo) {
    this._entityInfo = entityInfo;

    /** @type {boolean} */
    this._active = true;

    this._marker = L.marker([entityInfo.latitude, entityInfo.longitude]);
    this._marker.bindPopup(this._generateEntityInfoContent.bind(this));
    // this._marker.on('click', this._handleClick, this);
    this._marker.entityMapMarker = this; // so we can retrieve this instance if we have only the default markers available
    this._updateMarkerIcon();
  }

  getEntityInfo() {
    return this._entityInfo;
  }

  /**
   * @param {import('leaflet').Map} map
   */
  appendToMap(map) {
    this._marker.addTo(map);
  }

  remove() {
    this._marker.remove();
  }

  /**
   * @param {boolean} active
   */
  setActive(active) {
    if (this._active !== active) {
      this._active = active;

      const opacity = this._active ? 1 : 0.5;
      this._marker.setOpacity(opacity);
    }
  }

  /**
   * @param {string|null} text
   */
  setLabel(text) {
    this._entityInfo.label = text || '';
    this._updateMarkerIcon();
  }

  /**
   * @param {string|null} color
   */
  setColor(color) {
    this._entityInfo.markerColor = color || '';
    this._updateMarkerIcon();
  }

  /**
   * @param {boolean} [selected]
   */
  setSelected(selected) {
    this._updateMarkerIcon(selected);
  }

  /**
   * @param {string} eventName
   * @param {import('leaflet').LeafletEventHandlerFn} handler
   */
  on(eventName, handler) {
    this._marker.on(eventName, handler);
  }

  /**
   * @param {string} eventName
   */
  off(eventName) {
    this._marker.off(eventName);
  }

  /**
   * @param {boolean} [selected]
   */
  _updateMarkerIcon(selected) {
    // drop shadow for dark backgrounds
    const icon = L.divIcon({
      html:
        '' +
        '<div style="position: relative; text-align: center;">' +
        this._generateMarkerSvg(
          this._entityInfo.markerColor || '#ff0000',
          this._entityInfo.label,
          selected
        ) +
        '</div>',
      iconSize: [35, 35],
      iconAnchor: [17.5, 35],
      popupAnchor: [0, -27],
      tooltipAnchor: [0, -27],
      className: ''
    });

    this._marker.setIcon(icon);
  }

  /**
   * @returns {string}
   * @private
   */
  _generateEntityInfoContent() {
    const clientCoordinates = CoordinateHelper.getClientCoordinates();
    const formattedDistance = CoordinateHelper.calculateFormattedDistance(
      this._entityInfo.latitude,
      this._entityInfo.longitude,
      clientCoordinates.latitude,
      clientCoordinates.longitude
    );
    const entity = this._entityInfo.entity;

    return `
    <h4><a href="#/${this._entityInfo.linkUrl}">${entity.name}</a></h4>
    ${entity.description ? `<p>${entity.description}</p>` : ''}
    <small class="lgi-text">
      <i class="fas fa-map-marker-alt"></i>
      ${CoordinateHelper.roundCoordinate(
        this._entityInfo.latitude
      )} / ${CoordinateHelper.roundCoordinate(this._entityInfo.longitude)}
    </small>
    <small class="lgi-text">
      <i class="far fa-location-arrow"></i>
      ${formattedDistance}
    </small>
    `;
  }

  /**
   * @param {string} color
   * @param {string} [text]
   * @param {boolean} [selected]
   * @returns {string}
   */
  _generateMarkerSvg(color, text, selected) {
    const marked = selected || false;
    return `<svg xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      style="height: 35px;"
      viewBox="-10 -70 26.578759 35"
      width="26.57876"
      version="1.1"
      id="svg4">
      <metadata id="metadata10">
        <rdf:RDF>
          <cc:Work rdf:about="">
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:Work>
        </rdf:RDF>
      </metadata>
      <defs id="defs8" />
      <rect id="rect812"
            style="fill:#ffffff;fill-opacity:1;stroke:${color};stroke-width:0.26364988;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            width="15.759573"
            height="16.05582"
            x="-4.5237341"
            y="-64.801201"
            ry="0.078960545" />
      <path id="path2"
            d="${text ? this._svgPathWithoutHole : this._svgPathWithHole}"
            style="fill:${color};fill-opacity:1;stroke:${
              marked ? '#ffffff' : 'none'
            };stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none" />
      ${
        text
          ? `<text id="text879"
            style="font-style:normal;font-weight:bold;font-size:13px;line-height:1.25;font-family:'Basier Circle', sans-serif;letter-spacing:0px;word-spacing:0px;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.19867153"
            x="-1.6390141"
            y="-52">
        ${text}
      </text>`
          : ''
      }
    </svg>`;
  }

  _svgPathWithHole =
    'm 4.146474,-35.748628 c 10.126057,-14.18252 11.986353,-15.62478 12.071769,-20.89599 0.117003,-7.22041 -5.64141,-13.16852 -12.861823,-13.28553 -7.2204144,-0.117 -13.1685241,5.64141 -13.2855266,12.86183 -0.085416,5.27121 1.727173,6.77298 11.3883956,21.27614 0.63406,0.94842 2.022798,0.97086 2.687185,0.0435 z m -1.090177,-15.66048 c -3.00851096,-0.0487 -5.4078439,-2.52712 -5.3590932,-5.53563 0.048752,-3.00851 2.52712424,-5.40785 5.5356362,-5.3591 3.008511,0.0487 5.407845,2.52713 5.359093,5.53564 -0.04875,3.00851 -2.527124,5.40784 -5.535636,5.35909 z';
  _svgPathWithoutHole =
    'm 4.2594941,-36.281489 c 9.8006699,-13.726784 11.6011509,-15.1227 11.6838369,-20.224505 0.113235,-6.988394 -5.460106,-12.745342 -12.4485035,-12.858576 -6.9883821,-0.113257 -12.745348,5.460108 -12.8585881,12.448502 -0.082674,5.101806 1.671668,6.555295 11.0224258,20.592405 0.6136824,0.917945 1.9577927,0.939653 2.6008289,0.04237 z';
}

/**
 * @typedef {Object} TEntityMapEntityInfo
 * @property {string} id
 * @property {Object} entity
 * @property {number} longitude
 * @property {number} latitude
 * @property {string} linkUrl
 * @property {string} [markerColor]
 * @property {string} [label]
 */
