import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskOfferToProcessTask } from '../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/types';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import {
  ProcessTaskDeselectedEvent,
  ProcessTaskSelectedEvent
} from '../multi-process-task-selection-widget/multi-process-task-selection-widget';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskOfferRelationsWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  private availableProcessTasks: Array<ProcessTask> = [];
  /**
   * ProcessTasks which are connected to the offer with a ProcessTaskOfferToProcessTask
   */
  private includedProcessTasks: Array<ProcessTask> = [];
  private availableProcessTaskOfferToProcessTasks: Array<ProcessTaskOfferToProcessTask> =
    [];
  private category: ProcessConfigurationCategory | null = null;
  private isAttached = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateAvailableProcessTaskOfferToProcessTasks.bind(this)
    );
    this.updateAvailableProcessTaskOfferToProcessTasks();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateAvailableProcessTasks.bind(this)
    );
    this.updateAvailableProcessTasks();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateCategory.bind(this)
    );
    this.updateCategory();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskOfferChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTasks();
      this.updateAvailableProcessTaskOfferToProcessTasks();
      this.updateCategory();
    }
  }

  private updateCategory(): void {
    if (
      this.processTaskOffer &&
      this.processTaskOffer.processConfigurationCategoryId
    ) {
      this.category =
        this.entityManager.processConfigurationCategoryRepository.getById(
          this.processTaskOffer.processConfigurationCategoryId
        );
    } else {
      this.category = null;
    }
  }

  private updateAvailableProcessTasks(): void {
    if (this.processTaskOffer) {
      this.availableProcessTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskOffer.ownerProcessTaskGroupId
        );
    } else {
      this.availableProcessTasks = [];
    }

    this.updateIncludedProcessTasks();
  }

  private updateAvailableProcessTaskOfferToProcessTasks(): void {
    if (this.processTaskOffer) {
      this.availableProcessTaskOfferToProcessTasks =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferId(
          this.processTaskOffer.id
        );
    } else {
      this.availableProcessTaskOfferToProcessTasks = [];
    }

    this.updateIncludedProcessTasks();
  }

  private updateIncludedProcessTasks(): void {
    this.includedProcessTasks = this.availableProcessTasks.filter((pt) => {
      return !!this.availableProcessTaskOfferToProcessTasks.find(
        (r) => r.ownerProcessTaskId === pt.id
      );
    });
  }

  private handleProcessTaskSelected(event: ProcessTaskSelectedEvent): void {
    assertNotNullOrUndefined(
      this.processTaskOffer,
      "can't ProcessTaskOfferRelationsWidget.handleProcessTaskSelected without a processTaskOffer"
    );
    const relation = this.availableProcessTaskOfferToProcessTasks.find(
      (r) => r.ownerProcessTaskId === event.detail.processTask.id
    );
    if (!relation) {
      this.entityManager.processTaskOfferToProcessTaskRepository.create({
        ownerProcessTaskGroupId: this.processTaskOffer.ownerProcessTaskGroupId,
        ownerProcessTaskId: event.detail.processTask.id,
        ownerUserGroupId: this.processTaskOffer.ownerUserGroupId,
        processTaskOfferId: this.processTaskOffer.id,
        temporaryGroupName: this.processTaskOffer.temporaryGroupName
      });
    }
  }

  private handleProcessTaskDeselected(event: ProcessTaskDeselectedEvent): void {
    const relation = this.availableProcessTaskOfferToProcessTasks.find(
      (r) => r.ownerProcessTaskId === event.detail.processTask.id
    );
    if (relation) {
      // the relations to the positions won't get deleted on purpose, so the user can just check the checkbox again and still have the connection to the positions
      this.entityManager.processTaskOfferToProcessTaskRepository.delete(
        relation
      );
    }
  }
}
