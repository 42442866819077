import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  MoreButtonChoice,
  MoreButton
} from '../../../aureliaComponents/more-button/more-button';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * @event {ActionTriggeredEvent} action-triggered
 */
@autoinject()
export class SketcherOverlayToolBarActionButtons {
  private domElement: HTMLElement;
  private combinedMoreButton: MoreButton | null = null;
  private Action = Action;

  private combinedMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk:
        'drawingComponents.sketcherOverlayToolBar.sketcherOverlayToolBarActionButtons.cancel',
      name: 'cancel'
    },
    {
      labelTk:
        'drawingComponents.sketcherOverlayToolBar.sketcherOverlayToolBarActionButtons.reset',
      name: 'reset'
    }
  ];

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  private handleActionButtonClick(action: Action): void {
    DomEventHelper.fireEvent<ActionTriggeredEvent>(this.domElement, {
      name: 'action-triggered',
      detail: {
        action
      }
    });
  }

  private handleCombinedActionButtonClick(): void {
    assertNotNullOrUndefined(
      this.combinedMoreButton,
      "can't SketcherOverlayToolBarActionButtons.handleCombinedActionButtonClick without a combinedMoreButton"
    );
    this.combinedMoreButton.open();
  }
}

export enum Action {
  CANCEL = 'cancel',
  DONE = 'done',
  RESET = 'reset'
}

export type ActionTriggeredEvent = NamedCustomEvent<
  'action-triggered',
  { action: Action }
>;
