import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';

import Hammer from 'hammerjs';

/**
 * @event current-item-changed `{CurrentItemChangedEvent}` - triggered whenever the current item changes
 * @event swiped-left `{SwipedEvent}` - triggered when the user swipes left
 * @event swiped-right `{SwipedEvent}` - triggered when the user swipes right
 */
@autoinject()
export class SwipeableContainer<T> {
  @bindable() public enabled = true;

  /**
   * If `items` and `currentItem` is set, swiping will navigate through the items array
   * and set the currentItem (the previous one if swiped right, the next one if swiped left).
   */
  @bindable() public items: Array<T> | null = null;

  /**
   * If `items` and `currentItem` is set, swiping will navigate through the items array
   * and set the currentItem (the previous one if swiped right, the next one if swiped left).
   */
  @bindable() public currentItem: T | null = null;

  /**
   * Maximum allowed time a swipe may take.
   */
  private swipeTimeout: number = 500;

  /**
   * Maximum allowed y travel distance time a swipe may take.
   */
  private swipeYThreshold: number = 100;

  /**
   * Minimum allowed x travel distance time a swipe may take.
   */
  private swipeXThreshold = 150;

  private domElement: HTMLElement;
  private leftBoundaryElement: HTMLElement | null = null;
  private rightBoundaryElement: HTMLElement | null = null;

  private leftBoundaryElementAnimator: ShowHideAnimator | null = null;
  private rightBoundaryElementAnimator: ShowHideAnimator | null = null;

  private hammerManager: HammerManager | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected bind(): void {
    assertNotNullOrUndefined(
      this.leftBoundaryElement,
      "can't SwipeableContainer.bind without a leftBoundaryElement"
    );
    assertNotNullOrUndefined(
      this.rightBoundaryElement,
      "can't SwipeableContainer.bind without a leftBoundaryElement"
    );

    this.leftBoundaryElementAnimator = new ShowHideAnimator(
      this.leftBoundaryElement
    );
    this.rightBoundaryElementAnimator = new ShowHideAnimator(
      this.rightBoundaryElement
    );
  }

  protected attached(): void {
    this.hammerManager = new Hammer.Manager(this.domElement);
    this.hammerManager.add(
      new Hammer.Swipe({
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: this.swipeXThreshold
      })
    );
    this.hammerManager.on('swipe', this.onSwipe.bind(this));
  }

  protected detached(): void {
    this.hammerManager?.stop(false);
    this.hammerManager?.destroy();
  }

  protected onSwipe(evt: HammerInput): void {
    if (this.shouldHandleSwipe(evt)) {
      const direction = evt.deltaX > 0 ? 'right' : 'left';
      this.fireSwipeEvent(direction);
      this.handleSwipe(direction);
    }
  }

  private shouldHandleSwipe(evt: HammerInput): boolean {
    return (
      this.enabled &&
      Math.abs(evt.deltaY) < this.swipeYThreshold &&
      evt.deltaTime < this.swipeTimeout
    );
  }

  private handleSwipe(direction: 'right' | 'left'): void {
    assertNotNullOrUndefined(
      this.rightBoundaryElementAnimator,
      "can't SwipeableContainer.handleSwipe without a rightBoundaryElementAnimator"
    );
    assertNotNullOrUndefined(
      this.leftBoundaryElementAnimator,
      "can't SwipeableContainer.handleSwipe without a leftBoundaryElementAnimator"
    );

    if (!this.items || !this.items.length) {
      return;
    }

    const index = this.currentItem ? this.items.indexOf(this.currentItem) : -1;
    if (index === -1) {
      return;
    }

    switch (direction) {
      case 'left':
        if (index < this.items.length - 1) {
          this.currentItem = this.items[index + 1] ?? null;
          this.fireCurrentItemChangedEvent();
        } else {
          this.animateBoundary(this.rightBoundaryElementAnimator);
        }
        break;

      case 'right':
        if (index > 0) {
          this.currentItem = this.items[index - 1] ?? null;
          this.fireCurrentItemChangedEvent();
        } else {
          this.animateBoundary(this.leftBoundaryElementAnimator);
        }
        break;

      default:
        throw new Error(`unhandled direction "${direction}"`);
    }
  }

  private animateBoundary(animator: ShowHideAnimator): void {
    animator.onDone(() => {
      void animator.fadeOut();
    }, true);

    void animator.fadeIn();
  }

  private fireSwipeEvent(direction: 'right' | 'left'): void {
    DomEventHelper.fireEvent<SwipeEvent>(this.domElement, {
      name: `swiped-${direction}`,
      detail: null
    });
  }

  private fireCurrentItemChangedEvent(): void {
    DomEventHelper.fireEvent<CurrentItemChangedEvent<T>>(this.domElement, {
      name: 'current-item-changed',
      detail: {
        currentItem: this.currentItem
      }
    });
  }
}

export type CurrentItemChangedEvent<T> = NamedCustomEvent<
  'current-item-changed',
  { currentItem: T | null }
>;
export type SwipeEvent = NamedCustomEvent<'swiped-left' | 'swiped-right'>;
