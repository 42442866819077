import { inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ZoomBoxPictureResizer } from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(AppEntityManager, SubscriptionManagerService)
export class ProcessTaskPictureOverviewDialog {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  _processTask = null;
  /** @type {Array<string>} */
  _joinedProjectIds = [];
  /** @type {Array<import('../../classes/EntityManager/entities/Picture/types').Picture>} */
  _pictures = [];
  /** @type {import('../../classes/EntityManager/entities/Picture/types').Picture|null} */
  _selectedPicture = null;
  /** @type {boolean} */
  _dialogOpened = false;
  /** @type {ZoomBoxPictureResizer|null} */
  _pictureResizer = null;

  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;
  /** @type {HTMLElement|null} */
  _zoomBoxElement = null;
  /** @type {HTMLElement|null} */
  _pictureElement = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;

    this._subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * @param {TOpenOptions} options
   */
  open(options) {
    this._processTask = options.processTask;

    this._joinedProjectIds = this._joinProcessTaskProjects(this._processTask);

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this._updatePictures.bind(this)
    );
    this._updatePictures();

    if (!this._dialog) {
      throw new Error('dialog is not referenced');
    }

    this._dialog.open();

    setTimeout(() => {
      if (!this._dialogOpened) {
        // has been closed in the meantime, no need to initialize it
        return;
      }

      if (!this._pictureElement || !this._zoomBoxElement) {
        throw new Error('no _pictureElement or _zoomBoxElement');
      }

      this._pictureResizer = new ZoomBoxPictureResizer(
        this._pictureElement,
        this._zoomBoxElement
      );
      this._pictureResizer.update();
    }, 0);
  }

  _handleDialogClosed() {
    this._processTask = null;
    this._subscriptionManager.disposeSubscriptions();

    this._leaveJoinedProjects();

    if (this._pictureResizer) {
      this._pictureResizer.destroy();
      this._pictureResizer = null;
    }
  }

  _leaveJoinedProjects() {
    this._joinedProjectIds.forEach((id) => {
      void this._entityManager.joinedProjectsManager.leaveProject(id, true);
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask} processTask
   * @returns {Array<string>}
   */
  _joinProcessTaskProjects(processTask) {
    const relations =
      this._entityManager.processTaskToProjectRepository.getPictureProcessTaskToProjectsByProcessTaskId(
        processTask.id
      );
    const generalRelations = relations.filter(
      (r) => r.processTaskAppointmentId == null
    );

    for (const relation of generalRelations) {
      this._entityManager.joinedProjectsManager.joinProject(
        relation.projectId,
        true,
        true
      );
    }

    return generalRelations.map((r) => r.projectId);
  }

  _updatePictures() {
    /** @type {Array<import('../../classes/EntityManager/entities/Picture/types').Picture>} */
    let pictures = [];

    this._joinedProjectIds.forEach((projectId) => {
      pictures = pictures.concat(
        this._entityManager.pictureRepository.getAllPicturesByProjectId(
          projectId
        )
      );
    });

    this._pictures = pictures;

    if (!this._selectedPicture && this._pictures.length) {
      this._selectedPicture = this._pictures[0];
    }
  }

  /**
   * @param {TOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TOpenOptions
 * @property {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask} processTask
 */
