import { autoinject } from 'aurelia-dependency-injection';
import { ProcessTaskGroupHelper } from 'common/Operations/ProcessTaskGroupHelper';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import {
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer
} from '../../../../computedValues/computers/ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { DataStorageHelper } from '../../../DataStorageHelper/DataStorageHelper';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import {
  ProcessTaskAppointmentUtils,
  TimeStampRange
} from '../ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { EntityName } from '../types';
import { CurrentUserService } from '../User/CurrentUserService';
import { User } from '../User/types';
import { ProcessTaskToProject } from './types';

@autoinject()
export class ProcessTaskToProjectAutoJoinProjectsService {
  private static MANAGED_PROJECT_IDS_KEY =
    'ProcessTaskToProjectAutoJoinProjectsService::managedProjectIds';

  private readonly rateLimitedAutoJoin = Utils.rateLimitFunction(
    this.autoJoin.bind(this),
    250
  );
  private readonly rateLimitedSaveManagedProjectIds = Utils.rateLimitFunction(
    this.saveManagedProjectIds.bind(this),
    500
  );
  private readonly subscriptionManager: SubscriptionManager;

  private managedProjectIds = new Set<string>();

  private currentUser: User | null = null;
  private dateInfoMap: ProcessTaskAppointmentDateInfoMap = new Map();
  private appointmentToUsersByAppointmentIdMap: ProcessTaskAppointmentToUsersByProcessTaskAppointmentId =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    const storedManagedProjectIds = await DataStorageHelper.getItem(
      ProcessTaskToProjectAutoJoinProjectsService.MANAGED_PROJECT_IDS_KEY
    );
    this.managedProjectIds = new Set(storedManagedProjectIds ?? []);

    this.subscriptionManager.addDisposable(
      this.rateLimitedAutoJoin.toCancelDisposable()
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.rateLimitedAutoJoin
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        this.rateLimitedAutoJoin();
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (dateInfoMap) => {
          this.dateInfoMap = dateInfoMap;
          this.rateLimitedAutoJoin();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: (appointmentToUsersByAppointmentIdMap) => {
          this.appointmentToUsersByAppointmentIdMap =
            appointmentToUsersByAppointmentIdMap;
          this.rateLimitedAutoJoin();
        }
      })
    );
  }

  public async flush(): Promise<void> {
    if (this.rateLimitedSaveManagedProjectIds.isPending()) {
      await this.saveManagedProjectIds();
    }
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.rateLimitedAutoJoin.cancel();
  }

  public autoJoin(): void {
    this.rateLimitedAutoJoin.cancel();

    const relations = this.entityManager.processTaskToProjectRepository
      .getAll()
      .filter(
        (r): r is ProcessTaskToProjectWithAppointmentId =>
          !!r.processTaskAppointmentId
      );
    const projectIdsToJoin = this.getProjectIdsToJoin(relations);

    const newManagedProjectIds = new Set(this.managedProjectIds);
    this.leaveProjects(projectIdsToJoin, newManagedProjectIds);
    this.joinProjects(projectIdsToJoin, newManagedProjectIds);
    this.managedProjectIds = newManagedProjectIds;
    this.rateLimitedSaveManagedProjectIds();
  }

  private getProjectIdsToJoin(
    relations: Array<ProcessTaskToProjectWithAppointmentId>
  ): Array<string> {
    const fromTo =
      ProcessTaskGroupHelper.getAutomaticSynchronizationFromToDate();
    const timestampRange: TimeStampRange = {
      from: fromTo.from.getTime(),
      to: fromTo.to.getTime()
    };

    return relations
      .filter((relation) =>
        this.relationNeedsToBeJoined(relation, timestampRange)
      )
      .map((r) => r.projectId);
  }

  private relationNeedsToBeJoined(
    relation: ProcessTaskToProjectWithAppointmentId,
    timestampRange: TimeStampRange
  ): boolean {
    const relations =
      this.appointmentToUsersByAppointmentIdMap.get(
        relation.processTaskAppointmentId
      ) ?? [];
    const hasRelationToCurrentUser = relations.some(
      (r) => r.userId === this.currentUser?.id
    );
    if (!hasRelationToCurrentUser) {
      return false;
    }

    const dateInfo =
      this.dateInfoMap.get(relation.processTaskAppointmentId) ?? null;
    return ProcessTaskAppointmentUtils.overlapsWithTimestampRange(
      dateInfo,
      timestampRange
    );
  }

  private leaveProjects(
    projectIdsToJoin: Array<string>,
    managedProjectIds: Set<string>
  ): void {
    const projectIdsToLeave = Array.from(managedProjectIds.values()).filter(
      (projectId) => !projectIdsToJoin.includes(projectId)
    );

    for (const projectId of projectIdsToLeave) {
      void this.entityManager.joinedProjectsManager.leaveProject(projectId);
      managedProjectIds.delete(projectId);
    }
  }

  private joinProjects(
    projectIdsToJoin: Array<string>,
    managedProjectIds: Set<string>
  ): void {
    for (const projectId of projectIdsToJoin) {
      void this.entityManager.joinedProjectsManager.joinProject(
        projectId,
        true
      );
      managedProjectIds.add(projectId);
    }
  }

  private saveManagedProjectIds(): Promise<void> {
    return DataStorageHelper.setItem(
      ProcessTaskToProjectAutoJoinProjectsService.MANAGED_PROJECT_IDS_KEY,
      Array.from(this.managedProjectIds)
    );
  }
}

type ProcessTaskToProjectWithAppointmentId = ProcessTaskToProject & {
  processTaskAppointmentId: string;
};
