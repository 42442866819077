import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskMeasurePointEntityInfo } from 'common/Types/Entities/ProcessTaskMeasurePoint/createProcessTaskMeasurePointEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskMeasurePoint } from './types';

export const processTaskMeasurePointEntityInfo =
  createProcessTaskMeasurePointEntityInfo<ProcessTaskMeasurePoint>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
