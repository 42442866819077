import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationStepAutoForm } from './types';

export class ProcessConfigurationStepAutoFormRepository extends AppEntityRepository<EntityName.ProcessConfigurationStepAutoForm> {
  public getByProcessConfigurationStepId(
    processConfigurationStepId: string
  ): Array<ProcessConfigurationStepAutoForm> {
    return this.getAll().filter(
      (autoForm) =>
        autoForm.processConfigurationStepId === processConfigurationStepId
    );
  }
}
