import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationCategoryEntityInfo } from 'common/Types/Entities/ProcessConfigurationCategory/createProcessConfigurationCategoryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationCategory } from './types';

export const processConfigurationCategoryEntityInfo =
  createProcessConfigurationCategoryEntityInfo<ProcessConfigurationCategory>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
