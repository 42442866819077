import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Logger } from '../../classes/Logger/Logger';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';

@autoinject()
export class SignPdfReportDialog {
  private dialog: RecordItDialog | null = null;

  private subscriptionManager: SubscriptionManager;

  private isConnected = false;

  private reportId: string | null = null;
  private t2gUsername = '';
  private t2gPassword = '';
  private rememberCredentials = false;

  private isProcessing = false;

  private signErrorTk: string | null = null;

  public static async open(options: SignPdfReportDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private i18n: I18N,
    private entityManager: AppEntityManager,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  private open(options: SignPdfReportDialogOptions): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
    this.reportId = options.reportId;
    this.signErrorTk = null;
    this.dialog?.open();
  }

  private async startSigning(): Promise<void> {
    assertNotNullOrUndefined(this.reportId, 'No report to sign');
    if (this.isProcessing) {
      return;
    }
    if (!this.t2gUsername || !this.t2gPassword) {
      this.signErrorTk = 'WebsocketErrors.ERROR_PDFSIGNER_BAD_CREDENTIALS';
      return;
    }
    this.signErrorTk = null;
    this.isProcessing = true;
    try {
      await this.socketService.pdfSignerModuleEndpoints.sign({
        reportId: this.reportId,
        username: this.t2gUsername,
        password: this.t2gPassword
      });
      this.dialog?.close();
    } catch (e) {
      if (e instanceof WebSocketHandlerError) {
        this.signErrorTk = `WebsocketErrors.${e.type}`;
      } else {
        this.signErrorTk = 'WebsocketErrors.ERROR_UNKNOWN';
        Logger.logError({ error: e });
      }
    } finally {
      this.isProcessing = false;
    }
  }

  protected handleDialogClosed(): void {
    this.t2gUsername = '';
    this.t2gPassword = '';
    this.isProcessing = false;
    this.subscriptionManager.disposeSubscriptions();
  }
}

export type SignPdfReportDialogOptions = {
  reportId: string;
};
