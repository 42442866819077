import { computedFrom } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { ProjectThingSectionProperty } from '../../../classes/EntityManager/entities/Property/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { BaseThingSectionPropertyAdapter } from './BaseThingSectionPropertyAdapter';
import { CreationConfig } from './PropertyAdapter';

export class ProjectThingSectionPropertyAdapter extends BaseThingSectionPropertyAdapter<ProjectThingSectionProperty> {
  private readonly project: Project;

  constructor(options: ProjectThingSectionPropertyAdapterOptions) {
    super({
      ...options,
      fetchPropertiesFromEntityManager: () => {
        return options.entityManager.propertyRepository.getByThingSectionAndProjectId(
          {
            thingSectionId: options.thingSection.id,
            projectId: options.project.id
          }
        );
      },
      location: ThingSectionConfigPropertyLocation.PROJECT
    });

    this.project = options.project;
  }

  public createPropertyFromConfig(
    config: CreationConfig<TDefaultPropertyConfig>
  ): ProjectThingSectionProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      projectThingSectionId: this.thingSection.id,
      ownerProjectId: this.project.id,
      ownerUserGroupId: this.thingSection.ownerUserGroupId
    }) as ProjectThingSectionProperty;
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.ProjectThingSectionPropertyAdapter.updatePropertiesButton';
  }

  public getProject(): Project {
    return this.project;
  }
}

export type ProjectThingSectionPropertyAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  thingSection: ThingSection;
  project: Project;
};
