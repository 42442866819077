import { autoinject, bindable } from 'aurelia-framework';
import { ProcessConfigurationCustomPositionTypeConfiguration } from 'common/Types/ProcessConfigurationCustomPositionTypeConfiguration';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event value-changed
 */
@autoinject()
export class CustomProcessTaskPositionTypeSelect {
  @bindable()
  public value: string | null = null;

  @bindable()
  public processTaskGroupId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  private subscriptionManager: SubscriptionManager;
  private options: Array<Option> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly domElement: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateOptions.bind(this)
    );
    this.updateOptions();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskGroupIdChanged(): void {
    if (this.isAttached) {
      this.updateOptions();
    }
  }

  private updateOptions(): void {
    const typesConfig = this.getCustomTypesConfig();
    if (typesConfig) {
      this.options = this.generateOptions(typesConfig);
    } else {
      this.options = [];
    }
  }

  private getCustomTypesConfig(): ProcessConfigurationCustomPositionTypeConfiguration | null {
    const processTaskGroup = this.processTaskGroupId
      ? this.entityManager.processTaskGroupRepository.getById(
          this.processTaskGroupId
        )
      : null;
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const typesConfigJson =
      processConfiguration?.customPositionTypeConfigurationJson;
    if (typesConfigJson) {
      return JSON.parse(typesConfigJson);
    }

    return null;
  }

  private generateOptions(
    typesConfig: ProcessConfigurationCustomPositionTypeConfiguration
  ): Array<Option> {
    return Object.values(typesConfig.typesMap).map((typeConfig) => ({
      label: typeConfig.displayName,
      value: typeConfig.name
    }));
  }

  private handleSelectChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}

type Option = {
  label: string;
  value: string;
};
