import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { ProcessConfigurationDevice } from './types';

export class ProcessConfigurationDeviceUtils {
  public static sortOptions: ProcessConfigurationDeviceSortOptions = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return aName.localeCompare(bName);
      }
    }
  };
}

export type ProcessConfigurationDeviceSortOptions = {
  name: ProcessConfigurationDeviceSortOption;
};

export type ProcessConfigurationDeviceSortOption =
  SorterSortOption<ProcessConfigurationDevice>;
