import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { EntityInfoUtils } from '@record-it-npm/synchro-common';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { GetProcessTaskGroupsAndTasksResponseSuccess } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { CreateProcessTaskAppointmentService } from '../../classes/EntityManager/entities/ProcessTaskAppointment/CreateProcessTaskAppointmentService';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskToProjectFormCreationService } from '../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectFormCreationService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesGenerator
} from '../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import {
  SingleSocketRequest,
  SingleSocketRequestService
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { LoadProcessTaskGroupWithEntitiesService } from '../../services/LoadProcessTaskGroupWithEntitiesService';

@autoinject()
export class CreateAppointmentWorkerDialog {
  protected static _instance: CreateAppointmentWorkerDialog | null = null;

  private static TEMPORARY_GROUP_NAME = 'CreateAppointmentWorkerDialog';

  protected dialog: RecordItDialog | null = null;

  protected forms: Array<ProcessConfigurationForm> = [];
  protected selectedFormId: string | null = null;
  protected processTasks: Array<ProcessTask> = [];
  protected processTaskOptions: Array<{ id: string; label: string }> = [];
  private processTaskGroups: Array<ProcessTaskGroup> = [];
  protected selectedProcessTaskId: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  private readonly getTaskGroupsAndTasksRequest: SingleSocketRequest<
    {},
    GetProcessTaskGroupsAndTasksResponseSuccess
  >;

  constructor(
    subManagerService: SubscriptionManagerService,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly createProcessTaskAppointmentService: CreateProcessTaskAppointmentService,
    private readonly processTaskToProjectFormCreationService: ProcessTaskToProjectFormCreationService,
    private readonly currentUserService: CurrentUserService,
    singleSocketRequestService: SingleSocketRequestService,
    socketService: SocketService,
    private readonly loadProcessTaskGroupWithEntitiesService: LoadProcessTaskGroupWithEntitiesService
  ) {
    this.subscriptionManager = subManagerService.create();

    this.getTaskGroupsAndTasksRequest =
      singleSocketRequestService.createRequest({
        requestCallback: () => {
          return new Promise<GetProcessTaskGroupsAndTasksResponseSuccess>(
            (resolve, reject) => {
              socketService.getProcessTaskGroupsAndTasks({}, (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(
                    new Error("couldn't fetch process task groups and tasks")
                  );
                }
              });
            }
          );
        }
      });
  }

  public static async open(): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    await view.getViewModel().open();
  }

  private async open(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open CreateAppointmentWorkerDialog without a RecordItDialog'
    );

    this.updateForms();
    await this.updateProcessTasks();
    this.updateProcessTaskNamesByProcessTaskId();
    this.updateProcessTaskOptions();

    this.dialog.open();
  }

  private updateForms(): void {
    if (!this.selectedProcessTaskId) {
      return;
    }

    const processTaskGroupId = this.processTasks.find(
      (p) => p.id === this.selectedProcessTaskId
    )?.ownerProcessTaskGroupId;
    assertNotNullOrUndefined(
      processTaskGroupId,
      'cannot updateForms if the processTaskGroupId is not available'
    );
    const processConfigurationId = this.processTaskGroups.find(
      (p) => p.id === processTaskGroupId
    )?.processConfigurationId;
    assertNotNullOrUndefined(
      processConfigurationId,
      'cannot updateForms if the processConfigurationId is not available'
    );
    this.forms =
      this.entityManager.processConfigurationFormRepository.getByProcessConfigurationId(
        processConfigurationId
      );
  }

  private async updateProcessTasks(): Promise<void> {
    const resp = await this.getTaskGroupsAndTasksRequest.send({});
    this.processTasks = resp.entities.processTasks
      .map((p) => {
        EntityInfoUtils.applyDefaultValues(
          this.entityManager.processTaskRepository.getEntityInfo(),
          p
        );
        return p as ProcessTask;
      })
      .filter((p) => {
        const configuration =
          this.entityManager.processConfigurationStepRepository.getById(
            p.currentProcessConfigurationStepId
          );
        return configuration && !configuration.archive;
      });
    this.processTaskGroups = resp.entities.processTaskGroups.map((p) => {
      EntityInfoUtils.applyDefaultValues(
        this.entityManager.processTaskGroupRepository.getEntityInfo(),
        p
      );
      return p as ProcessTaskGroup;
    });
  }

  private updateProcessTaskNamesByProcessTaskId(): void {
    const generator = new ProcessTaskNamesGenerator(this.entityManager);

    for (const processTask of this.processTasks) {
      this.processTaskNamesByProcessTaskId.set(
        processTask.id,
        generator.generate(processTask)
      );
    }
  }

  private updateProcessTaskOptions(): void {
    this.processTaskOptions = this.processTasks.map((pt) => ({
      id: pt.id,
      label:
        this.processTaskNamesByProcessTaskId.get(pt.id)
          ?.nameWithThingAndPerson ?? ''
    }));
  }

  protected handleSelectedFormIdChanged(): void {
    this.updateForms();
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.selectedProcessTaskId,
      'cannot handleAcceptButtonClicked without a selectedProcessTaskId'
    );

    if (
      !this.entityManager.processTaskRepository.getById(
        this.selectedProcessTaskId
      )
    ) {
      await this.loadProcessTaskGroupWithEntitiesService.loadByProcessTaskId({
        processTaskId: this.selectedProcessTaskId,
        temporaryGroupName: CreateAppointmentWorkerDialog.TEMPORARY_GROUP_NAME,
        onProcessTaskLoaded: () => {}
      });
    }

    const selectedProcessTask = this.processTasks.find(
      (i) => i.id === this.selectedProcessTaskId
    );
    const selectedForm = this.forms.find((i) => i.id === this.selectedFormId);

    assertNotNullOrUndefined(
      selectedProcessTask,
      'cannot handleAcceptButtonClicked without a selectedProcessTask'
    );
    assertNotNullOrUndefined(
      selectedForm,
      'cannot handleAcceptButtonClicked without a selectedForm'
    );

    const appointmentStartDate = new Date();
    const appointmentEndDate = DateUtils.getDateWithHourOffset(new Date(), 1);

    const appointment =
      await this.createProcessTaskAppointmentService.createAppointment(
        selectedProcessTask,
        [],
        []
      );
    const form = this.processTaskToProjectFormCreationService.createForm(
      selectedProcessTask,
      appointment,
      selectedForm
    );
    this.entityManager.processTaskAppointmentToUserRepository.create({
      processTaskAppointmentId: appointment.id,
      userId: this.currentUserService.getRequiredCurrentUser().id,
      dateFrom: appointmentStartDate.toISOString(),
      dateTo: appointmentEndDate.toISOString(),
      ownerProcessTaskGroupId: selectedProcessTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: selectedProcessTask.id,
      ownerUserGroupId: selectedProcessTask.ownerUserGroupId
    });

    this.router.navigateToRoute('show_process_appointment', {
      appointment_id: appointment.id,
      open_form: form.processTaskToProject.id
    });
  }
}
