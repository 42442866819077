import { bindable, autoinject } from 'aurelia-framework';
import _ from 'lodash';
import { DateTimePickerNumberInput } from '../date-time-picker-number-input/date-time-picker-number-input';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { DatePickerDialog } from '../../../dialogs/date-picker-dialog/date-picker-dialog';

/**
 * @event {ValueChangedEvent} value-changed
 * @event {InputBlurEvent} input-blur
 */
@autoinject()
export class DateTimePickerDateInput {
  @bindable()
  public value: Date | null = null;

  /**
   * currently inputted value, read only
   */
  @bindable()
  public currentValue: Date | null = null;

  @bindable()
  public enabled: boolean = false;

  private domElement: HTMLElement;

  private dayValue: string | null = null;
  private monthValue: string | null = null;
  private yearValue: string | null = null;
  private isAttached: boolean = false;

  private dayInput: DateTimePickerNumberInput | null = null;
  private monthInput: DateTimePickerNumberInput | null = null;
  private yearInput: DateTimePickerNumberInput | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public focus(): void {
    assertNotNullOrUndefined(this.dayInput, 'dayInput is not available');
    this.dayInput.focus();
  }

  public isFocused(): boolean {
    assertNotNullOrUndefined(this.dayInput, 'dayInput is not available');
    assertNotNullOrUndefined(this.monthInput, 'monthInput is not available');
    assertNotNullOrUndefined(this.yearInput, 'yearInput is not available');

    return (
      this.dayInput.isFocused() ||
      this.monthInput.isFocused() ||
      this.yearInput.isFocused()
    );
  }

  protected attached(): void {
    this.isAttached = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private valueChanged(): void {
    this.updateInternalValues();
  }

  private handleDayInputValueChanged(): void {
    assertNotNullOrUndefined(this.monthInput, 'monthInput is not available');

    if (this.dayValue && this.dayValue.length === 2) {
      this.monthInput.focus();
    }

    this.updateCurrentValue();
  }

  private handleMonthInputValueChanged(): void {
    assertNotNullOrUndefined(this.yearInput, 'yearInput is not available');

    if (this.monthValue && this.monthValue.length === 2) {
      this.yearInput.focus();
    }

    this.updateCurrentValue();
  }

  private handleYearInputValueChanged(): void {
    this.updateCurrentValue();
  }

  private handleInputBlur(): void {
    setTimeout(() => {
      if (!this.isAttached || !this.isFocused()) {
        this.updateValue();

        setTimeout(() => {
          DomEventHelper.fireEvent<InputBlurEvent>(this.domElement, {
            name: 'input-blur',
            detail: null
          });
        }, 0);
      }
    }, 10);
  }

  protected handleOpenDatePickerClick(): void {
    if (!this.enabled) return;

    void DatePickerDialog.open({
      date: this.value || new Date(),
      onDateSelected: (date) => {
        this.setValue(date, ValueChangedReason.DIALOG);
      }
    });
  }

  private updateValue(): void {
    const date: Date | null = this.getCurrentDate();

    if (date) {
      if (date.toString() !== 'Invalid Date') {
        this.setValue(date, ValueChangedReason.NOT_SPECIFIED);
      } else {
        this.updateInternalValues();
      }
    } else {
      this.setValue(null, ValueChangedReason.NOT_SPECIFIED);
    }
  }

  private updateCurrentValue(): void {
    const date: Date | null = this.getCurrentDate();

    if (date && date.toString() !== 'Invalid Date') {
      this.currentValue = date;
    } else {
      this.currentValue = null;
    }
  }

  private getCurrentDate(): Date | null {
    if (this.dayValue && this.monthValue && this.yearValue) {
      const year = _.padStart(this.yearValue, 4, '0');
      const month = _.padStart(this.monthValue, 2, '0');
      const day = _.padStart(this.dayValue, 2, '0');

      return new Date(`${year}-${month}-${day}T00:00:00.00`);
    }

    return null;
  }

  private updateInternalValues(): void {
    if (this.value) {
      const day = this.value.getDate();
      const month = this.value.getMonth() + 1;
      const year = this.value.getFullYear();

      this.dayValue = _.padStart(day.toString(), 2, '0');
      this.monthValue = _.padStart(month.toString(), 2, '0');
      this.yearValue = year.toString();
    } else {
      this.dayValue = null;
      this.monthValue = null;
      this.yearValue = null;
    }

    this.updateCurrentValue();
  }

  private setValue(value: Date | null, reason: ValueChangedReason): void {
    if (this.value === value) {
      return;
    }

    if (this.value && value && this.value.getTime() === value.getTime()) {
      return;
    }

    this.value = value;

    setTimeout(() => {
      DomEventHelper.fireEvent<ValueChangedEvent>(this.domElement, {
        name: 'value-changed',
        detail: { reason }
      });
    }, 0);
  }
}

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { reason: ValueChangedReason }
>;
export enum ValueChangedReason {
  DIALOG = 'dialog',
  NOT_SPECIFIED = 'not-specified'
}

export type InputBlurEvent = NamedCustomEvent<'input-blur', null>;
