import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProcessTaskDto } from './ProcessTaskDto';

export function createProcessTaskEntityInfo<
  TEntity extends ProcessTaskDto<string, string>
>(
  options: CreateEntityInfoOptions<ProcessTaskDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTask,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTask,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing
        },
        currentProcessConfigurationStepId: {
          name: 'currentProcessConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep
        },
        processConfigurationActionStatusId: {
          name: 'processConfigurationActionStatusId',
          ref: EntityName.ProcessConfigurationActionStatus,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        /**
         * general note, for all users
         */
        note: {
          name: 'note',
          defaultValue: null
        },
        customActionStatusName: {
          name: 'customActionStatusName',
          defaultValue: null
        },
        customActionStatusAbbreviation: {
          name: 'customActionStatusAbbreviation',
          defaultValue: null
        },
        customId: {
          name: 'customId',
          defaultValue: null
        },
        order: {
          name: 'order',
          defaultValue: 0
        },
        sequenceNumber: {
          name: 'sequenceNumber',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
