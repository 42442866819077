import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskGroupEntityInfo } from 'common/Types/Entities/ProcessTaskGroup/createProcessTaskGroupEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskGroup } from './types';

export const processTaskGroupEntityInfo =
  createProcessTaskGroupEntityInfo<ProcessTaskGroup>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
