import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { ProcessTaskDeviceGroups } from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';

/**
 * a component which groups the devices according to the config and renders a list item for each device
 *
 *
 * @replaceable item-template
 * available variables in item-template
 * | type | name | description |
 * |------|------|-------------|
 * | ProcessTaskDevice | device | the device to display |
 */
@autoinject()
export class ProcessTaskDeviceList {
  @bindable()
  public groupedDevices: ProcessTaskDeviceGroups = [];
}
