import { inject, bindable } from 'aurelia-framework';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager)
export class UserGroupListItem {
  @bindable userGroup;
  @bindable currentUser;

  /** @type {HTMLElement} */
  _domElement;
  /**@type {HTMLElement}*/
  _listItemElement;

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  highlight() {
    EntityListItemHelper.highlightListItemElement(this._listItemElement);
  }

  _handleEditUserGroupClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-user-group-clicked',
      detail: null
    });
  }

  _userCanAdministerUserGroup() {
    return PermissionHelper.userCanAdministerUserGroup(
      this.currentUser,
      this.userGroup
    );
  }

  /**
   * @param {string} userId
   * @returns {string}
   * @private
   */
  _getUsernameById(userId) {
    const user = this._entityManager.userRepository.getById(userId);
    return user ? `${user.username} (${user.email})` : '';
  }
}
