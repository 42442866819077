import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  Credentials,
  NativeBiometric
} from '@capgo/capacitor-native-biometric';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { UrlManager } from '../../classes/UrlManager';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';

@autoinject()
export class BiometricAuthenticationService {
  private static SHOW_BIOMETRIC_AUTH_HINT =
    'BiometricAuthService::showHintOnLogin';

  private biometricAuthenticationAvailable = false;
  private userCredentialsSavedOnDevice = false;
  private showBiometricAuthenticationHintOnLogin = false;

  constructor(private readonly i18n: I18N) {}

  public async init(): Promise<void> {
    if (!DeviceInfoHelper.isApp()) return;

    const result = await NativeBiometric.isAvailable();
    this.biometricAuthenticationAvailable = result.isAvailable;

    if (!this.biometricAuthenticationAvailable) return;

    await this.updateUserCredentialsSavedOnDevice();

    const showBiometricHint = await DataStorageHelper.getItem(
      BiometricAuthenticationService.SHOW_BIOMETRIC_AUTH_HINT,
      'permanent'
    );

    if (this.userCredentialsSavedOnDevice && showBiometricHint !== false) {
      this.setShowBiometricAuthenticationHintOnLogin(false);
      this.showBiometricAuthenticationHintOnLogin = false;
    } else {
      this.showBiometricAuthenticationHintOnLogin = showBiometricHint !== false;
    }
  }

  public getBiometricAuthenticationAvailable(): boolean {
    return this.biometricAuthenticationAvailable;
  }

  public getUserCredentialsSavedOnDevice(): boolean {
    return this.userCredentialsSavedOnDevice;
  }

  public getShowBiometricAuthenticationHintOnLogin(): boolean {
    return this.showBiometricAuthenticationHintOnLogin;
  }

  public setShowBiometricAuthenticationHintOnLogin(show: boolean): void {
    void DataStorageHelper.setItem(
      BiometricAuthenticationService.SHOW_BIOMETRIC_AUTH_HINT,
      show,
      'permanent'
    );
    this.showBiometricAuthenticationHintOnLogin = show;
  }

  public async retrieveCredentials(): Promise<Credentials | null> {
    if (!this.userCredentialsSavedOnDevice) return null;

    try {
      const credentials = await NativeBiometric.getCredentials({
        server: UrlManager.webFolder
      });

      return credentials;
    } catch (error) {
      if (
        error instanceof Error &&
        this.isAcceptableGetCredentialsError(error)
      ) {
        return null;
      }
      throw error;
    }
  }

  public async verifyIdentity(
    title: string,
    reason: string
  ): Promise<VerifyIdentityResult> {
    try {
      await NativeBiometric.verifyIdentity({
        reason: reason,
        title: title,
        subtitle: '',
        description: ''
      });
      return { success: true };
    } catch (error) {
      if (
        error instanceof Error &&
        this.isAcceptableVerifyIdentityError(error)
      ) {
        return { success: false };
      }
      throw error;
    }
  }

  public async setCredentials(email: string, password: string): Promise<void> {
    try {
      await NativeBiometric.setCredentials({
        username: email,
        password: password,
        server: UrlManager.webFolder
      });
      this.userCredentialsSavedOnDevice = true;

      NotificationHelper.notifySuccess(
        this.i18n.tr(
          'services.BiometricAuthenticationService.saveCredentialsSuccess'
        )
      );
    } catch (error) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.BiometricAuthenticationService.saveCredentialsFailed'
        )
      );
      throw error;
    }
  }

  public async deleteCredentials(): Promise<void> {
    try {
      await NativeBiometric.deleteCredentials({
        server: UrlManager.webFolder
      });
      this.userCredentialsSavedOnDevice = false;

      NotificationHelper.notifySuccess(
        this.i18n.tr(
          'services.BiometricAuthenticationService.deleteCredentialsSuccess'
        )
      );
    } catch (error) {
      NotificationHelper.notifyDanger(
        this.i18n.tr(
          'services.BiometricAuthenticationService.deleteCredentialsFailed'
        )
      );
      throw error;
    }
  }

  public async showbiometricAuthenticationHintDialog(): Promise<DialogConfirmationResult> {
    try {
      await GlobalCustomDialog.open({
        titleTk: 'classes.Dialogs.biometricAuthHint.title',
        textTk: 'classes.Dialogs.biometricAuthHint.info',
        buttons: [
          {
            textTk: 'general.ok',
            className: 'record-it-button-primary'
          },
          {
            textTk: 'general.cancel',
            type: ButtonType.CANCEL
          }
        ]
      });

      return { confirmed: true };
    } catch (error) {
      if (error instanceof Error && error.message === 'DialogCanceled') {
        this.setShowBiometricAuthenticationHintOnLogin(false);
        return { confirmed: false };
      }
      throw error;
    }
  }

  private async updateUserCredentialsSavedOnDevice(): Promise<void> {
    try {
      const credentials = await NativeBiometric.getCredentials({
        server: UrlManager.webFolder
      });

      this.userCredentialsSavedOnDevice = credentials != null;
    } catch (error) {
      if (
        error instanceof Error &&
        this.isAcceptableGetCredentialsError(error)
      ) {
        return;
      }
      throw error;
    }
  }

  private isAcceptableGetCredentialsError(error: Error): boolean {
    return (
      (DeviceInfoHelper.isAndroidDevice() &&
        error.message === 'No credentials found') ||
      (DeviceInfoHelper.isIOSDevice() &&
        error.message ===
          'The operation couldn’t be completed. (CapgoCapacitorNativeBiometric.NativeBiometric.KeychainError error 1.)')
    );
  }

  private isAcceptableVerifyIdentityError(error: Error): boolean {
    return (
      (DeviceInfoHelper.isAndroidDevice() && error.message === 'Cancel') ||
      (DeviceInfoHelper.isIOSDevice() &&
        error.message === 'Authentication canceled.')
    );
  }
}

type VerifyIdentityResult = {
  success: boolean;
};

type DialogConfirmationResult = {
  confirmed: boolean;
};
