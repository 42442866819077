import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointmentUtils } from '../../classes/EntityManager/entities/ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import {
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer
} from '../../computedValues/computers/ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event edit-button-clicked
 */
@autoinject()
export class ProcessTaskAppointmentListItem {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  private panelOpened: boolean = false;
  private isAttached: boolean = false;
  protected processConfigurationStep: ProcessConfigurationStep | null = null;

  private dateInfoMap: ProcessTaskAppointmentDateInfoMap = new Map();
  private processTaskAppointmentToUsersByProcessTaskAppointmentId: ProcessTaskAppointmentToUsersByProcessTaskAppointmentId =
    new Map();

  protected dateInfo: ProcessTaskAppointmentDateInfo | null = null;
  protected assigneeUserIds: Array<string> = [];

  private listItemElement: HTMLElement | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentListItem,
        propertyName: 'processTaskAppointment'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateProcessConfigurationStep.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationStep.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (dateInfoMap) => {
          this.dateInfoMap = dateInfoMap;
          this.updateDateInfo();
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: (processTaskAppointmentToUsersByProcessTaskAppointmentId) => {
          this.processTaskAppointmentToUsersByProcessTaskAppointmentId =
            processTaskAppointmentToUsersByProcessTaskAppointmentId;
          this.updateAssigneeUserIds();
        }
      })
    );

    this.updateProcessConfigurationStep();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateProcessConfigurationStep();
      this.updateDateInfo();
      this.updateAssigneeUserIds();
    }
  }

  private updateProcessConfigurationStep(): void {
    if (
      this.processTaskAppointment &&
      this.processTaskAppointment.processConfigurationStepId
    ) {
      this.processConfigurationStep =
        this.entityManager.processConfigurationStepRepository.getById(
          this.processTaskAppointment.processConfigurationStepId
        );
    } else {
      this.processConfigurationStep = null;
    }
  }

  private updateDateInfo(): void {
    if (this.processTaskAppointment) {
      this.dateInfo =
        this.dateInfoMap.get(this.processTaskAppointment.id) ?? null;
    } else {
      this.dateInfo = null;
    }
  }

  private updateAssigneeUserIds(): void {
    if (this.processTaskAppointment) {
      const relations =
        this.processTaskAppointmentToUsersByProcessTaskAppointmentId.get(
          this.processTaskAppointment.id
        ) ?? [];
      this.assigneeUserIds = relations.map((r) => r.userId);
    } else {
      this.assigneeUserIds = [];
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEditButtonClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentListItem.handleDeleteButtonClick without processTaskAppointment"
    );
    void Dialogs.deleteEntityDialog(this.processTaskAppointment).then(() => {
      if (this.processTaskAppointment) {
        this.entityManager.processTaskAppointmentRepository.delete(
          this.processTaskAppointment
        );
        void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
          entityName: EntityName.ProcessTaskAppointment,
          entity: this.processTaskAppointment,
          displayNameAtLogTime: this.processTaskAppointment.name
        });
      }
    });
  }

  protected appointmentWarningVisible(
    finishedAt: string | null,
    dateTo: string | null
  ): boolean {
    return ProcessTaskAppointmentUtils.isInPastAndNotFinished(
      finishedAt,
      dateTo
    );
  }

  protected getAssigneeSeparator(
    index: number,
    assigneeUserIdsLength: number
  ): string {
    if (index < assigneeUserIdsLength - 1) {
      return ' - ';
    }

    return '';
  }
}
