export class SearchUtils {
  private constructor() {}

  private static characterReplacementMap: Map<string, string> = new Map([
    [' ', ''],
    ['-', ''],
    ['\n', ''],
    ['ä', 'ae'],
    ['ö', 'oe'],
    ['ü', 'ue'],
    ['ß', 'ss']
  ]);

  public static prepareSearchTerm(searchTerm: string | null): string | null {
    if (searchTerm == null) {
      return null;
    }

    const prepared = this.replaceCharacters({
      lowercaseSearchTerm: searchTerm.toLocaleLowerCase()
    });

    if (prepared === '') {
      return null;
    }

    return prepared;
  }

  private static replaceCharacters({
    lowercaseSearchTerm
  }: {
    lowercaseSearchTerm: string;
  }): string {
    let replaced: string = '';

    for (let i = 0; i < lowercaseSearchTerm.length; i++) {
      const char = lowercaseSearchTerm[i] as string;
      const replacement = this.characterReplacementMap.get(char);

      if (replacement != null) {
        replaced += replacement;
      } else {
        replaced += char;
      }
    }

    return replaced;
  }
}
