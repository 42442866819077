import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationStepAutoAppointmentEntityInfo } from 'common/Types/Entities/ProcessConfigurationStepAutoAppointment/createProcessConfigurationStepAutoAppointmentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationStepAutoAppointment } from './types';

export const processConfigurationStepAutoAppointmentEntityInfo =
  createProcessConfigurationStepAutoAppointmentEntityInfo<ProcessConfigurationStepAutoAppointment>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
