import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { QuestionCatalogueToQuestionSetDto } from './QuestionCatalogueToQuestionSetDto';

export function createQuestionCatalogueToQuestionSetEntityInfo<
  TEntity extends QuestionCatalogueToQuestionSetDto<string, string>
>(
  options: CreateEntityInfoOptions<
    QuestionCatalogueToQuestionSetDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.QuestionCatalogueToQuestionSet,
  TEntity
> {
  return {
    entityName: EntityName.QuestionCatalogueToQuestionSet,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      QuestionCatalogueToQuestionSetDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        questionCatalogueId: {
          name: 'questionCatalogueId',
          ref: EntityName.QuestionCatalogue,
          cascadeDeleteLocally: true
        },
        questionSetId: {
          name: 'questionSetId',
          ref: EntityName.QuestionSet,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
