import { computedFrom, Disposable } from 'aurelia-binding';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProjectEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

import {
  EntityWidgetHandle,
  EntityWidgetHandleSubscribeOptions
} from './EntityWidgetHandle';
import { EditProjectDialog } from '../../dialogs/edit-project-dialog/edit-project-dialog';

export class ProjectWidgetHandle implements EntityWidgetHandle {
  private entityManager: AppEntityManager;

  private projectActionService: ProjectActionService;

  private subscriptionManagerService: SubscriptionManagerService;

  private socketService: SocketService;

  private permissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: MoreButtonActionName.EDIT,
      labelTk: 'homePageComponents.entityWidget.projectEntityWidget.edit',
      iconClass: 'fal fa-pencil'
    },
    {
      name: MoreButtonActionName.PARAMETERS,
      labelTk: 'homePageComponents.entityWidget.projectEntityWidget.parameters',
      iconClass: 'fal fa-cog'
    },
    {
      name: MoreButtonActionName.DUPLICATE,
      labelTk:
        'homePageComponents.entityWidget.projectEntityWidget.duplicateAndOpenNew',
      iconClass: 'fal fa-copy',
      disabledContext: this,
      disabledPropertyName: 'isOffline'
    },
    {
      name: MoreButtonActionName.HIDE,
      labelTk: 'homePageComponents.entityWidget.hide',
      iconClass: 'fal fa-eye-slash'
    },
    {
      name: MoreButtonActionName.ARCHIVE,
      labelTk: 'homePageComponents.entityWidget.archive',
      iconClass: 'fal fa-archive',
      disabledContext: this,
      disabledPropertyName: 'canNotEditArchived'
    }
  ];

  private isOnline = false;

  constructor(
    private readonly project: Project,
    options: ProjectWidgetHandleOptions
  ) {
    this.entityManager = options.entityManager;
    this.projectActionService = options.projectActionService;
    this.socketService = options.socketService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: project
      });
  }

  public subscribe(options: EntityWidgetHandleSubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    subscriptionManager.addDisposable(this.permissionsHandle.subscribe());

    options.setOnWidgetClicked(
      this.projectActionService.navigateToProject.bind(
        this.projectActionService,
        this.project
      )
    );
    options.setOnStickyButtonClicked(
      this.projectActionService.togglestickyStatusOnHomepage.bind(
        this.projectActionService,
        this.project
      )
    );
    options.setMoreButtonChoices(this.moreButtonChoices);
    options.setMoreButtonChoicesClickHandler(
      this.handleChoiceSelected.bind(this)
    );

    const updateName = (): void => {
      options.setTitle(this.project.name ?? '');
    };
    updateName();
    subscriptionManager.subscribeToExpression(this.project, 'name', updateName);

    const updateThingName = (): void => {
      options.setSubTitle(this.getThingNameOfProject());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      updateThingName
    );
    updateThingName();

    const updateStructureTemplateName = (): void => {
      options.setProjectTypeInfo([
        {
          type: this.project.projectType,
          info: this.getStructureTemplateNameOfProject()
        }
      ]);
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      updateStructureTemplateName
    );
    updateStructureTemplateName();

    const updateReportTemplateName = (): void => {
      options.setProjectTypeInfo([
        { type: this.project.projectType, info: this.getReportNameOfProject() }
      ]);
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      updateReportTemplateName
    );
    updateReportTemplateName();

    const updateProjectTitlePicture = (): void => {
      options.setPicture(this.getProjectTitlePicture());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updateProjectTitlePicture
    );
    updateProjectTitlePicture();

    const updateMetadata = (): void => {
      options.setEntityDashboardInfo(this.getEntityDashboardInfo());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateMetadata
    );
    updateMetadata();

    return subscriptionManager.toDisposable();
  }

  public getEntityId(): string {
    return this.project.id;
  }

  private getEntityDashboardInfo(): ProjectEntityDashboardInfo | null {
    const metadata =
      this.entityManager.entityDashboardInfoRepository.getByProjectId(
        this.project.id
      );
    return metadata[0] ?? null;
  }

  @computedFrom('isOnline')
  public get isOffline(): boolean {
    return !this.isOnline;
  }

  @computedFrom('permissionsHandle.canEditField.archived')
  public get canNotEditArchived(): boolean {
    return !this.permissionsHandle.canEditField.archived;
  }

  private getProjectTitlePicture(): Picture | null {
    return (
      (this.entityManager.pictureRepository.getSelectedProjectTitlePicture(
        this.project.id
      ) as Picture) ?? null
    );
  }

  private getThingNameOfProject(): string {
    return (
      this.entityManager.thingRepository.getById(this.project.thing)?.name ?? ''
    );
  }

  private getStructureTemplateNameOfProject(): string {
    if (!this.project.structureTemplateId) return '';
    return (
      this.entityManager.structureTemplateRepository.getById(
        this.project.structureTemplateId
      )?.name ?? ''
    );
  }

  private getReportNameOfProject(): string {
    if (!this.project.report_type) return '';
    return (
      this.entityManager.reportTypeRepository.getById(this.project.report_type)
        ?.name ?? ''
    );
  }

  private handleChoiceSelected(name: string): void {
    switch (name) {
      case MoreButtonActionName.EDIT:
        void EditProjectDialog.open({
          project: this.project
        });
        break;
      case MoreButtonActionName.DUPLICATE:
        this.projectActionService.copyProject(this.project, {
          openAfterCopySuccess: true
        });
        break;
      case MoreButtonActionName.PARAMETERS:
        this.projectActionService.navigateToProject(this.project, {
          open_parameter_panel: true
        });
        break;
      case MoreButtonActionName.ARCHIVE:
        this.projectActionService.toggleArchivedStatusOfProject(this.project);
        break;
      case MoreButtonActionName.HIDE:
        this.projectActionService.toggleHideStatusOnHomepage(this.project);
        break;
      default:
        throw new Error(`unhandled choice "${name}"`);
    }
  }
}

type ProjectWidgetHandleOptions = {
  entityManager: AppEntityManager;
  projectActionService: ProjectActionService;
  subscriptionManagerService: SubscriptionManagerService;
  socketService: SocketService;
  permissionsService: PermissionsService;
};

enum MoreButtonActionName {
  EDIT = 'edit',
  PARAMETERS = 'parameters',
  DUPLICATE = 'duplicate',
  HIDE = 'hide',
  ARCHIVE = 'archive'
}
