import { PropertyWidgetBindingFeatureMap } from './PropertyWidgetBindingConfiguration';

export class PropertyWidgetBindingConfigurationUtils {
  private constructor() {}

  public static requiredFeaturesAreImplemented({
    requiredFeatures,
    implementedFeatures
  }: {
    requiredFeatures: PropertyWidgetBindingFeatureMap<any>;
    implementedFeatures: PropertyWidgetBindingFeatureMap<any>;
  }): boolean {
    for (const [feature, required] of Object.entries(requiredFeatures) as Array<
      [keyof PropertyWidgetBindingFeatureMap<any>, boolean]
    >) {
      if (!required) {
        continue;
      }

      if (!implementedFeatures[feature]) {
        return false;
      }
    }

    return true;
  }
}
