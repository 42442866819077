import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createThingToPersonEntityInfo } from 'common/Types/Entities/ThingToPerson/createThingToPersonEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ThingToPerson } from './types';

export const thingToPersonEntityInfo =
  createThingToPersonEntityInfo<ThingToPerson>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
