import { bindable } from 'aurelia-framework';
import { TSketchFinishedEvent } from '../data-url-sketch-button/data-url-sketch-button';

export class RapidFireImagePreview {
  @bindable()
  public originalDataUrl: string | null = null;

  @bindable()
  public editedDataUrl: string | null = null;

  @bindable()
  public sketchDataUrl: string | null = null;

  private handleSketchFinished(event: TSketchFinishedEvent): void {
    this.sketchDataUrl = event.detail.sketchDataUrl;
    this.editedDataUrl = event.detail.editedDataUrl;
  }
}
