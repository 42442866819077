import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { DateUtils } from '../../../../common/src/DateUtils';
import { Dialogs } from '../../classes/Dialogs';
import { GalleryThingJoinedProjectsService } from '../../services/GalleryThingJoinedProjectsService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

/**
 * this is meant to be a single global instance
 */
@inject(
  EventAggregator,
  GalleryThingJoinedProjectsService,
  SubscriptionManagerService
)
export class GalleryThingDateRangeSelectionDialog {
  /** @type {string} */
  _thingId;
  /** @type {boolean} */
  _isMobile;

  /** @type {Array<{value: number, label: string}>}**/
  _lastDaysRangeOptions = [];

  /** @type {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} */
  _dateRanges = [];
  /** @type {(string|null)} */
  _addDateRangeFromDate;
  /** @type {(string|null)} */
  _addDateRangeToDate;

  /** @type {Array<string>} */
  _addDateRangeWarningTextTks = [];

  /** @type {RecordItDialog} */
  _dialog;

  /**
   *
   * @param {EventAggregator} eventAggregator
   * @param {GalleryThingJoinedProjectsService} galleryThingJoinedProjectsService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(
    eventAggregator,
    galleryThingJoinedProjectsService,
    subscriptionManagerService
  ) {
    this._eventAggregator = eventAggregator;
    this._galleryThingJoinedProjectsService = galleryThingJoinedProjectsService;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this._isMobile = isMobile;
      })
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  /**
   *
   * @param {string} thingId
   */
  open(thingId) {
    this._thingId = thingId;
    this._dateRanges =
      this._galleryThingJoinedProjectsService.getDates(thingId);
    this._lastDaysRange =
      this._galleryThingJoinedProjectsService.getLastDaysRange(thingId);
    this._lastDaysRangeOptions =
      this._galleryThingJoinedProjectsService.getLastDaysRangeOptions();
    this._dialog.open();
  }

  _handleDialogClosed() {
    this._dateRanges = [];
    this._thingId = null;
    this._addDateRangeFromDate = null;
    this._addDateRangeToDate = null;
  }

  _handleAddDateRangeClick() {
    const fromTimestamp = this._addDateRangeFromDate
      ? new Date(this._addDateRangeFromDate).getTime()
      : null;
    const toTimestamp = this._addDateRangeToDate
      ? new Date(this._addDateRangeToDate).getTime()
      : null;

    if (this._validateAddDateRange(fromTimestamp, toTimestamp)) {
      const dateRanges = this._galleryThingJoinedProjectsService.getDates(
        this._thingId
      );
      dateRanges.push({
        from: fromTimestamp,
        to: toTimestamp
      });
      this._galleryThingJoinedProjectsService.setDates(
        this._thingId,
        dateRanges
      );
      this._dateRanges = dateRanges;

      this._addDateRangeFromDate = null;
      this._addDateRangeToDate = null;
    }
  }

  /**
   *
   * @param {(number|null)} fromTimestamp
   * @param {(number|null)} toTimestamp
   * @returns {boolean}
   * @private
   */
  _validateAddDateRange(fromTimestamp, toTimestamp) {
    const warningTexts = [];

    if (!fromTimestamp) {
      warningTexts.push(
        'galleryThing.dateRangeSelectionDialog.dateFromMissingWarning'
      );
    }

    if (!toTimestamp) {
      warningTexts.push(
        'galleryThing.dateRangeSelectionDialog.dateToMissingWarning'
      );
    }

    if (fromTimestamp && toTimestamp && fromTimestamp > toTimestamp) {
      warningTexts.push(
        'galleryThing.dateRangeSelectionDialog.dateToBeforeDateFromWarning'
      );
    }

    this._addDateRangeWarningTextTks = warningTexts;
    return this._addDateRangeWarningTextTks.length === 0;
  }

  /**
   *
   * @param {number} date
   * @private
   */
  _formatDateRangeDate(date) {
    return DateUtils.formatToDateString(date);
  }

  /**
   *
   * @param {TGalleryThingJoinedProjectsServiceThingInfoDateRange} dateRange
   * @private
   */
  _handleDeleteDateRangeClick(dateRange) {
    Dialogs.deleteDialogTk(
      'galleryThing.dateRangeSelectionDialog.removeDateRangeDialogText'
    ).then(() => {
      const index = this._dateRanges.indexOf(dateRange);
      if (index >= 0) {
        this._dateRanges.splice(index, 1);
        this._galleryThingJoinedProjectsService.setDates(
          this._thingId,
          this._dateRanges
        );
      }
    });
  }

  _handleLastDaysRangeChanged() {
    this._galleryThingJoinedProjectsService.setLastDaysRange(
      this._thingId,
      this._lastDaysRange
    );
  }

  /**
   *
   * @param {string} thingId
   * @returns {Promise<void>}
   */
  static async open(thingId) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(thingId);
  }
}
