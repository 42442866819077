import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event {TRegionSelectEvent} event when region has changed, contains ObjectID string in detail.value
 *
 * @attribute data-no-border - disables border of the component
 *
 * @export
 * @class RegionSelect
 */
@inject(AppEntityManager, SubscriptionManagerService)
export class RegionSelect {
  /** @type {HTMLElement|null} */
  _domElement = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {string|null} */
  @bindable thingId = null;

  /** @type {string|null} */
  @bindable selectedRegionId = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/Region/types').Region>} */
  regions = [];

  /** @type {string|undefined} */
  @bindable dataNoBorder;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @memberof RegionSelect
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Region,
      this._updateRegions.bind(this)
    );
    this._updateRegions();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  thingIdChanged() {
    this._updateRegions();
  }

  _updateRegions() {
    if (this.thingId) {
      this.regions = this._entityManager.regionRepository.getByThingId(
        this.thingId
      );
    } else {
      this.regions = [];
    }
  }

  /**
   * @param {DocumentEvent & { detail: { value: string } }} event
   */
  _handleSelectChanged(event) {
    setTimeout(() => {
      if (!this._domElement) return;
      DomEventHelper.fireEvent(this._domElement, {
        name: 'select-changed',
        detail: { value: event.detail.value }
      });
    }, 0);
  }
}

/**
 * @typedef {DocumentEvent & { detail: { value: string } }} TRegionSelectEvent
 */
