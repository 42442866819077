import _ from 'lodash';
import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';

import { AbstractLogDataConverter } from './AbstractLogDataConverter';
import { LogDataConverterUtils } from './LogDataConverterUtils';

@inject(AppEntityManager)
export class IdLookupLogDataConverter extends AbstractLogDataConverter {
  /** @type {Array<string>} */
  _namePropertyNames = ['name', 'username'];

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    super();
    this._entityManager = entityManager;
  }

  /**
   * @param {import('./AbstractLogDataConverter').ConvertOptions<'propertyPath'|'entityName'>} options
   */
  convert(options) {
    const value = _.get(options.logData, options.args.propertyPath);
    if (value) {
      const namePropertyPath = LogDataConverterUtils.generateNamePropertyPath(
        options.args.propertyPath
      );
      _.set(
        options.logData,
        namePropertyPath,
        this._getDisplayNameForId(value, options.args.entityName)
      );
    }
  }

  /**
   * @param {string} id
   * @param {import('../../../classes/EntityManager/entities/types').EntityName} entityName
   * @returns {string}
   */
  _getDisplayNameForId(id, entityName) {
    const entityRepository =
      this._entityManager.entityRepositoryContainer.getByEntityName(entityName);
    const entity = entityRepository.getById(id);

    // TODO: find a better method to get the entity name
    // could be implemented in the entityRepository
    // the entities which are referenced for now are supported, but special entities like e.g. relations don't have a name property and need a special handling

    if (entity) {
      for (const namePropertyName of this._namePropertyNames) {
        if (namePropertyName in entity) {
          return entity[namePropertyName];
        }
      }
    }

    return 'entity has no name';
  }
}
