import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {ConfirmClickedEvent} confirm-clicked
 * @event {CancelClickedEvent} cancel-clicked
 */
@autoinject()
export class PersonConfirmSelect {
  @bindable()
  public relatedToPersonId: string | null = null;

  @bindable()
  public filterByCategoryName: string | null = null;

  protected selectedPersonId: string | null = null;

  constructor(private readonly element: Element) {}

  protected handleConfirmClick(): void {
    DomEventHelper.fireEvent<ConfirmClickedEvent>(this.element, {
      name: 'confirm-clicked',
      detail: {
        personId: this.selectedPersonId
      }
    });
  }

  protected handleCancelClick(): void {
    DomEventHelper.fireEvent<CancelClickedEvent>(this.element, {
      name: 'cancel-clicked',
      detail: null
    });
  }
}

export type ConfirmClickedEvent = NamedCustomEvent<
  'confirm-clicked',
  { personId: string | null }
>;
export type CancelClickedEvent = NamedCustomEvent<'cancel-clicked', null>;
