import { z } from 'zod';

import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

const propertySchema = z.object({
  name: z.string().min(1),
  type: z.nativeEnum(PropertyType),
  choices: z.array(z.string()).optional(),
  value: z.string().optional(),
  options: z
    .array(
      z.object({
        name: z.string(),
        value: z.string()
      })
    )
    .optional()
});

export const structureTemplateEntryPropertyJsonImportValidationSchema = z
  .object({
    properties: z.array(propertySchema)
  })
  .strict();

export type StructureTemplateEntryPropertyJsonImportData = z.infer<
  typeof structureTemplateEntryPropertyJsonImportValidationSchema
>;
