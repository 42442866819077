import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../AppEntityManager';
import { Project } from './types';
import { CurrentUserService } from '../User/CurrentUserService';
import { CopyProjectDialog } from '../../../../dialogs/copy-project-dialog/copy-project-dialog';
import { NavigationService } from '../../../../services/NavigationService';
import { ProjectEntityDashboardInfo } from '../EntityDashboardInfo/types';

@autoinject()
export class ProjectActionService {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  public navigateToProject(
    project: Project,
    additionalRouteParams?: OpenProjectOptions | null
  ): void {
    const metadata = this.getOrCreateMetadata(project);
    metadata.lastOpenedAt = new Date().toISOString();

    this.entityManager.entityDashboardInfoRepository.update(metadata);

    this.navigationService.navigateToProject(project, additionalRouteParams);
  }

  public toggleArchivedStatusOfProject(project: Project): void {
    project.archived = !project.archived;
    this.entityManager.projectRepository.update(project);
  }

  public copyProject(project: Project, options?: CopyProjectOptions): void {
    void CopyProjectDialog.open({
      project: project,
      onCopySuccess: options?.openAfterCopySuccess
        ? this.navigateToProject.bind(this)
        : null
    });
  }

  public toggleHideStatusOnHomepage(project: Project): void {
    const metadata = this.getOrCreateMetadata(project);
    metadata.hidden = !metadata.hidden;
    this.entityManager.entityDashboardInfoRepository.update(metadata);
  }

  public togglestickyStatusOnHomepage(project: Project): void {
    const metadata = this.getOrCreateMetadata(project);
    metadata.sticky = !metadata.sticky;
    this.entityManager.entityDashboardInfoRepository.update(metadata);
  }

  private getOrCreateMetadata(project: Project): ProjectEntityDashboardInfo {
    const metadata =
      this.entityManager.entityDashboardInfoRepository.getByProjectId(
        project.id
      )[0];
    if (metadata) return metadata;

    const newData = this.entityManager.entityDashboardInfoRepository.create({
      projectId: project.id,
      userId: this.currentUserService.getRequiredCurrentUser().id
    }) as ProjectEntityDashboardInfo;
    return newData;
  }
}

type CopyProjectOptions = {
  openAfterCopySuccess: boolean;
};

export type OpenProjectOptions = {
  open_parameter_panel: boolean;
};
