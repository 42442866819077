import {
  CoordsFromPositionedPictureInfo,
  IPictureCoords
} from 'common/Types/Entities/Picture/PictureDto';
import { GalleryThingAdditionalMarkingsHelper } from 'common/GalleryThing/GalleryThingAdditionalMarkingsHelper';

import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';

export class GalleryThingPictureCreatorUtils {
  public static getCoordsFromPositionedPictureInfo(
    entityManager: AppEntityManager,
    coords: IPictureCoords,
    thingId: string
  ): CoordsFromPositionedPictureInfo | null {
    const galleryThingOverviewPicture = entityManager.pictureRepository
      .getByGalleryThingId(thingId)
      .find((x) => x.selected);
    return (
      GalleryThingAdditionalMarkingsHelper.getAdditionalMarkings(
        [galleryThingOverviewPicture],
        coords,
        'pictureId'
      )[0] ?? null
    );
  }
}
