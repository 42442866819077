import { bindable, inject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { FilterPersonSelect } from '../../personComponents/filter-person-select/filter-person-select';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(I18N, SubscriptionManagerService)
export class ProcessConfigurationStepPositionFilter {
  static DEACTIVATED_MODE_ALL = 'all';
  static DEACTIVATED_MODE_ONLY_DEACTIVATED = 'onlyDeactivated';
  static DEACTIVATED_MODE_ONLY_ACTIVATED = 'onlyActivated';

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} */
  @bindable positions = [];

  /**
   * read only
   *
   * @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>}
   */
  @bindable filteredPositions = [];

  /**
   * @type {string|null}
   */
  @bindable userGroupId = null;

  /**
   * read only
   *
   * @type {string|null}
   */
  @bindable selectedPersonId = null;

  /** @type {I18N} */
  _i18n;
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;
  /** @type {boolean} */
  _attached = false;
  /** @type {Array<TProcessConfigurationStepPositionFilterChoice>} */
  _deactivatedModeChoices = [];
  /** @type {string} */
  @observable _selectedDeactivatedMode;
  /** @type {import('../../personComponents/filter-person-select/filter-person-select').TPersonOption|null} */
  _selectedPersonOption = null;
  /** @type {Array<string>} */
  _selectablePersonIds = [];
  /** @type {string} */
  _filterPersonSelectMode = FilterPersonSelect.MODE_SELECT;

  /**
   * @param {I18N} i18n
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(i18n, subscriptionManagerService) {
    this._i18n = i18n;

    this._subscriptionManager = subscriptionManagerService.create();

    this._deactivatedModeChoices = this._createDeactivatedModeChoices();
    this._selectedDeactivatedMode =
      ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_ACTIVATED;

    this._ProcessConfigurationStepPositionFilter =
      ProcessConfigurationStepPositionFilter; // for the view
    this._FilterPersonSelect = FilterPersonSelect;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'positions',
      () => {
        this._updateSelectablePersonIds();
        this._updateFilteredPositions();
      }
    );
    this._updateFilteredPositions();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  /**
   * modify the filter by the least amount so the position will pass the filtering
   *
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition} position
   */
  setFilterToIncludePosition(position) {
    if (!this._filterPositionByPerson(position)) {
      this._selectedPersonOption = null;
    }

    if (!this._filterPositionBySelectedDeactivatedMode(position)) {
      this._selectedDeactivatedMode =
        ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ALL;
    }
  }

  _updateSelectablePersonIds() {
    /** @type {Set<string>} */
    const personIdsSet = new Set();
    this.positions.forEach((p) => {
      if (p.personId) {
        personIdsSet.add(p.personId);
      }
    });

    this._selectablePersonIds = Array.from(personIdsSet);
  }

  _selectedDeactivatedModeChanged() {
    this._updateFilteredPositions();
  }

  _updateFilteredPositions() {
    this.filteredPositions = this.positions.filter((position) => {
      let filtered = true;

      filtered = filtered && this._filterPositionByPerson(position);
      filtered =
        filtered && this._filterPositionBySelectedDeactivatedMode(position);

      return filtered;
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition} position
   * @returns {boolean}
   * @private
   */
  _filterPositionByPerson(position) {
    if (!this._selectedPersonOption) {
      return true;
    }
    if (this._selectedPersonOption.personId != null) {
      // only do a strict comparison if id != null
      return position.personId === this._selectedPersonOption.personId;
    } else {
      return position.personId == null;
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition} position
   * @returns {boolean}
   * @private
   */
  _filterPositionBySelectedDeactivatedMode(position) {
    switch (this._selectedDeactivatedMode) {
      case ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ALL:
        return true;

      case ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_DEACTIVATED:
        return position.deactivated;

      case ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_ACTIVATED:
      default:
        return !position.deactivated;
    }
  }

  /**
   * @returns {Array<TProcessConfigurationStepPositionFilterChoice>}
   * @private
   */
  _createDeactivatedModeChoices() {
    return [
      {
        displayName: this._translate(
          'deactivatedMode--' +
            ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_ACTIVATED
        ),
        value:
          ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_ACTIVATED
      },
      {
        displayName: this._translate(
          'deactivatedMode--' +
            ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_DEACTIVATED
        ),
        value:
          ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ONLY_DEACTIVATED
      },
      {
        displayName: this._translate(
          'deactivatedMode--' +
            ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ALL
        ),
        value: ProcessConfigurationStepPositionFilter.DEACTIVATED_MODE_ALL
      }
    ];
  }

  _handleSelectedPersonOptionChanged() {
    this._updateFilteredPositions();
    this.selectedPersonId = this._selectedPersonOption
      ? this._selectedPersonOption.personId
      : null;
  }

  /**
   * @param {string} key
   * @returns {string}
   * @private
   */
  _translate(key) {
    return this._i18n.tr(
      'operations.processConfigurationStepPositionFilter.' + key
    );
  }
}

/**
 * @typedef {Object} TProcessConfigurationStepPositionFilterChoice
 * @property {string} displayName
 * @property {string} value
 */
