import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EditProcessConfigurationDeviceExportDialog } from '../../operationsComponents/edit-process-configuration-device-export-dialog/edit-process-configuration-device-export-dialog';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class edit_process_configuration_device_exports {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private processConfigurationId: string | null = null;
  private processConfiguration: ProcessConfiguration | null = null;
  protected exportConfigurations: Array<ProcessConfigurationDeviceExport> = [];
  protected processConfigurationIsEditable: boolean = false; // TODO: remove
  protected isMobile: boolean = false;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  protected activate(params: { process_configuration_id: string }): void {
    this.processConfigurationId = params.process_configuration_id;
    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        this.processConfigurationId
      );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDeviceExport,
      this.updateExportConfigurations.bind(this)
    );
    this.updateExportConfigurations();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected deactivate(): void {
    this.disposeSubscriptions();
  }

  protected detached(): void {
    this.disposeSubscriptions();
  }

  private disposeSubscriptions(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateExportConfigurations(): void {
    if (this.processConfiguration) {
      this.exportConfigurations =
        this.entityManager.processConfigurationDeviceExportRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.exportConfigurations = [];
    }
  }

  protected handleCreateDeviceExportClicked(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      "can't edit_process_configuration_device_exports.handleCreateDeviceExportClicked without a processConfiguration"
    );
    this.entityManager.processConfigurationDeviceExportRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId
    });

    this.updateExportConfigurations();
  }

  protected handleEditButtonClicked(
    configuration: ProcessConfigurationDeviceExport
  ): void {
    void EditProcessConfigurationDeviceExportDialog.open({
      processConfigurationDeviceExport: configuration
    });
  }
}
