import { ProcessTaskHelper } from 'common/EntityHelper/ProcessTaskHelper';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTask } from './types';

export class ProcessTaskRepository extends AppEntityRepository<EntityName.ProcessTask> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTask> {
    const processTasks = this.getAll().filter(
      (p) => p.ownerProcessTaskGroupId === processTaskGroupId
    );

    return ProcessTaskHelper.sort(
      processTasks.map((pt) => ({
        id: pt.id,
        order: pt.order,
        processTask: pt
      }))
    );
  }
}
