import { Observable, OperatorFunction } from 'rxjs';
import { Utils } from '../../../classes/Utils/Utils';

/**
 * rate limits the emited values and emits the last value after the time has passed
 *
 * e.g.
 * Input:
 * ------1------2--------------------3-------4-------5-------6---------------|
 * Output:
 * ---------------------2------------------------------5-------------------6-|
 */
export function rateLimit<T>({
  rateInterval
}: {
  rateInterval: number;
}): OperatorFunction<T, T> {
  return (observable) => {
    return new Observable<T>((subscriber) => {
      let lastValueContainer: { value: T } | null = null;

      const rateLimitedNext = Utils.rateLimitFunction(() => {
        if (lastValueContainer) {
          subscriber.next(lastValueContainer.value);
        }
      }, rateInterval);

      const subscription = observable.subscribe((value) => {
        lastValueContainer = { value };
        rateLimitedNext();
      });

      return () => {
        rateLimitedNext.cancel();
        subscription.unsubscribe();
      };
    });
  };
}
