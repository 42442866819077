import { RequiredProperties } from 'common/Types/utilities';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { StructureTemplateRatingCategory } from './types';

export class StructureTemplateRatingCategoryRepository extends AppEntityRepository<EntityName.StructureTemplateRatingCategory> {
  public getByStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateRatingCategory> {
    return this.getAll().filter((p) => {
      return p.ownerStructureTemplateId === structureTemplateId;
    });
  }

  public getValidByStructureTemplateId(
    structureTemplateId: string
  ): Array<ValidStructureTemplateRatingCategory> {
    return this.getByStructureTemplateId(structureTemplateId).filter(
      (p): p is ValidStructureTemplateRatingCategory => {
        return (
          !!p.iconName && !!p.iconType && !!p.structureTemplateEntryPropertyId
        );
      }
    );
  }
}

/**
 * Valid structureTemplateRatingCategories have an icon & a connected property
 */
export type ValidStructureTemplateRatingCategory = RequiredProperties<
  StructureTemplateRatingCategory,
  'iconName' | 'iconType' | 'structureTemplateEntryPropertyId'
>;
