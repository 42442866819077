export class StringToBooleanValueConverter {
  /**
   * @param {string} string
   * @returns {boolean}
   */
  toView(string) {
    return string == 'true';
  }

  /**
   * @param {boolean} boolean
   * @returns {string}
   */
  fromView(boolean) {
    return boolean ? 'true' : 'false';
  }
}
