import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { QuestionCategory } from '../../classes/EntityManager/entities/QuestionCategory/types';
import {
  QuestionCategorySortOptions,
  QuestionCategoryUtils
} from '../../classes/EntityManager/entities/QuestionCategory/QuestionCategoryUtils';

@autoinject()
export class ChecklistQuestionCategoryList {
  protected sortedQuestionCategories: Array<QuestionCategory> = [];

  /**
   * question categories paginated by pagination.
   * sourced from `sortedQuestionCategories`.
   */
  protected paginatedQuestionCategories: Array<QuestionCategory> = [];

  protected sortOptions = QuestionCategoryUtils.getSortOptions();

  protected currentSortOption: QuestionCategorySortOptions[keyof QuestionCategorySortOptions] =
    this.sortOptions.name;

  constructor(private readonly entityManager: AppEntityManager) {}

  @computed(model(EntityName.QuestionCategory))
  protected get questionCategories(): Array<QuestionCategory> {
    return this.entityManager.questionCategoryRepository.getAll();
  }
}
