import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { TooltipContent } from '../tooltip-content/tooltip-content';

@autoinject()
export class MainButtonWithDropdown {
  @bindable public buttonLabelTk: string | null = null;

  @bindable public floatingLabelButton = false;

  @bindable public floatingLabelButtonIconClass: string = 'fal fa-plus';

  @bindable public enableDropdown = false;

  @bindable public enabled = true;

  /** used in view */
  protected domElement: HTMLElement;
  protected dropdownArrowButtonElement: HTMLButtonElement | null = null;
  protected floatingLabelButtonElement: HTMLButtonElement | null = null;
  protected selectionTooltipContent: TooltipContent | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleButtonClicked(): void {
    if (this.enableDropdown) {
      this.selectionTooltipContent?.open();
    } else {
      this.handleButtonClickedWithoutDropdown();
    }
  }

  protected handleButtonClickedWithoutDropdown(): void {
    DomEventHelper.fireEvent<ButtonClickedEvent>(this.domElement, {
      name: 'button-clicked',
      detail: null
    });
  }

  protected handleTooltipContentClosed(): void {
    DomEventHelper.fireEvent<DropdownClosedEvent>(this.domElement, {
      name: 'dropdown-closed',
      detail: null
    });
  }
}

export type ButtonClickedEvent = NamedCustomEvent<'button-clicked', null>;
export type DropdownClosedEvent = NamedCustomEvent<'dropdown-closed', null>;
