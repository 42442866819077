import { PropertyHelper } from '../../../../../common/src/EntityHelper/PropertyHelper';
import {
  ProcessConfigurationProcessTaskPropertiesConfiguration,
  ProcessTaskDefaultPropertyConfig
} from '../../../../../common/src/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { ProcessTaskInvoiceToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskPosition/types';
import { ProcessTaskOfferToProcessTask } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/types';
import { ProcessTaskOfferToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types';
import { ProcessTaskOfferToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { DirectRelatedEntityData } from './DirectRelatedEntityDataFetcher';

export class ProcessTaskOfferMover {
  constructor(private readonly entityManager: AppEntityManager) {}

  public move(
    sourceProcessTask: ProcessTask,
    targetProcessTask: ProcessTask,
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask,
    processTaskPositionsData: DirectRelatedPositionsData,
    processTaskDevicesData: DirectRelatedDevicesData
  ): void {
    this.copyProcessTaskProperties(sourceProcessTask, targetProcessTask);
    this.moveProcessTaskRelation(
      targetProcessTask.id,
      processTaskOfferToProcessTask
    );
    this.movePositions(targetProcessTask.id, processTaskPositionsData);
    this.moveDevices(targetProcessTask.id, processTaskDevicesData);
  }

  private moveProcessTaskRelation(
    targetProcessTaskId: string,
    processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask
  ): void {
    processTaskOfferToProcessTask.ownerProcessTaskId = targetProcessTaskId;
    this.entityManager.processTaskOfferToProcessTaskRepository.update(
      processTaskOfferToProcessTask
    );
  }

  private movePositions(
    targetProcessTaskId: string,
    processTaskPositionsData: DirectRelatedPositionsData
  ): void {
    for (const position of processTaskPositionsData.entities) {
      position.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.processTaskPositionRepository.update(position);

      this.movePositionDetailEntries(position, targetProcessTaskId);
      this.movePositionProperties(position, targetProcessTaskId);
    }

    for (const relation of processTaskPositionsData.offerRelations) {
      relation.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.processTaskOfferToProcessTaskPositionRepository.update(
        relation
      );
    }

    for (const inactiveOfferToPosition of processTaskPositionsData.inactiveOtherOfferRelations) {
      this.entityManager.processTaskOfferToProcessTaskPositionRepository.delete(
        inactiveOfferToPosition
      );
    }

    for (const inactiveInvoiceToPosition of processTaskPositionsData.inactiveInvoiceRelations) {
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.delete(
        inactiveInvoiceToPosition
      );
    }
  }

  private moveDevices(
    targetProcessTaskId: string,
    processTaskDevicesData: DirectRelatedDevicesData
  ): void {
    for (const device of processTaskDevicesData.entities) {
      device.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.processTaskDeviceRepository.update(device);

      this.moveDeviceProperties(device, targetProcessTaskId);
    }

    for (const relation of processTaskDevicesData.offerRelations) {
      relation.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.processTaskOfferToProcessTaskDeviceRepository.update(
        relation
      );
    }

    for (const inactiveOfferToDevice of processTaskDevicesData.inactiveOtherOfferRelations) {
      this.entityManager.processTaskOfferToProcessTaskDeviceRepository.delete(
        inactiveOfferToDevice
      );
    }

    for (const inactiveInvoiceToDevice of processTaskDevicesData.inactiveInvoiceRelations) {
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.delete(
        inactiveInvoiceToDevice
      );
    }
  }

  private movePositionDetailEntries(
    position: ProcessTaskPosition,
    targetProcessTaskId: string
  ): void {
    const detailEntries =
      this.entityManager.processTaskPositionDetailEntryRepository.getByProcessTaskPositionId(
        position.id
      );

    for (const detailEntry of detailEntries) {
      detailEntry.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.processTaskPositionDetailEntryRepository.update(
        detailEntry
      );

      this.movePositionDetailEntryProperties(detailEntry, targetProcessTaskId);
    }
  }

  private movePositionDetailEntryProperties(
    detailEntry: ProcessTaskPositionDetailEntry,
    targetProcessTaskId: string
  ): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskPositionDetailEntryId(
        detailEntry.id
      );
    this.moveProperties(properties, targetProcessTaskId);
  }

  private moveDeviceProperties(
    device: ProcessTaskDevice,
    targetProcessTaskId: string
  ): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskDeviceId(device.id);
    this.moveProperties(properties, targetProcessTaskId);
  }

  private movePositionProperties(
    position: ProcessTaskPosition,
    targetProcessTaskId: string
  ): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskPositionId(
        position.id
      );
    this.moveProperties(properties, targetProcessTaskId);
  }

  private moveProperties(
    properties: Array<Property>,
    targetProcessTaskId: string
  ): void {
    for (const property of properties) {
      property.ownerProcessTaskId = targetProcessTaskId;
      this.entityManager.propertyRepository.update(property);
    }
  }

  private copyProcessTaskProperties(
    sourceProcessTask: ProcessTask,
    targetProcessTask: ProcessTask
  ): void {
    const sourceProperties =
      this.entityManager.propertyRepository.getByProcessTaskId(
        sourceProcessTask.id
      );
    const targetProperties =
      this.entityManager.propertyRepository.getByProcessTaskId(
        targetProcessTask.id
      );
    const propertyConfigs = this.getPropertyConfigsToInherit(sourceProcessTask);

    for (const sourceProperty of sourceProperties) {
      const targetProperty = targetProperties.find((tp) =>
        PropertyHelper.isTheSameProperty(tp, sourceProperty)
      );
      const config = propertyConfigs.find((pc) =>
        PropertyHelper.isTheSameProperty(pc, sourceProperty)
      );

      if (!config) {
        continue;
      }

      if (targetProperty) {
        Object.assign(
          targetProperty,
          PropertyHelper.extractValueDataFromProperty(sourceProperty)
        );
        this.entityManager.propertyRepository.update(targetProperty);
      } else {
        this.entityManager.propertyRepository.create({
          ...sourceProperty,
          processTaskId: targetProcessTask.id,
          ownerProcessTaskId: targetProcessTask.id
        });
      }
    }
  }

  private getPropertyConfigsToInherit(
    sourceProcessTask: ProcessTask
  ): Array<ProcessTaskDefaultPropertyConfig> {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        sourceProcessTask.ownerProcessTaskGroupId
      );
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const configuration =
      processConfiguration?.processTaskPropertiesConfigurationJson
        ? (JSON.parse(
            processConfiguration.processTaskPropertiesConfigurationJson
          ) as ProcessConfigurationProcessTaskPropertiesConfiguration)
        : null;
    return (configuration?.defaultProperties ?? []).filter(
      (defaultProperty) => defaultProperty.inherit
    );
  }
}

export type DirectRelatedPositionsData = DirectRelatedEntityData<
  ProcessTaskPosition,
  ProcessTaskOfferToProcessTaskPosition,
  ProcessTaskInvoiceToProcessTaskPosition
>;
export type DirectRelatedDevicesData = DirectRelatedEntityData<
  ProcessTaskDevice,
  ProcessTaskOfferToProcessTaskDevice,
  ProcessTaskInvoiceToProcessTaskDevice
>;
