import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event {StructureTemplateSelectValueChangedEvent} value-changed
 */
@autoinject()
export class StructureTemplateSelect {
  @bindable()
  public value: string | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  protected availableStructureTemplates: Array<StructureTemplate> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      this.updateAvailableStructureTemplates.bind(this)
    );
    this.updateAvailableStructureTemplates();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent<StructureTemplateSelectValueChangedEvent>(
      this.element,
      {
        name: 'value-changed',
        detail: {
          value: this.value
        }
      }
    );
  }

  private updateAvailableStructureTemplates(): void {
    this.availableStructureTemplates =
      this.entityManager.structureTemplateRepository.getAll();
  }
}

export type StructureTemplateSelectValueChangedEvent = NamedCustomEvent<
  'value-changed',
  {
    value: string | null;
  }
>;
