import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from '../../../../common/src/DateUtils';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { Utils } from '../../classes/Utils/Utils';
import { ProcessTaskAppointmentCalendarWidgetMode } from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { autoinject } from 'aurelia-framework';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class OperationsFieldUseCalendarSettingsService {
  private static readonly STORAGE_KEY =
    'OperationsFieldUseCalendarSettingsService::settings';

  private settings: OperationsFieldUseCalendarSettings | null = null;
  private readonly saveSettingsRateLimited = Utils.rateLimitFunction(
    this.saveSettings.bind(this),
    250
  );
  private readonly eventDispatcher = new EventDispatcher<{
    settingsModified: OperationsFieldUseCalendarSettings;
  }>();

  constructor(
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService
  ) {}

  public async init(): Promise<void> {
    const settings: StoredFieldUseCalendarSettings | undefined =
      await DataStorageHelper.getItem(
        OperationsFieldUseCalendarSettingsService.STORAGE_KEY
      );
    this.settings = {
      ...this.getDefaultSettings(),
      ...settings,
      selectedDate: settings?.selectedDate
        ? new Date(settings.selectedDate)
        : new Date()
    };
  }

  public async flush(): Promise<void> {
    if (this.saveSettingsRateLimited.isPending()) {
      this.saveSettingsRateLimited.cancel();
      await this.saveSettings();
    }
  }

  public destroy(): void {
    this.settings = null;
  }

  public modifySettings(
    modifications: Partial<OperationsFieldUseCalendarSettings>
  ): void {
    this.settings = {
      ...this.getSettings(),
      ...modifications
    };

    this.saveSettingsRateLimited();

    this.eventDispatcher.dispatchEvent('settingsModified', this.settings);
  }

  public getSettings(): OperationsFieldUseCalendarSettings {
    assertNotNullOrUndefined(
      this.settings,
      "can't retrieve settings because the OperationsFieldUseCalendarSettingsService isn't initialized"
    );
    return this.settings;
  }

  public bindSettings(
    callback: (settings: OperationsFieldUseCalendarSettings) => void
  ): Disposable {
    callback(this.getSettings());

    return this.eventDispatcher.addDisposableEventListener(
      'settingsModified',
      callback
    );
  }

  private getDefaultSettings(): OperationsFieldUseCalendarSettings {
    const calendarMode =
      this.activeUserCompanySettingsService.getSettingProperty(
        'operationsFieldUse.defaultProcessTaskAppointmentCalendarWidgetMode'
      ) ?? ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY;

    return {
      calendarMode,
      selectedDate: new Date()
    };
  }

  private saveSettings(): Promise<void> {
    const settings = this.getSettings();
    let selectedDate: string | null = null;

    if (
      settings.selectedDate &&
      DateUtils.isOnSameDay(settings.selectedDate, new Date())
    ) {
      selectedDate = null;
    } else {
      selectedDate = settings.selectedDate?.toISOString() ?? null;
    }

    return DataStorageHelper.setItem(
      OperationsFieldUseCalendarSettingsService.STORAGE_KEY,
      {
        ...settings,
        selectedDate
      }
    );
  }
}

export type OperationsFieldUseCalendarSettings = {
  calendarMode: ProcessTaskAppointmentCalendarWidgetMode;
  selectedDate: Date;
};

type StoredFieldUseCalendarSettings = Partial<
  Omit<OperationsFieldUseCalendarSettings, 'selectedDate'> & {
    selectedDate: string | null;
  }
>;
