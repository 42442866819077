import _ from 'lodash';

import { SortUtils, NullCompareMode } from 'common/Utils/SortUtils';
import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { AppEntityManager } from '../AppEntityManager';
import { Defect } from './types';
import { DefectProperty } from '../Property/types';
import { UrlManager } from '../../../UrlManager';

export class DefectUtils {
  private constructor() {}

  public static createExistingPropertyForDefect(
    entityManager: AppEntityManager,
    oldProperty: DefectProperty,
    defect: Defect
  ): void {
    const newProperty = _.cloneDeep(oldProperty);
    newProperty.id = '';
    newProperty.defect = defect.id;
    newProperty.ownerDefectId = defect.id;
    newProperty.shadowEntity = defect.shadowEntity;
    newProperty.temporaryGroupName = defect.temporaryGroupName;
    entityManager.propertyRepository.create(newProperty);
  }

  public static createWorkerDefectUrl(defect: Defect): string {
    return `${UrlManager.webFolder}/#/defect/${defect.id}`;
  }

  public static createSortOptions({
    entityManager
  }: {
    entityManager: AppEntityManager;
  }): DefectSortOptions {
    return {
      sequenceNumber: {
        name: 'sequenceNumber',
        sortFunction: (a, b) => {
          const aSequenceNumber = a.sequenceNumber ?? 0;
          const bSequenceNumber = b.sequenceNumber ?? 0;
          return aSequenceNumber - bSequenceNumber;
        }
      },
      name: {
        name: 'name',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.name,
            b.name,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      dueAt: {
        name: 'dueAt',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.dueAt,
            b.dueAt,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      assignee: {
        name: 'assignee',
        sortFunction: (a, b) => {
          const aUser = a.assigneeId
            ? entityManager.userRepository.getById(a.assigneeId)
            : null;
          const bUser = b.assigneeId
            ? entityManager.userRepository.getById(b.assigneeId)
            : null;

          return SortUtils.localeCompareFalsyStrings(
            aUser?.username,
            bUser?.username,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      thing: {
        name: 'thing',
        sortFunction: (a, b) => {
          const aThing = entityManager.thingRepository.getById(a.ownerThingId);
          const bThing = entityManager.thingRepository.getById(b.ownerThingId);

          return SortUtils.localeCompareFalsyStrings(
            aThing?.name,
            bThing?.name,
            NullCompareMode.FalsyToBegin
          );
        }
      }
    };
  }
}

export type DefectSortOptions = {
  sequenceNumber: DefectSortOption;
  name: DefectSortOption;
  dueAt: DefectSortOption;
  assignee: DefectSortOption;
  thing: DefectSortOption;
};

export type DefectSortOption = SorterSortOption<Defect>;
