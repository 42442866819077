import { SorterSortOptions } from '../../../../aureliaAttributes/sorter';
import { QuestionCatalogue } from './types';
import { SortUtils, NullCompareMode } from 'common/Utils/SortUtils';

export namespace QuestionCatalogueUtils {
  export function getSortOptions(): QuestionCatalogueSortOptions {
    return {
      name: {
        name: 'name',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.name,
            b.name,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      createdAt: {
        name: 'createdAt',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.createdAt,
            b.createdAt,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      updatedAt: {
        name: 'updatedAt',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.updatedAt,
            b.updatedAt,
            NullCompareMode.FalsyToBegin
          );
        }
      }
    };
  }
}

export type QuestionCatalogueSortOptions = SorterSortOptions<
  QuestionCatalogue,
  'name' | 'createdAt' | 'updatedAt'
>;
