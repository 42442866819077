import { autoinject, bindable } from 'aurelia-framework';
import { DelayedQueue } from '../../classes/Queue/DelayedQueue';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ViewCreationService } from '../../services/ViewCreationService';
import { ContextualPropertyWidgetConfiguration } from '../base-property-widget/config/PropertyWidgetConfiguration/ContextualPropertyWidgetConfiguration';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';
import { SubWidgetRenderer } from './SubWidgetRenderer';

@autoinject()
export class ContextualBasePropertyWidget {
  private static renderingQueue: DelayedQueue | null = null;

  public static flushRenderingQueue(): void {
    this.renderingQueue?.flush();
  }

  @bindable()
  public contextualConfiguration: ContextualPropertyWidgetConfiguration<any> | null =
    null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  /**
   * if this is set to a number, the widget will be rendered at the given index
   *
   * this solution is not ideal, because e.g. if multiple HEADINGS are rendered at the same time, their children will be rendered in "parallel" (still after another, but one of heading 1, then one of heading 2, then one of heading 1 again etc)
   * this will lead to issues if the properties are rendered in a chunk size > 1 or if there ever are nested headings
   */
  @bindable()
  public priorityRenderOffset: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly renderer: SubWidgetRenderer;

  private focusOnRender: boolean = false;
  private isAttached: boolean = false;

  constructor(
    element: Element,
    viewCreationService: ViewCreationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.renderer = new SubWidgetRenderer({
      element,
      viewCreationService,
      onAfterRender: () => {
        if (this.focusOnRender && this.tryFocusingSubWidget()) {
          this.focusOnRender = false;
        }
      }
    });

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    if (!this.tryFocusingSubWidget()) {
      this.focusOnRender = true;
    }
  }

  private tryFocusingSubWidget(): boolean {
    const subWidget = this.renderer.getRenderedSubWidget();
    if (subWidget) {
      subWidget.focus();
      return true;
    }

    return false;
  }

  protected attached(): void {
    this.isAttached = true;

    if (this.priorityRenderOffset !== null) {
      ContextualBasePropertyWidget.getOrCreateRenderingQueue().queueInFront(
        this,
        this.priorityRenderOffset
      );
    } else {
      ContextualBasePropertyWidget.getOrCreateRenderingQueue().queue(this);
    }

    this.subscriptionManager.subscribeToExpression(
      this,
      'contextualConfiguration.binding.type',
      this.updateSubWidget.bind(this)
    );
    this.updateSubWidget();
  }

  protected detached(): void {
    this.isAttached = false;

    ContextualBasePropertyWidget.getOrCreateRenderingQueue().remove(this);

    this.subscriptionManager.disposeSubscriptions();
  }

  protected contextualConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateSubWidget();
    }
  }

  protected styleChanged(): void {
    if (this.isAttached) {
      this.updateSubWidget();
    }
  }

  protected updateSubWidget(): void {
    this.renderer.setTargetRenderInfo({
      contextualConfiguration: this.contextualConfiguration,
      style: this.style
    });
  }

  private static getOrCreateRenderingQueue(): DelayedQueue {
    if (!this.renderingQueue) {
      this.renderingQueue = new DelayedQueue({
        workerFunction: (propertyWidget: ContextualBasePropertyWidget) => {
          propertyWidget.renderer.setCanRender(true);
        },
        chunkSize: 1,
        timeout: 10
      });
    }

    return this.renderingQueue;
  }
}
