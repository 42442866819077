import {
  EntityWidgetHandle,
  EntityWidgetHandleSubscribeOptions
} from './EntityWidgetHandle';
import { Disposable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { DefectActionService } from '../../classes/EntityManager/entities/Defect/DefectActionService';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { computedFrom } from 'aurelia-binding';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { DefectEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { Router } from 'aurelia-router';

export class DefectWidgetHandle implements EntityWidgetHandle {
  private permissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  private moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: MoreButtonActionName.DELETE,
      labelTk: 'homePageComponents.entityWidget.delete',
      iconClass: 'fal fa-trash',
      disabledContext: this,
      disabledPropertyName: 'canNotEditDeleted'
    }
  ];

  constructor(
    private readonly defect: Defect,
    private readonly options: DefectWidgetHandleOptions
  ) {
    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Defect,
        entity: defect
      });
  }

  public subscribe(options: EntityWidgetHandleSubscribeOptions): Disposable {
    const subscriptionManager =
      this.options.subscriptionManagerService.create();
    subscriptionManager.addDisposable(this.permissionsHandle.subscribe());

    const updatePicture = (): void => {
      const pictures =
        this.options.entityManager.pictureRepository.getByDefectId(
          this.defect.id
        );
      const mainPicture = pictures.find((p) => p.selected) ?? null;
      if (mainPicture) options.setPicture(mainPicture);
    };
    updatePicture();
    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updatePicture
    );

    const updateName = (): void => {
      options.setTitle(this.defect.name ?? '');
    };
    updateName();
    subscriptionManager.subscribeToExpression(this.defect, 'name', updateName);
    subscriptionManager.subscribeToModelChanges(EntityName.Defect, updateName);

    const updateDescription = (): void => {
      options.setSubTitle(this.defect.description ?? '');
    };
    updateDescription();
    subscriptionManager.subscribeToExpression(
      this.defect,
      'description',
      updateDescription
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      updateDescription
    );

    options.setMoreButtonChoices(this.moreButtonChoices);
    options.setMoreButtonChoicesClickHandler(
      this.handleChoiceSelected.bind(this)
    );

    options.setOnWidgetClicked(() =>
      this.options.router.navigateToRoute('edit_defect', {
        defect_id: this.defect.id,
        close_to_thing: true
      })
    );

    const updateMetadata = (): void => {
      options.setEntityDashboardInfo(this.getEntityDashboardInfo());
    };
    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateMetadata
    );
    updateMetadata();

    options.setOnStickyButtonClicked(() =>
      this.options.defectActionService.toggleStickyStatus(this.defect)
    );

    return subscriptionManager.toDisposable();
  }

  private getEntityDashboardInfo(): DefectEntityDashboardInfo | null {
    const metadata =
      this.options.entityManager.entityDashboardInfoRepository.getByDefectId(
        this.defect.id
      );
    return metadata[0] ?? null;
  }

  private handleChoiceSelected(name: string): void {
    switch (name) {
      case MoreButtonActionName.DELETE:
        return this.options.defectActionService.toggleDeletedStatus(
          this.defect
        );
      default:
        throw new Error(`unhandled choice "${name}"`);
    }
  }

  public getEntityId(): string {
    return this.defect.id;
  }

  @computedFrom('permissionsHandle.canEditField.deleted')
  public get canNotEditDeleted(): boolean {
    return !this.permissionsHandle.canEditField.deleted;
  }
}

type DefectWidgetHandleOptions = {
  entityManager: AppEntityManager;
  defectActionService: DefectActionService;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  router: Router;
};

enum MoreButtonActionName {
  DELETE = 'delete'
}
