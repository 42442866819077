import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { StructureTemplateStatus } from '../../../../common/src/Types/Entities/StructureTemplate/StructureTemplateDto';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateEntryGroup } from '../../classes/EntityManager/entities/StructureTemplateEntryGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { StructureTemplateEntryTreeItem } from '../structure-template-entry-tree-item/structure-template-entry-tree-item';

@autoinject()
export class StructureTemplateEntryGroupItem {
  @bindable public structureTemplate: StructureTemplate | null = null;

  @bindable
  public structureTemplateEntryGroup: StructureTemplateEntryGroup | null = null;

  @bindable public editable = false;

  protected structureTemplateEntries: Array<StructureTemplateEntry> = [];

  private subscriptionManager: SubscriptionManager;

  private domElement: HTMLElement;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk:
        'structureTemplateComponents.structureTemplateEntryGroupItem.deleteGroup',
      name: 'delete-group'
    }
  ];

  protected StructureTemplateStatus = StructureTemplateStatus;
  protected StructureTemplateEntryTreeItem = StructureTemplateEntryTreeItem;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Life Cycle **********

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateEntries.bind(this)
    );
    this.updateStructureTemplateEntries();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Aurelia Change Handler **********

  protected structureTemplateEntryGroupChanged(): void {
    this.updateStructureTemplateEntries();
  }

  // ********** Updaters **********

  private updateStructureTemplateEntries(): void {
    if (this.structureTemplateEntryGroup) {
      this.structureTemplateEntries =
        this.entityManager.structureTemplateEntryRepository.getByStructureTemplateEntryGroupId(
          this.structureTemplateEntryGroup.id
        );
    } else {
      this.structureTemplateEntries = [];
    }
  }

  // ********** Handlers **********

  protected handleGroupChanged(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'cannot update if no structure template entry group is available'
    );
    this.entityManager.structureTemplateEntryGroupRepository.update(
      this.structureTemplateEntryGroup
    );
  }

  protected handleDeleteGroupFromEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'structure template entry group is not available'
    );
    DomEventHelper.fireEvent<DeleteStructureTemplateEntryGroupEvent>(
      this.domElement,
      {
        name: 'delete-structure-template-entry-group',
        detail: {
          structureTemplateEntryGroupId: this.structureTemplateEntryGroup.id
        }
      }
    );
  }

  protected handleAddEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'no structure template entry group available'
    );

    this.entityManager.structureTemplateEntryRepository.create({
      ownerUserGroupId: this.structureTemplateEntryGroup.ownerUserGroupId,
      ownerStructureTemplateId:
        this.structureTemplateEntryGroup.ownerStructureTemplateId,
      structureTemplateEntryGroupId: this.structureTemplateEntryGroup.id
    });
  }

  protected handleDeleteGroupClick(): void {
    const structureTemplateEntryGroup = this.structureTemplateEntryGroup;
    assertNotNullOrUndefined(
      structureTemplateEntryGroup,
      'structure template entry group not available'
    );

    void Dialogs.deleteEntityDialog(structureTemplateEntryGroup).then(() => {
      this.entityManager.structureTemplateEntryGroupRepository.delete(
        structureTemplateEntryGroup
      );
    });
  }

  protected handleActivateDropTarget(
    viewModel: StructureTemplateEntryTreeItem,
    entry: StructureTemplateEntry
  ): boolean {
    return viewModel.structureTemplateEntry?.id !== entry.id;
  }

  protected handleDropItem(
    item: StructureTemplateEntryTreeItem,
    entryToReplace: StructureTemplateEntry | null
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntryGroup,
      'structure template entry group is not available'
    );

    const entry = item.structureTemplateEntry;
    assertNotNullOrUndefined(
      entry,
      'cannot drop element without a structure template entry'
    );

    const listPosition = entryToReplace?.listPosition ?? null;

    if (
      entry.structureTemplateEntryGroupId !==
      this.structureTemplateEntryGroup.id
    ) {
      this.entityManager.structureTemplateEntryRepository.addStructureTemplateEntryToGroup(
        entry,
        this.structureTemplateEntryGroup.id
      );
    }

    if (listPosition != null) {
      this.entityManager.structureTemplateEntryRepository.setListPositionOfStructureTemplateEntry(
        entry,
        listPosition
      );
    }
  }

  // ********** Getter **********

  @computedFrom('editable', 'structureTemplate.status')
  protected get isEditable(): boolean {
    return (
      this.editable &&
      this.structureTemplate?.status === StructureTemplateStatus.DRAFT
    );
  }
}

export type DeleteStructureTemplateEntryGroupEvent = NamedCustomEvent<
  'delete-structure-template-entry-group',
  { structureTemplateEntryGroupId: string }
>;
