import { bindable } from 'aurelia-framework';

import {
  Picture,
  PictureMarking
} from '../../classes/EntityManager/entities/Picture/types';

export class ScaledMarkedPicture {
  /**
   * the pictures which should be shown
   */
  @bindable public picture: Picture | null = null;

  /**
   * the current, highlighted marking
   */
  @bindable public currentMarkings: Array<PictureMarking> = [];

  /**
   * other markings which should be shown on the picture
   */
  @bindable public markings: Array<PictureMarking> = [];
}
