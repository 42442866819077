import { inflate, deflate } from 'fflate';

export class FFlateUtils {
  private static textEncoder = new TextEncoder();
  private static textDecoder = new TextDecoder();

  private constructor() {}

  public static inflateText(data: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
      inflate(data, (error, deflated) => {
        if (!error) {
          resolve(this.textDecoder.decode(deflated));
        } else {
          reject(error);
        }
      });
    });
  }

  public static deflateText(data: string): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      deflate(
        this.textEncoder.encode(data),
        {
          level: 1
        },
        (error, deflated) => {
          if (!error) {
            resolve(deflated);
          } else {
            reject(error);
          }
        }
      );
    });
  }
}
