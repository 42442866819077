/**
 * TODO: replace the OffsetValueConverter in the process-configuration-follow-up-appointments-widget as soon as possible
 *
 * converts milliseconds to days
 */
export class MsToDaysValueConverter {
  static msPerDay = 24 * 60 * 60 * 1000;

  /**
   * @param {number|null} offset
   * @returns {number}
   */
  toView(offset) {
    return (offset || 0) / MsToDaysValueConverter.msPerDay;
  }

  /**
   * @param {number} days
   * @returns {number}
   */
  fromView(days) {
    return days * MsToDaysValueConverter.msPerDay;
  }
}
