import { autoinject, bindable } from 'aurelia-framework';

import { ExternalUserCredentialTypes } from 'common/Enums/ExternalUserCredentialTypes';

import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ExternalUserCredential } from '../../classes/EntityManager/entities/ExternalUserCredential/types';
import {
  ChangePasswordPanel,
  PasswordVerifierResult,
  SetNewPasswordEventDetail
} from '../../aureliaComponents/change-password-panel/change-password-panel';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';

@autoinject()
export class EditExternalUserCredentialsWidget {
  @bindable public user: User | null = null;

  @bindable public enabled = false;

  protected externalUserCredentialTypes;

  private changePasswordPanelViewModel: ChangePasswordPanel | null = null;

  protected boundPasswordVerifier = this.passwordVerifier.bind(this);

  constructor(
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager
  ) {
    this.externalUserCredentialTypes = Object.values(
      ExternalUserCredentialTypes
    ).map((value) => {
      return {
        value: value,
        label: value
      };
    });
  }

  @computed(expression('user'), model(EntityName.ExternalUserCredential))
  protected get externalUserCredentials(): Array<ExternalUserCredential> {
    if (this.user) {
      return this.entityManager.externalUserCredentialRepository.getByUserId(
        this.user.id
      );
    } else {
      return [];
    }
  }

  protected handleExternalUserCredentialChanged(
    externalUserCredential: ExternalUserCredential
  ): void {
    this.entityManager.externalUserCredentialRepository.update(
      externalUserCredential
    );
  }

  protected handleNewCredentialClick(): void {
    if (!this.user) return;
    this.entityManager.externalUserCredentialRepository.create({
      userId: this.user.id,
      type: ExternalUserCredentialTypes.ASTERISK_AMI,
      password: '',
      port: 0
    });
  }

  protected handleDeleteCredentialClick(
    externalUserCredential: ExternalUserCredential
  ): void {
    void Dialogs.deleteEntityDialog(externalUserCredential).then(() => {
      this.entityManager.externalUserCredentialRepository.delete(
        externalUserCredential
      );
    });
  }

  protected handleSetNewPasswordClick(
    externalUserCredential: ExternalUserCredential,
    eventDetail: SetNewPasswordEventDetail
  ): void {
    if (this.changePasswordPanelViewModel)
      this.changePasswordPanelViewModel.loading = true;
    this.socketService.changeExternalUserCredentialPassword(
      externalUserCredential.id,
      eventDetail.newPassword,
      () => {
        if (this.changePasswordPanelViewModel) {
          this.changePasswordPanelViewModel.loading = false;
          this.changePasswordPanelViewModel.closeChangePasswordPanel();
          this.changePasswordPanelViewModel.resetFields();
        }
      }
    );
  }

  private passwordVerifier(
    oldPassword: string,
    newPassword1: string,
    newPassword2: string
  ): PasswordVerifierResult {
    if (newPassword2 && newPassword1 !== newPassword2) {
      return {
        passwordFieldsAreValid: false,
        newPassword2InputErrorTextTk:
          'aureliaComponents.editExternalUserCredentialsWidget.noMatchingPasswordsInputErrorMessage'
      };
    }
    return {
      passwordFieldsAreValid: true
    };
  }
}
