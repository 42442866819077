import { TupleArray } from '../../Types/utilities';

export function createTupleOfUnionValidator<TKeys extends string>(): <
  TTuple extends Array<TKeys>
>(
  options: { tuple: TupleArray<TTuple> } & ValidateTupleValidationParams<
    TKeys,
    TTuple
  >
) => TTuple {
  return ({ tuple }) => tuple;
}

export type ValidateTupleValidationParams<
  TKeys extends string,
  TTuple extends Array<TKeys>,
  TValidateResult = ValidateTuple<TKeys, TTuple>
> = [TValidateResult] extends [true]
  ? Record<never, never>
  : { errors: Record<Extract<TValidateResult, string>, true> };

type ValidateTuple<
  TKeys extends string,
  TTuple extends Array<TKeys>,
  AllKeysAreExtendedResult = AllKeysAreExtended<TKeys, TTuple>,
  HasNoDuplicatesResult = HasNoDuplicates<TTuple>
> = AllKeysAreExtendedResult extends true
  ? HasNoDuplicatesResult extends true
    ? true
    : HasNoDuplicatesResult
  : AllKeysAreExtendedResult;

type HasNoDuplicates<TTuple extends Array<string>> = TTuple extends [
  infer L,
  ...infer R
]
  ? L extends string
    ? R extends Array<string>
      ? L extends R[number]
        ? `${L} is a duplicate`
        : HasNoDuplicates<R>
      : `a non string was found`
    : `a non string was found`
  : true;

type AllKeysAreExtended<
  TKeys extends string,
  TTuple extends Array<TKeys>,
  TTupleValues = TTuple[number]
> = [TKeys] extends [TTupleValues]
  ? [TTupleValues] extends [TKeys]
    ? true
    : `${Exclude<TKeys, TTupleValues>} is missing`
  : `${Exclude<TKeys, TTupleValues>} is missing`;
