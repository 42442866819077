import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskMeasurePointToPictureEntityInfo } from 'common/Types/Entities/ProcessTaskMeasurePointToPicture/createProcessTaskMeasurePointToPictureEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskMeasurePointToPicture } from './types';

export const processTaskMeasurePointToPictureEntityInfo =
  createProcessTaskMeasurePointToPictureEntityInfo<ProcessTaskMeasurePointToPicture>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
