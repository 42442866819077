import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationDeviceExportDto } from './ProcessConfigurationDeviceExportDto';

export function createProcessConfigurationDeviceExportEntityInfo<
  TEntity extends ProcessConfigurationDeviceExportDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationDeviceExportDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationDeviceExport,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationDeviceExport,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationDeviceExportDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfigurationDevice,
          cascadeDeleteLocally: true
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        reportTypeId: {
          name: 'reportTypeId',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        includeUnspecifiedDevices: {
          name: 'includeUnspecifiedDevices',
          defaultValue: false
        },
        defaultForNewDevices: {
          name: 'defaultForNewDevices',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
