import {
  MoreButton,
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../more-button/more-button';

/**
 * this is basically a global more button
 *
 * before calling any set function, takeControl first
 * every takeControl call also needs a releaseControl call
 */
export class GlobalMenu {
  private static instance: GlobalMenu | null = null;
  private static controls: Array<GlobalMenuControl> = [];

  private visible: boolean = false;
  private choices: Array<MoreButtonChoice> = [];

  private moreButton: MoreButton | null = null;

  protected attached(): void {
    if (GlobalMenu.instance) {
      console.error(
        "There is already another instance of 'GlobalMenu'. This is not allowed and the existing instance will be replaced."
      );
    }

    GlobalMenu.instance = this;

    this.syncToCurrentControl();
  }

  protected detached(): void {
    GlobalMenu.instance = null;
  }

  public close(): void {
    this.moreButton?.close();
  }

  private syncToCurrentControl(): void {
    const control = GlobalMenu.getCurrentControlOrDefault();
    this.visible = control.visible;
    this.choices = control.choices;

    if (!this.visible) {
      this.close();
    }
  }

  private handleChoiceSelected(name: string): void {
    this.callCallback(name, CallbackType.SELECTED, []);
  }

  private handleChoiceFileChanged(name: string, originalEvent: Event): void {
    this.callCallback(name, CallbackType.FILE_CHANGED, [originalEvent]);
  }

  private callCallback(
    name: string,
    type: CallbackType,
    args: Array<any>
  ): void {
    const control = GlobalMenu.getCurrentControlOrDefault();
    let callbacks: Record<string, (...args0: any) => void> = {};

    switch (type) {
      case CallbackType.FILE_CHANGED:
        callbacks = control.fileChangedCallbacks;
        break;

      case CallbackType.SELECTED:
        callbacks = control.selectedCallbacks;
        break;

      default:
        throw new Error('invalid callback type given');
    }

    callbacks[name]?.(...args);
  }

  public static takeControl(scope: any, options: TakeControlOptions): void {
    if (this.getControlByScope(scope)) {
      console.error('this scope already has control', scope);
      return;
    }

    this.controls.push({
      scope: scope,
      choices: options.choices,
      visible: options.visible,
      fileChangedCallbacks: options.fileChangedCallbacks
        ? options.fileChangedCallbacks
        : {},
      selectedCallbacks: options.selectedCallbacks
        ? options.selectedCallbacks
        : {}
    });

    this.syncToInstance();
    this.closeInstance();
  }

  public static setChoices(scope: any, choices: Array<MoreButtonChoice>): void {
    const control = this.getControlByScope(scope);
    if (control) {
      control.choices = choices;
      this.syncToInstance();
    }
  }

  public static setVisible(scope: any, visible: boolean): void {
    const control = this.getControlByScope(scope);
    if (control) {
      control.visible = visible;
      this.syncToInstance();
    }
  }

  public static releaseControl(scope: any): void {
    const index = this.controls.findIndex((c) => c.scope === scope);
    if (index >= 0) {
      this.controls.splice(index);
      this.syncToInstance();
      this.closeInstance();
    }
  }

  public static hasControl(scope: any): boolean {
    return this.getControlByScope(scope) != null;
  }

  private static getControlByScope(scope: any): GlobalMenuControl | null {
    return this.controls.find((c) => c.scope === scope) ?? null;
  }

  private static getCurrentControlOrDefault(): GlobalMenuControl {
    const control = this.controls[this.controls.length - 1];
    return control
      ? control
      : {
          scope: null,
          choices: [],
          visible: false,
          fileChangedCallbacks: {},
          selectedCallbacks: {}
        };
  }

  private static syncToInstance(): void {
    this.instance && this.instance.syncToCurrentControl();
  }

  private static closeInstance(): void {
    this.instance && this.instance.close();
  }
}

enum CallbackType {
  FILE_CHANGED = 'fileChanged',
  SELECTED = 'selected'
}

type TakeControlOptions = {
  choices: Array<MoreButtonChoice>;
  visible: boolean;

  /** callbacks: callbacks are a map of the IMoreButtonChoice name to it's handler */

  /** get called when the more-button file changed event gets fired */
  fileChangedCallbacks?: Record<
    string,
    (event: MoreButtonFileChangedEvent<any>['detail']) => void
  >;

  /** get called when the more-button selected event gets fired */
  selectedCallbacks?: Record<string, () => void>;
};

type GlobalMenuControl = {
  scope: any;
  choices: Array<MoreButtonChoice>;
  visible: boolean;
  fileChangedCallbacks: Record<
    string,
    (originalEvent: MoreButtonFileChangedEvent<any>['detail']) => void
  >;
  selectedCallbacks: Record<string, () => void>;
};
