export class SerialTask<TData, TReturn> {
  private nextTaskInfo: TaskInfo<TData, TReturn> | null = null;
  private isPending = false;

  constructor(private readonly task: (data: TData) => Promise<TReturn>) {}

  public run(data: TData): Promise<TReturn> {
    if (this.isPending) {
      return this.createNewNextTaskPromise(data);
    }

    return this.runTask(data);
  }

  private createNewNextTaskPromise(data: TData): Promise<TReturn> {
    if (this.nextTaskInfo) {
      this.nextTaskInfo.reject(new RunSkippedError());
    }

    return new Promise((resolve, reject) => {
      this.nextTaskInfo = {
        resolve,
        reject,
        data
      };
    });
  }

  private async runTask(data: TData): Promise<TReturn> {
    this.isPending = true;

    try {
      const result = await this.task(data);
      return result;
    } finally {
      this.isPending = false;
      this.runNextTaskIfNecessary();
    }
  }

  private runNextTaskIfNecessary(): void {
    const info = this.nextTaskInfo;
    this.nextTaskInfo = null;

    if (!info) return;

    this.runTask(info.data).then(info.resolve, info.reject);
  }
}

export class RunSkippedError extends Error {}

type TaskInfo<TData, TReturn> = {
  resolve: (value: TReturn) => void;
  reject: (error: Error) => void;
  data: TData;
};
