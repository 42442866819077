import '../../_debugFunctions';

import { App as CapacitorApp } from '@capacitor/app';

import { autoinject, PLATFORM } from 'aurelia-framework';
import {
  Router,
  Redirect,
  RouterConfiguration,
  NavigationInstruction,
  Next,
  PipelineStep
} from 'aurelia-router';
import { BindingSignaler } from 'aurelia-templating-resources';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';

import { SocketService } from '../../services/SocketService';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { GlobalFilter } from '../../aureliaComponents/global-filter/global-filter';
import { getRoutes } from '../../config/routes';
import { GlobalSignalsHelper } from '../../classes/GlobalSignalsHelper';
import { RecordItModuleHelper } from '../../classes/RecordItModuleHelper';
import { TestingHelper } from '../../classes/TestingHelper';
import { CheckEntitiesAvailableStep } from './CheckEntitiesAvailableStep';
import { SocketEventListeners } from '../../classes/SocketEventListeners';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { LocalProjectFilesManager } from '../../classes/LocalProjectFilesManager';
import { ProcessTaskGroupSynchronizationManagerService } from '../../services/ProcessTaskGroupSynchronizationManagerService/ProcessTaskGroupSynchronizationManagerService';
import { KeyboardFocusDetector } from '../../classes/DomUtilities/KeyboardFocusDetector';
import { MainPageLoaderHelper } from '../../classes/MainPageLoaderHelper';
import { LostCapturedPicturesRescueService } from '../../services/LostCapturedPicturesRescueService/LostCapturedPicturesRescueService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SynchronizationStatusSidebar } from '../../synchronizationComponents/synchronization-status-sidebar/synchronization-status-sidebar';
import { MainMenuSidebar } from '../../aureliaComponents/main-menu-sidebar/main-menu-sidebar';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ChangelogService } from '../../services/ChangelogService';
import { Logger } from '../../classes/Logger/Logger';
import { NavigationService } from '../../services/NavigationService';
import { ProcessConfigurationPicturesService } from '../../services/ProcessConfigurationPicturesService/ProcessConfigurationPicturesService';
import { ProcessTaskGroupPicturesService } from '../../services/ProcessTaskGroupPicturesService/ProcessTaskGroupPicturesService';

@autoinject()
export class App {
  private currentUser: User | null = null;

  private synchronizationStatusSidebar: SynchronizationStatusSidebar | null =
    null;
  private mainMenuSidebar: MainMenuSidebar | null = null;

  private loggerUserConfigured = false;

  private keyboardFocusDetector: KeyboardFocusDetector | null = null;

  private subscriptionManager: SubscriptionManager;
  private socketEventListeners: SocketEventListeners;
  private globalSignalsHelper: GlobalSignalsHelper;
  private localProjectFilesManager: LocalProjectFilesManager | null = null;

  constructor(
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly socketService: SocketService,
    private readonly eventAggregator: EventAggregator,
    bindingSignaler: BindingSignaler,
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    i18n: I18N,
    processTaskGroupSynchronizationManagerService: ProcessTaskGroupSynchronizationManagerService,
    private readonly lostCapturedPicturesRescueService: LostCapturedPicturesRescueService,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly changelogService: ChangelogService,
    processConfigurationPicturesService: ProcessConfigurationPicturesService,
    processTaskGroupPicturesService: ProcessTaskGroupPicturesService
  ) {
    MainPageLoaderHelper.setInitializationStep('constructing app');

    TestingHelper.appRouter = router;
    this.subscriptionManager = subscriptionManagerService.create();

    if (DeviceInfoHelper.isApp()) {
      MainPageLoaderHelper.setInitializationStep(
        'initializing project files manager'
      );
      this.initLocalProjectFilesManager();
    }

    this.globalSignalsHelper = new GlobalSignalsHelper(
      subscriptionManagerService,
      bindingSignaler
    );
    this.socketEventListeners = new SocketEventListeners({
      eventAggregator: eventAggregator,
      navigationService: navigationService,
      socketService: socketService,
      entityManager: entityManager,
      i18n: i18n
    });
    MainPageLoaderHelper.setInitializationStep(
      'initializing socket event listeners'
    );
    this.socketEventListeners.init();

    MainPageLoaderHelper.setInitializationStep('initializing url restoration');
    MainPageLoaderHelper.setInitializationStep(
      'intializing processTaskGroupSynchronizationManagerService'
    );
    processTaskGroupSynchronizationManagerService.initSubscriptions();

    setTimeout(() => {
      // delayed since this isn't really important and to have a smoother app start
      void processConfigurationPicturesService.cleanup().finally(() => {
        void processTaskGroupPicturesService.cleanup();
      });
    }, 30000);
  }

  protected activate(): void {
    MainPageLoaderHelper.setInitializationStep('activating app');

    this.socketService.connect();
  }

  protected attached(): void {
    MainPageLoaderHelper.setInitializationStep('attaching app');
    MainPageLoaderHelper.hide();

    const swipingAngle = function (
      xStart: number,
      xEnd: number,
      yStart: number,
      yEnd: number
    ): number {
      const yDiffAbs = Math.abs(yEnd - yStart);
      const xDiffAbs = Math.abs(xEnd - xStart);
      if (xDiffAbs === 0) return 0;
      return (360 * Math.atan(yDiffAbs / xDiffAbs)) / (2 * Math.PI);
    };

    $('body').swipe({
      swipeRight: (
        event,
        direction,
        distance,
        duration,
        fingerCount,
        fingerData
      ) => {
        if (
          swipingAngle(
            fingerData[0].start.x,
            fingerData[0].end.x,
            fingerData[0].start.y,
            fingerData[0].end.y
          ) > 22.5
        )
          return;
        if (fingerData[0].start.x < 20 && fingerData[0].end.x >= 10) {
          if (this.synchronizationStatusSidebar?.isShown()) {
            this.synchronizationStatusSidebar.hide();
          }
          this.mainMenuSidebar?.show();
        }
      },
      swipeLeft: (
        event,
        direction,
        distance,
        duration,
        fingerCount,
        fingerData
      ) => {
        if (
          swipingAngle(
            fingerData[0].start.x,
            fingerData[0].end.x,
            fingerData[0].start.y,
            fingerData[0].end.y
          ) > 22.5
        )
          return;
        if (
          fingerData[0].start.x > $(window).width() - 20 &&
          fingerData[0].end.x <= $(window).width() - 10
        ) {
          if (this.mainMenuSidebar?.isShown()) {
            this.mainMenuSidebar.hide();
          }
          this.synchronizationStatusSidebar?.show();
        }
      },
      threshold: 20,
      allowPageScroll: 'vertical',
      preventDefaultEvents: false
    });

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.keyboardFocusDetector = new KeyboardFocusDetector();
    this.keyboardFocusDetector.init();

    this.changelogService.init();

    if (DeviceInfoHelper.isApp()) void this.tryToRescueLostCapturedPictures();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    GlobalLoadingOverlay.setLoadingState(this, false);

    if (this.keyboardFocusDetector) {
      this.keyboardFocusDetector.destroy();
    }

    this.changelogService.destroy();
  }

  protected configureRouter(config: RouterConfiguration): void {
    MainPageLoaderHelper.setInitializationStep('configuring router');

    config.addAuthorizeStep(new AuthorizeStep(this.currentUserService));
    config.addPreActivateStep(
      new RedirectStep(this.currentUserService, this.entityManager)
    );
    config.addPreActivateStep(new PreActivateStep());
    config.addPreActivateStep(
      new CheckEntitiesAvailableStep(this.entityManager)
    );

    config.map(getRoutes());
    config.mapUnknownRoutes(PLATFORM.moduleName('pages/home_page/home_page'));
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    if (this.currentUser && !this.loggerUserConfigured) {
      Logger.setUser({ user: this.currentUser });
      this.loggerUserConfigured = true;
    }
  }

  // ******* INITIALIZATION FUNCTIONS //////////

  // ******* VIEW MODEL *******

  private handleOpenSynchronizationStatusSidebarClick(): void {
    if (!this.synchronizationStatusSidebar) {
      throw new Error('synchronizationStatusSidebar is not available');
    }
    this.synchronizationStatusSidebar.show();
  }

  private handleOpenNavigationSidebarClick(): void {
    if (!this.mainMenuSidebar) {
      throw new Error('mainMenuSidebar is not available');
    }
    this.mainMenuSidebar.show();
  }

  private initLocalProjectFilesManager(): void {
    this.localProjectFilesManager = new LocalProjectFilesManager(
      this.entityManager
    );

    TestingHelper.localProjectFilesManager = this.localProjectFilesManager;

    void CapacitorApp.addListener('appStateChange', (appState) => {
      if (!appState.isActive) return;

      setTimeout(() => {
        if (this.localProjectFilesManager) {
          void this.localProjectFilesManager.handleLocalProjectFiles();
        }
      }, 30000);
    });

    void this.localProjectFilesManager.handleLocalProjectFiles();
  }

  private async tryToRescueLostCapturedPictures(): Promise<void> {
    await this.lostCapturedPicturesRescueService.rescueLostCapturedPictures();
  }
}

class AuthorizeStep {
  constructor(private currentUserService: CurrentUserService) {}

  public run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<void> {
    if (navigationInstruction.config.auth == true) {
      return this.currentUserService.waitForCurrentUser().then((user) => {
        // TODO: what if token expired?

        if (user.admin) return next();

        if (navigationInstruction.config.settings.permissionName) {
          if (
            PermissionHelper.userHasPermission(
              user,
              navigationInstruction.config.settings.permissionName
            )
          )
            return next();
        } else {
          if (!navigationInstruction.config.admin) return next();
        }
        return next.cancel(new Redirect('home'));
      });
    } else {
      return next();
    }
  }
}

class RedirectStep implements PipelineStep {
  constructor(
    private currentUserService: CurrentUserService,
    private readonly entityManager: AppEntityManager
  ) {}

  public run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<void> {
    if (navigationInstruction.config.auth == true) {
      return this.currentUserService.waitForCurrentUser().then((user) => {
        // TODO: what if token expired?
        const availableModules =
          PermissionHelper.getAvailableModulesForUser(user);
        const firstModule = availableModules[0];

        const currentCompanySetting = user.userCompanySettingId
          ? this.entityManager.userCompanySettingRepository.getById(
              user.userCompanySettingId
            )
          : null;

        if (user.admin) return next();

        if (navigationInstruction.config.settings.permissionName) {
          if (
            PermissionHelper.userHasPermission(
              user,
              navigationInstruction.config.settings.permissionName
            )
          )
            return next();
        } else if (
          navigationInstruction.config.name === 'home' &&
          availableModules.length === 1 &&
          firstModule &&
          currentCompanySetting?.homePage.usesLegacyTileHomePage
        ) {
          const newRoute =
            RecordItModuleHelper.getOverviewPageRouteForModuleName(firstModule);
          if (newRoute) {
            return next.cancel(new Redirect(newRoute));
          }
          return next();
        } else {
          if (!navigationInstruction.config.admin) return next();
        }
        return next.cancel(new Redirect('home'));
      });
    } else {
      return next();
    }
  }
}

class PreActivateStep {
  private lastInstruction: NavigationInstruction | null = null;
  private excludedQueryParams: Array<string> = ['page'];

  public run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<void> {
    if (this.shouldClearGlobalFilter(navigationInstruction)) {
      GlobalFilter.clear();
    }

    this.lastInstruction = navigationInstruction;
    return next();
  }

  private shouldClearGlobalFilter(
    navigationInstruction: NavigationInstruction
  ): boolean {
    if (!this.lastInstruction) {
      return true;
    }

    if (this.lastInstruction.config !== navigationInstruction.config) {
      return true;
    }

    if (
      !this.queryParamsAreEqualOneWay(
        this.lastInstruction.queryParams,
        navigationInstruction.queryParams
      )
    ) {
      return true;
    }

    if (
      !this.queryParamsAreEqualOneWay(
        navigationInstruction.queryParams,
        this.lastInstruction.queryParams
      )
    ) {
      return true;
    }

    return false;
  }

  /**
   * checks if the params of paramsA are contained in paramsB
   * but doesn't check if paramsB has additional params
   */
  private queryParamsAreEqualOneWay(
    paramsA: Record<string, string> | null,
    paramsB: Record<string, string> | null
  ): boolean {
    if (paramsA == null || paramsB == null) {
      // no strict equality because it's a null check
      // eslint-disable-next-line eqeqeq
      return paramsA == paramsB;
    }

    const propertyNames = Object.getOwnPropertyNames(paramsA);
    for (const propertyName of propertyNames) {
      if (this.excludedQueryParams.indexOf(propertyName) >= 0) {
        continue;
      }

      if (paramsA[propertyName] !== paramsB[propertyName]) {
        return false;
      }
    }

    return true;
  }
}
