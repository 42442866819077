import { inject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
  ModuleName,
  RecordItModuleHelper
} from '../../classes/RecordItModuleHelper';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { InlineSvgLoaderSvgCopier } from '../../classes/Svg/InlineSvgLoader';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @attribute data-align - alignment of the text possible values: 'baseline', 'middle' - default: 'middle'
 */
@inject(Router, ActiveUserCompanySettingService, SubscriptionManagerService)
export class MainCaption {
  @bindable showDescription = false;

  @bindable showModuleName = true;

  /** @type {Router} */
  _router;
  /** @type {ActiveUserCompanySettingService} */
  _activeUserCompanySettingService;

  /** @type {string|null} */
  @observable _logoSvg;

  /** @type {boolean} */
  _showSvgElement = false;
  /** @type {SVGSVGElement|null} */
  _svgElement = null;
  /** @type {number|null} */
  _logoBaselineOffset = null;
  /** @type {boolean} */
  _showModuleNameSetting = false;
  /** @type {number|null} */
  _height = null;

  /**
   * @param {Router} router
   * @param {ActiveUserCompanySettingService} activeUserCompanySettingService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(
    router,
    activeUserCompanySettingService,
    subscriptionManagerService
  ) {
    this._router = router;
    this._activeUserCompanySettingService = activeUserCompanySettingService;
    this._subscriptionManager = subscriptionManagerService.create();

    this._logoSvg = null;
  }

  attached() {
    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'logo.svg',
        (svg) => {
          this._logoSvg = svg;
        }
      )
    );

    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'logo.svgBaselineOffset',
        (svgBaselineOffset) => {
          this._logoBaselineOffset = svgBaselineOffset;
        }
      )
    );

    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'logo.height',
        (height) => {
          this._height = height;
        }
      )
    );

    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'general.showModuleName',
        (showModuleName) => {
          this._showModuleNameSetting = showModuleName;
        }
      )
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _logoSvgChanged() {
    if (this._logoSvg && this._svgElement) {
      this._showSvgElement = true;

      const wrapper = document.createElement('div');
      wrapper.innerHTML = this._logoSvg;
      const svgElement = /** @type {SVGSVGElement} */ (
        wrapper.firstElementChild
      );
      const copier = new InlineSvgLoaderSvgCopier(
        svgElement,
        this._svgElement,
        true
      );
      copier.copy();
    } else {
      this._showSvgElement = false;
    }
  }

  /**
   * @param {number|null} logoBaselineOffset
   * @returns {object}
   */
  _getLogoSvgWrapperStyle(logoBaselineOffset) {
    if (logoBaselineOffset != null) {
      return {
        transform: 'translate(0,' + logoBaselineOffset + '%)'
      };
    }

    return {};
  }

  /**
   * @param {number|null} height
   * @returns {object}
   */
  _getLogoSvgStyle(height) {
    if (height != null) {
      return {
        height: height + 'px'
      };
    }

    return null;
  }

  /**
   *
   * @param {ModuleName} moduleName
   * @returns {string|null}
   * @private
   */
  _getDetailTitleFromModuleName(moduleName) {
    if (moduleName && moduleName !== ModuleName.GENERAL) {
      return RecordItModuleHelper.getShortDisplayNameForModuleName(moduleName);
    } else {
      return null;
    }
  }
}
