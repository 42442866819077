import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { OriginalIdUtils } from '../../utils/OriginalIdUtils/OriginalIdUtils';
import {
  EntityIdUpdateService,
  UpdateConfigType
} from '../generalServices/EntityIdUpdateService';
import { AppEntityManager } from '../AppEntityManager';
import { Tag } from '../Tag/types';
import { EntityName } from '../types';
import { Picture } from './types';

@autoinject()
export class PictureIdUpdateService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly entityIdUpdateService: EntityIdUpdateService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Picture,
        this.handlePictureIdUpgraded.bind(this)
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Tag,
        this.handleTagIdUpgraded.bind(this)
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityIdUpdateService.registerUpdateConfig<
        EntityName.Picture,
        EntityName.Tag
      >({
        type: UpdateConfigType.CUSTOM_CALLBACK,
        entityName: EntityName.Picture,
        referencedEntityName: EntityName.Tag,
        callback: this.removeTagReferences.bind(this)
      })
    );
  }

  public async flush(): Promise<void> {
    this.subscriptionManager.flush();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private handlePictureIdUpgraded(upgradedPicture: Picture): void {
    const originalIds =
      OriginalIdUtils.getOriginalIdsForEntity(upgradedPicture);
    for (const picture of this.entityManager.pictureRepository.getAll()) {
      let modified = false;

      for (const marking of picture.additional_markings) {
        if (originalIds.includes(marking.picture_id)) {
          marking.picture_id = upgradedPicture.id;
          modified = true;
        }
      }

      if (
        picture.coordsFromPositionedPictureInfo &&
        originalIds.includes(picture.coordsFromPositionedPictureInfo.pictureId)
      ) {
        picture.coordsFromPositionedPictureInfo.pictureId = upgradedPicture.id;
        modified = true;
      }

      if (modified) {
        this.entityManager.pictureRepository.update(picture);
      }
    }
  }

  private handleTagIdUpgraded(upgradedTag: Tag): void {
    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(upgradedTag);
    for (const picture of this.entityManager.pictureRepository.getAll()) {
      let modified = false;

      picture.tagIds.forEach((tagId, index) => {
        if (originalIds.includes(tagId)) {
          picture.tagIds[index] = upgradedTag.id;
          modified = true;
        }
      });

      if (modified) {
        this.entityManager.pictureRepository.update(picture);
      }
    }
  }

  private removeTagReferences(picture: Picture, tag: Tag): boolean {
    let hasChanged = false;

    for (let i = picture.tagIds.length - 1; i >= 0; i--) {
      const tagId = picture.tagIds[i];
      if (tagId === tag.id) {
        picture.tagIds.splice(i, 1);
        hasChanged = true;
      }
    }

    return hasChanged;
  }
}
