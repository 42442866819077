import { autoinject, bindable, observable } from 'aurelia-framework';

import { PersonCategories } from 'common/Types/PersonCategories';
import { PersonListItemFirstColumnContent } from 'common/Enums/PersonListItemFirstColumnContent';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  PersonSortOptions,
  PersonUtils
} from '../../classes/EntityManager/entities/Person/PersonUtils';
import { PersonFilterer } from '../../classes/EntityManager/entities/Person/PersonFilterer';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { computed } from '../../hooks/computed';
import {
  activeUserCompanySetting,
  expression,
  model
} from '../../hooks/dependencies';
import { Utils } from '../../classes/Utils/Utils';

@autoinject()
export class EditPersons {
  protected categoryFilteredPersons: Array<Person> = [];

  protected sortedPersons: Array<Person> = [];

  protected currentPagePersons: Array<Person> = [];

  protected sortOptions: PersonSortOptions;

  protected PersonListItemFirstColumnContent = PersonListItemFirstColumnContent;

  protected isMobile: boolean = false;

  protected currentSortOption: SorterSortOption<Person>;

  @observable protected filterString: string | null;

  @bindable protected currentCategoryFilterOption: string | null;

  private debouncedFilterString: string | null = null;

  private pagination: Pagination<Person> | null = null;

  private personFilterer: PersonFilterer;

  private updateFilterStringDebounced = Utils.debounceFunction(() => {
    this.debouncedFilterString = this.filterString;
  }, 500);

  private isAttached: boolean = false;

  private subscriptionManager: SubscriptionManager;

  protected readonly EntityName = EntityName;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.sortOptions = PersonUtils.getSortOptions(() => this.personCategories);
    this.currentSortOption = this.sortOptions.name;
    this.personFilterer = new PersonFilterer(false);

    this.filterString = null;
    this.currentCategoryFilterOption = null;
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected filterStringChanged(): void {
    this.updateFilterStringDebounced();
  }

  protected handleCreatePersonClicked(userGroup: UserGroup): void {
    const person = this.entityManager.personRepository.create({
      ownerUserGroupId: userGroup.id
    });

    this.goToPerson(person);
  }

  protected getPersonListItemId(personId: string): string {
    return 'edit-persons--person-' + personId;
  }

  @computed(model(EntityName.Person))
  protected get availablePersons(): Array<Person> {
    return this.entityManager.personRepository.getAll();
  }

  @computed(
    expression('debouncedFilterString'),
    expression('currentCategoryFilterOption'),
    expression('personCategories'),
    expression('availablePersons')
  )
  protected get filteredPersons(): Array<Person> {
    this.personFilterer.clear();
    this.personFilterer.addFilterText(this.filterString);
    const results = this.personFilterer.filter(this.availablePersons);
    const mappedResult = results.map((r) => r.person);
    if (this.personCategories.length === 0) {
      this.categoryFilteredPersons = mappedResult;
    }
    return mappedResult;
  }

  private goToPerson(person: Person): void {
    if (this.currentCategoryFilterOption) {
      return;
    }

    void ScrollHelper.autoScrollToListItem(
      '#' + this.getPersonListItemId(person.id),
      this.pagination,
      person,
      () => this.isAttached
    );
  }

  @computed(activeUserCompanySetting('general.personCategories'))
  private get personCategories(): PersonCategories {
    return (
      this.activeUserCompanySettingService.getJSONSettingProperty(
        'general.personCategories'
      ) ?? []
    );
  }

  @computed(
    activeUserCompanySetting('general.personListItemFirstColumnContent')
  )
  protected get activeFirstColumnContentType(): PersonListItemFirstColumnContent {
    return (
      this.activeUserCompanySettingService.getSettingProperty(
        'general.personListItemFirstColumnContent'
      ) ?? PersonListItemFirstColumnContent.CATEGORY
    );
  }
}
