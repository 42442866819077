import { autoinject, bindable } from 'aurelia-framework';

import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskChecklistEntry } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ProcessTaskDeselectedEvent,
  ProcessTaskSelectedEvent
} from '../multi-process-task-selection-widget/multi-process-task-selection-widget';
import {
  ChecklistEntryAttachments,
  ProcessTaskChecklistService
} from '../../services/ProcessTaskChecklistService/ProcessTaskChecklistService';
import {
  ProcessTaskChecklistListItemLayout,
  TextChangedEvent
} from './process-task-checklist-list-item-layout/process-task-checklist-list-item-layout';
import { GeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { MultiFileInput } from '../../inputComponents/multi-file-input/multi-file-input';
import { InlineCreationSection } from '../../aureliaComponents/inline-creation-section/inline-creation-section';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { watch } from '../../hooks/watch';
import { expression, model } from '../../hooks/dependencies';

@autoinject()
export class ProcessTaskChecklist {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public entryCreationEnabled: boolean = false;

  /**
   * null if entries aren't loaded
   * read only
   */
  @bindable()
  public openChecklistEntryCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTasksWhereChecklistEntriesCanBeCreatedHandle: EntitiesWithPermissionHandle<EntityName.ProcessTask>;

  protected availableChecklistEntries: Array<ProcessTaskChecklistEntry> = [];
  protected selectedProcessTasks: Array<ProcessTask> = [];

  private isAttached: boolean = false;
  private newEntryText: string | null = null;
  private showDoneEntries: boolean = false;

  protected newEntryListItem: ProcessTaskChecklistListItemLayout | null = null;
  protected newEntryMultiFileInput: MultiFileInput | null = null;
  protected newEntryInlineCreationSection: InlineCreationSection | null = null;

  protected readonly GeneralFileHelper = GeneralFileHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskChecklistService: ProcessTaskChecklistService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTasksWhereChecklistEntriesCanBeCreatedHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.ProcessTask,
        permissionName: 'canCreateProcessTaskChecklistEntries'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskChecklistEntry,
      this.updateAvailableChecklistEntries.bind(this)
    );
    this.updateAvailableChecklistEntries();

    let defaultShowDoneChecklistEntriesIsSet = false;
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.defaultShowDoneChecklistEntries',
        (defaultShowDoneChecklistEntries) => {
          if (!defaultShowDoneChecklistEntriesIsSet) {
            this.showDoneEntries = defaultShowDoneChecklistEntries;
            this.handleShowDoneEntriesChanged();
            defaultShowDoneChecklistEntriesIsSet = true;
          }
        }
      )
    );

    this.updateProcessTasksWhereChecklistEntriesCanBeCreatedHandle();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableChecklistEntries();
      if (this.processTask) {
        this.selectedProcessTasks = [this.processTask];
      }
    }
  }

  private updateAvailableChecklistEntries(): void {
    let availableEntries: Array<ProcessTaskChecklistEntry> = [];

    if (this.processTask) {
      availableEntries =
        this.entityManager.processTaskChecklistEntryRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.openChecklistEntryCount = availableEntries.filter(
        (a) => !a.done
      ).length;
    } else {
      this.openChecklistEntryCount = null;
    }

    if (!this.showDoneEntries) {
      availableEntries = availableEntries.filter((a) => !a.done);
    }

    availableEntries.sort((a, b) => {
      return (a.done ? 1 : 0) - (b.done ? 1 : 0);
    });

    this.availableChecklistEntries = availableEntries;
  }

  @watch(
    expression('processTask.ownerProcessTaskGroupId'),
    model(EntityName.ProcessTask)
  )
  private updateProcessTasksWhereChecklistEntriesCanBeCreatedHandle(): void {
    if (this.processTask) {
      this.processTasksWhereChecklistEntriesCanBeCreatedHandle.setEntities(
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTask.ownerProcessTaskGroupId
        )
      );
    } else {
      this.processTasksWhereChecklistEntriesCanBeCreatedHandle.setEntities([]);
    }
  }

  protected handleShowDoneEntriesChanged(): void {
    this.updateAvailableChecklistEntries();
  }

  protected handleCreationSectionOpened(): void {
    assertNotNullOrUndefined(
      this.newEntryListItem,
      "can't handleCreationSectionOpened without newEntryListItem"
    );
    this.newEntryListItem.focus();
  }

  protected handleCreationSectionClosed(): void {
    if (this.newEntryMultiFileInput) {
      this.newEntryMultiFileInput.selectedFiles = [];
    }
  }

  protected handleNewEntryTextChanged(event: TextChangedEvent): void {
    this.newEntryText = event.detail.value as string | null;
  }

  protected handleCreateNewEntryClick(): true {
    void this.createChecklistEntries().then(() => {
      this.updateAvailableChecklistEntries();

      this.newEntryText = null;
      if (this.processTask) {
        this.selectedProcessTasks = [this.processTask];
      }
    });

    return true; // do not cancel the event
  }

  protected handleCancelNewEntryClick(): true {
    this.newEntryText = null;

    return true; // do not cancel the event
  }

  protected handleProcessTaskSelected(event: ProcessTaskSelectedEvent): void {
    ArrayUtils.pushUnique(this.selectedProcessTasks, event.detail.processTask);
  }

  protected handleProcessTaskDeselected(
    event: ProcessTaskDeselectedEvent
  ): void {
    ArrayUtils.remove(this.selectedProcessTasks, event.detail.processTask);
  }

  private async createChecklistEntries(): Promise<void> {
    const attachments = await this.getAttachments();

    for (const processTask of this.selectedProcessTasks) {
      void this.processTaskChecklistService.createChecklistEntryForProcessTask({
        processTask,
        text: this.newEntryText,
        attachments
      });
    }
  }

  private async getAttachments(): Promise<Array<ChecklistEntryAttachments>> {
    const newEntryMultiFileInput = this.newEntryMultiFileInput;
    assertNotNullOrUndefined(
      newEntryMultiFileInput,
      "can't ProcessTaskCommentInlineCreationWidget.waitForFilesBeingLoaded without newEntryMultiFileInput"
    );

    this.newEntryInlineCreationSection?.startPreloader();
    await newEntryMultiFileInput.selectedFilesLoadedAwaitable();

    this.newEntryInlineCreationSection?.stopPreloader();
    this.newEntryInlineCreationSection?.close();

    return (newEntryMultiFileInput.selectedFiles ?? [])
      .map((selectedFile) => {
        return {
          name: selectedFile.name,
          dataUrl: selectedFile.dataUrl
        };
      })
      .filter((file): file is ChecklistEntryAttachments => {
        return file.dataUrl != null;
      });
  }
}
