import { bindable, autoinject } from 'aurelia-framework';

import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskAppointmentToProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EditProcessTaskPositionDialog } from '../edit-process-task-position-dialog/edit-process-task-position-dialog';
import { Dialogs } from '../../classes/Dialogs';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event navigation-triggered
 */
@autoinject()
export class ProcessTaskAppointmentPositionsWidget {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * positions which are connected to the appointment
   * read-only!
   */
  @bindable()
  public relatedPositions: Array<ProcessTaskPosition> = [];

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  private isAttached: boolean = false;
  private availablePositions: Array<ProcessTaskPosition> = [];
  protected categorizedPositions: Array<PositionCategoryGroup> = [];
  private availablePositionRelations: Array<ProcessTaskAppointmentToProcessTaskPosition> =
    [];

  protected positionCategoryGroupIdGetter: (
    categoryGroup: PositionCategoryGroup
  ) => string | null = (p) => p.categoryId;

  protected positionCategoryItemCountGetter: (
    items: Array<PositionCategoryGroup>
  ) => number = (items) =>
    items.reduce((sum, group) => sum + group.positions.length, 0);

  constructor(
    private readonly domElement: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentPositionsWidget,
        propertyName: 'processTaskAppointment'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateAvailablePositions.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskAppointment.processConfigurationStepId',
      this.updateAvailablePositions.bind(this)
    );
    this.updateAvailablePositions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      this.updateAvailablePositionRelations.bind(this)
    );
    this.updateAvailablePositionRelations();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePositions();
      this.updateAvailablePositionRelations();
    }
  }

  private updateAvailablePositions(): void {
    // only let the user select the positions when it has selected a step (to enforce the user to select a step)
    if (
      this.processTaskAppointment &&
      this.processTaskAppointment.processConfigurationStepId
    ) {
      const positions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTaskAppointment.ownerProcessTaskId
        );
      this.availablePositions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(positions);
    } else {
      this.availablePositions = [];
    }

    this.updateCategorizedPositions();
    this.updateRelatedPositions();
  }

  private updateAvailablePositionRelations(): void {
    if (this.processTaskAppointment) {
      this.availablePositionRelations =
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
          this.processTaskAppointment.id
        );
    } else {
      this.availablePositionRelations = [];
    }

    this.updateRelatedPositions();
  }

  private updateCategorizedPositions(): void {
    this.categorizedPositions = ProcessTaskPositionUtils.categorizePositions(
      this.availablePositions
    );
  }

  private updateRelatedPositions(): void {
    this.relatedPositions = this.availablePositions.filter((position) => {
      return !!this.availablePositionRelations.find(
        (relation) => relation.processTaskPositionId === position.id
      );
    });
  }

  protected handleProcessConfigurationStepIdChanged(): void {
    if (this.processTaskAppointment) {
      this.entityManager.processTaskAppointmentRepository.update(
        this.processTaskAppointment
      );
    }
  }

  protected handleCheckedChanged(
    position: ProcessTaskPosition,
    event: CheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentPositionsWidget.handleCheckedChanged without a processTaskAppointment"
    );

    const relation = this.availablePositionRelations.find(
      (r) => r.processTaskPositionId === position.id
    );
    if (event.detail.checked) {
      if (!relation) {
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.create(
          {
            processTaskPositionId: position.id,
            processTaskAppointmentId: this.processTaskAppointment.id,
            ownerProcessTaskGroupId: position.ownerProcessTaskGroupId,
            ownerProcessTaskId: position.ownerProcessTaskId,
            ownerUserGroupId: position.ownerUserGroupId
          }
        );
      }
    } else {
      if (relation) {
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.delete(
          relation
        );
      }
    }
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.processTaskAppointment,
      property: null,
      displayNameAtLogTime: this.processTaskAppointment.name
    });
  }

  protected handleNavigationTriggered(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'navigation-triggered',
      detail: null,
      bubbles: true
    });
  }

  protected handleEditPositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void EditProcessTaskPositionDialog.open({
      position: position,
      onDialogClosed: () => {}
    });
  }

  protected handleDeletePositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void Dialogs.deleteEntityDialog(position).then(() => {
      this.entityManager.processTaskPositionRepository.delete(position);
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: position,
        displayNameAtLogTime: position.name
      });
    });
  }

  protected positionIsRelated(
    position: ProcessTaskPosition,
    relatedPositions: Array<ProcessTaskPosition>
  ): boolean {
    return relatedPositions.indexOf(position) >= 0;
  }
}
