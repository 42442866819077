import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskChecklistEntryDialogService } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/ProcessTaskChecklistEntryDialogService';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskChecklistEntry } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskInvoiceFinishService } from '../../classes/EntityManager/entities/ProcessTaskInvoice/ProcessTaskInvoiceFinishService';

@autoinject()
export class EditProcessTaskInvoiceDialog {
  public static async open(
    options: EditProcessTaskInvoiceDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;
  private processTaskInvoice: ProcessTaskInvoice | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private onDialogClosed: EditProcessTaskInvoiceDialogOnDialogClosed | null =
    null;
  private permissionBindingHandle: PermissionBindingHandle;
  private processTaskGroupIsEditable: boolean = false;
  private closedByNavigation: boolean = false;
  private relatedProcessTasks: Array<ProcessTask> = [];
  private notDoneChecklistEntries: Array<ProcessTaskWithChecklistEntries> = [];

  private dialog: RecordItDialog | null = null;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskChecklistEntryDialogService: ProcessTaskChecklistEntryDialogService,
    private readonly processTaskInvoiceFinishService: ProcessTaskInvoiceFinishService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.i18n = i18n;

    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();
    this.processTaskChecklistEntryDialogService =
      processTaskChecklistEntryDialogService;

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        editableProperty: 'processTaskGroupIsEditable',
        property: 'processTaskGroup',
        userGroupPropertyOfEntity: 'ownerUserGroupId'
      }
    });
  }

  public open(options: EditProcessTaskInvoiceDialogOptions): void {
    this.processTaskInvoice = options.processTaskInvoice;
    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        options.processTaskInvoice.ownerProcessTaskGroupId
      );
    this.onDialogClosed = options.onDialogClosed;
    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );
    this.updateRelatedProcessTasks();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskChecklistEntry,
      this.updateNotDoneChecklistEntries.bind(this)
    );

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const closedByNavigation = this.closedByNavigation;

    this.processTaskInvoice = null;
    this.onDialogClosed = null;
    this.closedByNavigation = false;
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();

    onClosed && onClosed(closedByNavigation);
  }

  private updateRelatedProcessTasks(): void {
    if (this.processTaskInvoice) {
      const relations =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
          this.processTaskInvoice.id
        );
      const processTaskIds = relations.map((r) => r.ownerProcessTaskId);
      this.relatedProcessTasks =
        this.entityManager.processTaskRepository.getByIds(processTaskIds);
    } else {
      this.relatedProcessTasks = [];
    }

    this.updateNotDoneChecklistEntries();
  }

  private updateNotDoneChecklistEntries(): void {
    const map =
      this.processTaskChecklistEntryDialogService.getNotDoneChecklistEntriesByProcessTasks(
        this.relatedProcessTasks
      );

    const entries: Array<ProcessTaskWithChecklistEntries> = [];

    for (const [processTask, checklistEntries] of map) {
      entries.push({ processTask, checklistEntries });
    }

    this.notDoneChecklistEntries = entries;
  }

  private handleProcessTaskInvoiceChanged(): void {
    if (this.processTaskInvoice) {
      this.entityManager.processTaskInvoiceRepository.update(
        this.processTaskInvoice
      );
    }
  }

  private handleNavigationTriggered(): void {
    if (this.dialog) {
      this.closedByNavigation = true;
      this.dialog.close();
    }
  }

  private async handleDoneButtonClick(): Promise<void> {
    const processTaskInvoice = this.processTaskInvoice;
    assertNotNullOrUndefined(
      processTaskInvoice,
      "can't handleDoneButtonClick without invoice"
    );
    await this.processTaskChecklistEntryDialogService.confirmProcessTasksChecklistEntries(
      this.relatedProcessTasks
    );
    await Dialogs.cancelDialogTk(
      'operations.editProcessTaskInvoiceDialog.setDoneWarningText'
    );
    this.processTaskInvoiceFinishService.finishInvoice(processTaskInvoice);
  }

  private handleRevertInvoiceButtonClick(): void {
    const processTaskInvoice = this.processTaskInvoice;
    if (
      processTaskInvoice &&
      processTaskInvoice.doneAt &&
      !processTaskInvoice.reverted
    ) {
      void Dialogs.cancelDialogTk(
        'operations.editProcessTaskInvoiceDialog.revertWarningText'
      ).then(() => {
        processTaskInvoice.reverted = true;
        this.handleProcessTaskInvoiceChanged();

        const formattedSequenceNumber =
          SequenceNumberUtils.formatOptionalSequenceNumber(
            SequenceNumberType.INVOICE,
            processTaskInvoice.globalSequenceNumber,
            ''
          );

        const revertedInvoice =
          this.entityManager.processTaskInvoiceRepository.create({
            ...processTaskInvoice,
            globalSequenceNumber: null,
            inverted: true,
            doneAt: Date.now().toString(),
            reverted: false,
            name: `${this.i18n.tr(
              'operations.editProcessTaskInvoiceDialog.invertedInvoiceOf'
            )} ${formattedSequenceNumber}`
          });

        this.copyProcessTasksToRevertedInvoice(revertedInvoice);
        this.copyPositionsToRevertedInvoice(revertedInvoice);
        this.copyDevicesToRevertedInvoice(revertedInvoice);
      });
    }
  }

  private copyProcessTasksToRevertedInvoice(
    revertedInvoice: ProcessTaskInvoice
  ): void {
    if (!this.processTaskInvoice) return;

    const invoiceToProcessTasks =
      this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
        this.processTaskInvoice.id
      );
    invoiceToProcessTasks.forEach((invoiceToProcessTask) => {
      this.entityManager.processTaskInvoiceToProcessTaskRepository.create({
        ...invoiceToProcessTask,
        processTaskInvoiceId: revertedInvoice.id
      });
    });
  }

  private copyPositionsToRevertedInvoice(
    revertedInvoice: ProcessTaskInvoice
  ): void {
    if (!this.processTaskInvoice) return;

    const invoiceToPositions =
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskInvoiceId(
        this.processTaskInvoice.id
      );
    invoiceToPositions.forEach((invoiceToPosition) => {
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.create(
        {
          ...invoiceToPosition,
          processTaskInvoiceId: revertedInvoice.id
        }
      );
    });
  }

  private copyDevicesToRevertedInvoice(
    revertedInvoice: ProcessTaskInvoice
  ): void {
    if (!this.processTaskInvoice) return;

    const invoiceToDevices =
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceId(
        this.processTaskInvoice.id
      );
    invoiceToDevices.forEach((invoiceToDevice) => {
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.create(
        {
          ...invoiceToDevice,
          processTaskInvoiceId: revertedInvoice.id
        }
      );
    });
  }
}

export type EditProcessTaskInvoiceDialogOptions = {
  processTaskInvoice: ProcessTaskInvoice;
  onDialogClosed: EditProcessTaskInvoiceDialogOnDialogClosed | null;
};

export type EditProcessTaskInvoiceDialogOnDialogClosed = (
  closedByNavigation: boolean
) => void;

type ProcessTaskWithChecklistEntries = {
  processTask: ProcessTask;
  checklistEntries: Array<ProcessTaskChecklistEntry>;
};
