import { assertNotNullOrUndefined } from 'common/Asserts';

import { Disposable } from '../Utils/DisposableContainer';
import {
  AbstractNfcHelperStrategy,
  UidScanCallback
} from './AbstractNfcHelperStrategy';

export class IOSNfcHelperStrategy extends AbstractNfcHelperStrategy {
  public scanSingleUID(callback: UidScanCallback): Disposable {
    void window.nfc
      ?.scanTag()
      .then((tag) => {
        const tagId = window.nfc?.bytesToHexString(tag.id);
        assertNotNullOrUndefined(tagId, 'could not get a nfc tag id');

        callback(null, tagId);
      })
      .catch((error) => {
        if (error !== 'Session invalidated by user') {
          throw error;
        }
      });

    // we can only scan once at a time and will use the newer
    // scanTag function which should also give NDEF information
    // if available (according to documentation).

    // void window.nfc.scanNdef().then((tag) => {
    //   callback(window.nfc?.bytesToHexString(tag.id));
    // }).catch((error) => {
    //   console.warn('NDEF ERROR:', error);
    // });

    return {
      dispose: () => {
        void window.nfc?.cancelScan();
      }
    };
  }
}
