import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingToPersonDto } from './ThingToPersonDto';

export function createThingToPersonEntityInfo<
  TEntity extends ThingToPersonDto<string, string>
>(
  options: CreateEntityInfoOptions<ThingToPersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingToPerson,
  TEntity
> {
  return {
    entityName: EntityName.ThingToPerson,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingToPersonDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        },
        mainContact: {
          name: 'mainContact',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
