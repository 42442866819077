import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { BaseEntityUtils } from '../../BaseEntities/BaseEntityUtils';
import { UserCompanySettingDto } from './UserCompanySettingDto';
import { ProcessTaskGroupFilterMode } from '../../../Enums/ProcessTaskGroupFilterMode';
import { ProcessTaskAppointmentCalendarWidgetMode } from '../../../Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';

export function createUserCompanySettingEntityInfo<
  TEntity extends UserCompanySettingDto<string, string>
>(
  options: CreateEntityInfoOptions<
    UserCompanySettingDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.UserCompanySetting,
  TEntity
> {
  return {
    entityName: EntityName.UserCompanySetting,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserCompanySettingDto<string, string>,
      TEntity
    >(
      {
        ...BaseEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        logo: {
          name: 'logo',
          innerInfo: {
            height: {
              name: 'height',
              defaultValue: null
            },
            svg: {
              name: 'svg',
              defaultValue: null
            },
            svgBaselineOffset: {
              name: 'svgBaselineOffset',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        general: {
          name: 'general',
          innerInfo: {
            bigSelectionSwitch: {
              name: 'bigSelectionSwitch',
              defaultValue: false
            },
            breadcrumbBackgroundColor: {
              name: 'breadcrumbBackgroundColor',
              defaultValue: null
            },
            canUploadPictures: {
              name: 'canUploadPictures',
              defaultValue: true
            },
            customTranslations: {
              name: 'customTranslations',
              defaultValue: null
            },
            editEntryInsteadOfRFM: {
              name: 'editEntryInsteadOfRFM',
              defaultValue: false
            },
            entityCreationUserGroupId: {
              name: 'entityCreationUserGroupId',
              defaultValue: null
            },
            personCategories: {
              name: 'personCategories',
              defaultValue: null
            },
            preferredOpenMethodInApps: {
              name: 'preferredOpenMethodInApps',
              defaultValue: null
            },
            primaryColor: {
              name: 'primaryColor',
              defaultValue: null
            },
            primaryLightColor: {
              name: 'primaryLightColor',
              defaultValue: null
            },
            automaticProjectCreationConfigs: {
              name: 'automaticProjectCreationConfigs',
              innerInfo: [
                {
                  reportTemplateId: {
                    name: 'reportTemplateId'
                  }
                }
              ],
              defaultValue: () => []
            },
            secondaryColor: {
              name: 'secondaryColor',
              defaultValue: null
            },
            showModuleName: {
              name: 'showModuleName',
              defaultValue: true
            },
            structurePictureAreas: {
              name: 'structurePictureAreas',
              defaultValue: false
            },
            tagSortingMode: {
              name: 'tagSortingMode',
              defaultValue: null
            },
            thingGroupConfigurationJson: {
              name: 'thingGroupConfigurationJson',
              defaultValue: null
            },
            thingGroupOwnerPersonCategory: {
              name: 'thingGroupOwnerPersonCategory',
              defaultValue: null
            },
            thingSections: {
              name: 'thingSections',
              defaultValue: false
            },
            useThingGroupMap: {
              name: 'useThingGroupMap',
              defaultValue: false
            },
            showPictureUploadAndTakenDateExplicitly: {
              name: 'showPictureUploadAndTakenDateExplicitly',
              defaultValue: null
            },
            personListItemFirstColumnContent: {
              name: 'personListItemFirstColumnContent',
              defaultValue: null
            },
            useKfvPukProjectNameGeneration: {
              name: 'useKfvPukProjectNameGeneration',
              defaultValue: false
            },
            canUsePictureRevision: {
              name: 'canUsePictureRevision',
              defaultValue: false
            },
            standardBaseTileMapLayer: {
              name: 'standardBaseTileMapLayer',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        homePage: {
          name: 'homePage',
          innerInfo: {
            initialNumberOfProjectsShown: {
              name: 'initialNumberOfProjectsShown',
              defaultValue: null
            },
            initialNumberOfThingsShown: {
              name: 'initialNumberOfThingsShown',
              defaultValue: null
            },
            totalNumberOfProjectsShown: {
              name: 'totalNumberOfProjectsShown',
              defaultValue: null
            },
            totalNumberOfThingsShown: {
              name: 'totalNumberOfThingsShown',
              defaultValue: null
            },
            usesLegacyTileHomePage: {
              name: 'usesLegacyTileHomePage',
              defaultValue: false
            },
            showKukDefectShortcut: {
              name: 'showKukDefectShortcut',
              defaultValue: false
            }
          },
          defaultValue: () => ({})
        },
        mainMenu: {
          name: 'mainMenu',
          innerInfo: {
            hideEditUserGroups: {
              name: 'hideEditUserGroups',
              defaultValue: null
            },
            hideGeneralSectionHeader: {
              name: 'hideGeneralSectionHeader',
              defaultValue: null
            },
            hideGlobalSearch: {
              name: 'hideGlobalSearch',
              defaultValue: null
            },
            hideMap: {
              name: 'hideMap',
              defaultValue: null
            },
            hideProcessConfiguration: {
              name: 'hideProcessConfiguration',
              defaultValue: null
            },
            hideReportTypes: {
              name: 'hideReportTypes',
              defaultValue: null
            },
            hideThingGroups: {
              name: 'hideThingGroups',
              defaultValue: null
            },
            hideThingTypes: {
              name: 'hideThingTypes',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        operations: {
          name: 'operations',
          innerInfo: {
            appointmentColors: {
              name: 'appointmentColors',
              defaultValue: null
            },
            appointmentDeviceCreation: {
              name: 'appointmentDeviceCreation',
              defaultValue: null
            },
            appointmentFinishWithNfcTag: {
              name: 'appointmentFinishWithNfcTag',
              defaultValue: null
            },
            appointmentGeneralInfoEditing: {
              name: 'appointmentGeneralInfoEditing',
              defaultValue: null
            },
            appointmentHideSignatureField: {
              name: 'appointmentHideSignatureField',
              defaultValue: null
            },
            appointmentMeasurePointManaging: {
              name: 'appointmentMeasurePointManaging',
              defaultValue: null
            },
            appointmentOpenWithNfcTag: {
              name: 'appointmentOpenWithNfcTag',
              defaultValue: null
            },
            appointmentPositionCreation: {
              name: 'appointmentPositionCreation',
              defaultValue: null
            },
            calendarEndHour: {
              name: 'calendarEndHour',
              defaultValue: 23
            },
            calendarStartHour: {
              name: 'calendarStartHour',
              defaultValue: 6
            },
            defaultEditProcessTaskGroupFilterMode: {
              name: 'defaultEditProcessTaskGroupFilterMode',
              defaultValue: ProcessTaskGroupFilterMode.NEXT_DAYS
            },
            defaultProcessTaskAppointmentCalendarWidgetMode: {
              name: 'defaultProcessTaskAppointmentCalendarWidgetMode',
              defaultValue: ProcessTaskAppointmentCalendarWidgetMode.WEEK
            },
            defaultShowDoneChecklistEntries: {
              name: 'defaultShowDoneChecklistEntries',
              defaultValue: false
            },
            processTaskAppointmentCalendarWidgetYearModeEnabled: {
              name: 'processTaskAppointmentCalendarWidgetYearModeEnabled',
              defaultValue: false
            },
            publicHolidayBackgroundColor: {
              name: 'publicHolidayBackgroundColor',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        operationsFieldUse: {
          name: 'operationsFieldUse',
          innerInfo: {
            allowCustomSelectionOfCalendarWidgetMode: {
              name: 'allowCustomSelectionOfCalendarWidgetMode',
              defaultValue: null
            },
            defaultProcessTaskAppointmentCalendarWidgetMode: {
              name: 'defaultProcessTaskAppointmentCalendarWidgetMode',
              defaultValue: null
            },
            scrollToFirstAppointmentInDayCalendarWidgetMode: {
              name: 'scrollToFirstAppointmentInDayCalendarWidgetMode',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        ultraRapidFireWidget: {
          name: 'ultraRapidFireWidget',
          innerInfo: {
            overlayConfiguration: {
              name: 'overlayConfiguration',
              defaultValue: null
            },
            useCameraOverlay: {
              name: 'useCameraOverlay',
              defaultValue: true
            },
            useUltraRapidFireWidget: {
              name: 'useUltraRapidFireWidget',
              defaultValue: false
            },
            highlightedPictureBorderColor: {
              name: 'highlightedPictureBorderColor',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        defectManagement: {
          name: 'defectManagement',
          innerInfo: {
            pictureBorderColor: {
              name: 'pictureBorderColor',
              defaultValue: null
            },
            predefinedProperties: {
              name: 'predefinedProperties',
              defaultValue: null
            },
            propertyFilterBarConfiguration: {
              name: 'propertyFilterBarConfiguration',
              defaultValue: null
            },
            dailyNotificationForAssignedUsers: {
              name: 'dailyNotificationForAssignedUsers',
              defaultValue: false
            },
            periodicalNotificationForAssignedUsers: {
              name: 'periodicalNotificationForAssignedUsers',
              defaultValue: false
            },
            notificationsForOpenDefectsOnly: {
              name: 'notificationsForOpenDefectsOnly',
              defaultValue: false
            },
            defaultAssignedUserEmail: {
              name: 'defaultAssignedUserEmail',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        structureProject: {
          name: 'structureProject',
          innerInfo: {
            rapidFireModeConfigurationJson: {
              name: 'rapidFireModeConfigurationJson',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        via: {
          name: 'via',
          innerInfo: {
            bulkEditConfiguration: {
              name: 'bulkEditConfiguration',
              defaultValue: null
            },
            filterBarConfiguration: {
              name: 'filterBarConfiguration',
              defaultValue: null
            },
            groupByRegion: {
              name: 'groupByRegion',
              defaultValue: false
            },
            pictureIconsConfiguration: {
              name: 'pictureIconsConfiguration',
              defaultValue: null
            },
            predefinedEntryProperties: {
              name: 'predefinedEntryProperties',
              defaultValue: null
            },
            predefinedPropertiesConfiguration: {
              name: 'predefinedPropertiesConfiguration',
              defaultValue: null
            },
            predefinedRegionsConfiguration: {
              name: 'predefinedRegionsConfiguration',
              defaultValue: null
            },
            regionDescriptionPerProject: {
              name: 'regionDescriptionPerProject',
              defaultValue: false
            },
            shpExportConfig: {
              name: 'shpExportConfig',
              defaultValue: null
            },
            tagCategoryConfiguration: {
              name: 'tagCategoryConfiguration',
              defaultValue: null
            },
            tagMatchMode: {
              name: 'tagMatchMode',
              defaultValue: null
            },
            thingStatusConfiguration: {
              name: 'thingStatusConfiguration',
              defaultValue: null
            },
            useCategorizedTags: {
              name: 'useCategorizedTags',
              defaultValue: false
            },
            useStandardFilterBar: {
              name: 'useStandardFilterBar',
              defaultValue: true
            },
            automaticallyMarkPicturesOnThingPicture: {
              name: 'automaticallyMarkPicturesOnThingPicture',
              defaultValue: false
            },
            usePlanOverview: {
              name: 'usePlanOverview',
              defaultValue: false
            },
            copyPicturesForNewDefect: {
              name: 'copyPicturesForNewDefect',
              defaultValue: false
            }
          },
          defaultValue: () => ({})
        },
        externalSynchronization: {
          name: 'externalSynchronization',
          innerInfo: {
            sharepointSynchronizationOfThings: {
              name: 'sharepointSynchronizationOfThings',
              defaultValue: false
            }
          },
          defaultValue: () => ({})
        },
        kuk: {
          name: 'kuk',
          innerInfo: {
            questionCatalogueId: {
              name: 'questionCatalogueId',
              defaultValue: null
            },
            initialNumberOfDefectsShown: {
              name: 'initialNumberOfDefectsShown',
              defaultValue: null
            },
            totalNumberOfDefectsShown: {
              name: 'totalNumberOfDefectsShown',
              defaultValue: null
            },
            initialNumberOfProjectsShown: {
              name: 'initialNumberOfProjectsShown',
              defaultValue: null
            },
            totalNumberOfProjectsShown: {
              name: 'totalNumberOfProjectsShown',
              defaultValue: null
            },
            reminders: {
              name: 'reminders',
              innerInfo: [
                {
                  text: {
                    name: 'text'
                  },
                  propertyName: {
                    name: 'propertyName'
                  },
                  propertyValue: {
                    name: 'propertyValue'
                  }
                }
              ],
              defaultValue: () => []
            }
          },
          defaultValue: () => ({})
        },
        pdfSigning: {
          name: 'pdfSigning',
          defaultValue: () => ({}),
          innerInfo: {
            disableExportOfProjectsWithSignedReports: {
              name: 'disableExportOfProjectsWithSignedReports',
              defaultValue: false
            },
            limitToOneSignedReport: {
              name: 'limitToOneSignedReport',
              defaultValue: false
            },
            canConfigureSignatureAutoProgress: {
              name: 'canConfigureSignatureAutoProgress',
              defaultValue: false
            }
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
