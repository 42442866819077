import { autoinject } from 'aurelia-framework';

/**
 * Syncs categorized tags between URFW modes.
 *
 * When tags are selected while in the Via URFM, these tags
 * should also be selected when switching to the URFM Defect (and back).
 * This responsibility is handled here.
 */
@autoinject()
export class CategorizedTagSyncService {
  private tagIds: Array<string> = [];

  private onChangeCallbacks: Map<Symbol, OnChangeCallback> = new Map();

  /**
   * Add a new tagId.
   */
  public add(tagId: string): void {
    this.tagIds.push(tagId);
    this.triggerChangeCallbacks();
  }

  /**
   * Remove a tagId.
   */
  public remove(tagId: string): void {
    this.tagIds = this.tagIds.filter((t) => t !== tagId);
    this.triggerChangeCallbacks();
  }

  /**
   * Update the whole tagIds array.
   */
  public set(tagIds: Array<string>): void {
    this.tagIds = tagIds;
    this.triggerChangeCallbacks();
  }

  /**
   * Reset to the initial state.
   */
  public reset(): void {
    this.tagIds = [];
    this.triggerChangeCallbacks();
  }

  /**
   * Add a change callback, which will be called when the tagIds change.
   *
   * @returns A function, which you can call if you want to stop listening to changes.
   */
  public onChange(cb: OnChangeCallback): () => void {
    const key = Symbol();
    this.onChangeCallbacks.set(key, cb);
    cb(this.tagIds);
    return () => {
      this.onChangeCallbacks.delete(key);
    };
  }

  /**
   * Returns the tagIds.
   */
  public getTags(): Array<string> {
    return [...this.tagIds];
  }

  private triggerChangeCallbacks(): void {
    this.onChangeCallbacks.forEach((c) => c(this.tagIds));
  }
}

export type OnChangeCallback = (tagIds: Array<string>) => void;
