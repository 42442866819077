import { autoinject } from 'aurelia-framework';

import { ProcessConfigurationDevicePropertiesConfiguration } from 'common/Types/ProcessConfigurationDevicePropertiesConfiguration';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap
} from '../../computedValues/computers/ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PropertyHelper } from '../../../../common/src/EntityHelper/PropertyHelper';

@autoinject()
export class CreateProcessTaskDeviceDialog {
  public static async open(
    options: CreateProcessTaskDeviceDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  private processTask: ProcessTask | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private onDialogClosed: OnDialogClosed | null = null;
  private configurationDevices: Array<ProcessConfigurationDevice> = [];
  private lastCreatedDevice: ProcessTaskDevice | null = null;
  private dialog: RecordItDialog | null = null;
  private deviceExportsByProcessTaskGroupId: ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: CreateProcessTaskDeviceDialogOpenOptions): void {
    this.processTask = options.processTask;
    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.processTask.ownerProcessTaskGroupId
      );
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDevice,
      this.updateConfigurationDevices.bind(this)
    );
    this.updateConfigurationDevices();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
        computeData: {},
        callback: (deviceExportsByProcessTaskGroupId) => {
          this.deviceExportsByProcessTaskGroupId =
            deviceExportsByProcessTaskGroupId;
        }
      })
    );

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const device = this.lastCreatedDevice;

    this.processTask = null;
    this.onDialogClosed = null;
    this.lastCreatedDevice = null;
    this.subscriptionManager.disposeSubscriptions();

    onClosed && onClosed(device);
  }

  private updateConfigurationDevices(): void {
    if (this.processTaskGroup) {
      let configs =
        this.entityManager.processConfigurationDeviceRepository.getByProcessConfigurationIdAndPersonId(
          this.processTaskGroup.processConfigurationId,
          this.processTaskGroup.thingGroupOwnerPersonId
        );

      if (configs.length === 0) {
        configs =
          this.entityManager.processConfigurationDeviceRepository.getByProcessConfigurationIdAndPersonId(
            this.processTaskGroup.processConfigurationId,
            null
          );
      }

      this.configurationDevices = configs;
    } else {
      this.configurationDevices = [];
    }
  }

  private handleConfigurationDeviceClick(
    configurationDevice: ProcessConfigurationDevice
  ): void {
    if (!this.processTask) {
      return;
    }

    this.lastCreatedDevice =
      this.entityManager.processTaskDeviceRepository.create({
        processConfigurationDeviceId: configurationDevice.id,
        processConfigurationDeviceExportId:
          this.getDefaultProcessConfigurationDeviceExportId(
            this.processTask.ownerProcessTaskGroupId
          ),
        dailyCost: configurationDevice.dailyCost,
        plannedDuration: configurationDevice.plannedDuration,
        discount: configurationDevice.discount,
        discountNote: configurationDevice.discountNote,
        ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: this.processTask.id,
        ownerUserGroupId: this.processTask.ownerUserGroupId,
        temporaryGroupName: this.processTask.temporaryGroupName
      });

    this.createDefaultProperties(
      this.lastCreatedDevice,
      configurationDevice.id
    );
    void this.processTaskLoggingService.logProcessTaskSubEntityCreated({
      entityName: EntityName.ProcessTaskDevice,
      entity: this.lastCreatedDevice,
      displayNameAtLogTime: null
    });

    if (this.dialog) {
      this.dialog.close();
    }
  }

  private createDefaultProperties(
    device: ProcessTaskDevice,
    configurationDeviceId: string
  ): void {
    const configurationProperties =
      this.entityManager.propertyRepository.getByProcessConfigurationDeviceId(
        configurationDeviceId
      );
    const processConfiguration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;
    const config: ProcessConfigurationDevicePropertiesConfiguration | null =
      processConfiguration &&
      processConfiguration.devicePropertiesConfigurationJson
        ? JSON.parse(processConfiguration.devicePropertiesConfigurationJson)
        : null;
    const defaultPropertyConfigs = config ? config.defaultProperties : [];

    for (const [order, propertyConfig] of defaultPropertyConfigs.entries()) {
      const creationProperty: PropertyCreationBaseData = {
        value: '',
        ...propertyConfig
      };

      const configProperty = configurationProperties.find(
        (cp) => cp.name === propertyConfig.name
      );

      if (configProperty) {
        Object.assign(
          creationProperty,
          PropertyHelper.extractValueDataFromProperty(configProperty)
        );
      }

      this.entityManager.propertyRepository.create({
        ...creationProperty,
        order,
        processTaskDeviceId: device.id,
        ownerUserGroupId: device.ownerUserGroupId,
        ownerProcessTaskGroupId: device.ownerProcessTaskGroupId,
        ownerProcessTaskId: device.ownerProcessTaskId
      });
    }
  }

  private getDefaultProcessConfigurationDeviceExportId(
    processTaskGroupId: string
  ): string | null {
    const configs =
      this.deviceExportsByProcessTaskGroupId.get(processTaskGroupId) ?? [];
    const defaultConfig = configs.find((c) => c.defaultForNewDevices);
    return defaultConfig?.id ?? null;
  }
}

export type CreateProcessTaskDeviceDialogOpenOptions = {
  processTask: ProcessTask;
  onDialogClosed?: OnDialogClosed;
};

export type OnDialogClosed = (createdDevice: ProcessTaskDevice | null) => void;
