import { Entry } from './types';

export class EntryUtils {
  private constructor() {}

  public static getPageDepthIndex(path: Array<Entry>): string {
    const listPositions = [...path]
      .reverse()
      .map((entry) => entry.list_position + 1);
    return listPositions.join('.') + '.';
  }
}
