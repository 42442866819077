import { BaseMapMarkerColor } from '../Types/BaseMapMarker';
import { PropertyType } from '../Types/Entities/Property/PropertyDto';
import { CommonGalleryThingPictureOverviewEntry } from '../Types/GalleryThingPictureFilter/GalleryThingPictureOverviewEntry';
import { ValueContainer } from '../ValueContainer';

export class GalleryThingBaseMapMarkerHelper {
  public static getMarkerColor(
    getData: GetBaseMapMarkerData,
    overviewEntry: CommonGalleryThingPictureOverviewEntry<unknown>
  ): BaseMapMarkerColor | null {
    if (!overviewEntry.entryId) return null;
    const priority = getData.getEntryPropertyValue({
      entryId: overviewEntry.entryId,
      propertyName: 'Priorität',
      propertyType: PropertyType.PRIORITY
    });

    if (priority === null) return null;
    return this.getColorForPriority(priority.value);
  }

  public static getColorForPriority(
    color: string | null
  ): BaseMapMarkerColor | null {
    switch (color) {
      case 'Hoch':
        return BaseMapMarkerColor.RED;
      case 'Mittel':
        return BaseMapMarkerColor.YELLOW;
      case 'Niedrig':
        return BaseMapMarkerColor.GREEN;
      default:
        return null;
    }
  }
}

type GetBaseMapMarkerData = {
  getEntryPropertyValue({
    entryId,
    propertyType,
    propertyName
  }: {
    entryId: string;
    propertyType: PropertyType | null;
    propertyName: string | null;
  }): ValueContainer<null | string> | null;
};
