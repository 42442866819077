export class FileTypeUtils {
  public static getFileType(file: File): FileTypes | null {
    return (
      (file.type as FileTypes | null) ||
      FileTypeUtils.getFileTypeFromExtension(file)
    );
  }

  private static getFileTypeFromExtension(file: File): FileTypes | null {
    const match = file.name.match(/\.(\w+)$/);
    if (!match) return null;

    const extension = match[1];
    if (extension) {
      const type = Object.values(FileTypes).find((t) =>
        t.includes(extension.toLowerCase())
      );
      if (type) return type;
    }

    return null;
  }
}

export enum FileTypes {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  DXF = 'image/vnd.dxf'
}
