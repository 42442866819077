import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { EditQuestionSetDialog } from '../../dialogs/edit-question-set-dialog/edit-question-set-dialog';
import { QuestionSetService } from '../../classes/EntityManager/entities/QuestionSet/QuestionSetService';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { Router } from 'aurelia-router';

@autoinject()
export class EditChecklistQuestionSets {
  private questionCatalogueId: string | null = null;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly questionSetService: QuestionSetService,
    private readonly router: Router
  ) {}

  protected activate(params: { question_catalogue_id?: string }): void {
    this.questionCatalogueId = params.question_catalogue_id ?? null;
  }

  @computed()
  protected get hrefToAllQuestionSets(): string {
    return this.router.generate('edit_checklist_question_sets', {
      question_catalogue_id: null
    });
  }

  @computed(
    expression('questionCatalogueId'),
    model(EntityName.QuestionCatalogue)
  )
  protected get questionCatalogue(): QuestionCatalogue | null {
    if (!this.questionCatalogueId) return null;

    return this.entityManager.questionCatalogueRepository.getById(
      this.questionCatalogueId
    );
  }

  @computed(
    expression('questionCatalogue'),
    model(EntityName.QuestionSet),
    model(EntityName.QuestionCatalogueToQuestionSet)
  )
  protected get questionSets(): Array<QuestionSet> {
    // If no question catalogue is given as a parameter, we should display ALL question sets
    if (!this.questionCatalogue)
      return this.entityManager.questionSetRepository.getAll();

    return this.questionSetService.getByQuestionCatalogueId(
      this.questionCatalogue.id
    );
  }

  protected handleCreateSetButtonClicked(
    event: CreateEntityClickedEvent
  ): void {
    const ownerUserGroupId = event.detail.userGroup?.id;
    if (ownerUserGroupId) {
      const questionSet = this.questionSetService.create(
        { ownerUserGroupId },
        this.questionCatalogueId
      );
      void EditQuestionSetDialog.open({
        questionSet
      });
    }
  }
}
