import { autoinject } from 'aurelia-framework';
import { ProcessConfigurationDeviceBillingMethod } from 'common/Enums/ProcessConfigurationDeviceBillingMethod';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { EditProcessConfigurationDeviceDialog } from '../../operationsComponents/edit-process-configuration-device-dialog/edit-process-configuration-device-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  ProcessConfigurationDeviceSortOption,
  ProcessConfigurationDeviceUtils
} from '../../classes/EntityManager/entities/ProcessConfigurationDevice/ProcessConfigurationDeviceUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { ProcessConfigurationDeviceFilter } from '../../operationsComponents/process-configuration-device-filter/process-configuration-device-filter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class edit_process_configuration_devices {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  protected readonly sortOptions = ProcessConfigurationDeviceUtils.sortOptions;
  protected currentSortOption: ProcessConfigurationDeviceSortOption =
    ProcessConfigurationDeviceUtils.sortOptions.name;

  private processConfiguration: ProcessConfiguration | null = null;

  protected configurationDevices: Array<ProcessConfigurationDevice> = [];

  protected sortedConfigurationDevices: Array<ProcessConfigurationDevice> = [];

  protected filteredConfigurationDevices: Array<ProcessConfigurationDevice> =
    [];

  protected currentPageConfigurationDevices: Array<ProcessConfigurationDevice> =
    [];

  protected isMobile: boolean = false;

  private isAttached: boolean = false;
  private filteredPersonId: string | null = null;

  private pagination: Pagination<ProcessConfigurationDevice> | null = null;
  private processConfigurationDeviceFilter: ProcessConfigurationDeviceFilter | null =
    null;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  protected activate(params: { process_configuration_id: string }): void {
    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        params.process_configuration_id
      );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDevice,
      this.updateConfigurationDevices.bind(this)
    );
    this.updateConfigurationDevices();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private updateConfigurationDevices(): void {
    if (this.processConfiguration) {
      this.configurationDevices =
        this.entityManager.processConfigurationDeviceRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.configurationDevices = [];
    }
  }

  protected handleCreateDeviceClicked(): void {
    if (!this.processConfiguration) {
      return;
    }

    const device =
      this.entityManager.processConfigurationDeviceRepository.create({
        billingMethod: ProcessConfigurationDeviceBillingMethod.DAILY,
        personId: this.filteredPersonId,
        ownerProcessConfigurationId: this.processConfiguration.id,
        ownerUserGroupId: this.processConfiguration.ownerUserGroupId
      });

    this.editDevice(device);
  }

  protected handleEditDeviceClicked(device: ProcessConfigurationDevice): void {
    this.editDevice(device);
  }

  private editDevice(device: ProcessConfigurationDevice): void {
    void EditProcessConfigurationDeviceDialog.open({
      processConfigurationDevice: device,
      onDialogClosed: () => {
        this.goToDevice(device);
      }
    });
  }

  private goToDevice(device: ProcessConfigurationDevice): void {
    if (this.processConfigurationDeviceFilter) {
      this.processConfigurationDeviceFilter.setFilterToIncludeConfigurationDevice(
        device
      );
    }

    void ScrollHelper.autoScrollToListItem(
      '#' + this.getConfigurationDeviceListItemId(device.id),
      this.pagination,
      device,
      () => this.isAttached
    );
  }

  private getConfigurationDeviceListItemId(deviceId: string): string {
    return 'edit-process-configuration-devices--device' + deviceId;
  }
}
