export class NumberUtils {
  public static sumNumbersWithMaxPrecision(...numbers: Array<number>): number {
    const sumOfNumbers = numbers.reduce((sum, number) => {
      return sum + number;
    }, 0);
    const precision = this.getMaxPrecisionOfNumbers(...numbers);

    return this.setPrecisionOfNumber(sumOfNumbers, precision);
  }

  public static setPrecisionOfNumber(
    number: number,
    precision: number
  ): number {
    return Number(number.toFixed(precision));
  }

  public static getPrecisionOfNumber(number: number): number {
    return number.toString().split('.')[1]?.length ?? 0;
  }

  public static isInRange({
    value,
    max,
    min,
    compareMode
  }: {
    value: number;
    max: number | null | undefined;
    min: number | null | undefined;
    compareMode?: RangeMatchMode;
  }): boolean {
    const safeMax = max ?? Infinity;
    const safeMin = min ?? -Infinity;
    switch (compareMode) {
      case RangeMatchMode.EXCLUDE_BOUNDARIES:
        return value > safeMin && value < safeMax;
      case RangeMatchMode.INCLUDE_BOUNDARIES:
      default:
        return value >= safeMin && value <= safeMax;
    }
  }

  public static clamp({
    value,
    max,
    min
  }: {
    value: number;
    max: number;
    min: number;
  }): number {
    return Math.min(Math.max(value, min), max);
  }

  private static getMaxPrecisionOfNumbers(...numbers: Array<number>): number {
    if (!numbers.length) return 0;
    return Math.max(
      ...numbers.map((number) => this.getPrecisionOfNumber(number))
    );
  }
}

export enum RangeMatchMode {
  INCLUDE_BOUNDARIES = 0,
  EXCLUDE_BOUNDARIES = 1
}
