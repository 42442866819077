import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { ValueWithLabel } from '../../types/ValueWithLabel';
import {
  allMonths,
  Month,
  toNumber as monthToNumber
} from 'common/Enums/Month';
import { allWeekdays, Weekday } from 'common/Enums/Weekday';
import { DateUtils } from 'common/DateUtils';
import { SelectedWeekdaysChangedEvent } from '../../inputComponents/weekday-input/weekday-input';
import { Recurrence } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import {
  allRecurrenceFrequencies,
  RecurrenceFrequency
} from 'common/Types/Entities/ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentDto';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * shows ui elements for modifying recurrence.
 *
 * @event recurrence-changed triggered whenever the user modifies the recurrence.
 */
@autoinject()
export class RecurrenceInput {
  @bindable public recurrence: Recurrence | null = null;

  @bindable public enabled: boolean = false;

  protected RecurrenceFrequency = RecurrenceFrequency;
  protected YearlyRecurrenceMethod = YearlyRecurrenceMethod;
  protected MonthlyRecurrenceMethod = MonthlyRecurrenceMethod;
  protected EndOption = EndOption;

  protected frequency: RecurrenceFrequency | null = null;
  protected yearlyMethod: YearlyRecurrenceMethod =
    YearlyRecurrenceMethod.ON_DAY_IN_MONTH;

  protected monthlyMethod: MonthlyRecurrenceMethod =
    MonthlyRecurrenceMethod.ON_DAY;

  protected endOption: EndOption | null = null;

  protected interval: string = '1';
  protected byMonth: Month = Month.JAN;
  protected byMonthday: number = 1;
  protected pos: number = 1;
  protected byWeekday: Weekday = Weekday.MO;
  protected count: string = '1';
  protected until: string | null = null;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private getRecurrence(): Recurrence | null {
    if (this.frequency === null) {
      return null;
    } else {
      switch (this.frequency) {
        case RecurrenceFrequency.YEARLY:
          return this.getRecurrenceForYearly();
        case RecurrenceFrequency.MONTHLY:
          return this.getRecurrenceForMonthly();
        case RecurrenceFrequency.WEEKLY:
          return this.getRecurrenceForWeekly();
        case RecurrenceFrequency.DAILY:
          return this.getRecurrenceForDaily();
        default:
          return this.getUnconfiguredRecurrence({
            frequency: this.frequency
          });
      }
    }
  }

  private getRecurrenceForYearly(): Recurrence {
    switch (this.yearlyMethod) {
      case YearlyRecurrenceMethod.ON_DAY_IN_MONTH:
        return {
          ...this.getUnconfiguredRecurrence({
            frequency: RecurrenceFrequency.YEARLY
          }),
          byMonthDays: [this.byMonthday],
          byMonths: [this.byMonth],
          ...this.getEnd()
        };
      case YearlyRecurrenceMethod.ON_WEEKDAY_IN_WEEK_IN_MONTH:
        return {
          ...this.getUnconfiguredRecurrence({
            frequency: RecurrenceFrequency.YEARLY
          }),
          pos: this.pos,
          byDays: [this.byWeekday],
          byMonths: [this.byMonth],
          ...this.getEnd()
        };
      default:
        throw new Error('Yearly recurrence method not handled.');
    }
  }

  private getRecurrenceForMonthly(): Recurrence {
    switch (this.monthlyMethod) {
      case MonthlyRecurrenceMethod.ON_DAY:
        return {
          ...this.getUnconfiguredRecurrence({
            frequency: RecurrenceFrequency.MONTHLY
          }),
          byMonthDays: [this.byMonthday],
          interval: this.getValidInterval(this.interval),
          ...this.getEnd()
        };
      case MonthlyRecurrenceMethod.ON_POS_WEEKDAY:
        return {
          ...this.getUnconfiguredRecurrence({
            frequency: RecurrenceFrequency.MONTHLY
          }),
          pos: this.pos,
          byDays: [this.byWeekday],
          interval: this.getValidInterval(this.interval),
          ...this.getEnd()
        };
      default:
        throw new Error('Monthly recurrence method not handled.');
    }
  }

  private getRecurrenceForWeekly(): Recurrence {
    return {
      ...this.getUnconfiguredRecurrence({
        frequency: RecurrenceFrequency.WEEKLY
      }),
      byDays: [this.byWeekday],
      interval: this.getValidInterval(this.interval),
      ...this.getEnd()
    };
  }

  private getRecurrenceForDaily(): Recurrence {
    return {
      ...this.getUnconfiguredRecurrence({
        frequency: RecurrenceFrequency.DAILY
      }),
      interval: this.getValidInterval(this.interval),
      ...this.getEnd()
    };
  }

  private getValidInterval(num: string): number {
    const parsedNum = Number.parseInt(num);
    if (Number.isNaN(parsedNum)) {
      return 1;
    }
    if (parsedNum <= 0) {
      return 1;
    }
    return parsedNum;
  }

  private getEnd(): Partial<Recurrence> {
    switch (this.endOption) {
      case EndOption.AFTER_N_OCCURRENCES:
        return { count: this.getValidInterval(this.count) };
      case EndOption.UNTIL_DAY:
        return { until: this.until ?? undefined };
      default:
        return {};
    }
  }

  protected recurrenceChanged(): void {
    if (this.recurrence) {
      const recurrence = this.recurrence;
      this.frequency = recurrence.frequency ?? null;
      this.yearlyMethod = recurrence.pos
        ? YearlyRecurrenceMethod.ON_WEEKDAY_IN_WEEK_IN_MONTH
        : YearlyRecurrenceMethod.ON_DAY_IN_MONTH;
      this.monthlyMethod = recurrence.pos
        ? MonthlyRecurrenceMethod.ON_POS_WEEKDAY
        : MonthlyRecurrenceMethod.ON_DAY;
      this.interval = recurrence.interval?.toString() ?? '1';
      this.byMonth = recurrence.byMonths?.at(0) ?? Month.JAN;
      this.byMonthday = recurrence.byMonthDays?.at(0) ?? 1;
      this.pos = recurrence.pos ?? 1;
      this.byWeekday = recurrence.byDays?.at(0) ?? Weekday.MO;
      if (recurrence.until) {
        this.endOption = EndOption.UNTIL_DAY;
      } else if (recurrence.count) {
        this.endOption = EndOption.AFTER_N_OCCURRENCES;
      }
      this.count = recurrence.count?.toString() ?? '1';
      this.until = recurrence.until ?? null;
    } else {
      this.frequency = null;
    }
  }

  protected handleRecurrenceChanged(): void {
    this.recurrence = this.getRecurrence();
    setTimeout(() => {
      DomEventHelper.fireEvent<RecurrenceChangedEvent>(this.element, {
        name: 'recurrence-changed',
        detail: {
          recurrence: this.recurrence
        }
      });
    });
  }

  protected handleRecurrenceYearlyMethodChanged(
    method: YearlyRecurrenceMethod
  ): void {
    this.yearlyMethod = method;
    this.handleRecurrenceChanged();
  }

  protected handleRecurrenceMonthlyMethodChanged(
    method: MonthlyRecurrenceMethod
  ): void {
    this.monthlyMethod = method;
    this.handleRecurrenceChanged();
  }

  protected handleWeekdaysChanged(evt: SelectedWeekdaysChangedEvent): void {
    const weekdays = evt.detail.weekdays;
    if (weekdays.length === 1) {
      this.byWeekday = weekdays[0]!;
      this.handleRecurrenceChanged();
    }
  }

  private getUnconfiguredRecurrence({
    frequency
  }: {
    frequency: RecurrenceFrequency;
  }): Recurrence {
    return {
      frequency,
      byDays: [],
      byMonthDays: [],
      byMonths: [],
      count: null,
      interval: null,
      pos: null,
      until: null
    };
  }

  @computedFrom()
  protected get allRecurrenceFrequencies(): Array<
    ValueWithLabel<RecurrenceFrequency>
  > {
    return allRecurrenceFrequencies().map((f) => ({
      label: `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.frequency.${f}`,
      value: f
    }));
  }

  @computedFrom()
  protected get allMonths(): Array<ValueWithLabel<Month>> {
    return allMonths().map((m) => ({
      label: `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.month.${m}`,
      value: m
    }));
  }

  @computedFrom()
  protected get allWeekdays(): Array<ValueWithLabel<Weekday>> {
    return allWeekdays().map((d) => ({
      label: `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.weekday.${d}`,
      value: d
    }));
  }

  @computedFrom()
  protected get allPositions(): Array<ValueWithLabel<number>> {
    return [1, 2, 3, -1].map((n) => ({
      label: `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.position.${n}`,
      value: n
    }));
  }

  @computedFrom('byMonth')
  protected get allMonthdays(): Array<number> {
    const monthDate = DateUtils.createDate(
      1,
      monthToNumber(this.byMonth) + 1,
      2000,
      0,
      0
    );
    const daysInMonth = DateUtils.getDaysInMonth(monthDate);
    return [...Array(daysInMonth).keys()].map((x) => x + 1);
  }

  @computedFrom('byWeekday')
  protected get byWeekdays(): Array<Weekday> {
    return [this.byWeekday];
  }

  @computedFrom()
  protected get allEndOptions(): Array<ValueWithLabel<EndOption>> {
    return Object.values(EndOption).map((e) => ({
      label: `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.end.${e}`,
      value: e
    }));
  }
}

enum YearlyRecurrenceMethod {
  ON_DAY_IN_MONTH,
  ON_WEEKDAY_IN_WEEK_IN_MONTH
}

enum MonthlyRecurrenceMethod {
  ON_DAY,
  ON_POS_WEEKDAY
}

enum EndOption {
  AFTER_N_OCCURRENCES = 'count',
  UNTIL_DAY = 'until'
}

export type RecurrenceChangedEvent = NamedCustomEvent<
  'recurrence-changed',
  { recurrence: Recurrence | null }
>;
