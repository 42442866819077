import { autoinject } from 'aurelia-framework';

import { DialogIconType } from 'common/Enums/DialogIconType';

import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FloatingLabelInput } from '../../inputComponents/floating-label-input/floating-label-input';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * this is meant to be a single global instance, just include in the root of your app
 */
@autoinject()
export class GlobalCustomDialog {
  private static instance: GlobalCustomDialog | null = null;
  private static instanceAvailableResolveFunctions: Array<() => void> = [];

  private fullScreenOverlay: FullScreenOverlay | null = null;
  private floatingLabelInput: FloatingLabelInput | null = null;

  private options: GlobalCustomDialogOptions | null = null;
  private promiseResolveFunction:
    | ((value: GlobalCustomDialogReturnObject) => void)
    | null = null;

  private promiseRejectFunction: ((error?: Error) => void) | null = null;
  private inputValue: string | null = null;
  private timerTimeout: number | null = null;

  protected inputErrorText: string | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    if (GlobalCustomDialog.instance) {
      console.error('there was already a GlobalCustomDialog, replacing it');
    }

    GlobalCustomDialog.instance = this;
    GlobalCustomDialog.instanceAvailableResolveFunctions.forEach((resolve) =>
      resolve()
    );

    this.subscriptionManager.subscribeToEvent(
      'socket:disconnected',
      this.handleDisconnect.bind(this)
    );
  }

  protected detached(): void {
    GlobalCustomDialog.instance = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  public open(
    options: GlobalCustomDialogOptions
  ): Promise<GlobalCustomDialogReturnObject> {
    if (!this.fullScreenOverlay) {
      return Promise.reject(new Error('fullscreenoverlay is not available'));
    }

    if (this.fullScreenOverlay.isOpen()) {
      this.fullScreenOverlay.closeWithoutAnimation();
    }

    this.preprocessOptions(options);
    this.options = options;

    this.inputValue = null;
    this.fullScreenOverlay.open();
    this.focusInputIfPossibleDelayed();

    return new Promise((res, rej) => {
      this.promiseResolveFunction = res;
      this.promiseRejectFunction = rej;
    });
  }

  public close(): void {
    if (this.timerTimeout) {
      clearTimeout(this.timerTimeout);
      this.timerTimeout = null;
    }

    if (this.fullScreenOverlay) {
      this.fullScreenOverlay.close();
    }
    this.promiseResolveFunction = null;
    this.promiseRejectFunction = null;
  }

  private handleButtonClicked(
    button: GlobalCustomDialogButton
  ): boolean | void {
    if (!this.promiseRejectFunction || !this.promiseResolveFunction) {
      this.close();
      return;
    }

    if (button.type === ButtonType.CANCEL) {
      this.promiseRejectFunction(new Error('DialogCanceled'));
    } else {
      if (this.options && this.options.inputOptions) {
        if (this.validateInputValue()) {
          this.promiseResolveFunction({
            value: button.value ?? null,
            inputValue: this.inputValue
          });
        } else {
          return true;
        }
      } else {
        this.promiseResolveFunction({
          value: button.value ?? null,
          inputValue: null
        });
      }
    }

    if (button.closeDialog !== false) {
      this.close();
    }

    return true;
  }

  protected handleFloatingLabelInputKeydown(event: KeyboardEvent): boolean {
    if (event.key === 'Enter') {
      this.handleButtonClicked({
        textTk: 'inputEnterKey',
        value: 'inputEnterKey',
        closeDialog: true
      });
    }

    return true;
  }

  private validateInputValue(): boolean {
    if (
      this.options &&
      this.options.inputOptions &&
      this.options.inputOptions.validator
    ) {
      const result = this.options.inputOptions.validator(this.inputValue);

      if (result === true) {
        this.inputErrorText = null;
      } else if (typeof result === 'string') {
        this.inputErrorText = result;
        this.focusInputIfPossibleDelayed();
      } else {
        this.inputErrorText = 'Der eingegebene Wert ist nicht gültig';
        this.focusInputIfPossibleDelayed();
      }

      return result === true;
    } else {
      return true;
    }
  }

  /**
   * modifies the options in place
   */
  private preprocessOptions(options: GlobalCustomDialogOptions): void {
    if (options.buttons == null) {
      options.buttons = [
        {
          textTk: 'dialogs.globalCustomDialog.defaultOkButton',
          type: null
        }
      ];
    }
  }

  private focusInputIfPossibleDelayed(): void {
    if (this.options && this.options.inputOptions) {
      setTimeout(() => {
        if (this.floatingLabelInput) {
          this.floatingLabelInput.focus();
        }
      }, 20);
    }
  }

  protected handleFullScreenOverlayOpened(): void {
    this.focusInputIfPossibleDelayed();
    if (this.options && this.options.timer) {
      this.timerTimeout = window.setTimeout(() => {
        if (this.promiseResolveFunction) {
          this.promiseResolveFunction({
            value: null,
            inputValue: null
          });
        }
        this.close();
      }, this.options.timer);
    }
  }

  private handleDisconnect(): void {
    if (
      this.options &&
      this.options.autoCloseOnDisconnect &&
      this.fullScreenOverlay &&
      this.fullScreenOverlay.isOpen()
    ) {
      this.fullScreenOverlay.close();
    }
  }

  public static async open(
    options: GlobalCustomDialogOptions
  ): Promise<GlobalCustomDialogReturnObject> {
    await new Promise<void>((resolve) => {
      if (this.instance) {
        resolve();
      } else {
        this.instanceAvailableResolveFunctions.push(resolve);
      }
    });

    // @ts-ignore - instance will always be defined here
    return await this.instance.open(options);
  }

  public static close(): void {
    if (this.instance) {
      this.instance.close();
      this.instance.promiseRejectFunction &&
        this.instance.promiseRejectFunction();
    }
  }
}

export type GlobalCustomDialogOptions = {
  /** @deprecated */
  title?: string | null;

  /** @deprecated */
  text?: string | null;

  /** translation key of the title content */
  titleTk?: string | null;

  /** params for the title translation */
  titleTkParams?: Object | null;

  /** translation key of the text content */
  textTk?: string | null;

  /** params for the text translation */
  textTkParams?: Object | null;

  /** possible values success, error, warning */
  icon?: DialogIconType | null;

  /** automatically closes the dialog after the time ran out, time in ms */
  timer?: number | null;

  /** automatically closes the dialog when the socket disconnects, useful for info dialogs which get shown while a request is in progress */
  autoCloseOnDisconnect?: boolean | null;

  /** if this is set, an input field will be shown and the value will be passed to the resolved promise */
  inputOptions?: GlobalCustomDialogInputOptions | null;

  /** default an ok button will be shown, set this to an empty array if you don't want to show one */
  buttons?: Array<GlobalCustomDialogButton> | null;
};

export type GlobalCustomDialogButton = {
  /** @deprecated */
  text?: string | null;

  /** translation key of the text */
  textTk: string | null;

  textTkParams?: Object | null;

  /** possible values: cancel, null */
  type?: ButtonType | null;

  /** an additional class string for the button, e.g. 'record-it-button-orange super-cool-designed-button' */
  className?: string | null;

  /** if this is set to false the dialog will not be automatically closed, any other value will result in automatically closing it */
  closeDialog?: boolean | null;

  /** value passed to the open promise when this button has been pressed (if the button type is cancel it will be passed to the catch function instead of the then function) */
  value?: string;
};

export type GlobalCustomDialogInputOptions = {
  type: string;
  placeholder?: string | null;

  /** validates the input value, return true to accept it, return false or a string (which will be shown to the user) to invalidate it */
  validator?: ((value: string | null) => boolean | string) | null;
};

export type GlobalCustomDialogReturnObject = {
  value: null | string;
  inputValue: null | string;
};

export enum ButtonType {
  CANCEL = 'cancel'
}
