import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ThingGroupFieldsProperty {
  @bindable()
  public property: Property | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Property,
        context: this as ThingGroupFieldsProperty,
        propertyName: 'property'
      }
    );
  }

  protected handlePropertyChanged(): void {
    assertNotNullOrUndefined(
      this.property,
      "can't ThingGroupFieldsProperty.handlePropertyChanged without property"
    );

    this.entityManager.propertyRepository.update(this.property);
  }

  protected handleDeletePropertyClick(property: Property): void {
    void Dialogs.deleteEntityDialog(property).then(() => {
      this.entityManager.propertyRepository.delete(property);
    });
  }
}
