import { bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../inputComponents/clickable-text-input/clickable-text-input';
import {
  CustomSelect,
  OptionSelectedEvent,
  SelectChangedEvent
} from '../../inputComponents/custom-select/custom-select';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyDropdownWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyDropdownWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  protected customSelect: CustomSelect<string, string> | null = null;
  protected customChoiceInput: ClickableTextInput | null = null;

  public focus(): void {
    assertNotNullOrUndefined(
      this.customSelect,
      "can't BasePropertyDropdownWidget.focus without customSelect"
    );
    this.customSelect.focus();
  }

  protected handleOptionSelected(event: OptionSelectedEvent<string>): void {
    if (!event.detail.value) {
      assertNotNullOrUndefined(
        this.customChoiceInput,
        'no customChoiceInput available to autofocus'
      );
      this.customChoiceInput.focus();
    }
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<string, string>
  ): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyDropdownWidget.handleSelectChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: event.detail.value,
      customChoice: this.configuration.binding.customChoice
    });
  }

  protected handleCustomChoiceChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyDropdownWidget.handleCustomChoiceChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: this.configuration.binding.value,
      customChoice: event.detail.value as string | null
    });
  }

  @computedFrom('compact', 'compactButton', 'inTable')
  protected get compactInputExtraSpacingNeeded(): boolean {
    if (!this.style) {
      return false;
    }

    return (
      !this.style.compact && this.style.compactButton && !this.style.inTable
    );
  }
}

export const basePropertyDropdownWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
