import { autoinject, bindable } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { watch } from '../../hooks/watch';

@autoinject()
export class UserDefinedEntityByConfigFilter {
  @bindable public userDefinedEntities: Array<UserDefinedEntity> = [];

  @bindable
  public selectedUserDefinedEntityConfig: UserDefinedEntityConfig | null = null;

  @bindable public filteredUserDefinedEntities: Array<UserDefinedEntity> = [];

  @bindable public prefilteredByEntityConfigName: string | null = null;

  protected currentUser: User | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
      })
    );
    this.updateFilteredUserDefinedEntities();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected selectedUserDefinedEntityConfigChanged(): void {
    this.updateFilteredUserDefinedEntities();
  }

  protected userDefinedEntitiesChanged(): void {
    this.updateFilteredUserDefinedEntities();
  }

  @computed(
    expression('currentUser'),
    model(EntityName.UserDefinedEntityConfig)
  )
  protected get configFilterOptions(): Array<FilterOption> {
    const configs =
      this.entityManager.userDefinedEntityConfigRepository.getAll();

    return configs.map((c) => {
      return { label: c.name ?? '', config: c };
    });
  }

  protected updateFilteredUserDefinedEntities(): void {
    if (!this.selectedUserDefinedEntityConfig) {
      this.filteredUserDefinedEntities = this.userDefinedEntities.slice();
      return;
    }
    this.filteredUserDefinedEntities = this.userDefinedEntities.filter(
      (p) =>
        p.userDefinedEntityConfigId === this.selectedUserDefinedEntityConfig?.id
    );
  }

  @watch(
    expression('prefilteredByEntityConfigName'),
    expression('configFilterOptions')
  )
  protected setSelectedEntityConfigToPrefilteredEntityConfig(): void {
    if (this.prefilteredByEntityConfigName) {
      const filterOption = this.configFilterOptions.find(
        (x) => x.config?.name === this.prefilteredByEntityConfigName
      );
      if (filterOption) {
        this.selectedUserDefinedEntityConfig = filterOption.config;
      }
    }
  }
}

type FilterOption = {
  label: string;
  config: UserDefinedEntityConfig | null;
};
