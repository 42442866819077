import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../UrlManager';
import { FileUtils } from '../Utils/FileUtils/FileUtils';
import { SocketService } from '../../services/SocketService';
import { User } from '../EntityManager/entities/User/types';
import { CustomFileEntry } from '../Utils/FileUtils/entries/CustomFileEntry';

export class CapturedPicturesRescueUploader {
  private capturedPicturesFolder = PathUtils.joinPaths(
    UrlManager.localFolder,
    'captured_pictures'
  );

  constructor(
    private user: User,
    private socketService: SocketService
  ) {}

  public async uploadCapturedPictures(): Promise<void> {
    const fileEntries = await FileUtils.getFilesShallow(
      this.capturedPicturesFolder
    );
    const errorMessages: Array<string> = [];

    for (const fileEntry of fileEntries) {
      try {
        await this.uploadCapturedPicture(fileEntry);
      } catch (error) {
        if (error instanceof Error) {
          errorMessages.push(error.message);
        } else {
          errorMessages.push(`Error: ${JSON.stringify(error)}`);
        }
      }
    }

    if (errorMessages.length > 0) {
      throw new Error(errorMessages.join('\n'));
    }
  }

  private async uploadCapturedPicture(
    fileEntry: CustomFileEntry
  ): Promise<void> {
    const dataUrl = await FileUtils.readFilePathAsDataUrl(fileEntry.nativeUrl);
    return new Promise((res, rej) => {
      this.socketService.uploadCapturedPicture(
        this.user.id,
        fileEntry.name,
        dataUrl,
        (data) => {
          if (data.success) {
            res();
          } else {
            rej(new Error('Error while uploading captured picture'));
          }
        }
      );
    });
  }
}
