import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { structurePictureAreaEntityInfo } from '../../../../classes/EntityManager/entities/StructurePictureArea/structurePictureAreaEntityInfo';
import { StructurePictureArea } from '../../../../classes/EntityManager/entities/StructurePictureArea/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class StructurePictureAreaAdapter
  implements EntityAdapter<StructurePictureArea>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> = new Set();

  constructor(options: StructurePictureAreaAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(structurePictureArea: StructurePictureArea): boolean {
    return this.entityIsEditableUserGroup(structurePictureArea);
  }

  public canEditField(structurePictureArea: StructurePictureArea): boolean {
    return this.entityIsEditableUserGroup(structurePictureArea);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.StructurePictureArea,
    StructurePictureArea
  > {
    return structurePictureAreaEntityInfo;
  }

  private entityIsEditableUserGroup(entity: StructurePictureArea): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type StructurePictureAreaAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
