import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class ProcessTaskDeviceValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    appointment,
    configurationStep,
    canDeployDevices
  }: FinishAppointmentOptionsWithConfigurationStep): boolean {
    if (!configurationStep?.undeployedDevicesRequired || !canDeployDevices) {
      return true;
    }

    const assignedDevices = this.getAssignedDevices({ appointment });

    for (const device of assignedDevices) {
      if (device.dateFrom && !device.dateTo) {
        return false;
      }
    }

    return true;
  }

  private getAssignedDevices({
    appointment
  }: {
    appointment: ProcessTaskAppointment;
  }): Array<ProcessTaskDevice> {
    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentId(
        appointment.id
      );

    return relations.map((relation) => {
      return this.entityManager.processTaskDeviceRepository.getRequiredById(
        relation.processTaskDeviceId
      );
    });
  }
}
