import { computedFrom } from 'aurelia-binding';
import { autoinject } from 'aurelia-dependency-injection';
import { Router } from 'aurelia-router';
import { bindable } from 'aurelia-templating';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskUtils } from '../../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntitiesPermissionChecker } from '../../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { AddProcessTaskDialog } from '../../add-process-task-dialog/add-process-task-dialog';

@autoinject()
export class ProcessTaskInfoOverviewProcessTaskList {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * all process tasks in the processTaskGroup
   */
  @bindable()
  public availableProcessTasks: Array<ProcessTask> = [];

  @bindable()
  public activeProcessTask: ProcessTask | null = null;

  @subscribableLifecycle()
  protected readonly processTasksPermissionsChecker: EntitiesPermissionChecker<EntityName.ProcessTask>;

  @subscribableLifecycle()
  protected readonly processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  private readonly subscriptionManager: SubscriptionManager;

  protected processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  protected readonly ProcessTaskInfoOverviewProcessTaskListDragData =
    ProcessTaskInfoOverviewProcessTaskListDragData;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTasksPermissionsChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.ProcessTask
      });

    this.processTaskGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskInfoOverviewProcessTaskList,
        propertyName: 'processTaskGroup'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleAddProcessTaskClick(): void {
    if (!this.processTaskGroup) {
      return;
    }

    void AddProcessTaskDialog.open({
      processTaskGroup: this.processTaskGroup,
      onDialogClosed: (addedProcessTask) => {
        if (addedProcessTask) {
          ProcessTaskUtils.navigateToEditProcessTaskPage(
            this.router,
            addedProcessTask.id
          );
        }
      }
    });
  }

  protected handleDrop(
    dragData: ProcessTaskInfoOverviewProcessTaskListDragData,
    targetIndex: number
  ): void {
    const oldIndex = this.availableProcessTasks.indexOf(dragData.processTask);
    if (oldIndex === -1) {
      throw new Error(
        'attempted to drop a process task which is not a part of availableProcessTasks'
      );
    }

    this.availableProcessTasks.splice(oldIndex, 1);
    if (oldIndex <= targetIndex) {
      targetIndex--;
    }
    this.availableProcessTasks.splice(targetIndex, 0, dragData.processTask);

    for (const [index, processTask] of this.availableProcessTasks.entries()) {
      if (processTask.order !== index) {
        processTask.order = index;
        this.entityManager.processTaskRepository.update(processTask);
      }
    }
  }

  protected canActivate(
    dragData: ProcessTaskInfoOverviewProcessTaskListDragData,
    index: number
  ): boolean {
    const processTaskIndex = this.availableProcessTasks.indexOf(
      dragData.processTask
    );

    return index < processTaskIndex || index > processTaskIndex + 1;
  }

  protected getDragDataForProcessTask(
    processTask: ProcessTask
  ): ProcessTaskInfoOverviewProcessTaskListDragData {
    return new ProcessTaskInfoOverviewProcessTaskListDragData(processTask);
  }

  @computedFrom(
    'availableProcessTasks',
    'processTasksPermissionsChecker.revision'
  )
  protected get canDrag(): boolean {
    return this.processTasksPermissionsChecker.allEntitiesHavePermission({
      entities: this.availableProcessTasks,
      checkPermission: ({ adapter, entity }) => {
        return adapter.canEditField(entity); // order
      }
    });
  }

  protected getProcessTaskName(
    processTaskId: string,
    processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId
  ): string {
    return (
      processTaskNamesByProcessTaskId.get(processTaskId)
        ?.nameWithThingAndPerson ?? ''
    );
  }
}

export class ProcessTaskInfoOverviewProcessTaskListDragData {
  constructor(public readonly processTask: ProcessTask) {}
}
