import _ from 'lodash';

import { bindable, observable, autoinject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { Key } from '../../classes/Key';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';

/**
 * Widget that allows a user to specify multiple values with optional autocomplete.
 *
 * Additional info if you're using this for tags: The tag will not get added to the tags automatically, because the tag
 * also needs entity information. You have to listen to the `choice-added` event, create the tag in the TagService, and
 * push it into the tags array for it to work correctly (also don't forget if a tag with that name already exists, the
 * TagService has functions that handle that themselves though)
 *
 * @event choice-added - fired when a value is added, ChoiceAddedEvent
 * @event choice-removed - fired when a value is removed, ChoiceRemovedEvent
 */
@autoinject()
export class TextChoiceWidget {
  @bindable public enabled = false;

  @bindable public choices: Array<string> = [];

  /**
   * Values displayed to the user in form of autocomplete.
   */
  @bindable public autocompleteChoices: Array<string> = [];

  /**
   * if this is set to true, only values of `autoCompleteChoices` can be chosen
   */
  @bindable public allowOnlyAutocompleteChoices = false;

  /**
   * Translation key of the text to be displayed if no choices are found.
   */
  @bindable public noChoicesFoundTk =
    'aureliaComponents.textChoiceWidget.noChoicesFound';

  private availableChoices: Array<string> = [];

  @observable private newChoice: string;
  private activeChoice: string | null = null;

  private isEditing = false;

  private inputField: HTMLInputElement | null = null;
  private newChoiceContainerElement: HTMLElement | null = null;

  private domElement: HTMLElement;

  private autocompleteViewModel: TooltipContent | null = null;

  private choiceActionsTooltipContent: TooltipContent | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
    this.newChoice = '';
  }

  private startEditing(): void {
    this.isEditing = true;
    if (this.autocompleteViewModel && !this.autocompleteViewModel.isOpen()) {
      this.filterAvailableChoices();
      this.autocompleteViewModel.open();
    }
  }

  private stopEditing(): void {
    this.autocompleteViewModel && this.autocompleteViewModel.close();
    this.isEditing = false;
    this.newChoice = '';
  }

  private handleBlurEvent(): void {
    setTimeout(() => {
      const element = document.activeElement as HTMLElement | null;
      if (
        !element ||
        (!this.elementIsInsideContentElement(element) &&
          element !== this.newChoiceContainerElement)
      ) {
        this.stopEditing();
      }
    });
  }

  private handleFocusEvent(): void {
    this.startEditing();
  }

  private elementIsInsideContentElement(element: HTMLElement): boolean {
    let currentElement = element;
    let inside = false;

    do {
      if (
        currentElement.classList &&
        currentElement.classList.contains(
          'text-choices-widget--AutocompleteContainer'
        )
      ) {
        inside = true;
        break;
      }
    } while ((currentElement = currentElement.parentNode as HTMLElement));

    return inside;
  }

  private handleKeydownEvent(event: KeyboardEvent): boolean {
    if (event.key === Key.ENTER) {
      this.processNewChoice();
      this.inputField && this.inputField.blur();
      return false;
    } else {
      return true;
    }
  }

  private addNewChoice(): void {
    const newChoiceName = this.newChoice.trim();
    this.newChoice = '';
    if (this.checkIfChoiceExists(newChoiceName) || newChoiceName === '') return;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'choice-added',
      detail: {
        choice: newChoiceName
      }
    });

    this.stopEditing();
  }

  private removeChoice(choiceToRemove: string): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'choice-removed',
      detail: {
        choice: choiceToRemove
      }
    });
  }

  protected newChoiceChanged(): void {
    this.filterAvailableChoices();
  }

  private filterAvailableChoices(): void {
    this.availableChoices = _.differenceWith(
      this.autocompleteChoices,
      this.choices,
      (arrVal, othVal) => {
        return arrVal === othVal;
      }
    );
    if (this.newChoice) {
      this.availableChoices = this.availableChoices.filter((choice) => {
        return _.includes(choice, this.newChoice);
      });
    }
  }

  private checkIfChoiceExists(newChoiceName: string): boolean {
    const existingChoice = this.choices.find(
      (choice) => choice === newChoiceName
    );
    return !!existingChoice;
  }

  private handleChoiceClick(event: MouseEvent, choice: string): void {
    this.activeChoice = choice;
    this.updateChoiceActionsTooltipContent();
    if (this.choiceActionsTooltipContent) {
      this.choiceActionsTooltipContent.ignoreClickEvent(event);
    }
  }

  private handleNewChoiceContainerClick(event: MouseEvent): void {
    if (this.autocompleteViewModel) {
      this.autocompleteViewModel.ignoreClickEvent(event);
    }
  }

  private handleNewChoiceContainerBlur(): void {
    setTimeout(() => {
      const element = document.activeElement as HTMLElement | null;
      if (
        !element ||
        (!this.elementIsInsideContentElement(element) &&
          element !== this.inputField)
      ) {
        this.stopEditing();
      }
    });
  }

  private handlePlusIconClick(): void {
    this.processNewChoice();
  }

  private processNewChoice(): void {
    if (this.allowOnlyAutocompleteChoices) {
      const firstChoice = this.availableChoices[0];
      if (firstChoice) {
        this.newChoice = firstChoice;
        this.addNewChoice();
      } else {
        this.stopEditing();
      }
    } else {
      this.addNewChoice();
    }
  }

  protected handleSelectAvailableChoice(choice: string): void {
    this.newChoice = choice;
    this.addNewChoice();
  }

  protected handleClickOnNewChoice(): void {
    this.processNewChoice();
  }

  protected handleDeleteActiveChoiceClick(): void {
    if (!this.activeChoice) {
      return;
    }

    this.removeChoice(this.activeChoice);
    if (this.choiceActionsTooltipContent) {
      this.choiceActionsTooltipContent.close();
    }
  }

  private updateChoiceActionsTooltipContent(): void {
    if (!this.choiceActionsTooltipContent) {
      return;
    }

    if (this.activeChoice && this.choices.indexOf(this.activeChoice) >= 0) {
      const index = this.choices.findIndex(
        (choice) => choice === this.activeChoice
      );
      const element = this.getChoiceElement(index);
      if (element) {
        this.choiceActionsTooltipContent.targetElement = element as HTMLElement;
        if (!this.choiceActionsTooltipContent.isOpen()) {
          this.choiceActionsTooltipContent.open();
        }
      }
    } else {
      if (this.choiceActionsTooltipContent.isOpen()) {
        this.choiceActionsTooltipContent.close();
      }
    }
  }

  private getChoiceElement(index: number): HTMLElement | null {
    if (!this.domElement) {
      return null;
    }

    return this.domElement.querySelector('#' + this.getChoiceElementId(index));
  }

  private getChoiceElementId(index: number): string {
    return 'text-choice-widget--choice-' + index;
  }
}

export class NewChoiceValueValueConverter {
  public toView(value: string): string {
    return value;
  }

  public fromView(value: string): string {
    return value.trim();
  }
}

export type ChoiceAddedEvent = CustomEvent<{ choice: string }>;
export type ChoiceRemovedEvent = CustomEvent<{ choice: string }>;
