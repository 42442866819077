import { autoinject } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { assertType } from 'common/Asserts';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { FileUtils } from '../../classes/Utils/FileUtils/FileUtils';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MapLayer } from '../../classes/EntityManager/entities/MapLayer/types';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { FileDownloadService } from '../../services/FileDownloadService';
import { configureHooks } from '../../hooks/configureHooks';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditMapLayerDialog {
  @subscribableLifecycle()
  protected readonly mapLayerPermissionsHandle: EntityNameToPermissionsHandle[EntityName.MapLayer];

  private mapLayer: MapLayer | null = null;
  private onDialogClosed: TOnDialogClosedCallback | null = null;

  protected dialog: RecordItDialog | null = null;

  protected readonly DateUtils = DateUtils;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly generalFileUploadService: GeneralFileUploadService,
    permissionsService: PermissionsService
  ) {
    this.mapLayerPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.MapLayer,
        context: this,
        expression: 'mapLayer'
      });
  }

  public open(options: IEditMapLayerDialogOptions): void {
    this.mapLayer = options.mapLayer;
    this.onDialogClosed = options.onDialogClosed || null;

    this.dialog?.open();
  }

  public static async open(options: IEditMapLayerDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const mapLayer = this.mapLayer;

    this.mapLayer = null;
    this.onDialogClosed = null;

    mapLayer && onDialogClosed && onDialogClosed(mapLayer);
  }

  protected handleMapLayerChanged(): void {
    if (!this.mapLayer) return;
    this.entityManager.mapLayerRepository.update(this.mapLayer);
  }

  protected handleFileChanged(file: File): void {
    const mapLayer = this.mapLayer;
    if (!mapLayer) return;

    const reader = new FileReader();

    reader.onerror = () => {
      void Dialogs.errorDialogTk(
        'dialogs.editMapLayerDialog.errorWhileProcessingFile'
      );
    };

    reader.onloadstart = () => {
      Dialogs.blockingStatusDialogTk(
        'dialogs.editMapLayerDialog.fileIsBeingPrepared'
      );
    };

    reader.onload = async () => {
      Dialogs.closeAllDialogs();

      assertType<ArrayBuffer>(
        reader.result,
        ArrayBuffer,
        'did not get an ArrayBuffer as result'
      );

      const generalFile =
        this.entityManager.generalFileRepository.getMapLayerFile(mapLayer.id) ||
        this.entityManager.generalFileRepository.create({
          mapLayerId: mapLayer.id,
          ownerUserGroupId: mapLayer.ownerUserGroupId
        });

      const zipFile = await FileUtils.createZipFileFromArrayBuffer(
        'data.geojson',
        reader.result
      );

      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        zipFile,
        'zip'
      );

      mapLayer.fileName = file.name;
      this.entityManager.mapLayerRepository.update(mapLayer);
    };

    reader.readAsArrayBuffer(file);
  }

  protected handleFileDownloadRequested(): void {
    if (!this.mapLayer) return;

    const generalFile =
      this.entityManager.generalFileRepository.getMapLayerFile(
        this.mapLayer.id
      );
    if (!generalFile) return;

    const filePath =
      GeneralFileUtils.getRelativeOnlineFilePathForGeneralFile(generalFile);
    if (!filePath) return;

    void this.fileDownloadService.downloadFile(filePath);
  }

  protected getUserGroupNameById(userGroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(userGroupId);
    return (group && group.name) || '';
  }
}

interface IEditMapLayerDialogOptions {
  mapLayer: MapLayer;
  onDialogClosed?: TOnDialogClosedCallback | null;
}

type TOnDialogClosedCallback = (mapLayer: MapLayer) => void;
