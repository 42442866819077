import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskRecurringAppointmentEntityInfo } from 'common/Types/Entities/ProcessTaskRecurringAppointment/createProcessTaskRecurringAppointmentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskRecurringAppointment } from './types';

export const processTaskRecurringAppointmentEntityInfo =
  createProcessTaskRecurringAppointmentEntityInfo<ProcessTaskRecurringAppointment>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
