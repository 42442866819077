import moment from 'moment';
import { difference, flatten } from 'lodash';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PropertyBinder } from '../../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { GalleryThingPictureFilterHandleOptions } from '../GalleryThingPictureFilterService/GalleryThingPictureFilterHandle';
import { DefectManagementFilter } from '../../galleryThing/gallery-thing-filter/gallery-thing-defect-filter/gallery-thing-defect-filter';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import {
  PropertyCache,
  PropertyCacheComputer
} from '../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { GalleryThingFilterHelper } from '../../classes/GalleryThing/GalleryThingFilterHelper';
import { LatLongAreaHelper } from '../../classes/LatLongAreaHelper';

export class DefectFilterHandle {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly propertyBinder: PropertyBinder<PropertyBinderConfig>;
  private isSubscribed: boolean = false;
  private propertyCache: PropertyCache | null = null;
  private filter: DefectManagementFilter | null = null;
  private defects: Array<Defect> = [];

  constructor(options: GalleryThingPictureFilterHandleOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;

    this.propertyBinder = new PropertyBinder<PropertyBinderConfig>({
      defaultValuesByName: {
        filteredDefects: []
      }
    });
  }

  public setFilter(filter: DefectManagementFilter | null): void {
    this.filter = filter;

    if (this.isSubscribed) {
      this.updateFilteredDefects();
    }
  }

  public setDefects(pictures: Array<Defect>): void {
    this.defects = pictures;

    if (this.isSubscribed) {
      this.updateFilteredDefects();
    }
  }

  public subscribe(): Disposable {
    this.isSubscribed = true;

    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateFilteredDefects.bind(this)
    );

    // needed for the coordinate filters
    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateFilteredDefects.bind(this)
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: PropertyCacheComputer,
        computeData: {},
        callback: (propertyCache) => {
          this.propertyCache = propertyCache;
          this.updateFilteredDefects();
        }
      })
    );

    return {
      dispose: () => {
        this.isSubscribed = false;
        this.propertyCache = null;
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public bindFilteredDefects(
    callback: (filteredDefects: Array<Defect>) => void
  ): Disposable {
    return this.propertyBinder.registerBinding('filteredDefects', callback);
  }

  private updateFilteredDefects(): void {
    const propertyCache = this.propertyCache;
    if (!propertyCache) {
      return;
    }

    if (this.filter) {
      const filteredDefects = this.defects.filter((d) => this.matchesFilter(d));

      this.propertyBinder.setValue('filteredDefects', filteredDefects);
    }
  }

  // /////////// FILTERS /////////////

  /**
   * @returns true if the defect should be displayed with the current filter, false otherwise.
   */
  private matchesFilter(defect: Defect): boolean {
    const matchesDateFrom = this.matchesFilterDateFrom(defect);
    const matchesDateTo = this.matchesFilterDateTo(defect);
    const matchesLatLongArea = this.matchesFilterLatLongArea(defect);
    const matchesStatus = this.matchesFilterStatus(defect);
    const matchesAssigneeId = this.matchesFilterAssigneeId(defect);
    const matchesTags = this.matchesFilterTags(defect);
    const matchesProperties = this.matchesFilterProperties(defect);

    return (
      matchesDateFrom &&
      matchesDateTo &&
      matchesLatLongArea &&
      matchesStatus &&
      matchesAssigneeId &&
      matchesTags &&
      matchesProperties
    );
  }

  /**
   * @returns true if the `defect` was created after the filter `dateFrom` date.
   */
  private matchesFilterDateFrom(defect: Defect): boolean {
    if (!this.filter?.dateFrom) {
      return true;
    }
    return moment(defect.createdAt).isAfter(this.filter.dateFrom);
  }

  /**
   * @returns true if the `defect` was created before the filter `dateTo` date.
   */
  private matchesFilterDateTo(defect: Defect): boolean {
    if (!this.filter?.dateTo) {
      return true;
    }
    return moment(defect.createdAt).isBefore(this.filter.dateTo);
  }

  /**
   * @returns true if at least one of the `defect` pictures is within the filter lat-long area.
   */
  private matchesFilterLatLongArea(defect: Defect): boolean {
    assertNotNullOrUndefined(
      this.filter,
      'cannot evaluate filter without filter config'
    );

    if (!this.filter.latLongArea) {
      return true;
    }
    const defectPictures =
      this.entityManager.pictureRepository.getByOwnerDefectId(defect.id);
    return defectPictures.some((p) =>
      LatLongAreaHelper.isPictureInArea(this.filter?.latLongArea!, p)
    );
  }

  /**
   * @returns true if the `defect` status is included within the selected filter `visibleStatuses`.
   */
  private matchesFilterStatus(defect: Defect): boolean {
    assertNotNullOrUndefined(
      this.filter,
      'cannot evaluate filter without filter config'
    );
    return this.filter.visibleStatuses.includes(defect.status);
  }

  /**
   * @returns true if the assigneeId of the `defect` & the filter match.
   */
  private matchesFilterAssigneeId(defect: Defect): boolean {
    assertNotNullOrUndefined(
      this.filter,
      'cannot evaluate filter without filter config'
    );
    if (!this.filter.assigneeId) {
      return true;
    }
    return this.filter.assigneeId === defect.assigneeId;
  }

  /**
   * @returns true if all of the tags within the filter are included within the `defect` pictures.
   */
  private matchesFilterTags(defect: Defect): boolean {
    assertNotNullOrUndefined(
      this.filter,
      'cannot evaluate filter without filter config'
    );
    const defectPictures =
      this.entityManager.pictureRepository.getByOwnerDefectId(defect.id);

    const filterTagIds = this.filter.tags.map((t) => t.id);
    const pictureTagIds = flatten(defectPictures.map((p) => p.tagIds));
    // Return true if the `filterTagIds` array is fully included within `pictureTagIds`.
    return difference(filterTagIds, pictureTagIds).length === 0;
  }

  private matchesFilterProperties(defect: Defect): boolean {
    assertNotNullOrUndefined(
      this.filter,
      'cannot evaluate filter without filter config'
    );
    assertNotNullOrUndefined(
      this.propertyCache,
      'cannot evaluate property filter without property cache'
    );

    if (!this.filter?.properties) return true;
    const activePropertyFilters = this.filter.properties.filter((p) =>
      GalleryThingFilterHelper.isActiveFilter(p)
    );

    if (activePropertyFilters.length === 0) return true;

    return activePropertyFilters.every((filterProperty) => {
      const propertyType =
        GalleryThingFilterHelper.getCorrespondingPropertyType(
          filterProperty.type
        );

      const existingPropertyValue = this.propertyCache?.getDefectPropertyValue({
        defectId: defect.id,
        propertyName: filterProperty.name,
        propertyType
      });

      return GalleryThingFilterHelper.propertiesAreEqual(
        {
          name: filterProperty.name,
          type: propertyType,
          value: existingPropertyValue
        },
        filterProperty
      );
    });
  }
}

type PropertyBinderConfig = {
  filteredDefects: Array<Defect>;
};
