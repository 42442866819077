import { AureliaLifecycleUtils } from '../classes/Utils/AureliaLifecycleUtils/AureliaLifecycleUtils';

/**
 * Provides a configuration for all hooks (`@computed` & `@watch` decorators) in this class.
 *
 * @example
 * // Dialogs are attached on startup, so hooks in all dialog classes will also all
 * // be registered on startup if not configured otherwise.
 * // The `configureHooks` method allows a dialog class to change its mount & unmount
 * // points to `open` & `handleDialogClosed` in order to use hooks only when the
 * // dialog is actually used.
 * \@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
 * export class SomeDialog {
 *   [...]
 * }
 */
export function configureHooks(config: HooksConfig) {
  return function (constructor: Function) {
    setHooksConfig(constructor.prototype, config);
  };
}

/**
 * Register a function to be called at the mount point for hooks.
 * @param target the class of the function, given as `target` in method decorators.
 * @param cb the function to execute when mounting.
 */
export function setupMount(target: any, cb: () => void): void {
  // `queueMicrotask(...)` because the `configureHooks` decorator has to execute before this
  queueMicrotask(() => {
    const config = getHooksConfig(target);
    const mountFnName = config?.mount ?? 'bind';

    const oldMount = target[mountFnName];
    if (!oldMount && mountFnName !== 'bind') {
      throw new Error(`
        A hook tried tried to register it's mount into '${target?.constructor?.name}.${mountFnName}', but no function with that name was found.
        Hooks can only be used in Aurelia components, not in other classes.
        If you are using the 'configureHooks' decorator, make sure the function names are correct.
      `);
    }

    if ((target['open'] || target['handleDialogClosed']) && !config) {
      throw new Error(`
        A hook is mounting in what is probably a dialog component, but hook configuration is not provided.
        Use \`@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })\` if this is a dialog,
        or an explicit configuration \`@configureHooks({})\` if it's not.
      `);
    }

    target[mountFnName] = function (this: any, ...args: Array<any>) {
      cb.apply(this);
      oldMount?.apply(this, args);

      if (!oldMount && mountFnName === 'bind') {
        AureliaLifecycleUtils.executeDefaultAureliaBindFunctionality(this);
      }
    };
  });
}

/**
 * Register a function to be called at the unmount point for hooks.
 * @param target the class of the function, given as `target` in method decorators.
 * @param cb the function to execute when unmounting.
 */
export function setupUnmount(target: any, cb: () => void): void {
  // `queueMicrotask(...)` because the `configureHooks` decorator has to execute before this
  queueMicrotask(() => {
    const config = getHooksConfig(target);
    const unmountFnName = config?.unmount ?? 'unbind';

    const oldUnmount = target[unmountFnName];
    if (!oldUnmount && unmountFnName !== 'unbind') {
      console.error(target);
      throw new Error(`
        A hook tried tried to register it's unmount into '${target?.constructor?.name}.${unmountFnName}', but no function with that name was found.
        Hooks can only be used in Aurelia components, not in other classes.
        If you are using the 'configureHooks' decorator, make sure the function names are correct.
      `);
    }
    target[unmountFnName] = function (this: any, ...args: Array<any>) {
      cb.apply(this);
      oldUnmount?.apply(this, args);
    };
  });
}

function setHooksConfig(target: any, config: HooksConfig): void {
  return Reflect.defineMetadata('hooksConfig', config, target, 'hooksConfig');
}

function getHooksConfig(target: any): HooksConfig | null {
  return Reflect.getOwnMetadata('hooksConfig', target, 'hooksConfig');
}

/**
 * Configuration which will be used by all hooks in this class.
 */
export type HooksConfig = {
  /**
   * The name of the function that typically
   * sets up subscriptions.
   *
   * Hooks will overwrite the function,
   * and expects the class to call it.
   *
   * @default 'bind'
   */
  mount?: string;
  /**
   * The name of the function that typically
   * destroys set-up subscriptions.
   *
   * Hooks will overwrite the function,
   * and expects the class to call it.
   *
   * @default 'unbind'
   */
  unmount?: string;
};
