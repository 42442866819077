import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DateUtils } from 'common/DateUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { SocketService } from '../../services/SocketService';
import { AuthenticationService } from '../../services/AuthenticationService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';
import { PermissionHelper } from '../../classes/PermissionHelper';

import { EditUserProfileDialog } from '../../dialogs/edit-user-profile-dialog/edit-user-profile-dialog';
import {
  RecordItModuleHelper,
  ModuleName
} from '../../classes/RecordItModuleHelper';
import { FeatureControl } from '../../config/FeatureControl';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { LogoutLockService } from '../../services/LogoutLockService';
import { LogoutLockDialog } from '../../dialogs/logout-lock-dialog/logout-lock-dialog';
import { LostCapturedPicturesRescueService } from '../../services/LostCapturedPicturesRescueService/LostCapturedPicturesRescueService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { UserCompanySettingMainMenu } from '../../classes/EntityManager/entities/UserCompanySetting/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { NavigationService } from '../../services/NavigationService';
import { HelpDialog } from '../../dialogs/help-dialog/help-dialog';

@autoinject()
export class MainNavigation {
  @observable private activeThing: Thing | null;
  @observable private activeProject: Project | null;
  @observable private activeEntry: Entry | null;
  @observable private activePerson: Person | null;
  @observable private activeProcessConfiguration: ProcessConfiguration | null;
  @observable
  private activeProcessConfigurationStep: ProcessConfigurationStep | null;
  @observable private activeProcessTask: ProcessTask | null;
  @observable
  private activeProcessTaskAppointment: ProcessTaskAppointment | null;
  @observable private activeStructureTemplate: StructureTemplate | null;

  private updateDisplayedEntitiesRateLimited: IUtilsRateLimitedFunction;

  private displayedThing: Thing | null = null;
  private displayedProjects: Array<Project> = [];
  private displayedEntryTree: Array<TMainNavigationEntryTreeItem> | null = null;
  private displayedPerson: Person | null = null;
  private displayedProcessConfiguration:
    | ProcessConfiguration
    | null
    | undefined = null;
  private displayedProcessConfigurationStep: ProcessConfigurationStep | null =
    null;
  private displayedProcessTask: ProcessTask | null = null;
  private displayedProcessTaskAppointment: ProcessTaskAppointment | null = null;
  private currentRouteFragment: string | null = null;
  private currentRouteConfig: Object | null = null;

  private mainMenuSettings: UserCompanySettingMainMenu = {};
  private usesLegacyTilesHomepage = false;

  private isConnected: boolean = false;

  private availableModules: Array<string> = [];
  private latestModuleSubscription: string | null = null;
  private useThingGroupMap: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  private readonly RecordItModuleHelper = RecordItModuleHelper;
  private readonly ModuleNames = ModuleName;
  private readonly PersonUtils = PersonUtils;
  private readonly FeatureControl = FeatureControl;

  private currentUser: User | null = null;

  protected userHasAccessToViaWorkerModule = false;

  protected ProjectType = ProjectType;
  protected Utils = Utils;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly authenticationService: AuthenticationService,
    private readonly socketService: SocketService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly logoutLockService: LogoutLockService,
    private readonly lostCapturedPicturesRescueService: LostCapturedPicturesRescueService,
    private readonly currentUserService: CurrentUserService,
    private readonly navigationService: NavigationService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.updateDisplayedEntitiesRateLimited = Utils.rateLimitFunction(
      this.updateDisplayedEntities.bind(this),
      1
    );

    this.activeThing = null;
    this.activeProject = null;
    this.activeEntry = null;
    this.activePerson = null;
    this.activeProcessConfiguration = null;
    this.activeProcessConfigurationStep = null;
    this.activeProcessTask = null;
    this.activeProcessTaskAppointment = null;
    this.activeStructureTemplate = null;
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.bindActiveEntities();
    this.bindCompanySettingProperties();

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.useThingGroupMap',
        (useThingGroupMap) => {
          this.useThingGroupMap = useThingGroupMap;
        }
      )
    );

    this.subscriptionManager.subscribeToEvent(
      'router:navigation:complete',
      () => {
        /* we have to use the current instruction of the router instead of the one in the event
         * because of this bug: https://github.com/aurelia/router/issues/177
         */
        this.currentRouteFragment = this.router.currentInstruction.fragment;
        this.currentRouteConfig = this.router.currentInstruction.config;
      }
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.Entry, () => {
      this.syncEntryTree();
    });

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateDisplayedProjects.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateUserHasAccessToViaWorkerModule.bind(this)
    );

    this.updateLatestModuleSubscription();
    this.updateAvailableModules();
    this.updateUserHasAccessToViaWorkerModule();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.currentRouteFragment = this.router.currentInstruction.fragment;
    this.currentRouteConfig = this.router.currentInstruction.config;
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private bindActiveEntities(): void {
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeThing',
        (activeThing) => {
          this.activeThing = activeThing;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProject',
        (activeProject) => {
          this.activeProject = activeProject;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeEntry',
        (activeEntry) => {
          this.activeEntry = activeEntry;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activePerson',
        (activePerson) => {
          this.activePerson = activePerson;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessConfiguration',
        (activeProcessConfiguration) => {
          this.activeProcessConfiguration = activeProcessConfiguration;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessConfigurationStep',
        (activeProcessConfigurationStep) => {
          this.activeProcessConfigurationStep = activeProcessConfigurationStep;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessTask',
        (activeProcessTask) => {
          this.activeProcessTask = activeProcessTask;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeProcessTaskAppointment',
        (activeProcessTaskAppointment) => {
          this.activeProcessTaskAppointment = activeProcessTaskAppointment;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeEntitiesService.registerBinding(
        'activeStructureTemplate',
        (activeStructureTemplate) => {
          this.activeStructureTemplate = activeStructureTemplate;
        }
      )
    );
  }

  private bindCompanySettingProperties(): void {
    const propertyNames = [
      'hideThingTypes',
      'hideThingGroups',
      'hideReportTypes',
      'hideMap',
      'hideGlobalSearch',
      'hideEditUserGroups',
      'hideProcessConfiguration',
      'hideGeneralSectionHeader'
    ] as const;

    for (const name of propertyNames) {
      this.subscriptionManager.addDisposable(
        this.activeUserCompanySettingService.bindSettingProperty(
          `mainMenu.${name}` as const,
          (setting) => {
            this.mainMenuSettings[name] = setting;
          }
        )
      );
    }

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'homePage.usesLegacyTileHomePage',
        (setting) => {
          this.usesLegacyTilesHomepage = setting;
        }
      )
    );
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser() ?? null;
    this.updateLatestModuleSubscription();
    this.updateAvailableModules();
    this.updateUserHasAccessToViaWorkerModule();
  }

  private subscriptionValid(subscription: string | null): boolean {
    return PermissionHelper.subscriptionIsValid(subscription);
  }

  private getFormattedSubscriptionDate(subscription: string): string {
    return DateUtils.formatToDateString(subscription);
  }

  private createRoute(routeName: string, params: Object): string {
    return this.router.generate(routeName, params);
  }

  private async handleLogoutClick(): Promise<void> {
    if (DeviceInfoHelper.isApp()) await this.tryToUploadLostCapturedPictures();
    if (this.logoutLockService.getLockStatus().size > 0) {
      const result = await new Promise((resolve) => {
        void LogoutLockDialog.open({
          onLockReleasedCallback: () => resolve(true),
          onDialogClosedCallback: () => resolve(false)
        });
      });
      if (!result) return;
    }
    void this.authenticationService.logout();
  }

  private async tryToUploadLostCapturedPictures(): Promise<void> {
    await this.lostCapturedPicturesRescueService.rescueLostCapturedPictures();
  }

  private routeIsActive(
    routeName: string,
    params: Object,
    currentRouteFragment: string | null
  ): boolean {
    return Utils.compareRoutes(
      this.createRoute(routeName, params),
      currentRouteFragment
    );
  }

  protected activeThingChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeProjectChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeEntityChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activePersonChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeProcessConfigurationChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeProcessConfigurationStepChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeProcessTaskChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  protected activeProcessTaskAppointmentChanged(): void {
    this.updateDisplayedEntitiesRateLimited();
  }

  private updateAvailableModules(): void {
    this.availableModules = this.currentUser
      ? PermissionHelper.getAvailableModulesForUser(this.currentUser)
      : [];
  }

  private updateLatestModuleSubscription(): void {
    this.latestModuleSubscription = this.currentUser
      ? PermissionHelper.getLatestModuleSubscription(this.currentUser)
      : null;
  }

  private updateDisplayedEntities(): void {
    let displayedEntryTree = null;
    let displayedThing = null;
    let displayedPerson = null;
    let displayedProcessConfiguration = null;
    let displayedProcessConfigurationStep = null;
    let displayedProcessTask = null;
    let displayedProcessTaskAppointment = null;

    if (this.activeEntry) {
      const project = this.entityManager.projectRepository.getById(
        this.activeEntry.project
      );
      displayedEntryTree = project
        ? this.createEntryTreeForProjectId(project.id)
        : [];
      displayedThing =
        (project &&
          this.entityManager.thingRepository.getById(project.thing)) ??
        null;
    } else if (this.activeProject) {
      displayedThing =
        this.entityManager.thingRepository.getById(this.activeProject.thing) ??
        null;
      displayedEntryTree = this.createEntryTreeForProjectId(
        this.activeProject.id
      );
    } else if (this.activeThing) {
      displayedThing = this.activeThing;
    } else if (this.activePerson) {
      displayedPerson = this.activePerson;
    } else if (this.activeProcessConfigurationStep) {
      displayedProcessConfigurationStep = this.activeProcessConfigurationStep;
      displayedProcessConfiguration =
        this.entityManager.processConfigurationRepository.getById(
          displayedProcessConfigurationStep.ownerProcessConfigurationId
        );
    } else if (this.activeProcessConfiguration) {
      displayedProcessConfiguration = this.activeProcessConfiguration;
    } else if (this.activeProcessTask) {
      displayedProcessTask = this.activeProcessTask;
    } else if (this.activeProcessTaskAppointment) {
      displayedProcessTaskAppointment = this.activeProcessTaskAppointment;
    }

    this.displayedEntryTree = displayedEntryTree;
    this.displayedThing = displayedThing;
    this.displayedPerson = displayedPerson;
    this.displayedProcessConfiguration = displayedProcessConfiguration;
    this.displayedProcessConfigurationStep = displayedProcessConfigurationStep;
    this.displayedProcessTask = displayedProcessTask;
    this.displayedProcessTaskAppointment = displayedProcessTaskAppointment;

    this.updateDisplayedProjects();
  }

  private updateDisplayedProjects(): void {
    if (this.activeEntry) {
      const project = this.entityManager.projectRepository.getById(
        this.activeEntry.project
      );
      this.displayedProjects = project ? [project] : [];
    } else if (this.activeProject) {
      this.displayedProjects = [this.activeProject];
    } else if (this.activeThing) {
      this.displayedProjects = this.entityManager.projectRepository
        .getByThingId(this.activeThing.id)
        .filter((p) => p.projectType !== ProjectType.GALLERY);
    } else {
      this.displayedProjects = [];
    }
  }

  private updateUserHasAccessToViaWorkerModule(): void {
    const user = this.currentUserService.getCurrentUser();
    if (!user) {
      this.userHasAccessToViaWorkerModule = false;
      return;
    }

    this.userHasAccessToViaWorkerModule =
      this.entityManager.defectRepository.getByAssigneeId(user.id).length > 0;
  }

  private createEntryTreeForProjectId(
    projectId: string
  ): Array<TMainNavigationEntryTreeItem> {
    const tree: Array<TMainNavigationEntryTreeItem> = [];

    this.entityManager.entryRepository
      .getByParentId(projectId, null, null)
      .forEach((entry) => {
        tree.push({
          entry: entry,
          children:
            this.entityManager.entryRepository.getSubEntriesOfEntry(entry)
        });
      });

    return tree;
  }

  private syncEntryTree(): void {
    let projectId = null;

    if (this.activeEntry) {
      projectId = this.activeEntry.project;
    } else if (this.activeProject) {
      projectId = this.activeProject.id;
    }

    if (projectId && this.displayedEntryTree != null) {
      this.syncEntriesToTree(
        this.displayedEntryTree,
        this.entityManager.entryRepository.getByParentId(projectId, null, null)
      );
    }
  }

  private syncEntriesToTree(
    tree: Array<TMainNavigationEntryTreeItem>,
    entries: Array<Entry>
  ): void {
    // add/reorder tree items
    entries.forEach((entry, key) => {
      const treeIndex = tree.findIndex((item) => item.entry === entry);
      const treeItem = tree[treeIndex];
      const children =
        this.entityManager.entryRepository.getSubEntriesOfEntry(entry);

      if (treeIndex === key) {
        // position is still the same
        treeItem.children = children;
      } else if (treeIndex > 0 && treeIndex !== key) {
        // item has been moved
        tree.splice(treeIndex, 1);
        tree.splice(key, 0, treeItem);
        treeItem.children = children;
      } else if (treeIndex === -1) {
        tree.push({
          entry: entry,
          children: children
        });
      }
    });

    // remove deleted tree items
    for (let key = tree.length - 1; key >= 0; key--) {
      if (entries.indexOf(tree[key].entry) === -1) {
        tree.splice(key, 1);
      }
    }
  }

  protected handleOpenUserProfileDialogClick(): void {
    void EditUserProfileDialog.open();
  }

  protected handleOpenHelpDialogClick(): void {
    void HelpDialog.open();
  }

  private userHasPermission(user: User, permissionName: string): boolean {
    return PermissionHelper.userHasPermission(user, permissionName);
  }

  private userHasAccessToModule(user: User, moduleName: ModuleName): boolean {
    return PermissionHelper.userHasPermissionForModule(user, moduleName);
  }

  private userHasAccessToThingModule(user: User): boolean {
    const modules = PermissionHelper.getAvailableModulesForUser(user);
    return modules.some((moduleName) => {
      return (
        RecordItModuleHelper.getOverviewPageRouteForModuleName(moduleName) ===
        'edit_objects'
      );
    });
  }

  protected getProjectRoute(project: Project): string | null {
    return this.navigationService.getProjectPageUrl(
      project.id,
      project.projectType
    );
  }
}

type TMainNavigationEntryTreeItem = {
  entry: Entry;
  children: Array<Entry>;
};
