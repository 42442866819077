import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class ProcessTaskPositionValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    appointment,
    configurationStep
  }: FinishAppointmentOptionsWithConfigurationStep): boolean {
    if (!configurationStep?.donePositionsRequired) {
      return true;
    }

    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
        appointment.id
      );

    return relations.every((relation) => relation.done);
  }
}
