import { autoinject } from 'aurelia-dependency-injection';
import _ from 'lodash';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';
import { LogDataConverterUtils } from './LogDataConverterUtils';

@autoinject()
export class ProcessTaskChecklistEntryIdLogDataConverter extends AbstractLogDataConverter {
  constructor(private readonly entityManager: AppEntityManager) {
    super();
  }

  public convert({
    logData,
    args: { idPropertyPath }
  }: ConvertOptions<'idPropertyPath'>): void {
    const value = _.get(logData, idPropertyPath);
    if (typeof value === 'string') {
      const namePropertyPath =
        LogDataConverterUtils.generateNamePropertyPath(idPropertyPath);
      _.set(logData, namePropertyPath, this.getDisplayNameForId(value));
    }
  }

  private getDisplayNameForId(id: string): string | null {
    const entry =
      this.entityManager.processTaskChecklistEntryRepository.getById(id);
    return entry ? entry.text : 'entity not found';
  }
}
