import { autoinject } from 'aurelia-framework';

import { SettingsUnion } from 'common/Settings/Settings';

import { SocketService } from '../../services/SocketService';
import {
  SettingChangedEvent,
  SettingResetClickedEvent
} from '../../listItems/setting-list-item/setting-list-item';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

@autoinject()
export class edit_settings {
  protected settings: Array<SettingsUnion> = [];

  constructor(private readonly socketService: SocketService) {}

  protected attached(): void {
    void this.updateSettings();
  }

  protected async handleRefreshClicked(): Promise<void> {
    await this.updateSettings();
  }

  protected async handleSettingChanged(
    event: SettingChangedEvent
  ): Promise<void> {
    await this.socketService.settingsModuleEndpoints.setSetting(event.detail);
    await this.updateSettings();
  }

  protected async handleSettingReset(
    event: SettingResetClickedEvent
  ): Promise<void> {
    await this.socketService.settingsModuleEndpoints.setSetting({
      key: event.detail.settingKey,
      value: null
    });

    await this.updateSettings();
  }

  private async updateSettings(): Promise<void> {
    const result =
      await this.socketService.settingsModuleEndpoints.getSettings();

    const newSettings = Object.entries(result.settings).map(([key, value]) => ({
      key,
      value
    })) as Array<SettingsUnion>;

    this.settings = InstancePreserver.createNewArray({
      originalArray: this.settings,
      newArray: newSettings,
      getTrackingValue: (item) => item.key
    });
  }
}
