import { autoinject } from 'aurelia-framework';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { FileUtils } from '../../classes/Utils/FileUtils/FileUtils';
import { Utils } from '../../classes/Utils/Utils';
import { UrlManager } from '../../classes/UrlManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PictureFileLocalFilesService } from '../../classes/EntityManager/entities/PictureFile/PictureFileLocalFilesService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFileUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { Logger } from '../../classes/Logger/Logger';
import { CustomFileEntry } from '../../classes/Utils/FileUtils/entries/CustomFileEntry';

@autoinject()
export class LostCapturedPicturesRescueService {
  private capturedPicturesFolder = PathUtils.joinPaths(
    UrlManager.localFolder,
    'captured_pictures'
  );

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFileLocalFilesService: PictureFileLocalFilesService,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileUploadService: PictureFileUploadService
  ) {}

  public async rescueLostCapturedPictures(): Promise<void> {
    let capturedPicturesDirectoryListing: Array<CustomFileEntry> = [];
    try {
      capturedPicturesDirectoryListing = await FileUtils.getFilesShallow(
        this.capturedPicturesFolder
      );
    } catch (error) {
      if (
        error instanceof FileError &&
        error.code === FileError.NOT_FOUND_ERR
      ) {
        return;
      }
    }

    for (const capturedPicturesDirectoryEntry of capturedPicturesDirectoryListing) {
      const capturedPictureId = Utils.extractIdFromPictureFileName(
        capturedPicturesDirectoryEntry.name
      );
      if (!capturedPictureId) continue;

      const pictureFileByOriginalId =
        this.entityManager.pictureFileRepository.getByOriginalId(
          capturedPictureId
        );
      if (pictureFileByOriginalId && !pictureFileByOriginalId.isBeingRenamed) {
        await this.tryToRescuePictureFileByOriginalId({
          capturedPictureId,
          pictureFile: pictureFileByOriginalId
        });
      } else {
        const pictureFileById =
          this.entityManager.pictureFileRepository.getById(capturedPictureId);
        if (
          pictureFileById &&
          !pictureFileById.isBeingRenamed &&
          !pictureFileById.onlyLocal &&
          !pictureFileById.readyForUpload
        ) {
          this.tryToRescuePictureFileById({
            pictureFile: pictureFileById
          });
        }
      }
    }
  }

  private async tryToRescuePictureFileByOriginalId({
    capturedPictureId,
    pictureFile
  }: {
    capturedPictureId: string;
    pictureFile: PictureFile;
  }): Promise<void> {
    try {
      await this.pictureFileLocalFilesService.renameLocalPictureFile(
        pictureFile,
        capturedPictureId,
        pictureFile.id
      );
      const pictureFilePath =
        this.pictureFilePathService.getCapturedLocalPicPath({ pictureFile });
      this.pictureFileUploadService.submitToUpload(
        pictureFile,
        null,
        pictureFilePath,
        false
      );
    } catch (error) {
      Logger.logError({ error });
    }
  }

  private tryToRescuePictureFileById({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): void {
    try {
      const pictureFilePath =
        this.pictureFilePathService.getCapturedLocalPicPath({ pictureFile });
      this.pictureFileUploadService.submitToUpload(
        pictureFile,
        null,
        pictureFilePath,
        false
      );

      pictureFile.readyForUpload = true;
      this.entityManager.pictureFileRepository.update(pictureFile);
    } catch (error) {
      Logger.logError({ error });
    }
  }
}
