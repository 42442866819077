import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserCompanySettingUtils } from '../../classes/EntityManager/entities/UserCompanySetting/UserCompanySettingUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event value-changed
 */
@inject(AppEntityManager, SubscriptionManagerService)
export class UserCompanySettingSelect {
  /**
   * setting id
   *
   * @type {(string|null)}
   */
  @bindable value;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {Array<import('../../classes/EntityManager/entities/UserCompanySetting/types').UserCompanySetting>} */
  _availableUserCompanySettings = [];

  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.UserCompanySetting,
      this._updateAvailableUserCompanySettings.bind(this)
    );
    this._updateAvailableUserCompanySettings();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _updateAvailableUserCompanySettings() {
    const settings = this._entityManager.userCompanySettingRepository.getAll();
    this._availableUserCompanySettings = settings.sort(
      UserCompanySettingUtils.userCompanySettingSortOptions.name.sortFunction
    );
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
