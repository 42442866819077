import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { DefectCommentDto } from './DefectCommentDto';

export function createDefectCommentEntityInfo<
  TEntity extends DefectCommentDto<string, string>
>(
  options: CreateEntityInfoOptions<DefectCommentDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.DefectComment,
  TEntity
> {
  return {
    entityName: EntityName.DefectComment,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      DefectCommentDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        senderId: {
          name: 'senderId',
          ref: EntityName.User
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        },
        message: {
          name: 'message',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: () => new Date().toISOString()
        },
        statusChange: {
          name: 'statusChange',
          defaultValue: null
        },
        sequenceNumber: {
          name: 'sequenceNumber',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
