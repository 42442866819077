export class JsonSerializer {
  public serialize(data: any): string {
    return JSON.stringify(data);
  }

  public deserialize(serializedData: string): string {
    try {
      return JSON.parse(serializedData);
    } catch (error) {
      return serializedData;
    }
  }
}
