import { bindable, autoinject } from 'aurelia-framework';

import { ArrayUtils } from 'common//Utils/ArrayUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { ProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  fallbackProcessTaskToProjectTitleCache,
  ProcessTaskToProjectTitleCache,
  ProcessTaskToProjectTitleCacheComputer
} from '../../computedValues/computers/ProcessTaskToProjectTitleCacheComputer';

@autoinject()
export class SendOperationsEmailDialogFormSelect {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public selectedProcessTaskToProjectIds: Array<string> = [];

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected processTaskToProjects: Array<ProcessTaskToProject> = [];
  protected processTaskToProjectTitleCache: ProcessTaskToProjectTitleCache =
    fallbackProcessTaskToProjectTitleCache;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskToProjectTitleCacheComputer,
        computeData: {},
        callback: (processTaskToProjectTitleCache) => {
          this.processTaskToProjectTitleCache = processTaskToProjectTitleCache;
        }
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateProcessTaskToProjects.bind(this)
    );
    this.updateProcessTaskToProjects();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskToProjects();
    }
  }

  private updateProcessTaskToProjects(): void {
    if (this.processTask) {
      this.processTaskToProjects =
        this.entityManager.processTaskToProjectRepository.getFormProcessTaskToProjectsByProcessTaskId(
          this.processTask.id
        );
    } else {
      this.processTaskToProjects = [];
    }
  }

  protected getProcessTaskToProjectName(
    processTaskToProjectId: string,
    processTaskToProjectTitleCache: ProcessTaskToProjectTitleCache
  ): string | null {
    const processTaskToProject =
      this.entityManager.processTaskToProjectRepository.getById(
        processTaskToProjectId
      );
    return processTaskToProject
      ? processTaskToProjectTitleCache.getTitleForRelation(processTaskToProject)
      : null;
  }

  protected handleCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent,
    processTaskToProject: ProcessTaskToProject
  ): void {
    if (event.detail.checked) {
      ArrayUtils.pushUnique(
        this.selectedProcessTaskToProjectIds,
        processTaskToProject.id
      );
    } else {
      ArrayUtils.remove(
        this.selectedProcessTaskToProjectIds,
        processTaskToProject.id
      );
    }
  }

  /**
   * @param processTaskToProjectId
   * @param selectedProcessTaskToProjectIds
   * @param _selectedProcessTaskToProjectIdsLength - just for updating
   */
  protected processTaskToProjectIsSelected(
    processTaskToProjectId: string,
    selectedProcessTaskToProjectIds: Array<string>,
    _selectedProcessTaskToProjectIdsLength: number
  ): boolean {
    return selectedProcessTaskToProjectIds.indexOf(processTaskToProjectId) >= 0;
  }
}
