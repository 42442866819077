import { autoinject, bindable } from 'aurelia-framework';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskPositionsByProcessTaskId,
  ProcessTaskPositionsByProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskPositionsByProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event {ProcessTaskSelectedEvent} process-task-selected
 * @event {ProcessTaskDeselectedEvent} process-task-deselected
 */
@autoinject()
export class MultiProcessTaskSelectionWidget {
  @bindable()
  public processTaskGroupId: string | null = null;

  @bindable()
  public availableProcessTasks: Array<ProcessTask> = [];

  /**
   * When a new processTask get's selected it will not be added to this array.
   * Only the `process-task-selected` and `process-task-deselected` will be fired and changes to this array have to be handled in the parent component
   */
  @bindable()
  public selectedProcessTasks: Array<ProcessTask> = [];

  @bindable()
  public enabled = false;

  /**
   * show the available positions next to the processTask
   */
  @bindable()
  public showPositionCount: boolean = false;

  /**
   * only show positions of that category
   */
  @bindable()
  public processConfigurationCategory: ProcessConfigurationCategory | null =
    null;

  private readonly subscriptionManager: SubscriptionManager;
  protected processTaskPositionsByProcessTaskId: ProcessTaskPositionsByProcessTaskId =
    new Map();

  constructor(
    private readonly element: Element,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskPositionsByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskPositionsByProcessTaskId) => {
          this.processTaskPositionsByProcessTaskId =
            processTaskPositionsByProcessTaskId;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleProcessTaskCheckedChanged(
    event: CheckboxCheckedChangedEvent,
    processTask: ProcessTask
  ): void {
    if (event.detail.checked) {
      DomEventHelper.fireEvent<ProcessTaskSelectedEvent>(this.element, {
        name: 'process-task-selected',
        detail: { processTask }
      });
    } else {
      DomEventHelper.fireEvent<ProcessTaskDeselectedEvent>(this.element, {
        name: 'process-task-deselected',
        detail: { processTask }
      });
    }
  }

  protected processTaskIdIsSelected(
    processTaskId: string,
    selectedProcessTasks: Array<ProcessTask>
  ): boolean {
    return !!selectedProcessTasks.find(
      (processTask) => processTask.id === processTaskId
    );
  }

  protected getProcessTaskPositionCountAppendValue(
    processTaskId: string,
    showPositionCount: boolean,
    processConfigurationCategory: ProcessConfigurationCategory | null,
    processTaskPositionsByProcessTaskId: ProcessTaskPositionsByProcessTaskId
  ): string {
    if (!showPositionCount) {
      return '';
    }

    const processTaskPositions =
      processTaskPositionsByProcessTaskId.get(processTaskId) ?? [];
    const filteredPositions = processTaskPositions.filter(
      (p) =>
        !processConfigurationCategory ||
        processConfigurationCategory.general ||
        p.processConfigurationCategoryId === processConfigurationCategory.id
    );

    return ` (${filteredPositions.length})`;
  }
}

export type ProcessTaskSelectedEvent = NamedCustomEvent<
  'process-task-selected',
  { processTask: ProcessTask }
>;
export type ProcessTaskDeselectedEvent = NamedCustomEvent<
  'process-task-deselected',
  { processTask: ProcessTask }
>;
