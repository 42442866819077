import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';

/**
 * Allows an entry to be selected out of a list of entry options.
 *
 * @event select-changed called when the user selects another element.
 * @event remove-entry-clicked called when an entry is to be deleted.
 */
@autoinject()
export class RapidFireEntrySelect {
  @bindable public enabled = true;

  /**
   * The currently selected entry.
   */
  @bindable public entry: Entry | null = null;

  /**
   * A list of possible entries to be selected.
   */
  @bindable public entryOptions: Array<Entry> | null = null;

  /**
   * The 9 most used entries sorted by their list_position.
   *
   * Always displayed as quick select buttons.
   */
  protected highlightedButtonEntryOptions: Array<Entry> | null = null;

  /**
   * Remaining entries (e.g. all entries except the highlighted ones) sorted by their list_position.
   *
   * Only displayed if `showAllEntryButtons` is true.
   */
  protected remainingButtonEntryOptions: Array<Entry> | null = null;

  /**
   * If true, all available entries are shown as buttons and the "more" button is hidden.
   */
  protected showAllEntryButtons = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.updateHighlightedButtonEntryOptions();
    this.updateRemainingButtonEntryOptions();
  }

  // /////////// METHODS /////////////

  private fireSelectChangedEvent(entry: Entry | null): void {
    DomEventHelper.fireEvent<SelectChangedEvent<Entry, Entry>>(this.element, {
      name: 'select-changed',
      detail: { value: entry, selectedOption: entry }
    });
  }

  /**
   * retrieve the amount of times each entry has been used before.
   * @returns a map that maps entries to their useCount (amount of times they've been used before).
   */
  private getUseCountForEntries(entries: Array<Entry>): Map<Entry, number> {
    const user = this.currentUserService.getRequiredCurrentUser();

    const entryUseCountMap: Map<Entry, number> = new Map();
    for (const entry of entries) {
      const entryUsage =
        this.entityManager.entryUsageStatisticRepository.getByEntryAndUserId(
          entry.id,
          user.id
        );
      const useCount = entryUsage?.useCount ?? 0;
      entryUseCountMap.set(entry, useCount);
    }

    return entryUseCountMap;
  }

  /**
   * Unsorted list of available entry options + their usecount.
   */
  private getEntriesWithUseCount(
    entries: Array<Entry> | null
  ): Array<EntryWithUseCount> | null {
    if (!entries) {
      return null;
    }

    const entryUseCountMap = this.getUseCountForEntries(entries);
    const optionsWithUseCount = entries.map((o) => ({
      entry: o,
      useCount: entryUseCountMap.get(o) ?? 0
    }));
    return optionsWithUseCount;
  }

  /**
   * Entries sorted by usecount descending (with list_position ascending sorting fallback).
   */
  private sortByUseCount(entries: Array<Entry> | null): Array<Entry> | null {
    const entriesWithUseCount = this.getEntriesWithUseCount(entries);
    if (!entriesWithUseCount) {
      return null;
    }
    entriesWithUseCount.sort((a, b) => {
      if (a.useCount !== b.useCount) {
        return b.useCount - a.useCount;
      } else {
        return a.entry.list_position - b.entry.list_position;
      }
    });
    return entriesWithUseCount.map((e) => e.entry);
  }

  private updateHighlightedButtonEntryOptions(): void {
    const entries = this.sortByUseCount(this.entryOptions)?.slice(0, 9);
    if (!entries) {
      this.highlightedButtonEntryOptions = null;
      return;
    }
    entries.sort((a, b) => a.list_position - b.list_position);
    this.highlightedButtonEntryOptions = entries;
  }

  private updateRemainingButtonEntryOptions(): void {
    const entries = this.sortByUseCount(this.entryOptions)?.slice(9);
    if (!entries) {
      this.remainingButtonEntryOptions = null;
      return;
    }
    entries.sort((a, b) => a.list_position - b.list_position);
    this.remainingButtonEntryOptions = entries;
  }

  // /////////// OBSERVABLES /////////////

  protected entryOptionsChanged(): void {
    this.showAllEntryButtons = false;
    this.updateHighlightedButtonEntryOptions();
    this.updateRemainingButtonEntryOptions();
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleSelectedEntryChanged(
    event: SelectChangedEvent<Entry, Entry>
  ): void {
    this.fireSelectChangedEvent(event.detail.value);
  }

  protected handleRemoveEntryClicked(): void {
    DomEventHelper.fireEvent<RemoveEntryEvent>(this.element, {
      name: 'remove-entry-clicked',
      detail: null
    });
  }

  protected handleEntryButtonClicked(entry: Entry): void {
    this.entry = entry;
    setTimeout(() => this.fireSelectChangedEvent(entry));
  }

  protected handleMoreButtonClicked(): void {
    this.showAllEntryButtons = true;
  }
}

type RemoveEntryEvent = NamedCustomEvent<'remove-entry-clicked', null>;

type EntryWithUseCount = {
  entry: Entry;
  useCount: number;
};
