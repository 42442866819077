import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../classes/Utils/DisposableContainer';

export class SessionService {
  private static readonly CURRENT_SESSION_ID_STORAGE_KEY = 'currentSessionId';
  private static readonly CURRENT_USER_STORAGE_KEY = 'web_user';
  private static readonly CURRENT_JWT_STORAGE_KEY = 'web_token';

  private currentUser: IWebUser | null = null;
  private currentSessionId: string | null = null;
  private currentJWT: string | null = null;

  private eventDispatcher: EventDispatcher<EventDispatcherConfig> =
    new EventDispatcher();

  private initialized = false;

  public async initialize(): Promise<void> {
    this.currentSessionId =
      (await DataStorageHelper.getItem(
        SessionService.CURRENT_SESSION_ID_STORAGE_KEY
      )) ?? null;

    this.currentUser =
      (await DataStorageHelper.getItem(
        SessionService.CURRENT_USER_STORAGE_KEY
      )) ?? null;

    this.currentJWT =
      (await DataStorageHelper.getItem(
        SessionService.CURRENT_JWT_STORAGE_KEY
      )) ?? null;

    this.initialized = true;
  }

  public async setCurrentUser(user: IWebUser | null): Promise<void> {
    this.assertInitialized();

    this.currentUser = user;
    await DataStorageHelper.setItem('web_user', user);
    this.eventDispatcher.dispatchEvent('currentUserChanged', user);
  }

  /**
   * returns only the instance stored from the login response
   * this will not be the same instance as a user from the UserService
   */
  public getCurrentUser(): IWebUser | null {
    this.assertInitialized();

    return this.currentUser;
  }

  public async setCurrentJWT(jwt: string | null): Promise<void> {
    this.assertInitialized();

    this.currentJWT = jwt;
    await DataStorageHelper.setItem('web_token', jwt);
  }

  public getCurrentJWT(): string | null {
    this.assertInitialized();

    return this.currentJWT;
  }

  public async setCurrentSessionId(sessionId: string | null): Promise<void> {
    this.assertInitialized();

    this.currentSessionId = sessionId;
    await DataStorageHelper.setItem('currentSessionId', sessionId);
  }

  public getCurrentSessionId(): string | null {
    this.assertInitialized();

    return this.currentSessionId;
  }

  public subscribeToCurrentUserChanged(
    callback: (currentUser: IWebUser | null) => void
  ): Disposable {
    this.assertInitialized();

    return this.eventDispatcher.addDisposableEventListener(
      'currentUserChanged',
      callback
    );
  }

  /**
   * Useful for logging out when reloading this page after calling this function.
   */
  public async clearStoredData(): Promise<void> {
    this.currentJWT = null;
    this.currentSessionId = null;
    this.currentUser = null;

    await DataStorageHelper.setItem(
      SessionService.CURRENT_JWT_STORAGE_KEY,
      null
    );
    await DataStorageHelper.setItem(
      SessionService.CURRENT_SESSION_ID_STORAGE_KEY,
      null
    );
    await DataStorageHelper.setItem(
      SessionService.CURRENT_USER_STORAGE_KEY,
      null
    );
  }

  private assertInitialized(): void {
    if (!this.initialized) {
      throw new Error('SessionService is not initialized');
    }
  }
}

export interface IWebUser {
  id: string;
  username: string;
  email: string;
}

type EventDispatcherConfig = {
  currentUserChanged: IWebUser | null;
};
