import { RecordItHeader } from '../aureliaComponents/record-it-header/record-it-header';
import { Utils } from './Utils/Utils';

export class ScrollHelper {
  static _defaultScrollToItemOptions = {
    topOffset: 0
  };

  /**
   * @param {HTMLElement} element
   * @param {(number|null)} [duration] - 0 for no animation, null for default duration
   * @param {(TScrollHelperScrollToItemOptions|null)} [options]
   * @returns {Promise}
   */
  static scrollToItem(element, duration, options) {
    options = _.assign({}, this._defaultScrollToItemOptions, options);
    const headerHeight = RecordItHeader.getHeaderHeight();
    const scrollTop =
      Utils.getElementOffset(element).top -
      (headerHeight != null ? headerHeight : 0) -
      options.topOffset;

    return this.scrollToPosition(scrollTop, duration);
  }

  /**
   * @param {HTMLElement} element
   * @param {(number|null)} [duration] - 0 for no animation, null for default duration
   * @returns {Promise}
   */
  static scrollToItemCentered(element, duration) {
    const scrollTop = this.getCenteredScrollTopForElement(element);
    return this.scrollToPosition(scrollTop, duration);
  }

  /**
   * returns the scrollTop position for the element to be centered
   *
   * @param {HTMLElement} element
   * @returns {number}
   */
  static getCenteredScrollTopForElement(element) {
    const headerHeight = RecordItHeader.getHeaderHeight();
    const elementTopOffset = Utils.getElementOffset(element).top;
    const viewHeight =
      document.documentElement.clientHeight - (headerHeight || 0);
    const elementHeight = parseFloat(getComputedStyle(element).height);
    return elementTopOffset - headerHeight - viewHeight / 2 + elementHeight / 2;
  }

  /**
   * @param {number} scrollTop
   * @param {(number|null)} [duration] - 0 for no animation, null for default duration
   * @returns {Promise}
   */
  static scrollToPosition(scrollTop, duration) {
    return new Promise((resolve) => {
      const scrollElement = this.getMainScrollingElement();

      // if we are already at the correct position we don't have to animate it
      // also the same start value and end value for the tween will result in a velocity bug
      // which will pass null instead of the actual value in the first run
      if (scrollTop === scrollElement.scrollTop) {
        resolve();
        return;
      }

      if (duration == null || duration > 0) {
        // eslint-disable-next-line new-cap
        $.Velocity(
          scrollElement,
          {
            tween: [scrollTop, scrollElement.scrollTop]
          },
          {
            duration: duration || 1000,
            progress: (elements, complete, remaining, start, tweenValue) => {
              scrollElement.scrollTop = tweenValue;
            },
            complete: resolve,
            mobileHA: false
          }
        );
      } else {
        scrollElement.scrollTop = scrollTop;
        resolve();
      }
    });
  }

  /**
   *
   * @returns {Element}
   */
  static getMainScrollingElement() {
    if (!document.scrollingElement) {
      throw new Error('no scrolling element found');
    }

    return document.scrollingElement;
  }

  /**
   * @param {HTMLElement} element
   * @param {string} direction - 'top', 'left'
   * @param {number} value
   */
  static scrollElement(element, direction, value) {
    const propertyNameMap = {
      top: 'scrollTop',
      left: 'scrollLeft'
    };
    const options = {};
    options[propertyNameMap[direction]] = value;
    $(element).animate(options);
  }

  /**
   * for further documentation look at scrollElement
   *
   * @param {HTMLElement} element
   * @param {string} direction
   */
  static scrollElementToBottom(element, direction) {
    const computed = window.getComputedStyle(element);
    const maxScrollTop = Math.max(
      element.scrollHeight - parseFloat(computed.height)
    );
    this.scrollElement(element, direction, maxScrollTop);
  }

  /**
   * @param {string} querySelector
   * @param {Pagination} [pagination]
   * @param {Object} [item]
   * @param {function(): boolean} [checkAttachedCallback]
   */
  static async autoScrollToListItem(
    querySelector,
    pagination,
    item,
    checkAttachedCallback
  ) {
    const element = await this.autoScrollToElementWithPagination(
      querySelector,
      pagination,
      item,
      checkAttachedCallback
    );
    const vm = Utils.getViewModelOfElement(element);
    vm.highlight();
  }

  /**
   * @param {string} querySelector
   * @param {Pagination} [pagination]
   * @param {Object} [item]
   * @param {function(): boolean} [checkAttachedCallback]
   */
  static async autoScrollToSubtleListItem(
    querySelector,
    pagination,
    item,
    checkAttachedCallback
  ) {
    const element = await this.autoScrollToElementWithPagination(
      querySelector,
      pagination,
      item,
      checkAttachedCallback
    );
    element.classList.add('record-it-background-highlighted');
    setTimeout(() => {
      element.classList.remove('record-it-background-highlighted');
    }, 500);
  }

  /**
   * @param {string} querySelector
   * @param {Pagination} [pagination]
   * @param {Object} [item]
   * @param {function(): boolean} [checkAttachedCallback]
   * @returns {Promise<(HTMLElement|null)>}
   */
  static autoScrollToElementWithPagination(
    querySelector,
    pagination,
    item,
    checkAttachedCallback
  ) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (checkAttachedCallback && !checkAttachedCallback()) return;
        if (pagination) pagination.goToItem(item);
        setTimeout(() => {
          const element = document.querySelector(querySelector);

          if (element) {
            ScrollHelper.scrollToItemCentered(element);
            resolve(element);
          } else {
            reject(new Error('[ScrollHelper] element to scroll to not found'));
          }
        }, 5);
      }, 10);
    });
  }
}

/**
 * @typedef {Object} TScrollHelperScrollToItemOptions
 * @property {number} topOffset - offset of the element to the header in px, default 0 (it will touch the header)
 */
