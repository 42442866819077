import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SupportedLng, TranslationUtils } from '../../classes/TranslationUtils';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

@autoinject()
export class SwitchLanguageService {
  private customTranslations = {};

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly i18n: I18N,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public init(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(() => {
        this.updateCurrentLanguage();
      })
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.customTranslations',
        (customTranslations) => {
          this.customTranslations = customTranslations;
          this.updateCurrentLanguage();
        }
      )
    );
    this.updateCurrentLanguage();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateCurrentLanguage(): void {
    const ns = 'translation';
    const lng = this.getCurrentUserLanguageCode();
    const customLng = `${lng}-CUSTOM`;
    this.removeResourceBundle(customLng, ns);

    if (!this.customTranslations) {
      void this.i18n.setLocale(lng);
      return;
    }

    this.i18n.i18next.addResourceBundle(customLng, ns, this.customTranslations);

    void this.i18n.setLocale(customLng);
  }

  private getCurrentUserLanguageCode(): SupportedLng {
    const currentUser = this.currentUserService.getCurrentUser();
    if (currentUser) {
      const lngCode = currentUser.languageCode;
      TranslationUtils.assertLng(lngCode);
      return lngCode;
    }

    return TranslationUtils.getSupportedLngOfNavigator();
  }

  private removeResourceBundle(lng: string, ns: string): void {
    const i18next = this.i18n.i18next;
    if (i18next.hasResourceBundle(lng, ns)) {
      i18next.removeResourceBundle(lng, ns);
    }
  }
}
