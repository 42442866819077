import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import {
  MappingType,
  SharepointListColumnToThingMappingItemDto,
  SynchronizationDirection
} from './SharepointListColumnToThingMappingItemDto';

export function createSharepointListColumnToThingMappingItemEntityInfo<
  TEntity extends SharepointListColumnToThingMappingItemDto<string, string>
>(
  options: CreateEntityInfoOptions<
    SharepointListColumnToThingMappingItemDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.SharepointListColumnToThingMappingItem,
  TEntity
> {
  return {
    entityName: EntityName.SharepointListColumnToThingMappingItem,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      SharepointListColumnToThingMappingItemDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerThingGroupId: {
          name: 'ownerThingGroupId',
          ref: EntityName.ThingGroup,
          cascadeDeleteLocally: true
        },
        sharepointListColumnName: {
          name: 'sharepointListColumnName',
          defaultValue: ''
        },
        thingFieldName: {
          name: 'thingFieldName',
          defaultValue: ''
        },
        thingTypePropertyId: {
          name: 'thingTypePropertyId',
          ref: EntityName.Property,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        synchronizationDirection: {
          name: 'synchronizationDirection',
          defaultValue: SynchronizationDirection.SP_TO_THING
        },
        mappingType: {
          name: 'mappingType',
          defaultValue: MappingType.THING_PROPERTY
        }
      },
      options.additionalFieldInfos
    )
  };
}
