import { autoinject, observable } from 'aurelia-framework';
import { NavModel, RouteConfig, Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import {
  AvailableSearchResultEntities,
  EntityNameToSearchResultMap,
  GlobalSearchResults,
  SearchGloballySuccessResponse
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';

import { SocketService } from '../../services/SocketService';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { CurrentPageIndexChangedEvent } from '../../aureliaComponents/pagination/pagination';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { NFCHelper } from '../../classes/Nfc/NFCHelper';
import { Dialogs } from '../../classes/Dialogs';

@autoinject()
export class GlobalSearch {
  private searchTerm = '';
  private currentSearchTerm = '';
  protected categorizedResults: Array<
    ResultCategory<AvailableSearchResultEntities>
  > = [];
  private readonly subscriptionManager: SubscriptionManager;

  private isSearching = false;
  @observable private isAuthenticated = false;

  protected isMobile = false;

  protected maxPageIndex = 1;
  @observable protected currentPageSize = 10;
  @observable protected currentPageIndex = 1;

  protected moreButtonChoices: Array<MoreButtonChoice> = [];
  protected totalResultCount = 0;

  protected EntityName = EntityName;

  constructor(
    private router: Router,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly i18n: I18N
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Lifecycle **********

  protected activate(params: RouteParams, routeConfig: RouteConfig): void {
    this.searchTerm = params.search_term ?? '';
    this.updateTitle(this.searchTerm, routeConfig.navModel);

    if (this.searchTerm) this.search();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding(
        'isAuthenticated',
        (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        }
      ),
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;

        if (GlobalMenu.hasControl(this)) {
          GlobalMenu.setVisible(this, this.isMobile);
        }
      }),
      NFCHelper.registerBinding('nfcEnabled', (nfcEnabled) => {
        this.updateMoreButtonChoices(nfcEnabled);
      })
    );

    void NFCHelper.isNfcEnabled().then((nfcEnabled) => {
      this.updateMoreButtonChoices(nfcEnabled);
    });

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'search-by-nfc-tag': this.handleSearchByNfcTagClick.bind(this)
      }
    });
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    GlobalMenu.releaseControl(this);
  }

  // ********** Aurelia Change Handlers **********

  protected isAuthenticatedChanged(): void {
    if (!this.isAuthenticated && this.isSearching) {
      this.isSearching = false;
    } else {
      if (this.searchTerm) this.search(true);
    }
  }

  protected currentPageSizeChanged(): void {
    this.search(true);
  }

  protected currentPageIndexChanged(): void {
    this.search(true);
  }

  // ********** Handlers **********

  protected handleSearchTriggered(): void {
    this.router.navigateToRoute(
      'global_search',
      { search_term: this.searchTerm },
      { trigger: false }
    );
    this.updateTitle(this.searchTerm);

    this.search();
  }

  protected handleCurrentPageIndexChanged(
    event: CurrentPageIndexChangedEvent
  ): void {
    this.currentPageIndex = event.detail.page;
  }

  protected handleSearchByNfcTagClick(): void {
    NFCHelper.scanSingleUID((error, nfcTagId) => {
      if (error) {
        return false;
      } else if (nfcTagId) {
        this.isSearching = true;
        this.socketService.searchByNfcTokenId(
          {
            nfcTokenId: nfcTagId
          },
          (response) => {
            if (response.success) {
              this.processSearchResponse(response);
            } else {
              void Dialogs.warningDialogTk(
                `serverResponses.searchByNfcTokenId.${response.status}`
              );
            }
            this.isSearching = false;
          }
        );
      }
      return true;
    });
  }

  // ********** Methods **********

  private search(forceSearch = false): void {
    if (
      !this.isAuthenticated ||
      (this.searchTerm === this.currentSearchTerm && !forceSearch)
    ) {
      return;
    }
    this.currentSearchTerm = this.searchTerm;

    if (this.searchTerm.length >= 3) {
      this.startSearch();
    } else {
      this.resetSearchResults();
    }
  }

  private startSearch(): void {
    this.isSearching = true;
    this.socketService.searchGlobally(
      {
        searchTerm: this.searchTerm,
        resultsPerPage: this.currentPageSize,
        page: this.currentPageIndex - 1
      },
      (data) => {
        if (data.success) {
          this.processSearchResponse(data);
        } else {
          void Dialogs.errorDialogTk(
            `serverResponses.globalSearch.${data.status}`
          );
        }
        this.isSearching = false;
      }
    );
  }

  private processSearchResponse(response: SearchGloballySuccessResponse): void {
    this.totalResultCount = response.totalResultCount;
    this.updateMaxPageIndex(response.totalResultCount);
    this.updateSearchResultCategories(response.searchResults);
  }

  private updateSearchResultCategories(
    searchResults: GlobalSearchResults
  ): void {
    const categorizedResults: Array<
      ResultCategory<AvailableSearchResultEntities>
    > = [];

    for (const [entityName, results] of Object.entries(searchResults)) {
      categorizedResults.push({
        name: entityName,
        items: results.entities,
        totalCountPerEntity: results.totalResultCountPerEntity
      });
    }

    this.categorizedResults = categorizedResults;
  }

  private resetSearchResults(): void {
    this.isSearching = false;
    this.categorizedResults = [];
    this.totalResultCount = 0;
  }

  /**
   * because the currentInstruction is not available while being in the activate step,
   * we have to pass the navModel manually, this is not an issue after the activate step has been completed
   */
  private updateTitle(searchTerm: string, navModelOverride?: NavModel): void {
    const navModel =
      navModelOverride || this.router.currentInstruction.config.navModel;

    if (navModel && searchTerm)
      navModel.title = this.i18n.tr(navModel.title) + ' - ' + searchTerm;
  }

  private updateMaxPageIndex(resultCount: number): void {
    this.maxPageIndex = Math.ceil(resultCount / this.currentPageSize);
  }

  private updateMoreButtonChoices(nfcEnabled = false): void {
    const choices: Array<MoreButtonChoice> = [];

    if (nfcEnabled) {
      choices.push({
        labelTk: 'generalPages.globalSearch.searchByNfcTag',
        name: 'search-by-nfc-tag',
        iconClass: 'far fa-nfc'
      });
    }

    this.moreButtonChoices = choices;
    GlobalMenu.setChoices(this, this.moreButtonChoices);
  }
}

type RouteParams = {
  search_term?: string;
};

type ResultCategory<T extends AvailableSearchResultEntities> = {
  name: T;
  items: Array<EntityNameToSearchResultMap[T]>;
  totalCountPerEntity: number;
};
