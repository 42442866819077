import { EventAggregator } from 'aurelia-event-aggregator';
import {
  FileChunkFileInfoResponse,
  FileChunkUploadErrorType,
  FileInfoMissingChunk
} from 'common/EndpointTypes/FileChunkUploadEndpointsHandler';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { SocketService } from '../SocketService';
import { FileChunkUploaderSettings } from './FileChunkUploaderSettings';

export class FileChunkUploader {
  private readonly socketService: SocketService;
  private readonly eventAggregator: EventAggregator;
  private readonly chunkedFileId: string;
  private readonly fileData: Uint8Array;
  private missingChunks: Array<FileInfoMissingChunk>;

  constructor(options: FileChunkUploaderOptions) {
    this.socketService = options.socketService;
    this.eventAggregator = options.eventAggregator;
    this.chunkedFileId = options.chunkedFileId;
    this.fileData = options.fileData;
    this.missingChunks = options.missingChunks;
  }

  public getChunkedFileId(): string {
    return this.chunkedFileId;
  }

  public async upload(): Promise<void> {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const chunkToUpload = this.getNextChunkToUpload();
      if (!chunkToUpload) {
        break;
      }

      const data = await this.sendUploadRequest(chunkToUpload);
      this.missingChunks = data.missingChunks;
    }
  }

  private getNextChunkToUpload(): ChunkToUpload | null {
    const missingChunk = this.missingChunks[0];
    if (!missingChunk) {
      return null;
    }
    const offset = missingChunk.start;
    const maxEnd = offset + FileChunkUploaderSettings.getChunkSize() - 1; // - 1 because the end is inclusive

    return {
      offset,
      data: this.fileData.slice(offset, Math.min(missingChunk.end, maxEnd) + 1) // + 1 because the end is exclusive
    };
  }

  private async sendUploadRequest(
    chunk: ChunkToUpload
  ): Promise<FileChunkFileInfoResponse & { success: true }> {
    const data =
      await EventAggregatorPromiseHelper.createConnectedPromise<FileChunkFileInfoResponse>(
        this.eventAggregator,
        this.socketService.fileChunkUploadUploadChunk({
          fileId: this.chunkedFileId,
          offset: chunk.offset,
          data: chunk.data
        })
      );

    if (!data.success) {
      throw new FileChunkUploadError(data.errorType);
    }

    return data;
  }
}

export type FileChunkUploaderOptions = {
  socketService: SocketService;
  eventAggregator: EventAggregator;
  chunkedFileId: string;
  fileData: Uint8Array;
  missingChunks: Array<FileInfoMissingChunk>;
};

export class FileChunkUploadError extends Error {
  constructor(private readonly errorType: FileChunkUploadErrorType) {
    super(`failed to upload chunk, reason: ${errorType}`);
  }

  public getErrorType(): FileChunkUploadErrorType {
    return this.errorType;
  }
}

type ChunkToUpload = {
  offset: number;
  data: Uint8Array;
};
