import { CustomDirectoryEntry } from './CustomDirectoryEntry';
import { EntryNotOfExpectedTypeError } from './EntryNotOfExpectedTypeError';

/**
 * Custom prefix because the cordova-plugin-file is poluting the global namespace and we can't name this FileEntry...
 */
export abstract class CustomFileEntry {
  /**
   * name of the file
   *
   * e.g. if the file has the uri /apps/recordit/data/filename.txt it will be filename.txt
   */
  public abstract get name(): string;

  /**
   * the absolute path to the file
   */
  public abstract get path(): string;

  public abstract get nativeUrl(): string;

  public abstract moveTo(
    directory: CustomDirectoryEntry,
    newName: string
  ): Promise<CustomFileEntry>;
  public abstract copyTo(
    directory: CustomDirectoryEntry,
    newName: string
  ): Promise<CustomFileEntry>;

  public abstract writeBlob(blob: Blob): Promise<void>;

  public abstract readAsBlob(): Promise<Blob>;

  public abstract delete(): Promise<void>;

  public asFile(): this {
    return this;
  }

  public asDirectory(): never {
    throw new EntryNotOfExpectedTypeError({
      type: 'CustomFileEntry',
      expectedType: 'CustomDirectoryEntry'
    });
  }
}
