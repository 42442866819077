import _ from 'lodash';

import { autoinject, bindable } from 'aurelia-framework';

import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { DomElementIdGenerator } from '../../classes/DomUtilities/DomElementIdGenerator';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  AfterPropertyChangedEvent,
  PropertyInputField
} from '../property-input-field/property-input-field';
import {
  CreationConfig,
  PropertyAdapter
} from './PropertyAdapter/PropertyAdapter';

/**
 * A list of property input fields. Displays an input field for each property.
 * If not all configured properties exist, a button will be shown. This button allows the user to create all the missing properties.
 */
@autoinject()
export class PropertyInputFieldListWithDefaultProperties<
  TPropertyType extends Property,
  TDefaultPropertyConfigType extends TDefaultPropertyConfig
> {
  @bindable()
  public adapter: PropertyAdapter<
    TPropertyType,
    TDefaultPropertyConfigType
  > | null = null;

  @bindable()
  public forceDisabled: boolean = false;

  private readonly adapterSubscriptionManager: SubscriptionManager;
  private readonly id = DomElementIdGenerator.getNextId();
  private isAttached: boolean = false;
  private canUpdateProperties: boolean = false;

  private properties: Array<TPropertyType> = [];
  private configs: Array<CreationConfig<TDefaultPropertyConfigType>> = [];
  protected needsToUpdate: boolean = false;
  private updateInfos: Array<
    PropertyUpdateInfo<TPropertyType, TDefaultPropertyConfigType>
  > = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.adapterSubscriptionManager = subscriptionManagerService.create();
  }

  public focusProperty(property: TPropertyType): void {
    const element = this.element.querySelector(
      '#' + this.getPropertyInputFieldId(property.id)
    );

    const viewModel: PropertyInputField | null = element
      ? Utils.getViewModelOfElement(element)
      : null;
    if (viewModel) {
      viewModel.focus();
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateAdapterSubscription();
  }

  protected detached(): void {
    this.isAttached = false;

    this.adapterSubscriptionManager.disposeSubscriptions();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.updateAdapterSubscription();
    }
  }

  private updateAdapterSubscription(): void {
    this.adapterSubscriptionManager.disposeSubscriptions();

    if (!this.adapter) {
      this.properties = [];
      this.configs = [];
      this.updateUpdateInfos();
      return;
    }

    this.adapterSubscriptionManager.addDisposable(
      this.adapter.subscribe({
        setProperties: (properties) => {
          this.sortProperties(properties);
          this.properties = properties;
          this.updateUpdateInfos();
        },
        setConfigs: (configs) => {
          this.configs = configs.map((c, index) => {
            return {
              ...c,
              order: index
            };
          });
          this.updateUpdateInfos();
        },
        setCanUpdateProperties: (canUpdateProperties) => {
          this.canUpdateProperties = canUpdateProperties;
        }
      })
    );
  }

  private updateUpdateInfos(): void {
    const propertiesWithConfig = this.getPropertiesWithConfig();

    this.updateInfos = [
      ...this.getUpdateInfosForMissingProperties({ propertiesWithConfig }),
      ...this.getUpdateInfosForWronglyOrderedProperties({
        propertiesWithConfig
      })
    ];

    // if the only thing to update are properties without an order, we don't show the button because it will be functionally the same to the user
    this.needsToUpdate = !this.updateInfos.every((info) => {
      if (info.type !== PropertyUpdateInfoType.UPDATE_ORDER) {
        return false;
      }

      if (info.property.order !== null) {
        return false;
      }

      return info.order === info.displayedOrder;
    });
  }

  private getPropertiesWithConfig(): Array<
    PropertyWithConfig<TPropertyType, TDefaultPropertyConfigType>
  > {
    return this.properties.map((property) => {
      return {
        property,
        config:
          this.configs.find((config) => {
            return PropertyHelper.isTheSameProperty(property, config);
          }) ?? null
      };
    });
  }

  private getUpdateInfosForMissingProperties({
    propertiesWithConfig
  }: {
    propertiesWithConfig: Array<
      PropertyWithConfig<TPropertyType, TDefaultPropertyConfigType>
    >;
  }): Array<PropertyUpdateInfo<TPropertyType, TDefaultPropertyConfigType>> {
    const usedConfigs = new Set(
      propertiesWithConfig.map((item) => item.config)
    );
    const missingConfigs = _.uniqWith(this.configs, (c1, c2) => {
      return PropertyHelper.isTheSameProperty(c1, c2);
    }).filter((config) => !usedConfigs.has(config));

    return missingConfigs.map((config) => {
      return {
        type: PropertyUpdateInfoType.CREATE,
        config
      };
    });
  }

  private getUpdateInfosForWronglyOrderedProperties({
    propertiesWithConfig
  }: {
    propertiesWithConfig: Array<
      PropertyWithConfig<TPropertyType, TDefaultPropertyConfigType>
    >;
  }): Array<PropertyUpdateInfo<TPropertyType, TDefaultPropertyConfigType>> {
    const updateInfos: Array<
      PropertyUpdateInfo<TPropertyType, TDefaultPropertyConfigType>
    > = [];
    let superflousOrder = Math.max(0, ...this.configs.map((c) => c.order)) + 1;

    for (const [
      displayedOrder,
      { property, config }
    ] of propertiesWithConfig.entries()) {
      const order = config ? config.order : superflousOrder++;

      if (order !== property.order) {
        updateInfos.push({
          type: PropertyUpdateInfoType.UPDATE_ORDER,
          property,
          order,
          displayedOrder
        });
      }
    }

    return updateInfos;
  }

  protected handleUpdatePropertiesClick(): void {
    const adapter = this.adapter;
    assertNotNullOrUndefined(
      adapter,
      "can't PropertyInputFieldListWithDefaultProperties.handleAddMissingPropertiesClick without adapter"
    );

    const createdProperties: Array<TPropertyType> = [];

    for (const info of this.updateInfos) {
      switch (info.type) {
        case PropertyUpdateInfoType.CREATE:
          createdProperties.push(adapter.createPropertyFromConfig(info.config));
          break;

        case PropertyUpdateInfoType.UPDATE_ORDER:
          info.property.order = info.order;
          this.entityManager.propertyRepository.update(info.property);
          break;

        default:
          throw new Error(
            `unhandled type ${
              (
                info as PropertyUpdateInfo<
                  TPropertyType,
                  TDefaultPropertyConfigType
                >
              ).type
            }`
          );
      }
    }

    this.properties.push(...createdProperties);
    this.sortProperties(this.properties);

    this.updateInfos = [];
    this.needsToUpdate = false;
  }

  private sortProperties(properties: Array<TPropertyType>): void {
    properties.sort((a, b) => {
      const result = (a.order ?? 0) - (b.order ?? 0);
      if (result !== 0) {
        return result;
      }

      return a.created - b.created;
    });
  }

  protected handleAfterPropertyChanged(event: AfterPropertyChangedEvent): void {
    this.adapter?.afterPropertyModified?.(
      event.detail.property as TPropertyType
    );
  }

  protected getPropertyInputFieldId(propertyId: string): string {
    return `property-input-field-list-with-default-properties--${this.id}-property-${propertyId}`;
  }
}

enum PropertyUpdateInfoType {
  CREATE = 'create',
  UPDATE_ORDER = 'updateOrder'
}

type BasePropertyUpdateInfo<
  TType extends PropertyUpdateInfoType,
  TAdditionalInfo extends Record<string, any>
> = { type: TType } & TAdditionalInfo;

type CreatePropertyUpdateInfo<
  TDefaultPropertyConfigType extends TDefaultPropertyConfig
> = BasePropertyUpdateInfo<
  PropertyUpdateInfoType.CREATE,
  {
    config: CreationConfig<TDefaultPropertyConfigType>;
  }
>;

type UpdateOrderPropertyUpdateInfo<TPropertyType extends Property> =
  BasePropertyUpdateInfo<
    PropertyUpdateInfoType.UPDATE_ORDER,
    {
      property: TPropertyType;
      order: number;
      displayedOrder: number;
    }
  >;

type PropertyUpdateInfo<
  TPropertyType extends Property,
  TDefaultPropertyConfigType extends TDefaultPropertyConfig
> =
  | CreatePropertyUpdateInfo<TDefaultPropertyConfigType>
  | UpdateOrderPropertyUpdateInfo<TPropertyType>;

type PropertyWithConfig<
  TPropertyType extends Property,
  TDefaultPropertyConfigType extends TDefaultPropertyConfig
> = {
  property: TPropertyType;
  config: CreationConfig<TDefaultPropertyConfigType> | null;
};
