import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createUserDefinedEntityConfigPropertyConfigEntityInfo } from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/createUserDefinedEntityConfigPropertyConfigEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { UserDefinedEntityConfigPropertyConfig } from './types';

export const userDefinedEntityConfigPropertyConfigEntityInfo =
  createUserDefinedEntityConfigPropertyConfigEntityInfo<UserDefinedEntityConfigPropertyConfig>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
