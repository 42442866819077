import { ProcessTaskAppointmentToUserDto } from '../Types/Entities/ProcessTaskAppointmentToUser/ProcessTaskAppointmentToUserDto';

export class ProcessTaskAppointmentToUserHelper {
  private constructor() {}

  public static getTimespan<
    T extends ProcessTaskAppointmentToUserDto<any, any>
  >(
    relations: Array<T>,
    convertRelationDate: (date: T['dateFrom'] | T['dateTo']) => string | null
  ): { dateFrom: string | null; dateTo: string | null } {
    const dateFroms = relations
      .map((r) => convertRelationDate(r.dateFrom))
      .filter((i): i is string => i != null)
      .sort((a, b) => a.localeCompare(b));
    const dateTos = relations
      .map((r) => convertRelationDate(r.dateTo))
      .filter((i): i is string => i != null)
      .sort((a, b) => a.localeCompare(b));

    return {
      dateFrom: dateFroms[0] ?? null,
      dateTo: dateTos[dateTos.length - 1] ?? null
    };
  }
}
