import { assertNotNullOrUndefined } from 'common/Asserts';
import { CoordinateHelper } from '../../../../classes/CoordinateHelper';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { PictureProcessTaskToProjectType } from '../../../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectRepository';
import { ProcessTaskProjectService } from '../../../ProcessTaskProjectService';
import {
  ExecuteOptions,
  PictureCapturedHandler,
  PictureCapturedHandlerOptions
} from '../PictureCapturedHandler';

export class CapturedProcessTaskPictureHandler
  implements PictureCapturedHandler
{
  private readonly entityManager: AppEntityManager;
  private readonly processTaskProjectService: ProcessTaskProjectService;
  private readonly data: CapturedProcessTaskPictureHandlerData;

  constructor({
    entityManager,
    processTaskProjectService,
    data
  }: PictureCapturedHandlerOptions<CapturedProcessTaskPictureHandlerData>) {
    this.entityManager = entityManager;
    this.processTaskProjectService = processTaskProjectService;
    this.data = data;
  }

  public async execute({ dataUrl }: ExecuteOptions): Promise<void> {
    const processTask = this.getProcessTask();
    const processTaskAppointment = this.getProcessTaskAppointment();

    const { creator } = this.processTaskProjectService.prepareImageCreation({
      processTask,
      processTaskAppointment,
      processTaskToProjectType: this.data.processTaskToProjectType
    });

    const picture = creator.createPictureFromDataUrl(dataUrl);
    if (picture) {
      this.addCoordsToPicture(picture);
    }
  }

  private getProcessTask(): ProcessTask {
    let processTask = this.entityManager.processTaskRepository.getById(
      this.data.processTaskId
    );

    if (!processTask) {
      processTask = this.entityManager.processTaskRepository.getByOriginalId(
        this.data.processTaskId
      );
    }

    assertNotNullOrUndefined(
      processTask,
      `no processTask found for ${this.data.processTaskId}`
    );
    return processTask;
  }

  private getProcessTaskAppointment(): ProcessTaskAppointment | null {
    if (!this.data.processTaskAppointmentId) {
      return null;
    }

    let processTaskAppointment =
      this.entityManager.processTaskAppointmentRepository.getById(
        this.data.processTaskAppointmentId
      );

    if (!processTaskAppointment) {
      processTaskAppointment =
        this.entityManager.processTaskAppointmentRepository.getByOriginalId(
          this.data.processTaskAppointmentId
        );
    }

    assertNotNullOrUndefined(
      processTaskAppointment,
      `no processTaskAppointment found for ${this.data.processTaskAppointmentId}`
    );
    return processTaskAppointment;
  }

  private addCoordsToPicture(picture: Picture): void {
    const coordinates = CoordinateHelper.getClientCoordinates();
    if (coordinates.latitude && coordinates.longitude) {
      picture.coords = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude
      };
    }
  }
}

export type CapturedProcessTaskPictureHandlerData = {
  processTaskId: string;
  processTaskAppointmentId: string | null;
  processTaskToProjectType: PictureProcessTaskToProjectType | null;
};
