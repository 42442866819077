import { TupleArray, TupleArrayWithOtherType } from 'common/Types/utilities';

/**
 * A cache which maps an array to values.
 * If the same item will be mapped again, the previous value will be used
 */
export class ArrayMapCache<TItem, TMappedItem> {
  private readonly createMappedItem: CreateMappedItem<TItem, TMappedItem>;
  private readonly onItemRemoved: OnItemRemoved<TItem, TMappedItem> | null;
  private readonly itemToMappedItem = new Map<TItem, TMappedItem>();

  constructor(options: ArrayMapCacheOptions<TItem, TMappedItem>) {
    this.createMappedItem = options.createMappedItem;
    this.onItemRemoved = options.onItemRemoved ?? null;
  }

  /**
   * Maps the items to the mappedItems.
   * If there already is an mappeditem, then the existing one will be reused.
   * If there is none, a new one will be generated.
   *
   * MappedItems for items which are not in this array will be discarded.
   */
  public mapItems<TItems extends Array<TItem>>({
    items
  }: {
    items: TupleArray<TItems>;
  }): TupleArrayWithOtherType<TItems, TMappedItem> {
    const mappedItems = this.getOrCreateMappedItemsForItems<TItems>({ items });

    this.removeUnusedItems({
      usedItems: items
    });

    return mappedItems;
  }

  /**
   * Gets the MappedItem for the Item.
   * The MappedItem will be cached and considered in the next mapItems call
   *
   * This is useful if you need the MappedItem before the next mapItems call but you also want it to be cached
   */
  public mapItem({ item }: { item: TItem }): TMappedItem {
    return this.getOrCreateMappedItem({ item });
  }

  /**
   * Removes all items from the cache and disposes them
   */
  public clear(): void {
    for (const item of Array.from(this.itemToMappedItem.keys())) {
      this.removeItem({ item });
    }
  }

  private getOrCreateMappedItemsForItems<TItems extends Array<TItem>>({
    items
  }: {
    items: TupleArray<TItems>;
  }): TupleArrayWithOtherType<TItems, TMappedItem> {
    const mappedItems = items.map((item) => {
      return this.getOrCreateMappedItem({
        item
      });
    }) as TupleArrayWithOtherType<TItems, TMappedItem>;

    return mappedItems;
  }

  private getOrCreateMappedItem({ item }: { item: TItem }): TMappedItem {
    let mappedItem = this.itemToMappedItem.get(item);

    if (!mappedItem) {
      mappedItem = this.createMappedItem({ item });
      this.itemToMappedItem.set(item, mappedItem);
    }

    return mappedItem;
  }

  private removeUnusedItems({ usedItems }: { usedItems: Array<TItem> }): void {
    const entries = Array.from(this.itemToMappedItem.keys());
    const usedItemsSet = new Set(usedItems);

    for (const item of entries) {
      if (!usedItemsSet.has(item)) {
        this.removeItem({ item });
      }
    }
  }

  private removeItem({ item }: { item: TItem }): void {
    const mappedItem = this.itemToMappedItem.get(item);
    this.itemToMappedItem.delete(item);

    if (mappedItem) {
      this.onItemRemoved?.({ item, mappedItem });
    }
  }
}

export type ArrayMapCacheOptions<TItem, TMappedItem> = {
  createMappedItem: CreateMappedItem<TItem, TMappedItem>;

  /**
   * if an item gets removed from the cache, this will be called with the item
   */
  onItemRemoved?: OnItemRemoved<TItem, TMappedItem>;
};

export type CreateMappedItem<TItem, TMappedItem> = (options: {
  item: TItem;
}) => TMappedItem;

export type OnItemRemoved<TItem, TMappedItem> = (options: {
  item: TItem;
  mappedItem: TMappedItem;
}) => void;
