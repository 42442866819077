import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProjectDto } from './ProjectDto';

export function createProjectEntityInfo<
  TEntity extends ProjectDto<string, string>
>(
  options: CreateEntityInfoOptions<ProjectDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Project,
  TEntity
> {
  return {
    entityName: EntityName.Project,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProjectDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        customId: {
          name: 'customId',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        archived: {
          name: 'archived',
          defaultValue: false
        },
        usergroup: {
          name: 'usergroup',
          ref: EntityName.UserGroup,
          cascadeDeleteLocally: true
        },
        importnotes: {
          name: 'importnotes',
          innerInfo: [
            {
              tag: {
                name: 'tag'
              },
              value: {
                name: 'value'
              }
            }
          ],
          defaultValue: null
        },
        customTagsInEntries: {
          name: 'customTagsInEntries',
          defaultValue: () => []
        },
        longitude: {
          name: 'longitude',
          defaultValue: null
        },
        latitude: {
          name: 'latitude',
          defaultValue: null
        },
        template_name: {
          name: 'template_name',
          defaultValue: null
        },
        structureTemplateId: {
          name: 'structureTemplateId',
          defaultValue: null,
          ref: EntityName.StructureTemplate
        },
        projectType: {
          name: 'projectType'
        },
        report_type: {
          name: 'report_type',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        thing: {
          name: 'thing',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        },
        pictureCount: {
          name: 'pictureCount',
          defaultValue: null
        },
        sequenceNumber: {
          name: 'sequenceNumber',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
