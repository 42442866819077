import { HooksContainer } from '@record-it-npm/synchro-client';
import { EntityName } from '../../../../../../common/src/Types/Entities/Base/ClientEntityName';
import { GlobalLoadingOverlay } from '../../../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { Logger } from '../../../Logger/Logger';
import {
  Disposable,
  DisposableContainer
} from '../../../Utils/DisposableContainer';
import { PendingPromiseMap } from '../../../Utils/PendingPromiseMap';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';

export class JoinedDefectsManager {
  private started: boolean = false;
  private joinedDefectIds: Array<string> = [];
  private disposableContainer: DisposableContainer = new DisposableContainer();
  private finishedAutoJoiningDefectGroups: boolean = true;

  private readonly hooksContainer: HooksContainer<JoinedDefectManagerHooks> =
    new HooksContainer();

  private readonly pendingJoinPromiseMap: PendingPromiseMap<string, void> =
    new PendingPromiseMap();

  private readonly pendingLeavePromiseMap: PendingPromiseMap<string, void> =
    new PendingPromiseMap();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly options: Options
  ) {}

  public start(): void {
    this.started = true;
    this.autoJoinDefects();

    const boundUpdateDefectGroups = Utils.rateLimitFunction(
      this.updateDefects.bind(this, true),
      100
    );
    this.disposableContainer.add(boundUpdateDefectGroups.toCancelDisposable());
    const boundNoActualizeUpdateDefectGroups = Utils.rateLimitFunction(
      this.updateDefects.bind(this, false),
      100
    );
    this.disposableContainer.add(
      boundNoActualizeUpdateDefectGroups.toCancelDisposable()
    );

    this.disposableContainer.add({
      dispose: () => {
        boundUpdateDefectGroups.callImmediatelyIfPending();
      }
    });

    this.disposableContainer.add(
      this.entityManager.defectRepository.registerHooks({
        afterEntityUpdatedLocally: boundUpdateDefectGroups,
        afterEntityUpdated: boundUpdateDefectGroups,
        afterEntityDeleted: boundUpdateDefectGroups,
        afterEntityRemovedLocally: boundUpdateDefectGroups
      })
    );

    this.disposableContainer.add(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: boundNoActualizeUpdateDefectGroups
      })
    );

    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Defect,
        this.handleDefectIdUpgraded.bind(this)
      )
    );
  }

  public stop(): void {
    this.started = false;

    this.disposableContainer.disposeAll();
    this.pendingJoinPromiseMap.clear();
    this.pendingLeavePromiseMap.clear();
    this.joinedDefectIds = [];
  }

  public isStarted(): boolean {
    return this.started;
  }

  public async joinAllDefects(): Promise<void> {
    if (!this.started) {
      throw new Error(
        'cannot use the joinedDefectsManager if it is not started'
      );
    }

    GlobalLoadingOverlay.setLoadingState(this, true);
    await this.updateDefects(true);
  }

  public autoJoiningDefectsIsFinished(): boolean {
    return this.finishedAutoJoiningDefectGroups;
  }

  public registerHooks(hooks: JoinedDefectManagerHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  private autoJoinDefects(): void {
    void this.updateDefects(false).then(() => {
      this.finishedAutoJoiningDefectGroups = true;
      this.hooksContainer.callHooks('autoJoiningDefectsFinished');
    });
  }

  private async updateDefects(actualizeAfterJoining: boolean): Promise<void> {
    const relevantDefects = this.entityManager.defectRepository.getAll();

    const missingIds = relevantDefects
      .filter((d) => this.joinedDefectIds.indexOf(d.id) === -1)
      .filter((d) => !d.onlyLocal)
      .map((d) => d.id);

    const superfluousIds = this.joinedDefectIds.filter(
      (id) => !relevantDefects.find((d) => d.id === id)
    );

    this.updateRelativeSynchronizationEntityIds(missingIds, superfluousIds);

    const promises = [
      ...missingIds.map((id) =>
        this.pendingJoinPromiseMap.getOrCreate(id, () => this.joinDefect(id))
      ),
      ...superfluousIds.map((id) =>
        this.pendingLeavePromiseMap.getOrCreate(id, () => this.leaveDefect(id))
      )
    ];

    try {
      await Promise.all(promises);
      if (promises.length && actualizeAfterJoining) {
        void this.entityManager.entityActualization.actualize();
      }
    } catch (error) {
      Logger.logError({ error });
    } finally {
      GlobalLoadingOverlay.setLoadingState(this, false);
    }
  }

  private handleDefectIdUpgraded(): void {
    for (const id of this.joinedDefectIds) {
      const originalDefect =
        this.entityManager.defectRepository.getByOriginalId(id);
      if (originalDefect) {
        void this.leaveDefect(id);
        void this.joinDefect(originalDefect.id);
      }
    }
  }

  private async joinDefect(defectId: string): Promise<void> {
    try {
      await this.options.joinDefect(defectId);
      if (!this.joinedDefectIds.includes(defectId)) {
        this.joinedDefectIds.push(defectId);
      }
    } catch (e) {
      // continue regardless of error
    }
  }

  private async leaveDefect(defectId: string): Promise<void> {
    await this.options.leaveDefect(defectId);

    const index = this.joinedDefectIds.indexOf(defectId);
    if (index >= 0) {
      this.joinedDefectIds.splice(index, 1);
    }
  }

  private updateRelativeSynchronizationEntityIds(
    idsToAdd: Array<string>,
    idsToRemove: Array<string>
  ): void {
    for (const id of idsToAdd) {
      this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
        EntityName.Defect,
        id
      );
    }

    for (const id of idsToRemove) {
      this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
        EntityName.Defect,
        id
      );
    }
  }
}

export type Options = {
  joinDefect: (defectId: string) => Promise<void>;
  leaveDefect: (defectId: string) => Promise<void>;
};

export type JoinedDefectManagerHooks = {
  autoJoiningDefectsFinished?: () => void;
};
