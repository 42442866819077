import { EntityInfo } from '@record-it-npm/synchro-common';
import { userDefinedEntityConfigEntityInfo } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfig/userDefinedEntityConfigEntityInfo';
import { UserDefinedEntityConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';

export class UserDefinedEntityConfigAdapter
  implements EntityAdapter<UserDefinedEntityConfig>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private editableUserGroupIds: Set<string> = new Set();
  private currentUser: User | null = null;

  constructor(options: UserDefinedEntityConfigAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(
      userDefinedEntityConfig
    );
  }

  public canEditField(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(
      userDefinedEntityConfig
    );
  }

  public canEditProperties(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): boolean {
    return this.canAdministerUserDefinedEntitiesInUserGroup(
      userDefinedEntityConfig
    );
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.UserDefinedEntityConfig,
    UserDefinedEntityConfig
  > {
    return userDefinedEntityConfigEntityInfo;
  }

  private entityIsEditableUserGroup(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): boolean {
    return this.editableUserGroupIds.has(
      userDefinedEntityConfig.ownerUserGroupId
    );
  }

  private usercanAdministerUserDefinedEntities(): boolean {
    return this.currentUser
      ? PermissionHelper.userHasPermission(
          this.currentUser,
          'canAdministerUserDefinedEntities'
        )
      : false;
  }

  private canAdministerUserDefinedEntitiesInUserGroup(
    userDefinedEntityConfig: UserDefinedEntityConfig
  ): boolean {
    return (
      this.entityIsEditableUserGroup(userDefinedEntityConfig) &&
      this.usercanAdministerUserDefinedEntities()
    );
  }
}

export type UserDefinedEntityConfigAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
