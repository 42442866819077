import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { SettingKeys, SettingsUnion } from 'common/Settings/Settings';

import { EditSettingDialog } from '../../dialogs/edit-setting-dialog/edit-setting-dialog';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

@autoinject()
export class SettingListItem {
  @bindable() public setting: SettingsUnion | null = null;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleEditClicked(): void {
    assertNotNullOrUndefined(
      this.setting,
      'cannot edit setting if not available'
    );

    void EditSettingDialog.open({
      setting: this.setting,
      onSettingChanged: (setting) => {
        DomEventHelper.fireEvent<SettingChangedEvent>(this.domElement, {
          name: 'setting-changed',
          detail: setting
        });
      }
    });
  }

  protected handleResetClicked(): void {
    const setting = this.setting;
    assertNotNullOrUndefined(setting, 'no setting available to reset');

    void Dialogs.deleteDialogTk('listItems.settingListItem.resetSetting').then(
      () => {
        DomEventHelper.fireEvent<SettingResetClickedEvent>(this.domElement, {
          name: 'setting-reset-clicked',
          detail: {
            settingKey: setting.key
          }
        });
      }
    );
  }
}

export type SettingChangedEvent = NamedCustomEvent<
  'setting-changed',
  SettingsUnion
>;

export type SettingResetClickedEvent = NamedCustomEvent<
  'setting-reset-clicked',
  { settingKey: SettingKeys }
>;
