import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { SavePictureFileDataUrlService } from '../PictureFile/SavePictureFileDataUrlService';
import { Picture } from './types';
import { PictureFileByActivePictureRevisionService } from '../PictureFile/PictureFileByActivePictureRevisionService';

@autoinject()
export class PictureCopyService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService
  ) {}

  /**
   * Currently this doesn't copy markings since this would need special resolution on how they should be copied.
   * E.g. if you want to copy a picture from one entry to another, you don't really want to have markings on the parent of the original entry
   */
  public async copy<T extends Picture>({
    pictureToCopy,
    createPicture
  }: {
    pictureToCopy: Picture;
    createPicture: CreatePicture<T>;
  }): Promise<T> {
    const copiedPicture = createPicture({
      baseData: {
        description: pictureToCopy.description,
        coords: pictureToCopy.coords,
        location_info: pictureToCopy.location_info,
        takenAt: pictureToCopy.takenAt,
        additional_markings: [],
        marking: null,
        takenByUserId:
          pictureToCopy.takenByUserId ?? pictureToCopy.createdByUserId
      }
    });

    await this.copyPictureFiles({
      copiedPicture,
      pictureToCopy
    });

    return copiedPicture;
  }

  private async copyPictureFiles({
    copiedPicture,
    pictureToCopy
  }: {
    copiedPicture: Picture;
    pictureToCopy: Picture;
  }): Promise<void> {
    const pictureFilesToCopy =
      this.pictureFileByActivePictureRevisionService.getPictureFilesByPictureId(
        pictureToCopy.id
      );

    for (const pictureFileToCopy of pictureFilesToCopy) {
      if (!pictureFileToCopy.file_uploaded) {
        break;
      }

      const copiedPictureFile =
        this.entityManager.pictureFileRepository.createPictureFileForPicture({
          picture: copiedPicture,
          type: pictureFileToCopy.type,
          extension: pictureFileToCopy.file_extension,
          pictureRevisionId: null
        });

      await this.savePictureFileDataUrlService.copyPictureFileFile(
        pictureFileToCopy,
        copiedPictureFile
      );
    }
  }
}

export type PictureBaseData = Pick<
  Picture,
  | 'description'
  | 'location_info'
  | 'coords'
  | 'takenAt'
  | 'marking'
  | 'additional_markings'
  | 'takenByUserId'
>;

export type CreatePicture<T extends Picture> = (options: {
  baseData: PictureBaseData;
}) => T;
