import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ProjectType,
  structureProjectTypes
} from 'common/Types/Entities/Project/ProjectDto';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PageSwitcher } from '../page-switcher/page-switcher';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { RecordItModuleHelper } from '../../classes/RecordItModuleHelper';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { allKeysOfUnion } from 'common/Types/typeUtils';

@autoinject()
export class CreateProjectButton {
  @bindable public thing: Thing | null = null;

  @bindable public floatingLabelButton = false;

  /**
   * Translation for the label displayed on the button.
   */
  @bindable public labelTk =
    'aureliaComponents.createProjectButton.buttonLabel';

  /**
   * Project types the user is allowed to choose from.
   *
   * All project types the user does not have access to and which are not included in this array
   * are not shown to the user.
   */
  @bindable public includedProjectTypes: Array<AvailableProjectType> = [
    ...allAvailableProjectTypes
  ];

  /** used in view */
  protected domElement: HTMLElement;
  protected pageSwitcher: PageSwitcher | null = null;

  protected reportTypeGroups: Array<ReportTypeGroup> = [];

  protected structureTemplates: Array<StructureTemplate> = [];

  private subscriptionManager: SubscriptionManager;

  protected projectTypes: Array<AvailableProjectType> = [];
  private selectedProjectType: AvailableProjectType | null = null;
  private selectedStructureTemplate: StructureTemplate | null = null;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private currentUserService: CurrentUserService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateProjectTypes.bind(this)
      )
    );
    this.updateProjectTypes(this.currentUserService.getCurrentUser());

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      this.updateReportTypeGroups.bind(this)
    );
    this.updateReportTypeGroups();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      this.updateStructureTemplates.bind(this)
    );
    this.updateStructureTemplates();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Updater **********

  private updateProjectTypes(currentUser: User | null): void {
    if (!currentUser) {
      this.projectTypes = [];
      return;
    }

    const projectTypes: Array<AvailableProjectType> = [];

    const modules = PermissionHelper.getAvailableModulesForUser(currentUser);
    for (const moduleName of modules) {
      const config =
        RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
      assertNotNullOrUndefined(config, `module '${moduleName}' is not defined`);

      if (
        !config.projectType ||
        config.projectType === ProjectType.GALLERY ||
        config.projectType === ProjectType.OPERATIONS ||
        config.projectType === ProjectType.CHECKLIST ||
        !this.includedProjectTypes.includes(config.projectType)
      )
        continue;

      projectTypes.push(config.projectType);
    }

    this.projectTypes = projectTypes;
  }

  private updateReportTypeGroups(): void {
    const reportTypes = this.entityManager.reportTypeRepository.getAll();
    const reportTypeGroups: Array<ReportTypeGroup> = [];
    for (const reportType of reportTypes) {
      const reportTypeType = reportType.type || 'Sonstige';
      const group = reportTypeGroups.find(
        (g) => g.groupName === reportTypeType
      );
      if (group) {
        group.reportTypes.push(reportType);
      } else {
        reportTypeGroups.push({
          groupName: reportTypeType,
          reportTypes: [reportType]
        });
      }
    }
    reportTypeGroups.sort((rTG1, rTG2) =>
      rTG1.groupName.localeCompare(rTG2.groupName)
    );
    this.reportTypeGroups = reportTypeGroups;
  }

  private updateStructureTemplates(): void {
    if (
      this.thing &&
      (this.selectedProjectType === ProjectType.INSPECT ||
        this.selectedProjectType === ProjectType.B1300)
    ) {
      this.structureTemplates =
        this.entityManager.structureTemplateRepository.getActiveTemplatesByProjectType(
          this.selectedProjectType
        );
    } else {
      this.structureTemplates = [];
    }
  }

  // ********** Handlers **********

  protected handleProjectTypeSelected(projectType: AvailableProjectType): void {
    this.selectedProjectType = projectType;

    if (structureProjectTypes().includes(projectType)) {
      this.updateStructureTemplates();
      this.pageSwitcher?.switchToPage(Pages.STRUCTURE_TEMPlATE);
    } else {
      this.pageSwitcher?.switchToPage(Pages.REPORT_TYPE);
    }
  }

  protected handleStructureTemplateSelected(
    structureTemplate: StructureTemplate
  ): void {
    this.selectedStructureTemplate = structureTemplate;
    this.pageSwitcher?.switchToPage(Pages.REPORT_TYPE);
  }

  protected handleReportTypeSelected(reportType?: ReportType): void {
    assertNotNullOrUndefined(
      this.selectedProjectType,
      'cannot create project without a project type'
    );
    let eventDetail: CreateProjectClickedEventDetail;
    if (this.selectedProjectType === ProjectType.BASIC) {
      eventDetail = {
        projectType: this.selectedProjectType,
        reportType: reportType
      };
    } else {
      assertNotNullOrUndefined(
        this.selectedStructureTemplate,
        'cannot create a structure project without a structure template'
      );
      eventDetail = {
        projectType: this.selectedProjectType,
        structureTemplate: this.selectedStructureTemplate,
        reportType: reportType
      };
    }
    DomEventHelper.fireEvent<CreateProjectClickedEvent>(this.domElement, {
      name: 'create-project-clicked',
      detail: eventDetail
    });
    this.reset();
  }

  protected handleGoBackToPreviousPage(): void {
    if (
      this.pageSwitcher?.currentPageName === Pages.REPORT_TYPE &&
      this.isStructureProjectTypeSelected
    ) {
      this.pageSwitcher?.switchToPage(Pages.STRUCTURE_TEMPlATE);
    } else {
      this.pageSwitcher?.switchToPage(Pages.PROJECT_TYPE);
    }
  }

  protected handleDropdownClosed(): void {
    this.reset();
  }

  // ********** Getters **********

  private get isStructureProjectTypeSelected(): boolean {
    return (
      !!this.selectedProjectType &&
      structureProjectTypes().includes(this.selectedProjectType)
    );
  }

  // ********** Private Methods **********

  private reset(): void {
    this.selectedProjectType = null;
    this.selectedStructureTemplate = null;
    this.pageSwitcher?.switchToPage(Pages.PROJECT_TYPE);
  }
}

const allAvailableProjectTypes = allKeysOfUnion<AvailableProjectType>()([
  ProjectType.BASIC,
  ProjectType.B1300,
  ProjectType.INSPECT
]);

type AvailableProjectType =
  | ProjectType.BASIC
  | ProjectType.B1300
  | ProjectType.INSPECT;

export type CreateProjectClickedEvent = NamedCustomEvent<
  'create-project-clicked',
  CreateProjectClickedEventDetail
>;

type CreateProjectClickedEventDetail =
  | CreateProjectClickedEventDetailBasicProject
  | CreateProjectClickedEventDetailStructureProject;

type CreateProjectClickedEventDetailBasicProject = {
  projectType: ProjectType.BASIC;
  reportType?: ReportType;
};

type CreateProjectClickedEventDetailStructureProject = {
  projectType: ProjectType.B1300 | ProjectType.INSPECT;
  structureTemplate: StructureTemplate;
  reportType?: ReportType;
};

type ReportTypeGroup = {
  groupName: string;
  reportTypes: Array<ReportType>;
};

enum Pages {
  PROJECT_TYPE = 'project-type',
  STRUCTURE_TEMPlATE = 'structure-template',
  REPORT_TYPE = 'report-type'
}
