export class StringToNumberValueConverter {
  public toView(value: string | null): number | null {
    if (!value) {
      return null;
    }

    return Number(value);
  }

  public fromView(value: number | null): string | null {
    if (value == null) {
      return null;
    }

    return value.toString();
  }
}
