import { Utils } from '../Utils';

export class SequenceNumberUtils {
  private static prefixByType: Record<SequenceNumberType, string> = {
    offer: 'A',
    invoice: 'R'
  };

  private constructor() {}

  public static formatOptionalSequenceNumber(
    type: SequenceNumberType,
    sequenceNumber: number | null,
    fallback: string
  ): string {
    return sequenceNumber != null
      ? this.formatSequenceNumber(type, sequenceNumber)
      : fallback;
  }

  public static formatSequenceNumber(
    type: SequenceNumberType,
    sequenceNumber: number
  ): string {
    return (
      this.prefixByType[type] + Utils.leftPad(sequenceNumber.toString(), 5, '0')
    );
  }
}

export enum SequenceNumberType {
  OFFER = 'offer',
  INVOICE = 'invoice'
}
