export class SingleArrayElementValueConverter {
  /**
   * @param {Array<*>|null} value
   * @returns {*}
   */
  toView(value) {
    return value && value[0];
  }

  /**
   * @param {*} value
   * @returns {Array<*>}
   */
  fromView(value) {
    return [value];
  }
}
