import { DateUtils } from 'common/DateUtils';
import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskCreationService } from '../../../classes/EntityManager/entities/ProcessTask/ProcessTaskCreationService';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { ProcessTaskInvoiceToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskPosition/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskOfferToProcessTask } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTask/types';
import { ProcessTaskOfferToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types';
import { ProcessTaskOfferToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { Utils } from '../../../classes/Utils/Utils';
import { ProcessTaskAppointmentDateInfoMap } from '../../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import {
  DirectRelatedEntityData,
  DirectRelatedEntityDataFetcher
} from './DirectRelatedEntityDataFetcher';
import { ProcessTaskOfferMover } from './ProcessTaskOfferMover';

/**
 * handles the extraction for a single process task
 */
export class ProcessTaskOfferPartialExtractor {
  private offerMover: ProcessTaskOfferMover;
  private processTaskPositionsData: DirectRelatedEntityData<
    ProcessTaskPosition,
    ProcessTaskOfferToProcessTaskPosition,
    ProcessTaskInvoiceToProcessTaskPosition
  >;
  private processTaskDevicesData: DirectRelatedEntityData<
    ProcessTaskDevice,
    ProcessTaskOfferToProcessTaskDevice,
    ProcessTaskInvoiceToProcessTaskDevice
  >;

  constructor(
    private readonly processTaskOffer: ProcessTaskOffer,
    private readonly processTaskOfferToProcessTask: ProcessTaskOfferToProcessTask,
    private readonly processTask: ProcessTask,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskCreationService: ProcessTaskCreationService,
    private readonly processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap
  ) {
    this.offerMover = new ProcessTaskOfferMover(entityManager);

    this.processTaskPositionsData = this.fetchProcessTaskPositionsData();

    this.processTaskDevicesData = this.fetchProcessTaskDevicesData();
  }

  public getProcessTask(): ProcessTask {
    return this.processTask;
  }

  public validate(): Array<ValidationError> {
    const errors: Array<ValidationError> = [];

    this.validateInvoices(
      errors,
      'operations.extractProcessTaskOfferDialog.error.positionsInInvoice',
      this.processTaskPositionsData
    );
    this.validateOtherOffers(
      errors,
      'operations.extractProcessTaskOfferDialog.error.positionsInOffer',
      this.processTaskPositionsData
    );
    this.validateAppointments(
      errors,
      'operations.extractProcessTaskOfferDialog.error.positionsInAppointment',
      this.processTaskPositionsData
    );

    this.validateInvoices(
      errors,
      'operations.extractProcessTaskOfferDialog.error.devicesInInvoice',
      this.processTaskDevicesData
    );
    this.validateOtherOffers(
      errors,
      'operations.extractProcessTaskOfferDialog.error.devicesInOffer',
      this.processTaskDevicesData
    );
    this.validateAppointments(
      errors,
      'operations.extractProcessTaskOfferDialog.error.devicesInAppointment',
      this.processTaskDevicesData
    );

    return errors;
  }

  public extract(newProcessTaskName: string): ProcessTask {
    const newProcessTask = this.processTaskCreationService.createProcessTask({
      thingId: this.processTask.thingId,
      currentProcessConfigurationStepId:
        this.getCurrentProcessConfigurationStepId(),
      name: newProcessTaskName,
      ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
      ownerUserGroupId: this.processTask.ownerUserGroupId
    });

    this.offerMover.move(
      this.processTask,
      newProcessTask,
      this.processTaskOfferToProcessTask,
      this.processTaskPositionsData,
      this.processTaskDevicesData
    );

    return newProcessTask;
  }

  public getProcessTaskPositionCount(): number {
    return this.processTaskPositionsData.entities.length;
  }

  public getProcessTaskDevicesCount(): number {
    return this.processTaskDevicesData.entities.length;
  }

  private getCurrentProcessConfigurationStepId(): string {
    const offer = this.entityManager.processTaskOfferRepository.getById(
      this.processTaskOfferToProcessTask.processTaskOfferId
    );
    const category = offer?.processConfigurationCategoryId
      ? this.entityManager.processConfigurationCategoryRepository.getById(
          offer.processConfigurationCategoryId
        )
      : null;
    const defaultProcessConfigurationStepId =
      category?.defaultProcessConfigurationStepId;

    return (
      defaultProcessConfigurationStepId ??
      this.processTask.currentProcessConfigurationStepId
    );
  }

  /**
   * @param errors - gets modified in place
   */
  private validateInvoices(
    errors: Array<ValidationError>,
    translationKey: string,
    data: DirectRelatedEntityData<any, any, any>
  ): void {
    if (data.invoices.length) {
      errors.push({
        tk: translationKey,
        tkParams: { count: data.invoices.length },
        description: data.invoices
          .map((invoice) => {
            return `${
              invoice.name ?? ''
            } - ${SequenceNumberUtils.formatOptionalSequenceNumber(
              SequenceNumberType.INVOICE,
              invoice.globalSequenceNumber,
              invoice.serialNumber ?? ''
            )}`;
          })
          .join('; ')
      });
    }
  }

  /**
   * @param errors - gets modified in place
   */
  private validateOtherOffers(
    errors: Array<ValidationError>,
    translationKey: string,
    data: DirectRelatedEntityData<any, any, any>
  ): void {
    if (data.otherOffers.length) {
      errors.push({
        tk: translationKey,
        tkParams: { count: data.otherOffers.length },
        description: data.otherOffers
          .map((offer) => {
            return `${
              offer.name ?? ''
            } - ${SequenceNumberUtils.formatOptionalSequenceNumber(
              SequenceNumberType.OFFER,
              offer.globalSequenceNumber,
              ''
            )}`;
          })
          .join('; ')
      });
    }
  }

  /**
   * @param errors - gets modified in place
   */
  private validateAppointments(
    errors: Array<ValidationError>,
    translationKey: string,
    data: DirectRelatedEntityData<any, any, any>
  ): void {
    if (data.appointments.length) {
      errors.push({
        tk: translationKey,
        tkParams: { count: data.appointments.length },
        description: data.appointments
          .map((appointment) => {
            const parts: Array<string> = [];
            if (appointment.name) {
              parts.push(appointment.name);
            }

            const dateInfo = this.processTaskAppointmentDateInfoMap.get(
              appointment.id
            );
            if (dateInfo?.dateFrom) {
              parts.push(
                DateUtils.formatToDateWithHourMinuteString(dateInfo.dateFrom)
              );
            }
            return parts.join(' - ');
          })
          .join('; ')
      });
    }
  }

  private fetchProcessTaskPositionsData(): DirectRelatedEntityData<
    ProcessTaskPosition,
    ProcessTaskOfferToProcessTaskPosition,
    ProcessTaskInvoiceToProcessTaskPosition
  > {
    const positions = this.entityManager.processTaskPositionRepository.getAll();
    /**
     * The value is an array of the position and all snapshots of it.
     * The key is the id of originating position. If the position is a snapshot, it will be stored in the key of the snapshotted position
     */
    const positionsWithSnapshotsPreparationDataByPositionId = Utils.groupBy(
      positions,
      (position) => position.snapshotOfProcessTaskPositionId ?? position.id
    );

    /**
     * Contains all relevant positions for a given position id.
     * This is necessary, for cases where a snapshot of a position is used in an invoice
     */
    const positionsWithSnapshotsByPositionId = new Map<
      string,
      Array<ProcessTaskPosition>
    >();
    for (const position of positions) {
      positionsWithSnapshotsByPositionId.set(
        position.id,
        positionsWithSnapshotsPreparationDataByPositionId.get(
          position.snapshotOfProcessTaskPositionId ?? position.id
        ) ?? []
      );
    }

    return DirectRelatedEntityDataFetcher.fetch(
      this.processTask,
      this.processTaskOffer,
      {
        entityManager: this.entityManager,
        getEntitiesByIds: (ids) =>
          this.entityManager.processTaskPositionRepository.getByIds(ids),
        getExtendedEntitiesByIds: (ids) => {
          const entities: Array<ProcessTaskPosition> = [];

          for (const id of ids) {
            entities.push(
              ...(positionsWithSnapshotsByPositionId.get(id) ?? [])
            );
          }

          return entities;
        },
        getEntityIdFromOfferRelation: (relation) =>
          relation.processTaskPositionId,
        getOfferRelationsByProcessTaskOfferId: (processTaskOfferId) =>
          this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskOfferId(
            processTaskOfferId
          ),
        getOfferRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskPositionIds(
            entityIds
          ),
        getInvoiceRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskPositionIds(
            entityIds
          ),
        getAppointmentRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskPositionIds(
            entityIds
          )
      }
    );
  }

  private fetchProcessTaskDevicesData(): DirectRelatedEntityData<
    ProcessTaskDevice,
    ProcessTaskOfferToProcessTaskDevice,
    ProcessTaskInvoiceToProcessTaskDevice
  > {
    return DirectRelatedEntityDataFetcher.fetch(
      this.processTask,
      this.processTaskOffer,
      {
        entityManager: this.entityManager,
        getEntitiesByIds: (ids) =>
          this.entityManager.processTaskDeviceRepository.getByIds(ids),
        getEntityIdFromOfferRelation: (relation) =>
          relation.processTaskDeviceId,
        getOfferRelationsByProcessTaskOfferId: (processTaskOfferId) =>
          this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferId(
            processTaskOfferId
          ),
        getOfferRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskDeviceIds(
            entityIds
          ),
        getInvoiceRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskDeviceIds(
            entityIds
          ),
        getAppointmentRelationsByEntityIds: (entityIds) =>
          this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskDeviceIds(
            entityIds
          )
      }
    );
  }
}

export type ValidationError = {
  tk: string;
  tkParams: Record<string, any>;
  description: string;
};
