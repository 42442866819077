import { autoinject } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';

@autoinject()
export class DateDataConverter extends AbstractLogDataConverter {
  public convert({
    logData,
    args: { datePath }
  }: ConvertOptions<'datePath'>): void {
    const value = logData[datePath];
    if (value) {
      const date = this.getDateFromUnknownValue(value);
      if (date) {
        logData[`${datePath}Time`] = DateUtils.formatToHourMinuteString(date);
      }
    }
  }

  private getDateFromUnknownValue(value: unknown): Date | null {
    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string') {
      return new Date(value);
    }
    return null;
  }
}
