import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepAutoForm } from '../../classes/EntityManager/entities/ProcessConfigurationStepAutoForm/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessConfigurationStepAutoFormsWidget {
  @bindable()
  public processConfigurationStep: ProcessConfigurationStep | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected processConfigurationStepPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStep];

  private isAttached: boolean = false;
  private autoForms: Array<ProcessConfigurationStepAutoForm> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationStepPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationStep,
        context: this as ProcessConfigurationStepAutoFormsWidget,
        propertyName: 'processConfigurationStep'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStepAutoForm,
      this.updateAutoForms.bind(this)
    );
    this.updateAutoForms();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationStepChanged(): void {
    if (this.isAttached) {
      this.updateAutoForms();
    }
  }

  private updateAutoForms(): void {
    if (this.processConfigurationStep) {
      this.autoForms =
        this.entityManager.processConfigurationStepAutoFormRepository.getByProcessConfigurationStepId(
          this.processConfigurationStep.id
        );
    } else {
      this.autoForms = [];
    }
  }

  protected handleCreateAutoFormClick(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      "can't ProcessConfigurationStepAutoFormsWidget.handleCreateAutoFormClick without processConfigurationStep"
    );
    const autoForm =
      this.entityManager.processConfigurationStepAutoFormRepository.create({
        ownerProcessConfigurationId:
          this.processConfigurationStep.ownerProcessConfigurationId,
        ownerUserGroupId: this.processConfigurationStep.ownerUserGroupId,
        processConfigurationStepId: this.processConfigurationStep.id
      });

    this.autoForms.push(autoForm);
  }
}
