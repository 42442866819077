import _ from 'lodash';

import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Vector } from 'common/Geometry/Vector';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { Key } from '../../classes/Key';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { Utils } from '../../classes/Utils/Utils';

/**
 * @event {SelectChangedEvent} select-changed - the value has been changed
 * @event {OptionSelectedEvent} option-selected - an option has been selected, doesn't mean that the value has changed
 *
 * @attribute data-status - styles the input accordingly to its status, possible values: warning
 * @attribute data-no-border - styles the component without border
 */
@autoinject()
export class CustomSelect<TValue, TOption> {
  private static readonly CREATE_ITEM_VALUE = Symbol('create_item');

  @bindable public value: TValue | null = null;

  /**
   * read only
   */
  @bindable public selectedOption: TOption | string | null = null;

  @bindable public enabled = false;

  @bindable public options: Array<TOption> = [];

  @bindable public optionsValuePath: string | null = null;

  @bindable public optionsLabelPath: string | null = null;

  @bindable public optionsLabelMultiplePaths: Array<string> = [];

  @bindable public optionsLabelMultiplePathsSeparator: string | null = null;

  /**
   * set this to true to translate the label gather from the option
   */
  @bindable public optionsLabelIsTranslationKey = false;

  /**
   * label of the nullOption
   */
  @bindable public nullOption: string | null = null;

  @bindable public nullOptionValue: TValue | null = null;

  @bindable public matcher: TMatcher<TValue> | null = null;

  @bindable public nameTransformationFunction:
    | ((name: string) => string)
    | null = null;

  @bindable public optionsSubLabelPath: string | null = null;

  @bindable public doNotAllowNullValue = false;

  @bindable public createCallback: CreateCallback | null = null;
  @bindable public createNewLabelTk: string | null = null;

  /**
   * look at the tooltip-content.contentWidth documentation for more info
   */
  @bindable public tooltipContentWidth: string | null = null;

  private domElement: HTMLElement;

  private wrapperElement: HTMLElement | null = null;

  private inputElement: HTMLInputElement | null = null;

  private tooltipContentViewModel: TooltipContent | null = null;

  @observable private inputText: string;
  @observable private filterString: string;

  private currentMatcher = this.getDefaultMatcher();

  private inputIsFocused = false;

  private filterStringFragments: Array<string> = [];

  @observable private availableOptions: Array<Option<TValue, TOption>>;

  private filteredOptions: Array<Option<TValue, TOption>> = [];

  private highlightedOptionValue: TValue | Symbol | null = null;

  private marginVector = new Vector(0, 0);

  private tooltipContentOpen = false;

  private userHasTyped = false;

  private dropdownTargetAlignment = new Vector(0, 1);
  private dropdownTooltipAlignment = new Vector(0, 0);

  private inputBackgroundAnimator: ShowHideAnimator | null = null;

  private setInputTextDebounced = Utils.debounceFunction(
    this.setInputText.bind(this),
    200
  );

  protected CustomSelect = CustomSelect;

  constructor(
    element: Element,
    private i18n: I18N
  ) {
    this.domElement = element as HTMLElement;

    this.inputText = '';
    this.filterString = '';
    this.availableOptions = [];
  }

  protected attached(): void {
    this.resetCustomSelect();
    if (this.wrapperElement)
      this.inputBackgroundAnimator = new ShowHideAnimator(this.wrapperElement);
  }

  public focus(): void {
    if (this.inputElement) {
      this.inputElement.focus();
      this.inputElement.select();
    }
  }

  protected valueChanged(): void {
    this.forceValueIfNecessary();
    this.updateSelectedOption();
  }

  protected optionsChanged(): void {
    this.updateAvailableOptions();
  }

  protected optionsLabelPathChanged(): void {
    this.updateAvailableOptions();
  }

  protected optionsLabelIsTranslationKeyChanged(): void {
    this.updateAvailableOptions();
  }

  protected optionsValuePathChanged(): void {
    this.updateAvailableOptions();
  }

  protected nullOptionChanged(): void {
    this.updateAvailableOptions();
    this.refreshInputText(); // input text could be the nullOption so we need to refresh it
  }

  protected nullOptionValueChanged(): void {
    this.updateAvailableOptions();
  }

  protected createCallbackChanged(): void {
    this.updateAvailableOptions();
  }

  protected matcherChanged(): void {
    if (this.matcher) {
      this.currentMatcher = this.matcher;
    } else {
      this.currentMatcher = this.getDefaultMatcher();
    }
  }

  protected availableOptionsChanged(): void {
    this.forceValueIfNecessary();
    this.updateFilteredOptions();
    this.updateSelectedOption();
  }

  protected inputTextChanged(): void {
    if (this.userHasTyped) this.filterString = this.inputText;
  }

  protected filterStringChanged(): void {
    this.createFilterStringFragments();
    this.updateFilteredOptions();
  }

  protected doNotAllowNullValueChanged(): void {
    this.forceValueIfNecessary();
  }

  private forceValueIfNecessary(): void {
    if (this.value === null && this.doNotAllowNullValue) {
      const firstOption = this.availableOptions[0] ?? null;
      this.value =
        firstOption?.type === OptionType.DEFAULT ? firstOption.value : null;
    }
  }

  private updateFilteredOptions(): void {
    if (!this.filterString) {
      this.filteredOptions = this.availableOptions ?? [];
    } else {
      this.filteredOptions = this.availableOptions.filter((option) => {
        if (option.type === OptionType.CREATE) return true;

        const testString = option.label + ' ' + option.subLabel;
        return this.filterAllStringFragments(testString);
      });
    }

    const index = this.getIndexOfOptionByValue(
      this.highlightedOptionValue,
      this.filteredOptions
    );
    if (index < 0) this.highlightedOptionValue = null;
  }

  private filterAllStringFragments(testString: string): boolean {
    return this.filterStringFragments.every((fragment) => {
      return testString.toUpperCase().indexOf(fragment) > -1;
    });
  }

  private createFilterStringFragments(): void {
    this.filterStringFragments = this.filterString.toUpperCase().split(/\s+/);
  }

  private updateSelectedOption(): void {
    const option = this.getOptionByValue(this.value, this.availableOptions);
    if (option) {
      if (option.type === OptionType.CREATE)
        throw new Error('found creation option instead of default option');
      this.selectedOption = option.option ?? null;
    } else {
      this.selectedOption = this.nullOption;
    }

    this.refreshInputText();
  }

  private openTooltipContent(): void {
    void this.inputBackgroundAnimator?.animateBackgroundColor(null, {
      red: 255,
      green: 255,
      blue: 255,
      alpha: 255
    });
    this.tooltipContentViewModel?.open();
    this.tooltipContentOpen = true;
  }

  private closeTooltipContent(): void {
    this.tooltipContentViewModel?.close();
    this.tooltipContentOpen = false;
  }

  private toggleTooltipContent(): void {
    if (this.tooltipContentOpen) {
      this.closeTooltipContent();
    } else {
      this.openTooltipContent();
    }
  }

  private handleSelectChanged(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<SelectChangedEvent<TValue, TOption>>(
        this.domElement,
        {
          name: 'select-changed',
          detail: {
            value: this.value,
            selectedOption: this.selectedOption
          }
        }
      );
    }, 0);
  }

  private handleOptionSelected(option: DefaultOption<TValue, TOption>): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<OptionSelectedEvent<TValue>>(this.domElement, {
        name: 'option-selected',
        detail: {
          value: option.value
        }
      });
    }, 0);
  }

  private handleClickOnInput(event: MouseEvent): void {
    if (!this.tooltipContentOpen) {
      if (this.inputElement) this.inputElement.select();
      this.openTooltipContent();
    }
    if (this.tooltipContentViewModel)
      this.tooltipContentViewModel.ignoreClickEvent(event);
  }

  protected handleInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.setInputTextDebounced(input.value);
  }

  private handleClickOnCaret(): void {
    this.toggleTooltipContent();
    if (this.inputElement) this.inputElement.focus();
  }

  private handleClickOnOption(option: Option<TValue, TOption>): void {
    if (option.type === OptionType.CREATE) {
      this.handleClickOnCreateNewOption();
    } else {
      if (!this.currentMatcher(this.value, option.value)) {
        this.value = option.value;
        this.handleSelectChanged();
      }

      this.handleOptionSelected(option);
    }

    this.closeTooltipContent();
  }

  private handleClickOnCreateNewOption(): void {
    this.createCallback?.(this.filterString);
  }

  private handleTooltipContentIsClosing(): void {
    this.refreshInputText();
    void this.inputBackgroundAnimator?.animateBackgroundColor(null, {
      red: 255,
      green: 255,
      blue: 255,
      alpha: 0
    });
  }

  private handleTooltipContentClosed(): void {
    this.resetCustomSelect();
    this.tooltipContentOpen = false;
  }

  private resetCustomSelect(): void {
    this.refreshInputText();
    this.filterString = '';
    this.highlightedOptionValue = this.value;
    this.userHasTyped = false;
  }

  private refreshInputText(): void {
    this.inputText = this.getLabelOfOptionByValue(this.value) || '';
  }

  private getLabelOfOptionByValue(value: TValue | null): string | null {
    const option = this.getOptionByValue(value, this.availableOptions);
    if (option) {
      return option.label;
    }
    return this.nullOption || '';
  }

  private handleMouseOverOption(option: Option<TValue, TOption>): void {
    this.highlightedOptionValue = option.value;
  }

  private handleKeyDown(event: KeyboardEvent): boolean {
    switch (event.key) {
      case Key.ESCAPE:
        this.closeTooltipContent();
        return false;
      case Key.ENTER:
        if (this.tooltipContentOpen) {
          if (this.createCallback && !this.highlightedOptionValue) {
            this.handleClickOnCreateNewOption();
          } else if (this.availableOptions.length) {
            const option = this.getOptionByValue(
              this.highlightedOptionValue,
              this.availableOptions
            );
            assertNotNullOrUndefined(
              option,
              'highlighted option does not exist'
            );
            this.handleClickOnOption(option);
          } else {
            this.closeTooltipContent();
          }
          return false;
        }
        break;
      case Key.TAB:
        this.closeTooltipContent();
        return true;
      case Key.SHIFT:
        return true;
      default:
    }

    this.openTooltipContent();

    switch (event.key) {
      case Key.ARROW_UP:
        this.selectPreviousOption();
        break;
      case Key.ARROW_DOWN:
        this.selectNextOption();
        break;
      case Key.HOME:
        this.selectFirstOption();
        break;
      case Key.END:
        this.selectLastOption();
        break;
      default:
        this.userHasTyped = true;
        return true;
    }
    return false;
  }

  private selectNextOption(): void {
    if (this.filteredOptions.length === 0) return;

    const index = this.getIndexOfOptionByValue(
      this.highlightedOptionValue,
      this.filteredOptions
    );

    if (index < 0) {
      this.highlightedOptionValue = this.filteredOptions[0]?.value ?? null;
    } else if (this.filteredOptions.length - 1 > index) {
      this.highlightedOptionValue =
        this.filteredOptions[index + 1]?.value ?? null;
    }
  }

  private selectPreviousOption(): void {
    if (this.filteredOptions.length === 0) return;

    const index = this.getIndexOfOptionByValue(
      this.highlightedOptionValue,
      this.filteredOptions
    );

    if (index < 0) {
      this.highlightedOptionValue =
        this.filteredOptions[this.filteredOptions.length - 1]?.value ?? null;
    } else if (index - 1 >= 0) {
      this.highlightedOptionValue =
        this.filteredOptions[index - 1]?.value ?? null;
    }
  }

  private selectFirstOption(): void {
    if (this.filteredOptions.length === 0) return;
    this.highlightedOptionValue = this.filteredOptions[0]?.value ?? null;
  }

  private selectLastOption(): void {
    if (this.filteredOptions.length === 0) return;
    this.highlightedOptionValue =
      this.filteredOptions[this.filteredOptions.length - 1]?.value ?? null;
  }

  private getIndexOfOptionByValue(
    value: TValue | Symbol | null,
    options: Array<Option<TValue, TOption>>
  ): number {
    return options.findIndex((o) => o.value === value);
  }

  private getOptionByValue(
    value: TValue | Symbol | null,
    options: Array<Option<TValue, TOption>>
  ): Option<TValue, TOption> | null {
    return (
      options.find((o) => {
        return this.currentMatcher(o.value, value);
      }) || null
    );
  }

  private updateAvailableOptions(): void {
    const options: Array<Option<TValue, TOption>> = [];

    if (this.nullOption && !this.doNotAllowNullValue) {
      options.push({
        label: this.nullOption,
        value: this.nullOptionValue,
        type: OptionType.DEFAULT
      });
    }

    this.options.forEach((option) => {
      const subLabelValue = this.optionsSubLabelPath
        ? _.get(option, this.optionsSubLabelPath, null)
        : null;
      const subLabel = subLabelValue ? String(subLabelValue) : null;
      options.push({
        label: this.getLabelOfOption(option),
        subLabel: subLabel,
        value: this.optionsValuePath
          ? (_.get(option, this.optionsValuePath, null) as TValue)
          : (option as unknown as TValue),
        option: option,
        type: OptionType.DEFAULT
      });
    });
    if (this.createCallback) {
      options.push({
        label: this.i18n.tr(
          this.createNewLabelTk ||
            'inputComponents.customSelect.createNewOptionLabel'
        ),
        value: CustomSelect.CREATE_ITEM_VALUE,
        type: OptionType.CREATE
      });
    }
    this.availableOptions = options;
  }

  private getLabelOfOption(option: TOption): string {
    const optionsLabelPaths = this.optionsLabelMultiplePaths.length
      ? this.optionsLabelMultiplePaths
      : [this.optionsLabelPath ?? ''];
    const labelParts = [];
    for (const optionsLabelPath of optionsLabelPaths) {
      const optionLabelPropertyValue = _.get(option, optionsLabelPath, option);
      if (!optionLabelPropertyValue) continue;
      const labelOrTkKey = String(optionLabelPropertyValue);

      let label = this.optionsLabelIsTranslationKey
        ? this.i18n.tr(labelOrTkKey)
        : labelOrTkKey;

      if (this.nameTransformationFunction) {
        label = this.nameTransformationFunction(label);
      }
      labelParts.push(label);
    }

    return labelParts.join(this.optionsLabelMultiplePathsSeparator ?? '');
  }

  private getDefaultMatcher(): TMatcher<TValue> {
    return (a, b) => {
      return a === b || (a == null && b == null);
    };
  }

  private setInputText(inputText: string): void {
    this.setInputTextDebounced.cancel();
    this.inputText = inputText;
  }
}

export type TMatcher<TValue> = (
  a: TValue | Symbol | null,
  b: TValue | Symbol | null
) => boolean;

enum OptionType {
  DEFAULT = 'default',
  CREATE = 'create'
}

type Option<TValue, TOption> = DefaultOption<TValue, TOption> | CreateOption;

type DefaultOption<TValue, TOption> = {
  label: string;
  subLabel?: string | null;
  value: TValue | null;
  option?: TOption;
  type: OptionType.DEFAULT;
};

type CreateOption = {
  label: string;
  type: OptionType.CREATE;
  value: Symbol;
};

export type SelectChangedEvent<TValue, TOption> = NamedCustomEvent<
  'select-changed',
  { value: TValue | null; selectedOption: TOption | string | null }
>;
export type OptionSelectedEvent<TValue> = NamedCustomEvent<
  'option-selected',
  { value: TValue | null }
>;

export type CreateCallback = (filterString: string) => void;
