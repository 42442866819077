import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProcessTaskOfferDto } from './ProcessTaskOfferDto';

export function createProcessTaskOfferEntityInfo<
  TEntity extends ProcessTaskOfferDto<string, string>
>(
  options: CreateEntityInfoOptions<ProcessTaskOfferDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskOffer,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskOffer,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskOfferDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
        processConfigurationCategoryId: {
          name: 'processConfigurationCategoryId',
          ref: EntityName.ProcessConfigurationCategory,
          defaultValue: null
        },
        inspectorUserId: {
          name: 'inspectorUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        introduction: {
          name: 'introduction',
          defaultValue: null
        },
        ending: {
          name: 'ending',
          defaultValue: null
        },
        excludeVat: {
          name: 'excludeVat',
          defaultValue: false
        },
        expirationDate: {
          name: 'expirationDate',
          defaultValue: null
        },
        serialNumber: {
          name: 'serialNumber',
          defaultValue: null
        },
        globalSequenceNumber: {
          name: 'globalSequenceNumber',
          defaultValue: null
        },
        acceptedAt: {
          name: 'acceptedAt',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
