import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTaskPositionDetailEntry } from '../ProcessTaskPositionDetailEntry/types';
import { PropertyCopyService } from '../Property/PropertyCopyService';
import { ProcessTaskPosition } from './types';

@autoinject()
export class ProcessTaskPositionSnapshotService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly propertyCopyService: PropertyCopyService
  ) {}

  public createSnapshot(position: ProcessTaskPosition): ProcessTaskPosition {
    const snapshot = this.entityManager.processTaskPositionRepository.create({
      ...position,
      snapshotOfProcessTaskPositionId: position.id
    });

    this.copyPropertiesToSnapshot({
      position,
      snapshot
    });

    this.copyDetailEntriesToSnapshot({
      position,
      snapshot
    });

    return snapshot;
  }

  private copyPropertiesToSnapshot({
    position,
    snapshot
  }: {
    position: ProcessTaskPosition;
    snapshot: ProcessTaskPosition;
  }): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskPositionId(
        position.id
      );
    for (const property of properties) {
      this.propertyCopyService.copy({
        newProperty: {
          processTaskPositionId: snapshot.id,
          ownerProcessTaskId: snapshot.ownerProcessTaskId,
          ownerProcessTaskGroupId: snapshot.ownerProcessTaskGroupId,
          ownerUserGroupId: snapshot.ownerUserGroupId
        },
        propertyToCopy: property
      });
    }
  }

  private copyDetailEntriesToSnapshot({
    position,
    snapshot
  }: {
    position: ProcessTaskPosition;
    snapshot: ProcessTaskPosition;
  }): void {
    const detailEntries =
      this.entityManager.processTaskPositionDetailEntryRepository.getByProcessTaskPositionId(
        position.id
      );
    for (const detailEntry of detailEntries) {
      const detailEntryCopy =
        this.entityManager.processTaskPositionDetailEntryRepository.create({
          ...detailEntry,
          processTaskPositionId: snapshot.id
        });

      this.copyDetailEntryPropertiesToSnapshot({
        detailEntryOriginal: detailEntry,
        detailEntryCopy: detailEntryCopy
      });
    }
  }

  private copyDetailEntryPropertiesToSnapshot({
    detailEntryOriginal,
    detailEntryCopy
  }: {
    detailEntryOriginal: ProcessTaskPositionDetailEntry;
    detailEntryCopy: ProcessTaskPositionDetailEntry;
  }): void {
    const detailEntryProperties =
      this.entityManager.propertyRepository.getByProcessTaskPositionDetailEntryId(
        detailEntryOriginal.id
      );
    for (const detailEntryProperty of detailEntryProperties) {
      this.entityManager.propertyRepository.create({
        ...detailEntryProperty,
        processTaskPositionDetailEntryId: detailEntryCopy.id
      });
    }
  }
}
