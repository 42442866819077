import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { NfcTokenToPerson } from './types';

export class NfcTokenToPersonRepository extends AppEntityRepository<EntityName.NfcTokenToPerson> {
  public getByPersonId(personId: string): Array<NfcTokenToPerson> {
    return this.getAll().filter((nTTP) => nTTP.personId === personId);
  }

  public getByNfcTokenId(nfcTokenId: string): Array<NfcTokenToPerson> {
    return this.getAll().filter((nTTP) => nTTP.nfcTokenId === nfcTokenId);
  }

  public deleteByPersonId(personId: string): void {
    for (const nfcTokenToPerson of this.getByPersonId(personId)) {
      this.delete(nfcTokenToPerson);
    }
  }
}
