import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { PersonContactDto, PersonContactType } from './PersonContactDto';

export function createPersonContactEntityInfo<
  TEntity extends PersonContactDto<string, string>
>(
  options: CreateEntityInfoOptions<PersonContactDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.PersonContact,
  TEntity
> {
  return {
    entityName: EntityName.PersonContact,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PersonContactDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        },
        contactType: {
          name: 'contactType',
          defaultValue: PersonContactType.PHONE
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        highlighted: {
          name: 'highlighted',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
