import { PictureIconInfo } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureOverviewEntry';
import {
  GalleryThingIconHelper,
  PictureIconConfig
} from 'common/GalleryThing/GalleryThingIconHelper';

import { EntryToPersonCache } from '../../computedValues/computers/EntryToPersonCacheComputer/EntryToPersonCacheComputer';
import { PropertyCache } from '../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { GalleryThingPictureOverviewEntry } from './GalleryThingPictureOverviewEntryHelper';

// TODO rec-1616: unit tests
export class GalleryThingIconGenerator {
  constructor(
    private readonly entryToPersonCache: EntryToPersonCache,
    private readonly propertyCache: PropertyCache,
    private readonly pictureIconsConfiguration: Array<PictureIconConfig>
  ) {}

  public generatePictureIcons(
    overviewEntry: GalleryThingPictureOverviewEntry
  ): Array<PictureIconInfo> {
    return GalleryThingIconHelper.generatePictureIcons(
      {
        getEntryPropertyValue:
          this.propertyCache.getEntryPropertyValueIncludingFalsyValues.bind(
            this.propertyCache
          ),
        getPersonsByEntryId: this.entryToPersonCache.getPersonsByEntryId.bind(
          this.entryToPersonCache
        )
      },
      overviewEntry,
      this.pictureIconsConfiguration
    );
  }
}
