import { autoinject } from 'aurelia-dependency-injection';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../../UrlManager';
import { FileUtils } from '../../../Utils/FileUtils/FileUtils';
import { AppEntityManager } from '../AppEntityManager';
import { PictureFileNameUtils } from './PictureFileNameUtils';
import { PictureFilePathService } from './PictureFilePathService';
import { PictureFile } from './types';

@autoinject()
export class PictureFileLocalFilesService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService
  ) {}

  /**
   * copies a local pictureFile to the pictureFile location
   *
   * obviously only works in the app
   */
  public async moveLocalFile(
    pictureFile: PictureFile,
    fileUri: string
  ): Promise<void> {
    pictureFile.local_created = 0;
    pictureFile.file_created = Date.now();
    pictureFile.file_uploaded = false;
    pictureFile.readyToSynchronize = false;
    this.entityManager.pictureFileRepository.update(pictureFile);

    try {
      await FileUtils.copyFile(
        fileUri,
        this.pictureFilePathService.getRelativeCapturedLocalPicPath({
          pictureFile
        })
      );
      if (!pictureFile.onlyLocal) {
        pictureFile.readyForUpload = true;
      }
      pictureFile.isOriginatingHere = true;
      pictureFile.readyToSynchronize = true;
      this.entityManager.pictureFileRepository.update(pictureFile);

      await FileUtils.deleteEntry(fileUri);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * renames a local (captured) file when its id changed - only works in app!!
   */
  public async renameLocalPictureFile(
    pictureFile: PictureFile,
    oldId: string,
    newId: string
  ): Promise<void> {
    const baseFolder = PathUtils.joinPaths(
      UrlManager.localFolder,
      'captured_pictures'
    );
    assertNotNullOrUndefined(
      pictureFile.file_created,
      "can't get the path of a file which hasn't been created yet"
    );
    const oldName = PictureFileNameUtils.getPictureFileName({
      id: oldId,
      file_created: pictureFile.file_created,
      file_extension: pictureFile.file_extension
    });

    const newName = PictureFileNameUtils.getPictureFileName({
      id: newId,
      file_created: pictureFile.file_created,
      file_extension: pictureFile.file_extension
    });

    pictureFile.isBeingRenamed = true;

    try {
      await FileUtils.renameFile(baseFolder, oldName, newName);

      pictureFile.isBeingRenamed = false;
      pictureFile.readyForUpload = true;

      this.entityManager.pictureFileRepository.updateLocally(pictureFile);
    } catch (error) {
      pictureFile.isBeingRenamed = false;
      pictureFile.readyForUpload = false;

      this.entityManager.pictureFileRepository.updateLocally(pictureFile);

      throw error;
    }
  }
}
