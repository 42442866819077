import { computedFrom } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import {
  ProjectThingSectionProperty,
  ThingThingSectionProperty
} from '../../../classes/EntityManager/entities/Property/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  CreationConfig,
  PropertyAdapter,
  SubscribeOptions
} from './PropertyAdapter';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';

export abstract class BaseThingSectionPropertyAdapter<
  TProperty extends ThingSectionPropertyType
> implements PropertyAdapter<TProperty, TDefaultPropertyConfig>
{
  protected readonly thingSection: ThingSection;
  private readonly location: ThingSectionConfigPropertyLocation;
  private readonly fetchPropertiesFromEntityManager: GetProperties<TProperty>;
  protected readonly entityManager: AppEntityManager;
  private readonly permissionsService: PermissionsService;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  constructor(options: BaseThingSectionPropertyAdapterOptions<TProperty>) {
    this.thingSection = options.thingSection;
    this.location = options.location;
    this.fetchPropertiesFromEntityManager =
      options.fetchPropertiesFromEntityManager;
    this.entityManager = options.entityManager;
    this.permissionsService = options.permissionsService;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe(
    options: SubscribeOptions<TProperty, TDefaultPropertyConfig>
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(this.fetchPropertiesFromEntityManager());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );
    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSectionConfigProperty,
      updateConfigs.bind(this)
    );
    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.ThingSection,
      entity: this.thingSection,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return subscriptionManager.toDisposable();
  }

  public getThingSection(): ThingSection {
    return this.thingSection;
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }

  public abstract createPropertyFromConfig(
    config: CreationConfig<TDefaultPropertyConfig>
  ): TProperty;

  private getConfigs(): Array<TDefaultPropertyConfig> {
    const configProperties =
      this.entityManager.thingSectionConfigPropertyRepository.getOrderedByThingIdAndLocation(
        {
          thingId: this.thingSection.ownerThingId,
          location: this.location
        }
      );

    return configProperties.map((configProperty) => {
      return {
        name: configProperty.name ?? '',
        type: configProperty.type,
        choices: configProperty.choices,
        alwaysVisible: true
      };
    });
  }
}

export type BaseThingSectionPropertyAdapterOptions<
  TProperty extends ThingSectionPropertyType
> = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  thingSection: ThingSection;
  location: ThingSectionConfigPropertyLocation;
  fetchPropertiesFromEntityManager: GetProperties<TProperty>;
};

export type GetProperties<TProperty extends ThingSectionPropertyType> =
  () => Array<TProperty>;

export type ThingSectionPropertyType =
  | ThingThingSectionProperty
  | ProjectThingSectionProperty;
