import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createQuestionCatalogueToThingEntityInfo } from 'common/Types/Entities/QuestionCatalogueToThing/createQuestionCatalogueToThingEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { QuestionCatalogueToThing } from './types';

export const questionCatalogueToThingEntityInfo =
  createQuestionCatalogueToThingEntityInfo<QuestionCatalogueToThing>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
