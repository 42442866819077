import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTaskInvoice } from './types';
import { ProcessTaskPositionSnapshotService } from '../ProcessTaskPosition/ProcessTaskPositionSnapshotService';
import { ProcessTaskDeviceSnapshotService } from '../ProcessTaskDevice/ProcessTaskDeviceSnapshotService';

@autoinject()
export class ProcessTaskInvoiceFinishService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskPositionSnapshotService: ProcessTaskPositionSnapshotService,
    private readonly processTaskDeviceSnapshotService: ProcessTaskDeviceSnapshotService
  ) {}

  public finishInvoice(invoice: ProcessTaskInvoice): void {
    if (invoice.doneAt) {
      throw new InvoiceAlreadyFinishedError(invoice);
    }

    this.snapShotPositions(invoice);
    this.snapShotDevices(invoice);

    invoice.doneAt = new Date().toISOString();
    this.entityManager.processTaskInvoiceRepository.update(invoice);

    this.setStepOfRelatedProcessTasks(invoice);
  }

  private snapShotPositions(invoice: ProcessTaskInvoice): void {
    const relations =
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskInvoiceId(
        invoice.id
      );
    const relationWithPositions = relations.map((relation) => {
      return {
        relation,
        position:
          this.entityManager.processTaskPositionRepository.getRequiredById(
            relation.processTaskPositionId
          )
      };
    });

    for (const { relation, position } of relationWithPositions) {
      const entityCopy =
        this.processTaskPositionSnapshotService.createSnapshot(position);

      relation.processTaskPositionId = entityCopy.id;

      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.update(
        relation
      );
    }
  }

  private snapShotDevices(invoice: ProcessTaskInvoice): void {
    const relations =
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceId(
        invoice.id
      );
    const relationWithDevices = relations.map((relation) => {
      const device =
        this.entityManager.processTaskDeviceRepository.getRequiredById(
          relation.processTaskDeviceId
        );
      return { relation, device };
    });

    for (const { relation, device } of relationWithDevices) {
      const entityCopy =
        this.processTaskDeviceSnapshotService.createSnapshot(device);

      relation.processTaskDeviceId = entityCopy.id;

      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.update(
        relation
      );
    }
  }

  private setStepOfRelatedProcessTasks(invoice: ProcessTaskInvoice): void {
    const category = invoice.processConfigurationCategoryId
      ? this.entityManager.processConfigurationCategoryRepository.getById(
          invoice.processConfigurationCategoryId
        )
      : null;
    if (!category?.invoiceDoneProcessConfigurationStepId) {
      return;
    }

    const relations =
      this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
        invoice.id
      );
    const processTasks = this.entityManager.processTaskRepository.getByIds(
      relations.map((r) => r.ownerProcessTaskId)
    );

    for (const processTask of processTasks) {
      processTask.currentProcessConfigurationStepId =
        category.invoiceDoneProcessConfigurationStepId;
      this.entityManager.processTaskRepository.update(processTask);
    }
  }
}

export class InvoiceAlreadyFinishedError extends Error {
  constructor(invoice: ProcessTaskInvoice) {
    super(`invoice "${invoice.id}" is already finished`);
  }
}
