import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ThingGroup } from './types';

export class ThingGroupRepository extends AppEntityRepository<EntityName.ThingGroup> {
  public getByUserGroupId(userGroupId: string): Array<ThingGroup> {
    return this.getAll().filter(
      (group) => group.ownerUserGroupId === userGroupId
    );
  }
}
