import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ThingType } from '../../classes/EntityManager/entities/ThingType/types';
import { EditThingTypeDialog } from '../../dialogs/edit-thing-type-dialog/edit-thing-type-dialog';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';

@autoinject()
export class edit_thing_types {
  protected availableThingTypes: Array<ThingType> = [];
  protected sortedThingTypes: Array<ThingType> = [];
  protected currentPageThingTypes: Array<ThingType> = [];

  private isAttached = false;

  private sortOptions = {
    name: {
      name: 'Name',
      sortFunction: function (a: ThingType, b: ThingType) {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return aName.localeCompare(bName);
      }
    }
  };

  protected currentSortOption;

  private tryEditThingTypeTimeout: number | null = null;

  private pagination: Pagination | null = null;

  private editableUserGroups: Array<UserGroup> = [];

  private subscriptionManager: SubscriptionManager;

  private currentUser: User | null = null;
  protected isMobile: boolean = false;

  private currentThingTypeEditing: ThingType | null = null;
  protected readonly EntityName = EntityName;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.currentSortOption = this.sortOptions.name;
  }

  protected activate(params: { thing_type_id?: string }): void {
    if (params.thing_type_id) {
      const thingType = this.entityManager.thingTypeRepository.getById(
        params.thing_type_id
      );
      if (thingType) this.tryEditThingType(thingType);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingType,
      this.updateThingTypes.bind(this)
    );
    this.updateThingTypes();

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    if (this.tryEditThingTypeTimeout)
      window.clearTimeout(this.tryEditThingTypeTimeout);
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateThingTypes(): void {
    this.availableThingTypes = this.entityManager.thingTypeRepository.getAll();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }
  }

  protected thingTypeIsEditable(
    thingType: ThingType,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return thingType && user && editableUserGroups
      ? PermissionHelper.userCanEditOwnerUserGroupIdEntity(
          thingType,
          user,
          editableUserGroups
        )
      : false;
  }

  protected handleCreateThingTypeClick(event: CreateEntityClickedEvent): void {
    const userGroupId =
      event.detail.userGroup?.id ?? this.editableUserGroups[0]?.id;
    assertNotNullOrUndefined(
      userGroupId,
      'cannot create thing type without a usergroup'
    );

    const thingType = this.entityManager.thingTypeRepository.create({
      usergroup: userGroupId,
      ownerUserGroupId: userGroupId
    });

    this.updateThingTypes();
    this.editThingType(thingType);
  }

  protected handleEditThingTypeClick(thingType: ThingType): void {
    this.editThingType(thingType);
  }

  private editThingType(thingType: ThingType): void {
    this.currentThingTypeEditing = thingType;

    void EditThingTypeDialog.open({
      thingType: thingType,
      onDialogClosed: (tT) => {
        this.goToThingType(tT);
      }
    });
  }

  private goToThingType(thingType: ThingType): void {
    void ScrollHelper.autoScrollToListItem(
      '#thing-type-' + thingType.id,
      this.pagination,
      thingType,
      () => this.isAttached
    );
  }

  private tryEditThingType(thingType: ThingType): void {
    if (this.isAttached) {
      this.editThingType(thingType);
    } else {
      this.tryEditThingTypeTimeout = window.setTimeout(() => {
        this.tryEditThingType(thingType);
      }, 10);
    }
  }
}
