import { EncodedData } from './EncodedData';
import { FFlateUtils } from './FFlateUtils';

export class Decoder {
  public async decode(data: EncodedData): Promise<Record<any, any>> {
    const uint8BaseData =
      data.baseData instanceof Uint8Array
        ? data.baseData
        : new Uint8Array(data.baseData);
    const baseDecodedData = JSON.parse(
      await FFlateUtils.inflateText(uint8BaseData)
    );
    return this.reconstructData(baseDecodedData, data.attachments);
  }

  // code starting from here is (shamelessly) stolen from https://github.com/socketio/socket.io-parser/blob/4.2.2/lib/binary.ts and adapted
  private reconstructData(data: any, attachments: Array<Uint8Array>): any {
    if (!data) return data;

    if (data && data._placeholder === true) {
      const isIndexValid =
        typeof data.num === 'number' &&
        data.num >= 0 &&
        data.num < attachments.length;
      if (isIndexValid) {
        return attachments[data.num] as Uint8Array; // appropriate buffer (should be natural order anyway)
      } else {
        throw new Error('illegal attachments');
      }
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        data[i] = this.reconstructData(data[i], attachments);
      }
    } else if (typeof data === 'object') {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          data[key] = this.reconstructData(data[key], attachments);
        }
      }
    }

    return data;
  }
}
