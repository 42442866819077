import { PointerEventHelper } from '../../../classes/PointerEventHelper';
import { MathHelper } from '../../../classes/MathHelper';

import { Tool } from './Tool';

export class CloudTool extends Tool {
  /**
   * bump width relative to min size of the canvas
   * @type {number}
   */
  static bumpWidth = 33;

  /**
   * bump height relative to min size of the canvas
   *
   * @type {number}
   */
  static bumpHeight = 13;

  _name = 'Wolke';
  _icons = ['far fa-cloud'];
  _machineName = 'cloud';

  /** @type {(Msvg.Point|null)} */
  _startPosition = null;
  /** @type {(Msvg.Path|null)} */
  _path = null;

  _cancel() {
    if (this._path) {
      this._msvg.remove(this._path);
    }
    super._cancel();
  }

  _reset() {
    super._reset();
    this._path = null;
    this._startPosition = null;
  }

  /**
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    if (this._path) {
      this._finishPath(position);
    } else {
      this._startPath(position);
    }
  }

  /**
   * the position is already normalized
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseMovedHandler(event, position) {
    if (this._path) {
      this._updatePath(position);
    }
  }

  /**
   *
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (!this._path) {
      this._startPath(this._dragStartPosition);
    }
    this._updatePath(position);
  }

  /**
   *
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._finishPath(position);
  }

  /**
   *
   * @param {Msvg.Point} position
   * @private
   */
  _startPath(position) {
    this._startPosition = position;
    this._path = new Msvg.Path();
    this._updatePathStyling();
    this._msvg.append(this._path);
  }

  /**
   *
   * @param {Msvg.Point} position
   * @private
   */
  _finishPath(position) {
    this._updatePath(position);
    this._msvg.append(this._path);
    this._modified();
    this._reset();
  }

  /**
   * gets called after size/color or whatever additional stylings have been changed
   * overwrite this so you can update the preview correctly
   *
   * @protected
   */
  _stylingChanged() {
    if (this._path) {
      this._updatePathStyling();
    }
  }

  /**
   *
   * @param {Msvg.Point} endPosition
   * @private
   */
  _updatePath(endPosition) {
    const bounds = new Msvg.Utils.Bounds([this._startPosition, endPosition]);
    this._path.reset();
    this._path.addMoveToAbsolute(bounds.getTopLeftCorner());
    this._addBumpyPath(
      bounds.getTopLeftCorner(),
      bounds.getTopRightCorner(),
      this._path
    );
    this._addBumpyPath(
      bounds.getTopRightCorner(),
      bounds.getBottomRightCorner(),
      this._path
    );
    this._addBumpyPath(
      bounds.getBottomRightCorner(),
      bounds.getBottomLeftCorner(),
      this._path
    );
    this._addBumpyPath(
      bounds.getBottomLeftCorner(),
      bounds.getTopLeftCorner(),
      this._path
    );
    this._path.addClosePath();
  }

  _updatePathStyling() {
    this._path
      .setStroke(this._color)
      .setStrokeWidth(this._size)
      .setFill('none')
      .setStrokeLinejoin('bevel');
  }

  /**
   *
   * @param {Msvg.Point} pos1
   * @param {Msvg.Point} pos2
   * @param {Msvg.Path} path
   * @private
   */
  _addBumpyPath(pos1, pos2, path) {
    const diff = this._getPointDiffInfo(pos1, pos2);
    const distance = PointerEventHelper.calculateDistance(pos1, pos2),
      stepCount = Math.round(distance / CloudTool.bumpWidth),
      stepWidth = distance / stepCount,
      angle = Math.asin(Math.abs(diff.y) / distance);

    const bumpOffset = {
      x: diff.ySign * Math.sin(angle) * CloudTool.bumpHeight,
      y: -1 * diff.xSign * Math.cos(angle) * CloudTool.bumpHeight
    };

    for (let key = 1; key <= stepCount; key++) {
      const pos = new Msvg.Point(
        pos1.x + Math.cos(angle) * diff.xSign * stepWidth * key,
        pos1.y + Math.sin(angle) * diff.ySign * stepWidth * key
      );

      //the first cp1 is placed on the first 30% on the step and the second one on 70% + height of the bump
      const cp1 = new Msvg.Point(
        pos.x - Math.cos(angle) * diff.xSign * stepWidth * 0.7 + bumpOffset.x,
        pos.y - Math.sin(angle) * diff.ySign * stepWidth * 0.7 + bumpOffset.y
      );

      const cp2 = new Msvg.Point(
        pos.x - Math.cos(angle) * diff.xSign * stepWidth * 0.3 + bumpOffset.x,
        pos.y - Math.sin(angle) * diff.ySign * stepWidth * 0.3 + bumpOffset.y
      );

      path.addCurveToAbsolute(pos, cp1, cp2);
    }
  }

  /**
   *
   * @param {Msvg.Point} pos1
   * @param {Msvg.Point} pos2
   * @returns {{x: number, y: number, xSign: number, ySign: number}}
   * @private
   */
  _getPointDiffInfo(pos1, pos2) {
    const diff = {
      x: pos2.x - pos1.x,
      y: pos2.y - pos1.y,
      xSign: 0,
      ySign: 0
    };
    diff.xSign = MathHelper.signWithoutZero(diff.x);
    diff.ySign = MathHelper.signWithoutZero(diff.y);

    return diff;
  }
}
