import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingSectionDto } from './ThingSectionDto';

export function createThingSectionEntityInfo<
  TEntity extends ThingSectionDto<string, string>
>(
  options: CreateEntityInfoOptions<ThingSectionDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingSection,
  TEntity
> {
  return {
    entityName: EntityName.ThingSection,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingSectionDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        order: {
          name: 'order'
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
