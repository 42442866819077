import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentContact } from '../../classes/EntityManager/entities/ProcessTaskAppointmentContact/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessTaskAppointmentContactsWidget {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  private isAttached: boolean = false;
  private appointmentContacts: Array<ProcessTaskAppointmentContact> = [];
  private offerReceiver: Person | null = null;
  protected offerReceiverContact: ProcessTaskAppointmentContact | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentContactsWidget,
        propertyName: 'processTaskAppointment'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentContact,
      this.updateAppointmentContacts.bind(this)
    );
    this.updateAppointmentContacts();
    this.updateOfferReceiver();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateAppointmentContacts();
      this.updateOfferReceiver();
    }
  }

  private updateAppointmentContacts(): void {
    if (this.processTaskAppointment) {
      this.appointmentContacts =
        this.entityManager.processTaskAppointmentContactRepository.getByProcessTaskAppointmentId(
          this.processTaskAppointment.id
        );
    } else {
      this.appointmentContacts = [];
    }

    this.updateOfferReceiverContact();
  }

  private updateOfferReceiver(): void {
    if (this.processTaskAppointment) {
      const processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          this.processTaskAppointment.ownerProcessTaskGroupId
        );
      this.offerReceiver = processTaskGroup?.offerReceiverPersonId
        ? this.entityManager.personRepository.getById(
            processTaskGroup.offerReceiverPersonId
          )
        : null;
    } else {
      this.offerReceiver = null;
    }
  }

  private updateOfferReceiverContact(): void {
    const offerReceiver = this.offerReceiver;
    if (offerReceiver) {
      this.offerReceiverContact =
        this.appointmentContacts.find(
          (ac) => ac.personId === offerReceiver.id
        ) ?? null;
    } else {
      this.offerReceiverContact = null;
    }
  }

  protected handleAddContactClick(personId?: string): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentContactsWidget.handleAddContactClick without a processTaskAppointment"
    );

    const personContacts = personId
      ? this.entityManager.personContactRepository
          .getByPersonId(personId)
          .filter((pc) => pc.contactType === PersonContactType.EMAIL)
      : [];
    const personContactId = personContacts[0]?.id ?? null;

    const contact =
      this.entityManager.processTaskAppointmentContactRepository.create({
        processTaskAppointmentId: this.processTaskAppointment.id,
        personId,
        personContactId,
        ownerProcessTaskId: this.processTaskAppointment.ownerProcessTaskId,
        ownerProcessTaskGroupId:
          this.processTaskAppointment.ownerProcessTaskGroupId,
        ownerUserGroupId: this.processTaskAppointment.ownerUserGroupId
      });

    this.appointmentContacts.push(contact);
    this.updateOfferReceiverContact();
  }

  protected handleAppointmentContactDeleted(
    appointmentContact: ProcessTaskAppointmentContact
  ): void {
    const index = this.appointmentContacts.indexOf(appointmentContact);
    if (index >= 0) {
      this.appointmentContacts.splice(index, 1);
      this.updateOfferReceiverContact();
    }
  }
}
