import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { ExtractProcessTaskOfferDialog } from '../extract-process-task-offer-dialog/extract-process-task-offer-dialog';
import { SetProcessTaskCurrentProcessConfigurationStepIdService } from '../../services/SetProcessTaskCurrentProcessConfigurationStepIdService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';

@autoinject()
export class AcceptProcessTaskOfferDialog {
  private offer: ProcessTaskOffer | null = null;
  private processTasks: Array<ProcessTask> = [];
  private otherOffers: Array<ProcessTaskOffer> = [];

  protected dialog: RecordItDialog | null = null;

  protected readonly SequenceNumberUtils = SequenceNumberUtils;
  protected readonly SequenceNumberType = SequenceNumberType;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly setProcessTaskCurrentProcessConfigurationStepIdService: SetProcessTaskCurrentProcessConfigurationStepIdService
  ) {}

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: OpenOptions): void {
    this.offer = options.offer;

    this.updateEntities();

    if (this.otherOffers.length > 0) {
      void ExtractProcessTaskOfferDialog.open({
        offer: this.offer,
        warningTextTk:
          'operations.acceptProcessTaskOfferDialog.extractDialogWarning',
        onDialogClosed: (extractedEntities) => {
          if (this.dialog && extractedEntities.length) {
            this.updateEntities();
            this.dialog.open();
          }
        }
      });
    } else {
      if (this.dialog) {
        this.dialog.open();
      }
    }
  }

  protected handleDialogClosed(): void {
    this.offer = null;
    this.processTasks = [];
    this.otherOffers = [];
  }

  private updateEntities(): void {
    const offer = this.offer;
    assertNotNullOrUndefined(offer, "can't update entities without an offer");

    const processTaskOfferToProcessTasks =
      this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferIds(
        [offer.id]
      );
    const processTaskIds = processTaskOfferToProcessTasks.map(
      (r) => r.ownerProcessTaskId
    );
    this.processTasks =
      this.entityManager.processTaskRepository.getByIds(processTaskIds);

    const relations =
      this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskIds(
        processTaskIds
      );
    const offerIds = relations.map((r) => r.processTaskOfferId);
    const offers =
      this.entityManager.processTaskOfferRepository.getByIds(offerIds);
    this.otherOffers = offers.filter((o) => o !== offer);
  }

  protected handleCancelClick(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  protected handleAcceptClick(): void {
    assertNotNullOrUndefined(
      this.offer,
      "can't handleAcceptClick without offer"
    );

    const processConfigurationCategoryId =
      this.offer.processConfigurationCategoryId;
    const category = processConfigurationCategoryId
      ? this.entityManager.processConfigurationCategoryRepository.getById(
          processConfigurationCategoryId
        )
      : null;

    if (category && category.defaultProcessConfigurationStepId) {
      for (const pt of this.processTasks) {
        void this.setProcessTaskCurrentProcessConfigurationStepIdService.setCurrentProcessConfigurationStepId(
          pt,
          category.defaultProcessConfigurationStepId
        );
      }

      this.notifyChangeToStepId(category.defaultProcessConfigurationStepId);
    }

    this.offer.acceptedAt = new Date().toISOString();
    this.entityManager.processTaskOfferRepository.update(this.offer);

    if (this.dialog) {
      this.dialog.close();
    }
  }

  private notifyChangeToStepId(processConfigurationStepId: string): void {
    const step = this.entityManager.processConfigurationStepRepository.getById(
      processConfigurationStepId
    );

    if (step) {
      NotificationHelper.notifySuccess(
        this.i18n.tr('operations.acceptProcessTaskOfferDialog.changedToStep', {
          stepName: step.name
        })
      );
    }
  }
}

export type OpenOptions = {
  offer: ProcessTaskOffer;
};
