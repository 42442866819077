import { autoinject, bindable } from 'aurelia-framework';

import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../../classes/PropertyBinder/PropertyBinder';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { Disposable } from '../../classes/Utils/DisposableContainer';

/**
 * basically this is just a text input with global access
 *
 * it is not allowed to have multiple of these at once!!
 *
 * @event search-text-cleared - get fired when the user clicks on the clear icon, can also be used as a close icon
 */
@autoinject()
export class GlobalFilter {
  private static instance: GlobalFilter | null;
  private static propertyBinder: PropertyBinder<GlobalFilterPropertyBinderConfig>;
  private static initialized: boolean = false;
  private static placeholder: string | null = null;
  private static enableCount: number = 0;

  @bindable()
  public value: string = '';

  @bindable()
  public enabled: boolean = false;

  protected placeholder: string | null = null;
  protected inputElement: HTMLElement | null = null;

  constructor(private readonly element: Element) {}

  public focus(): void {
    assertNotNullOrUndefined(
      this.inputElement,
      "can't GlobalFilter.focus without inputElement"
    );
    this.inputElement.focus();
  }

  protected attached(): void {
    if (GlobalFilter.instance) {
      console.error(
        'there is already another instance, this is not allowed and the existing instance will be replaced with the new one'
      );
    }

    GlobalFilter.instance = this;
    this.setPlaceholder(GlobalFilter.placeholder);
    this.enabled = GlobalFilter.enableCount > 0;
  }

  protected detached(): void {
    GlobalFilter.instance = null;
  }

  protected valueChanged(): void {
    this.setValue(this.value);
  }

  protected handleClearClick(): void {
    this.setValue('');

    DomEventHelper.fireEvent(this.element, {
      name: 'search-text-cleared',
      detail: null
    });
  }

  private setValue(value: string): void {
    this.value = value ? value : '';
    GlobalFilter.propertyBinder.setValue('value', this.value);
  }

  private setPlaceholder(placeholder: string | null): void {
    this.placeholder = placeholder ? placeholder : 'Suche...';
  }

  public static focus(): void {
    if (this.instance) {
      this.instance.focus();
    }
  }

  public static init(): void {
    if (!this.initialized) {
      this.propertyBinder =
        new PropertyBinder<GlobalFilterPropertyBinderConfig>({
          defaultValuesByName: { value: '' }
        });
      this.initialized = true;
    }
  }

  public static clear(): void {
    if (this.instance) {
      this.instance.setValue('');
    }
  }

  public static registerBinding<
    TName extends PropertyBinderName<GlobalFilterPropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<GlobalFilterPropertyBinderConfig, TName>
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public static setPlaceholder(placeholder: string | null): void {
    this.placeholder = placeholder;

    if (this.instance) {
      this.instance.setPlaceholder(placeholder);
    }
  }

  /**
   * always call enable and disable in equally often
   */
  public static enable(): void {
    this.enableCount++;

    if (this.instance) {
      this.instance.enabled = this.enableCount > 0;
    }
  }

  /**
   * always call enable and disable in equally often
   */
  public static disable(): void {
    this.enableCount--;

    if (this.enableCount < 0) {
      this.enableCount = 0;
    }

    if (this.instance) {
      this.instance.enabled = this.enableCount > 0;
    }
  }

  public static setValue(value: string): void {
    if (this.instance) {
      this.instance.setValue(value);
    }
  }
}

GlobalFilter.init();

type GlobalFilterPropertyBinderConfig = {
  value: string;
};
