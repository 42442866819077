import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskOfferToProcessTaskPositionDto } from './ProcessTaskOfferToProcessTaskPositionDto';

export function createProcessTaskOfferToProcessTaskPositionEntityInfo<
  TEntity extends ProcessTaskOfferToProcessTaskPositionDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskOfferToProcessTaskPositionDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskOfferToProcessTaskPosition,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskOfferToProcessTaskPosition,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskOfferToProcessTaskPositionDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskOfferId: {
          name: 'processTaskOfferId',
          ref: EntityName.ProcessTaskOffer,
          cascadeDeleteLocally: true
        },
        processTaskPositionId: {
          name: 'processTaskPositionId',
          ref: EntityName.ProcessTaskPosition,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
