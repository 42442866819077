import { autoinject, bindable } from 'aurelia-framework';

import { User } from '../../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import {
  OperationsCalendarSettings,
  OperationsCalendarSettingsService
} from '../../../services/OperationsCalendarSettingsService/OperationsCalendarSettingsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@autoinject()
export class OperationsCalendarUserLegendGroup {
  @bindable public label = '';
  @bindable public labelColor = '';

  @bindable public users: Array<User> = [];
  @bindable public color: string | null = null;
  @bindable public userFilter: Array<string> = [];

  private readonly subscriptionManager: SubscriptionManager;
  private calendarSettings: OperationsCalendarSettings;
  private visible: boolean = false;

  constructor(
    private readonly operationsCalendarSettingsService: OperationsCalendarSettingsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.calendarSettings = operationsCalendarSettingsService.getSettings();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.operationsCalendarSettingsService.bindSettings((settings) => {
        this.calendarSettings = settings;
        this.updateVisible();
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private colorChanged(): void {
    this.updateVisible();
  }

  private updateVisible(): void {
    this.visible =
      this.calendarSettings.userColorGroupSelectedMap.get(this.color ?? '') !==
      false;
  }

  private isUserChecked(
    user: User,
    userIdSelectedMap: OperationsCalendarSettings['userIdSelectedMap']
  ): boolean {
    return userIdSelectedMap.get(user.id) !== false;
  }

  private getListOfUserNames(users: Array<User>): string {
    return users.map((user) => user.username).join(', ');
  }

  private handleGroupCheckboxChanged(checkedStatus: boolean): void {
    const userColorGroupSelectedMap = new Map(
      this.calendarSettings.userColorGroupSelectedMap
    );
    userColorGroupSelectedMap.set(this.color ?? '', checkedStatus);

    this.operationsCalendarSettingsService.modifySettings({
      userColorGroupSelectedMap
    });
  }

  private handleUserCheckboxChanged(checkedStatus: boolean, user: User): void {
    const userIdSelectedMap = new Map(this.calendarSettings.userIdSelectedMap);
    userIdSelectedMap.set(user.id, checkedStatus);

    this.operationsCalendarSettingsService.modifySettings({
      userIdSelectedMap
    });
  }
}
