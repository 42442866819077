import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  ProcessTaskInvoiceMapComputer,
  ProcessTaskInvoicesByProcessTaskPositionId
} from '../../../../computedValues/computers/ProcessTaskInvoiceMapComputer/ProcessTaskInvoiceMapComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  ListItemRelatedEntityCountsAdapter,
  SubscribeOptions
} from '../ListItemRelatedEntityCountsAdapter';
import { ListItemRelatedEntityCountsUtils } from '../ListItemRelatedEntityCountsUtils/ListItemRelatedEntityCountsUtils';

export class ProcessTaskPositionAdapter
  implements ListItemRelatedEntityCountsAdapter
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly processTaskPosition: ProcessTaskPosition;

  private processTaskInvoicesByProcessTaskPositionId: ProcessTaskInvoicesByProcessTaskPositionId =
    new Map();

  private offerCount: number = 0;
  private invoiceCount: number = 0;
  private appointmentCount: number = 0;

  constructor(options: ProcessTaskPositionAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.processTaskPosition = options.processTaskPosition;
  }

  public subscribe(options: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const emitCounts = (): void => {
      options.onNewCounts([
        ListItemRelatedEntityCountsUtils.createOfferCount(this.offerCount),
        ListItemRelatedEntityCountsUtils.createAppointmentCount(
          this.appointmentCount
        ),
        ListItemRelatedEntityCountsUtils.createInvoiceCount(this.invoiceCount)
      ]);
    };

    const updateOfferCountAndEmitCounts = (): void => {
      this.updateOfferCount();
      emitCounts();
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      updateOfferCountAndEmitCounts
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      updateOfferCountAndEmitCounts
    );
    this.updateOfferCount();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      () => {
        this.updateAppointmentCount();
        emitCounts();
      }
    );
    this.updateAppointmentCount();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskInvoiceMapComputer,
        computeData: {},
        callback: (processTaskInvoiceMapComputeResult) => {
          this.processTaskInvoicesByProcessTaskPositionId =
            processTaskInvoiceMapComputeResult.processTaskInvoicesByProcessTaskPositionId;
          this.updatedInvoiceCount();
          emitCounts();
        }
      })
    );

    emitCounts();

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  private updateOfferCount(): void {
    const offerRelations =
      this.entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskPositionId(
        this.processTaskPosition.id
      );
    const activeOfferRelations = offerRelations.filter((or) => {
      return (
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
          or.ownerProcessTaskId
        ).length > 0
      );
    });
    this.offerCount = activeOfferRelations.length;
  }

  private updateAppointmentCount(): void {
    const appointmentRelations =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskPositionId(
        this.processTaskPosition.id
      );
    this.appointmentCount = appointmentRelations.length;
  }

  private updatedInvoiceCount(): void {
    const invoices =
      this.processTaskInvoicesByProcessTaskPositionId.get(
        this.processTaskPosition.id
      ) ?? [];
    this.invoiceCount = invoices.length;
  }
}

export type ProcessTaskPositionAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  processTaskPosition: ProcessTaskPosition;
};
