import { inject, observable } from 'aurelia-framework';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { GlobalData } from '../../classes/GlobalData';
import { FilterHelper } from '../../classes/FilterHelper';
import { EntityMap } from '../../classes/EntityMap';
import { RecordItHeader } from '../../aureliaComponents/record-it-header/record-it-header';
import { UiUpdater } from '../../classes/UiUpdater';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { NavigationService } from '../../services/NavigationService';

@inject(
  SubscriptionManagerService,
  AppEntityManager,
  ActiveUserCompanySettingService,
  NavigationService
)
export class thing_map {
  /** @type {Array<Thing>} */
  _availableThings = [];

  /** @type {string} */
  @observable _thingFilterString;

  /** @type {Array<Thing>} */
  @observable _filteredThings;

  /** @type {import('../../map/basemap-map/basemap-map').BasemapMap|null} */
  _basemapMap = null;

  /** @type {EntityMap|null} */
  _entityMap = null;

  /** @type {string} */
  @observable _defaultMarkerColor;

  /** @type {Array<import('../../classes/EntityMap').TEntityMapEntityInfo>} */
  _entityMapEntityInfos = [];

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   * @param {ActiveUserCompanySettingService} activeUserCompanySettingService
   * @param {NavigationService} navigationService
   */
  constructor(
    subscriptionManagerService,
    entityManager,
    activeUserCompanySettingService,
    navigationService
  ) {
    this._subscriptionManager = subscriptionManagerService.create();
    this._entityManager = entityManager;
    this._boundHandleResize = this._handleResize.bind(this);

    this._activeUserCompanySettingService = activeUserCompanySettingService;
    this._navigationService = navigationService;

    this._thingFilterString = '';
    this._filteredThings = [];
    this._defaultMarkerColor = '#ff0000';
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this._updateAvailableThings.bind(this)
    );
    this._updateAvailableThings();

    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this._isMobile = isMobile;
      })
    );

    UiUpdater.registerResizeUpdateFunction(this._boundHandleResize);
    this._handleResize();

    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'general.primaryLightColor',
        (primaryLightColor) => {
          this._defaultMarkerColor = primaryLightColor;
        }
      )
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
    UiUpdater.unregisterResizeUpdateFunction(this._boundHandleResize);
  }

  _handleResize() {
    const headerHeight = RecordItHeader.getHeaderHeight() || 0;
    this._remainingHeight = window.innerHeight - headerHeight;

    const basemapMap = this._basemapMap;
    if (!basemapMap) return;

    window.requestAnimationFrame(() => {
      basemapMap.resize();
    });
  }

  _handleMapInitialised() {
    if (this._basemapMap) this._entityMap = new EntityMap(this._basemapMap);
    this._updateAvailableThings();
  }

  _updateAvailableThings() {
    this._availableThings = this._entityManager.thingRepository.getAll();
    this._updateEntityMapEntityInfos();
    this._thingFilterStringChanged();
  }

  _filteredThingsChanged() {
    if (!this._entityMap) {
      return; // not initialized yet
    }

    this._entityMap.showEntitiesOnMap(this._entityMapEntityInfos);

    const filteredThingIds = this._filteredThings.map((t) => t.id);
    const filteredEntityMapEntityInfos = this._entityMapEntityInfos.filter(
      (eMI) => filteredThingIds.includes(eMI.id)
    );
    this._entityMap.enableEntities(filteredEntityMapEntityInfos);
  }

  _thingFilterStringChanged() {
    this._filteredThings = FilterHelper.filterItems(
      this._availableThings,
      GlobalData.thingFilterFunction,
      this._thingFilterString
    );
  }

  _defaultMarkerColorChanged() {
    this._filteredThingsChanged();
  }

  _updateEntityMapEntityInfos() {
    this._entityMapEntityInfos = this._createEntityMapEntityInfosFromThings(
      this._availableThings
    );
  }

  /**
   * @param {Array<Thing>} things
   * @returns {Array<import('../../classes/EntityMap').TEntityMapEntityInfo>}
   */
  _createEntityMapEntityInfosFromThings(things) {
    /** @type {Array<import('../../classes/EntityMap').TEntityMapEntityInfo>} */
    const entityMapEntityInfos = [];

    things.forEach((thing) => {
      if (thing.longitude && thing.latitude) {
        entityMapEntityInfos.push({
          id: thing.id,
          entity: thing,
          longitude: thing.longitude,
          latitude: thing.latitude,
          linkUrl: `${this._navigationService.getThingRouteName()}/${thing.id}`,
          markerColor: this._defaultMarkerColor
        });
      }
    });

    return entityMapEntityInfos;
  }
}

/** @typedef {import('../../classes/EntityManager/entities/Thing/types').Thing} Thing */
