import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { QuestionCatalogue } from './types';

@autoinject()
export class QuestionCatalogueService {
  constructor(private readonly entityManager: AppEntityManager) {}

  /**
   * Retrieve all question catalogues belonging to a given question set.
   */
  public getByQuestionSetId(questionSetId: string): Array<QuestionCatalogue> {
    const entities =
      this.entityManager.questionCatalogueToQuestionSetRepository.getByQuestionSetId(
        questionSetId
      );
    return this.entityManager.questionCatalogueRepository.getByIds(
      entities.map((e) => e.questionCatalogueId)
    );
  }

  /**
   * Retrieve all question catalogues belonging to a given project.
   */
  public getByThingId(thingId: string): Array<QuestionCatalogue> {
    const entities =
      this.entityManager.questionCatalogueToThingRepository.getByThingId(
        thingId
      );
    return this.entityManager.questionCatalogueRepository.getByIds(
      entities.map((e) => e.questionCatalogueId)
    );
  }
}
