import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * A widget for uploading files (or pictures).
 *
 * @slot default slot for text within the upload area.
 * @event file-input-changed fired when the file input changes.
 */
@autoinject()
export class UploadWidget {
  @bindable public inputRef: HTMLElement | null = null;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected handleFileInputChanged(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'file-input-changed',
      detail: null
    });
  }
}
