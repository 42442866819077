import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskInvoiceToProcessTask } from '../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTask/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import {
  ProcessTaskDeselectedEvent,
  ProcessTaskSelectedEvent
} from '../multi-process-task-selection-widget/multi-process-task-selection-widget';

@autoinject()
export class ProcessTaskInvoiceRelationsWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;

  private availableProcessTaskInvoiceToProcessTasks: Array<ProcessTaskInvoiceToProcessTask> =
    [];

  private availableProcessTasks: Array<ProcessTask> = [];
  private includedProcessTasks: Array<ProcessTask> = [];
  private category: ProcessConfigurationCategory | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this.updateAvailableProcessTaskInvoiceToProcessTasks.bind(this)
    );
    this.updateAvailableProcessTaskInvoiceToProcessTasks();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateAvailableProcessTasks.bind(this)
    );
    this.updateAvailableProcessTasks();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateCategory.bind(this)
    );
    this.updateCategory();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskInvoiceChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTaskInvoiceToProcessTasks();
      this.updateAvailableProcessTasks();
      this.updateCategory();
    }
  }

  private updateCategory(): void {
    if (
      this.processTaskInvoice &&
      this.processTaskInvoice.processConfigurationCategoryId
    ) {
      this.category =
        this.entityManager.processConfigurationCategoryRepository.getById(
          this.processTaskInvoice.processConfigurationCategoryId
        );
    } else {
      this.category = null;
    }
  }

  private updateAvailableProcessTaskInvoiceToProcessTasks(): void {
    if (this.processTaskInvoice) {
      this.availableProcessTaskInvoiceToProcessTasks =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
          this.processTaskInvoice.id
        );
    } else {
      this.availableProcessTaskInvoiceToProcessTasks = [];
    }

    this.updateIncludedProcessTasks();
  }

  private updateAvailableProcessTasks(): void {
    if (this.processTaskInvoice) {
      this.availableProcessTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskInvoice.ownerProcessTaskGroupId
        );
    } else {
      this.availableProcessTasks = [];
    }

    this.updateIncludedProcessTasks();
  }

  private updateIncludedProcessTasks(): void {
    this.includedProcessTasks = this.availableProcessTasks.filter((pt) => {
      return !!this.availableProcessTaskInvoiceToProcessTasks.find(
        (r) => r.ownerProcessTaskId === pt.id
      );
    });
  }

  private handleProcessTaskSelected(event: ProcessTaskSelectedEvent): void {
    assertNotNullOrUndefined(
      this.processTaskInvoice,
      "can't ProcessTaskInvoiceRelationsWidget.handleProcessTaskSelected without a processTaskInvoice"
    );
    const relation = this.availableProcessTaskInvoiceToProcessTasks.find(
      (r) => r.ownerProcessTaskId === event.detail.processTask.id
    );
    if (!relation) {
      this.entityManager.processTaskInvoiceToProcessTaskRepository.create({
        ownerProcessTaskGroupId:
          this.processTaskInvoice.ownerProcessTaskGroupId,
        ownerProcessTaskId: event.detail.processTask.id,
        ownerUserGroupId: this.processTaskInvoice.ownerUserGroupId,
        processTaskInvoiceId: this.processTaskInvoice.id,
        temporaryGroupName: this.processTaskInvoice.temporaryGroupName
      });
    }
  }

  private handleProcessTaskDeselected(event: ProcessTaskDeselectedEvent): void {
    const relation = this.availableProcessTaskInvoiceToProcessTasks.find(
      (r) => r.ownerProcessTaskId === event.detail.processTask.id
    );
    if (relation) {
      // the relations to the positions won't get deleted on purpose, so the user can just check the checkbox again and still have the connection to the positions
      this.entityManager.processTaskInvoiceToProcessTaskRepository.delete(
        relation
      );
    }
  }
}
