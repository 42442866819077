import { Property } from '../../EntityManager/entities/Property/types';
import { RequiredProperty } from './RequiredProperty';

export abstract class RequiredPropertyGroup {
  private readonly internalName: string | null;
  protected readonly requiredProperties: Array<RequiredProperty> = [];

  constructor({ name }: { name: string | null }) {
    this.internalName = name;
  }

  public get name(): string | null {
    return this.internalName;
  }

  public addRequiredProperty({
    requiredProperty
  }: {
    requiredProperty: RequiredProperty;
  }): void {
    this.requiredProperties.push(requiredProperty);
  }

  public abstract validate(): ValidationResult;
}

export type ValidationResult = ValidationSuccessResult | ValidationErrorResult;

export type ValidationSuccessResult = {
  success: true;
};

export type ValidationErrorResult = {
  success: false;

  /**
   * First property which the validator failed at.
   * There could be more than one failing property though, but this is good enough for the first version.
   *
   * Useful for error messages/highlighting etc.
   */
  failedAtProperty: Property;
};
