import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { QuestionCatalogueToThing } from './types';

export class QuestionCatalogueToThingRepository extends AppEntityRepository<EntityName.QuestionCatalogueToThing> {
  /**
   * Connects multiple question catalogues to a thing.
   * Will remove relations with question catalogues not present within the given array of ids.
   *
   * @example
   * setRelations('thingId1', ['questionCatalogue1', 'questionCatalogue2'], ...);
   * // => will ensure QuestionCatalogueToThing { thingId: 'thingId1', questionCatalogueId: 'questionCatalogue1', ... } is present
   * // => will ensure QuestionCatalogueToThing { thingId: 'thingId1', questionCatalogueId: 'questionCatalogue2', ... } is present
   * // => will delete other previously existing QuestionCatalogueToThing's where thingId === 'thingId1'
   */
  public setRelations({
    thingId,
    questionCatalogueIds,
    ownerUserGroupId
  }: {
    thingId: string;
    questionCatalogueIds: Array<string>;
    ownerUserGroupId: string;
  }): void {
    const existingRelations = this.getByThingId(thingId);
    const existingQuestionCatalogueIds = existingRelations.map(
      (e) => e.questionCatalogueId
    );

    // Delete all relations not present in questionCatalogueIds
    for (const relation of existingRelations) {
      if (!questionCatalogueIds.includes(relation.questionCatalogueId)) {
        this.delete(relation);
      }
    }

    // Create relations not present in existingRelations
    for (const questionCatalogueId of questionCatalogueIds) {
      if (!existingQuestionCatalogueIds.includes(questionCatalogueId)) {
        this.create({ thingId, questionCatalogueId, ownerUserGroupId });
      }
    }
  }

  public getByThingId(thingId: string): Array<QuestionCatalogueToThing> {
    return this.getAll().filter((e) => e.thingId === thingId);
  }
}
