import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { UserRole } from '../../classes/EntityManager/entities/UserRole/types';
import { UserRoleToUser } from '../../classes/EntityManager/entities/UserRoleToUser/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class UserUserRolesWidget {
  @bindable()
  public user: User | null = null;

  @bindable()
  public userGroup: UserGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  private isAttached: boolean = false;
  protected userRoleToAddChoices: Array<UserRole> = [];
  protected userRoleToAdd: UserRole | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.UserGroup,
        context: this as UserUserRolesWidget,
        propertyName: 'userGroup'
      }
    );

    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserRoleToUser,
      this.updateUserRoleToAddChoices.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserRole,
      this.updateUserRoleToAddChoices.bind(this)
    );
    this.updateUserRoleToAddChoices();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected userChanged(): void {
    if (this.isAttached) {
      this.updateUserRoleToAddChoices();
    }
  }

  protected userGroupChanged(): void {
    if (this.isAttached) {
      this.updateUserRoleToAddChoices();
    }
  }

  private updateUserRoleToAddChoices(): void {
    if (this.user && this.userGroup) {
      const usedRoleIds = new Set(
        this.userRoleToUsers.map((userRoleToUser) => userRoleToUser.userRoleId)
      );

      this.userRoleToAddChoices = this.entityManager.userRoleRepository
        .getByUserGroupId(this.userGroup.id)
        .filter((userRole) => {
          return !usedRoleIds.has(userRole.id);
        });
    } else {
      this.userRoleToAddChoices = [];
    }

    this.updateUserRoleToAdd();
  }

  private updateUserRoleToAdd(): void {
    if (
      this.userRoleToAdd &&
      !this.userRoleToAddChoices.includes(this.userRoleToAdd)
    ) {
      this.userRoleToAdd = null;
    }
  }

  @computed(
    expression('user.id'),
    expression('userGroup.id'),
    model(EntityName.UserRoleToUser)
  )
  protected get userRoleToUsers(): Array<UserRoleToUser> {
    if (!this.user || !this.userGroup) {
      return [];
    }

    return this.entityManager.userRoleToUserRepository.getByUserAndUserGroupId({
      userId: this.user.id,
      userGroupId: this.userGroup.id
    });
  }

  protected handleAddUserRoleClick(): void {
    assertNotNullOrUndefined(
      this.userRoleToAdd,
      "can't UserUserRolesWidget.handleAddUserRoleClick without userRoleToAdd"
    );
    assertNotNullOrUndefined(
      this.userGroup,
      "can't UserUserRolesWidget.handleAddUserRoleClick without userGroup"
    );
    assertNotNullOrUndefined(
      this.user,
      "can't UserUserRolesWidget.handleAddUserRoleClick without user"
    );

    this.entityManager.userRoleToUserRepository.create({
      userId: this.user.id,
      userRoleId: this.userRoleToAdd.id,
      ownerUserGroupId: this.userGroup.id
    });

    this.userRoleToAdd = null;
  }
}
