import { Disposable } from '../Utils/DisposableContainer';

/**
 * A wrapper for an array of objects which contain a subscribable.
 *
 * This class is useful if you want to have a simpler management of the subscribables.
 *
 * Currently changes to the `items` array are not observed and you have to set the `items` again after each change to the array.
 */
export class SubscribableArray<T> {
  private readonly getSubscribableFromItem: (item: T) => Subscribable | null;
  private readonly subscribableToDisposable = new Map<
    Subscribable,
    Disposable
  >();

  private isSubscribed: boolean = false;
  private internalItems: Array<T> = [];

  constructor({
    getSubscribableFromItem
  }: {
    getSubscribableFromItem: (item: T) => Subscribable | null;
  }) {
    this.getSubscribableFromItem = getSubscribableFromItem;
  }

  public subscribe(): Disposable {
    this.isSubscribed = true;

    this.updateSubscriptions();

    return {
      dispose: () => {
        this.isSubscribed = false;
        this.disposeAllSubscriptions();
      }
    };
  }

  public get items(): Array<T> {
    return this.internalItems;
  }

  public set items(items: Array<T>) {
    this.internalItems = items;

    this.updateSubscriptions();
  }

  private updateSubscriptions(): void {
    if (!this.isSubscribed) {
      this.disposeAllSubscriptions();
      return;
    }

    const itemWithSubscribables = this.internalItems
      .map<ItemWithOptionalSubscribable<T>>((item) => ({
        item,
        subscribable: this.getSubscribableFromItem(item)
      }))
      .filter((data): data is ItemWithSubscribable<T> => {
        return !!data.subscribable;
      });

    this.disposeSubscribables({
      subscribables: this.getSuperflousSubscribables({
        itemWithSubscribables
      })
    });

    const missingItems = this.getMissingItems({
      itemWithSubscribables
    });

    for (const missingItem of missingItems) {
      this.subscribableToDisposable.set(
        missingItem.subscribable,
        missingItem.subscribable.subscribe()
      );
    }
  }

  private disposeAllSubscriptions(): void {
    this.disposeSubscribables({
      subscribables: Array.from(this.subscribableToDisposable.keys()).reverse()
    });
  }

  private getMissingItems({
    itemWithSubscribables
  }: {
    itemWithSubscribables: Array<ItemWithSubscribable<T>>;
  }): Array<ItemWithSubscribable<T>> {
    return itemWithSubscribables.filter((item) => {
      return !this.subscribableToDisposable.has(item.subscribable);
    });
  }

  private getSuperflousSubscribables({
    itemWithSubscribables
  }: {
    itemWithSubscribables: Array<ItemWithSubscribable<T>>;
  }): Array<Subscribable> {
    const usedSubscribables = new Set(
      itemWithSubscribables.map((item) => item.subscribable)
    );

    return Array.from(this.subscribableToDisposable.keys()).filter(
      (subscribable) => {
        return !usedSubscribables.has(subscribable);
      }
    );
  }

  private disposeSubscribables({
    subscribables
  }: {
    subscribables: Array<Subscribable>;
  }): void {
    for (const subscribable of subscribables) {
      const disposable = this.subscribableToDisposable.get(subscribable);

      disposable?.dispose();

      this.subscribableToDisposable.delete(subscribable);
    }
  }
}

export type Subscribable = {
  subscribe: () => Disposable;
};

type ItemWithSubscribable<T> = {
  item: T;
  subscribable: Subscribable;
};

type ItemWithOptionalSubscribable<T> = {
  item: T;
  subscribable: Subscribable | null;
};
