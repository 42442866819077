import { autoinject } from 'aurelia-framework';
import { EntityInfoUtils } from '@record-it-npm/synchro-common';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  SingleSocketRequest,
  SingleSocketRequestService
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { GetProcessTaskGroupsAndTasksResponseSuccess } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { SocketService } from '../../services/SocketService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesGenerator
} from '../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { CreateProcessTaskAppointmentService } from '../../classes/EntityManager/entities/ProcessTaskAppointment/CreateProcessTaskAppointmentService';
import { EditProcessTaskAppointmentDialog } from '../edit-process-task-appointment-dialog/edit-process-task-appointment-dialog';

@autoinject()
export class CreateProcessTaskAppointmentInCalendarDialog {
  protected dialog: RecordItDialog | null = null;
  protected processTaskOptions: Array<{ id: string; label: string }> = [];
  protected selectedProcessTaskId: string | null = null;

  private processTasks: Array<ProcessTask> = [];
  private readonly getTaskGroupsAndTasksRequest: SingleSocketRequest<
    {},
    GetProcessTaskGroupsAndTasksResponseSuccess
  >;

  private processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly createProcessTaskAppointmentService: CreateProcessTaskAppointmentService,
    singleSocketRequestService: SingleSocketRequestService,
    socketService: SocketService
  ) {
    this.getTaskGroupsAndTasksRequest =
      singleSocketRequestService.createRequest({
        requestCallback: () => {
          return new Promise<GetProcessTaskGroupsAndTasksResponseSuccess>(
            (resolve, reject) => {
              socketService.getProcessTaskGroupsAndTasks({}, (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(
                    new Error("couldn't fetch process task groups and tasks")
                  );
                }
              });
            }
          );
        }
      });
  }

  public static async open(): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    await view.getViewModel().open();
  }

  private async open(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open CreateProcessTaskAppointmentInCalendarDialog without dialog'
    );

    await this.updateProcessTasks();

    this.dialog.open();
  }

  private async updateProcessTasks(): Promise<void> {
    const resp = await this.getTaskGroupsAndTasksRequest.send({});
    this.processTasks = resp.entities.processTasks
      .map((p) => {
        EntityInfoUtils.applyDefaultValues(
          this.entityManager.processTaskRepository.getEntityInfo(),
          p
        );
        return p as ProcessTask;
      })
      .filter((p) => {
        const configuration =
          this.entityManager.processConfigurationStepRepository.getById(
            p.currentProcessConfigurationStepId
          );
        return configuration && !configuration.archive;
      });

    this.updateProcessTaskNamesByProcessTaskId();
  }

  private updateProcessTaskNamesByProcessTaskId(): void {
    const generator = new ProcessTaskNamesGenerator(this.entityManager);

    for (const processTask of this.processTasks) {
      this.processTaskNamesByProcessTaskId.set(
        processTask.id,
        generator.generate(processTask)
      );
    }

    this.updateProcessTaskOptions();
  }

  private updateProcessTaskOptions(): void {
    this.processTaskOptions = this.processTasks.map((pt) => ({
      id: pt.id,
      label:
        this.processTaskNamesByProcessTaskId.get(pt.id)
          ?.nameWithThingAndPerson ?? ''
    }));
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    const selectedProcessTask = this.processTasks.find(
      (i) => i.id === this.selectedProcessTaskId
    );
    if (!selectedProcessTask) return;

    const appointment =
      await this.createProcessTaskAppointmentService.createAppointment(
        selectedProcessTask,
        [],
        []
      );
    void EditProcessTaskAppointmentDialog.open({
      appointment
    });
  }
}
