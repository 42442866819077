import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskCommentEntityInfo } from 'common/Types/Entities/ProcessTaskComment/createProcessTaskCommentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskComment } from './types';

export const processTaskCommentEntityInfo =
  createProcessTaskCommentEntityInfo<ProcessTaskComment>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
