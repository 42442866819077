import { EntityIdInfoAdapter } from '@record-it-npm/synchro-common';
import { assertNotNullOrUndefined } from '../../../../Asserts';
import { ClientEntityIdInfoAdapter } from '../ClientEntityIdInfoAdapter/ClientEntityIdInfoAdapter';
import { ServerEntityIdAdapter } from '../ServerEntityIdAdapter/ServerEntityIdAdapter';

/**
 * For simple ids which are just a number. This is only necessary for the test ids in the client
 */
export class TestIdInfoAdapter implements EntityIdInfoAdapter {
  private readonly clientEntityIdInfoAdapter = new ClientEntityIdInfoAdapter();
  private readonly serverEntityIdAdapter = new ServerEntityIdAdapter();

  public isId(id: string): boolean {
    return (
      !this.clientEntityIdInfoAdapter.isId(id) &&
      !this.serverEntityIdAdapter.isId(id)
    );
  }

  public getNanoSecondsTimeStampFromId(id: string): number {
    const number = /([0-9]+)/.exec(id)?.[1];

    assertNotNullOrUndefined(
      number,
      `can't sort id "${id}" because it contains no number`
    );

    return parseInt(number);
  }

  public getNodeIdFromId(id: string): string {
    return id;
  }
}
