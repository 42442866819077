import jqueryCsv from 'jquery-csv';

import { autoinject, bindable } from 'aurelia-framework';

import { UnionToIntersection } from 'common/Types/utilities';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { BaseEntity } from '../../classes/EntityManager/entities/BaseEntity';
import { InfoTextType } from '../info-text/info-text';

@autoinject()
export class CsvImportWidget<T extends FieldInfoConfigContraint> {
  @bindable public fields: FieldInfosFromConfig<T> | null = null;

  @bindable public fileContents: string | null = null;

  @bindable public startRow = 1;
  @bindable public columnSeparator = ',';
  @bindable public decimalSeparator = ',';

  @bindable public columns: Record<string, string> = {};

  @bindable public notValid = false;

  @bindable public parsedData: Array<ParsedLineData<T>> = [];

  @bindable public totalNumberOfLines = 0;

  protected readonly InfoTextType = InfoTextType;

  private parsedContent: Array<LineData> = [];

  private columnContentWarningsTextTk: Record<string, string | null> = {};

  private availableColumns: Array<Column> = [];

  private isAttached = false;

  protected attached(): void {
    this.isAttached = true;

    this.resetColumns();
    this.parseFile();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected fileContentsChanged(): void {
    if (this.isAttached) {
      this.resetColumns();
      this.parseFile();
    }
  }

  protected columnSeparatorChanged(): void {
    if (this.isAttached) {
      this.resetColumns();
      this.parseFile();
    }
  }

  protected decimalSeparatorChanged(): void {
    if (this.isAttached) this.parseFile();
  }

  protected startRowChanged(): void {
    if (this.isAttached) this.parseFile();
  }

  protected handleFieldChanged(): void {
    this.updateData();
    this.validateColumns();
  }

  private validateColumns(): void {
    this.notValid = false;

    assertNotNullOrUndefined(this.fields, 'fields are not defined');

    for (const field of this.fields) {
      this.columnContentWarningsTextTk[field.field] = null;

      if (field.required && !this.columns[field.field]) {
        this.notValid = true;
        this.columnContentWarningsTextTk[field.field] =
          'aureliaComponents.csvImportWidget.fieldRequiredWarning';
        continue;
      }

      if (!this.validateFieldValue(field, this.parsedData)) {
        this.notValid = true;
        this.columnContentWarningsTextTk[field.field] =
          'aureliaComponents.csvImportWidget.wrongDataTypeWarning';
        continue;
      }

      if (field.validateCallback) {
        this.callValidateCallback(field);
        this.notValid =
          this.notValid ||
          (!!field.validationRequired &&
            !!this.columnContentWarningsTextTk[field.field]);
      }
    }
  }

  private validateFieldValue<TKey extends keyof T['fieldInfos']>(
    field: FieldInfo<T, TKey>,
    parsedData: Array<ParsedLineData<T>>
  ): boolean {
    return parsedData.every((lineData) => {
      const value = (lineData.fields as Record<string, any>)[field.field];
      switch (field.type) {
        case FieldType.BOOLEAN:
          return typeof value === 'boolean';

        case FieldType.NUMBER:
          return !Number.isNaN(value);

        default:
          return true;
      }
    });
  }

  private callValidateCallback<TKey extends keyof T['fieldInfos']>(
    fieldInfo: FieldInfo<T, TKey>
  ): boolean {
    if (!fieldInfo.validateCallback) return false;

    this.columnContentWarningsTextTk[fieldInfo.field] = null;

    const result = fieldInfo.validateCallback(this.parsedData);
    if (!result.valid && result.errorMsgTk) {
      this.columnContentWarningsTextTk[fieldInfo.field] = result.errorMsgTk;
    }

    return !result.valid;
  }

  private resetValues(): void {
    this.availableColumns = [];
    this.parsedContent = [];
    this.parsedData = [];
    this.columnContentWarningsTextTk = {};
    this.totalNumberOfLines = 0;
  }

  private resetColumns(): void {
    this.columns = {};
  }

  private parseFile(): void {
    if (!this.fileContents) return;
    this.resetValues();

    try {
      this.parsedContent = jqueryCsv.toObjects(this.fileContents, {
        separator: this.columnSeparator
      });
    } catch (error) {
      console.warn('CSV parse error:', error);
      return;
    }

    this.totalNumberOfLines = this.parsedContent.length;
    this.parsedContent.splice(0, this.startRow - 1);

    for (const lineData of this.parsedContent) {
      for (const [columnName, columnValue] of Object.entries(lineData)) {
        const availableColumn = this.getColumnByHeader(columnName);
        if (availableColumn) {
          availableColumn.lines.push(columnValue);
        } else {
          this.availableColumns.push({
            header: columnName,
            lines: [columnValue]
          });
        }
      }
    }

    this.setKnownColumns();
    this.updateData();
    this.validateColumns();
  }

  private setKnownColumns(): void {
    assertNotNullOrUndefined(this.fields, 'fields are not defined');

    for (const column of this.availableColumns) {
      const knownFieldByName: FieldInfosFromConfig<T>[number] | undefined =
        this.fields.find((f) => f.header === column.header);
      if (knownFieldByName) {
        assertNotNullOrUndefined(
          knownFieldByName.header,
          'found a field with no header'
        );

        this.columns[knownFieldByName.field] = knownFieldByName.header;
      }
    }
  }

  private updateData(): void {
    this.parsedData = this.parsedContent.map(this.parseLineData.bind(this));
  }

  private parseLineData(lineData: LineData): ParsedLineData<T> {
    const fields = this.fields;
    assertNotNullOrUndefined(fields, 'cannot parse line data without fields');

    const data: ParsedLineData<T> = {
      fields: {},
      additionalFields: {}
    };

    for (const [lineKey, lineValue] of Object.entries(lineData)) {
      const fieldKeys = [];
      for (const key in this.columns) {
        if (this.columns[key] === lineKey) fieldKeys.push(key);
      }

      if (fieldKeys.length) {
        fieldKeys.forEach((fK) => {
          const field = fields.find((fieldInfo) => fieldInfo.field === fK);
          const value = field
            ? this.getValueForField(field as FieldInfo<T, any>, lineValue)
            : lineValue;
          (data.fields as Record<string, any>)[fK] = value;
        });
      } else {
        data.additionalFields[lineKey] = lineValue;
      }
    }

    return data;
  }

  private getValueForField<
    TKey extends keyof T['fieldInfos'],
    TFieldInfo extends FieldInfo<T, TKey>
  >(
    fieldInfo: TFieldInfo,
    value: string
  ): FieldTypeToType<
    TFieldInfo['type'] extends FieldType ? TFieldInfo['type'] : never
  > {
    switch (fieldInfo.type) {
      case FieldType.BOOLEAN:
        return (value.toUpperCase() === 'JA') as any;

      case FieldType.NUMBER:
        const valueWithReplacedCommas = value.replace(
          this.decimalSeparator,
          '.'
        );
        if (!value || value === undefined) return NaN as any;
        return Number(valueWithReplacedCommas) as any;

      default:
        return value as any;
    }
  }

  private getColumnByHeader(header: string): Column | null {
    return this.availableColumns.find((c) => c.header === header) || null;
  }

  /**
   * @param header
   * @param parsedContent - used for auto updating
   * @param startRow - used for auto updating
   */
  protected getPreviewLinesOfColumn(
    header: string,
    _parsedContent: Array<LineData>,
    _startRow: number
  ): Array<string> {
    const column = this.getColumnByHeader(header);
    if (!column) return [];

    return column.lines.slice(0, 5);
  }
}

export type FieldInfoConfigContraint = {
  entityType: BaseEntity;
  fieldInfos: Array<FieldInfoConfig>;
};

export type FieldInfoConfig = {
  field: string;
  type: FieldType;
};

export type FieldInfosFromConfig<T extends FieldInfoConfigContraint> =
  FieldInfosFromConfig2<T, T['fieldInfos']>;

type FieldInfosFromConfig2<
  T extends FieldInfoConfigContraint,
  TFieldInfoConfigs extends FieldInfoConfigContraint['fieldInfos']
> = [
  ...{
    [key in keyof TFieldInfoConfigs]: key extends keyof T['fieldInfos']
      ? FieldInfo<T, key>
      : never;
  }
];

type FieldInfo<
  T extends FieldInfoConfigContraint,
  TKey extends keyof T['fieldInfos']
> = T['fieldInfos'][TKey] extends FieldInfoConfig
  ? T['fieldInfos'][TKey]['field'] extends keyof T['entityType']
    ? FieldInfoDefault<T['fieldInfos'][TKey], T>
    : FieldInfoExtraField<T['fieldInfos'][TKey], T>
  : never;

type CommonFieldInfo<
  TFieldInfoConfig extends FieldInfoConfig,
  T extends FieldInfoConfigContraint
> = {
  field: TFieldInfoConfig['field'];
  header?: string;
  headerTk?: string;
  required?: boolean;
  validateCallback?: ValidateCallback<T>;
  validationRequired?: boolean;
  ignoreField?: boolean;
};

type FieldInfoDefault<
  TFieldInfoConfig extends FieldInfoConfig,
  T extends FieldInfoConfigContraint
> = CommonFieldInfo<TFieldInfoConfig, T> & {
  type: TFieldInfoConfig['field'] extends keyof T['entityType']
    ? TFieldInfoConfig['type'] extends TypeToFieldType<
        T['entityType'][TFieldInfoConfig['field']]
      >
      ? TFieldInfoConfig['type']
      : `field has different type than property of entity, expected '${TypeToFieldType<
          T['entityType'][TFieldInfoConfig['field']]
        >}'`
    : 'field does not exist in entity';
  extraField?: false;
};

type FieldInfoExtraField<
  TFieldInfoConfig extends FieldInfoConfig,
  T extends FieldInfoConfigContraint
> = CommonFieldInfo<TFieldInfoConfig, T> & {
  type: TFieldInfoConfig['type'];
  extraField: true;
};

export enum FieldType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number'
}

type FieldTypeToType<T extends FieldType> = {
  [FieldType.STRING]: string;
  [FieldType.BOOLEAN]: boolean;
  [FieldType.NUMBER]: number;
}[T];

type TypeToFieldType<T> = T extends boolean
  ? FieldType.BOOLEAN
  : T extends string
    ? FieldType.STRING
    : T extends number
      ? FieldType.NUMBER
      : never;

type Column = {
  header: string;
  lines: Array<string>;
};

type ValidateCallback<T extends FieldInfoConfigContraint> = (
  parsedData: Array<ParsedLineData<T>>
) => ValidateCallbackResult;

export type ValidateCallbackResult = {
  valid: boolean;
  errorMsgTk?: string | null;
};

export type ParsedLineData<T extends FieldInfoConfigContraint> = {
  // UnionToIntersection ist hier nötig, damit man Record<'field1', type1> | Record<'field2', type2> quasi ein { 'field1': type1, 'field2': type2 } macht
  fields: ParsedFields<T['fieldInfos']>;
  additionalFields: LineData;
};

type ParsedFields<T extends Array<FieldInfoConfig>> = Partial<
  UnionToIntersection<
    {
      [key in keyof T]: Record<
        T[key]['field'],
        FieldTypeToType<T[key]['type']>
      >;
    }[number]
  >
>;

type LineData = Record<string, string>;
