import { EntityName } from '../../Types/BaseEntities/EntityName';
import { UserRoleEntitySpecificPermissionFields } from '../../Types/Entities/UserRole/UserRoleDto';
import { IsNever } from '../../Types/utilities';

/**
 * This function checks (on a typing base) if all EntityNames are defined and also if all UserRolePermissionFields are used.
 * This is to ensure that if you add a new field to the UserRolePermissionFields, that you can't forget to map it to an already existing entity name.
 *
 * Checking if the assignment makes sense or not (e.g. canUpdateFieldName = 'canCreateUserRoleToUsers') is not implemented yet.
 */
export function validateEntityNameToRoleInfo<
  T extends EntityNameToRoleInfoConstraint
>(
  options: { roleInfos: T } & ValidateEntityNameToRoleInfoValidationParams<T>
): T {
  return options.roleInfos;
}

type EntityNameToRoleInfoConstraint = Record<EntityName, RoleInfo>;

export type RoleInfo = {
  canCreateFieldName: keyof UserRoleEntitySpecificPermissionFields | null;
  canUpdateFieldName: keyof UserRoleEntitySpecificPermissionFields | null;
  canDeleteFieldName: keyof UserRoleEntitySpecificPermissionFields | null;
};

type ValidateEntityNameToRoleInfoValidationParams<
  T extends EntityNameToRoleInfoConstraint,
  TEachFieldNameIsUsedResult extends never | string = EachFieldNameIsUsed<T>
> =
  IsNever<TEachFieldNameIsUsedResult> extends true
    ? Record<never, never>
    : { [key in TEachFieldNameIsUsedResult]: false };

type FieldNames = keyof UserRoleEntitySpecificPermissionFields;

type EachFieldNameIsUsed<
  TEntityNameToRoleInfo extends EntityNameToRoleInfoConstraint
> = {
  [key in FieldNames]: true extends FieldNameIsUsed<TEntityNameToRoleInfo, key>
    ? never
    : `${key} is not used anywhere in the config`;
}[FieldNames];

type FieldNameIsUsed<
  TEntityNameToRoleInfo extends Record<string, RoleInfo>,
  TFieldName extends keyof UserRoleEntitySpecificPermissionFields
> = {
  [key in keyof TEntityNameToRoleInfo]: TEntityNameToRoleInfo[key]['canCreateFieldName'] extends TFieldName
    ? true
    : TEntityNameToRoleInfo[key]['canDeleteFieldName'] extends TFieldName
      ? true
      : TEntityNameToRoleInfo[key]['canUpdateFieldName'] extends TFieldName
        ? true
        : false;
}[keyof TEntityNameToRoleInfo];
