import { autoinject, bindable } from 'aurelia-framework';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { Question } from '../../classes/EntityManager/entities/Question/types';
import {
  QuestionSortOptions,
  QuestionUtils
} from '../../classes/EntityManager/entities/Question/QuestionUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class ChecklistQuestionList {
  @bindable public questionSet: QuestionSet | null = null;

  /**
   * question sets retrieved from a sorter.
   * sourced from `questionSets`.
   */
  protected sortedQuestions: Array<Question> = [];

  /**
   * question sets paginated by pagination.
   * sourced from `sortedQuestionSets`.
   */
  protected paginatedQuestions: Array<Question> = [];

  protected sortOptions = QuestionUtils.getSortOptions();

  protected currentSortOption: QuestionSortOptions[keyof QuestionSortOptions] =
    this.sortOptions.text;

  constructor(private readonly entityManager: AppEntityManager) {}

  @computed(expression('questionSet'), model(EntityName.Question))
  protected get questions(): Array<Question> {
    if (!this.questionSet) return [];

    return this.entityManager.questionRepository.getByQuestionSetId(
      this.questionSet.id
    );
  }
}
