import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { BaseEntityDto } from './BaseEntityUtils';
import { EntityName } from './EntityName';
import {
  UserGroupSubEntityDto,
  UserGroupSubEntityFieldInfos,
  UserGroupSubEntityUtils
} from './UserGroupSubEntityUtils';

export class ProjectSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): ProjectSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerProjectId: {
        name: 'ownerProjectId',
        relativeSynchronization: true,
        ref: EntityName.Project,
        cascadeDeleteLocally: true,
        additionalData: {
          ownerId: true
        }
      }
    };
  }

  public static createOptionalBaseFieldInfos(): OptionalProjectSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerProjectId: {
        name: 'ownerProjectId',
        relativeSynchronization: true,
        ref: EntityName.Project,
        cascadeDeleteLocally: true,
        defaultValue: null,
        additionalData: {
          ownerId: true
        }
      }
    };
  }
}

export type ProjectSubEntityFieldInfos = UserGroupSubEntityFieldInfos & {
  ownerProjectId: EntityFieldInfo<
    AppCommonSynchronizationEnvironmentTypes,
    ProjectSubEntityDto,
    'ownerProjectId'
  >;
};

export type ProjectSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerProjectId: string;
  };

export type OptionalProjectSubEntityFieldInfos =
  UserGroupSubEntityFieldInfos & {
    ownerProjectId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      OptionalProjectSubEntityDto,
      'ownerProjectId'
    >;
  };

export type OptionalProjectSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerProjectId: string | null;
  };
