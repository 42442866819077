// @ts-nocheck - TODO: remove this when tools are refactored

import { SvgPointerEventNormalizer } from './SvgPointerEventNormalizer';
import { PointerEventHelper } from '../../../classes/PointerEventHelper';
import { Tool } from './Tool';

/**
 * be careful: this class is dependend on some private properties of the Tool!
 */
export class ToolEventDelegator {
  private tool: Tool;

  private lastTouchstartTouch: TouchEvent | null = null;
  private lastMouseDownEvent: MouseEvent | null = null;

  /**
   * since the click event will still be fired after dragging a lot of distance (why browsers?) we have prevent it manuall
   */
  private preventNextMouseClicked: boolean = false;

  constructor(tool: Tool) {
    this.tool = tool;
  }

  public reset(): void {
    this.lastMouseDownEvent = null;
    this.lastTouchstartTouch = null;
  }

  public mouseClicked(event: MouseEvent): void {
    if (this.tool.isActive()) {
      if (!this.preventNextMouseClicked) {
        this.tool._mouseClickedHandler(event, this.normalizeMouseEvent(event));
      } else {
        this.preventNextMouseClicked = false;
      }
    }
  }

  public mouseMoved(event: MouseEvent): void {
    if (this.tool.isActive()) {
      this.updateIsDragging(event, this.lastMouseDownEvent);

      const position = this.normalizeMouseEvent(event);
      this.tool._mouseMovedHandler(event, position);

      if (this.tool._isDragging) {
        this.tool._draggingHandler(event, position);
        this.tool._lastDraggingPosition = position;
      }
    }
  }

  public mouseEntered(event: MouseEvent): void {
    if (!event.buttons) this.endMouseDragging(event);

    if (this.tool.isActive()) {
      this.tool._mouseEnteredHandler(event, this.normalizeMouseEvent(event));
    }
  }

  public mouseLeft(event: MouseEvent): void {
    if (this.tool.isActive()) {
      this.tool._mouseLeftHandler(event, this.normalizeMouseEvent(event));
    }
  }

  public mouseDown(event: MouseEvent): void {
    if (this.tool.isActive()) {
      this.lastMouseDownEvent = event;
      this.tool._dragStartPosition = this.normalizeMouseEvent(event);
    }
  }

  public mouseUp(event: MouseEvent): void {
    if (this.tool.isActive()) {
      this.lastMouseDownEvent = null;
      if (this.endMouseDragging(event)) {
        event.preventDefault(); // prevent event from creating a click event
      }
    }
  }

  // //////////////// Touch events

  public touchStarted(event: Event): void {
    if (this.tool.isActive()) {
      // noinspection JSUnresolvedVariable
      this.lastTouchstartTouch = event.targetTouches[0];
      this.tool._dragStartPosition = this.normalizeTouchEvent(event);
      this.tool._touchStartedHandler(event, this.tool._dragStartPosition);
    }
  }

  public touchMoved(event: Event): void {
    if (this.tool.isActive()) {
      // noinspection JSUnresolvedVariable
      this.updateIsDragging(event.targetTouches[0], this.lastTouchstartTouch);

      const position = this.normalizeTouchEvent(event);
      this.tool._touchMovedHandler(event, position);

      if (this.tool._isDragging) {
        this.tool._draggingHandler(event, position);
        this.tool._lastDraggingPosition = position;
      }
    }
  }

  /**
   * do not override this, but the _touchEndedHandler instead
   */
  public touchEnded(event: Event): void {
    if (this.tool.isActive()) {
      const position = this.normalizeTouchEvent(event);
      this.tool._touchEndedHandler(event, position);
      if (this.tool._isDragging) {
        this.tool._isDragging = false;
        // we don't have a position on the touch end, so we use the last dragging position
        this.tool._draggingEndedHandler(event, this.tool._lastDraggingPosition);
      }
    }
  }

  private endMouseDragging(event: MouseEvent): boolean {
    if (this.tool._isDragging) {
      this.tool._isDragging = false;
      this.tool._draggingEndedHandler(event, this.normalizeMouseEvent(event));
      this.preventNextMouseClicked = true;
      return true;
    }
    return false;
  }

  private updateIsDragging(
    currentTouchOrMouseEvent: MouseEvent | any,
    startTouchOrMouseEvent: MouseEvent | any
  ): void {
    if (!this.tool._isDragging && startTouchOrMouseEvent) {
      const distance = PointerEventHelper.calculateClientDistance(
        currentTouchOrMouseEvent,
        startTouchOrMouseEvent
      );
      this.tool._isDragging =
        distance >
        (this.tool.instantDraggingActivated()
          ? 0
          : PointerEventHelper.minDragDistance);
      this.tool._lastDraggingPosition = this.tool._dragStartPosition;
    }
  }

  /**
   * normalizes the event to canvas coordinates and aligns it to the grid
   */
  private normalizeMouseEvent(event: MouseEvent): Msvg.Point {
    return this.alignPointToGridAndHighlight(
      SvgPointerEventNormalizer.normalizeMouseEvent(event, this.tool.getMsvg())
    );
  }

  /**
   * normalizes the event to canvas coordinates and aligns it to the grid
   */
  private normalizeTouchEvent(event: Event): Msvg.Point {
    return this.alignPointToGridAndHighlight(
      SvgPointerEventNormalizer.normalizeTouchEvent(event, this.tool.getMsvg())
    );
  }

  private alignPointToGridAndHighlight(
    normalizedPoint: Msvg.Point
  ): Msvg.Point {
    if (
      this.tool._grid.isEnabled() &&
      this.tool._grid.isNearGridPoint(normalizedPoint, 0.5)
    ) {
      const point = this.tool._grid.alignPointToGrid(normalizedPoint);
      this.tool._grid.setHighlightedPoint(point);
      return point;
    } else {
      this.tool._grid.setHighlightedPoint(null);
      return normalizedPoint;
    }
  }
}
