import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskLogEntryDto } from './ProcessTaskLogEntryDto';

export function createProcessTaskLogEntryEntityInfo<
  TEntity extends ProcessTaskLogEntryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskLogEntryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskLogEntry,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskLogEntry,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskLogEntryDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          ref: EntityName.User
        },
        logAction: {
          name: 'logAction'
        },
        logDataJson: {
          name: 'logDataJson',
          defaultValue: null
        },
        date: {
          name: 'date'
        }
      },
      options.additionalFieldInfos
    )
  };
}
