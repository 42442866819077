import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ValueChangedEvent } from '../../inputComponents/coordinate-input/coordinate-input';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertyCoordinateWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyCoordinateWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected latitude: number | null = null;
  protected longitude: number | null = null;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    throw new Error("BasePropertyCoordinateWidget doesn't support focus");
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'configuration.binding.value',
      () => {
        this.updateLatitudeAndLongitude();
      }
    );

    this.updateLatitudeAndLongitude();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleValueChanged(event: ValueChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyCoordinateWidget.handleValueChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: `${event.detail.latitude};${event.detail.longitude}`,
      customChoice: null
    });
  }

  private updateLatitudeAndLongitude(): void {
    const value = this.configuration?.binding.value;
    if (value) {
      const valueSplit = value.split(';');
      this.latitude = this.parseCoordinate(valueSplit[0]);
      this.longitude = this.parseCoordinate(valueSplit[1]);
    } else {
      this.latitude = null;
      this.longitude = null;
    }
  }

  private parseCoordinate(
    coordinate: string | null | undefined
  ): number | null {
    if (coordinate == null) {
      return null;
    }

    const parsed = parseFloat(coordinate);
    if (isNaN(parsed)) {
      return null;
    }

    return parsed;
  }
}

export const basePropertyCoordinateWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
