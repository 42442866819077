import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  GroupedProperties,
  GroupedPropertyHelper
} from './GroupedPropertyHelper';
import {
  EntryProperty,
  ProcessConfigurationDeviceProperty,
  ProcessTaskDeviceProperty,
  ProcessTaskMeasurePointReadingProperty,
  ProcessTaskPositionDetailEntryProperty,
  ProcessTaskAppointmentProperty,
  ProcessTaskPositionProperty,
  ProcessTaskProperty,
  ProjectProperty,
  Property,
  RegionProperty,
  ThingGroupProperty,
  ThingProperty,
  ThingTypeProperty,
  DefectProperty,
  ThingThingSectionProperty,
  ProjectThingSectionProperty,
  ProcessConfigurationStepPositionProperty,
  UserDefinedEntityProperty
} from './types';

export class PropertyRepository extends AppEntityRepository<EntityName.Property> {
  public getByProjectId(projectId: string): Array<ProjectProperty> {
    return this.getAll().filter((p): p is ProjectProperty => {
      return p.project === projectId;
    });
  }

  public getByEntryId(entryId: string): Array<EntryProperty> {
    return this.getAll().filter((p): p is EntryProperty => {
      return p.entry === entryId;
    });
  }

  public getByDefectId(defectId: string): Array<DefectProperty> {
    return this.getByDefectIds([defectId]);
  }

  public getByDefectIds(defectIds: Array<string>): Array<DefectProperty> {
    return this.getAll().filter((p): p is DefectProperty => {
      return !!p.defect && defectIds.includes(p.defect);
    });
  }

  public getByThingId(thingId: string): Array<ThingProperty> {
    return this.getAll().filter((p): p is ThingProperty => {
      return p.thing === thingId;
    });
  }

  public getByThingThingSectionId(
    thingThingSectionId: string
  ): Array<ThingThingSectionProperty> {
    return this.getAll().filter((p): p is ThingThingSectionProperty => {
      return p.thingThingSectionId === thingThingSectionId;
    });
  }

  public getByThingSectionAndProjectId({
    thingSectionId,
    projectId
  }: {
    thingSectionId: string;
    projectId: string;
  }): Array<ProjectThingSectionProperty> {
    return this.getAll().filter((p): p is ProjectThingSectionProperty => {
      return (
        p.projectThingSectionId === thingSectionId &&
        p.ownerProjectId === projectId
      );
    });
  }

  public getByThingTypeIds(
    thingTypeIds: Array<string>
  ): Array<ThingTypeProperty> {
    return this.getAll().filter((p): p is ThingTypeProperty => {
      return !!p.thingType && thingTypeIds.includes(p.thingType);
    });
  }

  public getByThingTypeId(thingTypeId: string): Array<ThingTypeProperty> {
    return this.getByThingTypeIds([thingTypeId]);
  }

  public getByRegionId(regionId: string): Array<RegionProperty> {
    return this.getAll().filter((p): p is RegionProperty => {
      return p.regionId === regionId;
    });
  }

  public getByProcessConfigurationDeviceId(
    processConfigurationDeviceId: string
  ): Array<ProcessConfigurationDeviceProperty> {
    return this.getAll().filter(
      (p): p is ProcessConfigurationDeviceProperty => {
        return p.processConfigurationDeviceId === processConfigurationDeviceId;
      }
    );
  }

  public getByProcessTaskDeviceId(
    processTaskDeviceId: string
  ): Array<ProcessTaskDeviceProperty> {
    return this.getAll().filter((p): p is ProcessTaskDeviceProperty => {
      return p.processTaskDeviceId === processTaskDeviceId;
    });
  }

  public getByProcessTaskPositionId(
    processTaskPositionId: string
  ): Array<ProcessTaskPositionProperty> {
    return this.getAll().filter((p): p is ProcessTaskPositionProperty => {
      return p.processTaskPositionId === processTaskPositionId;
    });
  }

  public getByProcessTaskPositionDetailEntryId(
    processTaskPositionDetailEntryId: string
  ): Array<ProcessTaskPositionDetailEntryProperty> {
    return this.getAll().filter(
      (p): p is ProcessTaskPositionDetailEntryProperty => {
        return (
          p.processTaskPositionDetailEntryId ===
          processTaskPositionDetailEntryId
        );
      }
    );
  }

  public getProcessTaskPositionDetailEntryPropertiesByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskPositionDetailEntryProperty> {
    return this.getAll().filter(
      (p): p is ProcessTaskPositionDetailEntryProperty => {
        return (
          p.processTaskPositionDetailEntryId != null &&
          p.ownerProcessTaskId === processTaskId
        );
      }
    );
  }

  public getByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentProperty> {
    return this.getAll().filter((p): p is ProcessTaskAppointmentProperty => {
      return p.processTaskAppointmentId === processTaskAppointmentId;
    });
  }

  public getByProcessTaskGroupId(processTaskGroupId: string): Array<Property> {
    return this.getAll().filter((p): p is Property => {
      return p.ownerProcessTaskGroupId === processTaskGroupId;
    });
  }

  public getByProcessTaskId(processTaskId: string): Array<ProcessTaskProperty> {
    return this.getAll().filter((p): p is ProcessTaskProperty => {
      return p.processTaskId === processTaskId;
    });
  }

  public getByOwnerProcessTaskId(ownerProcessTaskId: string): Array<Property> {
    return this.getAll().filter((p): p is Property => {
      return p.ownerProcessTaskId === ownerProcessTaskId;
    });
  }

  public getByThingGroupId(thingGroupId: string): Array<ThingGroupProperty> {
    return this.getAll().filter((p): p is ThingGroupProperty => {
      return p.thingGroupId === thingGroupId;
    });
  }

  public getByProcessConfigurationStepPositionId(
    processConfigurationStepPositionId: string
  ): Array<ProcessConfigurationStepPositionProperty> {
    return this.getAll().filter(
      (p): p is ProcessConfigurationStepPositionProperty => {
        return (
          p.processConfigurationStepPositionId ===
          processConfigurationStepPositionId
        );
      }
    );
  }

  public getByUserDefinedEntityId(
    userDefinedEntityId: string
  ): Array<UserDefinedEntityProperty> {
    return this.getAll().filter((p): p is UserDefinedEntityProperty => {
      return p.userDefinedEntityId === userDefinedEntityId;
    });
  }

  public getGroupedPropertiesByProjectId(
    projectId: string
  ): GroupedProperties<ProjectProperty> {
    return this.getGroupedPropertiesOfEntity((p): p is ProjectProperty => {
      return p.project === projectId && this.isActiveOrAlwaysVisible(p);
    });
  }

  public getGroupedPropertiesByEntryId(
    entryId: string
  ): GroupedProperties<EntryProperty> {
    return this.getGroupedPropertiesOfEntity((p): p is EntryProperty => {
      return p.entry === entryId && this.isActiveOrAlwaysVisible(p);
    });
  }

  public getGroupedPropertiesByDefectId(
    defectId: string
  ): GroupedProperties<DefectProperty> {
    return this.getGroupedPropertiesOfEntity((p): p is DefectProperty => {
      return p.defect === defectId && this.isActiveOrAlwaysVisible(p);
    });
  }

  public getGroupedPropertiesByThingId(
    thingId: string
  ): GroupedProperties<ThingProperty> {
    return this.getGroupedPropertiesOfEntity((p): p is ThingProperty => {
      return p.thing === thingId;
    });
  }

  public getSortedPropertiesByProcessTaskMeasurePointReadingId(
    processTaskMeasurePointReadingId: string
  ): Array<ProcessTaskMeasurePointReadingProperty> {
    return this.getSortedDisplayedPropertiesOfEntity(
      (p): p is ProcessTaskMeasurePointReadingProperty => {
        return (
          p.processTaskMeasurePointReadingId ===
          processTaskMeasurePointReadingId
        );
      }
    );
  }

  private getGroupedPropertiesOfEntity<T extends Property>(
    filterFunction: (p: Property) => p is T
  ): GroupedProperties<T> {
    const activeProperties =
      this.getSortedDisplayedPropertiesOfEntity(filterFunction);

    return GroupedPropertyHelper.groupProperties(activeProperties);
  }

  private getSortedDisplayedPropertiesOfEntity<T extends Property>(
    filterFunction: (p: Property) => p is T
  ): Array<T> {
    const filteredEntities = this.getAll().filter((property): property is T => {
      return filterFunction(property);
    });

    filteredEntities.sort((a, b) => {
      const aOrder = a.order ?? Number.MAX_SAFE_INTEGER;
      const bOrder = b.order ?? Number.MAX_SAFE_INTEGER;

      if (aOrder !== bOrder) {
        return aOrder - bOrder;
      }

      return a.id.localeCompare(b.id);
    });

    return filteredEntities;
  }

  private isActiveOrAlwaysVisible(property: Property): boolean {
    return !!property.active || !!property.alwaysVisible;
  }
}
