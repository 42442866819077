import { autoinject } from 'aurelia-framework';

import { CreateThingDialog } from '../../dialogs/create-thing-dialog/create-thing-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditThingDialog } from '../../dialogs/edit-thing-dialog/edit-thing-dialog';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskCreationService } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskCreationService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { UserGroupPermissionsHandle } from '../../services/PermissionsService/EntitySpecificPermissionsHandle/UserGroupPermissionsHandle';
import { BehaviorSubject } from 'rxjs';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class AddProcessTaskDialog {
  public static async open(
    options: AddProcessTaskDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;
  private readonly userGroup$ = new BehaviorSubject<UserGroup | null>(null);

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: UserGroupPermissionsHandle;

  private processTaskGroup: ProcessTaskGroup | null = null;
  private onDialogClosed: OnDialogClosed | null = null;

  private selectedThingId: string | null = null;
  private lastAddedProcessTask: ProcessTask | null = null;

  protected excludedThingIds: Array<string> = [];
  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly processTaskCreationService: ProcessTaskCreationService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.userGroupPermissionsHandle = permissionsService.getPermissionsHandle({
      entityName: EntityName.UserGroup,
      entity$: this.userGroup$
    });
  }

  public open(options: AddProcessTaskDialogOpenOptions): void {
    this.processTaskGroup = options.processTaskGroup;
    this.onDialogClosed = options.onDialogClosed;

    this.userGroup$.next(
      this.entityManager.userGroupRepository.getById(
        this.processTaskGroup.ownerUserGroupId
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Thing,
        this.updateSelectedThingId.bind(this)
      )
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateExcludedThingIds.bind(this)
    );
    this.updateExcludedThingIds();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const lastAddedProcessTask = this.lastAddedProcessTask;

    this.processTaskGroup = null;
    this.lastAddedProcessTask = null;
    this.userGroup$.next(null);
    this.subscriptionManager.disposeSubscriptions();

    onClosed?.(lastAddedProcessTask);
  }

  private updateSelectedThingId(): void {
    if (this.selectedThingId) {
      const thing = this.entityManager.thingRepository.getByOriginalId(
        this.selectedThingId
      );
      if (thing) {
        this.selectedThingId = thing.id;
      }
    }
  }

  private updateExcludedThingIds(): void {
    if (this.processTaskGroup) {
      const processTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskGroup.id
        );
      this.excludedThingIds = processTasks.map((p) => p.thingId);
    } else {
      this.excludedThingIds = [];
    }

    if (
      this.selectedThingId != null &&
      this.excludedThingIds.includes(this.selectedThingId)
    ) {
      this.selectedThingId = null;
    }
  }

  protected handleEditThingClick(): void {
    const thing = this.selectedThingId
      ? this.entityManager.thingRepository.getById(this.selectedThingId)
      : null;
    if (!thing) {
      return;
    }

    void EditThingDialog.open({
      thing: thing,
      thingGroupSelectionEnabled: false
    });
  }

  protected handleCreateThingClick(): void {
    if (!this.processTaskGroup) {
      return;
    }

    void CreateThingDialog.open({
      thingGroupId: this.processTaskGroup.thingGroupId,
      userGroupId: this.processTaskGroup.ownerUserGroupId,
      onDialogClosed: (createdThing) => {
        if (createdThing) {
          this.selectedThingId = createdThing.id;
        }
      }
    });
  }

  protected handleAcceptButtonClicked(): void {
    if (!this.selectedThingId || !this.processTaskGroup) {
      return;
    }

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        this.processTaskGroup.processConfigurationId
      );
    const firstStep = steps[0];
    if (!firstStep) {
      return;
    }

    const createdProcessTask =
      this.processTaskCreationService.createProcessTask({
        thingId: this.selectedThingId,
        currentProcessConfigurationStepId: firstStep.id,
        ownerProcessTaskGroupId: this.processTaskGroup.id,
        ownerUserGroupId: this.processTaskGroup.ownerUserGroupId,
        temporaryGroupName: this.processTaskGroup.temporaryGroupName,
        shadowEntity: this.processTaskGroup.shadowEntity
      });

    void this.processTaskLoggingService.logProcessTaskCreated(
      createdProcessTask
    );

    this.lastAddedProcessTask = createdProcessTask;
  }
}

export type AddProcessTaskDialogOpenOptions = {
  processTaskGroup: ProcessTaskGroup;
  onDialogClosed: OnDialogClosed | null;
};

export type OnDialogClosed = (addedProcessTask: ProcessTask | null) => void;
