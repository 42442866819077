import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { PersonUtils } from './PersonUtils';
import { Person } from './types';

export class PersonFilterer {
  private searchWords: Array<string> = [];
  private personsToIgnore: Map<Person, boolean> = new Map();

  /**
   * @param ignoreEmptyFilterText - if this is true, then all persons will be returned if no filterText was given, else no person will be returned
   */
  constructor(private readonly ignoreEmptyFilterText = true) {}

  public clear(): void {
    this.searchWords = [];
    this.personsToIgnore.clear();
  }

  public addFilterText(filterText: string | null): void {
    const words = filterText ? StringUtils.splitWords(filterText) : [];
    words.forEach((w) => {
      this.searchWords.push(w.toLocaleLowerCase());
    });
  }

  /**
   * ignored persons will never be included in the result
   */
  public addPersonToIgnore(person: Person): void {
    this.personsToIgnore.set(person, true);
  }

  public filter(persons: Array<Person>): Array<FilterResult> {
    if (this.searchWords.length === 0 && this.ignoreEmptyFilterText) {
      return [];
    }

    const results: Array<FilterResult> = [];

    persons.forEach((person) => {
      if (this.personsToIgnore.get(person) === true) {
        return;
      }

      const name = PersonUtils.getPersonDisplayNameForPerson(person);
      const matchCount = StringUtils.matchWords(
        name.toLocaleLowerCase(),
        this.searchWords
      );
      if (matchCount > 0 || this.searchWords.length === 0) {
        results.push({ matchCount, person });
      }
    });

    results.sort((a, b) => a.matchCount - b.matchCount);

    return results;
  }
}

export type FilterResult = {
  matchCount: number;
  person: Person;
};
