import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createUserDefinedEntityEntityInfo } from 'common/Types/Entities/UserDefinedEntity/createUserDefinedEntityEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { UserDefinedEntity } from './types';

export const userDefinedEntityEntityInfo =
  createUserDefinedEntityEntityInfo<UserDefinedEntity>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
