import { autoinject, bindable } from 'aurelia-framework';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @slot footer - shows the close button if not used
 * @event panel-closed - fired when panel was closed
 */
@autoinject()
export class ParameterPanel {
  @bindable public title = '';

  @bindable public showOnlySmallCloseOnTop = false;

  @bindable public otherParameterPanelsOnPage: Array<
    ParameterPanel | undefined
  > = [];

  @bindable public isExpanded = false; // read-only!

  /**
   * readonly!!
   *
   * this is set to true if the content should be shown (happens slightly before the show animation starts)
   * useful to free up some resources while the panel is collapsed
   *
   * this feature is optional and content can be rendered all the time
   *
   * also showContent will be false after collapsing the
   */
  @bindable public showContent = false;

  private domElement: HTMLElement;

  private showHideAnimator: ShowHideAnimator | null = null;
  private showAnimationTimeoutHandle: number | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected bind(): void {
    if (!this.showHideAnimator)
      this.showHideAnimator = new ShowHideAnimator(this.domElement);
    this.domElement.style.display = 'none';
  }

  public expand(): void {
    if (this.isExpanded) return;

    this.showContent = true;

    const showHideAnimator = this.showHideAnimator;
    if (showHideAnimator) {
      this.showAnimationTimeoutHandle = window.setTimeout(() => {
        void showHideAnimator.slideDown();
      }, 0);
    }

    this.isExpanded = true;
  }

  public async collapse(): Promise<void> {
    if (!this.isExpanded) return;

    if (this.showAnimationTimeoutHandle) {
      window.clearTimeout(this.showAnimationTimeoutHandle);
      this.showAnimationTimeoutHandle = null;
    }

    if (this.showHideAnimator) {
      await this.showHideAnimator.slideUp();

      this.showContent = false;
      this.fireCloseEvent();
    }
    this.isExpanded = false;
  }

  public async toggle(): Promise<void> {
    if (this.isExpanded) {
      await this.collapse();
    } else {
      await this.collapseOtherPanelsIfNecessary();
      this.expand();
    }
  }

  private fireCloseEvent(): void {
    DomEventHelper.fireEvent<PanelClosedEvent>(this.domElement, {
      name: 'panel-closed',
      detail: null
    });
  }

  private async collapseOtherPanelsIfNecessary(): Promise<void> {
    await Promise.all(
      this.otherParameterPanelsOnPage
        .filter((x): x is ParameterPanel => !!x && x.isExpanded)
        .map((x) => x.collapse())
    );
  }
}

type PanelClosedEvent = NamedCustomEvent<'panel-closed', null>;
