import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { OriginatedFromPictureInfo } from 'common/Types/Entities/Picture/PictureDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingSectionPicture } from '../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { ThingSection } from '../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectPictureDialog } from '../../dialogs/select-picture-dialog/select-picture-dialog';
import { SelectPictureDialogThingSectionAdapter } from '../../dialogs/select-picture-dialog/SelectPictureDialogAdapter/SelectPictureDialogThingSectionAdapter/SelectPictureDialogThingSectionAdapter';
import { CreatePictureCutoutOverlay } from '../../picture/create-picture-cutout-overlay/create-picture-cutout-overlay';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ExistingThingGroupCutoutsAdapter } from '../../picture/create-picture-cutout-overlay/ExistingCutoutsAdapter/ExistingThingGroupCutoutsAdapter/ExistingThingGroupCutoutsAdapter';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class ThingSectionPicturesWidget {
  @bindable()
  public thingSection: ThingSection | null = null;

  /**
   * If this is set to false, the user is not allowed to do anything
   * If this is set to true, the user is allowed to do anything that the permissionsService allows
   */
  @bindable()
  public enabled: boolean = false;

  @subscribableLifecycle()
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingSection];

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected pictures: Array<ThingSectionPicture> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingSection,
        context: this,
        expression: 'thingSection'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictures.bind(this)
    );
    this.updatePictures();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingSectionChanged(): void {
    if (this.isAttached) {
      this.updatePictures();
    }
  }

  private updatePictures(): void {
    if (this.thingSection) {
      this.pictures = this.entityManager.pictureRepository.getByThingSectionId(
        this.thingSection.id
      );
    } else {
      this.pictures = [];
    }
  }

  protected handleAddButtonClick(): void {
    const thingSection = this.thingSection;
    assertNotNullOrUndefined(
      thingSection,
      "can't ThingSectionPicturesWidget.handleAddButtonClick without thingSection"
    );

    const adapter = new SelectPictureDialogThingSectionAdapter({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      thingSection
    });

    void SelectPictureDialog.open({
      adapter,
      onPictureSelected: ({ picture }) => {
        const existingCutoutsAdapter = new ExistingThingGroupCutoutsAdapter({
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          picture
        });

        void CreatePictureCutoutOverlay.open({
          picture,
          title: thingSection.name ?? '',
          existingCutoutsAdapter,
          onCutoutFinished: ({ cutoutDataUrl, originatedFromPictureInfo }) => {
            this.handleCutoutFinished({
              cutoutDataUrl,
              originatedFromPictureInfo,
              thingSection
            });
          }
        });
      }
    });
  }

  private handleCutoutFinished({
    cutoutDataUrl,
    originatedFromPictureInfo,
    thingSection
  }: {
    cutoutDataUrl: string;
    originatedFromPictureInfo: OriginatedFromPictureInfo;
    thingSection: ThingSection;
  }): void {
    const newPicture = this.entityManager.pictureRepository.create({
      coords: null,
      takenAt: new Date().toISOString(),
      originatedFromPictureInfo: originatedFromPictureInfo,
      thingSectionId: thingSection.id,
      ownerThingId: thingSection.ownerThingId,
      ownerUserGroupId: thingSection.ownerUserGroupId
    });

    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      newPicture,
      cutoutDataUrl
    );
  }

  @computed(
    expression('enabled'),
    expression('pictures.length'),
    expression('permissionsHandle.canEditPictures')
  )
  protected get createPictureButtonVisible(): boolean {
    return (
      this.enabled &&
      this.permissionsHandle.canEditPictures &&
      this.pictures.length === 0
    );
  }
}
