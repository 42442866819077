import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SocketService } from '../../services/SocketService';
import { SelectCoordinatesOnPositionedPictureOverlay } from '../../dialogs/select-coordinates-on-positioned-picture-overlay/select-coordinates-on-positioned-picture-overlay';
import { PictureFullScreenOverlay } from '../../aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { GeoDataCacheService } from '../../services/GeoDataCacheService';
import { GisService } from '../../services/GisService';
import { SelectThingPictureDialog } from '../../dialogs/select-thing-picture-dialog/select-thing-picture-dialog';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GisPropertyName } from '../../../../common/src/GisParsingHelper/GisParsingHelper';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Picture as EntityPicture } from '../../classes/EntityManager/entities/Picture/types';
import {
  Property,
  PropertyCreationBaseData
} from '../../classes/EntityManager/entities/Property/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { Picture } from 'src/picture/picture/picture';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { EntryDeletionService } from '../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * this is meant to be a single global instance!
 * also supports the instance demander
 *
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class GalleryThingEditEntryDialog {
  @subscribableLifecycle()
  protected readonly entryPicturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private entry: Entry | null = null;
  private thingId: string | null = null;
  private entryPicture: EntityPicture | null = null;
  private userGroupId: string | null = null;

  protected isApp = false;
  protected enabled = false;

  private dialog: RecordItDialog | null = null;

  protected isEditingPicture = false;

  @observable private canUseViaWildbach = false;

  private municipalityCode: string | null = null;
  private generalProperties: Array<Property> = [];
  private gisProperties: Array<Property> = [];
  private companySettingEntryProperties: {
    properties: Array<PropertyCreationBaseData>;
  } = { properties: [] };

  protected estateOwners: Array<any> = [];

  protected showWildbachOwnerSyncError = false;

  protected isOpen = false;
  protected isOnline = false;

  protected picture: Picture | null = null;

  private isActualizing: boolean;

  private subscriptionManager: SubscriptionManager;
  private permissionBindings: PermissionBindingHandle;
  protected onMapReadyBound = this.onMapReady.bind(this);

  constructor(
    private i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    private socketService: SocketService,
    private geoDataCacheService: GeoDataCacheService,
    private gisService: GisService,
    private pictureSketchingService: PictureSketchingService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindings = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseViaWildbach: 'canUseViaWildbach'
      }
    });

    this.entryPicturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'entryPicture'
      });

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Entry,
        context: this,
        expression: 'entry'
      });

    this.canUseViaWildbach = false;
    this.isActualizing = true;
  }

  protected attached(): void {
    this.permissionBindings.subscribe();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindings.unsubscribe();
  }

  public open(options: TGalleryThingEditEntryDialogOptions): void {
    this.entry = options.entry;
    this.thingId = options.thingId;
    this.userGroupId = options.userGroupId;
    this.enabled = options.enabled;
    if (this.dialog) this.dialog.open();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateEntryPicture.bind(this)
    );
    this.updateEntryPicture();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateEntryProperties.bind(this)
    );
    this.updateEntryProperties();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.isApp = DeviceInfoHelper.isApp();

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this.isActualizing = isActualizing;
          this.syncWithDefaultProperties();
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.predefinedEntryProperties',
        (predefinedEntryProperties) => {
          this.companySettingEntryProperties = predefinedEntryProperties;
          this.syncWithDefaultProperties();
        }
      )
    );

    this.updateMunicipalityCode();
    this.estateOwners = [];
    this.showWildbachOwnerSyncError = false;
    this.isOpen = true;
    if (this.canUseViaWildbach && this.gisProperties.length === 0)
      void this.syncEstateOwnersIgnoringErrors();
  }

  public close(): void {
    if (this.dialog) this.dialog.close();
  }

  protected handleDialogClosed(): void {
    this.isEditingPicture = false;
    this.subscriptionManager.disposeSubscriptions();
    this.isOpen = false;
    this.entry = null;
  }

  private updateEntryPicture(): void {
    if (this.entry) {
      // because of the rate limit, disposed subscriptions can still trigger
      this.entryPicture =
        this.entityManager.pictureRepository.getSelectedEntryPicture(
          this.entry.id
        ) || null;
      if (!this.entryPicture) {
        this.isEditingPicture = false; // can't edit if there is no picture
      }
    }
  }

  private updateEntryProperties(): void {
    if (this.entry) {
      const properties = this.entityManager.propertyRepository
        .getByEntryId(this.entry.id)
        .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
      this.generalProperties = properties.filter(
        (p) => !this.hasGisPropertyName(p.name ?? '')
      );
      this.gisProperties = properties.filter((p) =>
        this.hasGisPropertyName(p.name ?? '')
      );
    }
  }

  private updateMunicipalityCode(): void {
    if (this.thingId) {
      const municipalityProperty = this.entityManager.propertyRepository
        .getByThingId(this.thingId)
        .find((property) => property.name === 'Gemeindecode');
      this.municipalityCode = municipalityProperty
        ? municipalityProperty.value
        : null;
    } else {
      this.municipalityCode = null;
    }
  }

  private async syncEstateOwners(ignoreErrors = false): Promise<void> {
    this.showWildbachOwnerSyncError = false;
    if (
      !this.entry ||
      !this.entryPicture ||
      !this.municipalityCode ||
      !this.canUseViaWildbach ||
      !this.userGroupId ||
      !this.entryPicture.coords?.latitude ||
      !this.entryPicture.coords?.longitude
    )
      return;
    try {
      await this.gisService.syncGstInfoWithEntry(
        this.entry,
        this.entryPicture.coords?.latitude,
        this.entryPicture.coords?.longitude,
        this.municipalityCode
      );
    } catch (e) {
      if (!ignoreErrors) this.showWildbachOwnerSyncError = true;
    }
  }

  private async syncEstateOwnersIgnoringErrors(): Promise<void> {
    return await this.syncEstateOwners(true);
  }

  protected canUseViaWildbachChanged(): void {
    this.syncWithDefaultProperties();
  }

  private syncWithDefaultProperties(): void {
    if (this.isActualizing) return;
    if (!this.entry) return;
    if (this.companySettingEntryProperties) {
      const defaultProperties =
        this.companySettingEntryProperties?.properties ?? [];
      for (const defaultProperty of defaultProperties) {
        const existingProperty = this.generalProperties.find(
          (prop) => prop.name === defaultProperty.name
        );
        if (!existingProperty) {
          this.entityManager.propertyRepository.create({
            ownerUserGroupId: this.entry.ownerUserGroupId,
            entry: this.entry.id,
            ownerProjectId: this.entry.ownerProjectId,
            ...defaultProperty
          });
        }
      }
    }
    this.updateEntryProperties();
  }

  protected hasGisPropertyName(propertyName: string): boolean {
    return Object.values(GisPropertyName).includes(
      propertyName as GisPropertyName
    );
  }

  protected async handleDeleteClick(): Promise<void> {
    const entry = this.entry;
    if (!entry) return;

    if (await this.entryDeletionService.deleteEntryWithDialog(entry)) {
      this.close();
    }
  }

  protected handleSketchPictureClick(): void {
    if (!this.entryPicture) return;
    this.pictureSketchingService.sketchPicture(this.entryPicture);
  }

  protected handleStartEditingClick(): void {
    this.isEditingPicture = true;
  }

  protected handlePictureEditorStoppedEditing(): void {
    this.isEditingPicture = false;
  }

  protected async handleMarkPictureClick(): Promise<void> {
    const thingId = this.thingId;
    if (!thingId) return;

    const entryPicture = this.entryPicture;
    if (!entryPicture) return;

    const thingPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(thingId);
    const thingPicture =
      thingPictures.length === 1
        ? thingPictures[0]
        : await new Promise<EntityPicture | null>((res) => {
            void SelectThingPictureDialog.open({
              thingId: thingId,
              onDialogClosed: (picture) => res(picture)
            });
          });

    if (!thingPicture) return;

    void SelectCoordinatesOnPositionedPictureOverlay.open({
      oldPosition: this.entryPicture ? this.entryPicture.coords : null,
      picture: thingPicture,
      pictureNotPositionedText: this.i18n.tr(
        'galleryThing.thingPictureIsNotPositioned'
      ),
      onCoordinateSelected: ({ coords, coordsFromPositionedPictureInfo }) => {
        if (entryPicture.coords) {
          entryPicture.coords.latitude = coords.latitude;
          entryPicture.coords.longitude = coords.longitude;
          entryPicture.coordsFromPositionedPictureInfo =
            coordsFromPositionedPictureInfo;
        } else {
          entryPicture.coords = coords;
        }

        this.handlePictureChanged();
      }
    });
  }

  protected onMapReady(map: BasemapMap): void {
    if (this.canUseViaWildbach && this.municipalityCode) {
      this.geoDataCacheService
        .requestGstData(this.municipalityCode)
        .then((data) => {
          if (data) map.addAdditionalVectorLayer(data);
        })
        .catch(() => {
          console.error(
            'Could not find map for municipality code',
            this.municipalityCode
          );
        });
    }
  }

  protected handleCoordinatesChanged(): void {
    if (this.canUseViaWildbach) {
      void this.syncEstateOwners();
    }
  }

  protected handlePictureChanged(): void {
    if (!this.entryPicture) return;
    this.entityManager.pictureRepository.update(this.entryPicture);
  }

  protected handlePictureClick(): void {
    if (!this.entryPicture) return;
    void PictureFullScreenOverlay.open({
      picture: this.entryPicture
    });
  }

  @computedFrom(
    'entryPicturePermissionsHandle.canEditField.coords',
    'entryPicturePermissionsHandle.canEditField.coords'
  )
  protected get canMarkPicture(): boolean {
    return (
      this.entryPicturePermissionsHandle.canEditField.coords &&
      this.entryPicturePermissionsHandle.canEditField.coords
    );
  }

  public static async open(
    options: TGalleryThingEditEntryDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

export type TGalleryThingEditEntryDialogOptions = {
  entry: Entry;
  thingId: string;
  userGroupId: string;
  enabled: boolean;
};
