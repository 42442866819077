import { autoinject, bindable } from 'aurelia-framework';
import { allWeekdays, Weekday } from 'common/Enums/Weekday';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * allows the user to choose one or multiple weekdays (Mo/Tu/We/Th/Fr/Sa/Su).
 *
 * @event weekdays-changed triggered when the user selects/deselects a weekday.
 */
@autoinject()
export class WeekdayInput {
  /**
   * selected weekdays.
   */
  @bindable public weekdays: Array<Weekday> = [];

  /**
   * the minimum amount of weekdays that can be selected.
   */
  @bindable public min: number = 1;

  /**
   * the maximum amount of weekdays that can be selected.
   */
  @bindable public max: number = allWeekdays().length;

  @bindable public enabled: boolean = false;

  protected allWeekdays = allWeekdays();

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected attached(): void {
    if (this.weekdays.length < this.min) {
      this.weekdays = allWeekdays().slice(0, this.min);
    }
  }

  private canDeselectWeekday(): boolean {
    return this.weekdays.length > this.min;
  }

  private shouldSwitchWeekday(): boolean {
    return this.min === this.max;
  }

  private canSelectWeekday(): boolean {
    return this.weekdays.length < this.max;
  }

  private sendWeekdaysChangedEvent(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<SelectedWeekdaysChangedEvent>(this.element, {
        name: 'weekdays-changed',
        detail: { weekdays: this.weekdays }
      });
    });
  }

  protected handleWeekdayClicked(weekday: Weekday): void {
    if (this.isWeekdaySelected(this.weekdays, weekday)) {
      if (this.canDeselectWeekday()) {
        this.weekdays = this.weekdays.filter((w) => w !== weekday);
        this.sendWeekdaysChangedEvent();
      }
    } else {
      if (this.shouldSwitchWeekday()) {
        this.weekdays = [...this.weekdays.slice(1), weekday];
        this.sendWeekdaysChangedEvent();
      } else if (this.canSelectWeekday()) {
        this.weekdays = [...this.weekdays, weekday];
        this.sendWeekdaysChangedEvent();
      }
    }
  }

  protected getTranslationKeyForWeekday(weekday: Weekday): string {
    return `modelsDetail.ProcessTaskRecurringAppointmentModel.recurrence.weekday.${weekday}`;
  }

  protected isWeekdaySelected(
    values: Array<Weekday>,
    weekday: Weekday
  ): boolean {
    return this.weekdays.includes(weekday);
  }
}

export type SelectedWeekdaysChangedEvent = NamedCustomEvent<
  'weekdays-changed',
  { weekdays: Array<Weekday> }
>;
