import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructureTemplateEntryPropertyEntityInfo } from 'common/Types/Entities/StructureTemplateEntryProperty/createStructureTemplateEntryPropertyEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntryProperty } from './types';

export const structureTemplateEntryPropertyEntityInfo =
  createStructureTemplateEntryPropertyEntityInfo<StructureTemplateEntryProperty>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
