import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CustomRadioButtonCheckedChanged } from '../../aureliaComponents/custom-radiobutton/custom-radiobutton';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyRadiobuttonWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyRadiobuttonWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyRadiobuttonWidget doesn't support focus");
  }

  protected handleCheckedChanged(event: CustomRadioButtonCheckedChanged): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyRadiobuttonWidget.handleCheckedChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: event.detail.checked,
      customChoice: null
    });
  }
}

export const basePropertyRadiobuttonWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
