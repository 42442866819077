import { bindable, autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { IconType } from '../../aureliaComponents/custom-icon/custom-icon';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class GalleryThingStatusIcons {
  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public label: string = '';

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected thingStatusConfiguration: ThingStatusConfiguration | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as GalleryThingStatusIcons,
        propertyName: 'thing'
      }
    );
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.thingStatusConfiguration',
        (thingStatusConfiguration) => {
          this.thingStatusConfiguration = thingStatusConfiguration;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleStatusItemClick(item: ThingStatusConfigurationItem): void {
    if (!this.permissionsHandle.canEditField.status) {
      return;
    }

    assertNotNullOrUndefined(
      this.thing,
      "can't GalleryThingStatusIcons.handleStatusItemClick without thing"
    );

    this.thing.status = item.value;
    this.entityManager.thingRepository.update(this.thing);
  }
}

export type ThingStatusConfiguration = {
  items: Array<ThingStatusConfigurationItem>;
};

export type ThingStatusConfigurationItem = {
  label: string;
  value: string;
  iconName: string;
  iconType: IconType;
  selectionColor: string;
  selectionIconColor: string;
};
