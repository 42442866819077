import { IdType } from '../Types/Entities/Base/types';

export class ThingPictureIdPatcher {
  private constructor() {}

  public static patch<TId extends IdType>(
    picture: ThingPictureIdPatcherPictureData<TId>
  ): { changed: boolean } {
    let changed = false;

    if (picture.thing) {
      if (picture.ownerThingId !== picture.thing) {
        picture.ownerThingId = picture.thing;
        changed = true;
      }

      if (!picture.galleryThingId) {
        picture.galleryThingId = picture.thing;
        changed = true;
      }
    } else if (picture.galleryThingId) {
      if (picture.ownerThingId !== picture.galleryThingId) {
        picture.ownerThingId = picture.galleryThingId;
        changed = true;
      }

      if (!picture.thing) {
        picture.thing = picture.galleryThingId;
        changed = true;
      }
    }

    return {
      changed
    };
  }
}

export type ThingPictureIdPatcherPictureData<TId extends IdType> = {
  thing?: TId | null;
  ownerThingId?: TId | null;
  galleryThingId?: TId | null;
};
