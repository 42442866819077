import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskInvoiceToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskPosition/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ProcessTaskInvoiceToProcessTaskPositionMapComputer extends ValueComputer<
  ProcessTaskInvoiceToProcessTaskPositionMapComputerComputeData,
  ProcessTaskInvoiceToProcessTaskPositionMapComputerComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTaskPosition,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskInvoiceToProcessTaskPositionMapComputerComputeResult {
    return {
      activeProcessTaskInvoiceToProcessTaskPositionByPositionId:
        this.getActiveInvoiceToPositionByPositionId()
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getActiveInvoiceToPositionByPositionId(): ProcessTaskInvoiceToProcessTaskPositionByPositionId {
    const invoiceIdToProcessTaskIds = this.getInvoiceIdToProcessTaskIds();
    const invoiceToPositions =
      this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getAll();
    const activeInvoiceToPositionByPositionId: ProcessTaskInvoiceToProcessTaskPositionByPositionId =
      new Map();

    for (const invoiceToPosition of invoiceToPositions) {
      if (
        !invoiceIdToProcessTaskIds
          .get(invoiceToPosition.processTaskInvoiceId)
          ?.has(invoiceToPosition.ownerProcessTaskId)
      ) {
        continue;
      }

      const relations = activeInvoiceToPositionByPositionId.get(
        invoiceToPosition.processTaskPositionId
      );
      if (relations) {
        relations.push(invoiceToPosition);
      } else {
        activeInvoiceToPositionByPositionId.set(
          invoiceToPosition.processTaskPositionId,
          [invoiceToPosition]
        );
      }
    }

    return activeInvoiceToPositionByPositionId;
  }

  private getInvoiceIdToProcessTaskIds(): Map<string, Set<string>> {
    const invoiceIdToProcessTaskIds = new Map<string, Set<string>>();

    const relations =
      this.entityManager.processTaskInvoiceToProcessTaskRepository.getAll();
    for (const relation of relations) {
      const processTaskIds = invoiceIdToProcessTaskIds.get(
        relation.processTaskInvoiceId
      );
      if (processTaskIds) {
        processTaskIds.add(relation.ownerProcessTaskId);
      } else {
        invoiceIdToProcessTaskIds.set(
          relation.processTaskInvoiceId,
          new Set([relation.ownerProcessTaskId])
        );
      }
    }

    return invoiceIdToProcessTaskIds;
  }
}

export type ProcessTaskInvoiceToProcessTaskPositionMapComputerComputeData = {};

export type ProcessTaskInvoiceToProcessTaskPositionMapComputerComputeResult = {
  activeProcessTaskInvoiceToProcessTaskPositionByPositionId: ProcessTaskInvoiceToProcessTaskPositionByPositionId;
};

export type ProcessTaskInvoiceToProcessTaskPositionByPositionId = Map<
  string,
  Array<ProcessTaskInvoiceToProcessTaskPosition>
>;
