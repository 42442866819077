import { EntityInfo } from '@record-it-npm/synchro-common';
import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskGroupAuthorizationEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskGroupAuthorization/processTaskGroupAuthorizationEntityInfo';
import { ProcessTaskGroupAuthorization } from '../../../../classes/EntityManager/entities/ProcessTaskGroupAuthorization/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { ProcessTaskGroupAuthorizationComputer } from '../../../../computedValues/computers/ProcessTaskGroupAuthorizationComputer/ProcessTaskGroupAuthorizationComputer';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapterUtils } from '../../utils/EntityAdapterUtils/EntityAdapterUtils';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';

export class ProcessTaskGroupAuthorizationAdapter
  implements EntityAdapter<ProcessTaskGroupAuthorization>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly currentUserService: CurrentUserService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private processTaskGroupIdsWhereUserIsAuthorized = new Set<string>();

  constructor(options: ProcessTaskGroupAuthorizationAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.currentUserService = options.currentUserService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskGroupAuthorizationComputer,
        computeData: {},
        callback: ({ processTaskGroupIdsWhereUserIsAuthorized }) => {
          this.processTaskGroupIdsWhereUserIsAuthorized =
            processTaskGroupIdsWhereUserIsAuthorized;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(
    processTaskGroupAuthorization: ProcessTaskGroupAuthorization
  ): boolean {
    return this.checkPermission({
      processTaskGroupAuthorization,
      checkRolePermission: (permissions) =>
        permissions.getCanUpdateProcessTaskGroupAuthorizations()
    });
  }

  public canDeleteEntity(
    processTaskGroupAuthorization: ProcessTaskGroupAuthorization
  ): boolean {
    return this.checkPermission({
      processTaskGroupAuthorization,
      checkRolePermission: (permissions) =>
        permissions.getCanDeleteProcessTaskGroupAuthorizations()
    });
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskGroupAuthorization,
    ProcessTaskGroupAuthorization
  > {
    return processTaskGroupAuthorizationEntityInfo;
  }

  private checkPermission({
    processTaskGroupAuthorization,
    checkRolePermission
  }: {
    processTaskGroupAuthorization: ProcessTaskGroupAuthorization;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    const currentUser = this.currentUserService.getCurrentUser();
    if (
      currentUser &&
      processTaskGroupAuthorization.createdByUserId !== currentUser.id
    ) {
      return false;
    }

    return EntityAdapterUtils.checkProcessTaskGroupSubEntityPermission({
      entity: processTaskGroupAuthorization,
      processTaskGroupIdsWhereUserIsAuthorized:
        this.processTaskGroupIdsWhereUserIsAuthorized,
      roleBasedPermissions: this.roleBasedPermissions,
      checkRolePermission
    });
  }
}

export type ProcessTaskGroupAuthorizationAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  currentUserService: CurrentUserService;
};
