import { autoinject } from 'aurelia-framework';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

@autoinject()
export class EditThingGroupDialog {
  private thingGroup: ThingGroup | null = null;
  private options: OpenOptions | null = null;

  private dialog: RecordItDialog | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  public open(options: OpenOptions): void {
    this.thingGroup = options.thingGroup;
    this.options = options;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const thingGroup = this.thingGroup;
    const onDialogClosed = this.options ? this.options.onDialogClosed : null;

    this.thingGroup = null;
    this.options = null;

    if (thingGroup) {
      onDialogClosed && onDialogClosed(thingGroup);
    }
  }

  protected handleThingGroupChanged(): void {
    if (this.thingGroup) {
      this.entityManager.thingGroupRepository.update(this.thingGroup);
    }
  }

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

type OpenOptions = {
  thingGroup: ThingGroup;

  /** gets called after the dialog has been completely closed */
  onDialogClosed?: ((thingGroup: ThingGroup) => void) | null;
};
