import { assertNotNullOrUndefined } from '../Asserts';
import { DateUtils } from '../DateUtils';
import { PaginationInfo } from '../EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';
import { DateType, IdType } from '../Types/Entities/Base/types';
import { ProjectDto } from '../Types/Entities/Project/ProjectDto';
import { CommonGalleryThingPictureOverviewEntry } from '../Types/GalleryThingPictureFilter/GalleryThingPictureOverviewEntry';
import { GalleryThingHelper } from './GalleryThingHelper';

export class GalleryThingPictureGroupHelper {
  public static async groupByProject<
    BaseMapMarkerType,
    ProjectType extends CommonProject
  >(
    availableProjects: Array<ProjectType>,
    availableOverviewEntries: Array<
      CommonGalleryThingPictureOverviewEntry<BaseMapMarkerType>
    >,
    getOrCreateEmptyPictureGroup: GetOrCreateEmptyGalleryThingPictureGroup<ProjectType>,
    groupOptions: GalleryThingPictureOverviewEntryGroupOptions
  ): Promise<
    Array<GalleryThingPictureOverviewEntryGroup<BaseMapMarkerType, ProjectType>>
  > {
    const pictureGroupMapping: Map<
      ProjectType,
      EmptyGalleryThingPictureOverviewEntryGroup<BaseMapMarkerType, ProjectType>
    > = new Map();

    for (const project of availableProjects) {
      if (project.name) {
        const pictureGroup = this.createEmptyPictureGroupMapValue<
          BaseMapMarkerType,
          ProjectType
        >(project);
        pictureGroupMapping.set(project, pictureGroup);
      }
    }

    for (const pictureEntry of availableOverviewEntries) {
      const project = await this.getProjectForPictureOverviewEntry<ProjectType>(
        getOrCreateEmptyPictureGroup,
        availableProjects,
        pictureEntry
      );

      if (!project || !project.name) continue;

      let pictureGroup = pictureGroupMapping.get(project);
      if (!pictureGroup) {
        pictureGroup = this.createEmptyPictureGroupMapValue(project);
      }

      // Project.pictureCount already keeps track of project pictures,
      // But when adding pictures of a defect the pictureCount has to be increased manually
      if (pictureEntry.ownerDefectId) {
        pictureGroup.pictureCount++;
      }

      pictureGroup.pictureOverviewEntries.push(pictureEntry);
      pictureGroupMapping.set(project, pictureGroup);
    }

    const pictureGroups: Array<
      GalleryThingPictureOverviewEntryGroup<BaseMapMarkerType, ProjectType>
    > = [];
    for (const [key, value] of pictureGroupMapping) {
      if (
        value.pictureOverviewEntries.length > 0 ||
        !groupOptions.filterIsActive
      ) {
        pictureGroups.push({ project: key, ...value });
      }
    }

    return pictureGroups.sort((a, b) => {
      const aDate = GalleryThingHelper.getDateFromProjectName(
        a.project.name ?? ''
      );
      const bDate = GalleryThingHelper.getDateFromProjectName(
        b.project.name ?? ''
      );
      return bDate.getTime() - aDate.getTime();
    });
  }

  public static getSliceForPagination<ProjectType extends CommonProject>(
    groups: Array<GalleryThingPictureOverviewEntryGroup<any, ProjectType>>,
    paginationInfo: PaginationInfo
  ): Array<GalleryThingPictureOverviewEntryGroup<any, ProjectType>> {
    const sliceBegin =
      (paginationInfo.currentIndex - 1) * paginationInfo.currentPageSize;
    const paginatedEntryGroups = groups.slice(
      sliceBegin,
      sliceBegin + paginationInfo.currentPageSize
    );
    return paginatedEntryGroups;
  }

  private static createEmptyPictureGroupMapValue<
    BaseMapMarkerType,
    ProjectType extends CommonProject
  >(
    project: ProjectType
  ): EmptyGalleryThingPictureOverviewEntryGroup<
    BaseMapMarkerType,
    ProjectType
  > {
    assertNotNullOrUndefined(
      project.name,
      'cannot createEmptyPictureGroupMapValue without a project name'
    );

    const date = GalleryThingHelper.getDateFromProjectName(project.name);
    return {
      label: DateUtils.formatToDateString(date),
      date: date,
      pictureCount: project.pictureCount ?? 0,
      pictureOverviewEntries: []
    };
  }

  /**
   * Retrieve the project associated with a given picture.
   *
   * Retrieves from availableProjects if the projectId is set,
   * otherwise it will get/create a project for the date the
   * picture was created at.
   */
  private static async getProjectForPictureOverviewEntry<
    ProjectType extends CommonProject
  >(
    getOrCreateEmptyPictureGroup: GetOrCreateEmptyGalleryThingPictureGroup<ProjectType>,
    availableProjects: Array<ProjectType>,
    entry: CommonGalleryThingPictureOverviewEntry<unknown>
  ): Promise<ProjectType | null> {
    if (entry.projectId) {
      return availableProjects.find((p) => p.id === entry.projectId) ?? null;
    }

    if (entry.ownerDefectId) {
      if (entry.createdAt) {
        const createdAt = DateUtils.parseDateFromIsoString(entry.createdAt);
        if (createdAt) {
          return getOrCreateEmptyPictureGroup(createdAt);
        }
      }
    }
    return null;
  }
}

export type GalleryThingPictureOverviewEntryGroup<
  BaseMapMarkerType,
  ProjectType extends CommonProject
> = {
  project: ProjectType;
  date: Date;
  label: string;
  pictureCount: number;
  pictureOverviewEntries: Array<
    CommonGalleryThingPictureOverviewEntry<BaseMapMarkerType>
  >;
};

export type EmptyGalleryThingPictureOverviewEntryGroup<
  BaseMapMarkerType,
  ProjectType extends CommonProject
> = Omit<
  GalleryThingPictureOverviewEntryGroup<BaseMapMarkerType, ProjectType>,
  'project'
>;

export type GetOrCreateEmptyGalleryThingPictureGroup<
  ProjectType extends CommonProject
> = (date: Date) => Promise<ProjectType>;

export type CommonProject = ProjectDto<IdType, DateType>;

export type GalleryThingPictureOverviewEntryGroupOptions = {
  filterIsActive: boolean;
};
