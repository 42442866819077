import { inject, observable } from 'aurelia-framework';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { FilterHelper } from '../../classes/FilterHelper';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { EditUserDialog } from '../../dialogs/edit-user-dialog/edit-user-dialog';
import { CreateUserDialog } from '../../dialogs/create-user-dialog/create-user-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(AppEntityManager, SubscriptionManagerService, CurrentUserService)
export class edit_users {
  /** @type {string} */
  @observable _userFilterString;

  _userSortOptions = {
    name: {
      name: 'Name',
      sortFunction: function (a, b) {
        const aUsername = a.username ? a.username : '';
        const bUsername = b.username ? b.username : '';
        return bUsername.localeCompare(aUsername);
      }
    },
    status: {
      name: 'Status',
      sortFunction: function (a, b) {
        const aIsAdmin = a.admin ? 1 : 0;
        const bIsAdmin = b.admin ? 1 : 0;
        return aIsAdmin - bIsAdmin;
      }
    },
    subscription: {
      name: 'Abonnement',
      sortFunction: (a, b) => {
        const aSubscription =
          a.permissions && a.permissions.canUseBasic
            ? new Date(a.permissions.canUseBasic).getTime()
            : Number.MAX_SAFE_INTEGER;
        const bSubscription =
          b.permissions && b.permissions.canUseBasic
            ? new Date(b.permissions.canUseBasic).getTime()
            : Number.MAX_SAFE_INTEGER;
        return aSubscription - bSubscription;
      }
    }
  };
  _currentSortOption;

  _availableUsers = [];
  _filteredUsers = [];
  _sortedUsers = [];
  _currentPageUsers = [];

  /**@type {Pagination} */
  _pagination;

  _currentUserEditing;
  _attached = false;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {CurrentUserService} currentUserService
   */
  constructor(entityManager, subscriptionManagerService, currentUserService) {
    this._entityManager = entityManager;

    this._subscriptionManager = subscriptionManagerService.create();
    this._currentUserService = currentUserService;

    this._currentSortOption = this._userSortOptions.name;

    this._userFilterString = '';
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this._updateAvailableUsers.bind(this)
    );
    this._updateAvailableUsers();

    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this._isMobile = isMobile;
      })
    );

    this._subscriptionManager.addDisposable(
      this._currentUserService.subscribeToCurrentUserChanged(
        this._updateCurrentUser.bind(this)
      )
    );
    this._updateCurrentUser();
  }

  detached() {
    this._detached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  _updateCurrentUser() {
    this._currentUser = this._currentUserService.getCurrentUser();
  }

  _updateAvailableUsers() {
    this._availableUsers = this._entityManager.userRepository.getAll();
    this._handleFilterUsers();
  }

  _handleCreateUserClick() {
    CreateUserDialog.open({
      onDialogClosed: (user) => {
        if (user) this._goToUser(user);
      }
    });
  }

  _handleEditUserClick(user) {
    this._currentUserEditing = user;
    EditUserDialog.open({
      user: this._currentUserEditing,
      currentUser: this._currentUser,
      onDialogClosed: () => {
        this._goToUser(this._currentUserEditing);
      }
    });
  }

  /////////// View Helper

  _userFilterStringChanged() {
    this._handleFilterUsers();
  }

  _handleFilterUsers() {
    this._filteredUsers = FilterHelper.filterItems(
      this._availableUsers,
      (item) => item.username + ' ' + item.email,
      this._userFilterString
    );
  }

  /**
   * @param {import('../../classes/EntityManager/entities/User/types').User} user
   */
  _goToUser(user) {
    if (this._filteredUsers.indexOf(user) === -1) {
      this._userFilterString = '';
    }

    ScrollHelper.autoScrollToListItem(
      `#edit-users--user-${user.id}`,
      this._pagination,
      user,
      () => this._attached
    );
  }
}
