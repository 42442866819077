import { inject } from 'aurelia-framework';

import '../../map/basemap-map/LeafletSquareSelector';

import { MapAreaDrawer } from './MapAreaDrawer';
import { PictureCreatorService } from '../../classes/Picture/PictureCreatorService';
import { SocketService } from '../../services/SocketService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

/**
 * a dialog to upload a cutout of a map as a normal picture
 */
@inject(AppEntityManager, SocketService, PictureCreatorService)
export class CreateMapPictureDialog {
  /** @type {import('../record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;
  /** @type {import('../../map/basemap-map/basemap-map').BasemapMap|null} */
  _basemapMap = null;
  /** @type {HTMLCanvasElement|null} */
  _previewCanvas = null;

  /** @type {boolean} */
  _isDrawingPreview = false;
  _hasPreview = false;
  /** @type {L.Control.SquareSelector} */
  _squareSelector;

  /** @type {TCreateMapPictureDialogOpenOptions|null} */
  _options = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SocketService} socketService
   * @param {PictureCreatorService} pictureCreatorService
   */
  constructor(entityManager, socketService, pictureCreatorService) {
    this._entityManager = entityManager;
    this._socketService = socketService;
    this._pictureCreatorService = pictureCreatorService;
  }

  /**
   *
   * @param {TCreateMapPictureDialogOpenOptions} options
   */
  open(options) {
    this._hasPreview = false;

    if (this._dialog) {
      this._dialog.setTabDisabled('preview', true);
      this._dialog.selectTab('map');
      this._dialog.open();
    }
    this._options = options;
  }

  _handleMapInitialized() {
    if (!this._basemapMap) {
      return;
    }

    const map = this._basemapMap.getMapInstance();
    this._squareSelector = L.control
      .squareSelector({
        position: 'topleft',
        onAreaSelected: this._handleAreaSelected.bind(this),
        defaultActive: true
      })
      .addTo(map);
  }

  _handleMapRemove() {
    if (this._squareSelector) {
      this._squareSelector.remove();
    }
  }

  /**
   *
   * @param {import('../../map/basemap-map/LeafletSquareSelector').TLeafletSquareSelectorSelectedAreaInfo} areaInfo
   * @private
   */
  async _handleAreaSelected(areaInfo) {
    if (!this._dialog || !this._basemapMap || !this._previewCanvas) {
      return;
    }

    this._dialog.setTabDisabled('preview', false);
    this._dialog.selectTab('preview');

    this._isDrawingPreview = true;
    const drawer = new MapAreaDrawer(
      areaInfo.topLeftCorner,
      areaInfo.bottomRightCorner,
      this._previewCanvas
    );
    await drawer.draw(
      this._basemapMap.getActiveTileLayerInfos(),
      this._basemapMap.getActiveVectorTileLayerInfos()
    );
    this._isDrawingPreview = false;
    this._hasPreview = true;
  }

  _handleCancelClick() {
    if (this._dialog) {
      this._dialog.close();
    }
  }

  _handleAcceptClick() {
    if (!this._options || !this._previewCanvas) {
      return;
    }

    if (/** @type {TDataUrlOpenOptions} */ (this._options).onImageCreated) {
      /** @type {TDataUrlOpenOptions} */ (this._options).onImageCreated(
        this._previewCanvas.toDataURL('image/jpeg')
      );
    } else if (
      /** @type {TCreationOpenOptions} */ (this._options).getEntityInfos
    ) {
      this._automaticallyCreateImage(
        /** @type {TCreationOpenOptions} */ (this._options)
      );
      /** @type {TCreationOpenOptions} */ (this._options).onCapture?.();
    }

    if (this._dialog) {
      this._dialog.close();
    }
  }

  /**
   * @param {TCreationOpenOptions} options
   */
  _automaticallyCreateImage(options) {
    if (!this._previewCanvas) {
      return;
    }

    this._pictureCreatorService
      .withEntityInfos(options.getEntityInfos)
      .createPictureFromDataUrl(this._previewCanvas.toDataURL('image/jpeg'));
  }

  _handleDialogOpened() {
    this._isOpen = true;
  }

  _handleDialogClosed() {
    this._isOpen = false;
  }

  /**
   *
   * @param {TCreateMapPictureDialogOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * pass these options if you want the dialog handle the creation of the picture
 *
 * @typedef {Object} TCreationOpenOptions
 *
 * @property {import('../../classes/Picture/PictureCreatorService').GetEntityInfos} getEntityInfos
 * @property {(() => void)} [onCapture]
 */

/**
 * pass these options if you want to manually create the picture from the data url
 *
 * @typedef {Object} TDataUrlOpenOptions
 * @property {(dataUrl: string) => void} onImageCreated - param is the dataUrl of the picture
 */

/**
 * @typedef {TCreationOpenOptions | TDataUrlOpenOptions} TCreateMapPictureDialogOpenOptions
 */
