import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@inject(SubscriptionManagerService)
export class ProcessConfigurationDeviceFilter {
  /**
   * @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationDevice/types').ProcessConfigurationDevice>}
   */
  @bindable devices = [];

  /**
   * read-only!
   *
   * @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationDevice/types').ProcessConfigurationDevice>}
   */
  @bindable filteredDevices = [];

  /**
   * @type {string|null}
   */
  @bindable userGroupId = null;

  /**
   * @type {string|null}
   */
  @bindable selectedPersonId = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {import('../../personComponents/filter-person-select/filter-person-select').TPersonOption|null} */
  _selectedPersonOption = null;
  /** @type {Array<string>} */
  _selectablePersonIds = [];

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(subscriptionManagerService) {
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'devices',
      () => {
        this._updateSelectablePersonIds();
        this._updateFilteredDevices();
      }
    );

    this._updateSelectablePersonIds();
    this._updateFilteredDevices();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  /**
   * modify the filter by the least amount so the configurationDevice will pass the filtering
   *
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationDevice/types').ProcessConfigurationDevice} configurationDevice
   */
  setFilterToIncludeConfigurationDevice(configurationDevice) {
    if (!this._filterDeviceByPerson(configurationDevice)) {
      this._selectedPersonOption = null;
      this._handleSelectedPersonOptionChanged();
    }
  }

  _updateSelectablePersonIds() {
    /** @type {Set<string>} */
    const personIds = new Set();

    this.devices.forEach((d) => {
      if (d.personId) {
        personIds.add(d.personId);
      }
    });

    this._selectablePersonIds = Array.from(personIds);
  }

  _updateFilteredDevices() {
    this.filteredDevices = this.devices.filter((device) => {
      return this._filterDeviceByPerson(device);
    });
  }

  _handleSelectedPersonOptionChanged() {
    this._updateFilteredDevices();

    this.selectedPersonId = this._selectedPersonOption
      ? this._selectedPersonOption.personId
      : null;
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationDevice/types').ProcessConfigurationDevice} device
   * @returns {boolean}
   */
  _filterDeviceByPerson(device) {
    if (!this._selectedPersonOption) {
      return true;
    }

    return (
      this._selectedPersonOption.personId === device.personId ||
      (this._selectedPersonOption.personId == null && device.personId == null)
    );
  }
}
