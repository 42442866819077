import { bindable, autoinject } from 'aurelia-framework';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { configureHooks } from '../../hooks/configureHooks';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { assertNotNullOrUndefined } from 'common/Asserts';

/**
 * @event edit-user-group-dialog-closed
 * @performancereview make this an global instance which only initializes itself when needed (wait for the REC-109 merge/copy the class from there)
 */
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class EditUserGroupDialog {
  @bindable()
  public userGroup: UserGroup | null = null;

  @bindable()
  public currentUser: User | null = null;

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  private dialog: RecordItDialog | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.UserGroup,
        context: this as EditUserGroupDialog,
        propertyName: 'userGroup'
      });
  }

  public open(): void {
    this.dialog?.open();
  }

  public close(): void {
    this.dialog?.close();
  }

  protected handleUserGroupChanged(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't EditUserGroupDialog.handleUserGroupChanged without userGroup"
    );

    this.entityManager.userGroupRepository.update(this.userGroup);
  }

  @computed(
    expression('currentUser.admin'),
    expression('currentUser.permissions.canAdministerUsers'),
    expression('userGroup'),
    model(EntityName.User)
  )
  protected get permissionsDescriptionTk(): string {
    if (!this.currentUser || !this.userGroup) {
      return 'dialogs.editUserGroupDialog.permissionDescriptions.noPermission';
    }

    if (this.currentUser?.admin) {
      return 'dialogs.editUserGroupDialog.permissionDescriptions.superAdmin';
    }

    if (this.currentUser?.permissions.canAdministerUsers) {
      return 'dialogs.editUserGroupDialog.permissionDescriptions.userAdmin';
    }

    if (
      PermissionHelper.userCanWriteInUserGroup(this.currentUser, this.userGroup)
    ) {
      return 'dialogs.editUserGroupDialog.permissionDescriptions.readWrite';
    }

    if (
      PermissionHelper.userCanReadInUserGroup(this.currentUser, this.userGroup)
    ) {
      return 'dialogs.editUserGroupDialog.permissionDescriptions.readOnly';
    }

    return 'dialogs.editUserGroupDialog.permissionDescriptions.noPermission';
  }

  protected userCanAdministerUserGroup(
    user: User,
    userGroup: UserGroup
  ): boolean {
    return user && userGroup
      ? PermissionHelper.userCanAdministerUserGroup(user, userGroup)
      : false;
  }

  protected handleDialogClosed(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-user-group-dialog-closed',
      detail: null
    });
  }
}
