import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MomentInput } from 'moment/moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { QuestionCategory } from '../../classes/EntityManager/entities/QuestionCategory/types';
import { EditQuestionCategoryDialog } from '../../dialogs/edit-question-category-dialog/edit-question-category-dialog';

@autoinject()
export class ChecklistQuestionCategoryListItem {
  @bindable public questionCategory: QuestionCategory | null = null;

  protected panelOpen = false;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionCategory,
      'cannot handleEditButtonClick without questionCategory'
    );

    void EditQuestionCategoryDialog.open({
      questionCategory: this.questionCategory
    });
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionCategory,
      'cannot handleDeleteButtonClick without questionCategory'
    );

    const questionCategory = this.questionCategory;
    void Dialogs.deleteEntityDialog(questionCategory).then(() => {
      this.entityManager.questionCategoryRepository.delete(questionCategory);
    });
  }
}
