import { bindable, inject } from 'aurelia-framework';
import { ProcessTaskPositionCalculator } from 'common/Operations/ProcessTaskPositionCalculator';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskPositionDialog } from '../edit-process-task-position-dialog/edit-process-task-position-dialog';
import { ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer } from '../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionUtils } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskPositionDetailEntryUtils } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryUtils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { Dialogs } from '../../classes/Dialogs';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * this is just a sub-component of the ProcessTaskOfferTasksAndPositionsWidget and also uses its styling
 */
@inject(
  AppEntityManager,
  SubscriptionManagerService,
  ComputedValueService,
  ProcessTaskLoggingService,
  PermissionsService
)
export class ProcessTaskOfferRelationsWidgetPositions {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskOffer/types').ProcessTaskOffer|null} */
  @bindable processTaskOffer = null;

  /** @type {boolean} */
  @bindable allPositionsSelected = false;

  /**
   * is null when positions are not loaded
   * read only
   *
   * @type {number|null}
   */
  @bindable processTaskPositionCount = null;

  /** @type {import('../../services/PermissionsService/entityNameToPermissionsConfig').EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer]} */
  @subscribableLifecycle()
  _permissionHandle;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition>} */
  _availablePositions = [];
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types').ProcessTaskOfferToProcessTaskPosition>} */
  _availableProcessTaskOfferToProcessTaskPositions = [];

  /** @type {ProcessTaskPositionCalculator|null} */
  _calculator = null;
  /** @type {import('../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer').ProcessTaskPositionDetailEntriesByProcessTaskPositionId} */
  _detailEntriesByPositionId = new Map();
  /** @type {Array<import('../../../../common/src/Operations/ProcessTaskPositionCalculator').PositionPrices<import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition, import('../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types').ProcessTaskPositionDetailEntry>>} */
  _positionPrices = [];
  /**
   * all prices which are included in the offer
   * @type {Array<import('../../../../common/src/Operations/ProcessTaskPositionCalculator').PositionPrices<import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition, import('../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types').ProcessTaskPositionDetailEntry>>}
   */
  _includedPositionPrices = [];

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {ComputedValueService} computedValueService
   * @param {ProcessTaskLoggingService} processTaskLoggingService
   * @param {PermissionsService} permissionsService
   */
  constructor(
    entityManager,
    subscriptionManagerService,
    computedValueService,
    processTaskLoggingService,
    permissionsService
  ) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._computedValueService = computedValueService;
    this._processTaskLoggingService = processTaskLoggingService;

    this._permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
  }

  selectAll() {
    this._availablePositions.forEach((position) => {
      this._ensureRelationStatusToPosition(position, true);
    });

    this._updateAvailableProcessTaskOfferToProcessTaskPositions();
  }

  deselectAll() {
    this._availablePositions.forEach((position) => {
      this._ensureRelationStatusToPosition(position, false);
    });

    this._updateAvailableProcessTaskOfferToProcessTaskPositions();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this._updateAvailablePositions.bind(this)
    );
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this._updateAvailablePositions.bind(this)
    );
    this._updateAvailablePositions();

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      this._updateAvailableProcessTaskOfferToProcessTaskPositions.bind(this)
    );
    this._updateAvailableProcessTaskOfferToProcessTaskPositions();

    this._subscriptionManager.addDisposable(
      this._computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskGroup
            ? { processTaskGroupId: this.processTaskGroup.id }
            : null,
        createUpdaters: (updateSubscription) => {
          this._subscriptionManager.subscribeToExpression(
            this,
            'processTaskGroup.id',
            updateSubscription
          );
        },
        callback: (config) => {
          this._calculator = new ProcessTaskPositionCalculator(config);
          this._updatePositionPrices();
        },
        onNoComputeData: () => {
          this._calculator = null;
        }
      }),
      this._computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTask ? { ownerProcessTaskId: this.processTask.id } : null,
        createUpdaters: (updateSubscription) => {
          this._subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        },
        callback: (result) => {
          this._detailEntriesByPositionId = result;
          this._updatePositionPrices();
        }
      })
    );
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskChanged() {
    if (this._attached) {
      this._updateAvailablePositions();
      this._updateAvailableProcessTaskOfferToProcessTaskPositions();
    }
  }

  processTaskOfferChanged() {
    if (this._attached) {
      this._updateAvailablePositions();
      this._updateAvailableProcessTaskOfferToProcessTaskPositions();
    }
  }

  _updateAvailablePositions() {
    const processTask = this.processTask;
    const processTaskOffer = this.processTaskOffer;

    if (processTask && processTaskOffer) {
      const category = processTaskOffer.processConfigurationCategoryId
        ? this._entityManager.processConfigurationCategoryRepository.getById(
            processTaskOffer.processConfigurationCategoryId
          )
        : null;
      const offerIsGeneral = category ? category.general : false;
      const positions =
        this._entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          processTask.id
        );
      const filteredPositions = positions.filter((p) => {
        return (
          offerIsGeneral ||
          processTaskOffer.processConfigurationCategoryId == null ||
          p.processConfigurationCategoryId == null ||
          processTaskOffer.processConfigurationCategoryId ===
            p.processConfigurationCategoryId
        );
      });

      this._availablePositions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(filteredPositions);
      this.processTaskPositionCount = this._availablePositions.length;
    } else {
      this._availablePositions = [];
      this.processTaskPositionCount = null;
    }

    this._updatePositionPrices();
    this._updateAllPositionsSelected();
  }

  _updateAvailableProcessTaskOfferToProcessTaskPositions() {
    if (this.processTaskOffer) {
      this._availableProcessTaskOfferToProcessTaskPositions =
        this._entityManager.processTaskOfferToProcessTaskPositionRepository.getByProcessTaskOfferId(
          this.processTaskOffer.id
        );
    } else {
      this._availableProcessTaskOfferToProcessTaskPositions = [];
    }

    this._updateIncludedPositionPrices();
    this._updateAllPositionsSelected();
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition} position
   */
  _handleEditButtonClicked(position) {
    EditProcessTaskPositionDialog.open({
      position
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition} position
   */
  _handleDeleteButtonClicked(position) {
    void Dialogs.deleteEntityDialog(position).then(() => {
      this._entityManager.processTaskPositionRepository.delete(position);
      void this._processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: position,
        displayNameAtLogTime: position.name
      });
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition} position
   * @param {import('../../inputComponents/custom-checkbox/custom-checkbox').CustomCheckboxCheckedChangedEvent} event
   */
  _handlePositionCheckedChanged(position, event) {
    this._ensureRelationStatusToPosition(position, event.detail.checked);
    this._updateAvailableProcessTaskOfferToProcessTaskPositions();
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition} position
   * @param {boolean} available - true if a relation should exist, false to to ensure no relation exists
   */
  _ensureRelationStatusToPosition(position, available) {
    const relation = this._availableProcessTaskOfferToProcessTaskPositions.find(
      (r) => r.processTaskPositionId === position.id
    );

    if (available) {
      if (!relation) {
        this._createProcessTaskOfferToProcessTaskPosition(position);
      }
    } else {
      if (relation) {
        this._entityManager.processTaskOfferToProcessTaskPositionRepository.delete(
          relation
        );
      }
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition} position
   */
  _createProcessTaskOfferToProcessTaskPosition(position) {
    if (!this.processTaskOffer) {
      return;
    }

    this._entityManager.processTaskOfferToProcessTaskPositionRepository.create({
      ownerProcessTaskGroupId: position.ownerProcessTaskGroupId,
      ownerUserGroupId: position.ownerUserGroupId,
      ownerProcessTaskId: position.ownerProcessTaskId,
      processTaskOfferId: this.processTaskOffer.id,
      processTaskPositionId: position.id,
      temporaryGroupName: this.processTaskOffer.temporaryGroupName
    });
  }

  _updatePositionPrices() {
    if (this._calculator) {
      this._positionPrices = this._calculator.calculatePricesOfPositions(
        this._createPositionInfos()
      );
    } else {
      this._positionPrices = [];
    }
    this._updateIncludedPositionPrices();
  }

  _updateIncludedPositionPrices() {
    this._includedPositionPrices = this._positionPrices.filter((prices) => {
      return this._positionIdIsIncluded(
        prices.originalPosition.id,
        this._availableProcessTaskOfferToProcessTaskPositions
      );
    });
  }

  _updateAllPositionsSelected() {
    this.allPositionsSelected = this._availablePositions.every((position) => {
      return this._availableProcessTaskOfferToProcessTaskPositions.find(
        (otp) => otp.processTaskPositionId === position.id
      );
    });
  }

  /**
   * @returns {Array<import('../../../../common/src/Operations/ProcessTaskPositionCalculator').PositionInfo<import('../../classes/EntityManager/entities/ProcessTaskPosition/types').ProcessTaskPosition, import('../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types').ProcessTaskPositionDetailEntry>>}
   */
  _createPositionInfos() {
    return this._availablePositions.map((p) => {
      return {
        ownerProcessTaskId: p.ownerProcessTaskId,
        type: p.type,
        customType: p.customType,
        amount: p.amount,
        price: p.price,
        flatRate: p.flatRate,
        excludeFromMarkup: p.excludeFromMarkup,
        ignoreExcludeFromMarkup: p.ignoreExcludeFromMarkup,
        discount: p.discount,
        detailEnabled: p.detailEnabled,
        detailEntryInfos:
          ProcessTaskPositionDetailEntryUtils.getDetailEntryInfos(
            this._detailEntriesByPositionId.get(p.id) ?? []
          ),
        originalPosition: p
      };
    });
  }

  /**
   * @param {string} positionId
   * @param {Array<import('../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types').ProcessTaskOfferToProcessTaskPosition>} availableProcessTaskOfferToProcessTaskPositions
   * @returns {boolean}
   */
  _positionIdIsIncluded(
    positionId,
    availableProcessTaskOfferToProcessTaskPositions
  ) {
    return !!availableProcessTaskOfferToProcessTaskPositions.find(
      (r) => r.processTaskPositionId === positionId
    );
  }
}
