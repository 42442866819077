import { autoinject } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { DateUtils } from 'common/DateUtils';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@autoinject()
export class EditStructureTemplateEntryDialog {
  private dialog: RecordItDialog | null = null;

  private onDialogClosed: OnDialogClosedCallback | null = null;

  private structureTemplateEntry: StructureTemplateEntry | null = null;

  private permissionBindingHandle: PermissionBindingHandle;

  private userCanEditStructureTemplateEntry = false;
  private notEditable: boolean = false;

  private DateUtils: DateUtils = DateUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionBindingService: PermissionBindingService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        editableProperty: 'userCanEditStructureTemplateEntry',
        property: 'structureTemplateEntry',
        userGroupPropertyOfEntity: 'ownerUserGroupId'
      }
    });
  }

  public static async open(
    options: IEditStructureTemplateEntryDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IEditStructureTemplateEntryDialogOptions): void {
    this.structureTemplateEntry = options.structureTemplateEntry;
    this.notEditable = options.notEditable || false;
    this.onDialogClosed = options.onDialogClosed || null;

    this.permissionBindingHandle.subscribe();

    this.dialog?.open();
  }

  private get structureTemplateEntryIsEditable(): boolean {
    return this.userCanEditStructureTemplateEntry && !this.notEditable;
  }

  private handleDialogClosed(): void {
    const structureTemplateEntry = this.structureTemplateEntry;
    const onDialogClosed = this.onDialogClosed;

    this.structureTemplateEntry = null;
    this.onDialogClosed = null;

    this.permissionBindingHandle.unsubscribe();

    structureTemplateEntry &&
      onDialogClosed &&
      onDialogClosed(structureTemplateEntry);
  }

  private handleStructureTemplateEntryChanged(): void {
    if (this.structureTemplateEntry)
      this.entityManager.structureTemplateEntryRepository.update(
        this.structureTemplateEntry
      );
  }
}

interface IEditStructureTemplateEntryDialogOptions {
  structureTemplateEntry: StructureTemplateEntry;
  notEditable?: boolean;
  onDialogClosed?: OnDialogClosedCallback | null;
}

type OnDialogClosedCallback = (
  structureTEmplateEntry: StructureTemplateEntry
) => void;
