import 'indexeddb-getall-shim';

import { AbstractStorageStrategy, KeyValue } from './AbstractStorageStrategy';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { TestingHelper } from '../TestingHelper';
import { storeInfo } from './storeInfo';

export class DataStorageHelper {
  public static AFTER_LOGOUT_ERROR_MSG =
    'Tried to access local storage after logout.';

  private static strategy: AbstractStorageStrategy | null = null;

  private static accessLockedAfterLogout = false;

  public static async init(strategy: AbstractStorageStrategy): Promise<void> {
    this.strategy = strategy;

    await this.strategy.init();

    this.strategy = strategy;

    TestingHelper.DataStorageHelper = this;
  }

  public static async migrate(): Promise<void> {
    await this.getRequiredStrategy().migrate();
  }

  public static async getItem(
    key: string,
    tableName: string = storeInfo.defaultTableName
  ): Promise<any> {
    return this.getRequiredStrategy().getItem(key, tableName);
  }

  public static async getItems(
    tableName: string = storeInfo.defaultTableName
  ): Promise<Array<any>> {
    const strategy = this.getRequiredStrategy();

    const items = await strategy.getItems(tableName);
    for (let i = 0; i < items.length; i++) {
      items[i] = items[i];
    }
    return items;
  }

  public static async getItemsWithKeys(
    tableName: string = storeInfo.defaultTableName
  ): Promise<Array<KeyValue>> {
    return this.getRequiredStrategy().getItemsWithKeys(tableName);
  }

  public static async setItem(
    key: string,
    value: any,
    tableName: string = storeInfo.defaultTableName
  ): Promise<void> {
    await this.getRequiredStrategy().setItem(key, value, tableName);
  }

  public static async setItems(
    items: Array<KeyValue>,
    tableName: string = storeInfo.defaultTableName
  ): Promise<void> {
    await this.getRequiredStrategy().setItems(items, tableName);
  }

  public static async removeItem(
    key: string,
    tableName: string = storeInfo.defaultTableName
  ): Promise<void> {
    await this.getRequiredStrategy().removeItem(key, tableName);
  }

  public static async removeItems(
    keys: Array<string>,
    tableName: string = storeInfo.defaultTableName
  ): Promise<void> {
    await this.getRequiredStrategy().removeItems(keys, tableName);
  }

  /**
   * returns all stored keys inside the store
   */
  public static async getKeys(
    tableName: string = storeInfo.defaultTableName
  ): Promise<Array<string>> {
    return this.getRequiredStrategy().getKeys(tableName);
  }

  public static async clear(
    tableName: string = storeInfo.defaultTableName
  ): Promise<void> {
    await this.getRequiredStrategy().clear(tableName);
  }

  public static async lockDatabaseAndClearAll(): Promise<void> {
    await this.clearAll(false, () => {
      this.accessLockedAfterLogout = true;
    });
  }

  public static resetDatabaseLock(): void {
    this.accessLockedAfterLogout = false;
  }

  public static async clearAll(
    ignoreKeepOnClearAll = false,
    beforeClearAllCallback?: () => void
  ): Promise<void> {
    await this.clear(storeInfo.defaultTableName);

    const tablesToClear = ignoreKeepOnClearAll
      ? storeInfo.applicationTables
      : storeInfo.applicationTables.filter((t) => !('keepOnClearAll' in t));

    beforeClearAllCallback && beforeClearAllCallback();

    await this.getRequiredStrategy({ ignoreLock: true }).clearMany(
      tablesToClear,
      ignoreKeepOnClearAll
    );
  }

  private static getRequiredStrategy(
    { ignoreLock }: { ignoreLock: boolean } = { ignoreLock: false }
  ): AbstractStorageStrategy {
    if (!ignoreLock && this.accessLockedAfterLogout)
      throw new Error(DataStorageHelper.AFTER_LOGOUT_ERROR_MSG);

    assertNotNullOrUndefined(
      this.strategy,
      'No strategy found. Is the DataStorageHelper initialized?'
    );

    return this.strategy;
  }
}
