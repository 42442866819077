import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export abstract class SelectPictureDialogAdapter {
  public abstract subscribe(options: SubscribeOptions): Disposable;

  /**
   * Special behavior, like setting the lastUsedPicture etc should be implemented here
   */
  public abstract afterPictureSelected(
    options: AfterPictureSelectedOptions
  ): Promise<void>;
}

export type SubscribeOptions = {
  setPictureGroups: (pictureGroups: Array<PictureGroup>) => void;
};

export type AfterPictureSelectedOptions = {
  picture: Picture;
};

export type PictureGroup = {
  titleTk: string;
  pictureDisplayInfos: Array<PictureDisplayInfo>;
};

export type PictureDisplayInfo = {
  picture: Picture;
  subText: string | null;

  /**
   * If this is false, the picture will be displayed in the selection, but it will be grayed out and not be selectable.
   * This is so users can see their pictures are already there, but they are just not available for selections
   */
  enabled: boolean;

  relatedPictures: Array<Picture>;
};
