import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createDefectChangeLogEntryEntityInfo } from 'common/Types/Entities/DefectChangeLogEntry/createDefectChangeLogEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { DefectChangeLogEntry } from './types';

export const defectChangeLogEntryEntityInfo =
  createDefectChangeLogEntryEntityInfo<DefectChangeLogEntry>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
