/* global Msvg */

import { Tool } from './Tool';

export class FreehandTool extends Tool {
  _name = 'Freihand';
  _icons = ['far fa-paint-brush'];
  _machineName = 'freehand';
  _instantDragging = true;
  _gridIsEnabled = false;

  /**
   * @type {Array<Msvg.Path>}
   * @private
   */
  _paths = [];

  _cancel() {
    this._paths.forEach((path) => {
      this._msvg.remove(path);
    });
    super._cancel();
  }

  _reset() {
    super._reset();
    this._paths = [];
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (this._lastDraggingPosition) {
      let path = this._paths[this._paths.length - 1];
      if (!path) {
        path = this._startNewPath(this._lastDraggingPosition);
      }

      path.addLineToAbsolute(position);
    }
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._paths.forEach((path) => {
      this._msvg.append(path);
    });
    this._modified();
    this._reset();
  }

  // ********** we want to pause drawing the line when we are leaving the drawing area and continue again when we enter

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseLeftHandler(event, position) {
    if (this._isDragging) {
      this._draggingHandler(event, position);
    }
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseEnteredHandler(event, position) {
    if (this._isDragging) {
      this._startNewPath(position);
    }
  }

  /**
   * @param {Msvg.Point} startPosition
   * @returns {Msvg.Path}
   * @private
   */
  _startNewPath(startPosition) {
    const path = new Msvg.Path();
    path.addMoveToAbsolute(startPosition);
    path
      .setFill('none')
      .setStroke(this._color)
      .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
      .setStrokeWidth(this._size)
      .setStrokeLinejoin('round');

    this._msvg.append(path);
    this._paths.push(path);
    return path;
  }
}
