import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { PropertyToProcessTaskPosition } from './types';

export class PropertyToProcessTaskPositionRepository extends AppEntityRepository<EntityName.PropertyToProcessTaskPosition> {
  public getByPropertyId(
    propertyId: string
  ): Array<PropertyToProcessTaskPosition> {
    return this.getAll().filter((p) => p.propertyId === propertyId);
  }
}
