import { computedFrom } from 'aurelia-framework';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { PropertyAdapter } from '../EntityAdapter/PropertyAdapter/PropertyAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class PropertyPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<PropertyAdapter>,
  Property
> {
  @computedFrom('combinedRevision')
  public get canEditPropertyToPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditPropertyToPositions'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditPropertyToPersons(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditPropertyToPersons'
    );
  }

  @computedFrom('combinedRevision')
  public get canEditGeneralFiles(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditGeneralFiles');
  }

  @computedFrom('combinedRevision')
  public get canEditPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPictures');
  }
}
