import { assertNotNullOrUndefined } from '../../Asserts';
import { BaseAppointmentCalendarEntryDayWidgetTexts } from '../../EndpointTypes/OperationsEndpointsTypes';
import { ExprEvalParser } from '../../ExprEvalParser/ExprEvalParser';
import { ExpressionEditorScopeEvaluationUtils } from '../../ExpressionEditorScope/ExpressionEditorScopeEvaluationUtils';
import { OperationsExpressionEditorScope } from '../../ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsExpressionEditorScope';
import { ProcessConfigurationDto } from '../../Types/Entities/ProcessConfiguration/ProcessConfigurationDto';

export class CalendarEntryHelper {
  public static async getDayWidgetTexts({
    processTaskId,
    processTaskGroupId,
    processTaskAppointmentId,
    processTaskRecurringAppointmentId,
    thingGroupId,
    processConfigurationConfigurableDisplayText,
    operationsExpressionEditor,
    exprEvalParser
  }: CustomTextParams): Promise<BaseAppointmentCalendarEntryDayWidgetTexts> {
    const customTextConfig =
      processConfigurationConfigurableDisplayText?.processTaskAppointmentsInCalendarView;

    let appointmentConfig;
    if (processTaskAppointmentId) {
      appointmentConfig = {
        currentProcessTaskAppointment: { id: processTaskAppointmentId }
      };
    } else if (processTaskRecurringAppointmentId) {
      appointmentConfig = {
        currentProcessTaskRecurringAppointment: {
          id: processTaskRecurringAppointmentId
        }
      };
    }
    assertNotNullOrUndefined(
      appointmentConfig,
      'Invalid appointment config given, either processTaskAppointmentId or processTaskRecurringAppointmentId must be set.'
    );

    const fieldConfigs =
      await operationsExpressionEditor.createFieldConfigsForAppointmentScope({
        currentProcessTaskGroup: { id: processTaskGroupId },
        currentThingGroup: { id: thingGroupId },
        currentProcessTask: { id: processTaskId },
        ...appointmentConfig
      });
    const dataToSet =
      await ExpressionEditorScopeEvaluationUtils.convertToPlainObject(
        fieldConfigs
      );

    return {
      line1: exprEvalParser.evaluateExpression(
        customTextConfig?.firstLine || 'thingGroup.fullAddress',
        dataToSet
      ),
      line2: exprEvalParser.evaluateExpression(
        customTextConfig?.secondLine || 'processTask.thingMainContactPerson',
        dataToSet
      ),
      line3: exprEvalParser.evaluateExpression(
        customTextConfig?.thirdLine || 'appointment.name',
        dataToSet
      )
    };
  }
}

type CustomTextCommonParams = {
  processTaskId: string;
  processTaskGroupId: string;
  thingGroupId: string;
  processConfigurationConfigurableDisplayText:
    | ProcessConfigurationDto<string, string>['configurableDisplayText']
    | undefined;
  operationsExpressionEditor: OperationsExpressionEditorScope<string, string>;
  exprEvalParser: ExprEvalParser;
};
type CustomTextParams = (
  | {
      processTaskAppointmentId?: never;
      processTaskRecurringAppointmentId: string;
    }
  | {
      processTaskAppointmentId: string;
      processTaskRecurringAppointmentId?: never;
    }
) &
  CustomTextCommonParams;
