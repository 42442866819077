import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskPositionDetailEntryEntityInfo } from 'common/Types/Entities/ProcessTaskPositionDetailEntry/createProcessTaskPositionDetailEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskPositionDetailEntry } from './types';

export const processTaskPositionDetailEntryEntityInfo =
  createProcessTaskPositionDetailEntryEntityInfo<ProcessTaskPositionDetailEntry>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
