import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { ProcessTaskRecurringAppointment } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CalendarEntryType } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntry';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ComputedValueService } from '../ComputedValueService';
import { ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer } from './ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class UserProcessTaskAppointmentInfosComputer extends ValueComputer<
  UserProcessTaskAppointmentInfoMapComputerComputeData,
  UserAppointmentInfos
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: invokeCompute,
        skipInitialCall: true
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): UserAppointmentInfos {
    return [
      ...this.computeNormalProcessTaskAppointmentInfos(),
      ...this.computeRecurringProcessTaskAppointmentInfos()
    ];
  }

  private computeNormalProcessTaskAppointmentInfos(): UserAppointmentInfos {
    const relationsByAppointmentId = this.computedValueService.getCurrentValue(
      ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
      {}
    );
    if (!relationsByAppointmentId) {
      return [];
    }

    const appointments =
      this.entityManager.processTaskAppointmentRepository.getAll();
    const infos: UserAppointmentInfos = [];

    for (const appointment of appointments) {
      const relations = relationsByAppointmentId.get(appointment.id) ?? [];
      for (const relation of relations) {
        infos.push({
          type: CalendarEntryType.NORMAL,
          processTaskAppointment: appointment,
          processTaskAppointmentToUser: relation
        });
      }
    }

    return infos;
  }

  private computeRecurringProcessTaskAppointmentInfos(): UserAppointmentInfos {
    const processTaskRecurringAppointments =
      this.entityManager.processTaskRecurringAppointmentRepository.getAll();

    return processTaskRecurringAppointments.map((recurringAppointment) => ({
      type: CalendarEntryType.RECURRING,
      recurringAppointment
    }));
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type UserProcessTaskAppointmentInfoMapComputerComputeData = {};

export type UserAppointmentInfos = Array<
  UserProcessTaskAppointmentInfo | UserProcessTaskRecurringAppointmentInfo
>;

export type UserProcessTaskAppointmentInfo = {
  type: CalendarEntryType.NORMAL;
  processTaskAppointment: ProcessTaskAppointment;
  processTaskAppointmentToUser: ProcessTaskAppointmentToUser;
};

export type UserProcessTaskRecurringAppointmentInfo = {
  type: CalendarEntryType.RECURRING;
  recurringAppointment: ProcessTaskRecurringAppointment;
};
