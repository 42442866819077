import { bindable, autoinject } from 'aurelia-framework';
import { TProcessConfigurationPositionGroupingConfiguration } from 'common/Types/ProcessConfigurationPositionGroupingConfiguration';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  EntityGrouper,
  EntityGroup,
  GroupConfiguration,
  GroupConfigurationType
} from '../../classes/EntityGrouper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

/**
 * @replaceable item-template
 * available variables in item-template
 * | type | name | description |
 * |------|------|-------------|
 * | TPosition | position | the position to display |
 */
@autoinject()
export class ProcessTaskPositionList<TDragAndDropViewModel> {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public positions: Array<ProcessTaskPosition> = [];

  @bindable()
  public dragAndDropViewModelInfo: DragAndDropViewModelInfo<TDragAndDropViewModel> | null =
    null;

  private subscriptionManager: SubscriptionManager;
  private groupedPositions: Array<EntityGroup<ProcessTaskPosition>> = [];
  private isAttached: boolean = false;
  private entityGrouper: EntityGrouper<ProcessTaskPosition>;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.entityGrouper = new EntityGrouper({
      groupConfigurations: [],
      properties: [],
      propertyEntityIdField: 'processTaskPositionId'
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      () => {
        this.updateProperties();
        this.updateGroupedPositions();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      () => {
        this.updateGroupingConfiguration();
      }
    );

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'positions',
      () => {
        this.updateGroupedPositions();
      }
    );

    this.updateProperties();
    this.updateGroupingConfiguration();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateGroupingConfiguration();
    }
  }

  private updateGroupingConfiguration(): void {
    const processConfiguration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;
    const groupingConfigurationJson = processConfiguration
      ? processConfiguration.positionGroupingConfigurationJson
      : null;
    const groupingConfiguration: TProcessConfigurationPositionGroupingConfiguration | null =
      groupingConfigurationJson ? JSON.parse(groupingConfigurationJson) : null;
    const groupConfigurations = groupingConfiguration
      ? groupingConfiguration.groupConfigurations
      : [];

    const configs: Array<GroupConfiguration> = [];
    for (const groupConfig of groupConfigurations) {
      if (groupConfig.type === 'property') {
        configs.push({
          type: GroupConfigurationType.Property,
          value: groupConfig.value
        });
      } else {
        console.error(
          `unsupported groupConfig type "${groupConfig.type}"`,
          groupConfig
        );
      }
    }

    this.entityGrouper.setGroupConfigurations(configs);
    this.updateGroupedPositions();
  }

  private updateProperties(): void {
    if (this.processTask) {
      this.entityGrouper.setProperties(
        this.entityManager.propertyRepository.getByOwnerProcessTaskId(
          this.processTask.id
        )
      );
    } else {
      this.entityGrouper.setProperties([]);
    }
  }

  private updateGroupedPositions(): void {
    this.groupedPositions = InstancePreserver.createNewArray({
      originalArray: this.groupedPositions,
      newArray: this.entityGrouper.groupEntities(this.positions),
      getTrackingValue: (item) => item.name
    });
  }

  private handleDropTargetActivate(
    group: EntityGroup<ProcessTaskPosition>,
    viewModel: TDragAndDropViewModel
  ): boolean {
    assertNotNullOrUndefined(
      this.dragAndDropViewModelInfo,
      "can't handleDropTargetActivate without dragAndDropViewModelInfo"
    );

    const position =
      this.dragAndDropViewModelInfo.getPositionFromViewModel(viewModel);
    return group.entities.indexOf(position) >= 0;
  }

  private handleDrop(
    group: EntityGroup<ProcessTaskPosition>,
    viewModel: TDragAndDropViewModel,
    indexInGroup: number
  ): void {
    assertNotNullOrUndefined(
      this.dragAndDropViewModelInfo,
      "can't handleDrop without dragAndDropViewModelInfo"
    );

    const positions = this.groupedPositions.reduce<Array<ProcessTaskPosition>>(
      (flattened, g) => {
        flattened.push(...g.entities);
        return flattened;
      },
      []
    );

    const position =
      this.dragAndDropViewModelInfo.getPositionFromViewModel(viewModel);
    const oldIndexInGroup = group.entities.indexOf(position);
    if (oldIndexInGroup === -1) {
      throw new Error("can't drop positions in other groups");
    }

    const oldIndex = positions.indexOf(position);
    if (oldIndex === -1) {
      throw new Error(
        "can't move the position to the target index because it's not part of the positions"
      );
    }

    const groupIndex = positions.indexOf(group.entities[0]);
    const index = groupIndex + indexInGroup;
    const targetIndex = oldIndex < index ? index - 1 : index;

    if (targetIndex === oldIndex) {
      return;
    }

    positions.splice(oldIndex, 1);
    positions.splice(targetIndex, 0, position);
    this.entityManager.processTaskPositionRepository.updateOrders(positions);
    this.positions = positions;
  }
}

export type DragAndDropViewModelInfo<T> = {
  viewModelConstructor: new (...args: Array<any>) => T;
  getPositionFromViewModel: (viewModel: T) => ProcessTaskPosition;
};
