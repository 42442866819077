import { autoinject, bindable } from 'aurelia-framework';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';

@autoinject()
export class ProcessTaskAppointmentCommentsWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public expanded: boolean = false;

  private processTaskCommentCount: number | null = null;

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }
}
