import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';

@autoinject()
export class EditThingSectionConfigPropertiesDialog {
  public static async open(
    options: EditThingSectionConfigPropertiesDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected thing: Thing | null = null;

  protected dialog: RecordItDialog | null = null;

  protected readonly ThingSectionConfigPropertyLocation =
    ThingSectionConfigPropertyLocation;

  public open(
    options: EditThingSectionConfigPropertiesDialogOpenOptions
  ): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditThingSectionConfigPropertiesDialog.open without dialog"
    );
    this.thing = options.thing;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.thing = null;
  }
}

export type EditThingSectionConfigPropertiesDialogOpenOptions = {
  thing: Thing;
};
