export type AddUserToUserGroupRequest = {
  targetUserGroupId: string;
  userEmailToAdd: string;
};

export type AddUserToUserGroupSuccessResponse = {
  success: true;
  detail: AddUserToUserGroupSuccessResponseDetail;
};

export type AddUserToUserGroupErrorResponse = {
  success: false;
  detail: AddUserToUserGroupErrorResponseDetail;
};

export type AddUserToUserGroupResponse =
  | AddUserToUserGroupSuccessResponse
  | AddUserToUserGroupErrorResponse;
export type AddUserToUserGroupResponseDetail =
  | AddUserToUserGroupSuccessResponseDetail
  | AddUserToUserGroupErrorResponseDetail;

export enum AddUserToUserGroupSuccessResponseDetail {
  USER_HAS_BEEN_ADDED = 'userHasBeenAdded',
  REQUEST_PROCESSED = 'requestProcessed'
}

export enum AddUserToUserGroupErrorResponseDetail {
  USER_EMAIL_NOT_FOUND = 'userEmailNotFound',
  EMAIL_SENDING_ERROR = 'emailSendingError',
  USERGROUP_NOT_FOUND = 'userGroupNotFound',
  USER_ALREADY_IN_USERGROUP = 'userAlreadyInUserGroup',
  USER_NOT_ACTIVE = 'userNotActive'
}
