import { bindable, observable, autoinject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { GlobalFilter } from '../global-filter/global-filter';
import { Key } from '../../classes/Key';
import { FloatingLabelInput } from '../../inputComponents/floating-label-input/floating-label-input';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

/**
 * @event searched-triggered - will be fired when the user pressed enter on the keyboard or the user clicked on the search icon
 */
@autoinject()
export class ExpandableSearchInput {
  @bindable public searchString: string | null = null;

  @bindable public useGlobalFilter = true;

  @bindable public alwaysExpanded = false;

  @bindable public isLoading = false;

  @bindable public refreshSearchDelay = -1;

  @observable protected expanded: boolean;

  protected floatingLabelViewModel: FloatingLabelInput | null = null;

  protected floatingLabelInputIsFocused = false;

  @observable private globalFilterValue: string;

  private domElement: HTMLElement;
  private readonly subscriptionManager: SubscriptionManager;

  private refreshSearchDelayTimeout: number | null = null;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.globalFilterValue = '';
    this.expanded = false;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    if (this.useGlobalFilter) {
      GlobalFilter.enable();

      this.subscriptionManager.addDisposable(
        GlobalFilter.registerBinding('value', (value) => {
          this.globalFilterValue = value;
        })
      );
    }
    if (this.alwaysExpanded) {
      this.expanded = true;
    }
  }

  protected detached(): void {
    if (this.useGlobalFilter) {
      GlobalFilter.disable();
    }

    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private fireSearchEvent(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'search-triggered',
      detail: null
    });
  }

  // /////////// OBSERVABLES /////////////

  protected expandedChanged(newValue: boolean): void {
    if (!newValue) {
      this.searchString = '';
    } else {
      this.floatingLabelViewModel?.focus();
    }
  }

  protected globalFilterValueChanged(): void {
    this.searchString = this.globalFilterValue;
  }

  protected searchStringChanged(): void {
    if (this.useGlobalFilter) GlobalFilter.setValue(this.searchString || '');

    if (this.searchString) {
      this.expanded = true;
    } else if (!this.floatingLabelInputIsFocused && !this.alwaysExpanded) {
      this.expanded = false;
    }

    this.refreshSearchDelayTimeout &&
      clearTimeout(this.refreshSearchDelayTimeout);
    if (this.refreshSearchDelay >= 0) {
      this.refreshSearchDelayTimeout = window.setTimeout(
        this.fireSearchEvent.bind(this),
        this.refreshSearchDelay
      );
    }
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleInputKeydown(event: KeyboardEvent): boolean {
    if (event.key === Key.ENTER) {
      this.fireSearchEvent();
    }
    return true; // do not cancel the event
  }

  protected handleInputBlur(): void {
    if (!this.searchString && !this.alwaysExpanded) {
      this.expanded = false;
    } else {
      this.fireSearchEvent();
    }
  }

  protected handleCloseIconClicked(): void {
    this.searchString = '';
  }
}
