import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { BaseEntityDto } from './BaseEntityUtils';
import { EntityName } from './EntityName';
import {
  UserGroupSubEntityDto,
  UserGroupSubEntityFieldInfos,
  UserGroupSubEntityUtils
} from './UserGroupSubEntityUtils';

export class ProcessTaskGroupSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): ProcessTaskGroupSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerProcessTaskGroupId: {
        name: 'ownerProcessTaskGroupId',
        relativeSynchronization: true,
        ref: EntityName.ProcessTaskGroup,
        cascadeDeleteLocally: true,
        additionalData: {
          ownerId: true
        }
      }
    };
  }

  public static createOptionalBaseFieldInfos(): OptionalProcessTaskGroupSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerProcessTaskGroupId: {
        name: 'ownerProcessTaskGroupId',
        relativeSynchronization: true,
        ref: EntityName.ProcessTaskGroup,
        cascadeDeleteLocally: true,
        defaultValue: null,
        additionalData: {
          ownerId: true
        }
      }
    };
  }
}

export type ProcessTaskGroupSubEntityFieldInfos =
  UserGroupSubEntityFieldInfos & {
    ownerProcessTaskGroupId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskGroupSubEntityDto,
      'ownerProcessTaskGroupId'
    >;
  };

export type ProcessTaskGroupSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerProcessTaskGroupId: string;
  };

export type OptionalProcessTaskGroupSubEntityFieldInfos =
  UserGroupSubEntityFieldInfos & {
    ownerProcessTaskGroupId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      OptionalProcessTaskGroupSubEntityDto,
      'ownerProcessTaskGroupId'
    >;
  };

export type OptionalProcessTaskGroupSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerProcessTaskGroupId: string | null;
  };
