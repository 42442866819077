import { inject, bindable } from 'aurelia-framework';
import { FormController } from '../aureliaComponents/form-controller/form-controller';

/**
 * this attribute should only be applied to an input element
 *
 * it will block the user from inputting non numeric inputs
 *
 * this will also check the userinput based on the min/max attribute/property value
 */
@inject(Element)
export class NumberValueCustomAttribute {
  /** @type {number|null} */
  @bindable() value = null;
  /**
   * function gets called when the validity has changed
   * @type {(function(boolean): void)|null}
   */
  @bindable() validityChanged = null;

  /** @type {boolean} */
  _ignoreNextValueChange = false;
  /** @type {boolean} */
  _oldValidity = true;
  /** @type {number|null} */
  _initialValue = null;
  /** @type {import('../aureliaComponents/form-controller/form-controller').FormControllable} */
  _controllable;

  /** @type {HTMLInputElement} */
  _domElement;

  /** @type {function(): void} */
  _boundHandleFocus;
  /** @type {function(): void} */
  _boundHandleInput;
  /** @type {function(): void} */
  _boundHandleBlur;

  /**
   * @param {HTMLInputElement} element
   */
  constructor(element) {
    this._domElement = element;

    if (this._domElement.tagName !== 'INPUT') {
      console.error(
        'NumberValueCustomAttribute: this is only meant to be placed on a <input>!!',
        element
      );
    } else if (
      this._domElement.type !== 'number' &&
      this._domElement.type !== 'text'
    ) {
      console.error(
        'NumberValueCustomAttribute: input is not of type number or text and this will not work correctly',
        element
      );
    }

    this._boundHandleFocus = this._handleFocus.bind(this);
    this._boundHandleInput = this._handleInput.bind(this);
    this._boundHandleBlur = this._handleBlur.bind(this);

    this._controllable = {
      reset: () => {
        this.reset();
      }
    };
  }

  reset() {
    this._assignValueToInputElement(this.value);
    this._handleValidity(this._domElement.validity.valid);
  }

  attached() {
    this._domElement.addEventListener('focus', this._boundHandleFocus);
    this._domElement.addEventListener('input', this._boundHandleInput);
    this._domElement.addEventListener('blur', this._boundHandleBlur);
    this.valueChanged(); // force an update

    FormController.registerFormControllable(
      this._domElement,
      this._controllable
    );
  }

  detached() {
    this._domElement.removeEventListener('focus', this._boundHandleFocus);
    this._domElement.removeEventListener('input', this._boundHandleInput);
    this._domElement.removeEventListener('blur', this._boundHandleBlur);

    FormController.unregisterFormControllable(this._controllable);
  }

  _handleFocus() {
    this._initialValue = this.value;
  }

  _handleInput() {
    const valid = this._domElement.validity.valid;

    const value = valid
      ? this._parseNumberString(this._getCurrentDomElementValue())
      : this._initialValue;
    if (value !== this.value) {
      this._ignoreNextValueChange = true;
      this.value = value;
    }

    // mark input as valid as soon as it is valid
    if (valid) {
      this._handleValidity(valid);
    }
  }

  /**
   * rewrite the value on blur
   * this is needed because we don't 100% support the 5e10 notation
   * with this we convert the 5e10 notation back into the "normal" decimal notation 50000000000
   */
  _handleBlur() {
    const valid = this._domElement.validity.valid;

    // do not do this for null values, we get a null value when the inputted text is invalid
    // and we do not want to delete it
    if (this.value != null && valid) {
      this._assignValueToInputElement(this.value);
    }

    // only mark the input as invalid on blur to not annoy the user while typing
    this._handleValidity(valid);
  }

  valueChanged() {
    if (this._ignoreNextValueChange) {
      this._ignoreNextValueChange = false;
      return;
    }

    this._assignValueToInputElement(this.value);
    this._handleValidity(this._domElement.validity.valid);
  }

  /**
   * @param {string} string
   * @returns {number|null} - returns the parsed number, or null if it couldn't get parsed
   * @private
   */
  _parseNumberString(string) {
    if (string.length) {
      // we want to treat numbers which just are '-' as a 0
      // parsing this would result in a NaN
      return string !== '-' ? parseFloat(string) : 0;
    }

    return null;
  }

  _getCurrentDomElementValue() {
    let value = this._domElement.value;

    // some browsers still output the value as a number instead of a string
    if (typeof value === 'number') {
      // @ts-ignore - based on the typings this SHOULD never be executed, ignore the error
      value = value.toString();
    }

    return value.replace(',', '.');
  }

  /**
   * @param {number|null} value
   */
  _assignValueToInputElement(value) {
    this._domElement.value =
      value != null
        ? value.toLocaleString(undefined, { useGrouping: false })
        : '';
  }

  /**
   * @param {boolean} valid
   */
  _handleValidity(valid) {
    if (this.validityChanged && valid !== this._oldValidity) {
      this.validityChanged(valid);
      this._oldValidity = valid;
    }
  }
}
