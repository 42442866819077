import { PropertyBinder } from '../../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';

export class ProcessConfigurationStepBarDimensionCalculator {
  public static getFallbackDimension(): ProcessConfigurationStepBarDimension {
    return {
      groupDimensions: [],
      contentWidth: 0
    };
  }

  private readonly getContentScrollContainerContentElement: () => HTMLElement;
  private readonly propertyBinder: PropertyBinder<{
    dimension: ProcessConfigurationStepBarDimension;
  }>;
  private readonly updateDimensionRateLimited: IUtilsRateLimitedFunction;

  constructor({
    getContentScrollContainerContentElement
  }: {
    getContentScrollContainerContentElement: () => HTMLElement;
  }) {
    this.getContentScrollContainerContentElement =
      getContentScrollContainerContentElement;

    this.propertyBinder = new PropertyBinder<{
      dimension: ProcessConfigurationStepBarDimension;
    }>({
      defaultValuesByName: {
        dimension:
          ProcessConfigurationStepBarDimensionCalculator.getFallbackDimension()
      }
    });

    this.updateDimensionRateLimited = Utils.rateLimitFunction(
      this.updateDimension.bind(this),
      10
    );
  }

  public bindDimension(
    callback: (dimensions: ProcessConfigurationStepBarDimension) => void
  ): Disposable {
    return this.propertyBinder.registerBinding('dimension', callback);
  }

  public attached(): void {
    this.updateDimensionRateLimited();
  }

  public detached(): void {
    this.updateDimensionRateLimited.cancel();
  }

  public contentScrollContainerContentElementResized(): void {
    this.updateDimensionRateLimited();
  }

  public stepAttached(): void {
    this.updateDimensionRateLimited();
  }

  public stepDetached(): void {
    this.updateDimensionRateLimited();
  }

  private updateDimension(): void {
    const computed = window.getComputedStyle(
      this.getContentScrollContainerContentElement()
    );

    this.propertyBinder.setValue('dimension', {
      groupDimensions: this.calculateGroupDimensions(),
      contentWidth: computed.width ? parseFloat(computed.width) : 0
    });
  }

  private calculateGroupDimensions(): Array<ProcessConfigurationStepBarGroupDimension> {
    const dimensions: Array<ProcessConfigurationStepBarGroupDimension> = [];
    const elements =
      this.getContentScrollContainerContentElement().querySelectorAll(
        '.process-configuration-step-bar--Group'
      ) as NodeListOf<HTMLElement>;

    for (const element of elements) {
      dimensions.push(this.calculateGroupDimension(element));
    }

    return dimensions;
  }

  private calculateGroupDimension(
    groupElement: HTMLElement
  ): ProcessConfigurationStepBarGroupDimension {
    const computed = window.getComputedStyle(groupElement);
    const width = computed.width ? parseFloat(computed.width) : 0;
    const offset = Utils.getRelativeElementOffset(
      groupElement,
      this.getContentScrollContainerContentElement()
    );

    return {
      width: width,
      offsetLeft: offset.left,
      stepDimensions: this.calculateStepDimensions(groupElement, offset.left)
    };
  }

  private calculateStepDimensions(
    groupElement: HTMLElement,
    groupElementOffsetLeft: number
  ): Array<ProcessConfigurationStepBarStepDimension> {
    const dimensions: Array<ProcessConfigurationStepBarStepDimension> = [];
    const elements = groupElement.querySelectorAll(
      '.process-configuration-step-bar--Step'
    ) as NodeListOf<HTMLElement>;

    for (const element of elements) {
      dimensions.push(
        this.calculateStepDimension(
          element,
          groupElement,
          groupElementOffsetLeft
        )
      );
    }

    return dimensions;
  }

  private calculateStepDimension(
    stepElement: HTMLElement,
    groupElement: HTMLElement,
    groupElementOffsetLeft: number
  ): ProcessConfigurationStepBarStepDimension {
    const computed = window.getComputedStyle(stepElement);
    const width = computed.width ? parseFloat(computed.width) : 0;
    const offset = Utils.getRelativeElementOffset(stepElement, groupElement);

    return {
      centerOffsetLeft: offset.left + groupElementOffsetLeft + width / 2,
      stepId: stepElement.dataset.stepId as string
    };
  }
}

export type ProcessConfigurationStepBarDimension = {
  groupDimensions: Array<ProcessConfigurationStepBarGroupDimension>;
  contentWidth: number;
};

export type ProcessConfigurationStepBarGroupDimension = {
  width: number;
  offsetLeft: number;
  stepDimensions: Array<ProcessConfigurationStepBarStepDimension>;
};

export type ProcessConfigurationStepBarStepDimension = {
  /**
   * left offset of the center point of the step
   */
  centerOffsetLeft: number;
  stepId: string;
};
