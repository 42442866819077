import { CustomFileEntry } from './CustomFileEntry';
import { EntryNotOfExpectedTypeError } from './EntryNotOfExpectedTypeError';

/**
 * Custom prefix because the cordova-plugin-file is poluting the global namespace and we can't name this DirectoryEntry...
 */
export abstract class CustomDirectoryEntry {
  /**
   * the absolute path to the directory
   */
  public abstract get path(): string;

  public abstract get name(): string;

  public abstract get nativeUrl(): string;

  public getFilePath(fileName: string): string {
    return this.nativeUrl + fileName;
  }

  public abstract getFile(fileNameOrPath: string): Promise<CustomFileEntry>;

  /**
   * returns all file entries which are direct descendants of the directory
   */
  public abstract getFilesShallow(): Promise<Array<CustomFileEntry>>;

  /**
   * returns all directory entries which are direct descendants of the directory
   */
  public abstract getDirectoriesShallow(): Promise<Array<CustomDirectoryEntry>>;

  /**
   * Returns a file for the given name/path.
   * If the file or directories leading to it don't exist, they will be created
   */
  public abstract ensureFile(fileNameOrPath: string): Promise<CustomFileEntry>;

  /**
   * Returns the diretory for the given name/path
   * If the directory or directories leading to it don't exist, they will be created
   */
  public abstract ensureDirectory(
    directoryNameOrPath: string
  ): Promise<CustomDirectoryEntry>;

  public abstract delete(): Promise<void>;

  public asFile(): never {
    throw new EntryNotOfExpectedTypeError({
      type: 'CustomDirectoryEntry',
      expectedType: 'CustomFileEntry'
    });
  }

  public asDirectory(): this {
    return this;
  }
}
