export class KeyValueCache<TKey, TValue> {
  private readonly cache = new Map<TKey, TValue>();

  constructor(private readonly options: { getValue: (key: TKey) => TValue }) {}

  public get(key: TKey): TValue {
    if (this.cache.has(key)) {
      return this.cache.get(key) as TValue;
    }

    const value = this.options.getValue(key);

    this.cache.set(key, value);

    return value;
  }
}
