import { inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(I18N)
export class OperationsOfferPositionsViewDialog {
  /** @type {I18N} */
  _i18n;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} */
  _positions;
  /** @type {(function|null)} */
  _onDialogClosed;
  /** @type {boolean} */
  _viewRendered = false;

  /** @type {RecordItDialog} */
  _dialog;

  /**
   * @param {I18N} i18n
   */
  constructor(i18n) {
    this._i18n = i18n;
  }

  /**
   * @param {TOperationsOfferPositionsViewDialogOpenOptions} options
   */
  open(options) {
    this._positions = options.positions;
    this._onDialogClosed = options.onDialogClosed;
    this._viewRendered = true;

    this._dialog.open();
  }

  _handleDialogClosed() {
    const onClosed = this._onDialogClosed;

    this._positions = [];
    this._onDialogClosed = null;
    this._viewRendered = false;

    onClosed && onClosed();
  }

  /**
   * @param {string} key
   * @returns {string}
   * @private
   */
  _translate(key) {
    return this._i18n.tr(
      'operations.operationsOfferPositionsViewDialog.' + key
    );
  }

  /**
   * @param {TOperationsOfferPositionsViewDialogOpenOptions} options
   * @returns {Promise<void>}
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TOperationsOfferPositionsViewDialogOpenOptions
 * @property {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
 * @property {(function|null)} [onDialogClosed]
 */
