import { autoinject } from 'aurelia-framework';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { ProjectQuestionCategory } from '../../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Dialogs } from '../../classes/Dialogs';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { Router } from 'aurelia-router';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { ChecklistManageQuestionSetsDialog } from '../../dialogs/checklist-manage-question-sets-dialog/checklist-manage-question-sets-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class ChecklistInspection {
  private projectId: string | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly router: Router
  ) {}

  protected activate(params: { project_id: string }): void {
    this.projectId = params.project_id;
    this.activeEntitiesService.setActiveChecklistInspection(
      this.entityManager.projectRepository.getById(this.projectId)
    );

    void this.entityManager.joinedProjectsManager.joinProject(this.projectId);
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveChecklistInspection(null);
  }

  protected handleProjectQuestionCategoryClicked(
    projectQuestionCategory: ProjectQuestionCategory
  ): void {
    this.router.navigateToRoute('checklist_inspection_category', {
      project_id: this.projectId,
      project_category_id: projectQuestionCategory.id
    });
  }

  protected handleAddCategoryButtonClicked(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleAddCategoryButtonClicked without project'
    );

    void ChecklistManageQuestionSetsDialog.open({
      project: this.project
    });
  }

  protected handleEndInspectionButtonClicked(): void {
    // TODO: Implement this
    void Dialogs.todoDialog();
  }

  protected handleExportInspectionButtonClicked(): void {
    // TODO: Implement this
    void Dialogs.todoDialog();
  }

  @computed(expression('projectId'), model(EntityName.Project))
  protected get project(): Project | null {
    if (!this.projectId) return null;
    return this.entityManager.projectRepository.getById(this.projectId);
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.project) return null;
    return this.entityManager.thingRepository.getById(this.project.thing);
  }

  @computed(expression('project.name'))
  protected get projectDate(): string {
    if (!this.project || !this.project.name) return '...';
    return ChecklistProjectNameUtils.toDateString(this.project.name);
  }

  @computed(expression('project.createdByUserId'))
  protected get createdBy(): User | null {
    if (!this.project || !this.project.createdByUserId) return null;

    return this.entityManager.userRepository.getById(
      this.project.createdByUserId
    );
  }

  @computed(expression('projectId'), model(EntityName.ProjectQuestionCategory))
  protected get projectQuestionCategories(): Array<ProjectQuestionCategory> {
    if (!this.projectId) return [];
    return this.entityManager.projectQuestionCategoryRepository.getByProjectId(
      this.projectId
    );
  }

  @computed(
    expression('projectQuestionCategories'),
    model(EntityName.ProjectQuestion)
  )
  protected get projectQuestions(): Array<ProjectQuestion> {
    return this.entityManager.projectQuestionRepository.getByProjectQuestionCategoryIds(
      this.projectQuestionCategories.map((e) => e.id)
    );
  }

  @computed(expression('projectQuestions'))
  protected get amountOfAnsweredQuestions(): number {
    return this.projectQuestions.filter((q) => q.answer !== null).length;
  }

  @computed(expression('projectQuestions'))
  protected get amountOfQuestions(): number {
    return this.projectQuestions.length;
  }

  @computed(
    expression('amountOfAnsweredQuestions'),
    expression('amountOfQuestions')
  )
  protected get progressInPercent(): string {
    if (this.amountOfQuestions === 0)
      return Number(0).toLocaleString(undefined, { style: 'percent' });
    const percent = this.amountOfAnsweredQuestions / this.amountOfQuestions;
    return percent.toLocaleString(undefined, { style: 'percent' });
  }

  protected getQuestionAmountForCategory(
    projectQuestions: typeof this.projectQuestions,
    category: ProjectQuestionCategory
  ): number {
    return projectQuestions.filter(
      (q) => q.projectQuestionCategoryId === category.id
    ).length;
  }

  protected categoryIsFinished(
    projectQuestions: typeof this.projectQuestions,
    category: ProjectQuestionCategory
  ): boolean {
    return (
      projectQuestions.length > 0 &&
      projectQuestions
        .filter((q) => q.projectQuestionCategoryId === category.id)
        .filter((q) => q.answer === null).length === 0
    );
  }
}
