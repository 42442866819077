import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { StructureTemplateEntryGroupToStructureTemplateEntryDto } from './StructureTemplateEntryGroupToStructureTemplateEntryDto';

export function createStructureTemplateEntryGroupToStructureTemplateEntryEntityInfo<
  TEntity extends StructureTemplateEntryGroupToStructureTemplateEntryDto<
    string,
    string
  >
>(
  options: CreateEntityInfoOptions<
    StructureTemplateEntryGroupToStructureTemplateEntryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateEntryGroupToStructureTemplateEntryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        structureTemplateEntryGroupId: {
          name: 'structureTemplateEntryGroupId',
          ref: EntityName.StructureTemplateEntryGroup,
          cascadeDeleteLocally: true
        },
        structureTemplateEntryId: {
          name: 'structureTemplateEntryId',
          ref: EntityName.StructureTemplateEntry,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
