import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager)
export class UserIdToUserNameValueConverter {
  signals = ['userModelUpdated'];

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  /**
   * @param {string|null} userId
   * @param {TUserIdToUserNameValueConverterOptions} [options]
   * @returns {string}
   */
  toView(userId, options = {}) {
    const user = userId
      ? this._entityManager.userRepository.getById(userId)
      : null;

    if (!user) {
      return '';
    }
    if (options.abbreviation) {
      return this._getAbbreviation(user.username);
    } else {
      return user.username;
    }
  }

  /**
   * @param {string} username
   * @returns {string}
   */
  _getAbbreviation(username) {
    const parts = username.split(' ');
    /** @type {Array<string>} */
    const abbrvParts = [];
    let firstNonEmptyPartIndex = 0;

    //find the first non empty string part
    for (let key = 0; key < parts.length; key++) {
      if (parts[key].length > 0) {
        abbrvParts.push(parts[key]);
        firstNonEmptyPartIndex = key;
      }
    }

    //find the last non empty string part, and also prevent adding the first one again
    for (
      let key = parts.length - 1;
      key > firstNonEmptyPartIndex && key > 0;
      key--
    ) {
      if (parts[key].length > 0) {
        abbrvParts.push(parts[key]);
      }
    }

    //combine the first letters of all parts
    return abbrvParts.reduce((abbrvString, part) => {
      return abbrvString + part[0].toLocaleUpperCase();
    }, '');
  }
}

/**
 * @typedef {Object} TUserIdToUserNameValueConverterOptions
 * @property {boolean} [abbreviation] - only display the abbreviation instead of the full username
 */
