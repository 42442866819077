import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ValueChangedEvent } from '../../inputComponents/date-time-picker/date-time-picker';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyDateWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyDateWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyDateWidget doesn't support focus");
  }

  protected handleValueChanged(event: ValueChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyDateWidget.handleValueChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: event.detail.value,
      customChoice: null
    });
  }
}

export const basePropertyDateWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
