import _ from 'lodash';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../SubscriptionManager';
import { SocketService } from '../../services/SocketService';

import { parseDxfFileString } from '@dxfom/dxf';
import { createSvgString } from '@dxfom/svg';
import { DXF_COLOR_HEX } from '@dxfom/color/hex';
import { EventDispatcher } from '../EventDispatcher/EventDispatcher';
import { Disposable } from '../Utils/DisposableContainer';
import { FetchService } from '../../services/FetchService/FetchService';

export class ImageLoader {
  private sourcesToLoad: Array<string> = [];
  private loadedData: Array<LoadedDataInfo> = [];

  private sourcesAreLoading = false;

  private subscriptionManager: SubscriptionManager;
  private eventDispatcher: EventDispatcher<EventDispatcherConfig> =
    new EventDispatcher();

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService,
    private readonly fetchService: FetchService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public addSubscriptions(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected && !this.sourcesAreLoading) {
          this.loadNextImage();
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.resetLoadedData();
    this.sourcesToLoad = [];
  }

  public onLoadNextImage(onLoadNextImage: (index: number) => void): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'loadNextImage',
      onLoadNextImage
    );
  }

  public onImageLoaded(
    onImageLoaded: (data: LoadedDataInfo) => void
  ): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'imageLoaded',
      onImageLoaded
    );
  }

  public onLoadingError(onLoadingError: (error: Error) => void): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'loadingError',
      onLoadingError
    );
  }

  public onLoadingStopped(onLoadingStopped: () => void): Disposable {
    return this.eventDispatcher.addDisposableEventListener(
      'loadingStopped',
      onLoadingStopped
    );
  }

  public setSources(sources: Array<string>): boolean {
    if (_.isEqual(this.sourcesToLoad, sources)) {
      return false;
    }

    this.sourcesToLoad = sources.slice();

    this.resetLoadedData();

    this.loadNextImage();

    return true;
  }

  public abortRequests(): void {
    this.fetchService.abortRequests(this);
  }

  private resetLoadedData(): void {
    this.loadedData.forEach((data) => {
      URL.revokeObjectURL(data.data);
    });

    this.loadedData = [];

    this.abortRequests();
  }

  private loadNextImage(): void {
    this.sourcesAreLoading = true;

    const loadedSources = this.sourcesToLoad;
    const currentIndex = this.loadedData.length;
    const currentSource = loadedSources[currentIndex];
    if (!currentSource) {
      this.sourcesAreLoading = false;
      this.eventDispatcher.dispatchEvent('loadingStopped', null);
      return;
    }

    this.eventDispatcher.dispatchEvent('loadNextImage', currentIndex);

    void this.loadImage(currentSource)
      .then((data) => {
        if (this.sourcesToLoad === loadedSources) {
          const imageDataInfo: LoadedDataInfo = {
            index: currentIndex,
            src: currentSource,
            type: data.type,
            data: data.data
          };
          this.loadedData.push(imageDataInfo);
          this.loadNextImage();
          this.eventDispatcher.dispatchEvent('imageLoaded', imageDataInfo);
        }
      })
      .catch((error) => {
        this.sourcesAreLoading = false;
        this.eventDispatcher.dispatchEvent('loadingStopped', null);

        if (error instanceof DOMException && error.name === 'AbortError') {
          return;
        }

        console.error(error);
        this.eventDispatcher.dispatchEvent('loadingError', error);
      });
  }

  private async loadImage(
    source: string
  ): Promise<{ data: string; type: SourceType }> {
    const response = await this.fetchService.fetch(source, this);

    if (response.status !== 200) {
      throw new Error('could not load image');
    }

    const extension = /[\/.]([a-z]+)$/.exec(source)?.[1];
    if (extension === 'dxf') {
      const data = await response.toText();
      const dxf = this.convertDxfIntoSvgString(data);

      return { type: SourceType.SVG, data: dxf };
    } else {
      const blob = await response.toBlob();
      const objectUrl = URL.createObjectURL(blob);

      return { type: SourceType.IMG, data: objectUrl };
    }
  }

  private convertDxfIntoSvgString(file: string): string {
    const dxf = parseDxfFileString(file);
    return createSvgString(dxf, {
      resolveColorIndex: (index) => {
        return DXF_COLOR_HEX[DXF_COLOR_HEX.length - 1 - index] ?? '#000000';
      },
      resolveLineWeight: (lineWeight) => (lineWeight === -3 ? 0.1 : lineWeight),
      resolveFont: (font) => ({
        ...font,
        family: font.family + ',var(--font-family)'
      })
    });
  }
}

export type LoadedDataInfo = {
  index: number;
  src: string;
  type: SourceType;
  data: string;
};

enum SourceType {
  IMG = 'img',
  SVG = 'svg'
}

type EventDispatcherConfig = {
  loadNextImage: number;
  imageLoaded: LoadedDataInfo;
  loadingError: Error;
  loadingStopped: null;
};
