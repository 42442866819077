import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { OptionalDefectSubEntity } from '../Base/DefectSubEntity';
import { OptionalProcessTaskGroupSubEntity } from '../Base/ProcessTaskGroupSubEntity';
import { OptionalProjectSubEntity } from '../Base/ProjectSubEntity';
import { DateType, IdType } from '../Base/types';

type FullPictureFile<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  OptionalProjectSubEntity<TId> &
  OptionalDefectSubEntity<TId> &
  OptionalProcessTaskGroupSubEntity<TId> & {
    /** timestamp */
    file_created: number | null;

    file_uploaded: boolean | null;

    /**
     * needed for upload, won't get synched to database or across devices
     * TODO: this is a client only field and shouldn't be defined in the dto
     */
    isOriginatingHere?: boolean;

    /**
     * needed for offline availability, won't get synched to database or across devices
     * TODO: this is a client only field and shouldn't be defined in the dto
     */
    local_created?: number;

    /**
     * needed for upload, won't get synched to database or across devices
     * TODO: this is a client only field and shouldn't be defined in the dto
     */
    readyForUpload?: boolean | null;

    file_extension: PictureFileExtension;
    type: PictureFileType;

    /** @deprecated */
    project: TId | null;

    picture: TId;

    /** temporarily added while moving the file into the correct folder */
    isBeingRenamed?: boolean | null;

    ownerProcessConfigurationId: TId | null;

    pictureRevisionId: string | null;
  };

export type PictureFileDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullPictureFile<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullPictureFile<TId, TDate> = never
> = Omit<
  OptionalProperties<FullPictureFile<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export enum PictureFileType {
  ORIGINAL = 'original',
  EDITED = 'edited',
  SKETCH = 'sketch',
  CROPPED = 'cropped'
}

export enum PictureFileExtension {
  JPG = 'jpg',
  PNG = 'png',
  SVG = 'svg',
  DXF = 'dxf'
}
