import { autoinject } from 'aurelia-framework';
import { PermissionDataSubscriber } from '../classes/PermissionDataSubscriber';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { UserPermissions } from 'common/Types/Entities/User/UserDto';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { UserPermission } from '../classes/PermissionHelper';

/**
 * @performancereview instead of creating event listeners for every Handle and load the currentuser from the IndexedDB, only effectively have one instance of the PermissionDataSubscriber
 */
@autoinject()
export class PermissionBindingService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService
  ) {}

  public create(config: IPermissionBindingConfig): PermissionBindingHandle {
    const subscriber = new PermissionBindingHandle(
      this.entityManager,
      this.subscriptionManagerService,
      this.currentUserService
    );
    subscriber.configure(config);
    return subscriber;
  }
}

export class PermissionBindingHandle {
  private permissionDataSubscriber: PermissionDataSubscriber;

  private subscriptionManager: SubscriptionManager;

  private config: IPermissionBindingConfig = {
    context: null
  };

  constructor(
    entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionDataSubscriber = new PermissionDataSubscriber({
      entityManager,
      subscriptionManagerService,
      currentUserService
    });
  }

  public configure(config: IPermissionBindingConfig): this {
    this.config = config;
    return this;
  }

  /**
   * Subscribe to all bindings specified in the config
   */
  public subscribe(): void {
    if (this.config.entity) {
      this.subscriptionManager.subscribeToPropertyChange(
        this.config.context,
        this.config.entity.property,
        () => {
          this.entityChanged();
        }
      );
    }
    this.activatePermissionDataSubscriber();
  }

  /**
   * Unsubscribe from all bindings
   */
  public unsubscribe(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionDataSubscriber.dispose();
  }

  private activatePermissionDataSubscriber(): void {
    if (this.config.entity) {
      this.permissionDataSubscriber.setPermissionEntity(
        this.config.context[this.config.entity.property],
        this.config.entity.userGroupPropertyOfEntity
      );
    }
    this.permissionDataSubscriber.subscribe();

    const entityEditablePropertyName = this.config.entity?.editableProperty;
    if (entityEditablePropertyName) {
      this.permissionDataSubscriber.registerBinding(
        'permissionEntityEditable',
        (permissionEntityEditable) => {
          this.config.context[entityEditablePropertyName] =
            permissionEntityEditable;
        }
      );
    }

    const editableUserGroupsPropertyName =
      this.config.editableUserGroupsPropertyName;
    if (editableUserGroupsPropertyName) {
      this.permissionDataSubscriber.registerBinding(
        'editableUserGroups',
        (editableUserGroups) => {
          this.config.context[editableUserGroupsPropertyName] =
            editableUserGroups;
        }
      );
    }

    const currentUserPropertyName = this.config.currentUserPropertyName;
    if (currentUserPropertyName) {
      this.permissionDataSubscriber.registerBinding(
        'currentUser',
        (currentUser) => {
          this.config.context[currentUserPropertyName] = currentUser;
        }
      );
    }

    if (this.config.permissionProperties) {
      for (const [permission, field] of Object.entries(
        this.config.permissionProperties
      ) as Array<[UserPermission, string]>) {
        this.permissionDataSubscriber.bindUserHasPermission(
          permission,
          this.config.context,
          field
        );
      }
    }
  }

  private entityChanged(): void {
    if (this.config.entity) {
      this.permissionDataSubscriber.setPermissionEntity(
        this.config.context[this.config.entity.property],
        this.config.entity.userGroupPropertyOfEntity
      );
    }
  }
}

interface IPermissionBindingConfig {
  /** The context to bind to, usually the object requesting the binding */
  context: any;

  entity?: IEntityBindingConfig;

  /** property name of the context where the editableUserGroups should be written to */
  editableUserGroupsPropertyName?: string | null;

  /** property name of the context where the currentUser should be written to */
  currentUserPropertyName?: string | null;

  /** Permission to property name map. e.g. { 'canUseRegionExtension': 'yourPropertyToBindTo' } */
  permissionProperties?: Partial<Record<keyof UserPermissions<string>, string>>;
}

interface IEntityBindingConfig {
  /** Name of the property to bind the editable status of the given entity to. */
  property: string;

  /** Name of the group property of the **entity (!)** */
  userGroupPropertyOfEntity: string;

  /** Name of the property to bind the editable status of the given entity to. */
  editableProperty?: string;
}
