import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';
import { DialogIconType } from '../../../Enums/DialogIconType';

type FullQuestion<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    text: string;
    choices: Array<QuestionChoice>;
    copy: boolean;
    questionCategoryId: TId | null;
    questionSetId: TId;
    units: Array<QuestionUnit>;
    recommendationTemplates: Array<RecommendationTemplate>;
  };

export type QuestionChoice = {
  choiceText: string;
  actions: Array<QuestionChoiceAction>;
};

export type QuestionChoiceAction = {
  actionType: QuestionChoiceActionType;
  questionId: string | null;
  questionSetId: string | null;
  redirectButtonText: string | null;
  redirectUrl: string | null;
  dialogTitle: string | null;
  dialogText: string | null;
  dialogIconType: DialogIconType | null;
};

export enum QuestionChoiceActionType {
  ADD_QUESTION = 'addQuestion',
  ADD_QUESTION_SET = 'addQuestionSet',
  REDIRECT = 'redirect',
  OPEN_DIALOG = 'openDialog'
}

/**
 * An additional property that may be specified for certain questions.
 *
 * Use cases include useful additional information. For example, if the
 * question is "Is the elevator suitable for wheelchairs?", the question
 * may use a unit for specifying exact width ("Width [cm]").
 */
export interface QuestionUnit {
  title: string;
  unit: string;
}

/**
 * Predefined recommendations for an answer that can be quickly selected
 * by the user when answering questions.
 */
export interface RecommendationTemplate {
  content: string;
}

export type QuestionDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullQuestion<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullQuestion<TId, TDate> = never
> = Omit<
  OptionalProperties<FullQuestion<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;
