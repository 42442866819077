import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ThingToPerson } from '../../classes/EntityManager/entities/ThingToPerson/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { ThingToPersonDeletedEvent } from './thing-person-relations-select-and-edit-widget-relation/thing-person-relations-select-and-edit-widget-relation';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ThingPersonRelationsSelectAndEditWidget {
  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public thingGroup: ThingGroup | null = null;

  @bindable()
  public userGroupId: string | null = null;

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private thingToPersonInfos: Array<ThingPersonRelationInfo> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as ThingPersonRelationsSelectAndEditWidget,
        propertyName: 'thing'
      });
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateThingToPersonInfos.bind(this)
    );
    this.updateThingToPersonInfos();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private thingChanged(): void {
    if (this.isAttached) {
      this.updateThingToPersonInfos();
    }
  }

  private updateThingToPersonInfos(): void {
    if (this.thing) {
      const availableThingToPersons =
        this.entityManager.thingToPersonRepository.getByThingId(this.thing.id);

      const thingToPersonInfos =
        availableThingToPersons.map<ThingPersonRelationInfo>(
          (thingToPerson) => {
            return {
              thingToPerson: thingToPerson,
              personId: thingToPerson.personId,
              selectedPerson: this.entityManager.personRepository.getById(
                thingToPerson.personId
              )
            };
          }
        );

      this.thingToPersonInfos = InstancePreserver.createNewArray({
        originalArray: this.thingToPersonInfos,
        newArray: thingToPersonInfos,
        getTrackingValue: (item) => item.thingToPerson
      });
    } else {
      this.thingToPersonInfos = [];
    }
  }

  protected handleThingToPersonDeleted(event: ThingToPersonDeletedEvent): void {
    const index = this.thingToPersonInfos.findIndex(
      (thingToPersonInfo) =>
        thingToPersonInfo.thingToPerson === event.detail.thingToPerson
    );
    if (index >= 0) this.thingToPersonInfos.splice(index, 1);
  }

  protected handleAddButtonClicked(): void {
    this.thingToPersonInfos.push({
      personId: null,
      selectedPerson: null,
      thingToPerson: null
    });
  }
}

export type ThingPersonRelationInfo = {
  thingToPerson: ThingToPerson | null;
  personId: string | null;
  selectedPerson: Person | null;
};
