import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { OriginalIdUtils } from '../../utils/OriginalIdUtils/OriginalIdUtils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { PictureFileLocalFilesService } from './PictureFileLocalFilesService';
import { PictureFilePathService } from './PictureFilePathService';
import { PictureFileUploadService } from './PictureFileUploadService';
import { PictureFile } from './types';

/**
 * automatically moves synchronized pictureFiles into the correct folder and puts them into the upload queue
 */
@autoinject()
export class PictureFileAutoUploadService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFileLocalFilesService: PictureFileLocalFilesService,
    private readonly pictureFileUploadService: PictureFileUploadService,
    private readonly pictureFilePathService: PictureFilePathService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.PictureFile,
        this.handlePictureFileIdUpgraded.bind(this)
      )
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * returns all pictures which are not yet uploaded, but are originating from here
   * will only return items if this is an app
   */
  public getPictureFilesToUpload(): Array<PictureFile> {
    if (!DeviceInfoHelper.isApp()) {
      return [];
    }

    return this.entityManager.pictureFileRepository.getAll().filter((item) => {
      return (
        item.file_created &&
        item.file_created > 0 &&
        !item.file_uploaded &&
        !item.onlyLocal &&
        item.isOriginatingHere &&
        item.readyForUpload
      );
    });
  }

  private handlePictureFileIdUpgraded(pictureFile: PictureFile): void {
    if (!DeviceInfoHelper.isApp()) {
      return;
    }

    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(pictureFile);

    for (const originalId of originalIds) {
      void this.pictureFileLocalFilesService
        .renameLocalPictureFile(pictureFile, originalId, pictureFile.id)
        .then(() => {
          const pictureFilePath =
            this.pictureFilePathService.getCapturedLocalPicPath({
              pictureFile
            });
          this.pictureFileUploadService.submitToUpload(
            pictureFile,
            null,
            pictureFilePath
          );
        });
    }
  }
}
