import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationCategoryDto } from './ProcessConfigurationCategoryDto';

export function createProcessConfigurationCategoryEntityInfo<
  TEntity extends ProcessConfigurationCategoryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationCategoryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationCategory,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationCategory,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationCategoryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        defaultProcessConfigurationStepId: {
          name: 'defaultProcessConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        appointmentProcessConfigurationStepId: {
          name: 'appointmentProcessConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        invoiceDoneProcessConfigurationStepId: {
          name: 'invoiceDoneProcessConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        abbreviation: {
          name: 'abbreviation',
          defaultValue: null
        },
        general: {
          name: 'general',
          defaultValue: false
        },
        useGeneralPositions: {
          name: 'useGeneralPositions',
          defaultValue: false
        },
        useDevices: {
          name: 'useDevices',
          defaultValue: false
        },
        positionsCategoryName: {
          name: 'positionsCategoryName',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
