import { autoinject, bindable } from 'aurelia-framework';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../../inputComponents/clickable-text-input/clickable-text-input';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';

/**
 * @event {DoneChangedEvent} done-changed
 * @event {TextChangedEvent} text-changed
 *
 * @slot footer
 */
@autoinject()
export class ProcessTaskChecklistListItemLayout {
  /**
   * only to-view bindings are supported
   */
  @bindable()
  public done: boolean = false;

  /**
   * enable the done checkbox
   */
  @bindable()
  public doneEnabled: boolean = false;

  /**
   * only to-view bindings are supported
   */
  @bindable()
  public text: string | null = null;

  /**
   * enable the text input
   */
  @bindable()
  public textEnabled: boolean = false;

  protected textInput: ClickableTextInput | null = null;

  constructor(private readonly element: Element) {}

  public focus(): void {
    this.textInput?.focus();
  }

  protected handleDoneChanged($event: CustomCheckboxCheckedChangedEvent): void {
    DomEventHelper.fireEvent<DoneChangedEvent>(this.element, {
      name: 'done-changed',
      detail: $event.detail
    });
  }

  protected handleTextChanged($event: TTextChangedEvent): void {
    DomEventHelper.fireEvent<TextChangedEvent>(this.element, {
      name: 'text-changed',
      detail: $event.detail
    });
  }
}

export type DoneChangedEvent = NamedCustomEvent<
  'done-changed',
  CustomCheckboxCheckedChangedEvent['detail']
>;
export type TextChangedEvent = NamedCustomEvent<
  'text-changed',
  TTextChangedEvent['detail']
>;
