import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { Project } from '../../classes/EntityManager/entities/Project/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { RecordItModuleHelper } from '../../classes/RecordItModuleHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProjectTypeFilter {
  @bindable public projects: Array<Project> = [];

  @bindable public filteredProjects: Array<Project> = [];

  @bindable public projectType: ProjectType | null = null;

  protected projectTypeFilterOptions: Array<FilterOption> = [];

  private subscriptionManager: SubscriptionManager;

  private currentUser: User | null = null;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser(this.currentUserService.getCurrentUser());
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Change Handlers **********

  protected projectsChanged(): void {
    this.updateFilteredProjects();
  }

  protected projectTypeChanged(): void {
    this.updateFilteredProjects();
  }

  private updateCurrentUser(user: User | null): void {
    this.currentUser = user;
    this.updateFilterOptions();
  }

  protected updateFilteredProjects(): void {
    const projectTypes = this.projectTypeFilterOptions.map(
      (filterOption) => filterOption.projectType
    );
    this.filteredProjects = this.projects.filter((t) => {
      return (
        (!this.projectType && projectTypes.includes(t.projectType)) ||
        t.projectType === this.projectType
      );
    });
  }

  private updateFilterOptions(): void {
    if (!this.currentUser) {
      this.projectTypeFilterOptions = [];
      return;
    }

    const projectTypeFilterOptions: Array<FilterOption> = [];

    const modules = PermissionHelper.getAvailableModulesForUser(
      this.currentUser
    );
    for (const moduleName of modules) {
      const config =
        RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
      assertNotNullOrUndefined(config, 'module is not defined');

      if (!config.projectType || config.projectType === ProjectType.GALLERY)
        continue;

      projectTypeFilterOptions.push({
        projectType: config.projectType,
        label: config.shortDisplayName
      });
    }
    this.projectTypeFilterOptions = projectTypeFilterOptions;
    this.updateFilteredProjects();
  }
}

type FilterOption = {
  label: string;
  projectType: ProjectType | null;
};
