import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskInvoiceToProcessTaskPositionEntityInfo } from 'common/Types/Entities/ProcessTaskInvoiceToProcessTaskPosition/createProcessTaskInvoiceToProcessTaskPositionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskInvoiceToProcessTaskPosition } from './types';

export const processTaskInvoiceToProcessTaskPositionEntityInfo =
  createProcessTaskInvoiceToProcessTaskPositionEntityInfo<ProcessTaskInvoiceToProcessTaskPosition>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
