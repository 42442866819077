import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskMeasurePointReading } from '../../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { defaultProcessTaskMeasurePointReadingProperties } from '../../../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class ProcessTaskMeasurePointReadingValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    configurationStep,
    processTask,
    appointment
  }: FinishAppointmentOptionsWithConfigurationStep): boolean {
    const needsMeasurePointReadings =
      configurationStep?.appointmentMeasurePointReadingsRequired;
    return (
      !needsMeasurePointReadings ||
      this.allMeasurePointsHaveReadings({ processTask, appointment })
    );
  }

  private allMeasurePointsHaveReadings({
    processTask,
    appointment
  }: {
    processTask: ProcessTask;
    appointment: ProcessTaskAppointment;
  }): boolean {
    const measurePoints =
      this.entityManager.processTaskMeasurePointRepository.getByProcessTaskId(
        processTask.id
      );
    if (!measurePoints.length) {
      return false;
    }

    const readings =
      this.entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskAppointmentId(
        appointment.id
      );
    for (const measurePoint of measurePoints) {
      const reading = readings.find(
        (r) => r.processTaskMeasurePointId === measurePoint.id
      );
      if (!reading) {
        return false;
      }

      if (!this.checkMeasurePointReadingProperties({ reading })) {
        return false;
      }
    }

    return true;
  }

  private checkMeasurePointReadingProperties({
    reading
  }: {
    reading: ProcessTaskMeasurePointReading;
  }): boolean {
    const properties =
      this.entityManager.propertyRepository.getSortedPropertiesByProcessTaskMeasurePointReadingId(
        reading.id
      );
    const requiredDefaultProperties =
      defaultProcessTaskMeasurePointReadingProperties.filter(
        (property) => property.required
      );

    return requiredDefaultProperties.every((defaultProperty) => {
      const property = properties.find((p) => p.name === defaultProperty.name);
      return (
        !!property &&
        ((property.value !== null && property.value !== '') ||
          property.custom_choice)
      );
    });
  }
}
