import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { StructureTemplateEntryGroup } from './types';

export class StructureTemplateEntryGroupRepository extends AppEntityRepository<EntityName.StructureTemplateEntryGroup> {
  public getByStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateEntryGroup> {
    return this.getAll().filter(
      (s) => s.ownerStructureTemplateId === structureTemplateId
    );
  }
}
