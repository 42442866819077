/* global $ */

export class NotificationHelper {
  public static notifyDanger(
    message: string,
    autoHideDelay: number | undefined = NotificationDuration.STANDARD,
    placement: NotifySettings['placement'] = defaultPlacement
  ): NotifyReturn {
    return $.notify(
      { message: message },
      { type: 'danger', delay: autoHideDelay, placement }
    );
  }

  public static notifySuccess(
    message: string,
    autoHideDelay: number | undefined = NotificationDuration.STANDARD,
    placement: NotifySettings['placement'] = defaultPlacement
  ): NotifyReturn {
    return $.notify(
      { message: message },
      { type: 'success', delay: autoHideDelay, placement }
    );
  }

  public static notifyNeutral(
    message: string,
    autoHideDelay: number | undefined = NotificationDuration.STANDARD,
    placement: NotifySettings['placement'] = defaultPlacement
  ): NotifyReturn {
    return $.notify({ message: message }, { delay: autoHideDelay, placement });
  }

  public static notifyInverse(message: string): NotifyReturn {
    return $.notify(
      {
        message: message
      },
      {
        type: 'inverse',
        allow_dismiss: true,
        placement: {
          from: 'bottom',
          align: 'right'
        },
        delay: 2500,
        animate: {
          enter: 'animated fadeInRight',
          exit: 'animated fadeOutRight'
        },
        offset: {
          x: 30,
          y: 30
        }
      }
    );
  }

  public static notifyExportSuccess(
    message: string,
    url: string | null
  ): NotifyReturn {
    return $.notify(
      {
        message: message,
        url: url || undefined,
        target: '_self'
      },
      {
        type: 'success',
        delay: 5000,
        url_target: '_self',
        placement: defaultPlacement
      }
    );
  }
}

const defaultPlacement = {
  from: 'bottom',
  align: 'left'
};

export const NotificationDuration = {
  STANDARD: undefined, // 5000ms
  SHORT: 2000
};
