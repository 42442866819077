import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

/**
 * a simple container which gets moved to the [aurelia-app="main"] container or to the body when opened
 */
@autoinject()
export class FullScreenContent {
  private static fullScreenContentContainer: Element | null = null;

  @bindable public respectSafeArea = true;

  public static getFullScreenContentContainer(): Element {
    if (!this.fullScreenContentContainer) {
      // needs to be in here or else some click events will be broken on ios
      const appElement = document.querySelector('[aurelia-app="main"]');
      this.fullScreenContentContainer = appElement ? appElement : document.body;
    }

    return this.fullScreenContentContainer;
  }

  /** placeholder for reinserting the container after closing */
  private placeholderNode: Node | null = null;
  private isOpen: boolean = false;

  constructor(private readonly element: Element) {
    this.setIsOpenAndDisplayProperty(false);
  }

  public detached(): void {
    this.close();
  }

  public open(): void {
    if (this.isOpen) {
      // if the content is already open, we just move it on top of the stack
      FullScreenContent.getFullScreenContentContainer().appendChild(
        this.element
      );
      return;
    }

    assertNotNullOrUndefined(
      this.element.parentNode,
      "can't open the full-screen-content when it isn't attached"
    );
    this.placeholderNode = document.createComment(
      'full-screen-content placeholder'
    );
    this.element.parentNode.insertBefore(this.placeholderNode, this.element);

    FullScreenContent.getFullScreenContentContainer().appendChild(this.element);
    this.setIsOpenAndDisplayProperty(true);
  }

  public close(): void {
    if (!this.isOpen) {
      return;
    }

    assertNotNullOrUndefined(
      this.placeholderNode,
      "can't close the full-screen-content without a placeholder node"
    );

    if (this.placeholderNode.parentNode) {
      if (this.placeholderNode.nextSibling) {
        this.placeholderNode.parentNode.insertBefore(
          this.element,
          this.placeholderNode.nextSibling
        );
      } else {
        this.placeholderNode.parentNode.appendChild(this.element);
      }

      this.placeholderNode.parentNode.removeChild(this.placeholderNode);
    } else {
      // must be detached if there is no parent node, just detach the element then
      if (this.element.parentNode) {
        this.element.parentNode.removeChild(this.element);
      }
    }

    this.setIsOpenAndDisplayProperty(false);
  }

  private setIsOpenAndDisplayProperty(isOpen: boolean): void {
    this.isOpen = isOpen;
    (this.element as HTMLElement).style.display = isOpen ? '' : 'none';
  }
}
