import { autoinject, bindable } from 'aurelia-framework';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { ThingGroupSearch } from './ThingGroupSearch';

/**
 * @event value-changed
 * @event {ThingGroupChangedEvent} thing-group-changed
 */
@autoinject()
export class ThingGroupSelectAndEditWidget {
  /**
   * thing group id
   */
  @bindable()
  public value: string | null = null;

  /**
   * read-only
   */
  @bindable()
  public selectedThingGroup: ThingGroup | null = null;

  /**
   * the userGroupId to limit the thing groups to select
   */
  @bindable()
  public userGroupId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  /**
   * readonly!
   */
  @bindable()
  public hasWarning: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected filterOwnerPersonByCategoryName: string | null = null;
  protected duplicateThingGroups: Array<ThingGroupWithName> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingGroupOwnerPersonCategory',
        (thingGroupOwnerPersonCategory) => {
          this.filterOwnerPersonByCategoryName = thingGroupOwnerPersonCategory;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateDuplicateThingGroups.bind(this)
    );
    this.updateDuplicateThingGroups();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected selectedThingGroupChanged(): void {
    if (this.isAttached) {
      this.updateDuplicateThingGroups();
    }
  }

  private handleThingGroupSelectValueChanged(): void {
    this.fireValueChangedEvent();
  }

  private handleSelectedThingGroupChanged(
    propertyName: keyof ThingGroup
  ): void {
    this.createThingGroupIfNeeded();
    if (this.selectedThingGroup) {
      this.entityManager.thingGroupRepository.update(this.selectedThingGroup);
    }
    this.fireThingGroupChangedEvent(propertyName);
  }

  protected handleUseThingGroupClick(thingGroup: ThingGroup): void {
    const selectedThingGroup = this.selectedThingGroup;

    this.value = thingGroup.id;
    this.selectedThingGroup = thingGroup;

    setTimeout(() => {
      this.fireValueChangedEvent();

      if (selectedThingGroup && selectedThingGroup.shadowEntity) {
        this.entityManager.thingGroupRepository.delete(selectedThingGroup);
      }
    }, 0);
  }

  private updateDuplicateThingGroups(): void {
    this.duplicateThingGroups = this.findDuplicateThingGroups().map(
      (thingGroup) => {
        return {
          thingGroup,
          name: ThingGroupHelper.getThingGroupAddressString(
            thingGroup.streetName,
            thingGroup.zip,
            thingGroup.municipality
          )
        };
      }
    );

    this.hasWarning = !!this.duplicateThingGroups.length;
  }

  private fireValueChangedEvent(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'value-changed',
      detail: null
    });
  }

  private fireThingGroupChangedEvent(propertyName: keyof ThingGroup): void {
    DomEventHelper.fireEvent<ThingGroupChangedEvent>(this.element, {
      name: 'thing-group-changed',
      detail: {
        propertyName: propertyName
      }
    });
  }

  private createThingGroupIfNeeded(): void {
    /* thing group is not null here because aurelia automatically creates an empty object when values are modified,
       so we need to check it via the id if it is valid */
    if (this.selectedThingGroup && this.selectedThingGroup.id) {
      return;
    }

    this.createThingGroup();
  }

  private createThingGroup(): ThingGroup | null {
    if (!this.userGroupId) {
      return null;
    }

    const thingGroup = this.entityManager.thingGroupRepository.create({
      ...this.selectedThingGroup,
      ownerUserGroupId: this.userGroupId,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.value = thingGroup.id;
    this.selectedThingGroup = thingGroup;

    setTimeout(this.fireValueChangedEvent.bind(this), 0);

    return thingGroup;
  }

  private findDuplicateThingGroups(): Array<ThingGroup> {
    if (!this.selectedThingGroup?.shadowEntity || !this.userGroupId) {
      return [];
    }

    const search = new ThingGroupSearch({ entityManager: this.entityManager });
    return search.search({
      name: this.selectedThingGroup.name,
      streetName: this.selectedThingGroup.streetName,
      zip: this.selectedThingGroup.zip,
      userGroupId: this.userGroupId,
      thingGroupIdsToExclude: new Set([this.selectedThingGroup.id])
    });
  }
}

export type ThingGroupChangedEvent = NamedCustomEvent<
  'thing-group-changed',
  { propertyName: keyof ThingGroup }
>;

type ThingGroupWithName = {
  thingGroup: ThingGroup;
  name: string;
};
