import { autoinject, observable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  Picture,
  PictureAdditionalMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { GlobalElements } from '../global-elements/global-elements';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class UltraRapidFireWidgetEditPlanDialog {
  private dialog: RecordItDialog | null = null;

  private thing: Thing | null = null;

  @observable private picture: Picture | null;

  private isMarking = false;

  protected marking: PictureAdditionalMarking | null = null;
  private markings: Array<PictureAdditionalMarking> = [];

  private onDialogClosed:
    | ((additionalMarkings: Array<PictureAdditionalMarking>) => void)
    | null = null;

  private readonly pictureSketchingService: PictureSketchingService;

  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  protected readonly DialogState = DialogState;

  constructor(
    private readonly entityManager: AppEntityManager,
    pictureSketchingService: PictureSketchingService,
    permissionsService: PermissionsService
  ) {
    this.pictureSketchingService = pictureSketchingService;

    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'picture'
      });

    this.picture = null;
  }

  public static async open(
    options: IUltraRapidFireWidgetEditPlanDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IUltraRapidFireWidgetEditPlanDialogOptions): void {
    this.thing =
      this.entityManager.thingRepository.getById(options.thingId) || null;
    assertNotNullOrUndefined(this.thing, 'cannot open dialog without a thing');

    this.markings = options.markings;
    this.onDialogClosed = options.onDialogClosed;

    this.dialog?.open();

    this.picture = null;
  }

  protected pictureChanged(): void {
    const picture = this.picture;
    if (!picture) {
      this.marking = null;
    } else {
      this.marking =
        this.markings.find((m) => m.picture_id === picture.id) || null;
    }
    this.updateDialogTabState();
  }

  private updateDialogTabState(): void {
    if (!this.picture) {
      this.dialog?.setTabDisabled(DialogState.MARK, true);
      this.dialog?.setTabDisabled(DialogState.SELECT, false);
      this.dialog?.selectTab(DialogState.SELECT);
    } else {
      this.dialog?.setTabDisabled(DialogState.MARK, false);
      this.dialog?.selectTab(DialogState.MARK);
    }
  }

  protected handleDialogOpened(): void {
    this.updateDialogTabState();
  }

  protected handleDialogClosed(): void {
    const markings = this.markings;
    const onClosed = this.onDialogClosed;

    this.picture = null;
    this.thing = null;
    this.markings = [];
    this.onDialogClosed = null;

    onClosed && onClosed(markings);
  }

  protected handleDeletePictureClick(): void {
    const picture = this.picture;
    if (!picture) return;

    void Dialogs.deleteEntityDialog(picture).then(() => {
      this.entityManager.pictureRepository.delete(picture);
    });
  }

  protected handleSketchPictureClick(): void {
    if (this.picture) {
      this.pictureSketchingService.sketchPicture(this.picture);
    }
  }

  protected handleToggleMarkingClick(): void {
    this.isMarking = !this.isMarking;
  }

  protected handlePictureClick(event: MouseEvent): void {
    const picture = this.picture;
    if (!this.isMarking || !picture) return;

    const targetElement = event.target as HTMLElement;

    const left =
      event.offsetX /
        (parseInt(window.getComputedStyle(targetElement).width || '0') / 100) +
      '%';
    const top =
      event.offsetY /
        (parseInt(window.getComputedStyle(targetElement).height || '0') / 100) +
      '%';

    const marking = this.markings.find((m) => m.picture_id === picture.id);
    if (marking) {
      marking.left = left;
      marking.top = top;
    } else {
      const newMarking = {
        left: left,
        top: top,
        picture_id: picture.id
      };
      this.markings.push(newMarking);
      this.marking = newMarking;
    }

    this.isMarking = false;
  }

  protected handleSelectPictureClick(picture: Picture): void {
    this.picture = picture;
  }
}

enum DialogState {
  SELECT = 'select',
  MARK = 'mark'
}

export interface IUltraRapidFireWidgetEditPlanDialogOptions {
  thingId: string;
  markings: Array<PictureAdditionalMarking>;
  onDialogClosed: (additionalMarkings: Array<PictureAdditionalMarking>) => void;
}
