import { computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { GeneralFile } from '../../../../classes/EntityManager/entities/GeneralFile/types';
import { Property } from '../../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { CreateGeneralFileOptions } from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';

export class PropertyGeneralFileHandle implements Disposable {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly property: Property;
  private readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];
  private internalGeneralFiles: Array<GeneralFile> = [];

  constructor(options: GeneralFileHandleOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;
    this.propertyPermissionsHandle =
      options.alreadySubscribedPropertyPermissionsHandle;
    this.subscriptionManager = options.subscriptionManagerService.create();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.GeneralFile,
      this.updateGeneralFiles.bind(this)
    );
    this.updateGeneralFiles();
  }

  @computedFrom('propertyPermissionsHandle.canEditGeneralFiles')
  public get canCreateGeneralFile(): boolean {
    return this.propertyPermissionsHandle.canEditGeneralFiles;
  }

  @computedFrom('internalGeneralFiles')
  public get generalFiles(): Array<GeneralFile> {
    return this.internalGeneralFiles;
  }

  public createGeneralFile(options: CreateGeneralFileOptions): GeneralFile {
    // any casts because typescript can't check this (yet)
    return this.entityManager.generalFileRepository.create({
      type: options.type,
      propertyId: this.property.id,
      ownerUserGroupId: this.property.ownerUserGroupId,
      ownerProcessTaskGroupId: this.property.ownerProcessTaskGroupId as any,
      ownerProcessTaskId: this.property.ownerProcessTaskId as any,
      ownerProjectId: this.property.ownerProjectId as any,
      ownerDefectId: this.property.ownerDefectId as any
    });
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateGeneralFiles(): void {
    this.internalGeneralFiles =
      this.entityManager.generalFileRepository.getByPropertyId(
        this.property.id
      );
  }
}

export type GeneralFileHandleOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  property: Property;
  alreadySubscribedPropertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];
};
