import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskPositionEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/processTaskPositionEntityInfo';
import { ProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessTaskPositionAdapter
  implements EntityAdapter<ProcessTaskPosition>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> = new Set();

  constructor(options: ProcessTaskPositionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(entity: ProcessTaskPosition): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public canEditProperties(entity: ProcessTaskPosition): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public canEditDetailEntries(entity: ProcessTaskPosition): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public canDeleteEntity(entity: ProcessTaskPosition): boolean {
    return this.entityIsEditableUserGroup(entity);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName,
    ProcessTaskPosition
  > {
    return processTaskPositionEntityInfo;
  }

  private entityIsEditableUserGroup(entity: ProcessTaskPosition): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type ProcessTaskPositionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
