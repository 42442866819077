import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { DefectStatus } from 'common/Enums/DefectStatus';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import {
  MultiFileInput,
  TSelectedFile
} from '../../inputComponents/multi-file-input/multi-file-input';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { DefectComment } from '../../classes/EntityManager/entities/DefectComment/types';
import { PictureCreatorService } from '../../classes/Picture/PictureCreatorService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';

/**
 * An area where users can create new comments.
 *
 * The users can enter a comment text, can select a status, and submit.
 */
@autoinject()
export class DefectDetailsNewComment {
  /**
   * The defect where the comment should be added.
   */
  @bindable public defect: Defect | null = null;

  /**
   * The text of the comment to be created.
   * Bound to the textarea.
   */
  protected commentText = '';

  /**
   * The status which the defect should be set to.
   * Is initialized to the current defect status.
   */
  protected statusChange: DefectStatusUpdateChange = 'none';

  /**
   * A view model reference to the multi-file-input.
   */
  protected multiFileInput: MultiFileInput | null = null;

  /**
   * The files to upload together with the new comment.
   */
  protected selectedFiles: Array<TSelectedFile> = [];

  /**
   * DataUrls of Pictures to upload together with the new comment.
   */
  protected selectedPictureDataUrls: Array<string> = [];

  @subscribableLifecycle()
  private defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly generalFileUploadService: GeneralFileUploadService,
    private readonly currentUserService: CurrentUserService,
    private readonly pictureCreatorService: PictureCreatorService,
    permissionsService: PermissionsService
  ) {
    this.defectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Defect,
        context: this,
        expression: 'defect'
      });
  }

  // ////////// METHODS //////////

  private async createNewDefectComment(): Promise<DefectComment> {
    assertNotNullOrUndefined(
      this.defect,
      "Can't create a new defect comment without a defect"
    );

    const user = await this.currentUserService.getCurrentUser();
    assertNotNullOrUndefined(
      user,
      "Can't create a new defect comment without a current user"
    );

    const defectComment = this.entityManager.defectCommentRepository.create({
      created: new Date().toISOString(),
      ownerDefectId: this.defect.id,
      ownerThingId: this.defect.ownerThingId,
      ownerUserGroupId: this.defect.ownerUserGroupId,
      senderId: user.id,
      message: this.commentText,
      statusChange: this.statusChange !== 'none' ? this.statusChange : null
    });
    if (this.statusChange !== 'none') {
      // If the DefectComment includes a change to the defect status, update the defect as well
      this.entityManager.defectRepository.update({
        ...this.defect,
        status: this.statusChange
      });
    }

    return defectComment;
  }

  private async createDefectCommentFiles(
    defectComment: DefectComment
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.multiFileInput,
      'cannot create a new defect comment without a multi file input'
    );

    await this.multiFileInput.selectedFilesLoadedAwaitable();
    this.selectedFiles.forEach((file) => {
      if (file.dataUrl === null) {
        console.error('Uploading failed - selected file has no dataUrl');
        return;
      }
      const [name, extension] = this.splitFilename(file.name ?? '');
      const generalFile = this.entityManager.generalFileRepository.create({
        name: name,
        defectCommentId: defectComment.id,
        ownerUserGroupId: defectComment.ownerUserGroupId,
        ownerDefectId: defectComment.ownerDefectId
      });
      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        file.dataUrl,
        extension,
        false
      );
    });
  }

  private async createDefectCommentPictures(
    defectComment: DefectComment
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.defect,
      'cannot createDefectCommentPictures without a defect'
    );

    const defect = this.defect;
    const pictureCreator = this.pictureCreatorService.withEntityInfos(() => ({
      mainEntityIdField: 'ownerDefectId',
      mainEntityId: defect.id,
      subEntityField: 'defectComment',
      subEntityValue: defectComment.id,
      ownerProjectId: null,
      ownerUserGroupId: defect.ownerUserGroupId
    }));

    this.selectedPictureDataUrls.forEach((dataUrl) => {
      pictureCreator.createPictureFromDataUrl(dataUrl);
    });
  }

  private resetUI(): void {
    this.commentText = '';
    this.selectedFiles = [];
    this.selectedPictureDataUrls = [];
    this.statusChange = 'none';
  }

  /**
   * Splits a filename into a `[fileName, extension]` tuple.
   *
   * @example ```ts
   *  splitFilename('test') === ['test', '']
   *  splitFilename('test.ext') === ['test', 'ext']
   * ```
   */
  private splitFilename(fileName: string): [string, string] {
    const { name, extension } = Utils.getFilePathComponents(fileName);
    return [name ?? '', extension ?? ''];
  }

  // ////////// CLICK HANDLERS //////////

  /**
   * We are a bit generous here and allow choice selection by clicking on the label too.
   * Should make it a tad easier to select a choice on mobile.
   */
  protected handleStatusChangeLabelClick(
    choice: DefectStatusUpdateChange
  ): void {
    if (choice !== this.defect?.status) {
      this.statusChange = choice;
    }
  }

  protected async handleSendButtonClick(): Promise<void> {
    const comment = await this.createNewDefectComment();
    await this.createDefectCommentFiles(comment);
    await this.createDefectCommentPictures(comment);
    this.resetUI();
  }

  // /////////// HTML HELPERS /////////////

  /**
   * Returns the translation key for a given status choice.
   */
  protected getStatusChangeTk(choice: DefectStatusUpdateChange): string {
    const status: string = choice;
    return `modelsDetail.DefectModel.status${StringUtils.upperCaseFirstLetter(
      status
    )}`;
  }

  /**
   * Returns the css color for a given status choice, which will be assigned as
   * the radio button label color.
   */
  protected getStatusChangeColor(
    choice: DefectStatusUpdateChange,
    disabledStatusChange: DefectStatus | null
  ): string | null {
    if (choice === 'none') return null;
    if (choice === disabledStatusChange)
      return 'var(--record-it-color-gray-600)';
    return `var(--record-it-color-defect-state-${choice})`;
  }

  @computedFrom(
    'commentText',
    'statusChange',
    'multiFileInput.selectedFiles.length',
    'selectedPictureDataUrls.length'
  )
  protected get isCreateDefectCommentButtonEnabled(): boolean {
    assertNotNullOrUndefined(
      this.multiFileInput,
      'cannot retrieve isCreateDefectCommentButtonEnabled without multiFileInput'
    );

    const hasText = this.commentText.trim() !== '';
    const hasStatusChange = this.statusChange !== 'none';
    const hasFile = this.multiFileInput.selectedFiles.length > 0;
    const hasPictures = this.selectedPictureDataUrls.length > 0;
    return hasText || hasStatusChange || hasFile || hasPictures;
  }

  @computed(
    expression('defectPermissionsHandle.canSetDefectStatusToNone'),
    expression('defectPermissionsHandle.canSetDefectStatusToOpen'),
    expression('defectPermissionsHandle.canSetDefectStatusToProcessed'),
    expression('defectPermissionsHandle.canSetDefectStatusToDone')
  )
  protected get availableStatusChanges(): Array<DefectStatusUpdateChange> {
    const status: Array<DefectStatusUpdateChange> = [];

    if (this.defectPermissionsHandle.canSetDefectStatusToNone) {
      status.push('none');
    }

    if (this.defectPermissionsHandle.canSetDefectStatusToOpen) {
      status.push(DefectStatus.OPEN);
    }

    if (this.defectPermissionsHandle.canSetDefectStatusToProcessed) {
      status.push(DefectStatus.PROCESSED);
    }

    if (this.defectPermissionsHandle.canSetDefectStatusToDone) {
      status.push(DefectStatus.DONE);
    }

    return status;
  }
}

type DefectStatusUpdateChange = DefectStatus | 'none';
