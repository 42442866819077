import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import {
  NavigationButtonConfig,
  RecordItDialog
} from '../../dialogs/record-it-dialog/record-it-dialog';
import { ThingSection } from '../../classes/EntityManager/entities/ThingSection/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { IconType } from '../../aureliaComponents/custom-icon/custom-icon';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingSectionSelectedEvent } from './thing-section-assessment-dialog-thing-section-content/thing-section-assessment-dialog-thing-section-content';
import { LastUsedThingSectionForIdService } from '../../services/LastUsedEntityService';

@autoinject()
export class ThingSectionAssessmentDialog {
  public static async open(
    options: UltraRapidFireWidgetEditThingSectionDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  protected thing: Thing | null = null;
  protected project: Project | null = null;
  protected thingSections: Array<ThingSection> = [];
  protected currentThingSection: ThingSection | null = null;
  protected navigationButtonConfigs: Array<NavigationButtonConfig> = [];

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly lastUsedThingSectionForIdService: LastUsedThingSectionForIdService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(
    options: UltraRapidFireWidgetEditThingSectionDialogOpenOptions
  ): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't UltraRapidFireWidgetEditThingSectionDialog.open without dialog"
    );

    this.thing = options.thing;
    this.project = options.project;
    this.currentThingSection = options.thingSection ?? null;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSection,
      this.updateThingSections.bind(this)
    );
    this.updateThingSections();

    if (options.thingSection) {
      void this.lastUsedThingSectionForIdService.setLastUsed(
        options.project.id,
        options.thingSection.id
      );
    } else {
      void this.lastUsedThingSectionForIdService
        .getLastUsed(options.project.id)
        .then((lastUsedThingSectionId) => {
          const currentThingSection = lastUsedThingSectionId
            ? this.thingSections.find(
                (thingSection) => thingSection.id === lastUsedThingSectionId
              )
            : null;
          if (currentThingSection) {
            this.currentThingSection = currentThingSection;
            this.updateNavigationButtonConfigs();
          }
        });
    }

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.reset();
  }

  private updateThingSections(): void {
    if (this.thing) {
      this.thingSections =
        this.entityManager.thingSectionRepository.getOrderedByThingId(
          this.thing.id
        );
    } else {
      this.thingSections = [];
    }

    if (
      !this.currentThingSection ||
      this.thingSections.indexOf(this.currentThingSection) === -1
    ) {
      this.currentThingSection = this.thingSections[0] ?? null;
    }

    this.updateNavigationButtonConfigs();
  }

  private updateNavigationButtonConfigs(): void {
    const configs: Array<NavigationButtonConfig> = [];

    const index = this.currentThingSection
      ? this.thingSections.indexOf(this.currentThingSection)
      : -1;
    const previousThingSection = this.thingSections[index - 1];
    const nextThingSection = this.thingSections[index + 1];

    if (previousThingSection) {
      configs.push({
        name: 'previousThingSection',
        iconName: 'fa-arrow-left',
        iconType: IconType.FAL,
        position: 'left',
        onClick: this.reopenWithThingSection.bind(this, previousThingSection)
      });
    }

    if (nextThingSection) {
      configs.push({
        name: 'nextThingSection',
        iconName: 'fa-arrow-right',
        iconType: IconType.FAL,
        position: 'right',
        onClick: this.reopenWithThingSection.bind(this, nextThingSection)
      });
    }

    this.navigationButtonConfigs = configs;
  }

  protected handleThingSectionSelected(event: ThingSectionSelectedEvent): void {
    this.reopenWithThingSection(event.detail.thingSection);
  }

  private reopenWithThingSection(thingSection: ThingSection): void {
    const project = this.project;
    const thing = this.thing;
    assertNotNullOrUndefined(
      project,
      "can't ThingSectionAssessmentDialog.reopenWithThingSection without project"
    );
    assertNotNullOrUndefined(
      thing,
      "can't ThingSectionAssessmentDialog.reopenWithThingSection without thing"
    );

    this.reset();

    this.open({
      project,
      thing,
      thingSection
    });
  }

  private reset(): void {
    this.thing = null;
    this.project = null;

    this.subscriptionManager.disposeSubscriptions();
  }
}

export type UltraRapidFireWidgetEditThingSectionDialogOpenOptions = {
  thing: Thing;
  project: Project;

  /**
   * Opens the dialog with this specific thingSection
   */
  thingSection?: ThingSection | null;
};
