import { autoinject, bindable } from 'aurelia-framework';

import { Vector } from 'common/Geometry/Vector';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  IPictureMarking,
  TPictureAdditionalMarking
} from 'common/Types/Entities/Picture/PictureDto';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ZoomBoxPictureResizer } from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { TZoomBoxResizerHelperPicturePositionInfo } from '../../aureliaComponents/zoom-box/ZoomBoxResizerHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import {
  ContentClickedEvent,
  ZoomBox,
  ZoomBoxCustomButtonConfig
} from '../../aureliaComponents/zoom-box/zoom-box';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PictureMarkingsCalculator } from './PictureMarkingsCalculator';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { MarkingClickedEvent } from './picture-marking-overlay-marking/picture-marking-overlay-marking';
import { configureHooks } from '../../hooks/configureHooks';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';

/**
 * @event marking-set - is fired when the user set a new marking
 */
@autoinject()
@configureHooks({})
export class PictureMarkingOverlay {
  /**
   * picture where the marking is originating from
   */
  @bindable public picture: Picture | null = null;

  /**
   * relative picture
   */
  @bindable public parentPicture: Picture | null = null;

  /**
   * an array of extra markings to display
   */
  @bindable public markings: Array<IPictureMarking> = [];

  @bindable public showPictureMarking: boolean = false;

  /**
   * the marking to show if you want to show an extra marking/an additional_marking etc.
   */
  @bindable public currentMarkings: Array<IPictureMarking> = [];

  @bindable public navigationButtonsConfig: NavigationButtonsConfig = {};

  @bindable public editMarkings = false;

  private readonly subscriptionManager: SubscriptionManager;
  private fullScreenOverlay: FullScreenOverlay | null = null;

  private domElement: HTMLElement;

  private zoomBoxElement: HTMLElement | null = null;
  private zoomBox: ZoomBox | null = null;

  private pictureResizer: ZoomBoxPictureResizer | null = null;

  private zoomBoxRendered = false;

  private pictureElement: HTMLElement | null = null;

  private isMarking: boolean = false;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  protected editingMode: EditingMode = EditingMode.ADD_MARKING;

  protected EditingMode = EditingMode;

  protected readonly zoomBoxCustomButtonConfigs: Array<ZoomBoxCustomButtonConfig> =
    [
      {
        faIconName: 'fa-paint-brush',
        onButtonClicked: this.handleSketchPictureClick.bind(this)
      }
    ];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly pictureSketchingService: PictureSketchingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * @param {boolean} isMarking - set to false if you only want to show the picture with the markings, else set it to true
   */
  public open(isMarking: boolean): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't PictureMarkingOverlay.open without fullScreenOverlay"
    );

    this.isMarking = isMarking;
    this.fullScreenOverlay.open();

    this.resetPictureResizer();
    this.setupZoomBox();
  }

  public close(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't PictureMarkingOverlay.close without fullScreenOverlay"
    );

    this.fullScreenOverlay.close();
  }

  protected handleFullScreenOverlayClosed(): void {
    this.zoomBoxRendered = false;
    this.resetPictureResizer();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleSketchPictureClick(): void {
    assertNotNullOrUndefined(
      this.parentPicture,
      'cannot handleSketchPictureClick without parentPicture'
    );
    this.pictureSketchingService.sketchPicture(this.parentPicture);
  }

  private resetPictureResizer(): void {
    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  private setupZoomBox(): void {
    this.zoomBoxRendered = true;

    // wait for the zoomBox to be rendered
    setTimeout(() => {
      if (!this.zoomBoxRendered) {
        // has been closed in the meantime, no need to initialize it
        return;
      }

      assertNotNullOrUndefined(
        this.pictureElement,
        'cannot open picture marking overlay without a picture element'
      );
      assertNotNullOrUndefined(
        this.zoomBoxElement,
        'cannot open picture marking overlay without the zoom box element'
      );

      this.pictureResizer = new ZoomBoxPictureResizer(
        this.pictureElement,
        this.zoomBoxElement
      );
      this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
        this.picturePositionInfo = picturePositionInfo;
        this.setZoomBoxToCurrentMarkingDimensions();
      });
      this.pictureResizer.update();
    }, 0);
  }

  @computed(
    model(EntityName.ThingSection),
    expression('parentPicture.thingSectionId')
  )
  protected get headerText(): string | null {
    if (this.parentPicture?.thingSectionId) {
      const thingSection = this.entityManager.thingSectionRepository.getById(
        this.parentPicture.thingSectionId
      );
      return thingSection?.name ?? null;
    }

    return null;
  }

  protected handleZoomBoxContentClicked(event: ContentClickedEvent): void {
    assertNotNullOrUndefined(
      this.parentPicture,
      "can't PictureMarkingOverlay.handleZoomBoxContentClicked without parentPicture"
    );

    const prepareMarkingResult = this.prepareMarking({
      picture: this.parentPicture,
      position: event.detail.position,
      boxTopLeft: event.detail.boxTopLeft,
      boxBottomRight: event.detail.boxBottomRight
    });

    if (!this.editMarkings) {
      this.handleMarkingSet(prepareMarkingResult);
    } else if (this.editingMode === EditingMode.ADD_MARKING) {
      if (prepareMarkingResult) {
        DomEventHelper.fireEvent<CurrentMarkingAddedEvent>(this.domElement, {
          name: 'current-marking-added',
          detail: {
            currentMarking: prepareMarkingResult.pictureMarking
          }
        });
      }
    }
  }

  private handleMarkingSet(markingResult: MarkingResult | null): void {
    if (this.isMarking && markingResult) {
      DomEventHelper.fireEvent<MarkingSetEvent>(this.domElement, {
        name: 'marking-set',
        detail: {
          parentPictureMarking: markingResult.pictureMarking,
          originatedFromPictureMarking:
            markingResult.originatedFromPictureMarking
        }
      });
    }

    if (this.fullScreenOverlay) this.fullScreenOverlay.close();
  }

  private prepareMarking({
    picture,
    position,
    boxTopLeft,
    boxBottomRight
  }: {
    picture: Picture;
    position: Vector;
    boxTopLeft: Vector;
    boxBottomRight: Vector;
  }): MarkingResult | null {
    const relativeMarkingPosition = this.getRelativePicturePosition({
      zoomBoxPosition: position
    });

    const relativeBoxTopLeft = this.getRelativePicturePositionOrDefault({
      zoomBoxPosition: boxTopLeft,
      defaultRelativeValue: new Vector(0, 0)
    });

    const relativeBoxBottomRight = this.getRelativePicturePositionOrDefault({
      zoomBoxPosition: boxBottomRight,
      defaultRelativeValue: new Vector(1, 1)
    });

    if (!relativeMarkingPosition) return null;

    const calculator = new PictureMarkingsCalculator();
    const { pictureMarking, originatedFromPictureMarking } =
      calculator.getPictureMarkings({
        picture: picture,
        relativeMarkingPosition,
        relativeBoxTopLeft,
        relativeBoxBottomRight
      });

    return {
      pictureMarking,
      originatedFromPictureMarking
    };
  }

  private getRelativePicturePositionOrDefault({
    zoomBoxPosition,
    defaultRelativeValue
  }: {
    zoomBoxPosition: Vector;
    defaultRelativeValue: Vector;
  }): Vector {
    const relativePosition = this.getRelativePicturePosition({
      zoomBoxPosition
    });
    return relativePosition ?? defaultRelativeValue;
  }

  /**
   * returns a percentual (0 - 1) value of the contentPosition relative to the picture
   *
   * @param {Vector} zoomBoxPosition
   * @returns {Vector|null} - returns null if the picture hasn't been clicked
   */
  private getRelativePicturePosition({
    zoomBoxPosition
  }: {
    zoomBoxPosition: Vector;
  }): Vector | null {
    assertNotNullOrUndefined(
      this.pictureElement,
      'cannot get marking position without picture element'
    );

    const picturePosition = new Vector(
      this.pictureElement.offsetLeft,
      this.pictureElement.offsetTop
    );
    const pictureComputed = window.getComputedStyle(this.pictureElement);
    const pictureWidth = parseFloat(pictureComputed.width);
    const pictureHeight = parseFloat(pictureComputed.height);

    const normalizedContentPosition = zoomBoxPosition
      .clone()
      .substractVector(picturePosition);
    const x = normalizedContentPosition.getX();
    const y = normalizedContentPosition.getY();

    if (x >= 0 && x <= pictureWidth && y >= 0 && y <= pictureHeight) {
      return normalizedContentPosition.divideVector(
        new Vector(pictureWidth, pictureHeight)
      );
    } else {
      return null;
    }
  }

  private setZoomBoxToCurrentMarkingDimensions(): void {
    assertNotNullOrUndefined(this.zoomBox, 'zoom box is not available');

    const [topLeft, bottomRight] = this.currentMarkings.reduce<
      [Vector, Vector]
    >(
      ([topLeftVector, bottomRightVector], currentMarking) => {
        if (currentMarking.boxLeft && currentMarking.boxTop) {
          topLeftVector.setX(
            Math.min(topLeftVector.getX(), currentMarking.boxLeft / 100)
          );
          topLeftVector.setY(
            Math.min(topLeftVector.getY(), currentMarking.boxTop / 100)
          );
        }

        if (currentMarking.boxRight && currentMarking.boxBottom) {
          bottomRightVector.setX(
            Math.max(bottomRightVector.getX(), currentMarking.boxRight / 100)
          );
          bottomRightVector.setY(
            Math.max(bottomRightVector.getY(), currentMarking.boxBottom / 100)
          );
        }

        return [topLeftVector, bottomRightVector];
      },
      [new Vector(0, 0), new Vector(0, 0)]
    );

    if (topLeft.getLength() === 0 && bottomRight.getLength() === 0) return;

    const absoluteTopLeft =
      this.getAbsolutePicturePositionForRelativeZoomBoxPosition(topLeft);
    const absoluteBottomRight =
      this.getAbsolutePicturePositionForRelativeZoomBoxPosition(bottomRight);

    const dimensions = absoluteBottomRight
      .clone()
      .substractVector(absoluteTopLeft);

    const centerPoint = absoluteTopLeft
      .clone()
      .addVector(dimensions.clone().divideXY(2, 2));

    const zoomBoxDimensions = this.zoomBox.getZoomBoxDimensions();

    const zoom = zoomBoxDimensions.getX() / dimensions.getX();

    this.zoomBox.setZoom(zoom);
    this.zoomBox.setCenterPoint(centerPoint);
  }

  private getAbsolutePicturePositionForRelativeZoomBoxPosition(
    relativePosition: Vector
  ): Vector {
    assertNotNullOrUndefined(
      this.picturePositionInfo,
      'cannot get marking position without picture position info'
    );

    return relativePosition
      .clone()
      .multiplyVector(this.picturePositionInfo.size)
      .addVector(this.picturePositionInfo.topLeftPosition);
  }

  protected handleLeftNavigationButtonClick(): void {
    DomEventHelper.fireEvent<LeftNavigationButtonClickedEvent>(
      this.domElement,
      {
        name: 'left-navigation-button-clicked',
        detail: null
      }
    );
  }

  protected handleRightNavigationButtonClick(): void {
    DomEventHelper.fireEvent<RightNavigationButtonClickedEvent>(
      this.domElement,
      {
        name: 'right-navigation-button-clicked',
        detail: null
      }
    );
  }

  protected handleSetEditingMode(editingMode: EditingMode): void {
    this.editingMode = editingMode;
  }

  protected handleMarkingClicked(
    event: MarkingClickedEvent,
    marking: IPictureMarking
  ): void {
    if (this.editingMode !== EditingMode.REMOVE_MARKING) return;

    assertNotNullOrUndefined(
      this.parentPicture,
      'cannot remove marking without a parent picture'
    );

    DomEventHelper.fireEvent<CurrentMarkingRemovedEvent>(this.domElement, {
      name: 'current-marking-removed',
      detail: {
        currentMarking: {
          ...marking,
          picture_id: this.parentPicture.id
        }
      }
    });
  }
}

export type MarkingSetEvent = NamedCustomEvent<
  'marking-set',
  {
    parentPictureMarking: TPictureAdditionalMarking;
    originatedFromPictureMarking: TPictureAdditionalMarking | null;
  }
>;

export type CurrentMarkingAddedEvent = NamedCustomEvent<
  'current-marking-added',
  {
    currentMarking: TPictureAdditionalMarking;
  }
>;

export type CurrentMarkingRemovedEvent = NamedCustomEvent<
  'current-marking-removed',
  {
    currentMarking: TPictureAdditionalMarking;
  }
>;

export type LeftNavigationButtonClickedEvent = NamedCustomEvent<
  'left-navigation-button-clicked',
  null
>;

export type RightNavigationButtonClickedEvent = NamedCustomEvent<
  'right-navigation-button-clicked',
  null
>;

type NavigationButtonsConfig = {
  showLeftNavigationButton?: boolean;
  showRightNavigationButton?: boolean;
};

enum EditingMode {
  ADD_MARKING = 'addMarking',
  REMOVE_MARKING = 'removeMarking'
}

type MarkingResult = {
  pictureMarking: TPictureAdditionalMarking;
  originatedFromPictureMarking: TPictureAdditionalMarking | null;
};
