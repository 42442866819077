import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { DefectComment as DefectCommentEntity } from '../../classes/EntityManager/entities/DefectComment/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import {
  AttachmentFileContent,
  AttachmentPictureContent,
  AttachmentContent
} from '../defect-comment-attachment/defect-comment-attachment';
import { computed } from '../../hooks/computed';
import { model, expression } from '../../hooks/dependencies';

/**
 * A comment written on a defect by a certain user.
 *
 * Displays the comment text, and optionally attachments and state changes.
 */
@autoinject()
export class DefectComment {
  @bindable public defectComment: DefectCommentEntity | null = null;

  /**
   * The creator of the comment.
   * Used to display the username in status changes.
   */
  @bindable public defectCommentUser: User | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  // ////////// GETTERS //////////

  /**
   * File attachments of the comment.
   */
  @computed(
    expression('defectComment'),
    model(EntityName.GeneralFile),
    model(EntityName.Picture)
  )
  protected get defectCommentAttachments(): Array<AttachmentContent> {
    if (!this.defectComment) {
      return [];
    }

    const fileAttachments =
      this.entityManager.generalFileRepository.getByDefectCommentId(
        this.defectComment.id
      );
    const pictureAttachments =
      this.entityManager.pictureRepository.getByDefectCommentId(
        this.defectComment.ownerDefectId,
        this.defectComment.id
      );
    return [
      ...fileAttachments.map(
        (a) => ({ type: 'file', file: a }) as AttachmentFileContent
      ),
      ...pictureAttachments.map(
        (a) => ({ type: 'picture', picture: a }) as AttachmentPictureContent
      )
    ];
  }

  @computedFrom('defectComment.created')
  protected get defectCommentTime(): string {
    if (!this.defectComment) return '...';
    return `${DateUtils.formatToHourMinuteString(
      this.defectComment.created
    )} Uhr`;
  }

  @computedFrom('defectComment.sequenceNumber')
  protected get paddedSequenceNum(): string {
    if (!this.defectComment?.sequenceNumber) return '';
    return `${this.defectComment.sequenceNumber}`.padStart(2, '0');
  }

  /**
   * Returns the comment statusChange capitalized.
   */
  @computedFrom('defectComment.statusChange')
  protected get defectCommentStatusCapitalized(): string | null {
    const status = this.defectComment?.statusChange || null;
    if (!status) return null;
    return StringUtils.upperCaseFirstLetter(status);
  }
}
