import { autoinject } from 'aurelia-framework';

/**
 * Inserts the content of this aurelia component into the DOM only if the parent is visible.
 * If the parent is not visible, the content will not be inserted into the DOM.
 *
 * This is needed for multiline inputs which initialize incorrectly if they are rendered with `display: none`.
 * Can also be used to prevent potentially expensive child components from initializing if they won't render anyway.
 *
 * Using a slot to inject the content would be ideal, but dynamic slots are not supported. So we have to use replaceable.
 * Wrapping the content in an `<template replace-part="content">` can be kind of annoying, so maybe we should improve this in the future if we need this feature more often.
 * Maybe we can find a solution in the `if.to-view`, since it neither uses a replaceable nor a slot. But this needs a deeper understanding of aurelia and implementing it will take quite some time.
 *
 * How to use:
 * <div class="container">
 *   <render-content-if-visible>
 *     <div class="content">Span some content that takes a lot of resources or needs to be visible to be rendered correctly (e.g. if it has multiline inputs)</div>
 *   </render-content-if-visible>
 * </div>
 *
 * If the `.container` is visible, then the `.content` will be rendered.
 * If the `.container` gets set to `display: none`, then the `.content` will not be rendered.
 *
 * @replaceable content
 *
 * ```html
 * <div class="container">
 *   <render-content-if-visible>
 *     <!-- Content that should only appear in the DOM if its visible -->
 *     <div class="content">Some content here (e.g. a multiline input)</div>
 *   </render-content-if-visible>
 * </div>
 * ```
 */
@autoinject()
export class RenderContentIfVisible {
  private readonly resizeObserver: ResizeObserver;

  protected contentIsRendered: boolean = false;

  constructor(private readonly element: Element) {
    this.resizeObserver = new ResizeObserver(() => {
      // Since we modify the dom here and trigger a new resize here, we have to do that in a different loop,
      // or we will get a "ResizeObserver loop limit exceeded" Error (even though this will not result in an endless loop).
      // Normally this shouldn't happen, because aurelia (normally) does dom updates are asynchronously.
      // But perhaps in some cases it's possible that aurelia sometimes updates the dom synchronously. (That is the only explanation I could come up with. It's only an assumption and I haven't looked for any proof yet)
      setTimeout(() => {
        this.update();
      });
    });
  }

  protected attached(): void {
    this.resizeObserver.observe(this.element);
    this.update();
  }

  protected detached(): void {
    this.resizeObserver.unobserve(this.element);
  }

  private update(): void {
    // if no width can be calculated, it will be set to auto
    // this should mean it is not rendered
    this.contentIsRendered =
      window.getComputedStyle(this.element).width !== 'auto';
  }
}
