import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import {
  CreateTagClickedEvent,
  DeleteTagClickedEvent,
  TagCheckChangedEvent,
  TagDroppedEvent
} from '../../aureliaComponents/categorized-tag-list/categorized-tag-list';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import {
  Tag,
  TagCreationEntity
} from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { TagHelper } from 'common/EntityHelper/TagHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';

@autoinject()
export class SelectCategoryTagsDialog {
  protected dialog: RecordItDialog | null = null;

  protected options: SelectCategoryTagsDialogOptions | null = null;

  protected tagCategories: Array<TagCategory> = [];

  protected tags: Array<Tag> = [];

  private subscriptionManager: SubscriptionManager;

  constructor(
    subManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly i18n: I18N
  ) {
    this.subscriptionManager = subManagerService.create();
  }

  public static async open(
    options: SelectCategoryTagsDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: SelectCategoryTagsDialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open SelectCategoryTagsDialog without dialog'
    );
    this.options = options;

    this.dialog.open();

    this.updateTags();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateTags.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.tagCategoryConfiguration',
        (tagCategoryConfiguration) => {
          this.tagCategories = tagCategoryConfiguration ?? [];
        }
      )
    );
  }

  private createTag(name: string, category: TagCategory): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot create a new tag without options'
    );

    if (this.checkExistingTagAndNotifyUser(name, this.options)) return;

    let tag: TagCreationEntity;
    switch (this.options.type) {
      case 'project':
        tag = {
          name: name,
          category: category.name,
          project: this.options.projectId,
          ownerProjectId: this.options.projectId,
          ownerUserGroupId: this.options.ownerUserGroupId
        };
        break;
      case 'thing':
        tag = {
          name: name,
          category: category.name,
          thing: this.options.thingId,
          ownerUserGroupId: this.options.ownerUserGroupId
        };
        break;
      default:
        throw new Error(`type ${(this.options as any).type} not known.`);
    }

    this.entityManager.tagRepository.create(tag);
  }

  private checkExistingTagAndNotifyUser(
    newTagName: string,
    options: SelectCategoryTagsDialogOptions
  ): boolean {
    let tags: Array<Tag>;
    switch (options.type) {
      case 'project':
        tags = this.entityManager.tagRepository.getByProjectId(
          options.projectId
        );
        break;
      case 'thing':
        tags = this.entityManager.tagRepository.getByThingId(options.thingId);
        break;
      default:
        throw new Error(`type ${(this.options as any).type} not known.`);
    }

    const alreadyExistingTag = tags.find(
      (t) => TagHelper.getTagName(t.name) === TagHelper.getTagName(newTagName)
    );
    if (alreadyExistingTag) {
      const categoryName =
        alreadyExistingTag.category ??
        this.i18n.tr('dialogs.selectCategoryTagsDialog.emptyCategory');
      NotificationHelper.notifyNeutral(
        this.i18n.tr('dialogs.selectCategoryTagsDialog.tagsAlreadyExists', {
          categoryName
        })
      );
    }

    return !!alreadyExistingTag;
  }

  private deleteTag(tag: Tag): void {
    this.setTag(tag, false);
    this.entityManager.tagRepository.delete(tag);
  }

  private setTag(tag: Tag, checked: boolean): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot check/uncheck tag without options'
    );

    if (this.options.picture) {
      if (checked) {
        this.options.picture.tagIds.push(tag.id);
      } else {
        this.options.picture.tagIds = this.options.picture.tagIds.filter(
          (t) => t !== tag.id
        );
      }
      this.entityManager.pictureRepository.update(this.options.picture);
    }
    if (this.options.onTagCheckChanged) {
      this.options.onTagCheckChanged(tag, checked);
    }
  }

  protected updateTags(): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot update tags without options'
    );

    switch (this.options.type) {
      case 'project':
        this.tags = this.entityManager.tagRepository.getByProjectId(
          this.options.projectId
        );
        break;
      case 'thing':
        this.tags = this.entityManager.tagRepository.getByThingId(
          this.options.thingId
        );
        break;
      default:
        throw new Error(`type ${(this.options as any).type} not known.`);
    }
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleCreateTagClicked(
    category: TagCategory,
    event: CreateTagClickedEvent
  ): void {
    this.createTag(event.detail.name, category);
  }

  protected handleDeleteTagClicked(event: DeleteTagClickedEvent): void {
    this.deleteTag(event.detail.tag);
  }

  protected handleTagCheckChanged(event: TagCheckChangedEvent): void {
    this.setTag(event.detail.tag, event.detail.checked);
  }

  protected handleTagDropped(
    event: TagDroppedEvent,
    tagCategory: TagCategory
  ): void {
    const tag = event.detail.tag;
    tag.category = tagCategory.name;
    this.entityManager.tagRepository.update(tag);
  }

  /**
   * If some tags do not have a category, an additional TagCategory for these tags will be displayed.
   */
  @computedFrom('tags', 'tagCategories')
  protected get displayedTagsCategories(): Array<TagCategory> {
    const hasNoCategories = this.tagCategories.length === 0;
    const hasTagsWithoutCategories = this.tags.some((t) => !t.category);
    let tagCategories = this.tagCategories;
    if (hasNoCategories || hasTagsWithoutCategories) {
      tagCategories = [
        {
          name: '',
          displayName: ''
        },
        ...tagCategories
      ];
    }
    return tagCategories;
  }

  /**
   * Returns a map of tags, so that the categorized tag lists can use them better.
   *
   * @example
   * ```js
   *  {
   *    "help": [
   *      { name: "test", category: "help", ... },
   *      ...
   *    ],
   *    ...
   *  }
   * ```
   */
  @computedFrom('tags', 'tagCategories')
  protected get tagsGroupedByCategory(): GroupedCategoryTags {
    const mappedTags: GroupedCategoryTags = {};
    mappedTags[''] = this.tags.filter((t) => !t.category);
    for (const category of this.tagCategories) {
      mappedTags[category.name] = this.tags.filter((t) => {
        if (t.category) {
          return t.category === category.name;
        } else return false;
      });
    }
    return mappedTags;
  }

  @computedFrom('options.picture')
  protected get checkedTagIds(): Array<string> {
    if (!this.options) return [];

    const tagIds: Set<string> = new Set();
    if (this.options.picture) {
      this.options.picture.tagIds.forEach((i) => tagIds.add(i));
    }
    if (this.options.getTagIds) {
      this.options.getTagIds().forEach((i) => tagIds.add(i));
    }
    return Array.from(tagIds);
  }
}

export type SelectCategoryTagsDialogOptions = (
  | ThingDialogOptions
  | ProjectDialogOptions
) & {
  ownerUserGroupId: string;
  /**
   * If defined, whenever a tag is unchecked/checked,
   * the change will be reflected in the tagIds of the picture.
   */
  picture?: Picture;
  /**
   * If defined, the dialog will forward these tags as checked to the tag lists.
   *
   * Is a callback because the tagids need to be retrieved again when the view is updated externally, e.g. if a new tag is created.
   */
  getTagIds?: () => Array<string>;
  /**
   * If defined, whenever a tag is unchecked/checked,
   * this callback is called with the tag that changed
   * and its current value.
   */
  onTagCheckChanged?: (tag: Tag, value: boolean) => void;
};

export type ThingDialogOptions = {
  type: 'thing';
  thingId: string;
};

export type ProjectDialogOptions = {
  type: 'project';
  projectId: string;
};

export type TagCategory = {
  name: string;
  displayName: string;
};

type GroupedCategoryTags = {
  [name: string]: Array<Tag>;
};
