import { PathUtils } from 'common/Utils/PathUtils/PathUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { PictureFileNameUtils } from '../EntityManager/entities/PictureFile/PictureFileNameUtils';
import { PictureFile } from '../EntityManager/entities/PictureFile/types';
import { UrlManager } from '../UrlManager';
import { Disposable } from '../Utils/DisposableContainer';
import { CustomDirectoryEntry } from '../Utils/FileUtils/entries/CustomDirectoryEntry';
import { FileUtils } from '../Utils/FileUtils/FileUtils';
import { Utils } from '../Utils/Utils';

/**
 * A manager which manages the files of the pictures of an entity.
 * This manager needs a specific folder structure in the persistent storage: $baseEntityFolderPath/$entityId
 * $baseEntityFolderPath: can be any path/name relative to the persistent storage, but normally this will be decided by the mainEntityInfo of the picture (since the PictureFileLocalFilesService/PictureFileAutoUploadService will manage the files based on that)
 * $entityId: a subfolder which exists per entity
 * All picture files must be directly in the $entityId folder
 */
export class EntityPictureFilesManager {
  private readonly absoluteBaseEntityFolderPath: string;
  private readonly getEntityIdFromPictureFile: GetEntityIdFromPictureFile;
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  constructor(options: {
    getEntityIdFromPictureFile: GetEntityIdFromPictureFile;
    baseEntityFolderPath: string;
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
  }) {
    this.absoluteBaseEntityFolderPath = PathUtils.joinPaths(
      UrlManager.localFolder,
      options.baseEntityFolderPath
    );
    this.getEntityIdFromPictureFile = options.getEntityIdFromPictureFile;
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.entityManager.pictureFileRepository.registerHooks({
        afterEntityDeleted: (pictureFile) => {
          void this.removeFileForPictureFile({
            pictureFile,
            getBasePath: ({ entityId }) => {
              return PathUtils.joinPaths(
                this.absoluteBaseEntityFolderPath,
                entityId
              );
            }
          });
        },
        afterEntityRemovedLocally: (pictureFile) => {
          if (pictureFile.onlyLocal) {
            void this.removeFileForPictureFile({
              pictureFile,
              getBasePath: () => {
                return PathUtils.joinPaths(
                  UrlManager.localFolder,
                  'captured_pictures'
                );
              }
            });
          } else {
            void this.removeFileForPictureFile({
              pictureFile,
              getBasePath: ({ entityId }) => {
                return PathUtils.joinPaths(
                  this.absoluteBaseEntityFolderPath,
                  entityId
                );
              }
            });
          }
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public async cleanup(): Promise<void> {
    const directories = await this.tryGettingEntityDirectories();
    for (const directory of directories) {
      await this.cleanupEntityDirectory(directory);
    }
  }

  private async tryGettingEntityDirectories(): Promise<
    Array<CustomDirectoryEntry>
  > {
    try {
      return await FileUtils.getDirectoriesShallow(
        this.absoluteBaseEntityFolderPath
      );
    } catch (e) {
      if (e instanceof FileError && e.code === FileError.NOT_FOUND_ERR) {
        return []; // since there is no project folder we act like it's empty
      }

      throw e;
    }
  }

  private async cleanupEntityDirectory(
    directory: CustomDirectoryEntry
  ): Promise<void> {
    const files = await directory.getFilesShallow();

    for (const file of files) {
      const pictureFileId = Utils.extractIdFromPictureFileName(file.name);
      if (!pictureFileId) {
        continue;
      }

      if (!this.entityManager.pictureFileRepository.getById(pictureFileId)) {
        await file.delete();
      }
    }
  }

  private async removeFileForPictureFile({
    pictureFile,
    getBasePath
  }: {
    pictureFile: PictureFile;
    getBasePath: (options: { entityId: string }) => string;
  }): Promise<void> {
    const entityId = this.getEntityIdFromPictureFile(pictureFile);
    if (!entityId || !pictureFile.file_created) {
      return;
    }

    const fileName = PictureFileNameUtils.getPictureFileName({
      id: pictureFile.id,
      file_created: pictureFile.file_created,
      file_extension: pictureFile.file_extension
    });

    const filePath = PathUtils.joinPaths(getBasePath({ entityId }), fileName);
    console.error(filePath);

    if (await FileUtils.fileExists(filePath)) {
      await FileUtils.deleteEntry(filePath);
    }
  }
}

type GetEntityIdFromPictureFile = (pictureFile: PictureFile) => string | null;
