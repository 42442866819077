import { bindable } from 'aurelia-framework';

/**
 * @attribute data-background - supported values 'white'
 * @attribute data-fill - scales the preloader indicator to fill the whole space of the circle-preloader
 */
export class CirclePreloader {
  @bindable forceRunning = false;

  _loadingCount = 0;

  /**
   * you have to call stop for every start call
   */
  start() {
    this._loadingCount++;
  }

  stop() {
    if (this._loadingCount > 0) this._loadingCount--;
  }

  /**
   * call reset instead of stop if you don't want the counting functionality
   */
  reset() {
    this._loadingCount = 0;
  }

  isLoading() {
    return this._loadingCount == 0;
  }
}
