export class DataUrlHelper {
  public static getBase64FromDataUrl(dataUrl: string): string {
    return dataUrl.replace(/^data:(.+)?;base64,/, '');
  }

  public static getUint8ArrayFromDataUrl(dataUrl: string): Uint8Array {
    return Uint8Array.from(
      window.atob(this.getBase64FromDataUrl(dataUrl)),
      (c) => c.charCodeAt(0)
    );
  }

  /**
   * returns the size of the dataUrl content/data after decoding it into the binary data
   */
  public static getDataUrlContentSize(dataUrl: string): number {
    const prefix = /^data:(.+)?;base64,/.exec(dataUrl);
    const prefixLength = prefix && prefix[0] ? prefix[0].length : 0;
    const contentLength = dataUrl.length - prefixLength;

    // a base64 dataUrl has 4/3 the size of the original data
    return contentLength * 0.75;
  }
}
