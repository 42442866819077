import { bindable } from 'aurelia-framework';

import { UiUpdater } from '../../classes/UiUpdater';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

export class LoadingOverlay {
  @bindable visible = false;

  _containerNode;
  _circlePreloaderNode;

  constructor() {
    this._boundUpdatePosition = this._updatePosition.bind(this);
  }

  attached() {
    this._attached = true;

    this._updateUpdatePositionSubscription();
  }

  detached() {
    this._attached = false;

    this._updateUpdatePositionSubscription();
  }

  // aurelia change handler
  visibleChanged() {
    this._updateUpdatePositionSubscription();
  }

  _updateUpdatePositionSubscription() {
    if (this.visible && this._attached) {
      UiUpdater.registerUpdateFunction(this._boundUpdatePosition);
    } else {
      UiUpdater.unregisterUpdateFunction(this._boundUpdatePosition);
    }
  }

  _updatePosition() {
    const screenHeight = window.screen.height;
    const circlePreloaderRect =
      this._circlePreloaderNode.getBoundingClientRect();
    const containerRect = this._containerNode.getBoundingClientRect();
    let top;

    if (DeviceInfoHelper.isMobile()) {
      top =
        screenHeight / 2 - circlePreloaderRect.height / 2 - containerRect.top;
    } else {
      let y_overlap = Math.max(
        0,
        Math.min(screenHeight, containerRect.bottom) -
          Math.max(0, containerRect.top)
      );
      top = y_overlap / 2 - circlePreloaderRect.height / 2;
      if (containerRect.top < 0) {
        top -= containerRect.top;
      }
    }

    top = top + 'px';

    this._circlePreloaderNode.style.top = top;
  }
}
