import { bindable, autoinject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { UploadChoiceSelectedEvent } from '../../picture/picture-upload-area/picture-upload-area';
import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

/**
 * @event picture-clicked - detail: picture
 * @event upload-choice-selected
 */
@autoinject()
export class GalleryThingPictureGroupPictures {
  @bindable
  public pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> = [];

  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  @bindable public pictureSelectionEnabled = false;

  @bindable public useUltraRapidFireWidget = false;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handlePictureClick(picture: Picture): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'picture-clicked',
      detail: picture,
      bubbles: true
    });
  }

  /**
   * Accepts update dependencies as params that control when the HTML gets updated
   */
  protected pictureIsSelected(
    overviewEntry: GalleryThingPictureOverviewEntry,
    ..._deps: Array<any>
  ): boolean {
    return (
      this.selectedPictureOverviewEntries.findIndex(
        (p) => p.pictureId === overviewEntry.pictureId
      ) >= 0
    );
  }

  protected handleUploadChoiceSelected(event: UploadChoiceSelectedEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'upload-choice-selected',
      detail: event.detail
    });
  }
}
