import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { User } from '../User/types';
import { UserGroup } from './types';

export class UserGroupRepository extends AppEntityRepository<EntityName.UserGroup> {
  public getUserGroupsOfUser(user: User): Array<UserGroup> {
    if (user.admin) {
      return this.getAll();
    }

    return this.getAll().filter((userGroup) => {
      return (
        userGroup.userSpecs.findIndex((userSpec) => {
          return userSpec._id === user.id;
        }) > -1
      );
    });
  }

  public getEditableGroupsForUser(user: User): Array<UserGroup> {
    if (user.admin) {
      return this.getAll();
    }

    return this.getAll().filter((userGroup) => {
      return (
        userGroup.userSpecs.findIndex((userSpec) => {
          return (
            userSpec._id === user.id &&
            (userSpec.can_edit || userSpec.group_admin)
          );
        }) > -1
      );
    });
  }

  public getGroupsUserIsGroupAdminIn(user: User): Array<UserGroup> {
    if (user.admin) {
      return this.getAll();
    }

    return this.getAll().filter((userGroup) => {
      return (
        userGroup.userSpecs.findIndex((userSpec) => {
          return userSpec._id === user.id && userSpec.group_admin;
        }) > -1
      );
    });
  }
}
