import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskChecklistEntry } from './types';

export class ProcessTaskChecklistEntryRepository extends AppEntityRepository<EntityName.ProcessTaskChecklistEntry> {
  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskChecklistEntry> {
    return this.getAll().filter((e) => e.ownerProcessTaskId === processTaskId);
  }

  public getByOwnerProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskChecklistEntry> {
    return this.getAll().filter(
      (e) => e.ownerProcessTaskGroupId === processTaskGroupId
    );
  }
}
