import _ from 'lodash';
import { autoinject } from 'aurelia-framework';

import {
  RapidFireModeConfiguration,
  RapidFireModeConfigurationAdditionalButtonType
} from 'common/Types/RapidFireModeConfiguration';

import {
  EmbeddedCamera,
  EmbeddedCameraAdditionalButton
} from '../../aureliaComponents/embedded-camera/embedded-camera';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { Dialogs } from '../../classes/Dialogs';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { CirclePreloader } from '../../aureliaComponents/circle-preloader/circle-preloader';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ThingSectionAssessmentDialog } from '../../thingSectionComponents/thing-section-assessment-dialog/thing-section-assessment-dialog';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { EntryCreationHandle } from './EntryCreationHandle/EntryCreationHandle';
import { PictureCreatorService } from '../../classes/Picture/PictureCreatorService';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { DisplayedEntrySelect } from './EntryCreationHandle/DisplayedEntrySelect';
import { SelectEntryPathHandle } from './structure-thing-rapid-fire-widget-select-entry-path-content/SelectEntryPathHandle';
import { StructureThingRapidFireWidgetSelectEntryPathContent } from './structure-thing-rapid-fire-widget-select-entry-path-content/structure-thing-rapid-fire-widget-select-entry-path-content';

/**
 * this is meant to be a single global instance
 */
@autoinject()
export class StructureThingRapidFireWidget {
  private static instance: StructureThingRapidFireWidget | null = null;
  private static lastOptionsStorageKey =
    'StructureThingRapidFireWidget.lastOptions';
  private static savedEntryIdsStorageKey =
    'StructureThingRapidFireWidget.savedEntryIdsStorageKey';

  private dialog: RecordItDialog | null = null;

  private circlePreloader: CirclePreloader | null = null;

  private entryCreationHandle: EntryCreationHandle | null = null;
  private options: StructureThingRapidFireWidgetOptions | null = null;
  private selectEntryPathHandle: SelectEntryPathHandle | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected selectEntryPathContent: StructureThingRapidFireWidgetSelectEntryPathContent | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly currentUserService: CurrentUserService,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly applyPropertiesService: ApplyPropertiesService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public static start(options: StructureThingRapidFireWidgetOptions): void {
    assertNotNullOrUndefined(this.instance, 'no instance is registered');
    this.instance.start(options);
  }

  protected attached(): void {
    if (StructureThingRapidFireWidget.instance) {
      console.error('an instance was already attached, replacing it...');
    }
    StructureThingRapidFireWidget.instance = this;

    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:data-url-picture-available',
      () => {
        void this.handleDataUrlPictureAvailable();
      }
    );
    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:camera-closed',
      this.handleCameraClosed.bind(this)
    );
    if (EmbeddedCamera.hasLastDataUrlPicture()) {
      void this.handleDataUrlPictureAvailable();
    }
  }

  protected detached(): void {
    StructureThingRapidFireWidget.instance = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  public start(options: StructureThingRapidFireWidgetOptions): void {
    this.reset();
    this.options = _.cloneDeep(options);
    void DataStorageHelper.setItem(
      StructureThingRapidFireWidget.lastOptionsStorageKey,
      options
    );
    this.openCamera();
  }

  private async handleDataUrlPictureAvailable(): Promise<void> {
    const options: StructureThingRapidFireWidgetOptions | null =
      await DataStorageHelper.getItem(
        StructureThingRapidFireWidget.lastOptionsStorageKey
      );
    if (!options) {
      return; // not our event
    }

    const uri = EmbeddedCamera.getLastDataUriPictureOnce();
    if (!uri) {
      void Dialogs.errorDialog(
        'Fehler',
        'Bei dem Erstellen des Bildes ist ein Fehler aufgetreten.'
      );
      this.deleteLastOptions();
    } else {
      await this.startCreatingEntry({
        options,
        originalDataUrl: uri
      });
    }
  }

  private async startCreatingEntry({
    options,
    originalDataUrl
  }: {
    options: StructureThingRapidFireWidgetOptions;
    originalDataUrl: string | null;
  }): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      "can't StructureThingRapidFireWidget.startCreatingEntry without a dialog"
    );
    this.reset();
    this.options = options;

    this.entryCreationHandle = await EntryCreationHandle.createHandle({
      project: this.entityManager.projectRepository.getRequiredById(
        this.options.projectId
      ),
      structureTemplate: options.structureTemplate,
      applyPropertiesService: this.applyPropertiesService,
      entityManager: this.entityManager,
      originalDataUrl,
      pictureCreatorService: this.pictureCreatorService,
      subscriptionManagerService: this.subscriptionManagerService,
      currentUserService: this.currentUserService,
      savePictureFileDataUrlService: this.savePictureFileDataUrlService,
      loadEntryPath: () => this.loadPreservedEntryPath(),
      saveEntryPath: (entryPath) => this.preserveEntryPath(entryPath),
      selectEntryPath: ({ selectEntryPathHandle }) => {
        this.selectEntryPathHandle = selectEntryPathHandle;
      }
    });

    this.dialog.open();
  }

  private handleCameraClosed(): void {
    void DataStorageHelper.removeItem(
      StructureThingRapidFireWidget.savedEntryIdsStorageKey
    );
    this.deleteLastOptions();
    this.reset();
  }

  private reset(): void {
    this.resetAndPreserveOptions();
    this.options = null;
  }

  private resetAndPreserveOptions(): void {
    this.entryCreationHandle?.dispose();
    this.entryCreationHandle = null;
    this.selectEntryPathHandle = null;
  }

  protected handleAcceptButtonClicked(): void {
    if (this.selectEntryPathHandle) {
      this.acceptSelectedPath();
    } else {
      this.createEntitiesAndCaptureNewPicture();
    }
  }

  protected async handleDeclineButtonClicked(): Promise<void> {
    if (this.selectEntryPathHandle) {
      this.selectEntryPathHandle = null;
    } else {
      this.captureNewPicture();
    }
  }

  private acceptSelectedPath(): void {
    assertNotNullOrUndefined(
      this.selectEntryPathContent,
      "can't StructureThingRapidFireWidget.acceptSelectedPath without selectEntryPathContent"
    );
    this.selectEntryPathContent.acceptButtonClicked();
    this.selectEntryPathHandle = null;
  }

  private createEntitiesAndCaptureNewPicture(): void {
    assertNotNullOrUndefined(
      this.options,
      "can't StructureThingRapidFireWidget.handleAcceptButtonClicked without options"
    );
    assertNotNullOrUndefined(
      this.entryCreationHandle,
      "can't StructureThingRapidFireWidget.handleAcceptButtonClicked without entryCreationHandle"
    );

    this.entryCreationHandle.createEntities();

    this.circlePreloader && this.circlePreloader.start();
    setTimeout(() => {
      this.circlePreloader && this.circlePreloader.stop();
      this.captureNewPicture();
    }, 700); // fake loading for the user to feel better
  }

  private captureNewPicture(): void {
    this.resetAndPreserveOptions();
    this.dialog?.close();
    this.openCamera();
  }

  private openCamera(): void {
    void EmbeddedCamera.capturePicture({
      buttons: this.getEmbeddedCameraButtons()
    });
  }

  private getEmbeddedCameraButtons(): Array<EmbeddedCameraAdditionalButton> {
    const rapidFireModeConfigurationJson: RapidFireModeConfiguration | null =
      this.activeUserCompanySettingService.getJSONSettingProperty(
        'structureProject.rapidFireModeConfigurationJson'
      );
    const additionalCaptureButtons =
      rapidFireModeConfigurationJson?.additionalCaptureButtons ?? [];

    const clickHandlerForType: Record<
      RapidFireModeConfigurationAdditionalButtonType,
      () => void
    > = {
      [RapidFireModeConfigurationAdditionalButtonType.THING_SECTION_ASSESSMENT]:
        () => {
          assertNotNullOrUndefined(
            this.options,
            "can't open the ThingSectionAssessmentDialog without options"
          );
          const project = this.entityManager.projectRepository.getRequiredById(
            this.options.projectId
          );

          void ThingSectionAssessmentDialog.open({
            project,
            thing: this.entityManager.thingRepository.getRequiredById(
              project.thing
            )
          });
        },
      [RapidFireModeConfigurationAdditionalButtonType.CREATE_ENTRY_WITHOUT_PICTURE]:
        () => {
          assertNotNullOrUndefined(
            this.options,
            "can't open the ThingSectionAssessmentDialog without options"
          );
          void this.startCreatingEntry({
            options: this.options,
            originalDataUrl: null
          });
        }
    };

    return additionalCaptureButtons.map<EmbeddedCameraAdditionalButton>(
      (configuration) => {
        return {
          iconName: configuration.iconName,
          iconType: configuration.iconType,
          onClick: clickHandlerForType[configuration.type]
        };
      }
    );
  }

  private deleteLastOptions(): void {
    void DataStorageHelper.removeItem(
      StructureThingRapidFireWidget.lastOptionsStorageKey
    );
  }

  private async loadPreservedEntryPath(): Promise<Array<Entry>> {
    const savedEntryIds: Array<string> | undefined =
      await DataStorageHelper.getItem(
        StructureThingRapidFireWidget.savedEntryIdsStorageKey
      );
    if (!savedEntryIds) {
      return [];
    }

    const entryIds = savedEntryIds.map((entryId) => {
      const originalEntry =
        this.entityManager.entryRepository.getByOriginalId(entryId);
      return originalEntry ? originalEntry.id : entryId;
    });

    const entryPath = [];
    for (const entryId of entryIds) {
      const entry = this.entityManager.entryRepository.getById(entryId);
      if (entry) {
        entryPath.push(entry);
      } else {
        break;
      }
    }

    return entryPath;
  }

  private async preserveEntryPath(entryPath: Array<Entry>): Promise<void> {
    const entryIds = entryPath.map((e) => e.id);
    await DataStorageHelper.setItem(
      StructureThingRapidFireWidget.savedEntryIdsStorageKey,
      entryIds
    );
  }

  protected acceptButtonIsDisabled(
    displayedEntrySelects: Array<DisplayedEntrySelect> | null,
    selectEntryPathHandle: SelectEntryPathHandle
  ): boolean {
    if (selectEntryPathHandle) {
      return false;
    }

    if (!displayedEntrySelects) {
      return true;
    }

    return !displayedEntrySelects.every(
      (displayedEntrySelect) => !!displayedEntrySelect.selectedEntry
    );
  }
}

type StructureThingRapidFireWidgetOptions = {
  projectId: string;
  structureTemplate: StructureTemplate;
};
