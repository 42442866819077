import { PaginationInfo } from 'common/EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import {
  GalleryThingPictureOverviewEntryGroup,
  OnBaseMapMarkerClicked
} from '../../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { BaseMapDataCallbacks } from '../GalleryThingPictureDataSource';

export abstract class GalleryThingPictureDataSourceStrategy {
  protected readonly entityManager: AppEntityManager;
  protected options: GetGalleryThingPicturesOptions | null = null;

  protected readonly onMarkersChanged: () => void;
  protected readonly onMarkerClicked: OnBaseMapMarkerClicked;

  constructor(options: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    baseMapDataCallbacks: BaseMapDataCallbacks;
  }) {
    this.entityManager = options.entityManager;
    this.onMarkersChanged = options.baseMapDataCallbacks.onMarkersChanged;
    this.onMarkerClicked = options.baseMapDataCallbacks.onMarkerClicked;
  }

  public abstract subscribe(options: SubscribeOptions): Disposable;

  public abstract updatePictures(): Promise<void>;

  public setGalleryThingPicturesOptions(
    options: GetGalleryThingPicturesOptions
  ): void {
    this.options = options;
  }

  public abstract setFilter(
    filter: GalleryThingPictureFilter,
    paginationInfo: PaginationInfo
  ): void;

  public abstract bindFilteredPictureGroups(
    callback: (
      filteredPictureGroups: Array<GalleryThingPictureOverviewEntryGroup>
    ) => void
  ): Disposable;
}

export type GetGalleryThingPicturesOptions = {
  projects: Array<Project>;
  includeDefects: boolean;
  thing: Thing;
};

export type SubscribeOptions = {
  onDataChanged: () => void;
};
