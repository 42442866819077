import { computedFrom } from 'aurelia-binding';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import {
  EntryPicture,
  PictureAdditionalMarking
} from '../../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { ApplyPropertiesService } from '../../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { GroupedProperties } from '../../../classes/EntityManager/entities/Property/GroupedPropertyHelper';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { StructureTemplate } from '../../../classes/EntityManager/entities/StructureTemplate/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { PictureCreatorService } from '../../../classes/Picture/PictureCreatorService';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { DisplayedEntrySelect } from './DisplayedEntrySelect';
import { EntryPathHandle } from './EntryPathHandle';
import { EntryPropertiesHandle } from './EntryPropertiesHandle';
import {
  SelectEntryPath,
  StructurePictureAreaMarkingsHandler
} from './StructurePictureAreaMarkingsHandler/StructurePictureAreaMarkingsHandler';
import { TemporaryBaseEntities } from './TemporaryBaseEntities';

export class EntryCreationHandle implements Disposable {
  public static async createHandle(
    options: EntryCreationHandleCreationOptions
  ): Promise<EntryCreationHandle> {
    const preservedEntryPath = await options.loadEntryPath();
    return new EntryCreationHandle({
      ...options,
      preservedEntryPath
    });
  }

  private readonly internalProject: Project;
  private readonly internalStructureTemplate: StructureTemplate;
  private readonly structurePictureAreaMarkingsHandler: StructurePictureAreaMarkingsHandler;
  private readonly temporaryBaseEntities: TemporaryBaseEntities;
  private readonly internalPictureHandle: PictureHandle | null;
  private readonly entryPropertiesHandle: EntryPropertiesHandle;
  private readonly entryPathHandle: EntryPathHandle;
  private readonly saveEntryPath: SaveEntryPath;

  private internalOriginalDataUrl: string | null;
  private internalSketchDataUrl: string | null = null;
  private internalEditedDataUrl: string | null = null;

  private constructor(options: EntryCreationHandleOptions) {
    this.structurePictureAreaMarkingsHandler =
      new StructurePictureAreaMarkingsHandler({
        entityManager: options.entityManager,
        entryPath: options.preservedEntryPath,
        project: options.project,
        onEntryPathSelected: ({ entryPath }) => {
          this.entryPathHandle.setEntryPath(entryPath);
        },
        selectEntryPath: options.selectEntryPath
      });

    this.temporaryBaseEntities = new TemporaryBaseEntities({
      project: options.project,
      entityManager: options.entityManager,
      pictureCreatorService: options.pictureCreatorService,
      currentUserService: options.currentUserService,
      savePictureFileDataUrlService: options.savePictureFileDataUrlService,
      hasPicture: !!options.originalDataUrl
    });

    if (this.temporaryBaseEntities.picture) {
      this.internalPictureHandle = new PictureHandle({
        picture: this.temporaryBaseEntities.picture,
        structurePictureAreaMarkingsHandler:
          this.structurePictureAreaMarkingsHandler
      });
    } else {
      this.internalPictureHandle = null;
    }

    this.entryPropertiesHandle = new EntryPropertiesHandle({
      entry: this.temporaryBaseEntities.entry,
      project: options.project,
      structureTemplate: options.structureTemplate,
      entryPath: options.preservedEntryPath,
      entityManager: options.entityManager,
      applyPropertiesService: options.applyPropertiesService,
      subscriptionManagerService: options.subscriptionManagerService
    });

    this.entryPathHandle = new EntryPathHandle({
      project: options.project,
      preservedEntryPath: options.preservedEntryPath,
      entityManager: options.entityManager,
      subscriptionManagerService: options.subscriptionManagerService,
      onNewEntryPath: ({ entryPath }) => {
        this.entryPropertiesHandle.setEntryPath(entryPath);
        this.structurePictureAreaMarkingsHandler.setEntryPath(entryPath);
      }
    });

    this.internalProject = options.project;
    this.internalStructureTemplate = options.structureTemplate;
    this.internalOriginalDataUrl = options.originalDataUrl;
    this.saveEntryPath = options.saveEntryPath;
  }

  public dispose(): void {
    this.temporaryBaseEntities.dispose();
    this.entryPropertiesHandle.dispose();
    this.entryPathHandle.dispose();
  }

  @computedFrom('internalOriginalDataUrl')
  public get originalDataUrl(): string | null {
    return this.internalOriginalDataUrl;
  }

  @computedFrom('internalSketchDataUrl')
  public get sketchDataUrl(): string | null {
    return this.internalSketchDataUrl;
  }

  public set sketchDataUrl(sketchDataUrl: string | null) {
    this.internalSketchDataUrl = sketchDataUrl;
  }

  @computedFrom('internalEditedDataUrl')
  public get editedDataUrl(): string | null {
    return this.internalEditedDataUrl;
  }

  public set editedDataUrl(editedDataUrl: string | null) {
    this.internalEditedDataUrl = editedDataUrl;
  }

  @computedFrom('temporaryBaseEntities.entry')
  public get entry(): Entry {
    return this.temporaryBaseEntities.entry;
  }

  @computedFrom('internalProject')
  public get project(): Project {
    return this.internalProject;
  }

  @computedFrom('internalStructureTemplate')
  public get structureTemplate(): StructureTemplate {
    return this.internalStructureTemplate;
  }

  @computedFrom('entryPathHandle.displayedEntrySelects')
  public get displayedEntrySelects(): Array<DisplayedEntrySelect> {
    return this.entryPathHandle.displayedEntrySelects;
  }

  @computedFrom('entryPathHandle.preserveEntryPath')
  public get preserveEntryPath(): boolean {
    return this.entryPathHandle.preserveEntryPath;
  }

  public set preserveEntryPath(preserveEntryPath: boolean) {
    this.entryPathHandle.preserveEntryPath = preserveEntryPath;
  }

  @computedFrom('entryPath')
  public get entryPath(): Array<Entry> {
    return this.entryPathHandle.entryPath;
  }

  public setEntryPath(entryPath: Array<Entry>): void {
    this.entryPathHandle.setEntryPath(entryPath);
  }

  @computedFrom('entryPathHandle.entryNamePath')
  public get entryNamePath(): Array<string> {
    return this.entryPathHandle.entryNamePath;
  }

  @computedFrom('entryPropertiesHandle.groupProperties')
  public get groupedProperties(): GroupedProperties<Property> {
    return this.entryPropertiesHandle.groupedProperties;
  }

  @computedFrom('internalPictureHandle')
  public get pictureHandle(): PictureHandle | null {
    return this.internalPictureHandle;
  }

  public createEntities(): void {
    this.temporaryBaseEntities.createEntities({
      originalDataUrl: this.internalOriginalDataUrl,
      sketchDataUrl: this.internalSketchDataUrl,
      editedDataUrl: this.internalEditedDataUrl,
      entryPath: this.entryPath
    });

    if (this.preserveEntryPath) {
      void this.saveEntryPath(this.entryPathHandle.entryPath);
    } else {
      void this.saveEntryPath([]);
    }
  }
}

export class PictureHandle {
  private readonly picture: EntryPicture;
  private readonly structurePictureAreaMarkingsHandler: StructurePictureAreaMarkingsHandler;

  constructor({
    picture,
    structurePictureAreaMarkingsHandler
  }: {
    picture: EntryPicture;
    structurePictureAreaMarkingsHandler: StructurePictureAreaMarkingsHandler;
  }) {
    this.picture = picture;
    this.structurePictureAreaMarkingsHandler =
      structurePictureAreaMarkingsHandler;
  }

  @computedFrom('temporaryBaseEntities.picture.additional_markings')
  public get additionalMarkings(): Array<PictureAdditionalMarking> {
    return this.picture.additional_markings;
  }

  /**
   * This is not a setter to prevent this from being used as a two-way binding.
   * Since the additionalMarkings array will be preserved in the additional-marked-pictures-list, we would miss out on updates.
   * The solution is to call this function in the changed handler
   */
  public setAdditionalMarkings(
    additionalMarkings: Array<PictureAdditionalMarking>
  ): void {
    this.picture.additional_markings = additionalMarkings;
    this.structurePictureAreaMarkingsHandler.setAdditionalMarkings(
      additionalMarkings
    );
  }

  @computedFrom('temporaryBaseEntities.picture.description')
  public get description(): string | null {
    return this.picture.description;
  }

  public set description(pictureDescription: string | null) {
    this.picture.description = pictureDescription;
  }
}

export type EntryCreationHandleCreationOptions = Omit<
  EntryCreationHandleOptions,
  'preservedEntryPath'
> & {
  loadEntryPath: () => Promise<Array<Entry>>;
};

export type EntryCreationHandleOptions = {
  originalDataUrl: string | null;
  project: Project;
  structureTemplate: StructureTemplate;
  preservedEntryPath: Array<Entry>;
  saveEntryPath: SaveEntryPath;
  selectEntryPath: SelectEntryPath;
  subscriptionManagerService: SubscriptionManagerService;
  entityManager: AppEntityManager;
  pictureCreatorService: PictureCreatorService;
  applyPropertiesService: ApplyPropertiesService;
  currentUserService: CurrentUserService;
  savePictureFileDataUrlService: SavePictureFileDataUrlService;
};

type SaveEntryPath = (entryPath: Array<Entry>) => Promise<void>;
