import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationDevice } from './types';

export class ProcessConfigurationDeviceRepository extends AppEntityRepository<EntityName.ProcessConfigurationDevice> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationDevice> {
    return this.getAll().filter(
      (d) => d.ownerProcessConfigurationId === processConfigurationId
    );
  }

  public getByProcessConfigurationIdAndPersonId(
    processConfigurationId: string,
    personId: string | null
  ): Array<ProcessConfigurationDevice> {
    return this.getAll().filter((d) => {
      return (
        d.ownerProcessConfigurationId === processConfigurationId &&
        (d.personId === personId || (d.personId == null && personId == null))
      );
    });
  }
}
