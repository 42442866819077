import { AppEntityManagerEntityTypesByEntityName } from '../../../classes/EntityManager/entities/AppEntityManagerEntityTypesByEntityName';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { EntityNameToPermissionsConfig } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntitySpecificPermissionsHandle } from '../../../services/PermissionsService/EntitySpecificPermissionsHandle/EntitySpecificPermissionsHandle';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

export class PropertyAdapterUtils {
  private constructor() {}

  public static setupCanEditPropertiesSubscription<
    TEntityName extends keyof EntityNameToPermissionsConfig
  >({
    entityName,
    entity,
    permissionsService,
    subscriptionManager,
    setCanUpdateProperties
  }: {
    entityName: TEntityName;
    entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'];
    permissionsService: PermissionsService;
    subscriptionManager: SubscriptionManager;
    setCanUpdateProperties: (canUpdateProperties: boolean) => void;
  }): void {
    const permissionsHandle = permissionsService.getPermissionsHandleForEntity({
      entityName,
      entity: entity as any
    }) as EntitySpecificPermissionsHandle<any>;

    this.assertPermissionsHandleHasCanEditProperties(permissionsHandle);

    subscriptionManager.addDisposable(permissionsHandle.subscribe());

    subscriptionManager.subscribeToExpression(
      permissionsHandle,
      'canEditProperties',
      () => {
        setCanUpdateProperties(permissionsHandle.canEditProperties);
      }
    );
    setCanUpdateProperties(permissionsHandle.canEditProperties);
  }

  private static assertPermissionsHandleHasCanEditProperties(
    permissionsHandle: EntitySpecificPermissionsHandle<any>
  ): asserts permissionsHandle is EntitySpecificPermissionsHandle<any> & {
    get canEditProperties(): boolean;
  } {
    if (!('canEditProperties' in permissionsHandle)) {
      throw new Error(
        "the permissions handle can't be used because it has no canEditProperties implementation"
      );
    }
  }
}
