import { SorterSortOptions } from '../../../../aureliaAttributes/sorter';
import { Question } from './types';
import { SortUtils, NullCompareMode } from 'common/Utils/SortUtils';

export namespace QuestionUtils {
  export function getSortOptions(): QuestionSortOptions {
    return {
      text: {
        name: 'text',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.text,
            b.text,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      createdAt: {
        name: 'createdAt',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.createdAt,
            b.createdAt,
            NullCompareMode.FalsyToBegin
          );
        }
      },
      updatedAt: {
        name: 'updatedAt',
        sortFunction: (a, b) => {
          return SortUtils.localeCompareFalsyStrings(
            a.updatedAt,
            b.updatedAt,
            NullCompareMode.FalsyToBegin
          );
        }
      }
    };
  }
}

export type QuestionSortOptions = SorterSortOptions<
  Question,
  'text' | 'createdAt' | 'updatedAt'
>;
