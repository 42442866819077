import {
  type EndpointsHandler,
  type WebSocketEndpointConfigurations
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { type WebSocketResponse } from 'common/WebSocketEndpoints/WebSocketTypes';
import { Socket } from 'socket.io-client';
import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';

export class SocketCommandEmitter {
  constructor(private readonly io: Socket) {}

  protected emitCommand<
    TModulename extends keyof WebSocketEndpointConfigurations,
    TPath extends
      keyof WebSocketEndpointConfigurations[TModulename]['endpoints'],
    THandler extends EndpointsHandler<TModulename, TPath>['handleRequest']
  >(
    name: TModulename,
    path: TPath,
    data: Parameters<THandler>[0]
  ): Promise<ReturnType<THandler>> {
    return new Promise((resolve, reject) => {
      this.io.emit(
        `${name}/${String(path)}`,
        data,
        (response: WebSocketResponse<ReturnType<THandler>>) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(
              new WebSocketHandlerError({
                type: response.type,
                message: response.message,
                context: response.context ?? undefined
              })
            );
          }
        }
      );
    });
  }
}
