import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntryDeletionService } from '../../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { EditTableEntryDialog } from '../../../dialogs/edit-table-entry-dialog/edit-table-entry-dialog';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { MoreButtonChoice } from '../../more-button/more-button';
import { ColumnConfig } from '../edit-structure-table-widget';

/**
 * @event {AddEntryEvent} add-entry
 */
@autoinject()
export class EditStructureTableWidgetLeftFixedBarEntryRow {
  @bindable()
  public entry: Entry | null = null;

  @bindable()
  public columnConfigs: Array<ColumnConfig> = [];

  @bindable()
  public canAddEntry: boolean = false;

  @bindable()
  public forceDisabled: boolean = false;

  @subscribableLifecycle()
  private readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    permissionsService: PermissionsService
  ) {
    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as EditStructureTableWidgetLeftFixedBarEntryRow,
        propertyName: 'entry'
      });
  }

  protected forceDisabledChanged(): void {
    this.entryPermissionsHandle.overrideAllPermissions(
      this.forceDisabled ? false : null
    );
  }

  protected handleEditEntryClick(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetLeftFixedBarEntryRow.handleEditEntryClick without entry"
    );

    void EditTableEntryDialog.open({
      entry: this.entry,
      columnConfigs: this.columnConfigs
    });
  }

  protected handleChoiceSelected(name: string): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetLeftFixedBarEntryRow.handleChoiceSelected without entry"
    );

    switch (name) {
      case 'newEntryBefore':
        this.sendAddEntryEvent({
          listPosition: this.entry.list_position,
          pageDepthParent: this.entry.page_depth_parent
        });
        break;

      case 'newEntryAfter':
        this.sendAddEntryEvent({
          listPosition: this.entry.list_position + 1,
          pageDepthParent: this.entry.page_depth_parent
        });
        break;

      case 'newSubEntry':
        this.sendAddEntryEvent({
          listPosition: null,
          pageDepthParent: this.entry.id
        });
        break;

      case 'deleteEntry':
        this.deleteEntry();
        break;

      case 'excludeFromExport':
        this.handleEntryChanged();
        break;

      default:
        throw new Error(`unhandled choice "${name}"`);
    }
  }

  protected handleEntryChanged(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetLeftFixedBarEntryRow.handleEntryChanged without entry"
    );

    this.entityManager.entryRepository.update(this.entry);
  }

  private deleteEntry(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't EditStructureTableWidgetLeftFixedBarEntryRow.deleteEntry without entry"
    );

    void this.entryDeletionService.deleteEntryWithDialog(this.entry);
  }

  private sendAddEntryEvent({
    listPosition,
    pageDepthParent
  }: {
    listPosition: number | null;
    pageDepthParent: string | null;
  }): void {
    DomEventHelper.fireEvent<AddEntryEvent>(this.element, {
      name: 'add-entry',
      detail: {
        listPosition,
        pageDepthParent
      }
    });
  }

  @computedFrom('entry')
  protected get moreButtonChoices(): Array<MoreButtonChoice> {
    return [
      {
        labelTk: 'aureliaComponents.editStructureTableWidget.newEntry',
        name: 'newEntryBefore',
        iconClass: 'fal fa-arrow-to-top',
        disabledContext: this,
        disabledPropertyName: 'addingEntryIsDisabled'
      },
      {
        labelTk: 'aureliaComponents.editStructureTableWidget.newEntry',
        name: 'newEntryAfter',
        iconClass: 'fal fa-arrow-to-bottom',
        disabledContext: this,
        disabledPropertyName: 'addingEntryIsDisabled'
      },
      {
        labelTk: 'aureliaComponents.editStructureTableWidget.newSubEntry',
        name: 'newSubEntry',
        iconClass: 'fal fa-arrow-to-right',
        disabledContext: this,
        disabledPropertyName: 'addingEntryIsDisabled'
      },
      {
        labelTk: 'aureliaComponents.editStructureTableWidget.excludeFromExport',
        name: 'excludeFromExport',
        isCheckbox: true,
        propertyName: 'exclude_from_export',
        context: this.entry,
        disabledContext: this,
        disabledPropertyName: 'excludeFromExportIsDisabled'
      },
      {
        labelTk: 'general.delete',
        name: 'deleteEntry',
        iconClass: 'fal fa-trash-alt',
        disabledContext: this,
        disabledPropertyName: 'deleteIsDisabled'
      }
    ];
  }

  @computedFrom('canAddEntry')
  protected get addingEntryIsDisabled(): boolean {
    return !this.canAddEntry;
  }

  @computedFrom('entryPermissionsHandle.canEditField.exclude_from_export')
  protected get excludeFromExportIsDisabled(): boolean {
    return !this.entryPermissionsHandle.canEditField.exclude_from_export;
  }

  @computedFrom('entryPermissionsHandle.canDeleteEntity')
  protected get deleteIsDisabled(): boolean {
    return !this.entryPermissionsHandle.canDeleteEntity;
  }
}

export type AddEntryEvent = NamedCustomEvent<
  'add-entry',
  {
    listPosition: number | null;
    pageDepthParent: string | null;
  }
>;
