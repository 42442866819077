import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { Utils } from '../../classes/Utils/Utils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { EditStructureTemplateEntryDialog } from '../../dialogs/edit-structure-template-entry-dialog/edit-structure-template-entry-dialog';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplateEntryUtils } from '../../classes/EntityManager/entities/StructureTemplateEntry/StructureTemplateEntryUtils';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { StructureTemplateEntryGroup } from '../../classes/EntityManager/entities/StructureTemplateEntryGroup/types';
import { DeleteStructureTemplateEntryGroupEvent } from '../structure-template-entry-group-item/structure-template-entry-group-item';

@autoinject()
export class StructureTemplateEntryTreeItem {
  @bindable public structureTemplate: StructureTemplate | null = null;

  @bindable public structureTemplateEntry: StructureTemplateEntry | null = null;

  @bindable public editable = false;

  @bindable public entryFilterString = '';

  @bindable public ignoreTextFilter = false;

  private static createEntryGroupMoreButtonChoiceName = 'create-entry-group';

  private subscriptionManager: SubscriptionManager;

  private showHideAnimator: ShowHideAnimator | null = null;

  private availableChildren: Array<StructureTemplateEntry> = [];

  private structureTemplateEntryGroup: StructureTemplateEntryGroup | null =
    null;

  /**
   * flag which determines if the sub entries are expanded or not
   * will receive it's final value before the animation is finished
   */
  private showSubEntries = false;

  /**
   * determines if the subEntries should be rendered
   */
  private renderSubEntries = false;

  private domElement: Element;
  private subItemsElement: HTMLElement | null = null;

  private showAnimationFrame: number | null = null;

  private isAttached = false;

  private boundHandleDragOverItem = this.handleDragOverItem.bind(this);
  private boundHandleDragOutItem = this.handleDragOutItem.bind(this);
  private draggedOverTimeout: number | null = null;

  protected StructureTemplateEntryTreeItem = StructureTemplateEntryTreeItem;

  protected moreButtonChoices: Array<MoreButtonChoice> = [];

  @computedFrom('availableChildren.length', 'structureTemplateEntryGroup')
  private get cannotAddEntryGroup(): boolean {
    return (
      !!this.availableChildren.length || !!this.structureTemplateEntryGroup
    );
  }

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  private bind(): void {
    assertNotNullOrUndefined(
      this.subItemsElement,
      'subItemsElement is not available'
    );

    if (!this.showHideAnimator)
      this.showHideAnimator = new ShowHideAnimator(this.subItemsElement);
    this.subItemsElement.style.display = 'none';
  }

  private attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateChildren.bind(this)
    );
    this.updateChildren();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryGroup,
      this.updateMoreButtonChoices.bind(this)
    );
    this.updateMoreButtonChoices();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryGroupToStructureTemplateEntry,
      this.updateStructureTemplateEntryGroup.bind(this)
    );
    this.updateStructureTemplateEntryGroup();
  }

  private detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private updateChildren(): void {
    if (this.structureTemplate && this.structureTemplateEntry) {
      this.availableChildren =
        this.entityManager.structureTemplateEntryRepository.getByParentId(
          this.structureTemplate.id,
          this.structureTemplateEntry.id
        );
    } else {
      this.availableChildren = [];
    }
  }

  private updateStructureTemplateEntryGroup(): void {
    if (this.structureTemplateEntry) {
      const relation =
        this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
          this.structureTemplateEntry.id
        );
      this.structureTemplateEntryGroup =
        relation &&
        this.entityManager.structureTemplateEntryGroupRepository.getById(
          relation.structureTemplateEntryGroupId
        );
    } else {
      this.structureTemplateEntryGroup = null;
    }
  }

  private updateMoreButtonChoices(): void {
    const moreButtonChoices = [
      {
        labelTk:
          'structureTemplateComponents.structureTemplateEntryTreeItem.createEntryGroup',
        name: StructureTemplateEntryTreeItem.createEntryGroupMoreButtonChoiceName,
        disabledContext: this,
        disabledPropertyName: 'cannotAddEntryGroup'
      }
    ];

    if (this.structureTemplate) {
      const structureTemplateEntryGroups =
        this.entityManager.structureTemplateEntryGroupRepository.getByStructureTemplateId(
          this.structureTemplate.id
        );
      for (const structureTemplateEntryGroup of structureTemplateEntryGroups) {
        moreButtonChoices.push({
          name: structureTemplateEntryGroup.id,
          label:
            structureTemplateEntryGroup.name ?? structureTemplateEntryGroup.id,
          disabledContext: this,
          disabledPropertyName: 'cannotAddEntryGroup'
        });
      }
    }
    this.moreButtonChoices = moreButtonChoices;
  }

  private showEntriesModeChanged(): void {
    this.updateChildren();
  }

  private entryFilterStringChanged(): void {
    this.updateChildren();
  }

  private entryHasFilterStringInName(
    entryName: string,
    filterString: string
  ): boolean {
    if (!entryName || !filterString) return false;
    const upperCaseEntryName = entryName.toUpperCase();
    const upperCaseFilterString = filterString.toUpperCase();
    return upperCaseEntryName.indexOf(upperCaseFilterString) > -1;
  }

  private toggleShowSubEntries(event: MouseEvent): void {
    if (event.defaultPrevented) return;
    if (this.showSubEntries) {
      this.collapseSubEntries();
    } else {
      void this.expandSubEntries(true);
    }
  }

  public async expandToEntry(
    entryParents: Array<StructureTemplateEntry>
  ): Promise<void> {
    if (!this.showSubEntries) {
      await this.expandSubEntries(false);
    }

    const entryParentsCopy = entryParents.slice();
    if (entryParentsCopy.length === 0) {
      await this.updateChildrenAndWaitForRender();
      return;
    }

    const entryParent = entryParentsCopy.pop();
    if (!entryParent) return;

    const parentElement = this.getElementForEntryId(entryParent.id);
    if (!parentElement) return;

    const parentElementVM =
      Utils.getViewModelOfElement<StructureTemplateEntryTreeItem>(
        parentElement
      );
    if (parentElementVM) {
      await parentElementVM.expandToEntry(entryParentsCopy);
    }
  }

  private updateChildrenAndWaitForRender(): Promise<void> {
    return new Promise((res) => {
      this.updateChildren();
      setTimeout(res, 0);
    });
  }

  public expandSubEntries(animate: boolean): Promise<void> {
    return new Promise((res) => {
      if (this.showSubEntries) res();
      this.renderSubEntries = true;
      this.showAnimationFrame = window.requestAnimationFrame(async () => {
        const animationDuration = animate ? undefined : 0;
        this.showSubEntries = true;
        await this.showHideAnimator?.slideDown(animationDuration);
        setTimeout(() => {
          res();
        }, 0);
      });
    });
  }

  private collapseSubEntries(): void {
    if (!this.showSubEntries) return;
    if (this.showAnimationFrame)
      window.cancelAnimationFrame(this.showAnimationFrame);

    this.showSubEntries = false;

    this.showHideAnimator?.slideUp().then(() => {
      this.renderSubEntries = false;
    });
  }

  protected getPageDepthIndexOfEntry(entry: StructureTemplateEntry): string {
    const path =
      this.entityManager.structureTemplateEntryRepository.getPathByStructureTemplateEntryId(
        entry.id
      );
    return StructureTemplateEntryUtils.getPageDepthIndex(path);
  }

  private handleAddEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );

    this.entityManager.structureTemplateEntryRepository.create({
      ownerStructureTemplateId:
        this.structureTemplateEntry.ownerStructureTemplateId,
      ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId,
      parentEntryId: this.structureTemplateEntry.id
    });

    void this.expandSubEntries(true);
  }

  private handleEditEntryClick(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );
    void EditStructureTemplateEntryDialog.open({
      structureTemplateEntry: this.structureTemplateEntry,
      notEditable: !this.editable
    });
  }

  private handleDeleteEntryClick(): void {
    const structureTemplateEntry = this.structureTemplateEntry;
    assertNotNullOrUndefined(
      structureTemplateEntry,
      'structure template entry is not available'
    );

    void Dialogs.deleteEntityDialog(structureTemplateEntry).then(() => {
      this.entityManager.structureTemplateEntryRepository.deleteAndReorderChildren(
        structureTemplateEntry
      );
    });
  }

  protected handleDeleteStructureTemplateEntryGroup(
    event: DeleteStructureTemplateEntryGroupEvent
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'no structure template entry available'
    );

    const relation =
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
        this.structureTemplateEntry.id
      );
    if (
      !relation ||
      relation.structureTemplateEntryGroupId !==
        event.detail.structureTemplateEntryGroupId
    )
      return;

    void Dialogs.deleteDialogTk().then(() => {
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.delete(
        relation
      );
    });
  }

  protected handleMoreButtonChoiceSelected(name: string): void {
    if (
      name ===
      StructureTemplateEntryTreeItem.createEntryGroupMoreButtonChoiceName
    ) {
      this.handleCreateStructureTemplateEntryGroupClicked();
    } else {
      this.handleStructureTemplateEntryGroupSelected(name);
    }
  }

  private handleCreateStructureTemplateEntryGroupClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );

    const structureTemplateEntryGroup =
      this.entityManager.structureTemplateEntryGroupRepository.create({
        ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId,
        ownerStructureTemplateId:
          this.structureTemplateEntry.ownerStructureTemplateId
      });

    this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.create(
      {
        ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId,
        structureTemplateEntryGroupId: structureTemplateEntryGroup.id,
        structureTemplateEntryId: this.structureTemplateEntry.id
      }
    );
  }

  private handleStructureTemplateEntryGroupSelected(
    structureTemplateEntryGroupId: string
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );

    this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.create(
      {
        ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId,
        structureTemplateEntryGroupId: structureTemplateEntryGroupId,
        structureTemplateEntryId: this.structureTemplateEntry.id
      }
    );
  }

  private handleEntryChanged(): void {
    assertNotNullOrUndefined(this.structureTemplateEntry, 'entry is not set');
    this.entityManager.structureTemplateEntryRepository.update(
      this.structureTemplateEntry
    );
  }

  private getElementForEntryId(entryId: string): HTMLElement | null {
    assertNotNullOrUndefined(
      this.subItemsElement,
      'subItemsElement is not available'
    );
    return this.subItemsElement.querySelector(`#entry-${entryId}`);
  }

  private getSubEntriesToRender(
    visibleEntries: Array<StructureTemplateEntry>,
    renderSubEntries: boolean
  ): Array<StructureTemplateEntry> {
    return renderSubEntries ? visibleEntries : [];
  }

  private handleDragOverItem(): void {
    if (!this.showSubEntries) {
      this.domElement.classList.add('structure-list-tree-item--DraggedOver');
      this.draggedOverTimeout = window.setTimeout(() => {
        void this.expandSubEntries(true);
      }, 800);
    }
  }

  private handleDragOutItem(): void {
    this.domElement.classList.remove('structure-list-tree-item--DraggedOver');
    if (this.draggedOverTimeout) {
      window.clearTimeout(this.draggedOverTimeout);
    }
  }

  private handleDropItem(
    item: StructureTemplateEntryTreeItem,
    entryToReplace: StructureTemplateEntry | null
  ): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );

    const entry = item.structureTemplateEntry;
    assertNotNullOrUndefined(
      entry,
      'cannot drop element without a structure template entry'
    );

    const listPosition = entryToReplace ? entryToReplace.listPosition : null;

    const newParentId = this.structureTemplateEntry.id;
    if (entry.parentEntryId !== newParentId || !entryToReplace) {
      this.entityManager.structureTemplateEntryRepository.setParentIdOfStructureTemplateEntry(
        entry,
        newParentId
      );
    }

    if (listPosition != null) {
      this.entityManager.structureTemplateEntryRepository.setListPositionOfStructureTemplateEntry(
        entry,
        listPosition
      );
    }
  }

  private handleActivateDropTarget(
    viewModel: StructureTemplateEntryTreeItem,
    subEntry: StructureTemplateEntry
  ): boolean {
    return viewModel.structureTemplateEntry?.id !== subEntry.id;
  }

  @computedFrom('editable', 'structureTemplate.status')
  protected get isEntryEditable(): boolean {
    return (
      this.editable &&
      this.structureTemplate?.status === StructureTemplateStatus.DRAFT
    );
  }
}
