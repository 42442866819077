import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  GlobalThingPicture,
  TitleThingPicture
} from '../../classes/EntityManager/entities/Picture/types';
import { StructurePictureAreaUtils } from '../../classes/EntityManager/entities/StructurePictureArea/StructurePictureAreaUtils';
import { StructurePictureArea } from '../../classes/EntityManager/entities/StructurePictureArea/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { StructurePictureAreaPositionEditor } from '../structure-picture-area-position-editor/structure-picture-area-position-editor';

@autoinject()
export class EditStructurePictureAreasOverlay {
  private readonly subscriptionManager: SubscriptionManager;
  private picture: StructurePictureAreaOverlayPicture | null = null;
  private structurePictureAreas: Array<StructurePictureArea> = [];
  protected structurePictureAreaChoices: Array<StructurePictureAreaChoice> = [];
  protected selectedStructurePictureArea: StructurePictureArea | null = null;
  protected editBarExpanded: boolean = false;

  protected dialog: RecordItFullScreenDialog | null = null;
  protected pictureAreaPositionEditor: StructurePictureAreaPositionEditor | null =
    null;

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);

    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't StructurePictureAreaOverlay.open without dialog"
    );

    this.picture = options.picture;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructurePictureArea,
      this.updatePictureAreas.bind(this)
    );
    this.updatePictureAreas();

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.picture = null;
    this.structurePictureAreas = [];
    this.structurePictureAreaChoices = [];
    this.selectedStructurePictureArea = null;
    this.editBarExpanded = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't EditStructurePictureAreasOverlay.handleAddButtonClick without picture"
    );
    assertNotNullOrUndefined(
      this.picture.ownerThingId,
      "can' EditStructurePictureAreasOverlay.handleAddButtonClick because picture has no ownerThingId"
    );

    const structurePictureArea =
      this.entityManager.structurePictureAreaRepository.create({
        pictureId: this.picture.id,
        ownerThingId: this.picture.ownerThingId,
        ownerUserGroupId: this.picture.ownerUserGroupId
      });

    this.updatePictureAreas();
    this.selectedStructurePictureArea = structurePictureArea;
  }

  protected handleEditBarSizeChanged(): void {
    this.pictureAreaPositionEditor?.update();
  }

  protected handleEditButtonClicked(): void {
    this.editBarExpanded = !this.editBarExpanded;
  }

  protected handleSelectedStructurePictureAreaChanged(): void {
    if (!this.selectedStructurePictureArea) {
      this.editBarExpanded = false;
    }
  }

  protected handleStructurePictureAreaDeleted(): void {
    this.updatePictureAreas();
    this.selectedStructurePictureArea = null;
    this.editBarExpanded = false;
  }

  private updatePictureAreas(): void {
    if (this.picture) {
      this.structurePictureAreas =
        this.entityManager.structurePictureAreaRepository.getByPictureId(
          this.picture.id
        );
    } else {
      this.structurePictureAreas = [];
    }

    this.updatePictureAreaChoices();
  }

  private updatePictureAreaChoices(): void {
    const newChoices = this.structurePictureAreas.map(
      (structurePictureArea, index) => {
        const pathName = StructurePictureAreaUtils.formatPath(
          structurePictureArea.path
        );

        return {
          structurePictureArea,
          label: pathName
            ? pathName
            : this.i18n.tr(
                'structurePictureAreaComponents.editStructurePictureAreasOverlay.fallbackName',
                { index: index + 1 }
              )
        };
      }
    );

    this.structurePictureAreaChoices = InstancePreserver.createNewArray({
      originalArray: this.structurePictureAreaChoices,
      newArray: newChoices,
      getTrackingValue: (item) => item.structurePictureArea
    });
  }
}

export type OpenOptions = {
  picture: StructurePictureAreaOverlayPicture;
};

export type StructurePictureAreaOverlayPicture =
  | TitleThingPicture
  | GlobalThingPicture;

export type StructurePictureAreaChoice = {
  structurePictureArea: StructurePictureArea;
  label: string;
};
