import { inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';

import { Dialogs } from '../../classes/Dialogs';
import { default as dg700Schema } from '../../data/DG-700_schema.xml';
import { XmlSchemaParser } from '../../classes/XmlImporter/XmlSchemaParser';
import { XmlImportParser } from '../../classes/XmlImporter/XmlImportParser';
import { OpenWithHelper } from '../../classes/OpenWithHelper';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { DateUtils } from '../../../../common/src/DateUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

/**
 * this is meant to be a single global instance
 * if you want to include the openwith functionality, you need to call the registerOpenWithHandler function in the app.js or somewhere like this
 */
@inject(
  I18N,
  Router,
  SubscriptionManagerService,
  AppEntityManager,
  CurrentUserService
)
export class ImportEntryXmlFileDialog {
  /** @type {RecordItDialog} */
  _dialog;

  /** @type {TImportEntryXmlFileDialogOpenOptions} */
  _options;
  /** @type {boolean} */
  _loading = false;

  /** @type {Array<TImportEntryXmlFileDialogEntryToCreate>} */
  _entriesToCreate = [];

  /** @type {(string|null)} */
  _selectedProjectId;

  /**
   * this is only available when it is shown/needed!
   * @type {(ThingAndProjectSelect|null)}
   */
  _thingAndProjectSelect;

  /**
   *
   * @param {I18N} i18n
   * @param {Router} router
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   * @param {CurrentUserService} currentUserService
   */
  constructor(
    i18n,
    router,
    subscriptionManagerService,
    entityManager,
    currentUserService
  ) {
    this._i18n = i18n;
    this._router = router;
    this._subscriptionManager = subscriptionManagerService.create();
    this._entityManager = entityManager;
    this._currentUserService = currentUserService;
    this._ModuleNames = ModuleName;
  }

  /**
   *
   * @param {TImportEntryXmlFileDialogOpenOptions} options
   */
  open(options) {
    this._options = options;
    this._selectedProjectId = options.projectId;
    this._selectedThingId = options.thingId;
    this._dialog.open();

    if (options.file) {
      this._processFile(options.file);
    } else if (options.fileContents) {
      this._processXmlString(options.fileContents);
    }

    this._subscriptionManager.addDisposable(
      this._currentUserService.subscribeToCurrentUserChanged(
        this._checkUserPermission.bind(this)
      )
    );
    this._checkUserPermission();
  }

  close() {
    this._dialog.close();
  }

  _handleDeclineButtonClicked() {
    this.close();
  }

  _handleAcceptButtonClicked() {
    if (this._loading) {
      return;
    }

    if (
      this._thingAndProjectSelect &&
      !this._thingAndProjectSelect.validate()
    ) {
      return;
    }

    const entries = this._createEntries(this._entriesToCreate);
    if (this._options.entriesCreatedCallback) {
      this._options.entriesCreatedCallback(entries);
    }

    this._loading = true;
    setTimeout(() => {
      //just to make the user feel better
      this.close();
      this._autoNavigate(entries);
    }, 1000);
  }

  _handleDialogClosed() {
    this._subscriptionManager.disposeSubscriptions();
    this._loading = false;
    this._options = null;
    if (this._thingAndProjectSelect) {
      this._thingAndProjectSelect.resetValidation();
    }
  }

  _checkUserPermission() {
    const currentUser = this._currentUserService.getCurrentUser();
    if (
      currentUser &&
      !PermissionHelper.userHasPermission(
        currentUser,
        'canImportBlowerdoorXmlFiles'
      )
    ) {
      Dialogs.warningDialog(
        this._translate('noPermissionDialogTitle'),
        this._translate('noPermissionDialogText')
      );
      this.close();
    }
  }

  /**
   *
   * @param {File} file
   * @private
   */
  _processFile(file) {
    const reader = new FileReader();

    reader.onload = () => {
      this._processXmlString(reader.result);
      this._loading = false;
    };

    reader.onerror = (e) => {
      console.error(e);
      Dialogs.errorDialog(this._i18n.tr('general.unspecifiedError'));
      this.close();
      this._loading = false;
    };

    this._loading = true;
    reader.readAsText(file);
  }

  /**
   *
   * @param {string} xmlString
   * @private
   */
  _processXmlString(xmlString) {
    try {
      const schemaParser = new XmlSchemaParser(dg700Schema);
      const schema = schemaParser.parse();
      const importParser = new XmlImportParser(schema);
      const result = importParser.parse(xmlString);
      this._processImportResult(result);
    } catch (e) {
      console.error(e);
      Dialogs.errorDialog(
        this._translate('fileProcessingError'),
        this._translate('fileProcessingErrorDetail')
      );
      this.close();
    }
  }

  /**
   *
   * @param {TXmlImportParserParentNode} result
   * @private
   */
  _processImportResult(result) {
    const generalNodes = result.children.filter((child) => {
      return child.customId !== 'gauges';
    });

    /** @type {TXmlImportParserParentNode} */
    const gauges = result.children.find((child) => {
      return child.customId === 'gauges';
    });

    if (!gauges || !gauges.children.length) {
      throw new Error('no entries to add found!');
    }

    const entriesToCreate = [];
    gauges.children.forEach((nodesContainer, index) => {
      entriesToCreate.push(
        this._createEntryToCreate(
          generalNodes.concat(nodesContainer.children),
          index
        )
      );
    });

    this._entriesToCreate = entriesToCreate;
  }

  /**
   *
   * @param {Array<TXmlImportParserNode>} nodes
   * @param {number} index
   * @returns {TImportEntryXmlFileDialogEntryToCreate}
   * @private
   */
  _createEntryToCreate(nodes, index) {
    const entryToCreate = {
      name: this._translate('defaultEntryName'),
      properties: []
    };

    /** @type {TXmlImportParserNode} */
    let dateNode = null;
    nodes.forEach((node) => {
      if (node.customId === 'date') {
        dateNode = node;
      }

      entryToCreate.properties.push(this._createPropertyForNode(node));
    });

    if (dateNode) {
      entryToCreate.name +=
        ' ' + DateUtils.formatToDateWithHourMinuteString(dateNode.value);
    }

    return entryToCreate;
  }

  /**
   *
   * @param {TXmlImportParserNode} node
   * @returns {import('../../classes/EntityManager/entities/Property/types').Property}
   * @private
   */
  _createPropertyForNode(node) {
    let type = 'text';
    let value = '';
    let choices = null;
    let customChoice = null;

    switch (node.dataType) {
      case 'string':
        type = 'text';
        value = node.value;
        break;

      case 'number':
        type = 'nummer';
        value = node.value != null ? node.value.toString() : null;
        break;

      case 'date':
        type = 'datum';
        value = node.value != null ? node.value.toISOString() : null;
        break;

      case 'choice':
        type = 'dropdown';
        choices = node.choices ? node.choices.map((c) => c.userValue) : null;
        if (choices.indexOf(node.value) >= 0) {
          value = node.value;
        } else {
          value = '';
          customChoice = node.value;
        }

        break;
    }

    return {
      name: node.label,
      value: value,
      type: type,
      choices: choices,
      custom_choice: customChoice,
      alwaysVisible: true
    };
  }

  /**
   *
   * @param {Array<TImportEntryXmlFileDialogEntryToCreate>} entriesToCreate
   * @returns {Array<import('../../classes/EntityManager/entities/Entry/types').Entry>} - created entries
   * @private
   */
  _createEntries(entriesToCreate) {
    const project = this._selectedProjectId
      ? this._entityManager.projectRepository.getByOriginalId(
          this._selectedProjectId
        )
      : null;

    let parentEntryId = this._options.parentEntryId;
    if (this._options.parentEntryId) {
      const originalEntry = this._entityManager.entryRepository.getByOriginalId(
        this._options.parentEntryId
      );
      parentEntryId = originalEntry?.id || parentEntryId;
    }

    const entries = [];

    entriesToCreate.forEach((entryToCreate) => {
      entries.push(
        this._createEntry(
          entryToCreate,
          project?.id ?? this._selectedProjectId,
          parentEntryId
        )
      );
    });

    return entries;
  }

  /**
   *
   * @param {TImportEntryXmlFileDialogEntryToCreate} entryToCreate
   * @param {string} projectId
   * @param {(string|null)} parentEntryId
   * @returns {import('../../classes/EntityManager/entities/Entry/types').Entry}
   * @private
   */
  _createEntry(entryToCreate, projectId, parentEntryId) {
    const project = this._entityManager.projectRepository.getById(projectId);
    assertNotNullOrUndefined(project, `project for "${projectId}" not found`);

    const entry = this._entityManager.entryRepository.create({
      name: entryToCreate.name,
      project: projectId,
      ownerProjectId: projectId,
      page_depth_parent: parentEntryId,
      ownerUserGroupId: project.usergroup
    });

    entryToCreate.properties.forEach((property) => {
      property.entry = entry.id;
      property.ownerProjectId = entry.ownerProjectId;
      property.ownerUserGroupId = entry.ownerUserGroupId;

      this._entityManager.propertyRepository.create(property);
    });

    return entry;
  }

  /**
   *
   * @param {Array<import('../../classes/EntityManager/entities/Entry/types').Entry>} createdEntries
   * @private
   */
  _autoNavigate(createdEntries) {
    if (this._options.autoNavigateToEntry) {
      const entry = createdEntries[0];
      this._router.navigateToRoute('project', {
        project_id: entry.project,
        entry_id: entry.id,
        edit_entry: true
      });
    }
  }

  /**
   *
   * @param {string} key
   * @returns {string}
   * @private
   */
  _translate(key) {
    return this._i18n.tr('dialogs.importEntryXmlFileDialog.' + key);
  }

  /**
   *
   * @param {TImportEntryXmlFileDialogOpenOptions} options
   * @returns {Promise<void>}
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  static registerOpenWithHandler() {
    OpenWithHelper.addHandler((item) => {
      const content = atob(item.base64);

      if (/^<\?xml/.test(content)) {
        this.open({
          fileContents: content,
          autoNavigateToEntry: true
        });
        return true;
      }

      return false;
    });
  }
}

/**
 * @typedef {Object} TImportEntryXmlFileDialogEntryToCreate
 * @property {string} name
 * @property {Array<import('../../classes/EntityManager/entities/Property/types').Property>} properties
 */

/**
 * @typedef {Object} TImportEntryXmlFileDialogOpenOptions
 * @property {(string|null)} [projectId] - if the projectId is not given, then the user has to manually select a project
 * @property {(string|null)} [thingId] - if no projectId is given, you can preselect a thing (but the user still has to manually select a project)
 * @property {(string|null)} [parentEntryId]
 * @property {(function(createdEntries: Array<import('../../classes/EntityManager/entities/Entry/types').Entry>)|null)} [entriesCreatedCallback]
 * @property {(boolean|null)} [autoNavigateToEntry] - automatically navigate to the first new entry after it was created
 *
 * one of the following must be given
 * @property {(File|null)} [file] - a xml file
 * @property {(string|null)} [fileContents] - a xml string
 */
