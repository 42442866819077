import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  GetProcessTaskGroupsFilterAddressFilterType,
  ProcessConfigurationActionStatusFilterTypeEnum
} from 'common/EndpointTypes/OperationsEndpointsTypes';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';
import {
  FilterSettings,
  ProcessTaskGroupFilterSettingsService
} from '../../services/ProcessTaskGroupFilterSettingsService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationActionStatus } from '../../classes/EntityManager/entities/ProcessConfigurationActionStatus/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { DefaultProcessTaskGroupFilterBarFilterSettingsComputer } from '../../computedValues/computers/DefaultProcessTaskGroupFilterBarFilterSettingsComputer/DefaultProcessTaskGroupFilterBarFilterSettingsComputer';
import {
  FilterBarFilterSettings,
  FilterBarFilterSettingsUtils
} from './FilterBarFilterSettingsUtils';

/**
 * @slot filter-select - Slot for a ProcessTaskGroupFilterSelect displayed in one line with the filter icon toggle
 */
@autoinject()
export class ProcessTaskGroupsFilterBar {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  protected isFilterVisible: boolean = true;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly handleFilterCriteriaChangedDebounced =
    Utils.debounceFunction(this.handleFilterCriteriaChanged.bind(this), 500);

  private defaultProcessTaskGroupFilterBarFilterSettings: FilterBarFilterSettings | null =
    null;
  private filterMode: ProcessTaskGroupFilterMode =
    ProcessTaskGroupFilterMode.DATE_RANGE;
  private nextDaysFilterValue: number | null =
    DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE;
  private dateRangeFilterDateFromIso: string | null = null;
  private dateRangeFilterDateToIso: string | null = null;
  private addressFilterType: GetProcessTaskGroupsFilterAddressFilterType =
    GetProcessTaskGroupsFilterAddressFilterType.ALL;
  private includeArchive: boolean = false;
  private errorTextTranslationKeys: Array<string> | null = null;
  private assigneeUserId: string | null = null;
  private selectedActionStatusOption: ActionStatusOption | null = null;
  private actionStatusOptions: Array<ActionStatusOption> = [];
  private customActionStatusFilterText: string | null = null;
  private lastEditedBeforeDaysEnabled: boolean = false;
  private lastEditedBeforeDays: number = 0;
  private isAttached: boolean = false;

  protected ProcessTaskGroupFilterMode = ProcessTaskGroupFilterMode;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskGroupFilterSettingsService: ProcessTaskGroupFilterSettingsService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationActionStatus,
      this.updateActionStatusOptions.bind(this)
    );
    this.updateActionStatusOptions();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          DefaultProcessTaskGroupFilterBarFilterSettingsComputer,
        computeData: {},
        callback: (defaultProcessTaskGroupFilterBarFilterSettings) => {
          this.defaultProcessTaskGroupFilterBarFilterSettings =
            defaultProcessTaskGroupFilterBarFilterSettings;
        }
      })
    );

    this.processTaskGroupFilterSettingsService.subscribeToChange(
      this,
      (filterSettings) => {
        this.setPropsFromFilter(filterSettings);
      }
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    this.processTaskGroupFilterSettingsService.unsubscribe(this);
  }

  protected processConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateActionStatusOptions();
    }
  }

  private updateActionStatusOptions(): void {
    if (this.processConfiguration) {
      const options: Array<ActionStatusOption> = [
        {
          actionStatus: null,
          type: ProcessConfigurationActionStatusFilterTypeEnum.NONE,
          label: this.i18n.tr(
            'operations.processTaskGroupsFilterBar.noActionStatus'
          ) as string
        },
        {
          actionStatus: null,
          type: ProcessConfigurationActionStatusFilterTypeEnum.CUSTOM,
          label: this.i18n.tr('general.custom') as string
        }
      ];

      const actionStates =
        this.entityManager.processConfigurationActionStatusRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
      actionStates.forEach((i) => {
        options.push({
          actionStatus: i,
          type: ProcessConfigurationActionStatusFilterTypeEnum.ID,
          label: i.name
        });
      });

      this.actionStatusOptions = options;
    } else {
      this.actionStatusOptions = [];
    }

    const filterSettings =
      this.processTaskGroupFilterSettingsService.getFilterSettings();
    this.setSelectedActionStatusOptionFromFilterSaveData(filterSettings);
  }

  private setPropsFromFilter(filterSettings: FilterBarFilterSettings): void {
    this.filterMode = filterSettings.filterMode;
    this.addressFilterType = filterSettings.addressFilterType;
    this.nextDaysFilterValue = filterSettings.nextDaysFilterValue;
    this.includeArchive = filterSettings.includeArchive;
    this.assigneeUserId = filterSettings.assigneeUserId;

    this.dateRangeFilterDateFromIso = filterSettings.dateRangeFilterDateFromIso;
    this.dateRangeFilterDateToIso = filterSettings.dateRangeFilterDateToIso;

    this.lastEditedBeforeDaysEnabled =
      filterSettings.lastEditedBeforeDaysEnabled;
    this.lastEditedBeforeDays = filterSettings.lastEditedBeforeDays;

    this.setSelectedActionStatusOptionFromFilterSaveData(filterSettings);
    this.customActionStatusFilterText =
      filterSettings.customActionStatusFilterText;
  }

  @computed(
    expression('filterMode'),
    expression('addressFilterType'),
    expression('nextDaysFilterValue'),
    expression('dateRangeFilterDateFromIso'),
    expression('dateRangeFilterDateToIso'),
    expression('includeArchive'),
    expression('assigneeUserId'),
    expression('selectedActionStatusOption.type'),
    expression('selectedActionStatusOption.actionStatus'),
    expression('customActionStatusFilterText'),
    expression('lastEditedBeforeDaysEnabled'),
    expression('lastEditedBeforeDays')
  )
  private get currentPropsAsFilter(): FilterBarFilterSettings {
    return {
      filterMode: this.filterMode,
      addressFilterType: this.addressFilterType,
      nextDaysFilterValue: this.nextDaysFilterValue,
      dateRangeFilterDateFromIso: this.dateRangeFilterDateFromIso,
      dateRangeFilterDateToIso: this.dateRangeFilterDateToIso,
      includeArchive: this.includeArchive,
      assigneeUserId: this.assigneeUserId,
      selectedActionStatusOptionType: this.selectedActionStatusOption
        ? this.selectedActionStatusOption.type
        : null,
      selectedActionStatusId:
        this.selectedActionStatusOption &&
        this.selectedActionStatusOption.actionStatus
          ? this.selectedActionStatusOption.actionStatus.id
          : null,
      customActionStatusFilterText: this.customActionStatusFilterText,
      lastEditedBeforeDaysEnabled: this.lastEditedBeforeDaysEnabled,
      lastEditedBeforeDays: this.lastEditedBeforeDays
    };
  }

  private setSelectedActionStatusOptionFromFilterSaveData(
    filterSettings: Pick<
      FilterSettings,
      'selectedActionStatusOptionType' | 'selectedActionStatusId'
    >
  ): void {
    const option = this.actionStatusOptions.find((o) => {
      const actionStatusId = o.actionStatus ? o.actionStatus.id : null;
      return (
        o.type === filterSettings.selectedActionStatusOptionType &&
        actionStatusId === filterSettings.selectedActionStatusId
      );
    });

    const newOption = option !== undefined ? option : null;

    if (newOption !== this.selectedActionStatusOption) {
      this.selectedActionStatusOption = newOption;
    }
  }

  protected handleFilterContentClick(
    filterMode: ProcessTaskGroupFilterMode
  ): void {
    if (filterMode !== this.filterMode) {
      this.filterMode = filterMode;
      this.handleFilterCriteriaChanged();
    }
  }

  protected handleFilterIconClick(): void {
    if (this.isFilterVisible) {
      // Hiding the filter is only allowed if the filter has not been modified
      if (!this.filterIsModified) {
        this.isFilterVisible = false;
      }
    } else {
      this.isFilterVisible = true;
    }
  }

  protected handleCustomActionStatusFilterTextChanged(): void {
    this.handleFilterCriteriaChangedDebounced();
  }

  protected handleFilterCriteriaChanged(): void {
    if (!this.validate()) {
      return;
    }

    this.modifyFilterSettings();
  }

  private validate(): boolean {
    switch (this.filterMode) {
      case ProcessTaskGroupFilterMode.NEXT_DAYS:
        return this.validateNextDays();

      case ProcessTaskGroupFilterMode.DATE_RANGE:
        return this.validateDateRange();

      case ProcessTaskGroupFilterMode.NO_FUTURE_APPOINTMENTS:
        return true;

      case ProcessTaskGroupFilterMode.ALL:
        return true;

      default:
        console.error(`filter mode ${this.filterMode} is not handled`);
        return false;
    }
  }

  private validateNextDays(): boolean {
    const translationKeys: Array<string> = [];

    if (this.nextDaysFilterValue == null || this.nextDaysFilterValue < 0) {
      translationKeys.push(
        'operations.processTaskGroupsFilterBar.errorTextNextDaysValueInvalid'
      );
    }

    this.errorTextTranslationKeys = translationKeys.length
      ? translationKeys
      : null;
    return !this.errorTextTranslationKeys;
  }

  private validateDateRange(): boolean {
    const translationKeys: Array<string> = [];

    if (this.dateRangeFilterDateFromIso == null) {
      translationKeys.push(
        'operations.processTaskGroupsFilterBar.errorTextDateFromInvalid'
      );
    }

    if (this.dateRangeFilterDateToIso == null) {
      translationKeys.push(
        'operations.processTaskGroupsFilterBar.errorTextDateToInvalid'
      );
    }

    if (
      this.dateRangeFilterDateFromIso != null &&
      this.dateRangeFilterDateToIso != null &&
      this.dateRangeFilterDateFromIso.localeCompare(
        this.dateRangeFilterDateToIso
      ) >= 0
    ) {
      translationKeys.push(
        'operations.processTaskGroupsFilterBar.errorTextDateToBeforeDateFrom'
      );
    }

    this.errorTextTranslationKeys = translationKeys.length
      ? translationKeys
      : null;
    return !this.errorTextTranslationKeys;
  }

  private modifyFilterSettings(): void {
    this.processTaskGroupFilterSettingsService.modifyFilterSettings(
      this.currentPropsAsFilter
    );
  }

  protected handleResetButtonClick(): void {
    assertNotNullOrUndefined(
      this.defaultProcessTaskGroupFilterBarFilterSettings,
      "can't ProcessTaskGroupsFilterBar.handleResetButtonClick without defaultProcessTaskGroupFilterBarFilterSettings"
    );

    this.setPropsFromFilter(
      this.defaultProcessTaskGroupFilterBarFilterSettings
    );
    this.handleFilterCriteriaChanged();
  }

  @computedFrom('processConfiguration.showProcessTaskFeatures.actionStatus')
  protected get shouldShowActionStatus(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.actionStatus ?? true
    );
  }

  @computed(
    expression('currentPropsAsFilter'),
    expression('defaultProcessTaskGroupFilterBarFilterSettings')
  )
  protected get filterIsModified(): boolean {
    if (!this.defaultProcessTaskGroupFilterBarFilterSettings) {
      return false;
    }

    return !FilterBarFilterSettingsUtils.isEqual(
      this.defaultProcessTaskGroupFilterBarFilterSettings,
      this.currentPropsAsFilter
    );
  }
}

type BaseActionStatusOption<TActionStatus, TType> = {
  actionStatus: TActionStatus;
  type: TType;
  label: string | null;
};

type ActionStatusOption =
  | BaseActionStatusOption<
      ProcessConfigurationActionStatus,
      ProcessConfigurationActionStatusFilterTypeEnum.ID
    >
  | BaseActionStatusOption<
      null,
      ProcessConfigurationActionStatusFilterTypeEnum.NONE
    >
  | BaseActionStatusOption<
      null,
      ProcessConfigurationActionStatusFilterTypeEnum.CUSTOM
    >;
