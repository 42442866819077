import { DataHandler } from './DataHandler';
import { DebugLogger } from './DebugLogger';

export class SocketIoZipPluginUtils {
  public static patchEmitFunction<T extends { prototype: Record<any, any> }>({
    toPatch,
    functionName,
    dataHandler,
    debugLogger
  }: {
    toPatch: T;
    functionName: keyof T['prototype'];
    dataHandler: DataHandler;
    debugLogger: DebugLogger;
  }): void {
    const originalFunction = toPatch.prototype[functionName];
    toPatch.prototype[functionName] = function (
      eventName: string | symbol,
      ...args: Array<unknown>
    ) {
      debugLogger.log('emitted', eventName, ...args);

      void SocketIoZipPluginUtils.prepareEmitArgs({
        scope: this,
        args,
        dataHandler,
        debugLogger
      }).then((preparedArgs) => {
        originalFunction.apply(this, [eventName, ...preparedArgs]);
      });

      return this;
    };
  }

  public static patchOnOffFunctions({
    toPatch,
    dataHandler
  }: {
    toPatch: OnOffClass;
    dataHandler: DataHandler;
  }): void {
    const eventListenerToOverrideListenerByEventName = new Map<
      string | symbol,
      WeakMap<any, any>
    >();

    const onFunction = toPatch.prototype.on;
    toPatch.prototype.on = function (eventName, eventListener) {
      const overrideListener =
        SocketIoZipPluginUtils.createEventListenerOverrideCallback({
          scope: this,
          originalEventListener: eventListener,
          dataHandler
        });

      let mapOfEventName =
        eventListenerToOverrideListenerByEventName.get(eventName);
      if (!mapOfEventName) {
        mapOfEventName = new WeakMap();
        eventListenerToOverrideListenerByEventName.set(
          eventName,
          mapOfEventName
        );
      }

      mapOfEventName.set(eventListener, overrideListener);

      return onFunction.call(this, eventName, overrideListener);
    };

    const offFunction = toPatch.prototype.off;
    toPatch.prototype.off = function (eventName, eventListener) {
      if (eventName && eventListener) {
        const overrideListener = eventListenerToOverrideListenerByEventName
          .get(eventName)
          ?.get(eventListener);
        if (!overrideListener) {
          return this;
        }

        return offFunction.call(this, eventName, overrideListener);
      }

      return offFunction.call(this, eventName, eventListener);
    };
  }

  public static patchOnOffAnyFunctions({
    toPatch,
    dataHandler
  }: {
    toPatch: OnOffAnyClass;
    dataHandler: DataHandler;
  }): void {
    const eventListenerToOverrideListener = new WeakMap<any, any>();

    const onAnyFunction = toPatch.prototype.onAny;
    toPatch.prototype.onAny = function (eventListener) {
      const overrideListener =
        SocketIoZipPluginUtils.createEventListenerOverrideCallback({
          scope: this,
          originalEventListener: eventListener,
          dataHandler
        });

      eventListenerToOverrideListener.set(eventListener, overrideListener);

      return onAnyFunction.call(this, overrideListener);
    };

    const offAnyFunction = toPatch.prototype.offAny;
    toPatch.prototype.offAny = function (eventListener) {
      if (eventListener) {
        const overrideListener =
          eventListenerToOverrideListener.get(eventListener);
        if (!overrideListener) {
          return this;
        }

        return offAnyFunction.call(this, overrideListener);
      }

      return offAnyFunction.call(this, eventListener);
    };
  }

  private static async prepareEmitArgs({
    scope,
    args,
    dataHandler,
    debugLogger
  }: {
    scope: unknown;
    args: Array<unknown>;
    dataHandler: DataHandler;
    debugLogger: DebugLogger;
  }): Promise<Array<unknown>> {
    const newArgs: Array<unknown> = [];

    for (const [index, arg] of args.entries()) {
      if (typeof arg !== 'function') {
        newArgs.push(await dataHandler.encode({ scope, data: arg }));
      } else if (index === args.length - 1) {
        newArgs.push(
          this.createEmitAcknowledgedCallback({
            originalFunction: arg,
            dataHandler,
            debugLogger
          })
        );
      } else {
        throw new Error(
          'Detected a function in the emit arguments which is not the last parameter. This is not supported'
        );
      }
    }

    return newArgs;
  }

  private static createEmitAcknowledgedCallback({
    originalFunction,
    dataHandler,
    debugLogger
  }: {
    originalFunction: Function;
    dataHandler: DataHandler;
    debugLogger: DebugLogger;
  }): (...args: Array<unknown>) => void {
    return (...acknowledgementArgs: Array<unknown>) => {
      if (acknowledgementArgs.length > 1) {
        throw new Error(
          'only acknowledgements with a maximum of 1 arg are allowed'
        );
      }

      void dataHandler
        .decode({ data: acknowledgementArgs[0] })
        .then((decoded) => {
          debugLogger.log('got response', decoded);
          originalFunction(decoded);
        });
    };
  }

  private static createEventListenerOverrideCallback({
    scope,
    originalEventListener,
    dataHandler
  }: {
    scope: unknown;
    originalEventListener: (...args: Array<unknown>) => Promise<void> | void;
    dataHandler: DataHandler;
  }): (...args: Array<unknown>) => Promise<void> | void {
    return async (...args) => {
      const preparedArgs = await this.prepareOverrideEventListenerArgs({
        scope,
        args,
        dataHandler
      });

      return originalEventListener(...preparedArgs);
    };
  }

  private static async prepareOverrideEventListenerArgs({
    scope,
    args,
    dataHandler
  }: {
    scope: unknown;
    args: Array<unknown>;
    dataHandler: DataHandler;
  }): Promise<Array<unknown>> {
    const preparedArgs: Array<unknown> = [];

    for (const arg of args) {
      if (typeof arg === 'function') {
        preparedArgs.push(async function (
          ...acknowledgementArgs: Array<unknown>
        ) {
          const encodedArgs: Array<unknown> = [];

          for (const acknowledgementArg of acknowledgementArgs) {
            encodedArgs.push(
              await dataHandler.encode({
                scope,
                data: acknowledgementArg
              })
            );
          }

          arg(...encodedArgs);
        });
      } else {
        preparedArgs.push(await dataHandler.decode({ data: arg }));
      }
    }

    return preparedArgs;
  }
}

type OnOffClass = {
  prototype: {
    on: (
      eventName: string | symbol,
      eventListener: (...args: Array<unknown>) => void | Promise<void>
    ) => unknown;
    off: (
      eventName: string | symbol,
      eventListener: (...args: Array<unknown>) => void | Promise<void>
    ) => unknown;
  };
};

type OnOffAnyClass = {
  prototype: {
    onAny: (
      eventListener: (...args: Array<unknown>) => void | Promise<void>
    ) => unknown;
    offAny: (
      eventListener: (...args: Array<unknown>) => void | Promise<void>
    ) => unknown;
  };
};
