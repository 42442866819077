import { inject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(SubscriptionManagerService, AppEntityManager)
export class ThingPersonFilter {
  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {Array<Thing>} */
  @bindable things = [];

  /** @type {Array<Thing>} */
  @bindable filteredThings = [];

  /** @type {string|null} */
  @bindable personId = null;

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(subscriptionManagerService, entityManager) {
    this._entityManager = entityManager;

    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this._updateFilteredThings.bind(this)
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  thingsChanged() {
    this._updateFilteredThings();
  }

  personIdChanged() {
    this._updateFilteredThings();
  }

  _updateFilteredThings() {
    if (!this.personId) {
      this.filteredThings = this.things;
      return;
    }

    const personRelations =
      this._entityManager.thingToPersonRepository.getByPersonId(this.personId);

    this.filteredThings = this.things.filter((thing) => {
      return !!personRelations.find(
        (personRelation) => personRelation.thingId === thing.id
      );
    });
  }
}

/** @typedef {import('../../classes/EntityManager/entities/Thing/types').Thing} Thing */
