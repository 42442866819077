import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { StructurePictureAreaDto } from './StructurePictureAreaDto';

export function createStructurePictureAreaEntityInfo<
  TEntity extends StructurePictureAreaDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructurePictureAreaDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructurePictureArea,
  TEntity
> {
  return {
    entityName: EntityName.StructurePictureArea,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructurePictureAreaDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        path: {
          name: 'path',
          defaultValue: () => []
        },
        pictureId: {
          name: 'pictureId',
          ref: EntityName.Picture,
          cascadeDeleteLocally: true
        },
        topLeft: {
          name: 'topLeft',
          innerInfo: {
            x: {
              name: 'x'
            },
            y: {
              name: 'y'
            }
          },
          defaultValue: null
        },
        bottomRight: {
          name: 'bottomRight',
          innerInfo: {
            x: {
              name: 'x'
            },
            y: {
              name: 'y'
            }
          },
          defaultValue: null
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
