import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProjectQuestionCategoryEntityInfo } from 'common/Types/Entities/ProjectQuestionCategory/createProjectQuestionCategoryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProjectQuestionCategory } from './types';

export const projectQuestionCategoryEntityInfo =
  createProjectQuestionCategoryEntityInfo<ProjectQuestionCategory>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
