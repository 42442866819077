import { autoinject } from 'aurelia-framework';

import { MomentInput } from 'moment';
import { App } from '@capacitor/app';

import { environment } from '../../environment';

import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { TestingHelper } from '../../classes/TestingHelper';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DataStorageDumpSaver } from '../../classes/Rescue/DataStorageDumpSaver';
import { CapturedPicturesRescueUploader } from '../../classes/Rescue/CapturedPicturesRescueUploader';
import { LostCapturedPicturesRescueService } from '../../services/LostCapturedPicturesRescueService/LostCapturedPicturesRescueService';
import { Dialogs } from '../../classes/Dialogs';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { PictureFileAutoDownloadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoDownloadService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { PictureFileAutoUploadService } from '../../classes/EntityManager/entities/PictureFile/PictureFileAutoUploadService';

@autoinject()
export class SynchronizationStatusSidebar {
  private socketIsConnected = false;

  private asideElement: HTMLElement | null = null;

  private asideElementAnimator: ShowHideAnimator | null = null;

  private backdropElement: HTMLElement | null = null;

  private backdropElementAnimator: ShowHideAnimator | null = null;

  private projectsWhichAreJoined: Array<Project> = [];

  private pictureFilesToUpload: Array<PictureFile> = [];

  private pictureFilesToDownload: Array<PictureFile> = [];

  private itemsToSynchronizeCount: number = 0;
  private failedItemsToSynchronizeCount: number = 0;

  private shown = false;

  private subscriptionManager: SubscriptionManager;

  private isApp = false;

  private testingHelper = TestingHelper;

  protected appVersionInfo = '';

  constructor(
    private socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly lostCapturedPicturesRescueService: LostCapturedPicturesRescueService,
    private readonly currentUserService: CurrentUserService,
    private readonly pictureFileAutoDownloadService: PictureFileAutoDownloadService,
    private readonly pictureFileAutoUploadService: PictureFileAutoUploadService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public show(): void {
    if (this.asideElementAnimator) this.asideElementAnimator.fadeMoveInRight();
    if (this.backdropElementAnimator)
      void this.backdropElementAnimator.fadeIn();
    SiteScrollLocker.lockScrolling('synchronization-status-sidebar');
    this.shown = true;
  }

  public hide(): void {
    if (this.asideElementAnimator) this.asideElementAnimator.fadeMoveOutRight();
    if (this.backdropElementAnimator)
      void this.backdropElementAnimator.fadeOut();
    SiteScrollLocker.unlockScrolling('synchronization-status-sidebar');
    this.shown = false;
  }

  public isShown(): boolean {
    return this.shown;
  }

  private updateProjects(): void {
    this.projectsWhichAreJoined = this.entityManager.projectRepository.getByIds(
      this.entityManager.joinedProjectsManager.getJoinedProjectIds()
    );
  }

  private updatePictureFiles(): void {
    this.pictureFilesToUpload =
      this.pictureFileAutoUploadService.getPictureFilesToUpload();
    this.pictureFilesToDownload =
      this.pictureFileAutoDownloadService.getPictureFilesToDownload();
  }

  private handleToggleSocketConnectionClick(): void {
    this.socketService.toggleConnection();
  }

  private async handleLeaveProject(project: Project): Promise<void> {
    await this.entityManager.joinedProjectsManager.leaveProject(project.id);
  }

  private formatToDateWithTime(time: MomentInput): string {
    return DateUtils.formatToDateWithTimeString(time);
  }

  private handleRetryFailedSynchronizationClick(): void {
    this.entityManager.entitySynchronization.retryFailedItems();
  }

  private async handleSaveDumpClick(): Promise<void> {
    Dialogs.blockingStatusDialog('Lokale Daten werden hochgeladen');

    try {
      const saver = new DataStorageDumpSaver({
        currentUserService: this.currentUserService
      });
      await saver.createAndUploadDump();
      Dialogs.timedSuccessDialog('Lokale Daten wurden hochgeladen');
    } catch (e) {
      void Dialogs.errorDialog(
        'Beim Hochladen der lokalen Daten ist ein Fehler aufgetreten'
      );
      throw e;
    }
  }

  private handleCloseButtonClick(): void {
    this.hide();
  }

  private async handleUploadCapturedPicturesClick(): Promise<void> {
    Dialogs.blockingStatusDialog('Aufgenommene Bilder werden hochgeladen');

    try {
      const user = this.currentUserService.getCurrentUser();
      assertNotNullOrUndefined(user, 'no current user available');

      const pictureUploader = new CapturedPicturesRescueUploader(
        user,
        this.socketService
      );
      await pictureUploader.uploadCapturedPictures();
      Dialogs.timedSuccessDialog('Aufgenommene Bilder wurden hochgeladen');
    } catch (e) {
      void Dialogs.errorDialog(
        'Beim Hochladen der Bilder ist ein Fehler aufgetreten'
      );
      throw e;
    }
  }

  private async handleRescueLostCapturedPicturesClick(): Promise<void> {
    await this.lostCapturedPicturesRescueService.rescueLostCapturedPictures();
  }

  // ********** Lifecycle **********

  protected bind(): void {
    if (this.asideElement)
      this.asideElementAnimator = new ShowHideAnimator(this.asideElement);
    if (this.backdropElement)
      this.backdropElementAnimator = new ShowHideAnimator(this.backdropElement);
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.socketIsConnected = isConnected;
      })
    );

    this.subscriptionManager.subscribeToProjectJoinedChanged(
      this.updateProjects.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateProjects.bind(this)
    );
    void this.updateProjects();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      this.updatePictureFiles.bind(this)
    );
    this.updatePictureFiles();

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.bindItemsToSynchronizeInfos(
        (itemsToSynchronizeInfos) => {
          this.itemsToSynchronizeCount = itemsToSynchronizeInfos.length;
          this.failedItemsToSynchronizeCount = itemsToSynchronizeInfos.filter(
            (i) => i.failed
          ).length;
        }
      )
    );

    this.isApp = DeviceInfoHelper.isApp();

    if (this.isApp) {
      void App.getInfo().then((info) => {
        this.appVersionInfo = [
          info.version,
          info.build,
          environment.lastCommitHash
        ].join(' - ');
      });
    } else {
      this.appVersionInfo = environment.lastCommitHash;
    }
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}
