import { autoinject, bindable } from 'aurelia-framework';

import {
  DeviceDefaultPropertyConfig,
  ProcessConfigurationDevicePropertiesConfiguration
} from 'common/Types/ProcessConfigurationDevicePropertiesConfiguration';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import {
  CheckboxCheckedChangedEvent,
  ExpandableDualRowCompactListItem
} from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { RoundedNumberValueConverter } from '../../valueConverters/RoundedNumberValueConverter';
import { DateRangeValueConverter } from '../../valueConverters/DateRangeValueConverter';
import { I18N } from 'aurelia-i18n';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { PropertiesByProcessTaskDeviceIdComputer } from '../../computedValues/computers/PropertiesByProcessTaskDeviceIdComputer';
import { ProcessTaskDeviceProperty } from '../../classes/EntityManager/entities/Property/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { ProcessTaskDeviceAdapter } from '../list-item-related-entity-counts/adapter/ProcessTaskDeviceAdapter/ProcessTaskDeviceAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-button-clicked
 * @event {CheckboxCheckedChangedEvent} checkbox-checked-changed
 */
@autoinject()
export class ProcessTaskDeviceListItem {
  @bindable()
  public processTaskDevice: ProcessTaskDevice | null = null;

  @bindable()
  public checkboxEnabled: boolean = false;

  @bindable()
  public hasCheckbox: boolean = false;

  @bindable()
  public checked: boolean = false;

  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskDevice];

  private readonly subscriptionManager: SubscriptionManager;
  protected configurationDevice: ProcessConfigurationDevice | null = null;
  private isAttached = false;
  private listItem: ExpandableDualRowCompactListItem | null = null;
  protected visibleDefaultProperties: Array<DeviceDefaultPropertyConfig> = [];
  protected properties: Array<ProcessTaskDeviceProperty> = [];
  protected relatedEntityCountsAdapter: ProcessTaskDeviceAdapter | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly roundedNumberValueConverter: RoundedNumberValueConverter,
    private readonly dateRangeValueConverter: DateRangeValueConverter,
    private readonly computedValueService: ComputedValueService,
    private readonly i18n: I18N,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskDevice,
        context: this,
        expression: 'processTaskDevice'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    const configurationDisposable =
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () => {
          return this.processTaskDevice
            ? {
                processTaskGroupId:
                  this.processTaskDevice.ownerProcessTaskGroupId
              }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskDevice.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (processConfiguration) =>
          this.updateDefaultProperties(processConfiguration)
      });
    this.subscriptionManager.addDisposable(configurationDisposable);

    const properties =
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: PropertiesByProcessTaskDeviceIdComputer,
        createComputeData: () => {
          return this.processTaskDevice
            ? { ownerProcessTaskId: this.processTaskDevice.ownerProcessTaskId }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskDevice.id && processTaskDevice.ownerProcessTaskId',
            updateSubscription
          );
        },
        callback: (propertiesByProcessTaskDeviceId) => {
          if (this.processTaskDevice) {
            this.properties =
              propertiesByProcessTaskDeviceId.get(this.processTaskDevice.id) ??
              [];
          } else {
            this.properties = [];
          }
        }
      });
    this.subscriptionManager.addDisposable(properties);

    this.updateConfigurationDevice();
    this.updateRelatedEntityCountsAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  public highlight(): void {
    if (this.listItem) {
      this.listItem.highlight();
    }
  }

  private updateDefaultProperties(
    processConfiguration: ProcessConfiguration | null
  ): void {
    const config = processConfiguration?.devicePropertiesConfigurationJson
      ? (JSON.parse(
          processConfiguration.devicePropertiesConfigurationJson
        ) as ProcessConfigurationDevicePropertiesConfiguration)
      : null;
    const defaultProperties = config?.defaultProperties ?? [];
    this.visibleDefaultProperties = defaultProperties.filter(
      (p) => p.showInAdditionalInfo
    );
  }

  protected handleCheckboxCheckedChanged(
    event: CheckboxCheckedChangedEvent
  ): void {
    DomEventHelper.fireEvent<CheckboxCheckedChangedEvent>(this.element, {
      name: 'checkbox-checked-changed',
      detail: event.detail
    });
  }

  protected processTaskDeviceChanged(): void {
    if (this.isAttached) {
      this.updateConfigurationDevice();
      this.updateRelatedEntityCountsAdapter();
    }
  }

  private updateConfigurationDevice(): void {
    if (this.processTaskDevice) {
      this.configurationDevice =
        this.entityManager.processConfigurationDeviceRepository.getById(
          this.processTaskDevice.processConfigurationDeviceId
        );
    } else {
      this.configurationDevice = null;
    }
  }

  private updateRelatedEntityCountsAdapter(): void {
    if (this.processTaskDevice) {
      this.relatedEntityCountsAdapter = new ProcessTaskDeviceAdapter({
        entityManager: this.entityManager,
        computedValueService: this.computedValueService,
        subscriptionManagerService: this.subscriptionManagerService,
        processTaskDevice: this.processTaskDevice
      });
    } else {
      this.relatedEntityCountsAdapter = null;
    }
  }

  protected handleEditButtonClick(): void {
    if (!this.element) {
      return;
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected handleDeleteButtonClick(): void {
    if (!this.processTaskDevice) {
      return;
    }

    void Dialogs.deleteEntityDialog(this.processTaskDevice).then(() => {
      if (this.processTaskDevice) {
        this.entityManager.processTaskDeviceRepository.delete(
          this.processTaskDevice
        );
        void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
          entityName: EntityName.ProcessTaskDevice,
          entity: this.processTaskDevice,
          displayNameAtLogTime: null
        });
      }
    });
  }

  protected getBottomRowText(
    dailyCost: ProcessTaskDevice['dailyCost'],
    dateFrom: ProcessTaskDevice['dateFrom'],
    dateTo: ProcessTaskDevice['dateTo'],
    noteInternal: ProcessTaskDevice['noteInternal'],
    visibleDefaultProperties: Array<DeviceDefaultPropertyConfig>,
    properties: Array<ProcessTaskDeviceProperty>
  ): string {
    const parts: Array<string> = [];

    properties
      .filter((p) => visibleDefaultProperties.find((dp) => dp.name === p.name))
      .forEach((p) => {
        const text = PropertyHelper.getPropertyText(
          p.type || PropertyType.TEXT,
          p.name,
          p.value,
          p.custom_choice
        );
        parts.push(`${p.name}: ${text || '-'}`);
      });

    if (dailyCost) {
      parts.push(
        this.roundedNumberValueConverter.toView(dailyCost) +
          this.i18n.tr(
            'modelsDetail.ProcessConfigurationDeviceModel.dailyCostUnit'
          )
      );
    }

    const dateRange = this.dateRangeValueConverter.toView({
      fromIso: dateFrom,
      toIso: dateTo
    });
    if (dateRange) {
      parts.push(dateRange);
    }

    if (noteInternal) {
      parts.push(noteInternal);
    }

    return parts.join(' — ');
  }
}
