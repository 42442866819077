import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from 'common/Asserts';

export class SharepointHelper {
  public static getSharepointExportUrlForThingId({
    entityManager,
    thingId
  }: {
    entityManager: AppEntityManager;
    thingId: string;
  }): string {
    const thing = entityManager.thingRepository.getById(thingId);
    assertNotNullOrUndefined(thing, 'thing not found');

    const userGroup = entityManager.userGroupRepository.getRequiredById(
      thing.ownerUserGroupId
    );

    assertNotNullOrUndefined(
      userGroup.sharepointCredentials?.sharepointExportSite,
      'cannot get sharepoint url without a sharepoint export site'
    );

    return PathUtils.joinPaths(
      PathUtils.mergeOverlappingPaths(
        userGroup.sharepointCredentials.sharepointExportSite,
        userGroup.sharepointCredentials.sharepointExportPath ?? '',
        { caseInsensitive: true }
      ),
      thing.name ?? thing.id
    );
  }
}
