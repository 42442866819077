import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { InlineSvgLoaderSvgCopier } from '../classes/Svg/InlineSvgLoader';

@autoinject()
export class LoadSvgCustomAttribute {
  @bindable public svgText: string | null = null;

  @bindable public onLoaded: (() => void) | null = null;

  private domElement: SVGSVGElement;

  constructor(element: Element) {
    this.domElement = element as SVGSVGElement;
  }

  protected svgTextChanged(): void {
    if (!this.svgText) {
      this.domElement.innerHTML = '';
      return;
    }

    const sourceSvgElement = document.createElement('DIV') as HTMLDivElement;
    sourceSvgElement.innerHTML = this.svgText;

    const svgElement = sourceSvgElement.firstChild;
    assertNotNullOrUndefined(
      svgElement,
      'svg element container has no first child'
    );

    const svgCopier = new InlineSvgLoaderSvgCopier(
      svgElement as SVGSVGElement,
      this.domElement
    );
    svgCopier.copy();

    this.onLoaded?.();
  }
}
