import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { FullScreenOverlay } from '../full-screen-overlay/full-screen-overlay';
import { GlobalElements } from '../global-elements/global-elements';
import { ZoomBoxPictureResizer } from '../zoom-box/ZoomBoxPictureResizer';

/**
 * this is meant to be a single global instance
 */
@autoinject()
export class PictureFullScreenOverlay {
  public static async open(
    options: PictureFullScreenOverlayOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected options: PictureFullScreenOverlayOpenOptions | null = null;

  protected contentRendered: boolean = false;
  private fullScreenOverlay: FullScreenOverlay | null = null;
  private readonly zoomBoxElement: HTMLElement | null = null;
  private pictureElement: HTMLElement | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;

  constructor(private readonly i18n: I18N) {}

  public open(options: PictureFullScreenOverlayOpenOptions): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't PictureFullScreenOverlay.open without fullScreenOverlay"
    );

    this.options = {
      ...{
        noPictureText: this.i18n.tr('general.noPictureAvailable') as string
      },
      ...options
    };
    this.fullScreenOverlay.open();
    this.contentRendered = true;

    this.pictureResizer?.destroy();
  }

  public close(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't PictureFullScreenOverlay.close without fullScreenOverlay"
    );

    this.fullScreenOverlay.close();
  }

  protected handleContentAttached(): void {
    if (this.contentRendered) {
      assertNotNullOrUndefined(
        this.pictureElement,
        "can't PictureFullScreenOverlay.handleContentAttached without pictureElement"
      );
      assertNotNullOrUndefined(
        this.zoomBoxElement,
        "can't PictureFullScreenOverlay.handleContentAttached without zoomBoxElement"
      );
      this.pictureResizer = new ZoomBoxPictureResizer(
        this.pictureElement,
        this.zoomBoxElement
      );
      this.pictureResizer.update();
    }
  }

  protected handleFullScreenOverlayClosed(): void {
    this.options = null;
    this.contentRendered = false;

    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  protected handleCloseButtonClick(): void {
    this.close();
  }
}

export type PictureFullScreenOverlayOpenOptions = {
  picture: Picture;
  noPictureText?: string | null;
};
