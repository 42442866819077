import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { ProcessTaskOfferPartialExtractor } from './ProcessTaskOfferPartialExtractor/ProcessTaskOfferPartialExtractor';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskUtils } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTaskAppointmentDateInfoMapComputer } from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskCreationService } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskCreationService';

/**
 * this dialog enables the user to extract a processTaskOffer into a new processTask
 */
@inject(
  I18N,
  Router,
  AppEntityManager,
  ComputedValueService,
  ProcessTaskCreationService
)
export class ExtractProcessTaskOfferDialog {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskOffer/types').ProcessTaskOffer|null} */
  _offer = null;
  /** @type {(function(Array<import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask>): void)|null} */
  _onDialogClosed = null;
  /** @type {string|null} */
  _warningTextTk = null;
  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /** @type {string|null} */
  _newProcessTaskName = null;

  /** @type {Array<ProcessTaskOfferPartialExtractor>} */
  _extractors = [];

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask>} */
  _extractedProcessTasks = [];

  /** @type {number} */
  _errorCount = 0;

  _SequenceNumberUtils = SequenceNumberUtils;
  _SequenceNumberType = SequenceNumberType;

  /**
   * @param {I18N} i18n
   * @param {Router} router
   * @param {AppEntityManager} entityManager
   * @param {ComputedValueService} computedValueService
   * @param {ProcessTaskCreationService} processTaskCreationService
   */
  constructor(
    i18n,
    router,
    entityManager,
    computedValueService,
    processTaskCreationService
  ) {
    this._i18n = i18n;
    this._router = router;
    this._entityManager = entityManager;
    this._computedValueService = computedValueService;
    this._processTaskCreationService = processTaskCreationService;
  }

  /**
   * @param {TOpenOptions} options
   */
  open(options) {
    this._offer = options.offer;
    this._onDialogClosed = options.onDialogClosed ?? null;
    this._warningTextTk = options.warningTextTk ?? null;

    if (this._dialog) {
      this._dialog.open();
    }

    this._computedValueService.subscribeOnce({
      valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
      computeData: {},
      callback: (processTaskAppointmentDateInfoMap) => {
        this._setupExtractors(processTaskAppointmentDateInfoMap);
        this._validate();
      }
    });
    this._updateNewProcessTaskName();
  }

  _handleDialogClosed() {
    const onDialogClosed = this._onDialogClosed;
    const extractedProcessTasks = this._extractedProcessTasks;

    this._offer = null;
    this._onDialogClosed = null;
    this._extractedProcessTasks = [];
    this._warningTextTk = null;

    onDialogClosed && onDialogClosed(extractedProcessTasks);
  }

  _handleCancelClick() {
    if (this._dialog) {
      this._dialog.close();
    }
  }

  _handleExtractClick() {
    this._extractedProcessTasks = this._extractors.map((e) =>
      e.extract(this._newProcessTaskName ?? '')
    );
    const firstExtractedProcessTask = this._extractedProcessTasks[0];
    if (firstExtractedProcessTask) {
      ProcessTaskUtils.navigateToEditProcessTaskPage(
        this._router,
        firstExtractedProcessTask.id
      );
      ScrollHelper.scrollToPosition(0); // scroll to top so the user can notice that we automatically navigated to the new task
    }

    if (this._dialog) {
      this._dialog.close();
    }

    NotificationHelper.notifySuccess(
      this._i18n.tr('operations.extractProcessTaskOfferDialog.successDialog')
    );
  }

  /**
   * @param {import('../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer').ProcessTaskAppointmentDateInfoMap} processTaskAppointmentDateInfoMap
   */
  _setupExtractors(processTaskAppointmentDateInfoMap) {
    const offer = this._offer;
    assertNotNullOrUndefined(
      offer,
      "can' ExtractProcessTaskOfferDialog._setupExtractors without _offer"
    );

    const relations =
      this._entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferIds(
        [offer.id]
      );

    // sort the relations by the natural order of the processTasks, so everything is handled the same as everywhere else
    const processTaskIds = this._entityManager.processTaskRepository
      .getByProcessTaskGroupId(offer.ownerProcessTaskGroupId)
      .map((p) => p.id);
    relations.sort((a, b) => {
      return (
        processTaskIds.indexOf(a.ownerProcessTaskId) -
        processTaskIds.indexOf(b.ownerProcessTaskId)
      );
    });

    this._extractors = relations.map((r) => {
      const processTask = this._entityManager.processTaskRepository.getById(
        r.ownerProcessTaskId
      );
      assertNotNullOrUndefined(
        processTask,
        `processTask "${r.ownerProcessTaskId}" not found`
      );
      return new ProcessTaskOfferPartialExtractor(
        offer,
        r,
        processTask,
        this._entityManager,
        this._processTaskCreationService,
        processTaskAppointmentDateInfoMap
      );
    });
  }

  _validate() {
    /** @type {Array<ValidationErrorGroup>} */
    const groups = [];

    for (const extractor of this._extractors) {
      const validationErrors = extractor.validate();

      if (validationErrors.length > 0) {
        groups.push({
          processTask: extractor.getProcessTask(),
          validationErrors
        });
      }
    }

    this._validationErrorGroups = groups;
  }

  _updateNewProcessTaskName() {
    if (this._offer) {
      const category = this._offer.processConfigurationCategoryId
        ? this._entityManager.processConfigurationCategoryRepository.getById(
            this._offer.processConfigurationCategoryId
          )
        : null;
      this._newProcessTaskName = category ? category.name : null;
    } else {
      this._newProcessTaskName = null;
    }
  }

  /**
   * @param {Array<ProcessTaskOfferPartialExtractor>} extractors
   * @returns {number}
   */
  _getProcessTaskPositionsCount(extractors) {
    return extractors.reduce(
      (sum, extractor) => sum + extractor.getProcessTaskPositionCount(),
      0
    );
  }

  /**
   * @param {Array<ProcessTaskOfferPartialExtractor>} extractors
   * @returns {number}
   */
  _getProcessTaskDevicesCount(extractors) {
    return extractors.reduce(
      (sum, extractor) => sum + extractor.getProcessTaskDevicesCount(),
      0
    );
  }

  /**
   * @param {TOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TOpenOptions
 * @property {import('../../classes/EntityManager/entities/ProcessTaskOffer/types').ProcessTaskOffer} offer
 * @property {string|null} [warningTextTk] - additional info text to display (e.g. reason for the extraction)
 * @property {(function(Array<import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask>): void)|null} [onDialogClosed] - first param = extractedProcessTasks
 */

/**
 * @typedef {Object} ValidationErrorGroup
 * @property {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask} processTask
 * @property {Array<import('./ProcessTaskOfferPartialExtractor/ProcessTaskOfferPartialExtractor').ValidationError>} validationErrors
 */
