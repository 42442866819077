import { autoinject } from 'aurelia-dependency-injection';
import { isEqual } from 'lodash';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer extends ValueComputer<
  ComputeData,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToUser,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskAppointmentToUsersByProcessTaskAppointmentId {
    const relations =
      this.entityManager.processTaskAppointmentToUserRepository.getAll();
    return Utils.groupBy(
      relations,
      (relation) => relation.processTaskAppointmentId
    );
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ComputeData = {};
export type ProcessTaskAppointmentToUsersByProcessTaskAppointmentId = Map<
  string,
  Array<ProcessTaskAppointmentToUser>
>;
