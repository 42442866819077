import { autoinject } from 'aurelia-framework';
import { IProcessConfigurationDeviceGroupingConfiguration } from 'common/Types/ProcessConfigurationDeviceGroupingConfiguration';
import {
  GroupConfiguration,
  GroupConfigurationType
} from '../../../classes/EntityGrouper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class DeviceGroupConfigurationsByProcessTaskIdComputer extends ValueComputer<
  ComputeData,
  DeviceGroupConfigurationsByProcessTaskId
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): DeviceGroupConfigurationsByProcessTaskId {
    const groupConfigurationByProcessConfigurationId =
      this.getGroupConfigurationByProcessConfigurationId();
    const groupConfigurationsByProcessTaskGroupId =
      this.getGroupConfigurationsByProcessTaskGroupId(
        groupConfigurationByProcessConfigurationId
      );

    return this.getGroupConfigurationsByProcessTaskId(
      groupConfigurationsByProcessTaskGroupId
    );
  }

  private getGroupConfigurationByProcessConfigurationId(): Map<
    string,
    Array<GroupConfiguration>
  > {
    const groupConfigurationByProcessConfigurationId = new Map<
      string,
      Array<GroupConfiguration>
    >();
    const processConfigurations =
      this.entityManager.processConfigurationRepository.getAll();
    for (const processConfiguration of processConfigurations) {
      const groupConfigurationJson = processConfiguration
        ? processConfiguration.deviceGroupingConfigurationJson
        : null;
      const groupConfiguration: IProcessConfigurationDeviceGroupingConfiguration | null =
        groupConfigurationJson ? JSON.parse(groupConfigurationJson) : null;
      const groupConfigurations = groupConfiguration
        ? groupConfiguration.groupConfigurations
        : [];

      const configs: Array<GroupConfiguration> = [];
      for (const groupConfig of groupConfigurations) {
        if (groupConfig.type === 'property') {
          configs.push({
            type: GroupConfigurationType.Property,
            value: groupConfig.value
          });
        } else {
          console.error(
            `unsupported groupConfig type "${groupConfig.type}"`,
            groupConfig
          );
        }
      }
      groupConfigurationByProcessConfigurationId.set(
        processConfiguration.id,
        configs
      );
    }
    return groupConfigurationByProcessConfigurationId;
  }

  private getGroupConfigurationsByProcessTaskGroupId(
    groupConfigurationByProcessConfigurationId: Map<
      string,
      Array<GroupConfiguration>
    >
  ): Map<string, Array<GroupConfiguration>> {
    const groupConfigurationsByProcessTaskGroupId = new Map<
      string,
      Array<GroupConfiguration>
    >();
    const processTaskGroups =
      this.entityManager.processTaskGroupRepository.getAll();
    for (const processTaskGroup of processTaskGroups) {
      groupConfigurationsByProcessTaskGroupId.set(
        processTaskGroup.id,
        groupConfigurationByProcessConfigurationId.get(
          processTaskGroup.processConfigurationId
        ) ?? []
      );
    }
    return groupConfigurationsByProcessTaskGroupId;
  }

  private getGroupConfigurationsByProcessTaskId(
    groupConfigurationsByProcessTaskGroupId: Map<
      string,
      Array<GroupConfiguration>
    >
  ): DeviceGroupConfigurationsByProcessTaskId {
    const groupConfigurationsByProcessTaskId = new Map<
      string,
      Array<GroupConfiguration>
    >();
    const processTasks = this.entityManager.processTaskRepository.getAll();
    for (const processTask of processTasks) {
      groupConfigurationsByProcessTaskId.set(
        processTask.id,
        groupConfigurationsByProcessTaskGroupId.get(
          processTask.ownerProcessTaskGroupId
        ) ?? []
      );
    }
    return groupConfigurationsByProcessTaskId;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ComputeData = Record<string, never>;
export type DeviceGroupConfigurationsByProcessTaskId = Map<
  string,
  Array<GroupConfiguration>
>;
