import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { OperationsExpressionEditorScope } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsExpressionEditorScope';
import { ExpressionEditorScopeEvaluationUtils } from 'common/ExpressionEditorScope/ExpressionEditorScopeEvaluationUtils';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';
import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessConfigurationStepBarDimension } from '../process-configuration-step-bar/ProcessConfigurationStepBarDimensionCalculator';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { Utils } from '../../classes/Utils/Utils';
import { ProcessTaskGroupsTableViewProcessTask } from './process-task-groups-table-view-process-task';
import { OperationsDataFetcher } from '../../classes/Operations/OperationsDataFetcher';

/**
 * this is just a sub-component of the process-task-groups-table-view
 * it should never be used on it's own
 * it also borrows all of it's styles from the process-task-groups-table-view
 */
@autoinject()
export class ProcessTaskGroupsTableViewProcessTaskGroup {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public stepBarDimension: ProcessConfigurationStepBarDimension | null = null;

  @bindable()
  public processConfigurationSteps: Array<ProcessConfigurationStep> = [];

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  protected availableProcessTasks: Array<ProcessTask> = [];
  protected groupedProcessTasks: Array<TGroup> = [];
  protected title = '';
  private expanded: boolean = true;
  private clickEventToIgnore: MouseEvent | null = null;

  protected readonly ProcessTaskGroupUtils = ProcessTaskGroupUtils;

  private readonly operationsExpressionEditor: OperationsExpressionEditorScope<
    string,
    string
  >;
  private readonly exprParser: ExprEvalParser;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.operationsExpressionEditor = new OperationsExpressionEditorScope(
      new OperationsDataFetcher(entityManager)
    );
    this.exprParser = new ExprEvalParser();
  }

  public scrollToProcessTask({
    processTask
  }: {
    processTask: ProcessTask;
  }): void {
    const processTaskElement = this.element.querySelector(
      `#${this.getProcessTaskElementId(processTask.id)}`
    ) as HTMLElement | null;
    assertNotNullOrUndefined(
      processTaskElement,
      `no processTaskElement found for id "${processTask.id}"`
    );

    const viewModel =
      Utils.getRequiredViewModelOfElement<ProcessTaskGroupsTableViewProcessTask>(
        processTaskElement
      );
    viewModel.scrollToElement();
  }

  protected attached(): void {
    this.isAttached = true;
    const updateTitle = (): void => {
      void this.updateTitle();
    };

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateAvailableProcessTasks.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      updateTitle
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateTitle
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateTitle
    );
    this.updateAvailableProcessTasks();
    updateTitle();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTasks();
      void this.updateTitle();
    }
  }

  protected updateAvailableProcessTasks(): void {
    let processTasks: Array<ProcessTask> = [];
    if (this.processTaskGroup) {
      processTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskGroup.id
        );
    }

    this.availableProcessTasks = processTasks;
    this.groupedProcessTasks = this.groupProcessTasks(processTasks);
  }

  protected handleProcessTaskGroupTitleClick(event: MouseEvent): void {
    if (event !== this.clickEventToIgnore) {
      this.expanded = !this.expanded;
    }
  }

  protected handleInfoTextClick(event: MouseEvent): void {
    this.clickEventToIgnore = event;
  }

  protected getUserNameById(userId: string | null): string | null {
    const user = userId
      ? this.entityManager.userRepository.getById(userId)
      : null;
    return user ? user.username : null;
  }

  private groupProcessTasks(processTasks: Array<ProcessTask>): Array<TGroup> {
    const groups: Array<TGroup> = [];

    processTasks.forEach((processTask) => {
      const group = groups.find((g) => g.thingId === processTask.thingId);
      if (group) {
        group.processTasks.push(processTask);
      } else {
        groups.push({
          thingId: processTask.thingId,
          processTasks: [processTask]
        });
      }
    });

    return groups;
  }

  private async updateTitle(): Promise<void> {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot get text without processTaskGroup'
    );

    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        this.processTaskGroup.processConfigurationId
      );
    const titleConfigExpr =
      processConfiguration?.configurableDisplayText
        ?.processTaskGroupOverviewProcessTaskGroupTitle;
    if (titleConfigExpr) {
      this.title = await this.evaluateConfig(titleConfigExpr);
      return;
    }
    const thingGroup = this.entityManager.thingGroupRepository.getById(
      this.processTaskGroup.thingGroupId
    );
    assertNotNullOrUndefined(
      thingGroup,
      'cannot get text without thing group ' +
        this.processTaskGroup.thingGroupId
    );
    this.title = ThingGroupHelper.getThingGroupAddressString(
      thingGroup.streetName,
      thingGroup.zip,
      thingGroup.municipality
    );
  }

  private async evaluateConfig(expr: string): Promise<string> {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot get text without processTaskGroup'
    );

    const processTasks = BaseEntityUtils.sortByCreationOrder(
      this.entityManager.processTaskRepository.getByProcessTaskGroupId(
        this.processTaskGroup.id
      )
    );
    const firstProcessTask = processTasks[0] ?? null;

    const fieldConfigs =
      await this.operationsExpressionEditor.createFieldConfigsForProcessTaskGroupScope(
        {
          currentProcessTaskGroup: {
            id: this.processTaskGroup.id
          },
          currentThingGroup: {
            id: this.processTaskGroup.thingGroupId
          },
          firstProcessTask: firstProcessTask
            ? {
                id: firstProcessTask.id
              }
            : null
        }
      );
    const dataToSet =
      await ExpressionEditorScopeEvaluationUtils.convertToPlainObject(
        fieldConfigs
      );
    return this.exprParser.evaluateExpression(expr, dataToSet).toString();
  }

  private getProcessTaskElementId(processTaskId: string): string {
    return (
      'process-task-groups-table-view-proces-task-group--ProcessTask-' +
      processTaskId
    );
  }
}

type TGroup = {
  thingId: string;
  processTasks: Array<ProcessTask>;
};
