import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { FullScreenContent } from '../../aureliaComponents/full-screen-content/full-screen-content';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';

/**
 * Similar to the normal RecordItDialog, but instead of showing
 * a nice grey backdrop and a small window, this dialog takes the
 * whole screen by default.
 * Also displays a "Close Dialog" by default.
 *
 * @slot topbar-left - displayed on the left side of the topbar.
 * @slot topbar-right - displayed on the right side of the topbar. Already includes the "Close Dialog" button.
 * @slot default - slot for content.
 * @slot bottom-content - slot for a bottom bar, e.g. for the picture-scroller.
 * @event {RecordItFullScreenDialogClosed} record-it-full-screen-dialog-closed
 */
@autoinject()
export class RecordItFullScreenDialog {
  @bindable public animate: boolean = true;

  /**
   * allows to set the data-test-selector of the dialog container
   */
  @bindable public testSelector: string | null = null;

  protected showTopbarShadow: boolean = false;

  private showHideAnimator: ShowHideAnimator | null = null;

  private fullScreenContent: FullScreenContent | null = null;
  private fullScreenContentElement: HTMLElement | null = null;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.fullScreenContentElement,
      'the fullScreenContentElement is not bound'
    );
    this.showHideAnimator = new ShowHideAnimator(this.fullScreenContentElement);
  }

  protected detached(): void {
    this.closeWithoutAnimation();
  }

  public open(): void {
    assertNotNullOrUndefined(
      this.fullScreenContent,
      'cannot open RecordItFullScreenDialog if fullScreenContent is null'
    );
    assertNotNullOrUndefined(
      this.showHideAnimator,
      'cannot open RecordItFullScreenDialog if showHideAnimator is null'
    );
    SiteScrollLocker.lockScrolling('record-it-fullscreen-dialog');
    this.fullScreenContent.open();
    void this.showHideAnimator.fadeIn();
  }

  public close(): void {
    assertNotNullOrUndefined(
      this.showHideAnimator,
      'cannot close RecordItFullScreenDialog if showHideAnimator is null'
    );
    void this.showHideAnimator.fadeOut().then(() => {
      this.handleCloseAnimationFinished();
    });
  }

  public closeWithoutAnimation(): void {
    this.handleCloseAnimationFinished();
  }

  private handleCloseAnimationFinished(): void {
    assertNotNullOrUndefined(
      this.fullScreenContent,
      "can't RecordItFullScreenDialog.handleCloseAnimationFinished without fullScreenContent"
    );
    SiteScrollLocker.unlockScrolling('record-it-fullscreen-dialog');

    this.fullScreenContent.close();

    DomEventHelper.fireEvent<RecordItFullScreenDialogClosed>(this.element, {
      name: 'record-it-full-screen-dialog-closed',
      detail: null
    });
  }

  private checkIfTopbarNeedsShadow(scrollTop: number): void {
    this.showTopbarShadow = scrollTop > 0;
  }

  protected handleCloseButtonClicked(): void {
    this.close();
  }

  protected handleContentScrolled(evt: Event): void {
    this.checkIfTopbarNeedsShadow((evt.target as any).scrollTop);
  }
}

export type RecordItFullScreenDialogClosed =
  NamedCustomEvent<'record-it-full-screen-dialog-closed'>;
