import { autoinject, bindable } from 'aurelia-framework';
import { CustomPositionTypeConfiguration } from 'common/Types/ProcessConfigurationCustomPositionTypeConfiguration';

@autoinject()
export class ProcessTaskPositionCustomTypeIcon {
  @bindable()
  public typeConfig: CustomPositionTypeConfiguration<string> | null | null =
    null;

  protected getIconStyle(
    typeConfig: CustomPositionTypeConfiguration<string> | null
  ): Record<string, any> {
    return {
      color: typeConfig?.listItemIcon.color ?? 'black'
    };
  }
}
