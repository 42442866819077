import { computedFrom } from 'aurelia-binding';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { EntryPicture } from '../../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { PictureCreatorService } from '../../../classes/Picture/PictureCreatorService';
import { Disposable } from '../../../classes/Utils/DisposableContainer';

export class TemporaryBaseEntities implements Disposable {
  private static readonly TEMPORARY_GROUP_BASE_NAME =
    'EntityCreator.temporaryGroupName-';
  private static LAST_ID: number = 0;

  private readonly temporaryGroupName: string;
  private readonly entityManager: AppEntityManager;
  private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService;
  private readonly currentUserService: CurrentUserService;

  private readonly internalEntry: Entry;
  private readonly internalPicture: EntryPicture | null;

  constructor(options: TemporaryBaseEntitiesOptions) {
    this.temporaryGroupName =
      TemporaryBaseEntities.TEMPORARY_GROUP_BASE_NAME +
      ++TemporaryBaseEntities.LAST_ID;
    this.entityManager = options.entityManager;
    this.savePictureFileDataUrlService = options.savePictureFileDataUrlService;
    this.currentUserService = options.currentUserService;

    const { entry, picture } = this.setupTemporaryEntities({
      project: options.project,
      pictureCreatorService: options.pictureCreatorService,
      hasPicture: options.hasPicture
    });

    this.internalEntry = entry;
    this.internalPicture = picture;
  }

  @computedFrom('internalEntry')
  public get entry(): Entry {
    return this.internalEntry;
  }

  @computedFrom('internalPicture')
  public get picture(): EntryPicture | null {
    return this.internalPicture;
  }

  public createEntities(
    options: TemporaryBaseEntitiesCreateEntitiesOptions
  ): void {
    if (this.internalPicture && !options.originalDataUrl) {
      throw new Error(
        "can't create entities without an originalDataUrl, because the TemporaryBaseEntities got created with hasPicture"
      );
    }

    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      this.temporaryGroupName
    );

    this.makePathPermanent({
      entryPath: options.entryPath
    });

    this.markPathAsUsed({
      entryPath: options.entryPath
    });

    this.setParentIdOfEntry({
      entryPath: options.entryPath
    });

    if (this.internalPicture) {
      this.createPictureFiles({
        picture: this.internalPicture,
        originalDataUrl: options.originalDataUrl as string, // must be a string here, since at the beginning of a function we check that.
        editedDataUrl: options.editedDataUrl,
        sketchDataUrl: options.sketchDataUrl
      });
    }
  }

  public dispose(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      this.temporaryGroupName
    );
  }

  private markPathAsUsed({ entryPath }: { entryPath: Array<Entry> }): void {
    for (const entry of entryPath) {
      this.entityManager.entryUsageStatisticRepository.incrementUseCount(
        entry.id,
        this.currentUserService.getRequiredCurrentUser().id,
        {
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId
        }
      );
    }
  }

  private makePathPermanent({ entryPath }: { entryPath: Array<Entry> }): void {
    this.entityManager.entryRepository.createShadowEntitiesInPath(entryPath);
  }

  private setParentIdOfEntry({ entryPath }: { entryPath: Array<Entry> }): void {
    const lastEntry = entryPath.at(-1);

    if (lastEntry) {
      this.entityManager.entryRepository.setParentIdOfEntry(
        this.entry,
        lastEntry.id
      );
    }
  }

  private createPictureFiles({
    picture,
    originalDataUrl,
    editedDataUrl,
    sketchDataUrl
  }: {
    picture: EntryPicture;
    originalDataUrl: string;
    editedDataUrl: string | null;
    sketchDataUrl: string | null;
  }): void {
    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      originalDataUrl
    );

    if (editedDataUrl) {
      this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
        picture,
        editedDataUrl
      );
    }

    if (sketchDataUrl) {
      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        sketchDataUrl
      );
    }
  }

  private setupTemporaryEntities({
    project,
    pictureCreatorService,
    hasPicture
  }: {
    project: Project;
    pictureCreatorService: PictureCreatorService;
    hasPicture: boolean;
  }): { entry: Entry; picture: EntryPicture | null } {
    const entry = this.entityManager.entryRepository.create({
      ownerUserGroupId: project.ownerUserGroupId,
      ownerProjectId: project.id,
      project: project.id,

      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: true
    });

    let picture: EntryPicture | null = null;

    if (hasPicture) {
      picture = pictureCreatorService
        .withEntityInfos(() => ({
          mainEntityIdField: 'project',
          mainEntityId: project.id,
          subEntityField: 'entry',
          subEntityValue: entry.id,
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId
        }))
        .createPicture({
          selected: true, // its the first picture in the entry, so automatically select it
          additional_markings: [],
          temporaryGroupName: this.temporaryGroupName,
          shadowEntity: true
        }) as EntryPicture;
    }

    return { entry, picture };
  }
}

export type TemporaryBaseEntitiesOptions = {
  project: Project;
  hasPicture: boolean;
  entityManager: AppEntityManager;
  pictureCreatorService: PictureCreatorService;
  savePictureFileDataUrlService: SavePictureFileDataUrlService;
  currentUserService: CurrentUserService;
};

export type TemporaryBaseEntitiesCreateEntitiesOptions = {
  /**
   * this must be a string, if the TemporaryBaseEntities where created with hasPicture
   */
  originalDataUrl: string | null;
  editedDataUrl: string | null;
  sketchDataUrl: string | null;
  entryPath: Array<Entry>;
};
