import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import {
  ProcessConfigurationStepPosition,
  ProcessConfigurationStepPositionCreationEntity
} from './types';
import { ProcessConfigurationStep } from '../ProcessConfigurationStep/types';
import { ProcessConfigurationConfigPositionConfiguration } from 'common/Types/ProcessConfigurationConfigPositionConfiguration';

@autoinject()
export class ProcessConfigurationStepPositionCreationService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public create({
    processConfigurationStep,
    positionOverrides
  }: {
    processConfigurationStep: ProcessConfigurationStep;
    positionOverrides?: Omit<
      ProcessConfigurationStepPositionCreationEntity,
      | 'processConfigurationStepId'
      | 'ownerProcessConfigurationId'
      | 'ownerUserGroupId'
    >;
  }): ProcessConfigurationStepPosition {
    const position =
      this.entityManager.processConfigurationStepPositionRepository.create({
        ...positionOverrides,
        processConfigurationStepId: processConfigurationStep.id,
        ownerProcessConfigurationId:
          processConfigurationStep.ownerProcessConfigurationId,
        ownerUserGroupId: processConfigurationStep.ownerUserGroupId
      });

    this.createProperties({ position });

    return position;
  }

  private createProperties({
    position
  }: {
    position: ProcessConfigurationStepPosition;
  }): void {
    const configuration =
      this.entityManager.processConfigurationRepository.getById(
        position.ownerProcessConfigurationId
      );
    const positionConfig: ProcessConfigurationConfigPositionConfiguration | null =
      configuration?.configPositionConfigurationJson
        ? JSON.parse(configuration?.configPositionConfigurationJson)
        : null;
    const propertyConfigs = positionConfig?.defaultProperties ?? [];

    for (const propertyConfig of propertyConfigs) {
      this.entityManager.propertyRepository.create({
        ...propertyConfig,
        processConfigurationStepPositionId: position.id,
        ownerProcessConfigurationId: position.ownerProcessConfigurationId,
        ownerUserGroupId: position.ownerUserGroupId
      });
    }
  }
}
