import { autoinject } from 'aurelia-dependency-injection';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { ProcessTask } from '../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { PictureProcessTaskToProjectType } from '../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectRepository';
import { ProjectCreationService } from '../classes/EntityManager/entities/Project/ProjectCreationService';
import { Project } from '../classes/EntityManager/entities/Project/types';
import {
  PictureCreatorService,
  PictureCreatorServiceWithEntityData
} from '../classes/Picture/PictureCreatorService';

@autoinject()
export class ProcessTaskProjectService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService,
    private readonly pictureCreatorService: PictureCreatorService
  ) {}

  public prepareImageCreation({
    processTask,
    processTaskAppointment,
    processTaskToProjectType
  }: {
    processTask: ProcessTask;
    processTaskAppointment: ProcessTaskAppointment | null;
    processTaskToProjectType: PictureProcessTaskToProjectType | null;
  }): { creator: PictureCreatorServiceWithEntityData; entry: Entry } {
    const project = this.getOrCreateProject(
      processTask,
      processTaskAppointment,
      processTaskToProjectType
    );
    const entry = this.entityManager.entryRepository.create({
      ownerUserGroupId: project.ownerUserGroupId,
      project: project.id,
      ownerProjectId: project.id
    });

    return {
      creator: this.pictureCreatorService.withEntityInfos(() => ({
        mainEntityId: project.id,
        mainEntityIdField: 'project',
        subEntityValue: entry.id,
        subEntityField: 'entry',
        ownerProjectId: project.id,
        ownerUserGroupId: project.ownerUserGroupId
      })),
      entry
    };
  }

  public getOrCreateProject(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment | null,
    processTaskToProjectType: PictureProcessTaskToProjectType | null
  ): Project {
    let project = this.getProject(
      processTask,
      processTaskAppointment,
      processTaskToProjectType
    );

    if (!project) {
      if (processTaskAppointment?.processConfigurationStepAutoAppointmentId) {
        throw new Error(
          'projects for automatically created appointments are not supported'
        );
      }

      const thing = this.entityManager.thingRepository.getRequiredById(
        processTask.thingId
      );
      project = this.projectCreationService.createProject({
        thing,
        name: processTaskAppointment?.name ?? null,
        projectType: ProjectType.OPERATIONS,
        reportType: null
      });

      this.entityManager.processTaskToProjectRepository.create({
        projectId: project.id,
        type: processTaskToProjectType,
        processTaskAppointmentId: processTaskAppointment?.id ?? null,
        processConfigurationStepId: this.getProcessConfigurationStepId(
          processTask,
          processTaskAppointment
        ),
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: processTask.id,
        ownerUserGroupId: processTask.ownerUserGroupId,
        temporaryGroupName: processTask.temporaryGroupName
      });
    }

    return project;
  }

  public getProject(
    processTask: ProcessTask | null,
    processTaskAppointment: ProcessTaskAppointment | null,
    processTaskToProjectType: PictureProcessTaskToProjectType | null
  ): Project | null {
    if (!processTask) {
      return null;
    }

    const relation =
      this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndAppointmentId(
        processTask.id,
        processTaskAppointment?.id ?? null,
        processTaskToProjectType
      )[0];

    return relation
      ? this.entityManager.projectRepository.getById(relation.projectId)
      : null;
  }

  public getProcessConfigurationStepId(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment | null
  ): string | null {
    if (!processTaskAppointment) {
      return null; // not appointment specific relation should have not
    }

    if (processTaskAppointment?.processConfigurationStepId) {
      return processTaskAppointment.processConfigurationStepId;
    }

    if (processTask) {
      return processTask.currentProcessConfigurationStepId;
    }

    return null;
  }
}
