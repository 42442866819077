import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { DateUtils } from 'common/DateUtils';
import { DefectCreationService } from '../../../classes/EntityManager/entities/Defect/DefectCreationService';
import { Defect } from '../../../classes/EntityManager/entities/Defect/types';
import { ThingCreationService } from '../../../classes/EntityManager/entities/Thing/ThingCreationService';

/**
 * Creates a new kuk defect without user input.
 *
 * Automatically creates a thing needed for the defect
 * as well.
 */
@autoinject()
export class QuickKukDefectCreationService {
  constructor(
    private readonly thingCreationService: ThingCreationService,
    private readonly defectCreationService: DefectCreationService
  ) {}

  public createDefect(ownerUserGroupId: string): Defect {
    const entityName = DateUtils.formatToDateWithHourMinuteString(new Date());

    // Create necessary thing
    const thing = this.thingCreationService.create({
      name: entityName,
      usergroup: ownerUserGroupId,
      ownerUserGroupId
    });

    // Create Defect
    return this.defectCreationService.createDefect({
      name: entityName,
      ownerThingId: thing.id,
      ownerUserGroupId
    });
  }
}
