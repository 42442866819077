import { autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import {
  PictureDisplayInfo,
  PictureGroup,
  SelectPictureDialogAdapter
} from './SelectPictureDialogAdapter/SelectPictureDialogAdapter/SelectPictureDialogAdapter';

@autoinject()
export class SelectPictureDialog {
  private adapter: SelectPictureDialogAdapter | null = null;
  private onPictureSelected: SelectPictureDialogOnPictureSelected | null = null;

  protected dialog: RecordItDialog | null = null;
  protected pictureGroups: Array<PictureGroup> = [];

  private readonly subscriptionManager: SubscriptionManager;

  public static async open(
    options: SelectPictureDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: SelectPictureDialogOpenOptions): void {
    this.adapter = options.adapter;
    this.onPictureSelected = options.onPictureSelected;

    this.subscriptionManager.addDisposable(
      this.adapter.subscribe({
        setPictureGroups: (pictureGroups) => {
          this.handleNewPictureGroups({ pictureGroups });
        }
      })
    );
  }

  public close(): void {
    assertNotNullOrUndefined(this.dialog, 'cannot close dialog without dialog');

    this.reset();
    this.dialog.close();
  }

  private handleNewPictureGroups({
    pictureGroups
  }: {
    pictureGroups: Array<PictureGroup>;
  }): void {
    const dialog = this.dialog;
    assertNotNullOrUndefined(
      dialog,
      'cannot handleNewPictureGroups without dialog!'
    );
    assertNotNullOrUndefined(
      this.onPictureSelected,
      'cannot handleNewPictureGroups without onPictureSelected!'
    );

    const flatPictureDisplayInfos = pictureGroups.reduce<
      Array<PictureDisplayInfo>
    >((sum, group) => {
      return sum.concat(group.pictureDisplayInfos);
    }, []);

    const firstEnabledDisplayInfo = flatPictureDisplayInfos.find(
      (info) => info.enabled
    );
    if (firstEnabledDisplayInfo && flatPictureDisplayInfos.length === 1) {
      this.onPictureSelected({
        picture: firstEnabledDisplayInfo.picture,
        relatedPictures: firstEnabledDisplayInfo.relatedPictures
      });

      // close asynchronously so subscriptions can be registered completely before disposing them
      setTimeout(() => {
        this.reset();
        if (dialog.isOpen()) {
          this.close();
        }
      }, 0);
    } else {
      this.pictureGroups = pictureGroups;

      if (!dialog.isOpen()) {
        dialog.open();
      }
    }
  }

  protected handlePictureClick(info: PictureDisplayInfo): void {
    if (!info.enabled) {
      return;
    }

    const adapter = this.adapter;
    assertNotNullOrUndefined(
      adapter,
      'cannot handlePictureClick without adapter'
    );
    assertNotNullOrUndefined(
      this.onPictureSelected,
      'cannot handlePictureClick without onPictureSelected'
    );

    this.onPictureSelected({
      picture: info.picture,
      relatedPictures: info.relatedPictures
    });

    this.close();
    void adapter.afterPictureSelected({
      picture: info.picture
    });
  }

  protected handleDialogClosed(): void {
    this.reset();
  }

  private reset(): void {
    this.adapter = null;
    this.onPictureSelected = null;
    this.subscriptionManager.disposeSubscriptions();
  }
}

export type SelectPictureDialogOpenOptions = {
  adapter: SelectPictureDialogAdapter;
  onPictureSelected: SelectPictureDialogOnPictureSelected;
};

export type SelectPictureDialogFilterFunction = (
  pictures: Array<Picture>
) => Array<Picture>;
export type SelectPictureDialogOnPictureSelected = (options: {
  picture: Picture;
  relatedPictures: Array<Picture>;
}) => void;
