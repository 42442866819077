import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { AppEntityManager } from '../AppEntityManager';
import { PictureCopyService } from '../Picture/PictureCopyService';
import { Property, PropertyCreationEntity } from './types';

@autoinject()
export class PropertyCopyService {
  private readonly unsupportedTypes = [
    PropertyType.TABLE,
    PropertyType.SIGNATURE
  ];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureCopyService: PictureCopyService
  ) {}

  public copy({
    newProperty,
    propertyToCopy
  }: {
    newProperty: PropertyCreationEntity;
    propertyToCopy: Property;
  }): Property {
    if (
      this.unsupportedTypes.includes(
        PropertyHelper.getTypeOrDefault(propertyToCopy.type)
      )
    ) {
      throw new PropertyTypeNotSupportedError(
        PropertyHelper.getTypeOrDefault(propertyToCopy.type)
      );
    }

    const copiedProperty = this.entityManager.propertyRepository.create({
      ...PropertyHelper.extractValueDataFromProperty(propertyToCopy),
      name: propertyToCopy.name,
      type: propertyToCopy.type,
      choices: propertyToCopy.choices,
      active: propertyToCopy.active,
      alwaysVisible: propertyToCopy.alwaysVisible,
      hidden: propertyToCopy.hidden,
      options: propertyToCopy.options,
      order: propertyToCopy.order,
      shadowEntity: propertyToCopy.shadowEntity,
      temporaryGroupName: propertyToCopy.temporaryGroupName,
      ...newProperty
    });

    this.copyPersonRelations({ copiedProperty, propertyToCopy });
    this.copyPictures({ copiedProperty, propertyToCopy });

    return copiedProperty;
  }

  private copyPersonRelations({
    copiedProperty,
    propertyToCopy
  }: {
    copiedProperty: Property;
    propertyToCopy: Property;
  }): void {
    const relations =
      this.entityManager.propertyToPersonRepository.getByPropertyId(
        propertyToCopy.id
      );

    for (const relation of relations) {
      this.entityManager.propertyToPersonRepository.create({
        personId: relation.personId,
        propertyId: copiedProperty.id,
        ownerUserGroupId: copiedProperty.ownerUserGroupId,
        temporaryGroupName: copiedProperty.temporaryGroupName,
        shadowEntity: copiedProperty.shadowEntity
      });
    }
  }

  private copyPictures({
    copiedProperty,
    propertyToCopy
  }: {
    copiedProperty: Property;
    propertyToCopy: Property;
  }): void {
    const picturesToCopy = this.entityManager.pictureRepository.getByPropertyId(
      propertyToCopy.id
    );

    for (const pictureToCopy of picturesToCopy) {
      void this.pictureCopyService.copy({
        pictureToCopy,
        createPicture: ({ baseData }) => {
          const mainEntityInfo =
            PropertyHelper.getMainEntityInfo(copiedProperty);
          assertNotNullOrUndefined(
            mainEntityInfo,
            `no mainEntityInfo found for property ${copiedProperty.name}`
          );

          return this.entityManager.pictureRepository.createPictureForEntity(
            {
              mainEntityId: mainEntityInfo.id,
              mainEntityIdField: mainEntityInfo.name,
              subEntityValue: copiedProperty.id,
              subEntityField: 'property',
              ownerProjectId: copiedProperty.ownerProjectId,
              ownerUserGroupId: copiedProperty.ownerUserGroupId
            },
            {
              ...baseData,
              shadowEntity: copiedProperty.shadowEntity,
              temporaryGroupName: copiedProperty.temporaryGroupName
            }
          );
        }
      });
    }
  }
}

export class PropertyTypeNotSupportedError extends Error {
  constructor(type: PropertyType) {
    super(`PropertyType "${type}" can\'t be copied because it's not supported`);
  }
}
