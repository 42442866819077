import { bindable, autoinject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';

/**
 * @event edit-action-status-clicked
 */
@autoinject()
export class ProcessTaskInfoOverviewActionStatus {
  @bindable()
  public processTask: ProcessTask | null = null;

  private subscriptionManager: SubscriptionManager;
  private text: string | null = null;
  private domElement: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationActionStatus,
      this.updateText.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTask.processConfigurationActionStatusId || processTask.customActionStatusAbbreviation || processTask.customActionStatusName',
      this.updateText.bind(this)
    );

    this.updateText();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateText(): void {
    if (!this.processTask) {
      this.text = null;
    } else if (this.processTask.processConfigurationActionStatusId) {
      const actionStatus =
        this.entityManager.processConfigurationActionStatusRepository.getById(
          this.processTask.processConfigurationActionStatusId
        );
      if (actionStatus) {
        this.text = this.generateText(
          actionStatus.abbreviation,
          actionStatus.name
        );
      } else {
        this.text = null;
      }
    } else if (
      this.processTask.customActionStatusAbbreviation ||
      this.processTask.customActionStatusName
    ) {
      this.text = this.generateText(
        this.processTask.customActionStatusAbbreviation,
        this.processTask.customActionStatusName
      );
    } else {
      this.text = null;
    }
  }

  private generateText(
    abbreviation: string | null,
    name: string | null
  ): string {
    const parts: Array<string> = [];

    if (abbreviation) {
      parts.push(abbreviation);
    }

    if (name) {
      parts.push(name);
    }

    return parts.join(' - ');
  }

  private handleEditActionStatusClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-action-status-clicked',
      detail: null
    });
  }
}
