import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { ProjectQuestionCatalogueToQuestionSetDto } from './ProjectQuestionCatalogueToQuestionSetDto';

export function createProjectQuestionCatalogueToQuestionSetEntityInfo<
  TEntity extends ProjectQuestionCatalogueToQuestionSetDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProjectQuestionCatalogueToQuestionSetDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProjectQuestionCatalogueToQuestionSet,
  TEntity
> {
  return {
    entityName: EntityName.ProjectQuestionCatalogueToQuestionSet,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProjectQuestionCatalogueToQuestionSetDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createBaseFieldInfos(),
        projectQuestionCatalogueId: {
          name: 'projectQuestionCatalogueId',
          ref: EntityName.ProjectQuestionCatalogue,
          cascadeDeleteLocally: true
        },
        projectQuestionSetId: {
          name: 'projectQuestionSetId',
          ref: EntityName.ProjectQuestionSet,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
