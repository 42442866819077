import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskAppointmentToUserDto } from './ProcessTaskAppointmentToUserDto';

export function createProcessTaskAppointmentToUserEntityInfo<
  TEntity extends ProcessTaskAppointmentToUserDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskAppointmentToUserDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskAppointmentToUser,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskAppointmentToUser,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskAppointmentToUserDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          cascadeDeleteLocally: true,
          ref: EntityName.ProcessTaskAppointment
        },
        userId: {
          name: 'userId',
          cascadeDeleteLocally: true,
          ref: EntityName.User
        },
        dateFrom: {
          name: 'dateFrom',
          defaultValue: null
        },
        dateTo: {
          name: 'dateTo',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
