import { autoinject } from 'aurelia-framework';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { RecordItHeader } from '../record-it-header/record-it-header';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

@autoinject()
export class MainMenuSidebar {
  protected asideElement: HTMLElement | null = null;
  protected backdropElement: HTMLElement | null = null;

  private asideElementAnimator: ShowHideAnimator | null = null;
  private backdropElementAnimator: ShowHideAnimator | null = null;
  protected headerBreadCrumbIsVisible: boolean = false;

  private shown = false;

  private subscriptionManager: SubscriptionManager;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToEvent(
      'record-it-header:bread-crumb-is-visible-changed',
      this.updateHeaderBreadCrumbIsVisible.bind(this)
    );
    this.updateHeaderBreadCrumbIsVisible();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected bind(): void {
    if (!this.asideElement || !this.backdropElement) {
      throw new Error('asideElement or backdropElement is not bound');
    }

    this.asideElementAnimator = new ShowHideAnimator(this.asideElement);
    this.backdropElementAnimator = new ShowHideAnimator(this.backdropElement);
  }

  public show(): void {
    if (!this.asideElementAnimator || !this.backdropElementAnimator) {
      return;
    }

    this.asideElementAnimator.fadeMoveInLeft();
    void this.backdropElementAnimator.fadeIn();
    SiteScrollLocker.lockScrolling('main-menu-sidebar');
    this.shown = true;
  }

  public hide(): void {
    if (!this.asideElementAnimator || !this.backdropElementAnimator) {
      return;
    }

    this.asideElementAnimator.fadeMoveOutLeft();
    void this.backdropElementAnimator.fadeOut();
    SiteScrollLocker.unlockScrolling('main-menu-sidebar');
    this.shown = false;
  }

  public isShown(): boolean {
    return this.shown;
  }

  private updateHeaderBreadCrumbIsVisible(): void {
    this.headerBreadCrumbIsVisible = RecordItHeader.breadCrumbIsVisible();
  }

  protected handleAsideClick(event: MouseEvent): boolean {
    if (event.defaultPrevented || !event.target) {
      return false;
    }

    let currentElement = event.target as HTMLElement;
    // only close the sidebar if an A element has been clicked
    do {
      if (currentElement.tagName === 'A') {
        this.hide();
        break;
      }
    } while ((currentElement = currentElement.parentNode as HTMLElement));

    return true;
  }

  protected handleCloseButtonClick(): void {
    this.hide();
  }
}
