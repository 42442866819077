import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { UserCompanySetting, UserCompanySettingCreationEntity } from './types';

export class UserCompanySettingUtils {
  private constructor() {}

  public static userCompanySettingSortOptions: UserCompanySettingSortOptions = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }
    }
  };

  public static createDefaultUserCompanySetting(): UserCompanySettingCreationEntity {
    return {
      name: '',
      logo: {},
      general: {
        primaryColor: '#f57d00'
      },
      homePage: {},
      mainMenu: {},
      ultraRapidFireWidget: {
        useCameraOverlay: true,
        useUltraRapidFireWidget: false,
        overlayConfiguration: ''
      }
    };
  }
}

export type UserCompanySettingSortOptions = {
  name: UserCompanySettingSortOption;
};

export type UserCompanySettingSortOption = SorterSortOption<UserCompanySetting>;
