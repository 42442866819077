import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { PropertyStringParser } from 'common/PropertyStringParser/PropertyStringParser';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @slot default
 */
@autoinject()
export class PropertyStyledTextWidget {
  private static readonly availableThemeInfos: Array<OptionValueInfo> = [
    PropertyStyledTextWidget.createOptionValueInfo('Heading1'),
    PropertyStyledTextWidget.createOptionValueInfo('Heading2'),
    PropertyStyledTextWidget.createOptionValueInfo('Heading3'),
    PropertyStyledTextWidget.createOptionValueInfo('Heading4')
  ];

  private static readonly availableTextDecorationInfos: Array<OptionValueInfo> =
    [PropertyStyledTextWidget.createOptionValueInfo('Underline')];

  private static readonly availableFontStyleInfos: Array<OptionValueInfo> = [
    PropertyStyledTextWidget.createOptionValueInfo('Italic')
  ];

  private static createOptionValueInfo(name: string): OptionValueInfo {
    return { name, lowercaseName: name.toLocaleLowerCase() };
  }

  @bindable()
  public options: Property['options'] | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected themeName: string | null = null;
  protected textDecorationName: string | null = null;
  protected fontStyleName: string | null = null;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'options',
      this.handleOptionsArrayChanged.bind(this)
    );
    this.handleOptionsArrayChanged();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private handleOptionsArrayChanged(): void {
    this.themeName = this.getOptionValueName(
      'theme',
      PropertyStyledTextWidget.availableThemeInfos
    );
    this.textDecorationName = this.getOptionValueName(
      'textDecoration',
      PropertyStyledTextWidget.availableTextDecorationInfos
    );
    this.fontStyleName = this.getOptionValueName(
      'fontStyle',
      PropertyStyledTextWidget.availableFontStyleInfos
    );
  }

  private getOptionValueName(
    optionName: string,
    infos: Array<OptionValueInfo>
  ): string | null {
    const themeName = PropertyStringParser.getOptionValueByName(
      this.options ?? [],
      optionName
    );
    const lowercaseName = themeName?.toLocaleLowerCase();
    return (
      infos.find((info) => info.lowercaseName === lowercaseName)?.name ?? null
    );
  }
}

type OptionValueInfo = {
  name: string;
  lowercaseName: string;
};
