import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { KeyValueCache } from 'common/Cache/KeyValueCache/KeyValueCache';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class ProcessTaskToProjectTitleCacheComputer extends ValueComputer<
  ProcessTaskToProjectTitleCacheComputerComputeData,
  ProcessTaskToProjectTitleCache
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      invokeCompute
    );
    this.subscriptionManager.subscribeToEvent(
      'i18n:locale:changed',
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskToProjectTitleCache {
    return new ProcessTaskToProjectTitleCacheImpl(
      this.entityManager,
      this.i18n
    );
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ProcessTaskToProjectTitleCacheComputerComputeData = Record<
  string,
  never
>;

export type ProcessTaskToProjectTitleCache = {
  getFallbackTitle(
    appointmentId: string | null,
    processTaskToProjectType: ProcessTaskToProjectType | null
  ): string | null;
  getTitleForRelation(
    processTaskToProject: ProcessTaskToProject
  ): string | null;
};

export const fallbackProcessTaskToProjectTitleCache: ProcessTaskToProjectTitleCache =
  {
    getFallbackTitle: () => null,
    getTitleForRelation: () => null
  };

class ProcessTaskToProjectTitleCacheImpl
  implements ProcessTaskToProjectTitleCache
{
  private readonly projectNameCache: KeyValueCache<string, string | null>;
  private readonly appointmentNameCache: KeyValueCache<string, string | null>;
  private readonly processTaskToProjectTypeNameCache: KeyValueCache<
    ProcessTaskToProjectType | null,
    string | null
  >;

  constructor(entityManager: AppEntityManager, i18n: I18N) {
    this.projectNameCache = new KeyValueCache({
      getValue: (key) => {
        const project = entityManager.projectRepository.getById(key);
        assertNotNullOrUndefined(project, `no project for "${key}" found`);
        return project.name;
      }
    });

    this.appointmentNameCache = new KeyValueCache({
      getValue: (key) => {
        const processTaskAppointment =
          entityManager.processTaskAppointmentRepository.getById(key);
        assertNotNullOrUndefined(
          processTaskAppointment,
          `no processTaskAppointment for "${key}" found`
        );
        return processTaskAppointment.name;
      }
    });

    this.processTaskToProjectTypeNameCache = new KeyValueCache({
      getValue: (key) => {
        if (key === ProcessTaskToProjectType.MEASURE_POINT_PICTURES) {
          return i18n.tr(
            'operations.processTaskProjectPicturesWidget.measurePointPicturesTitle'
          ) as string;
        }

        return i18n.tr(
          'operations.processTaskProjectPicturesWidget.generalTitle'
        ) as string;
      }
    });
  }

  public getTitleForRelation(
    processTaskToProject: ProcessTaskToProject
  ): string | null {
    return this.getTitle(
      processTaskToProject.processTaskAppointmentId,
      processTaskToProject.type,
      processTaskToProject.projectId
    );
  }

  public getFallbackTitle(
    appointmentId: string | null,
    processTaskToProjectType: ProcessTaskToProjectType | null
  ): string | null {
    return this.getTitle(appointmentId, processTaskToProjectType, null);
  }

  private getTitle(
    appointmentId: string | null,
    processTaskToProjectType: ProcessTaskToProjectType | null,
    projectId: string | null
  ): string | null {
    const projectName = projectId ? this.projectNameCache.get(projectId) : null;
    if (projectName) {
      return projectName;
    }

    if (appointmentId) {
      return this.appointmentNameCache.get(appointmentId);
    }

    return this.processTaskToProjectTypeNameCache.get(processTaskToProjectType);
  }
}
