import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ShowHideAnimator } from '../../../classes/Animation/ShowHideAnimator';
import { FocusSetHintResult } from '../../../services/CameraStreamService/AbstractCameraStreamStrategy';

@autoinject()
export class FocusSetFeedback {
  protected focusSetHintContainer: HTMLElement | null = null;
  protected focusSetHint: HTMLElement | null = null;
  private focusSetHintAnimator: ShowHideAnimator | null = null;
  private focusSetHintTimeout: number | null = null;

  protected attached(): void {
    if (this.focusSetHint) {
      this.focusSetHintAnimator = new ShowHideAnimator(this.focusSetHint);
    }
  }

  protected detached(): void {
    if (this.focusSetHintTimeout) clearTimeout(this.focusSetHintTimeout);
  }

  public showFocusSetHint(result: FocusSetHintResult): void {
    if (this.focusSetHintTimeout) clearTimeout(this.focusSetHintTimeout);

    const focusSetHintAnimator = this.focusSetHintAnimator;

    assertNotNullOrUndefined(
      this.focusSetHint,
      'cannot show hint without focusSetHint'
    );
    assertNotNullOrUndefined(
      this.focusSetHintContainer,
      'cannot show hint without focusSetHintContainer'
    );
    assertNotNullOrUndefined(
      focusSetHintAnimator,
      'cannot show hint without focusSetHintAnimator'
    );

    const devicePixelRatio = window.devicePixelRatio;

    const viewportPositionX = result.x / devicePixelRatio;
    const viewportPositionY = result.y / devicePixelRatio;

    this.focusSetHintContainer.style.left = viewportPositionX + 'px';
    this.focusSetHintContainer.style.top = viewportPositionY + 'px';

    void focusSetHintAnimator.fadeIn();
    this.focusSetHintTimeout = window.setTimeout(() => {
      void focusSetHintAnimator.fadeOut();
    }, 1500);
  }
}
