import { autoinject, bindable } from 'aurelia-framework';

import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { PersonContactHelper } from 'common/EntityHelper/PersonContactHelper';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';

@autoinject()
export class PersonContactButtons {
  private static contactTypeToIconName: Record<PersonContactType, string> = {
    [PersonContactType.EMAIL]: 'fa-envelope',
    [PersonContactType.PHONE]: 'fa-phone'
  };

  @bindable()
  public person: Person | null = null;

  @bindable()
  public subject: string | null = null;

  @bindable()
  public message: string | null = null;

  /**
   * custom logic to handle a click on the E-Mail Button
   */
  @bindable()
  public customEmailHandler:
    | ((options: { $contact: PersonContact }) => void)
    | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected contacts: Array<PersonContact> = [];

  protected tooltipContent: TooltipContent | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updateContacts.bind(this)
    );
    this.updateContacts();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected personChanged(): void {
    if (this.isAttached) {
      this.updateContacts();
    }
  }

  private updateContacts(): void {
    if (this.person) {
      this.contacts = PersonContactHelper.highlightedToFront(
        this.entityManager.personContactRepository.getByPersonId(this.person.id)
      );
    } else {
      this.contacts = [];
    }
  }

  protected handleSelectClick(event: MouseEvent): void {
    if (this.tooltipContent) {
      this.tooltipContent.ignoreClickEvent(event);
      if (!this.tooltipContent.isOpen()) {
        this.tooltipContent.open();
      }
    }
  }

  protected handleContactMenuContactClick(contact: PersonContact): boolean {
    if (this.tooltipContent) {
      this.tooltipContent.close();
    }

    if (
      contact.contactType === PersonContactType.EMAIL &&
      this.customEmailHandler
    ) {
      this.customEmailHandler({ $contact: contact });
      return false;
    }

    return true;
  }

  protected getIconNameForContactType(contactType: PersonContactType): string {
    return PersonContactButtons.contactTypeToIconName[contactType];
  }
}
