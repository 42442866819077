import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskLogEntryEntityInfo } from 'common/Types/Entities/ProcessTaskLogEntry/createProcessTaskLogEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskLogEntry } from './types';

export const processTaskLogEntryEntityInfo =
  createProcessTaskLogEntryEntityInfo<ProcessTaskLogEntry>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
