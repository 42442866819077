import { inject, observable } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

import { EntityMap } from '../../classes/EntityMap';
import { FilterHelper } from '../../classes/FilterHelper';
import { UiUpdater } from '../../classes/UiUpdater';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { RecordItHeader } from '../../aureliaComponents/record-it-header/record-it-header';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingGroupUtils } from '../../classes/EntityManager/entities/ThingGroup/ThingGroupUtils';
import { NavigationService } from '../../services/NavigationService';

@inject(
  AppEntityManager,
  ActiveUserCompanySettingService,
  SubscriptionManagerService,
  NavigationService
)
export class ThingGroupMap {
  /** @type {import('../../map/basemap-map/basemap-map').BasemapMap|null} */
  _basemapMap = null;

  /** @type {Array<ThingGroup>} */
  _availableThingGroups = [];

  /** @type {string} */
  @observable _thingGroupFilterString;

  /** @type {Array<ThingGroup>} */
  @observable _filteredThingGroups;

  /** @type {string} */
  @observable _defaultMarkerColor;

  /** @type {Array<import('../../classes/EntityMap').TEntityMapEntityInfo>} */
  _entityMapEntityInfos = [];

  /** @type {ThingGroup|null} */
  @observable _selectedThingGroup;

  /** @type {Array<Thing>} */
  _thingsOfSelectedThingGroup = [];

  /**
   * @param {AppEntityManager} entityManager
   * @param {ActiveUserCompanySettingService} activeUserCompanySettingService
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {NavigationService} navigationService
   */
  constructor(
    entityManager,
    activeUserCompanySettingService,
    subscriptionManagerService,
    navigationService
  ) {
    this._entityManager = entityManager;

    this._activeUserCompanySettingService = activeUserCompanySettingService;

    this._subscriptionManager = subscriptionManagerService.create();

    this._navigationService = navigationService;

    this._boundHandleResize = this._handleResize.bind(this);

    this._thingGroupFilterString = '';
    this._filteredThingGroups = [];
    this._defaultMarkerColor = '#ff0000';
    this._selectedThingGroup = null;
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this._updateAvailableThingGroups.bind(this)
    );
    this._updateAvailableThingGroups();

    this._subscriptionManager.subscribeToModelChanges(EntityName.Thing, () => {
      this._selectedThingGroupChanged();
      this._updateEntityMapEntityInfos();
      this._filteredThingGroupsChanged();
    });

    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this._isMobile = isMobile;
      })
    );

    UiUpdater.registerResizeUpdateFunction(this._boundHandleResize);
    this._handleResize();

    this._subscriptionManager.addDisposable(
      this._activeUserCompanySettingService.bindSettingProperty(
        'general.primaryLightColor',
        (primaryLightColor) => {
          this._defaultMarkerColor = primaryLightColor;
        }
      )
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _selectedThingGroupChanged() {
    if (this._selectedThingGroup) {
      this._thingsOfSelectedThingGroup =
        this._entityManager.thingRepository.getByThingGroupId(
          this._selectedThingGroup.id
        );
    } else {
      this._thingsOfSelectedThingGroup = [];
    }
  }

  _handleResize() {
    const headerHeight = RecordItHeader.getHeaderHeight() || 0;
    this._remainingHeight = window.innerHeight - headerHeight;

    const basemapMap = this._basemapMap;
    if (!basemapMap) return;

    window.requestAnimationFrame(() => {
      basemapMap.resize();
    });
  }

  _handleMapInitialised() {
    if (this._basemapMap) this._entityMap = new EntityMap(this._basemapMap);
    if (this._entityMap) {
      this._entityMap.setMarkerClickedCallback(
        this._handleMarkerClicked.bind(this)
      );
      this._entityMap.setMarkerPopupClosedCallback(
        this._handleMarkerPopupClosed.bind(this)
      );
    }
    this._updateAvailableThingGroups();
  }

  _updateAvailableThingGroups() {
    this._availableThingGroups =
      this._entityManager.thingGroupRepository.getAll();
    this._updateEntityMapEntityInfos();
    this._thingGroupFilterStringChanged();
  }

  _filteredThingGroupsChanged() {
    if (!this._entityMap) return;

    this._entityMap.showEntitiesOnMap(this._entityMapEntityInfos);

    const filteredThingGroupIds = this._filteredThingGroups.map((tG) => tG.id);
    const filteredEntityMapEntityInfos = this._entityMapEntityInfos.filter(
      (eMI) => filteredThingGroupIds.includes(eMI.id)
    );
    this._entityMap.enableEntities(filteredEntityMapEntityInfos);
  }

  _thingGroupFilterStringChanged() {
    this._filteredThingGroups = FilterHelper.filterItems(
      this._availableThingGroups,
      ThingGroupUtils.filterFunction,
      this._thingGroupFilterString
    );
  }

  _defaultMarkerColorChanged() {
    this._filteredThingGroupsChanged();
  }

  _updateEntityMapEntityInfos() {
    this._entityMapEntityInfos =
      this._createEntityMapEntityInfosFromThingGroups(
        this._availableThingGroups
      );
  }

  /**
   * @param {Array<ThingGroup>} thingGroups
   * @returns {Array<import('../../classes/EntityMap').TEntityMapEntityInfo>}
   */
  _createEntityMapEntityInfosFromThingGroups(thingGroups) {
    /** @type {Array<import('../../classes/EntityMap').TEntityMapEntityInfo} */
    const entityMapEntityInfos = [];

    // TODO: fix getEntities performance in storage helper and use getByThingGroupId
    const things = this._entityManager.thingRepository.getAll();

    thingGroups.forEach((thingGroup) => {
      const thingsOfThingGroup = things.filter(
        (t) => t.thingGroupId === thingGroup.id
      );

      if (thingGroup.longitude && thingGroup.latitude) {
        const numberOfThings = thingsOfThingGroup.length;
        entityMapEntityInfos.push({
          id: thingGroup.id,
          entity: thingGroup,
          longitude: thingGroup.longitude,
          latitude: thingGroup.latitude,
          linkUrl: `edit_objects?projectType=${ProjectType.GALLERY}&thingGroupId=${thingGroup.id}`,
          label: numberOfThings > 1 ? String(numberOfThings) : '',
          markerColor: this._defaultMarkerColor
        });
      }
    });

    return entityMapEntityInfos;
  }

  /**
   * @param {import('../../classes/EntityMap').TEntityMapEntityInfo} entityMapEntityInfo
   */
  _handleMarkerClicked(entityMapEntityInfo) {
    this._selectedThingGroup = entityMapEntityInfo.entity;
  }

  /**
   * @param {import('../../classes/EntityMap').TEntityMapEntityInfo} entityMapEntityInfo
   */
  _handleMarkerPopupClosed(entityMapEntityInfo) {
    if (entityMapEntityInfo.entity === this._selectedThingGroup) {
      this._selectedThingGroup = null;
    }
  }

  /**
   * @param {ThingGroup} thingGroup
   * @returns {string}
   */
  _getThingGroupInfoString(thingGroup) {
    const stringParts = [];
    if (thingGroup.streetName) stringParts.push(thingGroup.streetName);
    stringParts.push(`${thingGroup.zip} ${thingGroup.municipality}`);

    return stringParts.join(' • ');
  }

  /**
   * @param {Thing} thing
   */
  _handleThingClicked(thing) {
    this._navigationService.navigateToThing(thing.id);
  }
}

/** @typedef {import('../../classes/EntityManager/entities/ThingGroup/types').ThingGroup} ThingGroup */
/** @typedef {import('../../classes/EntityManager/entities/Thing/types').Thing} Thing */
