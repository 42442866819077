import * as JSZip from 'jszip';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { Utils } from '../Utils';
import { UrlManager } from '../../UrlManager';
import { DataUrlReader } from '../../Reader/DataUrlReader/DataUrlReader';
import { TestingHelper } from '../../TestingHelper';
import { FileUtilsAdapter } from './adapter/FileUtilsAdapter';
import { Logger } from '../../Logger/Logger';
import { CustomDirectoryEntry } from './entries/CustomDirectoryEntry';
import { CustomFileEntry } from './entries/CustomFileEntry';

export class FileUtils {
  private static adapter: FileUtilsAdapter;

  public static init(adapter: FileUtilsAdapter): void {
    this.adapter = adapter;
  }

  /**
   * downloads a file to the localFolder
   */
  public static async downloadFileToLocalFolder(
    url: string,
    relativePath: string
  ): Promise<void> {
    await this.downloadFileToFolder(
      url,
      UrlManager.localFolder,
      () => relativePath
    );
  }

  /**
   * downloads a file into the Downloads folder of the phone
   */
  public static async downloadFileToDownloadFolder(
    url: string
  ): Promise<CustomFileEntry> {
    assertNotNullOrUndefined(
      UrlManager.downloadFolder,
      'no download folder found, are you in the app?'
    );

    return await this.downloadFileToFolder(
      url,
      UrlManager.downloadFolder,
      (headers) => {
        const contentDisposition = headers.get('content-disposition');
        const fileNameResult = /filename="([^"]*)"/.exec(
          contentDisposition ?? ''
        );
        return fileNameResult && fileNameResult[1] ? fileNameResult[1] : 'null';
      }
    );
  }

  private static async downloadFileToFolder(
    url: string,
    baseFolderPath: string,
    getRelativePath: (headers: Headers) => string
  ): Promise<CustomFileEntry> {
    const response = await fetch(url);
    if (response.status >= 400) {
      throw new FileDownloadError(response.status, response);
    }
    const data = await response.blob();

    const directory =
      await this.getRequiredAdapter().resolveDirectoryPath(baseFolderPath);

    const destinationFile = await directory.ensureFile(
      getRelativePath(response.headers)
    );
    await destinationFile.writeBlob(data);

    return destinationFile;
  }

  /**
   * only works in app!
   */
  public static async writeBase64ToLocalFile(
    absolutePath: string,
    base64Data: string
  ): Promise<void> {
    await this.writeBlobToLocalFile(absolutePath, Utils.b64toBlob(base64Data));
  }

  /**
   * only works in app!
   */
  public static async writeBlobToLocalFile(
    absolutePath: string,
    data: Blob
  ): Promise<void> {
    const pathInfo = PathUtils.getPathDetails(absolutePath);
    const directoryEntry = await this.getRequiredAdapter().resolveDirectoryPath(
      pathInfo.pathWithoutBaseName
    );

    const destinationFileEntry = await directoryEntry.ensureFile(
      pathInfo.baseName
    );
    await destinationFileEntry.writeBlob(data);
  }

  /**
   * only works in the app!!
   */
  public static async copyFile(
    from: string,
    relativePath: string
  ): Promise<void> {
    console.log(`trying to copy file from "${from}" to "${relativePath}"`);

    const fromFileEntry = await this.getRequiredAdapter().resolveFilePath(from);
    const localDirectory = await this.getRequiredAdapter().resolveDirectoryPath(
      UrlManager.localFolder
    );

    const relativePathDetails = PathUtils.getPathDetails(relativePath);

    const destinationDirectoryPath = relativePathDetails.pathWithoutBaseName;
    const destinationDirectory = await localDirectory.ensureDirectory(
      destinationDirectoryPath
    ); // strip the file name from the path

    await fromFileEntry.copyTo(
      destinationDirectory,
      relativePathDetails.baseName
    );
  }

  public static async renameFile(
    baseFolder: string,
    oldName: string,
    newName: string
  ): Promise<void> {
    const directory =
      await this.getRequiredAdapter().resolveDirectoryPath(baseFolder);

    const filePath = directory.getFilePath(newName);
    if (await this.fileExists(filePath)) {
      throw new Error('cannot rename, destination already exists');
    }

    try {
      const oldFileEntry = await directory.getFile(oldName);
      await oldFileEntry.moveTo(directory, newName);
      console.log("Local file '" + oldName + "' renamed to '" + newName + "'.");
    } catch (fileError) {
      console.log(`ERROR: Local file '${oldName}' could not be renamed!`);
      console.error(fileError);
      throw fileError;
    }
  }

  public static async deleteEntry(fileOrDirectoryPath: string): Promise<void> {
    try {
      const entry =
        await this.getRequiredAdapter().resolvePath(fileOrDirectoryPath);
      await entry.delete();

      console.log("File '" + fileOrDirectoryPath + "' successfully deleted.");
    } catch (error) {
      if (
        error instanceof FileError &&
        error.code === FileError.NOT_FOUND_ERR
      ) {
        console.log('File does not exist', fileOrDirectoryPath);
        return;
      }

      console.log('Could not delete file/directory', fileOrDirectoryPath);
      Logger.logError({ error });
      throw error;
    }
  }

  /**
   * only works in the app!!
   */
  public static async fileExists(filePath: string): Promise<boolean> {
    try {
      await this.getRequiredAdapter().resolveFilePath(filePath);
    } catch (error) {
      if (
        error instanceof FileError &&
        error.code === FileError.NOT_FOUND_ERR
      ) {
        return false;
      } else {
        throw error;
      }
    }
    return true;
  }

  public static async getFilesShallow(
    directoryPath: string
  ): Promise<Array<CustomFileEntry>> {
    const directory =
      await this.getRequiredAdapter().resolveDirectoryPath(directoryPath);

    return directory.getFilesShallow();
  }

  /**
   * returns all direct descendant directories of the directory given by the directoryPath
   */
  public static async getDirectoriesShallow(
    directoryPath: string
  ): Promise<Array<CustomDirectoryEntry>> {
    const directory =
      await this.getRequiredAdapter().resolveDirectoryPath(directoryPath);

    return directory.getDirectoriesShallow();
  }

  public static async ensureDirectory(
    basePath: string,
    directoryNameOrPath: string
  ): Promise<CustomDirectoryEntry> {
    const baseDirectory =
      await this.getRequiredAdapter().resolveDirectoryPath(basePath);
    return baseDirectory.ensureDirectory(directoryNameOrPath);
  }

  public static async moveFile(
    oldPath: string,
    newDirectory: string
  ): Promise<void> {
    const fileEntry = await this.getRequiredAdapter().resolveFilePath(oldPath);

    const directoryEntry =
      await this.getRequiredAdapter().resolveDirectoryPath(newDirectory);

    await fileEntry.moveTo(directoryEntry, fileEntry.name);
  }

  public static async readFilePathAsDataUrl(filePath: string): Promise<string> {
    const fileEntry = await this.getRequiredAdapter().resolveFilePath(filePath);

    const reader = new DataUrlReader();
    return reader.readBlob(await fileEntry.readAsBlob());
  }

  public static async createZipFileFromArrayBuffer(
    fileName: string,
    arrayBuffer: ArrayBuffer
  ): Promise<string> {
    const zip = new JSZip();
    zip.file(fileName, arrayBuffer);
    return await zip.generateAsync({
      type: 'base64',
      compression: 'DEFLATE'
    });
  }

  public static convertFileSrc(src: string): Promise<string> {
    return this.getRequiredAdapter().convertFileSrc(src);
  }

  /**
   * TODO: this is just a hotfix and needs a cleaner solution
   */
  public static async ensureCapturedPicturesFolder(): Promise<void> {
    const localDirectory = await this.getRequiredAdapter().resolveDirectoryPath(
      UrlManager.localFolder
    );
    await localDirectory.ensureDirectory('captured_pictures');
  }

  private static getRequiredAdapter(): FileUtilsAdapter {
    assertNotNullOrUndefined(
      this.adapter,
      'No adapter available, the FileUtils is not initialized'
    );
    return this.adapter;
  }
}

export class FileDownloadError extends Error {
  public status: number;
  public response: any;

  constructor(status: number, response: any) {
    super();

    this.status = status;
    this.response = response;
  }
}

TestingHelper.FileUtils = FileUtils;
