import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { TExportPicturesOfGalleryThingAsZipFileResponse } from 'common/EndpointTypes/ExportPictureZipEndpointsHandler';
import { ExportType } from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';

import { SocketService } from '../../services/SocketService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import {
  GalleryThingPictureBulkEditHelper,
  GalleryThingPictureBulkEditOptions
} from '../../classes/GalleryThing/GalleryThingPictureBulkEditHelper';
import { Dialogs } from '../../classes/Dialogs';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { FileDownloadService } from '../../services/FileDownloadService';
import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

/**
 * An export panel meant to be attached to a gallery-thing-picture-filter-bar.
 *
 * @event cancel-button-clicked
 *
 * @event export-started - fired when the request to export the thing was sent to the server
 * payload: {reportId: string|null} reportId is null when there is no information about it (yet)
 */
@autoinject()
export class GalleryThingPictureExportPanel {
  @bindable public thing: Thing | null = null;

  @bindable public editable = false;

  @bindable public pictureFilter: GalleryThingPictureFilter | null = null;

  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  @bindable public exportPanelVisible = false; // read-only!!

  protected currentUser: User | null = null;

  protected domElement: HTMLElement;

  protected isOnline = false;

  protected chooseGalleryThingExportTypeDropdownModel: TooltipContent | null =
    null;

  protected exportAndChangePanelViewModel: ParameterPanel | null = null;

  protected bulkEditOptions: GalleryThingPictureBulkEditOptions =
    GalleryThingPictureBulkEditHelper.createEmptyBulkEditOptions();

  protected PermissionHelper = PermissionHelper;

  protected ModuleNames = ModuleName;

  private isAttached: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private permissionBindingHandle: PermissionBindingHandle;

  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'export-pictures-as-shapefile',
      labelTk: 'galleryThing.pictureExportPanel.exportPicturesAsShapefile',
      iconClass: 'fal fa-file-archive'
    }
  ];

  protected canUseDefectManagement = false;

  constructor(
    element: Element,
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService,
    private readonly defectCreationService: DefectCreationService,
    private readonly router: Router,
    permissionBindingService: PermissionBindingService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as GalleryThingPictureExportPanel,
        propertyName: 'thing'
      }
    );

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseDefectManagement: 'canUseDefectManagement'
      }
    });
  }

  // /////////// PUBLIC METHODS /////////////

  public expand(): void {
    this.exportAndChangePanelViewModel?.expand();
  }

  public async collapse(): Promise<void> {
    await this.exportAndChangePanelViewModel?.collapse();
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();

    this.permissionBindingHandle.unsubscribe();
  }

  // /////////// METHODS /////////////

  private getSelectedPictureIds(): Array<string> {
    return this.selectedPictureOverviewEntries.map((p) => p.pictureId);
  }

  private resetPictureSelection(): void {
    this.selectedPictureOverviewEntries = [];
  }

  private copySelectedPicturesToNewBasicProject(): void {
    if (!this.thing) return;

    const selectedPictureIds = this.getSelectedPictureIds();

    Dialogs.waitDialog();

    this.socketService.copyPicturesOfGalleryThingToBasicProject(
      {
        ownerUserGroupId: this.thing.ownerUserGroupId,
        thingId: this.thing.id,
        pictureIds: selectedPictureIds
      },
      (data) => {
        if (data.success) {
          Dialogs.timedSuccessDialogTk(
            'galleryThing.pictureExportPanel.copyPicturesIntoBasicProjectSuccess'
          );
        } else {
          const errorMessageKey = data.status
            ? `serverResponses.${data.status}`
            : 'serverResponses.unspecifiedError';
          void Dialogs.errorDialogTk('general.error', errorMessageKey);
        }
      }
    );
  }

  private getSocketServiceParameters(): {
    thingId: string;
    pictureFilter: GalleryThingPictureFilter;
  } {
    assertNotNullOrUndefined(
      this.thing,
      'cannot getSocketServiceParameters without thing'
    );

    return {
      thingId: this.thing.id,
      pictureFilter: {
        ...this.pictureFilter,
        selectedPictureIds: this.getSelectedPictureIds()
      }
    };
  }

  private onExportResponseHandler(
    response: TExportPicturesOfGalleryThingAsZipFileResponse
  ): void {
    if (response.success) {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'export-started',
        detail: { reportId: response.reportId }
      });
      this.resetPictureSelection();
    }
  }

  // /////////// UPDATERS /////////////

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
  }

  // /////////// EVENT HANDLERS /////////////

  protected handlePanelClosed(): void {
    this.resetPictureSelection();
  }

  protected handleExportGalleryThingClick(exportType: ExportType): void {
    assertNotNullOrUndefined(
      this.chooseGalleryThingExportTypeDropdownModel,
      'cannot handleExportGalleryThingCLick without exportTypeDropdownModel'
    );

    this.chooseGalleryThingExportTypeDropdownModel.close();
    this.socketService.exportGalleryThing(
      {
        ...this.getSocketServiceParameters(),
        exportType: exportType
      },
      this.onExportResponseHandler.bind(this)
    );
  }

  protected handleExportGalleryThingPicturesAsZipClick(): void {
    assertNotNullOrUndefined(
      this.chooseGalleryThingExportTypeDropdownModel,
      'cannot handleExportGalleryThingPicturesAsZipCLick without exportTypeDropdownModel'
    );

    this.chooseGalleryThingExportTypeDropdownModel.close();
    const socketParameters = this.getSocketServiceParameters();
    this.socketService.exportPicturesOfGalleryThingAsZipFile(
      socketParameters,
      this.onExportResponseHandler.bind(this)
    );
  }

  protected handleExportPicturesAsShapefile(): void {
    this.chooseGalleryThingExportTypeDropdownModel?.close();

    Dialogs.waitDialog();

    const socketParameters = this.getSocketServiceParameters();
    this.socketService.exportPicturesOfGalleryThingAsShapefile(
      socketParameters,
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          void Dialogs.errorDialogTk(
            'general.error',
            `serverResponses.exportPicturesOfGalleryThingAsShapefile.${response.status}`
          );
          console.error(response.status);
        }
      }
    );
  }

  protected handleExportPicturesToBasicProject(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleExportPicturesToBasicProject without thing!'
    );
    assertNotNullOrUndefined(
      this.chooseGalleryThingExportTypeDropdownModel,
      'cannot handleExportPicturesToBasicProject without exportTypeDropdownModel'
    );

    this.chooseGalleryThingExportTypeDropdownModel.close();
    this.copySelectedPicturesToNewBasicProject();
  }

  protected handleBulkEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot bulk edit gallery thing pictures without a thing'
    );

    this.bulkEditOptions.automaticallyMarkPicturesOnThingPicture =
      this.activeUserCompanySettingService.getSettingProperty(
        'via.automaticallyMarkPicturesOnThingPicture'
      ) === true;

    const bulkEditHelper = new GalleryThingPictureBulkEditHelper(
      this.thing.id,
      this.bulkEditOptions,
      this.entityManager
    );

    // @techdebt
    // Currently, not already joined pictures will be joined upon click, no matter if we are in edit or in select mode.
    // In principle, this is fine as long as we do not have any mass selection UI option.
    // We will need a better solution as soon as users need to be able to edit a higher amount of data, including unjoined data.
    bulkEditHelper.bulkEditPictures(this.selectedPictureOverviewEntries);
    this.bulkEditOptions =
      GalleryThingPictureBulkEditHelper.createEmptyBulkEditOptions();
  }

  protected async handleBulkDeleteButtonClick(): Promise<void> {
    const entryIds = this.selectedPictureOverviewEntries.map((entry) => {
      assertNotNullOrUndefined(
        entry.entryId,
        'all selected pictures should have an entry id'
      );
      return entry.entryId;
    });
    const entries = this.entityManager.entryRepository.getByIds(entryIds);

    await Dialogs.deleteDialogTk('galleryThing.pictureExportPanel.bulkDelete', {
      count: this.selectedPictureOverviewEntries.length
    });

    entries.forEach((e) => {
      this.entityManager.entryRepository.delete(e);
    });

    this.selectedPictureOverviewEntries = [];
  }

  protected async handleCreateDefectFromSelectionClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.thing,
      'cannot create a defect without a thing'
    );

    const projectIds = new Set<string>();

    this.selectedPictureOverviewEntries.forEach((e) => {
      if (e.projectId) projectIds.add(e.projectId);
    });

    for (const projectId of projectIds.values()) {
      await this.entityManager.joinedProjectsManager.joinProject(
        projectId,
        true,
        true
      );
    }

    await this.entityManager.entityActualization.actualize();

    const pictureIds = this.selectedPictureOverviewEntries.map(
      (e) => e.pictureId
    );

    const pictures = this.entityManager.pictureRepository.getByIds(pictureIds);

    const defect =
      await this.defectCreationService.createDefectWithExistingPictures({
        creationEntity: {
          ownerUserGroupId: this.thing.ownerUserGroupId,
          ownerThingId: this.thing.id
        },
        pictures
      });

    for (const projectId of projectIds.values()) {
      await this.entityManager.joinedProjectsManager.leaveProject(
        projectId,
        true
      );
    }

    this.router.navigateToRoute('edit_defect', {
      defect_id: defect.id,
      close_to_thing: true
    });
  }
}
