import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import {
  FieldConfigs,
  specialCharsFieldConfigs
} from 'common/ExpressionEditorScope/ExpressionEditorScopeCreationUtils';

import { ExpressionEditorScopeEvaluationUtils } from '../../classes/Utils/ExpressionEditorScopeEvaluationUtils';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * @event {TTextChangedEvent} text-changed this will get fired when the user finished editing the text (through bubbling set in clickable-text-input)
 */
@autoinject()
export class ExpressionEditorTextInput {
  /**
   * fieldConfigs is the only bindable which is not copied from clickable-text-input
   */
  @bindable public fieldConfigs: FieldConfigs | null = null;

  @bindable public text: string | number | null = '';

  @bindable public placeholder = '';

  @bindable public multiline = false;

  @bindable public enabled = false;

  @bindable public readOnly = false;

  @bindable public label = '';

  @bindable public showButtons = true;

  @bindable public compactButton = false;

  /**
   * set this to true to use the label of the floating-label-input instead of one to the left
   */
  @bindable public useFloatingLabel = false;

  /**
   * set type of text input element, does not work together with 'numberOnly'
   */
  @bindable public type = 'text';

  constructor(private readonly i18n: I18N) {}

  @computed(expression('fieldConfigs'))
  protected get helperText(): string {
    if (!this.fieldConfigs) return '';
    return ExpressionEditorScopeEvaluationUtils.createLegend(
      this.i18n,
      {
        ...this.fieldConfigs,
        ...specialCharsFieldConfigs
      },
      4
    );
  }
}
