import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskOfferToProcessTaskEntityInfo } from 'common/Types/Entities/ProcessTaskOfferToProcessTask/createProcessTaskOfferToProcessTaskEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskOfferToProcessTask } from './types';

export const processTaskOfferToProcessTaskEntityInfo =
  createProcessTaskOfferToProcessTaskEntityInfo<ProcessTaskOfferToProcessTask>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
