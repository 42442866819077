import { autoinject, bindable } from 'aurelia-framework';
import { EntityGroupUtils } from 'common/EntityGrouper/EntityGroupUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { CreateProcessTaskDeviceDialog } from '../create-process-task-device-dialog/create-process-task-device-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditProcessTaskDeviceDialog } from '../edit-process-task-device-dialog/edit-process-task-device-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskDeviceGroups,
  ProcessTaskDeviceGroupsForProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';

@autoinject()
export class ProcessTaskAppointmentDevicesList {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * read-only!
   * count of the displayed positions
   * is null when no devices are loaded
   */
  @bindable()
  public appointmentDevicesCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private allGroupedDevices: ProcessTaskDeviceGroups = [];
  protected groupedAppointmentDevices: ProcessTaskDeviceGroups = [];
  protected groupedGeneralDevices: ProcessTaskDeviceGroups = [];
  private isAttached: boolean = false;
  private deviceCreationEnabled: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      this.updateDevices.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      this.updateDevices.bind(this)
    );
    this.updateDevices();

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentDeviceCreation',
        (appointmentDeviceCreation) => {
          this.deviceCreationEnabled = appointmentDeviceCreation;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.allGroupedDevices = groupedDevices;
          this.updateDevices();
        },
        createComputeData: () =>
          this.processTask ? { processTaskId: this.processTask.id } : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateDevices();
    }
  }

  private updateDevices(): void {
    if (this.processTaskAppointment) {
      const relations =
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentId(
          this.processTaskAppointment.id
        );
      const relatedDeviceIds = new Set(
        relations.map((r) => r.processTaskDeviceId)
      );

      this.groupedAppointmentDevices = EntityGroupUtils.filterEntities(
        this.allGroupedDevices,
        (device) => relatedDeviceIds.has(device.id)
      );

      this.groupedGeneralDevices = EntityGroupUtils.filterEntities(
        this.allGroupedDevices,
        (device) => !relatedDeviceIds.has(device.id)
      );

      this.appointmentDevicesCount = EntityGroupUtils.getAllEntities(
        this.allGroupedDevices
      ).length;
    } else {
      this.groupedAppointmentDevices = [];
      this.appointmentDevicesCount = null;
      this.groupedGeneralDevices = [];
    }
  }

  private handleAddDeviceClick(): void {
    if (!this.processTask) {
      return;
    }

    void CreateProcessTaskDeviceDialog.open({
      processTask: this.processTask,
      onDialogClosed: (createdDevice) => {
        if (createdDevice) {
          this.updateDevices();
          this.editDevice(createdDevice);
        }
      }
    });
  }

  private handleDeviceEditButtonClicked(device: ProcessTaskDevice): void {
    this.editDevice(device);
  }

  private editDevice(device: ProcessTaskDevice): void {
    void EditProcessTaskDeviceDialog.open({
      processTaskDevice: device,
      readOnly: !this.deviceCreationEnabled,
      onDialogClosed: () => {
        this.goToDevice(device);
      }
    });
  }

  private goToDevice(device: ProcessTaskDevice): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getDeviceListItemId(device.id),
      null,
      device,
      () => this.isAttached
    );
  }

  private getDeviceListItemId(deviceId: string): string {
    return 'process-task-appointment-devices-list--device-' + deviceId;
  }
}
