import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { PropertyToPersonDto } from './PropertyToPersonDto';

export function createPropertyToPersonEntityInfo<
  TEntity extends PropertyToPersonDto<string, string>
>(
  options: CreateEntityInfoOptions<PropertyToPersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.PropertyToPerson,
  TEntity
> {
  return {
    entityName: EntityName.PropertyToPerson,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PropertyToPersonDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProcessTaskSubEntityUtils.createOptionalBaseFieldInfos(),
        propertyId: {
          name: 'propertyId',
          ref: EntityName.Property,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
