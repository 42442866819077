/**
 * this class will normalize pointer events (on the canvas element) into canvas coordinates
 */
export class CanvasPointerEventNormalizer {
  /**
   *
   * @param {MouseEvent} event
   * @param {HTMLCanvasElement} canvas
   *
   * @returns {{x: number, y: number}}
   */
  static normalizeMouseEvent(event, canvas) {
    return CanvasPointerEventNormalizer.normalizeOffsetCoordinates(
      {
        x: event.offsetX,
        y: event.offsetY
      },
      canvas
    );
  }

  /**
   *
   * @param {Event} event
   * @param {HTMLCanvasElement} canvas
   *
   * @returns {{x: number, y: number}}
   */
  static normalizeTouchEvent(event, canvas) {
    //noinspection JSUnresolvedVariable
    let touch = event.targetTouches[0];
    let rect = canvas.getBoundingClientRect();

    return CanvasPointerEventNormalizer.normalizeOffsetCoordinates(
      {
        x: touch ? touch.clientX - rect.left : 0,
        y: touch ? touch.clientY - rect.top : 0
      },
      canvas
    );
  }

  /**
   * this is basically the reverse function for normalizeOffsetCoordinates
   * result is relative to the top left border of the canvas element
   *
   * @param {{x: number, y: number}} position
   * @param {HTMLCanvasElement} canvas
   * @returns {{x: number, y: number}}
   */
  static getOffsetCoordinatesForCanvasPosition(position, canvas) {
    let computed = window.getComputedStyle(canvas);
    let widthRatio = parseFloat(computed.width) / canvas.width;
    let heightRatio = parseFloat(computed.height) / canvas.height;

    return {
      x: position.x * widthRatio,
      y: position.y * heightRatio
    };
  }

  /**
   * coord must be relative to the top left of the canvas itself!
   *
   * @param {{x: number, y: number}} coord
   * @param {HTMLCanvasElement} canvas
   * @returns {{x: number, y: number}}
   */
  static normalizeOffsetCoordinates(coord, canvas) {
    let computed = window.getComputedStyle(canvas);
    let widthRatio = canvas.width / parseFloat(computed.width);
    let heightRatio = canvas.height / parseFloat(computed.height);

    return {
      x: coord.x * widthRatio,
      y: coord.y * heightRatio
    };
  }
}
