import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import {
  EntityInfoPathHandler,
  FieldInfoPath
} from '@record-it-npm/synchro-common';
import { AppEntityRepository } from '../../base/AppEntityRepository';

@autoinject()
export class ProjectSubEntitiesRemovalService {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityManager.joinedProjectsManager.registerHooks({
        onProjectLeft: this.handleProjectLeft.bind(this)
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private handleProjectLeft(projectId: string): void {
    const infos =
      this.entityManager.entityRepositoryContainer.getEntityNameReferenceInfos(
        EntityName.Project
      );

    for (const info of infos) {
      if (info.fieldInfo.relativeSynchronization) {
        this.removeProjectSubEntitiesLocally(
          info.repository,
          info.fieldInfo.path,
          projectId
        );
      }
    }
  }

  private removeProjectSubEntitiesLocally<TEntityName extends EntityName>(
    repository: AppEntityRepository<TEntityName>,
    fieldPath: FieldInfoPath,
    projectId: string
  ): void {
    const entities = repository.getAll();
    for (const entity of entities) {
      const pathHandler = new EntityInfoPathHandler({
        entityInfo: repository.getEntityInfo(),
        path: fieldPath
      });

      const referencesProject = pathHandler.some({
        data: entity,
        callback: ({ value }) => value === projectId
      });

      if (referencesProject) {
        repository.removeLocally(entity);
      }
    }
  }
}
