import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { ProcessConfigurationPositionType } from '../../../Enums/ProcessConfigurationPositionType';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskPositionDto } from './ProcessTaskPositionDto';

export function createProcessTaskPositionEntityInfo<
  TEntity extends ProcessTaskPositionDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskPositionDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskPosition,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskPosition,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskPositionDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processConfigurationStepPositionId: {
          name: 'processConfigurationStepPositionId',
          ref: EntityName.ProcessConfigurationStepPosition
        },
        processConfigurationStepId: {
          name: 'processConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep
        },
        createdAtAppointmentId: {
          name: 'createdAtAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          defaultValue: null
        },
        processConfigurationCategoryId: {
          name: 'processConfigurationCategoryId',
          ref: EntityName.ProcessConfigurationCategory,
          defaultValue: null
        },
        snapshotOfProcessTaskPositionId: {
          name: 'snapshotOfProcessTaskPositionId',
          ref: EntityName.ProcessTaskPosition,
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: ProcessConfigurationPositionType.DEFAULT
        },
        customType: {
          name: 'customType',
          defaultValue: null
        },
        namePrefix: {
          name: 'namePrefix',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        noteInternal: {
          name: 'noteInternal',
          defaultValue: null
        },
        amount: {
          name: 'amount',
          defaultValue: 0
        },
        unit: {
          name: 'unit',
          defaultValue: null
        },
        price: {
          name: 'price',
          defaultValue: 0
        },
        discount: {
          name: 'discount',
          defaultValue: 0
        },
        discountNote: {
          name: 'discountNote',
          defaultValue: null
        },
        flatRate: {
          name: 'flatRate',
          defaultValue: false
        },
        excludeFromMarkup: {
          name: 'excludeFromMarkup',
          defaultValue: false
        },
        ignoreExcludeFromMarkup: {
          name: 'ignoreExcludeFromMarkup',
          defaultValue: false
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        detailEnabled: {
          name: 'detailEnabled',
          defaultValue: false
        },
        order: {
          name: 'order',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
