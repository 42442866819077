import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  DefectEntityDashboardInfo,
  ProjectEntityDashboardInfo,
  ThingEntityDashboardInfo
} from './types';

export class EntityDashboardInfoRepository extends AppEntityRepository<EntityName.EntityDashboardInfo> {
  public getByDefectId(defectId: string): Array<DefectEntityDashboardInfo> {
    return this.getAll().filter((data): data is DefectEntityDashboardInfo => {
      return data.defectId === defectId;
    });
  }

  public getByProjectId(projectId: string): Array<ProjectEntityDashboardInfo> {
    return this.getAll().filter((data): data is ProjectEntityDashboardInfo => {
      return data.projectId === projectId;
    });
  }

  public getByThingId(thingId: string): Array<ThingEntityDashboardInfo> {
    return this.getAll().filter((data): data is ThingEntityDashboardInfo => {
      return data.thingId === thingId;
    });
  }
}
