import {
  BaseEntityFieldInfos as SynchroPackageBaseEntityFieldInfos,
  BaseEntityInfoUtils,
  EntityFieldInfo,
  BaseEntityDto as SynchroPackageBaseEntityDto,
  EntityIdSorter,
  EntityIdInfoAdapter
} from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { ClientEntityIdInfoAdapter } from './idAdapter/ClientEntityIdInfoAdapter/ClientEntityIdInfoAdapter';
import { ServerEntityIdAdapter } from './idAdapter/ServerEntityIdAdapter/ServerEntityIdAdapter';
import { TestIdInfoAdapter } from './idAdapter/TestIdInfoAdapter/TestIdInfoAdapter';

export class BaseEntityUtils {
  private static readonly idAdapters: Array<EntityIdInfoAdapter> = [
    new ServerEntityIdAdapter(),
    new ClientEntityIdInfoAdapter()
  ];

  private static idSorter = new EntityIdSorter({
    idAdapters: this.idAdapters
  });

  private constructor() {}

  public static createBaseFieldInfos(): BaseEntityFieldInfos {
    return {
      ...BaseEntityInfoUtils.createBaseFieldInfos<AppCommonSynchronizationEnvironmentTypes>(),
      createdAt: {
        name: 'createdAt',
        defaultValue: null
      },
      createdByUserId: {
        name: 'createdByUserId',
        defaultValue: null
      },
      updatedAt: {
        name: 'updatedAt',
        defaultValue: null
      },
      deletedAt: {
        name: 'deletedAt',
        defaultValue: null
      },
      originalId: {
        name: 'originalId',
        defaultValue: null
      }
    };
  }

  public static sortByCreationOrder<T extends { id: string }>(
    entities: Array<T>
  ): Array<T> {
    const toSort = entities.slice();

    toSort.sort((a, b) => {
      return this.idSorter.compareIds(a.id, b.id);
    });

    return toSort;
  }

  public static sortByUpdatedAt<T extends BaseEntityDto>(
    entities: Array<T>
  ): Array<T> {
    const toSort = entities.slice();

    toSort.sort((a, b) => {
      const result =
        this.getUpdatedAtTimestampWithIdFallback(a) -
        this.getUpdatedAtTimestampWithIdFallback(b);

      if (result !== 0) {
        return result;
      }

      return this.idSorter.compareIds(a.id, b.id);
    });

    return toSort;
  }

  public static supportSortingTestIds(): void {
    this.idAdapters.push(new TestIdInfoAdapter());

    this.idSorter = new EntityIdSorter({
      idAdapters: this.idAdapters
    });
  }

  public static createEmptyBaseEntityDto(): BaseEntityDto {
    return {
      id: 'id',
      deleted: false,
      deletedAt: null,
      originalId: null,
      originalIds: [],
      createdByUserId: null,
      createdAt: null,
      revision: 0,
      updatedAt: null
    };
  }

  private static getUpdatedAtTimestampWithIdFallback(
    entity: BaseEntityDto
  ): number {
    if (entity.updatedAt) {
      return new Date(entity.updatedAt).getTime() * 1000 * 1000; // we need nanoseconds here because of the ids
    }

    return this.getTimestampFromId(entity.id);
  }

  private static getTimestampFromId(id: string): number {
    for (const idAdapter of this.idAdapters) {
      if (!idAdapter.isId(id)) {
        continue;
      }

      return idAdapter.getNanoSecondsTimeStampFromId(id);
    }

    throw new Error(`unknown id format: ${id}`);
  }
}

export type BaseEntityFieldInfos =
  SynchroPackageBaseEntityFieldInfos<AppCommonSynchronizationEnvironmentTypes> & {
    createdAt: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      BaseEntityDto,
      'createdAt'
    >;
    createdByUserId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      BaseEntityDto,
      'createdByUserId'
    >;
    updatedAt: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      BaseEntityDto,
      'updatedAt'
    >;
    deletedAt: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      BaseEntityDto,
      'deletedAt'
    >;
    originalId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      BaseEntityDto,
      'originalId'
    >;
  };

export type BaseEntityDto = SynchroPackageBaseEntityDto & {
  createdAt: string | null;
  /**
   * readonly, only available after the item has been synchronized to the server
   */
  createdByUserId: string | null;
  updatedAt: string | null;
  originalId: string | null;
};
