import { autoinject, bindable } from 'aurelia-framework';
import { PersonCategories } from 'common/Types/PersonCategories';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { NfcTokenToPerson } from '../../classes/EntityManager/entities/NfcTokenToPerson/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityNfcTagWidget } from '../../inputComponents/entity-nfc-tag-widget/entity-nfc-tag-widget';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class EditPersonWidget {
  @bindable public person: Person | null = null;

  /** if this is set, then all subEntities will be created with a temporaryGroupName + shadowEntity = true */
  @bindable public temporaryGroupName: string | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  private readonly subscriptionManager: SubscriptionManager;

  protected personCategories: PersonCategories = [];

  protected nfcTagRelationInfo = PersonUtils.nfcTagRelationInfo;

  protected entityNfcTagWidget: EntityNfcTagWidget<
    NfcTokenToPerson,
    Person
  > | null = null;

  constructor(
    private entityManager: AppEntityManager,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'person'
      });
  }

  // Aurealia Lifecycle

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.personCategories',
        (personCategories) => {
          this.personCategories = personCategories;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // Aurelia Change Handlers

  protected handlePersonChanged(): void {
    if (this.person) {
      this.entityManager.personRepository.update(this.person);
    }
  }

  // Aurelia Template Methods

  protected getUserGroupName(userGroupId: string): string {
    const userGroup =
      this.entityManager.userGroupRepository.getById(userGroupId);
    return userGroup && userGroup.name ? userGroup.name : '';
  }
}
