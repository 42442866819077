import { bindable, inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * this component shows the swiping progress based on the position of the currentItem in the items array
 */
@inject(SubscriptionManagerService)
export class SwipingIndicator {
  /**
   * @type {Array<*>}
   */
  @bindable items;

  /**
   * @type {*}
   */
  @bindable currentItem;

  _maxIndicators = 5;
  /**@type {Array<{index: number}>}*/
  _indicators = [];
  /**@type {number}*/
  _currentIndex;

  /**
   *
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(subscriptionManagerService) {
    this._itemsSubscriptionManager = subscriptionManagerService.create();
  }

  itemsChanged() {
    this._handleItemsChange();
    this._itemsSubscriptionManager.disposeSubscriptions();
    if (this.items) {
      this._itemsSubscriptionManager.subscribeToArrayChanges(
        this.items,
        this._handleItemsChange.bind(this)
      );
    }
  }

  currentItemChanged() {
    this._updateCurrentIndex();
  }

  _handleItemsChange() {
    this._updateCurrentIndex();
  }

  _updateCurrentIndex() {
    this._currentIndex = this.items ? this.items.indexOf(this.currentItem) : -1;
    this._updateIndicators();
  }

  _updateIndicators() {
    const itemsLength = this.items ? this.items.length : 0;
    const indicatorCount = Math.min(this._maxIndicators, itemsLength);
    const indicatorsToTheLeft = Math.ceil(indicatorCount / 2) - 1;
    const indicatorsToTheRight = Math.floor(indicatorCount / 2);
    const rightOffsetCorrection = Math.max(
      this._currentIndex + indicatorsToTheRight - (itemsLength - 1),
      0
    );
    const indicatorOffset = Math.max(
      this._currentIndex - indicatorsToTheLeft - rightOffsetCorrection,
      0
    );

    const indicatorCircles = [];
    for (let key = 0; key < indicatorCount; key++) {
      indicatorCircles.push({
        index: key + indicatorOffset
      });
    }
    this._indicatorCircles = indicatorCircles;
  }

  /**
   *
   * @param {number} index
   * @param {number} currentIndex
   * @returns {boolean}
   * @private
   */
  _indexIsCloseToCurrentIndex(index, currentIndex) {
    return Math.abs(index - currentIndex) === 1;
  }

  /**
   *
   * @param {number} index
   * @param {number} currentIndex
   * @returns {boolean}
   * @private
   */
  _indexIsFarAwayFromCurrentIndex(index, currentIndex) {
    return Math.abs(index - currentIndex) > 2;
  }
}
