import { bindable, autoinject } from 'aurelia-framework';

import {
  ProcessConfigurationPositionType,
  ProcessConfigurationPositionTypeToConfiguration,
  TProcessConfigurationPositionTypeConfiguration
} from 'common/Enums/ProcessConfigurationPositionType';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ClickableTextInput } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionProperty } from '../../classes/EntityManager/entities/Property/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event process-task-position-changed - fired when some value of the position has been changed by the user, e.g. you can notify the ProcessTaskPositionService that it has been updated here
 */
@autoinject()
export class ProcessTaskPositionEditWidget {
  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  private subscriptionManager: SubscriptionManager;
  private domElement: HTMLElement;

  @subscribableLifecycle()
  protected permissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskPosition];

  private processTaskGroup: ProcessTaskGroup | null = null;
  private properties: Array<ProcessTaskPositionProperty> = [];
  private isAttached: boolean = false;

  private typeConfiguration: TProcessConfigurationPositionTypeConfiguration | null =
    null;

  private positionAmountTextInputViewModel: ClickableTextInput | null = null;
  private ProcessConfigurationPositionType = ProcessConfigurationPositionType;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskPosition,
        context: this,
        expression: 'processTaskPosition'
      });
  }

  public focusInput(): void {
    if (!this.positionAmountTextInputViewModel) return;
    this.positionAmountTextInputViewModel.focus();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskPosition.type',
      this.updateTypeConfiguration.bind(this)
    );
    this.updateTypeConfiguration();

    this.updateProcessTaskGroup();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskPositionChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
      this.updateProcessTaskGroup();
    }
  }

  private updateProperties(): void {
    if (this.processTaskPosition) {
      this.properties =
        this.entityManager.propertyRepository.getByProcessTaskPositionId(
          this.processTaskPosition.id
        );
    } else {
      this.properties = [];
    }
  }

  private updateTypeConfiguration(): void {
    const type = this.processTaskPosition
      ? this.processTaskPosition.type
      : ProcessConfigurationPositionType.DEFAULT;
    this.typeConfiguration =
      ProcessConfigurationPositionTypeToConfiguration.get(type) || null;
  }

  private handleProcessTaskPositionChanged(property?: string): void {
    if (!this.processTaskPosition || !this.domElement) {
      return;
    }

    DomEventHelper.fireEvent(this.domElement, {
      name: 'process-task-position-changed',
      detail: { property }
    });
  }

  private updateProcessTaskGroup(): void {
    if (this.processTaskPosition) {
      this.processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          this.processTaskPosition.ownerProcessTaskGroupId
        );
    } else {
      this.processTaskGroup = null;
    }
  }

  private convertToString(value: any): string {
    return String(value);
  }
}
