import { Socket } from 'socket.io-client';

import {
  CheckAccessRequest,
  CheckAccessResponse,
  FolderExistsRequest,
  FolderExistsResponse,
  GetListColumnsRequest,
  GetListColumnsResponse,
  ListExistsRequest,
  ListExistsResponse,
  UploadReportFileRequest,
  UploadReportFileResponse
} from 'common/EndpointTypes/SharepointEndpointsHandler';

export class SharepointSocketEndpoints {
  constructor(private readonly io: Socket) {}

  public async checkSharepointAccess(
    request: CheckAccessRequest
  ): Promise<CheckAccessResponse> {
    return this.emitSocketCommand('check_sharepoint_access/1', request);
  }

  public async listExists(
    request: ListExistsRequest
  ): Promise<ListExistsResponse> {
    return this.emitSocketCommand('sharepoint_list_exists/1', request);
  }

  public async getListColumns(
    request: GetListColumnsRequest
  ): Promise<GetListColumnsResponse> {
    return this.emitSocketCommand('sharepoint_get_list_columns/1', request);
  }

  public async folderExists(
    request: FolderExistsRequest
  ): Promise<FolderExistsResponse> {
    return this.emitSocketCommand('sharepoint_folder_exists/1', request);
  }

  public async uploadReportFile(
    request: UploadReportFileRequest
  ): Promise<UploadReportFileResponse> {
    return this.emitSocketCommand('sharepoint_upload_report_file/1', request);
  }

  private emitSocketCommand<TRequest, TResponse>(
    socketCommand: string,
    request: TRequest
  ): Promise<TResponse> {
    return new Promise((resolve) => {
      this.io.emit(socketCommand, request, (response: TResponse) => {
        this.genericResolveFunction(socketCommand, response, resolve);
      });
    });
  }

  private genericResolveFunction<TResponse>(
    socketCommand: string,
    response: TResponse,
    resolve: (response: TResponse) => void
  ): void {
    console.log('SOCKET SERVICE:', socketCommand, response);
    resolve(response);
  }
}
