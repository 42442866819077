import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { PictureFileDto, PictureFileExtension } from './PictureFileDto';

export function createPictureFileEntityInfo<
  TEntity extends PictureFileDto<string, string>
>(
  options: CreateEntityInfoOptions<PictureFileDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.PictureFile,
  TEntity
> {
  return {
    entityName: EntityName.PictureFile,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PictureFileDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProcessTaskGroupSubEntityUtils.createOptionalBaseFieldInfos(),
        file_created: {
          name: 'file_created',
          defaultValue: null
        },
        file_uploaded: {
          name: 'file_uploaded',
          defaultValue: false
        },
        isOriginatingHere: {
          name: 'isOriginatingHere',
          defaultValue: false
        },
        local_created: {
          name: 'local_created',
          defaultValue: 0
        },
        file_extension: {
          name: 'file_extension',
          defaultValue: PictureFileExtension.JPG
        },
        type: {
          name: 'type'
        },
        project: {
          name: 'project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        picture: {
          name: 'picture',
          ref: EntityName.Picture,
          cascadeDeleteLocally: true
        },
        isBeingRenamed: {
          name: 'isBeingRenamed',
          defaultValue: false
        },
        readyForUpload: {
          name: 'readyForUpload',
          defaultValue: true
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        pictureRevisionId: {
          name: 'pictureRevisionId',
          ref: EntityName.PictureRevision,
          cascadeDeleteLocally: true,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
