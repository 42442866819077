import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { AppEntityManager } from '../AppEntityManager';
import { StructureTemplateEntry } from '../StructureTemplateEntry/types';
import { StructureTemplateStructureTemplateEntryProperty } from './types';
import { Entry } from '../Entry/types';
import { ApplyPropertiesService } from '../Property/ApplyPropertiesService';

export class StructureTemplateEntryPropertiesHelper {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly applyPropertiesService: ApplyPropertiesService
  ) {}

  public applyEntryPropertiesFromTemplate(
    entry: Entry,
    structureTemplateId: string
  ): void {
    const properties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        structureTemplateId
      );
    const project = this.entityManager.projectRepository.getById(entry.project);
    assertNotNullOrUndefined(project, 'entry has no project');

    const parent = entry.page_depth_parent
      ? this.entityManager.entryRepository.getById(entry.page_depth_parent)
      : null;
    assertNotNullOrUndefined(parent, 'entry does not have a parent');

    this.applyPropertiesService.applyCustomPropertiesToStructureEntry(
      properties,
      entry,
      project
    );
  }

  public getDefaultValueForStructureTemplateEntryProperty(
    entry: Entry,
    property: StructureTemplateStructureTemplateEntryProperty,
    structureTemplateId: string
  ): string | undefined {
    let currentEntry: Entry | null = entry;
    while (currentEntry) {
      const structureTemplateEntry = this.getStructureTemplateEntryOfEntry(
        currentEntry,
        structureTemplateId
      );
      assertNotNullOrUndefined(
        structureTemplateEntry,
        `no structure template entry found for entry id '${currentEntry.id}'`
      );
      const structureTemplateEntryProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateEntryId(
          structureTemplateEntry.id
        );
      const prop = structureTemplateEntryProperties.find((p) =>
        PropertyHelper.isTheSameProperty(p, property)
      );
      if (prop && prop.value) {
        return prop.value;
      }

      currentEntry = currentEntry.page_depth_parent
        ? this.entityManager.entryRepository.getRequiredById(
            currentEntry.page_depth_parent
          )
        : null;
    }

    const topLevelStructureTemplateProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        structureTemplateId
      );
    const prop = topLevelStructureTemplateProperties.find((p) =>
      PropertyHelper.isTheSameProperty(p, property)
    );

    return prop && prop.value ? prop.value : undefined;
  }

  private getStructureTemplateEntryOfEntry(
    entry: Entry,
    structureTemplateId: string
  ): StructureTemplateEntry | null {
    if (entry.structureTemplateEntryId) {
      return this.entityManager.structureTemplateEntryRepository.getById(
        entry.structureTemplateEntryId
      );
    } else if (entry.originId) {
      return this.entityManager.structureTemplateEntryRepository.getByStructureTemplateIdAndOriginId(
        structureTemplateId,
        entry.originId
      );
    }
    return null;
  }
}
