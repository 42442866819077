/**
 * This class provides the utility to register/unregister functions which then will be called by window.requestAnimationFrame
 */
export class UiUpdater {
  private static updateFunctions: Array<(delta?: number) => void> = [];
  private static initialized = false;
  private static lastUpdateTimestamp: number;

  private static boundRequestAnimationFrameHandler: (time: number) => void;
  private static animationFrameHandle: number | null = null;

  public static init(): void {
    if (this.initialized) {
      return;
    }

    this.boundRequestAnimationFrameHandler =
      this.requestAnimationFrameHandler.bind(this);

    this.initialized = true;
  }

  /**
   * this function makes use of the requestAnimationFrame method, which is more performance intensive but this will always render a valid state
   *
   * @param {(delta?: number) => void} func args(delta time since last update in ms)
   */
  public static registerUpdateFunction(func: (delta?: number) => void): void {
    if (this.updateFunctions.indexOf(func) === -1) {
      this.updateFunctions.push(func);
    }

    if (this.updateFunctions.length) {
      this.startUpdateLoop();
    }
  }

  public static unregisterUpdateFunction(func: () => void): void {
    const index = this.updateFunctions.indexOf(func);

    if (index >= 0) {
      this.updateFunctions.splice(index, 1);
    }

    if (this.updateFunctions.length === 0) {
      this.stopUpdateLoop();
    }
  }

  /**
   * this costs less performance than the registerUpdateFunction but it can't react to all changes
   */
  public static registerResizeUpdateFunction(func: () => void): void {
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', func);
    } else {
      window.addEventListener('resize', func);
    }
  }

  public static unregisterResizeUpdateFunction(func: () => void): void {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener('resize', func);
    } else {
      window.removeEventListener('resize', func);
    }
  }

  private static callUpdateFunctions(
    currentTimeStamp: DOMHighResTimeStamp
  ): void {
    const delta = currentTimeStamp - UiUpdater.lastUpdateTimestamp;

    for (const updateFunction of this.updateFunctions) {
      try {
        updateFunction(delta);
      } catch (exception) {
        console.error(exception);
      }
    }
  }

  private static startUpdateLoop(): void {
    this.lastUpdateTimestamp = performance.now() || Date.now();
    this.animationFrameHandle = window.requestAnimationFrame(
      this.boundRequestAnimationFrameHandler
    );
  }

  private static stopUpdateLoop(): void {
    if (this.animationFrameHandle) {
      window.cancelAnimationFrame(this.animationFrameHandle);
      this.animationFrameHandle = null;
    }
  }

  private static requestAnimationFrameHandler(timestamp: number): void {
    timestamp = timestamp || Date.now();
    this.callUpdateFunctions(timestamp);
    this.lastUpdateTimestamp = timestamp;
    this.animationFrameHandle = window.requestAnimationFrame(
      this.boundRequestAnimationFrameHandler
    );
  }
}

UiUpdater.init();
