import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationStep } from './types';

export class ProcessConfigurationStepRepository extends AppEntityRepository<EntityName.ProcessConfigurationStep> {
  public getOrderedProcessConfigurationStepsByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationStep> {
    return this.getAll()
      .filter((step) => {
        return step.ownerProcessConfigurationId === processConfigurationId;
      })
      .sort((a, b) => {
        return a.order - b.order;
      });
  }

  public getInspectionStepsByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationStep> {
    return this.getAll().filter(
      (s) =>
        s.ownerProcessConfigurationId === processConfigurationId && s.inspection
    );
  }
}
