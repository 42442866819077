/**
 * A container for an optional value of type `T`. If the value is present, it can be retrieved with `.getVal()`.
 *
 * TODO: Mark ValueContainer as deprecated - This replaces `ValueContainer` as a wrapper for optional types.
 */
export class Opt<T, IsSome extends boolean = boolean> {
  /**
   * Returns the internal value.
   *
   * Can only be called if the Opt has been explicitly verified as beeing present, by either being
   * typed as `Opt<T, true>` or having passed an `Opt#isSome()` check.
   *
   * @example
   * const opt: Opt<string> = ...
   *
   * if(opt.isSome()) {
   *   const val = opt.getVal();
   * }
   */
  public readonly getVal: [IsSome] extends [true] ? () => T : never;

  protected readonly discriminator: IsSome;

  private constructor(value: T | null, some: IsSome) {
    this.getVal = some ? () => value : (undefined as any);
    this.discriminator = some;
  }

  /**
   * Constructs a Opt. Represents an optional value that exists.
   *
   * @example
   * const stringOption = Opt.some("string");
   */
  public static some<T>(val: T): Opt<T, true> {
    return new Opt(val, true);
  }

  /**
   * Constructs an Opt. Represents an optional value that does not exist.
   *
   * @example
   * const stringOption = Opt.none<string>();
   */
  public static none<T>(): Opt<T, false> {
    return new Opt<T, false>(null, false);
  }

  /**
   * Returns `true` if the optional value of type `T` exists, `false` otherwise.
   *
   * @example
   * if(opt.isSome()) {
   *   // value is present and can be retrieved
   *   const value = opt.getVal();
   * }
   */
  public isSome(): this is Opt<T, true> {
    return !!this.getVal;
  }

  /**
   * Returns `true` if the optional value of type `T` does not exist, `false` otherwise.
   *
   * @example
   * if(opt.isNone()) {
   *   // value is not present
   * }
   */
  public isNone(): this is Opt<T, false> {
    return !this.getVal;
  }
}
