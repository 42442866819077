import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import {
  ComponentView,
  ViewCreationService
} from '../../services/ViewCreationService';
import {
  PropertySubWidgetComponent,
  propertyTypeToSubWidget
} from '../base-property-widget/propertyTypeToSubWidget/propertyTypeToSubWidget';
import { ContextualPropertyWidgetConfiguration } from '../base-property-widget/config/PropertyWidgetConfiguration/ContextualPropertyWidgetConfiguration';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetBindingFeatureMap } from '../base-property-widget/config/PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetBindingConfigurationUtils } from '../base-property-widget/config/PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfigurationUtils';
import { ContextualBasePropertyWidgetNotAllFeaturesImplemented } from './contextual-base-property-widget-not-all-features-implemented';

export class SubWidgetRenderer {
  private readonly element: Element;
  private readonly viewCreationService: ViewCreationService;
  private readonly onAfterRender: OnAfterRender;

  private targetRenderInfo: NextRenderInfo | null = null;
  private isRendering: boolean = false;
  private canRender: boolean = false;
  private renderedSubWidget: RenderedSubWidget = { typeInfo: null, view: null };

  constructor(options: {
    element: Element;
    viewCreationService: ViewCreationService;
    onAfterRender: OnAfterRender;
  }) {
    this.element = options.element;
    this.viewCreationService = options.viewCreationService;
    this.onAfterRender = options.onAfterRender;
  }

  public setTargetRenderInfo(targetRenderInfo: NextRenderInfo): void {
    this.targetRenderInfo = targetRenderInfo;
    this.tryStartRendering();
  }

  public getRenderedSubWidget(): PropertySubWidget | null {
    return this.renderedSubWidget.view?.getViewModel() ?? null;
  }

  public setCanRender(canRender: boolean): void {
    this.canRender = canRender;

    this.tryStartRendering();
  }

  private tryStartRendering(): void {
    if (this.canRender && !this.isRendering) {
      this.startRendering();
    }
  }

  private startRendering(): void {
    const typeInfo = this.getTargetTypeInfo();
    if (!this.typeInfosAreEqual(this.renderedSubWidget.typeInfo, typeInfo)) {
      if (typeInfo) {
        this.renderSubWidget({ typeInfo });
      } else {
        this.unrenderSubWidget();
      }
    } else {
      if (this.renderedSubWidget.view) {
        this.updateViewBindings(this.renderedSubWidget.view);
      }
    }
  }

  private getTargetTypeInfo(): RenderTypeInfo | null {
    const configuration = this.targetRenderInfo?.contextualConfiguration;
    if (!configuration) {
      return null;
    }

    return {
      type: configuration.binding.type,
      features: configuration.binding.features
    };
  }

  private renderSubWidget({ typeInfo }: { typeInfo: RenderTypeInfo }): void {
    this.unrenderSubWidget();

    const component = this.getComponentToRender({ typeInfo });
    if (!component) {
      return;
    }

    this.isRendering = true;
    void this.createView(component)
      .then((view) => {
        // this can happen if the type changes mid rendering
        if (
          this.targetRenderInfo?.contextualConfiguration?.binding.type !==
          typeInfo.type
        ) {
          this.startRendering();
        } else {
          this.acceptView({ typeInfo, view });
          this.isRendering = false;
          this.onAfterRender();
        }
      })
      .catch((e) => {
        this.isRendering = false;
        throw e;
      });
  }

  private getComponentToRender({
    typeInfo
  }: {
    typeInfo: RenderTypeInfo;
  }): PropertySubWidgetComponent | null {
    const subWidget = propertyTypeToSubWidget[typeInfo.type] ?? null;
    if (!subWidget) {
      console.error('unsupported property type ', typeInfo.type);
      return null;
    }

    const featuresAreImplemented =
      PropertyWidgetBindingConfigurationUtils.requiredFeaturesAreImplemented({
        requiredFeatures: subWidget.configuration.features,
        implementedFeatures: typeInfo.features
      });

    if (!featuresAreImplemented) {
      return ContextualBasePropertyWidgetNotAllFeaturesImplemented;
    }

    return subWidget.component;
  }

  private acceptView({
    typeInfo,
    view
  }: {
    typeInfo: RenderTypeInfo;
    view: ComponentView<PropertySubWidgetComponent>;
  }): void {
    view.attachToContainer(this.element);

    this.renderedSubWidget = {
      typeInfo,
      view
    };
  }

  private unrenderSubWidget(): void {
    this.renderedSubWidget.view?.detach();

    this.renderedSubWidget = {
      typeInfo: null,
      view: null
    };
  }

  private async createView(
    component: PropertySubWidgetComponent
  ): Promise<ComponentView<PropertySubWidgetComponent>> {
    const componentView = await this.viewCreationService.createViewForComponent(
      {
        component,
        initialBindables: {
          configuration: this.targetRenderInfo?.contextualConfiguration ?? null,
          style: this.targetRenderInfo?.style ?? null
        }
      }
    );
    componentView.getViewModel();

    return componentView;
  }

  private updateViewBindings(
    view: ComponentView<PropertySubWidgetComponent>
  ): void {
    const viewModel = view.getViewModel();
    viewModel.configuration =
      this.targetRenderInfo?.contextualConfiguration ?? null;
    viewModel.style = this.targetRenderInfo?.style ?? null;
  }

  private typeInfosAreEqual(
    a: RenderTypeInfo | null,
    b: RenderTypeInfo | null
  ): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}

export type OnAfterRender = () => void;

type RenderedSubWidget =
  | {
      typeInfo: RenderTypeInfo;
      view: ComponentView<PropertySubWidgetComponent>;
    }
  | {
      typeInfo: null;
      view: null;
    };

type NextRenderInfo = {
  contextualConfiguration: ContextualPropertyWidgetConfiguration<any> | null;
  style: PropertyWidgetStyle | null;
};

type RenderTypeInfo = {
  type: PropertyType;
  features: PropertyWidgetBindingFeatureMap<any>;
};
