import { PropertyOption } from '../Entities/Property/PropertyDto';

export enum FilterType {
  RANGE = 'range',
  TEXT = 'text',
  NUMBER = 'nummer',
  RADIOBUTTON = 'radiobutton',
  DROPDOWN = 'dropdown',
  MULTI_DROPDOWN = 'multi-dropdown',
  CHECKBOX = 'checkbox',
  PRIORITY = 'priority'
}

type BasePropertyFilter<TFilterType extends FilterType> = {
  type: TFilterType;
} & CommonBasePropertyFilter;

export type CommonBasePropertyFilter = {
  name: string;
  options?: Array<PropertyOption>;
  choices?: Array<string>;
};

export type FilterTypeToFilter = GenerateFilterTypeToFilter<{
  [FilterType.RANGE]: {
    rangeMin: number | null;
    rangeMax: number | null;
  };
  [FilterType.TEXT]: {
    value: string | null;
  };
  [FilterType.NUMBER]: {
    value: string | null;
  };
  [FilterType.RADIOBUTTON]: {
    value: string | null;
  };
  [FilterType.DROPDOWN]: {
    value: string | null;
  };
  [FilterType.MULTI_DROPDOWN]: {
    value: string | null;
  };
  [FilterType.CHECKBOX]: {
    value: string | null;
  };
  [FilterType.PRIORITY]: {
    value: string | null;
  };
}>;

export type PropertyFilter = FilterTypeToFilter[FilterType];

type GenerateFilterTypeToFilter<
  T extends Record<FilterType, Record<string, unknown>>
> = {
  [type in FilterType]: BasePropertyFilter<type> & T[type];
};
