import { bindable, autoinject } from 'aurelia-framework';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { PersonSearch } from './PersonSearch';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { watch } from '../../hooks/watch';
import { expression } from '../../hooks/dependencies';

/**
 * @event value-changed
 *
 * @slot pre-address - positioned right before the address inputs, useful for e.g. a "use address of x button"
 */
@autoinject()
export class PersonSelectAndEditWidget {
  @bindable()
  public value: string | null = null;

  @bindable()
  public selectedPerson: Person | null = null;

  @bindable()
  public userGroupId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  /**
   * readonly!
   */
  @bindable()
  public hasWarning: boolean = false;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  private readonly subscriptionManager: SubscriptionManager;
  private allPersonEmailContacts: Array<PersonContact> = [];
  private personEmailContact: PersonContact | null = null;
  protected duplicatePersons: Array<PersonWithName> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'selectedPerson'
      });

    this.updatePermissionsOverride();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updateAllPersonEmailContacts.bind(this)
    );
    this.updateAllPersonEmailContacts();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateDuplicatePersons.bind(this)
    );
    this.updateDuplicatePersons();

    this.updatePersonEmailContact();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private selectedPersonChanged(): void {
    if (this.isAttached) {
      this.updatePersonEmailContact();
      this.updateDuplicatePersons();
    }
  }

  private handlePersonSelectValueChanged(): void {
    this.fireValueChangedEvent();
  }

  private handleSelectedPersonChanged(doNotCreate?: boolean): void {
    if (!doNotCreate) this.createPersonIfNeeded();
    if (this.selectedPerson && this.selectedPerson.id)
      this.entityManager.personRepository.update(this.selectedPerson);
  }

  private handleEmailPersonContactChanged(
    personEmailContact: PersonContact | null
  ): void {
    this.personEmailContact = this.handlePersonContactChanged(
      PersonContactType.EMAIL,
      personEmailContact
    );
  }

  private handlePersonContactChanged(
    contactType: PersonContactType,
    personContact: PersonContact | null
  ): PersonContact | null {
    if (!personContact) {
      return null;
    }

    this.createPersonIfNeeded();

    /* contact is not null here because aurelia automatically  creates an empty object when values are modified,
       so we need to check it via the id if it is valid */
    if (personContact.id) {
      this.entityManager.personContactRepository.update(personContact);
      return personContact;
    } else {
      return this.createPersonContact(contactType, personContact);
    }
  }

  protected handleUsePersonClick(person: Person): void {
    const selectedPerson = this.selectedPerson;

    this.value = person.id;
    this.selectedPerson = person;

    setTimeout(() => {
      this.fireValueChangedEvent();

      if (selectedPerson && selectedPerson.shadowEntity) {
        this.entityManager.personRepository.delete(selectedPerson);
      }
    }, 0);
  }

  private updateAllPersonEmailContacts(): void {
    this.allPersonEmailContacts = this.entityManager.personContactRepository
      .getAll()
      .filter((pc) => pc.contactType === PersonContactType.EMAIL);
  }

  private updateDuplicatePersons(): void {
    this.duplicatePersons = this.findDuplicatePersons().map((person) => {
      return {
        person,
        name: PersonUtils.getPersonDisplayNameForPerson(person)
      };
    });

    this.hasWarning = !!this.duplicatePersons.length;
  }

  private createPersonIfNeeded(): void {
    /* person is not null here because aurelia automatically  creates an empty object when values are modified,
       so we need to check it via the id if it is valid */
    if (this.selectedPerson && this.selectedPerson.id) return;

    this.createPerson();
  }

  private createPerson(): Person | null {
    if (!this.userGroupId) return null;

    const person = this.entityManager.personRepository.create({
      ...this.selectedPerson,
      ownerUserGroupId: this.userGroupId,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.value = person.id;
    this.selectedPerson = person;

    setTimeout(this.fireValueChangedEvent.bind(this), 0);

    return person;
  }

  private createPersonContact(
    contactType: PersonContactType,
    contact: PersonContact
  ): PersonContact | null {
    if (!this.userGroupId || !this.selectedPerson) return null;
    this.createPersonIfNeeded();

    const personContact = this.entityManager.personContactRepository.create({
      ownerUserGroupId: this.userGroupId,
      personId: this.selectedPerson.id,
      contactType: contactType,
      name: contact.name,
      temporaryGroupName: this.selectedPerson.temporaryGroupName,
      shadowEntity: this.selectedPerson.shadowEntity
    });

    return personContact;
  }

  private updatePersonEmailContact(): void {
    if (!this.selectedPerson || !this.selectedPerson.id) {
      this.personEmailContact = null;
      return;
    }

    const contacts = this.entityManager.personContactRepository.getByPersonId(
      this.selectedPerson.id
    );

    this.personEmailContact =
      contacts.find((contact) => contact.contactType === 'email') || null;
  }

  private fireValueChangedEvent(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'value-changed',
      detail: null
    });
  }

  private findDuplicatePersons(): Array<Person> {
    if (!this.selectedPerson?.shadowEntity || !this.userGroupId) {
      return [];
    }

    const personSearch = new PersonSearch({
      entityManager: this.entityManager
    });

    return personSearch.search({
      company: this.selectedPerson.company,
      companyName: this.selectedPerson.companyName,
      firstName: this.selectedPerson.firstName,
      lastName: this.selectedPerson.lastName,
      streetName: this.selectedPerson.streetName,
      zip: this.selectedPerson.zip,
      personIdsToExclude: new Set([this.selectedPerson.id]),
      userGroupId: this.userGroupId
    });
  }

  private getEmailAutocompleteItems(
    allPersonEmailContacts: Array<PersonContact>,
    selectedPersonId: string | null
  ): Array<string> {
    const contacts = allPersonEmailContacts.filter(
      (contact) => !selectedPersonId || contact.personId !== selectedPersonId
    );
    return Array.from(
      new Set(
        contacts
          .map((contact) => contact.name)
          .filter((item): item is string => item != null && item !== '')
      )
    );
  }

  @watch(expression('selectedPerson.ownerUserGroupId'))
  protected updatePermissionsOverride(): void {
    // because a new person is created when inputting values, we need to enable the inputs if there is no person yet
    // if no ownerUserGroupId is set, we have a partial object from aurelia
    if (!this.selectedPerson?.ownerUserGroupId) {
      this.permissionsHandle.overrideAllPermissions(true);
    } else {
      this.permissionsHandle.overrideAllPermissions(null); // no need to override anything here
    }
  }
}

type PersonWithName = {
  person: Person;
  name: string;
};
