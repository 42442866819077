import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { computed } from '../../hooks/computed';
import { currentUser } from '../../hooks/dependencies';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { EditQuestionCatalogueDialog } from '../../dialogs/edit-question-catalogue-dialog/edit-question-catalogue-dialog';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class EditChecklistQuestionCatalogues {
  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  @computed(currentUser())
  protected get user(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  protected handleCreateCatalogueButtonClicked(
    event: CreateEntityClickedEvent
  ): void {
    const ownerUserGroupId = event.detail.userGroup?.id;
    if (ownerUserGroupId) {
      const questionCatalogue =
        this.entityManager.questionCatalogueRepository.create({
          ownerUserGroupId
        });
      void EditQuestionCatalogueDialog.open({
        questionCatalogue
      });
    }
  }
}
