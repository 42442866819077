import { LatLongArea } from 'common/Types/LatLongArea';
import { Picture } from './EntityManager/entities/Picture/types';

/**
 * Helper class for a lat-long area.
 */
export class LatLongAreaHelper {
  /**
   * @returns true if `latitude` & `longitude` are within the given `area`, false otherwise.
   */
  public static areCoordinatesInArea(
    area: LatLongArea,
    latitude: number,
    longitude: number
  ): boolean {
    const isLatitudeInArea =
      latitude >= area.minLatitude && latitude <= area.maxLatitude;
    const isLongitudeInArea =
      longitude >= area.minLongitude && longitude <= area.maxLongitude;
    return isLatitudeInArea && isLongitudeInArea;
  }

  /**
   * @returns true if the `picture` is within the given `area`.
   */
  public static isPictureInArea(area: LatLongArea, picture: Picture): boolean {
    if (
      !picture.coords ||
      !picture.coords.latitude ||
      !picture.coords.longitude
    ) {
      return false;
    }

    return this.areCoordinatesInArea(
      area,
      picture.coords.latitude,
      picture.coords.longitude
    );
  }
}
