export class MathHelper {
  /**
   * since the Math.sign function means the sign of 0 = 0 and in some occasions we want it to be +1 we have this function
   *
   * @param number
   * @returns {number}
   */
  static signWithoutZero(number) {
    return number >= 0 ? +1 : -1;
  }

  /**
   * convert degrees to radiant
   *
   * @param {number} degrees
   * @returns {number}
   */
  static degreesToRadiant(degrees) {
    return (degrees * Math.PI) / 180;
  }
}
