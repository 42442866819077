import { AngleHelper } from './AngleHelper';
import _ from 'lodash';

export class Vector {
  private static DEFAULT_OPTIONS = {
    mode: 'default',
    allowAngleFunctions: true
  };

  private x: number = 0;
  private y: number = 0;
  private options: VectorOptions;

  constructor(x: number, y: number, opts?: VectorOptions) {
    this.x = x;
    this.y = y;
    this.options = _.assign({}, Vector.DEFAULT_OPTIONS, opts || null);
  }

  public setX(x: number): this {
    this.x = x;

    return this;
  }

  public getX(): number {
    return this.x;
  }

  public setY(y: number): this {
    this.y = y;

    return this;
  }

  public getY(): number {
    return this.y;
  }

  public setXY(x: number, y: number): this {
    this.x = x;
    this.y = y;

    return this;
  }

  public clone(): Vector {
    return new Vector(this.x, this.y, this.options);
  }

  public addVector(vector: Vector): this {
    this.setX(this.getX() + vector.getX());
    this.setY(this.getY() + vector.getY());

    return this;
  }

  public substractVector(vector: Vector): this {
    this.setX(this.getX() - vector.getX());
    this.setY(this.getY() - vector.getY());

    return this;
  }

  public multiplyVector(vector: Vector): this {
    this.setX(this.getX() * vector.getX());
    this.setY(this.getY() * vector.getY());

    return this;
  }

  public divideVector(vector: Vector): this {
    this.setX(this.getX() / vector.getX());
    this.setY(this.getY() / vector.getY());

    return this;
  }

  public divideXY(x: number, y: number): this {
    this.setX(this.getX() / x);
    this.setY(this.getY() / y);

    return this;
  }

  public addXY(x: number, y: number): this {
    this.setX(this.getX() + x);
    this.setY(this.getY() + y);

    return this;
  }

  public substractXY(x: number, y: number): this {
    this.setX(this.getX() - x);
    this.setY(this.getY() - y);

    return this;
  }

  public multiplyXY(x: number, y: number): this {
    this.setX(this.getX() * x);
    this.setY(this.getY() * y);

    return this;
  }

  /**
   * scales x and y by the factor
   */
  public scale(factor: number): this {
    this.setX(this.getX() * factor);
    this.setY(this.getY() * factor);

    return this;
  }

  /**
   * @param {number} angle - in degrees
   * @returns {Vector} - this
   */
  public rotate(angle: number): this {
    this.checkCanUseAngleFunctions();

    const currentAngle = this.getAngle() ?? 0;
    this.setAngle(currentAngle + angle);

    return this;
  }

  /**
   * @param {number} angle - in degrees
   */
  public setAngle(angle: number): this {
    this.checkCanUseAngleFunctions();
    const length = this.getLength();
    const modificator = this.getXYModificatorForAngleCalculation();
    this.setX(Math.cos(AngleHelper.degToRad(angle)) * length * modificator.x);
    this.setY(Math.sin(AngleHelper.degToRad(angle)) * length * modificator.y);

    return this;
  }

  public getLength(): number {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  /**
   * returns null if no angle could be calculated (e.g. for a vector with length 0)
   *
   * @returns {number|null}
   */
  public getAngle(): number | null {
    this.checkCanUseAngleFunctions();
    const modificator = this.getXYModificatorForAngleCalculation();

    const xCalc = this.x * modificator.x;
    const yCalc = this.y * modificator.y;

    if (xCalc === 0) {
      if (yCalc === 0) {
        return null;
      }

      return yCalc > 0 ? 90 : 270;
    }

    if (yCalc === 0) {
      return xCalc > 0 ? 0 : 180;
    }

    const angle = AngleHelper.radToDeg(Math.atan(yCalc / xCalc));

    if (xCalc < 0 && yCalc > 0) {
      // quadrant 2
      return angle + 180;
    } else if (xCalc < 0 && yCalc < 0) {
      // quadrant 3
      return angle + 180;
    } else if (angle < 0) {
      // quadrant 4
      return angle + 360;
    } else {
      return angle;
    }
  }

  public toPlainObject(): PlainVector {
    return {
      x: this.getX(),
      y: this.getY()
    };
  }

  private checkCanUseAngleFunctions(): void {
    if (!this.options.allowAngleFunctions) {
      throw new Error("[Vector] this vector can't use angle functions");
    }
  }

  private getXYModificatorForAngleCalculation(): { x: 1; y: 1 | -1 } {
    switch (this.options.mode) {
      case VectorMode.HTML:
        return {
          x: 1,
          y: -1
        };

      case VectorMode.DEFAULT:
      default:
        return {
          x: 1,
          y: 1
        };
    }
  }

  public static createFromLongitudeLatitude(latLong: {
    latitude: number;
    longitude: number;
  }): Vector {
    return new Vector(latLong.longitude, latLong.latitude, {
      mode: VectorMode.DEFAULT,
      allowAngleFunctions: false
    });
  }

  public static createHtmlVector(x: number, y: number): Vector {
    return new Vector(x, y, { mode: VectorMode.HTML });
  }

  public static createFromPlainVector(plainVector: PlainVector): Vector {
    return new Vector(plainVector.x, plainVector.y);
  }
}

/** because the coordinate system in html/js is shifted (increasing y moves things to the bottom), the rotate/setAngle feature will break */
type VectorOptions = {
  mode: VectorMode;
  allowAngleFunctions?: boolean;
};

enum VectorMode {
  DEFAULT = 'default',
  HTML = 'html'
}

export type PlainVector = {
  x: number;
  y: number;
};
