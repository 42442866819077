import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { createThingAuthorizationEntityInfo } from 'common/Types/Entities/ThingAuthorization/createThingAuthorizationEntityInfo';
import { ThingAuthorization } from './types';

export const thingAuthorizationEntityInfo =
  createThingAuthorizationEntityInfo<ThingAuthorization>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
