import { autoinject, bindable } from 'aurelia-framework';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDeviceExportUtils } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/ProcessConfigurationDeviceExportUtils';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap
} from '../../computedValues/computers/ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class SendOperationsEmailDialogMiscSelect {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public deviceExportIds: Array<string> = [];

  @bindable()
  public exportMeasurePoints: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private devices: Array<ProcessTaskDevice> = [];
  private isAttached = false;
  private deviceExportsByProcessTaskGroupId: ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap =
    new Map();
  private availableProcessConfigurationDeviceExports: Array<ProcessConfigurationDeviceExport> =
    [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      this.updateDevices.bind(this)
    );
    this.updateDevices();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
        computeData: {},
        callback: (deviceExportsByProcessTaskGroupId) => {
          this.deviceExportsByProcessTaskGroupId =
            deviceExportsByProcessTaskGroupId;
          this.updateAvailableProcessConfigurationDeviceExports();
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateDevices();
    }
  }

  private updateDevices(): void {
    if (this.processTask) {
      this.devices =
        this.entityManager.processTaskDeviceRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        );
    } else {
      this.devices = [];
    }

    this.updateAvailableProcessConfigurationDeviceExports();
  }

  private updateAvailableProcessConfigurationDeviceExports(): void {
    if (this.processTask) {
      this.availableProcessConfigurationDeviceExports =
        ProcessConfigurationDeviceExportUtils.getAvailableProcessConfigurationDeviceExports(
          this.devices,
          this.deviceExportsByProcessTaskGroupId.get(
            this.processTask.ownerProcessTaskGroupId
          ) ?? []
        );
    } else {
      this.availableProcessConfigurationDeviceExports = [];
    }
  }

  private deviceExportIsSelected(
    deviceExport: ProcessConfigurationDeviceExport,
    deviceExportIds: Array<string>,
    _deviceExportIdsLength: number
  ): boolean {
    return deviceExportIds.includes(deviceExport.id);
  }

  private handleDeviceExportCheckedChangedEvent(
    deviceExport: ProcessConfigurationDeviceExport,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    if (event.detail.checked) {
      ArrayUtils.pushUnique(this.deviceExportIds, deviceExport.id);
    } else {
      ArrayUtils.remove(this.deviceExportIds, deviceExport.id);
    }
  }
}
