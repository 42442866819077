import { EntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { EntityWidgetHandle } from './EntityWidgetHandle';

export class EntityWidgetHandleUtils {
  public static compareHandles(
    a: EntityWidgetHandle,
    b: EntityWidgetHandle,
    dashboardInfoMap: Map<string, EntityDashboardInfo>
  ): number {
    const infoA = dashboardInfoMap.get(a.getEntityId());
    const infoB = dashboardInfoMap.get(b.getEntityId());

    const aStickyStatus = infoA?.sticky ?? false;
    const bStickyStatus = infoB?.sticky ?? false;

    const aLastOpenedAt = new Date(infoA?.lastOpenedAt ?? 0).getTime();
    const bLastOpenedAt = new Date(infoB?.lastOpenedAt ?? 0).getTime();

    const stickyTieBreaker = Number(bStickyStatus) - Number(aStickyStatus);
    if (stickyTieBreaker) return stickyTieBreaker;

    return bLastOpenedAt - aLastOpenedAt;
  }

  public static removeHiddenHandles<T extends EntityWidgetHandle>(
    handles: Array<T>,
    dashboardInfoMap: Map<string, EntityDashboardInfo>
  ): Array<T> {
    return handles.filter((h) => {
      return !dashboardInfoMap.get(h.getEntityId())?.hidden;
    });
  }
}
