import { autoinject } from 'aurelia-framework';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { MomentInput } from 'moment/moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Question } from '../../classes/EntityManager/entities/Question/types';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../inputComponents/text-choice-widget/text-choice-widget';
import {
  QuestionChoice,
  QuestionChoiceAction,
  QuestionChoiceActionType,
  QuestionUnit,
  RecommendationTemplate
} from 'common/Types/Entities/Question/QuestionDto';
import { configureHooks } from '../../hooks/configureHooks';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { QuestionCategory } from '../../classes/EntityManager/entities/QuestionCategory/types';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditQuestionDialog {
  protected question: Question | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: Options): void {
    this.question = options.question;
    this.dialog?.open();
  }

  @computed(model(EntityName.QuestionCategory))
  protected get availableQuestionCategories(): Array<QuestionCategory> {
    return this.entityManager.questionCategoryRepository.getAll();
  }

  @computed(expression('question'), model(EntityName.Question))
  protected get choiceStrings(): Array<string> {
    return this.question?.choices.map((choice) => choice.choiceText) ?? [];
  }

  @computed(expression('question.questionSetId'), model(EntityName.Question))
  protected get questionSet(): QuestionSet | null {
    if (!this.question) return null;

    return this.entityManager.questionSetRepository.getById(
      this.question.questionSetId
    );
  }

  @computed(
    expression('question.questionCategoryId'),
    model(EntityName.Question)
  )
  protected get currentQuestionCategory(): QuestionCategory | null {
    if (!this.question?.questionCategoryId) return null;

    return this.entityManager.questionCategoryRepository.getById(
      this.question.questionCategoryId
    );
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  /** Needed for unmounting hooks */
  protected handleDialogClosed(): void {}

  protected handleQuestionChanged(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionChanged without question'
    );

    this.entityManager.questionRepository.update(this.question);
  }

  protected handleChoiceAdded(evt: ChoiceAddedEvent): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleChoiceAdded without question'
    );

    const choice: QuestionChoice = {
      choiceText: evt.detail.choice,
      actions: []
    };

    if (this.question.choices.find((c) => c.choiceText === choice.choiceText)) {
      console.warn('cannot add a choice which already exists');
      return;
    }

    this.question.choices.push(choice);
    this.handleQuestionChanged();
  }

  protected handleChoiceRemoved(evt: ChoiceRemovedEvent): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleChoiceRemoved without question'
    );

    const choice = this.question.choices.find(
      (c) => c.choiceText === evt.detail.choice
    );

    ArrayUtils.remove(this.question.choices, choice);
    this.handleQuestionChanged();
  }

  protected handleAddUnitButtonClicked(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleAddUnitButtonClicked without question'
    );

    this.question.units.push({
      title: '',
      unit: ''
    });
    this.handleQuestionChanged();
  }

  protected handleDeleteUnitButtonClicked(unit: QuestionUnit): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleDeleteUnitButtonClicked without question'
    );

    this.question.units = this.question.units.filter((u) => u !== unit);
    this.handleQuestionChanged();
  }

  protected handleAddRecommendationTemplateButtonClicked(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleAddRecommendationTemplateButtonClicked without question'
    );

    this.question.recommendationTemplates.push({ content: '' });
    this.handleQuestionChanged();
  }

  protected handleDeleteRecommendationTemplateButtonClicked(
    recommendationTemplate: RecommendationTemplate
  ): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleDeleteRecommendationTemplateButtonClicked without question'
    );

    ArrayUtils.remove(
      this.question.recommendationTemplates,
      recommendationTemplate
    );
    this.handleQuestionChanged();
  }

  protected handleQuestionCategorySelected(
    questionCategoryId: string | null
  ): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionCategorySelected without question'
    );
    this.question.questionCategoryId = questionCategoryId;
    this.handleQuestionChanged();
  }

  protected handleDeleteActionButtonClicked(
    choice: QuestionChoice,
    action: QuestionChoiceAction
  ): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleDeleteActionButtonClicked without question'
    );
    assertNotNullOrUndefined(
      this.question.choices.find((c) => c === choice),
      'cannot handleDeleteActionButtonClicked without respective choice'
    );

    ArrayUtils.remove(choice.actions, action);
    this.entityManager.questionRepository.update(this.question);
  }

  protected handleAddActionButtonClicked(choice: QuestionChoice): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleAddActionButtonClicked without question'
    );
    assertNotNullOrUndefined(
      this.question.choices.find((c) => c === choice),
      'cannot handleAddActionButtonClicked without respective choice'
    );

    choice.actions.push({
      actionType: QuestionChoiceActionType.ADD_QUESTION,
      questionId: null,
      questionSetId: null,
      redirectButtonText: null,
      redirectUrl: null,
      dialogText: null,
      dialogTitle: null,
      dialogIconType: null
    });
    this.entityManager.questionRepository.update(this.question);
  }
}

type Options = {
  question: Question;
};
export type EditQuestionDialogOptions = Options;
