import { autoinject } from 'aurelia-dependency-injection';
import { TOptions } from 'i18next';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ProcessConfigurationAppointmentConfirmConfiguration } from 'common/Types/ProcessConfigurationAppointmentConfirmConfiguration';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PropertyInputField } from '../../aureliaComponents/property-input-field/property-input-field';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskAppointmentProperty } from '../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { SetProcessTaskCurrentProcessConfigurationStepIdService } from '../../services/SetProcessTaskCurrentProcessConfigurationStepIdService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskChecklistService } from '../../services/ProcessTaskChecklistService/ProcessTaskChecklistService';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class FinishProcessTaskAppointmentConfirmDialog {
  private static TEMPORARY_GROUP_NAME =
    'FinishProcessTaskAppointmentConfirmDialog';

  public static async open(
    options: FinishProcessTaskAppointmentConfirmDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly appointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  protected options: FinishProcessTaskAppointmentConfirmDialogOptions | null =
    null;

  protected confirmConfiguration: ProcessConfigurationAppointmentConfirmConfiguration | null =
    null;

  protected properties: Array<ProcessTaskAppointmentProperty> = [];
  protected notDonePositions: Array<ProcessTaskPosition> = [];
  protected notDoneDevices: Array<ProcessTaskDevice> = [];
  protected finishedAsPlanned: boolean | null = null;
  protected warnings: Array<WarningInfo> = [];

  protected dialog: RecordItDialog | null = null;
  protected propertyInputFieldsWrapper: HTMLElement | null = null;
  private finishedAsPlannedTimeout: number | null = null;

  private readonly exprEvalParser = new ExprEvalParser();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly setProcessTaskCurrentProcessConfigurationStepIdService: SetProcessTaskCurrentProcessConfigurationStepIdService,
    private readonly processTaskChecklistService: ProcessTaskChecklistService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.appointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskAppointment,
        context: this,
        expression: 'options.appointment'
      });
  }

  public open(options: FinishProcessTaskAppointmentConfirmDialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't FinishProcessTaskAppointmentConfirmDialog.open without dialog"
    );
    this.options = options;

    this.finishedAsPlanned = options.finishedAsPlanned;
    this.updateConfirmConfiguration(options.processTaskGroup);
    this.updateAppointmentProperties(options.appointment);

    this.dialog.open();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      this.updateNotDonePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateNotDonePositions.bind(this)
    );
    this.updateNotDonePositions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      this.updateNotDoneDevices.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      this.updateNotDoneDevices.bind(this)
    );
    this.updateNotDoneDevices();
  }

  protected handleDialogClosed(): void {
    this.options = null;
    this.finishedAsPlanned = null;
    this.notDonePositions = [];
    this.notDoneDevices = [];
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateNotDonePositions(): void {
    if (this.options) {
      const relations =
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
          this.options.appointment.id
        );
      const notDonePositionIds = relations
        .filter((r) => !r.done)
        .map((r) => r.processTaskPositionId);
      this.notDonePositions =
        this.entityManager.processTaskPositionRepository.getByIds(
          notDonePositionIds
        );
    } else {
      this.notDonePositions = [];
    }
  }

  private updateNotDoneDevices(): void {
    if (this.options) {
      const relations =
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentId(
          this.options.appointment.id
        );
      const deviceIds = relations.map((r) => r.processTaskDeviceId);
      this.notDoneDevices = this.entityManager.processTaskDeviceRepository
        .getByIds(deviceIds)
        .filter((device) => {
          return device.dateFrom && !device.dateTo;
        });
    } else {
      this.notDoneDevices = [];
    }
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      "can't FinishProcessTaskAppointmentConfirmDialog.handleAcceptButtonClicked without options"
    );
    assertNotNullOrUndefined(
      this.dialog,
      "can't FinishProcessTaskAppointmentConfirmDialog.handleAcceptButtonClicked without dialog"
    );

    if (!this.validate()) {
      return;
    }

    this.options.appointment.finishedAsPlanned = this.finishedAsPlanned;
    this.entityManager.processTaskAppointmentRepository.update(
      this.options.appointment
    );

    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      FinishProcessTaskAppointmentConfirmDialog.TEMPORARY_GROUP_NAME
    );
    this.resetNotVisibleProperties();

    this.createChecklistEntries(this.options.processTask);

    await this.moveToStep(
      this.options.processTask,
      this.options.processTaskGroup
    );

    this.options.onConfirm();
    this.dialog.close();
  }

  protected handleCancelButtonClicked(): void {
    assertNotNullOrUndefined(
      this.options,
      "can't FinishProcessTaskAppointmentConfirmDialog.handleCancelButtonClicked without options"
    );
    assertNotNullOrUndefined(
      this.dialog,
      "can't FinishProcessTaskAppointmentConfirmDialog.handleCancelButtonClicked without dialog"
    );

    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      FinishProcessTaskAppointmentConfirmDialog.TEMPORARY_GROUP_NAME
    );

    this.options.onCancel();
    this.dialog.close();
  }

  protected handleFinishedAsPlannedChanged(): void {
    if (this.finishedAsPlannedTimeout) {
      window.clearTimeout(this.finishedAsPlannedTimeout);
    }

    if (this.finishedAsPlanned !== false || this.properties.length === 0) {
      return;
    }

    this.finishedAsPlannedTimeout = window.setTimeout(() => {
      assertNotNullOrUndefined(
        this.propertyInputFieldsWrapper,
        "can't FinishProcessTaskAppointmentConfirmDialog.handleFinishedAsPlannedChanged without propertyInputFieldsWrapper"
      );
      const firstPropertyElement = this.propertyInputFieldsWrapper.children[0];
      assertNotNullOrUndefined(
        firstPropertyElement,
        'no firstPropertyElement found'
      );
      const viewModel =
        Utils.getViewModelOfElement<PropertyInputField>(firstPropertyElement);
      assertNotNullOrUndefined(viewModel, 'no property view model found');
      viewModel.focus();
    }, 0);
  }

  private validate(): boolean {
    const warnings: Array<WarningInfo> = [];

    if (this.finishedAsPlanned == null) {
      warnings.push({
        tk: 'operationsComponents.finishProcessTaskAppointmentConfirmDialog.finishedAsPlannedIsRequired',
        tkParams: {}
      });
    } else if (this.finishedAsPlanned === false) {
      warnings.push(...this.validateRequiredProperties());
    }

    this.warnings = warnings;
    return !warnings.length;
  }

  private validateRequiredProperties(): Array<WarningInfo> {
    const warnings: Array<WarningInfo> = [];

    for (const configuration of this.confirmConfiguration?.properties ?? []) {
      if (!configuration.required) {
        continue;
      }

      const property = this.properties.find(
        (p) => p.name === configuration.name && p.type === configuration.type
      );
      const value = property
        ? PropertyHelper.getPropertyText(
            property.type ?? PropertyType.TEXT,
            property.name,
            property.value,
            property.custom_choice
          )
        : null;
      if (!value) {
        warnings.push({
          tk: 'operationsComponents.finishProcessTaskAppointmentConfirmDialog.propertyIsRequired',
          tkParams: { property }
        });
      }
    }

    return warnings;
  }

  private updateConfirmConfiguration(processTaskGroup: ProcessTaskGroup): void {
    const configuration =
      this.entityManager.processConfigurationRepository.getById(
        processTaskGroup.processConfigurationId
      );
    this.confirmConfiguration =
      configuration?.appointmentConfirmConfigurationJson
        ? JSON.parse(configuration.appointmentConfirmConfigurationJson)
        : null;
  }

  private updateAppointmentProperties(
    appointment: ProcessTaskAppointment
  ): void {
    this.createMissingProperties(appointment);
    this.properties =
      this.entityManager.propertyRepository.getByProcessTaskAppointmentId(
        appointment.id
      );
  }

  private createMissingProperties(appointment: ProcessTaskAppointment): void {
    if (!this.confirmConfiguration) {
      return;
    }

    const existingProperties =
      this.entityManager.propertyRepository.getByProcessTaskAppointmentId(
        appointment.id
      );
    for (const [
      order,
      configuredProperty
    ] of this.confirmConfiguration.properties.entries()) {
      const existingProperty = existingProperties.find(
        (ep) =>
          ep.name === configuredProperty.name &&
          ep.type === configuredProperty.type
      );
      if (!existingProperty) {
        this.entityManager.propertyRepository.create({
          ...configuredProperty,
          order,
          processTaskAppointmentId: appointment.id,
          ownerUserGroupId: appointment.ownerUserGroupId,
          ownerProcessTaskId: appointment.ownerProcessTaskId,
          ownerProcessTaskGroupId: appointment.ownerProcessTaskGroupId,
          temporaryGroupName:
            FinishProcessTaskAppointmentConfirmDialog.TEMPORARY_GROUP_NAME,
          shadowEntity: true
        });
      }
    }
  }

  private resetNotVisibleProperties(): void {
    if (this.finishedAsPlanned !== false) {
      for (const property of this.properties) {
        property.value = '';
        this.entityManager.propertyRepository.update(property);
      }
    }
  }

  private createChecklistEntries(processTask: ProcessTask): void {
    if (this.finishedAsPlanned !== false) {
      return;
    }

    const context = this.createChecklistEntryTemplateContext();
    const configurations =
      this.confirmConfiguration?.checklistEntryConfigurations ?? [];
    for (const configuration of configurations) {
      const text = this.exprEvalParser.evaluateStringWithExpressions({
        template: configuration.template,
        context
      });

      void this.processTaskChecklistService.createChecklistEntryForProcessTask({
        processTask,
        text
      });
    }
  }

  private createChecklistEntryTemplateContext(): Record<string, any> {
    return {
      username: this.currentUserService.getRequiredCurrentUser().username,
      date: DateUtils.formatToDateString(new Date()),
      properties: this.properties.reduce<Record<string, string>>(
        (context, p) => {
          context[p.name ?? ''] = PropertyHelper.getPropertyText(
            p.type ?? PropertyType.TEXT,
            p.name,
            p.value,
            p.custom_choice
          );
          return context;
        },
        {}
      )
    };
  }

  private async moveToStep(
    processTask: ProcessTask,
    processTaskGroup: ProcessTaskGroup
  ): Promise<void> {
    if (this.finishedAsPlanned !== false) {
      return;
    }

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        processTaskGroup.processConfigurationId
      );
    const step = steps.find(
      (s) => s.name === this.confirmConfiguration?.notFinishedAsPlannedStepName
    );
    if (step) {
      await this.setProcessTaskCurrentProcessConfigurationStepIdService.setCurrentProcessConfigurationStepId(
        processTask,
        step.id
      );
    }
  }
}

export type FinishProcessTaskAppointmentConfirmDialogOptions = {
  processTask: ProcessTask;
  processTaskGroup: ProcessTaskGroup;
  appointment: ProcessTaskAppointment;
  /**
   * null for undetermined
   */
  finishedAsPlanned: boolean | null;
  onConfirm: () => void;
  onCancel: () => void;
};

export type WarningInfo = {
  tk: string;
  tkParams: TOptions;
};
