import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createUserDefinedEntityConfigEntityInfo } from 'common/Types/Entities/UserDefinedEntityConfig/createUserDefinedEntityConfigEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { UserDefinedEntityConfig } from './types';

export const userDefinedEntityConfigEntityInfo =
  createUserDefinedEntityConfigEntityInfo<UserDefinedEntityConfig>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
