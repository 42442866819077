import { SocketCommandEmitter } from './SocketCommandEmitter';
import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';

export class PdfSignerModuleEndpoints extends SocketCommandEmitter {
  public async sign(
    request: EndpointParameter<'pdfSignerModule', 'sign'>
  ): Promise<EndpointResult<'pdfSignerModule', 'sign'>> {
    return this.emitCommand('pdfSignerModule', 'sign', request);
  }
}
