import _ from 'lodash';
import {
  CapacitorSQLite,
  DBSQLiteValues,
  SQLiteConnection,
  SQLiteDBConnection
} from '@capacitor-community/sqlite';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';
import { DeviceInfoHelper } from '../../DeviceInfoHelper';
import { FileUtils } from '../../Utils/FileUtils/FileUtils';
import { AbstractStorageStrategy, KeyValue } from '../AbstractStorageStrategy';
import { JsonSerializer } from '../JsonSerializer';
import { storeInfo } from '../storeInfo';
import { StoreInfoUtils } from '../StoreInfoUtils';
import { SqliteMigrator } from './SqliteMigrator';
import { SqliteUtils } from './SqliteUtils';

export class SQLiteDataStorageStrategy extends AbstractStorageStrategy {
  private static MAX_ITEM_COUNT = 10000;

  private readonly serializer = new JsonSerializer();
  private store: SQLiteDBConnection | null = null;
  private storeWasCreated: boolean = false;

  public async init(): Promise<void> {
    await this.migrateOldStore();

    const { store, storeWasCreated } = await this.openStore();
    this.store = store;
    this.storeWasCreated = storeWasCreated;

    for (const tableName of StoreInfoUtils.getAllTableNames()) {
      await this.createTable(tableName);
    }
  }

  public async migrate(): Promise<void> {
    const migrator = new SqliteMigrator({ store: this.getRequiredStore() });
    await migrator.migrate({ storeWasCreated: this.storeWasCreated });
  }

  public async getItem(key: string, tableName: string): Promise<any> {
    const result = await this.getRequiredStore().query(
      `SELECT * FROM ${tableName} WHERE key = ? LIMIT 1`,
      [key]
    );

    const firstValue = this.serializer.deserialize(result.values?.[0]?.value);

    return firstValue ?? null;
  }

  public async getItems(tableName: string): Promise<Array<any>> {
    return this.selectAllItems({
      tableName,
      getItemFromRow: (row) => row.value
    });
  }

  public async getItemsWithKeys(tableName: string): Promise<Array<KeyValue>> {
    return this.selectAllItems({
      tableName,
      getItemFromRow: (row) => row
    });
  }

  public async setItem(
    key: string,
    value: any,
    tableName: string
  ): Promise<any> {
    await this.getRequiredStore().run(
      `INSERT OR REPLACE INTO ${tableName} (key, value) VALUES (?, ?)`,
      [key, this.serializer.serialize(value)]
    );
  }

  public async setItems(
    items: Array<KeyValue>,
    tableName: string
  ): Promise<void> {
    const chunks = _.chunk(items, 1000);

    for (const chunk of chunks) {
      await this.getRequiredStore().executeSet(
        chunk.map((i) => {
          return {
            statement: `INSERT OR REPLACE INTO ${tableName} (key, value) VALUES (?, ?)`,
            values: [i.key, this.serializer.serialize(i.value)]
          };
        })
      );
    }
  }

  public async removeItem(key: string, tableName: string): Promise<void> {
    await this.getRequiredStore().run(
      `DELETE FROM ${tableName} WHERE key = ?`,
      [key]
    );
  }

  public async removeItems(
    keys: Array<string>,
    tableName: string
  ): Promise<void> {
    const chunks = _.chunk(keys, 1000);

    for (const chunk of chunks) {
      if (chunk.length === 0) {
        continue; // empty chunks would be fatal, since everything will get deleted from the table then
      }

      // Sadly we can't simply use the IN Sql Operator since we somehow can't pass an array as an value for "?" and I couldn't find any documentation about it.
      const condition = chunk.map(() => 'key = ?').join(' OR ');

      await this.getRequiredStore().run(
        `DELETE FROM ${tableName} WHERE ${condition}`,
        chunk
      );
    }
  }

  /**
   * returns all stored keys inside the store
   */
  public async getKeys(tableName: string): Promise<Array<string>> {
    const results = await this.getRequiredStore().query(
      `SELECT key FROM ${tableName}`
    );

    return (results.values ?? []).map((item) => item.key);
  }

  public async clear(tableName: string): Promise<void> {
    await this.getRequiredStore().run(`DELETE FROM ${tableName}`);
  }

  // ********** Private Functions //////////

  private async selectAllItems<T>({
    tableName,
    getItemFromRow
  }: {
    tableName: string;
    getItemFromRow: (parsedRow: { key: string; value: any }) => T;
  }): Promise<Array<T>> {
    const store = this.getRequiredStore();
    const items: Array<T> = [];
    const limit = SQLiteDataStorageStrategy.MAX_ITEM_COUNT;
    let startId = 0;

    /* eslint-disable no-constant-condition */
    while (true) {
      const result = await this.selectItems({
        store,
        tableName,
        startId,
        limit,
        getItemFromRow
      });

      items.push(...result.items);

      if (result.items.length < limit) {
        break;
      }

      startId = result.lastId + 1;
    }

    return items;
  }

  private async selectItems<T>({
    store,
    tableName,
    startId,
    limit,
    getItemFromRow
  }: {
    store: SQLiteDBConnection;
    tableName: string;
    startId: number;
    limit: number;
    getItemFromRow: (parsedRow: { key: string; value: any }) => T;
  }): Promise<{ items: Array<T>; lastId: number }> {
    const results = await store.query(
      `SELECT * FROM ${tableName} WHERE id >= ? ORDER BY id ASC LIMIT ${limit}`,
      [startId]
    );

    return {
      items: this.resultsToItems({
        results,
        getItemFromRow
      }),
      lastId: results.values?.at(-1)?.id ?? 0
    };
  }

  private resultsToItems<T>({
    results,
    getItemFromRow
  }: {
    results: DBSQLiteValues;
    getItemFromRow: (parsedRow: { key: string; value: any }) => T;
  }): Array<T> {
    const items: Array<T> = [];

    for (const rawRow of results.values ?? []) {
      items.push(
        getItemFromRow({
          key: rawRow.key,
          value: this.serializer.deserialize(rawRow.value)
        })
      );
    }

    return items;
  }

  private async createTable(tableName: string): Promise<void> {
    await SqliteUtils.createKeyValueTable({
      store: this.getRequiredStore(),
      tableName,
      ifNotExists: true,
      transaction: true
    });
  }

  private async openStore(): Promise<{
    store: SQLiteDBConnection;
    storeWasCreated: boolean;
  }> {
    const rootConnection = new SQLiteConnection(CapacitorSQLite);

    // If the page is reloaded, then a connection will already exist in the background/plugin.
    // Sadly we can't use `retrieveConnection` or `retrieveAllConnections` because they just won't work.
    // `retrieveConnection` will tell us that the connection doesn't exist, but then if we use `createConnection` it will complain that the connection already exists.
    // Seems like one of those functions is lying to us :thinking:
    // Just to be sure we will just close the connection (we can't reuse it anyway, since it's not accessible) and create a new one.
    try {
      await rootConnection.closeConnection(storeInfo.name, false);
    } catch (error) {
      // Ignore the error if there was no connection, since that's our goal here.
      if (
        !(error instanceof Error) ||
        error.message !==
          'CloseConnection: No available connection for database recordIT'
      ) {
        throw error;
      }
    }

    const existingDatabases = await this.getDataBaseList({ rootConnection });

    const store = await rootConnection.createConnection(
      storeInfo.name,
      false,
      'no-encryption',
      3,
      false
    );
    await store.open();

    return {
      store,
      storeWasCreated: !existingDatabases.includes(`${storeInfo.name}SQLite.db`)
    };
  }

  private getRequiredStore(): SQLiteDBConnection {
    assertNotNullOrUndefined(
      this.store,
      'no store is available, is the SQLiteDataStorageStrategy initialized?'
    );
    return this.store;
  }

  private async getDataBaseList({
    rootConnection
  }: {
    rootConnection: SQLiteConnection;
  }): Promise<Array<string>> {
    try {
      const result = await rootConnection.getDatabaseList();
      return result.values ?? [];
    } catch (error) {
      // Idk what's the reasoning behind throwing an error for this instead of just returning an empty list.
      // Also we check the message only with `includes` because there was an extra trailing space in the end and we want to make it as stable as possible
      if (
        error instanceof Error &&
        error.message.includes('No databases available')
      ) {
        return [];
      }

      throw error;
    }
  }

  private async migrateOldStore(): Promise<void> {
    assertNotNullOrUndefined(
      window.cordova,
      "can't SQLiteDataStorageStrategy.migrateOldStores without cordova"
    );
    const oldFileName = storeInfo.name;
    const newFileName = `${storeInfo.name}SQLite.db`;

    let path: string;
    let directoryName: string;

    if (DeviceInfoHelper.isIOSDevice()) {
      path = PathUtils.joinPaths(
        window.cordova.file.applicationStorageDirectory,
        'Library'
      );
      directoryName = 'LocalDatabase';
    } else {
      path = window.cordova.file.applicationStorageDirectory;
      directoryName = 'databases';
    }

    const directory = await FileUtils.ensureDirectory(path, directoryName);
    try {
      const oldFile = await directory.getFile(oldFileName);
      await oldFile.moveTo(directory, newFileName);
    } catch (error) {
      if (
        !(error instanceof FileError) ||
        error.code !== FileError.NOT_FOUND_ERR
      ) {
        throw error;
      }
    }
  }
}
