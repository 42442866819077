import { autoinject, bindable } from 'aurelia-framework';
import { DefectStatus } from '../../../../common/src/Enums/DefectStatus';

/**
 * A small defect icon that changes background color
 * depending on the status of the defect.
 */
@autoinject()
export class DefectStatusIcon {
  @bindable public status: DefectStatus | null = null;
}
