import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event {ProcessTaskGroupChangedEvent} process-task-group-changed
 */
@autoinject()
export class ProcessTaskGroupThingGroupOwnerPersonSelect {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly thingGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingGroup];

  protected thingGroupOwnerPersonCategory: string | null = null;
  private thingGroup: ThingGroup | null = null;
  private isAttached: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.thingGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingGroup,
        context: this,
        expression: 'thingGroup'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingGroupOwnerPersonCategory',
        (thingGroupOwnerPersonCategory) => {
          this.thingGroupOwnerPersonCategory = thingGroupOwnerPersonCategory;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      this.updateThingGroup.bind(this)
    );
    this.updateThingGroup();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateThingGroup();
    }
  }

  private updateThingGroup(): void {
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    } else {
      this.thingGroup = null;
    }
  }

  protected handleUseOwnerPersonIdForThingGroupClick(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      "can't ProcessTaskGroupThingGroupOwnerPersonSelect.handleUseOwnerPersonIdForThingGroupClick without processTaskGroup"
    );
    assertNotNullOrUndefined(
      this.thingGroup,
      "can't ProcessTaskGroupThingGroupOwnerPersonSelect.handleUseOwnerPersonIdForThingGroupClick without thingGroup"
    );

    this.thingGroup.ownerPersonId =
      this.processTaskGroup.thingGroupOwnerPersonId;
    this.entityManager.thingGroupRepository.update(this.thingGroup);
  }

  protected handlePersonSelected(): void {
    DomEventHelper.fireEvent<ProcessTaskGroupChangedEvent>(this.element, {
      name: 'process-task-group-changed',
      detail: null
    });
  }
}

export type ProcessTaskGroupChangedEvent = NamedCustomEvent<
  'process-task-group-changed',
  null
>;
