import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { RecordItHeader } from '../record-it-header/record-it-header';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { UiUpdater } from '../../classes/UiUpdater';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Utils } from '../../classes/Utils/Utils';

/**
 * Fades out an element, and slides a bigger widget in its place.
 * Useful for showing editing widgets of certain list elements.
 *
 * Due to performance concerns, only one widget-overlay should exist per list. It has to be in the same container (position relative)
 * as the elements returned by the entryElementGetter. There must not be a container with a `position` css property between the entry
 * and the container.
 *
 * @event overlay-closed - fired when the overlay has been completely closed
 * detail: {entry: Entry}
 *
 * @example
 * <div class="EntryListWrapper" style="position: relative">
 *   <entry-list-item repeat.for="entry of entries" ...></entry-list-item>
 *   <widget-overlay ...></widget-overlay>
 * </div>
 */
@autoinject()
export class WidgetOverlay {
  /**
   * The HTMLElement this widget-overlay shall replace once opened.
   * If you are using this widget-overlay in a list, this will most likely be the list item.
   *
   * You can optionally leave this empty if you don't want this overlay to look like it's part of the list.
   */
  @bindable public itemRef: HTMLElement | null = null;

  /**
   * Whether this overlay is open or not.
   *
   * This controls the state of the overlay and optionally triggers an animation whenever the state changes.
   */
  @bindable public isOpen: boolean = false;

  /**
   * Whether an animation should be played when the overlay is opened / closed.
   */
  @bindable public isAnimated: boolean = true;

  /**
   * A reference to this html template element.
   */
  protected widgetOverlayRef: HTMLElement;

  /**
   * A reference to the wrapper element around the widget.
   */
  protected wrapperElementRef: HTMLElement | null = null;

  /**
   * The height of the wrapper element once fully opened.
   * Should be the full screen size minus the header height.
   */
  private wrapperHeight = 0;

  /**
   * How many pixels of offset between the widget-overlay and the top of the container
   */
  private readonly scrollToTopOffset = 10;

  /**
   * Is set to true while elements are being animated, false otherwise.
   */
  private isCurrentlyAnimating = false;

  private wrapperElementAnimator: ShowHideAnimator | null = null;

  /**
   * Only returns true if `isOpen` is true AND the opening animation has finished playing.
   */
  protected completelyOpened = false;

  constructor(element: Element) {
    this.widgetOverlayRef = element as HTMLElement;
  }

  // /////////// LIFECYCLE /////////////

  protected bind(): void {
    assertNotNullOrUndefined(
      this.wrapperElementRef,
      "can't register ShowHideAnimator without wrapperElementRef"
    );
    this.wrapperElementAnimator = new ShowHideAnimator(this.wrapperElementRef);
  }

  protected detached(): void {
    SiteScrollLocker.unlockScrolling('widget-overlay');
    UiUpdater.unregisterResizeUpdateFunction(this.boundHandleResize);
  }

  // /////////// METHODS /////////////

  /**
   * Fade out itemRef and open the overlay with an optional slide-down animation,
   * while sliding down the placeholder component as well (with the `widgetElementAnimator`)
   * to properly reposition the html elements below the widget overlay.
   */
  private async openOverlay(): Promise<void> {
    assertNotNullOrUndefined(
      this.wrapperElementAnimator,
      "can't open overlay without an animator."
    );

    this.completelyOpened = false;
    this.wrapperHeight = this.calculateWrapperHeight();

    SiteScrollLocker.lockScrolling('widget-overlay');
    UiUpdater.registerResizeUpdateFunction(this.boundHandleResize);

    const animationDuration = this.isAnimated ? 450 : 0;
    this.wrapperElementAnimator.options.defaultAnimationDuration =
      animationDuration;

    await Promise.all([
      this.wrapperElementAnimator.fadeSlideDown(this.wrapperHeight),
      this.scrollToReferenceElement(animationDuration)
    ]);

    this.completelyOpened = true;
  }

  /**
   * Close the overlay by sliding up the widget-overlay & the placeholder, and fade itemRef in again.
   * @param isAnimated plays a slide-up closing animation if true, opens without an animation otherwise.
   */
  private async closeOverlay(): Promise<void> {
    assertNotNullOrUndefined(
      this.referenceElement,
      "can't close overlay without an reference element."
    );
    assertNotNullOrUndefined(
      this.wrapperElementAnimator,
      "can't close overlay without an animator."
    );

    const animationDuration = this.isAnimated ? 450 : 0;
    this.wrapperElementAnimator.options.defaultAnimationDuration =
      animationDuration;

    const refElementScrollTop = ScrollHelper.getCenteredScrollTopForElement(
      this.referenceElement
    );
    const scrollElement = ScrollHelper.getMainScrollingElement();
    const maxScrollTop = Math.max(
      scrollElement.scrollHeight -
        this.widgetOverlayRef.clientHeight -
        scrollElement.clientHeight,
      0
    );
    const scrollPos = Math.min(refElementScrollTop, maxScrollTop);

    await Promise.all([
      this.wrapperElementAnimator.fadeSlideUp(),
      ScrollHelper.scrollToPosition(scrollPos, animationDuration)
    ]);

    SiteScrollLocker.unlockScrolling('widget-overlay');
    UiUpdater.unregisterResizeUpdateFunction(this.boundHandleResize);

    DomEventHelper.fireEvent(this.widgetOverlayRef, {
      name: 'overlay-closed',
      detail: null
    });
  }

  private async scrollToReferenceElement(
    animationDuration: number = 0
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.referenceElement,
      "can't scroll to reference element if it's undefined!"
    );

    await ScrollHelper.scrollToItem(this.referenceElement, animationDuration, {
      topOffset: this.scrollToTopOffset
    });
  }

  private handleResize(): void {
    this.wrapperHeight = this.calculateWrapperHeight();
    void this.scrollToReferenceElement();
  }

  private boundHandleResize = this.handleResize.bind(this);

  private calculateWrapperHeight(): number {
    const headerHeight = RecordItHeader.getHeaderHeight() || 0;
    return document.documentElement.clientHeight - headerHeight - 10;
  }

  // /////////// UPDATERS /////////////

  protected itemRefChanged(): void {
    if (this.isOpen) {
      void this.scrollToReferenceElement();
    }
  }

  protected async isOpenChanged(): Promise<void> {
    // Set isCurrentlyAnimating to true so other animations don't start in the meantime
    this.isCurrentlyAnimating = true;
    await Utils.wait(20);
    this.isOpen ? await this.openOverlay() : await this.closeOverlay();
    this.isCurrentlyAnimating = false;
  }

  // /////////// GETTERS /////////////

  /**
   * The element to scroll to when opening the overlay.
   *
   * Returns the itemRef, if defined.
   * Otherwise, the parent of this template element.
   */
  @computedFrom('itemRef', 'widgetOverlayRef')
  private get referenceElement(): HTMLElement | null {
    return (
      this.itemRef || (this.widgetOverlayRef.offsetParent as HTMLElement | null)
    );
  }

  /**
   * The offset from the top of the itemRef.
   * If itemRef is zero, the offset is set to zero.
   */
  @computedFrom('itemRef')
  private get wrapperTopOffset(): number {
    return this.itemRef ? this.itemRef.offsetTop : 0;
  }

  // /////////// HTML GETTERS /////////////

  @computedFrom('isOpen', 'isCurrentlyAnimating')
  protected get widgetOverlayStyling(): any {
    return {
      display: this.isOpen || this.isCurrentlyAnimating ? 'block' : 'none'
    };
  }

  /**
   * CSS Properties for the wrapper element.
   */
  @computedFrom('wrapperTopOffset', 'wrapperHeight')
  protected get wrapperElementStyling(): any {
    return {
      top: `${this.wrapperTopOffset}px`,
      height: `${this.wrapperHeight}px`
    };
  }
}
