import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { PropertyType } from '../Property/PropertyDto';
import {
  ThingSectionConfigPropertyDto,
  ThingSectionConfigPropertyLocation
} from './ThingSectionConfigPropertyDto';

export function createThingSectionConfigPropertyEntityInfo<
  TEntity extends ThingSectionConfigPropertyDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ThingSectionConfigPropertyDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingSectionConfigProperty,
  TEntity
> {
  return {
    entityName: EntityName.ThingSectionConfigProperty,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingSectionConfigPropertyDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: PropertyType.TEXT
        },
        order: {
          name: 'order',
          defaultValue: 0
        },
        choices: {
          name: 'choices',
          defaultValue: () => []
        },
        options: {
          name: 'options',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              value: {
                name: 'value'
              }
            }
          ],
          defaultValue: () => []
        },
        location: {
          name: 'location',
          defaultValue: ThingSectionConfigPropertyLocation.THING
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
