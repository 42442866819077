import { combineLatest, map, Observable, shareReplay } from 'rxjs';

import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { RxjsService } from '../../../../services/RxjsService/RxjsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { StructureTemplateEntryPropertyDefinitionHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/StructureTemplateEntryPropertyDefinitionHandle/StructureTemplateEntryPropertyDefinitionHandle';
import {
  ManagePropertyDefinitionsBaseWidgetAdapter,
  ManagePropertyDefinitionsWidgetAdapterDeleteOptions,
  ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions
} from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsBaseWidgetAdapter';
import { ManagePropertyDefinitionsWidgetAdapterHandleCache } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapterHandleCache';

export class ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter
  implements
    ManagePropertyDefinitionsBaseWidgetAdapter<StructureTemplateEntryProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly handleCache: ManagePropertyDefinitionsWidgetAdapterHandleCache<
    StructureTemplateEntryProperty,
    StructureTemplateEntryPropertyDefinitionHandle
  >;

  private readonly structureTemplate: StructureTemplate;
  private readonly isEditableAndNotArchived$: Observable<boolean>;

  constructor(
    options: ManageDefinedPropertiesWidgetStructureTemplateEntryAdapterOptions
  ) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.structureTemplate = options.structureTemplate;

    const { isEditableAndNotArchived$, isEditableDraftOrProvisionallyActive$ } =
      this.createPermissionObservables({
        rxjsService: options.rxjsService,
        enabled$: options.enabled$,
        structureTemplate: options.structureTemplate
      });

    this.isEditableAndNotArchived$ = isEditableAndNotArchived$;

    this.handleCache = new ManagePropertyDefinitionsWidgetAdapterHandleCache({
      createPropertyDefinitionHandle: ({ propertyDefinition }) => {
        return new StructureTemplateEntryPropertyDefinitionHandle({
          entityManager: options.entityManager,
          property: propertyDefinition,
          canEditDefinition$: isEditableDraftOrProvisionallyActive$,
          canEditValue$: isEditableAndNotArchived$
        });
      }
    });
  }

  public subscribe({
    setPropertyDefinitionHandles,
    setCanCreatePropertyDefinitions
  }: ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<StructureTemplateEntryProperty>): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updatePropertyDefinitions } =
      this.handleCache.createUpdatePropertyDefinitionsFunction({
        setPropertyDefinitionHandles,
        getPropertyDefinitions: () => {
          return this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
            this.structureTemplate.id
          );
        }
      });

    subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      updatePropertyDefinitions
    );
    updatePropertyDefinitions();

    subscriptionManager.addRxjsSubscription(
      this.isEditableAndNotArchived$.subscribe(setCanCreatePropertyDefinitions)
    );

    return subscriptionManager.toDisposable();
  }

  public createPropertyDefinition(): StructureTemplateEntryPropertyDefinitionHandle {
    const property =
      this.entityManager.structureTemplateEntryPropertyRepository.create({
        ownerStructureTemplateId: this.structureTemplate.id,
        ownerUserGroupId: this.structureTemplate.ownerUserGroupId
      });

    return this.handleCache.createCachedPropertyDefinitionHandle({
      propertyDefinition: property
    });
  }

  public async deletePropertyDefinition({
    propertyDefinition
  }: ManagePropertyDefinitionsWidgetAdapterDeleteOptions<StructureTemplateEntryProperty>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      propertyDefinition,
      EntityName.Property
    ).then(() => {
      this.entityManager.structureTemplateEntryPropertyRepository.delete(
        propertyDefinition
      );
    });
  }

  public arePropertyDefinitionsEqual(
    p1: StructureTemplateEntryProperty,
    p2: StructureTemplateEntryProperty
  ): boolean {
    return PropertyHelper.isTheSameProperty(p1, p2);
  }

  private createPermissionObservables({
    structureTemplate,
    enabled$,
    rxjsService
  }: {
    structureTemplate: StructureTemplate;
    enabled$: Observable<boolean>;
    rxjsService: RxjsService;
  }): {
    isEditableDraftOrProvisionallyActive$: Observable<boolean>;
    isEditableAndNotArchived$: Observable<boolean>;
  } {
    const status$ = rxjsService.fromExpression<StructureTemplate['status']>({
      context: structureTemplate,
      expression: 'status'
    });

    const enabledAndStatus$ = combineLatest([enabled$, status$]);

    const isEditableDraftOrProvisionallyActive$ = enabledAndStatus$.pipe(
      map(([enabled, status]) => {
        return (
          enabled &&
          (status === StructureTemplateStatus.DRAFT ||
            status === StructureTemplateStatus.PROVISIONALLY_ACTIVE)
        );
      }),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );

    const isEditableAndNotArchived$ = enabledAndStatus$.pipe(
      map(([enabled, status]) => {
        return enabled && status !== StructureTemplateStatus.ARCHIVED;
      }),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );

    return {
      isEditableDraftOrProvisionallyActive$,
      isEditableAndNotArchived$
    };
  }
}

export type ManageDefinedPropertiesWidgetStructureTemplateEntryAdapterOptions =
  {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    rxjsService: RxjsService;
    structureTemplate: StructureTemplate;
    enabled$: Observable<boolean>;
  };
