import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskMeasurePointDialog } from '../edit-process-task-measure-point-dialog/edit-process-task-measure-point-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { ProcessTaskMeasurePointMarkingDialog } from '../process-task-measure-point-marking-dialog/process-task-measure-point-marking-dialog';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { FileDownloadService } from '../../services/FileDownloadService';

@inject(
  FileDownloadService,
  AppEntityManager,
  SubscriptionManagerService,
  SocketService
)
export class ProcessTaskMeasurePointOverviewWidget {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /**
   * null if measurePoints aren't loaded
   * read only
   *
   * @type {number|null}
   */
  @bindable processTaskMeasurePointCount = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint>} */
  _measurePoints = [];
  /** @type {boolean} */
  _attached = false;
  /** @type {import('../../classes/EntityManager/entities/Picture/types').Picture|null} */
  _displayedPicture = null;

  /**
   * @param {FileDownloadService} fileDownloadService
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {SocketService} socketService
   */
  constructor(
    fileDownloadService,
    entityManager,
    subscriptionManagerService,
    socketService
  ) {
    this._fileDownloadService = fileDownloadService;

    this._entityManager = entityManager;

    this._subscriptionManager = subscriptionManagerService.create();
    this._socketService = socketService;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePoint,
      this._updateMeasurePoints.bind(this)
    );
    this._updateMeasurePoints();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskChanged() {
    if (this._attached) {
      this._updateMeasurePoints();
    }
  }

  _updateMeasurePoints() {
    if (this.processTask) {
      this._measurePoints =
        this._entityManager.processTaskMeasurePointRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.processTaskMeasurePointCount = this._measurePoints.length;
    } else {
      this._measurePoints = [];
      this.processTaskMeasurePointCount = null;
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   */
  _handleEditMeasurePointClick(measurePoint) {
    EditProcessTaskMeasurePointDialog.open({
      measurePoint: measurePoint,
      onDialogClosed: () => {
        this._goToMeasurePoint(measurePoint);
      }
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   */
  _handleMarkMeasurePointClick(measurePoint) {
    if (!this._displayedPicture || !this.processTaskGroup) {
      return;
    }

    ProcessTaskMeasurePointMarkingDialog.open({
      picture: this._displayedPicture,
      measurePoint: measurePoint,
      processTaskGroup: this.processTaskGroup
    });
  }

  /**
   * @param {import('../process-task-measure-point-markings-overlay/process-task-measure-point-markings-overlay').TMeasurePointClickedEvent} event
   */
  _handlePictureMeasurePointClicked(event) {
    this._goToMeasurePoint(event.detail.measurePoint);
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   */
  _goToMeasurePoint(measurePoint) {
    ScrollHelper.autoScrollToListItem(
      '#' + this._getMeasurePointListItemElementId(measurePoint.id),
      null,
      measurePoint,
      () => this._attached
    );
  }

  /**
   * @param {string} measurePointId
   * @returns {string}
   */
  _getMeasurePointListItemElementId(measurePointId) {
    return (
      'process-task-measure-point-overview-widget--measure-point-' +
      measurePointId
    );
  }

  _handleExportButtonClick() {
    if (!this.processTask) {
      return;
    }

    this._socketService.downloadMeasurePoints(
      { processTaskId: this.processTask.id },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this._fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${
            response.status ? response.status : 'unspecifiedError'
          }`;
          Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }
}
