import { autoinject, bindable } from 'aurelia-framework';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { MomentInput } from 'moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EditQuestionCatalogueDialog } from '../../dialogs/edit-question-catalogue-dialog/edit-question-catalogue-dialog';
import { Router } from 'aurelia-router';

@autoinject()
export class ChecklistQuestionCatalogueListItem {
  @bindable public questionCatalogue: QuestionCatalogue | null = null;

  protected panelOpen = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly router: Router
  ) {}

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEnterQuestionCatalogueClick(): void {
    assertNotNullOrUndefined(
      this.questionCatalogue,
      'cannot handleEnterQuestionCatalogueClick without questionCatalogue'
    );

    this.router.navigateToRoute('edit_checklist_question_sets', {
      question_catalogue_id: this.questionCatalogue.id
    });
  }

  protected handleEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionCatalogue,
      'cannot handleEditButtonClick without questionCatalogue'
    );

    void EditQuestionCatalogueDialog.open({
      questionCatalogue: this.questionCatalogue
    });
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionCatalogue,
      'cannot handleDeleteButtonClick without questionCatalogue'
    );

    const questionCatalogue = this.questionCatalogue;
    void Dialogs.deleteEntityDialog(questionCatalogue).then(() => {
      this.entityManager.questionCatalogueRepository.delete(questionCatalogue);
    });
  }
}
