import { AppEntityManager } from '../AppEntityManager';
import { Entry } from './types';

export class EntryRecursiveFilter {
  constructor(private entityManager: AppEntityManager) {}

  public filter(
    entries: Array<Entry>,
    entryConditionCallback: (
      entry: Entry,
      remainingChildren: Array<Entry>,
      children: Array<Entry>
    ) => boolean
  ): Array<Entry> {
    const returnEntries = [];
    for (const entry of entries) {
      const children =
        this.entityManager.entryRepository.getSubEntriesOfEntry(entry);

      let remainingChildren: Array<Entry> = [];
      if (children.length) {
        remainingChildren = this.filter(children, entryConditionCallback);
      }

      if (entryConditionCallback(entry, remainingChildren, children)) {
        returnEntries.push(entry);
      }
    }
    return returnEntries;
  }
}
