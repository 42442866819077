import { autoinject } from 'aurelia-framework';
import { Observable } from 'rxjs';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { Picture, PictureAdditionalMarking } from './types';

@autoinject()
export class PictureMarkingService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService
  ) {}

  public createAdditionalMarkingsObservableForProjectId(
    projectId: string
  ): Observable<AdditionalMarkingsByPictureId> {
    return new Observable((subscriber) => {
      const subscriptionManager = this.subscriptionManagerService.create();

      const next = (): void => {
        subscriber.next(
          this.getAdditionalMarkingsByPictureIdForProjectId(projectId)
        );
      };

      subscriptionManager.subscribeToModelChanges(EntityName.Picture, next);
      next();

      return () => {
        subscriptionManager.disposeSubscriptions();
      };
    });
  }

  public getAdditionalMarkingsByPictureIdForProjectId(
    projectId: string
  ): AdditionalMarkingsByPictureId {
    const markingsByPictureId: AdditionalMarkingsByPictureId = new Map();

    const pictures =
      this.entityManager.pictureRepository.getAllPicturesByProjectId(projectId);

    for (const picture of pictures) {
      const entry = picture.entry
        ? this.entityManager.entryRepository.getById(picture.entry)
        : null;
      if (entry?.exclude_from_export) continue;

      for (const marking of picture.additional_markings) {
        this.addAdditionalMarkingToMarkingsByPictureId({
          picture,
          marking,
          markingsByPictureId
        });
      }
    }

    return markingsByPictureId;
  }

  private addAdditionalMarkingToMarkingsByPictureId({
    picture,
    marking,
    markingsByPictureId
  }: {
    picture: Picture;
    marking: PictureAdditionalMarking;
    markingsByPictureId: AdditionalMarkingsByPictureId;
  }): void {
    const existingArray = markingsByPictureId.get(marking.picture_id);

    const markingWithSourcePicture = {
      ...marking,
      sourcePicture: picture
    };

    if (existingArray) {
      existingArray.push(markingWithSourcePicture);
    } else {
      markingsByPictureId.set(marking.picture_id, [markingWithSourcePicture]);
    }
  }
}

export type AdditionalMarkingsByPictureId = Map<
  string,
  Array<AdditionalMarkingWithSourcePicture>
>;

export type AdditionalMarkingWithSourcePicture = PictureAdditionalMarking & {
  sourcePicture: Picture;
};
