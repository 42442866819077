import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import {
  BaseEntityDto,
  BaseEntityFieldInfos,
  BaseEntityUtils
} from './BaseEntityUtils';
import { EntityName } from './EntityName';

export class UserGroupSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): UserGroupSubEntityFieldInfos {
    return {
      ...BaseEntityUtils.createBaseFieldInfos(),
      ownerUserGroupId: {
        name: 'ownerUserGroupId',
        ref: EntityName.UserGroup,
        cascadeDeleteLocally: true,
        additionalData: {
          ownerId: true
        }
      }
    };
  }
}

export type UserGroupSubEntityFieldInfos = BaseEntityFieldInfos & {
  ownerUserGroupId: EntityFieldInfo<
    AppCommonSynchronizationEnvironmentTypes,
    UserGroupSubEntityDto,
    'ownerUserGroupId'
  >;
};

export type UserGroupSubEntityDto = BaseEntityDto & {
  ownerUserGroupId: string;
};
