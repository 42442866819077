import { RectangleToRectangleAligner } from 'common/Aligner/RectangleToRectangleAligner';
import { Vector } from 'common/Geometry/Vector';
import { TooltipContentHelper } from './TooltipContentHelper';

export class TooltipContentElementAligner {
  /** @type {HTMLElement} */
  _contentElement;

  /** @type {HTMLElement} */
  _slottedElement;

  /** @type {RectangleToRectangleAligner} */
  _rectangleToRectangleAligner;

  /**
   * @param {HTMLElement} contentElement
   * @param {HTMLElement} slottedElement
   * @param {Vector} targetAlignment
   * @param {Vector} tooltipAlignment
   * @param {Vector} margin
   */
  constructor(
    contentElement,
    slottedElement,
    targetAlignment,
    tooltipAlignment,
    margin
  ) {
    this._contentElement = contentElement;
    this._slottedElement = slottedElement;

    this._rectangleToRectangleAligner = new RectangleToRectangleAligner({
      boundingAreaSize: new Vector(window.innerWidth, window.innerHeight),
      margin: margin,
      referenceRectangleAlignment: targetAlignment,
      alignedRectangleAlignment: tooltipAlignment
    });
  }

  /**
   * @param {HTMLElement} referenceElement
   * @param {boolean} limitToAvailableSpace
   */
  align(referenceElement, limitToAvailableSpace) {
    const alignedRectangle = this._getContentElementRectangle(
      limitToAvailableSpace
    );
    const referenceRectangle =
      this._getRectangleForHtmlElement(referenceElement);

    this._rectangleToRectangleAligner.setBoundingAreaSize(
      new Vector(window.innerWidth, window.innerHeight)
    );
    const method = this._rectangleToRectangleAligner.alignRectangle(
      alignedRectangle,
      referenceRectangle
    );

    this._contentElement.style.left = alignedRectangle.position.getX() + 'px';
    this._contentElement.style.top =
      alignedRectangle.position.getY() +
      TooltipContentHelper.getScrollOffset() +
      'px';

    return method;
  }

  /**
   * @param {Vector} alignment
   */
  setReferenceRectangleAlignment(alignment) {
    this._rectangleToRectangleAligner.setReferenceRectangleAlignment(alignment);
  }

  /**
   * @param {Vector} alignment
   */
  setAlignedRectangleAlignment(alignment) {
    this._rectangleToRectangleAligner.setAlignedRectangleAlignment(alignment);
  }

  /**
   * @param {boolean} limitToAvailableSpace
   * @returns {import('../../../../common/src/Geometry/RectangleHelper').TGeometryRectangle}
   */
  _getContentElementRectangle(limitToAvailableSpace) {
    return TooltipContentHelper.getContentBoundingRectangle(
      this._contentElement,
      this._slottedElement,
      limitToAvailableSpace
    );
  }

  /**
   * @param {HTMLElement} element
   * @returns {import('../../../../common/src/Geometry/RectangleHelper').TGeometryRectangle}
   */
  _getRectangleForHtmlElement(element) {
    const boundingClientRect = element.getBoundingClientRect();

    return {
      position: new Vector(boundingClientRect.left, boundingClientRect.top),
      size: new Vector(boundingClientRect.width, boundingClientRect.height)
    };
  }
}
