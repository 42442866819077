import { TDefaultPropertyConfig } from '../Types/DefaultPropertyConfig';
import { UserDefinedEntityConfigPropertyConfigDto } from '../Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';

export class UserDefinedEntityConfigPropertyConfigHelper {
  public static reduceToPropertyConfig(
    config: UserDefinedEntityConfigPropertyConfigDto<any, any>
  ): TDefaultPropertyConfig {
    return {
      name: config.name,
      type: config.type,
      choices: config.choices
    };
  }
}
