import { assertNotNullOrUndefined } from 'common/Asserts';

import { DialogIconType } from 'common/Enums/DialogIconType';

import { GlobalCustomDialog } from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { Dialogs } from '../Dialogs';
import { EventDispatcher } from '../EventDispatcher/EventDispatcher';
import { Disposable } from '../Utils/DisposableContainer';
import {
  AbstractNfcHelperStrategy,
  UidScanCallback,
  UserAbortedScanError
} from './AbstractNfcHelperStrategy';

export class AndroidNfcHelperStrategy extends AbstractNfcHelperStrategy {
  private static TRANSLATION_KEY_PREFIX =
    'classes.Nfc.AndroidNfcHelperStrategy';

  private readonly eventDispatcher = new EventDispatcher<{
    nfcScanned: TagEvent;
  }>();

  constructor() {
    super();

    window.nfc?.addTagDiscoveredListener((tagEvent) => {
      this.eventDispatcher.dispatchEvent('nfcScanned', tagEvent);
    });
  }

  public scanSingleUID(callback: UidScanCallback): Disposable {
    void GlobalCustomDialog.open({
      titleTk: `${AndroidNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.scanDialogTitle`,
      textTk: `${AndroidNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.scanDialogText`,
      buttons: [
        {
          textTk: `${AndroidNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.abortScanButton`,
          value: 'abortScan'
        }
      ],
      icon: DialogIconType.WARNING
    }).then((value) => {
      if (value.value === 'abortScan') {
        callback(new UserAbortedScanError(), null);
        disposable.dispose();
      }
    });

    const disposable = this.eventDispatcher.addDisposableEventListener(
      'nfcScanned',
      (tagEvent) => {
        const tagId = window.nfc?.bytesToHexString(tagEvent.tag.id);
        assertNotNullOrUndefined(tagId, 'could not get a nfc tag id');

        if (callback(null, tagId)) {
          Dialogs.timedSuccessDialogTk(
            `${AndroidNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.successfullyScannedTitle`,
            `${AndroidNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.successfullyScannedText`
          );
        }

        disposable.dispose();
      }
    );

    return disposable;
  }
}
