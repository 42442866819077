import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../UrlManager';

export class PictureFilePathConverter {
  private constructor() {}

  public static getPathRelativeToLocalDirectory(
    relativeOrAbsolutePath: string
  ): string {
    if (this.pathIsRelative(relativeOrAbsolutePath)) {
      return relativeOrAbsolutePath;
    }

    const iosRelativePath = this.tryGettingRelativePathFromOldIosPath(
      relativeOrAbsolutePath
    );
    if (iosRelativePath) {
      return iosRelativePath;
    }

    return this.getPathRelativeTo({
      originPath: UrlManager.localFolder,
      path: relativeOrAbsolutePath
    });
  }

  /**
   * REC-3302, REC-3265
   * Necessary if the path changed because the UUID in the path changed.
   * This needs special handling, since the base path (UrlManager.localFolde) is different now and a relative path can't be calculated normally.
   *
   * example of old absolutePath: file:///var/mobile/Containers/Data/Application/C0A15A58-9A60-4716-8AA2-3D17BAD5DEC9/Library/NoCloud/captured_pictures/2023.02.14-09-58-40_63eb4d40f2dffe087253660c.jpg
   */
  private static tryGettingRelativePathFromOldIosPath(
    absolutePath: string
  ): string | null {
    const result =
      /^(?:file:\/\/)?\/var\/mobile\/Containers\/Data\/Application\/[^/]+\/Library\/NoCloud\/(.*)$/.exec(
        absolutePath
      );
    if (!result) {
      return null;
    }

    return result[1] ?? '';
  }

  // TODO: integrate this into PathUtils after REC-3235 https://bitbucket.corp.recordit.at/projects/REC/repos/record/pull-requests/1684/overview has been merged
  private static getPathRelativeTo({
    originPath,
    path
  }: {
    originPath: string;
    path: string;
  }): string {
    if (!this.pathIsAbsolute(originPath)) {
      throw new Error(`originPath is not absolute: "${originPath}"`);
    }

    if (!this.pathIsAbsolute(path)) {
      throw new Error(`path is not absolute: "${path}"`);
    }

    const { path1: originPathRelativeToCommon, path2: pathRelativeToCommon } =
      this.stripCommonRoot({
        path1: this.extractProtocol(originPath).pathWithoutProtocol,
        path2: this.extractProtocol(path).pathWithoutProtocol
      });

    const escapeLength = originPathRelativeToCommon
      ? originPathRelativeToCommon.split('/').length
      : 0;

    const escape: Array<string> = [];
    for (let i = 0; i < escapeLength; i++) {
      escape.push('..');
    }

    return PathUtils.joinPaths(escape.join('/'), pathRelativeToCommon);
  }

  private static stripCommonRoot({
    path1,
    path2
  }: {
    path1: string;
    path2: string;
  }): { path1: string; path2: string } {
    const elements1 = path1.split('/');
    const elements2 = path2.split('/');
    const maxLength = Math.max(elements1.length, elements2.length);

    /* index where the paths start to divert */
    let diversionIndex = 0;
    for (let index = 0; index <= maxLength; index++) {
      const pathElement1 = elements1[index];
      const pathElement2 = elements2[index];

      if (pathElement1 !== pathElement2) {
        diversionIndex = index;
        break;
      }
    }

    return {
      path1: elements1.slice(diversionIndex).join('/'),
      path2: elements2.slice(diversionIndex).join('/')
    };
  }

  // TODO: integrate this into PathUtils.getPathDetails after REC-3235 https://bitbucket.corp.recordit.at/projects/REC/repos/record/pull-requests/1684/overview has been merged
  private static pathIsRelative(path: string): boolean {
    return !this.pathIsAbsolute(path);
  }

  // TODO: integrate this into PathUtils.getPathDetails after REC-3235 https://bitbucket.corp.recordit.at/projects/REC/repos/record/pull-requests/1684/overview has been merged
  private static pathIsAbsolute(path: string): boolean {
    const { pathWithoutProtocol } = this.extractProtocol(path);
    return pathWithoutProtocol[0] === '/';
  }

  // TODO: use PathUtils.getPathDetails after REC-3235 https://bitbucket.corp.recordit.at/projects/REC/repos/record/pull-requests/1684/overview has been merged
  private static extractProtocol(path: string): {
    protocol: string | null;
    pathWithoutProtocol: string;
  } {
    const result = /([a-zA-Z:]+)\/{2}/.exec(path) as [string, string] | null;

    if (!result) {
      return {
        protocol: null,
        pathWithoutProtocol: path
      };
    }

    return {
      pathWithoutProtocol: path.slice(result[0].length),
      protocol: result[1]
    };
  }
}
