import { inject } from 'aurelia-framework';

import { GlobalSiteStateInfoService } from '../../services/GlobalSiteStateInfoService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * an element which provides a status bar for the GlobalSiteStateInfoService
 * it's also expandable to see further description of the site state/warnings
 */
@inject(GlobalSiteStateInfoService, SubscriptionManagerService)
export class GlobalSiteStateInfo {
  /** @type {boolean} */
  _expanded = false;
  /** @type {GlobalSiteStateInfoService} */
  _globalSiteStateInfoService;

  /** @type {boolean} */
  _anyWarning = false;
  /** @type {boolean} */
  _locationWarning = false;
  /** @type {Array<string>} */
  _warningTexts = [];

  /**
   * @param {GlobalSiteStateInfoService} globalSiteStateInfoService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(globalSiteStateInfoService, subscriptionManagerService) {
    this._globalSiteStateInfoService = globalSiteStateInfoService;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.addDisposable(
      this._globalSiteStateInfoService.registerBinding(
        'anyWarning',
        (anyWarning) => {
          this._anyWarning = anyWarning;
        }
      )
    );

    this._subscriptionManager.addDisposable(
      this._globalSiteStateInfoService.registerBinding(
        'locationWarning',
        (locationWarning) => {
          this._locationWarning = locationWarning;
        }
      )
    );

    this._subscriptionManager.addDisposable(
      this._globalSiteStateInfoService.registerBinding(
        'warningTexts',
        (warningTexts) => {
          this._warningTexts = warningTexts;
        }
      )
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _handleWarningIconClick() {
    this._expanded = !this._expanded;
  }

  _handleCollapseClick() {
    this._expanded = false;
  }
}
