import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ExportType,
  ExportDefectsResponse
} from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';
import { DefectManagementFilter as CommonDefectManagementFilter } from 'common/Types/DefectManagementFilter';

import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { SocketService } from '../../services/SocketService';
import { DefectManagementFilter } from '../gallery-thing-filter/gallery-thing-defect-filter/gallery-thing-defect-filter';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { ExportStartedEvent } from '../gallery-thing-filter/gallery-thing-picture-filter/gallery-thing-picture-filter';
import { GalleryThingFilterHelper } from '../../classes/GalleryThing/GalleryThingFilterHelper';
import { computed } from '../../hooks/computed';
import { expression, isConnected } from '../../hooks/dependencies';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event export-started {ExportStartedEvent} - triggered when the export is started.
 */
@autoinject()
export class GalleryThingDefectExportPanel {
  @bindable public isExpanded: boolean = false;

  @bindable public thing: Thing | null = null;

  @bindable public filter: DefectManagementFilter | null = null;

  @bindable public selectedDefects: Array<Defect> = [];

  protected parameterPanelViewModel: ParameterPanel | null = null;

  protected selectedReportType: ReportType | null = null;

  protected availableReportTypes: Array<ReportType> = [];

  protected exportDropdownModel: TooltipContent | null = null;

  protected ExportType = ExportType;

  private readonly element: HTMLElement;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        context: this,
        entityName: EntityName.Thing,
        expression: 'thing'
      });
  }

  // /////////// METHODS /////////////

  private getCommonFromClientFilter(): CommonDefectManagementFilter {
    assertNotNullOrUndefined(
      this.filter,
      'cannot getSerializedFilter without filter'
    );

    return {
      assigneeId: this.filter.assigneeId,
      dateFrom: this.filter.dateFrom?.getTime() ?? null,
      dateTo: this.filter.dateTo?.getTime() ?? null,
      latLongArea: this.filter.latLongArea,
      tagIds: this.filter.tags.map((t) => t.id),
      visibleStatuses: this.filter.visibleStatuses,
      properties: this.filter.properties
        .filter((p) => GalleryThingFilterHelper.isActiveFilter(p))
        .map((p) => GalleryThingFilterHelper.getFilterStringRepresentation(p))
    };
  }

  // /////////// UPDATERS /////////////

  private updateAvailableReportTypes(): void {
    this.availableReportTypes =
      this.entityManager.reportTypeRepository.getAll();
  }

  // /////////// OBSERVABLES /////////////

  protected isExpandedChanged(value: boolean): void {
    if (value) {
      this.parameterPanelViewModel?.expand();
    } else {
      void this.parameterPanelViewModel?.collapse();
    }
  }

  protected thingChanged(): void {
    this.updateAvailableReportTypes();
  }

  // /////////// EVENT HANDLERS /////////////

  protected handlePanelClosed(): void {
    this.isExpanded = false;
  }

  protected async handleExportClicked(exportType: ExportType): Promise<void> {
    assertNotNullOrUndefined(
      this.exportDropdownModel,
      'cannot handleExportClicked without exportDropdownModel'
    );

    this.exportDropdownModel.close();
    const response = await new Promise<ExportDefectsResponse>((res) => {
      assertNotNullOrUndefined(
        this.thing,
        'cannot handleExportClicked without thing'
      );

      assertNotNullOrUndefined(
        this.selectedReportType,
        'cannot handleExportClicked without selectedReportType'
      );

      this.socketService.exportDefects(
        {
          reportTypeId: this.selectedReportType.id,
          thingId: this.thing.id,
          defectIds: this.selectedDefects.map((d) => d.id),
          exportType: exportType,
          filter: this.getCommonFromClientFilter()
        },
        res
      );
    });

    if (response.success) {
      DomEventHelper.fireEvent<ExportStartedEvent>(this.element, {
        name: 'export-started',
        detail: { reportId: response.reportId }
      });
    }
  }

  @computed(
    expression('thingPermissionsHandle.canCreateReports'),
    isConnected(),
    expression('selectedReportType'),
    expression('selectedDefects.length')
  )
  protected get exportIsDisabled(): boolean {
    return (
      !this.thingPermissionsHandle.canCreateReports ||
      !this.socketService.isConnected() ||
      !this.selectedReportType ||
      !this.selectedDefects.length
    );
  }
}
