import { autoinject, bindable } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { currentUser, model } from '../../hooks/dependencies';

@autoinject()
export class ProjectByReportTypeFilter {
  @bindable public projects: Array<Project> = [];

  /**
   * readonly
   */
  @bindable protected selectedReportType: ReportType | null = null;

  @bindable public filteredProjects: Array<Project> = [];

  constructor(private readonly entityManager: AppEntityManager) {}

  protected projectsChanged(): void {
    this.updateFilteredProjects();
  }

  @computed(currentUser(), model(EntityName.ReportType))
  protected get reportTypeFilterOptions(): Array<FilterOption> {
    const reportTypesOfUserGroup =
      this.entityManager.reportTypeRepository.getAll();

    return reportTypesOfUserGroup.map((rt) => {
      return { label: rt.name ?? '', reportType: rt };
    });
  }

  protected updateFilteredProjects(): void {
    if (!this.selectedReportType) {
      this.filteredProjects = this.projects.slice();
      return;
    }
    this.filteredProjects = this.projects.filter(
      (p) => p.report_type === this.selectedReportType?.id
    );
  }
}

type FilterOption = {
  label: string;
  reportType: ReportType | null;
};
