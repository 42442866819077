import { BaseEntity } from '../../entities/BaseEntity';

export class OriginalIdUtils {
  private constructor() {}

  public static getOriginalIdsForEntity(entity: BaseEntity): Array<string> {
    return this.getOriginalIds(entity.originalIds, entity.originalId);
  }

  public static getOriginalIds(
    originalIds: Array<string> | null | undefined,
    originalId: string | null | undefined
  ): Array<string> {
    if (originalIds?.length) {
      return originalIds;
    } else if (originalId) {
      return [originalId];
    }

    return [];
  }
}
