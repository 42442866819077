import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class TagsByDefectIdValueComputer extends ValueComputer<
  ComputeData,
  TagsByDefectId
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): TagsByDefectId {
    const allPictures = this.entityManager.pictureRepository
      .getAll()
      .filter((p) => p.ownerDefectId != null);
    const picturesByDefectId = Utils.groupBy(
      allPictures,
      (p) => p.ownerDefectId
    );
    const defects = this.entityManager.defectRepository.getAll();
    const map: TagsByDefectId = new Map();

    for (const defect of defects) {
      const pictures = picturesByDefectId.get(defect.id) ?? [];
      map.set(defect.id, this.getTagsForPictures(pictures));
    }

    return map;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getTagsForPictures(pictures: Array<Picture>): Array<Tag> {
    const tagIds = pictures.reduce<Array<string>>((reduced, picture) => {
      return [...reduced, ...picture.tagIds];
    }, []);
    const uniqueTagIds = Array.from(new Set(tagIds));

    return this.entityManager.tagRepository.getByIds(uniqueTagIds);
  }
}

export type ComputeData = {};
export type TagsByDefectId = Map<string, Array<Tag>>;
