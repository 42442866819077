import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { IPictureCoords } from 'common/Types/Entities/Picture/PictureDto';
import { DefectStatus } from 'common/Enums/DefectStatus';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TagSortingMode } from 'common/Enums/TagSortingMode';

import { TooltipContent } from '../../../aureliaComponents/tooltip-content/tooltip-content';
import { TZoomBoxResizerHelperPicturePositionInfo } from '../../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { Defect } from '../../../classes/EntityManager/entities/Defect/types';
import { Tag } from '../../../classes/EntityManager/entities/Tag/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import {
  TagsByDefectId,
  TagsByDefectIdValueComputer
} from '../../../computedValues/computers/TagsByDefectIdValueComputer';
import { Picture } from '../../../picture/picture/picture';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { DateDisplayFormat } from '../../../valueConverters/DateFormatValueConverter';
import { GalleryThingPlanBasedEditDefectOverlay } from '../../gallery-thing-plan-based-edit-defect-overlay/gallery-thing-plan-based-edit-defect-overlay';
import { TagSorter } from '../../../classes/TagSorter';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class GalleryThingPlanBasedOverviewOverlayDefectMarker {
  @bindable()
  public defect: Defect | null = null;

  @bindable()
  public picture: Picture | null = null;

  @bindable()
  public picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  @bindable()
  public coordinates: IPictureCoords | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private tagsByDefectId: TagsByDefectId = new Map();
  protected tags: Array<Tag> = [];
  protected markerElement: HTMLElement | null = null;
  protected tooltipContent: TooltipContent | null = null;
  protected readonly DateDisplayFormat = DateDisplayFormat;
  private isAttached: boolean = false;

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  constructor(
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: TagsByDefectIdValueComputer,
        callback: (tagsByDefectId) => {
          this.tagsByDefectId = tagsByDefectId;
          this.updateTags();
        },
        computeData: {}
      }),
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateTags();
        }
      )
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected defectChanged(): void {
    if (this.isAttached) {
      this.updateTags();
    }
  }

  protected handleMarkerClick(): void {
    this.tooltipContent?.open();
  }

  protected handleCloseIconClick(): void {
    this.tooltipContent?.close();
  }

  protected handleEditDefectClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      "can't handleEditDefectClick without a defect"
    );
    void GalleryThingPlanBasedEditDefectOverlay.open({
      defect: this.defect
    });
  }

  private updateTags(): void {
    const tags = this.defect
      ? (this.tagsByDefectId.get(this.defect.id) ?? [])
      : [];
    this.tags = TagSorter.sortTags(tags, this.tagSortingMode);
  }

  protected get markerColor(): string {
    const status = this.defect?.status;
    switch (status) {
      case DefectStatus.PROCESSED:
        return 'var(--record-it-color-defect-state-processed)';

      case DefectStatus.DONE:
        return 'var(--record-it-color-defect-state-done)';

      case DefectStatus.OPEN:
      default:
        return 'var(--record-it-color-defect-state-open)';
    }
  }
}
