import { bindable, autoinject } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  ProcessTaskDefaultPropertyConfig,
  ProcessConfigurationProcessTaskPropertiesConfiguration
} from 'common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskProperty } from '../../classes/EntityManager/entities/Property/types';

/**
 * @event {TEditPropertyClickedEvent} edit-property-clicked
 */
@autoinject()
export class ProcessTaskInfoOverviewProperties {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  private domElement: HTMLElement;
  private subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  private processTaskDefaultProperties: Array<ProcessTaskDefaultPropertyConfig> =
    [];
  private properties: Array<ProcessTaskProperty> = [];

  private PropertyHelper = PropertyHelper;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateConfiguredProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );

    this.updateConfiguredProperties();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateConfiguredProperties();
    }
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private updateConfiguredProperties(): void {
    if (this.processTaskGroup) {
      const config = this.entityManager.processConfigurationRepository.getById(
        this.processTaskGroup.processConfigurationId
      );
      let configProperties: ProcessConfigurationProcessTaskPropertiesConfiguration | null =
        null;
      if (config && config.processTaskPropertiesConfigurationJson) {
        configProperties = JSON.parse(
          config.processTaskPropertiesConfigurationJson
        );
      }
      this.processTaskDefaultProperties =
        configProperties && configProperties.defaultProperties
          ? configProperties.defaultProperties
          : [];
    } else {
      this.processTaskDefaultProperties = [];
    }

    this.updateProperties();
  }

  private updateProperties(): void {
    const properties = this.processTask
      ? this.entityManager.propertyRepository.getByProcessTaskId(
          this.processTask.id
        )
      : [];
    const defaultPropertyNames = this.processTaskDefaultProperties
      .filter((p) => p.showInProcessTaskInfoOverview)
      .map((p) => p.name);
    const defaultPropertyNamesSet = new Set(defaultPropertyNames);

    this.properties = properties.filter(
      (p) => p.name && defaultPropertyNamesSet.has(p.name)
    );
  }

  private handleEditPropertyClick(property: ProcessTaskProperty): void {
    DomEventHelper.fireEvent<TEditPropertyClickedEvent>(this.domElement, {
      name: 'edit-property-clicked',
      detail: {
        property
      }
    });
  }
}

export type TEditPropertyClickedEvent = NamedCustomEvent<
  'edit-property-clicked',
  { property: ProcessTaskProperty }
>;
