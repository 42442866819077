import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { DrawingArea } from '../../drawing-area/tools/Tool';
import { SketcherOverlayToolBar } from '../../sketcher-overlay-tool-bar/sketcher-overlay-tool-bar';
import { DrawingHistoryManager } from '../DrawingHistoryManager';

/**
 * @event {SizeChangedEvent} size-changed
 */
@autoinject()
export class SketcherOverlayOldSketchWarning {
  @bindable()
  public drawingArea: DrawingArea | null = null;

  @bindable()
  public historyManager: DrawingHistoryManager | null = null;

  @bindable()
  public toolBar: SketcherOverlayToolBar | null = null;

  private domElement: HTMLElement;
  private hasOldSketch = false;
  private isAttached = false;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateHistoryManagerSubscriptions();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private historyManagerChanged(
    _newValue: DrawingHistoryManager | null,
    oldValue: DrawingHistoryManager | null
  ): void {
    oldValue?.removeEventListeners(this);

    if (this.isAttached) {
      this.updateHistoryManagerSubscriptions();
    }
  }

  private drawingAreaChanged(): void {
    if (this.isAttached) {
      this.updateHasOldSketch();
    }
  }

  private updateHistoryManagerSubscriptions(): void {
    if (this.historyManager) {
      this.historyManager.removeEventListeners(this);
      this.historyManager.addChangedListener(this, () => {
        this.updateHasOldSketch();
      });
    }

    this.updateHasOldSketch();
  }

  private updateHasOldSketch(): void {
    if (this.drawingArea) {
      this.setHasOldSketch(
        !!this.drawingArea
          .getMsvg()
          .getElement()
          .querySelector('[data-old-sketch]')
      );
    } else {
      this.setHasOldSketch(false);
    }
  }

  private setHasOldSketch(newValue: boolean): void {
    if (this.hasOldSketch === newValue) {
      return;
    }

    this.hasOldSketch = newValue;

    setTimeout(() => {
      DomEventHelper.fireEvent<SizeChangedEvent>(this.domElement, {
        name: 'size-changed',
        detail: null
      });
    }, 0);
  }

  private handleRemoveOldSketchClick(): void {
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlayOldSketchWarning.handleRemoveOldSketchClick without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlayOldSketchWarning.handleRemoveOldSketchClick without a historyManager"
    );

    const svgElement = this.drawingArea.getMsvg().getElement();
    const oldSketchElement = svgElement.querySelector('[data-old-sketch]');
    assertNotNullOrUndefined(oldSketchElement, 'no old sketch found to remove');

    this.toolBar?.cancelTool();
    oldSketchElement.parentNode?.removeChild(oldSketchElement);
    this.historyManager.push();
  }
}

export type SizeChangedEvent = NamedCustomEvent<'size-changed', null>;
