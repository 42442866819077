import { autoinject } from 'aurelia-framework';
import { KeyValueCache } from 'common/Cache/KeyValueCache/KeyValueCache';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class ProcessTaskNamesByProcessTaskIdComputer extends ValueComputer<
  ComputeData,
  ProcessTaskNamesByProcessTaskId
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskNamesByProcessTaskId {
    const processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
      new Map();
    const generator = new ProcessTaskNamesGenerator(this.entityManager);

    const processTasks = this.entityManager.processTaskRepository.getAll();
    for (const processTask of processTasks) {
      processTaskNamesByProcessTaskId.set(
        processTask.id,
        generator.generate(processTask)
      );
    }

    return processTaskNamesByProcessTaskId;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export class ProcessTaskNamesGenerator {
  private readonly thingWithPersonNameCache: KeyValueCache<string, string>;
  private readonly personNameCache: KeyValueCache<string, string | null>;

  constructor(private readonly entityManager: AppEntityManager) {
    this.thingWithPersonNameCache = this.createThingWithPersonNameCache();
    this.personNameCache = this.createPersonNameCache();
  }

  public generate(processTask: ProcessTask): ProcessTaskNames {
    return {
      nameWithThingAndPerson: this.getNameWithThingAndPerson(processTask)
    };
  }

  private getNameWithThingAndPerson(processTask: ProcessTask): string {
    const suffix = processTask.name ? ` - ${processTask.name}` : '';

    return this.thingWithPersonNameCache.get(processTask.thingId) + suffix;
  }

  private createThingWithPersonNameCache(): KeyValueCache<string, string> {
    return new KeyValueCache({
      getValue: (thingId) => {
        const parts = [];

        const thing = this.entityManager.thingRepository.getById(thingId);

        if (thing) {
          parts.push(thing.name);

          const thingToPerson =
            this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
              thing.id
            );
          const personName = thingToPerson
            ? this.personNameCache.get(thingToPerson.personId)
            : null;
          if (personName) {
            parts.push(personName);
          }
        }

        return parts.join(' - ');
      }
    });
  }

  private createPersonNameCache(): KeyValueCache<string, string | null> {
    return new KeyValueCache({
      getValue: (personId) => {
        const person = this.entityManager.personRepository.getById(personId);
        return person
          ? PersonUtils.getPersonDisplayNameForPerson(person)
          : null;
      }
    });
  }
}

export type ComputeData = {};

export type ProcessTaskNamesByProcessTaskId = Map<string, ProcessTaskNames>;

export type ProcessTaskNames = {
  nameWithThingAndPerson: string;
};
