import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskOffer } from './types';

export class ProcessTaskOfferRepository extends AppEntityRepository<EntityName.ProcessTaskOffer> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskOffer> {
    return this.getAll().filter(
      (o) => o.ownerProcessTaskGroupId === processTaskGroupId
    );
  }
}
