import { computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { DisplayedEntrySelect, OnEntrySelected } from './DisplayedEntrySelect';

export class EntryPathHandle implements Disposable {
  private readonly project: Project;
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly onNewEntryPath: OnNewEntryPath;

  private internalDisplayedEntrySelects: Array<DisplayedEntrySelect>;
  private internalPreserveEntryPath: boolean = false;
  private internalEntryPath: Array<Entry> = [];
  private internalEntryNamePath: Array<string> = [];

  constructor(options: EntryPathHandleOptions) {
    this.project = options.project;
    this.entityManager = options.entityManager;
    this.onNewEntryPath = options.onNewEntryPath;

    this.setInternalEntryPath({
      entryPath: options.preservedEntryPath,
      callEventListener: false
    });
    this.internalPreserveEntryPath = !!options.preservedEntryPath.length;

    this.internalDisplayedEntrySelects =
      this.createDisplayedEntrySelectsFromPath({
        entryPath: options.preservedEntryPath
      });

    this.subscriptionManager = options.subscriptionManagerService.create();
    this.subscriptionManager.subscribeToModelChanges(EntityName.Entry, () => {
      this.updateDisplayedEntrySelects();
    });
  }

  @computedFrom('internalDisplayedEntrySelects')
  public get displayedEntrySelects(): Array<DisplayedEntrySelect> {
    return this.internalDisplayedEntrySelects;
  }

  @computedFrom('internalPreserveEntryPath')
  public get preserveEntryPath(): boolean {
    return this.internalPreserveEntryPath;
  }

  public set preserveEntryPath(preserveEntryPath: boolean) {
    this.internalPreserveEntryPath = preserveEntryPath;
  }

  @computedFrom('internalEntryPath')
  public get entryPath(): Array<Entry> {
    return this.internalEntryPath;
  }

  public setEntryPath(entryPath: Array<Entry>): void {
    this.setInternalEntryPath({ entryPath });

    this.internalDisplayedEntrySelects =
      this.createDisplayedEntrySelectsFromPath({
        entryPath
      });
  }

  @computedFrom('internalEntryNamePath')
  public get entryNamePath(): Array<string> {
    return this.internalEntryNamePath;
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private createDisplayedEntrySelectsFromPath({
    entryPath
  }: {
    entryPath: Array<Entry>;
  }): Array<DisplayedEntrySelect> {
    const displayedEntrySelects: Array<DisplayedEntrySelect> = [];

    let previousEntry: Entry | null = null;
    for (const entry of entryPath) {
      displayedEntrySelects.push(
        this.createDisplayedEntrySelect({
          selectedEntry: entry,
          parentEntry: previousEntry
        })
      );

      previousEntry = entry;
    }

    this.addDisplayedEntrySelectIfNecessary({
      parentEntry: previousEntry,
      selectedEntry: null,
      displayedEntrySelects
    });

    return displayedEntrySelects;
  }

  private handleEntrySelected({
    displayedEntrySelect
  }: Parameters<OnEntrySelected>[0]): void {
    const newDisplayedEntrySelects = [...this.internalDisplayedEntrySelects];
    const index = newDisplayedEntrySelects.indexOf(displayedEntrySelect);

    if (index === -1) {
      // this should never happen, but if it does, reset the entry selects
      newDisplayedEntrySelects.length = 0;
      this.addDisplayedEntrySelectIfNecessary({
        parentEntry: null,
        selectedEntry: null,
        displayedEntrySelects: newDisplayedEntrySelects
      });
    } else {
      // remove all following elements, because they don't make any sense when the user selected a different entry
      newDisplayedEntrySelects.splice(
        index + 1,
        newDisplayedEntrySelects.length
      );

      if (displayedEntrySelect.selectedEntry) {
        this.addDisplayedEntrySelectIfNecessary({
          parentEntry: displayedEntrySelect.selectedEntry,
          selectedEntry: null,
          displayedEntrySelects: newDisplayedEntrySelects
        });
      }
    }

    this.internalDisplayedEntrySelects = newDisplayedEntrySelects;
    const entryPath = newDisplayedEntrySelects
      .map((select) => select.selectedEntry)
      .filter((entry): entry is Entry => !!entry);
    this.setInternalEntryPath({ entryPath });
  }

  private addDisplayedEntrySelectIfNecessary({
    parentEntry,
    selectedEntry,
    displayedEntrySelects
  }: {
    parentEntry: Entry | null;
    selectedEntry: Entry | null;
    displayedEntrySelects: Array<DisplayedEntrySelect>;
  }): void {
    if (this.hasSubEntries({ parentEntry })) {
      displayedEntrySelects.push(
        this.createDisplayedEntrySelect({
          selectedEntry,
          parentEntry
        })
      );
    }
  }

  private hasSubEntries({
    parentEntry
  }: {
    parentEntry: Entry | null;
  }): boolean {
    return this.getEntriesToSelect({ parentEntry }).length > 0;
  }

  private createDisplayedEntrySelect({
    selectedEntry,
    parentEntry
  }: {
    selectedEntry: Entry | null;
    parentEntry: Entry | null;
  }): DisplayedEntrySelect {
    return new DisplayedEntrySelect({
      selectedEntry,
      parentEntry,
      entriesToSelect: this.getEntriesToSelect({ parentEntry }),
      onEntrySelected: this.handleEntrySelected.bind(this)
    });
  }

  private updateDisplayedEntrySelects(): void {
    for (const displayedEntrySelect of this.displayedEntrySelects) {
      displayedEntrySelect.setEntriesToSelect(
        this.getEntriesToSelect({
          parentEntry: displayedEntrySelect.parentEntry
        })
      );
    }
  }

  private getEntriesToSelect({
    parentEntry
  }: {
    parentEntry: Entry | null;
  }): Array<Entry> {
    const entries = this.entityManager.entryRepository.getByParentId(
      this.project.id,
      parentEntry?.id ?? null,
      null
    );

    return entries.filter((e) => {
      return e.structureTemplateEntryId || e.originId;
    });
  }

  private setInternalEntryPath({
    entryPath,
    callEventListener = true
  }: {
    entryPath: Array<Entry>;
    callEventListener?: boolean;
  }): void {
    this.internalEntryPath = entryPath;
    this.internalEntryNamePath = entryPath.map((entry) => entry.name ?? '');

    if (callEventListener) {
      this.onNewEntryPath({ entryPath: this.internalEntryPath });
    }
  }
}

export type EntryPathHandleOptions = {
  project: Project;
  preservedEntryPath: Array<Entry>;
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  onNewEntryPath: OnNewEntryPath;
};

export type OnNewEntryPath = (options: { entryPath: Array<Entry> }) => void;
