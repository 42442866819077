import { autoinject } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskLoggingService } from '../ProcessTaskLoggingService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { NFCHelper } from '../../classes/Nfc/NFCHelper';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { FinishProcessTaskAppointmentConfirmDialog } from '../../operationsComponents/finish-process-task-appointment-confirm-dialog/finish-process-task-appointment-confirm-dialog';
import { ProcessTaskChecklistService } from '../ProcessTaskChecklistService/ProcessTaskChecklistService';
import { PictureValidator } from './validators/PictureValidator';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTaskMeasurePointReadingValidator } from './validators/ProcessTaskMeasurePointReadingValidator';
import { ProcessTaskPropertiesValidator } from './validators/ProcessTaskPropertiesValidator';
import { SignatureValidator } from './validators/SignatureValidator';
import {
  ProcessTaskFormValidator,
  ProcessTaskFormValidatorResultType
} from './validators/ProcessTaskFormValidator';
import { ProcessTaskDeviceValidator } from './validators/ProcessTaskDeviceValidator';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { ProcessTaskPositionValidator } from './validators/ProcessTaskPositionValidator';

@autoinject()
export class ProcessTaskAppointmentFinishService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly processTaskChecklistService: ProcessTaskChecklistService,
    private readonly pictureValidator: PictureValidator,
    private readonly processTaskMeasurePointReadingValidator: ProcessTaskMeasurePointReadingValidator,
    private readonly processTaskPropertiesValidator: ProcessTaskPropertiesValidator,
    private readonly signatureValidator: SignatureValidator,
    private readonly processTaskFormValidator: ProcessTaskFormValidator,
    private readonly processTaskDeviceValidator: ProcessTaskDeviceValidator,
    private readonly processTaskPositionValidator: ProcessTaskPositionValidator
  ) {}

  public async finishAppointmentWithNfcTag(
    options: FinishAppointmentWithNfcTagOptions
  ): Promise<void> {
    const personId = await this.getPersonIdFromNfcTagIfPossible();
    if (!personId) return;

    await this.finishAppointment({
      ...options,
      personId
    });
  }

  public async finishAppointment(
    options: FinishAppointmentOptions
  ): Promise<void> {
    const canFinishAppointmentResult = await this.canFinishAppointment(options);
    if (!canFinishAppointmentResult.success) {
      return;
    }

    const confirmFinishAppointmentResult = await this.confirmFinishAppointment({
      ...options,
      additionalConfirmationResult:
        canFinishAppointmentResult.additionalConfirmationResult
    });

    if (!confirmFinishAppointmentResult) {
      return;
    }

    options.appointment.finishedAt = new Date().toISOString();
    this.entityManager.processTaskAppointmentRepository.update(
      options.appointment
    );

    if (options.personId) {
      void this.processTaskLoggingService.logAppointmentFinishedByPerson(
        options.processTask,
        options.appointment.id,
        options.personId,
        options.appointment.openedAt ?? null
      );
    } else {
      void this.processTaskLoggingService.logAppointmentFinished(
        options.processTask,
        options.appointment.id,
        options.appointment.openedAt ?? null
      );
    }

    if (options.appointment.workNote) {
      void this.processTaskChecklistService.createChecklistEntryForProcessTask({
        processTask: options.processTask,
        text: `${options.appointment.workNote} (${DateUtils.formatToDateString(
          Date.now()
        )})`
      });
    }
  }

  public async undoFinishingAppointmentWithNfcTag(
    appointment: ProcessTaskAppointment,
    processTask: ProcessTask
  ): Promise<void> {
    const personId = await this.getPersonIdFromNfcTagIfPossible();
    if (!personId) return;

    this.undoFinishingAppointment(appointment, processTask, personId);
  }

  public undoFinishingAppointment(
    appointment: ProcessTaskAppointment,
    processTask: ProcessTask,
    personId?: string
  ): void {
    appointment.finishedAt = null;
    this.entityManager.processTaskAppointmentRepository.update(appointment);

    if (personId) {
      void this.processTaskLoggingService.logAppointmentUndoFinishedByPerson(
        processTask,
        appointment.id,
        personId
      );
    } else {
      void this.processTaskLoggingService.logAppointmentUndoFinished(
        processTask,
        appointment.id
      );
    }
  }

  private async getPersonIdFromNfcTagIfPossible(): Promise<string | null> {
    if (!(await NFCHelper.isNfcEnabled())) {
      this.showErrorDialog({
        type: ValidateAppointmentResultType.NFC_NOT_ENABLED
      });
      return null;
    }

    return new Promise((res) => {
      NFCHelper.scanSingleUID((error, tagId) => {
        if (error) {
          res(null);
          return false;
        } else if (tagId) {
          const nfc = this.entityManager.nfcTokenRepository.getByTokenId(tagId);
          const firstRelation = nfc
            ? this.entityManager.nfcTokenToPersonRepository.getByNfcTokenId(
                nfc.id
              )[0]
            : null;
          const person = firstRelation
            ? this.entityManager.personRepository.getById(
                firstRelation.personId
              )
            : null;
          if (!person) {
            this.showErrorDialog({
              type: ValidateAppointmentResultType.PERSON_NOT_FOUND
            });
            res(null);
            return false;
          }

          res(person.id);
          return true;
        }
        return false;
      });
    });
  }

  private async canFinishAppointment(
    options: FinishAppointmentOptions
  ): Promise<CanFinishAppointmentResult> {
    const canFinishAppointmentResult = this.validateAppointment(options);
    if (
      canFinishAppointmentResult.type !== ValidateAppointmentResultType.OK &&
      canFinishAppointmentResult.type !==
        ValidateAppointmentResultType.NEEDS_ADDITIONAL_CONFIRMATION
    ) {
      this.showErrorDialog(canFinishAppointmentResult);
      return { success: false };
    }

    let additionalConfirmationResult: AdditionalConfirmationResult | null =
      null;
    if (
      canFinishAppointmentResult.type ===
      ValidateAppointmentResultType.NEEDS_ADDITIONAL_CONFIRMATION
    ) {
      additionalConfirmationResult = await this.handleAdditionalConfirmations(
        canFinishAppointmentResult.confirmations
      );
      if (additionalConfirmationResult.success === false) {
        return { success: false };
      }
    }

    return {
      success: true,
      additionalConfirmationResult
    };
  }

  private validateAppointment(
    options: FinishAppointmentOptions
  ): ValidateAppointmentResult {
    const configurationStepId = options.appointment.processConfigurationStepId;
    const configurationStep = configurationStepId
      ? this.entityManager.processConfigurationStepRepository.getById(
          configurationStepId
        )
      : null;

    const optionsWithConfigurationStep: FinishAppointmentOptionsWithConfigurationStep =
      {
        ...options,
        configurationStep
      };

    const additionalConfirmations: Array<AdditionalConfirmationType> = [];

    if (!this.pictureValidator.validate(optionsWithConfigurationStep)) {
      return { type: ValidateAppointmentResultType.NO_PICTURES };
    }

    if (
      !this.processTaskMeasurePointReadingValidator.validate(
        optionsWithConfigurationStep
      )
    ) {
      return { type: ValidateAppointmentResultType.NO_MEASURE_POINT_READINGS };
    }

    const processTaskPropertiesValidatorResult =
      this.processTaskPropertiesValidator.validate(
        optionsWithConfigurationStep
      );
    if (!processTaskPropertiesValidatorResult.valid) {
      return {
        type: ValidateAppointmentResultType.MISSING_REQUIRED_PROPERTIES,
        propertyNames: processTaskPropertiesValidatorResult.missingProperties
          .map((p) => p.name)
          .join('; ')
      };
    }

    if (
      !this.processTaskDeviceValidator.validate(optionsWithConfigurationStep)
    ) {
      additionalConfirmations.push(
        AdditionalConfirmationType.NOT_ALL_DEVICES_UNDEPLOYED
      );
    }

    if (
      !this.processTaskPositionValidator.validate(optionsWithConfigurationStep)
    ) {
      additionalConfirmations.push(
        AdditionalConfirmationType.NOT_ALL_POSITIONS_DONE
      );
    }

    const formValidatorResult = this.processTaskFormValidator.validate(
      optionsWithConfigurationStep
    );
    switch (formValidatorResult.type) {
      case ProcessTaskFormValidatorResultType.VALID:
        break;

      case ProcessTaskFormValidatorResultType.FORM_NOT_AVAILABLE:
        return {
          type: ValidateAppointmentResultType.FORM_NOT_AVAILABLE,
          formName: formValidatorResult.formName
        };

      case ProcessTaskFormValidatorResultType.REQUIRED_PROPERTY_ERROR:
        return {
          type: ValidateAppointmentResultType.FORM_REQUIRED_PROPERTY_ERROR,
          formName: formValidatorResult.formName,
          propertyName: formValidatorResult.propertyName
        };

      default:
        throw new Error(
          `unhandled formValidatorResult "${JSON.stringify(
            formValidatorResult
          )}"`
        );
    }

    if (!this.signatureValidator.validate(optionsWithConfigurationStep)) {
      return {
        type: ValidateAppointmentResultType.MISSING_SIGNATURE
      };
    }

    if (additionalConfirmations.length) {
      return {
        type: ValidateAppointmentResultType.NEEDS_ADDITIONAL_CONFIRMATION,
        confirmations: additionalConfirmations
      };
    }

    return { type: ValidateAppointmentResultType.OK };
  }

  private async handleAdditionalConfirmations(
    confirmations: Array<AdditionalConfirmationType>
  ): Promise<AdditionalConfirmationResult> {
    for (const confirmation of confirmations) {
      try {
        await GlobalCustomDialog.open({
          titleTk:
            'services.processTaskAppointmentFinishService.additionalConfirmationDialogTitle',
          textTk:
            'services.processTaskAppointmentFinishService.additionalConfirmationDialogText_' +
            confirmation,
          buttons: [
            {
              textTk:
                'services.processTaskAppointmentFinishService.additionalConfirmationDialogAccept'
            },
            {
              textTk: 'general.cancel',
              type: ButtonType.CANCEL
            }
          ]
        });
      } catch (e) {
        if (e instanceof Error && e.message === 'DialogClosed') {
          return { success: false };
        }

        throw e;
      }
    }

    return { success: true, finishedAsPlanned: false };
  }

  private confirmFinishAppointment(
    options: FinishAppointmentOptions & {
      additionalConfirmationResult: AdditionalConfirmationSuccessResult | null;
    }
  ): Promise<boolean> {
    const configuration =
      this.entityManager.processConfigurationRepository.getById(
        options.processTaskGroup.processConfigurationId
      );
    assertNotNullOrUndefined(
      configuration,
      `no processConfiguration found for ${options.processTaskGroup.processConfigurationId}`
    );

    if (!configuration.useAppointmentConfirmDialog) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      void FinishProcessTaskAppointmentConfirmDialog.open({
        appointment: options.appointment,
        processTask: options.processTask,
        processTaskGroup: options.processTaskGroup,
        finishedAsPlanned:
          options.additionalConfirmationResult?.finishedAsPlanned ?? null,
        onConfirm: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        }
      }).catch(reject);
    });
  }

  private showErrorDialog(
    canFinishAppointmentResult: ValidateAppointmentResult
  ): void {
    const titleTk =
      'services.processTaskAppointmentFinishService.errorDialogTitle_' +
      canFinishAppointmentResult.type;
    const textTk =
      'services.processTaskAppointmentFinishService.errorDialogText_' +
      canFinishAppointmentResult.type;

    void Dialogs.errorDialogTk(titleTk, textTk, canFinishAppointmentResult);
  }
}

enum ValidateAppointmentResultType {
  OK = 'ok',
  NO_PICTURES = 'noPictures',
  NO_MEASURE_POINT_READINGS = 'noMeasurePointReadings',
  MISSING_REQUIRED_PROPERTIES = 'missingRequiredProperties',
  MISSING_SIGNATURE = 'missingSignature',
  NFC_NOT_ENABLED = 'nfcNotEnabled',
  PERSON_NOT_FOUND = 'personNotFound',
  FORM_NOT_AVAILABLE = 'formNotAvailable',
  FORM_REQUIRED_PROPERTY_ERROR = 'formRequiredPropertyError',
  NEEDS_ADDITIONAL_CONFIRMATION = 'needsAdditionalConfirmation'
}

type SpecificResult<T extends ValidateAppointmentResultType> = {
  type: T;
};

export type ValidateAppointmentResult =
  | SpecificResult<ValidateAppointmentResultType.OK>
  | SpecificResult<ValidateAppointmentResultType.NO_PICTURES>
  | SpecificResult<ValidateAppointmentResultType.NO_MEASURE_POINT_READINGS>
  | SpecificResult<ValidateAppointmentResultType.MISSING_SIGNATURE>
  | (SpecificResult<ValidateAppointmentResultType.MISSING_REQUIRED_PROPERTIES> & {
      propertyNames: string;
    })
  | SpecificResult<ValidateAppointmentResultType.NFC_NOT_ENABLED>
  | SpecificResult<ValidateAppointmentResultType.PERSON_NOT_FOUND>
  | (SpecificResult<ValidateAppointmentResultType.FORM_NOT_AVAILABLE> & {
      formName: string | null;
    })
  | (SpecificResult<ValidateAppointmentResultType.FORM_REQUIRED_PROPERTY_ERROR> & {
      formName: string | null;
      propertyName: string | null;
    })
  | (SpecificResult<ValidateAppointmentResultType.NEEDS_ADDITIONAL_CONFIRMATION> & {
      confirmations: Array<AdditionalConfirmationType>;
    });

export type FinishAppointmentOptions = {
  appointment: ProcessTaskAppointment;
  processTask: ProcessTask;
  processTaskGroup: ProcessTaskGroup;
  personId?: string | null;
  canEditGeneralProcessTaskInfo: boolean;
  canSignTheAppointment: boolean;
  canTakePictures: boolean;
  canDeployDevices: boolean;
};

export type FinishAppointmentOptionsWithConfigurationStep =
  FinishAppointmentOptions & {
    configurationStep: ProcessConfigurationStep | null;
  };

export type FinishAppointmentWithNfcTagOptions = Omit<
  FinishAppointmentOptions,
  'personId'
>;

export enum AdditionalConfirmationType {
  NOT_ALL_DEVICES_UNDEPLOYED = 'notAllDevicesUndeployed',
  NOT_ALL_POSITIONS_DONE = 'notAllPositionsDone'
}

type AdditionalConfirmationResult =
  | AdditionalConfirmationSuccessResult
  | AdditionalConfirmationUnsuccessfulResult;
type AdditionalConfirmationSuccessResult = {
  success: true;
  finishedAsPlanned: boolean;
};
type AdditionalConfirmationUnsuccessfulResult = { success: false };

type CanFinishAppointmentResult =
  | {
      success: true;
      additionalConfirmationResult: AdditionalConfirmationSuccessResult | null;
    }
  | { success: false };
