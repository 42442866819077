/**
 * inverts a boolean value in both direction (fromView/toView)
 */
export class InvertValueConverter {
  public toView(value: boolean): boolean {
    return !value;
  }

  public fromView(value: boolean): boolean {
    return !value;
  }
}
