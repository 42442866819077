import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FileUploadService } from '../../../../services/FileUploadService';
import { SocketService } from '../../../../services/SocketService';
import { PictureFilePathConverter } from '../../../UploadStrategies/PictureFileUploadStrategy/PictureFilePathConverter';
import {
  PictureFileUploadStrategy,
  PictureUploadHooks
} from '../../../UploadStrategies/PictureFileUploadStrategy/PictureFileUploadStrategy';
import { Disposable } from '../../../Utils/DisposableContainer';
import { AppEntityManager } from '../AppEntityManager';
import { PictureFilePathService } from './PictureFilePathService';
import { PictureFile } from './types';

@autoinject()
export class PictureFileUploadService {
  private pictureFileUploadStrategy: PictureFileUploadStrategy;

  constructor(
    private readonly fileUploadService: FileUploadService,
    entityManager: AppEntityManager,
    pictureFilePathService: PictureFilePathService,
    socketService: SocketService,
    eventAggregator: EventAggregator
  ) {
    this.pictureFileUploadStrategy = new PictureFileUploadStrategy({
      entityManager,
      pictureFilePathService,
      socketService,
      eventAggregator
    });
  }

  public async init(): Promise<void> {
    this.fileUploadService.registerStrategy(this.pictureFileUploadStrategy);
  }

  public destroy(): void {}

  public submitToUpload(
    pictureFile: PictureFile,
    dataUrl: string | null,
    filePath: string | null,
    showDialogs: boolean = true
  ): void {
    this.fileUploadService.submitUploadItem(
      this.pictureFileUploadStrategy,
      {
        dataUrl: dataUrl,
        pictureFileId: pictureFile.id,
        filePath:
          filePath != null
            ? PictureFilePathConverter.getPathRelativeToLocalDirectory(filePath)
            : null
      },
      {
        showDialogs
      }
    );
  }

  public registerHooks(hooks: PictureUploadHooks): Disposable {
    return this.pictureFileUploadStrategy.registerHooks(hooks);
  }
}
