import { ProjectType } from '../Types/Entities/Project/ProjectDto';

export class ProjectHelper {
  public static getColorOfProject(
    projectType: ProjectType | null
  ): string | null {
    switch (projectType) {
      case ProjectType.B1300:
        return 'var(--record-it-color-module-b1300)';
      case ProjectType.INSPECT:
        return 'var(--record-it-color-module-inspect)';
      case ProjectType.GALLERY:
        return 'var(--record-it-color-module-via)';
      case ProjectType.BASIC:
        return 'var(--record-it-color-module-basic)';
      default:
        return null;
    }
  }
}
