import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { computed } from '../../hooks/computed';
import { activeUserCompanySetting, expression } from '../../hooks/dependencies';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGroupRelationInfo } from 'common/EndpointTypes/ProcessTaskGroupRelationInfoEndpointsHandler';
import { SocketService } from '../../services/SocketService';
import { RequestWithStatus, Status } from '../../classes/RequestWithStatus';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class ProcessTaskGroupRelationInfoListItem {
  @bindable
  public processTaskGroupRelationInfo: ProcessTaskGroupRelationInfo | null =
    null;

  @bindable public currentProcessTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public updateProcessTaskGroupRelationInfos: (() => Promise<void>) | null =
    null;

  private toggleRelationRequestWithStatus: RequestWithStatus;

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly socketService: SocketService,
    private readonly requestWithStatusService: RequestWithStatusService
  ) {
    this.toggleRelationRequestWithStatus =
      this.requestWithStatusService.createRequestWithStatus(async () => {
        assertNotNullOrUndefined(
          this.processTaskGroupRelationInfo,
          'cannot send request to toggle processTaskGroupRelation without processTaskGroupRelationInfo'
        );
        assertNotNullOrUndefined(
          this.currentProcessTaskGroup,
          'cannot send request to toggle processTaskGroupRelation without currentProcessTaskGroup'
        );
        const response =
          await this.socketService.setProcessTaskGroupToProcessTaskGroupRelationStatus(
            {
              processTaskGroup1Id: this.currentProcessTaskGroup.id,
              processTaskGroup2Id:
                this.processTaskGroupRelationInfo.processTaskGroupId,
              areRelated: !this.processTaskGroupRelationInfo.hasActiveRelation
            }
          );

        if (response.success) {
          this.processTaskGroupRelationInfo.hasActiveRelation =
            response.newRelationStatus;
          this.updateProcessTaskGroupRelationInfos &&
            void this.updateProcessTaskGroupRelationInfos();
          return Status.OK;
        }

        return Status.ERROR;
      });
  }

  @computed(expression('processTaskGroupRelationInfo.hasActiveRelation'))
  protected get signedIconForButton(): 'fa-minus-circle' | 'fa-plus-circle' {
    return this.processTaskGroupRelationInfo?.hasActiveRelation
      ? 'fa-minus-circle'
      : 'fa-plus-circle';
  }

  @computed(expression('processTaskGroupRelationInfo.hasSameThingGroup'))
  protected get highlightColor(): string {
    if (this.processTaskGroupRelationInfo?.hasSameThingGroup)
      return 'var(--record-it-color-primary)';
    return 'transparent';
  }

  protected handleToggleRelationButtonClicked(): void {
    if (!this.toggleRelationRequestWithStatus.inProgress) {
      this.toggleRelationRequestWithStatus.startRequest();
    }
  }
}
