/* TODO: typings/cleanup/..., a lot of the typings have to be created manually, since leaflet doesnt't expose it */

export class AnnotatedSvgTileRenderer extends (L.SVG as any).Tile {
  private annotationEnabled: boolean = false;

  constructor(tileCoord: any, tileSize: any, opts: any) {
    super(tileCoord, tileSize, opts);
    this.annotationEnabled = tileCoord.z >= opts.minZoomAnnotation;
  }

  protected _initPath(featureLayer: any): void {
    super._initPath(featureLayer);
    if (this.annotationEnabled) this.initAnnotation(featureLayer);
  }

  protected _addPath(featureLayer: any): void {
    super._addPath(featureLayer);
    if (this.annotationEnabled) this.addAnnotation(featureLayer);
  }

  protected _updateCircle(featureLayer: any): void {
    super._updateCircle(featureLayer);
    if (!this.annotationEnabled) return;

    const x = featureLayer._point.x + featureLayer._radius;
    const y = featureLayer._point.y - featureLayer._radius;

    this.setPositionOfText(featureLayer._text, x, y);

    this.setOverflowVisible(true);
  }

  protected _updatePoly(featureLayer: any, closed: boolean): void {
    super._updatePoly(featureLayer, closed);
    if (!this.annotationEnabled) return;

    const points = featureLayer._parts[0];
    const textOrientation = this.getTextOrientation(points);
    if (textOrientation)
      this.setOrientationOfText(
        featureLayer._text,
        textOrientation,
        featureLayer.options
      );

    this.setOverflowVisible(!closed);
  }

  /* PRIVATE METHODS */

  private initAnnotation(featureLayer: any): void {
    const annotationText = this.getAnnotationText(featureLayer);

    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    text.appendChild(document.createTextNode(annotationText));

    text.setAttribute('fill', featureLayer.options.color);

    featureLayer._text = text;
  }

  private addAnnotation(featureLayer: any): void {
    this._rootGroup.appendChild(featureLayer._text);
  }

  private getAnnotationText(featureLayer: any): string {
    return (
      (this.options.annotationPropertyName &&
        String(featureLayer.properties[this.options.annotationPropertyName])) ||
      ''
    );
  }

  private setOrientationOfText(
    textElement: SVGTextElement,
    textOrientation: ITextOrientation,
    options: any
  ): void {
    const transformString = `translate(${textOrientation.x}, ${textOrientation.y}) rotate(${textOrientation.angle}) translate(0, -${options.weight})`;
    textElement.setAttribute('transform', transformString);
  }

  private setPositionOfText(
    textElement: SVGTextElement,
    x: number,
    y: number
  ): void {
    textElement.setAttribute('x', String(x));
    textElement.setAttribute('y', String(y));
  }

  private getTextOrientation(points: Array<IPoint>): ITextOrientation | null {
    if (points.length <= 1) return null;

    const middleIndex = Math.floor(points.length / 2) - 1;

    const deltaX =
      (points[middleIndex + 1]?.x ?? 0) - (points[middleIndex]?.x ?? 0);
    const deltaY =
      (points[middleIndex + 1]?.y ?? 0) - (points[middleIndex]?.y ?? 0);

    const radAngle = Math.atan2(deltaY, deltaX);

    let point = points[middleIndex];
    let angle = (360 / (2 * Math.PI)) * radAngle;

    if (angle < -90) {
      point = points[middleIndex + 1];
      angle += 180;
    } else if (angle > 90) {
      point = points[middleIndex + 1];
      angle -= 180;
    }

    return {
      x: point?.x ?? 0,
      y: point?.y ?? 0,
      angle: angle
    };
  }

  private setOverflowVisible(overflowVisible: boolean): void {
    const container = <SVGElement>this.getContainer();
    if (overflowVisible) {
      container.style.overflow = 'visible';
    } else {
      container.style.overflow = 'hidden';
    }
  }
}

interface IPoint {
  x: number;
  y: number;
}

interface ITextOrientation {
  x: number;
  y: number;
  angle: number;
}
