import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap
} from '../../computedValues/computers/ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessTaskDeviceExportSelect {
  @bindable()
  public processTaskDevice: ProcessTaskDevice | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private deviceExportsByProcessTaskGroupId: ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap =
    new Map();
  private deviceExports: Array<ProcessConfigurationDeviceExport> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskDevice.ownerProcessConfigurationId',
      this.updateDeviceExports.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer,
        computeData: {},
        callback: (deviceExportsByProcessTaskGroupId) => {
          this.deviceExportsByProcessTaskGroupId =
            deviceExportsByProcessTaskGroupId;
          this.updateDeviceExports();
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateDeviceExports(): void {
    if (this.processTaskDevice) {
      console.error(this.deviceExportsByProcessTaskGroupId);
      this.deviceExports =
        this.deviceExportsByProcessTaskGroupId.get(
          this.processTaskDevice.ownerProcessTaskGroupId
        ) ?? [];
    } else {
      this.deviceExports = [];
    }
  }

  private handleDeviceChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskDevice,
      "can't ProcessTaskDeviceExportSelect.handleDeviceChanged without processTaskDevice"
    );
    this.entityManager.processTaskDeviceRepository.update(
      this.processTaskDevice
    );
  }
}
