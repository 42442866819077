import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createExternalUserCredentialEntityInfo } from 'common/Types/Entities/ExternalUserCredential/createExternalUserCredentialEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ExternalUserCredential } from './types';

export const externalUserCredentialEntityInfo =
  createExternalUserCredentialEntityInfo<ExternalUserCredential>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
