import { Entry } from '../Entry/types';
import { StructureTemplateEntry } from './types';

export class StructureTemplateEntryUtils {
  private constructor() {}

  public static entryDependsOnStructureTemplateEntry(
    structureTemplateEntry: StructureTemplateEntry,
    entry: Entry
  ): boolean {
    if (entry.structureTemplateEntryId) {
      return entry.structureTemplateEntryId === structureTemplateEntry.id;
    } else if (entry.originId) {
      return entry.originId === structureTemplateEntry.originId;
    }
    return false;
  }

  public static getPageDepthIndex(path: Array<StructureTemplateEntry>): string {
    const listPositions = [...path]
      .reverse()
      .map((entry) => entry.listPosition + 1);
    return listPositions.join('.') + '.';
  }

  /**
   * Returns the maximum level of the tree of structureTemplateEntry children.
   * @param entries all entries of a structureTemplate.
   */
  public static getMaximumLevel(
    entries: Array<StructureTemplateEntry>
  ): number {
    return this.getMaximumLevelOf(null, entries);
  }

  /**
   * Returns the maximum level of the children tree of a given `entry`.
   * @param entryId the id of the entry for which the level (level of the node in the tree) shall be calculated.
   * @param entries a list of entries that contains all children of `entry`.
   */
  private static getMaximumLevelOf(
    entryId: string | null,
    entries: Array<StructureTemplateEntry>
  ): number {
    const children = entries.filter((e) => e.parentEntryId === entryId);
    return (
      Math.max(
        0,
        ...children.map((child) => this.getMaximumLevelOf(child.id, entries))
      ) + 1
    );
  }
}
