import { autoinject } from 'aurelia-framework';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditProcessConfigurationDialog } from '../../operationsComponents/edit-process-configuration-dialog/edit-process-configuration-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfigurationUtils } from '../../classes/EntityManager/entities/ProcessConfiguration/ProcessConfigurationUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';

@autoinject()
export class edit_process_configurations {
  private readonly subscriptionManager: SubscriptionManager;

  protected availableProcessConfigurations: Array<ProcessConfiguration> = [];
  protected sortedProcessConfigurations: Array<ProcessConfiguration> = [];
  protected currentPageProcessConfigurations: Array<ProcessConfiguration> = [];
  protected isMobile: boolean = false;
  private isAttached: boolean = false;

  protected currentSortOption =
    ProcessConfigurationUtils.processConfigurationSortOptions.name;

  protected pagination: Pagination<ProcessConfiguration> | null = null;

  protected readonly sortOptions =
    ProcessConfigurationUtils.processConfigurationSortOptions;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateAvailableProcessConfigurations.bind(this)
    );
    this.updateAvailableProcessConfigurations();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected updateAvailableProcessConfigurations(): void {
    this.availableProcessConfigurations =
      this.entityManager.processConfigurationRepository.getAll();
  }

  protected handleCreateProcessConfigurationClicked(
    userGroup: UserGroup
  ): void {
    const processConfiguration =
      this.entityManager.processConfigurationRepository.create({
        ownerUserGroupId: userGroup.id
      });

    this.updateAvailableProcessConfigurations();
    this.openEditDialogForProcessConfiguration(processConfiguration);
  }

  protected handleEditProcessConfigurationClicked(
    processConfiguration: ProcessConfiguration
  ): void {
    this.openEditDialogForProcessConfiguration(processConfiguration);
  }

  private openEditDialogForProcessConfiguration(
    processConfiguration: ProcessConfiguration
  ): void {
    void EditProcessConfigurationDialog.open({
      processConfiguration: processConfiguration,
      onDialogClosed: () => {
        this.goToProcessConfiguration(processConfiguration);
      }
    });
  }

  private goToProcessConfiguration(
    processConfiguration: ProcessConfiguration
  ): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getProcessConfigurationListItemId(processConfiguration.id),
      this.pagination,
      processConfiguration,
      () => this.isAttached
    );
  }

  private getProcessConfigurationListItemId(
    processConfigurationId: string
  ): string {
    return (
      'edit-process-configurations--process-configuration' +
      processConfigurationId
    );
  }
}
