import { PersonHelper } from '../../../EntityHelper/PersonHelper';
import { DateType, IdType } from '../../../Types/Entities/Base/types';
import { PersonDto } from '../../../Types/Entities/Person/PersonDto';
import { ProcessTaskDto } from '../../../Types/Entities/ProcessTask/ProcessTaskDto';
import { ProcessTaskGroupDto } from '../../../Types/Entities/ProcessTaskGroup/ProcessTaskGroupDto';
import { ThingGroupDto } from '../../../Types/Entities/ThingGroup/ThingGroupDto';
import { OperationsDataFetcher } from './OperationsDataFetcher';
import {
  ContextSpecificFieldConfigs,
  ExpressionEditorScopeCreationUtils
} from '../../ExpressionEditorScopeCreationUtils';
import { OperationsFieldConfigs } from './OperationsFieldConfigs';
import { ProcessTaskGroupHelper } from '../../../Operations/ProcessTaskGroupHelper';
import { ProcessTaskAppointmentDto } from '../../../Types/Entities/ProcessTaskAppointment/ProcessTaskAppointmentDto';
import { ThingGroupHelper } from '../../../EntityHelper/ThingGroupHelper';
import { ProcessTaskRecurringAppointmentDto } from '../../../Types/Entities/ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentDto';

export class OperationsExpressionEditorScope<
  TId extends IdType,
  TDate extends DateType
> {
  constructor(private readonly fetcher: OperationsDataFetcher<TId, TDate>) {}

  public async createFieldConfigsForProcessTaskGroupScope(
    options: OperationsExpressionEditorScopeProcessTaskGroupScopeOptions
  ): Promise<
    ContextSpecificFieldConfigs<
      typeof OperationsFieldConfigs.processTaskGroupRequestFieldConfigs
    >
  > {
    const thingGroup = await this.fetcher.getThingGroup(
      options.currentThingGroup.id
    );
    if (!thingGroup) {
      throw new Error(
        `tried to construct dataToSet for thingGroup ${options.currentThingGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTaskGroup = await this.fetcher.getProcessTaskGroup(
      options.currentProcessTaskGroup.id
    );
    if (!processTaskGroup) {
      throw new Error(
        `tried to construct dataToSet for processTaskGroup ${options.currentProcessTaskGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTask = options.firstProcessTask
      ? await this.fetcher.getProcessTask(options.firstProcessTask.id)
      : null;
    if (options.firstProcessTask && !processTask) {
      throw new Error(
        `tried to construct dataToSet for processTask ${options.firstProcessTask.id}, but couldn't retrieve db entity.`
      );
    }

    return this.createContextSpecificProcessTaskGroupScopeFieldConfigs({
      thingGroup,
      processTaskGroup,
      processTask
    });
  }

  public async createFieldConfigsForProcessTaskScope(
    options: OperationsExpressionEditorScopeProcessTaskScopeOptions
  ): Promise<
    ContextSpecificFieldConfigs<
      typeof OperationsFieldConfigs.processTaskRequestFieldConfigs
    >
  > {
    const thingGroup = await this.fetcher.getThingGroup(
      options.currentThingGroup.id
    );
    if (!thingGroup) {
      throw new Error(
        `tried to construct dataToSet for thingGroup ${options.currentThingGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTaskGroup = await this.fetcher.getProcessTaskGroup(
      options.currentProcessTaskGroup.id
    );
    if (!processTaskGroup) {
      throw new Error(
        `tried to construct dataToSet for processTaskGroup ${options.currentProcessTaskGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTask = await this.fetcher.getProcessTask(
      options.currentProcessTask.id
    );
    if (!processTask) {
      throw new Error(
        `tried to construct dataToSet for processTask ${options.currentProcessTask.id}, but couldn't retrieve db entity.`
      );
    }

    return this.createContextSpecificProcessTaskScopeFieldConfigs({
      thingGroup,
      processTaskGroup,
      processTask
    });
  }

  public async createFieldConfigsForAppointmentScope(
    options: OperationsExpressionEditorScopeAppointmentScopeOptions
  ): Promise<
    ContextSpecificFieldConfigs<
      typeof OperationsFieldConfigs.appointmentRequestFieldConfigs
    >
  > {
    const thingGroup = await this.fetcher.getThingGroup(
      options.currentThingGroup.id
    );
    if (!thingGroup) {
      throw new Error(
        `tried to construct dataToSet for thingGroup ${options.currentThingGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTaskGroup = await this.fetcher.getProcessTaskGroup(
      options.currentProcessTaskGroup.id
    );
    if (!processTaskGroup) {
      throw new Error(
        `tried to construct dataToSet for processTaskGroup ${options.currentProcessTaskGroup.id}, but couldn't retrieve db entity.`
      );
    }

    const processTask = await this.fetcher.getProcessTask(
      options.currentProcessTask.id
    );
    if (!processTask) {
      throw new Error(
        `tried to construct dataToSet for processTask ${options.currentProcessTask.id}, but couldn't retrieve db entity.`
      );
    }

    let appointment:
      | ProcessTaskAppointmentDto<TId, TDate>
      | ProcessTaskRecurringAppointmentDto<TId, TDate>
      | null = null;
    if (options.currentProcessTaskAppointment) {
      appointment = await this.fetcher.getProcessTaskAppointment(
        options.currentProcessTaskAppointment.id
      );
    } else if (options.currentProcessTaskRecurringAppointment) {
      appointment = await this.fetcher.getProcessTaskRecurringAppointment(
        options.currentProcessTaskRecurringAppointment.id
      );
    }
    if (!appointment) {
      throw new Error(
        `tried to construct dataToSet for processTaskAppointment ${options.currentProcessTaskAppointment?.id} or processTaskRecurringAppointment ${options.currentProcessTaskRecurringAppointment?.id}, but couldn't retrieve db entity.`
      );
    }

    return this.createContextSpecificProcessTaskAppointmentScopeFieldConfigs({
      thingGroup,
      processTaskGroup,
      processTask,
      appointment
    });
  }

  private createContextSpecificThingGroupFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.thingGroupFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thingGroup
        }: {
          thingGroup: ThingGroupDto<TId, TDate>;
        }) => {
          return {
            name: {
              getValue: async () => {
                return thingGroup.name ?? '';
              }
            },
            streetName: {
              getValue: async () => {
                return thingGroup.streetName ?? '';
              }
            },
            zip: {
              getValue: async () => {
                return thingGroup.zip ?? '';
              }
            },
            municipality: {
              getValue: async () => {
                return thingGroup.municipality ?? '';
              }
            },
            fullAddress: {
              getValue: async () => {
                return ThingGroupHelper.getThingGroupAddressString(
                  thingGroup.streetName,
                  thingGroup.zip,
                  thingGroup.municipality
                );
              }
            },
            note: {
              getValue: async () => {
                return thingGroup.note ?? '';
              }
            },
            contactPersonName: {
              getValue: async () => {
                const contactPerson = thingGroup.contactPersonId
                  ? await this.fetcher.getPerson(
                      thingGroup.contactPersonId.toString()
                    )
                  : null;

                return this.getPersonStringToDisplay(contactPerson);
              }
            },
            ownerPersonName: {
              getValue: async () => {
                const ownerPerson = thingGroup.ownerPersonId
                  ? await this.fetcher.getPerson(
                      thingGroup.ownerPersonId.toString()
                    )
                  : null;

                return this.getPersonStringToDisplay(ownerPerson);
              }
            }
          };
        }
      }
    );

  private createContextSpecificProcessTaskGroupFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.processTaskGroupFieldConfigs,
        createContextSpecificFieldConfigData: ({
          processTaskGroup
        }: {
          processTaskGroup: ProcessTaskGroupDto<TId, TDate>;
        }) => {
          return {
            note: {
              getValue: async () => {
                return processTaskGroup.note ?? '';
              }
            },
            officeNote: {
              getValue: async () => {
                return processTaskGroup.officeNote ?? '';
              }
            },
            referenceCode: {
              getValue: async () => {
                return processTaskGroup.referenceCode ?? '';
              }
            },
            contactPersonName: {
              getValue: async () => {
                // The datascheme has been changed to support an array of contact persons.
                // Arrays are not supported yet for ExpressionEditorScopes, so we fallback to the first person
                const contactPerson = processTaskGroup.contactPersons[0]
                  ?.personId
                  ? await this.fetcher.getPerson(
                      processTaskGroup.contactPersons[0].personId.toString()
                    )
                  : null;

                return this.getPersonStringToDisplay(contactPerson);
              }
            },
            offerReceiverPersonName: {
              getValue: async () => {
                const contactPerson = processTaskGroup.offerReceiverPersonId
                  ? await this.fetcher.getPerson(
                      processTaskGroup.offerReceiverPersonId.toString()
                    )
                  : null;

                return this.getPersonStringToDisplay(contactPerson);
              }
            },
            invoiceReceiverPersonName: {
              getValue: async () => {
                const id =
                  ProcessTaskGroupHelper.getInvoiceReceiverPersonIdOrFallback(
                    processTaskGroup
                  );
                const contactPerson = id
                  ? await this.fetcher.getPerson(id.toString())
                  : null;

                return this.getPersonStringToDisplay(contactPerson);
              }
            }
          };
        }
      }
    );

  private createContextSpecificProcessTaskFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.processTaskFieldConfigs,
        createContextSpecificFieldConfigData: ({
          processTask
        }: {
          processTask: ProcessTaskDto<TId, TDate>;
        }) => {
          return {
            name: {
              getValue: async () => {
                return processTask.name ?? '';
              }
            },
            description: {
              getValue: async () => {
                return processTask.description ?? '';
              }
            },
            note: {
              getValue: async () => {
                return processTask.note ?? '';
              }
            },
            thingName: {
              getValue: async () => {
                return processTask.thingId
                  ? ((
                      await this.fetcher.getThing(
                        processTask.thingId.toString()
                      )
                    )?.name ?? '')
                  : '';
              }
            },
            thingDescription: {
              getValue: async () => {
                return processTask.thingId
                  ? ((
                      await this.fetcher.getThing(
                        processTask.thingId.toString()
                      )
                    )?.description ?? '')
                  : '';
              }
            },
            thingMainContactPerson: {
              getValue: async () => {
                if (!processTask.thingId) return '';
                const thingToPersonRelations =
                  await this.fetcher.getThingToPersonsByThingId(
                    processTask.thingId.toString()
                  );
                const mainRelation =
                  thingToPersonRelations.find((x) => !!x.mainContact) ??
                  thingToPersonRelations[0];
                if (!mainRelation) return '';

                const person = await this.fetcher.getPerson(
                  mainRelation.personId.toString()
                );
                return this.getPersonStringToDisplay(person);
              }
            }
          };
        }
      }
    );

  private createContextSpecificAppointmentFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.appointmentFieldConfigs,
        createContextSpecificFieldConfigData: ({
          appointment
        }: {
          appointment:
            | ProcessTaskAppointmentDto<TId, TDate>
            | ProcessTaskRecurringAppointmentDto<TId, TDate>;
        }) => {
          return {
            name: {
              getValue: async () => {
                return appointment.name ?? '';
              }
            },
            note: {
              getValue: async () => {
                if ('note' in appointment) return appointment.note ?? '';
                return '';
              }
            },
            workNote: {
              getValue: async () => {
                if ('workNote' in appointment)
                  return appointment.workNote ?? '';
                return '';
              }
            }
          };
        }
      }
    );

  private createContextSpecificProcessTaskAppointmentScopeFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.appointmentRequestFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thingGroup,
          processTaskGroup,
          processTask,
          appointment
        }: {
          thingGroup: ThingGroupDto<TId, TDate>;
          processTaskGroup: ProcessTaskGroupDto<TId, TDate>;
          processTask: ProcessTaskDto<TId, TDate>;
          appointment:
            | ProcessTaskAppointmentDto<TId, TDate>
            | ProcessTaskRecurringAppointmentDto<TId, TDate>;
        }) => {
          return {
            thingGroup: this.createContextSpecificThingGroupFieldConfigs({
              thingGroup
            }),
            processTaskGroup:
              this.createContextSpecificProcessTaskGroupFieldConfigs({
                processTaskGroup
              }),
            processTask: this.createContextSpecificProcessTaskFieldConfigs({
              processTask
            }),
            appointment: this.createContextSpecificAppointmentFieldConfigs({
              appointment
            })
          };
        }
      }
    );

  private createContextSpecificProcessTaskScopeFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs: OperationsFieldConfigs.processTaskRequestFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thingGroup,
          processTaskGroup,
          processTask
        }: {
          thingGroup: ThingGroupDto<TId, TDate>;
          processTaskGroup: ProcessTaskGroupDto<TId, TDate>;
          processTask: ProcessTaskDto<TId, TDate>;
        }) => {
          return {
            thingGroup: this.createContextSpecificThingGroupFieldConfigs({
              thingGroup
            }),
            processTaskGroup:
              this.createContextSpecificProcessTaskGroupFieldConfigs({
                processTaskGroup
              }),
            processTask: this.createContextSpecificProcessTaskFieldConfigs({
              processTask
            })
          };
        }
      }
    );

  private createContextSpecificProcessTaskGroupScopeFieldConfigs =
    ExpressionEditorScopeCreationUtils.createContextSpecificFieldConfigsFactory(
      {
        fieldConfigs:
          OperationsFieldConfigs.processTaskGroupRequestFieldConfigs,
        createContextSpecificFieldConfigData: ({
          thingGroup,
          processTaskGroup,
          processTask
        }: {
          thingGroup: ThingGroupDto<TId, TDate>;
          processTaskGroup: ProcessTaskGroupDto<TId, TDate>;
          processTask: ProcessTaskDto<TId, TDate> | null;
        }) => {
          return {
            thingGroup: this.createContextSpecificThingGroupFieldConfigs({
              thingGroup
            }),
            processTaskGroup:
              this.createContextSpecificProcessTaskGroupFieldConfigs({
                processTaskGroup
              }),
            firstProcessTask: processTask
              ? this.createContextSpecificProcessTaskFieldConfigs({
                  processTask
                })
              : null
          };
        }
      }
    );

  private getPersonStringToDisplay(
    person: PersonDto<TId, TDate> | null
  ): string {
    if (!person) return '';
    return PersonHelper.getPersonDisplayName(
      person.company,
      person.companyName,
      person.title,
      person.firstName,
      person.lastName
    );
  }
}

export type OperationsExpressionEditorScopeProcessTaskScopeOptions = {
  currentThingGroup: Options;
  currentProcessTaskGroup: Options;
  currentProcessTask: Options;
};

export type OperationsExpressionEditorScopeAppointmentScopeOptions = {
  currentThingGroup: Options;
  currentProcessTaskGroup: Options;
  currentProcessTask: Options;
} & (
  | {
      currentProcessTaskAppointment: Options;
      currentProcessTaskRecurringAppointment?: never;
    }
  | {
      currentProcessTaskRecurringAppointment: Options;
      currentProcessTaskAppointment?: never;
    }
);

export type OperationsExpressionEditorScopeProcessTaskGroupScopeOptions = {
  currentThingGroup: Options;
  currentProcessTaskGroup: Options;
  firstProcessTask: Options | null;
};

type Options = {
  id: string;
};
