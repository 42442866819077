import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskRecurringAppointment } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { EditProcessTaskAppointmentDialog } from '../edit-process-task-appointment-dialog/edit-process-task-appointment-dialog';
import { OnAppointmentSplitOffEvent } from '../edit-process-task-recurring-appointment-widget/corresponding-appointments-widget/corresponding-appointments-widget';
import { EditProcessTaskRecurringAppointmentWidget } from '../edit-process-task-recurring-appointment-widget/edit-process-task-recurring-appointment-widget';

@autoinject()
export class EditProcessTaskRecurringAppointmentDialog {
  protected dialog: RecordItDialog | null = null;
  protected appointment: ProcessTaskRecurringAppointment | null = null;
  protected editProcessTaskRecurringAppointmentWidget: EditProcessTaskRecurringAppointmentWidget | null =
    null;

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: DialogOptions): void {
    this.appointment = options.appointment;
    this.dialog?.open();
    if (
      this.editProcessTaskRecurringAppointmentWidget &&
      options.selectedDate
    ) {
      this.editProcessTaskRecurringAppointmentWidget.setSelectedDate(
        options.selectedDate
      );
    }
  }

  protected handleAppointmentSplitOff(event: OnAppointmentSplitOffEvent): void {
    this.dialog?.close();
    void EditProcessTaskAppointmentDialog.open({
      appointment: event.detail.processTaskAppointment
    });
  }
}

export type DialogOptions = {
  appointment: ProcessTaskRecurringAppointment;
  /**
   * If present, upon opening this dialog, this date will be selected in the corresponding appointment widget.
   */
  selectedDate?: Date;
};
