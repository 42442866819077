import { toNumber as weekdayToNumber, Weekday } from '../../Enums/Weekday';
import { toNumber as monthToNumber } from '../../Enums/Month';
import {
  Recurrence,
  RecurrenceFrequency
} from '../../Types/Entities/ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentDto';
import { DateUtils } from '../../DateUtils';
import { assertNotNullOrUndefined } from '../../Asserts';
import { OptionalProperties, RequiredProperties } from '../../Types/utilities';

/**
 * Calculates all recurrences of an item with recurrence.
 */
export class RecurrenceCalculator {
  /**
   * Retrieves all calendar entries of the given item in the timespan from fromDate to toDate.
   *
   * This is an "algorithm" that takes items with recurrence rules
   * (specified in the https://datatracker.ietf.org/doc/html/rfc5545 format)
   * and generates the specific items from them.
   *
   * The algorithm is incomplete. It matches the iCal format, but doesn't
   * implement every possible functionality present there.
   * For example, some recurrence settings can only be used with certain
   * recurrence frequencies, and will be ignored in others. This is because
   * the RFC is a hell of a beast to implement, and most of the functionality
   * isn't needed here. Every rule that can be generated in RecordIT can be
   * exported 1:1 to the iCal format, but not the other way around.
   */
  public get(
    item: Item,
    fromDate: Date,
    toDate: Date
  ): Array<ItemWithoutRecurrence> {
    // If there's no recurrence specified, life is simple,
    // and the one entry can be returned.
    if (!item.recurrence) {
      if (DateUtils.isBetween(item.startDate, fromDate, toDate)) {
        return [this.removeRecurrence(item)];
      }
      return [];
    }

    // If recurrence.count is specified, in order to return the correct amount of items,
    // we need to start scanning for events from the startDate of the item instead of the given one
    let newFromDate = fromDate;
    const shouldUseItemStartDate =
      item.recurrence.count && DateUtils.isBefore(item.startDate, newFromDate);
    if (shouldUseItemStartDate) {
      newFromDate = item.startDate;
    }

    let entries: Array<ItemWithoutRecurrence> = [];

    // Depending on the frequency, different parameters may be set,
    // and entries need to be handled differently
    switch (item.recurrence.frequency) {
      case RecurrenceFrequency.YEARLY:
        entries = this.getEntriesForEventsRecurringYearly(
          item as ItemWithRecurrence,
          newFromDate,
          toDate
        );
        break;
      case RecurrenceFrequency.MONTHLY:
        entries = this.getEntriesForEventsRecurringMonthly(
          item as ItemWithRecurrence,
          newFromDate,
          toDate
        );
        break;
      case RecurrenceFrequency.WEEKLY:
        entries = this.getEntriesForEventsRecurringWeekly(
          item as ItemWithRecurrence,
          newFromDate,
          toDate
        );
        break;
      case RecurrenceFrequency.DAILY:
        entries = this.getEntriesForEventsRecurringDaily(
          item as ItemWithRecurrence,
          newFromDate,
          toDate
        );
        break;
      default:
        break;
    }

    // Filter out dates before the start date of item
    entries = entries.filter(
      (e) => item.startDate.getTime() <= e.startDate.getTime()
    );

    // If `count` is defined, limit entries to `count` entries
    if (item.recurrence.count) {
      entries = entries.slice(0, item.recurrence.count);
      // ... and if fromDate has been set to the item startDate above,
      // make sure there are no entries before the fromDate
      if (shouldUseItemStartDate) {
        entries = entries.filter(
          (e) => fromDate.getTime() <= e.startDate.getTime()
        );
      }
    }

    // If `until` is defined, remove all entries after `until`
    if (item.recurrence.until) {
      const untilDate = new Date(item.recurrence.until);
      entries = entries.filter((e) => {
        return DateUtils.isBefore(new Date(e.startDate), untilDate);
      });
    }

    // Filter out entries with invalid dates
    entries = entries.filter(
      (e) => !isNaN(e.startDate.getTime()) && !isNaN(e.endDate.getTime())
    );

    return entries;
  }

  /**
   * Generates entries in the calendar from an event that repeats yearly, within the timespan fromDate - toDate.
   */
  private getEntriesForEventsRecurringYearly(
    entry: ItemWithRecurrence,
    fromDate: Date,
    toDate: Date
  ): Array<ItemWithoutRecurrence> {
    // There are two cases we need to handle:
    // - the event repeats on a set date, e.g. every january 1st
    // - or the revent repeats on a certain weekday in a certain month, e.g. the first sunday of february

    const entries: Array<ItemWithoutRecurrence> = [];

    // In the first case, `byMonths` & `byMonthDays` will be set
    if (
      entry.recurrence.byMonths?.length &&
      entry.recurrence.byMonthDays?.length
    ) {
      const byMonths = entry.recurrence.byMonths;
      const byMonthDays = entry.recurrence.byMonthDays;
      entries.push(
        ...this.generateEntries({
          entry,
          fromDate: fromDate,
          toDate: toDate,
          getYears: () => this.genPossibleYears(fromDate, toDate),
          getMonths: () => byMonths.map(monthToNumber),
          getDays: () => byMonthDays
        })
      );
    }

    // In the second, `byMonths`, `byDays` and `pos` will be set
    if (
      entry.recurrence.byMonths?.length &&
      entry.recurrence.byDays?.length &&
      entry.recurrence.pos
    ) {
      const byMonths = entry.recurrence.byMonths;
      const byDays = entry.recurrence.byDays;
      entries.push(
        ...this.generateEntries({
          entry,
          fromDate: fromDate,
          toDate: toDate,
          getYears: () => this.genPossibleYears(fromDate, toDate),
          getMonths: () => byMonths.map(monthToNumber),
          getDays: (year, month) =>
            this.getDaysFromWeekdays(byDays, month, year),
          isValidEntry: () => true,
          getEntriesFromPossibleYearEntries: (possibleEntries) =>
            this.filterEntriesByPos(possibleEntries, fromDate, toDate, entry)
        })
      );
    }

    return entries;
  }

  /**
   * Generates entries in the calendar from an event that repeats monthly, within the timespan fromDate - toDate.
   */
  private getEntriesForEventsRecurringMonthly(
    entry: ItemWithRecurrence,
    fromDate: Date,
    toDate: Date
  ): Array<ItemWithoutRecurrence> {
    // There are two cases we need to handle:
    // - the event repeats on a set day, e.g. every 1st
    // - the event repeats on a certain weekday, e.g. the first tuesday

    const entries: Array<ItemWithoutRecurrence> = [];

    // In the first case, only `byMonthDays` will be defined
    if (entry.recurrence.byMonthDays?.length) {
      const byMonthDays = entry.recurrence.byMonthDays;
      entries.push(
        ...this.generateEntries({
          entry,
          fromDate: fromDate,
          toDate: toDate,
          getYears: () => this.genPossibleYears(fromDate, toDate),
          getMonths: (year) => this.genPossibleMonths(year, fromDate, toDate),
          getDays: () => byMonthDays
        })
      );
    }

    // In the second, there will be `byDays` & `pos`
    if (entry.recurrence.byDays?.length && entry.recurrence.pos) {
      const byDays = entry.recurrence.byDays;
      entries.push(
        ...this.generateEntries({
          entry,
          fromDate: fromDate,
          toDate: toDate,
          getYears: () => this.genPossibleYears(fromDate, toDate),
          getMonths: (year) => this.genPossibleMonths(year, fromDate, toDate),
          getDays: (year, month) =>
            this.getDaysFromWeekdays(byDays, month, year),
          isValidEntry: () => true,
          getEntriesFromPossibleMonthEntries: (possibleEntries) =>
            this.filterEntriesByPos(possibleEntries, fromDate, toDate, entry)
        })
      );
    }

    return entries;
  }

  /**
   * Generates entries in the calendar from an event that repeats weekly, within the timespan fromDate - toDate.
   */
  private getEntriesForEventsRecurringWeekly(
    entry: ItemWithRecurrence,
    fromDate: Date,
    toDate: Date
  ): Array<ItemWithoutRecurrence> {
    // Thankfully, with weeks, there is only one case: with weekdays (`byDays`) being specified.

    const entries: Array<ItemWithoutRecurrence> = [];

    if (entry.recurrence.byDays?.length) {
      const byDays = entry.recurrence.byDays;
      entries.push(
        ...this.generateEntries({
          entry,
          fromDate: fromDate,
          toDate: toDate,
          getYears: () => this.genPossibleYears(fromDate, toDate),
          getMonths: (year) => this.genPossibleMonths(year, fromDate, toDate),
          getDays: (year, month) =>
            this.getDaysFromWeekdays(byDays, month, year)
        })
      );
    }

    return entries;
  }

  /**
   * Generates entries in the calendar from an event that repeats daily, within the timespan fromDate - toDate.
   */
  private getEntriesForEventsRecurringDaily(
    entry: ItemWithRecurrence,
    fromDate: Date,
    toDate: Date
  ): Array<ItemWithoutRecurrence> {
    // With a daily recurring event, the only property can be the interval, so this is pretty simple.

    const entries: Array<ItemWithoutRecurrence> = [];
    const days = DateUtils.getDays(fromDate, toDate);

    // Iterate over all possible days...
    for (const day of days) {
      // ... and create an entry at that date.
      const year = day.getFullYear();
      const month = day.getMonth();
      const monthDay = day.getDate();
      const newEntry = this.getEntryFromBaseEntry(entry, {
        year,
        month,
        monthDay
      });
      if (
        this.isValidEntry(newEntry, {
          fromDate: fromDate,
          toDate: toDate,
          baseEntry: entry
        })
      ) {
        entries.push(newEntry);
      }
    }

    return entries;
  }

  private filterEntriesByPos(
    entries: Array<ItemWithoutRecurrence>,
    fromDate: Date,
    toDate: Date,
    baseEntry: ItemWithRecurrence
  ): Array<ItemWithoutRecurrence> {
    assertNotNullOrUndefined(
      baseEntry.recurrence?.pos,
      'cannot filterEntriesByPos without entry recurrence pos'
    );

    const selectedEntry = this.getEntryAtPos(entries, baseEntry.recurrence.pos);
    if (
      selectedEntry &&
      this.isValidEntry(selectedEntry, {
        fromDate: fromDate,
        toDate: toDate,
        baseEntry
      })
    ) {
      return [selectedEntry];
    }
    return [];
  }

  /**
   * Get days of the month given a list of weekdays and a month & year.
   */
  private getDaysFromWeekdays(
    weekdays: Array<Weekday>,
    month: number,
    year: number
  ): Array<number> {
    const byDays = weekdays.map(weekdayToNumber);
    const weekdayDates = DateUtils.getWeekdaysInMonthAndYear(
      byDays,
      month,
      year
    );
    return weekdayDates.map((w) => w.getDate());
  }

  /**
   * from an array of possible entries, return the one at `pos`.
   *
   * `pos` is NOT the index directly.
   * 1 means the first element, 2 the second, -1 the last, -2 the second-to-last.
   */
  private getEntryAtPos(
    entries: Array<ItemWithoutRecurrence>,
    pos: number
  ): ItemWithoutRecurrence | undefined {
    const idx = pos > 0 ? pos - 1 : entries.length + pos;
    return entries[idx];
  }

  /**
   * Return an array containing all years from fromDate (inclusive) to toDate (inclusive).
   */
  private genPossibleYears(fromDate: Date, toDate: Date): Array<number> {
    const years: Array<number> = [];
    for (let i = fromDate.getFullYear(); i <= toDate.getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  /**
   * Return an array containing all months in the given year.
   *
   * Only months between start & end date will be returned.
   */
  private genPossibleMonths(
    year: number,
    fromDate: Date,
    toDate: Date
  ): Array<number> {
    const months: Array<number> = [];
    // For all possible months
    for (const month of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]) {
      // Check that the month is after the start date
      if (!(year === fromDate.getFullYear() && month < fromDate.getMonth())) {
        // Check that the month is before the end date
        if (!(year === toDate.getFullYear() && month > toDate.getMonth())) {
          months.push(month);
        }
      }
    }
    return months;
  }

  /**
   * Generates multiple entries from a base entry.
   */
  private generateEntries(opts: {
    fromDate: Date;
    toDate: Date;
    entry: ItemWithRecurrence;
    getYears: () => Array<number>;
    getMonths: (year: number) => Array<number>;
    getDays: (year: number, month: number) => Array<number>;
    isValidEntry?: (entry: ItemWithoutRecurrence) => boolean;
    getEntriesFromPossibleYearEntries?: (
      entries: Array<ItemWithoutRecurrence>
    ) => Array<ItemWithoutRecurrence>;
    getEntriesFromPossibleMonthEntries?: (
      entries: Array<ItemWithoutRecurrence>
    ) => Array<ItemWithoutRecurrence>;
  }): Array<ItemWithoutRecurrence> {
    const isValidEntry =
      opts.isValidEntry ??
      ((entry) =>
        this.isValidEntry(entry, {
          fromDate: opts.fromDate,
          toDate: opts.toDate,
          baseEntry: opts.entry
        }));

    const getEntriesFromPossibleYearEntries =
      opts.getEntriesFromPossibleYearEntries ?? ((entries) => entries);
    const getEntriesFromPossibleMonthEntries =
      opts.getEntriesFromPossibleMonthEntries ?? ((entries) => entries);

    const entries: Array<ItemWithoutRecurrence> = [];
    for (const year of opts.getYears()) {
      const possibleYearEntries: Array<ItemWithoutRecurrence> = [];
      for (const month of opts.getMonths(year)) {
        const possibleMonthEntries: Array<ItemWithoutRecurrence> = [];
        for (const monthDay of opts.getDays(year, month)) {
          const newEntry = this.getEntryFromBaseEntry(opts.entry, {
            year,
            month,
            monthDay
          });
          if (isValidEntry(newEntry)) {
            possibleMonthEntries.push(newEntry);
          }
        }
        possibleYearEntries.push(
          ...getEntriesFromPossibleMonthEntries(possibleMonthEntries)
        );
      }
      entries.push(...getEntriesFromPossibleYearEntries(possibleYearEntries));
    }
    return entries;
  }

  /**
   * returns true if the entry is a valid entry to add to the result set.
   */
  private isValidEntry(
    entry: ItemWithoutRecurrence,
    opts: {
      baseEntry: ItemWithRecurrence;
      fromDate: Date;
      toDate: Date;
    }
  ): boolean {
    // Validness is simply defined as
    // - "is the startTimestamp within the fromDate-toDate timeframe?" and
    // - "is the offset from entry - baseEntry compatible with the given interval and frequency?"

    // Checking for the entry being within start- to endDate is fairly simple
    const isBetweenStartAndEnd = DateUtils.isBetween(
      entry.startDate,
      opts.fromDate,
      opts.toDate
    );

    if (!opts.baseEntry.recurrence?.interval) return isBetweenStartAndEnd;
    if (opts.baseEntry.recurrence.interval === 1) return isBetweenStartAndEnd;
    if (!opts.baseEntry.recurrence?.frequency) return false;

    // If an interval exists, we need to check if the diff between date of entry and baseEntry
    // is a multiple of the interval in terms of the frequency.
    //
    // This is complicated to explain. Here, have an example:
    // - If base entry is '2022-02-02', freq is DAILY, and interval is 1, '2022-02-02', '2022-02-03' & '2022-02-04' would be valid.
    // - If base entry is '2022-02-02', freq is DAILY, and interval is 2, '2022-01-31', '2022-02-04' & '2022-02-06' would be valid.
    // - If base entry is '2022-02-02', freq is MONTHLY, and interval is 3, '2021-11-02', '2022-05-02' & '2022-08-02' would be valid.

    // First, for every recurrence frequency, we get the unitOfTime we want to get the difference of between entry & base entry
    const unitOfTime = this.getUnitOfTimeForFrequency(
      opts.baseEntry.recurrence.frequency
    );
    // Then, calculate the difference of years/months/weeks/days between entry & base entry
    const diff = DateUtils.getDurationWithPrecision(
      opts.baseEntry.startDate,
      entry.startDate,
      unitOfTime
    );

    // Now, we just have to check if diff is a multiple of interval
    const isValidInInterval = diff % opts.baseEntry.recurrence.interval === 0;

    return isBetweenStartAndEnd && isValidInInterval;
  }

  /**
   * returns the moment.js unit of time representation +for a given frequency.
   */
  private getUnitOfTimeForFrequency(
    frequency: RecurrenceFrequency
  ): moment.unitOfTime.Base {
    return {
      [RecurrenceFrequency.YEARLY]: 'years',
      [RecurrenceFrequency.MONTHLY]: 'months',
      [RecurrenceFrequency.WEEKLY]: 'weeks',
      [RecurrenceFrequency.DAILY]: 'days'
    }[frequency] as moment.unitOfTime.Base;
  }

  /**
   * Create an entry from the base entry while changing
   * the starting date of the new entry to the provided `date` parameter.
   *
   * `month` is assumed to be starting at 0, e.g. January = 0, Dezember = 11.
   */
  private getEntryFromBaseEntry(
    entry: ItemWithRecurrence,
    date: { year: number; month: number; monthDay: number }
  ): ItemWithoutRecurrence {
    const entryDate = this.getModifiedDate(entry, date);
    // Get time duration from start - end and apply it to the new date
    const diff = DateUtils.getDurationInMillisecondsBetween(
      entry.startDate,
      entry.endDate
    );
    return this.createEntry(entry, {
      startDate: entryDate,
      endDate: DateUtils.getDatePlusMilliseconds(entryDate, diff)
    });
  }

  /**
   * Returns a new date from the startTimestamp of the given entry,
   * with the year/month/day data overwritten by the given `date` parameter.
   *
   * `month` is assumed to be starting at 0, e.g. January = 0, Dezember = 11.
   */
  private getModifiedDate(
    entry: ItemWithRecurrence,
    date: { year: number; month: number; monthDay: number }
  ): Date {
    const baseEntryDate = new Date(entry.startDate);
    const entryDate = DateUtils.createDate(
      date.monthDay,
      date.month + 1,
      date.year,
      baseEntryDate.getUTCHours(),
      baseEntryDate.getUTCMinutes(),
      true
    );
    return entryDate;
  }

  /**
   * Creates an entry (without recurrence rules) from a base entry.
   */
  private createEntry(
    baseData: ItemWithRecurrence,
    overrides?: Partial<ItemWithoutRecurrence>
  ): ItemWithoutRecurrence {
    return this.removeRecurrence({
      ...baseData,
      ...overrides
    });
  }

  private removeRecurrence(data: Item): ItemWithoutRecurrence {
    const withoutRecurrence: OptionalProperties<Item, 'recurrence'> = {
      ...data
    };
    delete withoutRecurrence.recurrence;
    return withoutRecurrence as ItemWithoutRecurrence;
  }
}

export type Item = {
  startDate: Date;
  endDate: Date;
  recurrence: Recurrence | null;
};

export type ItemWithoutRecurrence = Omit<Item, 'recurrence'> & {
  recurrence?: never;
};

export type ItemWithRecurrence = RequiredProperties<Item, 'recurrence'>;
