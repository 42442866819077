import { DateUtils } from 'common/DateUtils';

import {
  CalendarEntry,
  DaySpecificCalendarEntry
} from '../CalendarEntryDataSource/CalendarEntry';

/**
 * Splits CalendarEntries which cover multiple days into separate CalendarEntries
 */
export class CalendarEntryPerDaySplitter {
  public static convertToDaySpecificCalendarEntries(
    entries: Array<CalendarEntry>
  ): Array<DaySpecificCalendarEntry> {
    const splittedEntries: Array<DaySpecificCalendarEntry> = [];
    for (const entry of entries) {
      const range = this.generateDateRange(
        new Date(entry.startTimestamp),
        new Date(entry.endTimestamp)
      );

      if (range.length === 1) {
        splittedEntries.push({
          calendarEntry: entry,
          originalMultiDayData: null
        });
        continue;
      }
      splittedEntries.push(
        ...this.generateDaySpecificEntriesFromRange(range, entry)
      );
    }

    return splittedEntries;
  }

  private static generateDaySpecificEntriesFromRange(
    range: Array<Date>,
    originalEntry: CalendarEntry
  ): Array<DaySpecificCalendarEntry> {
    const splittedEntries: Array<DaySpecificCalendarEntry> = [];
    const originalMultiDayData = {
      endTimestamp: originalEntry.endTimestamp,
      startTimestamp: originalEntry.startTimestamp,
      fullDuration: originalEntry.duration
    };

    for (const [i, date] of range.entries()) {
      const minutesOnDay = this.getMinutesOfTargetDayCoveredInStartEndRange({
        startDate: new Date(originalEntry.startTimestamp),
        endDate: new Date(originalEntry.endTimestamp),
        targetDate: date
      });

      if (i === 0) {
        const endOfDay = DateUtils.getEndDateOfDay(date);
        splittedEntries.push({
          calendarEntry: {
            ...originalEntry,
            duration: minutesOnDay,
            endTimestamp: endOfDay.getTime()
          },
          originalMultiDayData
        });
      } else if (i === range.length - 1) {
        const startOfDay = DateUtils.getStartDateOfDay(date);
        splittedEntries.push({
          calendarEntry: {
            ...originalEntry,
            duration: minutesOnDay,
            startTimestamp: startOfDay.getTime(),
            startHour: startOfDay.getHours(),
            startMinute: startOfDay.getMinutes()
          },
          originalMultiDayData
        });
      } else {
        const startOfDay = DateUtils.getStartDateOfDay(date);
        const endOfDay = DateUtils.getEndDateOfDay(date);
        splittedEntries.push({
          calendarEntry: {
            ...originalEntry,
            duration: minutesOnDay,
            startTimestamp: startOfDay.getTime(),
            startHour: startOfDay.getHours(),
            startMinute: startOfDay.getMinutes(),
            endTimestamp: endOfDay.getTime()
          },
          originalMultiDayData
        });
      }
    }

    return splittedEntries;
  }

  private static generateDateRange(dateFrom: Date, dateTo: Date): Array<Date> {
    let startDate = dateFrom;
    const endDate = DateUtils.getEndDateOfDay(dateTo);
    const datesArray = [];

    while (startDate.getTime() <= endDate.getTime()) {
      datesArray.push(new Date(startDate));
      startDate = DateUtils.getNextDay(startDate);
    }
    return datesArray;
  }

  private static getMinutesOfTargetDayCoveredInStartEndRange({
    startDate,
    endDate,
    targetDate
  }: {
    startDate: Date;
    endDate: Date;
    targetDate: Date;
  }): number {
    const targetDayStart = DateUtils.getStartDateOfDay(targetDate);
    const targetDayEnd = DateUtils.getEndDateOfDay(targetDate);

    const rangeStart = startDate > targetDayStart ? startDate : targetDayStart;
    const rangeEnd = endDate < targetDayEnd ? endDate : targetDayEnd;

    return Math.round((rangeEnd.getTime() - rangeStart.getTime()) / 1000 / 60);
  }
}
