import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ValueCalculationResultDto } from './ValueCalculationResultDto';

export function createValueCalculationResultEntityInfo<
  TEntity extends ValueCalculationResultDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ValueCalculationResultDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ValueCalculationResult,
  TEntity
> {
  return {
    entityName: EntityName.ValueCalculationResult,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ValueCalculationResultDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        projectId: {
          name: 'projectId',
          relativeSynchronization: false,
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        value: {
          name: 'value',
          defaultValue: null
        },
        valueCalculationConfigId: {
          name: 'valueCalculationConfigId',
          ref: EntityName.ValueCalculationConfig,
          cascadeDeleteLocally: true
        },
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
