import { autoinject, bindable } from 'aurelia-framework';

import { TagSortingMode } from 'common/Enums/TagSortingMode';
import { TagHelper } from 'common/EntityHelper/TagHelper';

import { DomEventHelper } from '../../classes/DomEventHelper';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../inputComponents/text-choice-widget/text-choice-widget';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { TagSorter } from '../../classes/TagSorter';

/**
 * Additional info: the tag will not get added to the tags automatically, because the tag also needs entity information.
 * You have to listen to the `tag-created` event, create the tag in the TagService, and push it into the tags array for it to work correctly
 * (also don't forget if a tag with that name already exists, the TagService has functions that handle that themselves though)
 *
 * @event tag-created - fired when a tag is added, TagCreatedEvent
 * @event tag-removed - fired when a tag is removed, TagRemovedEvent
 */
@autoinject()
export class TagsWidget {
  @bindable public tags: Array<Tag> = [];

  @bindable public availableTags: Array<Tag> = [];

  @bindable public enabled = false;

  /**
   * if this is set to true, only tags from the available tags can be chosen
   */
  @bindable public selectOnly = false;

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  private textTags: Array<string> = [];
  private availableTextTags: Array<string> = [];

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(EntityName.Tag, () => {
      this.updateTextTags();
      this.updateAvailableTextTags();
    });
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'tags',
      this.updateTextTags.bind(this)
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateTextTags();
          this.updateAvailableTextTags();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected tagsChanged(): void {
    this.updateTextTags();
  }

  protected availableTagsChanged(): void {
    this.updateAvailableTextTags();
  }

  private updateTextTags(): void {
    this.textTags = TagSorter.sortTags(this.tags, this.tagSortingMode).map(
      (t) => t.name
    );
  }

  private updateAvailableTextTags(): void {
    this.availableTextTags = TagSorter.sortTags(
      this.availableTags,
      this.tagSortingMode
    ).map((t) => t.name);
  }

  private checkIfTagExists(newTagName: string): boolean {
    return !!this.tags.find(
      (t) => TagHelper.getTagName(t.name) === TagHelper.getTagName(newTagName)
    );
  }

  private handleTagCreated(event: ChoiceAddedEvent): void {
    const newTagName = event.detail.choice;
    if (this.checkIfTagExists(newTagName) || newTagName === '') return;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'tag-created',
      bubbles: true,
      detail: {
        name: newTagName
      }
    });
  }

  protected handleTagRemoved(event: ChoiceRemovedEvent): void {
    const removedTag = this.tags.find((t) => t.name === event.detail.choice);
    if (!removedTag) return;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'tag-removed',
      bubbles: true,
      detail: {
        tag: removedTag
      }
    });
  }
}

export type TagCreatedEvent = CustomEvent<{
  name: string;
}>;

export type TagRemovedEvent = CustomEvent<{
  tag: Tag;
}>;
