import { computedFrom } from 'aurelia-framework';
import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ThingThingSectionProperty } from '../../../classes/EntityManager/entities/Property/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { BaseThingSectionPropertyAdapter } from './BaseThingSectionPropertyAdapter';
import { CreationConfig } from './PropertyAdapter';

export class ThingThingSectionPropertyAdapter extends BaseThingSectionPropertyAdapter<ThingThingSectionProperty> {
  constructor(options: ThingThingSectionPropertyAdapterOptions) {
    super({
      ...options,
      fetchPropertiesFromEntityManager: () => {
        return options.entityManager.propertyRepository.getByThingThingSectionId(
          options.thingSection.id
        );
      },
      location: ThingSectionConfigPropertyLocation.THING
    });
  }

  public createPropertyFromConfig(
    config: CreationConfig<TDefaultPropertyConfig>
  ): ThingThingSectionProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      thingThingSectionId: this.thingSection.id,
      ownerUserGroupId: this.thingSection.ownerUserGroupId
    }) as ThingThingSectionProperty;
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.ThingThingSectionPropertyAdapter.updatePropertiesButton';
  }
}

export type ThingThingSectionPropertyAdapterOptions = {
  entityManager: AppEntityManager;
  permissionsService: PermissionsService;
  subscriptionManagerService: SubscriptionManagerService;
  thingSection: ThingSection;
};
