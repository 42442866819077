import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ColumnInfo } from 'common/EndpointTypes/SharepointEndpointsHandler';
import {
  MappingType,
  SynchronizationDirection
} from 'common/Types/Entities/SharepointListColumnToThingMappingItem/SharepointListColumnToThingMappingItemDto';

import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { SharepointListColumnToThingMappingItem } from '../../classes/EntityManager/entities/SharepointListColumnToThingMappingItem/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EditSharepointListColumnToThingMappingItemDialog } from '../edit-sharepoint-list-column-to-thing-mapping-item-dialog/edit-sharepoint-list-column-to-thing-mapping-item-dialog';

@autoinject()
export class SharepointListColumnToThingMappingListItem {
  @bindable public item: SharepointListColumnToThingMappingItem | null = null;

  @bindable public availableListColumnInfos: Array<ColumnInfo> = [];

  @subscribableLifecycle()
  protected readonly itemPermissionsHandle: EntityNameToPermissionsHandle[EntityName.SharepointListColumnToThingMappingItem];

  protected MappingType = MappingType;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.itemPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.SharepointListColumnToThingMappingItem,
        context: this as SharepointListColumnToThingMappingListItem,
        propertyName: 'item'
      });
  }

  @computed(expression('itemPermissionsHandle.canDeleteEntity'))
  protected get moreButtonChoices(): Array<MoreButtonChoice> {
    const items = [];

    if (this.itemPermissionsHandle.canDeleteEntity) {
      items.push({
        name: 'delete-list-item',
        labelTk:
          'sharepointSynchronizationComponents.sharepointListColumnToThingMappingListItem.deleteItem',
        iconClass: 'fal fa-trash-alt'
      });
    }

    items.push({
      name: 'edit-list-item',
      labelTk:
        'sharepointSynchronizationComponents.sharepointListColumnToThingMappingListItem.editItem',
      iconClass: 'fal fa-pen'
    });

    return items;
  }

  @computed(
    expression('availableListColumnInfos'),
    expression('item.sharepointListColumnName')
  )
  protected get sharepointColumnTitle(): string {
    return (
      this.availableListColumnInfos.find(
        (c) => c.name === this.item?.sharepointListColumnName
      )?.title ??
      this.item?.sharepointListColumnName ??
      ''
    );
  }

  @computed(expression('item.synchronizationDirection'))
  protected get synchronizationArrowClass(): string {
    assertNotNullOrUndefined(this.item, 'no mapping item available');

    switch (this.item.synchronizationDirection) {
      case SynchronizationDirection.SP_TO_THING:
        return 'fa-arrow-right';

      case SynchronizationDirection.THING_TO_SP:
        return 'fa-arrow-left';

      default:
        throw new Error('invalid synchronization direction');
    }
  }

  @computed(expression('itemStatusOk'))
  protected get statusIconName(): string {
    if (this.itemStatusOk) {
      return 'fa-circle-check';
    } else {
      return 'fa-circle-xmark';
    }
  }

  protected handleDeleteListItemClicked(): void {
    const item = this.item;
    assertNotNullOrUndefined(
      item,
      'cannot delete sharepoint list column to thing mapping item without item'
    );

    void Dialogs.deleteEntityDialog(item).then(() => {
      this.entityManager.sharepointListColumnToThingMappingItemRepository.delete(
        item
      );
    });
  }

  protected handleEditListItemClicked(): void {
    assertNotNullOrUndefined(
      this.item,
      'cannot edit sharepoint list column to thing mapping item without item'
    );
    assertNotNullOrUndefined(
      this.thingGroup,
      'cannot edit sharepoint list column to thing mapping item without a thing group'
    );

    void EditSharepointListColumnToThingMappingItemDialog.open({
      thingGroup: this.thingGroup,
      sharepointListColumnToThingMappingItem: this.item,
      listColumnInfos: this.availableListColumnInfos,
      showDeclineButton: false,
      onDialogClosed: () => {}
    });
  }

  @computed(expression('item.thingTypePropertyId'), model(EntityName.Property))
  protected get thingTypeProperty(): Property | null {
    if (!this.item?.thingTypePropertyId) return null;
    return this.entityManager.propertyRepository.getById(
      this.item?.thingTypePropertyId
    );
  }

  @computed(
    expression('availableListColumnInfos'),
    expression('item.mappingType'),
    expression('item.sharepointListColumnName'),
    expression('item.thingFieldName'),
    expression('item.thingTypePropertyId')
  )
  private get itemStatusOk(): boolean {
    switch (this.item?.mappingType) {
      case MappingType.THING_FIELD:
        return (
          !!this.availableListColumnInfos.find(
            (c) => c.name === this.item?.sharepointListColumnName
          ) && !!this.item.thingFieldName
        );

      case MappingType.THING_PROPERTY:
        return (
          !!this.availableListColumnInfos.find(
            (c) => c.name === this.item?.sharepointListColumnName
          ) && !!this.item.thingTypePropertyId
        );

      default:
        return false;
    }
  }

  @computed(expression('item.ownerThingGroupId'), model(EntityName.ThingGroup))
  private get thingGroup(): ThingGroup | null {
    if (!this.item) return null;

    return this.entityManager.thingGroupRepository.getById(
      this.item.ownerThingGroupId
    );
  }
}
