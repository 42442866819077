import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createQuestionCatalogueEntityInfo } from 'common/Types/Entities/QuestionCatalogue/createQuestionCatalogueEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { QuestionCatalogue } from './types';

export const questionCatalogueEntityInfo =
  createQuestionCatalogueEntityInfo<QuestionCatalogue>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
