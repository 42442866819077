import { PLATFORM } from 'aurelia-pal';
import { ModuleName } from '../classes/RecordItModuleHelper';

const routes: Array<InternalRouteConfig> = [
  {
    route: ['', 'home'],
    name: 'home',
    moduleId: PLATFORM.moduleName('pages/home_page/home_page'),
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_objects',
    name: 'edit_objects',
    moduleId: PLATFORM.moduleName('pages/edit_things/edit_things'),
    href: '#edit_objects',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_via_worker_defects',
    name: 'edit_via_worker_defects',
    moduleId: PLATFORM.moduleName(
      'pages/edit_via_worker_defects/edit_via_worker_defects'
    ),
    href: '#edit_via_worker_defects',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.VIA
    }
  },
  {
    route: 'object_map',
    name: 'object_map',
    moduleId: PLATFORM.moduleName('pages/thing_map/thing_map'),
    href: '#object_map',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'object_group_map',
    name: 'object_group_map',
    moduleId: PLATFORM.moduleName('pages/thing_group_map/thing_group_map'),
    href: 'object_group_map',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'object/:thing_id',
    name: 'object',
    moduleId: PLATFORM.moduleName('pages/edit_projects/edit_projects'),
    href: '#object',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_object_types/:thing_type_id?',
    name: 'edit_object_types',
    moduleId: PLATFORM.moduleName('pages/edit_thing_types/edit_thing_types'),
    href: '#edit_object_types',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_object_groups',
    name: 'edit_object_groups',
    moduleId: PLATFORM.moduleName('pages/edit_thing_groups/edit_thing_groups'),
    href: '#edit_object_groups',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_gallery_object/:thing_id',
    name: 'edit_gallery_object',
    moduleId: PLATFORM.moduleName(
      'pages/edit_gallery_thing/edit_gallery_thing'
    ),
    href: '#edit_gallery_object',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.VIA,
      permissionName: 'canUseVia'
    }
  },
  {
    route: 'edit_b1300_project/:project_id/:entry_id?',
    name: 'edit_b1300_project',
    moduleId: PLATFORM.moduleName(
      'pages/edit_b1300_project/edit_b1300_project'
    ),
    href: '#edit_b1300_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.B1300,
      permissionName: 'canUseB1300'
    }
  },
  {
    route: 'edit_inspect_project/:project_id/:entry_id?',
    name: 'edit_inspect_project',
    moduleId: PLATFORM.moduleName(
      'pages/edit_inspect_project/edit_inspect_project'
    ),
    href: '#edit_inspect_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.INSPECT,
      permissionName: 'canUseInspect'
    }
  },
  /**
   * you can also add `edit_entry` query param to open the the entry directly in the expanded view
   * the param must have the value `true` to have an effect
   * e.g. `project/${projectId}/${entryId}?edit_entry=true`
   */
  {
    route: 'project/:project_id/:entry_id?',
    name: 'project',
    moduleId: PLATFORM.moduleName('pages/edit_structure/edit_structure'),
    href: '#project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.BASIC,
      permissionName: 'canUseBasic'
    }
  },
  {
    route: 'import_report_type/:report_type_id?',
    name: 'import_report_type',
    moduleId: PLATFORM.moduleName(
      'pages/import_report_type/import_report_type'
    ),
    href: '#import_report_type',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'export_project/:project_id',
    name: 'export_project',
    moduleId: PLATFORM.moduleName('pages/export_project/export_project'),
    href: '#export_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.BASIC,
      permissionName: 'canUseBasic'
    }
  },
  {
    route: 'export_b1300_project/:project_id',
    name: 'export_b1300_project',
    moduleId: PLATFORM.moduleName('pages/export_project/export_project'),
    href: '#export_b1300_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.B1300,
      permissionName: 'canUseB1300'
    }
  },
  {
    route: 'export_inspect_project/:project_id',
    name: 'export_inspect_project',
    moduleId: PLATFORM.moduleName('pages/export_project/export_project'),
    href: '#export_inspect_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.INSPECT,
      permissionName: 'canUseInspect'
    }
  },
  {
    route: 'edit_users',
    name: 'edit_users',
    moduleId: PLATFORM.moduleName('pages/edit_users/edit_users'),
    href: '#edit_users',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canAdministerUsers'
    }
  },
  {
    route: 'edit_user_company_settings',
    name: 'edit_user_company_settings',
    moduleId: PLATFORM.moduleName(
      'pages/edit_user_company_settings/edit_user_company_settings'
    ),
    href: '#edit_user_company_settings',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canAdministerUsers'
    }
  },

  {
    route: 'edit_usergroups/:user_group_id?',
    name: 'edit_usergroups',
    moduleId: PLATFORM.moduleName('pages/edit_usergroups/edit_usergroups'),
    href: '#edit_usergroups',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },

  {
    route: 'edit_settings',
    name: 'edit_settings',
    moduleId: PLATFORM.moduleName('pages/edit_settings/edit_settings'),
    href: '#edit_settings',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canAdministerUsers'
    }
  },

  {
    // also accepts a search_term get/search parameter
    route: 'global_search',
    name: 'global_search',
    moduleId: PLATFORM.moduleName('pages/global_search/global_search'),
    href: '#global_search',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'confirmation_link/:token?',
    name: 'confirmation_link',
    moduleId: PLATFORM.moduleName('pages/confirmation_link/confirmation_link'),
    href: '#confirmation_link',
    nav: true,
    auth: false,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'accept_usergroup_invitation/:token?',
    name: 'accept_usergroup_invitation',
    moduleId: PLATFORM.moduleName(
      'pages/accept_usergroup_invitation/accept_usergroup_invitation'
    ),
    href: '#accept_usergroup_invitation',
    nav: true,
    auth: false,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'finish_invite/:token',
    name: 'finish_invite',
    moduleId: PLATFORM.moduleName('pages/finish_invite/finish_invite'),
    href: '#finish_invite',
    nav: true,
    auth: false,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'not_found',
    name: 'not_found',
    moduleId: PLATFORM.moduleName('pages/not_found/not_found'),
    nav: false,
    auth: false,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'jan_test',
    name: 'jan_test',
    moduleId: PLATFORM.moduleName('pages/jan_test/jan_test'),
    nav: false,
    auth: false,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_persons',
    name: 'edit_persons',
    moduleId: PLATFORM.moduleName('pages/edit_persons/edit_persons'),
    href: '#edit_persons',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUsePersonExtension'
    }
  },
  {
    route: 'edit_person/:person_id',
    name: 'edit_person',
    moduleId: PLATFORM.moduleName('pages/edit_person/edit_person'),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUsePersonExtension'
    }
  },
  {
    route: 'edit_map_layers',
    name: 'edit_map_layers',
    moduleId: PLATFORM.moduleName('pages/edit_map_layers/edit_map_layers'),
    href: '#edit_map_layers',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canUseMapLayers'
    }
  },
  {
    route: 'edit_structure_templates',
    name: 'edit_structure_templates',
    moduleId: PLATFORM.moduleName(
      'pages/edit_structure_templates/edit_structure_templates'
    ),
    href: '#edit_structure_templates',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canUseStructureTemplates'
    }
  },
  {
    route: 'edit_structure_template/:structure_template_id',
    name: 'edit_structure_template',
    moduleId: PLATFORM.moduleName(
      'pages/edit_structure_template/edit_structure_template'
    ),
    href: '#edit_structure_template',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canUseStructureTemplates'
    }
  },
  {
    route: 'edit_text_brick_templates',
    name: 'edit_text_brick_templates',
    moduleId: PLATFORM.moduleName(
      'pages/edit_text_brick_templates/edit_text_brick_templates'
    ),
    href: '#edit_text_brick_templates',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL
    }
  },
  {
    route: 'edit_user_defined_entity_configs',
    name: 'edit_user_defined_entity_configs',
    moduleId: PLATFORM.moduleName(
      'pages/edit_user_defined_entity_configs/edit_user_defined_entity_configs'
    ),
    href: '#edit_user_defined_entity_configs',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canAdministerUserDefinedEntities'
    }
  },
  {
    route: 'edit_user_defined_entities',
    name: 'edit_user_defined_entities',
    moduleId: PLATFORM.moduleName(
      'pages/edit_user_defined_entities/edit_user_defined_entities'
    ),
    href: '#edit_user_defined_entities',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.GENERAL,
      permissionName: 'canAdministerUserDefinedEntities'
    }
  },

  // ////////////// operations

  {
    route: 'create_process_task_group/:user_group_id',
    name: 'create_process_task_group',
    moduleId: PLATFORM.moduleName(
      'pages/create_process_task_group/create_process_task_group'
    ),
    href: '#create_process_task_group',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'edit_process_configurations',
    name: 'edit_process_configurations',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configurations/edit_process_configurations'
    ),
    href: '#edit_process_configurations',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'edit_process_configuration_steps/:process_configuration_id',
    name: 'edit_process_configuration_steps',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configuration_steps/edit_process_configuration_steps'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route:
      'edit_process_configuration_step_positions/:process_configuration_step_id',
    name: 'edit_process_configuration_step_positions',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configuration_step_positions/edit_process_configuration_step_positions'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'edit_process_configuration_devices/:process_configuration_id',
    name: 'edit_process_configuration_devices',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configuration_devices/edit_process_configuration_devices'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route:
      'edit_process_configuration_device_exports/:process_configuration_id',
    name: 'edit_process_configuration_device_exports',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configuration_device_exports/edit_process_configuration_device_exports'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'edit_process_configuration_forms/:process_configuration_id',
    name: 'edit_process_configuration_forms',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_configuration_forms/edit_process_configuration_forms'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'edit_process_task_groups',
    name: 'edit_process_task_groups',
    moduleId: PLATFORM.moduleName(
      'pages/edit_process_task_groups/edit_process_task_groups'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    /*
      optional get/search parameters:
        process_task_appointment_id - automatically focus it
        process_task_offer_id - automatically focus it
        process_task_invoice_id - automatically focus it
    */
    route: 'edit_process_task/:process_task_id',
    name: 'edit_process_task',
    moduleId: PLATFORM.moduleName('pages/edit_process_task/edit_process_task'),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations',
      disableEntityAvailableCheck: true
    }
  },
  {
    route: 'show_user_calendar',
    name: 'show_user_calendar',
    moduleId: PLATFORM.moduleName(
      'pages/show_user_calendar/show_user_calendar'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperationsFieldUse'
    }
  },
  {
    route: 'show_operations_calendar',
    name: 'show_operations_calendar',
    moduleId: PLATFORM.moduleName(
      'pages/show_operations_calendar/show_operations_calendar'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperations'
    }
  },
  {
    route: 'show_process_appointment/:appointment_id',
    name: 'show_process_appointment',
    moduleId: PLATFORM.moduleName(
      'pages/show_process_appointment/show_process_appointment'
    ),
    nav: false,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.OPERATIONS,
      permissionName: 'canUseOperationsFieldUse'
    }
  },
  {
    route: 'edit_checklist_object/:thing_id',
    name: 'edit_checklist_object',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_thing/edit_checklist_thing'
    ),
    href: '#edit_checklist_object',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canUseChecklist'
    }
  },
  {
    route: 'edit_checklist_project/:project_id/:entry_id?',
    name: 'edit_checklist_project',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_project/edit_checklist_project'
    ),
    href: '#edit_checklist_project',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canUseChecklist'
    }
  },
  {
    route: 'edit_checklist_question_catalogues',
    name: 'edit_checklist_question_catalogues',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_question_catalogues/edit_checklist_question_catalogues'
    ),
    href: '#edit_checklist_question_catalogues',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canManageChecklistQuestions'
    }
  },
  {
    route: 'edit_checklist_question_sets/:question_catalogue_id?',
    name: 'edit_checklist_question_sets',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_question_sets/edit_checklist_question_sets'
    ),
    href: '#edit_checklist_question_sets',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canManageChecklistQuestions'
    }
  },
  {
    route: 'edit_checklist_questions/:question_set_id',
    name: 'edit_checklist_questions',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_questions/edit_checklist_questions'
    ),
    href: '#edit_checklist_questions',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canManageChecklistQuestions'
    }
  },
  {
    route: 'edit_checklist_question_categories',
    name: 'edit_checklist_question_categories',
    moduleId: PLATFORM.moduleName(
      'pages/edit_checklist_question_categories/edit_checklist_question_categories'
    ),
    href: '#edit_checklist_question_categories',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'no-breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canManageChecklistQuestions'
    }
  },
  {
    route:
      'checklist_inspection/:project_id/:project_category_id/:project_question_id',
    name: 'checklist_inspection_question',
    moduleId: PLATFORM.moduleName(
      'pages/checklist_inspection_question/checklist_inspection_question'
    ),
    href: '#checklist_inspection_question',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canUseChecklist'
    }
  },
  {
    route: 'checklist_inspection/:project_id/:project_category_id',
    name: 'checklist_inspection_category',
    moduleId: PLATFORM.moduleName(
      'pages/checklist_inspection_category/checklist_inspection_category'
    ),
    href: '#checklist_inspection_category',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canUseChecklist'
    }
  },
  {
    route: 'checklist_inspection/:project_id',
    name: 'checklist_inspection',
    moduleId: PLATFORM.moduleName(
      'pages/checklist_inspection/checklist_inspection'
    ),
    href: '#checklist_inspection',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.CHECKLIST,
      permissionName: 'canUseChecklist'
    }
  },
  {
    route: 'catastrophe/:thing_id',
    name: 'edit_catastrophe',
    moduleId: PLATFORM.moduleName('pages/edit_catastrophe/edit_catastrophe'),
    href: '#edit_catastrophe',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.KUK,
      permissionName: 'canUseKuK'
    }
  },
  {
    route: 'defect/:defect_id',
    name: 'edit_defect',
    moduleId: PLATFORM.moduleName('pages/edit_defect/edit_defect'),
    href: '#edit_defect',
    nav: true,
    auth: true,
    admin: false,
    settings: {
      headerStyle: 'breadcrumb',
      recordItModuleName: ModuleName.VIA,
      permissionName: 'canUseDefectManagement'
    }
  }
];

export function getRoutes(): Array<RouteConfig> {
  const routesWithTitle: Array<RouteConfig> = routes.map((r) => ({
    ...r,
    title: 'routes.' + r.name // this is a translation key
  }));
  return routesWithTitle;
}

/**
 * these are just the currently used properties
 * for a list of full features/properties consult the aurelia docs (https://aurelia.io/docs/routing/configuration)
 */
type InternalRouteConfig = {
  route: string | Array<string>;
  name: string;
  moduleId: string;
  href?: string;
  nav: boolean;
  auth: boolean;
  admin: boolean;
  settings: RouteConfigSettings;
};

export type RouteConfig = InternalRouteConfig & { title: string };

type RouteConfigSettings = {
  /** possible values 'no-breadcrumb', 'breadcrumb' */
  headerStyle: string;

  /** Use the module name definitions of the `RecordItModuleHelper`, is used for e.g. special stylings for the page (e.g. the module name display in the header) */
  recordItModuleName: ModuleName;

  /** name of the user permission which is required to access this route */
  permissionName?: string;

  /** normally the entity ids in the route params will be checked for availability, you can disable this for routes which load the entities on the fly */
  disableEntityAvailableCheck?: boolean;
};
