import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../AppEntityManager';
import { Defect } from './types';
import {
  EditDefectDialog,
  EditDefectDialogOpenOptions
} from '../../../../dialogs/edit-defect-dialog/edit-defect-dialog';
import { DefectEntityDashboardInfo } from '../EntityDashboardInfo/types';
import { CurrentUserService } from '../User/CurrentUserService';

@autoinject()
export class DefectActionService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  public openEditDialog(options: EditDefectDialogOpenOptions): Promise<void> {
    return EditDefectDialog.open(options);
  }

  public toggleDeletedStatus(defect: Defect): void {
    defect.deleted = !defect.deleted;
    this.entityManager.defectRepository.update(defect);
  }

  public toggleStickyStatus(defect: Defect): void {
    const metadata = this.getOrCreateMetadata(defect);
    metadata.sticky = !metadata.sticky;
    this.entityManager.entityDashboardInfoRepository.update(metadata);
  }

  private getOrCreateMetadata(defect: Defect): DefectEntityDashboardInfo {
    const metadata =
      this.entityManager.entityDashboardInfoRepository.getByDefectId(
        defect.id
      )[0];
    if (metadata) return metadata;

    const newData = this.entityManager.entityDashboardInfoRepository.create({
      defectId: defect.id,
      userId: this.currentUserService.getRequiredCurrentUser().id
    }) as DefectEntityDashboardInfo;
    return newData;
  }
}
