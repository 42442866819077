import { bindable } from 'aurelia-framework';
import { UserGroupSubEntity } from '../../classes/EntityManager/entities/UserGroupSubEntityUtils';

export class UserGroupFilter<T extends UserGroupSubEntity> {
  @bindable public entities: Array<T> = [];

  @bindable public userGroupId: string | null = null;

  /** readonly */
  @bindable public filteredEntities: Array<T> = [];

  @bindable public doNotAllowNullValue = false;

  // ********** Aurelia Change Handlers **********

  protected entitiesChanged(): void {
    this.updateFilteredEntities();
  }

  protected filterPropertyChanged(): void {
    this.updateFilteredEntities();
  }

  protected userGroupIdChanged(): void {
    this.updateFilteredEntities();
  }

  // ********** Private Methods **********

  private updateFilteredEntities(): void {
    this.filteredEntities = this.entities.filter((e) => {
      return !this.userGroupId || e.ownerUserGroupId === this.userGroupId;
    });
  }
}
