import _ from 'lodash';
import { Router } from 'aurelia-router';

import { SocketIoZipClientPlugin } from 'common/SocketIoZipPlugin/SocketIoZipClientPlugin';
import {
  ImportDataDumpResponse,
  ImportDataDumpRequest,
  ImportProjectPictureFolderRequest,
  ImportProjectPictureFolderResponse
} from 'common/EndpointTypes/DataRescueEndpointsHandler';

import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { LocalProjectFilesManager } from './LocalProjectFilesManager';
import { CoordinateHelper } from './CoordinateHelper';
import { Dialogs } from './Dialogs';
import { FileUtils } from './Utils/FileUtils/FileUtils';
import { NFCHelper } from './Nfc/NFCHelper';
import { DataStorageHelper } from './DataStorageHelper/DataStorageHelper';
import { TSocketServiceCallbackFunction } from '../services/SocketService';
import { ClientDataPatcher } from './ClientDataPatcher';

const defaultValues = {
  debug: false,
  appRouter: null,
  enableSocketLogging: () => {
    SocketIoZipClientPlugin.enableDebugLogging();
  },
  disableSocketLogging: () => {
    SocketIoZipClientPlugin.disableDebugLogging();
  }
};

/**
 * an Object which is accessible outside of the app
 * the Object can already be available before this module is needed to pre-define default values from outside
 *
 */
export let TestingHelper: TTestingHelper;

// @ts-ignore
if (window.TestingHelper) {
  // since we want to preserve the global instance, we generate our config in a new Object,
  // and overwrite the existing instance with the new values then
  // @ts-ignore
  const values = _.assign({}, defaultValues, window.TestingHelper);
  // @ts-ignore
  TestingHelper = _.assign(window.TestingHelper, values);
} else {
  TestingHelper = defaultValues as TTestingHelper;
}

// @ts-ignore
window.TestingHelper = TestingHelper;

type TTestingHelper = {
  debug: boolean;
  appRouter?: Router | null;
  entityManager: AppEntityManager;
  localProjectFilesManager?: LocalProjectFilesManager;
  CoordinateHelper: CoordinateHelper;
  Dialogs: Dialogs;
  FileUtils: FileUtils;
  NFCHelper: NFCHelper;
  DataStorageHelper: DataStorageHelper;
  enableSocketLogging: () => void;
  disableSocketLogging: () => void;
  importDataDump: (
    userId: string,
    email: string,
    timestamp: number,
    callback?: TSocketServiceCallbackFunction<
      ImportDataDumpResponse,
      ImportDataDumpRequest
    >
  ) => void;
  importProjectPictureFolder: (
    projectId: string,
    folderName?: string,
    callback?: TSocketServiceCallbackFunction<
      ImportProjectPictureFolderResponse,
      ImportProjectPictureFolderRequest
    >
  ) => void;
  clientDataPatcher: ClientDataPatcher;
};
