import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskOfferToProcessTaskDto } from './ProcessTaskOfferToProcessTaskDto';

export function createProcessTaskOfferToProcessTaskEntityInfo<
  TEntity extends ProcessTaskOfferToProcessTaskDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskOfferToProcessTaskDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskOfferToProcessTask,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskOfferToProcessTask,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskOfferToProcessTaskDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskOfferId: {
          name: 'processTaskOfferId',
          ref: EntityName.ProcessTaskOffer,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
