import { Count } from '../ListItemRelatedEntityCountsAdapter';

export class ListItemRelatedEntityCountsUtils {
  private constructor() {}

  public static createAppointmentCount(value: number): Count {
    return {
      tk: 'operationsComponents.listItemRelatedEntityCounts.adapter.ListItemRelatedEntityCountsUtils.appointmentCount',
      value
    };
  }

  public static createInvoiceCount(value: number): Count {
    return {
      tk: 'operationsComponents.listItemRelatedEntityCounts.adapter.ListItemRelatedEntityCountsUtils.invoiceCount',
      value
    };
  }

  public static createOfferCount(value: number): Count {
    return {
      tk: 'operationsComponents.listItemRelatedEntityCounts.adapter.ListItemRelatedEntityCountsUtils.offerCount',
      value
    };
  }
}
