import { inject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskMeasurePointReadingCreationService } from '../../services/ProcessTaskMeasurePointReadingCreationService';

import { ProcessTaskMeasurePointMarkingDialog } from '../process-task-measure-point-marking-dialog/process-task-measure-point-marking-dialog';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EditProcessTaskMeasurePointReadingDialog } from '../edit-process-task-measure-point-reading-dialog/edit-process-task-measure-point-reading-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event {TMeasurePointCreatedEvent} measure-point-created
 */
@inject(
  Element,
  AppEntityManager,
  SubscriptionManagerService,
  ProcessTaskMeasurePointReadingCreationService
)
export class ProcessTaskMeasurePointCreationButtons {
  static TEMPORARY_GROUP_NAME = 'ProcessTaskMeasurePointCreationButtons';

  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment|null} */
  @bindable processTaskAppointment = null;

  /**
   * picture where to mark the measure point on
   *
   * @type {import('../../classes/EntityManager/entities/Picture/types').Picture|null}
   */
  @bindable picture = null;

  /** @type {boolean} */
  _attached = false;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType>} */
  _measurePointTypes = [];

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {ProcessTaskMeasurePointReadingCreationService} processTaskMeasurePointReadingCreationService
   */
  constructor(
    element,
    entityManager,
    subscriptionManagerService,
    processTaskMeasurePointReadingCreationService
  ) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._processTaskMeasurePointReadingCreationService =
      processTaskMeasurePointReadingCreationService;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationMeasurePointType,
      this._updateMeasurePointTypes.bind(this)
    );
    this._updateMeasurePointTypes();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  _updateMeasurePointTypes() {
    if (this.processTaskGroup) {
      this._measurePointTypes =
        this._entityManager.processConfigurationMeasurePointTypeRepository.getByProcessConfigurationId(
          this.processTaskGroup.processConfigurationId
        );
    } else {
      this._measurePointTypes = [];
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType} measurePointType
   */
  _handleCreateButtonClick(measurePointType) {
    if (
      !this.processTask ||
      !this.processTaskAppointment ||
      !this.picture ||
      !this.processTaskGroup
    ) {
      return;
    }

    const measurePoint =
      this._entityManager.processTaskMeasurePointRepository.create({
        processConfigurationMeasurePointTypeId: measurePointType.id,
        ownerProcessTaskId: this.processTask.id,
        ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
        ownerUserGroupId: this.processTask.ownerUserGroupId,
        temporaryGroupName:
          ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME,
        shadowEntity: true
      });

    ProcessTaskMeasurePointMarkingDialog.open({
      measurePoint: measurePoint,
      picture: this.picture,
      processTaskGroup: this.processTaskGroup,
      onDialogClosed: (marked) => {
        this._handleMarkingDialogClosed(measurePoint, marked, measurePointType);
      }
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {boolean} marked
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType} measurePointType
   */
  _handleMarkingDialogClosed(measurePoint, marked, measurePointType) {
    if (!marked || !this.processTask || !this.processTaskAppointment) {
      this._entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
        ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME
      );
      return;
    }

    this._entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      ProcessTaskMeasurePointCreationButtons.TEMPORARY_GROUP_NAME,
      this.processTask.temporaryGroupName
    );

    const measurePointReading =
      this._processTaskMeasurePointReadingCreationService.createReadingWithProperties(
        measurePoint,
        this.processTaskAppointment
      );

    this._handleMeasurePointCreated(
      measurePointType,
      measurePoint,
      measurePointReading
    );

    this._fireCreatedEvent(measurePoint, measurePointReading);
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType} measurePointType
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} measurePointReading
   */
  _handleMeasurePointCreated(
    measurePointType,
    measurePoint,
    measurePointReading
  ) {
    if (this.processTaskGroup) {
      EditProcessTaskMeasurePointReadingDialog.open({
        processTaskGroup: this.processTaskGroup,
        measurePoint: measurePoint,
        measurePointReading: measurePointReading,
        onDialogClosed: () => {
          this._handleCreateButtonClick(measurePointType);
        }
      });
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType} measurePointType
   * @returns {Object}
   */
  _getButtonStyle(measurePointType) {
    const style = {};

    if (measurePointType.color) {
      style['background-color'] = measurePointType.color;
    }

    return style;
  }

  /**
   *
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} measurePointReading
   */
  _fireCreatedEvent(measurePoint, measurePointReading) {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'measure-point-created',
      detail: {
        measurePoint,
        measurePointReading
      }
    });
  }
}

/**
 * @typedef {Object} TMeasurePointCreatedEventDetail
 * @property {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
 * @property {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} measurePointReading
 */

/**
 * @typedef {Event & { detail: TMeasurePointCreatedEventDetail }} TMeasurePointCreatedEvent
 */
