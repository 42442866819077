import { autoinject } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';

/**
 * exposes the life cycle of an element as callbacks
 */
@autoinject()
export class LifeCycleHooksCustomAttribute {
  protected element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  @bindable()
  public onAttached:
    | (({ $element }: { $element: HTMLElement }) => void)
    | null
    | undefined = null;

  @bindable()
  public onDetached:
    | (({ $element }: { $element: HTMLElement }) => void)
    | null
    | undefined = null;

  protected attached(): void {
    this.onAttached?.({ $element: this.element });
  }

  protected detached(): void {
    this.onDetached?.({ $element: this.element });
  }
}
