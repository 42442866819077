import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { Router, NavigationInstruction } from 'aurelia-router';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { SocketService } from '../../services/SocketService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

import { Dialogs } from '../../classes/Dialogs';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { GlobalData } from '../../classes/GlobalData';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { FilterHelper } from '../../classes/FilterHelper';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { EditThingDialog } from '../../dialogs/edit-thing-dialog/edit-thing-dialog';
import {
  ImportFromCsvFileDialog,
  CallbackParamsWithUserGroup
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { ExportThingsAsCsvDialog } from '../../dialogs/export-things-as-csv-dialog/export-things-as-csv-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData,
  ValidateCallbackResult
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import {
  ActionClickedEvent,
  SelectableItemList
} from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ThingExportService } from '../../classes/EntityManager/entities/Thing/ThingExportService';
import {
  Thing,
  ThingCreationEntity
} from '../../classes/EntityManager/entities/Thing/types';
import { ThingUtils } from '../../classes/EntityManager/entities/Thing/ThingUtils';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ThingListItem } from '../../listItems/thing-list-item/thing-list-item';
import { FilterMode } from '../../filterComponents/archive-filter/archive-filter';
import { FileDownloadService } from '../../services/FileDownloadService';
import {
  OpenThingOptions,
  ThingActionService
} from '../../classes/EntityManager/entities/Thing/ThingActionService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { ThingCreationService } from '../../classes/EntityManager/entities/Thing/ThingCreationService';

@autoinject()
export class edit_things {
  @observable public projectType: ProjectType | null;

  @observable protected isMobile: boolean;

  protected editableUserGroups: Array<UserGroup> = [];

  private availableThings: Array<Thing> = [];
  protected searchFilteredThings: Array<Thing> = [];
  protected thingTypeFilteredThings: Array<Thing> = [];
  protected thingGroupFilteredThings: Array<Thing> = [];
  protected archiveFilteredThings: Array<Thing> = [];
  protected filteredThings: Array<Thing> = [];
  protected sortedThings: Array<Thing> = [];

  @observable protected thingFilterString: string;

  protected sortOptions = ThingUtils.sortOptions;
  protected currentSortOption = ThingUtils.sortOptions.name;
  protected currentUser: User | null = null;

  private isAttached = false;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editThings.importThingsFromCsvFile',
      name: 'import-things-from-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'importThingsFromCsvDisabled',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    },
    {
      labelTk: 'generalPages.editThings.exportThingsToCsvFile',
      name: 'export-things-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'exportThingsToCsvDisabled'
    },
    {
      labelTk: 'generalPages.editThings.exportThingsWithContentToCsvFile',
      name: 'export-things-with-content-to-csv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'exportThingsWithContentToCsvDisabled'
    }
  ];

  private csvImportFields: FieldInfosFromConfig<FieldInfoConfiguration> = [
    {
      field: 'thingGroupName',
      header: 'Objektgruppe',
      type: FieldType.STRING,
      extraField: true,
      validateCallback: this.validateThingGroupColumn.bind(this),
      validationRequired: true
    },
    { field: 'customId', header: 'ID', type: FieldType.STRING, required: true },
    { field: 'name', header: 'Name', type: FieldType.STRING, required: true },
    { field: 'description', header: 'Beschreibung', type: FieldType.STRING },
    { field: 'longitude', header: 'Länge', type: FieldType.NUMBER },
    { field: 'latitude', header: 'Breite', type: FieldType.NUMBER }
  ];

  protected isOnline = false;

  private predefinedRegionsConfiguration: IPredefinedRegionsConfiguration | null =
    null;

  @observable protected filterThingGroupId: string | null;

  @observable protected filterPersonId: string | null;

  @observable protected archiveFilterMode: FilterMode;

  protected selectedThings: Array<Thing> = [];

  protected thingSelectionMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'general.delete',
      name: 'delete-things',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'cannotDeleteSelectedThings'
    },
    {
      labelTk: 'general.archive',
      name: 'archive-things',
      iconClass: 'fal fa-archive',
      disabledContext: this,
      disabledPropertyName: 'cannotArchiveSelectedThings'
    },
    {
      labelTk: 'general.unarchive',
      name: 'unarchive-things',
      iconClass: 'fal fa-house',
      disabledContext: this,
      disabledPropertyName: 'cannotUnarchiveSelectedThings'
    }
  ];

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  protected selectableItemList: SelectableItemList<
    Thing,
    ThingListItem
  > | null = null;

  protected readonly EntityName = EntityName;

  @subscribableLifecycle()
  private userGroupOfFilterThingGroupIdPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly router: Router,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    private readonly thingExportService: ThingExportService,
    private readonly thingActionService: ThingActionService,
    private readonly thingCreationService: ThingCreationService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      editableUserGroupsPropertyName: 'editableUserGroups',
      currentUserPropertyName: 'currentUser'
    });

    this.userGroupOfFilterThingGroupIdPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.UserGroup,
        context: this,
        expression: 'userGroupOfFilterThingGroupId'
      });

    this.projectType = null;
    this.isMobile = false;
    this.thingFilterString = '';
    this.filterThingGroupId = null;
    this.filterPersonId = null;
    this.archiveFilterMode = FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED;
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateAvailableThings.bind(this)
    );
    this.updateAvailableThings();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-things-to-csv':
          this.handleExportThingsAsCsvFileClick.bind(this),
        'export-things-with-content-to-csv':
          this.handleExportThingsWithContentAsCsvFileClick.bind(this)
      },
      fileChangedCallbacks: {
        'import-things-from-csv':
          this.handleImportThingsFromCsvFileChanged.bind(this)
      }
    });

    this.getFilterIdsFromCurrentInstruction(this.router.currentInstruction);
    this.subscriptionManager.subscribeToPropertyChange(
      this.router,
      'currentInstruction',
      (
        currentInstruction: NavigationInstruction,
        previousInstruction: NavigationInstruction
      ) => {
        if (currentInstruction.config.name === previousInstruction.config.name)
          this.getFilterIdsFromCurrentInstruction(currentInstruction);
      }
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.predefinedRegionsConfiguration',
        (predefinedRegionsConfiguration) => {
          this.predefinedRegionsConfiguration = predefinedRegionsConfiguration;
        }
      )
    );

    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.isAttached = false;

    GlobalMenu.releaseControl(this);
    this.subscriptionManager.disposeSubscriptions();

    this.permissionBindingHandle.unsubscribe();
  }

  private get cannotDeleteSelectedThings(): boolean {
    const currentUser = this.currentUser;
    return (
      !currentUser ||
      this.selectedThings.some(
        (t) => !this.thingIsEditable(t, currentUser, this.editableUserGroups)
      )
    );
  }

  private get cannotArchiveSelectedThings(): boolean {
    const currentUser = this.currentUser;
    return (
      !currentUser ||
      this.selectedThings.some(
        (t) =>
          !this.thingIsEditable(t, currentUser, this.editableUserGroups) ||
          t.archived
      )
    );
  }

  private get cannotUnarchiveSelectedThings(): boolean {
    const currentUser = this.currentUser;
    return (
      !currentUser ||
      this.selectedThings.some(
        (t) =>
          !this.thingIsEditable(t, currentUser, this.editableUserGroups) ||
          !t.archived
      )
    );
  }

  private getFilterIdsFromCurrentInstruction(
    currentInstruction: NavigationInstruction
  ): void {
    this.projectType = currentInstruction.queryParams['projectType'] || null;
    this.filterThingGroupId =
      currentInstruction.queryParams['thingGroupId'] || null;
    this.filterPersonId = currentInstruction.queryParams['personId'] || null;

    const archiveFilterMode =
      currentInstruction.queryParams['archiveFilterMode'];
    this.archiveFilterMode = Object.values(FilterMode).includes(
      archiveFilterMode
    )
      ? archiveFilterMode
      : FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED;
  }

  private updateAvailableThings(): void {
    this.availableThings = this.entityManager.thingRepository.getAll();
    this.updateSearchFilteredThings();
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) {
      GlobalMenu.setVisible(this, this.isMobile);
    }
  }

  protected projectTypeChanged(): void {
    if (this.isAttached) this.saveFilterIdsInRouteHistory();
  }

  protected filterThingGroupIdChanged(): void {
    if (this.isAttached) this.saveFilterIdsInRouteHistory();
  }

  protected filterPersonIdChanged(): void {
    if (this.isAttached) this.saveFilterIdsInRouteHistory();
  }

  protected archiveFilterModeChanged(): void {
    if (this.isAttached) this.saveFilterIdsInRouteHistory();
  }

  private saveFilterIdsInRouteHistory(): void {
    if (!this.router.currentInstruction.config.name) {
      throw new Error(
        'Current route has no name: ' +
          this.router.currentInstruction.getBaseUrl()
      );
    }

    const newParams = {
      ...this.router.currentInstruction.params,
      ...this.router.currentInstruction.queryParams,
      projectType: this.projectType,
      thingGroupId: this.filterThingGroupId,
      personId: this.filterPersonId,
      archiveFilterMode: this.archiveFilterMode
    };
    this.router.navigateToRoute(
      this.router.currentInstruction.config.name,
      newParams,
      { trigger: true, replace: false }
    );
  }

  @computedFrom('isOnline', 'editableUserGroups')
  private get importThingsFromCsvDisabled(): boolean {
    return (
      !this.isOnline ||
      !this.editableUserGroups ||
      this.editableUserGroups.length === 0
    );
  }

  @computedFrom('isOnline')
  private get exportThingsToCsvDisabled(): boolean {
    return !this.isOnline;
  }

  @computedFrom('isOnline', 'selectedThings.length')
  private get exportThingsWithContentToCsvDisabled(): boolean {
    return !this.isOnline || this.selectedThings.length === 0;
  }

  // ++++++++++++ VIEW MODEL ++++++++++++

  protected thingIsEditable(
    thing: Thing,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return thing && user && editableUserGroups
      ? PermissionHelper.userCanEditOwnerUserGroupIdEntity(
          thing,
          user,
          editableUserGroups
        )
      : false;
  }

  protected async handleImportThingsFromCsvFileChanged(
    event: Event
  ): Promise<void> {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files[0] && this.isOnline) {
      await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
        file: inputElement.files[0],
        editableUserGroups: this.editableUserGroups,
        fields: this.csvImportFields,
        importFromCsvFileCallback: async (detail) => {
          try {
            this.handleImportThingsFromCsvFileSubmitted(detail);
          } catch (error) {
            void Dialogs.errorDialog(
              'Fehler beim CSV Import!',
              error && error.message ? error.message : ''
            );
          }
          Dialogs.timedSuccessDialog('CSV Import erfolgreich!');
        },
        showUserGroupSelect: true,
        showAdditionalFieldsAsParametersCheckbox: true,
        showOverwriteCheckbox: true,
        entityNameTk: 'models.ThingModel_plural'
      });
      inputElement.value = '';
    }
  }

  private handleImportThingsFromCsvFileSubmitted(
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): void {
    const lineData = detail.parsedContent;

    const thingGroups =
      this.entityManager.thingGroupRepository.getByUserGroupId(
        detail.userGroupId
      );

    for (const lD of lineData) {
      const thingGroup =
        thingGroups.find((tG) => tG.name === lD.fields.thingGroupName) || null;
      if (lD.fields.thingGroupName && !thingGroup) {
        console.warn('THING GROUP NOT FOUND!', lD.fields.thingGroupName);
        continue;
      }
      this.createOrUpdateThing(lD, thingGroup?.id || null, detail);
    }
  }

  private createOrUpdateThing(
    lD: ParsedLineData<FieldInfoConfiguration>,
    thingGroupId: string | null,
    detail: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): void {
    const existingThing = lD.fields.customId
      ? this.entityManager.thingRepository
          .getByUserGroupId(detail.userGroupId)
          .find((t) => {
            return t.customId === lD.fields.customId;
          })
      : null;

    const newThingCreationEntity: ThingCreationEntity = {
      ownerUserGroupId: detail.userGroupId,
      usergroup: detail.userGroupId,
      thingGroupId: thingGroupId
    };

    this.applyValuesToNewThing(newThingCreationEntity, lD);

    let thing;
    if (detail.overwrite && existingThing) {
      Object.assign(existingThing, newThingCreationEntity);
      this.entityManager.thingRepository.update(existingThing);
      thing = existingThing;
    } else {
      thing = this.thingCreationService.create(newThingCreationEntity);
    }

    if (detail.fieldsAsProperties)
      this.createOrUpdateThingProperties(lD.additionalFields, thing);
  }

  private applyValuesToNewThing(
    newThing: ThingCreationEntity,
    lineData: ParsedLineData<FieldInfoConfiguration>
  ): void {
    for (const fieldKey in lineData.fields) {
      const fieldInfo = this.csvImportFields.find(
        (fInfo) => fInfo.field === fieldKey
      );
      if (!fieldInfo || fieldInfo.extraField) continue;

      newThing[fieldInfo.field] = lineData.fields[fieldKey];
    }
  }

  private createOrUpdateThingProperties(
    additionalFields: Record<string, string>,
    thing: Thing
  ): void {
    const properties = this.entityManager.propertyRepository.getByThingId(
      thing.id
    );
    for (const additionalField in additionalFields) {
      const existingProperty =
        properties.find((prop) => prop.name === additionalField) || null;
      if (existingProperty) {
        existingProperty.value = additionalFields[additionalField];
        this.entityManager.propertyRepository.update(existingProperty);
      } else {
        this.entityManager.propertyRepository.create({
          ownerUserGroupId: thing.ownerUserGroupId,
          thing: thing.id,
          name: additionalField,
          value: additionalFields[additionalField]
        });
      }
    }
  }

  private validateThingGroupColumn(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    const thingGroupNames = this.entityManager.thingGroupRepository
      .getAll()
      .map((g) => g.name);

    const valid = parsedData.every(
      (data) =>
        !data.fields.thingGroupName ||
        thingGroupNames.includes(data.fields.thingGroupName)
    );

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'generalPages.editThings.invalidThingGroupNames'
    };
  }

  protected handleExportThingsAsCsvFileClick(): void {
    const thingIds = this.sortedThings.map((thing) => {
      return thing.id;
    });
    Dialogs.waitDialog('Objekte werden exportiert...');
    this.thingExportService.exportThingsAsCsvFile(thingIds, (response) => {
      if (response.success) {
        Dialogs.closeAllDialogs();
        void this.fileDownloadService.downloadFile(
          response.filePath.replace(/\\/g, '/')
        );
      } else {
        void Dialogs.errorDialog(
          'Fehler beim CSV Export!',
          response.error && response.error.message ? response.error.message : ''
        );
      }
    });
  }

  protected handleExportThingsWithContentAsCsvFileClick(): void {
    const thingIds = this.selectedThings.map((thing) => thing.id);
    void ExportThingsAsCsvDialog.open({
      thingIds: thingIds
    });
  }

  protected handleCreateThingClick(userGroup: UserGroup): void {
    const thingGroup = this.filterThingGroupId
      ? this.entityManager.thingGroupRepository.getById(this.filterThingGroupId)
      : null;
    const userGroupId = thingGroup ? thingGroup.ownerUserGroupId : userGroup.id;

    const thing = this.thingCreationService.create({
      usergroup: userGroupId,
      ownerUserGroupId: userGroupId,
      thingGroupId: this.filterThingGroupId
    });

    if (this.predefinedRegionsConfiguration)
      this.createPredefinedRegions(thing);

    this.updateAvailableThings();
    this.editThing(thing);
  }

  private createPredefinedRegions(thing: Thing): void {
    if (
      !this.predefinedRegionsConfiguration ||
      !this.predefinedRegionsConfiguration.regions
    )
      return;

    this.predefinedRegionsConfiguration.regions.forEach((regionToCreate) => {
      this.entityManager.regionRepository.create({
        name: regionToCreate.name,
        description: regionToCreate.description || null,
        ownerUserGroupId: thing.ownerUserGroupId,
        thingId: thing.id
      });
    });
  }

  protected navigateToThing(
    thing: Thing,
    additionalRouteParams: OpenThingOptions | null
  ): void {
    this.thingActionService.navigateToThing(
      thing,
      additionalRouteParams,
      this.projectType ?? undefined
    );
  }

  protected handleEditThingClick(thing: Thing): void {
    this.editThing(thing);
  }

  private updateSearchFilteredThings(): void {
    this.searchFilteredThings = FilterHelper.filterItems(
      this.availableThings,
      GlobalData.thingFilterFunction,
      this.thingFilterString
    );
  }

  protected thingFilterStringChanged(): void {
    this.updateSearchFilteredThings();
  }

  private editThing(thing: Thing): void {
    this.thingActionService.editThing(thing, () => {
      this.goToThing(thing);
    });
  }

  private goToThing(thing: Thing): void {
    void this.selectableItemList?.goToItem(
      `#edit-things--thing-${thing.id}`,
      thing
    );
  }

  protected async handleDeleteSelectedThings(
    event: ActionClickedEvent<Thing>
  ): Promise<void> {
    await Dialogs.deleteDialogTk();

    event.detail.selectedItems.forEach((t) =>
      this.entityManager.thingRepository.delete(t)
    );
  }

  protected async handleArchiveSelectedThings(
    event: ActionClickedEvent<Thing>,
    archive: boolean
  ): Promise<void> {
    await Dialogs.warningDialog();

    event.detail.selectedItems.forEach((t) => {
      t.archived = archive;
      this.entityManager.thingRepository.update(t);
    });
  }

  @computed(
    expression('filterThingGroupId'),
    expression('userGroupOfFilterThingGroupIdPermissionsHandle.canCreateThings')
  )
  protected get canCreateThingsWithCreateEntityButton(): boolean {
    return (
      !this.filterThingGroupId ||
      this.userGroupOfFilterThingGroupIdPermissionsHandle.canCreateThings
    );
  }

  @computed(expression('filterThingGroupId'), model(EntityName.UserGroup))
  protected get userGroupOfFilterThingGroupId(): UserGroup | null {
    const thingGroup = this.filterThingGroupId
      ? this.entityManager.thingGroupRepository.getById(this.filterThingGroupId)
      : null;

    return thingGroup
      ? this.entityManager.userGroupRepository.getById(
          thingGroup.ownerUserGroupId
        )
      : null;
  }
}

interface IPredefinedRegionsConfiguration {
  regions?: Array<IPredefinedRegion> | null;
}

interface IPredefinedRegion {
  name: string;
  description?: string | null;
}

type FieldInfoConfiguration = {
  entityType: Thing;
  fieldInfos: [
    {
      field: 'thingGroupName';
      type: FieldType.STRING;
    },
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'description';
      type: FieldType.STRING;
    },
    {
      field: 'longitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'latitude';
      type: FieldType.NUMBER;
    }
  ];
};
