import { Container } from 'aurelia-framework';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';
import { setupMount, setupUnmount } from './configureHooks';
import { Dependency } from './dependencies';
import { getHookStateProp, HookState, HookStateProp } from './utils';

/**
 * Indicates that the decorated function depends on some dependencies.
 * If any of the given dependencies change, the function will be executed.
 *
 * @example
 * // doSomething() will be executed if a Thing entity updates
 * \@watch(model(EntityName.Thing))
 * protected doSomething() {
 *   [...]
 * }
 */
export function watch(...deps: Array<Dependency>) {
  return function (target: any, prop: string, descriptor: PropertyDescriptor) {
    if (!descriptor.value) {
      throw new Error('@watch only supports functions, no getters or setters.');
    }

    const stateProp = getHookStateProp(prop);

    /*
     * We initialize our dependencies when mounting,
     * and initialize the state
     */
    setupMount(target, function (this: This<typeof prop>) {
      const subscriptionManager = Container.instance
        .get(SubscriptionManagerService)
        .create();

      this[stateProp] = { subscriptionManager };
      // Initialize the dependencies
      for (const dependency of deps) {
        dependency({
          target: this,
          subscriptionManager,
          onChange: () => descriptor.value.apply(this)
        });
      }
    });

    /*
     * The subscriptions need to be destroyed when unmounting
     */
    setupUnmount(target, function (this: This<typeof prop>) {
      const subscriptionManager = this[stateProp]?.subscriptionManager;
      // Destroy all subscriptions
      subscriptionManager?.disposeSubscriptions();
    });

    return descriptor;
  };
}

type This<T extends string> = {
  [hookStateProp in `${HookStateProp<T>}`]: HookState | undefined;
};
