import { autoinject } from 'aurelia-framework';

import { DialogIconType } from 'common/Enums/DialogIconType';

import {
  ButtonType,
  GlobalCustomDialog,
  GlobalCustomDialogReturnObject
} from '../../../../dialogs/global-custom-dialog/global-custom-dialog';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { Entry } from './types';

@autoinject()
export class EntryDeletionService {
  private static translationKeyPrefix =
    'classes.EntityManager.entities.Entry.EntryDeletionService';

  constructor(private readonly entityManager: AppEntityManager) {}

  public async deleteEntryWithDialog(entry: Entry): Promise<boolean> {
    const subEntryCount = this.entityManager.entryRepository.getByParentId(
      entry.ownerProjectId,
      entry.id,
      entry.group_name
    ).length;

    const deletionFunction = await this.getDeletionFunction(
      subEntryCount,
      entry.name
    );

    if (deletionFunction) {
      deletionFunction(entry);
      return true;
    }

    return false;
  }

  public async deleteEntriesWithDialog(
    entries: Array<Entry>
  ): Promise<boolean> {
    const subEntryCount = entries.reduce((count, entry) => {
      count += this.entityManager.entryRepository.getByParentId(
        entry.ownerProjectId,
        entry.id,
        entry.group_name
      ).length;
      return count;
    }, 0);

    const deletionFunction = await this.getDeletionFunction(subEntryCount);

    if (deletionFunction) {
      entries.forEach((entry) => {
        deletionFunction(entry);
      });

      return true;
    }

    return false;
  }

  private async getDeletionFunction(
    subEntryCount: number,
    entryName?: string | null
  ): Promise<((entry: Entry) => void) | undefined> {
    try {
      const returnValue = await this.showDeletionConfirmationDialog(
        subEntryCount,
        entryName
      );

      if (returnValue.value === 'deleteSubEntries') {
        return (entry) => {
          this.entityManager.entryRepository.deleteChildrenRecursively(entry);
        };
      }
    } catch (error) {
      if (error instanceof Error && error.message !== 'DialogCanceled')
        throw error;
      return;
    }

    return (entry) => {
      this.entityManager.entryRepository.deleteEntryAndUpdateListPosition(
        entry
      );
    };
  }

  private async showDeletionConfirmationDialog(
    numberOfSubEntries = 0,
    entryName?: string | null
  ): Promise<GlobalCustomDialogReturnObject> {
    if (numberOfSubEntries > 0) {
      return this.showDeletionConfirmationDialogWithSubEntries(
        numberOfSubEntries,
        entryName
      );
    } else {
      return this.showDeletionConfirmationDialogWithoutSubEntries(entryName);
    }
  }

  private async showDeletionConfirmationDialogWithSubEntries(
    numberOfSubEntries: number,
    entryName?: string | null
  ): Promise<GlobalCustomDialogReturnObject> {
    return await GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntityTitle',
      textTk:
        EntryDeletionService.translationKeyPrefix +
        '.deleteEntryWithSubEntriesText',
      textTkParams: {
        entityName: entryName ?? EntityName.Entry,
        subEntries: numberOfSubEntries
      },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk:
            EntryDeletionService.translationKeyPrefix + '.alsoDeleteSubEntries',
          textTkParams: {
            subEntries: numberOfSubEntries
          },
          className: 'record-it-button-red',
          value: 'deleteSubEntries'
        },
        {
          textTk:
            EntryDeletionService.translationKeyPrefix + '.deleteOnlyEntry',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  private async showDeletionConfirmationDialogWithoutSubEntries(
    entryName?: string | null
  ): Promise<GlobalCustomDialogReturnObject> {
    return await GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntityTitle',
      textTk: 'classes.Dialogs.deleteEntityText',
      textTkParams: {
        entityName: entryName ?? EntityName.Entry
      },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'classes.Dialogs.deleteEntityButtonText',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }
}
