import { autoinject, bindable } from 'aurelia-framework';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import {
  DefectStatus,
  getAllDefectStatuses
} from '../../../../common/src/Enums/DefectStatus';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { CustomCheckboxCheckedChangedEvent } from '../custom-checkbox/custom-checkbox';

/**
 * Lets the user select one or multiple defect statuses
 *
 * @event statuses-changed {StatusesChangedEvent} triggered when a status gets selected or deselected.
 */
@autoinject()
export class DefectStatusSelect {
  @bindable public enabled = true;

  @bindable public label: string | null = null;

  @bindable public checkedStatuses: Array<DefectStatus> =
    getAllDefectStatuses();

  protected allDefectStatuses = getAllDefectStatuses();

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private addCheckedStatus(status: DefectStatus): void {
    ArrayUtils.pushUnique(this.checkedStatuses, status);
  }

  private removeCheckedStatus(status: DefectStatus): void {
    ArrayUtils.remove(this.checkedStatuses, status);
  }

  private fireStatusesChangedEvent(): void {
    DomEventHelper.fireEvent<StatusesChangedEvent>(this.element, {
      name: 'statuses-changed',
      detail: { checkedStatuses: this.checkedStatuses }
    });
  }

  protected handleCheckedChanged(
    defectStatus: DefectStatus,
    evt: CustomCheckboxCheckedChangedEvent
  ): void {
    if (evt.detail.checked) {
      this.addCheckedStatus(defectStatus);
    } else {
      this.removeCheckedStatus(defectStatus);
    }
    this.fireStatusesChangedEvent();
  }
}

type StatusesChangedEvent = NamedCustomEvent<
  'statuses-changed',
  { checkedStatuses: Array<DefectStatus> }
>;
