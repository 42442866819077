import {
  autoinject,
  bindable,
  observable,
  computedFrom
} from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  Path,
  StructureThingPathSelectedEvent
} from '../structure-thing-path-selector/structure-thing-path-selector';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntryUtils } from '../../classes/EntityManager/entities/StructureTemplateEntry/StructureTemplateEntryUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event move-entry-to-path - MoveEntryToPathEvent
 * @event copy-entry-to-path - CopyEntryToPathEvent
 */
@autoinject()
export class StructureThingMoveOrCopyEntryWidget {
  @bindable public entry: Entry | null = null;

  @bindable public structure: StructureTemplate | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  @subscribableLifecycle()
  private readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private domElement: HTMLElement;

  @observable private selectedPath: Path | null;

  protected parentEntryPath: Array<Entry> | null = null;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as StructureThingMoveOrCopyEntryWidget,
        propertyName: 'entry'
      });

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.Project,
        context: this as StructureThingMoveOrCopyEntryWidget,
        propertyName: 'entry',
        idPropertyName: 'ownerProjectId'
      });

    this.selectedPath = null;
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.entryChanged.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.selectedPath = null;
  }

  protected entryChanged(): void {
    if (!this.entry) {
      this.parentEntryPath = null;
    } else {
      this.parentEntryPath = this.entityManager.entryRepository
        .getPathByEntryId(this.entry.id)
        .reverse()
        .slice(0, -1);
    }
    this.selectedPath = null;
  }

  @computedFrom(
    'entryPermissionsHandle.canEditField.page_depth_parent',
    'projectPermissionsHandle.canCreateEntries'
  )
  protected get canSelectParentEntryPath(): boolean {
    return (
      this.entryPermissionsHandle.canEditField.page_depth_parent ||
      this.projectPermissionsHandle.canCreateEntries
    );
  }

  @computedFrom(
    'validPathIsSelected',
    'entryPermissionsHandle.canEditField.page_depth_parent'
  )
  protected get canMoveEntry(): boolean {
    return (
      this.validPathIsSelected &&
      this.entryPermissionsHandle.canEditField.page_depth_parent
    );
  }

  @computedFrom(
    'validPathIsSelected',
    'projectPermissionsHandle.canCreateEntries'
  )
  protected get canCopyEntry(): boolean {
    return (
      this.validPathIsSelected && this.projectPermissionsHandle.canCreateEntries
    );
  }

  @computedFrom('selectedPath', 'parentEntryPath')
  protected get validPathIsSelected(): boolean {
    const selectedPath = this.selectedPath;

    if (!selectedPath || !this.parentEntryPath) {
      return false;
    }

    return !this.parentEntryPath.every((entry, index) => {
      const structureTemplateEntry =
        selectedPath.structureTemplateEntries[index];
      assertNotNullOrUndefined(
        structureTemplateEntry,
        `selected path has no structure template entry for entry at index ${index}`
      );

      return StructureTemplateEntryUtils.entryDependsOnStructureTemplateEntry(
        structureTemplateEntry,
        entry
      );
    });
  }

  protected handlePathSelected(event: StructureThingPathSelectedEvent): void {
    this.selectedPath = event.detail.path;
  }

  protected handleMoveButtonClicked(): void {
    assertNotNullOrUndefined(
      this.selectedPath,
      'cannot move without destination path'
    );

    DomEventHelper.fireEvent(this.domElement, {
      name: 'move-entry-to-path',
      bubbles: true,
      detail: {
        entry: this.entry,
        path: this.selectedPath
      }
    });

    this.selectedPath = null;
  }

  protected handleCopyButtonClicked(): void {
    assertNotNullOrUndefined(
      this.selectedPath,
      'cannot copy without destination path'
    );

    DomEventHelper.fireEvent(this.domElement, {
      name: 'copy-entry-to-path',
      bubbles: true,
      detail: {
        entry: this.entry,
        path: this.selectedPath
      }
    });

    this.selectedPath = null;
  }
}

export type MoveEntryToPathEvent = CustomEvent<{
  entry: Entry;
  path: Path;
}>;

export type CopyEntryToPathEvent = CustomEvent<{
  entry: Entry;
  path: Path;
}>;
