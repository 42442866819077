import { GstInfoOwner } from '../EndpointTypes/GeoDataEndpointsHandler';
import { FullPerson } from '../Types/Entities/Person/PersonDto';

export class GisParsingHelper {
  public static parseLandOwnerAddress(address: string): GstInfoOwnerAddress {
    const addressSegments = address.split(',');
    const numSegments = addressSegments.length;
    if (numSegments !== 3) {
      return { street: address };
    }
    const street = addressSegments[0]!.trim();
    const country = addressSegments[2]!.trim();
    if (country !== 'Österreich') {
      return { street: address };
    }
    const municipalitySegments = addressSegments[1]!.trim().split(' ');
    let municipalityName = '';

    const zipCode = municipalitySegments[0] ?? '';
    if (isNaN(parseInt(zipCode))) {
      return { street: address };
    }
    if (municipalitySegments.length > 1) {
      municipalityName = municipalitySegments.slice(1).join(' ');
    }
    return { street, country, zipCode, municipalityName };
  }

  public static arePersonsEqual(
    person: FullPerson<any, any>,
    gstOwner: GstInfoOwner,
    address: GstInfoOwnerAddress
  ): boolean {
    return (
      person.firstName === gstOwner.name &&
      person.lastName === gstOwner.lastName &&
      person.companyName === gstOwner.company &&
      person.streetName === address.street &&
      person.municipality === address.municipalityName
    );
  }
}

export enum GisPropertyName {
  EZ = 'EZ',
  KGNR = 'KGNR',
  GSTNR = 'GSTNR'
}

export type GstInfoOwnerAddress = {
  street: string;
  country?: string;
  zipCode?: string;
  municipalityName?: string;
};
