export class RoundedNumberValueConverter {
  /**
   * @param {number|null} value
   * @returns {string}
   */
  toView(value) {
    if (value == null) {
      return '';
    }

    const roundedValue = Math.round(value * 100) / 100;
    return roundedValue.toFixed(2).replace('.', ',');
  }
}
