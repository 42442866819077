import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskComment } from '../../classes/EntityManager/entities/ProcessTaskComment/types';
import { ProcessTaskCommentGeneralFile } from '../../classes/EntityManager/entities/GeneralFile/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';

@autoinject()
export class ProcessTaskCommentsWidgetCommentCard {
  @bindable()
  public comment: ProcessTaskComment | null = null;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly commentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskComment];

  private isAttached: boolean = false;
  protected generalFiles: Array<ProcessTaskCommentGeneralFile> = [];
  protected processConfigurationStep: ProcessConfigurationStep | null = null;

  constructor(
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.commentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskComment,
        context: this as ProcessTaskCommentsWidgetCommentCard,
        propertyName: 'comment'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.GeneralFile,
      this.updateGeneralFiles.bind(this)
    );
    this.updateGeneralFiles();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationStep.bind(this)
    );
    this.updateProcessConfigurationStep();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected commentChanged(): void {
    if (this.isAttached) {
      this.updateGeneralFiles();
      this.updateProcessConfigurationStep();
    }
  }

  private updateGeneralFiles(): void {
    if (this.comment) {
      this.generalFiles =
        this.entityManager.generalFileRepository.getByProcessTaskCommentId(
          this.comment.id
        );
    } else {
      this.generalFiles = [];
    }
  }

  private updateProcessConfigurationStep(): void {
    if (this.comment && this.comment.createdAtProcessConfigurationStepId) {
      this.processConfigurationStep =
        this.entityManager.processConfigurationStepRepository.getById(
          this.comment.createdAtProcessConfigurationStepId
        );
    } else {
      this.processConfigurationStep = null;
    }
  }

  protected handleDeleteClick(): void {
    const comment = this.comment;
    assertNotNullOrUndefined(
      comment,
      "can't ProcessTaskCommentsWidgetCommentCard.handleDeleteClick without a comment"
    );

    void Dialogs.deleteEntityDialog(comment).then(() => {
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskComment,
        entity: comment,
        displayNameAtLogTime: null
      });
      this.entityManager.processTaskCommentRepository.delete(comment);
    });
  }
}
