import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { BaseEntityDto } from './BaseEntityUtils';
import { EntityName } from './EntityName';
import {
  OptionalProcessTaskGroupSubEntityDto,
  OptionalProcessTaskGroupSubEntityFieldInfos,
  ProcessTaskGroupSubEntityDto,
  ProcessTaskGroupSubEntityFieldInfos,
  ProcessTaskGroupSubEntityUtils
} from './ProcessTaskGroupSubEntityUtils';

export class ProcessTaskSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): ProcessTaskSubEntityFieldInfos {
    return {
      ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
      ownerProcessTaskId: {
        name: 'ownerProcessTaskId',
        ref: EntityName.ProcessTask,
        cascadeDeleteLocally: true,
        additionalData: {
          ownerId: true
        }
      }
    };
  }

  public static createOptionalBaseFieldInfos(): OptionalProcessTaskSubEntityFieldInfos {
    return {
      ...ProcessTaskGroupSubEntityUtils.createOptionalBaseFieldInfos(),
      ownerProcessTaskId: {
        name: 'ownerProcessTaskId',
        ref: EntityName.ProcessTask,
        cascadeDeleteLocally: true,
        defaultValue: null,
        additionalData: {
          ownerId: true
        }
      }
    };
  }
}

export type ProcessTaskSubEntityFieldInfos =
  ProcessTaskGroupSubEntityFieldInfos & {
    ownerProcessTaskId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskSubEntityDto,
      'ownerProcessTaskId'
    >;
  };

export type ProcessTaskSubEntityDto = BaseEntityDto &
  ProcessTaskGroupSubEntityDto & {
    ownerProcessTaskId: string;
  };

export type OptionalProcessTaskSubEntityFieldInfos =
  OptionalProcessTaskGroupSubEntityFieldInfos & {
    ownerProcessTaskId: EntityFieldInfo<
      AppCommonSynchronizationEnvironmentTypes,
      OptionalProcessTaskSubEntityDto,
      'ownerProcessTaskId'
    >;
  };

export type OptionalProcessTaskSubEntityDto = BaseEntityDto &
  OptionalProcessTaskGroupSubEntityDto & {
    ownerProcessTaskId: string | null;
  };
