import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { AutoProgressConfigurationType } from 'common/Types/Entities/ProcessConfigurationStep/ProcessConfigurationStepDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessConfigurationStepAutoProgressConfigurationWidget {
  @bindable()
  public processConfigurationStep: ProcessConfigurationStep | null = null;

  @subscribableLifecycle()
  protected readonly processConfigurationStepPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStep];

  protected readonly AutoProgressConfigurationType =
    AutoProgressConfigurationType;

  protected readonly typeOptions: Array<TypeOption> = [
    {
      value: AutoProgressConfigurationType.NONE,
      labelTk:
        'operationsComponents.processConfigurationStepAutoProgressConfigurationWidget.types.none'
    },
    {
      value: AutoProgressConfigurationType.NEXT_STEP,
      labelTk:
        'operationsComponents.processConfigurationStepAutoProgressConfigurationWidget.types.nextStep'
    },
    {
      value: AutoProgressConfigurationType.STEP,
      labelTk:
        'operationsComponents.processConfigurationStepAutoProgressConfigurationWidget.types.step'
    }
  ];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationStepPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationStep,
        context:
          this as ProcessConfigurationStepAutoProgressConfigurationWidget,
        propertyName: 'processConfigurationStep'
      });
  }

  @computedFrom('processConfigurationStep.autoProgressConfigurationType')
  protected get autoProgressConfigurationType(): AutoProgressConfigurationType {
    return (
      this.processConfigurationStep?.autoProgressConfigurationType ??
      AutoProgressConfigurationType.NEXT_STEP
    );
  }

  protected set autoProgressConfigurationType(
    value: AutoProgressConfigurationType
  ) {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      "can't ProcessConfigurationStepAutoProgressConfigurationWidget.set autoProgressConfigurationType without processConfigurationStep"
    );

    this.processConfigurationStep.autoProgressConfigurationType = value;
    this.entityManager.processConfigurationStepRepository.update(
      this.processConfigurationStep
    );
  }

  protected handleProcessConfigurationStepChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      "can't ProcessConfigurationStepAutoProgressConfigurationWidget.handleProcessConfigurationStepChanged without processConfigurationStep"
    );
    this.entityManager.processConfigurationStepRepository.update(
      this.processConfigurationStep
    );
  }
}

type TypeOption = {
  value: AutoProgressConfigurationType;
  labelTk: string;
};
