export class DebugLogger {
  private loggingEnabled: boolean;

  constructor({ loggingEnabled }: { loggingEnabled: boolean }) {
    this.loggingEnabled = loggingEnabled;
  }

  public log(...args: Array<unknown>): void {
    if (this.loggingEnabled) {
      console.debug('[socket]', ...args);
    }
  }

  public enableLogging(): void {
    this.loggingEnabled = true;
  }

  public disableLogging(): void {
    this.loggingEnabled = false;
  }
}
