import _ from 'lodash';

import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { Utils } from './Utils/Utils';
import { UrlManager } from './UrlManager';
import { FileUtils } from './Utils/FileUtils/FileUtils';
import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { Picture } from './EntityManager/entities/Picture/types';
import { PictureFile } from './EntityManager/entities/PictureFile/types';
import { CustomDirectoryEntry } from './Utils/FileUtils/entries/CustomDirectoryEntry';

export class LocalProjectFilesManager {
  private static MAXIMUM_AGE_OF_UNJOINED_PROJECT_FILES =
    14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds

  constructor(private readonly entityManager: AppEntityManager) {}

  public async handleLocalProjectFiles(): Promise<void> {
    await this.deleteOldProjectFiles();
    await this.scanProjectFolders();
  }

  private async deleteOldProjectFiles(): Promise<void> {
    console.log('DELETING OLD LOCAL PROJECT FILES...');
    const maxAgeTimestamp =
      Date.now() -
      LocalProjectFilesManager.MAXIMUM_AGE_OF_UNJOINED_PROJECT_FILES;
    const projectIdsToDelete =
      this.entityManager.projectMetadataManager.getProjectIdsLeftBeforeTimestamp(
        maxAgeTimestamp
      );
    for (const projectId of projectIdsToDelete) {
      console.log('... deleting files of project', projectId);
      await this.deleteLocalProjectFiles(projectId);
      this.entityManager.projectMetadataManager.removeMetadata(projectId);
    }
  }

  private async scanProjectFolders(): Promise<void> {
    console.log('SCANNING LOCAL PROJECT FOLDERS...');

    const directoryListing =
      await this.tryGettingLocalProjectFilesDirectoryListing();

    for (const directoryEntry of directoryListing) {
      const hasMetadata = this.entityManager.projectMetadataManager.hasMetadata(
        directoryEntry.name
      );
      if (hasMetadata) continue;

      if (
        this.entityManager.joinedProjectsManager.projectIsJoined(
          directoryEntry.name
        )
      )
        continue;

      this.entityManager.projectMetadataManager.setLeftProjectOn(
        directoryEntry.name
      );
    }

    console.error('FINISHED SCANNING LOCAL PROJECT FOLDERS!');
  }

  private async deleteLocalProjectFiles(projectId: string): Promise<void> {
    const titlePicturesOfProject =
      this.entityManager.pictureRepository.getByProjectId(projectId);
    const titlePictureFiles = this.getPictureFilesOfPictures(
      titlePicturesOfProject
    );
    const titlePictureFileIds = titlePictureFiles.map(
      (pictureFile) => pictureFile.id
    );

    const basePath = this.getLocalProjectFilesBasePath();
    const projectFilesPath = PathUtils.joinPaths(basePath, projectId);

    let directoryListing = null;
    try {
      directoryListing = await FileUtils.getFilesShallow(projectFilesPath);
    } catch (e) {
      if (e instanceof FileError && e.code === FileError.NOT_FOUND_ERR) {
        return; // ignore the file not found error because that's basically what we want ;)
      }

      throw e;
    }

    for (const fileEntry of directoryListing) {
      const pictureFileId = Utils.extractIdFromPictureFileName(fileEntry.name);
      if (!pictureFileId) continue;

      if (_.includes(titlePictureFileIds, pictureFileId)) continue;

      await FileUtils.deleteEntry(
        PathUtils.joinPaths(UrlManager.localFolder, fileEntry.path)
      );
      console.log('DELETED:', fileEntry.path);
    }
  }

  /**
   * returns an empty array if the folder doesn't exist yet
   */
  private async tryGettingLocalProjectFilesDirectoryListing(): Promise<
    Array<CustomDirectoryEntry>
  > {
    const basePath = this.getLocalProjectFilesBasePath();

    try {
      return await FileUtils.getDirectoriesShallow(basePath);
    } catch (e) {
      if (e instanceof FileError && e.code === FileError.NOT_FOUND_ERR) {
        return []; // since there is no project folder we act like it's empty
      }

      throw e;
    }
  }

  private getLocalProjectFilesBasePath(): string {
    return PathUtils.joinPaths(UrlManager.localFolder, 'project');
  }

  private getPictureFilesOfPictures(
    pictures: Array<Picture>
  ): Array<PictureFile> {
    const pictureFiles: Array<PictureFile> = [];

    for (const picture of pictures) {
      pictureFiles.push(
        ...this.entityManager.pictureFileRepository.getByPictureId(picture.id)
      );
    }

    return pictureFiles;
  }
}
