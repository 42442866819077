import { bindable, autoinject } from 'aurelia-framework';
import {
  IProcessConfigurationPositionPropertiesConfiguration,
  TPositionDefaultPropertyConfig
} from 'common/Types/ProcessConfigurationPositionPropertiesConfiguration';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  ProcessConfigurationPositionTypeToConfiguration,
  TProcessConfigurationPositionTypeConfiguration
} from 'common/Enums/ProcessConfigurationPositionType';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { PriceInfo } from './PriceInfo';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DateRangeValueConverter } from '../../valueConverters/DateRangeValueConverter';
import {
  PropertiesByProcessTaskPositionDetailEntryIdComputer,
  PropertiesByProcessTaskPositionDetailEntryId
} from '../../computedValues/computers/PropertiesByProcessTaskPositionDetailEntryIdComputer';
import {
  ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId
} from '../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionUtils } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskPositionProperty } from '../../classes/EntityManager/entities/Property/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import {
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer
} from '../../computedValues/computers/ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';

@autoinject()
export class ProcessTaskPositionListItemAdditionalInfoText {
  @bindable()
  public processTaskPosition: ProcessTaskPosition | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public priceInfo: PriceInfo | null = null;

  @bindable()
  public relatedAppointments: Array<ProcessTaskAppointment> = [];

  @bindable()
  public showPrice: boolean = true;

  private subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private properties: Array<ProcessTaskPositionProperty> = [];
  private nextAppointmentInfoText: string | null = null;
  private propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId =
    new Map();
  private detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId =
    new Map();
  private typeConfiguration: TProcessConfigurationPositionTypeConfiguration | null =
    null;
  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();
  private processTaskAppointmentToUsersByProcessTaskAppointmentId: ProcessTaskAppointmentToUsersByProcessTaskAppointmentId =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskPosition.type',
      this.updateTypeConfiguration.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          PropertiesByProcessTaskPositionDetailEntryIdComputer,
        createComputeData: () =>
          this.processTaskPosition
            ? {
                ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId
              }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskPosition.ownerProcessTaskId',
            updateSubscription
          );
        },
        callback: (propertiesByDetailEntryId) => {
          this.propertiesByDetailEntryId = propertiesByDetailEntryId;
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTaskPosition
            ? {
                ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId
              }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskPosition.ownerProcessTaskId',
            updateSubscription
          );
        },
        callback: (detailEntriesByPositionId) => {
          this.detailEntriesByPositionId = detailEntriesByPositionId;
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateNextAppointmentInfoText();
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: (processTaskAppointmentToUsersByProcessTaskAppointmentId) => {
          this.processTaskAppointmentToUsersByProcessTaskAppointmentId =
            processTaskAppointmentToUsersByProcessTaskAppointmentId;
          this.updateNextAppointmentInfoText();
        }
      })
    );

    this.updateProperties();
    this.updateTypeConfiguration();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private relatedAppointmentsChanged(): void {
    if (this.isAttached) {
      this.updateNextAppointmentInfoText();
    }
  }

  private updateProperties(): void {
    if (this.processTaskPosition) {
      const configs = this.getDefaultPropertyConfigs();
      const propertyNames = configs
        .filter((c) => c.showInAdditionalInfo)
        .map((c) => c.name);
      const properties =
        this.entityManager.propertyRepository.getByProcessTaskPositionId(
          this.processTaskPosition.id
        );
      this.properties = properties.filter(
        (p) => propertyNames.indexOf(p.name || '') >= 0
      );
    } else {
      this.properties = [];
    }
  }

  private updateNextAppointmentInfoText(): void {
    const now = Date.now();
    let nextAppointment: ProcessTaskAppointment | null = null;
    let nextAppointmentDateInfo: ProcessTaskAppointmentDateInfo | null = null;
    let nextAppointmentTimestamp: number | null = null;

    for (const appointment of this.relatedAppointments) {
      const dateInfo = this.processTaskAppointmentDateInfoMap.get(
        appointment.id
      );
      if (!dateInfo?.dateFrom) {
        continue;
      }

      const timestamp = new Date(dateInfo.dateFrom).getTime();
      if (
        timestamp > now &&
        (nextAppointmentTimestamp == null ||
          timestamp < nextAppointmentTimestamp)
      ) {
        nextAppointment = appointment;
        nextAppointmentDateInfo = dateInfo;
        nextAppointmentTimestamp = timestamp;
      }
    }

    if (nextAppointment && nextAppointmentDateInfo) {
      this.nextAppointmentInfoText = this.getAppointmentInfoText(
        nextAppointment,
        nextAppointmentDateInfo
      );
    } else {
      this.nextAppointmentInfoText = null;
    }
  }

  private updateTypeConfiguration(): void {
    if (this.processTaskPosition) {
      this.typeConfiguration =
        ProcessConfigurationPositionTypeToConfiguration.get(
          this.processTaskPosition.type
        ) ?? null;
    } else {
      this.typeConfiguration = null;
    }
  }

  private getAppointmentInfoText(
    appointment: ProcessTaskAppointment,
    dateInfo: ProcessTaskAppointmentDateInfo
  ): string {
    const parts: Array<string> = [];

    const converter = new DateRangeValueConverter();
    const dateRangeText = converter.toView({
      fromIso: dateInfo.dateFrom,
      toIso: dateInfo.dateTo
    });
    if (dateRangeText) {
      parts.push(dateRangeText);
    }

    const relations =
      this.processTaskAppointmentToUsersByProcessTaskAppointmentId.get(
        appointment.id
      ) ?? [];
    for (const relation of relations) {
      const user = this.entityManager.userRepository.getById(relation.userId);
      if (user) {
        parts.push(user.username || '');
      }
    }

    return parts.join(' - ');
  }

  private getDefaultPropertyConfigs(): Array<TPositionDefaultPropertyConfig> {
    const configuration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;
    const propertiesConfigJson =
      configuration && configuration.positionPropertiesConfigurationJson
        ? configuration.positionPropertiesConfigurationJson
        : null;
    const propertiesConfig: IProcessConfigurationPositionPropertiesConfiguration | null =
      propertiesConfigJson ? JSON.parse(propertiesConfigJson) : null;
    return propertiesConfig ? propertiesConfig.properties : [];
  }

  private hasText(
    amount: string,
    noteInternal: string | null,
    properties: Array<ProcessTaskPositionProperty>,
    nextAppointmentInfoText: null | string
  ): boolean {
    return (
      !!amount ||
      !!noteInternal ||
      !!properties.length ||
      !!nextAppointmentInfoText
    );
  }

  private getText(
    positionId: string,
    amount: string,
    unit: string | null,
    noteInternal: string | null,
    detailEnabled: boolean,
    detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
    propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId,
    properties: Array<ProcessTaskPositionProperty>,
    nextAppointmentInfoText: null | string
  ): string {
    const parts: Array<String> = [];

    parts.push(
      this.getAmountString(
        positionId,
        amount,
        unit,
        detailEnabled,
        detailEntriesByPositionId,
        propertiesByDetailEntryId
      )
    );

    properties.forEach((p) => {
      const text = PropertyHelper.getPropertyText(
        p.type || PropertyType.TEXT,
        p.name,
        p.value,
        p.custom_choice
      );
      parts.push(`${p.name}: ${text || '-'}`);
    });

    if (nextAppointmentInfoText) {
      parts.push(nextAppointmentInfoText);
    }

    if (noteInternal) {
      parts.push(noteInternal);
    }

    return parts.join(' — ');
  }

  private getAmountString(
    positionId: string,
    amount: string,
    unit: string | null,
    detailEnabled: boolean,
    detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
    propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId
  ): string {
    return ProcessTaskPositionUtils.getAmountString(
      positionId,
      amount,
      unit,
      detailEnabled,
      detailEntriesByPositionId,
      propertiesByDetailEntryId
    );
  }
}
