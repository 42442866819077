export class PersonHelper {
  public static getPersonDisplayName(
    company: boolean,
    companyName: string | null,
    title: string | null,
    firstName: string | null,
    lastName: string | null
  ): string {
    const companyNameParts = [];
    const firstLastNameParts = [];

    if (title) {
      firstLastNameParts.push(title);
    }

    if (firstName) {
      firstLastNameParts.push(firstName);
    }

    if (lastName) {
      firstLastNameParts.push(lastName);
    }

    if (company && companyName) {
      companyNameParts.push(companyName);
      if (firstLastNameParts.length) {
        companyNameParts.push('-');
      }
    }

    return companyNameParts.concat(firstLastNameParts).join(' ');
  }

  /**
   * Returns the first address line for a person.
   *
   * @example
   * const x = getAddressLine1("Street", "1", "2", "101", "21c", "Floor", "Staircase")
   * assert(x, "Street 101 21c, Floor 1, Staircase 2");
   */
  public static getAddressLine1(
    streetName: string | null,
    floor: string | null,
    staircase: string | null,
    doorNumber: string | null,
    addressAppendix: string | null,
    floorPrefix?: string,
    staircasePrefix?: string
  ): string {
    return [
      [streetName, doorNumber, addressAppendix].filter(truthy).join(' - '),
      floorPrefix && floor ? [floorPrefix, floor].join(' ') : null,
      staircasePrefix && staircase
        ? [staircasePrefix, staircase].join(' ')
        : null
    ]
      .filter(truthy)
      .join(', ');
  }

  /**
   * Returns the second address line for a person.
   *
   * @example
   * const x = getAddressLine2("6301", "New York")
   * assert(x, "6301 New York");
   */
  public static getAddressLine2(
    zip: string | null,
    municipality: string | null
  ): string {
    return [zip, municipality].filter(truthy).join(' ');
  }
}

/**
 * Returns true if the given value is truthy.
 */
function truthy<T extends unknown>(val: T | null | undefined): val is T {
  return !!val;
}
