import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import { EntityName } from '../EntityManager/entities/types';

const defaultTableName = 'defaultTable';
export const storeInfo = createTypeExtendsValidator<StoreInfoConstraint>()({
  name: 'recordIT',
  defaultTableName,
  applicationTables: [
    ...Object.values(EntityName).map((name) => ({ name: name })),
    { name: 'permanent', keepOnClearAll: true },
    { name: 'PictureFileUploadHelper', keepOnClearAll: true },
    { name: 'FileUploadService', keepOnClearAll: true }
  ],
  migrationsTableName: 'migrations'
});

export type StoreInfoConstraint = {
  readonly name: string;
  readonly defaultTableName: string;
  readonly migrationsTableName: string;

  /**
   * The defaultTable is not included here and needs to be handled explicitly
   */
  readonly applicationTables: Readonly<Array<Readonly<StorageTableConfig>>>;
};

export type StorageTableConfig = { name: string; keepOnClearAll?: boolean };
