import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @event file-changed - detail: IFileChangedEventDetail
 * @event file-download-requested
 */
@autoinject()
export class FileInput {
  @bindable public fileName = '';
  @bindable public enabled = false;
  @bindable public accept = null;

  @bindable public labelTk = 'inputComponents.fileInput.fileName';

  private domElement: Element;
  private fileInputElement: HTMLInputElement | null = null;

  constructor(element: Element) {
    this.domElement = element;
  }

  private handleFileInputChanged(): void {
    if (this.fileInputElement?.files && this.fileInputElement.files[0]) {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'file-changed',
        detail: {
          file: this.fileInputElement.files[0]
        } as IFileChangedEventDetail
      });
      this.fileInputElement.value = '';
    }
  }

  private handleDownloadFileClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'file-download-requested',
      detail: null
    });
  }
}

export interface IFileChangedEventDetail {
  file: File;
}
