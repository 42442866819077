import { bindable, computedFrom } from 'aurelia-framework';
import { StructurePictureArea } from '../../classes/EntityManager/entities/StructurePictureArea/types';

export class StructurePictureAreaPreview {
  @bindable()
  public structurePictureArea: StructurePictureArea | null = null;

  @bindable()
  public index: number | null = null;

  @computedFrom(
    'structurePictureArea.topLeft.x',
    'structurePictureArea.topLeft.y',
    'structurePictureArea.bottomRight.x',
    'structurePictureArea.bottomRight.y'
  )
  protected get rootElementStyle(): Record<string, string> {
    if (
      !this.structurePictureArea ||
      !this.structurePictureArea.topLeft ||
      !this.structurePictureArea.bottomRight
    ) {
      return {};
    }

    return {
      top: `${this.structurePictureArea.topLeft.y}%`,
      left: `${this.structurePictureArea.topLeft.x}%`,
      height: `${
        this.structurePictureArea.bottomRight.y -
        this.structurePictureArea.topLeft.y
      }%`,
      width: `${
        this.structurePictureArea.bottomRight.x -
        this.structurePictureArea.topLeft.x
      }%`
    };
  }
}
