import { I18N } from 'aurelia-i18n';

import {
  FieldConfigs,
  ValueType
} from 'common/ExpressionEditorScope/ExpressionEditorScopeCreationUtils';

export class ExpressionEditorScopeEvaluationUtils {
  public static createLegend(
    i18n: I18N,
    configs: FieldConfigs,
    indent: number
  ): string {
    const lines: Array<string> = [];
    const prefix = ' '.repeat(indent);
    for (const config of Object.values(configs)) {
      lines.push(
        `${prefix}${config.name}: ${i18n.tr(config.descriptionTk)} <${
          config.valueType
        }${config.nullable ? '|null' : ''}>`
      );
      if (config.valueType === ValueType.NESTED && config.fieldConfigs) {
        lines.push(this.createLegend(i18n, config.fieldConfigs, 2 * indent));
      }
    }
    return lines.join('\n');
  }
}
