import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { SharepointListColumnToThingMappingItem } from './types';

export class SharepointListColumnToThingMappingItemRepository extends AppEntityRepository<EntityName.SharepointListColumnToThingMappingItem> {
  public getByThingGroupId(
    thingGroupId: string
  ): Array<SharepointListColumnToThingMappingItem> {
    return this.getAll().filter((i) => i.ownerThingGroupId === thingGroupId);
  }
}
