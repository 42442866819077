import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { StructureTemplateSelectValueChangedEvent } from '../../structureTemplateComponents/structure-template-select/structure-template-select';
import { StructureTemplateEntryChangedEvent } from './edit-structure-picture-area-path-dialog-entry-select/edit-structure-picture-area-path-dialog-entry-select';

export class EditStructurePictureAreaPathDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);

    view.getViewModel().open(options);
  }

  private onNewPath: OnNewPath | null = null;
  protected selectedStructureTemplateId: string | null = null;
  protected displayedEntrySelects: Array<DisplayedEntrySelect> = [];
  protected dialog: RecordItDialog | null = null;

  public open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditStructurePictureAreaPathDialog.open without dialog"
    );

    this.onNewPath = options.onNewPath;
    this.resetDisplayedEntrySelects();

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.displayedEntrySelects = [];
  }

  protected handleAcceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.onNewPath,
      "can't EditStructurePictureAreaPathDialog.handleAcceptButtonClicked without onNewPath"
    );
    const path: Array<string> = [];

    for (const displayedEntrySelect of this.displayedEntrySelects) {
      if (displayedEntrySelect.structureTemplateEntry == null) {
        break;
      }

      path.push(displayedEntrySelect.structureTemplateEntry.name ?? '');
    }

    this.onNewPath(path);
  }

  protected handleSelectedStructureTemplateIdChanged(
    event: StructureTemplateSelectValueChangedEvent
  ): void {
    this.selectedStructureTemplateId = event.detail.value;

    this.resetDisplayedEntrySelects();
  }

  protected handleStructureTemplateEntryChanged(
    displayedEntrySelect: DisplayedEntrySelect,
    event: StructureTemplateEntryChangedEvent
  ): void {
    displayedEntrySelect.structureTemplateEntry =
      event.detail.structureTemplateEntry;
    const newDisplayedEntrySelects = [...this.displayedEntrySelects];
    const index = newDisplayedEntrySelects.indexOf(displayedEntrySelect);

    if (index === -1) {
      // this should never happen, but if it does, reset the entry selects
      newDisplayedEntrySelects.splice(0, newDisplayedEntrySelects.length, {
        depth: 0,
        parentStructureTemplateEntry: null,
        structureTemplateEntry: null
      });
    } else {
      // remove all following elements, because they don't make any sense when the user selected a different structureTemplateEntry
      newDisplayedEntrySelects.splice(
        index + 1,
        newDisplayedEntrySelects.length
      );

      if (displayedEntrySelect.structureTemplateEntry) {
        newDisplayedEntrySelects.push({
          depth: displayedEntrySelect.depth + 1,
          parentStructureTemplateEntry:
            displayedEntrySelect.structureTemplateEntry,
          structureTemplateEntry: null
        });
      }
    }

    this.displayedEntrySelects = newDisplayedEntrySelects;
  }

  protected resetDisplayedEntrySelects(): void {
    if (this.selectedStructureTemplateId) {
      this.displayedEntrySelects = [
        {
          depth: 0,
          structureTemplateEntry: null,
          parentStructureTemplateEntry: null
        }
      ];
    } else {
      this.displayedEntrySelects = [];
    }
  }
}

export type OpenOptions = {
  onNewPath: OnNewPath;
};

export type OnNewPath = (path: Array<string>) => void;

type DisplayedEntrySelect = {
  depth: number;
  structureTemplateEntry: StructureTemplateEntry | null;
  parentStructureTemplateEntry: StructureTemplateEntry | null;
};
