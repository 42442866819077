import { autoinject } from 'aurelia-framework';
import { CreateEntityClickedEvent } from '../../../aureliaComponents/create-entity-button/create-entity-button';
import { QuickKukDefectCreationService } from './QuickKukDefectCreationService';
import { Router } from 'aurelia-router';
import { EntityName } from '../../../classes/EntityManager/entities/types';

@autoinject()
export class KukDefectShortcut {
  protected EntityName = EntityName;

  constructor(
    private readonly quickKukDefectCreationService: QuickKukDefectCreationService,
    private readonly router: Router
  ) {}

  protected handleClicked(event: CreateEntityClickedEvent): void {
    if (!event.detail.userGroup?.id) return;

    const defect = this.quickKukDefectCreationService.createDefect(
      event.detail.userGroup.id
    );
    this.router.navigateToRoute('edit_defect', {
      defect_id: defect.id,
      close_to_thing: true
    });
  }
}
