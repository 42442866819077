import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createPictureEntityInfo } from 'common/Types/Entities/Picture/createPictureEntityInfo';
import { PictureDto } from 'common/Types/Entities/Picture/PictureDto';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { BaseEntity } from '../BaseEntity';

// since we already removed the `thing` property in the client, we have to use the dto type here, or typescript will complain about the typing difference
export const pictureEntityInfo = createPictureEntityInfo<
  BaseEntity & PictureDto<string, string>
>({
  additionalFieldInfos:
    EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
});
