import {
  AnyPropertyWidgetBindingFeature,
  PropertyWidgetBindingFeatureMap
} from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertySubWidgetConfiguration } from './PropertySubWidgetConfiguration';

/**
 * A helper function so only the necessary features can be defined and also so the features can be automatically inferred
 */
export function createPropertySubWidgetConfiguration<
  TFeaturesArray extends FeaturesArrayConstraint
>({
  features
}: CreatePropertySubWidgetConfigurationOptions<TFeaturesArray>): PropertySubWidgetConfiguration<
  TFeaturesArray[number]
> {
  return {
    features: createFeatureMapForFeatures({ features })
  };
}

export type CreatePropertySubWidgetConfigurationOptions<
  TFeaturesArray extends FeaturesArrayConstraint
> = {
  features: Readonly<TFeaturesArray>;
};

function createFeatureMapForFeatures<
  TFeaturesArray extends Array<AnyPropertyWidgetBindingFeature>
>({
  features
}: {
  features: Readonly<TFeaturesArray>;
}): PropertyWidgetBindingFeatureMap<TFeaturesArray[number]> {
  return {
    default: features.includes('default') as any,
    person: features.includes('person') as any,
    picture: features.includes('picture') as any,
    position: features.includes('position') as any,
    projectId: features.includes('projectId') as any,
    generalFile: features.includes('generalFile') as any
  };
}

type FeaturesArrayConstraint =
  | ['none']
  | [
      AnyPropertyWidgetBindingFeatureExceptNone,
      ...Array<AnyPropertyWidgetBindingFeatureExceptNone>
    ];
type AnyPropertyWidgetBindingFeatureExceptNone = Exclude<
  AnyPropertyWidgetBindingFeature,
  'none'
>;
