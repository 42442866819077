/**
 * automatically resizes an input element to be only as wide as necessary
 */
export class InputElementResizer {
  /** @type {HTMLInputElement} */
  _inputElement;

  /**
   * @param {HTMLInputElement} inputElement
   */
  constructor(inputElement) {
    this._inputElement = inputElement;
  }

  /**
   * @param {(string|null)} [value] - default value is the value of the input itself
   */
  resizeInput(value) {
    const s = document.createElement('span');
    s.style.position = 'absolute';
    s.style.opacity = '0';
    s.style.display = 'inline-block';

    const computed = window.getComputedStyle(this._inputElement);
    s.style.fontSize = computed.fontSize;
    s.style.fontFamily = computed.fontFamily;
    s.style.fontStyle = computed.fontStyle;
    s.style.fontVariant = computed.fontVariant;
    s.style.fontWeight = computed.fontWeight;
    s.style.lineHeight = computed.lineHeight;
    s.style.letterSpacing = computed.letterSpacing;
    s.style.padding = computed.padding;
    s.appendChild(
      document.createTextNode(value != null ? value : this._inputElement.value)
    );
    const paddingRight = parseInt(computed.fontSize) / 2;

    document.body.appendChild(s);
    const size = s.clientWidth + paddingRight;
    this._inputElement.style.width = !isNaN(size) ? size + 'px' : '';
    document.body.removeChild(s);
  }
}
