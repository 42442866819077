import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationCategory } from '../../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationCategoriesWidgetListItem {
  @bindable()
  public processConfigurationCategory: ProcessConfigurationCategory | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationCategoryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationCategory];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationCategoryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationCategory,
        context: this as ProcessConfigurationCategoriesWidgetListItem,
        propertyName: 'processConfigurationCategory'
      });
  }

  protected handleCategoryChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationCategory,
      "can't ProcessConfigurationCategoriesWidgetListItem.handleCategoryChanged without processConfigurationCategory"
    );

    this.entityManager.processConfigurationCategoryRepository.update(
      this.processConfigurationCategory
    );
  }

  protected handleDeleteCategoryClick(): void {
    const processConfigurationCategory = this.processConfigurationCategory;
    assertNotNullOrUndefined(
      processConfigurationCategory,
      "can't ProcessConfigurationCategoriesWidgetListItem.handleCategoryChanged without processConfigurationCategory"
    );

    void Dialogs.deleteEntityDialog(processConfigurationCategory).then(() => {
      this.entityManager.processConfigurationCategoryRepository.delete(
        processConfigurationCategory
      );
    });
  }
}
