import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { PermissionHelper } from '../../classes/PermissionHelper';
import { HighlightAnimator } from '../../classes/Animation/HighlightAnimator';
import {
  ModuleName,
  RecordItModuleHelper
} from '../../classes/RecordItModuleHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { CustomSelect } from '../custom-select/custom-select';
import { ProjectSelect } from '../project-select/project-select';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

@autoinject()
export class ThingAndProjectSelect {
  @bindable public thing: Thing | null = null;
  @bindable public thingId: string | null = null;
  @bindable public project: Project | null = null;
  @bindable public projectId: string | null = null;

  /**
   * to only provide things of a certain module
   */
  @bindable public moduleName: ModuleName | null = null;

  @bindable public enabled = false;

  @bindable public required = false;

  private currentUser: User | null = null;
  private editableUserGroups: Array<UserGroup> = [];
  private availableThings: Array<Thing> = [];
  private availableProjects: Array<Project> = [];
  private errorText: string | null = null;

  private thingCustomSelectElement: HTMLElement | null = null;
  private thingCustomSelect: CustomSelect<Thing, Thing> | null = null;

  /**
   * this element is not always available, only when a thing is selected
   */
  private projectSelectElement: HTMLElement | null = null;
  private projectSelect: ProjectSelect | null = null;

  /**
   * to ignore internal changes (internal and external changes are handled differently)
   */
  private ignoreThingIdChanged = false;

  private lastValidateResult = true;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );
    this.updateEditableUserGroups();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateAvailableThings.bind(this)
    );
    this.updateAvailableThings();

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Thing,
        this.updateIdBindings.bind(this)
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public validate(): boolean {
    const result = this.validateElements();
    this.highlightValidatedElements(result);

    this.lastValidateResult = result.valid;
    return result.valid;
  }

  /**
   * removes the whole validation style
   */
  public resetValidation(): void {
    this.lastValidateResult = true;
    this.errorText = null;
    if (this.thingCustomSelectElement)
      this.setCustomSelectElementWarningStyle(
        this.thingCustomSelectElement,
        false
      );
    if (this.projectSelectElement) {
      this.setCustomSelectElementWarningStyle(this.projectSelectElement, false);
    }
  }

  private validateElements(): TThingAndProjectSelectElementValidateElementsResult {
    let valid = true;
    let errorText = null;
    let elementToHighlight = null;
    let customSelect = null;

    if (!this.project) {
      errorText = this.translate('noProjectSelectedErrorText');
      elementToHighlight = this.projectSelectElement;
      customSelect = this.projectSelect;
      valid = false;
    }

    if (!this.thing) {
      errorText = this.translate('noThingSelectedErrorText');
      elementToHighlight = this.thingCustomSelectElement;
      customSelect = this.thingCustomSelect;
      valid = false;
    }

    this.errorText = errorText;

    return { elementToHighlight, customSelect, valid };
  }

  private highlightValidatedElements(
    result: TThingAndProjectSelectElementValidateElementsResult
  ): void {
    if (result.elementToHighlight) {
      const animator = new HighlightAnimator(result.elementToHighlight);
      animator.highlightBackground();
    }

    if (result.customSelect) {
      result.customSelect.focus();
    }

    if (this.thingCustomSelectElement)
      this.setCustomSelectElementWarningStyle(
        this.thingCustomSelectElement,
        !this.thing && this.required
      );
    if (this.projectSelectElement)
      this.setCustomSelectElementWarningStyle(
        this.projectSelectElement,
        !this.project && this.required
      );
  }

  private setCustomSelectElementWarningStyle(
    element: HTMLElement,
    warningStyle: boolean
  ): void {
    if (warningStyle) {
      element.setAttribute('data-status', 'warning');
    } else {
      element.removeAttribute('data-status');
    }
  }

  protected thingChanged(newValue: Thing, oldValue: Thing): void {
    if (newValue == null && oldValue == null) {
      return; // value hasn't actually changed, only from null to undefined
    }

    this.setThingIdAndIgnoreChange(this.thing ? this.thing.id : null);
    this.autoValidate();
  }

  protected thingIdChanged(): void {
    if (!this.ignoreThingIdChanged && this.thingId) {
      this.thing =
        this.entityManager.thingRepository.getById(this.thingId) || null;
    }
  }

  protected projectChanged(): void {
    this.autoValidate();
  }

  protected moduleNameChanged(): void {
    this.updateAvailableThings();
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }

    this.updateAvailableThings();
  }

  private updateAvailableThings(): void {
    const currentUser = this.currentUser;
    if (currentUser) {
      const projectType = this.moduleName
        ? RecordItModuleHelper.getProjectTypeForModuleName(this.moduleName)
        : null;
      this.availableThings = this.entityManager.thingRepository
        .getAll()
        .filter((thing) => {
          if (projectType) {
            const projectsOfThing = this.entityManager.projectRepository
              .getByThingId(thing.id)
              .filter((p) => p.projectType === projectType);
            if (!projectsOfThing.length) return false;
          }
          return PermissionHelper.userCanEditOwnerUserGroupIdEntity(
            thing,
            currentUser,
            this.editableUserGroups
          );
        });
    } else {
      this.availableThings = [];
    }
  }

  private updateIdBindings(): void {
    if (this.thingId && this.thing) {
      this.setThingIdAndIgnoreChange(this.thing.id);
    }
  }

  private autoValidate(): void {
    if (!this.lastValidateResult) {
      this.validate();
    }
  }

  private setThingIdAndIgnoreChange(thingId: string | null): void {
    this.ignoreThingIdChanged = true;
    this.thingId = thingId;
    this.ignoreThingIdChanged = false;
  }

  private translate(key: string): string {
    return this.i18n.tr('inputComponents.thingAndProjectSelect.' + key);
  }

  protected projectFilterFunction(project: Project): boolean {
    return project.projectType !== ProjectType.GALLERY;
  }
}

type TThingAndProjectSelectElementValidateElementsResult = {
  elementToHighlight: HTMLElement | null;
  customSelect: CustomSelect<Thing, Thing> | ProjectSelect | null; // the customSelect instance of the invalid element
  valid: boolean;
};
