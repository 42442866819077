import { XmlImporterUtils } from './XmlImporterUtils';

/**
 * Examples of supported formats
 *
 * Simple nodes:
 * to mark nodes to be parsed, add an importer-label + an importer-data-type to the relevant nodes
 *
 *  <title>abc</title>
 *  <timestamp importer-label="Datum" importer-data-type="number"></timestamp>
 *  <timestamp2 importer-label="Datum 2" importer-data-type="number"></timestamp2>
 *
 * Arrays:
 *
 *  <gauges importer-is-array=""> <!-- mark the container as an array container with the importer-is-array attribute -->
 *    <gauge>
 *      <timestamp importer-label="Datum" importer-data-type="number"></timestamp>
 *      <timestamp2 importer-label="Datum 2" importer-data-type="number"></timestamp2>
 *    </gauge>
 *    <gauge>
 *      <timestamp importer-label="Datum" importer-data-type="number"></timestamp>
 *      <timestamp2 importer-label="Datum 2" importer-data-type="number"></timestamp2>
 *    </gauge>
 *  </gauges>
 *
 *
 * choice importer-data-type
 * a choice data-type has it's predefined choices as it's direct descendants as shown in the example below
 *
 *  <gauge>
 *    <unit importer-label="Einheit" importer-data-type="choice">
 *      <choice importer-user-value="$$USERVALUE$$">$$XMLVALUE$$</choice>
 *    </unit>
 *  </gauge>
 *
 * Attributes for xml tags in the schema file:
 *
 * name | description | available values
 * ----|-----------|--------
 * importer-label | label which is user readable | -
 * importer-data-type | data type to parse the value into | string, number, date, choice
 * importer-custom-id | custom id, will not be used by the parser itself, but can be used to analyse the result | -
 * importer-is-array | marks the element as an array, look at the example to see how the structure has to look like | -
 * importer-user-value | only supported by the choice element, represents the value which will get presented to the user | -
 * importer-order | custom order if the output should be sorted differently than the input, if this value is not given it's equal to 0 | -
 */

export class XmlSchemaParser {
  /**
   * attributes which indicate that this is a parent node and a new scope should be created
   * @type {string[]}
   */
  static IS_PARENT_NODE_ATTRIBUTES = ['importer-is-array'];

  /**
   * attributes which indicate that this is a schema node and a new scope should be created
   * @type {string[]}
   */
  static IS_NODE_ATTRIBUTES = ['importer-label'];

  constructor(schemaXmlString) {
    this._schemaXmlString = schemaXmlString;
  }

  /**
   *
   * @returns {TXmlSchemaParserParentNode}
   */
  parse() {
    /** @type {TXmlSchemaParserParentNode} */
    const rootNode = { children: [] };
    const xmlDocument = XmlImporterUtils.parseXmlString(this._schemaXmlString);
    this._processNodes(xmlDocument.getRootNode().childNodes, rootNode);

    return rootNode;
  }

  /**
   *
   * @param {NodeListOf<Node>} nodes
   * @param {TXmlSchemaParserParentNode} schemaParentNode
   * @private
   */
  _processNodes(nodes, schemaParentNode) {
    this._walkThroughElementsInNodeList(nodes, (element) => {
      this._processElement(element, schemaParentNode);
    });

    this._sortSchemaParserNodes(schemaParentNode.children);
  }

  /**
   *
   * @param {Element} element
   * @param {TXmlSchemaParserParentNode} schemaParentNode
   * @private
   */
  _processElement(element, schemaParentNode) {
    /** @type {(TXmlSchemaParserNode|TXmlSchemaParserParentNode)} */
    let schemaNode;

    if (this._isParentElement(element)) {
      schemaNode = this._createSchemaParentNodeForElement(element);
      this._processParentElementAttributes(element, schemaNode);
    } else if (this._isParserNode(element)) {
      schemaNode = this._createSchemaNodeForElement(element);
      this._processSchemaNodeElementAttributes(element, schemaNode);
    } else {
      this._processNodes(element.childNodes, schemaParentNode);
    }

    if (schemaNode) {
      schemaNode.path = XmlImporterUtils.getElementPath(element);
      schemaParentNode.children.push(schemaNode);
    }
  }

  /**
   *
   * @param {Element} element
   * @param {TXmlSchemaParserParentNode} schemaParentNode - the scope for the element itself
   * @private
   */
  _processParentElementAttributes(element, schemaParentNode) {
    this._processSchemaNodeElementAttributes(element, schemaParentNode);

    if (element.hasAttribute('importer-is-array')) {
      schemaParentNode.isArray = true;
    }

    this._processNodes(element.childNodes, schemaParentNode);
  }

  /**
   *
   * @param {Element} element
   * @param {TXmlSchemaParserNode} schemaNode - the scope for the element itself
   * @private
   */
  _processSchemaNodeElementAttributes(element, schemaNode) {
    const attributeConfig = [
      { attrName: 'importer-label', propertyName: 'label' },
      { attrName: 'importer-data-type', propertyName: 'dataType' },
      { attrName: 'importer-custom-id', propertyName: 'customId' },
      { attrName: 'importer-order', propertyName: 'order', isNumber: true }
    ];

    attributeConfig.forEach((item) => {
      if (element.hasAttribute(item.attrName)) {
        let attrValue = element.getAttribute(item.attrName);

        if (item.isNumber) {
          const numberValue = parseFloat(attrValue);
          attrValue = isNaN(numberValue) ? null : numberValue;
        }

        schemaNode[item.propertyName] = attrValue;
      }
    });

    this._processSchemaNodeSpecialDataTypes(element, schemaNode);
  }

  /**
   *
   * @param {Element} element
   * @param {TXmlSchemaParserNode} schemaNode - the scope for the element itself, already needs to have the dataType set!!
   * @private
   */
  _processSchemaNodeSpecialDataTypes(element, schemaNode) {
    if (schemaNode.dataType !== 'choice') {
      return;
    }

    const choices = [];

    this._walkThroughElementsInNodeList(element.childNodes, (childElement) => {
      if (childElement.tagName.toLowerCase() === 'choice') {
        const userValue = childElement.getAttribute('importer-user-value');
        const innerHtml = childElement.innerHTML;
        if (userValue && innerHtml) {
          choices.push({
            userValue: userValue,
            xmlValue: innerHtml
          });
        } else {
          console.log(
            '[XmlSchemaParser] choice node is malformed',
            childElement
          );
        }
      }
    });

    schemaNode.choices = choices;
  }

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   * @private
   */
  _isParentElement(element) {
    return this._elementHasOneOrMoreAttributes(
      element,
      XmlSchemaParser.IS_PARENT_NODE_ATTRIBUTES
    );
  }

  /**
   *
   * @param {Element} element
   * @returns {boolean}
   * @private
   */
  _isParserNode(element) {
    return this._elementHasOneOrMoreAttributes(
      element,
      XmlSchemaParser.IS_NODE_ATTRIBUTES
    );
  }

  /**
   * checks if the element has at least one attribute given in attributeNames
   *
   * @param {Element} element
   * @param {Array<string>} attributeNames
   * @returns {boolean}
   * @private
   */
  _elementHasOneOrMoreAttributes(element, attributeNames) {
    for (let key = 0; key < element.attributes.length; key++) {
      const attr = element.attributes[key];
      if (attr.specified && attributeNames.indexOf(attr.name) >= 0) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param {NodeListOf<Node>} nodes
   * @param {function(element: Element)} callback
   * @private
   */
  _walkThroughElementsInNodeList(nodes, callback) {
    for (let key = 0; key < nodes.length; key++) {
      const node = nodes[key];
      if (node instanceof Element) {
        callback(node);
      }
    }
  }

  /**
   *
   * @param {Element} element
   * @returns {TXmlSchemaParserParentNode}
   * @private
   */
  _createSchemaParentNodeForElement(element) {
    const node = this._createSchemaNodeForElement(element);
    node.children = [];
    return node;
  }

  /**
   *
   * @param {Element} element
   * @returns {TXmlSchemaParserNode}
   * @private
   */
  _createSchemaNodeForElement(element) {
    return {
      tagName: element.tagName.toLowerCase()
    };
  }

  /**
   *
   * @param {Array<TXmlSchemaParserNode>} nodes
   * @private
   */
  _sortSchemaParserNodes(nodes) {
    nodes.sort((a, b) => {
      const aOrder = a.order != null ? a.order : 0;
      const bOrder = b.order != null ? b.order : 0;
      return aOrder - bOrder;
    });
  }

  /**
   *
   * @param {(TXmlSchemaParserNode|TXmlSchemaParserParentNode)} node
   * @returns {boolean}
   */
  static isSchemaParserParentNode(node) {
    return !!node.children;
  }
}

/**
 * @typedef {Object} TXmlSchemaParserNode
 * @property {(string|null)} [label]
 * @property {(string|null)} [dataType] - available values 'string', 'number', 'date', 'choice'
 * @property {(string|null)} [customId]
 * @property {(number|null)} [order]
 * @property {Array<string>} [path] - all parent nodes tag names
 * @property {string} tagName
 * @property {(Array<TXmlSchemaParserNodeChoice>|null)} choices - only available if the dataType === 'choice'
 */

/**
 * @typedef {TXmlSchemaParserNode} TXmlSchemaParserParentNode
 * @property {Array<TXmlSchemaParserNode|TXmlSchemaParserParentNode>} children
 * @property {(boolean|null)} [isArray]
 */

/**
 * @typedef {Object} TXmlSchemaParserNodeChoice
 * @property {string} userValue
 * @property {string} xmlValue
 */
