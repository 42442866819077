import { autoinject, bindable } from 'aurelia-framework';
import { Vector } from 'common/Geometry/Vector';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { TooltipContent } from '../tooltip-content/tooltip-content';

/**
 * @slot default - for your custom content
 *
 * @event tooltip-content-open - fired when the tooltip content starts opening
 *
 * @attribute data-icon-style - styles the info-text the same as a record-it-icon (size-wise)
 * @attribute data-compact-style
 */
@autoinject()
export class InfoText {
  @bindable public infoText: string = '';

  @bindable public infoTextType = InfoTextType.GENERAL;

  /**
   * preserve linebreaks of the infoText
   */
  @bindable public preserveLineBreaks = false;

  @bindable public contentWidth: string | null = null;

  protected tooltipTargetAlignment = new Vector(0.5, 0);
  protected tooltipTooltipAlignment = new Vector(0.5, 1);

  protected readonly InfoTextType = InfoTextType;

  private tooltipContent: TooltipContent | null = null;
  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleLabelClick(event: MouseEvent): void {
    if (!this.tooltipContent) {
      return;
    }

    this.tooltipContent.ignoreClickEvent(event);
    if (!this.tooltipContent.isOpen()) {
      this.fireOpenEvent();
      this.tooltipContent.open();
    }
  }

  protected fireOpenEvent(): void {
    DomEventHelper.fireEvent<TooltipContentOpenEvent>(this.domElement, {
      name: 'tooltip-content-open',
      detail: null
    });
  }
}

export type TooltipContentOpenEvent = NamedCustomEvent<
  'tooltip-content-open',
  null
>;

export enum InfoTextType {
  GENERAL = 'general',
  WARNING = 'warning'
}
