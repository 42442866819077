import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessConfigurationFormSelect {
  @bindable()
  public value: string | null = null;

  /**
   * limits the selection to a certain processConfiguration
   */
  @bindable()
  public processConfigurationId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private availableForms: Array<ProcessConfigurationForm> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationForm,
      this.updateAvailableForms.bind(this)
    );
    this.updateAvailableForms();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationIdChanged(): void {
    if (this.isAttached) {
      this.updateAvailableForms();
    }
  }

  private updateAvailableForms(): void {
    if (this.processConfigurationId) {
      this.availableForms =
        this.entityManager.processConfigurationFormRepository.getByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this.availableForms =
        this.entityManager.processConfigurationFormRepository.getAll();
    }
  }

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent<ValueChangedEvent>(this.element, {
      name: 'value-changed',
      detail: null
    });
  }
}

export type ValueChangedEvent = NamedCustomEvent<'value-changed', null>;
