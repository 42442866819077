import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { PropertyToProcessTaskPositionDto } from './PropertyToProcessTaskPositionDto';

export function createPropertyToProcessTaskPositionEntityInfo<
  TEntity extends PropertyToProcessTaskPositionDto<string, string>
>(
  options: CreateEntityInfoOptions<
    PropertyToProcessTaskPositionDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.PropertyToProcessTaskPosition,
  TEntity
> {
  return {
    entityName: EntityName.PropertyToProcessTaskPosition,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PropertyToProcessTaskPositionDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        propertyId: {
          name: 'propertyId',
          ref: EntityName.Property,
          cascadeDeleteLocally: true
        },
        processTaskPositionId: {
          name: 'processTaskPositionId',
          ref: EntityName.ProcessTaskPosition,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
