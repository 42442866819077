import { autoinject, bindable } from 'aurelia-framework';

import { PersonCategories } from 'common/Types/PersonCategories';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class PersonByCategoryFilter {
  @bindable public persons: Array<Person> = [];

  @bindable public filteredPersons: Array<Person> = [];

  @bindable public currentCategoryFilterOption: string | null = null;

  protected categoryFilterOptions: Array<FilterOption> = [];

  private readonly subscriptionManager: SubscriptionManager;

  private personCategories: PersonCategories | null = [];

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.updateFilterOptions();
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.personCategories',
        (personCategories: PersonCategories | null) => {
          this.personCategories = personCategories;
          this.updateFilterOptions();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
  // ********** Change Handlers **********

  protected personsChanged(): void {
    this.updateFilteredPersons();
  }

  protected currentCategoryFilterOptionChanged(): void {
    this.updateFilteredPersons();
  }

  protected updateFilteredPersons(): void {
    if (!this.currentCategoryFilterOption) {
      this.filteredPersons = this.persons.slice();
      return;
    }

    this.filteredPersons = this.persons.filter(
      (p) => p.categoryName === this.currentCategoryFilterOption
    );
  }

  private updateFilterOptions(): void {
    this.categoryFilterOptions =
      this.personCategories?.map((c) => ({
        label: c.label,
        category: c.value
      })) ?? [];
  }
}

type FilterOption = {
  label: string;
  category: string;
};
