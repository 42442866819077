import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskOfferToProcessTask } from './types';

export class ProcessTaskOfferToProcessTaskRepository extends AppEntityRepository<EntityName.ProcessTaskOfferToProcessTask> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskOfferToProcessTask> {
    return this.getAll().filter(
      (e) => e.ownerProcessTaskGroupId === processTaskGroupId
    );
  }

  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskOfferToProcessTask> {
    return this.getAll().filter((e) => e.ownerProcessTaskId === processTaskId);
  }

  public getByProcessTaskIds(
    processTaskIds: Array<string>
  ): Array<ProcessTaskOfferToProcessTask> {
    return this.getAll().filter(
      (e) => processTaskIds.indexOf(e.ownerProcessTaskId) >= 0
    );
  }

  public getByProcessTaskOfferId(
    processTaskOfferId: string
  ): Array<ProcessTaskOfferToProcessTask> {
    return this.getAll().filter(
      (e) => e.processTaskOfferId === processTaskOfferId
    );
  }

  public getByProcessTaskOfferIds(
    processTaskOfferIds: Array<string>
  ): Array<ProcessTaskOfferToProcessTask> {
    return this.getAll().filter(
      (e) => processTaskOfferIds.indexOf(e.processTaskOfferId) >= 0
    );
  }
}
