import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';

import { StructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import {
  ChoiceAddedEvent,
  ChoiceRemovedEvent
} from '../../inputComponents/text-choice-widget/text-choice-widget';

@autoinject()
export class StructureTemplateExcludeFromFlawNumbersWidget {
  @bindable()
  public structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];

  @bindable() public structureTemplate: StructureTemplate | null = null;

  protected selectedPropertyToAdd: StructureTemplateEntryProperty | null = null;

  private propertiesToShow: Array<StructureTemplateEntryProperty> = [];

  constructor(private readonly entityManager: AppEntityManager) {}

  @computedFrom('structureTemplateEntryProperties', 'propertiesToShow.length')
  protected get parameterSelectOptions(): Array<StructureTemplateEntryProperty> {
    return this.structureTemplateEntryProperties.filter((obj1) => {
      const safeType = PropertyHelper.getTypeOrDefault(obj1.type);
      return (
        !this.propertiesToShow.some((obj2) => obj2.id === obj1.id) &&
        PropertyHelper.propertyTypeHasEditableValue(safeType) &&
        PropertyHelper.propertyTypeHasPrimitiveRepresentation(safeType)
      );
    });
  }

  @computedFrom(
    'structureTemplateEntryProperties.length',
    'propertiesToShow.length'
  )
  protected get canAddNewConfig(): boolean {
    return (
      this.structureTemplateEntryProperties.length !==
      this.propertiesToShow.length
    );
  }

  protected getAvailableTagsForProperty(
    p: StructureTemplateEntryProperty
  ): Array<string> {
    if (PropertyHelper.propertyTypeNeedsChoices(p.type)) {
      return p.choices.filter(
        (obj1) =>
          !p.excludeFromFlawNumberComputationForValues?.some(
            (obj2) => obj2 === obj1
          )
      );
    }

    if (p.type === PropertyType.CHECKBOX) {
      return [
        PropertyHelper.UNCHECKED_BOX_CHAR,
        PropertyHelper.CHECKED_BOX_CHAR
      ];
    }

    return [];
  }

  protected handleDeleteButtonClicked(p: StructureTemplateEntryProperty): void {
    const index = this.propertiesToShow.indexOf(p);
    if (index !== -1) {
      this.propertiesToShow.splice(index, 1);
      p.excludeFromFlawNumberComputationForValues = [];
      this.entityManager.structureTemplateEntryPropertyRepository.update(p);
    }
  }

  protected handleStructureTemplateChanged(): void {
    if (!this.structureTemplate) return;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected structureTemplateEntryPropertiesChanged(): void {
    this.propertiesToShow = this.structureTemplateEntryProperties.filter(
      (p) => p.excludeFromFlawNumberComputationForValues?.length
    );
  }

  protected handleAddPropertyConfigurationButtonClicked(): void {
    if (!this.selectedPropertyToAdd) return;
    this.propertiesToShow.push(this.selectedPropertyToAdd);
    this.selectedPropertyToAdd = null;
  }

  protected handleValueTagCreated(
    event: ChoiceAddedEvent,
    p: StructureTemplateEntryProperty
  ): void {
    if (!p.excludeFromFlawNumberComputationForValues) return;
    ArrayUtils.pushUnique(
      p.excludeFromFlawNumberComputationForValues,
      event.detail.choice
    );
    this.entityManager.structureTemplateEntryPropertyRepository.update(p);
  }

  protected handleValueTagRemoved(
    event: ChoiceRemovedEvent,
    p: StructureTemplateEntryProperty
  ): void {
    if (!p.excludeFromFlawNumberComputationForValues) return;
    ArrayUtils.remove(
      p.excludeFromFlawNumberComputationForValues,
      event.detail.choice
    );
    this.entityManager.structureTemplateEntryPropertyRepository.update(p);
  }
}
