import 'animate.css/animate.css'; // TODO: maybe get rid of this

import '../styles/app.css';
import '../styles/custom.css';

import '../styles/record-it-safe-area-inset-variables.css';

import '../styles/record-it-colors.css';
import '../styles/record-it.css';
import '../styles/record-it-icon.css';
import '../styles/record-it-entity-list.css';
import '../styles/record-it-sidebar.css';
import '../styles/record-it-buttons.css';
import '../styles/record-it-marking.css';
import '../styles/record-it-message-box.css';
import '../styles/record-it-input.css';
import '../styles/record-it-filter.css';
import '../styles/record-it-card.css';
import '../styles/record-it-radiobutton.css';
import '../styles/record-it-operations.css';
import '../styles/record-it-select-sub-buttons.css';
import '../styles/record-it-centered-picture.css';
import '../styles/record-it-custom-select.css';
import '../styles/record-it-expandable-button.css';
import '../styles/record-it-picture-selected-icon.css';
import '../styles/record-it-request-status.css';

import { I18N, TCustomAttribute } from 'aurelia-i18n';
import Backend from 'i18next-http-backend';

import { App as CapacitorApp } from '@capacitor/app';
import { StatusBar } from '@capacitor/status-bar';

import { DataStorageHelper } from './classes/DataStorageHelper/DataStorageHelper';
import { DeviceInfoHelper } from './classes/DeviceInfoHelper';
import { OpenWithHelper } from './classes/OpenWithHelper';
import { CoordinateHelper } from './classes/CoordinateHelper';
import { ThemingService } from './services/ThemingService';
import { FeatureControl } from './config/FeatureControl';
import { PLATFORM } from 'aurelia-pal';
import { Aurelia } from 'aurelia-framework';
import { SessionService } from './services/SessionService/SessionService';
import { UrlManager } from './classes/UrlManager';
import { MainPageLoaderHelper } from './classes/MainPageLoaderHelper';
import { ProcessTaskGroupFilterSettingsService } from './services/ProcessTaskGroupFilterSettingsService';
import { init as initEntityManagerEvironment } from './classes/EntityManager/entities/environment';
import { GalleryThingJoinedProjectsService } from './services/GalleryThingJoinedProjectsService';
import { SQLiteDataStorageStrategy } from './classes/DataStorageHelper/SQLiteDataStorageStrategy/SQLiteDataStorageStrategy';
import { IndexedDBDataStorageStrategy } from './classes/DataStorageHelper/IndexedDBDataStorageStrategy/IndexedDBDataStorageStrategy';
import { OperationsCalendarSettingsService } from './services/OperationsCalendarSettingsService/OperationsCalendarSettingsService';
import { NFCHelper } from './classes/Nfc/NFCHelper';
import { ProcessTaskToProjectAutoJoinProjectsService } from './classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectAutoJoinProjectsService';
import { DeveloperCommandCenter } from './classes/DeveloperCommand/DeveloperCommandCenter';
import { SocketService } from './services/SocketService';
import { TranslationUtils } from './classes/TranslationUtils';
import { LastOpenedProcessTaskGroupsService } from './services/LastOpenedProcessTaskGroupsService/LastOpenedProcessTaskGroupsService';
import { setupCustomFormatters } from './i18next/formatter/setupCustomFormatters';
import { Logger } from './classes/Logger/Logger';
import { SentryAdapter } from './classes/Logger/adapter/SentryAdapter/SentryAdapter';
import { HistoryService } from './services/HistoryService/HistoryService';
import { EmbeddedCameraPictureCaptureService } from './services/EmbeddedCameraPictureCaptureService/EmbeddedCameraPictureCaptureService';
import { environment } from './environment';
import { SwitchLanguageService } from './services/SwitchLanguageService/SwitchLanguageService';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AppRouter } from 'aurelia-router';
import { FileUtils } from './classes/Utils/FileUtils/FileUtils';
import { CapacitorAdapter } from './classes/Utils/FileUtils/adapter/CapacitorAdapter/CapacitorAdapter';
import { WebAdapter } from './classes/Utils/FileUtils/adapter/WebAdapter/WebAdapter';
import { ProcessConfigurationPicturesService } from './services/ProcessConfigurationPicturesService/ProcessConfigurationPicturesService';
import { ProcessTaskGroupPicturesService } from './services/ProcessTaskGroupPicturesService/ProcessTaskGroupPicturesService';
import { initStorageAdapter } from './initStorageAdapter';
import { ThumbnailFileService } from './services/ThumbnailFileService/ThumbnailFileService';
import { OperationsFieldUseCalendarSettingsService } from './services/OperationsFieldUseCalendarSettingsService/OperationsFieldUseCalendarSettingsService';
import { ActualizationOverlayService } from './services/ActualizationOverlayService/ActualizationOverlayService';
import { BiometricAuthenticationService } from './services/BiometricAuthenticationService/BiometricAuthenticationService';

export async function configure(aurelia: Aurelia): Promise<void> {
  try {
    Logger.init(new SentryAdapter());
    await configureAurelia(aurelia);
  } catch (error) {
    Logger.logError({ error });
    MainPageLoaderHelper.setInitializationStep(
      `an error occured: ${String(error)}`
    );
    throw error;
  }
}

async function configureAurelia(aurelia: Aurelia): Promise<void> {
  if (DeviceInfoHelper.isApp()) await configureApp();

  MainPageLoaderHelper.setInitializationStep('initializing FileUtils');
  if (DeviceInfoHelper.isApp()) {
    FileUtils.init(new CapacitorAdapter());
  } else {
    FileUtils.init(new WebAdapter());
  }

  MainPageLoaderHelper.setInitializationStep('initializing data storage');
  if (DeviceInfoHelper.isApp()) {
    await DataStorageHelper.init(new SQLiteDataStorageStrategy());
  } else {
    await DataStorageHelper.init(new IndexedDBDataStorageStrategy());
  }

  MainPageLoaderHelper.setInitializationStep('migrating data storage');
  await DataStorageHelper.migrate();

  await UrlManager.init();

  if (DeviceInfoHelper.isApp()) {
    await FileUtils.ensureCapturedPicturesFolder();
  }

  MainPageLoaderHelper.setInitializationStep('configuring aurelia');

  aurelia.use
    .standardConfiguration()
    .plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance: I18N) => {
      const aliases = ['t', 'i18n'];
      TCustomAttribute.configureAliases(aliases);
      instance.i18next.use(Backend);
      await instance.setup({
        backend: {
          loadPath: TranslationUtils.createLoadPathFunction(),
          allowMultiLoading: false, // the backend can only deliver one element at a time
          load: 'languageOnly'
        },
        attributes: aliases,
        lng: 'de',
        fallbackLng: 'de',
        /*
         * Do not set supportedLngs! We use custom languages for our custom translations ("en-userCompanySettingId"), they stop working if supportedLngs is set.
         */
        // supportedLngs: ['de', 'en'],
        skipTranslationOnMissingKey: true,
        interpolation: {
          escapeValue: false // let aurelia handle the escaping to prevent double escaping, dangerous when using e.g. `element.innerHtml = i18n.tr('key', { variable: '<script>' })`
        },
        compatibilityJSON: 'v3'
      });

      const router = aurelia.container.get(AppRouter);
      const eventAggregator = aurelia.container.get(EventAggregator);

      router.transformTitle = (title: string) =>
        'recordIT | ' + instance.tr(title);
      eventAggregator.subscribe('i18n:locale:changed', () => {
        router.updateTitle();
      });

      setupCustomFormatters(instance.i18next);
    });

  if (environment.env !== 'prod') {
    aurelia.use.developmentLogging();
  }

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin('aurelia-animator-css');
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin('aurelia-html-import-template-loader')

  MainPageLoaderHelper.setInitializationStep('initializing OpenWith');
  void OpenWithHelper.init();

  MainPageLoaderHelper.setInitializationStep('initializing NFC');
  void NFCHelper.init();

  MainPageLoaderHelper.setInitializationStep('intializing StorageAdapter');
  initStorageAdapter();

  const sessionService: SessionService = aurelia.container.get(SessionService);
  MainPageLoaderHelper.setInitializationStep('intializing SessionService');
  await sessionService.initialize();

  await initEntityManagerEvironment(aurelia.container, (className) => {
    MainPageLoaderHelper.setInitializationStep(`initializing ${className}`);
  });

  const biometricAuthenticationService = aurelia.container.get(
    BiometricAuthenticationService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing BiometricAuthenticationService'
  );
  await biometricAuthenticationService.init();

  const historyService = aurelia.container.get(HistoryService);
  MainPageLoaderHelper.setInitializationStep('intializing HistoryService');
  historyService.init();

  const galleryThingJoinedProjectsService: GalleryThingJoinedProjectsService =
    aurelia.container.get(GalleryThingJoinedProjectsService);
  MainPageLoaderHelper.setInitializationStep(
    'initializing GalleryThingJoinedProjectsService'
  );
  await galleryThingJoinedProjectsService.init();

  // load the last setting as early as possible so we have minimal "unbranded" time
  const themingService: ThemingService = aurelia.container.get(ThemingService);
  MainPageLoaderHelper.setInitializationStep('restoring last theme');
  await themingService.loadThemeFromDisk();

  // the timeout here is necessary since our initialization code is blocking the browser so much that without this the browser wouldn't be able to animate
  // the transition for the loading overlay
  // transition is defined in the record-it.css/#main-page-loader
  if (FeatureControl.NICE_THEME_TRANSITION) {
    MainPageLoaderHelper.setInitializationStep('waiting for theme transition');
    await new Promise((res) => setTimeout(res, 410));
  }
  MainPageLoaderHelper.setInitializationStep(
    'start watching active UserCompanySetting'
  );
  await themingService.loadThemeFromActiveUserCompanySettings();

  const thumbnailFileService = aurelia.container.get(ThumbnailFileService);
  MainPageLoaderHelper.setInitializationStep(
    'initializing ThumbnailFileService'
  );
  await thumbnailFileService.init();

  const processTaskGroupFilterSettingsService: ProcessTaskGroupFilterSettingsService =
    aurelia.container.get(ProcessTaskGroupFilterSettingsService);
  MainPageLoaderHelper.setInitializationStep(
    'initializing ProcessTaskGroupFilterSettingsService'
  );
  await processTaskGroupFilterSettingsService.init();

  const operationsCalendarSettingsService: OperationsCalendarSettingsService =
    aurelia.container.get(OperationsCalendarSettingsService);
  MainPageLoaderHelper.setInitializationStep(
    'initializing OperationsCalendarSettingsService'
  );
  await operationsCalendarSettingsService.init();

  const operationsFieldUseCalendarSettingsService: OperationsFieldUseCalendarSettingsService =
    aurelia.container.get(OperationsFieldUseCalendarSettingsService);
  MainPageLoaderHelper.setInitializationStep(
    'initializing OperationsFieldUseCalendarSettingsService'
  );
  await operationsFieldUseCalendarSettingsService.init();

  const lastOpenedProcessTaskGroupsService = aurelia.container.get(
    LastOpenedProcessTaskGroupsService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing LastOpenedProcessTaskGroupsService'
  );
  await lastOpenedProcessTaskGroupsService.init();

  const processConfigurationPicturesService = aurelia.container.get(
    ProcessConfigurationPicturesService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing ProcessConfigurationPicturesService'
  );
  await processConfigurationPicturesService.init();

  const processTaskGroupPicturesService = aurelia.container.get(
    ProcessTaskGroupPicturesService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing ProcessTaskGroupPicturesService'
  );
  await processTaskGroupPicturesService.init();

  const actualizationOverlayService = aurelia.container.get(
    ActualizationOverlayService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing ActualizationOverlayService'
  );
  actualizationOverlayService.init();

  await initUrlRestoration();

  MainPageLoaderHelper.setInitializationStep('starting aurelia');
  await aurelia.start();

  MainPageLoaderHelper.setInitializationStep('starting switchLanguageService');
  const switchLanguageService = aurelia.container.get(SwitchLanguageService);
  switchLanguageService.init();

  MainPageLoaderHelper.setInitializationStep('initializing CoordinateHelper');
  await CoordinateHelper.init();

  const processTaskToProjectAutoJoinProjectsService = aurelia.container.get(
    ProcessTaskToProjectAutoJoinProjectsService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing ProcessTaskToProjectAutoJoinProjectsService'
  );
  await processTaskToProjectAutoJoinProjectsService.init();

  const embeddedCameraPictureCaptureService = aurelia.container.get(
    EmbeddedCameraPictureCaptureService
  );
  MainPageLoaderHelper.setInitializationStep(
    'initializing EmbeddedCameraPictureCaptureService'
  );
  await embeddedCameraPictureCaptureService.init();

  (window as any).developerCommandCenter = new DeveloperCommandCenter(
    aurelia.container.get(SocketService)
  );

  MainPageLoaderHelper.setInitializationStep('loading web token');
  const webToken = await DataStorageHelper.getItem('web_token');
  if (webToken) {
    MainPageLoaderHelper.setInitializationStep('set app as aurelia root');
    await aurelia.setRoot(PLATFORM.moduleName('pageRoots/app/app'));
  } else {
    MainPageLoaderHelper.setInitializationStep('set login as aurelia root');
    await aurelia.setRoot(PLATFORM.moduleName('pageRoots/login/login'));
  }

  OpenWithHelper.aureliaStarted();
}

async function initUrlRestoration(): Promise<void> {
  if (!DeviceInfoHelper.isApp()) {
    return;
  }

  await DataStorageHelper.getItem('app_lastRouteFragment').then(
    (lastRouteFragment) => {
      if (lastRouteFragment) {
        window.location.hash = lastRouteFragment;
      }
    }
  );

  void CapacitorApp.addListener('pause', () => {
    const hash = window.location.hash;
    void DataStorageHelper.setItem(
      'app_lastRouteFragment',
      hash && hash[0] == '#' ? hash.substr(1) : hash
    );
  });
}

async function configureApp(): Promise<void> {
  MainPageLoaderHelper.setInitializationStep('waiting for device ready');
  await DeviceInfoHelper.waitForDeviceReady();

  if (DeviceInfoHelper.isAndroidDevice()) {
    await DeviceInfoHelper.calculateStatusBarHeight();

    void StatusBar.setOverlaysWebView({
      overlay: true
    });
  }

  DeviceInfoHelper.updateAndroidSafeAreaInsets();
}
