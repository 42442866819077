import { bindable, autoinject } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';
import {
  MarkingSetEvent,
  PictureMarkingOverlay
} from '../../picture/picture-marking-overlay/picture-marking-overlay';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';

/**
 * @event marking-changed
 */
@autoinject()
export class PictureMarkingWidget {
  @bindable public picture: Picture | null = null;

  @bindable public parentPicture: Picture | null = null;

  @bindable public markings: Array<IPictureMarking> = [];

  @bindable public editable = false;

  protected domElement: HTMLElement;

  protected markingOverlay: PictureMarkingOverlay | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleClickOnEditMarking(): void {
    assertNotNullOrUndefined(
      this.markingOverlay,
      "can't edit marking without a markingOverlay"
    );
    this.markingOverlay.open(true);
  }

  protected handleClickOnRemoveMarking(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't delete marking without a picture"
    );
    this.picture.marking = null;
    this.fireMarkingChanged();
  }

  protected handlePreviewPictureClick(): void {
    assertNotNullOrUndefined(
      this.markingOverlay,
      "can't preview picture without a markingOverlay"
    );
    this.markingOverlay.open(false);
  }

  protected handleMarkingSet(event: MarkingSetEvent): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't handle the marking set without a picture"
    );
    const marking = event.detail.parentPictureMarking;

    this.picture.marking = {
      left: marking.left,
      top: marking.top,
      boxBottom: marking.boxBottom,
      boxLeft: marking.boxLeft,
      boxRight: marking.boxRight,
      boxTop: marking.boxTop
    };
    this.fireMarkingChanged();
  }

  protected fireMarkingChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'marking-changed',
      bubbles: true,
      detail: null
    });
  }
}
