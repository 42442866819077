import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskMeasurePointReadingEntityInfo } from 'common/Types/Entities/ProcessTaskMeasurePointReading/createProcessTaskMeasurePointReadingEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskMeasurePointReading } from './types';

export const processTaskMeasurePointReadingEntityInfo =
  createProcessTaskMeasurePointReadingEntityInfo<ProcessTaskMeasurePointReading>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
