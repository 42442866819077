import { OperationsDataFetcher as CommonOperationsDataFetcher } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsDataFetcher';

import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { Person } from '../EntityManager/entities/Person/types';
import { ProcessTask } from '../EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../EntityManager/entities/ProcessTaskGroup/types';
import { ThingGroup } from '../EntityManager/entities/ThingGroup/types';
import { Thing } from '../EntityManager/entities/Thing/types';
import { ProcessTaskAppointment } from '../EntityManager/entities/ProcessTaskAppointment/types';
import { ThingToPerson } from '../EntityManager/entities/ThingToPerson/types';
import { ProcessTaskRecurringAppointment } from '../EntityManager/entities/ProcessTaskRecurringAppointment/types';

export class OperationsDataFetcher
  implements CommonOperationsDataFetcher<string, string>
{
  constructor(private readonly entityManager: AppEntityManager) {}

  public async getThingGroup(thingGroupId: string): Promise<ThingGroup | null> {
    return this.entityManager.thingGroupRepository.getById(thingGroupId);
  }

  public async getProcessTaskGroup(
    processTaskGroupId: string
  ): Promise<ProcessTaskGroup | null> {
    return this.entityManager.processTaskGroupRepository.getById(
      processTaskGroupId
    );
  }

  public async getProcessTask(
    processTaskId: string
  ): Promise<ProcessTask | null> {
    return this.entityManager.processTaskRepository.getById(processTaskId);
  }

  public async getPerson(personId: string): Promise<Person | null> {
    return this.entityManager.personRepository.getById(personId);
  }

  public async getThing(thingId: string): Promise<Thing | null> {
    return this.entityManager.thingRepository.getById(thingId);
  }

  public async getProcessTaskAppointment(
    processTaskAppointmentId: string
  ): Promise<ProcessTaskAppointment | null> {
    return this.entityManager.processTaskAppointmentRepository.getById(
      processTaskAppointmentId
    );
  }

  public async getProcessTaskRecurringAppointment(
    processTaskRecurringAppointmentId: string
  ): Promise<ProcessTaskRecurringAppointment | null> {
    return this.entityManager.processTaskRecurringAppointmentRepository.getById(
      processTaskRecurringAppointmentId
    );
  }

  public async getThingToPersonsByThingId(
    thingId: string
  ): Promise<Array<ThingToPerson>> {
    return this.entityManager.thingToPersonRepository.getByThingId(thingId);
  }
}
