/**
 * a class which helps with queueing items and processing them later on to keep the UI responsive
 * this is only useful for time insensitive operations, like rendering something for the user
 */
export class DelayedQueue {
  private static defaultOptions = {
    chunkSize: 10,
    timeout: 50
  };

  private items: Array<any> = [];
  private options: Options;
  private queueInProgress: boolean = false;
  private processItemsTimeout: number | null = null;

  constructor(options: Options) {
    this.options = Object.assign({}, DelayedQueue.defaultOptions, options);
  }

  public queue(item: any): void {
    this.items.push(item);
    this.startProcessItemsLoop();
  }

  /**
   * will queue the item at the index given by the offset, if the offset = 0, the item will be queued in front of everything else
   * useful if you need to queue something with priority
   */
  public queueInFront(item: any, offset: number = 0): void {
    if (offset < this.items.length) {
      this.items.splice(offset, 0, item);
    } else {
      this.items.push(item);
    }

    this.startProcessItemsLoop();
  }

  public remove(item: any): void {
    // item can be multiple times in the queue and we want to remove all of them
    while (true) {
      const index = this.items.indexOf(item);

      if (index >= 0) {
        this.items.splice(index, 1);
      } else {
        break;
      }
    }
  }

  public flush(): void {
    while (this.items.length) {
      this.workNextItem();
    }

    this.clear();
  }

  public clear(): void {
    this.items.length = 0;
    this.queueInProgress = false;
    if (this.processItemsTimeout) window.clearTimeout(this.processItemsTimeout);
  }

  private startProcessItemsLoop(): void {
    if (!this.queueInProgress) {
      this.startProcessItemsTimeout();
      this.queueInProgress = true;
    }
  }

  private startProcessItemsTimeout(): void {
    this.processItemsTimeout = window.setTimeout(() => {
      this.processItems();
    }, this.options.timeout);
  }

  private processItems(): void {
    this.workNextItems();

    // the queueInProgress check here is only here in case the worker triggers a flush/clear
    if (this.items.length && this.queueInProgress) {
      this.startProcessItemsTimeout();
    } else {
      this.queueInProgress = false;
      this.processItemsTimeout = null;
    }
  }

  private workNextItems(): void {
    for (let key = 0; key < this.options.chunkSize; key++) {
      this.workNextItem();
    }
  }

  private workNextItem(): void {
    if (this.items.length === 0) {
      return;
    }

    try {
      this.options.workerFunction(this.items.shift());
    } catch (e) {
      console.error(e);
    }
  }
}

type Options = {
  /** gets called for each item which gets unqueued */
  workerFunction: (item: any) => any;

  /** amount of items which should be processed per timeout */
  chunkSize: number;

  /** wait time between working on the chunks */
  timeout: number;
};
