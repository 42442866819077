import { autoinject, bindable } from 'aurelia-framework';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TextBrickTemplate } from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PathChangedEvent } from '../../structureThing/structure-thing-path-edit-widget/structure-thing-path-edit-widget';

@autoinject()
export class TextBrickTemplateListItem {
  @bindable public textBrickTemplate: TextBrickTemplate | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly textBrickTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.TextBrickTemplate];

  protected isMobile: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.textBrickTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.TextBrickTemplate,
        context: this as TextBrickTemplateListItem,
        propertyName: 'textBrickTemplate'
      });
  }

  // Aurelia Lifecycle

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // Handlers

  protected handleTextBrickTemplateChanged(): void {
    if (!this.textBrickTemplate) return;
    this.entityManager.textBrickTemplateRepository.update(
      this.textBrickTemplate
    );
  }

  protected handleDeleteTextBrickTemplateClicked(): void {
    const textBrickTemplate = this.textBrickTemplate;
    if (!textBrickTemplate) return;

    void Dialogs.deleteEntityDialog(textBrickTemplate).then(() => {
      this.entityManager.textBrickTemplateRepository.delete(textBrickTemplate);
    });
  }

  protected handleTextBrickTemplatePathChanged(event: PathChangedEvent): void {
    if (!this.textBrickTemplate) return;

    this.textBrickTemplate.path = event.detail.path;
    this.handleTextBrickTemplateChanged();
  }
}
