import { autoinject, bindable } from 'aurelia-framework';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { TooltipContent } from '../tooltip-content/tooltip-content';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ModuleName,
  RecordItModuleHelper
} from '../../classes/RecordItModuleHelper';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { computed } from '../../hooks/computed';
import { currentUser, expression } from '../../hooks/dependencies';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { Router } from 'aurelia-router';
import { projectTypesOnObjectPage } from 'common/Types/Entities/Project/ProjectDto';
import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';

/**
 * Allows the user to switch between different object views
 * (project view, gallery view, checklist view, ...)
 *
 * Which views are available are automatically determined based on the user.
 */
@autoinject()
export class SwitchThingTypeButton {
  @bindable() public thing: Thing | null = null;

  @bindable() public currentType: ThingViewType | null = null;

  /** read-only */
  @bindable public buttonIsShown = false;

  protected buttonElement: HTMLElement | null = null;

  protected tooltipViewModel: TooltipContent | null = null;

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router
  ) {}

  protected currentTypeChanged(): void {
    if (
      this.availableObjectTypes.length === 0 &&
      this.thing &&
      this.currentType
    ) {
      this.navigateToRoute(
        thingViewTypeToThingViewListItemMap[this.currentType].href,
        this.thing.id
      );
    }
  }

  @computed(currentUser())
  private get currentUser(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  @computed(expression('currentUser'), currentUser())
  private get currentUserCanViewObjectPage(): boolean {
    if (!this.currentUser) return false;
    return PermissionHelper.getAvailableModulesForUser(this.currentUser)
      .map((moduleName) =>
        RecordItModuleHelper.getProjectTypeForModuleName(moduleName)
      )
      .some(
        (projectType) =>
          projectType && projectTypesOnObjectPage().includes(projectType)
      );
  }

  @computed(
    expression('currentType'),
    expression('thing'),
    expression('currentUserCanViewObjectPage'),
    expression('currentUser'),
    currentUser()
  )
  protected get availableObjectTypes(): Array<ThingTypeListItem> {
    if (!this.thing) return [];
    const types: Array<ThingTypeListItem> = [];

    if (
      this.currentType !== ThingViewType.THING &&
      this.currentUserCanViewObjectPage
    ) {
      types.push(thingViewTypeToThingViewListItemMap[ThingViewType.THING]);
    }

    if (
      this.currentType !== ThingViewType.GALLERY &&
      PermissionHelper.userHasPermissionForModule(
        this.currentUser,
        ModuleName.VIA
      )
    ) {
      types.push(thingViewTypeToThingViewListItemMap[ThingViewType.GALLERY]);
    }

    if (
      this.currentType !== ThingViewType.CHECKLIST &&
      PermissionHelper.userHasPermission(this.currentUser, 'canUseChecklist')
    ) {
      types.push(thingViewTypeToThingViewListItemMap[ThingViewType.CHECKLIST]);
    }

    if (
      this.currentType !== ThingViewType.KUK &&
      PermissionHelper.userHasPermission(this.currentUser, 'canUseKuK')
    ) {
      types.push(thingViewTypeToThingViewListItemMap[ThingViewType.KUK]);
    }

    this.buttonIsShown = types.length > 0;

    return types;
  }

  /**
   * If there's only one object type available, we don't need to show the dropdown
   * and can instead navigate directly to that one option upon clicking the main button
   * as well as showing the icon of the option on the main button
   */
  @computed(expression('availableObjectTypes'))
  protected get mainButton(): Icon {
    if (this.availableObjectTypes.length === 1) {
      return this.availableObjectTypes[0]!.icon;
    } else {
      return { type: 'fa-solid', name: 'fa-eye' };
    }
  }

  /**
   * If there's only one object type, directly navigate to it
   */
  protected handleButtonClick(): void {
    assertNotNullOrUndefined(
      this.tooltipViewModel,
      'cannot handleButtonClick without tooltipViewModel'
    );

    if (this.availableObjectTypes.length === 1 && this.thing) {
      this.navigateToRoute(this.availableObjectTypes[0]!.href, this.thing.id);
    } else {
      this.tooltipViewModel.open();
    }
  }

  private navigateToRoute(routeHref: string, thingId: string): void {
    this.router.navigateToRoute(routeHref, { thing_id: thingId });
    void DataStorageHelper.setItem('lastThingRoute', routeHref);
  }
}

type Icon = {
  type: string;
  name: string;
};

type ThingTypeListItem = {
  name: string;
  icon: Icon;
  href: string;
};

export enum ThingViewType {
  THING = 'thing',
  GALLERY = 'gallery',
  CHECKLIST = 'checklist',
  KUK = 'kuk'
}

const thingViewTypeToThingViewListItemMap = createTypeExtendsValidator<
  Record<ThingViewType, ThingTypeListItem>
>()({
  [ThingViewType.THING]: {
    name: 'aureliaComponents.switchThingTypeButton.views.thing',
    icon: { type: 'custom', name: 'object' },
    href: 'object'
  },
  [ThingViewType.GALLERY]: {
    name: 'aureliaComponents.switchThingTypeButton.views.gallery',
    icon: { type: 'fa-regular', name: 'fa-road' },
    href: 'edit_gallery_object'
  },
  [ThingViewType.CHECKLIST]: {
    name: 'aureliaComponents.switchThingTypeButton.views.checklist',
    icon: { type: 'fa-solid', name: 'fa-hotel' },
    href: 'edit_checklist_object'
  },
  [ThingViewType.KUK]: {
    name: 'aureliaComponents.switchThingTypeButton.views.kuk',
    icon: { type: 'fa-solid', name: 'fa-hill-avalanche' },
    href: 'edit_catastrophe'
  }
});
