/**
 * A map which contains pending promises. If the promises get resolved/rejected they will be removed automatically.
 *
 * This is useful for e.g. managing HTTP request to prevent multiple simultaneous requests for the same resource
 */
export class PendingPromiseMap<TKey, T> {
  private map: Map<TKey, Promise<T>> = new Map();

  public set(key: TKey, promise: Promise<T>): void {
    this.map.set(key, promise);

    void promise.finally(() => {
      if (this.get(key) === promise) {
        this.delete(key);
      }
    });
  }

  public delete(key: TKey): void {
    this.map.delete(key);
  }

  public clear(): void {
    this.map.clear();
  }

  public getOrCreate(key: TKey, factory: () => Promise<T>): Promise<T> {
    let promise = this.get(key);

    if (!promise) {
      promise = factory();
      this.set(key, promise);
    }

    return promise;
  }

  public get(key: TKey): Promise<T> | null {
    return this.map.get(key) ?? null;
  }

  public has(key: TKey): boolean {
    return this.map.has(key);
  }

  public get size(): number {
    return this.map.size;
  }
}
