import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructurePictureArea } from '../../classes/EntityManager/entities/StructurePictureArea/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event {StructurePictureAreaCollapsibleEditBarSizeChangedEvent} size-changed
 * @event {StructurePictureAreaCollapsibleEditBarDeletedEvent} structure-picture-area-deleted
 */
@autoinject()
export class StructurePictureAreaCollapsibleEditBar {
  @bindable()
  public structurePictureArea: StructurePictureArea | null = null;

  @bindable()
  public expanded: boolean = false;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.StructurePictureArea];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.StructurePictureArea,
        context: this as StructurePictureAreaCollapsibleEditBar,
        propertyName: 'structurePictureArea'
      }
    );
  }

  protected handleTransitionend(event: TransitionEvent): void {
    if (event.target === this.element && event.propertyName === 'max-height') {
      DomEventHelper.fireEvent<StructurePictureAreaCollapsibleEditBarSizeChangedEvent>(
        this.element,
        {
          name: 'size-changed',
          detail: null
        }
      );
    }
  }

  protected handleStructurePictureAreaChanged(): void {
    assertNotNullOrUndefined(
      this.structurePictureArea,
      "can't StructurePictureAreaCollapsibleEditBar.handleStructurePictureAreaChanged without structurePictureArea"
    );

    this.entityManager.structurePictureAreaRepository.update(
      this.structurePictureArea
    );
  }

  protected handleDeleteClick(): void {
    const structurePictureArea = this.structurePictureArea;
    assertNotNullOrUndefined(
      structurePictureArea,
      "can't StructurePictureAreaCollapsibleEditBar.handleDeleteClick without structurePictureArea"
    );

    void Dialogs.deleteEntityDialog(
      structurePictureArea,
      EntityName.StructurePictureArea
    ).then(() => {
      this.entityManager.structurePictureAreaRepository.delete(
        structurePictureArea
      );

      DomEventHelper.fireEvent<StructurePictureAreaCollapsibleEditBarDeletedEvent>(
        this.element,
        {
          name: 'structure-picture-area-deleted',
          detail: null
        }
      );
    });
  }
}

export type StructurePictureAreaCollapsibleEditBarSizeChangedEvent =
  NamedCustomEvent<'size-changed', null>;
export type StructurePictureAreaCollapsibleEditBarDeletedEvent =
  NamedCustomEvent<'structure-picture-area-deleted', null>;
