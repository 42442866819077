import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import { ThingGroupHelper } from '../../../../../common/src/EntityHelper/ThingGroupHelper';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { ProcessTaskUtils } from '../../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ThingGroup } from '../../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

/**
 * @event {EditProcessTaskAppointmentDialogProcessTaskInfoNavigationTriggeredEvent} navigation-triggered
 */
@autoinject()
export class EditProcessTaskAppointmentDialogProcessTaskInfo {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private thingGroup: ThingGroup | null = null;
  private thing: Thing | null = null;
  private thingContactPerson: Person | null = null;

  private readonly ThingGroupHelper = ThingGroupHelper;
  private readonly PersonUtils = PersonUtils;

  constructor(
    private readonly element: Element,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateThingGroup.bind(this)
    );
    this.updateThingGroup();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateThing.bind(this)
    );
    this.updateThing();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateThingContactPerson.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateThingContactPerson.bind(this)
    );
    this.updateThingContactPerson();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateThingGroup();
    }
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateThing();
    }
  }

  private updateThingGroup(): void {
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    } else {
      this.thingGroup = null;
    }
  }

  private updateThing(): void {
    if (this.processTask) {
      this.thing = this.entityManager.thingRepository.getById(
        this.processTask.thingId
      );
    } else {
      this.thing = null;
    }

    this.updateThingContactPerson();
  }

  private updateThingContactPerson(): void {
    if (this.thing) {
      const relation =
        this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          this.thing.id
        );
      this.thingContactPerson = relation
        ? this.entityManager.personRepository.getById(relation.personId)
        : null;
    } else {
      this.thingContactPerson = null;
    }
  }

  private handleNavigateToProcessTaskOverviewClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't EditProcessTaskAppointmentDialogProcessTaskInfo.handleNavigateToProcessTaskOverviewClick without processTask"
    );

    ProcessTaskUtils.navigateToEditProcessTaskPage(
      this.router,
      this.processTask.id
    );
    DomEventHelper.fireEvent<EditProcessTaskAppointmentDialogProcessTaskInfoNavigationTriggeredEvent>(
      this.element,
      {
        name: 'navigation-triggered',
        detail: null
      }
    );
  }
}

export type EditProcessTaskAppointmentDialogProcessTaskInfoNavigationTriggeredEvent =
  NamedCustomEvent<'navigation-triggered', null>;
