import { autoinject } from 'aurelia-framework';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { ProcessConfigurationAuthorizationTypeStepRelation } from 'common/Types/Entities/ProcessConfigurationAuthorizationType/ProcessConfigurationAuthorizationTypeDto';
import { Utils } from 'common/Utils';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationAuthorizationType } from '../../../classes/EntityManager/entities/ProcessConfigurationAuthorizationType/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ComputedValueService } from '../../ComputedValueService';
import { RoleBasedPermissionsComputer } from '../RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ProcessTaskGroupAuthorizationComputer extends ValueComputer<
  Record<never, never>,
  ProcessTaskGroupAuthorizationComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToMultipleModelChanges(
      [
        EntityName.ProcessTask,
        EntityName.ProcessTaskGroupAuthorization,
        EntityName.ProcessConfigurationAuthorizationType
      ],
      invokeCompute
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(invokeCompute)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        callback: invokeCompute,
        computeData: {},
        skipInitialCall: true
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskGroupAuthorizationComputeResult {
    const roleBasedPermissions = this.computedValueService.getCurrentValue(
      RoleBasedPermissionsComputer,
      {}
    );

    const processTaskIdsWhereUserIsAuthorized = roleBasedPermissions
      ? this.getProcessTaskIdsWhereUserIsAuthorized({ roleBasedPermissions })
      : new Set<string>();

    return {
      processTaskGroupIdsWhereUserIsAuthorized:
        this.getProcessTaskGroupIdsWhereUserIsAuthorized({
          processTaskIdsWhereUserIsAuthorized
        }),
      processTaskIdsWhereUserIsAuthorized
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getProcessTaskGroupIdsWhereUserIsAuthorized({
    processTaskIdsWhereUserIsAuthorized
  }: {
    processTaskIdsWhereUserIsAuthorized: Set<string>;
  }): Set<string> {
    const processTaskGroupIdToProcessTasks = Utils.groupValues(
      this.entityManager.processTaskRepository.getAll(),
      (processTask) => processTask.ownerProcessTaskGroupId
    );

    return new Set(
      this.entityManager.processTaskGroupRepository
        .getAll()
        .filter((processTaskGroup) => {
          const userGroup = this.entityManager.userGroupRepository.getById(
            processTaskGroup.ownerUserGroupId
          );
          if (!userGroup?.controlEntityVisibilityWithAuthorizations) {
            return true;
          }

          const processTasks =
            processTaskGroupIdToProcessTasks.get(processTaskGroup.id) ?? [];

          return processTasks.some((processTask) =>
            processTaskIdsWhereUserIsAuthorized.has(processTask.id)
          );
        })
        .map((processTaskGroup) => processTaskGroup.id)
    );
  }

  private getProcessTaskIdsWhereUserIsAuthorized({
    roleBasedPermissions
  }: {
    roleBasedPermissions: RoleBasedPermissions;
  }): Set<string> {
    const processTaskGroupIdToAuthorizationInfos =
      this.getProcessTaskGroupIdToAuthorizationInfos();

    return new Set(
      this.entityManager.processTaskRepository
        .getAll()
        .filter((processTask) => {
          const userGroupSpecificPermissions =
            roleBasedPermissions.inUserGroupId(processTask.ownerUserGroupId);
          if (
            !userGroupSpecificPermissions.getControlEntityVisibilityWithAuthorizations() ||
            userGroupSpecificPermissions.getCanSeeEntitiesWithoutProcessTaskGroupAuthorization()
          ) {
            return true;
          }

          const authorizationInfos =
            processTaskGroupIdToAuthorizationInfos.get(
              processTask.ownerProcessTaskGroupId
            ) ?? [];

          return authorizationInfos.some((info) => {
            if (!info.stepRelations || info.stepRelations.length === 0) {
              return true;
            }

            return info.stepRelations.some(
              (stepRelation) =>
                stepRelation.processConfigurationStepId ===
                processTask.currentProcessConfigurationStepId
            );
          });
        })
        .map((processTask) => {
          return processTask.id;
        })
    );
  }

  private getProcessTaskGroupIdToAuthorizationInfos(): ProcessTaskGroupIdToAuthorizationInfos {
    const currentUser = this.currentUserService.getCurrentUser();
    const processTaskGroupIdToAuthorizationInfos: ProcessTaskGroupIdToAuthorizationInfos =
      new Map();

    const authorizations =
      this.entityManager.processTaskGroupAuthorizationRepository.getAll();

    for (const authorization of authorizations) {
      if (!currentUser || authorization.userId !== currentUser.id) {
        continue;
      }

      const authorizationType =
        this.entityManager.processConfigurationAuthorizationTypeRepository.getById(
          authorization.processConfigurationAuthorizationTypeId
        );

      if (authorizationType) {
        this.addAuthorizationInfoToMap({
          processTaskGroupIdToAuthorizationInfos,
          processTaskGroupId: authorization.ownerProcessTaskGroupId,
          authorizationType
        });
      }
    }

    return processTaskGroupIdToAuthorizationInfos;
  }

  private addAuthorizationInfoToMap({
    processTaskGroupIdToAuthorizationInfos,
    processTaskGroupId,
    authorizationType
  }: {
    processTaskGroupIdToAuthorizationInfos: ProcessTaskGroupIdToAuthorizationInfos;
    processTaskGroupId: string;
    authorizationType: ProcessConfigurationAuthorizationType;
  }): void {
    let infos = processTaskGroupIdToAuthorizationInfos.get(processTaskGroupId);

    if (!infos) {
      infos = [];
      processTaskGroupIdToAuthorizationInfos.set(processTaskGroupId, infos);
    }

    const infosAlreadyContainAuthorizationType = infos.some(
      (info) =>
        info.processConfigurationAuthorizationTypeId === authorizationType.id
    );

    if (infosAlreadyContainAuthorizationType) {
      return;
    }

    infos.push({
      processConfigurationAuthorizationTypeId: authorizationType.id,
      stepRelations: authorizationType.stepRelations ?? []
    });
  }
}

export type ProcessTaskGroupAuthorizationComputeResult = {
  processTaskGroupIdsWhereUserIsAuthorized: Set<string>;
  processTaskIdsWhereUserIsAuthorized: Set<string>;
};

type ProcessTaskGroupIdToAuthorizationInfos = Map<
  string,
  Array<AuthorizationInfo>
>;

type AuthorizationInfo = {
  processConfigurationAuthorizationTypeId: string;
  stepRelations: Array<ProcessConfigurationAuthorizationTypeStepRelation>;
};
