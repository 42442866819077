import { computedFrom } from 'aurelia-binding';
import { GetScrollPosition, SetScrollPosition, Tab } from './Tab';

export class TabCollection {
  private readonly getScrollPosition: GetScrollPosition;
  private readonly setScrollPosition: SetScrollPosition;

  private internalTabs: Readonly<Array<Tab>> = [];
  private internalSelectedTab: Tab | null = null;
  private selectedTabName: string | null = null;
  private disabledTabNames: Set<string> = new Set();

  constructor({
    getScrollPosition,
    setScrollPosition
  }: {
    getScrollPosition: GetScrollPosition;
    setScrollPosition: SetScrollPosition;
  }) {
    this.getScrollPosition = getScrollPosition;
    this.setScrollPosition = setScrollPosition;
  }

  @computedFrom('internalTabs')
  public get tabs(): Readonly<Array<Tab>> {
    return this.internalTabs;
  }

  @computedFrom('internalSelectedTab')
  public get selectedTab(): Tab | null {
    return this.internalSelectedTab;
  }

  /**
   * Selects the tab even if it's disabled.
   * Maybe this behavior should be changed.
   */
  public selectFirstTab(): void {
    this.selectTab(this.tabs[0] ?? null);
  }

  /**
   * Selects the tab even if it's disabled.
   * Maybe this behavior should be changed.
   */
  public selectTabByName(name: string): void {
    this.internalSelectedTab = this.getTabByName(name);
    this.selectedTabName = name;

    this.updateTabVisibilities();
  }

  /**
   * Selects the tab even if it's disabled.
   * Maybe this behavior should be changed.
   */
  public selectTab(tab: Tab | null): void {
    if (this.internalSelectedTab === tab) {
      return;
    }

    this.internalSelectedTab = tab;
    this.updateTabVisibilities();
  }

  public setTabDisabled(name: string, disabled: boolean): void {
    const tab = this.getTabByName(name);
    tab?.setDisabled(disabled);

    if (disabled) {
      this.disabledTabNames.add(name);
    } else {
      this.disabledTabNames.delete(name);
    }
  }

  public setTabElements(tabElements: Array<HTMLElement>): void {
    this.internalTabs = tabElements.map((element) => {
      const tab = new Tab({
        element,
        getScrollPosition: this.getScrollPosition,
        setScrollPosition: this.setScrollPosition
      });

      tab.setDisabled(this.disabledTabNames.has(tab.name));

      return tab;
    });

    this.internalSelectedTab = this.selectedTabName
      ? this.getTabByName(this.selectedTabName)
      : null;

    this.updateTabVisibilities();
  }

  private updateTabVisibilities(): void {
    const shownTabs = this.internalTabs.filter((tab) => tab.isShown());
    for (const tab of shownTabs) {
      if (tab !== this.internalSelectedTab) {
        // if the tab is already shown, it will not be hidden
        tab.preHide();
      }
    }

    for (const tab of this.internalTabs) {
      if (tab === this.internalSelectedTab) {
        tab.show();
      } else {
        tab.hide();
      }
    }
  }

  private getTabByName(name: string): Tab | null {
    return this.tabs.find((tab) => tab.name === name) ?? null;
  }
}
