import { autoinject } from 'aurelia-framework';

import { Vector } from 'common/Geometry/Vector';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointToPicture } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types';

@autoinject
export class ProcessTaskMeasurePointMarkingDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private picture: Picture | null = null;
  private measurePoint: ProcessTaskMeasurePoint | null = null;
  private onDialogClosed: OnDialogClosed | null = null;

  private updatedRelation: boolean = false;

  private fullScreenOverlay: FullScreenOverlay | null = null;
  private pictureWrapper: HTMLElement | null = null;

  protected measurePointRelationsOfPicture: Array<ProcessTaskMeasurePointToPicture> =
    [];
  protected processTaskGroup: ProcessTaskGroup | null = null;
  protected measurePointsOfPicture: Array<ProcessTaskMeasurePoint> = [];

  constructor(private readonly entityManager: AppEntityManager) {}

  public open(options: OpenOptions): void {
    this.picture = options.picture;
    this.measurePoint = options.measurePoint;
    this.processTaskGroup = options.processTaskGroup;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.updatePictureMeasurePoints();

    if (this.fullScreenOverlay) {
      this.fullScreenOverlay.open();
    }
  }

  private updatePictureMeasurePoints(): void {
    if (this.picture) {
      this.measurePointRelationsOfPicture =
        this.entityManager.processTaskMeasurePointToPictureRepository.getByPictureId(
          this.picture.id
        );
      const measurePointIds = this.measurePointRelationsOfPicture.map(
        (r) => r.processTaskMeasurePointId
      );
      this.measurePointsOfPicture =
        this.entityManager.processTaskMeasurePointRepository.getByIds(
          measurePointIds
        );
    } else {
      this.measurePointRelationsOfPicture = [];
      this.measurePointsOfPicture = [];
    }
  }

  protected handleFullScreenOverlayClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const updatedRelation = this.updatedRelation;

    this.picture = null;
    this.measurePoint = null;
    this.onDialogClosed = null;
    this.updatedRelation = false;

    onDialogClosed && onDialogClosed(updatedRelation);
  }

  protected handlePictureWrapperClick(event: MouseEvent): void {
    if (!this.pictureWrapper || !this.measurePoint || !this.picture) {
      return;
    }

    const relativePosition = this.calculateRelativeClickPosition(
      event,
      this.pictureWrapper
    );
    this.updateMeasurePointToPicture(
      this.measurePoint,
      this.picture,
      relativePosition
    );

    if (this.fullScreenOverlay) {
      this.fullScreenOverlay.close();
    }
  }

  private calculateRelativeClickPosition(
    event: MouseEvent,
    pictureWrapper: HTMLElement
  ): Vector {
    const clickVector = new Vector(event.clientX, event.clientY);
    const rect = pictureWrapper.getBoundingClientRect();
    return clickVector
      .clone()
      .substractXY(rect.left, rect.top)
      .divideXY(rect.width, rect.height);
  }

  private updateMeasurePointToPicture(
    measurePoint: ProcessTaskMeasurePoint,
    picture: Picture,
    position: Vector
  ): void {
    const relation =
      this.entityManager.processTaskMeasurePointToPictureRepository.getByMeasurePointIdAndPictureId(
        measurePoint.id,
        picture.id
      );

    if (relation) {
      relation.left = position.getX();
      relation.top = position.getY();
      this.entityManager.processTaskMeasurePointToPictureRepository.update(
        relation
      );
    } else {
      this.entityManager.processTaskMeasurePointToPictureRepository.create({
        processTaskMeasurePointId: measurePoint.id,
        pictureId: picture.id,
        left: position.getX(),
        top: position.getY(),
        ownerProcessTaskId: measurePoint.ownerProcessTaskId,
        ownerProcessTaskGroupId: measurePoint.ownerProcessTaskGroupId,
        ownerUserGroupId: measurePoint.ownerUserGroupId,
        temporaryGroupName: measurePoint.temporaryGroupName,
        shadowEntity: measurePoint.shadowEntity
      });
    }

    this.updatedRelation = true;
  }
}

export type OpenOptions = {
  picture: Picture;
  measurePoint: ProcessTaskMeasurePoint;
  processTaskGroup: ProcessTaskGroup;
  onDialogClosed?: OnDialogClosed | null;
};

export type OnDialogClosed = (updatedRelation: boolean) => void;
