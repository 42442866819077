import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  StructureTemplateStatus,
  StructureTemplateType
} from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FilterHelper } from '../../classes/FilterHelper';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { EditStructureTemplateDialog } from '../../dialogs/edit-structure-template-dialog/edit-structure-template-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

@autoinject()
export class EditStructureTemplates {
  private subscriptionManager: SubscriptionManager;

  private currentUser: User | null = null;

  private editableUserGroups: Array<UserGroup> = [];

  private availableStructureTemplates: Array<StructureTemplate> = [];

  @observable private structureTemplateFilterString = '';

  private searchFilteredStructureTemplates: Array<StructureTemplate> = [];
  private filteredStructureTemplates: Array<StructureTemplate> = [];

  protected boundStructureTemplateIsArchived =
    this.structureTemplateIsArchived.bind(this);

  protected readonly EntityName = EntityName;

  private sortOptions: {
    name: SorterSortOption<StructureTemplate>;
    updatedAt: SorterSortOption<StructureTemplate>;
  } = {
    name: {
      name: 'name',
      sortFunction: (a, b) => {
        const aName = a.name ? a.name : 'Kartenebene';
        const bName = b.name ? b.name : 'Kartenebene';
        return aName.localeCompare(bName);
      }
    },
    updatedAt: {
      name: 'updatedAt',
      sortFunction: (a, b) => {
        const aUpdatedAt = a.updatedAt || a.createdAt || '';
        const bUpdatedAt = b.updatedAt || b.createdAt || '';
        return aUpdatedAt.localeCompare(bUpdatedAt);
      }
    }
  };

  private currentSortOption = this.sortOptions.name;

  private sortedStructureTemplates = [];

  private isMobile = false;

  private router: Router;

  constructor(
    router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.router = router;

    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      this.updateAvailableStructureTemplates.bind(this)
    );
    this.updateAvailableStructureTemplates();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private structureTemplateFilterStringChanged(): void {
    this.updateSearchFilteredStructureTemplates();
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }
  }

  private updateAvailableStructureTemplates(): void {
    this.availableStructureTemplates =
      this.entityManager.structureTemplateRepository.getAll();
    this.updateSearchFilteredStructureTemplates();
  }

  private updateSearchFilteredStructureTemplates(): void {
    this.searchFilteredStructureTemplates = FilterHelper.filterItems(
      this.availableStructureTemplates,
      (structureTemplate) => structureTemplate.name ?? '',
      this.structureTemplateFilterString
    );
  }

  private handleCreateStructureTemplateClicked(userGroupId: string): void {
    const structureTemplate =
      this.entityManager.structureTemplateRepository.create({
        ownerUserGroupId: userGroupId,
        type: StructureTemplateType.B1300
      });

    this.editStructureTemplate(structureTemplate);
  }

  private editStructureTemplate(structureTemplate: StructureTemplate): void {
    void EditStructureTemplateDialog.open({
      structureTemplate: structureTemplate,
      onDialogClosed: () => {
        this.scrollToStructureTemplate(structureTemplate);
      }
    });
  }

  private handleEnterStructureTemplateClicked(
    structureTemplate: StructureTemplate
  ): void {
    this.router.navigateToRoute('edit_structure_template', {
      structure_template_id: structureTemplate.id
    });
  }

  private scrollToStructureTemplate(
    structureTemplate: StructureTemplate
  ): void {
    void ScrollHelper.autoScrollToListItem(
      `#edit-structure-templates--structure-template-${structureTemplate.id}`
    );
  }

  private structureTemplateIsEditable(
    structureTemplate: StructureTemplate,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return PermissionHelper.userCanEditOwnerUserGroupIdEntity(
      structureTemplate,
      user,
      editableUserGroups
    );
  }

  private structureTemplateIsArchived(
    structureTemplate: StructureTemplate
  ): boolean {
    return structureTemplate.status === StructureTemplateStatus.ARCHIVED;
  }
}
