import 'flatpickr/dist/flatpickr.min.css';
import { autoinject, bindable } from 'aurelia-framework';
import flatpickr from 'flatpickr';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { German } from 'flatpickr/dist/l10n/de';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { isEqual } from 'lodash';

/**
 * @event on-changed triggered when the user selects a date or changes the time on a selected date.
 * @event on-month-changed triggered when the month is changed, either by the user or programmatically.
 * @event on-year-changed triggered when the year is changed, either by the user or programmatically.
 */
@autoinject()
export class Calendar {
  /**
   * If set, disables all dates except the provided ones.
   */
  @bindable public enabledDates: Array<Date> = [];

  private element: HTMLElement;
  private flatpickrInstance: flatpickr.Instance | null = null;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected attached(): void {
    this.flatpickrInstance = flatpickr(this.element, {
      locale: German,
      enableTime: false,
      allowInput: true,
      time_24hr: true,
      clickOpens: false,
      disableMobile: true,
      dateFormat: 'd.m.Y',
      inline: true,
      enable: this.enabledDates,
      onChange: (dates) => {
        DomEventHelper.fireEvent<OnChangedEvent>(this.element, {
          name: 'on-changed',
          detail: { dates }
        });
      },
      onMonthChange: () => {
        DomEventHelper.fireEvent<OnMonthChangedEvent>(this.element, {
          name: 'on-month-changed',
          detail: {}
        });
      },
      onYearChange: () => {
        DomEventHelper.fireEvent<OnYearChangedEvent>(this.element, {
          name: 'on-year-changed',
          detail: {}
        });
      }
    }) as flatpickr.Instance;
  }

  public getFlatpickrInstance(): flatpickr.Instance {
    assertNotNullOrUndefined(
      this.flatpickrInstance,
      'cannot getFlatpickrInstance without instance'
    );

    return this.flatpickrInstance;
  }

  protected enabledDatesChanged(
    value: Array<Date>,
    oldValue: Array<Date>
  ): void {
    if (!this.flatpickrInstance) return;
    if (isEqual(value, oldValue)) return;

    // Because some components change the enabledDates of the calendar
    // whenever the current month changes and that might prevent
    // the calendar from switching to the new month, we set it ourselves
    const currentMonth = this.flatpickrInstance.currentMonth;
    this.flatpickrInstance?.set('enable', this.enabledDates ?? undefined);
    this.flatpickrInstance?.changeMonth(currentMonth, false);
  }
}

export type OnChangedEvent = NamedCustomEvent<
  'on-changed',
  { dates: Array<Date> }
>;
export type OnMonthChangedEvent = NamedCustomEvent<'on-month-changed', {}>;
export type OnYearChangedEvent = NamedCustomEvent<'on-year-changed', {}>;
