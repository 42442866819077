import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { UserGroupSubEntityDto } from 'common/Types/BaseEntities/UserGroupSubEntityUtils';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { NFCHelper } from '../../classes/Nfc/NFCHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  NfcTagChangedEvent,
  NfcTagCreatedEvent,
  NfcTagDeletedEvent
} from '../nfc-tag-widget/nfc-tag-widget';

@autoinject()
export class EntityNfcTagWidget<
  TRelation extends UserGroupSubEntityDto,
  TEntity extends UserGroupSubEntityDto
> {
  @bindable public relationInfo: RelationInfo<TRelation, TEntity> | null = null;

  @bindable public entity: TEntity | null = null;

  protected nfcTagId: string | null = null;

  private isNfcEnabled = false;
  private canAssignNfcTagIds = false;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private boundUpdateNfcTagId = this.updateNfcTagId.bind(this);

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canAssignNfcTagIds: 'canAssignNfcTagIds'
      }
    });
  }

  // Aurelia Lifecylce

  protected attached(): void {
    assertNotNullOrUndefined(
      this.relationInfo,
      'cannot attach entity-nfc-tag-widget without relation info'
    );

    this.subscriptionManager.addDisposable(
      NFCHelper.registerBinding('nfcEnabled', (isNfcEnabled) => {
        this.isNfcEnabled = isNfcEnabled;
      })
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.NfcToken,
      this.boundUpdateNfcTagId
    );
    this.subscriptionManager.subscribeToModelChanges(
      this.relationInfo.entityName,
      this.boundUpdateNfcTagId
    );
    this.subscriptionManager.subscribeToModelChanges(
      this.relationInfo.relationEntityName,
      this.boundUpdateNfcTagId
    );
    this.updateNfcTagId();

    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();

    this.permissionBindingHandle.unsubscribe();
  }

  // Aurelia Change Handlers

  protected entityChanged(): void {
    this.updateNfcTagId();
  }

  // Getters

  @computedFrom('nfcTagId', 'canAssignNfcTagIds', 'isNfcEnabled')
  protected get showNfcTagWidget(): boolean {
    return !!this.nfcTagId || (this.canAssignNfcTagIds && this.isNfcEnabled);
  }

  // Aurelia Handlers

  protected handleNfcTagCreated(event: NfcTagCreatedEvent): void {
    assertNotNullOrUndefined(
      this.relationInfo,
      'cannot handle nfc tag create without relation info'
    );
    assertNotNullOrUndefined(
      this.entity,
      'cannot handle nfc tag create without an entity'
    );

    const token = this.entityManager.nfcTokenRepository.create({
      ownerUserGroupId: this.entity.ownerUserGroupId,
      tokenId: event.detail.nfcTagId
    });

    this.relationInfo.createRelation({
      tokenId: token.id,
      entity: this.entity,
      entityManager: this.entityManager
    });
  }

  protected handleNfcTagChanged(event: NfcTagChangedEvent): void {
    assertNotNullOrUndefined(
      this.relationInfo,
      'cannot handle nfc tag change without relation info'
    );
    assertNotNullOrUndefined(
      this.entity,
      'cannot handle nfc tag change without an entity'
    );
    assertNotNullOrUndefined(
      event.detail.oldNfcTagId,
      'cannot handle nfc tag change without an oldNfcTagId'
    );

    const nfcToken = this.entityManager.nfcTokenRepository.getByTokenId(
      event.detail.oldNfcTagId
    );
    assertNotNullOrUndefined(
      nfcToken,
      'cannot handle nfc tag change without an nfcToken'
    );
    nfcToken.tokenId = event.detail.newNfcTagId;
    this.entityManager.nfcTokenRepository.update(nfcToken);
  }

  protected handleNfcTagDeleted(event: NfcTagDeletedEvent): void {
    assertNotNullOrUndefined(
      this.relationInfo,
      'cannot handle nfc tag delete without relation info'
    );
    assertNotNullOrUndefined(
      this.entity,
      'cannot handle nfc tag delete without an entity'
    );
    assertNotNullOrUndefined(
      event.detail.nfcTagId,
      'cannot handle nfc tag delete without an nfcTagId'
    );

    const nfcToken = this.entityManager.nfcTokenRepository.getByTokenId(
      event.detail.nfcTagId
    );
    assertNotNullOrUndefined(
      nfcToken,
      'cannot handle nfc tag delete without an nfcToken'
    );
    this.entityManager.nfcTokenRepository.delete(nfcToken);

    this.relationInfo.deleteRelation({
      entity: this.entity,
      entityManager: this.entityManager
    });
  }

  // Private Methods

  private updateNfcTagId(): void {
    if (!this.relationInfo || !this.entity) {
      this.nfcTagId = null;
      return;
    }

    const relation = this.relationInfo.getRelationForEntity({
      entity: this.entity,
      entityManager: this.entityManager
    });

    if (!relation) {
      this.nfcTagId = null;
      return;
    }

    const nfcTokenId = this.relationInfo.getTokenIdFromRelation(relation);
    if (!nfcTokenId) {
      this.nfcTagId = null;
      return;
    }

    this.nfcTagId =
      this.entityManager.nfcTokenRepository.getById(nfcTokenId)?.tokenId ??
      null;
  }
}

export type RelationInfo<
  TRelation extends UserGroupSubEntityDto,
  TEntity extends UserGroupSubEntityDto
> = {
  entityName: EntityName;
  relationEntityName: EntityName;

  getRelationForTokenId: (params: {
    tokenId: string;
    entityManager: AppEntityManager;
    entity: TEntity;
  }) => TRelation | null;
  getRelationForEntity: (params: {
    entityManager: AppEntityManager;
    entity: TEntity;
  }) => TRelation | null;
  createRelation: (params: {
    tokenId: string;
    entityManager: AppEntityManager;
    entity: TEntity;
  }) => void;
  deleteRelation: (params: {
    entityManager: AppEntityManager;
    entity: TEntity;
  }) => void;

  getTokenIdFromRelation: (relation: TRelation) => string | null;
};
