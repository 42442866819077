import { Tool } from './Tool';

export class RectangleTool extends Tool {
  _name = 'Rechteck';
  _icons = ['far fa-square'];
  _machineName = 'rectangle';

  /** @type {Msvg.Rectangle|null} */
  _rectangle = null;

  /** @type {Msvg.Point|null} */
  _firstPosition = null;

  _cancel() {
    if (this._rectangle) {
      this._msvg.remove(this._rectangle);
    }
    super._cancel();
  }

  _reset() {
    super._reset();
    this._firstPosition = null;
    this._rectangle = null;
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    if (this._rectangle) {
      this._updateRectanglePosition(position);
      this._msvg.append(this._rectangle);
      this._modified();
      this._reset();
    } else {
      this._startNewRectangle(position);
    }
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseMovedHandler(event, position) {
    if (this._rectangle) {
      this._updateRectanglePosition(position);
    }
  }

  /**
   * @param event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (!this._rectangle) {
      this._startNewRectangle(this._dragStartPosition);
    }
    this._updateRectanglePosition(position);
  }

  /**
   * @param event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._updateRectanglePosition(position);
    this._msvg.append(this._rectangle);
    this._modified();
    this._reset();
  }

  /**
   * @param {Msvg.Point} position
   * @private
   */
  _startNewRectangle(position) {
    this._firstPosition = position;
    this._rectangle = this._createRectangle(position);
    this._msvg.append(this._rectangle);
  }

  /**
   * @param {Msvg.Point} position
   * @returns {Msvg.Rectangle}
   * @private
   */
  _createRectangle(position) {
    return new Msvg.Rectangle()
      .setRx(position.x)
      .setRy(position.y)
      .setHeight(0)
      .setWidth(0)
      .setRy(0)
      .setRx(0)
      .setFill('none')
      .setStroke(this._color)
      .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
      .setStrokeWidth(this._size);
  }

  /**
   * @param {Msvg.Point} secondPosition
   * @private
   */
  _updateRectanglePosition(secondPosition) {
    const bounds = new Msvg.Utils.Bounds([this._firstPosition, secondPosition]);
    const size = bounds.getSize();
    this._rectangle
      .setPosition(bounds.getTopLeftCorner())
      .setWidth(size.x)
      .setHeight(size.y);
  }

  _stylingChanged() {
    if (this._rectangle) {
      this._rectangle
        .setStroke(this._color)
        .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
        .setStrokeWidth(this._size);
    }
  }
}
