import { computedFrom } from 'aurelia-framework';

import { ValueCalculationConfigColorConfig } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { ThingValueCalculationConfig } from '../../../../classes/EntityManager/entities/ValueCalculationConfig/types';
import {
  ValueCalculationConfigDefinitionWidgetHandle,
  ValueCalculationConfigFeatures
} from '../ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export class ThingValueCalculationConfigDefinitionHandle
  implements
    ValueCalculationConfigDefinitionWidgetHandle<ThingValueCalculationConfig>
{
  private readonly entityManager: AppEntityManager;
  private readonly valueCalculationConfig: ThingValueCalculationConfig;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(options: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    valueCalculationConfig: ThingValueCalculationConfig;
  }) {
    this.entityManager = options.entityManager;
    this.valueCalculationConfig = options.valueCalculationConfig;

    const thing = this.entityManager.thingRepository.getById(
      this.valueCalculationConfig.ownerThingId
    );
    assertNotNullOrUndefined(
      thing,
      'cannot create ThingValueCalculationDefinitionHandle without thing'
    );

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Thing,
        entity: thing
      });
  }

  public subscribe(): Disposable {
    return this.permissionsHandle.subscribe();
  }

  @computedFrom('valueCalculationConfig.name')
  public get name(): string {
    return this.valueCalculationConfig.name;
  }

  public setName(name: string): void {
    this.valueCalculationConfig.name = name;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('valueCalculationConfig.calculationExpression')
  public get calculationExpression(): string {
    return this.valueCalculationConfig.calculationExpression;
  }

  public setCalculationExpression(calculationExpression: string): void {
    this.valueCalculationConfig.calculationExpression = calculationExpression;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  public get colorCodeConfig(): ValueCalculationConfigColorConfig | null {
    return this.valueCalculationConfig.colorCodeConfig;
  }

  public setColorCodeConfig(
    colorCodeConfig: ValueCalculationConfigColorConfig | null
  ): void {
    this.valueCalculationConfig.colorCodeConfig = colorCodeConfig;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('valueCalculationConfig.showInEntityOverview')
  public get showInEntityOverview(): boolean {
    return this.valueCalculationConfig.showInEntityOverview;
  }

  public setShowInEntityOverview(showInEntityOverview: boolean): void {
    this.valueCalculationConfig.showInEntityOverview = showInEntityOverview;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  public get reportTypeId(): string | null {
    return null;
  }

  public setReportTypeId(_reportTypeId: string | null): void {
    throw new Error('"reportTypeId" does not exist on this sub-type.');
  }

  @computedFrom('permissionsHandle.canAdministerValueCalculations')
  public get canEditFields(): boolean {
    return this.permissionsHandle.canAdministerValueCalculations;
  }

  @computedFrom('permissionsHandle.canAdministerValueCalculations')
  public get canBeDeleted(): boolean {
    return this.permissionsHandle.canAdministerValueCalculations;
  }

  public getValueCalculationDefinition(): ThingValueCalculationConfig {
    return this.valueCalculationConfig;
  }

  public getFeatures(): ValueCalculationConfigFeatures {
    return {
      showReportTypeSelection: false
    };
  }
}
