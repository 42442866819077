import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../../../classes/EntityManager/entities/Defect/types';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { DisposableUtils } from '../../../../classes/Utils/DisposableUtils';
import { LastUsedPictureForIdService } from '../../../../services/LastUsedEntityService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { PictureDisplayInfoUtils } from '../PictureDisplayInfoUtils/PictureDisplayInfoUtils';
import {
  AfterPictureSelectedOptions,
  PictureGroup,
  SelectPictureDialogAdapter,
  SubscribeOptions
} from '../SelectPictureDialogAdapter/SelectPictureDialogAdapter';

export class SelectPictureDialogDefectRoutePlannerAdapter extends SelectPictureDialogAdapter {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly lastUsedPictureForIdService: LastUsedPictureForIdService;
  private readonly defect: Defect;

  private lastUsedPictureId: string | null = null;

  constructor(options: SelectPictureDialogDefectRoutePlannerAdapterOptions) {
    super();

    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.lastUsedPictureForIdService = options.lastUsedPictureForIdService;
    this.defect = options.defect;
  }

  public subscribe({ setPictureGroups }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updatePictureGroups = DisposableUtils.disposableCallback((): void => {
      setPictureGroups(this.getPictureGroups());
    });

    subscriptionManager.addDisposable(updatePictureGroups);

    const updateLastUsedPictureId = (): void => {
      void this.lastUsedPictureForIdService
        .getLastUsed(this.defect.id)
        .then((lastUsedPictureId) => {
          this.lastUsedPictureId = lastUsedPictureId;
          updatePictureGroups();
        });
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updatePictureGroups
    );
    this.lastUsedPictureForIdService.onLastUsedChange(updateLastUsedPictureId);

    updateLastUsedPictureId();
    updatePictureGroups();

    return subscriptionManager.toDisposable();
  }

  public async afterPictureSelected({
    picture
  }: AfterPictureSelectedOptions): Promise<void> {
    await this.lastUsedPictureForIdService.setLastUsed(
      this.defect.id,
      picture.id
    );
  }

  private getPictureGroups(): Array<PictureGroup> {
    const defectPictures = this.entityManager.pictureRepository.getByDefectId(
      this.defect.id
    );

    const pictureGroups: Array<PictureGroup> = [];

    const lastUsedPictureGroup = this.getLastUsedPictureGroup({
      defectPictures
    });
    if (lastUsedPictureGroup) {
      pictureGroups.push(lastUsedPictureGroup);
    }

    pictureGroups.push(this.getDefectPictureGroup({ defectPictures }));

    return pictureGroups;
  }

  private getLastUsedPictureGroup({
    defectPictures
  }: {
    defectPictures: Array<Picture>;
  }): PictureGroup | null {
    const pictures = defectPictures.filter((picture) => {
      return picture.id === this.lastUsedPictureId;
    });

    if (pictures.length === 0) {
      return null;
    }

    return {
      titleTk: GroupTitleTk.LAST_USED,
      pictureDisplayInfos:
        PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({ pictures })
    };
  }

  private getDefectPictureGroup({
    defectPictures
  }: {
    defectPictures: Array<Picture>;
  }): PictureGroup {
    return {
      titleTk: GroupTitleTk.DEFECT_PICTURES,
      pictureDisplayInfos:
        PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({
          pictures: defectPictures,
          pictureIsEnabled: ({ picture }) => {
            return this.pictureHasCoords({ picture });
          }
        })
    };
  }

  private pictureHasCoords({ picture }: { picture: Picture }): boolean {
    return (
      !!picture.coords &&
      !!picture.coords.latitude &&
      !!picture.coords.longitude
    );
  }
}

export enum GroupTitleTk {
  DEFECT_PICTURES = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogDefectRoutePlannerAdapter.defectPictures',
  LAST_USED = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogDefectRoutePlannerAdapter.lastUsed'
}

export type SelectPictureDialogDefectRoutePlannerAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  defect: Defect;
  lastUsedPictureForIdService: LastUsedPictureForIdService;
};
