import { autoinject, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditProcessConfigurationStepDialog } from '../../operationsComponents/edit-process-configuration-step-dialog/edit-process-configuration-step-dialog';
import { ProcessConfigurationStepListItem } from '../../operationsComponents/process-configuration-step-list-item/process-configuration-step-list-item';
import {
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../../aureliaComponents/more-button/more-button';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import {
  ImportFromCsvFileDialog,
  CallbackParams
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData,
  ValidateCallbackResult
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  ProcessConfigurationStepPosition,
  ProcessConfigurationStepPositionCreationEntity
} from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { FileDownloadService } from '../../services/FileDownloadService';
import { ProcessConfigurationStepPositionCreationService } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionCreationService';
import { PropertyImporter } from '../../classes/CsvImporter/PropertyImporter';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';

@autoinject()
export class edit_process_configuration_steps_steps {
  private subscriptionManager: SubscriptionManager;
  private readonly propertyImporter: PropertyImporter;

  @subscribableLifecycle()
  private readonly processConfigurationStepPermissionsChecker: EntitiesPermissionChecker<EntityName.ProcessConfigurationStep>;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private activeEntitiesService: ActiveEntitiesService;

  private socketService: SocketService;

  private isAttached = false;
  protected isMobile = false;
  private isOnline = false;
  protected availableProcessConfigurationSteps: Array<ProcessConfigurationStep> =
    [];

  protected listItemsWrapper: HTMLElement | null = null;

  protected processConfiguration: ProcessConfiguration | null = null;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'export-positions-as-csv',
      labelTk: 'operations.editProcessConfigurationSteps.exportPositionsAsCsv',
      iconClass: 'fal fa-file-csv',
      disabledContext: this,
      disabledPropertyName: 'isNotOnline'
    },
    {
      name: 'import-positions-from-csv',
      labelTk:
        'operations.editProcessConfigurationSteps.importPositionsFromCsv',
      iconClass: 'fal fa-file-csv',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv',
      disabledContext: this,
      disabledPropertyName: 'positionsImportDisabled'
    }
  ];

  private boundValidatePerson = this.validatePerson.bind(this);

  private importFromCsvFileFieldInfos: FieldInfosFromConfig<FieldInfoConfiguration> =
    [
      {
        field: 'stepName',
        header: 'Schritt',
        type: FieldType.STRING,
        required: true,
        extraField: true,
        validateCallback: this.validateStepColumn.bind(this),
        validationRequired: true
      },
      {
        field: 'internalId',
        header: 'Interne ID',
        type: FieldType.STRING,
        extraField: true
      },
      {
        field: 'personCompanyName',
        header: 'Firma',
        type: FieldType.STRING,
        extraField: true,
        validationRequired: true,
        validateCallback: this.boundValidatePerson
      },
      {
        field: 'personLastName',
        header: 'Nachname',
        type: FieldType.STRING,
        extraField: true,
        validationRequired: true,
        validateCallback: this.boundValidatePerson
      },
      {
        field: 'personFirstName',
        header: 'Vorname',
        type: FieldType.STRING,
        extraField: true,
        validationRequired: true,
        validateCallback: this.boundValidatePerson
      },
      { field: 'customId', header: 'ID', type: FieldType.STRING },
      { field: 'type', header: 'Typ', type: FieldType.STRING },
      { field: 'namePrefix', header: 'Name Präfix', type: FieldType.STRING },
      { field: 'name', header: 'Name', type: FieldType.STRING },
      { field: 'description', header: 'Beschreibung', type: FieldType.STRING },
      { field: 'price', header: 'Preis', type: FieldType.NUMBER },
      { field: 'priceGroup', header: 'Preisgruppe', type: FieldType.STRING },
      { field: 'unit', header: 'Einheit', type: FieldType.STRING },
      { field: 'discount', header: 'Rabatt', type: FieldType.NUMBER },
      {
        field: 'discountNote',
        header: 'Rabattanmerkung',
        type: FieldType.STRING
      },
      { field: 'flatRate', header: 'Pauschale', type: FieldType.BOOLEAN },
      { field: 'groupName', header: 'Gruppenname', type: FieldType.STRING },
      {
        field: 'subGroupName',
        header: 'Untergruppenname',
        type: FieldType.STRING
      },
      {
        field: 'categoryName',
        header: 'Kategoriename',
        type: FieldType.STRING
      },
      { field: 'color', header: 'Farbe', type: FieldType.STRING },
      {
        field: 'excludeFromMarkup',
        header: 'von Aufschlag exkludiert',
        type: FieldType.BOOLEAN
      },
      {
        field: 'ignoreExcludeFromMarkup',
        header: 'Aufschlag enthält alle Positionen',
        type: FieldType.BOOLEAN
      },
      { field: 'deactivated', header: 'Deaktiviert', type: FieldType.BOOLEAN }
    ];

  protected readonly EntityName = EntityName;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly processConfigurationStepPositionCreationService: ProcessConfigurationStepPositionCreationService,
    subscriptionManagerService: SubscriptionManagerService,
    activeEntitiesService: ActiveEntitiesService,
    socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.activeEntitiesService = activeEntitiesService;

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });

    this.processConfigurationStepPermissionsChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.ProcessConfigurationStep
      });

    this.socketService = socketService;

    this.propertyImporter = new PropertyImporter({ entityManager });
  }

  protected activate(params: { process_configuration_id: string }): void {
    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        params.process_configuration_id
      ) || null;
    this.activeEntitiesService.setActiveProcessConfiguration(
      this.processConfiguration
    );

    if (this.isAttached) {
      this.updateAvailableProcessConfigurationSteps();
    }
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveProcessConfiguration(null);
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'processConfiguration is not available'
    );
    assertNotNullOrUndefined(
      this.listItemsWrapper,
      'listItemsWrapper is not available'
    );

    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateAvailableProcessConfigurationSteps.bind(this)
    );
    this.updateAvailableProcessConfigurationSteps();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      selectedCallbacks: {
        'export-positions-as-csv':
          this.handleExportPositionsAsCsvClicked.bind(this)
      },
      fileChangedCallbacks: {
        'import-positions-from-csv':
          this.handleImportPositionsFromCsvClicked.bind(this)
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();

    GlobalMenu.releaseControl(this);
  }

  @computedFrom('isOnline')
  protected get isNotOnline(): boolean {
    return !this.isOnline;
  }

  @computedFrom(
    'processConfigurationPermissionsHandle.canImportProcessConfigurationStepPositions'
  )
  protected get positionsImportDisabled(): boolean {
    return !this.processConfigurationPermissionsHandle
      .canImportProcessConfigurationStepPositions;
  }

  @computedFrom(
    'availableProcessConfigurationSteps',
    'processConfigurationStepPermissionsChecker.revision'
  )
  protected get processConfigurationStepsAreDraggable(): boolean {
    return this.processConfigurationStepPermissionsChecker.allEntitiesHavePermission(
      {
        entities: this.availableProcessConfigurationSteps,
        checkPermission: ({ entity, adapter }) => {
          return adapter.canEditField(entity); // order
        }
      }
    );
  }

  private updateAvailableProcessConfigurationSteps(): void {
    if (this.processConfiguration) {
      this.availableProcessConfigurationSteps =
        this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.availableProcessConfigurationSteps = [];
    }
  }

  protected handleCreateProcessConfigurationStepClicked(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'processConfiguration is not set'
    );

    const maxOrderStep = this.availableProcessConfigurationSteps.reduce(
      (maxStep, step) => {
        if (!maxStep) {
          return step;
        }

        return step.order > maxStep.order ? step : maxStep;
      },
      this.availableProcessConfigurationSteps[0]
    );

    const step = this.entityManager.processConfigurationStepRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      order: maxOrderStep ? maxOrderStep.order + 1 : 0,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId
    });

    this.updateAvailableProcessConfigurationSteps();
    this.openEditProcessConfigurationStepDialog(step);
  }

  protected handleEditProcessConfigurationStepClicked(
    processConfigurationStep: ProcessConfigurationStep
  ): void {
    this.openEditProcessConfigurationStepDialog(processConfigurationStep);
  }

  protected handleActivateDropTarget(
    item: ProcessConfigurationStepListItem,
    step: ProcessConfigurationStep
  ): boolean {
    return item.processConfigurationStep?.id !== step.id;
  }

  protected handleDropItem(
    item: ProcessConfigurationStepListItem,
    newStep: ProcessConfigurationStep | null
  ): void {
    const movedStep = item.processConfigurationStep;
    assertNotNullOrUndefined(
      movedStep,
      "process configuration step cannot be 'null' in dropped item"
    );

    const steps = this.availableProcessConfigurationSteps.slice();

    const oldIndex = steps.indexOf(movedStep);
    steps.splice(oldIndex, 1);

    const newPosition = newStep ? steps.indexOf(newStep) : steps.length;
    steps.splice(newPosition, 0, movedStep);

    steps.forEach((step, index) => {
      if (step.order !== index) {
        step.order = index;
        this.entityManager.processConfigurationStepRepository.update(step);
      }
    });

    this.updateAvailableProcessConfigurationSteps();
  }

  private openEditProcessConfigurationStepDialog(
    processConfigurationStep: ProcessConfigurationStep
  ): void {
    void EditProcessConfigurationStepDialog.open({
      processConfigurationStep: processConfigurationStep,
      onDialogClosed: () => {
        this.goToProcessConfigurationStep(processConfigurationStep);
      }
    });
  }

  private goToProcessConfigurationStep(
    processConfigurationStep: ProcessConfigurationStep
  ): void {
    void ScrollHelper.autoScrollToListItem(
      '#' +
        this.getProcessConfigurationStepElementId(processConfigurationStep.id),
      null,
      processConfigurationStep,
      () => this.isAttached
    );
  }

  protected getProcessConfigurationStepElementId(
    processConfigurationStepId: string
  ): string {
    return (
      'edit-process-configuration-steps--process-configuration-step-' +
      processConfigurationStepId
    );
  }

  protected handleExportPositionsAsCsvClicked(): void {
    if (!this.processConfiguration) return;

    Dialogs.waitDialogTk();
    this.socketService.exportPositionsAsCsvFile(
      {
        processConfigurationId: this.processConfiguration.id
      },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessageKey = `serverResponses.${
            response.status ? response.status : 'unspecifiedError'
          }`;
          void Dialogs.errorDialogTk('general.downloadError', errorMessageKey);
        }
      }
    );
  }

  protected handleImportPositionsFromCsvClicked(
    event: MoreButtonFileChangedEvent<any>['detail']
  ): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'processConfiguration is not set'
    );

    const firstFile = event.target.files?.[0];
    event.target.value = '';

    if (!firstFile) return;

    void ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      file: firstFile,
      entityNameTk: 'models.ProcessConfigurationStepPositionModel_plural',
      fields: this.importFromCsvFileFieldInfos,
      showOverwriteCheckbox: true,
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: false,
      importFromCsvFileCallback:
        this.handleImportPositionsFromCsvFileSubmitted.bind(this)
    });
  }

  private validateStepColumn(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'processConfiguration is not set'
    );

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        this.processConfiguration.id
      );
    const stepNames = steps.map((step) => step.name);

    const valid = parsedData.every((data) => {
      return stepNames.includes(data.fields.stepName ?? null);
    });

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'operations.editProcessConfigurationSteps.invalidStepNames'
    };
  }

  private validatePerson(
    parsedData: Array<ParsedLineData<FieldInfoConfiguration>>
  ): ValidateCallbackResult {
    const valid = parsedData.every((data) => {
      return (
        (!data.fields.personCompanyName &&
          !data.fields.personLastName &&
          !data.fields.personFirstName) ||
        !!this.getPersonByCompanyAndName(
          data.fields.personCompanyName ?? null,
          data.fields.personLastName ?? null,
          data.fields.personFirstName ?? null
        )
      );
    });

    return {
      valid,
      errorMsgTk: valid
        ? null
        : 'operations.editProcessConfigurationSteps.invalidPersons'
    };
  }

  protected async handleImportPositionsFromCsvFileSubmitted(
    detail: CallbackParams<FieldInfoConfiguration>
  ): Promise<void> {
    const processConfiguration = this.processConfiguration;
    assertNotNullOrUndefined(
      processConfiguration,
      'processConfiguration is not set'
    );

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        processConfiguration.id
      );

    const lineData = detail.parsedContent;

    for (const lD of lineData) {
      const step = steps.find((s) => s.name === lD.fields.stepName);
      if (!step) {
        console.warn('STEP NAME NOT FOUND!');
        continue;
      }

      if (
        lD.fields.personCompanyName ||
        lD.fields.personLastName ||
        lD.fields.personFirstName
      ) {
        const person = this.getPersonByCompanyAndName(
          lD.fields.personCompanyName ?? null,
          lD.fields.personLastName ?? null,
          lD.fields.personFirstName ?? null
        );
        if (!person) {
          console.warn('PERSON NOT FOUND!');
          continue;
        }
        this.createOrUpdatePosition(
          lD,
          step,
          person.id,
          detail.overwrite,
          detail.decimalSeparator
        );
      } else {
        this.createOrUpdatePosition(
          lD,
          step,
          null,
          detail.overwrite,
          detail.decimalSeparator
        );
      }
    }
  }

  private createOrUpdatePosition(
    lD: ParsedLineData<FieldInfoConfiguration>,
    processConfigurationStep: ProcessConfigurationStep,
    personId: string | null,
    overwrite: boolean,
    decimalSeparator: string
  ): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'processConfiguration is not set'
    );

    const positions =
      this.entityManager.processConfigurationStepPositionRepository.getByProcessConfigurationId(
        this.processConfiguration.id
      );

    const newPosition: ProcessConfigurationStepPositionCreationEntity = {
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
      processConfigurationStepId: processConfigurationStep.id,
      personId: personId
    };

    this.applyValuesToNewPosition(newPosition, lD);

    const position = positions.find((pos) => {
      if (lD.fields.internalId) {
        return pos.id === lD.fields.internalId;
      } else {
        return pos.customId === lD.fields.customId;
      }
    });

    if (position) {
      if (overwrite) {
        Object.assign(position, newPosition);
        this.entityManager.processConfigurationStepPositionRepository.update(
          position
        );
        this.importProperties({
          position,
          lineData: lD,
          decimalSeparator
        });
        console.log('-> SAVE:', position);
      } else {
        console.log(
          '-> FOUND EXISTING POSITION BUT OVERWRITE IS NOT ACTIVATED.'
        );
      }
    } else {
      const createdPosition =
        this.processConfigurationStepPositionCreationService.create({
          processConfigurationStep,
          positionOverrides: newPosition
        });
      this.importProperties({
        position: createdPosition,
        lineData: lD,
        decimalSeparator
      });
      console.log('-> CREATE:', createdPosition);
    }
  }

  private applyValuesToNewPosition(
    newPosition: ProcessConfigurationStepPositionCreationEntity,
    lineData: ParsedLineData<FieldInfoConfiguration>
  ): void {
    for (const [lineKey, lineValue] of Object.entries(lineData.fields)) {
      const fieldInfo = this.importFromCsvFileFieldInfos.find(
        (fInfo) => fInfo.field === lineKey
      );
      if (!fieldInfo || fieldInfo.extraField) continue;

      (newPosition[fieldInfo.field] as any) = lineValue;
    }
  }

  private getPersonByCompanyAndName(
    companyName: string | null,
    lastName: string | null,
    firstName: string | null
  ): Person | null {
    return (
      this.entityManager.personRepository.getAll().find((person) => {
        return (
          (person.companyName ?? '') === (companyName ?? '') &&
          (person.lastName ?? '') === (lastName ?? '') &&
          (person.firstName ?? '') === (firstName ?? '')
        );
      }) ?? null
    );
  }

  private importProperties({
    position,
    lineData,
    decimalSeparator
  }: {
    position: ProcessConfigurationStepPosition;
    lineData: ParsedLineData<FieldInfoConfiguration>;
    decimalSeparator: string;
  }): void {
    this.propertyImporter.import({
      properties:
        this.entityManager.propertyRepository.getByProcessConfigurationStepPositionId(
          position.id
        ),
      propertyValues: lineData.additionalFields,
      decimalSeparator
    });
  }
}

type FieldInfoConfiguration = {
  entityType: ProcessConfigurationStepPosition;
  fieldInfos: [
    {
      field: 'stepName';
      type: FieldType.STRING;
    },
    {
      field: 'internalId';
      type: FieldType.STRING;
    },
    {
      field: 'personCompanyName';
      type: FieldType.STRING;
    },
    {
      field: 'personLastName';
      type: FieldType.STRING;
    },
    {
      field: 'personFirstName';
      type: FieldType.STRING;
    },
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'type';
      type: FieldType.STRING;
    },
    {
      field: 'namePrefix';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'description';
      type: FieldType.STRING;
    },
    {
      field: 'price';
      type: FieldType.NUMBER;
    },
    {
      field: 'priceGroup';
      type: FieldType.STRING;
    },
    {
      field: 'unit';
      type: FieldType.STRING;
    },
    {
      field: 'discount';
      type: FieldType.NUMBER;
    },
    {
      field: 'discountNote';
      type: FieldType.STRING;
    },
    {
      field: 'flatRate';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'groupName';
      type: FieldType.STRING;
    },
    {
      field: 'subGroupName';
      type: FieldType.STRING;
    },
    {
      field: 'categoryName';
      type: FieldType.STRING;
    },
    {
      field: 'color';
      type: FieldType.STRING;
    },
    {
      field: 'excludeFromMarkup';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'ignoreExcludeFromMarkup';
      type: FieldType.BOOLEAN;
    },
    {
      field: 'deactivated';
      type: FieldType.BOOLEAN;
    }
  ];
};
