import { HooksContainer } from '@record-it-npm/synchro-client';
import { GlobalLoadingOverlay } from '../../../../loadingComponents/global-loading-overlay/global-loading-overlay';
import {
  Disposable,
  DisposableContainer
} from '../../../Utils/DisposableContainer';
import { PendingPromiseMap } from '../../../Utils/PendingPromiseMap';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';

export class JoinedProcessTaskGroupManager {
  private started: boolean = false;
  private joinedProcessTaskGroupIds: Array<string> = [];
  private disposableContainer: DisposableContainer = new DisposableContainer();
  private finishedAutoJoiningProcessTaskGroups: boolean = false;

  private readonly hooksContainer: HooksContainer<JoinedProcessTaskGroupManagerHooks> =
    new HooksContainer();

  private readonly pendingJoinPromisesMap: PendingPromiseMap<string, void> =
    new PendingPromiseMap();

  private readonly pendingLeavePromisesMap: PendingPromiseMap<string, void> =
    new PendingPromiseMap();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly options: Options
  ) {}

  public start(): void {
    this.started = true;
    this.autoJoinProcessTaskGroups();

    const boundUpdateJoinedProcessTaskGroups = Utils.rateLimitFunction(
      this.updateJoinedProcessTaskGroups.bind(this, true),
      100
    );
    this.disposableContainer.add(
      boundUpdateJoinedProcessTaskGroups.toCancelDisposable()
    );

    this.disposableContainer.add(
      this.entityManager.processTaskGroupRepository.registerHooks({
        afterEntityUpdated: boundUpdateJoinedProcessTaskGroups,
        afterEntityDeleted: boundUpdateJoinedProcessTaskGroups,
        afterEntityRemovedLocally: boundUpdateJoinedProcessTaskGroups,
        afterEntityUpdatedLocally: boundUpdateJoinedProcessTaskGroups
      })
    );

    this.disposableContainer.add(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: boundUpdateJoinedProcessTaskGroups
      })
    );

    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.ProcessTaskGroup,
        this.handleProcessTaskGroupIdUpgraded.bind(this)
      )
    );

    this.updateRelativeSynchronizationEntityIds(); // remove old synchronization entity ids immediately
  }

  public stop(): void {
    this.started = false;

    this.disposableContainer.disposeAll();
    this.pendingJoinPromisesMap.clear();
    this.pendingLeavePromisesMap.clear();
    this.joinedProcessTaskGroupIds = [];
  }

  public isStarted(): boolean {
    return this.started;
  }

  public joinAllProcessTaskGroups(): void {
    if (!this.started) {
      throw new Error(
        "can't use the joinedProcessTaskGroupManager when it's not started"
      );
    }

    GlobalLoadingOverlay.setLoadingState(this, true);
    void this.updateJoinedProcessTaskGroups(true);
  }

  public autoJoiningProcessTaskGroupsIsFinished(): boolean {
    return this.finishedAutoJoiningProcessTaskGroups;
  }

  public registerHooks(hooks: JoinedProcessTaskGroupManagerHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  private autoJoinProcessTaskGroups(): void {
    void this.updateJoinedProcessTaskGroups(false).then(() => {
      this.finishedAutoJoiningProcessTaskGroups = true;
      this.hooksContainer.callHooks('autoJoiningProcessTaskGroupsFinished');
    });
  }

  private async updateJoinedProcessTaskGroups(
    actualizeAfterJoining: boolean
  ): Promise<void> {
    this.updateRelativeSynchronizationEntityIds();

    const relevantProcessTaskGroups =
      this.entityManager.processTaskGroupRepository.getAll();

    const missingIds = relevantProcessTaskGroups
      .filter((p) => this.joinedProcessTaskGroupIds.indexOf(p.id) === -1)
      .map((p) => p.id);

    const superflousIds = this.joinedProcessTaskGroupIds.filter(
      (id) => !relevantProcessTaskGroups.find((p) => p.id === id)
    );

    const promises = [
      ...missingIds.map((id) =>
        this.pendingJoinPromisesMap.getOrCreate(id, () =>
          this.joinProcessTaskGroup(id)
        )
      ),
      ...superflousIds.map((id) =>
        this.pendingLeavePromisesMap.getOrCreate(id, () =>
          this.leaveProcessTaskGroup(id)
        )
      )
    ];

    Promise.all(promises)
      .then(() => {
        if (promises.length && actualizeAfterJoining) {
          void this.entityManager.entityActualization.actualize();
        }
      })
      .finally(() => {
        GlobalLoadingOverlay.setLoadingState(this, false);
      });
  }

  private async joinProcessTaskGroup(
    processTaskGroupId: string
  ): Promise<void> {
    await this.options.joinProcessTaskGroup(processTaskGroupId);
    if (!this.joinedProcessTaskGroupIds.includes(processTaskGroupId)) {
      this.joinedProcessTaskGroupIds.push(processTaskGroupId);
    }
  }

  private async leaveProcessTaskGroup(
    processTaskGroupId: string
  ): Promise<void> {
    await this.options.leaveProcessTaskGroup(processTaskGroupId);

    const index = this.joinedProcessTaskGroupIds.indexOf(processTaskGroupId);
    if (index >= 0) {
      this.joinedProcessTaskGroupIds.splice(index, 1);
    }
  }

  private handleProcessTaskGroupIdUpgraded(): void {
    for (const id of this.joinedProcessTaskGroupIds) {
      const originalProcessTaskGroup =
        this.entityManager.processTaskGroupRepository.getByOriginalId(id);
      if (originalProcessTaskGroup) {
        void this.leaveProcessTaskGroup(id);
        void this.joinProcessTaskGroup(originalProcessTaskGroup.id);
      }
    }
  }

  private updateRelativeSynchronizationEntityIds(): void {
    const processTaskGroupIds = this.entityManager.processTaskGroupRepository
      .getAll()
      .map((pt) => pt.id);
    const registeredIds =
      this.entityManager.entityActualization.getRelativeSynchronizationEntityIdsByEntityName(
        EntityName.ProcessTaskGroup
      );

    const idsToAdd = processTaskGroupIds.filter(
      (id) => !registeredIds.includes(id)
    );
    for (const toAdd of idsToAdd) {
      this.entityManager.entityActualization.addRelativeSynchronizationEntityId(
        EntityName.ProcessTaskGroup,
        toAdd
      );
    }

    const idsToRemove = registeredIds.filter(
      (id) => !processTaskGroupIds.includes(id)
    );
    for (const toRemove of idsToRemove) {
      this.entityManager.entityActualization.removeRelativeSynchronizationEntityId(
        EntityName.ProcessTaskGroup,
        toRemove
      );
    }
  }
}

export type Options = {
  joinProcessTaskGroup: (processTaskGroupId: string) => Promise<void>;
  leaveProcessTaskGroup: (processTaskGroupId: string) => Promise<void>;
};

export type JoinedProcessTaskGroupManagerHooks = {
  autoJoiningProcessTaskGroupsFinished?: () => void;
};
