export class FileChunkUploaderSettings {
  private constructor() {}

  private static chunkSize: number = 100 * 1024; // 100 KiB;

  /**
   *
   * @param chunkSize - in bytes
   */
  public static setChunkSize(chunkSize: number): void {
    this.chunkSize = chunkSize;
  }

  /**
   * in bytes
   */
  public static getChunkSize(): number {
    return this.chunkSize;
  }
}
