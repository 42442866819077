import { AbstractLogDataConverter } from './AbstractLogDataConverter';
import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationActionStatusUtils } from '../../../classes/EntityManager/entities/ProcessConfigurationActionStatus/ProcessConfigurationActionStatusUtils';

@inject(AppEntityManager)
export class ProcessConfigurationActionStatusDataConverter extends AbstractLogDataConverter {
  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    super();

    this._entityManager = entityManager;
  }

  /**
   * @param {import('./AbstractLogDataConverter').ConvertOptions<never>} options
   */
  convert(options) {
    options.logData.beforeDisplayName = this._convertChangeData(
      options.logData.before
    );
    options.logData.afterDisplayName = this._convertChangeData(
      options.logData.after
    );
  }

  /**
   * @param {import('../../../../../common/src/processTaskLogEntryActions').ProcessConfigurationActionStatusModifiedChangeData} changeData
   * @returns {string}
   */
  _convertChangeData(changeData) {
    if (changeData.processConfigurationActionStatusId) {
      const actionStatus =
        this._entityManager.processConfigurationActionStatusRepository.getById(
          changeData.processConfigurationActionStatusId
        );
      return actionStatus
        ? ProcessConfigurationActionStatusUtils.getFullDisplayNameForProcessConfigurationActionStatus(
            actionStatus
          )
        : '';
    }

    /** @type {Array<string>} */
    const parts = [];

    if (changeData.customActionStatusAbbreviation) {
      parts.push(changeData.customActionStatusAbbreviation);
    }

    if (changeData.customActionStatusName) {
      parts.push(changeData.customActionStatusName);
    }

    return parts.join(' - ');
  }
}
