import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationActionStatus } from '../../classes/EntityManager/entities/ProcessConfigurationActionStatus/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessConfigurationActionStatesWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private isAttached = false;
  protected actionStates: Array<ProcessConfigurationActionStatus> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationActionStatesWidget,
        propertyName: 'processConfiguration'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationActionStatus,
      this.updateActionStates.bind(this)
    );
    this.updateActionStates();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateActionStates();
    }
  }

  private updateActionStates(): void {
    if (this.processConfiguration) {
      this.actionStates =
        this.entityManager.processConfigurationActionStatusRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.actionStates = [];
    }
  }

  protected handleAddActionStatusClick(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      "can't ProcessConfigurationActionStatesWidget.handleAddActionStatusClick without processConfiguration"
    );

    this.entityManager.processConfigurationActionStatusRepository.create({
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
      ownerProcessConfigurationId: this.processConfiguration.id
    });

    this.updateActionStates();
  }
}
