import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { CirclePreloader } from '../circle-preloader/circle-preloader';

/**
 * a section which initially only shows an add button
 * if the button is clicked, the button will be hidden and a custom section will be shown
 *
 * @slot default - the content which gets shown when the user wants to add something new
 *
 * @event create-button-click - preventDefault() this event to cancel the auto closing of the section
 * @event cancel-button-click - preventDefault() this event to cancel the auto closing of the section
 * @event creation-section-opened
 * @event creation-section-closed
 *
 * @attribute data-left-align-open-button - align the open button to the left border
 */
@autoinject()
export class InlineCreationSection {
  /**
   * text additional to the icon for the button which opens the add section
   */
  @bindable()
  public openAddSectionButtonText: string = '';

  @bindable()
  public addButtonText: string = '';

  @bindable()
  public cancelButtonText: string = '';

  @bindable()
  public openAddSectionButtonIconType: string = 'far';

  @bindable()
  public openAddSectionButtonIconName: string = 'fa-plus-circle';

  @bindable()
  public addButtonEnabled: boolean = false;

  private addSectionAnimator: ShowHideAnimator | null = null;
  protected openAddSectionButtonVisible: boolean = true;
  protected addSectionElement: HTMLElement | null = null;
  protected circlePreloader: CirclePreloader | null = null;

  constructor(private readonly element: Element) {}

  public open(): void {
    if (!this.addSectionAnimator) {
      throw new Error('element not attached!');
    }

    this.openAddSectionButtonVisible = false;
    void this.addSectionAnimator.fadeSlideDown().then(() => {
      this.fireEvent('creation-section-opened');
    });
  }

  public close(): void {
    if (!this.addSectionAnimator) {
      throw new Error('element not attached!');
    }

    void this.addSectionAnimator.fadeSlideUp().then(() => {
      this.openAddSectionButtonVisible = true;
      this.fireEvent('creation-section-closed');
    });
  }

  public startPreloader(): void {
    this.circlePreloader?.start();
  }

  public stopPreloader(): void {
    this.circlePreloader?.stop();
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.addSectionElement,
      'add section element is missing'
    );

    this.addSectionAnimator = new ShowHideAnimator(this.addSectionElement);
  }

  protected detached(): void {
    this.addSectionAnimator = null;
  }

  protected handleOpenAddSectionButtonClick(): void {
    this.open();
  }

  protected handleAddSectionCreateClick(): void {
    if (!this.fireEvent('create-button-click')) {
      this.close();
    }
  }

  protected handleAddSectionCancelClick(): void {
    if (!this.fireEvent('cancel-button-click')) {
      this.close();
    }
  }

  /**
   * @returns - defaultPrevented
   */
  private fireEvent(name: string): boolean {
    const event = DomEventHelper.fireEvent(this.element, {
      name: name,
      detail: null
    });

    return event.defaultPrevented;
  }
}
