import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';

/**
 * @event value-changed triggered when the user selects a value
 */
@autoinject()
export class ProcessConfigurationStepSelect {
  /**
   * id of the selected ProcessConfigurationStep
   */
  @bindable public value: string | null = null;

  @bindable public processConfigurationId: string | null = null;

  /**
   * displays a default label if no label is given
   */
  @bindable public label: string | null = null;

  @bindable public enabled = false;

  @bindable public excludedProcessConfigurationStepIds: Array<string> = [];

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager
  ) {
    this.element = element as HTMLElement;
  }

  @computed(
    expression('processConfigurationId'),
    model(EntityName.ProcessConfigurationStep),
    arrayChanges('excludedProcessConfigurationStepIds')
  )
  protected get selectableProcessConfigurationSteps(): Array<ProcessConfigurationStep> {
    if (!this.processConfigurationId) {
      return [];
    }

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        this.processConfigurationId
      );

    return steps.filter((step) => {
      return !this.excludedProcessConfigurationStepIds.some(
        (stepId) => stepId === step.id
      );
    });
  }

  protected handleSelectChanged(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent(this.element, {
        name: 'value-changed',
        detail: null
      });
    }, 0);
  }
}
