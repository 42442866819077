import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { StringToBooleanValueConverter } from '../../valueConverters/StringToBooleanValueConverter';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertyCheckboxWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyCheckboxWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  constructor(
    private readonly stringToBooleanValueConverter: StringToBooleanValueConverter
  ) {}

  public focus(): void {
    throw new Error("BasePropertyCheckboxWidget doesn't support focus");
  }

  protected handleCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyCheckboxWidget.handleCheckedChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: this.stringToBooleanValueConverter.fromView(event.detail.checked),
      customChoice: null
    });
  }
}

export const basePropertyCheckboxWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
