import { DisposableContainer } from '../../../classes/Utils/DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../../../classes/Utils/Utils';
import { DaySpecificCalendarEntry } from './CalendarEntry';
import {
  CalendarEntryDataSourceStrategy,
  GetCalendarEntriesOptions
} from './strategies/CalendarEntryDataSourceStrategy';

export class CalendarEntryDataSource {
  private readonly registeredHandles = new Set<CalendarEntriesHandle>();
  private readonly disposableContainer = new DisposableContainer();

  constructor(private readonly strategy: CalendarEntryDataSourceStrategy) {}

  public createHandle(options: CreateHandleOptions): CalendarEntriesHandle {
    if (this.registeredHandles.size === 0) {
      this.setupSubscriptions();
    }

    const handle = new CalendarEntriesHandle({
      ...options,
      strategy: this.strategy,
      onDestroy: () => {
        this.unregisterHandle(handle);
      }
    });

    this.registeredHandles.add(handle);

    return handle;
  }

  private setupSubscriptions(): void {
    this.disposableContainer.add(this.strategy.subscribe());

    this.disposableContainer.add(
      this.strategy.registerOnDataChanged(() => {
        this.updateHandles();
      })
    );
  }

  private unregisterHandle(handle: CalendarEntriesHandle): void {
    this.registeredHandles.delete(handle);

    if (this.registeredHandles.size === 0) {
      this.disposableContainer.disposeAll();
    }
  }

  private updateHandles(): void {
    for (const handle of this.registeredHandles.values()) {
      void handle.update();
    }
  }
}

export class CalendarEntriesHandle {
  private readonly strategy: CalendarEntryDataSourceStrategy;
  private readonly onDestroy: () => void;
  private readonly onNewCalendarEntries: OnNewCalendarEntries;
  private getCalendarEntriesOptions: GetCalendarEntriesOptions;
  private updateRateLimited: IUtilsRateLimitedFunction;

  constructor({
    strategy,
    getCalendarEntriesOptions,
    onDestroy,
    onNewCalendarEntries
  }: CalendarEntryHandleOptions) {
    this.strategy = strategy;
    this.getCalendarEntriesOptions = getCalendarEntriesOptions;
    this.onDestroy = onDestroy;
    this.onNewCalendarEntries = onNewCalendarEntries;
    this.updateRateLimited = Utils.rateLimitFunction(this.update.bind(this), 0);

    this.updateRateLimited();
  }

  public updateGetCalendarEntriesOptions(
    getCalendarEntriesOptions: GetCalendarEntriesOptions
  ): void {
    this.getCalendarEntriesOptions = getCalendarEntriesOptions;
    this.updateRateLimited();
  }

  public destroy(): void {
    this.onDestroy();
  }

  public async update(): Promise<void> {
    this.onNewCalendarEntries(
      await this.strategy.getCalendarEntries(this.getCalendarEntriesOptions)
    );
  }
}

type CalendarEntryHandleOptions = {
  strategy: CalendarEntryDataSourceStrategy;
  getCalendarEntriesOptions: GetCalendarEntriesOptions;
  onDestroy: () => void;
  onNewCalendarEntries: OnNewCalendarEntries;
};

export type CreateHandleOptions = Pick<
  CalendarEntryHandleOptions,
  'onNewCalendarEntries' | 'getCalendarEntriesOptions'
>;

export type OnNewCalendarEntries = (
  calendarEntries: Array<DaySpecificCalendarEntry>
) => void;
