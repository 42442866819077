import { bindable, autoinject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointmentToProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskPosition/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskAppointmentToProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskDevice/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';

@autoinject()
export class ProcessTaskAppointmentsOverview {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public currentAppointmentId: string | null = null;

  /**
   * read-only!
   * count of the displayed positions
   * is null when no devices are loaded
   */
  @bindable()
  public appointmentCount: number | null = null;

  private subscriptionManager: SubscriptionManager;
  private appointments: Array<ProcessTaskAppointment> = [];
  private appointmentToPositionsByAppointmentId: Map<
    string,
    Array<ProcessTaskAppointmentToProcessTaskPosition>
  > = new Map();
  private positions: Array<ProcessTaskPosition> = [];
  private appointmentToDevicesByAppointmentId: Map<
    string,
    Array<ProcessTaskAppointmentToProcessTaskDevice>
  > = new Map();
  private devices: Array<ProcessTaskDevice> = [];
  private appointmentToUsersByAppointmentId: Map<
    string,
    Array<ProcessTaskAppointmentToUser>
  > = new Map();
  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();
  private appointmentInfos: Array<AppointmentInfo> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      () => {
        this.updateAppointments();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      () => {
        this.updateAppointmentToPositionsByAppointmentId();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      () => {
        this.updateAppointmentToPositionsByAppointmentId();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToUser,
      () => {
        this.updateAppointmentToUsersByAppointmentId();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      () => {
        this.updatePositions();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      () => {
        this.updateDevices();
        this.updateAppointmentInfos();
      }
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateAppointmentInfos();
        }
      })
    );

    this.updateAppointments();
    this.updatePositions();
    this.updateDevices();
    this.updateAppointmentInfos();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAppointments();
      this.updatePositions();
      this.updateDevices();
      this.updateAppointmentInfos();
    }
  }

  private updateAppointments(): void {
    if (this.processTask) {
      this.appointments =
        this.entityManager.processTaskAppointmentRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.appointmentCount = this.appointments.length;
    } else {
      this.appointments = [];
      this.appointmentCount = null;
    }

    this.updateAppointmentToPositionsByAppointmentId();
    this.updateAppointmentToDevicesByAppointmentId();
    this.updateAppointmentToUsersByAppointmentId();
  }

  private updateAppointmentToPositionsByAppointmentId(): void {
    const appointmentIds = this.appointments.map((a) => a.id);
    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentIds(
        appointmentIds
      );
    this.appointmentToPositionsByAppointmentId = Utils.groupBy(
      relations,
      (r) => r.processTaskAppointmentId
    );
  }

  private updateAppointmentToUsersByAppointmentId(): void {
    const appointmentIds = this.appointments.map((a) => a.id);
    const relations =
      this.entityManager.processTaskAppointmentToUserRepository.getByProcessTaskAppointmentIds(
        appointmentIds
      );
    this.appointmentToUsersByAppointmentId = Utils.groupBy(
      relations,
      (r) => r.processTaskAppointmentId
    );
  }

  private updatePositions(): void {
    if (this.processTask) {
      this.positions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        );
    } else {
      this.positions = [];
    }
  }

  private updateAppointmentToDevicesByAppointmentId(): void {
    const appointmentIds = this.appointments.map((a) => a.id);
    const relations =
      this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentIds(
        appointmentIds
      );
    this.appointmentToDevicesByAppointmentId = Utils.groupBy(
      relations,
      (r) => r.processTaskAppointmentId
    );
  }

  private updateDevices(): void {
    if (this.processTask) {
      this.devices =
        this.entityManager.processTaskDeviceRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        );
    } else {
      this.devices = [];
    }
  }

  private updateAppointmentInfos(): void {
    const result = this.generateInfosForAppointments();

    const unusedPositions = this.positions.filter(
      (p) => !result.usedPositions.has(p)
    );
    const unusedDevices = this.devices.filter(
      (p) => !result.usedDevices.has(p)
    );

    if (unusedPositions.length || unusedDevices.length) {
      result.infos.push({
        appointment: null,
        users: [],
        dateInfo: null,
        positions: unusedPositions,
        devices: unusedDevices
      });
    }

    this.sortAppointmentInfos(result.infos);

    this.appointmentInfos = result.infos;
  }

  private generateInfosForAppointments(): {
    infos: Array<AppointmentInfo>;
    usedPositions: Set<ProcessTaskPosition>;
    usedDevices: Set<ProcessTaskDevice>;
  } {
    const infos: Array<AppointmentInfo> = [];
    const usedPositions: Set<ProcessTaskPosition> = new Set();
    const usedDevices: Set<ProcessTaskDevice> = new Set();

    for (const appointment of this.appointments) {
      const positions = this.getPositionsForAppointment(appointment);
      const devices = this.getDevicesForAppointment(appointment);
      const dateInfo =
        this.processTaskAppointmentDateInfoMap.get(appointment.id) ?? null;

      for (const position of positions) {
        usedPositions.add(position);
      }

      for (const device of devices) {
        usedDevices.add(device);
      }

      infos.push({
        appointment,
        users: this.getUsersForAppointment(appointment),
        dateInfo,
        positions,
        devices
      });
    }

    return { infos, usedPositions, usedDevices };
  }

  private getPositionsForAppointment(
    appointment: ProcessTaskAppointment
  ): Array<ProcessTaskPosition> {
    const relations =
      this.appointmentToPositionsByAppointmentId.get(appointment.id) || [];
    const positionIds = relations.map((r) => r.processTaskPositionId);
    return this.positions.filter((p) => positionIds.includes(p.id));
  }

  private getDevicesForAppointment(
    appointment: ProcessTaskAppointment
  ): Array<ProcessTaskDevice> {
    const relations =
      this.appointmentToDevicesByAppointmentId.get(appointment.id) || [];
    const deviceIds = relations.map((r) => r.processTaskDeviceId);
    return this.devices.filter((p) => deviceIds.includes(p.id));
  }

  private getUsersForAppointment(
    appointment: ProcessTaskAppointment
  ): Array<User> {
    const relations =
      this.appointmentToUsersByAppointmentId.get(appointment.id) || [];
    const userIds = relations.map((r) => r.userId);
    return this.entityManager.userRepository.getByIds(userIds);
  }

  /**
   * @param infos - gets sorted in place
   */
  private sortAppointmentInfos(infos: Array<AppointmentInfo>): void {
    infos.sort((a, b) => {
      return this.getAppointmentInfoSortValue(a).localeCompare(
        this.getAppointmentInfoSortValue(b)
      );
    });
  }

  private getAppointmentInfoSortValue(info: AppointmentInfo): string {
    if (!info.appointment) {
      return 'ZZZZZZZZZZZZZZZZZZZZZZ';
    }

    if (!info.dateInfo?.dateFrom) {
      return 'ZZZZZZZZZZ';
    }

    return info.dateInfo.dateFrom;
  }
}

type AppointmentInfo = {
  appointment: ProcessTaskAppointment | null;
  users: Array<User>;
  dateInfo: ProcessTaskAppointmentDateInfo | null;
  positions: Array<ProcessTaskPosition>;
  devices: Array<ProcessTaskDevice>;
};
