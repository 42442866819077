import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { computed } from '../../hooks/computed';
import { model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  QuestionCatalogueSortOptions,
  QuestionCatalogueUtils
} from '../../classes/EntityManager/entities/QuestionCatalogue/QuestionCatalogueUtils';

@autoinject()
export class ChecklistQuestionCatalogueList {
  /**
   * question catalogues retrieved from a sorter.
   * sourced from `questionCatalogues`.
   */
  protected sortedQuestionCatalogues: Array<QuestionCatalogue> = [];

  /**
   * question catalogues paginated by pagination.
   * sourced from `sortedQuestionCatalogues`.
   */
  protected paginatedQuestionCatalogues: Array<QuestionCatalogue> = [];

  protected sortOptions = QuestionCatalogueUtils.getSortOptions();

  protected currentSortOption: QuestionCatalogueSortOptions[keyof QuestionCatalogueSortOptions] =
    this.sortOptions.name;

  constructor(private readonly entityManager: AppEntityManager) {}

  @computed(model(EntityName.QuestionCatalogue))
  protected get questionCatalogues(): Array<QuestionCatalogue> {
    return this.entityManager.questionCatalogueRepository.getAll();
  }
}
