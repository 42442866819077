import { bindable, inject } from 'aurelia-framework';

import { EntityGroupUtils } from 'common/EntityGrouper/EntityGroupUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTaskDeviceGroupsForProcessTaskIdComputer } from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * this is just a sub-component of the ProcessTaskOfferTasksAndPositionsWidget and also uses its styling
 */
@inject(
  AppEntityManager,
  ComputedValueService,
  SubscriptionManagerService,
  PermissionsService
)
export class ProcessTaskOfferRelationsWidgetDevices {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskOffer/types').ProcessTaskOffer|null} */
  @bindable processTaskOffer = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {boolean} */
  @bindable allDevicesSelected = false;

  /**
   * is null when devices are not loaded
   * read only
   *
   * @type {number|null}
   */
  @bindable processTaskDeviceCount = null;

  /** @type {import('../../services/PermissionsService/entityNameToPermissionsConfig').EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer]} */
  @subscribableLifecycle()
  _permissionHandle;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskDevice/types').ProcessTaskDevice>} */
  _processTaskDevices = [];
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types').ProcessTaskOfferToProcessTaskDevice>} */
  _processTaskOfferToProcessTaskDevices = [];
  /** @type {boolean} */
  _attached = false;

  /**
   * @param {AppEntityManager} entityManager
   * @param {ComputedValueService} computedValueService
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {PermissionsService} permissionsService
   */
  constructor(
    entityManager,
    computedValueService,
    subscriptionManagerService,
    permissionsService
  ) {
    this._entityManager = entityManager;
    this._computedValueService = computedValueService;
    this._permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });

    this._subscriptionManager = subscriptionManagerService.create();
  }

  selectAll() {
    this._processTaskDevices.forEach((device) => {
      this._ensureRelationStatusToDevice(device, true);
    });

    this._updateProcessTaskOfferToProcessTaskDevices();
  }

  deselectAll() {
    this._processTaskDevices.forEach((device) => {
      this._ensureRelationStatusToDevice(device, false);
    });

    this._updateProcessTaskOfferToProcessTaskDevices();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskDevice,
      this._updateProcessTaskOfferToProcessTaskDevices.bind(this)
    );
    this._updateProcessTaskOfferToProcessTaskDevices();

    this._subscriptionManager.addDisposable(
      this._computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this._groupedDevices = groupedDevices;
          this._processTaskDevices =
            EntityGroupUtils.getAllEntities(groupedDevices);
          this.processTaskDeviceCount = this._processTaskDevices.length;
          this._updateAllDevicesSelected();
        },
        createComputeData: () => {
          return this.processTask
            ? { processTaskId: this.processTask.id }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this._subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskChanged() {
    if (this._attached) {
      this._updateProcessTaskOfferToProcessTaskDevices();
    }
  }

  processTaskInvoiceChanged() {
    if (this._attached) {
      this._updateProcessTaskOfferToProcessTaskDevices();
    }
  }

  _updateProcessTaskOfferToProcessTaskDevices() {
    if (this.processTask && this.processTaskOffer) {
      this._processTaskOfferToProcessTaskDevices =
        this._entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferIdAndProcessTaskId(
          this.processTaskOffer.id,
          this.processTask.id
        );
    } else {
      this._processTaskOfferToProcessTaskDevices = [];
    }

    this._updateAllDevicesSelected();
  }

  _updateAllDevicesSelected() {
    this.allDevicesSelected = this._processTaskDevices.every((device) => {
      return this._processTaskOfferToProcessTaskDevices.find(
        (otd) => otd.processTaskDeviceId === device.id
      );
    });
  }

  /**
   * @param {import('../../inputComponents/custom-checkbox/custom-checkbox').CustomCheckboxCheckedChangedEvent} event
   * @param {import('../../classes/EntityManager/entities/ProcessTaskDevice/types').ProcessTaskDevice} device
   */
  _handleCheckboxChanged(event, device) {
    this._ensureRelationStatusToDevice(device, event.detail.checked);
    this._updateProcessTaskOfferToProcessTaskDevices();
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskDevice/types').ProcessTaskDevice} device
   * @param {boolean} available - true if a relation should exist, false to to ensure no relation exists
   */
  _ensureRelationStatusToDevice(device, available) {
    if (!this.processTaskOffer) {
      throw new Error(
        "can't ensure relation status to device without a processTaskOffer"
      );
    }

    if (!this.processTask) {
      throw new Error(
        "can't ensure relation status to device without a processTask"
      );
    }

    const existingRelation =
      this._entityManager.processTaskOfferToProcessTaskDeviceRepository.getByProcessTaskOfferIdAndProcessTaskDeviceId(
        this.processTaskOffer.id,
        device.id
      );

    if (available) {
      if (!existingRelation) {
        this._entityManager.processTaskOfferToProcessTaskDeviceRepository.create(
          {
            processTaskOfferId: this.processTaskOffer.id,
            processTaskDeviceId: device.id,
            ownerProcessTaskId: this.processTask.id,
            ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTask.ownerUserGroupId
          }
        );
      }
    } else {
      if (existingRelation) {
        this._entityManager.processTaskOfferToProcessTaskDeviceRepository.delete(
          existingRelation
        );
      }
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskDevice/types').ProcessTaskDevice} device
   * @returns {string|null}
   */
  _getDisplayNameOfDevice(device) {
    const config =
      this._entityManager.processConfigurationDeviceRepository.getById(
        device.processConfigurationDeviceId
      );
    return config ? config.name : null;
  }

  /**
   * @param {string} deviceId
   * @param {Array<import('../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskDevice/types').ProcessTaskOfferToProcessTaskDevice>} processTaskOfferToProcessTaskDevices
   * @returns {boolean}
   */
  _deviceIdIsIncluded(deviceId, processTaskOfferToProcessTaskDevices) {
    return !!processTaskOfferToProcessTaskDevices.find(
      (r) => r.processTaskDeviceId === deviceId
    );
  }
}
