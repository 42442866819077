import { bindable, autoinject } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationAuthorizationType } from '../../classes/EntityManager/entities/ProcessConfigurationAuthorizationType/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';

@autoinject()
export class ProcessConfigurationAuthorizationTypeSelect {
  @bindable()
  public value: string | null = null;

  @bindable()
  public processConfigurationId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * The string to display if no value is selected.
   */
  @bindable()
  public nullOption: string | null = null;

  /**
   * The label to display for the dropdown.
   * Setting this to null shows the default label.
   */
  @bindable()
  public label: string | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager
  ) {}

  @computed(
    expression('processConfigurationId'),
    model(EntityName.ProcessConfigurationAuthorizationType)
  )
  protected get authorizationTypes(): Array<ProcessConfigurationAuthorizationType> {
    if (!this.processConfigurationId) {
      return this.entityManager.processConfigurationAuthorizationTypeRepository.getAll();
    }

    return this.entityManager.processConfigurationAuthorizationTypeRepository.getByProcessConfigurationId(
      this.processConfigurationId
    );
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<string, ProcessConfigurationAuthorizationType>
  ): void {
    DomEventHelper.fireEvent<ProcessConfigurationAuthorizationTypeSelectValueChangedEvent>(
      this.element,
      {
        name: 'value-changed',
        detail: { value: event.detail.value }
      }
    );
  }
}

export type ProcessConfigurationAuthorizationTypeSelectValueChangedEvent =
  NamedCustomEvent<'value-changed', { value: string | null }>;
