import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { pictureFileEntityInfo } from '../../../../classes/EntityManager/entities/PictureFile/pictureFileEntityInfo';
import { PictureFile } from '../../../../classes/EntityManager/entities/PictureFile/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { PictureAdapter } from '../PictureAdapter/PictureAdapter';

export class PictureFileAdapter implements EntityAdapter<PictureFile> {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  private pictureAdapter: PictureAdapter | null = null;

  constructor(options: PictureFileAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe({
    updateBindings,
    bindAdapter
  }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      bindAdapter({
        entityName: EntityName.Picture,
        onNextAdapter: ({ adapter }) => {
          this.pictureAdapter = adapter;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public canDeleteEntity(pictureFile: PictureFile): boolean {
    return this.canEditPictureFile(pictureFile);
  }

  public canEditField(pictureFile: PictureFile): boolean {
    return this.canEditPictureFile(pictureFile);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PictureFile,
    PictureFile
  > {
    return pictureFileEntityInfo;
  }

  private canEditPictureFile(pictureFile: PictureFile): boolean {
    if (!this.pictureAdapter) {
      return false;
    }

    const picture = this.entityManager.pictureRepository.getById(
      pictureFile.picture
    );

    if (!picture) {
      return false;
    }

    return this.pictureAdapter.canEditPictureFiles(picture);
  }
}

export type PictureFileAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
};
