import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskGroupSubEntityUtils } from '../../BaseEntities/ProcessTaskGroupSubEntityUtils';
import { ProcessTaskInvoiceDto } from './ProcessTaskInvoiceDto';

export function createProcessTaskInvoiceEntityInfo<
  TEntity extends ProcessTaskInvoiceDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskInvoiceDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskInvoice,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskInvoice,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskInvoiceDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskGroupSubEntityUtils.createBaseFieldInfos(),
        processConfigurationCategoryId: {
          name: 'processConfigurationCategoryId',
          ref: EntityName.ProcessConfigurationCategory,
          defaultValue: null
        },
        inspectorUserId: {
          name: 'inspectorUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        invoiceReceiverPersonId: {
          name: 'invoiceReceiverPersonId',
          ref: EntityName.Person,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        introduction: {
          name: 'introduction',
          defaultValue: null
        },
        ending: {
          name: 'ending',
          defaultValue: null
        },
        excludeVat: {
          name: 'excludeVat',
          defaultValue: false
        },
        noteInternal: {
          name: 'noteInternal',
          defaultValue: null
        },
        serialNumber: {
          name: 'serialNumber',
          defaultValue: null
        },
        globalSequenceNumber: {
          name: 'globalSequenceNumber',
          defaultValue: null
        },
        doneAt: {
          name: 'doneAt',
          defaultValue: null
        },
        reverted: {
          name: 'reverted',
          defaultValue: false
        },
        inverted: {
          name: 'inverted',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
