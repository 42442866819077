import _ from 'lodash';

import { Vector } from 'common/Geometry/Vector';
import { TooltipContentHelper } from './TooltipContentHelper';
import { TRectangleToRectangleAlignerAlignmentMethod } from 'common/Aligner/RectangleToRectangleAligner';

export class TooltipContentMaxSizeSolver {
  private contentElement: HTMLElement;
  private slottedElement: HTMLElement;

  private lastScrollTop: number | null = null;

  private screenMargin = 5;

  constructor(contentElement: HTMLElement, slottedElement: HTMLElement) {
    this.contentElement = contentElement;
    this.slottedElement = slottedElement;
  }

  public solve(
    alignmentMethod: TRectangleToRectangleAlignerAlignmentMethod
  ): void {
    const contentElementRect = TooltipContentHelper.getContentBoundingRectangle(
      this.contentElement,
      this.slottedElement,
      true
    );
    const clampedTopLeftPoint = this.createWindowClampedVector(
      contentElementRect.position.getX(),
      contentElementRect.position.getY()
    );

    const clampedBottomRightPoint = this.createWindowClampedVector(
      contentElementRect.position.getX() + contentElementRect.size.getX(),
      contentElementRect.position.getY() + contentElementRect.size.getY()
    );

    const maxSize = this.calculateMaxSize(
      clampedTopLeftPoint,
      clampedBottomRightPoint,
      alignmentMethod
    );

    this.contentElement.style.left = clampedTopLeftPoint.getX() + 'px';
    this.contentElement.style.top =
      clampedTopLeftPoint.getY() +
      TooltipContentHelper.getScrollOffset() +
      'px';

    this.slottedElement.style.maxWidth = maxSize.getX() + 'px';
    this.slottedElement.style.maxHeight = maxSize.getY() + 'px';
  }

  private calculateMaxSize(
    clampedTopLeftPoint: Vector,
    clampedBottomRightPoint: Vector,
    alignmentMethod: TRectangleToRectangleAlignerAlignmentMethod
  ): Vector {
    let yStart = this.screenMargin;
    let yEnd = window.innerHeight - this.screenMargin;

    if (
      alignmentMethod.referenceRectangleAlignment.getY() >
      alignmentMethod.alignedRectangleAlignment.getY()
    ) {
      yStart = clampedTopLeftPoint.getY();
    } else {
      yEnd = clampedBottomRightPoint.getY();
    }

    let xStart = this.screenMargin;
    let xEnd = window.innerWidth - this.screenMargin;

    if (
      alignmentMethod.referenceRectangleAlignment.getX() >
      alignmentMethod.alignedRectangleAlignment.getX()
    ) {
      xStart = clampedTopLeftPoint.getX();
    } else {
      xEnd = clampedBottomRightPoint.getX();
    }

    return new Vector(xEnd - xStart, yEnd - yStart);
  }

  private createWindowClampedVector(x: number, y: number): Vector {
    return new Vector(
      _.clamp(x, this.screenMargin, window.innerWidth - this.screenMargin),
      _.clamp(y, this.screenMargin, window.innerHeight - this.screenMargin)
    );
  }
}
