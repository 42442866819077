import { autoinject, bindable } from 'aurelia-framework';

import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../classes/UrlManager';
import { SvgLoader } from '../../classes/Svg/SvgLoader';

/**
 * @attribute data-2x-zoom - only works with custom icons, zooms into the center of the icon with 200%
 * @attribute data-1x5-zoom - only works with custom icons, zooms into the center of the icon with 150%
 */
@autoinject()
export class CustomIcon {
  private static iconCache: Record<string, SVGSVGElement> = {};

  @bindable public iconType: IconType = IconType.CUSTOM;

  /**
   * For iconType custom this is the name of the icon located in the src/img/customIcons folder
   * For a fontawesome icon this is the icon with the fa prefix (e.g. fa-calendar-alt)
   */
  @bindable public iconName: string | null = null;

  private appliedClasses: Array<string> = [];
  private isAttached: boolean = false;
  private svgIconElement: SVGSVGElement | null = null;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;
    this.updateIcon();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected iconTypeChanged(): void {
    if (this.isAttached) {
      this.updateIcon();
    }
  }

  protected iconNameChanged(): void {
    if (this.isAttached) {
      this.updateIcon();
    }
  }

  private updateIcon(): void {
    const classesToApply = [];
    let iconNameToLoad: string | null = null;

    if (this.iconType && this.iconType !== IconType.CUSTOM) {
      classesToApply.push(this.iconType);
      if (this.iconName) {
        classesToApply.push(this.iconName);
      }
    } else if (this.iconType === 'custom') {
      iconNameToLoad = this.iconName;
    }

    this.applyClasses(classesToApply);
    this.loadCustomIcon(iconNameToLoad);
  }

  private applyClasses(classes: Array<string>): void {
    this.appliedClasses.forEach((c) => this.domElement.classList.remove(c));
    classes.forEach((c) => this.domElement.classList.add(c));

    this.appliedClasses = classes.slice();
  }

  private loadCustomIcon(iconName: string | null): void {
    if (!iconName) {
      this.removeSvgIconElement();
      return;
    }

    void CustomIcon.getCustomSvgElement(iconName).then((elem) => {
      if (elem && iconName === this.iconName) {
        // check if the icon still fits, icon could e.g. change while we are loading it
        this.removeSvgIconElement();
        this.svgIconElement = elem;
        this.domElement.appendChild(elem);
      }
    });
  }

  private removeSvgIconElement(): void {
    if (this.svgIconElement) {
      if (this.svgIconElement.parentNode) {
        this.svgIconElement.parentNode.removeChild(this.svgIconElement);
      }

      this.svgIconElement = null;
    }
  }

  private static async getCustomSvgElement(
    iconName: string
  ): Promise<SVGSVGElement | null> {
    try {
      const existingIconElement = this.iconCache[iconName];
      if (existingIconElement) {
        return existingIconElement.cloneNode(true) as SVGSVGElement;
      }

      // TODO: prevent icon being downloaded multiple times if multiple occurences of the same icon exist when it isn't cached yet

      const loader = new SvgLoader();
      const element = await loader.load(
        PathUtils.joinPaths(
          UrlManager.baseClientFilePath,
          'img',
          'custom-icons',
          iconName + '.svg'
        )
      );
      this.iconCache[iconName] = element.cloneNode(true) as SVGSVGElement;

      return element;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

export enum IconType {
  CUSTOM = 'custom',
  FAR = 'far',
  FAS = 'fas',
  FAL = 'fal'
}
