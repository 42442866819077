import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class UserGroupsWithPermissionComputer extends ValueComputer<
  UserGroupsWithPermissionComputerComputeData,
  UserGroupsWithPermissionComputerComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(invokeCompute)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      () => {
        invokeCompute();
      }
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): UserGroupsWithPermissionComputerComputeResult {
    const currentUser = this.currentUserService.getRequiredCurrentUser();
    const userGroups = this.entityManager.userGroupRepository.getAll();

    const editableUserGroupIds = new Set<string>();
    const groupAdminUserGroupIds = new Set<string>();

    for (const userGroup of userGroups) {
      const spec = userGroup.userSpecs.find((s) => s._id === currentUser.id);
      if (!spec) {
        continue;
      }

      // group_admins are always allowed to edit stuff
      if (spec.can_edit || spec.group_admin) {
        editableUserGroupIds.add(userGroup.id);
      }

      if (spec.group_admin) {
        groupAdminUserGroupIds.add(userGroup.id);
      }
    }

    return {
      editableUserGroupIds,
      groupAdminUserGroupIds,
      userGroupIdsWithSmsCredentials: this.getUserGroupIdsWithSmsCredentials()
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getUserGroupIdsWithSmsCredentials(): Set<string> {
    const userGroups = this.entityManager.userGroupRepository.getAll();
    return new Set(
      userGroups
        .filter(
          (userGroup) =>
            userGroup.smsCredentials?.username &&
            userGroup.smsCredentials?.password
        )
        .map((userGroup) => userGroup.id)
    );
  }
}

export type UserGroupsWithPermissionComputerComputeData = {};
export type UserGroupsWithPermissionComputerComputeResult = {
  editableUserGroupIds: Set<string>;
  groupAdminUserGroupIds: Set<string>;
  userGroupIdsWithSmsCredentials: Set<string>;
};
