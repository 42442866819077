import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';

import { GeneralFileTypes } from 'common/Enums/GeneralFileTypes';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonHelper } from 'common/EntityHelper/PersonHelper';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import {
  FinishAppointmentOptions,
  ProcessTaskAppointmentFinishService
} from '../../services/ProcessTaskAppointmentFinishService/ProcessTaskAppointmentFinishService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Person } from '../edit_persons/edit_persons';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ShowProcessAppointmentConfiguration } from './ShowProcessAppointmentConfiguration';
import { ProcessTaskToProjectFormCreationService } from '../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectFormCreationService';
import { PersonContactLink } from '../../personComponents/person-contact-link/person-contact-link';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProcessAppointmentTabsKeys } from 'common/Types/Entities/ProcessConfiguration/ProcessConfigurationDto';
import { TemporaryAppointmentService } from '../../services/TemporaryAppointmentService';

enum FinishAppointmentStatus {
  UNFINISHED = 'unfinished',
  FINISHED = 'finished',
  FINISHED_UNDO = 'finishedUndo'
}

@autoinject()
export class ShowProcessAppointment {
  private static FINISH_APPOINTMENT_UNDO_TIME = 2 * 60 * 60 * 1000; // give the user 2 hours time to revert the finishedAt in case he misclicks or something

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  private appointment: ProcessTaskAppointment | null = null;
  private dateInfo: ProcessTaskAppointmentDateInfo | null = null;
  private processTask: ProcessTask | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private thingGroup: ThingGroup | null = null;
  private thing: Thing | null = null;
  private processTaskContactPerson: Person | null = null;
  private processTaskGroupIsEditable: boolean = false;
  private processConfiguration: ProcessConfiguration | null = null;
  private processConfigurationStep: ProcessConfigurationStep | null = null;
  private appointmentId: string | null = null;
  private logOpenedBy: string | null = null;
  private openedFormId: string | null = null;
  private positions: Array<ProcessTaskPosition> = [];
  private selectedPositions: Array<ProcessTaskPosition> = [];

  private appointmentGeneralInfoEditing: boolean = false;
  private appointmentFinishWithNfcTag: boolean = false;

  private finishAppointmentState: FinishAppointmentStatus =
    FinishAppointmentStatus.UNFINISHED;
  private signatureImageUrl: string | null = null;
  private signatureText: string | null = null;
  private canUseOperations: boolean = false;
  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();
  private configuration: ShowProcessAppointmentConfiguration;

  private FinishAppointmentStatus = FinishAppointmentStatus;
  private ProcessTaskGroupUtils = ProcessTaskGroupUtils;
  private PersonHelper = PersonHelper;

  constructor(
    private readonly i18n: I18N,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly generalFileUploadService: GeneralFileUploadService,
    private readonly computedValueService: ComputedValueService,
    private readonly processTaskToProjectFormCreationService: ProcessTaskToProjectFormCreationService,
    private readonly activeEntitiesService: ActiveEntitiesService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    currentUserService: CurrentUserService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly processTaskAppointmentFinishService: ProcessTaskAppointmentFinishService,
    private readonly temporaryAppointmentService: TemporaryAppointmentService
  ) {
    this.i18n = i18n;
    this.router = router;

    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'processTaskGroup',
        userGroupPropertyOfEntity: 'ownerUserGroupId',
        editableProperty: 'processTaskGroupIsEditable'
      },
      permissionProperties: {
        canUseOperations: 'canUseOperations'
      }
    });

    this.configuration = new ShowProcessAppointmentConfiguration(
      this.activeUserCompanySettingService,
      this.subscriptionManager,
      this.computedValueService,
      currentUserService
    );
  }

  protected activate(params: {
    appointment_id: string;
    log_opened_by?: string;
    open_form?: string;
  }): void {
    this.appointmentId = params.appointment_id;
    this.logOpenedBy = this.logOpenedBy ?? null;
    this.openedFormId = params.open_form ?? null;
    this.redirectToNewIdIfIdIsLocal();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointmentData.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      this.updateProcessTaskGroup.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateProcessTask.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateThing.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateContactPerson.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateContactPerson.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.GeneralFile,
      this.updateSignature.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationStep.bind(this)
    );

    this.subscriptionManager.subscribeToInterval(
      this.updateFinishAppointmentState.bind(this),
      10000
    ); // updating the state every 10 seconds is accurate enough

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateDateInfo();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.appointment
            ? { processTaskGroupId: this.appointment.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (update) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'appointment.ownerProcessTaskGroupId',
            update
          );
        },
        callback: (configuration) => {
          this.processConfiguration = configuration;
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentGeneralInfoEditing',
        (appointmentGeneralInfoEditing) => {
          this.appointmentGeneralInfoEditing = appointmentGeneralInfoEditing;
        }
      ),
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentFinishWithNfcTag',
        (appointmentFinishWithNfcTag) => {
          this.appointmentFinishWithNfcTag = appointmentFinishWithNfcTag;
        }
      ),
      ...this.configuration.subscribe()
    );

    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.addDisposable(
      PersonContactLink.addEventListener('phoneContactClicked', (payload) => {
        if (this.processTask) {
          void this.processTaskLoggingService.logPhoneLinkClick(
            this.processTask,
            payload.person,
            payload.personContact
          );
        }
      })
    );

    this.updateAppointmentData();

    this.temporaryAppointmentService.startPersistingOnChanges(this.logOpenedBy);
  }

  private redirectToNewIdIfIdIsLocal(): void {
    let newAppointmentId: string | null = null;
    let newOpenedFormId: string | null = null;
    if (this.appointmentId) {
      const newEntity =
        this.entityManager.processTaskAppointmentRepository.getByOriginalId(
          this.appointmentId
        );
      if (newEntity) {
        newAppointmentId = newEntity.id;
      }
    }
    if (this.openedFormId) {
      const newEntity =
        this.entityManager.processTaskToProjectRepository.getByOriginalId(
          this.openedFormId
        );
      if (newEntity) {
        newOpenedFormId = newEntity.id;
      }
    }
    if (newAppointmentId || newOpenedFormId) {
      this.router.navigateToRoute('show_process_appointment', {
        appointment_id: newAppointmentId ?? this.appointmentId,
        log_opened_by: this.logOpenedBy ?? undefined,
        open_form: newOpenedFormId ?? this.openedFormId ?? undefined
      });
    }
  }

  protected deactivate(): void {
    this.temporaryAppointmentService.stopPersistingOnChanges();
    this.temporaryAppointmentService.clearTemporaryAppointments();
    this.activeEntitiesService.setActiveProcessTaskAppointment(null);
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  private updateAppointmentData(): void {
    this.updateAppointment();
    this.setAppointmentOpened();
    this.updateDateInfo();
    this.updateProcessConfigurationStep();
    this.updateProcessTaskGroup();
    this.updatePositions();
    this.updateProcessTask();
    this.updateSignature();
    this.updateFinishAppointmentState();

    if (this.processTask && this.appointment) {
      this.processTaskToProjectFormCreationService.createAutoForms(
        this.processTask,
        this.appointment
      );
    }
  }

  private updateAppointment(): void {
    this.appointment = this.appointmentId
      ? this.entityManager.processTaskAppointmentRepository.getById(
          this.appointmentId
        )
      : null;
    this.redirectToNewIdIfIdIsLocal();
    this.activeEntitiesService.setActiveProcessTaskAppointment(
      this.appointment || null
    );
    this.configuration.setAppointment(this.appointment);
  }

  private setAppointmentOpened(): void {
    if (!this.appointment) {
      return;
    }

    if (!this.appointment.openedAt) {
      this.appointment.openedAt = new Date().toISOString();
      this.entityManager.processTaskAppointmentRepository.update(
        this.appointment
      );
    }
  }

  private updateDateInfo(): void {
    if (this.appointment) {
      this.dateInfo =
        this.processTaskAppointmentDateInfoMap.get(this.appointment.id) ?? null;
    } else {
      this.dateInfo = null;
    }
  }

  private updateProcessTaskGroup(): void {
    if (this.appointment) {
      this.processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          this.appointment.ownerProcessTaskGroupId
        );
    } else {
      this.processTaskGroup = null;
    }

    this.updateThingGroup();
  }

  private updateThingGroup(): void {
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    } else {
      this.thingGroup = null;
    }
  }

  private updatePositions(): void {
    this.positions = [];
    this.selectedPositions = [];
    if (!this.appointment) {
      return;
    }
    const processTaskAppointmentToProcessTaskPositions =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
        this.appointment.id
      );
    for (const processTaskAppointmentToProcessTaskPosition of processTaskAppointmentToProcessTaskPositions) {
      const processTaskPosition =
        this.entityManager.processTaskPositionRepository.getById(
          processTaskAppointmentToProcessTaskPosition.processTaskPositionId
        );
      if (!processTaskPosition) continue;
      this.positions.push(processTaskPosition);
      if (processTaskAppointmentToProcessTaskPosition.done) {
        this.selectedPositions.push(processTaskPosition);
      }
    }
    this.updateSignatureText();
  }

  private updateSignatureText(): void {
    const positionsList = this.selectedPositions.map((p) => p.name);
    this.signatureText =
      this.i18n.tr(
        'generalPages.showProcessAppointment.positionsTextInSignature'
      ) +
      '\n\n' +
      positionsList.join(', ');

    if (
      this.processConfigurationStep &&
      this.processConfigurationStep.appointmentSignatureText
    ) {
      this.signatureText +=
        '\n\n' + this.processConfigurationStep.appointmentSignatureText;
    }
  }

  private updateProcessTask(): void {
    if (this.appointment) {
      this.processTask = this.entityManager.processTaskRepository.getById(
        this.appointment.ownerProcessTaskId
      );
    } else {
      this.processTask = null;
    }

    this.updateThing();
  }

  private updateThing(): void {
    if (this.processTask) {
      this.thing = this.entityManager.thingRepository.getById(
        this.processTask.thingId
      );
    } else {
      this.thing = null;
    }

    this.updateContactPerson();
  }

  private updateContactPerson(): void {
    this.processTaskContactPerson = null;
    if (this.thing) {
      const thingToPerson =
        this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          this.thing.id
        );
      this.processTaskContactPerson = thingToPerson
        ? this.entityManager.personRepository.getById(thingToPerson.personId)
        : null;
    }
  }

  private updateSignature(): void {
    if (!this.appointmentId) {
      this.signatureImageUrl = null;
      return;
    }
    const firstSignatureFile =
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        this.appointmentId
      )[0];
    if (!firstSignatureFile) {
      this.signatureImageUrl = null;
      return;
    }
    const path =
      GeneralFileUtils.getFullOnlineFilePathForGeneralFile(firstSignatureFile);
    if (!path) {
      this.signatureImageUrl = null;
      return;
    }
    this.signatureImageUrl = path;
  }

  private updateFinishAppointmentState(): void {
    const finishedAt = this.appointment ? this.appointment.finishedAt : null;

    if (!finishedAt) {
      this.finishAppointmentState = FinishAppointmentStatus.UNFINISHED;
      return;
    }

    const finishedAtDate = new Date(finishedAt);
    const diff = Date.now() - finishedAtDate.getTime();

    if (diff > ShowProcessAppointment.FINISH_APPOINTMENT_UNDO_TIME) {
      this.finishAppointmentState = FinishAppointmentStatus.FINISHED;
    } else {
      this.finishAppointmentState = FinishAppointmentStatus.FINISHED_UNDO;
    }
  }

  private updateProcessConfigurationStep(): void {
    if (this.appointment && this.appointment.processConfigurationStepId) {
      this.processConfigurationStep =
        this.entityManager.processConfigurationStepRepository.getById(
          this.appointment.processConfigurationStepId
        ) || null;
    } else {
      this.processConfigurationStep = null;
    }
    this.updateSignatureText();
  }

  private handleNavigateToProcessTaskOverviewClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ShowProcessAppointment.handleNavigateToProcessTaskOverviewClick without a processTask"
    );
    this.router.navigateToRoute('edit_process_task', {
      process_task_id: this.processTask.id
    });
  }

  private handleSignatureChanged(dataUrl: string): void {
    if (!this.appointment || !dataUrl) return;
    let signatureFile = null;
    const firstExistingSignatureFile =
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        this.appointment.id
      )[0];
    if (firstExistingSignatureFile) {
      signatureFile = firstExistingSignatureFile;
    } else {
      signatureFile = this.entityManager.generalFileRepository.create({
        ownerUserGroupId: this.appointment.ownerUserGroupId,
        ownerProcessTaskId: this.appointment.ownerProcessTaskId,
        ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId,
        processTaskAppointmentId: this.appointment.id,
        type: GeneralFileTypes.SIGNATURE,
        name: 'signature',
        extension: 'svg'
      });
    }
    this.generalFileUploadService.uploadGeneralFile(
      signatureFile,
      dataUrl,
      'svg'
    );
  }

  protected async handleFinishAppointmentClick(): Promise<void> {
    if (!this.appointment || !this.processTask || !this.processTaskGroup)
      return;

    const finishAppointmentOptions: FinishAppointmentOptions = {
      appointment: this.appointment,
      processTask: this.processTask,
      processTaskGroup: this.processTaskGroup,
      canEditGeneralProcessTaskInfo: this.appointmentGeneralInfoEditing,
      canSignTheAppointment: this.configuration.showSignature,
      canTakePictures: this.configuration.showPictures,
      canDeployDevices: this.configuration.showDevices
    };

    if (this.appointmentFinishWithNfcTag) {
      await this.processTaskAppointmentFinishService.finishAppointmentWithNfcTag(
        finishAppointmentOptions
      );
    } else {
      await this.processTaskAppointmentFinishService.finishAppointment(
        finishAppointmentOptions
      );
    }

    this.updateFinishAppointmentState();
  }

  protected async handleUndoFinishAppointmentClick(): Promise<void> {
    if (!this.appointment || !this.processTask) return;

    if (this.appointmentFinishWithNfcTag) {
      await this.processTaskAppointmentFinishService.undoFinishingAppointmentWithNfcTag(
        this.appointment,
        this.processTask
      );
    } else {
      this.processTaskAppointmentFinishService.undoFinishingAppointment(
        this.appointment,
        this.processTask
      );
    }

    this.updateFinishAppointmentState();
  }

  private handlePositionCheckboxChanged(
    position: ProcessTaskPosition,
    checked: boolean
  ): void {
    const index = this.selectedPositions.findIndex((p) => p.id === position.id);
    if (index >= 0 && !checked) {
      this.selectedPositions.splice(index, 1);
    } else if (index < 0 && checked) {
      this.selectedPositions.push(position);
    }
    this.updateAppointmentToPositionRelation(position, checked);
    this.updateSignatureText();
  }

  private updateAppointmentToPositionRelation(
    position: ProcessTaskPosition,
    done: boolean
  ): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't ShowProcessAppointment.updateAppointmentToPositionRelation without an appointment"
    );

    const relation =
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentIdAndProcessTaskPositionId(
        this.appointment.id,
        position.id
      );
    assertNotNullOrUndefined(
      relation,
      'ShowProcessAppointment.updateAppointmentToPositionRelation: processTaskAppointmentToProcessTaskPosition relation does not exist'
    );

    relation.done = done;
    this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.update(
      relation
    );
  }

  private isInSelectedPositions(
    selectedPositions: Array<ProcessTaskPosition>,
    position: ProcessTaskPosition
  ): boolean {
    return !!selectedPositions.find((p) => p.id === position.id);
  }

  protected shouldExpand(tab: ProcessAppointmentTabsKeys): boolean {
    return (
      this.processConfiguration?.processAppointmentTabsToExpand[tab] === true
    );
  }
}
