export class UrlUtils {
  /**
   * add a discriminator to it's href so the navigation is always triggered
   * useful for e.g. auto scrolling to an element via url params
   * without this you can only auto scroll to the element once, since the next time the url doesn't change and a navigation won't trigger
   *
   * @param {HTMLAnchorElement} aElement
   */
  static ensureAnchorNavigationTrigger(aElement) {
    //url will already trigger a navigation
    if (aElement.href !== window.location.href) {
      return;
    }

    const url = new URL(aElement.href);

    //temporary prefix the subUrl so the url is valid
    //needed because aurelias url is completely located in the hash, so we need to extract it
    const subUrlTempPrefix = 'https://localhost';
    const subUrl = new URL(subUrlTempPrefix + url.hash.substr(1));
    const discriminator = subUrl.searchParams.get('discriminator') || '';
    subUrl.searchParams.set('discriminator', discriminator + 'A');

    url.hash = '#' + subUrl.href.substr(subUrlTempPrefix.length);
    aElement.href = url.href;
  }

  /**
   * @param {{[s: string]: string}} params
   * @returns {string}
   */
  static createQueryString(params) {
    let queryString = '';

    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }

      if (queryString.length === 0) {
        queryString += '?';
      } else {
        queryString += '&';
      }

      queryString +=
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
    }

    return queryString;
  }
}
