import { autoinject } from 'aurelia-framework';
import { SocketService } from './SocketService';

import {
  GisParsingHelper,
  GisPropertyName
} from 'common/GisParsingHelper/GisParsingHelper';
import {
  GstInfoOwner,
  GstInfoMetaData,
  GetGstInfoRequest,
  GetGstInfoSuccessResponse
} from 'common/EndpointTypes/GeoDataEndpointsHandler';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { Person } from '../classes/EntityManager/entities/Person/types';
import { EntryToPerson } from '../classes/EntityManager/entities/EntryToPerson/types';
import { EntryProperty } from '../classes/EntityManager/entities/Property/types';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

@autoinject()
export class GisService {
  constructor(
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager
  ) {}

  public async syncGstInfoWithEntry(
    entry: Entry,
    latitude: number,
    longitude: number,
    municipalityCode: string
  ): Promise<void> {
    const gstInfo = await this.getGstInfo({
      lat: latitude,
      long: longitude,
      gemeindeNr: municipalityCode
    });
    this.syncGisProperties(entry, gstInfo.meta);
    this.syncOwners(entry, gstInfo, latitude, longitude);
  }

  public async syncGstInfoWithEntryForAdditionalOwners(
    entry: Entry,
    latitude: number,
    longitude: number,
    municipalityCode: string
  ): Promise<void> {
    const gstInfo = await this.getGstInfo({
      lat: latitude,
      long: longitude,
      gemeindeNr: municipalityCode
    });
    this.syncAdditionalOwners(entry, gstInfo, latitude, longitude);
  }

  private getDummyPersonIdentifier(gstInfo: GetGstInfoSuccessResponse): string {
    return `DummyEstatePerson_Gst${gstInfo.meta.gstnr}_KG${gstInfo.meta.kgnr}`;
  }

  private getPersonsFromGstInfo(
    gstInfo: GetGstInfoSuccessResponse,
    ownerUserGroupId: string
  ): Array<Person> {
    const currentContacts =
      this.entityManager.personRepository.getByUserGroupId(ownerUserGroupId);

    const persons = gstInfo.owners.length
      ? this.ensurePersonsFromOwnerData(
          currentContacts,
          gstInfo.owners,
          ownerUserGroupId
        )
      : [];
    if (!persons.length) {
      // We have no person data available => create estate as dummy person
      persons.push(
        this.ensureDummyEstatePersonFromGstData(
          currentContacts,
          gstInfo,
          ownerUserGroupId
        )
      );
    }

    return persons;
  }

  private syncAdditionalOwners(
    entry: Entry,
    gstInfo: GetGstInfoSuccessResponse,
    latitude: number,
    longitude: number
  ): void {
    const persons = this.getPersonsFromGstInfo(gstInfo, entry.ownerUserGroupId);
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);

    for (const person of persons) {
      const existingRelation = entryToPersonEntries.find(
        (entryToPerson) => entryToPerson.personId === person.id
      );
      if (existingRelation)
        this.ensureCorrectCoordinatesForEntryToPersonRelation(
          existingRelation,
          latitude,
          longitude
        );
      if (!existingRelation) {
        this.entityManager.entryToPersonRepository.create({
          entryId: entry.id,
          personId: person.id,
          mainContact: false,
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId,
          coords: {
            latitude: latitude,
            longitude: longitude
          }
        });
      }
    }
  }

  private ensureCorrectCoordinatesForEntryToPersonRelation(
    entryToPersonRelation: EntryToPerson,
    latitude: number,
    longitude: number
  ): void {
    if (
      entryToPersonRelation.coords?.latitude !== latitude ||
      entryToPersonRelation.coords?.longitude !== longitude
    ) {
      entryToPersonRelation.coords = { latitude, longitude };
      this.entityManager.entryToPersonRepository.update(entryToPersonRelation);
    }
  }

  private syncOwners(
    entry: Entry,
    gstInfo: GetGstInfoSuccessResponse,
    latitude: number,
    longitude: number
  ): void {
    const persons = this.getPersonsFromGstInfo(gstInfo, entry.ownerUserGroupId);
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);
    if (
      !this.ownersAreListedWithMatchingCoordinates(
        persons,
        entryToPersonEntries,
        latitude,
        longitude
      )
    ) {
      for (const entryToPersonEntry of entryToPersonEntries) {
        this.entityManager.entryToPersonRepository.delete(entryToPersonEntry);
      }
      let mainContactSet = false;
      for (const person of persons) {
        const isMainContact = !mainContactSet && person.streetName !== '';
        if (isMainContact) mainContactSet = true;
        this.entityManager.entryToPersonRepository.create({
          entryId: entry.id,
          personId: person.id,
          mainContact: isMainContact,
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId,
          coords: {
            latitude: latitude,
            longitude: longitude
          }
        });
      }
    }
  }

  /**
   * This will check if all the given owners are already part of the current owner list.
   * Returns false if at least one is missing.
   */
  private ownersAreListedWithMatchingCoordinates(
    persons: Array<Person>,
    entryToPersonEntries: Array<EntryToPerson>,
    latitude: number,
    longitude: number
  ): boolean {
    for (const person of persons) {
      const existingRelation = entryToPersonEntries.find(
        (entry) => entry.personId === person.id
      );
      if (existingRelation)
        this.ensureCorrectCoordinatesForEntryToPersonRelation(
          existingRelation,
          latitude,
          longitude
        );
      if (!existingRelation) return false; // We can return without updating coords of further relations because all relations will get deleted anyway.
    }
    return true;
  }

  // TODO: REC-4516 check for permissions in usages
  private ensureDummyEstatePersonFromGstData(
    currentContacts: Array<Person>,
    gstInfo: GetGstInfoSuccessResponse,
    userGroupId: string
  ): Person {
    let dummyPerson = currentContacts.find(
      (contact) => contact.note === this.getDummyPersonIdentifier(gstInfo)
    );

    if (!dummyPerson) {
      dummyPerson = this.entityManager.personRepository.create({
        firstName: 'Grundstück',
        ownerUserGroupId: userGroupId,
        streetName: `Gst-Nr.: ${gstInfo.meta.gstnr}`,
        zip: `KG: ${gstInfo.meta.kgnr}`,
        note: this.getDummyPersonIdentifier(gstInfo)
      });
    }
    return dummyPerson;
  }

  private ensurePersonsFromOwnerData(
    currentContacts: Array<Person>,
    owners: Array<GstInfoOwner>,
    userGroupId: string
  ): Array<Person> {
    const persons = [];

    for (const owner of owners) {
      const parsedAddress = owner.address;
      let existing = currentContacts.find((contact) =>
        GisParsingHelper.arePersonsEqual(contact, owner, parsedAddress)
      );
      if (!existing) {
        const newOwner = {
          firstName: owner.name,
          lastName: owner.lastName,
          company: owner.company !== '',
          companyName: owner.company,
          ownerUserGroupId: userGroupId,
          streetName: parsedAddress.street,
          zip: parsedAddress.zipCode || null,
          municipality: parsedAddress.municipalityName || null
        };
        existing = this.entityManager.personRepository.create(newOwner);
      }
      persons.push(existing);
    }
    return persons;
  }

  private syncGisProperties(entry: Entry, gstMeta: GstInfoMetaData): void {
    const properties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    this.ensureGisProperty(
      entry,
      properties,
      GisPropertyName.KGNR,
      gstMeta.kgnr
    );
    this.ensureGisProperty(
      entry,
      properties,
      GisPropertyName.GSTNR,
      gstMeta.gstnr
    );
    this.ensureGisProperty(entry, properties, GisPropertyName.EZ, gstMeta.ez);
  }

  private ensureGisProperty(
    entry: Entry,
    properties: Array<EntryProperty>,
    name: string,
    value: string
  ): void {
    let propertyToSet = properties.find((property) => property.name === name);
    if (!propertyToSet) {
      propertyToSet = this.entityManager.propertyRepository.create({
        entry: entry.id,
        name: name,
        type: PropertyType.TEXT,
        value: value,
        alwaysVisible: true,
        ownerUserGroupId: entry.ownerUserGroupId,
        ownerProjectId: entry.ownerProjectId
      }) as EntryProperty;
    } else {
      propertyToSet.value = value;
      this.entityManager.propertyRepository.update(propertyToSet);
    }
  }

  private async getGstInfo(
    locationData: GetGstInfoRequest
  ): Promise<GetGstInfoSuccessResponse> {
    return new Promise((resolve, reject) => {
      this.socketService.getGstInfo(locationData, (data) => {
        if (data.success) {
          resolve(data);
        } else {
          reject('Could not query owners');
        }
      });
    });
  }
}
