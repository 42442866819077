import { autoinject, bindable } from 'aurelia-framework';

import { MouseDraggingDetector } from '../classes/DomUtilities/MouseDraggingDetector';
import { TouchDraggingDetector } from '../classes/DomUtilities/TouchDraggingDetector';
import {
  DraggingService,
  SetActiveDraggableInfo
} from '../services/DraggingService';
import { PointerEventHelper } from '../classes/PointerEventHelper';
import { Vector } from '../../../common/src/Geometry/Vector';
import { Utils } from '../classes/Utils/Utils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EdgeScroller } from '../classes/DomUtilities/EdgeScroller';
import { DraggableInstantDragCustomAttribute } from './DraggableInstantDragCustomAttribute';

/**
 * this custom attribute can only be used with aurelia components
 */
@autoinject()
export class DraggableCustomAttribute {
  @bindable public enabled = true;

  /**
   * if the dragData is null, then it will fall back to the viewModel of the attached domElement
   */
  @bindable public dragData: unknown = null;

  /**
   * if this is set the dragging can only be started with elements which have an draggable-instant-drag attribute
   *
   * this is useful if you don't want the whole element be draggable but only with e.g. an icon
   *
   * You may be thinking: why can't I apply the DraggableCustomAttribute directly to the icon? You can do that, but in the drag preview only the icon would be visible instead of maybe the whole list item or whatever you want to drag.
   */
  @bindable public onlyInstantDragging: boolean = false;

  private static mouseDraggingDetector: MouseDraggingDetector | null = null;
  private static touchDraggingDetector: TouchDraggingDetector | null = null;

  private static contextMenuHandlerInitialized = false;

  private static attachedInstances: Array<DraggableCustomAttribute> = [];
  private static activeInstance: DraggableCustomAttribute | null = null;

  private draggingService: DraggingService;

  private domElement: HTMLElement;

  private dragElement: HTMLElement | null = null;
  private finishedDragging: (() => void) | null = null;

  private originalOpacity: string | null = null;

  private positionOffset: Vector | null = null;

  private edgeScroller = new EdgeScroller();

  constructor(element: Element, draggingService: DraggingService) {
    this.domElement = element as HTMLElement;
    this.draggingService = draggingService;
  }

  protected attached(): void {
    DraggableCustomAttribute.ensureMouseDraggingDetector();
    DraggableCustomAttribute.ensureTouchDraggingDetector();

    DraggableCustomAttribute.ensureContextMenuHandler();

    DraggableCustomAttribute.attachedInstances.push(this);
  }

  protected detached(): void {
    const index = DraggableCustomAttribute.attachedInstances.indexOf(this);
    DraggableCustomAttribute.attachedInstances.splice(index, 1);

    this.removeDragElement();
  }

  private static ensureMouseDraggingDetector(): void {
    if (!this.mouseDraggingDetector) {
      this.mouseDraggingDetector = new MouseDraggingDetector(document.body, {
        clickTimeoutEnabled: true,
        elementCanStartDragging: this.elementCanStartDragging.bind(this),
        elementSkipsClickTimeout: (element) =>
          DraggableInstantDragCustomAttribute.isInsideInstantDragElement(
            element
          )
      });
      this.mouseDraggingDetector.onDragStart(this.handleDragStart.bind(this));
      this.mouseDraggingDetector.onDrag(this.handleDrag.bind(this));
      this.mouseDraggingDetector.onDragEnd(this.handleDragEnd.bind(this));
    }
  }

  private static ensureTouchDraggingDetector(): void {
    if (!this.touchDraggingDetector) {
      this.touchDraggingDetector = new TouchDraggingDetector(document.body, {
        pressTimeoutEnabled: true,
        elementCanStartDragging: this.elementCanStartDragging.bind(this),
        elementSkipsPressTimeout: (element) =>
          DraggableInstantDragCustomAttribute.isInsideInstantDragElement(
            element
          )
      });
      this.touchDraggingDetector.onDragStart(this.handleDragStart.bind(this));
      this.touchDraggingDetector.onDrag(this.handleDrag.bind(this));
      this.touchDraggingDetector.onDragEnd(this.handleDragEnd.bind(this));
    }
  }

  private static ensureContextMenuHandler(): void {
    if (!this.contextMenuHandlerInitialized) {
      document.body.addEventListener(
        'contextmenu',
        this.handleContextMenu.bind(this)
      );
      this.contextMenuHandlerInitialized = true;
    }
  }

  private static elementCanStartDragging(element: Element): boolean {
    const instance = this.getEnabledInstanceForElement(element);

    if (instance?.onlyInstantDragging) {
      return DraggableInstantDragCustomAttribute.isInsideInstantDragElement(
        element
      );
    }

    return !!instance;
  }

  private static handleContextMenu(event: MouseEvent): void {
    if (this.activeInstance) {
      event.preventDefault();
    }
  }

  private static handleDragStart(event: MouseEvent | TouchEvent): void {
    this.activeInstance = this.getEnabledInstanceForElement(
      event.target as Element
    );
    if (this.activeInstance) {
      this.activeInstance.handleDragStart(event);
    }
  }

  private static handleDrag(event: MouseEvent | TouchEvent): void {
    if (this.activeInstance) {
      event.preventDefault();
      this.activeInstance.handleDrag(event);
    }
  }

  private static handleDragEnd(): void {
    if (this.activeInstance) {
      this.activeInstance.handleDragEnd();
      this.activeInstance = null;
    }
  }

  private static getEnabledInstanceForElement(
    element: Element
  ): DraggableCustomAttribute | null {
    let attributeInstance: DraggableCustomAttribute | null = null;

    Utils.walkThroughParentElements(element, (e) => {
      const attachedInstance = this.attachedInstances.find(
        (instance) => instance.domElement === e && instance.enabled
      );
      if (attachedInstance) {
        attributeInstance = attachedInstance;
        return false;
      }
      return true;
    });

    return attributeInstance;
  }

  private getOrCreateDragElement(): HTMLElement {
    if (!this.dragElement) {
      this.dragElement = this.domElement.cloneNode(true) as HTMLElement;

      this.dragElement.style.opacity = '0.5';
      this.dragElement.style.position = 'fixed';
      this.dragElement.style.pointerEvents = 'none';

      this.dragElement.style.marginTop = '0px';
      this.dragElement.style.marginBottom = '0px';
      this.dragElement.style.marginLeft = '0px';
      this.dragElement.style.marginRight = '0px';

      this.dragElement.style.width = this.domElement.clientWidth + 'px';
      this.dragElement.style.height = this.domElement.clientHeight + 'px';

      this.dragElement.style.zIndex = '100';

      document.body.appendChild(this.dragElement);
    }

    return this.dragElement;
  }

  private removeDragElement(): void {
    this.dragElement?.parentNode?.removeChild(this.dragElement);
    this.dragElement = null;
  }

  private handleDragStart(event: MouseEvent | TouchEvent): void {
    const result = this.draggingService.startDragging(this.getDraggableInfo());
    this.finishedDragging = result.finishedDragging;

    const targetPosition = this.getElementPosition(this.domElement);
    const mousePosition = PointerEventHelper.getClientPositionFromEvent(event);
    this.positionOffset = mousePosition.clone().substractVector(targetPosition);

    this.toggleDomElement(false);

    this.edgeScroller.setEnabled(true);
  }

  private handleDrag(event: MouseEvent | TouchEvent): void {
    assertNotNullOrUndefined(
      this.positionOffset,
      'no offset available, did drag really start?'
    );

    const element = this.getOrCreateDragElement();
    const clientPosition = PointerEventHelper.getClientPositionFromEvent(event);
    const newPosition = clientPosition
      .clone()
      .substractVector(this.positionOffset);

    element.style.left = newPosition.getX() + 'px';
    element.style.top = newPosition.getY() + 'px';
  }

  private handleDragEnd(): void {
    this.removeDragElement();

    try {
      this.finishedDragging?.();
    } catch (e) {
      throw e;
    } finally {
      this.toggleDomElement(true);
      this.edgeScroller.setEnabled(false);
    }
  }

  private getDraggableInfo(): SetActiveDraggableInfo {
    const boundingRect = this.domElement.getBoundingClientRect();
    const computedStyle = window.getComputedStyle(this.domElement);

    return {
      dragData:
        this.dragData != null
          ? this.dragData
          : Utils.getViewModelOfElement(this.domElement),
      elementSize: new Vector(boundingRect.width, boundingRect.height),
      elementMargin: {
        top: computedStyle.marginTop,
        bottom: computedStyle.marginBottom
      }
    };
  }

  private getElementPosition(element: HTMLElement): Vector {
    const rect = element.getBoundingClientRect();
    return Vector.createHtmlVector(rect.left, rect.top);
  }

  private toggleDomElement(display: boolean): void {
    if (display) {
      this.domElement.style.opacity = this.originalOpacity || '';
      this.domElement.style.pointerEvents = '';
    } else {
      this.originalOpacity = this.domElement.style.opacity;
      this.domElement.style.opacity = '0.5';
      this.domElement.style.pointerEvents = 'none';
    }
  }
}
