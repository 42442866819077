import { autoinject, bindable } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { CreateProjectClickedEvent } from '../../aureliaComponents/create-project-button/create-project-button';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProjectCreationService } from '../../classes/EntityManager/entities/Project/ProjectCreationService';
import { AssignReportTypeToProjectService } from '../../classes/EntityManager/entities/Project/AssignReportTypeToProjectService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityManagerProcessTaskToProjectCrudStrategy } from '../../dialogs/manage-process-task-to-project-relations-dialog/strategies/EntityManagerProcessTaskToProjectCrudStrategy';
import { EditProjectDialog } from '../../dialogs/edit-project-dialog/edit-project-dialog';
import { ProjectActionService } from '../../classes/EntityManager/entities/Project/ProjectActionService';

@autoinject()
export class CreateProjectsAndManageProcessTaskRelationsWidget {
  @bindable public processTask: ProcessTask | null = null;

  /*
   * read only
   */
  @bindable public totalCount: number | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected readonly crudStrategy: EntityManagerProcessTaskToProjectCrudStrategy;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService,
    private readonly assignReportTypeToProjectService: AssignReportTypeToProjectService,
    permissionsService: PermissionsService,
    private readonly projectActionService: ProjectActionService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });

    this.crudStrategy = new EntityManagerProcessTaskToProjectCrudStrategy(
      ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
      this.entityManager
    );
  }

  protected handleCreateProjectClicked(event: CreateProjectClickedEvent): void {
    if (!this.thing) {
      return;
    }

    let project: Project;
    if (event.detail.projectType === ProjectType.BASIC) {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        reportType: event.detail.reportType ?? null
      });
    } else {
      project = this.projectCreationService.createProject({
        thing: this.thing,
        projectType: event.detail.projectType,
        structureTemplateId: event.detail.structureTemplate.id,
        reportType: event.detail.reportType ?? null
      });
    }

    this.createProcessTaskToProjectRelation(project);

    void EditProjectDialog.open({
      project: project,
      onDialogClosed: (p) => {
        setTimeout(() => this.projectActionService.navigateToProject(p), 100);
      }
    });
  }

  @computed(expression('processTask.thingId'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.processTask) return null;

    return this.entityManager.thingRepository.getRequiredById(
      this.processTask.thingId
    );
  }

  private createProcessTaskToProjectRelation(project: Project): void {
    assertNotNullOrUndefined(
      this.processTask,
      'cannot create processTaskToProject without processTask'
    );

    void this.crudStrategy.createProcessTaskToProject({
      processTaskId: this.processTask.id,
      projectId: project.id
    });
  }
}
