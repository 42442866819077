import { autoinject, bindable } from 'aurelia-framework';

import { Vector } from 'common/Geometry/Vector';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { MouseDraggingDetector } from '../../classes/DomUtilities/MouseDraggingDetector';
import { TouchDraggingDetector } from '../../classes/DomUtilities/TouchDraggingDetector';
import { PinchZoomDetector } from '../../classes/DomUtilities/PinchZoomDetector';
import { ZoomBoxCalculationHelper } from './ZoomBoxCalculationHelper';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ElementSize } from '../../aureliaAttributes/ResizeObserverCustomAttribute';
import { NoZoomBoxDraggingCustomAttribute } from '../../aureliaAttributes/NoZoomBoxDraggingCustomAttribute';

/**
 *
 * @slot default - put all content just into this element
 *
 * @slot overlay - you can put extra stuff (e.g. markers) in here, important rules: elements in the overlay will not get scaled
 *  and they need to be positioned absolutely with percentage values, or else they will not be positioned correctly when zooming!
 *  also you need to set the overlayEnabled flag for the content to be shown
 *
 * @event content-clicked - fired when the content has been actually clicked (and not dragged)
 *  detail: {position: Vector} -> the position is for the content coordinates (and thus the same as if the zoom is 1)
 */
@autoinject()
export class ZoomBox {
  /**
   * value must be 1 at minimum!
   */
  @bindable public minZoom = 1;

  /**
   * maximum zoom has to be higher than or equal to minZoom
   */
  @bindable public maxZoom = 30;

  /**
   * the amount of zoom that should be changed when pressing the zoom button
   */
  @bindable public zoomButtonStep = 0.5;

  /**
   * the amount of zoom that should be changed per mousewheel trigger
   */
  @bindable public wheelZoomStep = 0.5;

  /**
   * look at the documentation for the overlay slot
   */
  @bindable public overlayEnabled = false;

  @bindable public customButtonConfigs: Array<ZoomBoxCustomButtonConfig> = [];

  @bindable public enabled = true;

  private zoom = 1;
  private translationVector = new Vector(0, 0);

  protected mouseInside = false;
  private domElement: HTMLElement;
  private scrollContainer: HTMLElement | null = null;

  private mouseDraggingDetector: MouseDraggingDetector | null = null;
  private touchDraggingDetector: TouchDraggingDetector | null = null;
  private pinchZoomDetector: PinchZoomDetector | null = null;
  private lastPointerPosition: Vector | null = null;

  private isAttached = false;

  private calculationHelper: ZoomBoxCalculationHelper;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;

    this.calculationHelper = new ZoomBoxCalculationHelper(
      this.zoom,
      null,
      new DOMRectReadOnly(0, 0, 0, 0)
    );
  }

  protected attached(): void {
    this.resetZoom();

    this.calculationHelper = this.calculationHelper.setScrollContainer(
      this.getScrollContainer()
    );

    this.mouseDraggingDetector = new MouseDraggingDetector(
      this.getScrollContainer(),
      {
        elementCanStartDragging: (element) =>
          !NoZoomBoxDraggingCustomAttribute.isInsideNoDraggingElement(element)
      }
    );
    this.mouseDraggingDetector.onDragStart(
      this.handleMouseDragStart.bind(this)
    );
    this.mouseDraggingDetector.onDrag(this.handleMouseDrag.bind(this));

    this.touchDraggingDetector = new TouchDraggingDetector(
      this.getScrollContainer(),
      {
        elementCanStartDragging: (element) =>
          !NoZoomBoxDraggingCustomAttribute.isInsideNoDraggingElement(element)
      }
    );
    this.touchDraggingDetector.onDragStart(
      this.handleTouchDragStart.bind(this)
    );
    this.touchDraggingDetector.onDrag(this.handleTouchDrag.bind(this));

    this.pinchZoomDetector = new PinchZoomDetector(this.getScrollContainer());
    this.pinchZoomDetector.onPinch(this.handlePinch.bind(this));

    this.isAttached = true;
    this.enabledChanged();
  }

  protected detached(): void {
    if (this.mouseDraggingDetector) this.mouseDraggingDetector.destroy();
    if (this.touchDraggingDetector) this.touchDraggingDetector.destroy();
    if (this.pinchZoomDetector) this.pinchZoomDetector.destroy();

    this.isAttached = false;
  }

  protected enabledChanged(): void {
    if (!this.isAttached) return;

    if (this.enabled) {
      this.enableDetectors();
    } else {
      this.disableDetectors();
    }
  }

  public resetZoom(): void {
    this.zoom = 1;
    this.calculationHelper = this.calculationHelper.setZoom(this.zoom);
    this.setTranslationVector(new Vector(0, 0));
  }

  private enableDetectors(): void {
    this.getMouseDraggingDetector().enable();
    this.getTouchDraggingDetector().enable();
    this.getPinchZoomDetector().enable();
  }

  private disableDetectors(): void {
    this.getMouseDraggingDetector().disable();
    this.getTouchDraggingDetector().disable();
    this.getPinchZoomDetector().disable();
  }

  /**
   * @param {number} zoom
   * @param {(Vector|null)} [fixationPoint] - needs to be the container point, this given point will still be on the same position (in the container) after zooming
   */
  public setZoom(zoom: number, fixationPoint?: Vector | null): void {
    if (!fixationPoint) {
      fixationPoint = this.calculationHelper
        .getScrollContainerSizeVector()
        .divideVector(new Vector(2, 2)); // center
    }
    const contentFixationPoint =
      this.getContentPointForContainerPoint(fixationPoint);

    zoom = Math.max(Math.min(zoom, this.maxZoom), this.minZoom);
    this.zoom = isNaN(zoom) ? this.minZoom : zoom;
    this.calculationHelper = this.calculationHelper.setZoom(this.zoom);

    this.moveContent(new Vector(0, 0)); // just to update the bounds (could be invalid now)

    this.moveToFixationPoint(contentFixationPoint, fixationPoint);
  }

  /**
   * returns the point of the content which are currently centered in the zoom-box
   */
  public getCenterPoint(): Vector {
    const size = this.calculationHelper.getScrollContainerSizeVector();
    return this.getContentPointForContainerPoint(
      size.divideVector(new Vector(2, 2))
    );
  }

  /**
   * returns the point of the content which is currently displayed at the given containerPoint
   */
  public getContentPointForContainerPoint(containerPoint: Vector): Vector {
    return this.calculationHelper.getContentPointForContainerPoint(
      containerPoint,
      this.translationVector
    );
  }

  /**
   * centers the given point of the content in the zoom-box as good as possible (since there are limitations on the bounds)
   */
  public setCenterPoint(centerPoint: Vector): void {
    const containerCenter = this.calculationHelper
      .getScrollContainerSizeVector()
      .divideVector(new Vector(2, 2));
    this.moveToFixationPoint(centerPoint, containerCenter);
  }

  public moveToFixationPoint(
    contentPoint: Vector,
    containerPoint: Vector
  ): void {
    this.setTranslationVector(
      this.calculationHelper.getTranslationForFixationPoint(
        contentPoint,
        containerPoint
      )
    );
  }

  public getZoomBoxDimensions(): Vector {
    return this.calculationHelper.getScrollContainerSizeVector();
  }

  protected handleMouseenter(): void {
    this.mouseInside = true;
  }

  protected handleMouseleave(): void {
    this.mouseInside = false;
  }

  protected handleNewScrollContainerSize(elementSize: ElementSize): void {
    this.calculationHelper =
      this.calculationHelper.setScrollContainerSize(elementSize);
  }

  protected handleScrollContainerClick(event: PointerEvent): void {
    if (
      this.mouseDraggingDetector &&
      this.mouseDraggingDetector.draggedRecently()
    ) {
      // it wasn't an actual click, problem doesn't exist for dragging
      return;
    }

    const containerPoint =
      this.calculationHelper.getContainerPointForMouseEvent(event);
    if (!containerPoint) {
      // not clicked inside the container
      return;
    }

    const contentPosition =
      this.calculationHelper.getContentPointForContainerPoint(
        containerPoint,
        this.translationVector
      );

    const boxTopLeft = this.getContentPointForContainerPoint(new Vector(0, 0));
    const boxBottomRight = this.getContentPointForContainerPoint(
      this.calculationHelper.getScrollContainerSizeVector()
    );

    DomEventHelper.fireEvent<ContentClickedEvent>(this.domElement, {
      name: 'content-clicked',
      detail: {
        position: contentPosition,
        boxTopLeft,
        boxBottomRight,
        originalEvent: event
      }
    });
  }

  protected handleZoomOutButtonClick(): void {
    this.setZoom(this.zoom - this.zoomButtonStep);
  }

  protected handleZoomInButtonClick(): void {
    this.setZoom(this.zoom + this.zoomButtonStep);
  }

  protected handleResetZoomButtonClick(): void {
    this.resetZoom();
  }

  protected handleCustomButtonClick(config: ZoomBoxCustomButtonConfig): void {
    config.onButtonClicked();
  }

  protected handleWheelEvent(event: WheelEvent): boolean {
    if (event.ctrlKey) {
      const zoomDiff =
        event.deltaY < 0 ? this.wheelZoomStep : -1 * this.wheelZoomStep;
      const mousePoint =
        this.calculationHelper.getContainerPointForMouseEvent(event);
      this.setZoom(this.zoom + zoomDiff, mousePoint);
      return false;
    } else {
      return true;
    }
  }

  // ////////// Dragging //////////

  private handleMouseDragStart(event: MouseEvent): void {
    this.lastPointerPosition = new Vector(event.clientX, event.clientY);
  }

  private handleMouseDrag(event: MouseEvent): void {
    this.handleNewPointerPosition(new Vector(event.clientX, event.clientY));
  }

  private handleTouchDragStart(event: TouchEvent): void {
    const touch = event.touches[0];
    assertNotNullOrUndefined(
      touch,
      'cannot handle touch start without a touch'
    );

    this.lastPointerPosition = new Vector(touch.clientX, touch.clientY);
  }

  private handleTouchDrag(event: TouchEvent): void {
    event.preventDefault();

    const touch = event.touches[0];
    assertNotNullOrUndefined(touch, 'cannot handle touch drag without a touch');

    this.handleNewPointerPosition(new Vector(touch.clientX, touch.clientY));
  }

  private handleNewPointerPosition(newPosition: Vector): void {
    if (this.lastPointerPosition)
      this.moveContent(
        newPosition.clone().substractVector(this.lastPointerPosition)
      );
    this.lastPointerPosition = newPosition;
  }

  // ////////// Pinch Zooming //////////

  private handlePinch(
    oldVector: Vector,
    oldMiddlePoint: Vector,
    newVector: Vector,
    newMiddlePoint: Vector
  ): void {
    const middlePointMovement = newMiddlePoint
      .clone()
      .substractVector(oldMiddlePoint);
    this.moveContent(middlePointMovement);

    this.setZoom(
      (this.zoom * newVector.getLength()) / oldVector.getLength(),
      this.calculationHelper.getContainerPointForClientPoint(newMiddlePoint)
    );
  }

  // ////////// View Calculation //////////

  private moveContent(moveVector: Vector): void {
    const targetVector = this.translationVector.addVector(moveVector);
    this.setTranslationVector(targetVector);
  }

  private setTranslationVector(vector: Vector): void {
    this.translationVector = this.calculationHelper.limitTranslation(vector);
  }

  protected getContentWrapperStyling(
    zoom: number,
    translationVector: Vector
  ): { transform: string } {
    return {
      transform:
        `translate(${translationVector.getX()}px, ${translationVector.getY()}px) ` +
        `scale(${zoom}, ${zoom})`
    };
  }

  protected getOverlayStyling(
    overlayEnabled: boolean,
    zoom: number,
    translationVector: Vector,
    calculationHelper: ZoomBoxCalculationHelper
  ): { height?: string; width?: string; left?: string; top?: string } {
    if (!overlayEnabled) {
      // we don't want to have an unnecessary calculation overhead
      return {};
    }

    const ratio = calculationHelper.getTranslationRatio(translationVector);
    const viewPortInfo = calculationHelper.getViewPortInfo();
    const offset = viewPortInfo.maxViewPortOffset
      .clone()
      .multiplyVector(ratio)
      .scale(zoom) // because the maxViewPortOffset is in the content itself and not zoomed
      .scale(-1); // because if we scroll to the right, we need to move the content to the left

    const sizePercent = 100 * zoom + '%';
    return {
      height: sizePercent,
      width: sizePercent,
      left: offset.getX() + 'px',
      top: offset.getY() + 'px'
    };
  }

  private getScrollContainer(): HTMLElement {
    assertNotNullOrUndefined(
      this.scrollContainer,
      'scrollContainer is not available!'
    );
    return this.scrollContainer;
  }

  private getMouseDraggingDetector(): MouseDraggingDetector {
    assertNotNullOrUndefined(
      this.mouseDraggingDetector,
      'mouseDraggingDetector is not available!'
    );
    return this.mouseDraggingDetector;
  }

  private getTouchDraggingDetector(): TouchDraggingDetector {
    assertNotNullOrUndefined(
      this.touchDraggingDetector,
      'touchDraggingDetector is not available!'
    );
    return this.touchDraggingDetector;
  }

  private getPinchZoomDetector(): PinchZoomDetector {
    assertNotNullOrUndefined(
      this.pinchZoomDetector,
      'pinchZoomDetector is not available!'
    );
    return this.pinchZoomDetector;
  }
}

export type ContentClickedEvent = NamedCustomEvent<
  'content-clicked',
  {
    position: Vector;
    boxTopLeft: Vector;
    boxBottomRight: Vector;
    originalEvent: PointerEvent;
  }
>;

export type ZoomBoxCustomButtonConfig = {
  faIconName: `fa-${string}`;
  onButtonClicked: () => void;
};
