import moment from 'moment';
import 'moment/locale/de-at.js';
import { MomentInput } from 'moment';
import _ from 'lodash';

export class DateUtils {
  /**
   * @param useUtc if true, date will be created with an utc hour & minute offset of 0,
   * otherwise with the current local timezone offset.
   */
  public static createDate(
    day: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    useUtc: boolean = false
  ): Date {
    const dayString = _.padStart(String(day), 2, '0');
    const monthString = _.padStart(String(month), 2, '0');
    const yearString = _.padStart(String(year), 4, '0');
    const hoursString = _.padStart(String(hours), 2, '0');
    const minutesString = _.padStart(String(minutes), 2, '0');

    let dateString = `${yearString}-${monthString}-${dayString}T${hoursString}:${minutesString}:00.000`;
    if (useUtc) {
      dateString += 'Z';
    }

    const date = new Date(dateString);

    // While in Firefox, new Date("2001-02-29T00:00:00.000Z") == Invalid Date,
    // Chrome decides to go ahead and decides that new Date("2001-02-29T00:00:00.000Z") == Thu Mar 01 2001
    //
    // Since the Firefox version is "more correct", we check if the day of the generated date
    // matches the given monthDay. If not, we return an invalid Date (so that Chrome gets it correct too).
    if (date.getDate() !== day) {
      return new Date(NaN);
    }

    return date;
  }

  public static datesAreEqual(
    a: Date | null | undefined,
    b: Date | null | undefined
  ): boolean {
    if (a == null && b == null) {
      return true;
    }

    const aTime = a ? a.getTime() : null;
    const bTime = b ? b.getTime() : null;

    return aTime === bTime;
  }

  public static formatToString(date: MomentInput, format?: string): string {
    return this.suppressInvalidDate(moment(date).format(format));
  }

  public static formatToDateWithTimeString(date: MomentInput): string {
    return this.formatToString(date, 'DD.MM.YYYY HH:mm:ss');
  }

  public static formatToDateWithHourMinuteString(date: MomentInput): string {
    return this.formatToString(date, 'DD.MM.YYYY HH:mm');
  }

  public static formatToLongDateString(date: MomentInput): string {
    return this.formatToString(date, 'dddd, Do MMMM YYYY');
  }

  public static formatToDateString(date: MomentInput): string {
    return this.formatToString(date, 'DD.MM.YYYY');
  }

  public static formatToTimeString(date: MomentInput): string {
    return this.formatToString(date, 'HH:mm:ss');
  }

  public static formatToHourMinuteString(date: MomentInput): string {
    return this.formatToString(date, 'HH:mm');
  }

  public static formatToDayOfWeekString(date: MomentInput): string {
    return this.formatToString(date, 'dddd');
  }

  public static formatToShortDayOfWeekString(date: MomentInput): string {
    return this.formatToString(date, 'dd');
  }

  public static formatToDayOfMonthString(date: MomentInput): string {
    return this.formatToString(date, 'DD');
  }

  public static formatToMonthString(date: MomentInput): string {
    return this.formatToString(date, 'MMMM');
  }

  public static formatToYearString(date: MomentInput): string {
    return this.formatToString(date, 'YYYY');
  }

  public static formatToLastTwoYearDigitsString(date: MomentInput): string {
    return this.formatToString(date, 'YY');
  }

  public static formatToIsoWeekString(date: MomentInput): string {
    return this.suppressInvalidDate(moment(date).isoWeek().toString());
  }

  public static getCurrentTimestamp(): number {
    return Date.now();
  }

  public static getDateWithDayOffsetWithoutTime(days: number): Date {
    const daysMs = days * 24 * 60 * 60 * 1000;
    const dateTo = new Date(Date.now() + daysMs);
    return DateUtils.removeTimeInfoFromDate(dateTo);
  }

  /**
   * @param date - gets modified in place
   * @returns {Date} - the passed instance
   */
  public static removeTimeInfoFromDate(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  /**
   * @param offset - 0 (or ommiting it) will return the timestamp of today at 00:00, -1 will return the timestamp of yesterday at 00:00, -2 will return the timestamp of the day before yesterday...
   * @returns {number}
   */
  public static getStartOfDayTimestamp(offset = 0): number {
    const d = this.getStartDateOfDay(new Date());

    return d.getTime() + offset * 86400000; // 60 * 60 * 24 * 1000 = 86400000 = number of milliseconds per day
  }

  /**
   * automatically converts the date so the current timezone is represented in the return string (instead of the UTC Timezone)
   *
   * @param date
   * @returns {string} - e.g. 2015-05-10
   */
  public static getIsoDateWithoutTime(date: Date): string {
    const d = new Date(date.getTime());
    d.setTime(d.getTime() + this.getNormalizedTimezoneOffsetFromDate(date));
    return d.toISOString().substring(0, 10); // 2018-10-17T08:17:09.555Z
  }

  /**
   * automatically converts the date so the current timezone is represented in the return string (instead of the UTC Timezone)
   *
   * @param date
   * @returns {string} - e.g. 2015-05-10T05:30:30
   */
  public static getIsoDateWithHourAndMinute(date: Date): string {
    const d = new Date(date.getTime());
    d.setTime(d.getTime() + this.getNormalizedTimezoneOffsetFromDate(date));
    return d.toISOString().substring(0, 16); // 2018-10-17T08:17:09.555Z
  }

  /**
   * returns the timezoneOffset in ms (also negates it so e.g. europe has positive numbers)
   *
   * @param date
   * @returns {number}
   */
  public static getNormalizedTimezoneOffsetFromDate(date: Date): number {
    return date.getTimezoneOffset() * -60 * 1000; // timezoneoffset is in minutes
  }

  public static getDaysInMonth(date: MomentInput): number {
    return moment(date).daysInMonth();
  }

  public static isWeekend(date: Date): boolean {
    const weekday = moment(date).isoWeekday();
    // 6 = saturday, 7 = sunday
    return weekday === 6 || weekday === 7;
  }

  /**
   *
   * @param {string} isoString
   * @returns {Date|null} - returns null if date couldn't be parsed
   */
  public static parseDateFromIsoString(isoString: string): Date | null {
    const d = new Date(isoString);

    // getTime isNaN when the date is invalid
    return !isNaN(d.getTime()) ? d : null;
  }

  public static toDate(date: MomentInput): Date {
    return moment(date).toDate();
  }

  public static isOnSameDay(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  public static isSameMonth(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isSame(date2, 'month');
  }

  public static isSameYear(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isSame(date2, 'year');
  }

  public static getStartDateOfDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
  }

  public static getEndDateOfDay(date: Date): Date {
    const newDate = new Date(date);
    newDate.setHours(23);
    newDate.setMinutes(59);
    newDate.setSeconds(59);
    newDate.setMilliseconds(999);

    return newDate;
  }

  public static getStartDateOfWeek(date: Date): Date {
    return moment(date).startOf('week').toDate();
  }

  public static getEndDateOfWeek(date: Date): Date {
    return moment(date).endOf('week').toDate();
  }

  public static getStartDateOfYear(date: moment.MomentInput): Date {
    return moment(date).startOf('year').toDate();
  }

  public static getEndDateOfYear(date: moment.MomentInput): Date {
    return moment(date).endOf('year').toDate();
  }

  public static getStartDateOfMonth(date: moment.MomentInput): Date {
    return moment(date).startOf('month').toDate();
  }

  public static getEndDateOfMonth(date: moment.MomentInput): Date {
    return moment(date).endOf('month').toDate();
  }

  public static getNextDay(date: Date): Date {
    return moment(date).add(1, 'day').toDate();
  }

  public static getDateOneYearAfter(date: moment.MomentInput): Date {
    return this.getDateWithYearOffset(date, 1);
  }

  public static getDateOneYearBefore(date: moment.MomentInput): Date {
    return this.getDateWithYearOffset(date, -1);
  }

  public static getDateOneMonthAfter(date: moment.MomentInput): Date {
    return moment(date).add(1, 'month').toDate();
  }

  public static getDateOneMonthBefore(date: moment.MomentInput): Date {
    return moment(date).subtract(1, 'month').toDate();
  }

  public static getDateOneWeekAfter(date: Date): Date {
    return moment(date).add(1, 'week').toDate();
  }

  public static getDateOneWeekBefore(date: Date): Date {
    return moment(date).subtract(1, 'week').toDate();
  }

  public static getDateWithHourOffset(
    date: MomentInput,
    offset: number = 1
  ): Date {
    return moment(date).add(offset, 'hour').toDate();
  }

  public static getDateWithDayOffset(date: Date, dayOffset: number): Date {
    return moment(date).add(dayOffset, 'day').toDate();
  }

  public static getDateWithMonthOffset(date: Date, offset: number = 1): Date {
    return moment(date).add(offset, 'month').toDate();
  }

  public static getDateWithYearOffset(
    date: moment.MomentInput,
    offset: number = 1
  ): Date {
    return moment(date).add(offset, 'year').toDate();
  }

  public static getDayOffsetBetween(
    date1: moment.MomentInput,
    date2: moment.MomentInput
  ): number {
    return moment(date1).diff(date2, 'day');
  }

  private static suppressInvalidDate(formattedString: string): string {
    return formattedString !== 'Invalid date' ? formattedString : '';
  }

  public static isBefore(date1: MomentInput, date2: MomentInput): boolean {
    return moment(date1).isBefore(date2);
  }

  /**
   * Within the given month and year, returns all dates where their day of the week is included in `weekdays`.
   */
  public static getWeekdaysInMonthAndYear(
    weekdays: Array<number>,
    month: number,
    year: number
  ): Array<Date> {
    const date = moment()
      .year(year)
      .month(month)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .milliseconds(0);
    const dates: Array<Date> = [];

    while (date.month() === month) {
      if (weekdays.includes(date.day())) {
        dates.push(date.toDate());
      }
      date.add(1, 'day');
    }

    return dates;
  }

  /**
   * Sort weekdays, assuming the week starts with monday.
   *
   * assumes all weekdays are unique in the given array.
   */
  private static sortWeekdays(weekday: Array<number>): Array<number> {
    // We want sunday after monday, therefore:
    // - we have some weekdays: [0, 2, 1] = Sunday, Tuesday, Monday
    // - we sort: [0, 1, 2] = Sunday, Monday, Tuesday
    // - and if the first entry is sunday, we pop it and push it to the end: [1, 2, 0] = Monday, Tuesday, Sunday
    const weekdays = [...weekday].sort();
    if (weekdays[0] === 0) {
      weekdays.shift();
      weekdays.push(0);
    }
    return weekdays;
  }

  /**
   * return all days ranging from startDate to endDate.
   */
  public static getDays(startDate: Date, endDate: Date): Array<Date> {
    const date = moment(startDate);
    const dates: Array<Date> = [];

    while (!date.isAfter(endDate)) {
      dates.push(date.toDate());
      date.add(1, 'day');
    }

    return dates;
  }

  public static getDurationInMillisecondsBetween(
    startDate: MomentInput,
    endDate: MomentInput
  ): number {
    return moment(endDate).diff(startDate);
  }

  public static getDurationInDaysBetween(
    startDate: MomentInput,
    endDate: MomentInput
  ): number {
    return moment(endDate).diff(startDate, 'days');
  }

  public static getDatePlusMilliseconds(
    date: MomentInput,
    milliseconds: number
  ): Date {
    return moment(date).add(milliseconds, 'milliseconds').toDate();
  }

  public static isBetween(
    date1: MomentInput,
    startDate: MomentInput,
    endDate: MomentInput
  ): boolean {
    return moment(date1).isBetween(startDate, endDate, undefined, '[]');
  }

  public static getDurationWithPrecision(
    startDate: MomentInput,
    endDate: MomentInput,
    unit: moment.unitOfTime.Base
  ): number {
    const startDateBegin = moment(startDate).startOf(unit);
    const endDateBegin = moment(endDate).startOf(unit);
    return moment(endDateBegin).diff(startDateBegin, unit);
  }

  public static mergeDateAndTime(date: Date, time: Date): Date {
    return this.createDate(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
      time.getHours(),
      time.getMinutes()
    );
  }

  public static dateRangesOverlap(
    range1: DateRange,
    range2: DateRange
  ): boolean {
    if (range1.from >= range2.from && range1.from <= range2.to) {
      return true;
    }

    if (range1.to >= range2.from && range1.to <= range2.to) {
      return true;
    }

    if (range1.from <= range2.from && range1.to >= range2.to) {
      return true;
    }

    return false;
  }

  public static getHumanReadableDurationBetween(
    startDate: MomentInput,
    endDate: MomentInput
  ): string | null {
    return this.humanizeExact(moment(endDate).diff(startDate));
  }

  /**
   * Converts the given duration into a human readable string.
   *
   * While `moment` has a `.humanize` method, it returns only approximate
   * values. This method, in contrast, always returns exact textual
   * representations of the duration.
   *
   * @example
   * const date1 = new Date("2022-01-01T10:00:00");
   * const date2 = new Date("2022-01-01T10:45:00");
   * const duration = moment(date1).diff(date2);
   * console.log(moment.duration(duration).humanize()); // => "eine Stunde"
   * console.log(DateUtils.humanizeExact(duration)); // => "45 Minuten"
   */
  public static humanizeExact(period: moment.DurationInputArg1): string | null {
    const duration = moment.duration(period);

    // return nothing when the duration is falsy or not correctly parsed (P0D)
    if (!duration || duration.toISOString() === 'P0D') return null;

    const stringRepresentationOfUnit = (
      unit: number,
      singular: string,
      plural: String
    ): string | null => {
      if (unit >= 1) {
        const flooredUnit = Math.floor(unit);
        return `${flooredUnit} ${flooredUnit > 1 ? plural : singular}`;
      }
      return null;
    };

    return [
      stringRepresentationOfUnit(duration.years(), 'Jahr', 'Jahre'),
      stringRepresentationOfUnit(duration.months(), 'Monat', 'Monate'),
      stringRepresentationOfUnit(duration.days(), 'Tag', 'Tage'),
      stringRepresentationOfUnit(duration.hours(), 'Stunde', 'Stunden'),
      stringRepresentationOfUnit(duration.minutes(), 'Minute', 'Minuten'),
      stringRepresentationOfUnit(duration.seconds(), 'Sekunde', 'Sekunden')
    ]
      .filter((u) => !!u)
      .join(', ');
  }

  /* min/max constants according to https://262.ecma-international.org/5.1/#sec-15.9.1.1
   *
   */
  public static DATE_MAX = new Date(8640e12);
  public static DATE_MIN = new Date(-8640e12);
}

export type DateRange = {
  from: Date;
  to: Date;
};
