import { Vector } from 'common/Geometry/Vector';

export class PointerEventHelper {
  /**
   * the distance we assume that is safe to say that the user wants to drag something instead of clicking it
   *
   * @type {number}
   */
  static minDragDistance = 50;

  /**
   *
   * @param {MouseEvent} event
   * @returns {boolean}
   */
  static leftMouseButtonIsPressed(event) {
    return (event.buttons & 1) != 0;
  }

  /**
   *
   * @param {{x: number, y: number}} pos1
   * @param {{x: number, y: number}} pos2
   * @returns {number}
   */
  static calculateDistance(pos1, pos2) {
    let diffX = pos1.x - pos2.x;
    let diffY = pos1.y - pos2.y;
    return Math.sqrt(diffX * diffX + diffY * diffY);
  }

  /**
   *
   * @param {{clientX: number, clientY: number}} p1
   * @param {{clientX: number, clientY: number}} p2
   * @returns {number}
   */
  static calculateClientDistance(p1, p2) {
    return PointerEventHelper.calculateDistance(
      {
        x: p1.clientX,
        y: p1.clientY
      },
      {
        x: p2.clientX,
        y: p2.clientY
      }
    );
  }

  /**
   * interpolates the mousepoints between the old and new position linearly
   *
   * @param {{x: number, y: number}} oldPosition
   * @param {{x: number, y: number}} newPosition
   * @returns {{x: number, y: number}[]}
   */
  static interpolateMousePoints(oldPosition, newPosition) {
    let diffX = oldPosition.x - newPosition.x;
    let diffY = oldPosition.y - newPosition.y;

    let distance = PointerEventHelper.calculateDistance(
      oldPosition,
      newPosition
    );
    let angle = Math.asin(Math.abs(diffY) / distance); //ignore the sign for now

    //determines how many points should be interpolated per distance (but in inverse)
    let iterationFactor = 2;
    let iterations = distance / iterationFactor;

    let points = [];

    //key starts at 1 to exclude oldPosition
    for (let key = 1; key < iterations; key++) {
      let currentDistance = key * iterationFactor;
      points.push({
        x: oldPosition.x - Math.cos(angle) * currentDistance * Math.sign(diffX),
        y: oldPosition.y - Math.sin(angle) * currentDistance * Math.sign(diffY)
      });
    }

    return points;
  }

  /**
   *
   * @param {MouseEvent} event
   * @param {ClientRect} boundingRect
   * @returns {boolean}
   */
  static mouseIsInsideClientRect(event, boundingRect) {
    return this.clientPointIsInsideClientRect(
      Vector.createHtmlVector(event.clientX, event.clientY),
      boundingRect
    );
  }

  /**
   *
   * @param {Touch} touch
   * @param {ClientRect} boundingRect
   * @returns {boolean}
   */
  static touchIsInsideClientRect(touch, boundingRect) {
    return this.clientPointIsInsideClientRect(
      Vector.createHtmlVector(touch.clientX, touch.clientY),
      boundingRect
    );
  }

  /**
   *
   * @param {Vector} point
   * @param {ClientRect} boundingRect
   * @returns {boolean}
   */
  static clientPointIsInsideClientRect(point, boundingRect) {
    return (
      point.getX() >= boundingRect.left &&
      point.getX() <= boundingRect.left + boundingRect.width &&
      point.getY() >= boundingRect.top &&
      point.getY() <= boundingRect.top + boundingRect.height
    );
  }

  /**
   *
   * @param {(MouseEvent|TouchEvent)} event
   * @returns {Vector}
   */
  static getClientPositionFromEvent(event) {
    let x = 0,
      y = 0;

    if (event.touches) {
      x = event.touches[0].clientX;
      y = event.touches[0].clientY;
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    return Vector.createHtmlVector(x, y);
  }
}
