import { autoinject, bindable } from 'aurelia-framework';
import {
  QuestionChoiceAction,
  QuestionChoiceActionType
} from 'common/Types/Entities/Question/QuestionDto';
import { Question } from '../../classes/EntityManager/entities/Question/types';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { DialogIconType } from 'common/Enums/DialogIconType';

@autoinject()
export class ChecklistQuestionChoiceActionSelector {
  @bindable public questionChoiceAction: QuestionChoiceAction | null = null;

  private selectionOptions = Object.values(QuestionChoiceActionType).map(
    (value) => ({
      labelTk: `checklistComponents.checklistQuestionChoiceActionSelector.${value}`,
      value: value
    })
  );

  private dialogIconTypeOptions = [
    {
      labelTk: `checklistComponents.checklistQuestionChoiceActionSelector.dialogNoIcon`,
      value: null
    },
    {
      labelTk: `checklistComponents.checklistQuestionChoiceActionSelector.dialogIconSuccess`,
      value: DialogIconType.SUCCESS
    },
    {
      labelTk: `checklistComponents.checklistQuestionChoiceActionSelector.dialogIconWarning`,
      value: DialogIconType.WARNING
    },
    {
      labelTk: `checklistComponents.checklistQuestionChoiceActionSelector.dialogIconError`,
      value: DialogIconType.ERROR
    }
  ];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly element: Element
  ) {}

  protected handleActionChanged(): void {
    DomEventHelper.fireEvent<QuestionChoiceActionChangedEvent>(this.element, {
      name: 'question-choice-action-changed',
      bubbles: false,
      detail: null
    });
  }

  @computed(model(EntityName.QuestionSet))
  protected get availableQuestionSets(): Array<QuestionSet> {
    return this.entityManager.questionSetRepository.getAll();
  }

  @computed(model(EntityName.Question))
  protected get availableQuestions(): Array<Question> {
    return this.entityManager.questionRepository.getAll();
  }

  @computed(expression('questionChoiceAction.actionType'))
  protected get isAddQuestion(): boolean {
    return (
      this.questionChoiceAction?.actionType ===
      QuestionChoiceActionType.ADD_QUESTION
    );
  }

  @computed(expression('questionChoiceAction.actionType'))
  protected get isAddQuestionSet(): boolean {
    return (
      this.questionChoiceAction?.actionType ===
      QuestionChoiceActionType.ADD_QUESTION_SET
    );
  }

  @computed(expression('questionChoiceAction.actionType'))
  protected get isRedirect(): boolean {
    return (
      this.questionChoiceAction?.actionType ===
      QuestionChoiceActionType.REDIRECT
    );
  }

  @computed(expression('questionChoiceAction.actionType'))
  protected get isOpenDialog(): boolean {
    return (
      this.questionChoiceAction?.actionType ===
      QuestionChoiceActionType.OPEN_DIALOG
    );
  }
}

export type QuestionChoiceActionChangedEvent = NamedCustomEvent<
  'question-choice-action-changed',
  null
>;
