import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskPosition } from './types';

export class ProcessTaskPositionRepository extends AppEntityRepository<EntityName.ProcessTaskPosition> {
  public getByProcessTaskGroupIdWithoutSnapshots(
    processTaskGroupId: string
  ): Array<ProcessTaskPosition> {
    return this.getAll().filter(
      (p) =>
        p.ownerProcessTaskGroupId === processTaskGroupId &&
        p.snapshotOfProcessTaskPositionId == null
    );
  }

  public getByProcessTaskIdWithoutSnapshots(
    processTaskId: string
  ): Array<ProcessTaskPosition> {
    return this.getAll().filter(
      (p) =>
        p.ownerProcessTaskId === processTaskId &&
        p.snapshotOfProcessTaskPositionId == null
    );
  }

  public getByCreatedAtAppointmentIdWithoutSnapshots(
    createdAtAppointmentId: string
  ): Array<ProcessTaskPosition> {
    return this.getAll().filter(
      (p) =>
        p.createdAtAppointmentId === createdAtAppointmentId &&
        p.snapshotOfProcessTaskPositionId == null
    );
  }

  public updateOrders(positions: Array<ProcessTaskPosition>): void {
    for (const [index, position] of positions.entries()) {
      const order = index + 1;
      if (position.order !== order) {
        position.order = order;
        this.update(position);
      }
    }
  }
}
