import $ from 'jquery';

import { bindable, observable, autoinject } from 'aurelia-framework';
import { BaseEntity } from '../classes/EntityManager/entities/BaseEntity';

@autoinject()
export class SorterCustomAttribute<T extends BaseEntity> {
  @bindable protected items: Array<T> = [];

  @bindable protected sortedItems: Array<T> = [];

  @bindable protected sortOption: SorterSortOption<T> | null = null;

  @bindable protected currentSortOption: SorterSortOption<T> | null = null;

  @observable protected currentSortDirection: boolean;

  private clickEventListener: () => void = () => {};

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
    this.currentSortDirection = false;
  }

  protected attached(): void {
    this.clickEventListener = this.setSortOption.bind(this);
    this.domElement.addEventListener('click', this.clickEventListener);
    this.itemsChanged();
  }

  protected detached(): void {
    this.domElement.removeEventListener('click', this.clickEventListener);
  }

  // aurelia change handler for items array
  protected itemsChanged(): void {
    if (
      this.items &&
      this.sortOption &&
      this.sortOption === this.currentSortOption
    ) {
      const sortedItems = this.items.slice(0, this.items.length);
      sortedItems.sort(this.sortOption.sortFunction);
      if (this.currentSortDirection) sortedItems.reverse();
      this.sortedItems = sortedItems;
    }
  }

  // aurelia change handler for currentSortOption
  protected currentSortOptionChanged(): void {
    if (this.sortOption === this.currentSortOption) {
      $(this.domElement).addClass('record-it-entity-list-active-sort-column');
      $(this.domElement).append(
        `<i class="fal ${
          this.currentSortDirection ? 'fa-chevron-up' : 'fa-chevron-down'
        }"></i>`
      );
    } else {
      $(this.domElement).removeClass(
        'record-it-entity-list-active-sort-column'
      );
      $(this.domElement).find('i.fal').remove();
    }

    this.itemsChanged();
  }

  // aurelia change handler for currentSortDirection
  protected currentSortDirectionChanged(): void {
    const item = $(this.domElement).find('i.fal');
    if (item.hasClass('fa-chevron-down') && this.currentSortDirection) {
      item.removeClass('fa-chevron-down');
      item.addClass('fa-chevron-up');
    } else if (item.hasClass('fa-chevron-up') && !this.currentSortDirection) {
      item.removeClass('fa-chevron-up');
      item.addClass('fa-chevron-down');
    }
  }

  private setSortOption(): void {
    if (this.sortOption === this.currentSortOption) {
      this.currentSortDirection = !this.currentSortDirection;
      this.itemsChanged();
    } else {
      this.currentSortOption = this.sortOption;
      // items will automatically be sorted in the currentSortOptionChanged
    }
  }
}

export type SorterSortOption<Item, Name = string> = {
  name: Name;
  sortFunction: (t1: Item, t2: Item) => number;
};

export type SorterSortOptions<
  Item,
  Keys extends string = keyof Item & string
> = {
  [Property in Keys]: SorterSortOption<Item, Property>;
};
