export class DomElementIdGenerator {
  private constructor() {}

  private static lastId: number = 0;

  public static getNextId(): string {
    const nextId = ++this.lastId;

    return nextId.toString();
  }
}
