import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonContactType } from '../../../../common/src/Enums/PersonContactType';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointmentContact } from '../../classes/EntityManager/entities/ProcessTaskAppointmentContact/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event {ProcessTaskAppointmentContactDeletedEvent} process-task-appointment-contact-deleted
 */
@autoinject()
export class ProcessTaskAppointmentContactWidget {
  @bindable()
  public processTaskAppointmentContact: ProcessTaskAppointmentContact | null =
    null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentContactPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointmentContact];

  protected personIdsWithoutEmailContact: Array<string> = [];
  protected PersonContactType = PersonContactType;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskAppointmentContactPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointmentContact,
        context: this as ProcessTaskAppointmentContactWidget,
        propertyName: 'processTaskAppointmentContact'
      });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updatePersonIdsWithoutEmailContact.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updatePersonIdsWithoutEmailContact.bind(this)
    );

    this.updatePersonIdsWithoutEmailContact();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updatePersonIdsWithoutEmailContact(): void {
    const personIds = this.entityManager.personRepository
      .getAll()
      .map((person) => person.id);
    const personIdsWithEmail = new Set(
      this.entityManager.personContactRepository
        .getAll()
        .filter((contact) => contact.contactType === PersonContactType.EMAIL)
        .map((contact) => contact.personId)
    );

    this.personIdsWithoutEmailContact = personIds.filter(
      (id) => !personIdsWithEmail.has(id)
    );
  }

  protected handlePersonIdChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointmentContact,
      "can't ProcessTaskAppointmentContactWidget.handlePersonIdChanged without a processTaskAppointmentContact"
    );
    const personContacts = this.processTaskAppointmentContact.personId
      ? this.entityManager.personContactRepository.getByPersonId(
          this.processTaskAppointmentContact.personId
        )
      : [];
    this.processTaskAppointmentContact.personContactId =
      personContacts[0]?.id ?? null;
    this.handleAppointmentContactChanged();
  }

  private handleAppointmentContactChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointmentContact,
      "can't ProcessTaskAppointmentContactWidget.handleAppointmentContactChanged without a processTaskAppointmentContact"
    );
    this.entityManager.processTaskAppointmentContactRepository.update(
      this.processTaskAppointmentContact
    );
  }

  protected handleDeleteClick(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointmentContact,
      "can't ProcessTaskAppointmentContactWidget.handleDeleteClick without a processTaskAppointmentContact"
    );
    this.entityManager.processTaskAppointmentContactRepository.delete(
      this.processTaskAppointmentContact
    );

    DomEventHelper.fireEvent<ProcessTaskAppointmentContactDeletedEvent>(
      this.element,
      {
        name: 'process-task-appointment-contact-deleted',
        detail: null
      }
    );
  }
}

export type ProcessTaskAppointmentContactDeletedEvent = NamedCustomEvent<
  'process-task-appointment-contact-deleted',
  null
>;
