import { autoinject, bindable } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { ProjectThingSectionPropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProjectThingSectionPropertyAdapter';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ThingSection } from '../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * display the properties of the given project and of the project before that one if available
 *
 * @attribute data-no-margin-bottom - since expandable containers are used internally, sometimes the margin-bottom of the last container is not desired
 */
@autoinject()
export class ThingSectionProjectProperties {
  @bindable()
  public thingSection: ThingSection | null = null;

  @bindable()
  public project: Project | null = null;

  /**
   * look at the @attribute documentation for more information
   */
  @bindable()
  public dataNoMarginBottom: any;

  @subscribableLifecycle()
  protected readonly thingSectionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingSection];

  private readonly subscriptionManager: SubscriptionManager;
  protected projectThingSectionPropertiesAdapter: ProjectThingSectionPropertyAdapter | null =
    null;

  protected previousProjectThingSectionPropertiesAdapter: ProjectThingSectionPropertyAdapter | null =
    null;

  protected previousProjectDate: string | null = null;

  /**
   * all projects in the same thing and with the same type in the thing of the project.
   * projects are sorted by creation date ascending
   */
  private allProjects: Array<Project> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.thingSectionPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ThingSection,
        context: this as ThingSectionProjectProperties,
        propertyName: 'thingSection'
      });

    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateAllProjects.bind(this)
    );
    this.updateAllProjects();

    this.updateProjectThingSectionPropertiesAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingSectionChanged(): void {
    if (this.isAttached) {
      this.updateProjectThingSectionPropertiesAdapter();
      this.updatePreviousProjectThingSectionPropertiesAdapter();
    }
  }

  protected projectChanged(): void {
    if (this.isAttached) {
      this.updateProjectThingSectionPropertiesAdapter();
      this.updateAllProjects();
    }
  }

  private updateProjectThingSectionPropertiesAdapter(): void {
    if (this.thingSection && this.project) {
      this.projectThingSectionPropertiesAdapter =
        new ProjectThingSectionPropertyAdapter({
          entityManager: this.entityManager,
          permissionsService: this.permissionsService,
          subscriptionManagerService: this.subscriptionManagerService,
          thingSection: this.thingSection,
          project: this.project
        });
    } else {
      this.projectThingSectionPropertiesAdapter = null;
    }
  }

  private updateAllProjects(): void {
    const projects = this.project
      ? this.entityManager.projectRepository.getByThingIdWithoutOperationsProjects(
          this.project.thing
        )
      : [];
    const projectType = this.project?.projectType ?? ProjectType.BASIC;

    this.allProjects = projects
      .filter((project) => {
        return project.projectType === projectType;
      })
      .sort((a, b) => a.created - b.created);

    this.updatePreviousProjectThingSectionPropertiesAdapter();
  }

  private updatePreviousProjectThingSectionPropertiesAdapter(): void {
    const index = this.project ? this.allProjects.indexOf(this.project) : -1;
    const previousProject = this.allProjects[index - 1];

    if (previousProject && this.thingSection) {
      this.previousProjectDate = DateUtils.formatToDateString(
        previousProject.createdAt
      );
      if (
        this.previousProjectThingSectionPropertiesAdapter?.getThingSection() !==
          this.thingSection ||
        this.previousProjectThingSectionPropertiesAdapter?.getProject() !==
          previousProject
      ) {
        this.previousProjectThingSectionPropertiesAdapter =
          new ProjectThingSectionPropertyAdapter({
            entityManager: this.entityManager,
            permissionsService: this.permissionsService,
            subscriptionManagerService: this.subscriptionManagerService,
            thingSection: this.thingSection,
            project: previousProject
          });
      }
    } else {
      this.previousProjectThingSectionPropertiesAdapter = null;
    }
  }

  @computed(expression('previousProjectThingSectionPropertiesAdapter'))
  protected get previousProject(): Project | null {
    if (this.previousProjectThingSectionPropertiesAdapter) {
      return this.previousProjectThingSectionPropertiesAdapter.getProject();
    }

    return null;
  }
}
