import {
  RequiredPropertyGroup,
  ValidationResult
} from './RequiredPropertyGroup';

export class RootRequiredPropertyGroup extends RequiredPropertyGroup {
  constructor() {
    super({ name: null });
  }

  public validate(): ValidationResult {
    for (const requiredProperty of this.requiredProperties) {
      if (!requiredProperty.hasValue) {
        return {
          success: false,
          failedAtProperty: requiredProperty.property
        };
      }
    }

    return {
      success: true
    };
  }
}
