import { ActionSheet, ActionSheetButton } from '@capacitor/action-sheet';
import { Share } from '@capacitor/share';
import { FileOpener } from '@capacitor-community/file-opener';
import { CapacitorException } from '@capacitor/core';

import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { PreferredOpenMethodInApps } from 'common/Enums/PreferredOpenMethodInApps';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { ActiveUserCompanySettingService } from '../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { UrlManager } from '../classes/UrlManager';
import { DeviceInfoHelper } from '../classes/DeviceInfoHelper';
import { Dialogs } from '../classes/Dialogs';
import { FileUtils } from '../classes/Utils/FileUtils/FileUtils';

@autoinject()
export class FileDownloadService {
  private static translationKeyPrefix = 'services.FileDownloadService.';

  constructor(
    private readonly i18n: I18N,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  public async downloadFileByToken(token: string): Promise<void> {
    const filePath = `/download-file/${token}`;
    await this.downloadFile(filePath);
  }

  public async downloadFile(filepath: string): Promise<void> {
    const url = PathUtils.joinPaths(UrlManager.webFolder, filepath);

    if (DeviceInfoHelper.isApp()) {
      // Since we download the file to the local filesystem first, this will only work in apps
      Dialogs.waitDialog();
      const fileUri = (await FileUtils.downloadFileToDownloadFolder(url))
        .nativeUrl;
      Dialogs.closeAllDialogs();

      const preferredOpenMethodInApps =
        this.activeUserCompanySettingService.getSettingProperty(
          'general.preferredOpenMethodInApps'
        );

      switch (preferredOpenMethodInApps) {
        case PreferredOpenMethodInApps.OPEN:
          await this.openFile(fileUri);
          break;

        case PreferredOpenMethodInApps.SHARE:
          await this.shareFile(fileUri);
          break;

        case PreferredOpenMethodInApps.SELECT:
        default:
          await this.selectMethod(fileUri);
      }
    } else {
      // without this setTimeout velocity.js can result in some loop sometimes (e.g. if you close a dialog right before calling the downloadFile function)
      setTimeout(() => {
        window.open(url, '_blank');
      }, 20);
    }
  }

  private async selectMethod(fileUri: string): Promise<void> {
    const actions: Array<ActionSheetButton> = [
      {
        title: this.i18n.tr(FileDownloadService.translationKeyPrefix + 'share')
      },
      {
        title: this.i18n.tr(FileDownloadService.translationKeyPrefix + 'open')
      },
      {
        title: this.i18n.tr(FileDownloadService.translationKeyPrefix + 'cancel')
      }
    ];

    const actionResult = await ActionSheet.showActions({
      title: this.i18n.tr(
        FileDownloadService.translationKeyPrefix + 'shareOrOpen'
      ),
      options: actions
    });

    switch (actionResult.index) {
      case 0:
        await this.shareFile(fileUri);
        break;

      case 1:
        await this.openFile(fileUri);
        break;

      default:
    }
  }

  private async shareFile(fileUri: string): Promise<void> {
    try {
      await Share.share({
        url: fileUri
      });
    } catch (error) {
      if (
        !(error instanceof CapacitorException) ||
        error.message !== 'Share canceled'
      ) {
        throw error;
      }
    }
  }

  private async openFile(fileUri: string): Promise<void> {
    try {
      await FileOpener.open({
        filePath: fileUri
      });
    } catch (error) {
      if (
        error instanceof CapacitorException &&
        (error.code as string) === '8' // code is a string since it's overridden by the FileOpener plugin
      ) {
        await this.shareFile(fileUri);
      } else {
        throw error;
      }
    }
  }
}
