import { autoinject } from 'aurelia-framework';
import { FileUploadService } from '../../../../services/FileUploadService';
import { SocketService } from '../../../../services/SocketService';
import { GeneralFileUploadStrategy } from '../../../UploadStrategies/GeneralFileUploadStrategy/GeneralFileUploadStrategy';
import { AppEntityManager } from '../AppEntityManager';
import { GeneralFile } from './types';

@autoinject()
export class GeneralFileUploadService {
  private readonly generalFileUploadStrategy: GeneralFileUploadStrategy;

  constructor(
    private readonly fileUploadService: FileUploadService,
    entityManager: AppEntityManager,
    socketService: SocketService
  ) {
    this.generalFileUploadStrategy = new GeneralFileUploadStrategy(
      entityManager,
      socketService
    );
  }

  public async init(): Promise<void> {
    this.fileUploadService.registerStrategy(this.generalFileUploadStrategy);
  }

  public destroy(): void {}

  public uploadGeneralFile(
    generalFile: GeneralFile,
    dataUrl: string,
    extension: string,
    showDialogs = true
  ): void {
    this.fileUploadService.submitUploadItem(
      this.generalFileUploadStrategy,
      {
        generalFileId: generalFile.id,
        dataUrl: dataUrl,
        extension: extension
      },
      {
        showDialogs
      }
    );
  }
}
