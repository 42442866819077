import { autoinject } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@autoinject()
export class SelectThingPictureDialog {
  private options: ISelectThingPictureDialogOptions | null = null;

  private thing: Thing | null = null;

  private dialog: RecordItDialog | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  public static async open(
    options: ISelectThingPictureDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: ISelectThingPictureDialogOptions): void {
    this.options = options;

    this.thing =
      this.entityManager.thingRepository.getById(this.options.thingId) || null;
    assertNotNullOrUndefined(this.thing, 'thing not found');

    this.dialog?.open();
  }

  private handleDialogClosed(returnValue: Picture | null): void {
    this.options?.onDialogClosed(returnValue);
    this.dialog?.close();
  }

  private handlePictureClicked(picture: Picture): void {
    this.handleDialogClosed(picture);
  }
}

interface ISelectThingPictureDialogOptions {
  thingId: string;
  onDialogClosed: (picture: Picture | null) => void;
}
