export class CommonEntityHelper {
  public static comparePositionByEntityOrder(
    infoA: { order: number | null },
    infoB: { order: number | null }
  ): number {
    const aOrder = infoA.order ?? Number.MAX_SAFE_INTEGER;
    const bOrder = infoB.order ?? Number.MAX_SAFE_INTEGER;

    return aOrder - bOrder;
  }
}
