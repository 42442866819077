import { inject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event values-changed
 */
@inject(Element, SubscriptionManagerService, AppEntityManager)
export class PersonListMultiSelect {
  /** @type {Array<string>} */
  @bindable values = [];

  /** @type {string|null} */
  @bindable userGroupId = null;

  @bindable enabled = false;

  /** @type {string|null} */
  @bindable filterByCategoryName = null;

  /** @type {HTMLElement} */
  _domElement;

  _attached = false;

  /** @type {Array<TPersonListMultiSelectSelectablePerson>} */
  _selectablePersons = [];

  /**
   * @param {HTMLElement} element
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(element, subscriptionManagerService, entityManager) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this._updateSelectablePersons.bind(this)
    );
    this._updateSelectablePersons();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  userGroupIdChanged() {
    this._updateSelectablePersonsIfAttached();
  }

  valuesChanged() {
    this._updateSelectablePersonsIfAttached();
  }

  _updateSelectablePersonsIfAttached() {
    if (this._attached) this._updateSelectablePersons();
  }

  _updateSelectablePersons() {
    const selectablePersons = this._getPersons()
      .filter(
        (person) =>
          !this.filterByCategoryName ||
          this.filterByCategoryName === person.categoryName
      )
      .map((person) => {
        const selected = !!(
          this.values && this.values.find((value) => value === person.id)
        );
        return {
          displayName: this._getDisplayNameForPerson(person),
          personId: person.id,
          person: person,
          selected: selected
        };
      });

    selectablePersons.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this._selectablePersons = selectablePersons;
  }

  /**
   * @returns {Array<Person>}
   */
  _getPersons() {
    if (this.userGroupId) {
      return this._entityManager.personRepository.getByUserGroupId(
        this.userGroupId
      );
    } else {
      return this._entityManager.personRepository.getAll();
    }
  }

  /**
   * @param {Person} person
   * @returns {string}
   */
  _getDisplayNameForPerson(person) {
    return PersonUtils.getPersonDisplayNameForPerson(person);
  }

  _sendEvent() {
    setTimeout(() => {
      DomEventHelper.fireEvent(this._domElement, {
        name: 'values-changed',
        detail: null
      });
    }, 0);
  }

  /**
   * @param {TPersonListMultiSelectSelectablePerson} person
   */
  _handleClickOnPerson(person) {
    const selectedPersons = this.values ? this.values.slice() : [];
    const index = selectedPersons.findIndex(
      (value) => value === person.personId
    );
    if (index < 0) {
      if (person.selected) selectedPersons.push(person.personId);
    } else {
      if (!person.selected) selectedPersons.splice(index, 1);
    }
    this.values = selectedPersons;
    this._sendEvent();
  }
}

/**
 * @typedef {import('../../classes/EntityManager/entities/Person/types').Person} Person
 */

/**
 * @typedef {Object} TPersonListMultiSelectSelectablePerson
 * @property {string} displayName
 * @property {string} personId
 * @property {Person} person
 * @property {boolean} selected
 */
