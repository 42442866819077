import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { TagSortingMode } from 'common/Enums/TagSortingMode';
import { groupBy } from 'lodash';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Tag, ThingTag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TagSorter } from '../../classes/TagSorter';
import { SelectCategoryTagsDialog } from '../../dialogs/select-category-tags-dialog/select-category-tags-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * Allow the user to select mutliple categorized tags from defects within the given thing.
 *
 * @event tags-changed {TagsChangedEvent} triggered when the selected tags change.
 */
@autoinject()
export class CategorizedTagsWidget {
  @bindable public enabled = true;

  @bindable public thingId: string | null = null;

  @bindable public ownerUserGroupId: string | null = null;

  @bindable public selectedTags: Array<ThingTag> = [];

  @bindable public label: string | null = null;

  /**
   * Whether tags will be displayed in multiple
   * lines, grouped by category.
   */
  @bindable public multiline = false;

  /**
   * The labels of the tags to display.
   *
   * Either the tags grouped by category, or one single
   * label specifying that no tags are selected if thats the
   * case.
   */
  protected groupedTagLabels: Array<string> = [];

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  constructor(
    element: Element,
    private readonly i18n: I18N,
    subManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateTagLabels.bind(this)
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateTagLabels();
        }
      )
    );
    this.updateTagLabels();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected selectedTagsChanged(): void {
    this.updateTagLabels();
  }

  /**
   * reduces an array of tags into nice strings with the tag names comma-seperated (grouped by category).
   */
  private getFormattedTagNames(tags: Array<ThingTag>): Array<string> {
    const sortedTags = TagSorter.sortTags(tags, this.tagSortingMode);
    const groupedTags = groupBy(sortedTags, 'category');

    return Object.values(groupedTags).map((categoryTags) =>
      categoryTags.map((t) => t.name).join(', ')
    );
  }

  private addTag(tag: ThingTag): void {
    if (!this.selectedTags.includes(tag)) {
      this.selectedTags.push(tag);
    }
  }

  private removeTag(tag: ThingTag): void {
    this.selectedTags = this.selectedTags.filter((t) => t !== tag);
  }

  private fireTagsChangedEvent(): void {
    DomEventHelper.fireEvent<TagsChangedEvent>(this.element, {
      name: 'tags-changed',
      detail: { tags: this.selectedTags }
    });
  }

  private handleTagCheckChanged(tag: Tag, checked: boolean): void {
    if (checked) {
      this.addTag(tag as ThingTag);
    } else {
      this.removeTag(tag as ThingTag);
    }
    this.fireTagsChangedEvent();
    this.updateTagLabels();
  }

  protected handleSelectCategoryTagsButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thingId,
      'cannot open SelectCategoryTagsDialog without thingId'
    );
    assertNotNullOrUndefined(
      this.ownerUserGroupId,
      'cannot open SelectCategoryTagsDialog without ownerUserGroupId'
    );

    void SelectCategoryTagsDialog.open({
      type: 'thing',
      thingId: this.thingId,
      ownerUserGroupId: this.ownerUserGroupId,
      getTagIds: () => this.selectedTags.map((t) => t.id),
      onTagCheckChanged: this.handleTagCheckChanged.bind(this)
    });
  }

  protected updateTagLabels(): void {
    if (this.selectedTags.length === 0) {
      const tr = this.i18n.tr(
        'aureliaComponents.categorizedTagsWidget.selectCategoryTagsButtonLabelPlaceholder'
      );
      this.groupedTagLabels = [tr];
    } else {
      this.groupedTagLabels = this.getFormattedTagNames(this.selectedTags);
    }
  }

  /**
   * All grouped tag labels in one single line.
   *
   * This will be displayed if `this.multiline == false`.
   */
  @computedFrom('groupedTagLabels')
  protected get allTagsLabel(): string {
    return this.groupedTagLabels.join(', ');
  }
}

export type TagsChangedEvent = NamedCustomEvent<
  'tags-changed',
  { tags: Array<ThingTag> }
>;
