import { assertNotNullOrUndefined } from 'common/Asserts';
import { Vector } from 'common/Geometry/Vector';
import { CoordsFromPositionedPictureInfo } from 'common/Types/Entities/Picture/PictureDto';
import { ContentClickedEvent } from '../../../aureliaComponents/zoom-box/zoom-box';
import { Picture as PictureViewModel } from '../../../picture/picture/picture';
import { Picture } from '../../EntityManager/entities/Picture/types';
import { Utils } from '../../Utils/Utils';

/**
 * a handler for clicks on a zoomBox with a positioned picture inside
 * suggested dom:
 *  <zoom-box content-clicked.trigger="positionedPictureZoomBoxClickHandler.handleZoomBoxContentClicked($event)">
 *    <picture ref="pictureElement"
 *             picture.to-view="options.picture"
 *             full-size.to-view="true"
 *             disable-dragging.to-view="true"
 *             show-selection.to-view="false">
 *    </picture>
 *  </zoom-box>
 */
export class PositionedPictureZoomBoxClickHandler {
  constructor(
    private readonly options: PositionedPictureZoomBoxClickHandlerOptions
  ) {}

  public handleZoomBoxContentClicked(event: ContentClickedEvent): void {
    if (event.detail.originalEvent.defaultPrevented) {
      return;
    }

    const picturePosition = this.getPercentPicturePositionFromZoomBoxPosition(
      event.detail.position
    );
    const boxTopLeft = this.getPercentPicturePositionFromZoomBoxPosition(
      event.detail.boxTopLeft
    );
    const boxBottomRight = this.getPercentPicturePositionFromZoomBoxPosition(
      event.detail.boxBottomRight
    );

    if (picturePosition) {
      const targetVector =
        this.getLatLongVectorForPercentPicturePosition(picturePosition);

      this.options.onCoordinatesSelected({
        coords: {
          latitude: targetVector.getY(),
          longitude: targetVector.getX()
        },
        coordsFromPositionedPictureInfo: {
          pictureId: this.getPicture().id,
          top: `${picturePosition.getY() * 100}%`,
          left: `${picturePosition.getX() * 100}%`,
          boxTop: (boxTopLeft?.getY() ?? 0) * 100,
          boxLeft: (boxTopLeft?.getX() ?? 0) * 100,
          boxBottom: (boxBottomRight?.getY() ?? 1) * 100,
          boxRight: (boxBottomRight?.getX() ?? 1) * 100
        }
      });
    }
  }

  /**
   * returns a percentual value of the contentPosition relative to the picture
   *
   * @param {Vector} contentPosition
   * @returns {Vector|null} - returns null if the click wasn't inside the picture
   */
  private getPercentPicturePositionFromZoomBoxPosition(
    contentPosition: Vector
  ): Vector | null {
    const pictureElement = this.options.getPictureElement();

    const picturePosition = new Vector(
      pictureElement.offsetLeft,
      pictureElement.offsetTop
    );
    const pictureComputed = window.getComputedStyle(pictureElement);
    const pictureWidth = parseFloat(pictureComputed.width);
    const pictureHeight = parseFloat(pictureComputed.height);

    const normalizedContentPosition = contentPosition
      .clone()
      .substractVector(picturePosition);
    const x = normalizedContentPosition.getX();
    const y = normalizedContentPosition.getY();

    if (x >= 0 && x <= pictureWidth && y >= 0 && y <= pictureHeight) {
      return normalizedContentPosition.divideVector(
        new Vector(pictureWidth, pictureHeight)
      );
    } else {
      return null;
    }
  }

  /**
   * @param {Vector} picturePosition - position in percent relative to the picture
   * @returns {Vector}
   */
  private getLatLongVectorForPercentPicturePosition(
    picturePosition: Vector
  ): Vector {
    const picture = this.getPicture();
    assertNotNullOrUndefined(
      picture.location_info,
      'picture has no location_info'
    );

    const topLeftVector = Vector.createFromLongitudeLatitude(
      picture.location_info.topLeftPosition
    );
    const topRightVector = Vector.createFromLongitudeLatitude(
      picture.location_info.topRightPosition
    );
    const bottomLeftVector = Vector.createFromLongitudeLatitude(
      picture.location_info.bottomLeftPosition
    );

    const widthVector = topRightVector.clone().substractVector(topLeftVector);
    const heightVector = bottomLeftVector
      .clone()
      .substractVector(topLeftVector);

    return topLeftVector
      .clone()
      .addVector(widthVector.clone().scale(picturePosition.getX()))
      .addVector(heightVector.clone().scale(picturePosition.getY()));
  }

  private getPicture(): Picture {
    const pictureViewModel = Utils.getViewModelOfElement(
      this.options.getPictureElement()
    );
    assertNotNullOrUndefined(
      pictureViewModel,
      'No view model of the picture found. Are you sure that the element of getPictureElement is a picture? If it is the picture, you may forgot to require it.'
    );
    if (!(pictureViewModel instanceof PictureViewModel)) {
      throw new Error(
        'The view model is not an instance of Picture. Make sure you return a picture in getPictureElement'
      );
    }

    assertNotNullOrUndefined(
      pictureViewModel.picture,
      'No picture entity found. The PositionedPictureZoomBoxClickHandler can only only handle clicks if a picture is set'
    );
    return pictureViewModel.picture;
  }
}

export type PositionedPictureZoomBoxClickHandlerOptions = {
  /**
   * return the element of the <picture> which is displayed in the zoom-box
   */
  getPictureElement: () => HTMLElement;
  /**
   * Gets called every time the user clicks on the picture.
   * If the user clicks inside the zoomBox, but not on the picture, nothing will happen
   */
  onCoordinatesSelected: (result: CoordinatesSelectedResult) => void;
};

export type CoordinatesSelectedResult = {
  coords: {
    latitude: number;
    longitude: number;
  };
  coordsFromPositionedPictureInfo: CoordsFromPositionedPictureInfo;
};
