import { inject, bindable, observable } from 'aurelia-framework';

import { ProcessConfigurationStepPositionUtils } from '../../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionUtils';
import { PageSwitcher } from '../../../aureliaComponents/page-switcher/page-switcher';
import { DomEventHelper } from '../../../classes/DomEventHelper';

import {
  TOperationsOfferPositionsViewPositionGroup,
  TOperationsOfferPositionsViewPositionCategory
} from '../operations-offer-positions-view';
import { Utils } from '../../../classes/Utils/Utils';
import { ProcessConfigurationStepPosition } from '../../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';

/**
 * @event position-clicked - detail: { position: ProcessConfigurationStepPosition }
 */
@inject(Element)
export class OperationsOfferPositionsViewCategoryGroup {
  @bindable
  public category: TOperationsOfferPositionsViewPositionCategory | null = null;

  @observable
  private activeGroup: TOperationsOfferPositionsViewPositionGroup | null;

  private ProcessConfigurationStepPositionUtils =
    ProcessConfigurationStepPositionUtils;

  private pageSwitcher: PageSwitcher | null = null;

  private desiredGroupSwitchWidth = 300;

  private domElement: HTMLElement;

  constructor(element: HTMLElement) {
    this.domElement = element;

    this.activeGroup = null;
  }

  private categoryChanged(): void {
    this.activeGroup = this.category?.groups[0] || null;
  }

  private activeGroupChanged(): void {
    this.pageSwitcher?.switchToPage(this.activeGroup?.groupName || null);
  }

  private getSubgroupsFromGroup(
    group: TOperationsOfferPositionsViewPositionGroup
  ): Array<IOperationsOfferPositionsViewPositionSubGroup> {
    const groupedPositions = Utils.groupBy(
      group.positions,
      (position) => position.subGroupName || ''
    );

    const subGroups: Array<IOperationsOfferPositionsViewPositionSubGroup> = [];
    for (const [subGroupName, positions] of groupedPositions) {
      subGroups.push({
        subGroupName: subGroupName,
        positions: positions
      });
    }

    this.sortGroups(subGroups);

    return subGroups;
  }

  private handlePositionClick(
    position: ProcessConfigurationStepPosition
  ): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'position-clicked',
      detail: { position }
    });
  }

  private sortGroups(
    groups: Array<IOperationsOfferPositionsViewPositionSubGroup>
  ): void {
    groups.sort((a, b) => {
      const aName = a.subGroupName || '';
      const bName = b.subGroupName || '';
      return aName.localeCompare(bName);
    });

    groups.forEach((g) => {
      g.positions.sort((a, b) => {
        return ProcessConfigurationStepPositionUtils.getFullName(
          a.namePrefix,
          a.name
        ).localeCompare(
          ProcessConfigurationStepPositionUtils.getFullName(
            b.namePrefix,
            b.name
          )
        );
      });
    });
  }
}

interface IOperationsOfferPositionsViewPositionSubGroup {
  subGroupName: string | null;
  positions: Array<ProcessConfigurationStepPosition>;
}
