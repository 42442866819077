import { AbortableFetchRequest } from '../../classes/AbortableFetchRequest';
import { DefaultFetchServiceResponse } from './response/DefaultFetchServiceResponse';
import { FetchServiceResponse } from './response/FetchServiceResponse';

export class FetchService {
  private readonly queuedRequests: Array<QueueItem> = [];
  private readonly runningRequests: Array<QueueItem> = [];

  private readonly numberOfParallelFetches = 10;

  public async fetch(url: string, context: any): Promise<FetchServiceResponse> {
    const request = new AbortableFetchRequest(url);

    const promise = new Promise<Response>((resolve, reject) => {
      this.queuedRequests.push({
        request: request,
        context,
        resolve,
        reject
      });
    });

    if (this.runningRequests.length < this.numberOfParallelFetches) {
      void this.run();
    }

    return new DefaultFetchServiceResponse(await promise);
  }

  public abortRequests(context: any): void {
    for (const [index, item] of Array.from(
      this.runningRequests.entries()
    ).reverse()) {
      if (item.context !== context) continue;
      item.request.abort();
      this.runningRequests.splice(index, 1);
    }

    for (const [index, item] of Array.from(
      this.queuedRequests.entries()
    ).reverse()) {
      if (item.context !== context) continue;
      this.queuedRequests.splice(index, 1);
    }
  }

  private async run(): Promise<void> {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const item = this.queuedRequests.shift();
      if (!item) break;

      this.runningRequests.push(item);

      try {
        await this.runQueueItem(item);
      } finally {
        const index = this.runningRequests.findIndex((i) => i === item);
        this.runningRequests.splice(index, 1);
      }
    }
  }

  private async runQueueItem(item: QueueItem): Promise<void> {
    try {
      const response = await item.request.fetch();
      item.resolve(response);
    } catch (error) {
      item.reject(error);
    }
  }
}

type QueueItem = {
  request: AbortableFetchRequest;
  context: any;
  resolve: (resp: Response) => void;
  reject: (reason: any) => void;
};
