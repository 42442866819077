import { assertNotNullOrUndefined } from 'common/Asserts';
import { Vector } from 'common/Geometry/Vector';
import { RepositionableElementUtils } from '../../RepositionableElementUtils';
import {
  HandleDragOptions,
  RepositionableElementDraggingHandler
} from '../RepositionableElementDraggingHandler';

export class RepositionableElementMoveDraggingHandler
  implements RepositionableElementDraggingHandler
{
  private readonly element: HTMLElement;
  private readonly pointerAnchorPoint: Vector;

  constructor(options: RepositionableElementMoveDraggingHandlerOptions) {
    this.element = options.element;
    this.pointerAnchorPoint = RepositionableElementUtils.getPointerAnchorPoint({
      element: options.element,
      initialPointerPosition: options.initialPointerPosition
    });
  }

  public handleDrag({ pointerPosition }: HandleDragOptions): MoveDragResult {
    assertNotNullOrUndefined(
      this.element.offsetParent,
      "can't handleMoveDragging without offsetParent"
    );
    const offsetParentRect = this.element.offsetParent.getBoundingClientRect();
    const offsetParentPosition = Vector.createHtmlVector(
      offsetParentRect.left,
      offsetParentRect.top
    );

    const newPosition = pointerPosition
      .clone()
      .substractVector(offsetParentPosition)
      .substractVector(this.pointerAnchorPoint);

    return {
      top: newPosition.getY(),
      left: newPosition.getX()
    };
  }
}

export type RepositionableElementMoveDraggingHandlerOptions = {
  element: HTMLElement;
  initialPointerPosition: Vector;
};

export type MoveDragResult = {
  top: number;
  left: number;
};
