import { computedFrom } from 'aurelia-framework';
import { ProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionAdapter } from '../EntityAdapter/ProcessTaskPositionAdapter/ProcessTaskPositionAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskPositionPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskPositionAdapter>,
  ProcessTaskPosition
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }

  @computedFrom('combinedRevision')
  public get canEditDetailEntries(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditDetailEntries');
  }
}
