import {
  EntityFilter,
  EntityFilterSubscribeOptions,
  EntityNameToIsVisibleFunction
} from '@record-it-npm/synchro-client';
import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import { SessionService } from '../../../services/SessionService/SessionService';
import { Disposable } from '../../Utils/DisposableContainer';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManager } from '../entities/AppEntityManager';
import { AppEntityManagerEntityTypesByEntityName } from '../entities/AppEntityManagerEntityTypesByEntityName';
import { Person } from '../entities/Person/types';
import { EntityName } from '../entities/types';
import { FilterData } from './FilterData';

export class AppEntityFilter
  implements
    EntityFilter<
      AppSynchronizationEnvironmentTypes,
      AppEntityManagerEntityTypesByEntityName
    >
{
  public readonly entityNameToIsVisibleFunction =
    this.generateEntityNameToIsVisibleFunction();

  private readonly entityManager: AppEntityManager;
  private readonly filterData: FilterData;

  constructor({
    entityManager,
    sessionService
  }: {
    entityManager: AppEntityManager;
    sessionService: SessionService;
  }) {
    this.entityManager = entityManager;

    this.filterData = new FilterData({
      entityManager,
      sessionService
    });
  }

  public waitForSubscriptionsToBeFinished(): Promise<void> {
    return this.filterData.waitForSubscriptionsToBeFinished();
  }

  public subscribe(
    options: EntityFilterSubscribeOptions<AppSynchronizationEnvironmentTypes>
  ): Disposable {
    return this.filterData.subscribe(options);
  }

  // we don't want to type this explicitly because it's easier to test if we have the exact type instead of a general EntityNameToFilterFunction type
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private generateEntityNameToIsVisibleFunction() {
    const checkProcessTaskGroupSubEntity = (options: {
      entity: { ownerUserGroupId: string; ownerProcessTaskGroupId: string };
    }): boolean => {
      return this.checkProcessTaskGroupSubEntity(options);
    };

    return createTypeExtendsValidator<
      EntityNameToIsVisibleFunction<
        AppSynchronizationEnvironmentTypes,
        AppEntityManagerEntityTypesByEntityName
      >
    >()({
      [EntityName.Thing]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.filterData.thingHasAuthorization({
              thingId: entity.id,
              userGroupId: entity.ownerUserGroupId
            });
          }
        });
      },
      [EntityName.ThingGroup]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.thingGroupHasAuthorization({
              thingGroupId: entity.id,
              userGroupId: entity.ownerUserGroupId
            });
          }
        });
      },
      [EntityName.Person]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.personHasAuthorization({
              person: entity
            });
          }
        });
      },
      [EntityName.Project]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.filterData.thingHasAuthorization({
              thingId: entity.thing,
              userGroupId: entity.ownerUserGroupId
            });
          }
        });
      },
      [EntityName.ThingSection]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.filterData.thingHasAuthorization({
              thingId: entity.ownerThingId,
              userGroupId: entity.ownerUserGroupId
            });
          }
        });
      },
      [EntityName.Property]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            if (entity.thing) {
              return this.filterData.thingHasAuthorization({
                thingId: entity.thing,
                userGroupId: entity.ownerUserGroupId
              });
            } else if (entity.thingGroupId) {
              return this.thingGroupHasAuthorization({
                thingGroupId: entity.thingGroupId,
                userGroupId: entity.ownerUserGroupId
              });
            } else if (entity.ownerProcessTaskGroupId) {
              return this.filterData.processTaskGroupHasAuthorization({
                processTaskGroupId: entity.ownerProcessTaskGroupId,
                userGroupId: entity.ownerUserGroupId
              });
            }

            return true;
          }
        });
      },
      [EntityName.ProcessTaskGroup]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            return this.filterData.processTaskGroupHasAuthorization({
              processTaskGroupId: entity.id,
              userGroupId: entity.ownerUserGroupId
            });
          }
        });
      },
      [EntityName.ProcessTaskGroupAuthorization]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTask]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskAppointment]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskAppointmentContact]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskAppointmentToProcessTaskDevice]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskAppointmentToProcessTaskPosition]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskAppointmentToUser]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskChecklistEntry]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskComment]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskDevice]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskInvoice]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskInvoiceToProcessTask]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskInvoiceToProcessTaskDevice]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskInvoiceToProcessTaskPosition]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskLogEntry]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskMeasurePoint]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskMeasurePointReading]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskMeasurePointToPicture]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskOffer]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskOfferToProcessTask]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskOfferToProcessTaskDevice]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskOfferToProcessTaskPosition]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskPosition]: checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskPositionDetailEntry]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskRecurringAppointment]:
        checkProcessTaskGroupSubEntity,
      [EntityName.ProcessTaskToProject]: checkProcessTaskGroupSubEntity,
      [EntityName.PropertyToProcessTaskPosition]: ({ entity }) => {
        return this.checkEntityIsVisible({
          userGroupId: entity.ownerUserGroupId,
          isAuthorized: () => {
            if (entity.ownerProcessTaskGroupId) {
              return this.filterData.processTaskGroupHasAuthorization({
                processTaskGroupId: entity.ownerProcessTaskGroupId,
                userGroupId: entity.ownerUserGroupId
              });
            } else if (entity.ownerProjectId) {
              const project =
                this.entityManager.projectRepository.getByIdIncludingInvisibleEntities(
                  entity.ownerProjectId
                );
              if (!project) {
                return false;
              }

              return this.filterData.thingHasAuthorization({
                thingId: project.thing,
                userGroupId: project.ownerUserGroupId
              });
            }

            return true;
          }
        });
      }
    });
  }

  private thingGroupHasAuthorization({
    thingGroupId,
    userGroupId
  }: {
    thingGroupId: string;
    userGroupId: string;
  }): boolean {
    const thingIds = this.filterData.getThingIdsForThingGroupId({
      thingGroupId
    });
    if (thingIds.length === 0) {
      return false;
    }

    return thingIds.some((thingId) => {
      return this.filterData.thingHasAuthorization({
        thingId,
        userGroupId
      });
    });
  }

  private personHasAuthorization({ person }: { person: Person }): boolean {
    if (
      this.filterData.canSeePersonsWithoutExplicitAuthorization({
        userGroupId: person.ownerUserGroupId
      })
    ) {
      return true;
    }

    const thingIds = this.filterData.getThingIdsForPersonId({
      personId: person.id
    });
    if (thingIds.length === 0) {
      return false;
    }

    return thingIds.some((thingId) => {
      return this.filterData.thingHasAuthorization({
        thingId,
        userGroupId: person.ownerUserGroupId
      });
    });
  }

  private checkProcessTaskGroupSubEntity({
    entity
  }: {
    entity: { ownerUserGroupId: string; ownerProcessTaskGroupId: string };
  }): boolean {
    return this.checkEntityIsVisible({
      userGroupId: entity.ownerUserGroupId,
      isAuthorized: () => {
        return this.filterData.processTaskGroupHasAuthorization({
          processTaskGroupId: entity.ownerProcessTaskGroupId,
          userGroupId: entity.ownerUserGroupId
        });
      }
    });
  }

  private checkEntityIsVisible({
    userGroupId,
    isAuthorized
  }: {
    userGroupId: string;
    isAuthorized: () => boolean;
  }): boolean {
    const userGroup =
      this.entityManager.userGroupRepository.getByIdIncludingInvisibleEntities(
        userGroupId
      );

    if (!userGroup?.controlEntityVisibilityWithAuthorizations) {
      return true;
    }

    return isAuthorized();
  }
}
