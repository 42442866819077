import { GeneralFileTypes } from 'common/Enums/GeneralFileTypes';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  DefectCommentGeneralFile,
  MapLayerGeneralFile,
  ProcessTaskAppointmentGeneralFile,
  ProcessTaskChecklistEntryGeneralFile,
  ProcessTaskCommentGeneralFile,
  PropertyGeneralFile
} from './types';

export class GeneralFileRepository extends AppEntityRepository<EntityName.GeneralFile> {
  public getByProcessTaskCommentId(
    processTaskCommentId: string
  ): Array<ProcessTaskCommentGeneralFile> {
    return this.getAll().filter((gf): gf is ProcessTaskCommentGeneralFile => {
      return gf.processTaskCommentId === processTaskCommentId;
    });
  }

  public getByPropertyId(propertyId: string): Array<PropertyGeneralFile> {
    return this.getAll().filter((gf): gf is PropertyGeneralFile => {
      return gf.propertyId === propertyId;
    });
  }

  public getSignatureFilesByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentGeneralFile> {
    return this.getAll().filter(
      (gf): gf is ProcessTaskAppointmentGeneralFile => {
        return (
          gf.processTaskAppointmentId === processTaskAppointmentId &&
          gf.type === GeneralFileTypes.SIGNATURE
        );
      }
    );
  }

  public getByProcessTaskChecklistEntryId(
    processTaskChecklistEntryId: string
  ): Array<ProcessTaskChecklistEntryGeneralFile> {
    return this.getAll().filter(
      (gf): gf is ProcessTaskChecklistEntryGeneralFile => {
        return gf.processTaskChecklistEntryId === processTaskChecklistEntryId;
      }
    );
  }

  public getSignatureFilesByPropertyId(
    propertyId: string
  ): Array<PropertyGeneralFile> {
    return this.getAll().filter((gf): gf is PropertyGeneralFile => {
      return (
        gf.propertyId === propertyId && gf.type === GeneralFileTypes.SIGNATURE
      );
    });
  }

  public getMapLayerFile(mapLayerId: string): MapLayerGeneralFile | null {
    return (
      this.getAll().find((gf): gf is MapLayerGeneralFile => {
        return gf.mapLayerId === mapLayerId;
      }) ?? null
    );
  }

  public getByDefectCommentId(
    defectCommentId: string
  ): Array<DefectCommentGeneralFile> {
    return this.getAll().filter((gf): gf is DefectCommentGeneralFile => {
      return gf.defectCommentId === defectCommentId;
    });
  }
}
