import { SortUtils } from 'common/Utils/SortUtils';

import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Region } from './types';

export class RegionRepository extends AppEntityRepository<EntityName.Region> {
  public getByThingId(thingId: string): Array<Region> {
    const regions = this.getAll().filter((region) => {
      return region.thingId === thingId;
    });
    return regions.sort((a, b) =>
      SortUtils.localeCompareFalsyNumericStrings(a.customId, b.customId)
    );
  }
}
