import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { FullScreenOverlay } from '../full-screen-overlay/full-screen-overlay';
import { GlobalElements } from '../global-elements/global-elements';

export class ImageFullScreenOverlay {
  protected options: OverlayOptions | null = null;

  protected fullScreenOverlay: FullScreenOverlay | null = null;

  public static async open(options: OverlayOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OverlayOptions): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      'cannot open ImageFullScreenOverlay without fullScreenOverlay'
    );

    this.options = options;
    this.fullScreenOverlay.open();
  }

  private close(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      'cannot close ImageFullScreenOverlay without fullScreenOverlay'
    );
    this.fullScreenOverlay.close();
  }

  protected handleFullScreenOverlayClosed(): void {
    this.options = null;
  }

  protected handleCloseButtonClick(): void {
    this.close();
  }
}

type OverlayOptions = {
  imageSrc: string;
};
