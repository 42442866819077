import { BehaviorPropertyObserver } from 'aurelia-framework';

export class AureliaLifecycleUtils {
  /**
   * Normally, Aurelia invokes change handlers of observable properties
   * immediately on bind - except when a component defines a `bind` function.
   * This is of course perfectly reasonable, completely expected and a good thing to do.
   *
   * Therefore, we replicate this functionality here so we can have a bind function and the default behavior
   *
   * @params scope - scope of the class. Will usually be `this`
   */
  public static executeDefaultAureliaBindFunctionality(scope: any): void {
    const observerToPreviousValue = new Map<
      BehaviorPropertyObserverWithInternalValues,
      any
    >();

    this.iterateOverBehaviorPropertyObservers({
      scope,
      handleObserver: (observer) => {
        observerToPreviousValue.set(observer, observer.currentValue);
      }
    });

    this.iterateOverBehaviorPropertyObservers({
      scope,
      handleObserver: (observer) => {
        const previousValue = observer.currentValue;

        // reapply the previous value, because thats the default aurelia behavior
        // this is necessary if you have a chain of obserables/bindables which are dependent on each other
        observer.currentValue = observerToPreviousValue.get(observer);

        /*
         * Often, the change handler of a given property is just `${propertyName}Changed`,
         * but this can be changed in the @observable / @bindable decorator.
         * ( @observable({ changeHandler: 'myChangeHandler' }) => changeHandler of the decorated property is `myChangeHandler` )
         *
         * Luckily, the change handler is stored in `selfSubscriber`. It expects a `newValue`
         * and an `oldValue`. To imitate Aurelia behaviour when bind is not defined, we pass in
         * the `currentValue` for the new value and `null` for the old one.
         */
        observer.selfSubscriber?.(observer.currentValue, previousValue);
      }
    });
  }

  private static iterateOverBehaviorPropertyObservers({
    scope,
    handleObserver
  }: {
    scope: any;
    handleObserver: (
      observer: BehaviorPropertyObserverWithInternalValues
    ) => void;
  }): void {
    const observerObj = scope['__observers__'];

    for (const [name, observer] of Object.entries(observerObj) as Array<
      [string, BehaviorPropertyObserverWithInternalValues | boolean]
    >) {
      if (typeof observer === 'boolean' || this.isChangeProp(name)) {
        continue;
      }

      if (observer instanceof BehaviorPropertyObserver) {
        handleObserver(observer);
      }
    }
  }

  /**
   * Returns whether a given prop is a changeProp registered by a hook.
   */
  public static isChangeProp(prop: string): boolean {
    return prop.startsWith('__') && prop.endsWith('__ComputedHook');
  }
}

type BehaviorPropertyObserverWithInternalValues = BehaviorPropertyObserver & {
  currentValue: any;
  selfSubscriber: ((newValue: any, oldValue: any) => void) | null;
};
