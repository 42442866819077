import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ColumnInfo } from 'common/EndpointTypes/SharepointEndpointsHandler';
import {
  MappingType,
  SynchronizationDirection
} from 'common/Types/Entities/SharepointListColumnToThingMappingItem/SharepointListColumnToThingMappingItemDto';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingTypeProperty } from '../../classes/EntityManager/entities/Property/types';
import { SharepointListColumnToThingMappingItem } from '../../classes/EntityManager/entities/SharepointListColumnToThingMappingItem/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  DialogClosedReason,
  RecordItDialog,
  RecordItDialogClosedEvent
} from '../../dialogs/record-it-dialog/record-it-dialog';
import { computed } from '../../hooks/computed';
import { configureHooks } from '../../hooks/configureHooks';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { ThingTypePropertyDefinitionHandle } from '../../propertyComponents/property-definition-widget/PropertyDefinitionWidgetHandle/ThingTypePropertyDefinitionHandle/ThingTypePropertyDefinitionHandle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@configureHooks({
  mount: 'open',
  unmount: 'handleDialogClosed'
})
@autoinject()
export class EditSharepointListColumnToThingMappingItemDialog {
  @subscribableLifecycle()
  protected readonly itemPermissionsHandle: EntityNameToPermissionsHandle[EntityName.SharepointListColumnToThingMappingItem];

  protected dialog: RecordItDialog | null = null;

  protected synchronizationOptions = Object.values(
    SynchronizationDirection
  ).map((v) => ({
    labelTk: `modelsDetail.SharepointListColumnToThingMappingListItem.SynchronizationDirection.${v}`,
    value: v
  }));

  protected item: SharepointListColumnToThingMappingItem | null = null;

  protected MappingType = MappingType;

  protected mappingTypes = Object.values(MappingType).map((v) => ({
    labelTk: `modelsDetail.SharepointListColumnToThingMappingListItem.MappingType.${v}`,
    value: v
  }));

  protected dialogType: 'accept' | 'accept-decline' = 'accept';
  protected doNotCloseOnBackdropClick: boolean = false;

  private thingGroup: ThingGroup | null = null;
  private listColumnInfos: Array<ColumnInfo> = [];
  private onDialogClosed: OnDialogClosed | null = null;

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.itemPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.SharepointListColumnToThingMappingItem,
        context: this,
        expression: 'item'
      });
  }

  public open(options: Options): void {
    this.thingGroup = options.thingGroup;
    this.item = options.sharepointListColumnToThingMappingItem;
    this.onDialogClosed = options.onDialogClosed;

    this.dialogType = options.showDeclineButton ? 'accept-decline' : 'accept';
    this.doNotCloseOnBackdropClick = options.showDeclineButton;

    this.listColumnInfos = options.listColumnInfos;
    this.dialog?.open();
  }

  protected handleDialogClosed(event: RecordItDialogClosedEvent): void {
    this.onDialogClosed?.({
      accepted:
        event.detail.reason === DialogClosedReason.BUTTON &&
        event.detail.buttonName === 'accept'
    });
  }

  @computed(
    expression('listColumnInfos'),
    expression('assignedListColumnNames'),
    expression('item.sharepointListColumnName')
  )
  protected get availableListColumnInfos(): Array<ColumnInfo> {
    return this.listColumnInfos.filter(
      (c) =>
        c.name === this.item?.sharepointListColumnName ||
        !this.assignedListColumnNames.includes(c.name)
    );
  }

  @computed(
    expression('thingGroup'),
    model(EntityName.SharepointListColumnToThingMappingItem)
  )
  protected get items(): Array<SharepointListColumnToThingMappingItem> {
    if (!this.thingGroup?.id) return [];

    return this.entityManager.sharepointListColumnToThingMappingItemRepository.getByThingGroupId(
      this.thingGroup.id
    );
  }

  @computed(expression('usedThingFields'), expression('item.thingFieldName'))
  protected get availableThingFields(): Array<string> {
    return [
      'customId',
      'name',
      'longitude',
      'latitude',
      'description',
      'status'
    ].filter(
      (f) =>
        f === this.item?.thingFieldName || !this.usedThingFields.includes(f)
    );
  }

  @computed(expression('availableThingFields'))
  protected get availableThingFieldOptions(): Array<{
    labelTk: string;
    value: string;
  }> {
    return this.availableThingFields.map((f) => ({
      labelTk: `modelsDetail.ThingModel.${f}`,
      value: f
    }));
  }

  @computed(
    model(EntityName.Property),
    expression('thingGroup.sharepointSettings.thingTypeId')
  )
  protected get availableThingTypeProperties(): Array<ThingTypeProperty> {
    if (this.thingGroup?.sharepointSettings.thingTypeId) {
      return this.entityManager.propertyRepository.getByThingTypeId(
        this.thingGroup.sharepointSettings.thingTypeId
      );
    }

    return [];
  }

  @computed(expression('item.thingTypePropertyId'))
  protected get propertyDefinitionWidgetHandle(): ThingTypePropertyDefinitionHandle | null {
    if (this.item?.thingTypePropertyId) {
      const property = this.entityManager.propertyRepository.getById(
        this.item.thingTypePropertyId
      );
      if (!property) return null;

      return new ThingTypePropertyDefinitionHandle({
        entityManager: this.entityManager,
        permissionsService: this.permissionsService,
        property: property as ThingTypeProperty
      });
    } else {
      return null;
    }
  }

  @computed(expression('isValid'))
  protected get disabledButton(): 'accept' | null {
    if (!this.isValid) {
      return 'accept';
    }

    return null;
  }

  protected handleItemChanged(): void {
    assertNotNullOrUndefined(this.item, 'cannot change item without an item');
    this.entityManager.sharepointListColumnToThingMappingItemRepository.update(
      this.item
    );
  }

  @computed(expression('items'))
  private get assignedListColumnNames(): Array<string> {
    return this.items.map((i) => i.sharepointListColumnName);
  }

  @computed(expression('items'))
  private get usedThingFields(): Array<string> {
    return this.items.map((i) => i.thingFieldName).filter((i) => !!i);
  }

  @computed(
    expression('availableListColumnInfos'),
    expression('item.mappingType'),
    expression('item.sharepointListColumnName'),
    expression('item.thingFieldName'),
    expression('item.thingTypePropertyId')
  )
  private get isValid(): boolean {
    switch (this.item?.mappingType) {
      case MappingType.THING_FIELD:
        return (
          !!this.availableListColumnInfos.find(
            (c) => c.name === this.item?.sharepointListColumnName
          ) && !!this.item.thingFieldName
        );

      case MappingType.THING_PROPERTY:
        return (
          !!this.availableListColumnInfos.find(
            (c) => c.name === this.item?.sharepointListColumnName
          ) && !!this.item.thingTypePropertyId
        );

      default:
        return false;
    }
  }
}

type Options = {
  thingGroup: ThingGroup;
  sharepointListColumnToThingMappingItem: SharepointListColumnToThingMappingItem;
  listColumnInfos: Array<ColumnInfo>;
  showDeclineButton: boolean;
  onDialogClosed: OnDialogClosed;
};

type OnDialogClosed = (options: { accepted: boolean }) => void;
