import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { BaseTileLayerInfo } from '../../map/basemap-map/basemap-map';
import { TileLayer } from 'leaflet';
import { SocketTileVectorGrid } from '../../map/basemap-map/SocketTileVectorGrid';
import { ImageHelper } from '../../classes/ImageHelper';
import { VectorTileLayerInfo } from '../../map/basemap-map/MapLayerManager';

/**
 * this class can draw an area (given in lat long) of the basemap normal layer onto a canvas element
 */
export class MapAreaDrawer {
  private topLeftPoint: ILatLong;
  private bottomRightPoint: ILatLong;

  private canvas: HTMLCanvasElement;

  constructor(
    topLeftPoint: ILatLong,
    bottomRightPoint: ILatLong,
    canvas: HTMLCanvasElement
  ) {
    this.topLeftPoint = topLeftPoint;
    this.bottomRightPoint = bottomRightPoint;
    this.canvas = canvas;
  }

  public async draw(
    tileLayerInfos: Array<BaseTileLayerInfo>,
    vectorTileLayerInfos: Array<VectorTileLayerInfo>
  ): Promise<void> {
    tileLayerInfos = tileLayerInfos.filter((info) => !info.excludeFromExport);
    vectorTileLayerInfos = vectorTileLayerInfos.filter(
      (info) => !info.opts?.excludeFromExport
    );

    const zoom = this.calculateZoom();

    const imageTileLayers = await this.loadTiles(tileLayerInfos, zoom);
    const vectorTileLayers = await this.loadVectorTiles(
      vectorTileLayerInfos,
      zoom
    );
    const tileLayers = [...imageTileLayers, ...vectorTileLayers];

    const drawingInfo = this.prepareCanvas();
    this.drawTileLayers(tileLayers, drawingInfo);
    this.drawAttribution(tileLayers);
  }

  private calculateZoom(): number {
    /*
    distance = 2^invZoom * factor
    2^invZoom = distance/factor
    invZoom * log(2) = log(distance/factor)
    invZoom = log(distance/factor)/log(2)

    factor = distance / 2^invZoom
     */
    const factor = 64; // for 2681m distance we want 14 zoom (value chosen by zooming around in the map manually)
    const width = CoordinateHelper.calculateDistance(
      this.topLeftPoint.long,
      this.topLeftPoint.lat,
      this.bottomRightPoint.long,
      this.topLeftPoint.lat
    );
    const height = CoordinateHelper.calculateDistance(
      this.topLeftPoint.long,
      this.topLeftPoint.lat,
      this.topLeftPoint.long,
      this.bottomRightPoint.lat
    );
    const distance =
      width != null && height != null ? Math.min(height, width) : 0;

    const inverseZoom = Math.floor(Math.log(distance / factor) / Math.log(2));
    // our maximum zoom is 18 and our minimum zoom is 1
    return 20 - Math.min(Math.max(inverseZoom, 2), 19);
  }

  private loadTiles(
    layerInfos: Array<BaseTileLayerInfo>,
    zoom: number
  ): Promise<Array<ITileLayer>> {
    const tiles = this.getNeededTiles(zoom);
    return this.loadTileLayers(layerInfos, tiles, zoom);
  }

  private getNeededTiles(zoom: number): Array<{ x: number; y: number }> {
    const topLeftTilePoint = this.latLongToTilePoint(this.topLeftPoint, zoom);
    const bottomRightTilePoint = this.latLongToTilePoint(
      this.bottomRightPoint,
      zoom
    );

    const neededTiles = [];
    for (let x = topLeftTilePoint.x; x <= bottomRightTilePoint.x; x++) {
      for (let y = topLeftTilePoint.y; y <= bottomRightTilePoint.y; y++) {
        neededTiles.push({ x: x, y: y });
      }
    }

    return neededTiles;
  }

  private async loadTileLayers(
    layerInfos: Array<BaseTileLayerInfo>,
    tiles: Array<{ x: number; y: number }>,
    zoom: number
  ): Promise<Array<ITileLayer>> {
    const drawerTileLayers = [];

    for (const info of layerInfos) {
      drawerTileLayers.push({
        tileImages: await this.loadTileImages(tiles, zoom, info.layer),
        attributionString: info.attributionExport || ''
      });
    }

    return drawerTileLayers;
  }

  private loadTileImages(
    tiles: Array<{ x: number; y: number }>,
    zoom: number,
    leafletLayer: TileLayer
  ): Promise<Array<ITileImage>> {
    const promises: Array<Promise<ITileImage>> = [];

    tiles.forEach((tile) => {
      promises.push(
        new Promise((res) => {
          const img = new Image();
          img.onload = () => {
            res({
              image: img,
              x: tile.x,
              y: tile.y,
              zoom: zoom
            });
          };

          img.onerror = () => {
            res({
              image: null,
              x: tile.x,
              y: tile.y,
              zoom: zoom
            });
          };

          img.crossOrigin = 'Anonymous';
          img.src = this.getUrlForTile(tile.x, tile.y, zoom, leafletLayer);
        })
      );
    });

    return Promise.all(promises);
  }

  private loadVectorTiles(
    layerInfos: Array<VectorTileLayerInfo>,
    zoom: number
  ): Promise<Array<ITileLayer>> {
    const tiles = this.getNeededTiles(zoom);
    return this.loadVectorTileLayers(layerInfos, tiles, zoom);
  }

  private async loadVectorTileLayers(
    layerInfos: Array<VectorTileLayerInfo>,
    tiles: Array<{ x: number; y: number }>,
    zoom: number
  ): Promise<Array<ITileLayer>> {
    const vectorTileLayers: Array<ITileLayer> = [];

    for (const layerInfo of layerInfos) {
      vectorTileLayers.push({
        tileImages: await this.loadVectorTileSvgImages(
          tiles,
          zoom,
          layerInfo.layer
        ),
        attributionString: '' // currently user's map layers have no attribution
      });
    }

    return vectorTileLayers;
  }

  private loadVectorTileSvgImages(
    tiles: Array<{ x: number; y: number }>,
    zoom: number,
    layer: SocketTileVectorGrid
  ): Promise<Array<ITileImage>> {
    return Promise.all(
      tiles.map((tile) => {
        return this.loadTile({ tile, zoom, layer });
      })
    );
  }

  private async loadTile({
    tile,
    zoom,
    layer
  }: {
    tile: { x: number; y: number };
    zoom: number;
    layer: SocketTileVectorGrid;
  }): Promise<ITileImage> {
    const svg = await layer.createSvgTile({
      x: tile.x,
      y: tile.y,
      z: zoom
    });

    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

    const dataUrl = ImageHelper.svgElementToDataUrl(svg);

    return new Promise((res) => {
      const img = new Image(256, 256);

      img.onload = () => {
        res({
          image: img,
          x: tile.x,
          y: tile.y,
          zoom: zoom
        });
      };

      img.onerror = () => {
        res({
          image: null,
          x: tile.x,
          y: tile.y,
          zoom: zoom
        });
      };

      img.crossOrigin = 'Anonymous';
      img.src = dataUrl;
    });
  }

  private prepareCanvas(): ICanvasDrawingInfo {
    const xDistance = CoordinateHelper.calculateDistance(
      this.topLeftPoint.long,
      this.topLeftPoint.lat,
      this.bottomRightPoint.long,
      this.topLeftPoint.lat
    );
    const yDistance = CoordinateHelper.calculateDistance(
      this.topLeftPoint.long,
      this.topLeftPoint.lat,
      this.topLeftPoint.long,
      this.bottomRightPoint.lat
    );
    const ratio =
      xDistance != null && yDistance != null ? xDistance / yDistance : 1;

    if (ratio >= 1) {
      this.canvas.width = 1920;
      this.canvas.height = 1920 / ratio;
    } else {
      this.canvas.width = 1920 * ratio;
      this.canvas.height = 1920;
    }

    const ctx = this.getCanvasRenderingContext();

    ctx.fillStyle = '#f5f5f5';
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    return {
      pixelLatRatio:
        this.canvas.height /
        (this.topLeftPoint.lat - this.bottomRightPoint.lat),
      pixelLongRatio:
        this.canvas.width /
        (this.topLeftPoint.long - this.bottomRightPoint.long)
    };
  }

  private drawTileLayers(
    layers: Array<ITileLayer>,
    info: ICanvasDrawingInfo
  ): void {
    layers.forEach((layer) => {
      this.drawTileImages(layer.tileImages, info);
    });
  }

  private drawTileImages(
    tileImages: Array<ITileImage>,
    info: ICanvasDrawingInfo
  ): void {
    const ctx = this.getCanvasRenderingContext();

    for (const tileImage of tileImages) {
      const topLeftLatLong = this.tileToLatLong(
        { x: tileImage.x, y: tileImage.y },
        tileImage.zoom
      );
      const bottomRightLatLong = this.tileToLatLong(
        { x: tileImage.x + 1, y: tileImage.y + 1 },
        tileImage.zoom
      );

      const x1 =
        (this.topLeftPoint.long - topLeftLatLong.long) * info.pixelLongRatio;
      const y1 =
        (this.topLeftPoint.lat - topLeftLatLong.lat) * info.pixelLatRatio;
      const x2 =
        (this.topLeftPoint.long - bottomRightLatLong.long) *
        info.pixelLongRatio;
      const y2 =
        (this.topLeftPoint.lat - bottomRightLatLong.lat) * info.pixelLatRatio;

      if (tileImage.image) {
        ctx.drawImage(tileImage.image, x1, y1, x2 - x1, y2 - y1);
      }
    }
  }

  private drawAttribution(layers: Array<ITileLayer>): void {
    const attributionText = this.getAttributionText(layers);

    const ctx = this.getCanvasRenderingContext();
    const fontSize = 25;
    ctx.font = fontSize + 'px Arial';
    ctx.textAlign = 'right';

    const padding = 10;
    const x = this.canvas.width - padding;
    const y = this.canvas.height - padding;

    const textInfo = ctx.measureText(attributionText);

    const recX = this.canvas.width - 2 * padding - textInfo.width;
    // for some reason arial is around 0.8 * fontSize high (being able to use fontSize would be to easy)
    // I just guessed the 0.8 factor
    const recY = this.canvas.height - 2 * padding - fontSize * 0.8;

    ctx.fillStyle = 'white';
    ctx.fillRect(
      recX,
      recY,
      this.canvas.width - recX,
      this.canvas.height - recY
    );

    ctx.fillStyle = 'black';
    ctx.fillText(attributionText, x, y);
  }

  private getAttributionText(layers: Array<ITileLayer>): string {
    const texts: Array<string> = [];

    layers.forEach((layer) => {
      if (
        layer.attributionString &&
        texts.indexOf(layer.attributionString) === -1
      ) {
        texts.push(layer.attributionString);
      }
    });

    return 'Datenquelle: ' + texts.join(' und/oder ');
  }

  private latLongToTilePoint(
    latLong: ILatLong,
    zoom: number
  ): { x: number; y: number } {
    // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29

    return {
      x: Math.floor(((latLong.long + 180) / 360) * Math.pow(2, zoom)),
      y: Math.floor(
        ((1 -
          Math.log(
            Math.tan((latLong.lat * Math.PI) / 180) +
              1 / Math.cos((latLong.lat * Math.PI) / 180)
          ) /
            Math.PI) /
          2) *
          Math.pow(2, zoom)
      )
    };
  }

  private tileToLatLong(
    point: { x: number; y: number },
    zoom: number
  ): ILatLong {
    // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#ECMAScript_.28JavaScript.2FActionScript.2C_etc..29

    const n = Math.PI - (2 * Math.PI * point.y) / Math.pow(2, zoom);
    return {
      long: (point.x / Math.pow(2, zoom)) * 360 - 180,
      lat: (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)))
    };
  }

  private getUrlForTile(
    x: number,
    y: number,
    z: number,
    layer: TileLayer
  ): string {
    let url = (layer as any)._url; // accessing privates is a bad practise, but there is no other way

    const firstSubdomain = layer.options.subdomains?.[0];
    if (firstSubdomain) {
      url = this.replaceStringVariable(url, 's', firstSubdomain);
    }
    url = this.replaceStringVariable(url, 'x', x.toString());
    url = this.replaceStringVariable(url, 'y', y.toString());
    url = this.replaceStringVariable(url, 'z', z.toString());

    return url;
  }

  private replaceStringVariable(
    str: string,
    name: string,
    value: string
  ): string {
    return str.replace('{' + name + '}', value);
  }

  private getCanvasRenderingContext(): CanvasRenderingContext2D {
    const ctx = this.canvas.getContext('2d');

    if (!ctx) {
      throw new Error("couldn't get canvas context");
    }

    return ctx;
  }
}

interface ILatLong {
  lat: number;
  long: number;
}

interface ITileLayer {
  tileImages: Array<ITileImage>;
  attributionString: string;
}

interface ITileImage {
  image: HTMLImageElement | null; // image is null if it couldn't be loaded for some reason
  x: number;
  y: number;
  zoom: number;
}

interface ICanvasDrawingInfo {
  pixelLatRatio: number; // number of pixels per 1 degree latitude
  pixelLongRatio: number; // number of pixels per 1 degree longitude
}
