import { PositionDetailEntryInfo } from 'common/Operations/ProcessTaskPositionCalculator';
import { ProcessTaskPositionDetailEntry } from './types';

export class ProcessTaskPositionDetailEntryUtils {
  public static getDetailEntryInfos(
    detailEntries: Array<ProcessTaskPositionDetailEntry>
  ): Array<PositionDetailEntryInfo<ProcessTaskPositionDetailEntry>> {
    return detailEntries.map((entry) => ({
      amount: entry.amount,
      originalDetailEntry: entry
    }));
  }
}
