import {
  GetProcessTaskToProjectRelationInfoRequest,
  ProcessTaskToProjectRelationInfo,
  SetProcessTaskToProjectRelationResponse
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

import { SubscriptionManager } from '../../../classes/SubscriptionManager';

export abstract class AbstractProcessTaskToProjectCrudStrategy {
  constructor(
    protected readonly processTaskToProjectType: ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE
  ) {}

  public abstract updateProcessTaskToProjectsAndCandidates(
    request: GetProcessTaskToProjectRelationInfoRequest,
    options: ProcessTaskToProjectUpdateOptions
  ): Promise<void>;

  public abstract createProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse>;

  public abstract deleteProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse>;

  public abstract setupSubscriptions(
    subscriptionManager: SubscriptionManager,
    updateCallback: () => Promise<void>
  ): void;
}

export type ProcessTaskToProjectUpdateOptions = {
  setRelationsToDisplay: (
    infos: Array<ProcessTaskToProjectRelationInfo>
  ) => void;
  setTotalRelationsCount: (count: number) => void;
  setOverlayStatus: (status: boolean) => void;
  config: UpdateSettings | null;
};

type UpdateSettings = {
  showOverlayImmediately: boolean;
  showOverlayForLongResponseTimes: boolean;
};
