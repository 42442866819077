import { autoinject } from 'aurelia-dependency-injection';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ExpressionEditorScopeEvaluationUtils } from 'common/ExpressionEditorScope/ExpressionEditorScopeEvaluationUtils';

import { OperationsExpressionEditorScope } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsExpressionEditorScope';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { OperationsDataFetcher } from '../../../Operations/OperationsDataFetcher';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTask } from '../ProcessTask/types';
import { ProcessTaskDevice } from '../ProcessTaskDevice/types';
import { ProcessTaskGroup } from '../ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../ProcessTaskPosition/types';
import { CurrentUserService } from '../User/CurrentUserService';
import { User } from '../User/types';
import { ProcessTaskAppointment } from './types';

@autoinject()
export class CreateProcessTaskAppointmentService {
  private readonly operationsExpressionEditor: OperationsExpressionEditorScope<
    string,
    string
  >;

  private readonly exprParser: ExprEvalParser;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.operationsExpressionEditor = new OperationsExpressionEditorScope(
      new OperationsDataFetcher(entityManager)
    );
    this.exprParser = new ExprEvalParser();
  }

  public async createAppointment(
    processTask: ProcessTask,
    positions: Array<ProcessTaskPosition>,
    devices: Array<ProcessTaskDevice>
  ): Promise<ProcessTaskAppointment> {
    const user = this.currentUserService.getRequiredCurrentUser();
    const appointment = await this.createAppointmentEntity(
      user,
      processTask,
      positions
    );

    this.createPositionRelations(appointment, positions);
    this.createDeviceRelations(appointment, devices);

    return appointment;
  }

  private async getAppointmentDefaultName(
    processTask: ProcessTask
  ): Promise<string | null> {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        processTask.ownerProcessTaskGroupId
      );
    assertNotNullOrUndefined(
      processTaskGroup,
      'cannot get appointment default name without processTaskGroup'
    );

    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processTaskGroup.processConfigurationId
      );
    const appointmentTitleConfig =
      processConfiguration?.configurableDisplayText
        ?.processTaskAppointmentDefaultName;
    if (appointmentTitleConfig) {
      return await this.evaluateConfig(
        appointmentTitleConfig,
        processTaskGroup,
        processTask
      );
    }
    return null;
  }

  private async createAppointmentEntity(
    user: User,
    processTask: ProcessTask,
    positions: Array<ProcessTaskPosition>
  ): Promise<ProcessTaskAppointment> {
    return this.entityManager.processTaskAppointmentRepository.create({
      coordinatorUserId: user.id,
      name: await this.getAppointmentDefaultName(processTask),
      processConfigurationStepId: this.getAppointmentStepId(positions),
      ownerProcessTaskId: processTask.id,
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerUserGroupId: processTask.ownerUserGroupId,
      temporaryGroupName: processTask.temporaryGroupName,
      shadowEntity: processTask.shadowEntity
    });
  }

  private createPositionRelations(
    appointment: ProcessTaskAppointment,
    positions: Array<ProcessTaskPosition>
  ): void {
    for (const position of positions) {
      this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.create(
        {
          processTaskPositionId: position.id,
          processTaskAppointmentId: appointment.id,
          ownerProcessTaskId: appointment.ownerProcessTaskId,
          ownerProcessTaskGroupId: appointment.ownerProcessTaskGroupId,
          ownerUserGroupId: appointment.ownerUserGroupId,
          temporaryGroupName: appointment.temporaryGroupName,
          shadowEntity: appointment.shadowEntity
        }
      );
    }
  }

  private createDeviceRelations(
    appointment: ProcessTaskAppointment,
    devices: Array<ProcessTaskDevice>
  ): void {
    for (const device of devices) {
      this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.create(
        {
          processTaskAppointmentId: appointment.id,
          processTaskDeviceId: device.id,
          ownerProcessTaskGroupId: appointment.ownerProcessTaskGroupId,
          ownerProcessTaskId: appointment.ownerProcessTaskId,
          ownerUserGroupId: appointment.ownerUserGroupId
        }
      );
    }
  }

  private getAppointmentStepId(
    positions: Array<ProcessTaskPosition>
  ): string | null {
    const firstPosition = positions[0];
    const categoryId = firstPosition?.processConfigurationCategoryId ?? null;
    const category = categoryId
      ? this.entityManager.processConfigurationCategoryRepository.getById(
          categoryId
        )
      : null;
    return category?.appointmentProcessConfigurationStepId ?? null;
  }

  private async evaluateConfig(
    expr: string,
    processTaskGroup: ProcessTaskGroup,
    processTask: ProcessTask
  ): Promise<string> {
    const fieldConfigs =
      await this.operationsExpressionEditor.createFieldConfigsForProcessTaskScope(
        {
          currentProcessTaskGroup: {
            id: processTaskGroup.id
          },
          currentThingGroup: {
            id: processTaskGroup.thingGroupId
          },
          currentProcessTask: {
            id: processTask.id
          }
        }
      );
    const dataToSet =
      await ExpressionEditorScopeEvaluationUtils.convertToPlainObject(
        fieldConfigs
      );
    return this.exprParser.evaluateExpression(expr, dataToSet).toString();
  }
}
