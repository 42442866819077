import { Vector } from './Vector';
import { AngleHelper } from './AngleHelper';

export class CoordinateHelper {
  private static earthRadius = 6371000;
  /**
   * calculate the distance between two coordinates
   */
  public static calculateDistance(
    lon1: number,
    lat1: number,
    lon2: number,
    lat2: number
  ): number {
    const dLat = AngleHelper.degToRad(lat2 - lat1);
    const dLon = AngleHelper.degToRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(AngleHelper.degToRad(lat1)) *
        Math.cos(AngleHelper.degToRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return this.earthRadius * c; // distance in m
  }

  /**
   * returns an approx vector of 'x' distance and 'y' distance
   * x and y are meters
   */
  public static createDeltaVector(
    lat1: number,
    long1: number,
    lat2: number,
    long2: number
  ): Vector {
    const avgLat = (lat1 + lat2) / 2;
    const xSign = long1 - long2 >= 0 ? 1 : -1;
    const x = this.calculateDistance(avgLat, long1, avgLat, long2) * xSign;

    const avgLong = (long1 + long2) / 2;
    const ySign = lat1 - lat2 >= 0 ? 1 : -1;
    const y = this.calculateDistance(lat1, avgLong, lat2, avgLong) * ySign;
    return new Vector(x, y);
  }

  /**
   * for documentation look at 'createDeltaVector'
   */
  public static createDeltaVectorForLatitudeLongitude(
    latitudeLongitude1: { latitude: number; longitude: number },
    latitudeLongitude2: { latitude: number; longitude: number }
  ): Vector {
    return this.createDeltaVector(
      latitudeLongitude1.latitude,
      latitudeLongitude1.longitude,
      latitudeLongitude2.latitude,
      latitudeLongitude2.longitude
    );
  }
}
