import { EventDispatcher } from '../classes/EventDispatcher/EventDispatcher';
import { Vector } from '../../../common/src/Geometry/Vector';

export class DraggingService {
  private eventDispatcher: EventDispatcher<EventConfig> = new EventDispatcher();

  private dragInProgressInfo: DragInProgressInfo | null = null;

  public registerEventHandler<T extends keyof EventConfig>(
    context: any,
    eventName: T,
    callback: (payload: EventConfig[T]) => void
  ): void {
    this.eventDispatcher.addEventListener(context, eventName, callback);
  }

  public unregisterEventHandlerByContext(context: any): void {
    this.eventDispatcher.removeEventListenersByContext(context);
  }

  public startDragging(info: SetActiveDraggableInfo): {
    finishedDragging: () => void;
  } {
    const dragInProgressInfo = (this.dragInProgressInfo = {
      elementSize: info.elementSize,
      elementMargin: info.elementMargin,
      dragData: info.dragData
    });
    this.eventDispatcher.dispatchEvent(
      'draggingStarted',
      this.dragInProgressInfo
    );
    this.setUserSelectState(false);

    return {
      finishedDragging: () => {
        // a new one could have started in the meantime
        if (dragInProgressInfo === this.dragInProgressInfo) {
          try {
            this.eventDispatcher.dispatchEvent('droppedElement', null);
          } finally {
            this.stopDragging();
          }
        }
      }
    };
  }

  public stopDragging(): void {
    this.dragInProgressInfo = null;
    this.eventDispatcher.dispatchEvent('draggingEnded', null);
    this.setUserSelectState(true);
  }

  public getDragInProgressInfo(): DragInProgressInfo | null {
    return this.dragInProgressInfo;
  }

  private setUserSelectState(selectable: boolean): void {
    const style = document.body.style;
    const value = selectable ? '' : 'none';

    style.userSelect = value;
    style.webkitUserSelect = value;
    // @ts-ignore
    style.msUserSelect = value;
  }
}

type EventConfig = {
  draggingStarted: DragInProgressInfo;
  droppedElement: null;
  draggingEnded: null;
};

export type DragInProgressInfo = {
  elementSize: Vector;
  elementMargin: Margin;
  dragData: unknown;
};

export type Margin = {
  top: string;
  bottom: string;
};

export type SetActiveDraggableInfo = {
  dragData: unknown;
  elementSize: Vector;
  elementMargin: Margin;
};
