import { bindable, autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';
import { UiUpdater } from '../../classes/UiUpdater';
import {
  FilterSettings,
  ProcessTaskGroupFilterSettingsService
} from '../../services/ProcessTaskGroupFilterSettingsService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepBarDimension } from '../process-configuration-step-bar/ProcessConfigurationStepBarDimensionCalculator';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroupsTableViewProcessTaskGroup } from './process-task-groups-table-view-process-task-group';

/**
 * TODO: make this support multiple processConfigurations in the processTaskGroups (currently only the configuration of the first processTaskGroup will be used)
 */
@autoinject()
export class ProcessTaskGroupsTableView {
  @bindable()
  public processTaskGroups: Array<ProcessTaskGroup> = [];

  @bindable()
  public processConfigurationId: string | null = null;

  private readonly domElement: HTMLElement;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly handleResizeRateLimited: IUtilsRateLimitedFunction;

  private searchText: string | null = null;
  private selectedStepIds: Array<string> = [];
  private excludedStepIds: Array<string> = [];
  private isAttached: boolean = false;

  protected processConfigurationSteps: Array<ProcessConfigurationStep> = [];
  protected stepBarDimension: ProcessConfigurationStepBarDimension | null =
    null;
  protected stepBarLeftOffset: number | null = null;
  protected stepBarElement: HTMLElement | null = null;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskGroupFilterSettingsService: ProcessTaskGroupFilterSettingsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.handleResizeRateLimited = Utils.rateLimitFunction(
      this.handleResize.bind(this),
      100
    );
  }

  public scrollToProcessTask({
    processTask,
    processTaskGroup
  }: {
    processTask: ProcessTask;
    processTaskGroup: ProcessTaskGroup;
  }): void {
    const processTaskGroupElement = this.domElement.querySelector(
      `#${this.getProcessTaskGroupElementId(processTaskGroup.id)}`
    );
    assertNotNullOrUndefined(
      processTaskGroupElement,
      `no processTaskGroupElement found for id "${processTaskGroup.id}"`
    );

    const viewModel =
      Utils.getRequiredViewModelOfElement<ProcessTaskGroupsTableViewProcessTaskGroup>(
        processTaskGroupElement
      );
    viewModel.scrollToProcessTask({ processTask });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.updateProcessConfigurationSteps.bind(this)
    );
    this.updateProcessConfigurationSteps();

    this.processTaskGroupFilterSettingsService.subscribeToChange(
      this,
      this.filterSettingsUpdated.bind(this)
    );

    UiUpdater.registerResizeUpdateFunction(this.handleResizeRateLimited);
    this.handleResize();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    this.processTaskGroupFilterSettingsService.unsubscribe(this);
    this.handleResizeRateLimited.cancel();

    UiUpdater.unregisterResizeUpdateFunction(this.handleResizeRateLimited);
  }

  protected processConfigurationIdChanged(): void {
    if (this.isAttached) {
      this.updateProcessConfigurationSteps();
    }
  }

  protected handleSearchTextChanged(): void {
    this.processTaskGroupFilterSettingsService.modifyFilterSettings({
      searchText: this.searchText
    });
  }

  private handleResize(): void {
    if (this.domElement && this.stepBarElement) {
      const offset = Utils.getRelativeElementOffset(
        this.stepBarElement,
        this.domElement
      );
      this.stepBarLeftOffset = offset.left;
    }
  }

  protected handleStepIdsChanged(): void {
    this.processTaskGroupFilterSettingsService.modifyFilterSettings({
      selectedProcessConfigurationStepIds: this.selectedStepIds,
      excludedProcessConfigurationStepIds: this.excludedStepIds
    });
  }

  private updateProcessConfigurationSteps(): void {
    if (this.processConfigurationId) {
      this.processConfigurationSteps =
        this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this.processConfigurationSteps = [];
    }
  }

  private filterSettingsUpdated(filterSettings: FilterSettings): void {
    this.searchText = filterSettings.searchText;
    this.selectedStepIds = filterSettings.selectedProcessConfigurationStepIds;
    this.excludedStepIds = filterSettings.excludedProcessConfigurationStepIds;
  }

  private getProcessTaskGroupElementId(processTaskGroupId: string): string {
    return (
      'process-task-groups-table-view--ProcessTaskGroup-' + processTaskGroupId
    );
  }
}
