import { DateType, IdType } from '../Types/Entities/Base/types';
import { PersonDto } from '../Types/Entities/Person/PersonDto';
import { PropertyType } from '../Types/Entities/Property/PropertyDto';
import {
  CommonGalleryThingPictureOverviewEntry,
  PictureIconInfo
} from '../Types/GalleryThingPictureFilter/GalleryThingPictureOverviewEntry';
import { ValueContainer } from '../ValueContainer';

export class GalleryThingIconHelper {
  public static generatePictureIcons(
    data: GetIconGeneratorData,
    overviewEntry: CommonGalleryThingPictureOverviewEntry<unknown>,
    pictureIconsConfigurations: Array<PictureIconConfig>
  ): Array<PictureIconInfo> {
    const pictureIconInfos: Array<PictureIconInfo> = [];

    for (const iconConfig of pictureIconsConfigurations) {
      switch (iconConfig.type) {
        case PictureIconConfigType.PROPERTY:
          this.addPropertyPictureIconInfos(
            data,
            overviewEntry,
            iconConfig,
            pictureIconInfos
          );
          break;

        case PictureIconConfigType.PERSON:
          this.addPersonPictureIconInfos(
            data,
            overviewEntry,
            iconConfig,
            pictureIconInfos
          );
          break;

        default:
      }
    }
    return pictureIconInfos;
  }

  private static addPropertyPictureIconInfos<BaseMapMarkerType>(
    data: GetIconGeneratorData,
    overviewEntry: CommonGalleryThingPictureOverviewEntry<BaseMapMarkerType>,
    iconConfig: PicturePropertyIconConfig,
    pictureIconInfos: Array<PictureIconInfo>
  ): void {
    if (
      this.entryHasConfiguredProperty(
        data,
        overviewEntry,
        iconConfig.propertySelector
      )
    ) {
      pictureIconInfos.push(iconConfig.iconInfo);
    }
  }

  private static addPersonPictureIconInfos<BaseMapMarkerType>(
    data: GetIconGeneratorData,
    overviewEntry: CommonGalleryThingPictureOverviewEntry<BaseMapMarkerType>,
    iconConfig: PicturePersonIconConfig,
    pictureIconInfos: Array<PictureIconInfo>
  ): void {
    if (!overviewEntry.entryId) return;

    data.getPersonsByEntryId(overviewEntry.entryId).forEach((person) => {
      pictureIconInfos.push({
        color: iconConfig.iconInfo.color,
        iconType: 'text',
        iconName: this.getIconForPerson(person)
      });
    });
  }

  private static getIconForPerson(person: CommonPerson): string {
    if (person.company && person.companyName) {
      return person.companyName.slice(0, 2);
    } else {
      return [
        person.firstName && person.firstName[0],
        person.lastName && person.lastName[0]
      ].join('');
    }
  }

  private static entryHasConfiguredProperty<BaseMapMarkerType>(
    data: GetIconGeneratorData,
    overviewEntry: CommonGalleryThingPictureOverviewEntry<BaseMapMarkerType>,
    propertyIconConfig: PicturePropertyIconPropertySelector
  ): boolean {
    if (!overviewEntry.entryId) return false;
    const entry = data.getEntryPropertyValue({
      entryId: overviewEntry.entryId,
      propertyName: propertyIconConfig.name,
      propertyType: propertyIconConfig.type
    });
    return entry !== null && entry.value === propertyIconConfig.value;
  }
}

enum PictureIconConfigType {
  PROPERTY = 'property',
  PERSON = 'person'
}

type CommonPictureIconConfig = {
  iconInfo: PictureIconInfo;
};

export type PictureIconConfig =
  | PicturePropertyIconConfig
  | PicturePersonIconConfig;

type PicturePropertyIconConfig = CommonPictureIconConfig & {
  type: PictureIconConfigType.PROPERTY;
  propertySelector: PicturePropertyIconPropertySelector;
};

type PicturePropertyIconPropertySelector = {
  name: string;
  value: string;
  type: PropertyType;
};

type PicturePersonIconConfig = CommonPictureIconConfig & {
  type: PictureIconConfigType.PERSON;
};

type GetIconGeneratorData = {
  getEntryPropertyValue({
    entryId,
    propertyType,
    propertyName
  }: {
    entryId: string;
    propertyType: PropertyType | null;
    propertyName: string | null;
  }): ValueContainer<null | string> | null;
  getPersonsByEntryId(entryId: string): Array<CommonPerson>;
};

type CommonPerson = PersonDto<IdType, DateType>;
