import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { autoinject } from 'aurelia-framework';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import {
  activeUserCompanySetting,
  Dependency,
  model
} from '../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { ActiveUserCompanySettingService } from '../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { KukReminderSettings } from 'common/Types/Entities/UserCompanySetting/UserCompanySettingDto';

/**
 * For Retrieval of KuK reminders, i.e. different text to be displayed
 * in the catastrophe overview depending on the types of defects within it.
 *
 * @see {KukReminderSettings}
 */
@autoinject()
export class KukRemindersService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  /**
   * TODO: This works, but isn't as good looking as a method decorator,
   * especially since it isn't as tightly coupled to the method.
   *
   * Maybe in the future, there can be a dependency `dependenciesOf()` which
   * may be used like so:
   *
   * ```ts
   * @computed(expression('thing'), ...dependenciesOf(this.kukRemindersService.getByThing))
   * protected get reminders(): Array<string> { ... }
   * ```
   *
   * Let's file this away under tech debt for now.
   */
  public static getByThingDependencies: Array<Dependency> = [
    model(EntityName.Defect),
    activeUserCompanySetting('kuk.reminders'),
    model(EntityName.Property)
  ];

  /**
   * Returns the reminders to be displayed for a given thing id.
   */
  public getByThingId(thingId: string): Array<string> {
    const settings = this.activeUserCompanySettingService.getSettingProperty(
      'kuk.reminders'
    ) as KukReminderSettings | null;
    if (!settings) return [];

    const defects =
      this.entityManager.defectRepository.getByOwnerThingId(thingId);
    const properties = this.entityManager.propertyRepository.getByDefectIds(
      defects.map((d) => d.id)
    );

    const reminders: Array<string> = [];
    for (const reminderSetting of settings) {
      // If `propertyName` is null, this reminder is always displayed
      // otherwise this reminder is displayed if at least one property has the provided value.
      if (
        reminderSetting.propertyName === null ||
        properties
          .filter((p) => p.name === reminderSetting.propertyName)
          .some((p) => p.value === reminderSetting.propertyValue)
      ) {
        reminders.push(reminderSetting.text);
      }
    }
    return reminders;
  }
}
