import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';

import { ProcessTaskGroupAssigneeService } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupAssigneeService';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class AssignProcessTaskGroupUserDialog {
  public static async open(options: TOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  private processTaskGroup: ProcessTaskGroup | null = null;
  private processTaskId: string | null = null;
  private assigneeUserId: string | null = null;
  private assigneeHint: string | null = null;
  protected assigneeUserIdLocked: boolean = false;
  private processTaskNameToLoadAsId: string | null = null;

  private processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  private dialog: RecordItDialog | null = null;

  constructor(
    private readonly processTaskGroupAssigneeService: ProcessTaskGroupAssigneeService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  private open(options: TOpenOptions): void {
    this.processTaskGroup = options.processTaskGroup;

    if (options.defaultHint) {
      this.assigneeHint = options.defaultHint;
    }

    if (options.lockedUserId) {
      this.assigneeUserId = options.lockedUserId;
      this.assigneeUserIdLocked = true;
    }

    this.setupProcessTaskId(options.processTaskInfo);

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
          this.tryLoadingProcessTaskByName();
        }
      })
    );

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    this.processTaskGroup = null;
    this.assigneeUserId = null;
    this.assigneeHint = null;
    this.assigneeUserIdLocked = false;
    this.processTaskNameToLoadAsId = null;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleAcceptButtonClicked(): void {
    if (this.processTaskGroup) {
      const processTaskName = this.processTaskId
        ? this.processTaskNamesByProcessTaskId.get(this.processTaskId)
            ?.nameWithThingAndPerson
        : null;

      this.processTaskGroupAssigneeService.setAssignee({
        processTaskGroup: this.processTaskGroup,
        assigneeData: this.assigneeUserId
          ? {
              userId: this.assigneeUserId,
              hint: this.assigneeHint,
              assignedProcessTaskName: processTaskName || null
            }
          : null
      });
    }
  }

  protected handleProcessTaskIdChanged(): void {
    // user changed the processTaskId manually, stop loading to prevent overwriting the value of the user
    this.processTaskNameToLoadAsId = null;
  }

  private setupProcessTaskId(
    processTaskInfo: ProcessTaskInfo | null | undefined
  ): void {
    if (!processTaskInfo) {
      this.processTaskId = null;
      return;
    }

    if ('processTask' in processTaskInfo) {
      this.processTaskId = processTaskInfo.processTask.id;
    } else {
      this.processTaskId = null;
      this.processTaskNameToLoadAsId = processTaskInfo.processTaskName;
    }
  }

  private tryLoadingProcessTaskByName(): void {
    if (!this.processTaskNameToLoadAsId) {
      return;
    }

    for (const [
      processTaskId,
      processTaskNames
    ] of this.processTaskNamesByProcessTaskId.entries()) {
      if (
        processTaskNames.nameWithThingAndPerson ===
        this.processTaskNameToLoadAsId
      ) {
        this.processTaskId = processTaskId;
        this.processTaskNameToLoadAsId = null;
        break;
      }
    }
  }
}

export type TOpenOptions = {
  processTaskGroup: ProcessTaskGroup;

  processTaskInfo?: ProcessTaskInfo | null;

  /**
   * pre fill the hint text
   */
  defaultHint?: string;

  /**
   * only allow assignment to this user id
   */
  lockedUserId?: string;
};

type ProcessTaskInfo =
  | { processTask: ProcessTask }
  | { processTaskName: string };
