import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { Picture } from './EntityManager/entities/Picture/types';
import { GalleryThingPictureCreatorUtils } from './GalleryThing/GalleryThingPictureCreatorUtils';
import { TestingHelper } from './TestingHelper';

export class ClientDataPatcher {
  constructor(private readonly entityManager: AppEntityManager) {}

  public patchGalleryThingPictureWithCoordsFromPositionedPictureInfo(
    picture: Picture,
    dryRun = true
  ): void {
    if (!!picture.coordsFromPositionedPictureInfo) {
      this.log(
        `Picture ${picture.id} not patched because coordsFromPositionedPictureInfo is already set.`
      );
      return;
    }
    if (!picture.coords) {
      this.log(`Picture ${picture.id} not patched because it has no coords.`);
      return;
    }
    if (!picture.ownerThingId) {
      this.log(
        `Trying to patch picture ${picture.id} which has no ownerThingId`
      );
      return;
    }

    picture.coordsFromPositionedPictureInfo =
      GalleryThingPictureCreatorUtils.getCoordsFromPositionedPictureInfo(
        this.entityManager,
        picture.coords,
        picture.ownerThingId
      );
    this.log(
      `Successfully patched picture (dryRun: ${dryRun}) ${picture.id}.
      New coordsFromPositionPictureInfo:
      ${JSON.stringify(picture.coordsFromPositionedPictureInfo)}`
    );
    if (!dryRun) {
      this.entityManager.pictureRepository.update(picture);
    }
  }

  private log(msg: string): void {
    console.log('CLIENT DATA PATCHER: ' + msg);
  }
}

TestingHelper.clientDataPatcher = new ClientDataPatcher(
  TestingHelper.entityManager
);
