import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { PictureProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { SortedProcessTaskAppointmentsForProcessTaskIdComputer } from '../../computedValues/computers/SortedProcessTaskAppointmentsForProcessTaskIdComputer/SortedProcessTaskAppointmentsForProcessTaskIdComputer';

@autoinject()
export class ProcessTaskProjectsWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public enablePictureCreation: boolean = false;

  /**
   * null if pictures aren't loaded
   * read only
   */
  @bindable()
  public processTaskPictureCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  private processTaskToProjects: Array<PictureProcessTaskToProject> = [];
  protected sortedAppointments: Array<ProcessTaskAppointment> = [];

  protected ProcessTaskToProjectType = ProcessTaskToProjectType;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          SortedProcessTaskAppointmentsForProcessTaskIdComputer,
        createComputeData: () =>
          this.processTask ? { processTaskId: this.processTask.id } : null,
        createUpdaters: (update) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            update
          );
        },
        callback: (sortedAppointments) => {
          this.sortedAppointments = sortedAppointments;
        }
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateProcessTaskToProjects.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictureCount.bind(this)
    );
    this.updateProcessTaskToProjects();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskToProjects();
    }
  }

  private updateProcessTaskToProjects(): void {
    if (this.processTask) {
      this.processTaskToProjects =
        this.entityManager.processTaskToProjectRepository.getPictureProcessTaskToProjectsByProcessTaskId(
          this.processTask.id
        );
    } else {
      this.processTaskToProjects = [];
    }

    this.updatePictureCount();
  }

  private updatePictureCount(): void {
    if (!this.processTask) {
      this.processTaskPictureCount = null;
      return;
    }

    let pictures: Array<Picture> = [];

    for (const ptp of this.processTaskToProjects) {
      pictures = pictures.concat(
        this.entityManager.pictureRepository.getAllPicturesByProjectId(
          ptp.projectId
        )
      );
    }

    this.processTaskPictureCount = pictures.length;
  }
}
