import { computedFrom } from 'aurelia-framework';
import { ProcessConfigurationDevice } from '../../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { ProcessConfigurationDeviceAdapter } from '../EntityAdapter/ProcessConfigurationDeviceAdapter/ProcessConfigurationDeviceAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessConfigurationDevicePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessConfigurationDeviceAdapter>,
  ProcessConfigurationDevice
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditField');
  }
}
