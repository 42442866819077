import { Disposable } from 'aurelia-binding';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ValueCalculationResultAdapter,
  ValueCalculationResultAdapterOptions,
  ValueCalculationResultAdapterSubscribeOptions
} from './ValueCalculationResultAdapter';

export class ProjectValueCalculationResultAdapter
  implements ValueCalculationResultAdapter<Project>
{
  private entityManager: AppEntityManager;
  private subscriptionManagerService: SubscriptionManagerService;

  private project: Project;

  constructor(options: ValueCalculationResultAdapterOptions<Project>) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.project = options.baseEntity;
  }

  public subscribe(
    options: ValueCalculationResultAdapterSubscribeOptions<Project>
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateResults = (): void => {
      const results =
        this.entityManager.valueCalculationResultRepository.getByProjectId(
          this.project.id
        );
      options.setResults(results);
    };
    updateResults();

    const updateConfigs = (): void => {
      const configs =
        this.project.report_type && this.project.thing
          ? this.entityManager.valueCalculationConfigRepository.getByThingAndReportTypeId(
              this.project.thing,
              this.project.report_type
            )
          : [];
      options.setConfigs(configs);
    };
    updateConfigs();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ValueCalculationResult,
      updateResults
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ValueCalculationConfig,
      updateConfigs
    );
    return subscriptionManager.toDisposable();
  }
}
