import { computedFrom } from 'aurelia-framework';

import { UserDefinedEntityConfigPropertyConfigHelper } from 'common/EntityHelper/UserDefinedEntityConfigPropertyConfigHelper';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntity } from '../../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityProperty } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  CreationConfig,
  PropertyAdapter,
  SubscribeOptions
} from './PropertyAdapter';
import { UserDefinedEntityConfigPropertyConfig } from '../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';

export class UserDefinedEntityPropertyAdapter
  implements
    PropertyAdapter<
      UserDefinedEntityProperty,
      UserDefinedEntityConfigPropertyConfig
    >
{
  private readonly userDefinedEntity: UserDefinedEntity;
  private readonly entityManager: AppEntityManager;
  private readonly permissionsService: PermissionsService;
  private readonly subscriptionManagerService: SubscriptionManagerService;

  constructor(options: {
    userDefinedEntity: UserDefinedEntity;
    subscriptionManagerService: SubscriptionManagerService;
    permissionsService: PermissionsService;
    entityManager: AppEntityManager;
  }) {
    this.userDefinedEntity = options.userDefinedEntity;
    this.entityManager = options.entityManager;
    this.permissionsService = options.permissionsService;
    this.subscriptionManagerService = options.subscriptionManagerService;
  }

  public subscribe(
    options: SubscribeOptions<
      UserDefinedEntityProperty,
      UserDefinedEntityConfigPropertyConfig
    >
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(
        this.entityManager.propertyRepository.getByUserDefinedEntityId(
          this.userDefinedEntity.id
        )
      );
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );
    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.UserDefinedEntityConfig,
      updateConfigs.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.UserDefinedEntity,
      updateConfigs.bind(this)
    );
    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.UserDefinedEntity,
      entity: this.userDefinedEntity,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public createPropertyFromConfig(
    config: CreationConfig<UserDefinedEntityConfigPropertyConfig>
  ): UserDefinedEntityProperty {
    return this.entityManager.propertyRepository.create({
      ...UserDefinedEntityConfigPropertyConfigHelper.reduceToPropertyConfig(
        config
      ),
      ownerUserGroupId: this.userDefinedEntity.ownerUserGroupId,
      userDefinedEntityId: this.userDefinedEntity.id,
      ownerProjectId: this.userDefinedEntity.ownerProjectId ?? null
    }) as UserDefinedEntityProperty;
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }

  private getConfigs(): Array<UserDefinedEntityConfigPropertyConfig> {
    return this.userDefinedEntity.userDefinedEntityConfigId
      ? this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
          this.userDefinedEntity.userDefinedEntityConfigId
        )
      : [];
  }
}
