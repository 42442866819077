import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { MoreButtonChoice } from '../more-button/more-button';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { Pagination } from '../pagination/pagination';

/**
 * @template {any} T
 *
 * @replaceable item-template
 * available variables in item-template
 * | type | name          | description                        |
 * |------|---------------|------------------------------------|
 * | T    | item          | the item to display                |
 * | U    | itemViewModel | view model of the items to display |
 *
 * @replaceable edit-overlay
 * @replaceable no-item-template
 * there are no available variables in no-item-template
 */
@autoinject()
export class SelectableItemList<T extends Object, U> {
  @bindable public items: Array<T> = [];

  @bindable public selectedItems: Array<T>;

  @bindable public moreButtonChoices: Array<MoreButtonChoice> = [];

  @bindable public enablePagination: boolean = true;

  @bindable public enableSelectAll: boolean = true;

  @bindable public showButtonAboveHeader: boolean = false;

  @bindable public draggableViewModel: (new () => U) | null = null;
  @bindable public dropZoneActivateCallback:
    | ((params: { viewModel: U; item: T }) => boolean)
    | null = null;

  @bindable public onDropCallback:
    | ((params: { viewModel: U; item: T }) => void)
    | null = null;

  private listWrapperElement: HTMLElement | null = null;

  private selectionEnabled = false;

  private domElement: HTMLElement;
  protected pagination: Pagination<T> | null = null;

  private currentPageItems: Array<T> = [];

  private isAttached = false;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;

    this.selectedItems = [];
  }

  public resetSelectionEnabled(): void {
    this.selectionEnabled = false;
    this.selectedItems = [];
  }

  protected attached(): void {
    this.isAttached = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  public async goToItem(querySelector: string, item: T): Promise<void> {
    await ScrollHelper.autoScrollToListItem(
      querySelector,
      this.pagination,
      item,
      () => this.isAttached
    );
  }

  private itemIsSelected(
    item: T,
    selectedItems: Array<T>,
    _selectedItemsLength: number
  ): boolean {
    return !!selectedItems.find((i) => i === item);
  }

  private allItemsSelected(
    items: Array<T>,
    selectedItems: Array<T>,
    _itemsLength: number,
    _selectedItemsLength: number
  ): boolean {
    return items.every((item) => selectedItems.indexOf(item) >= 0);
  }

  private toggleSelectionEnabled(): void {
    this.setSelectionEnabled(!this.selectionEnabled);
  }

  private setSelectionEnabled(enabled: boolean): void {
    this.selectionEnabled = enabled;

    if (!this.selectionEnabled) {
      this.selectedItems = [];
    }
  }

  private handleSelectionChanged(item: T): void {
    const index = this.selectedItems.findIndex((i) => i === item);
    if (index < 0) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems.splice(index, 1);
    }
  }

  private handleSelectAllChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    const checked = event.detail.checked;

    this.items.forEach((item) => {
      const index = this.selectedItems.indexOf(item);
      if (index < 0 && checked) {
        this.selectedItems.push(item);
      } else if (index >= 0 && !checked) {
        this.selectedItems.splice(index, 1);
      }
    });
  }

  private handleChoiceSelected(name: string): void {
    DomEventHelper.fireEvent<ActionClickedEvent<T>>(this.domElement, {
      name: `${name}-clicked`,
      detail: {
        selectedItems: this.selectedItems
      }
    });
    this.setSelectionEnabled(false);
  }

  public getListWrapperElement(): HTMLElement | null {
    return this.listWrapperElement;
  }

  private handleActivateDropTarget(viewModel: U, item: T): boolean {
    return (
      this.dropZoneActivateCallback?.({
        viewModel: viewModel,
        item: item
      }) ?? true
    );
  }

  private handleDropItem(viewModel: U, itemToReplace: T): void {
    this.onDropCallback?.({
      viewModel: viewModel,
      item: itemToReplace
    });
  }

  @computedFrom('enablePagination', 'currentPageItems', 'items')
  private get displayedItems(): Array<T> {
    return this.enablePagination ? this.currentPageItems : this.items;
  }
}

export type ActionClickedEvent<T> = CustomEvent<{ selectedItems: Array<T> }>;
