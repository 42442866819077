/**
 * @type {{GENERAL: 0, SIGNATURE: 1}}
 */
export const GeneralFileTypes = {
  GENERAL: 0,
  SIGNATURE: 1
};

/**
 * @typedef {0|1} TGeneralFileType
 */
