import { inject, bindable } from 'aurelia-framework';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';

/**
 * the content of the more panel normally should be some "buttons" to click on, but can be any content if you set the data-no-default-content-styling attribute
 *
 * Example for default content:
 *
 *  <entity-list-item-more-panel>
 *    <div>
 *      <div>
 *        <custom-icon class="record-it-icon" icon-type="fal" icon-name="fa-trash"></custom-icon>
 *        <span t="exampleText1"></span>
 *      </div>
 *    </div>
 *    <div>
 *      <div>
 *        <custom-icon class="record-it-icon" icon-type="fal" icon-name="fa-plus"></custom-icon>
 *        <span t="exampleText2"></span>
 *      </div>
 *    </div>
 *  </entity-list-item-more-panel>
 *
 * @slot default
 *
 * @attribute data-no-default-content-styling - disable the default flex layout in the default content slot
 */
@inject(Element)
export class EntityListItemMorePanel {
  @bindable isOpen = false;

  /** @type {HTMLElement} */
  _contentElement;

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._contentElement = element;
  }

  bind() {
    if (!this._animator) {
      this._animator = new ShowHideAnimator(this._contentElement);
    }
    this._contentElement.style.display = 'none';
  }

  isOpenChanged() {
    if (this.isOpen) {
      this._open();
    } else {
      this._close();
    }
  }

  _open() {
    if (this._animator) this._animator.slideDown();
  }

  _close() {
    if (this._animator) this._animator.slideUp();
  }
}
