import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { PictureRevision } from '../../../../classes/EntityManager/entities/PictureRevision/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';
import { pictureRevisionEntityInfo } from '../../../../classes/EntityManager/entities/PictureRevision/pictureRevisionEntityInfo';

export class PictureRevisionAdapter implements EntityAdapter<PictureRevision> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> = new Set();

  constructor(options: PictureRevisionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(pictureRevision: PictureRevision): boolean {
    return this.entityIsEditableUserGroup(pictureRevision);
  }

  public canEditField(pictureRevision: PictureRevision): boolean {
    return this.entityIsEditableUserGroup(pictureRevision);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.PictureRevision,
    PictureRevision
  > {
    return pictureRevisionEntityInfo;
  }

  private entityIsEditableUserGroup(entity: PictureRevision): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type PictureRevisionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
