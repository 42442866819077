import { autoinject } from 'aurelia-framework';

import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ManagePropertyDefinitionsWidgetThingAdapter } from '../../propertyComponents/manage-property-definitions-widget/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetThingAdapter/ManagePropertyDefinitionsWidgetThingAdapter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { RxjsService } from '../../services/RxjsService/RxjsService';

@autoinject()
export class EditThingPropertiesDialog {
  protected thing: Thing | null = null;

  private dialog: RecordItDialog | null = null;

  private subscriptionManager: SubscriptionManager;

  protected managePropertyDefinitionsWidgetAdapter: ManagePropertyDefinitionsWidgetThingAdapter | null =
    null;

  protected PropertyType = PropertyType;

  public static async open(
    options: EditThingPropertiesDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager,
    private readonly rxjsService: RxjsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: EditThingPropertiesDialogOptions): void {
    this.thing = options.thing;

    this.managePropertyDefinitionsWidgetAdapter =
      new ManagePropertyDefinitionsWidgetThingAdapter({
        entityManager: this.entityManager,
        rxjsService: this.rxjsService,
        permissionsService: this.permissionsService,
        subscriptionManagerService: this.subscriptionManagerService,
        thing: options.thing
      });

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}

type EditThingPropertiesDialogOptions = {
  thing: Thing;
};
