import {
  GalleryThingPictureFilter,
  GalleryThingPictureFilterTagMatchMode
} from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  DefectPicture,
  EntryPicture
} from '../../classes/EntityManager/entities/Picture/types';
import { PropertyBinder } from '../../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  PropertyCache,
  PropertyCacheComputer
} from '../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  filterTypeToFilterConfiguration,
  GalleryThingFilterHelper
} from '../../classes/GalleryThing/GalleryThingFilterHelper';
import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';

export class GalleryThingPictureFilterHandle {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly propertyBinder: PropertyBinder<PropertyBinderConfig>;
  private isSubscribed: boolean = false;
  private propertyCache: PropertyCache | null = null;
  private filter: GalleryThingPictureFilter | null = null;
  private pictures: Array<SupportedPicture> = [];
  private pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> = [];

  constructor(options: GalleryThingPictureFilterHandleOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;

    this.propertyBinder = new PropertyBinder<PropertyBinderConfig>({
      defaultValuesByName: {
        filteredPictureOverviewEntries: []
      }
    });
  }

  public setFilter(filter: GalleryThingPictureFilter | null): void {
    this.filter = filter;

    if (this.isSubscribed) {
      this.updateFilteredPictureOverviewEntries();
    }
  }

  public setPicturesAndOverviewEntries(
    pictures: Array<SupportedPicture>,
    overviewEntries: Array<GalleryThingPictureOverviewEntry>
  ): void {
    this.pictures = pictures;
    this.pictureOverviewEntries = overviewEntries;

    if (this.isSubscribed) {
      this.updateFilteredPictureOverviewEntries();
    }
  }

  public subscribe(): Disposable {
    this.isSubscribed = true;

    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateFilteredPictureOverviewEntries.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateFilteredPictureOverviewEntries.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(EntityName.Project, () => {
      this.updateFilteredPictureOverviewEntries();
    });

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: PropertyCacheComputer,
        computeData: {},
        callback: (propertyCache) => {
          this.propertyCache = propertyCache;
          this.updateFilteredPictureOverviewEntries();
        }
      })
    );

    return {
      dispose: () => {
        this.isSubscribed = false;
        this.propertyCache = null;
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public bindFilteredPictureOverviewEntries(
    callback: (
      filteredPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry>
    ) => void
  ): Disposable {
    return this.propertyBinder.registerBinding(
      'filteredPictureOverviewEntries',
      callback
    );
  }

  private updateFilteredPictureOverviewEntries(): void {
    const propertyCache = this.propertyCache;
    if (!propertyCache) {
      return;
    }

    const filteredPictures = this.pictures.filter((picture) => {
      return this.filterPicture(picture, propertyCache);
    });

    const filteredPictureOverviewEntries = this.pictureOverviewEntries.filter(
      (e) => filteredPictures.find((p) => p.id === e.pictureId)
    );

    this.propertyBinder.setValue(
      'filteredPictureOverviewEntries',
      filteredPictureOverviewEntries
    );
  }

  private filterPicture(
    picture: SupportedPicture,
    propertyCache: PropertyCache
  ): boolean {
    let filtered = true;

    filtered = filtered && this.filterPictureByMarkingsOnPictureId(picture);
    filtered = filtered && this.filterPictureByProjectIds(picture);
    filtered = filtered && this.filterPictureByTimestamp(picture);
    filtered = filtered && this.filterPictureByLatLongArea(picture);
    filtered = filtered && this.filterPictureByTags(picture);
    filtered = filtered && this.filterPictureByDescription(picture);
    filtered = filtered && this.filterPictureByRegion(picture);
    filtered = filtered && this.filterPictureByPerson(picture);
    filtered = filtered && this.filterPictureByPersonIds(picture);
    filtered =
      filtered && this.filterPictureByPropertyValues(picture, propertyCache);

    return filtered;
  }

  private filterPictureByMarkingsOnPictureId(
    picture: SupportedPicture
  ): boolean {
    if (!this.filter?.hasMarkingOnPictureId) return true;
    return (
      this.filter.hasMarkingOnPictureId ===
      picture.coordsFromPositionedPictureInfo?.pictureId
    );
  }

  private filterPictureByProjectIds(picture: SupportedPicture): boolean {
    if (!this.filter?.projectIds) return true;
    return this.filter.projectIds.some((pId) => pId === picture.ownerProjectId);
  }

  private filterPictureByTimestamp(picture: SupportedPicture): boolean {
    const timestamp = this.getTimestampOfPicture(picture);

    if (
      timestamp &&
      this.filter?.timestampFrom &&
      timestamp < this.filter.timestampFrom
    ) {
      return false;
    }

    if (
      timestamp &&
      this.filter?.timestampTo &&
      timestamp > this.filter.timestampTo
    ) {
      return false;
    }

    return true;
  }

  private getTimestampOfPicture(picture: SupportedPicture): number {
    return new Date(picture.takenAt || picture.createdAt).getTime();
  }

  private filterPictureByLatLongArea(picture: SupportedPicture): boolean {
    if (this.filter?.latLongArea) {
      if (
        !picture.coords ||
        !picture.coords.longitude ||
        !picture.coords.latitude
      ) {
        return false;
      }

      const lat = picture.coords.latitude;
      const long = picture.coords.longitude;
      const area = this.filter.latLongArea;
      if (
        lat < area.minLatitude ||
        lat > area.maxLatitude ||
        long < area.minLongitude ||
        long > area.maxLongitude
      ) {
        return false;
      }
    }

    return true;
  }

  private filterPictureByTags(picture: SupportedPicture): boolean {
    const tagIdsOfFilter = this.filter?.tagIds;
    const untaggedOnly = this.filter?.untaggedPicturesOnly ?? false;

    if (untaggedOnly) {
      if (!picture.tagIds || picture.tagIds.length === 0) {
        return true;
      }
      return false;
    } else {
      if (!tagIdsOfFilter || tagIdsOfFilter.length === 0) {
        return true;
      }

      const pictureTagIds = picture.tagIds || [];

      switch (this.filter?.tagMatchMode) {
        case GalleryThingPictureFilterTagMatchMode.EXACT:
          return (
            tagIdsOfFilter.length === pictureTagIds.length &&
            tagIdsOfFilter.every((id) => pictureTagIds.indexOf(id) >= 0)
          );
        case GalleryThingPictureFilterTagMatchMode.PARTIAL:
        default:
          return pictureTagIds.some((id) => tagIdsOfFilter.indexOf(id) >= 0);
      }
    }
  }

  private filterPictureByDescription(picture: SupportedPicture): boolean {
    if (!this.filter?.pictureDescription) return true;
    return (
      picture.description
        ?.toLocaleLowerCase()
        .includes(this.filter?.pictureDescription.toLocaleLowerCase()) ?? false
    );
  }

  private filterPictureByRegion(picture: SupportedPicture): boolean {
    if (!this.filter?.regionId) {
      return true;
    }

    if (!picture.entry) {
      return false;
    }

    const entry = this.entityManager.entryRepository.getById(picture.entry);
    return !!entry && entry.regionId === this.filter.regionId;
  }

  private filterPictureByPerson(picture: SupportedPicture): boolean {
    if (!this.filter?.personSearchPattern) {
      return true;
    }

    if (!picture.entry) {
      return false;
    }

    const pattern = this.filter.personSearchPattern.toUpperCase().trim();

    const entry = this.entityManager.entryRepository.getById(picture.entry);
    if (!entry) return false;

    const entryToPersons =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);
    for (const entryToPerson of entryToPersons) {
      const person = this.entityManager.personRepository.getById(
        entryToPerson.personId
      );
      if (!person) {
        setTimeout(() => {
          throw new Error(
            `Could not find person with id ${entryToPerson.personId}`
          );
        });
        continue;
      }
      const fullName = PersonUtils.getPersonDisplayNameForPerson(person);
      if (fullName.toLocaleUpperCase().includes(pattern)) return true;
    }
    return false;
  }

  private filterPictureByPersonIds(picture: SupportedPicture): boolean {
    const personIds = this.filter?.personIds;
    if (!personIds) return true;
    if (!picture.entry) return false;

    const entry = this.entityManager.entryRepository.getById(picture.entry);
    if (!entry) return false;

    const entryToPersons =
      this.entityManager.entryToPersonRepository.getByEntryId(entry.id);
    return entryToPersons.some((entryToPerson) =>
      personIds.includes(entryToPerson.personId)
    );
  }

  private filterPictureByPropertyValues(
    picture: SupportedPicture,
    propertyCache: PropertyCache
  ): boolean {
    if (!this.filter?.properties || this.filter.properties.length === 0)
      return true;
    if (!picture.entry) return false;

    return this.filter.properties.every((property) => {
      if (!GalleryThingFilterHelper.isActiveFilter(property)) return true;

      const entryPropertyValue = propertyCache.getEntryPropertyValue({
        entryId: picture.entry,
        propertyName: property.name ?? null,
        propertyType:
          filterTypeToFilterConfiguration[property.type]
            .correspondingPropertyType
      });

      return GalleryThingFilterHelper.propertiesAreEqual(
        {
          value: entryPropertyValue,
          type: filterTypeToFilterConfiguration[property.type]
            .correspondingPropertyType
        },
        property
      );
    });
  }
}

export type GalleryThingPictureFilterHandleOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};

export type SupportedPicture = EntryPicture | DefectPicture;

type PropertyBinderConfig = {
  filteredPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry>;
};
