import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManager } from '../../classes/SubscriptionManager';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditTextBrickTemplatesDialog } from '../../dialogs/edit-text-brick-templates-dialog/edit-text-brick-templates-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TextBrickTemplate } from '../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { TextBrick } from '../../classes/EntityManager/entities/TextBrick/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class TextBrickWidget {
  @bindable public entry: Entry | null = null;

  @bindable public path: Array<string> = [];

  @bindable public enabled = false;

  @bindable public temporaryGroupName: string | null = null;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  protected selectedTextBricks: Array<TextBrick> = [];
  protected remainingTextBrickTemplates: Array<TextBrickTemplate> = [];

  private subscriptionMananger: SubscriptionManager;

  private boundUpdateRemainingTextBricks =
    this.updateRemainingTextBrickTemplates.bind(this);

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.subscriptionMananger = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as TextBrickWidget,
        propertyName: 'entry'
      });
  }

  // Lifecycle

  protected attached(): void {
    this.subscriptionMananger.subscribeToModelChanges(
      EntityName.TextBrick,
      this.updateTextBricks.bind(this)
    );

    this.subscriptionMananger.subscribeToModelChanges(
      EntityName.TextBrickTemplate,
      this.boundUpdateRemainingTextBricks
    );
    this.subscriptionMananger.subscribeToArrayPropertyChanges(
      this,
      'path',
      this.boundUpdateRemainingTextBricks
    );
  }

  protected detached(): void {
    this.subscriptionMananger.disposeSubscriptions();
  }

  // Change handlers

  protected entryChanged(): void {
    this.updateTextBricks();
  }

  // Private methods

  private updateTextBricks(): void {
    if (this.entry) {
      this.selectedTextBricks =
        this.entityManager.textBrickRepository.getByEntryId(this.entry.id);
    } else {
      this.selectedTextBricks = [];
    }
    this.updateRemainingTextBrickTemplates();
  }

  private updateRemainingTextBrickTemplates(): void {
    if (this.entry) {
      const availableTextBricks =
        this.entityManager.textBrickTemplateRepository.getByUserGroupIdAndPathWithWildcards(
          this.entry.ownerUserGroupId,
          this.path
        );
      this.remainingTextBrickTemplates = availableTextBricks.filter(
        (aTB) =>
          !this.selectedTextBricks.find(
            (sTB) => sTB.textBrickTemplateId === aTB.id
          )
      );
    } else {
      this.remainingTextBrickTemplates = [];
    }
  }

  // Handlers

  protected handleClickOnRemainingTextBrickTemplate(
    textBrickTemplate: TextBrickTemplate
  ): void {
    assertNotNullOrUndefined(
      this.entry,
      'cannot add text brick without an entry'
    );
    assertNotNullOrUndefined(
      this.entry.ownerProjectId,
      'cannot add text brick without an ownerProjectId'
    );

    this.entityManager.textBrickRepository.create({
      entryId: this.entry.id,
      textBrickTemplateId: textBrickTemplate.id,
      ownerProjectId: this.entry.ownerProjectId,
      ownerUserGroupId: this.entry.ownerUserGroupId,
      value: textBrickTemplate.value,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.updateTextBricks();
  }

  protected handleClickOnMoreButton(): void {
    assertNotNullOrUndefined(
      this.entry,
      'cannot open EditTextBricksDialog without entry'
    );

    const textBrickTemplateIds = [];
    for (const textBrick of this.selectedTextBricks) {
      if (textBrick.textBrickTemplateId)
        textBrickTemplateIds.push(textBrick.textBrickTemplateId);
    }

    void EditTextBrickTemplatesDialog.open({
      path: this.path,
      textBrickTemplateIds: textBrickTemplateIds,
      entry: this.entry,
      temporaryGroupName: this.temporaryGroupName,
      onDialogAccept: () => {
        this.updateTextBricks();
      }
    });
  }
}
