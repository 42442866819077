import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessConfigurationDevicePropertiesConfiguration } from 'common/Types/ProcessConfigurationDevicePropertiesConfiguration';

import {
  DefaultPropertiesEnsurer,
  EnsureDefaultPropertiesService
} from '../../services/EnsureDefaultPropertiesService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationDeviceProperty } from '../../classes/EntityManager/entities/Property/types';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationDeviceDialog {
  public static async open(
    options: EditProcessConfigurationDeviceDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly configurationDevicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationDevice];

  private defaultPropertiesEnsurer: DefaultPropertiesEnsurer<ProcessConfigurationDeviceProperty> | null =
    null;

  private configurationDevice: ProcessConfigurationDevice | null = null;
  private properties: Array<ProcessConfigurationDeviceProperty> = [];
  private onDialogClosed: (() => void) | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly ensureDefaultPropertiesService: EnsureDefaultPropertiesService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.configurationDevicePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationDevice,
        context: this,
        expression: 'configurationDevice'
      });
  }

  public open(options: EditProcessConfigurationDeviceDialogOpenOptions): void {
    this.configurationDevice = options.processConfigurationDevice;
    this.onDialogClosed = options.onDialogClosed;

    this.setupDefaultPropertiesEnsurer();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.onDialogClosed = null;
    this.configurationDevice = null;
    if (this.defaultPropertiesEnsurer) {
      this.defaultPropertiesEnsurer.unsubscribe();
      this.defaultPropertiesEnsurer.setProperties(null);
    }

    onClosed && onClosed();
  }

  protected handleConfigurationDeviceChanged(): void {
    assertNotNullOrUndefined(
      this.configurationDevice,
      "can't EditProcessConfigurationDeviceDialog.handleConfigurationDeviceChanged without configurationDevice"
    );

    this.entityManager.processConfigurationDeviceRepository.update(
      this.configurationDevice
    );
  }

  private updateProperties(): void {
    if (this.configurationDevice) {
      this.properties =
        this.entityManager.propertyRepository.getByProcessConfigurationDeviceId(
          this.configurationDevice.id
        );
      this.defaultPropertiesEnsurer?.setProperties(this.properties);
    } else {
      this.properties = [];
      this.defaultPropertiesEnsurer?.setProperties(null);
    }
  }

  private setupDefaultPropertiesEnsurer(): void {
    this.defaultPropertiesEnsurer?.unsubscribe();

    const processConfiguration = this.configurationDevice
      ? this.entityManager.processConfigurationRepository.getById(
          this.configurationDevice.ownerProcessConfigurationId
        )
      : null;

    const config: ProcessConfigurationDevicePropertiesConfiguration | null =
      processConfiguration?.devicePropertiesConfigurationJson
        ? JSON.parse(processConfiguration.devicePropertiesConfigurationJson)
        : null;
    const defaultPropertyConfigs = config ? config.defaultProperties : [];

    this.defaultPropertiesEnsurer =
      this.ensureDefaultPropertiesService.createEnsurer({
        defaultPropertyConfigs: defaultPropertyConfigs,
        propertyCreationPostProcessor: (propertyConfig) => {
          assertNotNullOrUndefined(
            this.configurationDevice,
            "configurationDevice is not set yet, the default properties ensurer shouldn't be creating properties yet"
          );

          return {
            ...propertyConfig,
            processConfigurationDeviceId: this.configurationDevice.id,
            ownerUserGroupId: this.configurationDevice.ownerUserGroupId
          };
        }
      });

    this.defaultPropertiesEnsurer.subscribe();
  }
}

export type EditProcessConfigurationDeviceDialogOpenOptions = {
  processConfigurationDevice: ProcessConfigurationDevice;
  onDialogClosed: (() => void) | null;
};
