import { autoinject } from 'aurelia-framework';

/**
 * A widget that displays a header & corresponding details.
 *
 * @attribute data-border - either not defined, or "none". No bar will be shown between header and content if "none" is specified.
 *
 * @slot title - will be displayed as the title in the header
 * @slot actions - will be displayed to the right of the title in the header
 * @slot default - will be displayed below the header, positioned under a divider
 */
@autoinject()
export class EditDetails {}
