import { Tool } from './Tool';

export class ArrowTool extends Tool {
  _name = 'Pfeil';
  _icons = ['fal fa-arrow-up'];
  _machineName = 'arrow';

  /**@type {(Msvg.Point|null)}*/
  _firstPosition;
  /**@type {(Msvg.Point|null)}*/
  _lastSecondPosition;
  /**@type {(Msvg.Path|null)}*/
  _path;

  _cancel() {
    if (this._path) {
      this._msvg.remove(this._path);
    }
    super._cancel();
  }

  _reset() {
    super._reset();
    this._firstPosition = null;
    this._lastSecondPosition = null;
    this._path = null;
  }

  /**
   * the position is already normalized
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    if (this._path) {
      this._modified();
      this._reset();
    } else {
      this._startNewPath(position);
    }
  }

  /**
   * the position is already normalized
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseMovedHandler(event, position) {
    if (this._path) {
      this._updatePath(position);
    }
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (!this._path) {
      this._startNewPath(this._dragStartPosition);
    }

    this._updatePath(position);
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._updatePath(position);
    this._msvg.append(this._path);
    this._modified();
    this._reset();
  }

  /**
   *
   * @param {Msvg.Point} position
   * @private
   */
  _startNewPath(position) {
    this._firstPosition = position;
    this._path = this._createPath();
    this._msvg.append(this._path);
  }

  /**
   *
   * @returns {Msvg.Path}
   * @private
   */
  _createPath() {
    this._lastSecondPosition = null;
    return new Msvg.Path()
      .setStroke(this._color)
      .setStrokeWidth(this._size)
      .setStrokeLinejoin('round')
      .setFill('none');
  }

  _stylingChanged() {
    if (this._path) {
      this._path.setStroke(this._color).setStrokeWidth(this._size);

      //since the length of the legs also depend on the size, we have to update the path itself too
      if (this._lastSecondPosition) {
        this._updatePath(this._lastSecondPosition);
      }
    }
  }

  /**
   *
   * @param {Msvg.Point} secondPosition
   * @private
   */
  _updatePath(secondPosition) {
    const pos1 = this._firstPosition;
    const pos2 = secondPosition;

    const arrowLength = Math.sqrt(
      Math.pow(pos2.x - pos1.x, 2) + Math.pow(pos2.y - pos1.y, 2)
    );

    const angle = 25;
    const angleRad = (2 * Math.PI * angle) / 360;

    const arrowLegLengthFactor = 8;
    const arrowLegLength = this._size * arrowLegLengthFactor;

    const pos3 = new Msvg.Point(
      pos2.x +
        (arrowLegLength / arrowLength) *
          ((pos1.x - pos2.x) * Math.cos(angleRad) +
            (pos1.y - pos2.y) * Math.sin(angleRad)),
      pos2.y +
        (arrowLegLength / arrowLength) *
          ((pos1.y - pos2.y) * Math.cos(angleRad) -
            (pos1.x - pos2.x) * Math.sin(angleRad))
    );
    const pos4 = new Msvg.Point(
      pos2.x +
        (arrowLegLength / arrowLength) *
          ((pos1.x - pos2.x) * Math.cos(angleRad) -
            (pos1.y - pos2.y) * Math.sin(angleRad)),
      pos2.y +
        (arrowLegLength / arrowLength) *
          ((pos1.y - pos2.y) * Math.cos(angleRad) +
            (pos1.x - pos2.x) * Math.sin(angleRad))
    );

    this._path
      .reset()
      .addMoveToAbsolute(pos1)
      .addLineToAbsolute(pos2)
      .addLineToAbsolute(pos3)
      .addMoveToAbsolute(pos2)
      .addLineToAbsolute(pos4);

    this._lastSecondPosition = secondPosition;
  }
}
