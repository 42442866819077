import { autoinject, computedFrom } from 'aurelia-framework';
import { GetCalendarAppointmentsSuccessResponse } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DateUtils } from '../../../../common/src/DateUtils';
import { EditProcessTaskAppointmentDialog } from '../../operationsComponents/edit-process-task-appointment-dialog/edit-process-task-appointment-dialog';
import { SocketService } from '../../services/SocketService';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { ProcessTaskAppointmentCalendarWidgetMode } from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  OperationsCalendarSettings,
  OperationsCalendarSettingsService
} from '../../services/OperationsCalendarSettingsService/OperationsCalendarSettingsService';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ValueWithLabel } from '../../types/ValueWithLabel';
import { SelectedDayChangedEvent } from '../../aureliaComponents/calendar-day-select/calendar-day-select';
import { SelectedYearChangedEvent } from '../../aureliaComponents/calendar-year-select/calendar-year-select';
import {
  SingleSocketRequest,
  SingleSocketRequestService,
  SingleSocketRequestSkippedError
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { CalendarEntryDataSource } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntryDataSource';
import { CalendarEntriesFromServerStrategy } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/strategies/CalendarEntriesFromServerStrategy';
import { LoadProcessTaskGroupWithEntitiesService } from '../../services/LoadProcessTaskGroupWithEntitiesService';
import { CalendarEntryClickedEvent } from '../../operationsComponents/process-task-appointment-calendar-widget/process-task-appointment-calendar-widget';
import { CreateProcessTaskAppointmentInCalendarDialog } from '../../operationsComponents/create-process-task-appointment-in-calendar-dialog/create-process-task-appointment-in-calendar-dialog';
import { EditProcessTaskRecurringAppointmentDialog } from '../../operationsComponents/edit-process-task-recurring-appointment-dialog/edit-process-task-recurring-appointment-dialog';
import { CalendarEntryType } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntry';

@autoinject()
export class ShowOperationsCalendar {
  private static TEMPORARY_GROUP_NAME = 'ShowOperationsCalendar';

  protected selectedUsers: Array<User> = [];
  protected isConnected: boolean = false;

  protected readonly DateUtils = DateUtils;
  protected readonly ProcessTaskAppointmentCalendarWidgetMode =
    ProcessTaskAppointmentCalendarWidgetMode;

  private navBarDates: Array<Date> = [];
  private calendarSettings: OperationsCalendarSettings;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly getAppointmentsRequest: SingleSocketRequest<
    { dateFrom: Date; dateTo: Date },
    GetCalendarAppointmentsSuccessResponse
  >;
  private readonly dataSourceStrategy: CalendarEntriesFromServerStrategy;
  protected readonly dataSource: CalendarEntryDataSource;

  private isYearModeEnabled: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly loadProcessTaskGroupWithEntitiesService: LoadProcessTaskGroupWithEntitiesService,
    private readonly operationsCalendarSettingsService: OperationsCalendarSettingsService,
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService,
    singleSocketRequestService: SingleSocketRequestService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.calendarSettings = operationsCalendarSettingsService.getSettings();
    this.getAppointmentsRequest = singleSocketRequestService.createRequest({
      requestCallback: ({ data }) => {
        return new Promise<GetCalendarAppointmentsSuccessResponse>(
          (resolve, reject) => {
            this.socketService.getCalendarAppointments(
              {
                dateFromIso: data.dateFrom.toISOString(),
                dateToIso: data.dateTo.toISOString()
              },
              (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(new Error("couldn't fetch calendar appointments"));
                }
              }
            );
          }
        );
      }
    });

    this.dataSourceStrategy = new CalendarEntriesFromServerStrategy({
      entityManager
    });

    this.dataSource = new CalendarEntryDataSource(this.dataSourceStrategy);
  }

  protected activate(): void {
    this.selectWeekForDay(this.calendarSettings.selectedDate);
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.subscribeToEvent('socket:authenticated', () => {
      void this.updateAppointments(true);
    });
    this.subscriptionManager.subscribeToInterval(() => {
      void this.updateAppointments(false);
    }, 10000);

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateUsers.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.operationsCalendarSettingsService.bindSettings((settings) => {
        const oldSettings = this.calendarSettings;
        this.calendarSettings = settings;

        if (this.calendarSettings.selectedDate !== oldSettings.selectedDate) {
          this.selectWeekForDay(this.calendarSettings.selectedDate);
        }

        this.updateUsers();
      })
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.processTaskAppointmentCalendarWidgetYearModeEnabled',
        (value) => {
          this.isYearModeEnabled = value;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private async updateAppointments(showLoadingOverlay: boolean): Promise<void> {
    const firstNavBarDate = this.navBarDates[0];
    const lastNavBarDate = this.navBarDates[this.navBarDates.length - 1];

    if (
      !firstNavBarDate ||
      !lastNavBarDate ||
      !this.socketService.isConnected() ||
      !this.socketService.isAuthenticated()
    ) {
      return;
    }

    const calendarModeIsYear =
      this.calendarSettings.calendarMode ===
      ProcessTaskAppointmentCalendarWidgetMode.YEAR;

    const dateFrom = calendarModeIsYear
      ? DateUtils.getStartDateOfYear(firstNavBarDate)
      : new Date(firstNavBarDate);
    DateUtils.removeTimeInfoFromDate(dateFrom);

    const dateTo = calendarModeIsYear
      ? DateUtils.getEndDateOfYear(lastNavBarDate)
      : DateUtils.getNextDay(lastNavBarDate);
    DateUtils.removeTimeInfoFromDate(dateTo);

    try {
      showLoadingOverlay && GlobalLoadingOverlay.setLoadingState(this, true);

      const response = await this.getAppointmentsRequest.send({
        dateFrom,
        dateTo
      });

      this.dataSourceStrategy.setBaseData(response.entries);

      showLoadingOverlay && GlobalLoadingOverlay.setLoadingState(this, false);
    } catch (e) {
      showLoadingOverlay && GlobalLoadingOverlay.setLoadingState(this, false);

      // ignore the skipped error since it's kinda expected
      if (!(e instanceof SingleSocketRequestSkippedError)) {
        throw e;
      }
    }
  }

  private updateUsers(): void {
    const sortedUsers = this.entityManager.userRepository
      .getAll()
      .sort((a, b) => {
        const aName = a.username ? a.username : '';
        const bName = b.username ? b.username : '';
        return aName.localeCompare(bName);
      });

    this.selectedUsers = sortedUsers.filter((user) => {
      const color = user.secondaryColor ?? '';
      if (
        this.calendarSettings.userColorGroupSelectedMap.get(color) === false
      ) {
        return false;
      }

      if (this.calendarSettings.userIdSelectedMap.get(user.id) === false) {
        return false;
      }

      return true;
    });
  }

  private selectWeekForDay(day: Date): void {
    let currentDay = DateUtils.getStartDateOfWeek(day);
    this.navBarDates = [currentDay];
    for (let i = 1; i < 5; i++) {
      currentDay = DateUtils.getNextDay(currentDay);
      this.navBarDates.push(currentDay);
    }

    void this.updateAppointments(true);
  }

  protected handleSelectedDayChanged(evt: SelectedDayChangedEvent): void {
    this.operationsCalendarSettingsService.modifySettings({
      selectedDate: evt.detail.day
    });
  }

  protected handleSelectedYearChanged(evt: SelectedYearChangedEvent): void {
    this.operationsCalendarSettingsService.modifySettings({
      selectedDate: evt.detail.year
    });
  }

  private handleJumpToTodayClick(): void {
    this.operationsCalendarSettingsService.modifySettings({
      selectedDate: new Date()
    });
  }

  private handleCalendarModeChanged(
    event: SelectChangedEvent<
      ProcessTaskAppointmentCalendarWidgetMode,
      ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
    >
  ): void {
    this.operationsCalendarSettingsService.modifySettings({
      calendarMode:
        event.detail.value ??
        ProcessTaskAppointmentCalendarWidgetMode.USERS_WEEK
    });
  }

  protected handleCalendarEntryClicked(event: CalendarEntryClickedEvent): void {
    void this.loadProcessTaskGroupWithEntitiesService.loadByProcessTaskId({
      processTaskId: event.detail.entry.processTaskId,
      temporaryGroupName: ShowOperationsCalendar.TEMPORARY_GROUP_NAME,
      onProcessTaskLoaded: () => {
        const entry = event.detail.entry;
        switch (entry.type) {
          case CalendarEntryType.NORMAL:
            const appointment =
              this.entityManager.processTaskAppointmentRepository.getById(
                entry.id
              );
            if (!appointment) return;
            void EditProcessTaskAppointmentDialog.open({
              appointment: appointment,
              showProcessTaskInfo: true
            });
            break;
          case CalendarEntryType.RECURRING:
            const recurringAppointment =
              this.entityManager.processTaskRecurringAppointmentRepository.getById(
                entry.id
              );
            if (!recurringAppointment) return;
            void EditProcessTaskRecurringAppointmentDialog.open({
              appointment: recurringAppointment,
              selectedDate: entry.startTimestamp
                ? new Date(entry.startTimestamp)
                : undefined
            });
            break;
          default:
            throw new Error(
              `Type ${
                (event.detail as any).type
              } of CalendarEntry is not handled.`
            );
        }
      }
    });
  }

  protected handleFABClicked(): void {
    void CreateProcessTaskAppointmentInCalendarDialog.open();
  }

  @computedFrom('isYearModeEnabled')
  protected get viewModeChoices(): Array<
    ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
  > {
    const options: Array<
      ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
    > = [
      {
        value: ProcessTaskAppointmentCalendarWidgetMode.USERS_PER_DAY,
        label: 'operations.processTaskAppointmentCalendarWidget.mode.singleDay'
      },
      {
        value: ProcessTaskAppointmentCalendarWidgetMode.WEEK,
        label: 'operations.processTaskAppointmentCalendarWidget.mode.week'
      },
      {
        value: ProcessTaskAppointmentCalendarWidgetMode.USERS_WEEK,
        label: 'operations.processTaskAppointmentCalendarWidget.mode.usersWeek'
      }
    ];

    if (this.isYearModeEnabled) {
      options.push({
        value: ProcessTaskAppointmentCalendarWidgetMode.YEAR,
        label: 'operations.processTaskAppointmentCalendarWidget.mode.year'
      });
    }

    return options;
  }
}
