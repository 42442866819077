import moment from 'moment';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { CoordinateHelper, Coordinates } from '../../classes/CoordinateHelper';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { BasemapMap, MapMarker } from '../../map/basemap-map/basemap-map';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

export class NoCoordinatesDialog {
  protected expirationTimes = [5, 30];

  protected dialog: RecordItDialog | null = null;
  protected basemapMap: BasemapMap | null = null;

  private mapIsInitialized = false;

  private lastPositionMarker: MapMarker | null = null;

  private options: NoCoordinatesDialogOptions | null = null;

  public static async open(options: NoCoordinatesDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: NoCoordinatesDialogOptions): void {
    this.options = options;

    this.updateMapCenter();
    this.updateLastPositionMarker();

    CoordinateHelper.subscribeToCoordinatesChanged(
      this,
      this.coordinatesUpdated.bind(this)
    );

    this.dialog?.open();
  }

  private close(): void {
    this.dialog?.close();
    CoordinateHelper.unsubscribeByContext(this);
  }

  private coordinatesUpdated(position: Coordinates): void {
    if (
      position.latitude !== null &&
      position.longitude !== null &&
      position.timestamp &&
      this.options?.lastCoordinates.timestamp &&
      position.timestamp > this.options.lastCoordinates.timestamp
    ) {
      this.closeDialogWithCoordinates(position);
    }
  }

  // ********** Getters **********

  protected get timeSinceLastPosition(): string {
    const timestamp = this.options?.lastCoordinates.timestamp
      ? Date.now() - this.options.lastCoordinates.timestamp
      : 0;
    return moment(timestamp).format('mm:ss'); // Math.round(timestamp / 1000 / 60);
  }

  protected get hasLastCoordinates(): boolean {
    return (
      !!this.options?.lastCoordinates.latitude &&
      !!this.options.lastCoordinates.longitude
    );
  }

  // ********** Handlers **********

  protected handleUseLastPositionClicked(
    minutesUntilExpiration: number | null = null
  ): void {
    assertNotNullOrUndefined(this.options, 'dialog has no options');
    this.closeDialogWithCoordinates({
      ...this.options.lastCoordinates,
      expiresAt: this.minutesUntilExpirationToExpiresAt(minutesUntilExpiration)
    });
  }

  protected handleSetPositionManuallyClicked(
    minutesUntilExpiration: number | null = null
  ): void {
    const centerCoordinates = this.basemapMap?.getMapInstance()?.getCenter();
    this.closeDialogWithCoordinates({
      latitude: centerCoordinates?.lat ?? null,
      longitude: centerCoordinates?.lng ?? null,
      accuracy: 0,
      timestamp: Date.now(),
      expiresAt: this.minutesUntilExpirationToExpiresAt(minutesUntilExpiration)
    });
  }

  protected handleMapInitialized(): void {
    this.mapIsInitialized = true;
    this.updateMapCenter();
    this.updateLastPositionMarker();
  }

  // ********** Private Methods **********

  private updateLastPositionMarker(): void {
    if (!this.mapIsInitialized) return;
    assertNotNullOrUndefined(
      this.basemapMap,
      'no basemap-map available for some reason'
    );

    const latitude = this.options?.lastCoordinates.latitude;
    const longitude = this.options?.lastCoordinates.longitude;

    if (latitude && longitude) {
      if (this.lastPositionMarker) {
        this.lastPositionMarker.getLeafletMarker().setLatLng({
          lat: latitude,
          lng: longitude
        });
      } else {
        this.lastPositionMarker = new MapMarker(latitude, longitude);
        this.basemapMap.setMarkers([this.lastPositionMarker]);
      }
    } else {
      this.removeLastPositionMarker();
    }
  }

  private removeLastPositionMarker(): void {
    if (this.lastPositionMarker) {
      this.lastPositionMarker.remove();
      this.lastPositionMarker = null;
    }
  }

  private updateMapCenter(): void {
    if (!this.mapIsInitialized) return;
    assertNotNullOrUndefined(
      this.basemapMap,
      'no basemap-map available for some reason'
    );

    if (
      this.options?.lastCoordinates.latitude &&
      this.options?.lastCoordinates.longitude
    ) {
      this.basemapMap.setCenter({
        lat: this.options.lastCoordinates.latitude,
        lng: this.options.lastCoordinates.longitude
      });
    }
  }

  private closeDialogWithCoordinates(coordinates: Coordinates): void {
    this.options?.onDialogClosedCallback(coordinates);
    this.close();
  }

  private minutesUntilExpirationToExpiresAt(
    minutesUntilExpiration: number | null
  ): number | null {
    if (minutesUntilExpiration == null) {
      return null;
    }

    return Date.now() + minutesUntilExpiration * 60 * 1000;
  }
}

type NoCoordinatesDialogOptions = {
  lastCoordinates: Coordinates;
  onDialogClosedCallback: (coordinates: Coordinates) => void;
};
