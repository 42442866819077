/**
 * Days of the week in an enum for easier readability.
 */
export enum Weekday {
  MO = 'monday',
  TU = 'tuesday',
  WE = 'wednesday',
  TH = 'thursday',
  FR = 'friday',
  SA = 'saturday',
  SU = 'sunday'
}

/**
 * returns the ordinal number for a given weekday, assuming the week starts with sunday.
 */
export function toNumber(weekday: Weekday): number {
  switch (weekday) {
    case Weekday.SU:
      return 0;
    case Weekday.MO:
      return 1;
    case Weekday.TU:
      return 2;
    case Weekday.WE:
      return 3;
    case Weekday.TH:
      return 4;
    case Weekday.FR:
      return 5;
    case Weekday.SA:
      return 6;
    default:
      throw new Error('Weekday not supported.');
  }
}

export const allWeekdays = (): Array<Weekday> => Object.values(Weekday);
