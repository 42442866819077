import { Observable, shareReplay } from 'rxjs';
import { BaseEntity } from '../../../classes/EntityManager/entities/BaseEntity';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import {
  BindAdapter,
  EntityAdapter,
  EntityOfEntityAdapter
} from '../EntityAdapter/EntityAdapter';

/**
 * A container which manages the subscription of the adapter.
 * This is needed so the the adapter is only subscribed if there are usages for it
 */
export class EntityAdapterContainer<
  TAdapter extends EntityAdapter<BaseEntity>
> {
  private readonly adapter: TAdapter;
  private readonly adapterWithRevision$: Observable<
    BindAdapterOptions<TAdapter>
  >;

  constructor({
    adapter,
    bindAdapter
  }: {
    adapter: TAdapter;
    bindAdapter: BindAdapter;
  }) {
    this.adapter = adapter;

    this.adapterWithRevision$ = new Observable<BindAdapterOptions<TAdapter>>(
      (subscriber) => {
        let revision = 1;

        const adapterDisposable = this.adapter.subscribe({
          updateBindings: () => {
            revision++;

            subscriber.next({
              adapter,
              revision
            });
          },
          bindAdapter
        });

        subscriber.next({
          adapter,
          revision
        });

        return () => {
          adapterDisposable.dispose();
        };
      }
    ).pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  public bindAdapter(
    callback: (options: BindAdapterOptions<TAdapter>) => void
  ): Disposable {
    const subscription = this.adapterWithRevision$.subscribe((options) => {
      callback(options);
    });

    return {
      dispose: () => {
        subscription.unsubscribe();
      }
    };
  }

  public getEntityInfo(): ReturnType<TAdapter['getEntityInfo']> {
    return this.adapter.getEntityInfo() as ReturnType<
      TAdapter['getEntityInfo']
    >;
  }
}

export type EntityAdapterContainerOptions<TEntity extends BaseEntity> = {
  adapter: EntityAdapter<TEntity>;
};

export type EntityAdapterOfEntityAdapterContainer<TAdapterContainer> =
  TAdapterContainer extends EntityAdapterContainer<infer U> ? U : never;
export type EntityOfEntityAdapterContainer<TAdapterContainer> =
  EntityOfEntityAdapter<
    EntityAdapterOfEntityAdapterContainer<TAdapterContainer>
  >;

export type AdapterOptions<TAdapter extends EntityAdapter<BaseEntity>> = {
  adapter: TAdapter;
  updateBindings$: Observable<void>;
};

export type BindAdapterOptions<TAdapter extends EntityAdapter<BaseEntity>> = {
  adapter: TAdapter;
  /**
   * this will increase each time the adapter changed so you can use this for @computedFrom etc
   */
  revision: number;
};
