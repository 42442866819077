import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { UserDefinedEntityDto } from './UserDefinedEntityDto';

export function createUserDefinedEntityEntityInfo<
  TEntity extends UserDefinedEntityDto<string, string>
>(
  options: CreateEntityInfoOptions<
    UserDefinedEntityDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.UserDefinedEntity,
  TEntity
> {
  return {
    entityName: EntityName.UserDefinedEntity,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserDefinedEntityDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: ''
        },
        isGlobal: {
          name: 'isGlobal',
          defaultValue: false
        },
        userDefinedEntityConfigId: {
          name: 'userDefinedEntityConfigId',
          ref: EntityName.UserDefinedEntityConfig,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        customId: {
          name: 'customId',
          defaultValue: null
        },
        globalUserDefinedEntityId: {
          name: 'globalUserDefinedEntityId',
          defaultValue: null,
          ref: EntityName.UserDefinedEntity
        }
      },
      options.additionalFieldInfos
    )
  };
}
