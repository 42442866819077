import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { Utils } from '../../classes/Utils/Utils';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { StructureTemplateEntryUtils } from '../../classes/EntityManager/entities/StructureTemplateEntry/StructureTemplateEntryUtils';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

/**
 * @event structure-thing-path-selected - StructureThingPathSelectedEvent
 */
@autoinject()
export class StructureThingPathSelector {
  @bindable public enabled = false;

  @bindable public structure: StructureTemplate | null = null;

  @bindable public excludeIntermediatePaths = false;

  @bindable public parentEntryPath: Array<Entry> | null = null;

  protected paths: Array<Path> = [];

  private parentIdToChildrenMap: Map<
    string | null,
    Array<StructureTemplateEntry>
  > = new Map();

  protected selectedPathIndex: number | null = null;

  private projectId: string | null = null;

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'parentEntryPath',
      this.updateSelectedPathIndex.bind(this)
    );
    this.updateSelectedPathIndex();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected structureChanged(): void {
    this.updatePaths();
    this.updateSelectedPathIndex();
  }

  protected excludeIntermediatePathsChanged(): void {
    this.updatePaths();
    this.updateSelectedPathIndex();
  }

  private updatePaths(): void {
    if (!this.structure) {
      this.paths = [];
      return;
    }

    this.constructParentIdToChildrenMap();

    const array: Array<Path> = [];

    const structureTemplateRootEntries =
      this.entityManager.structureTemplateEntryRepository.getByParentId(
        this.structure.id
      );
    structureTemplateRootEntries.forEach((structureTemplateEntry) => {
      this.constructPathsRecursively(structureTemplateEntry, null, array);
    });

    this.paths = array;
  }

  private updateSelectedPathIndex(): void {
    const firstPathElement = this.parentEntryPath?.[0];

    if (!this.parentEntryPath || !firstPathElement) {
      this.selectedPathIndex = null;
      this.projectId = null;
      return;
    }

    this.projectId = firstPathElement.ownerProjectId;

    const path = this.getPathByEntryPath(this.parentEntryPath);
    if (path) {
      this.selectedPathIndex = this.paths.indexOf(path);
    } else {
      this.selectedPathIndex = null;
    }
  }

  private constructParentIdToChildrenMap(): void {
    if (this.structure) {
      const structureTemplateEntries =
        this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
          this.structure.id
        );
      this.parentIdToChildrenMap = Utils.groupBy<
        StructureTemplateEntry,
        string | null
      >(structureTemplateEntries, (e) => e.parentEntryId);
    } else {
      this.parentIdToChildrenMap = new Map();
    }
  }

  private constructPathsRecursively(
    item: StructureTemplateEntry,
    parentPathItem: Path | null,
    array: Array<Path>
  ): void {
    const name = `${parentPathItem ? `${parentPathItem.label} 🢒 ` : ''}${
      item.name
    }`;
    const arrayItem = {
      id: array.length,
      label: name,
      structureTemplateEntries: parentPathItem
        ? parentPathItem.structureTemplateEntries.concat(item)
        : [item]
    };

    let children: Array<StructureTemplateEntry> = [];

    const relation =
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
        item.id
      );
    if (relation) {
      children =
        this.entityManager.structureTemplateEntryRepository.getByStructureTemplateEntryGroupId(
          relation.structureTemplateEntryGroupId
        );
    } else {
      children = this.parentIdToChildrenMap.get(item.id) ?? [];
    }

    if (!this.excludeIntermediatePaths || !children.length) {
      array.push(arrayItem);
    }

    if (children?.length) {
      children.forEach((child) => {
        this.constructPathsRecursively(child, arrayItem, array);
      });
    }
  }

  protected handleStructurePathSelectionChanged(): void {
    if (!this.domElement) return;
    const path =
      this.selectedPathIndex != null
        ? this.paths[this.selectedPathIndex]
        : null;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'structure-thing-path-selected',
      detail: {
        path: path
      }
    });
  }

  private getPathByEntryPath(parentEntryPath: Array<Entry>): Path | null {
    return (
      this.paths.find((path) => {
        if (path.structureTemplateEntries.length !== parentEntryPath.length)
          return false;

        return path.structureTemplateEntries.every(
          (structureTemplateEntry, index) => {
            const parentEntry = parentEntryPath[index];
            if (!parentEntry) return false;

            return StructureTemplateEntryUtils.entryDependsOnStructureTemplateEntry(
              structureTemplateEntry,
              parentEntry
            );
          }
        );
      }) || null
    );
  }
}

export type Path = {
  id: number;
  label: string;
  structureTemplateEntries: Array<StructureTemplateEntry>;
};

export type StructureThingPathSelectedEvent = CustomEvent<{
  path: Path | null;
}>;
