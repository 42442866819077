import { Utils } from '../../classes/Utils/Utils';
import { autoinject } from 'aurelia-framework';

/**
 * a controller for form functionality, e.g. reseting all the input states
 * inputs which should be handled by the FormController should register themselves via the registerFormControllable function and be placed inside the form-controller element
 */
@autoinject()
export class FormController {
  private static attachedFormControllers: Array<FormController> = [];

  private domElement: Element;
  private controllables: Array<FormControllable> = [];

  public static registerFormControllable(
    element: Element,
    controllable: FormControllable
  ): void {
    Utils.walkThroughParentElements(element, (currentElement) => {
      if (currentElement.tagName !== 'FORM-CONTROLLER') {
        return true;
      }

      const controller = FormController.getControllerByElement(currentElement);

      if (controller) {
        controller.registerFormControllable(controllable);
      }

      return true;
    });
  }

  public static unregisterFormControllable(
    controllable: FormControllable
  ): void {
    for (const controller of FormController.attachedFormControllers) {
      controller.unregisterFormControllable(controllable);
    }
  }

  private static getControllerByElement(
    element: Element
  ): FormController | null {
    return (
      FormController.attachedFormControllers.find((fc) =>
        fc.isElement(element)
      ) || null
    );
  }

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public reset(): void {
    for (const controllable of this.controllables) {
      controllable.reset();
    }
  }

  protected bind(): void {
    FormController.attachedFormControllers.push(this);
  }

  protected unbind(): void {
    const index = FormController.attachedFormControllers.indexOf(this);
    if (index >= 0) {
      FormController.attachedFormControllers.splice(index, 1);
    }
  }

  private isElement(element: Element): boolean {
    return element === this.domElement;
  }

  private unregisterFormControllable(controllable: FormControllable): void {
    const index = this.controllables.indexOf(controllable);
    if (index >= 0) {
      this.controllables.splice(index, 1);
    }
  }

  private registerFormControllable(controllable: FormControllable): void {
    const index = this.controllables.indexOf(controllable);
    if (index === -1) {
      this.controllables.push(controllable);
    }
  }
}

export interface FormControllable {
  /**
   * reset the inputs state
   * e.g. resets an invalid number input to it's default value and removes the invalid styling
   */
  reset: () => void;
}
