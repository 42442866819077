import { autoinject, bindable } from 'aurelia-framework';

import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class UserDefinedEntityByBaseEntityFilter {
  @bindable public userDefinedEntities: Array<UserDefinedEntity> = [];

  /**
   * readonly
   */
  @bindable protected selectedLevel: UserDefinedEntityBaseEntityLevel | null =
    null;

  @bindable public filteredUserDefinedEntities: Array<UserDefinedEntity> = [];

  constructor() {}

  protected userDefinedEntitiesChanged(): void {
    this.preSelectFilterLevel();
    this.updateFilteredUserDefinedEntities();
  }

  @computed(expression('userDefinedEntities'))
  protected get filterOptions(): Array<FilterOption> {
    const options: Array<FilterOption> = [];
    const hasGlobal = this.userDefinedEntities.some((x) => x.isGlobal);
    const hasThing = this.userDefinedEntities.some((x) => !!x.thingId);
    const hasProject = this.userDefinedEntities.some((x) => !!x.ownerProjectId);

    if (hasGlobal) {
      options.push({
        labelTk: 'filterComponents.userDefinedEntityByBaseEntityFilter.global',
        level: UserDefinedEntityBaseEntityLevel.GLOBAL
      });
    }
    if (hasThing) {
      options.push({
        labelTk: 'filterComponents.userDefinedEntityByBaseEntityFilter.thing',
        level: UserDefinedEntityBaseEntityLevel.THING
      });
    }
    if (hasProject) {
      options.push({
        labelTk: 'filterComponents.userDefinedEntityByBaseEntityFilter.project',
        level: UserDefinedEntityBaseEntityLevel.PROJECT
      });
    }

    return options;
  }

  protected updateFilteredUserDefinedEntities(): void {
    if (!this.selectedLevel) {
      this.filteredUserDefinedEntities = this.userDefinedEntities.slice();
      return;
    }

    switch (this.selectedLevel) {
      case UserDefinedEntityBaseEntityLevel.GLOBAL:
        this.filteredUserDefinedEntities = this.userDefinedEntities.filter(
          (x) => x.isGlobal
        );
        return;
      case UserDefinedEntityBaseEntityLevel.THING:
        this.filteredUserDefinedEntities = this.userDefinedEntities.filter(
          (x) => !!x.thingId
        );
        return;
      case UserDefinedEntityBaseEntityLevel.PROJECT:
        this.filteredUserDefinedEntities = this.userDefinedEntities.filter(
          (x) => !!x.ownerProjectId
        );
        return;
      default:
        return;
    }
  }

  private preSelectFilterLevel(): void {
    const selectableLevels = this.filterOptions.map((x) => x.level);

    if (selectableLevels.includes(UserDefinedEntityBaseEntityLevel.PROJECT)) {
      this.selectedLevel = UserDefinedEntityBaseEntityLevel.PROJECT;
    } else if (
      selectableLevels.includes(UserDefinedEntityBaseEntityLevel.THING)
    ) {
      this.selectedLevel = UserDefinedEntityBaseEntityLevel.THING;
    } else if (
      selectableLevels.includes(UserDefinedEntityBaseEntityLevel.GLOBAL)
    ) {
      this.selectedLevel = UserDefinedEntityBaseEntityLevel.GLOBAL;
    } else {
      this.selectedLevel = null;
    }
  }
}

type FilterOption = {
  labelTk: string;
  level: UserDefinedEntityBaseEntityLevel | null;
};

export enum UserDefinedEntityBaseEntityLevel {
  GLOBAL = 'global',
  THING = 'thing',
  PROJECT = 'project'
}
