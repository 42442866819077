import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { NfcTokenToThing } from '../../classes/EntityManager/entities/NfcTokenToThing/types';
import { ThingUtils } from '../../classes/EntityManager/entities/Thing/ThingUtils';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { EntityNfcTagWidget } from '../../inputComponents/entity-nfc-tag-widget/entity-nfc-tag-widget';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { assertNotNullOrUndefined } from 'common/Asserts';

/**
 * @event thing-changed
 */
@autoinject()
export class ThingFields {
  @bindable public thing: Thing | null = null;

  @bindable public thingGroupSelectionEnabled = false;

  /**
   * if this is set, then all sub entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable public temporaryGroupName: string | null = null;

  protected nfcTagRelationInfo = ThingUtils.nfcTagRelationInfo;

  protected entityNfcTagWidget: EntityNfcTagWidget<
    NfcTokenToThing,
    Thing
  > | null = null;

  private domElement: HTMLElement;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(element: Element, permissionsService: PermissionsService) {
    this.domElement = element as HTMLElement;
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as ThingFields,
        propertyName: 'thing'
      }
    );

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as ThingFields,
        propertyName: 'thing',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  @computed(
    expression('permissionsHandle.canEditField.thingGroupId'),
    expression('thingGroupSelectionEnabled')
  )
  protected get canSelectThingGroup(): boolean {
    return (
      this.permissionsHandle.canEditField.thingGroupId &&
      this.thingGroupSelectionEnabled
    );
  }

  protected modifyThing<T extends keyof Thing>(
    propertyName: T,
    value: Thing[T]
  ): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingFields.modifyThing without a thing"
    );

    this.thing[propertyName] = value;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'thing-changed',
      detail: null
    });
  }
}
