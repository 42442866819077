import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createNfcTokenToThingEntityInfo } from 'common/Types/Entities/NfcTokenToThing/createNfcTokenToThingEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { NfcTokenToThing } from './types';

export const nfcTokenToThingEntityInfo =
  createNfcTokenToThingEntityInfo<NfcTokenToThing>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
