import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationFormSendTargetDto } from './ProcessConfigurationFormSendTargetDto';

export function createProcessConfigurationFormSendTargetEntityInfo<
  TEntity extends ProcessConfigurationFormSendTargetDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationFormSendTargetDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationFormSendTarget,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationFormSendTarget,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationFormSendTargetDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          defaultValue: null,
          ref: EntityName.User
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        sendFormsAutomaticallyAfterAppointmentHasFinished: {
          name: 'sendFormsAutomaticallyAfterAppointmentHasFinished',
          defaultValue: false
        },
        sendFormsOfAppointmentUsingExplicitButton: {
          name: 'sendFormsOfAppointmentUsingExplicitButton',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
