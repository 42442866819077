import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { PersonToPerson } from './types';

export class PersonToPersonRepository extends AppEntityRepository<EntityName.PersonToPerson> {
  /**
   * returns all personToPerson instances where the personId is part of (either as person1 or person2)
   */
  public getByPersonId(personId: string): Array<PersonToPerson> {
    return this.getAll().filter((personToPerson) => {
      return (
        personToPerson.person1Id === personId ||
        personToPerson.person2Id === personId
      );
    });
  }
}
