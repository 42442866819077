export class ProcessTaskHelper {
  private constructor() {}

  public static sort<T>(infos: Array<ProcessTaskInfo<T>>): Array<T> {
    return infos
      .slice()
      .sort((a, b) => {
        const diff = (a.order ?? 0) - (b.order ?? 0);

        if (diff === 0) {
          return a.id.localeCompare(b.id);
        }

        return diff;
      })
      .map((i) => i.processTask);
  }
}

type ProcessTaskInfo<T> = {
  id: string;
  order: number | null;
  processTask: T;
};
