import { autoinject } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { AfterPropertyChangedEvent } from '../../aureliaComponents/property-input-field/property-input-field';

@autoinject()
export class EditPropertyDialog {
  protected property: Property | null = null;

  private dialog: RecordItDialog | null = null;
  private onPropertyChanged: DialogOptions['onPropertyChanged'];

  public open(options: DialogOptions): void {
    this.property = options.property;
    this.onPropertyChanged = options.onPropertyChanged;

    this.dialog?.open();
  }

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected handleDialogClosed(): void {
    this.property = null;
  }

  protected handlePropertyChanged(evt: AfterPropertyChangedEvent): void {
    this.onPropertyChanged?.(evt.detail.property);
  }
}

interface DialogOptions {
  property: Property;
  onPropertyChanged?: (property: Property) => void;
}
