import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { NumberUtils } from 'common/Utils/NumberUtils';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  CommonBasePropertyFilter,
  FilterType,
  FilterTypeToFilter,
  PropertyFilter
} from 'common/Types/GalleryThingPictureFilter/GalleryThingPicturePropertyFilter';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { ExportableFilterProperty } from 'common/Types/DefectManagementFilter';

import { PropertyCreationBaseData } from '../EntityManager/entities/Property/types';
import { FilterBarWidget } from '../../services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService';

export class GalleryThingFilterHelper {
  public static updateProperties(
    propertyFilterWidgets: Array<FilterBarWidget>,
    currentProperties: Array<PropertyFilter>
  ): Array<PropertyFilter> {
    const properties: Array<PropertyFilter> = [];

    for (const filterWidget of propertyFilterWidgets) {
      const existingProperty = currentProperties.find((p) => {
        return GalleryThingFilterHelper.isTheSamePropertyFilter(
          {
            name: p.name,
            type: p.type
          },
          {
            type: filterWidget.propertyType,
            name: filterWidget.propertyName
          }
        );
      });

      if (existingProperty) {
        properties.push(existingProperty);
      } else {
        const filterType = filterWidget.propertyType ?? FilterType.TEXT;
        const property = (
          filterTypeToFilterConfiguration[
            filterType
          ] as FilterTypeConfiguration<any>
        ).create(filterWidget);
        properties.push(property);
      }
    }
    return properties;
  }

  public static propertiesAreEqual(
    existingProperty: PropertyCreationBaseData,
    filter: PropertyFilter
  ): boolean {
    if (!GalleryThingFilterHelper.isActiveFilter(filter)) return true;
    return (
      filterTypeToFilterConfiguration[
        filter.type
      ] as FilterTypeConfiguration<any>
    ).compare(existingProperty, filter);
  }

  public static getCorrespondingPropertyType(
    type: FilterType | null | undefined
  ): PropertyType {
    return filterTypeToFilterConfiguration[
      GalleryThingFilterHelper.getTypeOrDefault(type)
    ].correspondingPropertyType;
  }

  public static filterMatchesProperty(
    existingProperty: {
      type: PropertyType | null | undefined;
      name: string | null | undefined;
    },
    filter: {
      type: FilterType | null | undefined;
      name: string | null | undefined;
    }
  ): boolean {
    const safeFilterType =
      filterTypeToFilterConfiguration[
        GalleryThingFilterHelper.getTypeOrDefault(filter.type)
      ].correspondingPropertyType;
    const safeExistingPropertyType = PropertyHelper.getTypeOrDefault(
      existingProperty.type
    );

    return (
      (existingProperty.name ?? '') === (filter.name ?? '') &&
      safeFilterType === safeExistingPropertyType
    );
  }

  public static getProperty(
    name: string,
    type: FilterType,
    properties: Array<PropertyFilter>
  ): PropertyFilter | undefined {
    return properties.find((p) =>
      GalleryThingFilterHelper.isTheSamePropertyFilter(
        { name: p.name, type: p.type },
        { name, type }
      )
    );
  }

  public static isActiveFilter(filter: PropertyFilter): boolean {
    return (
      filterTypeToFilterConfiguration[
        filter.type
      ] as FilterTypeConfiguration<any>
    ).isActiveFilter(filter);
  }

  public static resetFilter(filter: PropertyFilter): void {
    (
      filterTypeToFilterConfiguration[
        filter.type
      ] as FilterTypeConfiguration<any>
    ).resetFilter(filter);
  }

  public static getFilterStringRepresentation(
    filter: PropertyFilter
  ): ExportableFilterProperty {
    return (
      filterTypeToFilterConfiguration[
        filter.type
      ] as FilterTypeConfiguration<any>
    ).printForExporter(filter);
  }

  public static propertyValueContainsFilterInput(
    propertyValue: string | null | undefined,
    filterInput: string | undefined | null
  ): boolean {
    if (!filterInput) return true;
    return (propertyValue || '')
      .toLocaleLowerCase()
      .includes(filterInput.toLocaleLowerCase());
  }

  private static isTheSamePropertyFilter(
    a: { type: FilterType | null | undefined; name: string | null | undefined },
    b: { type: FilterType | null | undefined; name: string | null | undefined }
  ): boolean {
    return (
      (a.name ?? '') === (b.name ?? '') &&
      GalleryThingFilterHelper.getTypeOrDefault(a.type) ===
        GalleryThingFilterHelper.getTypeOrDefault(b.type)
    );
  }

  private static getTypeOrDefault(
    type: FilterType | null | undefined
  ): FilterType {
    return type ?? FilterType.TEXT;
  }
}

/*
 * Types
 *
 */
type ValueBasedPropertyFilter = {
  name: string;
  type: FilterType;
  value: string | null;
};
type FilterTypeToFilterConfiguration = {
  [type in FilterType]: FilterTypeConfiguration<type>;
};

type FilterTypeConfiguration<TFilterType extends FilterType> = {
  isActiveFilter: (filter: FilterTypeToFilter[TFilterType]) => boolean;
  compare: (
    existingProperty: PropertyCreationBaseData,
    filter: FilterTypeToFilter[TFilterType]
  ) => boolean;
  create: (filterConfig: FilterBarWidget) => FilterTypeToFilter[TFilterType];
  correspondingPropertyType: PropertyType;
  isFilterExclusive: boolean;
  printForExporter: (
    filter: FilterTypeToFilter[TFilterType]
  ) => ExportableFilterProperty;
  resetFilter: (filter: FilterTypeToFilter[TFilterType]) => void;
};

/*
 * FilterType config
 *
 */

export const filterTypeToFilterConfiguration: FilterTypeToFilterConfiguration =
  {
    [FilterType.RANGE]: {
      isActiveFilter: isActiveRangeFilter,
      compare: compareByRange,
      create: createRangePropertyFilter,
      correspondingPropertyType: PropertyType.NUMBER,
      isFilterExclusive: true,
      printForExporter: printRangeFilter,
      resetFilter: resetRangeFilter
    },
    [FilterType.TEXT]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByTextValue,
      create: createTextPropertyFilter,
      correspondingPropertyType: PropertyType.TEXT,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.NUMBER]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByNumberValue,
      create: createNumberPropertyFilter,
      correspondingPropertyType: PropertyType.NUMBER,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.RADIOBUTTON]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByExactValue,
      create: createRadiobuttonPropertyFilter,
      correspondingPropertyType: PropertyType.RADIOBUTTON,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.DROPDOWN]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByExactValue,
      create: createDropdownPropertyFilter,
      correspondingPropertyType: PropertyType.DROPDOWN,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.MULTI_DROPDOWN]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByExactValue,
      create: createMultidropdownPropertyFilter,
      correspondingPropertyType: PropertyType.MULTI_DROPDOWN,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.CHECKBOX]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareCheckboxValue,
      create: createCheckboxPropertyFilter,
      correspondingPropertyType: PropertyType.CHECKBOX,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    },
    [FilterType.PRIORITY]: {
      isActiveFilter: isActiveValueFilter,
      compare: compareByExactValue,
      create: createPriorityPropertyFilter,
      correspondingPropertyType: PropertyType.PRIORITY,
      isFilterExclusive: false,
      printForExporter: printValueFilter,
      resetFilter: resetValueFilter
    }
  };

/*
 * Create functions
 *
 */

function createRangePropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.RANGE] {
  return {
    name: filterConfig.propertyName ?? '',
    type: FilterType.RANGE,
    rangeMin: null,
    rangeMax: null
  };
}

function createTextPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.TEXT] {
  return {
    type: FilterType.TEXT,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createNumberPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.NUMBER] {
  return {
    type: FilterType.NUMBER,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createDropdownPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.DROPDOWN] {
  return {
    type: FilterType.DROPDOWN,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createPriorityPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.PRIORITY] {
  return {
    type: FilterType.PRIORITY,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createMultidropdownPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.MULTI_DROPDOWN] {
  return {
    type: FilterType.MULTI_DROPDOWN,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createRadiobuttonPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.RADIOBUTTON] {
  return {
    type: FilterType.RADIOBUTTON,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createCheckboxPropertyFilter(
  filterConfig: FilterBarWidget
): FilterTypeToFilter[FilterType.CHECKBOX] {
  return {
    type: FilterType.CHECKBOX,
    value: null,
    ...createCommonBasedPropertyFilter(filterConfig)
  };
}

function createCommonBasedPropertyFilter(
  filterConfig: FilterBarWidget
): CommonBasePropertyFilter {
  return {
    name: filterConfig.propertyName ?? '',
    options: filterConfig.propertyOptions || undefined,
    choices: filterConfig.propertyChoices || undefined
  };
}

/*
 * Compare functions
 *
 */

function compareByTextValue(
  existingProperty: PropertyCreationBaseData,
  filter: FilterTypeToFilter[FilterType.TEXT]
): boolean {
  return GalleryThingFilterHelper.propertyValueContainsFilterInput(
    existingProperty.value,
    filter.value
  );
}

function compareByNumberValue(
  existingProperty: PropertyCreationBaseData,
  filter: FilterTypeToFilter[FilterType.NUMBER]
): boolean {
  if (existingProperty.value == null) return false;
  return Number(filter.value) === Number(existingProperty.value);
}

function compareByExactValue(
  existingProperty: PropertyCreationBaseData,
  filter: ValueBasedPropertyFilter
): boolean {
  return existingProperty.value === filter.value;
}

function compareCheckboxValue(
  existingProperty: PropertyCreationBaseData,
  filter: FilterTypeToFilter[FilterType.CHECKBOX]
): boolean {
  return compareByExactValue(
    {
      ...existingProperty,
      value: existingProperty.value == null ? 'false' : existingProperty.value
    },
    filter
  );
}

function compareByRange(
  existingProperty: PropertyCreationBaseData,
  filter: FilterTypeToFilter[FilterType.RANGE]
): boolean {
  if (existingProperty.value == null) return false;
  return NumberUtils.isInRange({
    value: Number(existingProperty.value),
    max: filter.rangeMax,
    min: filter.rangeMin
  });
}

/*
 * isActive functions
 *
 */

function isActiveRangeFilter(f: FilterTypeToFilter[FilterType.RANGE]): boolean {
  return f.rangeMin !== null || f.rangeMax !== null;
}

function isActiveValueFilter(f: ValueBasedPropertyFilter): boolean {
  return !StringUtils.isNullOrEmpty(f.value);
}

/*
 * reset functions
 *
 */

function resetRangeFilter(f: FilterTypeToFilter[FilterType.RANGE]): void {
  f.rangeMax = null;
  f.rangeMin = null;
}

function resetValueFilter(f: ValueBasedPropertyFilter): void {
  f.value = null;
}

/*
 * print functions
 *
 */

function printRangeFilter(
  f: FilterTypeToFilter[FilterType.RANGE]
): ExportableFilterProperty {
  let rangeText = '';
  if (f.rangeMin && f.rangeMax) {
    rangeText = `${f.rangeMin} - ${f.rangeMax}`;
  }
  if (f.rangeMin && !f.rangeMax) {
    rangeText = `> ${f.rangeMin}`;
  }
  if (!f.rangeMin && f.rangeMax) {
    rangeText = `< ${f.rangeMax}`;
  }
  return { name: f.name, value: rangeText };
}

function printValueFilter(
  f: ValueBasedPropertyFilter
): ExportableFilterProperty {
  return { name: f.name, value: f.value ?? '' };
}
