import { bindable, computedFrom } from 'aurelia-framework';
import { StructurePictureAreaUtils } from '../../classes/EntityManager/entities/StructurePictureArea/StructurePictureAreaUtils';

/**
 * This component is only the preview square with the label and other necessary things.
 *
 * It doesn't handle the size and positioning.
 * It's only it's own component, so the same style/layout can be shared between the structure-picture-area-preview and the repositionable-element in the editor.
 */
export class StructurePictureAreaBox {
  @bindable()
  public path: Array<string> | null = null;

  @bindable()
  public index: number | null = null;

  @bindable()
  public active: boolean = false;

  @computedFrom('path', 'index')
  protected get label(): string {
    if (this.path?.length) {
      return StructurePictureAreaUtils.formatPath(this.path);
    }

    if (this.index != null) {
      return `${this.index + 1}`;
    }

    return '';
  }
}
