import { DeviceInfoHelper } from '../DeviceInfoHelper';
import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../PropertyBinder/PropertyBinder';
import { TestingHelper } from '../TestingHelper';
import { Disposable } from '../Utils/DisposableContainer';
import {
  AbstractNfcHelperStrategy,
  UidScanCallback
} from './AbstractNfcHelperStrategy';
import { AndroidNfcHelperStrategy } from './AndroidNfcHelperStrategy';
import { IOSNfcHelperStrategy } from './IOSNfcHelperStrategy';
import { UfrNfcHelperStrategy } from './UfrNfcHelperStrategy';

export class NFCHelper {
  private static strategy: AbstractNfcHelperStrategy | null = null;

  private static initialized = false;

  private static propertyBinder = new PropertyBinder<PropertyBinderConfig>();

  public static async init(): Promise<void> {
    await DeviceInfoHelper.waitForDeviceReady();
    this.initialized = true;

    if (DeviceInfoHelper.isAndroidDevice()) {
      this.strategy = new AndroidNfcHelperStrategy();
    } else if (DeviceInfoHelper.isIOSDevice()) {
      this.strategy = new IOSNfcHelperStrategy();
    } else if (window.uFR_Request) {
      this.strategy = new UfrNfcHelperStrategy();
    }

    setInterval(this.updateNfcStatus.bind(this), 2000);
    this.updateNfcStatus();
  }

  public static registerBinding<
    TName extends PropertyBinderName<PropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<PropertyBinderConfig, TName>
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public static async isNfcEnabled(): Promise<boolean> {
    this.assertInitialized();
    return this.strategy?.isEnabled() ?? false;
  }

  public static scanSingleUID(callback: UidScanCallback): Disposable {
    this.assertInitialized();
    if (!this.strategy) throw new Error('no nfc strategy available');

    return this.strategy?.scanSingleUID(callback);
  }

  private static assertInitialized(): void {
    if (!this.initialized) throw new Error('NFC Helper is not initialized');
  }

  private static updateNfcStatus(): void {
    if (!this.initialized) return;
    void this.isNfcEnabled().then((enabled) =>
      this.propertyBinder.setValue('nfcEnabled', enabled)
    );
  }
}

TestingHelper.NFCHelper = NFCHelper;

type PropertyBinderConfig = {
  nfcEnabled: boolean;
};
