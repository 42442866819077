import { inject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SocketService } from '../../services/SocketService';

import { Utils } from '../../classes/Utils/Utils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(I18N, SocketService)
export class CreateUserDialog {
  /** @type {import('../record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  _disableClosingDialog = false;
  _disabled = false;
  _valid = false;
  _loading = false;

  /** @type {string} */
  @observable _username;
  /** @type {string} */
  @observable _email;
  /** @type {string} */
  @observable _password;
  /** @type {string} */
  @observable _passwordConfirmation;

  _emailInputErrorText = '';
  _passwordInputErrorText = '';
  _passwordConfirmationInputErrorText = '';

  _errorTextKey = '';

  _showPasswords = false;

  /** @type {import('../../classes/EntityManager/entities/User/types').User} */
  _createdUser = null;

  /**
   * @param {I18N} i18n
   * @param {SocketService} socketService
   */
  constructor(i18n, socketService) {
    this._i18n = i18n;
    this._socketService = socketService;

    this._username = '';
    this._email = '';
    this._password = '';
    this._passwordConfirmation = '';
  }

  /**
   * @param {TCreateUserDialogOpenOptions} options
   */
  open(options) {
    this._onDialogClosed = options.onDialogClosed;
    if (this._dialog) this._dialog.open();
  }

  close() {
    if (this._dialog) this._dialog.close();
  }

  _handleDialogClosed() {
    const onDialogClosed = this._onDialogClosed; // caching so we can reset everything before calling the callback
    const createdUser = this._createdUser;

    this._onDialogClosed = null;

    this._loading = false;
    this._disableClosingDialog = false;
    this._disabled = false;
    this._valid = false;

    this._username = '';
    this._email = '';
    this._password = '';
    this._passwordConfirmation = '';

    this._emailInputErrorText = '';
    this._passwordInputErrorText = '';
    this._passwordConfirmationInputErrorText = '';

    this._errorTextKey = '';

    this._createdUser = null;

    onDialogClosed && onDialogClosed(createdUser);
  }

  _handleCloseDialogClick() {
    this.close();
  }

  _handleCreateUserClick() {
    this._loading = true;
    this._disableClosingDialog = true;

    this._socketService.createUser(
      this._username,
      this._email,
      this._password,
      (data) => {
        this._loading = false;
        this._disableClosingDialog = false;
        if (!data.success) {
          this._errorTextKey = `serverResponses.${data.status}`;
        } else {
          this._createdUser = data.user;
          this.close();
        }
      }
    );
  }

  _handleToggleShowPasswordsClick() {
    this._showPasswords = !this._showPasswords;
  }

  _usernameChanged() {
    this._validateFields();
  }

  _emailChanged() {
    this._validateFields();
  }

  _passwordChanged() {
    this._validateFields();
  }

  _passwordConfirmationChanged() {
    this._validateFields();
  }

  _validateFields() {
    let valid = true;
    valid = !!this._username && valid;
    valid = this._emailFieldIsValid() && valid;
    valid = this._passwordFieldsAreValid() && valid;
    this._valid = valid;
  }

  _emailFieldIsValid() {
    if (this._email) {
      const emailIsValid = Utils.validateEmail(this._email);
      if (!emailIsValid) {
        this._emailInputErrorText = this._i18n.tr(
          'dialogs.createUserDialog.noValidEmailAddressInputErrorMessage'
        );
      } else {
        this._emailInputErrorText = '';
      }
      return emailIsValid;
    }
    this._emailInputErrorText = '';
    return false;
  }

  _passwordFieldsAreValid() {
    if (this._password || this._passwordConfirmation) {
      if (!Utils.validatePassword(this._password)) {
        this._passwordInputErrorText = this._i18n.tr(
          'dialogs.createUserDialog.noValidPasswordInputErrorMessage'
        );
        return false;
      } else {
        this._passwordInputErrorText = '';
      }
      if (
        this._passwordConfirmation &&
        this._password !== this._passwordConfirmation
      ) {
        this._passwordConfirmationInputErrorText = this._i18n.tr(
          'dialogs.createUserDialog.noMatchingPasswordsInputErrorMessage'
        );
        return false;
      } else {
        this._passwordConfirmationInputErrorText = '';
      }
      if (this._password && this._passwordConfirmation) {
        return true;
      }
    }
    return false;
  }

  /**
   * @param {TCreateUserDialogOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TCreateUserDialogOpenOptions
 * @property {function(import('../../classes/EntityManager/entities/User/types').User|null)|null} [onDialogClosed] - function parameter is the created user or null
 */
