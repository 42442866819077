import { computedFrom } from 'aurelia-framework';
import { UserDefinedEntity } from '../../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityAdapter } from '../EntityAdapter/UserDefinedEntityAdapter/UserDefinedEntityAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class UserDefinedEntityPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<UserDefinedEntityAdapter>,
  UserDefinedEntity
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }
}
