import { bindable, containerless } from 'aurelia-framework';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';

@containerless()
export class ShowProcessAppointmentPositionsWidget {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public expanded: boolean = false;

  private positionCount: number | null = null;

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }
}
