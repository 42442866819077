export class ThingGroupHelper {
  /**
   * for better understanding of the dependencies + directly using it in the view (so it will update correctly contrary to the version where you only pass the thingGroup)
   *
   * @param {string|null} streetName
   * @param {string|null} zip
   * @param {string|null} municipality
   * @returns {string}
   */
  static getThingGroupAddressString(streetName, zip, municipality) {
    const parts = [];

    if (streetName) {
      parts.push(streetName);
    }

    const zipAndMunicipality = [];

    if (zip) {
      zipAndMunicipality.push(zip.toString());
    }

    if (municipality) {
      zipAndMunicipality.push(municipality);
    }

    if (zipAndMunicipality.length) {
      parts.push(zipAndMunicipality.join(' '));
    }

    return parts.join(', ');
  }
}
