import { StructureTemplateProjectType } from 'common/Types/Entities/Project/ProjectDto';
import {
  projectTypeToStructureTemplateTypeMap,
  StructureTemplateStatus
} from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { StructureTemplate } from './types';

export class StructureTemplateRepository extends AppEntityRepository<EntityName.StructureTemplate> {
  public getByOldTemplateName(
    oldTemplateName: string
  ): StructureTemplate | null {
    return (
      this.getAll().find((t) => t.oldTemplateName === oldTemplateName) ?? null
    );
  }

  public getActiveTemplatesByProjectType(
    type: StructureTemplateProjectType
  ): Array<StructureTemplate> {
    const structureTemplateType = projectTypeToStructureTemplateTypeMap[type];
    return this.getAll().filter(
      (t) =>
        (t.status === StructureTemplateStatus.ACTIVE ||
          t.status === StructureTemplateStatus.PROVISIONALLY_ACTIVE) &&
        t.type === structureTemplateType
    );
  }
}
