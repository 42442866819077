import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createPersonToPersonEntityInfo } from 'common/Types/Entities/PersonToPerson/createPersonToPersonEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { PersonToPerson } from './types';

export const personToPersonEntityInfo =
  createPersonToPersonEntityInfo<PersonToPerson>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
