import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import { DrawingHistoryManager } from '../../sketcher-overlay/DrawingHistoryManager';
import { SketcherOverlayToolBar } from '../sketcher-overlay-tool-bar';

export class SketcherOverlayToolBarHistoryButtons {
  @bindable()
  public historyManager: DrawingHistoryManager | null = null;

  @bindable()
  public toolBar: SketcherOverlayToolBar | null = null;

  private isAttached: boolean = false;
  private canUndo: boolean = false;
  private canRedo: boolean = false;

  protected attached(): void {
    this.isAttached = true;
    this.updateHistoryManagerEventListeners();
  }

  protected detached(): void {
    this.isAttached = false;
    this.historyManager?.removeEventListeners(this);
  }

  private historyManagerChanged(
    newValue: DrawingHistoryManager | null,
    oldValue?: DrawingHistoryManager | null
  ): void {
    oldValue?.removeEventListeners(this);

    if (this.isAttached) {
      this.updateHistoryManagerEventListeners();
    }
  }

  private updateHistoryManagerEventListeners(): void {
    if (this.historyManager) {
      this.historyManager.removeEventListeners(this);
      this.historyManager.addChangedListener(this, () => {
        this.updateHistoryManagerData();
      });
    }

    this.updateHistoryManagerData();
  }

  private updateHistoryManagerData(): void {
    this.canUndo = this.historyManager?.canUndo() ?? false;
    this.canRedo = this.historyManager?.canRedo() ?? false;
  }

  private handleUndoButtonClick(): void {
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlayToolBarHistoryButtons.handleUndoButtonClick without a historyManager"
    );
    assertNotNullOrUndefined(
      this.toolBar,
      "can't SketcherOverlayToolBarHistoryButtons.handleUndoButtonClick without a toolBar"
    );
    this.toolBar.cancelTool();
    this.historyManager.undo();
  }

  private handleRedoButtonClick(): void {
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlayToolBarHistoryButtons.handleUndoButtonClick without a historyManager"
    );
    assertNotNullOrUndefined(
      this.toolBar,
      "can't SketcherOverlayToolBarHistoryButtons.handleUndoButtonClick without a toolBar"
    );
    this.toolBar.cancelTool();
    this.historyManager.redo();
  }
}
