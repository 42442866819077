import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationUtils } from '../../classes/EntityManager/entities/ProcessConfiguration/ProcessConfigurationUtils';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-process-configuration-clicked
 */
@autoinject()
export class ProcessConfigurationListItem {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private panelOpened: boolean = false;

  protected listItemElement: HTMLElement | null = null;

  constructor(
    private readonly element: Element,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationListItem,
        propertyName: 'processConfiguration'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEnterProcessConfigurationClick(): void {
    if (this.processConfiguration) {
      ProcessConfigurationUtils.navigateToEditProcessConfigurationStepsPage(
        this.router,
        this.processConfiguration.id
      );
    }
  }

  protected handleEditDevicesClick(): void {
    if (this.processConfiguration) {
      ProcessConfigurationUtils.navigateToEditProcessConfigurationDevicesPage(
        this.router,
        this.processConfiguration.id
      );
    }
  }

  protected handleEditDeviceExportsClick(): void {
    if (this.processConfiguration) {
      ProcessConfigurationUtils.navigateToEditProcessConfigurationDeviceExportsPage(
        this.router,
        this.processConfiguration.id
      );
    }
  }

  protected handleEditFormsClick(): void {
    if (this.processConfiguration) {
      ProcessConfigurationUtils.navigateToEditProcessConfigurationFormsPage(
        this.router,
        this.processConfiguration.id
      );
    }
  }

  protected handleEditProcessConfigurationClick(): void {
    if (!this.element) {
      return;
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'edit-process-configuration-clicked',
      detail: null
    });
  }

  protected handleDeleteProcessConfigurationClick(): void {
    const processConfiguration = this.processConfiguration;
    assertNotNullOrUndefined(
      processConfiguration,
      "can't ProcessConfigurationListItem.handleDeleteProcessConfigurationClick without processConfiguration"
    );

    void Dialogs.deleteEntityDialog(processConfiguration).then(() => {
      this.entityManager.processConfigurationRepository.delete(
        processConfiguration
      );
    });
  }
}
