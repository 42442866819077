import { BindingEngine } from 'aurelia-binding';
import { autoinject } from 'aurelia-dependency-injection';
import { distinctUntilChanged, Observable, shareReplay } from 'rxjs';

@autoinject()
export class RxjsService {
  constructor(private readonly bindingEngine: BindingEngine) {}

  /**
   * Similar to fromExpression, but it only supports public properties.
   * This allows for better typing.
   */
  public fromProperty<
    T extends Record<string, any>,
    TPropertyName extends keyof T
  >({
    context,
    propertyName
  }: {
    context: T;
    propertyName: TPropertyName;
  }): Observable<T[TPropertyName]> {
    return this.fromExpression<T[TPropertyName]>({
      context,
      expression: String(propertyName)
    });
  }

  /**
   * Creates an observable which subscribe to the given expression.
   * The observable also immediately emits the initial value of the expression, not only the changes.
   */
  public fromExpression<T>({
    context,
    expression
  }: {
    context: Record<string, any>;
    expression: string;
  }): Observable<T> {
    return new Observable<T>((subscriber) => {
      const e = this.bindingEngine.parseExpression(expression);
      const scope = {
        bindingContext: context,
        overrideContext: { bindingContext: context }
      };
      subscriber.next(e.evaluate(scope));

      const observer = this.bindingEngine.expressionObserver(
        context,
        expression
      );
      const expressionObserverDisposable = observer.subscribe((value) => {
        subscriber.next(value);
      });

      return () => {
        expressionObserverDisposable.dispose();
      };
    }).pipe(
      distinctUntilChanged(),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );
  }
}
