import { inject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @slot default - slot for more buttons
 */
@inject(Element)
export class UltraRapidFireWidgetOverlay {
  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._domElement = element;
  }

  /** @type {TUltraRapidFireWidgetOverlayConfiguration} */
  @bindable configuration = {
    widgets: []
  };

  /*
   * This is needed by some overlay widgets
   */
  /** @type {import('./ultra-rapid-fire-widget').SavePictureCallbackAnyOptions|null} */
  @bindable data = null;

  /**
   * @param {import('./urfw-categorized-tags-button/urfw-categorized-tags-button').TagIdsChangedEvent} evt
   */
  _handleTagIdsChanged(evt) {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'tag-ids-changed',
      detail: evt.detail
    });
  }

  /**
   * @param {import('../tags-widget-list/tags-widget-list').TagDeletedEvent} evt
   */
  _handleTagDeleted(evt) {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'tag-deleted',
      detail: evt.detail
    });
  }
}

/**
 * @typedef {Object} TUltraRapidFireWidgetOverlayConfiguration
 * @property {Array<import('./ultra-rapid-fire-widget-overlay-widget').WidgetConfiguration>} widgets
 */
