import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserRole } from '../../classes/EntityManager/entities/UserRole/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class UserGroupUserRoleWidget {
  @bindable()
  public userRole: UserRole | null = null;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserRole];

  constructor(
    private readonly entityManager: AppEntityManager,

    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        context: this as UserGroupUserRoleWidget,
        entityName: EntityName.UserRole,
        propertyName: 'userRole'
      }
    );
  }

  protected handleCanManageUserRoleToUsersChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.userRole,
      "can't UserGroupUserRoleWidget.handleCanManageUserRoleToUsersChanged without userRole"
    );

    this.userRole.canCreateUserRoleToUsers = event.detail.checked;
    this.userRole.canUpdateUserRoleToUsers = event.detail.checked;
    this.userRole.canDeleteUserRoleToUsers = event.detail.checked;
    this.entityManager.userRoleRepository.update(this.userRole);
  }

  protected handleUserRoleChanged(): void {
    assertNotNullOrUndefined(
      this.userRole,
      "can't UserGroupUserRoleWidget.handleUserRoleChanged without userRole"
    );
    this.entityManager.userRoleRepository.update(this.userRole);
  }

  protected handleDeleteClick(): void {
    const userRole = this.userRole;
    assertNotNullOrUndefined(
      userRole,
      "can't UserGroupUserRoleWidget.handleDeleteClick without userRole"
    );

    void Dialogs.deleteEntityDialog(
      userRole,
      userRole.name || EntityName.UserRole
    ).then(() => {
      this.entityManager.userRoleRepository.delete(userRole);
    });
  }

  @computed(
    expression('userRole.canCreateUserRoleToUsers'),
    expression('userRole.canUpdateUserRoleToUsers'),
    expression('userRole.canDeleteUserRoleToUsers')
  )
  protected get canManageUserRoleToUsers(): boolean {
    if (!this.userRole) {
      return false;
    }

    return (
      this.userRole.canCreateUserRoleToUsers ||
      this.userRole.canUpdateUserRoleToUsers ||
      this.userRole.canDeleteUserRoleToUsers
    );
  }
}
