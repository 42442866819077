/**
 * With this class you can modify an object and then check if it actually got modified.
 * If the object already has the same value (even though it might be a different instance), it will not get modified.
 *
 * Useful if you want to update an existing object and later on execute a piece of code only if the object has actually been modified
 */
export class ObjectEditor<T extends Record<string, unknown>> {
  private modified: boolean = false;

  constructor(private readonly obj: T) {}

  public objectIsModified(): boolean {
    return this.modified;
  }

  /**
   * @param key
   * @param value - the value must be JSON serializable
   */
  public setValue<TKey extends keyof T>(
    key: TKey,
    value: Exclude<T[TKey], Promise<any>>
  ): void {
    if (!this.valuesAreEqual(this.obj[key], value)) {
      this.obj[key] = value;
      this.modified = true;
    }
  }

  private valuesAreEqual(a: any, b: any): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
