import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { UserRole } from '../../../classes/EntityManager/entities/UserRole/types';
import { UserRoleToUser } from '../../../classes/EntityManager/entities/UserRoleToUser/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class UserUserRolesWidgetRole {
  @bindable()
  public userRoleToUser: UserRoleToUser | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserRoleToUser];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.UserRoleToUser,
        context: this as UserUserRolesWidgetRole,
        propertyName: 'userRoleToUser'
      }
    );
  }

  @computed(
    expression('userRoleToUser.userRoleId'),
    model(EntityName.UserRoleToUser)
  )
  protected get userRole(): UserRole | null {
    if (!this.userRoleToUser) {
      return null;
    }

    return this.entityManager.userRoleRepository.getById(
      this.userRoleToUser.userRoleId
    );
  }

  protected handleDeleteClick(): void {
    const userRoleToUser = this.userRoleToUser;
    assertNotNullOrUndefined(
      userRoleToUser,
      "can't UserUserRolesWidgetRole.handleDeleteClick without userRoleToUser"
    );

    void Dialogs.deleteEntityDialog(
      userRoleToUser,
      this.userRole?.name ?? undefined
    ).then(() => {
      this.entityManager.userRoleToUserRepository.delete(userRoleToUser);
    });
  }
}
