import { autoinject, bindable, containerless } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';

@containerless()
@autoinject()
export class ShowProcessAppointmentWorkNoteWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public expanded: boolean = false;

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ShowProcessAppointmentWorkNoteWidget,
        propertyName: 'processTaskAppointment'
      });
  }

  protected handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }

  protected handleAppointmentChanged(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ShowProcessAppointmentWorkNoteWidget.handleAppointmentChanged without processTask"
    );
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ShowProcessAppointmentWorkNoteWidget.handleAppointmentChanged without processTaskAppointment"
    );

    this.entityManager.processTaskAppointmentRepository.update(
      this.processTaskAppointment
    );
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.processTaskAppointment,
      property: null,
      displayNameAtLogTime: this.processTaskAppointment.name
    });
  }
}
