import { computedFrom } from 'aurelia-framework';
import { Report } from '../../../classes/EntityManager/entities/Report/types';
import { ReportAdapter } from '../EntityAdapter/ReportAdapter/ReportAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ReportPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ReportAdapter>,
  Report
> {
  @computedFrom('combinedRevision')
  public get canSignReport(): boolean {
    return this.checkAdapterSpecificPermissionByName('canSignReport');
  }
}
