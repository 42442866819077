import { PathUtils } from 'common/Utils/PathUtils/PathUtils';
import { CustomDirectoryEntry } from '../../entries/CustomDirectoryEntry';
import { CapacitorFileEntry } from './CapacitorFileEntry';

export class CapacitorDirectoryEntry extends CustomDirectoryEntry {
  constructor(private readonly directoryEntry: DirectoryEntry) {
    super();
  }

  /**
   * the absolute path to the directory
   */
  public get path(): string {
    return this.directoryEntry.fullPath;
  }

  public get name(): string {
    return this.directoryEntry.name;
  }

  public get nativeUrl(): string {
    return this.directoryEntry.nativeURL;
  }

  public async getFile(fileNameOrPath: string): Promise<CapacitorFileEntry> {
    return new Promise((res, rej) => {
      this.directoryEntry.getFile(
        fileNameOrPath,
        undefined,
        (fileEntry) => {
          res(new CapacitorFileEntry(fileEntry));
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  public async getFilesShallow(): Promise<Array<CapacitorFileEntry>> {
    return this.readDirectoryShallow((cordovaEntry) => {
      if (!cordovaEntry.isFile) {
        return null;
      }

      return new CapacitorFileEntry(cordovaEntry as FileEntry);
    });
  }

  public async getDirectoriesShallow(): Promise<
    Array<CapacitorDirectoryEntry>
  > {
    return this.readDirectoryShallow((cordovaEntry) => {
      if (!cordovaEntry.isDirectory) {
        return null;
      }

      return new CapacitorDirectoryEntry(cordovaEntry as DirectoryEntry);
    });
  }

  /**
   * Returns a file for the given name/path.
   * If the file or directories leading to it don't exist, they will be created
   */
  public async ensureFile(fileNameOrPath: string): Promise<CapacitorFileEntry> {
    const details = PathUtils.getPathDetails(fileNameOrPath);
    const directoryPath = details.pathWithoutBaseName;

    let parentDirectory;
    if (directoryPath) {
      // directoryPath will be empty if there was only a file name
      parentDirectory = await this.ensureDirectory(directoryPath);
    } else {
      parentDirectory = this;
    }

    return parentDirectory.ensureDirectDescendantFile(details.baseName);
  }

  public async ensureDirectory(
    directoryNameOrPath: string
  ): Promise<CapacitorDirectoryEntry> {
    const directoryNames =
      PathUtils.getPathDetails(directoryNameOrPath).pathElements;

    let dirEntry: CapacitorDirectoryEntry = this;
    for (const directoryName of directoryNames) {
      dirEntry = await dirEntry.ensureDirectDescendantDirectory(directoryName);
    }

    return dirEntry;
  }

  public delete(): Promise<void> {
    return new Promise((res, rej) => {
      this.directoryEntry.remove(
        () => {
          res();
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  public getCordovaDirectoryEntry(): DirectoryEntry {
    return this.directoryEntry;
  }

  /**
   * contrary to ensureFile, this will only ensure a file directly in the directory
   */
  private ensureDirectDescendantFile(
    fileName: string
  ): Promise<CapacitorFileEntry> {
    return new Promise((res, rej) => {
      this.directoryEntry.getFile(
        fileName,
        { create: true },
        (entry) => {
          res(new CapacitorFileEntry(entry));
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  /**
   * contrary to ensureDirectory, this will only ensure a directory directly in the directory
   */
  private ensureDirectDescendantDirectory(
    directoryName: string
  ): Promise<CapacitorDirectoryEntry> {
    return new Promise((res, rej) => {
      this.directoryEntry.getDirectory(
        directoryName,
        { create: true },
        (entry) => {
          res(new CapacitorDirectoryEntry(entry));
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  private readDirectoryShallow<
    T extends CapacitorDirectoryEntry | CapacitorFileEntry
  >(convertCordovaEntry: (cordovaEntry: Entry) => T | null): Promise<Array<T>> {
    const directoryReader = this.directoryEntry.createReader();

    return new Promise((res, rej) => {
      directoryReader.readEntries(
        (entries) => {
          const capacitorEntries = entries
            .map((cordovaEntry) => convertCordovaEntry(cordovaEntry))
            .filter((capacitorEntry): capacitorEntry is T => !!capacitorEntry);
          res(capacitorEntries);
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }
}
