import {
  ExpressionEditorScopeCreationUtils,
  ValueType
} from '../../ExpressionEditorScopeCreationUtils';

export class OperationsFieldConfigs {
  public static thingGroupFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      name: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'name',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.name'
      }),
      streetName: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'streetName',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.streetName'
      }),
      zip: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'zip',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.zip'
      }),
      municipality: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'municipality',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.municipality'
      }),
      fullAddress: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'fullAddress',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.fullAddress'
      }),
      contactPersonName: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'contactPersonName',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.contactPersonName'
      }),
      ownerPersonName: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'ownerPersonName',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.ownerPersonName'
      }),
      note: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'note',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.thingGroup.note'
      })
    });

  public static processTaskGroupFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      contactPersonName: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'contactPersonName',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTaskGroup.contactPersonName'
      }),
      offerReceiverPersonName:
        ExpressionEditorScopeCreationUtils.createFieldConfig({
          name: 'offerReceiverPersonName',
          valueType: ValueType.STRING,
          nullable: false,
          descriptionTk:
            'expressionEditorInputDescription.operations.processTaskGroup.offerReceiverPersonName'
        }),
      invoiceReceiverPersonName:
        ExpressionEditorScopeCreationUtils.createFieldConfig({
          name: 'invoiceReceiverPersonName',
          valueType: ValueType.STRING,
          nullable: false,
          descriptionTk:
            'expressionEditorInputDescription.operations.processTaskGroup.invoiceReceiverPersonName'
        }),
      note: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'note',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTaskGroup.note'
      }),
      officeNote: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'officeNote',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTaskGroup.officeNote'
      }),
      referenceCode: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'referenceCode',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTaskGroup.referenceCode'
      })
    });

  public static processTaskFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      name: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'name',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTask.name'
      }),
      description: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'description',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTask.description'
      }),
      note: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'note',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTask.note'
      }),
      thingName: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thingName',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTask.thingName'
      }),
      thingMainContactPerson:
        ExpressionEditorScopeCreationUtils.createFieldConfig({
          name: 'thingMainContactPerson',
          valueType: ValueType.STRING,
          nullable: true,
          descriptionTk:
            'expressionEditorInputDescription.operations.processTask.thingMainContactPerson'
        }),
      thingDescription: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thingDescription',
        valueType: ValueType.STRING,
        nullable: true,
        descriptionTk:
          'expressionEditorInputDescription.operations.processTask.thingDescription'
      })
    });

  public static appointmentFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      name: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'name',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk: 'modelProperties.name'
      }),

      note: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'note',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk: 'modelProperties.note'
      }),
      workNote: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'workNote',
        valueType: ValueType.STRING,
        nullable: false,
        descriptionTk: 'modelProperties.workNote'
      })
    });

  public static processTaskGroupRequestFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      thingGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thingGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.thingGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.thingGroup'
      }),
      processTaskGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'processTaskGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.processTaskGroup'
      }),
      firstProcessTask: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'firstProcessTask',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskFieldConfigs,
        nullable: true,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.firstProcessTask'
      })
    });

  public static processTaskRequestFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      thingGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thingGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.thingGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.thingGroup'
      }),
      processTaskGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'processTaskGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.processTaskGroup'
      }),
      processTask: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'processTask',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.currentProcessTask'
      })
    });

  public static appointmentRequestFieldConfigs =
    ExpressionEditorScopeCreationUtils.createFieldConfigs({
      thingGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'thingGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.thingGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.thingGroup'
      }),
      processTaskGroup: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'processTaskGroup',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskGroupFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.processTaskGroup'
      }),
      processTask: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'processTask',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.processTaskFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.currentProcessTask'
      }),
      appointment: ExpressionEditorScopeCreationUtils.createFieldConfig({
        name: 'appointment',
        valueType: ValueType.NESTED,
        fieldConfigs: OperationsFieldConfigs.appointmentFieldConfigs,
        nullable: false,
        descriptionTk:
          'expressionEditorInputDescription.operations.topLevelFields.currentAppointment'
      })
    });
}
