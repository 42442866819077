import { autoinject } from 'aurelia-framework';
import { EntityInfoPathHandler } from '@record-it-npm/synchro-common';
import { ReferenceInfo } from '@record-it-npm/synchro-client';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { RecurringCalendarEntry } from '../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntry';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { ProcessTaskAppointmentToUser } from '../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { EntityName } from '../classes/EntityManager/entities/types';
import { ProcessTaskLoggingService } from './ProcessTaskLoggingService';
import { ProcessTask } from '../classes/EntityManager/entities/ProcessTask/types';
import { Property } from '../classes/EntityManager/entities/Property/types';
import { BaseEntity } from '../classes/EntityManager/entities/BaseEntity';

/**
 * When recurring appointments are displayed in the user calendar, the user should be able to click
 * on the calendar entries. When they do, a temporary process task appointment is created in the background. In case
 * they exit without changing anything, the temporary entity is deleted, otherwise it is persisted.
 */
@autoinject()
export class TemporaryAppointmentService {
  private static TEMPORARY_GROUP_NAME = 'TemporaryAppointmentService';
  private readonly subscriptionManager: SubscriptionManager;

  /**
   * If defined, log that an appointment has been opened by the person represented by the person id
   * as soon as an appointment is created.
   */
  private logOpenedByPersonId: string | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * Persist if changes to the ProcessTaskAppointment / ProcessTaskAppointmentToUser entities occur.
   */
  public startPersistingOnChanges(logOpenedByPersonId?: string | null): void {
    this.logOpenedByPersonId = logOpenedByPersonId ?? null;
    this.subscriptionManager.addDisposable(
      this.entityManager.processTaskAppointmentToUserRepository.registerHooks({
        afterEntityUpdated: this.onAppointmentToUserEdited.bind(this)
      }),
      this.entityManager.processTaskAppointmentRepository.registerHooks({
        afterEntityUpdated: this.onAppointmentEdited.bind(this)
      }),
      this.entityManager.propertyRepository.registerHooks({
        afterEntityUpdated: this.onPropertyEdited.bind(this)
      }),
      ...this.registerHooksForEntitiesRelatedTo(
        EntityName.ProcessTaskAppointment
      ),
      ...this.registerHooksForEntitiesRelatedTo(
        EntityName.ProcessTaskAppointmentToUser
      ),
      ...this.registerHooksForEntitiesRelatedTo(EntityName.ProcessTask)
    );
  }

  /**
   * Stop listening to changes.
   */
  public stopPersistingOnChanges(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * Create a new temporary appointment (and its corresponding appointmentToUser) from a calendar entry.
   *
   * Removes previously created temporary entities.
   */
  public createEntity(
    calendarEntry: RecurringCalendarEntry
  ): ProcessTaskAppointment {
    this.clearTemporaryAppointments();
    const currentUser = this.currentUserService.getRequiredCurrentUser();

    const appointment =
      this.entityManager.processTaskAppointmentRepository.create({
        processConfigurationStepId: calendarEntry.processConfigurationStepId,
        name: calendarEntry.name,
        color: calendarEntry.color,
        secondaryColor: calendarEntry.secondaryColor,
        coordinatorUserId: currentUser.id,
        temporaryGroupName: TemporaryAppointmentService.TEMPORARY_GROUP_NAME,
        shadowEntity: true,
        recurringAppointmentId: calendarEntry.id,
        ownerProcessTaskId: calendarEntry.processTaskId,
        ownerProcessTaskGroupId: calendarEntry.processTaskGroupId,
        ownerUserGroupId: calendarEntry.userGroupId
      });

    this.entityManager.processTaskAppointmentToUserRepository.create({
      dateFrom: new Date(calendarEntry.startTimestamp).toISOString(),
      dateTo: new Date(calendarEntry.endTimestamp).toISOString(),
      userId: currentUser.id,
      processTaskAppointmentId: appointment.id,
      temporaryGroupName: TemporaryAppointmentService.TEMPORARY_GROUP_NAME,
      shadowEntity: true,
      ownerProcessTaskId: calendarEntry.processTaskId,
      ownerProcessTaskGroupId: calendarEntry.processTaskGroupId,
      ownerUserGroupId: calendarEntry.userGroupId
    });

    return appointment;
  }

  /**
   * Removes the temporary processTaskAppointment & processTaskAppointmentToUser.
   *
   * Should be called whenever the user navigates away from editing a temporary appointment.
   */
  public clearTemporaryAppointments(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      TemporaryAppointmentService.TEMPORARY_GROUP_NAME
    );
  }

  private onAppointmentToUserEdited(
    appointmentToUser: ProcessTaskAppointmentToUser
  ): void {
    if (
      appointmentToUser.temporaryGroupName ===
      TemporaryAppointmentService.TEMPORARY_GROUP_NAME
    ) {
      this.createTemporaryAppointments();
    }
  }

  private onAppointmentEdited(appointment: ProcessTaskAppointment): void {
    if (
      appointment.temporaryGroupName ===
      TemporaryAppointmentService.TEMPORARY_GROUP_NAME
    ) {
      this.createTemporaryAppointments();
    }
  }

  private onPropertyEdited(property: Property): void {
    if (
      property.temporaryGroupName ===
      TemporaryAppointmentService.TEMPORARY_GROUP_NAME
    ) {
      this.createTemporaryAppointments();
    }
  }

  /**
   * Creates hooks which trigger every time an entity is created which is related to the entity type of the given `entityName`.
   */
  private registerHooksForEntitiesRelatedTo(
    entityName: EntityName
  ): Array<Disposable> {
    const referenceInfos =
      this.entityManager.entityRepositoryContainer.getEntityNameReferenceInfos(
        entityName
      );
    const hooks: Array<Disposable> = [];

    for (const referenceInfo of referenceInfos) {
      if (referenceInfo.fieldInfo.ref === entityName) {
        const pathHandler = new EntityInfoPathHandler({
          entityInfo: referenceInfo.repository.getEntityInfo(),
          path: referenceInfo.fieldInfo.path
        });

        hooks.push(
          referenceInfo.repository.registerHooks({
            afterEntityCreated: (entity) => {
              this.handleAfterEntityCreated({
                entity,
                referenceInfo,
                pathHandler
              });
            }
          })
        );
      }
    }

    return hooks;
  }

  private handleAfterEntityCreated({
    entity,
    referenceInfo,
    pathHandler
  }: {
    entity: BaseEntity;
    referenceInfo: ReferenceInfo<any, any, any, any>;
    pathHandler: EntityInfoPathHandler<any, any>;
  }): void {
    const temporaryEntities = this.entityManager.entityRepositoryContainer
      .getByEntityName(referenceInfo.entityName)
      .getByTemporaryGroupName(
        TemporaryAppointmentService.TEMPORARY_GROUP_NAME
      );

    const temporaryIds = temporaryEntities.map((a: BaseEntity) => a.id);

    const referencesTemporaryId = pathHandler.some({
      data: entity,
      callback: ({ value }) =>
        typeof value === 'string' && temporaryIds.includes(value)
    });

    if (referencesTemporaryId) {
      this.createTemporaryAppointments();
    }
  }

  private createTemporaryAppointments(): void {
    const processTaskId =
      this.excludeTemporaryAppointmentsFromRecurringAppointments();
    const processTask =
      this.entityManager.processTaskRepository.getById(processTaskId);
    if (processTask) {
      this.logTemporaryAppointmentsOpened(processTask);
      this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
        TemporaryAppointmentService.TEMPORARY_GROUP_NAME,
        processTask.temporaryGroupName
      );
    } else {
      // If ProcessTask not locally available, sync temporary entities to server & delete locally
      this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
        TemporaryAppointmentService.TEMPORARY_GROUP_NAME,
        TemporaryAppointmentService.TEMPORARY_GROUP_NAME
      );
    }
  }

  private logTemporaryAppointmentsOpened(processTask: ProcessTask): void {
    if (this.logOpenedByPersonId) {
      const appointments =
        this.entityManager.processTaskAppointmentRepository.getByTemporaryGroupName(
          TemporaryAppointmentService.TEMPORARY_GROUP_NAME
        );

      if (appointments.length !== 1) {
        throw new Error('Expected only one temporary appointment');
      }

      const appointment = appointments[0]!;
      void this.processTaskLoggingService.logAppointmentOpenedByPerson(
        processTask,
        appointment.id,
        this.logOpenedByPersonId
      );
    }
  }

  /**
   * For each temporary appointment, exclude its date from the recurrence of its corresponding recurring appointment.
   *
   * @returns the id of the related process task.
   */
  private excludeTemporaryAppointmentsFromRecurringAppointments(): string {
    const appointmentToUsers =
      this.entityManager.processTaskAppointmentToUserRepository.getByTemporaryGroupName(
        TemporaryAppointmentService.TEMPORARY_GROUP_NAME
      );

    if (appointmentToUsers.length !== 1) {
      throw new Error('Expected only one temporary appointmentToUsers');
    }

    const appointmentToUser = appointmentToUsers[0]!;
    const processTaskId = appointmentToUser.ownerProcessTaskId;

    const appointment =
      this.entityManager.processTaskAppointmentRepository.getById(
        appointmentToUser.processTaskAppointmentId
      );
    if (!appointment?.recurringAppointmentId) return processTaskId;

    const recurringAppointment =
      this.entityManager.processTaskRecurringAppointmentRepository.getById(
        appointment.recurringAppointmentId
      );
    if (!recurringAppointment) return processTaskId;

    const dateFrom = appointmentToUser.dateFrom;
    const dateTo = appointmentToUser.dateTo;
    if (!dateFrom || !dateTo) return processTaskId;

    recurringAppointment.excludedDates.push(dateFrom);
    this.entityManager.processTaskRecurringAppointmentRepository.update(
      recurringAppointment
    );

    return processTaskId;
  }
}
