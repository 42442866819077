import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationFormSendTarget } from './types';

export class ProcessConfigurationFormSendTargetRepository extends AppEntityRepository<EntityName.ProcessConfigurationFormSendTarget> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationFormSendTarget> {
    return this.getAll().filter(
      (x) => x.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
