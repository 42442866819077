import { computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { Project } from './types';

export class TemporaryJoinedProjectHandle {
  private internalProject: Project | null = null;
  private projectIsJoined: boolean = false;

  private joiningEnabled: boolean = false;

  constructor(private readonly entityManager: AppEntityManager) {}

  public enableAutoJoining(): void {
    this.joiningEnabled = true;

    if (this.internalProject && !this.projectIsJoined) {
      this.joinProject(this.internalProject);
      this.projectIsJoined = true;
    }
  }

  public disableAutoJoining(): void {
    this.joiningEnabled = false;

    if (this.internalProject && this.projectIsJoined) {
      this.leaveProject(this.internalProject);
    }

    this.projectIsJoined = false;
  }

  public reset(): void {
    this.disableAutoJoining();
  }

  @computedFrom('internalProject')
  public get project(): Project | null {
    return this.internalProject;
  }

  public set project(project: Project | null) {
    const oldProject = this.internalProject;
    const newProject = project;

    this.internalProject = project;

    if (newProject !== oldProject && this.joiningEnabled) {
      this.leaveProject(oldProject);
      this.joinProject(newProject);
      this.projectIsJoined = !!newProject;
    }
  }

  private joinProject(project: Project | null): void {
    if (project) {
      void this.entityManager.joinedProjectsManager.joinProject(
        project.id,
        true,
        true
      );
    }
  }

  private leaveProject(project: Project | null): void {
    if (project) {
      void this.entityManager.joinedProjectsManager.leaveProject(
        project.id,
        true
      );
    }
  }
}
