import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

export abstract class AbstractCameraStreamStrategy {
  public abstract startStream(
    onFocusSetCallback: (result: FocusSetHintResult) => void
  ): Promise<void>;

  public abstract stopStream(): Promise<void>;

  public abstract takePicture(): Promise<string>;

  public abstract switchStream(): Promise<void>;

  public abstract updateSize(): Promise<void>;

  public abstract setFocusPoint(x: number, y: number): Promise<void>;

  public abstract getSupportedFlashModes(): Promise<
    Array<CameraPreviewFlashMode>
  >;

  public abstract getFlashMode(): Promise<CameraPreviewFlashMode | null>;

  public abstract setFlashMode(
    flashMode: CameraPreviewFlashMode
  ): Promise<void>;
}

export type FocusSetHintResult = {
  x: number;
  y: number;
};
