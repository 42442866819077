import { autoinject, bindable } from 'aurelia-framework';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class CustomCard {
  @bindable() public variant: 'normal' | 'dashed' = 'normal';

  @bindable() public clickable: boolean = false;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  @computed(expression('variant'), expression('clickable'))
  protected get className(): string {
    return `${this.variant === 'dashed' ? 'custom-card--Dashed' : ''} ${
      this.clickable ? 'custom-card--Clickable' : ''
    }`;
  }
}
