import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationAuthorizationType } from '../../../classes/EntityManager/entities/ProcessConfigurationAuthorizationType/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationAuthorizationTypesWidgetListItem {
  @bindable()
  public processConfigurationAuthorizationType: ProcessConfigurationAuthorizationType | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationAuthorizationTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationAuthorizationType];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationAuthorizationTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationAuthorizationType,
        context: this as ProcessConfigurationAuthorizationTypesWidgetListItem,
        propertyName: 'processConfigurationAuthorizationType'
      });
  }

  protected handleAuthorizationTypeChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationAuthorizationType,
      "can't ProcessConfigurationAuthorizationTypesWidgetListItem.handleAuthorizationTypeChanged without processConfigurationAuthorizationType"
    );

    this.entityManager.processConfigurationAuthorizationTypeRepository.update(
      this.processConfigurationAuthorizationType
    );
  }

  protected handleDeleteClick(): void {
    const processConfigurationAuthorizationType =
      this.processConfigurationAuthorizationType;
    assertNotNullOrUndefined(
      processConfigurationAuthorizationType,
      "can't ProcessConfigurationAuthorizationTypesWidgetListItem.handleDeleteClick without processConfigurationAuthorizationType"
    );

    void Dialogs.deleteEntityDialog(
      processConfigurationAuthorizationType,
      EntityName.ProcessConfigurationAuthorizationType
    ).then(() => {
      this.entityManager.processConfigurationAuthorizationTypeRepository.delete(
        processConfigurationAuthorizationType
      );
    });
  }
}
