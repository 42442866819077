import { autoinject, bindable, observable } from 'aurelia-framework';
import { UiUpdater } from '../../classes/UiUpdater';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { StructureListItem } from '../structure-list-item/structure-list-item';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

/**
 * attach these buttons at the root so they will have a loose coupling to the edit-entry-widget
 * the buttons will automatically assume the left/right position of it's container for correct styling/design
 *
 * how to use this element:
 * preparing:
 * * add all needed directions somewhere in the global scope (e.g. app.html) to a container which gives the buttons the right positioning (as mentioned above)
 *
 * access/controlling the global buttons:
 * * take control with your implementation, and use the instance of your implementation as the scope (the scope should be unique)
 * * change the properties you need to (e.g. setVisible), these will only take effect if not someone else took control in the meantime (state will still be stored and clickListeners will not be
 * called)
 * * release control as soon you don't need the buttons anymore (e.g. in the detached callback)
 */
@autoinject()
export class EntryNavigationButton {
  private static registeredButtonMap: Record<
    NavigationDirection,
    EntryNavigationButton | null
  > = {
    navigateOut: null,
    navigateIn: null
  };

  private static controls: Array<EntryNavigationButtonControl> = [];

  @bindable public direction: NavigationDirection =
    NavigationDirection.NAVIGATE_OUT;

  private domElement: HTMLElement;
  private readonly subscriptionManager: SubscriptionManager;

  private visible = false;
  private forceVisible = false;

  private dragInProgress = false;

  @observable private isMobile: boolean;

  private isAttached = false;

  private boundHandleResize = this.handleResize.bind(this);

  private domElementAnimator: ShowHideAnimator | null = null;

  private StructureListItem: typeof StructureListItem = StructureListItem;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.isMobile = true;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected bind(): void {
    if (!this.domElement) {
      throw new Error('domElement is not bound');
    }

    this.domElementAnimator = new ShowHideAnimator(this.domElement);
  }

  protected attached(): void {
    if (EntryNavigationButton.registeredButtonMap[this.direction]) {
      console.error(`A button for ${this.direction} was already registered!`);
    }

    EntryNavigationButton.registeredButtonMap[this.direction] = this;
    this.syncConfig();

    UiUpdater.registerResizeUpdateFunction(this.boundHandleResize);
    this.handleResize();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isSmallMobile', (isSmallMobile) => {
        this.isMobile = isSmallMobile;
      })
    );

    this.isAttached = true;
    this.syncConfig();
  }

  protected detached(): void {
    EntryNavigationButton.registeredButtonMap[this.direction] = null;
    UiUpdater.unregisterResizeUpdateFunction(this.boundHandleResize);
    this.subscriptionManager.disposeSubscriptions();
    this.isAttached = false;
  }

  private handleResize(): void {
    const rect = (
      this.domElement.parentNode as HTMLElement
    ).getBoundingClientRect();

    switch (this.direction) {
      case NavigationDirection.NAVIGATE_OUT:
        this.domElement.style.left = rect.left + 'px';
        break;

      case NavigationDirection.NAVIGATE_IN:
        this.domElement.style.left = rect.left + rect.width + 'px';
        break;

      default:
        console.error(
          `no way to position a button with the direction ${this.direction}`
        );
    }
  }

  private handleClick(): void {
    const control = EntryNavigationButton.getCurrentControl();
    const cbName = this.direction;
    if (control && control.clickListeners[cbName]) {
      // @ts-ignore - tsc just doesn't recognize the if correctly and thinks the control.clickListeners[cbName] can still be null :(
      control.clickListeners[cbName]();
    }
  }

  private handleDropTargetActivated(): boolean {
    this.setDragInProgress(true);
    return true;
  }

  private handleDropTargetDeactivated(): void {
    this.setDragInProgress(false);
  }

  private setDragInProgress(dragInProgress: boolean): void {
    if (this.direction === NavigationDirection.NAVIGATE_OUT) {
      const control =
        EntryNavigationButton.getCurrentDirectionConfigMapOrDefault();
      control[this.direction].dragInProgress = dragInProgress;
      this.syncConfig();
    }
  }

  private handleDrop(viewModel: StructureListItem): void {
    const control = EntryNavigationButton.getCurrentControl();
    const cbName = this.direction;
    if (control && control.dropListeners[cbName]) {
      // @ts-ignore - tsc just doesn't recognize the if correctly and thinks the control.dropListeners[cbName] can still be null :(
      control.dropListeners[cbName](viewModel);
    }
  }

  private syncConfig(): void {
    if (!this.domElementAnimator || !this.domElement) {
      throw new Error('syncConfig only works if the element is bound');
    }

    const oldVisible = (this.visible && !this.isMobile) || this.forceVisible;
    this.visible =
      EntryNavigationButton.getCurrentDirectionConfigMapOrDefault()[
        this.direction
      ].visible;
    this.forceVisible =
      EntryNavigationButton.getCurrentDirectionConfigMapOrDefault()[
        this.direction
      ].forceVisible;
    this.dragInProgress =
      EntryNavigationButton.getCurrentDirectionConfigMapOrDefault()[
        this.direction
      ].dragInProgress;
    const visible =
      (this.visible && !this.isMobile) ||
      this.forceVisible ||
      this.dragInProgress;

    if (visible !== oldVisible) {
      this.domElementAnimator.clearAnimations();

      if (visible) {
        void this.domElementAnimator.scaleFadeIn();
      } else {
        void this.domElementAnimator.scaleFadeOut();
      }
    } else if (!visible) {
      this.domElement.style.display = 'none';
    }
  }

  protected isMobileChanged(): void {
    if (!this.isAttached) return;
    this.syncConfig();
  }

  public static setVisible(
    scope: any,
    direction: NavigationDirection,
    visible: boolean
  ): void {
    const control = this.getControlByScope(scope);
    if (!control) {
      console.error("this scope doesn't have control", scope);
      return;
    }

    control.directionConfigMap[direction].visible = visible;
    this.syncRegisteredButtons();
  }

  public static setForceVisible(
    scope: any,
    direction: NavigationDirection,
    forceVisible: boolean
  ): void {
    const control = this.getControlByScope(scope);
    if (!control) {
      console.error("this scope doesn't have control", scope);
      return;
    }

    control.directionConfigMap[direction].forceVisible = forceVisible;
    this.syncRegisteredButtons();
  }

  public static takeControl(
    scope: any,
    clickListeners: EntryNavigationButtonClickListeners,
    dropListeners: EntryNavigationButtonDropListeners
  ): void {
    if (this.getControlByScope(scope)) {
      console.error('this scope already has control', scope);
      return;
    }

    this.controls.push({
      scope: scope,
      clickListeners: clickListeners,
      dropListeners: dropListeners,
      directionConfigMap: this.getDefaultDirectionConfigMap()
    });

    this.syncRegisteredButtons();
  }

  public static hasControl(scope: any): boolean {
    return this.getControlByScope(scope) != null;
  }

  public static releaseControl(scope: any): void {
    const index = this.controls.findIndex((c) => c.scope === scope);
    if (index >= 0) {
      this.controls.splice(index);
      this.syncRegisteredButtons();
    }
  }

  private static getControlByScope(
    scope: any
  ): EntryNavigationButtonControl | null | undefined {
    return this.controls.find((c) => c.scope === scope);
  }

  private static syncRegisteredButtons(): void {
    for (const key of Object.keys(this.registeredButtonMap) as Array<
      keyof EntryNavigationButtonClickListeners
    >) {
      if (
        this.registeredButtonMap.hasOwnProperty(key) &&
        this.registeredButtonMap[key]
      ) {
        // @ts-ignore - tsc just doesn't recognize the if correctly and thinks the registeredButtonMap[key] can still be null :(
        this.registeredButtonMap[key].syncConfig();
      }
    }
  }

  private static getCurrentDirectionConfigMapOrDefault(): Record<
    NavigationDirection,
    EntryNavigationButtonDirectionConfig
  > {
    const control = this.getCurrentControl();
    if (control) {
      return control.directionConfigMap;
    } else {
      return this.getDefaultDirectionConfigMap();
    }
  }

  private static getCurrentControl(): EntryNavigationButtonControl | null {
    return this.controls[this.controls.length - 1];
  }

  private static getDefaultDirectionConfigMap(): Record<
    NavigationDirection,
    EntryNavigationButtonDirectionConfig
  > {
    return {
      navigateOut: {
        visible: false,
        forceVisible: false,
        dragInProgress: false
      },
      navigateIn: {
        visible: false,
        forceVisible: false,
        dragInProgress: false
      }
    };
  }

  public static updateButtonPositions(): void {
    for (const key of Object.keys(this.registeredButtonMap) as Array<
      keyof EntryNavigationButtonClickListeners
    >) {
      if (
        this.registeredButtonMap.hasOwnProperty(key) &&
        this.registeredButtonMap[key]
      ) {
        // @ts-ignore - tsc just doesn't recognize the if correctly and thinks the registeredButtonMap[key] can still be null :(
        this.registeredButtonMap[key].handleResize();
      }
    }
  }
}

SiteScrollLocker.addScrollLockedStateChangedListener(
  EntryNavigationButton,
  () => {
    // make this async to not interrupt the javascript execution
    window.requestAnimationFrame(() => {
      EntryNavigationButton.updateButtonPositions();
    });
  }
);

type EntryNavigationButtonControl = {
  directionConfigMap: Record<string, EntryNavigationButtonDirectionConfig>;
  scope: Object;
  clickListeners: EntryNavigationButtonClickListeners;
  dropListeners: EntryNavigationButtonDropListeners;
};

type EntryNavigationButtonClickListeners = Record<
  NavigationDirection,
  (() => void) | null
>;

type EntryNavigationButtonDropListeners = Record<
  NavigationDirection,
  ((viewModel: StructureListItem) => void) | null
>;

type EntryNavigationButtonDirectionConfig = {
  visible: boolean;
  forceVisible: boolean;
  dragInProgress: boolean;
};

export enum NavigationDirection {
  NAVIGATE_IN = 'navigateIn',
  NAVIGATE_OUT = 'navigateOut'
}
