import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ImageFullScreenOverlay } from '../../aureliaComponents/image-full-screen-overlay/image-full-screen-overlay';
import { SocketService } from '../../services/SocketService';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import { PictureFullScreenOverlay } from '../../aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay';
import { DefectCommentPicture } from '../../classes/EntityManager/entities/Picture/types';
import { DefectCommentGeneralFile } from '../../classes/EntityManager/entities/GeneralFile/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FileDownloadService } from '../../services/FileDownloadService';

/**
 * A GeneralFile attached to a defect comment.
 * Displays icon & name.
 */
@autoinject()
export class DefectCommentAttachment {
  @bindable public attachment: AttachmentContent | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected isOnline: boolean = true;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
  }

  public detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private openDownloadWindow(): void {
    assertNotNullOrUndefined(
      this.attachment,
      'cannot start download without attachment'
    );
    if (this.attachment.type !== 'file') return;

    const path = GeneralFileUtils.getRelativeOnlineFilePathForGeneralFile(
      this.attachment.file
    );
    if (path) {
      void this.fileDownloadService.downloadFile(path);
    }
  }

  protected handleAttachmentClick(): void {
    if (this.attachment && this.attachment.type === 'file') {
      if (this.isGeneralFilePicture && this.generalFileImagePath) {
        void ImageFullScreenOverlay.open({
          imageSrc: this.generalFileImagePath
        });
      } else {
        this.openDownloadWindow();
      }
    } else if (this.attachment) {
      void PictureFullScreenOverlay.open({
        picture: this.attachment.picture
      });
    }
  }

  /**
   * Returns true if the given GeneralFile is a picture and can be displayed as one.
   */
  @computedFrom('isOnline', 'attachment')
  protected get isGeneralFilePicture(): boolean {
    if (!this.isOnline) return false;
    if (!this.attachment) return false;
    if (this.attachment.type !== 'file') return false;
    if (!this.attachment.file.extension) return false;
    return ['png', 'jpg', 'jpeg', 'svg', 'gif'].includes(
      this.attachment.file.extension.toLowerCase()
    );
  }

  /**
   * Returns the path for the supplied file.
   */
  @computedFrom('attachment', 'isPicture')
  protected get generalFileImagePath(): string | null {
    if (!this.attachment) return null;
    if (this.attachment.type !== 'file') return null;
    if (!this.isGeneralFilePicture) return null;
    return GeneralFileUtils.getRelativeOnlineFilePathForGeneralFile(
      this.attachment.file
    );
  }
}

export type AttachmentFileContent = {
  type: 'file';
  file: DefectCommentGeneralFile;
};

export type AttachmentPictureContent = {
  type: 'picture';
  picture: DefectCommentPicture;
};

export type AttachmentContent =
  | AttachmentFileContent
  | AttachmentPictureContent;
