import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { ThingSection } from '../../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { PictureDisplayInfoUtils } from '../PictureDisplayInfoUtils/PictureDisplayInfoUtils';
import {
  PictureGroup,
  SelectPictureDialogAdapter,
  SubscribeOptions
} from '../SelectPictureDialogAdapter/SelectPictureDialogAdapter';

export class SelectPictureDialogThingSectionAdapter extends SelectPictureDialogAdapter {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly thingSection: ThingSection;

  constructor(options: SelectPictureDialogEntryAdapterOptions) {
    super();

    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.thingSection = options.thingSection;
  }

  public subscribe({ setPictureGroups }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updatePictureGroups = (): void => {
      setPictureGroups(this.getPictureGroups());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updatePictureGroups
    );
    updatePictureGroups();

    return subscriptionManager.toDisposable();
  }

  public async afterPictureSelected(): Promise<void> {}

  private getPictureGroups(): Array<PictureGroup> {
    return [
      {
        titleTk: GroupTitleTk.GLOBAL_THING_PICTURES,
        pictureDisplayInfos:
          PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({
            pictures: this.entityManager.pictureRepository.getByGlobalThingId(
              this.thingSection.ownerThingId
            )
          })
      }
    ];
  }
}

export enum GroupTitleTk {
  GLOBAL_THING_PICTURES = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogThingSectionAdapter.globalThingPictures'
}

export type FilterPicture = (picture: Picture) => boolean;

export type SelectPictureDialogEntryAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  thingSection: ThingSection;
};
