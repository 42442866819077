import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationDeviceExport } from './types';

export class ProcessConfigurationDeviceExportRepository extends AppEntityRepository<EntityName.ProcessConfigurationDeviceExport> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationDeviceExport> {
    return this.getAll().filter(
      (d) => d.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
