import { bindable, containerless } from 'aurelia-templating';
import { PersonContactType } from 'common/Enums/PersonContactType';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { UrlUtils } from '../../classes/Utils/UrlUtils';

/**
 * @slot default
 */
@containerless()
export class PersonContactLink {
  private static eventDispatcher = new EventDispatcher<EventConfig>();

  private static contactTypeToHrefPrefix = {
    [PersonContactType.EMAIL]: 'mailto:',
    [PersonContactType.PHONE]: 'tel:'
  };

  private static contactTypeToEventName: Record<
    (typeof PersonContactType)[keyof typeof PersonContactType],
    keyof EventConfig
  > = {
    [PersonContactType.EMAIL]: 'emailContactClicked',
    [PersonContactType.PHONE]: 'phoneContactClicked'
  };

  public static addEventListener<T extends keyof EventConfig>(
    eventName: T,
    callback: (payload: EventConfig[T]) => void
  ): Disposable {
    return this.eventDispatcher.addDisposableEventListener(eventName, callback);
  }

  private static notifyEventListeners(
    person: Person,
    personContact: PersonContact
  ): { canceled: boolean } {
    const eventName = this.contactTypeToEventName[personContact.contactType];
    let canceled = false;
    const cancelClick = (): void => {
      canceled = true;
    };

    if (eventName) {
      this.eventDispatcher.dispatchEvent(eventName, {
        person,
        personContact,
        cancelClick
      });
    } else {
      console.error(`no event name found for ${personContact.contactType}`);
    }

    return { canceled };
  }

  @bindable()
  public person: Person | null = null;

  @bindable()
  public personContact: PersonContact | null = null;

  /**
   * relevant for email contacts
   */
  @bindable()
  public subject: string | null = null;

  /**
   * relevant for email contacts
   */
  @bindable()
  public message: string | null = null;

  /**
   * use this event instead of adding a click.trigger to the tag, since the tag is containerless
   */
  @bindable()
  public linkClicked: ((params: {}) => boolean | void) | null = null;

  @bindable()
  public class: string | undefined = undefined;

  protected getContactHref(
    contactType: string,
    contactName: string | null,
    subject: string | null,
    message: string | null
  ): string {
    if (!contactName) {
      // script url is on purpose and doesn't do anything dangerous
      // eslint-disable-next-line no-script-url
      return 'javascript:void(0)';
    }

    const prefix = PersonContactLink.contactTypeToHrefPrefix[contactType];
    let href = prefix + contactName;

    if (contactType === PersonContactType.EMAIL) {
      const queryParams: Record<string, string> = {};

      if (subject) {
        queryParams.subject = subject;
      }

      if (message) {
        queryParams.body = message;
      }

      href += UrlUtils.createQueryString(queryParams);
    }

    return href;
  }

  protected handleLinkClick(): boolean {
    let { canceled } = this.tryNotifyingEventListeners();

    if (this.linkClicked) {
      canceled = !this.linkClicked({});
    }

    return !canceled;
  }

  private tryNotifyingEventListeners(): { canceled: boolean } {
    if (this.person && this.personContact) {
      return PersonContactLink.notifyEventListeners(
        this.person,
        this.personContact
      );
    }

    return { canceled: false };
  }
}

type EventConfig = {
  emailContactClicked: ContactClickedPayload;
  phoneContactClicked: ContactClickedPayload;
};

export type ContactClickedPayload = {
  person: Person;
  personContact: PersonContact;
  cancelClick: () => void;
};
