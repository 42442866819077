export class GlobalLoadingOverlay {
  /** @type {Array<any>} */
  static _loadingScopes = [];
  /** @type {(GlobalLoadingOverlay|null)} */
  static _instance;

  /** @type {boolean} */
  _loadingOverlayVisible = false;

  attached() {
    if (GlobalLoadingOverlay._instance) {
      console.error(
        '[GlobalLoadingOverlay] instance already exists, replacing it'
      );
    }

    GlobalLoadingOverlay._instance = this;
    this._updateLoadingOverlayVisible();
  }

  detached() {
    GlobalLoadingOverlay._instance = null;
  }

  _updateLoadingOverlayVisible() {
    this._loadingOverlayVisible =
      GlobalLoadingOverlay._loadingScopes.length > 0;
  }

  /**
   * @param {*} scope
   * @param {boolean} loading
   */
  static setLoadingState(scope, loading) {
    const index = this._loadingScopes.indexOf(scope);

    if (loading && index === -1) {
      this._loadingScopes.push(scope);
    } else if (!loading && index >= 0) {
      this._loadingScopes.splice(index, 1);
    }

    this._instance && this._instance._updateLoadingOverlayVisible();
  }
}
