import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { ComputedValueService } from '../computedValues/ComputedValueService';
import { ProcessTaskAppointmentDateInfoMapComputer } from '../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';

@inject(AppEntityManager, ComputedValueService)
export class DefaultInspectorService {
  /**
   * @param {AppEntityManager} entityManager
   * @param {ComputedValueService} computedValueService
   */
  constructor(entityManager, computedValueService) {
    this._entityManager = entityManager;
    this._computedValueService = computedValueService;
  }

  /**
   * @param {import('../classes/EntityManager/entities/ProcessTask/types').ProcessTask} processTask
   * @returns {Promise<string|null>}
   */
  async getDefaultInspectorUserId(processTask) {
    const processTaskGroup =
      this._entityManager.processTaskGroupRepository.getById(
        processTask.ownerProcessTaskGroupId
      );
    if (!processTaskGroup) {
      return null;
    }

    const steps =
      this._entityManager.processConfigurationStepRepository.getInspectionStepsByProcessConfigurationId(
        processTaskGroup.processConfigurationId
      );
    const inspectionStepIds = steps.map((s) => s.id);
    const appointments =
      this._entityManager.processTaskAppointmentRepository.getByProcessTaskId(
        processTask.id
      );
    const dateInfoMap = await this._getProcessTaskAppointmentDateInfoMap();

    const appointmentsData = appointments.map((appointment) => {
      return {
        appointment,
        dateInfo: dateInfoMap.get(appointment.id)
      };
    });

    const defaultToDate = new Date(0).toISOString();
    appointmentsData.sort((a, b) => {
      const aDate = a.dateInfo?.dateTo ? a.dateInfo?.dateTo : defaultToDate;
      const bDate = b.dateInfo?.dateTo ? b.dateInfo?.dateTo : defaultToDate;

      return bDate.localeCompare(aDate);
    });

    let userId = null;

    for (const appointmentData of appointmentsData) {
      if (
        appointmentData.appointment.processConfigurationStepId &&
        inspectionStepIds.indexOf(
          appointmentData.appointment.processConfigurationStepId
        ) >= 0
      ) {
        const firstRelation =
          this._entityManager.processTaskAppointmentToUserRepository.getByProcessTaskAppointmentId(
            appointmentData.appointment.id
          )[0];
        if (firstRelation) {
          userId = firstRelation.userId;
          break;
        }
      }
    }

    return userId;
  }

  /**
   * @returns {Promise<import('../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer').ProcessTaskAppointmentDateInfoMap>}
   */
  async _getProcessTaskAppointmentDateInfoMap() {
    return new Promise((resolve) => {
      this._computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: resolve
      });
    });
  }
}
