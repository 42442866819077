import { bindable } from 'aurelia-framework';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

export class ThingThingGroupFilter {
  @bindable public things: Array<Thing> | null = null;

  /** readonly! */
  @bindable public filteredThings: Array<Thing> = [];

  @bindable public thingGroupId: string | null = null;

  protected thingsChanged(): void {
    this.updateFilteredThings();
  }

  protected thingGroupIdChanged(): void {
    this.updateFilteredThings();
  }

  private updateFilteredThings(): void {
    this.filteredThings =
      this.things?.filter((thing) => {
        if (this.thingGroupId) {
          return thing.thingGroupId === this.thingGroupId;
        }

        return true;
      }) ?? [];
  }
}
