import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { HighlightAnimator } from '../../classes/Animation/HighlightAnimator';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskUtils } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGeneralProperties } from '../process-task-general-properties/process-task-general-properties';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { ProcessConfigurationActionStatusModifiedChangeData } from 'common/processTaskLogEntryActions';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * widget for the general data of the process task (e.g. fields contained in the ProcessTaskModel itself)
 */
@autoinject()
export class ProcessTaskGeneralWidget {
  @bindable public processTask: ProcessTask | null = null;
  @bindable public processTaskGroup: ProcessTaskGroup | null = null;
  @bindable public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  protected selectedActionStatusId: string | null = null;
  protected customActionStatusName: string | null = null;
  protected customActionStatusAbbreviation: string | null = null;

  protected processTaskGeneralProperties: ProcessTaskGeneralProperties | null =
    null;

  protected actionStatusContainer: HTMLElement | null = null;
  protected ProcessTaskUtils = ProcessTaskUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskGeneralWidget,
        propertyName: 'processTask'
      });
  }

  protected processTaskChanged(): void {
    if (this.processTask) {
      this.customActionStatusName = this.processTask.customActionStatusName;
      this.customActionStatusAbbreviation =
        this.processTask.customActionStatusAbbreviation;
      this.selectedActionStatusId =
        this.processTask.processConfigurationActionStatusId;
    }
  }

  public focusProperty(property: PropertyCreationBaseData): void {
    if (this.processTaskGeneralProperties) {
      this.processTaskGeneralProperties.focusProperty(property);
    }
  }

  public focusActionStatus(): void {
    assertNotNullOrUndefined(
      this.actionStatusContainer,
      "can't focusActionStatus because _actionStatusContainer is not available"
    );
    void ScrollHelper.scrollToItemCentered(this.actionStatusContainer);
    const animator = new HighlightAnimator(this.actionStatusContainer);
    animator.highlightBackground();
  }

  protected handleRemoveActionStatusClick(): void {
    this.saveChangeData({
      customActionStatusAbbreviation: null,
      customActionStatusName: null,
      processConfigurationActionStatusId: null
    });

    this.selectedActionStatusId = null;
    this.customActionStatusAbbreviation = null;
    this.customActionStatusName = null;
  }

  protected handleSaveActionStatusClick(): boolean {
    const changeData: ProcessConfigurationActionStatusModifiedChangeData = {
      processConfigurationActionStatusId: this.selectedActionStatusId,
      customActionStatusName: this.customActionStatusName,
      customActionStatusAbbreviation: this.customActionStatusAbbreviation
    };

    this.saveChangeData(changeData);

    return true;
  }

  protected handleCancelActionStatusClick(): boolean {
    return true;
  }

  protected handleProcessTaskDescriptionChanged(): void {
    if (this.processTask) {
      void this.processTaskLoggingService.logProcessTaskDescriptionModified(
        this.processTask
      );
    }
    this.handleProcessTaskChanged();
  }

  protected handleProcessTaskNameChanged(): void {
    if (this.processTask) {
      void this.processTaskLoggingService.logProcessTaskNameModified(
        this.processTask
      );
    }
    this.handleProcessTaskChanged();
  }

  protected handleProcessTaskChanged(): void {
    if (this.processTask) {
      this.entityManager.processTaskRepository.update(this.processTask);
    }
  }

  private saveChangeData(
    changeData: ProcessConfigurationActionStatusModifiedChangeData
  ): void {
    if (!this.processTask) {
      return;
    }

    let changesDetected = false;

    for (const key in changeData) {
      if (Object.prototype.hasOwnProperty.call(changeData, key)) {
        // @ts-ignore - we don't have an index signature here because this isn't a map
        if (changeData[key] !== this.processTask[key]) {
          changesDetected = true;
          break;
        }
      }
    }

    if (changesDetected) {
      this.logChange(this.processTask, changeData);
      Object.assign(this.processTask, changeData);
      this.entityManager.processTaskRepository.update(this.processTask);
    }
  }

  private logChange(
    processTask: ProcessTask,
    changeData: ProcessConfigurationActionStatusModifiedChangeData
  ): void {
    const oldData: ProcessConfigurationActionStatusModifiedChangeData = {
      customActionStatusAbbreviation:
        processTask.customActionStatusAbbreviation,
      customActionStatusName: processTask.customActionStatusName,
      processConfigurationActionStatusId:
        processTask.processConfigurationActionStatusId
    };

    void this.processTaskLoggingService.logProcessConfigurationActionStatusModified(
      processTask,
      {
        before: oldData,
        after: changeData
      } as any
    );
  }

  @computedFrom('processConfiguration.showProcessTaskFeatures.actionStatus')
  protected get showActionStatus(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.actionStatus ?? true
    );
  }
}
