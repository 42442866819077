import { Capacitor } from '@capacitor/core';

import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from './PropertyBinder/PropertyBinder';
import { Disposable } from './Utils/DisposableContainer';

/**
 * checks the screen size and sets the mobile/desktop class of the body
 * also allows a binding so consumers are automatically updated
 */
export class DeviceInfoHelper {
  private static mobile = false;
  private static smallMobile = false;
  private static canCapturePictureStatus = false;

  private static initialized = false;

  private static deviceReadyPromise: Promise<void> | null = null;
  private static deviceReadyPromiseResolved = false;

  private static propertyBinder =
    new PropertyBinder<DeviceInfoHelperPropertyBinderConfig>();

  private static statusBarHeight = 0;

  private constructor() {}

  public static init(): void {
    if (this.initialized) {
      return;
    }

    window.addEventListener('resize', this.handleWindowResize.bind(this));
    this.handleWindowResize();

    setInterval(this.updateCanCapturePicture.bind(this), 1000); // we are polling here since it's cheap and so we can detect if a camera is plugged in while the website is opened

    this.deviceReadyPromiseResolved = false;
    this.deviceReadyPromise = new Promise((res) => {
      document.addEventListener('deviceready', () => {
        res();
        this.deviceReadyPromiseResolved = true;
      });
    });

    this.initialized = true;
  }

  public static async waitForDeviceReady(): Promise<void> {
    if (!this.isApp()) {
      return;
    }

    assertNotNullOrUndefined(
      this.deviceReadyPromise,
      'device info helper is not initialized'
    );
    if (!this.deviceReadyPromiseResolved) return await this.deviceReadyPromise;
  }

  private static updateCanCapturePicture(): void {
    const nav: Navigator = window.navigator;
    if (this.isApp()) {
      this.setCanCapturePicture(true);
    } else if (nav.mediaDevices && nav.mediaDevices.enumerateDevices) {
      // normally the enumeratePromise should always be defined, but there are some buggy instances of
      // chrome out there which return null (and we really don't want the sentry error messages)
      const enumeratePromise =
        nav.mediaDevices.enumerateDevices() as ReturnType<
          typeof navigator.mediaDevices.enumerateDevices
        > | null;
      if (enumeratePromise) {
        enumeratePromise
          .then((devices) => {
            this.setCanCapturePicture(
              !!devices.find((d) => d.kind === 'videoinput')
            );
          })
          .catch(() => {
            this.setCanCapturePicture(false);
          });
      }
    } else {
      this.setCanCapturePicture(false);
    }
  }

  public static isMobile(): boolean {
    return this.mobile;
  }

  public static isSmallMobile(): boolean {
    return this.smallMobile;
  }

  public static canCapturePicture(): boolean {
    return this.canCapturePictureStatus;
  }

  public static isApp(): boolean {
    return Capacitor.isNativePlatform();
  }

  public static isIOSDevice(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  public static isAndroidDevice(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  public static getUUID(): string | null {
    return window.device?.uuid ?? null;
  }

  public static registerBinding<
    TName extends PropertyBinderName<DeviceInfoHelperPropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<
      DeviceInfoHelperPropertyBinderConfig,
      TName
    >
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public static updateAndroidSafeAreaInsets(): void {
    if (!window.AndroidNotch) return;

    const style = document.documentElement.style;

    window.AndroidNotch.getInsetTop(
      (px) => {
        style.setProperty(
          '--android-safe-area-inset-top',
          Math.max(px, this.statusBarHeight) + 'px'
        );
      },
      (err) => console.error('Failed to get insets top:', err)
    );

    window.AndroidNotch.getInsetRight(
      (px) => {
        style.setProperty('--android-safe-area-inset-right', px + 'px');
      },
      (err) => console.error('Failed to get insets right:', err)
    );

    window.AndroidNotch.getInsetBottom(
      (px) => {
        style.setProperty('--android-safe-area-inset-bottom', px + 'px');
      },
      (err) => console.error('Failed to get insets bottom:', err)
    );

    window.AndroidNotch.getInsetLeft(
      (px) => {
        style.setProperty('--android-safe-area-inset-left', px + 'px');
      },
      (err) => console.error('Failed to get insets left:', err)
    );
  }

  public static async calculateStatusBarHeight(): Promise<void> {
    return new Promise((res, rej) => {
      window.cordova?.plugins.StatusBarHeight.getValue((statusBarHeight) => {
        this.statusBarHeight = statusBarHeight;
        res();
      }, rej);
    });
  }

  private static handleWindowResize(): void {
    const documentWidth = document.documentElement.clientWidth;
    this.smallMobile = documentWidth <= 767;
    this.mobile = documentWidth <= 991;

    this.propertyBinder.setValue('isMobile', this.mobile);
    this.propertyBinder.setValue('isSmallMobile', this.smallMobile);

    this.updateAndroidSafeAreaInsets();
  }

  private static setCanCapturePicture(value: boolean): void {
    this.canCapturePictureStatus = value;
    this.propertyBinder.setValue(
      'canCapturePicture',
      this.canCapturePictureStatus
    );
  }
}

DeviceInfoHelper.init();

type DeviceInfoHelperPropertyBinderConfig = {
  isMobile: boolean;
  isSmallMobile: boolean;
  canCapturePicture: boolean;
};
