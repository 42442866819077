import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { TPictureAdditionalMarking } from 'common/Types/Entities/Picture/PictureDto';
import { GalleryThingAdditionalMarkingsHelper } from 'common/GalleryThing/GalleryThingAdditionalMarkingsHelper';
import { DateUtils } from 'common/DateUtils';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * Edit Area & Details for a given picture.
 *
 * @event {PictureCoordinatesChangedEvent} picture-coordinates-changed - fired after the user manually changed the coordinates. The coordinates are already saved, but this is here in case you need to do some extra work
 */
@autoinject()
export class GalleryThingPictureDetail {
  /**
   * The thingId this picture is connected to.
   */
  @bindable public thingId: string | null = null;

  /**
   * The picture to display the details for.
   */
  @bindable public picture: Picture | null = null;

  /**
   * The entry for the picture.
   */
  @bindable public entry: Entry | null = null;

  /**
   * The `onMapReady` callback for the coordinate-input.
   */
  @bindable public coordinateInputOnMapReady:
    | ((map: BasemapMap) => void)
    | null = null;

  /**
   * Whether the user can select/change tags or not.
   */
  @bindable public userCanUseTags: boolean = false;

  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  /**
   * Whether the user can use the region extension or not.
   */
  protected userCanUseRegionExtension: boolean = false;

  /**
   * Whether the user is using categorized tags.
   */
  protected useCategorizedTags: boolean = false;

  /**
   * Whether the createdAt and takenAt timestamps are shown separately.
   */
  protected showPictureUploadAndTakenDateExplicitly: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly subscriptionManager: SubscriptionManager,
    private readonly currentUserService: CurrentUserService,
    permissionsService: PermissionsService
  ) {
    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Picture,
        context: this as GalleryThingPictureDetail,
        propertyName: 'picture'
      });

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as GalleryThingPictureDetail,
        propertyName: 'entry'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.useCategorizedTags',
        (setting) => {
          this.useCategorizedTags = setting;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.showPictureUploadAndTakenDateExplicitly',
        (setting) => {
          this.showPictureUploadAndTakenDateExplicitly = setting;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.userCanUseRegionExtension = currentUser
          ? PermissionHelper.userHasPermission(
              currentUser,
              'canUseRegionExtension'
            )
          : false;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected formatToDateWithTimeString(date: string | null): string {
    return DateUtils.formatToDateWithTimeString(date);
  }

  protected handlePictureCoordinatesChanged(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't GalleryThingPictureDetails.handlePictureCoordinatesChanged without picture"
    );
    this.picture.additional_markings = this.getAdditionalMarkingsForViaPicture(
      this.picture
    );
    this.handlePictureChanged();

    DomEventHelper.fireEvent<PictureCoordinatesChangedEvent>(this.element, {
      name: 'picture-coordinates-changed',
      detail: null
    });
  }

  protected handlePictureChanged(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't GalleryThingPictureDetails.handlePictureChanged without picture"
    );

    this.entityManager.pictureRepository.update(this.picture);
  }

  protected handleEntryChanged(): void {
    assertNotNullOrUndefined(
      this.entry,
      "can't GalleryThingPictureDetails.handleEntryChanged without entry"
    );

    this.entityManager.entryRepository.update(this.entry);
  }

  private getAdditionalMarkingsForViaPicture(
    picture: Picture
  ): Array<TPictureAdditionalMarking> {
    assertNotNullOrUndefined(
      this.thingId,
      "can't GalleryThingPictureDetails.updateAdditionalMarkingsForViaPicture without thingId"
    );
    if (
      this.activeUserCompanySettingService.getSettingProperty(
        'via.automaticallyMarkPicturesOnThingPicture'
      ) !== true ||
      !picture.coords?.latitude ||
      !picture.coords?.longitude
    )
      return [];
    const galleryThingOverviewPictures =
      this.entityManager.pictureRepository.getByGalleryThingId(this.thingId);

    return GalleryThingAdditionalMarkingsHelper.getAdditionalMarkings(
      galleryThingOverviewPictures,
      picture.coords,
      'picture_id'
    );
  }
}

export type PictureCoordinatesChangedEvent = NamedCustomEvent<
  'picture-coordinates-changed',
  null
>;
