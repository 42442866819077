import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskMeasurePointReadingDto } from './ProcessTaskMeasurePointReadingDto';

export function createProcessTaskMeasurePointReadingEntityInfo<
  TEntity extends ProcessTaskMeasurePointReadingDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskMeasurePointReadingDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskMeasurePointReading,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskMeasurePointReading,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskMeasurePointReadingDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          ref: EntityName.ProcessTaskAppointment
        },
        processTaskMeasurePointId: {
          name: 'processTaskMeasurePointId',
          ref: EntityName.ProcessTaskMeasurePoint
        },
        date: {
          name: 'date'
        }
      },
      options.additionalFieldInfos
    )
  };
}
