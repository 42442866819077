import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { Utils } from '../../classes/Utils/Utils';
import {
  EntryFilterFilterer,
  FilterMode,
  ShowMode
} from '../../filterComponents/entry-filter/EntryFilterFilterer';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StructureListItem } from '../structure-list-item/structure-list-item';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntryUtils } from '../../classes/EntityManager/entities/Entry/EntryUtils';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { StructureTemplateUtils } from '../../classes/EntityManager/entities/StructureTemplate/StructureTemplateUtils';
import { ExpandableListComponent } from '../../classes/ExpandableListComponent';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { SelectedChoiceChangedEvent } from '../../inputComponents/selection-switch/selection-switch';

/**
 * @event edit-clicked - bubbles, detail: entry
 * @event add-entry - bubbles, detail: entry
 * @event entry-changed - bubbles, gets fired as soon the entry gets changed, useful for e.g. shadow entries, so they can be created if they have been changed, detail: { entry: Entry }
 * @event move-entry - detail: IMoveEntryEventDetail
 */
@autoinject()
export class StructureListTreeItem implements ExpandableListComponent {
  @bindable public entry: Entry | null = null;

  @bindable public project: Project | null = null;

  @bindable public showEntriesMode = ShowMode.ALL;

  @bindable public entryFilterString = '';

  @bindable public parentSelectedStatus: StatusSelectionSwitchChoice | null =
    null;

  @bindable public ignoreTextFilter = false;

  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  @subscribableLifecycle()
  protected readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private showHideAnimator: ShowHideAnimator | null = null;

  protected availableChildren: Array<Entry> = [];
  protected visibleChildren: Array<Entry> = [];
  protected childListTreeItems: Set<StructureListTreeItem> = new Set();

  /**
   * flag which determines if the sub entries are expanded or not
   * will receive it's final value before the animation is finished
   */
  protected showSubEntries = false;

  /**
   * determines if the subEntries should be rendered
   */
  protected renderSubEntries = false;

  protected ratingEnabled = false;

  private domElement: Element;
  protected subItemsElement: HTMLElement | null = null;

  private showAnimationFrame: number | null = null;

  private isAttached = false;

  protected boundHandleDragOverItem = this.handleDragOverItem.bind(this);
  protected boundHandleDragOutItem = this.handleDragOutItem.bind(this);
  private draggedOverTimeout: number | null = null;

  protected StructureListItem = StructureListItem;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element;

    this.subscriptionManager = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as StructureListTreeItem,
        propertyName: 'entry'
      });

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Project,
        context: this as StructureListTreeItem,
        propertyName: 'project'
      });
  }

  protected bind(): void {
    assertNotNullOrUndefined(
      this.subItemsElement,
      'subItemsElement is not available'
    );

    if (!this.showHideAnimator)
      this.showHideAnimator = new ShowHideAnimator(this.subItemsElement);
    this.subItemsElement.style.display = 'none';
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateChildren.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplate,
      this.updateEnabled.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateEnabled.bind(this)
    );
    this.updateChildren();
    this.updateEnabled();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private updateChildren(): void {
    if (this.entry) {
      this.availableChildren =
        this.entityManager.entryRepository.getSubEntriesOfEntry(this.entry);
    } else {
      this.availableChildren = [];
    }

    const filterer = new EntryFilterFilterer(
      this.entityManager,
      this.showEntriesMode,
      FilterMode.STRUCTURE_TEMPLATE_ENTRY_ID,
      this.ignoreTextFilter ? '' : this.entryFilterString
    );
    this.visibleChildren = filterer.filterEntries(this.availableChildren);
  }

  private updateEnabled(): void {
    if (!this.entry || !this.project || !this.project.structureTemplateId) {
      this.ratingEnabled = false;
      return;
    }

    const structureTemplate =
      this.entityManager.structureTemplateRepository.getById(
        this.project.structureTemplateId
      );
    const level = this.entityManager.entryRepository.getLevel(this.entry.id);
    this.ratingEnabled = StructureTemplateUtils.isRatingEnabledOnLevel(
      structureTemplate,
      level
    );
  }

  protected projectChanged(): void {
    this.updateEnabled();
  }

  protected showEntriesModeChanged(): void {
    this.updateChildren();
  }

  protected entryFilterStringChanged(): void {
    this.updateChildren();
  }

  protected entryHasFilterStringInName(
    entryName: string,
    filterString: string
  ): boolean {
    if (!filterString) return false;
    const upperCaseEntryName = entryName.toUpperCase();
    const upperCaseFilterString = filterString.toUpperCase();
    return upperCaseEntryName.indexOf(upperCaseFilterString) > -1;
  }

  protected toggleShowSubEntries(event: MouseEvent): void {
    if (event.defaultPrevented) return;
    if (this.showSubEntries) {
      this.collapseSubEntries();
    } else {
      void this.expandSubEntries(true);
    }
  }

  public async expandToEntry(entryParents: Array<Entry>): Promise<void> {
    if (!this.showSubEntries) {
      await this.expandSubEntries(false);
    }

    const entryParentsCopy = entryParents.slice();
    if (entryParentsCopy.length === 0) {
      await this.updateChildrenAndWaitForRender();
      return;
    }

    const entryParent = entryParentsCopy.pop();
    if (!entryParent) return;

    const parentElement = this.getElementForEntryId(entryParent.id);
    if (!parentElement) return;

    const parentElementVM =
      Utils.getViewModelOfElement<StructureListTreeItem>(parentElement);
    if (parentElementVM) {
      await parentElementVM.expandToEntry(entryParentsCopy);
    }
  }

  private updateChildrenAndWaitForRender(): Promise<void> {
    return new Promise((res) => {
      this.updateChildren();
      setTimeout(res, 0);
    });
  }

  public expandSubEntries(animate: boolean): Promise<void> {
    return new Promise((res) => {
      if (this.showSubEntries) res();
      this.renderSubEntries = true;
      this.showAnimationFrame = window.requestAnimationFrame(() => {
        const animationDuration = animate ? undefined : 0;
        this.showSubEntries = true;
        void this.showHideAnimator?.slideDown(animationDuration).then(() => {
          setTimeout(() => res(), 0);
        });
      });
    });
  }

  public async expandSubEntriesRecursively(animate: boolean): Promise<void> {
    await this.expandSubEntries(animate);
    for (const item of this.childListTreeItems) {
      void item.expandSubEntriesRecursively(animate);
    }
  }

  public collapseSubEntries(): void {
    if (!this.showSubEntries) return;
    if (this.showAnimationFrame)
      window.cancelAnimationFrame(this.showAnimationFrame);

    this.showSubEntries = false;

    void this.showHideAnimator?.slideUp().then(() => {
      this.renderSubEntries = false;
    });
  }

  public collapseSubEntriesRecursively(): void {
    this.collapseSubEntries();
    for (const item of this.childListTreeItems) {
      item.collapseSubEntriesRecursively();
    }
  }

  public isExpanded(): boolean {
    return this.showSubEntries;
  }

  @computed(expression('entry.structureTemplateEntryId'))
  private get structureTemplateEntry(): StructureTemplateEntry | null {
    if (!this.entry?.structureTemplateEntryId) return null;

    return this.entityManager.structureTemplateEntryRepository.getById(
      this.entry.structureTemplateEntryId
    );
  }

  @computed(
    expression('structureTemplateEntry.showExcludeButton'),
    expression('structureTemplateEntry.showCheckButton')
  )
  protected get statusSelectionSwitchChoices(): Array<StatusSelectionSwitchChoice> {
    const choices: Array<StatusSelectionSwitchChoice> = [];

    if (
      this.structureTemplateEntry?.showExcludeButton == null ||
      this.structureTemplateEntry.showExcludeButton
    )
      choices.push({
        name: Status.EXCLUDED,
        label: '<i class="far fa-minus"></i>'
      });

    if (
      this.structureTemplateEntry?.showCheckButton == null ||
      this.structureTemplateEntry.showCheckButton
    )
      choices.push({
        name: Status.CHECKED,
        label: '<i class="far fa-check"></i>'
      });

    return choices;
  }

  protected getPageDepthIndexOfEntry(entry: Entry): string {
    return EntryUtils.getPageDepthIndex(
      this.entityManager.entryRepository.getPathByEntryId(entry.id)
    );
  }

  @computed(expression('availableChildren'))
  private get childrenHaveNoOriginId(): boolean {
    if (!this.availableChildren || this.availableChildren.length === 0)
      return true;

    return this.availableChildren.some((child) => {
      return !child.structureTemplateEntryId && !child.originId;
    });
  }

  protected handleEditEntryClick(entry: Entry): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-clicked',
      bubbles: true,
      detail: entry
    });
  }

  protected handleAddEntryClick(event: MouseEvent): void {
    event.preventDefault();
    DomEventHelper.fireEvent(this.domElement, {
      name: 'add-entry',
      bubbles: true,
      detail: this.entry
    });
  }

  protected handleEntryChanged(): void {
    assertNotNullOrUndefined(this.entry, 'entry is not set');

    DomEventHelper.fireEvent(this.domElement, {
      name: 'entry-changed',
      bubbles: true,
      detail: { entry: this.entry }
    });

    this.entityManager.entryRepository.update(this.entry);
  }

  private getElementForEntryId(entryId: string): HTMLElement | null {
    assertNotNullOrUndefined(
      this.subItemsElement,
      'subItemsElement is not available'
    );
    return this.subItemsElement.querySelector(`#entry-${entryId}`);
  }

  protected getSubEntriesToRender(
    visibleEntries: Array<Entry>,
    renderSubEntries: boolean
  ): Array<Entry> {
    return renderSubEntries ? visibleEntries : [];
  }

  protected handleStatusSelectionSwitchChanged(
    event: SelectedChoiceChangedEvent<StatusSelectionSwitchChoice>
  ): void {
    assertNotNullOrUndefined(this.entry, 'entry is not set');

    const statusName = event.detail.selectedChoice?.name ?? '';

    this.handleEntryChanged();

    if (!this.selectedStatusProperty) {
      const newSelectedStatusProperty = {
        name: 'Status',
        value: statusName,
        entry: this.entry.id,
        ownerProjectId: this.entry.ownerProjectId,
        ownerUserGroupId: this.entry.ownerUserGroupId,
        active: true
      };
      this.entityManager.propertyRepository.create(newSelectedStatusProperty);
    } else {
      const selectedStatusProperty = this.selectedStatusProperty;

      selectedStatusProperty.value = statusName;
      selectedStatusProperty.active = true;

      this.entityManager.propertyRepository.update(selectedStatusProperty);
    }
  }

  protected entryChanged(): void {
    this.updateEnabled();
  }

  @computed(
    expression('isAttached'),
    expression('statusSelectionSwitchChoices'),
    expression('selectedStatusProperty.value')
  )
  private get selectedStatus(): StatusSelectionSwitchChoice | null {
    if (!this.isAttached) return null;

    const selectedStatusProperty = this.selectedStatusProperty;

    if (selectedStatusProperty) {
      return (
        this.statusSelectionSwitchChoices.find((choice) => {
          return choice.name === selectedStatusProperty.value;
        }) || null
      );
    } else {
      return null;
    }
  }

  @computed(expression('entry.id'), model(EntityName.Property))
  private get selectedStatusProperty(): Property | null {
    if (!this.entry) return null;

    return (
      this.entityManager.propertyRepository
        .getByEntryId(this.entry.id)
        .find((property) => {
          return property.name === 'Status';
        }) || null
    );
  }

  private handleDragOverItem(): void {
    if (!this.showSubEntries) {
      this.domElement.classList.add('structure-list-tree-item--DraggedOver');
      this.draggedOverTimeout = window.setTimeout(() => {
        void this.expandSubEntries(true);
      }, 800);
    }
  }

  private handleDragOutItem(): void {
    this.domElement.classList.remove('structure-list-tree-item--DraggedOver');
    if (this.draggedOverTimeout) {
      window.clearTimeout(this.draggedOverTimeout);
    }
  }

  protected handleDropItem(
    item: StructureListItem,
    entryToReplace: Entry | null
  ): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'move-entry',
      bubbles: true,
      detail: {
        entry: item.entry,
        parent: this.entry,
        listPosition: entryToReplace ? entryToReplace.list_position : null
      } as MoveEntryEventDetail
    });
  }

  protected handleActivateDropTarget(
    viewModel: StructureListItem,
    subEntry: Entry
  ): boolean {
    return viewModel.entry?.id !== subEntry.id;
  }

  @computed(
    expression('projectPermissionsHandle.canCreateEntries'),
    expression('childrenHaveNoOriginId'),
    expression('parentSelectedStatus'),
    expression('selectedStatus'),
    expression('structureTemplateEntry.alwaysAllowEntryCreation')
  )
  protected get showNewEntryButton(): boolean {
    return (
      this.projectPermissionsHandle.canCreateEntries &&
      this.childrenHaveNoOriginId &&
      (!this.parentSelectedStatus ||
        !!this.structureTemplateEntry?.alwaysAllowEntryCreation) &&
      (!this.selectedStatus ||
        !!this.structureTemplateEntry?.alwaysAllowEntryCreation)
    );
  }
}

type StatusSelectionSwitchChoice = {
  name: Status;
  label: string;
};

export type MoveEntryEventDetail = {
  entry: Entry;
  parent: Entry;
  listPosition: number | null;
};

export enum Status {
  CHECKED = 'checked',
  EXCLUDED = 'excluded'
}
