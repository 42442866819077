import { bindable, autoinject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';

/**
 * @event {TAppointmentClickedEvent} appointment-clicked
 */
@autoinject()
export class ProcessTaskAppointmentRelatedAppointmentsList {
  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  private subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private domElement: HTMLElement;

  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();
  private appointmentWithDateInfos: Array<AppointmentWithDateInfo> = [];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.updateAppointmentWithDateInfos.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateAppointmentWithDateInfos();
        }
      })
    );
    this.updateAppointmentWithDateInfos();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private appointmentChanged(): void {
    if (this.isAttached) {
      this.updateAppointmentWithDateInfos();
    }
  }

  private updateAppointmentWithDateInfos(): void {
    const appointment = this.appointment;
    if (appointment) {
      this.appointmentWithDateInfos =
        this.entityManager.processTaskAppointmentRepository
          .getByProcessTaskId(appointment.ownerProcessTaskId)
          .filter(
            (a) =>
              a.id === appointment.createdFromAppointmentId ||
              a.createdFromAppointmentId === appointment.id
          )
          .map((a) => ({
            appointment: a,
            dateInfo: this.processTaskAppointmentDateInfoMap.get(a.id)
          }))
          .filter((info): info is AppointmentWithDateInfo => !!info.dateInfo);
    } else {
      this.appointmentWithDateInfos = [];
    }
  }

  private handleAppointmentClick(appointment: ProcessTaskAppointment): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'appointment-clicked',
      detail: {
        appointment
      } as IAppointmentClickedEventDetail
    });
  }
}

export interface IAppointmentClickedEventDetail {
  appointment: ProcessTaskAppointment;
}

export type TAppointmentClickedEvent = Event & {
  detail: IAppointmentClickedEventDetail;
};

type AppointmentWithDateInfo = {
  appointment: ProcessTaskAppointment;
  dateInfo: ProcessTaskAppointmentDateInfo;
};
