import { autoinject, Container } from 'aurelia-dependency-injection';
import {
  TemplatingEngine,
  View as AureliaView,
  ViewResources
} from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

/**
 * a service to create an enhanced dom from strings
 */
@autoinject()
export class ViewCreationService {
  constructor(
    private readonly templatingEngine: TemplatingEngine,
    private readonly container: Container
  ) {}

  public async createViewForComponent<T extends ViewComponentClass>({
    component,
    initialBindables = {}
  }: CreateViewForComponentOptions<T>): Promise<ComponentView<T>> {
    const resources = new ViewResources();
    const resource = resources.autoRegister(this.container, component) as any; // needs to be cast as any because the aurelia typings are wrong/incomplete
    await resource.load(this.container, resource.target);

    const element = this.createElement({
      elementName: resource.elementName,
      initialBindables
    });

    const bindingContext: { viewModel: InstanceType<T> | null } & Record<
      string,
      unknown
    > = {
      ...initialBindables,
      viewModel: null
    };

    const aureliaView = this.templatingEngine.enhance({
      element,
      bindingContext,
      resources
    });

    assertNotNullOrUndefined(bindingContext.viewModel, 'no viewModel found');

    return new ComponentView<T>(element, bindingContext.viewModel, aureliaView);
  }

  private createElement({
    elementName,
    initialBindables
  }: {
    elementName: string;
    initialBindables: Record<string, unknown>;
  }): Element {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = `<${elementName} view-model.ref="viewModel" ${this.createBindingAttributeStringForInitialBindables(
      { initialBindables }
    )}></${elementName}>`;
    assertNotNullOrUndefined(
      wrapper.firstElementChild,
      'no firstElementChild found, this should never occur to begin with'
    );
    return wrapper.firstElementChild;
  }

  private createBindingAttributeStringForInitialBindables({
    initialBindables
  }: {
    initialBindables: Record<string, unknown>;
  }): string {
    let attributeString = '';

    for (const name of Object.keys(initialBindables)) {
      if (typeof name !== 'string') {
        continue;
      }

      attributeString += ` ${StringUtils.camelCaseToKebabCase(
        name
      )}.one-time="${name}"`;
    }

    return attributeString;
  }
}

export class ComponentView<T extends ViewComponentClass> {
  constructor(
    private readonly element: Element,
    private readonly viewModel: InstanceType<T>,
    private readonly aureliaView: AureliaView
  ) {}

  public getElement(): Element {
    return this.element;
  }

  public getViewModel(): InstanceType<T> {
    return this.viewModel;
  }

  /**
   * attaches the root element of the view, before the referenceElement
   */
  public attachBefore(referenceElement: Element): void {
    assertNotNullOrUndefined(
      referenceElement.parentNode,
      'the referenceElement has no parentNode'
    );

    referenceElement.parentNode.insertBefore(this.element, referenceElement);
    this.aureliaView.attached();
  }

  /**
   * attaches the root element as the last element in the container
   */
  public attachToContainer(container: Element): void {
    container.appendChild(this.element);
    this.aureliaView.attached();
  }

  public detach(): void {
    this.element.remove();
    this.aureliaView.detached();
    this.aureliaView.unbind();
  }
}

export type ViewComponentClass = new (...args: Array<any>) => any;

export type CreateViewForComponentOptions<T extends ViewComponentClass> = {
  component: T;
  /**
   * A map of the name of the bindable to the initial value.
   * The name should be in camelCase and NOT kebab-case
   */
  initialBindables?: Record<string, unknown>;
};
