import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructureTemplateEntryGroupToStructureTemplateEntryEntityInfo } from 'common/Types/Entities/StructureTemplateEntryGroupToStructureTemplateEntry/createStructureTemplateEntryGroupToStructureTemplateEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from './types';

export const structureTemplateEntryGroupToStructureTemplateEntryEntityInfo =
  createStructureTemplateEntryGroupToStructureTemplateEntryEntityInfo<StructureTemplateEntryGroupToStructureTemplateEntry>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
