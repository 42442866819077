import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskRecurringAppointmentDto } from './ProcessTaskRecurringAppointmentDto';

export function createProcessTaskRecurringAppointmentEntityInfo<
  TEntity extends ProcessTaskRecurringAppointmentDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskRecurringAppointmentDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskRecurringAppointment,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskRecurringAppointment,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskRecurringAppointmentDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        coordinatorUserId: {
          name: 'coordinatorUserId',
          ref: EntityName.User
        },
        processConfigurationStepId: {
          name: 'processConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        dateFrom: {
          name: 'dateFrom',
          defaultValue: null
        },
        startTime: {
          name: 'startTime',
          defaultValue: null
        },
        durationInMs: {
          name: 'durationInMs',
          defaultValue: null
        },
        recurrence: {
          name: 'recurrence',
          innerInfo: {
            byDays: {
              name: 'byDays',
              defaultValue: null
            },
            byMonthDays: {
              name: 'byMonthDays',
              defaultValue: null
            },
            byMonths: {
              name: 'byMonths',
              defaultValue: null
            },
            count: {
              name: 'count',
              defaultValue: null
            },
            frequency: {
              name: 'frequency'
            },
            interval: {
              name: 'interval',
              defaultValue: null
            },
            pos: {
              name: 'pos',
              defaultValue: null
            },
            until: {
              name: 'until',
              defaultValue: null
            }
          },
          defaultValue: null
        },
        assignedUserId: {
          name: 'assignedUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        excludedDates: {
          name: 'excludedDates',
          defaultValue: () => []
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        secondaryColor: {
          name: 'secondaryColor',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
