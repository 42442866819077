import { bindable, inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';

/**
 * apply this attribute to any (empty) container
 * this attribute will automagically set the backgroundColor + add the icon
 */
@inject(SubscriptionManagerService, Element)
export class ProcessConfigurationStepIconCustomAttribute {
  /** @type {import('../classes/EntityManager/entities/ProcessConfigurationStep/types').ProcessConfigurationStep|null} */
  @bindable processConfigurationStep = null;

  /**
   * active means the icon will be highlighted/have more emphasis
   *
   * @type {boolean}
   */
  @bindable active = false;

  /**
   * a custom text to show instead of the icon,
   * maximum allowed characters: 2
   *
   * @type {string|null}
   */
  @bindable customText = null;

  /**
   * @type {boolean}
   */
  @bindable warning = true;

  /** @type {HTMLElement} */
  _domElement;

  /** @type {boolean} */
  _attached = false;
  /** @type {HTMLElement|null} */
  _iconElement = null;
  /** @type {HTMLElement|null} */
  _customTextElement = null;
  /** @type {HTMLElement|null} */
  _warningIcon = null;

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {HTMLElement} element
   */
  constructor(subscriptionManagerService, element) {
    this._subscriptionManager = subscriptionManagerService.create();
    this._domElement = element;
  }

  attached() {
    this._attached = true;
    this._updateProcessConfigurationStepSubscription();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();

    if (this._iconElement) {
      this._domElement.removeChild(this._iconElement);
      this._iconElement = null;
    }
  }

  processConfigurationStepChanged() {
    if (this._attached) {
      this._updateProcessConfigurationStepSubscription();
    }
  }

  _updateProcessConfigurationStepSubscription() {
    this._subscriptionManager.disposeSubscriptions();
    if (this.processConfigurationStep) {
      this._subscriptionManager.subscribeToMultiplePropertyChanges(
        this.processConfigurationStep,
        ['iconClassName', 'iconColor', 'color'],
        this._updateIcon.bind(this)
      );

      this._subscriptionManager.subscribeToPropertyChange(
        this,
        'active',
        this._updateIcon.bind(this)
      );
    }

    this._updateIcon();
  }

  customTextChanged() {
    this._updateIcon();
  }

  warningChanged() {
    if (this.warning) {
      this._getOrCreateWarningIconElement();
    } else {
      this._removeWarningIconElement();
    }
  }

  _updateIcon() {
    const style = this._getStepStyleOrDefault();
    this._setDomElementStyle(style);

    if (this.customText) {
      this._removeIconElement();
      const textElement = this._getOrCreateCustomTextElement();
      this._setCustomTextElementStyle(textElement, style);
    } else {
      this._removeCustomTextElement();
      const iconElement = this._getOrCreateIconElement();
      this._setIconElementStyle(iconElement, style);
    }
  }

  /**
   * @param {TStyle} style
   */
  _setDomElementStyle(style) {
    this._domElement.style.borderRadius = '50%';
    this._domElement.style.border = '1px solid ' + style.color;
    this._domElement.style.backgroundColor = this.active
      ? style.color
      : 'transparent';
  }

  /**
   * @param {HTMLElement} iconElement
   * @param {TStyle} style
   */
  _setIconElementStyle(iconElement, style) {
    iconElement.className = 'record-it-icon ' + style.iconClassName;
    iconElement.style.color = this.active ? style.iconColor : style.color;
  }

  _removeIconElement() {
    if (this._iconElement && this._iconElement.parentNode) {
      this._iconElement.parentNode.removeChild(this._iconElement);
    }

    this._iconElement = null;
  }

  /**
   * @returns {HTMLElement}
   * @private
   */
  _getOrCreateIconElement() {
    if (!this._iconElement) {
      this._iconElement = document.createElement('i');
      this._domElement.appendChild(this._iconElement);
    }

    return this._iconElement;
  }

  /**
   * @param {HTMLElement} customTextElement
   * @param {TStyle} style
   */
  _setCustomTextElementStyle(customTextElement, style) {
    customTextElement.style.color = this.active ? style.iconColor : style.color;

    while (customTextElement.firstChild) {
      customTextElement.removeChild(customTextElement.firstChild);
    }

    const text = this.customText ? this.customText.substr(0, 3) : '';
    customTextElement.appendChild(document.createTextNode(text));
  }

  _removeCustomTextElement() {
    if (this._customTextElement && this._customTextElement.parentNode) {
      this._customTextElement.parentNode.removeChild(this._customTextElement);
    }

    this._customTextElement = null;
  }

  /**
   * @returns {HTMLElement}
   */
  _getOrCreateCustomTextElement() {
    if (!this._customTextElement) {
      this._customTextElement = document.createElement('span');
      this._domElement.appendChild(this._customTextElement);
    }

    return this._customTextElement;
  }

  _removeWarningIconElement() {
    if (this._warningIcon?.parentNode) {
      this._warningIcon.parentNode.removeChild(this._warningIcon);
    }

    this._warningIcon = null;
  }

  /**
   * @returns {HTMLElement}
   */
  _getOrCreateWarningIconElement() {
    if (!this._warningIcon) {
      this._warningIcon = document.createElement('i');
      this._warningIcon.className =
        'record-it-operations-step-icon-warning fas fa-exclamation';
      this._domElement.appendChild(this._warningIcon);
    }

    return this._warningIcon;
  }

  /**
   * @returns {TStyle}
   */
  _getStepStyleOrDefault() {
    const style = {
      color: '#fff',
      iconClassName: '',
      iconColor: '#5a5a5a'
    };

    if (this.processConfigurationStep) {
      style.color = this.processConfigurationStep.color || style.color;
      style.iconClassName =
        this.processConfigurationStep.iconClassName || style.iconClassName;
      style.iconColor =
        this.processConfigurationStep.iconColor || style.iconColor;
    }

    return style;
  }
}

/**
 * @typedef {Object} TStyle
 * @property {string} color
 * @property {string} iconClassName
 * @property {string} iconColor
 */
