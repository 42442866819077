import { computedFrom } from 'aurelia-framework';
import { ThingType } from '../../../classes/EntityManager/entities/ThingType/types';
import { ThingTypeAdapter } from '../EntityAdapter/ThingTypeAdapter/ThingTypeAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ThingTypePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ThingTypeAdapter>,
  ThingType
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }

  @computedFrom('combinedRevision')
  public get canAdministerValueCalculations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canAdministerValueCalculations'
    );
  }
}
