import { autoinject } from 'aurelia-framework';
import _ from 'lodash';

import { NestedKeys } from 'common/Types/utilities';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { AppEntityManager } from '../AppEntityManager';
import { UserCompanySettingUtils } from './UserCompanySettingUtils';
import { CurrentUserService } from '../User/CurrentUserService';
import { EntityName } from '../types';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { UserCompanySetting, UserCompanySettingCreationEntity } from './types';
import { Disposable } from '../../../Utils/DisposableContainer';

@autoinject()
export class ActiveUserCompanySettingService {
  private readonly subscriptionManager: SubscriptionManager;

  private bindingsByBindableKey: Partial<
    Record<BindablePropertyPaths, Array<Binding>>
  > = {};

  private activeSetting: UserCompanySettingCreationEntity =
    UserCompanySettingUtils.createDefaultUserCompanySetting();

  private fallbackActive: boolean = true;

  private parsedJsonPropertyCache: Map<string, unknown> = new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.currentUserService = currentUserService;
  }

  /**
   * call this in your app root
   */
  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(() => {
        this.updateActiveSetting();
      })
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserCompanySetting,
      () => {
        this.updateActiveSetting();
      }
    );

    this.updateActiveSetting();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public bindSettingProperty(
    propertyPath: BindablePropertyPaths,
    callback: (value: any) => void
  ): Disposable {
    const binding: Binding = {
      propertyPath,
      callback,
      type: BindingType.DEFAULT
    };

    callback(this.getSettingProperty(propertyPath));

    this.addBinding(binding);

    return {
      dispose: () => {
        this.removeBinding(binding);
      }
    };
  }

  public bindJSONSettingProperty(
    propertyPath: BindablePropertyPaths,
    callback: (value: any) => void
  ): Disposable {
    const binding: Binding = {
      propertyPath,
      callback,
      type: BindingType.JSON
    };

    callback(this.getCachedJSONValue(propertyPath));

    this.addBinding(binding);

    return {
      dispose: () => {
        this.removeBinding(binding);
      }
    };
  }

  /**
   * get the active setting property by the name/path
   * can NOT be used directly in the view because it will not update
   */
  public getSettingProperty(propertyPath: BindablePropertyPaths): any {
    return _.get(this.activeSetting, propertyPath);
  }

  /**
   * get the active setting property by the name/path and parses it into an object
   * should NOT be used directly in the view because it will not update
   */
  public getJSONSettingProperty(
    propertyPath: BindablePropertyPaths
  ): any | null {
    return this.getCachedJSONValue(propertyPath);
  }

  /**
   * if true, then a fitting userCompanySetting has been loaded (or a default one if the user doesn't have any userCompanySetting)
   * if false, then a default userCompanySetting has been loaded, because the user or the userCompanySetting hasn't be loaded/received yet
   */
  public fallbackIsActive(): boolean {
    return this.fallbackActive;
  }

  private addBinding(binding: Binding): void {
    let bindings = this.bindingsByBindableKey[binding.propertyPath];
    if (!bindings) {
      bindings = this.bindingsByBindableKey[binding.propertyPath] = [];
    }

    bindings.push(binding);
  }

  private removeBinding(binding: Binding): void {
    const bindings = this.bindingsByBindableKey[binding.propertyPath];
    if (bindings) {
      ArrayUtils.remove(bindings, binding);
    }
  }

  private getCachedJSONValue(propertyPath: BindablePropertyPaths): any {
    if (this.parsedJsonPropertyCache.has(propertyPath)) {
      return this.parsedJsonPropertyCache.get(propertyPath);
    }

    const propertyValue = this.getSettingProperty(propertyPath);
    const jsonValue = this.getJSONValue(propertyValue);
    this.parsedJsonPropertyCache.set(propertyPath, jsonValue);
    return jsonValue;
  }

  private getJSONValue(value: string): any {
    let returnValue = null;
    try {
      returnValue = JSON.parse(value);
    } catch (error) {
      // return null on purpose
    }
    return returnValue;
  }

  private updateActiveSetting(): void {
    const user = this.currentUserService.getCurrentUser();
    const settingId =
      user && user.userCompanySettingId ? user.userCompanySettingId : null;
    const setting = settingId
      ? this.entityManager.userCompanySettingRepository.getById(settingId)
      : null;

    this.setFallbackActive(!user || (!!user.userCompanySettingId && !setting));

    this.setActiveSettingWithDefaultValues(setting);
    this.parsedJsonPropertyCache.clear();
    this.updateAllBindings();
  }

  private setActiveSettingWithDefaultValues(
    setting: UserCompanySetting | null
  ): void {
    const defaultSetting =
      UserCompanySettingUtils.createDefaultUserCompanySetting();
    if (setting) {
      this.activeSetting = this.mergeSettings(defaultSetting, setting);
    } else {
      this.activeSetting = defaultSetting;
    }
  }

  private mergeSettings<T extends Record<string, unknown>>(
    defaultSetting: T | null,
    overrideSetting: T | null
  ): T {
    const setting: Partial<T> = {};

    if (defaultSetting) {
      for (const key of Object.keys(defaultSetting) as Array<keyof T>) {
        if (defaultSetting.hasOwnProperty(key)) {
          setting[key] = _.cloneDeep(defaultSetting[key]) as any;
        }
      }
    }

    if (overrideSetting) {
      for (const key of Object.keys(overrideSetting) as Array<keyof T>) {
        const value = overrideSetting[key];

        if (
          !overrideSetting.hasOwnProperty(key) ||
          value == null ||
          (typeof value === 'string' && value === '')
        ) {
          continue;
        }

        if ((value as any).constructor === Object) {
          setting[key] = this.mergeSettings(setting[key] as any, value) as any;
        } else {
          setting[key] = value;
        }
      }
    }

    return setting as T;
  }

  private updateAllBindings(): void {
    const entries = Object.entries(this.bindingsByBindableKey) as Array<
      [BindablePropertyPaths, Array<Binding>]
    >;

    for (const [propertyPath, bindings] of entries) {
      const value = this.getSettingProperty(propertyPath);
      const jsonValue = this.getCachedJSONValue(propertyPath);
      this.callBindings(bindings, value, jsonValue);
    }
  }

  private callBindings(
    bindings: Array<Binding>,
    value: any,
    jsonValue: any
  ): void {
    for (const binding of bindings) {
      switch (binding.type) {
        case BindingType.DEFAULT:
          binding.callback(value);
          break;

        case BindingType.JSON:
          binding.callback(jsonValue);
          break;

        default:
          throw new Error(`unhandled binding type "${binding.type}"`);
      }
    }
  }

  private setFallbackActive(fallbackActive: boolean): void {
    this.fallbackActive = fallbackActive;
  }
}

export type BindablePropertyPaths = NestedKeys<UserCompanySetting>;

type Binding = {
  propertyPath: BindablePropertyPaths;
  type: BindingType;
  callback: (value: any) => void;
};

enum BindingType {
  DEFAULT = 'default',
  JSON = 'json'
}
