import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { OriginalIdUtils } from '../../utils/OriginalIdUtils/OriginalIdUtils';
import { AppEntityManager } from '../AppEntityManager';
import {
  EntityIdUpdateService,
  UpdateConfigType
} from '../generalServices/EntityIdUpdateService';
import { EntityName } from '../types';
import { User } from '../User/types';
import { UserGroup } from './types';

@autoinject()
export class UserGroupIdUpdateService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly entityIdUpdateService: EntityIdUpdateService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityIdUpdateService.registerUpdateConfig<
        EntityName.UserGroup,
        EntityName.User
      >({
        type: UpdateConfigType.CUSTOM_CALLBACK,
        entityName: EntityName.UserGroup,
        referencedEntityName: EntityName.User,
        callback: this.removeUserReferences.bind(this)
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.User,
        this.updateUserReferences.bind(this)
      )
    );
  }

  public async flush(): Promise<void> {
    this.subscriptionManager.flush();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private removeUserReferences(userGroup: UserGroup, user: User): boolean {
    let modified = false;

    for (let i = userGroup.userSpecs.length - 1; i >= 0; i--) {
      const spec = userGroup.userSpecs[i];
      if (spec?._id === user.id) {
        userGroup.userSpecs.splice(i, 1);
        modified = true;
      }
    }

    return modified;
  }

  private updateUserReferences(user: User): void {
    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(user);

    for (const userGroup of this.entityManager.userGroupRepository.getAll()) {
      let modified = false;

      for (const spec of userGroup.userSpecs) {
        if (originalIds.includes(spec._id)) {
          spec._id = user.id;
          modified = true;
        }
      }

      if (modified) {
        this.entityManager.userGroupRepository.update(userGroup);
      }
    }
  }
}
