import { EventDispatcher } from '../classes/EventDispatcher/EventDispatcher';

export class LogoutLockService {
  private lockStatusMap: Map<LockName, number> = new Map();

  private eventDispatcher: EventDispatcher<EventDispatcherConfig> =
    new EventDispatcher();

  public setLockStatus(lockName: LockName, remainingCount: number): void {
    if (remainingCount > 0) {
      this.lockStatusMap.set(lockName, remainingCount);
    } else {
      this.lockStatusMap.delete(lockName);
    }
    this.notifySubscribers();
  }

  public subscribe(
    context: any,
    callback: (payload: Map<LockName, number>) => void
  ): void {
    this.eventDispatcher.addEventListener(context, 'lockStatus', callback);
  }

  public unsubscribeByContext(context: any): void {
    this.eventDispatcher.removeEventListenersByContext(context);
  }

  public getLockStatus(): Map<LockName, number> {
    return this.lockStatusMap;
  }

  public isAnyLockActive(): boolean {
    return this.lockStatusMap.size > 0;
  }

  public clearLocks(): void {
    this.lockStatusMap.clear();
    this.notifySubscribers();
  }

  private notifySubscribers(): void {
    this.eventDispatcher.dispatchEvent('lockStatus', this.lockStatusMap);
  }
}

export enum LockName {
  SYNCHRONIZATION = 'synchronization',
  FAILED_SYNCHRONIZATION = 'failedSynchronization',
  FILE_UPLOAD = 'fileUpload',
  FILE_UPLOAD_ITEMS_NOT_READY = 'fileUploadNotReady'
}

type EventDispatcherConfig = {
  lockStatus: Map<LockName, number>;
};
