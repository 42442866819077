import { SQLiteDBConnection } from '@capacitor-community/sqlite';

export class SqliteUtils {
  private constructor() {}

  public static async createKeyValueTable({
    store,
    tableName,
    ifNotExists,
    transaction
  }: {
    store: SQLiteDBConnection;
    tableName: string;
    ifNotExists: boolean;
    transaction: boolean;
  }): Promise<void> {
    await store.run(
      `CREATE TABLE${
        ifNotExists ? ' IF NOT EXISTS' : ''
      } ${tableName} (id INTEGER PRIMARY KEY, key unique, value)`,
      undefined,
      transaction
    );
  }

  public static async tableExists({
    store,
    tableName
  }: {
    store: SQLiteDBConnection;
    tableName: string;
  }): Promise<boolean> {
    const result = await store.query(
      "SELECT name FROM sqlite_master WHERE type='table' AND name=?",
      [tableName]
    );
    return !!result.values?.length;
  }
}
