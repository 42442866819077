import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { ThingToPerson } from '../../../classes/EntityManager/entities/ThingToPerson/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { CustomCheckboxCheckedChangedEvent } from '../../../inputComponents/custom-checkbox/custom-checkbox';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditThingPersonRelationsWidgetRelation {
  @bindable()
  public thingToPerson: ThingToPerson | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingToPerson];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.ThingToPerson,
        context: this as EditThingPersonRelationsWidgetRelation,
        propertyName: 'thingToPerson'
      }
    );
  }

  @computed(expression('thingToPerson.personId'), model(EntityName.Person))
  protected get person(): Person | null {
    if (!this.thingToPerson) {
      return null;
    }

    return this.entityManager.personRepository.getById(
      this.thingToPerson.personId
    );
  }

  protected handleDeleteThingToPersonClick(): void {
    const thingToPerson = this.thingToPerson;
    assertNotNullOrUndefined(
      thingToPerson,
      "can't EditThingPersonRelationsWidgetRelation.handleDeleteThingToPersonClick without thingToPerson"
    );

    void Dialogs.deleteEntityDialog(thingToPerson).then(() => {
      this.entityManager.thingToPersonRepository.delete(thingToPerson);
    });
  }

  protected handleMainContactCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.thingToPerson,
      "can't EditThingPersonRelationsWidgetRelation.handleMainContactCheckedChanged without thingToPerson"
    );
    if (event.detail.checked) {
      const thingToPersons =
        this.entityManager.thingToPersonRepository.getByThingId(
          this.thingToPerson.thingId
        );

      for (const thingToPerson of thingToPersons) {
        if (thingToPerson !== this.thingToPerson && thingToPerson.mainContact) {
          thingToPerson.mainContact = false;
          this.entityManager.thingToPersonRepository.update(thingToPerson);
        }
      }
    }

    this.thingToPerson.mainContact = event.detail.checked;
    this.entityManager.thingToPersonRepository.update(this.thingToPerson);
  }
}
