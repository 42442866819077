import { autoinject, bindable } from 'aurelia-framework';
import { StructureTemplateEntry } from '../../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';

@autoinject()
export class RapidFireEntrySelectEntryButton {
  @bindable()
  public entry: Entry | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected structureTemplateEntry: StructureTemplateEntry | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateEntry.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'entry.structureTemplateEntryId',
      this.updateStructureTemplateEntry.bind(this)
    );
    this.updateStructureTemplateEntry();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateStructureTemplateEntry(): void {
    if (this.entry?.structureTemplateEntryId) {
      this.structureTemplateEntry =
        this.entityManager.structureTemplateEntryRepository.getById(
          this.entry.structureTemplateEntryId
        );
    } else {
      this.structureTemplateEntry = null;
    }
  }
}
