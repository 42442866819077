import {
  ProcessConfigurationPositionType,
  ProcessConfigurationPositionTypeToConfiguration,
  defaultConfiguration,
  TProcessConfigurationPositionType
} from '../Enums/ProcessConfigurationPositionType';
import { ProcessConfigurationCustomPositionTypeConfiguration } from '../Types/ProcessConfigurationCustomPositionTypeConfiguration';
import { NumberUtils } from '../Utils/NumberUtils';

export class ProcessTaskPositionCalculator {
  constructor(
    private readonly customTypesConfig: ProcessConfigurationCustomPositionTypeConfiguration | null
  ) {}

  public calculatePricesOfPositions<T, TDetailEntry>(
    positionInfos: Array<PositionInfo<T, TDetailEntry>>
  ): Array<PositionPrices<T, TDetailEntry>> {
    const defaultInfos = [];
    const markupInfos = [];
    const otherInfos = [];

    for (const info of positionInfos) {
      if (
        info.type == null ||
        info.type === ProcessConfigurationPositionType.DEFAULT
      ) {
        defaultInfos.push(info);
      } else if (info.type === ProcessConfigurationPositionType.MARKUP) {
        markupInfos.push(info);
      } else {
        otherInfos.push(info);
      }
    }

    const defaultPricesResult =
      this.calculateDefaultPositionInfosPrices(defaultInfos);
    let results = defaultPricesResult.results;

    const markupPricesResult = this.calculateMarkupPositionInfosPrices(
      markupInfos,
      defaultPricesResult.markupPositionCalculationOtherPositionInfos
    );
    results = results.concat(markupPricesResult.results);

    const otherPrices = otherInfos.map<PositionPrices<T, TDetailEntry>>(
      (info) => {
        return {
          fullPrice: 0,
          fullPriceWithoutDiscount: 0,
          positionInfo: info,
          originalPosition: info.originalPosition
        };
      }
    );

    return results.concat(otherPrices);
  }

  public getFullPrice({
    type,
    customType,
    amount,
    price,
    flatRate,
    discount,
    ignoreExcludeFromMarkup,
    ownerProcessTaskId,
    detailEnabled,
    detailEntryInfos,
    markupPositionCalculationOtherPositionInfos
  }: CommonArgs<
    | 'type'
    | 'customType'
    | 'amount'
    | 'price'
    | 'flatRate'
    | 'discount'
    | 'ignoreExcludeFromMarkup'
    | 'ownerProcessTaskId'
    | 'detailEnabled'
    | 'detailEntryInfos'
    | 'markupPositionCalculationOtherPositionInfos'
  >): number {
    switch (type) {
      case ProcessConfigurationPositionType.MARKUP:
        return this.getMarkupPositionFullPrice({
          customType,
          price,
          discount,
          ignoreExcludeFromMarkup,
          ownerProcessTaskId,
          markupPositionCalculationOtherPositionInfos
        });
      default:
        return this.getDefaultPositionFullPrice({
          customType,
          amount,
          price,
          flatRate,
          discount,
          detailEnabled,
          detailEntryInfos
        });
    }
  }

  /**
   * get the discount value disregarding the amount
   */
  public getDiscountValue({
    price,
    discount
  }: CommonArgs<'price' | 'discount'>): number {
    return price * (discount / 100);
  }

  public getDiscountedPrice({
    price,
    discount
  }: CommonArgs<'price' | 'discount'>): number {
    return (price * (100 - discount)) / 100;
  }

  /**
   * get the actual amount of a position
   * the amount property can't be used directly because it could be calculated from the detailEntries
   * the amount is automatically rounded to prevent floating point precision errors
   */
  public getAmount({
    type,
    amount,
    detailEnabled,
    detailEntryInfos
  }: CommonArgs<
    'type' | 'amount' | 'detailEnabled' | 'detailEntryInfos'
  >): number {
    const config =
      ProcessConfigurationPositionTypeToConfiguration.get(type) ??
      defaultConfiguration;

    if (!config.canHaveDetail || !detailEnabled) {
      return amount;
    }

    return NumberUtils.sumNumbersWithMaxPrecision(
      ...detailEntryInfos.map((e) => e.amount)
    );
  }

  public createMarkupPositionCalculationOtherPositionInfos<T, TDetail>(
    prices: Array<PositionPrices<T, TDetail>>
  ): MarkupPositionCalculationOtherPositionInfos {
    const markupPositionCalculationOtherPositionInfos: MarkupPositionCalculationOtherPositionInfos =
      {
        sumOfDefaultPositionsByProcessTaskId: new Map(),
        sumOfDefaultPositionsWhichAreIncludedInMarkupByProcessTaskId: new Map()
      };

    for (const item of prices) {
      if (
        item.positionInfo.type !== ProcessConfigurationPositionType.DEFAULT &&
        item.positionInfo.type != null
      ) {
        continue; // skip non default prices
      }

      this.addPricesToSumMap({
        prices: item,
        processTaskId: item.positionInfo.ownerProcessTaskId,
        sumPricesMap:
          markupPositionCalculationOtherPositionInfos.sumOfDefaultPositionsByProcessTaskId
      });

      if (!item.positionInfo.excludeFromMarkup) {
        this.addPricesToSumMap({
          prices: item,
          processTaskId: item.positionInfo.ownerProcessTaskId,
          sumPricesMap:
            markupPositionCalculationOtherPositionInfos.sumOfDefaultPositionsWhichAreIncludedInMarkupByProcessTaskId
        });
      }
    }

    return markupPositionCalculationOtherPositionInfos;
  }

  public getMarkupBaseAmount({
    ignoreExcludeFromMarkup,
    ownerProcessTaskId,
    markupPositionCalculationOtherPositionInfos
  }: CommonArgs<
    | 'ignoreExcludeFromMarkup'
    | 'ownerProcessTaskId'
    | 'markupPositionCalculationOtherPositionInfos'
  >): number {
    const pricesMap = ignoreExcludeFromMarkup
      ? markupPositionCalculationOtherPositionInfos.sumOfDefaultPositionsByProcessTaskId
      : markupPositionCalculationOtherPositionInfos.sumOfDefaultPositionsWhichAreIncludedInMarkupByProcessTaskId;
    const prices = pricesMap.get(ownerProcessTaskId);

    return prices?.fullPrice ?? 0;
  }

  private addPricesToSumMap({
    prices,
    processTaskId,
    sumPricesMap
  }: {
    prices: Prices;
    processTaskId: string;
    sumPricesMap: Map<string, Prices>;
  }): void {
    let sumPrices = sumPricesMap.get(processTaskId);
    if (!sumPrices) {
      sumPrices = this.createEmptyPrices();
      sumPricesMap.set(processTaskId, sumPrices);
    }

    sumPrices.fullPrice += prices.fullPrice;
    sumPrices.fullPriceWithoutDiscount += prices.fullPriceWithoutDiscount;
  }

  private calculateDefaultPositionInfosPrices<T, TDetail>(
    defaultInfos: Array<PositionInfo<T, TDetail>>
  ): {
    markupPositionCalculationOtherPositionInfos: MarkupPositionCalculationOtherPositionInfos;
    results: Array<PositionPrices<T, TDetail>>;
  } {
    const results = defaultInfos.map<PositionPrices<T, TDetail>>((info) => {
      return {
        fullPrice: this.getDefaultPositionFullPrice({
          customType: info.customType,
          amount: info.amount,
          price: info.price,
          flatRate: info.flatRate,
          discount: info.discount,
          detailEnabled: info.detailEnabled,
          detailEntryInfos: info.detailEntryInfos
        }),
        fullPriceWithoutDiscount:
          this.getDefaultPositionFullPriceWithoutDiscount({
            customType: info.customType,
            amount: info.amount,
            price: info.price,
            flatRate: info.flatRate,
            detailEnabled: info.detailEnabled,
            detailEntryInfos: info.detailEntryInfos
          }),
        positionInfo: info,
        originalPosition: info.originalPosition
      };
    });

    return {
      markupPositionCalculationOtherPositionInfos:
        this.createMarkupPositionCalculationOtherPositionInfos(results),
      results: results
    };
  }

  private calculateMarkupPositionInfosPrices<T, TDetail>(
    markupInfos: Array<PositionInfo<T, TDetail>>,
    markupPositionCalculationOtherPositionInfos: MarkupPositionCalculationOtherPositionInfos
  ): { results: Array<PositionPrices<T, TDetail>> } {
    const results = markupInfos.map((info) => {
      return {
        markupBaseAmount:
          info.type === ProcessConfigurationPositionType.MARKUP
            ? this.getMarkupBaseAmount({
                ignoreExcludeFromMarkup: info.ignoreExcludeFromMarkup,
                ownerProcessTaskId: info.ownerProcessTaskId,
                markupPositionCalculationOtherPositionInfos
              })
            : null,
        fullPrice: this.getMarkupPositionFullPrice({
          customType: info.customType,
          price: info.price,
          discount: info.discount,
          ignoreExcludeFromMarkup: info.ignoreExcludeFromMarkup,
          ownerProcessTaskId: info.ownerProcessTaskId,
          markupPositionCalculationOtherPositionInfos
        }),
        fullPriceWithoutDiscount:
          this.getMarkupPositionFullPriceWithoutDiscount({
            customType: info.customType,
            price: info.price,
            ignoreExcludeFromMarkup: info.ignoreExcludeFromMarkup,
            ownerProcessTaskId: info.ownerProcessTaskId,
            markupPositionCalculationOtherPositionInfos
          }),
        positionInfo: info,
        originalPosition: info.originalPosition
      };
    });

    return { results: results };
  }

  private createEmptyPrices(): Prices {
    return {
      fullPrice: 0,
      fullPriceWithoutDiscount: 0
    };
  }

  private getDefaultPositionFullPrice({
    customType,
    amount,
    price,
    flatRate,
    discount,
    detailEnabled,
    detailEntryInfos
  }: CommonArgs<
    | 'customType'
    | 'amount'
    | 'price'
    | 'flatRate'
    | 'discount'
    | 'detailEnabled'
    | 'detailEntryInfos'
  >): number {
    const priceWithoutDiscount =
      this.getDefaultPositionFullPriceWithoutDiscount({
        customType,
        amount,
        price,
        flatRate,
        detailEnabled,
        detailEntryInfos
      });

    return priceWithoutDiscount * ((100 - discount) / 100);
  }

  private getDefaultPositionFullPriceWithoutDiscount({
    customType,
    amount,
    price,
    flatRate,
    detailEnabled,
    detailEntryInfos
  }: CommonArgs<
    | 'customType'
    | 'amount'
    | 'price'
    | 'flatRate'
    | 'detailEnabled'
    | 'detailEntryInfos'
  >): number {
    const relevantAmount = this.getAmount({
      type: ProcessConfigurationPositionType.DEFAULT,
      amount,
      detailEnabled,
      detailEntryInfos
    });
    const fullPrice = flatRate ? price : relevantAmount * price;
    return fullPrice * this.getPriceFactorFromCustomType({ customType });
  }

  private getMarkupPositionFullPrice({
    customType,
    price,
    discount,
    ignoreExcludeFromMarkup,
    ownerProcessTaskId,
    markupPositionCalculationOtherPositionInfos
  }: CommonArgs<
    | 'customType'
    | 'price'
    | 'discount'
    | 'ignoreExcludeFromMarkup'
    | 'ownerProcessTaskId'
    | 'markupPositionCalculationOtherPositionInfos'
  >): number {
    const priceWithoutDiscount = this.getMarkupPositionFullPriceWithoutDiscount(
      {
        customType,
        price,
        ignoreExcludeFromMarkup,
        ownerProcessTaskId,
        markupPositionCalculationOtherPositionInfos
      }
    );

    return priceWithoutDiscount * ((100 - discount) / 100);
  }

  private getMarkupPositionFullPriceWithoutDiscount({
    customType,
    price,
    ignoreExcludeFromMarkup,
    ownerProcessTaskId,
    markupPositionCalculationOtherPositionInfos
  }: CommonArgs<
    | 'customType'
    | 'price'
    | 'ignoreExcludeFromMarkup'
    | 'ownerProcessTaskId'
    | 'markupPositionCalculationOtherPositionInfos'
  >): number {
    const fullPrice =
      (this.getMarkupBaseAmount({
        ignoreExcludeFromMarkup,
        ownerProcessTaskId,
        markupPositionCalculationOtherPositionInfos
      }) *
        price) /
      100;
    return fullPrice * this.getPriceFactorFromCustomType({ customType });
  }

  private getPriceFactorFromCustomType({
    customType
  }: CommonArgs<'customType'>): number {
    if (
      customType &&
      this.customTypesConfig?.typesMap[customType]?.disablePricing
    ) {
      return 0;
    } else {
      return 1;
    }
  }
}

export type PositionInfo<T, TDetailEntry> = {
  ownerProcessTaskId: string;
  type: TProcessConfigurationPositionType;
  customType: string | null;
  amount: number;
  price: number;
  flatRate: boolean;
  discount: number;
  detailEnabled: boolean;
  detailEntryInfos: Array<PositionDetailEntryInfo<TDetailEntry>>;
  excludeFromMarkup: boolean;
  ignoreExcludeFromMarkup: boolean;
  originalPosition: T;
};

export type Prices = {
  fullPrice: number;
  fullPriceWithoutDiscount: number;
  markupBaseAmount?: number | null;
};

export type PositionPrices<T, TDetailEntry> = Prices & {
  positionInfo: PositionInfo<T, TDetailEntry>;
  originalPosition: T;
};

/**
 * sum of all position prices which are relevant for the markup positions
 * key of the maps are the processTaskId
 */
export type MarkupPositionCalculationOtherPositionInfos = {
  sumOfDefaultPositionsWhichAreIncludedInMarkupByProcessTaskId: Map<
    string,
    Prices
  >;
  sumOfDefaultPositionsByProcessTaskId: Map<string, Prices>;
};

export type PositionDetailEntryInfo<T> = {
  amount: number;
  originalDetailEntry: T;
};

type PossibleCommonArgs = {
  type: TProcessConfigurationPositionType;
  price: number;
  amount: number;
  discount: number;
  ignoreExcludeFromMarkup: boolean;
  ownerProcessTaskId: string;
  customType: string | null;
  flatRate: boolean;
  detailEnabled: boolean;
  detailEntryInfos: Array<PositionDetailEntryInfo<unknown>>;
  markupPositionCalculationOtherPositionInfos: MarkupPositionCalculationOtherPositionInfos;
};

type CommonArgs<T extends keyof PossibleCommonArgs> = Pick<
  PossibleCommonArgs,
  T
>;
