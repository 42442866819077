import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  fallbackProcessTaskToProjectTitleCache,
  ProcessTaskToProjectTitleCache,
  ProcessTaskToProjectTitleCacheComputer
} from '../../computedValues/computers/ProcessTaskToProjectTitleCacheComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class SendOperationsEmailDialogPictureSelect {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskToProject: ProcessTaskToProject | null = null;

  @bindable()
  public selectedEntryIds: Array<string> = [];

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private appointment: ProcessTaskAppointment | null = null;
  private title: string = '';
  private processTaskToProjectTitleCache: ProcessTaskToProjectTitleCache =
    fallbackProcessTaskToProjectTitleCache;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskToProjectTitleCacheComputer,
        computeData: {},
        callback: (processTaskToProjectTitleCache) => {
          this.processTaskToProjectTitleCache = processTaskToProjectTitleCache;
          this.updateTitle();
        }
      })
    );

    this.updateAppointment();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskToProjectChanged(): void {
    if (this.isAttached) {
      this.updateAppointment();
    }
  }

  private updateAppointment(): void {
    if (this.processTaskToProject) {
      this.appointment = this.processTaskToProject.processTaskAppointmentId
        ? this.entityManager.processTaskAppointmentRepository.getById(
            this.processTaskToProject.processTaskAppointmentId
          )
        : null;
    } else {
      this.appointment = null;
    }
  }

  private updateTitle(): void {
    if (this.processTaskToProject) {
      this.title =
        this.processTaskToProjectTitleCache.getTitleForRelation(
          this.processTaskToProject
        ) ?? '';
    } else {
      this.title = '';
    }
  }
}
