import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TValueChangedEvent } from '../../personComponents/person-select/person-select';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyPersonWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyPersonWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyPersonWidget doesn't support focus");
  }

  protected handlePersonSelected(event: TValueChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyPersonWidget.handlePersonSelected without configuration"
    );

    this.configuration.binding.setPersonValue({
      person: event.detail.selectedPerson
    });
  }
}

export const basePropertyPersonWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default', 'person']
  });
