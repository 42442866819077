import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { QuestionCategoryDto } from './QuestionCategoryDto';

export function createQuestionCategoryEntityInfo<
  TEntity extends QuestionCategoryDto<string, string>
>(
  options: CreateEntityInfoOptions<QuestionCategoryDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.QuestionCategory,
  TEntity
> {
  return {
    entityName: EntityName.QuestionCategory,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      QuestionCategoryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: ''
        },
        copy: {
          name: 'copy',
          defaultValue: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
