import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import _ from 'lodash';
import { Opt } from 'common/Opt';

import {
  AbstractLogDataConverter,
  ConvertOptions,
  SubscribeOptions
} from './AbstractLogDataConverter';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@autoinject()
export class DisplayNameOfEntityConverter extends AbstractLogDataConverter {
  private readonly displayNameHandlerByEntityName: DisplayNameHandlerByEntityName;
  private readonly entityNameValues = new Set<string>(
    Object.values(EntityName)
  );

  constructor(
    private readonly i18n: I18N,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    entityManager: AppEntityManager
  ) {
    super();

    this.displayNameHandlerByEntityName = {
      [EntityName.ProcessTaskAppointment]: ({ entityId }) => {
        let appointment =
          entityManager.processTaskAppointmentRepository.getById(entityId);

        if (!appointment) {
          appointment =
            entityManager.processTaskAppointmentRepository.getByOriginalId(
              entityId
            );
        }

        if (!appointment) {
          return Opt.none();
        }

        return Opt.some(appointment.name);
      },
      [EntityName.ProcessTaskPosition]: ({ entityId }) => {
        let position =
          entityManager.processTaskPositionRepository.getById(entityId);

        if (!position) {
          position =
            entityManager.processTaskPositionRepository.getByOriginalId(
              entityId
            );
        }

        if (!position) {
          return Opt.none();
        }

        return Opt.some(position.name);
      }
    };
  }

  public subscribe({ onDependenciesChanged }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    for (const entityName of Object.keys(
      this.displayNameHandlerByEntityName
    ) as Array<EntityName>) {
      subscriptionManager.subscribeToModelChanges(
        entityName,
        onDependenciesChanged
      );
    }

    return subscriptionManager.toDisposable();
  }

  public convert({
    logData,
    args: { entityNamePath, entityIdPath, fallbackNamePath }
  }: ConvertOptions<
    'entityNamePath' | 'entityIdPath' | 'fallbackNamePath'
  >): void {
    const { displayName, displayNameWithoutFallbackInQuotesWithSpaceBefore } =
      this.buildDisplayNameData({
        logData,
        entityNamePath,
        entityIdPath,
        fallbackNamePath
      });

    logData.displayName = displayName;
    logData.displayNameWithoutFallbackInQuotesWithSpaceBefore =
      displayNameWithoutFallbackInQuotesWithSpaceBefore;
  }

  private buildDisplayNameData({
    logData,
    entityNamePath,
    entityIdPath,
    fallbackNamePath
  }: {
    logData: Record<string, any>;
    entityNamePath: string;
    entityIdPath: string;
    fallbackNamePath: string;
  }): DisplayNameData {
    const result = this.resolveEntityNameAndEntityId({
      logData,
      entityNamePath,
      entityIdPath
    });

    if (!result.success) {
      return result.displayNameData;
    }

    const fallbackText = this.i18n.tr(`models.${result.entityName}`);
    const displayName = this.getDisplayNameOfEntity({
      logData,
      fallbackNamePath,
      entityName: result.entityName,
      entityId: result.entityId
    });

    if (displayName.isSome()) {
      return {
        displayName: displayName.getVal() ?? fallbackText,
        displayNameWithoutFallbackInQuotesWithSpaceBefore: ` "${
          displayName.getVal() ?? ''
        }"`
      };
    }

    return {
      displayName: fallbackText,
      displayNameWithoutFallbackInQuotesWithSpaceBefore: ''
    };
  }

  private getDisplayNameOfEntity({
    entityName,
    entityId,
    logData,
    fallbackNamePath
  }: {
    entityName: EntityName;
    entityId: string | null;
    logData: Record<string, any>;
    fallbackNamePath: string;
  }): Opt<string | null> {
    const handler = this.displayNameHandlerByEntityName[entityName];

    if (!entityId || !handler) {
      return Opt.none();
    }

    const name = handler({
      entityId
    });

    if (name.isSome()) {
      return name;
    }

    const fallbackName = _.get(logData, fallbackNamePath);

    if (typeof fallbackName === 'string') {
      return Opt.some(fallbackName);
    }

    return Opt.none();
  }

  private resolveEntityNameAndEntityId({
    logData,
    entityNamePath,
    entityIdPath
  }: {
    logData: Record<string, any>;
    entityNamePath: string;
    entityIdPath: string;
  }): ResolveEntityNameAndEntityIdResult {
    const rawEntityName = _.get(logData, entityNamePath);

    if (typeof rawEntityName !== 'string') {
      return this.getResolveEntityNameAndEntityIdErrorResult({
        displayName: 'Error: entityName is not a string'
      });
    }

    const entityName = this.entityNameValues.has(rawEntityName)
      ? (rawEntityName as EntityName)
      : null;
    if (!entityName) {
      return this.getResolveEntityNameAndEntityIdErrorResult({
        displayName: 'Error: entityName is a string but not an entityName'
      });
    }

    const entityId = _.get(logData, entityIdPath);
    if (typeof entityId != null && typeof entityId !== 'string') {
      return this.getResolveEntityNameAndEntityIdErrorResult({
        displayName: 'Error: entityId is not a string'
      });
    }

    return {
      success: true,
      entityName,
      entityId
    };
  }

  private getResolveEntityNameAndEntityIdErrorResult({
    displayName
  }: {
    displayName: string;
  }): ResolveEntityNameAndEntityIdErrorResult {
    return {
      success: false,
      displayNameData: {
        displayName: displayName,
        displayNameWithoutFallbackInQuotesWithSpaceBefore: ` "${displayName}"`
      }
    };
  }
}

type DisplayNameData = {
  displayName: string;
  displayNameWithoutFallbackInQuotesWithSpaceBefore: string;
};

type ResolveEntityNameAndEntityIdResult =
  | ResolveEntityNameAndEntityIdSuccessResult
  | ResolveEntityNameAndEntityIdErrorResult;

type ResolveEntityNameAndEntityIdSuccessResult = {
  success: true;
  entityName: EntityName;
  entityId: string;
};

type ResolveEntityNameAndEntityIdErrorResult = {
  success: false;
  displayNameData: DisplayNameData;
};

type DisplayNameHandlerByEntityName = Partial<
  Record<EntityName, DisplayNameHandler>
>;
type DisplayNameHandler = (options: { entityId: string }) => Opt<string | null>;
