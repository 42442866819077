import { EventAggregator } from 'aurelia-event-aggregator';
import { EntityInfoUtils } from '@record-it-npm/synchro-common';
import { NotificationHelper } from './NotificationHelper';
import { Dialogs } from './Dialogs';
import { WildixXmppSocketEventListener } from './WildixXmppSocketEventListener';
import { SocketService } from '../services/SocketService';
import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { I18N } from 'aurelia-i18n';
import { NavigationService } from '../services/NavigationService';
import { AsteriskAmiSocketEventListener } from './AsteriskAmiSocketEventListener';

export class SocketEventListeners {
  private readonly eventAggregator: EventAggregator;
  private readonly socketService: SocketService;
  private readonly entityManager: AppEntityManager;
  private readonly i18n: I18N;
  private readonly wildixXmppSocketEventListener =
    new WildixXmppSocketEventListener();
  private readonly asteriskAmiSocketEventListener =
    new AsteriskAmiSocketEventListener();

  private readonly navigationService: NavigationService;

  constructor(options: Options) {
    this.eventAggregator = options.eventAggregator;
    this.socketService = options.socketService;
    this.entityManager = options.entityManager;
    this.i18n = options.i18n;
    this.navigationService = options.navigationService;
  }

  public init(): void {
    this.socketService.addListener(
      'disconnect user',
      this.onDisconnectUser.bind(this)
    );
    this.socketService.addListener(
      'updated entity',
      this.onUpdatedEntity.bind(this)
    );
    this.socketService.addListener(
      'export success',
      this.onExportSuccess.bind(this)
    );
    this.socketService.addListener(
      'export failed',
      this.onExportFailed.bind(this)
    );

    this.socketService.addListener(
      'wildix xmpp',
      this.wildixXmppSocketEventListener.listener.bind(
        this.wildixXmppSocketEventListener
      )
    );
    this.socketService.addListener(
      'AsteriskAmiConnector',
      this.asteriskAmiSocketEventListener.listener.bind(
        this.asteriskAmiSocketEventListener
      )
    );
  }

  private onDisconnectUser(data): void {
    console.log('SOCKET EVENT LISTENERS: user disconnected', data);
    this.eventAggregator.publish('socket:user disconnected', data);
  }

  private onUpdatedEntity(data): void {
    console.log('SOCKET EVENT LISTENERS: updated entity', data);
    try {
      const repository =
        this.entityManager.entityRepositoryContainer.getByEntityName(
          data.entity_name
        );
      if (!data.deleted) {
        repository.addExternalEntityLocally(data);
      } else {
        EntityInfoUtils.applyDefaultValues(repository.getEntityInfo(), data);
        repository.removeLocally(data);
      }
    } catch (e) {
      // ignore repositories which couldn't be found
      // this happens when a new entity is implemented but it doesn't exist on the client yet
      if (e && e.message.startsWith('no repository found for')) {
        console.error(e);
      } else {
        throw e;
      }
    }
  }

  private onExportSuccess(data): void {
    console.log('SOCKET EVENT LISTENERS: export finished:', data);
    let notification: NotifyReturn | null = null;
    if (data.success) {
      const url = this.getExportFinishedUrl(data);
      if (url) {
        notification = NotificationHelper.notifyExportSuccess(
          'Export erfolgreich abgeschlossen.',
          url
        );
      }
    }

    if (notification && notification.$ele) {
      notification.$ele.find('[data-notify=url]').on('click', () => {
        notification?.$ele.find('[data-notify=dismiss]').click(); // click close button
      });
    }
  }

  private onExportFailed(data): void {
    console.error(data);
    let message = 'Es ist ein Fehler aufgetreten';

    if (data.error && data.error.properties) {
      message = this.createErrorMessageFromExportFailedErrorProperties(
        data.error.properties
      );
    } else if (data.status) {
      message = this.i18n.tr(`serverResponses.${data.status}`);
    }

    const title = 'Bericht konnte leider nicht erstellt werden!';
    Dialogs.warningDialog(data.message ? data.message : title, message);
  }

  private createErrorMessageFromExportFailedErrorProperties(
    errorProperties
  ): string {
    let message = errorProperties.explanation;

    if (errorProperties.errors) {
      message += '\n';
      message += this.compileMultiErrorMessage(errorProperties.errors);
    }

    return message;
  }

  private compileMultiErrorMessage(errors: Array<DocxTemplaterError>): string {
    let message = '';

    errors.forEach((error) => {
      message += '\n' + error.properties.explanation;
    });

    return message;
  }

  private getExportFinishedUrl(data): string | null {
    if (!data.projectId) {
      return null;
    }

    const project = this.entityManager.projectRepository.getById(
      data.projectId
    );

    if (project) {
      return this.navigationService.getExportPageUrl(project);
    } else {
      return null;
    }
  }
}

export type Options = {
  eventAggregator: EventAggregator;
  socketService: SocketService;
  entityManager: AppEntityManager;
  i18n: I18N;
  navigationService: NavigationService;
};

type DocxTemplaterError = {
  name: string;
  message: string;
  properties: DocxTemplaterErrorProperties;
  stack: string;
};

type DocxTemplaterErrorProperties = {
  id: string;
  explanation: string;
  errors?: Array<DocxTemplaterError>;
};
