/**
 * provides a default value in both directions in case the value is null or undefined
 */
export class DefaultValueValueConverter {
  /**
   * @param {any} value
   * @param {any} defaultValue
   * @returns {any}
   */
  toView(value, defaultValue = null) {
    return value == null ? defaultValue : value;
  }

  /**
   * @param {any} value
   * @param {any} defaultValue
   * @returns {any}
   */
  fromView(value, defaultValue = null) {
    return value == null ? defaultValue : value;
  }
}
