import { Utils } from '../../../Utils/Utils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  StructureTemplateEntry,
  StructureTemplateEntryCreationEntity
} from './types';

export class StructureTemplateEntryRepository extends AppEntityRepository<EntityName.StructureTemplateEntry> {
  public create(
    creationEntity: StructureTemplateEntryCreationEntity
  ): StructureTemplateEntry {
    const desiredListPosition = creationEntity.listPosition;

    const siblings = this.getSiblingsOfStructureTemplateEntry(creationEntity);
    creationEntity.listPosition = siblings.length;

    const createdEntry = super.create(creationEntity);

    if (desiredListPosition != null) {
      this.setListPositionOfStructureTemplateEntry(
        createdEntry,
        desiredListPosition
      );
    }

    return createdEntry;
  }

  public getByStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateEntry> {
    const entries = this.getAll().filter(
      (sTE) => sTE.ownerStructureTemplateId === structureTemplateId
    );
    this.sortEntries(entries);
    return entries;
  }

  public setListPositionOfStructureTemplateEntry(
    entry: StructureTemplateEntry,
    listPosition: number
  ): void {
    let siblings = this.getSiblingsOfStructureTemplateEntry(entry);
    const oldIndex = siblings.findIndex((e) => e.id === entry.id);
    siblings = Utils.moveInArray(siblings, oldIndex, listPosition);
    this.refreshListPositions(siblings);
  }

  public setParentIdOfStructureTemplateEntry(
    entry: StructureTemplateEntry,
    parentEntryId: string | null
  ): void {
    if (
      entry.parentEntryId === parentEntryId &&
      !entry.structureTemplateEntryGroupId
    ) {
      return;
    }

    const oldParentEntryId = entry.parentEntryId;
    const oldStructureTemplateEntryGroupId =
      entry.structureTemplateEntryGroupId;

    const newSiblings = this.getByParentId(
      entry.ownerStructureTemplateId,
      parentEntryId
    );
    entry.listPosition = newSiblings.length;
    entry.parentEntryId = parentEntryId;
    entry.structureTemplateEntryGroupId = null;
    this.update(entry);

    const oldSiblings = this.getByParentId(
      entry.ownerStructureTemplateId,
      oldParentEntryId
    );
    this.refreshListPositions(oldSiblings);

    if (oldStructureTemplateEntryGroupId) {
      const oldGroupSiblings = this.getByStructureTemplateEntryGroupId(
        oldStructureTemplateEntryGroupId
      );
      this.refreshListPositions(oldGroupSiblings);
    }
  }

  public addStructureTemplateEntryToGroup(
    entry: StructureTemplateEntry,
    groupId: string
  ): void {
    if (entry.structureTemplateEntryGroupId === groupId) return;

    const oldParentEntryId = entry.parentEntryId;
    const oldStructureTemplateEntryGroupId =
      entry.structureTemplateEntryGroupId;

    const newSiblings = this.getByStructureTemplateEntryGroupId(groupId);
    entry.listPosition = newSiblings.length;
    entry.parentEntryId = null;
    entry.structureTemplateEntryGroupId = groupId;
    this.update(entry);

    const oldSiblings = this.getByParentId(
      entry.ownerStructureTemplateId,
      oldParentEntryId
    );
    this.refreshListPositions(oldSiblings);

    if (oldStructureTemplateEntryGroupId) {
      const oldGroupSiblings = this.getByStructureTemplateEntryGroupId(
        oldStructureTemplateEntryGroupId
      );
      this.refreshListPositions(oldGroupSiblings);
    }
  }

  public deleteAndReorderChildren(entry: StructureTemplateEntry): void {
    const children = this.getByParentId(
      entry.ownerStructureTemplateId,
      entry.id
    );
    const siblings = this.getSiblingsOfStructureTemplateEntry(entry);

    const index = siblings.indexOf(entry);
    siblings.splice(index, 1, ...children);

    for (const child of children) {
      child.parentEntryId = entry.parentEntryId;
      this.update(child);
    }

    this.refreshListPositions(siblings);
    this.delete(entry);
  }

  public getByParentId(
    structureTemplateId: string,
    parentId: string | null = null
  ): Array<StructureTemplateEntry> {
    const entries = this.getAll().filter((structureTemplateEntry) => {
      return (
        structureTemplateEntry.ownerStructureTemplateId ===
          structureTemplateId &&
        structureTemplateEntry.parentEntryId === parentId &&
        !structureTemplateEntry.structureTemplateEntryGroupId
      );
    });

    this.sortEntries(entries);

    return entries;
  }

  public getPathByStructureTemplateEntryId(
    structureTemplateEntryId: string
  ): Array<StructureTemplateEntry> {
    let entry = this.getById(structureTemplateEntryId);
    if (!entry) return [];

    const returnArray = [];
    do {
      returnArray.push(entry);
      entry = entry.parentEntryId ? this.getById(entry.parentEntryId) : null;
    } while (entry);
    return returnArray;
  }

  public getByStructureTemplateIdAndOriginId(
    structureTemplateId: string,
    originId: string
  ): StructureTemplateEntry | null {
    return (
      this.getAll().find(
        (e) =>
          e.ownerStructureTemplateId === structureTemplateId &&
          e.originId === originId
      ) || null
    );
  }

  public getByStructureTemplateEntryGroupId(
    structureTemplateEntryGroupId: string
  ): Array<StructureTemplateEntry> {
    const entries = this.getAll().filter(
      (e) => e.structureTemplateEntryGroupId === structureTemplateEntryGroupId
    );
    this.sortEntries(entries);
    return entries;
  }

  private sortEntries(entries: Array<StructureTemplateEntry>): void {
    entries.sort((a, b) => {
      return a.listPosition - b.listPosition;
    });
  }

  private getSiblingsOfStructureTemplateEntry(
    creationEntity: StructureTemplateEntryCreationEntity
  ): Array<StructureTemplateEntry> {
    let siblings: Array<StructureTemplateEntry> = [];
    if (creationEntity.structureTemplateEntryGroupId) {
      siblings = this.getByStructureTemplateEntryGroupId(
        creationEntity.structureTemplateEntryGroupId
      );
    } else {
      siblings = this.getByParentId(
        creationEntity.ownerStructureTemplateId,
        creationEntity.parentEntryId
      );
    }
    return siblings;
  }

  private refreshListPositions(entries: Array<StructureTemplateEntry>): void {
    entries.forEach((entry, index) => {
      if (index !== entry.listPosition) {
        entry.listPosition = index;
        this.update(entry);
      }
    });
  }
}
