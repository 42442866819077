import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { SearchUtils } from '../../classes/Utils/SearchUtils';

export class PersonSearch {
  private readonly entityManager: AppEntityManager;

  constructor({ entityManager }: { entityManager: AppEntityManager }) {
    this.entityManager = entityManager;
  }

  public search(options: SearchOptions): Array<Person> {
    const preparedFirstName = SearchUtils.prepareSearchTerm(options.firstName);
    const preparedLastName = SearchUtils.prepareSearchTerm(options.lastName);
    const preparedCompanyName = SearchUtils.prepareSearchTerm(
      options.companyName
    );
    const preparedStreetName = SearchUtils.prepareSearchTerm(
      options.streetName
    );
    const preparedZip = SearchUtils.prepareSearchTerm(options.zip);

    if (
      !preparedFirstName &&
      !preparedLastName &&
      (!options.company || !preparedCompanyName) &&
      !preparedStreetName &&
      !preparedZip
    ) {
      return [];
    }

    return this.entityManager.personRepository.getAll().filter((person) => {
      if (options.personIdsToExclude.has(person.id)) {
        return false;
      }

      if (person.ownerUserGroupId !== options.userGroupId) {
        return false;
      }

      if (options.company !== person.company) {
        return false;
      }

      if (
        options.company &&
        preparedCompanyName !==
          SearchUtils.prepareSearchTerm(person.companyName)
      ) {
        return false;
      }

      if (
        preparedFirstName !== SearchUtils.prepareSearchTerm(person.firstName)
      ) {
        return false;
      }

      if (preparedLastName !== SearchUtils.prepareSearchTerm(person.lastName)) {
        return false;
      }

      if (
        preparedStreetName !== SearchUtils.prepareSearchTerm(person.streetName)
      ) {
        return false;
      }

      if (preparedZip !== SearchUtils.prepareSearchTerm(person.zip)) {
        return false;
      }

      return true;
    });
  }
}

export type SearchOptions = {
  company: boolean;
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  zip: string | null;
  userGroupId: string;
  personIdsToExclude: Set<string>;
};
