import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ThingThingSectionPropertyAdapter } from '../../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ThingThingSectionPropertyAdapter';
import { Dialogs } from '../../../classes/Dialogs';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ThingSectionDragData } from '../ThingSectionDragData';

/**
 * @event {ThingSectionDeletedEvent} thing-section-deleted
 */
@autoinject()
export class EditThingSectionsWidgetThingSection {
  @bindable()
  public thingSection: ThingSection | null = null;

  /**
   * optional/can be null
   *
   * If this is set, the sections (except the project specific properties) won't be editable.
   * Also the the project specific properties will be shown
   */
  @bindable()
  public project: Project | null = null;

  @subscribableLifecycle()
  protected readonly thingSectionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingSection];

  protected thingThingSectionPropertiesAdapter: ThingThingSectionPropertyAdapter | null =
    null;

  private isAttached: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.thingSectionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingSection,
        context: this,
        expression: 'thingSection'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateThingSectionPropertiesAdapter();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected thingSectionChanged(): void {
    if (this.isAttached) {
      this.updateThingSectionPropertiesAdapter();
    }
  }

  private updateThingSectionPropertiesAdapter(): void {
    if (this.thingSection) {
      this.thingThingSectionPropertiesAdapter =
        new ThingThingSectionPropertyAdapter({
          entityManager: this.entityManager,
          permissionsService: this.permissionsService,
          subscriptionManagerService: this.subscriptionManagerService,
          thingSection: this.thingSection
        });
    } else {
      this.thingThingSectionPropertiesAdapter = null;
    }
  }

  protected handleSectionChanged(): void {
    assertNotNullOrUndefined(
      this.thingSection,
      "can't EditThingSectionsWidgetThingSection.handleSectionChanged without thingSection"
    );
    this.entityManager.thingSectionRepository.update(this.thingSection);
  }

  protected getDragData(section: ThingSection): ThingSectionDragData {
    return new ThingSectionDragData(section);
  }

  protected handleDeleteButtonClick(): void {
    const section = this.thingSection;
    assertNotNullOrUndefined(
      section,
      "can't EditThingSectionsWidgetThingSection.handleSectionChanged without thingSection"
    );

    void Dialogs.deleteEntityDialog(section, EntityName.ThingSection).then(
      () => {
        this.entityManager.thingSectionRepository.deleteAndUpdateOrder(section);

        DomEventHelper.fireEvent<ThingSectionDeletedEvent>(this.element, {
          name: 'thing-section-deleted',
          detail: null
        });
      }
    );
  }
}

export type ThingSectionDeletedEvent = NamedCustomEvent<
  'thing-section-deleted',
  null
>;
