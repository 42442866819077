import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { IParsedProperty } from '../../classes/PropertyStringParser/PropertyStringParser';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { StructureTemplateEntry } from '../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  StructureTemplateEntryStructureTemplateEntryProperty,
  StructureTemplateStructureTemplateEntryProperty
} from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';

@autoinject()
export class StructureTemplateEntryPropertiesDefaultValueWidget {
  @bindable public structureTemplateEntry: StructureTemplateEntry | null = null;
  @bindable public enabled = false;

  private subscriptionManager: SubscriptionManager;

  private structureTemplateProperties: Array<StructureTemplateStructureTemplateEntryProperty> =
    [];
  private structureTemplateEntryProperties: Array<StructureTemplateEntryStructureTemplateEntryProperty> =
    [];

  private properties: Array<IParsedProperty> = [];

  private PropertyHelper: PropertyHelper = PropertyHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      () => {
        this.updateStructureTemplateEntryProperties();
        this.updateStructureTemplateProperties();
      }
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected structureTemplateEntryChanged(): void {
    this.updateStructureTemplateEntryProperties();
    this.updateStructureTemplateProperties();
  }

  private updateStructureTemplateProperties(): void {
    if (this.structureTemplateEntry) {
      this.structureTemplateProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
          this.structureTemplateEntry.ownerStructureTemplateId
        );
    } else {
      this.structureTemplateProperties = [];
    }
    this.createOrUpdateProperties();
  }

  private updateStructureTemplateEntryProperties(): void {
    if (this.structureTemplateEntry) {
      this.structureTemplateEntryProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateEntryId(
          this.structureTemplateEntry.id
        );
    } else {
      this.structureTemplateEntryProperties = [];
    }
  }

  private handlePropertyChanged(property: IParsedProperty): void {
    assertNotNullOrUndefined(
      this.structureTemplateEntry,
      'structure template entry is not available'
    );
    const structureTemplateEntryProperty =
      this.structureTemplateEntryProperties.find((p) =>
        this.findByNameAndTypeCallback(p, property)
      );
    if (property.value) {
      if (!structureTemplateEntryProperty) {
        this.entityManager.structureTemplateEntryPropertyRepository.create({
          ownerUserGroupId: this.structureTemplateEntry.ownerUserGroupId,
          ownerStructureTemplateId:
            this.structureTemplateEntry.ownerStructureTemplateId,
          structureTemplateEntryId: this.structureTemplateEntry.id,

          name: property.name,
          type: property.type,
          choices: property.choices,
          value: property.value
        });
      } else {
        structureTemplateEntryProperty.value = property.value;
        this.entityManager.structureTemplateEntryPropertyRepository.update(
          structureTemplateEntryProperty
        );
      }
    } else {
      if (structureTemplateEntryProperty) {
        this.entityManager.structureTemplateEntryPropertyRepository.delete(
          structureTemplateEntryProperty
        );
      }
    }
  }

  private createOrUpdateProperties(): void {
    for (const structureTemplateProperty of this.structureTemplateProperties) {
      const property = this.properties.find((p) =>
        this.findByNameAndTypeCallback(p, structureTemplateProperty)
      );
      const structureTemplateEntryProperty =
        this.structureTemplateEntryProperties.find((p) =>
          this.findByNameAndTypeCallback(p, structureTemplateProperty)
        );

      const propertyToCreate = {
        name: structureTemplateProperty.name ?? '',
        type: structureTemplateProperty.type,
        choices: structureTemplateProperty.choices,
        value: structureTemplateEntryProperty?.value ?? ''
      };

      if (!property) {
        this.properties.push(propertyToCreate);
      } else {
        Object.assign(property, propertyToCreate);
      }
    }

    for (let i = this.properties.length - 1; i >= 0; i--) {
      const property = this.properties[i];

      const structureTemplateProperty = this.structureTemplateProperties.find(
        (p) => this.findByNameAndTypeCallback(p, property)
      );
      const structureTemplateEntryProperty = structureTemplateProperty
        ? this.structureTemplateEntryProperties.find((p) =>
            this.findByNameAndTypeCallback(p, structureTemplateProperty)
          )
        : null;

      if (!structureTemplateProperty) {
        this.properties.splice(i, 1);
        if (structureTemplateEntryProperty)
          this.entityManager.structureTemplateEntryPropertyRepository.delete(
            structureTemplateEntryProperty
          );
      }
    }
  }

  private findByNameAndTypeCallback(
    p1: { name: string | null; type: string },
    p2: { name: string | null; type: string }
  ): boolean {
    return p1.name === p2.name && p1.type === p2.type;
  }
}
