import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { watch } from '../../hooks/watch';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { ThingCreationService } from '../../classes/EntityManager/entities/Thing/ThingCreationService';

/**
 * @event value-changed
 */
@autoinject()
export class ThingSelectAndEditWidget {
  @bindable()
  public value: string | null = null;

  /**
   * readonly!
   */
  @bindable()
  public selectedThing: Thing | null = null;

  /**
   * limit the selection of the things to the thingGroup
   */
  @bindable()
  public thingGroup: ThingGroup | null = null;

  /**
   * limit the selection of the things to the userGroup
   */
  @bindable()
  public userGroupId: string | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public personsEnabled: boolean = false;

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  @subscribableLifecycle()
  private permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  private userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly thingCreationService: ThingCreationService,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as ThingSelectAndEditWidget,
        propertyName: 'selectedThing'
      }
    );

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForIdProperty({
        entityName: EntityName.UserGroup,
        context: this as ThingSelectAndEditWidget,
        propertyName: 'userGroupId'
      });

    this.updatePermissionsOverride();
  }

  protected handleValueChanged(): void {
    this.fireValueChangedEvent();
  }

  protected handleThingChanged(): void {
    this.createThingIfNeeded();
    if (this.selectedThing) {
      this.entityManager.thingRepository.update(this.selectedThing);
    }
  }

  private fireValueChangedEvent(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'value-changed',
      detail: null
    });
  }

  private createThingIfNeeded(): void {
    /* thing is not null here because aurelia automatically creates an empty object when values are modified,
       so we need to check it via the id if it is valid */
    if (this.selectedThing && this.selectedThing.id) {
      return;
    }

    this.selectedThing = this.createThing();
  }

  private createThing(): Thing | null {
    if (!this.userGroupId) {
      return null;
    }

    const thing = this.thingCreationService.create({
      thingGroupId: this.thingGroup?.id,
      usergroup: this.userGroupId,
      ownerUserGroupId: this.userGroupId,
      ...this.selectedThing,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.value = thing.id;
    this.selectedThing = thing;

    setTimeout(this.fireValueChangedEvent.bind(this), 0);

    return thing;
  }

  /**
   * If the user is in the process of creating a new thing, allow the user to edit name & description
   */
  @watch(
    expression('selectedThing'),
    expression('userGroupPermissionsHandle.canCreateThings')
  )
  private updatePermissionsOverride(): void {
    this.permissionsHandle.overrideAllPermissions(
      !this.selectedThing && this.userGroupPermissionsHandle.canCreateThings
        ? true
        : null
    );
  }
}
