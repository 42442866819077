import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ComputedValueService } from '../../ComputedValueService';
import {
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../ProcessTaskAppointmentDateInfoMapComputer';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class SortedProcessTaskAppointmentsForProcessTaskIdComputer extends ValueComputer<
  SortedProcessTaskAppointmentsForProcessTaskIdComputerComputeData,
  SortedProcessTaskAppointmentsForProcessTaskIdComputerResult
> {
  private readonly subscriptionManager: SubscriptionManager;
  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      invokeCompute
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          invokeCompute();
        }
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(
    data: SortedProcessTaskAppointmentsForProcessTaskIdComputerComputeData
  ): SortedProcessTaskAppointmentsForProcessTaskIdComputerResult {
    return this.entityManager.processTaskAppointmentRepository
      .getByProcessTaskId(data.processTaskId)
      .sort((a, b) => {
        const aInfo = this.processTaskAppointmentDateInfoMap.get(a.id);
        const aDateFrom = aInfo?.dateFrom ?? '';

        const bInfo = this.processTaskAppointmentDateInfoMap.get(b.id);
        const bDateFrom = bInfo?.dateFrom ?? '';

        return aDateFrom.localeCompare(bDateFrom);
      });
  }
}

export type SortedProcessTaskAppointmentsForProcessTaskIdComputerComputeData = {
  processTaskId: string;
};

export type SortedProcessTaskAppointmentsForProcessTaskIdComputerResult =
  Array<ProcessTaskAppointment>;
