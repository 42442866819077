import { inject, observable } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingCreationService } from '../../classes/EntityManager/entities/Thing/ThingCreationService';

@inject(AppEntityManager, ThingCreationService)
export class CopyThingDialog {
  /** @type {import('../record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  _loading = false;

  /** @type {TCopyThingDialogOptions} */
  _options = {
    thingId: null,
    newName: '',

    copyTags: false,

    copyRegions: false,
    copyRegionProperties: false,
    copyRegionPropertyValues: false,

    copyProperties: false,
    copyPropertyValues: false
  };

  @observable _selectAll;

  /**
   * @param {AppEntityManager} entityManager
   * @param {ThingCreationService} thingCreationService
   */
  constructor(entityManager, thingCreationService) {
    this._entityManager = entityManager;
    this._thingCreationService = thingCreationService;

    this._selectAll = false;
  }

  /**
   * @param {TCopyThingDialogOptions} options
   */
  open(options) {
    Object.assign(this._options, options);
    if (this._dialog) this._dialog.open();
  }

  _selectAllChanged() {
    this._options.copyTags = this._selectAll;

    this._options.copyRegions = this._selectAll;
    this._options.copyRegionProperties = this._selectAll;
    this._options.copyRegionPropertyValues = this._selectAll;

    this._options.copyProperties = this._selectAll;
    this._options.copyPropertyValues = this._selectAll;
  }

  _handleCloseDialogClick() {
    if (this._dialog) this._dialog.close();
  }

  _handleCopyThingClick() {
    this._loading = true;

    const originalThing = this._entityManager.thingRepository.getById(
      this._options.thingId
    );
    if (!originalThing) {
      throw new Error(`Thing with id "${this._options.thingId}" not found!`);
    }

    const newThing = this._thingCreationService.create({
      ...originalThing,
      name: this._options.newName
    });

    if (this._options.copyTags) this._copyThingTags(originalThing, newThing);
    if (this._options.copyRegions)
      this._copyThingRegions(originalThing, newThing);
    if (this._options.copyProperties)
      this._copyThingProperties(originalThing, newThing);

    this._loading = false;
    this._handleCloseDialogClick();
  }

  /**
   * @param {Thing} originalThing
   * @param {Thing} newThing
   */
  _copyThingTags(originalThing, newThing) {
    const originalTags = this._entityManager.tagRepository.getByThingId(
      originalThing.id
    );
    originalTags.forEach((tag) => {
      this._entityManager.tagRepository.create({
        ...tag,
        thing: newThing.id
      });
    });
  }

  /**
   * @param {Thing} originalThing
   * @param {Thing} newThing
   */
  _copyThingRegions(originalThing, newThing) {
    const originalRegions = this._entityManager.regionRepository.getByThingId(
      originalThing.id
    );
    originalRegions.forEach((region) => {
      const newRegion = this._entityManager.regionRepository.create({
        ...region,
        thingId: newThing.id
      });
      if (this._options.copyRegionProperties)
        this._copyRegionProperties(region, newRegion);
    });
  }

  /**
   * @param {Thing} originalThing
   * @param {Thing} newThing
   */
  _copyThingProperties(originalThing, newThing) {
    const originalProperties =
      this._entityManager.propertyRepository.getByThingId(originalThing.id);
    originalProperties.forEach((property) => {
      this._entityManager.propertyRepository.create({
        ...property,
        thing: newThing.id,
        ...(this._options.copyPropertyValues
          ? {}
          : { value: null, custom_choice: null })
      });
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/Region/types').Region} originalRegion
   * @param {import('../../classes/EntityManager/entities/Region/types').Region} newRegion
   */
  _copyRegionProperties(originalRegion, newRegion) {
    const originalProperties = this._entityManager.propertyRepository
      .getByRegionId(originalRegion.id)
      .filter(
        (property) => !property.name || !property.name.startsWith('description')
      );
    originalProperties.forEach((property) => {
      this._entityManager.propertyRepository.create({
        ...property,
        regionId: newRegion.id,
        ...(this._options.copyRegionPropertyValues
          ? {}
          : { value: null, custom_choice: null })
      });
    });
  }

  /**
   * @param {TCopyThingDialogOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/** @typedef {import('../../classes/EntityManager/entities/Thing/types').Thing} Thing */

/**
 * @typedef {Object} TCopyThingDialogOptions
 * @property {string} thingId
 * @property {string} newName
 *
 * @property {boolean} [copyTags]
 *
 * @property {boolean} [copyRegions]
 * @property {boolean} [copyRegionProperties]
 * @property {boolean} [copyRegionPropertyValues]
 *
 * @property {boolean} [copyProperties]
 * @property {boolean} [copyPropertyValues]
 */
