import { Router } from 'aurelia-router';

export class ProcessTaskGroupUtils {
  public static navigateToEditProcessTaskGroupsPage(router: Router): void {
    router.navigateToRoute('edit_process_task_groups');
  }

  public static navigateToCreateProcessTaskGroupPage(
    router: Router,
    params: { user_group_id: string }
  ): void {
    router.navigateToRoute('create_process_task_group', params);
  }

  public static getReferenceCodeOrFallback(
    referenceCode: string | null
  ): string {
    return referenceCode ? referenceCode : '---';
  }
}
