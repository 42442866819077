import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationActionStatus } from '../../../classes/EntityManager/entities/ProcessConfigurationActionStatus/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationActionStatesWidgetListItem {
  @bindable()
  public processConfigurationActionStatus: ProcessConfigurationActionStatus | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationActionStatusPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationActionStatus];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationActionStatusPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationActionStatus,
        context: this as ProcessConfigurationActionStatesWidgetListItem,
        propertyName: 'processConfigurationActionStatus'
      });
  }

  protected handleDeleteActionStatusClick(): void {
    const processConfigurationActionStatus =
      this.processConfigurationActionStatus;
    assertNotNullOrUndefined(
      processConfigurationActionStatus,
      "can't ProcessConfigurationActionStatesWidgetListItem.handleDeleteActionStatusClick without processConfigurationActionStatus"
    );

    void Dialogs.deleteEntityDialog(processConfigurationActionStatus).then(
      () => {
        this.entityManager.processConfigurationActionStatusRepository.delete(
          processConfigurationActionStatus
        );
      }
    );
  }

  protected handleActionStatusChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationActionStatus,
      "can't ProcessConfigurationActionStatesWidgetListItem.handleActionStatusChanged without processConfigurationActionStatus"
    );

    this.entityManager.processConfigurationActionStatusRepository.update(
      this.processConfigurationActionStatus
    );
  }
}
