import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import {
  CalendarEntry,
  DaySpecificCalendarEntry
} from '../CalendarEntryDataSource/CalendarEntry';

/**
 * Clamps the calendar entries to the displayed hours, so entries which are out of bounds are still shown.
 * Calendar entries are not clamped exactly to the displayed hours, entry will be shown a little bit before the displayed hours so the can be distinguished from regular ones
 */
export class CalendarEntryClamper {
  private static MIN_DURATION = 15;

  private readonly displayHours$: BehaviorSubject<Array<number>>;
  private readonly calendarEntries$: BehaviorSubject<
    Array<DaySpecificCalendarEntry>
  >;

  private readonly clampedCalendarEntries$: Observable<
    Array<ClampedCalendarEntry>
  >;

  constructor(options: CalendarEntryClamperOptions) {
    this.displayHours$ = new BehaviorSubject(options.displayHours);
    this.calendarEntries$ = new BehaviorSubject(options.calendarEntries);

    this.clampedCalendarEntries$ = this.createClampedCalendarEntries$({
      displayHours$: this.displayHours$,
      calendarEntries$: this.calendarEntries$
    });
  }

  public setDisplayHours(displayHours: Array<number>): void {
    this.displayHours$.next(displayHours);
  }

  public setCalendarEntries(
    calendarEntries: Array<DaySpecificCalendarEntry>
  ): void {
    this.calendarEntries$.next(calendarEntries);
  }

  public subscribe({
    onNewClampedCalendarEntries
  }: SubscribeOptions): Disposable {
    const subscription = this.clampedCalendarEntries$.subscribe(
      onNewClampedCalendarEntries
    );

    return {
      dispose: () => {
        subscription.unsubscribe();
      }
    };
  }

  private createClampedCalendarEntries$({
    displayHours$,
    calendarEntries$
  }: {
    displayHours$: BehaviorSubject<Array<number>>;
    calendarEntries$: BehaviorSubject<Array<DaySpecificCalendarEntry>>;
  }): Observable<Array<ClampedCalendarEntry>> {
    const minMaxHours$ = this.createMinMaxHours$({ displayHours$ });

    return combineLatest([calendarEntries$, minMaxHours$]).pipe(
      map(([calendarEntries, minMaxHours]) => {
        return this.clampCalendarEntries({
          calendarEntries,
          minMaxHours
        });
      })
    );
  }

  private createMinMaxHours$({
    displayHours$
  }: {
    displayHours$: BehaviorSubject<Array<number>>;
  }): Observable<MinMaxHours> {
    return displayHours$.pipe(
      map((displayHours) => {
        const minDisplayHour = displayHours.length
          ? Math.min(...displayHours)
          : null;
        const maxDisplayHour = displayHours.length
          ? Math.max(...displayHours) + 1
          : null; // + 1 because the full hour is displayed. That means if the max hour is 12, the maximum time would be 12:59:59.999

        return {
          minHourInMinutes:
            minDisplayHour != null ? minDisplayHour * 60 - 15 : null,
          maxHourInMinutes: maxDisplayHour != null ? maxDisplayHour * 60 : null
        };
      })
    );
  }

  private clampCalendarEntries({
    calendarEntries,
    minMaxHours
  }: {
    calendarEntries: Array<DaySpecificCalendarEntry>;
    minMaxHours: MinMaxHours;
  }): Array<ClampedCalendarEntry> {
    const clampedCalendarEntries: Array<ClampedCalendarEntry> = [];

    for (const daySpecificEntry of calendarEntries) {
      const clampedTimeWithoutTimestamps =
        this.clampTimeSlotsWhichEndAfterMaxHour({
          ...this.clampTimeSlotsWhichAreBeforeOrAfter({
            duration: daySpecificEntry.calendarEntry.duration,
            startHour: daySpecificEntry.calendarEntry.startHour,
            startMinute: daySpecificEntry.calendarEntry.startMinute,
            minMaxHours
          }),
          minMaxHours
        });

      clampedCalendarEntries.push({
        calendarEntry: daySpecificEntry.calendarEntry,
        clampedTime: this.addTimestampsToClampedTime({
          calendarEntry: daySpecificEntry.calendarEntry,
          clampedTimeWithoutTimestamps
        }),
        originalMultiDayData: daySpecificEntry.originalMultiDayData
      });
    }

    return clampedCalendarEntries;
  }

  private clampTimeSlotsWhichAreBeforeOrAfter({
    duration,
    startHour,
    startMinute,
    minMaxHours
  }: ClampedTimeWithoutTimestamps & {
    minMaxHours: MinMaxHours;
  }): ClampedTimeWithoutTimestamps {
    const startTime = startHour * 60 + startMinute;
    const endTime = startTime + duration;

    if (
      minMaxHours.minHourInMinutes != null &&
      startTime < minMaxHours.minHourInMinutes
    ) {
      let clampedDuration;
      if (endTime < minMaxHours.minHourInMinutes) {
        clampedDuration = duration > 60 ? 60 : duration;
      } else {
        clampedDuration = endTime - minMaxHours.minHourInMinutes;
      }
      const hourAndMinutes = this.minutesToHourAndMinute(
        minMaxHours.minHourInMinutes
      );
      return {
        startHour: hourAndMinutes.hour,
        startMinute: hourAndMinutes.minute,
        duration: clampedDuration
      };
    }

    if (
      minMaxHours.maxHourInMinutes != null &&
      startTime >= minMaxHours.maxHourInMinutes
    ) {
      const clampedDuration = duration > 60 ? 60 : duration;
      const hourAndMinute = this.minutesToHourAndMinute(
        minMaxHours.maxHourInMinutes
      );
      return {
        startHour: hourAndMinute.hour - 1,
        startMinute: hourAndMinute.minute,
        duration: clampedDuration
      };
    }

    return { duration, startHour, startMinute };
  }

  private clampTimeSlotsWhichEndAfterMaxHour({
    duration,
    startHour,
    startMinute,
    minMaxHours
  }: ClampedTimeWithoutTimestamps & {
    minMaxHours: MinMaxHours;
  }): ClampedTimeWithoutTimestamps {
    const startTime = startHour * 60 + startMinute;

    if (
      minMaxHours.maxHourInMinutes != null &&
      startTime < minMaxHours.maxHourInMinutes &&
      startTime + duration > minMaxHours.maxHourInMinutes
    ) {
      const newDuration = minMaxHours.maxHourInMinutes - startTime;
      const newDurationWithMinDuration = Math.max(
        newDuration,
        CalendarEntryClamper.MIN_DURATION
      );
      const correctionDiff = newDurationWithMinDuration - newDuration;
      const hourAndMinute = this.minutesToHourAndMinute(
        startTime - correctionDiff
      );

      return {
        startHour: hourAndMinute.hour,
        startMinute: hourAndMinute.minute,
        duration: newDurationWithMinDuration
      };
    }

    return {
      startHour,
      startMinute,
      duration
    };
  }

  private addTimestampsToClampedTime({
    calendarEntry,
    clampedTimeWithoutTimestamps
  }: {
    calendarEntry: CalendarEntry;
    clampedTimeWithoutTimestamps: ClampedTimeWithoutTimestamps;
  }): ClampedTimeWithTimestamps {
    const startDate = new Date(calendarEntry.startTimestamp);
    startDate.setHours(
      clampedTimeWithoutTimestamps.startHour,
      clampedTimeWithoutTimestamps.startMinute,
      0,
      0
    );

    return {
      ...clampedTimeWithoutTimestamps,
      startTimestamp: startDate.getTime(),
      endTimestamp:
        startDate.getTime() + clampedTimeWithoutTimestamps.duration * 60 * 1000
    };
  }

  private minutesToHourAndMinute(minutes: number): {
    hour: number;
    minute: number;
  } {
    return {
      hour: Math.floor(minutes / 60),
      minute: minutes % 60
    };
  }
}

export type CalendarEntryClamperOptions = {
  displayHours: Array<number>;
  calendarEntries: Array<DaySpecificCalendarEntry>;
};

export type SubscribeOptions = {
  onNewClampedCalendarEntries: (
    clampedCalendarEntries: Array<ClampedCalendarEntry>
  ) => void;
};

export type ClampedCalendarEntry = {
  calendarEntry: CalendarEntry;
  originalMultiDayData: DaySpecificCalendarEntry['originalMultiDayData'];

  clampedTime: ClampedTimeWithTimestamps;
};

type ClampedTimeWithoutTimestamps = {
  startHour: number;
  startMinute: number;
  duration: number;
};

type ClampedTimeWithTimestamps = ClampedTimeWithoutTimestamps & {
  startTimestamp: number;
  endTimestamp: number;
};

type MinMaxHours = {
  minHourInMinutes: number | null;
  maxHourInMinutes: number | null;
};
