import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationMeasurePointType } from '../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationMeasurePointTypesWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  protected measurePointTypes: Array<ProcessConfigurationMeasurePointType> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationMeasurePointTypesWidget,
        propertyName: 'processConfiguration'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationMeasurePointType,
      this.updateMeasurePointTypes.bind(this)
    );
    this.updateMeasurePointTypes();
  }

  protected detached(): void {
    this.isAttached = true;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateMeasurePointTypes();
    }
  }

  protected handleAddMeasurePointTypeClick(): void {
    if (!this.processConfiguration) {
      return;
    }

    this.entityManager.processConfigurationMeasurePointTypeRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
      temporaryGroupName: this.processConfiguration.temporaryGroupName
    });

    this.updateMeasurePointTypes();
  }

  private updateMeasurePointTypes(): void {
    if (this.processConfiguration) {
      this.measurePointTypes =
        this.entityManager.processConfigurationMeasurePointTypeRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.measurePointTypes = [];
    }
  }
}
