import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { IsNever } from 'common/Types/utilities';

import { RequestWithStatus, Status } from '../classes/RequestWithStatus';
import { SocketService } from './SocketService';

@autoinject()
export class RequestWithStatusService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService
  ) {}

  public createRequestWithStatus<T = never>(
    requestWorker: (
      ...args: IsNever<T> extends true ? [] : [T]
    ) => Promise<Status>
  ): RequestWithStatus<T> {
    return new RequestWithStatus({
      eventAggregator: this.eventAggregator,
      socketService: this.socketService,
      requestWorker
    });
  }
}
