import { LatLongArea } from '../LatLongArea';
import { PropertyFilter } from './GalleryThingPicturePropertyFilter';

export type GalleryThingPictureFilter = Partial<{
  timestampFrom: number | null;
  timestampTo: number | null;
  tagIds: Array<string> | null;
  tagMatchMode: GalleryThingPictureFilterTagMatchMode;
  untaggedPicturesOnly: boolean;
  pictureDescription: string | null;
  regionId: string | null;
  personSearchPattern: string | null;
  latLongArea: LatLongArea | null;
  projectIds: Array<string> | null;
  personIds: Array<string> | null;
  properties: Array<PropertyFilter>;
  selectedPictureIds: Array<string> | null;
}> & {
  thingId: string;
  ownerUserGroupId: string;
  isActive: boolean;
} & Partial<GalleryThingImplicitPictureFilter>;

/**  These filter settings are implicitly set, the user has no control over them. */
export type GalleryThingImplicitPictureFilter = {
  hasMarkingOnPictureId: string | null;
};

export enum GalleryThingPictureFilterTagMatchMode {
  PARTIAL = 'partial',
  EXACT = 'exact'
}
