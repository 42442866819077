import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject, bindable } from 'aurelia-framework';

import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * Button for switching between multiple cameras.
 *
 * @see ultra-rapid-fire-widget for usage
 *
 * @event switch-flash-mode
 */
@autoinject()
export class UrfwSwitchFlashModeButton {
  @bindable public availableFlashModes: Array<CameraPreviewFlashMode> = [];

  @bindable public currentFlashMode: CameraPreviewFlashMode | null = null;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  @computed(expression('currentFlashMode'))
  protected get flashModeIconName(): string {
    switch (this.currentFlashMode) {
      default:
      case CameraPreviewFlashMode.AUTO:
        return 'fa-bolt-auto';

      case CameraPreviewFlashMode.ON:
        return 'fa-bolt';

      case CameraPreviewFlashMode.OFF:
        return 'fa-bolt-slash';

      case CameraPreviewFlashMode.RED_EYE:
        return 'fa-eye';

      case CameraPreviewFlashMode.TORCH:
        return 'fa-flashlight';
    }
  }

  protected handleSwitchFlashModeClick(): void {
    const flashMode = this.getNextFlashMode();

    DomEventHelper.fireEvent<SwitchFlashModeEvent>(this.domElement, {
      name: 'switch-flash-mode',
      detail: {
        flashMode
      }
    });
  }

  private getNextFlashMode(): CameraPreviewFlashMode {
    const index = this.availableFlashModes.findIndex(
      (fM) => fM === this.currentFlashMode
    );
    const nextFlashMode =
      this.availableFlashModes[index + 1] ?? this.availableFlashModes[0];

    if (!nextFlashMode) throw new Error('no next flash mode available');

    return nextFlashMode;
  }
}

export type SwitchFlashModeEvent = NamedCustomEvent<
  'switch-flash-mode',
  {
    flashMode: CameraPreviewFlashMode;
  }
>;
