import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

import { Dialogs } from '../../../../classes/Dialogs';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  Property,
  PropertyCreationEntity,
  ThingProperty
} from '../../../../classes/EntityManager/entities/Property/types';
import { Thing } from '../../../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { RxjsService } from '../../../../services/RxjsService/RxjsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { ThingPropertyDefinitionHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/ThingPropertyDefinitionHandle/ThingPropertyDefinitionHandle';
import { ManagePropertyDefinitionsWidgetAdapterDeleteOptions } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsBaseWidgetAdapter';
import { ManagePropertyDefinitionsWidgetAdapterHandleCache } from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetAdapterHandleCache';
import { ThingType } from '../../../../classes/EntityManager/entities/ThingType/types';
import {
  ManagePropertyDefinitionsFromProviderWidgetAdapter,
  ManagePropertyDefinitionsFromProviderWidgetAdapterSubscribeOptions,
  TPropertyProviderConfig
} from '../ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsFromProviderWidgetAdapter';

export class ManagePropertyDefinitionsWidgetThingAdapter
  implements
    ManagePropertyDefinitionsFromProviderWidgetAdapter<
      ThingProperty,
      ThingType
    >
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;
  private readonly handleCache: ManagePropertyDefinitionsWidgetAdapterHandleCache<
    ThingProperty,
    ThingPropertyDefinitionHandle
  >;

  private readonly thing: Thing;

  constructor(options: ManageDefinedPropertiesWidgetThingAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.thing = options.thing;
    this.permissionsService = options.permissionsService;

    this.handleCache = new ManagePropertyDefinitionsWidgetAdapterHandleCache({
      createPropertyDefinitionHandle: ({ propertyDefinition }) => {
        return new ThingPropertyDefinitionHandle({
          entityManager: options.entityManager,
          rxjsService: options.rxjsService,
          permissionsService: options.permissionsService,
          property: propertyDefinition
        });
      }
    });
  }

  public subscribe({
    setPropertyDefinitionHandles,
    setPropertyProviderConfigs,
    setCanCreatePropertyDefinitions
  }: ManagePropertyDefinitionsFromProviderWidgetAdapterSubscribeOptions<
    ThingProperty,
    ThingType
  >): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(this.handleCache.subscribe());

    const { updatePropertyDefinitions } =
      this.handleCache.createUpdatePropertyDefinitionsFunction({
        setPropertyDefinitionHandles,
        getPropertyDefinitions: () => {
          return this.entityManager.propertyRepository.getByThingId(
            this.thing.id
          );
        }
      });

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updatePropertyDefinitions
    );
    updatePropertyDefinitions();

    subscriptionManager.subscribeToModelChanges(EntityName.ThingType, () =>
      setPropertyProviderConfigs(this.getPropertyProviderConfigs())
    );
    setPropertyProviderConfigs(this.getPropertyProviderConfigs());

    const handle = this.permissionsService.getPermissionsHandleForEntity({
      entityName: EntityName.Thing,
      entity: this.thing
    });

    subscriptionManager.addDisposable(handle.subscribe());

    subscriptionManager.subscribeToExpression(
      handle,
      'canEditProperties',
      () => {
        setCanCreatePropertyDefinitions(handle.canEditProperties);
      }
    );
    setCanCreatePropertyDefinitions(handle.canEditProperties);

    return subscriptionManager.toDisposable();
  }

  public createPropertyDefinition(): ThingPropertyDefinitionHandle {
    return this.createDefinitionFromProperty({
      thing: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  public createPropertyDefinitionsFromProvider(
    provider: ThingType
  ): Array<ThingPropertyDefinitionHandle> {
    const properties = this.loadPropertiesFromProvider(provider);

    return properties.map((p) =>
      this.createDefinitionFromProperty({
        thing: this.thing.id,
        ownerUserGroupId: this.thing.ownerUserGroupId,
        name: p.name,
        value: p.value,
        type: p.type,
        choices: p.choices,
        custom_choice: p.custom_choice,
        order: p.order,
        active: p.active,
        options: p.options,
        alwaysVisible: p.alwaysVisible,
        hidden: p.hidden
      })
    );
  }

  public loadPropertiesFromProvider(provider: ThingType): Array<Property> {
    return this.entityManager.propertyRepository.getByThingTypeId(provider.id);
  }

  public async deletePropertyDefinition({
    propertyDefinition
  }: ManagePropertyDefinitionsWidgetAdapterDeleteOptions<ThingProperty>): Promise<void> {
    return Dialogs.deleteEntityDialog(
      propertyDefinition,
      EntityName.Property
    ).then(() => {
      this.entityManager.propertyRepository.delete(propertyDefinition);
    });
  }

  public arePropertyDefinitionsEqual(
    p1: ThingProperty,
    p2: ThingProperty
  ): boolean {
    return PropertyHelper.isTheSameProperty(p1, p2);
  }

  private createDefinitionFromProperty(
    creationEntity: PropertyCreationEntity
  ): ThingPropertyDefinitionHandle {
    const property = this.entityManager.propertyRepository.create(
      creationEntity
    ) as ThingProperty;

    return this.handleCache.createCachedPropertyDefinitionHandle({
      propertyDefinition: property
    });
  }

  private getPropertyProviderConfigs(): Array<
    TPropertyProviderConfig<ThingType>
  > {
    return this.entityManager.thingTypeRepository.getAll().map((tt) => ({
      label: tt.name ?? '',
      provider: tt
    }));
  }
}

export type ManageDefinedPropertiesWidgetThingAdapterOptions = {
  entityManager: AppEntityManager;
  rxjsService: RxjsService;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  thing: Thing;
};
