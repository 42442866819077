import { inject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationActionStatusUtils } from '../../classes/EntityManager/entities/ProcessConfigurationActionStatus/ProcessConfigurationActionStatusUtils';

@inject(Element, I18N, SubscriptionManagerService, AppEntityManager)
export class ProcessConfigurationActionStatusSelect {
  /** @type {string|null} */
  @bindable actionStatusId = null;

  /** @type {string|null} */
  @bindable processConfigurationId = null;

  /** @type {string|null} */
  @bindable customActionStatusName = null;

  /** @type {string|null} */
  @bindable customActionStatusAbbreviation = null;

  @bindable label = '';

  @bindable enabled = false;
  @bindable compactButtons = false;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationActionStatus/types').ProcessConfigurationActionStatus>} */
  _processConfigurationActionStates = [];

  /** @type {Array<TActionStatusOption>} */
  _actionStatusOptions = [];

  /** @type {TActionStatusOption|null} */
  _selectedActionStatusOption = null;

  /** @type {string|null} */
  _customActionStatusName = null;

  /** @type {string|null} */
  _customActionStatusAbbreviation = null;

  _ignoreNextActionStatusIdChanged = false;

  /**
   * @param {HTMLElement} element
   * @param {I18N} i18n
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(element, i18n, subscriptionManagerService, entityManager) {
    this._domElement = element;

    this._i18n = i18n;
    this._subscriptionManager = subscriptionManagerService.create();
    this._entityManager = entityManager;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationActionStatus,
      this._updateProcessConfigurationActionStates.bind(this)
    );
    this._updateProcessConfigurationActionStates();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processConfigurationIdChanged() {
    if (this._attached) {
      this._updateProcessConfigurationActionStates();
    }
  }

  actionStatusIdChanged() {
    if (this._ignoreNextActionStatusIdChanged) {
      this._ignoreNextActionStatusIdChanged = false;
      return;
    }
    this._updateSelectedActionStatusOption();
  }

  customActionStatusNameChanged() {
    if (this._customActionStatusName !== this.customActionStatusName) {
      this._customActionStatusName = this.customActionStatusName;
      this._updateSelectedActionStatusOption();
    }
  }

  customActionStatusAbbreviationChanged() {
    if (
      this._customActionStatusAbbreviation !==
      this.customActionStatusAbbreviation
    ) {
      this._customActionStatusAbbreviation =
        this.customActionStatusAbbreviation;
      this._updateSelectedActionStatusOption();
    }
  }

  _updateProcessConfigurationActionStates() {
    if (this.processConfigurationId) {
      this._processConfigurationActionStates =
        this._entityManager.processConfigurationActionStatusRepository.getByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this._processConfigurationActionStates = [];
    }

    this._updateActionStatusOptions();
  }

  _updateActionStatusOptions() {
    /** @type {Array<TActionStatusOption>} */
    const options = [
      {
        actionStatusId: null,
        label: this._i18n.tr(
          'operationsComponents.processConfigurationActionStatusSelect.noActionStatusOption'
        ),
        customActionStatus: false
      },
      {
        actionStatusId: null,
        label: this._i18n.tr('general.custom'),
        customActionStatus: true
      }
    ];

    this._processConfigurationActionStates.forEach((as) => {
      options.push({
        actionStatusId: as.id,
        label:
          ProcessConfigurationActionStatusUtils.getFullDisplayNameForProcessConfigurationActionStatus(
            as
          ),
        customActionStatus: false
      });
    });

    this._actionStatusOptions = options;
    this._updateSelectedActionStatusOption();
  }

  _updateSelectedActionStatusOption() {
    const hasCustomActionStatus = !!(
      this.customActionStatusAbbreviation || this.customActionStatusName
    );

    let option = this._actionStatusOptions.find((opt) => {
      if (hasCustomActionStatus) {
        return opt.customActionStatus;
      } else {
        return opt.actionStatusId === this.actionStatusId;
      }
    });

    if (!option) option = this._actionStatusOptions[0];
    this._selectedActionStatusOption = option;
  }

  _handleSelectChanged() {
    if (!this._selectedActionStatusOption) {
      return;
    } else if (this._selectedActionStatusOption.customActionStatus) {
      this.customActionStatusName = this._customActionStatusName;
      this.customActionStatusAbbreviation =
        this._customActionStatusAbbreviation;

      this._setActionStatusId(null);
    } else {
      this.customActionStatusName = null;
      this.customActionStatusAbbreviation = null;

      this._setActionStatusId(this._selectedActionStatusOption.actionStatusId);
    }
  }

  /**
   * @param {string|null} actionStatusId
   */
  _setActionStatusId(actionStatusId) {
    if (actionStatusId !== this.actionStatusId) {
      this._ignoreNextActionStatusIdChanged = true;
      this.actionStatusId = actionStatusId;
    }
  }

  _handleCustomActionStatusNameChanged() {
    this._handleSelectChanged();
  }

  _handleCustomActionStatusAbbreviationChanged() {
    this._handleSelectChanged();
  }
}

/**
 * @typedef {Object} TActionStatusOption
 * @property {string|null} actionStatusId
 * @property {string} label
 * @property {boolean} customActionStatus
 */
