import _ from 'lodash';

import { autoinject } from 'aurelia-dependency-injection';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../AppEntityManager';
import { Thing } from '../Thing/types';
import { ThingType } from '../ThingType/types';
import { Property, PropertyCreationEntity } from './types';
import {
  PropertyStringParser,
  ParsedProperty,
  PropertyOption
} from 'common/PropertyStringParser/PropertyStringParser';
import { Project } from '../Project/types';
import { Entry } from '../Entry/types';
import { ObjectEditor } from '../../../ObjectEditor/ObjectEditor';
import { StructureTemplateStructureTemplateEntryProperty } from '../StructureTemplateEntryProperty/types';
import { StructureTemplateEntryPropertiesHelper } from '../StructureTemplateEntryProperty/StructureTemplateEntryPropertiesHelper';

@autoinject()
export class ApplyPropertiesService {
  private readonly structureTemplateEntryPropertiesHelper: StructureTemplateEntryPropertiesHelper;

  constructor(private readonly entityManager: AppEntityManager) {
    this.structureTemplateEntryPropertiesHelper =
      new StructureTemplateEntryPropertiesHelper(entityManager, this);
  }

  public applyThingTypePropertiesToThing(
    thingType: ThingType,
    thing: Thing
  ): void {
    const thingProperties = this.entityManager.propertyRepository.getByThingId(
      thing.id
    );
    const thingTypeProperties =
      this.entityManager.propertyRepository.getByThingTypeId(thingType.id);

    for (const thingTypeProperty of thingTypeProperties) {
      const thingProperty = thingProperties.find(
        (p) =>
          p.name === thingTypeProperty.name && p.type === thingTypeProperty.type
      );

      if (!thingProperty) {
        this.entityManager.propertyRepository.create({
          ...thingTypeProperty,
          thingType: undefined,
          thing: thing.id,
          ownerUserGroupId: thing.ownerUserGroupId
        });
      }
    }
  }

  /**
   * applies properties of report type to project
   */
  public applyRawPropertiesToProject(
    project: Project,
    rawProperties: Array<string>
  ): void {
    const projectProperties =
      this.entityManager.propertyRepository.getByProjectId(project.id);
    const thingProperties = this.entityManager.propertyRepository.getByThingId(
      project.thing
    );

    const entityInfo: ProcessPropertiesEntityInfo = {
      holdingEntityId: project.id,
      propertyEntityField: 'project',
      ownerProjectId: project.id,
      ownerUserGroupId: project.ownerUserGroupId
    };

    this.processRawProjectProperties({
      entityInfo,
      properties: projectProperties,
      rawProperties,
      thingProperties,
      shadowEntity: project.shadowEntity,
      temporaryGroupName: project.temporaryGroupName
    });
  }

  /**
   * applies custom properties of entries to entry
   */
  public applyRawCustomPropertiesToEntry(
    entry: Entry,
    rawProperties: Array<string>
  ): void {
    const parsedProperties = this.parseRawProperties(rawProperties);
    this.applyCustomPropertiesToEntry(entry, parsedProperties);
  }

  public applyCustomPropertiesToEntry(
    entry: Entry,
    customEntryProperties: Array<ParsedProperty>
  ): void {
    const entryProperties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    const project = this.entityManager.projectRepository.getById(entry.project);

    let thingProperties: Array<Property> = [];

    if (project)
      thingProperties = this.entityManager.propertyRepository.getByThingId(
        project.thing
      );

    const entityInfo = this.createProcessPropertiesEntityInfoForEntry(entry);
    this.processProperties({
      entityInfo,
      properties: entryProperties,
      parsedProperties: customEntryProperties,
      thingProperties,
      shadowEntity: false,
      temporaryGroupName: null
    });
  }

  /**
   * TODO: shouldn't this also inherit from the thingProperties the same way `applyCustomPropertiesToEntry` does? It's not clear why the thingProperties are not used when apply customProperties to a new Entry.
   */
  public applyCustomPropertiesToNewEntry(
    entry: Entry,
    customEntryProperties: Array<ParsedProperty>
  ): void {
    const entityInfo = this.createProcessPropertiesEntityInfoForEntry(entry);
    this.processProperties({
      entityInfo,
      properties: [],
      parsedProperties: customEntryProperties,
      shadowEntity: false,
      temporaryGroupName: null
    });
  }

  public applyCustomPropertiesToStructureEntry(
    structureTemplateEntryProperties: Array<StructureTemplateStructureTemplateEntryProperty>,
    entry: Entry,
    project: Project
  ): Array<StructureEntryPropertyInfo> {
    const entryProperties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    const entryPropertyInfos: Array<StructureEntryPropertyInfo> = [];
    const structureTemplateParentEntry = entry.page_depth_parent
      ? this.entityManager.entryRepository.getById(entry.page_depth_parent)
      : null;
    structureTemplateEntryProperties.forEach((p) => {
      const initialValue =
        (structureTemplateParentEntry
          ? this.structureTemplateEntryPropertiesHelper.getDefaultValueForStructureTemplateEntryProperty(
              structureTemplateParentEntry,
              p,
              p.ownerStructureTemplateId
            )
          : p.value) ?? '';

      const entryProperty = entryProperties.find((entryProp) =>
        PropertyHelper.isTheSameProperty(entryProp, p)
      );
      const property =
        entryProperty ??
        this.entityManager.propertyRepository.create({
          ownerUserGroupId: project.ownerUserGroupId,
          ownerProjectId: project.id,
          entry: entry.id,
          name: p.name ?? '',
          type: p.type,
          choices: p.choices,
          value: initialValue,
          active: true,

          temporaryGroupName: entry.temporaryGroupName,
          shadowEntity: entry.shadowEntity
        });

      entryPropertyInfos.push({
        property: property,
        defaultValue: initialValue
      });
    });

    return entryPropertyInfos;
  }

  private processRawProjectProperties(params: {
    entityInfo: ProcessPropertiesEntityInfo;
    properties: Array<Property>;
    rawProperties: Array<string>;
    thingProperties: Array<Property>;
    shadowEntity: boolean;
    temporaryGroupName: string | null;
  }): void {
    const parsedProperties = this.parseRawProperties(params.rawProperties);
    this.processProperties({
      entityInfo: params.entityInfo,
      properties: params.properties,
      parsedProperties,
      thingProperties: params.thingProperties,
      shadowEntity: params.shadowEntity,
      temporaryGroupName: params.temporaryGroupName
    });
  }

  private parseRawProperties(
    rawProperties: Array<string>
  ): Array<ParsedProperty> {
    return rawProperties.map((rawProperty) => {
      const parsedProperty = PropertyStringParser.parse(rawProperty);
      assertNotNullOrUndefined(
        parsedProperty,
        `cannot parse property '${rawProperty}'`
      );

      return parsedProperty;
    });
  }

  private processProperties(params: {
    entityInfo: ProcessPropertiesEntityInfo;
    properties: Array<Property>;
    parsedProperties: Array<ParsedProperty>;
    thingProperties?: Array<Property>;
    shadowEntity: boolean;
    temporaryGroupName: string | null;
  }): void {
    const propertiesToCreate = this.compilePropertiesToCreate(
      params.parsedProperties,
      params.thingProperties ?? []
    );

    for (const [i, propertyToCreate] of propertiesToCreate.entries()) {
      const existingProperty = params.properties.find((o) => {
        const oType = o.type || PropertyType.TEXT;
        return (
          o.name === propertyToCreate.name && oType === propertyToCreate.type
        );
      });

      if (existingProperty) {
        this.updateExistingProperty(existingProperty, propertyToCreate, i);
      } else {
        // typescript doesn't manage to resolve the typing correctly here because of [entityInfo.propertyEntityField]. Thats why we need an explizit cast.
        // splitting this into a function with a case for each possible entityInfo.propertyEntityField would help
        const newProperty = {
          name: propertyToCreate.name,
          value: propertyToCreate.value,
          type: propertyToCreate.type,
          choices: propertyToCreate.choices,
          options: propertyToCreate.options,
          active: true,
          order: i,
          ownerUserGroupId: params.entityInfo.ownerUserGroupId,
          ownerProjectId: params.entityInfo.ownerProjectId,
          shadowEntity: params.shadowEntity,
          temporaryGroupName: params.temporaryGroupName,
          [params.entityInfo.propertyEntityField]:
            params.entityInfo.holdingEntityId
        } as PropertyCreationEntity;

        const createdNewProperty =
          this.entityManager.propertyRepository.create(newProperty);
        params.properties.push(createdNewProperty);
      }
    }

    // cleanup double properties
    const uniqueProperties = this.getArrayOfUniqueProperties(params.properties);
    _.difference(params.properties, uniqueProperties).forEach((o) => {
      this.entityManager.propertyRepository.delete(o);
    });

    this.cleanupUnusedProperties(params.properties, params.parsedProperties);
  }

  private updateExistingProperty(
    existingProperty: Property,
    propertyToCreate: PropertyToCreate,
    index: number
  ): void {
    const existingPropertyEditor = new ObjectEditor(existingProperty);

    if (propertyToCreate.type === PropertyType.MULTI_DROPDOWN) {
      const currentSelectedChoices = existingProperty.value
        ? JSON.parse(existingProperty.value)
        : [];
      const stillExistingSelectedChoices = [];
      const deletedSelectedChoices = [];
      let includesCustomChoice = false;

      for (const choice of currentSelectedChoices) {
        const isCustomChoice =
          choice.value === null && existingProperty.custom_choice;
        if (
          propertyToCreate.choices.find((c) => c === choice.value) ||
          isCustomChoice
        ) {
          stillExistingSelectedChoices.push(choice);
          if (isCustomChoice) includesCustomChoice = true;
        } else {
          deletedSelectedChoices.push(choice);
        }
      }

      if (deletedSelectedChoices.length) {
        if (!includesCustomChoice)
          stillExistingSelectedChoices.push({ value: null }); // null value is responsible for displaying custom_choice

        const newValue = JSON.stringify(stillExistingSelectedChoices);
        existingPropertyEditor.setValue('value', newValue);
        let newCustomChoice = deletedSelectedChoices
          .map((c) => c.value)
          .join('; ');
        if (existingProperty.custom_choice)
          newCustomChoice += `; ${existingProperty.custom_choice}`;
        existingPropertyEditor.setValue('custom_choice', newCustomChoice);
      }
    } else if (
      !existingProperty.value &&
      (propertyToCreate.type !== PropertyType.DROPDOWN ||
        !existingProperty.custom_choice)
    ) {
      existingPropertyEditor.setValue('value', propertyToCreate.value);
    } else if (
      PropertyHelper.propertyTypeNeedsChoices(propertyToCreate.type) &&
      propertyToCreate.choices.findIndex((o) => o === existingProperty.value) <
        0 &&
      (propertyToCreate.type !== PropertyType.DROPDOWN ||
        !existingProperty.custom_choice)
    ) {
      if (propertyToCreate.type !== PropertyType.RADIOBUTTON)
        existingProperty.custom_choice = existingProperty.value;
      existingPropertyEditor.setValue(
        'value',
        propertyToCreate.choices[0] ?? ''
      );
    }

    existingPropertyEditor.setValue('type', propertyToCreate.type);
    existingPropertyEditor.setValue('choices', propertyToCreate.choices);
    existingPropertyEditor.setValue('options', propertyToCreate.options);
    existingPropertyEditor.setValue('active', true);
    existingPropertyEditor.setValue('order', index);

    if (existingPropertyEditor.objectIsModified()) {
      this.entityManager.propertyRepository.update(existingProperty);
    }
  }

  private compilePropertiesToCreate(
    parsedProperties: Array<ParsedProperty>,
    thingProperties: Array<Property>
  ): Array<PropertyToCreate> {
    const propertiesToCreate: Map<string, PropertyToCreate> = new Map();

    for (const parsedProperty of parsedProperties) {
      const propertyIdentifier = `${parsedProperty.name}:${parsedProperty.type}`;

      let propertyToCreate: PropertyToCreate = {
        name: parsedProperty.name,
        type: parsedProperty.type,
        choices: parsedProperty.choices,
        options: parsedProperty.options ?? [],
        value: parsedProperty.value
      };

      const existingPropertyToCreate =
        propertiesToCreate.get(propertyIdentifier);
      if (existingPropertyToCreate) {
        propertyToCreate = existingPropertyToCreate;
      } else {
        propertiesToCreate.set(propertyIdentifier, propertyToCreate);
      }

      if (thingProperties) {
        const thing_property = thingProperties.find(
          (property) => property.name === propertyToCreate.name
        );
        if (thing_property) {
          propertyToCreate.value = thing_property.value || '';
        }
      }

      if (
        PropertyHelper.propertyTypeNeedsChoices(propertyToCreate.type) &&
        propertyToCreate.type !== PropertyType.MULTI_DROPDOWN
      ) {
        if (
          propertyToCreate.choices.findIndex(
            (o) => o === propertyToCreate.value
          ) < 0
        ) {
          propertyToCreate.value = propertyToCreate.choices[0] ?? '';
        }
      }

      if (PropertyHelper.propertyTypeNeedsChoices(propertyToCreate.type)) {
        parsedProperty.choices.forEach((choice) => {
          if (propertyToCreate.choices.findIndex((o) => o === choice) < 0)
            propertyToCreate.choices.push(choice);
        });
      }
    }

    return Array.from(propertiesToCreate.values());
  }

  private cleanupUnusedProperties(
    properties: Array<Property>,
    parsedProperties: Array<ParsedProperty>
  ): void {
    const unusedProperties = _.differenceWith(
      properties,
      parsedProperties,
      (a, b) => {
        return b.name === a.name && b.type === a.type;
      }
    );
    unusedProperties.forEach((o) => {
      if (!o.value && !o.custom_choice) {
        this.entityManager.propertyRepository.delete(o);
      } else {
        if (o.active) {
          o.active = false;
          this.entityManager.propertyRepository.update(o);
        }
      }
    });
  }

  private getArrayOfUniqueProperties<T extends Property | ParsedProperty>(
    properties: Array<T>
  ): Array<T> {
    return _.uniqWith(properties, (a, b) => {
      return b.name === a.name && b.type === a.type;
    });
  }

  private createProcessPropertiesEntityInfoForEntry(
    entry: Entry
  ): ProcessPropertiesEntityInfo {
    return {
      holdingEntityId: entry.id,
      propertyEntityField: 'entry',
      ownerUserGroupId: entry.ownerUserGroupId,
      ownerProjectId: entry.ownerProjectId
    };
  }
}

type ProcessPropertiesEntityInfo = {
  propertyEntityField: 'entry' | 'project';
  holdingEntityId: string;
  ownerUserGroupId: string;
  ownerProjectId: string | null;
};

type PropertyToCreate = {
  name: string;
  type: PropertyType;
  choices: Array<string>;
  options: Array<PropertyOption>;
  value: string;
};

export type StructureEntryPropertyInfo = {
  property: Property;
  defaultValue: string;
};
