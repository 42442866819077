import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroupProperty } from '../../classes/EntityManager/entities/Property/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class CreateProcessTaskGroupThingGroupPropertiesWidget {
  @bindable()
  public thingGroup: ThingGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected properties: Array<ThingGroupProperty> = [];
  protected allThingGroupProperties: Array<ThingGroupProperty> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingGroupChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private updateProperties(): void {
    const thingGroup = this.thingGroup;

    if (thingGroup) {
      this.properties = this.entityManager.propertyRepository.getByThingGroupId(
        thingGroup.id
      );
      this.allThingGroupProperties = this.entityManager.propertyRepository
        .getAll()
        .filter(
          (p): p is ThingGroupProperty =>
            !!p.thingGroupId && p.thingGroupId !== thingGroup.id
        );
    } else {
      this.properties = [];
      this.allThingGroupProperties = [];
    }
  }

  protected getPropertyAutocompleteItems(
    allThingGroupProperties: Array<ThingGroupProperty>,
    propertyName: string
  ): Array<string> {
    return Array.from(
      new Set(
        allThingGroupProperties
          .filter((p) => p.name === propertyName)
          .map((p) => p.value)
          .filter((value): value is string => value != null && value !== '')
      )
    );
  }

  protected handlePropertyChanged(property: ThingGroupProperty): void {
    this.entityManager.propertyRepository.update(property);
  }
}
