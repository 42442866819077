import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityUtils } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityUtils';
import { FilterHelper } from '../../classes/FilterHelper';
import {
  CopyUserDefinedEntityDialog,
  OnCreateNewUserDefinedEntitiesCallback,
  SupportedTemplateUserDefinedEntity,
  SupportedUserDefinedEntityBaseEntity,
  UserDefinedEntityOfBaseEntity
} from '../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { computed } from '../../hooks/computed';
import { model, expression } from '../../hooks/dependencies';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { MoreButtonChoice } from '../more-button/more-button';
import { Dialogs } from '../../classes/Dialogs';
import { ActionClickedEvent } from '../selectable-item-list/selectable-item-list';
import { EditUserDefinedEntityDialog } from '../../dialogs/edit-user-defined-entity-dialog/edit-user-defined-entity-dialog';
import { UserDefinedEntityConfigPropertyConfigCustomColumn } from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';
import { UserDefinedEntityConfigPropertyConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

@autoinject()
export class UserDefinedEntitiesWidget<
  TBaseEntity extends SupportedUserDefinedEntityBaseEntity
> {
  @bindable public selectableUserDefinedEntityTemplates: Array<
    SupportedTemplateUserDefinedEntity<TBaseEntity>
  > = [];

  @bindable public userDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  @bindable public baseEntity: TBaseEntity | null = null;
  @bindable
  public onCreateNewEntities: OnCreateNewUserDefinedEntitiesCallback<TBaseEntity> | null =
    null;

  @bindable public allowCustomColumns = false;
  @bindable public prefilteredByEntityConfigName: string | null = null;

  private sortOptions = UserDefinedEntityUtils.sortOptions;

  protected sortedUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  protected selectedUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  protected filteredUserDefinedEntities: Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = [];

  private filterString: string = '';
  private currentSortOption: SorterSortOption<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > = this.sortOptions.name;

  private currentUser: User | null = null;

  protected multiSelectMoreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'general.delete',
      name: 'delete-user-defined-entities',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'cannotDeleteSelectedUserDefinedEntities'
    }
  ];

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(
    model(EntityName.UserDefinedEntity),
    expression('filterString'),
    expression('userDefinedEntities')
  )
  protected get searchFilteredUserDefinedEntities(): Array<
    UserDefinedEntityOfBaseEntity<TBaseEntity>
  > {
    return FilterHelper.filterItems(
      this.userDefinedEntities,
      (entity) => entity.name + ' ' + entity.customId ?? '',
      this.filterString
    );
  }

  protected handleCreateNewElementButtonClicked(): void {
    assertNotNullOrUndefined(
      this.baseEntity,
      'cannot open dialog without base entity'
    );
    assertNotNullOrUndefined(
      this.onCreateNewEntities,
      'cannot open dialog without onCreateEntities callback'
    );
    const usergroup = this.entityManager.userGroupRepository.getById(
      this.baseEntity.ownerUserGroupId
    );
    assertNotNullOrUndefined(usergroup, 'cannot open dialog without usergroup');

    void CopyUserDefinedEntityDialog.open<TBaseEntity>({
      availableUserDefinedEntities: this.selectableUserDefinedEntityTemplates,
      baseEntity: this.baseEntity,
      onCreateNewEntities: this.onCreateNewEntities,
      entityManager: this.entityManager,
      prefilteredByEntityConfigName: this.prefilteredByEntityConfigName
    });
  }

  protected async handleDeleteSelectedUserDefinedEntities(
    event: ActionClickedEvent<UserDefinedEntityOfBaseEntity<TBaseEntity>>
  ): Promise<void> {
    await Dialogs.deleteDialogTk('');

    event.detail.selectedItems.forEach((x) =>
      this.entityManager.userDefinedEntityRepository.delete(x)
    );
  }

  protected handleUserDefinedEntityDetailActionClick(
    userDefinedEntity: UserDefinedEntity
  ): void {
    void EditUserDefinedEntityDialog.open({
      userDefinedEntity: userDefinedEntity
    });
  }

  @computed(expression('currentUser'), model(EntityName.UserGroup))
  private get editableUserGroups(): Array<UserGroup> {
    if (this.currentUser) {
      return this.entityManager.userGroupRepository.getEditableGroupsForUser(
        this.currentUser
      );
    } else {
      return [];
    }
  }

  protected userDefinedEntityIsEditable(
    userDefinedEntity: UserDefinedEntity,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return userDefinedEntity && user && editableUserGroups
      ? PermissionHelper.userCanEditOwnerUserGroupIdEntity(
          userDefinedEntity,
          user,
          editableUserGroups
        )
      : false;
  }

  protected get cannotDeleteSelectedUserDefinedEntities(): boolean {
    const user = this.currentUser;
    return (
      !user ||
      this.selectedUserDefinedEntities.some(
        (x) =>
          !this.userDefinedEntityIsEditable(x, user, this.editableUserGroups)
      )
    );
  }

  protected get showCustomColumns(): boolean {
    return (
      this.allowCustomColumns &&
      UserDefinedEntityUtils.allShownEntitiesBelongToSameEntityConfig({
        filteredUserDefinedEntities: this.filteredUserDefinedEntities
      })
    );
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig)
  )
  protected get firstCustomColumnName(): string | null {
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    });
  }

  @computed(
    expression('filteredUserDefinedEntities'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig),
    model(EntityName.UserDefinedEntityConfig)
  )
  protected get secondCustomColumnName(): string | null {
    return UserDefinedEntityUtils.getCustomColumnName({
      filteredUserDefinedEntities: this.filteredUserDefinedEntities,
      entityManager: this.entityManager,
      customColumnType: UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    });
  }
}
