import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructurePictureAreaEntityInfo } from 'common/Types/Entities/StructurePictureArea/createStructurePictureAreaEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructurePictureArea } from './types';

export const structurePictureAreaEntityInfo =
  createStructurePictureAreaEntityInfo<StructurePictureArea>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
