import { autoinject } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ProcessConfigurationPositionTypeToConfiguration } from 'common/Enums/ProcessConfigurationPositionType';

import { IProcessConfigurationPositionPropertiesConfiguration } from 'common/Types/ProcessConfigurationPositionPropertiesConfiguration';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessConfigurationStepPosition } from '../ProcessConfigurationStepPosition/types';
import { ProcessTask } from '../ProcessTask/types';
import { PropertyCopyService } from '../Property/PropertyCopyService';
import { Property, PropertyCreationEntity } from '../Property/types';
import { ProcessTaskPosition } from './types';

@autoinject()
export class ProcessTaskPositionCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly propertyCopyService: PropertyCopyService
  ) {}

  public create({
    processTask,
    processConfigurationStepPosition,
    createdAtAppointmentId,
    processConfigurationCategoryId,
    temporaryGroupName,
    propertiesToCopyValuesFrom
  }: {
    processTask: ProcessTask;
    processConfigurationStepPosition: ProcessConfigurationStepPosition;
    createdAtAppointmentId: string | null;
    processConfigurationCategoryId: string | null;
    /**
     * setting a temporaryGroupName will automatically also make the entities a shadowEntity
     */
    temporaryGroupName: string | null;
    propertiesToCopyValuesFrom: Array<Property>;
  }): ProcessTaskPosition {
    const processTaskPosition =
      this.entityManager.processTaskPositionRepository.create({
        processConfigurationStepPositionId: processConfigurationStepPosition.id,
        processConfigurationStepId:
          processConfigurationStepPosition.processConfigurationStepId,
        createdAtAppointmentId: createdAtAppointmentId,
        processConfigurationCategoryId: processConfigurationCategoryId,
        ownerUserGroupId: processTask.ownerUserGroupId,
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: processTask.id,
        amount: 1,
        unit: processConfigurationStepPosition.unit,
        type: processConfigurationStepPosition.type,
        namePrefix: processConfigurationStepPosition.namePrefix,
        name: processConfigurationStepPosition.name,
        description: processConfigurationStepPosition.description,
        price: processConfigurationStepPosition.price || 0,
        discount: processConfigurationStepPosition.discount || 0,
        discountNote: processConfigurationStepPosition.discountNote,
        flatRate: processConfigurationStepPosition.flatRate || false,
        excludeFromMarkup: processConfigurationStepPosition.excludeFromMarkup,
        ignoreExcludeFromMarkup:
          processConfigurationStepPosition.ignoreExcludeFromMarkup,
        color: processConfigurationStepPosition.color,
        detailEnabled: this.getDefaultDetailEnabled({
          processConfigurationStepPosition
        }),
        temporaryGroupName: temporaryGroupName,
        shadowEntity: !!temporaryGroupName
      });

    this.createProperties({
      processTaskPosition,
      temporaryGroupName,
      processConfigurationStepPosition,
      propertiesToCopyValuesFrom
    });

    return processTaskPosition;
  }

  private getDefaultDetailEnabled({
    processConfigurationStepPosition
  }: {
    processConfigurationStepPosition: ProcessConfigurationStepPosition;
  }): boolean {
    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processConfigurationStepPosition.ownerProcessConfigurationId
      );
    if (processConfiguration?.useDetailEnabledForPositionsByDefault === false) {
      return false;
    }

    const typeConfiguration =
      ProcessConfigurationPositionTypeToConfiguration.get(
        processConfigurationStepPosition.type
      );
    if (typeConfiguration?.canHaveDetail !== true) {
      return false;
    }

    return !processConfigurationStepPosition.flatRate;
  }

  private createProperties({
    processTaskPosition,
    temporaryGroupName,
    processConfigurationStepPosition,
    propertiesToCopyValuesFrom
  }: {
    processTaskPosition: ProcessTaskPosition;
    temporaryGroupName: string | null;
    processConfigurationStepPosition: ProcessConfigurationStepPosition;
    propertiesToCopyValuesFrom: Array<Property>;
  }): void {
    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processConfigurationStepPosition.ownerProcessConfigurationId
      );
    const processConfigurationPositionProperties =
      this.entityManager.propertyRepository.getByProcessConfigurationStepPositionId(
        processConfigurationStepPosition.id
      );
    const propertyConfig: IProcessConfigurationPositionPropertiesConfiguration | null =
      processConfiguration?.positionPropertiesConfigurationJson
        ? JSON.parse(processConfiguration.positionPropertiesConfigurationJson)
        : null;
    const defaultPropertyConfigs = propertyConfig?.properties ?? [];

    const templateProperties = [
      ...propertiesToCopyValuesFrom,
      ...processConfigurationPositionProperties
    ];

    for (const [order, config] of defaultPropertyConfigs.entries()) {
      const templateProperty = templateProperties.find((tp) =>
        PropertyHelper.isTheSameProperty(tp, config)
      );

      const creationData: PropertyCreationEntity = {
        ...config,
        order,
        processTaskPositionId: processTaskPosition.id,
        ownerUserGroupId: processTaskPosition.ownerUserGroupId,
        ownerProcessTaskGroupId: processTaskPosition.ownerProcessTaskGroupId,
        ownerProcessTaskId: processTaskPosition.ownerProcessTaskId,
        temporaryGroupName: temporaryGroupName,
        shadowEntity: !!temporaryGroupName
      };

      if (templateProperty) {
        this.propertyCopyService.copy({
          newProperty: creationData,
          propertyToCopy: templateProperty
        });
      } else {
        this.entityManager.propertyRepository.create(creationData);
      }
    }
  }
}
