import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class ProcessConfigurationDeviceExportsByProcessTaskGroupIdComputer extends ValueComputer<
  ComputeData,
  ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDeviceExport,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap {
    const map: ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap =
      new Map();

    const processTaskGroups =
      this.entityManager.processTaskGroupRepository.getAll();
    const exportConfigByProcessConfigurationId = Utils.groupBy(
      this.entityManager.processConfigurationDeviceExportRepository.getAll(),
      (c) => c.ownerProcessConfigurationId
    );

    for (const processTaskGroup of processTaskGroups) {
      map.set(
        processTaskGroup.id,
        exportConfigByProcessConfigurationId.get(
          processTaskGroup.processConfigurationId
        ) ?? []
      );
    }

    return map;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ComputeData = Record<string, never>;

export type ProcessConfigurationDeviceExportsByProcessTaskGroupIdMap = Map<
  string,
  Array<ProcessConfigurationDeviceExport>
>;
