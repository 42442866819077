import { RequiredProperty } from './RequiredProperty';
import {
  RequiredPropertyGroup,
  ValidationResult
} from './RequiredPropertyGroup';
import { RequiredPropertyGroupUtils } from './RequiredPropertyGroupUtils';

export class NamedRequiredPropertyGroup extends RequiredPropertyGroup {
  constructor({ name }: { name: string }) {
    super({ name });
  }

  public validate(): ValidationResult {
    // we check the unique properties first, since solving that problems will also solve the whole group
    const uniqueResult = this.validateUniqueRequiredProperties();
    if (uniqueResult.success === false) {
      return uniqueResult;
    }

    return this.validateAllRequiredProperties();
  }

  private validateUniqueRequiredProperties(): ValidationResult {
    const uniqueRequiredProperties = this.requiredProperties.filter(
      (requiredProperty) => requiredProperty.unique
    );
    const firstUniqueRequiredProperty = uniqueRequiredProperties[0];
    if (!firstUniqueRequiredProperty) {
      // since there are no unique required properties, the validation is successful
      return RequiredPropertyGroupUtils.createSuccessResult();
    }

    let firstUniqueRequiredPropertyWithValue: RequiredProperty | null = null;

    for (const requiredProperty of uniqueRequiredProperties) {
      if (!requiredProperty.hasValue) {
        continue;
      }

      if (firstUniqueRequiredPropertyWithValue) {
        return RequiredPropertyGroupUtils.createErrorResult({
          property: requiredProperty.property
        });
      } else {
        firstUniqueRequiredPropertyWithValue = requiredProperty;
      }
    }

    if (!firstUniqueRequiredPropertyWithValue) {
      // since no unique property has a value, we emit an error with the first unique property for better discoverability
      return RequiredPropertyGroupUtils.createErrorResult({
        property: firstUniqueRequiredProperty.property
      });
    }

    return RequiredPropertyGroupUtils.createSuccessResult();
  }

  private validateAllRequiredProperties(): ValidationResult {
    const firstRequiredProperty = this.requiredProperties[0];
    if (!firstRequiredProperty) {
      return RequiredPropertyGroupUtils.createSuccessResult();
    }

    for (const requiredProperty of this.requiredProperties) {
      if (requiredProperty.hasValue) {
        return RequiredPropertyGroupUtils.createSuccessResult();
      }
    }

    return RequiredPropertyGroupUtils.createErrorResult({
      property: firstRequiredProperty.property
    });
  }
}
