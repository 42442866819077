import { computedFrom } from 'aurelia-framework';
import { ProcessConfigurationStepPosition } from '../../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { ProcessConfigurationStepPositionAdapter } from '../EntityAdapter/ProcessConfigurationStepPositionAdapter/ProcessConfigurationStepPositionAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessConfigurationStepPositionPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessConfigurationStepPositionAdapter>,
  ProcessConfigurationStepPosition
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }
}
