import { autoinject } from 'aurelia-framework';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class RoleBasedPermissionsComputer extends ValueComputer<
  Record<string, never>,
  RoleBasedPermissions
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(invokeCompute)
    );

    this.subscriptionManager.subscribeToMultipleModelChanges(
      [EntityName.UserGroup, EntityName.UserRole, EntityName.UserRoleToUser],
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): RoleBasedPermissions {
    const user = this.currentUserService.getCurrentUser();

    const userGroupsOfUser = user
      ? this.entityManager.userGroupRepository.getUserGroupsOfUser(user)
      : [];
    const userGroupIds = userGroupsOfUser.map((userGroup) => userGroup.id);

    const userRolesOfUserGroups =
      this.entityManager.userRoleRepository.getByUserGroupIds(userGroupIds);
    const userRoleToUsersOfUser = user
      ? this.entityManager.userRoleToUserRepository.getByUserAndUserGroupIds({
          userId: user.id,
          userGroupIds
        })
      : [];

    return new RoleBasedPermissions({
      user,
      userGroupsOfUser,
      userRolesOfUserGroups,
      userRoleToUsersOfUser
    });
  }
}
