import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskAppointmentToProcessTaskPositionEntityInfo } from 'common/Types/Entities/ProcessTaskAppointmentToProcessTaskPosition/createProcessTaskAppointmentToProcessTaskPositionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskAppointmentToProcessTaskPosition } from './types';

export const processTaskAppointmentToProcessTaskPositionEntityInfo =
  createProcessTaskAppointmentToProcessTaskPositionEntityInfo<ProcessTaskAppointmentToProcessTaskPosition>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
