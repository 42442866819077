import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  valueCalculationConfigColorConfigSchema,
  ValueCalculationConfigColorConfig
} from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';

import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../inputComponents/clickable-text-input/clickable-text-input';
import { ValueCalculationConfigDefinitionWidgetHandle } from './ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Dialogs } from '../../classes/Dialogs';
import { FloatingLabelInputTheme } from '../../inputComponents/floating-label-input/floating-label-input';
import { TJsonTextChangedEvent } from '../../inputComponents/json-zod-object-input/json-zod-object-input';

/**
 * A widget which uses a ValueCalculationConfigDefinitionWidgetHandle to edit a definition of a valueCalculationConfig.
 */
@autoinject()
export class ValueCalculationConfigDefinitionWidget<
  TValueCalculationConfigDefinition
> {
  @bindable()
  public handle: ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition> | null =
    null;

  @bindable() public reportTypes: Array<ReportType> = [];

  @bindable public showEntityOverviewSetForAnyHandle = false;

  protected selectedReportType: ReportType | null = null;

  protected colorCodeTextInput: ClickableTextInput | null = null;

  protected colorConfigValidationSchema =
    valueCalculationConfigColorConfigSchema;

  protected colorConfigDemoData: ValueCalculationConfigColorConfig = {
    colorConfigs: [
      {
        color: 'red',
        condition: 'x >= 1.0'
      },
      {
        color: 'green',
        condition: 'x < 1.0'
      }
    ]
  };

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'handle.reportTypeId',
      this.updateSelectedReportType.bind(this)
    );
    this.updateSelectedReportType();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateSelectedReportType(): void {
    this.selectedReportType =
      this.reportTypes.find((x) => x.id === this.handle?.reportTypeId) ?? null;
  }

  protected async handleChanged(): Promise<void> {}

  protected handleNameChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't ValueCalculationConfigDefinitionWidget.handleNameChanged without handle"
    );

    this.handle.setName(event.detail.value?.toString() ?? '');
  }

  protected handleCalculationExpressionChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't ValueCalculationConfigDefinitionWidget.handleCalculationExpressionChanged without handle"
    );

    this.handle.setCalculationExpression(event.detail.value?.toString() ?? '');
  }

  protected handleColorCodeConfigChanged(
    event: TJsonTextChangedEvent<ValueCalculationConfigColorConfig>
  ): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't ValueCalculationConfigDefinitionWidget.handleColorCodeConfigChanged without handle"
    );

    this.handle.setColorCodeConfig(event.detail.parsedObject);
  }

  protected handleShowEntityInOverviewChanged(
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't ValueCalculationConfigDefinitionWidget.handleNameChanged without handle"
    );

    this.handle.setShowInEntityOverview(event.detail.checked);
  }

  protected handleReportTypeSelectionChanged(
    event: SelectChangedEvent<ReportType, ReportTypeOption>
  ): void {
    assertNotNullOrUndefined(
      this.handle,
      "can't ValueCalculationConfigDefinitionWidget.handleReportTypeSelectionChanged without handle"
    );

    this.handle.setReportTypeId(event.detail.value?.id ?? null);
  }

  @computedFrom('handle')
  protected get showReportTypeSelection(): boolean {
    if (this.handle?.getFeatures().showReportTypeSelection) {
      return true;
    }

    return false;
  }

  @computedFrom('reportTypes')
  protected get reportTypeOptions(): Array<ReportTypeOption> {
    return this.reportTypes.map((rt) => {
      return { label: rt.name ?? '', reportType: rt };
    });
  }

  @computedFrom(
    'showEntityOverviewSetForAnyHandle',
    'handle.showEntityInOverview'
  )
  protected get disableShowEntityOverview(): boolean {
    if (this.handle?.showInEntityOverview) return false;
    if (!this.showEntityOverviewSetForAnyHandle) return false;
    return true;
  }
}

type ReportTypeOption = {
  label: string;
  reportType: ReportType | null;
};
