import { inject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';

/**
 * @event values-changed
 */
@inject(Element, SubscriptionManagerService, AppEntityManager)
export class PersonMultiSelect {
  /** @type {Array<string>} */
  @bindable values = [];

  /** @type {string|null} */
  @bindable userGroupId = null;

  @bindable enabled = false;

  /** @type {string|null} */
  @bindable filterByCategoryName = null;

  /** @type {HTMLElement} */
  _domElement;

  _attached = false;

  /** @type {Array<import('../person-select/person-select').TPersonSelectSelectablePerson} */
  _selectablePersons = [];

  /**
   * @param {HTMLElement} element
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(element, subscriptionManagerService, entityManager) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this._updateSelectablePersons.bind(this)
    );
    this._updateSelectablePersons();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  userGroupIdChanged() {
    this._updateSelectablePersonsIfAttached();
  }

  _updateSelectablePersonsIfAttached() {
    if (this._attached) this._updateSelectablePersons();
  }

  _updateSelectablePersons() {
    const selectablePersons = this._getPersons()
      .filter(
        (person) =>
          !this.filterByCategoryName ||
          person.categoryName === this.filterByCategoryName
      )
      .map((person) => {
        return {
          displayName: this._getDisplayNameForPerson(person),
          personId: person.id,
          person: person
        };
      });

    selectablePersons.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this._selectablePersons = selectablePersons;
  }

  /**
   * @returns {Array<Person>}
   */
  _getPersons() {
    if (this.userGroupId) {
      return this._entityManager.personRepository.getByUserGroupId(
        this.userGroupId
      );
    } else {
      return this._entityManager.personRepository.getAll();
    }
  }

  /**
   * @param {Person} person
   * @returns {string}
   */
  _getDisplayNameForPerson(person) {
    return PersonUtils.getPersonDisplayNameForPerson(person);
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'values-changed',
      detail: null
    });
  }
}

/**
 * @typedef {import('../../classes/EntityManager/entities/Person/types').Person} Person
 */
