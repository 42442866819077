import { Observable, map, OperatorFunction, shareReplay } from 'rxjs';
import { autoinject } from 'aurelia-dependency-injection';

import { PropertyOption } from 'common/Types/Entities/Property/PropertyDto';
import { FilterType } from 'common/Types/GalleryThingPictureFilter/GalleryThingPicturePropertyFilter';

import { Disposable } from '../../classes/Utils/DisposableContainer';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GalleryThingWidgetType } from '../../classes/GalleryThing/GalleryThingWidgetType';

@autoinject()
export class ViaFilterBarConfigurationService {
  private filterBarConfiguration$: Observable<FilterBarConfiguration>;

  constructor(
    activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    const userCompanySettingsValues$ = this.createUserCompanySettingsValues$({
      activeUserCompanySettingService
    });

    this.filterBarConfiguration$ = userCompanySettingsValues$.pipe(
      map(({ useStandardFilterBar, filterBarConfiguration }) => {
        if (useStandardFilterBar || !filterBarConfiguration?.widgets) {
          return this.getStandardFilterBarConfiguration();
        }

        return filterBarConfiguration;
      }),
      this.createReuseConfigurationOperator(),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );
  }

  public bindFilterBarConfiguration(
    callback: (configuration: FilterBarConfiguration) => void
  ): Disposable {
    const subscription = this.filterBarConfiguration$.subscribe(callback);

    return {
      dispose: () => {
        subscription.unsubscribe();
      }
    };
  }

  public getStandardFilterBarConfiguration(): FilterBarConfiguration {
    return {
      widgets: [
        { type: GalleryThingWidgetType.DateRange },
        { type: GalleryThingWidgetType.LatLongArea },
        { type: GalleryThingWidgetType.Region },
        { type: GalleryThingWidgetType.Tags },
        { type: GalleryThingWidgetType.PictureDescription }
      ]
    };
  }

  private createUserCompanySettingsValues$({
    activeUserCompanySettingService
  }: {
    activeUserCompanySettingService: ActiveUserCompanySettingService;
  }): Observable<UserCompanySettingsValues> {
    return new Observable((subscriber) => {
      let lastValue: UserCompanySettingsValues = {
        useStandardFilterBar: false,
        filterBarConfiguration: null
      };

      const modifyValue = (
        overrides: Partial<UserCompanySettingsValues>
      ): void => {
        lastValue = {
          ...lastValue,
          ...overrides
        };

        subscriber.next(lastValue);
      };

      activeUserCompanySettingService.bindSettingProperty(
        'via.useStandardFilterBar',
        (useStandardFilterBar) => {
          modifyValue({ useStandardFilterBar });
        }
      );

      activeUserCompanySettingService.bindJSONSettingProperty(
        'via.filterBarConfiguration',
        (filterBarConfiguration) => {
          modifyValue({ filterBarConfiguration });
        }
      );
    });
  }

  private createReuseConfigurationOperator(): OperatorFunction<
    FilterBarConfiguration,
    FilterBarConfiguration
  > {
    return (observer) => {
      return new Observable((subscriber) => {
        let previousConfigInfo: ConfigWithJsonInfo | null = null;

        const subscription = observer.subscribe((config) => {
          const reuseResult = this.reuseConfig({
            config,
            previousConfigInfo
          });
          subscriber.next(reuseResult.config);

          previousConfigInfo = reuseResult.configInfo;
        });

        return () => {
          subscription.unsubscribe();
        };
      });
    };
  }

  private reuseConfig({
    config,
    previousConfigInfo
  }: {
    config: FilterBarConfiguration;
    previousConfigInfo: ConfigWithJsonInfo | null;
  }): { config: FilterBarConfiguration; configInfo: ConfigWithJsonInfo } {
    const configJson = JSON.stringify(config);

    if (!previousConfigInfo || configJson !== previousConfigInfo.configJson) {
      return {
        config,
        configInfo: {
          config,
          configJson
        }
      };
    }

    return {
      config: previousConfigInfo.config,
      configInfo: previousConfigInfo
    };
  }
}

export type FilterBarConfiguration = {
  widgets: Array<FilterBarWidget>;
};

export type FilterBarWidget = {
  type: GalleryThingWidgetType;
  options?: PersonSelectOptions | null;

  // only used if type === 'property'
  propertyName?: string | null;
  propertyType?: FilterType | null;
  propertyChoices?: Array<string> | null;
  propertyOptions?: Array<PropertyOption> | null;
};

export type PersonSelectOptions = {
  filterByCategoryName: string | null;
};

type UserCompanySettingsValues = {
  useStandardFilterBar: boolean;
  filterBarConfiguration: FilterBarConfiguration | null;
};

type ConfigWithJsonInfo = {
  config: FilterBarConfiguration;
  configJson: string;
};
