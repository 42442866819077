import { inject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@inject(SocketService, SubscriptionManagerService)
export class ExportMultipleProjectsDialog {
  static STEP_CONFIRM = 'confirm';
  static STEP_EXPORTING = 'exporting';
  static STEP_FINISHED = 'finished';

  /** @type {Array<import('../../classes/EntityManager/entities/Project/types').Project>} */
  _projectsToExport = [];
  /** @type {Array<import('../../classes/EntityManager/entities/Project/types').Project>} */
  _exportableProjects = [];
  /** @type {Array<import('../../classes/EntityManager/entities/Project/types').Project>} */
  _notExportableProjects = [];
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _exportingSubscriptionManager;

  /** @type {number} */
  _finishedProjectsCount = 0;
  /** @type {number} */
  _exportedProjectsCount = 0;
  /** @type {number} */
  _failedProjectsCount = 0;
  /** @type {boolean} */
  _disconnectedWhileExporting = false;
  /** @type {string} */
  _step = ExportMultipleProjectsDialog.STEP_CONFIRM;

  /** @type {import('../record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /**
   * @param {SocketService} socketService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(socketService, subscriptionManagerService) {
    this._socketService = socketService;
    this._subscriptionManager = subscriptionManagerService.create();
    this._exportingSubscriptionManager = subscriptionManagerService.create();

    this._ExportMultipleProjectsDialog = ExportMultipleProjectsDialog;
  }

  /**
   * @param {TOpenOptions} options
   */
  open(options) {
    this._projectsToExport = options.projectsToExport;
    this._exportableProjects = options.projectsToExport.filter(
      (p) => !!p.report_type
    );
    this._notExportableProjects = options.projectsToExport.filter(
      (p) => !p.report_type
    );

    this._subscriptionManager.subscribeToEvent(
      'socket:disconnected',
      this._handleSocketDisconnected.bind(this)
    );

    if (this._dialog) {
      this._dialog.open();
    }
  }

  _handleDialogClosed() {
    this._projectsToExport = [];
    this._step = ExportMultipleProjectsDialog.STEP_CONFIRM;
    this._subscriptionManager.disposeSubscriptions();
    this._exportingSubscriptionManager.disposeSubscriptions();
    this._finishedProjectsCount = 0;
    this._exportedProjectsCount = 0;
    this._failedProjectsCount = 0;
    this._disconnectedWhileExporting = false;
  }

  _handleSocketDisconnected() {
    if (this._step !== ExportMultipleProjectsDialog.STEP_EXPORTING) {
      return; // only relevant while exporting
    }

    this._disconnectedWhileExporting = true;
  }

  _handleCloseDialogClick() {
    if (this._dialog) {
      this._dialog.close();
    }
  }

  _handleAcceptButtonClick() {
    switch (this._step) {
      case ExportMultipleProjectsDialog.STEP_CONFIRM:
        this._startExport();
        this._step = ExportMultipleProjectsDialog.STEP_EXPORTING;
        break;

      case ExportMultipleProjectsDialog.STEP_FINISHED:
        if (this._dialog) {
          this._dialog.close();
        }
        break;

      default:
        throw new Error(`unhandled step ${this._step}`);
    }
  }

  _startExport() {
    /** @type {Array<import('../../../../common/src/EndpointTypes/ReportFunctionsEndpointsHandler').IExportProjectRequest>} */
    const requests = this._exportableProjects.map((p) => {
      return {
        exportType: 'docx',
        projectId: p.id,
        timestampMs: Date.now()
      };
    });

    this._socketService.exportMultipleProjects(
      {
        exportRequests: requests
      },
      (response) => {
        this._listenToProgress(response.progressSocketChannelName);
      }
    );
  }

  /**
   * @param {string} progressSocketChannelName
   */
  _listenToProgress(progressSocketChannelName) {
    this._exportingSubscriptionManager.disposeSubscriptions();
    this._exportingSubscriptionManager.listenToSocket(
      progressSocketChannelName,
      this._handleProgress.bind(this)
    );
  }

  /**
   * @param {import('../../../../common/src/EndpointTypes/ReportFunctionsEndpointsHandler').IExportMultipleProjectsProgressResponse} response
   */
  _handleProgress(response) {
    this._finishedProjectsCount = response.finishedProjectsCount;
    this._exportedProjectsCount = response.exportedProjectsCount;
    this._failedProjectsCount = response.failedProjectsCount;

    if (this._finishedProjectsCount >= this._exportableProjects.length) {
      this._step = ExportMultipleProjectsDialog.STEP_FINISHED;
    }
  }

  /**
   * @param {TOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TOpenOptions
 * @property {Array<import('../../classes/EntityManager/entities/Project/types').Project>} projectsToExport
 */
