export type RapidFireModeConfiguration = {
  additionalCaptureButtons: Array<RapidFireModeConfigurationAdditionalButton>;
};

export type RapidFireModeConfigurationAdditionalButton = {
  type: RapidFireModeConfigurationAdditionalButtonType;
  iconName: string;
  iconType: string;
};

export enum RapidFireModeConfigurationAdditionalButtonType {
  THING_SECTION_ASSESSMENT = 'thingSectionAssessment',
  CREATE_ENTRY_WITHOUT_PICTURE = 'createEntryWithoutPicture'
}
