import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

import { DateUtils } from 'common/DateUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { GalleryThingHelper } from 'common/GalleryThing/GalleryThingHelper';

import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { Utils } from '../classes/Utils/Utils';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../classes/EntityManager/entities/types';

/**
 * @aggregatorevent gallery-thing-joined-projects-service:thing-info-changed - is fired everytime a thing gets assigned new value (e.g. via setDates)
 *  or when the data has been loaded from the database
 */
@inject(I18N, EventAggregator, AppEntityManager, SubscriptionManagerService)
export class GalleryThingJoinedProjectsService {
  static _thingInfosDatabaseKey =
    'GalleryThingJoinedProjectsService::ThingInfos';

  /** @type {Array<TGalleryThingJoinedProjectsServiceThingInfo>} */
  _thingInfos = [];

  /** @type {Array<{value: number|null, label: string}>|null} */
  _lastDaysRangeOptions = null;

  /** @type {Promise|null} */
  _initializedPromise = null;
  _initialized = false;

  /** @type {import('../classes/Utils/Utils').UtilsRateLimitedFunction} */
  _saveThingInfosToDatabaseRateLimited;
  /** @type {import('../classes/Utils/Utils').UtilsRateLimitedFunction} */
  _fireThingInfoChangedEventRateLimited;

  /**
   * @param {I18N} i18n
   * @param {EventAggregator} eventAggregator
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(
    i18n,
    eventAggregator,
    entityManager,
    subscriptionManagerService
  ) {
    this._i18n = i18n;
    this._eventAggregator = eventAggregator;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._saveThingInfosToDatabaseRateLimited = Utils.rateLimitFunction(
      this._saveThingInfosToDatabase.bind(this),
      250
    );
    this._fireThingInfoChangedEventRateLimited = Utils.rateLimitFunction(
      this._fireThingInfoChangedEvent.bind(this),
      0
    );
  }

  async init() {
    this._initializedPromise = this._loadThingInfosFromDatabase();
    this._initializedPromise.then(() => {
      this._initialized = true;
      this._initializedPromise = null;
    });

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      () => {
        if (this._initialized) {
          this._autoJoinMissingProjects(this._thingInfos);
        }
      },
      5000
    ); // we can wait the 5 seconds if someone else creates a new project which is in our date range
  }

  destroy() {
    this._initialized = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  /**
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   */
  setDates(thingId, dateRanges) {
    this._modifyThingInfoAndUpdateJoinedProjects(thingId, (info) => {
      info.dateRanges = dateRanges.slice();
    });
  }

  /**
   * @param {string} thingId
   * @returns {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>}
   */
  getDates(thingId) {
    const info = this._getInfoByThingId(thingId);
    return info ? info.dateRanges.slice() : [];
  }

  /**
   * @param {string} thingId
   * @returns {boolean}
   */
  lastDaysRangeIsSet(thingId) {
    const info = this._getInfoByThingId(thingId);
    return !!(info && info.lastDaysRangeIsSet);
  }

  /**
   * @param {string} thingId
   * @param {(number|null)} lastDaysRange
   */
  setLastDaysRange(thingId, lastDaysRange) {
    this._modifyThingInfoAndUpdateJoinedProjects(thingId, (info) => {
      info.lastDaysRange = lastDaysRange != null ? lastDaysRange : null;
      info.lastDaysRangeIsSet = true;
    });
  }

  /**
   * @param {string} thingId
   * @returns {(number|null)}
   */
  getLastDaysRange(thingId) {
    const info = this._getInfoByThingId(thingId);
    return info ? info.lastDaysRange : null;
  }

  /**
   * @param {string} thingId
   * @param {number} timestamp
   * @returns {boolean}
   */
  thingContainsTimestamp(thingId, timestamp) {
    const info = this._getInfoByThingId(thingId);
    if (!info) return false;

    const dateRanges = this._getDateRangesWithLastDaysRangeOfInfo(info);
    return this._timestampIsInDateRange(timestamp, dateRanges);
  }

  async isInitializedAwaitable() {
    if (!this._initialized) {
      await this._initializedPromise;
    }
  }

  /**
   * @returns {Array<{value: number|null, label: string}>}
   */
  getLastDaysRangeOptions() {
    if (!this._lastDaysRangeOptions) {
      this._lastDaysRangeOptions = this._generateLastDaysRangeOptions();
    }

    return this._lastDaysRangeOptions;
  }

  /**
   * @param {string} thingId
   * @param {import('../classes/EntityManager/entities/Project/types').Project} project
   * @memberof GalleryThingJoinedProjectsService
   */
  autoJoinProjectDateIfNeeded(thingId, project) {
    if (!project.name) return;

    const timestamp = GalleryThingHelper.getDateFromProjectName(
      project.name
    ).getTime();
    const lastDaysRange = this.getLastDaysRange(thingId);

    if (!lastDaysRange) {
      this.setLastDaysRange(thingId, 1);
    }

    if (!this.thingContainsTimestamp(thingId, timestamp)) {
      this._entityManager.joinedProjectsManager.joinProject(
        project.id,
        true,
        true
      );
    }
  }

  /**
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   */
  _addToDates(thingId, dateRanges) {
    this._modifyThingInfoAndUpdateJoinedProjects(thingId, (info) => {
      info.dateRanges.push(...dateRanges);
    });
  }

  /**
   * @param {string} thingId
   * @param {function(TGalleryThingJoinedProjectsServiceThingInfo): void} modificationFunction - make your changes to the info here
   * @private
   */
  _modifyThingInfoAndUpdateJoinedProjects(thingId, modificationFunction) {
    const info = this._getOrCreateInfo(thingId);
    const oldDateRanges = this._getDateRangesWithLastDaysRangeOfInfo(info);
    modificationFunction(info);
    const newDateRanges = this._getDateRangesWithLastDaysRangeOfInfo(info);

    this._updateJoinedProjectsToNewDates(thingId, oldDateRanges, newDateRanges);
    this._saveThingInfosToDatabaseRateLimited();
    this._fireThingInfoChangedEventRateLimited();
  }

  /**
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} oldDates
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} newDates
   * @private
   */
  async _updateJoinedProjectsToNewDates(thingId, oldDates, newDates) {
    const addedDates = newDates.filter((d) => {
      return oldDates.indexOf(d) < 0;
    });
    this._joinProjectsForDates(thingId, addedDates);

    const removedDates = oldDates.filter((d) => {
      return newDates.indexOf(d) < 0;
    });
    this._leaveProjectsForDates(thingId, removedDates, newDates);
  }

  /**
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   * @private
   */
  _joinProjectsForDates(thingId, dateRanges) {
    const info = this._getProjectIdsInfoForDates(thingId, dateRanges);
    info.includedProjectIds.forEach((projectId) => {
      this._entityManager.joinedProjectsManager.joinProject(projectId, true);
    });
  }

  /**
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} ignoreDateRanges
   * @private
   */
  _leaveProjectsForDates(thingId, dateRanges, ignoreDateRanges = []) {
    const info = this._getProjectIdsInfoForDates(thingId, dateRanges);
    const ignoreInfo = this._getProjectIdsInfoForDates(
      thingId,
      ignoreDateRanges
    );
    info.includedProjectIds.forEach((projectId) => {
      if (ignoreInfo.includedProjectIds.indexOf(projectId) === -1) {
        this._leaveProjectIfNotJoinedTemporarily(projectId);
      }
    });
  }

  /**
   * @param {string} projectId
   */
  async _leaveProjectIfNotJoinedTemporarily(projectId) {
    if (
      this._entityManager.joinedProjectsManager.projectIsJoinedPermanently(
        projectId
      )
    ) {
      this._entityManager.joinedProjectsManager.leaveProject(projectId);
    }
  }

  /**
   * @param {string} thingId
   * @returns {TGalleryThingJoinedProjectsServiceThingInfo}
   * @private
   */
  _getOrCreateInfo(thingId) {
    let info = this._getInfoByThingId(thingId);
    if (!info) {
      info = {
        thingId: thingId,
        dateRanges: [],
        lastDaysRangeIsSet: false,
        lastDaysRange: null
      };

      this._thingInfos.push(info);
    }

    return info;
  }

  /**
   * @param {string} thingId
   * @returns {TGalleryThingJoinedProjectsServiceThingInfo|null|undefined}
   * @private
   */
  _getInfoByThingId(thingId) {
    return this._thingInfos.find((i) => i.thingId === thingId);
  }

  async _loadThingInfosFromDatabase() {
    const infos = await DataStorageHelper.getItem(
      GalleryThingJoinedProjectsService._thingInfosDatabaseKey
    );
    if (infos) {
      const oldLength = this._thingInfos.length;
      this._mergeInfos(this._thingInfos, infos);
      if (oldLength > 0) {
        this._saveThingInfosToDatabaseRateLimited();
      }
    }

    await this._autoJoinMissingProjects(this._thingInfos);
    this._fireThingInfoChangedEventRateLimited();
  }

  /**
   * since other users could have added a project for the same date, we also need to join that one
   *
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfo>} infos
   * @returns {Promise<void>}
   * @private
   */
  async _autoJoinMissingProjects(infos) {
    infos.forEach((info) => {
      this._autoJoinMissingProjectsForInfo(info);
    });
  }

  /**
   * @param {TGalleryThingJoinedProjectsServiceThingInfo} info
   * @private
   */
  _autoJoinMissingProjectsForInfo(info) {
    const dateRanges = this._getDateRangesWithLastDaysRangeOfInfo(info);
    if (dateRanges.length === 0) {
      // there will be nothing to auto join if there are no date ranges
      return;
    }

    const projectIdsInfo = this._getProjectIdsInfoForDates(
      info.thingId,
      dateRanges
    );
    projectIdsInfo.includedProjectIds.forEach((projectId) => {
      if (
        !this._entityManager.joinedProjectsManager.projectIsJoined(projectId)
      ) {
        this._entityManager.joinedProjectsManager.joinProject(projectId, true);
      }
    });

    projectIdsInfo.excludedProjectIds.forEach((projectId) => {
      if (
        this._entityManager.joinedProjectsManager.projectIsJoined(projectId)
      ) {
        this._leaveProjectIfNotJoinedTemporarily(projectId);
      }
    });
  }

  /**
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfo>} infos1
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfo>} infos2
   * @private
   */
  _mergeInfos(infos1, infos2) {
    infos2.forEach((info2) => {
      const info1 = infos1.find((i) => i.thingId === info2.thingId);

      if (!info1) {
        infos1.push(info2);
      }
    });
  }

  /**
   * includedProjectIds are the corresponding projectIds to the dateRanges array,
   * excludedProjectIds are all projectIds of the thing which are not present in the includedProjectIds
   *
   * @param {string} thingId
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   * @returns {{includedProjectIds: Array<string>, excludedProjectIds: Array<string>}}
   * @private
   */
  _getProjectIdsInfoForDates(thingId, dateRanges) {
    const projects = this._entityManager.projectRepository
      .getByThingId(thingId)
      .filter((p) => p.projectType === ProjectType.GALLERY);

    /** @type {Array<string>} */
    const includedProjectIds = [];

    /** @type {Array<string>} */
    const excludedProjectIds = [];

    projects.forEach((project) => {
      if (!project.name) return;

      if (this._projectNameIsInDateRanges(project.name, dateRanges)) {
        includedProjectIds.push(project.id);
      } else {
        excludedProjectIds.push(project.id);
      }
    });

    return {
      includedProjectIds: includedProjectIds,
      excludedProjectIds: excludedProjectIds
    };
  }

  /**
   * @param {string} projectName
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   * @returns {boolean}
   * @private
   */
  _projectNameIsInDateRanges(projectName, dateRanges) {
    const timestamp =
      GalleryThingHelper.getDateFromProjectName(projectName).getTime();
    return this._timestampIsInDateRange(timestamp, dateRanges);
  }

  /**
   *
   * @param {number} timestamp
   * @param {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
   * @returns {boolean}
   * @private
   */
  _timestampIsInDateRange(timestamp, dateRanges) {
    for (const range of dateRanges) {
      if (timestamp >= range.from && timestamp <= range.to) {
        return true;
      }
    }

    return false;
  }

  /**
   * @param {TGalleryThingJoinedProjectsServiceThingInfo} info
   * @returns Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>
   * @private
   */
  _getDateRangesWithLastDaysRangeOfInfo(info) {
    const ranges = info.dateRanges.slice();
    if (info.lastDaysRange != null) {
      ranges.push({
        from: DateUtils.getStartOfDayTimestamp(info.lastDaysRange * -1),
        to: DateUtils.getStartOfDayTimestamp(0)
      });
    }

    return ranges;
  }

  _saveThingInfosToDatabase() {
    DataStorageHelper.setItem(
      GalleryThingJoinedProjectsService._thingInfosDatabaseKey,
      this._thingInfos
    );
  }

  /**
   * @returns {Array<{value: number|null, label: string}>}
   * @private
   */
  _generateLastDaysRangeOptions() {
    return [
      {
        value: null,
        label: this._translate('noChoice')
      },
      {
        value: 0,
        label: this._translate('todayChoice')
      },
      {
        value: 1,
        label: this._translate('lastDaysChoice')
      },
      {
        value: 2,
        label: this._translate('last2DaysChoice')
      },
      {
        value: 5,
        label: this._translate('last5DaysChoice')
      },
      {
        value: 10,
        label: this._translate('last10DaysChoice')
      }
    ];
  }

  /**
   * you probably don't need to call this function but `_fireThingInfoChangedEventRateLimited` instead
   *
   * @private
   */
  _fireThingInfoChangedEvent() {
    this._eventAggregator.publish(
      'gallery-thing-joined-projects-service:thing-info-changed'
    );
  }

  /**
   * @param {string} key
   * @returns {string}
   */
  _translate(key) {
    return this._i18n.tr('galleryThing.joinedProjectsService.' + key);
  }
}

/**
 *
 * lastDaysRange: a number which determines which projects are will get  automatically subscribed based on the current date ADDITIONALLY to the ones defined in the dateRanges
 *  examples:
 *    null = no project will get automatically subscribed
 *    0 = all projects from today get automatically subscribed
 *    1 = all projects from yesterday and today will get automaticall subscribed
 *    ...
 *
 * if you need to check the covered date ranges of the info, you can't only us the dateRanges, you also have to consider the lastDaysRange
 *
 * @typedef {Object} TGalleryThingJoinedProjectsServiceThingInfo
 * @property {string} thingId
 * @property {number|null} lastDaysRange
 * @property {boolean|null} lastDaysRangeIsSet - true if the lastDaysRange has been explicitly set (and isn't null just because it isn't set yet), is null on older infos
 * @property {Array<TGalleryThingJoinedProjectsServiceThingInfoDateRange>} dateRanges
 */

/**
 * @typedef {Object} TGalleryThingJoinedProjectsServiceThingInfoDateRange
 * @property {number} from - unix timestamp
 * @property {number} to - unix timestamp
 */
