import { autoinject, bindable } from 'aurelia-framework';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { MomentInput } from 'moment/moment';
import { DateUtils } from 'common/DateUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { User } from '../../classes/EntityManager/entities/User/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event project-clicked - triggered when the user tries to open the project
 */
@autoinject()
export class ChecklistProjectListItem {
  @bindable public project: Project | null = null;

  /**
   * Whether to display hours and minutes from the project name.
   */
  @bindable public showTime: boolean = false;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private element: HTMLElement;

  constructor(
    element: Element,
    private entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Project,
        context: this as ChecklistProjectListItem,
        propertyName: 'project'
      }
    );
  }

  protected panelOpen = false;

  @computed(expression('project.name'))
  protected get projectName(): string {
    if (!this.project || !this.project.name) return '...';

    if (this.showTime) {
      return ChecklistProjectNameUtils.toDateStringWithTime(this.project.name);
    }
    return ChecklistProjectNameUtils.toDateString(this.project.name);
  }

  @computed(expression('project.createdByUserId'))
  protected get createdBy(): User | null {
    if (!this.project || !this.project.createdByUserId) return null;

    return this.entityManager.userRepository.getById(
      this.project.createdByUserId
    );
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEnterProjectClick(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleEnterProjectClick without project'
    );

    DomEventHelper.fireEvent<EnterProjectClickedEvent>(this.element, {
      name: 'enter-project-clicked',
      detail: { project: this.project }
    });
  }

  protected handleExportButtonClick(): void {
    // TODO
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleDeleteButtonClick without project'
    );

    const project = this.project;
    void Dialogs.deleteEntityDialog(project).then(() => {
      this.entityManager.projectRepository.delete(project);
    });
  }
}

export type EnterProjectClickedEvent = NamedCustomEvent<
  'enter-project-clicked',
  { project: Project }
>;
