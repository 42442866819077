import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { BaseEntity } from '../../../classes/EntityManager/entities/BaseEntity';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ComputedValueService } from '../../ComputedValueService';
import {
  ProcessTaskInvoiceToProcessTaskDeviceByDeviceId,
  ProcessTaskInvoiceToProcessTaskDeviceMapComputer
} from '../ProcessTaskInvoiceToProcessTaskDeviceMapComputer/ProcessTaskInvoiceToProcessTaskDeviceMapComputer';
import {
  ProcessTaskInvoiceToProcessTaskPositionByPositionId,
  ProcessTaskInvoiceToProcessTaskPositionMapComputer
} from '../ProcessTaskInvoiceToProcessTaskPositionMapComputer/ProcessTaskInvoiceToProcessTaskPositionMapComputer';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ProcessTaskInvoiceMapComputer extends ValueComputer<
  ComputeData,
  ProcessTaskInvoiceMapComputerComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    const invokeComputeRateLimited =
      this.subscriptionManager.createRateLimitedCallback(invokeCompute, 250);

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      invokeComputeRateLimited,
      0
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoice,
      invokeComputeRateLimited,
      0
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskInvoiceToProcessTaskPositionMapComputer,
        computeData: {},
        callback: invokeCompute,
        skipInitialCall: true
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskInvoiceToProcessTaskDeviceMapComputer,
        computeData: {},
        callback: invokeCompute,
        skipInitialCall: true
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskInvoiceMapComputerComputeResult {
    const activeProcessTaskInvoiceToProcessTaskPositionByPositionId =
      this.computedValueService.getCurrentValue(
        ProcessTaskInvoiceToProcessTaskPositionMapComputer,
        {}
      )?.activeProcessTaskInvoiceToProcessTaskPositionByPositionId;
    const activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId =
      this.computedValueService.getCurrentValue(
        ProcessTaskInvoiceToProcessTaskDeviceMapComputer,
        {}
      )?.activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId;

    let processTaskInvoicesByProcessTaskPositionId;
    if (activeProcessTaskInvoiceToProcessTaskPositionByPositionId) {
      processTaskInvoicesByProcessTaskPositionId =
        this.createProcessTaskInvoicesByProcessTaskPositionId({
          activeProcessTaskInvoiceToProcessTaskPositionByPositionId
        });
    } else {
      processTaskInvoicesByProcessTaskPositionId = new Map();
    }

    let processTaskInvoicesByProcessTaskDeviceId;
    if (activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId) {
      processTaskInvoicesByProcessTaskDeviceId =
        this.createProcessTaskInvoicesByProcessTaskDeviceId({
          activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId
        });
    } else {
      processTaskInvoicesByProcessTaskDeviceId = new Map();
    }

    return {
      processTaskInvoicesByProcessTaskPositionId,
      processTaskInvoicesByProcessTaskDeviceId
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private createProcessTaskInvoicesByProcessTaskPositionId({
    activeProcessTaskInvoiceToProcessTaskPositionByPositionId
  }: {
    activeProcessTaskInvoiceToProcessTaskPositionByPositionId: ProcessTaskInvoiceToProcessTaskPositionByPositionId;
  }): ProcessTaskInvoicesByProcessTaskPositionId {
    const invoiceMapCreationDataByPositionId =
      this.createInvoiceMapCreationDataByEntityId({
        entities: this.entityManager.processTaskPositionRepository.getAll(),
        getInvoiceIdsOfEntity: (position) => {
          return (
            activeProcessTaskInvoiceToProcessTaskPositionByPositionId.get(
              position.id
            ) ?? []
          ).map((relation) => relation.processTaskInvoiceId);
        },
        getSnapshottedEntityId(entity) {
          return entity.snapshotOfProcessTaskPositionId;
        }
      });

    const processTaskInvoicesByProcessTaskPositionId: ProcessTaskInvoicesByProcessTaskPositionId =
      new Map();

    for (const creationData of invoiceMapCreationDataByPositionId.values()) {
      const invoices = this.entityManager.processTaskInvoiceRepository.getByIds(
        Array.from(creationData.invoiceIds)
      );

      for (const position of creationData.entities) {
        processTaskInvoicesByProcessTaskPositionId.set(position.id, invoices);
      }
    }

    return processTaskInvoicesByProcessTaskPositionId;
  }

  private createProcessTaskInvoicesByProcessTaskDeviceId({
    activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId
  }: {
    activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId: ProcessTaskInvoiceToProcessTaskDeviceByDeviceId;
  }): ProcessTaskInvoicesByProcessTaskPositionId {
    const invoiceMapCreationDataByDeviceId =
      this.createInvoiceMapCreationDataByEntityId({
        entities: this.entityManager.processTaskDeviceRepository.getAll(),
        getInvoiceIdsOfEntity: (device) => {
          return (
            activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId.get(
              device.id
            ) ?? []
          ).map((relation) => relation.processTaskInvoiceId);
        },
        getSnapshottedEntityId(entity) {
          return entity.snapshotOfProcessTaskDeviceId;
        }
      });

    // TODO extract this
    const processTaskInvoicesByProcessTaskDeviceId: ProcessTaskInvoicesByProcessTaskDeviceId =
      new Map();

    for (const creationData of invoiceMapCreationDataByDeviceId.values()) {
      const invoices = this.entityManager.processTaskInvoiceRepository.getByIds(
        Array.from(creationData.invoiceIds)
      );

      for (const device of creationData.entities) {
        processTaskInvoicesByProcessTaskDeviceId.set(device.id, invoices);
      }
    }

    return processTaskInvoicesByProcessTaskDeviceId;
  }

  private createInvoiceMapCreationDataByEntityId<TEntity extends BaseEntity>({
    entities,
    getInvoiceIdsOfEntity,
    getSnapshottedEntityId
  }: {
    entities: Array<TEntity>;
    getInvoiceIdsOfEntity: (entity: TEntity) => Array<string>;
    getSnapshottedEntityId: (entity: TEntity) => string | null;
  }): InvoiceMapCreationDataByEntityId<TEntity> {
    const invoiceMapCreationDataByEntityId: InvoiceMapCreationDataByEntityId<TEntity> =
      new Map();
    for (const entity of entities) {
      this.addInvoiceMapCreationData({
        invoiceMapCreationDataByPositionId: invoiceMapCreationDataByEntityId,
        entity,
        invoiceIds: getInvoiceIdsOfEntity(entity),
        getSnapshottedEntityId
      });
    }

    return invoiceMapCreationDataByEntityId;
  }

  private addInvoiceMapCreationData<TEntity extends BaseEntity>({
    invoiceMapCreationDataByPositionId,
    entity,
    getSnapshottedEntityId,
    invoiceIds
  }: {
    invoiceMapCreationDataByPositionId: InvoiceMapCreationDataByEntityId<TEntity>;
    entity: TEntity;
    getSnapshottedEntityId: (entity: TEntity) => string | null;
    invoiceIds: Array<string>;
  }): void {
    const newCreationData = this.createInvoiceMapCreationData({
      entity: entity,
      invoiceIds
    });

    const creationDataPositionId = getSnapshottedEntityId(entity) ?? entity.id;
    const existingCreationData = invoiceMapCreationDataByPositionId.get(
      creationDataPositionId
    );
    if (existingCreationData) {
      this.mergeCreationDatas({
        a: existingCreationData,
        b: newCreationData
      });
    } else {
      invoiceMapCreationDataByPositionId.set(
        creationDataPositionId,
        newCreationData
      );
    }
  }

  private createInvoiceMapCreationData<TEntity extends BaseEntity>({
    entity,
    invoiceIds
  }: {
    entity: TEntity;
    invoiceIds: Array<string>;
  }): InvoiceMapCreationData<TEntity> {
    return {
      entities: [entity],
      invoiceIds: new Set(invoiceIds)
    };
  }

  private mergeCreationDatas<TEntity extends BaseEntity>({
    a,
    b
  }: {
    a: InvoiceMapCreationData<TEntity>;
    b: InvoiceMapCreationData<TEntity>;
  }): void {
    a.entities.push(...b.entities);

    for (const invoiceId of b.invoiceIds) {
      a.invoiceIds.add(invoiceId);
    }
  }
}

export type ComputeData = Record<string, never>;

export type ProcessTaskInvoiceMapComputerComputeResult = {
  processTaskInvoicesByProcessTaskPositionId: ProcessTaskInvoicesByProcessTaskPositionId;
  processTaskInvoicesByProcessTaskDeviceId: ProcessTaskInvoicesByProcessTaskDeviceId;
};

/**
 * Also contains invoices which are indirectly referenced (via snapshots etc)
 */
export type ProcessTaskInvoicesByProcessTaskPositionId = Map<
  string,
  Array<ProcessTaskInvoice>
>;

/**
 * Also contains invoices which are indirectly referenced (via snapshots etc)
 */
export type ProcessTaskInvoicesByProcessTaskDeviceId = Map<
  string,
  Array<ProcessTaskInvoice>
>;

/**
 * A map which contains prepared data to create the ProcessTaskInvoicesByProcessTaskPositionId/ProcessTaskInvoicesByProcessTaskDeviceId.
 * The key will always be the id of the non snapshot position and the value will contain the relevant positions (including snapshots) and invoiceIds.
 *
 * The ProcessTaskInvoicesByProcessTaskXXXId can't be created directly, because the snapshot entity and the non snapshot entity need the same invoices
 */
type InvoiceMapCreationDataByEntityId<TEntity extends BaseEntity> = Map<
  string,
  InvoiceMapCreationData<TEntity>
>;

type InvoiceMapCreationData<TEntity extends BaseEntity> = {
  entities: Array<TEntity>;
  invoiceIds: Set<string>;
};
