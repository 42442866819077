import { inject } from 'aurelia-framework';
import { SocketService } from './SocketService';

@inject(SocketService)
export class GeoDataCacheService {
  /**
   * @type {string|null}
   */
  _municipalityCode = null;

  /**
   * @type {import('common/EndpointTypes/GeoDataEndpointsHandler').GeoJson}
   */
  _cachedData;

  /** @type {Map<string, Map<string, import('common/EndpointTypes/GeoDataEndpointsHandler').GeoJson>>} */
  _layerCache = new Map();

  /**
   * @type {function|null}
   */
  callback = null;

  /**
   * @param {SocketService} socketService
   */
  constructor(socketService) {
    this._socketService = socketService;
  }

  /**
   * @param {string} municipalityCode
   * @returns {Promise<import('common/EndpointTypes/GeoDataEndpointsHandler').GeoJson>}
   */
  requestGstData(municipalityCode) {
    return new Promise((resolve, reject) => {
      if (municipalityCode === this._municipalityCode) {
        resolve(this._cachedData);
        return;
      }
      this._municipalityCode = municipalityCode;
      this._socketService.downloadGstData(municipalityCode, (response) => {
        if (response === false) {
          reject(
            new Error(
              `No Gst Data received for municipalityCode "${municipalityCode}"`
            )
          );
          return;
        }
        this._cachedData = response;
        resolve(response);
      });
    });
  }

  /**
   * @param {string} layerId
   * @param {number} x
   * @param {number} y
   * @param {number} z
   * @returns {Promise<import('common/EndpointTypes/GeoDataEndpointsHandler').GeoJson>}
   */
  requestGeoJsonTile(layerId, x, y, z) {
    let savedLayerCache = this._layerCache.get(layerId);
    if (!savedLayerCache) {
      savedLayerCache = new Map();
      this._layerCache.set(layerId, savedLayerCache);
    }

    const layerCache = savedLayerCache;

    const mapIndex = `${x},${y},${z}`;
    const cachedData = layerCache.get(mapIndex);

    return new Promise((resolve, reject) => {
      if (cachedData) {
        resolve(cachedData);
      } else {
        this._socketService.getGeoJsonTile(
          layerId,
          x,
          y,
          z,
          (response, request) => {
            if (response.success) {
              layerCache.set(mapIndex, response.data);
              resolve(response.data);
            } else {
              reject(new Error(response.status));
            }
          }
        );
      }
    });
  }
}
