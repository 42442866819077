import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { ElementSize } from '../../aureliaAttributes/ResizeObserverCustomAttribute';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  EntityOverviewButtonConfig,
  EntityWidgetAdapter
} from '../EntityWidgetAdapter/EntityWidgetAdapter';
import { EntityWidgetHandle } from '../EntityWidgetHandle/EntityWidgetHandle';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class EntityWidgetContainer {
  @bindable public entityWidgetAdapter: EntityWidgetAdapter | null = null;

  protected titleTk = '';

  protected entityOverviewButtonConfig: EntityOverviewButtonConfig | null =
    null;

  protected currentElementSize: ElementSize | null = null;

  private handles: Array<EntityWidgetHandle> = [];

  private entityLimitFromCompanySetting: EntityLimit = defaultEntityLimit;

  private computedEntityLimit: EntityLimit = defaultEntityLimit;

  private domElement: HTMLElement;

  private gridHtmlElements: Array<HTMLElement> = [];

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly router: Router
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.entityWidgetAdapter,
      'cannot show last opened items without an entityWidgetAdapter'
    );

    const disposable = this.entityWidgetAdapter.subscribe({
      setEntityHandles: (handles) => {
        this.handles = handles;
      },
      setEntityMinNumberToShow: (minNumberToShow) => {
        this.entityLimitFromCompanySetting.defaultVisibleCount =
          minNumberToShow ?? defaultEntityLimit.defaultVisibleCount;
      },
      setEntityMaxNumberToShow: (maxNumberToShow) => {
        this.entityLimitFromCompanySetting.totalCount =
          maxNumberToShow ?? defaultEntityLimit.totalCount;
      },
      setTitleTk: (titleTk) => {
        this.titleTk = titleTk;
      },
      setEntityOverviewButtonConfig: (entityOverviewButtonConfig) => {
        this.entityOverviewButtonConfig = entityOverviewButtonConfig;
      }
    });
    if (disposable) {
      this.subscriptionManager.addDisposable(disposable);
    }

    this.gridHtmlElements = Array.from(
      this.domElement.getElementsByClassName('entity-widget-container--Grid')
    ) as Array<HTMLElement>;
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(
    expression('handles'),
    expression('entityLimitFromCompanySetting'),
    expression('currentElementSize')
  )
  protected get displayedEntities(): GroupedEntities {
    this.computeEntityLimits();
    return {
      defaultVisible: this.handles.slice(
        0,
        this.computedEntityLimit.defaultVisibleCount
      ),
      defaultHidden: this.handles.slice(
        this.computedEntityLimit.defaultVisibleCount,
        this.computedEntityLimit.totalCount
      )
    };
  }

  protected handleResize(elementSize: ElementSize): void {
    this.currentElementSize = { ...elementSize };
  }

  private computeEntityLimits(): void {
    const colCount = this.getDisplayableColumnCount();

    const limits = { ...this.entityLimitFromCompanySetting };

    const neededRows = Math.ceil(limits.defaultVisibleCount / colCount);
    limits.defaultVisibleCount = neededRows * colCount;
    if (limits.defaultVisibleCount > limits.totalCount)
      limits.defaultVisibleCount = limits.totalCount;

    this.computedEntityLimit = limits;
  }

  private getDisplayableColumnCount(): number {
    const grid = this.gridHtmlElements[0];
    if (!grid) return 0;
    const gridComputedStyle = window.getComputedStyle(grid);
    const gridColumnCount = gridComputedStyle
      .getPropertyValue('grid-template-columns')
      .split(' ').length;
    return gridColumnCount;
  }

  protected navigateToEntityOverview(): void {
    assertNotNullOrUndefined(
      this.entityOverviewButtonConfig,
      'no route defined for this click handler'
    );

    this.router.navigateToRoute(this.entityOverviewButtonConfig.routeName);
  }
}

export const defaultEntityLimit: EntityLimit = {
  defaultVisibleCount: 3,
  totalCount: 12
};

type GroupedEntities = {
  defaultVisible: Array<EntityWidgetHandle>;
  defaultHidden: Array<EntityWidgetHandle>;
};

export type EntityLimit = {
  defaultVisibleCount: number;
  totalCount: number;
};
