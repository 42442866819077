import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GeneralFileTypes } from 'common/Enums/GeneralFileTypes';
import { TSignatureChangedEvent } from '../../aureliaComponents/signature-area/signature-area';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import { GeneralFile } from '../../classes/EntityManager/entities/GeneralFile/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertySignatureWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertySignatureWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected signatureImageUrl: string | null = null;
  private signatureFile: GeneralFile | null = null;

  constructor(
    private readonly generalFileUploadService: GeneralFileUploadService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    throw new Error("BasePropertySignatureWidget doesn't support focus");
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'configuration.binding.generalFiles',
      () => {
        this.updateSignatureUrl();
      }
    );

    this.updateSignatureUrl();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateSignatureUrl(): void {
    const files = this.configuration?.binding.generalFiles ?? [];
    const signatureFile = files.find(
      (file) => file.type === GeneralFileTypes.SIGNATURE
    );
    if (signatureFile) {
      this.signatureImageUrl =
        GeneralFileUtils.getFullOnlineFilePathForGeneralFile(signatureFile);
      this.signatureFile = signatureFile;
    } else {
      this.signatureImageUrl = null;
      this.signatureFile = null;
    }
  }

  protected handleSignatureChanged(event: TSignatureChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertySignatureWidget.handleSignatureChanged without configuration"
    );

    let file;
    if (this.signatureFile) {
      file = this.signatureFile;
    } else {
      file = this.configuration.binding.createGeneralFile({
        type: GeneralFileTypes.SIGNATURE
      });
    }

    this.generalFileUploadService.uploadGeneralFile(
      file,
      event.detail.dataUrl,
      'svg'
    );
  }
}

export const basePropertySignatureWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default', 'generalFile']
  });
