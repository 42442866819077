import { isEqual as isObjEqual } from 'lodash';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';
import { FilterSettings } from '../../services/ProcessTaskGroupFilterSettingsService';

export namespace FilterBarFilterSettingsUtils {
  export function isEqual(a: any, b: any): boolean {
    const objA = getFilterComparisonObj(a);
    const objB = getFilterComparisonObj(b);
    return isObjEqual(objA, objB);
  }

  /**
   * Returns a filter where fields set by the UI are reset.
   * Using this, two filter setting objects can be properly compared with an object equality check.
   */
  const getFilterComparisonObj = <T extends Partial<FilterSettings>>(
    filter: T
  ): T => {
    return {
      ...filter,
      // `customActionStatusFilterText` is set to '' by the UI
      customActionStatusFilterText:
        filter.customActionStatusFilterText === ''
          ? null
          : filter.customActionStatusFilterText,
      // `dateRangeFilterDateFromIso` & `dateRangeFilterDateToIso` dates are set automatically by the UI
      // these don't need to be checked if the filterMode is not DATE_RANGE
      dateRangeFilterDateFromIso:
        filter.filterMode === ProcessTaskGroupFilterMode.DATE_RANGE
          ? filter.dateRangeFilterDateFromIso
          : null,
      dateRangeFilterDateToIso:
        filter.filterMode === ProcessTaskGroupFilterMode.DATE_RANGE
          ? filter.dateRangeFilterDateToIso
          : null
    };
  };
}

export type FilterBarFilterSettings = Omit<
  FilterSettings,
  | 'selectedProcessTaskGroupFilterId'
  | 'selectedProcessConfigurationStepIds'
  | 'excludedProcessConfigurationStepIds'
  | 'searchText'
>;
