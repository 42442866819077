import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProjectQuestionCatalogueToQuestionSetEntityInfo } from 'common/Types/Entities/ProjectQuestionCatalogueToQuestionSet/createProjectQuestionCatalogueToQuestionSetEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProjectQuestionCatalogueToQuestionSet } from './types';

export const projectQuestionCatalogueToQuestionSetEntityInfo =
  createProjectQuestionCatalogueToQuestionSetEntityInfo<ProjectQuestionCatalogueToQuestionSet>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
