import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { ProcessTaskPictureOverviewDialog } from '../process-task-picture-overview-dialog/process-task-picture-overview-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessConfigurationStepPosition } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionProperty } from '../../classes/EntityManager/entities/Property/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskPositionEditWidget } from '../process-task-position-edit-widget/process-task-position-edit-widget';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import {
  IProcessConfigurationPositionPropertiesConfiguration,
  TPositionDefaultPropertyConfig
} from '../../../../common/src/Types/ProcessConfigurationPositionPropertiesConfiguration';
import { TOperationsOfferPositionsViewPositionClickedEvent } from '../operations-offer-positions-view/operations-offer-positions-view';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskPositionCreationService } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionCreationService';

@autoinject()
export class CreateProcessTaskPositionDialog {
  private static TEMPORARY_GROUP_NAME = 'CreateProcessTaskPositionDialog';
  private static TEMPORARY_DEFAULT_PROPERTY_GROUP_NAME =
    'CreateProcessTaskPositionDialog::defaultProperty';

  public static async open(
    options: TCreateProcessTaskPositionDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  private processTask: ProcessTask | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private createdAtAppointmentId: string | null = null;
  private processConfigurationCategory: ProcessConfigurationCategory | null =
    null;
  private thingGroup: ThingGroup | null = null;
  private availableProcessConfigurationStepPositions: Array<ProcessConfigurationStepPosition> =
    [];
  private processTaskPositionToCreate: ProcessTaskPosition | null = null;
  private onDialogClosed: OnDialogClosed | null = null;
  private lastCreatedProcessTaskPosition: ProcessTaskPosition | null = null;
  private defaultPropertyConfigs: Array<TPositionDefaultPropertyConfig> = [];
  private selectionProperties: Array<ProcessTaskPositionProperty> = [];

  private dialog: RecordItDialog | null = null;

  private positionEditWidgetViewModel: ProcessTaskPositionEditWidget | null =
    null;

  private state: State = State.SELECTION;
  private readonly State = State;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly processTaskPositionCreationService: ProcessTaskPositionCreationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.i18n = i18n;
    this.subscriptionManager = subscriptionManagerService.create();
    this.entityManager = entityManager;
    this.processTaskLoggingService = processTaskLoggingService;
  }

  public open(options: TCreateProcessTaskPositionDialogOpenOptions): void {
    this.processTask = options.processTask;
    this.onDialogClosed = options.onDialogClosed ?? null;
    this.createdAtAppointmentId = options.createdAtAppointmentId ?? null;
    this.processConfigurationCategory =
      options.processConfigurationCategory ?? null;

    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.processTask.ownerProcessTaskGroupId
      );
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    }

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStepPosition,
      this.updateAvailableProcessConfigurationStepPositions.bind(this)
    );
    this.updateAvailableProcessConfigurationStepPositions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateDefaultPropertyConfigs.bind(this)
    );
    this.updateDefaultPropertyConfigs();

    if (this.dialog) {
      this.dialog.open();
      if (options.processConfigurationStepPosition) {
        this.startCreation(options.processConfigurationStepPosition);
      } else {
        this.dialog.setTabDisabled(State.CREATION, true);
      }
    }
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const lastCreated = this.lastCreatedProcessTaskPosition;

    this.processTask = null;
    this.onDialogClosed = null;
    this.lastCreatedProcessTaskPosition = null;
    this.availableProcessConfigurationStepPositions = [];
    this.setState(State.SELECTION);
    this.processTaskPositionToCreate = null;
    this.subscriptionManager.disposeSubscriptions();
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateProcessTaskPositionDialog.TEMPORARY_GROUP_NAME
    );
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateProcessTaskPositionDialog.TEMPORARY_DEFAULT_PROPERTY_GROUP_NAME
    );

    onClosed?.(lastCreated);
  }

  private updateAvailableProcessConfigurationStepPositions(): void {
    let positions: Array<ProcessConfigurationStepPosition> = [];

    if (
      this.processTaskGroup?.thingGroupOwnerPersonId &&
      !this.processConfigurationCategory?.useGeneralPositions
    ) {
      positions = this.getProcessConfigurationStepPositions(
        this.processTaskGroup.thingGroupOwnerPersonId
      );
    }

    if (positions.length === 0) {
      positions = this.getProcessConfigurationStepPositions(null);
    }

    this.availableProcessConfigurationStepPositions = positions;
  }

  private updateDefaultPropertyConfigs(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateProcessTaskPositionDialog.TEMPORARY_DEFAULT_PROPERTY_GROUP_NAME
    );

    const processConfiguration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;
    const propertiesConfig: IProcessConfigurationPositionPropertiesConfiguration | null =
      processConfiguration &&
      processConfiguration.positionPropertiesConfigurationJson
        ? JSON.parse(processConfiguration.positionPropertiesConfigurationJson)
        : null;
    this.defaultPropertyConfigs = propertiesConfig
      ? propertiesConfig.properties
      : [];

    this.updateSelectionProperties();
  }

  private updateSelectionProperties(): void {
    const processTask = this.processTask;

    if (!processTask) {
      this.selectionProperties = [];
      return;
    }

    const filteredConfigs = this.defaultPropertyConfigs
      .map((config, index) => {
        return {
          config,
          order: index
        };
      })
      .filter((item) => item.config.showInSelection);

    this.selectionProperties = filteredConfigs.map(({ order, config }) => {
      return this.entityManager.propertyRepository.create({
        value: '',
        ...config,
        order,
        processTaskPositionId: 'tempEntityId',
        ownerProcessTaskId: processTask.id,
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerUserGroupId: processTask.ownerUserGroupId,
        temporaryGroupName:
          CreateProcessTaskPositionDialog.TEMPORARY_DEFAULT_PROPERTY_GROUP_NAME,
        shadowEntity: true
      }) as ProcessTaskPositionProperty;
    });
  }

  private getProcessConfigurationStepPositions(
    personId: string | null
  ): Array<ProcessConfigurationStepPosition> {
    if (!this.processTaskGroup) {
      return [];
    }

    return this.entityManager.processConfigurationStepPositionRepository
      .getActiveEntitiesByProcessConfigurationId(
        this.processTaskGroup.processConfigurationId
      )
      .filter(
        (s) =>
          s.personId === personId || (personId == null && s.personId == null)
      );
  }

  private handleCancelClick(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  private handleProcessConfigurationStepPositionClicked(
    event: TOperationsOfferPositionsViewPositionClickedEvent
  ): void {
    this.startCreation(event.detail.processConfigurationStepPosition);
  }

  private startCreation(
    processConfigurationStepPosition: ProcessConfigurationStepPosition
  ): void {
    const processTask = this.processTask;
    if (!processTask) {
      throw new Error("Can't create a position without a process Task");
    }

    this.processTaskPositionToCreate =
      this.processTaskPositionCreationService.create({
        processTask,
        createdAtAppointmentId: this.createdAtAppointmentId,
        processConfigurationCategoryId:
          this.processConfigurationCategory?.id ?? null,
        processConfigurationStepPosition,
        propertiesToCopyValuesFrom: this.selectionProperties,
        temporaryGroupName: CreateProcessTaskPositionDialog.TEMPORARY_GROUP_NAME
      });

    this.setState(State.CREATION);
  }

  private handleBackClick(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateProcessTaskPositionDialog.TEMPORARY_GROUP_NAME
    );
    this.setState(State.SELECTION);
  }

  private handleShowPicturesDialogClick(): void {
    if (!this.processTask) {
      throw new Error('no ProcessTask');
    }

    void ProcessTaskPictureOverviewDialog.open({
      processTask: this.processTask
    });
  }

  private handleAcceptClick(): void {
    if (this.processTaskPositionToCreate && this.processTask) {
      this.createPosition(this.processTaskPositionToCreate, this.processTask);
    } else if (this.dialog) {
      this.dialog.close();
    }
  }

  private createPosition(
    processTaskPositionToCreate: ProcessTaskPosition,
    processTask: ProcessTask
  ): void {
    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      CreateProcessTaskPositionDialog.TEMPORARY_GROUP_NAME,
      processTask.temporaryGroupName
    );
    this.lastCreatedProcessTaskPosition = processTaskPositionToCreate;
    void this.processTaskLoggingService.logProcessTaskSubEntityCreated({
      entityName: EntityName.ProcessTaskPosition,
      entity: this.lastCreatedProcessTaskPosition,
      displayNameAtLogTime: this.lastCreatedProcessTaskPosition.name
    });

    this.setState(State.SELECTION);

    NotificationHelper.notifySuccess(
      this.i18n.tr(
        'operations.createProcessTaskPositionDialog.positionCreated',
        { positionName: processTaskPositionToCreate.name }
      )
    );

    // replay the opening animation
    if (this.dialog) {
      this.dialog.open();
    }
  }

  private setState(state: State): void {
    this.state = state;
    if (this.dialog) {
      this.dialog.selectTab(state);

      const vm = this.positionEditWidgetViewModel;
      if (state === State.CREATION && vm) {
        setTimeout(() => {
          vm.focusInput();
        }, 20);
      }
    }
  }
}

export type TCreateProcessTaskPositionDialogOpenOptions = {
  processTask: ProcessTask;
  processConfigurationStepPosition?: ProcessConfigurationStepPosition | null;
  createdAtAppointmentId?: string | null;
  processConfigurationCategory?: ProcessConfigurationCategory | null;
  onDialogClosed?: OnDialogClosed | null;
};

export type OnDialogClosed = (
  lastCreatedPosition: ProcessTaskPosition | null
) => void;

enum State {
  SELECTION = 'selection',
  CREATION = 'creation'
}
