import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { UserRoleToUserDto } from './UserRoleToUserDto';

export function createUserRoleToUserEntityInfo<
  TEntity extends UserRoleToUserDto<string, string>
>(
  options: CreateEntityInfoOptions<UserRoleToUserDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.UserRoleToUser,
  TEntity
> {
  return {
    entityName: EntityName.UserRoleToUser,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserRoleToUserDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          ref: EntityName.User,
          cascadeDeleteLocally: true
        },
        userRoleId: {
          name: 'userRoleId',
          ref: EntityName.UserRole,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
