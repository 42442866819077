import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomElementIdGenerator } from '../../../classes/DomUtilities/DomElementIdGenerator';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { SelectEntryPathHandle } from './SelectEntryPathHandle';

export class StructureThingRapidFireWidgetSelectEntryPathContent {
  @bindable()
  public selectEntryPathHandle: SelectEntryPathHandle | null = null;

  protected selectedEntryPath: Array<Entry> | null = null;
  protected readonly radioButtonName = DomElementIdGenerator.getNextId();

  public acceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.selectEntryPathHandle,
      "can't StructureThingRapidFireWidgetSelectEntryPathContent.acceptButtonClicked without selectEntryPathHandle"
    );

    if (this.selectedEntryPath) {
      this.selectEntryPathHandle.selectEntryPath(this.selectedEntryPath);
    }
  }

  protected selectEntryPathHandleChanged(): void {
    this.selectedEntryPath = this.selectEntryPathHandle?.entryPaths[0] ?? null;
  }
}
