import { bindable, inject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { ProcessTaskAppointmentSmsNotification } from '../../../../common/src/Enums/ProcessTaskAppointmentSmsNotification';

/**
 * @event value-changed
 */
@inject(Element)
export class ProcessTaskAppointmentSmsNotificationSelect {
  /** @type {import('../../../../common/src/Enums/ProcessTaskAppointmentSmsNotification').TProcessTaskAppointmentSmsNotification} */
  @bindable value = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {HTMLElement} */
  _domElement;

  _options = [
    {
      value: ProcessTaskAppointmentSmsNotification.DEFAULT,
      labelTk: 'modelsDetail.ProcessTaskAppointmentModel.smsNotification_null'
    },
    {
      value: ProcessTaskAppointmentSmsNotification.YES,
      labelTk: 'modelsDetail.ProcessTaskAppointmentModel.smsNotification_1'
    },
    {
      value: ProcessTaskAppointmentSmsNotification.NO,
      labelTk: 'modelsDetail.ProcessTaskAppointmentModel.smsNotification_2'
    }
  ];

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._domElement = element;
  }

  _handleValueChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
