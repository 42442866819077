import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationStepPosition } from './types';

export class ProcessConfigurationStepPositionRepository extends AppEntityRepository<EntityName.ProcessConfigurationStepPosition> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationStepPosition> {
    return this.getAll().filter(
      (position) =>
        position.ownerProcessConfigurationId === processConfigurationId
    );
  }

  public getActiveEntitiesByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationStepPosition> {
    return this.getAll().filter((position) => {
      return (
        !position.deactivated &&
        position.ownerProcessConfigurationId === processConfigurationId
      );
    });
  }

  public getByProcessConfigurationStepId(
    processConfigurationStepId: string
  ): Array<ProcessConfigurationStepPosition> {
    return this.getAll().filter((position) => {
      return position.processConfigurationStepId === processConfigurationStepId;
    });
  }
}
