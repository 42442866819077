import { StorageAdapter } from '@record-it-npm/synchro-client';
import { DataStorageHelper } from './classes/DataStorageHelper/DataStorageHelper';

export function initStorageAdapter(): void {
  StorageAdapter.configure({
    getEntities: (entityName) => {
      return DataStorageHelper.getItems(entityName);
    },
    setEntities: (entities, entityName) => {
      return DataStorageHelper.setItems(
        entities.map((e) => ({ key: e.id, value: e })),
        entityName
      );
    },
    removeEntities: (ids, entityName) => {
      return DataStorageHelper.removeItems(ids, entityName);
    },
    getGeneralItem: (key) => {
      return DataStorageHelper.getItem(key);
    },
    setGeneralItem: (key, value) => {
      return DataStorageHelper.setItem(key, value);
    },
    removeGeneralItem: (key) => {
      return DataStorageHelper.removeItem(key);
    }
  });
}
