import { MomentInput } from 'moment';
import { autoinject, bindable } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { CoordinateHelper, Coordinates } from '../../classes/CoordinateHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event thing-changed
 */
@autoinject()
export class ThingDialogFields {
  @bindable public thing: Thing | null = null;

  @bindable public thingGroupSelectionEnabled = false;

  /**
   * if this is set, then all sub entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable public temporaryGroupName: string | null = null;

  private domElement: HTMLElement;

  protected clientCoordinates: Coordinates | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as ThingDialogFields,
        propertyName: 'thing'
      }
    );
  }

  protected attached(): void {
    this.clientCoordinates = CoordinateHelper.getClientCoordinates();
  }

  protected handleThingChanged(): void {
    if (!this.domElement) {
      return;
    }

    DomEventHelper.fireEvent(this.domElement, {
      name: 'thing-changed',
      detail: null
    });
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected getUsergroupNameById(usergroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(usergroupId);
    return group && group.name ? group.name : '';
  }

  protected canCalculateDistance(
    latitude: number | null,
    longitude: number | null,
    clientLatitude: number | null,
    clientLongitude: number | null
  ): boolean {
    return (
      latitude != null &&
      longitude != null &&
      clientLatitude != null &&
      clientLongitude != null
    );
  }

  protected calculateDistance(
    latitude: number,
    longitude: number,
    clientLatitude: number,
    clientLongitude: number
  ): string {
    return CoordinateHelper.calculateFormattedDistance(
      clientLongitude,
      clientLatitude,
      longitude,
      latitude
    );
  }
}
