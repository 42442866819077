import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

@autoinject()
export class EditThingPersonRelationsDialog {
  protected thing: Thing | null = null;

  protected dialog: RecordItDialog | null = null;

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: Options): void {
    this.thing = options.thing;
    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.thing = null;
  }
}

type Options = {
  thing: Thing;
};
