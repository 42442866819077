import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FilterHelper } from '../../classes/FilterHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditUserGroupDialog } from '../../aureliaComponents/edit-user-group-dialog/edit-user-group-dialog';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

/**
 * if this site will get shown/called with a userGroupId query param, it will automagically navigate to the group
 */
@autoinject()
export class edit_usergroups {
  @observable private userGroupFilterString: string;

  private sortOptions: { name: SorterSortOption<UserGroup> } = {
    name: {
      name: 'Name',
      sortFunction: function (a, b) {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return aName.localeCompare(bName);
      }
    }
  };

  private currentSortOption = this.sortOptions.name;

  private sortedUserGroups: Array<UserGroup> = [];

  private availableUserGroups: Array<UserGroup> = [];
  private filteredUserGroups: Array<UserGroup> = [];
  private currentPageUserGroups: Array<UserGroup> = [];
  protected isMobile: boolean = false;

  private pagination: Pagination | null = null;

  private editUserGroupDialog: EditUserGroupDialog | null = null;

  private isAttached = false;

  private currentUser: User | null = null;

  private currentUserGroupEditing: UserGroup | null = null;

  private router: Router;

  private subscriptionManager: SubscriptionManager;

  constructor(
    router: Router,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.router = router;
    this.subscriptionManager = subscriptionManagerService.create();

    this.userGroupFilterString = '';
  }

  protected activate(params: { user_group_id: string }): void {
    if (params.user_group_id) {
      const userGroup = this.entityManager.userGroupRepository.getById(
        params.user_group_id
      );
      if (userGroup) this.tryEditUserGroup(userGroup);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateUserGroups.bind(this)
    );

    const userGroupId =
      this.router.currentInstruction.queryParams['userGroupId'];
    if (userGroupId) {
      const userGroup =
        this.entityManager.userGroupRepository.getById(userGroupId);
      if (userGroup) {
        void ScrollHelper.autoScrollToListItem(
          '#edit-usergroups--usergroup-' + userGroup.id,
          this.pagination,
          userGroup,
          () => this.isAttached
        );
      }
    }
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private handleCreateUserGroupClick(): void {
    assertNotNullOrUndefined(
      this.currentUser,
      'cannot create usergroup without a current user'
    );

    const userGroup = this.entityManager.userGroupRepository.create({
      synchronizeUsers: true,
      userSpecs: [
        {
          _id: this.currentUser.id,
          group_admin: true,
          can_edit: true
        }
      ]
    });
    void ScrollHelper.autoScrollToListItem(
      '#edit-usergroups--usergroup-' + userGroup.id,
      this.pagination,
      userGroup,
      () => this.isAttached
    );
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateUserGroups();
  }

  private updateUserGroups(): void {
    if (this.currentUser) {
      this.availableUserGroups =
        this.entityManager.userGroupRepository.getAll();
    } else {
      this.availableUserGroups = [];
    }

    this.handleFilterUserGroups();
  }

  // ////////// View Helper

  private userCanCreateUserGroups(user: User): boolean {
    return PermissionHelper.userHasPermission(user, 'canCreateGroups');
  }

  protected userGroupFilterStringChanged(): void {
    this.handleFilterUserGroups();
  }

  private handleFilterUserGroups(): void {
    this.filteredUserGroups = FilterHelper.filterItems(
      this.availableUserGroups,
      (userGroup) => userGroup.name ?? '',
      this.userGroupFilterString
    );
  }

  private handleEditUserGroupClick(userGroup: UserGroup): void {
    this.editUserGroup(userGroup);
  }

  private editUserGroup(userGroup: UserGroup): void {
    this.currentUserGroupEditing = userGroup;
    this.editUserGroupDialog?.open();
  }

  private tryEditUserGroup(userGroup: UserGroup): void {
    if (this.isAttached) {
      this.editUserGroup(userGroup);
    } else {
      setTimeout(() => {
        this.tryEditUserGroup(userGroup);
      }, 10);
    }
  }

  private handleEditUserGroupDialogClosed(): void {
    if (this.currentUserGroupEditing)
      this.goToUserGroup(this.currentUserGroupEditing);
  }

  private goToUserGroup(userGroup: UserGroup): void {
    void ScrollHelper.autoScrollToListItem(
      `#edit-usergroups--usergroup-${userGroup.id}`,
      this.pagination,
      userGroup,
      () => this.isAttached
    );
  }
}
