import { OptionalProperties } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';
import {
  ProjectType,
  StructureTemplateProjectType
} from '../Project/ProjectDto';

type FullStructureTemplate<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    name: string | null;
    description: string | null;
    type: StructureTemplateType;
    status: StructureTemplateStatus;

    /**
     * Configuration for levels in the tree of corresponding structure entries.
     * "Level" here refers to the level of a tree (http://typeocaml.com/2014/11/26/height-depth-and-level-of-a-tree/)
     *
     * A LevelConfiguration with `{ level: X, ... }` should therefore be applied to all nodes with a level of `X`
     * in corresponding structure entry trees.
     */
    levelConfigurations: Array<LevelConfiguration> | null;

    /**
     * Controls whether rating is enabled on structure list items.
     */
    ratingEnabledOnStructureListItems: boolean | null;

    enableFlawNumbers: boolean;
    flawNumberMajorPartConfig: FlawNumberMajor | null;
    flawNumberMinorPartConfig: FlawNumberMinor | null;
    minorTextForExcludedEntries: string | null;

    /**
     * @deprecated 2020-07-16
     */
    oldTemplateName: string | null;
  };

export type StructureTemplateDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullStructureTemplate<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullStructureTemplate<TId, TDate> = never
> = Omit<
  OptionalProperties<FullStructureTemplate<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export enum StructureTemplateType {
  INSPECT = 'inspect',
  B1300 = 'b1300'
}

export const projectTypeToStructureTemplateTypeMap: Record<
  StructureTemplateProjectType,
  StructureTemplateType
> = {
  [ProjectType.INSPECT]: StructureTemplateType.INSPECT,
  [ProjectType.B1300]: StructureTemplateType.B1300
};

export enum StructureTemplateStatus {
  DRAFT = 'draft',
  PROVISIONALLY_ACTIVE = 'provisionallyActive',
  ACTIVE = 'active',
  ARCHIVED = 'archived'
}

/**
 * A configuration for a level of the structure entry trees that belong to this StructureTemplate.
 */
export type LevelConfiguration = {
  /** The level this configuration refers to. */
  level: number;
  /** Whether rating is enabled on this level. */
  ratingEnabled?: boolean | null;
};

export enum FlawNumberMajor {
  FIRST_LEVEL_LIST_POSITION = 'firstLevelListPosition', // 1.x, 2.x, 4.x, 8.x
  SEQUENTIAL = 'sequential' // 1.x, 2.x, 3.x, 4.x
}

export enum FlawNumberMinor {
  SEQUENTIAL_PER_MAJOR = 'sequentialPerMajor', // 1.1, 1.2, 2.1, 3.1
  SEQUENTIAL_GLOBAL = 'sequentialGlobal' // 1.1, 1.2, 2.3, 3.4
}
