import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  Picture,
  PictureAdditionalMarking,
  PictureMarking
} from '../../classes/EntityManager/entities/Picture/types';
import {
  MoreButton,
  MoreButtonChoice
} from '../../aureliaComponents/more-button/more-button';
import { SelectPictureDialog } from '../../dialogs/select-picture-dialog/select-picture-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  AdditionalMarkingsHandle,
  MarkedPictureInfo
} from './AdditionalMarkingsHandle';
import { SelectPictureDialogEntryAdapter } from '../../dialogs/select-picture-dialog/SelectPictureDialogAdapter/SelectPictureDialogEntryAdapter/SelectPictureDialogEntryAdapter';
import {
  LastUsedPictureForIdService,
  LastUsedThingSectionForIdService
} from '../../services/LastUsedEntityService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { PictureMarkingService } from '../../classes/EntityManager/entities/Picture/PictureMarkingService';
import {
  MarkingSetEvent,
  PictureMarkingOverlayWithRelatedPictures
} from '../picture-marking-overlay-with-related-pictures/picture-marking-overlay-with-related-pictures';

/**
 * @event additional-markings-changed
 */
@autoinject()
export class AdditionalMarkedPicturesList {
  /**
   * array of markings as described in the Picture
   * if no value is passed it will automatically be set to an array
   */
  @bindable public additionalMarkings: Array<PictureAdditionalMarking> | null =
    null;

  @bindable public projectId: string | null = null;

  @bindable public enabled: boolean = false;

  private additionalMarkingsHandle: AdditionalMarkingsHandle;

  protected markedPictureInfos: Array<MarkedPictureInfo> = [];

  private markingOverlay: PictureMarkingOverlayWithRelatedPictures | null =
    null;

  private moreButton: MoreButton | null = null;

  private domElement: HTMLElement;

  protected moreButtonChoices: Array<MoreButtonChoice> = [];

  private lastMoreButtonInfo: MarkedPictureInfo | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly lastUsedPictureForIdService: LastUsedPictureForIdService,
    private readonly lastUsedThingSectionForIdService: LastUsedThingSectionForIdService,
    private readonly permissionsService: PermissionsService,
    pictureMarkingService: PictureMarkingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.additionalMarkingsHandle = new AdditionalMarkingsHandle({
      subscriptionManagerService,
      entityManager,
      additionalMarkings: this.additionalMarkings,
      pictureMarkingService,
      onAdditionalMarkingsChanged: (additionalMarkings) => {
        this.additionalMarkings = additionalMarkings;

        setTimeout(() => {
          DomEventHelper.fireEvent<AdditionalMarkingsChangedEvent>(
            this.domElement,
            {
              name: 'additional-markings-changed',
              detail: {
                additionalMarkings
              }
            }
          );
        }, 0);
      },
      projectId: this.projectId
    });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.additionalMarkingsHandle.subscribe({
        markedPictureInfosBindingCallback: (markedPictureInfos) => {
          this.markedPictureInfos = markedPictureInfos;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected additionalMarkingsChanged(): void {
    this.additionalMarkingsHandle.additionalMarkingsBindableChanged(
      this.additionalMarkings
    );
  }

  protected projectIdChanged(): void {
    this.additionalMarkingsHandle.projectIdBindableChanged(this.projectId);
  }

  // //////// click/user interaction handling //////////

  protected handleSetMarkingClick(info: MarkedPictureInfo): void {
    if (this.enabled) {
      this.openMarkingOverlay({
        markings: info.markings,
        picture: info.picture,
        relatedPictures: [],
        otherMarkings: info.otherMarkings,
        isMarking: true
      });
    }
  }

  protected handleEditMarkingsClick(info: MarkedPictureInfo): void {
    this.openMarkingOverlay({
      markings: info.markings,
      picture: info.picture,
      relatedPictures: [],
      otherMarkings: info.otherMarkings,
      isMarking: false,
      editMarkings: true
    });
  }

  protected handleClearMarkingClick(info: MarkedPictureInfo): void {
    if (!this.enabled) {
      return;
    }

    this.additionalMarkingsHandle.removeMarkingsForPicture({
      picture: info.picture
    });
  }

  protected handleViewDetailClick(info: MarkedPictureInfo): void {
    this.openMarkingOverlay({
      markings: info.markings,
      picture: info.picture,
      relatedPictures: [],
      otherMarkings: info.otherMarkings,
      isMarking: false
    });
  }

  protected handleMobileClickAreaClicked(
    event: MouseEvent,
    info: MarkedPictureInfo
  ): void {
    assertNotNullOrUndefined(this.moreButton, 'more-button is not available');

    event.preventDefault();
    this.lastMoreButtonInfo = info;
    this.moreButton.targetElement = this.getMoreButtonTargetFromEvent(event);
    this.updateMoreButtonChoices();
    this.moreButton.open();
  }

  private getMoreButtonTargetFromEvent(event: MouseEvent): HTMLElement | null {
    if (!(event.currentTarget instanceof HTMLElement)) {
      throw new Error('invalid currentTarget');
    }

    if (
      event.currentTarget.parentNode != null &&
      !(event.currentTarget.parentNode instanceof HTMLElement)
    ) {
      throw new Error('invalid parentNode type');
    }

    return event.currentTarget.parentNode;
  }

  protected handleMoreButtonChoiceSelected(name: string): void {
    if (!this.lastMoreButtonInfo) return;

    switch (name) {
      case 'set-marking':
        this.handleSetMarkingClick(this.lastMoreButtonInfo);
        break;

      case 'clear-marking':
        this.handleClearMarkingClick(this.lastMoreButtonInfo);
        break;

      case 'detail':
      default:
        this.handleViewDetailClick(this.lastMoreButtonInfo);
        break;

      case 'edit-markings':
        this.handleEditMarkingsClick(this.lastMoreButtonInfo);
        break;
    }
  }

  private openMarkingOverlay({
    picture,
    relatedPictures,
    markings,
    otherMarkings,
    isMarking,
    editMarkings = false
  }: {
    picture: Picture;
    relatedPictures: Array<Picture>;
    markings?: Array<PictureMarking>;
    otherMarkings: Array<PictureAdditionalMarking>;
    isMarking: boolean;
    editMarkings?: boolean;
  }): void {
    assertNotNullOrUndefined(
      this.markingOverlay,
      'cannot open marking overlay if it does not exist'
    );

    this.markingOverlay.picture = picture;
    this.markingOverlay.relatedPictures = relatedPictures;

    this.markingOverlay.markings = otherMarkings;
    this.markingOverlay.currentMarkings = markings ?? [];

    this.markingOverlay.editMarkings = editMarkings;

    this.markingOverlay.open(isMarking);
  }

  protected handleMarkingSet(event: MarkingSetEvent): void {
    this.additionalMarkingsHandle.upsertMarking({
      marking: event.detail.parentPictureMarking
    });

    if (event.detail.originatedFromPictureMarking) {
      this.additionalMarkingsHandle.upsertMarking({
        marking: event.detail.originatedFromPictureMarking
      });
    }
  }

  private updateMoreButtonChoices(): void {
    if (this.enabled) {
      this.moreButtonChoices = [
        {
          labelTk: 'picture.additionalMarkedPicturesList.detailView',
          name: 'detail',
          iconClass: 'far fa-expand'
        },
        {
          labelTk: 'picture.additionalMarkedPicturesList.setMarking',
          name: 'set-marking',
          iconClass: 'far fa-crosshairs'
        },
        {
          labelTk: 'picture.additionalMarkedPicturesList.removeMarking',
          name: 'clear-marking',
          iconClass: 'far fa-circle'
        },
        {
          labelTk: 'picture.additionalMarkedPicturesList.editMarkings',
          name: 'edit-markings',
          iconClass: 'far fa-location-pen'
        }
      ];
    } else {
      this.moreButtonChoices = [
        {
          labelTk: 'picture.additionalMarkedPicturesList.detailView',
          name: 'detail',
          iconClass: 'far fa-expand'
        }
      ];
    }
  }

  // ///////////// add new Marking //////////////

  protected handleAddNewMarkedPictureClick(): void {
    if (!this.enabled) {
      return;
    }

    assertNotNullOrUndefined(
      this.projectId,
      'cannot add new marked picture without the projectId'
    );

    const project = this.entityManager.projectRepository.getById(
      this.projectId
    );
    assertNotNullOrUndefined(project, '');

    const adapter = new SelectPictureDialogEntryAdapter({
      project,
      entityManager: this.entityManager,
      lastUsedPictureForIdService: this.lastUsedPictureForIdService,
      lastUsedThingSectionForIdService: this.lastUsedThingSectionForIdService,
      subscriptionManagerService: this.subscriptionManagerService,
      permissionsService: this.permissionsService,
      filterPicture: (picture) => {
        // picture is already visible in the overview
        return !this.additionalMarkingsHandle.hasMarkingForPicture({ picture });
      }
    });

    void SelectPictureDialog.open({
      adapter,
      onPictureSelected: this.handleOnPictureSelected.bind(this)
    });
  }

  private handleOnPictureSelected({
    picture,
    relatedPictures
  }: {
    picture: Picture;
    relatedPictures: Array<Picture>;
  }): void {
    if (picture) {
      this.openMarkingOverlay({
        picture,
        relatedPictures,
        otherMarkings: this.additionalMarkingsHandle.getOtherMarkingsOfPicture({
          picture
        }),
        isMarking: true
      });
    }
  }
}

export type AdditionalMarkingsChangedEvent = NamedCustomEvent<
  'additional-markings-changed',
  { additionalMarkings: Array<PictureAdditionalMarking> }
>;
