// put all global imports into this file

import moment from 'moment';
// @ts-ignore
window.moment = moment;
import 'moment/locale/de-at.js';

import './vendor/jqueryImport';

import _ from 'lodash';
// @ts-ignore
window._ = _;

import 'bootstrap-notify';

// jquery-ui
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/position';
import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/slider';

import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/slider.css';

// import 'jquery-ui-touch-punch'; not used at the moment until pull request (#290) is through, see below
import '../libs/jquery.ui.touch-punch.js';
// @ts-ignore
window.touchPunchDelay = 350;

import '../libs/webrtc.adapter.js';
import 'jquery-touchswipe';
import '../libs/velocity.min.js';

import * as Waves from 'node-waves';
// @ts-ignore
window.Waves = Waves;
// @ts-ignore
window.Waves.init();

import 'bootstrap-notify';
import 'leaflet/dist/leaflet-src';
import 'leaflet/dist/leaflet.css';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.bundled';

import '../libs/msvg/msvg';

import '../libs/fontawesome6pro/css/fontawesome.min.css';
import '../libs/fontawesome6pro/css/regular.min.css';
import '../libs/fontawesome6pro/css/solid.min.css';
import '../libs/fontawesome6pro/css/light.min.css';

import 'socket.io-parser';

import 'core-js/features/array/at';
