import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskChecklistEntryDto } from './ProcessTaskChecklistEntryDto';

export function createProcessTaskChecklistEntryEntityInfo<
  TEntity extends ProcessTaskChecklistEntryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskChecklistEntryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskChecklistEntry,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskChecklistEntry,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskChecklistEntryDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          ref: EntityName.User
        },
        text: {
          name: 'text',
          defaultValue: null
        },
        done: {
          name: 'done',
          defaultValue: false
        },
        createdDate: {
          name: 'createdDate'
        }
      },
      options.additionalFieldInfos
    )
  };
}
