export class DisposableContainer {
  private disposables: Array<Disposable> = [];

  public add(disposable: Disposable): void {
    this.disposables.push(disposable);
  }

  public disposeAll(): void {
    for (const disposable of this.disposables) {
      disposable.dispose();
    }

    this.disposables = [];
  }

  public toDisposable(): Disposable {
    return {
      dispose: () => {
        this.disposeAll();
      }
    };
  }
}

export type Disposable = {
  dispose: () => void;
};
