import { autoinject } from 'aurelia-framework';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EditProcessTaskMeasurePointReadingTable } from '../edit-process-task-measure-point-reading-table/edit-process-task-measure-point-reading-table';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@autoinject()
export class EditProcessTaskMeasurePointReadingDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly permissionBindingHandle: PermissionBindingHandle;
  private dialog: RecordItDialog | null = null;

  private measurePoint: ProcessTaskMeasurePoint | null = null;
  private measurePointReading: ProcessTaskMeasurePointReading | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private enabled: boolean = false;
  private readingTable: EditProcessTaskMeasurePointReadingTable | null = null;

  private onDialogClosed: OnDialogClosed | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionBindingService: PermissionBindingService
  ) {
    this.entityManager = entityManager;

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'measurePoint',
        userGroupPropertyOfEntity: 'ownerUserGroupId',
        editableProperty: 'enabled'
      }
    });
  }

  public open(options: OpenOptions): void {
    this.measurePoint = options.measurePoint;
    this.measurePointReading = options.measurePointReading;
    this.processTaskGroup = options.processTaskGroup;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.permissionBindingHandle.subscribe();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;

    this.permissionBindingHandle.unsubscribe();
    this.measurePoint = null;
    this.measurePointReading = null;
    this.onDialogClosed = null;

    onDialogClosed && onDialogClosed();
  }

  private handleMeasurePointChanged(): void {
    if (this.measurePoint) {
      this.entityManager.processTaskMeasurePointRepository.update(
        this.measurePoint
      );
    }
  }

  private handleRecordItDialogOpened(): void {
    this.readingTable?.focus();
  }
}

export type OpenOptions = {
  processTaskGroup: ProcessTaskGroup;
  measurePoint: ProcessTaskMeasurePoint;
  measurePointReading: ProcessTaskMeasurePointReading;
  onDialogClosed?: OnDialogClosed | null;
};

type OnDialogClosed = () => void;
