export enum ProcessTaskGroupFilterMode {
  ALL = 'all',
  NEXT_DAYS = 'nextDays',
  DATE_RANGE = 'dateRange',
  NO_FUTURE_APPOINTMENTS = 'noFutureAppointments'
}

export const allProcessTaskGroupFilterModes =
  (): Array<ProcessTaskGroupFilterMode> =>
    Object.values(ProcessTaskGroupFilterMode);
