import { autoinject } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';

@autoinject()
export class TimespanDataConverter extends AbstractLogDataConverter {
  public convert({
    logData,
    args: { startDatePath, endDatePath }
  }: ConvertOptions<'startDatePath' | 'endDatePath'>): void {
    const startDate = this.getDateFromUnknownValue(logData[startDatePath]);
    const endDate = this.getDateFromUnknownValue(logData[endDatePath]);
    if (startDate && endDate) {
      const rootKey = `durationFrom${startDatePath}To${endDatePath}`;
      logData[`${rootKey}humanReadable`] =
        DateUtils.getHumanReadableDurationBetween(startDate, endDate);
    }
  }

  private getDateFromUnknownValue(value: unknown): Date | null {
    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string') {
      return new Date(value);
    }
    return null;
  }
}
