import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression } from '../../hooks/dependencies';

@autoinject()
export class GalleryThingPlanBasedOverviewMarker {
  @bindable
  public pictureOverviewEntry: GalleryThingPictureOverviewEntry | null = null;

  @bindable
  public planPicture: Picture | null = null;

  /**
   * any css color
   */
  @bindable
  public color: string = '#ff401f';

  @computedFrom('color')
  protected get markerOutlineStyle(): string {
    return `fill:${this.color};fill-opacity:1;stroke:#ffffff;stroke-opacity:1;stroke-width:2;stroke-miterlimit:4;stroke-dasharray:none`;
  }

  @computed(
    expression('pictureOverviewEntry.coordsFromPositionedPictureInfo'),
    arrayChanges('pictureOverviewEntry.additional_markings')
  )
  protected get position(): Position | null {
    if (!this.planPicture) return null;
    const additionalMarking =
      this.pictureOverviewEntry?.additional_markings.find(
        (e) => e.picture_id === this.planPicture?.id
      );

    if (additionalMarking) {
      return { top: additionalMarking.top, left: additionalMarking.left };
    }
    const coordsFromPositionedPictureInfo =
      this.pictureOverviewEntry?.coordsFromPositionedPictureInfo;
    if (
      coordsFromPositionedPictureInfo?.pictureId === this.planPicture?.id &&
      coordsFromPositionedPictureInfo.top &&
      coordsFromPositionedPictureInfo.left
    ) {
      return {
        top: coordsFromPositionedPictureInfo.top,
        left: coordsFromPositionedPictureInfo.left
      };
    }

    return null;
  }
}

type Position = {
  top: string;
  left: string;
};
