import { bindable, PLATFORM } from 'aurelia-framework';

import {
  ReasonType,
  SearchResultReason as CommonSearchResultReason
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';

export class SearchResultReason {
  @bindable public reason: CommonSearchResultReason | null = null;

  private templates: Record<ReasonType, string> = {
    [ReasonType.EntityKey]: PLATFORM.moduleName(
      './reasonTemplates/entityKey.html'
    ),
    [ReasonType.Property]: PLATFORM.moduleName(
      './reasonTemplates/property.html'
    ),
    [ReasonType.Picture]: PLATFORM.moduleName('./reasonTemplates/picture.html'),
    [ReasonType.Tag]: PLATFORM.moduleName('./reasonTemplates/tag.html'),
    [ReasonType.NfcTag]: PLATFORM.moduleName('./reasonTemplates/nfcTag.html')
  };

  protected getReasonTypeContentTemplate(reasonType: ReasonType): string {
    return this.templates[reasonType];
  }
}
