import { autoinject, bindable } from 'aurelia-framework';
import { Vector } from 'common/Geometry/Vector';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * @event {ValueChangedEvent} value-changed
 * @event {ValueAcceptedEvent} value-accepted
 */
@autoinject()
export class TimeWheel {
  private static OUTER_RADIUS: number = 40;
  private static INNER_RADIUS: number = 27;

  @bindable()
  public value: number = 0;

  @bindable()
  public configuration: TimeWheelConfiguration | null = null;

  private ticks: Array<Tick> = [];
  private selectedTick: Tick | null = null;
  private lastTouchmoveTick: Tick | null = null;

  constructor(private readonly element: Element) {}

  private configurationChanged(): void {
    if (this.configuration) {
      this.ticks = [
        ...this.calculateTicks(
          this.configuration.innerTicks,
          TimeWheel.INNER_RADIUS
        ),
        ...this.calculateTicks(
          this.configuration.outerTicks,
          TimeWheel.OUTER_RADIUS
        )
      ];
    } else {
      this.ticks = [];
    }

    this.updateSelectedTick();
  }

  private valueChanged(): void {
    this.updateSelectedTick();
  }

  private updateSelectedTick(): void {
    this.selectedTick = this.ticks.find((t) => t.value === this.value) ?? null;
  }

  private calculateTicks(
    tickConfigurations: Array<TickConfiguration>,
    radius: number
  ): Array<Tick> {
    const centerVector = Vector.createHtmlVector(50, 50);
    const anglePerIndex = 360 / tickConfigurations.length;

    return tickConfigurations.map<Tick>((tickConfiguration, index) => {
      const position = Vector.createHtmlVector(0, -50 * ((radius * 2) / 100))
        .rotate(anglePerIndex * index * -1)
        .addVector(centerVector);

      return {
        value: tickConfiguration.value,
        x: position.getX(),
        y: position.getY(),
        secondary: tickConfiguration.secondary === true,
        visible: tickConfiguration.visible !== false
      };
    });
  }

  private handleClick(event: MouseEvent): void {
    const percentualPosition = this.getPercentualPosition(
      event.clientX,
      event.clientY
    );
    const tick = this.findClosestTick(percentualPosition, 12);
    if (tick) {
      this.selectedTick = tick;
      this.acceptTick(this.selectedTick);
    }
  }

  private handleMousemove(event: MouseEvent): void {
    // left mouse button
    if (event.buttons === 1) {
      const percentualPosition = this.getPercentualPosition(
        event.clientX,
        event.clientY
      );
      const tick = this.findClosestTick(percentualPosition, 12);
      if (tick) {
        this.selectedTick = tick;
        this.applyTickValue(tick);
      }
    }
  }

  private handleTouchmove(event: TouchEvent): boolean {
    const touch = event.touches[0];
    if (!touch || event.touches.length !== 1) {
      return true;
    }

    const percentualPosition = this.getPercentualPosition(
      touch.clientX,
      touch.clientY
    );
    this.lastTouchmoveTick = this.findClosestTick(percentualPosition, 12);
    if (this.lastTouchmoveTick) {
      this.selectedTick = this.lastTouchmoveTick;
      this.applyTickValue(this.lastTouchmoveTick);
    }

    return false;
  }

  private handleTouchend(event: TouchEvent): void {
    if (event.touches.length !== 0) {
      return;
    }

    if (this.lastTouchmoveTick) {
      this.acceptTick(this.lastTouchmoveTick);
    }
  }

  private getPercentualPosition(clientX: number, clientY: number): Vector {
    const boundingRect = this.element.getBoundingClientRect();
    const topLeftPosition = Vector.createHtmlVector(
      boundingRect.left,
      boundingRect.top
    );
    const mousePosition = Vector.createHtmlVector(clientX, clientY);

    return mousePosition
      .clone()
      .substractVector(topLeftPosition)
      .divideXY(this.element.clientWidth, this.element.clientHeight)
      .scale(100);
  }

  private findClosestTick(position: Vector, threshold: number): Tick | null {
    let closestTick: Tick | null = null;
    let closestDistance: number | null = null;

    for (const tick of this.ticks) {
      const diffVector = position.clone().substractXY(tick.x, tick.y);
      const distance = diffVector.getLength();

      if (
        distance < threshold &&
        (closestDistance == null || distance < closestDistance)
      ) {
        closestTick = tick;
        closestDistance = distance;
      }
    }

    return closestTick;
  }

  private applyTickValue(tick: Tick): void {
    this.value = tick.value;

    setTimeout(() => {
      DomEventHelper.fireEvent<ValueChangedEvent>(this.element, {
        name: 'value-changed',
        detail: null
      });
    });
  }

  private acceptTick(tick: Tick): void {
    this.value = tick.value;

    setTimeout(() => {
      DomEventHelper.fireEvent<ValueAcceptedEvent>(this.element, {
        name: 'value-accepted',
        detail: null
      });
    });
  }

  private getConnectorStyle(x: number, y: number): Record<string, string> {
    const vector = Vector.createHtmlVector(x, y).substractXY(50, 50);

    return {
      width: vector.getLength() + '%',
      transform: `rotate(${
        (vector.getAngle() || 0) * -1
      }deg) translate(0, -50%)`
    };
  }

  private formatTickValue(value: number): string {
    if (value === 0) {
      return '00';
    }

    return value.toString();
  }

  private getVisibleTicks(ticks: Array<Tick>): Array<Tick> {
    return ticks.filter((t) => t.visible);
  }
}

type Tick = {
  value: number;
  /**
   * in % from left
   */
  x: number;
  /**
   * in % from top
   */
  y: number;
  secondary: boolean;
  visible: boolean;
};

export type TimeWheelConfiguration = {
  outerTicks: Array<TickConfiguration>;
  innerTicks: Array<TickConfiguration>;
};

export type TickConfiguration = {
  value: number;
  /**
   * default false
   */
  secondary?: boolean;
  /**
   * default true
   */
  visible?: boolean;
};

export type ValueChangedEvent = NamedCustomEvent<'value-changed', null>;
export type ValueAcceptedEvent = NamedCustomEvent<'value-accepted', null>;
