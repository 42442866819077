import { autoinject } from 'aurelia-framework';
import { ProcessConfigurationProcessTaskPropertiesConfiguration } from 'common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTask, ProcessTaskCreationEntity } from './types';

@autoinject()
export class ProcessTaskCreationService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createProcessTask(
    creationEntity: ProcessTaskCreationEntity
  ): ProcessTask {
    const processTask =
      this.entityManager.processTaskRepository.create(creationEntity);

    const processTaskGroup = processTask
      ? this.entityManager.processTaskGroupRepository.getById(
          processTask.ownerProcessTaskGroupId
        )
      : null;
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const config: ProcessConfigurationProcessTaskPropertiesConfiguration | null =
      processConfiguration?.processTaskPropertiesConfigurationJson
        ? JSON.parse(
            processConfiguration.processTaskPropertiesConfigurationJson
          )
        : null;
    const propertyConfigs = config ? config.defaultProperties : [];

    for (const [order, propertyConfig] of propertyConfigs.entries()) {
      this.entityManager.propertyRepository.create({
        ...propertyConfig,
        order,
        ownerUserGroupId: processTask.ownerUserGroupId,
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: processTask.id,
        processTaskId: processTask.id
      });
    }

    return processTask;
  }
}
