export enum PhoneSystemSocketMessageType {
  CALL_ADDED = 'call_added',
  AUTH_FAIL = 'auth_fail',
  AUTH_SUCCESS = 'auth_success',
  REGISTRATION_SUCCESS = 'registration_success'
}

type TypeToSocketMessageData = {
  [PhoneSystemSocketMessageType.CALL_ADDED]: CallAddedData;
  [PhoneSystemSocketMessageType.AUTH_FAIL]: null;
  [PhoneSystemSocketMessageType.AUTH_SUCCESS]: null;
  [PhoneSystemSocketMessageType.REGISTRATION_SUCCESS]: boolean;
};

export type TypeToPhoneSystemSocketMessage = {
  [type in PhoneSystemSocketMessageType]: {
    type: type;
    data: TypeToSocketMessageData[type];
  };
};

export type PhoneSystemSocketMessage =
  TypeToPhoneSystemSocketMessage[PhoneSystemSocketMessageType];

export type ProcessTaskInfo = {
  processTaskId: string;
  processTaskName: string;
  processTaskSequenceNumber: number;
  processTaskGroupSequenceNumber: number;
  thingId: string;
  thingGroupId: string;
  dateFrom: string | null;
  dateTo: string | null;
};

export type Call = {
  calleeNumber: string;
  callerNumber: string;
  timestamp: number;
};

export type CallAddedData = {
  call: Call;
  processTaskInfos: Array<ProcessTaskInfo>;
};
