import { observable, autoinject } from 'aurelia-framework';

import { FloatingLabelInput } from '../../inputComponents/floating-label-input/floating-label-input';
import { BiometricAuthenticationService } from '../../services/BiometricAuthenticationService/BiometricAuthenticationService';
import { I18N } from 'aurelia-i18n';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

@autoinject()
export class SaveBiometricCredentialsPanel {
  @observable protected email = '';
  @observable protected password = '';

  protected saveCredentialsPanelVisible = false;
  protected showPassword = false;

  protected emailFloatingLabelInputViewModel: FloatingLabelInput | null = null;

  constructor(
    private readonly biometricAuthService: BiometricAuthenticationService,
    private readonly i18n: I18N
  ) {}

  protected showSaveCredentialsPanel(): void {
    this.saveCredentialsPanelVisible = true;
    setTimeout(() => {
      this.focusEmailField();
    }, 0);
  }

  protected closeSaveCredentialsPanel(): void {
    this.saveCredentialsPanelVisible = false;
  }

  protected handleCancelButtonClick(): void {
    this.resetFields();
    this.closeSaveCredentialsPanel();
  }

  protected handleToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword;
  }

  protected async handleSaveCredentialsClick(): Promise<void> {
    const result = await this.biometricAuthService.verifyIdentity(
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.confirm'),
      this.i18n.tr('aureliaComponents.saveBiometricCredentialsPanel.reason')
    );

    if (result.success) void this.setBiometricCredentials();
  }

  protected handleDeleteCredentialsClick(): void {
    void this.biometricAuthService.deleteCredentials();
  }

  @computed(expression('email'), expression('password'))
  protected get credentialsAreValid(): boolean {
    return this.email !== '' && this.password !== '';
  }

  @computed(expression('biometricAuthService.userCredentialsSavedOnDevice'))
  protected get userCredentialsSavedOnDevice(): boolean {
    return this.biometricAuthService.getUserCredentialsSavedOnDevice();
  }

  private async setBiometricCredentials(): Promise<void> {
    await this.biometricAuthService.setCredentials(this.email, this.password);

    this.resetFields();
    this.closeSaveCredentialsPanel();
  }

  private resetFields(): void {
    this.email = '';
    this.password = '';
  }

  private focusEmailField(): void {
    if (this.emailFloatingLabelInputViewModel)
      this.emailFloatingLabelInputViewModel.focus();
  }
}
