export class Key {
  static ENTER = 'Enter';
  static SPACE = ' ';
  static ESCAPE = 'Escape';
  static TAB = 'Tab';
  static ARROW_UP = 'ArrowUp';
  static ARROW_DOWN = 'ArrowDown';
  static HOME = 'Home';
  static END = 'End';
  static SHIFT = 'Shift';
}
