export class EntityListItemHelper {
  /**
   * element with the `record-it-entity-list-item` class
   * @param {HTMLElement} element
   */
  static highlightListItemElement(element) {
    element.classList.add('record-it-entity-list-item_highlighted');
    setTimeout(() => {
      element.classList.remove('record-it-entity-list-item_highlighted');
    }, 500);
  }
}
