import { PLATFORM } from 'aurelia-framework';
import { RouterConfiguration } from 'aurelia-router';

import { MainPageLoaderHelper } from '../../classes/MainPageLoaderHelper';

export class App {
  protected attached(): void {
    MainPageLoaderHelper.hide();
  }

  protected configureRouter(config: RouterConfiguration): void {
    config.title = 'recordIT';
    config.map([
      {
        route: ['', 'auth'],
        name: 'auth',
        moduleId: PLATFORM.moduleName('pages/auth/auth'),
        nav: true,
        title: 'Anmelden'
      },
      {
        route: 'reset_password/:token?',
        name: 'reset_password',
        moduleId: PLATFORM.moduleName('pages/reset_password/reset_password'),
        href: '#reset_password',
        nav: true,
        title: 'Passwort zurücksetzen'
      },
      {
        route: 'confirmation_link/:token?',
        name: 'confirmation_link',
        moduleId: PLATFORM.moduleName(
          'pages/confirmation_link/confirmation_link'
        ),
        href: '#confirmation_link',
        nav: true,
        title: 'Bestätigungslink'
      },
      {
        route: 'finish_invite/:token',
        name: 'finish_invite',
        moduleId: PLATFORM.moduleName('pages/finish_invite/finish_invite'),
        href: '#finish_invite',
        title: 'Einladung abschließen',
        nav: true
      }
    ]);
    config.mapUnknownRoutes(PLATFORM.moduleName('pages/auth/auth'));
  }
}
