import { bindable } from 'aurelia-framework';

export class ProcessTaskDeviceStatusIcon {
  static DEVICE_STATUS_READY = 'ready';
  static DEVICE_STATUS_DEPLOYED = 'deployed';
  static DEVICE_STATUS_FINISHED = 'finished';

  static TRANSITION_DIRECTION_PREVIOUS = 'previous';
  static TRANSITION_DIRECTION_UNSET = 'unset';
  static TRANSITION_DIRECTION_NEXT = 'next';

  /**
   * @type {string}
   */
  @bindable deviceStatus = ProcessTaskDeviceStatusIcon.DEVICE_STATUS_READY;

  /**
   * direction/state to transition to when clicking the icon
   *
   * @type {string}
   */
  @bindable transitionDirection =
    ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_UNSET;

  constructor() {
    this._ProcessTaskDeviceStatusIcon = ProcessTaskDeviceStatusIcon;
  }

  /**
   * @param {string} transitionDirection
   * @returns {string}
   */
  _getIconNameForTransitionDirection(transitionDirection) {
    switch (transitionDirection) {
      case ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_PREVIOUS:
        return 'rotate-left';
      case ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_NEXT:
        return 'rotate-right';
      case ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_UNSET:
        return '';
      default:
        throw new Error(
          `no icon for transitions direction "${transitionDirection}"`
        );
    }
  }
}
