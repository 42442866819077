import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskInvoiceEntityInfo } from 'common/Types/Entities/ProcessTaskInvoice/createProcessTaskInvoiceEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskInvoice } from './types';

export const processTaskInvoiceEntityInfo =
  createProcessTaskInvoiceEntityInfo<ProcessTaskInvoice>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
