import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { RegionDto } from './RegionDto';

export function createRegionEntityInfo<
  TEntity extends RegionDto<string, string>
>(
  options: CreateEntityInfoOptions<RegionDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Region,
  TEntity
> {
  return {
    entityName: EntityName.Region,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      RegionDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        customId: {
          name: 'customId',
          defaultValue: null
        },
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
