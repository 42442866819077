/**
 * A group of icons.
 *
 * `icon-section-group` elements are horizontally visually seperated.
 *
 * @slot a slot for `icon-section-group` elements.
 *
 * @example
 * ```html
 * <icon-section>
 *   <icon-section-group>
 *     <custom-icon id="add-icon" class="icon-section--Icon"></custom-icon>
 *   </icon-section-group>
 *   <icon-section-group>
 *     <custom-icon id="edit-icon" class="icon-section--Icon"></custom-icon>
 *     <custom-icon id="delete-icon" class="icon-section--Icon"></custom-icon>
 *   </div>
 * </icon-section-group>
 * ```
 */
export class IconSection {}
