import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  GlobalUserDefinedEntity,
  ProjectUserDefinedEntity,
  ThingUserDefinedEntity
} from './types';

export class UserDefinedEntityRepository extends AppEntityRepository<EntityName.UserDefinedEntity> {
  public getGlobalEntities(): Array<GlobalUserDefinedEntity> {
    return this.getAll().filter(
      (userDefinedEntity): userDefinedEntity is GlobalUserDefinedEntity => {
        return !!userDefinedEntity.isGlobal;
      }
    );
  }

  public getGlobalByOwnerUserGroupId(
    usergroupId: string
  ): Array<GlobalUserDefinedEntity> {
    return this.getAll().filter(
      (userDefinedEntity): userDefinedEntity is GlobalUserDefinedEntity => {
        return (
          !!userDefinedEntity.isGlobal &&
          userDefinedEntity.ownerUserGroupId === usergroupId
        );
      }
    );
  }

  public getByThingId(thingId: string): Array<ThingUserDefinedEntity> {
    return this.getAll().filter(
      (userDefinedEntity): userDefinedEntity is ThingUserDefinedEntity => {
        return userDefinedEntity.thingId === thingId;
      }
    );
  }

  public getByProjectId(projectId: string): Array<ProjectUserDefinedEntity> {
    return this.getAll().filter(
      (userDefinedEntity): userDefinedEntity is ProjectUserDefinedEntity => {
        return userDefinedEntity.ownerProjectId === projectId;
      }
    );
  }
}
