import { autoinject, bindable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { FormProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';

@autoinject()
export class ProcessTaskFormActions {
  @bindable public processTaskToProject: FormProcessTaskToProject | null = null;

  @bindable public disableDeleteMoreButtonChoice = false;

  protected readonly choices: Array<MoreButtonChoice> = [
    {
      name: 'delete',
      iconClass: 'fal fa-trash-alt',
      labelTk: 'general.delete',
      disabledContext: this,
      disabledPropertyName: 'disableDeleteMoreButtonChoice'
    }
  ];

  constructor(private readonly entityManager: AppEntityManager) {}

  protected handleDeleteClicked(): void {
    const relation = this.processTaskToProject;
    if (!relation) {
      return;
    }

    void Dialogs.deleteEntityDialog(relation).then(() => {
      this.entityManager.processTaskToProjectRepository.delete(relation);
      const project = this.entityManager.projectRepository.getById(
        relation.projectId
      );
      if (project) {
        this.entityManager.projectRepository.delete(project);
      }
    });
  }
}
