import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { NfcTokenToThingDto } from './NfcTokenToThingDto';

export function createNfcTokenToThingEntityInfo<
  TEntity extends NfcTokenToThingDto<string, string>
>(
  options: CreateEntityInfoOptions<NfcTokenToThingDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.NfcTokenToThing,
  TEntity
> {
  return {
    entityName: EntityName.NfcTokenToThing,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      NfcTokenToThingDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        nfcTokenId: {
          name: 'nfcTokenId',
          cascadeDeleteLocally: true,
          ref: EntityName.NfcToken
        },
        thingId: {
          name: 'thingId',
          cascadeDeleteLocally: true,
          ref: EntityName.Thing
        }
      },
      options.additionalFieldInfos
    )
  };
}
