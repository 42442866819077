import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingSection } from '../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ThingSectionSelect {
  @bindable()
  public value: string | null = null;

  @bindable()
  public selectedThingSection: ThingSection | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public thingId: string | null = null;

  @bindable()
  public label: string | null = null;

  protected readonly subscriptionManager: SubscriptionManager;
  protected selectableThingSections: Array<ThingSection> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSection,
      this.updateSelectableThingSections.bind(this)
    );
    this.updateSelectableThingSections();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingIdChanged(): void {
    if (this.isAttached) {
      this.updateSelectableThingSections();
    }
  }

  private updateSelectableThingSections(): void {
    if (this.thingId) {
      this.selectableThingSections =
        this.entityManager.thingSectionRepository.getOrderedByThingId(
          this.thingId
        );
    } else {
      this.selectableThingSections = [];
    }
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<string, ThingSection>
  ): void {
    void DomEventHelper.fireEvent<ValueChangedEvent>(this.element, {
      name: 'value-changed',
      detail: {
        value: event.detail.value,
        selectedThingSection:
          typeof event.detail.selectedOption !== 'string'
            ? event.detail.selectedOption
            : null
      }
    });
  }
}

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null; selectedThingSection: ThingSection | null }
>;
