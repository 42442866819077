import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingAuthorizationDto } from './ThingAuthorizationDto';

export function createThingAuthorizationEntityInfo<
  TEntity extends ThingAuthorizationDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ThingAuthorizationDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingAuthorization,
  TEntity
> {
  return {
    entityName: EntityName.ThingAuthorization,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingAuthorizationDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        },
        userId: {
          name: 'userId',
          ref: EntityName.User,
          cascadeDeleteLocally: true
        },
        systemGenerated: {
          name: 'systemGenerated',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
