import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event edit-button-clicked
 */
@autoinject()
export class ProcessConfigurationDeviceExportListItem {
  @bindable()
  public processConfigurationDeviceExport: ProcessConfigurationDeviceExport | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationDeviceExportPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationDeviceExport];

  private listItemElement: HTMLElement | null = null;
  private panelOpen: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationDeviceExportPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationDeviceExport,
        context: this as ProcessConfigurationDeviceExportListItem,
        propertyName: 'processConfigurationDeviceExport'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEditClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected async handleDeleteClick(): Promise<void> {
    const config = this.processConfigurationDeviceExport;
    assertNotNullOrUndefined(
      config,
      "can't ProcessConfigurationDeviceExportListItem.handleDeleteClick without a processConfigurationDeviceExport"
    );

    await Dialogs.deleteEntityDialog(config, config.name ?? '');
    this.entityManager.processConfigurationDeviceExportRepository.delete(
      config
    );
  }
}
