import { bindable, autoinject } from 'aurelia-framework';

import { PropertyHelper as CommonPropertyHelper } from 'common/EntityHelper/PropertyHelper';
import {
  IProcessConfigurationPositionPropertiesConfiguration,
  TPositionDefaultPropertyConfig
} from 'common/Types/ProcessConfigurationPositionPropertiesConfiguration';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId
} from '../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import {
  PropertiesByProcessTaskPositionDetailEntryIdComputer,
  PropertiesByProcessTaskPositionDetailEntryId
} from '../../computedValues/computers/PropertiesByProcessTaskPositionDetailEntryIdComputer';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionUtils } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionProperty } from '../../classes/EntityManager/entities/Property/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';

@autoinject()
export class ProcessTaskAppointmentPositionInfo {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public position: ProcessTaskPosition | null = null;

  private subscriptionManager: SubscriptionManager;
  private properties: Array<ProcessTaskPositionProperty> = [];
  private detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId =
    new Map();
  private propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId =
    new Map();
  private isAttached = false;

  private CommonPropertyHelper = CommonPropertyHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.position
            ? { ownerProcessTaskId: this.position.ownerProcessTaskId }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'position.ownerProcessTaskId',
            updateSubscription
          );
        },
        callback: (detailEntriesByPositionId) => {
          this.detailEntriesByPositionId = detailEntriesByPositionId;
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          PropertiesByProcessTaskPositionDetailEntryIdComputer,
        createComputeData: () =>
          this.position
            ? { ownerProcessTaskId: this.position.ownerProcessTaskId }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'position.ownerProcessTaskId',
            updateSubscription
          );
        },
        callback: (propertiesByDetailEntryId) => {
          this.propertiesByDetailEntryId = propertiesByDetailEntryId;
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private positionChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private updateProperties(): void {
    if (this.position) {
      const configs = this.getDefaultPropertyConfigs();
      const propertyNames = configs
        .filter((c) => c.showInAdditionalInfo)
        .map((c) => c.name);
      const properties =
        this.entityManager.propertyRepository.getByProcessTaskPositionId(
          this.position.id
        );
      this.properties = properties.filter(
        (p) => p.value && propertyNames.indexOf(p.name || '') >= 0
      );
    } else {
      this.properties = [];
    }
  }

  private getDefaultPropertyConfigs(): Array<TPositionDefaultPropertyConfig> {
    const configuration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;
    const propertiesConfigJson =
      configuration && configuration.positionPropertiesConfigurationJson
        ? configuration.positionPropertiesConfigurationJson
        : null;
    const propertiesConfig: IProcessConfigurationPositionPropertiesConfiguration | null =
      propertiesConfigJson ? JSON.parse(propertiesConfigJson) : null;
    return propertiesConfig ? propertiesConfig.properties : [];
  }

  private getAmount(
    positionId: string,
    amount: number,
    unit: string | null,
    detailEnabled: boolean,
    detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
    propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId
  ): string {
    return ProcessTaskPositionUtils.getAmountString(
      positionId,
      amount.toString(),
      unit,
      detailEnabled,
      detailEntriesByPositionId,
      propertiesByDetailEntryId
    );
  }

  private getPropertySeparator(
    index: number,
    propertiesLength: number
  ): string {
    return index < propertiesLength - 1 ? '—' : '';
  }
}
