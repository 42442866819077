import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { createPictureRevisionEntityInfo } from 'common/Types/Entities/PictureRevision/createPictureRevisionEntityInfo';
import { PictureRevision } from './types';

export const pictureRevisionEntityInfo =
  createPictureRevisionEntityInfo<PictureRevision>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
