import { bindable, inject } from 'aurelia-framework';

import { ProcessTaskDeviceStatusIcon } from '../process-task-device-status-icon/process-task-device-status-icon';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PermissionBindingService } from '../../services/PermissionBindingService';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-button-clicked
 */
@inject(
  Element,
  AppEntityManager,
  SubscriptionManagerService,
  PermissionBindingService,
  PermissionsService
)
export class ProcessTaskAppointmentDevicesListItem {
  static DEVICE_STATUS_CHANGE_UNDO_TIME = 2 * 60 * 60 * 1000;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment|null} */
  @bindable appointment = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskDevice/types').ProcessTaskDevice|null} */
  @bindable device = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {import('../../services/PermissionsService/entityNameToPermissionsConfig').EntityNameToPermissionsHandle[EntityName.ProcessTaskDevice]} */
  @subscribableLifecycle()
  _permissionHandle;

  /** @type {import('../../classes/EntityManager/entities/ProcessConfigurationDevice/types').ProcessConfigurationDevice|null} */
  _deviceConfig = null;

  /** @type {import('../../classes/EntityManager/entities/User/types').User|null} */
  _currentUser = null;
  /** @type {import('../../classes/EntityManager/entities/User/types').User|null} */
  _dateFromUser = null;
  /** @type {import('../../classes/EntityManager/entities/User/types').User|null} */
  _dateToUser = null;

  _deviceStatus = ProcessTaskDeviceStatusIcon.DEVICE_STATUS_READY;
  _deviceTransitionDirection =
    ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_UNSET;
  /** @type {boolean} */
  _panelOpened = false;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {HTMLElement|null} */
  _listItemElement = null;

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {PermissionBindingService} permissionBindingService
   * @param {PermissionsService} permissionsService
   */
  constructor(
    element,
    entityManager,
    subscriptionManagerService,
    permissionBindingService,
    permissionsService
  ) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._permissionBindingHandle = permissionBindingService.create({
      context: this,
      currentUserPropertyName: '_currentUser'
    });
    this._permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskDevice,
        context: this,
        expression: 'device'
      });
  }

  highlight() {
    if (this._listItemElement) {
      EntityListItemHelper.highlightListItemElement(this._listItemElement);
    }
  }

  deviceChanged() {
    this._updateData();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDevice,
      this._updateDeviceConfig.bind(this)
    );
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      this._updateData.bind(this)
    );
    this._updateData();
    this._permissionBindingHandle.subscribe();

    this._subscriptionManager.subscribeToInterval(
      this._updateDeviceTransitionDirection.bind(this),
      60000
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
    this._permissionBindingHandle.unsubscribe();
  }

  _updateData() {
    if (!this.device) {
      this._dateToUser = null;
      this._dateFromUser = null;
      this._deviceConfig = null;
      return;
    }

    this._updateDeviceConfig();

    this._dateFromUser = this.device.dateFromUserId
      ? this._entityManager.userRepository.getById(this.device.dateFromUserId)
      : null;
    this._dateToUser = this.device.dateToUserId
      ? this._entityManager.userRepository.getById(this.device.dateToUserId)
      : null;

    this._updateDeviceStatus();
  }

  _updateDeviceStatus() {
    if (!this.device) {
      return;
    }

    if (!this.device.dateFrom) {
      this._deviceStatus = ProcessTaskDeviceStatusIcon.DEVICE_STATUS_READY;
    } else if (!this.device.dateTo) {
      this._deviceStatus = ProcessTaskDeviceStatusIcon.DEVICE_STATUS_DEPLOYED;
    } else {
      this._deviceStatus = ProcessTaskDeviceStatusIcon.DEVICE_STATUS_FINISHED;
    }

    this._updateDeviceTransitionDirection();
  }

  _updateDeviceTransitionDirection() {
    if (!this.device) {
      return;
    }

    if (
      this._deviceStatus === ProcessTaskDeviceStatusIcon.DEVICE_STATUS_READY
    ) {
      this._deviceTransitionDirection =
        ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_NEXT;
    } else if (
      this._deviceStatus ===
        ProcessTaskDeviceStatusIcon.DEVICE_STATUS_DEPLOYED &&
      this.device.dateFrom
    ) {
      if (this._isInUndoTimeRange(this.device.dateFrom)) {
        this._deviceTransitionDirection =
          ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_PREVIOUS;
      } else {
        this._deviceTransitionDirection =
          ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_NEXT;
      }
    } else if (
      this._deviceStatus ===
        ProcessTaskDeviceStatusIcon.DEVICE_STATUS_FINISHED &&
      this.device.dateTo
    ) {
      if (this._isInUndoTimeRange(this.device.dateTo)) {
        this._deviceTransitionDirection =
          ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_PREVIOUS;
      } else {
        this._deviceTransitionDirection =
          ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_UNSET;
      }
    }
  }

  _updateDeviceConfig() {
    if (!this.device) {
      this._deviceConfig = null;
      return;
    }
    this._deviceConfig =
      this._entityManager.processConfigurationDeviceRepository.getById(
        this.device.processConfigurationDeviceId
      );
  }

  async _handleDeviceStatusClick() {
    if (!this.device) return;

    if (
      this._deviceStatus === ProcessTaskDeviceStatusIcon.DEVICE_STATUS_READY
    ) {
      this._deployDevice();
    } else if (
      this._deviceStatus ===
        ProcessTaskDeviceStatusIcon.DEVICE_STATUS_DEPLOYED &&
      this.device.dateFrom
    ) {
      if (
        this._deviceTransitionDirection ===
        ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_PREVIOUS
      ) {
        this._undoDeployDevice();
      } else {
        this._undeployDevice();
      }
    } else if (
      this._deviceStatus ===
        ProcessTaskDeviceStatusIcon.DEVICE_STATUS_FINISHED &&
      this.device.dateTo
    ) {
      if (
        this._deviceTransitionDirection ===
        ProcessTaskDeviceStatusIcon.TRANSITION_DIRECTION_PREVIOUS
      ) {
        this._undoUndeployDevice();
      }
    }

    this._updateDeviceStatus();
  }

  _deployDevice() {
    if (!this.device) return;
    this.device.dateFrom = new Date().toISOString();
    this.device.dateFromUserId = this._currentUser
      ? this._currentUser.id
      : null;
    this._entityManager.processTaskDeviceRepository.update(this.device);
  }

  _undoDeployDevice() {
    if (!this.device) return;
    this.device.dateFrom = null;
    this.device.dateFromUserId = null;
    this.device.dateTo = null;
    this.device.dateToUserId = null;
    this._entityManager.processTaskDeviceRepository.update(this.device);
  }

  _undeployDevice() {
    if (!this.device) return;
    this.device.dateTo = new Date().toISOString();
    this.device.dateToUserId = this._currentUser ? this._currentUser.id : null;
    this._entityManager.processTaskDeviceRepository.update(this.device);
  }

  _undoUndeployDevice() {
    if (!this.device) return;
    this.device.dateTo = null;
    this.device.dateToUserId = null;
    this._entityManager.processTaskDeviceRepository.update(this.device);
  }

  _handleMoreButtonClick() {
    this._panelOpened = !this._panelOpened;
  }

  _handleEditButtonClicked() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  _handleDeleteButtonClicked() {
    const device = this.device;
    if (!device) {
      return;
    }

    Dialogs.deleteEntityDialog(device).then(() => {
      this._entityManager.processTaskDeviceRepository.delete(device);
    });
  }

  /**
   * @param {string} dateString
   * @returns {boolean}
   */
  _isInUndoTimeRange(dateString) {
    return (
      new Date().getTime() - Date.parse(dateString) <
      ProcessTaskAppointmentDevicesListItem.DEVICE_STATUS_CHANGE_UNDO_TIME
    );
  }
}
