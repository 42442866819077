import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { MapLayerDto } from './MapLayerDto';

export function createMapLayerEntityInfo<
  TEntity extends MapLayerDto<string, string>
>(
  options: CreateEntityInfoOptions<MapLayerDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.MapLayer,
  TEntity
> {
  return {
    entityName: EntityName.MapLayer,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      MapLayerDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        fileName: {
          name: 'fileName',
          defaultValue: null
        },
        minZoom: {
          name: 'minZoom',
          defaultValue: 1
        },
        strokeEnabled: {
          name: 'strokeEnabled',
          defaultValue: true
        },
        strokeColor: {
          name: 'strokeColor',
          defaultValue: '#FF0000'
        },
        strokeOpacity: {
          name: 'strokeOpacity',
          defaultValue: 1
        },
        strokeWeight: {
          name: 'strokeWeight',
          defaultValue: 1
        },
        strokeDashArray: {
          name: 'strokeDashArray',
          defaultValue: null
        },
        fillEnabled: {
          name: 'fillEnabled',
          defaultValue: false
        },
        fillColor: {
          name: 'fillColor',
          defaultValue: '#FF0000'
        },
        fillOpacity: {
          name: 'fillOpacity',
          defaultValue: 0.2
        },
        circleRadius: {
          name: 'circleRadius',
          defaultValue: 3
        },
        annotationPropertyName: {
          name: 'annotationPropertyName',
          defaultValue: null
        },
        minZoomAnnotation: {
          name: 'minZoomAnnotation',
          defaultValue: 1
        }
      },
      options.additionalFieldInfos
    )
  };
}
