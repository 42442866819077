import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationAuthorizationTypeDto } from './ProcessConfigurationAuthorizationTypeDto';

export function createProcessConfigurationAuthorizationTypeEntityInfo<
  TEntity extends ProcessConfigurationAuthorizationTypeDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationAuthorizationTypeDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationAuthorizationType,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationAuthorizationType,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationAuthorizationTypeDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        userRoleId: {
          name: 'userRoleId',
          ref: EntityName.UserRole,
          defaultValue: null
        },
        stepRelations: {
          name: 'stepRelations',
          innerInfo: [
            {
              processConfigurationStepId: {
                name: 'processConfigurationStepId',
                ref: EntityName.ProcessConfigurationStep
              },
              autoRemoveAuthorizationsInThisStep: {
                name: 'autoRemoveAuthorizationsInThisStep',
                defaultValue: false
              },
              writePermissionInThisStep: {
                name: 'writePermissionInThisStep',
                defaultValue: false
              }
            }
          ],
          defaultValue: null
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
