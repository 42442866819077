import { autoinject } from 'aurelia-dependency-injection';
import { ProcessConfigurationOfferConfiguration } from '../../../../../../common/src/Types/ProcessConfigurationOfferConfiguration';
import { DefaultInspectorService } from '../../../../services/DefaultInspectorService';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTask } from '../ProcessTask/types';
import { ProcessTaskOffer } from './types';

@autoinject()
export class ProcessTaskOfferCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly defaultInspectorService: DefaultInspectorService
  ) {}

  public async createForProcessTask({
    processTask,
    categoryId
  }: {
    processTask: ProcessTask;
    categoryId: string | null;
  }): Promise<ProcessTaskOffer> {
    const defaultInspectorUserId =
      await this.defaultInspectorService.getDefaultInspectorUserId(processTask);

    const offer = this.entityManager.processTaskOfferRepository.create({
      processConfigurationCategoryId: categoryId,
      inspectorUserId: defaultInspectorUserId,
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerUserGroupId: processTask.ownerUserGroupId,
      temporaryGroupName: processTask.temporaryGroupName,
      expirationDate: this.getDefaultExpirationDate({ processTask })
    });

    this.entityManager.processTaskOfferToProcessTaskRepository.create({
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: processTask.id,
      ownerUserGroupId: processTask.ownerUserGroupId,
      temporaryGroupName: processTask.temporaryGroupName,
      processTaskOfferId: offer.id
    });

    return offer;
  }

  private getDefaultExpirationDate({
    processTask
  }: {
    processTask: ProcessTask;
  }): string | null {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        processTask.ownerProcessTaskGroupId
      );
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const config = processConfiguration?.offerConfigurationJson
      ? (JSON.parse(
          processConfiguration.offerConfigurationJson
        ) as ProcessConfigurationOfferConfiguration)
      : null;
    const firstDefaultButtonConfig = (config?.expirationDateButtons ?? []).find(
      (button) => button.useAsDefaultValue
    );

    if (firstDefaultButtonConfig) {
      return new Date(
        Date.now() + firstDefaultButtonConfig.offset
      ).toISOString();
    }

    return null;
  }
}
