import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskOfferToProcessTaskDeviceDto } from './ProcessTaskOfferToProcessTaskDeviceDto';

export function createProcessTaskOfferToProcessTaskDeviceEntityInfo<
  TEntity extends ProcessTaskOfferToProcessTaskDeviceDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskOfferToProcessTaskDeviceDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskOfferToProcessTaskDevice,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskOfferToProcessTaskDevice,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskOfferToProcessTaskDeviceDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskOfferId: {
          name: 'processTaskOfferId',
          ref: EntityName.ProcessTaskOffer,
          cascadeDeleteLocally: true
        },
        processTaskDeviceId: {
          name: 'processTaskDeviceId',
          ref: EntityName.ProcessTaskDevice,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
