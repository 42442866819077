import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProjectQuestionCatalogueEntityInfo } from 'common/Types/Entities/ProjectQuestionCatalogue/createProjectQuestionCatalogueEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProjectQuestionCatalogue } from './types';

export const projectQuestionCatalogueEntityInfo =
  createProjectQuestionCatalogueEntityInfo<ProjectQuestionCatalogue>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
