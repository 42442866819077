import { DateType, IdType } from '../Base/types';
import { ProcessTaskSubEntity } from '../Base/ProcessTaskSubEntity';
import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { Weekday } from '../../../Enums/Weekday';
import { Month } from '../../../Enums/Month';

/**
 * Similar to a ProcessTaskAppointment, but deals with recurring (= repeating) appointments.
 *
 * Since events that repeat every X days/months/weeks/etc. would be an infinite
 * amount of appointments, they are saved instead as a RecurringAppointment that defines the
 * start- & endtime as well as *how* the events shall repeat.
 */
type FullProcessTaskRecurringAppointment<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  ProcessTaskSubEntity<TId> & {
    coordinatorUserId: TId;
    processConfigurationStepId: TId | null;

    name: string | null;
    /** The date after which the recurring appointment should start. */
    dateFrom: TDate | null;
    /** Starting time of every event of this recurring appointment. */
    startTime: TDate | null;
    /** Duration of every event of this recurring appointment. The endTime of every event is their respective startDate (calculated from the recurrence) + durationInMs. */
    durationInMs: number | null;
    /** Rules for how the events of this recurring appointment repeat. */
    recurrence: Recurrence | null;

    assignedUserId: TId | null;

    /** Dates excluded from the recurrence. */
    excludedDates: Array<string>;

    color: string | null;
    secondaryColor: string | null;
  };

/**
 * If an event should occur more than once, this contains info on
 * when exactly repeating events occur, when they end, and similar info.
 *
 * This definition closely mirrors the recurrence rule in the .ics calendar format
 * (because there's a big chance events in RecordIT will need to be exported)
 * See here for more details: https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html
 * Or here for a more detailed overview: https://datatracker.ietf.org/doc/html/rfc5545
 *
 * According to the iCal format, if a recurrence rule for a part of the datetime (year/month/date),
 * is specified, it overwrites the same part from the process apointment datetime.
 * The time of recurring events will always occur at the same time as the appointment.
 * `2022-03-05T12:34:56` with `{ frequency: RecurrenceFrequency.YEARLY, byMonths: [Month.JAN], byMonthDays: [2] }`
 * will therefore always generate dates on the second of january, with a time of `12:34:56`.
 */
export type Recurrence = {
  /**
   * how frequently this event repeats.
   *
   * - Repeats every year on january 2nd:
   *    - `{ frequency: RecurrenceFrequency.YEARLY, byMonths: [Month.JAN], byMonthDays: [2] }`
   */
  frequency: RecurrenceFrequency;
  /**
   * how often this event occurs starting from the initial time, including the initial date.
   *
   * - Repeats daily for 10 occurences:
   *    - `{ frequency: RecurrenceFrequency.DAILY, count: 10 }`
   */
  count: number | null;
  /**
   * the end date, after which point this event no longer occurs.
   *
   * - Repeats daily until end of 2022:
   *    - `{ frequency: RecurrenceFrequency.DAILY, until: '2022-12-31' }`
   */
  until: string | null;
  /**
   * the interval of the recurrence frequency. The 'true' frequency of an event is frequency * interval.
   *
   * only used if the frequency is set to MONTHLY, WEEKLY, or DAILY.
   *
   * - Repeats every 2nd day:
   *    - `{ frequency: RecurrenceFrequency.DAILY, interval: 2 }`
   * - Repeats every 4th day:
   *    - `{ frequency: RecurrenceFrequency.DAILY, interval: 4 }`
   */
  interval: number | null;
  /**
   * only allows recurring events on days specified here.
   *
   * only used if the frequency is set to YEARLY, MONTHLY OR WEEKLY.
   *
   * - Repeats every monday:
   *    - `{ frequency: RecurrenceFrequency.WEEKLY, byDays: [Weekday.MO] }`
   * - Repeats every 2nd monday:
   *    - `{ frequency: RecurrenceFrequency.WEEKLY, byDays: [Weekday.MO], interval: 2 }`
   */
  byDays: Array<Weekday> | null;
  /**
   * only allows recurring events in months specified here.
   *
   * only used if the frequency is set to YEARLY.
   *
   * - Repeats every year on january 2nd:
   *    - `{ frequency: RecurrenceFrequency.YEARLY, byMonths: [Month.JAN], byMonthDays: [2] }`
   * - Repeats every year on the first sunday of february:
   *    - `{ frequency: RecurrenceFrequency.YEARLY, byMonths: [Month.MAR], byDays: [Weekday.SU], pos: 1 }`
   */
  byMonths: Array<Month> | null;
  /**
   * only allows recurring events on days of the month specified here.
   *
   * only used if the frequency is set to YEARLY or MONTHLY.
   *
   * - Repeats every 1st day of the month:
   *    - `{ frequency: RecurrenceFrequency.MONTHLY, byMonthDays: [1] }`
   * - Repeats every year on january 2nd:
   *    - `{ frequency: RecurrenceFrequency.YEARLY, byMonths: [Month.JAN], byMonthDays: [2] }`
   */
  byMonthDays: Array<number> | null;
  /**
   * Selects the event at pos within the recurrency frequency.
   *
   * Basically, all potential recurrent event dates are calculated first, then the code
   * removes all dates that are not at potential_dates[pos] for each recurrency interval.
   *
   * only used if the frequency is set to YEARLY or MONTHLY.
   *
   * - Repeats every 1st monday of the month:
   *    - `{ frequency: RecurrenceFrequency.MONTHLY, byDays: [Weekday.MO], pos: 1 }`
   * - Repeats every last friday of the month:
   *    - `{ frequency: RecurrenceFrequency.MONTHLY, byDays: [Weekday.FR], pos: -1 }`
   * - Repeats every 2nd monday in february:
   *    - `{ frequency: RecurrenceFrequency.MONTHLY, byDays: [Weekday.MO], pos: 1 }`
   */
  pos: number | null;
};

/**
 * How often a recurrent event repeats.
 *
 * Yearly & monthly frequency is based on a offset to parts of the initial date,
 * NOT as a offset to the date.
 *
 * Assuming an interval of 1 (see `Recurrence#interval`), this basically means:
 * - `2021-01-01` with a yearly recurrence frequency is `[2021+i]-01-01`
 * - `2021-01-01` with a yearly recurrence frequency is NOT `2021-01-01 + 365*i days`
 *
 * This is important because leap years exist.
 * Unfortunately, as a consequence, a yearly occurring event on Feb 29. will therefore
 * only *really* occur every leap year.
 *
 * For a weekly recurrence frequency, the offset is 7 days.
 */
export enum RecurrenceFrequency {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  DAILY = 'daily'
}

export const allRecurrenceFrequencies = (): Array<RecurrenceFrequency> =>
  Object.values(RecurrenceFrequency);

export type ProcessTaskRecurringAppointmentDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessTaskRecurringAppointment<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessTaskRecurringAppointment<
    TId,
    TDate
  > = never
> = Omit<
  OptionalProperties<
    FullProcessTaskRecurringAppointment<TId, TDate>,
    TOptionalProperties
  >,
  TPropertiesToRemove
>;
