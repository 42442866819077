import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createValueCalculationResultEntityInfo } from 'common/Types/Entities/ValueCalculationResult/createValueCalculationResultEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ValueCalculationResult } from './types';

export const valueCalculationResultEntityInfo =
  createValueCalculationResultEntityInfo<ValueCalculationResult>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
