import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject, observable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DefectStatus } from 'common/Enums/DefectStatus';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { EditDefectDialog } from '../../dialogs/edit-defect-dialog/edit-defect-dialog';
import { EditDefectPicturesDialog } from '../../dialogs/edit-defect-pictures-dialog/edit-defect-pictures-dialog';
import { CategorizedTagSyncService } from '../../services/CategorizedTagSyncService';
import { PictureCreatorData } from '../../services/GalleryThingPictureCreatorService';
import { GlobalElements } from '../global-elements/global-elements';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CameraOverlay } from '../camera-overlay/camera-overlay';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * The normal ultra-rapid-fire-widget-dialog, but in defect mode.
 * Will assign all pictures taken to a defect.
 */
@autoinject()
export class UltraRapidFireWidgetDefect {
  protected cameraOverlay: CameraOverlay | null = null;

  protected lastPicture: Picture | null = null;

  protected previewPictureAnimationPictureUrl: string | null = null;

  protected defect: Defect | null = null;

  protected defectAssignee: User | null = null;

  @observable protected tagIds: Array<string> = [];

  protected availableFlashModes: Array<CameraPreviewFlashMode> = [];
  protected currentFlashMode: CameraPreviewFlashMode | null = null;

  private options: UrfwDefectOptions | null = null;

  private removeTagIdsChangedCallback: (() => void) | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private tagSyncService: CategorizedTagSyncService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly defectCreationService: DefectCreationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.removeTagIdsChangedCallback = this.tagSyncService.onChange(
      this.updateTags.bind(this)
    );

    assertNotNullOrUndefined(
      this.cameraOverlay,
      'camera overlay is not attached'
    );
    this.subscriptionManager.addDisposable(
      this.cameraOverlay.subscribeToAvailableFlashModeChanges(
        ({ availableFlashModes }) => {
          this.availableFlashModes = availableFlashModes;
        }
      ),
      this.cameraOverlay.subscribeToCurrentFlashModeChanges(
        ({ currentFlashMode }) => {
          this.currentFlashMode = currentFlashMode;
        }
      )
    );
  }

  protected detached(): void {
    this.removeTagIdsChangedCallback?.();
    this.subscriptionManager.disposeSubscriptions();
  }

  public static async start(options: UrfwDefectOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().start(options);
  }

  private start(options: UrfwDefectOptions): void {
    this.options = options;

    // If defect id is already in options, retrieve the defect so taking pictures won't create a new one
    if (this.options.defectId) {
      this.defect = this.getDefect(this.options.defectId);
    } else {
      // If a defect id is not already set, create a new one
      this.defect = this.createDefect();
    }
    this.defectAssignee = this.getDefectAssignee(this.defect);

    void this.cameraOverlay?.open();
  }

  protected tagIdsChanged(): void {
    setTimeout(() => this.tagSyncService.set(this.tagIds));
  }

  private updateTags(tagIds: Array<string>): void {
    this.tagIds = tagIds;
  }

  private getDefectAssignee(defect: Defect): User | null {
    if (!defect.assigneeId) return null;
    return this.entityManager.userRepository.getById(defect.assigneeId);
  }

  private getDefect(defectId: string): Defect {
    const defect = this.entityManager.defectRepository.getById(defectId);
    assertNotNullOrUndefined(
      defect,
      'cannot retrieve defect if defectId is incorrect'
    );
    return defect;
  }

  private createDefect(): Defect {
    assertNotNullOrUndefined(
      this.options,
      'cannot create a new defect if options are null'
    );
    return this.defectCreationService.createDefect({
      ownerThingId: this.options.pictureCreatorData.thing.id,
      ownerUserGroupId: this.options.pictureCreatorData.thing.ownerUserGroupId,
      name: '',
      description: '',
      status: DefectStatus.OPEN
    });
  }

  private savePicture(): Picture {
    assertNotNullOrUndefined(
      this.options,
      'cannot save a picture without dialog options'
    );
    assertNotNullOrUndefined(
      this.defect,
      'cannot save a picture without a defect'
    );

    return this.options.savePictureCallback(
      this.defect,
      this.tagSyncService.getTags()
    );
  }

  protected async handleCapturePictureClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      'cannot handle a capturePictureClick without options'
    );
    assertNotNullOrUndefined(
      this.cameraOverlay,
      'cannot capture picture without a camera overlay'
    );

    const pictureDataUrl = await this.cameraOverlay.takePicture();
    const picture = this.savePicture();
    this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
      picture,
      pictureDataUrl,
      false
    );
    this.lastPicture = picture;
    this.previewPictureAnimationPictureUrl = pictureDataUrl;
  }

  protected handleEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handle a editButtonClick without a defect'
    );

    void EditDefectDialog.open({
      defect: this.defect,
      showPictures: false
    });
  }

  protected handleCloseButtonClick(): void {
    void this.cameraOverlay?.close();

    this.options = null;
    this.defect = null;
  }

  protected handlePreviewPictureClick(): void {
    assertNotNullOrUndefined(
      this.lastPicture,
      'cannot handle a previewPictureClick without a lastPicture'
    );
    assertNotNullOrUndefined(
      this.options,
      'cannot handle a previewPictureClick without options'
    );

    // The dialog can only be opened if a defect has already been set/created for the current dialog
    if (this.defect !== null) {
      void EditDefectPicturesDialog.open({
        thingId: this.defect.ownerThingId,
        defectId: this.defect.id,
        selectedPicture: this.lastPicture
      });
    }
  }

  protected handleSwitchCamera(): void {
    void this.cameraOverlay?.switchStream();
  }

  protected handleSwitchFlashMode(): void {
    void this.cameraOverlay?.switchFlashMode();
  }
}

/**
 * Options for URFW Defect dialog.
 */
type UrfwDefectOptions = {
  pictureCreatorData: PictureCreatorData;
  /**
   * The id of the defect to attach captured pictures to.
   * If not specified, the dialog itself will create a new defect
   * and attach captures pictures to it.
   */
  defectId?: string;
  /**
   * Callback called when a picture is taken.
   * `defect` is the Defect is needs to be associated with.
   */
  savePictureCallback: (defect: Defect, tagIds: Array<string>) => Picture;
};
