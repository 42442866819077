import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import {
  PropertyCreationEntity,
  UserDefinedEntityProperty
} from '../Property/types';
import {
  ProjectUserDefinedEntity,
  ThingUserDefinedEntity,
  UserDefinedEntity,
  UserDefinedEntityCreationEntity
} from './types';
import {
  SupportedTemplateUserDefinedEntity,
  SupportedUserDefinedEntityBaseEntity
} from '../../../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { Project } from '../Project/types';
import { Thing } from '../Thing/types';
import { UserDefinedEntityConfigPropertyConfigHelper } from 'common/EntityHelper/UserDefinedEntityConfigPropertyConfigHelper';

@autoinject()
export class UserDefinedEntityCreationService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createThingUserDefinedEntity(
    entityTemplate: SupportedTemplateUserDefinedEntity<Thing>,
    thing: Thing
  ): ThingUserDefinedEntity {
    const copiedEntity: UserDefinedEntityCreationEntity = {
      ...this.createEntitySpecificUserDefinedEntityFromTemplate(entityTemplate),
      thingId: thing.id
    };

    const newEntity = this.entityManager.userDefinedEntityRepository.create(
      copiedEntity
    ) as ThingUserDefinedEntity;
    for (const property of this.entityManager.propertyRepository.getByUserDefinedEntityId(
      entityTemplate.id
    )) {
      this.createEntitySpecificPropertyFromTemplate(property, newEntity);
    }

    return newEntity;
  }

  public createThingUserDefinedEntityWithoutTemplate(
    creationEntity: UserDefinedEntityCreationEntity
  ): ThingUserDefinedEntity {
    const newEntity = this.entityManager.userDefinedEntityRepository.create(
      creationEntity
    ) as ThingUserDefinedEntity;

    const propertyConfigs = !creationEntity.userDefinedEntityConfigId
      ? []
      : this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
          creationEntity.userDefinedEntityConfigId
        );

    for (const pConfig of propertyConfigs) {
      this.entityManager.propertyRepository.create({
        ...UserDefinedEntityConfigPropertyConfigHelper.reduceToPropertyConfig(
          pConfig
        ),
        ownerUserGroupId: newEntity.ownerUserGroupId,
        userDefinedEntityId: newEntity.id,
        ownerProjectId: newEntity.ownerProjectId ?? null
      });
    }

    return newEntity;
  }

  public createProjectUserDefinedEntity(
    entityTemplate: SupportedTemplateUserDefinedEntity<Project>,
    project: Project
  ): ProjectUserDefinedEntity {
    const copiedEntity: UserDefinedEntityCreationEntity = {
      ...this.createEntitySpecificUserDefinedEntityFromTemplate(entityTemplate),
      ownerProjectId: project.id
    };
    const newEntity = this.entityManager.userDefinedEntityRepository.create(
      copiedEntity
    ) as ProjectUserDefinedEntity;
    for (const property of this.entityManager.propertyRepository.getByUserDefinedEntityId(
      entityTemplate.id
    )) {
      this.createEntitySpecificPropertyFromTemplate(property, newEntity);
    }

    return newEntity;
  }

  private createEntitySpecificPropertyFromTemplate(
    property: UserDefinedEntityProperty,
    newUserDefinedEntity: UserDefinedEntity
  ): UserDefinedEntityProperty {
    const copiedProperty: PropertyCreationEntity = {
      ownerUserGroupId: newUserDefinedEntity.ownerUserGroupId,
      name: property.name,
      type: property.type,
      value: property.value,
      choices: property.choices,
      custom_choice: property.custom_choice,
      order: property.order,
      userDefinedEntityId: newUserDefinedEntity.id,
      ownerProjectId: newUserDefinedEntity.ownerProjectId ?? null
    };
    return this.entityManager.propertyRepository.create(
      copiedProperty
    ) as UserDefinedEntityProperty;
  }

  private createEntitySpecificUserDefinedEntityFromTemplate<
    T extends SupportedUserDefinedEntityBaseEntity
  >(
    entityTemplate: SupportedTemplateUserDefinedEntity<T>
  ): UserDefinedEntityDuplicationTemplate {
    const globalId = entityTemplate.isGlobal
      ? entityTemplate.id
      : entityTemplate.globalUserDefinedEntityId!;

    return {
      name: entityTemplate.name,
      customId: entityTemplate.customId,
      globalUserDefinedEntityId: globalId,
      ownerUserGroupId: entityTemplate.ownerUserGroupId,
      userDefinedEntityConfigId: entityTemplate.userDefinedEntityConfigId
    };
  }
}

type UserDefinedEntityDuplicationTemplate = Pick<
  UserDefinedEntityCreationEntity,
  'name' | 'customId' | 'ownerUserGroupId' | 'userDefinedEntityConfigId'
> & {
  globalUserDefinedEntityId: NonNullable<
    UserDefinedEntityCreationEntity['globalUserDefinedEntityId']
  >;
};
