/** @type {{DEFAULT: null, YES: 1, NO: 2}} */
export const ProcessTaskAppointmentSmsNotification = {
  DEFAULT: null,
  YES: 1,
  NO: 2
};

/**
 * @typedef {null|1|2} TProcessTaskAppointmentSmsNotification
 */
