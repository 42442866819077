import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { ScrollHelper } from '../../classes/ScrollHelper';
import { CreatePersonDialog } from '../create-person-dialog/create-person-dialog';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingToPerson } from '../../classes/EntityManager/entities/ThingToPerson/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class EditThingPersonRelationsWidget {
  @bindable()
  public thing: Thing | null = null;

  /**
   * if this is set, then all sub entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  protected personIdToAdd: string | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    protected readonly router: Router,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditThingPersonRelationsWidget,
        propertyName: 'thing'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.UserGroup,
        context: this as EditThingPersonRelationsWidget,
        propertyName: 'thing',
        idPropertyName: 'ownerUserGroupId'
      });
  }

  private addPersonIdToThing(thing: Thing, personId: string): void {
    const thingToPerson = this.entityManager.thingToPersonRepository.create({
      thingId: thing.id,
      personId: personId,
      mainContact: this.availableThingToPersons.length === 0,
      ownerUserGroupId: thing.ownerUserGroupId,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });
    setTimeout(() => this.goToThingToPerson(thingToPerson));
  }

  private goToThingToPerson(thingToPerson: ThingToPerson): void {
    void ScrollHelper.autoScrollToSubtleListItem(
      '#' + this.getThingToPersonElementId(thingToPerson.id)
    );
  }

  @computed(expression('thing'), model(EntityName.ThingToPerson))
  protected get availableThingToPersons(): Array<ThingToPerson> {
    if (this.thing) {
      return this.entityManager.thingToPersonRepository.getByThingId(
        this.thing.id
      );
    } else {
      return [];
    }
  }

  @computed(expression('personIdToAdd'), expression('availableThingToPersons'))
  protected get personIdToAddErrorTextTranslationKeys(): Array<string> | null {
    const errorTexts = [];

    if (this.personIdToAdd) {
      const personToPersonIndex = this.availableThingToPersons.findIndex(
        (thingToPerson) => {
          return thingToPerson.personId === this.personIdToAdd;
        }
      );

      if (personToPersonIndex >= 0) {
        errorTexts.push(
          'personComponents.editThingPersonRelationsWidget.relationExists'
        );
      }
    }

    return errorTexts.length ? errorTexts : null;
  }

  protected getThingToPersonElementId(thingToPersonId: string): string {
    return (
      'edit-thing-person-relations-widget--thing-to-person-' + thingToPersonId
    );
  }

  protected handleAddThingToPersonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditThingPersonRelationsWidget.handleAddThingToPersonClick without thing"
    );
    assertNotNullOrUndefined(
      this.personIdToAdd,
      "can't EditThingPersonRelationsWidget.handleAddThingToPersonClick without personIdToAdd"
    );

    this.addPersonIdToThing(this.thing, this.personIdToAdd);

    this.personIdToAdd = null;
  }

  protected handleCreatePersonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditThingPersonRelationsWidget.handleCreatePersonClick without thing"
    );

    void CreatePersonDialog.open({
      userGroupId: this.thing.ownerUserGroupId,
      onDialogClosed: (person) => {
        if (person && this.thing) {
          this.addPersonIdToThing(this.thing, person.id);
        }
      }
    });
  }
}
