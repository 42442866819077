import { bindable } from 'aurelia-framework';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import {
  Count,
  ListItemRelatedEntityCountsAdapter
} from './adapter/ListItemRelatedEntityCountsAdapter';

/**
 * A component which displays the count of related entities.
 * This is meant to be displayed in a list item.
 *
 * The counts are displayed in this form: `  A(X)    T(X)    V(5)  `
 * The adapter decides which entities will be counted and how many counts there will be
 */
export class ListItemRelatedEntityCounts {
  @bindable()
  public adapter: ListItemRelatedEntityCountsAdapter | null = null;

  private adapterDisposable: Disposable | null = null;
  private isAttached = false;
  protected counts: Array<Count> = [];

  protected attached(): void {
    this.isAttached = true;

    this.updateAdapterSubscription();
  }

  protected detached(): void {
    this.isAttached = false;
    this.adapterDisposable?.dispose();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.updateAdapterSubscription();
    }
  }

  private updateAdapterSubscription(): void {
    this.adapterDisposable?.dispose();
    if (!this.adapter) {
      return;
    }

    this.adapterDisposable = this.adapter.subscribe({
      onNewCounts: (counts) => {
        this.counts = InstancePreserver.createNewArray({
          originalArray: this.counts,
          newArray: counts,
          getTrackingValue: (count) => count.tk
        });
      }
    });
  }
}
