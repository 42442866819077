import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * for positioning/styling multiple TagButtons look at the bottom of the tag-button.css
 */
@autoinject()
export class TagButton {
  /**
   * the tag should be really short
   */
  @bindable public tag = '';

  /**
   * this is completely optional
   */
  @bindable public description = '';

  @bindable public disabled = false;

  @bindable public activated = false;

  @bindable public readOnly = false;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  private handleTagClick(): void {
    if (!this.disabled && !this.readOnly) {
      this.fireEvent();
    }
  }

  private fireEvent(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'tag-toggled',
      bubbles: true,
      detail: null
    });
  }
}
