import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskAppointmentDialog } from '../edit-process-task-appointment-dialog/edit-process-task-appointment-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionUtils } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { CreateProcessTaskAppointmentService } from '../../classes/EntityManager/entities/ProcessTaskAppointment/CreateProcessTaskAppointmentService';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * a widget which shows all Positions of a certain ProcessTask in an expandable container
 */
@autoinject()
export class ProcessTaskPositionsWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * null if positions aren't loaded
   * read only
   */
  @bindable()
  public processTaskPositionCount: number | null = null;

  /**
   * enables/disables the creation/editing of positions
   */
  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private positions: Array<ProcessTaskPosition> = [];
  private selectedPositions: Array<ProcessTaskPosition> = [];
  private selectedDevices: Array<ProcessTaskDevice> = [];
  private processConfiguration: ProcessConfiguration | null = null;
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly createProcessTaskAppointmentService: CreateProcessTaskAppointmentService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.updatePositions();

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTaskGroup.processConfigurationId',
      this.updateProcessConfiguration.bind(this)
    );
    this.updateProcessConfiguration();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updatePositions();
    }
  }

  private updatePositions(): void {
    if (this.processTask) {
      const positions =
        this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        );
      this.positions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(positions);
      this.processTaskPositionCount = this.positions.length;
    } else {
      this.positions = [];
      this.processTaskPositionCount = null;
    }
  }

  private updateProcessConfiguration(): void {
    if (this.processTaskGroup) {
      this.processConfiguration =
        this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        );
    } else {
      this.processConfiguration = null;
    }
  }

  protected handleProcessTaskPositionCreated(): void {
    this.updatePositions();
  }

  protected async handleCreateAppointmentClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskPositionsWidget.handleCreateAppointmentClicked without a processTask"
    );

    const appointment =
      await this.createProcessTaskAppointmentService.createAppointment(
        this.processTask,
        this.selectedPositions,
        this.selectedDevices
      );

    this.selectedPositions = [];
    this.selectedDevices = [];

    void EditProcessTaskAppointmentDialog.open({
      appointment: appointment
    });
  }

  @computed(
    expression('processConfiguration.showProcessAppointmentTabs.devices')
  )
  protected get showDevices(): boolean {
    return (
      this.processConfiguration?.showProcessAppointmentTabs?.devices !== false
    );
  }
}
