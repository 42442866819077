import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';
import { NavigationService } from '../../../services/NavigationService';

@autoinject
export class EntrySearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.Entry]
    | null = null;

  protected itemProperties: Array<ItemProperty> = [];

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly navigationService: NavigationService
  ) {}

  // Aurelia Lifecycle

  protected attached(): void {
    this.updateItemProperties();
  }

  // Aurelia Change Handlers

  protected searchResultChanged(): void {
    this.updateItemProperties();
  }

  // Aurelia Handlers

  protected handleNavigateToEntryClick(): void {
    const route = this.getRouteForEntrySearchResult();
    if (!route) return;

    this.router.navigate(route);
  }

  // Private Methods

  private updateItemProperties(): void {
    if (!this.searchResult) {
      this.itemProperties = [];
    } else {
      this.itemProperties = [
        {
          labelTk: 'modelProperties.note',
          value: this.searchResult.note ?? ''
        },
        {
          labelTk: 'modelProperties.created',
          value: DateUtils.formatToDateWithHourMinuteString(
            this.searchResult.created
          )
        }
      ];
    }
  }

  private getRouteForEntrySearchResult(): string | null {
    assertNotNullOrUndefined(this.searchResult, 'cannot navigate to entry');

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
        return this.getRouteForProjectId(this.searchResult.projectId, {
          entry_id: this.searchResult.id,
          edit_entry: true
        });

      case ReasonType.Property:
        return this.getRouteForProjectId(this.searchResult.projectId, {
          entry_id: this.searchResult.id,
          scroll_to_property: this.searchResult.reason.data.propertyId,
          edit_entry: true
        });

      case ReasonType.Picture:
      case ReasonType.Tag:
        return this.getRouteForProjectId(this.searchResult.projectId, {
          entry_id: this.searchResult.id,
          edit_entry: true
        });

      default:
        throw new Error(
          `cannot navigate to project result with unknown reason ${
            (this.searchResult as EntityNameToSearchResultMap[EntityName.Entry])
              .reason.type
          }`
        );
    }
  }

  private getRouteForProjectId(
    projectId: string,
    routeParams = {}
  ): string | null {
    const project = this.entityManager.projectRepository.getById(projectId);
    if (!project) return null;

    return this.navigationService.getProjectPageUrl(
      projectId,
      project.projectType,
      routeParams
    );
  }
}
