import { autoinject } from 'aurelia-framework';
import { SessionService } from '../../../../services/SessionService/SessionService';
import { SocketService } from '../../../../services/SocketService';
import { User } from './types';

@autoinject()
export class UserPasswordService {
  constructor(
    private readonly socketService: SocketService,
    private readonly sessionService: SessionService
  ) {}

  public updatePassword(
    user: User,
    oldPassword: string | null,
    newPassword: string
  ): Promise<{ success: boolean }> {
    return new Promise((res, rej) => {
      this.socketService.setNewUserPassword(
        user.id,
        oldPassword,
        newPassword,
        (response) => {
          if (response.success) {
            if (response.webtoken) {
              void this.sessionService.setCurrentJWT(response.webtoken);
            }
            res(response);
          } else {
            rej(response);
          }
        }
      );
    });
  }
}
