import { z } from 'zod';

import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { TTextChangedEvent } from '../clickable-text-input/clickable-text-input';

/**
 * @deprecated - Use json-zod-object-input instead for better validation.
 *
 * @event text-changed - will get fired when the user finished editing the text
 */
@autoinject()
export class JsonInput {
  @bindable public text = '';
  @bindable public placeholder = '';
  @bindable public enabled = false;
  @bindable public readOnly = false;
  @bindable public label = '';
  @bindable public showButtons = true;
  @bindable public compactButton = false;

  @bindable public useFloatingLabel = false;

  @bindable public stackedStyle = false;

  @bindable public normalCaseLabel = false;

  @bindable public zodType: z.ZodType | null = null;

  private domElement;

  protected inputText = '';
  protected inputErrorText = '';

  constructor(element: Element) {
    this.domElement = element;
  }

  protected textChanged(): void {
    this.inputText = this.text;
  }

  protected handleTextChanged(event: TTextChangedEvent): void {
    event.stopPropagation();
    this.validateJSONText();
  }

  private validateJSONText(): void {
    if (this.inputText) {
      try {
        JSON.parse(this.inputText);
      } catch (error) {
        this.inputErrorText =
          error instanceof Error ? error.message : 'unknown error';
        return;
      }
    }

    if (this.zodType) {
      try {
        this.zodType.parse(JSON.parse(this.inputText));
      } catch (error) {
        this.inputErrorText =
          error instanceof z.ZodError ? error.message : 'unknown error';
        return;
      }
    }

    this.inputErrorText = '';
    this.text = this.inputText;

    setTimeout(() => {
      this.fireEvent();
    }, 0);
  }

  private fireEvent(): void {
    DomEventHelper.fireEvent<TextChangedEvent>(this.domElement, {
      name: 'text-changed',
      detail: JSON.parse(this.text)
    });
  }
}

export type TextChangedEvent = NamedCustomEvent<'text-changed', any>;
