import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskInvoice } from '../../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { ProcessTaskOffer } from '../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskSubEntity } from '../../../classes/EntityManager/entities/ProcessTaskSubEntityUtils';

export class DirectRelatedEntityDataFetcher<
  TEntity extends ProcessTaskSubEntity,
  TOfferRelation extends DirectRelatedEntityOfferRelationConstraint,
  TInvoiceRelation extends DirectRelatedEntityInvoiceRelationConstraint,
  TAppointmentRelation extends DirectRelatedEntityAppointmentRelationConstraint
> {
  public static fetch<
    TEntityStatic extends ProcessTaskSubEntity,
    TOfferRelationStatic extends DirectRelatedEntityOfferRelationConstraint,
    TInvoiceRelationStatic extends DirectRelatedEntityInvoiceRelationConstraint,
    TAppointmentRelationStatic extends
      DirectRelatedEntityAppointmentRelationConstraint
  >(
    processTask: ProcessTask,
    processTaskOffer: ProcessTaskOffer,
    adapter: DirectRelatedEntityDataFetcherAdapter<
      TEntityStatic,
      TOfferRelationStatic,
      TInvoiceRelationStatic,
      TAppointmentRelationStatic
    >
  ): DirectRelatedEntityData<
    TEntityStatic,
    TOfferRelationStatic,
    TInvoiceRelationStatic
  > {
    const fetcher = new DirectRelatedEntityDataFetcher<
      TEntityStatic,
      TOfferRelationStatic,
      TInvoiceRelationStatic,
      TAppointmentRelationStatic
    >(processTask, processTaskOffer, adapter);
    return fetcher.fetch();
  }

  constructor(
    private readonly processTask: ProcessTask,
    private readonly processTaskOffer: ProcessTaskOffer,
    private readonly adapter: DirectRelatedEntityDataFetcherAdapter<
      TEntity,
      TOfferRelation,
      TInvoiceRelation,
      TAppointmentRelation
    >
  ) {}

  public fetch(): DirectRelatedEntityData<
    TEntity,
    TOfferRelation,
    TInvoiceRelation
  > {
    const relations = this.getOfferRelations();
    const entityIds = relations.map((r) =>
      this.adapter.getEntityIdFromOfferRelation(r)
    );
    const entities = this.adapter.getEntitiesByIds(entityIds);
    const extendedEntities =
      this.adapter.getExtendedEntitiesByIds?.(entityIds) ?? entities;

    const otherOfferRelations = this.getOtherOfferRelations(extendedEntities);
    const otherOffers = this.getOtherOffers(
      otherOfferRelations.activeRelations
    );

    const invoiceRelations = this.getInvoiceRelations(extendedEntities);
    const invoices = this.getInvoices(invoiceRelations.activeRelations);

    const appointments = this.getAppointments(extendedEntities);

    return {
      offerRelations: relations,
      entities,
      extendedEntities,
      activeOtherOfferRelations: otherOfferRelations.activeRelations,
      inactiveOtherOfferRelations: otherOfferRelations.inactiveRelations,
      otherOffers,
      activeInvoiceRelations: invoiceRelations.activeRelations,
      inactiveInvoiceRelations: invoiceRelations.inactiveRelations,
      invoices,
      appointments
    };
  }

  private getOfferRelations(): Array<TOfferRelation> {
    const relations = this.adapter.getOfferRelationsByProcessTaskOfferId(
      this.processTaskOffer.id
    );
    return relations.filter(
      (r) => r.ownerProcessTaskId === this.processTask.id
    );
  }

  private getOtherOfferRelations(entities: Array<TEntity>): {
    activeRelations: Array<TOfferRelation>;
    inactiveRelations: Array<TOfferRelation>;
  } {
    const entityIds = entities.map((p) => p.id);
    const relations = this.adapter
      .getOfferRelationsByEntityIds(entityIds)
      .filter((r) => r.processTaskOfferId !== this.processTaskOffer.id);

    const offerIds = Array.from(
      new Set(relations.map((r) => r.processTaskOfferId))
    );
    const offerToProcessTasks =
      this.adapter.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferIds(
        offerIds
      );

    const activeRelations = [];
    const inactiveRelations = [];

    for (const relation of relations) {
      const active = offerToProcessTasks.find(
        (otp) =>
          otp.ownerProcessTaskId === relation.ownerProcessTaskId &&
          otp.processTaskOfferId === relation.processTaskOfferId
      );

      if (active) {
        activeRelations.push(relation);
      } else {
        inactiveRelations.push(relation);
      }
    }

    return { activeRelations, inactiveRelations };
  }

  private getOtherOffers(
    activeOtherOfferRelations: Array<TOfferRelation>
  ): Array<ProcessTaskOffer> {
    const offerIds = Array.from(
      new Set(activeOtherOfferRelations.map((otp) => otp.processTaskOfferId))
    );

    return this.adapter.entityManager.processTaskOfferRepository.getByIds(
      offerIds
    );
  }

  private getInvoiceRelations(entities: Array<TEntity>): {
    activeRelations: Array<TInvoiceRelation>;
    inactiveRelations: Array<TInvoiceRelation>;
  } {
    const positionIds = entities.map((p) => p.id);
    const invoiceRelations =
      this.adapter.getInvoiceRelationsByEntityIds(positionIds);

    const invoiceIds = Array.from(
      new Set(invoiceRelations.map((r) => r.processTaskInvoiceId))
    );
    const invoiceToProcessTasks =
      this.adapter.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceIds(
        invoiceIds
      );

    const activeInvoiceToPositions = [];
    const inactiveInvoiceToPositions = [];

    for (const invoiceToPosition of invoiceRelations) {
      const active = invoiceToProcessTasks.find(
        (otp) =>
          otp.ownerProcessTaskId === invoiceToPosition.ownerProcessTaskId &&
          otp.processTaskInvoiceId === invoiceToPosition.processTaskInvoiceId
      );

      if (active) {
        activeInvoiceToPositions.push(invoiceToPosition);
      } else {
        inactiveInvoiceToPositions.push(invoiceToPosition);
      }
    }

    return {
      activeRelations: activeInvoiceToPositions,
      inactiveRelations: inactiveInvoiceToPositions
    };
  }

  private getInvoices(
    activeInvoiceRelations: Array<TInvoiceRelation>
  ): Array<ProcessTaskInvoice> {
    const invoiceIds = Array.from(
      new Set(activeInvoiceRelations.map((r) => r.processTaskInvoiceId))
    );

    return this.adapter.entityManager.processTaskInvoiceRepository.getByIds(
      invoiceIds
    );
  }

  private getAppointments(
    entities: Array<TEntity>
  ): Array<ProcessTaskAppointment> {
    const positionIds = entities.map((p) => p.id);
    const relations =
      this.adapter.getAppointmentRelationsByEntityIds(positionIds);
    const appointmentIds = Array.from(
      new Set(relations.map((r) => r.processTaskAppointmentId))
    );

    return this.adapter.entityManager.processTaskAppointmentRepository.getByIds(
      appointmentIds
    );
  }
}

export type DirectRelatedEntityData<
  TEntity extends ProcessTaskSubEntity,
  TOfferRelation extends DirectRelatedEntityOfferRelationConstraint,
  TInvoiceRelation extends DirectRelatedEntityInvoiceRelationConstraint
> = {
  offerRelations: Array<TOfferRelation>;
  entities: Array<TEntity>;
  extendedEntities: Array<TEntity>;
  /**
   * active relations of entities of the offer to other offers
   * (e.g. an entity could be related to OfferA and OfferB)
   */
  activeOtherOfferRelations: Array<TOfferRelation>;
  /**
   * inactive relations of positions of the offer to other offers
   * (e.g. a position could be related to OfferA and OfferB)
   */
  inactiveOtherOfferRelations: Array<TOfferRelation>;
  /**
   * offers of activeOtherRelations
   */
  otherOffers: Array<ProcessTaskOffer>;

  /**
   * active relations of entities to invoices
   */
  activeInvoiceRelations: Array<TInvoiceRelation>;
  /**
   * inactive relations of entities to invoices
   */
  inactiveInvoiceRelations: Array<TInvoiceRelation>;
  /**
   * invoices of activeInvoiceRelations
   */
  invoices: Array<ProcessTaskInvoice>;

  appointments: Array<ProcessTaskAppointment>;
};

export type DirectRelatedEntityDataFetcherAdapter<
  TEntity extends ProcessTaskSubEntity,
  TOfferRelation extends DirectRelatedEntityOfferRelationConstraint,
  TInvoiceRelation extends DirectRelatedEntityInvoiceRelationConstraint,
  TAppointmentRelation extends DirectRelatedEntityAppointmentRelationConstraint
> = {
  entityManager: AppEntityManager;
  getOfferRelationsByProcessTaskOfferId: (
    processTaskOfferId: string
  ) => Array<TOfferRelation>;
  getOfferRelationsByEntityIds: (
    entityIds: Array<string>
  ) => Array<TOfferRelation>;
  getInvoiceRelationsByEntityIds: (
    entityIds: Array<string>
  ) => Array<TInvoiceRelation>;

  /**
   * Get all entities for the ids. For snapshotted positions, this should still only return the referenced entities
   */
  getEntitiesByIds: (ids: Array<string>) => Array<TEntity>;

  /**
   * Also return the snapshots for the ids, if any exist.
   * Necessary to check if any entities are related to invoices/offers
   */
  getExtendedEntitiesByIds?: (ids: Array<string>) => Array<TEntity>;
  getEntityIdFromOfferRelation: (relation: TOfferRelation) => string;
  getAppointmentRelationsByEntityIds: (
    entityIds: Array<string>
  ) => Array<TAppointmentRelation>;
};

export type DirectRelatedEntityOfferRelationConstraint =
  ProcessTaskSubEntity & { processTaskOfferId: string };
export type DirectRelatedEntityInvoiceRelationConstraint =
  ProcessTaskSubEntity & { processTaskInvoiceId: string };
export type DirectRelatedEntityAppointmentRelationConstraint =
  ProcessTaskSubEntity & { processTaskAppointmentId: string };
