import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { ProcessTaskSubEntity } from '../Base/ProcessTaskSubEntity';
import { DateType, IdType } from '../Base/types';

type FullProcessTaskToProject<
  TId extends IdType,
  TDate extends DateType,
  TType extends
    ProcessTaskToProjectType | null = ProcessTaskToProjectType | null
> = BaseEntity<TDate> &
  ProcessTaskSubEntity<TId> & {
    projectId: TId;
    processTaskAppointmentId: TId | null;
    processConfigurationStepId: TId | null;
    processConfigurationFormId: TId | null;
    sentByMail: boolean;
    created: number | null;
    type: TType;
  };

export type PictureProcessTaskToProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessTaskToProject<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessTaskToProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    Omit<
      FullProcessTaskToProject<
        TId,
        TDate,
        | ProcessTaskToProjectType.GENERAL
        | ProcessTaskToProjectType.MEASURE_POINT_PICTURES
        | null
      >,
      'processConfigurationFormId'
    > & { processConfigurationFormId: null },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type ProjectFromOtherModuleProcessTaskToProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessTaskToProject<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessTaskToProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    Omit<
      FullProcessTaskToProject<
        TId,
        TDate,
        ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE
      >,
      'processConfigurationFormId' | 'processConfigurationStepId'
    > & { processConfigurationFormId: null; processConfigurationStepId: null },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type FormProcessTaskToProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessTaskToProject<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessTaskToProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    Omit<
      FullProcessTaskToProject<TId, TDate, ProcessTaskToProjectType.FORM>,
      'processConfigurationFormId'
    > & { processConfigurationFormId: TId },
    Exclude<TOptionalProperties, 'processConfigurationFormId' | 'type'>
  >,
  TPropertiesToRemove
>;

export type ProcessTaskToProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessTaskToProject<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessTaskToProject<TId, TDate> = never
> =
  | PictureProcessTaskToProjectDto<
      TId,
      TDate,
      TOptionalProperties,
      TPropertiesToRemove
    >
  | FormProcessTaskToProjectDto<
      TId,
      TDate,
      TOptionalProperties,
      TPropertiesToRemove
    >
  | ProjectFromOtherModuleProcessTaskToProjectDto<
      TId,
      TDate,
      TOptionalProperties,
      TPropertiesToRemove
    >;

export enum ProcessTaskToProjectType {
  GENERAL = 'general',
  MEASURE_POINT_PICTURES = 'measurePointPictures',
  FORM = 'form',
  PROJECT_FROM_OTHER_MODULE = 'projectFromOtherModule'
}
