import { autoinject } from 'aurelia-framework';

import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  FieldType,
  ParsedLineData
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import {
  ThingSection,
  ThingSectionCreationEntity
} from '../../classes/EntityManager/entities/ThingSection/types';

@autoinject()
export class ThingSectionCsvImporterService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public importFromCsvFile(options: Options): void {
    const thingSectionsOfThing =
      this.entityManager.thingSectionRepository.getOrderedByThingId(
        options.thing.id
      );

    for (const lineData of options.parsedContent) {
      let thingSection = thingSectionsOfThing.find(
        (tS) => tS.name === lineData.fields.name
      );

      if (thingSection && options.overwrite) {
        this.updateExistingThingSection({ thingSection, lineData });
      } else {
        thingSection = this.createNewThingSection({
          thing: options.thing,
          lineData
        });
      }

      this.createProperties({
        thing: options.thing,
        thingSection,
        lineData,
        overwrite: options.overwrite
      });
    }
  }

  private updateExistingThingSection({
    thingSection,
    lineData
  }: {
    thingSection: ThingSection;
    lineData: ParsedLineData<FieldInfoConfiguration>;
  }): void {
    thingSection.name = lineData.fields.name ?? '';
    this.entityManager.thingSectionRepository.update(thingSection);

    if (lineData.fields.order !== undefined) {
      this.entityManager.thingSectionRepository.moveSectionToIndex({
        section: thingSection,
        index: lineData.fields.order
      });
    }
  }

  private createNewThingSection({
    thing,
    lineData
  }: {
    thing: Thing;
    lineData: ParsedLineData<FieldInfoConfiguration>;
  }): ThingSection {
    const thingSectionCreationEntity: Omit<
      ThingSectionCreationEntity,
      'order'
    > = {
      ownerUserGroupId: thing.ownerUserGroupId,
      ownerThingId: thing.id,
      name: lineData.fields.name
    };

    const thingSection = this.entityManager.thingSectionRepository.createAtEnd(
      thingSectionCreationEntity
    );

    if (lineData.fields.order !== undefined) {
      this.entityManager.thingSectionRepository.moveSectionToIndex({
        section: thingSection,
        index: lineData.fields.order
      });
    }

    return thingSection;
  }

  private createProperties({
    thing,
    thingSection,
    lineData,
    overwrite
  }: {
    thing: Thing;
    thingSection: ThingSection;
    lineData: ParsedLineData<FieldInfoConfiguration>;
    overwrite: boolean;
  }): void {
    const propertiesOfThingSection =
      this.entityManager.propertyRepository.getByThingThingSectionId(
        thingSection.id
      );

    for (const [field, value] of Object.entries(lineData.additionalFields)) {
      this.ensureThingSectionConfigProperties({
        thing,
        configPropertyName: field
      });

      const existingProperty = propertiesOfThingSection.find(
        (p) => p.name === field && p.type === PropertyType.TEXT
      );

      if (existingProperty && overwrite) {
        existingProperty.value = value;
        this.entityManager.propertyRepository.update(existingProperty);
      } else {
        this.entityManager.propertyRepository.create({
          ownerUserGroupId: thing.ownerUserGroupId,
          thingThingSectionId: thingSection.id,
          type: PropertyType.TEXT,
          name: field,
          value: value
        });
      }
    }
  }

  private ensureThingSectionConfigProperties({
    thing,
    configPropertyName
  }: {
    thing: Thing;
    configPropertyName: string;
  }): void {
    const existingConfigProperty =
      this.entityManager.thingSectionConfigPropertyRepository
        .getAll()
        .filter((tSCP) => tSCP.ownerThingId === thing.id)
        .find(
          (tSCP) =>
            tSCP.location === ThingSectionConfigPropertyLocation.THING &&
            tSCP.type === PropertyType.TEXT &&
            tSCP.name === configPropertyName
        );

    if (!existingConfigProperty) {
      this.entityManager.thingSectionConfigPropertyRepository.createAtEnd({
        ownerUserGroupId: thing.ownerUserGroupId,
        ownerThingId: thing.id,
        name: configPropertyName,
        location: ThingSectionConfigPropertyLocation.THING
      });
    }
  }
}

type Options = {
  thing: Thing;
  overwrite: boolean;
  parsedContent: Array<ParsedLineData<FieldInfoConfiguration>>;
};

export type FieldInfoConfiguration = {
  entityType: ThingSection;
  fieldInfos: [
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'order';
      type: FieldType.NUMBER;
    }
  ];
};
