import { AppEntityManager } from '../AppEntityManager';
import { StructureTemplateEntry } from './types';

export class StructureTemplateEntryRecursiveFilter {
  constructor(private entityManager: AppEntityManager) {}

  public filter(
    entries: Array<StructureTemplateEntry>,
    entryConditionCallback: (
      entry: StructureTemplateEntry,
      remainingChildren: Array<StructureTemplateEntry>,
      children: Array<StructureTemplateEntry>
    ) => boolean
  ): Array<StructureTemplateEntry> {
    const returnEntries = [];
    for (const entry of entries) {
      const children =
        this.entityManager.structureTemplateEntryRepository.getByParentId(
          entry.ownerStructureTemplateId,
          entry.id
        );

      let remainingChildren: Array<StructureTemplateEntry> = [];
      if (children.length) {
        remainingChildren = this.filter(children, entryConditionCallback);
      }

      if (entryConditionCallback(entry, remainingChildren, children)) {
        returnEntries.push(entry);
      }
    }
    return returnEntries;
  }
}
