import { inject } from 'aurelia-framework';
import _ from 'lodash';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from './SubscriptionManagerService';

@inject(AppEntityManager, SubscriptionManagerService)
export class EnsureDefaultPropertiesService {
  /** @type {SubscriptionManagerService} */
  _subscriptionManagerService;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManagerService = subscriptionManagerService;
  }

  /**
   * @param {TDefaultPropertiesEnsurerOptions} options
   * @return {DefaultPropertiesEnsurer<TProperty>}
   * @template {import('../classes/EntityManager/entities/Property/types').Property} TProperty
   */
  createEnsurer(options) {
    return new DefaultPropertiesEnsurer(
      this._entityManager,
      this._subscriptionManagerService,
      options
    );
  }
}

/**
 * @template {import('../classes/EntityManager/entities/Property/types').Property} TProperty
 */
export class DefaultPropertiesEnsurer {
  /** @type {import('../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;
  /** @type {TDefaultPropertiesEnsurerOptions} */
  _options;

  /** @type {boolean} */
  _subscribed = false;
  /** @type {Array<TProperty>|null} */
  _properties = null;

  /** @type {boolean} */
  _isActualizing = true;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {TDefaultPropertiesEnsurerOptions} options
   */
  constructor(entityManager, subscriptionManagerService, options) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._options = options;
  }

  subscribe() {
    this._subscribed = true;

    this._subscriptionManager.addDisposable(
      this._entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this._isActualizing = isActualizing;
          this._ensureDefaultProperties();
        }
      )
    );

    this._ensureDefaultProperties();
  }

  unsubscribe() {
    this._subscribed = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  /**
   * set the properties to null if no properties can be specified (this will also prevent the ensuring of properties)
   *
   * @param {Array<TProperty>|null} properties
   */
  setProperties(properties) {
    this._properties = properties;

    this._ensureDefaultProperties();
  }

  _ensureDefaultProperties() {
    if (this._isActualizing || !this._subscribed || !this._properties) {
      return;
    }

    for (const defaultPropertyConfig of this._options.defaultPropertyConfigs) {
      const property = this._properties.find(
        (p) => p.name === defaultPropertyConfig.name
      );

      if (property) {
        this._updateProperty(property, defaultPropertyConfig);
      } else {
        this._createProperty(defaultPropertyConfig);
      }
    }
  }

  /**
   * @param {TProperty} property
   * @param {import('../../../common/src/Types/DefaultPropertyConfig').TDefaultPropertyConfig} defaultPropertyConfig
   */
  _updateProperty(property, defaultPropertyConfig) {
    let modified = false;

    if (
      !this._choicesAreEqual(property.choices, defaultPropertyConfig.choices)
    ) {
      // set new choices
      property.choices = defaultPropertyConfig.choices || [];

      // move value into custom_choice if the choices doesn't exist anymore
      if (
        property.value &&
        property.choices.length &&
        property.choices.indexOf(property.value) === -1
      ) {
        property.custom_choice = property.value;
        property.value = '';
      }

      modified = true;
    }

    if (property.type !== defaultPropertyConfig.type) {
      property.type = defaultPropertyConfig.type;
      modified = true;
    }

    // only overwrite the value if it is set explicitly
    if (
      defaultPropertyConfig.hasOwnProperty('alwaysVisible') &&
      property.alwaysVisible !== defaultPropertyConfig.alwaysVisible
    ) {
      property.alwaysVisible = defaultPropertyConfig.alwaysVisible ?? null;
      modified = true;
    }

    if (modified) {
      this._entityManager.propertyRepository.update(property);
    }
  }

  /**
   * @param {import('../../../common/src/Types/DefaultPropertyConfig').TDefaultPropertyConfig} defaultPropertyConfig
   */
  _createProperty(defaultPropertyConfig) {
    const propertyTemplate = {
      value: '',
      alwaysVisible: true,
      active: true,
      ..._.cloneDeep(defaultPropertyConfig)
    };

    const creationProperty =
      this._options.propertyCreationPostProcessor(propertyTemplate);
    const property = this._entityManager.propertyRepository.create({
      ...creationProperty,
      shadowEntity: this._options.shadowEntity ?? false
    });
    this._properties &&
      this._properties.push(/** @type {TProperty} */ (property));
  }

  /**
   * @param {Array<string>|null|undefined} choicesA
   * @param {Array<string>|null|undefined} choicesB
   * @returns {boolean}
   */
  _choicesAreEqual(choicesA, choicesB) {
    return (
      this._choicesAreEqualOneWay(choicesA, choicesB) &&
      this._choicesAreEqualOneWay(choicesB, choicesA)
    );
  }

  /**
   * @param {Array<string>|null|undefined} choicesA
   * @param {Array<string>|null|undefined} choicesB
   * @returns {boolean}
   */
  _choicesAreEqualOneWay(choicesA, choicesB) {
    const a = choicesA ? choicesA : [];
    const b = choicesB ? choicesB : [];

    return a.every((choice) => b.indexOf(choice) >= 0);
  }
}

/**
 * @typedef {Object} TDefaultPropertiesEnsurerOptions
 * @property {Array<import('../../../common/src/Types/DefaultPropertyConfig').TDefaultPropertyConfig>} defaultPropertyConfigs
 * @property {boolean|null} [shadowEntity]
 * @property {function(import('../../../common/src/Types/DefaultPropertyConfig').TDefaultPropertyConfig): import('../classes/EntityManager/entities/Property/types').PropertyCreationBaseData} propertyCreationPostProcessor
 */
