import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  ThingSectionConfigProperty,
  ThingSectionConfigPropertyCreationEntity
} from './types';

export class ThingSectionConfigPropertyRepository extends AppEntityRepository<EntityName.ThingSectionConfigProperty> {
  public getOrderedByThingIdAndLocation({
    thingId,
    location
  }: {
    thingId: string;
    location: ThingSectionConfigPropertyLocation;
  }): Array<ThingSectionConfigProperty> {
    return this.getUnorderedByThingIdAndLocation({ thingId, location }).sort(
      (a, b) => a.order - b.order
    );
  }

  public createAtEnd(
    creationEntity: Omit<ThingSectionConfigPropertyCreationEntity, 'order'>
  ): ThingSectionConfigProperty {
    const orders = this.getUnorderedByThingIdAndLocation({
      thingId: creationEntity.ownerThingId,
      location: creationEntity.location
    }).map((configProperty) => configProperty.order);

    return this.create({
      ...creationEntity,
      order: Math.max(-1, ...orders) + 1
    });
  }

  public deleteAndUpdateOrder(
    configProperty: ThingSectionConfigProperty
  ): void {
    this.delete(configProperty);

    this.reassignOrderBasedOnArrayIndex(
      this.getOrderedByThingIdAndLocation({
        thingId: configProperty.ownerThingId,
        location: configProperty.location
      })
    );
  }

  public moveConfigPropertyToIndex({
    configProperty,
    index
  }: {
    configProperty: ThingSectionConfigProperty;
    index: number;
  }): void {
    const configProperties = this.getOrderedByThingIdAndLocation({
      thingId: configProperty.ownerThingId,
      location: configProperty.location
    });

    const removalIndex = configProperties.indexOf(configProperty);
    configProperties.splice(removalIndex, 1);

    configProperties.splice(index, 0, configProperty);

    this.reassignOrderBasedOnArrayIndex(configProperties);
  }

  private getUnorderedByThingIdAndLocation({
    thingId,
    location
  }: {
    thingId: string;
    location: ThingSectionConfigPropertyLocation;
  }): Array<ThingSectionConfigProperty> {
    return this.getAll().filter(
      (configProperty) =>
        configProperty.ownerThingId === thingId &&
        configProperty.location === location
    );
  }

  private reassignOrderBasedOnArrayIndex(
    configProperties: Array<ThingSectionConfigProperty>
  ): void {
    for (const [index, configProperty] of configProperties.entries()) {
      if (configProperty.order !== index) {
        configProperty.order = index;
        this.update(configProperty);
      }
    }
  }
}
