import { autoinject } from 'aurelia-dependency-injection';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { AppEntityManager } from '../AppEntityManager';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ProcessConfigurationForm } from '../ProcessConfigurationForm/types';
import { ProcessTask } from '../ProcessTask/types';
import { ProcessTaskAppointment } from '../ProcessTaskAppointment/types';
import { ProcessTaskToProject } from './types';
import { Project } from '../Project/types';
import { ProjectCreationService } from '../Project/ProjectCreationService';

@autoinject()
export class ProcessTaskToProjectFormCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService
  ) {}

  public createAutoForms(
    processTask: ProcessTask,
    appointment: ProcessTaskAppointment
  ): Array<FormCreationResult> {
    if (appointment.processConfigurationAutoFormsCreated) {
      return [];
    }

    const autoForms = appointment.processConfigurationStepId
      ? this.entityManager.processConfigurationStepAutoFormRepository.getByProcessConfigurationStepId(
          appointment.processConfigurationStepId
        )
      : [];

    const createdForms = [];

    for (const autoForm of autoForms) {
      if (!autoForm.processConfigurationFormId) {
        continue;
      }

      const configurationForm =
        this.entityManager.processConfigurationFormRepository.getById(
          autoForm.processConfigurationFormId
        );
      if (configurationForm) {
        createdForms.push(
          this.createForm(processTask, appointment, configurationForm)
        );
      } else {
        console.error(
          `no processconfiguration form found for ${autoForm.processConfigurationFormId}`
        );
      }
    }

    appointment.processConfigurationAutoFormsCreated = true;
    this.entityManager.processTaskAppointmentRepository.update(appointment);

    return createdForms;
  }

  public createForm(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment | null,
    processConfigurationForm: ProcessConfigurationForm
  ): FormCreationResult {
    const thing = this.entityManager.thingRepository.getRequiredById(
      processTask.thingId
    );
    const reportType = processConfigurationForm.reportTypeId
      ? this.entityManager.reportTypeRepository.getById(
          processConfigurationForm.reportTypeId
        )
      : null;

    const project = this.projectCreationService.createProject({
      projectType: ProjectType.OPERATIONS,
      name: processConfigurationForm.name,
      thing,
      reportType,
      shadowEntity: processTaskAppointment?.shadowEntity,
      temporaryGroupName: processTaskAppointment?.shadowEntity
        ? processTaskAppointment.temporaryGroupName
        : null
    });

    const processTaskToProject =
      this.entityManager.processTaskToProjectRepository.create({
        projectId: project.id,
        processTaskAppointmentId: processTaskAppointment?.id,
        processConfigurationFormId: processConfigurationForm.id,
        type: ProcessTaskToProjectType.FORM,
        ownerProcessTaskId: processTask.id,
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerUserGroupId: processTask.ownerUserGroupId,
        shadowEntity: processTaskAppointment?.shadowEntity,
        temporaryGroupName:
          processTaskAppointment?.temporaryGroupName ??
          processTask.temporaryGroupName
      });

    return { project, processTaskToProject };
  }
}

export type FormCreationResult = {
  project: Project;
  processTaskToProject: ProcessTaskToProject;
};
