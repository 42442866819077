import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SocketService } from '../../services/SocketService';

import { EmbeddedCamera } from '../embedded-camera/embedded-camera';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import {
  PictureCreatorService,
  GetEntityInfos
} from '../../classes/Picture/PictureCreatorService';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { CreateMapPictureDialog } from '../../dialogs/create-map-picture-dialog/create-map-picture-dialog';
import { Utils } from '../../classes/Utils/Utils';
import {
  MoreButton,
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../more-button/more-button';
import {
  Picture,
  PictureEntityIdField,
  PictureSubEntityField
} from '../../classes/EntityManager/entities/Picture/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PictureHelper } from '../../classes/Picture/PictureHelper';

/**
 * @event pictures-created - gets fired after a picture has been created, detail: {pictures: Array<Picture>}
 */
@autoinject()
export class PictureUploadButtons {
  @bindable public mainEntityIdField: string | null = null;
  @bindable public mainEntityId: string | null = null;

  @bindable public subEntityField: string | null = null;
  @bindable public subEntityValue: any = null;

  @bindable public ownerUserGroupId: string | null = null;
  @bindable public ownerProjectId: string | null = null;

  @bindable public ensureEntityIdFields: (() => Promise<void>) | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected isConnected = false;
  protected canCapturePicture = false;
  private domElement: HTMLElement;

  private moreButtonViewModel: MoreButton | null = null;

  protected isApp = false;

  constructor(
    element: Element,
    private socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    private pictureCreatorService: PictureCreatorService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isApp = DeviceInfoHelper.isApp();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding(
        'canCapturePicture',
        (canCapturePicture) => {
          this.canCapturePicture = canCapturePicture;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected async handleCapturePictureClick(): Promise<void> {
    await EmbeddedCamera.capturePicture({
      getEntityInfos: await this.createGetEntityInfos()
    });
  }

  protected async handleUploadPicturesChanged(
    event: MoreButtonFileChangedEvent<'upload-pictures-changed'>
  ): Promise<void> {
    const input = event.detail.target;
    const files = input.files ?? [];

    const pictures: Array<Picture> = [];

    for (const file of files) {
      const creator = this.pictureCreatorService.withEntityInfos(
        await this.createGetEntityInfos()
      );
      const picture = await creator.createPictureFromFile(file);
      if (picture) pictures.push(picture);
    }

    this.firePicturesCreatedEvent(pictures);
    input.value = '';
  }

  protected async handleCreateSketchClicked(): Promise<void> {
    const creator = this.pictureCreatorService.withEntityInfos(
      await this.createGetEntityInfos()
    );
    const picture = creator.createWhitePicture();
    this.firePicturesCreatedEvent([picture]);
  }

  protected async handleCreateMapPictureClicked(): Promise<void> {
    void CreateMapPictureDialog.open({
      getEntityInfos: await this.createGetEntityInfos()
    });
  }

  private firePicturesCreatedEvent(pictures: Array<Picture>): void {
    DomEventHelper.fireEvent<PicturesCreatedEvent>(this.domElement, {
      name: 'pictures-created',
      detail: { pictures: pictures }
    });
  }

  private async createGetEntityInfos(): Promise<GetEntityInfos> {
    if (this.ensureEntityIdFields && !this.ownerUserGroupId) {
      await this.ensureEntityIdFields();
      await Utils.wait(0);
    }

    return () => {
      assertNotNullOrUndefined(
        this.mainEntityIdField,
        "can't getEntityInfos without a mainEntityIdField"
      );
      assertNotNullOrUndefined(
        this.mainEntityId,
        "can't getEntityInfos without a mainEntityId"
      );
      assertNotNullOrUndefined(
        this.ownerUserGroupId,
        "can't getEntityInfos without a ownerUserGroupId"
      );

      return {
        mainEntityIdField: this.mainEntityIdField as PictureEntityIdField,
        mainEntityId: this.mainEntityId,
        subEntityField: this.subEntityField as PictureSubEntityField | null,
        subEntityValue: this.subEntityValue,
        ownerProjectId: this.ownerProjectId,
        ownerUserGroupId: this.ownerUserGroupId
      };
    };
  }

  protected handleMoreButtonClick(): void {
    this.moreButtonViewModel?.open();
  }

  protected getMoreButtonChoices(
    isConnected: boolean
  ): Array<MoreButtonChoice> {
    const choices: Array<MoreButtonChoice> = [
      {
        labelTk: 'aureliaComponents.pictureUploadButtons.uploadPictures',
        name: 'upload-pictures',
        isFileInput: true,
        fileInputAccept: [
          ...PictureHelper.PICTURE_FILE_TYPES,
          'application/pdf'
        ].join(','),
        fileInputMultiple: true
      },
      {
        labelTk: 'aureliaComponents.pictureUploadButtons.newSketch',
        name: 'create-sketch'
      }
    ];

    if (isConnected) {
      choices.push({
        labelTk: 'aureliaComponents.pictureUploadButtons.mapPicture',
        name: 'map-picture'
      });
    }

    return choices;
  }
}

export type PicturesCreatedEvent = NamedCustomEvent<
  'pictures-created',
  { pictures: Array<Picture> }
>;
