import { DateUtils } from '../DateUtils';

export class GalleryThingHelper {
  public static getProjectNameForDate(date: Date): string {
    return `$$${DateUtils.getIsoDateWithoutTime(date)}$$`;
  }

  public static getDateFromProjectName(projectName: string): Date {
    const d = new Date(this.getDateStringFromProjectName(projectName));
    return new Date(
      d.getTime() - DateUtils.getNormalizedTimezoneOffsetFromDate(d)
    );
  }

  public static getDateStringFromProjectName(projectName: string): string {
    return projectName.substring(2, projectName.length - 2);
  }
}
