export enum DefectStatus {
  OPEN = 'open',
  PROCESSED = 'processed',
  DONE = 'done'
}

export const getAllDefectStatuses = (): Array<DefectStatus> => {
  return Object.keys(DefectStatus).map(
    (key) => DefectStatus[key as keyof typeof DefectStatus]
  );
};
