import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskGroupFilterEntityInfo } from 'common/Types/Entities/ProcessTaskGroupFilter/createProcessTaskGroupFilterEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskGroupFilter } from './types';

export const processTaskGroupFilterEntityInfo =
  createProcessTaskGroupFilterEntityInfo<ProcessTaskGroupFilter>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
