import { bindable, inject } from 'aurelia-framework';
import { InputElementResizer } from '../classes/DomUtilities/InputElementResizer';

/**
 * attach this to an input element and bind the current value to the inputValue and the input will magically only be as wide as needed
 */
@inject(Element)
export class AutoWidthInputCustomAttribute {
  /**
   * @type {(string|null)}
   */
  @bindable inputValue;

  /**
   *
   * @param {HTMLInputElement} element
   */
  constructor(element) {
    this._element = element;
    this._resizer = new InputElementResizer(element);
  }

  attached() {
    this._attached = true;
    setTimeout(() => {
      this.inputValueChanged();
    });
  }

  detached() {
    this._attached = false;
  }

  inputValueChanged() {
    if (!this._attached) {
      return;
    }

    this._resizer.resizeInput(this.inputValue);
  }
}
