import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { DateUtils } from '../../../../common/src/DateUtils';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { SocketService } from '../../services/SocketService';
import { AuthenticationService } from '../../services/AuthenticationService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { User } from '../../classes/EntityManager/entities/User/types';
import { UserPasswordService } from '../../classes/EntityManager/entities/User/UserPasswordService';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

@autoinject()
export class UserListItem {
  @bindable public user: User | null = null;
  @bindable public currentUser: User | null = null;

  private domElement: HTMLElement;
  private listItemElement: HTMLElement | null = null;
  private readonly subscriptionManager: SubscriptionManager;

  public panelOpen = false;

  private socketService: SocketService;
  private authenticationService: AuthenticationService;
  protected isMobile: boolean = false;

  private DateUtils = DateUtils;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly userPasswordService: UserPasswordService,
    socketService: SocketService,
    authenticationService: AuthenticationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.socketService = socketService;
    this.authenticationService = authenticationService;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  private handleUserChanged(): void {
    if (this.user) this.entityManager.userRepository.update(this.user);
  }

  private handleEditUserClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-user-clicked',
      detail: null
    });
  }

  private handleDeleteUserClick(): void {
    const user = this.user;
    if (!user) return;

    void Dialogs.deleteEntityDialog(user).then(() => {
      this.entityManager.userRepository.delete(user);
    });
  }

  private handleToggleUserActiveStatusClick(): void {
    if (!this.user) return;

    this.user.active = !this.user.active;
    this.handleUserChanged();
  }

  private handleToggleUserAdminStatusClick(): void {
    if (!this.user) return;

    this.user.admin = !this.user.admin;
    this.handleUserChanged();
  }

  private handleSetNewPasswordClick(): void {
    const user = this.user;
    assertNotNullOrUndefined(
      user,
      "can't UserListItem.handleSetNewPasswordClick without a user"
    );

    Dialogs.changePasswordDialog(user.username ?? '', (password) => {
      void this.userPasswordService.updatePassword(user, null, password);
    });
  }

  private handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  @computedFrom('user.id', 'user.active', 'user.admin', 'currentUser')
  private get getUserStatusIconClassName(): string {
    if (this.user && this.user.admin) {
      return 'fa-user-astronaut';
    }
    if (this.user && this.currentUser && this.user.id === this.currentUser.id) {
      return 'fa-user-tie';
    } else if (this.user && this.user.active) {
      return 'fa-user';
    } else {
      return 'fa-user-slash';
    }
  }

  @computedFrom('user.active')
  protected get toggleActiveStatusTk(): string {
    if (this.user?.active) return 'listItems.userListItem.deactivate';
    return 'listItems.userListItem.activate';
  }

  @computedFrom('user.active')
  protected get toggleAdminStatusTk(): string {
    if (this.user?.admin) return 'listItems.userListItem.removeAdminPermission';
    return 'listItems.userListItem.giveAdminPermission';
  }

  private handleImpersonateUserClick(): void {
    if (!this.user) return;

    this.socketService.impersonateUser(
      {
        userId: this.user.id
      },
      (response) => {
        if (response.success) {
          void this.authenticationService.reloginWithToken(
            response.token,
            response.user,
            { navigate: true }
          );
        } else {
          void Dialogs.errorDialogTk(`serverResponses.${response.status}`);
        }
      }
    );
  }
}
