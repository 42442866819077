import { bindable, inject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

/**
 * @event {TOperationsOfferPositionsViewPositionClickedEvent} position-clicked
 */
@inject(I18N)
export class OperationsOfferPositionsView {
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} */
  @bindable positions = [];

  /** @type {string|null} */
  @bindable defaultPositionsCategoryName = null;

  /** @type {I18N} */
  _i18n;

  /** @type {Array<string>} */
  _availableCategoryNames = [];
  /** @type {Array<TOperationsOfferPositionsViewPositionCategory>} */
  _availableCategories = [];
  /** @type {TOperationsOfferPositionsViewPositionCategory|null} */
  @observable _activeCategory;

  /** @type {HTMLElement|null} */
  _domElement = null;
  /** @type {import('../../aureliaComponents/page-switcher/page-switcher').PageSwitcher|null} */
  _pageSwitcher = null;
  _desiredCategorySwitchWidth = 300;

  /**
   * @param {I18N} i18n
   */
  constructor(i18n) {
    this._i18n = i18n;

    this._activeCategory = null;
  }

  attached() {
    this._attached = true;

    this._updateAvailableCategories();
  }

  detached() {
    this._attached = false;
  }

  positionsChanged() {
    if (this._attached) {
      this._updateAvailableCategories();
    }
  }

  defaultPositionsCategoryNameChanged() {
    if (!this._attached) {
      this._autoSetActiveCategory();
    }
  }

  _activeCategoryChanged() {
    if (this._pageSwitcher) {
      this._pageSwitcher.switchToPage(
        this._activeCategory ? this._activeCategory.categoryName : null
      );
    }
  }

  /**
   * @param {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
   * @param {string} categoryName
   * @returns {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>}
   * @private
   */
  _getPositionsForCategoryName(positions, categoryName) {
    return positions.filter((p) => p.categoryName === categoryName);
  }

  _updateAvailableCategories() {
    const categoryNames = this._getCategoryNamesOfPositions(this.positions);
    const categoryNameToPositions = this._getCategoryNameToPositionsMap(
      this.positions
    );

    const categories = categoryNames.map((categoryName) => {
      return {
        categoryName: categoryName,
        groups: this._getPositionGroups(
          categoryNameToPositions[categoryName] || []
        )
      };
    });

    categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName));

    this._availableCategories = InstancePreserver.createNewArray({
      originalArray: this._availableCategories,
      newArray: categories,
      getTrackingValue: (item) => item.categoryName
    });

    this._autoSetActiveCategory();

    setTimeout(() => {
      if (this._attached && this._pageSwitcher) {
        this._pageSwitcher.pageElementsChanged();
        this._pageSwitcher.switchToPage(
          this._activeCategory ? this._activeCategory.categoryName : null
        );
      }
    }, 10);
  }

  _autoSetActiveCategory() {
    if (
      !this._activeCategory ||
      this._availableCategories.indexOf(this._activeCategory) === -1
    ) {
      const index = this._availableCategories.findIndex((category) => {
        return category.categoryName === this.defaultPositionsCategoryName;
      });
      this._activeCategory =
        index >= 0
          ? this._availableCategories[index]
          : this._availableCategories[0];
    }
  }

  /**
   * @param {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
   * @returns {Array<string>}
   * @private
   */
  _getCategoryNamesOfPositions(positions) {
    /** @type {Array<string>} */
    const categorieNames = [];

    positions.forEach((position) => {
      const categoryName =
        position.categoryName ||
        this._i18n.tr(
          'modelsDetail.ProcessConfigurationStepPositionModel.defaultCategoryName'
        );
      if (categorieNames.indexOf(categoryName) === -1) {
        categorieNames.push(categoryName);
      }
    });

    return categorieNames;
  }

  /**
   * @param {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
   * @returns {{[s: string]: Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>}}
   * @private
   */
  _getCategoryNameToPositionsMap(positions) {
    /** @type {{[s: string]: Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>}} */
    const categoryNameToPositions = {};

    positions.forEach((position) => {
      const categoryName =
        position.categoryName ||
        this._i18n.tr(
          'modelsDetail.ProcessConfigurationStepPositionModel.defaultCategoryName'
        );
      if (!categoryNameToPositions[categoryName]) {
        categoryNameToPositions[categoryName] = [];
      }

      categoryNameToPositions[categoryName].push(position);
    });

    return categoryNameToPositions;
  }

  /**
   * @param {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
   * @returns {Array<TOperationsOfferPositionsViewPositionGroup>}
   * @private
   */
  _getPositionGroups(positions) {
    /** @type {Array<TOperationsOfferPositionsViewPositionGroup>} */
    const groups = [];

    positions.forEach((position) => {
      const groupName =
        position.groupName ||
        this._i18n.tr(
          'modelsDetail.ProcessConfigurationStepPositionModel.defaultGroupName'
        );
      let group = groups.find((g) => g.groupName === groupName);

      if (!group) {
        group = {
          groupName: groupName,
          positions: []
        };

        groups.push(group);
      }

      group.positions.push(position);
    });

    this._sortGroups(groups);
    return groups;
  }

  /**
   * @param {Array<TOperationsOfferPositionsViewPositionGroup>} groups
   * @private
   */
  _sortGroups(groups) {
    groups.sort((a, b) => a.groupName.localeCompare(b.groupName));

    groups.forEach((g) => {
      g.positions.sort((a, b) => {
        return (a.name || '').localeCompare(b.name || '');
      });
    });
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition} position
   */
  _handlePositionClick(position) {
    if (!this._domElement) {
      return;
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'position-clicked',
      detail: {
        processConfigurationStepPosition: position
      }
    });
  }
}

/**
 * @typedef {Object} TOperationsOfferPositionsViewPositionCategory
 * @property {string} categoryName
 * @property {Array<TOperationsOfferPositionsViewPositionGroup>} groups
 */

/**
 * @typedef {Object} TOperationsOfferPositionsViewPositionGroup
 * @property {string} groupName
 * @property {Array<import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition>} positions
 */

/**
 * @typedef {import('../../classes/DomEventHelper').NamedCustomEvent<'position-clicked', { processConfigurationStepPosition: import('../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types').ProcessConfigurationStepPosition }>} TOperationsOfferPositionsViewPositionClickedEvent
 */
