import { bindable, autoinject, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { DefectStatus } from 'common/Enums/DefectStatus';
import { TagSortingMode } from 'common/Enums/TagSortingMode';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Dialogs } from '../../classes/Dialogs';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EditDefectDialog } from '../../dialogs/edit-defect-dialog/edit-defect-dialog';
import { EditDefectPicturesDialog } from '../../dialogs/edit-defect-pictures-dialog/edit-defect-pictures-dialog';
import { RoutePlannerService } from '../../services/RoutePlannerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { EditDefectAssignedUserDialog } from '../../dialogs/edit-defect-assigned-user-dialog/edit-defect-assigned-user-dialog';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  TagsByDefectId,
  TagsByDefectIdValueComputer
} from '../../computedValues/computers/TagsByDefectIdValueComputer';
import { SelectPictureDialogDefectRoutePlannerAdapter } from '../../dialogs/select-picture-dialog/SelectPictureDialogAdapter/SelectPictureDialogDefectRoutePlannerAdapter/SelectPictureDialogDefectRoutePlannerAdapter';
import { SelectPictureDialog } from '../../dialogs/select-picture-dialog/select-picture-dialog';
import { LastUsedPictureForIdService } from '../../services/LastUsedEntityService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { TagSorter } from '../../classes/TagSorter';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { Router } from 'aurelia-router';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

/**
 * A defect in a table.
 *
 * Displays the picture, id, title, associatedPerson, tags and dueDate in that order in a row.
 * A small line on the right of the card indicates the status.
 *
 * @event detail-view-button-clicked Fired when the DetailView button of the protocol is clicked.
 */
@autoinject()
export class DefectListItem {
  /**
   * The defect to display.
   *
   * Since associations are stored as ids within defect,
   * the corresponding entities are saved in properties prefixed with "defect".
   */
  @bindable public defect: Defect | null = null;

  /**
   * Whether the defect opens in-line with the list or on a new page.
   *
   * `useInlineView: true` means the user can view the defect by expanding
   * the entity-list-item-more-panel which displays the defect on the same page
   * as the defect list.
   *
   * `useInlineView: false` means that only a button that shows the defect on a
   * new page is shown to the user.
   */
  @bindable public useInlineView = true;

  @bindable public showAssignedUser = true;

  private tagsByDefectId: TagsByDefectId = new Map();
  protected defectAssociatedUser: User | null = null;
  protected defectTags: Array<Tag> = [];
  protected tagNames: Array<String> = [];
  protected defectPictures: Array<Picture> = [];

  /**
   * The name of the currently opened panel.
   * If null, no panel is currently opened.
   *
   * Instead of having multiple boolean toggles for every panel that can be opened (like other components),
   * this enum switch prevents having multiple panels opened by design.
   */
  protected openPanelName: PanelNames | null = null;

  /**
   * For PanelNames enum access in the view.
   */
  protected PanelNames = PanelNames;

  /**
   * For DefectStatus enum access in the view.
   */
  protected DefectStatus = DefectStatus;

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  @subscribableLifecycle()
  protected readonly defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private routePlannerService: RoutePlannerService,
    private currentUserService: CurrentUserService,
    private readonly computedValueService: ComputedValueService,
    private readonly lastUsedPictureForIdService: LastUsedPictureForIdService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly router: Router,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.element = element as HTMLElement;

    this.defectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Defect,
        context: this,
        expression: 'defect'
      });
  }

  // ////////// LIFECYCLE //////////

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateDefectRelations.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateDefectAssociatedUser.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateDefectTags.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateDefectPictures.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: TagsByDefectIdValueComputer,
        callback: (tagsByDefectId) => {
          this.tagsByDefectId = tagsByDefectId;
          this.updateDefectTags();
        },
        computeData: {}
      }),
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateDefectTags();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ////////// METHODS //////////

  /**
   * Toggles a panel.
   * If a panel with this name is already open, it will be closed.
   * If a panel with another name is open, the other panel will be closed and this one will be opened.
   * @param name The panel name.
   */
  private togglePanel(name: PanelNames | null): void {
    if (this.openPanelName === null && name === PanelNames.PROTOCOL) {
      // Protocol Panel is going to be opened
      void ScrollHelper.scrollToItem(this.element, 450, {
        topOffset: 10
      });
    } else if (this.openPanelName === PanelNames.PROTOCOL && name === null) {
      // Protocol Panel is going to be closed
      void ScrollHelper.scrollToPosition(
        ScrollHelper.getMainScrollingElement().scrollTop,
        450
      );
    }
    this.openPanelName = this.openPanelName !== name ? name : null;
  }

  /**
   * Show a delete dialog to the user and delete the defect if they confirm it.
   */
  private async confirmDefectDelete(): Promise<void> {
    assertNotNullOrUndefined(
      this.defect,
      "Can't delete the defect if there's no defect"
    );
    await Dialogs.deleteEntityDialog(this.defect);
    this.entityManager.defectRepository.delete(this.defect);
  }

  private pictureHasCoords(picture: Picture): boolean {
    return (
      !!picture.coords &&
      !!picture.coords.latitude &&
      !!picture.coords.longitude
    );
  }

  // ////////// ENTITY UPDATERS //////////

  private updateDefectRelations(): void {
    this.updateDefectAssociatedUser();
    this.updateDefectPictures();
    this.updateDefectTags();
  }

  private updateDefectAssociatedUser(): void {
    if (!this.defect) {
      this.defectAssociatedUser = null;
      return;
    }
    this.defectAssociatedUser = this.defect.assigneeId
      ? this.entityManager.userRepository.getById(this.defect.assigneeId)
      : null;
  }

  private updateDefectPictures(): void {
    if (this.defect) {
      this.defectPictures =
        this.entityManager.pictureRepository.getByOwnerDefectId(this.defect.id);
    } else {
      this.defectPictures = [];
    }
  }

  private updateDefectTags(): void {
    const defectTags = this.defect
      ? (this.tagsByDefectId.get(this.defect.id) ?? [])
      : [];
    this.defectTags = TagSorter.sortTags(defectTags, this.tagSortingMode);
    this.tagNames = this.defectTags.map((x) => x.name);
  }

  // ////////// OBSERVABLES //////////

  protected defectChanged(): void {
    this.updateDefectRelations();
  }

  // ////////// CLICK HANDLERS //////////

  protected handleMoreButtonClick(): void {
    this.togglePanel(PanelNames.OPTIONS);
  }

  protected handleOpenProtocolButtonClick(): void {
    if (this.useInlineView) {
      this.togglePanel(PanelNames.PROTOCOL);
    } else {
      assertNotNullOrUndefined(
        this.defect,
        'cannot handleOpenProtocolButtonClick without defect'
      );
      void this.router.navigateToRoute('edit_defect', {
        defect_id: this.defect.id
      });
    }
  }

  protected handleEditDefectButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot open EditDefectDialog without defect'
    );
    if (this.useInlineView) {
      void EditDefectDialog.open({
        defect: this.defect
      });
    } else {
      void this.router.navigateToRoute('edit_defect', {
        defect_id: this.defect.id
      });
    }
  }

  protected handleAssignUserButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot open EditDefectAssignedUserDialog without defect'
    );
    void EditDefectAssignedUserDialog.open({
      defectId: this.defect.id
    });
  }

  protected handleEditDefectPicturesButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot open EditDefectDialog without defect'
    );
    void EditDefectPicturesDialog.open({
      thingId: this.defect.ownerThingId,
      defectId: this.defect.id,
      selectedPicture: null
    });
  }

  protected handleCompleteDefectButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot complete defect without defect'
    );
    this.defect.status = DefectStatus.DONE;
    this.entityManager.defectRepository.update(this.defect);
    const currentUser = this.currentUserService.getRequiredCurrentUser();
    this.entityManager.defectCommentRepository.create({
      senderId: currentUser.id,
      statusChange: DefectStatus.DONE,
      ownerDefectId: this.defect.id,
      ownerThingId: this.defect.ownerThingId,
      ownerUserGroupId: this.defect.ownerUserGroupId
    });
  }

  protected handleProcessDefectButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot complete defect without defect'
    );
    this.defect.status = DefectStatus.PROCESSED;
    this.entityManager.defectRepository.update(this.defect);
    const currentUser = this.currentUserService.getRequiredCurrentUser();
    this.entityManager.defectCommentRepository.create({
      senderId: currentUser.id,
      statusChange: DefectStatus.PROCESSED,
      ownerDefectId: this.defect.id,
      ownerThingId: this.defect.ownerThingId,
      ownerUserGroupId: this.defect.ownerUserGroupId
    });
  }

  protected handleDefectRoutePlannerButtonClick(): void {
    assertNotNullOrUndefined(
      this.defect,
      "can't DefectListItem.handleDefectRoutePlannerButtonClick without defect"
    );

    const adapter = new SelectPictureDialogDefectRoutePlannerAdapter({
      defect: this.defect,
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      lastUsedPictureForIdService: this.lastUsedPictureForIdService
    });

    void SelectPictureDialog.open({
      adapter,
      onPictureSelected: ({ picture }) => {
        assertNotNullOrUndefined(
          picture.coords,
          'cannot startRoute without coords of the selected picture'
        );
        assertNotNullOrUndefined(
          picture.coords.latitude,
          'cannot startRoute without latitude of the selected picture'
        );
        assertNotNullOrUndefined(
          picture.coords.longitude,
          'cannot startRoute without longitude of the selected picture'
        );
        this.routePlannerService.startRoute(
          picture.coords.latitude,
          picture.coords.longitude
        );
      }
    });
  }

  protected async handleDeleteDefectButtonClick(): Promise<void> {
    await this.confirmDefectDelete();
  }

  protected handleDetailViewButtonClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'detail-view-button-clicked',
      detail: this.defect
    });
  }

  // ////////// HTML HELPERS //////////

  /**
   * Returns the status of the defect in all lowercase for the css `data-status` selectors.
   */
  @computedFrom('defect.status')
  protected get defectStatus(): string | null {
    return this.defect?.status?.toLowerCase() || null;
  }

  /**
   * Get the translation key for the error states if no user
   * is assigned to the defect or no username has been found.
   */
  @computedFrom('defectAssociatedUser')
  protected get defectAssociatedUserErrorTk(): string | null {
    if (!this.defectAssociatedUser) {
      return 'defectComponents.defectListItem.noAssociatedUserName';
    }
    if (!this.defectAssociatedUser.username) {
      return 'defectComponents.defectListItem.missingAssociatedUserName';
    }
    return null;
  }

  /**
   * Returns a nicely formatted date string of defect.dueAt,
   * or a loading indicator if defect is null.
   */
  @computedFrom('defect.dueAt')
  protected get defectDueAtPretty(): string {
    if (!this.defect) return '...';
    return this.defect.dueAt !== null
      ? DateUtils.formatToDateString(this.defect.dueAt)
      : '';
  }

  @computedFrom('defectPictures')
  protected get mainPicture(): Picture | undefined {
    return this.defectPictures.find((p) => p.selected);
  }

  @computed(
    expression('defect.status'),
    expression('defectPermissionsHandle.canSetDefectStatusToDone')
  )
  protected get showCompleteDefectButton(): boolean {
    return (
      this.defectPermissionsHandle.canSetDefectStatusToDone &&
      this.defect?.status !== DefectStatus.DONE
    );
  }

  @computed(
    expression('defect.status'),
    expression('defectPermissionsHandle.canSetDefectStatusToProcessed')
  )
  protected get showProcessDefectButton(): boolean {
    return (
      this.defectPermissionsHandle.canSetDefectStatusToProcessed &&
      this.defect?.status === DefectStatus.OPEN
    );
  }

  @computed(expression('defect.ownerThingId'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.defect?.ownerThingId) return null;
    return this.entityManager.thingRepository.getById(this.defect.ownerThingId);
  }

  @computed(expression('thing.thingGroupId'), model(EntityName.ThingGroup))
  protected get thingGroup(): ThingGroup | null {
    if (!this.thing?.thingGroupId) return null;
    return this.entityManager.thingGroupRepository.getById(
      this.thing.thingGroupId
    );
  }
}

/**
 * The available names of foldable panels for the list item.
 */
enum PanelNames {
  PROTOCOL = 'protocol',
  OPTIONS = 'options'
}
