import { PersonContact } from './types';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';

export namespace PersonContactUtils {
  /**
   * Returns whether `personContact` is of a given `type`.
   */
  export function isOfType(
    type: PersonContactType
  ): (personContact: PersonContact) => boolean {
    return (pc) => pc.contactType === type;
  }

  /**
   * Compares two person contacts and returns a comparison number such that highlighted contacts are sorted before others,
   * otherwise retains the original order.
   */
  export function orderWithHighlightedFirst(
    a: PersonContact,
    b: PersonContact
  ): number {
    if (a.highlighted && b.highlighted) return 0;
    if (a.highlighted) return -1;
    if (b.highlighted) return 1;
    return 0;
  }
}
