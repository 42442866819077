import { inject, bindable } from 'aurelia-framework';

import { Vector } from '../../../../common/src/Geometry/Vector';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Key } from '../../classes/Key';

/**
 * @event select-changed - the selection has been changed
 * @event opening-dropdown - the dropdown starts to open
 * @event closing-dropdown - the dropdown started closing
 * @event closed-dropdown - the dropdown has been closed
 *
 * @attribute data-status - styles the input accordingly to its status, possible values: warning
 */
@inject(Element)
export class CustomMultiSelect {
  /** @type {Array<any>} */
  @bindable selectedValues = [];

  @bindable enabled = false;

  /** @type {Array<string|Object>} */
  @bindable options = [];

  /** @type {string|null} */
  @bindable optionsValuePath = null;

  /** @type {string|null} */
  @bindable optionsLabelPath = null;

  @bindable placeholder = '';

  /** @type {HTMLElement} */
  _domElement;

  /** @type {HTMLElement|null} */
  _wrapperElement = null;

  /** @type {import('../../aureliaComponents/tooltip-content/tooltip-content').TooltipContent|null} */
  _tooltipContentViewModel = null;

  /**
   * @typedef {Object} TOption
   * @property {string} label
   * @property {any} value
   * @property {boolean} selected
   * @property {any} [option]
   */

  /** @type {Array<TOption>} */
  _availableOptions = [];

  /** @type {any} */
  _highlightedOptionValue = null;

  _marginVector = new Vector(0, 0);

  _tooltipContentOpen = false;

  _selectionIsFocused = false;
  _selectionText = '';
  /** @type {HTMLElement|null} */
  _selectionElement = null;

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._domElement = element;
  }

  attached() {
    if (this._wrapperElement)
      this._inputBackgroundAnimator = new ShowHideAnimator(
        this._wrapperElement
      );
  }

  focus() {
    if (this._selectionElement) {
      this._selectionElement.focus();
    }
  }

  selectedValuesChanged() {
    this._updateAvailableOptions();
    this._updateSelectionText();
  }

  optionsChanged() {
    this._updateAvailableOptions();
  }

  optionsValuePathChanged() {
    this._updateAvailableOptions();
  }

  optionsLabelPathChanged() {
    this._updateAvailableOptions();
  }

  placeholderChanged() {
    this._updateSelectionText();
  }

  /**
   * @param {KeyboardEvent} event
   */
  _handleKeyDown(event) {
    switch (event.key) {
      case Key.ESCAPE:
        this._closeTooltipContent();
        return false;
      case Key.SPACE:
      case Key.ENTER:
        if (this._tooltipContentOpen) {
          const option = this._getOptionForValue(this._highlightedOptionValue);
          option.selected = !option.selected;
          this._handleClickOnOption(option);
        }
        return false;
      case Key.TAB:
        this._closeTooltipContent();
        return true;
      case Key.SHIFT:
        return true;
      case Key.ARROW_UP:
        this._openTooltipContent();
        this._selectPreviousOption();
        return false;
      case Key.ARROW_DOWN:
        this._openTooltipContent();
        this._selectNextOption();
        return false;
      case Key.HOME:
        this._openTooltipContent();
        this._selectFirstOption();
        return false;
      case Key.END:
        this._openTooltipContent();
        this._selectLastOption();
        return false;
      default:
        return true;
    }
  }

  _selectNextOption() {
    if (this._availableOptions.length === 0) return;

    const index = this._availableOptions.findIndex(
      (o) => o.value === this._highlightedOptionValue
    );

    if (index < 0) {
      this._highlightedOptionValue = this._availableOptions[0].value;
    } else if (index < this._availableOptions.length - 1) {
      this._highlightedOptionValue = this._availableOptions[index + 1].value;
    }
  }

  _selectPreviousOption() {
    if (this._availableOptions.length === 0) return;

    const index = this._availableOptions.findIndex(
      (o) => o.value === this._highlightedOptionValue
    );

    if (index < 0) {
      this._highlightedOptionValue =
        this._availableOptions[this._availableOptions.length - 1].value;
    } else if (index - 1 >= 0) {
      this._highlightedOptionValue = this._availableOptions[index - 1].value;
    }
  }

  _selectFirstOption() {
    if (this._availableOptions.length === 0) return;
    this._highlightedOptionValue = this._availableOptions[0].value;
  }

  _selectLastOption() {
    if (this._availableOptions.length === 0) return;
    this._highlightedOptionValue =
      this._availableOptions[this._availableOptions.length - 1].value;
  }

  _handleClickOnSelectWrapper() {
    this._toggleTooltipContent();
  }

  _handleTooltipContentStartOpening() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'opening-dropdown',
      detail: null
    });
  }

  _handleTooltipContentIsClosing() {
    if (this._inputBackgroundAnimator) {
      this._inputBackgroundAnimator.animateBackgroundColor(null, {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 0
      });
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'closing-dropdown',
      detail: null
    });
  }

  _handleTooltipContentClosed() {
    this._tooltipContentOpen = false;

    DomEventHelper.fireEvent(this._domElement, {
      name: 'closed-dropdown',
      detail: null
    });
  }

  /**
   * @param {any} value
   * @returns {TOption}
   */
  _getOptionForValue(value) {
    const option = this._availableOptions.find((o) => o.value === value);
    if (!option) {
      throw new Error('option not found');
    }

    return option;
  }

  /**
   * @param {TOption} option
   */
  _handleClickOnOption(option) {
    /** @type {Array<any>} */
    const selectedValues = this.selectedValues
      ? this.selectedValues.slice()
      : [];

    const index = selectedValues.findIndex((value) => {
      return value === option.value;
    });

    if (index < 0) {
      if (option.selected) selectedValues.push(option.value);
    } else {
      if (!option.selected) selectedValues.splice(index, 1);
    }

    this.selectedValues = selectedValues;
    setTimeout(() => {
      this._fireSelectChangedEvent();
    }, 0);
  }

  /**
   * @param {TOption} option
   */
  _handleMouseOverOption(option) {
    this._highlightedOptionValue = option.value;
  }

  _toggleTooltipContent() {
    if (this._tooltipContentOpen) {
      this._closeTooltipContent();
    } else {
      this._openTooltipContent();
    }
  }

  _closeTooltipContent() {
    if (this._tooltipContentViewModel) {
      this._tooltipContentViewModel.close();
      this._tooltipContentOpen = false;
    }
  }

  _openTooltipContent() {
    if (this._inputBackgroundAnimator) {
      this._inputBackgroundAnimator.animateBackgroundColor(null, {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 255
      });
    }
    if (this._tooltipContentViewModel) {
      this._tooltipContentViewModel.open();
      this._tooltipContentOpen = true;
    }
  }

  _updateAvailableOptions() {
    /** @type {Array<TOption>} */
    const options = [];

    this.options &&
      this.options.forEach((option) => {
        const label = String(
          this.optionsLabelPath ? option[this.optionsLabelPath] : option
        );
        const optionValue = this.optionsValuePath
          ? option[this.optionsValuePath]
          : option;
        const selected = !!(
          this.selectedValues &&
          this.selectedValues.find((value) => value === optionValue)
        );
        options.push({
          label: label,
          value: this.optionsValuePath ? option[this.optionsValuePath] : option,
          selected: selected,
          option: option
        });
      });

    this._availableOptions = options;
    this._updateSelectionText();
  }

  _updateSelectionText() {
    if (!this.selectedValues || this.selectedValues.length === 0) {
      this._selectionText = this.placeholder;
      return;
    } else {
      this._selectionText =
        this.selectedValues.length > 1
          ? `(${this.selectedValues.length}) `
          : '';

      const labels = this.selectedValues.map((value) => {
        const selectedOption = this._availableOptions.find(
          (o) => o.value === value
        );
        return selectedOption ? selectedOption.label : '';
      });
      const labelString = labels.join(', ');
      this._selectionText += labelString;
    }
  }

  _fireSelectChangedEvent() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'select-changed',
      detail: null
    });
  }
}
