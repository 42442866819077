import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Key } from '../../classes/Key';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * @event {StateChangedEvent} state-changed
 *
 * @attribute data-narrow - give the checkbox a tighter styling, useful for e.g. when display the checkbox without a label
 * @attribute data-label-style - 'bold'
 * @attribute data-border-light - makes border of checkbox thinner (only 1px)
 * @attribute data-fill-label - makes the label fill the width, allows clicking on the whole area
 * @attribute data-align-left - aligns the checkbox so it touches the left border of the container
 */
@autoinject()
export class MultistateCheckbox<T> {
  @bindable public states: Array<StateConfig<T>> = [];

  @bindable public value: T | null = null;

  @bindable public enabled = false;

  @bindable public labelTk = '';

  /** @deprecated use labelTk instead */
  @bindable public label = '';

  @bindable public dataNarrow: any;
  @bindable public dataLabelStyle?: 'bold';
  @bindable public dataBorderLight: any;
  @bindable public dataFillLabel: any;
  @bindable public dataAlignLeft: any;

  private domElement: HTMLElement;

  protected currentState: StateConfig<T> | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected statesChanged(): void {
    this.updateCurrentState();
  }

  protected valueChanged(): void {
    this.updateCurrentState();
  }

  private updateCurrentState(): void {
    const stateConfig = this.states.find((s) => s.value === this.value);
    if (stateConfig) {
      this.currentState = stateConfig;
    }
  }

  protected handleKeydown(event: KeyboardEvent): boolean {
    if (!this.enabled) {
      return true;
    }

    switch (event.key) {
      case Key.SPACE:
      case Key.ENTER:
        this.toggleState();
        return false;

      default:
        return true;
    }
  }

  protected handleInputClicked(): void {
    if (this.enabled) {
      this.toggleState();
    }
  }

  private toggleState(): void {
    const index = this.states.findIndex(
      (s) => s.value === this.currentState?.value
    );

    let newState = null;
    if (index >= 0 && index + 1 < this.states.length) {
      newState = this.states[index + 1];
    } else {
      newState = this.states[0];
    }

    this.value = newState?.value ?? null;
    this.fireEvent();
  }

  @computed(
    expression('inputHelperBackgroundColor'),
    expression('inputHelperBorderColor')
  )
  protected get inputHelperStyle(): InputStyle {
    return {
      'background-color': this.inputHelperBackgroundColor,
      'border-color': this.inputHelperBorderColor
    };
  }

  @computed(expression('checkMarkColor'))
  protected get checkMarkStyle(): CheckmarkStyle {
    return {
      color: this.checkMarkColor
    };
  }

  @computed(expression('currentState'), expression('enabled'))
  private get inputHelperBackgroundColor(): string | null {
    if (!this.enabled) {
      return this.currentState?.iconConfig ? '#e3e3e3' : null;
    }

    if (this.currentState?.iconConfig) {
      return (
        this.currentState.iconConfig.backgroundColor ??
        'var(--record-it-color-primary)'
      );
    }

    return null;
  }

  @computed(expression('currentState'), expression('enabled'))
  private get inputHelperBorderColor(): string | null {
    if (!this.enabled) {
      return '#cacaca';
    }

    if (this.currentState?.iconConfig) {
      return (
        this.currentState.borderColor ??
        this.currentState.iconConfig.backgroundColor ??
        'var(--record-it-color-primary)'
      );
    }

    return this.currentState?.borderColor ?? null;
  }

  @computed(expression('currentState'), expression('enabled'))
  private get checkMarkColor(): string | null {
    if (!this.enabled) {
      return '#cacaca';
    }

    if (this.currentState?.iconConfig) {
      return (
        this.currentState.iconConfig.color ?? 'var(--record-it-color-white)'
      );
    }

    return null;
  }

  private fireEvent(): void {
    DomEventHelper.fireEvent<ValueChangedEvent<T>>(this.domElement, {
      name: 'value-changed',
      bubbles: true,
      detail: {
        value: this.value
      }
    });
  }
}

export type StateConfig<T> = {
  iconConfig?: {
    iconType: string;
    iconName: string;
    color?: string;
    backgroundColor?: string;
  };
  borderColor?: string;
  name: string;
  value: T;
};

type InputStyle = {
  'background-color': string | null;
  'border-color': string | null;
};

type CheckmarkStyle = {
  color: string | null;
};

export type ValueChangedEvent<T> = NamedCustomEvent<
  'value-changed',
  { value: T | null }
>;
