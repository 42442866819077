import { autoinject } from 'aurelia-framework';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * Button for switching between multiple cameras.
 *
 * @see ultra-rapid-fire-widget for usage
 *
 * @event switch-camera
 */
@autoinject()
export class UrfwSwitchCameraButton {
  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleSwitchCameraClick(): void {
    DomEventHelper.fireEvent<SwitchCameraEvent>(this.domElement, {
      name: 'switch-camera',
      detail: null
    });
  }
}

export type SwitchCameraEvent = NamedCustomEvent<'switch-camera', null>;
