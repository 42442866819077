import { autoinject, bindable } from 'aurelia-framework';

import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingProperty } from '../../classes/EntityManager/entities/Property/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ThingType } from '../../classes/EntityManager/entities/ThingType/types';
import { EditThingPropertiesDialog } from '../../dialogs/edit-thing-properties-dialog/edit-thing-properties-dialog';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { MoreButtonChoice } from '../more-button/more-button';
import { EditThingSectionConfigPropertiesDialog } from '../../thingSectionComponents/edit-thing-section-config-properties-dialog/edit-thing-section-config-properties-dialog';
import { ImportFromCsvFileDialog } from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { FieldType } from '../csv-import-widget/csv-import-widget';
import {
  ThingSectionCsvImporterService,
  FieldInfoConfiguration as ThingSectionFieldInfoConfiguration
} from '../../services/ThingSectionCsvImporterService/ThingSectionCsvImporterService';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { ValueCalculationConfigCreationService } from '../../classes/EntityManager/entities/ValueCalculationConfig/ValueCalculationConfigCreationService';
import { ValueCalculationConfigType } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ThingProperties {
  @bindable public thing: Thing | null = null;
  @bindable public editable = false;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private currentThingTypeHasBeenAutomaticallySelected = false;

  protected properties: Array<ThingProperty> = [];

  protected thingTypes: Array<ThingType> = [];
  protected currentThingType: ThingType | null = null;
  protected selectedThingType: ThingType | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected PropertyType = PropertyType;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly applyPropertiesService: ApplyPropertiesService,
    permissionsService: PermissionsService,
    private readonly thingSectionCsvImporterService: ThingSectionCsvImporterService,
    private readonly valueCalculationConfigCreationService: ValueCalculationConfigCreationService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingType,
      () => {
        this.updateThingTypes();
        this.updateCurrentThingType();
      }
    );
    this.updateThingTypes();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToExpression(
      this,
      'thing.thing_type',
      this.updateCurrentThingType.bind(this)
    );
    this.updateCurrentThingType();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateThingTypes(): void {
    this.thingTypes = this.entityManager.thingTypeRepository.getAll();
  }

  private updateProperties(): void {
    if (this.thing) {
      this.properties =
        this.entityManager.propertyRepository.getGroupedPropertiesByThingId(
          this.thing.id
        );
    } else {
      this.properties = [];
    }
  }

  private updateCurrentThingType(): void {
    this.currentThingType = this.thing?.thing_type
      ? this.entityManager.thingTypeRepository.getById(this.thing.thing_type)
      : null;

    if (
      this.currentThingType &&
      !this.currentThingTypeHasBeenAutomaticallySelected
    ) {
      this.selectedThingType = this.currentThingType;
      this.currentThingTypeHasBeenAutomaticallySelected = true;
    }
  }

  protected handleEditPropertiesClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot edit thing properties without a thing'
    );

    void EditThingPropertiesDialog.open({
      thing: this.thing
    });
  }

  protected handleSetThingTypeClick(): void {
    if (!this.thing) return;

    // TODO maybe same loading overlay as in 'import_edit_project'
    if (this.selectedThingType) {
      this.applyPropertiesService.applyThingTypePropertiesToThing(
        this.selectedThingType,
        this.thing
      );
      this.copyValueCalculationConfigsOfThingType(
        this.selectedThingType,
        this.thing
      );
    }

    this.thing.thing_type = this.selectedThingType?.id ?? null;
    this.entityManager.thingRepository.update(this.thing);
  }

  protected handleManageThingSectionPropertiesClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingProperties.handleManageThingSectionPropertiesClicked without thing"
    );

    void EditThingSectionConfigPropertiesDialog.open({
      thing: this.thing
    });
  }

  protected handleImportThingSectionsFromCsvFileChanged(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (!file) return;

    void ImportFromCsvFileDialog.open<ThingSectionFieldInfoConfiguration>({
      file,
      entityNameTk: 'models.ThingSectionModel_plural',
      fields: [
        {
          field: 'name',
          type: FieldType.STRING,
          header: 'Name',
          required: true
        },
        {
          field: 'order',
          type: FieldType.NUMBER,
          header: 'Position'
        }
      ],
      importFromCsvFileCallback: async (details) => {
        const thing = this.thing;
        assertNotNullOrUndefined(
          thing,
          'cannot import thing sections without a thing'
        );

        this.thingSectionCsvImporterService.importFromCsvFile({
          thing,
          overwrite: details.overwrite,
          parsedContent: details.parsedContent
        });
      },
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: false,
      showOverwriteCheckbox: true
    });
  }

  protected thingChanged(): void {
    this.updateProperties();
  }

  @computed(expression('permissionsHandle.canCreateThingSections'))
  protected get thingSectionMoreButtonChoices(): Array<MoreButtonChoice> {
    const choices: Array<MoreButtonChoice> = [
      {
        labelTk:
          'aureliaComponents.thingProperties.manageThingSectionProperties',
        name: 'manage-thing-section-properties'
      }
    ];

    if (this.thingPermissionsHandle.canCreateThingSections) {
      choices.push({
        labelTk:
          'aureliaComponents.thingProperties.importThingSectionsFromCsvFile',
        name: 'import-thing-sections-from-csv-file',
        iconClass: 'fal fa-file-csv',
        isFileInput: true,
        fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
      });
    }

    return choices;
  }

  private copyValueCalculationConfigsOfThingType(
    thingType: ThingType,
    thing: Thing
  ): void {
    const configs =
      this.entityManager.valueCalculationConfigRepository.getByThingTypeId(
        thingType.id
      );

    for (const c of configs) {
      if (c.type === ValueCalculationConfigType.THING) {
        this.valueCalculationConfigCreationService.createOrUpdateThingValueCalculationConfig(
          c,
          thing
        );
        continue;
      }
      if (c.type === ValueCalculationConfigType.PROJECT) {
        this.valueCalculationConfigCreationService.createOrUpdateProjectValueCalculationConfig(
          c,
          thing
        );
        continue;
      }
    }
  }
}
