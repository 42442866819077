import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { CurrentItemChangedEvent } from '../../aureliaComponents/swipeable-container/swipeable-container';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { Utils } from '../../classes/Utils/Utils';

/**
 * a simple layout which will provide a picture selection on the left side and a configurable content
 *
 * @slot picture-selection-top - sits on top of the picture-selection, the content will be positioned in a column flex layout, so keep that in mind. Will not be shown in the mobile layout.
 * @slot main-content - inside a swipeable-container
 * @slot right-content - the content will be positioned in a column flex layout, so keep that in mind. Will not be shown in the mobile layout.
 * @slot mobile-top-content - will only be shown in the mobile layout
 * @slot mobile-bottom-content - will only be shown in the mobile layout
 *
 * @replaceable picture-selection-custom-content - available variables: picture, useful for displaying additional information in the preview of the images. The content needs to be positioned absolutely
 *
 * @event {FilesDroppedEvent} files-dropped
 */
@autoinject()
export class PictureWithSelectionLayout {
  @bindable()
  public pictures: Array<Picture> = [];

  @bindable()
  public selectedPicture: Picture | null = null;

  @bindable()
  public swipingEnabled: boolean = true;

  @bindable()
  public fileDroppingEnabled: boolean = false;

  /**
   * makes the right-content only as wide as it's content
   */
  @bindable()
  public thinRightContent: boolean = false;

  /**
   * display an orange border for select pictures in the selection
   */
  @bindable()
  public displayPictureSelectedStatus: boolean = true;

  private domElement: HTMLElement;
  private scrollToPictureDebounced: (picture: Picture) => void;
  private internalSelectedPicture: Picture | null = null;
  private nextAutoScrollTargetPicture: Picture | null = null;
  private pictureSelectionElement: HTMLElement | null = null;
  private showDragOverFileInputElement: boolean = false;
  private dragOverFileInputElement: HTMLInputElement | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
    this.scrollToPictureDebounced = Utils.debounceFunction(
      this.scrollToPicture.bind(this),
      250
    );
  }

  protected attached(): void {
    if (this.nextAutoScrollTargetPicture) {
      this.scrollToPicture(this.nextAutoScrollTargetPicture);
    }
  }

  private selectedPictureChanged(): void {
    if (this.selectedPicture !== this.internalSelectedPicture) {
      this.internalSelectedPicture = this.selectedPicture;

      if (this.selectedPicture) {
        this.scrollToPictureDebounced(this.selectedPicture);
      }
    }
  }

  private picturesChanged(): void {
    if (this.nextAutoScrollTargetPicture) {
      this.scrollToPicture(this.nextAutoScrollTargetPicture);
    }
  }

  private handlePictureSelectionPictureClick(picture: Picture): void {
    this.setSelectedPicture(picture);
  }

  private handleCurrentSwipeableContainerItemChanged(
    event: CurrentItemChangedEvent<Picture>
  ): void {
    if (event.detail.currentItem) {
      this.setSelectedPicture(event.detail.currentItem);
    }
  }

  private handleFileInputChange(): void {
    assertNotNullOrUndefined(
      this.dragOverFileInputElement,
      "can't PictureWithSelectionLayout.handleFileInputChanged without a dragOverFileInputElement"
    );
    assertNotNullOrUndefined(
      this.dragOverFileInputElement.files,
      "can't PictureWithSelectionLayout.handleFileInputChanged without files"
    );

    this.showDragOverFileInputElement = false;

    const files = Array.from(this.dragOverFileInputElement.files);

    DomEventHelper.fireEvent<FilesDroppedEvent>(this.domElement, {
      name: 'files-dropped',
      detail: {
        files: files
      }
    });

    this.dragOverFileInputElement.value = '';
  }

  private setSelectedPicture(picture: Picture): void {
    this.internalSelectedPicture = picture;
    this.selectedPicture = picture;
    if (picture) {
      this.scrollToPictureDebounced(picture);
    }
  }

  private scrollToPicture(picture: Picture): void {
    const pictureElement: HTMLElement | null =
      this.pictureSelectionElement?.querySelector(
        `#pictureWithSelectionLayoutPicture-${picture.id}`
      ) ?? null;
    if (!pictureElement || !this.pictureSelectionElement) {
      this.nextAutoScrollTargetPicture = picture;
      return;
    }

    const selectionOffset = Utils.getElementOffset(
      this.pictureSelectionElement
    );
    const pictureOffset = Utils.getElementOffset(pictureElement);

    const selectionComputed = window.getComputedStyle(
      this.pictureSelectionElement
    );
    const selectionHeight =
      selectionComputed.height != null ? parseInt(selectionComputed.height) : 0;
    const pictureComputed = window.getComputedStyle(pictureElement);
    const pictureHeight =
      pictureComputed.height != null ? parseInt(pictureComputed.height) : 0;

    ScrollHelper.scrollElement(
      this.pictureSelectionElement,
      'top',
      pictureOffset.top -
        selectionOffset.top -
        selectionHeight / 2 +
        pictureHeight / 2
    );
    this.nextAutoScrollTargetPicture = null;
  }

  private handleDragEnter(): void {
    if (this.fileDroppingEnabled) {
      this.showDragOverFileInputElement = true;
    }
  }

  private handleDragLeave(): void {
    this.showDragOverFileInputElement = false;
  }
}

export type FilesDroppedEvent = NamedCustomEvent<
  'files-dropped',
  { files: Array<File> }
>;
