import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepAutoAppointment } from '../../classes/EntityManager/entities/ProcessConfigurationStepAutoAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationStepAutoAppointmentsWidget {
  @bindable()
  public processConfigurationStep: ProcessConfigurationStep | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationStepPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStep];

  protected autoAppointments: Array<ProcessConfigurationStepAutoAppointment> =
    [];

  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.currentUserService = currentUserService;

    this.processConfigurationStepPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationStep,
        context: this as ProcessConfigurationStepAutoAppointmentsWidget,
        propertyName: 'processConfigurationStep'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStepAutoAppointment,
      this.updateAutoAppointments.bind(this)
    );
    this.updateAutoAppointments();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationStepChanged(): void {
    if (this.isAttached) {
      this.updateAutoAppointments();
    }
  }

  private updateAutoAppointments(): void {
    if (this.processConfigurationStep) {
      this.autoAppointments =
        this.entityManager.processConfigurationStepAutoAppointmentRepository.getByProcessConfigurationStepId(
          this.processConfigurationStep.id
        );
    } else {
      this.autoAppointments = [];
    }
  }

  protected handleAddAutoAppointmentClick(): void {
    if (!this.processConfigurationStep) {
      throw new Error(
        "can't add an auto appointment without a processConfigurationStep"
      );
    }

    const user = this.currentUserService.getRequiredCurrentUser();

    this.entityManager.processConfigurationStepAutoAppointmentRepository.create(
      {
        processConfigurationStepId: this.processConfigurationStep.id,
        ownerProcessConfigurationId:
          this.processConfigurationStep.ownerProcessConfigurationId,
        defaultUserId: user.id,
        ownerUserGroupId: this.processConfigurationStep.ownerUserGroupId,
        temporaryGroupName: this.processConfigurationStep.temporaryGroupName
      }
    );

    this.updateAutoAppointments();
  }
}
