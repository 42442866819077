import { GalleryThingBaseMapMarkerHelper } from 'common/GalleryThing/GalleryThingBaseMapMarkerHelper';

import { PropertyCache } from '../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { MapMarker } from '../../map/basemap-map/basemap-map';
import {
  GalleryThingPictureOverviewEntry,
  OnBaseMapMarkerClicked
} from './GalleryThingPictureOverviewEntryHelper';

export class GalleryThingBaseMapMarkerGenerator {
  public static generateMapMarker(
    overviewEntry: GalleryThingPictureOverviewEntry,
    propertyCache: PropertyCache | null,
    onMarkerClicked: OnBaseMapMarkerClicked
  ): MapMarker | null {
    if (
      !propertyCache ||
      !overviewEntry.coords ||
      !overviewEntry.coords.latitude ||
      !overviewEntry.coords.longitude
    )
      return null;

    const color = GalleryThingBaseMapMarkerHelper.getMarkerColor(
      {
        getEntryPropertyValue:
          propertyCache.getEntryPropertyValueIncludingFalsyValues.bind(
            propertyCache
          )
      },
      overviewEntry
    );
    if (!color) return null;
    const marker = new MapMarker(
      overviewEntry.coords.latitude,
      overviewEntry.coords.longitude,
      color
    );
    marker.onClick(() => {
      onMarkerClicked(overviewEntry);
    }, null);

    return marker;
  }
}
