import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { ManageValueCalculationConfigDefinitionsWidgetAdapter } from './ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetAdapter/ManageValueCalculationConfigDefinitionsWidgetAdapter';
import { ValueCalculationConfigDefinitionWidgetHandle } from '../value-calculation-config-definition-widget/ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * A widget to update/add and delete definitions of properties for some entity.
 * For example, this is used to define the properties of thingTypes so they can later be applied to the thing when assigning the thingType.
 */
export class ManageValueCalculationConfigDefinitionsWidget<
  TValueCalculationConfigDefinition
> {
  @bindable()
  public adapter: ManageValueCalculationConfigDefinitionsWidgetAdapter<TValueCalculationConfigDefinition> | null =
    null;

  private adapterDisposable: Disposable | null = null;
  private isAttached: boolean = false;
  protected valueCalculationConfigDefinitionHandles: Array<
    ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition>
  > = [];
  protected reportTypes: Array<ReportType> = [];
  protected canCreateValueCalculationConfigDefinitions: boolean = false;

  protected attached(): void {
    this.isAttached = true;

    this.subscribeToAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.unsubscribeFromAdapter();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.subscribeToAdapter();
    }
  }

  private subscribeToAdapter(): void {
    this.unsubscribeFromAdapter();

    if (this.adapter) {
      this.adapterDisposable = this.adapter.subscribe({
        setValueCalculationConfigDefinitionHandles: (
          valueCalculationConfigDefinitionHandles
        ) => {
          this.valueCalculationConfigDefinitionHandles =
            valueCalculationConfigDefinitionHandles;
        },
        setReportTypes: (reportTypes) => {
          this.reportTypes = reportTypes;
        },
        setCanCreateValueCalculationConfigDefinitions: (
          canCreateValueCalculationConfigDefinitions
        ) => {
          this.canCreateValueCalculationConfigDefinitions =
            canCreateValueCalculationConfigDefinitions;
        }
      });
    }
  }

  private unsubscribeFromAdapter(): void {
    this.adapterDisposable?.dispose();
    this.adapterDisposable = null;
    this.valueCalculationConfigDefinitionHandles = [];
    this.reportTypes = [];
    this.canCreateValueCalculationConfigDefinitions = false;
  }

  protected handleDeleteHandleClick(
    handle: ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition>
  ): void {
    assertNotNullOrUndefined(
      this.adapter,
      "can't ManageValueCalculationConfigDefinitionsWidget.handleDeleteHandleClick without adapter"
    );

    void this.adapter
      .deleteValueCalculationConfigDefinition({
        valueCalculationConfigDefinition: handle.getValueCalculationDefinition()
      })
      .then(() => {
        this.valueCalculationConfigDefinitionHandles =
          this.valueCalculationConfigDefinitionHandles.filter(
            (h) => h !== handle
          );
      });
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.adapter,
      "can't ManageValueCalculationConfigDefinitionsWidget.handleAddButtonClick without adapter"
    );

    this.valueCalculationConfigDefinitionHandles.push(
      this.adapter.createValueCalculationConfigDefinition()
    );
  }

  @computed(expression('valueCalculationConfigDefinitionHandles'))
  protected get showInEntityOverviewIsSet(): boolean {
    return this.valueCalculationConfigDefinitionHandles.some(
      (x) => x.showInEntityOverview
    );
  }
}
