import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyPriorityWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyPriorityWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyPriorityWidget doesn't support focus");
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<string, string>
  ): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyPriorityWidget.handleSelectChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: event.detail.value,
      customChoice: null
    });
  }
}

export const basePropertyPriorityWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
