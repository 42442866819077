export class DefaultFetchServiceResponse {
  constructor(private readonly response: Response) {}

  public get status(): number {
    return this.response.status;
  }

  public toText(): Promise<string> {
    return this.response.text();
  }

  public toBlob(): Promise<Blob> {
    return this.response.blob();
  }
}
