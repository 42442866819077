import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { PersonContact } from '../../../classes/EntityManager/entities/PersonContact/types';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Dialogs } from '../../../classes/Dialogs';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditPersonContactsWidgetContactListItem {
  @bindable()
  public personContact: PersonContact | null = null;

  @subscribableLifecycle()
  protected readonly personContactPermissionsHandle: EntityNameToPermissionsHandle[EntityName.PersonContact];

  protected readonly contactTypeDefinitions: Array<PersonContactOption> = [
    {
      contactType: PersonContactType.PHONE,
      labelTk: 'modelsDetail.PersonContactModel.phone'
    },
    {
      contactType: PersonContactType.EMAIL,
      labelTk: 'modelsDetail.PersonContactModel.email'
    }
  ];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.personContactPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.PersonContact,
        context: this as EditPersonContactsWidgetContactListItem,
        propertyName: 'personContact'
      });
  }

  protected handleContactChanged(): void {
    assertNotNullOrUndefined(
      this.personContact,
      "can't EditPersonContactsWidgetContactListItem.handleContactChanged without personContact"
    );

    this.entityManager.personContactRepository.update(this.personContact);
  }

  protected handleDeleteContactClick(): void {
    const personContact = this.personContact;
    assertNotNullOrUndefined(
      personContact,
      "can't EditPersonContactsWidgetContactListItem.handleDeleteContactClick without personContact"
    );

    void Dialogs.deleteEntityDialog(personContact).then(() => {
      this.entityManager.personContactRepository.delete(personContact);
    });
  }
}

type PersonContactOption = {
  contactType: PersonContactType;
  labelTk: string;
};
