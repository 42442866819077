import { Vector } from 'common/Geometry/Vector';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { ElementSize } from '../../aureliaAttributes/ResizeObserverCustomAttribute';

import { PointerEventHelper } from '../../classes/PointerEventHelper';

export class ZoomBoxCalculationHelper {
  constructor(
    private readonly zoom: number,
    private readonly scrollContainer: HTMLElement | null,
    private readonly scrollContainerSize: ElementSize
  ) {}

  /**
   * @returns - a new instance with the new zoom
   */
  public setZoom(zoom: number): ZoomBoxCalculationHelper {
    return new ZoomBoxCalculationHelper(
      zoom,
      this.scrollContainer,
      this.scrollContainerSize
    );
  }

  /**
   * @returns - a new instance with the new scrollContainerSize
   */
  public setScrollContainerSize(
    scrollContainerSize: ElementSize
  ): ZoomBoxCalculationHelper {
    return new ZoomBoxCalculationHelper(
      this.zoom,
      this.scrollContainer,
      scrollContainerSize
    );
  }

  /**
   * @returns - a new instance with the new scrollContainerSize
   */
  public setScrollContainer(
    scrollContainer: HTMLElement
  ): ZoomBoxCalculationHelper {
    return new ZoomBoxCalculationHelper(
      this.zoom,
      scrollContainer,
      this.scrollContainerSize
    );
  }

  /**
   * returns the point of the content which is currently displayed at the given containerPoint
   */
  public getContentPointForContainerPoint(
    containerPoint: Vector,
    translationVector: Vector
  ): Vector {
    const containerSize = this.getScrollContainerSizeVector();
    const viewPort = this.getScaledViewPortSize();
    const translateRatio = this.getTranslationRatio(translationVector);

    return translateRatio
      .clone()
      .multiplyVector(containerSize.clone().substractVector(viewPort))
      .addVector(
        containerPoint.clone().divideVector(new Vector(this.zoom, this.zoom))
      );
  }

  /**
   * moves the given contentPoint onto the point of the container
   */
  public getTranslationForFixationPoint(
    contentPoint: Vector,
    containerPoint: Vector
  ): Vector {
    const viewPortInfo = this.getViewPortInfo();

    // scale the container point to the "real" size on the content
    const scaledContainerPoint = containerPoint
      .clone()
      .divideVector(new Vector(this.zoom, this.zoom));
    // calculate the offset of the viewPort in content coordinates
    const viewPortOffset = contentPoint
      .clone()
      .substractVector(scaledContainerPoint);

    // percentual offset of the viewport
    const translateRatio = viewPortOffset
      .clone()
      .divideVector(viewPortInfo.maxViewPortOffset);

    return this.calculateTranslationFromRatio(translateRatio);
  }

  /**
   * limits the vector to the max translation
   */
  public limitTranslation(vector: Vector): Vector {
    const maxTranslate = this.getMaxTranslateVector();
    return new Vector(
      Math.max(
        Math.min(vector.getX(), maxTranslate.getX()),
        maxTranslate.getX() * -1
      ),
      Math.max(
        Math.min(vector.getY(), maxTranslate.getY()),
        maxTranslate.getY() * -1
      )
    );
  }

  public getScaledViewPortSize(): Vector {
    return this.getScrollContainerSizeVector().divideVector(
      new Vector(this.zoom, this.zoom)
    );
  }

  /**
   * maxViewPortOffset is the maximum offset in the content itself
   */
  public getViewPortInfo(): {
    maxViewPortOffset: Vector;
    viewPortSize: Vector;
  } {
    const containerSize = this.getScrollContainerSizeVector();
    const viewPortSize = containerSize
      .clone()
      .divideVector(new Vector(this.zoom, this.zoom));
    const maxViewPortOffset = containerSize
      .clone()
      .substractVector(viewPortSize);

    return { maxViewPortOffset, viewPortSize };
  }

  /**
   * returns the ratio of the translation to the max translation
   */
  public getTranslationRatio(translationVector: Vector): Vector {
    const maxTranslate = this.getMaxTranslateVector();
    let translationRatio = new Vector(0, 0);
    if (maxTranslate.getX() > 0) {
      translationRatio = translationVector
        .clone()
        .multiplyVector(new Vector(-1, -1))
        .addVector(maxTranslate)
        .divideVector(maxTranslate.multiplyVector(new Vector(2, 2)));
    }

    return translationRatio;
  }

  /**
   * @param {Vector} translateRatio - ratio of 1 means max translation to the direction, 0 means no translation
   * @returns {Vector}
   */
  public calculateTranslationFromRatio(translateRatio: Vector): Vector {
    const maxTranslate = this.getMaxTranslateVector();

    if (maxTranslate.getX() > 0) {
      const translate = translateRatio.clone();
      translate
        .multiplyVector(maxTranslate.clone().multiplyVector(new Vector(-2, -2)))
        .addVector(maxTranslate);

      return translate;
    } else {
      return new Vector(0, 0);
    }
  }

  public getMaxTranslateVector(): Vector {
    const size = this.getScrollContainerSizeVector();
    const zoomFactor = (this.zoom - 1) / 2;
    return new Vector(size.getX() * zoomFactor, size.getY() * zoomFactor);
  }

  public getScrollContainerSizeVector(): Vector {
    return new Vector(
      this.scrollContainerSize.width,
      this.scrollContainerSize.height
    );
  }

  public getContainerPointForMouseEvent(event: MouseEvent): Vector | null {
    assertNotNullOrUndefined(
      this.scrollContainer,
      "can't getContainerPointForMouseEvent without scrollContainer"
    );
    const clientRect = this.scrollContainer.getBoundingClientRect();

    if (PointerEventHelper.mouseIsInsideClientRect(event, clientRect)) {
      return new Vector(
        event.clientX - clientRect.left,
        event.clientY - clientRect.top
      );
    } else {
      return null;
    }
  }

  /**
   * returns null if the clientPoint is not inside the scrollContainer
   */
  public getContainerPointForClientPoint(clientPoint: Vector): Vector | null {
    assertNotNullOrUndefined(
      this.scrollContainer,
      "can't getContainerPointForClientPoint without scrollContainer"
    );
    const clientRect = this.scrollContainer.getBoundingClientRect();

    return new Vector(
      clientPoint.getX() - clientRect.left,
      clientPoint.getY() - clientRect.top
    );
  }
}
