import { bindable } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { PermissionHelper } from '../../../classes/PermissionHelper';
import { ModuleName } from '../../../classes/RecordItModuleHelper';
import { User } from '../../../classes/EntityManager/entities/User/types';

export class ModuleTileHomepage {
  @bindable public currentUser: User | null = null;

  protected ModuleName = ModuleName;
  protected ProjectType = ProjectType;

  protected userHasPermissionToAccessModule(moduleName: ModuleName): boolean {
    if (!this.currentUser) return false;
    return PermissionHelper.userHasPermissionForModule(
      this.currentUser,
      moduleName
    );
  }
}
