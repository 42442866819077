import { autoinject } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { GetProcessTaskGroupsFilterAddressFilterType } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { FilterBarFilterSettings } from '../../../operationsComponents/process-task-groups-filter-bar/FilterBarFilterSettingsUtils';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class DefaultProcessTaskGroupFilterBarFilterSettingsComputer extends ValueComputer<
  ComputeData,
  FilterBarFilterSettings
> {
  public static readonly DEFAULT_NEXT_DAYS_FILTER_VALUE = 30;

  private readonly subscriptionManager: SubscriptionManager;
  private defaultEditProcessTaskGroupFilterMode: ProcessTaskGroupFilterMode | null =
    null;

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.activeUserCompanySettingService.bindSettingProperty(
      'operations.defaultEditProcessTaskGroupFilterMode',
      (defaultEditProcessTaskGroupFilterMode) => {
        this.defaultEditProcessTaskGroupFilterMode =
          defaultEditProcessTaskGroupFilterMode;
        invokeCompute();
      }
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): FilterBarFilterSettings {
    return {
      filterMode:
        this.defaultEditProcessTaskGroupFilterMode ??
        ProcessTaskGroupFilterMode.NEXT_DAYS,
      addressFilterType: GetProcessTaskGroupsFilterAddressFilterType.ALL,
      nextDaysFilterValue:
        DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE,
      dateRangeFilterDateFromIso:
        DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString(),
      dateRangeFilterDateToIso: DateUtils.getDateWithDayOffsetWithoutTime(
        DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE +
          1
      ).toISOString(),
      includeArchive: false,
      assigneeUserId: null,
      selectedActionStatusOptionType: null,
      selectedActionStatusId: null,
      customActionStatusFilterText: null,
      lastEditedBeforeDaysEnabled: false,
      lastEditedBeforeDays: 0
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

type ComputeData = Record<string, string>;
