import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { Utils } from 'common/Utils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  FormProcessTaskToProject,
  PictureProcessTaskToProject,
  ProcessTaskToProject
} from './types';

export class ProcessTaskToProjectRepository extends AppEntityRepository<EntityName.ProcessTaskToProject> {
  private readonly pictureTypes: Array<PictureProcessTaskToProjectType> = [
    ProcessTaskToProjectType.GENERAL,
    ProcessTaskToProjectType.MEASURE_POINT_PICTURES
  ];

  public getByProcessTaskIdAndAppointmentId(
    processTaskId: string,
    appointmentId: string | null,
    type: ProcessTaskToProjectType | null
  ): Array<ProcessTaskToProject> {
    return this.getAll().filter((r) => {
      return (
        r.ownerProcessTaskId === processTaskId &&
        Utils.compareOptionalValues(
          r.processTaskAppointmentId,
          appointmentId
        ) &&
        Utils.compareOptionalValues(
          r.type ?? ProcessTaskToProjectType.GENERAL,
          type ?? ProcessTaskToProjectType.GENERAL
        )
      );
    });
  }

  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskToProject> {
    return this.getAll().filter((r) => {
      return r.ownerProcessTaskId === processTaskId;
    });
  }

  public getByProjectId(projectId: string): Array<ProcessTaskToProject> {
    return this.getAll().filter((r) => {
      return r.projectId === projectId;
    });
  }

  public getByProcessTaskIdAndProjectIdAndType({
    processTaskId,
    projectId,
    type
  }: {
    processTaskId: string;
    projectId: string;
    type: ProcessTaskToProjectType;
  }): Array<ProcessTaskToProject> {
    return this.getAll().filter((r) => {
      return (
        r.ownerProcessTaskId === processTaskId &&
        r.projectId === projectId &&
        r.type === type
      );
    });
  }

  public getPictureProcessTaskToProjectsByProcessTaskId(
    processTaskId: string
  ): Array<PictureProcessTaskToProject> {
    return this.getAll().filter((r): r is PictureProcessTaskToProject => {
      return (
        r.ownerProcessTaskId === processTaskId &&
        (this.pictureTypes as Array<ProcessTaskToProjectType>).includes(
          r.type ?? ProcessTaskToProjectType.GENERAL
        )
      );
    });
  }

  public getFormProcessTaskToProjectsByProcessTaskId(
    processTaskId: string
  ): Array<FormProcessTaskToProject> {
    return this.getAll().filter((r): r is FormProcessTaskToProject => {
      return (
        r.ownerProcessTaskId === processTaskId &&
        r.type === ProcessTaskToProjectType.FORM
      );
    });
  }

  public getFormProcessTaskToProjectsByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<FormProcessTaskToProject> {
    return this.getAll().filter((r): r is FormProcessTaskToProject => {
      return (
        r.processTaskAppointmentId === processTaskAppointmentId &&
        r.type === ProcessTaskToProjectType.FORM
      );
    });
  }
}

export type PictureProcessTaskToProjectType =
  | ProcessTaskToProjectType.GENERAL
  | ProcessTaskToProjectType.MEASURE_POINT_PICTURES;
