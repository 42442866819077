import { inject, bindable } from 'aurelia-framework';

import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@inject(Router, AppEntityManager)
export class ThingTypeListItem {
  /** @type {import('../../classes/EntityManager/entities/ThingType/types').ThingType|null} */
  @bindable thingType = null;
  @bindable editable = false;

  /** @type {HTMLElement} */
  _domElement;
  /**@type {HTMLElement}*/
  _listItemElement;

  /**
   * @param {Router} router
   * @param {AppEntityManager} entityManager
   */
  constructor(router, entityManager) {
    this._router = router;
    this._entityManager = entityManager;
  }

  highlight() {
    EntityListItemHelper.highlightListItemElement(this._listItemElement);
  }

  _handleEditThingTypeClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-thing-type-clicked',
      detail: null
    });
  }

  _handleDeleteThingType() {
    const thingType = this.thingType;
    assertNotNullOrUndefined(
      thingType,
      "can't ThingTypeListItem.handleDeleteThingType without a thingType"
    );

    Dialogs.deleteEntityDialog(thingType).then(() => {
      this._entityManager.thingTypeRepository.delete(thingType);
    });
  }

  _getUserGroupNameById(usergroupId) {
    const userGroup =
      this._entityManager.userGroupRepository.getById(usergroupId);
    return userGroup ? userGroup.name : '';
  }
}
