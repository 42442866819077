import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { z, ZodError } from 'zod';

import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../clickable-text-input/clickable-text-input';
import { FloatingLabelInputTheme } from '../floating-label-input/floating-label-input';
import { watch } from '../../hooks/watch';

/**
 * This component display a text input field for JSON and implements a parser using Zod.
 *
 * @event {TJsonTextChangedEvent} json-text-changed this will get fired when the user finished editing the text
 *
 * The event is sent after successful parsing only. You can decide whether empty input is accepted and causes an event by setting the bindable "acceptEmptyInput".
 *
 */
@autoinject()
export class JsonZodObjectInput<TZodSchema extends z.AnyZodObject> {
  @bindable public enabled = false;
  @bindable public validationSchema: TZodSchema | null = null;
  @bindable public object: z.infer<TZodSchema> | null = null;
  @bindable public label: string | null = null;
  @bindable public demoObject: z.infer<TZodSchema> | null = null;
  @bindable public errorDialogTitleTk: string | null = null;
  @bindable public errorDialogTextTk: string | null = null;
  @bindable public acceptEmptyInput = true;

  protected textInput: ClickableTextInput | null = null;

  protected element: HTMLElement;

  constructor(
    private readonly i18n: I18N,
    element: Element
  ) {
    this.element = element as HTMLElement;
  }

  @computed(expression('object'))
  public get text(): string {
    if (!this.object) return '';
    return JSON.stringify(this.object, undefined, 2);
  }

  protected handleTextChanged(event: TTextChangedEvent): void {
    const text = event.detail.value?.toString() ?? '';
    if (!text && this.acceptEmptyInput) {
      this.object = null;
      this.fireJsonTextChangedEvent(null);
      return;
    }
    const parsedObject = this.parseText(event.detail.value?.toString() ?? '');
    if (parsedObject && this.textInput) {
      this.textInput.theme = FloatingLabelInputTheme.DEFAULT;
      this.object = parsedObject;
      this.fireJsonTextChangedEvent(parsedObject);
    } else if (this.textInput) {
      this.textInput.theme = FloatingLabelInputTheme.RED;
    }
  }

  @watch(expression('text'))
  protected resetTheme(): void {
    if (this.textInput) {
      this.textInput.theme = FloatingLabelInputTheme.DEFAULT;
    }
  }

  private parseText(text: string): z.infer<TZodSchema> | null {
    assertNotNullOrUndefined(
      this.validationSchema,
      'cannot parse zod object without validation schema'
    );
    try {
      const jsonData = JSON.parse(text);
      return this.validationSchema.parse(jsonData);
    } catch (e) {
      if (e instanceof ZodError && e.issues.length > 0) {
        this.showJsonParsingError(e.issues[0]!.message);
        return null;
      }

      this.showJsonParsingError(JSON.stringify(e));
      return null;
    }
  }

  private showJsonParsingError(errorMsg: string): void {
    void Dialogs.warningDialog(
      this.i18n.tr(this.errorDialogTitleTk ?? ''),
      this.i18n.tr(this.errorDialogTextTk ?? '', {
        error: errorMsg,
        demoObject: JSON.stringify(this.demoObject, undefined, 2)
      })
    );
  }

  private fireJsonTextChangedEvent(
    parsedObject: z.infer<TZodSchema> | null
  ): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<TJsonTextChangedEvent<z.infer<TZodSchema>>>(
        this.element,
        {
          name: 'json-text-changed',
          bubbles: true,
          detail: { parsedObject }
        }
      );
    }, 0);
  }
}

export type TJsonTextChangedEvent<TTargetType extends any> = NamedCustomEvent<
  'json-text-changed',
  { parsedObject: TTargetType | null }
>;
