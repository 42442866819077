/* global $ */

import { OperationsPhoneCallDialog } from '../operationsComponents/operations-phone-call-dialog/operations-phone-call-dialog';

export class WildixXmppSocketEventListener {
  /** @type {NotifyReturn|null} */
  _notification = null;

  /**
   * @param {import('../../../common/src/Types/WildixXmppSocketMessages').TWildixXmppConnectorSocketMessage} socketMessageData
   */
  listener(socketMessageData) {
    switch (socketMessageData.name) {
      case 'call_added':
        this._handleCallAdded(socketMessageData.data);
        break;

      case 'call_updated':
        // not handled at the moment
        break;

      case 'call_terminated':
        // do not close dialog at the moment
        break;

      default:
        // @ts-ignore - ignore because typescript thinks it's never gonna happen ;)
        throw new Error(
          'Unknown wildix xmpp message name: ' + socketMessageData.name
        );
    }
  }

  /**
   * @param {import('../../../common/src/Types/WildixXmppSocketMessages').TCallAddedData} callData
   */
  _handleCallAdded(callData) {
    OperationsPhoneCallDialog.open({
      phoneNumber: callData.call.calleeNumber,
      processTaskInfos: callData.processTaskInfos
    });
  }
}
