import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject, bindable } from 'aurelia-framework';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { EmbeddedCameraAdditionalButton } from '../embedded-camera';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';

/**
 * @event
 */
@autoinject()
export class EmbeddedCameraButtons {
  @bindable()
  public streamIsActive: boolean = false;

  @bindable()
  public additionalCaptureButtons: Array<EmbeddedCameraAdditionalButton> | null =
    null;

  @bindable()
  public availableFlashModes: Array<CameraPreviewFlashMode> = [];

  @bindable()
  public currentFlashMode: CameraPreviewFlashMode | null = null;

  protected internalAdditionalCaptureButtons: Array<InternalAdditionalButton> =
    [];

  constructor(private readonly element: Element) {}

  protected additionalCaptureButtonsChanged(): void {
    if (this.additionalCaptureButtons?.length) {
      const middle = Math.ceil(this.additionalCaptureButtons.length / 2);

      const leftAdditionalCaptureButtons = this.additionalCaptureButtons.slice(
        0,
        middle
      );
      const rightAdditionalCaptureButtons =
        this.additionalCaptureButtons.slice(middle);

      this.internalAdditionalCaptureButtons = [
        ...leftAdditionalCaptureButtons.map<InternalAdditionalButton>(
          (button, index) => {
            return {
              ...button,
              index,
              direction: -1
            };
          }
        ),
        ...rightAdditionalCaptureButtons.map<InternalAdditionalButton>(
          (button, index) => {
            return {
              ...button,
              index,
              direction: 1
            };
          }
        )
      ];
    } else {
      this.internalAdditionalCaptureButtons = [];
    }
  }

  protected handleCancelClick(): void {
    DomEventHelper.fireEvent<CancelClickedEvent>(this.element, {
      name: 'cancel-clicked',
      detail: null
    });
  }

  protected handleSwitchCameraClick(): void {
    DomEventHelper.fireEvent<SwitchCameraClickedEvent>(this.element, {
      name: 'switch-camera-clicked',
      detail: null
    });
  }

  protected handleCapturePictureClick(): void {
    DomEventHelper.fireEvent<CapturePictureClickedEvent>(this.element, {
      name: 'capture-picture-clicked',
      detail: null
    });
  }

  protected handleCycleFlashModeClick(): void {
    DomEventHelper.fireEvent<ChangeFlashModeClickedEvent>(this.element, {
      name: 'change-flash-mode-clicked',
      detail: null
    });
  }

  @computed(expression('currentFlashMode'))
  protected get flashModeIconName(): string {
    switch (this.currentFlashMode) {
      default:
      case CameraPreviewFlashMode.AUTO:
        return 'fa-bolt-auto';

      case CameraPreviewFlashMode.ON:
        return 'fa-bolt';

      case CameraPreviewFlashMode.OFF:
        return 'fa-bolt-slash';

      case CameraPreviewFlashMode.RED_EYE:
        return 'fa-eye';

      case CameraPreviewFlashMode.TORCH:
        return 'fa-flashlight';
    }
  }
}

export type CancelClickedEvent = NamedCustomEvent<'cancel-clicked', null>;
export type SwitchCameraClickedEvent = NamedCustomEvent<
  'switch-camera-clicked',
  null
>;
export type CapturePictureClickedEvent = NamedCustomEvent<
  'capture-picture-clicked',
  null
>;
export type ChangeFlashModeClickedEvent = NamedCustomEvent<
  'change-flash-mode-clicked',
  null
>;

type InternalAdditionalButton = EmbeddedCameraAdditionalButton & {
  index: number;
  direction: 1 | -1;
};
