import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, Next, RedirectToRoute } from 'aurelia-router';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { RouteConfig } from '../../config/routes';

@autoinject()
export class CheckEntitiesAvailableStep {
  private lastAcceptedRouteInfo: LastRouteInfo = {
    lastAcceptedParams: {},
    lastAcceptedRoute: null
  };

  constructor(private readonly entityManager: AppEntityManager) {}

  public async run(
    navigationInstruction: NavigationInstruction,
    next: Next
  ): Promise<any> {
    const entityCheckConfigurations = this.generateEntityCheckConfigurations();
    const navigationInfo = { passed: true, overrideParams: {} };

    const config = this._getConfigFromNavigationInstruction(
      navigationInstruction
    );

    if (
      config.settings &&
      config.settings.disableEntityAvailableCheck !== true
    ) {
      await this.checkEntities({
        checkConfigurations: entityCheckConfigurations,
        params: navigationInstruction.params,
        navigationInfo,
        currentRoute: config.name
      });
    }

    if (navigationInfo.passed) {
      this.lastAcceptedRouteInfo = {
        lastAcceptedRoute: config.name,
        lastAcceptedParams: {
          ...navigationInstruction.params,
          ...navigationInfo.overrideParams
        }
      };
      if (this.navigationInfoHasNewOverrideParam(navigationInfo)) {
        // an id has been updated, change route
        return this.generateRedirectForNavigationInfo(
          navigationInfo,
          navigationInstruction,
          next
        );
      } else {
        return next();
      }
    } else {
      return next.cancel(
        new RedirectToRoute('not_found', {}, { replace: true, trigger: true })
      );
    }
  }

  /*
   * @techdebt The typings should be improved for proper code completion
   */
  private generateEntityCheckConfigurations(): Array<CheckEntitiesAvailableStepCheckConfiguration> {
    return [
      {
        parameterName: 'thing_id',
        checkFunction: this.checkThing.bind(this)
      },
      {
        parameterName: 'project_id',
        checkFunction: this.checkProject.bind(this)
      },
      {
        parameterName: 'project_category_id',
        checkFunction: this.checkProjectCategory.bind(this)
      },
      {
        parameterName: 'project_question_id',
        checkFunction: this.checkProjectQuestion.bind(this)
      },
      {
        parameterName: 'entry_id',
        checkFunction: this.checkEntry.bind(this)
      },
      {
        parameterName: 'thing_type_id',
        checkFunction: this.checkThingType.bind(this)
      },
      {
        parameterName: 'report_type_id',
        checkFunction: this.checkReportType.bind(this)
      },
      {
        parameterName: 'user_group_id',
        checkFunction: this.checkUserGroup.bind(this)
      },
      {
        parameterName: 'person_id',
        checkFunction: this.checkPerson.bind(this)
      },
      {
        parameterName: 'process_configuration_id',
        checkFunction: this.checkProcessConfiguration.bind(this)
      },
      {
        parameterName: 'process_configuration_step_id',
        checkFunction: this.checkProcessConfigurationStep.bind(this)
      },
      {
        parameterName: 'process_task_id',
        checkFunction: this.checkProcessTask.bind(this)
      },
      {
        parameterName: 'structure_template_id',
        checkFunction: this.checkStructureTemplate.bind(this)
      }
    ];
  }

  /**
   * @param {TCheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkEntities({
    checkConfigurations,
    params,
    navigationInfo,
    currentRoute
  }: {
    checkConfigurations: Array<CheckEntitiesAvailableStepCheckConfiguration>;
    params: AnyParams;
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo;
    currentRoute: string;
  }): Promise<void> {
    for (const config of checkConfigurations) {
      if (params[config.parameterName as keyof typeof params]) {
        await config.checkFunction(params, navigationInfo, currentRoute);
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkThing(
    params: { thing_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (!this.entityManager.thingRepository.getById(params.thing_id)) {
      const thing = this.entityManager.thingRepository.getByOriginalId(
        params.thing_id
      );
      if (thing) {
        navigationInfo.overrideParams.thing_id = thing.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkProject(
    params: { project_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (!this.entityManager.projectRepository.getById(params.project_id)) {
      const project = this.entityManager.projectRepository.getByOriginalId(
        params.project_id
      );
      if (project) {
        navigationInfo.overrideParams.project_id = project.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkProjectCategory(
    params: { project_category_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.projectQuestionCategoryRepository.getById(
        params.project_category_id
      )
    ) {
      const entity =
        this.entityManager.projectQuestionCategoryRepository.getByOriginalId(
          params.project_category_id
        );
      if (entity) {
        navigationInfo.overrideParams.project_category_id = entity.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkProjectQuestion(
    params: { project_question_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.projectQuestionRepository.getById(
        params.project_question_id
      )
    ) {
      const entity =
        this.entityManager.projectQuestionRepository.getByOriginalId(
          params.project_question_id
        );
      if (entity) {
        navigationInfo.overrideParams.project_question_id = entity.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkEntry(
    params: { entry_id: string; project_id?: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    currentRoute: string
  ): Promise<void> {
    /*
      This hack is necessary to cover cases where entries which have a related edit-entry-widget-overlay are being deleted while the overlay is opened.
      Opening an overlay involves modifying the current route and therefore, adding the overlay route to the Aurelia routing history.
      This is important for UX, because users expect to be able to close overlays by pressing the back button.
      Once the overlay is closed, we go back in history and remove (and re-trigger) the route.
      At the point of retriggering, the entry may already be deleted which would lead to a failed check for the Entry entity.
      As there is no easy way to prevent this re-triggering, we decided to circumvent the actual check here
      if the params (entryId + routeName) equal the last Entry which passed the actual check in this function.
    */

    if (
      this.checkEntryRouteHistory({ entryId: params.entry_id, currentRoute })
    ) {
      return;
    }

    if (!this.entityManager.entryRepository.getById(params.entry_id)) {
      const entry = this.entityManager.entryRepository.getByOriginalId(
        params.entry_id
      );
      if (entry) {
        navigationInfo.overrideParams.entry_id = entry.id;
        return;
      } else {
        const projectId =
          navigationInfo.overrideParams.project_id || params.project_id;
        if (
          projectId &&
          !this.entityManager.joinedProjectsManager.projectIsJoined(projectId)
        ) {
          await this.entityManager.joinedProjectsManager.joinProject(projectId);
          await this.entityManager.entityActualization.actualize();
          if (this.entityManager.entryRepository.getById(params.entry_id)) {
            navigationInfo.overrideParams.entry_id = params.entry_id;
            return;
          }
        }
        navigationInfo.passed = false;
        return;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkThingType(
    params: { thing_type_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (!this.entityManager.thingTypeRepository.getById(params.thing_type_id)) {
      const thingType = this.entityManager.thingTypeRepository.getByOriginalId(
        params.thing_type_id
      );
      if (thingType) {
        navigationInfo.overrideParams.thing_type_id = thingType.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkReportType(
    params: { report_type_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.reportTypeRepository.getById(params.report_type_id)
    ) {
      const reportType =
        this.entityManager.reportTypeRepository.getByOriginalId(
          params.report_type_id
        );
      if (reportType) {
        navigationInfo.overrideParams.report_type_id = reportType.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkUserGroup(
    params: { user_group_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (!this.entityManager.userGroupRepository.getById(params.user_group_id)) {
      const userGroup = this.entityManager.userGroupRepository.getByOriginalId(
        params.user_group_id
      );
      if (userGroup) {
        navigationInfo.overrideParams.user_group_id = userGroup.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkPerson(
    params: { person_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (!this.entityManager.personRepository.getById(params.person_id)) {
      const person = this.entityManager.personRepository.getByOriginalId(
        params.person_id
      );
      if (person) {
        navigationInfo.overrideParams.person_id = person.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkProcessConfiguration(
    params: { process_configuration_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.processConfigurationRepository.getById(
        params.process_configuration_id
      )
    ) {
      const processConfiguration =
        this.entityManager.processConfigurationRepository.getByOriginalId(
          params.process_configuration_id
        );
      if (processConfiguration) {
        navigationInfo.overrideParams.process_configuration_id =
          processConfiguration.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkProcessConfigurationStep(
    params: { process_configuration_step_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.processConfigurationStepRepository.getById(
        params.process_configuration_step_id
      )
    ) {
      const step =
        this.entityManager.processConfigurationStepRepository.getByOriginalId(
          params.process_configuration_step_id
        );
      if (step) {
        navigationInfo.overrideParams.process_configuration_step_id = step.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {{process_task_id: string}} params
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   * @private
   */
  private async checkProcessTask(
    params: { process_task_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.processTaskRepository.getById(params.process_task_id)
    ) {
      const processTask =
        this.entityManager.processTaskRepository.getByOriginalId(
          params.process_task_id
        );
      if (processTask) {
        navigationInfo.overrideParams.process_task_id = processTask.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  /**
   * @param {CheckEntitiesAvailableStepNavigationInfo} navigationInfo - gets modified in place
   */
  private async checkStructureTemplate(
    params: { structure_template_id: string },
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    _currentRoute: string
  ): Promise<void> {
    if (
      !this.entityManager.structureTemplateRepository.getById(
        params.structure_template_id
      )
    ) {
      const structureTemplate =
        this.entityManager.structureTemplateRepository.getByOriginalId(
          params.structure_template_id
        );
      if (structureTemplate) {
        navigationInfo.overrideParams.structure_template_id =
          structureTemplate.id;
      } else {
        navigationInfo.passed = false;
      }
    }
  }

  private navigationInfoHasNewOverrideParam(
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo
  ): boolean {
    for (const key in navigationInfo.overrideParams) {
      if (navigationInfo.overrideParams.hasOwnProperty(key)) {
        return true;
      }
    }

    return false;
  }

  private generateRedirectForNavigationInfo(
    navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
    navigationInstruction: NavigationInstruction,
    next: Next
  ): any {
    const newParams = {
      ...navigationInstruction.params,
      ...navigationInstruction.queryParams,
      ...navigationInfo.overrideParams
    };

    const config = this._getConfigFromNavigationInstruction(
      navigationInstruction
    );
    return next.cancel(
      new RedirectToRoute(config.name, newParams, {
        replace: true,
        trigger: true
      })
    );
  }

  private _getConfigFromNavigationInstruction(
    navigationInstruction: NavigationInstruction
  ): RouteConfig {
    return navigationInstruction.config as RouteConfig;
  }

  private checkEntryRouteHistory({
    entryId,
    currentRoute
  }: {
    entryId: string;
    currentRoute: string;
  }): boolean {
    return (
      'entry_id' in this.lastAcceptedRouteInfo.lastAcceptedParams &&
      this.lastAcceptedRouteInfo.lastAcceptedParams['entry_id'] === entryId &&
      this.lastAcceptedRouteInfo.lastAcceptedRoute === currentRoute
    );
  }
}

type CheckEntitiesAvailableStepNavigationInfo = {
  passed: boolean;
  overrideParams: Record<string, string>;
};

type CheckEntitiesAvailableStepCheckConfigurationCheckFunction = (
  params: AnyParams,
  navigationInfo: CheckEntitiesAvailableStepNavigationInfo,
  currentRoute: string
) => Promise<void>;

type CheckEntitiesAvailableStepCheckConfiguration = {
  parameterName: SupportedIds;
  checkFunction: CheckEntitiesAvailableStepCheckConfigurationCheckFunction;
};

type SupportedIds =
  | 'thing_id'
  | 'project_id'
  | 'project_category_id'
  | 'project_question_id'
  | 'entry_id'
  | 'thing_type_id'
  | 'report_type_id'
  | 'user_group_id'
  | 'person_id'
  | 'process_configuration_id'
  | 'process_configuration_step_id'
  | 'process_task_id'
  | 'structure_template_id';

type AnyParams = {
  [key in SupportedIds]: string;
};

type LastRouteInfo = {
  lastAcceptedRoute: string | null;
  lastAcceptedParams: Partial<AnyParams>;
};
