import { autoinject } from 'aurelia-framework';
import { ProcessTaskLoggingService } from './ProcessTaskLoggingService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../classes/EntityManager/entities/ProcessTask/types';
import { ProcessConfigurationStepAutoAppointment } from '../classes/EntityManager/entities/ProcessConfigurationStepAutoAppointment/types';

@autoinject()
export class SetProcessTaskCurrentProcessConfigurationStepIdService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService
  ) {}

  public async setCurrentProcessConfigurationStepId(
    processTask: ProcessTask,
    currentProcessConfigurationStepId: string
  ): Promise<void> {
    if (
      processTask.currentProcessConfigurationStepId ===
      currentProcessConfigurationStepId
    ) {
      return;
    }

    processTask.currentProcessConfigurationStepId =
      currentProcessConfigurationStepId;
    this.entityManager.processTaskRepository.update(processTask);
    await this.processTaskLoggingService.logCurrentProcessConfigurationStepIdChanged(
      processTask,
      currentProcessConfigurationStepId
    );

    await this.removeAutoAppointmentsForProcessTask(processTask);
    await this.createAutoAppointmentsForProcessTask(processTask);
  }

  private async removeAutoAppointmentsForProcessTask(
    processTask: ProcessTask
  ): Promise<void> {
    const appointments =
      this.entityManager.processTaskAppointmentRepository.getByProcessTaskId(
        processTask.id
      );

    for (const appointment of appointments) {
      if (!appointment.processConfigurationStepAutoAppointmentId) {
        continue;
      }

      this.entityManager.processTaskAppointmentRepository.delete(appointment);
      await this.processTaskLoggingService.logAutoAppointmentDeleted(
        processTask,
        appointment
      );
    }
  }

  private async createAutoAppointmentsForProcessTask(
    processTask: ProcessTask
  ): Promise<void> {
    const autoAppointmentConfigs =
      this.entityManager.processConfigurationStepAutoAppointmentRepository.getByProcessConfigurationStepId(
        processTask.currentProcessConfigurationStepId
      );

    for (const config of autoAppointmentConfigs) {
      const appointment =
        this.entityManager.processTaskAppointmentRepository.create({
          name: config.name,
          coordinatorUserId: config.defaultUserId,
          processConfigurationStepAutoAppointmentId: config.id,
          ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
          ownerProcessTaskId: processTask.id,
          ownerUserGroupId: processTask.ownerUserGroupId
        });

      this.entityManager.processTaskAppointmentToUserRepository.create({
        ownerProcessTaskGroupId: appointment.ownerProcessTaskGroupId,
        ownerProcessTaskId: appointment.ownerProcessTaskId,
        ownerUserGroupId: appointment.ownerUserGroupId,
        processTaskAppointmentId: appointment.id,
        userId: config.defaultUserId,
        dateFrom: this.getFutureDateForAutoAppointment(config, 12),
        dateTo: this.getFutureDateForAutoAppointment(config, 13)
      });

      await this.processTaskLoggingService.logAutoAppointmentCreated(
        processTask,
        appointment,
        config
      );
    }
  }

  private getFutureDateForAutoAppointment(
    autoAppointmentConfig: ProcessConfigurationStepAutoAppointment,
    hour: number
  ): string {
    const offset = autoAppointmentConfig.offset || 0;
    const futureDate = new Date(Date.now() + offset);
    futureDate.setMilliseconds(0);
    futureDate.setSeconds(0);
    futureDate.setMinutes(0);
    futureDate.setHours(hour);

    return futureDate.toISOString();
  }
}
