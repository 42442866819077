import { computedFrom } from 'aurelia-framework';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../../../classes/EntityManager/entities/Person/types';
import { Property } from '../../../../classes/EntityManager/entities/Property/types';
import { PropertyToPerson } from '../../../../classes/EntityManager/entities/PropertyToPerson/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SetPersonValueOptions } from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';

export class PropertyPersonHandle implements Disposable {
  private readonly property: Property;
  private readonly entityManager: AppEntityManager;
  private readonly propertyToPersonPermissionChecker;
  private readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];
  private readonly subscriptionManager: SubscriptionManager;

  private internalPerson: Person | null = null;

  constructor(options: PropertyPersonHandleOptions) {
    this.property = options.property;
    this.entityManager = options.entityManager;
    this.propertyPermissionsHandle =
      options.alreadySubscribedPropertyPermissionsHandle;

    this.propertyToPersonPermissionChecker =
      options.permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.PropertyToPerson
      });

    this.subscriptionManager = options.subscriptionManagerService.create();

    this.subscriptionManager.addDisposable(
      this.propertyToPersonPermissionChecker.subscribe()
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PropertyToPerson,
      this.updatePerson.bind(this)
    );
    this.updatePerson();
  }

  @computedFrom('propertyToPersonPermissionChecker.revision')
  public get canSetPersonValue(): boolean {
    const propertyToPerson = this.getFirstPropertyToPerson();

    if (propertyToPerson) {
      return this.propertyToPersonPermissionChecker.useAdapter(
        ({ adapter }) => {
          // if a propertyToPerson exists, it can be deleted or updated, based on the users choic
          return (
            adapter.canEditField(propertyToPerson) &&
            adapter.canDeleteEntity(propertyToPerson)
          );
        }
      );
    }

    return this.propertyPermissionsHandle.canEditPropertyToPersons;
  }

  @computedFrom('internalPerson')
  public get person(): Person | null {
    return this.internalPerson;
  }

  public setPersonValue({ person }: SetPersonValueOptions): void {
    const propertyToPerson = this.getFirstPropertyToPerson();
    if (propertyToPerson) {
      if (person) {
        propertyToPerson.personId = person.id;
        this.entityManager.propertyToPersonRepository.update(propertyToPerson);
      } else {
        this.entityManager.propertyToPersonRepository.delete(propertyToPerson);
      }
    } else {
      if (person) {
        // @ts-ignore - TS unfortunately cannot check this (yet)
        this.entityManager.propertyToPersonRepository.create({
          ownerUserGroupId: this.property.ownerUserGroupId,
          ownerProjectId: this.property.ownerProjectId,
          ownerProcessTaskId: this.property.ownerProcessTaskId,
          ownerProcessTaskGroupId: this.property.ownerProcessTaskGroupId,

          propertyId: this.property.id,
          personId: person.id
        });
      }
    }

    this.internalPerson = person;
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updatePerson(): void {
    const personId = this.getFirstPropertyToPerson()?.personId;
    this.internalPerson =
      personId != null
        ? this.entityManager.personRepository.getById(personId)
        : null;
  }

  private getFirstPropertyToPerson(): PropertyToPerson | null {
    return (
      this.entityManager.propertyToPersonRepository.getByPropertyId(
        this.property.id
      )[0] ?? null
    );
  }
}

export type PropertyPersonHandleOptions = {
  property: Property;
  entityManager: AppEntityManager;
  permissionsService: PermissionsService;
  alreadySubscribedPropertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];
  subscriptionManagerService: SubscriptionManagerService;
};
