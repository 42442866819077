import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationDeviceExportEntityInfo } from 'common/Types/Entities/ProcessConfigurationDeviceExport/createProcessConfigurationDeviceExportEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationDeviceExport } from './types';

export const processConfigurationDeviceExportEntityInfo =
  createProcessConfigurationDeviceExportEntityInfo<ProcessConfigurationDeviceExport>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
