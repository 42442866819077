import { assertNotNullOrUndefined, assertType } from 'common/Asserts';
import { DataUrlHelper } from 'common/DataUrlHelper';
import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';

import { ImageHelper } from '../../classes/ImageHelper';
import { SketcherOverlayImageHandler } from '../../drawingComponents/sketcher-overlay/SketcherOverlayImageHandler';

export class DataUrlSketcherOverlayImageHandler extends SketcherOverlayImageHandler {
  private image: HTMLImageElement | null = null;
  private readonly boundHandleImageLoad = this.handleImageLoad.bind(this);
  private readonly boundHandleImageError = this.handleImageError.bind(this);
  private readonly promiseContainer: PromiseContainer<void> =
    new PromiseContainer();

  constructor(
    private readonly pictureData: IPictureData,
    private readonly onDataSaved: TOnDataSavedCallback
  ) {
    super();
  }

  public async load(image: HTMLImageElement): Promise<void> {
    this.image = image;
    this.image.addEventListener('load', this.boundHandleImageLoad);
    this.image.addEventListener('error', this.boundHandleImageError);
    this.image.src = '';
    this.promiseContainer.resetPermanentStatus();
    this.image.src = this.pictureData.originalDataUrl;
  }

  public destroy(): void {
    if (this.image) {
      this.image.removeEventListener('load', this.boundHandleImageLoad);
      this.image.removeEventListener('error', this.boundHandleImageError);
      this.image = null;
    }
  }

  public waitForImageLoad(): Promise<void> {
    return this.promiseContainer.create();
  }

  public async loadSketch(): Promise<SVGSVGElement | null> {
    if (!this.pictureData.sketchDataUrl) {
      return null;
    }

    const wrapper = document.createElement('div');
    wrapper.innerHTML = atob(
      DataUrlHelper.getBase64FromDataUrl(this.pictureData.sketchDataUrl)
    );

    assertNotNullOrUndefined(wrapper.firstElementChild, 'empty svg sketch');
    assertType(
      wrapper.firstElementChild,
      SVGSVGElement,
      'wrong sketchDataUrl, firstElementChild is not an svg'
    );

    return wrapper.firstElementChild;
  }

  public async save(
    sketchDataUrl: string,
    sketchDataUrlForCanvas: string
  ): Promise<void> {
    const sketchImage = await ImageHelper.loadImage(sketchDataUrlForCanvas);
    const originalImage = await ImageHelper.loadImage(
      this.pictureData.originalDataUrl
    );

    this.onDataSaved({
      originalDataUrl: this.pictureData.originalDataUrl,
      sketchDataUrl: sketchDataUrl,
      editedDataUrl: ImageHelper.mergeImages(originalImage, sketchImage)
    });
  }

  private handleImageLoad(): void {
    this.promiseContainer.resolvePermanent();
  }

  private handleImageError(): void {
    this.promiseContainer.rejectPermanent(
      new Error(
        `error loading image from dataurl "${this.pictureData.originalDataUrl.slice(
          0,
          1000
        )}"`
      )
    );
  }
}

export interface IPictureData {
  originalDataUrl: string;
  sketchDataUrl?: string | null;
}

export interface ISaveResult {
  originalDataUrl: string;
  sketchDataUrl: string;
  editedDataUrl: string;
}

export type TOnDataSavedCallback = (result: ISaveResult) => void;
