import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { NfcTokenToThing } from './types';

export class NfcTokenToThingRepository extends AppEntityRepository<EntityName.NfcTokenToThing> {
  public getByThingId(thingId: string): Array<NfcTokenToThing> {
    return this.getAll().filter((nTTT) => nTTT.thingId === thingId);
  }

  public deleteByThingId(thingId: string): void {
    for (const nfcTokenToThing of this.getByThingId(thingId)) {
      this.delete(nfcTokenToThing);
    }
  }
}
