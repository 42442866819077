import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import { CirclePreloader } from '../../../aureliaComponents/circle-preloader/circle-preloader';
import { ZoomBoxContentResizer } from '../../../aureliaComponents/zoom-box/ZoomBoxContentResizer';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { InlineSvgLoaderSvgCopier } from '../../../classes/Svg/InlineSvgLoader';
import { DrawingArea } from '../../drawing-area/drawing-area';
import { ToolGrid } from '../../drawing-area/tools/ToolGrid';
import { DrawingHistoryManager } from '../DrawingHistoryManager';
import { SketcherOverlayImageHandler } from '../SketcherOverlayImageHandler';

/**
 * @event {TLoadErrorEvent} load-error
 */
@autoinject()
export class SketcherOverlaySketchArea {
  /**
   * readonly!
   */
  @bindable()
  public drawingArea: DrawingArea | null = null;

  /**
   * readonly!
   */
  @bindable()
  public grid: ToolGrid | null = null;

  /**
   * readonly!
   */
  @bindable()
  public historyManager: DrawingHistoryManager | null = null;

  @bindable()
  public toolIsActive: boolean = false;

  private domElement: HTMLElement;
  private gridElement: SVGSVGElement | null = null;
  private zoomBoxElement: HTMLElement | null = null;
  private gridMsvg: Msvg.Msvg | null = null;
  private contentResizer: ZoomBoxContentResizer | null = null;
  private sketchingContainerElement: HTMLElement | null = null;
  private imageElement: HTMLImageElement | null = null;
  private circlePreloader: CirclePreloader | null = null;
  private imageHandler: SketcherOverlayImageHandler | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public load(imageHandler: SketcherOverlayImageHandler): void {
    assertNotNullOrUndefined(
      this.sketchingContainerElement,
      "can't SketcherOverlaySketchArea.load without a sketchingContainerElement"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't SketcherOverlaySketchArea.load without a zoomBoxElement"
    );
    assertNotNullOrUndefined(
      this.imageElement,
      "can't SketcherOverlaySketchArea.load without an imageElement"
    );
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlaySketchArea.load without an drawingArea"
    );

    this.imageHandler = imageHandler;

    if (!this.contentResizer) {
      this.contentResizer = new ZoomBoxContentResizer(
        this.sketchingContainerElement,
        this.zoomBoxElement,
        this.imageElement
      );
    }

    this.contentResizer.update();
    this.drawingArea.getMsvg().empty();

    void this.loadImageAndSketch();
  }

  public destroy(): void {
    this.imageHandler = null;
    this.contentResizer?.destroy();
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlaySketchArea.attached without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.gridElement,
      "can't SketcherOverlaySketchArea.attached without a gridElement"
    );

    this.gridMsvg = new Msvg.Msvg(this.gridElement);
    this.historyManager = new DrawingHistoryManager(this.drawingArea.getMsvg());
    this.grid = new ToolGrid(this.gridMsvg);
  }

  private async loadImageAndSketch(): Promise<void> {
    assertNotNullOrUndefined(
      this.imageHandler,
      "can't SketcherOverlaySketchArea.loadImage without an imageHandler"
    );
    assertNotNullOrUndefined(
      this.imageElement,
      "can't SketcherOverlaySketchArea.loadImage without an imageElement"
    );
    assertNotNullOrUndefined(
      this.circlePreloader,
      "can't SketcherOverlaySketchArea.loadImage without a circlePreloader"
    );

    await this.imageHandler.load(this.imageElement);

    const circlePreloader = this.circlePreloader;
    circlePreloader.start();

    try {
      const result = await Promise.all([
        this.imageHandler.waitForImageLoad(),
        this.imageHandler.loadSketch()
      ]);
      this.handleImageAndSketchLoaded(result[1]);
    } catch (e) {
      this.handleImageAndSketchLoadedError(e);
    } finally {
      circlePreloader.stop();
    }
  }

  private handleImageAndSketchLoaded(sketch: SVGSVGElement | null): void {
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlaySketchArea.handleImageAndSketchLoaded without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlaySketchArea.handleImageAndSketchLoaded without a historyManager"
    );

    this.resizeZoomBoxContent();
    this.updateDrawingAreaSize(sketch);

    if (sketch) {
      const copier = new InlineSvgLoaderSvgCopier(
        sketch,
        this.drawingArea.getMsvg().getElement()
      );
      copier.copy();
    } else {
      this.drawingArea.getMsvg().empty();
    }

    this.historyManager.reset();
  }

  private handleImageAndSketchLoadedError(error: unknown): void {
    DomEventHelper.fireEvent<TLoadErrorEvent>(this.domElement, {
      name: 'load-error',
      detail: {
        error
      }
    });
  }

  private resizeZoomBoxContent(): void {
    this.contentResizer?.update();
  }

  private updateDrawingAreaSize(newSketch: SVGSVGElement | null): void {
    assertNotNullOrUndefined(
      this.imageElement,
      "can't SketcherOverlaySketchArea.loadImage without an imageElement"
    );

    if (newSketch) {
      this.setDrawingAreaSize(
        newSketch.viewBox.baseVal.width,
        newSketch.viewBox.baseVal.height
      );
    } else {
      let ratio = 1920;
      if (
        this.imageElement.naturalWidth > 0 &&
        this.imageElement.naturalHeight > 0
      ) {
        ratio =
          this.imageElement.naturalWidth / this.imageElement.naturalHeight;
      }
      this.setDrawingAreaSize(1920, 1920 / ratio);
    }
  }

  private setDrawingAreaSize(width: number, height: number): void {
    assertNotNullOrUndefined(
      this.drawingArea,
      "can't SketcherOverlaySketchArea.setDrawingAreaSize without a drawingArea"
    );
    assertNotNullOrUndefined(
      this.gridMsvg,
      "can't SketcherOverlaySketchArea.setDrawingAreaSize without a gridMsvg"
    );
    assertNotNullOrUndefined(
      this.grid,
      "can't SketcherOverlaySketchArea.setDrawingAreaSize without a grid"
    );

    this.drawingArea.width = width;
    this.drawingArea.height = height;
    this.gridMsvg.setViewBox(`0 0 ${width} ${height}`);
    this.grid.calculateGrid();
  }

  private handleDrawingAreaChanged(): void {
    assertNotNullOrUndefined(
      this.historyManager,
      "can't SketcherOverlaySketchArea.handleDrawingAreaChanged without a historyManager"
    );
    this.historyManager.push();
  }
}

export type TLoadErrorEvent = NamedCustomEvent<
  'load-error',
  { error: unknown }
>;
