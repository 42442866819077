import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationStepAutoFormEntityInfo } from 'common/Types/Entities/ProcessConfigurationStepAutoForm/createProcessConfigurationStepAutoFormEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationStepAutoForm } from './types';

export const processConfigurationStepAutoFormEntityInfo =
  createProcessConfigurationStepAutoFormEntityInfo<ProcessConfigurationStepAutoForm>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
