import { autoinject, bindable } from 'aurelia-framework';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event value-changed
 */
@autoinject()
export class PersonContactSelect {
  @bindable()
  public value: string | null = null;

  /**
   * limit the selection to a certain personId
   */
  @bindable()
  public personId: string | null = null;

  /**
   * limit the selection to a certain PersonContactType
   */
  @bindable()
  public contactType: PersonContactType | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached = false;
  private contacts: Array<PersonContact> = [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updateContacts.bind(this)
    );
    this.updateContacts();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private contactTypeChanged(): void {
    if (this.isAttached) {
      this.updateContacts();
    }
  }

  private personIdChanged(): void {
    if (this.isAttached) {
      this.updateContacts();
    }
  }

  private updateContacts(): void {
    const contacts = this.personId
      ? this.entityManager.personContactRepository.getByPersonId(this.personId)
      : this.entityManager.personContactRepository.getAll();
    this.contacts = contacts.filter(
      (c) => !this.contactType || c.contactType === this.contactType
    );
  }

  private handleSelectChanged(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'value-changed',
      detail: {
        value: this.value
      }
    });
  }
}
