import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { TagSortingMode } from 'common/Enums/TagSortingMode';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TagSorter } from '../../classes/TagSorter';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * Displays a list of tags.
 *
 * @event tag-deleted triggered when a tag is deleted from the list.
 */
@autoinject()
export class TagsWidgetList {
  @bindable public tagIds: Array<string> | null = null;

  @bindable public enabled = false;

  protected tags: Array<Tag> | null = null;

  private element: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  constructor(
    element: Element,
    subManagerService: SubscriptionManagerService,
    private entityManager: AppEntityManager,
    private activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Tag,
      this.updateTags.bind(this)
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'tagIds',
      this.updateTags.bind(this)
    );
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateTags();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateTags(): void {
    if (!this.tagIds) {
      this.tags = null;
      return;
    }

    this.tags = TagSorter.sortTags(
      this.entityManager.tagRepository.getByIds(this.tagIds),
      this.tagSortingMode
    );
  }

  private deleteTag(tagId: string): void {
    assertNotNullOrUndefined(
      this.tagIds,
      'cannot delete a tag if tagIds are undefined'
    );
    assertNotNullOrUndefined(
      this.tags,
      'cannot delete a tag if tags are undefined'
    );

    this.tagIds = this.tagIds.filter((t) => t !== tagId);

    setTimeout(() => {
      DomEventHelper.fireEvent<TagDeletedEvent>(this.element, {
        name: 'tag-deleted',
        detail: { tagId }
      });
    });
  }

  protected tagIdsChanged(): void {
    this.updateTags();
  }

  protected handleDeleteTagClicked(tag: Tag): void {
    this.deleteTag(tag.id);
  }
}

export type TagDeletedEvent = NamedCustomEvent<
  'tag-deleted',
  { tagId: string }
>;
