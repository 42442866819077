import { autoinject, bindable } from 'aurelia-framework';

import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event edit-process-configuration-form-clicked
 */
@autoinject()
export class ProcessConfigurationFormListItem {
  @bindable()
  public processConfigurationForm: ProcessConfigurationForm | null = null;

  @bindable()
  public enabled: boolean = false; // TODO: remove

  @subscribableLifecycle()
  protected readonly processConfigurationFormPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationForm];

  protected panelOpened: boolean = false;
  protected listItemElement: HTMLElement | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationFormPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationForm,
        context: this as ProcessConfigurationFormListItem,
        propertyName: 'processConfigurationForm'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleEditClick(): void {
    if (!this.element) {
      return;
    }

    DomEventHelper.fireEvent(this.element, {
      name: 'edit-process-configuration-form-clicked',
      detail: null
    });
  }

  protected handleDeleteClick(): void {
    const processConfigurationForm = this.processConfigurationForm;
    if (!processConfigurationForm) {
      return;
    }

    void Dialogs.deleteEntityDialog(processConfigurationForm).then(() => {
      this.entityManager.processConfigurationFormRepository.delete(
        processConfigurationForm
      );
    });
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }
}
