import { Property } from '../../EntityManager/entities/Property/types';
import { PropertyConfigsByPropertyType } from '../createPropertyConfigsByPropertyTypes/createPropertyConfigsByPropertyTypes';
import { NamedRequiredPropertyGroup } from './NamedRequiredPropertyGroup';
import { RequiredProperty } from './RequiredProperty';
import {
  RequiredPropertyGroup,
  ValidationErrorResult,
  ValidationSuccessResult
} from './RequiredPropertyGroup';
import { RootRequiredPropertyGroup } from './RootRequiredPropertyGroup';

export class RequiredPropertyGroupUtils {
  private constructor() {}

  public static createRequiredPropertyGroups({
    properties,
    propertyConfigsByPropertyType
  }: {
    properties: Array<Property>;
    propertyConfigsByPropertyType: PropertyConfigsByPropertyType;
  }): Array<RequiredPropertyGroup> {
    const groupsByName: RequiredPropertyGroupsByNameMap = new Map();

    const requiredProperties = RequiredProperty.createRequiredProperties({
      properties,
      propertyConfigsByPropertyType
    });
    for (const property of requiredProperties) {
      const group = this.getOrCreateGroup({
        name: property.groupName,
        groupsByName
      });

      group.addRequiredProperty({ requiredProperty: property });
    }

    return Array.from(groupsByName.values());
  }

  public static createSuccessResult(): ValidationSuccessResult {
    return {
      success: true
    };
  }

  public static createErrorResult({
    property
  }: {
    property: Property;
  }): ValidationErrorResult {
    return {
      success: false,
      failedAtProperty: property
    };
  }

  private static getOrCreateGroup({
    name,
    groupsByName
  }: {
    name: RequiredPropertyGroup['name'];
    groupsByName: RequiredPropertyGroupsByNameMap;
  }): RequiredPropertyGroup {
    let group = groupsByName.get(name);

    if (!group) {
      group =
        name != null
          ? new NamedRequiredPropertyGroup({ name })
          : new RootRequiredPropertyGroup();
      groupsByName.set(name, group);
    }

    return group;
  }
}

type RequiredPropertyGroupsByNameMap = Map<
  RequiredPropertyGroup['name'],
  RequiredPropertyGroup
>;
