import { ProcessTaskToProjectType } from '../Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

export type GetProcessTaskToProjectRelationInfoSuccessResponse = {
  success: true;
  processTaskToProjectInfos: Array<ProcessTaskToProjectRelationInfo>;
  totalResultLength: number;
};

export type GetProcessTaskToProjectRelationInfoErrorResponse = {
  success: false;
};

export type GetProcessTaskToProjectRelationInfoResponse =
  | GetProcessTaskToProjectRelationInfoSuccessResponse
  | GetProcessTaskToProjectRelationInfoErrorResponse;

export type SetProcessTaskToProjectRelationSuccessResponse = {
  success: true;
  newProcessTaskToProjectId: string | null;
};

export type SetProcessTaskToProjectRelationErrorResponse = {
  success: false;
};

export type SetProcessTaskToProjectRelationResponse =
  | SetProcessTaskToProjectRelationSuccessResponse
  | SetProcessTaskToProjectRelationErrorResponse;

export type SetProcessTaskToProjectRelationRequest = {
  processTaskId: string;
  projectId: string;
  processTaskToProjectType: ProcessTaskToProjectType;
  newRelationStatus: boolean;
};

export type GetProcessTaskToProjectRelationInfoRequest = {
  activeTargetEntityId: string;
  type: ProcessTaskToProjectRelationInfoRequestType;
  paginationInfo: PaginationInfo;
  processTaskToProjectType: ProcessTaskToProjectType;
  includeArchivedEntities: boolean;
  filterString?: string;
};

export enum ProcessTaskToProjectRelationInfoRequestType {
  WITH_EXISTING_RELATION = 'withExistingRelation',
  ALL = 'all'
}

export type ProcessTaskToProjectRelationInfo = {
  processTaskToProjectId: string | null;
  mainInfoText: string;
  subInfoText: string;
  url: string;
  processTaskId?: string;
  projectId?: string;
  isArchived: boolean;
};

export type PaginationInfo = {
  /** one-indexed */
  currentPageNumber: number;
  currentPageSize: number;
};
