import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureSelectAndEditDialog } from '../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyPictureWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyPictureWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyPictureWidget doesn't support focus");
  }

  protected handleTablePictureClick(): void {
    assertNotNullOrUndefined(
      this.configuration?.binding.pictureEntityInfo,
      "can't BasePropertyPictureWidget.handleTablePictureClick without pictureEntityInfo"
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: this.configuration.binding.canEditPictures,
      mainEntityIdField:
        this.configuration.binding.pictureEntityInfo.mainEntityIdField,
      mainEntityId: this.configuration.binding.pictureEntityInfo.mainEntityId,
      subEntityField:
        this.configuration.binding.pictureEntityInfo.subEntityField,
      subEntityValue:
        this.configuration.binding.pictureEntityInfo.subEntityValue,
      ownerUserGroupId:
        this.configuration.binding.pictureEntityInfo.ownerUserGroupId,
      ownerProjectId:
        this.configuration.binding.pictureEntityInfo.ownerProjectId
    });
  }
}

export const basePropertyPictureWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default', 'picture']
  });
