import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { ProcessConfigurationPositionType } from '../../../Enums/ProcessConfigurationPositionType';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationStepPositionDto } from './ProcessConfigurationStepPositionDto';

export function createProcessConfigurationStepPositionEntityInfo<
  TEntity extends ProcessConfigurationStepPositionDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationStepPositionDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationStepPosition,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationStepPosition,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationStepPositionDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        processConfigurationStepId: {
          name: 'processConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          cascadeDeleteLocally: true
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: ProcessConfigurationPositionType.DEFAULT
        },
        namePrefix: {
          name: 'namePrefix',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        price: {
          name: 'price',
          defaultValue: null
        },
        priceGroup: {
          name: 'priceGroup',
          defaultValue: null
        },
        unit: {
          name: 'unit',
          defaultValue: null
        },
        discount: {
          name: 'discount',
          defaultValue: null
        },
        discountNote: {
          name: 'discountNote',
          defaultValue: null
        },
        flatRate: {
          name: 'flatRate',
          defaultValue: false
        },
        excludeFromMarkup: {
          name: 'excludeFromMarkup',
          defaultValue: false
        },
        ignoreExcludeFromMarkup: {
          name: 'ignoreExcludeFromMarkup',
          defaultValue: false
        },
        groupName: {
          name: 'groupName',
          defaultValue: null
        },
        subGroupName: {
          name: 'subGroupName',
          defaultValue: null
        },
        categoryName: {
          name: 'categoryName',
          defaultValue: null
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        deactivated: {
          name: 'deactivated',
          defaultValue: false
        },
        customId: {
          name: 'customId',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
