import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { BaseEntityDto } from './BaseEntityUtils';
import { EntityName } from './EntityName';
import {
  UserGroupSubEntityDto,
  UserGroupSubEntityFieldInfos,
  UserGroupSubEntityUtils
} from './UserGroupSubEntityUtils';

export class DefectSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): DefectSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerDefectId: {
        name: 'ownerDefectId',
        ref: EntityName.Defect,
        cascadeDeleteLocally: true
      }
    };
  }

  public static createOptionalBaseFieldInfos(): OptionalDefectSubEntityFieldInfos {
    return {
      ...UserGroupSubEntityUtils.createBaseFieldInfos(),
      ownerDefectId: {
        name: 'ownerDefectId',
        ref: EntityName.Defect,
        cascadeDeleteLocally: true,
        defaultValue: null
      }
    };
  }
}

export type DefectSubEntityFieldInfos = UserGroupSubEntityFieldInfos & {
  ownerDefectId: EntityFieldInfo<
    AppCommonSynchronizationEnvironmentTypes,
    DefectSubEntityDto,
    'ownerDefectId'
  >;
};

export type DefectSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerDefectId: string;
  };

export type OptionalDefectSubEntityFieldInfos = UserGroupSubEntityFieldInfos & {
  ownerDefectId: EntityFieldInfo<
    AppCommonSynchronizationEnvironmentTypes,
    OptionalDefectSubEntityDto,
    'ownerDefectId'
  >;
};

export type OptionalDefectSubEntityDto = BaseEntityDto &
  UserGroupSubEntityDto & {
    ownerDefectId: string | null;
  };
