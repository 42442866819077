import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { ImageHelper } from '../../classes/ImageHelper';
import { AbstractCameraStreamStrategy } from './AbstractCameraStreamStrategy';

export class MediaDevicesCameraStreamStrategy extends AbstractCameraStreamStrategy {
  private static readonly dataStoragePrefix =
    'VideoStreamer::selectedVideoDeviceInfo';

  private savedVideoDeviceInfo: MediaDeviceInfo | null = null;

  private videoElement: HTMLVideoElement | null = null;

  private activeStream: MediaStream | null = null;

  private videoDevices: Array<MediaDeviceInfo> = [];

  private selectedVideoDevice: MediaDeviceInfo | null = null;

  public async startStream(): Promise<void> {
    if (!this.savedVideoDeviceInfo) await this.loadSavedVideoDeviceInfo();

    await this.updateVideoDevices();
    await this.selectVideoDevice();
  }

  public async stopStream(): Promise<void> {
    if (this.activeStream) {
      const tracks = this.activeStream.getVideoTracks();
      if (tracks) {
        tracks[0]?.stop();
      }
      this.activeStream = null;
    }

    this.removeVideoElement();
  }

  public async takePicture(): Promise<string> {
    assertNotNullOrUndefined(
      this.videoElement,
      'cannot take picture without a video element'
    );

    return ImageHelper.getCurrentVideoImage(this.videoElement);
  }

  public async switchStream(): Promise<void> {
    const nextIndex = this.selectedVideoDevice
      ? this.videoDevices.indexOf(this.selectedVideoDevice) + 1
      : 0;
    const nextDevice =
      this.videoDevices.at(nextIndex) ?? this.videoDevices.at(0) ?? null;

    await this.setSelectedVideoDevice(nextDevice);
  }

  public async updateSize(): Promise<void> {
    // nothing to do here :)
  }

  public async setFocusPoint(): Promise<void> {
    // nothing to do here :)
  }

  public async getSupportedFlashModes(): Promise<
    Array<CameraPreviewFlashMode>
  > {
    return [];
  }

  public async getFlashMode(): Promise<CameraPreviewFlashMode | null> {
    return null;
  }

  public async setFlashMode(): Promise<void> {
    // nothing to do here :)
  }

  /**
   * @throws e: MediaStreamError
   */
  private async updateVideoDevices(): Promise<void> {
    await navigator.mediaDevices.getUserMedia({ video: true });
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.videoDevices = this.filterVideoDevices(devices);
  }

  private filterVideoDevices(
    devices: Array<MediaDeviceInfo>
  ): Array<MediaDeviceInfo> {
    return devices.filter((device) => {
      return device.kind === 'videoinput';
    });
  }

  private async selectVideoDevice(): Promise<void> {
    const videoDevice = this.videoDevices.find((videoDev) => {
      return (
        (this.selectedVideoDevice &&
          this.selectedVideoDevice.deviceId === videoDev.deviceId) ||
        (this.savedVideoDeviceInfo &&
          this.savedVideoDeviceInfo.deviceId === videoDev.deviceId)
      );
    });

    if (videoDevice) {
      await this.setSelectedVideoDevice(videoDevice);
    } else {
      const lastVideoDevice = this.videoDevices.at(-1);
      await this.setSelectedVideoDevice(lastVideoDevice ?? null);
    }
  }

  /**
   * also starts capturing with the selectedVideo device
   */
  private async setSelectedVideoDevice(
    device: MediaDeviceInfo | null
  ): Promise<void> {
    this.selectedVideoDevice = device;

    this.savedVideoDeviceInfo = device;
    this.saveSelectedVideoDeviceInfo();

    if (this.videoElement) this.videoElement.srcObject = null; // detach the old device

    await this.stopStream();

    if (!device) {
      return;
    }

    await this.startNewStream();
  }

  private async startNewStream(): Promise<void> {
    const constraint: MediaStreamConstraints = {
      audio: false,
      video: {
        deviceId: this.selectedVideoDevice
          ? { exact: this.selectedVideoDevice.deviceId }
          : undefined
      }
    };

    const stream = await navigator.mediaDevices.getUserMedia(constraint);

    this.addVideoElement();

    if (this.videoElement) this.videoElement.srcObject = stream;

    this.activeStream = stream;
  }

  private saveSelectedVideoDeviceInfo(): void {
    void DataStorageHelper.setItem(
      MediaDevicesCameraStreamStrategy.dataStoragePrefix,
      this.selectedVideoDevice
    );
  }

  private async loadSavedVideoDeviceInfo(): Promise<void> {
    const savedVideoDeviceInfo = await DataStorageHelper.getItem(
      MediaDevicesCameraStreamStrategy.dataStoragePrefix
    );
    if (savedVideoDeviceInfo) this.savedVideoDeviceInfo = savedVideoDeviceInfo;
  }

  private addVideoElement(): void {
    const videoElement = document.createElement('VIDEO') as HTMLVideoElement;
    videoElement.setAttribute('autoplay', '');

    const videoContainer = this.getVideoContainer();
    videoContainer.append(videoElement);

    this.videoElement = videoElement;
  }

  private removeVideoElement(): void {
    this.videoElement?.remove();
  }

  private getVideoContainer(): HTMLElement {
    const videoContainer = document.querySelector('#video-container');
    assertNotNullOrUndefined(
      videoContainer,
      'video container is not available'
    );

    return videoContainer as HTMLElement;
  }
}

export type MediaStreamError = {
  constraintName: string | null;
  message: string | null;
  name: MediaStreamErrorName;
};

export enum MediaStreamErrorName {
  AbortError = 'AbortError',
  NotAllowedError = 'NotAllowedError',
  NotFoundError = 'NotFoundError',
  NotReadableError = 'NotReadableError',
  OverconstrainedError = 'OverconstrainedError',
  SecurityError = 'SecurityError',
  TypeError = 'TypeError'
}
