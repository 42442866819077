import { autoinject } from 'aurelia-dependency-injection';
import { Disposable } from '../../../Utils/DisposableContainer';
import { AppEntityManager } from '../AppEntityManager';
import { EntityRemovedRegisterService } from '../EntityRemovedRegisterService';
import { AppEntityManagerEntityTypesByEntityName } from '../AppEntityManagerEntityTypesByEntityName';
import { EntityName } from '../types';
import { AppEntityRepository } from '../../base/AppEntityRepository';

@autoinject()
export class EntityIdUpdateService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly entityRemovedRegisterService: EntityRemovedRegisterService
  ) {}

  public registerUpdateConfig<
    TEntityName extends EntityName,
    TReferencedEntityName extends EntityName
  >(config: UpdateConfig<TEntityName, TReferencedEntityName>): Disposable {
    return this.entityRemovedRegisterService.registerCascadingListener(
      config.referencedEntityName,
      this.createReferencedEntityRemovedHandler(config)
    );
  }

  private createReferencedEntityRemovedHandler<
    TEntityName extends EntityName,
    TReferencedEntityName extends EntityName
  >(
    config: UpdateConfig<TEntityName, TReferencedEntityName>
  ): (
    removedEntity: AppEntityManagerEntityTypesByEntityName[TReferencedEntityName]['entity']
  ) => void {
    switch (config.type) {
      case UpdateConfigType.SET_TO_NULL:
        return (removedEntity) => {
          const repository =
            this.entityManager.entityRepositoryContainer.getByEntityName(
              config.entityName
            ) as AppEntityRepository<any>; // casted as any to prevent typescript from hanging/crashing
          for (const entity of repository.getAllIncludingInvisibleEntities()) {
            // sadly i found no way to type this correctly, so we have to use any here (somehow typescript doesn't recognize that config.key and entity are related to each other)
            const anyEntity = entity as any;
            if (anyEntity[config.key] === removedEntity.id) {
              anyEntity[config.key] = null;
              repository.update(entity);
            }
          }
        };

      case UpdateConfigType.CUSTOM_CALLBACK:
        return (removedEntity) => {
          const repository =
            this.entityManager.entityRepositoryContainer.getByEntityName(
              config.entityName
            ) as AppEntityRepository<any>; // casted as any to prevent typescript from hanging/crashing
          for (const entity of repository.getAllIncludingInvisibleEntities()) {
            if (config.callback(entity, removedEntity)) {
              repository.update(entity);
            }
          }
        };

      default:
        throw new Error(
          `unhandled type ${
            (config as UpdateConfig<TEntityName, TReferencedEntityName>).type
          }`
        );
    }
  }
}

export enum UpdateConfigType {
  SET_TO_NULL = 'setToNull',
  CUSTOM_CALLBACK = 'customCallback'
}

type BaseUpdateConfig<
  TType extends UpdateConfigType,
  TEntityName extends EntityName,
  TReferencedEntityName extends EntityName
> = {
  type: TType;
  entityName: TEntityName;
  referencedEntityName: TReferencedEntityName;
};

export type SetToNullUpdateConfig<
  TEntityName extends EntityName,
  TReferencedEntityName extends EntityName
> = BaseUpdateConfig<
  UpdateConfigType.SET_TO_NULL,
  TEntityName,
  TReferencedEntityName
> & {
  key: keyof AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'];
};

export type CustomCallbackUpdateConfig<
  TEntityName extends EntityName,
  TReferencedEntityName extends EntityName
> = BaseUpdateConfig<
  UpdateConfigType.CUSTOM_CALLBACK,
  TEntityName,
  TReferencedEntityName
> & {
  /**
   * should return true if the entity has been modified
   */
  callback: (
    entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'],
    removedEntity: AppEntityManagerEntityTypesByEntityName[TReferencedEntityName]['entity']
  ) => boolean;
};

export type UpdateConfig<
  TEntityName extends EntityName,
  TReferencedEntityName extends EntityName
> =
  | SetToNullUpdateConfig<TEntityName, TReferencedEntityName>
  | CustomCallbackUpdateConfig<TEntityName, TReferencedEntityName>;
