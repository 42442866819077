import { PropertyHelper } from '../EntityHelper/PropertyHelper';
import { PropertyType } from '../Types/Entities/Property/PropertyDto';

export class EntityGrouper<TEntity, TProperty> {
  private groupConfigurations: Array<GroupConfiguration>;
  private properties: Array<TProperty>;
  private cachedPropertiesByEntityId: CachedPropertyInfosByEntityIdMap | null =
    null;
  private entityIdGetter: EntityIdGetter<TEntity>;
  private propertyInfoGetter: PropertyInfoGetter<TProperty>;

  constructor(options: Options<TEntity, TProperty>) {
    this.groupConfigurations = options.groupConfigurations;
    this.properties = options.properties;
    this.entityIdGetter = options.entityIdGetter;
    this.propertyInfoGetter = options.propertyInfoGetter;
  }

  public setGroupConfigurations(
    groupConfigurations: Array<GroupConfiguration>
  ): void {
    this.groupConfigurations = groupConfigurations;
  }

  public setProperties(properties: Array<TProperty>): void {
    this.properties = properties;
    this.cachedPropertiesByEntityId = null;
  }

  public groupEntities(entities: Array<TEntity>): Array<EntityGroup<TEntity>> {
    const groupConfig = this.getGroupConfig();

    const groups: Array<EntityGroup<TEntity>> = [];

    for (const entity of entities) {
      if (groupConfig && groupConfig.type === 'property') {
        this.addEntityToPropertyGroup(entity, groupConfig.value, groups);
      } else {
        if (groupConfig) {
          console.error(
            `groupConfig Type "${groupConfig.type}" is not supported, falling back to default grouping`
          );
        }

        this.addEntityToDefaultGroup(entity, groups);
      }
    }

    groups.sort((a, b) => {
      const aName = a.name !== '' ? a.name : 'ZZZZZZZZZZZ'; // move empty strings to the end
      const bName = b.name !== '' ? b.name : 'ZZZZZZZZZZZ';
      return aName.localeCompare(bName);
    });

    return groups;
  }

  private getGroupConfig(): GroupConfiguration | null {
    const firstConfig = this.groupConfigurations[0];

    if (firstConfig) {
      if (this.groupConfigurations.length > 1) {
        console.error(
          "multiple group configs aren't supported yet. Continuing with only using the first one"
        );
      }

      return firstConfig;
    }

    return null;
  }

  /**
   * @param entity
   * @param propertyName
   * @param groups - gets modified in place
   */
  private addEntityToPropertyGroup(
    entity: TEntity,
    propertyName: string,
    groups: Array<EntityGroup<TEntity>>
  ): void {
    const propertyValue = this.getPropertyValue(entity, propertyName) || '';

    const group = groups.find((g) => g.name === propertyValue);
    if (group) {
      group.entities.push(entity);
    } else {
      groups.push({
        name: propertyValue,
        entities: [entity]
      });
    }
  }

  /**
   * @param entity
   * @param groups - gets modified in place
   */
  private addEntityToDefaultGroup(
    entity: TEntity,
    groups: Array<EntityGroup<TEntity>>
  ): void {
    const firstGroup = groups[0];

    if (firstGroup) {
      firstGroup.entities.push(entity);
    } else {
      groups.push({
        name: '',
        entities: [entity]
      });
    }
  }

  private getPropertyValue(
    entity: TEntity,
    propertyName: string
  ): string | null {
    const cachedPropertyInfosByPositionId =
      this.getCachedPropertiesByEntityId();
    const propertyInfos =
      cachedPropertyInfosByPositionId.get(this.entityIdGetter(entity)) || [];
    const propertyInfo = propertyInfos.find((p) => p.name === propertyName);

    if (propertyInfo?.type) {
      return PropertyHelper.getPropertyText(
        propertyInfo.type,
        propertyInfo.name,
        propertyInfo.value,
        propertyInfo.customChoice
      );
    }

    return null;
  }

  private getCachedPropertiesByEntityId(): CachedPropertyInfosByEntityIdMap {
    if (!this.cachedPropertiesByEntityId) {
      const map: CachedPropertyInfosByEntityIdMap = new Map();

      for (const property of this.properties) {
        const propertyInfo = this.propertyInfoGetter(property);
        if (!propertyInfo.referencedEntityId) {
          continue;
        }

        const propertiesForEntity = map.get(propertyInfo.referencedEntityId);
        if (propertiesForEntity) {
          propertiesForEntity.push(propertyInfo);
        } else {
          map.set(propertyInfo.referencedEntityId, [propertyInfo]);
        }
      }

      this.cachedPropertiesByEntityId = map;
    }

    return this.cachedPropertiesByEntityId;
  }
}

// a map from the entity id to the property
type CachedPropertyInfosByEntityIdMap = Map<string, Array<PropertyInfo>>;

export type EntityIdGetter<TEntity> = (entity: TEntity) => string;
export type PropertyInfoGetter<TProperty> = (entity: TProperty) => PropertyInfo;

export type PropertyInfo = {
  referencedEntityId: string | null;
  name: string | null;
  type: PropertyType | null;
  value: string | null;
  customChoice: string | null;
};

export type EntityGroup<TEntity> = {
  name: string;
  entities: Array<TEntity>;
};

export type Options<TEntity, TProperty> = {
  groupConfigurations: Array<GroupConfiguration>;
  properties: Array<TProperty>;
  entityIdGetter: EntityIdGetter<TEntity>;
  propertyInfoGetter: PropertyInfoGetter<TProperty>;
};

export enum GroupConfigurationType {
  Property = 'property'
}

export type GroupConfiguration = {
  type: GroupConfigurationType;
  value: string; // for type Property this is the name of the Property
};
