import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationMeasurePointType } from '../../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationMeasurePointTypesWidgetListItem {
  @bindable()
  public processConfigurationMeasurePointType: ProcessConfigurationMeasurePointType | null =
    null;

  @bindable()
  public enabled: boolean = false;

  @subscribableLifecycle()
  protected processConfigurationMeasurePointTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationMeasurePointType];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationMeasurePointTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationMeasurePointType,
        context: this as ProcessConfigurationMeasurePointTypesWidgetListItem,
        propertyName: 'processConfigurationMeasurePointType'
      });
  }

  protected handleMeasurePointTypeChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationMeasurePointType,
      "can't ProcessConfigurationMeasurePointTypesWidgetListItem.handleMeasurePointTypeChanged without processConfigurationMeasurePointType"
    );

    this.entityManager.processConfigurationMeasurePointTypeRepository.update(
      this.processConfigurationMeasurePointType
    );
  }

  protected handleDeleteMeasurePointTypeClick(): void {
    const processConfigurationMeasurePointType =
      this.processConfigurationMeasurePointType;
    assertNotNullOrUndefined(
      processConfigurationMeasurePointType,
      "can't ProcessConfigurationMeasurePointTypesWidgetListItem.handleDeleteMeasurePointTypeClick without processConfigurationMeasurePointType"
    );

    void Dialogs.deleteEntityDialog(processConfigurationMeasurePointType).then(
      () => {
        this.entityManager.processConfigurationMeasurePointTypeRepository.delete(
          processConfigurationMeasurePointType
        );
      }
    );
  }
}
