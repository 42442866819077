/**
 * this class is necessary because some browsers *cough* safari *cough* are too dumb to handle namespaces correctly
 * because apparently it's a good idea to name the href attribute in the xlink namespace `NS1:href` because `xlink:href` would be too cool
 */
export class SvgCleaner {
  public clean(str: string): string {
    return this.fixHrefs(str);
  }

  private fixHrefs(str: string): string {
    return str.replace(/NS\d+:href/gi, 'xlink:href');
  }
}
