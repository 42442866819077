import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createValueCalculationConfigEntityInfo } from 'common/Types/Entities/ValueCalculationConfig/createValueCalculationConfigEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ValueCalculationConfig } from './types';

export const valueCalculationConfigEntityInfo =
  createValueCalculationConfigEntityInfo<ValueCalculationConfig>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
