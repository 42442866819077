import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskOfferToProcessTaskDeviceEntityInfo } from 'common/Types/Entities/ProcessTaskOfferToProcessTaskDevice/createProcessTaskOfferToProcessTaskDeviceEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskOfferToProcessTaskDevice } from './types';

export const processTaskOfferToProcessTaskDeviceEntityInfo =
  createProcessTaskOfferToProcessTaskDeviceEntityInfo<ProcessTaskOfferToProcessTaskDevice>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
