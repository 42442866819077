import { autoinject, bindable } from 'aurelia-framework';
import { MomentInput } from 'moment';

import { DateUtils } from 'common/DateUtils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { PictureInfo } from '../picture-revision-dialog/picture-revision-dialog';
import { I18N } from 'aurelia-i18n';

/**
 * @event delete-revision-clicked
 * @event select-revision-clicked
 */
@autoinject()
export class PictureRevisionListEntry {
  @bindable public picture: Picture | null = null;
  @bindable public pictureInfo: PictureInfo | null = null;

  @bindable public showDeleteButton: boolean = false;

  private domElement: Element;

  constructor(
    element: Element,
    private readonly i18n: I18N
  ) {
    this.domElement = element;
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected handleDeleteRevisionClick(pictureInfo: PictureInfo): void {
    DomEventHelper.fireEvent<DeleteRevisionEvent>(this.domElement, {
      name: 'delete-revision-clicked',
      detail: pictureInfo
    });
  }

  protected handleSelectedRevisionChanged(pictureInfo: PictureInfo): void {
    DomEventHelper.fireEvent<SelectRevisionEvent>(this.domElement, {
      name: 'select-revision-clicked',
      detail: pictureInfo
    });
  }
}

export type DeleteRevisionEvent = NamedCustomEvent<
  'delete-revision-clicked',
  PictureInfo
>;
export type SelectRevisionEvent = NamedCustomEvent<
  'select-revision-clicked',
  PictureInfo
>;
