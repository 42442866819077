import { inject } from 'aurelia-framework';

import { PermissionBindingService } from '../../services/PermissionBindingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(AppEntityManager, PermissionBindingService, SubscriptionManagerService)
export class EditProcessTaskMeasurePointDialog {
  /** @type {import('../../services/PermissionBindingService').PermissionBindingHandle} */
  _permissionBindingHandle;
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint|null} */
  _measurePoint = null;
  /** @type {function|null} */
  _onDialogClosed = null;
  /** @type {boolean} */
  _measurePointIsEditable = false;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading>} */
  _measurePointReadings = [];
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  _processTaskGroup = null;

  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {PermissionBindingService} permissionBindingService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(
    entityManager,
    permissionBindingService,
    subscriptionManagerService
  ) {
    this._entityManager = entityManager;

    this._permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: '_measurePoint',
        editableProperty: '_measurePointIsEditable',
        userGroupPropertyOfEntity: 'ownerUserGroupId'
      }
    });

    this._subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * @param {TEditProcessTaskMeasurePointDialogOpenOptions} options
   */
  open(options) {
    this._measurePoint = options.measurePoint;
    this._onDialogClosed = options.onDialogClosed;
    this._processTaskGroup =
      this._entityManager.processTaskGroupRepository.getById(
        options.measurePoint.ownerProcessTaskGroupId
      );

    this._permissionBindingHandle.subscribe();
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointReading,
      this._updateMeasurePointReadings.bind(this)
    );
    this._updateMeasurePointReadings();

    if (this._dialog) {
      this._dialog.open();
    }
  }

  _handleDialogClosed() {
    const onClosed = this._onDialogClosed;

    this._measurePoint = null;
    this._onDialogClosed = null;
    this._permissionBindingHandle.unsubscribe();
    this._subscriptionManager.disposeSubscriptions();

    onClosed && onClosed();
  }

  _updateMeasurePointReadings() {
    if (this._measurePoint) {
      const readings =
        this._entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskMeasurePointId(
          this._measurePoint.id
        );

      readings.sort((a, b) => b.date.localeCompare(a.date));

      this._measurePointReadings = readings;
    } else {
      this._measurePointReadings = [];
    }
  }

  _handleMeasurePointChanged() {
    if (this._measurePoint) {
      this._entityManager.processTaskMeasurePointRepository.update(
        this._measurePoint
      );
    }
  }

  /**
   * @param {TEditProcessTaskMeasurePointDialogOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TEditProcessTaskMeasurePointDialogOpenOptions
 * @property {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
 * @property {function|null} [onDialogClosed]
 */
