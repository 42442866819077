import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskMeasurePoint } from './types';

export class ProcessTaskMeasurePointRepository extends AppEntityRepository<EntityName.ProcessTaskMeasurePoint> {
  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskMeasurePoint> {
    return this.getAll().filter((o) => o.ownerProcessTaskId === processTaskId);
  }
}
