import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { UserDefinedEntityConfigPropertyConfig } from './types';

export class UserDefinedEntityConfigPropertyConfigRepository extends AppEntityRepository<EntityName.UserDefinedEntityConfigPropertyConfig> {
  public getByUserDefinedEntityConfigId(
    userDefinedEntityConfigId: string
  ): Array<UserDefinedEntityConfigPropertyConfig> {
    return this.getAll().filter(
      (p) => p.userDefinedEntityConfigId === userDefinedEntityConfigId
    );
  }
}
