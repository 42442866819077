import { EntityIdInfoAdapter } from '@record-it-npm/synchro-common';
import { assertNotNullOrUndefined } from '../../../../Asserts';

export class ClientEntityIdInfoAdapter implements EntityIdInfoAdapter {
  public isId(id: string): boolean {
    return id.split('_').length === 4;
  }

  public getNanoSecondsTimeStampFromId(id: string): number {
    const timestampInMilliseconds = id.split('_')[2];
    assertNotNullOrUndefined(
      timestampInMilliseconds,
      `no timestamp found in id "${id}"`
    );

    return parseInt(timestampInMilliseconds) * 1000 * 1000;
  }

  public getNodeIdFromId(id: string): string {
    const sessionId = id.split('_')[1];
    assertNotNullOrUndefined(sessionId, `no sessionId found in id "${id}"`);

    return sessionId;
  }
}
