import { bindable, autoinject } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * a switch component which is basically a toggle-switch with unlimited choices
 * the switch can also have a multi row layout by using multiple ones, just assign the correct data-style-multi-row-* attribute based on it's position/order
 *
 * @attribute data-style-full-width - takes the whole available width
 * @attribute data-style-multi-row-first - this is the first one in a multi row layout
 * @attribute data-style-multi-row-middle - this is a middle one in a multi row layout
 * @attribute data-style-multi-row-last - this is the last one in a multi row layout
 * @attribute data-style-fill-height - fills the height of the parent container
 * @attribute data-style-hollow - uses the hollow button style
 * @attribute data-user-company-setting-big-mode - will only be set internally when the big mode is set via the user-company-settings
 * @event selected-choice-changed
 */
@autoinject()
export class SelectionSwitch<TChoice> {
  /**
   * and additional (optional) label to show on top of the choices
   */
  @bindable()
  public label: string | null = '';

  /**
   * if you pass an array of objects, you will also have to set the choiceLabelPropertyName
   *
   */
  @bindable()
  public choices: Array<TChoice> = [];

  /**
   * Choices that will be shown disabled and unselectable.
   */
  @bindable()
  public disabledChoices: Array<TChoice> = [];

  /**
   * the property of a choice which should be shown
   * set it to null to use the choice itself
   * null is only useful if the choices is a string array
   *
   * @type {string|null}
   */
  @bindable()
  public choiceLabelPropertyName: keyof TChoice | null = null;

  /**
   * The property of a choice which contains the translation key.
   * Can not be used with choiceLabelPropertyName (will override it)
   */
  @bindable()
  public choiceLabelTranslationKeyPropertyName: keyof TChoice | null = null;

  @bindable()
  public choiceLabelTranslationKeyParamsPropertyName: keyof TChoice | null =
    null;

  @bindable()
  public choiceValuePropertyName: keyof TChoice | null = null;

  @bindable()
  public value: any = null;

  /**
   * true if the user can deselect choices
   */
  @bindable()
  public allowDeselect: boolean = false;

  @bindable()
  public selectedChoice: TChoice | null = null;

  /**
   * number of choices per row
   * only useful in a multi row layout (look at the data-style-multi-row-* attributes)
   * this doesn't actually wrap the elements, it just sets the choices to a fixed width and adds a spacer at the end if there are less choices than the "multiRowElementCount"
   */
  @bindable()
  public multiRowChoiceCount: number | null = null;

  /**
   * set to true to allow html inside the label
   */
  @bindable()
  public labelContainsHtml: boolean = false;

  /**
   * gets set by the data-style-hollow attribute and passed to the button
   */
  @bindable()
  public dataStyleHollow: boolean | string = false;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public ignoreBigSelectionSwitchOption = false;

  private readonly subscriptionManager: SubscriptionManager;

  private ignoreNextSelectedChoiceChange: boolean = false;
  private ignoreNextValueChange: boolean = false;
  protected bigSelectionSwitch: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.bigSelectionSwitch',
        (bigSelectionSwitch) => {
          this.bigSelectionSwitch = bigSelectionSwitch;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected selectedChoiceChanged(): void {
    if (this.ignoreNextSelectedChoiceChange) {
      this.ignoreNextSelectedChoiceChange = false;
      return;
    }

    this.setValueForChoice(this.selectedChoice);
  }

  protected valueChanged(): void {
    if (this.ignoreNextValueChange) {
      this.ignoreNextValueChange = false;
      return;
    }

    const newSelectedChoice =
      this.choices.find((choice) => {
        return (
          (this.choiceValuePropertyName &&
            choice[this.choiceValuePropertyName] === this.value) ||
          choice === this.value
        );
      }) ?? null;

    if (this.selectedChoice !== newSelectedChoice) {
      this.ignoreNextSelectedChoiceChange = true;
      this.selectedChoice = newSelectedChoice;
    }
  }

  protected handleChoiceClicked(choice: TChoice): void {
    if (choice === this.selectedChoice) {
      if (this.allowDeselect) {
        this.setSelectedChoice(null);
      }
    } else {
      this.setSelectedChoice(choice);
    }
  }

  protected setSelectedChoice(choice: TChoice | null): void {
    const oldChoice = this.selectedChoice;

    if (oldChoice !== choice) {
      this.ignoreNextSelectedChoiceChange = true;
      this.selectedChoice = choice;

      this.setValueForChoice(choice);

      setTimeout(() => {
        this.fireSelectedChoiceChangedEvent();
      }, 0);
    }
  }

  protected setValueForChoice(choice: TChoice | null): void {
    const newValue =
      choice && this.choiceValuePropertyName
        ? choice[this.choiceValuePropertyName]
        : choice;
    if (this.value !== newValue) {
      this.ignoreNextValueChange = true;
      this.value = newValue;
    }
  }

  protected isEnabled(
    choice: TChoice,
    disabledChoices: Array<TChoice>,
    enabled: boolean
  ): boolean {
    const isChoiceEnabled = !disabledChoices.includes(choice);
    return enabled && isChoiceEnabled;
  }

  protected getChoiceLabel(
    choice: TChoice,
    choiceLabelPropertyName: keyof TChoice | null
  ): unknown {
    return choiceLabelPropertyName ? choice[choiceLabelPropertyName] : choice;
  }

  protected getChoiceLabelTranslationKey(
    choice: TChoice,
    choiceLabelTranslationKey: keyof TChoice | null
  ): unknown {
    return choiceLabelTranslationKey
      ? choice[choiceLabelTranslationKey]
      : undefined;
  }

  protected getChoiceLabelTranslationKeyParams(
    choice: TChoice,
    choiceLabelTranslationKeyParamsPropertyName: keyof TChoice | null
  ): unknown {
    return choiceLabelTranslationKeyParamsPropertyName
      ? choice[choiceLabelTranslationKeyParamsPropertyName]
      : {};
  }

  protected fireSelectedChoiceChangedEvent(): void {
    DomEventHelper.fireEvent<SelectedChoiceChangedEvent<TChoice>>(
      this.element,
      {
        name: 'selected-choice-changed',
        detail: {
          selectedChoice: this.selectedChoice
        }
      }
    );
  }

  protected getButtonWrapperStyle(
    multiRowChoiceCount: number | null
  ): Record<string, unknown> {
    return {
      flex:
        multiRowChoiceCount != null
          ? `0 0 ${(1 / multiRowChoiceCount) * 100}%`
          : null
    };
  }

  protected isActive(choice: TChoice, selectedChoice: TChoice | null): boolean {
    if (this.choiceValuePropertyName) {
      return (
        choice[this.choiceValuePropertyName] ===
        selectedChoice?.[this.choiceValuePropertyName]
      );
    }

    return choice === selectedChoice;
  }
}

export type SelectedChoiceChangedEvent<T> = NamedCustomEvent<
  'selected-choice-changed',
  {
    selectedChoice: T | null;
  }
>;
