import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Vector } from 'common/Geometry/Vector';
import { NumberUtils } from 'common/Utils/NumberUtils';
import {
  RepositionableElementSnappingFeature,
  SnappingPoint
} from '../../aureliaComponents/repositionable-element/features/RepositionableElementSnappingFeature/RepositionableElementSnappingFeature';
import {
  PositionAndSizeChangedEvent,
  RepositionableElementPositionAndSize
} from '../../aureliaComponents/repositionable-element/repositionable-element';
import { ZoomBox } from '../../aureliaComponents/zoom-box/zoom-box';
import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructurePictureArea } from '../../classes/EntityManager/entities/StructurePictureArea/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { IUtilsDebouncedFunction, Utils } from '../../classes/Utils/Utils';
import { Picture } from '../../picture/picture/picture';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class StructurePictureAreaPositionEditor {
  @bindable()
  public structurePictureArea: StructurePictureArea | null = null;

  @bindable()
  public structurePictureAreas: Array<StructurePictureArea> = [];

  @bindable()
  public picture: Picture | null = null;

  /**
   * Normally there will only be the structurePictureArea in here.
   * But because of the debouncing, it is possible that the structurePictureArea has changed while the timeout was pending.
   * If we take a naive approach and always only update the structurePictureArea in the repository, changes may not be saved in edge cases.
   */
  private readonly deferedStructurePictureAreasToUpdate: Set<StructurePictureArea> =
    new Set();
  private readonly updateDeferredStructurePictureAreasDebounced: IUtilsDebouncedFunction;
  private readonly snappingFeature = new RepositionableElementSnappingFeature();
  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;
  protected zoomBoxElement: HTMLElement | null = null;
  protected pictureElement: HTMLElement | null = null;
  protected zoomBox: ZoomBox | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.updateDeferredStructurePictureAreasDebounced = Utils.debounceFunction(
      this.updateDeferredStructurePictureAreas.bind(this),
      250
    );

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public update(): void {
    this.pictureResizer?.update();
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't StructurePictureAreaPositionEditor.attached without pictureElement"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't StructurePictureAreaPositionEditor.attached without zoomBoxElement"
    );
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructurePictureArea,
      this.updateSnappingPoints.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'structurePictureAreas',
      this.updateSnappingPoints.bind(this)
    );
    this.updateSnappingPoints();

    this.pictureResizer?.destroy();
    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();

    this.pictureResizer?.destroy();
    this.pictureResizer = null;
  }

  protected structurePictureAreaChanged(): void {
    if (this.isAttached) {
      this.updateSnappingPoints();
    }
  }

  private updateSnappingPoints(): void {
    const snappingPoints: Array<SnappingPoint> = [];
    for (const structurePictureArea of this.structurePictureAreas) {
      if (
        !structurePictureArea.topLeft ||
        !structurePictureArea.bottomRight ||
        structurePictureArea === this.structurePictureArea
      ) {
        continue;
      }

      snappingPoints.push(
        new Vector(
          structurePictureArea.topLeft.x,
          structurePictureArea.topLeft.y
        )
      );

      snappingPoints.push(
        new Vector(
          structurePictureArea.bottomRight.x,
          structurePictureArea.topLeft.y
        )
      );

      snappingPoints.push(
        new Vector(
          structurePictureArea.bottomRight.x,
          structurePictureArea.bottomRight.y
        )
      );

      snappingPoints.push(
        new Vector(
          structurePictureArea.topLeft.x,
          structurePictureArea.bottomRight.y
        )
      );
    }

    this.snappingFeature.setSnappingPoints(snappingPoints);
  }

  protected handlePositionAndSizeChanged(
    event: PositionAndSizeChangedEvent
  ): void {
    this.setSelectedStructurePictureAreaPositionAndSize({
      positionAndSize: event.detail.positionAndSize
    });
  }

  private setSelectedStructurePictureAreaPositionAndSize({
    positionAndSize
  }: {
    positionAndSize: PositionAndSizeWithoutRotation;
  }): void {
    assertNotNullOrUndefined(
      this.structurePictureArea,
      "can't StructurePictureAreasEditor.setSelectedStructurePictureAreaPositionAndSize without structurePictureArea"
    );

    const clampedPositionAndSize = this.clampPositionAndSize({
      positionAndSize
    });

    this.structurePictureArea.topLeft = {
      x: clampedPositionAndSize.left,
      y: clampedPositionAndSize.top
    };

    this.structurePictureArea.bottomRight = {
      x: clampedPositionAndSize.left + clampedPositionAndSize.width,
      y: clampedPositionAndSize.top + clampedPositionAndSize.height
    };

    this.deferedStructurePictureAreasToUpdate.add(this.structurePictureArea);
    this.updateDeferredStructurePictureAreasDebounced();
  }

  /**
   * caps the position and size to the boundaries
   */
  private clampPositionAndSize({
    positionAndSize
  }: {
    positionAndSize: PositionAndSizeWithoutRotation;
  }): PositionAndSizeWithoutRotation {
    const clampedTop = NumberUtils.clamp({
      value: positionAndSize.top,
      min: 0,
      max: 100 - positionAndSize.height
    });

    const clampedLeft = NumberUtils.clamp({
      value: positionAndSize.left,
      min: 0,
      max: 100 - positionAndSize.width
    });

    return {
      top: clampedTop,
      left: clampedLeft,
      width: positionAndSize.width,
      height: positionAndSize.height
    };
  }

  private updateDeferredStructurePictureAreas(): void {
    for (const area of this.deferedStructurePictureAreasToUpdate.values()) {
      this.entityManager.structurePictureAreaRepository.update(area);
    }

    this.deferedStructurePictureAreasToUpdate.clear();
  }

  @computedFrom(
    'structurePictureArea.topLeft.x',
    'structurePictureArea.topLeft.y',
    'structurePictureArea.bottomRight.x',
    'structurePictureArea.bottomRight.y'
  )
  protected get structurePictureAreaPositionAndSize(): RepositionableElementPositionAndSize | null {
    if (this.structurePictureArea) {
      if (
        this.structurePictureArea.topLeft &&
        this.structurePictureArea.bottomRight
      ) {
        return {
          top: this.structurePictureArea.topLeft.y,
          left: this.structurePictureArea.topLeft.x,
          height:
            this.structurePictureArea.bottomRight.y -
            this.structurePictureArea.topLeft.y,
          width:
            this.structurePictureArea.bottomRight.x -
            this.structurePictureArea.topLeft.x,
          rotation: 0
        };
      } else {
        return {
          top: 45,
          left: 45,
          height: 10,
          width: 10,
          rotation: 0
        };
      }
    }

    return null;
  }

  @computedFrom('picturePositionInfo')
  protected get repositionableElementContainerStyle(): Record<string, any> {
    if (!this.picturePositionInfo) {
      return {};
    }

    const getPercentSize = (size: number, containerSize: number): string => {
      return `${(100 * size) / containerSize}%`;
    };

    return {
      top: getPercentSize(
        this.picturePositionInfo.topLeftPosition.getY(),
        this.picturePositionInfo.containerSize.getY()
      ),
      left: getPercentSize(
        this.picturePositionInfo.topLeftPosition.getX(),
        this.picturePositionInfo.containerSize.getX()
      ),
      width: getPercentSize(
        this.picturePositionInfo.size.getX(),
        this.picturePositionInfo.containerSize.getX()
      ),
      height: getPercentSize(
        this.picturePositionInfo.size.getY(),
        this.picturePositionInfo.containerSize.getY()
      )
    };
  }

  @computedFrom('structurePictureAreas', 'structurePictureArea')
  protected get otherStructurePictureAreaInfos(): Array<OtherStructurePictureAreaInfo> {
    return this.structurePictureAreas
      .map<OtherStructurePictureAreaInfo>((structurePictureArea, index) => {
        return {
          structurePictureArea,
          index
        };
      })
      .filter(
        (info) => info.structurePictureArea !== this.structurePictureArea
      );
  }

  @computedFrom('structurePictureAreas', 'structurePictureArea')
  protected get indexOfStructurePictureArea(): number {
    if (!this.structurePictureArea) {
      return -1;
    }

    return this.structurePictureAreas.indexOf(this.structurePictureArea);
  }
}

type PositionAndSizeWithoutRotation = Omit<
  RepositionableElementPositionAndSize,
  'rotation'
>;

type OtherStructurePictureAreaInfo = {
  structurePictureArea: StructurePictureArea;
  index: number;
};
