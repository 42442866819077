import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { RequiredPropertiesValidator } from '../../../classes/RequiredPropertiesValidator/RequiredPropertiesValidator';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class ProcessTaskFormValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    appointment,
    configurationStep
  }: FinishAppointmentOptionsWithConfigurationStep): ProcessTaskFormValidatorResult {
    if (!configurationStep?.validFormsRequired) {
      return {
        type: ProcessTaskFormValidatorResultType.VALID
      };
    }

    return this.validateForms({ appointment });
  }

  private validateForms({
    appointment
  }: {
    appointment: ProcessTaskAppointment;
  }): ProcessTaskFormValidatorResult {
    const formRelations =
      this.entityManager.processTaskToProjectRepository.getFormProcessTaskToProjectsByProcessTaskAppointmentId(
        appointment.id
      );
    const formProjects = this.entityManager.projectRepository.getByIds(
      formRelations.map((relation) => relation.projectId)
    );

    for (const project of formProjects) {
      const projectResult = this.validateProject({ project });
      if (projectResult.type !== ProcessTaskFormValidatorResultType.VALID) {
        return projectResult;
      }
    }

    return {
      type: ProcessTaskFormValidatorResultType.VALID
    };
  }

  private validateProject({
    project
  }: {
    project: Project;
  }): ProcessTaskFormValidatorResult {
    if (
      !this.entityManager.projectMetadataManager.projectWasActualizedAfterJoining(
        project.id
      )
    ) {
      return {
        type: ProcessTaskFormValidatorResultType.FORM_NOT_AVAILABLE,
        formName: project.name
      };
    }

    const properties = this.entityManager.propertyRepository.getByProjectId(
      project.id
    );
    const validator = new RequiredPropertiesValidator({
      entityManager: this.entityManager,
      properties
    });

    const result = validator.validate();
    if (!result.success) {
      return {
        type: ProcessTaskFormValidatorResultType.REQUIRED_PROPERTY_ERROR,
        formName: project.name,
        propertyName: result.failedAtProperty.name
      };
    }

    return {
      type: ProcessTaskFormValidatorResultType.VALID
    };
  }
}

export type ProcessTaskFormValidatorResult =
  | SpecificResult<ProcessTaskFormValidatorResultType.VALID>
  | SpecificResult<
      ProcessTaskFormValidatorResultType.FORM_NOT_AVAILABLE,
      { formName: string | null }
    >
  | SpecificResult<
      ProcessTaskFormValidatorResultType.REQUIRED_PROPERTY_ERROR,
      { formName: string | null; propertyName: string | null }
    >;

export enum ProcessTaskFormValidatorResultType {
  VALID = 'valid',
  FORM_NOT_AVAILABLE = 'formNotAvailable',
  REQUIRED_PROPERTY_ERROR = 'requiredPropertyError'
}

type SpecificResult<
  TType extends ProcessTaskFormValidatorResultType,
  TExtension extends Record<string, any> = Record<string, unknown>
> = {
  type: TType;
} & TExtension;
