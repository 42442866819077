import { bindable, autoinject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @event edit-icon-clicked
 * @event remove-icon-clicked
 * @event copy-icon-clicked
 */
@autoinject()
export class CreateProcessTaskGroupPageWidget {
  @bindable()
  public title = '';

  @bindable()
  public showEditIcon = false;

  @bindable()
  public showRemoveIcon = false;

  @bindable()
  public showCopyIcon = false;

  @bindable()
  public hasWarning = false;

  constructor(private readonly element: Element) {}

  private handleEditIconClick(): void {
    this.fireEvent('edit-icon-clicked');
  }

  private handleRemoveIconClick(): void {
    this.fireEvent('remove-icon-clicked');
  }

  private handleCopyIconClick(): void {
    this.fireEvent('copy-icon-clicked');
  }

  private fireEvent(eventName: string): void {
    DomEventHelper.fireEvent(this.element, {
      name: eventName,
      detail: null
    });
  }
}
