import { autoinject } from 'aurelia-framework';

import { ValueCalculationConfigType } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';

import { AppEntityManager } from '../AppEntityManager';
import { Thing } from '../Thing/types';
import {
  ProjectValueCalculationConfig,
  ThingTypeProjectValueCalculationConfig,
  ThingTypeThingValueCalculationConfig,
  ThingValueCalculationConfig,
  ValueCalculationConfig,
  ValueCalculationConfigCreationEntity
} from './types';

@autoinject()
export class ValueCalculationConfigCreationService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createOrUpdateThingValueCalculationConfig(
    entityTemplate: SupportedTemplateValueCalculationConfig[ValueCalculationConfigType.THING],
    thing: Thing
  ): ThingValueCalculationConfig {
    const newConfig: ValueCalculationConfigCreationEntity = {
      ...this.createValueCalculationConfigFromTemplate(entityTemplate),
      reportTypeId: null,
      ownerThingId: thing.id,
      type: ValueCalculationConfigType.THING
    };

    const existingConfig = this.entityManager.valueCalculationConfigRepository
      .getByThingIdAndType(thing.id, ValueCalculationConfigType.THING)
      .find((x) => x.name === entityTemplate.name);
    if (existingConfig) {
      const updatedConfig = {
        ...existingConfig,
        ...newConfig
      };
      this.entityManager.valueCalculationConfigRepository.update(updatedConfig);
      return updatedConfig;
    }

    const newCreatedConfig =
      this.entityManager.valueCalculationConfigRepository.create(
        newConfig
      ) as ThingValueCalculationConfig;
    return newCreatedConfig;
  }

  public createOrUpdateProjectValueCalculationConfig(
    entityTemplate: SupportedTemplateValueCalculationConfig[ValueCalculationConfigType.PROJECT],
    thing: Thing
  ): ProjectValueCalculationConfig {
    const newConfig: ValueCalculationConfigCreationEntity = {
      ...this.createValueCalculationConfigFromTemplate(entityTemplate),
      ownerThingId: thing.id,
      type: ValueCalculationConfigType.PROJECT
    };

    const existingConfig = this.entityManager.valueCalculationConfigRepository
      .getByThingIdAndType(thing.id, ValueCalculationConfigType.PROJECT)
      .find((x) => x.name === entityTemplate.name);
    if (existingConfig) {
      const updatedConfig = {
        ...existingConfig,
        ...newConfig
      };
      this.entityManager.valueCalculationConfigRepository.update(updatedConfig);
      return updatedConfig;
    }

    const newCreatedConfig =
      this.entityManager.valueCalculationConfigRepository.create(
        newConfig
      ) as ProjectValueCalculationConfig;
    return newCreatedConfig;
  }

  private createValueCalculationConfigFromTemplate<
    T extends ValueCalculationConfigType
  >(
    entityTemplate: SupportedTemplateValueCalculationConfig[T]
  ): ValueCalculationConfigDuplicationTemplate {
    return {
      name: entityTemplate.name,
      calculationExpression: entityTemplate.calculationExpression,
      colorCodeConfig: entityTemplate.colorCodeConfig,
      showInEntityOverview: entityTemplate.showInEntityOverview,
      reportTypeId: entityTemplate.reportTypeId ?? null,
      ownerUserGroupId: entityTemplate.ownerUserGroupId
    };
  }
}

type ValueCalculationConfigDuplicationTemplate = Pick<
  ValueCalculationConfig,
  | 'ownerUserGroupId'
  | 'name'
  | 'calculationExpression'
  | 'colorCodeConfig'
  | 'showInEntityOverview'
> & {
  reportTypeId: Exclude<ValueCalculationConfig['reportTypeId'], undefined>;
};

export type SupportedTargetValueCalculationConfig =
  | ThingValueCalculationConfig
  | ProjectValueCalculationConfig;

export type SupportedTemplateValueCalculationConfig = {
  [ValueCalculationConfigType.THING]: ThingTypeThingValueCalculationConfig;
  [ValueCalculationConfigType.PROJECT]: ThingTypeProjectValueCalculationConfig;
};
