/**
 * returns the viewModel of the element
 *
 * @param {HTMLElement} element
 * @returns {*}
 */
window.vm = function (element) {
  //noinspection JSUnresolvedVariable
  return element.au.controller.viewModel;
};

/**
 * returns the currently shown viewModel of a router-view element
 *
 * @param {HTMLElement} element
 */
window.vmRouterView = function (element) {
  //noinspection JSUnresolvedVariable
  return element.au.controller.viewModel.viewSlot.children[0].controller
    .viewModel;
};

window.vmFirstRouterView = function () {
  return window.vmRouterView(document.querySelector('router-view'));
};

window.getMainApp = function () {
  return document.querySelector('[aurelia-app]').aurelia;
};
