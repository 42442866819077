/* global Msvg */

import { Tool } from './Tool';

export class LineTool extends Tool {
  _name = 'Linie';
  _icons = ['far fa-window-minimize', 'far fa-pen'];
  _machineName = 'line';

  /** @type {Msvg.Line|null} */
  _line = null;

  _cancel() {
    if (this._line) {
      this._msvg.remove(this._line);
    }
    super._cancel();
  }

  _reset() {
    super._reset();
    this._line = null;
  }

  /**
   * the position is already normalized
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    if (this._line) {
      this._modified();
      this._reset();
    } else {
      this._startNewLine(position);
    }
  }

  /**
   * the position is already normalized
   *
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseMovedHandler(event, position) {
    if (this._line) {
      this._line.setPosition2(position);
    }
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (!this._line) {
      this._startNewLine(this._dragStartPosition);
    }

    this._line.setPosition2(position);
  }

  /**
   * @param {Event} event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._line.setPosition2(position);
    this._msvg.append(this._line);

    this._modified();
    this._reset();
  }

  /**
   *
   * @param {Msvg.Point} position
   * @private
   */
  _startNewLine(position) {
    this._line = this._createLine(position);
    this._msvg.append(this._line);
  }

  /**
   *
   * @param {Msvg.Point} startPosition
   * @returns {Msvg.Line}
   * @private
   */
  _createLine(startPosition) {
    return new Msvg.Line()
      .setStroke(this._color)
      .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
      .setStrokeWidth(this._size)
      .setPosition1(startPosition)
      .setPosition2(startPosition);
  }

  _stylingChanged() {
    if (this._line) {
      this._line
        .setStroke(this._color)
        .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
        .setStrokeWidth(this._size);
    }
  }
}
