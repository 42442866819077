import { inject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';

/**
 * automatically positions the tooltip relative to the parent element
 *
 * @slot default - content of the tooltip
 */
@inject(Element, SubscriptionManagerService)
export class CustomTooltip {
  /**
   * delay between hovering the parent element and opening the tooltip
   *
   * @type {number}
   */
  @bindable delay = 250;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;
  /** @type {HTMLElement|null} */
  _refElement = null;
  /** @type {import('../../classes/Utils/Utils').UtilsRateLimitedFunction} */
  _openTooltipContentRateLimited;

  /** @type {import('../tooltip-content/tooltip-content').TooltipContent|null} */
  _tooltipContent = null;

  /**
   * @param {HTMLElement} element
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(element, subscriptionManagerService) {
    this._domElement = element;
    this._subscriptionManager = subscriptionManagerService.create();

    this._openTooltipContentRateLimited = Utils.rateLimitFunction(
      this._openTooltipContent.bind(this),
      this.delay
    );
  }

  attached() {
    this._refElement = /** @type {HTMLElement} */ (this._domElement.parentNode);
    this._subscriptionManager.subscribeToDomEvent(
      this._refElement,
      'mouseenter',
      this._handleMouseenter.bind(this)
    );
    this._subscriptionManager.subscribeToDomEvent(
      this._refElement,
      'mouseleave',
      this._handleMouseleave.bind(this)
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  delayChanged() {
    this._openTooltipContentRateLimited = Utils.rateLimitFunction(
      this._openTooltipContent.bind(this),
      this.delay
    );
  }

  _handleMouseenter() {
    this._openTooltipContentRateLimited();
  }

  _handleMouseleave() {
    if (!this._tooltipContent) {
      return;
    }

    this._openTooltipContentRateLimited.cancel();

    if (this._tooltipContent.isOpen()) {
      this._tooltipContent.close();
    }
  }

  _openTooltipContent() {
    if (!this._tooltipContent) {
      return;
    }

    if (!this._tooltipContent.isOpen()) {
      this._tooltipContent.open();
    }
  }
}
