import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createEntryToPersonEntityInfo } from 'common/Types/Entities/EntryToPerson/createEntryToPersonEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { EntryToPerson } from './types';

export const entryToPersonEntityInfo =
  createEntryToPersonEntityInfo<EntryToPerson>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
