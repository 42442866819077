import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentToProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToProcessTaskDevice/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskDeviceGroups,
  ProcessTaskDeviceGroupsForProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskDeviceDialog } from '../edit-process-task-device-dialog/edit-process-task-device-dialog';

@autoinject()
export class ProcessTaskAppointmentDevicesWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private relations: Array<ProcessTaskAppointmentToProcessTaskDevice> = [];
  protected groupedDevices: ProcessTaskDeviceGroups = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      this.updateRelations.bind(this)
    );
    this.updateRelations();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessTaskDeviceGroupsForProcessTaskIdComputer,
        callback: (groupedDevices) => {
          this.groupedDevices = groupedDevices;
        },
        createComputeData: () => {
          return this.processTask
            ? { processTaskId: this.processTask.id }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateRelations();
    }
  }

  private updateRelations(): void {
    if (this.processTaskAppointment) {
      this.relations =
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentId(
          this.processTaskAppointment.id
        );
    } else {
      this.relations = [];
    }
  }

  private handleDeviceCheckboxCheckedChanged(
    device: ProcessTaskDevice,
    event: CheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.processTaskAppointment,
      "can't ProcessTaskAppointmentDevices.handleDeviceCheckboxCheckedChanged without an appointment"
    );

    const existingRelation =
      this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentAndProcessTaskDeviceId(
        this.processTaskAppointment.id,
        device.id
      );

    if (event.detail.checked) {
      if (!existingRelation) {
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.create(
          {
            processTaskAppointmentId: this.processTaskAppointment.id,
            processTaskDeviceId: device.id,
            ownerProcessTaskId: this.processTaskAppointment.ownerProcessTaskId,
            ownerProcessTaskGroupId:
              this.processTaskAppointment.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTaskAppointment.ownerUserGroupId
          }
        );
      }
    } else {
      if (existingRelation) {
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.delete(
          existingRelation
        );
      }
    }

    this.updateRelations();
  }

  private handleEditDeviceClicked(device: ProcessTaskDevice): void {
    void EditProcessTaskDeviceDialog.open({
      processTaskDevice: device
    });
  }

  private deviceIsRelated(
    relations: Array<ProcessTaskAppointmentToProcessTaskDevice>,
    deviceId: string
  ): boolean {
    return !!relations.find((r) => r.processTaskDeviceId === deviceId);
  }
}
