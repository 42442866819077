export class PersonContactHelper {
  public static highlightedToFront<T extends { highlighted: boolean }>(
    persons: Array<T>
  ): Array<T> {
    return [
      ...persons.filter((p) => p.highlighted),
      ...persons.filter((p) => !p.highlighted)
    ];
  }
}
