import moment from 'moment';

import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DialogIconType } from 'common/Enums/DialogIconType';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import {
  ImportFromCsvFileDialog,
  CallbackParamsWithoutUserGroup
} from '../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { ProjectProperty } from '../classes/EntityManager/entities/Property/types';
import { Project } from '../classes/EntityManager/entities/Project/types';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData
} from '../aureliaComponents/csv-import-widget/csv-import-widget';
import { StructureTemplate } from '../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateUtils } from '../classes/EntityManager/entities/StructureTemplate/StructureTemplateUtils';
import { Dialogs } from '../classes/Dialogs';
import { GlobalCustomDialog } from '../dialogs/global-custom-dialog/global-custom-dialog';
import { ReportType } from '../classes/EntityManager/entities/ReportType/types';
import { ProjectCreationService } from '../classes/EntityManager/entities/Project/ProjectCreationService';

@autoinject()
export class ProjectCsvImporterService {
  private readonly commonCsvFields: FieldInfosFromConfig<CommonFieldInfoConfiguration> =
    [
      {
        field: 'reportTypeName',
        header: 'Berichtvorlage',
        type: FieldType.STRING,
        required: false,
        extraField: true,
        validateCallback: (parsedData) => {
          const valid = parsedData.every((data) => {
            return (
              !data.fields.reportTypeName ||
              this.entityManager.reportTypeRepository
                .getAll()
                .find(
                  (reportType) => reportType.name === data.fields.reportTypeName
                )
            );
          });

          return {
            valid,
            errorMsgTk: valid
              ? null
              : 'services.projectCsvImporterService.invalidReportTypeNames'
          };
        }
      },
      { field: 'name', header: 'Name', type: FieldType.STRING, required: true },
      { field: 'description', header: 'Beschreibung', type: FieldType.STRING },
      { field: 'longitude', header: 'Länge', type: FieldType.NUMBER },
      { field: 'latitude', header: 'Breite', type: FieldType.NUMBER }
    ];

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly projectCreationService: ProjectCreationService
  ) {}

  public async importProjectsFromCsvFile<TProjectType extends ProjectType>(
    file: File,
    projectType: TProjectType,
    thing: Thing
  ): Promise<void> {
    const wantBasicProject = projectType === ProjectType.BASIC;
    await ImportFromCsvFileDialog.open<FieldInfoConfiguration<TProjectType>>({
      file: file,
      fields: [ProjectType.B1300, ProjectType.INSPECT].includes(projectType)
        ? this.getCsvFieldsForStructureProject(projectType)
        : this.getCsvFieldsForBasicProject(),
      importFromCsvFileCallback: (detail) =>
        this.handleImportProjectsFromCsvFileSubmitted(
          detail,
          projectType,
          thing
        ),
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: wantBasicProject ? false : true,
      showOverwriteCheckbox: wantBasicProject ? true : false,
      entityNameTk: 'models.ProjectModel_plural'
    });
  }

  private getCsvFieldsForBasicProject(): FieldInfosFromConfig<BasicProjectFieldInfoConfiguration> {
    return [
      ...this.commonCsvFields,
      {
        field: 'customId',
        header: 'ID',
        type: FieldType.STRING,
        required: true
      }
    ];
  }

  private getCsvFieldsForStructureProject(
    projectType: ProjectType
  ): FieldInfosFromConfig<StructureProjectFieldInfoConfiguration> {
    return [
      ...this.commonCsvFields,
      {
        field: 'templateName',
        header: 'Objekttyp',
        type: FieldType.STRING,
        required: true,
        extraField: true,
        validateCallback: (parsedData) => {
          const structureTemplates =
            this.entityManager.structureTemplateRepository.getAll();
          const valid = parsedData.every((data) => {
            return structureTemplates.find((structureTemplate) => {
              return (
                (structureTemplate.status === StructureTemplateStatus.ACTIVE ||
                  structureTemplate.status ===
                    StructureTemplateStatus.PROVISIONALLY_ACTIVE) &&
                structureTemplate.type ===
                  StructureTemplateUtils.getStructureTemplateTypeFromProjectType(
                    projectType
                  ) &&
                structureTemplate.name === data.fields.templateName
              );
            });
          });

          return {
            valid,
            errorMsgTk: valid
              ? null
              : 'services.projectCsvImporterService.invalidTemplateNames'
          };
        }
      }
    ];
  }

  private async handleImportProjectsFromCsvFileSubmitted<
    TProjectType extends ProjectType
  >(
    config: CallbackParamsWithoutUserGroup<
      FieldInfoConfiguration<TProjectType>
    >,
    projectType: TProjectType,
    thing: Thing
  ): Promise<void> {
    try {
      const { warnings } = this.importProjects(thing, projectType, config);
      this.showCsvImportWarningsDialogIfApplicable(warnings);
    } catch (e) {
      void Dialogs.errorDialogTk(
        'services.projectCsvImporterService.csvImportError'
      );
      return;
    }
  }

  private showCsvImportWarningsDialogIfApplicable(
    errors: Array<Warning>
  ): void {
    const errorLines = [];
    for (const error of errors) {
      if (error.missingProperties.length > 0) {
        errorLines.push(
          this.i18n.tr(
            'services.projectCsvImporterService.csvImportWarningPropertiesNotFound',
            {
              line: error.line,
              name: error.reportTypeName,
              properties: error.missingProperties.join(', ')
            }
          )
        );
      } else {
        errorLines.push(
          this.i18n.tr(
            'services.projectCsvImporterService.csvImportWarningReportTypeNotFound',
            { line: error.line, name: error.reportTypeName }
          )
        );
      }
    }
    if (errorLines.length > 0) {
      void GlobalCustomDialog.open({
        titleTk: 'services.projectCsvImporterService.csvImportWarningTitle',
        text: errorLines.join('\n\n'),
        icon: DialogIconType.WARNING,
        buttons: [
          {
            textTk: 'general.ok',
            className: 'record-it-button-orange'
          }
        ]
      });
    }
  }

  private importProjects<TProjectType extends ProjectType>(
    thing: Thing,
    projectType: TProjectType,
    config: CallbackParamsWithoutUserGroup<FieldInfoConfiguration<TProjectType>>
  ): Result {
    switch (projectType) {
      default:
      case ProjectType.BASIC:
        return this.importBasicProjects(thing, config);

      case ProjectType.B1300:
      case ProjectType.INSPECT:
        return this.importStructureProjects({
          thing,
          projectType,
          detail: config
        });
    }
  }

  private importBasicProjects(
    thing: Thing,
    config: CallbackParamsWithoutUserGroup<BasicProjectFieldInfoConfiguration>
  ): Result {
    const lines = config.parsedContent;

    const fieldSpecification = config.fieldSpecification;

    const specialFieldNames = new Set<string>();
    for (const fieldValue of Object.values(fieldSpecification)) {
      specialFieldNames.add(fieldValue);
    }

    const warnings: Array<Warning> = [];

    for (const [lineIndex, lineData] of lines.entries()) {
      const reportTypeName = lineData.fields['reportTypeName'];

      let reportType = null;
      try {
        reportType = this.getReportType(reportTypeName);
      } catch (error) {
        warnings.push({
          line: lineIndex,
          reportTypeName: reportTypeName,
          missingProperties: []
        });
        continue;
      }

      const customId = lineData.fields.customId ?? null;
      const project = this.findOrCreateProjectWithCustomId({
        thing,
        customId,
        useExisting: config.overwrite,
        reportType
      });

      this.applyNewValuesToProject(project, lineData);
      project.customId = customId;

      this.entityManager.projectRepository.update(project);

      const { missingProperties } = this.updateExistingProperties(
        project.id,
        lineData.additionalFields,
        specialFieldNames
      );
      const missingPropertyNames = Object.keys(missingProperties);

      if (missingPropertyNames.length > 0) {
        warnings.push({
          line: lineIndex,
          reportTypeName: reportTypeName,
          missingProperties: missingPropertyNames
        });
      }
    }
    return { warnings };
  }

  private importStructureProjects(options: {
    thing: Thing;
    projectType: ProjectType.B1300 | ProjectType.INSPECT;
    detail: CallbackParamsWithoutUserGroup<StructureProjectFieldInfoConfiguration>;
  }): Result {
    const lines = options.detail.parsedContent;

    const warnings: Array<Warning> = [];

    for (const [lineIndex, lineData] of lines.entries()) {
      const templateName = lineData.fields['templateName'];
      const structureTemplates =
        this.entityManager.structureTemplateRepository.getAll();
      const structureTemplate = structureTemplates.find((s) => {
        return (
          (s.status === StructureTemplateStatus.ACTIVE ||
            s.status === StructureTemplateStatus.PROVISIONALLY_ACTIVE) &&
          s.type ===
            StructureTemplateUtils.getStructureTemplateTypeFromProjectType(
              options.projectType
            ) &&
          s.name === templateName
        );
      });
      if (!structureTemplate) {
        warnings.push({
          line: lineIndex,
          structureTemplateName: templateName,
          missingProperties: []
        });
        continue;
      }

      const reportTypeName = lineData.fields['reportTypeName'];

      let reportType = null;
      try {
        reportType = this.getReportType(reportTypeName);
      } catch (error) {
        warnings.push({
          line: lineIndex,
          reportTypeName: reportTypeName,
          missingProperties: []
        });
        continue;
      }

      const project = this.createStructureProject({
        thing: options.thing,
        projectType: options.projectType,
        structureTemplate,
        reportType
      });
      this.applyNewValuesToProject(project, lineData);

      this.entityManager.projectRepository.update(project);

      if (options.detail.fieldsAsProperties) {
        const { missingProperties } = this.updateExistingProperties(
          project.id,
          lineData.additionalFields,
          new Set()
        );
        for (const [name, value] of Object.entries(missingProperties)) {
          this.createProjectProperty(name, value, project);
        }
      }
    }

    return { warnings };
  }

  private getReportType(reportTypeName?: string): ReportType | null {
    if (!reportTypeName) return null;

    const reportType = this.entityManager.reportTypeRepository
      .getAll()
      .find((rt) => rt.name === reportTypeName);
    if (!reportType) throw new Error('report type not found');

    return reportType;
  }

  private createProjectProperty(
    name: string,
    value: string,
    project: Project
  ): void {
    this.entityManager.propertyRepository.create({
      name: name,
      value: value,
      type: PropertyType.TEXT,
      ownerUserGroupId: project.ownerUserGroupId,
      ownerProjectId: project.id,
      project: project.id,
      alwaysVisible: true
    });
  }

  private applyNewValuesToProject(
    project: Project,
    lineData: ParsedLineData<CommonFieldInfoConfiguration>
  ): void {
    project.name = lineData.fields.name ?? null;
    project.description = lineData.fields.description ?? null;
    project.latitude = lineData.fields.latitude ?? null;
    project.longitude = lineData.fields.longitude ?? null;
  }

  private findOrCreateProjectWithCustomId({
    thing,
    customId,
    useExisting,
    reportType
  }: {
    thing: Thing;
    customId: string | null;
    useExisting: boolean;
    reportType: ReportType | null;
  }): Project {
    let project = null;
    if (customId && useExisting) {
      project =
        this.entityManager.projectRepository.getFirstByThingIdAndCustomId(
          thing.id,
          customId
        );
    }
    if (!project) {
      project = this.createBasicProject({ thing, reportType });
    }
    return project;
  }

  private createStructureProject({
    thing,
    projectType,
    structureTemplate,
    reportType
  }: {
    thing: Thing;
    projectType: ProjectType.B1300 | ProjectType.INSPECT;
    structureTemplate: StructureTemplate;
    reportType: ReportType | null;
  }): Project {
    return this.projectCreationService.createProject({
      thing,
      projectType,
      structureTemplateId: structureTemplate.id,
      reportType
    });
  }

  private createBasicProject({
    thing,
    reportType
  }: {
    thing: Thing;
    reportType: ReportType | null;
  }): Project {
    return this.projectCreationService.createProject({
      thing,
      projectType: ProjectType.BASIC,
      reportType
    });
  }

  private updateExistingProperties(
    projectId: string,
    propertiesToImport: Record<string, string>,
    specialFieldNames: Set<string>
  ): { missingProperties: Record<string, string> } {
    const projectProperties =
      this.entityManager.propertyRepository.getByProjectId(projectId);

    const missingProperties: Record<string, string> = {};
    for (const [propertyName, propertyValue] of Object.entries(
      propertiesToImport
    )) {
      if (!propertyValue) {
        continue;
      }
      if (specialFieldNames.has(propertyName)) {
        continue;
      }
      const propertyToSet = projectProperties.find(
        (p) => p.name === propertyName
      );
      if (!propertyToSet) {
        missingProperties[propertyName] = propertyValue;
        continue;
      }
      this.setPropertyValue(propertyToSet, propertyValue);
      this.entityManager.propertyRepository.update(propertyToSet);
    }
    return { missingProperties };
  }

  private setPropertyValue(property: ProjectProperty, value: string): void {
    switch (property.type) {
      case 'nummer':
        property.value = value.replace(',', '.');
        break;
      case 'zeit':
        property.value = moment(value, ['h:m a', 'H:m']).toISOString();
        break;
      case 'datum':
        property.value = moment(value, ['DD.MM.YYYY']).toISOString();
        break;
      case 'checkbox':
        property.value = value ? 'true' : 'false';
        break;
      default:
        property.value = value;
    }
  }
}

type Result = {
  warnings: Array<Warning>;
};

export type Warning = {
  line: number;
  reportTypeName?: string;
  structureTemplateName?: string;

  /** if this array is empty, the report type with the given reportTypeName was not found */
  missingProperties: Array<string>;
};

type FieldInfoConfiguration<TProjectType extends ProjectType> =
  TProjectType extends ProjectType.B1300
    ? StructureProjectFieldInfoConfiguration
    : TProjectType extends ProjectType.INSPECT
      ? StructureProjectFieldInfoConfiguration
      : BasicProjectFieldInfoConfiguration;

type CommonFieldInfoConfiguration = {
  entityType: Project;
  fieldInfos: [
    {
      field: 'reportTypeName';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'description';
      type: FieldType.STRING;
    },
    {
      field: 'longitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'latitude';
      type: FieldType.NUMBER;
    }
  ];
};

type BasicProjectFieldInfoConfiguration = {
  entityType: Project;
  fieldInfos: [
    ...CommonFieldInfoConfiguration['fieldInfos'],
    {
      field: 'customId';
      type: FieldType.STRING;
    }
  ];
};

type StructureProjectFieldInfoConfiguration = {
  entityType: Project;
  fieldInfos: [
    ...CommonFieldInfoConfiguration['fieldInfos'],
    {
      field: 'templateName';
      type: FieldType.STRING;
    }
  ];
};
