import { autoinject } from 'aurelia-framework';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityPictureFilesManager } from '../../classes/EntityPictureFilesManager/EntityPictureFilesManager';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

@autoinject()
export class ProcessTaskGroupPicturesService {
  private readonly subscriptionManager: SubscriptionManager;
  private readonly manager: EntityPictureFilesManager;

  constructor(
    entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.manager = new EntityPictureFilesManager({
      baseEntityFolderPath: 'processTaskGroup',
      getEntityIdFromPictureFile: (pictureFile) => {
        return pictureFile.ownerProcessTaskGroupId;
      },
      entityManager,
      subscriptionManagerService
    });
  }

  public async init(): Promise<void> {
    if (!DeviceInfoHelper.isApp()) {
      return;
    }

    this.subscriptionManager.addDisposable(this.manager.subscribe());
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public async cleanup(): Promise<void> {
    if (!DeviceInfoHelper.isApp()) {
      return;
    }

    await this.manager.cleanup();
  }
}
