import { autoinject, bindable, observable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { RecordItModuleHelper } from '../../classes/RecordItModuleHelper';
import { Utils } from '../../classes/Utils/Utils';

import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';

@autoinject()
export class ThingByProjectTypeFilter {
  @bindable public things: Array<Thing> = [];

  @bindable public filteredThings: Array<Thing> = [];

  @bindable public projectType: ProjectType | null = null;

  protected projectTypeFilterOptions: Array<FilterOption> = [];

  private permissionBindingHandle: PermissionBindingHandle;

  @observable private currentUser: User | null = null;

  constructor(
    permissionBindingService: PermissionBindingService,
    private entityManager: AppEntityManager
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      currentUserPropertyName: 'currentUser'
    });
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.permissionBindingHandle.subscribe();
    this.updateFilterOptions();
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
  }

  // ********** Change Handlers **********

  protected thingsChanged(): void {
    this.updateFilteredThings();
  }

  protected projectTypeChanged(): void {
    this.updateFilteredThings();
  }

  protected currentUserChanged(): void {
    this.updateFilterOptions();
  }

  protected updateFilteredThings(): void {
    if (!this.projectType) {
      this.filteredThings = this.things.slice();
      return;
    }

    const thingIds: Set<string> = new Set(
      this.entityManager.projectRepository
        .getByType(this.projectType)
        .map((p) => p.thing)
    );
    const thingIdToProjectsMap = Utils.groupBy(
      this.entityManager.projectRepository.getAll(),
      (p) => p.thing
    );

    this.filteredThings = this.things.filter(
      (t) => thingIds.has(t.id) || !thingIdToProjectsMap.get(t.id)
    );
  }

  private updateFilterOptions(): void {
    if (!this.currentUser) {
      this.projectTypeFilterOptions = [];
      return;
    }

    const projectTypeFilterOptions: Array<FilterOption> = [];

    const modules = PermissionHelper.getAvailableModulesForUser(
      this.currentUser
    );
    for (const moduleName of modules) {
      const config =
        RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP[moduleName];
      assertNotNullOrUndefined(config, 'module is not defined');

      if (!config.projectType) continue;

      projectTypeFilterOptions.push({
        projectType: config.projectType,
        label: config.shortDisplayName
      });
    }
    this.projectTypeFilterOptions = projectTypeFilterOptions;
  }
}

type FilterOption = {
  label: string;
  projectType: ProjectType | null;
};
