import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event `selected-day-changed` triggered when the user selects a different day than selectedDay.
 */
@autoinject()
export class CalendarDaySelect {
  /** `to-view` binding only */
  @bindable public readonly selectedDay: Date | null = null;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private fireSelectedDayChanged(day: Date): void {
    DomEventHelper.fireEvent<SelectedDayChangedEvent>(this.element, {
      name: 'selected-day-changed',
      detail: { day }
    });
  }

  protected handlePrevWeekClicked(): void {
    assertNotNullOrUndefined(
      this.selectedDay,
      'cannot handlePrevWeekClicked without selectedDay'
    );

    this.fireSelectedDayChanged(
      DateUtils.getDateOneWeekBefore(this.selectedDay)
    );
  }

  protected handleDayClicked(day: Date): void {
    this.fireSelectedDayChanged(day);
  }

  protected handleNextWeekClicked(): void {
    assertNotNullOrUndefined(
      this.selectedDay,
      'cannot handleNextWeekClicked without selectedDay'
    );

    this.fireSelectedDayChanged(
      DateUtils.getDateOneWeekAfter(this.selectedDay)
    );
  }

  @computedFrom('selectedDay')
  protected get days(): Array<Date> {
    if (!this.selectedDay) return [];

    let currentDay = DateUtils.getStartDateOfWeek(this.selectedDay);
    const dates = [currentDay];
    for (let i = 1; i < 5; i++) {
      currentDay = DateUtils.getNextDay(currentDay);
      dates.push(currentDay);
    }
    return dates;
  }

  protected isSelected(selectedDay: Date, day: Date): boolean {
    return DateUtils.isOnSameDay(selectedDay, day);
  }
}

export type SelectedDayChangedEvent = NamedCustomEvent<
  'selected-day-changed',
  { day: Date }
>;
