import { autoinject } from 'aurelia-framework';
import { SocketService } from '../../../../services/SocketService';
import { Dialogs } from '../../../Dialogs';
import { User } from './types';

@autoinject()
export class UserEmailService {
  constructor(private readonly socketService: SocketService) {}

  /**
   * email will not be validated!
   */
  public sendEmailVerification(user: User, newEmail: string): void {
    this.socketService.sendEmailVerification(user.id, newEmail, (response) => {
      if (response.success) {
        Dialogs.successDialog('Mail versandt.', response.message);
      } else {
        void Dialogs.errorDialog(
          'Bestätigungs E-Mail konnte nicht versandt werden!',
          response.message ? response.message : 'Es ist ein Fehler aufgetreten!'
        );
      }
    });
  }
}
