import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event picture-changed - fired when tags get added/removed to the picture, detail: {picture: Object}
 */

@autoinject()
export class PictureTagsWidget {
  @bindable public picture: Picture | null = null;

  @bindable public mainEntityIdField: string | null = null;
  @bindable public mainEntityId: string | null = null;

  @bindable public ownerUserGroupId: string | null = null;
  @bindable public ownerProjectId: string | null = null;

  @bindable public enabled = false;

  protected tags: Array<Tag> = [];
  private availableTags: Array<Tag> = [];

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    private entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(EntityName.Tag, () => {
      this.updateAvailableTags();
      this.updatePictureTags();
    });
    this.subscriptionManager.subscribeToModelChanges(EntityName.Picture, () => {
      this.updatePictureTags();
    });
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected mainEntityIdChanged(): void {
    this.updateAvailableTags();
    this.updatePictureTags();
  }

  protected pictureChanged(): void {
    this.updatePictureTags();
  }

  private updateAvailableTags(): void {
    if (!this.mainEntityIdField || !this.mainEntityId) {
      this.availableTags = [];
    } else {
      if (
        this.mainEntityIdField === 'thing' ||
        this.mainEntityIdField === 'ownerThingId'
      ) {
        this.availableTags = this.entityManager.tagRepository.getByThingId(
          this.mainEntityId
        );
      } else if (
        this.mainEntityIdField === 'project' ||
        this.mainEntityIdField === 'ownerProjectId'
      ) {
        this.availableTags = this.entityManager.tagRepository.getByProjectId(
          this.mainEntityId
        );
      } else {
        throw new Error(
          `unsupported mainEntityIdField "${this.mainEntityIdField}"`
        );
      }
    }
  }

  private updatePictureTags(): void {
    const picture = this.picture;

    if (!picture?.tagIds) {
      this.tags = [];
      return;
    }

    this.tags = this.availableTags.filter((tag) => {
      return picture.tagIds.includes(tag.id);
    });
  }

  protected handleTagCreated(newTagName: string): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't PictureTagsWidget.handleTagCreated without a picture"
    );
    assertNotNullOrUndefined(
      this.mainEntityId,
      "can't PictureTagsWidget.handleTagCreated without a mainEntityId"
    );
    assertNotNullOrUndefined(
      this.ownerUserGroupId,
      "can't PictureTagsWidget.handleTagCreated without a ownerUserGroupId"
    );

    this.removeNonExistingTagsFromPicture();
    let newTag;

    if (
      this.mainEntityIdField === 'thing' ||
      this.mainEntityIdField === 'ownerThingId'
    ) {
      newTag = this.entityManager.tagRepository.getOrCreateThingTag(
        newTagName,
        this.mainEntityId,
        this.ownerUserGroupId
      );
    } else if (this.mainEntityIdField === 'project') {
      newTag = this.entityManager.tagRepository.getOrCreateProjectTag(
        newTagName,
        this.mainEntityId,
        this.ownerUserGroupId
      );
    } else {
      throw new Error(
        `unsupported mainEntityIdField "${this.mainEntityIdField}"`
      );
    }

    if (!this.picture.tagIds) this.picture.tagIds = [];
    this.picture.tagIds.push(newTag.id);

    this.handlePictureChanged();
  }

  protected handleTagRemoved(tagToRemove: Tag): void {
    this.removeNonExistingTagsFromPicture();
    this.removeTagFromPicture(tagToRemove);
  }

  private removeTagFromPicture(tagToRemove: Tag): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot remove tag from picture without a picture'
    );

    this.picture.tagIds = this.picture.tagIds.filter((tagId) => {
      return tagId !== tagToRemove.id;
    });
    this.handlePictureChanged();
  }

  private handlePictureChanged(): void {
    DomEventHelper.fireEvent<PictureChangedEvent>(this.domElement, {
      name: 'picture-changed',
      detail: {
        picture: this.picture
      },
      bubbles: true
    });
  }

  private removeNonExistingTagsFromPicture(): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot remove non existing tags from picture without a picture'
    );

    this.picture.tagIds = this.picture.tagIds.filter((tagId) => {
      return this.availableTags.find((tag) => tag.id === tagId);
    });
  }
}

export type PictureChangedEvent = NamedCustomEvent<
  'picture-changed',
  { picture: Picture | null }
>;
