import { OptionalProperties } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type FullProject<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    customId: string | null;
    name: string | null;
    description: string | null;
    created: number;
    archived: boolean;

    /** @deprecated */
    usergroup: TId;

    importnotes: Array<IImportNote> | null;
    customTagsInEntries: Array<string>;
    longitude: number | null;
    latitude: number | null;

    /** @deprecated - 2020-07-16 */
    template_name: string | null;

    structureTemplateId: TId | null;
    projectType: ProjectType;
    report_type: TId | null;
    thing: TId;

    pictureCount: number | null;
    sequenceNumber: number | null;
  };

export type BasicProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & {
      projectType: ProjectType.BASIC;
      template_name: null;
      structureTemplateId: null;
    },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type GalleryProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & {
      projectType: ProjectType.GALLERY;
      template_name: null;
      structureTemplateId: null;
    },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type B1300ProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & { projectType: ProjectType.B1300 },
    Exclude<TOptionalProperties, 'structureTemplateId'>
  >,
  TPropertiesToRemove
>;

export type InspectProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & { projectType: ProjectType.INSPECT },
    Exclude<TOptionalProperties, 'structureTemplateId'>
  >,
  TPropertiesToRemove
>;

export type ChecklistProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & {
      projectType: ProjectType.CHECKLIST;
      template_name: null;
      structureTemplateId: null;
    },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type OperationsProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullProject<TId, TDate> & {
      projectType: ProjectType.OPERATIONS;
      template_name: null;
      structureTemplateId: null;
    },
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export type ProjectDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProject<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullProject<TId, TDate> = never
> =
  | BasicProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>
  | GalleryProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>
  | B1300ProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>
  | InspectProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>
  | ChecklistProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>
  | OperationsProjectDto<TId, TDate, TOptionalProperties, TPropertiesToRemove>;

export type ProjectAllSubtypesIntersection<
  TId extends IdType,
  TDate extends DateType
> = FullProject<TId, TDate>;

export interface IImportNote {
  tag: string;
  value: string;
}

export enum ProjectType {
  BASIC = 'basic',
  GALLERY = 'gallery',
  B1300 = 'b1300',
  INSPECT = 'inspect',
  CHECKLIST = 'checklist',
  OPERATIONS = 'operations'
}

export type StructureTemplateProjectType =
  | ProjectType.B1300
  | ProjectType.INSPECT;

export const structureProjectTypes = (): Array<ProjectType> => [
  ProjectType.INSPECT,
  ProjectType.B1300
];

export const projectTypesOnObjectPage = (): Array<ProjectType> => [
  ProjectType.BASIC,
  ProjectType.INSPECT,
  ProjectType.B1300
];

export const colorCodedProjectTypes = (): Array<ProjectType> => [
  ...projectTypesOnObjectPage(),
  ProjectType.GALLERY
];
