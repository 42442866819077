import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { TAppointmentClickedEvent } from '../process-task-appointment-related-appointments-list/process-task-appointment-related-appointments-list';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentUtils } from '../../classes/EntityManager/entities/ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessTaskAppointmentProperty } from '../../classes/EntityManager/entities/Property/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskRecurringAppointment } from 'src/classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { OnAppointmentSplitOffEvent } from '../edit-process-task-recurring-appointment-widget/corresponding-appointments-widget/corresponding-appointments-widget';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessTaskAppointmentDialog {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly appointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private permissionBindingHandle: PermissionBindingHandle;

  private appointment: ProcessTaskAppointment | null = null;
  protected recurringAppointment: ProcessTaskRecurringAppointment | null = null;
  protected showProcessTaskInfo: boolean = false;
  /**
   * appointment was in past and not finished while opening the dialog
   */
  protected inPastAndNotFinished: boolean = false;
  private inPastAndNotFinishedUpdated: boolean = false;
  protected processTaskGroup: ProcessTaskGroup | null = null;
  protected processTask: ProcessTask | null = null;
  protected processConfiguration: ProcessConfiguration | null = null;
  protected properties: Array<ProcessTaskAppointmentProperty> = [];
  private onDialogClosed: (() => void) | null = null;
  protected processTaskGroupIsEditable: boolean = false;
  protected relatedPositions: Array<ProcessTaskPosition> = [];
  protected signatureImageUrl: string | null = null;
  private appointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap = new Map();
  protected showColors: boolean = false;

  private dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'processTaskGroup',
        editableProperty: 'processTaskGroupIsEditable',
        userGroupPropertyOfEntity: 'ownerUserGroupId'
      }
    });
    this.processTaskLoggingService = processTaskLoggingService;
    this.subscriptionManager = subscriptionManagerService.create();

    this.appointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        context: this,
        entityName: EntityName.ProcessTaskAppointment,
        expression: 'appointment'
      });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        context: this,
        entityName: EntityName.ProcessTask,
        expression: 'processTask'
      });
  }

  public static async open(options: IOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IOpenOptions): void {
    this.appointment = options.appointment;
    this.showProcessTaskInfo = options.showProcessTaskInfo ?? false;

    this.inPastAndNotFinishedUpdated = false;
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (appointmentDateInfoMap) => {
          this.appointmentDateInfoMap = appointmentDateInfoMap;
          this.updateInPastAndNotFinished();
        }
      })
    );

    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.appointment.ownerProcessTaskGroupId
      );
    this.processTask = this.entityManager.processTaskRepository.getById(
      this.appointment.ownerProcessTaskId
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () => {
          return this.appointment?.ownerProcessTaskGroupId
            ? { processTaskGroupId: this.appointment.ownerProcessTaskGroupId }
            : null;
        },
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'appointment.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (processConfiguration) => {
          this.processConfiguration = processConfiguration;
        }
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    const signatureFile =
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        this.appointment.id
      )[0];
    if (signatureFile) {
      this.signatureImageUrl =
        GeneralFileUtils.getFullOnlineFilePathForGeneralFile(signatureFile);
    } else {
      this.signatureImageUrl = null;
    }
    this.onDialogClosed = options.onDialogClosed || null;

    if (options.appointment.recurringAppointmentId) {
      this.recurringAppointment =
        this.entityManager.processTaskRecurringAppointmentRepository.getById(
          options.appointment.recurringAppointmentId
        );
    } else {
      this.recurringAppointment = null;
    }

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingsService.bindSettingProperty(
        'operations.appointmentColors',
        (value) => {
          this.showColors = value;
        }
      )
    );

    this.permissionBindingHandle.subscribe();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.appointment = null;
    this.onDialogClosed = null;
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();

    if (this.dialog) {
      this.dialog.selectTab('general');
    }

    onClosed && onClosed();
  }

  private updateInPastAndNotFinished(): void {
    if (this.inPastAndNotFinishedUpdated) {
      return;
    }

    const info = this.appointment
      ? this.appointmentDateInfoMap.get(this.appointment.id)
      : null;
    if (this.appointment && info) {
      this.inPastAndNotFinished =
        ProcessTaskAppointmentUtils.isInPastAndNotFinished(
          this.appointment.finishedAt,
          info.dateTo
        );
      this.inPastAndNotFinishedUpdated = true;
    } else {
      this.inPastAndNotFinished = false;
    }
  }

  private updateProperties(): void {
    if (this.appointment) {
      this.properties =
        this.entityManager.propertyRepository.getByProcessTaskAppointmentId(
          this.appointment.id
        );
    } else {
      this.properties = [];
    }
  }

  private handleAppointmentChanged(
    property: keyof ProcessTaskAppointment
  ): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't handleAppointmentChanged without a appointment"
    );
    this.entityManager.processTaskAppointmentRepository.update(
      this.appointment
    );
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.appointment,
      property,
      displayNameAtLogTime: this.appointment.name
    });
  }

  protected handleEditPositionsClick(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't handleEditPositionsClick without a dialog"
    );
    this.dialog.selectTab('positions');
  }

  protected handleMarkAsFinishedClick(): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't handleMarkAsFinishedClick without an appointment"
    );
    this.appointment.finishedAt = new Date().toISOString();
    this.handleAppointmentChanged('finishedAt');
  }

  protected handleMarkAsOpenedClick(): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't handleMarkAsOpenedClick without an appointment"
    );
    this.appointment.openedAt = new Date().toISOString();
    this.handleAppointmentChanged('openedAt');
  }

  protected handleNavigationTriggered(): void {
    this.dialog?.close();
  }

  protected handleAppointmentClicked(event: TAppointmentClickedEvent): void {
    this.open({
      appointment: event.detail.appointment,
      onDialogClosed: this.onDialogClosed
    });
  }

  protected handleAppointmentSplitOff(event: OnAppointmentSplitOffEvent): void {
    this.handleDialogClosed();
    this.open({
      appointment: event.detail.processTaskAppointment
    });
  }
}

export interface IOpenOptions {
  appointment: ProcessTaskAppointment;
  showProcessTaskInfo?: boolean;
  onDialogClosed?: (() => void) | null;
}
