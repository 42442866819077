import { inject, BindingEngine, Lazy } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { SocketService } from './SocketService';
import { ComputedValueService } from '../computedValues/ComputedValueService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(
  EventAggregator,
  BindingEngine,
  SocketService,
  ComputedValueService,
  Lazy.of(AppEntityManager)
)
export class SubscriptionManagerService {
  /**
   * @param {EventAggregator} eventAggregator
   * @param {BindingEngine} bindingEngine
   * @param {SocketService} socketService
   * @param {SocketService} socketService
   * @param {ComputedValueService} computedValueService
   * @param {() => AppEntityManager} getEntityManager
   */
  constructor(
    eventAggregator,
    bindingEngine,
    socketService,
    computedValueService,
    getEntityManager
  ) {
    this._eventAggregator = eventAggregator;
    this._bindingEngine = bindingEngine;
    this._socketService = socketService;
    this._computedValueService = computedValueService;
    this._getEntityManager = getEntityManager;
  }

  /**
   * @returns {SubscriptionManager} the new instance
   */
  create() {
    return new SubscriptionManager(
      this._eventAggregator,
      this._bindingEngine,
      this._socketService,
      this._computedValueService,
      this._getEntityManager
    );
  }
}
