import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskAppointmentContact } from './types';

export class ProcessTaskAppointmentContactRepository extends AppEntityRepository<EntityName.ProcessTaskAppointmentContact> {
  public getByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentContact> {
    return this.getAll().filter(
      (c) => c.processTaskAppointmentId === processTaskAppointmentId
    );
  }
}
