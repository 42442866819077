import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class GalleryThingPlanBasedEditDefectOverlay {
  @subscribableLifecycle()
  protected defectPermissionsHandle:
    | EntityNameToPermissionsHandle[EntityName.Defect]
    | null = null;

  constructor(private readonly permissionsService: PermissionsService) {}

  public static async open(
    options: GalleryThingPlanBasedEditDefectOverlayOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected defect: Defect | null = null;
  protected dialog: RecordItFullScreenDialog | null = null;

  public open(options: GalleryThingPlanBasedEditDefectOverlayOptions): void {
    assertNotNullOrUndefined(this.dialog, "can't open without dialog");

    this.defect = options.defect;

    this.defectPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Defect,
        entity: this.defect
      });

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.defect = null;
    this.defectPermissionsHandle = null;
  }
}

export type GalleryThingPlanBasedEditDefectOverlayOptions = {
  defect: Defect;
};
