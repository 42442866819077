import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GisService } from '../../services/GisService';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectCoordinatesOnMapDialog } from '../../dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';

@autoinject()
export class GalleryThingEntryToPersonWidget {
  @bindable private entry: Entry | null = null;
  @bindable private entryPicture: Picture | null = null;
  @bindable private thingId: string | null = null;
  @bindable protected enabled = false;

  /**
   * The `onMapReady` callback for the coordinate-input.
   */
  @bindable protected onMapReady: ((map: BasemapMap) => void) | null = null;

  protected municipalityCode: string | null = null;

  private owners: Array<Person> = [];

  protected mainContactId: string | null = null;

  private personIdToAdd: string | null = null;

  protected PersonUtils = PersonUtils; // for usage in the view

  private subscriptionManager: SubscriptionManager;

  constructor(
    private i18n: I18N,
    protected router: Router,
    private entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private gisService: GisService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.EntryToPerson,
      this.updateOwners.bind(this)
    );
    this.updateOwners();
  }

  public detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected entryChanged(): void {
    this.updateOwners();
  }

  private updateOwners(): void {
    this.owners = [];
    if (!this.entry) return;
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(this.entry.id);
    for (const entryToPersonEntry of entryToPersonEntries) {
      const owner = this.entityManager.personRepository.getById(
        entryToPersonEntry.personId
      );
      if (owner) {
        this.owners.push(owner);
      }
      if (entryToPersonEntry.mainContact) {
        this.mainContactId = entryToPersonEntry.personId;
      }
    }
  }

  protected async handleNewCoordinatesReceived(
    latitude: number,
    longitude: number
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.thingId,
      "can't handleNewCoordinatesReceived without thingId"
    );
    assertNotNullOrUndefined(
      this.entry,
      "can't handleNewCoordinatesReceived without entry"
    );

    const municipalityCode = this.entityManager.propertyRepository
      .getByThingId(this.thingId)
      .find((property) => property.name === 'Gemeindecode')?.value;
    if (!municipalityCode) {
      throw new Error(`no municipalityCode for "${this.thingId}" found`);
    }

    await this.gisService.syncGstInfoWithEntryForAdditionalOwners(
      this.entry,
      latitude,
      longitude,
      municipalityCode
    );
    this.updateOwners();
  }

  protected async handleSelectCoordinateOnMapClick(): Promise<void> {
    const oldLat = this.entryPicture?.coords?.latitude;
    const oldLong = this.entryPicture?.coords?.longitude;

    void SelectCoordinatesOnMapDialog.open({
      lat: oldLat,
      long: oldLong,
      onCoordinateSelected: async (newLat, newLong) => {
        if (oldLat !== newLat || oldLong !== newLong) {
          await this.handleNewCoordinatesReceived(newLat, newLong);
        }
      },
      onMapReady: (map) => {
        this.onMapReady && this.onMapReady(map);
      }
    });
  }

  protected handleAddPerson(): void {
    const personId = this.personIdToAdd;
    if (!this.entry || !personId) return;
    const entryToPersonEntries =
      this.entityManager.entryToPersonRepository.getByEntryId(this.entry.id);
    const existingEntryToPerson = entryToPersonEntries.find(
      (entryToPerson) => entryToPerson.personId === personId
    );
    if (!existingEntryToPerson) {
      this.entityManager.entryToPersonRepository.create({
        personId: personId,
        entryId: this.entry.id,
        ownerProjectId: this.entry.ownerProjectId,
        ownerUserGroupId: this.entry.ownerUserGroupId
      });
    } else {
      Dialogs.successDialog(
        this.i18n.tr(
          'galleryThing.entryToPersonWidget.personAlreadyLinkedDialogText'
        )
      );
    }
  }

  protected async handleRemovePerson(personId: string): Promise<void> {
    try {
      await Dialogs.deleteDialogTk(
        'galleryThing.entryToPersonWidget.removePersonDialogText'
      );
      if (!this.entry || !personId) return;
      const entryToPersonEntries =
        this.entityManager.entryToPersonRepository.getByEntryId(this.entry.id);
      const entryToPersonToRemove = entryToPersonEntries.find(
        (entryToPerson) => entryToPerson.personId === personId
      );
      if (entryToPersonToRemove)
        this.entityManager.entryToPersonRepository.delete(
          entryToPersonToRemove
        );
    } catch (e) {
      if (e.message !== 'DialogCanceled') throw e;
    }
  }
}
