import { autoinject } from 'aurelia-framework';
import { EmbeddedCamera } from '../../aureliaComponents/embedded-camera/embedded-camera';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskProjectService } from '../ProcessTaskProjectService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import {
  PictureCapturedHandlerDataForName,
  PictureCapturedHandlerName,
  pictureCapturedHandlersByName
} from './PictureCapturedHandler/pictureCapturedHandlersByName';

/**
 * If you need to use the EmbeddedCamera without passing `getEntityInfos` you should use this service.
 * This service persists the data to handle the result so that it also works if the app has been suspended by the camera
 */
@autoinject()
export class EmbeddedCameraPictureCaptureService {
  private static STORAGE_KEY =
    'EmbeddedCameraPictureCaptureService::currentOptions';

  private readonly subscriptionManager: SubscriptionManager;

  private currentOptions: CapturePictureOptions<any> | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskProjectService: ProcessTaskProjectService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:data-url-picture-available',
      () => {
        void this.handleEmbeddedCameraDataUrlAvailable();
      }
    );

    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:camera-closed',
      () => {
        void this.handleEmbeddedCameraClosed();
      }
    );

    const loadedOptions = (await DataStorageHelper.getItem(
      EmbeddedCameraPictureCaptureService.STORAGE_KEY
    )) as CapturePictureOptions<any> | undefined | null;
    this.currentOptions = loadedOptions ?? null;
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.currentOptions = null;
  }

  public async capturePicture<TName extends PictureCapturedHandlerName>(
    options: CapturePictureOptions<TName>
  ): Promise<void> {
    this.currentOptions = options;

    const storageData: CapturePictureOptions<TName> = {
      name: options.name,
      data: options.data,
      onCapturedPictureHandled: null // functions can't be stored
    };

    await DataStorageHelper.setItem(
      EmbeddedCameraPictureCaptureService.STORAGE_KEY,
      storageData
    );

    await EmbeddedCamera.capturePicture({});
  }

  private async handleEmbeddedCameraDataUrlAvailable(): Promise<void> {
    if (!this.currentOptions) {
      return;
    }

    const dataUrl = EmbeddedCamera.getLastDataUriPictureOnce();

    if (dataUrl) {
      await this.invokeHandler({
        name: this.currentOptions.name,
        data: this.currentOptions.data,
        dataUrl
      });

      this.currentOptions.onCapturedPictureHandled?.();
    }

    await this.resetCurrentOptions();
  }

  private async handleEmbeddedCameraClosed(): Promise<void> {
    await this.resetCurrentOptions();
  }

  private async invokeHandler<TName extends PictureCapturedHandlerName>({
    name,
    data,
    dataUrl
  }: {
    name: TName;
    data: PictureCapturedHandlerDataForName<TName>;
    dataUrl: string;
  }): Promise<void> {
    const handler = new pictureCapturedHandlersByName[name]({
      entityManager: this.entityManager,
      processTaskProjectService: this.processTaskProjectService,
      data
    });

    await handler.execute({
      dataUrl
    });
  }

  private async resetCurrentOptions(): Promise<void> {
    await DataStorageHelper.removeItem(
      EmbeddedCameraPictureCaptureService.STORAGE_KEY
    );
    this.currentOptions = null;
  }
}

export type CapturePictureOptions<TName extends PictureCapturedHandlerName> = {
  name: TName;
  data: PictureCapturedHandlerDataForName<TName>;
  /**
   * be careful: this function is only called if the app has not been suspended while taking a picture
   */
  onCapturedPictureHandled: (() => void) | null;
};
