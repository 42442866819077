import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../classes/EntityManager/entities/Person/PersonUtils';
import { ThingToPerson } from '../classes/EntityManager/entities/ThingToPerson/types';

/**
 * converts a thing id into a "thing.name - maincontactName" string
 */
@autoinject()
export class ThingWithPersonDisplayNameValueConverter {
  protected signals = [
    'thingModelUpdated',
    'personModelUpdated',
    'thingToPersonModelUpdated'
  ];

  constructor(private _entityManager: AppEntityManager) {}

  protected toView(thingId: string): string {
    const parts = [];

    const thing = this._entityManager.thingRepository.getById(thingId);

    if (thing) {
      parts.push(thing.name);

      const thingToPerson =
        this._entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          thing.id
        );
      const personName = thingToPerson
        ? this.getPersonNameForThingToPerson(thingToPerson)
        : null;
      if (personName) {
        parts.push(personName);
      }
    }

    return parts.join(' - ');
  }

  private getPersonNameForThingToPerson(
    thingToPerson: ThingToPerson
  ): string | null {
    const person = this._entityManager.personRepository.getById(
      thingToPerson.personId
    );
    return person ? PersonUtils.getPersonDisplayNameForPerson(person) : null;
  }
}
