import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';

import { SocketCommandEmitter } from './SocketCommandEmitter';

export class SettingsModuleEndpoints extends SocketCommandEmitter {
  public async getSettings(): Promise<
    EndpointResult<'settingsModule', 'getSettings'>
  > {
    return this.emitCommand('settingsModule', 'getSettings', {});
  }

  public async getSetting(
    request: EndpointParameter<'settingsModule', 'getSetting'>
  ): Promise<EndpointResult<'settingsModule', 'getSetting'>> {
    return this.emitCommand('settingsModule', 'getSetting', request);
  }

  public async setSetting(
    request: EndpointParameter<'settingsModule', 'setSetting'>
  ): Promise<EndpointResult<'settingsModule', 'setSetting'>> {
    return this.emitCommand('settingsModule', 'setSetting', request);
  }
}
