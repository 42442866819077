import { inject, bindable } from 'aurelia-framework';

import { SocketService } from '../../services/SocketService';

import { Dialogs } from '../../classes/Dialogs';
import { DateUtils } from '../../../../common/src/DateUtils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ReportTypeTemplateService } from '../../classes/EntityManager/entities/ReportType/ReportTypeTemplateService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@inject(
  SocketService,
  AppEntityManager,
  ReportTypeTemplateService,
  SubscriptionManagerService
)
export class ReportTypeListItem {
  @bindable reportType;
  @bindable editable = false;

  /** @type {HTMLElement} */
  _domElement;
  /**@type {HTMLElement}*/
  _listItemElement;

  /**
   * @param {SocketService} socketService
   * @param {AppEntityManager} entityManager
   * @param {ReportTypeTemplateService} reportTypeTemplateService
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(
    socketService,
    entityManager,
    reportTypeTemplateService,
    subscriptionManagerService
  ) {
    this._socketService = socketService;
    this._entityManager = entityManager;
    this._reportTypeTemplateService = reportTypeTemplateService;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.addDisposable(
      this._socketService.registerBinding('isConnected', (isConnected) => {
        this._isOnline = isConnected;
      })
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  highlight() {
    EntityListItemHelper.highlightListItemElement(this._listItemElement);
  }

  _handleDeleteReportType() {
    Dialogs.deleteEntityDialog(this.reportType).then(() => {
      this._entityManager.reportTypeRepository.delete(this.reportType);
    });
  }

  _handleEditReportTypeClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-report-type-clicked',
      detail: null
    });
  }

  /**
   * @param {Event} event
   */
  _handleUploadTemplateFileInputChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this._reportTypeTemplateService.uploadTemplate(
        this.reportType,
        event.target.files[0]
      );
      event.target.value = null;
    }
  }

  _handleDownloadTemplate() {
    this._reportTypeTemplateService.downloadTemplate(this.reportType);
  }

  _formatToDate(time) {
    return DateUtils.formatToDateString(time);
  }

  _formatToTime(time) {
    return DateUtils.formatToTimeString(time);
  }
}
