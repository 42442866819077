import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  QuestionCatalogueToQuestionSet,
  QuestionCatalogueToQuestionSetCreationEntity
} from './types';

export class QuestionCatalogueToQuestionSetRepository extends AppEntityRepository<EntityName.QuestionCatalogueToQuestionSet> {
  public getOrCreate(
    entity: QuestionCatalogueToQuestionSetCreationEntity
  ): QuestionCatalogueToQuestionSet {
    let relation = this.getRelation(
      entity.questionCatalogueId,
      entity.questionSetId
    );
    if (!relation) {
      relation = super.create(entity);
    }
    return relation;
  }

  public getRelation(
    questionCatalogueId: string,
    questionSetId: string
  ): QuestionCatalogueToQuestionSet | null {
    const entities = this.getAll().filter(
      (entity) =>
        entity.questionCatalogueId === questionCatalogueId &&
        entity.questionSetId === questionSetId
    );
    return entities[0] ?? null;
  }

  /**
   * Returns all questionCatalogueToQuestionSet entities for the given questionCatalogueIds.
   */
  public getByQuestionCatalogueIds(
    questionCatalogueIds: Array<string>
  ): Array<QuestionCatalogueToQuestionSet> {
    return this.getAll().filter((entity) =>
      questionCatalogueIds.includes(entity.questionCatalogueId)
    );
  }

  /**
   * Returns all questionCatalogueToQuestionSet entities for a given questionCatalogueId.
   *
   * For easier retrieval of question sets, use {@link QuestionSetService#getByQuestionCatalogueId} instead.
   */
  public getByQuestionCatalogueId(
    questionCatalogueId: string
  ): Array<QuestionCatalogueToQuestionSet> {
    return this.getByQuestionCatalogueIds([questionCatalogueId]);
  }

  /**
   * Returns all questionCatalogueToQuestionSet entities for a given questionSetId.
   *
   * For easier retrieval of question catalogues, use {@link QuestionCatalogueService#getByQuestionSetId} instead.
   */
  public getByQuestionSetId(
    questionSetId: string
  ): Array<QuestionCatalogueToQuestionSet> {
    return this.getAll().filter(
      (entity) => entity.questionSetId === questionSetId
    );
  }
}
