import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * A pagination which can be used to paginate through an array.
 * It can also be configured for a server based pagination.
 *
 * Example for an array pagination
 *
 *  <pagination items.to-view="availableItems"
 *              current-page-items.from-view="currentPageItems">
 *  </pagination>
 *
 * Example for a server side pagination
 *
 *   <pagination max-page-index.to-view="maxPageIndex"
 *               current-page-index.from-view="currentPageIndex"
 *               current-page-index-changed.trigger="reloadItems()">
 *   </pagination>
 * @event current-page-index-changed
 */
@autoinject()
export class Pagination<T> {
  /**
   * if items are null, then you have to manually set the maxPageIndex externally (also the currentPageItems won't work)
   */
  @bindable()
  public items: Array<T> | null = null;

  /**
   * read-only!!
   * items for the currently selected page, only works if items != null
   */
  @bindable()
  public currentPageItems: Array<T> = [];

  /**
   * items per page
   */
  @bindable()
  public currentPageSize: number = 10;

  @bindable()
  public showPageSizeSelection: boolean = true;

  /**
   * only set this manually if currentPageItems == null
   *
   * This can also be set to null, if you can't decide on a maxPageIndex, because the underlying data is not there yet.
   * Also the current page index from the route param will used without any limits, if the maxPageIndex is set to null.
   */
  @bindable()
  public maxPageIndex: number | null = 0;

  @bindable()
  public paginationQueryParameter: string = 'page';

  /**
   * it is advisable to set this to true if your pagination is in a dialog
   */
  @bindable public ignoreRouteParameter = false;

  private readonly subscriptionManager: SubscriptionManager;

  /**
   * the currently selected page
   * Initializing this to 0 ensures that a changed event is fired after
   * the page parameters have been parsed and the correct page is set.
   */
  private currentPageIndex: number = 0;

  /**
   * the currently selected page
   */
  private routeTargetPageIndex = 1;

  private isAttached: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly router: Router,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.router = router;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public goToFirstPage(): void {
    this.navigateToPage(1);
  }

  public goToLastPage(): void {
    assertNotNullOrUndefined(
      this.maxPageIndex,
      "can't go to last page without a maxPageIndex"
    );
    this.navigateToPage(this.maxPageIndex);
  }

  public goToNextPage(): void {
    this.navigateToPage(this.currentPageIndex + 1);
  }

  public goToPreviousPage(): void {
    this.navigateToPage(this.currentPageIndex - 1);
  }

  public goToItem(item: T): void {
    const index = this.items ? this.items.indexOf(item) : -1;

    if (index >= 0) {
      this.navigateToPage(Math.floor(index / this.currentPageSize) + 1);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'items',
      this.itemsChanged.bind(this)
    );

    this.routeTargetPageIndex = !this.ignoreRouteParameter
      ? parseInt(
          this.router.currentInstruction.queryParams[
            this.paginationQueryParameter
          ]
        ) || 1
      : 1;
    this.setCurrentPageIndex(this.routeTargetPageIndex);
    this.update();
    this.updateMaxPageIndex();
    this.subscriptionManager.subscribeToPropertyChange(
      this.router,
      'currentInstruction',
      (/** @type {NavigationInstruction} */ currentInstruction) => {
        if (this.ignoreRouteParameter) {
          return;
        }
        this.routeTargetPageIndex =
          parseInt(
            currentInstruction.queryParams[this.paginationQueryParameter]
          ) || 1;
        this.setCurrentPageIndex(this.routeTargetPageIndex);
        this.update();
      }
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected itemsChanged(): void {
    if (!this.isAttached) {
      return;
    }

    this.updateMaxPageIndex();
    this.setCurrentPageIndex(this.routeTargetPageIndex);
    this.updateVisibleItems();
  }

  protected currentPageSizeChanged(): void {
    if (!this.isAttached) {
      return;
    }

    this.updateMaxPageIndex();
    this.updateVisibleItems();
  }

  protected currentPageIndexChanged(): void {
    if (!this.isAttached) {
      return;
    }

    // set it so we trigger the limiting again in case it was changed externally
    this.setCurrentPageIndex(this.currentPageIndex);
    this.update();
  }

  protected maxPageIndexChanged(): void {
    if (!this.isAttached) {
      return;
    }

    this.setCurrentPageIndex(this.routeTargetPageIndex);
    this.update();
  }

  private navigateToPage(index: number): void {
    if (this.ignoreRouteParameter) {
      this.setCurrentPageIndex(index);
      return;
    }

    if (index === this.currentPageIndex) {
      return;
    }

    if (!this.router.currentInstruction.config.name) {
      throw new Error(
        'Current route has no name: ' +
          this.router.currentInstruction.getBaseUrl()
      );
    }
    const newParams = {
      ...this.router.currentInstruction.params,
      ...this.router.currentInstruction.queryParams
    };
    if (index > 1 || newParams[this.paginationQueryParameter])
      newParams[this.paginationQueryParameter] = index;
    this.router.navigateToRoute(
      this.router.currentInstruction.config.name,
      newParams,
      { trigger: true, replace: false }
    );
  }

  private setCurrentPageIndex(index: number): void {
    const limitedIndex = this.limitPageIndex(index);
    const newCurrentPageIndex = limitedIndex ? limitedIndex : 1;
    if (newCurrentPageIndex !== this.currentPageIndex) {
      this.currentPageIndex = newCurrentPageIndex;

      this.subscriptionManager.subscribeToTimeout(() => {
        DomEventHelper.fireEvent(this.element, {
          name: 'current-page-index-changed',
          detail: {
            page: this.currentPageIndex
          }
        });
      }, 0);
    }
  }

  private limitPageIndex(index: number): number {
    if (this.maxPageIndex == null) {
      return index;
    }

    return Math.max(Math.min(index, this.maxPageIndex), 1);
  }

  private updateMaxPageIndex(): void {
    if (this.items && this.currentPageSize) {
      this.maxPageIndex = Math.max(
        Math.ceil(this.items.length / this.currentPageSize),
        1
      );
    }
  }

  private update(): void {
    this.updateVisibleItems();
  }

  private updateVisibleItems(): void {
    if (this.items && this.currentPageSize) {
      this.currentPageItems = this.items.slice(
        this.currentPageSize * (this.currentPageIndex - 1),
        Math.min(
          this.currentPageSize * this.currentPageIndex,
          this.items.length
        )
      );
    }
  }
}

export type CurrentPageIndexChangedEvent = NamedCustomEvent<
  'current-page-index-changed',
  { page: number }
>;
