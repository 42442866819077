import {
  RoleBasedPermissions,
  RoleBasedUserGroupSpecificPermissions
} from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { ProjectSubEntityDto } from 'common/Types/BaseEntities/ProjectSubEntityUtils';
import { ProcessTaskGroupSubEntity } from 'common/Types/Entities/Base/ProcessTaskGroupSubEntity';
import { ProcessTaskSubEntity } from 'common/Types/Entities/Base/ProcessTaskSubEntity';
import { Person } from '../../../../classes/EntityManager/entities/Person/types';

export class EntityAdapterUtils {
  private constructor() {}

  public static checkProcessTaskSubEntityPermission({
    entity,
    roleBasedPermissions,
    processTaskIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProcessTaskSubEntity<string>;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return this.checkPermissionBasedOnProcessTaskId({
      processTaskId: entity.ownerProcessTaskId,
      ownerUserGroupId: entity.ownerUserGroupId,
      processTaskIdsWhereUserIsAuthorized,
      roleBasedPermissions,
      checkRolePermission
    });
  }

  public static checkPermissionBasedOnProcessTaskId({
    processTaskId,
    ownerUserGroupId,
    roleBasedPermissions,
    processTaskIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    processTaskId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !processTaskIdsWhereUserIsAuthorized.has(processTaskId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkProcessTaskGroupSubEntityPermission({
    entity,
    roleBasedPermissions,
    processTaskGroupIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProcessTaskGroupSubEntity<string> & { ownerProcessTaskId?: never };
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskGroupIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    return this.checkPermissionBasedOnProcessTaskGroupId({
      processTaskGroupId: entity.ownerProcessTaskGroupId,
      ownerUserGroupId: entity.ownerUserGroupId,
      processTaskGroupIdsWhereUserIsAuthorized,
      roleBasedPermissions,
      checkRolePermission
    });
  }

  public static checkPermissionBasedOnProcessTaskGroupId({
    processTaskGroupId,
    ownerUserGroupId,
    roleBasedPermissions,
    processTaskGroupIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    processTaskGroupId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    processTaskGroupIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !processTaskGroupIdsWhereUserIsAuthorized.has(processTaskGroupId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnThingId({
    thingId,
    ownerUserGroupId,
    roleBasedPermissions,
    thingIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    thingId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    thingIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!roleBasedPermissions || !thingIdsWhereUserIsAuthorized.has(thingId)) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnThingGroupId({
    thingGroupId,
    ownerUserGroupId,
    roleBasedPermissions,
    thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing,
    checkRolePermission
  }: {
    thingGroupId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !thingGroupIdsWhereUserIsAuthorizedForAtLeastOneThing.has(thingGroupId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkProjectSubEntityPermission({
    entity,
    roleBasedPermissions,
    projectIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    entity: ProjectSubEntityDto;
    roleBasedPermissions: RoleBasedPermissions | null;
    projectIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !projectIdsWhereUserIsAuthorized.has(entity.ownerProjectId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(entity.ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnProjectId({
    projectId,
    ownerUserGroupId,
    roleBasedPermissions,
    projectIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    projectId: string;
    ownerUserGroupId: string;
    roleBasedPermissions: RoleBasedPermissions | null;
    projectIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (
      !roleBasedPermissions ||
      !projectIdsWhereUserIsAuthorized.has(projectId)
    ) {
      return false;
    }

    return checkRolePermission(
      roleBasedPermissions.inUserGroupId(ownerUserGroupId)
    );
  }

  public static checkPermissionBasedOnPerson({
    person,
    roleBasedPermissions,
    groupAdminOnlyPersonCategoryNames,
    groupAdminUserGroupIds,
    personIdToThingIds,
    thingIdsWhereUserIsAuthorized,
    checkRolePermission
  }: {
    person: Person;
    roleBasedPermissions: RoleBasedPermissions | null;
    groupAdminOnlyPersonCategoryNames: Set<string>;
    groupAdminUserGroupIds: Set<string>;
    personIdToThingIds: Map<string, Array<string>>;
    thingIdsWhereUserIsAuthorized: Set<string>;
    checkRolePermission: (
      permissions: RoleBasedUserGroupSpecificPermissions
    ) => boolean;
  }): boolean {
    if (!roleBasedPermissions) {
      return false;
    }

    if (
      person.categoryName &&
      groupAdminOnlyPersonCategoryNames.has(person.categoryName) &&
      !groupAdminUserGroupIds.has(person.ownerUserGroupId)
    ) {
      return false;
    }

    const groupPermissions = roleBasedPermissions.inUserGroupId(
      person.ownerUserGroupId
    );

    if (
      groupPermissions.getControlEntityVisibilityWithAuthorizations() &&
      !groupPermissions.getCanSeePersonsWithoutExplicitAuthorization()
    ) {
      const thingIds = personIdToThingIds.get(person.id) ?? [];
      const isConnectedToThingWhereUserIsAuthorized = thingIds.some(
        (thingId) => {
          return thingIdsWhereUserIsAuthorized.has(thingId);
        }
      );

      if (!isConnectedToThingWhereUserIsAuthorized) {
        return false;
      }
    }

    return checkRolePermission(groupPermissions);
  }
}
