import { autoinject, computedFrom } from 'aurelia-framework';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { DateUtils } from '../../../../common/src/DateUtils';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import {
  StructureTemplateType,
  StructureTemplateStatus,
  FlawNumberMajor,
  FlawNumberMinor
} from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { StructureTemplateRatingCategory } from '../../classes/EntityManager/entities/StructureTemplateRatingCategory/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { StructureTemplateEntryProperty } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';

@autoinject()
export class EditStructureTemplateDialog {
  protected structureTemplate: StructureTemplate | null = null;
  protected structureTemplateRatingCategories: Array<StructureTemplateRatingCategory> =
    [];
  protected structureTemplateEntryProperties: Array<StructureTemplateEntryProperty> =
    [];
  protected userCanEditStructureTemplate = false;
  protected dialog: RecordItDialog | null = null;
  protected structureTemplateTypes = Object.values(StructureTemplateType);
  protected StructureTemplateType = StructureTemplateType;
  protected DateUtils = DateUtils;

  protected flawNumberMinorOptions: Array<FlawNumberOption> = [
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMinorSequentialPerMajor',
      value: FlawNumberMinor.SEQUENTIAL_PER_MAJOR
    },
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMinorSequentialGlobal',
      value: FlawNumberMinor.SEQUENTIAL_GLOBAL
    }
  ];

  protected flawNumberMajorOptions: Array<FlawNumberOption> = [
    {
      labelTk:
        'dialogs.editStructureTemplateDialog.flawNumbersMajorFirstLevelListPosition',
      value: FlawNumberMajor.FIRST_LEVEL_LIST_POSITION
    },
    {
      labelTk: 'dialogs.editStructureTemplateDialog.flawNumbersMajorSequential',
      value: FlawNumberMajor.SEQUENTIAL
    }
  ];

  private permissionBindingHandle: PermissionBindingHandle;
  private onDialogClosed: TOnDialogClosedCallback | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        editableProperty: 'userCanEditStructureTemplate',
        property: 'structureTemplate',
        userGroupPropertyOfEntity: 'ownerUserGroupId'
      }
    });
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: IEditStructureTemplateDialogOptions): void {
    this.structureTemplate = options.structureTemplate;
    this.updateStructureTemplateRatingCategories();
    this.updateStructureTemplateEntryProperties();
    this.onDialogClosed = options.onDialogClosed || null;

    this.permissionBindingHandle.subscribe();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateRatingCategory,
      this.updateStructureTemplateRatingCategories.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      this.updateStructureTemplateEntryProperties.bind(this)
    );

    this.dialog?.open();
  }

  public static async open(
    options: IEditStructureTemplateDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private updateStructureTemplateRatingCategories(): void {
    if (!this.structureTemplate) {
      this.structureTemplateRatingCategories = [];
      return;
    }

    this.structureTemplateRatingCategories =
      this.entityManager.structureTemplateRatingCategoryRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
  }

  private updateStructureTemplateEntryProperties(): void {
    if (!this.structureTemplate) {
      this.structureTemplateEntryProperties = [];
      return;
    }

    this.structureTemplateEntryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );
  }

  @computedFrom('userCanEditStructureTemplate', 'structureTemplate.status')
  protected get structureTemplateIsEditable(): boolean {
    return (
      this.userCanEditStructureTemplate &&
      this.structureTemplate?.status === StructureTemplateStatus.DRAFT
    );
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const structureTemplate = this.structureTemplate;

    this.structureTemplate = null;
    this.structureTemplateRatingCategories = [];
    this.structureTemplateEntryProperties = [];
    this.onDialogClosed = null;

    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();

    structureTemplate && onDialogClosed && onDialogClosed(structureTemplate);
  }

  protected handleStructureTemplateChanged(): void {
    if (!this.structureTemplate) return;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected handleAddRatingCategoryButtonClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'cannot handleAddRatingCategoryButtonClicked without a structureTemplate'
    );

    this.entityManager.structureTemplateRatingCategoryRepository.create({
      ownerStructureTemplateId: this.structureTemplate.id,
      ownerUserGroupId: this.structureTemplate.ownerUserGroupId
    });
  }

  protected getUserGroupNameById(userGroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(userGroupId);
    return (group && group.name) || '';
  }
}

interface IEditStructureTemplateDialogOptions {
  structureTemplate: StructureTemplate;
  onDialogClosed?: TOnDialogClosedCallback | null;
}

type TOnDialogClosedCallback = (structureTemplate: StructureTemplate) => void;

type FlawNumberOption = {
  labelTk: string;
  value: FlawNumberMajor | FlawNumberMinor;
};
