import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';

import {
  CurrentMarkingAddedEvent,
  CurrentMarkingRemovedEvent,
  MarkingSetEvent,
  PictureMarkingOverlay
} from '../picture-marking-overlay/picture-marking-overlay';
import {
  Picture,
  PictureAdditionalMarking,
  PictureMarking
} from '../../classes/EntityManager/entities/Picture/types';
import { AdditionalMarkingsHandle } from '../additional-marked-pictures-list/AdditionalMarkingsHandle';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Disposable } from '../../classes/Utils/DisposableContainer';

export { MarkingSetEvent };

@autoinject()
export class PictureMarkingOverlayWithRelatedPictures {
  @bindable public picture: Picture | null = null;

  @bindable public markings: Array<IPictureMarking> = [];

  @bindable public currentMarkings: Array<IPictureMarking> = [];

  @bindable public relatedPictures: Array<Picture> = [];

  @bindable public additionalMarkingsHandle: AdditionalMarkingsHandle | null =
    null;

  @bindable public editMarkings = false;

  private domElement: HTMLElement;

  protected markingOverlay: PictureMarkingOverlay | null = null;

  private additionalMarkingsHandleSubscription: Disposable | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public open(isMarking: boolean): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot open marking overlay without a picture'
    );
    assertNotNullOrUndefined(
      this.markingOverlay,
      'cannot open overlay without one'
    );

    this.markingOverlay.parentPicture = this.picture;

    this.markingOverlay.navigationButtonsConfig.showLeftNavigationButton =
      this.getPreviousRelatedPictureIndex(this.picture) >= 0;
    this.markingOverlay.navigationButtonsConfig.showRightNavigationButton =
      this.getNextRelatedPictureIndex(this.picture) >= 0;

    this.markingOverlay.open(isMarking);
  }

  protected detached(): void {
    this.additionalMarkingsHandleSubscription?.dispose();
  }

  private getNextRelatedPictureInfo(
    picture: Picture
  ): RelatedPictureInfo | null {
    const nextIndex = this.getNextRelatedPictureIndex(picture);
    const nextRelatedPicture = this.relatedPictures[nextIndex];

    return nextRelatedPicture
      ? {
          picture: nextRelatedPicture,
          hasPreviousRelatedPicture: nextIndex > 0,
          hasNextRelatedPicture: nextIndex < this.relatedPictures.length - 1
        }
      : null;
  }

  private getNextRelatedPictureIndex(picture: Picture): number {
    const selectedPictureIndex = this.relatedPictures.findIndex(
      (p) => p.id === picture.id
    );
    const nextIndex = selectedPictureIndex + 1;
    if (nextIndex > this.relatedPictures.length - 1) {
      return -1;
    }

    return nextIndex;
  }

  private getPreviousRelatedPictureInfo(
    picture: Picture
  ): RelatedPictureInfo | null {
    const previousIndex = this.getPreviousRelatedPictureIndex(picture);
    const previousRelatedPicture = this.relatedPictures[previousIndex];

    return previousRelatedPicture
      ? {
          picture: previousRelatedPicture,
          hasPreviousRelatedPicture: previousIndex > 0,
          hasNextRelatedPicture: previousIndex < this.relatedPictures.length - 1
        }
      : null;
  }

  private getPreviousRelatedPictureIndex(picture: Picture): number {
    const selectedPictureIndex = this.relatedPictures.findIndex(
      (p) => p.id === picture.id
    );
    const previousIndex = selectedPictureIndex - 1;
    if (previousIndex < 0) {
      return -1;
    }

    return previousIndex;
  }

  protected handleLeftNavigationButtonClick(): void {
    const currentPicture = this.markingOverlay?.parentPicture;
    assertNotNullOrUndefined(
      currentPicture,
      'cannot navigate to previous related picture without a current picture'
    );

    const previousRelatedPictureInfo =
      this.getPreviousRelatedPictureInfo(currentPicture);
    assertNotNullOrUndefined(
      previousRelatedPictureInfo,
      'cannot navigate to previous related picture'
    );

    assertNotNullOrUndefined(
      this.additionalMarkingsHandle,
      'cannot navigate to previous related picture without additional marking handle'
    );

    this.setMarkingOverlayParameters({
      picture: previousRelatedPictureInfo.picture,
      markings: [],
      otherMarkings: this.additionalMarkingsHandle.getOtherMarkingsOfPicture({
        picture: previousRelatedPictureInfo.picture
      }),
      hasPreviousRelatedPicture:
        previousRelatedPictureInfo.hasPreviousRelatedPicture,
      hasNextRelatedPicture: previousRelatedPictureInfo.hasNextRelatedPicture
    });
  }

  protected handleRightNavigationButtonClick(): void {
    const currentPicture = this.markingOverlay?.parentPicture;
    assertNotNullOrUndefined(
      currentPicture,
      'cannot navigate to next related picture without a current picture'
    );

    const nextRelatedPictureInfo =
      this.getNextRelatedPictureInfo(currentPicture);
    assertNotNullOrUndefined(
      nextRelatedPictureInfo,
      'cannot navigate to next related picture'
    );

    assertNotNullOrUndefined(
      this.additionalMarkingsHandle,
      'cannot navigate to next related picture without additional marking handle'
    );

    this.setMarkingOverlayParameters({
      picture: nextRelatedPictureInfo.picture,
      markings: [],
      otherMarkings: this.additionalMarkingsHandle.getOtherMarkingsOfPicture({
        picture: nextRelatedPictureInfo.picture
      }),
      hasPreviousRelatedPicture:
        nextRelatedPictureInfo.hasPreviousRelatedPicture,
      hasNextRelatedPicture: nextRelatedPictureInfo.hasNextRelatedPicture
    });
  }

  private setMarkingOverlayParameters({
    picture,
    markings,
    otherMarkings,
    hasPreviousRelatedPicture,
    hasNextRelatedPicture
  }: {
    picture: Picture;
    markings: Array<PictureMarking>;
    otherMarkings: Array<PictureAdditionalMarking>;
    hasPreviousRelatedPicture: boolean;
    hasNextRelatedPicture: boolean;
  }): void {
    assertNotNullOrUndefined(
      this.markingOverlay,
      'cannot set marking overlay parameters if it does not exist'
    );

    this.markingOverlay.markings = otherMarkings;
    this.markingOverlay.currentMarkings = markings;
    this.markingOverlay.parentPicture = picture;
    this.markingOverlay.showPictureMarking = false;

    this.markingOverlay.navigationButtonsConfig.showLeftNavigationButton =
      hasPreviousRelatedPicture;
    this.markingOverlay.navigationButtonsConfig.showRightNavigationButton =
      hasNextRelatedPicture;
  }

  protected handleMarkingSet(event: MarkingSetEvent): void {
    DomEventHelper.fireEvent<MarkingSetEvent>(this.domElement, {
      name: 'marking-set',
      detail: event.detail
    });
  }

  protected handleMarkingAdded(event: CurrentMarkingAddedEvent): void {
    assertNotNullOrUndefined(
      this.additionalMarkingsHandle,
      'cannot add marking without an additional marking handle'
    );
    this.additionalMarkingsHandle.addMarking({
      marking: event.detail.currentMarking
    });
  }

  protected handleMarkingRemoved(event: CurrentMarkingRemovedEvent): void {
    assertNotNullOrUndefined(
      this.additionalMarkingsHandle,
      'cannot remove marking without an additional marking handle'
    );
    this.additionalMarkingsHandle.removeMarking({
      marking: event.detail.currentMarking
    });
  }

  protected additionalMarkingsHandleChanged(): void {
    this.additionalMarkingsHandleSubscription?.dispose();

    if (!this.additionalMarkingsHandle) return;

    this.additionalMarkingsHandleSubscription =
      this.additionalMarkingsHandle.subscribe({
        markedPictureInfosBindingCallback: (markedPictureInfos) => {
          const info = markedPictureInfos.find(
            (i) => i.picture.id === this.picture?.id
          );
          if (!info) return;

          this.markings = info.otherMarkings;
          this.currentMarkings = info.markings;
        }
      });
  }
}

type RelatedPictureInfo = {
  picture: Picture;
  hasPreviousRelatedPicture: boolean;
  hasNextRelatedPicture: boolean;
};
