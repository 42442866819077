import { EventAggregator } from 'aurelia-event-aggregator';

export class EventAggregatorPromiseHelper {
  public static createPromise<T>(
    eventAggregator: EventAggregator,
    promise: Promise<T>,
    options: Options
  ): Promise<T> {
    return new Promise((promiseResolveFunction, promiseRejectFunction) => {
      const info: PromiseInfo = { subscriptions: [] };
      const functions = this.createResolveRejectFunctions(
        info,
        eventAggregator,
        promiseResolveFunction,
        promiseRejectFunction,
        options
      );
      promise.then(functions.resolve).catch(functions.reject);
    });
  }

  /**
   * returns a promise which gets automatically rejected when the internet connection gets interrupted
   * promise still gets automatically executed if there is no connection while it get initiated
   */
  public static createConnectedPromise<T>(
    eventAggregator: EventAggregator,
    promise: Promise<T>
  ): Promise<T> {
    return this.createPromise(eventAggregator, promise, {
      rejectEventName: 'socket:disconnected'
    });
  }

  private static createResolveRejectFunctions<T>(
    info: PromiseInfo,
    eventAggregator: EventAggregator,
    promiseResolveFunction: ResolveCallback<T>,
    promiseRejectFunction: RejectCallback,
    options: Options
  ): { resolve: ResolveCallback<T>; reject: RejectCallback } {
    return {
      resolve: this.wrapPromiseFunction(info, promiseResolveFunction),
      reject: this.wrapRejectFunction(
        info,
        eventAggregator,
        promiseRejectFunction,
        options.rejectEventName
      )
    };
  }

  private static wrapPromiseFunction<T>(
    info: PromiseInfo,
    promiseFunction: ResolveCallback<T>
  ): ResolveCallback<T> {
    return (resolveValue: T): void => {
      info.subscriptions.forEach((sub) => sub.dispose());
      promiseFunction.apply(promiseFunction, [resolveValue]);
    };
  }

  private static wrapRejectFunction(
    info: PromiseInfo,
    eventAggregator: EventAggregator,
    rejectFunction: RejectCallback,
    eventName: string
  ): RejectCallback {
    const wrappedFunction = this.wrapPromiseFunction(info, rejectFunction);

    info.subscriptions.push(
      eventAggregator.subscribe(eventName, () => {
        wrappedFunction(new PromiseReactedToEventError(eventName));
      })
    );

    return wrappedFunction;
  }
}

export class PromiseReactedToEventError extends Error {
  constructor(eventName: string) {
    super(`Promise reacted to the "${eventName}" event`);
  }
}

type Options = {
  /** if this event occurs, the promise gets automatically rejected */
  rejectEventName: string;
};

type PromiseInfo = {
  subscriptions: Array<{ dispose: Function }>;
};

type ResolveCallback<T> = (param: T) => void;
type RejectCallback = (error: Error) => void;
