import { autoinject } from 'aurelia-dependency-injection';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Utils } from '../../../classes/Utils/Utils';
import { ValueComputer } from '../ValueComputer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Person } from '../../../classes/EntityManager/entities/Person/types';
import { EntryToPerson } from '../../../classes/EntityManager/entities/EntryToPerson/types';

@autoinject()
export class EntryToPersonCacheComputer extends ValueComputer<
  EntryToPersonCacheComputerComputeData,
  EntryToPersonCache
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.EntryToPerson,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): EntryToPersonCache {
    return new EntryToPersonCache({
      entityManager: this.entityManager
    });
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export class EntryToPersonCache {
  private readonly entityManager;

  private readonly entryToPersonCache: EntryToPersonMap;

  constructor(options: { entityManager: AppEntityManager }) {
    this.entityManager = options.entityManager;
    this.entryToPersonCache = this.createCache();
  }

  public getPersonsByEntryId(entryId: string): Array<Person> {
    return this.entryToPersonCache.get(entryId) || [];
  }

  private createCache(): EntryToPersonMap {
    const cache: EntryToPersonMap = new Map();

    const allEntryToPersonMappings =
      this.entityManager.entryToPersonRepository.getAll();
    const mappingsByEntryId = Utils.groupBy(
      allEntryToPersonMappings,
      (e2p) => e2p.entryId
    );

    for (const [entryId, mappings] of mappingsByEntryId.entries()) {
      if (!entryId) {
        continue;
      }

      cache.set(entryId, this.resolveMapping(mappings));
    }

    return cache;
  }

  private resolveMapping(e2pMappings: Array<EntryToPerson>): Array<Person> {
    const result = [];
    for (const e2p of e2pMappings) {
      const person = this.entityManager.personRepository.getById(e2p.personId);
      if (person) {
        result.push(person);
      }
    }

    return result;
  }
}

export type EntryToPersonCacheComputerComputeData = Record<string, never>;

type EntryToPersonMap = Map<string, Array<Person>>;
