import { Router } from 'aurelia-router';
import {
  ProcessConfigurationPositionType,
  TProcessConfigurationPositionType,
  TProcessConfigurationPositionTypeWithoutNull
} from 'common/Enums/ProcessConfigurationPositionType';
import { ProcessTaskPositionCalculator } from 'common/Operations/ProcessTaskPositionCalculator';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { ProcessConfigurationStepPosition } from './types';

export class ProcessConfigurationStepPositionUtils {
  private static typeToCurrency: Record<
    TProcessConfigurationPositionTypeWithoutNull | 'null',
    string
  > = {
    null: '€',
    [ProcessConfigurationPositionType.DEFAULT]: '€',
    [ProcessConfigurationPositionType.MARKUP]: '%',
    [ProcessConfigurationPositionType.INFO]: '€'
  };

  public static getFullName(
    namePrefix: ProcessConfigurationStepPosition['namePrefix'],
    name: ProcessConfigurationStepPosition['name']
  ): string {
    const parts: Array<string> = [];

    if (namePrefix) {
      parts.push(namePrefix);
    }

    if (name) {
      parts.push(name);
    }

    return parts.join(' ');
  }

  public static getPriceInfoString(
    type: TProcessConfigurationPositionType,
    priceGroup: string | null,
    price: number | null,
    unit: string | null,
    flatRate: boolean | null,
    discount: number | null,
    discountNote: string | null
  ): string {
    const currency = this.typeToCurrency[type != null ? type : 'null'];
    const calculator = new ProcessTaskPositionCalculator({ typesMap: {} });

    let str = '';
    if (priceGroup) {
      str += priceGroup + ': ';
    }

    if (price) {
      str +=
        StringUtils.priceToString(
          calculator.getDiscountedPrice({
            price,
            discount: discount || 0
          })
        ) + currency;
    }

    if (flatRate) {
      str += ' PA';
    }

    if (unit) {
      str += ' / ' + unit;
    }

    if (discount && price) {
      discountNote = discountNote ? ' ' + discountNote : '';
      str +=
        ' (' +
        StringUtils.priceToString(price) +
        currency +
        ' -' +
        discount +
        '%' +
        discountNote +
        ')';
    }

    return str;
  }

  public static navigateToEditProcessConfigurationStepPositionsPage(
    router: Router,
    processConfigurationStepId: string
  ): void {
    router.navigateToRoute('edit_process_configuration_step_positions', {
      process_configuration_step_id: processConfigurationStepId
    });
  }
}
