import { computedFrom } from 'aurelia-framework';
import { ThingGroup } from '../../../classes/EntityManager/entities/ThingGroup/types';
import { ThingGroupAdapter } from '../EntityAdapter/ThingGroupAdapter/ThingGroupAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ThingGroupPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ThingGroupAdapter>,
  ThingGroup
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }

  @computedFrom('combinedRevision')
  public get canCreateSharepointListColumnToThingMappingItems(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateSharepointListColumnToThingMappingItems'
    );
  }
}
