import { Vector } from 'common/Geometry/Vector';
import { RepositionableElementPositionAndSize } from './repositionable-element';

export class RepositionableElementUtils {
  public static transformPositionAndSize({
    transformation,
    positionAndSize
  }: TransformPositionAndSizeOptions): RepositionableElementPositionAndSize {
    return {
      top: transformation.top ?? positionAndSize.top,
      left: transformation.left ?? positionAndSize.left,
      width: transformation.width ?? positionAndSize.width,
      height: transformation.height ?? positionAndSize.height,
      rotation: transformation.rotation ?? positionAndSize.rotation
    };
  }

  public static isResizeOnlyTransformation(
    transformation: PositionAndSizeTransformation
  ): boolean {
    return (
      (transformation.width != null || transformation.height != null) &&
      transformation.rotation == null
    );
  }

  public static isMovementOnlyTransformation(
    transformation: PositionAndSizeTransformation
  ): boolean {
    return (
      (transformation.top != null || transformation.left != null) &&
      transformation.height == null &&
      transformation.width == null
    );
  }

  public static getPointerAnchorPoint({
    element,
    initialPointerPosition
  }: {
    element: HTMLElement;
    initialPointerPosition: Vector;
  }): Vector {
    const parent = element.offsetParent?.getBoundingClientRect() ?? {
      left: 0,
      top: 0
    };

    return Vector.createHtmlVector(
      initialPointerPosition.getX() - element.offsetLeft - parent.left,
      initialPointerPosition.getY() - element.offsetTop - parent.top
    );
  }
}

export type TransformPositionAndSizeOptions = {
  transformation: PositionAndSizeTransformation;
  positionAndSize: RepositionableElementPositionAndSize;
};

export type PositionAndSizeTransformation = {
  top?: number | null;
  left?: number | null;
  width?: number | null;
  height?: number | null;
  rotation?: number | null;
};
