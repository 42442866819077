import { EncodedData } from './EncodedData';
import { FFlateUtils } from './FFlateUtils';
import { ObjectDeconstructor } from './ObjectDeconstructor';

export class Encoder {
  public static readonly ENCODING_THRESHOLD = 10 * 1024;

  private readonly deconstructor = new ObjectDeconstructor();

  public async encodeIfBigEnough(
    data: Record<any, any>
  ): Promise<EncodedData | Record<any, any>> {
    const { deconstructedObject, attachments } =
      this.deconstructor.deconstruct(data);

    const stringified = JSON.stringify(deconstructedObject);

    if (stringified.length < Encoder.ENCODING_THRESHOLD) {
      return data;
    }

    return {
      $$isEncodedData$$: true,
      baseData: await FFlateUtils.deflateText(stringified),
      attachments
    };
  }
}
