import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DialogIconType } from 'common/Enums/DialogIconType';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskRecurringAppointment } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';

/**
 * @event edit-button-clicked
 */
@autoinject()
export class ProcessTaskRecurringAppointmentListItem {
  @bindable public appointment: ProcessTaskRecurringAppointment | null = null;

  @subscribableLifecycle()
  protected readonly appointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskRecurringAppointment];

  @subscribableLifecycle()
  protected readonly processTaskAppointmentPermissionChecker: EntitiesPermissionChecker<EntityName.ProcessTaskAppointment>;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.appointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskRecurringAppointment,
        context: this as ProcessTaskRecurringAppointmentListItem,
        propertyName: 'appointment'
      });

    this.processTaskAppointmentPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.ProcessTaskAppointment
      });
  }

  protected handleEditButtonClicked(): void {
    DomEventHelper.fireEvent<EditButtonClickedEvent>(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected handleDeleteButtonClicked(): void {
    const appointment = this.appointment;
    assertNotNullOrUndefined(
      appointment,
      'cannot ProcessTaskRecurringAppointmentListItem.handleDeleteButtonClicked without appointment'
    );

    const deletableCorrespondingAppointments =
      this.getDeletableCorrespondingAppointments({
        appointment
      });

    void GlobalCustomDialog.open({
      titleTk:
        'operationsComponents.processTaskRecurringAppointmentsList.deleteDialog.title',
      textTk:
        'operationsComponents.processTaskRecurringAppointmentsList.deleteDialog.text',
      icon: DialogIconType.WARNING,
      buttons: [
        ...(deletableCorrespondingAppointments.length > 0
          ? [
              {
                textTk:
                  'operationsComponents.processTaskRecurringAppointmentsList.deleteDialog.options.all',
                className: 'record-it-button-red',
                value: DeletionDialogChoice.ALL
              }
            ]
          : []),
        {
          textTk:
            'operationsComponents.processTaskRecurringAppointmentsList.deleteDialog.options.onlyrecurring',
          className: 'record-it-button-red',
          value: DeletionDialogChoice.ONLY_RECURRING
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL,
          value: DeletionDialogChoice.CANCEL
        }
      ]
    })
      .then((value) => {
        switch (value.value as DeletionDialogChoice) {
          case DeletionDialogChoice.ALL:
            this.entityManager.processTaskRecurringAppointmentRepository.delete(
              appointment
            );

            for (const correspondingAppointment of deletableCorrespondingAppointments) {
              this.entityManager.processTaskAppointmentRepository.delete(
                correspondingAppointment
              );
            }
            break;
          case DeletionDialogChoice.ONLY_RECURRING:
            this.entityManager.processTaskRecurringAppointmentRepository.delete(
              appointment
            );
            break;
          case DeletionDialogChoice.CANCEL:
            break;
          default:
            break;
        }
      })
      .catch(() => {});
  }

  private getDeletableCorrespondingAppointments({
    appointment
  }: {
    appointment: ProcessTaskRecurringAppointment;
  }): Array<ProcessTaskAppointment> {
    return this.processTaskAppointmentPermissionChecker.filterEntitiesByPermission(
      {
        entities:
          this.entityManager.processTaskAppointmentRepository.getByRecurringAppointmentId(
            appointment.id
          ),
        checkPermission: ({ adapter, entity }) => {
          return adapter.canDeleteEntity(entity);
        }
      }
    );
  }
}

enum DeletionDialogChoice {
  ALL = 'all',
  ONLY_RECURRING = 'only-recurring',
  CANCEL = 'cancel'
}

export type EditButtonClickedEvent = NamedCustomEvent<
  'edit-button-clicked',
  null
>;
