import _ from 'lodash';
import { autoinject } from 'aurelia-dependency-injection';

import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { SocketService } from '../../../../services/SocketService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { SubscriptionManager } from '../../../SubscriptionManager';
import {
  FileDownloadError,
  FileUtils
} from '../../../Utils/FileUtils/FileUtils';
import { AppEntityManager } from '../AppEntityManager';
import { PictureFile } from './types';
import { PictureFilePathService } from './PictureFilePathService';
import { EntityName } from '../types';
import { Logger } from '../../../Logger/Logger';
import { UrlManager } from '../../../UrlManager';

/**
 * automatically downloads necessary pictures for e.g. subscribed projects
 */
@autoinject()
export class PictureFileAutoDownloadService {
  private subscriptionManager: SubscriptionManager;
  private downloadItems: Array<DownloadItem> = [];
  private isDownloading: boolean = false;
  private needsToCleanupDownloadItems: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly pictureFilePathService: PictureFilePathService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.subscribeToEvent(
      'socket:connected',
      this.handleConnectedChanged.bind(this)
    );
    this.handleConnectedChanged();

    const fillPictureFileDownloadQueueRateLimited =
      this.subscriptionManager.createRateLimitedCallback(
        this.fillPictureFileDownloadQueue.bind(this),
        250
      );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      fillPictureFileDownloadQueueRateLimited,
      0
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      fillPictureFileDownloadQueueRateLimited,
      0
    );
    fillPictureFileDownloadQueueRateLimited();

    this.subscriptionManager.addDisposable(
      this.entityManager.pictureFileRepository.registerHooks({
        afterEntityRemovedLocally: () => {
          this.needsToCleanupDownloadItems = true;
        },
        afterEntityDeleted: () => {
          this.needsToCleanupDownloadItems = true;
        }
      })
    );

    // in case the downloading crashes, we try to start it again to retry it
    // this does nothing when the download is in progress as usual
    this.subscriptionManager.subscribeToInterval(() => {
      this.startDownloading();
    }, 5000);
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public getPictureFilesToDownload(): Array<PictureFile> {
    if (!DeviceInfoHelper.isApp()) {
      return [];
    }

    const joinedProjectIds =
      this.entityManager.joinedProjectsManager.getJoinedProjectIds();
    const picturesToDownload = this.entityManager.pictureRepository
      .getAll()
      .filter((picture) => {
        if (picture.project && joinedProjectIds.indexOf(picture.project) >= 0) {
          // pictures of all subscribed projects
          return true;
        }

        if (picture.picture_of_project && picture.selected) {
          // main pictures of all projects (so they can be shown in the project overview)
          return true;
        }

        if (picture.ownerThingId) {
          // currently only for via pictures
          return true;
        }

        if (picture.ownerProcessConfigurationId) {
          return true;
        }

        if (picture.ownerProcessTaskGroupId) {
          return true;
        }

        return false;
      });

    const pictureIdsToDownload = picturesToDownload.map((p) => p.id);

    return this.entityManager.pictureFileRepository
      .getAll()
      .filter((pictureFile) => {
        return (
          this.pictureFileIsReadyToDownload(pictureFile) &&
          pictureIdsToDownload.indexOf(pictureFile.picture) >= 0
        );
      });
  }

  private fillPictureFileDownloadQueue(): void {
    const filesToDownload = this.getPictureFilesToDownload();
    filesToDownload.forEach((pictureFile) => {
      const path = this.pictureFilePathService.getRelativeLocalPicPath({
        pictureFile
      });
      this.submitFileToDownloadQueue(pictureFile, path);
    });

    this.startDownloading();
  }

  private submitFileToDownloadQueue(
    pictureFile: PictureFile,
    filePath: string
  ): void {
    const item = new DownloadItem(pictureFile, filePath);
    const downloadItemIndex = this.downloadItems.findIndex((i) => {
      return i.pictureFile.id === pictureFile.id && !i.isBeingDownloaded;
    });
    if (downloadItemIndex > -1) {
      this.downloadItems[downloadItemIndex] = item;
    } else {
      this.downloadItems.push(item);
    }
  }

  private handleConnectedChanged(): void {
    if (this.socketService.isConnected()) {
      this.startDownloading();
    } else {
      this.reset();
    }
  }

  private startDownloading(): void {
    if (!this.isDownloading && this.socketService.isConnected()) {
      this.download().catch(() => {
        this.reset();
      });
    }
  }

  private reset(): void {
    this.isDownloading = false;
    this.downloadItems.forEach((item) => {
      item.isBeingDownloaded = false;
    });
  }

  private async download(): Promise<void> {
    while (this.downloadItems.length && this.socketService.isConnected()) {
      this.cleanupDownloadItemsIfNecessary();
      const item = this.getNextReadyDownloadItem();
      if (!item) {
        break;
      }

      if (this.pictureFileIsReadyToDownload(item.pictureFile)) {
        this.isDownloading = true;
        item.isBeingDownloaded = true;

        this.onDownloadCallback(await this.downloadDownloadItem(item));
      } else {
        _.remove(this.downloadItems, item);
      }
    }

    this.isDownloading = false;
  }

  private cleanupDownloadItemsIfNecessary(): void {
    if (!this.needsToCleanupDownloadItems) {
      return;
    }

    if (this.downloadItems.length) {
      const pictureFiles = new Set(
        this.entityManager.pictureFileRepository.getAll()
      );
      this.downloadItems = this.downloadItems.filter((downloadItem) => {
        return pictureFiles.has(downloadItem.pictureFile);
      });
    }

    this.needsToCleanupDownloadItems = false;
  }

  private getNextReadyDownloadItem(): DownloadItem | null {
    return (
      this.downloadItems.find((i) => {
        return (
          this.pictureFileIsReadyToDownload(i.pictureFile) &&
          !i.isBeingDownloaded
        );
      }) ?? null
    );
  }

  private pictureFileIsReadyToDownload(pictureFile: PictureFile): boolean {
    return (
      !!pictureFile.file_uploaded &&
      !pictureFile.isOriginatingHere &&
      (!pictureFile.local_created ||
        (!!pictureFile.file_created &&
          pictureFile.local_created < pictureFile.file_created))
    );
  }

  private async downloadDownloadItem(
    item: DownloadItem
  ): Promise<DownloadResponse> {
    try {
      if (!(await this.fileExistsLocally(item.filePath))) {
        await FileUtils.downloadFileToLocalFolder(
          this.pictureFilePathService.getFullOnlinePicPath({
            pictureFile: item.pictureFile,
            fullSize: true
          }),
          item.filePath
        );
      }
      return { success: true, item: item };
    } catch (error) {
      if (!(error instanceof Error)) {
        return { success: false, item: item, error: new Error(String(error)) };
      }
      return { success: false, item: item, error: error };
    }
  }

  /**
   * These case occurs because the files for projects are not deleted immediately, only after 14 days with the LocalProjectFilesManager.
   * So if someone joins a project, leaves it, and joins it again (before 14 days have passed after leaving) the files will still exist locally and don't need to be downloaded again.
   */
  private async fileExistsLocally(relativeFilePath: string): Promise<boolean> {
    try {
      // the result is stored in a variable as a workaround, because something in the build pipeline "optimizes" away the await and then errors won't be caught with the catch
      const result = await FileUtils.fileExists(
        PathUtils.joinPaths(UrlManager.localFolder, relativeFilePath)
      );

      return result;
    } catch (error) {
      Logger.logError({ error });
      return false; // just download the file if there was issue determining if the file exists locally or not
    }
  }

  private onDownloadCallback(response: DownloadResponse): void {
    const item = response.item;
    const pictureFile = item.pictureFile;
    if (response.success) {
      pictureFile.local_created = pictureFile.file_created || Date.now();
      this.entityManager.pictureFileRepository.updateLocally(pictureFile);
      _.remove(this.downloadItems, item);
    } else {
      console.log('Picture file download failed:', response.error);
      if (
        response.error instanceof FileDownloadError &&
        response.error.status === 404
      ) {
        // not found on server
        _.remove(this.downloadItems, item);
      }
      item.isBeingDownloaded = false;
    }
  }
}

class DownloadItem {
  public isBeingDownloaded = false;

  constructor(
    public pictureFile: PictureFile,
    public filePath: string
  ) {}
}

type DownloadResponse = DownloadSuccessResponse | DownloadErrorResponse;

type DownloadSuccessResponse = {
  success: true;
  item: DownloadItem;
};

type DownloadErrorResponse = {
  success: false;
  item: DownloadItem;
  error: Error | FileDownloadError;
};
