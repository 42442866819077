import {
  EntityRepository,
  EntityRepositoryOptions,
  PatchLoadedEntityOptions
} from '@record-it-npm/synchro-client';
import { EntityInfo } from '@record-it-npm/synchro-common';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { SessionService } from '../../../services/SessionService/SessionService';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManagerEntityTypesByEntityName } from '../entities/AppEntityManagerEntityTypesByEntityName';
import { BaseEntity } from '../entities/BaseEntity';
import { EntityName } from '../entities/types';

export class AppEntityRepository<
  TEntityName extends EntityName
> extends EntityRepository<
  AppSynchronizationEnvironmentTypes,
  TEntityName,
  AppEntityManagerEntityTypesByEntityName
> {
  private readonly sessionService: SessionService;

  constructor(
    entityInfo: EntityInfo<
      AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
      TEntityName,
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    >,
    options: AppEntityRepositoryOptions
  ) {
    super(entityInfo, options.entityRepositoryOptions);

    this.sessionService = options.sessionService;
  }

  protected patchLoadedEntity(
    options: PatchLoadedEntityOptions<
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    >
  ): void {
    super.patchLoadedEntity(options);

    const entity = options.entity as BaseEntity;

    if (entity.originalIds) {
      return;
    }

    if (entity.originalId) {
      entity.originalIds = [entity.originalId];
    } else {
      entity.originalIds = [];
    }
  }

  public create(
    creationEntity: AppEntityManagerEntityTypesByEntityName[TEntityName]['creationEntity']
  ): AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'] {
    const currentUserId = this.sessionService.getCurrentUser()?.id;
    if (!currentUserId) {
      throw new Error(
        `can't ${StringUtils.lowerCaseFirstLetter(
          this.getEntityInfo().entityName
        )}Repository.create because no currentUser is loaded yet`
      );
    }
    return super.create({
      ...creationEntity,
      createdByUserId: currentUserId
    });
  }
}

export type AppEntityRepositoryOptions = {
  sessionService: SessionService;
  entityRepositoryOptions: EntityRepositoryOptions<
    AppSynchronizationEnvironmentTypes,
    AppEntityManagerEntityTypesByEntityName
  >;
};
