import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { GetProjectTitlePicturesSuccessResponse } from 'common/EndpointTypes/GetActualizationsEndpointsHandler';
import { PictureDto } from 'common/Types/Entities/Picture/PictureDto';
import { PictureFileDto } from 'common/Types/Entities/PictureFile/PictureFileDto';
import { PictureRevisionDto } from 'common/Types/Entities/PictureRevision/PictureRevisionDto';
import { SocketService } from '../../../../services/SocketService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { EventAggregatorPromiseHelper } from '../../../Promise/EventAggregatorPromiseHelper';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { AppEntityManager } from '../AppEntityManager';

@autoinject()
export class ProjectTitlePicturesActualizationsService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly eventAggregator: EventAggregator,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerPostActualizationTask(
        this.postActualization.bind(this)
      )
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private async postActualization(): Promise<void> {
    const projectPictureEntities = await this.getProjectPictureEntities();

    for (const picture of projectPictureEntities.pictures) {
      this.entityManager.pictureRepository.addExternalEntityLocally(picture);
    }

    for (const pictureFile of projectPictureEntities.pictureFiles) {
      this.entityManager.pictureFileRepository.addExternalEntityLocally(
        pictureFile
      );
    }

    for (const pictureRevision of projectPictureEntities.pictureRevisions) {
      this.entityManager.pictureRevisionRepository.addExternalEntityLocally(
        pictureRevision
      );
    }
  }

  private async getProjectPictureEntities(): Promise<{
    pictures: Array<PictureDto<string, string>>;
    pictureFiles: Array<PictureFileDto<string, string>>;
    pictureRevisions: Array<PictureRevisionDto<string, string>>;
  }> {
    const response =
      await EventAggregatorPromiseHelper.createConnectedPromise<GetProjectTitlePicturesSuccessResponse>(
        this.eventAggregator,
        new Promise((resolve, reject) => {
          this.socketService.getProjectTitlePictures((data) => {
            if (data.success) {
              resolve(data);
            } else {
              reject(new Error(data.message));
            }
          });
        })
      );

    return {
      pictures: response.pictures,
      pictureFiles: response.pictureFiles,
      pictureRevisions: response.pictureRevisions
    };
  }
}
