import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { EntityNameToSearchResultMap } from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';

@autoinject
export class PersonSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.Person]
    | null = null;

  constructor(private readonly router: Router) {}

  @computed(expression('searchResult'))
  protected get itemProperties(): Array<ItemProperty> {
    if (!this.searchResult) return [];

    return [
      {
        labelTk: 'modelProperties.name',
        value: this.searchResult.name
      },
      {
        labelTk: 'modelProperties.companyName',
        value: this.searchResult.companyName
      },
      {
        labelTk: 'modelProperties.address',
        value: this.searchResult.address
      },
      {
        labelTk: 'modelProperties.iban',
        value: this.searchResult.iban
      },
      {
        labelTk: 'modelProperties.bankAccountHolder',
        value: this.searchResult.bankAccountHolder
      },
      {
        labelTk: 'modelProperties.note',
        value: this.searchResult.note
      },
      {
        labelTk: 'modelProperties.vatIdentificationNumber',
        value: this.searchResult.vatIdentificationNumber
      }
    ];
  }

  protected handleNavigateToPersonClick(): void {
    assertNotNullOrUndefined(
      this.searchResult,
      'cannot navigate to person without a search result'
    );
    this.router.navigateToRoute('edit_person', {
      person_id: this.searchResult.id
    });
  }
}
