import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SortUtils } from 'common/Utils/SortUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertyProcessTaskPositionWidget
  implements PropertySubWidget
{
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyProcessTaskPositionWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  protected noProcessTaskFound = false;
  protected selectablePositions: Array<ProcessTaskPosition> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    throw new Error(
      "BasePropertyProcessTaskPositionWidget doesn't support focus"
    );
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'configuration.binding.projectId',
      () => {
        this.updateSelectablePositions();
      }
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      () => {
        this.updateSelectablePositions();
      }
    );

    this.updateSelectablePositions();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<ProcessTaskPosition, ProcessTaskPosition>
  ): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyDropdownWidget.handleSelectChanged without configuration"
    );

    this.configuration.binding.setProcessTaskPositionValue({
      processTaskPosition: event.detail.value
    });
  }

  private updateSelectablePositions(): void {
    const processTaskToProject = this.configuration?.binding.projectId
      ? this.entityManager.processTaskToProjectRepository.getByProjectId(
          this.configuration?.binding.projectId
        )[0]
      : null;

    if (processTaskToProject) {
      this.noProcessTaskFound = false;
      this.selectablePositions =
        this.entityManager.processTaskPositionRepository
          .getByProcessTaskIdWithoutSnapshots(
            processTaskToProject.ownerProcessTaskId
          )
          .sort((a, b) =>
            SortUtils.localeCompareFalsyNumericStrings(
              a.namePrefix,
              b.namePrefix
            )
          );
    } else {
      this.noProcessTaskFound = true;
      this.selectablePositions = [];
    }
  }
}

export const basePropertyProcessTaskPositionWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default', 'position', 'projectId']
  });
