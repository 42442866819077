import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationFollowUpAppointment } from '../../classes/EntityManager/entities/ProcessConfigurationFollowUpAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationFollowUpAppointmentsWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private isAttached: boolean = false;
  protected followUpAppointments: Array<ProcessConfigurationFollowUpAppointment> =
    [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationFollowUpAppointmentsWidget,
        propertyName: 'processConfiguration'
      });
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationFollowUpAppointment,
      this.updateFollowUpAppointments.bind(this)
    );
    this.updateFollowUpAppointments();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateFollowUpAppointments();
    }
  }

  private updateFollowUpAppointments(): void {
    if (this.processConfiguration) {
      this.followUpAppointments =
        this.entityManager.processConfigurationFollowUpAppointmentRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.followUpAppointments = [];
    }
  }

  protected handleAddFollowUpAppointmentClick(): void {
    if (!this.processConfiguration) {
      throw new Error(
        "can't create a followUpAppointment without a processConfiguration"
      );
    }

    this.entityManager.processConfigurationFollowUpAppointmentRepository.create(
      {
        ownerProcessConfigurationId: this.processConfiguration.id,
        ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
        temporaryGroupName: this.processConfiguration.temporaryGroupName
      }
    );

    this.updateFollowUpAppointments();
  }
}
