import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * An icon that, when clicked, expands to reveal an area.
 *
 * @slot default - The content shown when InlineExpandableArea is expanded
 * @see ExpandableSearchInput
 *
 * @event opened-icon-clicked
 * @event closed-icon-clicked
 */
@autoinject()
export class InlineExpandableArea {
  /**
   * Whether the icon is in the expanded state or not.
   */
  @bindable public expanded = false;

  /**
   * If true, this component will toggle `expanded` itself whenever the user clicks on the toggle buttons.
   * If false, a click will not change the state and the parent component must handle toggling
   * `expanded` themselves.
   */
  @bindable public enableAutoExpansion = true;

  /**
   * Whether a spinner is displayed instead of the opened or closed icons.
   */
  @bindable public loading = false;

  /**
   * What max-width the content should reach when expanding.
   */
  @bindable public targetMaxWidth = 300;

  @bindable public openedIconType = 'far';
  @bindable public openedIconName = 'fa-times';

  @bindable public closedIconType = 'fas';
  @bindable public closedIconName = 'fa-pen';

  protected openedIconRef: HTMLElement | null = null;

  protected closedIconRef: HTMLElement | null = null;

  protected contentWrapperRef: HTMLElement | null = null;

  private openedIconAnimator: ShowHideAnimator | null = null;
  private closedIconAnimator: ShowHideAnimator | null = null;
  private contentWrapperRefAnimator: ShowHideAnimator | null = null;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    if (this.expanded) {
      this.expand();
    }
  }

  protected bind(): void {
    assertNotNullOrUndefined(
      this.openedIconRef,
      "Can't register openedIconAnimator if there isn't a openedIconRef"
    );
    assertNotNullOrUndefined(
      this.closedIconRef,
      "Can't register closedIconAnimator if there isn't a closedIconRef"
    );
    assertNotNullOrUndefined(
      this.contentWrapperRef,
      "Can't register contentWrapperRefAnimator if there isn't a contentWrapperRef"
    );
    this.openedIconAnimator = new ShowHideAnimator(this.openedIconRef);
    this.closedIconAnimator = new ShowHideAnimator(this.closedIconRef);
    this.contentWrapperRefAnimator = new ShowHideAnimator(
      this.contentWrapperRef
    );
  }

  // /////////// METHODS /////////////

  /**
   * Expand so the content slot + openedIcon is visible
   */
  private expand(): void {
    assertNotNullOrUndefined(
      this.openedIconAnimator,
      "Can't animate without openedIconAnimator"
    );
    assertNotNullOrUndefined(
      this.closedIconAnimator,
      "Can't animate without closedIconAnimator"
    );
    assertNotNullOrUndefined(
      this.contentWrapperRefAnimator,
      "Can't animate without contentWrapperRefAnimator"
    );
    void this.openedIconAnimator.scaleFadeIn();
    void this.closedIconAnimator.scaleFadeOut();
    void this.contentWrapperRefAnimator.slideFadeIn(this.targetMaxWidth);
  }

  /**
   * Collapses so only closedIcon is visible
   */
  private collapse(): void {
    assertNotNullOrUndefined(
      this.openedIconAnimator,
      "Can't animate without openedIconAnimator"
    );
    assertNotNullOrUndefined(
      this.closedIconAnimator,
      "Can't animate without closedIconAnimator"
    );
    assertNotNullOrUndefined(
      this.contentWrapperRefAnimator,
      "Can't animate without contentWrapperRefAnimator"
    );
    void this.openedIconAnimator.scaleFadeOut();
    void this.closedIconAnimator.scaleFadeIn();
    void this.contentWrapperRefAnimator.slideFadeOut();
  }

  // /////////// OBSERVABLES /////////////

  protected expandedChanged(): void {
    this.expanded ? this.expand() : this.collapse();
  }

  // /////////// CLICK HANDLERS /////////////

  protected handleOpenedIconClick(): void {
    if (this.enableAutoExpansion) {
      this.expanded = false;
    }

    DomEventHelper.fireEvent(this.domElement, {
      name: 'opened-icon-clicked',
      detail: null
    });
  }

  protected handleClosedIconClick(): void {
    if (this.enableAutoExpansion) {
      this.expanded = true;
    }

    DomEventHelper.fireEvent(this.domElement, {
      name: 'closed-icon-clicked',
      detail: null
    });
  }
}
