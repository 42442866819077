import { Router } from 'aurelia-router';
import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { ProcessConfiguration } from './types';

export class ProcessConfigurationUtils {
  private constructor() {}

  public static processConfigurationSortOptions: ProcessConfigurationSortOptions =
    {
      name: {
        name: 'Name',
        sortFunction: (a, b) => {
          const aName = a.name || '';
          const bName = b.name || '';

          return aName.localeCompare(bName);
        }
      }
    };

  public static navigateToEditProcessConfigurationStepsPage(
    router: Router,
    processConfigurationId: string
  ): void {
    router.navigateToRoute('edit_process_configuration_steps', {
      process_configuration_id: processConfigurationId
    });
  }

  public static navigateToEditProcessConfigurationDevicesPage(
    router: Router,
    processConfigurationId: string
  ): void {
    router.navigateToRoute('edit_process_configuration_devices', {
      process_configuration_id: processConfigurationId
    });
  }

  public static navigateToEditProcessConfigurationDeviceExportsPage(
    router: Router,
    processConfigurationId: string
  ): void {
    router.navigateToRoute('edit_process_configuration_device_exports', {
      process_configuration_id: processConfigurationId
    });
  }

  public static navigateToEditProcessConfigurationFormsPage(
    router: Router,
    processConfigurationId: string
  ): void {
    router.navigateToRoute('edit_process_configuration_forms', {
      process_configuration_id: processConfigurationId
    });
  }
}

export type ProcessConfigurationSortOptions = {
  name: ProcessConfigurationSortOption;
};

export type ProcessConfigurationSortOption =
  SorterSortOption<ProcessConfiguration>;
