import { bindable, inject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * @event edit-user-company-setting-clicked
 */
@inject(Element, AppEntityManager)
export class UserCompanySettingListItem {
  /** @type {import('../../classes/EntityManager/entities/UserCompanySetting/types').UserCompanySetting} */
  @bindable userCompanySetting;

  /** @type {boolean} */
  _panelOpened = false;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {HTMLElement} */
  _listItemElement;

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   */
  constructor(element, entityManager) {
    this._domElement = element;
    this._entityManager = entityManager;
  }

  highlight() {
    EntityListItemHelper.highlightListItemElement(this._listItemElement);
  }

  _handleMoreButtonClick() {
    this._panelOpened = !this._panelOpened;
  }

  _handleEditSettingClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-user-company-setting-clicked',
      detail: null
    });
  }

  _handleDeleteSettingClick() {
    Dialogs.deleteEntityDialog(this.userCompanySetting).then(() => {
      this._entityManager.userCompanySettingRepository.delete(
        this.userCompanySetting
      );
    });
  }
}
