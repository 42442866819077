import { signalBindings } from 'aurelia-binding';
import { BindingSignaler } from 'aurelia-templating-resources';

import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { DelayedQueue } from './Queue/DelayedQueue';
import { EntityName } from './EntityManager/entities/types';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';
import { SubscriptionManager } from './SubscriptionManager';

export class GlobalSignalsHelper {
  /**
   * for each model name a signal name modelNameUpdated will be executed when a model of this type has been changed (rate limited)
   */
  private static modelNames: Array<EntityName> = [
    EntityName.Entry,
    EntityName.Person,
    EntityName.Picture,
    EntityName.ProcessConfigurationDevice,
    EntityName.Property,
    EntityName.StructureTemplateEntry,
    EntityName.Thing,
    EntityName.ThingToPerson,
    EntityName.User,
    EntityName.UserGroup
  ];

  private subscriptionManager: SubscriptionManager;
  private signalingQueue: DelayedQueue;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private bindingSignaler: BindingSignaler
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.signalingQueue = new DelayedQueue({
      chunkSize: 1,
      timeout: 10,
      workerFunction: (signalName) => {
        console.log(`signaling: ${signalName}`);
        this.bindingSignaler.signal(signalName);
        signalBindings(signalName); // needed so value converters update correctly
      }
    });

    GlobalSignalsHelper.modelNames.forEach((modelName) => {
      this.registerListener(modelName);
    });
  }

  private registerListener(modelName: EntityName): void {
    const signalName = StringUtils.lowerCaseFirstLetter(modelName) + 'Updated';
    this.subscriptionManager.subscribeToModelChanges(modelName, () => {
      this.signalingQueue.queue(signalName);
    });
  }
}
