import { autoinject, bindable } from 'aurelia-framework';
import { GeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { InlineCreationSection } from '../../aureliaComponents/inline-creation-section/inline-creation-section';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskComment } from '../../classes/EntityManager/entities/ProcessTaskComment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { watch } from '../../hooks/watch';
import { ClickableTextInput } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { MultiFileInput } from '../../inputComponents/multi-file-input/multi-file-input';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ProcessTaskDeselectedEvent,
  ProcessTaskSelectedEvent
} from '../multi-process-task-selection-widget/multi-process-task-selection-widget';

@autoinject()
export class ProcessTaskCommentInlineCreationWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTasksWhereCommentsCanBeCreatedHandle: EntitiesWithPermissionHandle<EntityName.ProcessTask>;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private text: string | null = null;
  private errorTextTks: Array<string> | null = null;
  private textInput: ClickableTextInput | null = null;
  private multiFileInput: MultiFileInput | null = null;
  private inlineCreationSection: InlineCreationSection | null = null;
  private currentUser: User | null = null;
  private selectedProcessTasks: Array<ProcessTask> = [];

  protected readonly GeneralFileHelper = GeneralFileHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly generalFileUploadService: GeneralFileUploadService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTasksWhereCommentsCanBeCreatedHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.ProcessTask,
        permissionName: 'canCreateProcessTaskComments'
      });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskCommentInlineCreationWidget,
        propertyName: 'processTask'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((user) => {
        this.currentUser = user;
      })
    );

    this.updateProcessTasksWhereChecklistEntriesCanBeCreatedHandle();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    this.selectedProcessTasks = this.processTask ? [this.processTask] : [];
  }

  @watch(
    expression('processTask.ownerProcessTaskGroupId'),
    model(EntityName.ProcessTask)
  )
  private updateProcessTasksWhereChecklistEntriesCanBeCreatedHandle(): void {
    if (this.processTask) {
      this.processTasksWhereCommentsCanBeCreatedHandle.setEntities(
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTask.ownerProcessTaskGroupId
        )
      );
    } else {
      this.processTasksWhereCommentsCanBeCreatedHandle.setEntities([]);
    }
  }

  private handleCreationSectionOpened(): void {
    if (this.textInput) {
      this.textInput.toggleEditMode();
    }
  }

  private handleCreationSectionClosed(): void {
    if (this.multiFileInput) {
      this.multiFileInput.selectedFiles = [];
    }
  }

  private handleProcessTaskSelected(event: ProcessTaskSelectedEvent): void {
    ArrayUtils.pushUnique(this.selectedProcessTasks, event.detail.processTask);
  }

  private handleProcessTaskDeselected(event: ProcessTaskDeselectedEvent): void {
    ArrayUtils.remove(this.selectedProcessTasks, event.detail.processTask);
  }

  private handleCancelNewCommentClick(): boolean {
    this.text = null;
    return true;
  }

  private handleCreateNewCommentClick(): boolean {
    this.validateNewComment();
    if (this.errorTextTks) {
      return false;
    }

    void this.waitForFilesBeingLoaded().then(() => {
      for (const processTask of this.selectedProcessTasks) {
        const comment = this.createNewComment(processTask);
        this.createCommentFiles(comment);
      }

      this.text = null;
    });

    return false;
  }

  private async waitForFilesBeingLoaded(): Promise<void> {
    assertNotNullOrUndefined(
      this.multiFileInput,
      "can't ProcessTaskCommentInlineCreationWidget.waitForFilesBeingLoaded without multiFileInput"
    );

    this.inlineCreationSection && this.inlineCreationSection.startPreloader();
    await this.multiFileInput.selectedFilesLoadedAwaitable();

    if (this.inlineCreationSection) {
      this.inlineCreationSection.stopPreloader();
      this.inlineCreationSection.close();
    }
  }

  private createNewComment(processTask: ProcessTask): ProcessTaskComment {
    assertNotNullOrUndefined(
      this.currentUser,
      "can't ProcessTaskCommentInlineCreationWidget.createNewComment without a currentUser"
    );

    const comment = this.entityManager.processTaskCommentRepository.create({
      text: this.text,
      userId: this.currentUser.id,
      createdAtProcessConfigurationStepId:
        processTask.currentProcessConfigurationStepId,
      date: new Date().toISOString(),
      ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
      ownerProcessTaskId: processTask.id,
      ownerUserGroupId: processTask.ownerUserGroupId,
      temporaryGroupName: processTask.temporaryGroupName
    });

    return comment;
  }

  private createCommentFiles(comment: ProcessTaskComment): void {
    const selectedFiles = this.multiFileInput
      ? this.multiFileInput.selectedFiles
      : [];
    selectedFiles.forEach((selectedFile) => {
      if (!selectedFile.dataUrl) {
        // should never happen since we wait for all files to be loaded before calling this function
        console.error('selected file has no dataUrl');
        return;
      }

      const { name, extension } = Utils.getFilePathComponents(
        selectedFile.name ?? ''
      );

      const generalFile = this.entityManager.generalFileRepository.create({
        name: name,
        processTaskCommentId: comment.id,
        ownerProcessTaskGroupId: comment.ownerProcessTaskGroupId,
        ownerProcessTaskId: comment.ownerProcessTaskId,
        ownerUserGroupId: comment.ownerUserGroupId,
        temporaryGroupName: comment.temporaryGroupName
      });

      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        selectedFile.dataUrl,
        extension || ''
      );
    });
  }

  private validateNewComment(): void {
    const keys: Array<string> = [];

    if (this.text == null || this.text.trim().length === 0) {
      keys.push(
        'operationsComponents.processTaskCommentInlineCreationWidget.noCommentTextEnteredErrorText'
      );
    }

    if (this.selectedProcessTasks.length === 0) {
      keys.push(
        'operationsComponents.processTaskCommentInlineCreationWidget.noProcessTaskSelected'
      );
    }

    this.errorTextTks = keys.length ? keys : null;
  }
}
