import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import { Dialogs } from '../../classes/Dialogs';
import { DateUtils } from '../../../../common/src/DateUtils';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { EditStructureTemplateDialog } from '../../dialogs/edit-structure-template-dialog/edit-structure-template-dialog';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateCopyService } from '../../classes/EntityManager/entities/StructureTemplate/StructureTemplateCopyService';

@autoinject()
export class StructureTemplateListItem {
  @bindable public structureTemplate: StructureTemplate | null = null;

  @bindable public editable = false;

  private listItemElement: HTMLElement | null = null;

  private panelOpen = false;

  private DateUtils: DateUtils = DateUtils;

  private domElement: HTMLElement;

  private StringUtils = StringUtils;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly structureTemplateCopyService: StructureTemplateCopyService
  ) {
    this.domElement = element as HTMLElement;
  }

  @computedFrom('structureTemplate.status')
  private get isDraftOrProvisionallyActive(): boolean {
    return (
      this.structureTemplate?.status === StructureTemplateStatus.DRAFT ||
      this.structureTemplate?.status ===
        StructureTemplateStatus.PROVISIONALLY_ACTIVE
    );
  }

  @computedFrom('structureTemplate.status')
  private get isProvisionallyActiveOrActive(): boolean {
    return (
      this.structureTemplate?.status ===
        StructureTemplateStatus.PROVISIONALLY_ACTIVE ||
      this.structureTemplate?.status === StructureTemplateStatus.ACTIVE
    );
  }

  @computedFrom('structureTemplate.status')
  private get isArchived(): boolean {
    return this.structureTemplate?.status === StructureTemplateStatus.ARCHIVED;
  }

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  private handleMoreButtonClicked(): void {
    this.panelOpen = !this.panelOpen;
  }

  private handleEnterStructureTemplateClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'enter-structure-template-clicked',
      detail: null
    });
  }

  private handleEditStructureTemplateClicked(): void {
    if (!this.structureTemplate) return;

    void EditStructureTemplateDialog.open({
      structureTemplate: this.structureTemplate
    });
  }

  private handleDeleteStructureTemplateClicked(): void {
    const structureTemplate = this.structureTemplate;
    if (!structureTemplate) return;

    void Dialogs.deleteEntityDialog(structureTemplate).then(() => {
      this.entityManager.structureTemplateRepository.delete(structureTemplate);
    });
  }

  private async handleArchiveStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.archiveItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.archiveItemInfo'
    );

    this.structureTemplate.status = StructureTemplateStatus.ARCHIVED;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected handleCopyStructureTemplateClicked(): void {
    if (!this.structureTemplate) return;

    this.structureTemplateCopyService.copyStructureTemplate({
      structureTemplate: this.structureTemplate
    });
  }

  protected async handlePublishStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.publishItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.publishItemInfo'
    );

    this.structureTemplate.status = StructureTemplateStatus.ACTIVE;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }

  protected async handleProvisionallyPublishStructureTemplateClicked(): Promise<void> {
    if (!this.structureTemplate) return;

    await Dialogs.yesNoDialogTk(
      'listItems.structureTemplateListItem.publishItemDialogTitle',
      {},
      'listItems.structureTemplateListItem.provisionallyPublishItemInfo'
    );

    this.structureTemplate.status =
      StructureTemplateStatus.PROVISIONALLY_ACTIVE;
    this.entityManager.structureTemplateRepository.update(
      this.structureTemplate
    );
  }
}
