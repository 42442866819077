import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import {
  UserGroup,
  UserGroupUserSpec
} from '../../classes/EntityManager/entities/UserGroup/types';
import { computed } from '../../hooks/computed';
import { currentUser, expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class UserGroupUserWidget {
  @bindable()
  public user: User | null = null;

  @bindable()
  public userGroup: UserGroup | null = null;

  @bindable()
  public userSpec: UserGroupUserSpec | null = null;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.UserGroup,
        context: this as UserGroupUserWidget,
        propertyName: 'userGroup'
      }
    );
  }

  protected handleUserGroupChanged(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't UserGroupUserWidget.handleUserGroupChanged without userGroup"
    );
    this.entityManager.userGroupRepository.update(this.userGroup);
  }

  protected handleRemoveUserSpecClick(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't UserGroupUserWidget.handleRemoveUserSpecClick without userGroup"
    );
    assertNotNullOrUndefined(
      this.userSpec,
      "can't UserGroupUserWidget.handleRemoveUserSpecClick without userSpec"
    );
    const index = this.userGroup.userSpecs.indexOf(this.userSpec);

    if (index >= 0) {
      this.userGroup.userSpecs.splice(index, 1);
      this.entityManager.userGroupRepository.update(this.userGroup);
    }
  }

  @computed(
    expression('userSpec._id'),
    expression('permissionsHandle.canEditOwnGroupAdminRights'),
    expression('permissionsHandle.canEditUserSpecs'),
    currentUser()
  )
  protected get canEditGroupAdmin(): boolean {
    if (this.userSpec?._id === this.currentUserService.getCurrentUser()?.id) {
      return this.permissionsHandle.canEditOwnGroupAdminRights;
    }

    return this.permissionsHandle.canEditUserSpecs;
  }

  @computed(
    expression('userSpec._id'),
    expression('permissionsHandle.canRemoveOwnUserSpec'),
    expression('permissionsHandle.canEditUserSpecs'),
    currentUser()
  )
  protected get canRemoveUserSpec(): boolean {
    if (this.userSpec?._id === this.currentUserService.getCurrentUser()?.id) {
      return this.permissionsHandle.canRemoveOwnUserSpec;
    }

    return this.permissionsHandle.canEditUserSpecs;
  }

  @computed(
    expression('permissionsHandle.canCreateUserRoleToUsers'),
    expression('user.id'),
    expression('userGroup.id'),
    model(EntityName.UserRoleToUser)
  )
  protected get showUserRolesWidget(): boolean {
    if (!this.user || !this.userGroup) {
      return false;
    }

    const userRoleToUsers =
      this.entityManager.userRoleToUserRepository.getByUserAndUserGroupId({
        userId: this.user.id,
        userGroupId: this.userGroup.id
      });

    return (
      this.permissionsHandle.canCreateUserRoleToUsers ||
      !!userRoleToUsers.length
    );
  }
}
