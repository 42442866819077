import {
  AbstractUploadStrategy,
  ModificationType
} from '../../../services/FileUploadService';
import { Dialogs } from '../../Dialogs';
import { OriginalIdUtils } from '../../EntityManager/utils/OriginalIdUtils/OriginalIdUtils';
import { EntityName } from '../../EntityManager/entities/types';

/**
 * @extends {AbstractUploadStrategy<TUploadItemData>}
 */
export class GeneralFileUploadStrategy extends AbstractUploadStrategy {
  /** @type {import('../../../services/SocketService').SocketService} */
  _socketService;

  /**
   * @param {import('../../EntityManager/entities/AppEntityManager').AppEntityManager} entityManager
   * @param {import('../../../services/SocketService').SocketService} socketService
   */
  constructor(entityManager, socketService) {
    super();

    this._entityManager = entityManager;
    this._socketService = socketService;
  }

  /**
   * @returns {string}
   */
  getName() {
    return 'generalFileUploadStrategy';
  }

  /**
   *
   * @param {import('../../../services/FileUploadService').ModifyUploadItems<TUploadItemData>} modifyUploadItems
   * @returns
   */
  subscribeToEvents(modifyUploadItems) {
    return [
      this._entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.GeneralFile,
        (generalFile) => {
          this._handleGeneralFileIdUpdated(generalFile, modifyUploadItems);
        }
      )
    ];
  }

  /**
   * @param {TUploadItemData} data
   * @returns {boolean}
   */
  updateUploadItemData(data) {
    const generalFile =
      this._entityManager.generalFileRepository.getByOriginalId(
        data.generalFileId
      );

    if (generalFile) {
      data.generalFileId = generalFile.id;
      return true;
    }

    return false;
  }

  /**
   * @param {TUploadItemData} a
   * @param {TUploadItemData} b
   * @returns {boolean}
   */
  uploadItemsAreSimilar(a, b) {
    return a.generalFileId === b.generalFileId;
  }

  /**
   * @param {TUploadItemData} data
   * @param {string} chunkedFileId
   * @returns {Promise<void>}
   */
  async uploadUploadItem(data, chunkedFileId) {
    const requestData = {
      generalFileId: data.generalFileId,
      extension: data.extension,
      chunkedFileId
    };

    await /** @type {Promise<void>} */ (
      new Promise((resolve, reject) => {
        this._socketService.uploadGeneralFile(requestData, (response) => {
          if (response.success) {
            resolve();
          } else {
            Dialogs.errorDialogTk(
              'general.error',
              'serverResponses.' + response.status
            );
            reject(new Error(`GeneralFileUploadError "${response.status}"`));
          }
        });
      })
    );
  }

  /**
   * @param {TUploadItemData} data
   * @returns {boolean}
   */
  itemIsReadyToBeUploaded(data) {
    // if no generalFile is found (e.g. it was temporary) we still try to upload the file
    const generalFile = this._entityManager.generalFileRepository.getById(
      data.generalFileId
    );
    if (generalFile && generalFile.onlyLocal) {
      return false;
    }

    if (
      this._entityManager.entitySynchronization.entityIsInTheQueue(
        EntityName.GeneralFile,
        data.generalFileId
      )
    ) {
      return false;
    }

    return true;
  }

  /**
   * @param {TUploadItemData} data
   * @returns {Promise<string>}
   */
  async getFileDataUrl(data) {
    return data.dataUrl;
  }

  /**
   * @param {TUploadItemData} data
   * @returns {Promise<import('common/EndpointTypes/DataRescueEndpointsHandler').UploadItemRescueData>}
   */
  async getRescueData(data) {
    const generalFile = this._entityManager.generalFileRepository.getById(
      data.generalFileId
    );
    return {
      entityId: data.generalFileId,
      entityName: EntityName.GeneralFile,
      fileExtension: generalFile?.extension ?? '',
      dataUrl: data.dataUrl
    };
  }

  /**
   *
   * @param {import('../../EntityManager/entities/GeneralFile/types').GeneralFile} generalFile
   * @param {import('../../../services/FileUploadService').ModifyUploadItems<TUploadItemData>} modifyUploadItems
   */
  _handleGeneralFileIdUpdated(generalFile, modifyUploadItems) {
    const originalIds = new Set(
      OriginalIdUtils.getOriginalIdsForEntity(generalFile)
    );
    modifyUploadItems((data) => {
      if (originalIds.has(data.generalFileId)) {
        data.generalFileId = generalFile.id;
        return ModificationType.MODIFIED;
      }

      return ModificationType.NONE;
    });
  }
}

/**
 * @typedef {Object} TUploadItemData
 * @property {string} generalFileId
 * @property {string} dataUrl
 * @property {string} extension
 */

/**
 * @typedef {import('../../../services/FileUploadService').TUploadItem<TUploadItemData>} TUploadItem
 */
