import { autoinject, bindable } from 'aurelia-framework';

import { DataUrlHelper } from 'common/DataUrlHelper';

import { Dialogs } from '../../classes/Dialogs';
import { DataUrlReader } from '../../classes/Reader/DataUrlReader/DataUrlReader';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

@autoinject()
export class MultiFileInput {
  @bindable public label: string | null = null;

  @bindable public selectedFiles: Array<TSelectedFile> = [];

  /** in bytes */
  @bindable public maxFileSize: number | null = null;

  @bindable public accept: string | undefined = this.getDefaultAcceptValue();

  private fileReaderInProgessCount: number = 0;
  private selectedFilesLoadedPromiseCallbacks: Array<() => void> = [];

  private fileInput: HTMLInputElement | null = null;

  public selectedFilesAreLoaded(): boolean {
    return this.fileReaderInProgessCount === 0;
  }

  public selectedFilesLoadedAwaitable(): Promise<void> {
    return new Promise((resolve) => {
      if (this.selectedFilesAreLoaded()) {
        resolve();
      } else {
        this.selectedFilesLoadedPromiseCallbacks.push(resolve);
      }
    });
  }

  protected handleRemoveSelectedFileClick(selectedFile: TSelectedFile): void {
    this.removeSelectedFile(selectedFile);
  }

  protected handleAddFileButtonClick(): void {
    if (this.fileInput) {
      this.fileInput.click();
    }
  }

  protected handleFileInputChange(): void {
    if (
      !this.fileInput ||
      !this.fileInput.files ||
      !this.fileInput.files.length
    ) {
      return;
    }

    for (let key = 0; key < this.fileInput.files.length; key++) {
      const file = this.fileInput.files.item(key);
      if (!file || this.fileIsAlreadySelected(file)) {
        continue;
      }

      const selectedFile = this.createdSelectedFileForFile(file);
      this.selectedFiles.push(selectedFile);
      this.loadFileIntoSelectedFile(file, selectedFile);
    }

    this.fileInput.value = '';
  }

  private getDefaultAcceptValue(): string | undefined {
    if (DeviceInfoHelper.isIOSDevice()) {
      return 'image/*,application/pdf';
    } else {
      return undefined;
    }
  }

  private fileIsAlreadySelected(file: File): boolean {
    return !!this.selectedFiles.find((sf) => sf.name === file.name);
  }

  private createdSelectedFileForFile(file: File): TSelectedFile {
    return {
      dataUrl: null,
      name: file.name,
      loaded: false
    };
  }

  private loadFileIntoSelectedFile(
    file: File,
    selectedFile: TSelectedFile
  ): void {
    const reader = new DataUrlReader();

    reader.readFile(file).then(
      (result) => this.handleFileReaderFinished(result, selectedFile),
      (error) => this.handleFileReaderError(error, selectedFile)
    );
    this.fileReaderStarted();
  }

  private handleFileReaderFinished(
    result: string,
    selectedFile: TSelectedFile
  ): void {
    if (this.validateFileSize(result)) {
      selectedFile.dataUrl = result;
      selectedFile.loaded = true;
    } else {
      void Dialogs.errorDialogTk('serverResponses.fileTooBig');
      this.removeSelectedFile(selectedFile);
    }

    this.fileReaderFinished();
  }

  private validateFileSize(dataUrl: string): boolean {
    if (!this.maxFileSize) {
      return true;
    }

    const size = DataUrlHelper.getDataUrlContentSize(dataUrl);
    if (size > this.maxFileSize) {
      return false;
    }

    return true;
  }

  private handleFileReaderError(
    error: Error,
    selectedFile: TSelectedFile
  ): void {
    console.error(error);
    this.removeSelectedFile(selectedFile);
    this.fileReaderFinished();
  }

  private fileReaderStarted(): void {
    this.fileReaderInProgessCount++;
  }

  private fileReaderFinished(): void {
    if (this.fileReaderInProgessCount > 0) {
      this.fileReaderInProgessCount--;

      if (this.fileReaderInProgessCount === 0) {
        this.selectedFilesLoadedPromiseCallbacks.forEach((r) => r());
        this.selectedFilesLoadedPromiseCallbacks.length = 0;
      }
    }
  }

  private removeSelectedFile(selectedFile: TSelectedFile): void {
    const index = this.selectedFiles.indexOf(selectedFile);
    if (index >= 0) {
      this.selectedFiles.splice(index, 1);
    }
  }
}

export type TSelectedFile = {
  dataUrl: string | null; // null when it hasn't been loaded yet
  name: string | null; // null when it hasn't been loaded yet
  loaded: boolean;
};
