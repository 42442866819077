import { i18n } from 'i18next';
import { Formatter } from '../Formatter';

/**
 * A conditional formatter.
 *
 * You can use it like this:
 *     {{variable, if(then: 'abc'; else: 'abc')}}
 * or
 *     {{variable, if(then: 'path.to.some.key';)}}
 *
 * The value of then/else will be passed through the t function again.
 * then and else are optional, if they are not specified, they will fall back to an empty string.
 */
export function createIfFormatter(i18next: i18n): Formatter {
  const translateOrFallback = (value: any, options: any): string => {
    if (value == null) {
      return '';
    }

    return String(i18next.t(value, options));
  };

  return (value, _lng, options) => {
    if (!options || typeof options !== 'object') {
      throw new Error(
        "options of the ifFormatter should be a plain object in the form of: { then?: string, else?: string }. Example when using the formatter in an translation: {{variable, if(then: 'abc'; else: 'abc')}}"
      );
    }

    if (value) {
      return translateOrFallback(options.then, options);
    } else {
      return translateOrFallback(options.else, options);
    }
  };
}
