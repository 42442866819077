/* global Msvg */

import { Tool } from './Tool';

export class EllipseTool extends Tool {
  _name = 'Ellipse';
  _icons = ['far fa-circle'];
  _machineName = 'ellipse';

  /** @type {Msvg.Point|null} */
  _firstPosition = null;
  /** @type {Msvg.Path|null} */
  _path = null;

  _cancel() {
    if (this._path) {
      this._msvg.remove(this._path);
    }
    super._cancel();
  }

  _reset() {
    super._reset();
    this._firstPosition = null;
    this._path = null;
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    if (this._path) {
      this._updatePath(position);
      this._modified();
      this._reset();
    } else {
      this._startNewPath(position);
    }
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseMovedHandler(event, position) {
    if (this._path) {
      this._updatePath(position);
    }
  }

  /**
   * @param event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingHandler(event, position) {
    if (!this._path) {
      this._startNewPath(position);
    }

    this._updatePath(position);
  }

  /**
   * @param event
   * @param {Msvg.Point} position
   * @protected
   */
  _draggingEndedHandler(event, position) {
    this._updatePath(position);
    this._msvg.append(this._path);
    this._modified();
    this._reset();
  }

  /**
   * @param {Msvg.Point} position
   * @private
   */
  _startNewPath(position) {
    this._path = this._createPath();
    this._msvg.append(this._path);
    this._firstPosition = position;
  }

  /**
   * @returns {Msvg.Path}
   * @private
   */
  _createPath() {
    const path = new Msvg.Path();
    this._setPathStyling(path);
    return path;
  }

  _stylingChanged() {
    if (this._path) {
      this._setPathStyling(this._path);
    }
  }

  /**
   * @param {Msvg.Path} path
   * @private
   */
  _setPathStyling(path) {
    path
      .setStrokeWidth(this._size)
      .setStroke(this._color)
      .setAttribute('stroke-dasharray', this._getStrokeDashArrayStyleString())
      .setFill('none');
  }

  /**
   * @param {Msvg.Point} secondPosition
   * @private
   */
  _updatePath(secondPosition) {
    const pos1 = this._firstPosition;
    const pos2 = secondPosition;

    const x = Math.min(pos1.x, pos2.x);
    const y = Math.min(pos1.y, pos2.y);
    const w = Math.abs(pos1.x - pos2.x);
    const h = Math.abs(pos1.y - pos2.y);
    const kappa = 0.5522848;
    const ox = (w / 2) * kappa;
    const oy = (h / 2) * kappa;
    const xm = x + w / 2;
    const ym = y + h / 2;
    const xe = x + w;
    const ye = y + h;

    this._path
      .reset()
      .addMoveToAbsolute(new Msvg.Point(x, ym))
      .addCurveToAbsolute(
        new Msvg.Point(xm, y),
        new Msvg.Point(x, ym - oy),
        new Msvg.Point(xm - ox, y)
      )
      .addCurveToAbsolute(
        new Msvg.Point(xe, ym),
        new Msvg.Point(xm + ox, y),
        new Msvg.Point(xe, ym - oy)
      )
      .addCurveToAbsolute(
        new Msvg.Point(xm, ye),
        new Msvg.Point(xe, ym + oy),
        new Msvg.Point(xm + ox, ye)
      )
      .addCurveToAbsolute(
        new Msvg.Point(x, ym),
        new Msvg.Point(xm - ox, ye),
        new Msvg.Point(x, ym + oy)
      )
      .addClosePath();
  }
}
