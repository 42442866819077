export class SetUtils {
  private constructor() {}

  public static setsAreEqual<TValue>({
    a,
    b
  }: {
    a: Set<TValue>;
    b: Set<TValue>;
  }): boolean {
    if (a.size !== b.size) {
      return false;
    }

    for (const value of a.values()) {
      if (!b.has(value)) {
        return false;
      }
    }

    return true;
  }
}
