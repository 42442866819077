import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { thingSectionEntityInfo } from '../../../../classes/EntityManager/entities/ThingSection/thingSectionEntityInfo';
import { ThingSection } from '../../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ThingSectionAdapter implements EntityAdapter<ThingSection> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;

  private editableUserGroupIds: Set<string> = new Set();
  private thingSectionsSetting: boolean = false;

  constructor(options: ThingSectionAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.thingSections',
        (thingSections) => {
          this.thingSectionsSetting = thingSections;
          updateBindings();
        }
      )
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(thingSection: ThingSection): boolean {
    return this.canEditDefault(thingSection);
  }

  public canEditField(thingSection: ThingSection): boolean {
    return this.canEditDefault(thingSection);
  }

  public canEditPictures(thingSection: ThingSection): boolean {
    return this.canEditDefault(thingSection);
  }

  public canEditProperties(thingSection: ThingSection): boolean {
    return this.canEditDefault(thingSection);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ThingSection,
    ThingSection
  > {
    return thingSectionEntityInfo;
  }

  private canEditDefault(thingSection: ThingSection): boolean {
    return (
      this.thingSectionsSetting && this.entityIsEditableUserGroup(thingSection)
    );
  }

  private entityIsEditableUserGroup(entity: ThingSection): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type ThingSectionAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
  activeUserCompanySettingService: ActiveUserCompanySettingService;
};
