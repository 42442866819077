import { MomentInput } from 'moment';

import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntityConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter } from '../../propertyComponents/manage-property-definitions-widget/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter/ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';
import { RxjsService } from '../../services/RxjsService/RxjsService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditUserDefinedEntityConfigDialog {
  protected userDefinedEntityConfig: UserDefinedEntityConfig | null = null;

  private onDialogClosed: OnDialogClosedCallback | null = null;

  private dialog: RecordItDialog | null = null;

  protected managePropertyDefinitionsWidgetAdapter: ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter | null =
    null;

  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntityConfig];

  private subscriptionManager: SubscriptionManager;

  public static async open(
    options: EditUserDefinedEntityConfigDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly rxjsService: RxjsService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.UserDefinedEntityConfig,
        context: this,
        expression: 'userDefinedEntityConfig'
      });
  }

  public open(options: EditUserDefinedEntityConfigDialogOptions): void {
    this.userDefinedEntityConfig = options.userDefinedEntityConfig;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.managePropertyDefinitionsWidgetAdapter =
      new ManagePropertyDefinitionsWidgetUserDefinedEntityConfigAdapter({
        entityManager: this.entityManager,
        rxjsService: this.rxjsService,
        subscriptionManagerService: this.subscriptionManagerService,
        permissionsService: this.permissionsService,
        userDefinedEntityConfig: options.userDefinedEntityConfig
      });

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
    if (this.userDefinedEntityConfig)
      this.onDialogClosed?.(this.userDefinedEntityConfig);
    this.managePropertyDefinitionsWidgetAdapter = null;
  }

  protected handleUserDefinedEntityConfigChanged(): void {
    assertNotNullOrUndefined(
      this.userDefinedEntityConfig,
      "can't EditUserDefinedEntityConfigDialog.handleUserDefinedEntityConfigChanged without a userDefinedEntityConfig"
    );
    this.entityManager.userDefinedEntityConfigRepository.update(
      this.userDefinedEntityConfig
    );
  }

  protected formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  protected getUsergroupNameById(usergroupId: string): string {
    const group = this.entityManager.userGroupRepository.getById(usergroupId);
    return group?.name ?? '';
  }
}

type EditUserDefinedEntityConfigDialogOptions = {
  userDefinedEntityConfig: UserDefinedEntityConfig;
  onDialogClosed?: OnDialogClosedCallback;
};

type OnDialogClosedCallback = (
  userDefinedEntityConfig: UserDefinedEntityConfig
) => void;
