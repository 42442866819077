import { FileUploadService } from '../../services/FileUploadService';
import { SocketService } from '../../services/SocketService';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../EntityManager/entities/User/CurrentUserService';

export class UnsynchedItemsUploader {
  constructor(
    private entityManager: AppEntityManager,
    private currentUserService: CurrentUserService,
    private socketService: SocketService,
    private fileUploadService: FileUploadService
  ) {}

  public uploadUnsynchedItems(): Promise<void> {
    return new Promise((res, rej) => {
      this.socketService.uploadUnsynchedItems(
        {
          userId: this.currentUserService.getRequiredCurrentUser().id,
          items:
            this.entityManager.entitySynchronization.getItemsToSynchronizeCopies()
        },
        (data) => {
          if (data.success) {
            res();
          } else {
            rej();
          }
        }
      );
    });
  }

  public async uploadFileUploadItems(): Promise<void> {
    const fileUploadItems = await this.fileUploadService.getRescueDataItems();

    for (const item of fileUploadItems) {
      await new Promise<void>((res, rej) => {
        this.socketService.uploadFileUploadItem(
          {
            userId: this.currentUserService.getRequiredCurrentUser().id,
            item: item
          },
          (data) => {
            if (data.success) {
              res();
            } else {
              rej();
            }
          }
        );
      });
    }
  }
}
