import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskAppointmentContactDto } from './ProcessTaskAppointmentContactDto';

export function createProcessTaskAppointmentContactEntityInfo<
  TEntity extends ProcessTaskAppointmentContactDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskAppointmentContactDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskAppointmentContact,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskAppointmentContact,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskAppointmentContactDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        personContactId: {
          name: 'personContactId',
          ref: EntityName.PersonContact,
          cascadeDeleteLocally: true,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
