import { autoinject, bindable } from 'aurelia-framework';
import { ExpandableContainer } from '../expandable-container/expandable-container';

@autoinject()
export class ExpandableContainerMultiToggle {
  @bindable public containers: Array<ExpandableContainer> = [];

  protected toggleAll(): void {
    if (!this.allExpanded) {
      this.containers.forEach((c) => c.expand());
    } else {
      this.containers.forEach((c) => c.collapse());
    }
  }

  protected get allExpanded(): boolean {
    return this.containers.every((c) => c.isExpanded()) ?? false;
  }
}
