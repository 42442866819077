export class DisposableUtils {
  private constructor() {}

  public static disposableCallback<TArgs extends Array<any>, TReturnValue>(
    callback: (...args: TArgs) => TReturnValue
  ): DisposableCallback<TArgs, TReturnValue> {
    let disposed = false;

    const wrappedCallback: DisposableCallback<TArgs, TReturnValue> = (
      ...args
    ) => {
      if (disposed) {
        return { called: false };
      }

      return {
        called: true,
        result: callback(...args)
      };
    };

    wrappedCallback.dispose = () => {
      disposed = true;
    };

    return wrappedCallback;
  }
}

export type DisposableCallback<TArgs extends Array<any>, TReturnValue> = ((
  ...args: TArgs
) => DisposableCallbackResult<TReturnValue>) & {
  dispose: () => void;
};

export type DisposableCallbackCalledResult<TReturnValue> = {
  called: true;
  result: TReturnValue;
};

export type DisposableCallbackNotCalledResult = {
  called: false;
};

export type DisposableCallbackResult<TReturnValue> =
  | DisposableCallbackCalledResult<TReturnValue>
  | DisposableCallbackNotCalledResult;
