import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { CheckboxCheckedChangedEvent } from '../../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { TextBrickTemplate } from '../../../classes/EntityManager/entities/TextBrickTemplate/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { watch } from '../../../hooks/watch';
import { EntitiesPermissionChecker } from '../../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  TextBrickSelectionData,
  TextBrickTemplateIsSelectedData
} from '../TextBrickSelectionData';

@autoinject()
export class EditTextBrickTemplatesDialogTextBrickTemplate {
  @bindable()
  public textBrickTemplate: TextBrickTemplate | null = null;

  @bindable()
  public selectionData: TextBrickSelectionData | null = null;

  @bindable()
  public entry: Entry | null = null;

  private readonly selectionDataSubscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly textBrickTemplatePermissionsHandle: EntityNameToPermissionsHandle[EntityName.TextBrickTemplate];

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  @subscribableLifecycle()
  protected readonly textBrickPermissionsChecker: EntitiesPermissionChecker<EntityName.TextBrick>;

  private textBrickTemplateIsSelectedData: TextBrickTemplateIsSelectedData | null =
    null;

  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.textBrickTemplatePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.TextBrickTemplate,
        context: this as EditTextBrickTemplatesDialogTextBrickTemplate,
        propertyName: 'textBrickTemplate'
      });

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Entry,
        context: this as EditTextBrickTemplatesDialogTextBrickTemplate,
        propertyName: 'entry'
      });

    this.textBrickPermissionsChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.TextBrick
      });

    this.selectionDataSubscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  @watch(
    expression('selectionData'),
    expression('textBrickTemplate'),
    expression('isAttached')
  )
  protected updateSelectionDataSubscription(): void {
    this.selectionDataSubscriptionManager.disposeSubscriptions();
    if (!this.selectionData || !this.textBrickTemplate || !this.isAttached) {
      this.textBrickTemplateIsSelectedData = null;
      return;
    }

    this.selectionDataSubscriptionManager.addDisposable(
      this.selectionData.bindTextBrickTemplateIsSelectedData({
        textBrickTemplate: this.textBrickTemplate,
        callback: (textBrickTemplateIsSelectedData) => {
          this.textBrickTemplateIsSelectedData =
            textBrickTemplateIsSelectedData;
        }
      })
    );
  }

  protected handleCheckboxChanged(event: CheckboxCheckedChangedEvent): void {
    assertNotNullOrUndefined(
      this.textBrickTemplate,
      "can't EditTextBrickTemplatesDialogTextBrickTemplate.handleCheckboxChanged without textBrickTemplate"
    );

    if (event.detail.checked) {
      this.selectionData?.selectTextBrickTemplate({
        textBrickTemplate: this.textBrickTemplate
      });
    } else {
      this.selectionData?.deselectTextBrickTemplate({
        textBrickTemplate: this.textBrickTemplate
      });
    }
  }

  protected handleTextBrickTemplateChanged(): void {
    assertNotNullOrUndefined(
      this.textBrickTemplate,
      "can't EditTextBrickTemplatesDialogTextBrickTemplate.handleTextBrickTemplateChanged without textBrickTemplate"
    );

    this.entityManager.textBrickTemplateRepository.update(
      this.textBrickTemplate
    );
  }

  protected handleDeleteClicked(): void {
    const textBrickTemplate = this.textBrickTemplate;
    assertNotNullOrUndefined(
      textBrickTemplate,
      "can't EditTextBrickTemplatesDialogTextBrickTemplate.handleDeleteClicked without textBrickTemplate"
    );

    void Dialogs.deleteEntityDialog(textBrickTemplate).then(() => {
      this.entityManager.textBrickTemplateRepository.delete(textBrickTemplate);
    });
  }

  @computed(
    expression('textBrickTemplateIsSelectedData'),
    expression('textBrickPermissionsChecker.revision')
  )
  protected get checkboxIsDisabled(): boolean {
    if (!this.textBrickTemplateIsSelectedData?.textBrickTemplateIsSelected) {
      return !this.entryPermissionsHandle.canCreateTextBricks;
    }

    return !this.textBrickPermissionsChecker.allEntitiesHavePermission({
      entities: this.textBrickTemplateIsSelectedData.existingTextBricks,
      checkPermission: ({ adapter, entity }) => {
        return adapter.canDeleteEntity(entity);
      }
    });
  }

  @computed(
    expression('textBrickTemplate.value'),
    expression('textBrickTemplateIsSelectedData.existingTextBricks')
  )
  protected get textBrickText(): string {
    const firstTextBrick =
      this.textBrickTemplateIsSelectedData?.existingTextBricks[0];

    if (
      firstTextBrick &&
      firstTextBrick.value !== this.textBrickTemplate?.value
    ) {
      return firstTextBrick.value;
    } else {
      return '';
    }
  }

  @computed(expression('textBrickTemplate.path'))
  protected get hasWildcardInPath(): boolean {
    if (!this.textBrickTemplate) {
      return false;
    }

    return this.textBrickTemplate.path.some((pathItem) => pathItem === '*');
  }
}
