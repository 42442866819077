import { autoinject } from 'aurelia-dependency-injection';

import { SubscriptionManagerService } from './SubscriptionManagerService';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import {
  NotificationWidget,
  Notification
} from '../aureliaComponents/notification-widget/notification-widget';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { SocketService } from './SocketService';

import { changelogNotifications } from '../data/currentChangelogNotifications';

@autoinject()
export class ChangelogService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService,
    private readonly socketService: SocketService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public init(): void {
    this.subscriptionManager.subscribeToEvent(
      'socket:authenticated',
      this.onAuthenticatedEvent.bind(this)
    );
    if (this.socketService.isAuthenticated()) {
      void this.onAuthenticatedEvent();
    }
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private async onAuthenticatedEvent(): Promise<void> {
    const currentChangelogNotifications =
      this.getCurrentChangelogNotifications();
    if (!currentChangelogNotifications || !currentChangelogNotifications.length)
      return;

    const currentUser = await this.currentUserService.waitForCurrentUser();
    const newerThan = currentUser.notificationAcknowledgedAt
      ? new Date(currentUser.notificationAcknowledgedAt)
      : undefined;

    void NotificationWidget.open({
      notifications: currentChangelogNotifications,
      newerThan: newerThan,
      notificationClosed: this.notificationClosed.bind(this)
    });
  }

  private getCurrentChangelogNotifications(): Array<Notification> {
    return changelogNotifications;
  }

  private notificationClosed(date?: Date): void {
    const currentUser = this.currentUserService.getCurrentUser();
    if (!currentUser) return;

    currentUser.notificationAcknowledgedAt = date
      ? new Date(date).toISOString()
      : new Date().toISOString();
    this.entityManager.userRepository.update(currentUser);
  }
}
