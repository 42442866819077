import { Tool } from './Tool';
import { PointerEventHelper } from '../../../classes/PointerEventHelper';

export class DeleteTool extends Tool {
  _name = 'Löschen';
  _icons = ['far fa-trash', 'fas fa-mouse-pointer'];
  _machineName = 'delete';
  _mouseIsDown = false;
  /** @type {Map} */
  _originalElementToClickAreaElementMap = new Map();
  /** @type {Map} */
  _clickAreaElementToOriginalElementMap = new Map();

  drawingAreaExternallyModified() {
    super.drawingAreaExternallyModified();

    this._removeClickAreaElements();
    this._addClickAreaElements();
  }

  _activated() {
    this._addClickAreaElements();
  }

  _cancel() {
    super._cancel();
    this._removeClickAreaElements();
  }

  /**
   * @param {MouseEvent} event
   * @param {Msvg.Point} position
   * @protected
   */
  _mouseClickedHandler(event, position) {
    const rootElement = this._getRootSvgElement(event.target);
    const originalElement =
      this._clickAreaElementToOriginalElementMap.get(rootElement);
    const element = originalElement ? originalElement : rootElement;
    if (element && this._elementIsDeletable(element)) {
      this._removeRootElementFromMsvg(element);
      return true;
    }

    return false;
  }

  /**
   *
   * @param {Element} element
   * @returns {(SVGElement|null)}
   * @private
   */
  _getRootSvgElement(element) {
    let svgElement = this._msvg.getElement();
    let currentElement = element;

    do {
      if (
        currentElement.parentNode &&
        currentElement.parentNode === svgElement
      ) {
        return currentElement;
      }
    } while ((currentElement = currentElement.parentNode));

    return null;
  }

  /**
   *
   * @param {Element} element
   * @private
   */
  _elementIsDeletable(element) {
    return !element.hasAttribute('data-old-sketch');
  }

  /**
   *
   * @param {SVGElement} rootElement
   * @private
   */
  _removeRootElementFromMsvg(rootElement) {
    this._msvg.removeSvgElement(rootElement);
    this._removeClickAreaElements();
    this._modified();
    this._addClickAreaElements();
  }

  /**
   *
   * @param {MouseEvent} event
   * @returns {Msvg.Point}
   * @private
   */
  _getMousePositionRelativeToSvgElement(event) {
    const position = PointerEventHelper.getClientPositionFromEvent(event);
    const boundingRect = this._msvg.getElement().getBoundingClientRect();
    return new Msvg.Point(
      position.getX() - boundingRect.x,
      position.getY() - boundingRect.y
    );
  }

  _addClickAreaElements() {
    const svgElement = this._msvg.getElement();
    const childNodes = Array.from(svgElement.childNodes);
    const insertRefNode = svgElement.firstChild;

    for (let key = childNodes.length - 1; key >= 0; key--) {
      const node = childNodes[key];
      if (
        !(
          node instanceof SVGPathElement ||
          node instanceof SVGRectElement ||
          node instanceof SVGLineElement
        )
      ) {
        continue;
      }

      if (this._originalElementToClickAreaElementMap.get(node)) {
        continue;
      }

      const clone = node.cloneNode(true);
      this._originalElementToClickAreaElementMap.set(node, clone);
      this._clickAreaElementToOriginalElementMap.set(clone, node);

      clone.setAttribute('stroke', 'transparent');
      clone.setAttribute('stroke-width', 25);
      clone.setAttribute('stroke-linecap', 'round');
      clone.setAttribute('data-temporary-element', ''); //just in case this lands in the result, to be able to remove it later on
      svgElement.insertBefore(clone, insertRefNode);
    }
  }

  _removeClickAreaElements() {
    const clones = this._clickAreaElementToOriginalElementMap.keys();
    for (let clone of clones) {
      if (clone.parentNode) {
        clone.parentNode.removeChild(clone);
      }
    }

    this._clickAreaElementToOriginalElementMap = new Map();
    this._originalElementToClickAreaElementMap = new Map();
  }
}
