import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import {
  Map,
  Circle,
  CircleMarker,
  Control,
  ControlOptions,
  LeafletEvent,
  LeafletMouseEvent
} from 'leaflet';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import '../../vendor';

class LeafletLocationTracker {
  private controlElement: HTMLElement | null = null;
  private toggleActiveButton: HTMLAnchorElement | null = null;

  private locationMarker: CircleMarker | null = null;
  private accuracyCircle: Circle | null = null;

  private map: Map;

  private initialized = false;

  private active = false;

  private subscriptionManager: SubscriptionManager;

  constructor(
    map: Map,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.map = map;
    this.initialized = false;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initialize(): void {
    if (this.initialized) {
      return;
    }

    this.controlElement = L.DomUtil.create('div');
    this.controlElement.setAttribute('title', 'Tracker');
    this.controlElement.className = 'leaflet-bar';

    this.toggleActiveButton = L.DomUtil.create('a') as HTMLAnchorElement;
    this.toggleActiveButton.href = 'javascript:void(0)';
    this.controlElement.appendChild(this.toggleActiveButton);

    const icon = L.DomUtil.create('i');
    icon.className = 'basemap-map--ControlIcon far fa-location';
    this.toggleActiveButton.appendChild(icon);

    L.DomEvent.on(
      this.toggleActiveButton,
      'click',
      this.handleToggleActiveClick,
      this
    );
    this.map.on('move', this.handleMapMove, this);

    const coordinates = CoordinateHelper.getClientCoordinates();
    this.subscriptionManager.subscribeToMultiplePropertyChanges(
      coordinates,
      ['latitude', 'longitude', 'accuracy'],
      this.handleLocationChanged.bind(this)
    );
    this.handleLocationChanged();

    this.initialized = true;
  }

  public getControlElement(): HTMLElement {
    assertNotNullOrUndefined(
      this.controlElement,
      'no controlElement available, is the LeafletLocationTrack initialized?'
    );
    return this.controlElement;
  }

  public destroy(): void {
    this.initialized = false;

    if (this.toggleActiveButton) {
      L.DomEvent.off(
        this.toggleActiveButton,
        'click',
        this.handleToggleActiveClick,
        this
      );
    }

    this.map.off('move', this.handleMapMove, this);
    this.subscriptionManager.disposeSubscriptions();
  }

  public setActive(active: boolean): void {
    this.active = active;
    this.getToggleActiveButton().style.color = active ? '#3a84df' : '';

    if (active) {
      this.centerLocationMarker();
    }
  }

  private handleToggleActiveClick(event: Event): void {
    event.preventDefault();
    this.setActive(!this.active);
  }

  private handleMapMove(event: LeafletEvent | LeafletMouseEvent): void {
    if (this.active && (event as LeafletMouseEvent).originalEvent) {
      // this is a user event (and not fired from e.g. map.paneTo
      this.setActive(false);
    }
  }

  private handleLocationChanged(): void {
    this.updateLocationMarker();

    if (this.active) {
      this.centerLocationMarker();
    }
  }

  private updateLocationMarker(): void {
    const coordinates = CoordinateHelper.getClientCoordinates();
    if (
      coordinates.longitude == null ||
      coordinates.latitude == null ||
      coordinates.accuracy == null
    ) {
      this.removeCurrentLocation();
      return;
    }

    if (!this.locationMarker) {
      this.locationMarker = L.circleMarker([0, 0], {
        stroke: true,
        color: '#fff',
        weight: 2,
        fill: true,
        fillColor: '#3a84df',
        fillOpacity: 0.9,
        radius: 10
      }).addTo(this.map);
    }

    if (!this.accuracyCircle) {
      this.accuracyCircle = L.circle([0, 0], {
        stroke: true,
        color: '#3a84df',
        opacity: 0.8,
        weight: 2,
        fill: true,
        fillColor: '#3a84df',
        fillOpacity: 0.25,
        radius: 10
      }).addTo(this.map);
    }

    this.locationMarker.setLatLng([
      coordinates.latitude,
      coordinates.longitude
    ]);
    this.accuracyCircle.setLatLng([
      coordinates.latitude,
      coordinates.longitude
    ]);
    this.accuracyCircle.setRadius(coordinates.accuracy);
  }

  private removeCurrentLocation(): void {
    this.locationMarker?.remove();
    this.accuracyCircle?.remove();

    this.locationMarker = null;
    this.accuracyCircle = null;
  }

  private centerLocationMarker(): void {
    if (this.locationMarker) {
      this.map.panTo(this.locationMarker.getLatLng());
    }
  }

  private getToggleActiveButton(): HTMLElement {
    assertNotNullOrUndefined(
      this.toggleActiveButton,
      'no toggleActiveButton available, is the LeafletLocationTracker initialized?'
    );
    return this.toggleActiveButton;
  }
}

const LocationTrackerControl = L.Control.extend<{
  onAdd: (map: Map) => HTMLElement;
  onRemove: () => void;
  locationTracker?: LeafletLocationTracker | null;
}>({
  onAdd: function (map: Map): HTMLElement {
    this.locationTracker = new LeafletLocationTracker(
      map,
      (this as any).options.subscriptionManagerService
    );
    this.locationTracker.initialize();
    return this.locationTracker.getControlElement();
  },

  onRemove: function (): void {
    this.locationTracker?.destroy();
    this.locationTracker = null;
  }
});

export function createLocationTracker(
  options: {
    subscriptionManagerService: SubscriptionManagerService;
  } & ControlOptions
): Control {
  return new LocationTrackerControl(options);
}
