import $ from 'jquery';

import { autoinject, bindable } from 'aurelia-framework';

import { DragInProgressInfo } from '../../services/DraggingService';

/**
 * @cssproperty --drop-target--hit-box-extra-size - default 20px. Defines how far the hit box will reach outside of the <drop-target> element. If you are using the <drop-target> in a list, then this should be smaller than half of the list item height or it's possible to drop an item on 2 <drop-targets> at the same time.
 *
 * @slot default
 */
@autoinject()
export class DropTarget<T> {
  @bindable public accepts: (new () => T) | null = null;

  @bindable public onDrop: ((params: { dragData: T }) => void) | null = null;

  @bindable public activateCallback:
    | ((params: { dragData: T }) => boolean)
    | null = null;
  @bindable public deactivateCallback: (() => void) | null = null;

  @bindable public animate = true;

  protected dragInProgress = false;
  protected mouseIsInside = false;
  protected dataIsValid = false;

  private domElement: HTMLElement;
  protected containerElement: HTMLElement | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  private validateData(data: unknown): boolean {
    if (this.accepts) {
      return data instanceof this.accepts;
    }

    return true;
  }

  private handleDragOver(info: DragInProgressInfo): void {
    if (this.animate) {
      this.expandSize(info);
    }
  }

  private handleDragOut(): void {
    if (this.animate) {
      this.reduceSize();
    }
  }

  private expandSize(info: DragInProgressInfo): void {
    $(this.domElement).velocity('stop');

    $.Velocity.animate(
      this.domElement,
      {
        height: info.elementSize.getY() + 'px',
        marginTop: info.elementMargin.top,
        marginBottom: info.elementMargin.bottom
      },
      {}
    );
  }

  private reduceSize(): void {
    $(this.domElement).velocity('stop');

    $.Velocity.animate(
      this.domElement,
      {
        height: '0px',
        marginTop: '0px',
        marginBottom: '0px'
      },
      {}
    );
  }
}
