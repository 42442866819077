import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager)
export class ProcessTaskDisplayNameValueConverter {
  signals = ['thingModelUpdated'];

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  /**
   * @param {import('../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} processTask
   * @returns {string}
   */
  toView(processTask) {
    if (!processTask) {
      return '';
    }

    const parts = [];

    const thing = this._entityManager.thingRepository.getById(
      processTask.thingId
    );
    if (thing?.name) {
      parts.push(thing.name);
    }

    if (processTask.name) {
      parts.push(processTask.name);
    }

    return parts.join(' - ');
  }
}
