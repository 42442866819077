import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditProcessTaskOfferDialog } from '../edit-process-task-offer-dialog/edit-process-task-offer-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskOfferCreationService } from '../../classes/EntityManager/entities/ProcessTaskOffer/ProcessTaskOfferCreationService';

@autoinject()
export class ProcessTaskOffersWidget {
  @bindable public processTask: ProcessTask | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable public enabled = false;

  /**
   * null if offers aren't loaded
   * read only
   */
  @bindable public processTaskOfferCount: number | null = null;

  private subscriptionManager: SubscriptionManager;

  private isAttached = false;
  protected availableProcessTaskOffers: Array<ProcessTaskOffer> = [];
  private offerIdToNavigateTo: string | null = null;

  protected offerCategoryIdGetter: (offer: ProcessTaskOffer) => string | null =
    (o) => o.processConfigurationCategoryId;

  private positionCategories: Array<PositionCategoryGroup> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskOfferCreationService: ProcessTaskOfferCreationService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOffer,
      this.updateAvailableProcessTaskOffers.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateAvailableProcessTaskOffers.bind(this)
    );
    this.updateAvailableProcessTaskOffers();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.updatePositions();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTaskOffers();
      this.updatePositions();
    }
  }

  public navigateToOffer(offerId: string): void {
    if (!this.isAttached) {
      this.offerIdToNavigateTo = offerId;
      return;
    }

    const offer = this.availableProcessTaskOffers.find((a) => a.id === offerId);

    if (!offer) {
      throw new Error(`appointment with id "${offerId}" not found`);
    }

    this.goToOffer(offer);
    this.offerIdToNavigateTo = null;
  }

  private updateAvailableProcessTaskOffers(): void {
    if (this.processTask) {
      const relations =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
          this.processTask.id
        );
      const offerIds = relations.map((r) => r.processTaskOfferId);
      const relatedOffers =
        this.entityManager.processTaskOfferRepository.getByIds(offerIds);

      const offers =
        this.entityManager.processTaskOfferRepository.getByProcessTaskGroupId(
          this.processTask.ownerProcessTaskGroupId
        );
      const offersWithoutRelation = offers.filter((o) => {
        return (
          this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferId(
            o.id
          ).length === 0
        );
      });

      this.availableProcessTaskOffers =
        offersWithoutRelation.concat(relatedOffers);
      this.processTaskOfferCount = this.availableProcessTaskOffers.length;
    } else {
      this.availableProcessTaskOffers = [];
      this.processTaskOfferCount = null;
    }
  }

  private updatePositions(): void {
    const positions = this.processTask
      ? this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
          this.processTask.id
        )
      : [];
    this.updateCategorizedPositions(positions);
  }

  private updateCategorizedPositions(
    positions: Array<ProcessTaskPosition>
  ): void {
    this.positionCategories =
      ProcessTaskPositionUtils.categorizePositions(positions);
  }

  protected getNumberOfPositionsInCategory(
    categoryId: string,
    positionCategories: Array<PositionCategoryGroup>
  ): number {
    const category = positionCategories.find(
      (cat) => cat.categoryId === categoryId
    );
    if (category) {
      return category.positions.length;
    }
    return 0;
  }

  protected async handleAddOfferClick(
    categoryId: string | null
  ): Promise<void> {
    if (!this.processTask) {
      return;
    }

    const offer =
      await this.processTaskOfferCreationService.createForProcessTask({
        processTask: this.processTask,
        categoryId
      });

    this.updateAvailableProcessTaskOffers();
    this.editOffer(offer);
  }

  protected handleEditOfferClicked(offer: ProcessTaskOffer): void {
    this.editOffer(offer);
  }

  private editOffer(offer: ProcessTaskOffer): void {
    void EditProcessTaskOfferDialog.open({
      processTaskOffer: offer,
      onDialogClosed: (closedByNavigation) => {
        if (!closedByNavigation) {
          this.goToOffer(offer);
        }
      }
    });
  }

  private goToOffer(offer: ProcessTaskOffer): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getOfferListItemId(offer.id),
      null,
      offer,
      () => this.isAttached
    );
  }

  protected getOfferListItemId(offerId: string): string {
    return 'process-task-offers-widget--offer' + offerId;
  }
}
