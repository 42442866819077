import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { CustomSelect } from '../custom-select/custom-select';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { User } from '../../classes/EntityManager/entities/User/types';

@autoinject()
export class ProjectSelect {
  @bindable public thingId: string | null = null;

  @bindable public project: Project | null = null;

  @bindable public projectId: string | null = null;

  @bindable public enabled = false;

  @bindable public dataStatus = '';

  @bindable public nameTransformationFunction:
    | ((name: string) => string)
    | null = null;

  @bindable public projectFilterFunction:
    | ((project: Project) => boolean)
    | null = null;

  protected availableProjects: Array<Project> = [];

  private currentUser: User | null = null;
  private editableUserGroups: Array<UserGroup> = [];

  protected projectCustomSelect: CustomSelect<Project, Project> | null = null;

  /**
   * to ignore internal changes (internal and external changes are handled differently)
   */
  private ignoreProjectIdChanged = false;

  private subscriptionManager: SubscriptionManager;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );
    this.updateEditableUserGroups();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateAvailableProjects.bind(this)
    );
    this.updateAvailableProjects();

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.Project,
        this.updateIdBindings.bind(this)
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingIdChanged(): void {
    this.updateAvailableProjects();
  }

  protected projectChanged(newValue: Project, oldValue: Project): void {
    if (newValue == null && oldValue == null) {
      return; // value hasn't actually changed, only from null to undefined
    }

    this.setProjectIdAndIgnoreChange(this.project ? this.project.id : null);
  }

  protected projectIdChanged(): void {
    if (!this.ignoreProjectIdChanged && this.projectId) {
      this.project = this.entityManager.projectRepository.getById(
        this.projectId
      );
    } else {
      this.project = null;
    }
  }

  public focus(): void {
    if (this.projectCustomSelect) this.projectCustomSelect.focus();
  }

  private updateAvailableProjects(): void {
    const currentUser = this.currentUser;
    if (currentUser && this.thingId) {
      this.availableProjects = this.entityManager.projectRepository
        .getByThingId(this.thingId)
        .filter((project) => {
          return (
            PermissionHelper.userCanEditOwnerUserGroupIdEntity(
              project,
              currentUser,
              this.editableUserGroups
            ) &&
            (!this.projectFilterFunction || this.projectFilterFunction(project))
          );
        });
    } else {
      this.availableProjects = [];
    }
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }

    this.updateAvailableProjects();
  }

  private updateIdBindings(): void {
    if (this.project && this.project.id) {
      this.setProjectIdAndIgnoreChange(this.project.id);
    }
  }

  private setProjectIdAndIgnoreChange(projectId: string | null): void {
    this.ignoreProjectIdChanged = true;
    this.projectId = projectId;
    this.ignoreProjectIdChanged = false;
  }
}
