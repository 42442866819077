import { Utils } from '../../classes/Utils/Utils';
import { UiUpdater } from '../../classes/UiUpdater';
import { ZoomBoxResizerHelper } from './ZoomBoxResizerHelper';

/**
 * if you want a zoom-box to have a fixed dimension (e.g. to use all of the available space), and have a picture element inside it,
 * then this class will make the picture as big as possible to fit the zoom-box
 *
 * this class also automatically sets the `no-border` attribute on the picture element
 */
export class ZoomBoxPictureResizer {
  _initialized = false;
  /** @type {HTMLElement} */
  _zoomBoxElement;
  /** @type {HTMLElement} */
  _pictureElement;
  /** @type {import('../../picture/picture/picture').Picture} */
  _pictureViewModel;
  /** @type {Function} */
  _boundHandlePictureLoaded;
  /** @type {Function} */
  _boundHandleResize;
  /** @type {(function(TZoomBoxResizerHelperPicturePositionInfo): void)|null} */
  _afterUpdateCallback = null;

  /**
   * @param {HTMLElement} pictureElement - the html element of the custom picture/Picture
   * @param {HTMLElement} zoomBoxElement - the html element of the zoom-box
   */
  constructor(pictureElement, zoomBoxElement) {
    this._pictureElement = pictureElement;
    this._zoomBoxElement = zoomBoxElement;
    this._pictureViewModel = Utils.getViewModelOfElement(pictureElement);
    this._boundHandlePictureLoaded = this._handlePictureLoaded.bind(this);
    this._boundHandleResize = this._handleResize.bind(this);

    this.init();
  }

  /**
   * adds event listeners and stuff
   * if it is already initialized, it will re-initialize itself
   */
  init() {
    if (this._initialized) {
      this.destroy();
    }

    this._pictureElement.addEventListener(
      'picture-loaded',
      this._boundHandlePictureLoaded
    );
    UiUpdater.registerResizeUpdateFunction(this._boundHandleResize);
    this._initialized = true;
  }

  /**
   * be sure to call this function when the picture resizer is not needed anymore
   */
  destroy() {
    this._pictureElement.removeEventListener(
      'picture-loaded',
      this._boundHandlePictureLoaded
    );
    UiUpdater.unregisterResizeUpdateFunction(this._boundHandleResize);
    this._initialized = false;
  }

  /**
   * gets called everytime there has been an update
   *
   * @param {function(TZoomBoxResizerHelperPicturePositionInfo): void} afterUpdateCallback
   */
  onAfterUpdate(afterUpdateCallback) {
    this._afterUpdateCallback = afterUpdateCallback;
  }

  update() {
    const dimensions = this._pictureViewModel.getNaturalDimensions();
    if (!dimensions) return;

    const info = ZoomBoxResizerHelper.updateStyle(
      this._pictureElement,
      dimensions,
      this._zoomBoxElement
    );
    if (!info) return;

    this._pictureElement.setAttribute('no-border', '');

    this._afterUpdateCallback && this._afterUpdateCallback(info);
  }

  _handlePictureLoaded() {
    this.update();
  }

  _handleResize() {
    this.update();
  }
}

/** @typedef {import('./ZoomBoxResizerHelper').TZoomBoxResizerHelperPicturePositionInfo} TZoomBoxResizerHelperPicturePositionInfo */
