import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { NfcTokenDto } from './NfcTokenDto';

export function createNfcTokenEntityInfo<
  TEntity extends NfcTokenDto<string, string>
>(
  options: CreateEntityInfoOptions<NfcTokenDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.NfcToken,
  TEntity
> {
  return {
    entityName: EntityName.NfcToken,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      NfcTokenDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        tokenId: {
          name: 'tokenId',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
