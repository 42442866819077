import { Disposable } from 'aurelia-binding';
import _ from 'lodash';

import {
  ProjectType,
  colorCodedProjectTypes
} from 'common/Types/Entities/Project/ProjectDto';

import {
  EntityWidgetHandle,
  EntityWidgetHandleSubscribeOptions,
  ProjectTypeInfo
} from './EntityWidgetHandle';
import { ThingActionService } from '../../classes/EntityManager/entities/Thing/ThingActionService';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ThingEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

export class ThingWidgetHandle implements EntityWidgetHandle {
  private entityManager: AppEntityManager;

  private thingActionService: ThingActionService;

  private subscriptionManagerService: SubscriptionManagerService;

  private readonly permissionsService: PermissionsService;

  constructor(
    private readonly thing: Thing,
    options: ThingWidgetHandleOptions
  ) {
    this.entityManager = options.entityManager;
    this.thingActionService = options.thingActionService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.permissionsService = options.permissionsService;
  }

  public subscribe(options: EntityWidgetHandleSubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();
    const permissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Thing,
        entity: this.thing
      });

    subscriptionManager.addDisposable(permissionsHandle.subscribe());

    options.setOnWidgetClicked(
      this.thingActionService.navigateToThing.bind(
        this.thingActionService,
        this.thing
      )
    );
    options.setOnStickyButtonClicked(
      this.thingActionService.togglestickyStatusOnHomepage.bind(
        this.thingActionService,
        this.thing
      )
    );

    permissionsHandle.bindCanEditField(
      'archived',
      (canEditArchived: boolean) => {
        options.setMoreButtonChoices(
          this.getMoreButtonChoices({ canEditArchived })
        );
      }
    );

    options.setMoreButtonChoicesClickHandler(
      this.handleChoiceSelected.bind(this)
    );

    const updateName = (): void => {
      options.setTitle(this.thing.name ?? '');
    };
    updateName();
    subscriptionManager.subscribeToExpression(this.thing, 'name', updateName);

    const updateThingGroupName = (): void => {
      options.setSubTitle(this.getThingGroupName());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      updateThingGroupName
    );
    updateThingGroupName();

    const updateThingTitlePicture = (): void => {
      options.setPicture(this.getThingTitlePicture());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updateThingTitlePicture
    );
    updateThingTitlePicture();

    const updateProjectTypesOfThing = (): void => {
      const projectTypes = this.getProjectTypes();
      const projectInfo: Array<ProjectTypeInfo> = projectTypes.map((t) => {
        return { type: t, info: ' ' };
      });

      options.setProjectTypeInfo(projectInfo);
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      updateProjectTypesOfThing
    );
    updateProjectTypesOfThing();

    const updateMetadata = (): void => {
      options.setEntityDashboardInfo(this.getEntityDashboardInfo());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateMetadata
    );
    updateMetadata();

    return subscriptionManager.toDisposable();
  }

  public getEntityId(): string {
    return this.thing.id;
  }

  private getEntityDashboardInfo(): ThingEntityDashboardInfo | null {
    const metadata =
      this.entityManager.entityDashboardInfoRepository.getByThingId(
        this.thing.id
      );
    return metadata[0] ?? null;
  }

  private getThingGroupName(): string {
    const thingGroupId = this.thing?.thingGroupId;
    if (!thingGroupId) return '';

    return (
      this.entityManager.thingGroupRepository.getById(thingGroupId)?.name ?? ''
    );
  }

  private getThingTitlePicture(): Picture | null {
    return (
      (this.entityManager.pictureRepository.getSelectedTitleThingPicture(
        this.thing.id
      ) as Picture) ?? null
    );
  }

  private getProjectTypes(): Array<ProjectType> {
    const projects =
      this.entityManager.projectRepository.getByThingIdWithoutOperationsProjects(
        this.thing.id
      );
    const projectTypes = _.uniq(projects.map((p) => p.projectType))
      .sort((pT1, pT2) => pT1.localeCompare(pT2))
      .filter((type) => colorCodedProjectTypes().includes(type));

    return projectTypes;
  }

  private getMoreButtonChoices({
    canEditArchived
  }: {
    canEditArchived: boolean;
  }): Array<MoreButtonChoice> {
    return [
      {
        name: MoreButtonActionName.EDIT,
        labelTk: 'homePageComponents.entityWidget.thingEntityWidget.edit',
        iconClass: 'fal fa-edit'
      },
      {
        name: MoreButtonActionName.PARAMETERS,
        labelTk: 'homePageComponents.entityWidget.thingEntityWidget.parameters',
        iconClass: 'fal fa-cog'
      },
      {
        name: MoreButtonActionName.HIDE,
        labelTk: 'homePageComponents.entityWidget.hide',
        iconClass: 'fal fa-eye-slash'
      },
      {
        name: MoreButtonActionName.ARCHIVE,
        labelTk: 'homePageComponents.entityWidget.archive',
        iconClass: 'fal fa-archive',
        disabled: !canEditArchived
      }
    ];
  }

  private handleChoiceSelected(name: string): void {
    switch (name) {
      case MoreButtonActionName.EDIT:
        this.thingActionService.editThing(this.thing);
        break;
      case MoreButtonActionName.PARAMETERS:
        this.thingActionService.navigateToThing(this.thing, {
          open_parameter_panel: true
        });
        break;
      case MoreButtonActionName.ARCHIVE:
        this.thingActionService.toggleArchivedStatusOfThing(this.thing);
        break;
      case MoreButtonActionName.HIDE:
        this.thingActionService.toggleHideStatusOnHomepage(this.thing);
        break;
      default:
        throw new Error(`unhandled choice "${name}"`);
    }
  }
}

type ThingWidgetHandleOptions = {
  entityManager: AppEntityManager;
  thingActionService: ThingActionService;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
};

enum MoreButtonActionName {
  EDIT = 'edit',
  PARAMETERS = 'parameters',
  HIDE = 'hide',
  ARCHIVE = 'archive'
}
