import { bindable } from 'aurelia-framework';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';

import {
  PictureScroller,
  PictureSelectedEvent
} from '../picture-scroller/picture-scroller';

/**
 * @attribute data-no-container-spacing - disable the space between the <picture-scroller> and the first picture
 */
export class SelectedPicturePictureScroller {
  @bindable()
  public pictures: Array<Picture> = [];

  @bindable()
  public selectedPicture: Picture | null = null;

  @bindable()
  public dataNoContainerSpacing: any = undefined;

  protected pictureScroller: PictureScroller | null = null;

  public scrollToPicture(picture: Picture): void {
    this.pictureScroller?.scrollToPicture(picture);
  }

  protected handlePictureSelected(event: PictureSelectedEvent): void {
    this.selectedPicture = event.detail.picture;
    this.scrollToPicture(event.detail.picture);
  }

  protected selectedPictureChanged(): void {
    if (!this.selectedPicture) return;
    this.scrollToPicture(this.selectedPicture);
  }

  protected handleClickOnPicture(picture: Picture): void {
    this.selectedPicture = picture;
    this.scrollToPicture(picture);
  }
}
