import { SocketCommandEmitter } from './SocketCommandEmitter';
import {
  EndpointParameter,
  EndpointResult
} from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';

export class MoveDefectModuleEndpoints extends SocketCommandEmitter {
  public async moveToExistingThing_v1(
    request: EndpointParameter<'moveDefectModule', 'moveToExistingThing_v1'>
  ): Promise<EndpointResult<'moveDefectModule', 'moveToExistingThing_v1'>> {
    return this.emitCommand(
      'moveDefectModule',
      'moveToExistingThing_v1',
      request
    );
  }

  public async moveToNewThing_v1(
    request: EndpointParameter<'moveDefectModule', 'moveToNewThing_v1'>
  ): Promise<EndpointResult<'moveDefectModule', 'moveToNewThing_v1'>> {
    return this.emitCommand('moveDefectModule', 'moveToNewThing_v1', request);
  }
}
