import EXIF from 'exif-js';
import moment from 'moment';

import { IPictureCoords } from 'common/Types/Entities/Picture/PictureDto';

export class ExifDataHelper {
  public static async getCoordinatesFromExifData(
    file: File
  ): Promise<IPictureCoords | null> {
    return new Promise((res) => {
      EXIF.getData(file as any, function () {
        // @ts-ignore - EXIF package writes data into 'this'
        const exifData = this;

        const latitude = EXIF.getTag(exifData, 'GPSLatitude');
        const longitude = EXIF.getTag(exifData, 'GPSLongitude');

        if (!latitude || !longitude) {
          res(null);
          return;
        }

        res({
          latitude: latitude[0] + latitude[1] / 60 + latitude[2] / 3600,
          longitude: longitude[0] + longitude[1] / 60 + longitude[2] / 3600
        });
      });
    });
  }

  public static async getCreationDateFromExifData(
    file: File
  ): Promise<string | null> {
    return new Promise((res) => {
      EXIF.getData(file as any, function () {
        // @ts-ignore - EXIF package writes data into 'this'
        const exifData = this;

        const creationDateStr = EXIF.getTag(exifData, 'DateTimeOriginal');

        if (!creationDateStr) {
          res(null);
          return;
        }

        res(moment(creationDateStr, 'YYYY:MM:DD HH:mm:ss').toISOString());
      });
    });
  }
}
