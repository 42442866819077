import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Question } from './types';

export class QuestionRepository extends AppEntityRepository<EntityName.Question> {
  public getByQuestionSetIds(questionSetIds: Array<string>): Array<Question> {
    return this.getAll().filter((entity) =>
      questionSetIds.includes(entity.questionSetId)
    );
  }

  public getByQuestionSetId(questionSetId: string): Array<Question> {
    return this.getByQuestionSetIds([questionSetId]);
  }

  public getByQuestionCategoryId(questionCategoryId: string): Array<Question> {
    return this.getAll().filter(
      (entity) => entity.questionCategoryId === questionCategoryId
    );
  }
}
