import { DefectManagementFilter } from '../Types/DefectManagementFilter';
import { GalleryThingPictureFilter } from '../Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

export enum ExportType {
  DOCX = 'docx',
  PDF = 'pdf'
}

export interface IExportMultipleProjectsRequest {
  exportRequests: Array<IExportProjectRequest>;
}

export interface IExportMultipleProjectsResponse {
  progressSocketChannelName: string; // name of the socket channel where the progress will be reported to
}

export interface IExportMultipleProjectsProgressResponse {
  finishedProjectsCount: number;
  failedProjectsCount: number;
  exportedProjectsCount: number;
  projectCount: number;
}

export interface IExportProjectRequest {
  projectId: string;
  timestampMs: number;
  exportType: ExportType;
}

export type TExportProjectResponse =
  | IExportProjectSuccessResponse
  | IExportProjectErrorResponse;

interface IExportProjectSuccessResponse {
  success: true;
  reportId: string;
}

interface IExportProjectErrorResponse {
  success: false;
  status: string;
  error?: Error;
}

export interface IExportGalleryThingRequest {
  thingId: string;
  pictureFilter: GalleryThingPictureFilter;
  exportType: ExportType;
}

export interface IExportGalleryThingResponse {
  success: boolean;
  reportId?: string;
}

export type ExportDefectsRequest = {
  reportTypeId: string;
  thingId: string;
  filter: DefectManagementFilter;
  defectIds: Array<string>;
  exportType: ExportType;
};

type ExportDefectsResponseError = {
  success: false;
};

type ExportDefectsResponseSuccess = {
  success: true;
  reportId: string;
};

export type ExportDefectsResponse =
  | ExportDefectsResponseError
  | ExportDefectsResponseSuccess;
