import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

import { Property } from './types';

export class GroupedPropertyHelper {
  public static groupProperties<T extends Property>(
    properties: Array<T>
  ): GroupedProperties<T> {
    const groupedProperties: GroupedProperties<T> = [];

    let arrayToWrite = groupedProperties;
    for (const property of properties) {
      if (property.type !== PropertyType.HEADING) {
        arrayToWrite.push(property);
      } else {
        if (arrayToWrite !== groupedProperties) {
          arrayToWrite = groupedProperties;
        }
        arrayToWrite.push(property);
        (property as GroupedProperty<T>).children = arrayToWrite = [];
      }
    }
    return groupedProperties;
  }
}

export type GroupedProperty<T extends Property> = T & { children: Array<T> };
export type GroupedProperties<T extends Property> = Array<
  GroupedProperty<T> | T
>;
