import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createNfcTokenToPersonEntityInfo } from 'common/Types/Entities/NfcTokenToPerson/createNfcTokenToPersonEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { NfcTokenToPerson } from './types';

export const nfcTokenToPersonEntityInfo =
  createNfcTokenToPersonEntityInfo<NfcTokenToPerson>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
