import { autoinject } from 'aurelia-dependency-injection';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';

@autoinject()
export class PictureRevisionComparisonFullScreenDialog {
  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected dialog: RecordItFullScreenDialog | null = null;

  protected options: Options | null = null;

  protected isRendered = false;

  public open(options: Options): void {
    this.options = options;
    this.getDialog().open();
    this.isRendered = true;
  }

  public close(): void {
    this.getDialog().close();
  }

  protected handleDialogClosed(): void {
    this.isRendered = false;
  }

  private getDialog(): RecordItFullScreenDialog {
    assertNotNullOrUndefined(this.dialog, 'no dialog available');
    return this.dialog;
  }
}

export type Options = {
  picture: Picture;
  revisionLeft: PictureRevision;
  revisionRight: PictureRevision;
};
