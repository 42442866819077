import { Disposable } from '../Utils/DisposableContainer';

export abstract class AbstractNfcHelperStrategy {
  public isEnabled(): Promise<boolean> {
    return new Promise((res, _rej) => {
      if (!window.nfc) {
        res(false);
        return;
      }

      window.nfc.enabled(
        () => {
          res(true);
        },
        () => {
          res(false);
        }
      );
    });
  }

  public abstract scanSingleUID(callback: UidScanCallback): Disposable;
}

export interface UidScanCallback {
  (err: UserAbortedScanError, result: null): boolean;
  (err: null, result: string): boolean;
}

/**
 * Thrown if user aborts the NFC scan manually.
 */
export class UserAbortedScanError extends Error {}
