import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { ProjectQuestionCategoryDto } from './ProjectQuestionCategoryDto';

export function createProjectQuestionCategoryEntityInfo<
  TEntity extends ProjectQuestionCategoryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProjectQuestionCategoryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProjectQuestionCategory,
  TEntity
> {
  return {
    entityName: EntityName.ProjectQuestionCategory,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProjectQuestionCategoryDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: ''
        },
        createdFromQuestionCategoryId: {
          name: 'createdFromQuestionCategoryId',
          ref: EntityName.QuestionCategory
        }
      },
      options.additionalFieldInfos
    )
  };
}
