import { autoinject } from 'aurelia-dependency-injection';
import { ProcessTaskAppointmentToUserHelper } from 'common/EntityHelper/ProcessTaskAppointmentToUserHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ComputedValueService } from '../ComputedValueService';
import {
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentId,
  ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer
} from './ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer';
import { ValueComputer } from './ValueComputer';

@autoinject()
export class ProcessTaskAppointmentDateInfoMapComputer extends ValueComputer<
  ComputeData,
  ProcessTaskAppointmentDateInfoMap
> {
  private readonly subscriptionManager: SubscriptionManager;

  /**
   *
   * @param relevantRelations - relations of a single appointment
   */
  public static createDateInfo(
    relevantRelations: Array<ProcessTaskAppointmentToUser>
  ): ProcessTaskAppointmentDateInfo {
    const { dateFrom, dateTo } = ProcessTaskAppointmentToUserHelper.getTimespan(
      relevantRelations,
      (d) => d
    );

    return {
      dateFrom,
      dateTo
    };
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      invokeCompute
    );
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass:
          ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        computeData: {},
        callback: invokeCompute,
        skipInitialCall: true
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskAppointmentDateInfoMap {
    const appointments =
      this.entityManager.processTaskAppointmentRepository.getAll();
    const relationInfos: ProcessTaskAppointmentToUsersByProcessTaskAppointmentId =
      this.computedValueService.getCurrentValue(
        ProcessTaskAppointmentToUsersByProcessTaskAppointmentIdComputer,
        {}
      ) ?? new Map();
    const map: ProcessTaskAppointmentDateInfoMap = new Map();

    for (const appointment of appointments) {
      const relations = relationInfos.get(appointment.id) ?? [];
      map.set(
        appointment.id,
        ProcessTaskAppointmentDateInfoMapComputer.createDateInfo(relations)
      );
    }

    return map;
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export type ComputeData = {};
export type ProcessTaskAppointmentDateInfoMap = Map<
  string,
  ProcessTaskAppointmentDateInfo
>;
export type ProcessTaskAppointmentDateInfo = {
  dateFrom: string | null;
  dateTo: string | null;
};
