import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { ProcessConfigurationForm } from './types';

export class ProcessConfigurationFormUtils {
  public static formSortOptions: ProcessConfigurationFormSortOptions = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';
        return aName.localeCompare(bName);
      }
    }
  };
}

export type ProcessConfigurationFormSortOptions = {
  name: ProcessConfigurationFormSortOption;
};

export type ProcessConfigurationFormSortOption =
  SorterSortOption<ProcessConfigurationForm>;
