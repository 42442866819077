import { assertNotNullOrUndefined } from 'common/Asserts';

import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { RelationInfo } from '../../../../inputComponents/entity-nfc-tag-widget/entity-nfc-tag-widget';
import { CoordinateHelper } from '../../../CoordinateHelper';
import { NfcTokenToThing } from '../NfcTokenToThing/types';
import { EntityName } from '../types';
import { Thing } from './types';

export class ThingUtils {
  private constructor() {}

  public static sortOptions: ThingSortOptions = {
    name: {
      name: 'Name',
      sortFunction: function (a, b) {
        const aName = a.name ? a.name : '';
        const bName = b.name ? b.name : '';

        return aName.localeCompare(bName);
      }
    },
    created: {
      name: 'Erstelldatum',
      sortFunction: function (a, b) {
        return a.created - b.created;
      }
    },
    updatedAt: {
      name: 'Geändert',
      sortFunction: function (a, b) {
        const aUpdatedAt = a.updatedAt
          ? a.updatedAt
          : new Date(a.created).toISOString();
        const bUpdatedAt = b.updatedAt
          ? b.updatedAt
          : new Date(b.created).toISOString();
        return aUpdatedAt.localeCompare(bUpdatedAt);
      }
    },
    distance: {
      name: 'Entfernung',
      sortFunction: function (a, b) {
        // setting a really high default value to treat things without a position as far away

        const aDistance = ThingUtils.calculateDistanceFromThing(a);
        const aDistanceWithFallback =
          aDistance !== null ? aDistance : Number.MAX_SAFE_INTEGER;
        const bDistance = ThingUtils.calculateDistanceFromThing(b);
        const bDistanceWithFallback =
          bDistance !== null ? bDistance : Number.MAX_SAFE_INTEGER;

        return aDistanceWithFallback - bDistanceWithFallback;
      }
    }
  };

  public static nfcTagRelationInfo: RelationInfo<NfcTokenToThing, Thing> = {
    entityName: EntityName.Thing,
    relationEntityName: EntityName.NfcTokenToThing,
    createRelation: ({ tokenId, entityManager, entity }) => {
      assertNotNullOrUndefined(
        entity,
        'cannot create relation without a thing'
      );
      entityManager.nfcTokenToThingRepository.create({
        ownerUserGroupId: entity.ownerUserGroupId,
        nfcTokenId: tokenId,
        thingId: entity.id
      });
    },
    deleteRelation: ({ entityManager, entity }) => {
      assertNotNullOrUndefined(
        entity,
        'cannot delete relation without a thing'
      );
      entityManager.nfcTokenToThingRepository.deleteByThingId(entity.id);
    },
    getRelationForTokenId: ({ tokenId, entityManager, entity }) => {
      if (!entity) return null;

      return (
        entityManager.nfcTokenToThingRepository
          .getByThingId(entity.id)
          .find((r) => r.nfcTokenId === tokenId) ?? null
      );
    },
    getRelationForEntity: ({ entityManager, entity }) => {
      if (!entity) return null;

      return (
        entityManager.nfcTokenToThingRepository.getByThingId(entity.id)[0] ??
        null
      );
    },
    getTokenIdFromRelation: (relation) => {
      return relation.nfcTokenId;
    }
  };

  private static calculateDistanceFromThing(thing: Thing): number | null {
    if (!this.canCalculateDistanceFromThing(thing)) {
      return null;
    }

    const clientCoordinates = CoordinateHelper.getClientCoordinates();
    return CoordinateHelper.calculateDistance(
      // @ts-ignore - values can't be null here anymore
      clientCoordinates.longitude,
      clientCoordinates.latitude,
      thing.longitude,
      thing.latitude
    );
  }

  private static canCalculateDistanceFromThing(thing: Thing): boolean {
    const coordinates = CoordinateHelper.getClientCoordinates();

    return (
      thing.latitude != null &&
      thing.longitude != null &&
      !coordinates.latitude != null &&
      coordinates.longitude != null
    );
  }
}

export type ThingSortOptions = {
  name: ThingSorterOption;
  created: ThingSorterOption;
  updatedAt: ThingSorterOption;
  distance: ThingSorterOption;
};

export type ThingSorterOption = SorterSortOption<Thing>;
