import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { OperationsCalendarSettingsService } from '../../services/OperationsCalendarSettingsService/OperationsCalendarSettingsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class OperationsCalendarUserLegend {
  private readonly subscriptionManager: SubscriptionManager;
  private groups: Array<UserColorGroup> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly operationsCalendarSettingsService: OperationsCalendarSettingsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateUsers.bind(this)
    );
    this.updateUsers();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateUsers(): void {
    const users = this.entityManager.userRepository.getAll().sort((a, b) => {
      const aName = a.username ? a.username : '';
      const bName = b.username ? b.username : '';
      return aName.localeCompare(bName);
    });

    this.groups = this.groupUsers(users);
  }

  private groupUsers(sortedUsers: Array<User>): Array<UserColorGroup> {
    const groups: Array<UserColorGroup> = [];

    for (const user of sortedUsers) {
      const color = user.secondaryColor || '';

      const existingGroup = groups.find((group) => group.color === color);
      if (existingGroup) {
        existingGroup.users.push(user);
      } else {
        groups.push({
          color,
          users: [user]
        });
      }
    }

    return groups;
  }
}

type UserColorGroup = {
  color: string;
  users: Array<User>;
};
