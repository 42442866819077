import { autoinject } from 'aurelia-framework';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessTaskPositionEditWidget } from '../process-task-position-edit-widget/process-task-position-edit-widget';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';

@autoinject()
export class EditProcessTaskPositionDialog {
  private position: ProcessTaskPosition | null = null;
  private onDialogClosed: (() => void) | null = null;

  protected dialog: RecordItDialog | null = null;
  protected positionEditWidgetViewModel: ProcessTaskPositionEditWidget | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService
  ) {
    this.entityManager = entityManager;
    this.processTaskLoggingService = processTaskLoggingService;
  }

  public open(options: EditProcessTaskPositionDialogOpenOptions): void {
    assertNotNullOrUndefined(this.dialog, "can't open without a dialog");

    this.position = options.position;
    this.onDialogClosed = options.onDialogClosed ?? null;

    this.dialog.open();
  }

  protected handleDialogOpened(): void {
    this.positionEditWidgetViewModel?.focusInput();
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;

    this.position = null;
    this.onDialogClosed = null;

    onClosed && onClosed();
  }

  protected handleProcessTaskPositionChanged(property?: string): void {
    if (this.position) {
      this.entityManager.processTaskPositionRepository.update(this.position);
      this.processTaskLoggingService.logProcessTaskSubEntityModified({
        entityName: EntityName.ProcessTaskPosition,
        entity: this.position,
        property: property ?? null,
        displayNameAtLogTime: this.position.name
      });
    }
  }

  public static async open(
    options: EditProcessTaskPositionDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

export type EditProcessTaskPositionDialogOpenOptions = {
  position: ProcessTaskPosition;
  onDialogClosed?: (() => void) | null;
};
