import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskPositionEntityInfo } from 'common/Types/Entities/ProcessTaskPosition/createProcessTaskPositionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskPosition } from './types';

export const processTaskPositionEntityInfo =
  createProcessTaskPositionEntityInfo<ProcessTaskPosition>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
