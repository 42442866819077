import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { UserRole } from '../../classes/EntityManager/entities/UserRole/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class UserGroupUserRolesWidget {
  @bindable()
  public userGroup: UserGroup | null = null;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        context: this as UserGroupUserRolesWidget,
        entityName: EntityName.UserGroup,
        propertyName: 'userGroup'
      }
    );
  }

  @computed(expression('userGroup.id'), model(EntityName.UserRole))
  protected get userRoles(): Array<UserRole> {
    if (!this.userGroup) {
      return [];
    }

    return this.entityManager.userRoleRepository.getByUserGroupId(
      this.userGroup.id
    );
  }

  protected handleAddUserRoleClick(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't UserGroupUserRolesWidget.handleAddUserRoleClick without userGroup"
    );

    this.entityManager.userRoleRepository.create({
      ownerUserGroupId: this.userGroup.id
    });
  }
}
