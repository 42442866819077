import { Disposable } from 'aurelia-binding';
import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { ArrayMapCache } from '../../classes/ArrayMapCache/ArrayMapCache';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { ThingActionService } from '../../classes/EntityManager/entities/Thing/ThingActionService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityWidgetHandleUtils } from '../EntityWidgetHandle/EntityWidgetHandleUtils';
import { ThingWidgetHandle } from '../EntityWidgetHandle/ThingWidgetHandle';
import {
  EntityWidgetAdapter,
  EntityWidgetAdapterOptions,
  EntityWidgetAdapterSubscribeOptions
} from './EntityWidgetAdapter';

export class ThingWidgetAdapter implements EntityWidgetAdapter {
  private entityManager: AppEntityManager;

  private subscriptionManagerService: SubscriptionManagerService;

  private activeUserCompanySettingService: ActiveUserCompanySettingService;

  private thingActionService: ThingActionService;

  private handleCache: ArrayMapCache<Thing, ThingWidgetHandle>;

  constructor(private readonly options: ThingWidgetAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.thingActionService = options.thingActionService;

    this.handleCache = new ArrayMapCache({
      createMappedItem: ({ item }) =>
        new ThingWidgetHandle(item, {
          entityManager: this.entityManager,
          subscriptionManagerService: this.subscriptionManagerService,
          thingActionService: this.thingActionService,
          permissionsService: options.permissionsService
        })
    });
  }

  public subscribe(options: EntityWidgetAdapterSubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();
    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'homePage.initialNumberOfThingsShown',
        (initialNumberOfThingsShown: number | null) => {
          options.setEntityMinNumberToShow(initialNumberOfThingsShown);
        }
      )
    );

    subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'homePage.totalNumberOfThingsShown',
        (totalNumberOfThingsShown: number | null) => {
          options.setEntityMaxNumberToShow(totalNumberOfThingsShown);
        }
      )
    );

    const updateThings = (): void => {
      const handles = this.handleCache.mapItems({ items: this.getThings() });
      const sortedHandles = this.applyDashboardInfoToHandles(handles);
      options.setEntityHandles(sortedHandles);
    };

    options.setTitleTk(
      'homePageComponents.entityWidgetContainer.lastOpenendThings'
    );

    options.setEntityOverviewButtonConfig({
      textTk: 'aureliaComponents.mainNavigation.generalMainMenu.things',
      routeName: 'edit_objects'
    });

    subscriptionManager.subscribeToModelChanges(EntityName.Thing, updateThings);
    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateThings
    );
    updateThings();

    return subscriptionManager.toDisposable();
  }

  private applyDashboardInfoToHandles(
    handles: Array<ThingWidgetHandle>
  ): Array<ThingWidgetHandle> {
    const thingDashboardInfo = BaseEntityUtils.sortByUpdatedAt(
      this.entityManager.entityDashboardInfoRepository
        .getAll()
        .filter((x): x is ThingEntityDashboardInfo => !!x.thingId)
    );

    const dashboardInfoMap = new Map(
      thingDashboardInfo.map((x) => [x.thingId, x])
    );
    const handlesWithoutHidden = EntityWidgetHandleUtils.removeHiddenHandles(
      handles,
      dashboardInfoMap
    );
    return handlesWithoutHidden.sort((a, b) =>
      EntityWidgetHandleUtils.compareHandles(a, b, dashboardInfoMap)
    );
  }

  private getThings(): Array<Thing> {
    return BaseEntityUtils.sortByUpdatedAt(
      this.entityManager.thingRepository.getAll().filter((t) => !t.archived)
    );
  }
}

export type ThingWidgetAdapterOptions = EntityWidgetAdapterOptions & {
  thingActionService: ThingActionService;
  permissionsService: PermissionsService;
};
