import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import { BaseEntityDto } from './BaseEntityUtils';
import { EntityName } from './EntityName';

export class DtoUtils {
  private constructor() {}

  public static getParentEntityInfo<TDto extends BaseEntityDto>({
    dto,
    parentEntityIdFieldNames,
    entityInfo
  }: {
    dto: TDto;
    parentEntityIdFieldNames: Array<Extract<Exclude<keyof TDto, 'id'>, string>>;
    entityInfo: EntityInfo<AppCommonSynchronizationEnvironmentTypes, any, TDto>;
  }): ParentEntityInfo {
    for (const parentEntityIdFieldName of parentEntityIdFieldNames) {
      const id = dto[parentEntityIdFieldName];

      if (typeof id !== 'string') {
        continue;
      }

      const fieldInfo = entityInfo.fieldInfos[parentEntityIdFieldName];

      if (!fieldInfo) {
        throw new Error(`no field info found for "${parentEntityIdFieldName}"`);
      }

      if (!fieldInfo.ref) {
        throw new Error(
          `the field "${parentEntityIdFieldName}" does not reference an entity`
        );
      }

      return {
        id,
        entityName: fieldInfo.ref
      };
    }

    throw new Error(`no parentEntity found: ${JSON.stringify(dto)}`);
  }
}

export type ParentEntityInfo = {
  id: string;
  entityName: EntityName;
};
