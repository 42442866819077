import { bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../inputComponents/clickable-text-input/clickable-text-input';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyMultilineTextWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyMultilineTextWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  protected clickableTextInput: ClickableTextInput | null = null;

  public focus(): void {
    assertNotNullOrUndefined(
      this.clickableTextInput,
      "can't BasePropertyMultilineTextWidget.focus without clickableTextInput"
    );
    this.clickableTextInput.focus();
  }

  protected handleTextChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyMultilineTextWidget.handleTextChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: event.detail.value as string | null,
      customChoice: null
    });
  }
}

export const basePropertyMultilineTextWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
