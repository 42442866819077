import { inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';

@inject(Element, SubscriptionManagerService)
export class IsFocusedCustomAttribute {
  /**
   * @param {HTMLElement} element
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(element, subscriptionManagerService) {
    this._domElement = element;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToDomEvent(
      this._domElement,
      'focus',
      this._handleFocus.bind(this)
    );
    this._subscriptionManager.subscribeToDomEvent(
      this._domElement,
      'blur',
      this._handleBlur.bind(this)
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _handleFocus() {
    this.value = true;
  }

  _handleBlur() {
    this.value = false;
  }
}
