import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskDeviceEntityInfo } from 'common/Types/Entities/ProcessTaskDevice/createProcessTaskDeviceEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskDevice } from './types';

export const processTaskDeviceEntityInfo =
  createProcessTaskDeviceEntityInfo<ProcessTaskDevice>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
