import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserSubEntityUtils } from '../../BaseEntities/UserSubEntityUtils';
import { EntityDashboardInfoDto } from './EntityDashboardInfoDto';

export function createEntityDashboardInfoEntityInfo<
  TEntity extends EntityDashboardInfoDto<string, string>
>(
  options: CreateEntityInfoOptions<
    EntityDashboardInfoDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.EntityDashboardInfo,
  TEntity
> {
  return {
    entityName: EntityName.EntityDashboardInfo,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      EntityDashboardInfoDto<string, string>,
      TEntity
    >(
      {
        ...UserSubEntityUtils.createBaseFieldInfos(),
        hidden: {
          name: 'hidden',
          defaultValue: false
        },
        sticky: {
          name: 'sticky',
          defaultValue: false
        },
        lastOpenedAt: {
          name: 'lastOpenedAt',
          defaultValue: null
        },
        defectId: {
          name: 'defectId',
          ref: EntityName.Defect,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        projectId: {
          name: 'projectId',
          ref: EntityName.Project,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          defaultValue: null,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
