import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskMeasurePointReading } from './types';

export class ProcessTaskMeasurePointReadingRepository extends AppEntityRepository<EntityName.ProcessTaskMeasurePointReading> {
  public getByProcessTaskAppointmentId(
    appointmentId: string
  ): Array<ProcessTaskMeasurePointReading> {
    return this.getAll().filter(
      (o) => o.processTaskAppointmentId === appointmentId
    );
  }

  public getLastReading(
    processTaskMeasurePointId: string,
    date: string
  ): ProcessTaskMeasurePointReading | null {
    const otherReadings = this.getByProcessTaskMeasurePointId(
      processTaskMeasurePointId
    );

    return otherReadings.reduce<ProcessTaskMeasurePointReading | null>(
      (lastReading, reading) => {
        if (
          (!lastReading || reading.date > lastReading.date) &&
          reading.date < date
        ) {
          return reading;
        }

        return lastReading;
      },
      null
    );
  }

  public getByProcessTaskMeasurePointId(
    measurePointId: string
  ): Array<ProcessTaskMeasurePointReading> {
    return this.getAll().filter(
      (o) => o.processTaskMeasurePointId === measurePointId
    );
  }

  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskMeasurePointReading> {
    return this.getAll().filter((o) => o.ownerProcessTaskId === processTaskId);
  }
}
