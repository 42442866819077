import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import {
  ShowHideAnimator,
  ShowHideAnimatorColor
} from '../../classes/Animation/ShowHideAnimator';
import { FullScreenContent } from '../full-screen-content/full-screen-content';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomElementIdGenerator } from '../../classes/DomUtilities/DomElementIdGenerator';
import { UrlParameterService } from '../../classes/UrlParameterService';

/**
 *
 * a simple overlay with a backdrop
 * @slot 'content'
 *
 * @event full-screen-overlay-opened - is fired when the overlay is shown/visible (and the show animation has been completed)
 * @event full-screen-overlay-closed - is fired when the overlay gets closed (by the user or programmatically) (and the hide animation has been completed)
 * @event full-screen-overlay-closing - is fired when the overlay starts closing (by the user or programmatically)
 * @event full-screen-overlay-backdrop-clicked - is fired when the user clicks the backdrop (regardless of doNotCloseOnBackdropClick)
 *
 * providing/recreation the close button:
 *  if you need your own implementation of the button bar, or if the standard one isn't supported,
 *  you can recreate it with the following code in the 'content' slot:
 *    <div class="full-screen-overlay--ButtonBar">
 *      <i class="record-it-icon far fa-times full-screen-overlay--CloseButton"></i>
 *    </div>
 */
@autoinject()
export class FullScreenOverlay {
  @bindable()
  public showCloseIcon: boolean = true;

  @bindable()
  public doNotCloseOnBackdropClick: boolean = false;

  /**
   * since the dom gets detached and moved into the root, you can pass a testSelector here to find the root full-screen-overlay element
   * via a data-test-selector="${testSelector}" attribute
   *
   * @type {(string|null)}
   */
  @bindable()
  public testSelector: string | null = null;

  @bindable()
  public fillWidth: boolean = true;

  protected fullscreenContent: FullScreenContent | null = null;
  protected fullscreenContentElement: HTMLElement | null = null;
  protected fullscreenContentWrapper: HTMLElement | null = null;
  protected backdropElement: HTMLElement | null = null;

  private opened: boolean = false;
  private animator: ShowHideAnimator | null = null;
  private backdropAnimator: ShowHideAnimator | null = null;
  private backdropColor: ShowHideAnimatorColor = {
    // if you change the color here, you also need to change it in the css
    red: 74,
    green: 74,
    blue: 74,
    alpha: 0.53
  };

  /**
   * this is a workaround for the browser behavior, without this starting a mousedown in the content,
   * and releasing it on the backdrop, the overlay would automatically close
   * which is unhandy when you have some drag and drop functionality in the overlay and the user overshoots the area
   */
  private mouseDownStartedOnBackdrop: boolean = false;

  private subscriptionManager: SubscriptionManager;

  private domElementId = DomElementIdGenerator.getNextId();

  constructor(
    private readonly element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly urlParameterService: UrlParameterService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(): void {
    assertNotNullOrUndefined(
      this.fullscreenContent,
      "can't open without the fullscreenContent"
    );
    assertNotNullOrUndefined(
      this.backdropElement,
      "can't open without the backdropElement"
    );

    this.opened = true;
    this.fullscreenContent.open();
    SiteScrollLocker.lockScrolling('full-screen-overlay');

    this.subscriptionManager.addDisposable(
      this.urlParameterService.registerUrlParameterRemovedListener(
        this.domElementId,
        () => {
          if (this.doNotCloseOnBackdropClick) return;
          this.close();
        }
      )
    );

    this.urlParameterService.overlayOpened(this.domElementId);

    this.updateAnimator();

    if (!this.backdropAnimator) {
      this.backdropAnimator = new ShowHideAnimator(this.backdropElement);
    }

    if (this.animator) {
      this.animator.clearAnimations();
      this.backdropAnimator.clearAnimations();
      void this.animator
        .scaleFadeIn()
        .then(this.handleOpenAnimationFinished.bind(this));
      void this.backdropAnimator.animateBackgroundColor(
        this.createTransparentBackdropColor(),
        this.backdropColor
      );
    } else {
      this.handleOpenAnimationFinished();
      this.backdropElement.style.backgroundColor = ''; // let css decide the background color
    }
  }

  public close(): void {
    if (!this.opened) return;

    this.opened = false;

    DomEventHelper.fireEvent(this.element, {
      name: 'full-screen-overlay-closing',
      detail: null
    });

    if (this.animator) {
      this.animator.clearAnimations();
      void this.backdropAnimator?.clearAnimations();
      void this.animator
        .scaleFadeOut()
        .then(this.handleCloseAnimationFinished.bind(this));
      void this.backdropAnimator?.animateBackgroundColor(
        this.backdropColor,
        this.createTransparentBackdropColor()
      );
    } else {
      this.handleCloseAnimationFinished();
    }
  }

  public closeWithoutAnimation(): void {
    if (this.opened) {
      this.opened = false;
      this.handleCloseAnimationFinished();
    }
  }

  public isOpen(): boolean {
    return this.opened;
  }

  protected detached(): void {
    this.reset();
  }

  private handleCloseAnimationFinished(): void {
    this.reset();
    this.urlParameterService.overlayClosed(this.domElementId);

    DomEventHelper.fireEvent(this.element, {
      name: 'full-screen-overlay-closed',
      detail: null
    });
  }

  private reset(): void {
    assertNotNullOrUndefined(
      this.fullscreenContent,
      "can't _handleCloseAnimationFinished without the fullscreenContent"
    );
    this.fullscreenContent.close();
    SiteScrollLocker.unlockScrolling('full-screen-overlay');

    this.subscriptionManager.disposeSubscriptions();
  }

  private handleOpenAnimationFinished(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'full-screen-overlay-opened',
      detail: null
    });
  }

  private updateAnimator(): void {
    if (
      this.animator &&
      this.animator.getElement() === this.fullscreenContentWrapper
    ) {
      return;
    }

    if (this.animator) {
      this.animator.clearAnimations();
    }

    if (this.fullscreenContentWrapper) {
      this.animator = new ShowHideAnimator(this.fullscreenContentWrapper, {
        keepDisplayIntact: true
      });
    } else {
      this.animator = null;
    }
  }

  private createTransparentBackdropColor(): ShowHideAnimatorColor {
    // minus 1 because if it is the same then velocity won't animate it correctly in the first frame
    // (the color will be rgba(255,255,255, alpha) on the first frame instead of our set color)
    // but it works when it can detect a change and users won't notice it anyway
    return {
      red: this.backdropColor.red != null ? this.backdropColor.red - 1 : 0,
      green:
        this.backdropColor.green != null ? this.backdropColor.green - 1 : 0,
      blue: this.backdropColor.blue != null ? this.backdropColor.blue - 1 : 0,
      alpha: 0
    };
  }

  protected handleBackdropMousedown(event: MouseEvent): boolean {
    this.mouseDownStartedOnBackdrop = this.mouseEventOnBackdrop(event);
    return true;
  }

  protected handleBackdropClick(event: MouseEvent): boolean {
    // only close when the backdrop has been clicked directly
    // dialog shouldn't close when you click the content
    if (this.mouseEventOnBackdrop(event) && this.mouseDownStartedOnBackdrop) {
      DomEventHelper.fireEvent(this.element, {
        name: 'full-screen-overlay-backdrop-clicked',
        detail: null
      });

      if (!this.doNotCloseOnBackdropClick) {
        this.close();
      }

      return false;
    } else {
      return true;
    }
  }

  private mouseEventOnBackdrop(event: MouseEvent): boolean {
    return event.target === this.backdropElement;
  }

  protected handleCloseButtonClick(): void {
    this.close();
  }
}
