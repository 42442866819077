import { OptionalProperties } from '../../utilities';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type FullPersonContact<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    personId: TId;
    contactType: PersonContactType;
    name: string | null;
    note: string | null;
    highlighted: boolean;
  };

export type PersonContactDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullPersonContact<TId, TDate> = never,
  TPropertiesToRemove extends keyof FullPersonContact<TId, TDate> = never
> = Omit<
  OptionalProperties<FullPersonContact<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export enum PersonContactType {
  PHONE = 'phone',
  EMAIL = 'email'
}
