import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { configureHooks } from '../../hooks/configureHooks';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { watch } from '../../hooks/watch';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * Dialog where the user can verify that project data is correct
 * before starting a new inspection.
 */
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class ChecklistStartInspectionDialog {
  protected dialog: RecordItDialog | null = null;

  protected project: Project | null = null;

  protected onAcceptButtonClicked: null | (() => void) = null;

  protected selectedQuestionCatalogueIds: Array<string> = [];

  protected date: Date | null = null;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService
  ) {
    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: null
      });
  }

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open ChecklistStartInspectionDialog without a RecordItDialog.'
    );
    this.project = options.project;
    this.onAcceptButtonClicked = options.onAcceptButtonClicked ?? null;

    this.projectPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: options.project
      });

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.project = null;
  }

  protected handleAcceptButtonClicked(): void {
    this.onAcceptButtonClicked?.();
  }

  protected handleSelectedQuestionCataloguesChanged(): void {
    if (!this.project) return;

    this.entityManager.questionCatalogueToThingRepository.setRelations({
      thingId: this.project.thing,
      questionCatalogueIds: this.selectedQuestionCatalogueIds,
      ownerUserGroupId: this.project.ownerUserGroupId
    });
  }

  protected handleDateChanged(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot handleDateChanged without project'
    );
    if (!this.date) return;

    this.project.name = ChecklistProjectNameUtils.fromDate(this.date);
    this.entityManager.projectRepository.update(this.project);
  }

  @computed(expression('project.createdByUserId'), model(EntityName.User))
  protected get projectCreatorName(): string | null {
    const userId = this.project?.createdByUserId;
    if (!userId) return null;

    return this.entityManager.userRepository.getById(userId)?.username ?? null;
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  protected get thingName(): string | null {
    const thingId = this.project?.thing;
    if (!thingId) return null;

    return this.entityManager.thingRepository.getById(thingId)?.name ?? null;
  }

  @watch(expression('project.name'))
  protected updateDate(): void {
    if (!this.project || !this.project.name) return;

    this.date = ChecklistProjectNameUtils.toDate(this.project.name);
  }

  @watch(
    expression('project.thing'),
    model(EntityName.QuestionCatalogueToThing)
  )
  protected updateSelectedQuestionCatalogues(): void {
    if (!this.project) return;

    const relations =
      this.entityManager.questionCatalogueToThingRepository.getByThingId(
        this.project.thing
      );
    this.selectedQuestionCatalogueIds = relations.map(
      (r) => r.questionCatalogueId
    );
  }

  @computed(model(EntityName.QuestionCatalogue))
  protected get availableQuestionCatalogues(): Array<QuestionCatalogue> {
    return this.entityManager.questionCatalogueRepository.getAll();
  }

  @computed(expression('selectedQuestionCatalogueIds'))
  private get canCloseDialog(): boolean {
    return this.selectedQuestionCatalogueIds.length > 0;
  }

  @computed(expression('canCloseDialog'))
  protected get disabledButtons(): Array<string> {
    return this.canCloseDialog ? [] : ['accept'];
  }
}

type OpenOptions = {
  project: Project;
  onAcceptButtonClicked?: () => void;
};
