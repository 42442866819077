import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDeviceExport } from '../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { computed } from '../../hooks/computed';
import { configureHooks } from '../../hooks/configureHooks';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntitiesPermissionChecker } from '../../services/PermissionsService/EntitiesPermissionChecker/EntitiesPermissionChecker';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationDeviceExportDialog {
  @subscribableLifecycle()
  protected readonly processConfigurationDeviceExportPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationDeviceExport];

  @subscribableLifecycle()
  protected readonly processConfigurationDeviceExportPermissionChecker: EntitiesPermissionChecker<EntityName.ProcessConfigurationDeviceExport>;

  private processConfigurationDeviceExport: ProcessConfigurationDeviceExport | null =
    null;

  private dialog: RecordItDialog | null = null;

  public static async open(
    options: EditProcessConfigurationDeviceExportDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationDeviceExportPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationDeviceExport,
        context: this,
        expression: 'processConfigurationDeviceExport'
      });

    this.processConfigurationDeviceExportPermissionChecker =
      permissionsService.getEntitiesPermissionChecker({
        entityName: EntityName.ProcessConfigurationDeviceExport
      });
  }

  public open(
    options: EditProcessConfigurationDeviceExportDialogOpenOptions
  ): void {
    this.processConfigurationDeviceExport =
      options.processConfigurationDeviceExport;

    this.dialog?.open();
  }

  protected handleDialogClosed(): void {
    this.processConfigurationDeviceExport = null;
  }

  protected handleDefaultForNewDevicesChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationDeviceExport,
      "can't EditProcessConfigurationDeviceExportDialog.handleDefaultForNewDevicesChanged without a processConfigurationDeviceExport"
    );

    if (this.processConfigurationDeviceExport.defaultForNewDevices) {
      for (const deviceExport of this.allDeviceExportsInConfiguration) {
        if (
          deviceExport !== this.processConfigurationDeviceExport &&
          deviceExport.defaultForNewDevices
        ) {
          deviceExport.defaultForNewDevices = false;
          this.entityManager.processConfigurationDeviceExportRepository.update(
            deviceExport
          );
        }
      }
    }

    this.handleProcessConfigurationDeviceExportChanged();
  }

  private handleProcessConfigurationDeviceExportChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationDeviceExport,
      "can't EditProcessConfigurationDeviceExportDialog.handleProcessConfigurationDeviceExportChanged without a processConfigurationDeviceExport"
    );
    this.entityManager.processConfigurationDeviceExportRepository.update(
      this.processConfigurationDeviceExport
    );
  }

  @computed(
    expression('processConfigurationDeviceExport'),
    expression('allDeviceExportsInConfiguration'),
    expression('processConfigurationDeviceExportPermissionChecker.revision')
  )
  protected get defaultForNewDevicesEnabled(): boolean {
    if (!this.processConfigurationDeviceExport) {
      return false;
    }

    return this.processConfigurationDeviceExportPermissionChecker.allEntitiesHavePermission(
      {
        entities: this.allDeviceExportsInConfiguration,
        checkPermission: ({ adapter, entity }) => {
          return adapter.canEditField(entity); // defaultForNewDevices
        }
      }
    );
  }

  @computed(
    expression('processConfigurationDeviceExport.ownerProcessConfigurationId'),
    model(EntityName.ProcessConfigurationDeviceExport)
  )
  protected get allDeviceExportsInConfiguration(): Array<ProcessConfigurationDeviceExport> {
    if (!this.processConfigurationDeviceExport) {
      return [];
    }

    return this.entityManager.processConfigurationDeviceExportRepository.getByProcessConfigurationId(
      this.processConfigurationDeviceExport.ownerProcessConfigurationId
    );
  }
}

export type EditProcessConfigurationDeviceExportDialogOpenOptions = {
  processConfigurationDeviceExport: ProcessConfigurationDeviceExport;
};
