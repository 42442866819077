import { App } from '@capacitor/app';

import { DeviceInfoHelper } from './DeviceInfoHelper';
import { AsyncFunctionQueue } from './Queue/AsyncFunctionQueue';
import { Dialogs } from './Dialogs';

/**
 * a class which helps with setting up open with callbacks (openWith = opening/sharing a file with the recordIT app)
 */
export class OpenWithHelper {
  static _initialized = false;
  /** @type {Array<TOpenWithHelperHandler>} */
  static _handlers = [];
  /** @type {Array<import('openwith').IOpenwithIntent>} */
  static _pendingIntents = [];
  /** @type {AsyncFunctionQueue} */
  static _processPendingIntentsQueue;

  /** @type {boolean} */
  static _aureliaStarted = false;

  /**
   * this function doesn't need to be awaited/finished before the other functions can be used
   *
   * @returns {Promise<void>}
   */
  static async init() {
    if (
      this._initialized ||
      !DeviceInfoHelper.isApp() ||
      !window.cordova ||
      !window.cordova.openwith
    ) {
      return;
    }

    await App.addListener('appStateChange', (appState) => {
      if (!appState.isActive) {
        this._pendingIntents = []; // ignore intents which haven't been handled until the app has been closed again
      }
    });

    this._initialized = true;
    await DeviceInfoHelper.waitForDeviceReady();
    this._initializeOpenwithPlugin(window.cordova.openwith);
    window.cordova.openwith.addHandler(this._openWithMainHandler.bind(this));
  }

  static aureliaStarted() {
    this._aureliaStarted = true;
    this._getOrCreateProcessPendingIntentsQueue().queueFunction(
      this._processPendingIntents.bind(this)
    );
  }

  /**
   *
   * @param {TOpenWithHelperHandler} handler
   */
  static addHandler(handler) {
    this._handlers.push(handler);
    this._getOrCreateProcessPendingIntentsQueue().queueFunction(
      this._processPendingIntents.bind(this)
    );
  }

  /**
   * @param {import('openwith').IOpenwith} openwith
   */
  static _initializeOpenwithPlugin(openwith) {
    return new Promise((resolve, reject) => {
      openwith.init(
        () => {
          console.log('[OpenWithHelper] initialized the openwith plugin');
          resolve();
        },
        (err) => {
          console.error(
            '[OpenWithHelper], failed initializing the openwith plugin',
            err
          );
          reject(err);
        }
      );
    });
  }

  static async _processPendingIntents() {
    if (this._handlers.length && this._aureliaStarted) {
      // reverse loop because we modify the array
      for (let key = this._pendingIntents.length - 1; key >= 0; key--) {
        const intent = this._pendingIntents[key];
        const handled = await this._handleIntent(intent);

        if (handled) {
          this._pendingIntents.splice(key, 1);
        }
      }
    }
  }

  /**
   * @param {import('openwith').IOpenwithIntent} intent
   * @private
   */
  static async _openWithMainHandler(intent) {
    let handled = false;

    if (this._aureliaStarted) {
      handled = await this._handleIntent(intent);
    }

    if (!handled) {
      this._pendingIntents.push(intent);
    }
  }

  /**
   *
   * @param {import('openwith').IOpenwithIntent} intent
   * @returns {Promise<boolean>} - true if the intent has been handled by a handler
   * @private
   */
  static async _handleIntent(intent) {
    if (!intent.items.length) {
      return true;
    }

    let handled = false;

    for (let key = 0; key < intent.items.length; key++) {
      const item = intent.items[key];

      // for some reason the openwith plugin can send an item array with a null value in it
      if (!item) {
        continue;
      }

      const itemHandled = await this._handleItem(item);
      handled = handled || itemHandled;
    }

    this._showErrorDialogIfNecessary(handled);

    return handled;
  }

  /**
   *
   * @param {import('openwith').IOpenwithItem} item
   * @returns {Promise<boolean>} - true if the item has been handled
   * @private
   */
  static async _handleItem(item) {
    await this._loadBase64IntoItem(item);

    let handled = false;

    this._handlers.forEach((handler) => {
      if (handler(item)) {
        handled = true;
      }
    });

    return handled;
  }

  /**
   *
   * @param {import('openwith').IOpenwithItem} item
   * @returns {Promise}
   */
  static _loadBase64IntoItem(item) {
    return new Promise((resolve, reject) => {
      if (window.cordova && window.cordova.openwith) {
        window.cordova.openwith.load(item, resolve, reject);
      } else {
        reject(new Error('openwith is not available'));
      }
    });
  }

  /**
   * @param {boolean} intentHandled
   */
  static _showErrorDialogIfNecessary(intentHandled) {
    if (!intentHandled && this._handlers.length) {
      Dialogs.errorDialogTk(
        'classes.OpenWithHelper.invalidFileTypeTitle',
        'classes.OpenWithHelper.invalidFileTypeDescription'
      );
    }
  }

  /**
   *
   * @returns {AsyncFunctionQueue}
   * @private
   */
  static _getOrCreateProcessPendingIntentsQueue() {
    if (!this._processPendingIntentsQueue) {
      this._processPendingIntentsQueue = new AsyncFunctionQueue();
    }

    return this._processPendingIntentsQueue;
  }
}

/**
 * the TOpenWithItem is already loaded
 * function needs to return true if the item has been handled, if it can't handle the item, it should return false
 *
 * @typedef {function(import('openwith').IOpenwithItem):boolean} TOpenWithHelperHandler
 */
