import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationDeviceEntityInfo } from 'common/Types/Entities/ProcessConfigurationDevice/createProcessConfigurationDeviceEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationDevice } from './types';

export const processConfigurationDeviceEntityInfo =
  createProcessConfigurationDeviceEntityInfo<ProcessConfigurationDevice>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
