import { computedFrom } from 'aurelia-framework';
import { UserGroup } from '../../../classes/EntityManager/entities/UserGroup/types';
import { UserGroupAdapter } from '../EntityAdapter/UserGroupAdapter/UserGroupAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class UserGroupPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<UserGroupAdapter>,
  UserGroup
> {
  @computedFrom('combinedRevision')
  public get canAddUserSpec(): boolean {
    return this.checkAdapterSpecificPermissionByName('canAddUserSpec');
  }

  @computedFrom('combinedRevision')
  public get canEditUserSpecs(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditUserSpecs');
  }

  @computedFrom('combinedRevision')
  public get canEditOwnGroupAdminRights(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canEditOwnGroupAdminRights'
    );
  }

  @computedFrom('combinedRevision')
  public get canRemoveOwnUserSpec(): boolean {
    return this.checkAdapterSpecificPermissionByName('canRemoveOwnUserSpec');
  }

  @computedFrom('combinedRevision')
  public get canCreateThings(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateThings');
  }

  @computedFrom('combinedRevision')
  public get canCreateUserRoles(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateUserRoles');
  }

  @computedFrom('combinedRevision')
  public get canCreateUserRoleToUsers(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateUserRoleToUsers'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeePersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canSeePersons');
  }

  @computedFrom('combinedRevision')
  public get canCreatePersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreatePersons');
  }

  @computedFrom('combinedRevision')
  public get canCreateTextBrickTemplates(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateTextBrickTemplates'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurations(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurations'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeeControlEntityVisibilityWithAuthorizationsCheckbox(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeControlEntityVisibilityWithAuthorizationsCheckbox'
    );
  }
}
