import { computedFrom } from 'aurelia-binding';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';

export class SelectEntryPathHandle {
  private readonly internalEntryPaths: Array<Array<Entry>>;
  private readonly onEntryPathSelected: OnEntryPathSelected;

  constructor(options: SelectEntryPathHandleOptions) {
    this.internalEntryPaths = options.entryPaths;
    this.onEntryPathSelected = options.onEntryPathSelected;
  }

  @computedFrom('internalEntryPaths')
  public get entryPaths(): Array<Array<Entry>> {
    return this.internalEntryPaths;
  }

  public selectEntryPath(entryPath: Array<Entry>): void {
    this.onEntryPathSelected({ entryPath });
  }
}

export type SelectEntryPathHandleOptions = {
  entryPaths: Array<Array<Entry>>;
  onEntryPathSelected: OnEntryPathSelected;
};

export type OnEntryPathSelected = (options: {
  entryPath: Array<Entry>;
}) => void;
