import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  OnCreateNewUserDefinedEntitiesCallback,
  SupportedTemplateUserDefinedEntity
} from '../../dialogs/copy-user-defined-entity-dialog/copy-user-defined-entity-dialog';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import {
  GlobalUserDefinedEntity,
  ThingUserDefinedEntity
} from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntityCreationService } from '../../classes/EntityManager/entities/UserDefinedEntity/UserDefinedEntityCreationService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserDefinedEntitiesFilterService } from '../../services/UserDefinedEntitiesFilterService/UserDefinedEntitiesFilterService';

@autoinject()
export class ThingUserDefinedEntities {
  @bindable public thing: Thing | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly userDefinedEntityCreationService: UserDefinedEntityCreationService,
    private userDefinedEntitiesFilterService: UserDefinedEntitiesFilterService
  ) {}

  @computed(model(EntityName.UserDefinedEntity), expression('thing'))
  private get userDefinedEntities(): Array<ThingUserDefinedEntity> {
    if (!this.thing) return [];
    return this.entityManager.userDefinedEntityRepository.getByThingId(
      this.thing.id
    );
  }

  protected createOnCreateNewThingUserDefinedEntitiesCallback(): OnCreateNewUserDefinedEntitiesCallback<Thing> {
    return this.userDefinedEntityCreationService.createThingUserDefinedEntity.bind(
      this.userDefinedEntityCreationService
    );
  }

  @computed(model(EntityName.UserDefinedEntity), expression('userGroup'))
  private get availableGlobalUserDefinedEntities(): Array<GlobalUserDefinedEntity> {
    if (!this.thing) return [];
    return this.entityManager.userDefinedEntityRepository.getGlobalByOwnerUserGroupId(
      this.thing.ownerUserGroupId
    );
  }

  @computed(
    expression('availableGlobalUserDefinedEntities'),
    expression('userDefinedEntities')
  )
  protected get selectableUserDefinedEntityTemplates(): Array<
    SupportedTemplateUserDefinedEntity<Thing>
  > {
    return this.userDefinedEntitiesFilterService.filterGlobalUserDefinedEntityTemplates(
      this.userDefinedEntities,
      this.availableGlobalUserDefinedEntities
    );
  }
}
