import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DefectStatus } from '../../../Enums/DefectStatus';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { DefectDto } from './DefectDto';

export function createDefectEntityInfo<
  TEntity extends DefectDto<string, string>
>(
  options: CreateEntityInfoOptions<DefectDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Defect,
  TEntity
> {
  return {
    entityName: EntityName.Defect,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      DefectDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        sequenceNumber: {
          name: 'sequenceNumber',
          defaultValue: null
        },
        status: {
          name: 'status',
          defaultValue: DefectStatus.OPEN
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        dueAt: {
          name: 'dueAt',
          defaultValue: null
        },
        assigneeId: {
          name: 'assigneeId',
          ref: EntityName.User,
          defaultValue: null
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
