import { AnyEntry } from '../../entries/AnyEntry';
import { CustomDirectoryEntry } from '../../entries/CustomDirectoryEntry';
import { CustomFileEntry } from '../../entries/CustomFileEntry';
import { FileUtilsAdapter } from '../FileUtilsAdapter';

export class WebAdapter extends FileUtilsAdapter {
  public resolvePath(): Promise<AnyEntry> {
    throw new FileSystemNotAvailableInWebError();
  }

  public resolveDirectoryPath(): Promise<CustomDirectoryEntry> {
    throw new FileSystemNotAvailableInWebError();
  }

  public resolveFilePath(): Promise<CustomFileEntry> {
    throw new FileSystemNotAvailableInWebError();
  }

  public convertFileSrc(): Promise<string> {
    throw new FileSystemNotAvailableInWebError();
  }
}

export class FileSystemNotAvailableInWebError extends Error {
  constructor() {
    super('The filesystem is not available in the browser.');
  }
}
