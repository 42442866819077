import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Thing } from './types';
import { AppEntityManagerEntityTypesByEntityName } from '../AppEntityManagerEntityTypesByEntityName';

export class ThingRepository extends AppEntityRepository<EntityName.Thing> {
  /**
   * @deprecated use {ThingCreationService} instead.
   */
  create(
    creationEntity: AppEntityManagerEntityTypesByEntityName[EntityName.Thing]['creationEntity']
  ): AppEntityManagerEntityTypesByEntityName[EntityName.Thing]['entity'] {
    return super.create(creationEntity);
  }

  public getByUserGroupId(userGroupId: string): Array<Thing> {
    return this.getAll().filter((thing) => {
      return thing.ownerUserGroupId === userGroupId;
    });
  }

  public getByThingGroupId(thingGroupId: string): Array<Thing> {
    return this.getAll().filter((thing) => {
      return thing.thingGroupId === thingGroupId;
    });
  }
}
