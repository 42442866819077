import { assertNotNullOrUndefined } from 'common/Asserts';
import { PercentXYPosition } from 'common/Types/Entities/Picture/PictureDto';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../../classes/EntityManager/entities/Entry/types';
import { PictureAdditionalMarking } from '../../../../classes/EntityManager/entities/Picture/types';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { StructurePictureArea } from '../../../../classes/EntityManager/entities/StructurePictureArea/types';
import { SelectEntryPathHandle } from '../../structure-thing-rapid-fire-widget-select-entry-path-content/SelectEntryPathHandle';

export class StructurePictureAreaMarkingsHandler {
  private readonly entityManager: AppEntityManager;
  private readonly project: Project;
  private readonly onEntryPathSelected: OnEntryPathSelected;
  private readonly selectEntryPath: SelectEntryPath;

  private entryPath: Array<Entry>;

  constructor(options: StructurePictureAreaMarkingsHandlerOptions) {
    this.entityManager = options.entityManager;
    this.project = options.project;
    this.onEntryPathSelected = options.onEntryPathSelected;
    this.selectEntryPath = options.selectEntryPath;
    this.entryPath = options.entryPath;
  }

  public setEntryPath(entryPath: Array<Entry>): void {
    this.entryPath = entryPath;
  }

  public setAdditionalMarkings(
    additionalMarkings: Array<PictureAdditionalMarking>
  ): void {
    const pictureAreas = this.getPictureAreasWithMarkings({
      additionalMarkings
    });
    const mostSpecificPictureAreas = this.getMostSpecificPictureAreas({
      pictureAreas
    });
    const entryPaths = this.convertPictureAreasToEntryPaths({
      pictureAreas: mostSpecificPictureAreas
    });
    const allowedEntryPaths = this.getEntryPathsWhichAreSubPathsOfTheEntryPath({
      allEntryPaths: entryPaths
    });

    if (allowedEntryPaths.length === 0) {
      // we explicitly don't want to do anything if we have no candidates
    } else if (allowedEntryPaths.length === 1) {
      const firstEntryPath = allowedEntryPaths[0];
      assertNotNullOrUndefined(
        firstEntryPath,
        'array has a length of one but has an undefined/or null first entry?'
      ); // necessary because typescript doesn't realise if the array has a length of 1, it also has a first element

      this.handleEntryPathSelected({
        entryPath: firstEntryPath
      });
    } else {
      this.selectEntryPath({
        selectEntryPathHandle: new SelectEntryPathHandle({
          entryPaths: allowedEntryPaths,
          onEntryPathSelected: ({ entryPath }) => {
            this.handleEntryPathSelected({
              entryPath
            });
          }
        })
      });
    }
  }

  private getPictureAreasWithMarkings({
    additionalMarkings
  }: {
    additionalMarkings: Array<PictureAdditionalMarking>;
  }): Array<StructurePictureArea> {
    const pictureAreas: Array<StructurePictureArea> = [];

    for (const additionalMarking of additionalMarkings) {
      pictureAreas.push(
        ...this.getMarkedPictureAreasOfAdditionalMarking({
          additionalMarking
        })
      );
    }

    return pictureAreas;
  }

  private getMarkedPictureAreasOfAdditionalMarking({
    additionalMarking
  }: {
    additionalMarking: PictureAdditionalMarking;
  }): Array<StructurePictureArea> {
    const picture = this.entityManager.pictureRepository.getById(
      additionalMarking.picture_id
    );
    const areas = picture
      ? this.entityManager.structurePictureAreaRepository.getByPictureId(
          picture.id
        )
      : [];

    const markingPosition: PercentXYPosition = {
      x: parseFloat(additionalMarking.left),
      y: parseFloat(additionalMarking.top)
    };

    return areas.filter((area) => {
      if (!area.topLeft || !area.bottomRight) {
        return false;
      }

      return this.pointIsInArea({
        markingPosition,
        areaTopLeft: area.topLeft,
        areaBottomRight: area.bottomRight
      });
    });
  }

  private getMostSpecificPictureAreas({
    pictureAreas
  }: {
    pictureAreas: Array<StructurePictureArea>;
  }): Array<StructurePictureArea> {
    const highestSpecifity = Math.max(
      ...pictureAreas.map((pictureArea) => pictureArea.path.length)
    );

    return pictureAreas.filter((pictureArea) => {
      return pictureArea.path.length === highestSpecifity;
    });
  }

  private convertPictureAreasToEntryPaths({
    pictureAreas
  }: {
    pictureAreas: Array<StructurePictureArea>;
  }): Array<Array<Entry>> {
    return pictureAreas.map((pictureArea) => {
      return this.convertEntryNamePathToEntryPath({
        entryNamePath: pictureArea.path
      });
    });
  }

  private convertEntryNamePathToEntryPath({
    entryNamePath
  }: {
    entryNamePath: Array<string>;
  }): Array<Entry> {
    const entryPath: Array<Entry> = [];
    let lastEntry: Entry | null = null;

    for (const entryName of entryNamePath) {
      const comparisonEntryName = entryName.trim().toLocaleLowerCase();
      const entries = this.entityManager.entryRepository.getByParentId(
        this.project.id,
        lastEntry?.id ?? null,
        null
      );

      const entry =
        entries.find((e) => {
          return (
            (e.name ?? '').trim().toLocaleLowerCase() === comparisonEntryName
          );
        }) ?? null;

      if (!entry) {
        break;
      }

      entryPath.push(entry);
      lastEntry = entry;
    }

    return entryPath;
  }

  private getEntryPathsWhichAreSubPathsOfTheEntryPath({
    allEntryPaths
  }: {
    allEntryPaths: Array<Array<Entry>>;
  }): Array<Array<Entry>> {
    const subPaths: Array<Array<Entry>> = [];

    for (const entryPath of allEntryPaths) {
      const isMoreSpecific = entryPath.length > this.entryPath.length;
      const isSubPath = this.entryPath.every((entry, index) => {
        return entryPath[index] === entry;
      });

      if (isMoreSpecific && isSubPath) {
        subPaths.push(entryPath);
      }
    }

    return subPaths;
  }

  private handleEntryPathSelected({
    entryPath
  }: {
    entryPath: Array<Entry>;
  }): void {
    this.onEntryPathSelected({ entryPath });
  }

  private pointIsInArea({
    markingPosition,
    areaTopLeft,
    areaBottomRight
  }: {
    markingPosition: PercentXYPosition;
    areaTopLeft: PercentXYPosition;
    areaBottomRight: PercentXYPosition;
  }): boolean {
    return (
      markingPosition.x >= areaTopLeft.x &&
      markingPosition.x <= areaBottomRight.x &&
      markingPosition.y >= areaTopLeft.y &&
      markingPosition.y <= areaBottomRight.y
    );
  }
}

export type StructurePictureAreaMarkingsHandlerOptions = {
  project: Project;
  entryPath: Array<Entry>;
  selectEntryPath: SelectEntryPath;
  onEntryPathSelected: OnEntryPathSelected;
  entityManager: AppEntityManager;
};

export type SelectEntryPath = (options: {
  selectEntryPathHandle: SelectEntryPathHandle;
}) => void;
export type OnEntryPathSelected = (options: {
  entryPath: Array<Entry>;
}) => void;
