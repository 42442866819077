import { inject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@inject(AppEntityManager, SubscriptionManagerService)
export class ProcessTaskFormsWidget {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /**
   * null if forms aren't loaded
   * read only
   *
   * @type {number|null}
   */
  @bindable formCount = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskToProject/types').FormProcessTaskToProject>} */
  _formRelations = [];

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this._updateFormRelations.bind(this)
    );
    this._updateFormRelations();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskChanged() {
    this._updateFormRelations();
  }

  _updateFormRelations() {
    if (this.processTask) {
      this._formRelations =
        this._entityManager.processTaskToProjectRepository.getFormProcessTaskToProjectsByProcessTaskId(
          this.processTask.id
        );
      this.formCount = this._formRelations.length;
    } else {
      this._formRelations = [];
      this.formCount = null;
    }
  }
}
