import { autoinject } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * A component for displaying one list item in a list of config settings.
 *
 * @see {config-list}
 *
 * @slot default
 *
 * @event delete-button-clicked triggered when the delete button is clicked.
 */
@autoinject()
export class ConfigListItem {
  private readonly element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected handleDeleteButtonClicked(): void {
    DomEventHelper.fireEvent<DeleteButtonClickedEvent>(this.element, {
      name: 'delete-button-clicked',
      detail: null
    });
  }
}

export type DeleteButtonClickedEvent =
  NamedCustomEvent<'delete-button-clicked'>;
