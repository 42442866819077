import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { GalleryThingHelper } from 'common/GalleryThing/GalleryThingHelper';

import {
  UltraRapidFireWidget,
  SavePictureCallbackThingOptions,
  Type
} from '../aureliaComponents/ultra-rapid-fire-widget/ultra-rapid-fire-widget';
import {
  PictureCreatorService,
  AdditionalPictureCreationOptions,
  PictureCreatorServiceWithEntityData
} from '../classes/Picture/PictureCreatorService';
import { UltraRapidFireWidgetDefect } from '../aureliaComponents/ultra-rapid-fire-widget-defect/ultra-rapid-fire-widget-defect';
import { CreateDefectDialog } from '../dialogs/create-defect-dialog/create-defect-dialog';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../classes/EntityManager/entities/Project/types';
import { Defect } from '../classes/EntityManager/entities/Defect/types';
import {
  Picture,
  PictureAdditionalMarking,
  PictureCoords
} from '../classes/EntityManager/entities/Picture/types';
import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { PropertyCreationBaseData } from '../classes/EntityManager/entities/Property/types';
import { GalleryThingJoinedProjectsService } from './GalleryThingJoinedProjectsService';
import {
  CoordsFromPositionedPictureInfo,
  IPictureCoords
} from '../../../common/src/Types/Entities/Picture/PictureDto';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import { ProjectCreationService } from '../classes/EntityManager/entities/Project/ProjectCreationService';
import { ExifDataHelper } from '../classes/Picture/ExifDataHelper';
import { GalleryThingPictureCreatorUtils } from '../classes/GalleryThing/GalleryThingPictureCreatorUtils';
import { ActiveUserCompanySettingService } from '../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class GalleryThingPictureCreatorService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly galleryThingJoinedProjectsService: GalleryThingJoinedProjectsService,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly projectCreationService: ProjectCreationService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  public async createPictureFromFile(
    file: File,
    pictureData: PictureCreatorData,
    projectId?: string,
    showDialogs?: boolean
  ): Promise<Picture | null> {
    const createdProjectId = await this.injectProjectIdUsingCreationDate(
      projectId,
      file,
      pictureData
    );
    return this.createPictureWithPictureCreator(
      pictureData,
      createdProjectId,
      async (creator, creationOptions) => {
        return creator.createPictureFromFile(
          file,
          creationOptions,
          showDialogs
        );
      }
    );
  }

  public createPicture(
    pictureData: PictureCreatorData,
    projectId?: string
  ): Promise<Picture> {
    return this.createPictureWithPictureCreator(
      pictureData,
      projectId,
      async (creator, creationOptions) => {
        return creator.createPicture(creationOptions);
      }
    );
  }

  public async createWhitePicture(
    pictureData: PictureCreatorData,
    projectId?: string
  ): Promise<Picture> {
    return await this.createPictureWithPictureCreator(
      pictureData,
      projectId,
      async (creator, creationOptions) => {
        return creator.createWhitePicture(creationOptions);
      }
    );
  }

  public createDefectPicture(
    pictureData: PictureCreatorData,
    defect: Defect
  ): Picture {
    return this.createDefectPictureWithPictureCreator(
      pictureData,
      defect,
      (creator, creationOptions) => {
        return creator.createPicture(creationOptions);
      }
    );
  }

  public capturePictureWithUltraRapidFireWidget(
    pictureData: PictureCreatorDataWithoutCoords,
    projectId?: string
  ): void {
    this.capturePictureWithUltraRapidFireWidgetWithSetCoords(
      pictureData,
      projectId
    );
  }

  public capturePictureWithUltraRapidFireWidgetWithSetCoords(
    pictureData: PictureCreatorData,
    projectId?: string
  ): void {
    const project = this.getOrCreateProject(pictureData, projectId);

    const options: SavePictureCallbackThingOptions = {
      type: Type.GALLERY_THING,
      thingId: pictureData.thing.id,
      ownerUserGroupId: project.ownerUserGroupId,
      properties: pictureData.properties || [],
      additionalMarkings: pictureData.additionalMarkings || null,
      coords: pictureData.coords,
      description: pictureData.description || null,
      personIds: pictureData.personIds || null,
      regionId: pictureData.regionId || null,
      tagIds: pictureData.tagIds || []
    };
    const createPictureData: PictureCreatorData = {
      thing: pictureData.thing,
      regionId: options.regionId,
      personIds: options.personIds,
      tagIds: options.tagIds,
      properties: options.properties,
      coords: options.coords,
      coordsFromPositionedPictureInfo:
        pictureData.coordsFromPositionedPictureInfo || undefined,
      description: options.description,
      additionalMarkings: options.additionalMarkings
    };
    void UltraRapidFireWidget.start({
      options: options,
      savePictureCallback: (userTagIds: Array<string>) => {
        const savePictureData: PictureCreatorData = {
          ...createPictureData,
          // Prevent duplication of tagIds
          tagIds: createPictureData.tagIds
            ? Array.from(new Set([...createPictureData.tagIds, ...userTagIds]))
            : userTagIds
        };

        return this.createPicture(savePictureData, projectId);
      },
      createDefectCallback: () => {
        void CreateDefectDialog.open({
          ownerThingId: options.thingId,
          ownerUserGroupId: options.ownerUserGroupId,
          onAcceptButtonClicked: (defectId: string) => {
            this.capturePictureWithUltraRapidFireWidgetDefect(
              createPictureData,
              defectId
            );
          }
        });
      }
    });
  }

  public capturePictureWithUltraRapidFireWidgetDefect(
    createPictureData: PictureCreatorData,
    defectId: string
  ): void {
    void UltraRapidFireWidgetDefect.start({
      pictureCreatorData: createPictureData,
      defectId: defectId,
      savePictureCallback: (defect, tagIds) => {
        const pictureData: PictureCreatorData = {
          ...createPictureData,
          tagIds: createPictureData.tagIds
            ? Array.from(new Set([...createPictureData.tagIds, ...tagIds]))
            : tagIds
        };
        return this.createDefectPicture(pictureData, defect);
      }
    });
  }

  /**
   * Searches for a project for a given date.
   * If none is found, creates a new project with the given date instead.
   */
  public getOrCreateProjectForDate(thing: Thing, date = new Date()): Project {
    return (
      this.findAndJoinProjectByDate(thing, date) ||
      this.createAndJoinProjectForDate(thing, date)
    );
  }

  private async injectProjectIdUsingCreationDate(
    projectId: string | undefined,
    pictureFile: File,
    pictureData: PictureCreatorData
  ): Promise<string | undefined> {
    if (!projectId) {
      const creationDate =
        await ExifDataHelper.getCreationDateFromExifData(pictureFile);
      if (creationDate) {
        const newProject = this.getOrCreateProjectForDate(
          pictureData.thing,
          new Date(creationDate)
        );
        return newProject.id;
      }
    }
    return projectId;
  }

  private getCoordsFromPositionedPictureInfo(
    coordsOfNewPicture: IPictureCoords | null | undefined,
    coordsFromPositionedPictureInfoOfNewPicture:
      | CoordsFromPositionedPictureInfo
      | null
      | undefined,
    thing: Thing
  ): CoordsFromPositionedPictureInfo | null {
    if (
      this.activeUserCompanySettingService.getSettingProperty(
        'via.automaticallyMarkPicturesOnThingPicture'
      ) !== true ||
      !!coordsFromPositionedPictureInfoOfNewPicture ||
      !coordsOfNewPicture?.latitude ||
      !coordsOfNewPicture?.longitude
    )
      return coordsFromPositionedPictureInfoOfNewPicture ?? null;

    return GalleryThingPictureCreatorUtils.getCoordsFromPositionedPictureInfo(
      this.entityManager,
      coordsOfNewPicture,
      thing.id
    );
  }

  private createPictureWithPictureCreator<T extends Picture | null>(
    pictureData: PictureCreatorData,
    projectId: string | undefined,
    executor: (
      creator: PictureCreatorServiceWithEntityData,
      options: AdditionalPictureCreationOptions
    ) => Promise<T>
  ): Promise<T> {
    const prepareResult = this.prepareEntitiesForPicture(
      pictureData,
      projectId
    );

    const creator = this.pictureCreatorService.withEntityInfos(() => ({
      mainEntityId: prepareResult.project.id,
      mainEntityIdField: 'project',
      subEntityValue: prepareResult.entry.id,
      subEntityField: 'entry',
      ownerProjectId: prepareResult.project.id,
      ownerUserGroupId: prepareResult.project.ownerUserGroupId
    }));

    return executor(
      creator,
      this.createAdditionalPictureCreationOptions({
        pictureData,
        selected: true
      })
    );
  }

  private createDefectPictureWithPictureCreator(
    pictureData: PictureCreatorData,
    defect: Defect,
    executor: (
      creator: PictureCreatorServiceWithEntityData,
      options: AdditionalPictureCreationOptions
    ) => Picture
  ): Picture {
    const creator = this.pictureCreatorService.withEntityInfos(() => ({
      mainEntityIdField: 'ownerDefectId',
      mainEntityId: defect.id,
      subEntityField: 'defect',
      subEntityValue: defect.id,
      ownerUserGroupId: defect.ownerUserGroupId
    }));

    return executor(
      creator,
      this.createAdditionalPictureCreationOptions({
        pictureData,
        selected: false
      })
    );
  }

  private createAdditionalPictureCreationOptions({
    pictureData,
    selected
  }: {
    pictureData: PictureCreatorData;
    selected: boolean;
  }): AdditionalPictureCreationOptions {
    return {
      selected: selected,
      coords: pictureData.coords,
      coordsFromPositionedPictureInfo: this.getCoordsFromPositionedPictureInfo(
        pictureData.coords,
        pictureData.coordsFromPositionedPictureInfo,
        pictureData.thing
      ),
      description: pictureData.description,
      tagIds: pictureData.tagIds || undefined,
      additional_markings: pictureData.additionalMarkings || undefined
    };
  }

  private prepareEntitiesForPicture(
    pictureData: PictureCreatorData,
    projectId?: string
  ): PrepareEntitiesForPictureResult {
    const project = this.getOrCreateProject(pictureData, projectId);
    const entry = this.entityManager.entryRepository.create({
      project: project.id,
      ownerProjectId: project.id,
      ownerUserGroupId: project.usergroup,
      regionId: pictureData.regionId || null
    });

    if (pictureData.properties) {
      for (const property of pictureData.properties) {
        this.entityManager.propertyRepository.create({
          entry: entry.id,
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId,
          alwaysVisible: true,
          ...property
        });
      }
    }

    if (pictureData.personIds) {
      pictureData.personIds.forEach((personId) => {
        this.entityManager.entryToPersonRepository.create({
          ownerUserGroupId: entry.ownerUserGroupId,
          ownerProjectId: entry.ownerProjectId,
          entryId: entry.id,
          personId: personId
        });
      });
    }

    return {
      project,
      entry
    };
  }

  /**
   * If projectId exists, tries to find a project from there.
   * Otherwise, get's the project for the current date.
   */
  private getOrCreateProject(
    pictureData: PictureCreatorData,
    projectId?: string
  ): Project {
    return projectId
      ? this.getAndJoinProjectForId(pictureData.thing, projectId)
      : this.getOrCreateProjectForDate(pictureData.thing);
  }

  private getAndJoinProjectForId(thing: Thing, projectId: string): Project {
    const project = this.entityManager.projectRepository.getById(projectId);
    assertNotNullOrUndefined(
      project,
      `projectId '${projectId}' does not correspond to a project`
    );
    this.joinProject(thing.id, project);
    return project;
  }

  private findAndJoinProjectByDate(thing: Thing, date: Date): Project | null {
    const projects = this.entityManager.projectRepository
      .getByThingId(thing.id)
      .filter((p) => p.projectType === ProjectType.GALLERY);
    const projectName = GalleryThingHelper.getProjectNameForDate(date);
    const project = projects.find((pr) => pr.name === projectName) || null;
    if (project) {
      this.joinProject(thing.id, project);
    }
    return project;
  }

  private createAndJoinProjectForDate(thing: Thing, date: Date): Project {
    const project = this.projectCreationService.createProject({
      thing,
      projectType: ProjectType.GALLERY,
      name: GalleryThingHelper.getProjectNameForDate(date),
      reportType: null,
      structureTemplateId: null
    });
    this.joinProject(thing.id, project);
    return project;
  }

  private joinProject(thingId: string, project: Project): void {
    this.galleryThingJoinedProjectsService.autoJoinProjectDateIfNeeded(
      thingId,
      project
    );
  }
}

export type PictureCreatorData = {
  thing: Thing;
  regionId?: string | null;
  personIds?: Array<string> | null;
  tagIds?: Array<string> | null;
  properties?: Array<PropertyCreationBaseData> | null;
  coords?: PictureCoords | null;
  coordsFromPositionedPictureInfo?: CoordsFromPositionedPictureInfo | null;
  description?: string | null;
  additionalMarkings?: Array<PictureAdditionalMarking> | null;
};

export type PictureCreatorDataWithoutCoords = Omit<
  PictureCreatorData,
  'coords'
> & { coords?: undefined };

type PrepareEntitiesForPictureResult = {
  project: Project;
  entry: Entry;
};
