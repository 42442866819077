import { CommonEntityHelper } from 'common/EntityHelper/CommonEntityHelper';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ProcessTaskPosition } from './types';
import { ProcessTaskPositionDetailEntriesByProcessTaskPositionId } from '../../../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { PropertiesByProcessTaskPositionDetailEntryId } from '../../../../computedValues/computers/PropertiesByProcessTaskPositionDetailEntryIdComputer';
import { PositionInfo } from 'common/Operations/ProcessTaskPositionCalculator';
import { ProcessTaskPositionDetailEntry } from '../ProcessTaskPositionDetailEntry/types';
import { ProcessTaskPositionDetailEntryUtils } from '../ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryUtils';

export class ProcessTaskPositionUtils {
  private constructor() {}

  public static sortProcessTaskPositions(
    positions: Array<ProcessTaskPosition>
  ): Array<ProcessTaskPosition> {
    const sortedPositions = positions.slice();

    sortedPositions.sort((a, b) => {
      return CommonEntityHelper.comparePositionByEntityOrder(a, b);
    });

    return sortedPositions;
  }

  public static categorizePositions(
    positions: Array<ProcessTaskPosition>
  ): Array<PositionCategoryGroup> {
    const groups: Array<PositionCategoryGroup> = [];

    positions.forEach((position) => {
      const group = groups.find(
        (g) => g.categoryId === position.processConfigurationCategoryId
      );
      if (group) {
        group.positions.push(position);
      } else {
        groups.push({
          categoryId: position.processConfigurationCategoryId,
          positions: [position]
        });
      }
    });

    return groups;
  }

  public static getAmountString(
    positionId: string,
    amount: string,
    unit: string | null,
    detailEnabled: boolean,
    detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
    propertiesByDetailEntryId: PropertiesByProcessTaskPositionDetailEntryId
  ): string {
    if (!detailEnabled) {
      return this.getAmountWithUnit(amount, unit);
    }

    const detailEntries = detailEntriesByPositionId.get(positionId) ?? [];
    const parts = detailEntries.map((detailEntry) => {
      const properties = propertiesByDetailEntryId.get(detailEntry.id) ?? [];
      const propertyStrings = properties.map((property) => {
        return (
          PropertyHelper.getPropertyText(
            property.type || PropertyType.TEXT,
            property.name,
            property.value,
            property.custom_choice
          ) || '-'
        );
      });

      const infoParts = [propertyStrings.join(',')];
      infoParts.push(
        ProcessTaskPositionUtils.getAmountWithUnit(
          detailEntry.amount.toString(),
          unit
        )
      );
      return infoParts.join(': ');
    });

    return parts.join(' — ');
  }

  public static createPositionInfosForCalculation(
    processTaskPositions: Array<ProcessTaskPosition>,
    detailEntriesByPositionIdMap: ProcessTaskPositionDetailEntriesByProcessTaskPositionId
  ): Array<PositionInfo<ProcessTaskPosition, ProcessTaskPositionDetailEntry>> {
    return processTaskPositions.map((p) => {
      return {
        ownerProcessTaskId: p.ownerProcessTaskId,
        type: p.type,
        customType: p.customType,
        amount: p.amount,
        price: p.price,
        flatRate: p.flatRate,
        excludeFromMarkup: p.excludeFromMarkup,
        ignoreExcludeFromMarkup: p.ignoreExcludeFromMarkup,
        discount: p.discount,
        detailEnabled: p.detailEnabled,
        detailEntryInfos:
          ProcessTaskPositionDetailEntryUtils.getDetailEntryInfos(
            detailEntriesByPositionIdMap.get(p.id) ?? []
          ),
        originalPosition: p
      };
    });
  }

  private static getAmountWithUnit(
    amount: string,
    unit: string | null
  ): string {
    return amount + (unit ? ` ${unit}` : '');
  }
}

export type PositionCategoryGroup = {
  categoryId: string | null;
  positions: Array<ProcessTaskPosition>;
};
