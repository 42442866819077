import { autoinject, bindable } from 'aurelia-framework';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { StructureTemplateEntry } from '../../../classes/EntityManager/entities/StructureTemplateEntry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SelectChangedEvent } from '../../../inputComponents/custom-select/custom-select';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

/**
 * @event {StructureTemplateEntryChangedEvent} structure-template-entry-changed
 */
@autoinject()
export class EditStructurePictureAreaPathDialogEntrySelect {
  @bindable()
  public structureTemplateId: string | null = null;

  @bindable()
  public parentStructureTemplateEntryId: string | null = null;

  @bindable()
  public structureTemplateEntry: StructureTemplateEntry | null = null;

  @bindable()
  public depth: number = 0;

  @bindable()
  public enabled = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected structureTemplateEntriesToSelect: Array<StructureTemplateEntry> =
    [];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntry,
      this.updateStructureTemplateEntriesToSelect.bind(this)
    );
    this.updateStructureTemplateEntriesToSelect();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected structureTemplateIdChanged(): void {
    if (this.isAttached) {
      this.updateStructureTemplateEntriesToSelect();
    }
  }

  protected parentStructureTemplateEntryIdChanged(): void {
    if (this.isAttached) {
      this.updateStructureTemplateEntriesToSelect();
    }
  }

  protected handleSelectChanged(
    event: SelectChangedEvent<StructureTemplateEntry, StructureTemplateEntry>
  ): void {
    DomEventHelper.fireEvent<StructureTemplateEntryChangedEvent>(this.element, {
      name: 'structure-template-entry-changed',
      detail: {
        structureTemplateEntry: event.detail.value
      }
    });
  }

  private updateStructureTemplateEntriesToSelect(): void {
    if (this.structureTemplateId) {
      this.structureTemplateEntriesToSelect = [
        ...this.getStructureTemplateEntriesFromGroup(
          this.parentStructureTemplateEntryId
        ),
        ...this.entityManager.structureTemplateEntryRepository.getByParentId(
          this.structureTemplateId,
          this.parentStructureTemplateEntryId
        )
      ];
    } else {
      this.structureTemplateEntriesToSelect = [];
    }
  }

  private getStructureTemplateEntriesFromGroup(
    parentStructureTemplateEntryId: string | null
  ): Array<StructureTemplateEntry> {
    const structureTemplateEntry = parentStructureTemplateEntryId
      ? this.entityManager.structureTemplateEntryRepository.getById(
          parentStructureTemplateEntryId
        )
      : null;
    const relation = structureTemplateEntry
      ? this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getByStructureTemplateEntryId(
          structureTemplateEntry.id
        )
      : null;

    if (relation) {
      return this.entityManager.structureTemplateEntryRepository.getByStructureTemplateEntryGroupId(
        relation.structureTemplateEntryGroupId
      );
    }

    return [];
  }
}

export type StructureTemplateEntryChangedEvent = NamedCustomEvent<
  'structure-template-entry-changed',
  {
    structureTemplateEntry: StructureTemplateEntry | null;
  }
>;
