import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { BaseEntityUtils } from '../../BaseEntities/BaseEntityUtils';
import { ExternalUserCredentialDto } from './ExternalUserCredentialDto';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';

export function createExternalUserCredentialEntityInfo<
  TEntity extends ExternalUserCredentialDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ExternalUserCredentialDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ExternalUserCredential,
  TEntity
> {
  return {
    entityName: EntityName.ExternalUserCredential,
    synchronizeRelativeTo: [EntityName.ExternalUserCredential],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ExternalUserCredentialDto<string, string>,
      TEntity
    >(
      {
        ...BaseEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          ref: EntityName.User
        },
        type: {
          name: 'type'
        },
        url: {
          name: 'url',
          defaultValue: null
        },
        username: {
          name: 'username',
          defaultValue: null
        },
        password: {
          name: 'password',
          defaultValue: null
        },
        port: {
          name: 'port',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
