import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { PictureFile } from '../PictureFile/types';
import { Picture } from '../Picture/types';
import {
  PictureFileExtension,
  PictureFileType
} from 'common/Types/Entities/PictureFile/PictureFileDto';

@autoinject()
export class PictureFileByActivePictureRevisionService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public getPictureFileToDisplayByPictureId(
    pictureId: string
  ): PictureFile | null {
    let pictureFile = this.getEditedPictureFileByPictureId(pictureId);
    if (!pictureFile) {
      pictureFile = this.getOriginalPictureFileByPictureId(pictureId);
    }
    return pictureFile;
  }

  public getOriginalPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    const revisionId = this.getActiveRevisionIdForPicture(pictureId);

    return this.entityManager.pictureFileRepository.getOriginalPictureFileByRevisionId(
      { pictureId, revisionId }
    );
  }

  public getEditedPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    const revisionId = this.getActiveRevisionIdForPicture(pictureId);

    return this.entityManager.pictureFileRepository.getEditedPictureFileByRevisionId(
      { pictureId, revisionId }
    );
  }

  public getSketchPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    const revisionId = this.getActiveRevisionIdForPicture(pictureId);

    return this.entityManager.pictureFileRepository.getSketchPictureFileByRevisionId(
      { pictureId, revisionId }
    );
  }

  public getCroppedPictureFileByPictureId(
    pictureId: string
  ): PictureFile | null {
    const revisionId = this.getActiveRevisionIdForPicture(pictureId);

    return this.entityManager.pictureFileRepository.getCroppedPictureFileByRevisionId(
      { pictureId, revisionId }
    );
  }

  public getPictureFilesByPictureId(pictureId: string): Array<PictureFile> {
    const revisionId = this.getActiveRevisionIdForPicture(pictureId);

    return this.entityManager.pictureFileRepository.getByRevisionId({
      pictureId,
      revisionId
    });
  }

  public createPictureFileForPicture({
    picture,
    type,
    extension = PictureFileExtension.JPG
  }: {
    picture: Picture;
    type: PictureFileType;
    extension?: PictureFileExtension;
  }): PictureFile {
    const pictureRevisionId = this.getActiveRevisionIdForPicture(picture.id);

    return this.entityManager.pictureFileRepository.createPictureFileForPicture(
      {
        picture,
        type,
        extension,
        pictureRevisionId
      }
    );
  }

  private getActiveRevisionIdForPicture(pictureId: string): string | null {
    const pictureRevision =
      this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
        pictureId
      );
    return pictureRevision ? pictureRevision.id : null;
  }
}
