export enum ProcessTaskAppointmentCalendarWidgetMode {
  SINGLE_DAY = 'singleDay',
  USERS_PER_DAY = 'usersPerDay',
  WEEK = 'week',
  USERS_WEEK = 'usersWeek',
  YEAR = 'year'
}

export const allProcessTaskAppointmentCalendarWidgetModes =
  (): Array<ProcessTaskAppointmentCalendarWidgetMode> =>
    Object.values(ProcessTaskAppointmentCalendarWidgetMode);

export const processTaskAppointmentCalendarWidgetModesForFieldUse =
  (): Array<ProcessTaskAppointmentCalendarWidgetMode> => [
    ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY,
    ProcessTaskAppointmentCalendarWidgetMode.WEEK
  ];

export type TProcessTaskAppointmentCalendarWidgetMode =
  | 'singleDay'
  | 'usersPerDay'
  | 'week'
  | 'usersWeek'
  | 'year';
