import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MomentInput } from 'moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { Question } from '../../classes/EntityManager/entities/Question/types';
import { EditQuestionDialog } from '../../dialogs/edit-question-dialog/edit-question-dialog';

@autoinject()
export class ChecklistQuestionListItem {
  @bindable public question: Question | null = null;

  protected panelOpen = false;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleEditButtonClick without question'
    );

    void EditQuestionDialog.open({
      question: this.question
    });
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleDeleteButtonClick without question'
    );

    const question = this.question;
    void Dialogs.deleteEntityDialog(question).then(() => {
      this.entityManager.questionRepository.delete(question);
    });
  }
}
