import { autoinject } from 'aurelia-framework';

import { AuthenticationService } from '../../services/AuthenticationService';
import { NotificationHelper } from '../../classes/NotificationHelper';

@autoinject()
export class confirmation_link {
  private token = '';

  protected loading = false;

  constructor(private _authenticationService: AuthenticationService) {}

  protected activate(params: { token: string }): void {
    if (params.token) {
      this.token = params.token;
    }
  }

  protected attached(): void {
    // automagically accept the token, when it's given over the url (or the user thought he was funny, entered a code, detached this module and reattached it :^) )
    if (this.token) {
      void this.handleAcceptConfirmationTokenFormSubmit();
    }
  }

  private async handleAcceptConfirmationTokenFormSubmit(): Promise<void> {
    if (this.token.trim() === '') {
      NotificationHelper.notifyDanger('Rücksetzcode muss ausgefüllt werden!');
    } else {
      try {
        this.loading = true;
        await this._authenticationService.acceptConfirmationToken(this.token);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  }
}
