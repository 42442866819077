import { autoinject } from 'aurelia-framework';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityManager } from '../AppEntityManager';
import { Project } from '../Project/types';
import { ThingSection } from '../ThingSection/types';
import { ThingSectionConfigProperty } from './types';

@autoinject()
export class ApplyThingSectionConfigPropertiesService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createThingThingSectionsProperties({
    thingSections,
    thingId
  }: {
    thingSections: Array<ThingSection>;
    thingId: string;
  }): void {
    this.createThingSectionsProperties({
      thingId,
      thingSections,
      location: ThingSectionConfigPropertyLocation.THING,
      createThingSectionPropertyIds: ({ thingSection }) => {
        return {
          thingThingSectionId: thingSection.id,
          ownerUserGroupId: thingSection.ownerUserGroupId
        };
      }
    });
  }

  public createProjectThingSectionsProperties({
    thingSections,
    project
  }: {
    thingSections: Array<ThingSection>;
    project: Project;
  }): void {
    this.createThingSectionsProperties({
      thingId: project.thing,
      thingSections,
      location: ThingSectionConfigPropertyLocation.PROJECT,
      createThingSectionPropertyIds: ({ thingSection }) => {
        return {
          projectThingSectionId: thingSection.id,
          ownerProjectId: project.id,
          ownerUserGroupId: thingSection.ownerUserGroupId
        };
      }
    });
  }

  private createThingSectionsProperties({
    thingSections,
    thingId,
    location,
    createThingSectionPropertyIds
  }: {
    thingSections: Array<ThingSection>;
    thingId: string;
    location: ThingSectionConfigPropertyLocation;
    createThingSectionPropertyIds: (options: {
      thingSection: ThingSection;
    }) => ThingSectionPropertyIds;
  }): void {
    const configProperties =
      this.entityManager.thingSectionConfigPropertyRepository.getOrderedByThingIdAndLocation(
        {
          thingId: thingId,
          location
        }
      );

    for (const thingSection of thingSections) {
      this.createThingSectionProperties({
        configProperties,
        thingSectionPropertyIds: createThingSectionPropertyIds({ thingSection })
      });
    }
  }

  private createThingSectionProperties({
    configProperties,
    thingSectionPropertyIds
  }: {
    configProperties: Array<ThingSectionConfigProperty>;
    thingSectionPropertyIds: ThingSectionPropertyIds;
  }): void {
    for (const configProperty of configProperties) {
      this.entityManager.propertyRepository.create({
        name: configProperty.name,
        type: configProperty.type,
        choices: configProperty.choices,
        order: configProperty.order,
        active: true,
        alwaysVisible: true,
        ...thingSectionPropertyIds
      });
    }
  }
}

type ThingThingSectionPropertyIds = {
  thingThingSectionId: string;
  ownerUserGroupId: string;
};

type ProjectThingSectionPropertyIds = {
  projectThingSectionId: string;
  ownerProjectId: string;
  ownerUserGroupId: string;
};

type ThingSectionPropertyIds =
  | ThingThingSectionPropertyIds
  | ProjectThingSectionPropertyIds;
