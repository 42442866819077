import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import {
  ProcessTaskToProjectDto,
  ProcessTaskToProjectType
} from './ProcessTaskToProjectDto';

export function createProcessTaskToProjectEntityInfo<
  TEntity extends ProcessTaskToProjectDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskToProjectDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskToProject,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskToProject,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskToProjectDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        projectId: {
          name: 'projectId',
          ref: EntityName.Project
        },
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processConfigurationStepId: {
          name: 'processConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        processConfigurationFormId: {
          name: 'processConfigurationFormId',
          ref: EntityName.ProcessConfigurationForm,
          defaultValue: null
        },
        sentByMail: {
          name: 'sentByMail',
          defaultValue: false
        },
        created: {
          name: 'created',
          defaultValue: () => Date.now()
        },
        type: {
          name: 'type',
          defaultValue: ProcessTaskToProjectType.GENERAL
        }
      },
      options.additionalFieldInfos
    )
  };
}
