import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class EntityListItemTextChoices {
  @bindable public textChoices: Array<string> = [];

  @bindable public hiddenTextTk: string =
    'aureliaComponents.entityListItemTextChoices.hiddenText';

  protected hiddenCounter = 0;

  private mainContent: HTMLElement | null = null;

  private isAttached = false;

  private readonly domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateTagHtmlElements();
    this.updateDisplayPropertiesOfTextChoices();
  }

  protected textChoicesChanged(): void {
    if (!this.isAttached) return;
    this.updateTagHtmlElements();
    this.updateDisplayPropertiesOfTextChoices();
  }

  protected handleNewContainerSize(): void {
    if (!this.isAttached) return;
    this.updateDisplayPropertiesOfTextChoices();
  }

  private updateTagHtmlElements(): void {
    assertNotNullOrUndefined(
      this.mainContent,
      'cannot add choice elements to mainContent without mainContent'
    );

    this.mainContent.replaceChildren(
      ...this.textChoices.map((choice) => {
        const newElement = document.createElement('span');
        newElement.classList.add(
          'record-it-badge',
          'entity-list-item-text-choices--Item'
        );
        newElement.innerText = choice;
        return newElement;
      })
    );
  }

  private updateDisplayPropertiesOfTextChoices(): void {
    assertNotNullOrUndefined(
      this.mainContent,
      'cannot update choice elements of mainContent without mainContent'
    );
    const childElements = this.getCurrentChildElements();

    for (const element of childElements) {
      const childBottom = element.getBoundingClientRect().bottom;
      if (!childBottom) {
        continue;
      }
      const threshold = this.mainContent.getBoundingClientRect().bottom;

      if (Math.ceil(childBottom) > Math.floor(threshold)) {
        element.style.display = 'none';
      }
    }
    this.hiddenCounter = childElements.filter(
      (x) => x.style.display === 'none'
    ).length;
  }

  private getCurrentChildElements(): Array<HTMLElement> {
    return Array.from(
      this.domElement.getElementsByClassName(
        'entity-list-item-text-choices--Item'
      )
    ) as Array<HTMLElement>;
  }
}
