import { inject } from 'aurelia-framework';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager)
export class ThingGroupIdAddressValueConverter {
  signals = ['thingGroupModelUpdated'];

  /** @type {AppEntityManager} */
  _entityManager;

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  /**
   * @param {string|null} thingGroupId
   * @returns {string}
   */
  toView(thingGroupId) {
    if (thingGroupId) {
      const thingGroup =
        this._entityManager.thingGroupRepository.getById(thingGroupId);
      if (thingGroup) {
        return ThingGroupHelper.getThingGroupAddressString(
          thingGroup.streetName,
          thingGroup.zip,
          thingGroup.municipality
        );
      }
    }

    return '';
  }
}
