import { computedFrom } from 'aurelia-framework';
import { Observable } from 'rxjs';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { StructureTemplateEntryProperty } from '../../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import {
  createStructureTemplateEntryPropertyDefinitionWidgetConfiguration,
  StructureTemplateEntryPropertyDefinitionWidgetConfiguration
} from '../../../base-property-widget/config/structureTemplateEntryPropertyDefinitionWidgetConfiguration/createStructureTemplateEntryPropertyDefinitionWidgetConfiguration';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export class StructureTemplateEntryPropertyDefinitionHandle
  implements PropertyDefinitionWidgetHandle<StructureTemplateEntryProperty>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: StructureTemplateEntryProperty;
  private readonly canEditValue$: Observable<boolean>;
  private readonly canEditDefinition$: Observable<boolean>;

  private internalCanEditName: boolean = false;
  private internalCanEditType: boolean = false;
  private internalCanEditChoices: boolean = false;
  private internalCanBeDeleted: boolean = false;

  constructor(options: StructureTemplateEntryPropertyDefinitionHandleOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;

    this.canEditValue$ = options.canEditValue$;
    this.canEditDefinition$ = options.canEditDefinition$;
  }

  public subscribe(): Disposable {
    const canEditDefinitionSubscription = this.canEditDefinition$.subscribe(
      (value) => {
        this.internalCanEditName = value;
        this.internalCanEditType = value;
        this.internalCanEditChoices = value;
        this.internalCanBeDeleted = value;
      }
    );

    return {
      dispose: () => {
        canEditDefinitionSubscription.unsubscribe();
      }
    };
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  public setName(name: string | null): void {
    this.property.name = name;
    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.property
    );
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  public setType(type: PropertyType): void {
    this.property.type = type;
    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.property
    );
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  public setChoices(choices: Array<string>): void {
    this.property.choices = choices;
    this.entityManager.structureTemplateEntryPropertyRepository.update(
      this.property
    );
  }

  @computedFrom('internalCanEditName')
  public get canEditName(): boolean {
    return this.internalCanEditName;
  }

  @computedFrom('internalCanEditType')
  public get canEditType(): boolean {
    return this.internalCanEditType;
  }

  @computedFrom('internalCanEditChoices')
  public get canEditChoices(): boolean {
    return this.internalCanEditChoices;
  }

  @computedFrom('internalCanBeDeleted')
  public get canBeDeleted(): boolean {
    return this.internalCanBeDeleted;
  }

  public getPropertyDefinition(): StructureTemplateEntryProperty {
    return this.property;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: false,
      canHaveTableEntries: false,
      allowDefaultValues: true
    };
  }

  public createPropertyWidgetConfiguration(): StructureTemplateEntryPropertyDefinitionWidgetConfiguration | null {
    return createStructureTemplateEntryPropertyDefinitionWidgetConfiguration({
      enabled$: this.canEditValue$
    });
  }
}

export type StructureTemplateEntryPropertyDefinitionHandleOptions = {
  entityManager: AppEntityManager;
  property: StructureTemplateEntryProperty;

  /**
   * This will affect every input except the value input
   * If this emits false and canEditValue emits true, then the value will still be able to be edited
   */
  canEditDefinition$: Observable<boolean>;

  /**
   * This will only affect the value field indepently of canEditDefinition$
   */
  canEditValue$: Observable<boolean>;
};
