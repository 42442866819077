import { bindable, computedFrom } from 'aurelia-framework';

/**
 * A simple component which scales an image to the size of the component.
 * If the image and the component  do not have the same ratio, only a part of the image will be shown
 *
 * You probably also need to set a size (height/width) on this element.
 */
export class ScaledImage {
  /**
   * File path/url of the image.
   */
  @bindable public src: string | null = null;

  /**
   * Whether this picture is disabled, e.g. greyed out.
   */
  @bindable public disabled: boolean = false;

  @computedFrom('src')
  protected get url(): string | null {
    return this.src ? `url("${this.src}")` : null;
  }
}
