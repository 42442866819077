import { DateTimePickerNumberInput } from '../date-time-picker-number-input/date-time-picker-number-input';
import { bindable, autoinject } from 'aurelia-framework';
import _ from 'lodash';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { TimePickerDialog } from '../../../dialogs/time-picker-dialog/time-picker-dialog';

/**
 * @event value-changed
 * @event input-blur
 * @event {DialogClosedEvent} dialog-closed
 * @event {DialogCanceledEvent} dialog-canceled
 */
@autoinject()
export class DateTimePickerTimeInput {
  @bindable()
  public value: Date | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public minutesStepWidth: number = 5;

  private domElement: HTMLElement;

  private hoursValue: string | null = null;
  private minutesValue: string | null = null;
  private isAttached: boolean = false;

  private hoursInput: DateTimePickerNumberInput | null = null;
  private minutesInput: DateTimePickerNumberInput | null = null;
  private timePickerDialogOpened: boolean = false;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public focus(): void {
    assertNotNullOrUndefined(
      this.hoursInput,
      'hoursInput not available in handleInputBlur'
    );
    this.hoursInput.focus();
  }

  public isFocused(): boolean {
    assertNotNullOrUndefined(
      this.hoursInput,
      'hoursInput not available in handleInputBlur'
    );
    assertNotNullOrUndefined(
      this.minutesInput,
      'minutesInput not available in handleInputBlur'
    );

    return this.hoursInput.isFocused() || this.minutesInput.isFocused();
  }

  public openDialog(): void {
    this.timePickerDialogOpened = true;

    let timeSelected = false;

    void TimePickerDialog.open({
      hours: this.value ? this.value.getHours() : 0,
      minutes: this.value ? this.value.getMinutes() : 0,
      minutesTickWidth: this.minutesStepWidth,
      onTimeSelected: (hours, minutes) => {
        this.hoursValue = hours.toString();
        this.minutesValue = minutes.toString();
        this.updateValue();
        timeSelected = true;
      },
      onClosing: () => {
        this.timePickerDialogOpened = false;
        setTimeout(() => {
          // wait for the bindings of the onTimeSelected to propagate
          if (timeSelected) {
            DomEventHelper.fireEvent<DialogClosedEvent>(this.domElement, {
              name: 'dialog-closed',
              detail: null
            });
          } else {
            DomEventHelper.fireEvent<DialogCanceledEvent>(this.domElement, {
              name: 'dialog-canceled',
              detail: null
            });
          }
        }, 0);
      }
    });
  }

  public dialogIsOpened(): boolean {
    return this.timePickerDialogOpened;
  }

  protected attached(): void {
    this.isAttached = true;
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private valueChanged(): void {
    this.updateInternalValues();
  }

  private handleHoursInputValueChanged(): void {
    assertNotNullOrUndefined(
      this.minutesInput,
      'minutesInput not available in handleHoursInputValueChanged'
    );

    if (this.hoursValue && this.hoursValue.length === 2) {
      this.minutesInput.focus();
    }
  }

  private handleMinutesBlur(): void {
    this.minutesValue =
      this.minutesValue != null ? this.roundMinutes(this.minutesValue) : null;
    this.handleInputBlur();
  }

  private handleInputBlur(): void {
    setTimeout(() => {
      if (!this.isAttached || !this.isFocused()) {
        this.updateValue();

        setTimeout(() => {
          this.fireEvent('input-blur');
        }, 0);
      }
    }, 10);
  }

  protected handleOpenTimePickerClick(): void {
    if (!this.enabled) return;
    this.openDialog();
  }

  private updateValue(): void {
    let date: Date | null = null;
    if (this.hoursValue && this.minutesValue) {
      const hours = _.padStart(this.hoursValue, 2, '0');
      const minutes = _.padStart(this.minutesValue, 2, '0');

      date = new Date(`1900-01-01T${hours}:${minutes}:00.000`);
    }

    if (date) {
      if (date.toString() !== 'Invalid Date') {
        this.setValue(date);
      } else {
        this.updateInternalValues();
      }
    } else {
      this.setValue(null);
    }
  }

  private updateInternalValues(): void {
    if (this.value) {
      const date = new Date(this.value);

      const hours = date.getHours().toString();
      const minutes = date.getMinutes().toString();

      this.hoursValue = _.padStart(hours, 2, '0');
      this.minutesValue = _.padStart(minutes, 2, '0');
    } else {
      this.hoursValue = null;
      this.minutesValue = null;
    }
  }

  private setValue(value: Date | null): void {
    if (this.value === value) {
      return;
    }

    if (this.value && value && this.value.getTime() === value.getTime()) {
      return;
    }

    this.value = value;

    setTimeout(() => {
      this.fireEvent('value-changed');
    }, 0);
  }

  private fireEvent(eventName: string): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: eventName,
      detail: null
    });
  }

  private roundMinutes(minutes: string): string {
    const minutesNumber = parseInt(minutes);
    const multiplicator = minutesNumber / this.minutesStepWidth;
    const roundedMinutes = Math.round(multiplicator) * this.minutesStepWidth;
    return roundedMinutes.toString();
  }
}

export type DialogClosedEvent = NamedCustomEvent<'dialog-closed', null>;
export type DialogCanceledEvent = NamedCustomEvent<'dialog-canceled', null>;
