import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { StateConfig } from '../multistate-checkbox/multistate-checkbox';

/**
 * @event {CustomCheckboxCheckedChangedEvent} checked-changed
 *
 * @attribute data-color - color of the checkmark, default color is ??
 * @attribute data-bg-color - color of the checkbox background, default color is orange
 * @attribute data-label-style - 'bold'
 * @attribute data-narrow - give the checkbox a tighter styling, useful for e.g. when display the checkbox without a label
 * @attribute data-border - allows to set a different border color
 * @attribute data-border-light - makes border of checkbox thinner (only 1px)
 * @attribute data-fill-label - makes the label fill the width, allows clicking on the whole area
 * @attribute data-align-left - aligns the checkbox so it touches the left border of the container
 */
@autoinject()
export class CustomCheckbox {
  @bindable public checked = false;
  @bindable public disabled = false;

  /** @deprecated use labelTk instead */
  @bindable public label = '';

  @bindable public labelTk = '';

  @bindable public dataColor?: string;
  @bindable public dataBgColor?: string;
  @bindable public dataBorder?: string;

  @bindable public dataLabelStyle?: 'bold';
  @bindable public dataNarrow: any;
  @bindable public dataBorderLight: any;
  @bindable public dataFillLabel: any;
  @bindable public dataAlignLeft: any;

  private domElement: HTMLElement;

  protected checkboxStates: Array<StateConfig<boolean>> = [];

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.updateCheckboxStates();
  }

  protected dataColorChanged(): void {
    this.updateCheckboxStates();
  }

  protected dataBgColorChanged(): void {
    this.updateCheckboxStates();
  }

  protected dataBorderChanged(): void {
    this.updateCheckboxStates();
  }

  protected handleCheckboxChange(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'checked-changed',
        bubbles: true,
        detail: {
          checked: this.checked
        }
      });
    }, 0);
  }

  private updateCheckboxStates(): void {
    this.checkboxStates = [
      {
        name: 'unchecked',
        value: false,
        borderColor: this.dataBorder
      },
      {
        name: 'checked',
        value: true,
        borderColor: this.dataBorder,
        iconConfig: {
          iconType: 'far',
          iconName: 'fa-check',
          color: this.dataColor,
          backgroundColor: this.dataBgColor
        }
      }
    ];
  }
}

export type CustomCheckboxCheckedChangedEvent = NamedCustomEvent<
  'checked-changed',
  { checked: boolean }
>;
