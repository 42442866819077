import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStep } from '../../../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskRecurringAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { EventDispatcher } from '../../../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import {
  DaySpecificCalendarEntry,
  NormalCalendarEntry
} from '../CalendarEntry';

export abstract class CalendarEntryDataSourceStrategy {
  private readonly dataChangedDispatcher = new EventDispatcher<{
    dataChanged: void;
  }>();

  protected readonly entityManager: AppEntityManager;

  constructor(options: { entityManager: AppEntityManager }) {
    this.entityManager = options.entityManager;
  }

  public registerOnDataChanged(onDataChanged: () => void): Disposable {
    return this.dataChangedDispatcher.addDisposableEventListener(
      'dataChanged',
      onDataChanged
    );
  }

  public abstract subscribe(): Disposable;

  public abstract getCalendarEntries(
    options: GetCalendarEntriesOptions
  ): Promise<Array<DaySpecificCalendarEntry>>;

  /**
   * call this whenever the underlaying data changed, e.g. an entity in the EntityManager
   */
  protected dataChanged(): void {
    this.dataChangedDispatcher.dispatchEvent('dataChanged', undefined);
  }

  protected getAppointmentColors({
    appointmentId,
    userId,
    processConfigurationStepId,
    useUserColors
  }: {
    appointmentId: string;
    userId: string;
    processConfigurationStepId: string | null;
    useUserColors: boolean;
  }): CalendarEntryColors {
    const appointment =
      this.entityManager.processTaskAppointmentRepository.getById(
        appointmentId
      );
    if (appointment && this.hasColors(appointment)) {
      return this.getEntityColors(appointment);
    } else if (useUserColors) {
      return this.getUserColors(userId);
    } else {
      return this.processConfigurationStepColors(processConfigurationStepId);
    }
  }

  protected getRecurringAppointmentColors({
    recurringAppointmentId,
    userId,
    useUserColors
  }: {
    recurringAppointmentId: string;
    userId: string;
    useUserColors: boolean;
  }): CalendarEntryColors {
    const appointment =
      this.entityManager.processTaskRecurringAppointmentRepository.getById(
        recurringAppointmentId
      );
    if (appointment && this.hasColors(appointment)) {
      return this.getEntityColors(appointment);
    } else if (useUserColors) {
      return this.getUserColors(userId);
    } else {
      return {
        color: null,
        secondaryColor: null
      };
    }
  }

  private hasColors({
    color,
    secondaryColor
  }: ProcessTaskAppointment | ProcessTaskRecurringAppointment): boolean {
    return !!color || !!secondaryColor;
  }

  private getEntityColors({
    color,
    secondaryColor
  }:
    | ProcessTaskAppointment
    | ProcessTaskRecurringAppointment): CalendarEntryColors {
    return {
      color,
      secondaryColor
    };
  }

  private processConfigurationStepColors(
    processConfigurationStepId: string | null
  ): CalendarEntryColors {
    let step: ProcessConfigurationStep | null = null;
    if (processConfigurationStepId) {
      step = this.entityManager.processConfigurationStepRepository.getById(
        processConfigurationStepId
      );
    }

    const colors: CalendarEntryColors = { color: null, secondaryColor: null };

    if (step) {
      colors.color = step.color;
    }

    return colors;
  }

  private getUserColors(userId: string | null): CalendarEntryColors {
    const colors: CalendarEntryColors = { color: null, secondaryColor: null };
    if (!userId) return colors;

    const user = this.entityManager.userRepository.getById(userId);

    if (user) {
      colors.color = user.color;
      colors.secondaryColor = user.secondaryColor;
    }

    return colors;
  }
}

export type GetCalendarEntriesOptions = {
  useUserColors: boolean;
  filter: CalendarEntriesFilter;
};

export type CalendarEntriesFilter = {
  userIds: Array<string>;
  date: {
    dateFrom: Date;
    dateTo: Date;
  };
};

export type CalendarEntryColors = Pick<
  NormalCalendarEntry,
  'color' | 'secondaryColor'
>;
