import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { CoordinateHelper } from '../../../CoordinateHelper';
import { ThingGroup } from './types';

export class ThingGroupUtils {
  private constructor() {}

  public static sortOptions: ThingGroupSortOptions = {
    name: {
      name: 'Name',
      sortFunction: (a, b) => {
        const aName = a.name || '';
        const bName = b.name || '';
        return aName.localeCompare(bName);
      }
    },
    distance: {
      name: 'Entfernung',
      sortFunction: (a, b) => {
        // setting a really high default value to treat thing groups without a position as far away

        const aDistance = ThingGroupUtils.calculateDistanceFromThingGroup(a);
        const aDistanceWithFallback =
          aDistance !== null ? aDistance : Number.MAX_SAFE_INTEGER;
        const bDistance = ThingGroupUtils.calculateDistanceFromThingGroup(b);
        const bDistanceWithFallback =
          bDistance !== null ? bDistance : Number.MAX_SAFE_INTEGER;

        return aDistanceWithFallback - bDistanceWithFallback;
      }
    }
  };

  public static filterFunction = (thingGroup: ThingGroup): string => {
    const stringParts = [];

    if (thingGroup.name) stringParts.push(thingGroup.name);

    if (thingGroup.streetName) stringParts.push(thingGroup.streetName);
    if (thingGroup.zip) stringParts.push(thingGroup.zip);
    if (thingGroup.municipality) stringParts.push(thingGroup.municipality);

    if (thingGroup.note) stringParts.push(thingGroup.note);

    return stringParts.join(' ');
  };

  private static calculateDistanceFromThingGroup(
    thingGroup: ThingGroup
  ): number | null {
    if (!this.canCalculateDistanceFromThingGroup(thingGroup)) {
      return null;
    }

    const clientCoordinates = CoordinateHelper.getClientCoordinates();
    return CoordinateHelper.calculateDistance(
      // @ts-ignore - values can't be null here anymore
      clientCoordinates.longitude,
      clientCoordinates.latitude,
      thingGroup.longitude,
      thingGroup.latitude
    );
  }

  private static canCalculateDistanceFromThingGroup(
    thingGroup: ThingGroup
  ): boolean {
    const coordinates = CoordinateHelper.getClientCoordinates();

    return (
      !!thingGroup.latitude &&
      !!thingGroup.longitude &&
      !!coordinates.latitude &&
      !!coordinates.longitude
    );
  }
}

export type ThingGroupSortOptions = {
  name: SorterSortOption<ThingGroup>;
  distance: SorterSortOption<ThingGroup>;
};
