import {
  FieldConfigs,
  ValueType,
  ContextSpecificFieldConfigs
} from './ExpressionEditorScopeCreationUtils';

export class ExpressionEditorScopeEvaluationUtils {
  public static async convertToPlainObject<TFieldConfigs extends FieldConfigs>(
    configs: ContextSpecificFieldConfigs<TFieldConfigs>
  ): Promise<Record<string, any>> {
    const plainObject: Record<string, unknown> = {};
    for (const config of Object.values(configs)) {
      if (config.valueType === ValueType.NESTED) {
        plainObject[config.name] = config.contextSpecific
          ? await this.convertToPlainObject(
              config.contextSpecific as ContextSpecificFieldConfigs<FieldConfigs>
            )
          : null;
      } else {
        plainObject[config.name] = await config.contextSpecific.getValue();
      }
    }
    return plainObject;
  }
}
