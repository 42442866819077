/**
 * Service for starting an external route planner for given coordinates.
 */
export class RoutePlannerService {
  /**
   * Call the external route planning program appropriate for the current device.
   */
  public startRoute(latitude: number, longitude: number): void {
    this.startGoogleMapsRoute(latitude, longitude, {
      zoom: 77,
      satellite: true
    });
  }

  private startGoogleMapsRoute(
    latitude: number,
    longitude: number,
    options: GoogleMapsOptions
  ): void {
    const zoom = options.satellite
      ? `${this.decode(options.zoom)}m`
      : `${this.decode(options.zoom)}z`;
    const suffix = options.satellite ? '/data=!3m1!1e3' : '';
    const loc = `${this.decode(latitude)},${this.decode(longitude)}`;
    const href = `https://www.google.at/maps/dir//${loc}/@${loc},${zoom}${suffix}`;
    const googleMapsWindow = window.open(href);
    if (!googleMapsWindow || googleMapsWindow.closed) {
      // Browser does not allow popups, open in the current window instead
      window.location.href = href;
    }
  }

  private decode(arg: any): string {
    return decodeURIComponent(`${arg}`);
  }
}

type GoogleMapsOptions = {
  /**
   * Level of zoom for the map.
   * For satellite view, the larger the zoom, the less the map is zoomed in.
   * For map view, the larger the zoom, the more the map is zoomed in.
   */
  zoom: number;
  /**
   * If true, uses satellite view.
   * If false, uses normal map view.
   */
  satellite?: boolean;
};
