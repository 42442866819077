import { BaseAppointmentCalendarEntryDayWidgetTexts } from 'common/EndpointTypes/OperationsEndpointsTypes';

export type CalendarEntry = NormalCalendarEntry | RecurringCalendarEntry;

export type DaySpecificCalendarEntry = {
  calendarEntry: CalendarEntry;
  originalMultiDayData: {
    startTimestamp: number;
    endTimestamp: number;
    fullDuration: number;
  } | null;
};

export enum CalendarEntryType {
  NORMAL = 'normal',
  RECURRING = 'recurring'
}

export type CalendarEntryBase = {
  name: string | null;
  id: string;
  processConfigurationStepId: string | null;
  processTaskId: string;
  startHour: number;
  startMinute: number;
  startTimestamp: number;
  endTimestamp: number;
  duration: number;
  color: string | null;
  secondaryColor: string | null;
  dayWidgetTexts: BaseAppointmentCalendarEntryDayWidgetTexts;
};

/**
 * A calendar entry calculated from one processTaskAppointmentToUser.
 */
export type NormalCalendarEntry = CalendarEntryBase & {
  type: CalendarEntryType.NORMAL;
  address: string;
  processTaskNote: string | null;
  processTaskAppointmentNote: string | null;
  done: boolean;
};

/**
 * A calendar entry calculated from a processTaskRecurringAppointment.
 */
export type RecurringCalendarEntry = CalendarEntryBase & {
  type: CalendarEntryType.RECURRING;
  processTaskGroupId: string;
  userGroupId: string;
};
