import { Entry } from '../classes/EntityManager/entities/Entry/types';
import { Person } from '../classes/EntityManager/entities/Person/types';
import { ProcessConfiguration } from '../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationStep } from '../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTask } from '../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { Project } from '../classes/EntityManager/entities/Project/types';
import { StructureTemplate } from '../classes/EntityManager/entities/StructureTemplate/types';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { ProjectQuestionCategory } from '../classes/EntityManager/entities/ProjectQuestionCategory/types';
import { ProjectQuestion } from '../classes/EntityManager/entities/ProjectQuestion/types';

export class ActiveEntitiesService {
  private readonly propertyBinder =
    new PropertyBinder<ActiveEntitiesServicePropertyBinderConfig>();

  public registerBinding<
    TName extends PropertyBinderName<ActiveEntitiesServicePropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<
      ActiveEntitiesServicePropertyBinderConfig,
      TName
    >
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public setActiveThing(thing: Thing | null): void {
    this.propertyBinder.setValue('activeThing', thing);
  }

  public setActiveProject(project: Project | null): void {
    this.propertyBinder.setValue('activeProject', project);
  }

  public setActiveEntry(entry: Entry | null): void {
    this.propertyBinder.setValue('activeEntry', entry);
  }

  public setActivePerson(person: Person | null): void {
    this.propertyBinder.setValue('activePerson', person);
  }

  public setActiveProcessConfiguration(
    processConfiguration: ProcessConfiguration | null
  ): void {
    this.propertyBinder.setValue(
      'activeProcessConfiguration',
      processConfiguration
    );
  }

  public setActiveProcessConfigurationStep(
    processConfigurationStep: ProcessConfigurationStep | null
  ): void {
    this.propertyBinder.setValue(
      'activeProcessConfigurationStep',
      processConfigurationStep
    );
  }

  public setActiveProcessTask(processTask: ProcessTask | null): void {
    this.propertyBinder.setValue('activeProcessTask', processTask);
  }

  public setActiveProcessTaskAppointment(
    processTaskAppointment: ProcessTaskAppointment | null
  ): void {
    this.propertyBinder.setValue(
      'activeProcessTaskAppointment',
      processTaskAppointment
    );
  }

  public setActiveStructureTemplate(
    structureTemplate: StructureTemplate | null
  ): void {
    this.propertyBinder.setValue('activeStructureTemplate', structureTemplate);
  }

  public setActiveChecklistInspection(project: Project | null): void {
    this.propertyBinder.setValue('activeChecklistInspection', project);
  }

  public setActiveChecklistInspectionCategory(
    category: ProjectQuestionCategory | null
  ): void {
    this.propertyBinder.setValue('activeChecklistInspectionCategory', category);
  }

  public setActiveChecklistInspectionQuestion(
    question: ProjectQuestion | null
  ): void {
    this.propertyBinder.setValue('activeChecklistInspectionQuestion', question);
  }
}

type ActiveEntitiesServicePropertyBinderConfig = {
  activeThing: Thing | null;
  activeProject: Project | null;
  activeEntry: Entry | null;
  activePerson: Person | null;
  activeProcessConfiguration: ProcessConfiguration | null;
  activeProcessConfigurationStep: ProcessConfigurationStep | null;
  activeProcessTask: ProcessTask | null;
  activeProcessTaskAppointment: ProcessTaskAppointment | null;
  activeStructureTemplate: StructureTemplate | null;
  activeChecklistInspection: Project | null;
  activeChecklistInspectionCategory: ProjectQuestionCategory | null;
  activeChecklistInspectionQuestion: ProjectQuestion | null;
};
