import { assertNotNullOrUndefined } from 'common/Asserts';

import { DialogIconType } from 'common/Enums/DialogIconType';

import { GlobalCustomDialog } from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { environment } from '../../environment';
import { DeviceInfoHelper } from '../DeviceInfoHelper';
import { Dialogs } from '../Dialogs';
import { Disposable } from '../Utils/DisposableContainer';
import {
  AbstractNfcHelperStrategy,
  UidScanCallback,
  UserAbortedScanError
} from './AbstractNfcHelperStrategy';

/** Simulate a uFR device in dev environments */
if (
  environment.env !== 'prod' &&
  !window.uFR_Request &&
  !DeviceInfoHelper.isApp()
) {
  window.uFR_Request = <T extends UfrCommand>(
    command: T
  ): Promise<UfrCommandToResponseMap[T]> => {
    switch (command) {
      case UfrCommand.READER_OPEN:
        return Promise.resolve({
          Status: 'fakeStatus'
        } as UfrCommandToResponseMap[T]);
      case UfrCommand.READER_CLOSE:
        return Promise.resolve({
          Status: 'fakeStatus'
        } as UfrCommandToResponseMap[T]);
      case UfrCommand.GET_CARD_ID:
        // eslint-disable-next-line no-alert
        const cardId = prompt('DEVELOPER: Enter card Id');
        if (cardId !== null) {
          return Promise.resolve({
            CardSerial: cardId,
            CardType: 'fakeType',
            Status: 'fakeStatus'
          });
        } else {
          return Promise.reject('User aborted scan');
        }
      default:
        return Promise.reject('Unknown UfrCommand');
    }
  };
}

export class UfrNfcHelperStrategy extends AbstractNfcHelperStrategy {
  private static TRANSLATION_KEY_PREFIX = 'classes.Nfc.UfrNfcHelperStrategy';

  public async isEnabled(): Promise<boolean> {
    if (window.uFR_Request) return true;
    return false;
  }

  public scanSingleUID(callback: UidScanCallback): Disposable {
    const uFR_Request = window.uFR_Request;
    assertNotNullOrUndefined(
      uFR_Request,
      'ufr nfc reader extension is not available'
    );

    let intervalId: number | null = null;
    let disposed = false;

    void uFR_Request(UfrCommand.READER_OPEN).then(() => {
      if (disposed) return;

      intervalId = window.setInterval(() => {
        void uFR_Request(UfrCommand.GET_CARD_ID).then((response) => {
          if (response.CardSerial) {
            if (callback(null, response.CardSerial)) {
              Dialogs.timedSuccessDialogTk(
                `${UfrNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.successfullyScannedTitle`,
                `${UfrNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.successfullyScannedText`
              );
            }

            disposeFunction();
          }
        });
      }, 750);

      void GlobalCustomDialog.open({
        titleTk: `${UfrNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.scanDialogTitle`,
        textTk: `${UfrNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.scanDialogText`,
        buttons: [
          {
            textTk: `${UfrNfcHelperStrategy.TRANSLATION_KEY_PREFIX}.abortScanButton`,
            value: 'abortScan'
          }
        ],
        icon: DialogIconType.WARNING
      }).then((value) => {
        if (value.value === 'abortScan') {
          callback(new UserAbortedScanError(), null);
          disposeFunction();
        }
      });
    });

    const disposeFunction = (): void => {
      if (intervalId != null) window.clearInterval(intervalId);
      void uFR_Request(UfrCommand.READER_CLOSE);
      disposed = true;
    };

    return {
      dispose: disposeFunction
    };
  }
}

enum UfrCommand {
  READER_OPEN = 'ReaderOpen',
  READER_CLOSE = 'ReaderClose',
  GET_CARD_ID = 'GetCardId'
}
