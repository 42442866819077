import { bindable, observable, autoinject } from 'aurelia-framework';

import { Utils } from '../../classes/Utils/Utils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { FloatingLabelInput } from '../../inputComponents/floating-label-input/floating-label-input';
import { NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event set-new-password-button-clicked fired after the "set new password" button has been clicked.
 */
@autoinject()
export class ChangePasswordPanel {
  @bindable public warningText = '';
  @bindable public loading = false;

  @bindable public useOldPasswordField = true;

  @bindable public passwordVerifier:
    | ((
        oldPassword: string,
        newPassword1: string,
        newPassword2: string
      ) => PasswordVerifierResult)
    | null = null;

  @observable protected oldPassword = '';
  @observable protected newPassword1 = '';
  @observable protected newPassword2 = '';

  protected passwordFieldsAreValid = false;

  protected oldPasswordInputErrorTextTk = '';
  protected newPassword1InputErrorTextTk = '';
  protected newPassword2InputErrorTextTk = '';

  protected changePasswordPanelVisible = false;
  protected showPasswords = false;

  protected oldPasswordFloatingLabelInputViewModel: FloatingLabelInput | null =
    null;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public closeChangePasswordPanel(): void {
    this.changePasswordPanelVisible = false;
  }

  public resetFields(): void {
    this.oldPassword = '';
    this.newPassword1 = '';
    this.newPassword2 = '';
    this.oldPasswordInputErrorTextTk = '';
    this.newPassword1InputErrorTextTk = '';
    this.newPassword2InputErrorTextTk = '';
  }

  protected oldPasswordChanged(): void {
    this.handlePasswordFieldsChanged();
  }

  protected newPassword1Changed(): void {
    this.handlePasswordFieldsChanged();
  }

  protected newPassword2Changed(): void {
    this.handlePasswordFieldsChanged();
  }

  protected handlePasswordFieldsChanged(): void {
    const passwordVerifierResult = this.passwordVerifier
      ? this.passwordVerifier(
          this.oldPassword,
          this.newPassword1,
          this.newPassword2
        )
      : this.defaultPasswordVerifier(
          this.oldPassword,
          this.newPassword1,
          this.newPassword2
        );
    this.passwordFieldsAreValid = passwordVerifierResult.passwordFieldsAreValid;
    this.oldPasswordInputErrorTextTk =
      passwordVerifierResult.oldPasswordInputErrorTextTk || '';
    this.newPassword1InputErrorTextTk =
      passwordVerifierResult.newPassword1InputErrorTextTk || '';
    this.newPassword2InputErrorTextTk =
      passwordVerifierResult.newPassword2InputErrorTextTk || '';
  }

  protected defaultPasswordVerifier(
    oldPassword: string,
    newPassword1: string,
    newPassword2: string
  ): PasswordVerifierResult {
    if (newPassword1 || newPassword2) {
      if (!oldPassword && this.useOldPasswordField) {
        return {
          passwordFieldsAreValid: false,
          oldPasswordInputErrorTextTk:
            'aureliaComponents.changePasswordPanel.oldPasswordMissingInputErrorMessage',
          newPassword1InputErrorTextTk: null,
          newPassword2InputErrorTextTk: null
        };
      }
      if (!Utils.validatePassword(newPassword1)) {
        return {
          passwordFieldsAreValid: false,
          oldPasswordInputErrorTextTk: null,
          newPassword1InputErrorTextTk:
            'aureliaComponents.changePasswordPanel.noValidPasswordInputErrorMessage',
          newPassword2InputErrorTextTk: null
        };
      }
      if (newPassword2 && newPassword1 !== newPassword2) {
        return {
          passwordFieldsAreValid: false,
          oldPasswordInputErrorTextTk: null,
          newPassword1InputErrorTextTk: null,
          newPassword2InputErrorTextTk:
            'aureliaComponents.changePasswordPanel.noMatchingPasswordsInputErrorMessage'
        };
      }
    }
    return {
      passwordFieldsAreValid: true,
      oldPasswordInputErrorTextTk: null,
      newPassword1InputErrorTextTk: null,
      newPassword2InputErrorTextTk: null
    };
  }

  protected showChangePasswordPanel(): void {
    this.changePasswordPanelVisible = true;
    setTimeout(() => {
      this.focusOldPasswordField();
    }, 0);
  }

  protected handleCancelButtonClick(): void {
    this.resetFields();
    this.closeChangePasswordPanel();
  }

  protected handleSetNewPasswordClick(): void {
    DomEventHelper.fireEvent<SetNewPasswordClickedEvent>(this.domElement, {
      name: 'set-new-password-button-clicked',
      detail: {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword1
      }
    });
  }

  protected handleToggleShowPasswordsClick(): void {
    this.showPasswords = !this.showPasswords;
  }

  private focusOldPasswordField(): void {
    if (this.oldPasswordFloatingLabelInputViewModel)
      this.oldPasswordFloatingLabelInputViewModel.focus();
  }
}

export type SetNewPasswordEventDetail = {
  oldPassword: string;
  newPassword: string;
};

export type PasswordVerifierResult = {
  passwordFieldsAreValid: boolean;
  oldPasswordInputErrorTextTk: string | null;
  newPassword1InputErrorTextTk: string | null;
  newPassword2InputErrorTextTk: string | null;
};

export type SetNewPasswordClickedEvent = NamedCustomEvent<
  'set-new-password-button-clicked',
  SetNewPasswordEventDetail
>;
