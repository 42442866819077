import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { ThingCreationService } from '../../classes/EntityManager/entities/Thing/ThingCreationService';

@autoinject()
export class CreateThingDialog {
  public static async open(options: CreateThingDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private static TEMPORARY_GROUP_NAME = 'createThingDialog';

  private onDialogClosed: OnDialogClosedCallback | null = null;
  private thing: Thing | null = null;

  protected showThingGroupSelect: boolean = false;

  protected dialog: RecordItDialog | null = null;

  protected readonly CreateThingDialog = CreateThingDialog;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly thingCreationService: ThingCreationService
  ) {}

  public open(options: CreateThingDialogOptions): void {
    this.thing = this.thingCreationService.create({
      ownerUserGroupId: options.userGroupId,
      usergroup: options.userGroupId,
      temporaryGroupName: CreateThingDialog.TEMPORARY_GROUP_NAME,
      thingGroupId: options.thingGroupId,
      shadowEntity: true
    });

    this.onDialogClosed = options.onDialogClosed;
    // only enable/show the the thing group select if we aren't creating a thing specific to a group
    this.showThingGroupSelect = !options.thingGroupId;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const thing = this.thing;

    this.onDialogClosed = null;
    this.thing = null;
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateThingDialog.TEMPORARY_GROUP_NAME
    );

    if (!thing?.shadowEntity) {
      onClosed?.(thing);
    }
  }

  protected handleAcceptButtonClicked(): void {
    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      CreateThingDialog.TEMPORARY_GROUP_NAME
    );
  }

  protected handleThingChanged(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't CreateThingDialog.handleThingChanged without thing"
    );

    this.entityManager.thingRepository.update(this.thing);
  }
}

export type CreateThingDialogOptions = {
  userGroupId: string;
  thingGroupId: string | null;
  onDialogClosed: OnDialogClosedCallback | null;
};

export type OnDialogClosedCallback = (createdThing: Thing | null) => void;
