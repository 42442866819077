import { autoinject } from 'aurelia-framework';

/**
 * Groups together content with a title and subtitle, used for groups of comments.
 *
 * Displays the title and subtitle on the left, the content on the right.
 *
 * If you want to display a list of defect entries directly, use defect-entry-list instead
 * which simplifies splitting up defect entries into entry groups.
 *
 * @slot title - The first line on the left side. Text will be styled bold.
 * @slot subtitle - The second line on the left side.
 * @slot default - Content on the right.
 */
@autoinject()
export class DefectGroup {}
