import { Vector } from 'common/Geometry/Vector';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ElementPositionInformation } from '../../../../classes/Geometry/ElementPositionInformation';
import {
  HandleDragOptions,
  RepositionableElementDraggingHandler
} from '../RepositionableElementDraggingHandler';

export class RepositionableElementResizeDraggingHandler
  implements RepositionableElementDraggingHandler
{
  private readonly fixedRatio: number | null;
  private readonly direction: string;
  private readonly originalElementPositionInfo: ElementPositionInformation;
  private readonly initialPointerPosition: Vector;

  constructor(options: RepositionableElementResizeDraggingHandlerOptions) {
    this.fixedRatio = options.fixedRatio;
    this.direction = options.direction;
    this.originalElementPositionInfo = new ElementPositionInformation(
      options.element
    );
    this.initialPointerPosition = options.initialPointerPosition;
  }

  public handleDrag({ pointerPosition }: HandleDragOptions): ResizeDragResult {
    const sizeLimitedDiffs = this.getSizeLimitedDiffs({ pointerPosition });

    const dragResult = this.calculateDragResultForDirection({
      sizeLimitedDiffs
    });

    if (this.fixedRatio && dragResult) {
      this.capHeightToRatio(dragResult, this.fixedRatio);
    }

    return dragResult;
  }

  private getSizeLimitedDiffs({
    pointerPosition
  }: {
    pointerPosition: Vector;
  }): SizeLimitedDiffs {
    const pointerVector = this.getPointerMoveVector(pointerPosition);
    const rotatedPointerVector = pointerVector
      .clone()
      .rotate(this.originalElementPositionInfo.rotation * -1);
    const info = this.originalElementPositionInfo;

    return {
      positiveSizeLimitedDiff: {
        height:
          rotatedPointerVector.getY() < info.height
            ? rotatedPointerVector.getY()
            : info.height,
        width:
          rotatedPointerVector.getX() < info.width
            ? rotatedPointerVector.getX()
            : info.width
      },
      negativeSizeLimitedDiff: {
        height:
          rotatedPointerVector.getY() > info.height * -1
            ? rotatedPointerVector.getY()
            : info.height * -1,
        width:
          rotatedPointerVector.getX() > info.width * -1
            ? rotatedPointerVector.getX()
            : info.width * -1
      }
    };
  }

  private calculateDragResultForDirection({
    sizeLimitedDiffs
  }: {
    sizeLimitedDiffs: SizeLimitedDiffs;
  }): ResizeDragResult {
    const getDirectionInfo = getDirectionInfoByDirection[this.direction];
    assertNotNullOrUndefined(
      getDirectionInfo,
      `no getDirectionInfo found for direction "${this.direction}"`
    );
    const directionInfo = getDirectionInfo({ sizeLimitedDiffs });

    const topLeftMoveVector = Vector.createHtmlVector(
      directionInfo.topLeftMoveX,
      directionInfo.topLeftMoveY
    );
    topLeftMoveVector.rotate(this.originalElementPositionInfo.rotation);
    const newTopLeftPoint = this.originalElementPositionInfo.topLeftPoint
      .clone()
      .addVector(topLeftMoveVector);

    return {
      top: newTopLeftPoint.getY(),
      left: newTopLeftPoint.getX(),
      width:
        this.originalElementPositionInfo.width + directionInfo.widthModificator,
      height:
        this.originalElementPositionInfo.height +
        directionInfo.heightModificator
    };
  }

  private capHeightToRatio(dragResult: ResizeDragResult, ratio: number): void {
    const parts = this.direction.split('-');
    const verticalDirection = parts[0];

    const newHeight = dragResult.width / ratio;
    const oldHeight = dragResult.height;

    dragResult.height = newHeight;
    if (verticalDirection === 'top') {
      const heightDiff = Vector.createHtmlVector(0, oldHeight - newHeight);
      heightDiff.rotate(this.originalElementPositionInfo.rotation);

      const positionVector = Vector.createHtmlVector(
        dragResult.left,
        dragResult.top
      );
      positionVector.addVector(heightDiff);
      dragResult.top = positionVector.getY();
      dragResult.left = positionVector.getX();
    }
  }

  private getPointerMoveVector(pointerPosition: Vector): Vector {
    return Vector.createHtmlVector(
      pointerPosition.getX() - this.initialPointerPosition.getX(),
      pointerPosition.getY() - this.initialPointerPosition.getY()
    );
  }
}

const getDirectionInfoByDirection: Record<string, GetDirectionInfo> = {
  'top-left': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: sizeLimitedDiffs.positiveSizeLimitedDiff.width,
    topLeftMoveY: sizeLimitedDiffs.positiveSizeLimitedDiff.height,
    widthModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.width * -1,
    heightModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.height * -1
  }),
  'top-middle': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: 0,
    topLeftMoveY: sizeLimitedDiffs.positiveSizeLimitedDiff.height,
    widthModificator: 0,
    heightModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.height * -1
  }),
  'top-right': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: 0,
    topLeftMoveY: sizeLimitedDiffs.positiveSizeLimitedDiff.height,
    widthModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.width,
    heightModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.height * -1
  }),
  'right-middle': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: 0,
    topLeftMoveY: 0,
    widthModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.width,
    heightModificator: 0
  }),
  'bottom-right': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: 0,
    topLeftMoveY: 0,
    widthModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.width,
    heightModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.height
  }),
  'bottom-middle': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: 0,
    topLeftMoveY: 0,
    widthModificator: 0,
    heightModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.height
  }),
  'bottom-left': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: sizeLimitedDiffs.positiveSizeLimitedDiff.width,
    topLeftMoveY: 0,
    widthModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.width * -1,
    heightModificator: sizeLimitedDiffs.negativeSizeLimitedDiff.height
  }),
  'left-middle': ({ sizeLimitedDiffs }) => ({
    topLeftMoveX: sizeLimitedDiffs.positiveSizeLimitedDiff.width,
    topLeftMoveY: 0,
    widthModificator: sizeLimitedDiffs.positiveSizeLimitedDiff.width * -1,
    heightModificator: 0
  })
};

export type RepositionableElementResizeDraggingHandlerOptions = {
  element: HTMLElement;

  /**
   * 0/null will disable this feature, any number will enable it, if you have enabled it, make sure to not use `*-middle` directions, because they don't support it
   *
   * fixedRatio = width / height
   */
  fixedRatio: number | null;

  /**
   * is the corner which got grabbed, possible values: top-left, top-middle, top-right, right-middle, bottom-right, bottom-middle, bottom-left, left-middle
   */
  direction: string;

  initialPointerPosition: Vector;
};

type GetDirectionInfo = (options: {
  sizeLimitedDiffs: SizeLimitedDiffs;
}) => DirectionInfo;

type DirectionInfo = {
  topLeftMoveX: number;
  topLeftMoveY: number;
  widthModificator: number;
  heightModificator: number;
};

type ResizeDragResult = {
  top: number;
  left: number;
  width: number;
  height: number;
};

type SizeLimitedDiffs = {
  positiveSizeLimitedDiff: { width: number; height: number };
  negativeSizeLimitedDiff: { width: number; height: number };
};
