import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskAppointmentToProcessTaskDeviceEntityInfo } from 'common/Types/Entities/ProcessTaskAppointmentToProcessTaskDevice/createProcessTaskAppointmentToProcessTaskDeviceEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskAppointmentToProcessTaskDevice } from './types';

export const processTaskAppointmentToProcessTaskDeviceEntityInfo =
  createProcessTaskAppointmentToProcessTaskDeviceEntityInfo<ProcessTaskAppointmentToProcessTaskDevice>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
