import { autoinject } from 'aurelia-framework';

import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

import { ValueComputer } from '../ValueComputer';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { PermissionHelper } from '../../../classes/PermissionHelper';

@autoinject()
export class LockedByFinishedAppointmentsProjectIdsComputer extends ValueComputer<
  Record<string, never>,
  Set<string>
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToMultipleModelChanges(
      [EntityName.ProcessTaskToProject, EntityName.ProcessTaskAppointment],
      () => {
        invokeCompute();
      }
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(invokeCompute)
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): Set<string> {
    const currentUser = this.currentUserService.getCurrentUser();

    const isOperationsOfficeUser = currentUser
      ? PermissionHelper.userHasPermission(currentUser, 'canUseOperations')
      : false;

    if (isOperationsOfficeUser) return new Set();

    const processTaskToProjects =
      this.entityManager.processTaskToProjectRepository.getAll();

    const set = new Set<string>();

    for (const processTaskToProject of processTaskToProjects) {
      if (
        processTaskToProject.type !== ProcessTaskToProjectType.FORM ||
        !processTaskToProject.processTaskAppointmentId
      )
        continue;

      const processTaskAppointment =
        this.entityManager.processTaskAppointmentRepository.getById(
          processTaskToProject.processTaskAppointmentId
        );

      if (processTaskAppointment?.finishedAt)
        set.add(processTaskToProject.projectId);
    }

    return set;
  }
}
