import { bindable, inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event value-changed
 */
@inject(SubscriptionManagerService, I18N, AppEntityManager)
export class ProcessConfigurationSelect {
  /**
   * processConfigurationId
   *
   * @type {(string|null)}
   */
  @bindable value;

  /**
   * limit the selection to ProcessConfigurations which only belong to a certain UserGroup
   *
   * @type {(string|null)}
   */
  @bindable userGroupId;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {I18N} */
  _i18n;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfiguration/types').ProcessConfiguration>} */
  _availableProcessConfigurations = [];
  /** @type {boolean} */
  _attached = false;

  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {I18N} i18n
   * @param {AppEntityManager} entityManager
   */
  constructor(subscriptionManagerService, i18n, entityManager) {
    this._subscriptionManager = subscriptionManagerService.create();
    this._i18n = i18n;
    this._entityManager = entityManager;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this._updateAvailableProcessConfigurations.bind(this)
    );
    this._updateAvailableProcessConfigurations();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();

    this._attached = false;
  }

  userGroupIdChanged() {
    if (this._attached) {
      this._updateAvailableProcessConfigurations();
    }
  }

  _handleSelectChanged() {
    setTimeout(() => {
      DomEventHelper.fireEvent(this._domElement, {
        name: 'value-changed',
        detail: null
      });
    }, 0);
  }

  _updateAvailableProcessConfigurations() {
    if (this.userGroupId) {
      this._availableProcessConfigurations =
        this._entityManager.processConfigurationRepository.getByUserGroupId(
          this.userGroupId
        );
    } else {
      this._availableProcessConfigurations =
        this._entityManager.processConfigurationRepository.getAll();
    }
  }

  /**
   * @param {string} key
   * @returns {string}
   * @private
   */
  _translate(key) {
    return this._i18n.tr('operations.processConfigurationSelect.' + key);
  }
}
