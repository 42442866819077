import {
  CameraPreview,
  CameraPreviewFlashMode
} from '@capacitor-community/camera-preview';

import { assertNotNullOrUndefined } from 'common/Asserts';

import {
  AbstractCameraStreamStrategy,
  FocusSetHintResult
} from './AbstractCameraStreamStrategy';

export class NativeCameraStreamStrategy extends AbstractCameraStreamStrategy {
  private aspectRatio: number | null = null;
  private dimensions: PreviewDimensions | null = null;

  public async startStream(
    onFocusSetCallback: (result: FocusSetHintResult) => void
  ): Promise<void> {
    await CameraPreview.start({
      position: 'rear',
      toBack: true,
      enableZoom: true,
      disableAudio: true,
      width: this.dimensions?.width,
      height: this.dimensions?.height,
      x: this.dimensions?.x,
      y: this.dimensions?.y
    });

    await this.updateSize();
    void CameraPreview.subscribeToFocusSet(onFocusSetCallback);
  }

  public async stopStream(): Promise<void> {
    this.dimensions = null;

    await CameraPreview.stop();
  }

  public async takePicture(): Promise<string> {
    const size = {
      width: 2400,
      height: 2400
    };

    const result = await CameraPreview.capture({
      width: size.width,
      height: size.height
    });

    const dataUrl = 'data:image/jpeg;base64,' + result.value;
    return dataUrl;
  }

  public async switchStream(): Promise<void> {
    await CameraPreview.flip();
  }

  public async updateSize(): Promise<void> {
    await this.updateDesiredPreviewDimensions();
    assertNotNullOrUndefined(
      this.dimensions,
      'cannot update size without dimensions'
    );

    await CameraPreview.setPreviewDimensions({
      x: this.dimensions.x,
      y: this.dimensions.y,
      width: this.dimensions.width,
      height: this.dimensions.height
    });
  }

  public async setFocusPoint(_x: number, _y: number): Promise<void> {
    // not necessary to do it programmatically here
    // await CameraPreview.focus({
    //   x: x - (this.dimensions?.x ?? 0),
    //   y: y - (this.dimensions?.y ?? 0)
    // });
  }

  public async getSupportedFlashModes(): Promise<
    Array<CameraPreviewFlashMode>
  > {
    const result = await CameraPreview.getSupportedFlashModes();
    return result.flashModes;
  }

  public async getFlashMode(): Promise<CameraPreviewFlashMode | null> {
    const result = await CameraPreview.getFlashMode();
    return result.flashMode;
  }

  public async setFlashMode(flashMode: CameraPreviewFlashMode): Promise<void> {
    await CameraPreview.setFlashMode({
      flashMode
    });
  }

  private async getAspectRatioOfMaximumPictureSize(): Promise<number> {
    const result = await CameraPreview.getSupportedPictureSizes();
    const dimensions = result.result;

    const highestResolutionDimensions = { width: 0, height: 0 };

    for (const dimension of dimensions) {
      if (
        dimension.width * dimension.height >
        highestResolutionDimensions.width * highestResolutionDimensions.height
      ) {
        highestResolutionDimensions.width = dimension.width;
        highestResolutionDimensions.height = dimension.height;
      }
    }

    const aspectRatio =
      highestResolutionDimensions.width / highestResolutionDimensions.height;

    if (aspectRatio < 1) {
      return 1 / aspectRatio;
    } else {
      return aspectRatio;
    }
  }

  private async getDesiredAspectRatio(): Promise<number> {
    if (this.aspectRatio !== null) {
      return this.aspectRatio;
    }

    this.aspectRatio = await this.getAspectRatioOfMaximumPictureSize();
    return this.aspectRatio;
  }

  private async updateDesiredPreviewDimensions(): Promise<void> {
    const desiredAspectRatio = await this.getDesiredAspectRatio();
    const screenInfo = this.getScreenInfo();

    const dimensions = {
      width: 0,
      height: 0,
      x: 0,
      y: 0
    };

    if (screenInfo.aspectRatio > desiredAspectRatio) {
      dimensions.height = screenInfo.height;
      dimensions.width = dimensions.height * desiredAspectRatio;
      dimensions.x = (screenInfo.width - dimensions.width) / 2;
    } else {
      dimensions.width = screenInfo.width;
      dimensions.height = dimensions.width / desiredAspectRatio;
      dimensions.y = (screenInfo.height - dimensions.height) / 2;
    }

    if (screenInfo.portrait) {
      this.dimensions = {
        width: dimensions.height,
        height: dimensions.width,
        x: dimensions.y,
        y: dimensions.x
      };
    } else {
      this.dimensions = dimensions;
    }
  }

  private getScreenInfo(): {
    width: number;
    height: number;
    aspectRatio: number;
    portrait: boolean;
  } {
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;

    const portrait = innerWidth / innerHeight < 1;

    const screenInfo = {
      width: portrait ? innerHeight : innerWidth,
      height: portrait ? innerWidth : innerHeight,
      aspectRatio: portrait
        ? innerHeight / innerWidth
        : innerWidth / innerHeight,
      portrait
    };

    return screenInfo;
  }
}

type PreviewDimensions = {
  width: number;
  height: number;
  x: number;
  y: number;
};
