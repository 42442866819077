import _ from 'lodash';

import { autoinject, bindable, PLATFORM } from 'aurelia-framework';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { UltraRapidFireWidgetEditPlanDialog } from '../ultra-rapid-fire-widget-edit-plan-dialog/ultra-rapid-fire-widget-edit-plan-dialog';
import { SavePictureCallbackAnyOptions, Type } from './ultra-rapid-fire-widget';
import { PropertyCreationBaseData } from '../../classes/EntityManager/entities/Property/types';
import { TagIdsChangedEvent } from './urfw-categorized-tags-button/urfw-categorized-tags-button';
import { TagDeletedEvent } from '../tags-widget-list/tags-widget-list';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

export enum WidgetType {
  CATEGORIZED_TAGS = 'categorizedTags',
  REGION_SELECT = 'regionSelect',
  PERSON_MULTI_SELECT = 'personMultiSelect'
}

/**
 * @event region-id-changed
 * @event person-ids-changed
 * @event property-value-changed
 */
@autoinject()
export class UltraRapidFireWidgetOverlayWidget {
  @bindable public widget: WidgetConfiguration | null = null;

  @bindable public data: SavePictureCallbackAnyOptions | null = null;

  /** Controls whether the categorized-tags overlay widget should display the categorized-tag-list widget as well. */
  @bindable public displayTagList: boolean = true;

  @bindable public enabled = false;

  private domElement: HTMLElement;

  private dataPropertiesSubscriptionManager: SubscriptionManager;

  private property: PropertyCreationBaseData | null = null;

  private static widgetTypeToComponentTemplateMap: Record<WidgetType, string> =
    {
      [WidgetType.CATEGORIZED_TAGS]: PLATFORM.moduleName(
        './overlay-widget-templates/categorized-tags.html'
      ),
      [WidgetType.REGION_SELECT]: PLATFORM.moduleName(
        './overlay-widget-templates/region-select.html'
      ),
      [WidgetType.PERSON_MULTI_SELECT]: PLATFORM.moduleName(
        './overlay-widget-templates/person-multi-select.html'
      )
    };

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.dataPropertiesSubscriptionManager =
      subscriptionManagerService.create();
  }

  protected detached(): void {
    this.dataPropertiesSubscriptionManager.disposeSubscriptions();
  }

  protected widgetChanged(): void {
    this.updatePropertyData();
  }

  protected dataChanged(): void {
    this.dataPropertiesSubscriptionManager.disposeSubscriptions();
    if (this.data) {
      this.dataPropertiesSubscriptionManager.subscribeToArrayPropertyChanges(
        this.data,
        'properties',
        this.updatePropertyData.bind(this)
      );
    }
    this.updatePropertyData();
  }

  private updatePropertyData(): void {
    const widgetProperty =
      this.widget && 'property' in this.widget ? this.widget.property : null;
    if (!this.data || !widgetProperty) return;

    const property = this.data.properties.find((prop) => {
      return PropertyHelper.isTheSameProperty(
        {
          type: widgetProperty.type ?? null,
          name: widgetProperty.name ?? null
        },
        {
          type: prop.type ?? null,
          name: prop.name ?? null
        }
      );
    });

    if (!property) {
      this.property = _.clone(widgetProperty);
      this.data.properties.push(this.property);
    } else {
      this.property = property;
    }
  }

  protected getComponentTemplateObject(name: WidgetType): string {
    return UltraRapidFireWidgetOverlayWidget.widgetTypeToComponentTemplateMap[
      name
    ];
  }

  protected toggleCheckboxPropertyValue(): void {
    if (!this.property) return;
    this.property.value = this.property.value === 'true' ? 'false' : 'true';
  }

  protected handleRegionSelectChanged(regionId: string): void {
    DomEventHelper.fireEvent<RegionIdChangedEvent>(this.domElement, {
      name: 'region-id-changed',
      detail: {
        regionId
      }
    });
  }

  protected handlePersonIdsChanged(personIds: Array<string>): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'person-ids-changed',
      detail: personIds
    });
  }

  protected handlePropertyValueChanged(
    property: PropertyCreationBaseData
  ): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'property-value-changed',
      detail: property
    });
  }

  protected handleTagIdsChanged(event: TagIdsChangedEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'tag-ids-changed',
      detail: event.detail
    });
  }

  protected handleTagDeleted(event: TagDeletedEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'tag-deleted',
      detail: event.detail
    });
  }

  protected handleButtonClick(): void {
    const data = this.data;

    if (!this.widget || !('button' in this.widget) || !data) return;

    if (this.widget.button.type === ButtonWidgetType.EDIT_PLAN_DIALOG) {
      if (data.type !== Type.GALLERY_THING) {
        throw new Error('marking is only supported for the galleryThing');
      }

      void UltraRapidFireWidgetEditPlanDialog.open({
        thingId: data.thingId,
        markings: data.additionalMarkings || [],
        onDialogClosed: (markings) => {
          data.additionalMarkings = markings;
          DomEventHelper.fireEvent(this.domElement, {
            name: 'marking-changed',
            detail: markings
          });
        }
      });
    }
  }
}

export type WidgetConfiguration =
  | CategorizedTagsWidget
  | RegionSelectWidget
  | PersonMultiSelectWidget
  | PropertyWidget
  | ButtonWidget;

type CategorizedTagsWidget = {
  widgetType: WidgetType.CATEGORIZED_TAGS;
};

type RegionSelectWidget = {
  widgetType: WidgetType.REGION_SELECT;
};

type PersonMultiSelectWidget = {
  widgetType: WidgetType.PERSON_MULTI_SELECT;
  options: PersonSelectOptions | null;
};

export type PersonSelectOptions = {
  filterByCategoryName: string | null;
};

type PropertyWidget = {
  property: PropertyCreationBaseData;
};

type ButtonWidget = {
  button: {
    type: ButtonWidgetType;
    iconName: string;
  };
};

enum ButtonWidgetType {
  EDIT_PLAN_DIALOG = 'editPlanDialog'
}

export type RegionIdChangedEvent = NamedCustomEvent<
  'region-id-changed',
  { regionId: string }
>;
