import { autoinject, bindable } from 'aurelia-framework';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  Picture,
  ProjectQuestionPicture
} from '../../classes/EntityManager/entities/Picture/types';
import { PictureSelectAndEditDialog } from '../../dialogs/picture-select-and-edit-dialog/picture-select-and-edit-dialog';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';

@autoinject()
export class ChecklistProjectQuestionPicturesWidget {
  @bindable public question: ProjectQuestion | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected handlePictureClicked(picture: Picture): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handlePictureClicked without question'
    );

    void PictureSelectAndEditDialog.open({
      canCreatePictures: true,
      mainEntityIdField: 'ownerProjectId',
      mainEntityId: this.question.ownerProjectId,
      subEntityField: 'projectQuestionId',
      subEntityValue: this.question.id,
      ownerUserGroupId: this.question.ownerUserGroupId
    });
  }

  @computed(expression('question'), model(EntityName.Picture))
  protected get pictures(): Array<ProjectQuestionPicture> {
    if (!this.question?.id) return [];

    return this.entityManager.pictureRepository.getByProjectQuestionId(
      this.question.id
    );
  }
}
