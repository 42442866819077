import { Router } from 'aurelia-router';
import { AppEntityManager } from '../AppEntityManager';

export class ProcessTaskUtils {
  private constructor() {}

  public static processTaskHasActionStatus(
    processConfigurationActionStatusId: string | null,
    customActionStatusName: string | null,
    customActionStatusAbbreviation: string | null
  ): boolean {
    return !!(
      processConfigurationActionStatusId ||
      customActionStatusName ||
      customActionStatusAbbreviation
    );
  }

  public static getActionStatusDisplayName(
    processConfigurationActionStatusId: string | null,
    customActionStatusName: string | null,
    customActionStatusAbbreviation: string | null,
    entityManager: AppEntityManager
  ): string {
    let abbr = customActionStatusAbbreviation;
    let name = customActionStatusName;

    if (processConfigurationActionStatusId) {
      const actionStatus =
        entityManager.processConfigurationActionStatusRepository.getById(
          processConfigurationActionStatusId
        );
      if (actionStatus) {
        abbr = actionStatus.abbreviation;
        name = actionStatus.name;
      }
    }

    if (abbr) {
      return abbr;
    }

    if (name) {
      return name;
    }

    return '';
  }

  public static navigateToEditProcessTaskPage(
    router: Router,
    processTaskId: string
  ): void {
    router.navigateToRoute('edit_process_task', {
      process_task_id: processTaskId
    });
  }
}
