import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  MoreButtonChoice,
  MoreButtonFileChangedEvent
} from '../../aureliaComponents/more-button/more-button';
import {
  structureTemplateEntryPropertyJsonImportValidationSchema,
  StructureTemplateEntryPropertyJsonImportData
} from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/StructureTemplateEntryPropertyJsonImportValidationSchema';
import { ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter } from '../../propertyComponents/manage-property-definitions-widget/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter/ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter';
import { RxjsService } from '../../services/RxjsService/RxjsService';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';

@autoinject()
export class StructureTemplateEntryPropertiesWidget {
  @bindable public structureTemplate: StructureTemplate | null = null;
  @bindable public enabled = false;

  private subscriptionManager: SubscriptionManager;

  protected managePropertyDefinitionsWidgetAdapter: ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter | null =
    null;

  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk:
        'structureTemplateComponents.structureTemplateEntryPropertiesWidget.uploadJsonFile',
      name: 'upload-entry-properties',
      fileInputAccept: 'application/json,.json',
      isFileInput: true,
      disabledContext: this,
      disabledPropertyName: 'uploadJsonFileDisabled',
      iconClass: 'fal fa-file-code'
    }
  ];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly i18n: I18N,
    private readonly rxjsService: RxjsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {}

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computedFrom('enabled', 'structureTemplate.status')
  protected get uploadJsonFileDisabled(): boolean {
    return (
      !this.enabled ||
      (this.structureTemplate?.status !== StructureTemplateStatus.DRAFT &&
        this.structureTemplate?.status !==
          StructureTemplateStatus.PROVISIONALLY_ACTIVE)
    );
  }

  protected structureTemplateChanged(): void {
    if (this.structureTemplate) {
      this.managePropertyDefinitionsWidgetAdapter =
        new ManagePropertyDefinitionsWidgetStructureTemplateEntryPropertyAdapter(
          {
            entityManager: this.entityManager,
            rxjsService: this.rxjsService,
            subscriptionManagerService: this.subscriptionManagerService,
            structureTemplate: this.structureTemplate,
            enabled$: this.rxjsService.fromExpression({
              context: this,
              expression: 'enabled'
            })
          }
        );
    } else {
      this.managePropertyDefinitionsWidgetAdapter = null;
    }
  }

  protected handleUploadEntryProperties(
    event: MoreButtonFileChangedEvent<'upload-entry-properties'>
  ): void {
    const inputElement = event.detail.target as HTMLInputElement;
    const file = inputElement?.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = reader.result as string;

      const parsedJsonData = this.parsePropertiesFromJson(
        JSON.parse(fileContent)
      );
      if (!parsedJsonData) return;

      assertNotNullOrUndefined(
        this.structureTemplate,
        'structure template is not available'
      );
      const existingProperties =
        this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
          this.structureTemplate.id
        );

      for (const property of parsedJsonData.properties) {
        if (
          !existingProperties.find(
            (entryProperty) =>
              entryProperty.name === property.name &&
              entryProperty.type === property.type
          )
        ) {
          this.entityManager.structureTemplateEntryPropertyRepository.create({
            ...property,
            ownerUserGroupId: this.structureTemplate.ownerUserGroupId,
            ownerStructureTemplateId: this.structureTemplate.id
          });
        }
      }
    };
    reader.readAsText(file);
  }

  private parsePropertiesFromJson(
    jsonData: any
  ): StructureTemplateEntryPropertyJsonImportData | null {
    const result =
      structureTemplateEntryPropertyJsonImportValidationSchema.safeParse(
        jsonData
      );
    if (!result.success) {
      this.showJsonParsingError(JSON.stringify(result.error.format()));
      return null;
    } else {
      return result.data;
    }
  }

  private showJsonParsingError(errorMsg: string): void {
    void Dialogs.warningDialog(
      this.i18n.tr(
        'structureTemplateComponents.structureTemplateEntryPropertiesWidget.jsonParsingFailed'
      ),
      this.i18n.tr(
        'structureTemplateComponents.structureTemplateEntryPropertiesWidget.jsonParsingFailedDetail',
        {
          error: errorMsg,
          demoObject: JSON.stringify(
            this.getPropertyJsonImportDemoData(),
            undefined,
            2
          )
        }
      )
    );
  }

  private getPropertyJsonImportDemoData(): StructureTemplateEntryPropertyJsonImportData {
    return {
      properties: [
        {
          name: 'Dropdownname',
          type: 'dropdown' as PropertyType,
          choices: ['a', 'DEFAULT', 'b'],
          value: 'DEFAULT'
        },
        {
          name: 'Formatierter Text',
          type: 'text_sichtbar' as PropertyType,
          options: [{ name: 'theme', value: 'Heading1' }]
        }
      ]
    };
  }
}
