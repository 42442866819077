import { inject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject()
export class EditPersonDialog {
  /** @type {import('../../classes/EntityManager/entities/Person/types').Person|null} */
  _person = null;
  /** @type {(function():void)|null|undefined} */
  _onDialogClosed = null;
  /** @type {boolean} */
  _dialogOpened = false;

  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /**
   * @param {TEditPersonDialogOpenOptions} options
   */
  open(options) {
    this._person = options.person;
    this._onDialogClosed = options.onDialogClosed;

    if (this._dialog) {
      this._dialog.open();
    }
  }

  _handleDialogClosed() {
    this._person = null;
  }

  /**
   * @param {TEditPersonDialogOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TEditPersonDialogOpenOptions
 * @property {import('../../classes/EntityManager/entities/Person/types').Person} person
 * @property {(function():void)|null} [onDialogClosed]
 */
