import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import {
  BasePropertyCheckboxWidget,
  basePropertyCheckboxWidgetConfiguration
} from '../../base-property-checkbox-widget/base-property-checkbox-widget';
import {
  BasePropertyCoordinateWidget,
  basePropertyCoordinateWidgetConfiguration
} from '../../base-property-coordinate-widget/base-property-coordinate-widget';
import {
  BasePropertyDateWidget,
  basePropertyDateWidgetConfiguration
} from '../../base-property-date-widget/base-property-date-widget';
import {
  BasePropertyDropdownWidget,
  basePropertyDropdownWidgetConfiguration
} from '../../base-property-dropdown-widget/base-property-dropdown-widget';
import {
  BasePropertyHeadingWidget,
  basePropertyHeadingWidgetConfiguration
} from '../../base-property-heading-widget/base-property-heading-widget';
import {
  BasePropertyInvisibleTextWidget,
  basePropertyInvisibleTextWidgetConfiguration
} from '../../base-property-invisible-text-widget/base-property-invisible-text-widget';
import {
  BasePropertyMultiDropdownWidget,
  basePropertyMultiDropdownWidgetConfiguration
} from '../../base-property-multi-dropdown-widget/base-property-multi-dropdown-widget';
import {
  BasePropertyMultilineTextWidget,
  basePropertyMultilineTextWidgetConfiguration
} from '../../base-property-multiline-text-widget/base-property-multiline-text-widget';
import {
  BasePropertyNumberWidget,
  basePropertyNumberWidgetConfiguration
} from '../../base-property-number-widget/base-property-number-widget';
import {
  BasePropertyPersonWidget,
  basePropertyPersonWidgetConfiguration
} from '../../base-property-person-widget/base-property-person-widget';
import {
  BasePropertyPictureWidget,
  basePropertyPictureWidgetConfiguration
} from '../../base-property-picture-widget/base-property-picture-widget';
import {
  BasePropertyPriorityWidget,
  basePropertyPriorityWidgetConfiguration
} from '../../base-property-priority-widget/base-property-priority-widget';
import {
  BasePropertyProcessTaskPositionWidget,
  basePropertyProcessTaskPositionWidgetConfiguration
} from '../../base-property-process-task-position-widget/base-property-process-task-position-widget';
import {
  BasePropertyProjectPropertyWidget,
  basePropertyProjectPropertyWidgetConfiguration
} from '../../base-property-project-property-widget/base-property-project-property-widget';
import {
  BasePropertyRadiobuttonWidget,
  basePropertyRadiobuttonWidgetConfiguration
} from '../../base-property-radiobutton-widget/base-property-radiobutton-widget';
import {
  BasePropertySignatureWidget,
  basePropertySignatureWidgetConfiguration
} from '../../base-property-signature-widget/base-property-signature-widget';
import {
  BasePropertyTableWidget,
  basePropertyTableWidgetConfiguration
} from '../../base-property-table-widget/base-property-table-widget';
import {
  BasePropertyTextWidget,
  basePropertyTextWidgetConfiguration
} from '../../base-property-text-widget/base-property-text-widget';
import {
  BasePropertyTimeWidget,
  basePropertyTimeWidgetConfiguration
} from '../../base-property-time-widget/base-property-time-widget';
import {
  BasePropertyVisibleTextWidget,
  basePropertyVisibleTextWidgetConfiguration
} from '../../base-property-visible-text-widget/base-property-visible-text-widget';
import { PropertySubWidget } from '../config/PropertySubWidget/PropertySubWidget';
import { PropertySubWidgetConfiguration } from '../config/PropertySubWidget/PropertySubWidgetConfiguration';

export const propertyTypeToSubWidget =
  createTypeExtendsValidator<PropertyTypeToSubWidgetConstraint>()({
    [PropertyType.BERICHTPARAMETER]: {
      component: BasePropertyProjectPropertyWidget,
      configuration: basePropertyProjectPropertyWidgetConfiguration
    },
    [PropertyType.CHECKBOX]: {
      component: BasePropertyCheckboxWidget,
      configuration: basePropertyCheckboxWidgetConfiguration
    },
    [PropertyType.COORDINATE]: {
      component: BasePropertyCoordinateWidget,
      configuration: basePropertyCoordinateWidgetConfiguration
    },
    [PropertyType.DATE]: {
      component: BasePropertyDateWidget,
      configuration: basePropertyDateWidgetConfiguration
    },
    [PropertyType.DROPDOWN]: {
      component: BasePropertyDropdownWidget,
      configuration: basePropertyDropdownWidgetConfiguration
    },
    [PropertyType.HEADING]: {
      component: BasePropertyHeadingWidget,
      configuration: basePropertyHeadingWidgetConfiguration
    },
    [PropertyType.INVISIBLE_TEXT]: {
      component: BasePropertyInvisibleTextWidget,
      configuration: basePropertyInvisibleTextWidgetConfiguration
    },
    [PropertyType.MULTI_DROPDOWN]: {
      component: BasePropertyMultiDropdownWidget,
      configuration: basePropertyMultiDropdownWidgetConfiguration
    },
    [PropertyType.MULTILINE]: {
      component: BasePropertyMultilineTextWidget,
      configuration: basePropertyMultilineTextWidgetConfiguration
    },
    [PropertyType.NUMBER]: {
      component: BasePropertyNumberWidget,
      configuration: basePropertyNumberWidgetConfiguration
    },
    [PropertyType.PERSON]: {
      component: BasePropertyPersonWidget,
      configuration: basePropertyPersonWidgetConfiguration
    },
    [PropertyType.PICTURE]: {
      component: BasePropertyPictureWidget,
      configuration: basePropertyPictureWidgetConfiguration
    },
    [PropertyType.PRIORITY]: {
      component: BasePropertyPriorityWidget,
      configuration: basePropertyPriorityWidgetConfiguration
    },
    [PropertyType.POSITION]: {
      component: BasePropertyProcessTaskPositionWidget,
      configuration: basePropertyProcessTaskPositionWidgetConfiguration
    },
    [PropertyType.RADIOBUTTON]: {
      component: BasePropertyRadiobuttonWidget,
      configuration: basePropertyRadiobuttonWidgetConfiguration
    },
    [PropertyType.SIGNATURE]: {
      component: BasePropertySignatureWidget,
      configuration: basePropertySignatureWidgetConfiguration
    },
    [PropertyType.TABLE]: {
      component: BasePropertyTableWidget,
      configuration: basePropertyTableWidgetConfiguration
    },
    [PropertyType.TEXT]: {
      component: BasePropertyTextWidget,
      configuration: basePropertyTextWidgetConfiguration
    },
    [PropertyType.TIME]: {
      component: BasePropertyTimeWidget,
      configuration: basePropertyTimeWidgetConfiguration
    },
    [PropertyType.VISIBLE_TEXT]: {
      component: BasePropertyVisibleTextWidget,
      configuration: basePropertyVisibleTextWidgetConfiguration
    }
  });

export type PropertyTypeToSubWidget = typeof propertyTypeToSubWidget;

// @typeimprovement - currently it's possibly to mix a component with a configuration which doesn't cover the component. This can lead to null errors if the binding doesn't support all the features needed for the component
type PropertyTypeToSubWidgetConstraint = {
  [type in PropertyType]: {
    component: PropertySubWidgetComponent;
    configuration: PropertySubWidgetConfiguration<any>;
  };
};

export type PropertySubWidgetComponent = new (
  ...args: Array<any>
) => PropertySubWidget;
