import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { DefectChangeLogEntryDto } from './DefectChangeLogEntryDto';

export function createDefectChangeLogEntryEntityInfo<
  TEntity extends DefectChangeLogEntryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    DefectChangeLogEntryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.DefectChangeLogEntry,
  TEntity
> {
  return {
    entityName: EntityName.DefectChangeLogEntry,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      DefectChangeLogEntryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        userId: {
          name: 'userId',
          ref: EntityName.User
        },
        logAction: {
          name: 'logAction'
        },
        logDataJson: {
          name: 'logDataJson',
          defaultValue: null
        },
        created: {
          name: 'created'
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
