export type FileChunkUploadStartRequest = {
  fileSize: number;
};

export type FileChunkUploadUploadChunkRequest = {
  fileId: string;
  offset: number;
  data: Uint8Array;
};

export type FileChunkUploadStatusRequest = {
  fileId: string;
};

export type FileChunkFileInfoResponse =
  | {
      success: true;
      fileId: string;
      missingChunks: Array<FileInfoMissingChunk>;
    }
  | {
      success: false;
      errorType: FileChunkUploadErrorType;
    };

export type FileInfoMissingChunk = {
  start: number;
  end: number;
};

export enum FileChunkUploadErrorType {
  NO_PERMISSION = 'no_permission',
  UNKNOWN = 'unknown',
  NOT_FOUND = 'not_found',
  CHUNK_OUT_OF_BOUNDS = 'chunk_out_of_bounds'
}
