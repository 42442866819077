import { MomentInput } from 'moment';
import { DateUtils } from 'common/DateUtils';

export class DateFormatValueConverter {
  /**
   * Properly formats a date into a human-readable format.
   * @param value a date (string|number|date|...)
   * @param format how the date should be formatted.
   */
  public toView(value?: MomentInput, format?: DateDisplayFormat): string {
    if (!value) return '';
    switch (format) {
      case DateDisplayFormat.DATE_TIME:
        return DateUtils.formatToDateWithTimeString(value);
      case DateDisplayFormat.DATE_HOUR_MINUTE:
        return DateUtils.formatToDateWithHourMinuteString(value);
      case DateDisplayFormat.LONG_DATE:
        return DateUtils.formatToLongDateString(value);
      case DateDisplayFormat.DATE:
        return DateUtils.formatToDateString(value);
      case DateDisplayFormat.HOUR_MINUTE:
        return DateUtils.formatToHourMinuteString(value);
      case DateDisplayFormat.DAY_OF_WEEK:
        return DateUtils.formatToDayOfWeekString(value);
      case DateDisplayFormat.SHORT_DAY_OF_WEEK:
        return DateUtils.formatToShortDayOfWeekString(value);
      case DateDisplayFormat.DAY_OF_MONTH:
        return DateUtils.formatToDayOfMonthString(value);
      case DateDisplayFormat.ISO_WEEK:
        return DateUtils.formatToIsoWeekString(value);
      case DateDisplayFormat.MONTH:
        return DateUtils.formatToMonthString(value);
      case DateDisplayFormat.YEAR:
        return DateUtils.formatToYearString(value);
      default:
        return DateUtils.formatToDateString(value);
    }
  }
}

export enum DateDisplayFormat {
  /** Formatted as `DD.MM.YYYY HH:mm:ss` */
  DATE_TIME = 'dateTime',
  /** Formatted as `DD.MM.YYYY HH:mm` */
  DATE_HOUR_MINUTE = 'dateHourMinute',
  /** Formatted as `dddd, Do MMMM YYYY` */
  LONG_DATE = 'longDate',
  /** Formatted as `DD.MM.YYYY` */
  DATE = 'date',
  /** Formatted as `HH:mm` */
  HOUR_MINUTE = 'hourMinute',
  /** Formatted as `dddd` */
  DAY_OF_WEEK = 'dayOfWeek',
  /** Formatted as `dd` */
  SHORT_DAY_OF_WEEK = 'shortDayOfWeek',
  /** Formatted as `DD` */
  DAY_OF_MONTH = 'dayOfMonth',
  /** Formatted as `moment(...).isoWeek()` */
  ISO_WEEK = 'isoWeek',
  /** Formatted as `MMMM` */
  MONTH = 'month',
  /** Formatted as `YYYY` */
  YEAR = 'year'
}
