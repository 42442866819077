import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointmentUtils } from '../../classes/EntityManager/entities/ProcessTaskAppointment/ProcessTaskAppointmentUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { configureHooks } from '../../hooks/configureHooks';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class ProcessTaskAppointmentsWithWarningsDialog {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private readonly appointmentsWhereFinishedAtCanBeEditedHandle: EntitiesWithPermissionHandle<EntityName.ProcessTaskAppointment>;

  protected processTask: ProcessTask | null = null;

  private appointmentWithDateInfos: Array<AppointmentWithDateInfo> = [];
  protected allAppointmentsFinished: boolean = false;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.appointmentsWhereFinishedAtCanBeEditedHandle =
      permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.ProcessTaskAppointment,
        permissionName: 'canEditField'
      });
  }

  public open(options: OpenOptions): void {
    if (!this.dialog) {
      throw new Error('dialog is not referenced');
    }

    this.processTask = options.processTask;

    let first = true;
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (appointmentDateInfoMap) => {
          if (first) {
            this.appointmentWithDateInfos = this.getAppointmentWithDateInfos(
              options.processTask,
              appointmentDateInfoMap
            );
            first = false;
          } else {
            this.updateAppointmentWithDateInfos(appointmentDateInfoMap);
          }

          this.appointmentsWhereFinishedAtCanBeEditedHandle.setEntities(
            this.appointmentWithDateInfos.map((info) => info.appointment)
          );
          this.updateAllAppointmentsFinished();
        }
      })
    );

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.processTask = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  private getAppointmentWithDateInfos(
    processTask: ProcessTask,
    appointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap
  ): Array<AppointmentWithDateInfo> {
    const appointments =
      this.entityManager.processTaskAppointmentRepository.getByProcessTaskId(
        processTask.id
      );
    return appointments
      .map((a) => {
        return {
          appointment: a,
          dateInfo: appointmentDateInfoMap.get(a.id) ?? null
        };
      })
      .filter((info) => {
        if (!info.dateInfo) {
          return false;
        }
        return ProcessTaskAppointmentUtils.isInPastAndNotFinished(
          info.appointment.finishedAt,
          info.dateInfo.dateTo
        );
      });
  }

  private updateAppointmentWithDateInfos(
    appointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap
  ): void {
    for (const info of this.appointmentWithDateInfos) {
      info.dateInfo = appointmentDateInfoMap.get(info.appointment.id) ?? null;
    }
  }

  protected handleMarkAllAppointmentsAsFinishedClick(): void {
    for (const appointment of this.appointmentsWhereFinishedAtCanBeEditedHandle
      .filteredEntities) {
      if (!appointment.finishedAt) {
        appointment.finishedAt = new Date().toISOString();
        this.entityManager.processTaskAppointmentRepository.update(appointment);
      }
    }

    this.updateAllAppointmentsFinished();
  }

  protected handleAppointmentFinished(): void {
    this.updateAllAppointmentsFinished();
  }

  private updateAllAppointmentsFinished(): void {
    this.allAppointmentsFinished = this.appointmentWithDateInfos.every(
      (info) => !!info.appointment.finishedAt
    );
  }
}

export type OpenOptions = {
  processTask: ProcessTask;
};

export type AppointmentWithDateInfo = {
  appointment: ProcessTaskAppointment;
  dateInfo: ProcessTaskAppointmentDateInfo | null;
};
