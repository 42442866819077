import { autoinject } from 'aurelia-dependency-injection';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureHelper } from 'common/EntityHelper/PictureHelper';
import { PictureFileExtension } from 'common/Types/Entities/PictureFile/PictureFileDto';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../../UrlManager';
import { PictureFile } from './types';
import { AppEntityManager } from '../AppEntityManager';
import { PictureMainEntityInfo } from '../Picture/types';
import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { SocketService } from '../../../../services/SocketService';
import { PictureFileNameUtils } from './PictureFileNameUtils';
import { FileUtils } from '../../../Utils/FileUtils/FileUtils';
import { ThumbnailFileService } from '../../../../services/ThumbnailFileService/ThumbnailFileService';

@autoinject()
export class PictureFilePathService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly thumbnailFileService: ThumbnailFileService
  ) {}

  public async getPicturePreviews(
    pictureFile: PictureFile | null,
    fullSize: boolean
  ): Promise<Array<string>> {
    if (!pictureFile) return [];
    const previews = [];

    if (pictureFile?.file_extension !== PictureFileExtension.DXF) {
      const thumbnailSrc =
        await this.getPictureFileThumbnailSource(pictureFile);
      if (thumbnailSrc) {
        previews.push(thumbnailSrc);
      }
    }

    if (pictureFile?.file_extension === PictureFileExtension.DXF || fullSize) {
      const fullSizeSrc = await this.getPictureFileSource(pictureFile);
      if (fullSizeSrc) previews.push(fullSizeSrc);
    }

    return previews;
  }

  public async getPictureFileSource(
    pictureFile: PictureFile
  ): Promise<null | string> {
    let returnPath = null;

    if (DeviceInfoHelper.isApp()) {
      if (pictureFile.isOriginatingHere) {
        returnPath = this.getCapturedLocalPicPath({ pictureFile });
      } else if ((pictureFile.local_created ?? 0) > 0) {
        returnPath = this.getFullLocalPicPath({ pictureFile });
      }

      if (returnPath) {
        returnPath = await this.getPictureSourceInApp(returnPath);
      }
    }

    if (
      !returnPath &&
      pictureFile.file_uploaded &&
      this.socketService.isConnected()
    ) {
      returnPath = this.getFullOnlinePicPath({ pictureFile, fullSize: true });
    }

    return returnPath;
  }

  public getRelativeOnlinePicPath({
    pictureFile,
    fullSize = false,
    mainEntityInfo = null,
    download = false
  }: {
    pictureFile: PictureFile;
    fullSize?: boolean;
    mainEntityInfo?: PictureMainEntityInfo | null;
    download?: boolean;
  }): string {
    mainEntityInfo = mainEntityInfo || this.getMainEntityInfo(pictureFile);

    let path = PathUtils.getFullOnlinePicPath(
      pictureFile,
      fullSize,
      mainEntityInfo,
      ''
    );
    if (download) path = PathUtils.joinPaths(path, '?download');
    return path;
  }

  public getFullOnlinePicPath({
    pictureFile,
    fullSize = false,
    mainEntityInfo = null
  }: {
    pictureFile: PictureFile;
    fullSize?: boolean;
    mainEntityInfo?: PictureMainEntityInfo | null;
  }): string {
    mainEntityInfo = mainEntityInfo || this.getMainEntityInfo(pictureFile);

    return PathUtils.getFullOnlinePicPath(
      pictureFile,
      fullSize,
      mainEntityInfo,
      UrlManager.webFolder
    );
  }

  public getRelativeLocalPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): string {
    const mainEntityInfo = this.getMainEntityInfo(pictureFile);

    if (!mainEntityInfo) {
      throw new Error(
        `No mainEntityInfo found for PictureFile ${pictureFile.id}`
      );
    }

    assertNotNullOrUndefined(
      pictureFile.file_created,
      "can't get the path of a file which hasn't been created yet"
    );

    const pictureFileName = PictureFileNameUtils.getPictureFileName({
      id: pictureFile.id,
      file_created: pictureFile.file_created,
      file_extension: pictureFile.file_extension
    });

    return PathUtils.joinPaths(
      mainEntityInfo.name,
      mainEntityInfo.id,
      pictureFileName
    );
  }

  public getCapturedLocalPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): string {
    const relativePath = this.getRelativeCapturedLocalPicPath({ pictureFile });
    return PathUtils.joinPaths(UrlManager.localFolder, relativePath);
  }

  private async getCapturedLocalThumbnailPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): Promise<string> {
    const relativePath = this.getRelativeCapturedLocalPicPath({ pictureFile });
    return this.thumbnailFileService.getOrCreateThumbnailPath(relativePath);
  }

  public getRelativeCapturedLocalPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): string {
    assertNotNullOrUndefined(
      pictureFile.file_created,
      "can't get the path of a file which hasn't been created yet"
    );

    const pictureFileName = PictureFileNameUtils.getPictureFileName({
      id: pictureFile.id,
      file_created: pictureFile.file_created,
      file_extension: pictureFile.file_extension
    });

    return PathUtils.joinPaths('captured_pictures', pictureFileName);
  }

  private async getPictureFileThumbnailSource(
    pictureFile: PictureFile
  ): Promise<null | string> {
    if (!pictureFile) return null;

    let returnPath = null;

    if (DeviceInfoHelper.isApp()) {
      if (pictureFile.isOriginatingHere) {
        returnPath = await this.getCapturedLocalThumbnailPicPath({
          pictureFile
        });
      } else if ((pictureFile.local_created ?? 0) > 0) {
        returnPath = await this.getFullLocalThumbnailPicPath({ pictureFile });
      } else if (pictureFile.file_uploaded) {
        returnPath = this.getFullOnlinePicPath({ pictureFile });
      }

      if (returnPath) {
        returnPath = this.getPictureSourceInApp(returnPath);
      }
    } else {
      if (pictureFile.file_uploaded) {
        returnPath = this.getFullOnlinePicPath({ pictureFile });
      }
    }

    return returnPath;
  }

  private async getPictureSourceInApp(sourcePath: string): Promise<string> {
    if (DeviceInfoHelper.isApp()) {
      return await FileUtils.convertFileSrc(sourcePath);
    }

    return sourcePath;
  }

  private getFullLocalPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): string {
    const relativePath = this.getRelativeLocalPicPath({ pictureFile });
    return PathUtils.joinPaths(UrlManager.localFolder, relativePath);
  }

  private async getFullLocalThumbnailPicPath({
    pictureFile
  }: {
    pictureFile: PictureFile;
  }): Promise<string> {
    const relativePath = this.getRelativeLocalPicPath({ pictureFile });
    return this.thumbnailFileService.getOrCreateThumbnailPath(relativePath);
  }

  private getMainEntityInfo(
    pictureFile: PictureFile
  ): PictureMainEntityInfo | null {
    const picture = this.entityManager.pictureRepository.getById(
      pictureFile.picture
    );
    return picture ? PictureHelper.getMainEntityInfo<string>(picture) : null;
  }
}
