import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TagsChangedEvent } from '../../aureliaComponents/categorized-tags-widget/categorized-tags-widget';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event tags-changed triggered when the user changes their selection of tags.
 */
@autoinject()
export class CategorizedPictureTagsWidget {
  @bindable public picture: Picture | null = null;

  @bindable public thingId: string | null = null;

  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  protected pictureTags: Array<Tag> = [];

  private subscriptionManager: SubscriptionManager;
  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();

    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Picture,
        context: this as CategorizedPictureTagsWidget,
        propertyName: 'picture'
      });
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictureTags.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected pictureChanged(): void {
    this.updatePictureTags();
  }

  private updatePictureTags(): void {
    if (!this.picture) {
      this.pictureTags = [];
      return;
    }

    this.pictureTags = this.entityManager.tagRepository.getByIds(
      this.picture.tagIds
    );
  }

  protected handleTagsChanged(event: TagsChangedEvent): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot handleTagsChanged without picture'
    );

    this.picture.tagIds = event.detail.tags.map((t) => t.id);
    this.entityManager.pictureRepository.update(this.picture);

    DomEventHelper.fireEvent<TagsChangedEvent>(this.element, {
      name: 'tags-changed',
      detail: event.detail
    });
  }
}
