import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { DefectSubEntityUtils } from '../../BaseEntities/DefectSubEntityUtils';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { PropertyDto, PropertyType } from './PropertyDto';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';

export function createPropertyEntityInfo<
  TEntity extends PropertyDto<string, string>
>(
  options: CreateEntityInfoOptions<PropertyDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Property,
  TEntity
> {
  return {
    entityName: EntityName.Property,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      PropertyDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProcessTaskSubEntityUtils.createOptionalBaseFieldInfos(),
        ...DefectSubEntityUtils.createOptionalBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        value: {
          name: 'value',
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: PropertyType.TEXT
        },
        choices: {
          name: 'choices',
          defaultValue: () => []
        },
        custom_choice: {
          name: 'custom_choice',
          defaultValue: null
        },
        active: {
          name: 'active',
          defaultValue: false
        },
        order: {
          name: 'order',
          defaultValue: null
        },
        options: {
          name: 'options',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              value: {
                name: 'value'
              }
            }
          ],
          defaultValue: () => []
        },
        alwaysVisible: {
          name: 'alwaysVisible',
          defaultValue: false
        },
        hidden: {
          name: 'hidden',
          defaultValue: false
        },
        thing: {
          name: 'thing',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thingType: {
          name: 'thingType',
          ref: EntityName.ThingType,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thingGroupId: {
          name: 'thingGroupId',
          ref: EntityName.ThingGroup,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        project: {
          name: 'project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        entry: {
          name: 'entry',
          ref: EntityName.Entry,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        defect: {
          name: 'defect',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        regionId: {
          name: 'regionId',
          ref: EntityName.Region,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thingThingSectionId: {
          name: 'thingThingSectionId',
          ref: EntityName.ThingSection,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        projectThingSectionId: {
          name: 'projectThingSectionId',
          ref: EntityName.ThingSection,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskMeasurePointReadingId: {
          name: 'processTaskMeasurePointReadingId',
          ref: EntityName.ProcessTaskMeasurePointReading,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processConfigurationDeviceId: {
          name: 'processConfigurationDeviceId',
          ref: EntityName.ProcessConfigurationDevice,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskDeviceId: {
          name: 'processTaskDeviceId',
          ref: EntityName.ProcessTaskDevice,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskPositionId: {
          name: 'processTaskPositionId',
          ref: EntityName.ProcessTaskPosition,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskPositionDetailEntryId: {
          name: 'processTaskPositionDetailEntryId',
          ref: EntityName.ProcessTaskPositionDetailEntry,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processTaskId: {
          name: 'processTaskId',
          ref: EntityName.ProcessTask,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        processConfigurationStepPositionId: {
          name: 'processConfigurationStepPositionId',
          ref: EntityName.ProcessConfigurationStepPosition,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        },
        userDefinedEntityId: {
          name: 'userDefinedEntityId',
          ref: EntityName.UserDefinedEntity,
          cascadeDeleteLocally: true,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
