import { computedFrom } from 'aurelia-framework';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { ThingTypeProperty } from '../../../../classes/EntityManager/entities/Property/types';
import {
  PropertyWidgetBinding,
  PropertyWidgetBindingFeatureMap,
  PropertyWidgetBindingValueData
} from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetConfiguration } from '../PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';

export function createThingTypePropertyDefinitionWidgetConfiguration(): ThingTypePropertyDefinitionWidgetConfiguration {
  return {
    bindingConfiguration: {
      features: {
        default: true,
        person: false,
        generalFile: false,
        picture: false,
        position: false,
        projectId: false
      },
      createBinding: ({ container, context }) => {
        return new PropertyBinding({
          entityManager: container.get(AppEntityManager),
          subscriptionManagerService: container.get(SubscriptionManagerService),
          permissionsService: container.get(PermissionsService),
          property: context
        });
      }
    }
  };
}

export type ThingTypePropertyDefinitionWidgetConfiguration =
  PropertyWidgetConfiguration<
    ThingTypePropertyDefinitionFeatures,
    ThingTypeProperty
  >;

class PropertyBinding
  implements PropertyWidgetBinding<ThingTypePropertyDefinitionFeatures>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: ThingTypeProperty;
  private readonly subscriptionManager: SubscriptionManager;

  private propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];

  constructor(options: PropertyBindingOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;

    this.subscriptionManager = options.subscriptionManagerService.create();

    this.propertyPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Property,
        entity: options.property
      });

    this.subscriptionManager.addDisposable(
      this.propertyPermissionsHandle.subscribe()
    );
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  @computedFrom('property.value')
  public get value(): string | null {
    return this.property.value;
  }

  @computedFrom('property.custom_choice')
  public get customChoice(): string | null {
    return this.property.custom_choice;
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options;
  }

  @computedFrom('property.ownerUserGroupId')
  public get userGroupId(): string {
    return this.property.ownerUserGroupId;
  }

  @computedFrom()
  public get children(): Array<never> {
    return [];
  }

  @computedFrom(
    'propertyPermissionsHandle.canEditField.value',
    'propertyPermissionsHandle.canEditField.custom_choice'
  )
  public get canSetValueData(): boolean {
    return (
      this.propertyPermissionsHandle.canEditField.value &&
      this.propertyPermissionsHandle.canEditField.custom_choice
    );
  }

  @computedFrom()
  public get forceDisabled(): boolean {
    return false;
  }

  @computedFrom()
  public get features(): PropertyWidgetBindingFeatureMap<ThingTypePropertyDefinitionFeatures> {
    return {
      default: true,
      generalFile: false,
      person: false,
      picture: false,
      position: false,
      projectId: false
    };
  }

  public setValueData(valueData: PropertyWidgetBindingValueData): void {
    this.property.value = valueData.value;
    this.property.custom_choice = valueData.customChoice;
    this.entityManager.propertyRepository.update(this.property);
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}

type PropertyBindingOptions = {
  entityManager: AppEntityManager;
  property: ThingTypeProperty;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
};

type ThingTypePropertyDefinitionFeatures = 'default';
