import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskOfferToProcessTaskDevice } from './types';

export class ProcessTaskOfferToProcessTaskDeviceRepository extends AppEntityRepository<EntityName.ProcessTaskOfferToProcessTaskDevice> {
  public getByProcessTaskOfferId(
    processTaskOfferId: string
  ): Array<ProcessTaskOfferToProcessTaskDevice> {
    return this.getAll().filter((e) => {
      return e.processTaskOfferId === processTaskOfferId;
    });
  }

  public getByProcessTaskDeviceId(
    processTaskDeviceId: string
  ): Array<ProcessTaskOfferToProcessTaskDevice> {
    return this.getAll().filter(
      (e) => e.processTaskDeviceId === processTaskDeviceId
    );
  }

  public getByProcessTaskDeviceIds(
    processTaskDeviceIds: Array<string>
  ): Array<ProcessTaskOfferToProcessTaskDevice> {
    return this.getAll().filter(
      (e) => processTaskDeviceIds.indexOf(e.processTaskDeviceId) >= 0
    );
  }

  public getByProcessTaskOfferIdAndProcessTaskId(
    processTaskOfferId: string,
    processTaskId: string
  ): Array<ProcessTaskOfferToProcessTaskDevice> {
    return this.getAll().filter((e) => {
      return (
        e.ownerProcessTaskId === processTaskId &&
        e.processTaskOfferId === processTaskOfferId
      );
    });
  }

  public getByProcessTaskOfferIdAndProcessTaskDeviceId(
    processTaskOfferId: string,
    processTaskDeviceId: string
  ): ProcessTaskOfferToProcessTaskDevice | null {
    return (
      this.getAll().find((e) => {
        return (
          e.processTaskOfferId === processTaskOfferId &&
          e.processTaskDeviceId === processTaskDeviceId
        );
      }) ?? null
    );
  }
}
