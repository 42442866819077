import {
  PropertyMultiDropdownValueItem,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { createTypeExtendsValidator } from 'common/Types/typeUtils';
import { AppEntityManager } from '../../EntityManager/entities/AppEntityManager';
import { Property } from '../../EntityManager/entities/Property/types';
import { Logger } from '../../Logger/Logger';

export function createPropertyConfigsByPropertyTypes({
  entityManager
}: {
  entityManager: AppEntityManager;
}): PropertyConfigsByPropertyType {
  return {
    [PropertyType.BERICHTPARAMETER]: {
      hasValue: null
    },
    [PropertyType.CHECKBOX]: {
      hasValue: ({ property }) => {
        // a non checked checkbox is seen as not having a value
        return property.value != null && property.value !== 'false';
      }
    },
    [PropertyType.COORDINATE]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.DATE]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.DROPDOWN]: {
      hasValue: ({ property }) => {
        if (!hasValueWhichIsNotNullOrEmptyString({ property })) {
          return isNotNullOrEmptyString(property.custom_choice);
        }

        return true;
      }
    },
    [PropertyType.HEADING]: {
      hasValue: null
    },
    [PropertyType.INVISIBLE_TEXT]: {
      hasValue: null
    },
    [PropertyType.MULTILINE]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.MULTI_DROPDOWN]: {
      hasValue: ({ property }) => {
        let valueItems: Array<PropertyMultiDropdownValueItem> = [];

        try {
          valueItems = property.value ? JSON.parse(property.value) : [];
        } catch (error) {
          Logger.logError({ error });
        }

        if (valueItems.some((valueItem) => valueItem.value !== null)) {
          // a value is selected
          return true;
        }

        if (valueItems.some((valueItem) => valueItem.value == null)) {
          return isNotNullOrEmptyString(property.custom_choice);
        }

        return false;
      }
    },
    [PropertyType.NUMBER]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.PERSON]: {
      hasValue: ({ property }) => {
        const propertyToPersons =
          entityManager.propertyToPersonRepository.getByPropertyId(property.id);
        return propertyToPersons.length > 0;
      }
    },
    [PropertyType.PICTURE]: {
      hasValue: ({ property }) => {
        const pictures = entityManager.pictureRepository.getByPropertyId(
          property.id
        );
        return pictures.length > 0;
      }
    },
    [PropertyType.POSITION]: {
      hasValue: ({ property }) => {
        const propertyToPositions =
          entityManager.propertyToProcessTaskPositionRepository.getByPropertyId(
            property.id
          );
        return propertyToPositions.length > 0;
      }
    },
    [PropertyType.PRIORITY]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.RADIOBUTTON]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.SIGNATURE]: {
      hasValue: ({ property }) => {
        const signatureFiles =
          entityManager.generalFileRepository.getSignatureFilesByPropertyId(
            property.id
          );
        return signatureFiles.length > 0;
      }
    },
    [PropertyType.TABLE]: {
      hasValue: ({ property }) => {
        if (!property.ownerProjectId) {
          return false; // tables are only supported in projects
        }

        const entries = entityManager.entryRepository.getByProjectId(
          property.ownerProjectId,
          property.name
        );
        return entries.length > 0;
      }
    },
    [PropertyType.TEXT]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.TIME]: {
      hasValue: hasValueWhichIsNotNullOrEmptyString
    },
    [PropertyType.VISIBLE_TEXT]: {
      hasValue: null
    }
  };
}

const propertyConfigFactoriesByPropertyType =
  createTypeExtendsValidator<PropertyConfigFactoriesByPropertyTypeConstraint>()(
    {
      [PropertyType.BERICHTPARAMETER]: {
        hasValue: null
      },
      [PropertyType.CHECKBOX]: {
        hasValue:
          () =>
          ({ property }) => {
            // a non checked checkbox is seen as not having a value
            return property.value != null && property.value !== 'false';
          }
      },
      [PropertyType.COORDINATE]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.DATE]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.DROPDOWN]: {
        hasValue:
          () =>
          ({ property }) => {
            if (!hasValueWhichIsNotNullOrEmptyString({ property })) {
              return isNotNullOrEmptyString(property.custom_choice);
            }

            return true;
          }
      },
      [PropertyType.HEADING]: {
        hasValue: null
      },
      [PropertyType.INVISIBLE_TEXT]: {
        hasValue: null
      },
      [PropertyType.MULTILINE]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.MULTI_DROPDOWN]: {
        hasValue:
          () =>
          ({ property }) => {
            let valueItems: Array<PropertyMultiDropdownValueItem> = [];

            try {
              valueItems = property.value ? JSON.parse(property.value) : [];
            } catch (error) {
              Logger.logError({ error });
            }

            if (valueItems.some((valueItem) => valueItem.value !== null)) {
              // a value is selected
              return true;
            }

            if (valueItems.some((valueItem) => valueItem.value == null)) {
              return isNotNullOrEmptyString(property.custom_choice);
            }

            return false;
          }
      },
      [PropertyType.NUMBER]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.PERSON]: {
        hasValue:
          ({ entityManager }) =>
          ({ property }) => {
            const propertyToPersons =
              entityManager.propertyToPersonRepository.getByPropertyId(
                property.id
              );
            return propertyToPersons.length > 0;
          }
      },
      [PropertyType.PICTURE]: {
        hasValue:
          ({ entityManager }) =>
          ({ property }) => {
            const pictures = entityManager.pictureRepository.getByPropertyId(
              property.id
            );
            return pictures.length > 0;
          }
      },
      [PropertyType.POSITION]: {
        hasValue:
          ({ entityManager }) =>
          ({ property }) => {
            const propertyToPositions =
              entityManager.propertyToProcessTaskPositionRepository.getByPropertyId(
                property.id
              );
            return propertyToPositions.length > 0;
          }
      },
      [PropertyType.PRIORITY]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.RADIOBUTTON]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.SIGNATURE]: {
        hasValue:
          ({ entityManager }) =>
          ({ property }) => {
            const signatureFiles =
              entityManager.generalFileRepository.getSignatureFilesByPropertyId(
                property.id
              );
            return signatureFiles.length > 0;
          }
      },
      [PropertyType.TABLE]: {
        hasValue:
          ({ entityManager }) =>
          ({ property }) => {
            if (!property.ownerProjectId) {
              return false; // tables are only supported in projects
            }

            const entries = entityManager.entryRepository.getByProjectId(
              property.ownerProjectId,
              property.name
            );
            return entries.length > 0;
          }
      },
      [PropertyType.TEXT]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.TIME]: {
        hasValue: () => hasValueWhichIsNotNullOrEmptyString
      },
      [PropertyType.VISIBLE_TEXT]: {
        hasValue: null
      }
    }
  );

type PropertyConfigFactoriesByPropertyType =
  typeof propertyConfigFactoriesByPropertyType;

function hasValueWhichIsNotNullOrEmptyString({
  property
}: {
  property: Property;
}): boolean {
  return isNotNullOrEmptyString(property.value);
}

function isNotNullOrEmptyString(value: string | null): boolean {
  return value != null && value !== '';
}

type PropertyConfigFactoriesByPropertyTypeConstraint = Record<
  PropertyType,
  PropertyConfigFactory
>;
type PropertyConfigFactory = {
  /**
   * if this is null, the value of the property can\'t be checked
   */
  hasValue: ((options: { entityManager: AppEntityManager }) => HasValue) | null;
};

export type PropertyConfigsByPropertyType = {
  [key in PropertyType]: PropertyConfig<
    ExtractHasValueTypeFromFactory<PropertyConfigFactoriesByPropertyType[key]>
  >;
};

type ExtractHasValueTypeFromFactory<TFactory extends PropertyConfigFactory> =
  TFactory['hasValue'] extends null
    ? null
    : ReturnType<NonNullable<TFactory['hasValue']>>;

export type PropertyConfig<THasValue extends HasValue | null> = {
  /**
   * if this is null, the value of the property can\'t be checked
   */
  hasValue: THasValue;
};

export type HasValue = (options: { property: Property }) => boolean;
