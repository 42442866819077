import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { MapMarker } from '../../map/basemap-map/basemap-map';

export class SelectCoordinatesOnMapDialog {
  /** @type {import('../record-it-dialog/record-it-dialog').RecordItDialog} */
  _dialog;

  /** @type {boolean} */
  _isOpen = false;

  /** @type {import('../../map/basemap-map/basemap-map').BasemapMap} */
  _basemapMap;

  /** @type {(MapMarker|null)} */
  _originalPositionMarker;

  /** @type {TSelectCoordinatesOnMapDialogOpenOptions} */
  _options;

  /**
   *
   * @param {TSelectCoordinatesOnMapDialogOpenOptions} options
   */
  open(options) {
    this._options = options;
    this._dialog.open();
  }

  close() {
    this._dialog.close();
  }

  _handleDialogOpened() {
    this._isOpen = true;
  }

  _handleDialogClosed() {
    if (this._originalPositionMarker) {
      this._originalPositionMarker.remove();
      this._originalPositionMarker.offClick(
        this._handleOriginalPositionMarkerClick,
        this
      );
      this._originalPositionMarker = null;
    }

    this._isOpen = false;
  }

  _handleMapInitialized() {
    const map = this._basemapMap.getMapInstance();

    if (this._options && this._options.onMapReady) {
      this._options.onMapReady(this._basemapMap);
    }

    if (this._options.lat && this._options.long) {
      this._flyToPosition(new L.LatLng(this._options.lat, this._options.long));

      this._originalPositionMarker = new MapMarker(
        this._options.lat,
        this._options.long
      );
      this._originalPositionMarker.appendToMap(map);
      this._originalPositionMarker.setOpacity(0.6);
      this._originalPositionMarker.onClick(
        this._handleOriginalPositionMarkerClick,
        this
      );
    }
  }

  _handleAcceptClick() {
    const center = this._basemapMap.getMapInstance().getCenter();
    this._options.onCoordinateSelected(center.lat, center.lng);
    this.close();
  }

  _handleCancelClick() {
    this.close();
  }

  _handleOriginalPositionMarkerClick() {
    this._flyToPosition(
      this._originalPositionMarker.getLeafletMarker().getLatLng()
    );
  }

  /**
   *
   * @param {L.LatLng} latLng
   * @private
   */
  _flyToPosition(latLng) {
    this._basemapMap.getMapInstance().flyTo(latLng, 16, {
      duration: 1
    });
  }

  /**
   *
   * @param {TSelectCoordinatesOnMapDialogOpenOptions} options
   * @returns {Promise<void>}
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TSelectCoordinatesOnMapDialogOpenOptions
 * @property {(number|null)} [lat]
 * @property {(number|null)} [long]
 * @property {(lat: number, long: number)=>void} onCoordinateSelected
 * @property {onMapReadyCallback} [onMapReady]
 */

/**
 * @callback onMapReadyCallback
 * @param {import('../../map/basemap-map/basemap-map').BasemapMap} baseMap
 */
