import { Capacitor } from '@capacitor/core';

import { AnyEntry } from '../../entries/AnyEntry';
import { FileUtilsAdapter } from '../FileUtilsAdapter';
import { CapacitorDirectoryEntry } from './CapacitorDirectoryEntry';
import { CapacitorFileEntry } from './CapacitorFileEntry';

export class CapacitorAdapter extends FileUtilsAdapter {
  public async resolvePath(path: string): Promise<AnyEntry> {
    return this.convertCordovaEntryToCustomEntry(
      await this.resolveLocalFileSystemURL(path)
    );
  }

  private resolveLocalFileSystemURL(url: string): Promise<Entry> {
    return new Promise((res, rej) => {
      window.resolveLocalFileSystemURL(
        url,
        (fileSystem) => {
          res(fileSystem);
        },
        (error) => {
          rej(error);
        }
      );
    });
  }

  private convertCordovaEntryToCustomEntry(entry: Entry): AnyEntry {
    if (entry.isDirectory) {
      return new CapacitorDirectoryEntry(entry as DirectoryEntry);
    }

    return new CapacitorFileEntry(entry as FileEntry);
  }

  public async convertFileSrc(src: string): Promise<string> {
    return Capacitor.convertFileSrc(src);
  }
}
