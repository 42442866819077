import { DateUtils } from '../../../../common/src/DateUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskAppointmentDateInfoMap } from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';

export class AppointmentCountdownUtils {
  private constructor() {}

  /**
   * the countdown is limited to 2 digits
   */
  public static getCountdown(
    appointments: Array<ProcessTaskAppointment>,
    processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap
  ): number | null {
    const appointmentDiff = this.getMsToCountdownAppointment(
      appointments,
      processTaskAppointmentDateInfoMap
    );

    if (appointmentDiff != null) {
      // cap at 99 because we don't want to display more than 2 digits
      return Math.max(
        Math.min(Math.round(appointmentDiff / (24 * 60 * 60 * 1000)), 99),
        -99
      );
    } else {
      return null;
    }
  }

  private static getMsToCountdownAppointment(
    appointments: Array<ProcessTaskAppointment>,
    processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap
  ): number | null {
    const today = new Date();
    DateUtils.removeTimeInfoFromDate(today);

    let nextAppointmentDiff: number | null = null;
    let pastUndoneAppointmentDiff: number | null = null;

    for (const appointment of appointments) {
      const dateInfo = processTaskAppointmentDateInfoMap.get(appointment.id);
      if (!dateInfo?.dateFrom || appointment.finishedAt) {
        continue;
      }

      const dateFrom = new Date(dateInfo.dateFrom);
      DateUtils.removeTimeInfoFromDate(dateFrom);
      const diff = dateFrom.getTime() - today.getTime();

      if (
        (nextAppointmentDiff == null || diff < nextAppointmentDiff) &&
        diff >= 0
      ) {
        nextAppointmentDiff = diff;
      }

      if (
        (pastUndoneAppointmentDiff == null ||
          diff < pastUndoneAppointmentDiff) &&
        diff < 0
      ) {
        pastUndoneAppointmentDiff = diff;
      }
    }

    return pastUndoneAppointmentDiff ?? nextAppointmentDiff;
  }
}
