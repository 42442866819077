import { autoinject } from 'aurelia-framework';
import { Redirect, RouteConfig } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { ThingViewType } from '../../aureliaComponents/switch-thing-type-button/switch-thing-type-button';
import { ChecklistProjectNameUtils } from 'common/Checklist/ChecklistProjectNameUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ProjectCreationService } from '../../classes/EntityManager/entities/Project/ProjectCreationService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class EditChecklistThing {
  protected thing: Thing | null = null;

  protected ThingViewType = ThingViewType;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly projectCreationService: ProjectCreationService,
    private readonly i18n: I18N,
    private readonly permissionsService: PermissionsService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForEntity({
      entityName: EntityName.Thing,
      entity: null
    });
  }

  protected activate(
    params: { thing_id?: string },
    routeConfig: RouteConfig
  ): Redirect | void {
    this.thing = params.thing_id
      ? this.entityManager.thingRepository.getById(params.thing_id)
      : null;

    this.permissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Thing,
        entity: this.thing
      });

    this.activeEntitiesService.setActiveThing(this.thing);

    if (routeConfig.navModel && this.thing?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.thing.name;
  }

  protected handleCreateProjectButtonClicked(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCreateProjectButtonClicked without thing'
    );

    this.projectCreationService.createProject({
      thing: this.thing,
      name: ChecklistProjectNameUtils.fromDate(new Date()),
      projectType: ProjectType.CHECKLIST,
      reportType: null
    });
  }
}
