import { sortBy, groupBy } from 'lodash';
import { bindable, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import {
  GalleryThingPictureOverviewEntry,
  GalleryThingPictureOverviewEntryGroup as PictureGroup
} from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import {
  UploadChoiceSelectedEvent,
  UploadChoiceSelectedEventDetail
} from '../../picture/picture-upload-area/picture-upload-area';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';

/**
 * @event upload-choice-selected
 */

@autoinject()
export class GalleryThingRegionPicturesGroup {
  @bindable public thing: Thing | null = null;

  @bindable public pictureGroup: PictureGroup | null = null;

  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  @bindable public pictureSelectionEnabled = false;

  @bindable public useUltraRapidFireWidget = false;

  private domElement: HTMLElement;

  private cachedPictureGroups: Array<RegionPictureGroup> = [];

  constructor(
    element: Element,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager
  ) {
    this.domElement = element as HTMLElement;
  }

  protected handleUploadChoiceSelected(
    event: UploadChoiceSelectedEvent,
    regionId: string
  ): void {
    DomEventHelper.fireEvent<UploadChoiceSelectedRegionEvent>(this.domElement, {
      name: 'upload-choice-selected',
      detail: {
        ...event.detail,
        regionId
      }
    });
  }

  @computed(
    expression('thing'),
    expression('pictureGroup'),
    arrayChanges('pictureGroup.pictureOverviewEntries'),
    model(EntityName.Region)
  )
  protected get pictureGroups(): Array<RegionPictureGroup> {
    const pictureGroup = this.pictureGroup;
    if (!this.thing || !pictureGroup) {
      this.cachedPictureGroups = [];
      return [];
    }

    const groupedPictures = groupBy(
      pictureGroup.pictureOverviewEntries,
      (pictureOverviewEntry) => {
        if (!pictureOverviewEntry.entryId) return 'null';
        return pictureOverviewEntry.regionId;
      }
    );
    const ungroupedPictures = groupedPictures['null'];

    const pictureGroupsDataToSet: Array<RegionPictureGroup> = [];

    const regions = sortBy(
      this.entityManager.regionRepository.getByThingId(this.thing.id),
      'customId'
    );
    regions.forEach((region) => {
      const label = `${region.customId ? `${region.customId} - ` : ''}${
        region.name ? region.name : ''
      }`;
      const property = this.entityManager.propertyRepository
        .getByRegionId(region.id)
        .find((prop) => prop.name === 'description' + pictureGroup.project.id);
      pictureGroupsDataToSet.push({
        entityId: region.id,
        label: label,
        regionDescription: (property && property.value) || region.description,
        generalRegionDescription:
          (property && property.value && region.description) || null,
        pictureCount: groupedPictures[region.id]?.length ?? 0,
        pictureOverviewEntries: groupedPictures[region.id] || []
      });
    });

    if (ungroupedPictures) {
      pictureGroupsDataToSet.push({
        entityId: '',
        label: this.i18n.tr(
          'galleryThing.regionPicturesGroup.defaultGroupLabel'
        ),
        regionDescription: null,
        pictureCount: ungroupedPictures.length,
        pictureOverviewEntries: ungroupedPictures
      });
    }

    const newPictureGroups = InstancePreserver.createNewArray({
      originalArray: this.cachedPictureGroups,
      newArray: pictureGroupsDataToSet,
      getTrackingValue: (item) => item.entityId
    });

    this.cachedPictureGroups = newPictureGroups;
    return newPictureGroups;
  }
}

type RegionPictureGroup = {
  entityId: string;
  label: string;
  regionDescription?: string | null;
  generalRegionDescription?: string | null;
  pictureCount: number;
  pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry>;
};

type UploadChoiceSelectedEventRegionDetail = UploadChoiceSelectedEventDetail & {
  regionId: string;
};
export type UploadChoiceSelectedRegionEvent = NamedCustomEvent<
  'upload-choice-selected',
  UploadChoiceSelectedEventRegionDetail
>;
