import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskToProjectEntityInfo } from 'common/Types/Entities/ProcessTaskToProject/createProcessTaskToProjectEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskToProject } from './types';

export const processTaskToProjectEntityInfo =
  createProcessTaskToProjectEntityInfo<ProcessTaskToProject>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
