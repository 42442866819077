import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGroupAuthorization } from '../../classes/EntityManager/entities/ProcessTaskGroupAuthorization/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { CreateProcessTaskGroupAuthorizationDialog } from '../create-process-task-group-authorization-dialog/create-process-task-group-authorization-dialog';

@autoinject()
export class ProcessTaskGroupAuthorizationsWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @subscribableLifecycle()
  protected processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskGroupAuthorizationsWidget,
        propertyName: 'processTaskGroup'
      });
  }

  protected handleAddAuthorizationClick(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      "can't ProcessTaskGroupAuthorizationsWidget.handleAddAuthorizationClick without processTaskGroup"
    );

    void CreateProcessTaskGroupAuthorizationDialog.open({
      processTaskGroup: this.processTaskGroup
    });
  }

  @computed(
    expression('processTaskGroup.id'),
    model(EntityName.ProcessTaskGroupAuthorization)
  )
  protected get processTaskGroupAuthorizations(): Array<ProcessTaskGroupAuthorization> {
    if (!this.processTaskGroup) {
      return [];
    }

    return this.entityManager.processTaskGroupAuthorizationRepository.getByProcessTaskGroupId(
      this.processTaskGroup.id
    );
  }
}
