import { Disposable } from 'aurelia-binding';
import { ValueCalculationConfigType } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ValueCalculationResultAdapter,
  ValueCalculationResultAdapterOptions,
  ValueCalculationResultAdapterSubscribeOptions
} from './ValueCalculationResultAdapter';

export class ThingValueCalculationResultAdapter
  implements ValueCalculationResultAdapter<Thing>
{
  private entityManager: AppEntityManager;
  private subscriptionManagerService: SubscriptionManagerService;

  private thing: Thing;

  constructor(options: ValueCalculationResultAdapterOptions<Thing>) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.thing = options.baseEntity;
  }

  public subscribe(
    options: ValueCalculationResultAdapterSubscribeOptions<Thing>
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateResults = (): void => {
      const results =
        this.entityManager.valueCalculationResultRepository.getByThingId(
          this.thing.id
        );
      options.setResults(results);
    };
    updateResults();

    const updateConfigs = (): void => {
      const configs =
        this.entityManager.valueCalculationConfigRepository.getByThingIdAndType(
          this.thing.id,
          ValueCalculationConfigType.THING
        );
      options.setConfigs(configs);
    };
    updateConfigs();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ValueCalculationResult,
      updateResults
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ValueCalculationConfig,
      updateConfigs
    );
    return subscriptionManager.toDisposable();
  }
}
