import { autoinject } from 'aurelia-framework';
import _ from 'lodash';

import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroupAssigneeChangedDataChange } from 'common/processTaskLogEntryActions';

@autoinject()
export class ProcessTaskGroupAssigneeChangedDataChangeFormatter extends AbstractLogDataConverter {
  constructor(private readonly entityManager: AppEntityManager) {
    super();
  }

  public convert({
    logData,
    args: { changeDataPropertyPath }
  }: ConvertOptions<'changeDataPropertyPath'>): void {
    const changeData: ChangeDataOrAnythingElse = _.get(
      logData,
      changeDataPropertyPath
    );

    const parts: Array<string> = [];

    const userName = this.tryParsingUsername(changeData);
    if (userName) {
      parts.push(userName);
    }

    const hint = this.tryParsingHint(changeData);
    if (hint) {
      parts.push(hint);
    }

    const processTaskName = this.tryParsingProcessTaskName(changeData);
    if (processTaskName) {
      parts.push(processTaskName);
    }

    _.set(logData, `${changeDataPropertyPath}Formatted`, parts.join('; '));
  }

  private tryParsingUsername(
    changeData: ChangeDataOrAnythingElse
  ): string | null {
    if (
      !changeData?.assigneeUserId ||
      typeof changeData.assigneeUserId !== 'string'
    ) {
      return null;
    }

    const user = this.entityManager.userRepository.getById(
      changeData.assigneeUserId
    );
    return user ? user.username : 'unknown';
  }

  private tryParsingHint(changeData: ChangeDataOrAnythingElse): string | null {
    if (
      !changeData?.assigneeHint ||
      typeof changeData.assigneeHint !== 'string'
    ) {
      return null;
    }

    return changeData.assigneeHint;
  }

  private tryParsingProcessTaskName(
    changeData: ChangeDataOrAnythingElse
  ): string | null {
    if (
      !changeData?.assignedProcessTaskName ||
      typeof changeData.assignedProcessTaskName !== 'string'
    ) {
      return null;
    }

    return changeData.assignedProcessTaskName;
  }
}

type ChangeDataOrAnythingElse =
  | ProcessTaskGroupAssigneeChangedDataChange
  | null
  | undefined
  | Record<string, any>;
