import moment from 'moment';
import { IdType } from '../Types/Entities/Base/types';
import {
  PropertyDto,
  PropertyMultiDropdownValueItem,
  PropertyType,
  propertyTypeInfos
} from '../Types/Entities/Property/PropertyDto';

export class PropertyHelper {
  public static CHECKED_BOX_CHAR = String.fromCharCode(0x2612);
  public static UNCHECKED_BOX_CHAR = String.fromCharCode(0x2610);

  public static isPropertyType(type: any): type is PropertyType {
    return Object.values(PropertyType).includes(type);
  }

  public static getPropertyText(
    type: PropertyType | null,
    name: string | null,
    value: string | null,
    customChoice: string | null
  ): string {
    switch (type) {
      case PropertyType.CHECKBOX:
        return value === 'true'
          ? PropertyHelper.CHECKED_BOX_CHAR
          : PropertyHelper.UNCHECKED_BOX_CHAR;

      case PropertyType.DATE:
        return PropertyHelper.getDateValue(value, 'DD.MM.YYYY');

      case PropertyType.TIME:
        return PropertyHelper.getDateValue(value, 'HH:mm');

      case PropertyType.HEADING:
        return name || '';

      case PropertyType.INVISIBLE_TEXT:
        return name || '';

      case PropertyType.VISIBLE_TEXT:
        return name || '';

      case PropertyType.RADIOBUTTON:
      case PropertyType.DROPDOWN:
        if (
          (value !== '' && value != null) ||
          customChoice === '' ||
          customChoice == null
        ) {
          return value || '';
        } else {
          return customChoice;
        }

      case PropertyType.MULTI_DROPDOWN:
        const valueItems: Array<PropertyMultiDropdownValueItem> = value
          ? JSON.parse(value)
          : [];
        return valueItems
          .map((v) => {
            if (v.value == null) {
              return customChoice || '';
            } else {
              return v.value;
            }
          })
          .join('; ');

      case PropertyType.PICTURE:
        return 'Bild';

      case PropertyType.TABLE:
        return 'Tabelle';

      case PropertyType.SIGNATURE:
        return 'Unterschrift';

      case PropertyType.MULTILINE:
      case PropertyType.TEXT:
      case PropertyType.NUMBER:
      default:
        return value || '';
    }
  }

  public static propertyTypeNeedsChoices(propertyType: PropertyType): boolean {
    return propertyTypeInfos[propertyType].needsChoices;
  }

  public static propertyTypeHasPicture(propertyType: PropertyType): boolean {
    return propertyTypeInfos[propertyType].hasPicture;
  }

  public static propertyTypeHasPrimitiveRepresentation(
    propertyType: PropertyType
  ): boolean {
    return propertyTypeInfos[propertyType].hasPrimitiveRepresentation;
  }

  public static propertyTypeHasEditableValue(
    propertyType: PropertyType
  ): boolean {
    return propertyTypeInfos[propertyType].valueIsEditable;
  }

  public static getAnyIdentifierForTemplate(
    propertyName: string,
    propertyType: PropertyType
  ): string {
    return propertyTypeInfos[propertyType].getTemplateIdentifier(
      propertyName,
      propertyType
    )[0];
  }

  public static getAllIdentifiersForTemplate(
    propertyName: string,
    propertyType: PropertyType
  ): [string, ...Array<string>] {
    return propertyTypeInfos[propertyType].getTemplateIdentifier(
      propertyName,
      propertyType
    );
  }

  /**
   * checks if a has the same name + type as b
   *
   * @param a
   * @param b
   */
  public static isTheSameProperty(
    a: {
      type: PropertyType | null | undefined;
      name: string | null | undefined;
    },
    b: {
      type: PropertyType | null | undefined;
      name: string | null | undefined;
    }
  ): boolean {
    return (
      (a.name ?? '') === (b.name ?? '') &&
      PropertyHelper.getTypeOrDefault(a.type) ===
        PropertyHelper.getTypeOrDefault(b.type)
    );
  }

  public static getTypeOrDefault(
    type: PropertyType | null | undefined
  ): PropertyType {
    // older properties (seemed to stop at the end of 2019) can have an empty string as a property type. These are also TEXT properties
    return type || PropertyType.TEXT;
  }

  public static extractValueDataFromProperty(property: ValueData): ValueData {
    return {
      value: property.value,
      custom_choice: property.custom_choice
    };
  }

  private static getDateValue(value: string | null, format: string): string {
    return value ? moment(value).format(format) : '';
  }

  public static getMainEntityInfo<T extends IdType>(
    property: PropertyMainEntityInfoRelevantFields<T>
  ): PropertyMainEntityInfo<T> | null {
    // REMINDER: ownerIds are assigned recursively tp parent entities => check for child before you check for parent
    if (property.ownerDefectId) {
      return {
        id: property.ownerDefectId,
        name: 'ownerDefectId',
        supportsTags: true
      };
    }

    const projectId = property.project ?? property.ownerProjectId;
    if (projectId) {
      return {
        id: projectId,
        name: 'project',
        supportsTags: true
      };
    }

    if (property.thing) {
      return {
        id: property.thing,
        name: 'ownerThingId',
        supportsTags: false
      };
    }

    if (property.ownerProcessConfigurationId) {
      return {
        id: property.ownerProcessConfigurationId,
        name: 'ownerProcessConfigurationId',
        supportsTags: false
      };
    }

    if (property.ownerProcessTaskGroupId) {
      return {
        id: property.ownerProcessTaskGroupId,
        name: 'ownerProcessTaskGroupId',
        supportsTags: false
      };
    }

    return null;
  }
}

export type ValueData = Pick<
  PropertyDto<string, string>,
  'value' | 'custom_choice'
>;

export type PropertyMainEntityInfoRelevantFields<T extends IdType> = Pick<
  PropertyDto<T, any>,
  | 'ownerProjectId'
  | 'project'
  | 'ownerDefectId'
  | 'thing'
  | 'ownerProcessConfigurationId'
  | 'ownerProcessTaskGroupId'
>;

export type PropertyMainEntityInfo<T extends IdType> = {
  name:
    | 'project'
    | 'ownerDefectId'
    | 'ownerThingId'
    | 'ownerProcessConfigurationId'
    | 'ownerProcessTaskGroupId';
  id: T;
  supportsTags: boolean;
};
