import { computedFrom } from 'aurelia-binding';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import {
  ApplyPropertiesService,
  StructureEntryPropertyInfo
} from '../../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import {
  GroupedProperties,
  GroupedPropertyHelper
} from '../../../classes/EntityManager/entities/Property/GroupedPropertyHelper';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { StructureTemplate } from '../../../classes/EntityManager/entities/StructureTemplate/types';
import { StructureTemplateEntryPropertiesHelper } from '../../../classes/EntityManager/entities/StructureTemplateEntryProperty/StructureTemplateEntryPropertiesHelper';
import { StructureTemplateStructureTemplateEntryProperty } from '../../../classes/EntityManager/entities/StructureTemplateEntryProperty/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

export class EntryPropertiesHandle implements Disposable {
  private readonly entry: Entry;
  private readonly project: Project;
  private readonly structureTemplate: StructureTemplate;
  private readonly entityManager: AppEntityManager;
  private readonly applyPropertiesService: ApplyPropertiesService;
  private readonly structureTemplateEntryPropertiesHelper: StructureTemplateEntryPropertiesHelper;
  private readonly subscriptionManager: SubscriptionManager;

  private structureTemplateEntryProperties: Array<StructureTemplateStructureTemplateEntryProperty> =
    [];
  private entryPropertyInfos: Array<StructureEntryPropertyInfo> = [];
  private entryPath: Array<Entry>;

  private internalGroupedProperties: GroupedProperties<Property> = [];

  constructor(options: EntryPropertiesHandleOptions) {
    this.entry = options.entry;
    this.project = options.project;
    this.structureTemplate = options.structureTemplate;
    this.entryPath = options.entryPath;
    this.subscriptionManager = options.subscriptionManagerService.create();
    this.entityManager = options.entityManager;
    this.applyPropertiesService = options.applyPropertiesService;
    this.structureTemplateEntryPropertiesHelper =
      new StructureTemplateEntryPropertiesHelper(
        options.entityManager,
        options.applyPropertiesService
      );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.StructureTemplateEntryProperty,
      this.updateStructureTemplateEntryProperties.bind(this)
    );
    this.updateStructureTemplateEntryProperties();
  }

  public setEntryPath(entryPath: Array<Entry>): void {
    this.entryPath = entryPath;
    this.updateDefaultValuesOfEntryProperties();
  }

  @computedFrom('internalGroupedProperties')
  public get groupedProperties(): GroupedProperties<Property> {
    return this.internalGroupedProperties;
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateStructureTemplateEntryProperties(): void {
    this.structureTemplateEntryProperties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByStructureTemplateId(
        this.structureTemplate.id
      );

    this.updateEntryProperties();
  }

  private updateEntryProperties(): void {
    this.entryPropertyInfos =
      this.applyPropertiesService.applyCustomPropertiesToStructureEntry(
        this.structureTemplateEntryProperties,
        this.entry,
        this.project
      );

    const properties = this.entryPropertyInfos.map((info) => info.property);
    this.internalGroupedProperties =
      GroupedPropertyHelper.groupProperties(properties);

    this.updateDefaultValuesOfEntryProperties();
  }

  private updateDefaultValuesOfEntryProperties(): void {
    const lastEntry = this.entryPath.at(-1);
    if (!lastEntry) return;

    for (const structureTemplateEntryProperty of this
      .structureTemplateEntryProperties) {
      const entryPropertyInfo = this.entryPropertyInfos.find((info) =>
        PropertyHelper.isTheSameProperty(
          info.property,
          structureTemplateEntryProperty
        )
      );
      assertNotNullOrUndefined(
        entryPropertyInfo,
        'corresponding entry property is not available'
      );

      const newDefaultValue =
        this.structureTemplateEntryPropertiesHelper.getDefaultValueForStructureTemplateEntryProperty(
          lastEntry,
          structureTemplateEntryProperty,
          this.structureTemplate.id
        );
      if (!newDefaultValue) continue;
      if (entryPropertyInfo.defaultValue === entryPropertyInfo.property.value) {
        entryPropertyInfo.property.value = newDefaultValue;
        entryPropertyInfo.defaultValue = newDefaultValue;
      }
    }
  }
}

export type EntryPropertiesHandleOptions = {
  entry: Entry;
  project: Project;
  structureTemplate: StructureTemplate;
  entryPath: Array<Entry>;
  subscriptionManagerService: SubscriptionManagerService;
  entityManager: AppEntityManager;
  applyPropertiesService: ApplyPropertiesService;
};
