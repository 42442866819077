import { DateUtils } from '../DateUtils';
import { RecurrenceCalculator } from '../Operations/Calendar/RecurrenceCalculator';
import { ProcessTaskRecurringAppointmentDto } from '../Types/Entities/ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentDto';

export namespace ProcessTaskRecurringAppointmentHelper {
  export function getEndTime(
    appointment: CommmonProcessTaskRecurringAppointment
  ): Date | null {
    if (appointment.startTime && appointment.durationInMs) {
      return DateUtils.getDatePlusMilliseconds(
        appointment.startTime,
        appointment.durationInMs
      );
    }
    return null;
  }

  /**
   * Return the start date of the appointment with the date attached.
   */
  export function getCompleteStartDate(
    appointment: CommmonProcessTaskRecurringAppointment
  ): Date | null {
    if (appointment.dateFrom && appointment.startTime) {
      const date = new Date(appointment.dateFrom);
      const time = new Date(appointment.startTime);
      return DateUtils.mergeDateAndTime(date, time);
    }
    return null;
  }

  /**
   * Return the end date of the appointment with the date attached.
   */
  export function getCompleteEndDate(
    appointment: CommmonProcessTaskRecurringAppointment
  ): Date | null {
    const startDate = getCompleteStartDate(appointment);
    if (startDate && appointment.durationInMs) {
      return DateUtils.getDatePlusMilliseconds(
        startDate,
        appointment.durationInMs
      );
    }
    return null;
  }

  /**
   * Returns true if any entry of the given appointment is within a given date range.
   */
  export function anyEntryIsInDateRange(
    appointment: CommmonProcessTaskRecurringAppointment,
    fromDate: Date | number,
    toDate: Date | number
  ): boolean {
    const startDate = getCompleteStartDate(appointment);
    const endDate = getCompleteEndDate(appointment);
    const from = fromDate instanceof Date ? fromDate : new Date(fromDate);
    const to = toDate instanceof Date ? toDate : new Date(toDate);

    if (!startDate || !endDate) return false;

    const calculator = new RecurrenceCalculator();
    const items = calculator.get(
      { startDate, endDate, recurrence: appointment.recurrence },
      from,
      to
    );

    return items.some((i) =>
      DateUtils.dateRangesOverlap(
        { from: i.startDate, to: i.endDate },
        { from, to }
      )
    );
  }
}

type CommmonProcessTaskRecurringAppointment =
  ProcessTaskRecurringAppointmentDto<any, string | Date>;
