import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  ProjectValueCalculationResult,
  ThingValueCalculationResult,
  ValueCalculationResult,
  ValueCalculationResultCreationEntity
} from './types';

export class ValueCalculationResultRepository extends AppEntityRepository<EntityName.ValueCalculationResult> {
  public create(
    _creationEntity: ValueCalculationResultCreationEntity
  ): ValueCalculationResult {
    throw new Error(ValueCalculationCreationErrorMessage);
  }

  public getByProjectId(
    projectId: string
  ): Array<ProjectValueCalculationResult> {
    return this.getAll().filter(
      (r): r is ProjectValueCalculationResult => r.projectId === projectId
    );
  }

  public getByThingId(thingId: string): Array<ThingValueCalculationResult> {
    return this.getAll().filter(
      (r): r is ThingValueCalculationResult => r.thingId === thingId
    );
  }
}

export const ValueCalculationCreationErrorMessage =
  'The entity "ValueCalculationResult" cannot be created manually. It is meant to be created and updated by the server exclusively.';
