import { autoinject } from 'aurelia-dependency-injection';
import { EntityToSynchronizeInfo } from '@record-it-npm/synchro-client';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppSynchronizationEnvironmentTypes } from '../../classes/EntityManager/AppSynchronizationEnvironmentTypes';

@autoinject()
export class ItemsToSynchronizeList {
  private subscriptionManager: SubscriptionManager;
  private availableItemsToSynchronizeInfos: Array<
    EntityToSynchronizeInfo<AppSynchronizationEnvironmentTypes>
  > = [];
  private displayedItemsToSynchronizeInfos: Array<
    EntityToSynchronizeInfo<AppSynchronizationEnvironmentTypes>
  > = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    const updateDisplayedItemsToSynchronizeInfoRateLimited =
      this.subscriptionManager.createRateLimitedCallback(
        this.updateDisplayedItemsToSynchronizeInfo.bind(this),
        250
      );

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.bindItemsToSynchronizeInfos(
        (itemsToSynchronizeInfos) => {
          this.availableItemsToSynchronizeInfos = itemsToSynchronizeInfos;
          updateDisplayedItemsToSynchronizeInfoRateLimited();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected updateDisplayedItemsToSynchronizeInfo(): void {
    this.displayedItemsToSynchronizeInfos = InstancePreserver.createNewArray({
      originalArray: this.displayedItemsToSynchronizeInfos,
      newArray: this.availableItemsToSynchronizeInfos,
      getTrackingValue: (item) => item.entityId
    });
  }
}
