import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStepAutoForm } from '../../../classes/EntityManager/entities/ProcessConfigurationStepAutoForm/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationStepAutoFormsWidgetListItem {
  @bindable()
  public processConfigurationStepAutoForm: ProcessConfigurationStepAutoForm | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationStepAutoFormPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStepAutoForm];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationStepAutoFormPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationStepAutoForm,
        context: this as ProcessConfigurationStepAutoFormsWidgetListItem,
        propertyName: 'processConfigurationStepAutoForm'
      });
  }

  protected handleAutoFormChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStepAutoForm,
      "can't ProcessConfigurationStepAutoFormsWidgetListItem.handleAutoFormChanged"
    );
    this.entityManager.processConfigurationStepAutoFormRepository.update(
      this.processConfigurationStepAutoForm
    );
  }

  protected handleDeleteAutoFormClick(): void {
    const processConfigurationStepAutoForm =
      this.processConfigurationStepAutoForm;
    assertNotNullOrUndefined(
      processConfigurationStepAutoForm,
      "can't ProcessConfigurationStepAutoFormsWidgetListItem.handleDeleteAutoFormClick"
    );

    void Dialogs.deleteEntityDialog(
      processConfigurationStepAutoForm,
      EntityName.ProcessConfigurationStepAutoForm
    ).then(() => {
      this.entityManager.processConfigurationStepAutoFormRepository.delete(
        processConfigurationStepAutoForm
      );
    });
  }
}
