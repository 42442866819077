import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import _ from 'lodash';

import { TagSortingMode } from 'common/Enums/TagSortingMode';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { DefectChangeLogEntryAction } from 'common/Types/Entities/DefectChangeLogEntry/DefectChangeLogEntryDto';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';
import { DefectLoggingService } from '../../classes/EntityManager/entities/Defect/DefectLoggingService';
import { DefectUtils } from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import {
  DefectPicture,
  Picture
} from '../../classes/EntityManager/entities/Picture/types';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EditDefectPicturesDialog } from '../../dialogs/edit-defect-pictures-dialog/edit-defect-pictures-dialog';
import { computed } from '../../hooks/computed';
import { model, expression } from '../../hooks/dependencies';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  TagsByDefectId,
  TagsByDefectIdValueComputer
} from '../../computedValues/computers/TagsByDefectIdValueComputer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { TagSorter } from '../../classes/TagSorter';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PictureFullScreenOverlay } from '../../aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';

/**
 * The area where a defect can be edited. Here, pictures can be viewed & uploaded,
 * and properties such as name, description, etc. can be viewed and changed.
 *
 * @see defect-details-widget
 * @see edit-defect-dialog
 */
@autoinject()
export class EditDefectDetailsWidget {
  @bindable public defect: Defect | null = null;

  @bindable public allowDuplicatingDefects: boolean = true;

  /**
   * Whether to show pictures & allow them to be edited
   */
  @bindable public showPictures: boolean = true;

  /**
   * true if a user is currently being invited over email,
   * false otherwise.
   */
  @bindable public isInvitingUser: boolean = false;

  protected selectedDefectAssigneeId: string | null = null;

  protected Action = DefectChangeLogEntryAction;

  protected defectTags: Array<Tag> = [];

  private lastCreatedDefects: Array<Defect> | null = null;

  private tagsByDefectId: TagsByDefectId = new Map();

  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  @subscribableLifecycle()
  protected mainPicturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly defectLoggingService: DefectLoggingService,
    private readonly defectCreationService: DefectCreationService,
    private readonly computedValueService: ComputedValueService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subManagerService.create();
    this.defectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Defect,
        context: this,
        expression: 'defect'
      });

    this.mainPicturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'mainPicture'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.defectCreationService.registerBinding(
        'lastCreatedDefects',
        (lastCreatedDefects) => {
          this.lastCreatedDefects = lastCreatedDefects;
        }
      ),
      this.computedValueService.subscribe({
        valueComputerClass: TagsByDefectIdValueComputer,
        callback: (tagsByDefectId) => {
          this.tagsByDefectId = tagsByDefectId;
          this.updateDefectTags();
        },
        computeData: {}
      })
    );

    this.activeUserCompanySettingService.bindSettingProperty(
      'general.tagSortingMode',
      (tagSortingMode) => {
        this.tagSortingMode = tagSortingMode;
        this.updateDefectTags();
      }
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected defectChanged(): void {
    this.updateDefectTags();
  }

  @computed(expression('defect'), model(EntityName.Picture))
  protected get defectPictures(): Array<DefectPicture> {
    if (!this.defect) {
      return [];
    }
    return this.entityManager.pictureRepository.getByDefectId(this.defect.id);
  }

  @computed(expression('defect'), model(EntityName.Property))
  protected get properties(): Array<Property> {
    if (this.defect) {
      return this.entityManager.propertyRepository.getGroupedPropertiesByDefectId(
        this.defect.id
      );
    } else {
      return [];
    }
  }

  protected createDefectBasedOnCurrentProperties(): void {
    if (!this.defect) return;
    const duplicatedDefect =
      this.defectCreationService.createdDefectBasedOnExistingProperties(
        this.defect
      );

    this.defect = duplicatedDefect;
  }

  @computedFrom('lastCreatedDefects', 'defect.ownerThingId')
  protected get disableCopyPropertiesFromPreviousDefectButton(): boolean {
    if (!this.defect) return true;
    const defectId = this.getSecondToLastCreatedDefectId();
    if (defectId === this.defect.id) return true;
    return defectId === undefined;
  }

  private getSecondToLastCreatedDefectId(): string | undefined {
    if (!this.defect || !this.lastCreatedDefects) return undefined;

    const allDefectsOfCurrentThing = this.lastCreatedDefects.filter(
      (d) => d.ownerThingId === this.defect?.ownerThingId
    );
    if (allDefectsOfCurrentThing.length < 2) return undefined;

    return allDefectsOfCurrentThing.find(
      (d) => d.ownerThingId === this.defect?.ownerThingId
    )?.id;
  }

  protected copyPropertiesFromPreviousDefect(): void {
    if (!this.defect) return;
    const lastDefectId = this.getSecondToLastCreatedDefectId();
    if (!lastDefectId || lastDefectId === this.defect.id) return;

    const propertiesOfLastDefect =
      this.entityManager.propertyRepository.getByDefectId(lastDefectId);
    const propertiesOfCurrentDefect =
      this.entityManager.propertyRepository.getByDefectId(this.defect.id);

    for (const propertyOfLast of propertiesOfLastDefect) {
      const propertyOfCurrent = propertiesOfCurrentDefect.find((p) =>
        PropertyHelper.isTheSameProperty(p, propertyOfLast)
      );
      if (!propertyOfCurrent) {
        DefectUtils.createExistingPropertyForDefect(
          this.entityManager,
          propertyOfLast,
          this.defect
        );
        continue;
      }

      const valueDataOfLast =
        PropertyHelper.extractValueDataFromProperty(propertyOfLast);
      const valueDataOfCurrent =
        PropertyHelper.extractValueDataFromProperty(propertyOfCurrent);

      if (!_.isEqual(valueDataOfLast, valueDataOfCurrent)) {
        Object.assign(propertyOfCurrent, valueDataOfLast);
        this.entityManager.propertyRepository.update(propertyOfCurrent);
      }
    }
  }

  protected handlePictureClicked(picture: Picture): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot open EditDefectDialog without defect'
    );

    if (!this.defectPermissionsHandle.canEditPictures) {
      void PictureFullScreenOverlay.open({
        picture
      });
      return;
    }

    void EditDefectPicturesDialog.open({
      thingId: this.defect.ownerThingId,
      defectId: this.defect.id,
      selectedPicture: picture
    });
  }

  protected handleDefectChanged(action: DefectChangeLogEntryAction): void {
    assertNotNullOrUndefined(
      this.defect,
      'Cannot update defect if it is undefined'
    );
    void this.defectLoggingService.log(this.defect, action);
    this.entityManager.defectRepository.update(this.defect);
  }

  protected handleDefectPictureChanged(picture: Picture): void {
    this.entityManager.pictureRepository.update(picture);
  }

  protected handleDefectNewPictureUploaded(): void {
    assertNotNullOrUndefined(
      this.defect,
      'Cannot update defect if it is undefined'
    );
    void this.defectLoggingService.log(
      this.defect,
      DefectChangeLogEntryAction.PICTURE_UPLOADED
    );
  }

  /**
   * Returns the main defect picture.
   */
  @computedFrom('defectPictures')
  protected get mainPicture(): DefectPicture | null {
    if (!this.defectPictures || this.defectPictures.length === 0) return null;
    return this.defectPictures.find((p) => p.selected) ?? null;
  }

  /**
   * Returns all defectPictures that aren't the main picture.
   */
  @computedFrom('defectPictures')
  protected get tailPictures(): Array<DefectPicture> | null {
    if (!this.defectPictures) return null;
    return this.defectPictures.filter((p) => !p.selected);
  }

  private updateDefectTags(): void {
    const defectTags = this.defect
      ? (this.tagsByDefectId.get(this.defect.id) ?? [])
      : [];
    this.defectTags = TagSorter.sortTags(defectTags, this.tagSortingMode);
  }

  @computed(expression('defect.ownerThingId'), model(EntityName.Thing))
  protected get thing(): Thing | null {
    if (!this.defect?.ownerThingId) return null;

    return this.entityManager.thingRepository.getById(this.defect.ownerThingId);
  }

  @computed(expression('thing.thingGroupId'), model(EntityName.ThingGroup))
  protected get thingGroup(): ThingGroup | null {
    if (!this.thing?.thingGroupId) return null;

    return this.entityManager.thingGroupRepository.getById(
      this.thing.thingGroupId
    );
  }

  @computed(
    expression('thingGroup.streetName'),
    expression('thingGroup.zip'),
    expression('thingGroup.municipality')
  )
  protected get thingGroupAddress(): string | null {
    if (!this.thingGroup) return null;

    return ThingGroupHelper.getThingGroupAddressString(
      this.thingGroup.streetName,
      this.thingGroup.zip,
      this.thingGroup.municipality
    );
  }
}
