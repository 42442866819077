import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import {
  AutoProgressConfigurationType,
  ProcessConfigurationStepDto
} from './ProcessConfigurationStepDto';

export function createProcessConfigurationStepEntityInfo<
  TEntity extends ProcessConfigurationStepDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationStepDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationStep,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationStep,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationStepDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        iconClassName: {
          name: 'iconClassName',
          defaultValue: null
        },
        iconColor: {
          name: 'iconColor',
          defaultValue: null
        },
        groupName: {
          name: 'groupName',
          defaultValue: null
        },
        appointmentSignatureText: {
          name: 'appointmentSignatureText',
          defaultValue: null
        },
        picturesRequired: {
          name: 'picturesRequired',
          defaultValue: true
        },
        signatureRequired: {
          name: 'signatureRequired',
          defaultValue: true
        },
        validFormsRequired: {
          name: 'validFormsRequired',
          defaultValue: false
        },
        undeployedDevicesRequired: {
          name: 'undeployedDevicesRequired',
          defaultValue: false
        },
        donePositionsRequired: {
          name: 'donePositionsRequired',
          defaultValue: false
        },
        smsNotifications: {
          name: 'smsNotifications',
          defaultValue: false
        },
        archive: {
          name: 'archive',
          defaultValue: false
        },
        inspection: {
          name: 'inspection',
          defaultValue: false
        },
        appointmentMeasurePointReadingsRequired: {
          name: 'appointmentMeasurePointReadingsRequired',
          defaultValue: false
        },
        order: {
          name: 'order',
          defaultValue: 0
        },
        autoProgressConfigurationType: {
          name: 'autoProgressConfigurationType',
          defaultValue: AutoProgressConfigurationType.NEXT_STEP
        },
        autoProgressConfigurationStepId: {
          name: 'autoProgressConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        autoProgressAfterReportSignatureConfigurationType: {
          name: 'autoProgressAfterReportSignatureConfigurationType',
          defaultValue: AutoProgressConfigurationType.NONE
        },
        autoProgressAfterReportSignatureConfigurationStepId: {
          name: 'autoProgressAfterReportSignatureConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
