import { UiUpdater } from '../../classes/UiUpdater';
import { ZoomBoxResizerHelper } from './ZoomBoxResizerHelper';

export class ZoomBoxContentResizer {
  /** @type {HTMLElement} */
  _contentElement;

  /** @type {HTMLElement} */
  _zoomBoxElement;

  /** @type {HTMLImageElement} */
  _imageElement;

  _initialized = false;

  /**
   * @param {HTMLElement} contentElement
   * @param {HTMLElement} zoomBoxElement
   * @param {HTMLImageElement} imageElement
   */
  constructor(contentElement, zoomBoxElement, imageElement) {
    this._contentElement = contentElement;
    this._zoomBoxElement = zoomBoxElement;
    this._imageElement = imageElement;

    this._boundHandleImageLoaded = this._handleImageLoaded.bind(this);
    this._boundHandleResize = this._handleResize.bind(this);

    this.init();
  }

  init() {
    if (this._initialized) {
      this.destroy();
    }

    this._imageElement.addEventListener('load', this._boundHandleImageLoaded);
    UiUpdater.registerResizeUpdateFunction(this._boundHandleResize);
    this._initialized = true;
  }

  destroy() {
    this._imageElement.removeEventListener(
      'load',
      this._boundHandleImageLoaded
    );
    UiUpdater.unregisterResizeUpdateFunction(this._boundHandleResize);
    this._initialized = false;
  }

  update() {
    ZoomBoxResizerHelper.updateStyle(
      this._contentElement,
      this._imageElement,
      this._zoomBoxElement
    );
  }

  _handleImageLoaded() {
    this.update();
  }

  _handleResize() {
    this.update();
  }
}
