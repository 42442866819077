import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepPositionUtils } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionUtils';

/**
 * @event edit-process-configuration-step-clicked
 */
@autoinject()
export class ProcessConfigurationStepListItem {
  @bindable()
  public processConfigurationStep: ProcessConfigurationStep | null = null;

  @bindable()
  public enabled: boolean = false;

  private panelOpened: boolean = false;

  protected listItemElement: HTMLElement | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly router: Router
  ) {}

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEnterProcessConfigurationStepClick(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      "can't ProcessConfigurationStepListItem.handleEnterProcessConfigurationStepClick without processConfigurationStep"
    );

    ProcessConfigurationStepPositionUtils.navigateToEditProcessConfigurationStepPositionsPage(
      this.router,
      this.processConfigurationStep.id
    );
  }

  protected handleEditProcessConfigurationStepClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-process-configuration-step-clicked',
      detail: null
    });
  }

  protected handleDeleteProcessConfigurationStepClick(): void {
    const processConfigurationStep = this.processConfigurationStep;
    assertNotNullOrUndefined(
      processConfigurationStep,
      "can't ProcessConfigurationStepListItem.handleDeleteProcessConfigurationStepClick without a processConfigurationStep"
    );

    void Dialogs.deleteEntityDialog(processConfigurationStep).then(() => {
      this.entityManager.processConfigurationStepRepository.delete(
        processConfigurationStep
      );
    });
  }
}
