import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationStepEntityInfo } from 'common/Types/Entities/ProcessConfigurationStep/createProcessConfigurationStepEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationStep } from './types';

export const processConfigurationStepEntityInfo =
  createProcessConfigurationStepEntityInfo<ProcessConfigurationStep>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
