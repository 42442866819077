import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  AddUserToUserGroupErrorResponseDetail,
  AddUserToUserGroupResponseDetail,
  AddUserToUserGroupSuccessResponseDetail
} from 'common/EndpointTypes/AddUserToUserGroupEndpointsHandler';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import {
  UserGroup,
  UserGroupUserSpec
} from '../../classes/EntityManager/entities/UserGroup/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { computed } from '../../hooks/computed';
import { model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class UserGroupUsersWidget {
  @bindable()
  public userGroup: UserGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  private isAttached: boolean = false;

  protected userSpecWithUsers: Array<UserSpecWithUser> = [];
  protected emailToAdd: string | null = null;
  protected isMobile: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly i18n: I18N,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.UserGroup,
        context: this as UserGroupUsersWidget,
        propertyName: 'userGroup'
      }
    );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      () => {
        this.updateUserSpecWithUsers();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.User, () => {
      this.updateUserSpecWithUsers();
    });

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.updateUserSpecWithUsers();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected userGroupChanged(): void {
    if (this.isAttached) {
      this.updateUserSpecWithUsers();
    }
  }

  private updateUserSpecWithUsers(): void {
    this.userSpecWithUsers = InstancePreserver.createNewArray({
      originalArray: this.userSpecWithUsers,
      newArray: this.getNewUserSpecWithUsers(),
      getTrackingValue: (userSpecWithUser) => userSpecWithUser.user
    });
  }

  private getNewUserSpecWithUsers(): Array<UserSpecWithUser> {
    if (!this.userGroup) {
      return [];
    }

    const userSpecWithUsers: Array<UserSpecWithUser> = [];

    for (const userSpec of this.userGroup.userSpecs) {
      const user = this.entityManager.userRepository.getById(userSpec._id);

      if (user) {
        userSpecWithUsers.push({
          userSpec,
          user
        });
      }
    }

    return userSpecWithUsers;
  }

  protected handleAddUserClick(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't UserGroupUsersWidget.handleAddUserClick without userGroup"
    );

    if (this.emailToAdd && Utils.validateEmail(this.emailToAdd)) {
      this.socketService.addUserToUserGroup(
        {
          targetUserGroupId: this.userGroup.id,
          userEmailToAdd: this.emailToAdd
        },
        (res) => {
          if (res.success) {
            NotificationHelper.notifySuccess(
              this.i18n.tr(addUserToUserGroupResponseDetailToTkMap[res.detail])
            );
            this.emailToAdd = '';
          } else {
            NotificationHelper.notifyDanger(
              this.i18n.tr(addUserToUserGroupResponseDetailToTkMap[res.detail])
            );
          }
        }
      );
    } else {
      NotificationHelper.notifyDanger(
        this.i18n.tr('dialogs.editUserGroupDialog.enterValidEmail')
      );
    }
  }

  @computed(model(EntityName.User))
  protected get userEmailList(): Array<string> {
    const users = this.entityManager.userRepository.getAll();
    const emailList = [];

    for (const user of users) {
      if (user.email) {
        emailList.push(user.email);
      }
    }

    return emailList;
  }
}

type UserSpecWithUser = {
  userSpec: UserGroupUserSpec;
  user: User;
};

const addUserToUserGroupResponseDetailToTkMap: Record<
  AddUserToUserGroupResponseDetail,
  string
> = {
  [AddUserToUserGroupSuccessResponseDetail.REQUEST_PROCESSED]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.nonAdminRequestProcessed',
  [AddUserToUserGroupSuccessResponseDetail.USER_HAS_BEEN_ADDED]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.userAdded',
  [AddUserToUserGroupErrorResponseDetail.EMAIL_SENDING_ERROR]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.emailError',
  [AddUserToUserGroupErrorResponseDetail.USERGROUP_NOT_FOUND]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.nonExistingUserGroup',
  [AddUserToUserGroupErrorResponseDetail.USER_ALREADY_IN_USERGROUP]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.userAlreadyInUserGroup',
  [AddUserToUserGroupErrorResponseDetail.USER_EMAIL_NOT_FOUND]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.nonExistingUser',
  [AddUserToUserGroupErrorResponseDetail.USER_NOT_ACTIVE]:
    'dialogs.editUserGroupDialog.addUserRequest.responseDetails.userNotActive'
};
