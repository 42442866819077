import { autoinject, bindable } from 'aurelia-framework';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import {
  QuestionSetSortOptions,
  QuestionSetUtils
} from '../../classes/EntityManager/entities/QuestionSet/QuestionSetUtils';

@autoinject()
export class ChecklistQuestionSetList {
  @bindable public questionSets: Array<QuestionSet> = [];

  /**
   * question sets retrieved from a sorter.
   * sourced from `questionSets`.
   */
  protected sortedQuestionSets: Array<QuestionSet> = [];

  /**
   * question sets paginated by pagination.
   * sourced from `sortedQuestionSets`.
   */
  protected paginatedQuestionSets: Array<QuestionSet> = [];

  protected sortOptions = QuestionSetUtils.getSortOptions();

  protected currentSortOption: QuestionSetSortOptions[keyof QuestionSetSortOptions] =
    this.sortOptions.name;
}
