import { inject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(AppEntityManager, SubscriptionManagerService)
export class ProcessTaskLogEntriesViewer {
  /** @type {(string|null)} */
  @bindable processTaskId = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {boolean} */
  _attached = false;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskLogEntry/types').ProcessTaskLogEntry>} */
  _availableLogEntries = [];

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskLogEntry,
      this._updateAvailableLogEntries.bind(this)
    );
    this._updateAvailableLogEntries();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskIdChanged() {
    if (this._attached) {
      this._updateAvailableLogEntries();
    }
  }

  _updateAvailableLogEntries() {
    if (this.processTaskId) {
      this._availableLogEntries =
        this._entityManager.processTaskLogEntryRepository
          .getByProcessTaskId(this.processTaskId)
          .slice();
      this._availableLogEntries.sort((a, b) => b.date.localeCompare(a.date));
    } else {
      this._availableLogEntries = [];
    }
  }

  /**
   * @param {import('common/processTaskLogEntryActions').ProcessTaskLogEntryAction} logAction
   * @returns {string}
   */
  _getLogActionTranslationKey(logAction) {
    return `modelsDetail.ProcessTaskLogEntryModel.logAction--${logAction}`;
  }
}
