import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

import { Property } from '../../classes/EntityManager/entities/Property/types';
import { BasePropertyWidget } from '../../propertyComponents/base-property-widget/base-property-widget';
import {
  createPropertyInputFieldPropertyWidgetConfiguration,
  PropertyInputFieldPropertyWidgetConfiguration
} from '../../propertyComponents/base-property-widget/config/propertyInputFieldPropertyWidgetConfiguration/createPropertyInputFieldPropertyWidgetConfiguration';
import { RxjsService } from '../../services/RxjsService/RxjsService';

/**
 * @event {AfterPropertyChangedEvent} after-property-changed
 */
@autoinject()
export class PropertyInputField {
  @bindable()
  public property: Property | null = null;

  @bindable()
  public inTable = false;

  @bindable()
  public compact = false;

  @bindable()
  public compactButton = false;

  @bindable()
  public forceDisabled: boolean = false;

  @bindable()
  public fillHeight: boolean = false;

  protected readonly propertyInputFieldPropertyWidgetConfiguration: PropertyInputFieldPropertyWidgetConfiguration;
  protected baseWidget: BasePropertyWidget<Property> | null = null;

  constructor(element: Element, rxjsService: RxjsService) {
    this.propertyInputFieldPropertyWidgetConfiguration =
      createPropertyInputFieldPropertyWidgetConfiguration({
        forceDisabled$: rxjsService.fromProperty({
          context: this as PropertyInputField,
          propertyName: 'forceDisabled'
        }),
        afterPropertyUpdated: ({ property }) => {
          DomEventHelper.fireEvent<AfterPropertyChangedEvent>(element, {
            name: 'after-property-changed',
            detail: {
              property
            }
          });
        }
      });
  }

  public focus(): void {
    assertNotNullOrUndefined(
      this.baseWidget,
      "can't PropertyInputFieldNew.focus without baseWidget"
    );
    this.baseWidget.focus();
  }
}

export type AfterPropertyChangedEvent = NamedCustomEvent<
  'after-property-changed',
  { property: Property }
>;
