import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../../../classes/EntityManager/entities/User/types';
import { UserGroup } from '../../../../classes/EntityManager/entities/UserGroup/types';
import { userGroupEntityInfo } from '../../../../classes/EntityManager/entities/UserGroup/userGroupEntityInfo';
import { PermissionHelper } from '../../../../classes/PermissionHelper';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class UserGroupAdapter implements EntityAdapter<UserGroup> {
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly currentUserService: CurrentUserService;
  private readonly computedValueService: ComputedValueService;

  private currentUser: User | null = null;
  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: UserGroupAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.currentUserService = options.currentUserService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        updateBindings();
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      updateBindings
    );

    return subscriptionManager.toDisposable();
  }

  public canEditField(userGroup: UserGroup): boolean {
    return this.userCanAdministerUserGroup(userGroup);
  }

  public canDeleteEntity(userGroup: UserGroup): boolean {
    return this.userCanAdministerUserGroup(userGroup);
  }

  public canAddUserSpec(userGroup: UserGroup): boolean {
    return this.userCanAdministerUserGroup(userGroup);
  }

  public canEditUserSpecs(userGroup: UserGroup): boolean {
    return this.userCanAdministerUserGroup(userGroup);
  }

  public canRemoveOwnUserSpec(): boolean {
    return this.userCanAdministerUsers();
  }

  public canEditOwnGroupAdminRights(): boolean {
    return this.userCanAdministerUsers();
  }

  public canSeePersons(): boolean {
    return this.userCanUsePersonExtension();
  }

  public canCreatePersons(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    if (!this.userCanUsePersonExtension()) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreatePersons();
  }

  public canCreateThings(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateThings();
  }

  public canCreateUserRoles(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateUserRoles();
  }

  public canCreateUserRoleToUsers(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateUserRoleToUsers();
  }

  public canCreateTextBrickTemplates(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateTextBrickTemplates();
  }

  public canCreateProcessConfigurations(userGroup: UserGroup): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateProcessConfigurations();
  }

  public canSeeControlEntityVisibilityWithAuthorizationsCheckbox(): boolean {
    if (!this.currentUser) {
      return false;
    }

    return PermissionHelper.userCanAdministerUsers(this.currentUser);
  }

  public canCreateProcessTaskAppointmentsInFieldCalendar(
    userGroup: UserGroup
  ): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(userGroup.id)
      .getCanCreateProcessTaskAppointmentsInFieldCalendar();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.UserGroup,
    UserGroup
  > {
    return userGroupEntityInfo;
  }

  private userCanAdministerUsers(): boolean {
    return this.currentUser
      ? PermissionHelper.userCanAdministerUsers(this.currentUser)
      : false;
  }

  private userCanAdministerUserGroup(userGroup: UserGroup): boolean {
    return this.currentUser
      ? PermissionHelper.userCanAdministerUserGroup(this.currentUser, userGroup)
      : false;
  }

  private userCanUsePersonExtension(): boolean {
    return PermissionHelper.userHasPermission(
      this.currentUser,
      'canUsePersonExtension'
    );
  }

  private userCanEditInUserGroup(userGroup: UserGroup): boolean {
    return this.currentUser
      ? PermissionHelper.userCanWriteInUserGroup(this.currentUser, userGroup)
      : false;
  }
}

export type UserGroupAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  currentUserService: CurrentUserService;
  computedValueService: ComputedValueService;
};
