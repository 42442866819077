import { bindable, Disposable } from 'aurelia-framework';

import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { ValueCalculationConfig } from '../../classes/EntityManager/entities/ValueCalculationConfig/types';
import { ValueCalculationResult } from '../../classes/EntityManager/entities/ValueCalculationResult/types';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import {
  ValueCalculationBaseEntityToConfig,
  ValueCalculationBaseEntityToResult,
  ValueCalculationResultAdapter,
  ValueCalculationResultSupportedBaseEntity
} from '../ValueCalculationResultAdapter/ValueCalculationResultAdapter';

export class ValueCalculationEntityListItemWidget<
  TSupportedBaseEntity extends ValueCalculationResultSupportedBaseEntity
> {
  @bindable()
  public adapter: ValueCalculationResultAdapter<TSupportedBaseEntity> | null =
    null;

  protected colorCodeSpan: HTMLSpanElement | null = null;

  private adapterDisposable: Disposable | null = null;
  private isAttached = false;
  private results: Array<
    ValueCalculationBaseEntityToResult<TSupportedBaseEntity>
  > = [];

  private configs: Array<
    ValueCalculationBaseEntityToConfig<TSupportedBaseEntity>
  > = [];

  private evalParser: ExprEvalParser = new ExprEvalParser();

  protected attached(): void {
    this.isAttached = true;

    this.subscribeToAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.unsubscribeFromAdapter();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.subscribeToAdapter();
    }
  }

  @computed(expression('configs'))
  protected get configToShowOnEntityListItem(): ValueCalculationConfig | null {
    return this.configs.find((c) => c.showInEntityOverview) ?? null;
  }

  @computed(expression('configToShowOnEntityListItem'), expression('results'))
  protected get resultToShowOnEntityListItem(): ValueCalculationResult | null {
    return !!this.configToShowOnEntityListItem
      ? (this.results.find(
          (r) =>
            r.valueCalculationConfigId === this.configToShowOnEntityListItem?.id
        ) ?? null)
      : null;
  }

  @computed(
    expression('resultToShowOnEntityListItem'),
    expression('configToShowOnEntityListItem.colorCodeConfig')
  )
  protected get computedColor(): { color: string } | null {
    if (
      !this.resultToShowOnEntityListItem?.value ||
      !this.configToShowOnEntityListItem
    )
      return null;

    const colorConfigCandidates =
      this.configToShowOnEntityListItem?.colorCodeConfig?.colorConfigs ?? [];
    if (colorConfigCandidates.length === 0) {
      return null;
    }

    for (const colorConfig of colorConfigCandidates) {
      const resultNumber = Number(
        this.resultToShowOnEntityListItem.value.replace(',', '.')
      );
      const result = !Number.isNaN(resultNumber)
        ? resultNumber
        : this.resultToShowOnEntityListItem.value;

      const exprResult = this.evalParser.evaluateExpression(
        colorConfig.condition,
        { x: result }
      );
      if (exprResult === true) {
        return {
          color: colorConfig.color
        };
      }
    }

    return null;
  }

  private subscribeToAdapter(): void {
    this.unsubscribeFromAdapter();

    if (this.adapter) {
      this.adapterDisposable = this.adapter.subscribe({
        setResults: (results) => {
          this.results = results;
        },
        setConfigs: (configs) => {
          this.configs = configs;
        }
      });
    }
  }

  private unsubscribeFromAdapter(): void {
    this.adapterDisposable?.dispose();
    this.adapterDisposable = null;
    this.results = [];
    this.configs = [];
  }
}
