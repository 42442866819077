import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { StructureTemplateEntryGroupDto } from './StructureTemplateEntryGroupDto';

export function createStructureTemplateEntryGroupEntityInfo<
  TEntity extends StructureTemplateEntryGroupDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructureTemplateEntryGroupDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplateEntryGroup,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplateEntryGroup,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateEntryGroupDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        customId: {
          name: 'customId',
          defaultValue: null
        },
        referenceId: {
          name: 'referenceId',
          defaultValue: null
        },
        ownerStructureTemplateId: {
          name: 'ownerStructureTemplateId',
          ref: EntityName.StructureTemplate,
          cascadeDeleteLocally: true,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
