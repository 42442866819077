import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskPositionDialog } from '../edit-process-task-position-dialog/edit-process-task-position-dialog';
import { Dialogs } from '../../classes/Dialogs';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { CreateProcessTaskPositionDialog } from '../create-process-task-position-dialog/create-process-task-position-dialog';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ProcessTaskPositionListItem } from '../process-task-position-list-item/process-task-position-list-item';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  PositionCategoryGroup,
  ProcessTaskPositionUtils
} from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { DragAndDropViewModelInfo } from '../process-task-position-list/process-task-position-list';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';

@autoinject()
export class ProcessTaskAppointmentPositionsList {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public enabled: boolean = false;

  /**
   * read-only!
   * count of the displayed positions
   * is null when no positions are loaded
   */
  @bindable()
  public positionCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private positions: Array<ProcessTaskPosition> = [];
  protected categorizedPositions: Array<PositionCategoryGroup> = [];
  private isAttached = false;

  protected positionCategoryGroupIdGetter = (
    p: PositionCategoryGroup
  ): string | null => p.categoryId;
  protected positionCategoryItemCountGetter = (
    items: Array<PositionCategoryGroup>
  ): number => items.reduce((sum, group) => sum + group.positions.length, 0);

  protected dragAndDropViewModelInfo: DragAndDropViewModelInfo<ProcessTaskPositionListItem> =
    {
      viewModelConstructor: ProcessTaskPositionListItem,
      getPositionFromViewModel: (viewModel) => {
        assertNotNullOrUndefined(
          viewModel.processTaskPosition,
          'dragged a position-list-item without a process-task-position'
        );
        return viewModel.processTaskPosition;
      }
    };

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updatePositions.bind(this)
    );
    this.updatePositions();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updatePositions();
    }
  }

  private updatePositions(): void {
    if (this.processTaskAppointment) {
      const positions =
        this.entityManager.processTaskPositionRepository.getByCreatedAtAppointmentIdWithoutSnapshots(
          this.processTaskAppointment.id
        );
      this.positions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(positions);
      this.positionCount = this.positions.length;
    } else {
      this.positions = [];
      this.positionCount = null;
    }

    this.updateCategorizedPositions();
  }

  private updateCategorizedPositions(): void {
    this.categorizedPositions = ProcessTaskPositionUtils.categorizePositions(
      this.positions
    );
  }

  protected handleAddPositionClick(
    category: ProcessConfigurationCategory | null
  ): void {
    if (!this.processTaskAppointment || !this.processTask) {
      return;
    }

    void CreateProcessTaskPositionDialog.open({
      processTask: this.processTask,
      processConfigurationCategory: category,
      createdAtAppointmentId: this.processTaskAppointment.id,
      onDialogClosed: (createdPosition) => {
        if (createdPosition) {
          this.updatePositions();
          this.goToPosition(createdPosition);
        }
      }
    });
  }

  protected handleEditPositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void EditProcessTaskPositionDialog.open({
      position: position,
      onDialogClosed: () => {
        this.goToPosition(position);
      }
    });
  }

  protected handleDeletePositionButtonClicked(
    position: ProcessTaskPosition
  ): void {
    void Dialogs.deleteEntityDialog(position).then(() => {
      this.entityManager.processTaskPositionRepository.delete(position);
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: position,
        displayNameAtLogTime: position.name
      });
    });
  }

  private goToPosition(position: ProcessTaskPosition): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getPositionListItemId(position.id),
      null,
      position,
      () => this.isAttached
    );
  }

  private getPositionListItemId(positionId: string): string {
    return 'process-task-appointment-positions-list--position-' + positionId;
  }
}
