import { computedFrom } from 'aurelia-framework';
import { UserRole } from '../../../classes/EntityManager/entities/UserRole/types';
import { UserRoleAdapter } from '../EntityAdapter/UserRoleAdapter/UserRoleAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class UserRolePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<UserRoleAdapter>,
  UserRole
> {
  @computedFrom('combinedRevision')
  public get canSeeCanSeeEntitiesWithoutThingAuthorizationCheckbox(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeCanSeeEntitiesWithoutThingAuthorizationCheckbox'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeeCanSeePersonsWithoutExplicitAuthorizationCheckbox(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeCanSeePersonsWithoutExplicitAuthorizationCheckbox'
    );
  }

  @computedFrom('combinedRevision')
  public get canSeeCanSeeEntitiesWithoutProcessTaskGroupAuthorizationCheckbox(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeCanSeeEntitiesWithoutProcessTaskGroupAuthorizationCheckbox'
    );
  }
}
