import { autoinject, bindable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { CreateMapPictureDialog } from '../../dialogs/create-map-picture-dialog/create-map-picture-dialog';
import { PictureCreatorServiceWithEntityData } from '../../classes/Picture/PictureCreatorService';
import {
  SavePictureCallbackProjectOptions,
  Type,
  UltraRapidFireWidget
} from '../../aureliaComponents/ultra-rapid-fire-widget/ultra-rapid-fire-widget';
import { UltraRapidFireWidgetEditDialog } from '../../aureliaComponents/ultra-rapid-fire-widget-edit-dialog/ultra-rapid-fire-widget-edit-dialog';
import { ProcessTaskProjectService } from '../../services/ProcessTaskProjectService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { UploadChoiceSelectedEvent } from '../../picture/picture-upload-area/picture-upload-area';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { TemporaryJoinedProjectHandle } from '../../classes/EntityManager/entities/Project/TemporaryJoinedProjectHandle';
import { PictureProcessTaskToProjectType } from '../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectRepository';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EmbeddedCameraPictureCaptureService } from '../../services/EmbeddedCameraPictureCaptureService/EmbeddedCameraPictureCaptureService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskProjectPictureTiles {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskToProjectType: PictureProcessTaskToProjectType | null =
    null;

  @bindable()
  public enablePictureCreation: boolean = true;

  @bindable()
  public enablePictureSelection: boolean = false;

  @bindable()
  public selectedEntryIds: Array<string> = [];

  /**
   * read-only!
   * count of the displayed positions
   * is null when no pictures are loaded
   */
  @bindable()
  public pictureCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly projectHandle: TemporaryJoinedProjectHandle;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private isAttached = false;
  private entries: Array<Entry> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskProjectService: ProcessTaskProjectService,
    private readonly embeddedCameraPictureCaptureService: EmbeddedCameraPictureCaptureService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.projectHandle = new TemporaryJoinedProjectHandle(entityManager);
    this.projectHandle.enableAutoJoining();

    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskProjectPictureTiles,
        propertyName: 'processTask'
      });

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.Thing,
        context: this as ProcessTaskProjectPictureTiles,
        propertyName: 'processTask',
        idPropertyName: 'thingId'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateProject.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateProject.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateEntries.bind(this)
    );

    this.updateProject();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();

    this.projectHandle.reset();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProject();
    }
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateProject();
    }
  }

  protected processTaskToProjectTypeChanged(): void {
    if (this.isAttached) {
      this.updateProject();
    }
  }

  private updateProject(): void {
    if (!this.processTask) {
      return;
    }

    let newProject: Project | null = null;

    const firstRelation =
      this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndAppointmentId(
        this.processTask.id,
        this.processTaskAppointment ? this.processTaskAppointment.id : null,
        this.processTaskToProjectType
      )[0];
    if (firstRelation) {
      newProject = this.entityManager.projectRepository.getById(
        firstRelation.projectId
      );
    }

    this.projectHandle.project = newProject;
    this.updateEntries();
  }

  private updateEntries(): void {
    if (this.projectHandle.project) {
      this.entries = this.entityManager.entryRepository.getByProjectId(
        this.projectHandle.project.id,
        null
      );
    } else {
      this.entries = [];
    }

    this.pictureCount = this.processTask ? this.entries.length : null;
  }

  protected handleSelectAllCheckedChanged(
    $event: CustomCheckboxCheckedChangedEvent
  ): void {
    const checked = $event.detail.checked;

    this.entries.forEach((entry) => {
      const index = this.selectedEntryIds.indexOf(entry.id);
      if (index === -1 && checked) {
        this.selectedEntryIds.push(entry.id);
      } else if (index >= 0 && !checked) {
        this.selectedEntryIds.splice(index, 1);
      }
    });
  }

  protected handleEntryClick(entry: Entry): void {
    if (this.enablePictureSelection) {
      this.handleToggleEntrySelectedClick(entry);
    } else {
      this.handleEditEntryClick(entry);
    }
  }

  protected handleToggleEntrySelectedClick(entry: Entry): void {
    const index = this.selectedEntryIds.indexOf(entry.id);

    if (index >= 0) {
      this.selectedEntryIds.splice(index, 1);
    } else {
      this.selectedEntryIds.push(entry.id);
    }
  }

  protected handleEditEntryClick(entry: Entry): void {
    if (!this.processTask) {
      return;
    }

    const pictures = this.entityManager.pictureRepository.getByEntryId(
      entry.id
    );

    let picture = pictures.find((p) => p.selected);
    if (!picture) {
      picture = pictures[0];
    }

    if (picture) {
      void UltraRapidFireWidgetEditDialog.open({
        type: Type.PROJECT,
        picture: picture,
        projectId: entry.project,
        userGroupId: entry.ownerUserGroupId
      });
    }
  }

  protected async handleUploadChoiceSelected(
    event: UploadChoiceSelectedEvent
  ): Promise<void> {
    switch (event.detail.method) {
      case 'file':
        const files =
          event.detail.files && event.detail.files.length >= 1
            ? event.detail.files
            : null;
        if (files) {
          await this.handleFileMethod(files);
        }
        break;

      case 'sketch':
        this.handleSketchMethod();
        break;

      case 'map':
        this.handleMapMethod();
        break;

      case 'camera':
        void this.handleCameraMethod();
        break;

      case 'urfm':
        this.handleUrfmMethod();
        break;

      default:
        throw new Error(`unknown method "${event.detail.method}"`);
    }
  }

  private async handleFileMethod(files: Array<File>): Promise<void> {
    if (!this.processTask) {
      throw new Error('processTask is not set');
    }
    const { creator } = this.prepareImageCreation(
      this.processTask,
      this.processTaskAppointment
    );

    for (const file of files) {
      await creator.createPictureFromFile(file);
    }
  }

  private handleSketchMethod(): void {
    if (!this.processTask) {
      throw new Error('processTask is not set');
    }
    const { creator } = this.prepareImageCreation(
      this.processTask,
      this.processTaskAppointment
    );
    creator.createWhitePicture();
  }

  private handleMapMethod(): void {
    void CreateMapPictureDialog.open({
      onImageCreated: (dataUrl) => {
        if (!this.processTask) {
          throw new Error('processTask is not set');
        }
        const { creator } = this.prepareImageCreation(
          this.processTask,
          this.processTaskAppointment
        );
        creator.createPictureFromDataUrl(dataUrl);
      }
    });
  }

  private async handleCameraMethod(): Promise<void> {
    if (!this.processTask) {
      return;
    }

    await this.embeddedCameraPictureCaptureService.capturePicture({
      name: 'capturedProcessTaskPicture',
      data: {
        processTaskId: this.processTask.id,
        processTaskAppointmentId: this.processTaskAppointment?.id ?? null,
        processTaskToProjectType: this.processTaskToProjectType
      },
      onCapturedPictureHandled: null
    });
  }

  private handleUrfmMethod(): void {
    if (!this.processTask) {
      return;
    }

    const project = this.getOrCreateProject(
      this.processTask,
      this.processTaskAppointment
    );

    const options: SavePictureCallbackProjectOptions = {
      type: Type.PROJECT,
      projectId: project.id,
      ownerUserGroupId: this.processTask.ownerUserGroupId,
      properties: [],
      tagIds: []
    };
    void UltraRapidFireWidget.start({
      options: options,
      savePictureCallback: async () => {
        if (!this.processTask) {
          throw new Error("ProcessTask or Appointment isn't set");
        }

        const { creator } = this.prepareImageCreation(
          this.processTask,
          this.processTaskAppointment || null
        );
        const picture = creator.createPicture();
        this.addCoordsToPicture(picture);
        return picture;
      }
    });
  }

  private addCoordsToPicture(picture: Picture): void {
    const coordinates = CoordinateHelper.getClientCoordinates();
    if (coordinates.latitude && coordinates.longitude) {
      picture.coords = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude
      };
    }
  }

  private prepareImageCreation(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment | null
  ): { creator: PictureCreatorServiceWithEntityData; entry: Entry } {
    const { creator, entry } =
      this.processTaskProjectService.prepareImageCreation({
        processTask,
        processTaskAppointment,
        processTaskToProjectType: this.processTaskToProjectType
      });

    this.updateProject();

    return {
      creator,
      entry
    };
  }

  private getOrCreateProject(
    processTask: ProcessTask,
    processTaskAppointment: ProcessTaskAppointment | null
  ): Project {
    const project = this.processTaskProjectService.getOrCreateProject(
      processTask,
      processTaskAppointment,
      this.processTaskToProjectType
    );
    this.updateProject();
    return project;
  }

  protected entryIsSelected(
    entryId: string,
    selectedEntryIds: Array<string>,
    _selectedEntryIdsLength: number
  ): boolean {
    return selectedEntryIds.indexOf(entryId) >= 0;
  }

  protected allEntriesSelected(
    entries: Array<Entry>,
    selectedEntryIds: Array<string>,
    _entriesLength: number,
    _selectedEntryIdsLength: number
  ): boolean {
    return entries.every((entry) => selectedEntryIds.indexOf(entry.id) >= 0);
  }
}
