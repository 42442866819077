import { autoinject, bindable } from 'aurelia-framework';

import {
  PositionPrices,
  ProcessTaskPositionCalculator
} from 'common/Operations/ProcessTaskPositionCalculator';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EditProcessTaskPositionDialog } from '../edit-process-task-position-dialog/edit-process-task-position-dialog';
import {
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer
} from '../../computedValues/computers/ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskPositionUtils } from '../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { Dialogs } from '../../classes/Dialogs';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskInvoiceToProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';

@autoinject()
export class ProcessTaskInvoiceRelationsWidgetPositions {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public allPositionsSelected: boolean = false;

  /**
   * is null when positions are not loaded
   * read only
   */
  @bindable()
  public processTaskPositionCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private availableProcessTaskPositions: Array<ProcessTaskPosition> = [];
  private availableProcessTaskInvoiceToProcessTaskPositions: Array<ProcessTaskInvoiceToProcessTaskPosition> =
    [];

  private calculator: ProcessTaskPositionCalculator | null = null;
  private detailEntriesByPositionId: ProcessTaskPositionDetailEntriesByProcessTaskPositionId =
    new Map();
  private positionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];
  /**
   * all prices which are included in the invoice
   */
  protected includedPositionPrices: Array<
    PositionPrices<ProcessTaskPosition, ProcessTaskPositionDetailEntry>
  > = [];

  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.computedValueService = computedValueService;
    this.processTaskLoggingService = processTaskLoggingService;
  }

  public selectAll(): void {
    this.availableProcessTaskPositions.forEach((position) => {
      this.ensureRelationStatusToPosition(position, true);
    });

    this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
  }

  public deselectAll(): void {
    this.availableProcessTaskPositions.forEach((position) => {
      this.ensureRelationStatusToPosition(position, false);
    });

    this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      this.updateAvailableProcessTaskPositions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateAvailableProcessTaskPositions.bind(this)
    );
    this.updateAvailableProcessTaskPositions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTaskPosition,
      this.updateAvailableProcessTaskInvoiceToProcessTaskPositions.bind(this)
    );
    this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskGroup
            ? { processTaskGroupId: this.processTaskGroup.id }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskGroup.id',
            updateSubscription
          );
        },
        callback: (config) => {
          this.calculator = new ProcessTaskPositionCalculator(config);
          this.updatePositionPrices();
        },
        onNoComputeData: () => {
          this.calculator = null;
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer,
        createComputeData: () =>
          this.processTask ? { ownerProcessTaskId: this.processTask.id } : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            updateSubscription
          );
        },
        callback: (result) => {
          this.detailEntriesByPositionId = result;
          this.updatePositionPrices();
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
    }
  }

  protected processTaskInvoiceChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTaskPositions();
      this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
    }
  }

  private updateAvailableProcessTaskPositions(): void {
    const processTask = this.processTask;
    const processTaskInvoice = this.processTaskInvoice;

    if (processTask && processTaskInvoice) {
      let availablePositions;
      if (processTaskInvoice.doneAt != null) {
        availablePositions =
          this.availableProcessTaskInvoiceToProcessTaskPositions.map((r) =>
            this.entityManager.processTaskPositionRepository.getRequiredById(
              r.processTaskPositionId
            )
          );
      } else {
        availablePositions = this.getAvailableProcessTaskPositionsForCategory({
          processTask,
          processTaskInvoice
        });
      }
      this.availableProcessTaskPositions =
        ProcessTaskPositionUtils.sortProcessTaskPositions(availablePositions);
      this.processTaskPositionCount = this.availableProcessTaskPositions.length;
    } else {
      this.availableProcessTaskPositions = [];
      this.processTaskPositionCount = null;
    }

    this.updatePositionPrices();
    this.updateAllPositionsSelected();
  }

  private getAvailableProcessTaskPositionsForCategory({
    processTask,
    processTaskInvoice
  }: {
    processTask: ProcessTask;
    processTaskInvoice: ProcessTaskInvoice;
  }): Array<ProcessTaskPosition> {
    const category = processTaskInvoice.processConfigurationCategoryId
      ? this.entityManager.processConfigurationCategoryRepository.getById(
          processTaskInvoice.processConfigurationCategoryId
        )
      : null;
    const invoiceIsGeneral = category ? category.general : false;
    const positions =
      this.entityManager.processTaskPositionRepository.getByProcessTaskIdWithoutSnapshots(
        processTask.id
      );
    return positions.filter((p) => {
      return (
        invoiceIsGeneral ||
        processTaskInvoice.processConfigurationCategoryId == null ||
        p.processConfigurationCategoryId == null ||
        processTaskInvoice.processConfigurationCategoryId ===
          p.processConfigurationCategoryId
      );
    });
  }

  private updateAvailableProcessTaskInvoiceToProcessTaskPositions(): void {
    if (this.processTaskInvoice && this.processTask) {
      this.availableProcessTaskInvoiceToProcessTaskPositions =
        this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.getByProcessTaskInvoiceIdAndProcessTaskId(
          this.processTaskInvoice.id,
          this.processTask.id
        );
    } else {
      this.availableProcessTaskInvoiceToProcessTaskPositions = [];
    }

    this.updateIncludedPositionPrices();
    this.updateAvailableProcessTaskPositions();
  }

  private updatePositionPrices(): void {
    if (this.calculator) {
      const positionInfos =
        ProcessTaskPositionUtils.createPositionInfosForCalculation(
          this.availableProcessTaskPositions,
          this.detailEntriesByPositionId
        );
      this.positionPrices =
        this.calculator.calculatePricesOfPositions(positionInfos);
    } else {
      this.positionPrices = [];
    }

    this.updateIncludedPositionPrices();
  }

  private updateIncludedPositionPrices(): void {
    this.includedPositionPrices = this.positionPrices.filter((prices) => {
      return this.positionIdIsIncluded(
        prices.originalPosition.id,
        this.availableProcessTaskInvoiceToProcessTaskPositions
      );
    });
  }

  private updateAllPositionsSelected(): void {
    this.allPositionsSelected = this.availableProcessTaskPositions.every(
      (position) => {
        return this.availableProcessTaskInvoiceToProcessTaskPositions.find(
          (itp) => itp.processTaskPositionId === position.id
        );
      }
    );
  }

  protected handleEditButtonClicked(position: ProcessTaskPosition): void {
    void EditProcessTaskPositionDialog.open({
      position
    });
  }

  protected handleDeleteButtonClicked(position: ProcessTaskPosition): void {
    void Dialogs.deleteEntityDialog(position).then(() => {
      this.entityManager.processTaskPositionRepository.delete(position);
      void this.processTaskLoggingService.logProcessTaskSubEntityDeleted({
        entityName: EntityName.ProcessTaskPosition,
        entity: position,
        displayNameAtLogTime: position.name
      });
    });
  }

  protected handlePositionCheckedChanged(
    position: ProcessTaskPosition,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    this.ensureRelationStatusToPosition(position, event.detail.checked);
    this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
  }

  /**
   * @param position
   * @param available - true if a relation should exist, false to to ensure no relation exists
   */
  private ensureRelationStatusToPosition(
    position: ProcessTaskPosition,
    available: boolean
  ): void {
    const relation =
      this.availableProcessTaskInvoiceToProcessTaskPositions.find(
        (r) => r.processTaskPositionId === position.id
      );

    if (available) {
      if (!relation) {
        this.createProcessTaskInvoiceToProcessTaskPosition(position);
      }
    } else {
      if (relation) {
        this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.delete(
          relation
        );
      }
    }
  }

  private createProcessTaskInvoiceToProcessTaskPosition(
    position: ProcessTaskPosition
  ): void {
    if (!this.processTaskInvoice) {
      return;
    }

    this.entityManager.processTaskInvoiceToProcessTaskPositionRepository.create(
      {
        ownerProcessTaskGroupId:
          this.processTaskInvoice.ownerProcessTaskGroupId,
        ownerUserGroupId: this.processTaskInvoice.ownerUserGroupId,
        processTaskInvoiceId: this.processTaskInvoice.id,
        ownerProcessTaskId: position.ownerProcessTaskId,
        processTaskPositionId: position.id,
        temporaryGroupName: position.temporaryGroupName
      }
    );

    this.updateAvailableProcessTaskInvoiceToProcessTaskPositions();
  }

  private positionIdIsIncluded(
    positionId: string,
    availableProcessTaskInvoiceToProcessTaskPositions: Array<ProcessTaskInvoiceToProcessTaskPosition>
  ): boolean {
    return !!availableProcessTaskInvoiceToProcessTaskPositions.find(
      (r) => r.processTaskPositionId === positionId
    );
  }
}
