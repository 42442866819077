import { autoinject, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { EditProcessConfigurationStepPositionDialog } from '../../operationsComponents/edit-process-configuration-step-position-dialog/edit-process-configuration-step-position-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { OperationsOfferPositionsViewDialog } from '../../operationsComponents/operations-offer-positions-view-dialog/operations-offer-positions-view-dialog';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessConfigurationStepPositionUtils } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionUtils';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationStepPosition } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepPositionFilter } from '../../operationsComponents/process-configuration-step-position-filter/process-configuration-step-position-filter';
import { ProcessConfigurationStepPositionCreationService } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/ProcessConfigurationStepPositionCreationService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class edit_process_configuration_step_positions {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationStepPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStep];

  private processConfigurationStep: ProcessConfigurationStep | null = null;
  private isAttached: boolean = false;
  private availablePositions: Array<ProcessConfigurationStepPosition> = [];
  protected groupedPositions: Array<EditProcessConfigurationStepPositionsPositionGroup> =
    [];

  protected selectedFilterPersonId: string | null = null;

  @observable()
  private filteredPositions: Array<ProcessConfigurationStepPosition>;

  @observable()
  private isMobile;

  protected positionFilter: ProcessConfigurationStepPositionFilter | null =
    null;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly i18n: I18N,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly entityManager: AppEntityManager,
    private readonly processConfigurationStepPositionCreationService: ProcessConfigurationStepPositionCreationService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationStepPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationStep,
        context: this,
        expression: 'processConfigurationStep'
      });

    this.filteredPositions = [];
    this.isMobile = false;
  }

  protected activate(params: { process_configuration_step_id: string }): void {
    this.processConfigurationStep =
      this.entityManager.processConfigurationStepRepository.getRequiredById(
        params.process_configuration_step_id
      );

    this.activeEntitiesService.setActiveProcessConfigurationStep(
      this.processConfigurationStep
    );
    if (this.isAttached) {
      this.updateAvailablePositions();
    }
  }

  protected deactivate(): void {
    this.activeEntitiesService.setActiveProcessConfigurationStep(null);
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStepPosition,
      this.updateAvailablePositions.bind(this)
    );
    this.updateAvailablePositions();

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: [
        {
          name: 'preview',
          labelTk:
            'operations.editProcessConfigurationStepPositions.previewButtonText'
        }
      ],
      selectedCallbacks: {
        preview: this.handleShowPositionsPreviewClick.bind(this)
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();

    GlobalMenu.releaseControl(this);
  }

  protected isMobileChanged(): void {
    GlobalMenu.setVisible(this, this.isMobile);
  }

  protected filteredPositionsChanged(): void {
    this.updateGroupedPositions();
  }

  private updateAvailablePositions(): void {
    if (this.processConfigurationStep) {
      this.availablePositions =
        this.entityManager.processConfigurationStepPositionRepository.getByProcessConfigurationStepId(
          this.processConfigurationStep.id
        );
    } else {
      this.availablePositions = [];
    }
  }

  private updateGroupedPositions(): void {
    const groups: Array<EditProcessConfigurationStepPositionsPositionGroup> =
      [];

    this.filteredPositions.forEach((position) => {
      this.groupPosition(position, groups);
    });

    this.sortGroups(groups);

    this.groupedPositions = groups;
  }

  private sortGroups(
    groups: Array<EditProcessConfigurationStepPositionsPositionGroup>
  ): void {
    groups.sort((a, b) => {
      const categoryNameResult = a.categoryName.localeCompare(b.categoryName);
      const groupNameResult = a.groupName.localeCompare(b.groupName);
      return categoryNameResult !== 0 ? categoryNameResult : groupNameResult;
    });

    groups.forEach((group) => {
      group.positions.sort((a, b) => {
        return ProcessConfigurationStepPositionUtils.getFullName(
          a.namePrefix,
          a.name
        ).localeCompare(
          ProcessConfigurationStepPositionUtils.getFullName(
            b.namePrefix,
            b.name
          )
        );
      });
    });
  }

  private groupPosition(
    position: ProcessConfigurationStepPosition,
    groups: Array<EditProcessConfigurationStepPositionsPositionGroup>
  ): void {
    const categoryName =
      position.categoryName ||
      (this.i18n.tr(
        'modelsDetail.ProcessConfigurationStepPositionModel.defaultCategoryName'
      ) as string);
    const groupName =
      position.groupName ||
      (this.i18n.tr(
        'modelsDetail.ProcessConfigurationStepPositionModel.defaultGroupName'
      ) as string);
    const subGroupName = position.subGroupName || '';

    let group = groups.find((g) => {
      return (
        g.groupName === groupName &&
        g.categoryName === categoryName &&
        g.subGroupName === subGroupName
      );
    });

    if (!group) {
      group = {
        categoryName: categoryName,
        groupName: groupName,
        subGroupName: subGroupName,
        positions: []
      };

      groups.push(group);
    }

    if (group.positions.indexOf(position) === -1) {
      group.positions.push(position);
    }
  }

  protected handleShowPositionsPreviewClick(): void {
    void OperationsOfferPositionsViewDialog.open({
      positions: this.availablePositions
    });
  }

  protected handleCreateProcessConfigurationStepPositionClicked(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStep,
      "can't edit_process_configuration_step_positions.handleCreateProcessConfigurationStepPositionClicked without processConfigurationStep"
    );

    const position =
      this.processConfigurationStepPositionCreationService.create({
        processConfigurationStep: this.processConfigurationStep
      });

    this.openPositionEditDialog(position);
  }

  protected handleEditPositionClicked(
    position: ProcessConfigurationStepPosition
  ): void {
    this.openPositionEditDialog(position);
  }

  private openPositionEditDialog(
    position: ProcessConfigurationStepPosition
  ): void {
    void EditProcessConfigurationStepPositionDialog.open({
      processConfigurationStepPosition: position,
      onDialogClosed: () => {
        this.goToPosition(position);
      }
    });
  }

  private goToPosition(position: ProcessConfigurationStepPosition): void {
    if (this.positionFilter) {
      this.positionFilter.setFilterToIncludePosition(position);
    }

    void ScrollHelper.autoScrollToListItem(
      '#' + this.getPositionListItemId(position.id),
      undefined,
      undefined,
      () => this.isAttached
    );
  }

  private getPositionListItemId(positionId: string): string {
    return 'edit-process-configuration-step-positions--position-' + positionId;
  }
}

type EditProcessConfigurationStepPositionsPositionGroup = {
  categoryName: string;
  groupName: string;
  subGroupName: string | null;
  positions: Array<ProcessConfigurationStepPosition>;
};
