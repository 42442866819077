import { PathCommand, CommandIdentifier } from './PathCommand';
import { Vector } from 'common/Geometry/Vector';

export class PathCommandStringBuilder {
  public buildString(commands: Array<PathCommand>): string {
    let str = '';

    for (const command of commands) {
      if (str.length) {
        str += ' ';
      }

      str += this.buildStringForCommand(command);
    }

    return str;
  }

  private buildStringForCommand(command: PathCommand): string {
    switch (command.identifier) {
      case CommandIdentifier.M:
      case CommandIdentifier.m:
      case CommandIdentifier.L:
      case CommandIdentifier.l:
        return this.buildPointsCommandString(command.identifier, [
          command.point
        ]);

      case CommandIdentifier.C:
      case CommandIdentifier.c:
        return this.buildPointsCommandString(command.identifier, [
          command.cp1,
          command.cp2,
          command.point
        ]);

      case CommandIdentifier.Z:
      case CommandIdentifier.z:
        return command.identifier;

      default:
        // @ts-ignore - typescript says this will never happen because we handled all cases, but it could happen when types get added
        const identifier: string = command.identifier;

        throw new Error(
          `unsupported command "${identifier}" for building string`
        );
    }
  }

  private buildPointsCommandString(
    identifier: CommandIdentifier,
    points: Array<Vector>
  ): string {
    let pointsString: string = '';

    for (const point of points) {
      if (pointsString.length) {
        pointsString += ' ';
      }

      pointsString += `${point.getX()} ${point.getY()}`;
    }

    return identifier + pointsString;
  }
}
