import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { EntryUsageStatistic } from './types';

export class EntryUsageStatisticRepository extends AppEntityRepository<EntityName.EntryUsageStatistic> {
  /**
   * retrieve the EntryUsageStatistic entity with the given entryId & userId.
   */
  public getByEntryAndUserId(
    entryId: string,
    userId: string
  ): EntryUsageStatistic | null {
    return (
      this.getAll().find((e) => e.entryId === entryId && e.userId === userId) ??
      null
    );
  }

  /**
   * retrieve the EntryUsageStatistic entity with the given entryId & userId. If none is found, a new one is created.
   *
   * (This is not atomic, but doesn't need to be because one entity is only ever used by one user)
   */
  public getOrCreateByEntryAndUserId(
    entryId: string,
    userId: string,
    ownerIds: { ownerUserGroupId: string; ownerProjectId: string }
  ): EntryUsageStatistic {
    let entity = this.getByEntryAndUserId(entryId, userId);

    if (!entity) {
      entity = this.create({
        entryId,
        userId,
        useCount: 0,
        ownerUserGroupId: ownerIds.ownerUserGroupId,
        ownerProjectId: ownerIds.ownerProjectId
      });
    }

    return entity;
  }

  /**
   * Increment the useCount of the entryUsageStatistic entity with the given `entryId` and `userId`.
   */
  public incrementUseCount(
    entryId: string,
    userId: string,
    ownerIds: { ownerUserGroupId: string; ownerProjectId: string }
  ): EntryUsageStatistic {
    const entity = this.getOrCreateByEntryAndUserId(entryId, userId, ownerIds);
    entity.useCount++;
    this.update(entity);
    return entity;
  }
}
