export class StringUtils {
  private static upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ'.split('');

  public static upperCaseFirstLetter(str: string): string {
    return this.modifyFirstChar(str, (c) => c.toLocaleUpperCase());
  }

  public static lowerCaseFirstLetter(str: string): string {
    return this.modifyFirstChar(str, (c) => c.toLocaleLowerCase());
  }

  /**
   * checks if the value is null, undefined or ''
   */
  public static isNullOrEmpty(value: string | null | undefined): boolean {
    return value === null || value === undefined || value === '';
  }

  public static priceToString(
    price: number,
    options?: PriceToStringOptions
  ): string {
    if (options?.withSign) {
      return this.numberWithSign(price, {
        fractionDigits: 2,
        spaceBetweenSign: options.spaceBetweenSign
      });
    }

    return this.fixedNumber(price, 2);
  }

  /**
   * Formats the number to a string so it will always contain the sign, even for positive numbers
   */
  public static numberWithSign(
    value: number,
    options?: NumberWithSignOptions
  ): string {
    const absoluteString = this.fixedNumber(
      Math.abs(value),
      options?.fractionDigits
    );

    let sign: string;
    if (value >= 0) {
      sign = '+';
    } else {
      sign = '-';
    }

    const optionalSpace = options?.spaceBetweenSign ? ' ' : '';

    return `${sign}${optionalSpace}${absoluteString}`;
  }

  /**
   * splits words from the string to search for and filters out empty strings
   */
  public static splitWords(str: string): Array<string> {
    const words = str.split(/\.|-| /);
    const trimmedWords = words.map((w) => w.trim());
    return trimmedWords.filter((w) => w.length > 0);
  }

  /**
   * simple implementation, if a word is contained twice in words, and only once in the string, then it still counts as two matches
   */
  public static matchWords(str: string, words: Array<string>): number {
    let matches = 0;

    words.forEach((w) => {
      if (str.indexOf(w) >= 0) {
        matches++;
      }
    });

    return matches;
  }

  public static orderByArray(
    sortedArray: Array<any>,
    a: string | null | undefined,
    b: string | null | undefined
  ): number {
    const indA = sortedArray.indexOf(a);
    const indB = sortedArray.indexOf(b);
    if (indA === -1 && indB === -1) return 0;
    if (indA === -1) return 1;
    if (indB === -1) return -1;
    return indA - indB;
  }

  public static camelCaseToKebabCase(camelCase: string): string {
    const parts: Array<string> = [];
    let currentPart: string = '';

    for (const letter of camelCase.split('')) {
      if (this.isUpperCaseLetter(letter)) {
        if (currentPart.length) {
          parts.push(currentPart);
        }

        currentPart = letter;
      } else {
        currentPart += letter;
      }
    }

    if (currentPart.length) {
      parts.push(currentPart);
    }

    return parts.map((p) => p.toLocaleLowerCase()).join('-');
  }

  public static isUpperCaseLetter(letter: string): boolean {
    if (letter.length === 0 || letter.length > 1) {
      throw new Error(
        `parameter "${letter}" is not a letter because it has a length other than 1`
      );
    }

    return this.upperCaseLetters.includes(letter);
  }

  private static modifyFirstChar(
    str: string,
    callback: (firstChar: string) => string
  ): string {
    const firstChar = str[0];

    if (firstChar === undefined) {
      return str;
    }

    return `${callback(firstChar)}${str.substring(1)}`;
  }

  /**
   * @param value
   * @param fractionDigits - if this isn't specified, it will be determined by the value
   */
  private static fixedNumber(value: number, fractionDigits?: number): string {
    if (fractionDigits != null) {
      return value.toFixed(fractionDigits);
    }

    return String(value);
  }
}

export type PriceToStringOptions = {
  withSign?: boolean;
  /**
   * only works in conjuction with `withSign`
   * see NumberWithSignOptions.spaceBetweenSign
   */
  spaceBetweenSign?: boolean;
};

export type NumberWithSignOptions = {
  fractionDigits?: number;

  /**
   * Add a space between the sign and the number. Useful to make it look like a formula
   */
  spaceBetweenSign?: boolean;
};
