/* global L */
import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import {
  Map as LeafletMap,
  Control,
  LatLng,
  TileLayerOptions,
  Marker,
  LeafletEventHandlerFn,
  Handler,
  LatLngExpression,
  LayersControlEvent
} from 'leaflet';

import { GeoJson } from 'common/EndpointTypes/GeoDataEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { BaseMapMarkerColor } from 'common/Types/BaseMapMarker';
import { BaseTileMapLayer } from 'common/Enums/BaseMapLayer';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { GeoDataCacheService } from '../../services/GeoDataCacheService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { Utils } from '../../classes/Utils/Utils';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { createLocationTracker } from './LeafletLocationTracker';
import { BaseTileLayer } from './BaseTileLayer';
import { MapLayerManager, VectorTileLayerInfo } from './MapLayerManager';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

/**
 * @event map-initialized - if you call getMapInstance before this event it will only return null
 * @event map-remove - if you added additional controls to the map, you need to remove them in this event (else the controls onRemove doesn't get called)
 */
@autoinject()
export class BasemapMap {
  @bindable public disableZoom = false;
  @bindable public disableDrag = false;
  @bindable public disableLocationTracker = false;

  @bindable public disablePositionSaving = false;

  private static STORAGE_NAME = 'BasemapMap';

  private tileLayerToInfoMap: Map<BaseTileLayer, BaseTileLayerInfo> = new Map();

  private map: LeafletMap | null = null;
  private mapElement: HTMLElement | null = null;
  private domElement: HTMLElement;

  private mapLayerManager: MapLayerManager | null = null;

  private markerRefs: Array<MapMarker> = [];

  /**
   * an array of all additional controls added to the map (leaflet doesn't track this by itself)
   */
  private controls: Array<{ remove: () => void }> = [];

  private layerControl: Control.Layers | null = null;

  private baseTileLayersWithControls = {
    [BaseTileMapLayer.BASEMAP_NORMAL]: this.createTileLayerInfo(
      BaseTileMapLayer.BASEMAP_NORMAL,
      'enums.baseTileMapLayer.' + BaseTileMapLayer.BASEMAP_NORMAL,
      'https://{s}.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png',
      {
        subdomains: ['maps', 'maps1', 'maps2', 'maps3', 'maps4'],
        attribution:
          '&copy; Datenquelle: <a href="http://basemap.at">basemap.at</a>'
      },
      {
        attributionExport: 'www.basemap.at'
      }
    ),
    [BaseTileMapLayer.BASEMAP_GRAY]: this.createTileLayerInfo(
      BaseTileMapLayer.BASEMAP_GRAY,
      'enums.baseTileMapLayer.' + BaseTileMapLayer.BASEMAP_GRAY,
      'https://{s}.wien.gv.at/basemap/bmapgrau/normal/google3857/{z}/{y}/{x}.png',
      {
        subdomains: ['maps', 'maps1', 'maps2', 'maps3', 'maps4'],
        attribution:
          '&copy; Datenquelle: <a href="http://basemap.at">basemap.at</a>'
      },
      {
        attributionExport: 'www.basemap.at'
      }
    ),
    [BaseTileMapLayer.BASEMAP_ORTHO]: this.createTileLayerInfo(
      BaseTileMapLayer.BASEMAP_ORTHO,
      'enums.baseTileMapLayer.' + BaseTileMapLayer.BASEMAP_ORTHO,
      'https://{s}.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg',
      {
        subdomains: ['maps', 'maps1', 'maps2', 'maps3', 'maps4'],
        attribution:
          '&copy; Datenquelle: <a href="http://basemap.at">basemap.at</a>'
      },
      {
        attributionExport: 'www.basemap.at'
      }
    )
  };

  private baseTileLayersWhichAreAlwaysEnabled = [
    this.createTileLayerInfo(
      'osm',
      '',
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors'
      },
      {
        attributionExport: 'www.openstreetmap.org'
      }
    )
  ];

  private tileLayerOverlays = [
    this.createTileLayerInfo(
      'basemapOverlay',
      'map.basemapMap.basemapOverlay',
      'https://{s}.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png',
      {
        subdomains: ['maps', 'maps1', 'maps2', 'maps3', 'maps4'],
        attribution:
          '&copy; Datenquelle: <a href="http://basemap.at">basemap.at</a>'
      },
      {
        attributionExport: 'www.basemap.at'
      }
    )
  ];

  private userInteractionHandlerNames = [
    'boxZoom',
    'doubleClickZoom',
    'dragging',
    'keyboard',
    'scrollWheelZoom',
    'touchZoom'
  ];

  private doNotCenterMapOnNextLatLngChange = false;
  private doNotCenterMapOnNextZoomChange = false;

  private defaultLatLng = L.latLng(47.5, 14.15); // approx centered austria
  private defaultZoom = 7;

  private subscriptionManager: SubscriptionManager;

  private saveLatLngZoomRateLimited = Utils.rateLimitFunction(
    this.saveLatLngZoom.bind(this),
    500
  );

  private initializedEventTimeout: number | null = null;

  @observable private latLng: LatLng | null;

  @observable private zoom: number | null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    element: Element,
    private readonly geoDataCacheService: GeoDataCacheService,
    private readonly i18n: I18N,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.domElement = element as HTMLElement;

    this.subscriptionManager = subscriptionManagerService.create();

    this.latLng = null;
    this.zoom = null;
  }

  protected async attached(): Promise<void> {
    const latLngZoom = await this.loadLatLngZoom();
    this.latLng = latLngZoom.latLng;
    this.zoom = latLngZoom.zoom;

    await this.createMap();
  }

  protected detached(): void {
    this.removeMap();
    this.subscriptionManager.disposeSubscriptions();
  }

  public addAdditionalVectorLayer(data: GeoJson): void {
    if (!this.map) {
      throw new Error('map is not initialized yet');
    }

    this.map.createPane('top');
    const vectorTileStyling = {
      sliced: {
        weight: 1,
        color: '#FFFF00',
        opacity: 1
      }
    };

    L.vectorGrid
      .slicer(data, {
        interactive: false,
        maxZoom: 18,
        minZoom: 16,
        pane: 'top',
        vectorTileLayerStyles: vectorTileStyling
      })
      .addTo(this.map);
  }

  public setMarkers(markers: Array<MapMarker>): void {
    if (!this.map) {
      throw new Error('map is not initialized yet');
    }

    for (const oldMarker of this.markerRefs) {
      oldMarker.remove();
    }
    this.markerRefs = [];
    const markerGroup = [];
    if (markers.length > 0) {
      for (const marker of markers) {
        marker.appendToMap(this.map);
        this.markerRefs.push(marker);
        markerGroup.push(marker.getLeafletMarker());
      }
      const group = L.featureGroup(markerGroup);
      this.map.fitBounds(group.getBounds());
    }
  }

  /**
   * returns null if them map isn't initialized yet
   */
  public getMapInstance(): LeafletMap | null {
    return this.map;
  }

  /**
   * call this everytime if the height of the element changed after the element was attached
   */
  public resize(): void {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  /**
   * only call this function after the map has been initialized
   */
  public disableUserInteraction(): void {
    if (!this.map) {
      throw new Error('map is not initialized yet');
    }

    for (const handlerName of this.userInteractionHandlerNames) {
      const handler: Handler = (this.map as any)[handlerName];
      handler.disable();
    }
  }

  /**
   * only call this function after the map has been initialized
   */
  public enableUserInteraction(): void {
    this.userInteractionHandlerNames.forEach((handlerName) => {
      try {
        const handler: Handler = (this.map as any)[handlerName];
        handler.enable();
      } catch (e) {
        console.error(e, handlerName);
      }
    });
  }

  public getActiveTileLayerInfos(): Array<BaseTileLayerInfo> {
    const infos: Array<BaseTileLayerInfo> = [];

    this.map?.eachLayer((layer) => {
      if (layer instanceof BaseTileLayer) {
        const info = this.tileLayerToInfoMap.get(layer);
        if (info) infos.push(info);
      }
    });

    return infos;
  }

  public getActiveVectorTileLayerInfos(): Array<VectorTileLayerInfo> {
    assertNotNullOrUndefined(
      this.mapLayerManager,
      'cannot get active vector tile layer infos without map layer manager'
    );
    return this.mapLayerManager.getActiveVectorTileLayerInfos();
  }

  public setCenter(center: LatLngExpression): void {
    this.centerMapToLatLng(center, this.zoom || this.defaultZoom);
  }

  public setZoom(zoom: number): void {
    this.centerMapToLatLng(this.latLng || this.defaultLatLng, zoom);
  }

  protected latLngChanged(): void {
    this.saveLatLngZoomRateLimited();

    if (!this.doNotCenterMapOnNextLatLngChange) {
      this.centerMapToLatLng(
        this.latLng || this.defaultLatLng,
        this.zoom || this.defaultZoom
      );
    }
    this.doNotCenterMapOnNextLatLngChange = false;
  }

  protected zoomChanged(): void {
    this.saveLatLngZoomRateLimited();

    if (!this.doNotCenterMapOnNextZoomChange) {
      this.centerMapToLatLng(
        this.latLng || this.defaultLatLng,
        this.zoom || this.defaultZoom
      );
    }
    this.doNotCenterMapOnNextZoomChange = false;
  }

  private saveLatLngZoom(): void {
    if (this.disablePositionSaving) return;

    const latLngZoom = {
      latLng: this.latLng,
      zoom: this.zoom
    };
    void DataStorageHelper.setItem(
      `${BasemapMap.STORAGE_NAME}::LatLngZoom`,
      latLngZoom
    );
  }

  private saveBaseTileLayerName(layerName: string): void {
    void DataStorageHelper.setItem(
      `${BasemapMap.STORAGE_NAME}::BaseTileLayerName`,
      layerName
    );
  }

  private saveTileLayerNames(names: Array<string>): void {
    void DataStorageHelper.setItem(
      `${BasemapMap.STORAGE_NAME}::TileLayerNames`,
      names
    );
  }

  private async loadLatLngZoom(): Promise<{
    latLng: LatLng | null;
    zoom: number | null;
  }> {
    const latLngZoom = await DataStorageHelper.getItem(
      `${BasemapMap.STORAGE_NAME}::LatLngZoom`
    );
    const latLng = (latLngZoom && latLngZoom.latLng) || null;

    return {
      latLng:
        (latLng &&
          latLng.lat &&
          latLng.lng &&
          L.latLng(latLng.lat, latLng.lng)) ||
        null,
      zoom: (latLngZoom && latLngZoom.zoom) || null
    };
  }

  private async loadBaseTileLayerName(): Promise<BaseTileMapLayer | undefined> {
    return await DataStorageHelper.getItem(
      `${BasemapMap.STORAGE_NAME}::BaseTileLayerName`
    );
  }

  private async loadTileLayerNames(): Promise<Array<string>> {
    return (
      (await DataStorageHelper.getItem(
        `${BasemapMap.STORAGE_NAME}::TileLayerNames`
      )) ?? []
    );
  }

  private async createMap(): Promise<void> {
    if (!this.mapElement) {
      throw new Error('_mapElement is not bound');
    }

    this.initializedEventTimeout && clearTimeout(this.initializedEventTimeout);

    const baseTileLayerName = await this.loadBaseTileLayerName();

    const tileLayerNameFromUserCompanySetting =
      this.activeUserCompanySettingService.getSettingProperty(
        'general.standardBaseTileMapLayer'
      ) as BaseTileMapLayer | null;

    const defaultLayer = baseTileLayerName
      ? this.baseTileLayersWithControls[baseTileLayerName].layer
      : this.baseTileLayersWithControls[
          tileLayerNameFromUserCompanySetting ?? BaseTileMapLayer.BASEMAP_NORMAL
        ].layer;

    const layers = [defaultLayer];

    const tileLayerNames = await this.loadTileLayerNames();
    tileLayerNames.forEach((layerName) => {
      const layer = this.tileLayerOverlays.find(
        (l) => l.layer.getName() === layerName
      );
      if (!layer) return;

      layers.push(layer.layer);
    });

    const mapSettings = {
      layers,
      zoomControl: !this.disableZoom
    };

    this.baseTileLayersWhichAreAlwaysEnabled.forEach((layerInfo) => {
      layers.unshift(layerInfo.layer);
    });

    const latLng = this.latLng || this.defaultLatLng;
    const zoom = this.zoom || this.defaultZoom;

    this.map = new L.Map(this.mapElement, mapSettings).setView(latLng, zoom);
    this.layerControl = L.control.layers();

    this.addControlsToMap();

    this.mapLayerManager = new MapLayerManager(
      this.entityManager,
      this.subscriptionManagerService,
      this.geoDataCacheService,
      this.map
    );
    this.subscriptionManager.addDisposable(
      await this.mapLayerManager.init(this.layerControl)
    );

    this.map.on('moveend', this.handleMapMoved.bind(this));
    this.map.on('zoomend', this.handleMapZoomed.bind(this));

    this.map.on('baselayerchange', (event: LayersControlEvent) => {
      if (event.layer instanceof BaseTileLayer) {
        this.saveBaseTileLayerName(event.layer.getName());
      }
    });

    this.map.on('overlayadd', this.handleTileLayersChanged.bind(this));
    this.map.on('overlayremove', this.handleTileLayersChanged.bind(this));

    if (this.disableZoom) {
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.scrollWheelZoom.disable();
      this.map.boxZoom.disable();
      this.map.keyboard.disable();
    }

    if (this.disableDrag) {
      this.map.dragging.disable();
    }

    this.initializedEventTimeout = window.setTimeout(() => {
      if (!this.domElement) {
        return;
      }

      DomEventHelper.fireEvent(this.domElement, {
        name: 'map-initialized',
        detail: null
      });
    }, 100); // extra wait time since leaflet doesn't initialize synchronously
  }

  private handleTileLayersChanged(): void {
    if (!this.map) return;

    const mapLayerNames: Array<string> = [];
    this.map.eachLayer((layer) => {
      if (
        layer instanceof BaseTileLayer &&
        this.tileLayerOverlays.find(
          (l) => l.layer.getName() === layer.getName()
        )
      ) {
        mapLayerNames.push(layer.getName());
      }
    });

    this.saveTileLayerNames(mapLayerNames);
  }

  private addControlsToMap(): void {
    if (!this.map) {
      throw new Error('map is not initialized yet');
    }

    const layerControl = this.layerControl;
    assertNotNullOrUndefined(
      layerControl,
      'cannot add controls without layer control'
    );

    Object.values(this.baseTileLayersWithControls).forEach((layerInfo) => {
      const layerName = this.i18n.tr(layerInfo.labelTk);
      layerControl.addBaseLayer(layerInfo.layer, layerName);
    });

    this.tileLayerOverlays.forEach((layerInfo) => {
      const layerName = this.i18n.tr(layerInfo.labelTk);
      layerControl.addOverlay(layerInfo.layer, layerName);
    });

    layerControl.addTo(this.map);
    this.controls.push(layerControl);

    if (!this.disableLocationTracker) {
      this.controls.push(
        createLocationTracker({
          position: 'bottomright',
          subscriptionManagerService: this.subscriptionManagerService
        }).addTo(this.map)
      );
    }
  }

  private removeMap(): void {
    if (this.map) {
      this.initializedEventTimeout &&
        clearTimeout(this.initializedEventTimeout);

      this.controls.forEach((c) => c.remove());
      this.controls = [];

      if (this.domElement) {
        DomEventHelper.fireEvent(this.domElement, {
          name: 'map-remove',
          detail: null
        });
      }

      try {
        this.map.remove();
      } catch (e) {
        console.log('Remove error');
      }
      this.map = null;
    }
  }

  private createTileLayerInfo(
    name: string,
    labelTk: string,
    url: string,
    layerOptions: TileLayerOptions,
    infoOptions: TileLayerInfoOptions
  ): BaseTileLayerInfo {
    const layer = new BaseTileLayer(name, url, layerOptions);

    const info = new BaseTileLayerInfo(layer, labelTk, infoOptions);

    this.tileLayerToInfoMap.set(info.layer, info);

    return info;
  }

  private centerMapToLatLng(latLng: LatLngExpression, zoom: number): void {
    if (!this.map) return;
    this.map.setView(latLng, zoom);
  }

  private handleMapMoved(): void {
    if (!this.map) return;
    this.doNotCenterMapOnNextLatLngChange = true;
    this.latLng = this.map.getCenter();
  }

  private handleMapZoomed(): void {
    if (!this.map) return;
    this.doNotCenterMapOnNextZoomChange = true;
    this.zoom = this.map.getZoom();
  }
}

export class MapMarker {
  private marker: Marker;
  private opacity = 1;
  private colorSuffix: BaseMapMarkerColor;

  constructor(
    latitude: number,
    longitude: number,
    colorSuffix = BaseMapMarkerColor.RED
  ) {
    this.marker = new L.Marker([latitude, longitude]);
    this.colorSuffix = colorSuffix;
    this.updateMarkerIcon();
  }

  public appendToMap(map: LeafletMap): void {
    this.marker.addTo(map);
  }

  public remove(): void {
    this.marker.remove();
  }

  /**
   * @param opacity - 0-1
   */
  public setOpacity(opacity: number): void {
    this.opacity = opacity;
    this.updateMarkerIcon();
  }

  public setColorSuffix(colorSuffix: BaseMapMarkerColor): void {
    this.colorSuffix = colorSuffix;
    this.updateMarkerIcon();
  }

  public onClick(callback: LeafletEventHandlerFn, context: any): void {
    this.marker.on('click', callback, context);
  }

  public offClick(callback: LeafletEventHandlerFn, context: any): void {
    this.marker.off('click', callback, context);
  }

  public getLeafletMarker(): Marker {
    return this.marker;
  }

  private updateMarkerIcon(): void {
    // drop shadow for dark backgrounds
    const icon = new L.DivIcon({
      html:
        '' +
        '<div style="position: relative; text-align: center;">' +
        `<img src="./img/map-marker-${this.colorSuffix}.svg" style="opacity: ${this.opacity}; height: 35px;"/>` +
        '</div>',
      iconSize: [35, 35],
      iconAnchor: [17.5, 35],
      popupAnchor: [0, -27],
      tooltipAnchor: [0, -27],
      className: ''
    });

    this.marker.setIcon(icon);
  }
}

export class BaseTileLayerInfo {
  public layer: BaseTileLayer;

  public labelTk: string;

  public attributionExport: string | undefined;
  public excludeFromExport: boolean | undefined;

  constructor(
    layer: BaseTileLayer,
    labelTk: string,
    opts?: TileLayerInfoOptions
  ) {
    this.layer = layer;

    this.labelTk = labelTk;

    if (opts) {
      this.attributionExport = opts.attributionExport;
      this.excludeFromExport = opts.excludeFromExport;
    }
  }
}

type TileLayerInfoOptions = {
  attributionExport: string;
  excludeFromExport?: boolean;
};
