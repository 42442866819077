import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class SignatureValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    canSignTheAppointment,
    configurationStep,
    appointment
  }: FinishAppointmentOptionsWithConfigurationStep): boolean {
    const needsSignature =
      (configurationStep?.signatureRequired ?? true) && canSignTheAppointment;

    if (!needsSignature) {
      return true;
    }

    return this.hasSignature({ appointment });
  }

  private hasSignature({
    appointment
  }: {
    appointment: ProcessTaskAppointment;
  }): boolean {
    return (
      this.entityManager.generalFileRepository.getSignatureFilesByProcessTaskAppointmentId(
        appointment.id
      ).length > 0
    );
  }
}
