import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { OperationsFieldConfigs } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsFieldConfigs';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationProcessTaskGroupRelationsWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  protected readonly processTaskGroupFieldConfigs =
    OperationsFieldConfigs.processTaskGroupRequestFieldConfigs;

  @subscribableLifecycle()
  protected permissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager
  ) {
    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  protected handleProcessConfigurationChanged(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'cannot use ProcessConfigurationFinishAppointmentConfigWidget without processConfiguration'
    );
    this.entityManager.processConfigurationRepository.update(
      this.processConfiguration
    );
  }
}
