import { assertNotNullOrUndefined, assertType } from 'common/Asserts';

export class SvgLoader {
  private xhr: XMLHttpRequest | null = null;

  public load(url: string): Promise<SVGSVGElement> {
    return new Promise((resolve, reject) => {
      if (this.xhr) {
        this.xhr.onerror = null;
        this.xhr.onload = null;
        this.xhr.abort();
      }

      const xhr = new XMLHttpRequest();

      xhr.open('GET', url, true);
      this.xhr = xhr;

      xhr.onload = () => {
        try {
          resolve(this.generateSvgElementFromXhr(xhr));
        } catch (e) {
          reject(e);
        }
      };

      xhr.onerror = (event) => {
        this.xhr = null;
        reject(new Error(event.type));
      };

      xhr.send();
    });
  }

  private generateSvgElementFromXhr(xhr: XMLHttpRequest): SVGSVGElement {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = xhr.response;

    assertNotNullOrUndefined(wrapper.firstElementChild, 'empty response');
    assertType(
      wrapper.firstElementChild,
      SVGSVGElement,
      'wrong response, firstElementChild is not an svg'
    );

    return wrapper.firstElementChild as SVGSVGElement;
  }
}
