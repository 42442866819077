/* TODO: typings/cleanup/..., a lot of the typings have to be created manually, since leaflet doesnt't expose it */

import * as L from 'leaflet';
import { GeoDataCacheService } from '../../services/GeoDataCacheService';
import { AnnotatedSvgTileRenderer } from './AnnotatedSvgTileRenderer';

export class SocketTileVectorGrid extends L.VectorGrid {
  private geoDataCacheService: GeoDataCacheService;

  private layerId = '';
  private layerStyle: LayerStyle = {
    color: '#FF0000',
    opacity: 1,
    weight: 1
  };

  private annotationPropertyName?: string | null;
  private minZoomAnnotation?: number;

  constructor(geoDataCacheService: GeoDataCacheService, options: Options) {
    super(
      Object.assign({}, options, { vectorTileLayerStyles: { geoJsonTile: {} } })
    );

    this.geoDataCacheService = geoDataCacheService;
    this.layerId = options.layerId;

    this.annotationPropertyName = options.annotationPropertyName;
    this.minZoomAnnotation = options.minZoomAnnotation;

    (this.options as any).rendererFactory = this.rendererFactory;
  }

  public getId(): string {
    return this.layerId;
  }

  public setMinZoom(minZoom: number): void {
    this.options.minZoom = minZoom;
  }

  public setStyle(layerStyle: LayerStyle): void {
    const updatedLayerStyle = Object.assign({}, this.layerStyle, layerStyle);

    this.layerStyle = updatedLayerStyle;
    this.applyLayerStyle();
  }

  public async createSvgTile(coords: Coordinates): Promise<SVGSVGElement> {
    return new Promise((res) => {
      const svg = super.createTile(coords, () => res(svg as SVGSVGElement));
    });
  }

  private applyLayerStyle(): void {
    this.options.vectorTileLayerStyles.geoJsonTile = this.layerStyle;
  }

  protected async _getVectorTilePromise(
    coords: Coordinates
  ): Promise<Response> {
    const response: Response = {
      layers: {},
      coords: coords
    };

    let data;
    try {
      data = await this.geoDataCacheService.requestGeoJsonTile(
        this.layerId,
        coords.x,
        coords.y,
        coords.z
      );
    } catch (error) {
      console.error(error);
      return response;
    }

    const vectorTileLayer: VectorTileLayer = {
      features: [],
      extent: 4096,
      name: 'geoJsonTile',
      length: data.length
    };

    for (const feature in data) {
      vectorTileLayer.features.push({
        geometry: data[feature].geometry,
        properties: data[feature].tags,
        type: data[feature].type
      });
    }

    response.layers = {
      geoJsonTile: vectorTileLayer
    };

    return response;
  }

  protected _createLayer(
    feature: any,
    pxPerExtent: number,
    layerStyle: any
  ): FeatureLayer {
    return super._createLayer(feature, pxPerExtent, layerStyle);
  }

  private rendererFactory(
    tileCoord: any,
    tileSize: any,
    opts: Options
  ): Renderer {
    return new AnnotatedSvgTileRenderer(tileCoord, tileSize, opts);
  }
}

type FeatureLayer = any;
type Renderer = any;

type Options = L.GridLayerOptions & {
  layerId: string;
  annotationPropertyName?: string | null;
  minZoomAnnotation?: number;

  interactive: boolean;
  pane: string;
  vectorTileLayerStyles?: any;
};

type Coordinates = {
  x: number;
  y: number;
  z: number;
};

type Response = {
  layers: Layers;
  coords: Coordinates;
};

type Layers = {
  [key: string]: VectorTileLayer;
};

type VectorTileLayer = {
  features: Array<Feature>;
  extent: number;
  name: string;
  length: number;
};

type Feature = {
  geometry: any;
  properties: Array<any>;
  type: number;
};

export type LayerStyle = {
  stroke?: boolean;
  color?: string | null;
  weight?: number;
  opacity?: number;
  dashArray?: string;

  fill?: boolean;
  fillColor?: string | null;
  fillOpacity?: number;

  radius?: number;
};
