import { DataHandler, DecodeOptions, EncodeOptions } from './DataHandler';
import { Decoder } from './Decoder';
import { EncodedData } from './EncodedData';
import { Encoder } from './Encoder';

export class AlwaysEncodeDataHandler implements DataHandler {
  private readonly encoder = new Encoder();
  private readonly decoder = new Decoder();

  public async encode({ data }: EncodeOptions): Promise<any> {
    if (data === undefined) {
      return data;
    }

    if (data instanceof Object) {
      return this.encoder.encodeIfBigEnough(data);
    }

    return data;
  }

  public async decode({ data }: DecodeOptions): Promise<any> {
    if (data instanceof Object && (data as EncodedData).$$isEncodedData$$) {
      return this.decoder.decode(data as EncodedData);
    }

    return data;
  }
}
