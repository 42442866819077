import { Disposable } from 'aurelia-binding';
import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { ArrayMapCache } from '../../classes/ArrayMapCache/ArrayMapCache';
import { DefectEntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../services/SocketService';
import { EntityWidgetHandleUtils } from '../EntityWidgetHandle/EntityWidgetHandleUtils';
import {
  EntityWidgetAdapter,
  EntityWidgetAdapterOptions,
  EntityWidgetAdapterSubscribeOptions
} from './EntityWidgetAdapter';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DefectActionService } from '../../classes/EntityManager/entities/Defect/DefectActionService';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { DefectWidgetHandle } from '../EntityWidgetHandle/DefectWidgetHandle';
import { Router } from 'aurelia-router';

export class CatastropheDefectWidgetAdapter implements EntityWidgetAdapter {
  private handleCache: ArrayMapCache<Defect, DefectWidgetHandle>;

  constructor(private readonly options: CatastropheDefectWidgetAdapterOptions) {
    this.handleCache = new ArrayMapCache({
      createMappedItem: ({ item }) =>
        new DefectWidgetHandle(item, {
          entityManager: this.options.entityManager,
          defectActionService: this.options.defectActionService,
          subscriptionManagerService: this.options.subscriptionManagerService,
          permissionsService: options.permissionsService,
          router: options.router
        })
    });
  }

  public subscribe(options: EntityWidgetAdapterSubscribeOptions): Disposable {
    const subscriptionManager =
      this.options.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.options.activeUserCompanySettingService.bindSettingProperty(
        'kuk.initialNumberOfDefectsShown',
        (initialNumberOfDefectsShown: number | null) => {
          options.setEntityMinNumberToShow(initialNumberOfDefectsShown);
        }
      )
    );

    subscriptionManager.addDisposable(
      this.options.activeUserCompanySettingService.bindSettingProperty(
        'kuk.totalNumberOfDefectsShown',
        (totalNumberOfDefectsShown: number | null) => {
          options.setEntityMaxNumberToShow(totalNumberOfDefectsShown);
        }
      )
    );

    const updateDefects = (): void => {
      const handles = this.handleCache.mapItems({ items: this.getDefects() });
      const sortedHandles = this.applyDashboardInfoToHandles(handles);
      options.setEntityHandles(sortedHandles);
    };
    subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      updateDefects
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.EntityDashboardInfo,
      updateDefects
    );
    this.options.createSubscriptionsForThingId(
      subscriptionManager,
      updateDefects.bind(this)
    );
    updateDefects();

    return subscriptionManager.toDisposable();
  }

  private applyDashboardInfoToHandles(
    handles: Array<DefectWidgetHandle>
  ): Array<DefectWidgetHandle> {
    const defectDashboardInfo = BaseEntityUtils.sortByUpdatedAt(
      this.options.entityManager.entityDashboardInfoRepository
        .getAll()
        .filter((x): x is DefectEntityDashboardInfo => !!x.defectId)
    );

    const dashboardInfoMap = new Map(
      defectDashboardInfo.map((x) => [x.defectId, x])
    );
    const handlesWithoutHidden = EntityWidgetHandleUtils.removeHiddenHandles(
      handles,
      dashboardInfoMap
    );
    return handlesWithoutHidden.sort((a, b) =>
      EntityWidgetHandleUtils.compareHandles(a, b, dashboardInfoMap)
    );
  }

  private getDefects(): Array<Defect> {
    const thingId = this.options.getThingId();
    if (!thingId) return [];

    return BaseEntityUtils.sortByUpdatedAt(
      this.options.entityManager.defectRepository
        .getByOwnerThingId(thingId)
        .filter((d) => !d.deleted)
    );
  }
}

export type CatastropheDefectWidgetAdapterOptions =
  EntityWidgetAdapterOptions & {
    defectActionService: DefectActionService;
    socketService: SocketService;
    permissionsService: PermissionsService;
    activeUserCompanySettingService: ActiveUserCompanySettingService;
    router: Router;
    /**
     * The id of the thing to retrieve all defects for.
     */
    getThingId(): string | null;
    /**
     * Create additional subscriptions here which should trigger a call to `getThingId`.
     */
    createSubscriptionsForThingId(
      subscriptionManager: SubscriptionManager,
      updateItems: () => void
    ): void;
  };
