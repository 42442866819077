export class ProcessConfigurationCategoryHelper {
  private constructor() {}

  public static getAbbreviationWithFallback(
    abbreviation: string | null,
    name: string | null
  ): string | null {
    if (abbreviation) {
      return abbreviation;
    }

    const firstNameLetter = name?.[0];
    if (firstNameLetter) {
      return firstNameLetter;
    }

    return null;
  }
}
