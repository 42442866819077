import { Router } from 'aurelia-router';
import { autoinject, computedFrom, observable } from 'aurelia-framework';

import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SocketService } from '../../services/SocketService';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import { SetProcessTaskCurrentProcessConfigurationStepIdService } from '../../services/SetProcessTaskCurrentProcessConfigurationStepIdService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskUtils } from '../../classes/EntityManager/entities/ProcessTask/ProcessTaskUtils';
import { PersonContactLink } from '../../personComponents/person-contact-link/person-contact-link';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { ProcessTaskAppointmentsWidget } from '../../operationsComponents/process-task-appointments-widget/process-task-appointments-widget';
import { ProcessTaskOffersWidget } from '../../operationsComponents/process-task-offers-widget/process-task-offers-widget';
import { ProcessTaskInvoicesWidget } from '../../operationsComponents/process-task-invoices-widget/process-task-invoices-widget';
import { ProcessTaskGeneralWidget } from '../../operationsComponents/process-task-general-widget/process-task-general-widget';
import { ProcessConfigurationStepClickedEvent } from '../../operationsComponents/process-configuration-step-bar/process-configuration-step-bar';
import { TEditPropertyClickedEvent } from '../../operationsComponents/process-task-info-overview/process-task-info-overview-properties';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { LoadProcessTaskGroupWithEntitiesService } from '../../services/LoadProcessTaskGroupWithEntitiesService';
import { LastOpenedProcessTaskGroupsService } from '../../services/LastOpenedProcessTaskGroupsService/LastOpenedProcessTaskGroupsService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

const tabParamDecorator = observable({ changeHandler: 'updateTabs' });

@autoinject()
export class edit_process_task {
  private static TEMPORARY_GROUP_NAME = 'edit_process_task';

  private readonly subscriptionsManager: SubscriptionManager;
  private readonly permissionBindingHandle: PermissionBindingHandle;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private activatedProcessTaskId: string | null = null;

  private processTask: ProcessTask | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private availableProcessTasks: Array<ProcessTask> = [];
  private appointmentIdToNavigateTo: string | null = null;
  private invoiceIdToNavigateTo: string | null = null;
  private offerIdToNavigateTo: string | null = null;
  private isActivated: boolean = false;
  protected thingGroup: ThingGroup | null = null;
  protected processTaskGroupIsEditable: boolean = false;
  protected isConnected: boolean = false;

  @tabParamDecorator private processTaskPositionCount: number | null = null;
  @tabParamDecorator private processTaskAppointmentCount: number | null = null;
  @tabParamDecorator private processTaskDeviceCount: number | null = null;
  @tabParamDecorator private openChecklistEntryCount: number | null = null;
  @tabParamDecorator private processTaskCommentCount: number | null = null;
  @tabParamDecorator private processTaskOfferCount: number | null = null;
  @tabParamDecorator private processTaskInvoiceCount: number | null = null;
  @tabParamDecorator private formCount: number | null = null;
  @tabParamDecorator private processTaskMeasurePointCount: number | null = null;
  @tabParamDecorator private processTaskPictureCount: number | null = null;
  @tabParamDecorator private projectCount: number | null = null;

  private tabs: Array<TTab> = [];
  private selectedTab: TTab | null = null;
  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'delete',
      iconClass: 'fal fa-trash-alt',
      labelTk: 'generalPages.editProcessTask.deleteProcessTaskChoiceLabel',
      disabledContext: this,
      disabledPropertyName: 'deleteProcessTaskChoiceIsDisabled'
    }
  ];

  private processTaskAppointmentsWidget: ProcessTaskAppointmentsWidget | null =
    null;

  private processTaskOffersWidget: ProcessTaskOffersWidget | null = null;
  private processTaskInvoicesWidget: ProcessTaskInvoicesWidget | null = null;
  private processTaskGeneralWidget: ProcessTaskGeneralWidget | null = null;
  private processConfiguration: ProcessConfiguration | null = null;

  protected readonly ThingGroupHelper = ThingGroupHelper;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly activeEntitiesService: ActiveEntitiesService,
    private readonly socketService: SocketService,
    private readonly setProcessTaskCurrentProcessConfigurationStepIdService: SetProcessTaskCurrentProcessConfigurationStepIdService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    private readonly loadProcessTaskGroupWithEntitiesService: LoadProcessTaskGroupWithEntitiesService,
    private readonly lastOpenedProcessTaskGroupsService: LastOpenedProcessTaskGroupsService,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      entity: {
        property: 'processTaskGroup',
        userGroupPropertyOfEntity: 'ownerUserGroupId',
        editableProperty: 'processTaskGroupIsEditable'
      }
    });
    this.subscriptionsManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTask,
        context: this,
        expression: 'processTask'
      });
  }

  public activate(params: {
    process_task_id: string;
    process_task_appointment_id?: string;
    process_task_offer_id?: string;
    process_task_invoice_id?: string;
  }): void {
    this.isActivated = true;
    this.activatedProcessTaskId = params.process_task_id;

    // no await here on purpose, else the activation lifecycle will get blocked until the entities are loaded, which isn't necessary
    void this.loadEntities().then(() => {
      void this.navigateToAppointmentIfPossible(
        params.process_task_appointment_id
      );
      void this.navigateToInvoiceIfPossible(params.process_task_invoice_id);
      void this.navigateToOfferIfPossible(params.process_task_offer_id);
    });
  }

  protected attached(): void {
    this.permissionBindingHandle.subscribe();
    void this.navigateToAppointmentIfPossible();
    void this.navigateToInvoiceIfPossible();
    void this.navigateToOfferIfPossible();

    this.subscriptionsManager.subscribeToEvent('socket:authenticated', () => {
      void this.loadEntities();
    });
    this.subscriptionsManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateAvailableProcessTasks.bind(this)
    );
    this.updateAvailableProcessTasks();

    this.subscriptionsManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.subscriptionsManager.addDisposable(
      PersonContactLink.addEventListener('phoneContactClicked', (payload) => {
        if (this.processTask) {
          void this.processTaskLoggingService.logPhoneLinkClick(
            this.processTask,
            payload.person,
            payload.personContact
          );
        }
      })
    );

    this.subscriptionsManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskGroup
            ? { processTaskGroupId: this.processTaskGroup.id }
            : null,
        createUpdaters: (update) => {
          this.subscriptionsManager.subscribeToExpression(
            this,
            'processTaskGroup.id',
            update
          );
        },
        callback: (configuration) => {
          this.processConfiguration = configuration;
          this.updateTabs();
        }
      })
    );
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    GlobalLoadingOverlay.setLoadingState(this, false);
    this.subscriptionsManager.disposeSubscriptions();
  }

  protected deactivate(): void {
    this.isActivated = false;

    this.activeEntitiesService.setActiveProcessTask(null);
  }

  protected determineActivationStrategy(): string {
    return 'invoke-lifecycle';
  }

  /**
   * if you call this without the appointmentId, it will try to navigate to the appointmentIdToNavigateTo
   * if it's not possible to navigate to the appointmentId yet, it will be stored in appointmentIdToNavigateTo
   */
  private async navigateToAppointmentIfPossible(
    appointmentId?: string | null
  ): Promise<void> {
    if (!appointmentId) {
      appointmentId = this.appointmentIdToNavigateTo;
    }

    if (this.processTaskAppointmentsWidget && appointmentId) {
      await this.navigateToTabByPageName('appointments');
      this.processTaskAppointmentsWidget.navigateToAppointment(appointmentId);
      this.appointmentIdToNavigateTo = null;
    } else {
      this.appointmentIdToNavigateTo = appointmentId;
    }
  }

  /**
   * if you call this without the invoiceId, it will try to navigate to the invoiceIdToNavigateTo
   * if it's not possible to navigate to the invoiceId yet, it will be stored in invoiceIdToNavigateTo
   */
  private async navigateToInvoiceIfPossible(
    invoiceId?: string | null
  ): Promise<void> {
    if (!invoiceId) {
      invoiceId = this.invoiceIdToNavigateTo;
    }

    if (this.processTaskInvoicesWidget && invoiceId) {
      await this.navigateToTabByPageName('invoices');
      this.processTaskInvoicesWidget.navigateToInvoice(invoiceId);
      this.invoiceIdToNavigateTo = null;
    } else {
      this.invoiceIdToNavigateTo = invoiceId;
    }
  }

  /**
   * if you call this without the offerId, it will try to navigate to the offerIdToNavigateTo
   * if it's not possible to navigate to the offerId yet, it will be stored in offerIdToNavigateTo
   */
  private async navigateToOfferIfPossible(
    offerId?: string | null
  ): Promise<void> {
    if (!offerId) {
      offerId = this.offerIdToNavigateTo;
    }

    if (this.processTaskOffersWidget && offerId) {
      await this.navigateToTabByPageName('offers');
      this.processTaskOffersWidget.navigateToOffer(offerId);
      this.offerIdToNavigateTo = null;
    } else {
      this.offerIdToNavigateTo = offerId;
    }
  }

  protected handleProcessConfigurationStepClicked(
    event: ProcessConfigurationStepClickedEvent
  ): void {
    this.assignNewCurrentProcessConfigurationStepId(
      event.detail.processConfigurationStep.id
    );
  }

  protected handleNextStepButtonClick(): void {
    const processTask = this.processTask;
    if (!this.processTaskGroup || !processTask) {
      return;
    }

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        this.processTaskGroup.processConfigurationId
      );

    const currentIndex = steps.findIndex(
      (s) => s.id === processTask.currentProcessConfigurationStepId
    );
    const nextStep = steps[currentIndex + 1];
    if (nextStep) {
      this.assignNewCurrentProcessConfigurationStepId(nextStep.id);
    }
  }

  private assignNewCurrentProcessConfigurationStepId(newId: string): void {
    if (!this.processTask) {
      return;
    }

    void this.setProcessTaskCurrentProcessConfigurationStepIdService.setCurrentProcessConfigurationStepId(
      this.processTask,
      newId
    );
  }

  /**
   * load the entities from the services if available, else request them from the server
   */
  private async loadEntities(): Promise<void> {
    const processTaskId = this.activatedProcessTaskId;

    if (!processTaskId) {
      return;
    }

    await this.loadProcessTaskGroupWithEntitiesService.loadByProcessTaskId({
      processTaskId: processTaskId,
      temporaryGroupName: edit_process_task.TEMPORARY_GROUP_NAME,
      onProcessTaskLoaded: () => {
        if (this.isActivated) {
          this.loadEntitiesFromServices(processTaskId);
        }
      }
    });
  }

  private loadEntitiesFromServices(processTaskId: string): void {
    this.processTask =
      this.entityManager.processTaskRepository.getById(processTaskId);
    if (!this.processTask) {
      throw new Error(`no process task found with id "${processTaskId}"`);
    }
    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.processTask.ownerProcessTaskGroupId
      );
    if (!this.processTaskGroup) {
      throw new Error(
        `no process task group found with id "${this.processTask.ownerProcessTaskGroupId}"`
      );
    }

    this.availableProcessTasks =
      this.entityManager.processTaskRepository.getByProcessTaskGroupId(
        this.processTaskGroup.id
      );
    this.thingGroup = this.entityManager.thingGroupRepository.getById(
      this.processTaskGroup.thingGroupId
    );
    this.activeEntitiesService.setActiveProcessTask(this.processTask);

    this.lastOpenedProcessTaskGroupsService.processTaskGroupOpened({
      processTaskGroup: this.processTaskGroup,
      processTask: this.processTask
    });
  }

  private updateAvailableProcessTasks(): void {
    if (this.processTaskGroup) {
      this.availableProcessTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskGroup.id
        );
    } else {
      this.availableProcessTasks = [];
    }
  }

  private updateTabs(): void {
    const tabs: Array<TTab> = [
      {
        pageName: 'general',
        titleTk: 'generalPages.editProcessTask.tabs.general'
      },
      {
        pageName: 'comments',
        titleTk: 'generalPages.editProcessTask.tabs.comments',
        titleTkParams: { count: this.processTaskCommentCount }
      },
      {
        pageName: 'checklist',
        titleTk: 'generalPages.editProcessTask.tabs.checklist',
        titleTkParams: { count: this.openChecklistEntryCount }
      },
      {
        pageName: 'pictures',
        titleTk: 'generalPages.editProcessTask.tabs.pictures',
        titleTkParams: { count: this.processTaskPictureCount }
      },
      {
        pageName: 'devices',
        titleTk: 'generalPages.editProcessTask.tabs.devices',
        titleTkParams: { count: this.processTaskDeviceCount }
      },
      {
        pageName: 'appointments',
        titleTk: 'generalPages.editProcessTask.tabs.appointments',
        titleTkParams: { count: this.processTaskAppointmentCount }
      },
      {
        pageName: 'positions',
        titleTk: 'generalPages.editProcessTask.tabs.positions',
        titleTkParams: { count: this.processTaskPositionCount }
      },
      {
        pageName: 'offers',
        titleTk: 'generalPages.editProcessTask.tabs.offers',
        titleTkParams: { count: this.processTaskOfferCount }
      },
      {
        pageName: 'invoices',
        titleTk: 'generalPages.editProcessTask.tabs.invoices',
        titleTkParams: { count: this.processTaskInvoiceCount }
      },
      {
        pageName: 'forms',
        titleTk: 'generalPages.editProcessTask.tabs.forms',
        titleTkParams: { count: this.formCount }
      },
      {
        pageName: 'measurePoints',
        titleTk: 'generalPages.editProcessTask.tabs.measurePoints',
        titleTkParams: { count: this.processTaskMeasurePointCount }
      },
      {
        pageName: 'log',
        titleTk: 'generalPages.editProcessTask.tabs.log'
      },
      {
        pageName: 'projects',
        titleTk: 'generalPages.editProcessTask.tabs.projects',
        titleTkParams: { count: this.projectCount }
      }
    ];

    this.tabs = this.filterOutDisabledTabs(tabs);
    this.updateSelectedTab();
  }

  private updateSelectedTab(): void {
    let newTab = this.tabs[0] ?? null;

    const selectedTab = this.selectedTab;
    if (selectedTab) {
      const tab = this.tabs.find((t) => t.pageName === selectedTab.pageName);
      if (tab) {
        newTab = tab;
      }
    }

    this.selectedTab = newTab;
  }

  private filterOutDisabledTabs(tabs: Array<TTab>): Array<TTab> {
    if (
      !this.processConfiguration ||
      !this.processConfiguration.showProcessAppointmentTabs
    ) {
      return tabs;
    }

    const showProcessAppointmentTabs = Object.entries(
      this.processConfiguration.showProcessAppointmentTabs
    );
    const disabledTabKeys = showProcessAppointmentTabs
      .filter((t) => t[1] === false)
      .map((t) => t[0]);

    if (
      (this.processConfiguration.showProcessTaskFeatures?.offersAndInvoices ??
        true) === false
    ) {
      disabledTabKeys.push(...['offers', 'invoices']);
    }

    if (!this.processConfiguration.processTaskToProjectRelations?.enabled)
      disabledTabKeys.push('projects');

    return tabs.filter((t) => !disabledTabKeys.includes(t.pageName));
  }

  private async navigateToTabByPageName(pageName: string): Promise<void> {
    const tab = this.tabs.find((t) => t.pageName === pageName);
    if (!tab) {
      throw new Error(`tab with name ${pageName} not found`);
    }

    this.selectedTab = tab;

    return new Promise((resolve) => setTimeout(resolve, 0));
  }

  protected handleDeleteProcessTaskClicked(): void {
    const processTask = this.processTask;
    if (!processTask) {
      return;
    }

    void Dialogs.deleteDialogTk(
      'generalPages.editProcessTask.deleteProcessTaskDialogText'
    ).then(() => {
      this.deleteProcessTask(processTask);
    });
  }

  private deleteProcessTask(processTask: ProcessTask): void {
    const offerIds = this.entityManager.processTaskOfferToProcessTaskRepository
      .getByProcessTaskId(processTask.id)
      .map((r) => r.processTaskOfferId);
    const invoiceIds =
      this.entityManager.processTaskInvoiceToProcessTaskRepository
        .getByProcessTaskId(processTask.id)
        .map((r) => r.processTaskInvoiceId);

    this.entityManager.processTaskRepository.delete(processTask);

    // delete dangling offers/invoices
    // this happens when the processTask is the only processTask included in an offer/invoice because we can't let the deletion of the relations cascade
    // (they can't cascade because it is only allowed to cascade when it's the last relation)
    offerIds.forEach((offerId) => {
      const relations =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferIds(
          [offerId]
        );
      const offer =
        this.entityManager.processTaskOfferRepository.getById(offerId);
      if (relations.length === 0 && offer) {
        this.entityManager.processTaskOfferRepository.delete(offer);
      }
    });

    invoiceIds.forEach((invoiceId) => {
      const relations =
        this.entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceIds(
          [invoiceId]
        );
      const invoice =
        this.entityManager.processTaskInvoiceRepository.getById(invoiceId);
      if (relations.length === 0 && invoice) {
        this.entityManager.processTaskInvoiceRepository.delete(invoice);
      }
    });

    // navigate to next processTask or overview page
    const otherProcessTasks = this.availableProcessTasks.filter(
      (p) => p.id !== processTask.id
    );
    const firstOtherProcessTask = otherProcessTasks[0];
    if (firstOtherProcessTask) {
      ProcessTaskUtils.navigateToEditProcessTaskPage(
        this.router,
        firstOtherProcessTask.id
      );
    } else {
      ProcessTaskGroupUtils.navigateToEditProcessTaskGroupsPage(this.router);
    }
  }

  protected handleProcessTaskInfoOverviewEditPropertyClicked(
    event: TEditPropertyClickedEvent
  ): void {
    const widget = this.processTaskGeneralWidget;
    if (!widget) {
      return;
    }

    const property = event.detail.property;
    this.navigateToTabIfPossible('general');

    // wait until navigating to the tab is finished
    this.subscriptionsManager.subscribeToTimeout(() => {
      widget.focusProperty(property);
    }, 0);
  }

  protected handleProcessTaskInfoOverviewEditActionStatusClicked(): void {
    assertNotNullOrUndefined(
      this.processTaskGeneralWidget,
      'processTaskGeneralWidget not available'
    );

    this.navigateToTabIfPossible('general');
    this.processTaskGeneralWidget.focusActionStatus();
  }

  @computedFrom('processTaskPermissionsHandle.canDeleteEntity')
  protected get deleteProcessTaskChoiceIsDisabled(): boolean {
    return !this.processTaskPermissionsHandle.canDeleteEntity;
  }

  private navigateToTabIfPossible(pageName: string): void {
    const tab = this.tabs.find((t) => t.pageName === pageName);
    if (tab) {
      this.selectedTab = tab;
    }
  }
}

type TTab = {
  pageName: string;
  titleTk: string;
  titleTkParams?: Record<string, any>;
};
