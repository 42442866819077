import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createDefectCommentEntityInfo } from 'common/Types/Entities/DefectComment/createDefectCommentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { DefectComment } from './types';

export const defectCommentEntityInfo =
  createDefectCommentEntityInfo<DefectComment>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
