import { ProcessTaskLogEntry } from '../../../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';

export abstract class AbstractLogDataConverter {
  public abstract convert(options: ConvertOptions<string>): void;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public subscribe(options: SubscribeOptions): Disposable {
    return {
      dispose: () => {}
    };
  }
}

export type SubscribeOptions = {
  onDependenciesChanged: () => void;
};

export type ConvertOptions<TKeys extends string> = {
  logData: Record<string, unknown>;
  processTaskLogEntry: ProcessTaskLogEntry;
  /**
   * the newest log entry should be at the start
   */
  sortedProcessTaskLogEntries: Array<ProcessTaskLogEntry>;
  args: Record<TKeys, string>;
};
