import { autoinject } from 'aurelia-framework';
import _ from 'lodash';

import { CoordinateHelper } from '../classes/CoordinateHelper';
import { I18N } from 'aurelia-i18n';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../classes/Utils/DisposableContainer';

/**
 * a service which provides you with information of the global site state
 * e.g. if the location services are currently active
 * also provides you with warning texts to display for those information/warnings
 */
@autoinject()
export class GlobalSiteStateInfoService {
  private readonly subscriptionManager: SubscriptionManager;

  private readonly propertyBinder =
    new PropertyBinder<GlobalSiteStateInfoServicePropertyBinderConfig>({
      defaultValuesByName: {
        anyWarning: false,
        locationWarning: false,
        warningTexts: []
      }
    });

  private readonly coordinates = CoordinateHelper.getClientCoordinates();

  constructor(
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.subscriptionManager.subscribeToMultiplePropertyChanges(
      this.coordinates,
      ['latitude', 'longitude'],
      this.updateLocationWarning.bind(this)
    );

    this.updateLocationWarning();
  }

  /**
   * returns true when any warning is active
   */
  public getAnyWarning(): boolean {
    return this.propertyBinder.getRequiredValue('anyWarning');
  }

  /**
   * returns true when no location services are available
   */
  public getLocationWarning(): boolean {
    return this.propertyBinder.getRequiredValue('locationWarning');
  }

  public getWarningTexts(): Array<string> {
    return this.propertyBinder.getRequiredValue('warningTexts');
  }

  public registerBinding<
    TName extends
      PropertyBinderName<GlobalSiteStateInfoServicePropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<
      GlobalSiteStateInfoServicePropertyBinderConfig,
      TName
    >
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  private updateLocationWarning(): void {
    const locationWarning =
      this.coordinates.latitude == null || this.coordinates.longitude == null;
    this.propertyBinder.setValue('locationWarning', locationWarning);
    this.updateAnyWarning();
    this.updateWarningTexts();
  }

  private updateAnyWarning(): void {
    const anyWarning = this.propertyBinder.getRequiredValue('locationWarning');
    this.propertyBinder.setValue('anyWarning', anyWarning);
  }

  private updateWarningTexts(): void {
    const texts = this.createWarningTexts();

    if (
      !_.isEqual(this.propertyBinder.getRequiredValue('warningTexts'), texts)
    ) {
      this.propertyBinder.setValue('warningTexts', texts);
    }
  }

  private createWarningTexts(): Array<string> {
    const texts = [];

    if (this.propertyBinder.getRequiredValue('locationWarning')) {
      texts.push(this.translate('locationWarning'));
    }

    return texts;
  }

  private translate(key: string): string {
    return this.i18n.tr('services.globalSiteStateInfoService.' + key);
  }
}

type GlobalSiteStateInfoServicePropertyBinderConfig = {
  anyWarning: boolean;
  locationWarning: boolean;
  warningTexts: Array<string>;
};
