import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { ProcessTask } from '../../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { PositionCategoryGroup } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/ProcessTaskPositionUtils';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { ShowProcessAppointmentConfiguration } from '../../ShowProcessAppointmentConfiguration';

@autoinject()
export class ShowProcessAppointmentAllOfferedPositionsProcessTask {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public categorizedPositions: Array<PositionCategoryGroup> = [];

  @bindable()
  public configuration: ShowProcessAppointmentConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  protected readonly positionCategoryGroupIdGetter = (
    p: PositionCategoryGroup
  ): string | null => p.categoryId;
  protected readonly positionCategoryItemCountGetter = (
    items: Array<PositionCategoryGroup>
  ): number => items.reduce((sum, group) => sum + group.positions.length, 0);

  constructor(
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected get expandableContainerLabel(): string {
    const names = this.processTask
      ? this.processTaskNamesByProcessTaskId.get(this.processTask.id)
      : null;
    return names?.nameWithThingAndPerson ?? '';
  }
}
