import { autoinject, bindable } from 'aurelia-framework';
import autosize from 'autosize';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { Utils } from '../../classes/Utils/Utils';
import { InputElementResizer } from '../../classes/DomUtilities/InputElementResizer';

/**
 * @slot 'suffix' - useful for e.g. additional icons which should be shown after the input
 *
 * @event blur
 * @event value-changed - fired on the input event
 *
 * @attribute data-style - 'big' for a bigger version of this input
 * @attribute data-status - 'default', 'warning', 'error'
 */
@autoinject()
export class FloatingLabelInput {
  @bindable public value: string | number | null = null;

  @bindable public label: string | null = null;

  @bindable public placeholder = '';
  @bindable public size = 20;
  @bindable public type = 'text';

  @bindable public autocomplete: string | undefined;

  @bindable public autocorrect: string | undefined;

  @bindable public autocapitalize: string | undefined;

  @bindable public disabled = false;
  @bindable public readOnly = false;
  @bindable public multiline = false;
  @bindable public numberOnly = false;

  @bindable public min: number | null = null;

  @bindable public max: number | null = null;

  @bindable public theme: FloatingLabelInputTheme =
    FloatingLabelInputTheme.DEFAULT;

  @bindable public inputErrorText = '';
  @bindable public inputErrorTextTk = '';

  @bindable public inputIsFocused = false;

  /** set this to true so the input only has the needed width */
  @bindable public autoWidth = false;

  @bindable public inTable = false;

  private isAttached = false;

  private domElement: HTMLElement;

  private inputElement: HTMLInputElement | null = null;

  private multilineInputElement: HTMLTextAreaElement | null = null;

  private inputElementResizer: InputElementResizer | null = null;
  private numberIsValid: boolean = true;

  private updateAutoSizeRateLimited = Utils.rateLimitFunction(
    this.updateAutoSize.bind(this),
    250
  );
  private boundHandleNumberValidityChanged =
    this.handleNumberValidityChanged.bind(this);

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    if (this.autoWidth && this.inputElement) {
      this.inputElementResizer = new InputElementResizer(this.inputElement);
    }

    // autosize triggers a dom reflow, so execute them together to improve performance
    window.requestAnimationFrame(() => {
      if (this.isAttached) {
        // could have been detached in the meantime
        if (this.multilineInputElement) {
          autosize(this.multilineInputElement);
        }
        if (this.inputElementResizer) {
          const value = this.convertValueToString(this.value);
          this.inputElementResizer.resizeInput(value);
        }
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;

    if (this.multilineInputElement)
      autosize.destroy(this.multilineInputElement);
  }

  public getInputElement(): HTMLInputElement | null {
    return this.inputElement;
  }

  private handleInputFocus(): void {
    this.inputIsFocused = true;
    if (this.multilineInputElement) {
      autosize.update(this.multilineInputElement);
    }

    this.autoSelectInputText();
  }

  private handleInputBlur(): void {
    this.inputIsFocused = false;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'blur',
      detail: null
    });
  }

  private handleInputInput(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'value-changed',
        detail: null
      });
    });
  }

  private hasValue(value: string | null): boolean {
    return value !== '' && value != null;
  }

  public focus(): void {
    if (!this.multiline) {
      this.inputElement && this.inputElement.focus();
    } else {
      this.multilineInputElement && this.multilineInputElement.focus();
    }
  }

  protected valueChanged(): void {
    if (this.multiline) {
      this.updateAutoSizeRateLimited();
    }

    if (this.inputElementResizer) {
      const value = this.convertValueToString(this.value);
      this.inputElementResizer.resizeInput(value);
    }
  }

  protected autoWidthChanged(): void {
    if (this.autoWidth && this.isAttached && this.inputElement) {
      this.inputElementResizer = new InputElementResizer(this.inputElement);
    } else {
      this.inputElementResizer = null;
    }
  }

  private updateAutoSize(): void {
    window.requestAnimationFrame(() => {
      if (this.isAttached && this.multilineInputElement) {
        autosize.update(this.multilineInputElement);
      }
    });
  }

  private autoSelectInputText(): void {
    if (this.numberOnly && this.inputElement) {
      this.inputElement.select();
    }
  }

  private handleNumberValidityChanged(valid: boolean): void {
    this.numberIsValid = valid;
  }

  private convertValueToString(value: string | number | null): string {
    return value === null ? '' : '' + value;
  }
}

export enum FloatingLabelInputTheme {
  DEFAULT = 'default',
  RED = 'red',
  GREEN = 'green'
}
