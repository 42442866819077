import { Vector } from 'common/Geometry/Vector';

export enum CommandType {
  Absolute = 'absolute',
  Relative = 'relative'
}

export enum CommandIdentifier {
  M = 'M',
  m = 'm',
  L = 'L',
  l = 'l',
  C = 'C',
  c = 'c',
  Z = 'Z',
  z = 'z'
}

interface ICommand {
  identifier: CommandIdentifier;
  type: CommandType;
}

// move

export interface IMoveAbsoluteCommand extends ICommand {
  identifier: CommandIdentifier.M;
  type: CommandType.Absolute;
  point: Vector;
}

export interface IMoveRelativeCommand extends ICommand {
  identifier: CommandIdentifier.m;
  type: CommandType.Relative;
  point: Vector;
}

// Lines

export interface ILineAbsoluteCommand extends ICommand {
  identifier: CommandIdentifier.L;
  type: CommandType.Absolute;
  point: Vector;
}

export interface ILineRelativeCommand extends ICommand {
  identifier: CommandIdentifier.l;
  type: CommandType.Relative;
  point: Vector;
}

// Cubic

export interface ICubicAbsoluteCommand extends ICommand {
  identifier: CommandIdentifier.C;
  type: CommandType.Absolute;
  point: Vector;
  cp1: Vector;
  cp2: Vector;
}

export interface ICubicRelativeCommand extends ICommand {
  identifier: CommandIdentifier.c;
  type: CommandType.Relative;
  point: Vector;
  cp1: Vector;
  cp2: Vector;
}

// close

export interface ICloseAbsoluteCommand extends ICommand {
  identifier: CommandIdentifier.Z;
  type: CommandType.Absolute;
}

export interface ICloseRelativeCommand extends ICommand {
  identifier: CommandIdentifier.z;
  type: CommandType.Relative;
}

export type PathCommand =
  | IMoveAbsoluteCommand
  | IMoveRelativeCommand
  | ILineAbsoluteCommand
  | ILineRelativeCommand
  | ICubicAbsoluteCommand
  | ICubicRelativeCommand
  | ICloseAbsoluteCommand
  | ICloseRelativeCommand;
