import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DateUtils } from 'common/DateUtils';
import { GroupedDefectComments } from '../defect-entry-group/defect-entry-group';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';

/**
 * Visually groups multiple comments of the same user & date together.
 *
 * If you want to display a list of defect entries, use defect-entry-list instead.
 */
@autoinject()
export class DefectCommentGroup {
  @bindable public defectCommentGroup: GroupedDefectComments | null = null;

  protected defectCommentGroupUser: User | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    subManagerService: SubscriptionManagerService,
    private entityManager: AppEntityManager
  ) {
    this.subscriptionManager = subManagerService.create();
  }

  // ////////// LIFECYCLE //////////

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateDefectCommentGroupUser.bind(this)
    );
    this.updateDefectCommentGroupUser();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ////////// ENTITY UPDATERS //////////

  protected updateDefectCommentGroupUser(): void {
    if (!this.defectCommentGroup) {
      this.defectCommentGroupUser = null;
      return;
    }

    this.defectCommentGroupUser =
      this.entityManager.userRepository.getById(
        this.defectCommentGroup.senderId
      ) || null;
  }

  // ////////// OBSERVABLES //////////

  protected defectCommentGroupChanged(): void {
    this.updateDefectCommentGroupUser();
  }

  // ////////// HTML GETTERS //////////

  @computedFrom('defectCommentGroup.date')
  protected get prettyDefectCommentGroupDate(): string {
    if (!this.defectCommentGroup) return '...';
    return DateUtils.formatToDateString(this.defectCommentGroup.date);
  }
}
