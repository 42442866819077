export abstract class SketcherOverlayImageHandler {
  /**
   * load all the necessary data/subscribe to the subscription manager etc.
   * load the image asynchronously and provide waiting for the image to finish loading in the waitForImageLoad method
   */
  public abstract load(image: HTMLImageElement): Promise<void>;

  /**
   * remove all subscriptions etc.
   */
  public abstract destroy(): void;

  /**
   * the promise should be resolved/rejected as soon as an image/error is available
   */
  public abstract waitForImageLoad(): Promise<void>;

  /**
   * load the sketch or return null if there is no existing sketch
   */
  public abstract loadSketch(): Promise<SVGSVGElement | null>;

  /**
   * @param sketchDataUrl - the sketch dataUrl for being saved as an .svg file
   * @param sketchDataUrlForCanvas - the sketch dataUrl with a width/height attribute to be drawn on a canvas (without that it would be buggy on some browsers)
   */
  public abstract save(
    sketchDataUrl: string,
    sketchDataUrlForCanvas: string
  ): Promise<void>;
}
