import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { createProcessConfigurationAuthorizationTypeEntityInfo } from 'common/Types/Entities/ProcessConfigurationAuthorizationType/createProcessConfigurationAuthorizationTypeEntityInfo';
import { ProcessConfigurationAuthorizationType } from './types';

export const processConfigurationAuthorizationTypeEntityInfo =
  createProcessConfigurationAuthorizationTypeEntityInfo<ProcessConfigurationAuthorizationType>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
