import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskMeasurePointToPicture } from './types';

export class ProcessTaskMeasurePointToPictureRepository extends AppEntityRepository<EntityName.ProcessTaskMeasurePointToPicture> {
  public getByMeasurePointIdAndPictureId(
    measurePointId: string,
    pictureId: string
  ): ProcessTaskMeasurePointToPicture | null {
    return (
      this.getAll().find(
        (r) =>
          r.processTaskMeasurePointId === measurePointId &&
          r.pictureId === pictureId
      ) ?? null
    );
  }

  public getByMeasurePointId(
    measurePointId: string
  ): Array<ProcessTaskMeasurePointToPicture> {
    return this.getAll().filter(
      (r) => r.processTaskMeasurePointId === measurePointId
    );
  }

  public getByPictureId(
    pictureId: string
  ): Array<ProcessTaskMeasurePointToPicture> {
    return this.getAll().filter((r) => r.pictureId === pictureId);
  }

  public getByOwnerProcessTaskId(
    ownerProcessTaskId: string
  ): Array<ProcessTaskMeasurePointToPicture> {
    return this.getAll().filter(
      (r) => r.ownerProcessTaskId === ownerProcessTaskId
    );
  }
}
