/**
 * this makes it possible to link multiple scrollable containers together,
 * so if you scroll one container the other will scroll too.
 *
 * Also you can link the top/left position of an element to a scroll container.
 *
 */
export class ScrollLinker {
  /**@type {Array<ScrollLink>}*/
  _links = [];
  /**@type {Function}*/
  _boundHandleLinkUpdated;
  /**@type {number}*/
  _lastValue = 0;
  /**@type {Function}*/
  _lastValueChangedCallback;

  constructor() {
    this._boundHandleLinkUpdated = this._handleLinkUpdated.bind(this);
  }

  /**
   *
   * @param {HTMLElement} element
   * @param {TScrollLinkOptions} options
   */
  addLink(element, options) {
    const link = new ScrollLink(element, options, this._boundHandleLinkUpdated);
    link.link();
    this._links.push(link);
  }

  /**
   *
   * @param {HTMLElement} element
   */
  removeLinksForElement(element) {
    for (let key = this._links.length - 1; key >= 0; key--) {
      const link = this._links[key];

      if (link.getElement() == element) {
        link.unlink();
        this._links.splice(key, 1);
      }
    }
  }

  removeAllLinks() {
    this._links.forEach((l) => {
      l.unlink();
    });

    this._links.length = 0;
  }

  /**
   * retrieves the last value which was set
   *
   * @returns {number}
   */
  getLastValue() {
    return this._lastValue;
  }

  /**
   *
   * @param {Function} callback
   */
  onLastValueChanged(callback) {
    this._lastValueChangedCallback = callback;
  }

  /**
   *
   * @param {number} value
   * @param {ScrollLink} link
   * @private
   */
  _handleLinkUpdated(value, link) {
    this._links.forEach((l) => {
      if (l !== link) {
        l.update(value);
      }
    });

    if (value != this._lastValue) {
      this._lastValue = value;
      this._lastValueChangedCallback && this._lastValueChangedCallback();
    }
  }
}

class ScrollLink {
  /**@type {(Function|null)}*/
  _boundHandleScroll;

  /**
   *
   * @param {HTMLElement} element
   * @param {TScrollLinkOptions} options
   * @param {function(value: number, link: ScrollLink)} updateCallback - will get called everytime the user scrolls the element
   */
  constructor(element, options, updateCallback) {
    this._element = element;
    this._options = options;
    this._updateCallback = updateCallback;
    this._ignoreNextScroll = false;
  }

  getElement() {
    return this._element;
  }

  link() {
    if (this._options.strategy == 'scroll') {
      this._boundHandleScroll = this._handleScroll.bind(this);
      this._element.addEventListener('scroll', this._boundHandleScroll);
    }
  }

  unlink() {
    if (this._boundHandleScroll) {
      this._element.removeEventListener('scroll', this._boundHandleScroll);
    }
  }

  /**
   *
   * @param {number} value
   */
  update(value) {
    this._updatingInProgress = true;

    switch (this._options.strategy) {
      case 'scroll':
        this._updateScrollPosition(value);
        break;

      case 'position':
        this._updatePosition(value);
        break;
    }

    this._updatingInProgress = false;
  }

  /**
   *
   * @param {number} value
   */
  _updateScrollPosition(value) {
    const propertyName =
      this._options.direction === 'vertical' ? 'scrollTop' : 'scrollLeft';
    const oldValue = this._element[propertyName];

    if (oldValue != null && oldValue != value) {
      this._ignoreNextScroll = true; //since the scroll is delayed this is the only way to prevent an unnecessary update
    }

    this._element[propertyName] = value;
  }

  /**
   *
   * @param {number} value
   */
  _updatePosition(value) {
    if (this._options.direction === 'vertical') {
      this._element.style.top = value + 'px';
    } else {
      this._element.style.left = value + 'px';
    }
  }

  /**
   *
   * @performance could be improved by storing the old value, so the callback will only get called when necessary
   * e.g. callback will get called if the direction is horizontal and the element will get scrolled vertically
   *
   * @param {Event} event
   * @private
   */
  _handleScroll(event) {
    if (this._ignoreNextScroll) {
      this._ignoreNextScroll = false;
      return;
    }

    if (this._options.direction === 'vertical') {
      this._updateCallback(this._element.scrollTop, this);
    } else {
      this._updateCallback(this._element.scrollLeft, this);
    }
  }
}

/**
 * @typedef {Object} TScrollLinkOptions
 *
 * @property {string} strategy - 'scroll' or 'position'
 * @property {string} direction - 'horizontal' or 'vertical'
 */

/**
 * strategies:
 *
 * scroll: will set the scrollTop and scrollLeft properties, will also listen to the scroll event of the element
 *
 * position: will set the top and left properties of the element
 */
