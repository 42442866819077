import { autoinject, bindable } from 'aurelia-framework';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  GetProcessTaskGroupRelationInfoRequest,
  GetProcessTaskGroupRelationInfoSuccessResponse,
  ProcessTaskGroupRelationInfo,
  ProcessTaskGroupRelationInfoRequestType
} from 'common/EndpointTypes/ProcessTaskGroupRelationInfoEndpointsHandler';

import { watch } from '../../hooks/watch';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';
import {
  SingleSocketRequest,
  SingleSocketRequestService,
  SingleSocketRequestSkippedError
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { interval } from '../../hooks/dependencies';
import { SocketService } from '../../services/SocketService';
import { ManageProcessTaskGroupRelationsDialog } from '../../dialogs/manage-process-task-group-relations-dialog/manage-process-task-group-relations-dialog';

@autoinject()
export class ProcessTaskGroupRelationsWidget {
  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  protected availableProcessTaskGroupRelationInfos: Array<ProcessTaskGroupRelationInfo> =
    [];

  private getProcessTaskGroupRelationInfosRequest: SingleSocketRequest<
    GetProcessTaskGroupRelationInfoRequest<ProcessTaskGroupRelationInfoRequestType.WITH_EXISTING_RELATION>,
    GetProcessTaskGroupRelationInfoSuccessResponse
  >;

  constructor(
    private readonly socketService: SocketService,
    singleSocketRequestService: SingleSocketRequestService
  ) {
    this.getProcessTaskGroupRelationInfosRequest =
      singleSocketRequestService.createAfterFirstActualizationRequest({
        requestCallback: ({ data }) => {
          return new Promise<GetProcessTaskGroupRelationInfoSuccessResponse>(
            (resolve, reject) => {
              socketService.getProcessTaskGroupRelationInfos(data, (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(
                    new Error("couldn't fetch process task group context infos")
                  );
                }
              });
            }
          );
        }
      });
  }

  protected processTaskGroupChanged(): void {
    void this.updateAvailableProcessTaskGroupRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  @watch(interval(10000))
  protected pollProcessTaskGroupRelationInfos(): void {
    if (!this.getProcessTaskGroupRelationInfosRequest.isPending) {
      void this.updateAvailableProcessTaskGroupRelationInfos({
        showOverlayForLongResponseTimes: true,
        showOverlayImmediately: false
      });
    }
  }

  protected async updateAvailableProcessTaskGroupRelationInfos(
    config: UpdateSettings
  ): Promise<void> {
    if (
      !this.socketService.isAuthenticated() ||
      !this.socketService.isConnected()
    ) {
      return;
    }

    const delayedLoadingOverlay = !config.showOverlayForLongResponseTimes
      ? setTimeout(() => {
          GlobalLoadingOverlay.setLoadingState(this, true);
        }, 1000)
      : null;

    try {
      config.showOverlayImmediately &&
        GlobalLoadingOverlay.setLoadingState(this, true);

      assertNotNullOrUndefined(
        this.processTaskGroup,
        'cannot updateAvailableProcessTaskGroupRelationInfos() without processTaskGroup'
      );
      const response = await this.getProcessTaskGroupRelationInfosRequest.send({
        activeProcessTaskGroupId: this.processTaskGroup.id,
        type: ProcessTaskGroupRelationInfoRequestType.WITH_EXISTING_RELATION,
        paginationInfo: null
      });
      const processTaskGroupInfos = response.processTaskGroupInfos;
      this.availableProcessTaskGroupRelationInfos = processTaskGroupInfos;
    } catch (e) {
      if (!(e instanceof SingleSocketRequestSkippedError)) {
        throw e;
      }
    } finally {
      if (delayedLoadingOverlay) clearTimeout(delayedLoadingOverlay);
      GlobalLoadingOverlay.setLoadingState(this, false);
    }
  }

  protected handleManageRelationsButtonClick(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot handleManageRelationsButtonClick without processTaskGroup'
    );

    void ManageProcessTaskGroupRelationsDialog.open({
      currentProcessTaskGroup: this.processTaskGroup,
      onNewRelationInfos: () => {
        void this.updateAvailableProcessTaskGroupRelationInfos({
          showOverlayForLongResponseTimes: true,
          showOverlayImmediately: false
        });
      }
    });
  }
}

type UpdateSettings = {
  showOverlayImmediately: boolean;
  showOverlayForLongResponseTimes: boolean;
};
