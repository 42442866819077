import { bindable } from 'aurelia-framework';

export class LoadingButton {
  private loadingCount = 0;

  @bindable public caption: string = '';
  @bindable public buttonClass: string = '';
  @bindable public disabled = false;

  @bindable public loading = false;

  /**
   * you have to call stop for every start call
   */
  public start(): void {
    this.loadingCount++;
  }

  public stop(): void {
    if (this.loadingCount > 0) this.loadingCount--;
  }

  /**
   * call reset instead of stop if you don't want the counting functionality
   */
  public reset(): void {
    this.loadingCount = 0;
  }

  public isLoading(): boolean {
    return this.loadingCount === 0;
  }
}
