import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import '../../../libs/msvg/msvg';
import { assertNotNullOrUndefined } from 'common/Asserts';

export class DrawingHistoryManager {
  private static readonly HISTORY_SIZE = 20;

  private history: Array<SVGSVGElement> = [];

  private msvg: Msvg.Msvg;
  private dispatcher: EventDispatcher<{
    changed: null;
    'msvg-modified': null;
  }> = new EventDispatcher();
  private currentIndex: number = -1;

  constructor(msvg: Msvg.Msvg) {
    this.msvg = msvg;
    this.push();
  }

  public reset(): void {
    this.history = [];
    this.push();
    this.dispatcher.dispatchEvent('changed', null);
    this.dispatcher.dispatchEvent('msvg-modified', null);
  }

  /**
   * saves the current state into the history
   * also removes the forward queue if there was one
   */
  public push(): void {
    this.removeRedoHistory();

    if (this.history.length >= DrawingHistoryManager.HISTORY_SIZE) {
      this.history.shift();
    }

    this.currentIndex = this.history.length;
    this.history.splice(
      this.currentIndex,
      1,
      this.msvg.getElement().cloneNode(true) as SVGSVGElement
    );
    this.fireChangedEvent();
  }

  /**
   * loads the last state into the canvas
   */
  public undo(): void {
    if (!this.canUndo()) {
      return;
    }

    this.currentIndex = Math.max(this.currentIndex - 1, 0); // never remove the first item
    const item = this.history[this.currentIndex];
    assertNotNullOrUndefined(item, 'cannot undo without item');
    this.loadHistoryItem(item);
    this.fireChangedEvent();
  }

  /**
   * loads the next step (if one exists) into the canvas
   */
  public redo(): void {
    if (!this.canRedo()) {
      return;
    }

    const nextItem = this.history[this.currentIndex + 1];

    if (nextItem) {
      ++this.currentIndex;
      this.loadHistoryItem(nextItem);
    }
    this.fireChangedEvent();
  }

  public canUndo(): boolean {
    return this.currentIndex >= 1;
  }

  public canRedo(): boolean {
    return this.currentIndex < this.history.length - 1;
  }

  /**
   * fired everytime when the history status changed (e.g. a new state has been pushed)
   */
  public addChangedListener(context: any, callback: () => void): void {
    this.dispatcher.addEventListener(context, 'changed', callback);
  }

  /**
   * only fired when an history item has been loaded into the msvg
   */
  public addMsvgModifiedListener(context: any, callback: () => void): void {
    this.dispatcher.addEventListener(context, 'msvg-modified', callback);
  }

  public removeEventListeners(context: any): void {
    this.dispatcher.removeEventListenersByContext(context);
  }

  private fireChangedEvent(): void {
    this.dispatcher.dispatchEvent('changed', null);
  }

  private loadHistoryItem(item: SVGSVGElement): void {
    this.msvg.empty();

    const svg = this.msvg.getElement();
    // we don't want modify the item because else the content would be lost when redoing and undoing the history
    const itemClone = item.cloneNode(true);
    while (itemClone.firstChild) {
      svg.appendChild(itemClone.firstChild);
    }

    this.dispatcher.dispatchEvent('msvg-modified', null);
  }

  private removeRedoHistory(): void {
    if (this.canRedo()) {
      // there must be new data on top of the currentIndex, remove it
      this.history.splice(
        this.currentIndex + 1,
        this.history.length - this.currentIndex - 1
      );
    }
  }
}
