import { autoinject, bindable } from 'aurelia-framework';
import { OriginalIdUtils } from '../../classes/EntityManager/utils/OriginalIdUtils/OriginalIdUtils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskNamesByProcessTaskId,
  ProcessTaskNamesByProcessTaskIdComputer
} from '../../computedValues/computers/ProcessTaskNamesByProcessTaskIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class ProcessTaskSelect {
  @bindable()
  public value: string | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private availableProcessTasks: Array<ProcessTask> = [];
  protected options: Array<ProcessTaskOption> = [];
  protected processTaskNamesByProcessTaskId: ProcessTaskNamesByProcessTaskId =
    new Map();

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.entityManager.entitySynchronization.registerHooks({
        entityIdUpgraded: (entityWithEntityName) => {
          if (entityWithEntityName.entityName === EntityName.ProcessTask) {
            this.upgradeProcessTaskIdIfNecessary(
              entityWithEntityName.entity as ProcessTask
            );
          }
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskNamesByProcessTaskIdComputer,
        computeData: {},
        callback: (processTaskNamesByProcessTaskId) => {
          this.processTaskNamesByProcessTaskId =
            processTaskNamesByProcessTaskId;
          this.updateOptions();
        }
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateAvailableProcessTasks.bind(this)
    );
    this.updateAvailableProcessTasks();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateAvailableProcessTasks();
    }
  }

  protected handleSelectChanged(): void {
    this.fireValueChangedEvent();
  }

  protected updateAvailableProcessTasks(): void {
    if (this.processTaskGroup) {
      this.availableProcessTasks =
        this.entityManager.processTaskRepository.getByProcessTaskGroupId(
          this.processTaskGroup.id
        );
    } else {
      this.availableProcessTasks = [];
    }

    this.updateOptions();
  }

  protected updateOptions(): void {
    const newOptions = this.availableProcessTasks.map<ProcessTaskOption>(
      (processTask) => {
        return {
          value: processTask.id,
          label:
            this.processTaskNamesByProcessTaskId.get(processTask.id)
              ?.nameWithThingAndPerson ?? ''
        };
      }
    );

    this.options = InstancePreserver.createNewArray({
      originalArray: this.options,
      newArray: newOptions,
      getTrackingValue: (option) => option.value
    });
  }

  private upgradeProcessTaskIdIfNecessary(
    upgradedProcessTask: ProcessTask
  ): void {
    if (!this.value) {
      return;
    }

    if (
      OriginalIdUtils.getOriginalIdsForEntity(upgradedProcessTask).includes(
        this.value
      )
    ) {
      this.value = upgradedProcessTask.id;
    }
  }

  protected fireValueChangedEvent(): void {
    DomEventHelper.fireEvent<ProcessTaskSelectValueChangedEvent>(this.element, {
      name: 'value-changed',
      detail: {
        value: this.value
      }
    });
  }
}

export type ProcessTaskSelectValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null }
>;

type ProcessTaskOption = {
  value: string;
  label: string;
};
