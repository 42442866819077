/**
 * this class is used to prevent the site from scrolling, and also to fix case where
 * multiple modules want to lock the scrolling at the same time and unlock it at different times
 *
 * this class will only prevent the root scrolling!
 * useful for e.g. Dialogs so you don't accidentally scroll in the background
 */
import { EventDispatcher } from './EventDispatcher/EventDispatcher';

export class SiteScrollLocker {
  private static locks: Record<string, boolean> = {};
  private static locked = false;
  private static dispatcher = new EventDispatcher<EventConfig>();

  public static lockScrolling(name: string): void {
    this.locks[name] = true;
    this.updateScrollLock();
  }

  public static unlockScrolling(name: string): void {
    this.locks[name] = false;
    this.updateScrollLock();
  }

  public static isLocked(): boolean {
    return this.locked;
  }

  /**
   * useful for e.g. element which positions are calculated and which would get a wrong position when the scrollbar gets removed
   */
  public static addScrollLockedStateChangedListener(
    context: any,
    callback: () => void
  ): void {
    this.dispatcher.addEventListener(
      context,
      'scroll-locked-changed',
      callback
    );
  }

  public static removeListeners(context: any): void {
    this.dispatcher.removeEventListenersByContext(context);
  }

  private static updateLocked(): void {
    let locked = false;

    for (const key in this.locks) {
      if (this.locks.hasOwnProperty(key) && this.locks[key]) {
        locked = true;
        break;
      }
    }

    this.locked = locked;
  }

  private static updateScrollLock(): void {
    const scrollingElement = document.documentElement;

    const oldLocked = scrollingElement.classList.contains('o-hidden');
    this.updateLocked();

    if (this.locked) {
      scrollingElement.classList.add('o-hidden');
    } else {
      scrollingElement.classList.remove('o-hidden');
    }

    if (this.locked !== oldLocked) {
      this.dispatcher.dispatchEvent('scroll-locked-changed', null);
    }
  }
}

type EventConfig = {
  ['scroll-locked-changed']: null;
};
