import { computedFrom } from 'aurelia-framework';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { ThingSectionAdapter } from '../EntityAdapter/ThingSectionAdapter/ThingSectionAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ThingSectionPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ThingSectionAdapter>,
  ThingSection
> {
  @computedFrom('combinedRevision')
  public get canEditPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPictures');
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }
}
