import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { TagDto } from './TagDto';

export function createTagEntityInfo<TEntity extends TagDto<string, string>>(
  options: CreateEntityInfoOptions<TagDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.Tag,
  TEntity
> {
  return {
    entityName: EntityName.Tag,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      TagDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        name: {
          name: 'name'
        },
        category: {
          name: 'category',
          defaultValue: null
        },
        order: {
          name: 'order',
          defaultValue: null
        },
        project: {
          name: 'project',
          ref: EntityName.Project,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thing: {
          name: 'thing',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
