import { bindable, computedFrom } from 'aurelia-framework';

/**
 * A box which expands to the specified ratio.
 * Can be controlled by the width or height.
 *
 * @slot default - content needs to be positioned absolutely or it will be positioned on the bottem
 */
export class AspectRatioBox {
  /**
   * Width and height separated by a /
   * Spaces will be ignored
   * Comma separator must be .
   */
  @bindable()
  public ratio: string = '4/3';

  protected width: number = 4;
  protected height: number = 3;

  protected ratioChanged(): void {
    const withoutSpaces = this.ratio.replace(/\s/g, '');
    const parts = withoutSpaces.split('/');
    const width = parts[0] ? parseFloat(parts[0]) : NaN;
    const height = parts[1] ? parseFloat(parts[1]) : NaN;

    if (parts.length === 2 && !isNaN(width) && !isNaN(height)) {
      this.width = width;
      this.height = height;
    } else {
      throw new Error(`Unsupported ratio: "${this.ratio}"`);
    }
  }

  @computedFrom('width', 'height')
  protected get viewBox(): string {
    return `0 0 ${this.width} ${this.height}`;
  }
}
