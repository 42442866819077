import { autoinject, bindable } from 'aurelia-framework';
import { ThingSectionConfigPropertyLocation } from 'common/Types/Entities/ThingSectionConfigProperty/ThingSectionConfigPropertyDto';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ManagePropertyDefinitionsWidgetThingSectionConfigAdapter } from '../../../propertyComponents/manage-property-definitions-widget/ManagePropertyDefinitionsWidgetAdapter/ManagePropertyDefinitionsWidgetThingSectionConfigAdapter/ManagePropertyDefinitionsWidgetThingSectionConfigAdapter';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@autoinject()
export class EditThingSectionConfigPropertiesDialogPropertyConfigs {
  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public location = ThingSectionConfigPropertyLocation.THING;

  private readonly subscriptionManager: SubscriptionManager;
  protected configPropertiesCount: number | null = null;
  protected managePropertyDefinitionsWidgetAdapter: ManagePropertyDefinitionsWidgetThingSectionConfigAdapter | null =
    null;

  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService,
    private readonly subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSectionConfigProperty,
      this.updateConfigPropertiesCount.bind(this)
    );
    this.updateConfigPropertiesCount();

    this.updateManagePropertyDefinitionsWidgetAdapter();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.updateConfigPropertiesCount();
      this.updateManagePropertyDefinitionsWidgetAdapter();
    }
  }

  protected locationChanged(): void {
    if (this.isAttached) {
      this.updateConfigPropertiesCount();
      this.updateManagePropertyDefinitionsWidgetAdapter();
    }
  }

  private updateManagePropertyDefinitionsWidgetAdapter(): void {
    if (this.thing) {
      this.managePropertyDefinitionsWidgetAdapter =
        new ManagePropertyDefinitionsWidgetThingSectionConfigAdapter({
          entityManager: this.entityManager,
          permissionsService: this.permissionsService,
          subscriptionManagerService: this.subscriptionManagerService,
          location: this.location,
          thing: this.thing
        });
    } else {
      this.managePropertyDefinitionsWidgetAdapter = null;
    }
  }

  private updateConfigPropertiesCount(): void {
    if (this.thing) {
      this.configPropertiesCount =
        this.entityManager.thingSectionConfigPropertyRepository.getOrderedByThingIdAndLocation(
          {
            thingId: this.thing.id,
            location: this.location
          }
        ).length;
    } else {
      this.configPropertiesCount = null;
    }
  }
}
