import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskOfferToProcessTaskPosition } from './types';

export class ProcessTaskOfferToProcessTaskPositionRepository extends AppEntityRepository<EntityName.ProcessTaskOfferToProcessTaskPosition> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskOfferToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => e.ownerProcessTaskGroupId === processTaskGroupId
    );
  }

  public getByProcessTaskOfferId(
    processTaskOfferId: string
  ): Array<ProcessTaskOfferToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => e.processTaskOfferId === processTaskOfferId
    );
  }

  public getByProcessTaskPositionId(
    processTaskPositionId: string
  ): Array<ProcessTaskOfferToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => e.processTaskPositionId === processTaskPositionId
    );
  }

  public getByProcessTaskPositionIds(
    processTaskPositionIds: Array<string>
  ): Array<ProcessTaskOfferToProcessTaskPosition> {
    return this.getAll().filter(
      (e) => processTaskPositionIds.indexOf(e.processTaskPositionId) >= 0
    );
  }
}
