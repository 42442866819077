import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectThingSectionPropertyAdapter } from '../../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProjectThingSectionPropertyAdapter';
import { ThingThingSectionPropertyAdapter } from '../../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ThingThingSectionPropertyAdapter';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ThingSectionPicture } from '../../../classes/EntityManager/entities/Picture/types';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueChangedEvent } from '../../thing-section-select/thing-section-select';

@autoinject()
export class ThingSectionAssessmentDialogThingSectionContent {
  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public project: Project | null = null;

  @bindable()
  public thingSection: ThingSection | null = null;

  private isAttached: boolean = false;
  protected picture: ThingSectionPicture | null = null;

  protected thingThingSectionPropertiesAdapter: ThingThingSectionPropertyAdapter | null =
    null;

  protected projectThingSectionPropertiesAdapter: ProjectThingSectionPropertyAdapter | null =
    null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {}

  protected attached(): void {
    this.isAttached = true;

    this.updateThingThingSectionPropertiesAdapter();
    this.updateProjectThingSectionPropertiesAdapter();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected thingSectionChanged(): void {
    if (this.isAttached) {
      this.updateThingThingSectionPropertiesAdapter();
      this.updateProjectThingSectionPropertiesAdapter();
    }
  }

  protected projectChanged(): void {
    if (this.isAttached) {
      this.updateProjectThingSectionPropertiesAdapter();
    }
  }

  private updateThingThingSectionPropertiesAdapter(): void {
    if (this.thingSection) {
      this.thingThingSectionPropertiesAdapter =
        new ThingThingSectionPropertyAdapter({
          entityManager: this.entityManager,
          permissionsService: this.permissionsService,
          subscriptionManagerService: this.subscriptionManagerService,
          thingSection: this.thingSection
        });
    } else {
      this.thingThingSectionPropertiesAdapter = null;
    }
  }

  private updateProjectThingSectionPropertiesAdapter(): void {
    if (this.thingSection && this.project) {
      this.projectThingSectionPropertiesAdapter =
        new ProjectThingSectionPropertyAdapter({
          entityManager: this.entityManager,
          permissionsService: this.permissionsService,
          subscriptionManagerService: this.subscriptionManagerService,
          thingSection: this.thingSection,
          project: this.project
        });
    } else {
      this.projectThingSectionPropertiesAdapter = null;
    }
  }

  protected handleThingSectionSelected(event: ValueChangedEvent): void {
    assertNotNullOrUndefined(
      event.detail.selectedThingSection,
      'No thing section selected'
    );

    DomEventHelper.fireEvent<ThingSectionSelectedEvent>(this.element, {
      name: 'thing-section-selected',
      detail: {
        thingSection: event.detail.selectedThingSection
      }
    });
  }
}

export type ThingSectionSelectedEvent = NamedCustomEvent<
  'thing-section-selected',
  { thingSection: ThingSection }
>;
