import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';
import { NavigationService } from '../../../services/NavigationService';

@autoinject
export class ProjectSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.Project]
    | null = null;

  protected itemProperties: Array<ItemProperty> = [];

  constructor(
    private readonly router: Router,
    private readonly navigationService: NavigationService
  ) {}

  // Aurelia Lifecycle

  protected attached(): void {
    this.updateItemProperties();
  }

  // Aurelia Change Handlers

  protected searchResultChanged(): void {
    this.updateItemProperties();
  }

  // Aurelia Handlers

  protected handleNavigateToProjectClick(): void {
    const route = this.getRouteForProjectSearchResult();
    if (!route) return;

    this.router.navigate(route);
  }

  // Getters

  @computedFrom('searchResult.projectType')
  protected get projectTypeColor(): string {
    switch (this.searchResult?.projectType) {
      case ProjectType.B1300:
        return 'var(--record-it-color-module-b1300)';

      case ProjectType.INSPECT:
        return 'var(--record-it-color-module-inspect)';

      case ProjectType.BASIC:
      default:
        return 'var(--record-it-color-module-basic)';
    }
  }

  // Private Methods

  private updateItemProperties(): void {
    if (!this.searchResult) {
      this.itemProperties = [];
    } else {
      this.itemProperties = [
        {
          labelTk: 'modelProperties.description',
          value: this.searchResult.description ?? ''
        },
        {
          labelTk: 'modelProperties.latitude',
          value: this.searchResult.latitude?.toString() ?? ''
        },
        {
          labelTk: 'modelProperties.longitude',
          value: this.searchResult.longitude?.toString() ?? ''
        },
        {
          labelTk: 'modelProperties.created',
          value: DateUtils.formatToDateWithHourMinuteString(
            this.searchResult.created
          )
        }
      ];
    }
  }

  private getRouteForProjectSearchResult(): string | null {
    assertNotNullOrUndefined(this.searchResult, 'cannot navigate to project');

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
        return this.navigationService.getProjectPageUrl(
          this.searchResult.id,
          this.searchResult.projectType
        );

      case ReasonType.Picture:
      case ReasonType.Tag:
        return this.navigationService.getProjectPageUrl(
          this.searchResult.id,
          this.searchResult.projectType,
          {
            open_parameter_panel: true
          }
        );

      case ReasonType.Property:
        return this.navigationService.getProjectPageUrl(
          this.searchResult.id,
          this.searchResult.projectType,
          {
            open_parameter_panel: true,
            scroll_to_property: this.searchResult.reason.data.propertyId
          }
        );

      default:
        throw new Error(
          `cannot navigate to project result with unknown reason ${
            (
              this
                .searchResult as EntityNameToSearchResultMap[EntityName.Project]
            ).reason.type
          }`
        );
    }
  }
}
