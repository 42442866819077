import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processConfigurationDeviceExportEntityInfo } from '../../../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/processConfigurationDeviceExportEntityInfo';
import { ProcessConfigurationDeviceExport } from '../../../../classes/EntityManager/entities/ProcessConfigurationDeviceExport/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessConfigurationDeviceExportAdapter
  implements EntityAdapter<ProcessConfigurationDeviceExport>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProcessConfigurationDeviceExportAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canEditField(entity: ProcessConfigurationDeviceExport): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(entity.ownerUserGroupId)
      .getCanUpdateProcessConfigurationDeviceExports();
  }

  public canDeleteEntity(entity: ProcessConfigurationDeviceExport): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(entity.ownerUserGroupId)
      .getCanDeleteProcessConfigurationDeviceExports();
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessConfigurationDeviceExport,
    ProcessConfigurationDeviceExport
  > {
    return processConfigurationDeviceExportEntityInfo;
  }
}

export type ProcessConfigurationDeviceExportAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
