import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfiguration } from './types';

export class ProcessConfigurationRepository extends AppEntityRepository<EntityName.ProcessConfiguration> {
  public getByUserGroupId(userGroupId: string): Array<ProcessConfiguration> {
    return this.getAll().filter(
      (configuration) => configuration.ownerUserGroupId === userGroupId
    );
  }
}
