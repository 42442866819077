import { inject } from 'aurelia-framework';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { defaultProcessTaskMeasurePointReadingProperties as defaultReadingProperties } from '../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';

@inject(AppEntityManager)
export class ProcessTaskMeasurePointReadingCreationService {
  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  /**
   *
   * @param {import('../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {import('../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment} appointment
   */
  createReadingWithProperties(measurePoint, appointment) {
    const measurePointReading = this.createReading(measurePoint, appointment);

    this.createReadingProperties(measurePoint, measurePointReading);

    return measurePointReading;
  }

  /**
   *
   * @param {import('../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {import('../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment} appointment
   */
  createReading(measurePoint, appointment) {
    const measurePointReading =
      this._entityManager.processTaskMeasurePointReadingRepository.create({
        ownerProcessTaskGroupId: measurePoint.ownerProcessTaskGroupId,
        ownerProcessTaskId: measurePoint.ownerProcessTaskId,
        ownerUserGroupId: measurePoint.ownerUserGroupId,
        processTaskAppointmentId: appointment.id,
        processTaskMeasurePointId: measurePoint.id,
        date: new Date().toISOString()
      });

    return measurePointReading;
  }

  /**
   *
   * @param {import('../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @param {import('../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} measurePointReading
   */
  createReadingProperties(measurePoint, measurePointReading) {
    const type = measurePoint.processConfigurationMeasurePointTypeId
      ? this._entityManager.processConfigurationMeasurePointTypeRepository.getById(
          measurePoint.processConfigurationMeasurePointTypeId
        )
      : null;
    const json = type ? type.defaultReadingPropertyValuesJson : null;
    /** @type {import('../../../common/src/Types/ProcessConfigurationMeasurePointType').TDefaultReadingPropertyValues} */
    const defaultValues = json ? JSON.parse(json) : [];

    for (const property of defaultReadingProperties) {
      this._entityManager.propertyRepository.create({
        ...property,
        ...this._getDefaultReadingPropertyValueOverride(
          property.name,
          defaultValues
        ),
        ownerProcessTaskGroupId: measurePointReading.ownerProcessTaskGroupId,
        ownerProcessTaskId: measurePointReading.ownerProcessTaskId,
        ownerUserGroupId: measurePointReading.ownerUserGroupId,
        processTaskMeasurePointReadingId: measurePointReading.id
      });
    }
  }

  /**
   * @param {string} propertyName
   * @param {import('../../../common/src/Types/ProcessConfigurationMeasurePointType').TDefaultReadingPropertyValues} defaultValues
   * @returns {{ value?: string }}
   */
  _getDefaultReadingPropertyValueOverride(propertyName, defaultValues) {
    const item = defaultValues.find((i) => i.propertyName === propertyName);

    if (item) {
      return {
        value: item.defaultValue
      };
    }

    return {};
  }
}
