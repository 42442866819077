export function assertNotNullOrUndefined<T>(
  value: T | null | undefined | void,
  errorMessage: string
): asserts value is T {
  if (value === null || value === undefined) {
    throw new Error(errorMessage);
  }
}

export function assertType<T>(
  value: any,
  constructor: { new (...args: Array<any>): T },
  errorMessage: string
): asserts value is T {
  if (!(value instanceof constructor)) {
    throw new Error(errorMessage);
  }
}

export function assertPrimitiveType<
  TTypeName extends keyof PrimitiveTypeNameToType
>(
  value: any,
  typeName: TTypeName,
  errorMessage: string
): asserts value is PrimitiveTypeNameToType[TTypeName] {
  if (!(typeof value === typeName)) {
    throw new Error(errorMessage);
  }
}

type PrimitiveTypeNameToType = {
  string: string;
  number: number;
};
