import { autoinject, bindable, containerless } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';

import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { EditThingGroupDialog } from '../../dialogs/edit-thing-group-dialog/edit-thing-group-dialog';
import { EditThingDialog } from '../../dialogs/edit-thing-dialog/edit-thing-dialog';
import { AssignProcessTaskGroupUserDialog } from '../assign-process-task-group-user-dialog/assign-process-task-group-user-dialog';
import { EditThingMainContactBankAccountDataDialog } from '../../dialogs/edit-thing-main-contact-bank-account-data-dialog/edit-thing-main-contact-bank-account-data-dialog';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@containerless()
@autoinject()
export class ProcessTaskAppointmentGeneralWidget {
  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public thingGroup: ThingGroup | null = null;

  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public appointmentGeneralInfoEditing: boolean = false;

  @bindable()
  public expanded: boolean = false;

  @subscribableLifecycle()
  protected readonly processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private ThingGroupHelper = ThingGroupHelper;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    permissionsService: PermissionsService
  ) {
    this.processTaskGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskAppointmentGeneralWidget,
        propertyName: 'processTaskGroup'
      });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskAppointmentGeneralWidget,
        propertyName: 'processTask'
      });
  }

  private handleProcessTaskDescriptionChanged(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskAppointmentGeneralWidget._handleProcessTaskDescriptionChanged"
    );

    if (this.processTask) {
      void this.processTaskLoggingService.logProcessTaskDescriptionModified(
        this.processTask
      );
      this.entityManager.processTaskRepository.update(this.processTask);
    }
  }

  private handleEditThingGroupClick(): void {
    if (!this.thingGroup) {
      return;
    }

    void EditThingGroupDialog.open({
      thingGroup: this.thingGroup
    });
  }

  private handleEditThingClick(): void {
    if (!this.thing) {
      return;
    }

    void EditThingDialog.open({
      thing: this.thing,
      thingGroupSelectionEnabled: false
    });
  }

  private handleAssignUserClick(): void {
    if (!this.processTaskGroup || !this.processTask) {
      return;
    }

    void AssignProcessTaskGroupUserDialog.open({
      processTaskGroup: this.processTaskGroup,
      processTaskInfo: { processTask: this.processTask }
    });
  }

  private handleEditAssigneeHintClick(): void {
    if (!this.processTaskGroup || !this.processTaskGroup.assigneeUserId) {
      return;
    }

    void AssignProcessTaskGroupUserDialog.open({
      processTaskGroup: this.processTaskGroup,
      lockedUserId: this.processTaskGroup.assigneeUserId,
      defaultHint: this.processTaskGroup.assigneeHint || '',
      processTaskInfo: this.processTaskGroup.assignedProcessTaskName
        ? { processTaskName: this.processTaskGroup.assignedProcessTaskName }
        : null
    });
  }

  private handleEditBankAccountDataClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't ProcessTaskAppointmentGeneralWidget.handleEditBankAccountDataClick without a thing"
    );
    void EditThingMainContactBankAccountDataDialog.open({
      thingId: this.thing.id
    });
  }

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }
}
