import { autoinject } from 'aurelia-framework';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { MomentInput } from 'moment/moment';
import { DateUtils } from 'common/DateUtils';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { configureHooks } from '../../hooks/configureHooks';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { QuestionSetService } from '../../classes/EntityManager/entities/QuestionSet/QuestionSetService';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditQuestionCatalogueDialog {
  protected questionCatalogue: QuestionCatalogue | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly questionSetService: QuestionSetService
  ) {}

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: Options): void {
    this.questionCatalogue = options.questionCatalogue;

    this.dialog?.open();
  }

  @computed(expression('questionCatalogue'), model(EntityName.UserGroup))
  protected get ownerUserGroup(): UserGroup | null {
    if (!this.questionCatalogue) return null;
    return this.entityManager.userGroupRepository.getById(
      this.questionCatalogue.ownerUserGroupId
    );
  }

  @computed(model(EntityName.QuestionSet))
  protected get availableQuestionSets(): Array<QuestionSet> {
    return this.entityManager.questionSetRepository.getAll();
  }

  @computed(
    expression('questionCatalogue'),
    model(EntityName.QuestionSet),
    model(EntityName.QuestionCatalogueToQuestionSet)
  )
  protected get relatedQuestionSets(): Array<QuestionSet> {
    if (!this.questionCatalogue) return [];

    return this.questionSetService.getByQuestionCatalogueId(
      this.questionCatalogue.id
    );
  }

  protected isRelatedQuestionSet(
    relatedQuestionSets: Array<QuestionSet>,
    set: QuestionSet
  ): boolean {
    return relatedQuestionSets.includes(set);
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  /** Needed for unmounting hooks */
  protected handleDialogClosed(): void {}

  protected handleQuestionCatalogueChanged(): void {
    assertNotNullOrUndefined(
      this.questionCatalogue,
      'cannot handleQuestionCatalogueChanged without questionCatalogue'
    );

    this.entityManager.questionCatalogueRepository.update(
      this.questionCatalogue
    );
  }

  protected handleRelatedQuestionSetCheckedChanged(
    questionSet: QuestionSet,
    evt: CustomCheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.questionCatalogue,
      'cannot handleRelatedQuestionSetCheckedChanged without questionCatalogue'
    );

    if (evt.detail.checked) {
      this.entityManager.questionCatalogueToQuestionSetRepository.getOrCreate({
        questionSetId: questionSet.id,
        questionCatalogueId: this.questionCatalogue.id,
        ownerUserGroupId: this.questionCatalogue.ownerUserGroupId
      });
    } else {
      const relation =
        this.entityManager.questionCatalogueToQuestionSetRepository.getRelation(
          this.questionCatalogue.id,
          questionSet.id
        );
      if (relation) {
        this.entityManager.questionCatalogueToQuestionSetRepository.delete(
          relation
        );
      }
    }
  }
}

type Options = {
  questionCatalogue: QuestionCatalogue;
};
export type EditQuestionCatalogueDialogOptions = Options;
