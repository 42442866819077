import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SelectCoordinatesOnMapDialog } from '../../dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog';
import { BasemapMap } from '../../map/basemap-map/basemap-map';
import { FloatingLabelInput } from '../floating-label-input/floating-label-input';

/**
 * Component for changing coordinates. Coords can be inputted manually or by selecting a point on a map.
 *
 * @attribute data-inline - shows latitude/longitude inputs on one line
 *
 * @event value-changed fired when either latitude or longitude change
 *
 * @techdebt latitudeInputText/longitudeInputText is typed as a number but sometimes in the code/the view it is used as a string. I suspect it sometimes is a string and sometimes a number. latitude/longitude can probably sometimes be a string as well
 */
@autoinject()
export class CoordinateInput {
  @bindable public enabled = true;

  @bindable public latitude: number | null = null;

  @bindable public longitude: number | null = null;

  @bindable public onMapReady: ((map: BasemapMap) => void) | null = null;

  @bindable public showMapIconOnly = false;

  private latitudeInputText: number | null = null;

  private longitudeInputText: number | null = null;

  private domElement: HTMLElement;

  private latitudeInputViewModel: FloatingLabelInput | null = null;

  private currentlyEditing = false;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected toggleEditText(): void {
    if (!this.currentlyEditing && this.enabled) {
      this.currentlyEditing = true;
      setTimeout(() => {
        this.latitudeInputViewModel && this.latitudeInputViewModel.focus();
      }, 0);
    } else if (this.currentlyEditing) {
      this.stopTextEditable();
    }
  }

  private stopTextEditable(): void {
    this.currentlyEditing = false;
    if (typeof this.latitudeInputText == 'string')
      this.latitudeInputText = (this.latitudeInputText as any).trim();
    if (typeof this.longitudeInputText == 'string')
      this.longitudeInputText = (this.longitudeInputText as any).trim();
    if (
      this.latitude !== this.latitudeInputText ||
      this.longitude !== this.longitudeInputText
    ) {
      this.latitude = this.latitudeInputText;
      this.longitude = this.longitudeInputText;
      this.fireChangedEvent();
    }
  }

  protected latitudeChanged(): void {
    this.latitudeInputText = this.latitude;
  }

  protected longitudeChanged(): void {
    this.longitudeInputText = this.longitude;
  }

  protected handleSelectCoordinateOnMapClick(): void {
    void SelectCoordinatesOnMapDialog.open({
      lat: this.latitude,
      long: this.longitude,
      onCoordinateSelected: (lat, long) => {
        const oldLat = this.latitude;
        const oldLong = this.longitude;
        this.latitude = lat;
        this.longitude = long;

        this.currentlyEditing = false;

        if (oldLat !== this.latitude || oldLong !== this.longitude) {
          this.fireChangedEvent();
        }
      },
      onMapReady: (map) => {
        this.onMapReady && this.onMapReady(map);
      }
    });
  }

  private fireChangedEvent(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<ValueChangedEvent>(this.domElement, {
        name: 'value-changed',
        detail: {
          latitude: this.latitude,
          longitude: this.longitude
        }
      });
    });
  }
}

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  {
    latitude: number | null;
    longitude: number | null;
  }
>;
