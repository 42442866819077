import { computedFrom } from 'aurelia-framework';
import {
  DeviceDefaultPropertyConfig,
  ProcessConfigurationDevicePropertiesConfiguration
} from 'common/Types/ProcessConfigurationDevicePropertiesConfiguration';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskDeviceProperty } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { ProcessTaskLoggingService } from '../../../services/ProcessTaskLoggingService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  CreationConfig,
  PropertyAdapter,
  SubscribeOptions
} from './PropertyAdapter';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';

export class ProcessTaskDevicePropertyAdapter
  implements
    PropertyAdapter<ProcessTaskDeviceProperty, DeviceDefaultPropertyConfig>
{
  private readonly device: ProcessTaskDevice;
  private readonly entityManager: AppEntityManager;
  private readonly permissionsService: PermissionsService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly processTaskLoggingService: ProcessTaskLoggingService;

  constructor(options: {
    device: ProcessTaskDevice;
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    processTaskLoggingService: ProcessTaskLoggingService;
    permissionsService: PermissionsService;
  }) {
    this.device = options.device;
    this.entityManager = options.entityManager;
    this.permissionsService = options.permissionsService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.processTaskLoggingService = options.processTaskLoggingService;
  }

  public subscribe(
    options: SubscribeOptions<
      ProcessTaskDeviceProperty,
      DeviceDefaultPropertyConfig
    >
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(
        this.entityManager.propertyRepository.getByProcessTaskDeviceId(
          this.device.id
        )
      );
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );
    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateConfigs.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateConfigs.bind(this)
    );
    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.ProcessTaskDevice,
      entity: this.device,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public createPropertyFromConfig(
    config: CreationConfig<DeviceDefaultPropertyConfig>
  ): ProcessTaskDeviceProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      processTaskDeviceId: this.device.id,
      ownerUserGroupId: this.device.ownerUserGroupId,
      ownerProcessTaskGroupId: this.device.ownerProcessTaskGroupId,
      ownerProcessTaskId: this.device.ownerProcessTaskId
    }) as ProcessTaskDeviceProperty;
  }

  public afterPropertyModified(): void {
    this.processTaskLoggingService.logProcessTaskSubEntityModified({
      entityName: EntityName.ProcessTaskDevice,
      entity: this.device,
      property: null,
      displayNameAtLogTime: null
    });
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }

  private getConfigs(): Array<DeviceDefaultPropertyConfig> {
    const processTaskGroup = this.device
      ? this.entityManager.processTaskGroupRepository.getById(
          this.device.ownerProcessTaskGroupId
        )
      : null;
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const config: ProcessConfigurationDevicePropertiesConfiguration | null =
      processConfiguration &&
      processConfiguration.devicePropertiesConfigurationJson
        ? JSON.parse(processConfiguration.devicePropertiesConfigurationJson)
        : null;
    return config ? config.defaultProperties : [];
  }
}
