import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationDeviceDto } from './ProcessConfigurationDeviceDto';

export function createProcessConfigurationDeviceEntityInfo<
  TEntity extends ProcessConfigurationDeviceDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationDeviceDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfigurationDevice,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfigurationDevice,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationDeviceDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        ownerProcessConfigurationId: {
          name: 'ownerProcessConfigurationId',
          ref: EntityName.ProcessConfigurationDevice,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        dailyCost: {
          name: 'dailyCost',
          defaultValue: null
        },
        plannedDuration: {
          name: 'plannedDuration',
          defaultValue: null
        },
        discount: {
          name: 'discount',
          defaultValue: 0
        },
        discountNote: {
          name: 'discountNote',
          defaultValue: null
        },
        billingMethod: {
          name: 'billingMethod'
        }
      },
      options.additionalFieldInfos
    )
  };
}
