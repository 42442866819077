import { computedFrom } from 'aurelia-framework';

import { MapLayer } from '../../../classes/EntityManager/entities/MapLayer/types';
import { MapLayerAdapter } from '../EntityAdapter/MapLayerAdapter/MapLayerAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class MapLayerPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<MapLayerAdapter>,
  MapLayer
> {
  @computedFrom('combinedRevision')
  public get canEditGeneralFiles(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditGeneralFiles');
  }
}
