import { computedFrom } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  PropertyDefinitionWidgetHandle,
  PropertyFeatures
} from '../PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { UserDefinedEntityConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfig/types';

import {
  createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration,
  UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration
} from '../../../base-property-widget/config/userDefinedEntityConfigPropertyDefinitionConfiguration/createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration';
import { UserDefinedEntityConfigPropertyConfig } from '../../../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { UserDefinedEntityConfigPropertyDefinitionAdditionalFields } from '../../../property-definition-widget-additional-fields/UserDefinedEntityConfigPropertyDefinitionAdditionalFields/user-defined-entity-config-property-definition-additional-fields';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { RxjsService } from '../../../../services/RxjsService/RxjsService';

export class UserDefinedEntityConfigPropertyDefinitionHandle
  implements
    PropertyDefinitionWidgetHandle<UserDefinedEntityConfigPropertyConfig>
{
  public readonly property: UserDefinedEntityConfigPropertyConfig;
  private readonly entityManager: AppEntityManager;
  private readonly rxjsService: RxjsService;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.UserDefinedEntityConfig];

  public readonly additionalFieldsComponentClass =
    UserDefinedEntityConfigPropertyDefinitionAdditionalFields;

  constructor(options: {
    entityManager: AppEntityManager;
    rxjsService: RxjsService;
    permissionsService: PermissionsService;
    property: UserDefinedEntityConfigPropertyConfig;
    userDefinedEntityConfig: UserDefinedEntityConfig;
  }) {
    this.entityManager = options.entityManager;
    this.rxjsService = options.rxjsService;
    this.property = options.property;

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.UserDefinedEntityConfig,
        entity: options.userDefinedEntityConfig
      });
  }

  public subscribe(): Disposable {
    return this.permissionsHandle.subscribe();
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  public setName(name: string | null): void {
    this.property.name = name;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  public setType(type: PropertyType): void {
    this.property.type = type;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices ?? [];
  }

  public setChoices(choices: Array<string>): void {
    this.property.choices = choices;
    this.entityManager.userDefinedEntityConfigPropertyConfigRepository.update(
      this.property
    );
  }

  @computedFrom('permissionsHandle.canEditProperties')
  public get canEditName(): boolean {
    return this.permissionsHandle.canEditProperties;
  }

  @computedFrom('permissionsHandle.canEditProperties')
  public get canEditType(): boolean {
    return this.permissionsHandle.canEditProperties;
  }

  @computedFrom('permissionsHandle.canEditProperties')
  public get canEditChoices(): boolean {
    return this.permissionsHandle.canEditProperties;
  }

  @computedFrom('permissionsHandle.canEditProperties')
  public get canBeDeleted(): boolean {
    return this.permissionsHandle.canEditProperties;
  }

  public getPropertyDefinition(): UserDefinedEntityConfigPropertyConfig {
    return this.property;
  }

  public getPropertyFeatures(): PropertyFeatures {
    return {
      canHavePositions: false,
      canHaveProjectParameter: false,
      canHaveTableEntries: false,
      allowDefaultValues: false
    };
  }

  public createPropertyWidgetConfiguration(): UserDefinedEntityConfigPropertyDefinitionWidgetConfiguration | null {
    return createUserDefinedEntityConfigPropertyDefinitionWidgetConfiguration({
      enabled$: this.rxjsService.fromProperty({
        context: this.permissionsHandle.canEditField,
        propertyName: 'name'
      })
    });
  }
}
