import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { QuestionCatalogueToThingDto } from './QuestionCatalogueToThingDto';

export function createQuestionCatalogueToThingEntityInfo<
  TEntity extends QuestionCatalogueToThingDto<string, string>
>(
  options: CreateEntityInfoOptions<
    QuestionCatalogueToThingDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.QuestionCatalogueToThing,
  TEntity
> {
  return {
    entityName: EntityName.QuestionCatalogueToThing,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      QuestionCatalogueToThingDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        questionCatalogueId: {
          name: 'questionCatalogueId',
          ref: EntityName.QuestionCatalogue,
          cascadeDeleteLocally: true
        },
        thingId: {
          name: 'thingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true
        }
      },
      options.additionalFieldInfos
    )
  };
}
