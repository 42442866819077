import { ProcessConfigurationActionStatus } from './types';

export class ProcessConfigurationActionStatusUtils {
  public static getFullDisplayNameForProcessConfigurationActionStatus(
    processConfigurationActionStatus: ProcessConfigurationActionStatus
  ): string {
    return this.getFullDisplayName(
      processConfigurationActionStatus.abbreviation,
      processConfigurationActionStatus.name
    );
  }

  public static getFullDisplayName(
    abbreviation: string | null,
    name: string | null
  ): string {
    const parts = [];

    if (abbreviation) {
      parts.push(abbreviation);
    }

    if (name) {
      parts.push(name);
    }

    return parts.join(' - ');
  }
}
