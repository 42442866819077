import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import {
  ImportFromCsvFileDialog,
  CallbackParamsWithoutUserGroup
} from '../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import {
  FieldInfosFromConfig,
  FieldType,
  ParsedLineData
} from '../aureliaComponents/csv-import-widget/csv-import-widget';
import { Dialogs } from '../classes/Dialogs';
import {
  Defect,
  DefectCreationEntity
} from '../classes/EntityManager/entities/Defect/types';
import { Thing } from '../classes/EntityManager/entities/Thing/types';
import { NotificationHelper } from '../classes/NotificationHelper';
import { DefectCreationService } from '../classes/EntityManager/entities/Defect/DefectCreationService';

@autoinject()
export class DefectCsvImporterService {
  private config: ImportConfig;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly defectCreationService: DefectCreationService
  ) {
    this.config = {
      createNullValueProperties: false,
      predefinedProperties: []
    };
  }

  private csvImportFields: FieldInfosFromConfig<FieldInfoConfiguration> = [
    { field: 'sequenceNumber', header: 'Id', type: FieldType.NUMBER },
    { field: 'name', header: 'Titel', type: FieldType.STRING },
    { field: 'description', header: 'Beschreibung', type: FieldType.STRING },
    { field: 'status', header: 'Status', type: FieldType.STRING },
    { field: 'dueAt', header: 'Fällig bis', type: FieldType.STRING },
    {
      field: 'dbInfos',
      header: '$$dbInfos',
      type: FieldType.STRING,
      extraField: true,
      ignoreField: false
    },
    {
      field: 'longitude',
      header: 'Longitude',
      type: FieldType.NUMBER,
      extraField: true,
      ignoreField: true
    },
    {
      field: 'latitude',
      header: 'Latitude',
      type: FieldType.NUMBER,
      extraField: true,
      ignoreField: true
    },
    {
      field: 'assignedPerson',
      header: 'Bearbeiter',
      type: FieldType.STRING,
      extraField: true,
      ignoreField: true
    }
  ];

  public async importDefectsFromCsvFile(
    file: File,
    thing: Thing
  ): Promise<void> {
    await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
      file: file,
      fields: this.csvImportFields,
      importFromCsvFileCallback: async (detail) => {
        try {
          this.handleImportDefectsFromCsvFileSubmitted(detail, thing);
        } catch (error) {
          void Dialogs.errorDialogTk('galleryThing.defectImport.importFailed');
          throw error;
        }
        NotificationHelper.notifySuccess(
          this.i18n.tr('galleryThing.defectImport.importSuccess')
        );
      },
      showUserGroupSelect: false,
      showAdditionalFieldsAsParametersCheckbox: false,
      showOverwriteCheckbox: false,
      entityNameTk: 'models.DefectModel_plural',
      additionalImportOptions: {
        createNullValueProperties: {
          labelTk:
            'dialogs.importFromCsvFileDialog.createNullValuePropertiesLabel'
        }
      }
    });
  }

  private handleImportDefectsFromCsvFileSubmitted(
    detail: CallbackParamsWithoutUserGroup<FieldInfoConfiguration>,
    thing: Thing
  ): void {
    const lineData = detail.parsedContent;
    this.config.createNullValueProperties =
      detail.additionalImportOptions?.createNullValueProperties ?? false;
    this.config.predefinedProperties =
      this.defectCreationService.getPredefinedDefectProperties();
    for (const line of lineData) {
      this.createDefect(line, thing);
    }
  }

  private createDefect(
    lD: ParsedLineData<FieldInfoConfiguration>,
    thing: Thing
  ): void {
    const newDefectCreationEntity: DefectCreationEntity = {
      ownerThingId: thing.id,
      ownerUserGroupId: thing.ownerUserGroupId
    };
    this.applyValuesToNewDefect(newDefectCreationEntity, lD.fields);

    const defect = this.defectCreationService.createDefectWithoutProperties(
      newDefectCreationEntity
    );

    this.createExtraFieldsAsInvisibleProperty(defect, lD.fields);
    this.createDefectProperties(lD.additionalFields, defect);
  }

  private createExtraFieldsAsInvisibleProperty(
    defect: Defect,
    fields: ParsedLineData<FieldInfoConfiguration>['fields']
  ): void {
    for (const fieldKey in fields) {
      const fieldInfo = this.csvImportFields.find(
        (fInfo) => fInfo.field === fieldKey
      );
      const value = String(fields[fieldKey]);
      if (
        !fieldInfo ||
        !value ||
        !fieldInfo.extraField ||
        fieldInfo.ignoreField
      )
        continue;

      this.entityManager.propertyRepository.create({
        ownerUserGroupId: defect.ownerUserGroupId,
        defect: defect.id,
        ownerDefectId: defect.id,
        active: false,
        alwaysVisible: false,
        value: value,
        name: fieldInfo.header
      });
    }
  }

  private applyValuesToNewDefect(
    newDefect: DefectCreationEntity,
    fields: ParsedLineData<FieldInfoConfiguration>['fields']
  ): void {
    for (const fieldKey in fields) {
      const fieldInfo = this.csvImportFields.find(
        (fInfo) => fInfo.field === fieldKey
      );
      if (!fieldInfo || fieldInfo.extraField) continue;

      const newField = {
        [fieldInfo.field]: fields[fieldKey]
      };
      Object.assign(newDefect, newField);
    }
  }

  private createDefectProperties(
    additionalFields: Record<string, string>,
    defect: Defect
  ): void {
    for (const additionalField in additionalFields) {
      const importedValue = additionalFields[additionalField]?.trim() ?? '';
      this.createNewDefectProperty(importedValue, defect, additionalField);
    }
  }

  private createNewDefectProperty(
    value: string,
    defect: Defect,
    propertyName: string
  ): void {
    if (!value && !this.config.createNullValueProperties) return;
    const existingPredefinedProperty = this.config.predefinedProperties.find(
      (p) => p.name === propertyName
    );

    const baseData = {
      ownerUserGroupId: defect.ownerUserGroupId,
      defect: defect.id,
      ownerDefectId: defect.id,
      active: true,
      alwaysVisible: true,
      value: value
    };

    if (existingPredefinedProperty) {
      this.entityManager.propertyRepository.create({
        ...existingPredefinedProperty,
        ...baseData
      });
    } else {
      const type =
        !Number.isNaN(Number(value.replace(',', '.'))) &&
        !StringUtils.isNullOrEmpty(value)
          ? PropertyType.NUMBER
          : PropertyType.TEXT;
      this.entityManager.propertyRepository.create({
        name: propertyName,
        ...baseData,
        type: type
      });
    }
  }
}

type ImportConfig = {
  createNullValueProperties: boolean;
  predefinedProperties: Array<TDefaultPropertyConfig>;
};

type FieldInfoConfiguration = {
  entityType: Defect;
  fieldInfos: [
    {
      field: 'sequenceNumber';
      type: FieldType.NUMBER;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'description';
      type: FieldType.STRING;
    },
    {
      field: 'status';
      type: FieldType.STRING;
    },
    {
      field: 'dueAt';
      type: FieldType.STRING;
    },
    {
      field: 'dbInfos';
      type: FieldType.STRING;
    },
    {
      field: 'longitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'latitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'assignedPerson';
      type: FieldType.STRING;
    }
  ];
};
