import { computedFrom } from 'aurelia-framework';

import { ValueCalculationConfigColorConfig } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { ThingTypeProjectValueCalculationConfig } from '../../../../classes/EntityManager/entities/ValueCalculationConfig/types';
import {
  ValueCalculationConfigDefinitionWidgetHandle,
  ValueCalculationConfigFeatures
} from '../ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';

export class ThingTypeProjectValueCalculationConfigDefinitionHandle
  implements
    ValueCalculationConfigDefinitionWidgetHandle<ThingTypeProjectValueCalculationConfig>
{
  private readonly entityManager: AppEntityManager;
  private readonly valueCalculationConfig: ThingTypeProjectValueCalculationConfig;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingType];

  constructor(options: {
    entityManager: AppEntityManager;
    permissionsService: PermissionsService;
    valueCalculationConfig: ThingTypeProjectValueCalculationConfig;
  }) {
    this.entityManager = options.entityManager;
    this.valueCalculationConfig = options.valueCalculationConfig;

    const thingType = this.entityManager.thingTypeRepository.getById(
      this.valueCalculationConfig.thingTypeId
    );
    assertNotNullOrUndefined(
      thingType,
      'cannot create ThingTypeThingValueCalculationDefinitionHandle without thingType'
    );

    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.ThingType,
        entity: thingType
      });
  }

  public subscribe(): Disposable {
    return this.permissionsHandle.subscribe();
  }

  @computedFrom('valueCalculationConfig.name')
  public get name(): string {
    return this.valueCalculationConfig.name;
  }

  public setName(name: string): void {
    this.valueCalculationConfig.name = name;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('valueCalculationConfig.calculationExpression')
  public get calculationExpression(): string {
    return this.valueCalculationConfig.calculationExpression;
  }

  public setCalculationExpression(calculationExpression: string): void {
    this.valueCalculationConfig.calculationExpression = calculationExpression;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('valueCalculationConfig.colorCodeConfig')
  public get colorCodeConfig(): ValueCalculationConfigColorConfig | null {
    return this.valueCalculationConfig.colorCodeConfig;
  }

  public setColorCodeConfig(
    colorCodeConfig: ValueCalculationConfigColorConfig | null
  ): void {
    this.valueCalculationConfig.colorCodeConfig = colorCodeConfig;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('valueCalculationConfig.showInEntityOverview')
  public get showInEntityOverview(): boolean {
    return this.valueCalculationConfig.showInEntityOverview;
  }

  public setShowInEntityOverview(showInEntityOverview: boolean): void {
    this.valueCalculationConfig.showInEntityOverview = showInEntityOverview;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  public get reportTypeId(): string | null {
    return this.valueCalculationConfig.reportTypeId;
  }

  public setReportTypeId(reportTypeId: string | null): void {
    this.valueCalculationConfig.reportTypeId = reportTypeId;
    this.entityManager.valueCalculationConfigRepository.update(
      this.valueCalculationConfig
    );
  }

  @computedFrom('permissionsHandle.canAdministerValueCalculations')
  public get canEditFields(): boolean {
    return this.permissionsHandle.canAdministerValueCalculations;
  }

  @computedFrom('permissionsHandle.canAdministerValueCalculations')
  public get canBeDeleted(): boolean {
    return this.permissionsHandle.canAdministerValueCalculations;
  }

  public getValueCalculationDefinition(): ThingTypeProjectValueCalculationConfig {
    return this.valueCalculationConfig;
  }

  public getFeatures(): ValueCalculationConfigFeatures {
    return {
      showReportTypeSelection: true
    };
  }
}
