import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from 'common/Types/BaseEntities/EntityName';

import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';

import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';
import {
  AdditionalMarkingsByPictureId,
  PictureMarkingService
} from '../../classes/EntityManager/entities/Picture/PictureMarkingService';
import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ContentClickedEvent } from '../../aureliaComponents/zoom-box/zoom-box';

@autoinject()
export class PictureRevisionComparisonPicture {
  @bindable protected picture: Picture | null = null;
  @bindable protected pictureRevision: PictureRevision | null = null;

  private readonly zoomBoxElement: HTMLElement | null = null;
  private pictureElement: HTMLElement | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureMarkingService: PictureMarkingService
  ) {}

  protected attached(): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't PictureFullScreenOverlay.handleContentAttached without pictureElementToCompareAndEdit"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't PictureFullScreenOverlay.handleContentAttached without zoomBoxElementToCompareAndEdit"
    );
    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();
  }

  protected detached(): void {
    if (this.pictureResizer) {
      this.pictureResizer.destroy();
      this.pictureResizer = null;
    }
  }

  // TODO: will be implemented in follow-up PR
  protected handleZoomBoxContentClicked(event: ContentClickedEvent): void {
    console.log(event.detail);
  }

  @computed(
    expression('picture'),
    model(EntityName.Project),
    model(EntityName.Picture)
  )
  protected get markings(): Array<IPictureMarking> {
    if (!this.picture) return [];
    const projects = this.entityManager.projectRepository.getByThingId(
      this.picture.globalThingId ?? ''
    );

    let projectPictureMarkings: AdditionalMarkingsByPictureId = new Map();

    for (const project of projects) {
      const additionalMarkingsByPictureId =
        this.pictureMarkingService.getAdditionalMarkingsByPictureIdForProjectId(
          project.id
        );

      projectPictureMarkings = new Map([
        ...projectPictureMarkings.entries(),
        ...additionalMarkingsByPictureId.entries()
      ]);
    }

    const additionalMarkingsWithSourcePicture =
      projectPictureMarkings.get(this.picture?.id) ?? [];

    return additionalMarkingsWithSourcePicture.map((marking) => {
      return {
        top: marking.top,
        left: marking.left,
        boxTop: marking.boxTop,
        boxBottom: marking.boxBottom,
        boxLeft: marking.boxLeft,
        boxRight: marking.boxRight
      };
    });
  }
}
