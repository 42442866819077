import { computedFrom } from 'aurelia-binding';
import {
  CustomPositionTypeConfiguration,
  ProcessConfigurationCustomPositionTypeConfiguration
} from 'common/Types/ProcessConfigurationCustomPositionTypeConfiguration';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

export class CustomTypeHandle {
  private readonly computedValueService: ComputedValueService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private customTypesConfig: ProcessConfigurationCustomPositionTypeConfiguration | null =
    null;
  private position: ProcessTaskPosition | null = null;
  private internalTypeConfig: CustomPositionTypeConfiguration<string> | null =
    null;

  constructor(options: CustomTypeHandleOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public setPosition(position: ProcessTaskPosition | null): void {
    this.position = position;
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToExpression(
      this,
      'position.customType',
      this.updateTypeConfig.bind(this)
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.position
            ? { processTaskGroupId: this.position.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (updateSubscription) => {
          subscriptionManager.subscribeToExpression(
            this,
            'position.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (customTypesConfig) => {
          this.customTypesConfig = customTypesConfig;
          this.updateTypeConfig();
        }
      })
    );
    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  @computedFrom('internalTypeConfig')
  public get typeConfig(): CustomPositionTypeConfiguration<string> | null {
    return this.internalTypeConfig;
  }

  private updateTypeConfig(): void {
    if (this.customTypesConfig && this.position?.customType) {
      this.internalTypeConfig =
        this.customTypesConfig.typesMap[this.position.customType] ?? null;
    } else {
      this.internalTypeConfig = null;
    }
  }
}

export type CustomTypeHandleOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
