import { UserPermissions } from 'common/Types/Entities/User/UserDto';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { User } from './types';

export class UserRepository extends AppEntityRepository<EntityName.User> {
  public getByEmail(email: string): User | null {
    const lowerCaseEmail = email.toLocaleLowerCase();
    return (
      this.getAll().find((user) => {
        return (
          !!user.email && user.email.toLocaleLowerCase() === lowerCaseEmail
        );
      }) ?? null
    );
  }

  public setUserPermission<
    TPermissionName extends keyof UserPermissions<string>
  >(
    user: User,
    permissionName: TPermissionName,
    value: UserPermissions<string>[TPermissionName]
  ): void {
    if (!user.permissions) {
      user.permissions = this.createDefaultPermissions();
    }

    const oldValue = user.permissions[permissionName];
    if (oldValue !== value) {
      user.permissions[permissionName] = value;
      this.update(user);
    }
  }

  public createDefaultPermissions(): UserPermissions<string> {
    return {
      canCreateGroups: false,
      canAdministerUsers: false,
      canImpersonateUsers: false,
      canImportBlowerdoorXmlFiles: false,
      canUseBasic: null,
      canUseVia: null,
      canUseViaWildbach: false,
      canUseDefectManagement: false,
      canUseB1300: null,
      canUseInspect: null,
      canUseOperations: null,
      canUseOperationsFieldUse: null,
      canUsePersonExtension: null,
      canUseRegionExtension: null,
      canUseMapLayers: false,
      canUseStructureTemplates: false,
      canAssignNfcTagIds: false,
      canAdministerUserDefinedEntities: false,
      canAdministerValueCalculations: false,
      canViewValueCalculationResults: false,
      canUseKuK: null
    };
  }
}
