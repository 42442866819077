import { Disposable } from '../Utils/DisposableContainer';

export class PropertyBinder<
  TTypeConfiguration extends
    PropertyBinderTypeConfiguration = PropertyBinderTypeConfiguration
> {
  private callbacksByName: CallbacksByName<TTypeConfiguration> = {};
  private readonly valuesByName: ValuesByName<TTypeConfiguration>;

  constructor(options?: PropertyBinderOptions<TTypeConfiguration>) {
    this.valuesByName = options?.defaultValuesByName ?? {};
  }

  public setValue<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName,
    value: PropertyBinderValue<TTypeConfiguration, TName>
  ): void {
    const oldValue = this.getValue(name);
    if (value !== oldValue) {
      this.valuesByName[name] = value;
      this.callCallbacks(name, value);
    }
  }

  public getValue<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName
  ): PropertyBinderValue<TTypeConfiguration, TName> | undefined {
    return this.valuesByName[name];
  }

  public getRequiredValue<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName
  ): PropertyBinderValue<TTypeConfiguration, TName> {
    if (!(name in this.valuesByName)) {
      throw new Error(`no value for "${String(name)}" available`);
    }

    return this.valuesByName[name] as PropertyBinderValue<
      TTypeConfiguration,
      TName
    >;
  }

  public registerBinding<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName,
    callback: PropertyBinderCallback<TTypeConfiguration, TName>
  ): Disposable {
    const callbacks = this.getCallbacks(name);
    callbacks.add(callback);

    if (this.valuesByName.hasOwnProperty(name)) {
      callback(
        this.valuesByName[name] as PropertyBinderValue<
          TTypeConfiguration,
          TName
        >
      );
    }

    return {
      dispose: () => {
        this.removeCallback(name, callback);
      }
    };
  }

  public unregisterAllBindings(): void {
    this.callbacksByName = {};
  }

  private callCallbacks<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName,
    value: PropertyBinderValue<TTypeConfiguration, TName>
  ): void {
    const callbacks = this.getCallbacks(name);
    for (const callback of callbacks.values()) {
      callback(value);
    }
  }

  private removeCallback<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName,
    callback: PropertyBinderCallback<TTypeConfiguration, TName>
  ): void {
    const callbacks = this.getCallbacks(name);
    callbacks.delete(callback);
  }

  private getCallbacks<TName extends PropertyBinderName<TTypeConfiguration>>(
    name: TName
  ): Set<PropertyBinderCallback<TTypeConfiguration, TName>> {
    let callbacks = this.callbacksByName[name];

    if (!callbacks) {
      callbacks = this.callbacksByName[name] = new Set();
    }

    // typescript thinks `callbacks` can still be undefined here, but it can't
    return callbacks as Set<PropertyBinderCallback<TTypeConfiguration, TName>>;
  }
}

export type PropertyBinderOptions<
  TTypeConfiguration extends PropertyBinderTypeConfiguration
> = {
  defaultValuesByName?: ValuesByName<TTypeConfiguration>;
};

export type PropertyBinderTypeConfiguration = Record<string, unknown>;
export type PropertyBinderName<
  TTypeConfiguration extends PropertyBinderTypeConfiguration
> = keyof TTypeConfiguration;
export type PropertyBinderCallback<
  TTypeConfiguration extends PropertyBinderTypeConfiguration,
  TName extends keyof TTypeConfiguration
> = (value: PropertyBinderValue<TTypeConfiguration, TName>) => void;
export type PropertyBinderValue<
  TTypeConfiguration extends PropertyBinderTypeConfiguration,
  TName extends keyof TTypeConfiguration
> = TTypeConfiguration[TName];

type CallbacksByName<
  TTypeConfiguration extends PropertyBinderTypeConfiguration
> = Partial<{
  [TName in PropertyBinderName<TTypeConfiguration>]: Set<
    PropertyBinderCallback<TTypeConfiguration, TName>
  >;
}>;

type ValuesByName<TTypeConfiguration extends PropertyBinderTypeConfiguration> =
  Partial<{
    [TName in PropertyBinderName<TTypeConfiguration>]: PropertyBinderValue<
      TTypeConfiguration,
      TName
    >;
  }>;
