import { bindable, inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(AppEntityManager, SubscriptionManagerService)
export class EntryPicturePreview {
  /** @type {import('../../classes/EntityManager/entities/Entry/types').Entry|null} */
  @bindable entry = null;
  /** @type {boolean} */
  @bindable fullSize = false;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {boolean} */
  _attached = false;
  /** @type {import('../../classes/EntityManager/entities/Picture/types').Picture|null} */
  _picture = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this._updatePicture.bind(this)
    );
    this._updatePicture();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  entryChanged() {
    if (this._attached) {
      this._updatePicture();
    }
  }

  _updatePicture() {
    if (this.entry) {
      const pictures = this._entityManager.pictureRepository.getByEntryId(
        this.entry.id
      );

      let picture = pictures.find((p) => p.selected);
      if (!picture) {
        picture = pictures[0];
      }

      this._picture = picture;
    } else {
      this._picture = null;
    }
  }
}
