import { autoinject } from 'aurelia-framework';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Utils } from '../classes/Utils/Utils';

/**
 * A custom attribute that uses the view model of an element and adds it to a set.
 */
@autoinject()
export class ViewModelSetCustomAttribute<T> {
  @bindable({ primaryProperty: true }) public set: Set<T> | null = null;

  constructor(private readonly element: Element) {}

  protected attached(): void {
    assertNotNullOrUndefined(
      this.set,
      'cannot add elements to non-existing view model set'
    );

    this.set.add(Utils.getRequiredViewModelOfElement<T>(this.element));
  }

  protected detached(): void {
    assertNotNullOrUndefined(
      this.set,
      'cannot remove elements from non-existing view model set'
    );
    const success = this.set.delete(
      Utils.getRequiredViewModelOfElement<T>(this.element)
    );
    if (!success)
      throw new Error(
        'tried to remove non-existing element from view model set'
      );
  }
}
