/**
 * Since aurelia (specifically the repeat.for) will rerender the dom when it detects a new object instance, it will be really unperformant to always use new instances.
 * Sadly aurelia doesn't provide a way to track data over multiple object instances (e.g. via an id)
 * To prevent aurelia from rerendering, we need to create a new array with existing instances where possible.
 */
export class InstancePreserver<T extends ItemConstraint> {
  public static createNewArray<TStatic extends ItemConstraint>({
    originalArray,
    newArray,
    getTrackingValue,
    valueMerger
  }: {
    originalArray: Array<TStatic>;
    newArray: Array<TStatic>;
    getTrackingValue: TrackingValueGetter<TStatic>;
    valueMerger?: ValueMerger<TStatic>;
  }): Array<TStatic> {
    const preserver = new InstancePreserver(
      originalArray,
      newArray,
      getTrackingValue,
      valueMerger
    );
    return preserver.createNewArray();
  }

  private constructor(
    private readonly originalArray: Array<T>,
    private readonly newArray: Array<T>,
    private readonly getTrackingValue: TrackingValueGetter<T>,
    private readonly valueMergerConfig: ValueMerger<T> | undefined
  ) {}

  public createNewArray(): Array<T> {
    const newItemWithOriginalItems = this.createNewItemWithOriginalItems();

    return newItemWithOriginalItems.map(({ newItem, originalItem }) => {
      return this.mergeItems(newItem, originalItem);
    });
  }

  private createNewItemWithOriginalItems(): Array<NewItemWithOriginalItem<T>> {
    const trackingValueToOriginalItemMap =
      this.createTrackingValueToOriginalItemMap();

    return this.newArray.map((newItem) => ({
      newItem,
      originalItem:
        trackingValueToOriginalItemMap.get(this.getTrackingValue(newItem)) ??
        null
    }));
  }

  private createTrackingValueToOriginalItemMap(): TrackingValueToItemMap<T> {
    const map: TrackingValueToItemMap<T> = new Map();

    for (const item of this.originalArray) {
      map.set(this.getTrackingValue(item), item);
    }

    return map;
  }

  private mergeItems(newItem: T, originalItem: T | null): T {
    if (!originalItem) {
      return newItem;
    }

    // remove properties which don't exist in the newItems
    for (const key in originalItem) {
      if (!Object.prototype.hasOwnProperty.call(originalItem, key)) {
        continue;
      }

      if (!Object.prototype.hasOwnProperty.call(newItem, key)) {
        (originalItem as any)[key] = undefined; // do not remove the key itself since it MAY have side effects with aurelias getters/setters and undefined should be good enough
      }
    }

    // shallow copy the newItem on top of the original one
    for (const key in newItem) {
      if (!Object.prototype.hasOwnProperty.call(newItem, key)) {
        continue;
      }

      if (this.valueMergerConfig?.[key]) {
        originalItem[key] = this.valueMergerConfig[key]({
          oldValue: originalItem[key],
          newValue: newItem[key]
        });
      } else {
        originalItem[key] = newItem[key];
      }
    }

    return originalItem;
  }
}

export type TrackingValueGetter<T extends ItemConstraint> = (
  item: T
) => unknown;

type ValueMerger<T extends ItemConstraint> = {
  [key in keyof Partial<T>]: (options: {
    oldValue: T[key];
    newValue: T[key];
  }) => T[key];
};

export type ItemConstraint = Record<string, unknown>;

type TrackingValueToItemMap<T extends ItemConstraint> = Map<unknown, T>;

type NewItemWithOriginalItem<T extends ItemConstraint> = {
  newItem: T;
  originalItem: T | null;
};
