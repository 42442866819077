import { autoinject, bindable, observable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { Dialogs } from '../../classes/Dialogs';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';

import { regionProperties as wildbachRegionProperties } from '../../data/Wildbach/regionProperties';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { Region } from '../../classes/EntityManager/entities/Region/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RegionProperty } from '../../classes/EntityManager/entities/Property/types';
import { ExpandableContainer } from '../../aureliaComponents/expandable-container/expandable-container';
import { Utils } from '../../classes/Utils/Utils';

@autoinject()
export class EditRegionsWidget {
  @bindable public enabled = false;
  @bindable public thing: Thing | null = null;
  private regions: Array<Region> = [];
  protected filteredRegions: Array<Region> = [];
  private canUseViaWildbach = false;
  @observable private regionFilterString: string;
  private subscriptionManager: SubscriptionManager;
  private permissionBindings: PermissionBindingHandle;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private entityManager: AppEntityManager
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindings = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseViaWildbach: 'canUseViaWildbach'
      }
    });

    this.regionFilterString = '';
  }

  protected attached(): void {
    this.permissionBindings.subscribe();
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Region,
      this.updateRegions.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateRegions.bind(this)
    );
    this.updateRegions();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindings.unsubscribe();
  }

  protected thingChanged(): void {
    this.updateRegions();
  }

  protected regionFilterStringChanged(): void {
    this.filterRegions();
  }

  private filterRegions(): void {
    this.filteredRegions = this.regions.filter((region) =>
      this.matchesRegionFilter(region.name)
    );
  }

  private matchesRegionFilter(name: string | null): boolean {
    return (
      name == null ||
      name === '' ||
      name
        .toLocaleUpperCase()
        .includes(this.regionFilterString.toLocaleUpperCase())
    );
  }

  protected handleAddRegionClick(): void {
    if (!this.thing) return;
    const region = this.entityManager.regionRepository.create({
      thingId: this.thing.id,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });

    if (this.canUseViaWildbach) {
      for (const property of wildbachRegionProperties) {
        this.entityManager.propertyRepository.create({
          ...property,
          regionId: region.id,
          ownerUserGroupId: region.ownerUserGroupId
        });
      }
    }

    this.updateRegions();
    setTimeout(() => {
      this.goToRegion(region);
    }, 10);
  }

  protected handleRegionChanged(region: Region): void {
    this.entityManager.regionRepository.update(region);
    this.updateRegions();
    this.goToRegion(region);
  }

  protected handleSyncRegionPropertiesClick(): void {
    if (!this.canUseViaWildbach) return;
    for (const region of this.regions) {
      const currentProperties = this.getRegionProperties(region);
      for (const property of wildbachRegionProperties) {
        if (!currentProperties.find((prop) => prop.name === property.name)) {
          this.entityManager.propertyRepository.create({
            ...property,
            regionId: region.id,
            ownerUserGroupId: region.ownerUserGroupId
          });
        }
      }
    }
  }

  private updateRegions(): void {
    if (!this.thing) {
      this.regions = [];
      return;
    }
    this.regions = this.entityManager.regionRepository.getByThingId(
      this.thing.id
    );
    this.filterRegions();
  }

  private getRegionProperties(region: Region): Array<RegionProperty> {
    return this.entityManager.propertyRepository
      .getByRegionId(region.id)
      .filter((prop) => !prop.hidden);
  }

  protected handleDeleteClick(region: Region): void {
    void Dialogs.deleteEntityDialog(region).then(() => {
      this.entityManager.regionRepository.delete(region);
    });
  }

  private goToRegion(region: Region): void {
    if (this.matchesRegionFilter(region.name)) {
      const element: HTMLElement = document.querySelector(
        '#' + this.getRegionElementId(region.id)
      ) as HTMLElement;
      if (element) void ScrollHelper.scrollToItem(element);
      this.expandRegion(region);
    }
  }

  private expandRegion(region: Region): void {
    const element: HTMLElement = document.querySelector(
      '#' + this.getExpandableContainerId(region.id)
    ) as HTMLElement;
    if (element)
      Utils.getViewModelOfElement<ExpandableContainer>(element)?.expand();
  }

  private getRegionElementId(regionId: string): string {
    return 'edit-regions-widget--region-' + regionId;
  }

  private getExpandableContainerId(regionId: string): string {
    return 'edit-regions-widget-expandable-container--region-' + regionId;
  }
}
