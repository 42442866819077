import { bindable, autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';

/**
 * @replaceable category-header-template
 * available variables in category-header-template
 * | type | name | description |
 * |------|------|-------------|
 * | TItemCategory | itemCategory | the current category |
 *
 * @replaceable item-template
 * available variables in item-template
 * | type | name | description |
 * |------|------|-------------|
 * | T | item | the item to display |
 * | TItemCategory | itemCategory | the current category |
 *
 * @replaceable category-footer-template
 * available variables in category-footer-template
 * | type | name | description |
 * |------|------|-------------|
 * | TItemCategory | itemCategory | the current category |
 */
@autoinject()
export class ProcessConfigurationCategoryGroups<T> {
  @bindable()
  public items: Array<T> = [];

  @bindable()
  public categoryIdGetter: CategoryIdGetter<T> | null = null;

  /**
   * Only needed for special cases, where the data is passed as an already grouped version so you can count the actual data instead of the groups of data
   */
  @bindable()
  public itemCountGetter: (items: Array<T>) => number = (items) => items.length;

  @bindable()
  public processConfigurationId: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private categorizedItems: Array<ItemCategory<T>> = [];
  private isAttached: boolean = false;
  private languageUpdateCounter: number = 0;

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateGroupedItems.bind(this)
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'items',
      this.updateGroupedItems.bind(this)
    );
    this.updateGroupedItems();

    this.subscriptionManager.subscribeToEvent(
      'i18n:locale:changed',
      () => this.languageUpdateCounter++
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationIdChanged(): void {
    if (this.isAttached) {
      this.updateGroupedItems();
    }
  }

  private updateGroupedItems(): void {
    if (this.categoryIdGetter && this.processConfigurationId) {
      const categorizedItems = this.categorizeItems(this.categoryIdGetter);
      this.categorizedItems = InstancePreserver.createNewArray({
        originalArray: this.categorizedItems,
        newArray: categorizedItems,
        getTrackingValue: (item) => item.category
      });
    } else {
      this.categorizedItems = [];
    }
  }

  private categorizeItems(
    categoryIdGetter: CategoryIdGetter<T>
  ): Array<ItemCategory<T>> {
    const itemCategories = this.getDefaultItemCategories();

    this.items.forEach((i) => {
      const categoryId = categoryIdGetter(i);
      let itemCategory = itemCategories.find(
        (c) => c.category && c.category.id === categoryId
      );

      if (!itemCategory) {
        itemCategory = itemCategories.find((c) => !c.category);
      }

      if (!itemCategory) {
        // create a fallback category
        itemCategory = {
          category: null,
          items: []
        };
        itemCategories.push(itemCategory);
      }

      itemCategory.items.push(i);
    });

    return itemCategories;
  }

  private getDefaultItemCategories(): Array<ItemCategory<T>> {
    if (!this.processConfigurationId) {
      return [];
    }

    const categories =
      this.entityManager.processConfigurationCategoryRepository.getSortedCategoriesByProcessConfigurationId(
        this.processConfigurationId
      );
    return categories.map((c) => {
      return {
        category: c,
        items: []
      };
    });
  }

  /**
   * @param category
   * @param categoryName
   * @param itemCount
   * @param _languageUpdateCounter - just here so the view updates correctly
   * @returns
   */
  protected getCategoryDisplayName(
    category: ProcessConfigurationCategory | null,
    categoryName: string | null,
    itemCount: number,
    _languageUpdateCounter: number
  ): string {
    if (category) {
      return categoryName + ` (${itemCount})`;
    } else {
      return (
        this.i18n.tr(
          'operations.processConfigurationCategoryGroups.defaultCategoryName'
        ) + ` (${itemCount})`
      );
    }
  }
}

export type CategoryIdGetter<T> = (item: T) => string | null;

type ItemCategory<T> = {
  /**
   * is null if the items don't belong to any category or the category couldn't be found
   */
  category: ProcessConfigurationCategory | null;
  items: Array<T>;
};
