import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskInvoiceToProcessTaskEntityInfo } from 'common/Types/Entities/ProcessTaskInvoiceToProcessTask/createProcessTaskInvoiceToProcessTaskEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskInvoiceToProcessTask } from './types';

export const processTaskInvoiceToProcessTaskEntityInfo =
  createProcessTaskInvoiceToProcessTaskEntityInfo<ProcessTaskInvoiceToProcessTask>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
