import _ from 'lodash';

export abstract class ValueComputer<
  TComputeData = unknown,
  TComputeResult = unknown
> {
  /**
   * called when initializing the ValueComputer
   *
   * Example:
   *    public abstract initializeEventListeners(invokeCompute: () => void): void {
   *      window.addEventListener('resize', invokeCompute); // recompute all values when the window resizes
   *    }
   *
   * @param invokeCompute - call this trigger a recomputation
   */
  public abstract initializeEventListeners(invokeCompute: () => void): void;

  public abstract removeEventListeners(): void;

  public abstract compute(
    data: TComputeData,
    options: ValueComputerComputeOptions<TComputeResult>
  ): TComputeResult;

  /**
   * checks if the provided values are equal
   * useful to prevent unnecessary computations, because sometimes simple equality isn't enough to check if both computeDatas mean the same thing
   *
   * e.g.
   *    const new = { foo: 'bar' };
   *    const old = { foo: 'bar' };
   *    if(new !== old) { // we maybe don't want to compute anything, because both values can lead to the same result
   *      this.expensiveComputation(new);
   *    }
   *
   * @param a
   * @param b
   */
  public computeDataAreEqual(a: TComputeData, b: TComputeData): boolean {
    return _.isEqual(a, b);
  }
}

export type ValueComputerComputeOptions<TComputeResult> = {
  lastResult: TComputeResult | undefined;
};
