import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { computed } from '../../hooks/computed';
import {
  entityActualizationHook,
  expression,
  joinedProjectsManagerHook
} from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';

@autoinject()
export class ProjectJoinedWrapper {
  @bindable public project: Project | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected isConnected: boolean = false;

  protected joiningProjectAndWaitingForActualization: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @watch(entityActualizationHook('afterActualization'))
  protected resetWaitingForActualizationStatus(): void {
    this.joiningProjectAndWaitingForActualization = false;
  }

  @computed(
    expression('project'),
    joinedProjectsManagerHook('onJoinedStatusChanged')
  )
  protected get projectIsJoined(): boolean {
    if (this.project) {
      return this.entityManager.joinedProjectsManager.projectIsJoined(
        this.project.id
      );
    }

    return false;
  }

  @computed(
    expression('project'),
    joinedProjectsManagerHook('onJoinedStatusChanged'),
    entityActualizationHook('afterActualization')
  )
  protected get projectWasActualizedAfterJoining(): boolean {
    if (this.project) {
      return this.entityManager.projectMetadataManager.projectWasActualizedAfterJoining(
        this.project.id
      );
    }

    return false;
  }

  protected handleJoinProjectClick(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't join project without a project"
    );

    void this.entityManager.joinedProjectsManager.joinProject(this.project.id);

    this.joiningProjectAndWaitingForActualization = true;
  }
}
