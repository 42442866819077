export class DirtyChecker {
  /**
   *
   * @param {*} value
   */
  setValue(value) {
    if (typeof value === 'object' && value != null) {
      this._value = _.cloneDeep(value);
    } else {
      this._value = value;
    }
  }

  /**
   *
   * @param {*} newValue
   * @returns {boolean} - returns true if the new value is different
   */
  isDirty(newValue) {
    return !_.isEqual(this._value, newValue);
  }

  /**
   *
   * @param {*} newValue
   * @returns {boolean} - returns true if the new value was different
   */
  updateValueIfDirty(newValue) {
    const isDirty = this.isDirty(newValue);
    if (isDirty) {
      this.setValue(newValue);
    }

    return isDirty;
  }
}
