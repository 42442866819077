import { autoinject, bindable, observable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PersonToPerson } from '../../classes/EntityManager/entities/PersonToPerson/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @techdebt [REC-2998] this widget currently uses the permissionsHandle.canManageRelations permission to control the permission to the relations.
 * Ideally permissions should be handled on the PersonToPerson level though. That would require to extract the content for a personToPerson into it's own component.
 */
@autoinject()
export class EditPersonRelationsWidget {
  @bindable()
  public person: Person | null = null;

  /**
   * if this is set, then all entities will be created with a temporaryGroupName + shadowEntity = true
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  private isAttached: boolean = false;
  private availablePersonToPersons: Array<PersonToPerson> = [];

  @observable()
  private personIdToAdd: string | null;

  protected personIdToAddErrorTexts: Array<string> | null = null;

  constructor(
    private readonly i18n: I18N,
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.personIdToAdd = null;

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'person'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonToPerson,
      this.updateAvailablePersonToPersons.bind(this)
    );
    this.updateAvailablePersonToPersons();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected personChanged(): void {
    if (this.isAttached) {
      this.updateAvailablePersonToPersons();
      this.personIdToAdd = null;
    }
  }

  protected personIdToAddChanged(): void {
    this.updatePersonIdToAddErrorTexts();
  }

  private updateAvailablePersonToPersons(): void {
    if (this.person) {
      this.availablePersonToPersons =
        this.entityManager.personToPersonRepository.getByPersonId(
          this.person.id
        );
    } else {
      this.availablePersonToPersons = [];
    }

    this.updatePersonIdToAddErrorTexts();
  }

  private updatePersonIdToAddErrorTexts(): void {
    const errorTexts = [];

    if (this.personIdToAdd) {
      const personToPersonIndex = this.availablePersonToPersons.findIndex(
        (personToPerson) => {
          return (
            personToPerson.person1Id === this.personIdToAdd ||
            personToPerson.person2Id === this.personIdToAdd
          );
        }
      );

      if (personToPersonIndex >= 0) {
        errorTexts.push(
          this.i18n.tr(
            'personComponents.editPersonRelationsWidget.relationExists'
          )
        );
      }
    }

    this.personIdToAddErrorTexts = errorTexts.length ? errorTexts : null;
  }

  protected handleAddPersonToPersonClick(): void {
    if (!this.person || !this.personIdToAdd) {
      return;
    }

    const personToPerson = this.entityManager.personToPersonRepository.create({
      person1Id: this.person.id,
      person2Id: this.personIdToAdd,
      ownerUserGroupId: this.person.ownerUserGroupId,
      temporaryGroupName: this.temporaryGroupName,
      shadowEntity: !!this.temporaryGroupName
    });

    this.personIdToAdd = null;

    this.updateAvailablePersonToPersons();

    this.goToPersonToPerson(personToPerson);
  }

  protected handleDeletePersonToPersonClick(
    personToPerson: PersonToPerson
  ): void {
    void Dialogs.deleteEntityDialog(personToPerson).then(() => {
      this.entityManager.personToPersonRepository.delete(personToPerson);
    });
  }

  protected handlePersonToPersonChanged(personToPerson: PersonToPerson): void {
    this.entityManager.personToPersonRepository.update(personToPerson);
  }

  private goToPersonToPerson(personToPerson: PersonToPerson): void {
    void ScrollHelper.autoScrollToSubtleListItem(
      '#' + this.getPersonToPersonElementId(personToPerson.id)
    );
  }

  private getPersonToPersonElementId(personToPersonId: string): string {
    return 'edit-person-relations-widget--personToPerson-' + personToPersonId;
  }

  protected getPersonRelationOtherPersonName(
    personId: string,
    person1Id: string,
    person2Id: string
  ): string {
    const otherPersonId = person1Id !== personId ? person1Id : person2Id;
    const person = this.entityManager.personRepository.getById(otherPersonId);
    if (person) {
      return PersonUtils.getPersonDisplayNameForPerson(person);
    }

    return this.i18n.tr('modelsDetail.PersonModel.personNotFound');
  }

  protected getPersonRelationOtherPersonHref(
    personId: string,
    person1Id: string,
    person2Id: string
  ): string {
    const otherPersonId = person1Id !== personId ? person1Id : person2Id;
    return PersonUtils.getEditPersonPageUrl(this.router, otherPersonId);
  }
}
