import { bindable, inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event value-changed
 */
@inject(Element, AppEntityManager, SubscriptionManagerService)
export class ProcessConfigurationMeasurePointTypeSelect {
  /** @type {string|null} */
  @bindable value = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType|null} */
  @bindable selectedMeasurePointType = null;

  /** @type {string|null} */
  @bindable processConfigurationId = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {boolean} */
  @bindable noLabel = false;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType>} */
  _measurePointTypes = [];
  /** @type {boolean} */
  _attached = false;

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(element, entityManager, subscriptionManagerService) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationMeasurePointType,
      this._updateMeasurePointTypes.bind(this)
    );
    this._updateMeasurePointTypes();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processConfigurationIdChanged() {
    if (this._attached) {
      this._updateMeasurePointTypes();
    }
  }

  _updateMeasurePointTypes() {
    if (this.processConfigurationId) {
      this._measurePointTypes =
        this._entityManager.processConfigurationMeasurePointTypeRepository.getByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this._measurePointTypes = [];
    }
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
