import { TransformHelper } from 'common/Geometry/TransformHelper';
import { Vector } from 'common/Geometry/Vector';
import { AngleHelper } from 'common/Geometry/AngleHelper';

/**
 * this currently only works correctly for elements with the following conditions:
 *  * position: absolute
 *  * top/left explicitly set!
 *  * height/width explicitly set!
 *  * transform-origin: 0, 0
 *  * rotation achieved through transform: rotate(deg)
 *
 */
export class ElementPositionInformation {
  /** @type {HTMLElement} */
  _element;

  /**
   * in deg
   * already normalized to vector logic
   * @type {number}
   */
  rotation;

  /** @type {number} */
  height;
  /** @type {number} */
  width;

  /** @type {Vector} */
  topLeftPoint;
  /** @type {Vector} */
  topRightPoint;
  /** @type {Vector} */
  bottomRightPoint;
  /** @type {Vector} */
  bottomLeftPoint;
  /** @type {Vector} */
  centerPoint;

  /**
   *
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._element = element;
    this.recalculate();
  }

  recalculate() {
    const computed = window.getComputedStyle(this._element);
    this.rotation = AngleHelper.flipAngle(
      TransformHelper.getRotationFromTransformMatrix(computed.transform)
    );

    this.width = parseFloat(computed.width);
    const widthVector = Vector.createHtmlVector(this.width, 0);
    widthVector.rotate(this.rotation);
    this.height = parseFloat(computed.height);
    const heightVector = Vector.createHtmlVector(0, this.height);
    heightVector.rotate(this.rotation);

    this.topLeftPoint = Vector.createHtmlVector(
      parseFloat(computed.left),
      parseFloat(computed.top)
    );
    this.topRightPoint = this.topLeftPoint.clone().addVector(widthVector);
    this.bottomRightPoint = this.topLeftPoint
      .clone()
      .addVector(widthVector)
      .addVector(heightVector);
    this.bottomLeftPoint = this.topLeftPoint.clone().addVector(heightVector);

    const diagonalVector = this.bottomRightPoint
      .clone()
      .substractVector(this.topLeftPoint);
    const halfDiagonalVector = Vector.createHtmlVector(
      diagonalVector.getLength() / 2,
      0
    );
    halfDiagonalVector.setAngle(diagonalVector.getAngle());

    this.centerPoint = this.topLeftPoint.clone().addVector(halfDiagonalVector);
  }
}
