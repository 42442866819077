import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createUserCompanySettingEntityInfo } from 'common/Types/Entities/UserCompanySetting/createUserCompanySettingEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { UserCompanySetting } from './types';

export const userCompanySettingEntityInfo =
  createUserCompanySettingEntityInfo<UserCompanySetting>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
