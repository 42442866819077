import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskDeviceGroups } from '../../computedValues/computers/ProcessTaskDeviceGroupsForProcessTaskIdComputer';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskInvoice } from '../../classes/EntityManager/entities/ProcessTaskInvoice/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import {
  DeviceGroupConfigurationsByProcessTaskId,
  DeviceGroupConfigurationsByProcessTaskIdComputer
} from '../../computedValues/computers/DeviceGroupConfigurationByProcessTaskIdComputer/DeviceGroupConfigurationByProcessTaskIdComputer';
import { EntityGrouper } from '../../classes/EntityGrouper';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

@autoinject()
export class ProcessTaskInvoiceRelationsWidgetDevices {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskInvoice: ProcessTaskInvoice | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public allDevicesSelected: boolean = false;

  /**
   * is null when devices are not loaded
   * read only
   */
  @bindable()
  public processTaskDeviceCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  protected groupedDevices: ProcessTaskDeviceGroups = [];
  private processTaskDevices: Array<ProcessTaskDevice> = [];
  private processTaskInvoiceToProcessTaskDevices: Array<ProcessTaskInvoiceToProcessTaskDevice> =
    [];
  private deviceGroupConfigurationsByProcessTaskId: DeviceGroupConfigurationsByProcessTaskId =
    new Map();
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.entityManager = entityManager;
    this.computedValueService = computedValueService;
  }

  public selectAll(): void {
    this.processTaskDevices.forEach((device) => {
      this.ensureRelationStatusToDevice(device, true);
    });

    this.updateProcessTaskInvoiceToProcessTaskDevices();
  }

  public deselectAll(): void {
    this.processTaskDevices.forEach((device) => {
      this.ensureRelationStatusToDevice(device, false);
    });

    this.updateProcessTaskInvoiceToProcessTaskDevices();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTaskDevice,
      this.updateProcessTaskInvoiceToProcessTaskDevices.bind(this)
    );
    this.updateProcessTaskInvoiceToProcessTaskDevices();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      this.updateProcessTaskDevices.bind(this)
    );

    this.updateProcessTaskDevices();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateGroupedDevices.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: DeviceGroupConfigurationsByProcessTaskIdComputer,
        callback: (deviceGroupConfigurationsByProcessTaskId) => {
          this.deviceGroupConfigurationsByProcessTaskId =
            deviceGroupConfigurationsByProcessTaskId;
          this.updateGroupedDevices();
        },
        computeData: {}
      })
    );
  }

  protected detached(): void {
    this.isAttached = true;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskInvoiceToProcessTaskDevices();
    }
  }

  protected processTaskInvoiceChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskInvoiceToProcessTaskDevices();
    }
  }

  private updateProcessTaskDevices(): void {
    if (this.processTask) {
      if (this.processTaskInvoice?.doneAt != null) {
        this.processTaskDevices =
          this.processTaskInvoiceToProcessTaskDevices.map((r) =>
            this.entityManager.processTaskDeviceRepository.getRequiredById(
              r.processTaskDeviceId
            )
          );
      } else {
        this.processTaskDevices =
          this.entityManager.processTaskDeviceRepository.getByProcessTaskIdWithoutSnapshots(
            this.processTask.id
          );
      }

      this.processTaskDeviceCount = this.processTaskDevices.length;
    } else {
      this.processTaskDevices = [];
    }
    this.updateAllDevicesSelected();
    this.updateGroupedDevices();
  }

  private updateGroupedDevices(): void {
    if (this.processTask) {
      const properties =
        this.entityManager.propertyRepository.getByOwnerProcessTaskId(
          this.processTask?.id
        );

      const entityGrouper = new EntityGrouper<ProcessTaskDevice>({
        groupConfigurations:
          this.deviceGroupConfigurationsByProcessTaskId.get(
            this.processTask?.id
          ) || [],
        properties,
        propertyEntityIdField: 'processTaskDeviceId'
      });

      this.groupedDevices = InstancePreserver.createNewArray({
        originalArray: this.groupedDevices ?? [],
        newArray: entityGrouper.groupEntities(this.processTaskDevices),
        getTrackingValue: (item) => item.name
      });
    } else {
      this.groupedDevices = [];
    }
  }

  private updateProcessTaskInvoiceToProcessTaskDevices(): void {
    if (this.processTaskInvoice && this.processTask) {
      this.processTaskInvoiceToProcessTaskDevices =
        this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceIdAndProcessTaskId(
          this.processTaskInvoice.id,
          this.processTask.id
        );
    } else {
      this.processTaskInvoiceToProcessTaskDevices = [];
    }

    this.updateProcessTaskDevices();
  }

  private updateAllDevicesSelected(): void {
    this.allDevicesSelected = this.processTaskDevices.every((device) => {
      return this.processTaskInvoiceToProcessTaskDevices.find(
        (itd) => itd.processTaskDeviceId === device.id
      );
    });
  }

  protected handleCheckboxChanged(
    event: CustomCheckboxCheckedChangedEvent,
    device: ProcessTaskDevice
  ): void {
    this.ensureRelationStatusToDevice(device, event.detail.checked);
    this.updateProcessTaskInvoiceToProcessTaskDevices();
  }

  private ensureRelationStatusToDevice(
    device: ProcessTaskDevice,
    available: boolean
  ): void {
    if (!this.processTaskInvoice) {
      throw new Error(
        "can't ensure relation status to device without a processTaskInvoice"
      );
    }

    if (!this.processTask) {
      throw new Error(
        "can't ensure relation status to device without a processTask"
      );
    }

    const existingRelation =
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getByProcessTaskInvoiceIdAndProcessTaskDeviceId(
        this.processTaskInvoice.id,
        device.id
      );

    if (available) {
      if (!existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.create(
          {
            processTaskInvoiceId: this.processTaskInvoice.id,
            processTaskDeviceId: device.id,
            ownerProcessTaskId: this.processTask.id,
            ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
            ownerUserGroupId: this.processTask.ownerUserGroupId
          }
        );
      }
    } else {
      if (existingRelation) {
        this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.delete(
          existingRelation
        );
      }
    }
  }

  protected deviceIdIsIncluded(
    deviceId: string,
    processTaskInvoiceToProcessTaskDevices: Array<ProcessTaskInvoiceToProcessTaskDevice>
  ): boolean {
    return !!processTaskInvoiceToProcessTaskDevices.find(
      (r) => r.processTaskDeviceId === deviceId
    );
  }
}
