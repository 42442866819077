import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from '../../classes/EntityManager/entities/types';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskProjectService } from '../../services/ProcessTaskProjectService';
import { ProcessConfigurationStepsFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationStepsFromProcessTaskGroupIdComputer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  ProcessTaskAppointmentDateInfo,
  ProcessTaskAppointmentDateInfoMap,
  ProcessTaskAppointmentDateInfoMapComputer
} from '../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ExpandableContainer } from '../../aureliaComponents/expandable-container/expandable-container';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import {
  ProcessTaskToProjectTitleCacheComputer,
  ProcessTaskToProjectTitleCache,
  fallbackProcessTaskToProjectTitleCache
} from '../../computedValues/computers/ProcessTaskToProjectTitleCacheComputer';
import { PictureProcessTaskToProjectType } from '../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectRepository';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * @attribute data-no-margin-top
 */
@autoinject()
export class ProcessTaskProjectPicturesWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskToProjectType: PictureProcessTaskToProjectType | null =
    null;

  @bindable()
  public enablePictureCreation: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  private readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  @subscribableLifecycle()
  private readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private isAttached: boolean = false;
  private title: string | null = null;
  private processTaskToProject: ProcessTaskToProject | null = null;
  private expandableContainer: ExpandableContainer | null = null;

  private processConfigurationSteps: Array<ProcessConfigurationStep> | null =
    null;

  private processTaskToProjectTitleCache: ProcessTaskToProjectTitleCache =
    fallbackProcessTaskToProjectTitleCache;

  private processTaskAppointmentDateInfoMap: ProcessTaskAppointmentDateInfoMap =
    new Map();

  private dateInfo: ProcessTaskAppointmentDateInfo | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskProjectService: ProcessTaskProjectService,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Project,
        context: this,
        expression: 'project'
      });

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.Thing,
        context: this as ProcessTaskProjectPicturesWidget,
        propertyName: 'processTask',
        idPropertyName: 'thingId'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateProcessTaskToProject.bind(this)
    );
    this.updateProcessTaskToProject();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskToProjectTitleCacheComputer,
        computeData: {},
        callback: (processTaskToProjectTitleCache) => {
          this.processTaskToProjectTitleCache = processTaskToProjectTitleCache;
          this.updateTitle();
        }
      }),
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          ProcessConfigurationStepsFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTask
            ? { processTaskGroupId: this.processTask.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (steps) => {
          this.processConfigurationSteps = steps;
        },
        onNoComputeData: () => {
          this.processConfigurationSteps = null;
        }
      }),
      this.computedValueService.subscribe({
        valueComputerClass: ProcessTaskAppointmentDateInfoMapComputer,
        computeData: {},
        callback: (processTaskAppointmentDateInfoMap) => {
          this.processTaskAppointmentDateInfoMap =
            processTaskAppointmentDateInfoMap;
          this.updateDateInfo();
        }
      })
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskToProject();
    }
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskToProject();
    }
  }

  protected processTaskToProjectTypeChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskToProject();
    }
  }

  private updateProcessTaskToProject(): void {
    if (this.processTask) {
      this.processTaskToProject =
        this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndAppointmentId(
          this.processTask.id,
          this.processTaskAppointment?.id ?? null,
          this.processTaskToProjectType
        )[0] ?? null;
    } else {
      this.processTaskToProject = null;
    }
  }

  private updateTitle(): void {
    if (this.processTaskToProject) {
      this.title =
        this.processTaskToProjectTitleCache.getTitleForRelation(
          this.processTaskToProject
        ) ?? null;
    } else {
      this.title = this.processTaskToProjectTitleCache.getFallbackTitle(
        this.processTaskAppointment?.id ?? null,
        this.processTaskToProjectType
      );
    }
  }

  private updateDateInfo(): void {
    if (this.processTaskAppointment) {
      this.dateInfo =
        this.processTaskAppointmentDateInfoMap.get(
          this.processTaskAppointment.id
        ) ?? null;
    } else {
      this.dateInfo = null;
    }
  }

  protected handleTitleInputClick(event: MouseEvent): void {
    if (this.canEditTitle) {
      this.expandableContainer?.ignoreClickEvent(event);
    }
  }

  protected handleTextChanged(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskProjectPicturesWidget.handleTextChanged without a processTask"
    );

    const project = this.processTaskProjectService.getOrCreateProject(
      this.processTask,
      this.processTaskAppointment,
      this.processTaskToProjectType
    );
    project.name = this.title;
    this.entityManager.projectRepository.update(project);
  }

  @computedFrom(
    'processTask.currentProcessConfigurationStepId',
    'processTaskToProject.processConfigurationStepId',
    'processConfigurationSteps',
    'dateInfo.dateFrom'
  )
  protected get processConfigurationStepNameWithSeparator(): string {
    let id = this.processTaskToProject?.processConfigurationStepId ?? null;

    if (!id && this.processTaskAppointment && this.processTask) {
      // only show a step if there is an appointment, general pictures won't have a step
      id = this.processTaskProjectService.getProcessConfigurationStepId(
        this.processTask,
        this.processTaskAppointment
      );
    }

    const step = this.processConfigurationSteps?.find((s) => s.id === id);

    if (step?.name) {
      return step.name + (this.dateInfo?.dateFrom ? ': ' : '');
    }

    return '';
  }

  @computed(
    expression(
      'processTaskAppointment.processConfigurationStepAutoAppointmentId'
    ),
    expression('project'),
    expression('projectPermissionsHandle.canEditField.name'),
    expression('thingPermissionsHandle.canCreateProjects')
  )
  protected get canEditTitle(): boolean {
    if (
      this.processTaskAppointment?.processConfigurationStepAutoAppointmentId
    ) {
      return false;
    }

    if (this.project) {
      return this.projectPermissionsHandle.canEditField.name;
    }

    return this.thingPermissionsHandle.canCreateProjects;
  }

  @computed(expression('processTaskToProject.projectId'))
  protected get project(): Project | null {
    if (!this.processTaskToProject?.projectId) {
      return null;
    }

    return this.entityManager.projectRepository.getById(
      this.processTaskToProject.projectId
    );
  }
}
