import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructureTemplateEntryEntityInfo } from 'common/Types/Entities/StructureTemplateEntry/createStructureTemplateEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntry } from './types';

export const structureTemplateEntryEntityInfo =
  createStructureTemplateEntryEntityInfo<StructureTemplateEntry>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
