export class CsvImportUtils {
  public static parseNumericInput(
    input: string | number | null,
    decimalSeparator: string
  ): string | number | null {
    if (typeof input === 'number') return input;
    if (!input) return null;
    let normalizedInput = input;

    // Clear input of thousands's separators
    // We consider the char which is not selected as decimal separator as thousand's separator
    switch (decimalSeparator) {
      case '.':
        normalizedInput = normalizedInput.replace(/,/g, '');
        break;
      case ',':
      default:
        normalizedInput = normalizedInput.replace(/\./g, '');
    }

    normalizedInput = normalizedInput.replace(decimalSeparator, '.');

    normalizedInput = normalizedInput.replace(/[^0-9\.-]/g, '');

    if (!isNaN(parseFloat(normalizedInput))) return normalizedInput;

    return null;
  }
}
