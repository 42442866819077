import { AppEntityRepository } from '../../base/AppEntityRepository';
import { BaseEntity } from '../BaseEntity';
import { Project } from '../Project/types';
import { QuestionCatalogue } from '../QuestionCatalogue/types';
import { EntityName } from '../types';
import { ProjectQuestionCatalogue } from './types';

export class ProjectQuestionCatalogueRepository extends AppEntityRepository<EntityName.ProjectQuestionCatalogue> {
  public createFromQuestionCatalogue(
    questionCatalogue: Pick<
      QuestionCatalogue,
      'id' | 'name' | 'ownerUserGroupId'
    >,
    project: Pick<Project, 'id' | 'ownerUserGroupId'>,
    options?: Pick<Partial<BaseEntity>, 'shadowEntity' | 'temporaryGroupName'>
  ): ProjectQuestionCatalogue {
    return this.create({
      name: questionCatalogue.name,
      ownerUserGroupId: project.ownerUserGroupId,
      createdFromQuestionCatalogueId: questionCatalogue.id,
      ownerProjectId: project.id,
      temporaryGroupName: options?.temporaryGroupName,
      shadowEntity: options?.shadowEntity
    });
  }

  public getByProjectId(projectId: string): Array<ProjectQuestionCatalogue> {
    return this.getAll().filter((e) => e.ownerProjectId === projectId);
  }
}
