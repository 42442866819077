import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class ArchiveFilter<T extends U> {
  @bindable public entities: Array<T> = [];

  @bindable public archivePropertyName = 'archived';
  @bindable public archiveCallback: ((entity: T) => boolean) | null = null;

  @bindable public filteredEntities: Array<T> = [];

  @bindable public filterMode: FilterMode =
    FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED;

  private filterModeOptions = [
    {
      labelTk: 'filterComponents.archiveFilter.showNoArchived',
      value: FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED
    },
    {
      labelTk: 'filterComponents.archiveFilter.showOnlyArchived',
      value: FilterMode.FILTER_MODE_SHOW_ONLY_ARCHIVED
    },
    {
      labelTk: 'filterComponents.archiveFilter.showAll',
      value: FilterMode.FILTER_MODE_SHOW_ALL
    }
  ];

  protected entitiesChanged(): void {
    this.updateFilteredEntities();
  }

  protected filterModeChanged(): void {
    this.updateFilteredEntities();
  }

  private updateFilteredEntities(): void {
    this.filteredEntities = this.entities.filter((entity) => {
      switch (this.filterMode) {
        default:
        case FilterMode.FILTER_MODE_SHOW_NO_ARCHIVED:
          return !this.entityIsArchived(entity);

        case FilterMode.FILTER_MODE_SHOW_ONLY_ARCHIVED:
          return this.entityIsArchived(entity);

        case FilterMode.FILTER_MODE_SHOW_ALL:
          return true;
      }
    });
  }

  private entityIsArchived(entity: T): boolean {
    return this.archiveCallback
      ? this.archiveCallback(entity)
      : entity[this.archivePropertyName];
  }
}

export enum FilterMode {
  FILTER_MODE_SHOW_NO_ARCHIVED = 'show-no-archived',
  FILTER_MODE_SHOW_ONLY_ARCHIVED = 'show-only-archived',
  FILTER_MODE_SHOW_ALL = 'show-all'
}

type U = Record<string, any>;
