import { BaseEntityUtils } from 'common/Types/BaseEntities/BaseEntityUtils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationCategory } from './types';

export class ProcessConfigurationCategoryRepository extends AppEntityRepository<EntityName.ProcessConfigurationCategory> {
  public getSortedCategoriesByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationCategory> {
    const categories = this.getAll().filter(
      (c) => c.ownerProcessConfigurationId === processConfigurationId
    );
    return BaseEntityUtils.sortByCreationOrder(categories);
  }
}
