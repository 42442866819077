import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { SocketService } from '../../services/SocketService';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditThingGroupDialog } from '../../dialogs/edit-thing-group-dialog/edit-thing-group-dialog';
import { FilterHelper } from '../../classes/FilterHelper';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { Dialogs } from '../../classes/Dialogs';
import {
  CallbackParamsWithUserGroup,
  ImportFromCsvFileDialog
} from '../../dialogs/import-from-csv-file-dialog/import-from-csv-file-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroupUtils } from '../../classes/EntityManager/entities/ThingGroup/ThingGroupUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import {
  FieldInfosFromConfig,
  FieldType
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { User } from '../../classes/EntityManager/entities/User/types';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';

@autoinject()
export class edit_thing_groups {
  private subscriptionManager: SubscriptionManager;

  private availableThingGroups: Array<ThingGroup> = [];
  protected filteredThingGroups: Array<ThingGroup> = [];
  protected sortedThingGroups: Array<ThingGroup> = [];
  protected currentPageThingGroups: Array<ThingGroup> = [];

  protected sortOptions: { name: SorterSortOption<ThingGroup> } =
    ThingGroupUtils.sortOptions;
  protected currentSortOption: SorterSortOption<ThingGroup>;

  private editableUserGroups: Array<UserGroup> = [];

  private currentUser: User | null = null;

  @observable private isMobile: boolean;

  private isOnline: boolean = false;

  private isAttached: boolean = false;

  @observable private thingGroupFilterString: string | null;

  private pagination: Pagination<ThingGroup> | null = null;

  private moreButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editThingGroups.importThingGroupsFromCsvFile',
      name: 'import-thing-groups-from-csv',
      iconClass: 'far fa-file-lines',
      disabledContext: this,
      disabledPropertyName: 'importThingGroupsFromCsvDisabled',
      isFileInput: true,
      fileInputAccept: 'text/plain,text/comma-separated-values,.csv'
    }
  ];

  private csvImportFields: FieldInfosFromConfig<FieldInfoConfiguration> = [
    { field: 'customId', header: 'ID', type: FieldType.STRING, required: true },
    { field: 'name', header: 'Name', type: FieldType.STRING, required: true },
    { field: 'streetName', header: 'Straße', type: FieldType.STRING },
    { field: 'zip', header: 'PLZ', type: FieldType.STRING },
    { field: 'municipality', header: 'Ort', type: FieldType.STRING },
    { field: 'longitude', header: 'Länge', type: FieldType.NUMBER },
    { field: 'latitude', header: 'Breite', type: FieldType.NUMBER },
    { field: 'note', header: 'Bemerkung', type: FieldType.STRING }
  ];

  protected readonly EntityName = EntityName;

  constructor(
    private readonly router: Router,
    private readonly socketService: SocketService,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.currentSortOption = ThingGroupUtils.sortOptions.name;

    this.isMobile = false;
    this.thingGroupFilterString = null;
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateAvailableThingGroups.bind(this)
    );
    this.updateAvailableThingGroups();

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.moreButtonChoices,
      fileChangedCallbacks: {
        'import-thing-groups-from-csv':
          this.handleImportThingGroupsFromCsvFileChanged.bind(this)
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
    GlobalMenu.releaseControl(this);
  }

  @computedFrom('isOnline', 'editableUserGroups')
  protected get importThingGroupsFromCsvDisabled(): boolean {
    return (
      !this.isOnline ||
      !this.editableUserGroups ||
      this.editableUserGroups.length === 0
    );
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }
  }

  private updateAvailableThingGroups(): void {
    this.availableThingGroups =
      this.entityManager.thingGroupRepository.getAll();
    this.updateFilteredThingGroups();
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) {
      GlobalMenu.setVisible(this, this.isMobile);
    }
  }

  protected thingGroupFilterStringChanged(): void {
    this.updateFilteredThingGroups();
  }

  private updateFilteredThingGroups(): void {
    this.filteredThingGroups = FilterHelper.filterItems(
      this.availableThingGroups,
      ThingGroupUtils.filterFunction,
      this.thingGroupFilterString
    );
  }

  protected handleCreateThingGroupClicked(userGroup: UserGroup): void {
    const thingGroup = this.entityManager.thingGroupRepository.create({
      ownerUserGroupId: userGroup.id
    });

    this.updateAvailableThingGroups();
    this.editThingGroup(thingGroup);
  }

  protected handleEditThingGroupClicked(thingGroup: ThingGroup): void {
    this.editThingGroup(thingGroup);
  }

  protected handleEnterThingGroupClicked(thingGroupId: string): void {
    this.router.navigateToRoute('edit_objects', { thingGroupId: thingGroupId });
  }

  private async handleImportThingGroupsFromCsvFileChanged(
    event: Event
  ): Promise<void> {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files[0] && this.isOnline) {
      await ImportFromCsvFileDialog.open<FieldInfoConfiguration>({
        file: inputElement.files[0],
        editableUserGroups: this.editableUserGroups,
        fields: this.csvImportFields,
        importFromCsvFileCallback:
          this.handleImportThingGroupsFromCsvFileSubmitted.bind(this),
        showAdditionalFieldsAsParametersCheckbox: true,
        showOverwriteCheckbox: true,
        entityNameTk: 'models.ThingGroupModel_plural'
      });
      inputElement.value = '';
    }
  }

  private handleImportThingGroupsFromCsvFileSubmitted(
    sendData: CallbackParamsWithUserGroup<FieldInfoConfiguration>
  ): Promise<void> {
    return new Promise((res) => {
      this.socketService.importThingGroupsFromCsvFile(sendData, (response) => {
        res();

        if (response.success) {
          Dialogs.timedSuccessDialog('CSV Import erfolgreich!');
        } else {
          Dialogs.errorDialog(
            'Fehler beim CSV Import!',
            response.error && response.error.message
              ? response.error.message
              : ''
          );
        }
      });
    });
  }

  private editThingGroup(thingGroup: ThingGroup): void {
    void EditThingGroupDialog.open({
      thingGroup: thingGroup,
      onDialogClosed: (group) => {
        this.goToThingGroup(group);
      }
    });
  }

  private goToThingGroup(thingGroup: ThingGroup): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getThingGroupListItemId(thingGroup.id),
      this.pagination,
      thingGroup,
      () => this.isAttached
    );
  }

  private getThingGroupListItemId(thingGroupId: string): string {
    return 'edit-thing-group--thing-group-' + thingGroupId;
  }
}

type FieldInfoConfiguration = {
  entityType: ThingGroup;
  fieldInfos: [
    {
      field: 'customId';
      type: FieldType.STRING;
    },
    {
      field: 'name';
      type: FieldType.STRING;
    },
    {
      field: 'streetName';
      type: FieldType.STRING;
    },
    {
      field: 'zip';
      type: FieldType.STRING;
    },
    {
      field: 'municipality';
      type: FieldType.STRING;
    },
    {
      field: 'longitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'latitude';
      type: FieldType.NUMBER;
    },
    {
      field: 'note';
      type: FieldType.STRING;
    }
  ];
};
