import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../../UrlManager';
import { ReportType } from './types';

export class ReportTypeUtils {
  private constructor() {}

  public static getFullOnlineFilePathForReportType(
    reportType: ReportType,
    token: string
  ): string {
    return this.getFullOnlineFilePath(reportType.id, token);
  }

  public static getRelativeOnlineFilePathForReportType(
    reportType: ReportType,
    token: string
  ): string {
    return this.getRelativeOnlineFilePath(reportType.id, token);
  }

  public static getFullOnlineFilePath(
    reportTypeId: string,
    token: string
  ): string {
    const relativePath = this.getRelativeOnlineFilePath(reportTypeId, token);

    return PathUtils.joinPaths(UrlManager.webFolder, relativePath);
  }

  public static getRelativeOnlineFilePath(
    reportTypeId: string,
    token: string
  ): string {
    return PathUtils.joinPaths(
      'files',
      'report-templates',
      'download',
      reportTypeId,
      `?token=${token}`
    );
  }
}
