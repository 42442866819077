import { bindable } from 'aurelia-framework';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyProjectPropertyWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyProjectPropertyWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error("BasePropertyProjectPropertyWidget doesn't support focus");
  }
}

export const basePropertyProjectPropertyWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['none']
  });
