import { ThingType } from './types';
import { EntityName } from '../types';
import { AppEntityRepository } from '../../base/AppEntityRepository';

export class ThingTypeRepository extends AppEntityRepository<EntityName.ThingType> {
  public getByUserGroupId(userGroupId: string): Array<ThingType> {
    return this.getAll().filter((tG) => tG.ownerUserGroupId === userGroupId);
  }

  /**
   * Retrieve ThingTypes from which properties should be loaded
   * whenever a new Thing is created.
   *
   * @see {ThingCreationService#loadPropertiesFromThingTypes}
   */
  public getEntitiesToImportOnThingCreation(): Array<ThingType> {
    return this.getAll().filter((tt) => tt.importOnThingCreation);
  }
}
