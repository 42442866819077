import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { CreateEntityClickedEvent } from '../../aureliaComponents/create-entity-button/create-entity-button';
import { EditQuestionCategoryDialog } from '../../dialogs/edit-question-category-dialog/edit-question-category-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class EditChecklistQuestionCategories {
  protected readonly EntityName = EntityName;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected handleCreateCategoryButtonClicked(
    event: CreateEntityClickedEvent
  ): void {
    const ownerUserGroupId = event.detail.userGroup?.id;

    assertNotNullOrUndefined(
      ownerUserGroupId,
      'cannot handleCreateCategoryButtonClicked without ownerUserGroupId'
    );

    const questionCategory =
      this.entityManager.questionCategoryRepository.create({
        ownerUserGroupId
      });

    void EditQuestionCategoryDialog.open({
      questionCategory
    });
  }
}
