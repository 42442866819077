import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../../common/src/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { Tag } from '../../../classes/EntityManager/entities/Tag/types';
import { SelectCategoryTagsDialog } from '../../../dialogs/select-category-tags-dialog/select-category-tags-dialog';

/**
 * The "Beschlagworten" button. Keeps a list of all selected tags.
 *
 * @event tag-ids-changed fired when tag ids are changed. Contains the updated list of tag ids.
 */
@autoinject()
export class UrfwCategorizedTagsButton {
  /**
   * The IDs of tags selected in the dialog.
   */
  @bindable public tagIds: Array<string> = [];

  @bindable public ownerUserGroupId: string | null = null;

  @bindable public thingId: string | null = null;

  @bindable public enabled = false;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private toggleTagId(tag: Tag, value: boolean): void {
    if (value) {
      this.tagIds = [...this.tagIds, tag.id];
    } else {
      this.tagIds = this.tagIds.filter((t) => t !== tag.id);
    }
    DomEventHelper.fireEvent<TagIdsChangedEvent>(this.element, {
      name: 'tag-ids-changed',
      detail: { tagIds: this.tagIds }
    });
  }

  protected handleButtonClicked(): void {
    assertNotNullOrUndefined(
      this.ownerUserGroupId,
      'cannot handleButtonClicked without a ownerUserGroupId'
    );
    assertNotNullOrUndefined(
      this.thingId,
      'cannot handleButtonClicked without a thingId'
    );

    void SelectCategoryTagsDialog.open({
      type: 'thing',
      ownerUserGroupId: this.ownerUserGroupId,
      thingId: this.thingId,
      getTagIds: () => this.tagIds,
      onTagCheckChanged: this.toggleTagId.bind(this)
    });
  }
}

export type TagIdsChangedEvent = NamedCustomEvent<
  'tag-ids-changed',
  { tagIds: Array<string> }
>;
