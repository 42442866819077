import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessConfigurationAuthorizationTypeStepRelation } from 'common/Types/Entities/ProcessConfigurationAuthorizationType/ProcessConfigurationAuthorizationTypeDto';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationAuthorizationType } from '../../classes/EntityManager/entities/ProcessConfigurationAuthorizationType/types';
import { ProcessConfigurationStep } from '../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationAuthorizationTypeRelationsWidget {
  @bindable()
  public processConfigurationAuthorizationType: ProcessConfigurationAuthorizationType | null =
    null;

  @subscribableLifecycle()
  protected processConfigurationAuthorizationTypePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationAuthorizationType];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationAuthorizationTypePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationAuthorizationType,
        context: this as ProcessConfigurationAuthorizationTypeRelationsWidget,
        propertyName: 'processConfigurationAuthorizationType'
      });
  }

  public handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.processConfigurationAuthorizationType,
      "can't ProcessConfigurationAuthorizationTypeRelationsWidget.handleAddButtonClick without processConfigurationAuthorizationType"
    );
    assertNotNullOrUndefined(
      this.firstUnusedStep,
      "can't ProcessConfigurationAuthorizationTypeRelationsWidget.handleAddButtonClick without firstUnusedStep"
    );

    const relation: ProcessConfigurationAuthorizationTypeStepRelation = {
      processConfigurationStepId: this.firstUnusedStep.id,
      writePermissionInThisStep: false,
      autoRemoveAuthorizationsInThisStep: false
    };

    if (!this.processConfigurationAuthorizationType.stepRelations) {
      this.processConfigurationAuthorizationType.stepRelations = [relation];
    } else {
      this.processConfigurationAuthorizationType.stepRelations.push(relation);
    }

    this.entityManager.processConfigurationAuthorizationTypeRepository.update(
      this.processConfigurationAuthorizationType
    );
  }

  public handleDeleteButtonClicked(
    stepRelation: ProcessConfigurationAuthorizationTypeStepRelation
  ): void {
    assertNotNullOrUndefined(
      this.processConfigurationAuthorizationType,
      "can't ProcessConfigurationAuthorizationTypeRelationsWidget.handleDeleteButtonClicked without processConfigurationAuthorizationType"
    );

    const stepRelationIndex =
      this.processConfigurationAuthorizationType.stepRelations?.indexOf(
        stepRelation
      );

    if (
      !this.processConfigurationAuthorizationType.stepRelations ||
      stepRelationIndex == null ||
      stepRelationIndex === -1
    ) {
      return;
    }

    this.processConfigurationAuthorizationType.stepRelations.splice(
      stepRelationIndex,
      1
    );
    this.entityManager.processConfigurationAuthorizationTypeRepository.update(
      this.processConfigurationAuthorizationType
    );
  }

  public handleStepRelationChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationAuthorizationType,
      "can't ProcessConfigurationAuthorizationTypeRelationsWidget.handleDeleteButtonClicked without processConfigurationAuthorizationType"
    );

    this.entityManager.processConfigurationAuthorizationTypeRepository.update(
      this.processConfigurationAuthorizationType
    );
  }

  @computed(
    expression('processConfigurationAuthorizationType'),
    model(EntityName.ProcessConfigurationAuthorizationType)
  )
  protected get usedStepIds(): Array<string> {
    if (!this.processConfigurationAuthorizationType) {
      return [];
    }

    return (
      this.processConfigurationAuthorizationType.stepRelations?.map(
        (stepRelation) => stepRelation.processConfigurationStepId
      ) ?? []
    );
  }

  @computed(
    expression('processConfigurationAuthorizationType'),
    model(EntityName.ProcessConfigurationAuthorizationType),
    model(EntityName.ProcessConfigurationStep)
  )
  private get firstUnusedStep(): ProcessConfigurationStep | null {
    const processConfigurationAuthorizationType =
      this.processConfigurationAuthorizationType;
    if (!processConfigurationAuthorizationType) {
      return null;
    }

    const steps =
      this.entityManager.processConfigurationStepRepository.getOrderedProcessConfigurationStepsByProcessConfigurationId(
        processConfigurationAuthorizationType.ownerProcessConfigurationId
      );

    return (
      steps.find((step) => {
        if (!processConfigurationAuthorizationType.stepRelations) {
          return true;
        }

        return !processConfigurationAuthorizationType.stepRelations.some(
          (stepRelation) => stepRelation.processConfigurationStepId === step.id
        );
      }) ?? null
    );
  }
}
