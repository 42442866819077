import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { DateUtils } from '../../../../common/src/DateUtils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event `selected-year-changed` triggered when the user selects a different year that does not contain selectedDay.
 */
@autoinject()
export class CalendarYearSelect {
  /** `to-view` binding only */
  @bindable public readonly selectedYear: Date | null = null;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private fireSelectedYearChanged(year: Date): void {
    DomEventHelper.fireEvent<SelectedYearChangedEvent>(this.element, {
      name: 'selected-year-changed',
      detail: { year }
    });
  }

  protected handlePrevYearClicked(): void {
    assertNotNullOrUndefined(
      this.selectedYear,
      'cannot handlePrevWeekClicked without selectedDay'
    );

    this.fireSelectedYearChanged(
      DateUtils.getDateOneYearBefore(this.selectedYear)
    );
  }

  protected handleYearClicked(year: Date): void {
    this.fireSelectedYearChanged(year);
  }

  protected handleNextYearClicked(): void {
    assertNotNullOrUndefined(
      this.selectedYear,
      'cannot handleNextWeekClicked without selectedDay'
    );

    this.fireSelectedYearChanged(
      DateUtils.getDateOneYearAfter(this.selectedYear)
    );
  }

  @computedFrom('selectedDay')
  protected get years(): Array<Date> {
    if (!this.selectedYear) return [];

    let currentYear = DateUtils.getDateWithYearOffset(this.selectedYear, -2);
    const dates = [currentYear];
    for (let i = 1; i < 5; i++) {
      currentYear = DateUtils.getDateOneYearAfter(currentYear);
      dates.push(currentYear);
    }
    return dates;
  }

  protected isSelected(selectedDay: Date, year: Date): boolean {
    return DateUtils.isSameYear(selectedDay, year);
  }
}

export type SelectedYearChangedEvent = NamedCustomEvent<
  'selected-year-changed',
  { year: Date }
>;
