import { bindable, Disposable } from 'aurelia-framework';

import {
  ValueCalculationBaseEntityToConfig,
  ValueCalculationBaseEntityToResult,
  ValueCalculationResultAdapter,
  ValueCalculationResultSupportedBaseEntity
} from '../ValueCalculationResultAdapter/ValueCalculationResultAdapter';

export class ValueCalculationAllResultsWidget<
  TSupportedBaseEntity extends ValueCalculationResultSupportedBaseEntity
> {
  @bindable()
  public adapter: ValueCalculationResultAdapter<TSupportedBaseEntity> | null =
    null;

  protected results: Array<
    ValueCalculationBaseEntityToResult<TSupportedBaseEntity>
  > = [];
  protected configs: Array<
    ValueCalculationBaseEntityToConfig<TSupportedBaseEntity>
  > = [];

  private adapterDisposable: Disposable | null = null;

  private isAttached = false;

  protected attached(): void {
    this.isAttached = true;

    this.subscribeToAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.unsubscribeFromAdapter();
  }

  protected adapterChanged(): void {
    if (this.isAttached) {
      this.subscribeToAdapter();
    }
  }

  protected getConfigNameOfResult(
    result: ValueCalculationBaseEntityToResult<TSupportedBaseEntity>
  ): string {
    return (
      this.configs.find((c) => c.id === result.valueCalculationConfigId)
        ?.name ?? ''
    );
  }

  private subscribeToAdapter(): void {
    this.unsubscribeFromAdapter();

    if (this.adapter) {
      this.adapterDisposable = this.adapter.subscribe({
        setResults: (results) => {
          this.results = results;
        },
        setConfigs: (configs) => {
          this.configs = configs;
        }
      });
    }
  }

  private unsubscribeFromAdapter(): void {
    this.adapterDisposable?.dispose();
    this.adapterDisposable = null;
    this.results = [];
    this.configs = [];
  }
}
