import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import {
  UploadResult,
  EntitySynchronizationInfo,
  GetActualizationEntityInfo,
  GetActualizationsResult
} from '@record-it-npm/synchro-client';
import {
  GetActualizationsBulkResponse,
  GetActualizationsRequest
} from 'common/EndpointTypes/GetActualizationsEndpointsHandler';
import { TSaveEntityResponse } from 'common/EndpointTypes/SaveEntityEndpointsHandler';
import { SocketService } from '../../../services/SocketService';
import { EventAggregatorPromiseHelper } from '../../Promise/EventAggregatorPromiseHelper';
import { Disposable } from '../../Utils/DisposableContainer';
import { AppEntityManagerEntityTypesByEntityName } from './AppEntityManagerEntityTypesByEntityName';
import { EntityName } from './types';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';

@autoinject()
export class AppEntityManagerApiAdapter {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService
  ) {}

  public async getActualizations(
    infos: Array<GetActualizationEntityInfo<AppSynchronizationEnvironmentTypes>>
  ): Promise<
    GetActualizationsResult<
      AppSynchronizationEnvironmentTypes,
      AppEntityManagerEntityTypesByEntityName
    >
  > {
    const getActualizationsRequests = infos.map<GetActualizationsRequest>(
      (info) => {
        return {
          model: info.entityName,
          revision: info.revision,
          entityIdRevisionPairs: info.entitySpecificRevisions.map(
            (specificRevision) => {
              return {
                entityName: specificRevision.entityName,
                id: specificRevision.entityId,
                revision: specificRevision.revision
              };
            }
          )
        };
      }
    );

    const response: GetActualizationsBulkResponse =
      await EventAggregatorPromiseHelper.createConnectedPromise(
        this.eventAggregator,
        new Promise((resolve) => {
          this.socketService.getActualizationsBulk(
            { getActualizationsRequests },
            (res) => {
              resolve(res);
            }
          );
        })
      );

    return {
      entities: response.getActualizationResponses.map((r) => {
        return {
          entityName: r.model as any,
          entities: r.data as any
        };
      }),
      missingEntityInfos: response.missingRequests.map((missingRequest) => {
        return {
          entityName: missingRequest.model as EntityName,
          revision: missingRequest.revision,
          entitySpecificRevisions:
            missingRequest.entityIdRevisionPairs?.map((revisionPair) => {
              return {
                entityName: revisionPair.entityName as EntityName,
                entityId: revisionPair.id,
                revision: revisionPair.revision
              };
            }) ?? []
        };
      })
    };
  }

  public async uploadItem<TEntityName extends EntityName>(
    item: EntitySynchronizationInfo<
      AppSynchronizationEnvironmentTypes,
      TEntityName,
      AppEntityManagerEntityTypesByEntityName
    >
  ): Promise<
    UploadResult<
      AppSynchronizationEnvironmentTypes,
      TEntityName,
      AppEntityManagerEntityTypesByEntityName
    >
  > {
    const response = await EventAggregatorPromiseHelper.createConnectedPromise<
      TSaveEntityResponse<TEntityName>
    >(
      this.eventAggregator,
      new Promise((resolve) => {
        this.socketService.saveEntity(
          {
            entity_name: item.entityName,
            ...(item.entity as any) // I couldn't get this to work without the any here. I think that for some reason TS compares an collapsed (&) with an union (|) type here and that's why this is not working
          },
          (res) => {
            resolve(res as TSaveEntityResponse<TEntityName>);
          }
        );
      })
    );

    if ('success' in response && response.success === false) {
      throw new Error(`Failed to upload item: ${response.error}`);
    }

    return {
      entityName: item.entityName,
      entity: {
        ...item.entity, // because some fields are not used by the server it will not send us them. So we use the value we had before
        ...response
      }
    };
  }

  public bindIsAuthenticated(
    callback: (isAuthenticated: boolean) => void
  ): Disposable {
    return this.socketService.registerBinding('isAuthenticated', callback);
  }

  public async joinProject(projectId: string): Promise<void> {
    return new Promise((resolve) => {
      this.socketService.joinProject(projectId, () => resolve());
    });
  }

  public async leaveProject(projectId: string): Promise<void> {
    return new Promise((resolve) => {
      this.socketService.leaveProject(projectId, () => resolve());
    });
  }

  public async joinProcessTaskGroup(processTaskGroupId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socketService.joinProcessTaskGroup(
        { processTaskGroupId },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(
              new Error(
                `couldn't join ProcessTaskGroup "${processTaskGroupId}"`
              )
            );
          }
        }
      );
    });
  }

  public async leaveProcessTaskGroup(
    processTaskGroupId: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socketService.leaveProcessTaskGroup(
        { processTaskGroupId },
        (response) => {
          if (response.success) {
            resolve();
          } else {
            reject(
              new Error(
                `couldn't leave ProcessTaskGroup "${processTaskGroupId}"`
              )
            );
          }
        }
      );
    });
  }

  public async joinDefect(defectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socketService.joinDefect({ defectId }, (response) => {
        if (response.success) {
          resolve();
        } else {
          reject(new Error(`couldn't join DefectGroup "${defectId}"`));
        }
      });
    });
  }

  public async leaveDefect(defectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.socketService.leaveDefect({ defectId }, (response) => {
        if (response.success) {
          resolve();
        } else {
          reject(new Error(`couldn't leave DefectGroup "${defectId}"`));
        }
      });
    });
  }
}
