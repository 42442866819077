import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationActionStatusEntityInfo } from 'common/Types/Entities/ProcessConfigurationActionStatus/createProcessConfigurationActionStatusEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationActionStatus } from './types';

export const processConfigurationActionStatusEntityInfo =
  createProcessConfigurationActionStatusEntityInfo<ProcessConfigurationActionStatus>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
