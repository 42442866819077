import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { SearchUtils } from '../../classes/Utils/SearchUtils';

export class ThingGroupSearch {
  private readonly entityManager: AppEntityManager;

  constructor({ entityManager }: { entityManager: AppEntityManager }) {
    this.entityManager = entityManager;
  }

  public search(options: SearchOptions): Array<ThingGroup> {
    const preparedName = SearchUtils.prepareSearchTerm(options.name);
    const preparedStreetName = SearchUtils.prepareSearchTerm(
      options.streetName
    );
    const preparedZip = SearchUtils.prepareSearchTerm(options.zip);

    const prefilteredThingGroups = this.getPrefilteredThingGroups({
      thingGroupIdsToExclude: options.thingGroupIdsToExclude,
      userGroupId: options.userGroupId
    });

    if (preparedStreetName && preparedZip) {
      return this.filterThingGroupsByStreetNameAndZip({
        preparedStreetName,
        preparedZip,
        prefilteredThingGroups
      });
    }

    if (preparedName) {
      const thingGroups = this.filterThingGroupsByName({
        preparedName,
        prefilteredThingGroups
      });

      if (thingGroups.length < 5) {
        return thingGroups;
      }
    }

    return [];
  }

  private getPrefilteredThingGroups({
    thingGroupIdsToExclude,
    userGroupId
  }: Pick<
    SearchOptions,
    'userGroupId' | 'thingGroupIdsToExclude'
  >): PrefilteredThingGroups {
    return this.entityManager.thingGroupRepository
      .getAll()
      .filter((thingGroup) => {
        if (thingGroupIdsToExclude.has(thingGroup.id)) {
          return false;
        }

        if (thingGroup.ownerUserGroupId !== userGroupId) {
          return false;
        }

        return true;
      });
  }

  private filterThingGroupsByStreetNameAndZip({
    preparedStreetName,
    preparedZip,
    prefilteredThingGroups
  }: {
    preparedStreetName: string;
    preparedZip: string;
    prefilteredThingGroups: PrefilteredThingGroups;
  }): Array<ThingGroup> {
    return prefilteredThingGroups.filter((thingGroup) => {
      if (
        preparedStreetName !==
        SearchUtils.prepareSearchTerm(thingGroup.streetName)
      ) {
        return false;
      }

      if (preparedZip !== SearchUtils.prepareSearchTerm(thingGroup.zip)) {
        return false;
      }

      return true;
    });
  }

  private filterThingGroupsByName({
    preparedName,
    prefilteredThingGroups
  }: {
    preparedName: string;
    prefilteredThingGroups: PrefilteredThingGroups;
  }): Array<ThingGroup> {
    return prefilteredThingGroups.filter((thingGroup) => {
      if (preparedName !== SearchUtils.prepareSearchTerm(thingGroup.name)) {
        return false;
      }

      return true;
    });
  }
}

export type SearchOptions = {
  name: string | null;
  streetName: string | null;
  zip: string | null;
  userGroupId: string;
  thingGroupIdsToExclude: Set<string>;
};

/**
 * thing groups filtered by the ownerUserGroupId and the thingGroupIdsToExclude
 */
type PrefilteredThingGroups = Array<ThingGroup>;
