import { bindable, autoinject, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';
import {
  UserDefinedEntityConfigPropertyConfigCustomColumn,
  UserDefinedEntityConfigPropertyConfigCustomColumnPermission
} from 'common/Types/Entities/UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigDto';

import { MomentInput } from 'moment';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserDefinedEntity } from '../../classes/EntityManager/entities/UserDefinedEntity/types';
import { UserDefinedEntityConfigPropertyConfig } from '../../classes/EntityManager/entities/UserDefinedEntityConfigPropertyConfig/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Property } from '../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';

@autoinject()
export class UserDefinedEntityListItem {
  @bindable public userDefinedEntity: UserDefinedEntity | null = null;

  @bindable public editable = false;

  @bindable public showCustomColumns = false;

  private panelOpen = false;

  private domElement: HTMLElement;

  private listItemElement: HTMLElement | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    element: Element
  ) {
    this.domElement = element as HTMLElement;
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't UserDefinedEntityListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleUserDefinedEntityDetailActionClick(): void {
    DomEventHelper.fireEvent<UserDefinedEntityDetailActionEvent>(
      this.domElement,
      {
        name: 'user-defined-entity-detail-action-clicked',
        detail: null
      }
    );
  }

  protected handleDeleteUserDefinedEntity(): void {
    const entity = this.userDefinedEntity;
    assertNotNullOrUndefined(
      entity,
      "can't UserDefinedEntityListItem.handleDeleteUserDefinedEntity without an entity"
    );

    void Dialogs.deleteEntityDialog(entity).then(() => {
      this.entityManager.userDefinedEntityRepository.delete(entity);
    });
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected propertyInputFieldIsForcedDisabled(
    permission: UserDefinedEntityConfigPropertyConfigCustomColumnPermission
  ): boolean {
    return (
      permission ===
      UserDefinedEntityConfigPropertyConfigCustomColumnPermission.READ_ONLY
    );
  }

  @computedFrom('userDefinedEntity.userDefinedEntityConfigId')
  protected get entityConfigName(): string {
    return this.userDefinedEntity?.userDefinedEntityConfigId
      ? (this.entityManager.userDefinedEntityConfigRepository.getById(
          this.userDefinedEntity.userDefinedEntityConfigId
        )?.name ?? '')
      : '';
  }

  @computed(
    expression('userDefinedEntity'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig)
  )
  protected get firstCustomColumnProperty(): CustomColumnPropertyConfig | null {
    return this.getPropertyOfCustomColumn(
      UserDefinedEntityConfigPropertyConfigCustomColumn.FIRST
    );
  }

  @computed(
    expression('userDefinedEntity'),
    model(EntityName.UserDefinedEntityConfigPropertyConfig)
  )
  protected get secondCustomColumnProperty(): CustomColumnPropertyConfig | null {
    return this.getPropertyOfCustomColumn(
      UserDefinedEntityConfigPropertyConfigCustomColumn.SECOND
    );
  }

  private getPropertyOfCustomColumn(
    customColumn: UserDefinedEntityConfigPropertyConfigCustomColumn
  ): CustomColumnPropertyConfig | null {
    if (!this.userDefinedEntity) return null;
    const propertiesOfEntityConfig = this.getPropertiesOfEntityConfig();
    const customColumnPropertyConfig = propertiesOfEntityConfig.find(
      (x) => x.customColumn === customColumn
    );
    if (!customColumnPropertyConfig) return null;

    const propertiesOfEntity =
      this.entityManager.propertyRepository.getByUserDefinedEntityId(
        this.userDefinedEntity.id
      );
    return {
      property:
        propertiesOfEntity.find((p) =>
          PropertyHelper.isTheSameProperty(p, {
            type: customColumnPropertyConfig.type,
            name: customColumnPropertyConfig.name
          })
        ) ?? null,
      permission:
        customColumnPropertyConfig.customColumnPermission ??
        UserDefinedEntityConfigPropertyConfigCustomColumnPermission.READ_ONLY
    };
  }

  private getPropertiesOfEntityConfig(): Array<UserDefinedEntityConfigPropertyConfig> {
    const entityConfigId = this.userDefinedEntity?.userDefinedEntityConfigId;
    if (!entityConfigId) return [];

    const entityConfig =
      this.entityManager.userDefinedEntityConfigRepository.getById(
        entityConfigId
      );
    if (!entityConfig) return [];

    return this.entityManager.userDefinedEntityConfigPropertyConfigRepository.getByUserDefinedEntityConfigId(
      entityConfig.id
    );
  }
}

export type UserDefinedEntityDetailActionEvent = NamedCustomEvent<
  'user-defined-entity-detail-action-clicked',
  null
>;

type CustomColumnPropertyConfig = {
  property: Property | null;
  permission: UserDefinedEntityConfigPropertyConfigCustomColumnPermission;
};
