import { autoinject } from 'aurelia-framework';
import { EntityInfoPathHandler } from '@record-it-npm/synchro-common';

import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { PermissionHelper } from '../../../PermissionHelper';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManagerEntityTypesByEntityName } from '../AppEntityManagerEntityTypesByEntityName';
import { EntityName } from '../types';
import { Thing } from './types';
import { AppEntityManager } from '../AppEntityManager';
import { Project } from '../Project/types';
import { CurrentUserService } from '../User/CurrentUserService';
import { User } from '../User/types';

@autoinject()
export class ThingChangeService {
  private subscriptionManager: SubscriptionManager;

  private currentUser: User | null = null;

  private projectIdsSet: Set<string> = new Set();

  private rateLimitedUpdateForProjectIdsFunction = Utils.rateLimitFunction(
    this.updateLastEditedByUserIdForProjectIds.bind(this),
    200
  );

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscribeToThingChangedEvents();
    this.subscribeToSubentityChangedEvent(
      EntityName.Project,
      this.updateLastEditedByUserIdForProject.bind(this)
    );

    const referenceInfos =
      this.entityManager.entityRepositoryContainer.getEntityNameReferenceInfos(
        EntityName.Project
      );
    for (const referenceInfo of referenceInfos) {
      if (!referenceInfo.fieldInfo.relativeSynchronization) {
        continue;
      }

      const pathHandler = new EntityInfoPathHandler({
        entityInfo: referenceInfo.repository.getEntityInfo(),
        path: referenceInfo.fieldInfo.path
      });

      this.subscribeToSubentityChangedEvent(
        referenceInfo.entityName,
        (entity) => {
          pathHandler.forEachValue({
            data: entity,
            callback: ({ value }) =>
              this.updateLastEditedByUserIdForOwnerProjectEntity(value)
          });
        }
      );
    }

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();
  }

  public async flush(): Promise<void> {
    this.subscriptionManager.flush();
    this.rateLimitedUpdateForProjectIdsFunction.callImmediatelyIfPending();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
  }

  private subscribeToThingChangedEvents(): void {
    this.subscriptionManager.addDisposable(
      this.entityManager.thingRepository.registerHooks({
        afterEntityUpdated: this.updateLastEditedByUserIdForThing.bind(this),
        afterEntityDeleted: this.updateLastEditedByUserIdForThing.bind(this),
        beforeEntityCreated: this.handleBeforeThingCreated.bind(this)
      })
    );
  }

  private subscribeToSubentityChangedEvent<TEntityName extends EntityName>(
    entityName: TEntityName,
    callback: (
      entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    ) => void
  ): void {
    const repository =
      this.entityManager.entityRepositoryContainer.getByEntityName(entityName);
    this.subscriptionManager.addDisposable(
      repository.registerHooks({
        afterEntityUpdated: callback,
        afterEntityDeleted: callback,
        afterEntityCreated: callback
      })
    );
  }

  private updateLastEditedByUserIdForThing(thing: Thing): void {
    this.changeLastEditedByUserId(thing, () => {
      this.entityManager.thingRepository.update(thing);
    });
  }

  private handleBeforeThingCreated(thing: Thing): void {
    // we are not allowed to call update here, so this is a special case
    this.changeLastEditedByUserId(thing, () => {});
  }

  private updateLastEditedByUserIdForProject(project: Project): void {
    this.projectIdsSet.add(project.id);
    this.rateLimitedUpdateForProjectIdsFunction();
  }

  private updateLastEditedByUserIdForOwnerProjectEntity(
    ownerProjectId: string | null
  ): void {
    if (!ownerProjectId) return;

    this.projectIdsSet.add(ownerProjectId);
    this.rateLimitedUpdateForProjectIdsFunction();
  }

  private updateLastEditedByUserIdForProjectIds(): void {
    const thingIdsSet: Set<string> = new Set();
    for (const projectId of this.projectIdsSet) {
      const project = this.entityManager.projectRepository.getById(projectId);
      if (!project) continue;

      thingIdsSet.add(project.thing);
    }

    for (const thingId of thingIdsSet) {
      const thing = this.entityManager.thingRepository.getById(thingId);
      if (!thing) continue;

      this.changeLastEditedByUserId(thing, () => {
        this.entityManager.thingRepository.update(thing);
      });
    }
    this.projectIdsSet.clear();
  }

  private changeLastEditedByUserId(
    thing: Thing,
    afterLastEditedbyUserIdSet: () => void
  ): void {
    if (!this.currentUser) return;

    const userGroups =
      this.entityManager.userGroupRepository.getEditableGroupsForUser(
        this.currentUser
      );
    if (
      !PermissionHelper.userCanEditOwnerUserGroupIdEntity(
        thing,
        this.currentUser,
        userGroups
      )
    )
      return;

    if (this.currentUser.id !== thing.lastEditedByUserId) {
      thing.lastEditedByUserId = this.currentUser.id;
      afterLastEditedbyUserIdSet();
    }
  }
}
