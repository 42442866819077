import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { EditThingDialog } from '../../dialogs/edit-thing-dialog/edit-thing-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskGroupUtils } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupUtils';
import { Dialogs } from '../../classes/Dialogs';
import { EditThingGroupDialog } from '../../dialogs/edit-thing-group-dialog/edit-thing-group-dialog';
import { SendOperationsEmailDialog } from '../send-operations-email-dialog/send-operations-email-dialog';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { CreateFollowUpProcessTaskAppointmentDialog } from '../create-follow-up-process-task-appointment-dialog/create-follow-up-process-task-appointment-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ConfirmClickedEvent } from '../../personComponents/person-confirm-select/person-confirm-select';
import { TEditPropertyClickedEvent } from './process-task-info-overview-properties';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { ProcessConfigurationFollowUpAppointment } from '../../classes/EntityManager/entities/ProcessConfigurationFollowUpAppointment/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { computed } from '../../hooks/computed';
import { arrayChanges, expression, model } from '../../hooks/dependencies';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event {import('./process-task-info-overview-properties').TEditPropertyClickedEvent} edit-property-clicked
 * @event edit-action-status-clicked
 */
@autoinject()
export class ProcessTaskInfoOverview {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  /**
   * all process tasks in the processTaskGroup
   */
  @bindable()
  public availableProcessTasks: Array<ProcessTask> = [];

  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected readonly processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private domElement: HTMLElement;
  private subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  private thingGroup: ThingGroup | null = null;

  protected processTaskGroupThingGroupOwnerPerson: Person | null = null;
  protected processTaskGroupOfferReceiverPerson: Person | null = null;
  protected processTaskGroupInvoiceReceiverPerson: Person | null = null;

  private processTaskThing: Thing | null = null;
  protected processTaskContactPerson: Person | null = null;
  protected followUpAppointments: Array<ProcessConfigurationFollowUpAppointment> =
    [];

  protected thingGroupOwnerPersonCategory: string | null = null;

  protected ThingGroupHelper = ThingGroupHelper;
  protected ProcessTaskGroupUtils = ProcessTaskGroupUtils;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingwService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;

    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskInfoOverview,
        propertyName: 'processTaskGroup'
      });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskInfoOverview,
        propertyName: 'processTask'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingwService.bindSettingProperty(
        'general.thingGroupOwnerPersonCategory',
        (thingGroupOwnerPersonCategory) => {
          this.thingGroupOwnerPersonCategory = thingGroupOwnerPersonCategory;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      () => {
        this.updateProcessTaskGroupThingGroupOwnerPerson();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateProcessTaskContactPerson.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      () => {
        this.updateThingGroup();
      }
    );
    this.updateThingGroup();

    this.subscriptionManager.subscribeToModelChanges(EntityName.Person, () => {
      this.updateProcessTaskGroupThingGroupOwnerPerson();
      this.updateProcessTaskGroupOfferReceiverPerson();
      this.updateProcessTaskGroupInvoiceReceiverPerson();
    });
    this.updateProcessTaskGroupThingGroupOwnerPerson();
    this.updateProcessTaskGroupOfferReceiverPerson();
    this.updateProcessTaskGroupInvoiceReceiverPerson();

    this.subscriptionManager.subscribeToModelChanges(EntityName.Thing, () => {
      this.updateProcessTaskThing();
    });
    this.updateProcessTaskThing();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationFollowUpAppointment,
      this.updateFollowUpAppointments.bind(this)
    );
    this.updateFollowUpAppointments();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(expression('processConfiguration.contactPersonPurposes'))
  protected get allowMultipleContactPersons(): boolean {
    return !!this.processConfiguration?.contactPersonPurposes?.length;
  }

  @computed(
    arrayChanges('processTaskGroup.contactPersons'),
    model(EntityName.Person)
  )
  protected get processTaskGroupContactPerson(): Person | null {
    if (
      this.processTaskGroup &&
      this.processTaskGroup.contactPersons[0]?.personId
    ) {
      return this.entityManager.personRepository.getById(
        this.processTaskGroup.contactPersons[0].personId
      );
    } else {
      return null;
    }
  }

  @computed(
    expression('processConfiguration.processTaskGroupRelations.enabled')
  )
  protected get showRelationsWidget(): boolean {
    return !!this.processConfiguration?.processTaskGroupRelations?.enabled;
  }

  protected processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.updateThingGroup();
      this.updateProcessTaskGroupThingGroupOwnerPerson();
      this.updateProcessTaskGroupOfferReceiverPerson();
      this.updateProcessTaskGroupInvoiceReceiverPerson();
      this.updateFollowUpAppointments();
    }
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProcessTaskThing();
    }
  }

  private updateThingGroup(): void {
    if (this.processTaskGroup) {
      this.thingGroup = this.entityManager.thingGroupRepository.getById(
        this.processTaskGroup.thingGroupId
      );
    } else {
      this.thingGroup = null;
    }
  }

  private updateProcessTaskGroupThingGroupOwnerPerson(): void {
    if (
      this.processTaskGroup &&
      this.processTaskGroup.thingGroupOwnerPersonId
    ) {
      this.processTaskGroupThingGroupOwnerPerson =
        this.entityManager.personRepository.getById(
          this.processTaskGroup.thingGroupOwnerPersonId
        );
    } else {
      this.processTaskGroupThingGroupOwnerPerson = null;
    }
  }

  private updateProcessTaskGroupOfferReceiverPerson(): void {
    if (this.processTaskGroup && this.processTaskGroup.offerReceiverPersonId) {
      this.processTaskGroupOfferReceiverPerson =
        this.entityManager.personRepository.getById(
          this.processTaskGroup.offerReceiverPersonId
        );
    } else {
      this.processTaskGroupOfferReceiverPerson = null;
    }
  }

  private updateProcessTaskGroupInvoiceReceiverPerson(): void {
    if (
      this.processTaskGroup &&
      this.processTaskGroup.invoiceReceiverPersonId
    ) {
      this.processTaskGroupInvoiceReceiverPerson =
        this.entityManager.personRepository.getById(
          this.processTaskGroup.invoiceReceiverPersonId
        );
    } else {
      this.processTaskGroupInvoiceReceiverPerson = null;
    }
  }

  private updateProcessTaskThing(): void {
    if (this.processTask) {
      this.processTaskThing = this.entityManager.thingRepository.getById(
        this.processTask.thingId
      );
    } else {
      this.processTaskThing = null;
    }

    this.updateProcessTaskContactPerson();
  }

  private updateProcessTaskContactPerson(): void {
    if (this.processTaskThing) {
      const thingToPerson =
        this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
          this.processTaskThing.id
        );
      this.processTaskContactPerson = thingToPerson
        ? this.entityManager.personRepository.getById(thingToPerson.personId)
        : null;
    } else {
      this.processTaskContactPerson = null;
    }
  }

  private updateFollowUpAppointments(): void {
    if (this.processTaskGroup) {
      this.followUpAppointments =
        this.entityManager.processConfigurationFollowUpAppointmentRepository.getByProcessConfigurationId(
          this.processTaskGroup.processConfigurationId
        );
    } else {
      this.followUpAppointments = [];
    }
  }

  protected handleEditProcessTaskThingClick(): void {
    if (!this.processTaskThing) {
      return;
    }

    void EditThingDialog.open({
      thing: this.processTaskThing,
      thingGroupSelectionEnabled: false
    });
  }

  protected handleProcessTaskGroupChanged(): void {
    if (this.processTaskGroup) {
      this.entityManager.processTaskGroupRepository.update(
        this.processTaskGroup
      );
    }
  }

  protected handleEditThingGroupClick(): void {
    if (!this.thingGroup) {
      return;
    }

    void EditThingGroupDialog.open({
      thingGroup: this.thingGroup
    });
  }

  protected canStartContactPersonSelection(): boolean {
    if (!this.processTaskGroup?.thingGroupOwnerPersonId) {
      void Dialogs.errorDialogTk(
        'operations.processTaskInfoOverview.noThingGroupOwnerPersonId.title',
        'operations.processTaskInfoOverview.noThingGroupOwnerPersonId.text'
      );
      return false;
    }
    return true;
  }

  protected handleProcessTaskGroupOwnerPersonChanged(
    event: ConfirmClickedEvent
  ): void {
    if (this.processTaskGroup) {
      this.processTaskGroup.thingGroupOwnerPersonId = event.detail.personId;
      this.entityManager.processTaskGroupRepository.update(
        this.processTaskGroup
      );
      this.updateProcessTaskGroupThingGroupOwnerPerson();
    }
  }

  protected handleProcessTaskGroupContactPersonChanged(
    event: ConfirmClickedEvent
  ): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      'cannot ProcessTaskGroupMultipleContactPersonsWidget.handleAddButtonClicked without processTaskGroup'
    );
    /* If the configuration is set to support multiple persons and later is changed to only allow one person,
    we could have the case that there are still multiple persons referenced in processTaskGroup.contactPersons,
    but the frontend only allows to set/unset one person.
    Therefore, in this method we operate on element [0] only and keep all other referenced persons untouched,
    so we don't lose any data. */

    this.processTaskGroup.contactPersons.shift();
    this.processTaskGroup.contactPersons.unshift({
      personId: event.detail.personId,
      purposeOfPerson: null
    });

    this.entityManager.processTaskGroupRepository.update(this.processTaskGroup);
  }

  protected handleProcessTaskGroupOfferReceiverPersonConfirm(
    event: ConfirmClickedEvent
  ): void {
    if (this.processTaskGroup) {
      this.processTaskGroup.offerReceiverPersonId = event.detail.personId;
      this.entityManager.processTaskGroupRepository.update(
        this.processTaskGroup
      );
      this.updateProcessTaskGroupOfferReceiverPerson();
    }
  }

  protected handleProcessTaskGroupInvoiceReceiverPersonConfirm(
    event: ConfirmClickedEvent
  ): void {
    if (this.processTaskGroup) {
      this.processTaskGroup.invoiceReceiverPersonId = event.detail.personId;
      this.entityManager.processTaskGroupRepository.update(
        this.processTaskGroup
      );
      this.updateProcessTaskGroupInvoiceReceiverPerson();
    }
  }

  protected handleEmailClick(contact: PersonContact): void {
    if (!this.processTask) {
      return;
    }

    void SendOperationsEmailDialog.open({
      contact: contact,
      processTask: this.processTask
    });
  }

  protected handleEditPropertyClicked(event: TEditPropertyClickedEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-property-clicked',
      detail: event.detail
    });
  }

  protected handleEditActionStatusClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'edit-action-status-clicked',
      detail: null
    });
  }

  protected handleFollowUpAppointmentButtonClick(
    followUpAppointment: ProcessConfigurationFollowUpAppointment
  ): void {
    if (!this.processTask) {
      throw new Error(
        "can't create a followUpAppointment without a processTask"
      );
    }

    void CreateFollowUpProcessTaskAppointmentDialog.open({
      appointment: null,
      processTask: this.processTask,
      processConfigurationFollowUpAppointment: followUpAppointment
    });
  }

  protected getFollowUpButtonStyle(
    followUpAppointment: ProcessConfigurationFollowUpAppointment
  ): Record<string, string> {
    const style: Record<string, string> = {};

    if (followUpAppointment.color) {
      style['background-color'] = followUpAppointment.color;
    }

    return style;
  }

  @computedFrom(
    'processConfiguration.showProcessTaskInfoSections.referenceCode'
  )
  protected get showReferenceCode(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.referenceCode ??
      true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskInfoSections.assignee')
  protected get showAssignee(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.assignee ?? true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskInfoSections.notes')
  protected get showNotes(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.notes ?? true
    );
  }

  @computedFrom(
    'processConfiguration.showProcessTaskInfoSections.followUpButtons'
  )
  protected get showFollowupButtons(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.followUpButtons ??
      true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskInfoSections.persons')
  protected get showPersons(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.persons ?? true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskInfoSections.thingInfo')
  protected get showThingInfo(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.thingInfo ?? true
    );
  }

  @computedFrom(
    'processConfiguration.showProcessTaskInfoSections.processTaskList'
  )
  protected get showProcessTaskList(): boolean {
    return (
      this.processConfiguration?.showProcessTaskInfoSections?.processTaskList ??
      true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskFeatures.actionStatus')
  protected get showActionStatus(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.actionStatus ?? true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskFeatures.contactPerson')
  protected get showContactPerson(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.contactPerson ?? true
    );
  }

  @computedFrom(
    'processConfiguration.showProcessTaskFeatures.offersAndInvoices'
  )
  protected get showOffersAndInvoices(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.offersAndInvoices ??
      true
    );
  }

  @computedFrom('processConfiguration.showProcessTaskFeatures.ownerPerson')
  protected get showOwnerPerson(): boolean {
    return (
      this.processConfiguration?.showProcessTaskFeatures?.ownerPerson ?? true
    );
  }
}
