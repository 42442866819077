import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationAuthorizationType } from './types';

export class ProcessConfigurationAuthorizationTypeRepository extends AppEntityRepository<EntityName.ProcessConfigurationAuthorizationType> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationAuthorizationType> {
    return this.getAll().filter(
      (type) => type.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
