import { PatchLoadedEntityOptions } from '@record-it-npm/synchro-client';
import { ThingPictureIdPatcher } from 'common/ThingPictureIdPatcher/ThingPictureIdPatcher';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  DefectCommentPicture,
  DefectPicture,
  EntryPicture,
  Picture,
  PictureCreationEntity,
  PictureEntityIdField,
  ProjectPicture,
  PropertyPicture,
  TitleThingPicture,
  GalleryThingPicture,
  GlobalThingPicture,
  ThingSectionPicture,
  PictureSubEntityField,
  ProjectQuestionPicture
} from './types';

export class PictureRepository extends AppEntityRepository<EntityName.Picture> {
  /**
   * TODO: provide a fully typed typing for the options
   * currently it's possible to create a picture for an entry without a ownerProjectId e.g.
   * this needs some better validation in the future
   */
  public createPictureForEntity(
    options: CreatePictureForEntityOptions,
    creationEntity?: Partial<PictureCreationEntity>
  ): Picture {
    return this.create({
      coords: null,
      ...creationEntity,
      ...this.getPictureEntityIdFields(options)
    } as PictureCreationEntity);
  }

  public getSelectedEntryPicture(entryId: string): EntryPicture | null {
    return this.getSelectedPictureByEntityId(
      'entry',
      entryId
    ) as EntryPicture | null;
  }

  public getSelectedProjectTitlePicture(
    projectId: string
  ): ProjectPicture | null {
    return this.getSelectedPictureByEntityId(
      'picture_of_project',
      projectId
    ) as ProjectPicture | null;
  }

  public getSelectedTitleThingPicture(
    thingId: string
  ): TitleThingPicture | null {
    return this.getSelectedPictureByEntityId(
      'titleThingId',
      thingId
    ) as TitleThingPicture | null;
  }

  public getSelectedPictureByEntityId(
    idField: PictureEntityIdField,
    entityId: string
  ): Picture | null {
    return (
      this.getAll().find((picture) => {
        return picture[idField] === entityId && picture.selected;
      }) ?? null
    );
  }

  public getByEntryId(entryId: string): Array<EntryPicture> {
    return this.getByEntityId('entry', entryId) as Array<EntryPicture>;
  }

  public getByPropertyId(propertyId: string): Array<PropertyPicture> {
    return this.getByEntityId('property', propertyId) as Array<PropertyPicture>;
  }

  public getByOwnerDefectId(defectId: string): Array<DefectPicture> {
    return this.getByEntityId(
      'ownerDefectId',
      defectId
    ) as Array<DefectPicture>;
  }

  public getByDefectId(defectId: string): Array<DefectPicture> {
    return this.getByEntityId(
      'ownerDefectId',
      defectId,
      'defect',
      defectId
    ) as Array<DefectPicture>;
  }

  public getByDefectCommentId(
    defectId: string,
    defectCommentId: string
  ): Array<DefectCommentPicture> {
    return this.getByEntityId(
      'ownerDefectId',
      defectId,
      'defectComment',
      defectCommentId
    ) as Array<DefectCommentPicture>;
  }

  public getByGalleryThingId(thingId: string): Array<GalleryThingPicture> {
    return this.getByEntityId(
      'galleryThingId',
      thingId
    ) as Array<GalleryThingPicture>;
  }

  public getByGlobalThingId(thingId: string): Array<GlobalThingPicture> {
    return this.getByEntityId(
      'globalThingId',
      thingId
    ) as Array<GlobalThingPicture>;
  }

  public getByThingSectionId(
    thingSectionId: string
  ): Array<ThingSectionPicture> {
    return this.getByEntityId(
      'thingSectionId',
      thingSectionId
    ) as Array<ThingSectionPicture>;
  }

  public getByTitleThingId(thingId: string): Array<TitleThingPicture> {
    return this.getByEntityId(
      'ownerThingId',
      thingId,
      'titleThingId',
      thingId
    ) as Array<TitleThingPicture>;
  }

  public getThingSectionPicturesByThingId(
    thingId: string
  ): Array<ThingSectionPicture> {
    return this.getAll().filter((picture): picture is ThingSectionPicture => {
      return !!picture.thingSectionId && picture.ownerThingId === thingId;
    });
  }

  /**
   * returns all pictures which are directly assigned to the project (basically the title pictures)
   * if you want to get all pictures which are in the project, look at {@link getAllPicturesByProjectId}
   */
  public getByProjectId(projectId: string): Array<ProjectPicture> {
    return this.getByEntityId(
      'picture_of_project',
      projectId
    ) as Array<ProjectPicture>;
  }

  /**
   * returns all pictures which are directly assigned to a project question
   * if you want to get all pictures which are in the project, look at {@link getAllPicturesByProjectId}
   */
  public getByProjectQuestionId(
    projectQuestionId: string
  ): Array<ProjectQuestionPicture> {
    return this.getByEntityId(
      'projectQuestionId',
      projectQuestionId
    ) as Array<ProjectQuestionPicture>;
  }

  /**
   * returns all pictures which are part of this project, e.g. also pictures of entries which are in this project
   * if you only want the pictures which are directly assigned to the project, look at {@link getByProjectId}
   */
  public getAllPicturesByProjectId(projectId: string): Array<Picture> {
    return this.getByEntityId('project', projectId);
  }

  /**
   * returns all pictures which are part of these projects, e.g. also pictures of entries which are in projects
   */
  public getAllPicturesByProjectIds(projectIds: Array<string>): Array<Picture> {
    return projectIds
      .map((pId) => this.getByEntityId('ownerProjectId', pId))
      .flat();
  }

  public getByEntityId(
    idField: PictureEntityIdField,
    entityId: string
  ): Array<Picture>;

  public getByEntityId(
    idField: PictureEntityIdField,
    entityId: string,
    subEntityField: PictureSubEntityField | undefined | null,
    subEntityValue: any
  ): Array<Picture>;

  public getByEntityId(
    idField: PictureEntityIdField,
    entityId: string,
    subEntityField?: PictureSubEntityField | undefined | null,
    subEntityValue?: any
  ): Array<Picture> {
    return this.getAll()
      .filter((picture) => {
        return (
          picture[idField] === entityId &&
          (!subEntityField || picture[subEntityField] === subEntityValue)
        );
      })
      .sort((a, b) => {
        const aSequenceNumber =
          a.sequence_number != null
            ? a.sequence_number
            : Number.MAX_SAFE_INTEGER;
        const bSequenceNumber =
          b.sequence_number != null
            ? b.sequence_number
            : Number.MAX_SAFE_INTEGER;
        return aSequenceNumber - bSequenceNumber;
      });
  }

  public getSelectedPicturesByEntityId(
    idField: PictureEntityIdField,
    entityId: string
  ): Array<Picture>;

  public getSelectedPicturesByEntityId(
    idField: PictureEntityIdField,
    entityId: string,
    subEntityField: PictureSubEntityField,
    subEntityValue: any
  ): Array<Picture>;

  public getSelectedPicturesByEntityId(
    idField: PictureEntityIdField,
    entityId: string,
    subEntityField?: PictureSubEntityField,
    subEntityValue?: any
  ): Array<Picture> {
    return this.getByEntityId(
      idField,
      entityId,
      subEntityField,
      subEntityValue
    ).filter((p) => p.selected);
  }

  /**
   * unsets selected of all pictures and then sets the selected of the picture
   *
   * pictures is an array of all pictures in the same scope as picture (i.e. all pictures of the project)
   */
  public setSelectedPicture(picture: Picture, pictures: Array<Picture>): void {
    for (const p of pictures) {
      if (p.selected && p !== picture) {
        p.selected = false;
        this.update(p);
      }
    }

    picture.selected = true;
    this.update(picture);
  }

  protected patchLoadedEntity(
    options: PatchLoadedEntityOptions<Picture>
  ): void {
    super.patchLoadedEntity(options);
    ThingPictureIdPatcher.patch(options.entity);
  }

  private getPictureEntityIdFields(
    options: CreatePictureForEntityOptions
  ): Partial<PictureCreationEntity> & { ownerUserGroupId: string } {
    const fields: Partial<PictureCreationEntity> & {
      ownerUserGroupId: string;
    } = {
      [options.mainEntityIdField]: options.mainEntityId,
      ownerUserGroupId: options.ownerUserGroupId
    };

    if (options.ownerProjectId) {
      fields.ownerProjectId = options.ownerProjectId;
    }

    if (options.subEntityField) {
      (fields as Record<string, any>)[options.subEntityField] =
        options.subEntityValue;
    }

    this.patchDeprecatedProjectField(fields);

    return fields;
  }

  /**
   * @param creationEntity - gets modified in place
   */
  private patchDeprecatedProjectField(
    creationEntity: Partial<PictureCreationEntity>
  ): void {
    if (creationEntity.ownerProjectId && !creationEntity.project) {
      creationEntity.project = creationEntity.ownerProjectId;
    }

    if (creationEntity.project && !creationEntity.ownerProjectId) {
      creationEntity.ownerProjectId = creationEntity.project;
    }
  }
}

export type CreatePictureForEntityOptions = {
  mainEntityIdField: PictureEntityIdField;
  mainEntityId: string;
  subEntityField?: PictureSubEntityField | null | undefined;
  subEntityValue?: any;
  ownerUserGroupId: string;
  ownerProjectId?: string | null | undefined;
};
