import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

/**
 * this is meant to be a single global instance
 */
@autoinject()
export class EditThingDialog {
  private thing: Thing | null = null;
  private onDialogClosed: ((thing: Thing) => void) | null = null;
  protected thingGroupSelectionEnabled = false;

  private dialog: RecordItDialog | null = null;

  private subscriptionManager: SubscriptionManager;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: EditThingDialogOpenOptions): void {
    this.thing = options.thing;
    this.onDialogClosed = options.onDialogClosed ?? null;
    this.thingGroupSelectionEnabled =
      options.thingGroupSelectionEnabled !== false;

    if (this.dialog) {
      this.dialog.open();
    }
  }

  public close(): void {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  protected handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed; // caching so we can reset everything before calling the callback
    const thing = this.thing;

    this.thing = null;
    this.onDialogClosed = null;
    this.subscriptionManager.disposeSubscriptions();

    thing && onDialogClosed && onDialogClosed(thing);
  }

  protected handleThingChanged(): void {
    if (!this.thing) return;

    this.entityManager.thingRepository.update(this.thing);
  }

  public static async open(options: EditThingDialogOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

type EditThingDialogOpenOptions = {
  thing: Thing;

  /** gets called after the dialog has been completely closed */
  onDialogClosed?: ((thing: Thing) => void) | null;

  /** true by default */
  thingGroupSelectionEnabled?: boolean;
};
