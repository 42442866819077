import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';

/**
 * @event picture-clicked - TPictureClickedEvent
 */
@autoinject()
export class GalleryThingPictureSelector {
  @bindable public thing: Thing | null = null;

  private availablePictures: Array<Picture> = [];

  private subscriptionManager: SubscriptionManager;

  private domElement: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictures.bind(this)
    );
    this.updatePictures();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingChanged(): void {
    this.updatePictures();
  }

  private updatePictures(): void {
    if (this.thing?.id) {
      this.availablePictures =
        this.entityManager.pictureRepository.getByGalleryThingId(this.thing.id);
    } else {
      this.availablePictures = [];
    }
  }

  protected handlePictureClick(picture: Picture): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'picture-clicked',
      detail: {
        picture: picture
      }
    });
  }
}

export type TPictureClickedEvent = Event & { detail: { picture: Picture } };
