import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { ThingSubEntity } from '../Base/ThingSubEntity';
import { DateType, IdType } from '../Base/types';
import { PropertyOption, PropertyType } from '../Property/PropertyDto';

type FullThingSectionConfigProperty<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  ThingSubEntity<TId> & {
    name: string | null;
    type: PropertyType;
    order: number;
    choices: Array<string>;
    options: Array<PropertyOption>;
    /**
     * location where the property should be displayed
     * Either in the thing or in the project
     */
    location: ThingSectionConfigPropertyLocation;
  };

/**
 * A ThingSectionConfigProperty contains the configuration for a single property in a thing section
 */
export type ThingSectionConfigPropertyDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullThingSectionConfigProperty<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullThingSectionConfigProperty<
    TId,
    TDate
  > = never
> = Omit<
  OptionalProperties<
    FullThingSectionConfigProperty<TId, TDate>,
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export enum ThingSectionConfigPropertyLocation {
  THING = 'thing',
  PROJECT = 'project'
}
