import { bindable, autoinject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { EditProcessTaskAppointmentDialog } from '../edit-process-task-appointment-dialog/edit-process-task-appointment-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from 'src/classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from 'src/classes/SubscriptionManager';
import { ProcessTaskAppointment } from 'src/classes/EntityManager/entities/ProcessTaskAppointment/types';
import { User } from 'src/classes/EntityManager/entities/User/types';
import { ProcessTaskRecurringAppointment } from '../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';
import { EditProcessTaskRecurringAppointmentDialog } from '../edit-process-task-recurring-appointment-dialog/edit-process-task-recurring-appointment-dialog';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { SortedProcessTaskAppointmentsForProcessTaskIdComputer } from '../../computedValues/computers/SortedProcessTaskAppointmentsForProcessTaskIdComputer/SortedProcessTaskAppointmentsForProcessTaskIdComputer';
import { CreateProcessTaskAppointmentService } from '../../classes/EntityManager/entities/ProcessTaskAppointment/CreateProcessTaskAppointmentService';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskAppointmentsWidget {
  @bindable public processTask: ProcessTask | null = null;

  /**
   * null if appointments aren't loaded
   * read only
   */
  @bindable public processTaskAppointmentCount: number | null = null;

  protected sortedAppointments: Array<ProcessTaskAppointment> = [];
  protected availableRecurringAppointments: Array<ProcessTaskRecurringAppointment> =
    [];

  private isAttached = false;
  private currentUser: User | null = null;
  private appointmentIdToNavigateTo: string | null = null;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly computedValueService: ComputedValueService,
    private readonly createProcessTaskAppointmentService: CreateProcessTaskAppointmentService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      currentUserPropertyName: 'currentUser'
    });

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskAppointmentsWidget,
        propertyName: 'processTask'
      });
  }

  public navigateToAppointment(appointmentId: string): void {
    if (!this.isAttached) {
      this.appointmentIdToNavigateTo = appointmentId;
      return;
    }

    const appointment = this.sortedAppointments.find(
      (a) => a.id === appointmentId
    );

    if (!appointment) {
      throw new Error(`appointment with id "${appointmentId}" not found`);
    }

    this.scrollToAppointment(appointment);
    this.appointmentIdToNavigateTo = null;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskRecurringAppointment,
      this.updateAvailableRecurringAppointments.bind(this)
    );
    this.updateAvailableRecurringAppointments();

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          SortedProcessTaskAppointmentsForProcessTaskIdComputer,
        createComputeData: () =>
          this.processTask ? { processTaskId: this.processTask.id } : null,
        createUpdaters: (update) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.id',
            update
          );
        },
        callback: (sortedAppointments) => {
          this.sortedAppointments = sortedAppointments;
          this.processTaskAppointmentCount = sortedAppointments.length;
        }
      })
    );

    this.permissionBindingHandle.subscribe();

    if (this.appointmentIdToNavigateTo) {
      this.navigateToAppointment(this.appointmentIdToNavigateTo);
    }
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  // /////////// METHODS /////////////

  private editAppointment(appointment: ProcessTaskAppointment): void {
    void EditProcessTaskAppointmentDialog.open({
      appointment: appointment,
      onDialogClosed: () => {
        this.scrollToAppointment(appointment);
      }
    });
  }

  private editRecurringAppointment(
    appointment: ProcessTaskRecurringAppointment
  ): void {
    void EditProcessTaskRecurringAppointmentDialog.open({
      appointment: appointment
    });
  }

  private scrollToAppointment(appointment: ProcessTaskAppointment): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getAppointmentElementId(appointment.id),
      null,
      appointment,
      () => this.isAttached
    );
  }

  // /////////// OBSERVABLES /////////////

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableRecurringAppointments();
    }
  }

  // /////////// UPDATERS /////////////

  private updateAvailableRecurringAppointments(): void {
    if (this.processTask) {
      this.availableRecurringAppointments =
        this.entityManager.processTaskRecurringAppointmentRepository.getByProcessTaskId(
          this.processTask.id
        );
    }
  }

  // /////////// EVENT HANDLERS /////////////

  protected async handleAddAppointmentClick(): Promise<void> {
    if (!this.processTask || !this.currentUser) {
      return;
    }
    const appointment =
      await this.createProcessTaskAppointmentService.createAppointment(
        this.processTask,
        [],
        []
      );

    void this.processTaskLoggingService.logProcessTaskSubEntityCreated({
      entityName: EntityName.ProcessTaskAppointment,
      entity: appointment,
      displayNameAtLogTime: appointment.name
    });

    this.editAppointment(appointment);
  }

  protected handleAddRecurringAppointmentClick(): void {
    if (!this.processTask || !this.currentUser) {
      return;
    }

    const appointment =
      this.entityManager.processTaskRecurringAppointmentRepository.create({
        coordinatorUserId: this.currentUser.id,
        ownerUserGroupId: this.processTask.ownerUserGroupId,
        ownerProcessTaskGroupId: this.processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: this.processTask.id
      });

    this.editRecurringAppointment(appointment);
  }

  protected handleEditButtonClicked(appointment: ProcessTaskAppointment): void {
    this.editAppointment(appointment);
  }

  protected handleRecurringEditButtonClicked(
    appointment: ProcessTaskRecurringAppointment
  ): void {
    this.editRecurringAppointment(appointment);
  }

  // /////////// HTML HELPERS /////////////

  protected getAppointmentElementId(appointmentId: string): string {
    return 'process-task-appointments--appointment-' + appointmentId;
  }
}
