import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';

/**
 * @event {TSketchFinishedEvent} sketch-finished
 */
@autoinject()
export class DataUrlSketchButton {
  @bindable()
  public originalPictureDataUrl: string | null = null;

  @bindable()
  public sketchDataUrl: string | null = null;

  constructor(
    private readonly element: Element,
    private readonly pictureSketchingService: PictureSketchingService
  ) {}

  private handleSketchClick(): void {
    assertNotNullOrUndefined(
      this.originalPictureDataUrl,
      "can't StructureThingRapidFireWidget.handleSketchClick without a pictureDataUrl"
    );
    this.pictureSketchingService.sketchDataUrl(
      this.originalPictureDataUrl,
      this.sketchDataUrl,
      (data) => {
        DomEventHelper.fireEvent<TSketchFinishedEvent>(this.element, {
          name: 'sketch-finished',
          detail: {
            editedDataUrl: data.editedDataUrl,
            sketchDataUrl: data.sketchDataUrl
          }
        });
      }
    );
  }
}

export type TSketchFinishedEvent = NamedCustomEvent<
  'sketch-finished',
  { editedDataUrl: string; sketchDataUrl: string }
>;
