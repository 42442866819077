import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskPositionDetailEntry } from './types';

export class ProcessTaskPositionDetailEntryRepository extends AppEntityRepository<EntityName.ProcessTaskPositionDetailEntry> {
  public getByProcessTaskPositionId(
    processTaskPositionId: string
  ): Array<ProcessTaskPositionDetailEntry> {
    return this.getAll().filter(
      (detailEntry) =>
        detailEntry.processTaskPositionId === processTaskPositionId
    );
  }

  public getByOwnerProcessTaskId(
    ownerProcessTaskId: string
  ): Array<ProcessTaskPositionDetailEntry> {
    return this.getAll().filter(
      (detailEntry) => detailEntry.ownerProcessTaskId === ownerProcessTaskId
    );
  }
}
