import { autoinject, bindable } from 'aurelia-framework';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';

/**
 * 🕷 - This is Charly. Charly is a spider. Charly keeps the bugs away. Because Charly is a spider.
 */
@autoinject()
export class SendOperationsEmailDialogOfferSelect {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public selectedOfferIds: Array<string> = [];

  private readonly subscriptionManager: SubscriptionManager;
  private readonly categoryIdGetter = (
    offer: ProcessTaskOffer
  ): string | null => offer.processConfigurationCategoryId;

  private isAttached: boolean = false;
  private offers: Array<ProcessTaskOffer> = [];

  private readonly SequenceNumberUtils = SequenceNumberUtils;
  private readonly SequenceNumberType = SequenceNumberType;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateOffers.bind(this)
    );
    this.updateOffers();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateOffers();
    }
  }

  private updateOffers(): void {
    if (this.processTask) {
      const relations =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskId(
          this.processTask.id
        );
      const offerIds = relations.map((r) => r.processTaskOfferId);
      this.offers =
        this.entityManager.processTaskOfferRepository.getByIds(offerIds);
    } else {
      this.offers = [];
    }
  }

  private handleCheckedChanged(
    event: CustomCheckboxCheckedChangedEvent,
    offer: ProcessTaskOffer
  ): void {
    const index = this.selectedOfferIds.indexOf(offer.id);

    if (event.detail.checked) {
      if (index === -1) {
        this.selectedOfferIds.push(offer.id);
      }
    } else {
      if (index >= 0) {
        this.selectedOfferIds.splice(index, 1);
      }
    }
  }

  private offerIsSelected(
    offerId: string,
    selectedOfferIds: Array<string>,
    _selectedOfferIdsLength: number
  ): boolean {
    return selectedOfferIds.indexOf(offerId) >= 0;
  }
}
