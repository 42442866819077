import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ProcessTaskAppointmentDateInfo } from '../../../computedValues/computers/ProcessTaskAppointmentDateInfoMapComputer';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskAppointmentsWithWarningsDialogAppointment {
  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public dateInfo: ProcessTaskAppointmentDateInfo | null = null;

  @subscribableLifecycle()
  protected readonly appointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointment];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.appointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointment,
        context: this as ProcessTaskAppointmentsWithWarningsDialogAppointment,
        propertyName: 'appointment'
      });
  }

  protected handleMarkAppointmentAsFinishedClick(): void {
    assertNotNullOrUndefined(
      this.appointment,
      "can't ProcessTaskAppointmentsWithWarningsDialogAppointment.handleMarkAppointmentAsFinishedClick"
    );

    this.appointment.finishedAt = new Date().toISOString();
    this.entityManager.processTaskAppointmentRepository.update(
      this.appointment
    );

    DomEventHelper.fireEvent<ProcessTaskAppointmentsWithWarningsDialogAppointmentAppointmentFinishedEvent>(
      this.element,
      {
        name: 'appointment-finished',
        detail: null
      }
    );
  }
}

export type ProcessTaskAppointmentsWithWarningsDialogAppointmentAppointmentFinishedEvent =
  NamedCustomEvent<'appointment-finished', null>;
