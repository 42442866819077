/* global Msvg */
import { DelayedQueue } from '../../../classes/Queue/DelayedQueue';

export class ToolGrid {
  /** @type {number} */
  _desiredGridSize = 0;
  /** @type {(Msvg.Point|null)} */
  _highlightedPoint = null;

  /** @type {number} */
  _gridSize = 0;

  _enabled = false;

  /** @type {Msvg.Msvg} */
  _svgElement;
  /** @type {Array<TToolGridGridPoint>} */
  _gridPoints = [];
  _gridPointSize = 5;
  _gridPointWidth = 1;

  /**
   * the svgElement element for the grid to work on
   *
   * will draw the grid on there and also calculate the grid based on the svgElement
   * will also redraw on the svgElement, so give the grid its own svgElement
   *
   * @param {Msvg.Msvg} svgElement
   */
  constructor(svgElement) {
    this._svgElement = svgElement;

    this._gridRenderQueue = new DelayedQueue({
      workerFunction: this._renderGridPoint.bind(this),
      chunkSize: 150,
      timeout: 100
    });

    this.calculateGrid();
  }

  /**
   * size in pixel for the grid lines
   *
   * @param {number} size
   */
  setLineWidth(size) {
    this._lineWidth = Math.max(size, 1);
    this.calculateGrid();
  }

  /**
   * set to 0 to deactivate the grid
   *
   * @param {number} gridSize
   */
  setDesiredGridSize(gridSize) {
    this._desiredGridSize = gridSize;
    this.calculateGrid();
  }

  /**
   * @returns {number}
   */
  getDesiredGridSize() {
    return this._desiredGridSize;
  }

  /**
   *
   * @param {boolean} enabled
   */
  setEnabled(enabled) {
    this._enabled = enabled;
  }

  calculateGrid() {
    if (!this._svgElement) {
      return;
    }

    if (this._desiredGridSize !== 0) {
      const width = this._svgElement.getViewBox().width;
      const x = width / this._desiredGridSize;
      this._gridSize = this._roundNumber(width / Math.round(x));
    } else {
      this._gridSize = 0;
    }

    this.reDrawGrid();
  }

  /**
   *
   * approximationFactor should be between (bounds included) 0.1 and 1
   *
   * @param {Msvg.Point} point
   * @param {number} approximationFactor
   * @returns {boolean}
   */
  isNearGridPoint(point, approximationFactor) {
    approximationFactor /= 2; // conversion from percent into a comma value
    if (this._gridSize <= 30) {
      approximationFactor = 0.5; // grid is to small to use the factor
    }

    return (
      this._checkCoordinateIsCloseToGrid(point.x, approximationFactor) &&
      this._checkCoordinateIsCloseToGrid(point.y, approximationFactor)
    );
  }

  /**
   *   *
   * @param {number} coordinate
   * @param {number} approximationFactor
   * @returns {boolean}
   */
  _checkCoordinateIsCloseToGrid(coordinate, approximationFactor) {
    const divisionResult = coordinate / this._gridSize;
    let commaValue = divisionResult - Math.floor(divisionResult); // we only have the comma parts now
    commaValue = commaValue >= 0.5 ? 1 - commaValue : commaValue; // normalize the comma value into the percentual distance to the next point

    return commaValue === 0 || commaValue <= approximationFactor;
  }

  /**
   * align the point to the nearest grid point
   *
   * @param {Msvg.Point} point
   * @return {Msvg.Point}
   */
  alignPointToGrid(point) {
    return new Msvg.Point(
      Math.round(point.x / this._gridSize) * this._gridSize,
      Math.round(point.y / this._gridSize) * this._gridSize
    );
  }

  isEnabled() {
    return this._enabled && this._gridSize > 0;
  }

  /**
   *
   * @param {(Msvg.Point|null)} point
   */
  setHighlightedPoint(point) {
    if (point) {
      this._highlightedPoint = new Msvg.Point(
        this._roundNumber(point.x),
        this._roundNumber(point.y)
      );
    } else {
      this._highlightedPoint = null;
    }

    this._updateHighlightedGridPoint();
  }

  reDrawGrid() {
    this._svgElement.empty();
    this._gridPoints = [];
    this._gridRenderQueue.clear();

    if (this._gridSize <= 0) {
      return;
    }

    const viewBox = this._svgElement.getViewBox();
    const yPoints = this._calculateGridCoordinatesForLength(viewBox.height);
    const xPoints = this._calculateGridCoordinatesForLength(viewBox.width);

    yPoints.forEach((y) => {
      xPoints.forEach((x) => {
        this._gridRenderQueue.queue(new Msvg.Point(x, y));
      });
    });

    this._updateHighlightedGridPoint();
  }

  _updateHighlightedGridPoint() {
    this._gridPoints.forEach((gridPoint) => {
      if (
        this._highlightedPoint &&
        gridPoint.x === this._highlightedPoint.x &&
        gridPoint.y === this._highlightedPoint.y
      ) {
        this._setPathHighlightStyling(gridPoint.path, gridPoint.x, gridPoint.y);
        gridPoint.isHighlighted = true;
      } else if (gridPoint.isHighlighted) {
        this._setPathDefaultStyling(gridPoint.path, gridPoint.x, gridPoint.y);
        gridPoint.isHighlighted = false;
      }
    });
  }

  /**
   *
   * @param {Msvg.Point} point
   * @private
   */
  _renderGridPoint(point) {
    this._gridPoints.push({
      x: point.x,
      y: point.y,
      isHighlighted: false,
      path: this._generateGridPointPath(point.x, point.y)
    });
  }

  /**
   *
   * @param {number} x
   * @param {number} y
   * @returns {Msvg.Path}
   * @private
   */
  _generateGridPointPath(x, y) {
    const path = new Msvg.Path();
    this._setPathDefaultStyling(path, x, y);
    this._svgElement.append(path);
    return path;
  }

  /**
   *
   * @param {Msvg.Path} path
   * @param {number} x
   * @param {number} y
   * @private
   */
  _setPathDefaultStyling(path, x, y) {
    path.setFill('rgba(127,127,127,0.4)').setStrokeWidth(0);

    this._addCrosshairToPath(path, x, y, this._gridPointWidth);
  }

  /**
   *
   * @param {Msvg.Path} path
   * @param {number} x
   * @param {number} y
   * @private
   */
  _setPathHighlightStyling(path, x, y) {
    path.setFill('rgba(0, 0, 0)').setStroke('white').setStrokeWidth(1);

    this._addCrosshairToPath(path, x, y, this._gridPointWidth * 2);
  }

  /**
   *
   * @param {Msvg.Path} path
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @private
   */
  _addCrosshairToPath(path, x, y, width) {
    /*       _
     *      | |
     *  ____| |____
     * |____   ____|
     *      | |
     *      |_|
     */

    const widthOffset = width / 2;
    path
      .createFastBuilder()
      .addMoveToAbsolute(new Msvg.Point(x - widthOffset, y - widthOffset))
      .addLineToAbsolute(
        new Msvg.Point(x - widthOffset, y - this._gridPointSize)
      )
      .addLineToAbsolute(
        new Msvg.Point(x + widthOffset, y - this._gridPointSize)
      )
      .addLineToAbsolute(new Msvg.Point(x + widthOffset, y - widthOffset))
      .addLineToAbsolute(
        new Msvg.Point(x + this._gridPointSize, y - widthOffset)
      )
      .addLineToAbsolute(
        new Msvg.Point(x + this._gridPointSize, y + widthOffset)
      )
      .addLineToAbsolute(new Msvg.Point(x + widthOffset, y + widthOffset))
      .addLineToAbsolute(
        new Msvg.Point(x + widthOffset, y + this._gridPointSize)
      )
      .addLineToAbsolute(
        new Msvg.Point(x - widthOffset, y + this._gridPointSize)
      )
      .addLineToAbsolute(new Msvg.Point(x - widthOffset, y + widthOffset))
      .addLineToAbsolute(
        new Msvg.Point(x - this._gridPointSize, y + widthOffset)
      )
      .addLineToAbsolute(
        new Msvg.Point(x - this._gridPointSize, y - widthOffset)
      )
      .addClosePath()
      .finish();
  }

  /**
   *
   * @param {number} length
   * @returns {Array<number>}
   * @private
   */
  _calculateGridCoordinatesForLength(length) {
    const points = [];

    for (let y = 0; y <= length; y += this._gridSize) {
      points.push(this._roundNumber(y));
    }

    return points;
  }

  /**
   *
   * @param {number} value
   * @return {number}
   * @private
   */
  _roundNumber(value) {
    return Math.round(value * 10) / 10; // round to the first comma digit
  }
}

/**
 * @typedef {Object} TToolGridGridPoint
 * @property {number} y
 * @property {number} x
 * @property {boolean} isHighlighted
 * @property {Msvg.Path} path
 */
