import { AngleHelper } from 'common/Geometry/AngleHelper';
import { Vector } from 'common/Geometry/Vector';
import { PathCommandParser } from './PathCommandParser';
import { PathCommand, CommandIdentifier } from './PathCommand';
import { PathCommandStringBuilder } from './PathCommandStringBuilder';

export class SvgRotator {
  private svg: SVGSVGElement;
  private rotation: number;
  /**
   * centerPoint before rotating the viewBox
   */
  private oldCenterPoint: Vector;
  /**
   * centerPoint after rotating the viewBox
   */
  private newCenterPoint: Vector;

  constructor(svg: SVGSVGElement, rotation: number) {
    if (svg.viewBox.baseVal.x !== 0 || svg.viewBox.baseVal.y !== 0) {
      throw new Error('only svgs with a viewBox origin of 0 0 are supported');
    }

    const simpleRotation = AngleHelper.simplifyDegAngle(rotation);

    if (simpleRotation % 90 !== 0) {
      throw new Error(
        `only rotations which are a multiple of 90 are supported, "${rotation}" is not`
      );
    }

    this.svg = svg;
    this.rotation = simpleRotation;
    this.oldCenterPoint = new Vector(
      this.svg.viewBox.baseVal.width,
      this.svg.viewBox.baseVal.height
    ).scale(0.5);
    this.newCenterPoint = this.oldCenterPoint.clone();
  }

  public rotate(): void {
    this.rotateViewBox();

    for (const element of Array.from(this.svg.children)) {
      if (element instanceof SVGElement) {
        this.rotateElement(element);
      } else {
        console.warn(
          'svg element with a non SVGElement child?',
          element.constructor.name
        );
      }
    }
  }

  private rotateViewBox(): void {
    if (this.rotation === 90 || this.rotation === 270) {
      const height = this.svg.viewBox.baseVal.height;
      const width = this.svg.viewBox.baseVal.width;

      this.svg.viewBox.baseVal.width = height;
      this.svg.viewBox.baseVal.height = width;

      this.newCenterPoint = new Vector(height, width).scale(0.5);
    }
  }

  private rotateElement(element: SVGElement): void {
    if (element instanceof SVGRectElement) {
      this.rotateRectangle(element);
    } else if (element instanceof SVGLineElement) {
      this.rotateLine(element);
    } else if (element instanceof SVGPathElement) {
      this.rotatePath(element);
    } else if (element instanceof SVGTextElement) {
      this.rotateText(element);
    } else {
      console.warn(`unsupported tag "${element.tagName}"`);
    }
  }

  private rotateRectangle(element: SVGRectElement): void {
    const rotatedSize = new Vector(
      parseFloat(element.getAttribute('width') || '0'),
      parseFloat(element.getAttribute('height') || '0')
    ).rotate(this.rotation);

    const size = new Vector(
      Math.abs(rotatedSize.getX()),
      Math.abs(rotatedSize.getY())
    );

    // we need a special offset since in the svg the position is always our top left point
    // if we rotate our top left point by e.g. 180 degrees, then the top left point is now the bottom right point
    const offset = size
      .clone()
      .multiplyXY(
        this.rotation === 90 || this.rotation === 180 ? 1 : 0,
        this.rotation === 180 || this.rotation === 270 ? 1 : 0
      );

    this.rotateElementPosition(element, 'x', 'y', offset);

    element.setAttribute('width', size.getX().toString());
    element.setAttribute('height', size.getY().toString());
  }

  private rotateLine(element: SVGLineElement): void {
    this.rotateElementPosition(element, 'x1', 'y1');
    this.rotateElementPosition(element, 'x2', 'y2');
  }

  private rotatePath(element: SVGPathElement): void {
    const d = element.getAttribute('d');
    if (!d) {
      return;
    }

    const parser = new PathCommandParser();
    const commands = parser.parse(d);
    this.rotateCommands(commands);

    const stringBuilder = new PathCommandStringBuilder();
    element.setAttribute('d', stringBuilder.buildString(commands));
  }

  private rotateText(element: SVGTextElement): void {
    // TODO: (REC-1136) transform text correctly
    this.rotateElementPosition(element, 'x', 'y');
    for (const child of Array.from(element.children)) {
      if (child instanceof SVGTSpanElement) {
        this.rotateElementPosition(child, 'x', 'y');
      }
    }
  }

  private rotateElementPosition(
    element: SVGElement,
    xAttrName: string,
    yAttrName: string,
    offset?: Vector
  ): void {
    const position = this.getElementPosition(element, xAttrName, yAttrName);
    const rotatedPosition = this.rotateAndMoveToNewCenter(position);

    if (offset) {
      rotatedPosition.substractVector(offset);
    }

    element.setAttribute(xAttrName, rotatedPosition.getX().toString());
    element.setAttribute(yAttrName, rotatedPosition.getY().toString());
  }

  private getElementPosition(
    element: SVGElement,
    xAttrName: string,
    yAttrName: string
  ): Vector {
    return new Vector(
      parseFloat(element.getAttribute(xAttrName) || '0'),
      parseFloat(element.getAttribute(yAttrName) || '0')
    );
  }

  private rotateCommands(commands: Array<PathCommand>): void {
    for (const command of commands) {
      switch (command.identifier) {
        case CommandIdentifier.L:
        case CommandIdentifier.M:
          command.point = this.rotateAndMoveToNewCenter(command.point);
          break;

        case CommandIdentifier.C:
          command.point = this.rotateAndMoveToNewCenter(command.point);
          command.cp1 = this.rotateAndMoveToNewCenter(command.cp1);
          command.cp2 = this.rotateAndMoveToNewCenter(command.cp2);
          break;

        case CommandIdentifier.Z:
        case CommandIdentifier.z:
          break;

        default:
          throw new Error(
            `rotating of "${command.identifier}" is not supported`
          );
      }
    }
  }

  private rotateAndMoveToNewCenter(point: Vector): Vector {
    return point
      .clone()
      .substractVector(this.oldCenterPoint)
      .rotate(this.rotation)
      .addVector(this.newCenterPoint);
  }
}
