import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { SocketService } from '../../SocketService';
import { SubscriptionManagerService } from '../../SubscriptionManagerService';
import { BindAdapter } from '../EntityAdapter/EntityAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import {
  EntityNameToAdapter,
  EntityNameToAdapterContainer,
  entityNameToPermissionsConfig,
  SupportedEntityName
} from '../entityNameToPermissionsConfig';
import { PictureFileByActivePictureRevisionService } from '../../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

@autoinject()
export class EntityAdapterContainerService {
  private readonly entityNameToAdapterContainerCache: Partial<EntityNameToAdapterContainer> =
    {};

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly currentUserService: CurrentUserService,
    private readonly socketService: SocketService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService
  ) {}

  public getCachedEntityAdapterContainer<
    TEntityName extends SupportedEntityName
  >(entityName: TEntityName): EntityNameToAdapterContainer[TEntityName] {
    let adapterContainer = this.entityNameToAdapterContainerCache[
      entityName
    ] as EntityNameToAdapterContainer[TEntityName] | undefined;

    if (!adapterContainer) {
      const adapter = entityNameToPermissionsConfig[entityName].createAdapter({
        computedValueService: this.computedValueService,
        entityManager: this.entityManager,
        subscriptionManagerService: this.subscriptionManagerService,
        currentUserService: this.currentUserService,
        activeUserCompanySettingService: this.activeUserCompanySettingService,
        socketService: this.socketService,
        pictureFileByActivePictureRevisionService:
          this.pictureFileByActivePictureRevisionService
      }) as EntityNameToAdapter[TEntityName];

      adapterContainer = new EntityAdapterContainer({
        adapter,
        bindAdapter: this.getBindAdapterFunction({ entityName })
      }) as EntityNameToAdapterContainer[TEntityName];
      this.entityNameToAdapterContainerCache[entityName] =
        adapterContainer as any;
    }

    return adapterContainer;
  }

  public getBindAdapterFunction(options: {
    entityName: EntityName;
  }): BindAdapter {
    return ({ entityName, onNextAdapter }) => {
      if (options.entityName === entityName) {
        throw new Error(
          `can't request an adapter for ${entityName} from the adapter itself.`
        );
      }

      const entityAdapterContainer =
        this.getCachedEntityAdapterContainer(entityName);

      return entityAdapterContainer.bindAdapter(onNextAdapter);
    };
  }
}
