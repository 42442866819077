import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { CalendarHolidays } from '../../classes/Calendar/CalendarHolidays';
import {
  CalendarEntry,
  DaySpecificCalendarEntry
} from './CalendarEntryDataSource/CalendarEntry';
import { CalendarEntryClickedEvent } from './process-task-appointment-calendar-widget';
import {
  CalendarEntriesHandle,
  CalendarEntryDataSource
} from './CalendarEntryDataSource/CalendarEntryDataSource';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GetCalendarEntriesOptions } from './CalendarEntryDataSource/strategies/CalendarEntryDataSourceStrategy';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

/**
 * @event {CalendarEntryClickedEvent} entry-clicked - Triggered whenever a calendar entry is clicked.
 */
@autoinject()
export class ProcessTaskAppointmentCalendarWidgetMonth {
  @bindable()
  public dataSource: CalendarEntryDataSource | null = null;

  @bindable()
  public userIds: Array<string> | null = null;

  @bindable()
  public activeMonthDate: Date = new Date();

  @bindable()
  public useUserColors: boolean = false;

  @bindable()
  public getIncreasedZIndex: (() => number) | null = null;

  /**
   * Number array containing [0, 1, ..., 30]
   */
  protected displayDays: Array<number> = [...Array(31).keys()];

  protected calendarEntries: Array<DaySpecificCalendarEntry> = [];
  private calendarEntriesHandle: CalendarEntriesHandle | null = null;

  private element: HTMLElement;
  private isAttached: boolean = false;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateCalendarEntriesHandle();
  }

  protected detached(): void {
    this.isAttached = false;

    this.calendarEntriesHandle?.destroy();
    this.calendarEntriesHandle = null;
  }

  protected activeMonthDateChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  protected userIdsChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  protected useUserColorsChanged(): void {
    this.calendarEntriesHandle?.updateGetCalendarEntriesOptions(
      this.getGetCalendarEntriesOptions()
    );
  }

  private updateCalendarEntriesHandle(): void {
    this.calendarEntriesHandle?.destroy();
    this.calendarEntriesHandle = null;

    if (this.isAttached && this.dataSource) {
      this.calendarEntriesHandle = this.dataSource?.createHandle({
        getCalendarEntriesOptions: this.getGetCalendarEntriesOptions(),
        onNewCalendarEntries: (entries) => {
          this.calendarEntries = entries;
        }
      });
    }
  }

  private getGetCalendarEntriesOptions(): GetCalendarEntriesOptions {
    assertNotNullOrUndefined(
      this.userIds,
      'cannot getGetCalendarEntriesOptions without userIds'
    );
    assertNotNullOrUndefined(
      this.activeMonthDate,
      'cannot getGetCalendarEntriesOptions without day'
    );

    return {
      useUserColors: this.useUserColors,
      filter: {
        userIds: this.userIds,
        date: {
          dateFrom: DateUtils.getStartDateOfMonth(this.activeMonthDate),
          dateTo: DateUtils.getEndDateOfMonth(this.activeMonthDate)
        }
      }
    };
  }

  protected handleAppointmentClick(entry: CalendarEntry): void {
    DomEventHelper.fireEvent<CalendarEntryClickedEvent>(this.element, {
      name: 'calendar-entry-clicked',
      detail: {
        entry
      },
      bubbles: true
    });
  }

  /**
   * Will return the short day-of-week representation (e.g. `Mo`, `Di`, `Mi`)
   * for a given date.
   */
  protected getShortDayOfWeekForDayOffset(
    activeMonthDate: Date,
    offset: number
  ): string {
    const day = DateUtils.getDateWithDayOffset(activeMonthDate, offset);
    return DateUtils.formatToShortDayOfWeekString(day);
  }

  /**
   * Calculates the day at the given offset from activeMonthDate,
   * and returns true if the resulting day is still within the same month
   * (false otherwise).
   */
  protected isValidDayOffset(activeMonthDate: Date, offset: number): boolean {
    return DateUtils.getDaysInMonth(activeMonthDate) >= offset + 1;
  }

  /**
   * Contains all appointments at the day of the given offset from activeMonthDate.
   */
  @computed(expression('activeMonthDate'), expression('calendarEntries'))
  protected get appointmentsOfDayMap(): Map<number, Array<CalendarEntry>> {
    const map = new Map<number, Array<CalendarEntry>>();
    for (const offset of this.displayDays) {
      const day = DateUtils.getDateWithDayOffset(this.activeMonthDate, offset);
      map.set(
        offset,
        this.calendarEntries
          .filter((e) =>
            DateUtils.isOnSameDay(e.calendarEntry.startTimestamp, day)
          )
          .map((e) => e.calendarEntry)
      );
    }
    return map;
  }

  /**
   * True if the given day is a work free day (saturday or sunday).
   * Will set the bg color of the day cell to another color.
   */
  protected isWorkFreeDay(activeMonthDate: Date, offset: number): boolean {
    const day = DateUtils.getDateWithDayOffset(activeMonthDate, offset);
    return DateUtils.isWeekend(day) || CalendarHolidays.isHoliday(day);
  }

  /**
   * formats the time of the appointment to a pretty string.
   */
  protected getFormattedAppointmentTime(entry: CalendarEntry): string {
    return `${DateUtils.formatToHourMinuteString(
      entry.startTimestamp
    )} - ${DateUtils.formatToHourMinuteString(entry.endTimestamp)}`;
  }
}
