import holidays from './holidays.json';

/**
 * Helper for retrieving which holidays fall into a given calendar year.
 *
 * Only austrian holidays are supported.
 */
export class CalendarHolidays {
  /**
   * stores holidays for a given date.
   */
  private static holidaysMap: Map<DateKey, Array<Holiday>> = new Map();

  /**
   * stores repeating holidays (e.g. holidays that fall on the same date every year) for a given month + day.
   */
  private static repeatingHolidaysMap: Map<RepeatingDateKey, Array<Holiday>> =
    new Map();

  /**
   * Initializes the CalendarHolidays class.
   *
   * Reads holidays from .json and fills a map with them for easier retrieval.
   */
  public static init(): void {
    for (const holiday of holidays) {
      this.addHoliday(holiday);
    }
  }

  /**
   * Retrieve all (non-repeating & repeating) holidays on a given day.
   */
  public static getHolidaysOnDay(date: Date): Array<Holiday> {
    const key = this.getDateKey(date);
    const repeatingKey = this.getRepeatingDateKey(date);
    const holidayArr = this.holidaysMap.get(key) ?? [];
    const repeatingHolidayArr =
      this.repeatingHolidaysMap.get(repeatingKey) ?? [];
    return [...holidayArr, ...repeatingHolidayArr];
  }

  /**
   * returns true if the given date is a holiday, false otherwise.
   */
  public static isHoliday(date: Date): boolean {
    return this.getHolidaysOnDay(date).length > 0;
  }

  /**
   * stores a holiday to one of the two maps depending on whether the holiday is repeating or not.
   */
  private static addHoliday(holiday: Holiday): void {
    if (holiday.frequency) {
      const key = holiday.date.substr(4);
      const holidayArr = this.repeatingHolidaysMap.get(key) ?? [];
      this.repeatingHolidaysMap.set(key, [...holidayArr, holiday]);
    } else {
      const key = holiday.date;
      const holidayArr = this.holidaysMap.get(key) ?? [];
      this.holidaysMap.set(key, [...holidayArr, holiday]);
    }
  }

  private static getDateKey(date: Date): DateKey {
    const year = '' + date.getFullYear();
    const month = '' + (date.getMonth() + 1);
    const day = '' + date.getDate();
    return `${year.padStart(4, '0')}${month.padStart(2, '0')}${day.padStart(
      2,
      '0'
    )}`;
  }

  private static getRepeatingDateKey(date: Date): RepeatingDateKey {
    const month = '' + (date.getMonth() + 1);
    const day = '' + date.getDate();
    return `${month.padStart(2, '0')}${day.padStart(2, '0')}`;
  }
}

CalendarHolidays.init();

/**
 * Key for the holidaysMap.
 * in 'YYYYMMDD' format.
 */
type DateKey = string;

/**
 * Key for the repeatingHolidaysMap.
 * in 'MMDD' format.
 */
type RepeatingDateKey = string;

/**
 * holidays.json entries.
 */
type Holiday = {
  name: string;
  date: string;
  frequency?: string;
};
