import { bindable, inject } from 'aurelia-framework';
import _ from 'lodash';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * @event {TMeasurePointClickedEvent} measure-point-clicked
 */
@inject(Element, SubscriptionManagerService, AppEntityManager)
export class ProcessTaskMeasurePointMarkingsOverlay {
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types').ProcessTaskMeasurePointToPicture>} */
  @bindable measurePointToPictures = [];

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint>} */
  @bindable measurePoints = [];

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {import('../../classes/EntityManager/entities/Picture/types').Picture|null} */
  @bindable picture = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {{[s: string]: import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType}} */
  _measurePointTypeMap = {};
  /** @type {Array<TMarking>} */
  _displayedMarkings = [];
  /** @type {boolean} */
  _attached = false;

  /**
   * @param {HTMLElement} element
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(element, subscriptionManagerService, entityManager) {
    this._domElement = element;
    this._subscriptionManager = subscriptionManagerService.create();
    this._entityManager = entityManager;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'measurePointToPictures',
      this._updateDisplayedMarkings.bind(this)
    );
    this._subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'measurePoints',
      this._updateDisplayedMarkings.bind(this)
    );
    this._updateMeasurePointTypeMap();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskGroupChanged() {
    if (this._attached) {
      this._updateMeasurePointTypeMap();
    }
  }

  _updateMeasurePointTypeMap() {
    /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType>} */
    let types = [];

    if (this.processTaskGroup) {
      types =
        this._entityManager.processConfigurationMeasurePointTypeRepository.getByProcessConfigurationId(
          this.processTaskGroup.processConfigurationId
        );
    }

    this._measurePointTypeMap = _.keyBy(types, 'id');

    this._updateDisplayedMarkings();
  }

  _updateDisplayedMarkings() {
    const picture = this.picture;

    if (!picture) {
      this._displayedMarkings = [];
      return;
    }

    /** @type {Array<TMarking>} */
    const markings = [];

    this.measurePoints.forEach((mp) => {
      const relations = this.measurePointToPictures.filter(
        (relation) =>
          relation.processTaskMeasurePointId === mp.id &&
          relation.pictureId === picture.id
      );
      relations.forEach((r) => {
        markings.push({
          relation: r,
          measurePoint: mp,
          color: this._getColorForMeasurePoint(mp)
        });
      });
    });

    this._displayedMarkings = markings;
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
   * @returns {string|null}
   */
  _getColorForMeasurePoint(measurePoint) {
    const type = measurePoint.processConfigurationMeasurePointTypeId
      ? this._measurePointTypeMap[
          measurePoint.processConfigurationMeasurePointTypeId
        ]
      : null;
    return type && type.color ? type.color : null;
  }

  /**
   * @param {TMarking} marking
   * @returns {Object}
   */
  _getDescriptionStyle(marking) {
    const style = {};

    if (marking.color) {
      style.color = marking.color;
    }

    return style;
  }

  /**
   * @param {TMarking} marking
   * @returns {Object}
   */
  _getMarkingStyle(marking) {
    const style = {};

    if (marking.color) {
      style['border-color'] = marking.color;
    }

    return style;
  }

  /**
   * @param {TMarking} marking
   */
  _handleMarkingClick(marking) {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'measure-point-clicked',
      detail: {
        measurePoint: marking.measurePoint
      }
    });
  }
}

/**
 * @typedef {Object} TMarking
 * @property {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types').ProcessTaskMeasurePointToPicture} relation
 * @property {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint} measurePoint
 * @property {string|null} color
 */

/**
 * @typedef {Event & { detail : { measurePoint: import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint } }} TMeasurePointClickedEvent
 */
