import { autoinject, observable } from 'aurelia-framework';
import { DefectStatus } from '../../../../common/src/Enums/DefectStatus';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  DefectSortOption,
  DefectSortOptions,
  DefectUtils
} from '../../classes/EntityManager/entities/Defect/DefectUtils';
import { computed } from '../../hooks/computed';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { expression } from '../../hooks/dependencies';
import { ModuleName } from '../../classes/RecordItModuleHelper';

@autoinject()
export class DefectOverviewWorker {
  protected defects: Array<Defect> = [];

  protected currentPageDefects: Array<Defect> = [];

  protected openedDefectInfo: Readonly<{
    defect: Defect | null;
    listItemElement: HTMLElement | null;
  }> | null = null;

  protected isDetailsWidgetOpen: boolean = false;

  protected defectListRef: HTMLElement | null = null;

  @observable() protected statusFilterSelectedOption =
    StatusFilterOptions.OPEN_AND_PROCESSED;

  protected statusFilterOptions = [
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.all',
      value: StatusFilterOptions.ALL
    },
    {
      tk: 'defectComponents.defectOverviewWorker.statusFilter.onlyOpenAndProcessed',
      value: StatusFilterOptions.OPEN_AND_PROCESSED
    }
  ];

  private currentUser: User | null = null;

  private subscriptionManager: SubscriptionManager;

  private readonly sortOptions: DefectSortOptions;
  protected currentSortOption: DefectSortOption;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.sortOptions = DefectUtils.createSortOptions({ entityManager });
    this.currentSortOption = this.sortOptions.sequenceNumber;
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser(this.updateCurrentUser.bind(this))
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateDefects.bind(this)
    );
    this.updateDefects();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private openDetailsView(defect: Defect): void {
    this.openedDefectInfo = {
      defect,
      listItemElement:
        this.defectListRef?.querySelector(`#defect-${defect.id}`) || null
    };

    this.isDetailsWidgetOpen = true;
  }

  private closeDetailsView(): void {
    // Don't set openedDefect to null because the widget closing animation plays
    // - The next openDetailsView will set it again anyway
    this.isDetailsWidgetOpen = false;
  }

  private matchesStatusFilter(defect: Defect): boolean {
    switch (this.statusFilterSelectedOption) {
      case StatusFilterOptions.ALL:
        return true;
      case StatusFilterOptions.OPEN_AND_PROCESSED:
        const status = defect.status;
        return (
          status === DefectStatus.OPEN || status === DefectStatus.PROCESSED
        );
      default:
        throw new Error('unknown StatusFilterOptions value');
    }
  }

  // /////////// UPDATERS /////////////

  private updateCurrentUser(user: User | null): void {
    this.currentUser = user;
    this.updateDefects();
  }

  private updateDefects(): void {
    if (!this.currentUser) {
      this.defects = [];
      return;
    }

    this.defects = this.entityManager.defectRepository
      .getAll()
      .filter((d) => d.assigneeId === this.currentUser!.id)
      .filter(this.matchesStatusFilter.bind(this));
  }

  // /////////// OBSERVABLES /////////////

  protected statusFilterSelectedOptionChanged(): void {
    this.updateDefects();
  }

  protected handleDetailViewButtonClick(defect: Defect): void {
    this.openDetailsView(defect);
  }

  protected handleWidgetOverlayCloseIconClicked(): void {
    this.closeDetailsView();
  }

  @computed(expression('currentUser'))
  protected get useInlineView(): boolean {
    return !PermissionHelper.userHasPermissionForModule(
      this.currentUser,
      ModuleName.KUK
    );
  }
}

enum StatusFilterOptions {
  ALL = 'all',
  OPEN_AND_PROCESSED = 'openAndProcessed'
}
