import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createQuestionCatalogueToQuestionSetEntityInfo } from 'common/Types/Entities/QuestionCatalogueToQuestionSet/createQuestionCatalogueToQuestionSetEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { QuestionCatalogueToQuestionSet } from './types';

export const questionCatalogueToQuestionSetEntityInfo =
  createQuestionCatalogueToQuestionSetEntityInfo<QuestionCatalogueToQuestionSet>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
