import { autoinject } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskPositionDetailEntryProperty } from '../../classes/EntityManager/entities/Property/types';

@autoinject()
export class PropertiesByProcessTaskPositionDetailEntryIdComputer extends ValueComputer<
  IComputeData,
  PropertiesByProcessTaskPositionDetailEntryId
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(
    data: IComputeData
  ): PropertiesByProcessTaskPositionDetailEntryId {
    const properties =
      this.entityManager.propertyRepository.getByOwnerProcessTaskId(
        data.ownerProcessTaskId
      );
    const detailEntryProperties = properties.filter(
      (p): p is ProcessTaskPositionDetailEntryProperty =>
        !!p.processTaskPositionDetailEntryId
    );
    return Utils.groupBy(
      detailEntryProperties,
      (p) => p.processTaskPositionDetailEntryId
    );
  }
}

export interface IComputeData {
  ownerProcessTaskId: string;
}

export type PropertiesByProcessTaskPositionDetailEntryId = Map<
  string,
  Array<ProcessTaskPositionDetailEntryProperty>
>;
