import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { GetProcessTaskGroupsFilterAddressFilterType } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {ProcessTaskGroupAddressFilterTypeSelectValueChangedEvent} value-changed
 */
@autoinject()
export class ProcessTaskGroupAddressFilterTypeSelect {
  @bindable()
  public value: GetProcessTaskGroupsFilterAddressFilterType =
    GetProcessTaskGroupsFilterAddressFilterType.ALL;

  @bindable()
  public enabled: boolean = false;

  protected readonly options: Array<{
    value: GetProcessTaskGroupsFilterAddressFilterType;
    labelTk: string;
  }> = [
    {
      value: GetProcessTaskGroupsFilterAddressFilterType.ALL,
      labelTk:
        'operationsComponents.processTaskGroupAddressFilterTypeSelect.options.all'
    },
    {
      value: GetProcessTaskGroupsFilterAddressFilterType.WITH_ADDRESS,
      labelTk:
        'operationsComponents.processTaskGroupAddressFilterTypeSelect.options.withAddress'
    },
    {
      value: GetProcessTaskGroupsFilterAddressFilterType.WITHOUT_ADDRESS,
      labelTk:
        'operationsComponents.processTaskGroupAddressFilterTypeSelect.options.withoutAddress'
    }
  ];

  constructor(private readonly element: Element) {}

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent<ProcessTaskGroupAddressFilterTypeSelectValueChangedEvent>(
      this.element,
      {
        name: 'value-changed',
        detail: {
          value: this.value
        }
      }
    );
  }
}

export type ProcessTaskGroupAddressFilterTypeSelectValueChangedEvent =
  NamedCustomEvent<
    'value-changed',
    { value: GetProcessTaskGroupsFilterAddressFilterType }
  >;
