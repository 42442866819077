import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { TouchDraggingDetector } from '../../classes/DomUtilities/TouchDraggingDetector';

class LeafletSquareSelector {
  /**@type {HTMLElement}*/
  _controlElement;
  /**@type {HTMLElement}*/
  _toggleActiveButton;
  /** @type {L.LatLng} */
  _lastClickedPoint;

  /**
   *
   * @param {L.Map} map
   * @param {Object} options
   * @param {function(areaInfo: TLeafletSquareSelectorSelectedAreaInfo)} options.onAreaSelected
   */
  constructor(map, options) {
    this._map = map;
    this._onAreaSelected = options.onAreaSelected;
    this._maxRatio = 2 / 1; //height / width
    this._minRatio = 1 / this._maxRatio;
  }

  initialize() {
    this._initialized = true;

    this._controlElement = L.DomUtil.create('div');
    this._controlElement.setAttribute('title', 'Mehrfachauswahl');
    this._controlElement.className = 'leaflet-bar';

    this._toggleActiveButton = L.DomUtil.create('a');
    this._toggleActiveButton.innerHTML = '▭';
    this._toggleActiveButton.href = '#';
    this._controlElement.appendChild(this._toggleActiveButton);

    L.DomEvent.on(
      this._toggleActiveButton,
      'click',
      this._handleToggleActiveClick,
      this
    );
    this._map.on('click', this._handleMapClick, this);
    this._map.on('mousemove', this._handleMapMousemove, this);
    this._touchDraggingDetector = new TouchDraggingDetector(
      this._map.getContainer(),
      { pressTimeoutEnabled: true, rippleEffect: true }
    );
    this._touchDraggingDetector.onDragStart(
      this._handleTouchDragStart.bind(this)
    );
    this._touchDraggingDetector.onDrag(this._handleTouchDrag.bind(this));
    this._touchDraggingDetector.onDragEnd(this._handleTouchDragEnd.bind(this));
  }

  destroy() {
    this._initialized = false;

    L.DomEvent.off(
      this._toggleActiveButton,
      'click',
      this._handleToggleActiveClick,
      this
    );
    this._map.off('click', this._handleMapClick, this);
    this._map.off('mousemove', this._handleMapMousemove, this);

    this._touchDraggingDetector.destroy();
  }

  getControlElement() {
    return this._controlElement;
  }

  /**
   *
   * @param {MouseEvent} event
   * @private
   */
  _handleToggleActiveClick(event) {
    event.preventDefault();
    this.setActive(!this._active);
  }

  /**
   *
   * @param {{latlng: L.LatLng, originalEvent: MouseEvent}} event
   * @private
   */
  _handleMapClick(event) {
    if (!this._active || event.originalEvent.defaultPrevented) {
      return; //nothing to do here
    }

    if (!this._lastClickedPoint) {
      this._lastClickedPoint = event.latlng;
      this._updateRectangle(null, null);
    } else if (
      this._lastClickedPoint.lat != event.latlng.lat ||
      this._lastClickedPoint.lng != event.latlng.lng
    ) {
      //ignore clicks on the same point
      this._updateRectangle(this._lastClickedPoint, event.latlng);
      this._callOnAreaSelected();
      this._lastClickedPoint = null;
    }
  }

  /**
   *
   * @param {{latlng: L.latLng}} event
   * @private
   */
  _handleMapMousemove(event) {
    if (
      this._active &&
      this._lastClickedPoint &&
      (this._lastClickedPoint.lat != event.latlng.lat ||
        this._lastClickedPoint.lng != event.latlng.lng)
    ) {
      this._updateRectangle(this._lastClickedPoint, event.latlng);
    }
  }

  /**
   *
   * @param {TouchEvent} event
   * @private
   */
  _handleTouchDragStart(event) {
    if (!this._active) {
      return;
    }

    this._lastClickedPoint = this._touchEventToLatLng(event);
    this._map.dragging.disable();
    this._updateRectangle(null, null);
  }

  /**
   *
   * @param {TouchEvent} event
   * @private
   */
  _handleTouchDrag(event) {
    event.preventDefault();

    const secondPoint = this._touchEventToLatLng(event);
    if (
      this._lastClickedPoint.lat != secondPoint.lat ||
      this._lastClickedPoint.lng != secondPoint.lng
    ) {
      this._updateRectangle(this._lastClickedPoint, secondPoint);
    }
  }

  _handleTouchDragEnd() {
    this._map.dragging.enable();
    this._callOnAreaSelected();
    this._lastClickedPoint = null;
  }

  /**
   *
   * @param {TouchEvent} event
   * @returns {(null|L.LatLng)}
   * @private
   */
  _touchEventToLatLng(event) {
    const touch = event.touches[0];
    const bounds = this._map.getContainer().getBoundingClientRect();
    const x = touch.clientX - bounds.x;
    const y = touch.clientY - bounds.y;
    if (x >= 0 && y >= 0) {
      return this._map.containerPointToLatLng(L.point(x, y));
    } else {
      return null;
    }
  }

  setActive(active) {
    this._active = active;
    this._toggleActiveButton.style.background = active ? '#6cceff' : null;

    if (!active) {
      this._updateRectangle(null, null);
      this._lastClickedPoint = null;
    }
  }

  /**
   *
   * @param {(L.LatLng|null)} firstPoint
   * @param {(L.LatLng|null)} secondPoint
   * @private
   */
  _updateRectangle(firstPoint, secondPoint) {
    if (firstPoint == null || secondPoint == null) {
      if (this._rectangle) {
        this._rectangle.remove();
      }
      this._rectangle = null;
      return;
    }

    secondPoint = this._normalizeSecondPointToRatio(firstPoint, secondPoint);

    if (!this._rectangle) {
      this._rectangle = this._createRectangle(firstPoint, secondPoint);
    } else {
      this._rectangle.setBounds(L.latLngBounds(firstPoint, secondPoint));
    }
  }

  /**
   *
   * returns a new secondPoint which should be used for the rectangle
   *
   * @param {L.LatLng} firstPoint
   * @param {L.LatLng} secondPoint
   * @returns {L.LatLng}
   * @private
   */
  _normalizeSecondPointToRatio(firstPoint, secondPoint) {
    const yLength = CoordinateHelper.calculateDistance(
      firstPoint.lng,
      firstPoint.lat,
      firstPoint.lng,
      secondPoint.lat
    );
    const xLength = CoordinateHelper.calculateDistance(
      firstPoint.lng,
      firstPoint.lat,
      secondPoint.lng,
      firstPoint.lat
    );
    const ratio = xLength / yLength;
    const newRatio = Math.min(Math.max(ratio, this._minRatio), this._maxRatio);

    if (newRatio === ratio) {
      //ratio is fine like it is, no need to recalculate the secondPoint
      return L.latLng(secondPoint.lat, secondPoint.lng);
    } else if (newRatio > 1) {
      return L.latLng(
        CoordinateHelper.movePointAlongLatitude(
          firstPoint.lat,
          (xLength / newRatio) * Math.sign(secondPoint.lat - firstPoint.lat)
        ),
        secondPoint.lng
      );
    } else {
      return L.latLng(
        secondPoint.lat,
        CoordinateHelper.movePointAlongLongitude(
          firstPoint.lng,
          secondPoint.lat,
          yLength * newRatio * Math.sign(secondPoint.lng - firstPoint.lng)
        )
      );
    }
  }

  /**
   *
   * @param {L.LatLng} firstPoint
   * @param {L.LatLng} secondPoint
   * @returns {L.Rectangle}
   * @private
   */
  _createRectangle(firstPoint, secondPoint) {
    var rect = L.rectangle(L.latLngBounds(firstPoint, secondPoint), {
      color: '#3678ff',
      weight: 1
    });

    rect.addTo(this._map);
    return rect;
  }

  _callOnAreaSelected() {
    if (!this._rectangle || !this._onAreaSelected) {
      return;
    }

    const bounds = this._rectangle.getBounds();
    const northWest = bounds.getNorthWest();
    const southEast = bounds.getSouthEast();

    this._onAreaSelected({
      topLeftCorner: {
        lat: northWest.lat,
        long: northWest.lng
      },
      bottomRightCorner: {
        lat: southEast.lat,
        long: southEast.lng
      }
    });
  }
}

L.Control.SquareSelector = L.Control.extend({
  onAdd: function (map) {
    this._squareSelector = new LeafletSquareSelector(map, this.options);
    this._squareSelector.initialize();
    this._squareSelector.setActive(!!this.options.defaultActive);
    return this._squareSelector.getControlElement();
  },

  onRemove: function () {
    this._squareSelector.destroy();
    this._squareSelector = null;
  }
});

/**
 *
 * @param {Object} options
 * @param {function(areaInfo: TLeafletSquareSelectorSelectedAreaInfo)} options.onAreaSelected
 * @param {boolean} options.defaultActive
 */
L.control.squareSelector = function (options) {
  return new L.Control.SquareSelector(options);
};

/**
 * @typedef {Object} TLeafletSquareSelectorSelectedAreaInfo
 * @property {{lat: number, long: number}} topLeftCorner
 * @property {{lat: number, long: number}} bottomRightCorner
 */
