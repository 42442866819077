import { autoinject } from 'aurelia-framework';
import {
  DialogOptions as SketcherOverlayDialogOptions,
  SketcherOverlay
} from '../../drawingComponents/sketcher-overlay/sketcher-overlay';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import {
  DataUrlSketcherOverlayImageHandler,
  TOnDataSavedCallback
} from './DataUrlSketcherOverlayImageHandler';
import { PictureSketcherOverlayImageHandler } from './PictureSketcherOverlayImageHandler';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

@autoinject()
export class PictureSketchingService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService
  ) {}

  public sketchPicture(
    picture: Picture,
    options?: SketcherOverlayDialogOptions
  ): void {
    const handler = new PictureSketcherOverlayImageHandler(
      picture,
      this.entityManager,
      this.pictureFilePathService,
      this.pictureFileByActivePictureRevisionService,
      this.savePictureFileDataUrlService,
      this.subscriptionManagerService
    );
    void SketcherOverlay.open(handler, options);
  }

  public sketchDataUrl(
    originalDataUrl: string,
    sketchDataUrl: string | null | undefined,
    onDataSaved: TOnDataSavedCallback
  ): void {
    const handler = new DataUrlSketcherOverlayImageHandler(
      { originalDataUrl, sketchDataUrl },
      onDataSaved
    );
    void SketcherOverlay.open(handler);
  }
}
