import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationFormSendTarget } from '../../classes/EntityManager/entities/ProcessConfigurationFormSendTarget/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationFormSendTargetWidget {
  @bindable()
  public processConfigurationFormSendTarget: ProcessConfigurationFormSendTarget | null =
    null;

  @subscribableLifecycle()
  protected processConfigurationFormSendTargetPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationFormSendTarget];

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager
  ) {
    this.processConfigurationFormSendTargetPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationFormSendTarget,
        context: this,
        expression: 'processConfigurationFormSendTarget'
      });
  }

  protected handleFormSendTargetChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationFormSendTarget,
      'cannot ProcessConfigurationFormSendTargetsWidget.handleDeleteButtonClick without processConfigurationFormSendTarget bindable'
    );
    this.entityManager.processConfigurationFormSendTargetRepository.update(
      this.processConfigurationFormSendTarget
    );
  }

  protected handleDeleteButtonClick(): void {
    const processConfigurationFormSendTarget =
      this.processConfigurationFormSendTarget;
    assertNotNullOrUndefined(
      processConfigurationFormSendTarget,
      'cannot ProcessConfigurationFormSendTargetsWidget.handleDeleteButtonClick without processConfigurationFormSendTarget'
    );

    void Dialogs.deleteEntityDialog(processConfigurationFormSendTarget).then(
      () => {
        this.entityManager.processConfigurationFormSendTargetRepository.delete(
          processConfigurationFormSendTarget
        );
      }
    );
  }
}
