import { EntityFieldInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../AppCommonSynchronizationEnvironmentTypes';
import {
  BaseEntityDto,
  BaseEntityFieldInfos,
  BaseEntityUtils
} from './BaseEntityUtils';
import { EntityName } from './EntityName';

export class UserSubEntityUtils {
  private constructor() {}

  public static createBaseFieldInfos(): UserSubEntityFieldInfos {
    return {
      ...BaseEntityUtils.createBaseFieldInfos(),
      userId: {
        name: 'userId',
        ref: EntityName.User,
        cascadeDeleteLocally: true
      }
    };
  }
}

export type UserSubEntityFieldInfos = BaseEntityFieldInfos & {
  userId: EntityFieldInfo<
    AppCommonSynchronizationEnvironmentTypes,
    UserSubEntityDto,
    'userId'
  >;
};

export type UserSubEntityDto = BaseEntityDto & {
  userId: string;
};
