import { bindable } from 'aurelia-framework';

import { ArrayUtils } from 'common/Utils/ArrayUtils';

import { GalleryThingPictureOverviewEntry } from '../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { computed } from '../../hooks/computed';
import { arrayChanges } from '../../hooks/dependencies';

export class GalleryThingSelectPictureGroupWidget {
  @bindable
  public pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> = [];

  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  protected handleSelectPictureGroupButtonClick(): void {
    if (this.isPictureGroupSelected) {
      this.deselectPictureGroup();
    } else {
      this.selectPictureGroup();
    }
  }

  @computed(
    arrayChanges('pictureOverviewEntries'),
    arrayChanges('selectedPictureOverviewEntries')
  )
  protected get isPictureGroupSelected(): boolean {
    return this.pictureOverviewEntries.every(
      (e) => !e.entryId || this.selectedPictureOverviewEntries.includes(e)
    );
  }

  @computed(arrayChanges('pictureOverviewEntries'))
  protected get isEnabled(): boolean {
    return !!this.pictureOverviewEntries.filter((e) => e.entryId).length;
  }

  private selectPictureGroup(): void {
    for (const entry of this.pictureOverviewEntries) {
      if (!entry.entryId) continue;
      ArrayUtils.pushUnique(this.selectedPictureOverviewEntries, entry);
    }
  }

  private deselectPictureGroup(): void {
    this.selectedPictureOverviewEntries =
      this.selectedPictureOverviewEntries.filter(
        (e) => !this.pictureOverviewEntries.includes(e)
      );
  }
}
