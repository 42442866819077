import { TagSortingMode } from 'common/Enums/TagSortingMode';
import { SortUtils } from 'common/Utils/SortUtils';

import { Tag } from './EntityManager/entities/Tag/types';

export class TagSorter {
  public static sortTags(
    tags: Array<Tag>,
    sortingMode?: TagSortingMode
  ): Array<Tag> {
    switch (sortingMode) {
      case TagSortingMode.UNSORTED:
      default:
        return tags;

      case TagSortingMode.ALPHABETICALLY:
        return this.sortAlphabetically(tags);

      case TagSortingMode.REVERSED_ALPHABETICALLY:
        return this.sortAlphabetically(tags, true);
    }
  }

  private static sortAlphabetically(
    tags: Array<Tag>,
    reverse = false
  ): Array<Tag> {
    return tags
      .slice()
      .sort((tagA, tagB) =>
        reverse
          ? SortUtils.localeCompareFalsyNumericStrings(tagB.name, tagA.name)
          : SortUtils.localeCompareFalsyNumericStrings(tagA.name, tagB.name)
      );
  }
}
