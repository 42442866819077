import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { Property } from '../../EntityManager/entities/Property/types';
import { PropertyConfigsByPropertyType } from '../createPropertyConfigsByPropertyTypes/createPropertyConfigsByPropertyTypes';

export class RequiredProperty {
  public static createRequiredProperties({
    properties,
    propertyConfigsByPropertyType
  }: {
    properties: Array<Property>;
    propertyConfigsByPropertyType: PropertyConfigsByPropertyType;
  }): Array<RequiredProperty> {
    return properties
      .map((property) => {
        return RequiredProperty.createRequiredProperty({
          property,
          propertyConfigsByPropertyType
        });
      })
      .filter((requiredProperty): requiredProperty is RequiredProperty => {
        return requiredProperty != null;
      });
  }

  /**
   * return null if the property is not required
   */
  public static createRequiredProperty({
    property,
    propertyConfigsByPropertyType
  }: {
    property: Property;
    propertyConfigsByPropertyType: PropertyConfigsByPropertyType;
  }): RequiredProperty | null {
    const { required, groupName, unique } =
      OptionParsingUtils.getOptionConfigurations({ property });
    if (!required) {
      return null;
    }

    return new RequiredProperty({
      property,
      groupName,
      unique,
      propertyConfigsByPropertyType
    });
  }

  private readonly internalProperty: Property;
  private readonly internalGroupName: string | null;
  private readonly internalUnique: boolean;
  private readonly propertyConfigsByPropertyType: PropertyConfigsByPropertyType;

  private constructor({
    property,
    groupName,
    unique,
    propertyConfigsByPropertyType
  }: {
    property: Property;
    groupName: string | null;
    unique: boolean;
    propertyConfigsByPropertyType: PropertyConfigsByPropertyType;
  }) {
    this.internalProperty = property;
    this.internalGroupName = groupName;
    this.internalUnique = unique;
    this.propertyConfigsByPropertyType = propertyConfigsByPropertyType;
  }

  public get groupName(): string | null {
    return this.internalGroupName;
  }

  public get unique(): boolean {
    return this.internalUnique;
  }

  public get hasValue(): boolean {
    const config =
      this.propertyConfigsByPropertyType[
        PropertyHelper.getTypeOrDefault(this.property.type)
      ];
    if (!config.hasValue) {
      return true;
    }

    return config.hasValue({ property: this.property });
  }

  public get property(): Property {
    return this.internalProperty;
  }
}

class OptionParsingUtils {
  private constructor() {}

  public static getOptionConfigurations({ property }: { property: Property }): {
    required: boolean;
    groupName: string | null;
    unique: boolean;
  } {
    const requiredOptionInfo = this.getRequiredOptionInfo({ property });
    const requiredGroupOptionInfo = this.getRequiredGroupOptionInfo({
      property
    });
    const requiredGroupUniqueOptionInfo = this.getRequiredGroupUniqueOptionInfo(
      { property }
    );

    /**
     * property will also automatically be required if an requiredGroup is set. But only if the required is not explicitly set to false
     */
    const required =
      requiredOptionInfo.required ||
      (!requiredOptionInfo.explicitlySetToFalse &&
        requiredGroupOptionInfo.groupName != null);

    /**
     * non required properties can't be in a group
     */
    const groupName = required ? requiredGroupOptionInfo.groupName : null;

    return {
      required,
      groupName,
      unique: requiredGroupUniqueOptionInfo.unique
    };
  }

  private static getRequiredOptionInfo({ property }: { property: Property }): {
    required: boolean;
    explicitlySetToFalse: boolean;
  } {
    const option = property.options.find((o) => o.name === 'required');

    return {
      required: option != null && option.value !== 'false',
      explicitlySetToFalse: option?.value === 'false'
    };
  }

  private static getRequiredGroupOptionInfo({
    property
  }: {
    property: Property;
  }): { groupName: string | null } {
    const option = property.options.find((o) => o.name === 'requiredGroup');

    return {
      groupName: option?.value ?? null
    };
  }

  private static getRequiredGroupUniqueOptionInfo({
    property
  }: {
    property: Property;
  }): { unique: boolean } {
    const option = property.options.find(
      (o) => o.name === 'requiredGroupUnique'
    );

    return {
      unique: option != null && option.value !== 'false'
    };
  }
}
