import { autoinject, bindable } from 'aurelia-framework';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Router } from 'aurelia-router';
import { EditPersonDialog } from '../edit-person-dialog/edit-person-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PersonContactType } from 'common/Types/Entities/PersonContact/PersonContactDto';
import { PersonContactUtils } from '../../classes/EntityManager/entities/PersonContact/PersonContactUtils';
import { I18N } from 'aurelia-i18n';

/**
 * Displays details of a person, and optionally allows the user to edit the person by clicking it.
 */
@autoinject()
export class PersonWidget {
  @bindable public person: Person | null = null;

  /**
   * Whether the user is allowed to click on the person to edit it.
   */
  @bindable public editable = false;

  /**
   * If true, the address, emails & phone numbers will also be shown.
   */
  @bindable public detailed = false;

  protected PersonUtils = PersonUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly i18n: I18N,
    protected readonly router: Router
  ) {}

  @computed(expression('person'), model(EntityName.PersonContact))
  private get personContacts(): Array<PersonContact> {
    if (!this.person) return [];

    return this.entityManager.personContactRepository.getByPersonId(
      this.person.id
    );
  }

  @computed(expression('personContacts'))
  protected get personEmailContacts(): Array<PersonContact> {
    return this.personContacts
      .filter(PersonContactUtils.isOfType(PersonContactType.EMAIL))
      .sort(PersonContactUtils.orderWithHighlightedFirst);
  }

  @computed(expression('personContacts'))
  protected get personPhoneContacts(): Array<PersonContact> {
    return this.personContacts
      .filter(PersonContactUtils.isOfType(PersonContactType.PHONE))
      .sort(PersonContactUtils.orderWithHighlightedFirst);
  }

  @computed(expression('person'))
  protected get addressLine1(): string {
    if (!this.person) return '';

    return PersonUtils.getPersonAddressLine1(this.person, this.i18n);
  }

  @computed(expression('person'))
  protected get addressLine2(): string {
    if (!this.person) return '';

    return PersonUtils.getPersonAddressLine2(this.person);
  }

  protected handleClick(): void {
    assertNotNullOrUndefined(this.person, 'cannot handleClick without person');

    void EditPersonDialog.open({
      person: this.person
    });
  }
}
