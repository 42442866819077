import { autoinject } from 'aurelia-framework';
import {
  ActiveUserCompanySettingService,
  BindablePropertyPaths
} from '../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { SubscriptionManager } from '../classes/SubscriptionManager';

/**
 * A map that stores which css variable (or variables) to update
 * when a certain active user company property updates.
 */
const colorSources: Partial<
  Record<BindablePropertyPaths, string | Array<string>>
> = {
  'general.primaryColor': [
    '--record-it-color-primary',
    '--record-it-color-main-page-loader'
  ],
  'general.primaryLightColor': '--record-it-color-primary-light',
  'general.secondaryColor': '--record-it-color-secondary',
  'general.breadcrumbBackgroundColor': '--record-it-color-breadcrumb',
  'defectManagement.pictureBorderColor': '--defect-picture-border-color',
  'ultraRapidFireWidget.highlightedPictureBorderColor':
    '--urfm-picture-border-color'
};
/**
 * start up optimization:
 * * call the loadThemeFromDisk method as early as possible
 * * call the loadThemeFromActiveUserCompanySettings method as soon as everything else in your app is ready
 */
@autoinject()
export class ThemingService {
  private static LAST_THEME_DATABASE_KEY = 'ThemingService::lastTheme';

  private readonly subscriptionManager: SubscriptionManager;

  private theme: ColorTheme = {
    cssVariables: {}
  };

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * Restore the last used theme from disk.
   */
  public async loadThemeFromDisk(): Promise<void> {
    const lastTheme: ColorTheme | null = await DataStorageHelper.getItem(
      ThemingService.LAST_THEME_DATABASE_KEY
    );
    if (!lastTheme) return;
    this.theme = lastTheme;
    this.setCssVariables(lastTheme);
  }

  /**
   * Load the current theme from the active user company settings.
   */
  public async loadThemeFromActiveUserCompanySettings(): Promise<void> {
    for (const sourcePath of Object.keys(
      colorSources
    ) as Array<BindablePropertyPaths>) {
      const cssVariables = colorSources[sourcePath]!;
      this.subscriptionManager.addDisposable(
        this.activeUserCompanySettingService.bindSettingProperty(
          sourcePath,
          (colorValue) => {
            if (this.activeUserCompanySettingService.fallbackIsActive()) return;
            const colorNames = Array.isArray(cssVariables)
              ? cssVariables
              : [cssVariables];
            for (const colorName of colorNames) {
              this.theme.cssVariables[colorName] = colorValue;
            }
            this.setCssVariables(this.theme);
            void this.saveThemeToDisk(this.theme);
          }
        )
      );
    }
  }

  private setCssVariables(theme: ColorTheme): void {
    for (const key in theme.cssVariables) {
      if (theme.cssVariables.hasOwnProperty(key)) {
        document.documentElement.style.setProperty(
          key,
          theme.cssVariables[key] ?? null
        );
      }
    }
  }

  private async saveThemeToDisk(theme: ColorTheme): Promise<void> {
    await DataStorageHelper.setItem(
      ThemingService.LAST_THEME_DATABASE_KEY,
      theme
    );
  }
}

export type ColorTheme = {
  cssVariables: {
    [s: string]: string;
  };
};
