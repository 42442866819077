import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from './PropertyBinder/PropertyBinder';
import { PermissionHelper, UserPermission } from './PermissionHelper';
import { EntityName } from './EntityManager/entities/types';
import { AppEntityManager } from './EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';
import { CurrentUserService } from './EntityManager/entities/User/CurrentUserService';
import { User } from './EntityManager/entities/User/types';
import { UserGroup } from './EntityManager/entities/UserGroup/types';
import { BaseEntity } from './EntityManager/entities/BaseEntity';
import { SubscriptionManager } from './SubscriptionManager';
import { Disposable } from './Utils/DisposableContainer';

/**
 * a class which fetches/updates the currentUser + editableUserGroups when necessary
 * also has the possibility to check if an entity is editable with automatic updating
 *
 * usage:
 * * create an instance of the class (can also be in the constructor of the element, no listeners will be initialized yet)
 * * call the `subscribe()` function when you want to receive the updates (will mostly be used in `attached()`)
 * * set the permission entity if needed with the `setPermissionEntity` function
 * * bind all the properties you need access to (`registerBinding` and `bindUserHasPermission`)
 * * call the `dispose()` function when you don't need the updates anymore (will mostly be used in `detached()`)
 *
 */
export class PermissionDataSubscriber {
  private readonly propertyBinder =
    new PropertyBinder<PermissionDataSubscriberPropertyBinderConfig>({
      defaultValuesByName: {
        currentUser: null,
        editableUserGroups: [],
        permissionEntityEditable: false
      }
    });

  private readonly subscriptionManager: SubscriptionManager;
  private readonly permissionEntitySubscriptionManager: SubscriptionManager;

  /**
   * a map of user permission names to a config to bind the permission to
   */
  private userPermissionNameBindingMap: Partial<
    Record<UserPermission, Array<{ target: any; targetPropertyName: string }>>
  > = {};

  private permissionEntity: BaseEntity | null = null;
  private permissionEntityUserGroupFieldName: string | null = null;

  constructor(private readonly options: PermissionDataSubscripterOptions) {
    this.subscriptionManager = options.subscriptionManagerService.create();
    this.permissionEntitySubscriptionManager =
      options.subscriptionManagerService.create();
  }

  public subscribe(): void {
    this.subscriptionManager.addDisposable(
      this.options.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateUserGroups.bind(this)
    );
    this.updateUserGroups();
  }

  public setPermissionEntity(
    entity: BaseEntity | null,
    userGroupFieldName: string | null
  ): void {
    this.permissionEntity = entity;
    this.permissionEntityUserGroupFieldName = userGroupFieldName;

    this.permissionEntitySubscriptionManager.disposeSubscriptions();

    if (entity && userGroupFieldName) {
      this.permissionEntitySubscriptionManager.subscribeToPropertyChange(
        entity,
        userGroupFieldName,
        this.updatePermissionEntityIsEditable.bind(this)
      );
    }

    this.updatePermissionEntityIsEditable();
  }

  public registerBinding<
    TName extends
      PropertyBinderName<PermissionDataSubscriberPropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<
      PermissionDataSubscriberPropertyBinderConfig,
      TName
    >
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public bindUserHasPermission(
    permissionName: UserPermission,
    target: any,
    targetPropertyName: string
  ): void {
    target[targetPropertyName] = this.currentUserHasPermission(permissionName);

    let bindings = this.userPermissionNameBindingMap[permissionName];
    if (!bindings) {
      bindings = this.userPermissionNameBindingMap[permissionName] = [];
    }

    bindings.push({
      target,
      targetPropertyName
    });
  }

  /**
   * dispose all bindings + subscriptions + permission entity
   */
  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionEntitySubscriptionManager.disposeSubscriptions();
    this.propertyBinder.unregisterAllBindings();

    this.userPermissionNameBindingMap = {};
    this.permissionEntity = null;
    this.permissionEntityUserGroupFieldName = null;

    this.propertyBinder.setValue('permissionEntityEditable', false);
    this.propertyBinder.setValue('currentUser', null);
    this.propertyBinder.setValue('editableUserGroups', []);
  }

  private updateCurrentUser(): void {
    this.propertyBinder.setValue(
      'currentUser',
      this.options.currentUserService.getCurrentUser()
    );
    this.updateUserGroups();
    this.updateUserPermissionBindings();
  }

  private updateUserGroups(): void {
    const currentUser = this.propertyBinder.getRequiredValue('currentUser');

    if (currentUser) {
      this.propertyBinder.setValue(
        'editableUserGroups',
        this.options.entityManager.userGroupRepository.getEditableGroupsForUser(
          currentUser
        )
      );
    } else {
      this.propertyBinder.setValue('editableUserGroups', []);
    }

    this.updatePermissionEntityIsEditable();
  }

  private updatePermissionEntityIsEditable(): void {
    let editable = false;

    if (this.permissionEntity && this.permissionEntityUserGroupFieldName) {
      const userGroupId = (this.permissionEntity as any)[
        this.permissionEntityUserGroupFieldName
      ];
      const userGroup = this.propertyBinder
        .getRequiredValue('editableUserGroups')
        .find((group) => group.id === userGroupId);
      const currentUser = this.propertyBinder.getRequiredValue('currentUser');

      editable = !!currentUser && (!!currentUser.admin || !!userGroup);
    }

    this.propertyBinder.setValue('permissionEntityEditable', editable);
  }

  private updateUserPermissionBindings(): void {
    for (const permissionName of Object.keys(
      this.userPermissionNameBindingMap
    ) as Array<UserPermission>) {
      const bindingInfos = this.userPermissionNameBindingMap[permissionName];
      const hasPermission = this.currentUserHasPermission(permissionName);

      bindingInfos?.forEach((info) => {
        info.target[info.targetPropertyName] = hasPermission;
      });
    }
  }

  private currentUserHasPermission(permissionName: UserPermission): boolean {
    const currentUser = this.propertyBinder.getRequiredValue('currentUser');
    if (!currentUser) {
      return false;
    }

    return PermissionHelper.userHasPermission(currentUser, permissionName);
  }
}

export type PermissionDataSubscripterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  currentUserService: CurrentUserService;
};

type PermissionDataSubscriberPropertyBinderConfig = {
  currentUser: User | null;
  editableUserGroups: Array<UserGroup>;
  permissionEntityEditable: boolean;
};
