import {
  DataForDeveloperCommandName,
  DeveloperCommandName,
  developerCommands
} from 'common/DeveloperCommand/developerCommands';
import { SocketService } from '../../services/SocketService';

export class DeveloperCommandCenter {
  public execute: Readonly<Execute>;

  constructor(private readonly socketService: SocketService) {
    this.execute = this.createExecute();
  }

  private createExecute(): Execute {
    const execute: Partial<Execute> = {};

    for (const [commandName, config] of Object.entries(developerCommands)) {
      execute[commandName as DeveloperCommandName] = (data) => {
        const parsedData = config.dataSchema.parse(data);

        this.socketService.executeDeveloperCommand(
          {
            commandName: commandName as DeveloperCommandName,
            data: parsedData
          },
          (response) => {
            console.log(`executed command ${commandName}`, response);
          }
        );
      };
    }

    return execute as Execute;
  }
}

type Execute = {
  [key in DeveloperCommandName]: (
    data: DataForDeveloperCommandName<key>
  ) => void;
};
