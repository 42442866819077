import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SocketService } from '../../services/SocketService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import {
  FieldInfoConfigContraint,
  FieldInfosFromConfig,
  ParsedLineData
} from '../../aureliaComponents/csv-import-widget/csv-import-widget';
import { Dialogs } from '../../classes/Dialogs';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';
import { configureHooks } from '../../hooks/configureHooks';
import { Logger } from '../../classes/Logger/Logger';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class ImportFromCsvFileDialog<T extends FieldInfoConfigContraint> {
  private socketService: SocketService;
  private readonly subscriptionManager: SubscriptionManager;

  protected dialogViewModel: RecordItDialog | null = null;

  protected loading = false;

  protected isOnline = false;

  protected overwrite = false;
  protected fieldsAsProperties = true;
  protected startRow = 1;

  protected additionalImportOptionsResult: AdditionalImportOptionsCallbackParam =
    {};

  protected availableColumnSeparators = [
    { char: ',', label: ',' },
    { char: ';', label: ';' },
    { char: '	', label: 'Tab' }
  ];

  protected availableDecimalSeparators = [
    { char: ',', label: ',' },
    { char: '.', label: '.' }
  ];

  protected columnSeparator = ',';
  protected decimalSeparator = ',';

  protected fileContents: string | null = null;

  protected columns: Record<string, string> = {};

  protected selectedUserGroup: UserGroup | null = null;

  protected columnsNotValid = false;

  protected options: DialogOptions<T> | null = null;

  protected parsedData: Array<ParsedLineData<T>> = [];

  constructor(
    socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.socketService = socketService;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public resetValues(): void {
    this.options = null;
    this.loading = false;
    this.overwrite = false;
    this.fieldsAsProperties = true;
    this.startRow = 1;
    this.columns = {};
    this.columnsNotValid = true;
  }

  public open(options: DialogOptions<T>): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.resetValues();

    this.overwrite = options.overwriteDefaultValue ?? false;

    const file = options.file;
    this.options = Object.assign({}, { showUserGroupSelect: true }, options);

    this.fileContents = null;

    const reader = new FileReader();
    reader.onload = () => {
      this.fileContents = reader.result as string | null;
    };
    reader.readAsText(file);

    if (this.dialogViewModel) this.dialogViewModel.open();
  }

  public close(): void {
    if (this.dialogViewModel) this.dialogViewModel.close();
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleCloseDialogClick(): void {
    this.close();
  }

  protected async handleImportCSVClick(): Promise<void> {
    assertNotNullOrUndefined(this.options, 'no options set');

    this.loading = true;

    const callbackParam: CallbackParamsWithoutUserGroup<T> = {
      columnSeparator: this.columnSeparator,
      decimalSeparator: this.decimalSeparator,
      fieldSpecification: this.columns,
      fieldsAsProperties: this.fieldsAsProperties,
      overwrite: this.overwrite,
      startRow: this.startRow,
      parsedContent: this.parsedData,
      additionalImportOptions: this.additionalImportOptionsResult
    };

    try {
      if (this.options.showUserGroupSelect === false) {
        await this.options.importFromCsvFileCallback(callbackParam);
      } else {
        assertNotNullOrUndefined(
          this.selectedUserGroup,
          'no usergroup selected'
        );
        const callbackParamsWithUserGroup: CallbackParamsWithUserGroup<T> = {
          ...callbackParam,
          userGroupId: this.selectedUserGroup.id
        };
        await this.options.importFromCsvFileCallback(
          callbackParamsWithUserGroup
        );
      }
    } catch (error) {
      Logger.logError({ error });
      void Dialogs.errorDialogTk('general.error', 'general.unspecifiedError');
    }

    this.close();
  }

  @computed(expression('loading'))
  protected get disableClosingDialog(): boolean {
    return this.loading;
  }

  @computed(
    expression('isOnline'),
    expression('columnsNotValid'),
    expression('loading'),
    expression('selectedUserGroup'),
    expression('options.showUserGroupSelect')
  )
  protected get disableSubmitButton(): boolean {
    const disabled = !this.isOnline || this.columnsNotValid || this.loading;
    if (this.options?.showUserGroupSelect === false) {
      return disabled;
    } else {
      return disabled || !this.selectedUserGroup;
    }
  }

  public static async open<T extends FieldInfoConfigContraint>(
    options: DialogOptions<T>
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    (view.getViewModel() as ImportFromCsvFileDialog<T>).open(options);
  }
}

export type DialogOptions<T extends FieldInfoConfigContraint> =
  DialogOptionsCommon<T> &
    (DialogOptionsWithUserGroup<T> | DialogOptionsWithoutUserGroup<T>);

type DialogOptionsCommon<T extends FieldInfoConfigContraint> = {
  file: File;
  fields: FieldInfosFromConfig<T>;
  entityNameTk: string;
  showAdditionalFieldsAsParametersCheckbox: boolean;
  showOverwriteCheckbox: boolean;
  overwriteDefaultValue?: boolean;
  overwriteCheckboxInfoText?: string | null;
  additionalImportOptions?: AdditionalImportOptions;
};

type DialogOptionsWithUserGroup<T extends FieldInfoConfigContraint> = {
  showUserGroupSelect?: true | null;
  editableUserGroups: Array<UserGroup>;
  importFromCsvFileCallback: CallbackWithUserGroup<T>;
};

type DialogOptionsWithoutUserGroup<T extends FieldInfoConfigContraint> = {
  showUserGroupSelect: false;
  importFromCsvFileCallback: CallbackWithoutUserGroup<T>;
};

type CallbackWithUserGroup<T extends FieldInfoConfigContraint> = (
  detail: CallbackParamsWithUserGroup<T>
) => Promise<void>;

type CallbackWithoutUserGroup<T extends FieldInfoConfigContraint> = (
  detail: CallbackParamsWithoutUserGroup<T>
) => Promise<void>;

export type CallbackParams<T extends FieldInfoConfigContraint> =
  CommonCallbackParams<T> &
    (CallbackParamsUserGroupMandatory | CallbackParamsUserGroupOptional);

export type CallbackParamsWithUserGroup<T extends FieldInfoConfigContraint> =
  CommonCallbackParams<T> & CallbackParamsUserGroupMandatory;

export type CallbackParamsWithoutUserGroup<T extends FieldInfoConfigContraint> =
  CommonCallbackParams<T> & CallbackParamsUserGroupOptional;

type CommonCallbackParams<T extends FieldInfoConfigContraint> = {
  columnSeparator: string;
  decimalSeparator: string;
  fieldSpecification: Record<string, string>;
  fieldsAsProperties: boolean;
  overwrite: boolean;
  startRow: number;
  parsedContent: Array<ParsedLineData<T>>;
  additionalImportOptions?: AdditionalImportOptionsCallbackParam;
};

type CallbackParamsUserGroupMandatory = {
  userGroupId: string;
};

type CallbackParamsUserGroupOptional = {
  userGroupId?: string | null;
};

type AdditionalImportOptions = Record<string, { labelTk: string }>;
type AdditionalImportOptionsCallbackParam = Partial<Record<string, boolean>>;
