export class SortUtils {
  public static localeCompareFalsyStrings(
    a: string | null | undefined,
    b: string | null | undefined,
    mode: NullCompareMode = NullCompareMode.FalsyToEnd,
    numericStrings: boolean = false
  ): number {
    const validA = a ?? '';
    const validB = b ?? '';

    if (mode === NullCompareMode.FalsyToEnd) {
      if (!validA && !validB) return 0;
      if (!validA) return 1;
      if (!validB) return -1;
    }

    if (numericStrings) {
      return validA.localeCompare(validB, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    }

    return validA.localeCompare(validB);
  }

  public static localeCompareFalsyNumericStrings(
    a: string | null,
    b: string | null,
    mode: NullCompareMode = NullCompareMode.FalsyToEnd
  ): number {
    return this.localeCompareFalsyStrings(a, b, mode, true);
  }
}

export enum NullCompareMode {
  FalsyToBegin = 0,
  FalsyToEnd = 1
}
