/*
  THIS FILE IS AUTOMATICALLY CREATED BY A GENERATION SCRIPT.
  Manual changes will not be persisted.
*/

import { createRepositoryByEntityNameFactory } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManagerEntityTypesByEntityName } from './AppEntityManagerEntityTypesByEntityName';
import { AppEntityRepositoryOptions } from '../base/AppEntityRepository';
import { SessionService } from '../../../services/SessionService/SessionService';
import { DefectRepository } from './Defect/DefectRepository';
import { defectEntityInfo } from './Defect/defectEntityInfo';
import { DefectChangeLogEntryRepository } from './DefectChangeLogEntry/DefectChangeLogEntryRepository';
import { defectChangeLogEntryEntityInfo } from './DefectChangeLogEntry/defectChangeLogEntryEntityInfo';
import { DefectCommentRepository } from './DefectComment/DefectCommentRepository';
import { defectCommentEntityInfo } from './DefectComment/defectCommentEntityInfo';
import { EntityDashboardInfoRepository } from './EntityDashboardInfo/EntityDashboardInfoRepository';
import { entityDashboardInfoEntityInfo } from './EntityDashboardInfo/entityDashboardInfoEntityInfo';
import { EntryRepository } from './Entry/EntryRepository';
import { entryEntityInfo } from './Entry/entryEntityInfo';
import { EntryToPersonRepository } from './EntryToPerson/EntryToPersonRepository';
import { entryToPersonEntityInfo } from './EntryToPerson/entryToPersonEntityInfo';
import { EntryUsageStatisticRepository } from './EntryUsageStatistic/EntryUsageStatisticRepository';
import { entryUsageStatisticEntityInfo } from './EntryUsageStatistic/entryUsageStatisticEntityInfo';
import { ExternalUserCredentialRepository } from './ExternalUserCredential/ExternalUserCredentialRepository';
import { externalUserCredentialEntityInfo } from './ExternalUserCredential/externalUserCredentialEntityInfo';
import { GeneralFileRepository } from './GeneralFile/GeneralFileRepository';
import { generalFileEntityInfo } from './GeneralFile/generalFileEntityInfo';
import { MapLayerRepository } from './MapLayer/MapLayerRepository';
import { mapLayerEntityInfo } from './MapLayer/mapLayerEntityInfo';
import { NfcTokenRepository } from './NfcToken/NfcTokenRepository';
import { nfcTokenEntityInfo } from './NfcToken/nfcTokenEntityInfo';
import { NfcTokenToPersonRepository } from './NfcTokenToPerson/NfcTokenToPersonRepository';
import { nfcTokenToPersonEntityInfo } from './NfcTokenToPerson/nfcTokenToPersonEntityInfo';
import { NfcTokenToThingRepository } from './NfcTokenToThing/NfcTokenToThingRepository';
import { nfcTokenToThingEntityInfo } from './NfcTokenToThing/nfcTokenToThingEntityInfo';
import { PersonRepository } from './Person/PersonRepository';
import { personEntityInfo } from './Person/personEntityInfo';
import { PersonContactRepository } from './PersonContact/PersonContactRepository';
import { personContactEntityInfo } from './PersonContact/personContactEntityInfo';
import { PersonToPersonRepository } from './PersonToPerson/PersonToPersonRepository';
import { personToPersonEntityInfo } from './PersonToPerson/personToPersonEntityInfo';
import { PictureRepository } from './Picture/PictureRepository';
import { pictureEntityInfo } from './Picture/pictureEntityInfo';
import { PictureFileRepository } from './PictureFile/PictureFileRepository';
import { pictureFileEntityInfo } from './PictureFile/pictureFileEntityInfo';
import { PictureRevisionRepository } from './PictureRevision/PictureRevisionRepository';
import { pictureRevisionEntityInfo } from './PictureRevision/pictureRevisionEntityInfo';
import { ProcessConfigurationRepository } from './ProcessConfiguration/ProcessConfigurationRepository';
import { processConfigurationEntityInfo } from './ProcessConfiguration/processConfigurationEntityInfo';
import { ProcessConfigurationActionStatusRepository } from './ProcessConfigurationActionStatus/ProcessConfigurationActionStatusRepository';
import { processConfigurationActionStatusEntityInfo } from './ProcessConfigurationActionStatus/processConfigurationActionStatusEntityInfo';
import { ProcessConfigurationAuthorizationTypeRepository } from './ProcessConfigurationAuthorizationType/ProcessConfigurationAuthorizationTypeRepository';
import { processConfigurationAuthorizationTypeEntityInfo } from './ProcessConfigurationAuthorizationType/processConfigurationAuthorizationTypeEntityInfo';
import { ProcessConfigurationCategoryRepository } from './ProcessConfigurationCategory/ProcessConfigurationCategoryRepository';
import { processConfigurationCategoryEntityInfo } from './ProcessConfigurationCategory/processConfigurationCategoryEntityInfo';
import { ProcessConfigurationDeviceRepository } from './ProcessConfigurationDevice/ProcessConfigurationDeviceRepository';
import { processConfigurationDeviceEntityInfo } from './ProcessConfigurationDevice/processConfigurationDeviceEntityInfo';
import { ProcessConfigurationDeviceExportRepository } from './ProcessConfigurationDeviceExport/ProcessConfigurationDeviceExportRepository';
import { processConfigurationDeviceExportEntityInfo } from './ProcessConfigurationDeviceExport/processConfigurationDeviceExportEntityInfo';
import { ProcessConfigurationFollowUpAppointmentRepository } from './ProcessConfigurationFollowUpAppointment/ProcessConfigurationFollowUpAppointmentRepository';
import { processConfigurationFollowUpAppointmentEntityInfo } from './ProcessConfigurationFollowUpAppointment/processConfigurationFollowUpAppointmentEntityInfo';
import { ProcessConfigurationFormRepository } from './ProcessConfigurationForm/ProcessConfigurationFormRepository';
import { processConfigurationFormEntityInfo } from './ProcessConfigurationForm/processConfigurationFormEntityInfo';
import { ProcessConfigurationFormSendTargetRepository } from './ProcessConfigurationFormSendTarget/ProcessConfigurationFormSendTargetRepository';
import { processConfigurationFormSendTargetEntityInfo } from './ProcessConfigurationFormSendTarget/processConfigurationFormSendTargetEntityInfo';
import { ProcessConfigurationMeasurePointTypeRepository } from './ProcessConfigurationMeasurePointType/ProcessConfigurationMeasurePointTypeRepository';
import { processConfigurationMeasurePointTypeEntityInfo } from './ProcessConfigurationMeasurePointType/processConfigurationMeasurePointTypeEntityInfo';
import { ProcessConfigurationStepRepository } from './ProcessConfigurationStep/ProcessConfigurationStepRepository';
import { processConfigurationStepEntityInfo } from './ProcessConfigurationStep/processConfigurationStepEntityInfo';
import { ProcessConfigurationStepAutoAppointmentRepository } from './ProcessConfigurationStepAutoAppointment/ProcessConfigurationStepAutoAppointmentRepository';
import { processConfigurationStepAutoAppointmentEntityInfo } from './ProcessConfigurationStepAutoAppointment/processConfigurationStepAutoAppointmentEntityInfo';
import { ProcessConfigurationStepAutoFormRepository } from './ProcessConfigurationStepAutoForm/ProcessConfigurationStepAutoFormRepository';
import { processConfigurationStepAutoFormEntityInfo } from './ProcessConfigurationStepAutoForm/processConfigurationStepAutoFormEntityInfo';
import { ProcessConfigurationStepPositionRepository } from './ProcessConfigurationStepPosition/ProcessConfigurationStepPositionRepository';
import { processConfigurationStepPositionEntityInfo } from './ProcessConfigurationStepPosition/processConfigurationStepPositionEntityInfo';
import { ProcessTaskRepository } from './ProcessTask/ProcessTaskRepository';
import { processTaskEntityInfo } from './ProcessTask/processTaskEntityInfo';
import { ProcessTaskAppointmentRepository } from './ProcessTaskAppointment/ProcessTaskAppointmentRepository';
import { processTaskAppointmentEntityInfo } from './ProcessTaskAppointment/processTaskAppointmentEntityInfo';
import { ProcessTaskAppointmentContactRepository } from './ProcessTaskAppointmentContact/ProcessTaskAppointmentContactRepository';
import { processTaskAppointmentContactEntityInfo } from './ProcessTaskAppointmentContact/processTaskAppointmentContactEntityInfo';
import { ProcessTaskAppointmentToProcessTaskDeviceRepository } from './ProcessTaskAppointmentToProcessTaskDevice/ProcessTaskAppointmentToProcessTaskDeviceRepository';
import { processTaskAppointmentToProcessTaskDeviceEntityInfo } from './ProcessTaskAppointmentToProcessTaskDevice/processTaskAppointmentToProcessTaskDeviceEntityInfo';
import { ProcessTaskAppointmentToProcessTaskPositionRepository } from './ProcessTaskAppointmentToProcessTaskPosition/ProcessTaskAppointmentToProcessTaskPositionRepository';
import { processTaskAppointmentToProcessTaskPositionEntityInfo } from './ProcessTaskAppointmentToProcessTaskPosition/processTaskAppointmentToProcessTaskPositionEntityInfo';
import { ProcessTaskAppointmentToUserRepository } from './ProcessTaskAppointmentToUser/ProcessTaskAppointmentToUserRepository';
import { processTaskAppointmentToUserEntityInfo } from './ProcessTaskAppointmentToUser/processTaskAppointmentToUserEntityInfo';
import { ProcessTaskChecklistEntryRepository } from './ProcessTaskChecklistEntry/ProcessTaskChecklistEntryRepository';
import { processTaskChecklistEntryEntityInfo } from './ProcessTaskChecklistEntry/processTaskChecklistEntryEntityInfo';
import { ProcessTaskCommentRepository } from './ProcessTaskComment/ProcessTaskCommentRepository';
import { processTaskCommentEntityInfo } from './ProcessTaskComment/processTaskCommentEntityInfo';
import { ProcessTaskDeviceRepository } from './ProcessTaskDevice/ProcessTaskDeviceRepository';
import { processTaskDeviceEntityInfo } from './ProcessTaskDevice/processTaskDeviceEntityInfo';
import { ProcessTaskGroupRepository } from './ProcessTaskGroup/ProcessTaskGroupRepository';
import { processTaskGroupEntityInfo } from './ProcessTaskGroup/processTaskGroupEntityInfo';
import { ProcessTaskGroupAuthorizationRepository } from './ProcessTaskGroupAuthorization/ProcessTaskGroupAuthorizationRepository';
import { processTaskGroupAuthorizationEntityInfo } from './ProcessTaskGroupAuthorization/processTaskGroupAuthorizationEntityInfo';
import { ProcessTaskGroupFilterRepository } from './ProcessTaskGroupFilter/ProcessTaskGroupFilterRepository';
import { processTaskGroupFilterEntityInfo } from './ProcessTaskGroupFilter/processTaskGroupFilterEntityInfo';
import { ProcessTaskInvoiceRepository } from './ProcessTaskInvoice/ProcessTaskInvoiceRepository';
import { processTaskInvoiceEntityInfo } from './ProcessTaskInvoice/processTaskInvoiceEntityInfo';
import { ProcessTaskInvoiceToProcessTaskRepository } from './ProcessTaskInvoiceToProcessTask/ProcessTaskInvoiceToProcessTaskRepository';
import { processTaskInvoiceToProcessTaskEntityInfo } from './ProcessTaskInvoiceToProcessTask/processTaskInvoiceToProcessTaskEntityInfo';
import { ProcessTaskInvoiceToProcessTaskDeviceRepository } from './ProcessTaskInvoiceToProcessTaskDevice/ProcessTaskInvoiceToProcessTaskDeviceRepository';
import { processTaskInvoiceToProcessTaskDeviceEntityInfo } from './ProcessTaskInvoiceToProcessTaskDevice/processTaskInvoiceToProcessTaskDeviceEntityInfo';
import { ProcessTaskInvoiceToProcessTaskPositionRepository } from './ProcessTaskInvoiceToProcessTaskPosition/ProcessTaskInvoiceToProcessTaskPositionRepository';
import { processTaskInvoiceToProcessTaskPositionEntityInfo } from './ProcessTaskInvoiceToProcessTaskPosition/processTaskInvoiceToProcessTaskPositionEntityInfo';
import { ProcessTaskLogEntryRepository } from './ProcessTaskLogEntry/ProcessTaskLogEntryRepository';
import { processTaskLogEntryEntityInfo } from './ProcessTaskLogEntry/processTaskLogEntryEntityInfo';
import { ProcessTaskMeasurePointRepository } from './ProcessTaskMeasurePoint/ProcessTaskMeasurePointRepository';
import { processTaskMeasurePointEntityInfo } from './ProcessTaskMeasurePoint/processTaskMeasurePointEntityInfo';
import { ProcessTaskMeasurePointReadingRepository } from './ProcessTaskMeasurePointReading/ProcessTaskMeasurePointReadingRepository';
import { processTaskMeasurePointReadingEntityInfo } from './ProcessTaskMeasurePointReading/processTaskMeasurePointReadingEntityInfo';
import { ProcessTaskMeasurePointToPictureRepository } from './ProcessTaskMeasurePointToPicture/ProcessTaskMeasurePointToPictureRepository';
import { processTaskMeasurePointToPictureEntityInfo } from './ProcessTaskMeasurePointToPicture/processTaskMeasurePointToPictureEntityInfo';
import { ProcessTaskOfferRepository } from './ProcessTaskOffer/ProcessTaskOfferRepository';
import { processTaskOfferEntityInfo } from './ProcessTaskOffer/processTaskOfferEntityInfo';
import { ProcessTaskOfferToProcessTaskRepository } from './ProcessTaskOfferToProcessTask/ProcessTaskOfferToProcessTaskRepository';
import { processTaskOfferToProcessTaskEntityInfo } from './ProcessTaskOfferToProcessTask/processTaskOfferToProcessTaskEntityInfo';
import { ProcessTaskOfferToProcessTaskDeviceRepository } from './ProcessTaskOfferToProcessTaskDevice/ProcessTaskOfferToProcessTaskDeviceRepository';
import { processTaskOfferToProcessTaskDeviceEntityInfo } from './ProcessTaskOfferToProcessTaskDevice/processTaskOfferToProcessTaskDeviceEntityInfo';
import { ProcessTaskOfferToProcessTaskPositionRepository } from './ProcessTaskOfferToProcessTaskPosition/ProcessTaskOfferToProcessTaskPositionRepository';
import { processTaskOfferToProcessTaskPositionEntityInfo } from './ProcessTaskOfferToProcessTaskPosition/processTaskOfferToProcessTaskPositionEntityInfo';
import { ProcessTaskPositionRepository } from './ProcessTaskPosition/ProcessTaskPositionRepository';
import { processTaskPositionEntityInfo } from './ProcessTaskPosition/processTaskPositionEntityInfo';
import { ProcessTaskPositionDetailEntryRepository } from './ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryRepository';
import { processTaskPositionDetailEntryEntityInfo } from './ProcessTaskPositionDetailEntry/processTaskPositionDetailEntryEntityInfo';
import { ProcessTaskRecurringAppointmentRepository } from './ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentRepository';
import { processTaskRecurringAppointmentEntityInfo } from './ProcessTaskRecurringAppointment/processTaskRecurringAppointmentEntityInfo';
import { ProcessTaskToProjectRepository } from './ProcessTaskToProject/ProcessTaskToProjectRepository';
import { processTaskToProjectEntityInfo } from './ProcessTaskToProject/processTaskToProjectEntityInfo';
import { ProjectRepository } from './Project/ProjectRepository';
import { projectEntityInfo } from './Project/projectEntityInfo';
import { ProjectQuestionRepository } from './ProjectQuestion/ProjectQuestionRepository';
import { projectQuestionEntityInfo } from './ProjectQuestion/projectQuestionEntityInfo';
import { ProjectQuestionCatalogueRepository } from './ProjectQuestionCatalogue/ProjectQuestionCatalogueRepository';
import { projectQuestionCatalogueEntityInfo } from './ProjectQuestionCatalogue/projectQuestionCatalogueEntityInfo';
import { ProjectQuestionCatalogueToQuestionSetRepository } from './ProjectQuestionCatalogueToQuestionSet/ProjectQuestionCatalogueToQuestionSetRepository';
import { projectQuestionCatalogueToQuestionSetEntityInfo } from './ProjectQuestionCatalogueToQuestionSet/projectQuestionCatalogueToQuestionSetEntityInfo';
import { ProjectQuestionCategoryRepository } from './ProjectQuestionCategory/ProjectQuestionCategoryRepository';
import { projectQuestionCategoryEntityInfo } from './ProjectQuestionCategory/projectQuestionCategoryEntityInfo';
import { ProjectQuestionSetRepository } from './ProjectQuestionSet/ProjectQuestionSetRepository';
import { projectQuestionSetEntityInfo } from './ProjectQuestionSet/projectQuestionSetEntityInfo';
import { PropertyRepository } from './Property/PropertyRepository';
import { propertyEntityInfo } from './Property/propertyEntityInfo';
import { PropertyToPersonRepository } from './PropertyToPerson/PropertyToPersonRepository';
import { propertyToPersonEntityInfo } from './PropertyToPerson/propertyToPersonEntityInfo';
import { PropertyToProcessTaskPositionRepository } from './PropertyToProcessTaskPosition/PropertyToProcessTaskPositionRepository';
import { propertyToProcessTaskPositionEntityInfo } from './PropertyToProcessTaskPosition/propertyToProcessTaskPositionEntityInfo';
import { QuestionRepository } from './Question/QuestionRepository';
import { questionEntityInfo } from './Question/questionEntityInfo';
import { QuestionCatalogueRepository } from './QuestionCatalogue/QuestionCatalogueRepository';
import { questionCatalogueEntityInfo } from './QuestionCatalogue/questionCatalogueEntityInfo';
import { QuestionCatalogueToQuestionSetRepository } from './QuestionCatalogueToQuestionSet/QuestionCatalogueToQuestionSetRepository';
import { questionCatalogueToQuestionSetEntityInfo } from './QuestionCatalogueToQuestionSet/questionCatalogueToQuestionSetEntityInfo';
import { QuestionCatalogueToThingRepository } from './QuestionCatalogueToThing/QuestionCatalogueToThingRepository';
import { questionCatalogueToThingEntityInfo } from './QuestionCatalogueToThing/questionCatalogueToThingEntityInfo';
import { QuestionCategoryRepository } from './QuestionCategory/QuestionCategoryRepository';
import { questionCategoryEntityInfo } from './QuestionCategory/questionCategoryEntityInfo';
import { QuestionSetRepository } from './QuestionSet/QuestionSetRepository';
import { questionSetEntityInfo } from './QuestionSet/questionSetEntityInfo';
import { RegionRepository } from './Region/RegionRepository';
import { regionEntityInfo } from './Region/regionEntityInfo';
import { ReportRepository } from './Report/ReportRepository';
import { reportEntityInfo } from './Report/reportEntityInfo';
import { ReportTypeRepository } from './ReportType/ReportTypeRepository';
import { reportTypeEntityInfo } from './ReportType/reportTypeEntityInfo';
import { SharepointListColumnToThingMappingItemRepository } from './SharepointListColumnToThingMappingItem/SharepointListColumnToThingMappingItemRepository';
import { sharepointListColumnToThingMappingItemEntityInfo } from './SharepointListColumnToThingMappingItem/sharepointListColumnToThingMappingItemEntityInfo';
import { StructurePictureAreaRepository } from './StructurePictureArea/StructurePictureAreaRepository';
import { structurePictureAreaEntityInfo } from './StructurePictureArea/structurePictureAreaEntityInfo';
import { StructureTemplateRepository } from './StructureTemplate/StructureTemplateRepository';
import { structureTemplateEntityInfo } from './StructureTemplate/structureTemplateEntityInfo';
import { StructureTemplateEntryRepository } from './StructureTemplateEntry/StructureTemplateEntryRepository';
import { structureTemplateEntryEntityInfo } from './StructureTemplateEntry/structureTemplateEntryEntityInfo';
import { StructureTemplateEntryGroupRepository } from './StructureTemplateEntryGroup/StructureTemplateEntryGroupRepository';
import { structureTemplateEntryGroupEntityInfo } from './StructureTemplateEntryGroup/structureTemplateEntryGroupEntityInfo';
import { StructureTemplateEntryGroupToStructureTemplateEntryRepository } from './StructureTemplateEntryGroupToStructureTemplateEntry/StructureTemplateEntryGroupToStructureTemplateEntryRepository';
import { structureTemplateEntryGroupToStructureTemplateEntryEntityInfo } from './StructureTemplateEntryGroupToStructureTemplateEntry/structureTemplateEntryGroupToStructureTemplateEntryEntityInfo';
import { StructureTemplateEntryPropertyRepository } from './StructureTemplateEntryProperty/StructureTemplateEntryPropertyRepository';
import { structureTemplateEntryPropertyEntityInfo } from './StructureTemplateEntryProperty/structureTemplateEntryPropertyEntityInfo';
import { StructureTemplateRatingCategoryRepository } from './StructureTemplateRatingCategory/StructureTemplateRatingCategoryRepository';
import { structureTemplateRatingCategoryEntityInfo } from './StructureTemplateRatingCategory/structureTemplateRatingCategoryEntityInfo';
import { TagRepository } from './Tag/TagRepository';
import { tagEntityInfo } from './Tag/tagEntityInfo';
import { TextBrickRepository } from './TextBrick/TextBrickRepository';
import { textBrickEntityInfo } from './TextBrick/textBrickEntityInfo';
import { TextBrickTemplateRepository } from './TextBrickTemplate/TextBrickTemplateRepository';
import { textBrickTemplateEntityInfo } from './TextBrickTemplate/textBrickTemplateEntityInfo';
import { ThingRepository } from './Thing/ThingRepository';
import { thingEntityInfo } from './Thing/thingEntityInfo';
import { ThingAuthorizationRepository } from './ThingAuthorization/ThingAuthorizationRepository';
import { thingAuthorizationEntityInfo } from './ThingAuthorization/thingAuthorizationEntityInfo';
import { ThingGroupRepository } from './ThingGroup/ThingGroupRepository';
import { thingGroupEntityInfo } from './ThingGroup/thingGroupEntityInfo';
import { ThingSectionRepository } from './ThingSection/ThingSectionRepository';
import { thingSectionEntityInfo } from './ThingSection/thingSectionEntityInfo';
import { ThingSectionConfigPropertyRepository } from './ThingSectionConfigProperty/ThingSectionConfigPropertyRepository';
import { thingSectionConfigPropertyEntityInfo } from './ThingSectionConfigProperty/thingSectionConfigPropertyEntityInfo';
import { ThingToPersonRepository } from './ThingToPerson/ThingToPersonRepository';
import { thingToPersonEntityInfo } from './ThingToPerson/thingToPersonEntityInfo';
import { ThingTypeRepository } from './ThingType/ThingTypeRepository';
import { thingTypeEntityInfo } from './ThingType/thingTypeEntityInfo';
import { UserRepository } from './User/UserRepository';
import { userEntityInfo } from './User/userEntityInfo';
import { UserCompanySettingRepository } from './UserCompanySetting/UserCompanySettingRepository';
import { userCompanySettingEntityInfo } from './UserCompanySetting/userCompanySettingEntityInfo';
import { UserDefinedEntityRepository } from './UserDefinedEntity/UserDefinedEntityRepository';
import { userDefinedEntityEntityInfo } from './UserDefinedEntity/userDefinedEntityEntityInfo';
import { UserDefinedEntityConfigRepository } from './UserDefinedEntityConfig/UserDefinedEntityConfigRepository';
import { userDefinedEntityConfigEntityInfo } from './UserDefinedEntityConfig/userDefinedEntityConfigEntityInfo';
import { UserDefinedEntityConfigPropertyConfigRepository } from './UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigRepository';
import { userDefinedEntityConfigPropertyConfigEntityInfo } from './UserDefinedEntityConfigPropertyConfig/userDefinedEntityConfigPropertyConfigEntityInfo';
import { UserGroupRepository } from './UserGroup/UserGroupRepository';
import { userGroupEntityInfo } from './UserGroup/userGroupEntityInfo';
import { UserRoleRepository } from './UserRole/UserRoleRepository';
import { userRoleEntityInfo } from './UserRole/userRoleEntityInfo';
import { UserRoleToUserRepository } from './UserRoleToUser/UserRoleToUserRepository';
import { userRoleToUserEntityInfo } from './UserRoleToUser/userRoleToUserEntityInfo';
import { ValueCalculationConfigRepository } from './ValueCalculationConfig/ValueCalculationConfigRepository';
import { valueCalculationConfigEntityInfo } from './ValueCalculationConfig/valueCalculationConfigEntityInfo';
import { ValueCalculationResultRepository } from './ValueCalculationResult/ValueCalculationResultRepository';
import { valueCalculationResultEntityInfo } from './ValueCalculationResult/valueCalculationResultEntityInfo';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createRepositoriesByEntityNameFactory = ({
  sessionService
}: {
  sessionService: SessionService;
}) => {
  return createRepositoryByEntityNameFactory<
    AppSynchronizationEnvironmentTypes,
    AppEntityManagerEntityTypesByEntityName
  >()(({ entityStorage, idGenerator, filterContainer }) => {
    const repositoryOptions: AppEntityRepositoryOptions = {
      sessionService,
      entityRepositoryOptions: {
        entityStorage,
        idGenerator,
        filterContainer,
        preserveEntityInstances: true
      }
    };

    return {
      DefectModel: new DefectRepository(defectEntityInfo, repositoryOptions),
      DefectChangeLogEntryModel: new DefectChangeLogEntryRepository(
        defectChangeLogEntryEntityInfo,
        repositoryOptions
      ),
      DefectCommentModel: new DefectCommentRepository(
        defectCommentEntityInfo,
        repositoryOptions
      ),
      EntityDashboardInfoModel: new EntityDashboardInfoRepository(
        entityDashboardInfoEntityInfo,
        repositoryOptions
      ),
      EntryModel: new EntryRepository(entryEntityInfo, repositoryOptions),
      EntryToPersonModel: new EntryToPersonRepository(
        entryToPersonEntityInfo,
        repositoryOptions
      ),
      EntryUsageStatisticModel: new EntryUsageStatisticRepository(
        entryUsageStatisticEntityInfo,
        repositoryOptions
      ),
      ExternalUserCredentialModel: new ExternalUserCredentialRepository(
        externalUserCredentialEntityInfo,
        repositoryOptions
      ),
      GeneralFileModel: new GeneralFileRepository(
        generalFileEntityInfo,
        repositoryOptions
      ),
      MapLayerModel: new MapLayerRepository(
        mapLayerEntityInfo,
        repositoryOptions
      ),
      NfcTokenModel: new NfcTokenRepository(
        nfcTokenEntityInfo,
        repositoryOptions
      ),
      NfcTokenToPersonModel: new NfcTokenToPersonRepository(
        nfcTokenToPersonEntityInfo,
        repositoryOptions
      ),
      NfcTokenToThingModel: new NfcTokenToThingRepository(
        nfcTokenToThingEntityInfo,
        repositoryOptions
      ),
      PersonModel: new PersonRepository(personEntityInfo, repositoryOptions),
      PersonContactModel: new PersonContactRepository(
        personContactEntityInfo,
        repositoryOptions
      ),
      PersonToPersonModel: new PersonToPersonRepository(
        personToPersonEntityInfo,
        repositoryOptions
      ),
      PictureModel: new PictureRepository(pictureEntityInfo, repositoryOptions),
      PictureFileModel: new PictureFileRepository(
        pictureFileEntityInfo,
        repositoryOptions
      ),
      PictureRevisionModel: new PictureRevisionRepository(
        pictureRevisionEntityInfo,
        repositoryOptions
      ),
      ProcessConfigurationModel: new ProcessConfigurationRepository(
        processConfigurationEntityInfo,
        repositoryOptions
      ),
      ProcessConfigurationActionStatusModel:
        new ProcessConfigurationActionStatusRepository(
          processConfigurationActionStatusEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationAuthorizationTypeModel:
        new ProcessConfigurationAuthorizationTypeRepository(
          processConfigurationAuthorizationTypeEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationCategoryModel:
        new ProcessConfigurationCategoryRepository(
          processConfigurationCategoryEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationDeviceModel: new ProcessConfigurationDeviceRepository(
        processConfigurationDeviceEntityInfo,
        repositoryOptions
      ),
      ProcessConfigurationDeviceExportModel:
        new ProcessConfigurationDeviceExportRepository(
          processConfigurationDeviceExportEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationFollowUpAppointmentModel:
        new ProcessConfigurationFollowUpAppointmentRepository(
          processConfigurationFollowUpAppointmentEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationFormModel: new ProcessConfigurationFormRepository(
        processConfigurationFormEntityInfo,
        repositoryOptions
      ),
      ProcessConfigurationFormSendTargetModel:
        new ProcessConfigurationFormSendTargetRepository(
          processConfigurationFormSendTargetEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationMeasurePointTypeModel:
        new ProcessConfigurationMeasurePointTypeRepository(
          processConfigurationMeasurePointTypeEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationStepModel: new ProcessConfigurationStepRepository(
        processConfigurationStepEntityInfo,
        repositoryOptions
      ),
      ProcessConfigurationStepAutoAppointmentModel:
        new ProcessConfigurationStepAutoAppointmentRepository(
          processConfigurationStepAutoAppointmentEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationStepAutoFormModel:
        new ProcessConfigurationStepAutoFormRepository(
          processConfigurationStepAutoFormEntityInfo,
          repositoryOptions
        ),
      ProcessConfigurationStepPositionModel:
        new ProcessConfigurationStepPositionRepository(
          processConfigurationStepPositionEntityInfo,
          repositoryOptions
        ),
      ProcessTaskModel: new ProcessTaskRepository(
        processTaskEntityInfo,
        repositoryOptions
      ),
      ProcessTaskAppointmentModel: new ProcessTaskAppointmentRepository(
        processTaskAppointmentEntityInfo,
        repositoryOptions
      ),
      ProcessTaskAppointmentContactModel:
        new ProcessTaskAppointmentContactRepository(
          processTaskAppointmentContactEntityInfo,
          repositoryOptions
        ),
      ProcessTaskAppointmentToProcessTaskDeviceModel:
        new ProcessTaskAppointmentToProcessTaskDeviceRepository(
          processTaskAppointmentToProcessTaskDeviceEntityInfo,
          repositoryOptions
        ),
      ProcessTaskAppointmentToProcessTaskPositionModel:
        new ProcessTaskAppointmentToProcessTaskPositionRepository(
          processTaskAppointmentToProcessTaskPositionEntityInfo,
          repositoryOptions
        ),
      ProcessTaskAppointmentToUserModel:
        new ProcessTaskAppointmentToUserRepository(
          processTaskAppointmentToUserEntityInfo,
          repositoryOptions
        ),
      ProcessTaskChecklistEntryModel: new ProcessTaskChecklistEntryRepository(
        processTaskChecklistEntryEntityInfo,
        repositoryOptions
      ),
      ProcessTaskCommentModel: new ProcessTaskCommentRepository(
        processTaskCommentEntityInfo,
        repositoryOptions
      ),
      ProcessTaskDeviceModel: new ProcessTaskDeviceRepository(
        processTaskDeviceEntityInfo,
        repositoryOptions
      ),
      ProcessTaskGroupModel: new ProcessTaskGroupRepository(
        processTaskGroupEntityInfo,
        repositoryOptions
      ),
      ProcessTaskGroupAuthorizationModel:
        new ProcessTaskGroupAuthorizationRepository(
          processTaskGroupAuthorizationEntityInfo,
          repositoryOptions
        ),
      ProcessTaskGroupFilterModel: new ProcessTaskGroupFilterRepository(
        processTaskGroupFilterEntityInfo,
        repositoryOptions
      ),
      ProcessTaskInvoiceModel: new ProcessTaskInvoiceRepository(
        processTaskInvoiceEntityInfo,
        repositoryOptions
      ),
      ProcessTaskInvoiceToProcessTaskModel:
        new ProcessTaskInvoiceToProcessTaskRepository(
          processTaskInvoiceToProcessTaskEntityInfo,
          repositoryOptions
        ),
      ProcessTaskInvoiceToProcessTaskDeviceModel:
        new ProcessTaskInvoiceToProcessTaskDeviceRepository(
          processTaskInvoiceToProcessTaskDeviceEntityInfo,
          repositoryOptions
        ),
      ProcessTaskInvoiceToProcessTaskPositionModel:
        new ProcessTaskInvoiceToProcessTaskPositionRepository(
          processTaskInvoiceToProcessTaskPositionEntityInfo,
          repositoryOptions
        ),
      ProcessTaskLogEntryModel: new ProcessTaskLogEntryRepository(
        processTaskLogEntryEntityInfo,
        repositoryOptions
      ),
      ProcessTaskMeasurePointModel: new ProcessTaskMeasurePointRepository(
        processTaskMeasurePointEntityInfo,
        repositoryOptions
      ),
      ProcessTaskMeasurePointReadingModel:
        new ProcessTaskMeasurePointReadingRepository(
          processTaskMeasurePointReadingEntityInfo,
          repositoryOptions
        ),
      ProcessTaskMeasurePointToPictureModel:
        new ProcessTaskMeasurePointToPictureRepository(
          processTaskMeasurePointToPictureEntityInfo,
          repositoryOptions
        ),
      ProcessTaskOfferModel: new ProcessTaskOfferRepository(
        processTaskOfferEntityInfo,
        repositoryOptions
      ),
      ProcessTaskOfferToProcessTaskModel:
        new ProcessTaskOfferToProcessTaskRepository(
          processTaskOfferToProcessTaskEntityInfo,
          repositoryOptions
        ),
      ProcessTaskOfferToProcessTaskDeviceModel:
        new ProcessTaskOfferToProcessTaskDeviceRepository(
          processTaskOfferToProcessTaskDeviceEntityInfo,
          repositoryOptions
        ),
      ProcessTaskOfferToProcessTaskPositionModel:
        new ProcessTaskOfferToProcessTaskPositionRepository(
          processTaskOfferToProcessTaskPositionEntityInfo,
          repositoryOptions
        ),
      ProcessTaskPositionModel: new ProcessTaskPositionRepository(
        processTaskPositionEntityInfo,
        repositoryOptions
      ),
      ProcessTaskPositionDetailEntryModel:
        new ProcessTaskPositionDetailEntryRepository(
          processTaskPositionDetailEntryEntityInfo,
          repositoryOptions
        ),
      ProcessTaskRecurringAppointmentModel:
        new ProcessTaskRecurringAppointmentRepository(
          processTaskRecurringAppointmentEntityInfo,
          repositoryOptions
        ),
      ProcessTaskToProjectModel: new ProcessTaskToProjectRepository(
        processTaskToProjectEntityInfo,
        repositoryOptions
      ),
      ProjectModel: new ProjectRepository(projectEntityInfo, repositoryOptions),
      ProjectQuestionModel: new ProjectQuestionRepository(
        projectQuestionEntityInfo,
        repositoryOptions
      ),
      ProjectQuestionCatalogueModel: new ProjectQuestionCatalogueRepository(
        projectQuestionCatalogueEntityInfo,
        repositoryOptions
      ),
      ProjectQuestionCatalogueToQuestionSetModel:
        new ProjectQuestionCatalogueToQuestionSetRepository(
          projectQuestionCatalogueToQuestionSetEntityInfo,
          repositoryOptions
        ),
      ProjectQuestionCategoryModel: new ProjectQuestionCategoryRepository(
        projectQuestionCategoryEntityInfo,
        repositoryOptions
      ),
      ProjectQuestionSetModel: new ProjectQuestionSetRepository(
        projectQuestionSetEntityInfo,
        repositoryOptions
      ),
      PropertyModel: new PropertyRepository(
        propertyEntityInfo,
        repositoryOptions
      ),
      PropertyToPersonModel: new PropertyToPersonRepository(
        propertyToPersonEntityInfo,
        repositoryOptions
      ),
      PropertyToProcessTaskPositionModel:
        new PropertyToProcessTaskPositionRepository(
          propertyToProcessTaskPositionEntityInfo,
          repositoryOptions
        ),
      QuestionModel: new QuestionRepository(
        questionEntityInfo,
        repositoryOptions
      ),
      QuestionCatalogueModel: new QuestionCatalogueRepository(
        questionCatalogueEntityInfo,
        repositoryOptions
      ),
      QuestionCatalogueToQuestionSetModel:
        new QuestionCatalogueToQuestionSetRepository(
          questionCatalogueToQuestionSetEntityInfo,
          repositoryOptions
        ),
      QuestionCatalogueToThingModel: new QuestionCatalogueToThingRepository(
        questionCatalogueToThingEntityInfo,
        repositoryOptions
      ),
      QuestionCategoryModel: new QuestionCategoryRepository(
        questionCategoryEntityInfo,
        repositoryOptions
      ),
      QuestionSetModel: new QuestionSetRepository(
        questionSetEntityInfo,
        repositoryOptions
      ),
      RegionModel: new RegionRepository(regionEntityInfo, repositoryOptions),
      ReportModel: new ReportRepository(reportEntityInfo, repositoryOptions),
      ReportTypeModel: new ReportTypeRepository(
        reportTypeEntityInfo,
        repositoryOptions
      ),
      SharepointListColumnToThingMappingItemModel:
        new SharepointListColumnToThingMappingItemRepository(
          sharepointListColumnToThingMappingItemEntityInfo,
          repositoryOptions
        ),
      StructurePictureAreaModel: new StructurePictureAreaRepository(
        structurePictureAreaEntityInfo,
        repositoryOptions
      ),
      StructureTemplateModel: new StructureTemplateRepository(
        structureTemplateEntityInfo,
        repositoryOptions
      ),
      StructureTemplateEntryModel: new StructureTemplateEntryRepository(
        structureTemplateEntryEntityInfo,
        repositoryOptions
      ),
      StructureTemplateEntryGroupModel:
        new StructureTemplateEntryGroupRepository(
          structureTemplateEntryGroupEntityInfo,
          repositoryOptions
        ),
      StructureTemplateEntryGroupToStructureTemplateEntryModel:
        new StructureTemplateEntryGroupToStructureTemplateEntryRepository(
          structureTemplateEntryGroupToStructureTemplateEntryEntityInfo,
          repositoryOptions
        ),
      StructureTemplateEntryPropertyModel:
        new StructureTemplateEntryPropertyRepository(
          structureTemplateEntryPropertyEntityInfo,
          repositoryOptions
        ),
      StructureTemplateRatingCategoryModel:
        new StructureTemplateRatingCategoryRepository(
          structureTemplateRatingCategoryEntityInfo,
          repositoryOptions
        ),
      TagModel: new TagRepository(tagEntityInfo, repositoryOptions),
      TextBrickModel: new TextBrickRepository(
        textBrickEntityInfo,
        repositoryOptions
      ),
      TextBrickTemplateModel: new TextBrickTemplateRepository(
        textBrickTemplateEntityInfo,
        repositoryOptions
      ),
      ThingModel: new ThingRepository(thingEntityInfo, repositoryOptions),
      ThingAuthorizationModel: new ThingAuthorizationRepository(
        thingAuthorizationEntityInfo,
        repositoryOptions
      ),
      ThingGroupModel: new ThingGroupRepository(
        thingGroupEntityInfo,
        repositoryOptions
      ),
      ThingSectionModel: new ThingSectionRepository(
        thingSectionEntityInfo,
        repositoryOptions
      ),
      ThingSectionConfigPropertyModel: new ThingSectionConfigPropertyRepository(
        thingSectionConfigPropertyEntityInfo,
        repositoryOptions
      ),
      ThingToPersonModel: new ThingToPersonRepository(
        thingToPersonEntityInfo,
        repositoryOptions
      ),
      ThingTypeModel: new ThingTypeRepository(
        thingTypeEntityInfo,
        repositoryOptions
      ),
      UserModel: new UserRepository(userEntityInfo, repositoryOptions),
      UserCompanySettingModel: new UserCompanySettingRepository(
        userCompanySettingEntityInfo,
        repositoryOptions
      ),
      UserDefinedEntityModel: new UserDefinedEntityRepository(
        userDefinedEntityEntityInfo,
        repositoryOptions
      ),
      UserDefinedEntityConfigModel: new UserDefinedEntityConfigRepository(
        userDefinedEntityConfigEntityInfo,
        repositoryOptions
      ),
      UserDefinedEntityConfigPropertyConfigModel:
        new UserDefinedEntityConfigPropertyConfigRepository(
          userDefinedEntityConfigPropertyConfigEntityInfo,
          repositoryOptions
        ),
      UserGroupModel: new UserGroupRepository(
        userGroupEntityInfo,
        repositoryOptions
      ),
      UserRoleModel: new UserRoleRepository(
        userRoleEntityInfo,
        repositoryOptions
      ),
      UserRoleToUserModel: new UserRoleToUserRepository(
        userRoleToUserEntityInfo,
        repositoryOptions
      ),
      ValueCalculationConfigModel: new ValueCalculationConfigRepository(
        valueCalculationConfigEntityInfo,
        repositoryOptions
      ),
      ValueCalculationResultModel: new ValueCalculationResultRepository(
        valueCalculationResultEntityInfo,
        repositoryOptions
      )
    };
  });
};
