import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationFollowUpAppointment } from './types';

export class ProcessConfigurationFollowUpAppointmentRepository extends AppEntityRepository<EntityName.ProcessConfigurationFollowUpAppointment> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationFollowUpAppointment> {
    return this.getAll().filter(
      (f) => f.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
