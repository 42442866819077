import { assertNotNullOrUndefined } from 'common/Asserts';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { DateUtils } from '../../../../common/src/DateUtils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { Calendar } from '../../inputComponents/calendar/calendar';

export class DatePickerDialog {
  protected nextYear: number | null = null;
  protected lastYear: number | null = null;
  protected fullScreenOverlay: FullScreenOverlay | null = null;
  protected calendar: Calendar | null = null;

  private options: IOpenOptions | null = null;

  public static async open(options: IOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IOpenOptions): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't open without a fullScreenOverlay"
    );
    assertNotNullOrUndefined(
      this.calendar,
      'cannot handleLastYearClick without calendar'
    );

    this.options = options;
    this.calendar.getFlatpickrInstance().setDate(options.date);
    this.updateNextAndLastYear();

    this.fullScreenOverlay.open();
    this.calendar.getFlatpickrInstance().open();
  }

  protected handleOkClick(): void {
    this.acceptSelectedDateAndClose();
  }

  protected handleCancelClick(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't handleCancelClick without a fullScreenOverlay"
    );
    this.fullScreenOverlay.close();
  }

  protected handleLastYearClick(): void {
    assertNotNullOrUndefined(
      this.calendar,
      'cannot handleLastYearClick without calendar'
    );

    this.calendar.getFlatpickrInstance().changeMonth(-12);
    this.updateNextAndLastYear();
  }

  protected handleTodayClick(): void {
    assertNotNullOrUndefined(
      this.calendar,
      'cannot handleTodayClick without calendar'
    );

    this.calendar.getFlatpickrInstance().jumpToDate(new Date());
    this.updateNextAndLastYear();
  }

  protected handleNextYearClick(): void {
    assertNotNullOrUndefined(
      this.calendar,
      'cannot handleNextYearClick without calendar'
    );

    this.calendar.getFlatpickrInstance().changeMonth(12);
    this.updateNextAndLastYear();
  }

  protected handleCalendarDateChanged(): void {
    this.updateNextAndLastYear();
    this.acceptSelectedDateAndClose();
  }

  protected handleCalendarYearChanged(): void {
    this.updateNextAndLastYear();
  }

  private acceptSelectedDateAndClose(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't acceptDateAndClose without a fullScreenOverlay"
    );
    assertNotNullOrUndefined(
      this.calendar,
      "can't acceptDateAndClose without a calendar"
    );

    this.fullScreenOverlay.close();
    const date = this.calendar.getFlatpickrInstance().selectedDates[0];
    if (this.options?.onDateSelected && date) {
      this.options.onDateSelected(date);
    }
  }

  private updateNextAndLastYear(): void {
    this.lastYear = this.getLastYear();
    this.nextYear = this.getNextYear();
  }

  private getLastYear(): number {
    assertNotNullOrUndefined(
      this.calendar,
      'cannot getLastYear without calendar'
    );

    const currentYear = new Date(
      this.calendar.getFlatpickrInstance().currentYear,
      1,
      1
    );
    return DateUtils.getDateOneYearBefore(currentYear).getFullYear();
  }

  private getNextYear(): number {
    assertNotNullOrUndefined(
      this.calendar,
      'cannot getNextYear without calendar'
    );

    const currentYear = new Date(
      this.calendar.getFlatpickrInstance().currentYear,
      1,
      1
    );
    return DateUtils.getDateOneYearAfter(currentYear).getFullYear();
  }
}

interface IOpenOptions {
  date: Date;
  onDateSelected?: (date: Date) => void;
}
