import { bindable, inject } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

/**
 * @event edit-clicked
 * @event mark-clicked
 */
@inject(AppEntityManager)
export class ProcessTaskMeasurePointOverviewWidgetListItem {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint|null} */
  @bindable measurePoint = null;

  /** @type {HTMLElement|null} */
  _domElement = null;
  /** @type {HTMLElement|null} */
  _listElement = null;

  /** @type {boolean} */
  _panelOpen = false;

  /**
   * @param {AppEntityManager} entityManager
   */
  constructor(entityManager) {
    this._entityManager = entityManager;
  }

  highlight() {
    if (this._listElement) {
      EntityListItemHelper.highlightListItemElement(this._listElement);
    }
  }

  _handleEditButtonClick() {
    if (!this.measurePoint || !this._domElement) {
      return;
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-clicked',
      detail: null
    });
  }

  _handleMoreButtonClick() {
    this._panelOpen = !this._panelOpen;
  }

  _handleMarkButtonClick() {
    if (!this.measurePoint || !this._domElement) {
      return;
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'mark-clicked',
      detail: null
    });
  }

  _handleDeleteClick() {
    const measurePoint = this.measurePoint;

    if (measurePoint) {
      Dialogs.deleteEntityDialog(measurePoint).then(() => {
        this._entityManager.processTaskMeasurePointRepository.delete(
          measurePoint
        );
      });
    }
  }
}
