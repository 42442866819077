import { autoinject } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { AppEntityManager } from '../AppEntityManager';
import { Thing } from './types';
import { EditThingDialog } from '../../../../dialogs/edit-thing-dialog/edit-thing-dialog';
import { NavigationService } from '../../../../services/NavigationService';
import { CurrentUserService } from '../User/CurrentUserService';
import { ThingEntityDashboardInfo } from '../EntityDashboardInfo/types';

@autoinject()
export class ThingActionService {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  /**
   * Navigate to a thing and sets metadata for the homepage.
   */
  public navigateToThing(
    thing: Thing,
    additionalRouteParams?: OpenThingOptions | null,
    projectType?: ProjectType
  ): void {
    const metadata = this.getOrCreateMetadata(thing);
    metadata.lastOpenedAt = new Date().toISOString();
    this.entityManager.entityDashboardInfoRepository.update(metadata);

    this.navigationService.navigateToThing(
      thing.id,
      additionalRouteParams ?? null,
      projectType
    );
  }

  public editThing(thing: Thing, onDialogClosedAction?: () => void): void {
    void EditThingDialog.open({
      thing: thing,
      onDialogClosed: onDialogClosedAction
    });
  }

  public toggleArchivedStatusOfThing(thing: Thing): void {
    thing.archived = !thing.archived;
    this.entityManager.thingRepository.update(thing);
  }

  public toggleHideStatusOnHomepage(thing: Thing): void {
    const metadata = this.getOrCreateMetadata(thing);
    metadata.hidden = !metadata.hidden;
    this.entityManager.entityDashboardInfoRepository.update(metadata);
  }

  public togglestickyStatusOnHomepage(thing: Thing): void {
    const metadata = this.getOrCreateMetadata(thing);
    metadata.sticky = !metadata.sticky;
    this.entityManager.entityDashboardInfoRepository.update(metadata);
  }

  private getOrCreateMetadata(thing: Thing): ThingEntityDashboardInfo {
    const metadata =
      this.entityManager.entityDashboardInfoRepository.getByThingId(
        thing.id
      )[0];
    if (metadata) return metadata;

    const newData = this.entityManager.entityDashboardInfoRepository.create({
      thingId: thing.id,
      userId: this.currentUserService.getRequiredCurrentUser().id,
      lastOpenedAt: null
    }) as ThingEntityDashboardInfo;
    return newData;
  }
}

export type OpenThingOptions = {
  open_parameter_panel: boolean;
};
