import { autoinject } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  LastOpenedInfo,
  LastOpenedProcessTaskGroupsService
} from '../../services/LastOpenedProcessTaskGroupsService/LastOpenedProcessTaskGroupsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @replaceable item-template
 * available variables in item-template
 * | type | name | description |
 * |------|-----|-------------|
 * | LastOpenedInfo | item | the item to display |
 *
 * @replaceable no-items-template
 * there are no available variables in no-items-template
 *
 */
@autoinject()
export class LastOpenedProcessTaskGroupsList {
  private readonly subscriptionManager: SubscriptionManager;
  protected lastOpenedInfos: Array<LastOpenedInfo> = [];

  constructor(
    private readonly lastOpenedProcessTaskGroupsService: LastOpenedProcessTaskGroupsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.lastOpenedProcessTaskGroupsService.bindLastOpenedInfos(
        (lastOpenedInfos) => {
          this.lastOpenedInfos = lastOpenedInfos;
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}
