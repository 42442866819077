import { FileTypeUtils } from '../Utils/FileTypeUtils';

export class PictureHelper {
  public static readonly PICTURE_FILE_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/vnd.dxf'
  ];

  public static isPictureFileType(file: File): boolean {
    const fileType = FileTypeUtils.getFileType(file);
    return !!fileType && this.PICTURE_FILE_TYPES.indexOf(fileType) >= 0;
  }
}
