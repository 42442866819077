import { capSQLiteSet, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { StoreInfoUtils } from '../StoreInfoUtils';
import { UpdateProgress } from './SqliteMigrator';
import { SqliteUtils } from './SqliteUtils';

export class DoubleJsonMigration {
  private readonly store: SQLiteDBConnection;

  constructor(options: { store: SQLiteDBConnection }) {
    this.store = options.store;
  }

  public async migrate({
    updateProgress
  }: {
    updateProgress: UpdateProgress;
  }): Promise<void> {
    const tableNamesToMigrate =
      StoreInfoUtils.getApplicationTableNamesWithDefaultTable();

    for (const [index, tableNameToMigrate] of tableNamesToMigrate.entries()) {
      const migrationTableName = `${tableNameToMigrate}_doubleJson`;

      updateProgress({
        progress: index + 1,
        maxProgress: tableNamesToMigrate.length
      });

      await this.ensureMigrationTables({
        tableNameToMigrate,
        migrationTableName
      });

      await this.removeExistingData({ tableNameToMigrate });

      await this.migrateData({
        tableNameToMigrate,
        migrationTableName
      });

      await this.removeMigrationTable({ migrationTableName });
    }
  }

  private async ensureMigrationTables({
    tableNameToMigrate,
    migrationTableName
  }: {
    tableNameToMigrate: string;
    migrationTableName: string;
  }): Promise<void> {
    const migrationTableExists = await SqliteUtils.tableExists({
      store: this.store,
      tableName: migrationTableName
    });

    if (!migrationTableExists) {
      await SqliteUtils.createKeyValueTable({
        store: this.store,
        tableName: migrationTableName,
        ifNotExists: false,
        transaction: false
      });

      await this.store.run(
        `INSERT INTO ${migrationTableName} SELECT * FROM ${tableNameToMigrate}`,
        undefined,
        false
      );
    }
  }

  private async removeExistingData({
    tableNameToMigrate
  }: {
    tableNameToMigrate: string;
  }): Promise<void> {
    await this.store.run(`DELETE FROM ${tableNameToMigrate}`, undefined, false);
  }

  private async migrateData({
    tableNameToMigrate,
    migrationTableName
  }: {
    tableNameToMigrate: string;
    migrationTableName: string;
  }): Promise<void> {
    const limit = 5000;
    let startId = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { migratedCount, lastId } = await this.migrateDataChunk({
        tableNameToMigrate,
        migrationTableName,
        limit,
        startId
      });

      if (migratedCount < limit) {
        break;
      }

      startId = lastId + 1;
    }
  }

  private async migrateDataChunk({
    tableNameToMigrate,
    migrationTableName,
    limit,
    startId
  }: {
    tableNameToMigrate: string;
    migrationTableName: string;
    limit: number;
    startId: number;
  }): Promise<{ migratedCount: number; lastId: number }> {
    const results = await this.store.query(
      `SELECT * FROM ${migrationTableName} WHERE id >= ? ORDER BY id ASC LIMIT ${limit}`,
      [startId]
    );
    const rows = results.values ?? [];

    const instructions: Array<capSQLiteSet> = [];

    for (const row of rows) {
      instructions.push({
        statement: `INSERT INTO ${tableNameToMigrate} (key, value) VALUES (?, ?)`,
        values: [row.key, row.value ? JSON.parse(row.value) : row.value]
      });
    }

    if (instructions.length) {
      await this.store.executeSet(instructions, false);
    }

    return {
      migratedCount: rows.length,
      lastId: rows.at(-1)?.id ?? 0
    };
  }

  private async removeMigrationTable({
    migrationTableName
  }: {
    migrationTableName: string;
  }): Promise<void> {
    await this.store.run(`DROP TABLE ${migrationTableName}`, undefined, false);
  }
}
