import { computedFrom } from 'aurelia-framework';

import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { ProcessConfigurationAppointmentPropertiesConfiguration } from 'common/Types/ProcessConfigurationAppointmentPropertiesConfiguration';

import { ProcessTaskAppointmentProperty } from '../../../classes/EntityManager/entities/Property/types';
import {
  CreationConfig,
  PropertyAdapter,
  SubscribeOptions
} from './PropertyAdapter';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';

export class ProcessTaskAppointmentPropertyAdapter
  implements
    PropertyAdapter<ProcessTaskAppointmentProperty, TDefaultPropertyConfig>
{
  private readonly appointment: ProcessTaskAppointment;

  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly permissionsService: PermissionsService;
  private readonly entityManager: AppEntityManager;

  constructor({
    appointment,
    subscriptionManagerService,
    permissionsService,
    entityManager
  }: {
    appointment: ProcessTaskAppointment;
    subscriptionManagerService: SubscriptionManagerService;
    permissionsService: PermissionsService;
    entityManager: AppEntityManager;
  }) {
    this.appointment = appointment;

    this.subscriptionManagerService = subscriptionManagerService;
    this.permissionsService = permissionsService;
    this.entityManager = entityManager;
  }

  public subscribe(
    options: SubscribeOptions<
      ProcessTaskAppointmentProperty,
      TDefaultPropertyConfig
    >
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(
        this.entityManager.propertyRepository.getByProcessTaskAppointmentId(
          this.appointment.id
        )
      );
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );

    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateConfigs.bind(this)
    );

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateConfigs.bind(this)
    );

    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.ProcessTaskAppointment,
      entity: this.appointment,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public createPropertyFromConfig(
    config: CreationConfig<TDefaultPropertyConfig>
  ): ProcessTaskAppointmentProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      processTaskAppointmentId: this.appointment.id,
      ownerUserGroupId: this.appointment.ownerUserGroupId,
      ownerProcessTaskGroupId: this.appointment.ownerProcessTaskGroupId,
      ownerProcessTaskId: this.appointment.ownerProcessTaskId
    }) as ProcessTaskAppointmentProperty;
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }

  private getConfigs(): Array<TDefaultPropertyConfig> {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.appointment.ownerProcessTaskGroupId
      );

    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;

    const propertiesConfiguration: ProcessConfigurationAppointmentPropertiesConfiguration | null =
      processConfiguration?.appointmentPropertiesConfigurationJson
        ? JSON.parse(
            processConfiguration.appointmentPropertiesConfigurationJson
          )
        : null;

    return propertiesConfiguration?.properties ?? [];
  }
}
