import { assertNotNullOrUndefined } from 'common/Asserts';

export class LogDataConverterUtils {
  private constructor() {}

  /**
   * strips the Id suffix from the idPropertyPath (if there is any), and replaces it with a 'Name' suffix
   *
   * e.g. converts data.propertyId to data.propertName
   *
   */
  public static generateNamePropertyPath(idPropertyPath: string): string {
    const res = /^(?:(.*)(?=Id)|(.*))/.exec(idPropertyPath);
    assertNotNullOrUndefined(res, "regex couldn't find a match");
    return res[0] + 'Name';
  }
}
