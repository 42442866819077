import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskOfferEntityInfo } from 'common/Types/Entities/ProcessTaskOffer/createProcessTaskOfferEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskOffer } from './types';

export const processTaskOfferEntityInfo =
  createProcessTaskOfferEntityInfo<ProcessTaskOffer>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
