import { autoinject } from 'aurelia-framework';
import { ProcessConfigurationCustomPositionTypeConfiguration } from 'common/Types/ProcessConfigurationCustomPositionTypeConfiguration';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer extends ValueComputer<
  IComputeData,
  ProcessConfigurationCustomPositionTypeConfiguration | null
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(
    data: IComputeData
  ): ProcessConfigurationCustomPositionTypeConfiguration | null {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        data.processTaskGroupId
      );
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const typesConfigJson =
      processConfiguration?.customPositionTypeConfigurationJson;
    if (typesConfigJson) {
      return JSON.parse(typesConfigJson);
    }

    return null;
  }
}

export interface IComputeData {
  processTaskGroupId: string;
}
