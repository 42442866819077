import { XmlImporterUtils } from './XmlImporterUtils';
import { XmlSchemaParser } from './XmlSchemaParser';
import { DateUtils } from '../../../../common/src/DateUtils';

/**
 * parses an xmlString into a schema
 *
 * Example of a parse result with an array
 *
 *  {
 *    'children': [
 *      { //a generic node
 *        'label': 'Datum',
 *        'value': '2018-12-19T15:43:19.000Z',
 *        'dataType': 'date'
 *      },
 *      { //an array node with a customId (optional, but handy to find the desired array nodes again)
 *        'customId': 'gauges',
 *        'children': [
 *          { //first child container of the Array
 *            'children': [ //actual values of the child
 *              { 'label': 'Messgerät ID', 'value': 'DG700-62883', 'dataType': 'string' },
 *              { 'label': 'Seriennummer', 'value': '62883', 'dataType': 'string' }
 *            ]
 *          },
 *          { //second child container of the Array
 *            'children': [ //actual values of the child
 *              { 'label': 'Messgerät ID', 'value': 'DG700-62883', 'dataType': 'string' },
 *              { 'label': 'Seriennummer', 'value': '62883', 'dataType': 'string' }
 *            ]
 *          }
 *        ]
 *      }
 *    ]
 *  }
 */
export class XmlImportParser {
  /** @type {TXmlSchemaParserParentNode} */
  _schemaRootNode;

  /**
   *
   * @param {TXmlSchemaParserParentNode} schemaRootNode
   */
  constructor(schemaRootNode) {
    this._schemaRootNode = schemaRootNode;
  }

  /**
   *
   * @param {string} xmlString
   * @returns {TXmlImportParserParentNode}
   */
  parse(xmlString) {
    const document = XmlImporterUtils.parseXmlString(xmlString);
    /** @type {TXmlImportParserParentNode} */
    const rootNode = { children: [] };

    this._parseSchemaNodes(
      this._schemaRootNode.children,
      rootNode,
      document.getRootNode()
    );

    return rootNode;
  }

  /**
   *
   * @param {Array<TXmlSchemaParserNode|TXmlSchemaParserParentNode>} schemaNodes
   * @param {TXmlImportParserParentNode} parentNode
   * @param {Element} parentElement
   * @private
   */
  _parseSchemaNodes(schemaNodes, parentNode, parentElement) {
    schemaNodes.forEach((schemaNode) => {
      this._parseSchemaNode(schemaNode, parentNode, parentElement);
    });
  }

  /**
   *
   * @param {(TXmlSchemaParserParentNode|TXmlSchemaParserNode)} schemaNode
   * @param {TXmlImportParserParentNode} parentNode
   * @param {Element} parentElement
   * @private
   */
  _parseSchemaNode(schemaNode, parentNode, parentElement) {
    if (XmlSchemaParser.isSchemaParserParentNode(schemaNode)) {
      this._parseParentSchemaNode(schemaNode, parentNode, parentElement);
    } else {
      this._parseSimpleSchemaNode(schemaNode, parentNode, parentElement);
    }
  }

  /**
   *
   * @param {TXmlSchemaParserParentNode} schemaNode
   * @param {TXmlImportParserParentNode} parentNode
   * @param {Element} parentElement
   * @private
   */
  _parseParentSchemaNode(schemaNode, parentNode, parentElement) {
    const element = this._findElement(parentElement, schemaNode.path);
    if (element) {
      const node = this._createImportParserNodeFromSchemaNode(schemaNode);
      parentNode.children.push(node);

      if (schemaNode.isArray) {
        this._parseArrayNode(schemaNode, node, element);
      } else {
        this._parseSchemaNodes(schemaNode.children, node, element);
      }
    } else {
      console.warn(
        `[XmlImportParser] element for schemaNode not found`,
        schemaNode
      );
    }
  }

  /**
   *
   * @param {TXmlSchemaParserNode} schemaNode
   * @param {TXmlImportParserParentNode} parentNode
   * @param {Element} parentElement
   * @private
   */
  _parseSimpleSchemaNode(schemaNode, parentNode, parentElement) {
    const element = this._findElement(parentElement, schemaNode.path);
    if (element) {
      const node = this._createImportParserNodeFromSchemaNode(schemaNode);
      this._parseValueOfElement(element, node);
      parentNode.children.push(node);
    } else {
      console.warn(
        `[XmlImportParser] element for schemaNode not found`,
        schemaNode
      );
    }
  }

  /**
   *
   * @param {Element} element
   * @param {TXmlImportParserNode} parserNode
   * @private
   */
  _parseValueOfElement(element, parserNode) {
    const stringValue = element.innerHTML;
    switch (parserNode.dataType) {
      case 'string':
        parserNode.value = stringValue;
        break;

      case 'number':
        const numberValue = parseFloat(stringValue);
        if (!isNaN(numberValue)) {
          parserNode.value = numberValue;
        } else {
          console.warn(
            `[XmlImportParser] couldn't parse number from string "${stringValue}"`
          );
          parserNode.value = null;
        }
        break;

      case 'date':
        parserNode.value = DateUtils.parseDateFromIsoString(stringValue);
        break;

      case 'choice':
        this._parseChoice(stringValue, parserNode);
        break;

      default:
        parserNode.value = null;
        break;
    }
  }

  /**
   *
   * @param {string} xmlValue
   * @param {TXmlImportParserNode} parserNode
   * @private
   */
  _parseChoice(xmlValue, parserNode) {
    let choice;
    if (parserNode.choices) {
      choice = parserNode.choices.find((c) => c.xmlValue === xmlValue);
    }

    if (choice) {
      parserNode.value = choice.userValue;
    } else {
      parserNode.value = xmlValue;
      parserNode.isCustomChoice = true;
    }
  }

  /**
   *
   * @param {TXmlSchemaParserParentNode} schemaNode - array schema
   * @param {TXmlImportParserParentNode} importParserNode - node of the array itself
   * @param {Element} element - element of the schema node
   * @private
   */
  _parseArrayNode(schemaNode, importParserNode, element) {
    for (let key = 0; key < element.childNodes.length; key++) {
      const childNode = element.childNodes[key];
      if (childNode instanceof Element) {
        const parentNode = { children: [] };
        this._parseSchemaNodes(schemaNode.children, parentNode, childNode);

        if (parentNode.children.length) {
          importParserNode.children.push(parentNode);
        }
      }
    }
  }

  /**
   *
   * @param {Element} parentElement
   * @param {Array<string>} elementPath
   * @returns {(Element|null)}
   * @private
   */
  _findElement(parentElement, elementPath) {
    const relativePath = XmlImporterUtils.getRelativeElementPath(
      parentElement,
      elementPath
    );
    const selector = relativePath.join(' > ');

    return parentElement.querySelector(selector);
  }

  /**
   *
   * @param {(TXmlSchemaParserNode|TXmlSchemaParserParentNode)} schemaNode
   * @returns {(TXmlImportParserParentNode|TXmlImportParserNode)}
   * @private
   */
  _createImportParserNodeFromSchemaNode(schemaNode) {
    const node = {
      label: schemaNode.label,
      value: null,
      dataType: schemaNode.dataType,
      customId: schemaNode.customId,
      choices: schemaNode.choices,
      isCustomChoice: false
    };

    if (XmlSchemaParser.isSchemaParserParentNode(schemaNode)) {
      node.children = [];
    }

    return node;
  }
}

/**
 * in case of a choice, the value represented is the userValue instead of the xmlValue, if no fitting choice is found, then the value just contains the xmlValue
 *
 * @typedef {Object} TXmlImportParserNode
 * @property {(string|null)} [label]
 * @property {(string|null)} [dataType] - 'number', 'string', 'date', 'choice'
 * @property {(string|null)} [customId]
 * @property {*} value - correctly parsed into the dataType given in dataType, look at documentation on the typedef for more info
 * @property {(Array<TXmlSchemaParserNodeChoice>|null)} choices - only available if the dataType === 'choice'
 * @property {boolean} isCustomChoice - if the value is not found in choices, then this flag will be set
 */

/**
 * @typedef {TXmlImportParserNode} TXmlImportParserParentNode
 * @property {Array<TXmlImportParserParentNode|TXmlImportParserNode>} children
 */
