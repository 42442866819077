import { Router } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { PersonHelper as CommonPersonHelper } from 'common/EntityHelper/PersonHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PersonCategories } from 'common/Types/PersonCategories';

import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { RelationInfo } from '../../../../inputComponents/entity-nfc-tag-widget/entity-nfc-tag-widget';
import { NfcTokenToPerson } from '../NfcTokenToPerson/types';
import { EntityName } from '../types';
import { Person } from './types';

export class PersonUtils {
  private constructor() {}

  public static getSortOptions(
    getPersonCategories: () => PersonCategories
  ): PersonSortOptions {
    return {
      name: {
        name: 'Name',
        sortFunction: (a, b) => {
          return PersonUtils.getPersonDisplayNameForPerson(a).localeCompare(
            PersonUtils.getPersonDisplayNameForPerson(b)
          );
        }
      },
      category: {
        name: 'Category',
        sortFunction: (a, b) => {
          const personCategories = getPersonCategories();
          return PersonUtils.getPersonDisplayCategoryForPerson(
            a,
            personCategories
          ).localeCompare(
            PersonUtils.getPersonDisplayCategoryForPerson(b, personCategories)
          );
        }
      }
    };
  }

  public static getPersonDisplayNameForPerson(person: Person): string {
    return this.getPersonDisplayName(
      person.company,
      person.companyName,
      person.title,
      person.firstName,
      person.lastName
    );
  }

  public static getPersonDisplayCategoryForPerson(
    person: Person,
    personCategories: PersonCategories
  ): string {
    return (
      personCategories.find((x) => x.value === person.categoryName)?.label ?? ''
    );
  }

  /**
   * split up version to better understand the dependency and to directly use in the view (with just passing in the person the view wouldn't get updated)
   */
  public static getPersonDisplayName(
    company: boolean,
    companyName: string | null,
    title: string | null,
    firstName: string | null,
    lastName: string | null
  ): string {
    return CommonPersonHelper.getPersonDisplayName(
      company,
      companyName,
      title,
      firstName,
      lastName
    );
  }

  public static getPersonAddressLine1(person: Person, t: I18N): string {
    return CommonPersonHelper.getAddressLine1(
      person.streetName,
      person.floor,
      person.staircase,
      person.doorNumber,
      person.addressAppendix,
      t.tr('modelsDetail.PersonModel.prefixes.floor'),
      t.tr('modelsDetail.PersonModel.prefixes.staircase')
    );
  }

  public static getPersonAddressLine2(person: Person): string {
    return CommonPersonHelper.getAddressLine2(person.zip, person.municipality);
  }

  public static navigateToEditPersonPage(
    router: Router,
    personId: string
  ): void {
    router.navigate(this.getEditPersonPageUrl(router, personId));
  }

  public static getEditPersonPageUrl(router: Router, personId: string): string {
    return router.generate('edit_person', { person_id: personId });
  }

  public static getPersonIconClassName(company: boolean): string {
    return company ? 'fa-industry' : 'fa-person-simple';
  }

  public static nfcTagRelationInfo: RelationInfo<NfcTokenToPerson, Person> = {
    entityName: EntityName.Person,
    relationEntityName: EntityName.NfcTokenToPerson,
    createRelation: ({ tokenId, entityManager, entity }) => {
      assertNotNullOrUndefined(
        entity,
        'cannot create relation without a person'
      );
      entityManager.nfcTokenToPersonRepository.create({
        ownerUserGroupId: entity.ownerUserGroupId,
        nfcTokenId: tokenId,
        personId: entity.id
      });
    },
    deleteRelation: ({ entityManager, entity }) => {
      assertNotNullOrUndefined(
        entity,
        'cannot delete relation without a person'
      );
      entityManager.nfcTokenToPersonRepository.deleteByPersonId(entity.id);
    },
    getRelationForTokenId: ({ tokenId, entityManager, entity }) => {
      assertNotNullOrUndefined(entity, 'cannot get relation without a person');
      return (
        entityManager.nfcTokenToPersonRepository
          .getByPersonId(entity.id)
          .find((r) => r.nfcTokenId === tokenId) ?? null
      );
    },
    getRelationForEntity: ({ entityManager, entity }) => {
      assertNotNullOrUndefined(entity, 'cannot get relation without a person');
      return (
        entityManager.nfcTokenToPersonRepository.getByPersonId(entity.id)[0] ??
        null
      );
    },
    getTokenIdFromRelation: (relation) => {
      return relation.nfcTokenId;
    }
  };
}

export type PersonSortOptions = {
  name: PersonSortOption;
  category: PersonSortOption;
};

export type PersonSortOption = SorterSortOption<Person>;
