import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationStepAutoAppointment } from './types';

export class ProcessConfigurationStepAutoAppointmentRepository extends AppEntityRepository<EntityName.ProcessConfigurationStepAutoAppointment> {
  public getByProcessConfigurationStepId(
    processConfigurationStepId: string
  ): Array<ProcessConfigurationStepAutoAppointment> {
    return this.getAll().filter(
      (e) => e.processConfigurationStepId === processConfigurationStepId
    );
  }
}
