import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { createProcessTaskGroupAuthorizationEntityInfo } from 'common/Types/Entities/ProcessTaskGroupAuthorization/createProcessTaskGroupAuthorizationEntityInfo';
import { ProcessTaskGroupAuthorization } from './types';

export const processTaskGroupAuthorizationEntityInfo =
  createProcessTaskGroupAuthorizationEntityInfo<ProcessTaskGroupAuthorization>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
