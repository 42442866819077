import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';

import {
  ExportEntitiesAsCsvFileRequest,
  ExportEntitiesAsCsvFileResponse
} from 'common/EndpointTypes/ExportCsvEndpointsHandler';

import {
  SocketService,
  TSocketServiceCallbackFunction
} from '../../../../services/SocketService';
import { Dialogs } from '../../../Dialogs';
import { EventAggregatorPromiseHelper } from '../../../Promise/EventAggregatorPromiseHelper';
import { FileDownloadService } from '../../../../services/FileDownloadService';

@autoinject()
export class ThingExportService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService,
    private readonly fileDownloadService: FileDownloadService
  ) {}

  public exportThingsAsCsvFile(
    thingIds: Array<string>,
    callback: TSocketServiceCallbackFunction<
      ExportEntitiesAsCsvFileResponse,
      ExportEntitiesAsCsvFileRequest
    >
  ): void {
    this.socketService.exportThingsAsCsvFile(thingIds, callback);
  }

  public exportPicturesOfThingIdAsZipFile(thingId: string): void {
    Dialogs.waitDialog();
    this.socketService.exportPicturesOfThingIdAsZipFile(thingId, (response) => {
      if (response.success) {
        Dialogs.closeAllDialogs();
        void this.fileDownloadService.downloadFile(response.filePath);
      } else {
        void Dialogs.errorDialog(
          'Fehler beim Bilderexport!',
          response.message ? response.message : ''
        );
      }
    });
  }

  public async exportLatestReportsOfThingIdAsZipFile(
    thingId: string
  ): Promise<void> {
    try {
      Dialogs.waitDialog();
      await EventAggregatorPromiseHelper.createConnectedPromise<void>(
        this.eventAggregator,
        new Promise((res) => {
          this.socketService.exportLatestReportsOfThingAsZipFile(
            { thingId, reportFileType: 'docx' },
            (response) => {
              Dialogs.closeAllDialogs();
              if (response.success) {
                void this.fileDownloadService.downloadFile(response.filePath);
              } else {
                void Dialogs.errorDialogTk(
                  'general.error',
                  response.status ? 'serverResponses.' + response.status : ''
                );
              }
              res();
            }
          );
        })
      );
    } catch (e) {
      Dialogs.closeAllDialogs();
    }
  }
}
