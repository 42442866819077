import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ReportTypeDto } from './ReportTypeDto';

export function createReportTypeEntityInfo<
  TEntity extends ReportTypeDto<string, string>
>(
  options: CreateEntityInfoOptions<ReportTypeDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ReportType,
  TEntity
> {
  return {
    entityName: EntityName.ReportType,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ReportTypeDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        file: {
          name: 'file',
          defaultValue: null
        },
        options: {
          name: 'options',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              value: {
                name: 'value'
              }
            }
          ],
          defaultValue: () => []
        },
        upload_date: {
          name: 'upload_date',
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: null
        },
        file_name: {
          name: 'file_name',
          defaultValue: null
        },
        usergroup: {
          name: 'usergroup',
          ref: EntityName.UserGroup,
          cascadeDeleteLocally: true
        },
        custom_tags_in_entries: {
          name: 'custom_tags_in_entries',
          defaultValue: () => []
        },
        tags: {
          name: 'tags',
          defaultValue: () => []
        },
        notes: {
          name: 'notes',
          defaultValue: () => []
        },
        group_by_structures: {
          name: 'group_by_structures',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              options: {
                name: 'options',
                innerInfo: [
                  {
                    name: {
                      name: 'name'
                    },
                    value: {
                      name: 'value'
                    }
                  }
                ]
              }
            }
          ],
          defaultValue: () => []
        },

        /** @deprecated - Remove? */
        specialStructures: {
          name: 'specialStructures',
          innerInfo: [
            {
              name: {
                name: 'name'
              },
              options: {
                name: 'options',
                innerInfo: [
                  {
                    name: {
                      name: 'name'
                    },
                    value: {
                      name: 'value'
                    }
                  }
                ]
              },
              type: {
                name: 'type'
              }
            }
          ],
          defaultValue: () => []
        },

        features: {
          name: 'features',
          innerInfo: {
            hasGlobalProjectPictures: {
              name: 'hasGlobalProjectPictures',
              defaultValue: true
            },
            hasStructure: {
              name: 'hasStructure',
              defaultValue: true
            },
            hasStructureReason: {
              name: 'hasStructureReason',
              defaultValue: 'default'
            },
            hasTitlePictures: {
              name: 'hasTitlePictures',
              defaultValue: true
            },
            userDefinedEntityWidgetConfig: {
              name: 'userDefinedEntityWidgetConfig',
              innerInfo: [
                {
                  allowCopyingOfLevel: {
                    name: 'allowCopyingOfLevel',
                    innerInfo: {
                      global: {
                        name: 'global',
                        defaultValue: null
                      },
                      thing: {
                        name: 'thing',
                        defaultValue: null
                      }
                    },
                    defaultValue: null
                  },
                  filteredEntityConfig: {
                    name: 'filteredEntityConfig',
                    defaultValue: null
                  },
                  label: {
                    name: 'label',
                    defaultValue: null
                  }
                }
              ],
              defaultValue: () => []
            }
          },
          defaultValue: () => ({
            hasStructure: true,
            hasStructureReason: 'default',
            hasGlobalProjectPictures: true,
            hasTitlePictures: true
          })
        }
      },
      options.additionalFieldInfos
    )
  };
}
