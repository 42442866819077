import { autoinject } from 'aurelia-framework';
import { MomentInput } from 'moment';

import { configureHooks } from '../../hooks/configureHooks';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { PictureRevisionComparisonFullScreenDialog } from '../picture-revision-comparison-full-screen-dialog/picture-revision-comparison-full-screen-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { DateUtils } from 'common/DateUtils';
import { DataUrlReader } from '../../classes/Reader/DataUrlReader/DataUrlReader';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SelectableItemList } from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { PictureRevisionListEntry } from '../picture-revision-list-entry/picture-revision-list-entry';

@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
@autoinject()
export class PictureRevisionDialog {
  protected options: DialogOptions | null = null;
  protected dialog: RecordItDialog | null = null;

  protected pictureInfos: Array<PictureInfo> = [];
  protected selectedPictureInfosForComparison: Array<PictureInfo> = [];

  protected selectableItemListViewModel: SelectableItemList<
    PictureInfo,
    PictureRevisionListEntry
  > | null = null;

  private fileInputElement: HTMLInputElement | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: DialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open PictureRevisionDialog without RecordItDialog'
    );

    this.options = options;
    this.setupRevisionsListForPicture();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureRevision,
      () => {
        this.setupRevisionsListForPicture();
      }
    );

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'selectedPictureInfosForComparison',
      this.selectedPictureInfosForComparisonChanged.bind(this)
    );

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.options = null;
    this.pictureInfos = [];

    this.selectableItemListViewModel?.resetSelectionEnabled();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected async handleFileInputChanged(): Promise<void> {
    assertNotNullOrUndefined(
      this.options?.picture,
      'cannot add revision without Picture'
    );

    assertNotNullOrUndefined(
      this.pictureInfos[0],
      'cannot add revision without existing revision data'
    );

    if (!this.pictureInfos[0].pictureRevision) {
      this.createPictureRevisionForOriginalPictureFile(
        this.options.picture,
        this.pictureInfos[0]
      );
    }

    await this.readNewInputFileAndCreateRevision(this.options.picture);
  }

  private createPictureRevisionForOriginalPictureFile(
    picture: Picture,
    pictureInfo: PictureInfo
  ): void {
    const revision =
      this.entityManager.pictureRevisionRepository.createPictureRevisionForPicture(
        picture
      );

    pictureInfo.pictureFile.pictureRevisionId = revision.id;
    this.entityManager.pictureFileRepository.update(pictureInfo.pictureFile);
  }

  private async readNewInputFileAndCreateRevision(
    picture: Picture
  ): Promise<void> {
    const file = this.fileInputElement?.files
      ? this.fileInputElement.files[0]
      : null;
    if (!file) return;

    const reader = new DataUrlReader();

    const result = await reader.readFile(file);
    this.savePictureFileDataUrlService.saveOriginalDataUrlInNewRevision(
      picture,
      result,
      false
    );
  }

  private setupRevisionsListForPicture(): void {
    assertNotNullOrUndefined(
      this.options?.picture,
      'cannot setup revisions list without Picture'
    );

    const revisions =
      this.entityManager.pictureRevisionRepository.getByPictureId(
        this.options.picture.id
      );

    this.pictureInfos = [];
    if (revisions.length === 0) {
      this.setupRevisionListWithoutRevisions(this.options.picture);
    } else {
      this.setupRevisionListFromExistingRevisions(revisions);
    }
  }

  private setupRevisionListWithoutRevisions(picture: Picture): void {
    assertNotNullOrUndefined(
      this.options?.picture,
      'cannot setup revisions list without Picture'
    );

    const pictureFile =
      this.entityManager.pictureFileRepository.getOriginalPictureFileByRevisionId(
        { pictureId: picture.id }
      );

    assertNotNullOrUndefined(
      pictureFile,
      'cannot setup revisions list without PictureFile'
    );

    this.pictureInfos.push({ pictureFile });
  }

  private setupRevisionListFromExistingRevisions(
    revisions: Array<PictureRevision>
  ): void {
    assertNotNullOrUndefined(
      this.options?.picture,
      'cannot setup revisions list without Picture'
    );

    for (const revision of revisions) {
      const pictureFile =
        this.entityManager.pictureFileRepository.getPictureFileToDisplayByRevisionId(
          { pictureId: this.options.picture.id, revisionId: revision.id }
        );

      assertNotNullOrUndefined(
        pictureFile,
        'cannot setup revisions list without PictureFile'
      );

      this.pictureInfos.push({
        pictureFile: pictureFile,
        pictureRevision: revision
      });
    }
  }

  protected handleDeleteRevisionClick(pictureInfo: PictureInfo): void {
    assertNotNullOrUndefined(
      pictureInfo.pictureRevision,
      'cannot show picture previev without PictureFile'
    );

    const deletedRevisionWasActiveRevision =
      pictureInfo.pictureRevision.selected;

    this.entityManager.pictureRevisionRepository.delete(
      pictureInfo.pictureRevision
    );
    ArrayUtils.remove(this.pictureInfos, pictureInfo);

    if (deletedRevisionWasActiveRevision) {
      this.entityManager.pictureRevisionRepository.setFallbackRevisionForPicture(
        this.options?.picture.id ?? ''
      );
    }
  }

  protected handleSelectedRevisionChanged(pictureInfo: PictureInfo): void {
    if (!pictureInfo.pictureRevision) return;

    for (const revision of this.pictureInfos.map((rd) => rd.pictureRevision)) {
      if (!revision || revision.selected === false) continue;

      revision.selected = false;
      this.entityManager.pictureRevisionRepository.update(revision);
    }

    pictureInfo.pictureRevision.selected = true;
    this.entityManager.pictureRevisionRepository.update(
      pictureInfo.pictureRevision
    );
  }

  private selectedPictureInfosForComparisonChanged(): void {
    if (
      !this.selectedPictureInfosForComparison ||
      this.selectedPictureInfosForComparison.length !== 2
    )
      return;

    assertNotNullOrUndefined(
      this.options?.picture,
      'cannot show picture comparison without Picture'
    );
    assertNotNullOrUndefined(
      this.selectedPictureInfosForComparison[0]?.pictureRevision?.createdAt,
      'cannot show picture previev without first PictureRevision with createdAt date'
    );
    assertNotNullOrUndefined(
      this.selectedPictureInfosForComparison[1]?.pictureRevision?.createdAt,
      'cannot show picture previev without second PictureRevision with createdAt date'
    );

    const date1 = DateUtils.parseDateFromIsoString(
      this.selectedPictureInfosForComparison[0].pictureRevision.createdAt
    );
    const date2 = DateUtils.parseDateFromIsoString(
      this.selectedPictureInfosForComparison[1].pictureRevision.createdAt
    );

    const { olderRevision, newerRevision } = DateUtils.isBefore(date1, date2)
      ? {
          olderRevision:
            this.selectedPictureInfosForComparison[0].pictureRevision,
          newerRevision:
            this.selectedPictureInfosForComparison[1].pictureRevision
        }
      : {
          olderRevision:
            this.selectedPictureInfosForComparison[1].pictureRevision,
          newerRevision:
            this.selectedPictureInfosForComparison[0].pictureRevision
        };

    void PictureRevisionComparisonFullScreenDialog.open({
      picture: this.options.picture,
      revisionLeft: olderRevision,
      revisionRight: newerRevision
    });

    this.selectableItemListViewModel?.resetSelectionEnabled();
  }
}

type DialogOptions = {
  picture: Picture;
};

export type PictureInfo = {
  pictureFile: PictureFile;
  pictureRevision?: PictureRevision;
};
