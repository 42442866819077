import { autoinject, bindable, Container } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ContextualBasePropertyWidget } from '../contextual-base-property-widget/contextual-base-property-widget';
import { ContextualPropertyWidgetConfiguration } from './config/PropertyWidgetConfiguration/ContextualPropertyWidgetConfiguration';
import { PropertyWidgetConfiguration } from './config/PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import { PropertyWidgetStyle } from './config/PropertyWidgetStyle/PropertyWidgetStyle';

/**
 * A widget which renders a specific widget for the configured context/configuration.
 * It acts as a single entry point for contexts where the PropertyType is only known at runtime
 */
@autoinject()
export class BasePropertyWidget<TContext> {
  @bindable()
  public context: TContext | null = null;

  @bindable()
  public configuration: PropertyWidgetConfiguration<any, TContext> | null =
    null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  @bindable()
  public fillHeight: boolean = false;

  protected contextualConfiguration: ContextualPropertyWidgetConfiguration<any> | null =
    null;
  protected contextualWidget: ContextualBasePropertyWidget | null = null;
  private isAttached: boolean = false;

  constructor(private readonly container: Container) {}

  public focus(): void {
    assertNotNullOrUndefined(
      this.contextualWidget,
      "can't BasePropertyWidget.focus without contextualWidget"
    );
    this.contextualWidget.focus();
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateContextualConfiguration();
  }

  protected detached(): void {
    this.isAttached = false;

    this.resetContextualConfiguration();
  }

  protected contextChanged(): void {
    if (this.isAttached) {
      this.updateContextualConfiguration();
    }
  }

  protected configurationChanged(): void {
    if (this.isAttached) {
      this.updateContextualConfiguration();
    }
  }

  private updateContextualConfiguration(): void {
    this.resetContextualConfiguration();

    if (this.context && this.configuration) {
      this.contextualConfiguration = {
        binding: this.configuration.bindingConfiguration.createBinding({
          container: this.container,
          context: this.context
        })
      };
    }
  }

  private resetContextualConfiguration(): void {
    this.contextualConfiguration?.binding.dispose();
    this.contextualConfiguration = null;
  }
}
