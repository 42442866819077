import { autoinject, computedFrom } from 'aurelia-framework';

import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { UnsynchedItemsUploader } from '../../classes/Rescue/UnsynchedItemsUploader';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FileUploadService } from '../../services/FileUploadService';
import { LockName, LogoutLockService } from '../../services/LogoutLockService';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

@autoinject()
export class LogoutLockDialog {
  protected dialog: RecordItDialog | null = null;

  private options: LogoutLockDialogOptions | null = null;

  private lockStatus: Map<LockName, number> = new Map();

  protected lockStatusInfos: Array<LockStatusInfo> = [];

  protected isOnline = false;

  protected loading = false;

  private subscriptionManager: SubscriptionManager;

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private logoutLockService: LogoutLockService,
    private socketService: SocketService,
    private entityManager: AppEntityManager,
    private currentUserService: CurrentUserService,
    private fileUploadService: FileUploadService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public static async open(options: LogoutLockDialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: LogoutLockDialogOptions): void {
    this.options = options;

    this.logoutLockService.subscribe(this, this.updateLockStatus.bind(this));
    this.updateLockStatus(this.logoutLockService.getLockStatus());

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.dialog?.open();
  }

  private close(): void {
    this.dialog?.close();
    this.logoutLockService.unsubscribeByContext(this);
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateLockStatus(lockStatus: Map<LockName, number>): void {
    this.lockStatus = lockStatus;
    this.updateLockStatusInfos();

    if (!this.logoutLockService.isAnyLockActive()) {
      this.lockReleased();
    }
  }

  private lockReleased(): void {
    this.options?.onLockReleasedCallback();
    this.close();
  }

  protected handleDialogClosed(): void {
    this.options?.onDialogClosedCallback();
    this.close();
  }

  private updateLockStatusInfos(): void {
    const lockStatusInfos: Array<LockStatusInfo> = [];
    this.lockStatus.forEach((count, lockName) => {
      lockStatusInfos.push({
        lockNameTk: lockName,
        count: count
      });
    });
    this.lockStatusInfos = lockStatusInfos;
  }

  @computedFrom('lockStatus')
  protected get showOverrideInformation(): boolean {
    const hasFailedItem =
      this.lockStatus.has(LockName.FAILED_SYNCHRONIZATION) ||
      this.lockStatus.has(LockName.FILE_UPLOAD_ITEMS_NOT_READY);
    const hasNoPendingItem =
      !this.lockStatus.has(LockName.SYNCHRONIZATION) &&
      !this.lockStatus.has(LockName.FILE_UPLOAD);
    return hasFailedItem && hasNoPendingItem;
  }

  protected async handleLogoutLockOverrideButtonClick(): Promise<void> {
    this.loading = true;

    const unsynchedItemsUploader = new UnsynchedItemsUploader(
      this.entityManager,
      this.currentUserService,
      this.socketService,
      this.fileUploadService
    );

    await unsynchedItemsUploader.uploadUnsynchedItems();
    await unsynchedItemsUploader.uploadFileUploadItems();

    this.loading = false;

    this.logoutLockService.clearLocks();
  }
}

type LogoutLockDialogOptions = {
  onLockReleasedCallback: () => void;
  onDialogClosedCallback: () => void;
};

type LockStatusInfo = {
  lockNameTk: LockName;
  count: number;
};
