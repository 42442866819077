import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import {
  AbstractCameraStreamStrategy,
  FocusSetHintResult
} from './AbstractCameraStreamStrategy';
import { MediaDevicesCameraStreamStrategy } from './MediaDevicesCameraStreamStrategy';
import { NativeCameraStreamStrategy } from './NativeCameraStreamStrategy';
import { FullScreenContent } from '../../aureliaComponents/full-screen-content/full-screen-content';

import './CameraStreamService.css';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

export class CameraStreamService {
  private strategy: AbstractCameraStreamStrategy;

  private streamIsStarted = false;
  private streamInfos: Array<StreamContextInfo> = [];

  private boundResizeHandler = this.resizeHandler.bind(this);

  constructor() {
    if (DeviceInfoHelper.isApp()) {
      this.strategy = new NativeCameraStreamStrategy();
    } else {
      this.strategy = new MediaDevicesCameraStreamStrategy();
    }
  }

  public async startStream(
    context: any,
    onFocusSetCallback: (result: FocusSetHintResult) => void
  ): Promise<void> {
    await this.addContext(context, onFocusSetCallback);
  }

  public async stopStream(context: any): Promise<void> {
    await this.removeContext(context);
  }

  public async switchStream(): Promise<void> {
    await this.strategy.switchStream();
  }

  public async takePicture(): Promise<string> {
    return this.strategy.takePicture();
  }

  public async setFocusPoint(x: number, y: number): Promise<void> {
    await this.strategy.setFocusPoint(x, y);
  }

  public async getAvailableFlashModes(): Promise<
    Array<CameraPreviewFlashMode>
  > {
    return await this.strategy.getSupportedFlashModes();
  }

  public async getFlashMode(): Promise<CameraPreviewFlashMode | null> {
    return await this.strategy.getFlashMode();
  }

  public async setFlashMode(flashMode: CameraPreviewFlashMode): Promise<void> {
    await this.strategy.setFlashMode(flashMode);
  }

  private onFocusSetCallback(result: FocusSetHintResult): void {
    if (!this.streamInfos.length) return;

    const currentContext = this.streamInfos[this.streamInfos.length - 1];
    currentContext?.onFocusSetCallback(result);
  }

  private async addContext(
    context: any,
    onFocusSetCallback: (result: FocusSetHintResult) => void
  ): Promise<void> {
    this.addHtmlModifications();

    const visibilityCutoffNode = this.addCutoffElement();

    this.streamInfos.push({
      context,
      cutoffElement: visibilityCutoffNode,
      onFocusSetCallback
    });

    if (!this.streamIsStarted) {
      await this.strategy.startStream(this.onFocusSetCallback.bind(this));

      window.addEventListener('resize', this.boundResizeHandler);
    }

    this.streamIsStarted = true;
  }

  private async removeContext(context: any): Promise<void> {
    const ctxt = this.streamInfos.find(
      (streamContexInfo) => streamContexInfo.context === context
    );
    assertNotNullOrUndefined(ctxt, 'stream context not found');

    ctxt.cutoffElement.remove();

    ArrayUtils.remove(this.streamInfos, ctxt);

    if (!this.streamInfos.length) {
      window.removeEventListener('resize', this.boundResizeHandler);

      await this.strategy.stopStream();
      this.streamIsStarted = false;

      this.removeHtmlModifications();
    }
  }

  private addHtmlModifications(): void {
    const htmlNode = document.documentElement;
    htmlNode.classList.add('camera-preview-active');
  }

  private removeHtmlModifications(): void {
    const htmlNode = document.documentElement;
    htmlNode.classList.remove('camera-preview-active');
  }

  private addCutoffElement(): HTMLElement {
    const mainAppNode = FullScreenContent.getFullScreenContentContainer();
    const visibilityCutoffNode = document.createElement('visibility-cutoff');
    mainAppNode.append(visibilityCutoffNode);
    return visibilityCutoffNode;
  }

  private resizeHandler(): void {
    void this.strategy.updateSize();
  }
}

type StreamContextInfo = {
  context: any;
  cutoffElement: HTMLElement;
  onFocusSetCallback: (result: FocusSetHintResult) => void;
};
