import { ProcessTaskLogEntryAction } from 'common/processTaskLogEntryActions';
import { ProcessTaskLogEntry } from '../../../classes/EntityManager/entities/ProcessTaskLogEntry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import {
  AbstractLogDataConverter,
  ConvertOptions
} from './AbstractLogDataConverter';

export class MostRecentSubEntityDisplayNameAtLogTime extends AbstractLogDataConverter {
  public convert({
    logData,
    sortedProcessTaskLogEntries,
    processTaskLogEntry
  }: ConvertOptions<never>): void {
    const newestOtherLogEntry = this.getNewestOtherLogEntry({
      entityName: logData.entityName as EntityName,
      entityId: logData.entityId as string,
      sortedProcessTaskLogEntries,
      processTaskLogEntry
    });

    if (!newestOtherLogEntry?.logDataJson) {
      return;
    }

    const newerLogData = JSON.parse(newestOtherLogEntry.logDataJson);
    if (newerLogData.displayNameAtLogTime) {
      logData.displayNameAtLogTime = newerLogData.displayNameAtLogTime;
    }
  }

  private getNewestOtherLogEntry({
    entityName,
    entityId,
    sortedProcessTaskLogEntries,
    processTaskLogEntry
  }: {
    entityName: EntityName;
    entityId: string;
    sortedProcessTaskLogEntries: Array<ProcessTaskLogEntry>;
    processTaskLogEntry: ProcessTaskLogEntry;
  }): ProcessTaskLogEntry | null {
    const relevantLogEntryActions = new Set([
      ProcessTaskLogEntryAction.SUB_ENTITY_CREATED,
      ProcessTaskLogEntryAction.SUB_ENTITY_DELETED,
      ProcessTaskLogEntryAction.SUB_ENTITY_MODIFIED
    ]);

    for (const logEntry of sortedProcessTaskLogEntries) {
      if (
        !logEntry.logDataJson ||
        !relevantLogEntryActions.has(logEntry.logAction)
      ) {
        continue;
      }

      if (
        logEntry.logDataJson.includes(`"entityId":"${entityId}"`) &&
        logEntry.logDataJson.includes(`"entityName":"${entityName}"`)
      ) {
        return logEntry;
      }

      // we only need newer versions, so we stop as soon we reach the currently handled processTaskLogEntry
      if (logEntry === processTaskLogEntry) {
        return null;
      }
    }

    return null;
  }

  private stringLogDataJsonContainsIds({
    logDataJson,
    entityIds
  }: {
    logDataJson: string;
    entityIds: Array<string>;
  }): boolean {
    for (const id of entityIds) {
      if (logDataJson.includes(`"entityId":"${id}"`)) {
        return true;
      }
    }

    return false;
  }
}
