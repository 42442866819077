import { Vector } from 'common/Geometry/Vector';
import { PointerEventHelper } from '../PointerEventHelper';

export class EdgeScroller {
  private screenOffset = 50;

  private scrollIntervalHandle: number | null = null;

  private boundMoveHandler = this.moveHandler.bind(this);

  private scrollFunction = (amount: number, direction?: boolean): void => {
    const scrollAmount = this.screenOffset - amount;
    window.scrollBy({
      top: direction ? scrollAmount * -1 : scrollAmount
    });
  };

  public setEnabled(enabled: boolean): void {
    if (enabled) {
      this.setupMouseMoveListener();
    } else {
      this.clearScrollInterval();
      this.removeMouseMoveListener();
    }
  }

  private setupMouseMoveListener(): void {
    document.body.addEventListener('mousemove', this.boundMoveHandler);
    document.body.addEventListener('touchmove', this.boundMoveHandler);
  }

  private removeMouseMoveListener(): void {
    document.body.removeEventListener('mousemove', this.boundMoveHandler);
    document.body.removeEventListener('touchmove', this.boundMoveHandler);
  }

  private moveHandler(event: MouseEvent | TouchEvent): void {
    const mousePosition = PointerEventHelper.getClientPositionFromEvent(event);
    const windowSize = new Vector(window.innerWidth, window.innerHeight);
    const distance = windowSize.clone().substractVector(mousePosition);

    if (distance.getY() < this.screenOffset) {
      this.setupScrollInterval(distance.getY());
    } else if (mousePosition.getY() < this.screenOffset) {
      this.setupScrollInterval(mousePosition.getY(), true);
    } else {
      this.clearScrollInterval();
    }
  }

  private setupScrollInterval(amount: number, direction?: boolean): void {
    this.clearScrollInterval();
    this.scrollIntervalHandle = window.setInterval(() => {
      this.scrollFunction(amount, direction);
    }, 20);
  }

  private clearScrollInterval(): void {
    if (!this.scrollIntervalHandle) return;
    window.clearInterval(this.scrollIntervalHandle);
    this.scrollIntervalHandle = null;
  }
}
