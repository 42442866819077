import { computedFrom } from 'aurelia-binding';
import { SubscriptionManager } from 'src/classes/SubscriptionManager';
import { ComputedValueService } from 'src/computedValues/ComputedValueService';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';

export class ShowProcessAppointmentConfiguration {
  private appointment: ProcessTaskAppointment | null = null;
  private isAutoAppointment: boolean = false;
  private processConfiguration: ProcessConfiguration | null = null;

  private appointmentHideSignatureField: boolean = false;
  private appointmentPositionCreation: boolean = false;
  private canUseOperations: boolean = false;

  constructor(
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly subscriptionManager: SubscriptionManager,
    private readonly computedValueService: ComputedValueService,
    private readonly currentUserService: CurrentUserService
  ) {}

  public subscribe(): Array<Disposable> {
    return [
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.appointment
            ? { processTaskGroupId: this.appointment.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (update) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'appointment.ownerProcessTaskGroupId',
            update
          );
        },
        callback: (configuration) => {
          this.processConfiguration = configuration;
        }
      }),
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentHideSignatureField',
        (appointmentHideSignatureField) => {
          this.appointmentHideSignatureField = appointmentHideSignatureField;
        }
      ),
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentPositionCreation',
        (appointmentPositionCreation) => {
          this.appointmentPositionCreation = appointmentPositionCreation;
        }
      ),
      this.currentUserService.bindCurrentUser((user) => {
        this.canUseOperations = PermissionHelper.userHasPermission(
          user,
          'canUseOperations'
        );
      })
    ];
  }

  public setAppointment(appointment: ProcessTaskAppointment | null): void {
    this.appointment = appointment;
    this.isAutoAppointment =
      !!appointment?.processConfigurationStepAutoAppointmentId;
  }

  @computedFrom('processConfiguration.showProcessAppointmentTabs.address')
  public get showAddress(): boolean {
    return (
      this.processConfiguration?.showProcessAppointmentTabs?.address ?? true
    );
  }

  @computedFrom('processConfiguration.showProcessAppointmentTabs.phone')
  public get showPhone(): boolean {
    return this.processConfiguration?.showProcessAppointmentTabs?.phone ?? true;
  }

  @computedFrom('processConfiguration.showProcessAppointmentTabs.general')
  public get showGeneral(): boolean {
    return (
      this.processConfiguration?.showProcessAppointmentTabs?.general ?? true
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.measurePoints'
  )
  public get showMeasurePoints(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.measurePoints ??
        true) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.pictures'
  )
  public get showPictures(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.pictures ??
        true) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.devices'
  )
  public get showDevices(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.devices ??
        true) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.positions',
    'appointmentPositionCreation'
  )
  public get showPositions(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.positions ??
        true) &&
      this.appointmentPositionCreation &&
      !this.isAutoAppointment
    );
  }

  @computedFrom('appointmentPositionCreation', 'canUseOperations')
  public get showPositionPrices(): boolean {
    return this.appointmentPositionCreation || this.canUseOperations;
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.allOfferedPositions'
  )
  public get showAllOfferedPositions(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs
        ?.allOfferedPositions ??
        false) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.forms'
  )
  public get showForms(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.forms ?? true) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom(
    'processConfiguration.showProcessAppointmentTabs.appointmentOverview'
  )
  public get showAppointmentOverview(): boolean {
    return (
      this.processConfiguration?.showProcessAppointmentTabs
        ?.appointmentOverview ?? true
    );
  }

  @computedFrom(
    'isAutoAppointment',
    'processConfiguration.showProcessAppointmentTabs.worknote'
  )
  public get showWorkNote(): boolean {
    return (
      (this.processConfiguration?.showProcessAppointmentTabs?.worknote ??
        true) &&
      !this.isAutoAppointment
    );
  }

  @computedFrom('processConfiguration.showProcessAppointmentTabs.comments')
  public get showComments(): boolean {
    return (
      this.processConfiguration?.showProcessAppointmentTabs?.comments ?? true
    );
  }

  @computedFrom('isAutoAppointment', 'appointmentHideSignatureField')
  public get showSignature(): boolean {
    return !this.isAutoAppointment && !this.appointmentHideSignatureField;
  }
}
