import { DateUtils } from 'common/DateUtils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Defect } from './types';

export class DefectRepository extends AppEntityRepository<EntityName.Defect> {
  public getByAssigneeId(assigneeId: string): Array<Defect> {
    return this.getAll().filter((d) => d.assigneeId === assigneeId);
  }

  public getByOwnerThingId(ownerThingId: string): Array<Defect> {
    return this.getAll().filter((d) => d.ownerThingId === ownerThingId);
  }

  public getByCreationDateAndOwnerThingId(
    creationDate: Date,
    ownerThingId: string
  ): Array<Defect> {
    const dateString = DateUtils.formatToDateString(creationDate);
    return this.getAll().filter(
      (d) =>
        d.ownerThingId === ownerThingId &&
        DateUtils.formatToDateString(d.createdAt) === dateString
    );
  }
}
