import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import {
  ProcessTaskToProjectRelationInfo,
  ProcessTaskToProjectRelationInfoRequestType
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { ProjectHelper } from 'common/EntityHelper/ProjectHelper';

import { Project } from '../../classes/EntityManager/entities/Project/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AbstractProcessTaskToProjectCrudStrategy } from '../../dialogs/manage-process-task-to-project-relations-dialog/strategies/AbstractProcessTaskToProjectCrudStrategy';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ManageProcessTaskToProjectRelationsDialog } from '../../dialogs/manage-process-task-to-project-relations-dialog/manage-process-task-to-project-relations-dialog';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { GlobalLoadingOverlay } from '../../loadingComponents/global-loading-overlay/global-loading-overlay';

@autoinject()
export class ProcessTaskToProjectRelationsWidget<
  TTargetEntityType extends Project | ProcessTask
> {
  @bindable public activeTargetEntity: TTargetEntityType | null = null;

  @bindable
  public crudStrategy: AbstractProcessTaskToProjectCrudStrategy | null = null;

  @bindable public entityName: string | null = null;

  /*
   * read only
   */
  @bindable public totalCount: number | null = null;

  protected availableProcessTaskToProjectRelationInfos: Array<ProcessTaskToProjectRelationInfo> =
    [];

  protected afterFirstUpdate = false;

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly router: Router
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.crudStrategy,
      'cannot ProcessTaskToProjectRelationsWidget.attached without crudStrategy'
    );

    this.crudStrategy.setupSubscriptions(
      this.subscriptionManager,
      this.updateAvailableProcessTaskToProjectRelationInfos.bind(this, {
        showOverlayForLongResponseTimes: true,
        showOverlayImmediately: false
      })
    );
    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected activeTargetEntityChanged(): void {
    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected async updateAvailableProcessTaskToProjectRelationInfos(
    config: UpdateSettings
  ): Promise<void> {
    if (!this.crudStrategy || !this.activeTargetEntity) return;

    await this.crudStrategy.updateProcessTaskToProjectsAndCandidates(
      {
        activeTargetEntityId: this.activeTargetEntity.id,
        paginationInfo: {
          currentPageNumber: 1,
          currentPageSize: Number.MAX_SAFE_INTEGER
        },
        processTaskToProjectType:
          ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
        type: ProcessTaskToProjectRelationInfoRequestType.WITH_EXISTING_RELATION,

        includeArchivedEntities: true
      },
      {
        setOverlayStatus: (status: boolean) => {
          GlobalLoadingOverlay.setLoadingState(this, status);
        },
        setTotalRelationsCount: (count) => {
          this.totalCount = count;
        },
        setRelationsToDisplay: (
          infos: Array<ProcessTaskToProjectRelationInfo>
        ) => {
          const oldRelationInfos =
            this.availableProcessTaskToProjectRelationInfos;
          this.availableProcessTaskToProjectRelationInfos =
            InstancePreserver.createNewArray({
              originalArray: oldRelationInfos,
              newArray: infos,
              getTrackingValue: (item) =>
                item.processTaskId ?? '' + (item.projectId ?? '')
            });
          this.afterFirstUpdate = true;
        },
        config
      }
    );
  }

  protected getTargetRoute(
    relationInfo: ProcessTaskToProjectRelationInfo
  ): string {
    if (relationInfo.processTaskId) {
      return this.router.generate('edit_process_task', {
        process_task_id: relationInfo.processTaskId
      });
    }
    if (relationInfo.projectId) {
      const project = this.entityManager.projectRepository.getRequiredById(
        relationInfo.projectId
      );
      switch (project.projectType) {
        case ProjectType.B1300:
          return this.router.generate('edit_b1300_project', {
            project_id: relationInfo.projectId
          });
        case ProjectType.INSPECT:
          return this.router.generate('edit_inspect_project', {
            project_id: relationInfo.projectId
          });
        default:
          return this.router.generate('project', {
            project_id: relationInfo.projectId
          });
      }
    }
    throw new Error(
      'Illegal combination of processTaskToProject entity ids, cannot create route parameters.'
    );
  }

  protected getProjectColor(
    relationInfo: ProcessTaskToProjectRelationInfo
  ): string | null {
    if (relationInfo.projectId) {
      const project = this.entityManager.projectRepository.getRequiredById(
        relationInfo.projectId
      );
      return ProjectHelper.getColorOfProject(project.projectType);
    }
    return 'var(--record-it-color-module-basic)';
  }

  protected handleManageRelationsButtonClick(): void {
    assertNotNullOrUndefined(
      this.activeTargetEntity,
      'cannot handleManageRelationsButtonClick without project'
    );
    assertNotNullOrUndefined(
      this.crudStrategy,
      'cannot handleManageRelationsButtonClick without crudStrategy'
    );

    void ManageProcessTaskToProjectRelationsDialog.open({
      entityName: this.entityName,
      activeTargetEntity: this.activeTargetEntity,
      crudStrategy: this.crudStrategy,
      updateProcessTaskToProjectRelationInfos: () => {
        void this.updateAvailableProcessTaskToProjectRelationInfos({
          showOverlayForLongResponseTimes: true,
          showOverlayImmediately: false
        });
      }
    });
  }
}

type UpdateSettings = {
  showOverlayImmediately: boolean;
  showOverlayForLongResponseTimes: boolean;
};
