import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskInvoice } from './types';

export class ProcessTaskInvoiceRepository extends AppEntityRepository<EntityName.ProcessTaskInvoice> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskInvoice> {
    return this.getAll().filter(
      (e) => e.ownerProcessTaskGroupId === processTaskGroupId
    );
  }
}
