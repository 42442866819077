import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';

@autoinject
export class ThingTypeSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.ThingType]
    | null = null;

  protected itemProperties: Array<ItemProperty> = [];

  constructor(private readonly router: Router) {}

  // Aurelia Lifecycle

  protected attached(): void {
    this.updateItemProperties();
  }

  // Aurealia Change Handlers

  protected searchResultChanged(): void {
    this.updateItemProperties();
  }

  // Aurelia Handlers

  protected handleNavigateToThingTypeClick(): void {
    const route = this.getRouteForThingTypeSearchResult();
    this.router.navigate(route);
  }

  // Private Methods

  private getRouteForThingTypeSearchResult(): string {
    assertNotNullOrUndefined(
      this.searchResult,
      'cannot navigate to thing type'
    );

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
      case ReasonType.Property:
        return this.router.generate('edit_object_types', {
          thing_type_id: this.searchResult.id
        });

      default:
        throw new Error(
          `cannot navigate to thing type result with unknown reason ${
            (
              this
                .searchResult as EntityNameToSearchResultMap[EntityName.ThingType]
            ).reason.type
          }`
        );
    }
  }

  private updateItemProperties(): void {
    if (!this.searchResult) {
      this.itemProperties = [];
    } else {
      this.itemProperties = [
        {
          labelTk: 'modelProperties.created',
          value: DateUtils.formatToDateWithHourMinuteString(
            this.searchResult.created
          )
        }
      ];
    }
  }
}
