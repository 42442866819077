import { autoinject, observable } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { EditUserCompanySettingDialog } from '../../dialogs/edit-user-company-setting-dialog/edit-user-company-setting-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  UserCompanySettingSortOption,
  UserCompanySettingSortOptions,
  UserCompanySettingUtils
} from '../../classes/EntityManager/entities/UserCompanySetting/UserCompanySettingUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { UserCompanySetting } from '../../classes/EntityManager/entities/UserCompanySetting/types';
import { Pagination } from '../../aureliaComponents/pagination/pagination';
import { FilterHelper } from '../../classes/FilterHelper';

@autoinject()
export class edit_user_company_settings {
  private subscriptionManager: SubscriptionManager;

  protected availableUserCompanySettings: Array<UserCompanySetting> = [];
  protected filteredUserCompanySettings: Array<UserCompanySetting> = [];
  protected sortedUserCompanySettings: Array<UserCompanySetting> = [];
  protected currentPageUserCompanySettings: Array<UserCompanySetting> = [];

  protected isMobile = false;
  private isAttached = false;

  protected sortOptions: UserCompanySettingSortOptions =
    UserCompanySettingUtils.userCompanySettingSortOptions;
  protected currentSortOption: UserCompanySettingSortOption =
    UserCompanySettingUtils.userCompanySettingSortOptions.name;

  private pagination: Pagination | null = null;
  protected listItemContainer: HTMLElement | null = null;

  @observable protected userCompanySettingFilterString: string;

  protected readonly EntityName = EntityName;

  constructor(
    private entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.userCompanySettingFilterString = '';
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserCompanySetting,
      this.updateAvailableUserCompanySettings.bind(this)
    );
    this.updateAvailableUserCompanySettings();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();

    this.isAttached = false;
  }

  protected userCompanySettingFilterStringChanged(): void {
    this.updateFilteredUserCompanySettings();
  }

  private updateAvailableUserCompanySettings(): void {
    this.availableUserCompanySettings =
      this.entityManager.userCompanySettingRepository.getAll();
    this.updateFilteredUserCompanySettings();
  }

  private updateFilteredUserCompanySettings(): void {
    this.filteredUserCompanySettings = FilterHelper.filterItems(
      this.availableUserCompanySettings,
      (userCompanySetting) => userCompanySetting.name ?? '',
      this.userCompanySettingFilterString
    );
  }

  protected handleCreateUserCompanySettingClicked(): void {
    const userCompanySetting =
      this.entityManager.userCompanySettingRepository.create(
        UserCompanySettingUtils.createDefaultUserCompanySetting()
      );
    this.updateAvailableUserCompanySettings();
    this.openEditUserCompanySettingDialog(userCompanySetting);
  }

  protected handleEditUserCompanySettingClicked(
    setting: UserCompanySetting
  ): void {
    this.openEditUserCompanySettingDialog(setting);
  }

  private openEditUserCompanySettingDialog(
    userCompanySetting: UserCompanySetting
  ): void {
    void EditUserCompanySettingDialog.open({
      userCompanySetting: userCompanySetting,
      onDialogClosed: () => {
        this.goToUserCompanySetting(userCompanySetting);
      }
    });
  }

  private goToUserCompanySetting(userCompanySetting: UserCompanySetting): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getUserCompanySettingListItemId(userCompanySetting.id),
      this.pagination,
      userCompanySetting,
      () => this.isAttached
    );
  }

  private getUserCompanySettingListItemId(
    userCompanySettingId: string
  ): string {
    return 'edit-user-company-settings--' + userCompanySettingId;
  }
}
