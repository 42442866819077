import { computedFrom } from 'aurelia-framework';
import { UserDefinedEntityConfig } from '../../../classes/EntityManager/entities/UserDefinedEntityConfig/types';
import { UserDefinedEntityConfigAdapter } from '../EntityAdapter/UserDefinedEntityConfigAdapter/UserDefinedEntityConfigAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class UserDefinedEntityConfigPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<UserDefinedEntityConfigAdapter>,
  UserDefinedEntityConfig
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }
}
