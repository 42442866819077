import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createEntryUsageStatisticEntityInfo } from 'common/Types/Entities/EntryUsageStatistic/createEntryUsageStatisticEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { EntryUsageStatistic } from './types';

export const entryUsageStatisticEntityInfo =
  createEntryUsageStatisticEntityInfo<EntryUsageStatistic>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
