import { WebSocketHandlerErrorType } from './WebSocketHandlerErrorType';

export class WebSocketHandlerError<
  Context extends Record<string, unknown> = Record<string, unknown>
> extends Error {
  public readonly type: WebSocketHandlerErrorType =
    WebSocketHandlerErrorType.ERROR_UNKNOWN;

  public readonly context: Context | undefined;

  constructor(params: WebSocketHandlerErrorParams<Context>) {
    super(params.message ?? undefined);
    this.type = params.type;
    this.context = params.context;
  }
}

export type WebSocketHandlerErrorParams<
  Context extends Record<string, unknown> = Record<string, unknown>
> = {
  type: WebSocketHandlerErrorType;
  message?: string | null;
  context?: Context;
};
