import _ from 'lodash';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TimeWheelConfiguration } from './time-wheel/time-wheel';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

export class TimePickerDialog {
  private hours: number = 0;
  private minutes: number = 0;
  private minutesTickWidth: number = 1;
  private onTimeSelected: OnTimeSelectedCallback | null = null;
  private onClosing: OnClosingCallback | null = null;
  private mode: Mode = Mode.Hours;
  private timeWheelValue: number = 0;

  private timeWheelConfiguration: TimeWheelConfiguration | null = null;

  private fullScreenOverlay: FullScreenOverlay | null = null;
  private timeWheelContainer: HTMLElement | null = null;

  private Mode = Mode;

  public static async open(options: IOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IOpenOptions): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't open without a fullScreenOverlay"
    );

    this.hours = options.hours;
    this.minutes = options.minutes;
    this.minutesTickWidth = options.minutesTickWidth;
    this.onTimeSelected = options.onTimeSelected || null;
    this.onClosing = options.onClosing || null;

    this.fullScreenOverlay.open();

    this.mode = Mode.Hours;
    this.updateTimeWheelConfiguration();
  }

  private handleBackClick(): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't handleCancelClick without a fullScreenOverlay"
    );

    switch (this.mode) {
      case Mode.Minutes:
        this.mode = Mode.Hours;
        this.updateTimeWheelConfiguration();
        break;

      case Mode.Hours:
      default:
        this.fullScreenOverlay.close();
        break;
    }
  }

  private handleContinueClick(): void {
    this.acceptValue(this.timeWheelValue);
  }

  private handleHoursPreviewClick(): void {
    this.mode = Mode.Hours;
    this.updateTimeWheelConfiguration();
  }

  private handleMinutesPreviewClick(): void {
    this.mode = Mode.Minutes;
    this.updateTimeWheelConfiguration();
  }

  private handleTimeWheelValueChanged(): void {
    this.applyValue(this.timeWheelValue);
  }

  private handleTimeWheelValueAccepted(): void {
    this.acceptValue(this.timeWheelValue);
  }

  private handleFullScreenOverlayClosing(): void {
    this.onClosing?.();
  }

  private acceptValue(value: number): void {
    assertNotNullOrUndefined(
      this.fullScreenOverlay,
      "can't acceptTick without a fullScreenOverlay"
    );

    this.applyValue(value);

    switch (this.mode) {
      case Mode.Minutes:
        this.onTimeSelected && this.onTimeSelected(this.hours, this.minutes);
        this.fullScreenOverlay.close();
        break;

      case Mode.Hours:
      default:
        this.mode = Mode.Minutes;
        this.updateTimeWheelConfiguration();
        break;
    }
  }

  private applyValue(value: number): void {
    switch (this.mode) {
      case Mode.Minutes:
        this.minutes = value;
        break;

      case Mode.Hours:
      default:
        this.hours = value;
        break;
    }
  }

  private updateTimeWheelConfiguration(): void {
    switch (this.mode) {
      case Mode.Minutes:
        this.timeWheelConfiguration = this.getMinuteTimeWheelConfiguration();
        this.timeWheelValue = this.minutes;
        break;

      case Mode.Hours:
      default:
        this.timeWheelConfiguration = this.getHourTimeWheelConfiguration();
        this.timeWheelValue = this.hours;
        break;
    }
  }

  private getHourTimeWheelConfiguration(): TimeWheelConfiguration {
    return {
      outerTicks: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((value) => ({
        value
      })),
      innerTicks: [0, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(
        (value) => ({ value, secondary: true })
      )
    };
  }

  private getMinuteTimeWheelConfiguration(): TimeWheelConfiguration {
    const minutes: Array<number> = [];

    for (let i = 0; i < 60; i += this.minutesTickWidth) {
      minutes.push(i);
    }

    return {
      outerTicks: minutes.map((minute) => ({
        value: minute,
        visible: minute % 5 === 0
      })),
      innerTicks: []
    };
  }

  private formatPreview(minutes: number): string {
    return _.padStart(minutes.toString(), 2, '0');
  }
}

enum Mode {
  Hours = 'hours',
  Minutes = 'minutes'
}

export type OnTimeSelectedCallback = (hours: number, minutes: number) => void;
export type OnClosingCallback = () => void;
export interface IOpenOptions {
  hours: number;
  minutes: number;
  /* must be a multiple of 5 */
  minutesTickWidth: number;
  onTimeSelected?: OnTimeSelectedCallback;
  onClosing?: OnClosingCallback;
}
