import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

/**
 * Dialog for editing the assigned user of a defect.
 */
@autoinject()
export class EditDefectAssignedUserDialog {
  /**
   * The ID of the defect that's currently being edited.
   */
  protected defectId: string | null = null;

  public defect: Defect | null = null;

  protected dialog: RecordItDialog | null = null;

  protected selectedUserId: string | null = null;

  /**
   * true if a user is currently being invited over email,
   * false otherwise.
   */
  protected isInvitingUser: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subManagerService.create();
  }

  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open EditDefectAssignedUserDialog without a RecordItDialog.'
    );
    this.defectId = options.defectId;
    this.updateDefect();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateDefect.bind(this)
    );

    this.dialog.open();
  }

  private afterClose(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ////////// ENTITY UPDATERS //////////

  private updateDefect(): void {
    if (!this.defectId) {
      this.defect = null;
      return;
    }

    this.defect = this.entityManager.defectRepository.getById(this.defectId);
  }

  // ////////// HANDLERS //////////

  protected handleDialogClosed(): void {
    this.afterClose();
  }
}

export type OpenOptions = {
  defectId: string;
};
