import { autoinject } from 'aurelia-dependency-injection';
import { EntityGroup, EntityGrouper } from '../../classes/EntityGrouper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ComputedValueService } from '../ComputedValueService';
import {
  DeviceGroupConfigurationsByProcessTaskId,
  DeviceGroupConfigurationsByProcessTaskIdComputer
} from './DeviceGroupConfigurationByProcessTaskIdComputer/DeviceGroupConfigurationByProcessTaskIdComputer';
import { ValueComputer, ValueComputerComputeOptions } from './ValueComputer';

/**
 * the group object instances can/will be modified later on because they will be reused for future results. This is necessary, so groups can be used in a repeat.for without aurelia discarding the dom
 */
@autoinject()
export class ProcessTaskDeviceGroupsForProcessTaskIdComputer extends ValueComputer<
  ProcessTaskDeviceGroupsForProcessTaskIdComputerComputeData,
  ProcessTaskDeviceGroups
> {
  private readonly subscriptionManager: SubscriptionManager;
  private deviceGroupConfigurationsByProcessTaskId: DeviceGroupConfigurationsByProcessTaskId =
    new Map();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      invokeCompute
    );
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: DeviceGroupConfigurationsByProcessTaskIdComputer,
        computeData: {},
        callback: (deviceGroupConfigurationsByProcessTaskId) => {
          this.deviceGroupConfigurationsByProcessTaskId =
            deviceGroupConfigurationsByProcessTaskId;
          invokeCompute();
        }
      })
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(
    {
      processTaskId
    }: ProcessTaskDeviceGroupsForProcessTaskIdComputerComputeData,
    { lastResult }: ValueComputerComputeOptions<ProcessTaskDeviceGroups>
  ): ProcessTaskDeviceGroups {
    const devices =
      this.entityManager.processTaskDeviceRepository.getByProcessTaskIdWithoutSnapshots(
        processTaskId
      );
    const properties =
      this.entityManager.propertyRepository.getByOwnerProcessTaskId(
        processTaskId
      );

    const entityGrouper = new EntityGrouper<ProcessTaskDevice>({
      groupConfigurations:
        this.deviceGroupConfigurationsByProcessTaskId.get(processTaskId) ?? [],
      properties: properties,
      propertyEntityIdField: 'processTaskDeviceId'
    });

    return InstancePreserver.createNewArray({
      originalArray: lastResult ?? [],
      newArray: entityGrouper.groupEntities(devices),
      getTrackingValue: (item) => item.name
    });
  }
}

export type ProcessTaskDeviceGroupsForProcessTaskIdComputerComputeData = {
  processTaskId: string;
};

export type ProcessTaskDeviceGroups = Array<EntityGroup<ProcessTaskDevice>>;
