import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  GalleryThingPictureOverviewEntryGroup as CommonPictureOverviewEntryGroup,
  GetPictureOverviewEntryGroupsSuccessResponse,
  PaginationInfo
} from 'common/EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';
import { CommonProject } from 'common/GalleryThing/GalleryThingPictureGroupHelper';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { PictureFileUploadService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import {
  GalleryThingPictureOverviewEntry as ClientPictureOverviewEntry,
  GalleryThingPictureOverviewEntryGroup as ClientPictureOverviewEntryGroup,
  OnBaseMapMarkerClicked
} from '../../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { PropertyBinder } from '../../../../classes/PropertyBinder/PropertyBinder';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { UrlManager } from '../../../../classes/UrlManager';
import {
  Disposable,
  DisposableContainer
} from '../../../../classes/Utils/DisposableContainer';
import {
  IUtilsRateLimitedFunction,
  Utils
} from '../../../../classes/Utils/Utils';
import { GlobalLoadingOverlay } from '../../../../loadingComponents/global-loading-overlay/global-loading-overlay';
import { MapMarker } from '../../../../map/basemap-map/basemap-map';
import {
  SingleSocketRequest,
  SingleSocketRequestService,
  SingleSocketRequestSkippedError
} from '../../../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../../../services/SocketService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { BaseMapDataCallbacks } from '../GalleryThingPictureDataSource';
import { GalleryThingPictureDataSourceStrategy } from './GalleryThingPictureDataSourceStrategy';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscribableArray } from '../../../../classes/SubscribableArray/SubscribableArray';

export class GalleryThingPicturesFromServerStrategy extends GalleryThingPictureDataSourceStrategy {
  private readonly getPictureOverviewEntryGroupsRequest: SingleSocketRequest<
    { filter: GalleryThingPictureFilter; paginationInfo: PaginationInfo },
    GetPictureOverviewEntryGroupsSuccessResponse<CommonProject>
  >;
  private readonly propertyBinder: PropertyBinder<PropertyBinderConfig>;

  private currentFilter: GalleryThingPictureFilter | null = null;
  private paginationInfo: PaginationInfo = {
    currentIndex: 1,
    currentPageSize: 10
  };

  private updatePaginationMaxIndex: (totalCount: number) => void;

  private readonly socketService: SocketService;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly pictureFileUploadService: PictureFileUploadService;
  private readonly permissionsService: PermissionsService;

  private updatePictureOverviewEntriesRatelimited: IUtilsRateLimitedFunction;
  protected readonly onMarkerClicked: OnBaseMapMarkerClicked;

  constructor(options: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    singleSocketRequestService: SingleSocketRequestService;
    pictureFileUploadService: PictureFileUploadService;
    socketService: SocketService;
    baseMapDataCallbacks: BaseMapDataCallbacks;
    updatePaginationMaxIndex: (totalCount: number) => void;
    permissionsService: PermissionsService;
  }) {
    super(options);

    this.socketService = options.socketService;
    this.pictureFileUploadService = options.pictureFileUploadService;
    this.subscriptionManager = options.subscriptionManagerService.create();
    this.permissionsService = options.permissionsService;

    this.onMarkerClicked = options.baseMapDataCallbacks.onMarkerClicked;

    this.getPictureOverviewEntryGroupsRequest =
      options.singleSocketRequestService.createAfterFirstActualizationRequest({
        requestCallback: ({ data }) => {
          return new Promise<
            GetPictureOverviewEntryGroupsSuccessResponse<CommonProject>
          >((resolve, reject) => {
            options.socketService.getGalleryThingPictureOverviewEntries(
              { filter: data.filter, paginationInfo: data.paginationInfo },
              (r) => {
                if (r.success) {
                  resolve(r);
                } else {
                  console.error(r);
                  reject(
                    new Error(
                      "couldn't fetch gallery thing picture overview entries"
                    )
                  );
                }
              }
            );
          });
        }
      });

    this.propertyBinder = new PropertyBinder<PropertyBinderConfig>({
      defaultValuesByName: {
        availablePictureOverviewEntryGroups: []
      }
    });

    this.updatePictureOverviewEntriesRatelimited = Utils.rateLimitFunction(
      this.updatePictureOverviewEntries.bind(this, {
        showOverlay: false,
        isPoll: false
      }),
      20
    );
    this.updatePaginationMaxIndex = options.updatePaginationMaxIndex;
  }

  public subscribe(): Disposable {
    this.subscriptionManager.subscribeToEvent('socket:authenticated', () => {
      void this.updatePictureOverviewEntries({
        showOverlay: true,
        isPoll: false
      });
    });

    this.subscriptionManager.subscribeToInterval(() => {
      if (!this.getPictureOverviewEntryGroupsRequest.isPending)
        void this.updatePictureOverviewEntries({
          showOverlay: false,
          isPoll: true
        });
    }, 10000);

    // Recreate entries if the user changed properties => new icons + basemap markers
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      () => {
        void this.updatePictureOverviewEntriesRatelimited();
      }
    );

    // Recreate entries if the user changed picture files => new thumbnails
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      () => {
        void this.updatePictureOverviewEntriesRatelimited();
      }
    );

    this.subscriptionManager.addDisposable(
      this.pictureFileUploadService.registerHooks({
        afterFileWasUploaded:
          this.updatePictureOverviewEntriesRatelimited.bind(this)
      })
    );

    return {
      dispose: () => {
        this.subscriptionManager.disposeSubscriptions();
        this.updatePictureOverviewEntriesRatelimited.cancel();
      }
    };
  }

  public async updatePictures(): Promise<void> {
    void this.updatePictureOverviewEntriesRatelimited();
  }

  public setFilter(
    filter: GalleryThingPictureFilter,
    paginationInfo: PaginationInfo
  ): void {
    this.currentFilter = filter;
    this.paginationInfo = paginationInfo;
    void this.updatePictureOverviewEntriesRatelimited();
  }

  public bindFilteredPictureGroups(
    callback: (
      filteredPictureGroups: Array<ClientPictureOverviewEntryGroup>
    ) => void
  ): Disposable {
    const disposableContainer = new DisposableContainer();
    const subscribableArray =
      new SubscribableArray<ClientPictureOverviewEntryGroup>({
        getSubscribableFromItem: (item) => item.projectPermissionsHandle
      });

    disposableContainer.add(subscribableArray.subscribe());

    disposableContainer.add(
      this.propertyBinder.registerBinding(
        'availablePictureOverviewEntryGroups',
        (items) => {
          subscribableArray.items = items;
          callback(subscribableArray.items);
        }
      )
    );

    return disposableContainer.toDisposable();
  }

  private async updatePictureOverviewEntries(
    config: UpdateSettings
  ): Promise<void> {
    if (
      !this.socketService.isAuthenticated() ||
      !this.socketService.isConnected() ||
      !this.currentFilter
    )
      return;
    const delayedLoadingOverlay = !config.isPoll
      ? setTimeout(() => {
          GlobalLoadingOverlay.setLoadingState(this, true);
        }, 1000)
      : null;

    try {
      config.showOverlay && GlobalLoadingOverlay.setLoadingState(this, true);

      const response = await this.getPictureOverviewEntryGroupsRequest.send({
        filter: this.currentFilter,
        paginationInfo: this.paginationInfo
      });
      const entryGroups = response.entryGroups;

      this.updatePaginationMaxIndex(response.totalResultLength);
      this.propertyBinder.setValue(
        'availablePictureOverviewEntryGroups',
        this.convertServerToClientOverviewEntryGroups(entryGroups)
      );
      this.onMarkersChanged();
    } catch (e) {
      if (!(e instanceof SingleSocketRequestSkippedError)) {
        throw e;
      }
    } finally {
      if (delayedLoadingOverlay) clearTimeout(delayedLoadingOverlay);
      GlobalLoadingOverlay.setLoadingState(this, false);
    }
  }

  /**
   * This function is used to handle to handle differences between server and client overviewEntries (and overviewEntryGroups)
   */
  private convertServerToClientOverviewEntryGroups(
    entryGroups: Array<CommonPictureOverviewEntryGroup<CommonProject>>
  ): Array<ClientPictureOverviewEntryGroup> {
    const clientEntryGroups: Array<ClientPictureOverviewEntryGroup> = [];
    for (const group of entryGroups) {
      const clientEntries: Array<ClientPictureOverviewEntry> = [];
      const {
        pictureOverviewEntries,
        project: serverProject,
        ...remainingGroupAttrs
      } = group;
      for (const e of pictureOverviewEntries) {
        const { baseMapMarker, ...remainingEntryAttrs } = e;
        const clientMapMarker = baseMapMarker
          ? new MapMarker(
              baseMapMarker.latitude,
              baseMapMarker.longitude,
              baseMapMarker.colorSuffix
            )
          : null;

        const clientEntry: ClientPictureOverviewEntry = {
          ...remainingEntryAttrs,
          baseMapMarker: clientMapMarker
        };
        clientEntry.thumbnailPath = this.prependThumbnailPath(
          clientEntry.thumbnailPath
        );
        clientMapMarker?.onClick(() => {
          this.onMarkerClicked(clientEntry);
        }, null);
        clientEntries.push(clientEntry);
      }

      const clientProject = this.entityManager.projectRepository.getById(
        serverProject.id
      );
      assertNotNullOrUndefined(
        clientProject,
        `got GalleryThingOverviewEntry whose project ${serverProject.id} is not available on this client.`
      );

      const projectPermissionsHandle =
        this.permissionsService.getPermissionsHandleForEntity({
          entityName: EntityName.Project,
          entity: clientProject
        });

      const clientGroup: ClientPictureOverviewEntryGroup = {
        pictureOverviewEntries: clientEntries,
        project: clientProject,
        ...remainingGroupAttrs,
        projectPermissionsHandle
      };
      clientEntryGroups.push(clientGroup);
    }
    return clientEntryGroups;
  }

  private prependThumbnailPath(path: string | null): string | null {
    if (path == null) return null;
    if (!(path[0] === '/'))
      throw new Error(
        'galleryThingOverviewEntry has non-relative path where we expect a root-relative path.'
      );
    return PathUtils.joinPaths(UrlManager.webFolder, path);
  }
}

type PropertyBinderConfig = {
  availablePictureOverviewEntryGroups: Array<ClientPictureOverviewEntryGroup>;
};

type UpdateSettings = {
  showOverlay: boolean;
  isPoll: boolean;
};
