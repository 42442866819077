import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import {
  expression,
  model,
  currentUser as currentUserDependency
} from '../../hooks/dependencies';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { computed } from '../../hooks/computed';
import { User } from '../../classes/EntityManager/entities/User/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SocketService } from '../../services/SocketService';

@autoinject()
export class GalleryThingReportTypeWidget {
  @bindable public thing: Thing | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private subscriptionManager: SubscriptionManager;

  protected isOnline = false;

  protected selectedReportTypeId: string | null = null;

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService
  ) {
    this.permissionsHandle = permissionsService.getPermissionsHandleForProperty(
      {
        entityName: EntityName.Thing,
        context: this as GalleryThingReportTypeWidget,
        propertyName: 'thing'
      }
    );

    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(expression('thing.report_type'), model(EntityName.ReportType))
  protected get currentReportType(): ReportType | null {
    if (!this.thing?.report_type) {
      this.selectedReportTypeId = null;

      return null;
    }

    const newReportType = this.entityManager.reportTypeRepository.getById(
      this.thing.report_type
    );

    this.selectedReportTypeId = newReportType?.id ?? null;

    return newReportType;
  }

  protected handleSetReportTypeClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleSetReportTypeClick without a thing'
    );

    this.thing.report_type = this.selectedReportTypeId;
    this.entityManager.thingRepository.update(this.thing);
  }

  protected reportTypeIsEditable(
    currentReportType: ReportType,
    currentUser: User
  ): boolean {
    const editableUserGroups = currentUser
      ? this.entityManager.userGroupRepository.getEditableGroupsForUser(
          currentUser
        )
      : [];
    return currentReportType
      ? PermissionHelper.userCanEditOwnerUserGroupIdEntity(
          currentReportType,
          currentUser,
          editableUserGroups
        )
      : false;
  }

  @computed(currentUserDependency())
  protected get currentUser(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  @computed(expression('thing.report_type'), model(EntityName.ReportType))
  protected get currentReportTypeName(): string {
    if (this.thing?.report_type == null || this.thing.report_type === '') {
      return `(${this.i18n.tr('general.noReportType')})`;
    }

    const type = this.entityManager.reportTypeRepository.getById(
      this.thing.report_type
    );

    if (!type) {
      return this.i18n.tr('general.reportTypeNotFound');
    }
    return type.name ?? '';
  }
}
