import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { UrlManager } from '../../../UrlManager';
import { GeneralFile } from './types';

export class GeneralFileUtils {
  private constructor() {}

  public static getFullOnlineFilePathForGeneralFile(
    generalFile: GeneralFile
  ): string | null {
    return this.getFullOnlineFilePath(
      generalFile.id,
      generalFile.fileUploadedAt,
      generalFile.fileCreatedAt,
      generalFile.extension
    );
  }

  public static getRelativeOnlineFilePathForGeneralFile(
    generalFile: GeneralFile
  ): string | null {
    return this.getRelativeOnlineFilePath(
      generalFile.id,
      generalFile.fileUploadedAt,
      generalFile.fileCreatedAt,
      generalFile.extension
    );
  }

  public static getFullOnlineFilePath(
    generalFileId: string,
    fileUploadedAt: number | null,
    fileCreatedAt: number,
    extension: string | null
  ): string | null {
    if (!fileUploadedAt) {
      return null;
    }

    const relativePath = this.getRelativeOnlineFilePath(
      generalFileId,
      fileUploadedAt,
      fileCreatedAt,
      extension
    );
    if (!relativePath) return null;

    return PathUtils.joinPaths(UrlManager.webFolder, relativePath);
  }

  private static getRelativeOnlineFilePath(
    generalFileId: string,
    fileUploadedAt: number | null,
    fileCreatedAt: number,
    extension: string | null
  ): string | null {
    if (!fileUploadedAt) {
      return null;
    }

    return PathUtils.joinPaths(
      'generalFile',
      'download',
      generalFileId,
      fileCreatedAt.toString(),
      fileUploadedAt.toString(),
      extension || ''
    );
  }
}
