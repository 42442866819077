/**
 * a container to create promises and resolve/reject all of them at once
 */
export class PromiseContainer<T> {
  private pendingPromises: Array<TPromiseFunctions<T>> = [];
  private permanentStatus: TPermanentStatus<T> | null = null;

  public create(): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.permanentStatus) {
        this.pendingPromises.push({ resolve, reject });
      } else if (this.permanentStatus.type === 'resolve') {
        resolve(this.permanentStatus.value);
      } else if (this.permanentStatus.type === 'reject') {
        reject(this.permanentStatus.error);
      }
    });
  }

  /**
   * resolve all existing and new Promises immediately with the given value until resetPermanentStatus has been called
   */
  public resolvePermanent(value: T): void {
    this.permanentStatus = {
      type: 'resolve',
      value
    };

    this.resolveAll(value);
  }

  /**
   * reject all existing and new Promises immediately with the given error until resetPermanentStatus has been called
   */
  public rejectPermanent(error: Error): void {
    this.permanentStatus = {
      type: 'reject',
      error
    };

    this.rejectAll(error);
  }

  /**
   * stop automatically resolving/rejecting promises
   */
  public resetPermanentStatus(): void {
    this.permanentStatus = null;
  }

  /**
   * resolve all created promises
   */
  public resolveAll(value: T): void {
    this.consumeAllPendingPromises((p) => p.resolve(value));
  }

  /**
   * reject all created promises
   */
  public rejectAll(error: unknown): void {
    this.consumeAllPendingPromises((p) => p.reject(error));
  }

  public getPendingPromiseCount(): number {
    return this.pendingPromises.length;
  }

  private consumeAllPendingPromises(
    cb: (p: TPromiseFunctions<T>) => void
  ): void {
    const promises = this.pendingPromises;
    this.pendingPromises = [];

    promises.forEach(cb);
  }
}

type TPermanentStatus<T> =
  | { type: 'resolve'; value: T }
  | { type: 'reject'; error: Error };

type TPromiseFunctions<T> = {
  resolve: (value: T) => void;
  reject: (e: unknown) => void;
};
