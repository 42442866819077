import { bindable, inlineView } from 'aurelia-framework';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { ContextualPropertyWidgetConfiguration } from '../base-property-widget/config/PropertyWidgetConfiguration/ContextualPropertyWidgetConfiguration';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@inlineView(`
<template>
  <span t="propertyComponents.contextualBasePropertyWidget.notAllFeaturesImplemented"></span>
</template>
`)
export class ContextualBasePropertyWidgetNotAllFeaturesImplemented
  implements PropertySubWidget
{
  @bindable()
  public configuration: ContextualPropertyWidgetConfiguration<any> | null =
    null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  public focus(): void {
    throw new Error(
      "ContextualBasePropertyWidgetNotAllFeaturesImplemented doesn't support focus"
    );
  }
}
