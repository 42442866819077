import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskChecklistEntry } from '../../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { User } from '../../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  DoneChangedEvent,
  TextChangedEvent
} from '../process-task-checklist-list-item-layout/process-task-checklist-list-item-layout';
import { ProcessTaskChecklistService } from '../../../services/ProcessTaskChecklistService/ProcessTaskChecklistService';
import { ProcessTaskChecklistEntryGeneralFile } from '../../../classes/EntityManager/entities/GeneralFile/types';
import { DateDisplayFormat } from '../../../valueConverters/DateFormatValueConverter';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskChecklistEntryListItem {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public entry: ProcessTaskChecklistEntry | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly entryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskChecklistEntry];

  private isAttached: boolean = false;

  protected user: User | null = null;
  protected generalFiles: Array<ProcessTaskChecklistEntryGeneralFile> = [];

  protected readonly DateDisplayFormat = DateDisplayFormat;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskChecklistService: ProcessTaskChecklistService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.entryPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskChecklistEntry,
        context: this as ProcessTaskChecklistEntryListItem,
        propertyName: 'entry'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToExpression(
      this,
      'entry.userId',
      this.updateUser.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateUser.bind(this)
    );
    this.updateUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.GeneralFile,
      this.updateGeneralFiles.bind(this)
    );
    this.updateGeneralFiles();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected entryChanged(): void {
    if (this.isAttached) {
      this.updateGeneralFiles();
    }
  }

  protected handleDoneChanged(event: DoneChangedEvent): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskChecklistEntryListItem.handleDoneChanged without processTask"
    );
    assertNotNullOrUndefined(
      this.entry,
      "can't ProcessTaskChecklistEntryListItem.handleDoneChanged without entry"
    );

    void this.processTaskChecklistService.setChecklistEntryDone({
      processTask: this.processTask,
      checklistEntry: this.entry,
      done: event.detail.checked
    });
  }

  protected handleTextChanged(event: TextChangedEvent): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ProcessTaskChecklistEntryListItem.handleTextChanged without processTask"
    );
    assertNotNullOrUndefined(
      this.entry,
      "can't ProcessTaskChecklistEntryListItem.handleTextChanged without entry"
    );

    void this.processTaskChecklistService.setChecklistEntryText({
      processTask: this.processTask,
      checklistEntry: this.entry,
      text: event.detail.value != null ? event.detail.value.toString() : null
    });
  }

  private updateUser(): void {
    if (this.entry?.userId) {
      this.user = this.entityManager.userRepository.getById(this.entry.userId);
    } else {
      this.user = null;
    }
  }

  private updateGeneralFiles(): void {
    if (this.entry) {
      this.generalFiles =
        this.entityManager.generalFileRepository.getByProcessTaskChecklistEntryId(
          this.entry.id
        );
    } else {
      this.generalFiles = [];
    }
  }
}
