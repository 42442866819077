import { autoinject } from 'aurelia-framework';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ProcessConfigurationProcessTaskPropertiesConfiguration } from 'common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskProperty } from '../../../classes/EntityManager/entities/Property/types';
import { FinishAppointmentOptionsWithConfigurationStep } from '../ProcessTaskAppointmentFinishService';

@autoinject()
export class ProcessTaskPropertiesValidator {
  constructor(private readonly entityManager: AppEntityManager) {}

  public validate({
    processTask,
    canEditGeneralProcessTaskInfo
  }: FinishAppointmentOptionsWithConfigurationStep): {
    valid: boolean;
    missingProperties: Array<ProcessTaskProperty>;
  } {
    const missingProperties = canEditGeneralProcessTaskInfo
      ? this.getMissingRequiredProcessTaskProperties({ processTask })
      : [];

    return {
      valid: missingProperties.length === 0,
      missingProperties
    };
  }

  private getMissingRequiredProcessTaskProperties({
    processTask
  }: {
    processTask: ProcessTask;
  }): Array<ProcessTaskProperty> {
    const properties = this.entityManager.propertyRepository.getByProcessTaskId(
      processTask.id
    );
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        processTask.ownerProcessTaskGroupId
      );
    const processConfiguration = processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
    const json = processConfiguration?.processTaskPropertiesConfigurationJson
      ? processConfiguration.processTaskPropertiesConfigurationJson
      : null;
    const propertiesConfiguration: ProcessConfigurationProcessTaskPropertiesConfiguration | null =
      json ? JSON.parse(json) : null;
    const requiredProperties = (
      propertiesConfiguration?.defaultProperties ?? []
    ).filter((p) => p.requiredToFinishAppointment);

    return properties.filter((property) => {
      const requiredProperty = requiredProperties.find((rp) =>
        PropertyHelper.isTheSameProperty(rp, property)
      );
      if (requiredProperty) {
        return !property.value;
      }

      return false;
    });
  }
}
