import { storeInfo } from './storeInfo';

export class StoreInfoUtils {
  private constructor() {}

  public static getAllTableNames(): Array<string> {
    return [
      storeInfo.defaultTableName,
      storeInfo.migrationsTableName,
      ...storeInfo.applicationTables.map((table) => table.name)
    ];
  }

  public static getApplicationTableNamesWithDefaultTable(): Array<string> {
    return [
      storeInfo.defaultTableName,
      ...storeInfo.applicationTables.map((table) => table.name)
    ];
  }
}
