import { autoinject } from 'aurelia-framework';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { Dialogs } from '../../classes/Dialogs';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingViewType } from '../../aureliaComponents/switch-thing-type-button/switch-thing-type-button';
import { watch } from '../../hooks/watch';
import { Router } from 'aurelia-router';
import { UpgradeEntityIdInUrlService } from '../../services/UpgradeEntityIdInUrlService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditCatastrophe {
  private thingId: string | null = null;

  protected ThingViewType = ThingViewType;

  @subscribableLifecycle()
  protected thingPermissionHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly upgradeEntityIdInUrlService: UpgradeEntityIdInUrlService,
    private readonly router: Router,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditCatastrophe,
        propertyName: 'thing'
      });
  }

  protected activate(params: { thing_id: string }): void {
    this.thingId = params.thing_id;
  }

  @watch(expression('thingId'), model(EntityName.Thing))
  protected upgradeThing(): void {
    this.upgradeEntityIdInUrlService.checkId(
      {
        id: this.thingId,
        entityName: EntityName.Thing
      },
      'thing_id'
    );
  }

  protected handleFinishCatastropheButtonClicked(): void {
    // TODO: KUK finish catastrophe
    void Dialogs.todoDialog();
  }

  @computed(expression('thingId'), model(EntityName.Thing))
  public get thing(): Thing | null {
    if (!this.thingId) return null;

    return this.entityManager.thingRepository.getById(this.thingId);
  }
}
