import { inject } from 'aurelia-framework';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';

@inject(AppEntityManager, ActiveEntitiesService)
export class edit_person {
  /** @type {ActiveEntitiesService} */
  _activeEntitiesService;

  /** @type {import('../../classes/EntityManager/entities/Person/types').Person|null} */
  _person = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {ActiveEntitiesService} activeEntitiesService
   */
  constructor(entityManager, activeEntitiesService) {
    this._entityManager = entityManager;
    this._activeEntitiesService = activeEntitiesService;

    this._PersonUtils = PersonUtils; // for the view
  }

  /**
   * @param {{ person_id: string }} params
   */
  activate(params) {
    this._person =
      this._entityManager.personRepository.getById(params.person_id) || null;
    if (this._person) this._activeEntitiesService.setActivePerson(this._person);
    ScrollHelper.scrollToPosition(0, 0);
  }

  deactivate() {
    this._activeEntitiesService.setActivePerson(null);
  }
}
