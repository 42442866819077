import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createThingSectionEntityInfo } from 'common/Types/Entities/ThingSection/createThingSectionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ThingSection } from './types';

export const thingSectionEntityInfo =
  createThingSectionEntityInfo<ThingSection>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
