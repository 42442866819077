import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { Property } from '../EntityManager/entities/Property/types';
import { createPropertyConfigsByPropertyTypes } from './createPropertyConfigsByPropertyTypes/createPropertyConfigsByPropertyTypes';
import {
  RequiredPropertyGroup,
  ValidationResult
} from './RequiredProperty/RequiredPropertyGroup';
import { RequiredPropertyGroupUtils } from './RequiredProperty/RequiredPropertyGroupUtils';

export class RequiredPropertiesValidator {
  private readonly groups: Array<RequiredPropertyGroup>;

  constructor({
    properties,
    entityManager
  }: {
    properties: Array<Property>;
    entityManager: AppEntityManager;
  }) {
    const propertyConfigsByPropertyType = createPropertyConfigsByPropertyTypes({
      entityManager
    });
    this.groups = RequiredPropertyGroupUtils.createRequiredPropertyGroups({
      properties,
      propertyConfigsByPropertyType
    });
  }

  public validate(): ValidationResult {
    for (const group of this.groups) {
      const result = group.validate();
      if (result.success === false) {
        return result;
      }
    }

    return {
      success: true
    };
  }
}
