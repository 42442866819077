import { autoinject, bindable } from 'aurelia-framework';

import { PropertyStringParser } from 'common/PropertyStringParser/PropertyStringParser';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { Dialogs } from '../../classes/Dialogs';
import { UiUpdater } from '../../classes/UiUpdater';
import { ScrollLinker } from '../../classes/ScrollLinker';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntryProperty } from '../../classes/EntityManager/entities/Property/types';
import { ThingToPerson } from '../../classes/EntityManager/entities/ThingToPerson/types';
import { AddEntryEvent } from './edit-structure-table-widget-left-fixed-bar-entry-row/edit-structure-table-widget-left-fixed-bar-entry-row';
import { expression, model } from '../../hooks/dependencies';
import { computed } from '../../hooks/computed';
import { watch } from '../../hooks/watch';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

// TODO: automatically enable_properties on new entries

@autoinject()
export class EditStructureTableWidget {
  @bindable public projectId: string | null = null;

  /** groupName of the entries which should be shown */
  @bindable public groupName: string | null = null;

  /**
   * the columns to display
   * the pageDepthIndex will always be visible
   */
  @bindable public columns: Array<string> = [];

  @bindable public forceDisabled: boolean = false;

  private entries: Array<Entry> = [];

  protected columnConfigs: Array<ColumnConfig> = [];

  /** this flags indicates if the entry properties are updated to the columns */
  private updatedEntryProperties = false;

  private boundUpdateHeaderWidth = this.updateHeaderWidth.bind(this);
  protected isScrolled = false;

  private headerElement: HTMLElement | null = null;
  private headerWidthMeasureElement: HTMLElement | null = null;
  private tableElement: HTMLElement | null = null;
  private leftFixedBarElement: HTMLElement | null = null;
  private headerLeftFixedCellElement: HTMLElement | null = null;

  private isActualizing = true;

  private subscriptionManager: SubscriptionManager;

  private scrollLinker: ScrollLinker;

  private hasPersonColumn = false;

  @subscribableLifecycle()
  protected readonly projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly applyPropertiesService: ApplyPropertiesService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.scrollLinker = new ScrollLinker();

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Project,
        context: this,
        expression: 'project'
      });

    this.projectPermissionsHandle.canCreateEntries;
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.bindIsActualizing(
        (isActualizing) => {
          this.isActualizing = isActualizing;

          if (!isActualizing) {
            this.updateEntriesProperties(this.entries);
          }
        }
      )
    );

    this.updateEntries();

    UiUpdater.registerUpdateFunction(this.boundUpdateHeaderWidth);
    this.setUpScrollLinker();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    UiUpdater.unregisterUpdateFunction(this.boundUpdateHeaderWidth);
    this.scrollLinker.removeAllLinks();
  }

  protected forceDisabledChanged(): void {
    this.projectPermissionsHandle.overrideAllPermissions(
      this.forceDisabled ? false : null
    );
  }

  private setUpScrollLinker(): void {
    if (
      !this.leftFixedBarElement ||
      !this.tableElement ||
      !this.headerElement ||
      !this.headerLeftFixedCellElement
    ) {
      throw new Error('not all required elements are bound');
    }

    this.scrollLinker.addLink(this.leftFixedBarElement, {
      strategy: 'position',
      direction: 'horizontal'
    });

    this.scrollLinker.addLink(this.tableElement, {
      strategy: 'scroll',
      direction: 'horizontal'
    });

    this.scrollLinker.addLink(this.headerElement, {
      strategy: 'scroll',
      direction: 'horizontal'
    });

    this.scrollLinker.addLink(this.headerLeftFixedCellElement, {
      strategy: 'position',
      direction: 'horizontal'
    });

    this.scrollLinker.onLastValueChanged(this.updateIsScrolled.bind(this));

    this.updateIsScrolled();
  }

  private updateIsScrolled(): void {
    this.isScrolled = this.tableElement
      ? this.tableElement.scrollLeft > 0
      : false;
  }

  // ++++++++++ entries updating ++++++++++

  @watch(
    expression('projectId'),
    expression('groupName'),
    model(EntityName.Entry)
  )
  protected updateEntries(): void {
    const entries: Array<Entry> = [];
    const rootEntries = this.projectId
      ? this.entityManager.entryRepository.getByParentId(
          this.projectId,
          null,
          this.groupName
        )
      : [];

    rootEntries.forEach((entry) => {
      this.addEntryAndChildrenRecursive(entry, entries);
    });

    this.entries = entries;
    this.updateEntriesProperties(this.entries);
  }

  protected columnsChanged(): void {
    const columns = this.columns;
    const configs: Array<ColumnConfig> = [];

    columns.forEach((column) => {
      configs.push(ColumnConfig.createColumnConfigFromString(column));
    });

    this.hasPersonColumn = configs.some((config) => config.isPersonColumn());

    this.columnConfigs = configs;
    this.updatedEntryProperties = false;
    this.updateEntriesProperties(this.entries);
  }

  /**
   * this function will add all entries and sub entries to the entries array
   *
   * be careful, entries will be modified in place!
   */
  private addEntryAndChildrenRecursive(
    entry: Entry,
    entries: Array<Entry>
  ): void {
    entries.push(entry);

    const children =
      this.entityManager.entryRepository.getSubEntriesOfEntry(entry);
    children.forEach((child) => {
      this.addEntryAndChildrenRecursive(child, entries);
    });
  }

  private updateEntriesProperties(entries: Array<Entry>): void {
    if (
      this.isActualizing ||
      this.updatedEntryProperties ||
      !entries ||
      !entries.length ||
      !this.columns ||
      !this.columns.length
    ) {
      return; // only really update the properties if entries and columns are present
    }

    entries.forEach((entry) => {
      this.applyPropertiesService.applyRawCustomPropertiesToEntry(
        entry,
        this.columns
      );
    });

    this.updatedEntryProperties = true;
  }

  // ++++++++++ entry adding/deleting ++++++++++

  protected handleAddEntryClick(): void {
    if (this.projectId) {
      this.addEntry();
    }
  }

  protected handleAddEntry(event: AddEntryEvent): void {
    this.addEntry(event.detail.listPosition, event.detail.pageDepthParent);
  }

  private addEntry(
    listPosition?: number | null,
    pageDepthParent?: string | null
  ): Entry {
    if (!this.project) {
      throw new Error("can't create an entry without a project");
    }

    const entry = {
      project: this.project.id,
      ownerProjectId: this.project.id,
      group_name: this.groupName,
      enable_properties: true,
      page_depth_parent: pageDepthParent || null,
      ...(listPosition != null ? { list_position: listPosition } : {}),
      ownerUserGroupId: this.project.ownerUserGroupId
    };

    const createdEntry = this.entityManager.entryRepository.create(entry);
    this.applyPropertiesService.applyRawCustomPropertiesToEntry(
      createdEntry,
      this.columns
    );

    return createdEntry;
  }

  protected loadThingToPersonsIntoTable(): void {
    if (!this.project?.thing || !this.hasPersonColumn) return;

    const availableThingToPersons =
      this.entityManager.thingToPersonRepository.getByThingId(
        this.project?.thing
      );
    let atLeastOneNewEntryAdded = false;

    if (availableThingToPersons.length === 0) {
      Dialogs.warningDialogTk(
        'aureliaComponents.editStructureTableWidget.noThingToPersonsFound',
        'aureliaComponents.editStructureTableWidget.addPersonsToObjectRequest'
      );
      return;
    }

    for (const thingToPerson of availableThingToPersons) {
      const entryWithPersonAlreadyExists =
        this.personEntryInTableAlreadyExists(thingToPerson);

      if (!entryWithPersonAlreadyExists) {
        const newEntry = this.addEntry();
        const properties = this.entityManager.propertyRepository.getByEntryId(
          newEntry.id
        );
        // If there are multiple person properties, we will set the value only for the first/leftmost column
        const personProperty = properties.find(
          (p) => p.type === PropertyType.PERSON
        );
        if (personProperty) {
          this.createNewPropertyToPerson(
            personProperty,
            thingToPerson.personId
          );
          atLeastOneNewEntryAdded = true;
        }
      }
    }

    if (!atLeastOneNewEntryAdded) {
      Dialogs.warningDialogTk(
        'aureliaComponents.editStructureTableWidget.allThingToPersonsAlreadyInTable'
      );
      return;
    }
  }

  private createNewPropertyToPerson(
    personProperty: EntryProperty,
    personId: string
  ): void {
    this.entityManager.propertyToPersonRepository.create({
      ownerUserGroupId: personProperty.ownerUserGroupId,
      ownerProjectId: personProperty.ownerProjectId,
      ownerProcessTaskId: personProperty.ownerProcessTaskId,
      ownerProcessTaskGroupId: personProperty.ownerProcessTaskGroupId,

      propertyId: personProperty.id,
      personId: personId
    });
  }

  private personEntryInTableAlreadyExists(
    thingToPerson: ThingToPerson
  ): boolean {
    for (const entry of this.entries) {
      const properties = this.entityManager.propertyRepository.getByEntryId(
        entry.id
      );
      const personProperty = properties.find(
        (p) => p.type === PropertyType.PERSON
      );
      const propertyToPerson =
        this.entityManager.propertyToPersonRepository.getByPropertyId(
          personProperty!.id
        );
      if (propertyToPerson[0]?.personId === thingToPerson.personId) return true;
    }
    return false;
  }

  // ++++++++++ view helpers ++++++++++

  /**
   * this function will ensure the header is properly sized, since it is outside the scroll container it will have the wrong width
   */
  private updateHeaderWidth(): void {
    // check if element is bound already
    if (this.headerElement && this.headerWidthMeasureElement) {
      this.headerElement.style.width = window.getComputedStyle(
        this.headerWidthMeasureElement
      ).width;
    }
  }

  @computed(expression('projectId'), model(EntityName.Project))
  private get project(): Project | null {
    return this.projectId
      ? this.entityManager.projectRepository.getById(this.projectId)
      : null;
  }
}

export enum TableColumnWidth {
  'xx-small',
  'x-small',
  'small',
  'medium',
  'large',
  'x-large',
  'xx-large',
  'huge'
}

export class ColumnConfig {
  private static nameInputColumnNames = ['name']; // all names must be in lowercase
  private static pictureInputColumnNames = ['bild']; // all names must be in lowercase
  private static needsFillHeightColumnNames = [
    PropertyType.SIGNATURE,
    PropertyType.MULTILINE,
    PropertyType.MULTI_DROPDOWN
  ];

  /** column is mapped to the entry.name instead of a property */
  private isNameInput: boolean;

  /** column is mapped to the pictures of the entry instead of a property */
  private isPictureInput: boolean;

  /** this is actually a property :o */
  private isPropertyInput: boolean;

  private needsFillHeight: boolean;

  private propertyName: string;

  private propertyType: PropertyType;

  private colWidth: string;

  constructor(data: PlainColumnConfig) {
    this.isNameInput = data.isNameInput;
    this.isPictureInput = data.isPictureInput;
    this.isPropertyInput = data.isPropertyInput;
    this.needsFillHeight = data.needsFillHeight;
    this.propertyName = data.propertyName;
    this.propertyType = data.propertyType;
    this.colWidth = data.colWidth;
  }

  public isPersonColumn(): boolean {
    if (this.propertyType === PropertyType.PERSON) return true;
    return false;
  }

  /**
   * @param {string} str - propertyDefinition string from the template, e.g. coolProperty:checkbox
   * @returns {ColumnConfig}
   */
  public static createColumnConfigFromString(str: string): ColumnConfig {
    const parsed = PropertyStringParser.parse(str);
    assertNotNullOrUndefined(parsed, 'cannot parse property');

    const lowerCaseName = parsed.name.toLocaleLowerCase();
    const isNameInput =
      ColumnConfig.nameInputColumnNames.indexOf(lowerCaseName) >= 0;
    const isPictureInput =
      ColumnConfig.pictureInputColumnNames.indexOf(lowerCaseName) >= 0;
    const needsFillHeight =
      ColumnConfig.needsFillHeightColumnNames.indexOf(parsed.type) >= 0;

    const parsedWidth =
      PropertyStringParser.getOptionValueByName(parsed.options, 'colWidth') ??
      '';
    const colWidthString = Object.keys(TableColumnWidth).includes(parsedWidth)
      ? parsedWidth
      : 'medium';

    return new ColumnConfig({
      propertyName: parsed.name,
      propertyType: parsed.type,
      isNameInput: isNameInput,
      isPictureInput: isPictureInput,
      isPropertyInput: !isNameInput && !isPictureInput,
      needsFillHeight: needsFillHeight,
      colWidth: colWidthString
    });
  }
}

type PlainColumnConfig = {
  isNameInput: boolean;
  isPictureInput: boolean;
  isPropertyInput: boolean;
  needsFillHeight: boolean;
  propertyName: string;
  propertyType: PropertyType;
  colWidth: string;
};
