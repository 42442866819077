import { autoinject } from 'aurelia-framework';
import {
  QuestionChoice,
  QuestionChoiceAction,
  QuestionChoiceActionType
} from 'common/Types/Entities/Question/QuestionDto';
import { ProjectQuestion } from '../classes/EntityManager/entities/ProjectQuestion/types';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { GlobalCustomDialog } from '../dialogs/global-custom-dialog/global-custom-dialog';

@autoinject()
export class ProjectQuestionService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public setQuestionAnswer(
    question: ProjectQuestion,
    choice: QuestionChoice | null
  ): void {
    question.answer = choice?.choiceText ?? null;
    this.entityManager.projectQuestionRepository.update(question);

    if (!choice) return;

    for (const action of choice.actions) {
      switch (action.actionType) {
        case QuestionChoiceActionType.ADD_QUESTION:
          this.triggerAddQuestionAction(question, action);
          break;

        case QuestionChoiceActionType.ADD_QUESTION_SET:
          this.triggerAddQuestionSetAction(question, action);
          break;

        case QuestionChoiceActionType.REDIRECT:
          break;

        case QuestionChoiceActionType.OPEN_DIALOG:
          this.triggerDialogAction(action);
          break;

        default:
          throw new Error(
            `unsupported QuestionChoiceActionType: "${action.actionType}"`
          );
      }
    }
  }

  private triggerAddQuestionAction(
    triggerQuestion: ProjectQuestion,
    action: QuestionChoiceAction
  ): void {
    const project = this.entityManager.projectRepository.getById(
      triggerQuestion.ownerProjectId
    );
    if (!action.questionId || !project) return;

    const questionToAdd = this.entityManager.questionRepository.getById(
      action.questionId
    );
    if (!questionToAdd) return;

    this.entityManager.projectQuestionRepository.createFromQuestion(
      questionToAdd,
      {
        projectQuestionCategoryId: triggerQuestion.projectQuestionCategoryId,
        projectQuestionSetId: triggerQuestion.projectQuestionSetId,
        project
      }
    );
  }

  private triggerAddQuestionSetAction(
    triggerQuestion: ProjectQuestion,
    action: QuestionChoiceAction
  ): void {
    const project = this.entityManager.projectRepository.getById(
      triggerQuestion.ownerProjectId
    );
    if (!action.questionSetId || !project) return;

    const questionSetQuestions =
      this.entityManager.questionRepository.getByQuestionSetId(
        action.questionSetId
      );

    for (const questionToAdd of questionSetQuestions) {
      this.entityManager.projectQuestionRepository.createFromQuestion(
        questionToAdd,
        {
          projectQuestionCategoryId: triggerQuestion.projectQuestionCategoryId,
          projectQuestionSetId: triggerQuestion.projectQuestionSetId,
          project
        }
      );
    }
  }

  private triggerDialogAction(action: QuestionChoiceAction): void {
    void GlobalCustomDialog.open({
      titleTk: action.dialogTitle,
      text: action.dialogText,
      icon: action.dialogIconType,
      buttons: [
        {
          textTk: 'general.ok'
        }
      ],
      autoCloseOnDisconnect: true
    });
  }
}
