import { Vector } from 'common/Geometry/Vector';

/**
 * Detects a pinch zooming gesture and outputs the diff of the zoom distance
 *
 * all Vectors are client positions!
 */
export class PinchZoomDetector {
  _active = false;

  _enabled = true;

  /**
   * gap between both fingers
   *
   * @type {Vector|null}
   * @private
   */
  _lastGapVector = null;
  /** @type {(function(Vector, Vector, Vector, Vector): void)|null} */
  _onPinchCallback = null;
  /** @type {(function(Vector, Vector): void)|null} */
  _onPinchStartCallback = null;

  /**
   *
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._element = element;

    this._boundHandleTouchstart = this._handleTouchstart.bind(this);
    this._boundHandleTouchmove = this._handleTouchmove.bind(this);
    this._boundHandleTouchend = this._handleTouchend.bind(this);

    this.init();
  }

  init() {
    if (this._initialized) {
      return;
    }

    this._element.addEventListener('touchstart', this._boundHandleTouchstart);
    this._element.addEventListener('touchmove', this._boundHandleTouchmove);
    this._element.addEventListener('touchend', this._boundHandleTouchend);

    this._initialized = true;
  }

  destroy() {
    this._element.removeEventListener(
      'touchstart',
      this._boundHandleTouchstart
    );
    this._element.removeEventListener('touchmove', this._boundHandleTouchmove);
    this._element.removeEventListener('touchend', this._boundHandleTouchend);

    this._initialized = false;
  }

  enable() {
    this._enabled = true;
  }

  disable() {
    this._enabled = false;
  }

  /**
   * calls the callback with the delta of the movement of both fingers
   *
   * @param {(function(Vector, Vector, Vector, Vector):void)|null} callback - function parameters: oldVector, oldMiddlePoint, newVector, newMiddlePoint
   */
  onPinch(callback) {
    this._onPinchCallback = callback;
  }

  /**
   * @param {(function(Vector, Vector): void)|null} callback - function parameters: vector, middlePoint
   */
  onPinchStart(callback) {
    this._onPinchStartCallback = callback;
  }

  /**
   * @param {TouchEvent} event
   * @private
   */
  _handleTouchstart(event) {
    if (event.touches.length === 2 && this._enabled) {
      this._active = true;
      this._lastGapVector = this._getTouchEventGapVector(event);
      this._lastMiddlePoint = this._getTouchEventMiddlePoint(event);
      this._onPinchStartCallback &&
        this._onPinchStartCallback(this._lastGapVector, this._lastMiddlePoint);
    } else {
      this._clearActiveState();
    }
  }

  /**
   * @param {TouchEvent} event
   * @private
   */
  _handleTouchmove(event) {
    if (!this._active) {
      return;
    }

    const gapVector = this._getTouchEventGapVector(event);
    const middlePoint = this._getTouchEventMiddlePoint(event);
    this._onPinchCallback &&
      this._onPinchCallback(
        this._lastGapVector.clone(),
        this._lastMiddlePoint.clone(),
        gapVector.clone(),
        middlePoint.clone()
      );
    this._lastGapVector = gapVector;
    this._lastMiddlePoint = middlePoint;

    event.preventDefault();
  }

  /**
   * @param {TouchEvent} event
   * @private
   */
  _handleTouchend(event) {
    this._clearActiveState();
  }

  /**
   * @param {TouchEvent} event
   * @returns {Vector}
   * @private
   */
  _getTouchEventGapVector(event) {
    const touchAPosition = this._getTouchEventClientPosition(event, 0);
    const touchBPosition = this._getTouchEventClientPosition(event, 1);
    return touchAPosition.substractVector(touchBPosition);
  }

  /**
   * @param {TouchEvent} event
   * @returns {Vector}
   * @private
   */
  _getTouchEventMiddlePoint(event) {
    const touchAPosition = this._getTouchEventClientPosition(event, 0);
    const touchBPosition = this._getTouchEventClientPosition(event, 1);
    const halfVector = touchAPosition
      .clone()
      .substractVector(touchBPosition)
      .divideVector(new Vector(2, 2));
    return touchAPosition.clone().addVector(halfVector);
  }

  /**
   * @param {TouchEvent} event
   * @param {number} touchOffset
   * @private
   */
  _getTouchEventClientPosition(event, touchOffset) {
    const touch = event.touches[touchOffset];
    if (touch) {
      return new Vector(touch.clientX, touch.clientY);
    } else {
      return null;
    }
  }

  _clearActiveState() {
    this._active = false;
  }
}
