import { bindable, autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper } from '../../../classes/DomEventHelper';

/**
 * @event {TValueChangedEvent} value-changed
 * @event input-blur
 */
@autoinject()
export class DateTimePickerNumberInput {
  @bindable()
  public value: string | null = null;

  @bindable()
  public digits: 2 | 4 = 2;

  @bindable()
  public placeholder: string | null = null;

  @bindable()
  public enabled: boolean = false;

  private domElement: HTMLElement;
  private inputElement: HTMLInputElement | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public focus(): void {
    assertNotNullOrUndefined(this.inputElement, 'inputElement not available');
    this.inputElement.focus();
  }

  public isFocused(): boolean {
    assertNotNullOrUndefined(this.inputElement, 'inputElement not available');
    return document.activeElement === this.inputElement;
  }

  private handleKeydown(event: KeyboardEvent): boolean {
    // do not prevent special key (tab, enter etc.)
    if (event.key.length > 1 || event.ctrlKey) {
      return true;
    }

    const digits = '0123456789';
    return digits.indexOf(event.key) >= 0;
  }

  private handleFocus(): void {
    assertNotNullOrUndefined(this.inputElement, 'inputElement not available');
    this.inputElement.select();
  }

  private handleInput(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent(this.domElement, {
        name: 'value-changed',
        detail: {
          value: this.value
        } as IValueChangedEventDetail
      });
    }, 0);
  }

  private handleBlur(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'input-blur',
      detail: null
    });
  }
}

export interface IValueChangedEventDetail {
  value: string | null;
}

export type TValueChangedEvent = Event & { detail: IValueChangedEventDetail };
