/**
 * the view here is directly in the index.html so it will be shown even before anything javascript related is initialized
 */
export class MainPageLoaderHelper {
  private static readonly MAX_DISPLAYED_INITIALIZING_STEPS = 5;
  private static lastInitializationStepElementInfo: {
    stepElement: HTMLElement;
    key: string | null;
  } | null = null;

  private constructor() {}

  public static hide(): void {
    $('#main-page-loader').fadeOut();
  }

  /**
   * @param stepName
   * @param key - If a key is given (and not null) and the last initializationStep has the same key as the currently set one, it will get overriden/updated. This is useful if you want to display a progress or something similar.
   * @returns
   */
  public static setInitializationStep(
    stepName: string,
    key: string | null = null
  ): void {
    const container = document.getElementById(
      'main-page-loader--InitializingStepsContainer'
    );
    if (!container) {
      return;
    }

    if (
      this.lastInitializationStepElementInfo?.key != null &&
      this.lastInitializationStepElementInfo.key === key
    ) {
      this.updateInitializationStepElementContent({
        stepElement: this.lastInitializationStepElementInfo.stepElement,
        stepName
      });
    } else {
      this.stripSuperflousInitializingSteps(container);
      this.addInitiliazingStep({
        container,
        stepName,
        key
      });
    }

    // log the stepName so it's available in the sentry telemetry
    console.log(`[initalization step] ${stepName}`);
  }

  private static stripSuperflousInitializingSteps(
    container: HTMLElement
  ): void {
    const toRemoveCount =
      container.childNodes.length -
      MainPageLoaderHelper.MAX_DISPLAYED_INITIALIZING_STEPS +
      1;
    for (let i = 0; i < toRemoveCount; i++) {
      if (container.lastChild) {
        container.removeChild(container.lastChild);
      }
    }
  }

  private static addInitiliazingStep({
    container,
    stepName,
    key
  }: {
    container: HTMLElement;
    stepName: string;
    key: string | null;
  }): void {
    for (const node of Array.from(container.childNodes)) {
      if (node instanceof HTMLElement) {
        node.classList.add('main-page-loader--InitializingStep_old');
      }
    }

    const stepElement = document.createElement('div');
    stepElement.classList.add('main-page-loader--InitializingStep');
    stepElement.appendChild(document.createTextNode(stepName));
    container.insertBefore(stepElement, container.firstChild);

    this.lastInitializationStepElementInfo = {
      stepElement,
      key
    };
  }

  private static updateInitializationStepElementContent({
    stepElement,
    stepName
  }: {
    stepElement: HTMLElement;
    stepName: string;
  }): void {
    while (stepElement.firstChild) {
      stepElement.firstChild.remove();
    }

    stepElement.appendChild(document.createTextNode(stepName));
  }
}
