import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Dialogs } from '../../classes/Dialogs';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { ProcessTaskMeasurePointReadingCreationService } from '../../services/ProcessTaskMeasurePointReadingCreationService';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event mark-measure-point-clicked
 */
@inject(
  Element,
  AppEntityManager,
  SubscriptionManagerService,
  ProcessTaskMeasurePointReadingCreationService
)
export class ProcessTaskMeasurePointListItem {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types').ProcessTaskMeasurePoint|null} */
  @bindable measurePoint = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment|null} */
  @bindable appointment = null;

  /** @type {boolean} */
  @bindable canManageMeasurePoints = false;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading>} */
  _measurePointReadingsHistory = [];

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading>} */
  _appointmentMeasurePointReadings = [];

  /** @type {import('../../classes/EntityManager/entities/ProcessConfigurationMeasurePointType/types').ProcessConfigurationMeasurePointType|null} */
  _selectedMeasurePointType = null;

  _newMeasurePointName = '';

  _showLog = false;

  /** @type {import('../../inputComponents/compact-clickable-text-input/compact-clickable-text-input').CompactClickableTextInput|null} */
  _nameInput = null;
  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {ProcessTaskMeasurePointReadingCreationService} processTaskMeasurePointReadingCreationService
   */
  constructor(
    element,
    entityManager,
    subscriptionManagerService,
    processTaskMeasurePointReadingCreationService
  ) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._processTaskMeasurePointReadingCreationService =
      processTaskMeasurePointReadingCreationService;
  }

  focus() {
    if (this._nameInput) {
      this._nameInput.focus();
    }
  }

  focusFirstReading() {
    this._updateMeasurePointReadings();
    setTimeout(() => {
      const reading = this._appointmentMeasurePointReadings[0];
      if (!reading) {
        return;
      }

      const vm = this._getReadingListItemViewModel(reading);
      if (vm) {
        vm.focus();
      }
    });
  }

  attached() {
    this._updateMeasurePointReadings();
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointReading,
      this._updateMeasurePointReadings.bind(this)
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  measurePointChanged() {
    this._updateMeasurePointReadings();
  }

  appointmentChanged() {
    this._updateMeasurePointReadings();
  }

  _updateMeasurePointReadings() {
    if (!this.measurePoint) {
      this._measurePointReadingsHistory = [];
      return;
    }
    this._measurePointReadingsHistory =
      this._entityManager.processTaskMeasurePointReadingRepository.getByProcessTaskMeasurePointId(
        this.measurePoint.id
      );
    this._measurePointReadingsHistory.sort((a, b) =>
      a.date.localeCompare(b.date)
    );
    const appointment = this.appointment;
    if (!appointment) {
      this._appointmentMeasurePointReadings = [];
      return;
    }
    this._appointmentMeasurePointReadings =
      this._measurePointReadingsHistory.filter(
        (o) => o.processTaskAppointmentId === appointment.id
      );
    this._measurePointReadingsHistory =
      this._measurePointReadingsHistory.filter(
        (o) => o.processTaskAppointmentId !== appointment.id
      );
  }

  async _handleDeleteMeasurePointClick() {
    if (!this.measurePoint) return;
    await Dialogs.deleteDialogTk(
      'operations.processTaskMeasurePointListWidget.measurePointDeleteConfirmation'
    );
    this._entityManager.processTaskMeasurePointRepository.delete(
      this.measurePoint
    );
  }

  _handleAddMeasurePointReadingClick() {
    if (!this.measurePoint) return;
    if (!this.appointment) return;

    const measurePointReading =
      this._processTaskMeasurePointReadingCreationService.createReadingWithProperties(
        this.measurePoint,
        this.appointment
      );
    this._updateMeasurePointReadings();

    setTimeout(() => {
      this._focusMeasurePointReading(measurePointReading);
    }, 20);
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} measurePointReading
   */
  _focusMeasurePointReading(measurePointReading) {
    const viewModel = this._getReadingListItemViewModel(measurePointReading);
    if (viewModel) viewModel.focus();
  }

  _handleMarkMeasurePointClick() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'mark-measure-point-clicked',
      detail: null
    });
  }

  _handleMeasurePointChanged() {
    if (this.measurePoint) {
      this._entityManager.processTaskMeasurePointRepository.update(
        this.measurePoint
      );
    }
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} processTaskMeasurePointReading
   */
  async _handleDeleteMeasurePointReadingClick(processTaskMeasurePointReading) {
    try {
      await Dialogs.deleteDialogTk(
        'operations.processTaskMeasurePointListWidget.measurePointReadingDeleteConfirmation'
      );
    } catch (e) {
      return;
    }
    this._entityManager.processTaskMeasurePointReadingRepository.delete(
      processTaskMeasurePointReading
    );
  }

  /**
   * @param {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading} reading
   * @returns {import('../process-task-measure-point-reading-item/process-task-measure-point-reading-item').ProcessTaskMeasurePointReadingItem|null}
   */
  _getReadingListItemViewModel(reading) {
    const elementId = this._getReadingListItemId(reading.id);
    const element = this._domElement.querySelector('#' + elementId);

    if (!element) {
      return null;
    }

    return Utils.getViewModelOfElement(/** @type {HTMLElement} */ (element));
  }

  /**
   * @param {string} readingId
   * @returns {string}
   */
  _getReadingListItemId(readingId) {
    return 'process-task-measure-point-list-item--reading-' + readingId;
  }
}
