import { autoinject, bindable } from 'aurelia-framework';

import { I18N } from 'aurelia-i18n';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Dialogs } from '../../classes/Dialogs';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ExtractProcessTaskOfferDialog } from '../extract-process-task-offer-dialog/extract-process-task-offer-dialog';
import { AcceptProcessTaskOfferDialog } from '../accept-process-task-offer-dialog/accept-process-task-offer-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SocketService } from '../../services/SocketService';
import { DateDisplayFormat } from '../../valueConverters/DateFormatValueConverter';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { FileDownloadService } from '../../services/FileDownloadService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

/**
 * @event edit-offer-clicked
 */
@autoinject()
export class ProcessTaskOfferListItem {
  @bindable()
  public processTaskOffer: ProcessTaskOffer | null = null;

  @bindable()
  public enabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  private panelOpened: boolean = false;
  private isAttached: boolean = false;
  protected relatedProcessTasks: Array<ProcessTask> = [];

  protected listItemElement: HTMLElement | null = null;

  @subscribableLifecycle()
  protected readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  protected readonly SequenceNumberUtils = SequenceNumberUtils;
  protected readonly SequenceNumberType = SequenceNumberType;
  protected readonly DateDisplayFormat = DateDisplayFormat;

  constructor(
    private readonly element: Element,
    private readonly i18n: I18N,
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this.updateRelatedProcessTasks.bind(this)
    );

    this.updateRelatedProcessTasks();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskOfferChanged(): void {
    if (this.isAttached) {
      this.updateRelatedProcessTasks();
    }
  }

  private updateRelatedProcessTasks(): void {
    if (this.processTaskOffer) {
      const relations =
        this.entityManager.processTaskOfferToProcessTaskRepository.getByProcessTaskOfferId(
          this.processTaskOffer.id
        );
      const tasks = this.entityManager.processTaskRepository.getAll();

      this.relatedProcessTasks = tasks.filter((t) => {
        return !!relations.find((r) => r.ownerProcessTaskId === t.id);
      });
    } else {
      this.relatedProcessTasks = [];
    }
  }

  protected handleEditOfferClick(): void {
    DomEventHelper.fireEvent<EditOfferClickedEvent>(this.element, {
      name: 'edit-offer-clicked',
      detail: null
    });
  }

  protected handleDeleteOfferClick(): void {
    const processTaskOffer = this.processTaskOffer;
    if (!processTaskOffer) {
      return;
    }

    void Dialogs.deleteEntityDialog(processTaskOffer).then(() => {
      this.entityManager.processTaskOfferRepository.delete(processTaskOffer);
    });
  }

  protected handleDownloadOfferClick(): void {
    const processTaskOffer = this.processTaskOffer;
    if (!processTaskOffer) return;

    Dialogs.waitDialog();
    this.socketService.downloadProcessTaskOffer(
      processTaskOffer.id,
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessage = this.i18n.tr(
            `serverResponses.${
              response.status ? response.status : 'unspecifiedError'
            }`
          );
          void Dialogs.errorDialog('Fehler beim Download!', errorMessage);
        }
      }
    );
  }

  protected handleExtractOfferClick(): void {
    if (!this.processTaskOffer) {
      return;
    }

    void ExtractProcessTaskOfferDialog.open({
      offer: this.processTaskOffer
    });
  }

  protected handleAcceptOfferClick(): void {
    if (!this.processTaskOffer) {
      return;
    }

    void AcceptProcessTaskOfferDialog.open({
      offer: this.processTaskOffer
    });
  }

  protected handleRevertAcceptOfferClick(): void {
    if (!this.processTaskOffer) {
      return;
    }

    this.processTaskOffer.acceptedAt = null;
    this.entityManager.processTaskOfferRepository.update(this.processTaskOffer);
  }

  protected handleToggleMorePanelClick(): void {
    this.panelOpened = !this.panelOpened;
  }
}

export type EditOfferClickedEvent = NamedCustomEvent<
  'edit-offer-clicked',
  null
>;
