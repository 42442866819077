import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ThingGroup } from '../../../classes/EntityManager/entities/ThingGroup/types';
import { ThingToPerson } from '../../../classes/EntityManager/entities/ThingToPerson/types';
import { EditPersonDialog } from '../../edit-person-dialog/edit-person-dialog';
import { ThingPersonRelationInfo } from '../thing-person-relations-select-and-edit-widget';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';
import { watch } from '../../../hooks/watch';

/**
 * @event {ThingToPersonDeletedEvent} thing-to-person-deleted
 */
@autoinject()
export class ThingPersonRelationsSelectAndEditWidgetRelation {
  @bindable()
  public thingToPersonInfo: ThingPersonRelationInfo | null = null;

  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public thingGroup: ThingGroup | null = null;

  @bindable()
  public userGroupId: string | null = null;

  /**
   * If this is set, new entities will be created in this temporaryGroupName and as shadow entities
   */
  @bindable()
  public temporaryGroupName: string | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected readonly thingToPersonPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ThingToPerson];

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  @subscribableLifecycle()
  protected readonly personPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as ThingPersonRelationsSelectAndEditWidgetRelation,
        propertyName: 'thing'
      });

    this.thingToPersonPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ThingToPerson,
        context: this,
        expression: 'thingToPersonInfo.thingToPerson'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForIdProperty({
        entityName: EntityName.UserGroup,
        context: this as ThingPersonRelationsSelectAndEditWidgetRelation,
        propertyName: 'userGroupId'
      });

    this.personPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'thingToPersonInfo.selectedPerson'
      });

    this.updatePermissionsOverride();
  }

  protected handleEditIconClicked(): void {
    assertNotNullOrUndefined(
      this.thingToPersonInfo?.selectedPerson,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handleEditIconClicked without thingToPersonInfo.selectedPerson"
    );

    void EditPersonDialog.open({
      person: this.thingToPersonInfo.selectedPerson
    });
  }

  protected handleRemoveIconClicked(): void {
    assertNotNullOrUndefined(
      this.thingToPersonInfo?.thingToPerson,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handleRemoveIconClicked without thingToPersonInfo.thingToPerson"
    );
    this.entityManager.thingToPersonRepository.delete(
      this.thingToPersonInfo.thingToPerson
    );

    DomEventHelper.fireEvent<ThingToPersonDeletedEvent>(this.element, {
      name: 'thing-to-person-deleted',
      detail: {
        thingToPerson: this.thingToPersonInfo.thingToPerson
      }
    });
  }

  protected handlePersonSelectValueChanged(): void {
    assertNotNullOrUndefined(
      this.thingToPersonInfo,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handlePersonSelectValueChanged without thingToPersonInfo"
    );

    if (!this.thingToPersonInfo.personId) {
      if (this.thingToPersonInfo.thingToPerson) {
        this.entityManager.thingToPersonRepository.delete(
          this.thingToPersonInfo.thingToPerson
        );
      }
    } else if (this.thingToPersonInfo.thingToPerson) {
      if (
        this.thingToPersonInfo.thingToPerson.personId !==
        this.thingToPersonInfo.personId
      ) {
        this.thingToPersonInfo.thingToPerson.personId =
          this.thingToPersonInfo.personId;
        this.entityManager.thingToPersonRepository.update(
          this.thingToPersonInfo.thingToPerson
        );
      }
    } else {
      assertNotNullOrUndefined(
        this.userGroupId,
        'can\t ThingPersonRelationsSelectAndEditWidgetRelation.handlePersonSelectValueChanged without userGroupId'
      );
      assertNotNullOrUndefined(
        this.thing,
        'can\t ThingPersonRelationsSelectAndEditWidgetRelation.handlePersonSelectValueChanged without thing'
      );
      const thingToPerson = this.entityManager.thingToPersonRepository.create({
        ownerUserGroupId: this.userGroupId,
        personId: this.thingToPersonInfo.personId,
        thingId: this.thing.id,
        temporaryGroupName: this.temporaryGroupName,
        shadowEntity: !!this.temporaryGroupName
      });

      this.thingToPersonInfo.thingToPerson = thingToPerson;
    }
  }

  protected handleUseAddressOfThingGroupClick(): void {
    assertNotNullOrUndefined(
      this.thingToPersonInfo,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handleUseAddressOfThingGroupClick without a thingToPersonInfo"
    );
    assertNotNullOrUndefined(
      this.thingGroup,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handleUseAddressOfThingGroupClick without a thingGroup"
    );
    assertNotNullOrUndefined(
      this.thing,
      "can't ThingPersonRelationsSelectAndEditWidgetRelation.handleUseAddressOfThingGroupClick without a thing"
    );

    if (this.thingToPersonInfo.selectedPerson) {
      this.thingToPersonInfo.selectedPerson.zip = this.thingGroup.zip;
      this.thingToPersonInfo.selectedPerson.streetName =
        this.thingGroup.streetName;
      this.thingToPersonInfo.selectedPerson.municipality =
        this.thingGroup.municipality;
      this.entityManager.personRepository.update(
        this.thingToPersonInfo.selectedPerson
      );
    } else {
      const person = this.entityManager.personRepository.create({
        streetName: this.thingGroup.streetName,
        zip: this.thingGroup.zip,
        municipality: this.thingGroup.municipality,
        ownerUserGroupId: this.thingGroup.ownerUserGroupId,
        temporaryGroupName: this.temporaryGroupName,
        shadowEntity: !!this.temporaryGroupName
      });

      const thingToPerson = this.entityManager.thingToPersonRepository.create({
        personId: person.id,
        thingId: this.thing.id,
        ownerUserGroupId: this.thing.ownerUserGroupId,
        temporaryGroupName: this.temporaryGroupName,
        shadowEntity: !!this.temporaryGroupName
      });

      this.thingToPersonInfo.personId = person.id;
      this.thingToPersonInfo.selectedPerson = person;
      this.thingToPersonInfo.thingToPerson = thingToPerson;
    }
  }

  @watch(
    expression('thingToPersonInfo.selectedPerson.ownerUserGroupId'),
    expression('thingPermissionsHandle.canCreateThingToPersons'),
    expression('userGroupPermissionsHandle.canCreatePersons')
  )
  protected updatePermissionsOverride(): void {
    // because a new person is created when inputting values, we need to enable the inputs if there is no person yet
    // if no ownerUserGroupId is set, we have a partial object from aurelia
    if (
      !this.thingToPersonInfo?.selectedPerson?.ownerUserGroupId &&
      this.thingPermissionsHandle.canCreateThingToPersons &&
      this.userGroupPermissionsHandle.canCreatePersons
    ) {
      this.personPermissionsHandle.overrideAllPermissions(true);
    } else {
      this.personPermissionsHandle.overrideAllPermissions(null); // no need to override anything here
    }
  }

  @computed(
    expression('thing.thingGroupId'),
    expression('personPermissionsHandle.canEditField.zip'),
    expression('personPermissionsHandle.canEditField.streetName'),
    expression('personPermissionsHandle.canEditField.municipality')
  )
  protected get showUseAddressOfThingGroup(): boolean {
    if (!this.thing?.thingGroupId) {
      return false;
    }

    return (
      this.personPermissionsHandle.canEditField.zip &&
      this.personPermissionsHandle.canEditField.streetName &&
      this.personPermissionsHandle.canEditField.municipality
    );
  }

  @computed(
    expression('thingToPersonInfo.thingToPerson'),
    expression('thingToPersonPermissionsHandle.canEditField.personId'),
    expression('thingToPersonPermissionsHandle.canDeleteEntity'),
    expression('thingPermissionsHandle.canCreateThingToPersons')
  )
  protected get canSelectPerson(): boolean {
    if (this.thingToPersonInfo?.thingToPerson) {
      return (
        this.thingToPersonPermissionsHandle.canEditField.personId &&
        this.thingToPersonPermissionsHandle.canDeleteEntity
      );
    }

    return this.thingPermissionsHandle.canCreateThingToPersons;
  }
}

export type ThingToPersonDeletedEvent = NamedCustomEvent<
  'thing-to-person-deleted',
  { thingToPerson: ThingToPerson }
>;
