import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { EntityNameToSearchResultMap } from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { DateUtils } from 'common/DateUtils';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';

@autoinject
export class ReportTypeSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.ReportType]
    | null = null;

  protected itemProperties: Array<ItemProperty> = [];

  constructor(private readonly router: Router) {}

  // Aurealia Change Handlers

  protected searchResultChanged(): void {
    this.updateItemProperties();
  }

  // Aurelia Handlers

  protected handleNavigateToReportTypeClick(): void {
    if (!this.searchResult) return;

    const route = this.router.generate('import_report_type', {
      report_type_id: this.searchResult.id
    });
    this.router.navigate(route);
  }

  // Private Methods

  private updateItemProperties(): void {
    if (!this.searchResult) {
      this.itemProperties = [];
    } else {
      this.itemProperties = [
        {
          labelTk: 'modelProperties.file_name',
          value: this.searchResult.fileName ?? ''
        },
        {
          labelTk: 'modelProperties.created',
          value: DateUtils.formatToDateWithHourMinuteString(
            this.searchResult.created
          )
        }
      ];
    }
  }
}
