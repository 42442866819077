import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from '../../../../common/src/DateUtils';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { EventDispatcher } from '../../classes/EventDispatcher/EventDispatcher';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { Utils } from '../../classes/Utils/Utils';
import { ProcessTaskAppointmentCalendarWidgetMode } from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { autoinject } from 'aurelia-framework';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';

@autoinject()
export class OperationsCalendarSettingsService {
  private static readonly STORAGE_KEY =
    'OperationsCalendarSettingsService::settings';

  private settings: OperationsCalendarSettings | null = null;
  private readonly saveSettingsRateLimited = Utils.rateLimitFunction(
    this.saveSettings.bind(this),
    250
  );
  private readonly eventDispatcher = new EventDispatcher<{
    settingsModified: OperationsCalendarSettings;
  }>();

  constructor(
    private readonly activeUserCompanySettingsService: ActiveUserCompanySettingService
  ) {}

  public async init(): Promise<void> {
    const settings: StoredCalendarSettings | undefined =
      await DataStorageHelper.getItem(
        OperationsCalendarSettingsService.STORAGE_KEY
      );
    this.settings = {
      ...this.getDefaultSettings(),
      ...settings,
      selectedDate: settings?.selectedDate
        ? new Date(settings.selectedDate)
        : new Date(),
      userIdSelectedMap: settings?.userIdSelectedMap
        ? Utils.recordToMap(settings.userIdSelectedMap)
        : new Map(),
      userColorGroupSelectedMap: settings?.userColorGroupSelectedMap
        ? Utils.recordToMap(settings.userColorGroupSelectedMap)
        : new Map()
    };
  }

  public async flush(): Promise<void> {
    if (this.saveSettingsRateLimited.isPending()) {
      this.saveSettingsRateLimited.cancel();
      await this.saveSettings();
    }
  }

  public destroy(): void {
    this.settings = null;
  }

  public modifySettings(
    modifications: Partial<OperationsCalendarSettings>
  ): void {
    this.settings = {
      ...this.getSettings(),
      ...modifications
    };

    this.saveSettingsRateLimited();

    this.eventDispatcher.dispatchEvent('settingsModified', this.settings);
  }

  public getSettings(): OperationsCalendarSettings {
    assertNotNullOrUndefined(
      this.settings,
      "can't retrieve settings because the OperationsCalendarSettingsService isn't initialized"
    );
    return this.settings;
  }

  public bindSettings(
    callback: (settings: OperationsCalendarSettings) => void
  ): Disposable {
    callback(this.getSettings());

    return this.eventDispatcher.addDisposableEventListener(
      'settingsModified',
      callback
    );
  }

  private getDefaultSettings(): OperationsCalendarSettings {
    const companySettingsDefaultWidgetMode =
      this.activeUserCompanySettingsService.getSettingProperty(
        'operations.defaultProcessTaskAppointmentCalendarWidgetMode'
      ) as ProcessTaskAppointmentCalendarWidgetMode;
    return {
      calendarMode:
        companySettingsDefaultWidgetMode ??
        ProcessTaskAppointmentCalendarWidgetMode.WEEK,
      selectedDate: new Date(),
      userIdSelectedMap: new Map(),
      userColorGroupSelectedMap: new Map()
    };
  }

  private saveSettings(): Promise<void> {
    const settings = this.getSettings();
    let selectedDate: string | null = null;

    if (
      settings.selectedDate &&
      DateUtils.isOnSameDay(settings.selectedDate, new Date())
    ) {
      selectedDate = null;
    } else {
      selectedDate = settings.selectedDate?.toISOString() ?? null;
    }

    return DataStorageHelper.setItem(
      OperationsCalendarSettingsService.STORAGE_KEY,
      {
        ...settings,
        selectedDate,
        userIdSelectedMap: Utils.mapToRecord(settings.userIdSelectedMap),
        userColorGroupSelectedMap: Utils.mapToRecord(
          settings.userColorGroupSelectedMap
        )
      } as StoredCalendarSettings
    );
  }
}

export type OperationsCalendarSettings = {
  calendarMode: ProcessTaskAppointmentCalendarWidgetMode;
  selectedDate: Date;
  userIdSelectedMap: Map<string, boolean>;
  userColorGroupSelectedMap: Map<string, boolean>;
};

type StoredCalendarSettings = Partial<
  Omit<
    OperationsCalendarSettings,
    'selectedDate' | 'userIdSelectedMap' | 'userColorGroupSelectedMap'
  > & {
    selectedDate: string | null;
    userIdSelectedMap: Partial<Record<string, boolean>>;
    userColorGroupSelectedMap: Partial<Record<string, boolean>>;
  }
>;
