import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class EditUserGroupSmsCredentialsWidget {
  @bindable()
  public userGroup: UserGroup | null = null;

  @subscribableLifecycle()
  protected permissionHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionHandle = permissionsService.getPermissionsHandleForProperty({
      context: this as EditUserGroupSmsCredentialsWidget,
      propertyName: 'userGroup',
      entityName: EntityName.UserGroup
    });
  }

  protected handleUserGroupChanged(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      "can't EditUserGroupSmsCredentialsWidget.handleUserGroupChanged without a userGroup"
    );

    this.entityManager.userGroupRepository.update(this.userGroup);
  }
}
