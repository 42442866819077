import { Container } from 'aurelia-framework';
import { EntityActualizationHooks } from '@record-it-npm/synchro-client';
import { EntityName } from '../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { CurrentUserService } from '../classes/EntityManager/entities/User/CurrentUserService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { JoinedProjectsManagerHooks } from '../classes/EntityManager/entities/Project/JoinedProjectsManager';
import {
  ActiveUserCompanySettingService,
  BindablePropertyPaths
} from '../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SocketService } from '../services/SocketService';
import { AppSynchronizationEnvironmentTypes } from '../classes/EntityManager/AppSynchronizationEnvironmentTypes';

export type Dependency = (args: {
  target: any;
  subscriptionManager: SubscriptionManager;
  onChange: () => void;
}) => void;

/**
 * Creates a dependency that triggers an update whenever `target` emits an DOM event with the given `eventName`.
 *
 * @example
 * // handleElementClicked() will be executed if the user clicks on `element`
 * export class SomeComponent {
 *   protected element: HTMLElement;
 *
 *   [...]
 *
 *   \@watch(domEvent<SomeComponent>((t) => t.element, 'click'))
 *   protected get handleElementClicked() {
 *     [...]
 *   }
 * }
 */
/* eslint-disable no-redeclare */
export function domEvent<T, K extends keyof DocumentEventMap>(
  target: (t: T) => Document,
  eventName: K
): Dependency;
export function domEvent<T, K extends keyof HTMLElementEventMap>(
  target: (t: T) => HTMLElement,
  eventName: K
): Dependency;
export function domEvent<T>(
  target: (t: T) => EventTarget,
  eventName: string
): Dependency;
export function domEvent<T>(
  targetFn: (t: T) => EventTarget,
  eventName: string
): Dependency {
  return ({ target, subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToDomEvent(
      targetFn(target),
      eventName,
      onChange
    );
  };
}
/* eslint-enable no-redeclare */

/**
 * Creates a dependency that triggers an update whenever an event with the given `eventName` occurs.
 *
 * @example
 * // currentRoute() will only recalculate when 'router:navigation:complete' is emitted
 * \@computed(event('router:navigation:complete'))
 * protected get currentRoute() {
 *   [...]
 * }
 */
export function event(eventName: string, rateInterval?: number): Dependency {
  return ({ subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToEvent(eventName, onChange, rateInterval);
  };
}

/**
 * Creates a dependency that triggers an update every `ms` milliseconds.
 *
 * @example
 * // updateStuff() will be executed every second
 * \@watch(interval(1000))
 * protected updateStuff() {
 *   [...]
 * }
 */
export function interval(ms: number): Dependency {
  return ({ subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToInterval(onChange, ms);
  };
}

/**
 * Creates a dependency that triggers an update whenever an entity of the given `entityName` type changes.
 *
 * @example
 * // someFunction() will only recalculate if a Defect entity updates
 * \@computed(model(EntityName.Defect))
 * protected get someFunction() {
 *   [...]
 * }
 */
export function model(
  entityName: EntityName,
  rateInterval?: number
): Dependency {
  return ({ subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToModelChanges(
      entityName,
      onChange,
      rateInterval
    );
  };
}

/**
 * Creates a dependency that triggers an update whenever a given expression changes.
 *
 * This works with properties (`'entityList'`) as well as with more complex expressions (`'entityList.length'`).
 *
 * @example
 * // someFunction() will only recalculate if `this.entityList` updates
 * \@computed(expression('entityList'))
 * protected get someFunction() {
 *   [...]
 * }
 */
export function expression(propertyName: string): Dependency {
  return ({ target, subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToExpression(target, propertyName, onChange);
  };
}

/**
 * Creates a dependency that triggers an update whenever a given array changes.
 *
 * This works with properties (`'entityArray'`) as well as with more complex expressions (`'entity.myArray'`).
 *
 * @example
 * // someFunction() will only recalculate if `this.myArray` updates
 * \@computed(arrayChanges('myArray'))
 * protected get someFunction() {
 *  [...]
 * }
 */
export function arrayChanges(arrayName: string): Dependency {
  return ({ subscriptionManager, target, onChange }) => {
    subscriptionManager.subscribeToArrayPropertyChanges(
      target,
      arrayName,
      onChange
    );
  };
}

/**
 * Creates a dependency that triggers once after `ms` milliseconds.
 *
 * @example
 * // logSomething() will be executed 10 seconds after the component attached
 * \@watch(timeout(10000))
 * protected logSomething() {
 *   [...]
 * }
 */
export function timeout(ms: number): Dependency {
  return ({ subscriptionManager, onChange }) => {
    subscriptionManager.subscribeToTimeout(onChange, ms);
  };
}

/**
 * Creates a dependency that triggers when the current user changes / updates.
 *
 * @example
 * // always returns the current user
 * \@computed(currentUser())
 * protected get currentUser(): User|null {
 *   return this.currentUserService.getCurrentUser();
 * }
 */
export function currentUser(): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const currentUserService = Container.instance.get(CurrentUserService);
    subscriptionManager.addDisposable(
      currentUserService.subscribeToCurrentUserChanged(onChange)
    );
  };
}

/**
 * Creates a dependency that triggers when an entity actualization hook is called
 *
 * @example
 * // logSomething() will be executed when 'afterActualization' hook is called
 * \@watch(entityActualizationHook('afterActualization'))
 * protected logSomething(): void {
 *   [...]
 * }
 *
 * @param {EntityActualizationHooks} hook
 * @returns {Dependency}
 */
export function entityActualizationHook(
  hook: keyof EntityActualizationHooks<AppSynchronizationEnvironmentTypes>
): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const entityManager = Container.instance.get(AppEntityManager);
    subscriptionManager.addDisposable(
      entityManager.entityActualization.registerHooks({
        [hook]: onChange
      })
    );
  };
}

/**
 * Creates a dependency that triggers when a joined projects manager hook is called
 *
 * @example
 * // logSomething() will be executed when 'onJoinedStatusChanged' hook is called
 * \@watch(joinedProjectsManagerHook('onJoinedStatusChanged'))
 * protected logSomething(): void {
 *   [...]
 * }
 *
 * @param {JoinedProjectsManagerHooks} hook
 * @returns {Dependency}
 */
export function joinedProjectsManagerHook(
  hook: keyof JoinedProjectsManagerHooks
): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const entityManager = Container.instance.get(AppEntityManager);
    subscriptionManager.addDisposable(
      entityManager.joinedProjectsManager.registerHooks({
        [hook]: onChange
      })
    );
  };
}

/**
 * Creates a dependency that triggers when a user company setting updates.
 *
 * @example
 * \@computed(activeUserCompanySetting('general.primaryColor'))
 * protected get primaryColor(): string|null {
 *   return this.activeUserCompanySettingService.getSettingProperty('general.primaryColor');
 * }
 */
export function activeUserCompanySetting(
  path: BindablePropertyPaths
): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const activeUserCompanySettingsService = Container.instance.get(
      ActiveUserCompanySettingService
    );
    subscriptionManager.addDisposable(
      activeUserCompanySettingsService.bindSettingProperty(path, onChange)
    );
  };
}

/**
 * Creates a dependency that triggers when socket authentication status changes.
 *
 * @example
 * \@computed(isAuthenticated())
 * protected get enableUploadButton(): boolean {
 *   return this.socketService.isAuthenticated();
 * }
 */
export function isAuthenticated(): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const socketService = Container.instance.get(SocketService);
    subscriptionManager.addDisposable(
      socketService.registerBinding('isAuthenticated', onChange)
    );
  };
}

/**
 * Creates a dependency that triggers when socket connection status changes.
 *
 * @example
 * \@computed(isConnected())
 * protected get enableUploadButton(): boolean {
 *   return this.socketService.isConnected();
 * }
 */
export function isConnected(): Dependency {
  return ({ subscriptionManager, onChange }) => {
    const socketService = Container.instance.get(SocketService);
    subscriptionManager.addDisposable(
      socketService.registerBinding('isConnected', onChange)
    );
  };
}
