import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { AdditionalMarkingsChangedEvent } from '../../../picture/additional-marked-pictures-list/additional-marked-pictures-list';
import { StructureThingPathSelectedEvent } from '../../structure-thing-path-selector/structure-thing-path-selector';
import { DisplayedEntrySelect } from '../EntryCreationHandle/DisplayedEntrySelect';
import { EntryCreationHandle } from '../EntryCreationHandle/EntryCreationHandle';

@autoinject()
export class StructureThingRapidFireWidgetMainContent {
  @bindable()
  public entryCreationHandle: EntryCreationHandle | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected handleRemoveSelectedEntryClick(
    displayedEntrySelect: DisplayedEntrySelect
  ): void {
    displayedEntrySelect.selectedEntry = null;
  }

  protected isTagActivated(
    tag: string,
    _currentImportnotesLength: number
  ): boolean {
    if (!this.entryCreationHandle?.entry.importnotes) return false;

    const index = this.entryCreationHandle.entry.importnotes.findIndex(
      (t) => t === tag
    );
    return index >= 0;
  }

  protected handleTagToggled(tag: string): void {
    assertNotNullOrUndefined(
      this.entryCreationHandle,
      "can' StructureThingRapidFireWidgetMainContent.handleTagToggled without entryCreationHandle"
    );

    if (!this.entryCreationHandle.entry.importnotes) {
      this.entryCreationHandle.entry.importnotes = [];
    }

    const index = this.entryCreationHandle.entry.importnotes.findIndex(
      (t) => t === tag
    );
    if (index >= 0) {
      this.entryCreationHandle.entry.importnotes.splice(index, 1);
    } else {
      this.entryCreationHandle.entry.importnotes.push(tag);
    }
  }

  protected handleStructurePathSelected(
    event: StructureThingPathSelectedEvent
  ): void {
    assertNotNullOrUndefined(
      this.entryCreationHandle,
      "can't StructureThingRapidFireWidget.handleStructurePathSelected without entryCreationHandle"
    );
    const structureTemplateEntries =
      event.detail.path?.structureTemplateEntries ?? [];

    const entryPath: Array<Entry> = [];
    for (const [
      index,
      structureTemplateEntry
    ] of structureTemplateEntries.entries()) {
      const entries =
        this.entityManager.entryRepository.getByStructureTemplateEntryAndProjectId(
          structureTemplateEntry,
          this.entryCreationHandle.project.id
        );

      const firstEntry = entries[0];
      assertNotNullOrUndefined(
        firstEntry,
        `no entry with structureTemplateEntryId/originId "${
          structureTemplateEntry.structureTemplateEntryGroupId ||
          structureTemplateEntry.originId
        }" found!`
      );

      if (entries.length === 1) {
        entryPath.push(firstEntry);
      } else {
        const parentStructureTemplateEntry =
          structureTemplateEntries[index - 1];
        assertNotNullOrUndefined(
          parentStructureTemplateEntry,
          'there is no next parent in structure template entry path'
        );

        const [parentEntry] =
          this.entityManager.entryRepository.getByStructureTemplateEntryAndProjectId(
            parentStructureTemplateEntry,
            this.entryCreationHandle.project.id
          );
        assertNotNullOrUndefined(
          parentEntry,
          'parent of entry is not available'
        );

        const entry = entries.find(
          (e) => e.page_depth_parent === parentEntry.id
        );
        assertNotNullOrUndefined(entry, 'cannot find entry');

        entryPath.push(entry);
      }
    }

    this.entryCreationHandle.setEntryPath(entryPath);
  }

  protected handlePictureAdditionalMarkingsChanged(
    event: AdditionalMarkingsChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.entryCreationHandle?.pictureHandle,
      "can't StructureThingRapidFireWidgetMainContent.handlePictureAdditionalMarkingsChanged without entryCreationHandle.pictureHandle"
    );
    this.entryCreationHandle.pictureHandle.setAdditionalMarkings(
      event.detail.additionalMarkings
    );
  }
}
