import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { PersonContactType } from '../../../../common/src/Types/Entities/PersonContact/PersonContactDto';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TTextChangedEvent } from '../../inputComponents/clickable-text-input/clickable-text-input';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class PersonSelectAndEditWidgetPhoneContact {
  @bindable()
  public person: Person | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public createPerson: (() => Person) | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;

  private mainContactHandle: MainContactHandle = this.createMainContactHandle({
    contact: null
  });

  private additionalContactHandles: Array<AdditionalContactHandle> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PersonContact,
      this.updateContacts.bind(this)
    );
    this.resetContacts();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected personChanged(): void {
    if (this.isAttached) {
      this.resetContacts();
    }
  }

  private resetContacts(): void {
    this.mainContactHandle = this.createMainContactHandle({ contact: null });
    this.additionalContactHandles = [];
    this.updateContacts();
  }

  private updateContacts(): void {
    if (this.person) {
      const contacts = this.entityManager.personContactRepository
        .getByPersonId(this.person.id)
        .filter((pc) => pc.contactType === PersonContactType.PHONE);
      const { additionalContactHandles, mainContactHandle } =
        this.getMainContactHandleAndAdditionalContactHandles({
          contacts,
          previousMainContactHandle: this.mainContactHandle,
          previousAdditionalContactHandles: this.additionalContactHandles
        });
      this.mainContactHandle = mainContactHandle;
      this.additionalContactHandles = additionalContactHandles;
    } else {
      this.mainContactHandle = this.createMainContactHandle({ contact: null });
      this.additionalContactHandles = [];
    }
  }

  private getMainContactHandleAndAdditionalContactHandles({
    contacts,
    previousMainContactHandle,
    previousAdditionalContactHandles
  }: {
    contacts: Array<PersonContact>;
    previousMainContactHandle: MainContactHandle;
    previousAdditionalContactHandles: Array<AdditionalContactHandle>;
  }): MainContactHandleAndAdditionalContactHandles {
    const additionalContactHandles =
      this.getAdditionalContactHandlesWhichAreIncludedInContacts({
        contacts,
        previousAdditionalContactHandles
      });
    const unusedContacts = this.getUnusedContacts({
      contacts,
      previousMainContactHandle,
      additionalContactHandles
    });

    let mainContactHandle =
      this.mainContactHandle.contact &&
      contacts.includes(this.mainContactHandle.contact)
        ? this.mainContactHandle
        : null;
    if (!mainContactHandle) {
      const unusedContact = unusedContacts.shift() ?? null;
      mainContactHandle = this.createMainContactHandle({
        contact: unusedContact
      });
    }

    return {
      mainContactHandle,
      additionalContactHandles: [
        ...additionalContactHandles,
        ...unusedContacts.map<AdditionalContactHandle>((contact) => ({
          contact,
          defaultHighlighted: false,
          manuallyCreated: false
        }))
      ]
    };
  }

  private getAdditionalContactHandlesWhichAreIncludedInContacts({
    contacts,
    previousAdditionalContactHandles
  }: {
    contacts: Array<PersonContact>;
    previousAdditionalContactHandles: Array<AdditionalContactHandle>;
  }): Array<AdditionalContactHandle> {
    return previousAdditionalContactHandles.filter((handle) => {
      // keep manually created ones without a contact
      if (handle.manuallyCreated && handle.contact == null) {
        return true;
      }

      // remove handles for contacts which don't exist anymore
      return handle.contact && contacts.includes(handle.contact);
    });
  }

  private getUnusedContacts({
    contacts,
    previousMainContactHandle,
    additionalContactHandles
  }: {
    contacts: Array<PersonContact>;
    previousMainContactHandle: MainContactHandle;
    additionalContactHandles: Array<AdditionalContactHandle>;
  }): Array<PersonContact> {
    return contacts.filter((contact) => {
      const isInAdditonalHandles = !!additionalContactHandles.find(
        (handle) => handle.contact === contact
      );
      const isMainContact = contact === previousMainContactHandle.contact;

      return !isInAdditonalHandles && !isMainContact;
    });
  }

  protected handleMainContactValueChanged(event: TTextChangedEvent): void {
    const previousContact = this.mainContactHandle.contact;

    this.mainContactHandle.contact = this.handleInputChange({
      contact: this.mainContactHandle.contact,
      defaultHighlighted: false,
      event
    });

    if (!this.mainContactHandle.contact && previousContact) {
      this.mainContactHandle.defaultHighlighted = previousContact.highlighted;
    }
  }

  protected handleAdditionalContactValueChanged(
    handle: AdditionalContactHandle,
    event: TTextChangedEvent
  ): void {
    const previousContact = handle.contact;

    handle.contact = this.handleInputChange({
      contact: handle.contact,
      defaultHighlighted: handle.defaultHighlighted,
      event
    });

    if (!handle.contact) {
      // mark the contact as manually created because there is no contact anymore but we still want to keep the input
      handle.manuallyCreated = true;

      if (previousContact) {
        handle.defaultHighlighted = previousContact.highlighted;
      }
    } else {
      // if we created the contact, we mark the handle as not manually created so when someone else deletes the contact, we also want that handle to be removed
      handle.manuallyCreated = false;
    }
  }

  private handleInputChange({
    contact,
    defaultHighlighted,
    event
  }: {
    contact: PersonContact | null;
    defaultHighlighted: boolean;
    event: TTextChangedEvent;
  }): PersonContact | null {
    const person = this.getOrCreatePerson();
    const value = event.detail.value as string | null;

    if (!value) {
      if (contact) {
        this.entityManager.personContactRepository.delete(contact);
      }

      return null;
    }

    if (contact) {
      contact.name = value;
      this.entityManager.personContactRepository.update(contact);
      return contact;
    }

    return this.entityManager.personContactRepository.create({
      name: value,
      contactType: PersonContactType.PHONE,
      highlighted: defaultHighlighted,
      personId: person.id,
      ownerUserGroupId: person.ownerUserGroupId,
      temporaryGroupName: person.temporaryGroupName,
      shadowEntity: person.shadowEntity
    });
  }

  protected async handleDeleteClick(
    handle: AdditionalContactHandle
  ): Promise<void> {
    const contact = handle.contact;

    if (contact) {
      await Dialogs.deleteEntityDialog(contact);
      this.entityManager.personContactRepository.delete(contact);
    }

    this.additionalContactHandles = this.additionalContactHandles.filter(
      (item) => item !== handle
    );
  }

  protected handleHighlightedClick(
    handle: AdditionalContactHandle | MainContactHandle
  ): void {
    if (handle.contact) {
      handle.contact.highlighted = !handle.contact.highlighted;
      handle.defaultHighlighted = handle.contact.highlighted;
      this.entityManager.personContactRepository.update(handle.contact);
    } else {
      handle.defaultHighlighted = !handle.defaultHighlighted;
    }
  }

  protected handleAddButtonClick(): void {
    this.additionalContactHandles.push({
      contact: null,
      defaultHighlighted: false,
      manuallyCreated: true
    });
  }

  private getOrCreatePerson(): Person {
    let person = this.person;

    if (!person && this.createPerson) {
      person = this.createPerson();
    }

    assertNotNullOrUndefined(person, "couldn't get a person");

    return person;
  }

  protected highlightedOrDefault({
    contactHighlighted,
    defaultHighlighted
  }: {
    contactHighlighted: boolean | undefined;
    defaultHighlighted: boolean;
  }): boolean {
    return contactHighlighted ?? defaultHighlighted;
  }

  private createMainContactHandle({
    contact
  }: {
    contact: PersonContact | null;
  }): MainContactHandle {
    return {
      contact,
      defaultHighlighted: contact?.highlighted ?? false
    };
  }
}

type MainContactHandle = {
  contact: PersonContact | null;
  defaultHighlighted: boolean;
};

type AdditionalContactHandle = {
  contact: PersonContact | null;
  defaultHighlighted: boolean;
  manuallyCreated: boolean;
};

type MainContactHandleAndAdditionalContactHandles = {
  mainContactHandle: MainContactHandle;
  additionalContactHandles: Array<AdditionalContactHandle>;
};
