import { autoinject, bindable } from 'aurelia-framework';

import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { EntityDashboardInfo } from '../../classes/EntityManager/entities/EntityDashboardInfo/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  EntityWidgetHandle,
  ProjectTypeInfo
} from '../EntityWidgetHandle/EntityWidgetHandle';

@autoinject()
export class EntityWidget {
  @bindable public entityWidgetHandle: EntityWidgetHandle | null = null;

  protected ProjectType = ProjectType;

  private subscriptionManager: SubscriptionManager;

  protected picture: Picture | null = null;

  protected title = '';

  protected subtitle = '';

  protected projectTypeInfos: Array<ProjectTypeInfo> = [];

  protected moreButtonChoices: Array<MoreButtonChoice> = [];

  protected onClickCallback: (() => void) | null = null;

  protected handleChoiceSelected: ((name: string) => void) | null = null;

  protected handlestickyButtonClicked: (() => void) | null = null;

  protected dashboardInfo: EntityDashboardInfo | null = null;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    const disposable = this.entityWidgetHandle?.subscribe({
      setTitle: (title) => {
        this.title = title;
      },
      setSubTitle: (subtitle) => {
        this.subtitle = subtitle;
      },
      setMoreButtonChoices: (choices) => {
        this.moreButtonChoices = choices;
      },
      setPicture: (picture) => {
        this.picture = picture;
      },
      setProjectTypeInfo: (infos) => {
        this.projectTypeInfos = infos;
      },
      setOnWidgetClicked: (callback) => {
        this.onClickCallback = callback;
      },
      setMoreButtonChoicesClickHandler: (callback) => {
        this.handleChoiceSelected = callback;
      },
      setOnStickyButtonClicked: (callback) => {
        this.handlestickyButtonClicked = callback;
      },
      setEntityDashboardInfo: (dashboardInfo) => {
        this.dashboardInfo = dashboardInfo;
      }
    });
    if (disposable) {
      this.subscriptionManager.addDisposable(disposable);
    }
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(expression('dashboardInfo'), model(EntityName.EntityDashboardInfo))
  protected get isSticky(): boolean {
    return this.dashboardInfo?.sticky ?? false;
  }
}
