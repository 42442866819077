import { UiUpdater } from '../../../classes/UiUpdater';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Tool } from './Tool';
import { SvgPointerEventNormalizer } from './SvgPointerEventNormalizer';

export class TextTool extends Tool {
  protected _name = 'Text';
  protected _icons = ['far fa-font'];
  protected _machineName = 'text';

  private currentText = '';
  private msvgText: Msvg.Text | null = null;
  private textPosition: Msvg.Point | null = null;
  private updateTextOverlayFunction: () => void =
    this.updateTextOverlayPosition.bind(this);
  private textInputOverlay: HTMLElement | null = null;
  private dragStartTextPosition: Msvg.Point | null = null;
  private dragStartClientPosition: Msvg.Point | null = null;

  protected _cancel(): void {
    if (this.msvgText && this._msvg) {
      this._msvg.remove(this.msvgText);
    }
    super._cancel();
  }

  protected _reset(): void {
    super._reset();
    this.textPosition = null;
    UiUpdater.unregisterUpdateFunction(this.updateTextOverlayFunction);
    this.dragStartClientPosition = null;
    this.removeTextInputOverlay();
    this.currentText = '';
    this.msvgText = null;
  }

  /**
   * the position is already normalized
   */
  protected _mouseClickedHandler(
    event: MouseEvent,
    position: Msvg.Point
  ): void {
    if (!this.textPosition) {
      this.msvgText = this.createMsvgText();
      this.textPosition = this.calculateTextPosition(position, this.msvgText);
      this.msvgText.setPosition(this.textPosition);

      this.createTextInputOverlay();
      UiUpdater.registerUpdateFunction(this.updateTextOverlayFunction);
    }
  }

  private createTextInputOverlay(): void {
    if (this.textInputOverlay) {
      return;
    }

    const { textInputOverlay, acceptButton, cancelButton, textArea } =
      this.createTextInputOverlayElement();

    acceptButton.addEventListener(
      'click',
      this.handleTextInputOverlayAcceptClick.bind(this)
    );

    cancelButton.addEventListener(
      'click',
      this.handleTextInputOverlayCancelClick.bind(this)
    );

    $(textInputOverlay).draggable({
      start: this.handleTextInputOverlayDragstart.bind(this),
      stop: this.handleTextInputOverlayDragstop.bind(this),
      drag: this.handleTextInputOverlayDrag.bind(this)
    });

    textArea.addEventListener(
      'input',
      this.handleTextInputOverlayInput.bind(this)
    );
    setTimeout(() => {
      textArea.focus();
    }, 20);

    document.body.appendChild(textInputOverlay);
    this.textInputOverlay = textInputOverlay;
  }

  private createTextInputOverlayElement(): {
    textInputOverlay: HTMLDivElement;
    acceptButton: HTMLAnchorElement;
    cancelButton: HTMLAnchorElement;
    textArea: HTMLTextAreaElement;
  } {
    const textInputOverlay = document.createElement('div');
    textInputOverlay.setAttribute(
      'data-test-selector',
      'TextTool--TextInputOverlay'
    );
    textInputOverlay.innerHTML = `
      <div class="input-group">
        <textarea type="text" class="form-control" placeholder="Text"></textarea>
        <a class="input-group-addon" data-action="drag" draggable="true">
          <i class="far fa-arrows-alt"></i>
        </a>
        <a class="input-group-addon" data-action="accept">
          <i class="far fa-check"></i>
        </a>
        <a class="input-group-addon" data-action="cancel">
          <i class="far fa-times"></i>
        </a>
      </div>
    `;
    textInputOverlay.className = 'picture-sketcher-text-tool--InputWrapper';

    const acceptButton = textInputOverlay.querySelector(
      '[data-action="accept"]'
    ) as HTMLAnchorElement | null;
    const cancelButton = textInputOverlay.querySelector(
      '[data-action="cancel"]'
    ) as HTMLAnchorElement | null;
    const textArea = textInputOverlay.querySelector(
      'textarea'
    ) as HTMLTextAreaElement | null;

    assertNotNullOrUndefined(acceptButton, 'no acceptButton found');
    assertNotNullOrUndefined(cancelButton, 'no cancelButton found');
    assertNotNullOrUndefined(textArea, 'no textArea found');

    return { textInputOverlay, acceptButton, cancelButton, textArea };
  }

  private removeTextInputOverlay(): void {
    if (this.textInputOverlay && this.textInputOverlay.parentNode) {
      this.textInputOverlay.parentNode.removeChild(this.textInputOverlay);
    }

    this.textInputOverlay = null;
  }

  private handleTextInputOverlayAcceptClick(): void {
    if (this.currentText && this.currentText.trim() !== '') {
      this._modified();
      this._reset();
    } else {
      this._cancel();
    }
  }

  private handleTextInputOverlayCancelClick(): void {
    this._cancel();
  }

  private handleTextInputOverlayInput(event: Event): void {
    assertNotNullOrUndefined(
      this.msvgText,
      "can't TextTool.handleTextInputOverlayInput without msvgText"
    );

    this.currentText = (event.target as HTMLInputElement).value;
    this.msvgText.setText(this.currentText);
    this.updateTextStyling();
  }

  private handleTextInputOverlayDragstart(event: JQueryEventObject): void {
    this.dragStartClientPosition = new Msvg.Point(event.clientX, event.clientY);
    this.dragStartTextPosition = this.textPosition;
  }

  private handleTextInputOverlayDragstop(event: JQueryEventObject): void {
    this.handleDragging(new Msvg.Point(event.clientX, event.clientY));
    this.dragStartClientPosition = null;
  }

  private handleTextInputOverlayDrag(event: JQueryEventObject): void {
    if (this.dragStartClientPosition) {
      this.handleDragging(new Msvg.Point(event.clientX, event.clientY));
    }
  }

  private updateTextOverlayPosition(): void {
    if (
      !this.msvgText ||
      !this.textPosition ||
      !this._msvg ||
      !this.textInputOverlay
    ) {
      return;
    }

    const offsetPosition = new Msvg.Point(
      0,
      Math.max(this.msvgText.getHeight(), this.msvgText.getLineHeight())
    );
    const yOffset =
      SvgPointerEventNormalizer.getOffsetCoordinatesForSvgPosition(
        offsetPosition,
        this._msvg
      ).y + 3;
    const realTextPosition =
      SvgPointerEventNormalizer.getOffsetCoordinatesForSvgPosition(
        this.textPosition,
        this._msvg
      );
    const rect = this._msvg.getElement().getBoundingClientRect();

    this.textInputOverlay.style.top =
      rect.top + realTextPosition.y + yOffset + 'px';
    this.textInputOverlay.style.left = rect.left + realTextPosition.x + 'px';
  }

  private createMsvgText(): Msvg.Text {
    assertNotNullOrUndefined(
      this._msvg,
      "can't TextTool.createMsvgText without msvg"
    );

    const msvgText = new Msvg.Text();
    this.updateTextStyling();
    this._msvg.append(msvgText);

    return msvgText;
  }

  protected _stylingChanged(): void {
    this.updateTextStyling();
  }

  private updateTextStyling(): void {
    if (this.msvgText) {
      this.msvgText.setFontSize(this._size * 5).setFill(this._color);
    }
  }

  private handleDragging(currentPointerClientPosition: Msvg.Point): void {
    if (
      !this.msvgText ||
      !this.dragStartClientPosition ||
      !this._msvg ||
      !this.dragStartTextPosition
    ) {
      return;
    }

    const diff = SvgPointerEventNormalizer.normalizeOffsetCoordinates(
      new Msvg.Point(
        currentPointerClientPosition.x - this.dragStartClientPosition.x,
        currentPointerClientPosition.y - this.dragStartClientPosition.y
      ),
      this._msvg
    );

    this.textPosition = new Msvg.Point(
      this.dragStartTextPosition.x + diff.x,
      this.dragStartTextPosition.y + diff.y
    );

    this.msvgText.setPosition(this.textPosition);
  }

  private calculateTextPosition(
    position: Msvg.Point,
    msvgText: Msvg.Text
  ): Msvg.Point {
    return new Msvg.Point(position.x, position.y - msvgText.getLineHeight());
  }
}
