import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SelectChangedEvent } from '../custom-select/custom-select';

/**
 * Allows the user to select an assignee from all defects of a given thing.
 *
 * @see DefectAssigneeSelect if you want to select an assignee for a given defect.
 * @event assignee-changed {AssigneeChangedEvent} triggered when the user selects another assignee.
 */
@autoinject()
export class DefectThingAssigneeSelect {
  @bindable public enabled = true;

  @bindable public label: string | null = null;

  @bindable public thingId: string | null = null;

  @bindable public selectedAssigneeId: string | null = null;

  protected assignees: Array<User> = [];

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  constructor(
    element: Element,
    private entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Defect,
      this.updateAssignees.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateAssignees.bind(this)
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingIdChanged(): void {
    this.updateAssignees();
  }

  private updateAssignees(): void {
    if (!this.thingId) {
      this.assignees = [];
      return;
    }
    const defects = this.entityManager.defectRepository.getByOwnerThingId(
      this.thingId
    );
    const assigneeIds = defects
      .map((d) => d.assigneeId)
      .filter((id): id is string => !!id);
    this.assignees = this.entityManager.userRepository.getByIds(assigneeIds);
  }

  protected handleSelectValueChanged(
    evt: SelectChangedEvent<string, User>
  ): void {
    DomEventHelper.fireEvent<AssigneeChangedEvent>(this.element, {
      name: 'assignee-changed',
      detail: { assigneeId: evt.detail.value }
    });
  }
}

type AssigneeChangedEvent = NamedCustomEvent<
  'assignee-changed',
  { assigneeId: string | null }
>;
