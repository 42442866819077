import { bindable } from 'aurelia-framework';
import { ArrayMapCache } from '../../classes/ArrayMapCache/ArrayMapCache';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetBinding } from '../base-property-widget/config/PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

export class BasePropertyHeadingWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyHeadingWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  private readonly contextualConfigurationsCache: ArrayMapCache<
    PropertyWidgetBinding<any>,
    ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<any>
  >;

  constructor() {
    this.contextualConfigurationsCache = new ArrayMapCache<
      PropertyWidgetBinding<any>,
      ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<any>
    >({
      createMappedItem: ({ item }) => {
        return {
          binding: item
        };
      }
    });
  }

  public focus(): void {
    throw new Error("BasePropertyHeadingWidget doesn't support focus");
  }

  protected getContextualConfigurations(
    bindings: Array<PropertyWidgetBinding<any>> | undefined
  ): Array<
    ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<any>
  > {
    return this.contextualConfigurationsCache.mapItems({
      items: bindings ?? []
    });
  }
}

export const basePropertyHeadingWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
