import { autoinject, bindable } from 'aurelia-framework';

import { LatLongArea } from 'common/Types/LatLongArea';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { SelectCoordinatesOnMapDialog } from '../../dialogs/select-coordinates-on-map-dialog/select-coordinates-on-map-dialog';
import { FloatingLabelInput } from '../floating-label-input/floating-label-input';

/**
 * @slot coordinate-selector - you can add icons for different methods to select lat/long here, also the icons have to have the 'lat-long-area--SelectCoordinatesIcon' css class
 *
 * @event lat-long-area-changed
 */
@autoinject()
export class LatLongAreaInput {
  @bindable public latitude: number | null = null;

  @bindable public longitude: number | null = null;

  /**
   * in meters
   */
  @bindable public distance: number | null = null;

  /**
   * read-only!
   * the area will be a "square" (not exactly, but approximately)
   *
   * is null when the input is not valid/complete
   */
  @bindable public latLongArea: LatLongArea | null = null;

  /**
   * read-only!
   * true when latLongArea can be calculated or when no input is present
   */
  @bindable public valid: boolean;

  private domElement: HTMLElement;
  private distanceInput: FloatingLabelInput | null = null;

  private latitudeInputStatus = 'default';
  private longitudeInputStatus = 'default';
  private distanceInputStatus = 'default';

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
    this.valid = true;
  }

  public focusDistanceInput(): void {
    this.distanceInput?.focus();
  }

  protected latitudeChanged(): void {
    this.recalculateLatLongArea();
  }

  protected longitudeChanged(): void {
    this.recalculateLatLongArea();
  }

  protected distanceChanged(): void {
    this.recalculateLatLongArea();
  }

  private recalculateLatLongArea(): void {
    if (
      this.validateInputs() &&
      this.latitude &&
      this.longitude &&
      this.distance
    ) {
      this.latLongArea = {
        minLatitude: CoordinateHelper.movePointAlongLatitude(
          this.latitude,
          this.distance * -1
        ),
        maxLatitude: CoordinateHelper.movePointAlongLatitude(
          this.latitude,
          this.distance
        ),
        minLongitude: CoordinateHelper.movePointAlongLongitude(
          this.longitude,
          this.latitude,
          this.distance * -1
        ),
        maxLongitude: CoordinateHelper.movePointAlongLongitude(
          this.longitude,
          this.latitude,
          this.distance
        )
      };
    } else {
      this.latLongArea = null;
    }

    this.fireLatLongAreaChangedEvent();
  }

  private validateInputs(): boolean {
    let latitudeStatus = 'default';
    let longitudeStatus = 'default';
    let distanceStatus = 'default';
    let valid = true;

    if (
      (this.latitude == null ||
        this.longitude == null ||
        this.distance == null) &&
      (this.latitude != null || this.longitude != null || this.distance != null)
    ) {
      if (this.latitude == null) {
        latitudeStatus = 'warning';
        valid = false;
      }

      if (this.longitude == null) {
        longitudeStatus = 'warning';
        valid = false;
      }

      if (this.distance == null) {
        distanceStatus = 'warning';
        valid = false;
      }
    }

    this.latitudeInputStatus = latitudeStatus;
    this.longitudeInputStatus = longitudeStatus;
    this.distanceInputStatus = distanceStatus;

    this.valid = valid;
    return valid;
  }

  private fireLatLongAreaChangedEvent(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<LatLongAreaChangedEvent>(this.domElement, {
        name: 'lat-long-area-changed',
        detail: { latLongArea: this.latLongArea }
      });
    });
  }

  protected handleSelectCoordinatesOnMapClick(): void {
    void SelectCoordinatesOnMapDialog.open({
      lat: this.latitude,
      long: this.longitude,
      onCoordinateSelected: (lat, long) => {
        this.latitude = lat;
        this.longitude = long;
        setTimeout(() => {
          this.distanceInput?.focus();
        }, 10);
      }
    });
  }
}

export type LatLongAreaChangedEvent = NamedCustomEvent<
  'lat-long-area-changed',
  { latLongArea: LatLongArea | null }
>;
