import { CustomFileEntry } from '../../entries/CustomFileEntry';
import { CapacitorDirectoryEntry } from './CapacitorDirectoryEntry';
import { CordovaFilePluginUtils } from './CordovaFilePluginUtils';

export class CapacitorFileEntry extends CustomFileEntry {
  constructor(private fileEntry: FileEntry) {
    super();
  }

  public get name(): string {
    return this.fileEntry.name;
  }

  public get path(): string {
    return this.fileEntry.fullPath;
  }

  public get nativeUrl(): string {
    return this.fileEntry.nativeURL;
  }

  public moveTo(
    directory: CapacitorDirectoryEntry,
    newName: string
  ): Promise<CapacitorFileEntry> {
    return new Promise((res, rej) => {
      this.fileEntry.moveTo(
        directory.getCordovaDirectoryEntry(),
        newName,
        (fileEntry) => {
          CordovaFilePluginUtils.assertIsFileEntry(fileEntry); // this function will only return FileEntries, but it has bad typings since the function also works for DirectoryEntries

          res(new CapacitorFileEntry(fileEntry));
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  public copyTo(
    directory: CapacitorDirectoryEntry,
    newName: string
  ): Promise<CustomFileEntry> {
    return new Promise((res, rej) => {
      this.fileEntry.copyTo(
        directory.getCordovaDirectoryEntry(),
        newName,
        (fileEntry) => {
          CordovaFilePluginUtils.assertIsFileEntry(fileEntry); // this function will only return FileEntries, but it has bad typings since the function also works for DirectoryEntries

          res(new CapacitorFileEntry(fileEntry));
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  public async writeBlob(blob: Blob): Promise<void> {
    const fileWriter = await this.createWriter();

    return new Promise((res, rej) => {
      let offset = 0;
      const blockSize = 10 * 1024 * 1024;

      const writeNext = (): void => {
        const currentBlockSize = Math.min(blockSize, blob.size - offset);
        const block = blob.slice(offset, offset + currentBlockSize);

        fileWriter.onwriteend = () => {
          if (offset < blob.size) {
            offset += currentBlockSize;
            writeNext();
          } else {
            res();
          }
        };

        fileWriter.write(block);
      };

      writeNext();

      fileWriter.onerror = (error) => {
        rej(error);
      };
    });
  }

  public readAsBlob(): Promise<Blob> {
    return new Promise((res, rej) => {
      this.fileEntry.file(
        (file) => {
          res(file);
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  public delete(): Promise<void> {
    return new Promise((res, rej) => {
      this.fileEntry.remove(
        () => {
          res();
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }

  private async createWriter(): Promise<FileWriter> {
    return new Promise((res, rej) => {
      this.fileEntry.createWriter(
        (writer) => {
          res(writer);
        },
        (fileError) => {
          rej(fileError);
        }
      );
    });
  }
}
