import { autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskOffer } from '../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { DateTimePickerMode } from '../../inputComponents/date-time-picker/date-time-picker';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessTaskOfferDialog {
  @subscribableLifecycle()
  private readonly permissionHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskOffer];

  private processTaskOffer: ProcessTaskOffer | null = null;
  private processTaskGroup: ProcessTaskGroup | null = null;
  private onDialogClosed: EditProcessTaskOfferDialogOpenOptionsOnDialogClosed | null =
    null;
  private dialogOpened: boolean = false;
  private closedByNavigation: boolean = false;

  private dialog: RecordItDialog | null = null;

  protected DateTimePickerMode = DateTimePickerMode;

  public static async open(
    options: EditProcessTaskOfferDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;

    this.permissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessTaskOffer,
        context: this,
        expression: 'processTaskOffer'
      });
  }

  public open(options: EditProcessTaskOfferDialogOpenOptions): void {
    if (!this.dialog) {
      throw new Error("dialog isn't bound");
    }

    this.processTaskOffer = options.processTaskOffer;
    this.processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        this.processTaskOffer.ownerProcessTaskGroupId
      );
    this.onDialogClosed = options.onDialogClosed;

    this.dialog.open();
  }

  private handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const closedByNavigation = this.closedByNavigation;

    this.processTaskOffer = null;
    this.onDialogClosed = null;
    this.closedByNavigation = false;

    onClosed && onClosed(closedByNavigation);
  }

  private handleOfferChanged(): void {
    if (this.processTaskOffer) {
      this.entityManager.processTaskOfferRepository.update(
        this.processTaskOffer
      );
    }
  }

  private handleNavigationTriggered(): void {
    if (this.dialog) {
      this.closedByNavigation = true;
      this.dialog.close();
    }
  }
}

type EditProcessTaskOfferDialogOpenOptions = {
  processTaskOffer: ProcessTaskOffer;
  onDialogClosed: EditProcessTaskOfferDialogOpenOptionsOnDialogClosed | null;
};

type EditProcessTaskOfferDialogOpenOptionsOnDialogClosed = (
  closedByNavigation: boolean
) => void;
