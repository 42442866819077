import moment from 'moment';

export class PictureFileNameUtils {
  private constructor() {}

  public static getPictureFileName({
    id,
    file_created,
    file_extension
  }: {
    id: string;
    file_created: number;
    file_extension: string | null;
  }): string {
    let fileName =
      moment(file_created).format('YYYY.MM.DD-HH-mm-ss') + '_' + id;

    if (file_extension) {
      fileName += '.' + file_extension;
    } else {
      fileName += '.jpg'; // older pictures don't have an extension
    }
    return fileName;
  }
}
