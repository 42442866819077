import { autoinject } from 'aurelia-framework';
import {
  ICopyProjectResponse,
  TCopyProjectRequest
} from 'common/EndpointTypes/CopyProjectEndpointsHandler';
import {
  SocketService,
  TSocketServiceCallbackFunction
} from '../../../../services/SocketService';

@autoinject()
export class CopyProjectService {
  constructor(private readonly socketService: SocketService) {}

  public copyProjectById(
    options: TCopyProjectRequest,
    callback?: TSocketServiceCallbackFunction<
      ICopyProjectResponse,
      TCopyProjectRequest
    >
  ): void {
    this.socketService.copyProject(options, callback);
  }
}
