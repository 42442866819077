import {
  AppointmentCalendarEntry,
  NormalAppointmentCalendarEntry,
  RecurringAppointmentCalendarEntry
} from 'common/EndpointTypes/OperationsEndpointsTypes';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import {
  CalendarEntry,
  CalendarEntryType,
  DaySpecificCalendarEntry,
  NormalCalendarEntry,
  RecurringCalendarEntry
} from '../CalendarEntry';
import {
  CalendarEntryDataSourceStrategy,
  GetCalendarEntriesOptions
} from './CalendarEntryDataSourceStrategy';
import { CalendarEntryPerDaySplitter } from '../../CalendarEntryPerDaySplitter/CalendarEntryPerDaySplitter';

export class CalendarEntriesFromServerStrategy extends CalendarEntryDataSourceStrategy {
  private calendarEntryInfos: Array<CalendarEntryInfo> = [];

  constructor(options: { entityManager: AppEntityManager }) {
    super(options);
  }

  public subscribe(): Disposable {
    return {
      dispose: () => {}
    };
  }

  public async getCalendarEntries({
    filter,
    useUserColors
  }: GetCalendarEntriesOptions): Promise<Array<DaySpecificCalendarEntry>> {
    const relevantInfos = this.calendarEntryInfos.filter((info) => {
      return filter.userIds.includes(info.userId);
    });

    const fromTimestamp = filter.date?.dateFrom.getTime();
    const toTimestamp = filter.date?.dateTo.getTime();

    const calendarEntries = this.createCalendarEntriesFromInfos({
      infos: relevantInfos,
      useUserColors
    });

    return CalendarEntryPerDaySplitter.convertToDaySpecificCalendarEntries(
      calendarEntries
    ).filter((e) => {
      if (fromTimestamp == null || toTimestamp == null) {
        return true;
      }

      return (
        e.calendarEntry.startTimestamp >= fromTimestamp &&
        e.calendarEntry.endTimestamp <= toTimestamp
      );
    });
  }

  public setBaseData(
    serverCalendarEntries: Array<AppointmentCalendarEntry>
  ): void {
    const infos: Array<CalendarEntryInfo> = [];

    for (const serverCalendarEntry of serverCalendarEntries) {
      for (const calendarEntryUser of serverCalendarEntry.users) {
        const info = this.createInfoForServerCalendarEntry(
          serverCalendarEntry,
          calendarEntryUser
        );
        if (info) {
          infos.push(info);
        }
      }
    }

    this.calendarEntryInfos = infos;
    this.dataChanged();
  }

  private createInfoForServerCalendarEntry(
    serverCalendarEntry: AppointmentCalendarEntry,
    calendarEntryUser: AppointmentCalendarEntry['users'][number]
  ): CalendarEntryInfo | null {
    if (!calendarEntryUser.dateFrom || !calendarEntryUser.dateTo) {
      return null;
    }

    const dateFrom = new Date(calendarEntryUser.dateFrom);
    const dateTo = new Date(calendarEntryUser.dateTo);
    const duration = (dateTo.getTime() - dateFrom.getTime()) / 1000 / 60;

    let calendarEntryWithoutColors: CalendarEntryInfo['calendarEntryWithoutColors'];
    if ('recurringAppointment' in serverCalendarEntry) {
      calendarEntryWithoutColors = this.getRecurringCalendarEntry(
        serverCalendarEntry,
        dateFrom,
        dateTo,
        duration
      );
    } else {
      calendarEntryWithoutColors = this.getNormalCalendarEntryWithoutColors(
        serverCalendarEntry,
        dateFrom,
        dateTo,
        duration
      );
    }

    return {
      calendarEntryWithoutColors,
      userId: calendarEntryUser.id
    };
  }

  private getNormalCalendarEntryWithoutColors(
    serverCalendarEntry: NormalAppointmentCalendarEntry,
    dateFrom: Date,
    dateTo: Date,
    duration: number
  ): OmitColor<NormalCalendarEntry> {
    return {
      type: CalendarEntryType.NORMAL,
      id: serverCalendarEntry.appointment.id,
      name: serverCalendarEntry.appointment.name,
      processConfigurationStepId:
        serverCalendarEntry.appointment.processConfigurationStepId,
      processTaskId: serverCalendarEntry.processTask.id,
      startHour: dateFrom.getHours(),
      startMinute: dateFrom.getMinutes(),
      startTimestamp: dateFrom.getTime(),
      endTimestamp: dateTo.getTime(),
      duration: duration,
      address: serverCalendarEntry.thingGroup.address,
      done: serverCalendarEntry.appointment.finishedAt != null,
      processTaskAppointmentNote: serverCalendarEntry.appointment.note,
      processTaskNote: serverCalendarEntry.processTask.note,
      dayWidgetTexts: serverCalendarEntry.dayWidgetTexts
    };
  }

  private getRecurringCalendarEntry(
    serverCalendarEntry: RecurringAppointmentCalendarEntry,
    dateFrom: Date,
    dateTo: Date,
    duration: number
  ): OmitColor<RecurringCalendarEntry> {
    return {
      type: CalendarEntryType.RECURRING,
      id: serverCalendarEntry.recurringAppointment.id,
      name: serverCalendarEntry.recurringAppointment.name,
      processConfigurationStepId:
        serverCalendarEntry.recurringAppointment.processConfigurationStepId,
      processTaskId: serverCalendarEntry.processTask.id,
      processTaskGroupId: serverCalendarEntry.processTaskGroup.id,
      userGroupId: serverCalendarEntry.recurringAppointment.userGroupId,
      startHour: dateFrom.getHours(),
      startMinute: dateFrom.getMinutes(),
      startTimestamp: dateFrom.getTime(),
      endTimestamp: dateTo.getTime(),
      duration: duration,
      dayWidgetTexts: serverCalendarEntry.dayWidgetTexts
    };
  }

  private createCalendarEntriesFromInfos({
    infos,
    useUserColors
  }: {
    infos: Array<CalendarEntryInfo>;
    useUserColors: boolean;
  }): Array<CalendarEntry> {
    return infos.map((info) => {
      switch (info.calendarEntryWithoutColors.type) {
        case CalendarEntryType.NORMAL:
          return {
            ...info.calendarEntryWithoutColors,
            ...this.getAppointmentColors({
              appointmentId: info.calendarEntryWithoutColors.id,
              processConfigurationStepId:
                info.calendarEntryWithoutColors.processConfigurationStepId,
              userId: info.userId,
              useUserColors: useUserColors
            })
          } as NormalCalendarEntry;
        case CalendarEntryType.RECURRING:
          return {
            ...info.calendarEntryWithoutColors,
            ...this.getRecurringAppointmentColors({
              recurringAppointmentId: info.calendarEntryWithoutColors.id,
              userId: info.userId,
              useUserColors: useUserColors
            })
          } as RecurringCalendarEntry;
        default:
          throw new Error(
            `calendarEntry type ${info.calendarEntryWithoutColors.type} not supported.`
          );
      }
    });
  }
}

type OmitColor<T> = Omit<T, 'color' | 'secondaryColor'>;

type CalendarEntryInfo = {
  calendarEntryWithoutColors: OmitColor<CalendarEntry>;
  userId: string;
};
