import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { QuestionSet, QuestionSetCreationEntity } from './types';

@autoinject()
export class QuestionSetService {
  constructor(private readonly entityManager: AppEntityManager) {}

  /**
   * Create a question set and immediately associate it with a question catalogue.
   */
  public create(
    entity: QuestionSetCreationEntity,
    questionCatalogueId: string | null
  ): QuestionSet {
    const questionSet = this.entityManager.questionSetRepository.create(entity);
    if (questionCatalogueId) {
      this.entityManager.questionCatalogueToQuestionSetRepository.getOrCreate({
        questionSetId: questionSet.id,
        questionCatalogueId: questionCatalogueId,
        ownerUserGroupId: entity.ownerUserGroupId
      });
    }
    return questionSet;
  }

  /**
   * Retrieve all question sets for a given question catalogue id.
   */
  public getByQuestionCatalogueId(
    questionCatalogueId: string
  ): Array<QuestionSet> {
    const entities =
      this.entityManager.questionCatalogueToQuestionSetRepository.getByQuestionCatalogueId(
        questionCatalogueId
      );
    return this.entityManager.questionSetRepository.getByIds(
      entities.map((e) => e.questionSetId)
    );
  }
}
