import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { computed } from '../../hooks/computed';
import { activeUserCompanySetting } from '../../hooks/dependencies';
import { PersonCategories } from 'common/Types/PersonCategories';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditCatastropheContactsDialog {
  protected dialog: RecordItDialog | null = null;

  public thing: Thing | null = null;
  protected selectedPersonCategory: string | null = null;

  public static async open(
    options: EditCatastropheContactsDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {}

  public open(options: EditCatastropheContactsDialogOptions): void {
    this.thing = options.thing;

    this.dialog?.open();
  }

  protected handleCatastropheUpdated(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleCatastropheUpdated without thing'
    );

    const persons = this.selectedPersonCategory
      ? this.entityManager.personRepository.getByCategoryName(
          this.selectedPersonCategory
        )
      : [];
    this.entityManager.thingToPersonRepository.setPersonIdsForThingId({
      thingId: this.thing.id,
      personIds: persons.map((p) => p.id),
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
  }

  protected handleDialogClosed(): void {
    this.thing = null;
    this.selectedPersonCategory = null;
  }

  @computed(activeUserCompanySetting('general.personCategories'))
  protected get personCategories(): PersonCategories {
    return (
      this.activeUserCompanySettingService.getJSONSettingProperty(
        'general.personCategories'
      ) ?? []
    );
  }
}

type EditCatastropheContactsDialogOptions = {
  thing: Thing;
};
