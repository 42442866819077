import { autoinject, bindable } from 'aurelia-framework';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MomentInput } from 'moment/moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../classes/Dialogs';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { EditQuestionSetDialog } from '../../dialogs/edit-question-set-dialog/edit-question-set-dialog';
import { Router } from 'aurelia-router';

@autoinject()
export class ChecklistQuestionSetListItem {
  @bindable public questionSet: QuestionSet | null = null;

  protected panelOpen = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly router: Router
  ) {}

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEnterQuestionSetClick(): void {
    assertNotNullOrUndefined(
      this.questionSet,
      'cannot handleEnterQuestionSetClick without questionSet'
    );

    this.router.navigateToRoute('edit_checklist_questions', {
      question_set_id: this.questionSet.id
    });
  }

  protected handleEditButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionSet,
      'cannot handleEditButtonClick without questionSet'
    );

    void EditQuestionSetDialog.open({
      questionSet: this.questionSet
    });
  }

  protected handleDeleteButtonClick(): void {
    assertNotNullOrUndefined(
      this.questionSet,
      'cannot handleDeleteButtonClick without questionSet'
    );

    const questionSet = this.questionSet;
    void Dialogs.deleteEntityDialog(questionSet).then(() => {
      this.entityManager.questionSetRepository.delete(questionSet);
    });
  }
}
