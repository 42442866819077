/**
 * This helper class can be used to containerize values in case your API should explicitly differentiate whether a value does not exist at all
 * or a value does exist but is explicitly set to null/undefined.
 *
 * Example usage:
 * public getValue<T>(container: Array<any>, needle: T): ValueContainer<null|T>|null {
 *   const val = container.find(x => x === needle);
 *   if(val === undefined) return null;
 *   return new ValueContainer(val);
 * }
 */

export class ValueContainer<T> {
  constructor(private readonly internalValue: T) {}

  public get value(): T {
    return this.internalValue;
  }
}
