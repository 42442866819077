import { autoinject, bindable } from 'aurelia-framework';

import { CirclePreloader } from '../../aureliaComponents/circle-preloader/circle-preloader';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ImageLoader, LoadedDataInfo } from '../../classes/Picture/ImageLoader';
import { FetchService } from '../../services/FetchService/FetchService';

@autoinject()
export class CustomImg {
  @bindable public sources: Array<string> = [];

  @bindable public editable: boolean = false;

  /** the image elements are dragable by default, but in some situations you don't want this, e.g. in a zoom-box element */
  @bindable public disableDragging: boolean = false;

  private domElement: HTMLElement;
  protected circlePreloader: CirclePreloader | null = null;

  protected imageSources: Array<LoadedDataInfo> = [];

  private subscriptionManager: SubscriptionManager;
  private imageLoader: ImageLoader;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    socketService: SocketService,
    fetchService: FetchService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.imageLoader = new ImageLoader(
      subscriptionManagerService,
      socketService,
      fetchService
    );
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'sources',
      () => {
        if (this.imageLoader.setSources(this.sources)) {
          this.imageSources = [];
        }
      }
    );

    this.subscriptionManager.addDisposable(
      this.imageLoader.onLoadNextImage((index) => {
        if (index === 0) this.circlePreloader?.start();
      }),
      this.imageLoader.onImageLoaded((data) => {
        if (data.index === 0) this.circlePreloader?.stop();
        this.imageSources.push(data);
      }),
      this.imageLoader.onLoadingError((error) => {
        this.fireLoadingErrorEvent(error);
      }),
      this.imageLoader.onLoadingStopped(() => {
        this.circlePreloader?.stop();
      })
    );

    this.imageLoader.addSubscriptions();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.imageLoader.destroy();
  }

  private getFirstImageElement(): HTMLImageElement | SVGSVGElement | null {
    const imageElements =
      this.domElement.getElementsByClassName('custom-img--Image');
    return (imageElements[0] as HTMLImageElement) ?? null;
  }

  public getNaturalDimensions(): { height: number; width: number } {
    const element = this.getFirstImageElement();
    if (!element) {
      return {
        height: 0,
        width: 0
      };
    }

    if (element instanceof HTMLImageElement) {
      return {
        height: element.naturalHeight,
        width: element.naturalWidth
      };
    } else if (element instanceof SVGSVGElement) {
      return {
        height: element.viewBox.baseVal.height,
        width: element.viewBox.baseVal.width
      };
    } else {
      throw new Error('cannot get dimensions of other than img/svg element');
    }
  }

  protected getDragstartFunction(disableDragging: boolean): Function | null {
    return disableDragging
      ? (event: MouseEvent) => {
          event.preventDefault();
          return false;
        }
      : null;
  }

  protected firePictureLoadedEvent(index: number): void {
    this.fireEvent('picture-loaded', { index });
  }

  private fireLoadingErrorEvent(error: Error): void {
    this.fireEvent('loading-error', { error });
  }

  private fireEvent<T extends PictureLoadedEvent | LoadingErrorEvent>(
    eventName: T['type'],
    detail: T['detail']
  ): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<T>(this.domElement, {
        name: eventName,
        detail: detail
      });
    });
  }
}

export type PictureLoadedEvent = NamedCustomEvent<
  'picture-loaded',
  {
    index: number;
  }
>;

export type LoadingErrorEvent = NamedCustomEvent<
  'loading-error',
  {
    error: Error;
  }
>;
