/**
 * Months of the year in an enum for easier readability.
 */
export enum Month {
  JAN = 'january',
  FEB = 'february',
  MAR = 'march',
  APR = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUG = 'august',
  SEPT = 'september',
  OCT = 'october',
  NOV = 'november',
  DEC = 'december'
}

/**
 * returns the ordinal number of the given weekday.
 */
export function toNumber(month: Month): number {
  switch (month) {
    case Month.JAN:
      return 0;
    case Month.FEB:
      return 1;
    case Month.MAR:
      return 2;
    case Month.APR:
      return 3;
    case Month.MAY:
      return 4;
    case Month.JUNE:
      return 5;
    case Month.JULY:
      return 6;
    case Month.AUG:
      return 7;
    case Month.SEPT:
      return 8;
    case Month.OCT:
      return 9;
    case Month.NOV:
      return 10;
    case Month.DEC:
      return 11;
    default:
      throw new Error('Month not supported.');
  }
}

export const allMonths = (): Array<Month> => Object.values(Month);
