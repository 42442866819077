import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskAppointmentEntityInfo } from 'common/Types/Entities/ProcessTaskAppointment/createProcessTaskAppointmentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskAppointment } from './types';

export const processTaskAppointmentEntityInfo =
  createProcessTaskAppointmentEntityInfo<ProcessTaskAppointment>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
