import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createPropertyToProcessTaskPositionEntityInfo } from 'common/Types/Entities/PropertyToProcessTaskPosition/createPropertyToProcessTaskPositionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { PropertyToProcessTaskPosition } from './types';

export const propertyToProcessTaskPositionEntityInfo =
  createPropertyToProcessTaskPositionEntityInfo<PropertyToProcessTaskPosition>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
