import { i18n } from 'i18next';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { createIfFormatter } from './ifFormatter/ifFormatter';

export function setupCustomFormatters(i18next: i18n): void {
  const formatter = i18next.services.formatter;
  assertNotNullOrUndefined(
    formatter,
    "can 't setupCustomFormatters without formatter. Is the i18next instance not initialized yet?"
  );

  formatter.add('if', createIfFormatter(i18next));
}
