import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';

@autoinject()
export class ProcessTaskAppointmentTodoList {
  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  private subscriptionManager: SubscriptionManager;

  private positions: Array<ProcessTaskPosition> = [];
  private devices: Array<ProcessTaskDevice> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition,
      () => {
        this.updatePositions();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPosition,
      () => {
        this.updatePositions();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice,
      () => {
        this.updateDevices();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskDevice,
      () => {
        this.updateDevices();
      }
    );

    this.updatePositions();
    this.updateDevices();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected appointmentChanged(): void {
    if (this.isAttached) {
      this.updatePositions();
      this.updateDevices();
    }
  }

  private updatePositions(): void {
    if (this.appointment) {
      const processTaskAppointmentToProcessTaskPositions =
        this.entityManager.processTaskAppointmentToProcessTaskPositionRepository.getByProcessTaskAppointmentId(
          this.appointment.id
        );

      const positions = [];
      for (const processTaskAppointmentToProcessTaskPosition of processTaskAppointmentToProcessTaskPositions) {
        const processTaskPosition =
          this.entityManager.processTaskPositionRepository.getById(
            processTaskAppointmentToProcessTaskPosition.processTaskPositionId
          );
        if (!processTaskPosition) continue;
        positions.push(processTaskPosition);
      }

      this.positions = positions;
    } else {
      this.positions = [];
    }
  }

  private updateDevices(): void {
    if (this.appointment) {
      const relations =
        this.entityManager.processTaskAppointmentToProcessTaskDeviceRepository.getByProcessTaskAppointmentId(
          this.appointment.id
        );

      const devices = [];
      for (const relation of relations) {
        const device = this.entityManager.processTaskDeviceRepository.getById(
          relation.processTaskDeviceId
        );
        if (!device) continue;
        devices.push(device);
      }

      this.devices = devices;
    } else {
      this.devices = [];
    }
  }
}
