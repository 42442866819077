import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationStepPositionEntityInfo } from 'common/Types/Entities/ProcessConfigurationStepPosition/createProcessConfigurationStepPositionEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationStepPosition } from './types';

export const processConfigurationStepPositionEntityInfo =
  createProcessConfigurationStepPositionEntityInfo<ProcessConfigurationStepPosition>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
