import { PropertyHelper } from '../EntityHelper/PropertyHelper';
import { PropertyType } from '../Types/Entities/Property/PropertyDto';

export class PropertyStringParser {
  /**
   * property types which have picture tags {{%propertyName:propertyType}}
   */
  private static picturePropertyTypes: Array<PropertyType> = [
    PropertyType.SIGNATURE
  ];

  public static parse(propertyString: string): ParsedProperty | null {
    const propertyStringWithoutThingPrefix =
      this.removeThingPrefixFromPropertyName(propertyString);

    const extractResult = this.extractOptionStringFromPropertyString(
      propertyStringWithoutThingPrefix
    );
    const options = this.parseOptionsString(extractResult.optionsString);

    const parseResult = this.parsePropertyStringWithoutOptions(
      extractResult.propertyStringWithoutOptions
    );

    if (parseResult) {
      return {
        name: parseResult.name,
        type: parseResult.type,
        choices: parseResult.choices,
        value: '',
        options: options
      };
    }

    return null;
  }

  /**
   * The search for the optionName is case insensitive
   */
  public static getOptionValueByName(
    options: Array<PropertyOption> | undefined,
    optionName: string
  ): string | null {
    const lowercaseOptionName = optionName.toLocaleLowerCase();
    const option = options?.find(
      (o) => o.name.toLocaleLowerCase() === lowercaseOptionName
    );
    return option?.value ?? null;
  }

  public static getNumberOptionValueByName(
    options: Array<PropertyOption> | undefined,
    optionName: string
  ): number | null {
    const value = PropertyStringParser.getOptionValueByName(
      options,
      optionName
    );
    if (value === null) return null;
    return Number(value);
  }

  public static getBooleanOptionValueByName(
    options: Array<PropertyOption> | undefined,
    optionName: string
  ): boolean | null {
    const value = PropertyStringParser.getOptionValueByName(
      options,
      optionName
    );
    if (value === null) return null;
    return value === 'true';
  }

  private static removeThingPrefixFromPropertyName(name: string): string {
    return name.replace('__THING:', '');
  }

  private static extractOptionStringFromPropertyString(
    propertyString: string
  ): {
    propertyStringWithoutOptions: string;
    optionsString: string;
  } {
    const choices = propertyString.match(/\[.*\]/);
    const choiceString = choices?.[0] ?? '';
    const propertyStringWithoutChoices = propertyString.replace(
      choiceString,
      ''
    );

    const result = propertyStringWithoutChoices.match(/<%(.*)%>/);
    const result0 = result?.[0];
    const result1 = result?.[1];
    const resultIndex = result?.index;

    if (result0 != null && result1 != null && resultIndex != null) {
      return {
        propertyStringWithoutOptions:
          propertyStringWithoutChoices.substring(0, resultIndex) +
          propertyStringWithoutChoices.substring(resultIndex + result0.length) +
          choiceString,
        optionsString: result1
      };
    } else {
      return {
        propertyStringWithoutOptions: propertyString,
        optionsString: ''
      };
    }
  }

  private static parseOptionsString(
    optionsString: string
  ): Array<PropertyOption> {
    const options: Array<PropertyOption> = [];

    const optionStrings = optionsString.split(';');
    optionStrings.forEach((optionString) => {
      const optionParts = optionString.split('=');
      const optionPart0 = optionParts[0];

      if (optionPart0) {
        options.push({
          name: optionPart0.trim(),
          value: optionParts[1]?.trim() ?? null
        });
      }
    });

    return options;
  }

  private static parsePropertyStringWithoutOptions(
    propertyStringWithoutOptions: string
  ): Omit<ParsedProperty, 'options' | 'value'> | null {
    const parts = this.splitWithLimit(
      propertyStringWithoutOptions,
      /:(.+)?/,
      2
    );

    let name = parts[0] ?? '';
    const typeWithChoices = parts[1] || '';

    if (['structure', 'flatStructure', 'treeStructure'].includes(name)) {
      return null;
    }

    const result = typeWithChoices.match(/\[(.+)\]/);
    const parsedType =
      result && result.index != null
        ? typeWithChoices.substring(0, result.index)
        : typeWithChoices;

    const isPropertyType = PropertyHelper.isPropertyType(parsedType);

    let type = isPropertyType ? parsedType : PropertyType.TEXT;

    if (name[0] === '%') {
      name = name.slice(1);
      if (this.picturePropertyTypes.indexOf(type) === -1) {
        type = PropertyType.PICTURE;
      }
    } else if (/\d+(x\d+)?/.test(parsedType)) {
      type = PropertyType.PICTURE;
    } else if (!isPropertyType && parsedType !== '') {
      return null;
    }

    const choicesString = result?.[1] ?? '';
    const choices = this.parseChoicesString(choicesString);

    return {
      name,
      type,
      choices: choices
    };
  }

  private static parseChoicesString(choicesString: string): Array<string> {
    if (!choicesString) {
      // empty strings are not handled correctly by the regex
      return [];
    }

    return choicesString.split(
      /,(?=(?:[^\[\]]*[\[\]][^\[\]]*[\[\]])*[^\[\]]*$)/
    );
  }

  private static splitWithLimit(
    string: string,
    splitRegex: RegExp,
    limit: number
  ): Array<string> {
    const parts = string.split(splitRegex, limit);

    // propertyStringSplit[1] can be undefined if the propertyString = 'abc:' (only happens in FF)
    // all other browsers output an empty string instead of undefined
    // so we "polyfill" it
    if (parts.length === 2 && parts[1] == null) {
      parts.pop();
      parts.push('');
    }

    return parts;
  }
}

export type ParsedProperty = {
  name: string;
  type: PropertyType;
  value: string;
  choices: Array<string>;
  options?: Array<PropertyOption>;
};

export type PropertyOption = {
  name: string;
  value: string | null;
};

export enum ParserOptionTypes {
  SIZE = 'size',
  WIDTH = 'width',
  HEIGHT = 'height'
}
