import { inject, bindable } from 'aurelia-framework';

import { ProcessTaskAppointmentSmsNotification } from 'common/Enums/ProcessTaskAppointmentSmsNotification';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager, SubscriptionManagerService)
export class ProcessTaskAppointmentSmsEnabledText {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskAppointment/types').ProcessTaskAppointment|null} */
  @bindable processTaskAppointment = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _appointmentSubscriptionManager;

  /** @type {boolean} */
  _attached = false;
  _messageTextTk =
    'operations.processTaskAppointmentSmsEnabledText.noAppointment';
  /** @type {'error'|'neutral'|'ok'} */
  _type = 'error';

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._appointmentSubscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;
    this._updateAppointmentSubscription();
  }

  detached() {
    this._attached = false;
    this._appointmentSubscriptionManager.disposeSubscriptions();
  }

  processTaskAppointmentChanged() {
    if (this._attached) {
      this._updateAppointmentSubscription();
    }
  }

  _updateAppointmentSubscription() {
    this._appointmentSubscriptionManager.disposeSubscriptions();

    if (this.processTaskAppointment) {
      this._appointmentSubscriptionManager.subscribeToPropertyChange(
        this.processTaskAppointment,
        'processConfigurationStepId',
        this._updateMessage.bind(this)
      );
      this._appointmentSubscriptionManager.subscribeToPropertyChange(
        this.processTaskAppointment,
        'smsNotification',
        this._updateMessage.bind(this)
      );
    }

    this._updateMessage();
  }

  _updateMessage() {
    const stepId = this.processTaskAppointment
      ? this.processTaskAppointment.processConfigurationStepId
      : null;
    const step = stepId
      ? this._entityManager.processConfigurationStepRepository.getById(stepId)
      : null;
    const smsNotification = this.processTaskAppointment
      ? this.processTaskAppointment.smsNotification
      : ProcessTaskAppointmentSmsNotification.DEFAULT;

    if (!this.processTaskAppointment) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.noAppointment';
      this._type = 'error';
      // no strict equality because of null check
      // eslint-disable-next-line eqeqeq
    } else if (
      !stepId &&
      smsNotification == ProcessTaskAppointmentSmsNotification.DEFAULT
    ) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.noStepSelected';
      this._type = 'error';
    } else if (smsNotification === ProcessTaskAppointmentSmsNotification.YES) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.notificationsEnabled';
      this._type = 'ok';
    } else if (smsNotification === ProcessTaskAppointmentSmsNotification.NO) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.noSmsNotificationsEnabled';
      this._type = 'neutral';
    } else if (!step) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.noStep';
      this._type = 'error';
    } else if (!step.smsNotifications) {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.noSmsNotificationsEnabled';
      this._type = 'neutral';
    } else {
      this._messageTextTk =
        'operations.processTaskAppointmentSmsEnabledText.notificationsEnabled';
      this._type = 'ok';
    }
  }
}
