import { AlwaysEncodeDataHandler } from './AlwaysEncodeDataHandler';
import { DebugLogger } from './DebugLogger';
import { SocketIoZipPluginUtils } from './SocketIoZipPluginUtils';

/**
 * Automatically encodes/compresses data sent.
 *
 * Since there is no way to plug in into the base layer of the server and client, we modify the prototypes here and plug on top of the public api.
 */
export class SocketIoZipClientPlugin {
  private static readonly debugLogger = new DebugLogger({
    loggingEnabled: false
  });

  private constructor() {}

  public static patch(
    packageToPatch: typeof import('../../../public/node_modules/socket.io-client')
  ): void {
    const socketDataHandler = new AlwaysEncodeDataHandler();

    SocketIoZipPluginUtils.patchEmitFunction({
      toPatch: packageToPatch.Socket,
      functionName: 'emit',
      dataHandler: socketDataHandler,
      debugLogger: this.debugLogger
    });

    SocketIoZipPluginUtils.patchOnOffFunctions({
      toPatch: packageToPatch.Socket,
      dataHandler: socketDataHandler
    });

    SocketIoZipPluginUtils.patchOnOffAnyFunctions({
      toPatch: packageToPatch.Socket,
      dataHandler: socketDataHandler
    });
  }

  public static enableDebugLogging(): void {
    this.debugLogger.enableLogging();
  }

  public static disableDebugLogging(): void {
    this.debugLogger.disableLogging();
  }
}
