import { autoinject } from 'aurelia-framework';

import { TUploadDocxTemplateFileResponse } from 'common/EndpointTypes/UploadFileEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SessionService } from '../../../../services/SessionService/SessionService';
import { SocketService } from '../../../../services/SocketService';
import { Dialogs } from '../../../Dialogs';
import { DataUrlReader } from '../../../Reader/DataUrlReader/DataUrlReader';
import { ReportTypeUtils } from './ReportTypeUtils';
import { ReportType } from './types';
import { FileDownloadService } from '../../../../services/FileDownloadService';

@autoinject()
export class ReportTypeTemplateService {
  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly socketService: SocketService,
    private readonly sessionService: SessionService
  ) {}

  public async downloadTemplate(reportType: ReportType): Promise<void> {
    const token = this.sessionService.getCurrentJWT();
    assertNotNullOrUndefined(
      token,
      'tried to download report template without a token'
    );

    const downloadUrl = ReportTypeUtils.getRelativeOnlineFilePathForReportType(
      reportType,
      token
    );
    await this.fileDownloadService.downloadFile(downloadUrl);
  }

  /**
   *
   * @param {Object} reportType
   * @param {File} file
   */
  public uploadTemplate(reportType: ReportType, file: File): void {
    if (file.size > 1024 * 1024 * 20) {
      void Dialogs.errorDialog('Es sind nur Dateien mit max. 20MB erlaubt!');
      return;
    }

    const reader = new DataUrlReader();

    Dialogs.blockingStatusDialog('Datei wird vorbereitet...');
    void reader.readFile(file).then(
      (result) => {
        if (this.socketService.isConnected()) {
          this.socketService.uploadDOCXTemplateFile(
            {
              file: result,
              reportTypeId: reportType.id,
              fileName: file.name
            },
            this.onUploadTemplateReturn.bind(this)
          );
        } else {
          void Dialogs.errorDialog(
            'Datei kann nicht hochgeladen werden, Server nicht gefunden! Überprüfen Sie Ihre Internetverbindung.'
          );
        }
      },
      (error) => {
        console.error('error while uploading template', error);
        void Dialogs.errorDialog(
          'Fehler',
          'Beim Hochladen ist ein Fehler aufgetreten, bitte versuchen Sie es gleich nochmal!'
        );
      }
    );
  }

  private onUploadTemplateReturn(data: TUploadDocxTemplateFileResponse): void {
    if (data.success) {
      Dialogs.timedSuccessDialog('Upload erfolgreich!');
    } else {
      const errorMessage = `serverResponses.${
        data.status ? data.status : 'unspecifiedError'
      }`;
      void Dialogs.errorDialogTk(
        'serverResponses.uploadDocxTemplateFile.uploadError',
        errorMessage
      );
    }
  }
}
