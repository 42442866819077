import { autoinject } from 'aurelia-framework';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { MomentInput } from 'moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { QuestionSet } from '../../classes/EntityManager/entities/QuestionSet/types';
import { QuestionCatalogue } from '../../classes/EntityManager/entities/QuestionCatalogue/types';
import { configureHooks } from '../../hooks/configureHooks';
import { QuestionCatalogueService } from '../../classes/EntityManager/entities/QuestionCatalogue/QuestionCatalogueService';
import { CheckboxCheckedChangedEvent } from '../../aureliaComponents/expandable-dual-row-compact-list-item/expandable-dual-row-compact-list-item';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditQuestionSetDialog {
  protected questionSet: QuestionSet | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly questionCatalogueService: QuestionCatalogueService
  ) {}

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: Options): void {
    this.questionSet = options.questionSet;

    this.dialog?.open();
  }

  @computed(
    expression('questionSet'),
    model(EntityName.QuestionCatalogue),
    model(EntityName.QuestionCatalogueToQuestionSet)
  )
  private get relatedQuestionCatalogues(): Array<QuestionCatalogue> {
    if (!this.questionSet) return [];

    return this.questionCatalogueService.getByQuestionSetId(
      this.questionSet.id
    );
  }

  @computed(model(EntityName.QuestionCatalogue))
  protected get availableQuestionCatalogues(): Array<QuestionCatalogue> {
    return this.entityManager.questionCatalogueRepository.getAll();
  }

  @computed(expression('relatedQuestionCatalogues'))
  protected get questionCatalogueText(): string {
    return this.relatedQuestionCatalogues.map((e) => e.name).join(' / ');
  }

  protected isRelatedQuestionCatalogue(
    relatedQuestionCatalogues: Array<QuestionCatalogue>,
    catalogue: QuestionCatalogue
  ): boolean {
    return relatedQuestionCatalogues.includes(catalogue);
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  /** Needed for unmounting hooks */
  protected handleDialogClosed(): void {}

  protected handleQuestionSetChanged(): void {
    assertNotNullOrUndefined(
      this.questionSet,
      'cannot handleQuestionCatalogueChanged without questionCatalogue'
    );

    this.entityManager.questionSetRepository.update(this.questionSet);
  }

  protected handleRelatedQuestionCatalogueCheckedChanged(
    questionCatalogue: QuestionCatalogue,
    evt: CheckboxCheckedChangedEvent
  ): void {
    assertNotNullOrUndefined(
      this.questionSet,
      'cannot handleRelatedQuestionCatalogueCheckedChanged without questionSet'
    );

    if (evt.detail.checked) {
      this.entityManager.questionCatalogueToQuestionSetRepository.getOrCreate({
        questionSetId: this.questionSet.id,
        questionCatalogueId: questionCatalogue.id,
        ownerUserGroupId: this.questionSet.ownerUserGroupId
      });
    } else {
      const relation =
        this.entityManager.questionCatalogueToQuestionSetRepository.getRelation(
          questionCatalogue.id,
          this.questionSet.id
        );
      if (relation) {
        this.entityManager.questionCatalogueToQuestionSetRepository.delete(
          relation
        );
      }
    }
  }
}

type Options = {
  questionSet: QuestionSet;
};
export type EditQuestionSetDialogOptions = Options;
