import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { QuestionCategory } from '../../classes/EntityManager/entities/QuestionCategory/types';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { MomentInput } from 'moment';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditQuestionCategoryDialog {
  protected questionCategory: QuestionCategory | null = null;

  protected dialog: RecordItDialog | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: Options): void {
    this.questionCategory = options.questionCategory;

    this.dialog?.open();
  }

  @computed(expression('questionCategory'), model(EntityName.Question))
  protected get amountOfRelatedQuestions(): number {
    if (!this.questionCategory) return 0;

    return this.entityManager.questionRepository.getByQuestionCategoryId(
      this.questionCategory.id
    ).length;
  }

  protected formatToDate(time: MomentInput): string {
    return DateUtils.formatToDateString(time);
  }

  protected formatToTime(time: MomentInput): string {
    return DateUtils.formatToTimeString(time);
  }

  /** Needed for unmounting hooks */
  protected handleDialogClosed(): void {}

  protected handleQuestionCategoryChanged(): void {
    assertNotNullOrUndefined(
      this.questionCategory,
      'cannot handleQuestionCategoryChanged without questionCategory'
    );

    this.entityManager.questionCategoryRepository.update(this.questionCategory);
  }
}

type Options = {
  questionCategory: QuestionCategory;
};
export type EditQuestionCategoryDialogOptions = Options;
