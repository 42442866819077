import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  UserNotAvailableResponse,
  UserResponse
} from 'common/EndpointTypes/ProcessTaskAppointmentEndpointsHandler';
import { DateRangeChangeHandler } from '../../classes/Date/DateRangeChangeHandler';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskAppointmentToUser } from '../../classes/EntityManager/entities/ProcessTaskAppointmentToUser/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { DateRangeValueConverter } from '../../valueConverters/DateRangeValueConverter';

/**
 * @event {ProcessTaskAppointmentToUserChangedEvent} process-task-appointment-to-user-changed
 * @event {ProcessTaskAppointmentToUserDeleteClickedEvent} process-task-appointment-to-user-delete-clicked
 */
@autoinject()
export class ProcessTaskAppointmentUserWidget {
  @bindable()
  public processTaskAppointmentToUser: ProcessTaskAppointmentToUser | null =
    null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public userAvailableResponses: Array<UserResponse> = [];

  @subscribableLifecycle()
  protected processTaskAppointmentToUserPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskAppointmentToUser];

  protected userAvailableResponse: UserResponse | null = null;
  private dateRangeChangeHandler: DateRangeChangeHandler<ProcessTaskAppointmentToUser> | null =
    null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly dateRangeValueConverter: DateRangeValueConverter,
    permissionsService: PermissionsService
  ) {
    this.processTaskAppointmentToUserPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskAppointmentToUser,
        context: this as ProcessTaskAppointmentUserWidget,
        propertyName: 'processTaskAppointmentToUser'
      });
  }

  protected processTaskAppointmentToUserChanged(): void {
    this.getOrCreateDateRangeChangeHandler().setModel(
      this.processTaskAppointmentToUser
    );
    this.updateUserAvailableResponse();
  }

  protected userAvailableResponsesChanged(): void {
    this.updateUserAvailableResponse();
  }

  private updateUserAvailableResponse(): void {
    this.userAvailableResponse =
      this.userAvailableResponses.find(
        (r) => r.userId === this.processTaskAppointmentToUser?.userId
      ) ?? null;
  }

  protected handleRelationDateChanged(): void {
    this.getOrCreateDateRangeChangeHandler().autoAdjustAndAccept();
    this.handleRelationChanged();
  }

  private handleRelationChanged(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointmentToUser,
      "can't ProcessTaskAppointmentUserWidget.handleRelationChanged without processTaskAppointmentToUser"
    );

    this.entityManager.processTaskAppointmentToUserRepository.update(
      this.processTaskAppointmentToUser
    );

    DomEventHelper.fireEvent<ProcessTaskAppointmentToUserChangedEvent>(
      this.element,
      {
        name: 'process-task-appointment-to-user-changed',
        detail: null
      }
    );

    this.updateUserAvailableResponse();
  }

  protected handleDeleteClick(): void {
    assertNotNullOrUndefined(
      this.processTaskAppointmentToUser,
      "can't ProcessTaskAppointmentUserWidget.handleRelationChanged without processTaskAppointmentToUser"
    );
    DomEventHelper.fireEvent<ProcessTaskAppointmentToUserDeleteClickedEvent>(
      this.element,
      {
        name: 'process-task-appointment-to-user-delete-clicked',
        detail: {
          processTaskAppointmentToUser: this.processTaskAppointmentToUser
        }
      }
    );
  }

  private getOrCreateDateRangeChangeHandler(): DateRangeChangeHandler<ProcessTaskAppointmentToUser> {
    if (!this.dateRangeChangeHandler) {
      this.dateRangeChangeHandler = new DateRangeChangeHandler({
        dateFormat: 'iso',
        getDateFrom: (a) => a.dateFrom,
        getDateTo: (a) => a.dateTo,
        setDateFrom: (a, date) => {
          a.dateFrom = date;
        },
        setDateTo: (a, date) => {
          a.dateTo = date;
        }
      });
    }

    return this.dateRangeChangeHandler;
  }

  protected getAppointmentSummary(
    userNotAvailableResponse: UserNotAvailableResponse
  ): string {
    const parts: Array<string> = [];

    const rangeText = this.dateRangeValueConverter.toView({
      fromIso: userNotAvailableResponse.appointmentDateFrom,
      toIso: userNotAvailableResponse.appointmentDateTo
    });

    if (rangeText) {
      parts.push(rangeText);
    }

    if (userNotAvailableResponse.appointmentName) {
      parts.push(userNotAvailableResponse.appointmentName);
    }

    return parts.join(', ');
  }
}

export type ProcessTaskAppointmentToUserChangedEvent = NamedCustomEvent<
  'process-task-appointment-to-user-changed',
  null
>;
export type ProcessTaskAppointmentToUserDeleteClickedEvent = NamedCustomEvent<
  'process-task-appointment-to-user-delete-clicked',
  { processTaskAppointmentToUser: ProcessTaskAppointmentToUser }
>;
