/** @type {{DEFAULT: 'default', MARKUP: 'markup', INFO: 'info'}} */
export const ProcessConfigurationPositionType = {
  DEFAULT: 'default',
  MARKUP: 'markup',
  INFO: 'info'
};

/** @type {TProcessConfigurationPositionTypeConfiguration} */
export const defaultConfiguration = {
  hasUnit: true,
  hasPrice: true,
  hasAmount: true,
  canHaveDetail: true,
  relevantInMarkupCalculation: true,
  hasIgnoreExcludeFromMarkup: false
};

/** @type {Map<TProcessConfigurationPositionType|undefined, TProcessConfigurationPositionTypeConfiguration>} */
export const ProcessConfigurationPositionTypeToConfiguration = new Map([
  [null, defaultConfiguration],
  [undefined, defaultConfiguration],
  [ProcessConfigurationPositionType.DEFAULT, defaultConfiguration],
  [
    ProcessConfigurationPositionType.MARKUP,
    {
      hasUnit: false,
      hasPrice: true,
      hasAmount: false,
      canHaveDetail: false,
      relevantInMarkupCalculation: false,
      hasIgnoreExcludeFromMarkup: true
    }
  ],
  [
    ProcessConfigurationPositionType.INFO,
    {
      hasUnit: false,
      hasPrice: false,
      hasAmount: false,
      canHaveDetail: false,
      relevantInMarkupCalculation: false,
      hasIgnoreExcludeFromMarkup: false
    }
  ]
]);

/**
 * @typedef {null|TProcessConfigurationPositionTypeWithoutNull} TProcessConfigurationPositionType
 */

/**
 * @typedef {'default'|'markup'|'info'} TProcessConfigurationPositionTypeWithoutNull
 */

/**
 * @typedef {Object} TProcessConfigurationPositionTypeConfiguration
 * @property {boolean} hasUnit
 * @property {boolean} hasPrice
 * @property {boolean} hasAmount
 * @property {boolean} canHaveDetail
 * @property {boolean} relevantInMarkupCalculation
 * @property {boolean} hasIgnoreExcludeFromMarkup
 */
