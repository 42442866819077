import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ThingAuthorization } from './types';

export class ThingAuthorizationRepository extends AppEntityRepository<EntityName.ThingAuthorization> {
  public getByUserId(userId: string): Array<ThingAuthorization> {
    return this.getAll().filter(
      (authorization) => authorization.userId === userId
    );
  }

  public getByThingId(thingId: string): Array<ThingAuthorization> {
    return this.getAll().filter(
      (authorization) => authorization.thingId === thingId
    );
  }
}
