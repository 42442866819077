import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfigurationCategory } from '../../classes/EntityManager/entities/ProcessConfigurationCategory/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationCategoriesWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private isAttached: boolean = false;
  protected categories: Array<ProcessConfigurationCategory> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationCategoriesWidget,
        propertyName: 'processConfiguration'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this.updateCategories.bind(this)
    );
    this.updateCategories();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processConfigurationChanged(): void {
    if (this.isAttached) {
      this.updateCategories();
    }
  }

  private updateCategories(): void {
    if (this.processConfiguration) {
      this.categories =
        this.entityManager.processConfigurationCategoryRepository.getSortedCategoriesByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.categories = [];
    }
  }

  protected handleAddCategoryClick(): void {
    if (this.processConfiguration) {
      this.entityManager.processConfigurationCategoryRepository.create({
        ownerProcessConfigurationId: this.processConfiguration.id,
        ownerUserGroupId: this.processConfiguration.ownerUserGroupId
      });

      this.updateCategories();
    }
  }
}
