import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { NfcTokenToPersonDto } from './NfcTokenToPersonDto';

export function createNfcTokenToPersonEntityInfo<
  TEntity extends NfcTokenToPersonDto<string, string>
>(
  options: CreateEntityInfoOptions<NfcTokenToPersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.NfcTokenToPerson,
  TEntity
> {
  return {
    entityName: EntityName.NfcTokenToPerson,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      NfcTokenToPersonDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        nfcTokenId: {
          name: 'nfcTokenId',
          cascadeDeleteLocally: true,
          ref: EntityName.NfcToken
        },
        personId: {
          name: 'personId',
          cascadeDeleteLocally: true,
          ref: EntityName.Person
        }
      },
      options.additionalFieldInfos
    )
  };
}
