import { EntityInfo } from '@record-it-npm/synchro-common';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processConfigurationStepAutoFormEntityInfo } from '../../../../classes/EntityManager/entities/ProcessConfigurationStepAutoForm/processConfigurationStepAutoFormEntityInfo';
import { ProcessConfigurationStepAutoForm } from '../../../../classes/EntityManager/entities/ProcessConfigurationStepAutoForm/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import { EntityAdapter, SubscribeOptions } from '../EntityAdapter';

export class ProcessConfigurationStepAutoFormAdapter
  implements EntityAdapter<ProcessConfigurationStepAutoForm>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;
  private userGroupIdsWithSmsCredentials: Set<string> = new Set();

  private roleBasedPermissions: RoleBasedPermissions | null = null;

  constructor(options: ProcessConfigurationStepAutoFormAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ userGroupIdsWithSmsCredentials }) => {
          this.userGroupIdsWithSmsCredentials = userGroupIdsWithSmsCredentials;
          updateBindings();
        }
      })
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          updateBindings();
        }
      })
    );

    return subscriptionManager.toDisposable();
  }

  public canDeleteEntity(step: ProcessConfigurationStepAutoForm): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(step.ownerUserGroupId)
      .getCanDeleteProcessConfigurationStepAutoForms();
  }

  public canEditField(step: ProcessConfigurationStepAutoForm): boolean {
    if (!this.roleBasedPermissions) {
      return false;
    }

    return this.roleBasedPermissions
      .inUserGroupId(step.ownerUserGroupId)
      .getCanUpdateProcessConfigurationStepAutoForms();
  }

  public canSeeSmsNotificationSetting(
    step: ProcessConfigurationStepAutoForm
  ): boolean {
    return this.userGroupIdsWithSmsCredentials.has(step.ownerUserGroupId);
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessConfigurationStepAutoForm,
    ProcessConfigurationStepAutoForm
  > {
    return processConfigurationStepAutoFormEntityInfo;
  }
}

export type ProcessConfigurationStepAutoFormAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
