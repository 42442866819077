import { autoinject } from 'aurelia-framework';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { User } from '../../classes/EntityManager/entities/User/types';
import { ModuleName } from '../../classes/RecordItModuleHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { computed } from '../../hooks/computed';
import { configureHooks } from '../../hooks/configureHooks';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { currentUser } from '../../hooks/dependencies';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { computedFrom } from 'aurelia-binding';
import { UrlManager } from '../../classes/UrlManager';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class HelpDialog {
  protected dialog: RecordItDialog | null = null;
  protected ModuleName = ModuleName;

  constructor(private readonly currentUserService: CurrentUserService) {}

  public static async open(): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    return view.getViewModel().open();
  }

  private open(): void {
    this.dialog?.open();
  }

  /** Needed for unmounting hooks */
  protected handleDialogClosed(): void {}

  @computed(currentUser())
  protected get currentUser(): User | null {
    return this.currentUserService.getCurrentUser();
  }

  protected userHasAccessToModule(user: User, moduleName: ModuleName): boolean {
    return PermissionHelper.userHasPermissionForModule(user, moduleName);
  }

  protected withBaseUrl(path: string): string {
    return `${UrlManager.webFolder}${path}`;
  }

  @computedFrom()
  protected get isApp(): boolean {
    return DeviceInfoHelper.isApp();
  }
}
