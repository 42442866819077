import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessTaskChecklistEntryEntityInfo } from 'common/Types/Entities/ProcessTaskChecklistEntry/createProcessTaskChecklistEntryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessTaskChecklistEntry } from './types';

export const processTaskChecklistEntryEntityInfo =
  createProcessTaskChecklistEntryEntityInfo<ProcessTaskChecklistEntry>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
