import { TileLayer, TileLayerOptions } from 'leaflet';

export class BaseTileLayer extends TileLayer {
  constructor(
    private name: string,
    url: string,
    options: TileLayerOptions
  ) {
    super(url, options);
  }

  public getName(): string {
    return this.name;
  }
}
