import { computedFrom } from 'aurelia-binding';
import { AppEntityManagerEntityTypesByEntityName } from '../../../classes/EntityManager/entities/AppEntityManagerEntityTypesByEntityName';
import { Subscribable } from '../../../classes/SubscribableArray/SubscribableArray';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import {
  EntityNameToAdapter,
  EntityNameToAdapterContainer,
  SupportedEntityName
} from '../entityNameToPermissionsConfig';

/**
 * A handle which filters the entities by a given permission
 */
export class EntitiesWithPermissionHandle<
  TEntityName extends SupportedEntityName,
  TEntity extends
    AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'] = AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
> implements Subscribable
{
  private readonly adapterContainer: EntityNameToAdapterContainer[TEntityName];
  private readonly checkPermission: CheckPermissionCallback<TEntityName>;

  private adapter: EntityNameToAdapter[TEntityName] | null = null;

  private entities: Array<TEntity> = [];

  private internalFilteredEntities: Array<TEntity> = [];

  constructor({
    adapterContainer,
    checkPermission
  }: {
    adapterContainer: EntityNameToAdapterContainer[TEntityName];
    checkPermission: CheckPermissionCallback<TEntityName>;
  }) {
    this.adapterContainer = adapterContainer;
    this.checkPermission = checkPermission;
  }

  public subscribe(): Disposable {
    const disposable = this.adapterContainer.bindAdapter(({ adapter }) => {
      this.adapter = adapter;
      this.updateFilteredEntities();
    });

    return {
      dispose: () => {
        disposable.dispose();
        this.adapter = null;
        this.updateFilteredEntities();
      }
    };
  }

  public setEntities(entities: Array<TEntity>): void {
    this.entities = entities;
    this.updateFilteredEntities();
  }

  @computedFrom('internalFilteredEntities')
  public get filteredEntities(): Array<TEntity> {
    return this.internalFilteredEntities;
  }

  private updateFilteredEntities(): void {
    const newFilteredEntities = this.filterEntities();

    if (
      this.isDifferentThanFilteredEntities({
        newFilteredEntities
      })
    ) {
      this.internalFilteredEntities = newFilteredEntities;
    }
  }

  private filterEntities(): Array<TEntity> {
    const adapter = this.adapter;
    if (!adapter) {
      return [];
    }

    return this.entities.filter((entity) => {
      return this.checkPermission({ adapter, entity });
    });
  }

  private isDifferentThanFilteredEntities({
    newFilteredEntities
  }: {
    newFilteredEntities: Array<TEntity>;
  }): boolean {
    if (newFilteredEntities.length !== this.internalFilteredEntities.length) {
      return true;
    }

    for (const [index, item] of newFilteredEntities.entries()) {
      if (item !== this.internalFilteredEntities[index]) {
        return true;
      }
    }

    return false;
  }
}

type CheckPermissionCallback<TEntityName extends SupportedEntityName> =
  (options: {
    adapter: EntityNameToAdapter[TEntityName];
    entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'];
  }) => boolean;
