import { bindable, inject } from 'aurelia-framework';

import { ProcessConfigurationPositionType } from 'common/Enums/ProcessConfigurationPositionType';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @event value-changed
 */
@inject(Element)
export class ProcessConfigurationPositionTypeSelect {
  /** @type {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionType} */
  @bindable value = ProcessConfigurationPositionType.DEFAULT;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {Array<{labelTk: string, value: import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionType}>} */
  _options;

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._domElement = element;

    this._options = [
      {
        value: ProcessConfigurationPositionType.DEFAULT,
        labelTk:
          'modelsDetail.ProcessConfigurationStepPositionModel.type.' +
          ProcessConfigurationPositionType.DEFAULT
      },
      {
        value: ProcessConfigurationPositionType.MARKUP,
        labelTk:
          'modelsDetail.ProcessConfigurationStepPositionModel.type.' +
          ProcessConfigurationPositionType.MARKUP
      },
      {
        value: ProcessConfigurationPositionType.INFO,
        labelTk:
          'modelsDetail.ProcessConfigurationStepPositionModel.type.' +
          ProcessConfigurationPositionType.INFO
      }
    ];
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}

export class NullToDefaultValueConverter {
  /**
   * @param {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionType} type
   * @returns {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionTypeWithoutNull}
   */
  toView(type) {
    return this._convertType(type);
  }

  /**
   * @param {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionType} type
   * @returns {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionTypeWithoutNull}
   */
  fromView(type) {
    return this._convertType(type);
  }

  /**
   * @param {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionType} type
   * @returns {import('../../../../common/src/Enums/ProcessConfigurationPositionType').TProcessConfigurationPositionTypeWithoutNull}
   */
  _convertType(type) {
    if (type == null) {
      return ProcessConfigurationPositionType.DEFAULT;
    }

    return type;
  }
}
