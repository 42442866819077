import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTaskDevice } from './types';

@autoinject()
export class ProcessTaskDeviceSnapshotService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public createSnapshot(position: ProcessTaskDevice): ProcessTaskDevice {
    const snapshot = this.entityManager.processTaskDeviceRepository.create({
      ...position,
      snapshotOfProcessTaskDeviceId: position.id
    });

    this.copyPropertiesToSnapshot({
      position,
      snapshot
    });

    return snapshot;
  }

  private copyPropertiesToSnapshot({
    position,
    snapshot
  }: {
    position: ProcessTaskDevice;
    snapshot: ProcessTaskDevice;
  }): void {
    const properties =
      this.entityManager.propertyRepository.getByProcessTaskDeviceId(
        position.id
      );
    for (const property of properties) {
      this.entityManager.propertyRepository.create({
        ...property,
        processTaskDeviceId: snapshot.id
      });
    }
  }
}
