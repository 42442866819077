import { autoinject, bindable } from 'aurelia-framework';

import { UiUpdater } from '../../classes/UiUpdater';
import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { Vector } from 'common/Geometry/Vector';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TooltipContentMaxSizeSolver } from './TooltipContentMaxSizeSolver';
import { TooltipContentElementAligner } from './TooltipContentElementAligner';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { TRectangleToRectangleAlignerAlignmentMethod } from 'common/Aligner/RectangleToRectangleAligner';
import { SiteScrollLocker } from '../../classes/SiteScrollLocker';
import { FullScreenContent } from '../full-screen-content/full-screen-content';

/**
 * this element will position it self fixed to the parent element
 * be careful, only the first HTMLElement child will be displayed and the element shouldn't have any position set
 * it's made this way to have more customizablity (instead of internally wrapping the content)
 *
 * usage example:
 *  <tooltip-content>
 *    <div>this is the content</div>
 *  </tooltip-content>
 *
 * following examples are NOT allowed:
 *  <tooltip-content>
 *    this is the content
 *  </tooltip-content>
 *  <tooltip-content>
 *    <div>this is the content</div>
 *    <div>this is also the content</div>
 *  </tooltip-content>
 *
 *
 * @slot default put all the content in here
 * @event tooltip-content-start-opening
 * @event tooltip-content-start-closing
 * @event tooltip-content-closed
 */
@autoinject()
export class TooltipContent {
  /**
   * element which the content should be positioned to
   * if ommited then the parent Element will be used
   */
  @bindable public targetElement: HTMLElement | null = null;

  /**
   * close the tooltip when the user clicked on the body (but not inside the tooltip)
   *
   * if you just want to prevent certain clicks (which ultimately also bubble to the body),
   * call the ignoreClickEvent function in a click eventListener which is be called BEFORE the event has reached the body
   * usage example for this case would be a button which toggles the tooltip (since the tooltip is already opened when the click event reaches the body,
   * and the toggle function would immediately close the tooltip again)
   */
  @bindable public closeOnBodyClick: boolean = true;

  @bindable public enableTriangle: boolean = true;

  /**
   * any valid css color
   */
  @bindable public triangleBackgroundColor: string = '#fafafa';

  /**
   * limit the size of the content element to the maximum available screen space
   */
  @bindable public limitToAvailableSpace: boolean = false;

  @bindable public targetAlignment = new Vector(0.5, 1);
  @bindable public tooltipAlignment = new Vector(0.5, 0);
  @bindable public margin = new Vector(0, 10);

  /**
   * use the same width as the targetElement
   */
  @bindable public useSameWidth = false;

  /**
   * explicit width of the tooltip-content
   * if this is used in combination with `useSameWidth` then the bigger value will be used
   */
  @bindable public contentWidth: string | null = null;

  @bindable public lockScrolling = false;

  private domElement: HTMLElement;

  private isOpened: boolean = false;

  private isAttached: boolean = false;

  private fullScreenContent: FullScreenContent | null = null;
  private fullScreenContentElement: HTMLElement | null = null;

  private contentElementAnimator: ShowHideAnimator | null = null;

  private triangleElement: HTMLElement | null = null;

  private slottedElement: HTMLElement | null = null;

  private maxSizeSolver: TooltipContentMaxSizeSolver | null = null;

  private boundUpdatePosition: () => void = this.updatePosition.bind(this);

  private boundHandleBodyClick: (event: MouseEvent) => void =
    this.handleBodyClick.bind(this);

  private eventToIgnore: MouseEvent | null = null;

  private aligner: TooltipContentElementAligner | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public open(): void {
    assertNotNullOrUndefined(
      this.fullScreenContent,
      "can't open without fullScreenContent"
    );
    assertNotNullOrUndefined(
      this.fullScreenContentElement,
      "can't open without fullScreenContentElement"
    );

    if (this.lockScrolling) SiteScrollLocker.lockScrolling('tooltip-content');

    if (!this.isOpened) {
      this.updateSlottedElement();

      if (!this.contentElementAnimator) {
        this.contentElementAnimator = new ShowHideAnimator(
          this.fullScreenContentElement
        );
      } else {
        this.contentElementAnimator.clearAnimations();
      }

      this.isOpened = true;
      this.fullScreenContent.open();
      UiUpdater.registerUpdateFunction(this.boundUpdatePosition);
      this.animateContentElement(true, () => {});

      DomEventHelper.fireEvent(this.domElement, {
        name: 'tooltip-content-start-opening',
        detail: null
      });
    }
  }

  public close(noAnimation?: boolean): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'tooltip-content-start-closing',
      detail: null
    });

    if (noAnimation) {
      if (this.contentElementAnimator) {
        this.contentElementAnimator.clearAnimations();
      }
      this.handleCloseAnimationFinished();
    } else {
      this.closeAnimated();
    }

    SiteScrollLocker.unlockScrolling('tooltip-content');
  }

  private closeAnimated(): void {
    if (this.isOpened) {
      this.isOpened = false;

      this.animateContentElement(
        false,
        this.handleCloseAnimationFinished.bind(this)
      );
    }
  }

  private handleCloseAnimationFinished(): void {
    assertNotNullOrUndefined(
      this.fullScreenContent,
      "can't handleCloseAnimationFinished without fullScreenContent"
    );
    UiUpdater.unregisterUpdateFunction(this.boundUpdatePosition);
    this.slottedElement = null;

    this.fullScreenContent.close();

    DomEventHelper.fireEvent(this.domElement, {
      name: 'tooltip-content-closed',
      detail: null
    });
  }

  public toggle(): void {
    if (this.isOpened) {
      this.close();
    } else {
      this.open();
    }
  }

  public isOpen(): boolean {
    return this.isOpened;
  }

  protected attached(): void {
    this.isAttached = true;

    document.body.addEventListener('click', this.boundHandleBodyClick);
  }

  protected detached(): void {
    this.isAttached = false;

    this.close(true);

    document.body.removeEventListener('click', this.boundHandleBodyClick);
  }

  public ignoreClickEvent(event: MouseEvent): void {
    this.eventToIgnore = event;
  }

  protected targetAlignmentChanged(): void {
    if (this.aligner) {
      this.aligner.setReferenceRectangleAlignment(this.targetAlignment);
    }
  }

  protected tooltipAlignmentChanged(): void {
    if (this.aligner) {
      this.aligner.setReferenceRectangleAlignment(this.targetAlignment);
    }
  }

  private updateSlottedElement(): void {
    if (
      this.fullScreenContentElement &&
      this.fullScreenContentElement.firstElementChild &&
      this.fullScreenContentElement.firstElementChild !== this.triangleElement
    ) {
      this.slottedElement = this.fullScreenContentElement
        .firstElementChild as HTMLElement;
      this.maxSizeSolver = new TooltipContentMaxSizeSolver(
        this.fullScreenContentElement,
        this.slottedElement
      );
      this.aligner = new TooltipContentElementAligner(
        this.fullScreenContentElement,
        this.slottedElement,
        this.targetAlignment,
        this.tooltipAlignment,
        this.margin
      );
    } else {
      console.error(
        'There is no required element slotted into this tooltip-content!',
        this.fullScreenContentElement
      ); // error also as log, so we can output the html to find the responsible element mor easily
      throw new Error(
        'There is no required element slotted into this tooltip-content!'
      );
    }
  }

  private getTargetElementOrParent(): HTMLElement {
    if (this.targetElement) {
      return this.targetElement;
    }

    if (this.domElement && this.domElement.parentNode) {
      return this.domElement.parentNode as HTMLElement;
    }

    throw new Error(
      'no matching element found, maybe the element is not attached?'
    );
  }

  private handleBodyClick(event: MouseEvent): void {
    const toIgnore = this.eventToIgnore;
    this.eventToIgnore = null;

    if (
      event !== toIgnore &&
      this.isOpened &&
      !this.mouseEventIsInsideFullScreenContentElement(event) &&
      this.closeOnBodyClick
    ) {
      this.close();
    }
  }

  private mouseEventIsInsideFullScreenContentElement(
    event: MouseEvent
  ): boolean {
    if (!event.target) {
      return false;
    }

    let currentElement: HTMLElement = event.target as HTMLElement;
    let inside = false;

    do {
      if (currentElement === this.fullScreenContentElement) {
        inside = true;
        break;
      }
    } while ((currentElement = currentElement.parentNode as HTMLElement));

    return inside;
  }

  // +++++++++++++ Animation/Positioning +++++++++++++

  /**
   * @param {boolean} show - set to true to do a show animation, else do a hide animation
   * @param {() => void} callback - gets called when animation has finished
   * @private
   */
  private animateContentElement(show: boolean, callback: () => void): void {
    if (!this.contentElementAnimator) {
      throw new Error("contentElementAnimator isn't initialized");
    }

    if (show) {
      void this.contentElementAnimator.fadeIn().then(callback);
    } else {
      void this.contentElementAnimator.fadeOut().then(callback);
    }
  }

  private updatePosition(): void {
    const target = this.getTargetElementOrParent();
    if (target && this.fullScreenContentElement && this.aligner) {
      if (this.contentWidth && this.useSameWidth) {
        this.fullScreenContentElement.style.width = `max(${this.contentWidth}, ${target.offsetWidth}px)`;
      } else if (this.contentWidth) {
        this.fullScreenContentElement.style.width = this.contentWidth;
      } else if (this.useSameWidth) {
        this.fullScreenContentElement.style.width = target.offsetWidth + 'px';
      } else {
        this.fullScreenContentElement.style.width = 'auto';
      }
      const method = this.aligner.align(target, this.limitToAvailableSpace);

      if (this.maxSizeSolver && this.limitToAvailableSpace) {
        this.maxSizeSolver.solve(method);
      }

      this.updateTriangleElement(method, target);
    }
  }

  private updateTriangleElement(
    method: TRectangleToRectangleAlignerAlignmentMethod,
    targetElement: HTMLElement
  ): void {
    if (!this.triangleElement || !this.fullScreenContentElement) {
      return;
    }

    if (
      method.referenceRectangleAlignment.getY() >
      method.alignedRectangleAlignment.getY()
    ) {
      this.triangleElement.className = 'tooltip-content--TriangleUp';
    } else {
      this.triangleElement.className = 'tooltip-content--TriangleDown';
    }

    this.triangleElement.style.borderBottomColor = this.triangleBackgroundColor;

    const targetBoundingRect = targetElement.getBoundingClientRect();
    const contentBoundingRect =
      this.fullScreenContentElement.getBoundingClientRect();

    const targetX = targetBoundingRect.left + targetBoundingRect.width / 2;
    this.triangleElement.style.left = targetX - contentBoundingRect.left + 'px';
  }
}
