import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { FullScreenOverlay } from '../../aureliaComponents/full-screen-overlay/full-screen-overlay';
import { FormController } from '../../aureliaComponents/form-controller/form-controller';
import { IconType } from '../../aureliaComponents/custom-icon/custom-icon';
import { Tab } from './Tab/Tab';
import { TabCollection } from './Tab/TabCollection';

/**
 * @slot content - can contain simple elements or tabs
 * Tabs can be defined as <div data-tab="name" data-tab-title="title" slot="content">
 * If you want to preserve the scroll position when changing between tabs, then you can add the 'data-tab-preserve-scrolling' attribute to the tab element
 *
 * @slot buttons - you can place additional buttons in this slot (or handle all of the button logic yourself)
 *
 * @event record-it-dialog-${button.name}-button-clicked is fired when a button has been clicked
 *
 * @event record-it-dialog-closed is fired when the dialog has been closed (by the user or programmatically)
 *
 * @event record-it-dialog-opened - is fired when the dialog is fully shown
 *
 * @event record-it-dialog-backdrop-clicked - is fired when the user clicks the backdrop (regardless of disableClosingDialog)
 */
@autoinject()
export class RecordItDialog {
  /**
   * look at RecordItDialog.typeConfigs for a list of available types
   */
  @bindable public type: DialogType = DialogType.ACCEPT;

  /**
   * optional title to show at the top
   */
  @bindable public title: string | null = null;

  /**
   * if you set this to false you have to manually close the dialog after a button press
   */
  @bindable public closeOnButtonClick = true;

  @bindable public disableClosingDialog = false;

  @bindable public doNotCloseOnBackdropClick: boolean = false;

  @bindable public navigationButtonConfigs: Array<NavigationButtonConfig> = [];

  /**
   * for further documentation look at the full-screen-overlay
   */
  @bindable public testSelector: string | null = null;

  /**
   * readonly!
   */
  @bindable public opened = false;

  /**
   * if true, then the dialog size will not be dependent on the content, but will always be the max size
   * also the content element can have the height set to a % value to fill the dialog
   */
  @bindable public fullSize = false;

  /**
   * Disables all buttons when set to `true`.
   * If a string / array of strings is given, will disable buttons with the given name.
   */
  @bindable public disableButtons: boolean | Array<string> | string = false;

  private static typeConfigs: Record<DialogType, DialogConfig> = {
    accept: {
      buttons: [
        {
          name: 'accept',
          className: 'record-it-dialog--IconButton',
          html: '<i class="fal fa-check"></i>'
        }
      ]
    },
    'accept-decline': {
      buttons: [
        {
          name: 'decline',
          className: 'record-it-dialog--IconButton',
          html: '<i class="fal fa-times"></i>'
        },
        {
          name: 'accept',
          className: 'record-it-dialog--IconButton',
          html: '<i class="fal fa-check"></i>'
        }
      ]
    },
    'accept-cancel': {
      buttons: [
        {
          name: 'cancel',
          className: '',
          html: 'Abbrechen'
        },
        {
          name: 'accept',
          className: 'record-it-dialog--IconButton',
          html: '<i class="fal fa-check"></i>'
        }
      ]
    },
    close: {
      buttons: [
        {
          name: 'close',
          className: 'record-it-dialog--IconButton',
          html: '<i class="fal fa-times"></i>'
        }
      ]
    },
    cancel: {
      buttons: [
        {
          name: 'cancel',
          html: 'Abbrechen'
        }
      ]
    },
    'no-buttons': {
      buttons: []
    }
  };

  private fullScreenOverlay: FullScreenOverlay | null = null;
  private domElement: HTMLElement;
  private contentDomElement: HTMLElement | null = null;
  private contentWrapperElement: HTMLElement | null = null;
  private formController: FormController | null = null;

  private tabCollection: TabCollection;

  private lastCloseDescription: DialogCloseDescription = {
    reason: DialogClosedReason.PROGRAMMATICALLY
  };

  private config: DialogConfig = RecordItDialog.typeConfigs.close;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;

    this.tabCollection = new TabCollection({
      getScrollPosition: () => {
        assertNotNullOrUndefined(
          this.contentWrapperElement,
          'contentWrapperElement is not available'
        );
        return this.contentWrapperElement.scrollTop;
      },
      setScrollPosition: (position) => {
        assertNotNullOrUndefined(
          this.contentWrapperElement,
          'contentWrapperElement is not available'
        );
        this.contentWrapperElement.scrollTop = position;
      }
    });

    // since aurelia doesn't call it itself, we call it manually
    this.typeChanged();
  }

  public open(): void {
    // timeout so hidden tabs can be computed properly before opening the dialog
    setTimeout(() => {
      this.updateTabElements();
      this.tabCollection.selectFirstTab();

      if (this.fullScreenOverlay) {
        this.fullScreenOverlay.open();
        this.opened = true;
      }
    });
  }

  public close(): void {
    this.closeWithDescription({
      reason: DialogClosedReason.PROGRAMMATICALLY
    });
  }

  public isOpen(): boolean {
    return this.fullScreenOverlay ? this.fullScreenOverlay.isOpen() : false;
  }

  public scrollToBottom(): void {
    if (this.contentWrapperElement) {
      ScrollHelper.scrollElementToBottom(this.contentWrapperElement, 'top');
    }
  }

  public setTabDisabled(name: string, disabled: boolean): void {
    this.getRequiredTabCollection().setTabDisabled(name, disabled);
  }

  public selectTab(name: string): void {
    this.getRequiredTabCollection().selectTabByName(name);
  }

  protected typeChanged(): void {
    const conf = RecordItDialog.typeConfigs[this.type];
    this.config = conf ? conf : RecordItDialog.typeConfigs.close;
  }

  protected handleFullScreenOverlayClosed(): void {
    if (this.formController) {
      this.formController.reset();
    }

    DomEventHelper.fireEvent<RecordItDialogClosedEvent>(this.domElement, {
      name: 'record-it-dialog-closed',
      detail: this.lastCloseDescription
    });

    this.opened = false;
  }

  protected handleFullScreenOverlayOpened(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'record-it-dialog-opened',
      detail: null
    });
  }

  protected handleFullScreenOverlayBackdropClicked(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'record-it-dialog-backdrop-clicked',
      detail: null
    });

    if (!this.disableClosingDialog && !this.doNotCloseOnBackdropClick) {
      this.closeWithDescription({
        reason: DialogClosedReason.BACKDROP
      });
    }
  }

  protected getClassNameForButton(button: ButtonConfig): string {
    return `${
      button.className ? button.className : ''
    } record-it-dialog--${StringUtils.upperCaseFirstLetter(button.name)}Button`;
  }

  protected handleButtonClick(button: ButtonConfig): void {
    if (this.isButtonDisabled(this.disableButtons, button)) return;

    DomEventHelper.fireEvent(this.domElement, {
      name: `record-it-dialog-${button.name}-button-clicked`,
      detail: null
    });

    if (this.closeOnButtonClick && !this.disableClosingDialog) {
      this.closeWithDescription({
        reason: DialogClosedReason.BUTTON,
        buttonName: button.name
      });
    }
  }

  protected handleTabTitleClick(tab: Tab): void {
    if (!tab.disabled) {
      this.getRequiredTabCollection().selectTabByName(tab.name);
    }
  }

  protected groupNavigationButtonConfigs(
    navigationButtonConfigs: Array<NavigationButtonConfig>
  ): Array<{
    position: NavigationButtonConfigPosition;
    configs: Array<NavigationButtonConfig>;
  }> {
    const groups: Array<{
      position: NavigationButtonConfigPosition;
      configs: Array<NavigationButtonConfig>;
    }> = [];

    for (const config of navigationButtonConfigs) {
      const group = groups.find((g) => g.position === config.position);

      if (group) {
        group.configs.push(config);
      } else {
        groups.push({
          position: config.position,
          configs: [config]
        });
      }
    }

    return groups;
  }

  protected isButtonDisabled(
    disableButtons: RecordItDialog['disableButtons'],
    button: ButtonConfig
  ): boolean {
    // If disableButtons is set to true/false, disable/enable all buttons respectively
    if (typeof disableButtons === 'boolean') return disableButtons;

    // Otherwise, disable a button if it's included in the list of disabled buttons
    const disabledButtonList = Array.isArray(disableButtons)
      ? disableButtons
      : [disableButtons];
    return disabledButtonList.includes(button.name);
  }

  private closeWithDescription(description: DialogCloseDescription): void {
    this.lastCloseDescription = description;
    this.fullScreenOverlay?.close();
  }

  private updateTabElements(): void {
    assertNotNullOrUndefined(
      this.contentDomElement,
      'contentDomElement not bound'
    );

    const tabElements = Array.from(
      this.contentDomElement.querySelectorAll(
        ':scope > :not(.aurelia-hide)[data-tab]'
      )
    ).filter(
      (element): element is HTMLElement => element instanceof HTMLElement
    );

    this.tabCollection.setTabElements(tabElements);
  }

  private getRequiredTabCollection(): TabCollection {
    assertNotNullOrUndefined(
      this.tabCollection,
      'tabCollection is not available, is the record-it-dialog attached?'
    );
    return this.tabCollection;
  }
}

enum DialogType {
  ACCEPT = 'accept',
  ACCEPT_DECLINE = 'accept-decline',
  ACCEPT_CANCEL = 'accept-cancel',
  CLOSE = 'close',
  CANCEL = 'cancel',
  NO_BUTTONS = 'no-buttons'
}

type DialogConfig = {
  buttons: Array<ButtonConfig>;
};

/**
 * to style the button individually it will receive a css class described in getClassNameForButton
 */
type ButtonConfig = {
  name: string;
  html: string;
  className?: string | null;
};

export type NavigationButtonConfig = {
  /**
   * for programmatic purposes
   * e.g. finding the button in tests
   */
  name: string;
  position: NavigationButtonConfigPosition;
  iconType: IconType;
  iconName: string;
  onClick: () => void;
};

type NavigationButtonConfigPosition = 'left' | 'right';

export enum DialogClosedReason {
  PROGRAMMATICALLY = 'programmatically',
  BUTTON = 'button',
  BACKDROP = 'backdrop'
}

export type RecordItDialogClosedEvent = NamedCustomEvent<
  'record-it-dialog-closed',
  DialogCloseDescription
>;

export type DialogCloseDescription =
  | {
      reason: DialogClosedReason.PROGRAMMATICALLY | DialogClosedReason.BACKDROP;
    }
  | {
      reason: DialogClosedReason.BUTTON;
      buttonName: string;
    };
