import { bindable, inject } from 'aurelia-framework';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(AppEntityManager, SubscriptionManagerService)
export class SendOperationsEmailDialogInvoiceSelect {
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  @bindable processTask = null;

  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  @bindable processTaskGroup = null;

  /** @type {Array<string>} */
  @bindable selectedInvoiceIds = [];

  /** @type {boolean} */
  _attached = false;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskInvoice/types').ProcessTaskInvoice>} */
  _invoices = [];

  /** @type {function(import('../../classes/EntityManager/entities/ProcessTaskInvoice/types').ProcessTaskInvoice): string|null} */
  _categoryIdGetter = (invoice) => invoice.processConfigurationCategoryId;

  _SequenceNumberUtils = SequenceNumberUtils;
  _SequenceNumberType = SequenceNumberType;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this._updateInvoices.bind(this)
    );
    this._updateInvoices();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processTaskChanged() {
    if (this._attached) {
      this._updateInvoices();
    }
  }

  _updateInvoices() {
    if (this.processTask) {
      const relations =
        this._entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskId(
          this.processTask.id
        );
      const invoicesIds = relations.map((r) => r.processTaskInvoiceId);
      this._invoices =
        this._entityManager.processTaskInvoiceRepository.getByIds(invoicesIds);
    } else {
      this._invoices = [];
    }
  }

  /**
   * @param {import('../../inputComponents/custom-checkbox/custom-checkbox').CustomCheckboxCheckedChangedEvent} event
   * @param {import('../../classes/EntityManager/entities/ProcessTaskInvoice/types').ProcessTaskInvoice} invoice
   */
  _handleCheckedChanged(event, invoice) {
    const index = this.selectedInvoiceIds.indexOf(invoice.id);

    if (event.detail.checked) {
      if (index === -1) {
        this.selectedInvoiceIds.push(invoice.id);
      }
    } else {
      if (index >= 0) {
        this.selectedInvoiceIds.splice(index, 1);
      }
    }
  }

  /**
   * @param {string} invoiceId
   * @param {Array<string>} selectedInvoiceIds
   * @param {number} selectedInvoiceIdsLength - just for the view
   */
  _invoiceIsSelected(invoiceId, selectedInvoiceIds, selectedInvoiceIdsLength) {
    return selectedInvoiceIds.indexOf(invoiceId) >= 0;
  }
}
