import { autoinject, observable } from 'aurelia-framework';

import { SocketService } from '../../services/SocketService';

import { FileUtils } from '../../classes/Utils/FileUtils/FileUtils';
import { Dialogs } from '../../classes/Dialogs';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { FileDownloadService } from '../../services/FileDownloadService';

@autoinject()
export class ExportThingsAsCsvDialog {
  private _dialog: RecordItDialog | null = null;

  private _options: IExportThingsAsCsvDialogOptions = {
    thingIds: [],

    filterForProjectIds: [],

    exportThingProperties: false,

    exportProjectPictures: false,
    exportProjectProperties: false,

    exportEntries: false,
    exportEntryProperties: false,

    exportEntryPicture: false,
    exportEntryPictureTags: false,
    exportEntryPictureFiles: false
  };

  @observable private _selectAll: boolean;

  private _loading = false;

  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly _socketService: SocketService
  ) {
    this._selectAll = false;
  }

  public open(options: IExportThingsAsCsvDialogOptions): void {
    Object.assign(this._options, options);
    if (this._dialog) this._dialog.open();
  }

  private _selectAllChanged(): void {
    this._options.exportThingProperties = this._selectAll;

    this._options.exportProjectPictures = this._selectAll;
    this._options.exportProjectProperties = this._selectAll;

    this._options.exportEntries = this._selectAll;
    this._options.exportEntryProperties = this._selectAll;

    this._options.exportEntryPicture = this._selectAll;
    this._options.exportEntryPictureTags = this._selectAll;
    this._options.exportEntryPictureFiles = this._selectAll;
  }

  private _handleCloseDialogClicked(): void {
    if (this._dialog) this._dialog.close();
  }

  private _handleAcceptButtonClicked(): void {
    this._loading = true;
    this._socketService.exportThingsWithContentAsCsvFile(
      this._options,
      (data) => {
        this._loading = false;
        if (this._dialog) this._dialog.close();
        if (data.success) {
          void this.fileDownloadService.downloadFile(
            data.filePath.replace(/\\/g, '/')
          );
        } else {
          const errorMessageKey = data.status
            ? `serverResponses.${data.status}`
            : 'serverResponses.unspecifiedError';
          Dialogs.errorDialogTk('general.error', errorMessageKey);
        }
      }
    );
  }

  public static async open(
    options: IExportThingsAsCsvDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

interface IExportThingsAsCsvDialogOptions {
  thingIds: Array<string>;
  filterForProjectIds?: Array<string>;

  exportThingProperties?: boolean;

  exportProjectPictures?: boolean;
  exportProjectProperties?: boolean;

  exportEntries?: boolean;
  exportEntryProperties?: boolean;

  exportEntryPicture?: boolean;
  exportEntryPictureTags?: boolean;
  exportEntryPictureFiles?: boolean;
}
