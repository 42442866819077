import { EntityInfo } from '@record-it-npm/synchro-common';
import { AppSynchronizationEnvironmentTypes } from '../../../../classes/EntityManager/AppSynchronizationEnvironmentTypes';
import { processTaskOfferEntityInfo } from '../../../../classes/EntityManager/entities/ProcessTaskOffer/processTaskOfferEntityInfo';
import { ProcessTaskOffer } from '../../../../classes/EntityManager/entities/ProcessTaskOffer/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import { UserGroupsWithPermissionComputer } from '../../../../computedValues/computers/UserGroupsWithPermissionComputer/UserGroupsWithPermissionComputer';
import { SubscriptionManagerService } from '../../../SubscriptionManagerService';
import {
  CanEditFieldFieldName,
  EntityAdapter,
  SubscribeOptions
} from '../EntityAdapter';

export class ProcessTaskOfferAdapter
  implements EntityAdapter<ProcessTaskOffer>
{
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly computedValueService: ComputedValueService;

  private editableUserGroupIds: Set<string> = new Set();

  constructor(options: ProcessTaskOfferAdapterOptions) {
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.computedValueService = options.computedValueService;
  }

  public subscribe({ updateBindings }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserGroupsWithPermissionComputer,
        computeData: {},
        callback: ({ editableUserGroupIds }) => {
          this.editableUserGroupIds = editableUserGroupIds;
          updateBindings();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public canDeleteEntity(offer: ProcessTaskOffer): boolean {
    return this.entityIsEditableUserGroup(offer);
  }

  public canEditField(
    offer: ProcessTaskOffer,
    fieldName: CanEditFieldFieldName<ProcessTaskOffer>
  ): boolean {
    if (fieldName === 'acceptedAt') {
      return this.entityIsEditableUserGroup(offer);
    }

    return this.entityIsEditableUserGroup(offer) && !offer.acceptedAt;
  }

  /**
   * can add/remove relations to positions/devices/processTasks
   */
  public canManageRelations(offer: ProcessTaskOffer): boolean {
    return this.entityIsEditableUserGroup(offer) && !offer.acceptedAt;
  }

  public getEntityInfo(): EntityInfo<
    AppSynchronizationEnvironmentTypes['CommonSynchronizationEnvironmentTypes'],
    EntityName.ProcessTaskOffer,
    ProcessTaskOffer
  > {
    return processTaskOfferEntityInfo;
  }

  private entityIsEditableUserGroup(entity: ProcessTaskOffer): boolean {
    return this.editableUserGroupIds.has(entity.ownerUserGroupId);
  }
}

export type ProcessTaskOfferAdapterOptions = {
  subscriptionManagerService: SubscriptionManagerService;
  computedValueService: ComputedValueService;
};
