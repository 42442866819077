import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { TagSortingMode } from 'common/Enums/TagSortingMode';
import { TagHelper } from 'common/EntityHelper/TagHelper';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Tag } from '../../classes/EntityManager/entities/Tag/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TagSorter } from '../../classes/TagSorter';
import { CustomCheckboxCheckedChangedEvent } from '../../inputComponents/custom-checkbox/custom-checkbox';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * A list of tags complete with a search box
 * and the ability to create new tags.
 *
 * @event create-tag-clicked triggered whenever a the "new tag" button has been clicked.
 * @event tag-check-changed triggered whenever a tag is checked or unchecked.
 * @event tag-dropped triggered whenever a tag is dropped onto the list
 *
 * @see select-category-tags-dialog
 */
@autoinject()
export class CategorizedTagList {
  @bindable public tags: Array<Tag> | null = null;

  /**
   * A list of IDs for tags that should be checked on Dialog startup.
   * Use the `to-view` binding only. Listening for changes should be done
   * with the `tag-check-changed` event.
   */
  @bindable public checkedTagIds: Array<string> | null = null;

  protected inputText: string = '';

  private element: HTMLElement;

  protected sortedTags: Array<Tag> = [];
  private tagSortingMode: TagSortingMode = TagSortingMode.UNSORTED;

  private subscriptionManager: SubscriptionManager;

  protected TagDragData = TagDragData;

  protected dragInProgress = false;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.tagSortingMode',
        (tagSortingMode) => {
          this.tagSortingMode = tagSortingMode;
          this.updateSortedTags();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected tagsChanged(): void {
    this.updateSortedTags();
  }

  @computedFrom('sortedTags', 'inputText')
  protected get sortedAndFilteredTags(): Array<Tag> {
    return this.sortedTags.filter((t) =>
      t.name.includes(TagHelper.getTagName(this.inputText))
    );
  }

  private fireCreateTagClickedEvent(): void {
    const tagName = this.inputText.trim();
    if (tagName === '') return;

    DomEventHelper.fireEvent<CreateTagClickedEvent>(this.element, {
      name: 'create-tag-clicked',
      detail: {
        name: tagName
      }
    });

    this.inputText = '';
  }

  protected handleCreateTagClicked(): void {
    this.fireCreateTagClickedEvent();
  }

  protected handleDeleteTagClicked(tag: Tag): void {
    DomEventHelper.fireEvent<DeleteTagClickedEvent>(this.element, {
      name: 'delete-tag-clicked',
      detail: { tag }
    });
  }

  protected handleInputKeyDown(event: KeyboardEvent): boolean {
    if (event.key === 'Enter') {
      this.fireCreateTagClickedEvent();
    }
    return true;
  }

  protected handleTagCheckChanged(
    tag: Tag,
    event: CustomCheckboxCheckedChangedEvent
  ): void {
    DomEventHelper.fireEvent<TagCheckChangedEvent>(this.element, {
      name: 'tag-check-changed',
      detail: {
        tag,
        checked: event.detail.checked
      }
    });
  }

  protected getDragDataForTag(tag: Tag): TagDragData {
    return new TagDragData(tag);
  }

  protected handleActivated(): boolean {
    this.dragInProgress = true;
    return true;
  }

  protected handleDeactivated(): void {
    this.dragInProgress = false;
  }

  protected handleDropTag(dragData: TagDragData): void {
    DomEventHelper.fireEvent<TagDroppedEvent>(this.element, {
      name: 'tag-dropped',
      detail: {
        tag: dragData.tag
      }
    });
  }

  private updateSortedTags(): void {
    this.sortedTags = this.tags
      ? TagSorter.sortTags(this.tags, this.tagSortingMode)
      : [];
  }
}

class TagDragData {
  constructor(public readonly tag: Tag) {}
}

export type CreateTagClickedEvent = NamedCustomEvent<
  'create-tag-clicked',
  { name: string }
>;

export type DeleteTagClickedEvent = NamedCustomEvent<
  'delete-tag-clicked',
  { tag: Tag }
>;

export type TagCheckChangedEvent = NamedCustomEvent<
  'tag-check-changed',
  { tag: Tag; checked: boolean }
>;

export type TagDroppedEvent = NamedCustomEvent<'tag-dropped', { tag: Tag }>;
