import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationMeasurePointType } from './types';

export class ProcessConfigurationMeasurePointTypeRepository extends AppEntityRepository<EntityName.ProcessConfigurationMeasurePointType> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationMeasurePointType> {
    return this.getAll().filter(
      (f) => f.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
