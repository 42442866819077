import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskGroupAuthorization } from './types';

export class ProcessTaskGroupAuthorizationRepository extends AppEntityRepository<EntityName.ProcessTaskGroupAuthorization> {
  public getByProcessTaskGroupId(
    processTaskGroupId: string
  ): Array<ProcessTaskGroupAuthorization> {
    return this.getAll().filter((authorization) => {
      return authorization.ownerProcessTaskGroupId === processTaskGroupId;
    });
  }
}
