import { inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskChecklistEntryDialogService } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/ProcessTaskChecklistEntryDialogService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

@inject(
  AppEntityManager,
  SubscriptionManagerService,
  SocketService,
  ProcessTaskChecklistEntryDialogService
)
export class SendOperationsEmailDialog {
  /** @type {import('../../classes/EntityManager/entities/PersonContact/types').PersonContact|null} */
  _contact = null;
  /** @type {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask|null} */
  _processTask = null;
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup|null} */
  _processTaskGroup = null;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTaskToProject/types').PictureProcessTaskToProject>} */
  _processTaskToProjects = [];
  /** @type {Array<string>} */
  _selectedEntryIds = [];
  /** @type {Array<string>} */
  _selectedOfferIds = [];
  /** @type {Array<string>} */
  _selectedInvoiceIds = [];
  /** @type {Array<string>} */
  _selectedProcessTaskToProjectIds = [];
  /** @type {Array<string>} */
  _deviceExportIds = [];
  /** @type {boolean} */
  _exportMeasurePoints = false;
  /** @type {boolean} */
  _requestInProgress = false;

  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {SocketService} socketService
   * @param {ProcessTaskChecklistEntryDialogService} processTaskChecklistEntryDialogService
   */
  constructor(
    entityManager,
    subscriptionManagerService,
    socketService,
    processTaskChecklistEntryDialogService
  ) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
    this._socketService = socketService;
    this._processTaskChecklistEntryDialogService =
      processTaskChecklistEntryDialogService;
  }

  /**
   * @param {TOpenOptions} options
   */
  open(options) {
    this._contact = options.contact;
    this._processTask = options.processTask;
    this._processTaskGroup =
      this._entityManager.processTaskGroupRepository.getById(
        this._processTask.ownerProcessTaskGroupId
      );

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this._updateProcessTaskToProjects.bind(this)
    );
    this._updateProcessTaskToProjects();

    if (this._dialog) {
      this._dialog.open();
    }
  }

  close() {
    if (this._dialog) {
      this._dialog.close();
    }
  }

  _handleDialogClosed() {
    this._contact = null;
    this._processTask = null;
    this._selectedEntryIds = [];
    this._selectedOfferIds = [];
    this._selectedInvoiceIds = [];
    this._selectedProcessTaskToProjectIds = [];
    this._deviceExportIds = [];
    this._exportMeasurePoints = false;
  }

  async _handleAcceptButtonClick() {
    if (!this._contact || !this._processTask) {
      return;
    }

    await this._checkSelectedInvoicesReadyToDownload();

    this._requestInProgress = true;

    this._socketService.sendOperationsEmail(
      {
        personContactId: this._contact.id,
        processTaskId: this._processTask.id,
        entryIds: this._selectedEntryIds,
        offerIds: this._selectedOfferIds,
        invoiceIds: this._selectedInvoiceIds,
        processTaskToProjectIds: this._selectedProcessTaskToProjectIds,
        deviceExportIds: this._deviceExportIds,
        exportMeasurePoints: this._exportMeasurePoints
      },
      (response) => {
        this._requestInProgress = false;

        if (response.success) {
          this.close();
        } else {
          Dialogs.errorDialogTk(
            'operations.sendOperationsEmailDialog.failedToSendEmail'
          );
        }
      }
    );
  }

  _handleCancelButtonClick() {
    this.close();
  }

  _updateProcessTaskToProjects() {
    if (this._processTask) {
      this._processTaskToProjects =
        this._entityManager.processTaskToProjectRepository.getPictureProcessTaskToProjectsByProcessTaskId(
          this._processTask.id
        );
    } else {
      this._processTaskToProjects = [];
    }
  }

  async _checkSelectedInvoicesReadyToDownload() {
    const invoices = this._entityManager.processTaskInvoiceRepository.getByIds(
      this._selectedInvoiceIds
    );
    const invoiceIds = invoices.map((i) => i.id);
    const invoiceToProcessTasks =
      this._entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceIds(
        invoiceIds
      );
    const processTaskIds = invoiceToProcessTasks.map(
      (r) => r.ownerProcessTaskId
    );
    const processTasks =
      this._entityManager.processTaskRepository.getByIds(processTaskIds);

    await this._processTaskChecklistEntryDialogService.confirmProcessTasksChecklistEntries(
      processTasks
    );
  }

  /**
   * @param {TOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }
}

/**
 * @typedef {Object} TOpenOptions
 * @property {import('../../classes/EntityManager/entities/PersonContact/types').PersonContact} contact
 * @property {import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask} processTask
 */
