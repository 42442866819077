import { autoinject } from 'aurelia-framework';

import { AuthenticationService } from '../../services/AuthenticationService';

@autoinject()
export class accept_usergroup_invitation {
  private token = '';

  protected loading = false;

  constructor(private readonly authenticationService: AuthenticationService) {}

  protected activate(params: { token?: string }): void {
    if (params.token) {
      this.token = params.token;
    }
  }

  protected attached(): void {
    if (this.token) {
      void this.handleAcceptUserGroupInvitationTokenFormSubmit();
    }
  }

  private async handleAcceptUserGroupInvitationTokenFormSubmit(): Promise<void> {
    try {
      this.loading = true;
      await this.authenticationService.acceptUserGroupInvitationToken(
        this.token
      );
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }
}
