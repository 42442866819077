import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type FullSharepointListColumnToThingMappingItemDto<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    ownerThingGroupId: TId;

    sharepointListColumnName: string;

    thingFieldName: string;
    thingTypePropertyId: TId | null;

    synchronizationDirection: SynchronizationDirection;
    mappingType: MappingType;
  };

export type SharepointListColumnToThingMappingItemDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends
    keyof FullSharepointListColumnToThingMappingItemDto<TId, TDate> = never,
  TPropertiesToRemove extends
    keyof FullSharepointListColumnToThingMappingItemDto<TId, TDate> = never
> = Omit<
  OptionalProperties<
    FullSharepointListColumnToThingMappingItemDto<TId, TDate>,
    TOptionalProperties
  >,
  TPropertiesToRemove
>;

export enum SynchronizationDirection {
  SP_TO_THING = 'spToThing',
  THING_TO_SP = 'thingToSp'
}

export enum MappingType {
  THING_FIELD = 'thingField',
  THING_PROPERTY = 'thingProperty'
}
