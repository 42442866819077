import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { EntryToPersonDto } from './EntryToPersonDto';

export function createEntryToPersonEntityInfo<
  TEntity extends EntryToPersonDto<string, string>
>(
  options: CreateEntityInfoOptions<EntryToPersonDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.EntryToPerson,
  TEntity
> {
  return {
    entityName: EntityName.EntryToPerson,
    synchronizeRelativeTo: [EntityName.Project],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      EntryToPersonDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createBaseFieldInfos(),
        entryId: {
          name: 'entryId',
          ref: EntityName.Entry,
          cascadeDeleteLocally: true
        },
        personId: {
          name: 'personId',
          ref: EntityName.Person,
          cascadeDeleteLocally: true
        },
        mainContact: {
          name: 'mainContact',
          defaultValue: false
        },
        coords: {
          name: 'coords',
          innerInfo: {
            latitude: {
              name: 'latitude'
            },
            longitude: {
              name: 'longitude'
            }
          },
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
