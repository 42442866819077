/**
 * just an object to store data which should be globally available
 *
 */

export const GlobalData = {
  thingFilterFunction: function (item) {
    return item.name + ' ' + item.description;
  },

  projectFilterFunction: function (item) {
    return item.name + ' ' + item.description;
  },

  projectSortOptions: {
    name: {
      name: 'Name',
      sortFunction: function (a, b) {
        const aName = a.name ? a.name : 'Berichtname';
        const bName = b.name ? b.name : 'Berichtname';

        return aName.localeCompare(bName);
      }
    },
    created: {
      name: 'Erstelldatum',
      sortFunction: function (a, b) {
        return a.created - b.created;
      }
    },
    updatedAt: {
      name: 'Geändert',
      sortFunction: function (a, b) {
        const aUpdatedAt = a.updatedAt
          ? a.updatedAt
          : new Date(a.created).toISOString();
        const bUpdatedAt = b.updatedAt
          ? b.updatedAt
          : new Date(b.created).toISOString();
        return aUpdatedAt.localeCompare(bUpdatedAt);
      }
    }
  }
};
