import { bindable, inject } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DateUtils } from '../../../../common/src/DateUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * a card which will show the user/created date as a small information on top of the content
 *
 * @slot default
 * @slot footer - footer of the record-it-card, only visible if footerEnabled = true
 */
@inject(AppEntityManager, SubscriptionManagerService)
export class CreatedByUserCard {
  /** @type {(string|null)} */
  @bindable userId = null;

  /**
   * date in the iso format
   *
   * @type {(string|null)}
   */
  @bindable isoDate = null;

  /**
   * enables the footer slot
   *
   * @type {boolean}
   */
  @bindable footerEnabled = false;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {boolean} */
  _attached = false;
  /** @type {import('../../classes/EntityManager/entities/User/types').User|null} */
  _user;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this._updateUser.bind(this)
    );
    this._updateUser();
  }

  detached() {
    this._attached = false;
    this._subscriptionManager.disposeSubscriptions();
  }

  userIdChanged() {
    if (this._attached) {
      this._updateUser();
    }
  }

  _updateUser() {
    if (this.userId) {
      this._user = this._entityManager.userRepository.getById(this.userId);
    } else {
      this._user = null;
    }
  }

  /**
   * @param {(string|null)} isoDate
   * @returns {string}
   */
  _getTimeOfDay(isoDate) {
    return isoDate ? DateUtils.formatToHourMinuteString(isoDate) : '';
  }

  /**
   * @param {(string|null)} isoDate
   * @returns {string}
   */
  _getDate(isoDate) {
    return isoDate ? DateUtils.formatToDateString(isoDate) : '';
  }
}
