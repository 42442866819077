import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { Utils } from '../../classes/Utils/Utils';

/**
 * @event {PictureSelectedEvent} picture-selected
 * @attribute data-no-container-spacing - disable the space between the <picture-scroller> and the first picture
 *
 * @replaceable picture-item-overlay
 * A way to display additional content on top of the picture. The content must be positioned absolutely.
 *
 * available variables
 * | type | name | description |
 * |------|------|-------------|
 * | Picture | picture | the displayed picture |
 */
@autoinject()
export class PictureScroller {
  @bindable()
  public pictures: Array<Picture> = [];

  @bindable()
  public highlightedPictures: Array<Picture> = [];

  /**
   * Normally a spacer will be added at the end of the pictures, so they can be scrolled to the center
   */
  @bindable()
  public hideCenterScrollSpacer: boolean = false;

  protected containerElement: HTMLElement | null = null;

  constructor(private readonly element: Element) {}

  public scrollToPicture(picture: Picture): void {
    if (!this.containerElement) return;

    const pictureElement: HTMLElement | null = this.element.querySelector(
      `#${this.getPictureElementId(picture.id)}`
    );
    if (!pictureElement) return;

    const selectionOffset = Utils.getElementOffset(this.containerElement);
    const pictureOffset = Utils.getElementOffset(pictureElement);
    const selectionWidth = parseInt(
      window.getComputedStyle(this.containerElement).width || '0'
    );
    const pictureWidth = parseInt(
      window.getComputedStyle(pictureElement).width || '0'
    );
    ScrollHelper.scrollElement(
      this.containerElement,
      'left',
      pictureOffset.left -
        selectionOffset.left -
        selectionWidth / 2 +
        pictureWidth / 2
    );
  }

  protected handlePictureClick(picture: Picture): void {
    DomEventHelper.fireEvent<PictureSelectedEvent>(this.element, {
      name: 'picture-selected',
      detail: {
        picture
      }
    });
  }

  protected getPictureElementId(pictureId: string): string {
    return `picture-scroller--Picture-${pictureId}`;
  }

  protected pictureIsHighlighted(
    picture: Picture,
    highlightedPictures: Array<Picture>
  ): boolean {
    return highlightedPictures.includes(picture);
  }
}

export type PictureSelectedEvent = NamedCustomEvent<
  'picture-selected',
  { picture: Picture }
>;
