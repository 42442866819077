import { isEqual } from 'lodash';

import { autoinject, bindable } from 'aurelia-framework';

import { DefectStatus, getAllDefectStatuses } from 'common/Enums/DefectStatus';
import { LatLongArea } from 'common/Types/LatLongArea';
import { DateUtils } from 'common/DateUtils';
import { PropertyFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPicturePropertyFilter';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { Defect } from '../../../classes/EntityManager/entities/Defect/types';
import { Tag } from '../../../classes/EntityManager/entities/Tag/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ExportStartedEvent } from '../gallery-thing-picture-filter/gallery-thing-picture-filter';
import { FilterBarConfiguration } from '../../../services/ViaFilterBarConfigurationService/ViaFilterBarConfigurationService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { Utils } from '../../../classes/Utils/Utils';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { GalleryThingFilterHelper } from '../../../classes/GalleryThing/GalleryThingFilterHelper';
import { MoreButtonChoice } from '../../../aureliaComponents/more-button/more-button';
import { Dialogs } from '../../../classes/Dialogs';
import { SocketService } from '../../../services/SocketService';
import { FileDownloadService } from '../../../services/FileDownloadService';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

/**
 * Component that allows the user to set a filter for defects.
 *
 * @event filter-changed {FilterChangedEvent} triggered whenever the filter is edited.
 * @event export-started {ExportStartedEvent} triggered when a export is started from the export panel.
 */
@autoinject()
export class GalleryThingDefectFilter {
  @bindable public thing: Thing | null = null;

  @bindable public ownerUserGroupId: string | null = null;

  /**
   * The currently active defect management filter.
   * Should only be read from.
   */
  @bindable public filter: DefectManagementFilter = this.createEmptyFilter();

  @bindable public selectedDefects: Array<Defect> = [];

  protected filterIsEmpty: boolean = true;

  /**
   * These need to be connected to the latitude-longitude-chooser so that the filter can be reset.
   */
  protected latitude: number | null = null;
  protected longitude: number | null = null;
  protected distance: number | null = null;

  protected isExportPanelOpen = false;

  private element: HTMLElement;

  protected GalleryThingFilterHelper = GalleryThingFilterHelper;

  protected readonly moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'export-defects-to-csv',
      labelTk: 'galleryThing.defectExportPanel.exportAsCsvFile',
      iconClass: 'fal fa-file-csv'
    }
  ];

  /**
   * The data format of the filterBarConfig is consistent to its Via pendant but is currently only used for the property widgets
   * the other filters are not configurable for the moment
   */
  private filterBarConfiguration: FilterBarConfiguration | null = null;
  private subscriptionManager: SubscriptionManager;

  private fireFilterChangedRateLimited = Utils.rateLimitFunction(
    this.fireFilterChanged.bind(this),
    10
  );

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    element: Element,
    private fileDownloadService: FileDownloadService,
    subscriptionManagerService: SubscriptionManagerService,
    private activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        context: this,
        entityName: EntityName.Thing,
        expression: 'thing'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'defectManagement.propertyFilterBarConfiguration',
        (configuration) => {
          this.filterBarConfiguration = configuration;
          this.updateProperties();
        }
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * Creates a defect filter matching all defects.
   *
   * The `visibleStatus` array is sorted to make deep filter comparisons possible.
   */
  private createEmptyFilter(): DefectManagementFilter {
    return {
      dateFrom: null,
      dateTo: null,
      latLongArea: null,
      visibleStatuses: getAllDefectStatuses()
        .filter((s) => s !== DefectStatus.DONE)
        .sort((a, b) => a.localeCompare(b)),
      assigneeId: null,
      tags: [],
      properties: []
    };
  }

  private fireFilterChanged(): void {
    this.updateFilterIsEmpty();

    DomEventHelper.fireEvent<FilterChangedEvent>(this.element, {
      name: 'filter-changed',
      detail: { filter: this.filter }
    });
  }

  private updateFilterIsEmpty(): void {
    // sort array in order for the `isEqual` comparison to work
    this.filter.visibleStatuses.sort((a, b) => a.localeCompare(b));

    const { properties, ...filterWithoutProperties } = this.filter;
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { properties: emptyProperties, ...emptyFilterWithoutProperties } =
      this.createEmptyFilter();

    this.filterIsEmpty =
      isEqual(filterWithoutProperties, emptyFilterWithoutProperties) &&
      properties.every((p) => !GalleryThingFilterHelper.isActiveFilter(p));
  }

  private updateProperties(): void {
    if (!this.filterBarConfiguration?.widgets) return;
    const propertyFilterWidgets = this.filterBarConfiguration.widgets.filter(
      (widget) => widget.type === 'property'
    );

    this.filter.properties = GalleryThingFilterHelper.updateProperties(
      propertyFilterWidgets,
      this.filter.properties
    );
  }

  protected handlePropertyWidgetValueChanged(): void {
    this.fireFilterChangedRateLimited();
  }

  protected parseDateFromDateTimePickerValue(value: string): Date | null {
    return DateUtils.parseDateFromIsoString(value);
  }

  /**
   * Updates filter properties.
   *
   * Apparently the Aurelia two-way binding doesn't want to connect the template to properties of the `filter` object,
   * which would be way easier than having event handlers. Fuck Aurelia and its two-way binding, when it works, it only
   * works in confusing ways, and when it doesn't, it makes me consider a career change to turd farmer.
   */
  protected handleFilterUpdate(
    filterUpdate: Partial<DefectManagementFilter>
  ): void {
    this.filter = {
      ...this.filter,
      ...filterUpdate
    };

    this.fireFilterChangedRateLimited();
  }

  protected handleResetFilterClick(): void {
    this.filter = this.createEmptyFilter();
    this.latitude = null;
    this.longitude = null;
    this.distance = null;
    this.updateProperties();
    this.fireFilterChanged();
  }

  protected handleExportButtonClick(): void {
    this.isExportPanelOpen = true;
  }

  protected handleExportStarted(event: ExportStartedEvent): void {
    DomEventHelper.fireEvent<ExportStartedEvent>(this.element, {
      name: 'export-started',
      detail: event.detail
    });
  }

  protected handleExportDefectsToCsv(): void {
    const defectIds = this.selectedDefects.map((defect) => {
      return defect.id;
    });
    Dialogs.waitDialogTk('galleryThing.defectExportPanel.csvExportStarted');

    this.socketService.exportDefectsAsCsvFile(defectIds, (response) => {
      if (response.success) {
        Dialogs.closeAllDialogs();
        void this.fileDownloadService.downloadFile(
          response.filePath.replace(/\\/g, '/')
        );
      } else {
        void Dialogs.errorDialogTk(
          'galleryThing.defectExportPanel.csvExportFailed',
          response.error && response.error.message ? response.error.message : ''
        );
      }
    });
  }
}

export type DefectManagementFilter = {
  dateFrom: Date | null;
  dateTo: Date | null;
  latLongArea: LatLongArea | null;
  visibleStatuses: Array<DefectStatus>;
  assigneeId: string | null;
  tags: Array<Tag>;
  properties: Array<PropertyFilter>;
};

export type FilterChangedEvent = NamedCustomEvent<
  'filter-changed',
  { filter: DefectManagementFilter }
>;
