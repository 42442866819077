import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { Status } from '../../classes/RequestWithStatus';
import { SocketService } from '../../services/SocketService';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';

@autoinject()
export class SharepointExportSettingsWidget {
  @bindable public userGroup: UserGroup | null = null;

  @bindable public enabled = false;

  protected readonly checkSharepointUrlAccessRequestStatus;
  protected readonly checkFolderExistenceRequestStatus;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    requestWithStatusService: RequestWithStatusService
  ) {
    this.checkSharepointUrlAccessRequestStatus =
      requestWithStatusService.createRequestWithStatus(async () => {
        if (!this.userGroup) return Status.NO_STATUS;

        const siteUrl =
          this.userGroup.sharepointCredentials?.sharepointExportSite;
        if (!siteUrl) return Status.NO_STATUS;

        const response =
          await this.socketService.sharepointSocketEndpoints.checkSharepointAccess(
            {
              userGroupId: this.userGroup.id,
              siteUrl
            }
          );

        if (response.success && response.hasAccess) return Status.OK;

        return Status.ERROR;
      });

    this.checkFolderExistenceRequestStatus =
      requestWithStatusService.createRequestWithStatus(async () => {
        if (!this.userGroup) return Status.NO_STATUS;

        const siteUrl =
          this.userGroup.sharepointCredentials?.sharepointExportSite;
        const folderPath =
          this.userGroup.sharepointCredentials?.sharepointExportPath;
        if (!siteUrl || !folderPath) return Status.NO_STATUS;

        const response =
          await this.socketService.sharepointSocketEndpoints.folderExists({
            userGroupId: this.userGroup.id,
            siteUrl,
            folderPath
          });

        if (response.success && response.folderExists) return Status.OK;

        return Status.ERROR;
      });
  }

  protected attached(): void {
    this.checkSharepointUrlAccess();
    this.checkFolderExistence();
  }

  protected handleSharepointUrlChanged(): void {
    this.handleUserCompanySettingChanged();

    this.checkSharepointUrlAccess();
    this.checkFolderExistence();
  }

  protected handleSharepointFolderPathChanged(): void {
    this.handleUserCompanySettingChanged();
    this.checkFolderExistence();
  }

  private handleUserCompanySettingChanged(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      'cannot update without a user group'
    );
    this.entityManager.userGroupRepository.update(this.userGroup);
  }

  private checkSharepointUrlAccess(): void {
    this.checkSharepointUrlAccessRequestStatus.startRequest();
  }

  private checkFolderExistence(): void {
    this.checkFolderExistenceRequestStatus.startRequest();
  }
}
