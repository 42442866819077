import { autoinject } from 'aurelia-framework';

import {
  ExportType,
  TExportProjectResponse
} from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';

import { SocketService } from '../../../../services/SocketService';
import { Dialogs } from '../../../Dialogs';
import { Project } from './types';
import { FileDownloadService } from '../../../../services/FileDownloadService';

@autoinject()
export class ExportProjectService {
  constructor(
    private readonly fileDownloadService: FileDownloadService,
    private readonly socketService: SocketService
  ) {}

  public async exportProject(
    project: Project,
    type: ExportType
  ): Promise<{ success: boolean; reportId?: string | null }> {
    return new Promise((resolve, reject) => {
      const responseHandler = (response: TExportProjectResponse): void => {
        if (response.success) {
          resolve(response);
        } else {
          reject(response);
        }
      };

      if (type === 'pdf') {
        this.socketService.exportProject(
          {
            projectId: project.id,
            timestampMs: Date.now(),
            exportType: ExportType.PDF
          },
          responseHandler
        );
      } else if (type === 'docx') {
        this.socketService.exportProject(
          {
            projectId: project.id,
            timestampMs: Date.now(),
            exportType: ExportType.DOCX
          },
          responseHandler
        );
      }
    });
  }

  public exportProjectPictures(project: Project): void {
    Dialogs.waitDialog();

    this.socketService.exportPicturesZIPFile(project.id, (response) => {
      if (response.success) {
        Dialogs.closeAllDialogs();
        void this.fileDownloadService.downloadFile(response.filepath);
      } else {
        Dialogs.warningDialog('Datei konnte leider nicht gefunden werden!');
      }
    });
  }
}
