import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { StructureTemplateStatus } from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { Utils } from 'common/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { StructureTemplate } from './types';
import { StructureTemplateEntry } from '../StructureTemplateEntry/types';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from '../StructureTemplateEntryGroupToStructureTemplateEntry/types';

@autoinject()
export class StructureTemplateCopyService {
  constructor(private readonly entityManager: AppEntityManager) {}

  public copyStructureTemplate({
    structureTemplate
  }: {
    structureTemplate: StructureTemplate;
  }): StructureTemplate {
    const newStructureTemplate =
      this.entityManager.structureTemplateRepository.create({
        ...structureTemplate,
        name: structureTemplate.name + ' (Kopie)',
        oldTemplateName: null,
        status: StructureTemplateStatus.DRAFT
      });

    const { groupIdMapping } = this.copyGroups({
      structureTemplate,
      newStructureTemplate
    });

    const entriesByParentId = Utils.groupValues(
      this.entityManager.structureTemplateEntryRepository.getByStructureTemplateId(
        structureTemplate.id
      ),
      (entry) => entry.parentEntryId
    );

    const entryGroupRelationsByEntryId = Utils.groupValues(
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.getAll(),
      (relation) => relation.structureTemplateEntryId
    );

    const { entryIdMapping } = this.copyStructureTemplateEntries({
      entriesByParentId,
      newStructureTemplate,
      groupIdMapping,
      entryGroupRelationsByEntryId,
      entries: entriesByParentId.get(null) ?? [],
      parentEntry: null
    });

    const { propertyIdMapping } = this.copyProperties({
      structureTemplate,
      newStructureTemplate,
      entryIdMapping
    });

    this.copyRatingCategories({
      structureTemplate,
      newStructureTemplate,
      propertyIdMapping
    });

    return newStructureTemplate;
  }

  private copyGroups({
    structureTemplate,
    newStructureTemplate
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
  }): { groupIdMapping: IdMapping } {
    const groupIdMapping = new IdMapping();

    const groups =
      this.entityManager.structureTemplateEntryGroupRepository.getByStructureTemplateId(
        structureTemplate.id
      );
    for (const group of groups) {
      const newGroup =
        this.entityManager.structureTemplateEntryGroupRepository.create({
          ...group,
          ownerStructureTemplateId: newStructureTemplate.id
        });

      groupIdMapping.set(group.id, newGroup.id);
    }

    return { groupIdMapping };
  }

  private copyStructureTemplateEntries({
    entriesByParentId,
    entries,
    parentEntry,
    newStructureTemplate,
    groupIdMapping,
    entryGroupRelationsByEntryId
  }: {
    entriesByParentId: StructureTemplateEntriesByParentId;
    entries: Array<StructureTemplateEntry>;
    parentEntry: StructureTemplateEntry | null;
    newStructureTemplate: StructureTemplate;
    groupIdMapping: IdMapping;
    entryGroupRelationsByEntryId: StructureTemplateEntryGroupRelationsByEntryId;
  }): { entryIdMapping: IdMapping } {
    const entryIdMapping = new IdMapping();

    for (const entry of entries) {
      const newEntry =
        this.entityManager.structureTemplateEntryRepository.create({
          ...entry,
          ownerStructureTemplateId: newStructureTemplate.id,
          parentEntryId: parentEntry?.id || null,
          structureTemplateEntryGroupId: entry.structureTemplateEntryGroupId
            ? groupIdMapping.get(entry.structureTemplateEntryGroupId)
            : null
        });

      entryIdMapping.set(entry.id, newEntry.id);

      this.copyGroupRelations({
        entry,
        entryGroupRelationsByEntryId,
        newEntry,
        groupIdMapping
      });

      const childrenCopyResult = this.copyStructureTemplateEntries({
        entriesByParentId,
        entries: entriesByParentId.get(entry.id) ?? [],
        parentEntry: newEntry,
        newStructureTemplate,
        groupIdMapping,
        entryGroupRelationsByEntryId
      });

      entryIdMapping.mergeIntoSelf(childrenCopyResult.entryIdMapping);
    }

    return { entryIdMapping };
  }

  private copyGroupRelations({
    entry,
    newEntry,
    entryGroupRelationsByEntryId,
    groupIdMapping
  }: {
    entry: StructureTemplateEntry;
    newEntry: StructureTemplateEntry;
    entryGroupRelationsByEntryId: StructureTemplateEntryGroupRelationsByEntryId;
    groupIdMapping: IdMapping;
  }): void {
    const relations = entryGroupRelationsByEntryId.get(entry.id) ?? [];

    for (const relation of relations) {
      this.entityManager.structureTemplateEntryGroupToStructureTemplateEntryRepository.create(
        {
          ownerUserGroupId: newEntry.ownerUserGroupId,
          structureTemplateEntryGroupId: groupIdMapping.get(
            relation.structureTemplateEntryGroupId
          ),
          structureTemplateEntryId: newEntry.id
        }
      );
    }
  }

  private copyProperties({
    structureTemplate,
    newStructureTemplate,
    entryIdMapping
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
    entryIdMapping: IdMapping;
  }): { propertyIdMapping: IdMapping } {
    const propertyIdMapping = new IdMapping();

    const properties =
      this.entityManager.structureTemplateEntryPropertyRepository.getByOwnerStructureTemplateId(
        structureTemplate.id
      );
    for (const property of properties) {
      const newProperty =
        this.entityManager.structureTemplateEntryPropertyRepository.create({
          ...property,
          structureTemplateEntryId: property.structureTemplateEntryId
            ? entryIdMapping.get(property.structureTemplateEntryId)
            : null,
          ownerStructureTemplateId: newStructureTemplate.id
        });

      propertyIdMapping.set(property.id, newProperty.id);
    }

    return { propertyIdMapping };
  }

  private copyRatingCategories({
    structureTemplate,
    newStructureTemplate,
    propertyIdMapping
  }: {
    structureTemplate: StructureTemplate;
    newStructureTemplate: StructureTemplate;
    propertyIdMapping: IdMapping;
  }): void {
    const ratingCategories =
      this.entityManager.structureTemplateRatingCategoryRepository.getByStructureTemplateId(
        structureTemplate.id
      );
    for (const category of ratingCategories) {
      this.entityManager.structureTemplateRatingCategoryRepository.create({
        ...category,
        structureTemplateEntryPropertyId:
          category.structureTemplateEntryPropertyId
            ? propertyIdMapping.get(category.structureTemplateEntryPropertyId)
            : null,
        ownerStructureTemplateId: newStructureTemplate.id
      });
    }
  }
}

type StructureTemplateEntriesByParentId = Map<
  string | null,
  Array<StructureTemplateEntry>
>;
type StructureTemplateEntryGroupRelationsByEntryId = Map<
  string,
  Array<StructureTemplateEntryGroupToStructureTemplateEntry>
>;

/**
 * a map from the previous id to the new id
 */
class IdMapping {
  private map = new Map<string, string>();

  public get(previousId: string): string {
    const newId = this.map.get(previousId);
    assertNotNullOrUndefined(newId, `no new id found for "${previousId}"`);

    return newId;
  }

  public set(previousId: string, newId: string): void {
    this.map.set(previousId, newId);
  }

  public mergeIntoSelf(otherIdMapping: IdMapping): void {
    Utils.mergeMaps(this.map, otherIdMapping.map);
  }
}
