import { Vector } from 'common/Geometry/Vector';
import { AngleHelper } from 'common/Geometry/AngleHelper';

import { ElementPositionInformation } from '../../../../classes/Geometry/ElementPositionInformation';
import {
  HandleDragOptions,
  RepositionableElementDraggingHandler
} from '../RepositionableElementDraggingHandler';

export class RepositionableElementRotateDraggingHandler
  implements RepositionableElementDraggingHandler
{
  private readonly element: HTMLElement;
  private readonly controlPosition: string;
  private readonly elementPositionInfo: ElementPositionInformation;

  constructor(options: RepositionableElementRotateDraggingHandlerOptions) {
    this.element = options.element;
    this.controlPosition = options.controlPosition;
    this.elementPositionInfo = new ElementPositionInformation(options.element);
  }

  public handleDrag({ pointerPosition }: HandleDragOptions): RotateDragResult {
    this.elementPositionInfo.recalculate();

    const rotationDiff = this.calculateRotationDiff(pointerPosition);
    const topLeftPointCentered = this.elementPositionInfo.topLeftPoint
      .clone()
      .substractVector(this.elementPositionInfo.centerPoint);

    topLeftPointCentered.rotate(rotationDiff);

    const topLeftPoint = this.elementPositionInfo.centerPoint
      .clone()
      .addVector(topLeftPointCentered);

    return {
      top: topLeftPoint.getY(),
      left: topLeftPoint.getX(),
      rotation: AngleHelper.simplifyDegAngle(
        this.elementPositionInfo.rotation + rotationDiff
      )
    };
  }

  /**
   * returns the difference in deg
   */
  private calculateRotationDiff(pointerPosition: Vector): number {
    const controlCornerPoint = this.getControlCornerPoint();
    if (!this.element.offsetParent || !controlCornerPoint) {
      return 0;
    }

    const offsetBounding = this.element.offsetParent.getBoundingClientRect();
    const offsetCenter = this.elementPositionInfo.centerPoint
      .clone()
      .addXY(offsetBounding.left, offsetBounding.top);
    const pointerVector = pointerPosition.clone().substractVector(offsetCenter);

    const cornerVector = controlCornerPoint
      .clone()
      .substractVector(this.elementPositionInfo.centerPoint);

    const pointerAngle = pointerVector.getAngle() ?? 0;
    const cornerAngle = cornerVector.getAngle() ?? 0;

    return pointerAngle - cornerAngle;
  }

  private getControlCornerPoint(): Vector | null {
    switch (this.controlPosition) {
      case 'top-left':
        return this.elementPositionInfo.topLeftPoint;

      case 'top-right':
        return this.elementPositionInfo.topRightPoint;

      case 'bottom-right':
        return this.elementPositionInfo.bottomRightPoint;

      case 'bottom-left':
        return this.elementPositionInfo.bottomLeftPoint;

      default:
        return null;
    }
  }
}

export type RepositionableElementRotateDraggingHandlerOptions = {
  element: HTMLElement;

  initialPointerPosition: Vector;
  /**
   * position of the control which started the dragging, possible values: 'top-left', 'top-right', 'bottom-right', 'bottom-left'
   */
  controlPosition: string;
};

export type RotateDragResult = {
  top: number;
  left: number;

  /**
   * in deg
   */
  rotation: number;
};
