import { StorageTableConfig } from './storeInfo';

export abstract class AbstractStorageStrategy {
  public abstract init(): Promise<void>;

  public abstract migrate(): Promise<void>;

  public abstract getItem(key: string, tableName: string): Promise<any>;

  public abstract getItems(tableName: string): Promise<Array<any>>;

  public abstract getItemsWithKeys(tableName: string): Promise<Array<KeyValue>>;

  public abstract setItem(
    key: string,
    value: any,
    tableName: string
  ): Promise<void>;

  public abstract setItems(
    items: Array<KeyValue>,
    tableName: string
  ): Promise<void>;

  public abstract removeItem(key: string, tableName: string): Promise<void>;

  public abstract removeItems(
    key: Array<string>,
    tableName: string
  ): Promise<void>;

  public abstract getKeys(tableName: string): Promise<Array<string>>;

  public abstract clear(tableName: string): Promise<void>;

  public async clearMany(
    tables: Array<StorageTableConfig>,
    ignoreKeepOnClearAll: boolean
  ): Promise<void> {
    const clearPromises: Array<Promise<void>> = [];
    for (const table of tables) {
      if (
        ignoreKeepOnClearAll ||
        !('keepOnClearAll' in table) ||
        !table.keepOnClearAll
      ) {
        clearPromises.push(this.clear(table.name));
      }
    }
    await Promise.all(clearPromises);
  }
}

export type KeyValue = {
  key: string;
  value: any;
};
