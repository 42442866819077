import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskDeviceDto } from './ProcessTaskDeviceDto';

export function createProcessTaskDeviceEntityInfo<
  TEntity extends ProcessTaskDeviceDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskDeviceDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskDevice,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskDevice,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskDeviceDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        processConfigurationDeviceId: {
          name: 'processConfigurationDeviceId',
          ref: EntityName.ProcessConfigurationDevice
        },
        processConfigurationDeviceExportId: {
          name: 'processConfigurationDeviceExportId',
          ref: EntityName.ProcessConfigurationDeviceExport,
          defaultValue: null
        },
        snapshotOfProcessTaskDeviceId: {
          name: 'snapshotOfProcessTaskDeviceId',
          ref: EntityName.ProcessTaskDevice,
          defaultValue: null
        },
        dateFrom: {
          name: 'dateFrom',
          defaultValue: null
        },
        dateFromUserId: {
          name: 'dateFromUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        dateTo: {
          name: 'dateTo',
          defaultValue: null
        },
        dateToUserId: {
          name: 'dateToUserId',
          ref: EntityName.User,
          defaultValue: null
        },
        dailyCost: {
          name: 'dailyCost',
          defaultValue: null
        },
        plannedDuration: {
          name: 'plannedDuration',
          defaultValue: null
        },
        discount: {
          name: 'discount',
          defaultValue: 0
        },
        discountNote: {
          name: 'discountNote',
          defaultValue: null
        },
        noteInternal: {
          name: 'noteInternal',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
