import { computedFrom } from 'aurelia-framework';

import { Entry } from '../../../classes/EntityManager/entities/Entry/types';
import { EntryAdapter } from '../EntityAdapter/EntryAdapter/EntryAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class EntryPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<EntryAdapter>,
  Entry
> {
  @computedFrom('combinedRevision')
  public get canEditPictures(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditPictures');
  }

  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }

  @computedFrom('combinedRevision')
  public get canCreateEntryToPersons(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateEntryToPersons');
  }

  @computedFrom('combinedRevision')
  public get canCreateTextBricks(): boolean {
    return this.checkAdapterSpecificPermissionByName('canCreateTextBricks');
  }
}
