import { DateUtils } from 'common/DateUtils';

export class ProcessTaskAppointmentUtils {
  public static isInPastAndNotFinished(
    finishedAt: string | null,
    dateTo: string | null
  ): boolean {
    if (dateTo == null) {
      return false;
    }

    const currentDate = new Date().toISOString();
    return !finishedAt && dateTo < currentDate;
  }

  public static overlapsWithTimestampRange(
    appointmentDateInfo: {
      dateFrom: string | null;
      dateTo: string | null;
    } | null,
    timestampRange: TimeStampRange
  ): boolean {
    if (!appointmentDateInfo?.dateFrom || !appointmentDateInfo.dateTo)
      return false;

    return DateUtils.dateRangesOverlap(
      {
        from: new Date(appointmentDateInfo.dateFrom),
        to: new Date(appointmentDateInfo.dateTo)
      },
      { from: new Date(timestampRange.from), to: new Date(timestampRange.to) }
    );
  }
}

export type TimeStampRange = {
  from: number;
  to: number;
};
