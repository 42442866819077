import { autoinject } from 'aurelia-dependency-injection';

import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { ValueContainer } from 'common/ValueContainer';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { Property } from '../../../classes/EntityManager/entities/Property/types';
import { Utils } from '../../../classes/Utils/Utils';
import { ValueComputer } from '../ValueComputer';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { EntityName } from '../../../classes/EntityManager/entities/types';

@autoinject()
export class PropertyCacheComputer extends ValueComputer<
  PropertyCacheComputerComputeData,
  PropertyCache
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): PropertyCache {
    return new PropertyCache({
      entityManager: this.entityManager
    });
  }

  public computeDataAreEqual(): boolean {
    return true;
  }
}

export class PropertyCache {
  private readonly entityManager;

  private readonly entryPropertyCache: CacheRoot;

  private readonly defectPropertyCache: CacheRoot;

  constructor(options: { entityManager: AppEntityManager }) {
    this.entityManager = options.entityManager;

    this.entryPropertyCache = new Map();
    this.defectPropertyCache = new Map();
    this.fillPropertyCaches();
  }

  public getEntryPropertyValue({
    entryId,
    propertyType,
    propertyName
  }: {
    entryId: string;
    propertyType: PropertyType | null;
    propertyName: string | null;
  }): string | null {
    const typeToValueByName = this.entryPropertyCache.get(entryId);
    const valueByName = typeToValueByName?.get(
      PropertyHelper.getTypeOrDefault(propertyType)
    );
    return valueByName?.get(propertyName ?? '') ?? null;
  }

  public getDefectPropertyValue({
    defectId,
    propertyType,
    propertyName
  }: {
    defectId: string;
    propertyType: PropertyType | null;
    propertyName: string | null;
  }): string | null {
    const typeToValueByName = this.defectPropertyCache.get(defectId);
    const valueByName = typeToValueByName?.get(
      PropertyHelper.getTypeOrDefault(propertyType)
    );
    return valueByName?.get(propertyName ?? '') ?? null;
  }

  public getEntryPropertyValueIncludingFalsyValues({
    entryId,
    propertyType,
    propertyName
  }: {
    entryId: string;
    propertyType: PropertyType | null;
    propertyName: string | null;
  }): ValueContainer<null | string> | null {
    const typeToValueByName = this.entryPropertyCache.get(entryId);
    const valueByName = typeToValueByName?.get(
      PropertyHelper.getTypeOrDefault(propertyType)
    );

    const value = valueByName?.get(propertyName ?? '');
    if (value === undefined) return null;
    return new ValueContainer(value);
  }

  private fillPropertyCaches(): void {
    const allProperties = this.entityManager.propertyRepository.getAll();
    const propertiesByEntryId = Utils.groupBy(
      allProperties,
      (property) => property.entry
    );
    const propertiesByDefectId = Utils.groupBy(
      allProperties,
      (property) => property.ownerDefectId
    );

    for (const [entryId, properties] of propertiesByEntryId.entries()) {
      this.setPropertyCacheEntry(entryId, properties, this.entryPropertyCache);
    }

    for (const [defectId, properties] of propertiesByDefectId.entries()) {
      this.setPropertyCacheEntry(
        defectId,
        properties,
        this.defectPropertyCache
      );
    }
  }

  private setPropertyCacheEntry(
    entityId: string | null | undefined,
    properties: Array<Property>,
    cache: CacheRoot
  ): void {
    if (!entityId) return;

    cache.set(entityId, this.createTypeToValueByNameCache({ properties }));
  }

  private createTypeToValueByNameCache({
    properties: allProperties
  }: {
    properties: Array<Property>;
  }): PropertyTypeToPropertyValueByName {
    const propertiesByType = Utils.groupBy(allProperties, (property) =>
      PropertyHelper.getTypeOrDefault(property.type)
    );
    const typeToValueByName: PropertyTypeToPropertyValueByName = new Map();

    for (const [type, properties] of propertiesByType.entries()) {
      typeToValueByName.set(type, this.createValueByNameCache({ properties }));
    }

    return typeToValueByName;
  }

  private createValueByNameCache({
    properties
  }: {
    properties: Array<Property>;
  }): PropertyValueByName {
    const valueByName: PropertyValueByName = new Map();

    for (const property of properties) {
      valueByName.set(property.name ?? '', property.value);
    }

    return valueByName;
  }
}

export type PropertyCacheComputerComputeData = Record<string, never>;

type CacheRoot = Map<string, PropertyTypeToPropertyValueByName>;
type PropertyTypeToPropertyValueByName = Map<PropertyType, PropertyValueByName>;
type PropertyValueByName = Map<string, string | null>;
