import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * Dropdown list for choosing a user.
 * User selection can optionally be restricted to a certain user group.
 *
 * @event value-changed
 */
@autoinject()
export class UserSelect {
  /**
   * The userId of the currently selected user.
   */
  @bindable public value: string | null = null;

  @bindable public enabled = false;

  /**
   * Limit the persons to select via the user group.
   */
  @bindable public userGroupId: string | null = null;

  /**
   * The string to display if no value is selected.
   */
  @bindable public nullOption: string | null = null;

  /**
   * The label to display for the dropdown.
   * Setting this to null shows the default label.
   */
  @bindable public label: string | null = null;

  /**
   * Additional options to show for the dropdown.
   * Are displayed BEFORE all other options.
   */
  @bindable public additionalOptions: Array<UserSelectOption> = [];

  protected options: Array<UserSelectOption> = [];

  private userGroup: UserGroup | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached = false;

  private readonly element: HTMLElement;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.updateOptions.bind(this)
    );
    this.updateOptions();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateUserGroup.bind(this)
    );
    this.updateUserGroup();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// OBSERVABLES /////////////

  protected userGroupIdChanged(): void {
    if (this.isAttached) {
      this.updateUserGroup();
    }
  }

  // /////////// UPDATERS /////////////

  private updateUserGroup(): void {
    let userGroup: UserGroup | null = null;

    if (this.userGroupId) {
      userGroup =
        this.entityManager.userGroupRepository.getById(this.userGroupId) ??
        null;
    }

    if (userGroup !== this.userGroup) {
      this.userGroup = userGroup;
      this.updateOptions();
    }
  }

  private updateOptions(): void {
    const userOptions = this.entityManager.userRepository
      .getAll()
      .filter((user) => {
        // If userGroupId is defined, only return users which have an id in the corresponding userGroup
        if (this.userGroupId && this.userGroup) {
          return !!this.userGroup.userSpecs.find((s) => s._id === user.id);
        } else return true;
      })
      .map((user) => ({
        label: user.username,
        value: user.id
      }));
    this.options = [...this.additionalOptions, ...userOptions];
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleSelectChanged(): void {
    if (this.element) {
      DomEventHelper.fireEvent(this.element, {
        name: 'value-changed',
        detail: { value: this.value }
      });
    }
  }
}

export interface UserSelectOption {
  value: string;
  label: string;
}
