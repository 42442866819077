import {
  CallAddedData,
  PhoneSystemSocketMessage,
  PhoneSystemSocketMessageType
} from 'common/Types/PhoneSystemSocketMessages';

import { OperationsPhoneCallDialog } from '../operationsComponents/operations-phone-call-dialog/operations-phone-call-dialog';
import { Dialogs } from './Dialogs';

export class AsteriskAmiSocketEventListener {
  public listener(socketMessageData: PhoneSystemSocketMessage): void {
    switch (socketMessageData.type) {
      case PhoneSystemSocketMessageType.CALL_ADDED:
        this.handleCallAdded(socketMessageData.data);
        break;
      case PhoneSystemSocketMessageType.AUTH_FAIL:
        Dialogs.warningDialogTk(
          'operations.phoneSystem.connectionErrorTitle',
          'operations.phoneSystem.authenticationError'
        );
        break;
      case PhoneSystemSocketMessageType.AUTH_SUCCESS:
        this.log(
          'Authentication successful. Current connection status (Server -> Phone System): Connected.'
        );
        break;
      case PhoneSystemSocketMessageType.REGISTRATION_SUCCESS:
        const connectionStatus =
          socketMessageData.data === true ? 'Connected.' : 'Not connected.';
        this.log(
          'Client is registered and ready to receive events. Current connection status (Server -> Phone System): ' +
            connectionStatus
        );
        break;
      default:
        throw new Error(
          'Unknown asterisk ami message name: ' + socketMessageData['type']
        );
    }
  }

  private handleCallAdded(callData: CallAddedData): void {
    void OperationsPhoneCallDialog.open({
      phoneNumber: callData.call.callerNumber,
      processTaskInfos: callData.processTaskInfos
    });
  }

  private log(msg: string): void {
    console.log('PHONE SYSTEM: ' + msg);
  }
}
