import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { GalleryThingPictureFilter as PictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ScrollHelper } from '../../../classes/ScrollHelper';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import {
  GalleryThingJoinedProjectsService,
  TGalleryThingJoinedProjectsServiceThingInfoDateRange
} from '../../../services/GalleryThingJoinedProjectsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { GalleryThingDateRangeSelectionDialog } from '../../gallery-thing-date-range-selection-dialog/gallery-thing-date-range-selection-dialog';
import { GalleryThingPictureExportPanel } from '../../gallery-thing-picture-export-panel/gallery-thing-picture-export-panel';
import { GalleryThingPictureOverviewEntry } from '../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { GalleryThingFilterResetEvent } from '../../gallery-thing-picture-filter-bar/gallery-thing-picture-filter-bar';

/**
 * Via filter for the gallery-thing-picture-overview-and-export.
 *
 * @event export-started triggered when an export is started.
 * @event gallery-thing-filter-reset triggered when the filter reset button is clicked
 */
@autoinject()
export class GalleryThingPictureFilter {
  @bindable public editable = false;

  @bindable public thing: Thing | null = null;

  @bindable public pictureFilter: PictureFilter | null = null;

  @bindable
  public selectedPictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];

  @bindable public exportPanelVisible: boolean = false;

  @bindable public showFilterOnly = false;

  protected selectedLastDaysRange: number | null = null;

  protected thingDateRangeText: string | null = null;

  protected exportPanelElement: HTMLElement | null = null;

  protected exportPanelViewModel: GalleryThingPictureExportPanel | null = null;

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly i18n: I18N,
    protected readonly galleryThingJoinedProjectsService: GalleryThingJoinedProjectsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // /////////// LIFECYCLE /////////////

  protected attached(): void {
    this.subscriptionManager.subscribeToEvent(
      'gallery-thing-joined-projects-service:thing-info-changed',
      this.updateDateRangeInfo.bind(this)
    );
    this.updateDateRangeInfo();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // /////////// METHODS /////////////

  private updateDateRangeInfo(): void {
    this.selectedLastDaysRange = this.thing
      ? this.galleryThingJoinedProjectsService.getLastDaysRange(this.thing.id)
      : null;
    this.updateThingDateRangeText();
  }

  private updateThingDateRangeText(): void {
    if (!this.thing) {
      this.thingDateRangeText = null;
      return;
    }
    const dateRanges = this.galleryThingJoinedProjectsService.getDates(
      this.thing.id
    );
    const firstDateRange = dateRanges[0];

    this.thingDateRangeText = firstDateRange
      ? this.generateThingDateRangeText(firstDateRange, dateRanges)
      : null;
  }

  private generateThingDateRangeText(
    displayedDateRange: ThingInfoDateRange,
    availableDateRanges: Array<ThingInfoDateRange>
  ): string {
    let text = ' + ';

    if (displayedDateRange.from === displayedDateRange.to) {
      text += DateUtils.formatToDateString(displayedDateRange.to);
    } else {
      text +=
        DateUtils.formatToDateString(displayedDateRange.from) +
        ' - ' +
        DateUtils.formatToDateString(displayedDateRange.to);
    }

    const remainingDatesCount = availableDateRanges.length - 1; // -1 because of the displayed date range
    if (remainingDatesCount > 0) {
      text += ` ${this.translate(
        'additionalDatesPrefix'
      )} ${remainingDatesCount} `;
      text +=
        remainingDatesCount === 1
          ? this.translate('additionalDatesSuffixSingular')
          : this.translate('additionalDatesSuffixPlural');
      text += '.';
    }

    return text;
  }

  private translate(key: string): string {
    return this.i18n.tr('galleryThing.pictureOverviewAndExport.' + key);
  }

  // /////////// OBSERVABLES /////////////

  protected thingChanged(): void {
    this.updateDateRangeInfo();
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleLastDaysRangeSelectChanged(): void {
    assertNotNullOrUndefined(
      this.thing,
      'cannot handleLastDaysRangeSelectChanged without a thing'
    );
    this.galleryThingJoinedProjectsService.setLastDaysRange(
      this.thing.id,
      this.selectedLastDaysRange
    );
  }

  protected handleEditDateRangeDetailClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "Can't edit date range without a thing"
    );
    void GalleryThingDateRangeSelectionDialog.open(this.thing.id);
  }

  protected handleShowExportPanelClick(): void {
    assertNotNullOrUndefined(
      this.exportPanelElement,
      'cannot show the export panel without an ExportPanelElement!'
    );
    assertNotNullOrUndefined(
      this.exportPanelViewModel,
      'cannot show the export panel without an ExportPanelViewModel!'
    );

    this.exportPanelViewModel.expand();
    const exportPanelElement = this.exportPanelElement;
    setTimeout(() => {
      void ScrollHelper.scrollToItem(exportPanelElement, null, {
        topOffset: 100
      });
    }, 50); // timeout to wait for the element to be rendered (it's display is none while hidden)
  }

  protected handleExportPanelExportStarted(event: ExportStartedEvent): void {
    assertNotNullOrUndefined(
      this.exportPanelViewModel,
      'cannot handle an export without an ExportPanelViewModel!'
    );
    void this.exportPanelViewModel.collapse();

    DomEventHelper.fireEvent<ExportStartedEvent>(this.element, {
      name: 'export-started',
      detail: event.detail
    });
  }

  protected handleGalleryThingFilterReset(): void {
    DomEventHelper.fireEvent<GalleryThingFilterResetEvent>(this.element, {
      name: 'gallery-thing-filter-reset',
      detail: null
    });
  }
}

export type ExportStartedEvent = NamedCustomEvent<
  'export-started',
  { reportId: string | null }
>;

type ThingInfoDateRange = TGalleryThingJoinedProjectsServiceThingInfoDateRange;
