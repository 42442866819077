import { autoinject, bindable } from 'aurelia-framework';

import {
  AvailableSearchResultEntities,
  EntityNameToSearchResultMap
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';

import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

/**
 * @event navigate-to-entity-clicked
 */
@autoinject()
export class GlobalSearchResultListItem<
  T extends AvailableSearchResultEntities
> {
  @bindable public item: EntityNameToSearchResultMap[T] | null = null;

  @bindable public itemProperties: Array<ItemProperty> = [];

  @bindable public colorBarColor: string | null = null;

  protected panelOpen = false;

  private domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleNavigateToEntityClick(): void {
    DomEventHelper.fireEvent<NavigateToEntityClickedEvent>(this.domElement, {
      name: 'navigate-to-entity-clicked',
      detail: null
    });
  }
}

export type ItemProperty = {
  labelTk: string;
  value: string | null;
};

export type NavigateToEntityClickedEvent = NamedCustomEvent<
  'navigate-to-entity-clicked',
  null
>;
