import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { IPictureMarking } from 'common/Types/Entities/Picture/PictureDto';
import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { SocketService } from '../../services/SocketService';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Dialogs } from '../../classes/Dialogs';
import { PictureCreatorService } from '../../classes/Picture/PictureCreatorService';
import { PictureFullScreenOverlay } from '../picture-full-screen-overlay/picture-full-screen-overlay';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';
import { FilesDroppedEvent } from '../../picture/picture-with-selection-layout/picture-with-selection-layout';
import { PicturesCreatedEvent } from '../picture-upload-buttons/picture-upload-buttons';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import {
  Picture,
  PictureEntityIdField,
  PictureSubEntityField
} from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { MoreButtonChoice } from '../more-button/more-button';
import { EditStructurePictureAreasOverlay } from '../../structurePictureAreaComponents/edit-structure-picture-areas-overlay/edit-structure-picture-areas-overlay';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { FileDownloadService } from '../../services/FileDownloadService';
import { AdditionalMarkingsChangedEvent } from '../../picture/additional-marked-pictures-list/additional-marked-pictures-list';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PictureRevisionDialog } from '../../pictureRevisionComponents/picture-revision-dialog/picture-revision-dialog';

/**
 * @slot detailContent
 * @slot detailContentMobile
 */
@autoinject()
export class PictureSelectAndEdit {
  /**
   * entry is still needed sometimes for the (optional) picture-marking-widget
   */
  @bindable public entry: Entry | null = null;

  @bindable public mainEntityIdField: PictureEntityIdField | null = null;

  @bindable public mainEntityId: string | null = null;

  @bindable public subEntityField: PictureSubEntityField | null = null;

  @bindable public subEntityValue: any = null;

  @bindable public ownerUserGroupId: string | null = null;

  @bindable public ownerProjectId: string | null = null;

  @bindable public canCreatePictures = true;

  /**
   * this is meant to be just a readOnly bindable (I haven't found a way to implement this in aurelia)
   * so you should refrain from changing this property externally
   */
  @bindable public selectedPicture: Picture | null = null;

  /**
   * set this to true if the user will see the description and can edit it
   */
  @bindable public showDescription = false;

  @bindable public showDetails = true;

  @bindable public showSelectedCheckbox = true;
  /**
   * if set to true, the picture-tags-widget will be shown
   */
  @bindable public supportsTags: boolean = true;

  @bindable public supportsPictureRevision: boolean = false;

  /**
   * READONLY!
   */
  @bindable public shownPicture: Picture | null = null;

  @bindable public showDetailContentSlot = false;

  @subscribableLifecycle()
  protected readonly shownPicturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  protected pictures: Array<Picture> = [];

  protected isEditing = false;

  protected isOnline = true;

  protected isApp = DeviceInfoHelper.isApp();

  protected showPictureUploadAndTakenDateExplicitly = false;

  protected parentPicture: Picture | null = null;

  protected siblingPictureMarkings: Array<IPictureMarking> = [];

  private canEditStructurePictureAreas: boolean = false;

  private userCanUsePictureRevision: boolean = false;

  private subscriptionManager: SubscriptionManager;

  private socketService: SocketService;

  private pictureSketchingService: PictureSketchingService;

  constructor(
    private readonly i18n: I18N,
    private readonly fileDownloadService: FileDownloadService,
    private readonly entityManager: AppEntityManager,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    socketService: SocketService,
    pictureSketchingService: PictureSketchingService,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.socketService = socketService;
    this.pictureSketchingService = pictureSketchingService;

    this.shownPicturePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Picture,
        context: this as PictureSelectAndEdit,
        propertyName: 'shownPicture'
      });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.structurePictureAreas',
        (canEditStructurePictureAreas) => {
          this.canEditStructurePictureAreas = !!canEditStructurePictureAreas;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.canUsePictureRevision',
        (canUsePictureRevision) => {
          this.userCanUsePictureRevision = !!canUsePictureRevision;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.showPictureUploadAndTakenDateExplicitly',
        (setting) => {
          this.showPictureUploadAndTakenDateExplicitly = setting;
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictures.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateParentPicture.bind(this)
    );
    this.updatePictures();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  protected mainEntityIdChanged(): void {
    this.updatePictures();
  }

  protected subEntityValueChanged(): void {
    this.updatePictures();
  }

  protected updatePictures(): void {
    if (this.mainEntityIdField && this.mainEntityId) {
      this.pictures = this.entityManager.pictureRepository.getByEntityId(
        this.mainEntityIdField,
        this.mainEntityId,
        this.subEntityField,
        this.subEntityValue
      );
    } else {
      this.pictures = [];
    }

    // there is no selected picture if you just show the globalProjectPicture
    if (this.subEntityField !== 'is_global_project_picture') {
      const currentlySelectedPicture = this.pictures.find((p) => p.selected);
      const firstPicture = this.pictures[0];
      if (!currentlySelectedPicture && firstPicture) {
        this.entityManager.pictureRepository.setSelectedPicture(
          firstPicture,
          this.pictures
        );
      }
      this.selectedPicture = this.pictures.find((p) => p.selected) || null;
    } else {
      this.selectedPicture = null;
    }

    if (!this.shownPicture || this.pictures.indexOf(this.shownPicture) === -1) {
      if (this.subEntityField === 'is_global_project_picture') {
        this.setShownPicture(this.pictures[0] ?? null);
      } else {
        // show the selected picture if the shownPicture is not set or not able to be selected
        this.setShownPicture(
          this.selectedPicture
            ? this.selectedPicture
            : (this.pictures[0] ?? null)
        );
      }
    }

    this.updateParentPicture();
    this.updateSiblingPictureMarkings();
  }

  protected updateParentPicture(): void {
    if (this.entry && this.entry.page_depth_parent) {
      this.parentPicture =
        this.entityManager.pictureRepository.getSelectedEntryPicture(
          this.entry.page_depth_parent
        );
    } else {
      this.parentPicture = null;
    }
  }

  protected updateSiblingPictureMarkings(): void {
    if (this.entry) {
      const siblings = this.entityManager.entryRepository.getSiblingsOfEntry(
        this.entry
      );

      const siblingPictureMarkings: Array<IPictureMarking> = [];

      for (const sibling of siblings) {
        const picture =
          this.entityManager.pictureRepository.getSelectedEntryPicture(
            sibling.id
          );
        if (
          picture &&
          sibling.id !== this.entry.id &&
          picture.marking &&
          picture.marking.top != null &&
          picture.marking.left != null
        ) {
          siblingPictureMarkings.push(picture.marking);
        }
      }

      this.siblingPictureMarkings = siblingPictureMarkings;
    } else {
      this.siblingPictureMarkings = [];
    }
  }

  protected handlePictureSelectionPictureClick(picture: Picture): void {
    this.setShownPicture(picture);
  }

  protected handleSelectedPictureClicked(): void {
    if (!this.shownPicture) return;

    void PictureFullScreenOverlay.open({
      picture: this.shownPicture,
      noPictureText: this.noPictureText
    });
  }

  protected handleDeleteShownPictureClick(): void {
    const shownPicture = this.shownPicture;
    if (!shownPicture) return;

    const index = this.pictures.indexOf(shownPicture);
    void Dialogs.deleteEntityDialog(shownPicture).then(() => {
      this.entityManager.pictureRepository.delete(shownPicture);

      let newShownPicture = null;
      if (index + 1 < this.pictures.length) {
        newShownPicture = this.pictures[index + 1] ?? null;
      } else if (index - 1 >= 0) {
        newShownPicture = this.pictures[index - 1] ?? null;
      }

      if (newShownPicture && shownPicture.selected) {
        newShownPicture.selected = true;
      }

      this.setShownPicture(newShownPicture);
    });
  }

  protected handleSketchPictureClick(): void {
    if (!this.shownPicture) return;
    this.pictureSketchingService.sketchPicture(this.shownPicture);
  }

  protected handleDownloadMarkedPicturesClick(): void {
    if (!this.mainEntityIdField || !this.mainEntityId) return;

    Dialogs.waitDialog();
    this.socketService.exportMarkedPictures(
      {
        mainEntityIdField: this.mainEntityIdField,
        mainEntityId: this.mainEntityId,
        subEntityField: this.subEntityField,
        subEntityValue: this.subEntityValue
      },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          void Dialogs.errorDialogTk(
            'general.error',
            `serverResponses.exportMarkedPictures.${response.status}`
          );
        }
      }
    );
  }

  protected handleEditStructurePictureAreasClicked(): void {
    assertNotNullOrUndefined(
      this.shownPicture,
      "can't PictureSelectAndEdit.handleEditStructurePictureAreasClicked without shownPicture"
    );

    if (!this.shownPicture.titleThingId && !this.shownPicture.globalThingId) {
      throw new Error(
        'PictureSelectAndEdit.handleEditStructurePictureAreasClicked only works for Title and GlobalThingPictures'
      );
    }

    void EditStructurePictureAreasOverlay.open({
      picture: this.shownPicture
    });
  }

  protected handleOpenPictureRevisionClick(): void {
    assertNotNullOrUndefined(
      this.shownPicture,
      "can't PictureSelectAndEdit.handleOpenPictureRevisionClick without shownPicture"
    );

    void PictureRevisionDialog.open({ picture: this.shownPicture });
  }

  protected handleStartEditingSelectedPictureClick(): void {
    this.isEditing = true;
  }

  protected handlePictureEditorStopped(): void {
    this.isEditing = false;
  }

  protected handlePictureIsSelectedCheckboxChange(event: CustomEvent): void {
    if (this.shownPicture) {
      // why this strange if construct? to prevent that there is no picture selected or 2 are selected

      // only do something with the old picture if it is different from the current one so we don't send two updates for the same thing
      const oldSelectedPicture: Picture | null =
        this.shownPicture !== this.selectedPicture
          ? this.selectedPicture
          : null;

      if (oldSelectedPicture) {
        oldSelectedPicture.selected = false;
      }

      this.shownPicture.selected = event.detail.checked;
      this.entityManager.pictureRepository.update(this.shownPicture);

      if (oldSelectedPicture) {
        this.entityManager.pictureRepository.update(oldSelectedPicture);
      }

      this.selectedPicture = this.shownPicture;
    }
  }

  protected handleAdditionalMarkingsChanged(
    event: AdditionalMarkingsChangedEvent
  ): void {
    if (this.shownPicture) {
      this.handlePictureChanged({
        ...this.shownPicture,
        additional_markings: event.detail.additionalMarkings
      });
    } else {
      const ownerUserGroupId = this.ownerUserGroupId;
      const mainEntityIdField = this.mainEntityIdField;
      const mainEntityId = this.mainEntityId;

      if (!ownerUserGroupId || !mainEntityIdField || !mainEntityId)
        throw new Error(
          'cannot create a picture without the required fields: mainEntityIdField, mainEntityId and ownerUserGroupId'
        );

      const picture = this.pictureCreatorService
        .withEntityInfos(() => ({
          ownerUserGroupId,
          ownerProjectId: this.ownerProjectId,
          mainEntityIdField,
          mainEntityId,
          subEntityField: this.subEntityField,
          subEntityValue: this.subEntityValue
        }))
        .createWhitePicture({
          excludeFromExport: true,
          additional_markings: event.detail.additionalMarkings
        });

      this.setShownPicture(picture);
    }
  }

  protected handlePictureChanged(picture: Picture): void {
    this.entityManager.pictureRepository.update(picture);
  }

  protected handlePicturesCreated(event: PicturesCreatedEvent): void {
    const pictures = event.detail.pictures;
    if (pictures.length) this.setShownPicture(pictures[0] ?? null);
  }

  protected setShownPicture(picture: Picture | null): void {
    this.shownPicture = picture;
  }

  protected formatToDateWithTimeString(timestamp: number): string {
    return DateUtils.formatToDateWithTimeString(timestamp);
  }

  private async handleFilesDropped(event: FilesDroppedEvent): Promise<void> {
    const pictures = await this.pictureCreatorService
      .withEntityInfos(() => {
        if (
          !this.mainEntityIdField ||
          !this.mainEntityId ||
          !this.ownerUserGroupId
        ) {
          throw new Error(
            `can't create a picture without the required fields: mainEntityIdField "${this.mainEntityIdField}"  mainEntityId "${this.mainEntityId}"  ownerUserGroupId "${this.ownerUserGroupId}"`
          );
        }

        return {
          mainEntityIdField: this.mainEntityIdField,
          mainEntityId: this.mainEntityId,
          subEntityField: this.subEntityField,
          subEntityValue: this.subEntityValue,
          ownerProjectId: this.ownerProjectId,
          ownerUserGroupId: this.ownerUserGroupId
        };
      })
      .createPicturesFromFiles(event.detail.files);
    if (pictures.length > 0)
      this.setShownPicture(pictures[pictures.length - 1] ?? null);
  }

  @computedFrom('pictures.length', 'isOnline')
  private get cannotDownloadMarkedPictures(): boolean {
    return this.pictures.length <= 1 || !this.isOnline;
  }

  @computedFrom('shownPicture')
  private get cannotEditStructurePictureAreas(): boolean {
    return !this.shownPicture;
  }

  @computedFrom('pictures.length')
  protected get noPictureText(): string {
    return this.pictures.length === 0
      ? this.i18n.tr('general.noPictureAvailable')
      : this.i18n.tr('general.noPictureSelected');
  }

  @computedFrom(
    'canEditStructurePictureAreas',
    'shownPicture.titleThingId',
    'shownPicture.globalThingId',
    'canUsePictureRevision'
  )
  protected get moreButtonChoices(): Array<MoreButtonChoice> {
    const moreButtonChoices: Array<MoreButtonChoice> = [
      {
        name: 'download-marked-pictures',
        labelTk:
          'aureliaComponents.pictureSelectAndEdit.downloadMarkedPictures',
        disabledContext: this,
        disabledPropertyName: 'cannotDownloadMarkedPictures'
      }
    ];

    const isThingPicture =
      this.shownPicture &&
      (!!this.shownPicture.titleThingId || !!this.shownPicture.globalThingId);
    if (isThingPicture && this.canEditStructurePictureAreas) {
      moreButtonChoices.push({
        name: 'edit-structure-picture-areas',
        labelTk:
          'aureliaComponents.pictureSelectAndEdit.editStructurePictureAreas',
        disabledContext: this,
        disabledPropertyName: 'cannotEditStructurePictureAreas'
      });
    }

    if (
      this.shownPicture &&
      this.supportsPictureRevision &&
      this.userCanUsePictureRevision
    ) {
      moreButtonChoices.push({
        name: 'open-picture-revision',
        labelTk: 'aureliaComponents.pictureSelectAndEdit.openPictureRevision',
        disabledContext: this
      });
    }

    return moreButtonChoices;
  }
}
