export class GoogleMapsSearchUrlValueConverter {
  /**
   * @param {string} value
   * @returns {string}
   */
  toView(value) {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      value
    )}`;
  }
}
