import { AnyEntry } from '../entries/AnyEntry';
import { CustomDirectoryEntry } from '../entries/CustomDirectoryEntry';
import { CustomFileEntry } from '../entries/CustomFileEntry';

export abstract class FileUtilsAdapter {
  public async resolveFilePath(path: string): Promise<CustomFileEntry> {
    const entry = await this.resolvePath(path);
    return entry.asFile();
  }

  public async resolveDirectoryPath(
    path: string
  ): Promise<CustomDirectoryEntry> {
    const entry = await this.resolvePath(path);
    return entry.asDirectory();
  }

  public abstract resolvePath(path: string): Promise<AnyEntry>;

  public abstract convertFileSrc(src: string): Promise<string>;
}
