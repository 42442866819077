import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingTypeDto } from './ThingTypeDto';

export function createThingTypeEntityInfo<
  TEntity extends ThingTypeDto<string, string>
>(
  options: CreateEntityInfoOptions<ThingTypeDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingType,
  TEntity
> {
  return {
    entityName: EntityName.ThingType,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingTypeDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        usergroup: {
          name: 'usergroup',
          ref: EntityName.UserGroup,
          cascadeDeleteLocally: true
        },
        importOnThingCreation: {
          name: 'importOnThingCreation',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
