import { DtoUtils, ParentEntityInfo } from '../../BaseEntities/DtoUtils';
import { createGeneralFileEntityInfo } from './createGeneralFileEntityInfo';
import { GeneralFileDto } from './GeneralFileDto';

export class GeneralFileDtoUtils {
  private static readonly generalFileEntityInfo = createGeneralFileEntityInfo({
    additionalFieldInfos: []
  });

  private constructor() {}

  public static getParentEntityInfo({
    generalFileDto
  }: {
    generalFileDto: GeneralFileDto<string, string>;
  }): ParentEntityInfo {
    return DtoUtils.getParentEntityInfo<GeneralFileDto<string, string>>({
      dto: generalFileDto,
      parentEntityIdFieldNames: [
        'processTaskAppointmentId',
        'processTaskCommentId',
        'processTaskChecklistEntryId',
        'propertyId',
        'mapLayerId',
        'defectCommentId'
      ],
      entityInfo: this.generalFileEntityInfo
    });
  }
}
