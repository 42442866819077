import { EventAggregator } from 'aurelia-event-aggregator';
import { HooksContainer } from '@record-it-npm/synchro-client';

import { PictureHelper } from 'common/EntityHelper/PictureHelper';
import {
  UploadPictureFileRequest,
  UploadPictureFileResponse
} from 'common/EndpointTypes/UploadFileEndpointsHandler';
import { UploadItemRescueData } from 'common/EndpointTypes/DataRescueEndpointsHandler';
import { PictureFileExtension } from 'common/Types/Entities/PictureFile/PictureFileDto';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import {
  AbstractUploadStrategy,
  ModificationType,
  ModifyUploadItems
} from '../../../services/FileUploadService';
import { IUtilsRateLimitedFunction, Utils } from '../../Utils/Utils';
import { FileUtils } from '../../Utils/FileUtils/FileUtils';
import { ImageHelper } from '../../ImageHelper';
import { SocketService } from '../../../services/SocketService';
import { AppEntityManager } from '../../EntityManager/entities/AppEntityManager';
import { Disposable } from '../../Utils/DisposableContainer';
import { EntityName } from '../../EntityManager/entities/types';
import { PictureFilePathService } from '../../EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFile } from '../../EntityManager/entities/PictureFile/types';
import { OriginalIdUtils } from '../../EntityManager/utils/OriginalIdUtils/OriginalIdUtils';
import { PictureFilePathConverter } from './PictureFilePathConverter';
import { UrlManager } from '../../UrlManager';
import { EventAggregatorPromiseHelper } from '../../Promise/EventAggregatorPromiseHelper';

export class PictureFileUploadStrategy extends AbstractUploadStrategy<IUploadItemData> {
  private readonly entityManager: AppEntityManager;
  private readonly pictureFilePathService: PictureFilePathService;
  private readonly socketService: SocketService;
  private readonly eventAggregator: EventAggregator;
  private readonly hooksContainer: HooksContainer<PictureUploadHooks> =
    new HooksContainer();

  constructor(options: {
    entityManager: AppEntityManager;
    pictureFilePathService: PictureFilePathService;
    socketService: SocketService;
    eventAggregator: EventAggregator;
  }) {
    super();

    this.entityManager = options.entityManager;
    this.pictureFilePathService = options.pictureFilePathService;
    this.socketService = options.socketService;
    this.eventAggregator = options.eventAggregator;
  }

  public getName(): string {
    return 'pictureFileUploadStrategy';
  }

  public subscribeToEvents(
    modifyUploadItems: ModifyUploadItems<IUploadItemData>
  ): Array<Disposable> {
    return [
      this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        EntityName.PictureFile,
        (pictureFile) => {
          this.handlePictureFileIdUpdated(pictureFile, modifyUploadItems);
        }
      ),
      this.entityManager.pictureFileRepository.registerHooks({
        afterEntityDeleted: (pictureFile) => {
          this.handlePictureFileDeleted(pictureFile, modifyUploadItems);
        },
        afterEntityRemovedLocally: (pictureFile) => {
          if (pictureFile.onlyLocal) {
            this.handlePictureFileDeleted(pictureFile, modifyUploadItems);
          }
        }
      })
    ];
  }

  public updateUploadItemData(data: IUploadItemData): boolean {
    const pictureFile =
      this.entityManager.pictureFileRepository.getByOriginalId(
        data.pictureFileId
      );

    if (pictureFile) {
      data.pictureFileId = pictureFile.id;
      return true;
    }

    data.filePath =
      data.filePath != null
        ? PictureFilePathConverter.getPathRelativeToLocalDirectory(
            data.filePath
          )
        : null;

    return false;
  }

  public itemIsReadyToBeUploaded(data: IUploadItemData): boolean {
    // if no pictureFile is found (e.g. the project has been unsubscribed) then we just try to upload the file anyway
    const pictureFile = this.entityManager.pictureFileRepository.getById(
      data.pictureFileId
    );
    if (pictureFile && pictureFile.onlyLocal) {
      return false;
    }

    // if no picture is found (e.g. the project has been unsubscribed) then we just try to upload the file anyway
    const picture = pictureFile
      ? this.entityManager.pictureRepository.getById(pictureFile.picture)
      : null;
    if (picture && picture.onlyLocal) {
      return false;
    }

    const idsToCheck = [
      data.pictureFileId,
      ...(pictureFile?.originalIds ?? [])
    ];

    for (const id of idsToCheck) {
      if (
        this.entityManager.entitySynchronization.entityIsInTheQueue(
          EntityName.PictureFile,
          id
        )
      )
        return false;
    }

    return true;
  }

  public uploadItemsAreSimilar(
    a: IUploadItemData,
    b: IUploadItemData
  ): boolean {
    return a.pictureFileId === b.pictureFileId;
  }

  public async uploadUploadItem(
    data: IUploadItemData,
    chunkedFileId: string
  ): Promise<void> {
    const requestData: UploadPictureFileRequest = {
      pictureFileId: data.pictureFileId,
      chunkedFileId: chunkedFileId
    };

    this.addFileExtensionToUploadRequest(requestData, data);

    const response =
      await EventAggregatorPromiseHelper.createConnectedPromise<UploadPictureFileResponse>(
        this.eventAggregator,
        new Promise((resolve) => {
          this.socketService.uploadImageFile(requestData, (res) => {
            resolve(res);
          });
        })
      );

    await this.processUploadResponse(data, response);
  }

  public async getRescueData(
    data: IUploadItemData
  ): Promise<UploadItemRescueData> {
    const pictureFile = this.entityManager.pictureFileRepository.getById(
      data.pictureFileId
    );
    return {
      entityId: data.pictureFileId,
      entityName: EntityName.PictureFile,
      fileExtension: pictureFile?.file_extension ?? PictureFileExtension.JPG,
      dataUrl: await this.getFileDataUrl(data)
    };
  }

  public async getFileDataUrl(data: IUploadItemData): Promise<string> {
    if (data.dataUrl) {
      return data.dataUrl;
    } else if (data.filePath) {
      const filePath = PathUtils.joinPaths(
        UrlManager.localFolder,
        data.filePath
      );
      return await FileUtils.readFilePathAsDataUrl(filePath);
    } else {
      throw new Error('item has no dataUrl or filePath');
    }
  }

  public registerHooks(hooks: PictureUploadHooks): Disposable {
    return this.hooksContainer.registerHooks(hooks);
  }

  private handlePictureFileIdUpdated(
    pictureFile: PictureFile,
    modifyUploadItems: ModifyUploadItems<IUploadItemData>
  ): void {
    const originalIds = new Set(
      OriginalIdUtils.getOriginalIdsForEntity(pictureFile)
    );
    modifyUploadItems((data) => {
      if (originalIds.has(data.pictureFileId)) {
        data.pictureFileId = pictureFile.id;
        return ModificationType.MODIFIED;
      }

      return ModificationType.NONE;
    });
  }

  private handlePictureFileDeleted(
    pictureFile: PictureFile,
    modifyUploadItems: ModifyUploadItems<IUploadItemData>
  ): void {
    modifyUploadItems((data) => {
      if (data.pictureFileId === pictureFile.id) {
        return ModificationType.DELETE;
      }

      return ModificationType.NONE;
    });
  }

  private addFileExtensionToUploadRequest(
    request: UploadPictureFileRequest,
    data: IUploadItemData
  ): void {
    let extension = null;

    if (data.dataUrl) {
      extension = ImageHelper.getFileExtensionForDataUrl(data.dataUrl);
    } else if (data.filePath) {
      extension = Utils.getExtensionFromPath(data.filePath);
    } else {
      throw new Error('item has no dataUrl or filePath');
    }

    if (extension) {
      request.fileExtension = extension as PictureFileExtension;
    }
  }

  private async processUploadResponse(
    data: IUploadItemData,
    response: UploadPictureFileResponse
  ): Promise<void> {
    if (response.success) {
      if (data.filePath) {
        await this.moveUploadedFile(data);
      }
      this.hooksContainer.callHooks('afterFileWasUploaded');
    } else {
      throw new Error('cannot process unsuccessful upload response');
    }
  }

  private async moveUploadedFile(data: IUploadItemData): Promise<void> {
    if (data.filePath) {
      const pictureFile = this.entityManager.pictureFileRepository.getById(
        data.pictureFileId
      );
      const picture = pictureFile
        ? this.entityManager.pictureRepository.getById(pictureFile.picture)
        : null;
      const mainEntityInfo = picture
        ? PictureHelper.getMainEntityInfo<string>(picture)
        : null;

      if (
        pictureFile &&
        mainEntityInfo &&
        (mainEntityInfo.name !== 'project' ||
          this.entityManager.joinedProjectsManager.projectIsJoined(
            mainEntityInfo.id
          ))
      ) {
        await this.movePictureFileToMainEntityFolder(data, pictureFile);
      } else if (pictureFile) {
        pictureFile.isOriginatingHere = false;
        this.entityManager.pictureFileRepository.updateLocally(pictureFile);
        await FileUtils.deleteEntry(data.filePath);
      }
    }
  }

  /**
   * move file to the main entity folder to prevent the app from downloading it (unnecessarily) again
   */
  private async movePictureFileToMainEntityFolder(
    data: IUploadItemData,
    pictureFile: PictureFile
  ): Promise<void> {
    if (!data.filePath) {
      throw new Error("can't move an upload item without a filePath");
    }

    const filePath = PathUtils.joinPaths(UrlManager.localFolder, data.filePath);
    await FileUtils.copyFile(
      filePath,
      this.pictureFilePathService.getRelativeLocalPicPath({ pictureFile })
    );

    pictureFile.isOriginatingHere = false;
    pictureFile.local_created = pictureFile.file_created || 0;
    this.entityManager.pictureFileRepository.updateLocally(pictureFile);

    await FileUtils.deleteEntry(filePath);
  }
}

interface IUploadItemData {
  pictureFileId: string;
  dataUrl: string | null;

  /**
   * filePath should be relative to the UrlManager.localFolder
   */
  filePath: string | null;
}

export type PictureUploadHooks = {
  afterFileWasUploaded?:
    | (() => Promise<void>)
    | IUtilsRateLimitedFunction<(...args: Array<any>) => Promise<void>>;
};
