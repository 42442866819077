import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationFormEntityInfo } from 'common/Types/Entities/ProcessConfigurationForm/createProcessConfigurationFormEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationForm } from './types';

export const processConfigurationFormEntityInfo =
  createProcessConfigurationFormEntityInfo<ProcessConfigurationForm>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
