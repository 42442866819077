import { autoinject } from 'aurelia-framework';
import { Utils } from '../classes/Utils/Utils';

/**
 * a custom attribute which marks elements that skip the press/click timeout of the DraggableCustom attribute
 *
 * Example:
 *     <element-to-drag draggable>
 *       <dragging-icon draggable-instant-drag></dragging-icon>
 *       <general-content></general-content>
 *     </element-to-drag>
 *
 */
@autoinject()
export class DraggableInstantDragCustomAttribute {
  private static instantDragElements: Set<Element> = new Set();

  public static isInsideInstantDragElement(element: Element): boolean {
    let instantDragElement = false;

    Utils.walkThroughParentElements(element, (currentElement) => {
      if (this.instantDragElements.has(currentElement)) {
        instantDragElement = true;
        return false;
      }

      return true;
    });

    return instantDragElement;
  }

  constructor(private readonly element: Element) {}

  protected attached(): void {
    DraggableInstantDragCustomAttribute.instantDragElements.add(this.element);
  }

  protected detached(): void {
    DraggableInstantDragCustomAttribute.instantDragElements.delete(
      this.element
    );
  }
}
