import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskInvoiceToProcessTaskDevice } from './types';

export class ProcessTaskInvoiceToProcessTaskDeviceRepository extends AppEntityRepository<EntityName.ProcessTaskInvoiceToProcessTaskDevice> {
  public getByProcessTaskDeviceIds(
    processTaskDeviceIds: Array<string>
  ): Array<ProcessTaskInvoiceToProcessTaskDevice> {
    return this.getAll().filter((r) =>
      processTaskDeviceIds.includes(r.processTaskDeviceId)
    );
  }

  public getByProcessTaskInvoiceIdAndProcessTaskId(
    processTaskInvoiceId: string,
    processTaskId: string
  ): Array<ProcessTaskInvoiceToProcessTaskDevice> {
    return this.getAll().filter((r) => {
      return (
        r.processTaskInvoiceId === processTaskInvoiceId &&
        r.ownerProcessTaskId === processTaskId
      );
    });
  }

  public getByProcessTaskInvoiceId(
    processTaskInvoiceId: string
  ): Array<ProcessTaskInvoiceToProcessTaskDevice> {
    return this.getAll().filter(
      (r) => r.processTaskInvoiceId === processTaskInvoiceId
    );
  }

  public getByProcessTaskInvoiceIdAndProcessTaskDeviceId(
    processTaskInvoiceId: string,
    processTaskDeviceId: string
  ): ProcessTaskInvoiceToProcessTaskDevice | null {
    return (
      this.getAll().find((r) => {
        return (
          r.processTaskInvoiceId === processTaskInvoiceId &&
          r.processTaskDeviceId === processTaskDeviceId
        );
      }) ?? null
    );
  }
}
