import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { EmbeddedCamera } from '../../aureliaComponents/embedded-camera/embedded-camera';

@autoinject()
export class MultiPictureInput {
  @bindable public thingId: string | null = null;

  @bindable public thingUserGroupId: string | null = null;

  /**
   * An array of dataUrls captured by the EmbeddedCamera.
   */
  @bindable public selectedPictureDataUrls: Array<string> = [];

  protected async handleAddPictureButtonClick(): Promise<void> {
    assertNotNullOrUndefined(
      this.thingId,
      'cannot handleAddPictureButtonCLick without thingId'
    );
    assertNotNullOrUndefined(
      this.thingUserGroupId,
      'cannot handleAddPictureButtonCLick without thingUserGroupId'
    );

    await EmbeddedCamera.capturePicture({
      onCapture: () => {
        const dataUrl = EmbeddedCamera.getLastDataUriPictureOnce()!;
        this.selectedPictureDataUrls.push(dataUrl);
      }
    });
  }

  protected handleDeletePictureClick(dataUrl: string): void {
    ArrayUtils.remove(this.selectedPictureDataUrls, dataUrl);
  }
}
