import { User } from '@sentry/capacitor';
import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { configureHooks } from '../../hooks/configureHooks';
import { expression, model } from '../../hooks/dependencies';
import { watch } from '../../hooks/watch';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class CreateProcessTaskGroupAuthorizationDialog {
  public static async open(
    options: CreateProcessTaskGroupAuthorizationDialogOpenOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected processTaskGroup: ProcessTaskGroup | null = null;
  protected processConfigurationAuthorizationTypeId: string | null = null;
  protected userId: string | null = null;

  protected selectableUsers: Array<User> = [];

  protected dialog: RecordItDialog | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService
  ) {}

  public open(
    options: CreateProcessTaskGroupAuthorizationDialogOpenOptions
  ): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CreateProcessTaskGroupAuthorizationDialog.open without dialog"
    );

    this.processTaskGroup = options.processTaskGroup;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.processTaskGroup = null;
    this.processConfigurationAuthorizationTypeId = null;
    this.userId = null;
  }

  protected handleAcceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      "can't CreateProcessTaskGroupAuthorizationDialog.handleDialogClosed without processTaskGroup"
    );
    if (!this.processConfigurationAuthorizationTypeId || !this.userId) {
      return;
    }

    const processTaskGroupAuthorization =
      this.entityManager.processTaskGroupAuthorizationRepository.create({
        userId: this.userId,
        processConfigurationAuthorizationTypeId:
          this.processConfigurationAuthorizationTypeId,
        ownerProcessTaskGroupId: this.processTaskGroup.id,
        ownerUserGroupId: this.processTaskGroup.ownerUserGroupId,
        temporaryGroupName: this.processTaskGroup.temporaryGroupName,
        shadowEntity: this.processTaskGroup.shadowEntity
      });

    const processTasks =
      this.entityManager.processTaskRepository.getByProcessTaskGroupId(
        this.processTaskGroup.id
      );
    for (const processTask of processTasks) {
      void this.processTaskLoggingService.logProcessTaskGroupAuthorizationCreated(
        {
          processTask,
          processTaskGroupAuthorization
        }
      );
    }
  }

  @watch(
    expression('processConfigurationAuthorizationTypeId'),
    model(EntityName.ProcessConfigurationAuthorizationType),
    model(EntityName.UserGroup),
    model(EntityName.UserRoleToUser),
    model(EntityName.User)
  )
  protected updateUserOptions(): void {
    const processConfigurationAuthorizationType = this
      .processConfigurationAuthorizationTypeId
      ? this.entityManager.processConfigurationAuthorizationTypeRepository.getById(
          this.processConfigurationAuthorizationTypeId
        )
      : null;

    const userRole = processConfigurationAuthorizationType?.userRoleId
      ? this.entityManager.userRoleRepository.getById(
          processConfigurationAuthorizationType.userRoleId
        )
      : null;

    const userGroup = userRole
      ? this.entityManager.userGroupRepository.getById(
          userRole.ownerUserGroupId
        )
      : null;

    const userRoleToUsers = userGroup
      ? this.entityManager.userRoleToUserRepository.getByUserGroupId(
          userGroup.id
        )
      : null;

    this.selectableUsers = this.entityManager.userRepository
      .getAll()
      .filter((user) => {
        if (
          userGroup &&
          !PermissionHelper.userCanReadInUserGroup(user, userGroup)
        ) {
          return false;
        }

        if (
          processConfigurationAuthorizationType &&
          userRoleToUsers &&
          !userRoleToUsers.some(
            (role) =>
              role.userId === user.id &&
              role.userRoleId ===
                processConfigurationAuthorizationType.userRoleId
          )
        ) {
          return false;
        }

        return true;
      });

    this.resetUserIdIfUserIsNotSelectableAnymore();
  }

  private resetUserIdIfUserIsNotSelectableAnymore(): void {
    if (!this.selectableUsers.some((user) => user.id === this.userId)) {
      this.userId = null;
    }
  }
}

export type CreateProcessTaskGroupAuthorizationDialogOpenOptions = {
  processTaskGroup: ProcessTaskGroup;
};
