export class ScopeBasedLock {
  private locks: Set<any> = new Set();

  public setLocked(scope: any, locked: boolean): void {
    if (locked) {
      this.lock(scope);
    } else {
      this.unlock(scope);
    }
  }

  public lock(scope: any): void {
    this.locks.add(scope);
  }

  public unlock(scope: any): void {
    this.locks.delete(scope);
  }

  public isLocked(): boolean {
    return this.locks.size > 0;
  }

  public async executeLocked(
    scope: any,
    worker: () => Promise<void> | void
  ): Promise<void> {
    this.lock(scope);
    try {
      await worker();
    } finally {
      this.unlock(scope);
    }
  }
}
