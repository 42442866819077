import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ValueCalculationConfigDto } from './ValueCalculationConfigDto';

export function createValueCalculationConfigEntityInfo<
  TEntity extends ValueCalculationConfigDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ValueCalculationConfigDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ValueCalculationConfig,
  TEntity
> {
  return {
    entityName: EntityName.ValueCalculationConfig,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ValueCalculationConfigDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: ''
        },
        calculationExpression: {
          name: 'calculationExpression',
          defaultValue: ''
        },
        colorCodeConfig: {
          name: 'colorCodeConfig',
          innerInfo: {
            colorConfigs: {
              name: 'colorConfigs',
              innerInfo: [
                {
                  color: {
                    name: 'color'
                  },
                  condition: {
                    name: 'condition'
                  }
                }
              ]
            }
          },
          defaultValue: null
        },
        showInEntityOverview: {
          name: 'showInEntityOverview',
          defaultValue: false
        },
        ownerThingId: {
          name: 'ownerThingId',
          ref: EntityName.Thing,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        reportTypeId: {
          name: 'reportTypeId',
          ref: EntityName.ReportType,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        thingTypeId: {
          name: 'thingTypeId',
          ref: EntityName.ThingType,
          cascadeDeleteLocally: true,
          defaultValue: null
        },
        type: {
          name: 'type'
        }
      },
      options.additionalFieldInfos
    )
  };
}
