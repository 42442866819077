import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskComment } from '../../classes/EntityManager/entities/ProcessTaskComment/types';

@autoinject()
export class ProcessTaskCommentsWidget {
  @bindable()
  public processTask: ProcessTask | null = null;

  /**
   * null if comments aren't loaded
   * read only
   */
  @bindable()
  public processTaskCommentCount: number | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  private availableComments: Array<ProcessTaskComment> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskComment,
      this.updateAvailableComments.bind(this)
    );
    this.updateAvailableComments();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAvailableComments();
    }
  }

  private updateAvailableComments(): void {
    if (this.processTask) {
      const comments =
        this.entityManager.processTaskCommentRepository.getByProcessTaskId(
          this.processTask.id
        );
      this.availableComments = comments.sort((a, b) =>
        b.date.localeCompare(a.date)
      );
      this.processTaskCommentCount = this.availableComments.length;
    } else {
      this.availableComments = [];
      this.processTaskCommentCount = null;
    }
  }
}
