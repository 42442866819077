import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProjectQuestionSetEntityInfo } from 'common/Types/Entities/ProjectQuestionSet/createProjectQuestionSetEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProjectQuestionSet } from './types';

export const projectQuestionSetEntityInfo =
  createProjectQuestionSetEntityInfo<ProjectQuestionSet>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
