import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskInvoiceToProcessTask } from './types';

export class ProcessTaskInvoiceToProcessTaskRepository extends AppEntityRepository<EntityName.ProcessTaskInvoiceToProcessTask> {
  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskInvoiceToProcessTask> {
    return this.getAll().filter((e) => e.ownerProcessTaskId === processTaskId);
  }

  public getByProcessTaskInvoiceId(
    processTaskInvoiceId: string
  ): Array<ProcessTaskInvoiceToProcessTask> {
    return this.getAll().filter(
      (e) => e.processTaskInvoiceId === processTaskInvoiceId
    );
  }

  public getByProcessTaskInvoiceIds(
    processTaskInvoiceIds: Array<string>
  ): Array<ProcessTaskInvoiceToProcessTask> {
    return this.getAll().filter(
      (e) => processTaskInvoiceIds.indexOf(e.processTaskInvoiceId) >= 0
    );
  }
}
