import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingToPerson } from '../../classes/EntityManager/entities/ThingToPerson/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { configureHooks } from '../../hooks/configureHooks';
import { expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { watch } from '../../hooks/watch';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditThingMainContactBankAccountDataDialog {
  @subscribableLifecycle()
  protected readonly personPermissionHandle: EntityNameToPermissionsHandle[EntityName.Person];

  @subscribableLifecycle()
  protected readonly userGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private dialog: RecordItDialog | null = null;
  private thing: Thing | null = null;
  private thingToPerson: ThingToPerson | null = null;
  private person: Person | null = null;
  private personAutomaticallyCreated: boolean = false;

  protected PersonUtils = PersonUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.personPermissionHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'person'
      });

    this.userGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForIdExpressionValue({
        entityName: EntityName.UserGroup,
        context: this,
        expression: 'thing.ownerUserGroupId'
      });

    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });

    this.updatePermissionsOverride();
  }

  public static async open(options: IOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  public open(options: IOpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditThingMainContactBankAccountDataDialog.open without a dialog"
    );

    const thing = this.entityManager.thingRepository.getById(options.thingId);
    assertNotNullOrUndefined(
      thing,
      `thing for id "${options.thingId}" EditThingMainContactBankAccountDataDialog.open`
    );
    this.thing = thing;

    this.thingToPerson =
      this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
        thing.id
      );
    this.person = this.thingToPerson
      ? this.entityManager.personRepository.getById(this.thingToPerson.personId)
      : null;
    this.personAutomaticallyCreated = false;

    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    if (this.personAutomaticallyCreated && this.person) {
      this.person.lastName = this.person.bankAccountHolder;
      this.entityManager.personRepository.update(this.person);
    }

    this.thing = null;
    this.thingToPerson = null;
    this.person = null;
  }

  protected handlePersonChanged(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditThingMainContactBankAccountDataDialog.handlePersonChanged without a thing"
    );

    if (!this.thingToPerson) {
      this.person = this.entityManager.personRepository.create({
        ...this.person, // if no thingToPerson is set, it can only mean we are working with a partial person which has been created automatically by aurelia
        ownerUserGroupId: this.thing.ownerUserGroupId,
        temporaryGroupName: this.thing.temporaryGroupName,
        shadowEntity: this.thing.shadowEntity
      });

      this.thingToPerson = this.entityManager.thingToPersonRepository.create({
        personId: this.person.id,
        thingId: this.thing.id,
        mainContact: true,
        ownerUserGroupId: this.thing.ownerUserGroupId,
        temporaryGroupName: this.thing.temporaryGroupName,
        shadowEntity: this.thing.shadowEntity
      });

      this.personAutomaticallyCreated = true;
    } else {
      assertNotNullOrUndefined(
        this.person,
        'EditThingMainContactBankAccountDataDialog.handlePersonChanged: person has to be set if a thingToPerson is set'
      );
      this.entityManager.personRepository.update(this.person);
    }
  }

  @watch(
    expression('person.ownerUserGroupId'),
    expression('thingPermissionsHandle.canCreateThingToPersons'),
    expression('userGroupPermissionsHandle.canCreatePersons')
  )
  protected updatePermissionsOverride(): void {
    // because a new person is created when inputting values, we need to enable the inputs if there is no person yet
    // if no ownerUserGroupId is set, we have a partial object from aurelia
    if (
      !this.person?.ownerUserGroupId &&
      this.thingPermissionsHandle.canCreateThingToPersons &&
      this.userGroupPermissionsHandle.canCreatePersons
    ) {
      this.personPermissionHandle.overrideAllPermissions(true);
    } else {
      this.personPermissionHandle.overrideAllPermissions(null);
    }
  }
}

export interface IOpenOptions {
  thingId: string;
}
