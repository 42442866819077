import { autoinject } from 'aurelia-dependency-injection';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { ThingGroupConfiguration } from 'common/Types/ThingGroupConfiguration';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { AppEntityManager } from '../AppEntityManager';
import { ActiveUserCompanySettingService } from '../UserCompanySetting/ActiveUserCompanySettingService';
import { ThingGroup } from './types';

@autoinject()
export class ThingGroupPropertiesService {
  private readonly subscriptionManager: SubscriptionManager;
  private thingGroupConfiguration: ThingGroupConfiguration | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'general.thingGroupConfigurationJson',
        (thingGroupConfiguration) => {
          this.thingGroupConfiguration = thingGroupConfiguration;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.thingGroupRepository.registerHooks({
        afterEntityCreated: this.handleThingGroupCreated.bind(this)
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private handleThingGroupCreated(thingGroup: ThingGroup): void {
    if (this.activeUserCompanySettingService.fallbackIsActive()) {
      console.error(
        "can't create default properties for the thing group since the user company setting is not loaded/available"
      );
    }

    if (!this.thingGroupConfiguration) {
      console.error('no thing group configuration');
      return;
    }

    for (const [
      order,
      defaultProperty
    ] of this.thingGroupConfiguration.defaultProperties.entries()) {
      this.entityManager.propertyRepository.create({
        ...defaultProperty,
        order,
        type: PropertyType.TEXT,
        active: true,
        alwaysVisible: true,
        choices: [],
        ownerUserGroupId: thingGroup.ownerUserGroupId,
        thingGroupId: thingGroup.id,
        temporaryGroupName: thingGroup.temporaryGroupName,
        shadowEntity: thingGroup.shadowEntity
      });
    }
  }
}
