import { SvgLoader } from '../Svg/SvgLoader';

/**
 * loads a remote url into an existing <svg>
 */
export class InlineSvgLoader {
  private static defaultOptions: IInlineSvgLoaderOptions = {
    preserveClassAttribute: true
  };

  private options: IInlineSvgLoaderOptions;
  private svgElement: SVGSVGElement;

  constructor(
    svgElement: SVGSVGElement,
    options?: Partial<IInlineSvgLoaderOptions>
  ) {
    this.svgElement = svgElement;
    this.options = {
      ...InlineSvgLoader.defaultOptions,
      ...(options || {})
    };
  }

  /**
   * @param url
   * @returns - the svg element which got loaded from the url
   */
  public async load(url: string): Promise<SVGSVGElement> {
    const loader = new SvgLoader();
    const svgElement = await loader.load(url);
    const copier = new InlineSvgLoaderSvgCopier(svgElement, this.svgElement);
    copier.copy();

    return svgElement;
  }
}

/**
 * this class copies an svg to another existing svg element
 */
export class InlineSvgLoaderSvgCopier {
  private targetSvgElement: SVGSVGElement;
  private srcSvgElement: SVGSVGElement;
  private preserveClassAttribute: boolean;

  constructor(
    srcSvgElement: SVGSVGElement,
    targetSvgElement: SVGSVGElement,
    preserveClassAttribute: boolean = true
  ) {
    this.targetSvgElement = targetSvgElement;
    this.srcSvgElement = srcSvgElement;
    this.preserveClassAttribute = preserveClassAttribute;
  }

  public copy(): void {
    this.copyChildren();
    this.copyAttributes();
  }

  private copyChildren(): void {
    // remove old elements
    while (this.targetSvgElement.firstChild) {
      this.targetSvgElement.removeChild(this.targetSvgElement.firstChild);
    }

    // add new elements
    while (this.srcSvgElement.firstChild) {
      this.targetSvgElement.appendChild(this.srcSvgElement.firstChild);
    }
  }

  private copyAttributes(): void {
    // remove non existing attributes
    this.walkOverSpecifiedAttributes(this.targetSvgElement, (attr) => {
      if (!this.srcSvgElement.hasAttribute(attr.name)) {
        this.targetSvgElement.removeAttribute(attr.name);
      }
    });

    // add new attributes/values
    this.walkOverSpecifiedAttributes(this.srcSvgElement, (attr) => {
      this.targetSvgElement.setAttribute(attr.name, attr.value);
    });
  }

  private walkOverSpecifiedAttributes(
    element: Element,
    callback: (attr: Attr) => void
  ): void {
    const attrs: Array<Attr> = Array.from(element.attributes);
    attrs.forEach((attr) => {
      if (
        attr.specified &&
        (!this.preserveClassAttribute || attr.name !== 'class')
      ) {
        callback(attr);
      }
    });
  }
}

interface IInlineSvgLoaderOptions {
  preserveClassAttribute: boolean;
}
