import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { StructureTemplateEntryGroupToStructureTemplateEntry } from './types';

export class StructureTemplateEntryGroupToStructureTemplateEntryRepository extends AppEntityRepository<EntityName.StructureTemplateEntryGroupToStructureTemplateEntry> {
  public getByStructureTemplateEntryId(
    structureTemplateEntryId: string
  ): StructureTemplateEntryGroupToStructureTemplateEntry | null {
    return (
      this.getAll().find(
        (s) => s.structureTemplateEntryId === structureTemplateEntryId
      ) ?? null
    );
  }
}
