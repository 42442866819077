import { autoinject, bindable } from 'aurelia-framework';
import { ProcessConfigurationProcessTaskPropertiesConfiguration } from 'common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskProperty } from '../../classes/EntityManager/entities/Property/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ShowProcessAppointmentInfoBlock {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public generalInfoEnabled: boolean = false;

  @bindable()
  public enabled: boolean = false;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private permissionBindingHandle: PermissionBindingHandle;
  private subscriptionManager: SubscriptionManager;

  private properties: Array<ProcessTaskProperty> = [];
  private isAttached: boolean = false;
  private canUseOperations: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService,
    permissionsService: PermissionsService
  ) {
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canUseOperations: 'canUseOperations'
      }
    });

    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTask,
        context: this as ShowProcessAppointmentInfoBlock,
        propertyName: 'processTask'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      this.updateProperties.bind(this)
    );
    this.updateProperties();

    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
    }
  }

  private updateProperties(): void {
    if (this.processTask && this.processTaskGroup) {
      const properties =
        this.entityManager.propertyRepository.getByProcessTaskId(
          this.processTask.id
        );
      const config = this.entityManager.processConfigurationRepository.getById(
        this.processTaskGroup.processConfigurationId
      );
      const settings: ProcessConfigurationProcessTaskPropertiesConfiguration | null =
        config && config.processTaskPropertiesConfigurationJson
          ? JSON.parse(config.processTaskPropertiesConfigurationJson)
          : null;

      this.properties = properties.filter((property) => {
        const settingProperty =
          settings &&
          settings.defaultProperties.find((configProperty) => {
            return (
              configProperty.showInFieldUse &&
              configProperty.name === property.name
            );
          });
        return !!settingProperty;
      });
    } else {
      this.properties = [];
    }
  }

  private handleProcessTaskChanged(): void {
    if (this.processTask) {
      this.entityManager.processTaskRepository.update(this.processTask);
    }
  }

  private handleProcessTaskGroupChanged(): void {
    if (this.processTaskGroup) {
      this.entityManager.processTaskGroupRepository.update(
        this.processTaskGroup
      );
    }
  }
}
