import {
  GetProcessTaskToProjectRelationInfoRequest,
  GetProcessTaskToProjectRelationInfoSuccessResponse,
  SetProcessTaskToProjectRelationResponse
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';

import {
  AbstractProcessTaskToProjectCrudStrategy,
  ProcessTaskToProjectUpdateOptions
} from './AbstractProcessTaskToProjectCrudStrategy';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { SocketService } from '../../../services/SocketService';
import {
  SingleSocketRequest,
  SingleSocketRequestService,
  SingleSocketRequestSkippedError
} from '../../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';

export class SocketEndpointProcessTaskToProjectCrudStrategy extends AbstractProcessTaskToProjectCrudStrategy {
  private getProcessTaskToProjectRelationInfosRequest: SingleSocketRequest<
    GetProcessTaskToProjectRelationInfoRequest,
    GetProcessTaskToProjectRelationInfoSuccessResponse
  >;

  constructor(
    processTaskToProjectType: ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
    private readonly socketService: SocketService,
    private readonly singleSocketRequestService: SingleSocketRequestService
  ) {
    super(processTaskToProjectType);
    this.getProcessTaskToProjectRelationInfosRequest =
      this.singleSocketRequestService.createAfterFirstActualizationRequest({
        requestCallback: ({ data }) => {
          return new Promise<GetProcessTaskToProjectRelationInfoSuccessResponse>(
            (resolve, reject) => {
              this.socketService.getProcessTaskToProjectRelationInfos(
                data,
                (r) => {
                  if (r.success) {
                    resolve(r);
                  } else {
                    console.error(r);
                    reject(
                      new Error(
                        "couldn't fetch process task to project relation infos"
                      )
                    );
                  }
                }
              );
            }
          );
        }
      });
  }

  public async updateProcessTaskToProjectsAndCandidates(
    request: GetProcessTaskToProjectRelationInfoRequest,
    options: ProcessTaskToProjectUpdateOptions
  ): Promise<void> {
    if (
      !this.socketService.isAuthenticated() ||
      !this.socketService.isConnected()
    ) {
      return;
    }
    const delayedLoadingOverlay = !options.config
      ?.showOverlayForLongResponseTimes
      ? setTimeout(() => {
          options.setOverlayStatus(true);
        }, 1000)
      : null;

    try {
      if (options.config?.showOverlayImmediately) {
        options.setOverlayStatus(true);
      }

      const response =
        await this.getProcessTaskToProjectRelationInfosRequest.send(request);

      options.setRelationsToDisplay(response.processTaskToProjectInfos);
      options.setTotalRelationsCount(response.totalResultLength);
    } catch (e) {
      if (!(e instanceof SingleSocketRequestSkippedError)) {
        throw e;
      }
    } finally {
      if (delayedLoadingOverlay) clearTimeout(delayedLoadingOverlay);
      options.setOverlayStatus(false);
    }
  }

  public createProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse> {
    return this.socketService.setProcessTaskToProjectToRelationStatus({
      processTaskId,
      projectId,
      processTaskToProjectType: this.processTaskToProjectType,
      newRelationStatus: true
    });
  }

  public deleteProcessTaskToProject({
    processTaskId,
    projectId
  }: {
    processTaskId: string;
    projectId: string;
  }): Promise<SetProcessTaskToProjectRelationResponse> {
    return this.socketService.setProcessTaskToProjectToRelationStatus({
      processTaskId,
      projectId,
      processTaskToProjectType: this.processTaskToProjectType,
      newRelationStatus: false
    });
  }

  public setupSubscriptions(
    subscriptionManager: SubscriptionManager,
    updateCallback: () => Promise<void>
  ): void {
    subscriptionManager.subscribeToInterval(() => {
      void updateCallback();
    }, 10000);
    subscriptionManager.subscribeToEvent('socket:authenticated', () => {
      void updateCallback();
    });
  }
}
