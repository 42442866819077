import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { Dialogs } from '../../classes/Dialogs';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * @event edit-button-clicked
 */
@autoinject()
export class ProcessConfigurationDeviceListItem {
  @bindable()
  public processConfigurationDevice: ProcessConfigurationDevice | null = null;

  @subscribableLifecycle()
  protected readonly processConfigurationDevicePermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationDevice];

  private listItemElement: HTMLElement | null = null;
  private panelOpen: boolean = false;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationDevicePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationDevice,
        context: this as ProcessConfigurationDeviceListItem,
        propertyName: 'processConfigurationDevice'
      });
  }

  public highlight(): void {
    if (this.listItemElement) {
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
    }
  }

  protected handleMoreButtonClick(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEditClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  protected async handleDeleteClick(): Promise<void> {
    const device = this.processConfigurationDevice;
    assertNotNullOrUndefined(
      device,
      "can't ProcessConfigurationDeviceListItem.handleDeleteClick without a processConfigurationDevice"
    );

    await Dialogs.deleteEntityDialog(device, device.name ?? '');
    this.entityManager.processConfigurationDeviceRepository.delete(device);
  }
}
