import { Vector } from 'common/Geometry/Vector';
import { TGeometryRectangle } from 'common/Geometry/RectangleHelper';

export class TooltipContentHelper {
  public static getContentBoundingRectangle(
    contentElement: HTMLElement,
    slottedElement: HTMLElement,
    limitToAvailableSpace: boolean
  ): TGeometryRectangle {
    const boundingClientRect = contentElement.getBoundingClientRect();
    let size: Vector | null = null;

    // if limitToAvailableSpace is set, then the slotted element will have a max width/height from the TooltipContentMaxSizeSolver
    // so we can't measure the element normally, so we have to rely on the slottedElement to have the scroll style set to 'auto' or 'scroll'
    if (limitToAvailableSpace) {
      const computed = window.getComputedStyle(slottedElement);
      const borderWidth =
        parseInt(computed.borderLeftWidth || '0') +
        parseInt(computed.borderRightWidth || '0');
      const borderHeight =
        parseInt(computed.borderTopWidth || '0') +
        parseInt(computed.borderBottomWidth || '0');

      size = new Vector(
        Math.max(
          slottedElement.scrollWidth + borderWidth,
          boundingClientRect.width
        ),
        Math.max(
          slottedElement.scrollHeight + borderHeight,
          boundingClientRect.height
        )
      );
    } else {
      size = new Vector(boundingClientRect.width, boundingClientRect.height);
    }

    return {
      position: new Vector(boundingClientRect.left, boundingClientRect.top),
      size: size
    };
  }

  public static getScrollOffset(): number {
    return document.scrollingElement?.scrollTop ?? 0;
  }
}
