import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { BaseEntityUtils } from '../../BaseEntities/BaseEntityUtils';
import { UserGroupDto } from './UserGroupDto';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';

export function createUserGroupEntityInfo<
  TEntity extends UserGroupDto<string, string>
>(
  options: CreateEntityInfoOptions<UserGroupDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.UserGroup,
  TEntity
> {
  return {
    entityName: EntityName.UserGroup,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserGroupDto<string, string>,
      TEntity
    >(
      {
        ...BaseEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        userSpecs: {
          name: 'userSpecs',
          innerInfo: [
            {
              _id: {
                name: '_id'
              },
              can_edit: {
                name: 'can_edit'
              },
              group_admin: {
                name: 'group_admin'
              }
            }
          ],
          defaultValue: () => []
        },
        synchronizeUsers: {
          name: 'synchronizeUsers',
          defaultValue: false
        },
        smsCredentials: {
          name: 'smsCredentials',
          innerInfo: {
            password: {
              name: 'password',
              defaultValue: null
            },
            username: {
              name: 'username',
              defaultValue: null
            }
          },
          defaultValue: () => ({
            username: null,
            password: null
          })
        },
        sharepointCredentials: {
          name: 'sharepointCredentials',
          innerInfo: {
            tenantId: {
              name: 'tenantId',
              defaultValue: null
            },
            applicationId: {
              name: 'applicationId',
              defaultValue: null
            },
            clientCertificate: {
              name: 'clientCertificate',
              innerInfo: {
                privateKey: {
                  name: 'privateKey',
                  defaultValue: null
                },
                thumbprint: {
                  name: 'thumbprint',
                  defaultValue: null
                }
              },
              defaultValue: () => ({
                thumbprint: null,
                privateKey: null
              })
            },
            sharepointExportPath: {
              name: 'sharepointExportPath',
              defaultValue: null
            },
            sharepointExportSite: {
              name: 'sharepointExportSite',
              defaultValue: null
            }
          },
          defaultValue: () => ({
            tenantId: null,
            applicationId: null,
            clientCertificate: {
              thumbprint: null,
              privateKey: null
            },
            sharepointExportSite: null,
            sharepointExportPath: null
          })
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        controlEntityVisibilityWithAuthorizations: {
          name: 'controlEntityVisibilityWithAuthorizations',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
