import { EntityName } from '../types';
import { UserDefinedEntityConfig } from './types';
import { AppEntityRepository } from '../../base/AppEntityRepository';

export class UserDefinedEntityConfigRepository extends AppEntityRepository<EntityName.UserDefinedEntityConfig> {
  public getByUserGroupId(userGroupId: string): Array<UserDefinedEntityConfig> {
    return this.getAll().filter((userDefinedEntity) => {
      return userDefinedEntity.ownerUserGroupId === userGroupId;
    });
  }

  public getByName(name: string): UserDefinedEntityConfig | undefined {
    return this.getAll().find((userDefinedEntity) => {
      return userDefinedEntity.name === name;
    });
  }
}
