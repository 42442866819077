import { bindable, observable, inject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

/**
 * a person select in the record-it-filter style
 * It has the possibility to limit the selectable person to a certain subset (e.g. to only show the available persons), but still allows the user to select a different one with an extra button.
 * It allows the selection of explicitly "no person" or "all persons" so you can filter the data more precisely
 *
 * @event selected-person-option-changed
 */
@inject(Element, I18N, AppEntityManager, SubscriptionManagerService)
export class FilterPersonSelect {
  static MODE_SELECT = 'select';
  static MODE_OTHER_PERSON = 'otherPerson';

  /**
   * null if no option is selected/all persons are selected
   * is set when explicitly no person is selected (option.person will be null) or when a certain person has been selected (option.person will be the selected person)
   *
   * @type {TPersonOption|null|undefined}
   */
  @bindable selectedPersonOption = null;

  /**
   * @type {Array<string>}
   */
  @bindable selectablePersonIds = [];

  /**
   * @type {string|null}
   */
  @bindable label = null;

  /**
   * @type {string|null}
   */
  @bindable userGroupId = null;

  /** @type {string}  */
  @bindable mode = FilterPersonSelect.MODE_SELECT;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {I18N} */
  _i18n;
  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {TPersonOption|null} */
  @observable _selectedSelectablePerson;
  /** @type {Array<TPersonOption>} */
  _personOptions = [];
  /** @type {string|null} */
  _forcedVisiblePersonId = null;
  /** @type {string|null} */
  _selectedForcedVisiblePersonId = null;

  /**
   * @param {HTMLElement} element
   * @param {I18N} i18n
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(element, i18n, entityManager, subscriptionManagerService) {
    this._domElement = element;

    this._i18n = i18n;

    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();

    this._FilterPersonSelect = FilterPersonSelect;

    this._selectedSelectablePerson = null;
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(EntityName.Person, () => {
      this._updateSelectablePersons();
    });

    this._subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'selectablePersonIds',
      () => {
        this._updateSelectablePersons();
      }
    );

    this._subscriptionManager.subscribeToEvent('i18n:locale:changed', () => {
      this._updateSelectablePersons();
    });

    this._updateSelectablePersons();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  _updateSelectablePersons() {
    const personIds = this.selectablePersonIds.slice();

    if (
      this._forcedVisiblePersonId &&
      personIds.indexOf(this._forcedVisiblePersonId) === -1
    ) {
      personIds.push(this._forcedVisiblePersonId);
    }

    // make sure to reuse existing Options so references to them don't get lost
    this._personOptions = InstancePreserver.createNewArray({
      originalArray: this._personOptions,
      newArray: this._getPersonOptionsForPersonIds(personIds),
      getTrackingValue: (item) => item.person
    });
  }

  /**
   * @param {Array<string>} personIds
   * @returns {Array<TPersonOption>}
   * @private
   */
  _getPersonOptionsForPersonIds(personIds) {
    /** @type {Array<TPersonOption>} */
    const selectablePersons = [];

    personIds.forEach((personId) => {
      const person = this._entityManager.personRepository.getById(personId);
      if (!person) {
        return;
      }

      selectablePersons.push({
        displayName: PersonUtils.getPersonDisplayNameForPerson(person),
        personId: personId,
        person: person
      });
    });

    selectablePersons.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    selectablePersons.unshift({
      displayName: this._i18n.tr('general.general'),
      personId: null,
      person: null
    });

    return selectablePersons;
  }

  _handleOtherPersonClicked() {
    this.mode = FilterPersonSelect.MODE_OTHER_PERSON;
  }

  _handleSelectForcedVisiblePersonIdClick() {
    this._forcedVisiblePersonId = this._selectedForcedVisiblePersonId;
    this._updateSelectablePersons();
    this.selectedPersonOption = this._personOptions.find(
      (sp) => sp.personId === this._forcedVisiblePersonId
    );
    this.mode = FilterPersonSelect.MODE_SELECT;

    setTimeout(() => {
      this._fireSelectedPersonOptionChangedEvent();
    }, 0);
  }

  _handleCancelForcedVisiblePersonIdClick() {
    this.mode = FilterPersonSelect.MODE_SELECT;
  }

  _handleSelectChanged() {
    this._fireSelectedPersonOptionChangedEvent();
  }

  _fireSelectedPersonOptionChangedEvent() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'selected-person-option-changed',
      detail: null
    });
  }
}

/**
 * @typedef {Object} TPersonOption
 * @property {string} displayName
 * @property {string|null} personId
 * @property {import('../../classes/EntityManager/entities/Person/types').Person|null} person
 */
