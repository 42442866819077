import { autoinject, containerless } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { ImportEntryXmlFileDialog } from '../../dialogs/import-entry-xml-file-dialog/import-entry-xml-file-dialog';
import {
  ComponentView,
  ViewComponentClass,
  ViewCreationService
} from '../../services/ViewCreationService';

/**
 * this is a container which provides an interface to create global instances of components on demand
 */
@containerless()
@autoinject()
export class GlobalElements {
  private static instance: GlobalElements | null = null;

  public static ensureGlobalComponentView<T extends ViewComponentClass>(
    component: T
  ): Promise<ComponentView<T>> {
    assertNotNullOrUndefined(
      this.instance,
      'no instance found, is the <global-elements> attached?'
    );
    return this.instance.ensureComponent(component);
  }

  protected insertionReference: HTMLSpanElement | null = null;
  private componentToView: Map<ViewComponentClass, ComponentView<any>> =
    new Map();

  constructor(private readonly viewCreationService: ViewCreationService) {
    ImportEntryXmlFileDialog.registerOpenWithHandler();
  }

  protected attached(): void {
    if (GlobalElements.instance) {
      console.error('An GlobalElements instance already exists. Replacing it.');
    }

    GlobalElements.instance = this;
  }

  protected detached(): void {
    GlobalElements.instance = null;

    for (const view of this.componentToView.values()) {
      view.detach();
    }

    this.componentToView = new Map();
  }

  private async ensureComponent<T extends ViewComponentClass>(
    component: T
  ): Promise<ComponentView<T>> {
    let componentView = this.componentToView.get(component);

    if (!componentView) {
      componentView = await this.createView(component);
      this.componentToView.set(component, componentView);
    }

    return componentView;
  }

  private async createView<T extends ViewComponentClass>(
    component: T
  ): Promise<ComponentView<T>> {
    assertNotNullOrUndefined(
      this.insertionReference,
      'no insertionReference found, is the global-elements attached/bound?'
    );

    const componentView = await this.viewCreationService.createViewForComponent(
      { component }
    );
    componentView.attachBefore(this.insertionReference);

    return componentView;
  }
}
