import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { UserRoleToUser } from './types';

export class UserRoleToUserRepository extends AppEntityRepository<EntityName.UserRoleToUser> {
  public getByUserAndUserGroupId({
    userId,
    userGroupId
  }: {
    userId: string;
    userGroupId: string;
  }): Array<UserRoleToUser> {
    return this.getAll().filter((userRoleToUser) => {
      return (
        userRoleToUser.ownerUserGroupId === userGroupId &&
        userRoleToUser.userId === userId
      );
    });
  }

  public getByUserAndUserGroupIds({
    userId,
    userGroupIds
  }: {
    userId: string;
    userGroupIds: Array<string>;
  }): Array<UserRoleToUser> {
    return this.getAll().filter((userRoleToUser) => {
      return (
        userRoleToUser.userId === userId &&
        userGroupIds.includes(userRoleToUser.ownerUserGroupId)
      );
    });
  }

  public getByUserGroupId(userGroupId: string): Array<UserRoleToUser> {
    return this.getAll().filter((userRoleToUser) => {
      return userRoleToUser.ownerUserGroupId === userGroupId;
    });
  }
}
