import { autoinject, bindable, computedFrom } from 'aurelia-framework';

import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';

import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroupFilter } from '../../classes/EntityManager/entities/ProcessTaskGroupFilter/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event process-task-group-filter-changed
 * @event create-new-process-task-group-filter
 */
@autoinject()
export class ProcessTaskGroupFilterSelect {
  @bindable public processTaskGroupFilterId: string | null = null;

  private domElement: HTMLElement;
  private subscriptionManager: SubscriptionManager;

  protected processTaskGroupFilters: Array<ProcessTaskGroupFilter> = [];

  protected selectedFilter: ProcessTaskGroupFilter | null = null;

  protected boundHandleCreateNewFilter = this.handleCreateNewFilter.bind(this);

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'saveFilter',
      labelTk: 'operationsComponents.processTaskGroupFilterSelect.saveFilter',
      iconClass: 'fal fa-save',
      disabledContext: this,
      disabledPropertyName: 'manipulateFilterDisabled'
    },
    {
      name: 'deleteFilter',
      labelTk: 'operationsComponents.processTaskGroupFilterSelect.deleteFilter',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'manipulateFilterDisabled'
    }
  ];

  protected boundHandleMoreButtonChoiceSelected =
    this.handleMoreButtonChoiceSelected.bind(this);

  @computedFrom('processTaskGroupFilterId')
  private get manipulateFilterDisabled(): boolean {
    return !this.processTaskGroupFilterId;
  }

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroupFilter,
      this.updateProcessTaskGroupFilters.bind(this)
    );
    this.updateProcessTaskGroupFilters();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Updater **********

  private updateProcessTaskGroupFilters(): void {
    this.processTaskGroupFilters =
      this.entityManager.processTaskGroupFilterRepository
        .getAll()
        .sort((f1, f2) => f1.name.localeCompare(f2.name));
    this.updateProcessTaskGroupFilterIdIfNecessary();
  }

  private updateProcessTaskGroupFilterIdIfNecessary(): void {
    const filter = this.processTaskGroupFilters.find(
      (f) => f.id === this.processTaskGroupFilterId
    );
    if (!filter) this.processTaskGroupFilterId = null;
  }

  // ********** Handlers **********

  protected handleFilterSelected(): void {
    DomEventHelper.fireEvent<ProcessTaskGroupFilterChangedEvent>(
      this.domElement,
      {
        name: 'process-task-group-filter-changed',
        detail: {
          processTaskGroupFilterId: this.processTaskGroupFilterId
        }
      }
    );
  }

  protected handleCreateNewFilter(filterString: string): void {
    DomEventHelper.fireEvent<CreateNewProcessTaskGroupFilterEvent>(
      this.domElement,
      {
        name: 'create-new-process-task-group-filter',
        detail: {
          name: filterString
        }
      }
    );
  }

  protected handleMoreButtonChoiceSelected(params: {
    name: string | null;
  }): void {
    switch (params.name) {
      case 'saveFilter':
        this.handleUpdateCurrentFilter();
        break;

      case 'deleteFilter':
        this.handleDeleteCurrentFilter();
        break;

      default:
    }
  }

  private handleUpdateCurrentFilter(): void {
    DomEventHelper.fireEvent<UpdateProcessTaskGroupFilterEvent>(
      this.domElement,
      {
        name: 'update-process-task-group-filter',
        detail: null
      }
    );
  }

  private handleDeleteCurrentFilter(): void {
    const selectedFilter = this.selectedFilter;
    if (!selectedFilter) return;

    void Dialogs.deleteEntityDialog(selectedFilter).then(() =>
      this.entityManager.processTaskGroupFilterRepository.delete(selectedFilter)
    );
  }

  protected handleQuickSelectButtonClicked(
    filter: ProcessTaskGroupFilter
  ): void {
    if (this.processTaskGroupFilterId === filter.id) {
      this.processTaskGroupFilterId = null;
    } else {
      this.processTaskGroupFilterId = filter.id;
    }
    this.handleFilterSelected();
  }
}

export type ProcessTaskGroupFilterChangedEvent = NamedCustomEvent<
  'process-task-group-filter-changed',
  { processTaskGroupFilterId: string | null }
>;
export type CreateNewProcessTaskGroupFilterEvent = NamedCustomEvent<
  'create-new-process-task-group-filter',
  { name: string }
>;
export type UpdateProcessTaskGroupFilterEvent = NamedCustomEvent<
  'update-process-task-group-filter',
  null
>;
