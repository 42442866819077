import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import {
  PictureCreatorService,
  PictureCreatorServiceWithEntityData
} from '../../classes/Picture/PictureCreatorService';
import { SocketService } from '../../services/SocketService';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { EmbeddedCamera } from '../../aureliaComponents/embedded-camera/embedded-camera';
import { CoordinateHelper } from '../../classes/CoordinateHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { TMeasurePointClickedEvent } from '../process-task-measure-point-markings-overlay/process-task-measure-point-markings-overlay';
import { PicturesCreatedEvent } from '../../aureliaComponents/picture-upload-buttons/picture-upload-buttons';
import { ProcessTaskProjectService } from '../../services/ProcessTaskProjectService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskMeasurePoint } from '../../classes/EntityManager/entities/ProcessTaskMeasurePoint/types';
import { ProcessTaskMeasurePointToPicture } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointToPicture/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { TemporaryJoinedProjectHandle } from '../../classes/EntityManager/entities/Project/TemporaryJoinedProjectHandle';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { Dialogs } from '../../classes/Dialogs';
import { PictureSketchingService } from '../../services/PictureSketchingService/PictureSketchingService';

/**
 * @event {TMeasurePointClickedEvent} measure-point-clicked
 */
@autoinject()
export class ProcessTaskMeasurePointPicture {
  private static CAPTURE_PICTURE_DATA_STORAGE_KEY =
    'ProcessTaskMeasurePointListWidget::capturePictureData';

  @bindable()
  public measurePoints: Array<ProcessTaskMeasurePoint> = [];

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  /**
   * read only!
   */
  @bindable()
  public displayedPicture: Picture | null = null;

  @bindable()
  public enabled: boolean = false;

  private domElement: HTMLElement;
  private subscriptionManager: SubscriptionManager;
  private socketService: SocketService;
  /** the project for general pictures */
  private readonly projectHandle: TemporaryJoinedProjectHandle;

  @subscribableLifecycle()
  protected readonly displayedPicturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  @subscribableLifecycle()
  protected readonly displayedPictureEntryPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Entry];

  private pictures: Array<Picture> | null = null;

  protected isApp: boolean = false;
  protected isConnected: boolean = false;
  private measurePointToPictures: Array<ProcessTaskMeasurePointToPicture> | null =
    null;

  protected displayedPictureMeasurePointToPictures: Array<ProcessTaskMeasurePointToPicture> =
    [];

  private isAttached: boolean = false;

  protected measurePointCountByPictureId: MeasurePointCountByPictureId = {};

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskProjectService: ProcessTaskProjectService,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly pictureSketchingService: PictureSketchingService,
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.domElement = element as HTMLElement;
    this.projectHandle = new TemporaryJoinedProjectHandle(entityManager);
    this.projectHandle.enableAutoJoining();
    this.subscriptionManager = subscriptionManagerService.create();
    this.socketService = socketService;

    this.displayedPicturePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Picture,
        context: this as ProcessTaskMeasurePointPicture,
        propertyName: 'displayedPicture'
      });

    this.displayedPictureEntryPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.Entry,
        context: this as ProcessTaskMeasurePointPicture,
        propertyName: 'displayedPicture',
        idPropertyName: 'entry'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:data-url-picture-available',
      () => {
        void this.handleEmbeddedCameraDataUrlAvailable();
      }
    );
    this.subscriptionManager.subscribeToEvent(
      'embedded-camera:camera-closed',
      this.handleEmbeddedCameraClosed.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateProject.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateProject.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePictures.bind(this)
    );
    this.updateProject();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointToPicture,
      this.updateMeasurePointToPictures.bind(this)
    );
    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'measurePoints',
      this.updateMeasurePointToPictures.bind(this)
    );
    this.updateMeasurePointToPictures();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );

    this.isApp = DeviceInfoHelper.isApp();

    this.autoSelectDisplayedPicture();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();

    this.projectHandle.reset();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProject();
    }
  }

  protected displayedPictureChanged(): void {
    this.updateDisplayedPictureMeasurePointToPictures();
  }

  private updateProject(): void {
    let newProject: Project | null = null;

    if (this.processTask) {
      const relation =
        this.entityManager.processTaskToProjectRepository.getByProcessTaskIdAndAppointmentId(
          this.processTask.id,
          null,
          ProcessTaskToProjectType.MEASURE_POINT_PICTURES
        )[0];
      if (relation) {
        newProject = this.entityManager.projectRepository.getById(
          relation.projectId
        );
      }
    }

    this.projectHandle.project = newProject;
    this.updatePictures();
  }

  private updateMeasurePointToPictures(): void {
    if (this.processTask) {
      this.measurePointToPictures =
        this.entityManager.processTaskMeasurePointToPictureRepository.getByOwnerProcessTaskId(
          this.processTask.id
        );
    } else {
      this.measurePointToPictures = null;
    }

    this.updateDisplayedPictureMeasurePointToPictures();
    this.autoSelectDisplayedPicture();
    this.updateMeasurePointCountByPictureId();
  }

  private updateDisplayedPictureMeasurePointToPictures(): void {
    const displayedPicture = this.displayedPicture;
    if (displayedPicture && this.measurePointToPictures) {
      this.displayedPictureMeasurePointToPictures =
        this.measurePointToPictures.filter(
          (r) => r.pictureId === displayedPicture.id
        );
    } else {
      this.displayedPictureMeasurePointToPictures = [];
    }
  }

  private updatePictures(): void {
    if (this.projectHandle.project) {
      this.pictures =
        this.entityManager.pictureRepository.getAllPicturesByProjectId(
          this.projectHandle.project.id
        );
    } else {
      this.pictures = null;
    }

    this.autoSelectDisplayedPicture();
    this.updateMeasurePointCountByPictureId();
  }

  private updateMeasurePointCountByPictureId(): void {
    const map: MeasurePointCountByPictureId = {};

    if (this.pictures && this.measurePointToPictures) {
      for (const picture of this.pictures) {
        const relations = this.measurePointToPictures.filter(
          (r) => r.pictureId === picture.id
        );
        map[picture.id] = relations.length;
      }
    }

    this.measurePointCountByPictureId = map;
  }

  protected handleMeasurePointClicked(event: TMeasurePointClickedEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'measure-point-clicked',
      detail: event.detail
    });
  }

  protected async handleDeletePictureClick(): Promise<void> {
    const displayedPicture = this.displayedPicture;
    assertNotNullOrUndefined(
      displayedPicture,
      "can't ProcessTaskMeasurePointPicture.handleDeletePictureClick without displayedPicture"
    );
    if (!displayedPicture) {
      return;
    }

    await Dialogs.deleteEntityDialog(displayedPicture);
    this.entityManager.pictureRepository.delete(displayedPicture);
    if (displayedPicture === this.displayedPicture && this.pictures) {
      this.displayedPicture =
        this.pictures.find((p) => p !== displayedPicture) ?? null;
    }

    const entry = displayedPicture.entry
      ? this.entityManager.entryRepository.getById(displayedPicture.entry)
      : null;
    if (entry) {
      this.entityManager.entryRepository.delete(entry);
    }
  }

  protected handleSketchPictureClick(): void {
    assertNotNullOrUndefined(
      this.displayedPicture,
      "can't ProcessTaskMeasurePointPicture.handleSketchPictureClick without displayedPicture"
    );
    this.pictureSketchingService.sketchPicture(this.displayedPicture);
  }

  protected handleAddSketchClick(): void {
    if (!this.processTask) {
      return;
    }

    const creator = this.preparePictureCreation(this.processTask);
    this.displayedPicture = creator.createWhitePicture();
    this.addCoordsToPicture(this.displayedPicture);
  }

  protected async handleTakePictureClick(): Promise<void> {
    if (!this.processTask) {
      return;
    }

    await DataStorageHelper.setItem(
      ProcessTaskMeasurePointPicture.CAPTURE_PICTURE_DATA_STORAGE_KEY,
      {
        processTaskId: this.processTask.id
      }
    );
    await EmbeddedCamera.capturePicture({});
  }

  private async handleEmbeddedCameraDataUrlAvailable(): Promise<void> {
    const data: CapturePictureData | null = await DataStorageHelper.getItem(
      ProcessTaskMeasurePointPicture.CAPTURE_PICTURE_DATA_STORAGE_KEY
    );
    if (data && EmbeddedCamera.hasLastDataUrlPicture()) {
      const processTask = this.entityManager.processTaskRepository.getById(
        data.processTaskId
      );
      if (processTask) {
        const creator = this.preparePictureCreation(processTask);
        const picture = creator.createPictureFromDataUrl(
          EmbeddedCamera.getLastDataUriPictureOnce() as string
        );
        this.addCoordsToPicture(picture);

        if (processTask === this.processTask) {
          this.displayedPicture = picture;
        }

        await DataStorageHelper.setItem(
          ProcessTaskMeasurePointPicture.CAPTURE_PICTURE_DATA_STORAGE_KEY,
          null
        );
      }
    }
  }

  private handleEmbeddedCameraClosed(): void {
    void DataStorageHelper.setItem(
      ProcessTaskMeasurePointPicture.CAPTURE_PICTURE_DATA_STORAGE_KEY,
      null
    );
  }

  protected handleResetForcedDisplayedPictureClick(): void {
    this.displayedPicture = null;
  }

  protected handlePicturesCreated(event: PicturesCreatedEvent): void {
    const pictures = event.detail.pictures;

    for (const picture of pictures) {
      assertNotNullOrUndefined(
        picture.ownerProjectId,
        'non project pictures are not supported (and this should actually never happen)'
      );

      const entry = this.entityManager.entryRepository.create({
        project: picture.ownerProjectId,
        ownerProjectId: picture.ownerProjectId,
        ownerUserGroupId: picture.ownerUserGroupId
      });

      // move the picture to the entry. Ideally we would have a callback or an option to automatically do that, but currently this is not possible. (Would pretty much need an refactoring for the whole picture creation process)
      // TODO: improve this. Wait for the new EntityManager before doing that though
      picture.picture_of_project = null;
      picture.entry = entry.id;
      this.entityManager.pictureRepository.update(picture);
    }

    const firstPicture = pictures[0];
    if (firstPicture) this.displayedPicture = firstPicture;
  }

  private preparePictureCreation(
    processTask: ProcessTask
  ): PictureCreatorServiceWithEntityData {
    const project = this.processTaskProjectService.getOrCreateProject(
      processTask,
      null,
      ProcessTaskToProjectType.MEASURE_POINT_PICTURES
    );
    const entry = this.entityManager.entryRepository.create({
      project: project.id,
      ownerProjectId: project.id,
      ownerUserGroupId: project.ownerUserGroupId
    });

    return this.createPictureCreator(project, entry);
  }

  private createPictureCreator(
    project: Project,
    entry: Entry
  ): PictureCreatorServiceWithEntityData {
    return this.pictureCreatorService.withEntityInfos(() => ({
      mainEntityId: project.id,
      mainEntityIdField: 'project',
      subEntityValue: entry.id,
      subEntityField: 'entry',
      ownerUserGroupId: project.ownerUserGroupId,
      ownerProjectId: project.id
    }));
  }

  protected ensureProject(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ensureProject without a processTask"
    );
    this.projectHandle.project =
      this.processTaskProjectService.getOrCreateProject(
        this.processTask,
        null,
        ProcessTaskToProjectType.MEASURE_POINT_PICTURES
      );
  }

  private addCoordsToPicture(picture: Picture): void {
    const coordinates = CoordinateHelper.getClientCoordinates();
    if (coordinates.latitude && coordinates.longitude) {
      picture.coords = {
        latitude: coordinates.latitude,
        longitude: coordinates.longitude
      };
    }
  }

  private autoSelectDisplayedPicture(): void {
    if (
      this.displayedPicture ||
      this.pictures == null ||
      this.measurePointToPictures == null
    ) {
      return;
    }

    const measurePointToPictures = this.measurePointToPictures;
    let displayedPicture =
      this.pictures.find((p) => {
        return !!measurePointToPictures.find((r) => r.pictureId === p.id);
      }) ?? null;

    if (!displayedPicture) {
      displayedPicture = this.pictures[0] ?? null;
    }

    this.displayedPicture = displayedPicture;
  }

  @computedFrom(
    'displayedPicture.entry',
    'displayedPictureEntryPermissionsHandle.canDeleteEntity',
    'displayedPicturePermissionsHandle.canDeleteEntity'
  )
  protected get canDeleteDisplayedPicture(): boolean {
    if (!this.displayedPicture) {
      return false;
    }

    if (
      this.displayedPicture.entry &&
      !this.displayedPictureEntryPermissionsHandle.canDeleteEntity
    ) {
      return false;
    }

    return this.displayedPicturePermissionsHandle.canDeleteEntity;
  }
}

type CapturePictureData = {
  processTaskId: string;
};

type MeasurePointCountByPictureId = Partial<Record<string, number>>;
