import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { HistoryService } from '../../services/HistoryService/HistoryService';

export class AutoScrollToProcessTaskHandler {
  private readonly historyService: HistoryService;
  private readonly entityManager: AppEntityManager;
  private readonly scrollToProcessTask: ScrollToProcessTask;

  private hasTriedAutoScrolling: boolean = false;

  constructor({
    entityManager,
    historyService,
    scrollToProcessTask
  }: {
    entityManager: AppEntityManager;
    historyService: HistoryService;
    scrollToProcessTask: ScrollToProcessTask;
  }) {
    this.entityManager = entityManager;
    this.historyService = historyService;
    this.scrollToProcessTask = scrollToProcessTask;
  }

  public processTaskGroupsLoaded(
    processTaskGroups: Array<ProcessTaskGroup>
  ): void {
    this.tryAutoScrolling(processTaskGroups);
  }

  private tryAutoScrolling(processTaskGroups: Array<ProcessTaskGroup>): void {
    if (this.hasTriedAutoScrolling) {
      return;
    }

    const previousInstruction = this.historyService.getPreviousInstruction();
    if (
      previousInstruction?.config.name === 'edit_process_task' &&
      previousInstruction.params.process_task_id
    ) {
      const processTask = this.entityManager.processTaskRepository.getById(
        previousInstruction.params.process_task_id
      );
      const processTaskGroup = processTask
        ? processTaskGroups.find(
            (group) => group.id === processTask.ownerProcessTaskGroupId
          )
        : null;

      if (processTask && processTaskGroup) {
        setTimeout(() => {
          // wait for aurelia to render
          this.scrollToProcessTask({ processTask, processTaskGroup });
        }, 0);
      }
    }

    this.hasTriedAutoScrolling = true;
  }
}

export type ScrollToProcessTask = (options: {
  processTask: ProcessTask;
  processTaskGroup: ProcessTaskGroup;
}) => void;
