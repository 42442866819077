import { bindable, autoinject } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Key } from '../../classes/Key';
import { FloatingLabelInput } from '../floating-label-input/floating-label-input';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';

/**
 * @attribute data-flex-layout - allows the clickable-text-input to be directly integrated into a flex layout
 *
 * @event text-changed - fired when the text has changed, bubbles
 */
@autoinject()
export class CompactClickableTextInput {
  @bindable public text: string | null = null;

  @bindable public enabled = true;

  @bindable public textToHighlight: string | null = null;

  protected internalText: string | null = null;

  protected isInEditMode = false;

  protected floatingLabelInput: FloatingLabelInput | null = null;

  protected isSaving = false;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  // /////////// PUBLIC METHODS /////////////

  public focus(): void {
    this.startEditMode();
  }

  // /////////// METHODS /////////////

  private startEditMode(): void {
    this.isInEditMode = true;
    // wait for floatingLabelInput to show up
    setTimeout(() => {
      assertNotNullOrUndefined(
        this.floatingLabelInput,
        'Cannot start edit mode without floatingLabelInput'
      );
      this.floatingLabelInput.focus();
    }, 0);
  }

  private stopEditMode(): void {
    this.isInEditMode = false;
    const textHasChanged = this.text !== this.internalText;
    this.text = this.internalText;

    if (textHasChanged) {
      this.isSaving = true;
      setTimeout(
        () =>
          DomEventHelper.fireEvent(this.element, {
            name: 'text-changed',
            detail: null,
            bubbles: true
          }),
        0
      );
      setTimeout(() => {
        this.isSaving = false;
      }, 1000);
    }
  }

  // /////////// OBSERVABLES /////////////

  protected textChanged(): void {
    this.internalText = this.text;
  }

  // /////////// EVENT HANDLERS /////////////

  protected handleDisplayTextClick(): void {
    if (this.enabled) {
      this.startEditMode();
    }
  }

  protected handleEditIconClick(): void {
    if (this.enabled) {
      this.startEditMode();
    }
  }

  protected handleStopEditIconClick(): void {
    this.stopEditMode();
  }

  protected handleInputKeydownEvent(event: KeyboardEvent): boolean {
    if (event.key === Key.ENTER) {
      this.stopEditMode();
      return false;
    } else {
      return true;
    }
  }

  protected handleInputBlurEvent(): void {
    this.stopEditMode();
  }
}
