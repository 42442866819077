import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createPersonContactEntityInfo } from 'common/Types/Entities/PersonContact/createPersonContactEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { PersonContact } from './types';

export const personContactEntityInfo =
  createPersonContactEntityInfo<PersonContact>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
