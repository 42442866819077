import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { GeneralFileHelper as CommonGeneralFileHelper } from 'common/EntityHelper/GeneralFileHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { GeneralFileUtils } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUtils';
import { GeneralFile } from '../../classes/EntityManager/entities/GeneralFile/types';
import { FileDownloadService } from '../../services/FileDownloadService';

/**
 * A (horizontal) list of file links meant to be displayed below some text inside a card.
 * Also has a slot for placing some content on the bottom-right of list
 *
 * @slot bottom-right
 */
@autoinject()
export class FileListWithBottomRightContent {
  @bindable()
  public files: Array<GeneralFile> = [];

  protected readonly GeneralFileUtils = GeneralFileUtils;
  protected readonly CommonGeneralFileHelper = CommonGeneralFileHelper;

  constructor(private readonly fileDownloadService: FileDownloadService) {}

  protected handleGeneralFileLinkClick(file: GeneralFile): boolean {
    if (DeviceInfoHelper.isApp()) {
      const filePath =
        GeneralFileUtils.getRelativeOnlineFilePathForGeneralFile(file);

      assertNotNullOrUndefined(
        filePath,
        "can't download a file without a filePath"
      );

      void this.fileDownloadService.downloadFile(filePath);

      return false;
    }

    return true;
  }
}
