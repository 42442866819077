import { DateUtils } from 'common/DateUtils';

export namespace ChecklistProjectNameUtils {
  /**
   * Get the name of a checklist project from a date.
   */
  export function fromDate(date: Date | string): string {
    return `$$${DateUtils.getIsoDateWithoutTime(new Date(date))}$$`;
  }

  /**
   * Get the name of a checklist project (including time) from a date.
   */
  export function fromDateWithTime(date: Date | string): string {
    return `$$${DateUtils.getIsoDateWithHourAndMinute(new Date(date))}$$`;
  }

  /**
   * Retrieve the date from the name of a checklist project.
   *
   * Returns the date with the time set to 00:00:00.000 in the local timezone.
   */
  export function toDate(projectName: string): Date {
    const parsedDate = projectName.substring(2, projectName.length - 2);
    if (parsedDate.length === 16) {
      return new Date(`${parsedDate}:00.000`);
    }
    return new Date(`${parsedDate}T00:00:00.000`);
  }

  /**
   * Retrieve the date from the name of a checklist project, formatted nicely
   * for display purposes.
   */
  export function toDateString(projectName: string): string {
    const date = ChecklistProjectNameUtils.toDate(projectName);
    return DateUtils.formatToDateString(date);
  }

  /**
   * Retrieve the date from the name of a checklist project, formatted nicely
   * for display purposes (also includes HH:mm time format).
   */
  export function toDateStringWithTime(projectName: string): string {
    const date = ChecklistProjectNameUtils.toDate(projectName);
    return DateUtils.formatToDateWithHourMinuteString(date);
  }
}
