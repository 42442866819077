import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { MainPageLoaderHelper } from '../../MainPageLoaderHelper';
import { storeInfo } from '../storeInfo';
import { DoubleJsonMigration } from './DoubleJsonMigration';

export class SqliteMigrator {
  private readonly store: SQLiteDBConnection;

  constructor(options: { store: SQLiteDBConnection }) {
    this.store = options.store;
  }

  public async migrate({
    storeWasCreated
  }: {
    storeWasCreated: boolean;
  }): Promise<void> {
    const missingMigrations = await this.getMissingMigrations();

    if (storeWasCreated) {
      return this.saveCompletedMigrationNames({
        migrationNames: missingMigrations.map((migration) => migration.name),
        transaction: true
      });
    } else {
      await this.applyMigrations(missingMigrations);
    }
  }

  private async getMissingMigrations(): Promise<Array<Migration>> {
    const appliedMigrationNames = await this.getAppliedMigrationNames();

    return this.getMigrations().filter((migration) => {
      return !appliedMigrationNames.includes(migration.name);
    });
  }

  private getMigrations(): Array<Migration> {
    const migrations: Array<Migration> = [
      {
        name: 'doubleJson',
        migrate: async ({ updateProgress }) => {
          const migration = new DoubleJsonMigration({ store: this.store });
          await migration.migrate({
            updateProgress
          });
        }
      }
    ];

    this.validateMigrations(migrations);

    return migrations;
  }

  private validateMigrations(migrations: Array<Migration>): void {
    const usedMigrationNames = new Set<string>();

    for (const migration of migrations) {
      if (usedMigrationNames.has(migration.name)) {
        throw new Error(
          `the migration name "${migration.name}" is already used`
        );
      }

      usedMigrationNames.add(migration.name);
    }
  }

  private async getAppliedMigrationNames(): Promise<Array<string>> {
    const result = await this.store.query(
      `SELECT key FROM ${storeInfo.migrationsTableName}`
    );
    return (
      (result.values as Array<{ key: string }> | null)?.map((row) => row.key) ??
      []
    );
  }

  private async applyMigrations(migrations: Array<Migration>): Promise<void> {
    try {
      for (const [index, migration] of migrations.entries()) {
        await this.store.run('BEGIN EXCLUSIVE TRANSACTION', undefined, false);

        MainPageLoaderHelper.setInitializationStep(
          `applying migration ${index + 1} of ${migrations.length}: ${
            migration.name
          }`,
          'SqliteMigrator.migrationMessage'
        );

        await migration.migrate({
          updateProgress: ({ progress, maxProgress }) => {
            MainPageLoaderHelper.setInitializationStep(
              `migrating ${migration.name}: ${progress}/${maxProgress}`,
              `SqliteMigrator.${migration.name}`
            );
          }
        });

        await this.saveCompletedMigrationNames({
          migrationNames: [migration.name],
          transaction: false
        });

        await this.store.run('COMMIT', undefined, false);
      }
    } catch (error) {
      await this.store.run('ROLLBACK', undefined, false);
      throw error;
    }
  }

  private async saveCompletedMigrationNames({
    migrationNames,
    transaction
  }: {
    migrationNames: Array<string>;
    transaction: boolean;
  }): Promise<void> {
    await this.store.executeSet(
      migrationNames.map((migrationName) => {
        return {
          statement: `INSERT INTO ${storeInfo.migrationsTableName} (key, value) VALUES (?, ?)`,
          values: [
            migrationName,
            JSON.stringify({ date: new Date().toISOString() })
          ]
        };
      }),
      transaction
    );
  }
}

type Migration = {
  name: string;
  migrate: (options: { updateProgress: UpdateProgress }) => Promise<void>;
};

export type UpdateProgress = (options: UpdateProgressOptions) => void;
export type UpdateProgressOptions = {
  progress: number;
  maxProgress: number;
};

export type MigrateOptions = {
  /**
   * Should be true if the store was just created.
   * If this is true, migrations will not be executed since there is no data in the store
   */
  storeWasCreated: boolean;
};
