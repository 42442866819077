import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DialogIconType } from 'common/Enums/DialogIconType';

import { AssignProcessTaskGroupUserDialog } from '../assign-process-task-group-user-dialog/assign-process-task-group-user-dialog';
import {
  ButtonType,
  GlobalCustomDialog
} from '../../dialogs/global-custom-dialog/global-custom-dialog';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskGroupAssigneeService } from '../../classes/EntityManager/entities/ProcessTaskGroup/ProcessTaskGroupAssigneeService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class ProcessTaskInfoOverviewAssigneeWidget {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @subscribableLifecycle()
  protected readonly processTaskGroupPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroup];

  protected editTooltipContent: TooltipContent | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskGroupAssigneeService: ProcessTaskGroupAssigneeService,
    permissionsService: PermissionsService
  ) {
    this.processTaskGroupPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroup,
        context: this as ProcessTaskInfoOverviewAssigneeWidget,
        propertyName: 'processTaskGroup'
      });
  }

  protected handleAssignUserClick(): void {
    if (!this.processTaskGroup || !this.processTask) {
      return;
    }

    void AssignProcessTaskGroupUserDialog.open({
      processTaskGroup: this.processTaskGroup,
      processTaskInfo: { processTask: this.processTask }
    });
  }

  protected handleEditAssignmentClick(): void {
    assertNotNullOrUndefined(
      this.editTooltipContent,
      "can't ProcessTaskInfoOverviewAssigneeWidget.handleEditAssignmentClick without editTooltipContent"
    );

    this.editTooltipContent.open();
  }

  protected async handleResetAssigneeClick(): Promise<void> {
    if (!this.processTaskGroup) {
      return;
    }

    await GlobalCustomDialog.open({
      titleTk:
        'operations.processTaskInfoOverview.assigneeWidget.resetAssigneeWarning',
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'general.yes',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });

    this.processTaskGroupAssigneeService.setAssignee({
      processTaskGroup: this.processTaskGroup,
      assigneeData: null
    });
  }

  protected handleHandBackToAssignedFromUserClick(): void {
    assertNotNullOrUndefined(
      this.processTaskGroup,
      "can't ProcessTaskInfoOverviewAssigneeWidget.handleHandBackToAssignedFromUserClick without processTaskGroup"
    );
    assertNotNullOrUndefined(
      this.processTaskGroup.assignedFromUserId,
      "can't ProcessTaskInfoOverviewAssigneeWidget.handleHandBackToAssignedFromUserClick with a processTaskGroup without a assignedFromUserId"
    );

    void AssignProcessTaskGroupUserDialog.open({
      processTaskGroup: this.processTaskGroup,
      lockedUserId: this.processTaskGroup.assignedFromUserId,
      processTaskInfo: this.processTaskGroup.assignedProcessTaskName
        ? { processTaskName: this.processTaskGroup.assignedProcessTaskName }
        : null
    });
  }

  protected getUserNameById(userId: string | null): string | null {
    const user = userId
      ? this.entityManager.userRepository.getById(userId)
      : null;
    return user ? user.username : null;
  }
}
