import {
  PictureFileExtension,
  PictureFileType
} from 'common/Types/Entities/PictureFile/PictureFileDto';
import { EntityName } from '../types';
import { PictureFile } from './types';
import { Picture } from '../Picture/types';
import { DeviceInfoHelper } from '../../../DeviceInfoHelper';
import { AppEntityRepository } from '../../base/AppEntityRepository';

export class PictureFileRepository extends AppEntityRepository<EntityName.PictureFile> {
  public getByPictureIds(pictureIds: Array<string>): Array<PictureFile> {
    return this.getAll().filter((pF) => pictureIds.indexOf(pF.picture) >= 0);
  }

  public getByPictureId(pictureId: string): Array<PictureFile> {
    return this.getAll().filter(
      (pictureFile) => pictureFile.picture === pictureId
    );
  }

  public getByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): Array<PictureFile> {
    if (revisionId) {
      return this.getAll().filter((pictureFile) => {
        return pictureFile.pictureRevisionId === revisionId;
      });
    } else {
      return this.getAll().filter((pictureFile) => {
        return pictureFile.picture === pictureId;
      });
    }
  }

  public getPictureFileToDisplayByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): PictureFile | null {
    let pictureFile = this.getEditedPictureFileByRevisionId({
      pictureId,
      revisionId
    });
    if (!pictureFile) {
      pictureFile = this.getOriginalPictureFileByRevisionId({
        pictureId,
        revisionId
      });
    }
    return pictureFile;
  }

  public getEditedPictureFileByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): PictureFile | null {
    return this.getPictureFileWithType({
      pictureId,
      revisionId,
      type: PictureFileType.EDITED
    });
  }

  public getOriginalPictureFileByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): PictureFile | null {
    return this.getPictureFileWithType({
      pictureId,
      revisionId,
      type: PictureFileType.ORIGINAL
    });
  }

  public getSketchPictureFileByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): PictureFile | null {
    return this.getPictureFileWithType({
      pictureId,
      revisionId,
      type: PictureFileType.SKETCH
    });
  }

  public getCroppedPictureFileByRevisionId({
    pictureId,
    revisionId = null
  }: {
    pictureId: string;
    revisionId?: string | null;
  }): PictureFile | null {
    return this.getPictureFileWithType({
      pictureId,
      revisionId,
      type: PictureFileType.CROPPED
    });
  }

  public createPictureFileForPicture({
    picture,
    type,
    extension = PictureFileExtension.JPG,
    pictureRevisionId = null
  }: {
    picture: Picture;
    type: PictureFileType;
    extension?: PictureFileExtension;
    pictureRevisionId: string | null;
  }): PictureFile {
    return this.create({
      picture: picture.id,
      project: picture.project ?? null, // DEPRECATED FIELD
      type: type,
      file_extension: extension,
      ownerUserGroupId: picture.ownerUserGroupId,
      ownerProjectId: picture.ownerProjectId ?? null,
      ownerDefectId: picture.ownerDefectId ?? null,
      ownerProcessConfigurationId: picture.ownerProcessConfigurationId ?? null,
      ownerProcessTaskGroupId: picture.ownerProcessTaskGroupId ?? null,
      readyForUpload: DeviceInfoHelper.isApp() ? false : true, // app images are not instantly ready to upload because there is some file handling to be done in the background
      readyToSynchronize: DeviceInfoHelper.isApp() ? false : true, // pictureFiles in the app shouldn't be synchronized immediately, because the id can be upgraded while a file is still getting written and that would result in missing files because they can't be tracked anymore.
      temporaryGroupName: picture.temporaryGroupName,
      shadowEntity: picture.shadowEntity,
      pictureRevisionId: pictureRevisionId
    });
  }

  private getPictureFileWithType({
    pictureId,
    revisionId = null,
    type
  }: {
    pictureId: string;
    revisionId: string | null;
    type: PictureFileType;
  }): PictureFile | null {
    if (revisionId) {
      return (
        this.getAll().find((pictureFile) => {
          return (
            pictureFile.pictureRevisionId === revisionId &&
            pictureFile.type === type
          );
        }) ?? null
      );
    } else {
      return (
        this.getAll().find((pictureFile) => {
          return pictureFile.picture === pictureId && pictureFile.type === type;
        }) ?? null
      );
    }
  }
}
