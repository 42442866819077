import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { UserRole } from './types';

export class UserRoleRepository extends AppEntityRepository<EntityName.UserRole> {
  public getByUserGroupId(ownerUserGroupId: string): Array<UserRole> {
    return this.getAll().filter(
      (role) => role.ownerUserGroupId === ownerUserGroupId
    );
  }

  public getByUserGroupIds(ownerUserGroupIds: Array<string>): Array<UserRole> {
    return this.getAll().filter((role) =>
      ownerUserGroupIds.includes(role.ownerUserGroupId)
    );
  }
}
