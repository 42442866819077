import { assertNotNullOrUndefined } from '../Asserts';
import { IdType } from '../Types/Entities/Base/types';
import { PictureDto } from '../Types/Entities/Picture/PictureDto';

export class PictureHelper {
  public static getRequiredMainEntityInfo<T extends IdType>(
    picture: PictureMainEntityInfoRelevantFields<T>
  ): PictureMainEntityInfo<T> {
    const mainEntityInfo = this.getMainEntityInfo(picture);
    assertNotNullOrUndefined(
      mainEntityInfo,
      `no mainentity info found for picture ${picture.id}`
    );

    return mainEntityInfo;
  }

  public static getMainEntityInfo<T extends IdType>(
    picture: PictureMainEntityInfoRelevantFields<T>
  ): PictureMainEntityInfo<T> | null {
    // REMINDER: ownerIds are assigned recursively to parent entities => check for child before you check for parent
    if (picture.ownerDefectId) {
      return {
        id: picture.ownerDefectId,
        name: 'ownerDefectId'
      };
    }

    if (picture.ownerProcessConfigurationId) {
      return {
        id: picture.ownerProcessConfigurationId,
        name: 'processConfiguration'
      };
    }

    if (picture.ownerProcessTaskGroupId) {
      return {
        id: picture.ownerProcessTaskGroupId,
        name: 'processTaskGroup'
      };
    }

    const projectId = picture.project ?? picture.ownerProjectId;
    if (projectId) {
      return {
        id: projectId,
        name: 'project'
      };
    }

    const thingId = picture.thing || picture.ownerThingId;
    if (thingId) {
      return {
        id: thingId,
        name: 'thing'
      };
    }

    return null;
  }
}

export type PictureMainEntityInfoRelevantFields<T extends IdType> = Pick<
  PictureDto<T, any>,
  | 'id'
  | 'ownerProjectId'
  | 'project'
  | 'ownerDefectId'
  | 'thing'
  | 'ownerThingId'
  | 'ownerProcessConfigurationId'
  | 'ownerProcessTaskGroupId'
>;

export type PictureMainEntityInfo<T extends IdType> = {
  name:
    | 'project'
    | 'thing'
    | 'ownerDefectId'
    | 'processConfiguration'
    | 'processTaskGroup';
  id: T;
};
