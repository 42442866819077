import { inject } from 'aurelia-framework';

import { ProcessTaskGroupSynchronizationManagerService } from './ProcessTaskGroupSynchronizationManagerService/ProcessTaskGroupSynchronizationManagerService';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';

@inject(AppEntityManager, ProcessTaskGroupSynchronizationManagerService)
export class ProcessTaskGroupsWithSubEntitiesLoadingService {
  /** @type {ProcessTaskGroupSynchronizationManagerService} */
  _processTaskGroupSynchronizationManagerService;

  /**
   * @param {AppEntityManager} entityManager
   * @param {ProcessTaskGroupSynchronizationManagerService} processTaskGroupSynchronizationManagerService
   */
  constructor(entityManager, processTaskGroupSynchronizationManagerService) {
    this._entityManager = entityManager;
    this._processTaskGroupSynchronizationManagerService =
      processTaskGroupSynchronizationManagerService;
  }

  /**
   * this will remove the old loaded response and load the new one
   * if an entity already exists, no action will be taken
   * be careful, this introduces a possible bug (which is not handled for now, because this is fixed by navigating to the page which loads the response again):
   *  when temporarily loading a processTaskGroups which you already have synced (e.g. because you have an appointment in that group)
   *  and then unsyncing the group (e.g. deleting the appointment) then the group will not be loaded anymore and is inaccessible
   *
   * @param {import('../../../common/src/Types/ProcessTaskGroupsWithSubEntities').ProcessTaskGroupsWithSubEntities<string, string>} processTaskGroupsWithSubEntities
   * @param {string} temporaryGroupName
   */
  loadEntitiesTemporarily(
    processTaskGroupsWithSubEntities,
    temporaryGroupName
  ) {
    const processTaskGroupIds =
      processTaskGroupsWithSubEntities.processTaskGroups.map((p) => p.id);
    this.allocateProcessTaskGroupIdsAsTemporary(
      processTaskGroupIds,
      temporaryGroupName
    );

    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskGroups,
      temporaryGroupName,
      this._entityManager.processTaskGroupRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskGroupAuthorizations,
      temporaryGroupName,
      this._entityManager.processTaskGroupAuthorizationRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTasks,
      temporaryGroupName,
      this._entityManager.processTaskRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskAppointmentToProcessTaskPositions,
      temporaryGroupName,
      this._entityManager.processTaskAppointmentToProcessTaskPositionRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskAppointments,
      temporaryGroupName,
      this._entityManager.processTaskAppointmentRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskAppointmentContacts,
      temporaryGroupName,
      this._entityManager.processTaskAppointmentContactRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskAppointmentToProcessTaskDevices,
      temporaryGroupName,
      this._entityManager.processTaskAppointmentToProcessTaskDeviceRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskAppointmentToUsers,
      temporaryGroupName,
      this._entityManager.processTaskAppointmentToUserRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskChecklistEntries,
      temporaryGroupName,
      this._entityManager.processTaskChecklistEntryRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskComments,
      temporaryGroupName,
      this._entityManager.processTaskCommentRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskDevices,
      temporaryGroupName,
      this._entityManager.processTaskDeviceRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskInvoices,
      temporaryGroupName,
      this._entityManager.processTaskInvoiceRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskInvoiceToProcessTasks,
      temporaryGroupName,
      this._entityManager.processTaskInvoiceToProcessTaskRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskInvoiceToProcessTaskDevices,
      temporaryGroupName,
      this._entityManager.processTaskInvoiceToProcessTaskDeviceRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskInvoiceToProcessTaskPositions,
      temporaryGroupName,
      this._entityManager.processTaskInvoiceToProcessTaskPositionRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskLogEntries,
      temporaryGroupName,
      this._entityManager.processTaskLogEntryRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskOffers,
      temporaryGroupName,
      this._entityManager.processTaskOfferRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskOfferToProcessTasks,
      temporaryGroupName,
      this._entityManager.processTaskOfferToProcessTaskRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskOfferToProcessTaskDevices,
      temporaryGroupName,
      this._entityManager.processTaskOfferToProcessTaskDeviceRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskOfferToProcessTaskPositions,
      temporaryGroupName,
      this._entityManager.processTaskOfferToProcessTaskPositionRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskPositions,
      temporaryGroupName,
      this._entityManager.processTaskPositionRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskToProjects,
      temporaryGroupName,
      this._entityManager.processTaskToProjectRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskMeasurePoints,
      temporaryGroupName,
      this._entityManager.processTaskMeasurePointRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskMeasurePointReadings,
      temporaryGroupName,
      this._entityManager.processTaskMeasurePointReadingRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.processTaskMeasurePointToPictures,
      temporaryGroupName,
      this._entityManager.processTaskMeasurePointToPictureRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.generalFiles,
      temporaryGroupName,
      this._entityManager.generalFileRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.properties,
      temporaryGroupName,
      this._entityManager.propertyRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.pictures,
      temporaryGroupName,
      this._entityManager.pictureRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.pictureFiles,
      temporaryGroupName,
      this._entityManager.pictureFileRepository
    );
    this._loadEntitiesTemporarily(
      processTaskGroupsWithSubEntities.pictureRevisions,
      temporaryGroupName,
      this._entityManager.pictureRevisionRepository
    );

    if (this._entityManager.joinedProcessTaskGroupManager.isStarted()) {
      this._entityManager.joinedProcessTaskGroupManager.joinAllProcessTaskGroups();
    }
  }

  /**
   * mark the processTaskGroupIds as temporary
   *
   * @param {Array<string>} processTaskGroupIds
   * @param {string} temporaryGroupName
   */
  allocateProcessTaskGroupIdsAsTemporary(
    processTaskGroupIds,
    temporaryGroupName
  ) {
    this._processTaskGroupSynchronizationManagerService.clearTemporaryGroupName(
      temporaryGroupName
    );
    this._processTaskGroupSynchronizationManagerService.mapTemporaryGroupName(
      processTaskGroupIds,
      temporaryGroupName
    );
  }

  /**
   * return all tracked (= loaded into the ProcessTaskGroupService) processTaskGroups
   * make sure to load the entities before calling this
   *
   * @param {import('../../../common/src/Types/ProcessTaskGroupsWithSubEntities').ProcessTaskGroupsWithSubEntities<string, string>} processTaskGroupsWithSubEntities
   * @returns {Array<import('../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup>}
   */
  getTrackedProcessTaskGroups(processTaskGroupsWithSubEntities) {
    const ids = processTaskGroupsWithSubEntities.processTaskGroups.map(
      (p) => p.id
    );

    const groups = ids.map((id) => {
      return this._entityManager.processTaskGroupRepository.getByIdIncludingInvisibleEntities(
        id
      );
    });

    return /** @type {Array<import('../classes/EntityManager/entities/ProcessTaskGroup/types').ProcessTaskGroup>} */ (
      groups.filter((g) => !!g)
    );
  }

  /**
   *
   * @param {Array<Partial<import('../classes/EntityManager/entities/BaseEntity').BaseEntity>>} entities
   * @param {string} temporaryGroupName
   * @param {import('../classes/EntityManager/base/AppEntityRepository').AppEntityRepository<any>} entityRepository
   */
  _loadEntitiesTemporarily(entities, temporaryGroupName, entityRepository) {
    // remove all we have created temporarily
    entityRepository.getAll().forEach((entity) => {
      if (entity.temporaryGroupName === temporaryGroupName) {
        entityRepository.removeLocally(entity);
      }
    });

    // track the new temporary entities
    entities.forEach((entity) => {
      const existingEntity = entityRepository.getById(entity.id);

      if (!existingEntity) {
        entity.temporaryGroupName = temporaryGroupName;
        entityRepository.addExternalEntityLocally(entity);
      }
    });
  }
}
