import { SubscriptionManager } from '../classes/SubscriptionManager';

/**
 * Internal state of the hook needs to be managed in the target class,
 * so it can be created & destroyed by the classes' lifecycle.
 */
export type HookState = {
  /**
   * SubscriptionManager needed for managing the lifecycle of dependencies.
   */
  subscriptionManager: SubscriptionManager;
};

/**
 * Retrieve the property name of the changeProp.
 *
 * Getter functions in Aurelia work in one of two ways:
 *   - If a getter function declares a property with `descriptor.get.dependencies`,
 *     Aurelia will only call the getter if the given property changes
 *   - If a getter function doesn't declare dependencies,
 *     it gets called every 120ms
 *
 * Since we want the first one, we need a second property that we can update
 * whenever we want Aurelia to call our getter
 *
 * @param prop the property passed into the method decorator.
 */
export const getChangeProp = <T extends string>(prop: T): ChangeProp<T> =>
  `__${prop}__ComputedHook`;
export type ChangeProp<T extends string> = `__${T}__ComputedHook`;

/**
 * Retrieve the property name where internal state should be stored.
 *
 * @param prop the property passed into the method decorator.
 */
export const getHookStateProp = <T extends string>(prop: T): HookStateProp<T> =>
  `${getChangeProp(prop)}_State`;
export type HookStateProp<T extends string> = `${ChangeProp<T>}_State`;
