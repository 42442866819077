import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import {
  FilterType,
  PropertyFilter
} from 'common/Types/GalleryThingPictureFilter/GalleryThingPicturePropertyFilter';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { filterTypeToFilterConfiguration } from '../../classes/GalleryThing/GalleryThingFilterHelper';

@autoinject()
export class GalleryThingPropertyWidget {
  @bindable public property: PropertyFilter | null = null;

  @bindable public isFilter: boolean | null = null;

  protected selectionSwitchChoices: Array<Choice> = [
    { value: 'true', label: '<i class="far fa-check"></i>' },
    { value: 'false', label: '<i class="far fa-times"></i>' }
  ];

  protected FilterType = FilterType;

  protected PropertyHelper = PropertyHelper;

  protected PropertyType = PropertyType;

  private domElement: HTMLElement;

  constructor(
    element: Element,
    protected readonly i18n: I18N
  ) {
    this.domElement = element as HTMLElement;
  }

  @computedFrom('property.type')
  protected get getPropertyType(): PropertyType | null {
    if (!this.property?.type) return null;

    return filterTypeToFilterConfiguration[this.property.type]
      .correspondingPropertyType;
  }

  @computedFrom('property.type')
  protected get getFilterType(): FilterType | null {
    if (!this.property?.type) return null;

    if (
      !this.isFilter &&
      filterTypeToFilterConfiguration[this.property.type].isFilterExclusive
    )
      throw new Error(
        'Filter-only property type is used to create or edit a property'
      );
    return this.property.type;
  }

  protected handleValueChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}

type Choice = {
  value: string;
  label: string;
};
