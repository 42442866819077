export class AngleHelper {
  /**
   *
   * @param {number} deg
   * @returns {number}
   */
  static degToRad(deg) {
    return (deg * Math.PI) / 180;
  }

  /**
   *
   * @param {number} rad
   * @returns {number}
   */
  static radToDeg(rad) {
    return (rad * 180) / Math.PI;
  }

  /**
   *
   * flip the angle from the clockwise to the counter clockwise representation (and vice versa)
   *
   * e.g. because the transform angle is clockwise and vector angles are counter clockwise we have to convert it
   *
   * @param {number} angle - in deg
   * @returns {number}
   */
  static flipAngle(angle) {
    return this.simplifyDegAngle(360 - this.simplifyDegAngle(angle));
  }

  /**
   * converts the angle to a 0 - 360 range
   *
   * @param {number} angle
   * @returns {number}
   */
  static simplifyDegAngle(angle) {
    const simplified = angle % 360;
    if (simplified < 0) {
      return simplified + 360;
    } else {
      return simplified;
    }
  }
}
