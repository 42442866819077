import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { CalendarEntryHelper } from 'common/Operations/Calendar/CalendarEntryHelper';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  UserAppointmentInfos,
  UserProcessTaskAppointmentInfo,
  UserProcessTaskAppointmentInfosComputer,
  UserProcessTaskRecurringAppointmentInfo
} from '../../../../computedValues/computers/UserProcessTaskAppointmentInfosComputer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  CalendarEntry,
  CalendarEntryType,
  DaySpecificCalendarEntry
} from '../CalendarEntry';
import { ProcessTaskRecurringAppointmentHelper } from 'common/EntityHelper/ProcessTaskRecurringAppointmentHelper';
import {
  CalendarEntryDataSourceStrategy,
  GetCalendarEntriesOptions
} from './CalendarEntryDataSourceStrategy';
import { RecurrenceCalculator } from 'common/Operations/Calendar/RecurrenceCalculator';
import { DateUtils } from 'common/DateUtils';
import { ExprEvalParser } from 'common/ExprEvalParser/ExprEvalParser';

import { CalendarEntryPerDaySplitter } from '../../CalendarEntryPerDaySplitter/CalendarEntryPerDaySplitter';
import { OperationsExpressionEditorScope } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsExpressionEditorScope';
import { OperationsDataFetcher } from '../../../../classes/Operations/OperationsDataFetcher';
import { BaseAppointmentCalendarEntryDayWidgetTexts } from 'common/EndpointTypes/OperationsEndpointsTypes';
import { ProcessTaskAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskRecurringAppointment } from '../../../../classes/EntityManager/entities/ProcessTaskRecurringAppointment/types';

export class CalendarEntriesFromEntityManagerStrategy extends CalendarEntryDataSourceStrategy {
  private readonly computedValueService: ComputedValueService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly exprEvalParser: ExprEvalParser;
  private readonly operationsExpressionEditor: OperationsExpressionEditorScope<
    string,
    string
  >;

  private userAppointmentInfos: UserAppointmentInfos = [];

  constructor(options: {
    entityManager: AppEntityManager;
    computedValueService: ComputedValueService;
    subscriptionManagerService: SubscriptionManagerService;
  }) {
    super(options);
    this.computedValueService = options.computedValueService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.exprEvalParser = new ExprEvalParser();
    this.operationsExpressionEditor = new OperationsExpressionEditorScope(
      new OperationsDataFetcher(this.entityManager)
    );
  }

  public subscribe(): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationStep,
      this.dataChanged.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      this.dataChanged.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskAppointment,
      this.dataChanged.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskRecurringAppointment,
      this.dataChanged.bind(this)
    );

    subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: UserProcessTaskAppointmentInfosComputer,
        computeData: {},
        callback: (userAppointmentInfos) => {
          this.userAppointmentInfos = userAppointmentInfos;
          this.dataChanged();
        }
      })
    );

    return {
      dispose: () => {
        subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public async getCalendarEntries({
    filter,
    useUserColors
  }: GetCalendarEntriesOptions): Promise<Array<DaySpecificCalendarEntry>> {
    const relevantInfos = this.userAppointmentInfos.filter((info) => {
      if (info.type === CalendarEntryType.NORMAL) {
        return filter.userIds.includes(
          info.processTaskAppointmentToUser.userId
        );
      } else if (
        info.type === CalendarEntryType.RECURRING &&
        info.recurringAppointment.assignedUserId
      ) {
        return filter.userIds.includes(
          info.recurringAppointment.assignedUserId
        );
      } else return false;
    });

    const fromTimestamp = filter.date.dateFrom.getTime();
    const toTimestamp = filter.date.dateTo.getTime();

    const calendarEntries = await this.createCalendarEntriesFromAppointments(
      relevantInfos,
      filter.date.dateFrom,
      filter.date.dateTo,
      useUserColors
    );

    return CalendarEntryPerDaySplitter.convertToDaySpecificCalendarEntries(
      calendarEntries
    ).filter((e) => {
      if (fromTimestamp == null || toTimestamp == null) {
        return true;
      }

      return (
        e.calendarEntry.startTimestamp >= fromTimestamp &&
        e.calendarEntry.endTimestamp <= toTimestamp
      );
    });
  }

  private async createCalendarEntriesFromAppointments(
    userAppointmentInfos: UserAppointmentInfos,
    fromDate: Date,
    toDate: Date,
    useUserColors: boolean
  ): Promise<Array<CalendarEntry>> {
    const entries = await Promise.all(
      userAppointmentInfos.map(async (info) => {
        switch (info.type) {
          case CalendarEntryType.NORMAL:
            return await this.createCalendarEntryFromUserProcessTaskAppointmentInfo(
              info,
              useUserColors
            );
          case CalendarEntryType.RECURRING:
            return await this.createCalendarEntryFromUserProcessTaskRecurringAppointmentInfo(
              info,
              fromDate,
              toDate,
              useUserColors
            );
          default:
            throw new Error(
              `CalendarEntryType ${(info as any).type} not handled.`
            );
        }
      })
    );
    return entries.flat().filter((info): info is CalendarEntry => !!info);
  }

  private async createCalendarEntryFromUserProcessTaskAppointmentInfo(
    info: UserProcessTaskAppointmentInfo,
    useUserColors: boolean
  ): Promise<CalendarEntry | null> {
    const appointment = info.processTaskAppointment;
    const relation = info.processTaskAppointmentToUser;

    const dateFrom = relation.dateFrom ? new Date(relation.dateFrom) : null;
    const dateTo = relation.dateTo ? new Date(relation.dateTo) : null;
    if (!dateFrom || !dateTo) return null;

    const duration = (dateTo.getTime() - dateFrom.getTime()) / 1000 / 60;

    let address = '';
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        appointment.ownerProcessTaskGroupId
      );
    if (processTaskGroup) {
      const thingGroup = this.entityManager.thingGroupRepository.getById(
        processTaskGroup.thingGroupId
      );
      if (thingGroup) {
        address = ThingGroupHelper.getThingGroupAddressString(
          thingGroup.streetName,
          thingGroup.zip,
          thingGroup.municipality
        );
      }
    }

    const processTask = this.entityManager.processTaskRepository.getById(
      appointment.ownerProcessTaskId
    );

    return {
      type: CalendarEntryType.NORMAL,
      name: appointment.name,
      id: appointment.id,
      processConfigurationStepId: appointment.processConfigurationStepId,
      processTaskId: appointment.ownerProcessTaskId,
      startHour: dateFrom.getHours(),
      startMinute: dateFrom.getMinutes(),
      startTimestamp: dateFrom.getTime(),
      endTimestamp: dateTo.getTime(),
      duration: duration,
      address: address,
      done: appointment.finishedAt != null,
      ...this.getAppointmentColors({
        appointmentId: appointment.id,
        processConfigurationStepId: appointment.processConfigurationStepId,
        userId: relation.userId,
        useUserColors: useUserColors
      }),
      processTaskAppointmentNote: appointment.note,
      processTaskNote: processTask?.note ?? null,
      dayWidgetTexts:
        await this.getDayWidgetTextsForProcessTaskAppointment(appointment)
    };
  }

  private async createCalendarEntryFromUserProcessTaskRecurringAppointmentInfo(
    info: UserProcessTaskRecurringAppointmentInfo,
    fromDate: Date,
    toDate: Date,
    useUserColors: boolean
  ): Promise<Array<CalendarEntry | null>> {
    const appointment = info.recurringAppointment;
    const startDate =
      ProcessTaskRecurringAppointmentHelper.getCompleteStartDate(
        info.recurringAppointment
      );
    const endDate = ProcessTaskRecurringAppointmentHelper.getCompleteEndDate(
      info.recurringAppointment
    );

    if (!startDate || !endDate || !appointment.durationInMs) return [];

    const recurrenceCalculator = new RecurrenceCalculator();
    let items = recurrenceCalculator.get(
      {
        startDate,
        endDate,
        recurrence: info.recurringAppointment.recurrence
      },
      fromDate,
      toDate
    );

    if (appointment.excludedDates) {
      items = items.filter((item) => {
        if (
          appointment.excludedDates.some((date) =>
            DateUtils.isOnSameDay(item.startDate, date)
          )
        ) {
          return false;
        }
        return true;
      });
    }

    const results: Array<CalendarEntry> = [];
    for (const i of items) {
      results.push({
        type: CalendarEntryType.RECURRING,
        name: appointment.name,
        id: appointment.id,
        processConfigurationStepId: appointment.processConfigurationStepId,
        processTaskId: appointment.ownerProcessTaskId,
        processTaskGroupId: appointment.ownerProcessTaskGroupId,
        userGroupId: appointment.ownerUserGroupId,
        startHour: i.startDate.getHours(),
        startMinute: i.startDate.getMinutes(),
        startTimestamp: i.startDate.getTime(),
        endTimestamp: i.endDate.getTime(),
        duration: appointment.durationInMs / (1000 * 60),
        ...this.getRecurringAppointmentColors({
          recurringAppointmentId: appointment.id,
          userId: appointment.assignedUserId ?? appointment.coordinatorUserId,
          useUserColors: useUserColors
        }),
        dayWidgetTexts:
          await this.getDayWidgetTextsForRecurringAppointment(appointment)
      });
    }

    return results;
  }

  private async getDayWidgetTextsForProcessTaskAppointment(
    appointment: ProcessTaskAppointment
  ): Promise<BaseAppointmentCalendarEntryDayWidgetTexts> {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        appointment.ownerProcessTaskGroupId
      );
    if (!processTaskGroup)
      return {
        line1: '',
        line2: '',
        line3: ''
      };
    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processTaskGroup.processConfigurationId
      );

    return CalendarEntryHelper.getDayWidgetTexts({
      processTaskGroupId: processTaskGroup.id,
      processTaskId: appointment.ownerProcessTaskId,
      exprEvalParser: this.exprEvalParser,
      operationsExpressionEditor: this.operationsExpressionEditor,
      processConfigurationConfigurableDisplayText:
        processConfiguration?.configurableDisplayText,
      thingGroupId: processTaskGroup.thingGroupId,
      processTaskAppointmentId: appointment.id
    });
  }

  private async getDayWidgetTextsForRecurringAppointment(
    recurringAppointment: ProcessTaskRecurringAppointment
  ): Promise<BaseAppointmentCalendarEntryDayWidgetTexts> {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        recurringAppointment.ownerProcessTaskGroupId
      );
    if (!processTaskGroup)
      return {
        line1: '',
        line2: '',
        line3: ''
      };
    const processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        processTaskGroup.processConfigurationId
      );

    return CalendarEntryHelper.getDayWidgetTexts({
      processTaskGroupId: processTaskGroup.id,
      processTaskId: recurringAppointment.ownerProcessTaskId,
      exprEvalParser: this.exprEvalParser,
      operationsExpressionEditor: this.operationsExpressionEditor,
      processConfigurationConfigurableDisplayText:
        processConfiguration?.configurableDisplayText,
      thingGroupId: processTaskGroup.thingGroupId,
      processTaskRecurringAppointmentId: recurringAppointment.id
    });
  }
}
