export class AbortableFetchRequest {
  private readonly abortController = new AbortController();
  private readonly signal = this.abortController.signal;

  constructor(private readonly url: string) {}

  public fetch(): Promise<Response> {
    return fetch(this.url, {
      signal: this.signal
    });
  }

  public abort(): void {
    this.abortController.abort();
  }
}
