import { assertNotNullOrUndefined } from 'common/Asserts';

import { ImageHelper } from '../ImageHelper';
import { FileUtils } from '../Utils/FileUtils/FileUtils';

export class ImageResizer {
  private constructor() {}

  public static async generateThumbnail({
    imageSrc,
    smallestDimension
  }: {
    imageSrc: string;
    smallestDimension: number;
  }): Promise<Blob | null> {
    const image = await ImageHelper.loadImage(
      await FileUtils.convertFileSrc(imageSrc)
    );

    const width = image.naturalWidth;
    const height = image.naturalHeight;

    const smallestImageDimension = Math.min(width, height);
    const ratio = smallestDimension / smallestImageDimension;

    if (ratio >= 1.0) return null;

    return this.generateResizedImage({
      imageElement: image,
      width: width * ratio,
      height: height * ratio
    });
  }

  private static async generateResizedImage({
    imageElement,
    width,
    height
  }: {
    imageElement: HTMLImageElement;
    width: number;
    height: number;
  }): Promise<Blob> {
    const canvas = document.createElement('canvas');

    canvas.setAttribute('width', width.toString());
    canvas.setAttribute('height', height.toString());

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(
      ctx,
      'cannot resize image without a canvas context'
    );

    ctx.drawImage(imageElement, 0, 0, width, height);

    return new Promise((res, rej) => {
      canvas.toBlob((blob) => {
        if (blob) res(blob);
        else rej(new Error('image could not be resized'));
      });
    });
  }
}
