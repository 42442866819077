import { containerless, autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntryUtils } from '../../classes/EntityManager/entities/Entry/EntryUtils';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { Utils } from '../../classes/Utils/Utils';
import { NavigationService } from '../../services/NavigationService';

/**
 * this element is meant to be used only inside the main-navigation!
 *
 * the content has just been moved in here from the main-navigation for easier handling of the animations + expanded state,
 * thats why all of the styles are in the main-navigation.css
 */
@containerless()
@autoinject()
export class MainNavigationEntryItem {
  @bindable public entry: Entry | null = null;
  @bindable public children: Array<Entry> = [];
  @bindable public activeEntry: Entry | null = null;

  @bindable public currentRouteFragment: string | null = null;

  private expanded = false;

  protected Utils = Utils;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly navigationService: NavigationService
  ) {}

  protected attached(): void {
    this.autoExpand();
  }

  public expand(): void {
    this.expanded = true;
  }

  public collapse(): void {
    this.expanded = false;
  }

  protected routeIsActive(
    entry: Entry,
    currentRouteFragment: string | null
  ): boolean {
    let route = this.getEntryRoute(entry);
    if (route && route[0] === '#') {
      // currentRouteFragment has no leading '#'
      route = route.substr(1);
    }
    return route === currentRouteFragment;
  }

  private handleToggleExpandClick(): void {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  private getIndexForEntry(entry: Entry): string {
    return EntryUtils.getPageDepthIndex(
      this.entityManager.entryRepository.getPathByEntryId(entry.id)
    );
  }

  protected activeEntryChanged(): void {
    this.autoExpand();
  }

  protected childrenChanged(): void {
    this.autoExpand();
  }

  private autoExpand(): void {
    if (
      this.children &&
      this.activeEntry &&
      this.children.indexOf(this.activeEntry) >= 0
    ) {
      this.expand();
    }
  }

  private getEntryRoute(entry: Entry): string | null {
    const project = this.entityManager.projectRepository.getById(entry.project);
    assertNotNullOrUndefined(project, 'entry has no project');

    return this.navigationService.getProjectPageUrl(
      project.id,
      project.projectType,
      { entry_id: entry.id }
    );
  }
}
