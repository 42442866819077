import { autoinject } from 'aurelia-framework';

import {
  ProcessConfigurationPositionType,
  ProcessConfigurationPositionTypeToConfiguration,
  TProcessConfigurationPositionTypeConfiguration
} from 'common/Enums/ProcessConfigurationPositionType';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStepPosition } from '../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { ProcessConfigurationStepPositionPropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProcessConfigurationStepPositionPropertyAdapter';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { configureHooks } from '../../hooks/configureHooks';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationStepPositionDialog {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly positionPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStepPosition];

  protected readonly ProcessConfigurationPositionType =
    ProcessConfigurationPositionType;

  private position: ProcessConfigurationStepPosition | null = null;
  private onDialogClosed: OnDialogClosed | null = null;
  private processConfiguration: ProcessConfiguration | null = null;
  protected typeConfiguration: TProcessConfigurationPositionTypeConfiguration | null =
    null;

  protected propertyAdapter: ProcessConfigurationStepPositionPropertyAdapter | null =
    null;

  private dialog: RecordItDialog | null = null;

  public static async open(
    options: EditProcessConfigurationStepPositionDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;

    this.subscriptionManager = subscriptionManagerService.create();

    this.positionPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfigurationStepPosition,
        context: this,
        expression: 'position'
      });
  }

  public open(
    options: EditProcessConfigurationStepPositionDialogOptions
  ): void {
    this.position = options.processConfigurationStepPosition;
    this.propertyAdapter = new ProcessConfigurationStepPositionPropertyAdapter({
      entityManager: this.entityManager,
      permissionsService: this.permissionsService,
      processConfigurationStepPosition:
        options.processConfigurationStepPosition,
      subscriptionManagerService: this.subscriptionManagerService
    });

    this.onDialogClosed = options.onDialogClosed ?? null;

    const step = this.entityManager.processConfigurationStepRepository.getById(
      this.position.processConfigurationStepId
    );
    if (!step) {
      throw new Error(
        `no processConfigurationStep found for id "${this.position.processConfigurationStepId}"`
      );
    }

    this.processConfiguration =
      this.entityManager.processConfigurationRepository.getById(
        step.ownerProcessConfigurationId
      );
    if (!this.processConfiguration) {
      throw new Error(
        `no processConfiguration found for id "${step.ownerProcessConfigurationId}"`
      );
    }

    this.subscriptionManager.subscribeToExpression(
      this,
      'position.type',
      this.updateTypeConfiguration.bind(this)
    );
    this.updateTypeConfiguration();

    if (this.dialog) {
      this.dialog.open();
    }
  }

  protected handleDialogClosed(): void {
    const onClosed = this.onDialogClosed;
    const position = this.position;

    this.position = null;
    this.propertyAdapter = null;
    this.processConfiguration = null;
    this.onDialogClosed = null;

    position && onClosed && onClosed(position);
  }

  private updateTypeConfiguration(): void {
    this.typeConfiguration =
      ProcessConfigurationPositionTypeToConfiguration.get(
        this.position
          ? this.position.type
          : ProcessConfigurationPositionType.DEFAULT
      ) || null;
  }

  protected handlePositionChanged(): void {
    if (this.position) {
      this.entityManager.processConfigurationStepPositionRepository.update(
        this.position
      );
    }
  }

  protected positionTypeToString(type: ProcessTaskPosition['type']): string {
    return String(type);
  }
}

export type EditProcessConfigurationStepPositionDialogOptions = {
  processConfigurationStepPosition: ProcessConfigurationStepPosition;
  onDialogClosed?: OnDialogClosed | null;
};

export type OnDialogClosed = (
  position: ProcessConfigurationStepPosition
) => void;
