import { ArrayMapCache } from '../../../../classes/ArrayMapCache/ArrayMapCache';
import { SubscribableArray } from '../../../../classes/SubscribableArray/SubscribableArray';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { ValueCalculationConfigDefinitionWidgetHandle } from '../../../value-calculation-config-definition-widget/ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle/ValueCalculationConfigDefinitionWidgetHandle';

import { ManageValueCalculationConfigDefinitionsWidgetAdapterSubscribeOptions } from './ManageValueCalculationConfigDefinitionsWidgetAdapter';

/**
 * A simple cache for the ValueCalculationConfigDefinitionWidgetHandle to avoid unnecessary rerenders.
 */
export class ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCache<
  TValueCalculationConfigDefinition,
  TValueCalculationConfigDefinitionHandle extends
    ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition>
> {
  private readonly cache: ArrayMapCache<
    TValueCalculationConfigDefinition,
    TValueCalculationConfigDefinitionHandle
  >;
  private readonly subscribableArray: SubscribableArray<TValueCalculationConfigDefinitionHandle>;

  constructor(
    options: ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCacheOptions<
      TValueCalculationConfigDefinition,
      TValueCalculationConfigDefinitionHandle
    >
  ) {
    this.cache = new ArrayMapCache({
      createMappedItem: ({ item }) =>
        options.createValueCalculationConfigDefinitionHandle({
          valueCalculationConfigDefinition: item
        })
    });

    this.subscribableArray = new SubscribableArray({
      getSubscribableFromItem: (item) => item
    });
  }

  public subscribe(): Disposable {
    return this.subscribableArray.subscribe();
  }

  /**
   * Creates an update function for easier reuse across multiple event listeners.
   * If the update function gets called, it will also remove unused cache entries to prevent memory leaks from old unused entries
   */
  public createUpdateValueCalculationConfigDefinitionsFunction({
    getValueCalculationConfigDefinitions,
    setValueCalculationConfigDefinitionHandles
  }: CreateUpdateValueCalculationConfigDefinitionsFunctionOptions<TValueCalculationConfigDefinition>): {
    updateValueCalculationConfigDefinitions: () => void;
  } {
    return {
      updateValueCalculationConfigDefinitions: () => {
        this.subscribableArray.items = this.cache.mapItems({
          items: getValueCalculationConfigDefinitions()
        });
        setValueCalculationConfigDefinitionHandles(
          this.subscribableArray.items
        );
      }
    };
  }

  /**
   * Creates a new handle and caches it for later use by e.g. the updateValueCalculationConfigDefinitions function
   */
  public createCachedValueCalculationConfigDefinitionHandle({
    valueCalculationConfigDefinition
  }: {
    valueCalculationConfigDefinition: TValueCalculationConfigDefinition;
  }): TValueCalculationConfigDefinitionHandle {
    return this.cache.mapItem({ item: valueCalculationConfigDefinition });
  }
}

export type ManageValueCalculationConfigDefinitionsWidgetAdapterHandleCacheOptions<
  TValueCalculationConfigDefinition,
  TValueCalculationConfigDefinitionHandle extends
    ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition>
> = {
  createValueCalculationConfigDefinitionHandle: CreateValueCalculationConfigDefinitionHandle<
    TValueCalculationConfigDefinition,
    TValueCalculationConfigDefinitionHandle
  >;
};

export type CreateValueCalculationConfigDefinitionHandle<
  TValueCalculationConfigDefinition,
  TValueCalculationConfigDefinitionHandle extends
    ValueCalculationConfigDefinitionWidgetHandle<TValueCalculationConfigDefinition>
> = (options: {
  valueCalculationConfigDefinition: TValueCalculationConfigDefinition;
}) => TValueCalculationConfigDefinitionHandle;

export type CreateUpdateValueCalculationConfigDefinitionsFunctionOptions<
  TValueCalculationConfigDefinition
> = {
  setValueCalculationConfigDefinitionHandles: ManageValueCalculationConfigDefinitionsWidgetAdapterSubscribeOptions<TValueCalculationConfigDefinition>['setValueCalculationConfigDefinitionHandles'];
  getValueCalculationConfigDefinitions: () => Array<TValueCalculationConfigDefinition>;
};
