import { bindable, autoinject } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { CustomImg } from '../custom-img/custom-img';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { PictureRevision } from '../../classes/EntityManager/entities/PictureRevision/types';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';
import {
  RunSkippedError,
  SerialTask
} from '../../classes/SerialTask/SerialTask';

/**
 * A component which is just meant to preview a picture
 *
 * You can either bind a picture entity using 'picture', a pictureRevision entity using 'pictureRevision'
 * or an array of picture file paths using 'bound-picture-paths'.
 *
 * Automatically scales the picture to any format/ratio
 */
@autoinject()
export class PicturePreview {
  @bindable public picture: Picture | null = null;

  @bindable public pictureRevision: PictureRevision | null = null;

  @bindable public boundPicturePaths: Array<string> | null = null;

  @bindable public fullSize: boolean = true;

  @bindable public disabled: boolean = false;

  protected filePaths: Array<string> = [];
  protected cssProps: string = '';

  protected errorTextTk: string | null = null;

  private isAttached: boolean = false;

  protected customImgViewModel: CustomImg | null = null;

  private domElement: Element;
  private readonly subscriptionManager: SubscriptionManager;

  protected boundHandleElementResized = this.handleElementResized.bind(this);

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly socketService: SocketService
  ) {
    this.domElement = element;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;
    void this.loadPicture();

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (isConnected) void this.loadPicture();
      })
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      () => {
        void this.loadPicture();
      }
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureRevision,
      () => {
        void this.loadPicture();
      }
    );
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected pictureChanged(): void {
    if (this.isAttached) {
      void this.loadPicture();
    }
  }

  protected pictureRevisionChanged(): void {
    if (this.isAttached) {
      void this.loadPicture();
    }
  }

  protected boundPicturePathsChanged(): void {
    if (this.isAttached) {
      void this.loadPicture();
    }
  }

  protected fullSizeChanged(): void {
    if (this.isAttached) void this.loadPicture();
  }

  private async loadPicture(): Promise<void> {
    this.errorTextTk = null;

    if (!this.picture && !this.boundPicturePaths) {
      this.filePaths = [];
      return;
    }

    if (this.picture) {
      try {
        this.filePaths = await this.loadPictureSourcesTask.run({
          picture: this.picture,
          pictureRevision: this.pictureRevision
        });
      } catch (e) {
        if (!(e instanceof RunSkippedError)) throw e;
      }
    }
    if (this.boundPicturePaths) {
      this.filePaths = this.boundPicturePaths;
    }
    if (!this.filePaths.length)
      this.errorTextTk = 'picture.picturePreview.pictureHasNoThumbnail';
  }

  private loadPictureSourcesTask = new SerialTask<PictureInfo, Array<string>>(
    (pictureInfo) => {
      pictureInfo.pictureRevision =
        pictureInfo.pictureRevision ??
        this.entityManager.pictureRevisionRepository.getActiveRevisionByPictureId(
          pictureInfo.picture.id
        );

      let pictureFile: PictureFile | null = null;

      if (pictureInfo.pictureRevision) {
        pictureFile =
          this.entityManager.pictureFileRepository.getPictureFileToDisplayByRevisionId(
            {
              pictureId: pictureInfo.picture.id,
              revisionId: pictureInfo.pictureRevision.id
            }
          );
      } else {
        pictureFile =
          this.pictureFileByActivePictureRevisionService.getPictureFileToDisplayByPictureId(
            pictureInfo.picture.id
          );
      }
      return this.pictureFilePathService.getPicturePreviews(
        pictureFile,
        this.fullSize
      );
    }
  );

  protected handlePictureLoaded(): void {
    this.setPictureRatioCssProperties();
  }

  private setPictureRatioCssProperties(): void {
    if (!this.customImgViewModel) return;

    const dimensions = this.customImgViewModel.getNaturalDimensions();
    const parentDimensions = this.domElement.getBoundingClientRect();

    const ratio = dimensions.width / dimensions.height;
    const parentRatio = parentDimensions.width / parentDimensions.height;

    if (ratio < parentRatio) {
      const widthRatio = dimensions.width / parentDimensions.width;
      this.cssProps = `width: 100%; transform: translateY(${
        -(dimensions.height / widthRatio - parentDimensions.height) / 2
      }px);`;
    } else {
      const heightRatio = dimensions.height / parentDimensions.height;
      this.cssProps = `height: 100%; transform: translateX(${
        -(dimensions.width / heightRatio - parentDimensions.width) / 2
      }px);`;
    }
  }

  protected handleLoadingError(): void {
    this.errorTextTk = 'picture.picturePreview.pictureLoadingError';
  }

  private handleElementResized(): void {
    this.setPictureRatioCssProperties();
  }
}

type PictureInfo = {
  picture: Picture;
  pictureRevision: PictureRevision | null;
};
