import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { TagIdsChangedEvent } from '../../aureliaComponents/ultra-rapid-fire-widget/urfw-categorized-tags-button/urfw-categorized-tags-button';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

/**
 * A visual alternative to the picture-tags-widget.
 *
 * Connects the `tags-widget-list` component with a picture.
 *
 * Instead of displaying an inline box with selected widgets, this simply
 * displays the tags in a list. No "adding Tags" functionality, so this
 * is best used together with the SelectCategoryTagsDialog.
 *
 * @event tag-ids-changed triggered when a tag is deleted
 */
@autoinject()
export class PictureTagsWidgetList {
  @bindable public picture: Picture | null = null;

  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  private element: HTMLElement;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;

    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Picture,
        context: this as PictureTagsWidgetList,
        propertyName: 'picture'
      });
  }

  protected handleTagDeleted(): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot update tags on deletion without picture'
    );
    this.entityManager.pictureRepository.update(this.picture);
    DomEventHelper.fireEvent<TagIdsChangedEvent>(this.element, {
      name: 'tag-ids-changed',
      detail: {
        tagIds: this.picture.tagIds
      }
    });
  }
}
