import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class ResizeObserverCustomAttribute {
  private domElement: HTMLElement;

  private resizeObserver: ResizeObserver;

  @bindable public onResize:
    | ((options: { $elementSize: ElementSize }) => void)
    | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;

    this.resizeObserver = new ResizeObserver(() => {
      this.callOnResize();
    });
  }

  protected attached(): void {
    this.resizeObserver.observe(this.domElement);
  }

  protected detached(): void {
    this.resizeObserver.disconnect();
  }

  protected onResizeChanged(): void {
    this.callOnResize();
  }

  private callOnResize(): void {
    const computedStyle = window.getComputedStyle(this.domElement);

    const parseSizeOrFallbackToZero = (size: string): number => {
      const floatSize = parseFloat(size);
      return isNaN(floatSize) ? 0 : floatSize;
    };

    this.onResize?.({
      $elementSize: {
        height: parseSizeOrFallbackToZero(computedStyle.height),
        width: parseSizeOrFallbackToZero(computedStyle.width)
      }
    });
  }
}

export type ElementSize = {
  width: number;
  height: number;
};
