import { bindable } from 'aurelia-framework';

/**
 * @slot middle-content
 * @slot button-content
 */
export class PageHeader {
  @bindable public title: string | null = null;
  @bindable public subTitle: string | null = null;
  @bindable public subTitlePlaceholder: string | null = null;
  /**
   * for further documentation look at the custom-icon.ts
   */
  @bindable public iconType: string | null = null;
  /**
   * for further documentation look at the custom-icon.ts
   */
  @bindable public iconName: string | null = null;
}
