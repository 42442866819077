import { inject, observable } from 'aurelia-framework';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { ScrollHelper } from '../../classes/ScrollHelper';

import { SocketService } from '../../services/SocketService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { FilterHelper } from '../../classes/FilterHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

@inject(
  SubscriptionManagerService,
  SocketService,
  AppEntityManager,
  CurrentUserService
)
export class import_report_type {
  _reportTypeSortOptions = {
    name: {
      name: 'Name',
      sortFunction: (
        /** @type {ReportType} */ a,
        /** @type {ReportType} */ b
      ) => {
        let aName = a.name;
        let bName = b.name;
        if (!aName) aName = 'Name';
        if (!bName) bName = 'Name';
        return aName.localeCompare(bName);
      }
    },
    uploaded: {
      name: 'Uploaddatum',
      sortFunction: (
        /** @type {ReportType} */ a,
        /** @type {ReportType} */ b
      ) => {
        const aDate = a.upload_date ? new Date(a.upload_date) : new Date(0);
        const bDate = b.upload_date ? new Date(b.upload_date) : new Date(0);
        return aDate.getTime() - bDate.getTime();
      }
    }
  };

  /** @type {string} */
  @observable _reportTypeFilterString;

  /** @type {Array<ReportType>} */
  _availableReportTypes = [];
  /** @type {Array<ReportType>} */
  _filteredReportTypes = [];
  /** @type {Array<ReportType>} */
  _sortedReportTypes = [];
  /** @type {Array<ReportType>} */
  _currentPageReportTypes = [];
  /** @type {Array<import('../../classes/EntityManager/entities/UserGroup/types').UserGroup>} */
  _editableUserGroups = [];
  _currentSortOption;

  /** @type {import('../../aureliaComponents/pagination/pagination').Pagination|null} */
  _pagination = null;
  /** @type {import('../../dialogs/edit-report-type-dialog/edit-report-type-dialog').EditReportTypeDialog|null} */
  _editReportTypeDialog = null;

  _attached = false;

  EntityName = EntityName;

  /**
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {SocketService} socketService
   * @param {AppEntityManager} entityManager
   * @param {CurrentUserService} currentUserService
   */
  constructor(
    subscriptionManagerService,
    socketService,
    entityManager,
    currentUserService
  ) {
    this._subscriptionManager = subscriptionManagerService.create();
    this._socketService = socketService;
    this._entityManager = entityManager;
    this._currentUserService = currentUserService;
    this._currentSortOption = this._reportTypeSortOptions.name;

    this._reportTypeFilterString = '';
  }

  /**
   * @param {Object} params
   * @param {import('aurelia-router').RouteConfig} routeConfig
   * @param {import('aurelia-router').NavigationInstruction} navigationInstruction
   */
  activate(params, routeConfig, navigationInstruction) {
    this._updateAvailableReportTypes();

    if (params.report_type_id) {
      const reportType = this._entityManager.reportTypeRepository.getById(
        params.report_type_id
      );
      if (reportType) this._tryEditReportType(reportType);
    }
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.addDisposable(
      this._socketService.registerBinding('isConnected', (isConnected) => {
        this._isOnline = isConnected;
      })
    );

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this._updateEditableUserGroups.bind(this)
    );

    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this._isMobile = isMobile;
      })
    );

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      this._updateAvailableReportTypes.bind(this)
    );

    this._subscriptionManager.addDisposable(
      this._currentUserService.subscribeToCurrentUserChanged(
        this._updateCurrentUser.bind(this)
      )
    );
    this._updateCurrentUser();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  _updateCurrentUser() {
    this._currentUser = this._currentUserService.getCurrentUser();
    this._updateEditableUserGroups();
  }

  _updateAvailableReportTypes() {
    this._availableReportTypes =
      this._entityManager.reportTypeRepository.getAll();
    this._handleFilterReportTypes();
  }

  /**
   * @param {ReportType} reportType
   * @param {import('../../classes/EntityManager/entities/User/types').User} user
   * @param {Array<import('../../classes/EntityManager/entities/UserGroup/types').UserGroup>} userGroups
   * @returns {boolean}
   */
  _reportTypeIsEditable(reportType, user, userGroups) {
    return (
      reportType &&
      PermissionHelper.userCanEditOwnerUserGroupIdEntity(
        reportType,
        user,
        userGroups
      )
    );
  }

  /**
   * @param {import('../../classes/EntityManager/entities/UserGroup/types').UserGroup} userGroup
   */
  _handleCreateReportTypeClick(userGroup) {
    const reportType = this._entityManager.reportTypeRepository.create({
      usergroup: userGroup.id,
      ownerUserGroupId: userGroup.id
    });

    this._editReportType(reportType);
  }

  _handleFilterReportTypes() {
    this._filteredReportTypes = FilterHelper.filterItems(
      this._availableReportTypes,
      (/** @type {ReportType} */ item) => {
        let returnValue = item.name + ' ' + item.file_name;
        const userGroup = this._entityManager.userGroupRepository.getById(
          item.usergroup
        );
        if (userGroup) returnValue += ' ' + userGroup.name;
        return returnValue;
      },
      this._reportTypeFilterString
    );
  }

  _reportTypeFilterStringChanged() {
    this._handleFilterReportTypes();
  }

  /**
   * @param {ReportType} reportType
   */
  _handleEditReportTypeClick(reportType) {
    this._editReportType(reportType);
  }

  _handleReportTypeDialogClosed() {
    if (this._currentReportTypeEditing)
      this._goToReportType(this._currentReportTypeEditing);
  }

  /**
   * @param {ReportType} reportType
   */
  _editReportType(reportType) {
    this._currentReportTypeEditing = reportType;
    if (this._editReportTypeDialog) this._editReportTypeDialog.open();
  }

  /**
   * @param {ReportType} reportType
   */
  _goToReportType(reportType) {
    ScrollHelper.autoScrollToListItem(
      '#import-report-type--report-type-' + reportType.id,
      this._pagination,
      reportType,
      () => this._attached
    );
  }

  /**
   * @param {ReportType} reportType
   */
  _tryEditReportType(reportType) {
    if (this._attached) {
      this._editReportType(reportType);
    } else {
      setTimeout(() => {
        this._tryEditReportType(reportType);
      }, 10);
    }
  }

  _updateEditableUserGroups() {
    this._editableUserGroups = this._currentUser
      ? this._entityManager.userGroupRepository.getEditableGroupsForUser(
          this._currentUser
        )
      : [];
  }
}

/** @typedef {import('../../classes/EntityManager/entities/ReportType/types').ReportType} ReportType */
