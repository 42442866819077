import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { StructureTemplateEntryDto } from './StructureTemplateEntryDto';

export function createStructureTemplateEntryEntityInfo<
  TEntity extends StructureTemplateEntryDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructureTemplateEntryDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplateEntry,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplateEntry,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateEntryDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        originId: {
          name: 'originId',
          defaultValue: null
        },
        customId: {
          name: 'customId',
          defaultValue: null
        },
        referenceId: {
          name: 'referenceId',
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        label: {
          name: 'label',
          defaultValue: null
        },
        iconType: {
          name: 'iconType',
          defaultValue: null
        },
        iconName: {
          name: 'iconName',
          defaultValue: null
        },
        iconColor: {
          name: 'iconColor',
          defaultValue: null
        },
        abbreviation: {
          name: 'abbreviation',
          defaultValue: null
        },
        showExcludeButton: {
          name: 'showExcludeButton',
          defaultValue: true
        },
        showCheckButton: {
          name: 'showCheckButton',
          defaultValue: true
        },
        alwaysAllowEntryCreation: {
          name: 'alwaysAllowEntryCreation',
          defaultValue: false
        },
        parentEntryId: {
          name: 'parentEntryId',
          defaultValue: null,
          ref: EntityName.StructureTemplateEntry
        },
        listPosition: {
          name: 'listPosition',
          defaultValue: 0
        },
        structureTemplateEntryGroupId: {
          name: 'structureTemplateEntryGroupId',
          defaultValue: null,
          cascadeDeleteLocally: true,
          ref: EntityName.StructureTemplateEntryGroup
        },
        ownerStructureTemplateId: {
          name: 'ownerStructureTemplateId',
          cascadeDeleteLocally: true,
          ref: EntityName.StructureTemplate,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
