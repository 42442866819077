import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ThingGroupDto } from './ThingGroupDto';

export function createThingGroupEntityInfo<
  TEntity extends ThingGroupDto<string, string>
>(
  options: CreateEntityInfoOptions<ThingGroupDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ThingGroup,
  TEntity
> {
  return {
    entityName: EntityName.ThingGroup,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ThingGroupDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        customId: {
          name: 'customId',
          defaultValue: null
        },
        ownerPersonId: {
          name: 'ownerPersonId',
          defaultValue: null,
          ref: EntityName.Person
        },
        contactPersonId: {
          name: 'contactPersonId',
          defaultValue: null,
          ref: EntityName.Person
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        streetName: {
          name: 'streetName',
          defaultValue: null
        },
        zip: {
          name: 'zip',
          defaultValue: null
        },
        municipality: {
          name: 'municipality',
          defaultValue: null
        },
        country: {
          name: 'country',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        latitude: {
          name: 'latitude',
          defaultValue: null
        },
        longitude: {
          name: 'longitude',
          defaultValue: null
        },
        sharepointSettings: {
          name: 'sharepointSettings',
          innerInfo: {
            enabled: {
              name: 'enabled',
              defaultValue: false
            },
            listPath: {
              name: 'listPath',
              defaultValue: null
            },
            sharepointUrl: {
              name: 'sharepointUrl',
              defaultValue: null
            },
            thingTypeId: {
              name: 'thingTypeId',
              ref: EntityName.ThingType,
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        }
      },
      options.additionalFieldInfos
    )
  };
}
