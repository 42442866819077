import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { PersonCategories } from 'common/Types/PersonCategories';
import { PersonListItemFirstColumnContent } from 'common/Enums/PersonListItemFirstColumnContent';

import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { Dialogs } from '../../classes/Dialogs';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { Person } from '../../classes/EntityManager/entities/Person/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { computed } from '../../hooks/computed';
import { activeUserCompanySetting, expression } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class PersonListItem {
  @bindable()
  public person: Person | null = null;

  @subscribableLifecycle()
  protected readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Person];

  protected personIsEditable: boolean = false;

  protected listItemElement: HTMLElement | null = null;

  protected readonly PersonUtils = PersonUtils;

  protected PersonListItemFirstColumnContent = PersonListItemFirstColumnContent;

  private panelOpened: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;

    this.permissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Person,
        context: this,
        expression: 'person'
      });
  }

  public highlight(): void {
    assertNotNullOrUndefined(
      this.listItemElement,
      "can't PersonListItem.highlight without listItemElement"
    );
    EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  @computed(expression('person.categoryName'), expression('personCategories'))
  protected get personCategoryName(): string {
    return (
      this.personCategories.find((x) => x.value === this.person?.categoryName)
        ?.label ?? ''
    );
  }

  protected handleMoreButtonClick(): void {
    this.panelOpened = !this.panelOpened;
  }

  protected handleEditPersonClick(): void {
    assertNotNullOrUndefined(
      this.person,
      "can't PersonListItem.handleEditPersonClick without person"
    );
    PersonUtils.navigateToEditPersonPage(this.router, this.person.id);
  }

  protected handleDeletePersonClick(): void {
    const person = this.person;
    assertNotNullOrUndefined(
      person,
      "can't PersonListItem.handleDeletePersonClick without person"
    );

    void Dialogs.deleteEntityDialog(person).then(() => {
      this.entityManager.personRepository.delete(person);
    });
  }

  @computed(activeUserCompanySetting('general.personCategories'))
  private get personCategories(): PersonCategories {
    return (
      this.activeUserCompanySettingService.getJSONSettingProperty(
        'general.personCategories'
      ) ?? []
    );
  }

  @computed(
    activeUserCompanySetting('general.personListItemFirstColumnContent')
  )
  protected get activeFirstColumnContentType(): PersonListItemFirstColumnContent {
    return (
      this.activeUserCompanySettingService.getSettingProperty(
        'general.personListItemFirstColumnContent'
      ) ?? PersonListItemFirstColumnContent.CATEGORY
    );
  }
}
