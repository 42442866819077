import { z } from 'zod';

export const SettingsZodSchemaUnion = z.discriminatedUnion('key', [
  z.object({
    key: z.literal('ServerEndpoints'),
    value: z
      .array(
        z.object({
          name: z.string(),
          webUrl: z.string(),
          recordItUrl: z.string()
        })
      )
      .default([])
  })
]);

export const SettingsZodSchemaUnionWithNullValue = z.discriminatedUnion(
  'key',
  SettingsZodSchemaUnion.options.map((option) => {
    return z.object({
      key: option.shape.key,
      value: option.shape.value.nullable()
    });
  }) as OptionsWithNullableValue<(typeof SettingsZodSchemaUnion)['options']>
);

type OptionsWithNullableValue<TOptions extends Array<unknown>> = [
  ...{
    [key in keyof TOptions]: TOptions[key] extends z.ZodObject<
      infer RawShape extends z.ZodRawShape
    >
      ? z.ZodObject<ModifyRawShape<RawShape>>
      : never;
  }
];

type ModifyRawShape<T extends z.ZodRawShape> = {
  [key in keyof T]: key extends 'value' ? z.ZodNullable<T[key]> : T[key];
};

export const SettingsZodSchema = z.object(
  Array.from(SettingsZodSchemaUnion.optionsMap.entries()).reduce(
    (schema, [key, value]) => {
      if (typeof key !== 'string')
        throw new Error('settings key must be of type string');

      schema[key] = value.shape.value;
      return schema;
    },
    {} as Record<string, z.ZodTypeAny | undefined>
  ) as {
    [key in SettingsUnion['key']]: z.ZodType<
      Extract<SettingsUnion, { key: key }>['value']
    >;
  }
);

export type SettingsUnion = z.infer<typeof SettingsZodSchemaUnion>;

export type SettingKeys = SettingsUnion['key'];

export type Settings = z.infer<typeof SettingsZodSchema>;
