export class OverwriteValueValueConverter {
  /**
   * @param {any} value
   * @param {boolean} overwrite
   * @param {any} overwriteValue
   */
  toView(value, overwrite, overwriteValue = null) {
    return overwrite ? overwriteValue : value;
  }
}
