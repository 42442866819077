export class MouseDraggingDetector {
  private static defaultOptions: MouseDraggingDetectorOptions = {
    clickTimeoutEnabled: false,
    elementCanStartDragging: () => true,
    elementSkipsClickTimeout: () => false
  };

  private initialized = false;

  private enabled = true;

  private options: MouseDraggingDetectorOptions;

  private isDragging = false;

  private dragStartPosition: { x: number; y: number } = { x: 0, y: 0 };

  private dragStartCallback: ((event: MouseEvent) => void) | null = null;
  private dragCallback: ((event: MouseEvent) => void) | null = null;
  private dragEndCallback: ((event: MouseEvent) => void) | null = null;

  // recently dragged is for detecting "wrong" clicks after dragging
  private recentlyDragged = false;
  private recentlyDraggedTimeout: number | undefined;
  private recentlyDraggedMinDistance = 2; // min distance to be moved to actually detect a recently dragged

  private clickTimeoutHandle: number | undefined;

  private element: HTMLElement;

  private boundHandleMousedown = this.handleMousedown.bind(this);
  private boundHandleMousemove = this.handleMousemove.bind(this);
  private boundHandleMouseup = this.handleMouseup.bind(this);
  private boundHandleMouseleave = this.handleMouseleave.bind(this);

  constructor(
    element: HTMLElement,
    options: Partial<MouseDraggingDetectorOptions>
  ) {
    this.element = element;
    this.options = Object.assign(
      {},
      MouseDraggingDetector.defaultOptions,
      options || {}
    );

    this.init();
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.element.addEventListener('mousedown', this.boundHandleMousedown);
    this.element.addEventListener('mousemove', this.boundHandleMousemove);
    this.element.addEventListener('mouseup', this.boundHandleMouseup);
    this.element.addEventListener('mouseleave', this.boundHandleMouseleave);

    this.initialized = true;
  }

  public destroy(): void {
    this.element.removeEventListener('mousedown', this.boundHandleMousedown);
    this.element.removeEventListener('mousemove', this.boundHandleMousemove);
    this.element.removeEventListener('mouseup', this.boundHandleMouseup);
    this.element.removeEventListener('mouseleave', this.boundHandleMouseleave);

    this.initialized = false;
  }

  public enable(): void {
    this.enabled = true;
  }

  public disable(): void {
    this.enabled = false;
    window.clearTimeout(this.clickTimeoutHandle);
  }

  /**
   * manually cancel the current dragging event, but the dragEnd callback will not be called
   */
  public cancelDragging(): void {
    this.isDragging = false;
  }

  /**
   * needed if you need to differentiate if the element has been dragged or actually clicked
   * because after dragging, a click event will always be fired (stupid browser)
   */
  public draggedRecently(): boolean {
    return this.recentlyDragged;
  }

  public onDragStart(callback: (event: MouseEvent) => void): void {
    this.dragStartCallback = callback;
  }

  public onDrag(callback: (event: MouseEvent) => void): void {
    this.dragCallback = callback;
  }

  public onDragEnd(callback: (event: MouseEvent) => void): void {
    this.dragEndCallback = callback;
  }

  private handleMousedown(event: MouseEvent): void {
    if (!this.enabled) return;
    const element = event.target as Element;

    if (!this.options.elementCanStartDragging(element)) {
      return;
    }

    // Apparently fixes Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=339293#c3
    event.preventDefault();

    const startDragging = (): void => {
      this.isDragging = true;
      this.dragStartPosition = { x: event.clientX, y: event.clientY };
      this.dragStartCallback && this.dragStartCallback(event);
    };

    if (
      this.options.clickTimeoutEnabled &&
      !this.options.elementSkipsClickTimeout(element)
    ) {
      this.clickTimeoutHandle = window.setTimeout(startDragging, 500);
    } else {
      startDragging();
    }
  }

  private handleMousemove(event: MouseEvent): void {
    if (this.isDragging) {
      if (!this.recentlyDragged) {
        const x = event.clientX - this.dragStartPosition.x;
        const y = event.clientY - this.dragStartPosition.y;
        const distance = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        if (distance > this.recentlyDraggedMinDistance) {
          this.recentlyDragged = true;
          window.clearTimeout(this.recentlyDraggedTimeout);
        }
      }
      this.dragCallback && this.dragCallback(event);
    } else {
      window.clearTimeout(this.clickTimeoutHandle);
    }
  }

  private handleMouseup(event: MouseEvent): void {
    this.dragEnded(event);
  }

  private handleMouseleave(event: MouseEvent): void {
    this.dragEnded(event);
  }

  private dragEnded(event: MouseEvent): void {
    if (this.isDragging) {
      this.isDragging = false;

      this.recentlyDraggedTimeout = window.setTimeout(() => {
        this.recentlyDragged = false;
      }, 50);

      this.dragEndCallback && this.dragEndCallback(event);
    }

    window.clearTimeout(this.clickTimeoutHandle);
  }
}

export type MouseDraggingDetectorOptions = {
  clickTimeoutEnabled: boolean;
  elementCanStartDragging: (element: Element) => boolean;
  elementSkipsClickTimeout: (element: Element) => boolean;
};
