import { autoinject } from 'aurelia-framework';

import { DialogIconType } from 'common/Enums/DialogIconType';

import {
  ButtonType,
  GlobalCustomDialog
} from '../../../../dialogs/global-custom-dialog/global-custom-dialog';
import { AppEntityManager } from '../AppEntityManager';
import { ProcessTask } from '../ProcessTask/types';
import { ProcessTaskChecklistEntry } from './types';

@autoinject()
export class ProcessTaskChecklistEntryDialogService {
  constructor(private readonly entityManager: AppEntityManager) {}

  /**
   * shows a dialog if the processTasks have pending checklist entries and allows the user to proceed anyway
   *
   */
  public async confirmProcessTasksChecklistEntries(
    processTasks: Array<ProcessTask>
  ): Promise<void> {
    const checklistEntriesByProcessTask =
      this.getNotDoneChecklistEntriesByProcessTasks(processTasks);

    if (checklistEntriesByProcessTask.size === 0) {
      return; // everything is fine, nothing to do
    }

    const entrySummary = this.getChecklistEntrySummary(
      checklistEntriesByProcessTask
    );

    await GlobalCustomDialog.open({
      titleTk:
        'classes.EntityManager.entities.ProcessTaskChecklistEntry.ProcessTaskChecklistEntryDialogService.checklistEntriesNotDone.title',
      textTk:
        'classes.EntityManager.entities.ProcessTaskChecklistEntry.ProcessTaskChecklistEntryDialogService.checklistEntriesNotDone.text',
      textTkParams: { entrySummary },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          className: 'record-it-button-primary',
          textTk:
            'classes.EntityManager.entities.ProcessTaskChecklistEntry.ProcessTaskChecklistEntryDialogService.checklistEntriesNotDone.continue'
        },
        {
          type: ButtonType.CANCEL,
          textTk: 'general.cancel'
        }
      ]
    });
  }

  /**
   * if a processTask has no checklistEntries, then the key in the map will be unset
   */
  public getNotDoneChecklistEntriesByProcessTasks(
    processTasks: Array<ProcessTask>
  ): ProcessTaskChecklistEntriesByProcessTask {
    const checklistEntriesByProcessTask: ProcessTaskChecklistEntriesByProcessTask =
      new Map();

    processTasks.forEach((processTask) => {
      const entries = this.entityManager.processTaskChecklistEntryRepository
        .getByProcessTaskId(processTask.id)
        .filter((e) => !e.done);
      if (entries.length) {
        checklistEntriesByProcessTask.set(processTask, entries);
      }
    });

    return checklistEntriesByProcessTask;
  }

  private getChecklistEntrySummary(
    checklistEntriesByProcessTask: ProcessTaskChecklistEntriesByProcessTask
  ): string {
    let entrySummary = '';

    for (const [processTask, entries] of checklistEntriesByProcessTask) {
      const processTaskGroup =
        this.entityManager.processTaskGroupRepository.getById(
          processTask.ownerProcessTaskGroupId
        );
      entrySummary += `Auftrag ${
        processTaskGroup ? processTaskGroup.sequenceNumber : ''
      }-${processTask.sequenceNumber}${
        processTask.name ? ' ' + processTask.name : ''
      }:`;

      // it's ok here, since the function is called immediately (and not async)
      // eslint-disable-next-line no-loop-func
      entries.forEach((entry) => {
        entrySummary += '\n  * ' + entry.text;
      });
    }

    return entrySummary;
  }
}

type ProcessTaskChecklistEntriesByProcessTask = Map<
  ProcessTask,
  Array<ProcessTaskChecklistEntry>
>;
