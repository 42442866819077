import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { ModuleName, RecordItModuleHelper } from './RecordItModuleHelper';
import { UserGroup } from './EntityManager/entities/UserGroup/types';
import { User } from './EntityManager/entities/User/types';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

export class PermissionHelper {
  public static userCanEditOwnerUserGroupIdEntity(
    entity: { ownerUserGroupId: string },
    user: User,
    userGroups: Array<UserGroup>
  ): boolean {
    return user && user.admin
      ? true
      : userGroups.findIndex((group) => group.id === entity.ownerUserGroupId) >=
          0;
  }

  public static userCanWriteInUserGroup(
    user: User,
    userGroup: UserGroup
  ): boolean {
    if (user.admin) return true;

    for (const spec of userGroup.userSpecs) {
      if (spec._id === user.id && (spec.can_edit || spec.group_admin)) {
        return true;
      }
    }

    return false;
  }

  public static userCanReadInUserGroup(
    user: User,
    userGroup: UserGroup
  ): boolean {
    if (user.admin) return true;

    return userGroup.userSpecs.some((spec) => spec._id === user.id);
  }

  public static userCanAdministerUsers(user: User): boolean {
    if (user.admin || this.userHasPermission(user, 'canAdministerUsers')) {
      return true;
    }
    return false;
  }

  public static userCanAdministerUserGroup(
    user: User,
    userGroup: UserGroup
  ): boolean {
    if (this.userCanAdministerUsers(user)) return true;

    for (const spec of userGroup.userSpecs) {
      if (spec._id === user.id && spec.group_admin) {
        return true;
      }
    }

    return false;
  }

  public static subscriptionIsValid(userSubscription: string | null): boolean {
    if (userSubscription != null) {
      return new Date(userSubscription) >= new Date();
    } else {
      return true;
    }
  }

  public static getAvailableModulesForUser(user: User): Array<ModuleName> {
    const availableModules: Array<ModuleName> = [];

    for (const moduleName of Object.keys(
      RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP
    ) as Array<ModuleName>) {
      if (
        moduleName !== ModuleName.GENERAL &&
        this.userHasPermissionForModule(user, moduleName)
      ) {
        availableModules.push(moduleName as ModuleName);
      }
    }

    return availableModules;
  }

  public static getAvailableProjectTypesForUser(
    user: User
  ): Array<ProjectType> {
    const modules = this.getAvailableModulesForUser(user);

    /** @type {Array<ProjectType>} */
    const projectTypes = [];

    for (const module of modules) {
      const projectType =
        RecordItModuleHelper.getProjectTypeForModuleName(module);
      if (projectType) projectTypes.push(projectType);
    }

    return projectTypes;
  }

  public static userHasPermissionForModule(
    user: User | null,
    moduleName: ModuleName
  ): boolean {
    const permissionName = this.getPermissionNameForModuleName(moduleName);
    return this.userHasPermission(user, permissionName);
  }

  public static userHasPermission(
    user: User | null,
    permissionName: UserPermission
  ): boolean {
    if (!user) return false;
    if (user.admin) return true;

    let rawValue = this.getRawPermissionValue(user, permissionName);
    if (rawValue == null) {
      rawValue = false;
    }

    if (typeof rawValue === 'string') {
      return this.subscriptionIsValid(rawValue);
    }

    return rawValue;
  }

  public static getLatestModuleSubscription(user: User | null): string | null {
    let maxDateString = null;
    let maxDateTimestamp = Number.MIN_SAFE_INTEGER;

    for (const moduleName of Object.keys(
      RecordItModuleHelper.MODULE_NAME_TO_CONFIGURATION_MAP
    )) {
      if (moduleName === ModuleName.GENERAL) {
        continue;
      }

      const rawValue = this.getRawPermissionValue(
        user,
        this.getPermissionNameForModuleName(moduleName)
      );

      if (typeof rawValue !== 'string') {
        continue;
      }

      const timestamp = new Date(rawValue).getTime();
      if (!isNaN(timestamp) && timestamp > maxDateTimestamp) {
        maxDateString = rawValue;
        maxDateTimestamp = timestamp;
      }
    }

    return maxDateString;
  }

  public static getPermissionNameForModuleName(
    moduleName: string
  ): UserPermission {
    return ('canUse' +
      StringUtils.upperCaseFirstLetter(moduleName)) as UserPermission;
  }

  public static getRawPermissionValue(
    user: User | null,
    permissionName: UserPermission
  ): string | boolean | null {
    if (user && user.permissions && user.permissions[permissionName]) {
      return user.permissions[permissionName] ?? null;
    } else {
      return null;
    }
  }
}

export type UserPermission = keyof User['permissions'];
