import { autoinject } from 'aurelia-framework';
import { configureHooks } from '../../hooks/configureHooks';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from 'common/Types/Entities/Base/ClientEntityName';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { Dialogs } from '../../classes/Dialogs';
import { SocketService } from '../../services/SocketService';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EndpointResult } from 'common/WebSocketEndpoints/WebSocketEndpointConfigurations';
import { EntitiesWithPermissionHandle } from '../../services/PermissionsService/EntitiesWithPermissionHandle/EntitiesWithPermissionHandle';
import { watch } from '../../hooks/watch';
import { Logger } from '../../classes/Logger/Logger';
import { WebSocketHandlerError } from 'common/WebSocketEndpoints/WebSocketHandlerError/WebSocketHandlerError';
import { ThingActionService } from '../../classes/EntityManager/entities/Thing/ThingActionService';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class DefectMoveToExistingThingDialog {
  protected dialog: RecordItDialog | null = null;

  public defect: Defect | null = null;
  protected thingToMoveTo: Thing | null = null;
  protected deleteOldThing = false;

  @subscribableLifecycle()
  protected thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  @subscribableLifecycle()
  protected availableThingsHandle: EntitiesWithPermissionHandle<EntityName.Thing>;

  public static async open(
    options: DefectMoveToExistingThingDialogOptions
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly thingActionService: ThingActionService,
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService,
    private readonly permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.Thing,
        context: this as DefectMoveToExistingThingDialog,
        propertyName: 'defect',
        idPropertyName: 'ownerThingId'
      });

    this.availableThingsHandle =
      this.permissionsService.getEntitiesWithPermissionHandleForPermissionName({
        entityName: EntityName.Thing,
        permissionName: 'canCreateDefects'
      });
  }

  public open(options: DefectMoveToExistingThingDialogOptions): void {
    this.defect = options.defect;
    this.thingToMoveTo = null;
    this.deleteOldThing = false;
    this.updateAvailableThings();

    this.dialog?.open();
  }

  @watch(expression('thingPermissionsHandle.canDeleteEntity'))
  protected updateDeleteOldThingOnPermissionHandleChange(): void {
    this.deleteOldThing = this.thingPermissionsHandle.canDeleteEntity;
  }

  @watch(model(EntityName.Thing))
  protected updateAvailableThings(): void {
    this.availableThingsHandle.setEntities(
      this.entityManager.thingRepository
        .getAll()
        .filter((t) => !t.archived)
        .filter((t) => t.id !== this.defect?.ownerThingId)
    );
  }

  @computed(expression('availableThingsHandle.filteredEntities'))
  protected get availableThings(): Array<Thing> {
    return this.availableThingsHandle.filteredEntities;
  }

  protected handleDialogClosed(): void {
    this.defect = null;
  }

  protected async handleAcceptButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleAcceptButtonClicked without defect'
    );
    assertNotNullOrUndefined(
      this.thingToMoveTo,
      'cannot handleAcceptButtonClicked without thingToMoveTo'
    );

    try {
      const response =
        await EventAggregatorPromiseHelper.createConnectedPromise<
          EndpointResult<'moveDefectModule', 'moveToExistingThing_v1'>
        >(
          this.eventAggregator,
          this.socketService.moveDefectModuleEndpoints.moveToExistingThing_v1({
            defectId: this.defect.id,
            thingId: this.thingToMoveTo.id,
            deleteOldThing: this.deleteOldThing
          })
        );

      const thing = this.entityManager.thingRepository.getById(
        response.newThingId
      );
      assertNotNullOrUndefined(
        thing,
        'cannot handleAcceptButtonClicked without thing'
      );
      this.thingActionService.navigateToThing(thing);
    } catch (e) {
      if (e instanceof WebSocketHandlerError) {
        void Dialogs.errorDialogTk(
          'general.error',
          `WebsocketErrors.${e.type}`,
          e.context
        );
      } else {
        void Dialogs.errorDialogTk(
          'general.error',
          'WebsocketErrors.ERROR_UNKNOWN'
        );
      }
      Logger.logError({ error: e });
    }
  }
}

type DefectMoveToExistingThingDialogOptions = {
  defect: Defect;
};
