import { EntityIdAdapter } from '@record-it-npm/synchro-common';
import ObjectId from 'bson-objectid';

export class ServerEntityIdAdapter implements EntityIdAdapter {
  public generateNewId(): string {
    return new ObjectId().toHexString();
  }

  public isId(id: string): boolean {
    // sadly the library accepts any string that is 12 characters long, like 'localThingId' which isn't a server id
    if (id.length !== 24) {
      return false;
    }

    return ObjectId.isValid(id);
  }

  public getNanoSecondsTimeStampFromId(id: string): number {
    const timestampPartInSeconds = id.slice(0, 8);

    return parseInt(timestampPartInSeconds, 16) * 1000 * 1000 * 1000;
  }

  public getNodeIdFromId(id: string): string {
    return id.slice(8); // this starts with a random identifier for the node/process and contains a counter for the entities at the end
  }
}
