import { v1 as uuid } from 'uuid';

import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { UploadItemRescueData } from 'common/EndpointTypes/DataRescueEndpointsHandler';
import { FileChunkUploadErrorType } from 'common/EndpointTypes/FileChunkUploadEndpointsHandler';
import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';

import { SubscriptionManagerService } from './SubscriptionManagerService';
import { SocketService } from './SocketService';
import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { IUtilsRateLimitedFunction, Utils } from '../classes/Utils/Utils';
import { DeviceInfoHelper } from '../classes/DeviceInfoHelper';
import { SubscriptionManager } from '../classes/SubscriptionManager';
import { LockName, LogoutLockService } from './LogoutLockService';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { PictureFile } from '../classes/EntityManager/entities/PictureFile/types';
import { Uint8ArrayReader } from '../classes/Reader/Uint8ArrayReader/Uint8ArrayReader';
import { FileChunkUploadService } from './FileChunkUploadService/FileChunkUploadService';
import { FileChunkUploadError } from './FileChunkUploadService/FileChunkUploader';
import {
  NotificationDuration,
  NotificationHelper
} from '../classes/NotificationHelper';
import { Logger } from '../classes/Logger/Logger';
import { ArrayUtils } from 'common/Utils/ArrayUtils';

@autoinject()
export class FileUploadService {
  private static TABLE_NAME = 'FileUploadService';

  private readonly subscriptionManager: SubscriptionManager;
  private readonly strategiesSubscriptionManager: SubscriptionManager;

  /**
   * map of the strategy name to the strategy
   */
  private strategyMap: Map<string, TAnyUploadStrategy> = new Map();
  private uploadItems: Array<TAnyUploadItem> = [];
  private isAuthenticated: boolean = false;
  private isUploading: boolean = false;
  private saveUploadItemsRateLimited: IUtilsRateLimitedFunction;
  private uploadItemsLoadedFromLocalStorage: boolean = false;
  private pauseContexts: Set<unknown> = new Set();
  private initialized: boolean = false;
  private uploadFinishedPromiseContainer = new PromiseContainer<void>();

  constructor(
    private readonly socketService: SocketService,
    private readonly logoutLockService: LogoutLockService,
    private readonly fileChunkUploadService: FileChunkUploadService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly i18n: I18N
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.strategiesSubscriptionManager = subscriptionManagerService.create();

    this.saveUploadItemsRateLimited = Utils.rateLimitFunction(
      this.saveUploadItems.bind(this),
      250
    );
  }

  public async init(): Promise<void> {
    if (this.initialized) {
      return;
    }

    await this.loadUploadItemsFromLocalStorage();

    this.subscriptionManager.subscribeToInterval(
      this.tryUploadingNextItem.bind(this),
      500
    );
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding(
        'isAuthenticated',
        (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
          this.isAuthenticatedChanged();
        }
      )
    );

    this.updateSubscriptions();
    this.initialized = true;
  }

  public async flush(): Promise<void> {
    this.assertInitialized();

    if (this.saveUploadItemsRateLimited.isPending()) {
      this.saveUploadItemsRateLimited.cancel();
      await this.saveUploadItems();
    }
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();

    this.strategiesSubscriptionManager.disposeSubscriptions();
    this.initialized = false;
  }

  /**
   * call unpause as soon as this doesn't need to be paused
   * don't call start to unpause
   */
  public pauseUploading(context: unknown): void {
    this.pauseContexts.add(context);
  }

  public resumeUploading(context: unknown): void {
    this.pauseContexts.delete(context);
  }

  public registerStrategy(strategy: TAnyUploadStrategy): void {
    this.assertInitialized();

    this.strategyMap.set(strategy.getName(), strategy);
    this.updateSubscriptions();
    this.updateUploadItemsOfStrategy(strategy.getName());
  }

  public submitUploadItem<T>(
    strategy: AbstractUploadStrategy<T>,
    data: T,
    options: { showDialogs: boolean }
  ): void {
    this.assertInitialized();

    const uploadItem: TUploadItem<T> = {
      id: uuid(),
      strategyName: strategy.getName(),
      hasError: false,
      isBeingUploaded: false,
      showDialogs: options.showDialogs,
      chunkedFileId: null,
      data
    };

    const index = this.findSimilarUploadItemIndex(uploadItem);

    if (index >= 0) {
      this.uploadItems[index] = uploadItem;
    } else {
      this.uploadItems.push(uploadItem);
    }

    this.tryUploadingNextItem();

    this.saveUploadItemsRateLimited();
  }

  public getUploadItemCount(): number {
    this.assertInitialized();

    return this.uploadItems.length;
  }

  public retryFailedUploadItems(): void {
    this.uploadItems.forEach((item) => {
      item.hasError = false;
    });
    this.saveUploadItemsRateLimited();

    this.tryUploadingNextItem();
  }

  public async getRescueDataItems(): Promise<Array<UploadItemRescueData>> {
    this.assertInitialized();

    const rescueDataItems: Array<UploadItemRescueData> = [];
    for (const item of this.uploadItems) {
      const strategy = this.getStrategyByName(item.strategyName);
      if (strategy.itemIsReadyToBeUploaded(item.data)) continue;

      rescueDataItems.push(await strategy.getRescueData(item.data));
    }
    return rescueDataItems;
  }

  public waitUntilUploadFinished(): Promise<void> {
    if (this.isUploading) {
      return this.uploadFinishedPromiseContainer.create();
    }

    return Promise.resolve();
  }

  protected isAuthenticatedChanged(): void {
    if (this.isAuthenticated) {
      if (!this.isUploading) {
        this.resetUploadItems(this.uploadItems);
      }

      this.tryUploadingNextItem();
    } else {
      this.isUploading = false;
    }
  }

  private findSimilarUploadItemIndex(uploadItem: TAnyUploadItem): number {
    return this.uploadItems.findIndex((item) => {
      if (!item.isBeingUploaded) {
        return this.uploadItemsAreSimilar(item, uploadItem);
      }

      return false;
    });
  }

  private uploadItemsAreSimilar(
    item: TAnyUploadItem,
    otherItem: TAnyUploadItem
  ): boolean {
    if (item.strategyName !== otherItem.strategyName) {
      return false;
    }

    try {
      const strategy = this.getStrategyByName(item.strategyName);
      return strategy.uploadItemsAreSimilar(item.data, otherItem.data);
    } catch (error) {
      Logger.logError({ error });
      return false;
    }
  }

  private updateSubscriptions(): void {
    this.strategiesSubscriptionManager.disposeSubscriptions();
    for (const [, strategy] of this.strategyMap) {
      const disposables = strategy.subscribeToEvents(
        this.modifyStrategyItems.bind(this, strategy.getName())
      );

      if (disposables.length > 0) {
        this.strategiesSubscriptionManager.addDisposable(
          ...(disposables as [Disposable, ...Array<Disposable>])
        );
      }
    }

    this.strategiesSubscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'uploadItems',
      this.setLogoutLockStatus.bind(this)
    );
  }

  private modifyStrategyItems(
    strategyName: string,
    modifyFunction: ModifyFunction<any>
  ): void {
    let changed = false;

    this.uploadItems = this.uploadItems.slice().filter((item) => {
      if (item.strategyName === strategyName) {
        const result = modifyFunction(item.data);

        if (result === ModificationType.DELETE) {
          changed = true;
          return false;
        }

        if (result === ModificationType.MODIFIED) {
          changed = true;
        }
      }

      return true;
    });

    if (changed) {
      this.saveUploadItemsRateLimited();
    }
  }

  private updateUploadItemsOfStrategy(strategyName: string): void {
    let changed = false;

    this.uploadItems.forEach((item) => {
      if (item.strategyName === strategyName) {
        changed = this.updateUploadItem(item) || changed;
      }
    });

    if (changed) {
      this.saveUploadItemsRateLimited();
    }
  }

  /**
   * true when the item has been changed
   */
  private updateUploadItem(item: TAnyUploadItem): boolean {
    try {
      const strategy = this.getStrategyByName(item.strategyName);
      return strategy.updateUploadItemData(item.data);
    } catch (error) {
      Logger.logError({ error });
    }

    return false;
  }

  /**
   * uploads the next item if possible
   */
  private tryUploadingNextItem(): void {
    if (
      !this.isUploading &&
      this.uploadItemsLoadedFromLocalStorage &&
      this.initialized
    ) {
      this.uploadNextItem();
    }
  }

  private uploadNextItem(): void {
    const item = this.getNextUploadItem();

    if (item && !this.pauseContexts.size && this.isAuthenticated) {
      this.isUploading = true;

      // not awaited on purpose, so it doesn't interrupt the program flow
      void this.uploadUploadItem(item);
    } else {
      this.isUploading = false;
      this.uploadFinishedPromiseContainer.resolveAll();
    }
  }

  private async uploadUploadItem(item: TAnyUploadItem): Promise<void> {
    item.isBeingUploaded = true;

    try {
      const strategy = this.getStrategyByName(item.strategyName);

      if (item.showDialogs && !DeviceInfoHelper.isApp()) {
        NotificationHelper.notifyNeutral(
          this.i18n.tr('general.fileUploadStarted'),
          NotificationDuration.SHORT
        );
      }

      const { chunkedFileId } = await this.uploadFileAsChunks(strategy, item);
      await strategy.uploadUploadItem(item.data, chunkedFileId);

      if (item.showDialogs && !DeviceInfoHelper.isApp()) {
        NotificationHelper.notifySuccess(
          this.i18n.tr('general.fileUploadSuccess'),
          NotificationDuration.SHORT
        );
      }

      const index = this.uploadItems.indexOf(item);
      if (index >= 0) {
        this.uploadItems.splice(index, 1);
        this.saveUploadItemsRateLimited();
      }

      // do not prevent other items from being uploaded just because we have one malformed item
    } catch (error) {
      if (item.showDialogs && !DeviceInfoHelper.isApp()) {
        void NotificationHelper.notifyDanger(
          this.i18n.tr('services.FileUploadService.failedUploadingFile')
        );
      }

      Logger.logError({ error });
      item.hasError = true;
      item.isBeingUploaded = false;
    }

    this.uploadNextItem();
  }

  private async uploadFileAsChunks(
    strategy: TAnyUploadStrategy,
    item: TAnyUploadItem
  ): Promise<{ chunkedFileId: string }> {
    const reader = new Uint8ArrayReader();
    const fileData = await reader.readDataUrl(
      await strategy.getFileDataUrl(item.data)
    );

    const uploader = await this.fileChunkUploadService.startUpload({
      fileData,
      chunkedFileId: item.chunkedFileId ?? null
    });

    item.chunkedFileId = uploader.getChunkedFileId();
    this.saveUploadItemsRateLimited();

    try {
      await uploader.upload();
    } catch (e) {
      if (e instanceof FileChunkUploadError) {
        if (e.getErrorType() !== FileChunkUploadErrorType.UNKNOWN) {
          item.chunkedFileId = null;
          this.saveUploadItemsRateLimited();
        }
      }

      throw e;
    }

    return {
      chunkedFileId: uploader.getChunkedFileId()
    };
  }

  private async loadUploadItemsFromLocalStorage(): Promise<void> {
    await this.readAndRemoveOldUploadItemsKey();

    const uploadItems: Array<TAnyUploadItem> = await DataStorageHelper.getItems(
      FileUploadService.TABLE_NAME
    );

    uploadItems.sort((a, b) => a.id.localeCompare(b.id));

    if (uploadItems.length) {
      this.resetUploadItems(uploadItems);
      this.uploadItems = uploadItems;
    }

    this.uploadItemsLoadedFromLocalStorage = true;
  }

  private async readAndRemoveOldUploadItemsKey(): Promise<void> {
    const uploadItems: Array<TAnyUploadItem> = await DataStorageHelper.getItem(
      '_uploadItems',
      FileUploadService.TABLE_NAME
    );

    if (!uploadItems) return;

    const convertedItems = uploadItems.map((item) => ({
      ...item,
      id: uuid()
    }));

    await this.appendOldConvertedItemsToStoredQueue(convertedItems);

    await DataStorageHelper.removeItem(
      '_uploadItems',
      FileUploadService.TABLE_NAME
    );
  }

  private async appendOldConvertedItemsToStoredQueue(
    convertedItems: Array<TAnyUploadItem>
  ): Promise<void> {
    // skip all the work if there is nothing to append
    if (convertedItems.length === 0) {
      return;
    }

    let uploadItems: Array<TAnyUploadItem> = await DataStorageHelper.getItems(
      FileUploadService.TABLE_NAME
    );
    uploadItems = uploadItems || [];

    convertedItems.forEach((i) => {
      uploadItems.push(i);
    });

    await DataStorageHelper.setItems(
      convertedItems.map((item) => ({
        key: item.id,
        value: item
      })),
      FileUploadService.TABLE_NAME
    );
  }

  private async saveUploadItems(): Promise<void> {
    const keys = await DataStorageHelper.getKeys(FileUploadService.TABLE_NAME);

    const { onlyInArray1: itemsToRemove } =
      ArrayUtils.computeArrayElementDifferences(
        keys,
        this.uploadItems.map((i) => i.id),
        (key1, key2) => key1 === key2
      );

    await DataStorageHelper.removeItems(
      itemsToRemove,
      FileUploadService.TABLE_NAME
    );

    await DataStorageHelper.setItems(
      this.uploadItems.map((item) => ({
        key: item.id,
        value: item
      })),
      FileUploadService.TABLE_NAME
    );
  }

  private getNextUploadItem(): TAnyUploadItem | null | undefined {
    return this.uploadItems.find((item) => {
      let filtered = true;

      filtered = filtered && !item.isBeingUploaded;
      filtered = filtered && !item.hasError;

      if (filtered) {
        try {
          const strategy = this.getStrategyByName(item.strategyName);
          filtered = strategy.itemIsReadyToBeUploaded(item.data);
          // do not prevent other items from being uploaded just because we have one malformed item
        } catch (error) {
          Logger.logError({ error });
          filtered = false;
        }
      }

      return filtered;
    });
  }

  private resetUploadItems(uploadItems: Array<TAnyUploadItem>): void {
    uploadItems.forEach((item) => {
      item.isBeingUploaded = false;
      item.hasError = false;
    });
  }

  private getStrategyByName(strategyName: string): TAnyUploadStrategy {
    const strategy = this.strategyMap.get(strategyName);

    if (!strategy) {
      throw new Error(
        `the strategy with the name "${strategyName}" doesn't exist`
      );
    }

    return strategy;
  }

  private setLogoutLockStatus(): void {
    let readyToBeUploaded = 0;
    let notReadyToBeUploaded = 0;
    for (const item of this.uploadItems) {
      const strategy = this.getStrategyByName(item.strategyName);
      if (strategy.itemIsReadyToBeUploaded(item.data)) {
        readyToBeUploaded++;
      } else {
        notReadyToBeUploaded++;
      }
    }

    this.logoutLockService.setLockStatus(
      LockName.FILE_UPLOAD,
      readyToBeUploaded
    );
    this.logoutLockService.setLockStatus(
      LockName.FILE_UPLOAD_ITEMS_NOT_READY,
      notReadyToBeUploaded
    );
  }

  private assertInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        "can't use the FileUploadService when it isn't initialized"
      );
    }
  }
}

/**
 * @template {Object} T
 */
export abstract class AbstractUploadStrategy<T> {
  public abstract getName(): string;

  /**
   * call modifyUploadItems to update upload items of this strategy
   * the modifyFunction should return true if the item has been changed and false if not
   * e.g. when there are ids in the data, you need to subscribe to the id changed event
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public subscribeToEvents(
    _modifyUploadItems: ModifyUploadItems<T>
  ): Array<Disposable> {
    return [];
  }

  /**
   * update e.g. local ids in the data
   * return true when you have changed the data, else return false
   */
  public abstract updateUploadItemData(uploadItemData: T): boolean;

  public abstract uploadItemsAreSimilar(a: T, b: T): boolean;

  /**
   * throw an exception/reject the promise if the item couldn't be uploaded sucessfully
   */
  public abstract uploadUploadItem(
    uploadItemData: T,
    chunkedFileId: string
  ): Promise<void>;

  public abstract itemIsReadyToBeUploaded(uploadItemData: T): boolean;

  public abstract getFileDataUrl(uploadItemData: T): Promise<string>;

  public abstract getRescueData(
    uploadItemData: T
  ): Promise<UploadItemRescueData>;
}

export type TUploadItem<T> = {
  id: string;
  strategyName: string;
  isBeingUploaded: boolean;
  hasError: boolean;
  showDialogs: boolean;
  chunkedFileId: string | null | undefined;
  data: T;
};

export enum ModificationType {
  NONE = 'none',
  MODIFIED = 'modified',
  DELETE = 'deleted'
}

export type ModifyUploadItems<TData> = (
  modifyFunction: ModifyFunction<TData>
) => void;
export type ModifyFunction<TData> = (item: TData) => ModificationType;

type TAnyUploadStrategy = AbstractUploadStrategy<any>;

type TAnyUploadItem = TUploadItem<any>;

type TOldPictureFileUploadHelperItem = {
  dataUrl: string | null;
  filePath: string | null;
  pictureFile: PictureFile | null;
  pictureFileId: string | null;
};
