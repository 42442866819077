import { bindable, autoinject } from 'aurelia-framework';
import moment from 'moment';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import {
  DateTimePickerDateInput,
  ValueChangedEvent as DateTimePickerDateInputValueChangedEvent,
  ValueChangedReason
} from './date-time-picker-date-input/date-time-picker-date-input';
import { DateTimePickerTimeInput } from './date-time-picker-time-input/date-time-picker-time-input';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from '../../../../common/src/DateUtils';

/**
 * @event value-changed
 */
@autoinject()
export class DateTimePicker {
  @bindable()
  public value: string | null = null;

  @bindable()
  public mode: DateTimePickerMode = DateTimePickerMode.Date;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public label: string | null = null;

  @bindable()
  public minutesStepWidth: number = 5;

  /**
   * If mode === DateTime and this is true, upon selecting a date from the date picker,
   * the time input will be immediately opened afterwards
   */
  @bindable()
  public requiresTimeInput: boolean = true;

  /**
   * What hour the time should be set to if not explicitly changed by the user.
   */
  @bindable()
  public defaultHour: number = 12;

  /**
   * What minute the time should be set to if not explicitly changed by the user.
   */
  @bindable()
  public defaultMinute: number = 0;

  private domElement: HTMLElement;
  private isAttached: boolean = false;
  private dateValue: Date | null = null;
  private currentDateValue: Date | null = null;
  private timeValue: Date | null = null;

  private dateInput: DateTimePickerDateInput | null = null;
  private timeInput: DateTimePickerTimeInput | null = null;

  private Mode = DateTimePickerMode;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public focus(): void {
    switch (this.mode) {
      case DateTimePickerMode.Time:
        assertNotNullOrUndefined(this.timeInput, 'no timeInput to focus');
        this.timeInput.focus();
        break;

      case DateTimePickerMode.Date:
      case DateTimePickerMode.DateTime:
      default:
        assertNotNullOrUndefined(this.dateInput, 'no dateInput to focus');
        this.dateInput.focus();
        break;
    }
  }

  public isFocused(): boolean {
    const dateInputFocused = this.dateInput
      ? this.dateInput.isFocused()
      : false;
    const timeInputFocused = this.timeInput
      ? this.timeInput.isFocused()
      : false;

    return dateInputFocused || timeInputFocused;
  }

  protected attached(): void {
    this.isAttached = true;
    this.updateInternalValues();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  private valueChanged(): void {
    if (this.isAttached) {
      this.updateInternalValues();
    }
  }

  private handlePaste(event: ClipboardEvent): void {
    const firstItem = event.clipboardData?.items[0];
    if (!firstItem || firstItem.kind !== 'string') {
      return;
    }

    firstItem.getAsString((str) => {
      let date = moment(str, 'DD.MM.YYYY HH:mm').toDate();
      if (date.toString() === 'Invalid Date') {
        date = moment(str, 'HH:mm').toDate();
      }

      if (date.toString() !== 'Invalid Date') {
        this.applyPastedDate(date);
      }
    });
  }

  private handleDateInputValueChanged(
    event: DateTimePickerDateInputValueChangedEvent
  ): void {
    if (
      event.detail.reason === ValueChangedReason.DIALOG &&
      this.timeInput &&
      this.requiresTimeInput
    ) {
      this.timeInput.openDialog();
    }

    this.handleValueChanged();
  }

  private handleValueChanged(): void {
    this.tryUpdatingValue();
  }

  private handleInputBlur(): void {
    setTimeout(() => {
      this.tryUpdatingValue();
    }, 10);
  }

  private handleDialogClosed(): void {
    this.tryUpdatingValue();
  }

  private handleDialogCanceled(): void {
    this.updateInternalValues();
  }

  private applyPastedDate(date: Date): void {
    let parsedDate: Date | null = null;

    switch (this.mode) {
      case DateTimePickerMode.Time:
        parsedDate = DateUtils.createDate(
          1,
          1,
          1900,
          date.getHours(),
          date.getMinutes()
        );
        break;

      case DateTimePickerMode.Date:
        parsedDate = DateUtils.createDate(
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear(),
          0,
          0
        );
        break;

      default:
      case DateTimePickerMode.DateTime:
        parsedDate = date;
        break;
    }

    if (parsedDate) {
      const newValue = parsedDate.toISOString();
      if (newValue !== this.value) {
        this.setValue(parsedDate.toISOString());
      } else {
        this.updateInternalValues();
      }
      setTimeout(() => {
        this.focus();
      }, 0);
    }
  }

  private updateInternalValues(): void {
    if (this.value) {
      this.dateValue = new Date(this.value);
      this.timeValue = new Date(this.value);
    } else {
      this.dateValue = null;
      this.timeValue = null;
    }
  }

  private tryUpdatingValue(): void {
    if (this.readyToChangeValue()) {
      this.updateValue();
    }
  }

  private updateValue(): void {
    switch (this.mode) {
      case DateTimePickerMode.Time:
        this.setValue(this.timeValue ? this.timeValue.toISOString() : null);
        break;

      case DateTimePickerMode.Date:
        this.setValue(this.dateValue ? this.dateValue.toISOString() : null);
        break;

      default:
      case DateTimePickerMode.DateTime:
        if (this.dateValue) {
          let hours = this.defaultHour;
          let minutes = this.defaultMinute;

          if (this.timeValue) {
            hours = this.timeValue.getHours();
            minutes = this.timeValue.getMinutes();
          }

          const date = DateUtils.createDate(
            this.dateValue.getDate(),
            this.dateValue.getMonth() + 1,
            this.dateValue.getFullYear(),
            hours,
            minutes
          );
          this.setValue(date.toISOString());
        } else if (this.value === null) {
          this.updateInternalValues();
        } else {
          this.setValue(null);
        }
        break;
    }
  }

  private setValue(value: string | null): void {
    if (this.value === value) {
      return;
    }

    this.value = value;
    setTimeout(() => {
      DomEventHelper.fireEvent<ValueChangedEvent>(this.domElement, {
        name: 'value-changed',
        detail: {
          value: this.value
        }
      });
    }, 0);
  }

  /**
   * only change the value if the user finished interacting with the date-time-input, because we want to let the user finish inputting their data before emitting them
   */
  private readyToChangeValue(): boolean {
    return (
      this.isAttached && !this.isFocused() && !this.timeInput?.dialogIsOpened()
    );
  }
}

export enum DateTimePickerMode {
  Time = 'time',
  DateTime = 'date-time',
  Date = 'date'
}

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null }
>;
