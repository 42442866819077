import _ from 'lodash';
import { KeyValue } from '../AbstractStorageStrategy';
import { JsonSerializer } from '../JsonSerializer';

export class IndexedDBSplitDataInSeparateStoresMigrator {
  private oldStore: IDBDatabase;
  private newStore: IDBDatabase;

  private tableNamesToMigrate: Array<string>;
  private readonly serializer = new JsonSerializer();

  constructor(options: Options) {
    this.oldStore = options.oldStore;
    this.newStore = options.newStore;
    this.tableNamesToMigrate = options.tableNamesToMigrate;
  }

  public async migrate(): Promise<MigrationResult> {
    const tablesOfOldStores = this.oldStore.objectStoreNames;
    const tablesToMigrate = Array.from(tablesOfOldStores).filter((name) =>
      this.tableNamesToMigrate.includes(name)
    );
    if (tablesToMigrate.length === 0) {
      return {
        oldStore: this.oldStore,
        status: MigrationStatus.ALREADY_DONE
      };
    }

    try {
      const dataToMigrate = await this.getDataOfTablesToMigrate(this.oldStore);
      await this.setDataOfTablesToMigrateToNewStore(
        this.newStore,
        dataToMigrate
      );
      const oldStoreWithoutMigratedTables = await this.deleteTables(
        tablesToMigrate,
        this.oldStore
      );
      this.oldStore = oldStoreWithoutMigratedTables;
      return {
        oldStore: this.oldStore,
        status: MigrationStatus.SUCCESS
      };
    } catch (e: any) {
      return {
        oldStore: this.oldStore,
        status: MigrationStatus.ERROR,
        error: e
      };
    }
  }

  private async setDataOfTablesToMigrateToNewStore(
    newStore: IDBDatabase,
    tableData: Map<string, Array<KeyValue>>
  ): Promise<void> {
    for (const [tableName, items] of tableData.entries()) {
      await this.setItems(items, tableName, newStore);
    }
  }

  private async getDataOfTablesToMigrate(
    oldStore: IDBDatabase
  ): Promise<Map<string, Array<KeyValue>>> {
    const tablesToMigrate: Map<string, Array<KeyValue>> = new Map();

    for (const tableName of this.tableNamesToMigrate) {
      const items = await this.getItemsWithKeys(tableName, oldStore);
      if (items.length) {
        tablesToMigrate.set(tableName, [...items]);
      }
    }
    return tablesToMigrate;
  }

  private async getItemsWithKeys(
    tableName: string,
    store: IDBDatabase
  ): Promise<Array<KeyValue>> {
    const tx = store.transaction(tableName, 'readonly');
    const objectStore = tx.objectStore(tableName);

    const req = objectStore.openCursor();

    return await new Promise((res, rej) => {
      const itemsWithKeys: Array<KeyValue> = [];

      req.onsuccess = () => {
        const cursor = req.result;
        if (cursor) {
          itemsWithKeys.push({
            key: cursor.key.toString(),
            value: this.serializer.deserialize(cursor.value)
          });
          cursor.continue();
        } else {
          res(itemsWithKeys);
        }
      };
      req.onerror = () => {
        rej();
      };
    });
  }

  private async setItems(
    items: Array<KeyValue>,
    tableName: string,
    store: IDBDatabase
  ): Promise<void> {
    const tx = store.transaction(tableName, 'readwrite');
    const objectStore = tx.objectStore(tableName);

    items.forEach((item) => {
      objectStore.put(this.serializer.serialize(item.value), item.key);
    });

    return new Promise((res, rej) => {
      tx.oncomplete = () => {
        res();
      };
      tx.onerror = (event) => {
        console.error(event);
        rej(new Error('An error occured while setting items.'));
      };
      tx.onabort = (event) => {
        console.error(event);
        rej(new Error('Setting items was aborted.'));
      };
    });
  }

  private async deleteTables(
    tableNames: Array<string>,
    store: IDBDatabase
  ): Promise<IDBDatabase> {
    const oldStore = store;
    const oldVersion = oldStore.version;
    const storeName = oldStore.name;

    oldStore.close();

    return new Promise((res, rej) => {
      const newStoreOpenHandler = indexedDB.open(storeName, oldVersion + 1);

      newStoreOpenHandler.onupgradeneeded = () => {
        const db = newStoreOpenHandler.result;
        tableNames.forEach((tableName) => {
          db.deleteObjectStore(tableName);
        });
      };

      newStoreOpenHandler.onsuccess = () => {
        res(newStoreOpenHandler.result);
      };

      newStoreOpenHandler.onerror = () => {
        rej();
      };
    });
  }
}

type Options = {
  oldStore: IDBDatabase;
  newStore: IDBDatabase;
  tableNamesToMigrate: Array<string>;
};

export enum MigrationStatus {
  ALREADY_DONE = 'alreadyDone',
  SUCCESS = 'success',
  ERROR = 'error'
}

type MigrationResult = {
  status: MigrationStatus;
  oldStore: IDBDatabase;
  error?: any;
};
