import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessConfigurationActionStatus } from './types';

export class ProcessConfigurationActionStatusRepository extends AppEntityRepository<EntityName.ProcessConfigurationActionStatus> {
  public getByProcessConfigurationId(
    processConfigurationId: string
  ): Array<ProcessConfigurationActionStatus> {
    return this.getAll().filter(
      (as) => as.ownerProcessConfigurationId === processConfigurationId
    );
  }
}
