import { autoinject, bindable } from 'aurelia-framework';

/**
 * A progress bar for displaying loading states.
 *
 * # Styling
 * The color may be changed with the `--progress-bar-color` property.
 * The background can be set with the `--progress-bar-bg` property.
 */
@autoinject()
export class ProgressBar {
  @bindable() public value: number = 0;
  @bindable() public max: number = 100;
}
