import { autoinject, observable, computedFrom } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManager } from '../../classes/SubscriptionManager';

import { Utils } from '../../classes/Utils/Utils';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import {
  FilterMode,
  ShowMode
} from '../../filterComponents/entry-filter/EntryFilterFilterer';
import { ShadowEntriesUpdater } from '../../classes/ShadowEntriesUpdater';

import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { StructureThingRapidFireWidget } from '../structure-thing-rapid-fire-widget/structure-thing-rapid-fire-widget';
import { EditStructureNavigator } from '../../classes/EditStructureNavigator';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { EditEntryWidgetOverlay } from '../../aureliaComponents/edit-entry-widget-overlay/edit-entry-widget-overlay';
import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { StructureListItem } from '../../aureliaComponents/structure-list-item/structure-list-item';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  MoveEntryEventDetail,
  StructureListTreeItem
} from '../../aureliaComponents/structure-list-tree-item/structure-list-tree-item';
import {
  MoveEntryToPathEvent,
  CopyEntryToPathEvent
} from '../structure-thing-move-or-copy-entry-widget/structure-thing-move-or-copy-entry-widget';
import { Path } from '../structure-thing-path-selector/structure-thing-path-selector';
import { ImageHelper } from '../../classes/ImageHelper';
import { Dialogs } from '../../classes/Dialogs';
import { StructureTemplateEntryPropertiesHelper } from '../../classes/EntityManager/entities/StructureTemplateEntryProperty/StructureTemplateEntryPropertiesHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { StructureTemplate } from '../../classes/EntityManager/entities/StructureTemplate/types';
import { EntryPicture } from '../../classes/EntityManager/entities/Picture/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ApplyPropertiesService } from '../../classes/EntityManager/entities/Property/ApplyPropertiesService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureFileCreationEntity } from '../../classes/EntityManager/entities/PictureFile/types';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { SharepointHelper } from '../../classes/SharepointHelper';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SocketEndpointProcessTaskToProjectCrudStrategy } from '../../dialogs/manage-process-task-to-project-relations-dialog/strategies/SocketEndpointProcessTaskToProjectCrudStrategy';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { SocketService } from '../../services/SocketService';
import {
  SingleSocketRequest,
  SingleSocketRequestService
} from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { PictureFileByActivePictureRevisionService } from '../../classes/EntityManager/entities/PictureFile/PictureFileByActivePictureRevisionService';

/**
 * @event navigate-to-report-export - detail: { projectId: string }
 */
@autoinject()
export class StructureProjectPageContent {
  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private readonly structureTemplateEntryPropertiesHelper: StructureTemplateEntryPropertiesHelper;

  private structureTemplate: StructureTemplate | null = null;

  @observable protected isMobile: boolean;

  protected project: Project | null = null;
  private reportType: ReportType | null = null;

  protected availableEntries: Array<Entry> = [];

  protected filteredEntries: Array<Entry> = [];

  protected listTreeItems: Set<StructureListTreeItem> = new Set();

  protected entryFilterShowMode = ShowMode.ALL;

  private domElement: Element;

  protected treeListElement: HTMLElement | null = null;

  protected parameterPanelOpen = false;
  protected parameterPanelViewModel: ParameterPanel | null = null;

  protected operationsProcessTasksPanelOpen = false;
  protected operationsProcessTasksPanelViewModel: ParameterPanel | null = null;

  protected editEntryWidgetOverlay: EditEntryWidgetOverlay | null = null;

  protected crudStrategy: SocketEndpointProcessTaskToProjectCrudStrategy;

  private navigator: EditStructureNavigator | null = null;

  private isAttached = false;
  protected showProjectProperties = false;

  private entryToStartEditing: Entry | null = null;

  private globalMenuChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'structureThing.structureProjectPageContent.exportButton',
      name: 'export'
    }
  ];

  protected entryFilterString = '';

  protected FilterMode = FilterMode;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly pictureFileByActivePictureRevisionService: PictureFileByActivePictureRevisionService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService,
    applyPropertiesService: ApplyPropertiesService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    socketService: SocketService,
    singleSocketRequestService: SingleSocketRequestService
  ) {
    this.domElement = element;

    this.subscriptionManager = subscriptionManagerService.create();

    this.structureTemplateEntryPropertiesHelper =
      new StructureTemplateEntryPropertiesHelper(
        entityManager,
        applyPropertiesService
      );

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: null
      });

    this.isMobile = false;
    this.crudStrategy = new SocketEndpointProcessTaskToProjectCrudStrategy(
      ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
      socketService,
      singleSocketRequestService
    );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateEntries.bind(this)
    );
    this.updateEntries();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Project,
      this.updateReportType.bind(this)
    );
    this.updateReportType();

    this.subscriptionManager.subscribeToPropertyChange(
      this,
      'reportTypeHasStructure',
      () => {
        if (!this.reportTypeHasStructure)
          this.parameterPanelViewModel?.collapse();
      }
    );

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.globalMenuChoices,
      selectedCallbacks: {
        export: this.navigateToReportExport.bind(this)
      }
    });

    this.tryStartEditingEntry(null, false);
  }

  protected detached(): void {
    this.isAttached = false;
    GlobalMenu.releaseControl(this);
    this.subscriptionManager.disposeSubscriptions();
  }

  /**
   * @param projectId
   * @param editEntry - 'true' to automatically open the edit-entry-widget
   * @param entryId
   * @param navigator
   * @param openParameterPanel - 'true' to automatically open the parameter-panel
   */
  public init(
    projectId: string,
    editEntry: string | null,
    entryId: string | null,
    navigator: EditStructureNavigator,
    openParameterPanel: string | null
  ): void {
    this.project =
      this.entityManager.projectRepository.getById(projectId) || null;
    this.updateReportType();

    this.projectPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: this.project
      });

    assertNotNullOrUndefined(this.project, 'project not found');

    void this.entityManager.joinedProjectsManager.joinProject(this.project.id);

    if (this.project.structureTemplateId) {
      this.structureTemplate =
        this.entityManager.structureTemplateRepository.getById(
          this.project.structureTemplateId
        );
    } else if (this.project.template_name) {
      console.warn(
        'Project has deprecated template name set!',
        this.project.id
      );
      this.structureTemplate =
        this.entityManager.structureTemplateRepository.getByOldTemplateName(
          this.project.template_name
        );
    }
    if (!this.structureTemplate) {
      console.warn('structure template of project not found');
      return;
    }

    this.updateEntries();

    if (editEntry === 'true' && entryId) {
      const entry = this.entityManager.entryRepository.getById(entryId) || null;
      // we want the parent to be shown in the background, so the behaviour is the same as in the no direct link variant
      this.tryStartEditingEntry(entry, false);
    } else if (
      this.editEntryWidgetOverlay &&
      this.editEntryWidgetOverlay.isActive()
    ) {
      this.editEntryWidgetOverlay.stopEditingEntry(false);
    }

    if (openParameterPanel === 'true' && this.parameterPanelViewModel) {
      this.parameterPanelViewModel.expand();
    }

    this.navigator = navigator;
  }

  @computed(model(EntityName.ProcessConfiguration))
  protected get showOperationsProcessTasksPanel(): boolean {
    return this.entityManager.processConfigurationRepository
      .getAll()
      .some((pc) => pc.processTaskToProjectRelations?.enabled);
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) {
      GlobalMenu.setVisible(this, this.isMobile);
    }
  }

  protected entryHasFilterStringInName(
    entryName: string | null,
    filterString: string
  ): boolean {
    if (!filterString || !entryName) return false;
    const upperCaseEntryName = entryName.toUpperCase();
    const upperCaseFilterString = filterString.toUpperCase();
    return upperCaseEntryName.indexOf(upperCaseFilterString) > -1;
  }

  protected handleNewEntry(pageDepthParent: Entry): void {
    assertNotNullOrUndefined(this.project, 'project is not available');

    const newEntry = this.entityManager.entryRepository.create({
      project: this.project.id,
      ownerProjectId: this.project.id,
      ownerUserGroupId: this.project.usergroup,
      page_depth_parent: pageDepthParent.id
    });

    this.ensureShadowEntries(newEntry, pageDepthParent.id);
    void this.expandAndStartEditingEntry(newEntry);
  }

  protected handleMoveEntry({
    entry,
    parent,
    listPosition
  }: MoveEntryEventDetail): void {
    this.entityManager.entryRepository.setParentIdOfEntry(entry, parent.id);

    if (listPosition != null) {
      this.entityManager.entryRepository.setListPositionOfEntry(
        entry,
        listPosition
      );
    }

    this.ensureShadowEntries(entry, parent.id);
  }

  protected handleMoveEntryToPath(event: MoveEntryToPathEvent): void {
    this.moveEntryToPath(event.detail.entry, event.detail.path);
  }

  private moveEntryToPath(entry: Entry, path: Path): void {
    const newParent = this.getLastParentForPath(path);
    this.handleMoveEntry({
      entry: entry,
      parent: newParent,
      listPosition: null
    });
  }

  protected async handleCopyEntryToPath(
    event: CopyEntryToPathEvent
  ): Promise<void> {
    const entry = event.detail.entry;
    try {
      await this.checkEntryPictureFileFiles(entry);
    } catch (error) {
      void Dialogs.errorDialog(error);
      return;
    }

    const entryCopy = this.entityManager.entryRepository.create({
      ...entry
    });

    this.copyPropertiesOfEntry(entry, entryCopy.id);
    await this.copyPicturesOfEntry(entry, entryCopy.id);

    this.moveEntryToPath(entryCopy, event.detail.path);

    Dialogs.timedSuccessDialogTk(
      'structureThing.structureProjectPageContent.entrySuccessfullyCopied'
    );
  }

  private copyPropertiesOfEntry(entry: Entry, newEntryId: string): void {
    const entryProperties = this.entityManager.propertyRepository.getByEntryId(
      entry.id
    );
    entryProperties.forEach((property) => {
      const notAllowedTypes = ['tabelle', 'bild', 'unterschrift'];
      if (property.type && notAllowedTypes.includes(property.type)) {
        console.warn(
          `entry should not have a property of type '${property.type}'`
        );
        return;
      }

      this.entityManager.propertyRepository.create({
        ...property,
        entry: newEntryId
      });
    });
  }

  private async copyPicturesOfEntry(
    entry: Entry,
    newEntryId: string
  ): Promise<void> {
    const entryPictures = this.entityManager.pictureRepository.getByEntryId(
      entry.id
    );

    for (const picture of entryPictures) {
      await this.copyPicture(picture, newEntryId);
    }
  }

  private async copyPicture(
    picture: EntryPicture,
    newEntryId: string
  ): Promise<void> {
    const newPicture = this.entityManager.pictureRepository.create({
      ...picture,
      takenByUserId: picture.takenByUserId ?? picture.createdByUserId,
      entry: newEntryId
    });

    const pictureFile =
      this.pictureFileByActivePictureRevisionService.getPictureFileToDisplayByPictureId(
        picture.id
      );
    assertNotNullOrUndefined(
      pictureFile,
      `picture '${picture.id}' should have a picture file`
    );

    const pictureFileCreationData: PictureFileCreationEntity = {
      ...pictureFile,
      picture: newPicture.id
    };

    delete pictureFileCreationData['file_uploaded'];
    delete pictureFileCreationData['file_created'];
    delete pictureFileCreationData['isOriginatingHere'];
    delete pictureFileCreationData['local_created'];
    delete pictureFileCreationData['readyForUpload'];

    const newPictureFile = this.entityManager.pictureFileRepository.create(
      pictureFileCreationData
    );

    await this.savePictureFileDataUrlService.copyPictureFileFile(
      pictureFile,
      newPictureFile
    );
  }

  private async checkEntryPictureFileFiles(entry: Entry): Promise<void> {
    const pictures = this.entityManager.pictureRepository.getByEntryId(
      entry.id
    );
    const pictureIds = pictures.map((picture) => picture.id);
    const pictureFiles =
      this.entityManager.pictureFileRepository.getByPictureIds(pictureIds);

    for (const picFile of pictureFiles) {
      const pictureFileSource =
        await this.pictureFilePathService.getPictureFileSource(picFile);
      assertNotNullOrUndefined(pictureFileSource, 'picture file has no source');

      await ImageHelper.loadImage(pictureFileSource);
    }
  }

  private getLastParentForPath(path: Path): Entry {
    assertNotNullOrUndefined(this.project, 'cannot get entry without project');

    const parentStructureTemplateEntry =
      path.structureTemplateEntries[path.structureTemplateEntries.length - 1];
    assertNotNullOrUndefined(
      parentStructureTemplateEntry,
      'no structure template parent entry found'
    );

    const parents =
      this.entityManager.entryRepository.getByStructureTemplateEntryAndProjectId(
        parentStructureTemplateEntry,
        this.project.id
      );

    const firstParent = parents[0];
    assertNotNullOrUndefined(
      firstParent,
      `parent(s) with origin id "${parentStructureTemplateEntry.id}" of project "${this.project.id}" not found`
    );

    if (parents.length === 1) {
      return firstParent;
    } else {
      const parentParentStructureTemplateEntry =
        path.structureTemplateEntries[path.structureTemplateEntries.length - 2];
      assertNotNullOrUndefined(
        parentParentStructureTemplateEntry,
        'there is no next parent in structure template entry path'
      );

      const [parentParentEntry] =
        this.entityManager.entryRepository.getByStructureTemplateEntryAndProjectId(
          parentParentStructureTemplateEntry,
          this.project.id
        );
      assertNotNullOrUndefined(
        parentParentEntry,
        'parent of parent entry is not available'
      );

      const parentEntry = parents.find(
        (e) => e.page_depth_parent === parentParentEntry.id
      );
      assertNotNullOrUndefined(parentEntry, 'cannot find parent entry');

      return parentEntry;
    }
  }

  private ensureShadowEntries(
    newEntry: Entry,
    pageDepthParentId: string
  ): void {
    this.createShadowEntryParents(pageDepthParentId);
    const parents =
      this.entityManager.entryRepository.getPathByEntryId(pageDepthParentId);
    this.createShadowEntries(parents);

    assertNotNullOrUndefined(
      this.structureTemplate,
      'structure template is not available'
    );
    this.structureTemplateEntryPropertiesHelper.applyEntryPropertiesFromTemplate(
      newEntry,
      this.structureTemplate.id
    );

    this.updateEntries();
  }

  private async expandAndStartEditingEntry(entry: Entry): Promise<void> {
    if (entry.page_depth_parent) {
      await this.expandToEntry(entry.page_depth_parent);
    }

    this.startEditingEntry(entry, true);
  }

  private async expandToEntry(pageDepthParentId: string): Promise<void> {
    const parents =
      this.entityManager.entryRepository.getPathByEntryId(pageDepthParentId);
    const parentsCopy = parents.slice();

    const firstParent = parentsCopy.pop();
    if (!firstParent) return;

    const parentElement = this.getElementForEntryId(firstParent.id);
    if (!parentElement) return;

    const parentElementVM =
      Utils.getViewModelOfElement<StructureListTreeItem>(parentElement);
    if (parentElementVM) {
      await parentElementVM.expandToEntry(parentsCopy);
    }
  }

  private updateEntries(): void {
    if (!this.project) {
      this.availableEntries = [];
      return;
    }

    if (!this.structureTemplate) {
      console.warn('structureTemplate is not available');
      return;
    }

    const shadowEntriesUpdater = new ShadowEntriesUpdater(
      this.entityManager,
      this.project,
      this.structureTemplate
    );
    shadowEntriesUpdater.update();

    this.availableEntries = this.entityManager.entryRepository.getByParentId(
      this.project.id,
      null,
      null
    );
  }

  private updateReportType(): void {
    if (this.project) {
      this.reportType = this.entityManager.reportTypeRepository.getById(
        this.project.report_type
      );
    } else {
      this.reportType = null;
    }
  }

  protected handleEditEntryWidgetOverlayClosed(event: CustomEvent): void {
    assertNotNullOrUndefined(this.navigator, 'navigator is not available');
    this.navigator.navigateToEntry(this.project);

    const element = this.getElementForEntryId(event.detail.entry.id);
    if (element) {
      const vm = Utils.getViewModelOfElement<StructureListItem>(element);
      vm?.highlight();
    }
  }

  protected handleEditEntryWidgetOverlayEntryChanged(event: CustomEvent): void {
    assertNotNullOrUndefined(this.navigator, 'navigator is not available');

    const entry = event.detail.entry;
    this.navigator.navigateToEntry(this.project, entry, true);
  }

  protected createBoundGetElementForEntryFunction(): (
    entry: Entry
  ) => Element | null {
    return (entry) => {
      const element = this.getElementForEntryId(entry.id);
      return element ? element : this.treeListElement;
    };
  }

  private getElementForEntryId(entryId: string): HTMLElement | null {
    assertNotNullOrUndefined(
      this.treeListElement,
      'treeListElement is not available'
    );
    return this.treeListElement.querySelector(`#entry-${entryId}`);
  }

  protected handleTogglePropertiesClick(): void {
    if (this.parameterPanelViewModel)
      void this.parameterPanelViewModel.toggle();
  }

  protected handleToggleOperationsProcessTasksClick(): void {
    if (this.operationsProcessTasksPanelViewModel)
      void this.operationsProcessTasksPanelViewModel.toggle();
  }

  protected handleEntryEditClicked(entry: Entry): void {
    this.startEditingEntry(entry, true);
  }

  protected handleEntryChanged(entry: Entry): void {
    this.createShadowEntryParents(entry.id);
  }

  /**
   * tries to start editing the entry (since it only works when the elements are rendered)
   * if it can't be started yet, the entry will be stored in _entryToStartEditing for a later retry
   * this logic is extracted into this function so you don't have a duplicate implementation in attached and in activate
   */
  private tryStartEditingEntry(entry: Entry | null, animate: boolean): void {
    const e = entry || this.entryToStartEditing;

    if (!e) {
      return;
    }

    if (this.isAttached) {
      setTimeout(() => {
        this.startEditingEntry(e, animate, false);
      }, 0); // wait until elements have been rendered
    } else {
      this.entryToStartEditing = e;
    }
  }

  /**
   * @param entry
   * @param animate - set this to true to display the editor with a fancy animation
   */
  private startEditingEntry(
    entry: Entry,
    animate: boolean,
    navigate = true
  ): void {
    assertNotNullOrUndefined(
      this.editEntryWidgetOverlay,
      'editEntryWidgetOverlay is not available'
    );
    this.editEntryWidgetOverlay.startEditingEntry(entry, animate);

    assertNotNullOrUndefined(this.navigator, 'navigator is not available');
    if (navigate) this.navigator.navigateToEntry(this.project, entry, true);
  }

  protected navigateToReportExport(): void {
    assertNotNullOrUndefined(this.project, 'project is not available');

    DomEventHelper.fireEvent(this.domElement, {
      name: 'navigate-to-report-export',
      detail: {
        projectId: this.project.id
      },
      bubbles: true
    });
  }

  protected handleStartRapidFireModeClicked(): void {
    assertNotNullOrUndefined(
      this.structureTemplate,
      'structure template not available'
    );
    assertNotNullOrUndefined(this.project, 'project is not available');

    StructureThingRapidFireWidget.start({
      projectId: this.project.id,
      structureTemplate: this.structureTemplate
    });
  }

  /**
   * creates the entry/parent entries if necessary
   */
  private createShadowEntryParents(entryId: string): void {
    const parents =
      this.entityManager.entryRepository.getPathByEntryId(entryId);
    this.createShadowEntries(parents);
  }

  /**
   * creates the entries if they are a shadow entry
   * the entries will be created beginning from the last entry passed in,
   * because it's expected the innermost entry will be the first one
   */
  private createShadowEntries(entries: Array<Entry>): void {
    const structureTemplate = this.structureTemplate;
    assertNotNullOrUndefined(
      structureTemplate,
      'structure template not available'
    );

    this.entityManager.entryRepository.createShadowEntitiesInPath(entries);
  }

  @computedFrom('reportType.features.hasStructure')
  protected get reportTypeHasStructure(): boolean {
    return this.reportType?.features?.hasStructure !== false;
  }

  protected handleOpenInSharepoint(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot open in sharepoint without a project'
    );

    const url = SharepointHelper.getSharepointExportUrlForThingId({
      entityManager: this.entityManager,
      thingId: this.project.thing
    });

    window.open(url, DeviceInfoHelper.isApp() ? '_self' : '_blank');
  }

  @computed(expression('userGroup.sharepointCredentials'))
  protected get sharepointEnabled(): boolean {
    const sharepointCredentials = this.userGroup?.sharepointCredentials;
    return [
      sharepointCredentials?.applicationId,
      sharepointCredentials?.tenantId,
      sharepointCredentials?.clientCertificate.thumbprint,
      sharepointCredentials?.clientCertificate.privateKey,
      sharepointCredentials?.sharepointExportSite,
      sharepointCredentials?.sharepointExportPath
    ].every(Boolean);
  }

  @computed(expression('thing.ownerUserGroupId'), model(EntityName.UserGroup))
  private get userGroup(): UserGroup | null {
    if (!this.thing) {
      return null;
    }

    return this.entityManager.userGroupRepository.getById(
      this.thing.ownerUserGroupId
    );
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  private get thing(): Thing | null {
    if (!this.project) return null;
    return this.entityManager.thingRepository.getById(this.project?.thing);
  }
}
