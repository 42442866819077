import { OriginatedFromPictureInfo } from 'common/Types/Entities/Picture/PictureDto';
import { EntityUtils } from '@record-it-npm/synchro-client';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  Picture,
  ThingSectionPicture
} from '../../../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  ExistingCutout,
  ExistingCutoutsAdapter,
  SubscribeOptions
} from '../ExistingCutoutsAdapter';

export class ExistingThingGroupCutoutsAdapter
  implements ExistingCutoutsAdapter
{
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly picture: Picture;

  constructor(options: ExistingThingGroupCutoutsAdapterOptions) {
    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.picture = options.picture;
  }

  public subscribe({ setExistingCutouts }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateCutouts = (): void => {
      setExistingCutouts(this.getExistingCutouts());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSection,
      updateCutouts
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updateCutouts
    );
    updateCutouts();

    return subscriptionManager.toDisposable();
  }

  private getExistingCutouts(): Array<ExistingCutout> {
    const pictures = this.entityManager.pictureRepository
      .getAll()
      .filter(
        (
          picture
        ): picture is ThingSectionPictureWithOriginatedFromPictureInfo => {
          return (
            !!picture.thingSectionId &&
            picture.originatedFromPictureInfo?.pictureId === this.picture.id
          );
        }
      );

    return pictures
      .map<ExistingCutout | null>((picture) => {
        const thingSection = this.entityManager.thingSectionRepository.getById(
          picture.thingSectionId
        );
        if (!thingSection) {
          return null;
        }

        return {
          trackingKey: EntityUtils.getTrackingKey(thingSection),
          label: thingSection.name,
          topLeft: picture.originatedFromPictureInfo.topLeft,
          bottomRight: picture.originatedFromPictureInfo.bottomRight
        };
      })
      .filter((existingCutout): existingCutout is ExistingCutout => {
        return !!existingCutout;
      });
  }
}

export type ExistingThingGroupCutoutsAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  /**
   * Base picture where a cutout will be created from
   */
  picture: Picture;
};

type ThingSectionPictureWithOriginatedFromPictureInfo = ThingSectionPicture & {
  originatedFromPictureInfo: OriginatedFromPictureInfo;
};
