import { autoinject, bindable } from 'aurelia-framework';
import { ProjectQuestion } from '../../classes/EntityManager/entities/ProjectQuestion/types';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@autoinject()
export class ChecklistProjectQuestionNoteWidget {
  @bindable public question: ProjectQuestion | null = null;

  constructor(private readonly entityManager: AppEntityManager) {}

  protected handleQuestionUpdated(): void {
    assertNotNullOrUndefined(
      this.question,
      'cannot handleQuestionUpdated without question'
    );

    this.entityManager.projectQuestionRepository.update(this.question);
  }
}
