import { autoinject, bindable } from 'aurelia-framework';
import { BehaviorSubject, switchMap } from 'rxjs';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';

import { PictureFullScreenOverlay } from '../../../aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import {
  AdditionalMarkingsByPictureId,
  AdditionalMarkingWithSourcePicture,
  PictureMarkingService
} from '../../../classes/EntityManager/entities/Picture/PictureMarkingService';
import {
  EntryPicture,
  Picture,
  ThingSectionPicture
} from '../../../classes/EntityManager/entities/Picture/types';
import { Project } from '../../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../../classes/EntityManager/entities/Thing/types';
import { ThingSection } from '../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import {
  PictureScroller,
  PictureSelectedEvent
} from '../../../picture/picture-scroller/picture-scroller';
import { RxjsService } from '../../../services/RxjsService/RxjsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { computed } from '../../../hooks/computed';
import { expression } from '../../../hooks/dependencies';

@autoinject()
export class ThingSectionAssessmentDialogThingSectionPicture {
  @bindable()
  public thing: Thing | null = null;

  @bindable()
  public project: Project | null = null;

  @bindable()
  public thingSection: ThingSection | null = null;

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;

  private additionalMarkingsByPictureId: AdditionalMarkingsByPictureId =
    new Map();
  private additionalMarkingsByPreviousPictureId: AdditionalMarkingsByPictureId =
    new Map();

  protected picture: ThingSectionPicture | null = null;

  protected highlightedPicture: Picture | null = null;

  protected markings: Array<AdditionalMarkingWithSourcePicture> = [];
  protected markedEntryPictures: Array<EntryPicture> = [];

  protected pictureScroller: PictureScroller | null = null;
  protected pictureScrollerElement: HTMLElement | null = null;

  protected previousPictureScroller: PictureScroller | null = null;
  protected previousPictureScrollerElement: HTMLElement | null = null;

  protected showPreviousMarkings = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureMarkingService: PictureMarkingService,
    private readonly rxjsService: RxjsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updatePicture.bind(this)
    );
    this.updatePicture();

    this.subscriptionManager.addRxjsSubscription(
      this.rxjsService
        .fromProperty({ context: this, propertyName: 'project' })
        .pipe(
          switchMap((project) => {
            if (!project) {
              return new BehaviorSubject(new Map());
            }

            return this.pictureMarkingService.createAdditionalMarkingsObservableForProjectId(
              project.id
            );
          })
        )
        .subscribe((additionalMarkingsByPictureId) => {
          this.additionalMarkingsByPictureId = additionalMarkingsByPictureId;
          this.updateMarkings();
        })
    );

    this.subscriptionManager.addRxjsSubscription(
      this.rxjsService
        .fromExpression<Project | null>({
          context: this,
          expression: 'previousProject'
        })
        .pipe(
          switchMap((project) => {
            if (!project) {
              return new BehaviorSubject(new Map());
            }

            return this.pictureMarkingService.createAdditionalMarkingsObservableForProjectId(
              project.id
            );
          })
        )
        .subscribe((additionalMarkingsByPreviousPictureId) => {
          this.additionalMarkingsByPreviousPictureId =
            additionalMarkingsByPreviousPictureId;
        })
    );
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingSectionChanged(): void {
    if (this.isAttached) {
      this.updatePicture();
    }
  }

  @computed(expression('project'))
  private get allProjects(): Array<Project> {
    const projects = this.project
      ? this.entityManager.projectRepository.getByThingIdWithoutOperationsProjects(
          this.project.thing
        )
      : [];

    const projectType = this.project?.projectType ?? ProjectType.BASIC;

    return projects
      .filter((p) => p.projectType === projectType)
      .sort((p1, p2) => p1.created - p2.created);
  }

  @computed(expression('allProjects'))
  protected get previousProject(): Project | null {
    const index = this.project ? this.allProjects.indexOf(this.project) : -1;
    return this.allProjects[index - 1] ?? null;
  }

  @computed(
    expression('picture'),
    expression('additionalMarkingsByPreviousPictureId')
  )
  private get previousMarkings(): Array<AdditionalMarkingWithSourcePicture> {
    if (this.picture) {
      return (
        this.additionalMarkingsByPreviousPictureId.get(this.picture.id) ?? []
      );
    }

    return [];
  }

  @computed(expression('previousMarkings'))
  protected get previousMarkedEntryPictures(): Array<EntryPicture> {
    const markedEntryPictures = [];

    for (const marking of this.previousMarkings) {
      if (marking.sourcePicture.entry) {
        markedEntryPictures.push(marking.sourcePicture);
      }
    }

    return markedEntryPictures;
  }

  private updatePicture(): void {
    if (this.thingSection) {
      const pictures = this.entityManager.pictureRepository.getByThingSectionId(
        this.thingSection.id
      );
      this.picture = pictures[0] ?? null;
    } else {
      this.picture = null;
    }

    this.updateMarkings();
  }

  private updateMarkings(): void {
    if (this.picture) {
      this.markings =
        this.additionalMarkingsByPictureId.get(this.picture.id) ?? [];
    } else {
      this.markings = [];
    }

    this.updateMarkedEntryPictures();
  }

  private updateMarkedEntryPictures(): void {
    const markedEntryPictures: Array<EntryPicture> = [];

    for (const marking of this.markings) {
      if (marking.sourcePicture.entry) {
        markedEntryPictures.push(marking.sourcePicture);
      }
    }

    this.markedEntryPictures = markedEntryPictures;
  }

  protected handlePictureClicked(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't ThingSectionAssessmentDialogThingSectionPicture.handlePictureClicked without picture"
    );

    void PictureFullScreenOverlay.open({
      picture: this.picture
    });
  }

  protected handlePictureScrollerPictureSelected(
    event: PictureSelectedEvent
  ): void {
    this.setHighlightedPicture(event.detail.picture);
  }

  protected handleMarkingClicked(
    marking: AdditionalMarkingWithSourcePicture
  ): void {
    this.setHighlightedPicture(marking.sourcePicture);
  }

  protected handleExpandPictureClicked(picture: Picture): void {
    void PictureFullScreenOverlay.open({
      picture
    });
  }

  private setHighlightedPicture(picture: Picture | null): void {
    this.highlightedPicture = picture;

    if (picture) {
      this.pictureScroller?.scrollToPicture(picture);
      this.previousPictureScroller?.scrollToPicture(picture);

      if (picture.ownerProjectId === this.project?.id) {
        this.pictureScrollerElement?.scrollIntoView({
          block: 'nearest'
        });
      } else if (picture.ownerProjectId === this.previousProject?.id) {
        this.previousPictureScrollerElement?.scrollIntoView({
          block: 'nearest'
        });
      }
    }
  }
}
