import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createProcessConfigurationFollowUpAppointmentEntityInfo } from 'common/Types/Entities/ProcessConfigurationFollowUpAppointment/createProcessConfigurationFollowUpAppointmentEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { ProcessConfigurationFollowUpAppointment } from './types';

export const processConfigurationFollowUpAppointmentEntityInfo =
  createProcessConfigurationFollowUpAppointmentEntityInfo<ProcessConfigurationFollowUpAppointment>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
