import { autoinject } from 'aurelia-framework';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskPositionDetailEntry } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class ProcessTaskPositionDetailEntriesByProcessTaskPositionIdComputer extends ValueComputer<
  IComputeData,
  ProcessTaskPositionDetailEntriesByProcessTaskPositionId
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskPositionDetailEntry,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(
    data: IComputeData
  ): ProcessTaskPositionDetailEntriesByProcessTaskPositionId {
    const detailEntries =
      this.entityManager.processTaskPositionDetailEntryRepository.getByOwnerProcessTaskId(
        data.ownerProcessTaskId
      );
    return Utils.groupBy(detailEntries, (entry) => entry.processTaskPositionId);
  }
}

export interface IComputeData {
  ownerProcessTaskId: string;
}

export type ProcessTaskPositionDetailEntriesByProcessTaskPositionId = Map<
  string,
  Array<ProcessTaskPositionDetailEntry>
>;
