import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { MomentInput } from 'moment';

import { DateUtils } from 'common/DateUtils';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  allExpandableProcessAppointmentTabs,
  allProcessAppointmentTabs,
  allProcessTaskFeatures,
  allProcessTaskInfoSections,
  ProcessAppointmentTabsKeys,
  ShowProcessTaskInfoSectionsKeys,
  ShowProcessTaskFeaturesKeys,
  processConfigurationContactPersonPurposesSchema,
  ProcessConfigurationContactPersonPurpose
} from 'common/Types/Entities/ProcessConfiguration/ProcessConfigurationDto';

import { RecordItDialog } from '../../dialogs/record-it-dialog/record-it-dialog';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { SocketService } from '../../services/SocketService';
import { Dialogs } from '../../classes/Dialogs';
import { TJsonTextChangedEvent } from '../../inputComponents/json-zod-object-input/json-zod-object-input';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { configureHooks } from '../../hooks/configureHooks';

/**
 * @techdebt [REC-4352] dialog should be refactored to use permissionsService, add permission checking where enabled=true is still used
 */
@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class EditProcessConfigurationDialog {
  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  private processConfiguration: ProcessConfiguration | null = null;
  private onDialogClosed: OnDialogClosedCallback | null | undefined = null;

  private dialog: RecordItDialog | null = null;

  protected processConfigurationContactPersonPurposesSchema =
    processConfigurationContactPersonPurposesSchema;

  protected processConfigurationContactPersonPurposesDemoObject: Array<ProcessConfigurationContactPersonPurpose> =
    [
      {
        label: 'General Contact',
        value: null,
        restrictToThingGroupOwnerPersonRelations: true
      },
      {
        label: 'Regional Manager',
        value: 'regionalManager',
        restrictToCategory: 'whiteCollar'
      },
      {
        label: 'Assistant to the Regional Manager',
        value: 'regionalManagerAssistant'
      }
    ];

  public static async open(options: IOpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  constructor(
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  public open(options: IOpenOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't EditProcessConfigurationDialog.open without a dialog"
    );
    this.processConfiguration = options.processConfiguration;
    this.onDialogClosed = options.onDialogClosed;

    this.dialog.open();
  }

  private handleDialogClosed(): void {
    const onDialogClosed = this.onDialogClosed;
    const processConfiguration = this.processConfiguration;

    this.processConfiguration = null;
    this.onDialogClosed = null;

    if (processConfiguration) {
      onDialogClosed?.(processConfiguration);
    }
  }

  private handleProcessConfigurationChanged(): void {
    if (this.processConfiguration) {
      this.entityManager.processConfigurationRepository.update(
        this.processConfiguration
      );
    }
  }

  protected handleContactPersonPurposesChanged(
    event: TJsonTextChangedEvent<
      Array<ProcessConfigurationContactPersonPurpose>
    >
  ): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      "can't EditProcessConfigurationDialog.handleContactPersonPurposesChanged without processConfiguration"
    );
    this.processConfiguration.contactPersonPurposes =
      event.detail.parsedObject ?? [];
    this.handleProcessConfigurationChanged();
  }

  protected async handleTestMissedAppointmentButtonClick(): Promise<void> {
    if (this.processConfiguration?.missedAppointmentContactUserId) {
      const response =
        await this.socketService.sendMissedAppointmentsNotification({
          userId: this.processConfiguration.missedAppointmentContactUserId
        });
      if (response.emailSent) {
        Dialogs.successDialogTk(
          'operations.editProcessConfigurationDialog.missedAppointments.dialogs.emailSent.title',
          'operations.editProcessConfigurationDialog.missedAppointments.dialogs.emailSent.text'
        );
      } else {
        Dialogs.warningDialogTk(
          'operations.editProcessConfigurationDialog.missedAppointments.dialogs.emailNotSent.title',
          'operations.editProcessConfigurationDialog.missedAppointments.dialogs.emailNotSent.text'
        );
      }
    }
  }

  private getUserGroupNameById(userGroupId: string): string {
    const userGroup =
      this.entityManager.userGroupRepository.getById(userGroupId);
    return userGroup
      ? userGroup.name || ''
      : this.i18n.tr('modelsDetail.UserGroupModel.userGroupNotFound');
  }

  private formatToDateString(date: MomentInput): string {
    return DateUtils.formatToDateString(date);
  }

  @computedFrom()
  protected get allProcessAppointmentTabs(): Array<ProcessAppointmentTabsKeys> {
    return allProcessAppointmentTabs();
  }

  /**
   * returns all process appointment tabs that can be selected as an option for "auto-expand".
   */
  @computedFrom()
  protected get allExpandableProcessAppointmentTabs(): Array<ProcessAppointmentTabsKeys> {
    return allExpandableProcessAppointmentTabs();
  }

  @computedFrom()
  protected get allProcessTaskFeatures(): Array<ShowProcessTaskFeaturesKeys> {
    return allProcessTaskFeatures();
  }

  @computedFrom()
  protected get allProcessTaskInfoSections(): Array<ShowProcessTaskInfoSectionsKeys> {
    return allProcessTaskInfoSections();
  }
}

type OnDialogClosedCallback = (configuration: ProcessConfiguration) => void;

export interface IOpenOptions {
  processConfiguration: ProcessConfiguration;
  onDialogClosed?: OnDialogClosedCallback | null;
}
