import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GeneralFileUploadService } from '../../classes/EntityManager/entities/GeneralFile/GeneralFileUploadService';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskChecklistEntry } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { Utils } from '../../classes/Utils/Utils';
import { ProcessTaskLoggingService } from '../ProcessTaskLoggingService';

@autoinject()
export class ProcessTaskChecklistService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly currentUserService: CurrentUserService,
    private readonly generalFileUploadService: GeneralFileUploadService
  ) {}

  public async createChecklistEntryForProcessTask({
    processTask,
    text,
    attachments
  }: {
    processTask: ProcessTask;
    text: string | null;
    attachments?: Array<ChecklistEntryAttachments>;
  }): Promise<ProcessTaskChecklistEntry> {
    const currentUser = this.currentUserService.getRequiredCurrentUser();

    const checklistEntry =
      this.entityManager.processTaskChecklistEntryRepository.create({
        userId: currentUser.id,
        createdDate: new Date().toISOString(),
        text: text,
        ownerProcessTaskGroupId: processTask.ownerProcessTaskGroupId,
        ownerProcessTaskId: processTask.id,
        ownerUserGroupId: processTask.ownerUserGroupId
      });

    if (attachments) {
      this.createFiles({
        checklistEntry,
        attachments
      });
    }

    await this.processTaskLoggingService.logCheckListEntryCreated({
      processTask,
      checklistEntry,
      attachmentNames:
        attachments?.map((attachment) => attachment.name ?? '') ?? []
    });

    return checklistEntry;
  }

  public async setChecklistEntryDone({
    processTask,
    checklistEntry,
    done
  }: {
    processTask: ProcessTask;
    checklistEntry: ProcessTaskChecklistEntry;
    done: boolean;
  }): Promise<void> {
    checklistEntry.done = done;
    this.entityManager.processTaskChecklistEntryRepository.update(
      checklistEntry
    );

    await this.processTaskLoggingService.logCheckListEntryDoneChanged(
      processTask,
      checklistEntry
    );
  }

  public async setChecklistEntryText({
    processTask,
    checklistEntry,
    text
  }: {
    processTask: ProcessTask;
    checklistEntry: ProcessTaskChecklistEntry;
    text: string | null;
  }): Promise<void> {
    const oldText = checklistEntry.text;

    checklistEntry.text = text;
    this.entityManager.processTaskChecklistEntryRepository.update(
      checklistEntry
    );

    await this.processTaskLoggingService.logCheckListEntryTextChanged(
      processTask,
      checklistEntry,
      oldText
    );
  }

  private createFiles({
    checklistEntry,
    attachments
  }: {
    checklistEntry: ProcessTaskChecklistEntry;
    attachments: Array<ChecklistEntryAttachments>;
  }): void {
    for (const attachment of attachments) {
      const { name, extension } = Utils.getFilePathComponents(
        attachment.name ?? ''
      );

      const generalFile = this.entityManager.generalFileRepository.create({
        name,
        extension,
        processTaskChecklistEntryId: checklistEntry.id,
        ownerProcessTaskGroupId: checklistEntry.ownerProcessTaskGroupId,
        ownerProcessTaskId: checklistEntry.ownerProcessTaskId,
        ownerUserGroupId: checklistEntry.ownerUserGroupId,
        temporaryGroupName: checklistEntry.temporaryGroupName
      });

      this.generalFileUploadService.uploadGeneralFile(
        generalFile,
        attachment.dataUrl,
        extension || ''
      );
    }
  }
}

export type ChecklistEntryAttachments = {
  name: string | null;
  dataUrl: string;
};
