import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStepAutoAppointment } from '../../../classes/EntityManager/entities/ProcessConfigurationStepAutoAppointment/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationStepAutoAppointmentsWidgetListItem {
  @bindable()
  public processConfigurationStepAutoAppointment: ProcessConfigurationStepAutoAppointment | null =
    null;

  @subscribableLifecycle()
  protected readonly processConfigurationStepAutoAppointmentPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfigurationStepAutoAppointment];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationStepAutoAppointmentPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfigurationStepAutoAppointment,
        context: this as ProcessConfigurationStepAutoAppointmentsWidgetListItem,
        propertyName: 'processConfigurationStepAutoAppointment'
      });
  }

  protected handleAutoAppointmentChanged(): void {
    assertNotNullOrUndefined(
      this.processConfigurationStepAutoAppointment,
      "can't ProcessConfigurationStepAutoAppointmentsWidgetListItem.handleAutoAppointmentChanged without processConfigurationStepAutoAppointment"
    );

    this.entityManager.processConfigurationStepAutoAppointmentRepository.update(
      this.processConfigurationStepAutoAppointment
    );
  }

  protected handleDeleteAutoAppointmentClick(): void {
    const processConfigurationStepAutoAppointment =
      this.processConfigurationStepAutoAppointment;
    assertNotNullOrUndefined(
      processConfigurationStepAutoAppointment,
      "can't ProcessConfigurationStepAutoAppointmentsWidgetListItem.handleDeleteAutoAppointmentClick without processConfigurationStepAutoAppointment"
    );

    void Dialogs.deleteEntityDialog(
      processConfigurationStepAutoAppointment
    ).then(() => {
      this.entityManager.processConfigurationStepAutoAppointmentRepository.delete(
        processConfigurationStepAutoAppointment
      );
    });
  }
}
