import { ProjectType } from 'common/Types/Entities/Project/ProjectDto';
import {
  LevelConfiguration,
  StructureTemplateType
} from 'common/Types/Entities/StructureTemplate/StructureTemplateDto';
import { StructureTemplate } from './types';

export class StructureTemplateUtils {
  private constructor() {}

  public static getStructureTemplateTypeFromProjectType(
    projectType: ProjectType
  ): StructureTemplateType {
    if (!Object.values(StructureTemplateType).includes(projectType as any)) {
      throw new Error(
        `project type '${projectType}' is not a valid structure template type`
      );
    }
    return projectType as unknown as StructureTemplateType;
  }

  /**
   * Allows editing of the levelConfigurations of a StructureTemplate as if they were a map.
   *
   * @param configurations a levelConfigurations array.
   * @param cb a callback that will be synchronously called with the levelConfigurations as a map.
   * @returns the levelConfigurations array as edited by the `cb`.
   *
   * @example
   * // Set `ratingEnabled` on level `1` to `false`.
   * structureTemplate.levelConfigurations = StructureTemplateUtils.editLevelConfigurationsAsMap(
   *   structureTemplate.levelConfigurations,
   *   (map) => map.set(1, { ...map.get(1), ratingEnabled: false })
   * );
   */
  public static editLevelConfigurationsAsMap(
    configurations: Array<LevelConfiguration>,
    cb: (map: LevelConfigurationMap) => LevelConfigurationMap
  ): Array<LevelConfiguration> {
    let map = StructureTemplateUtils.levelConfigurationToMap(configurations);
    map = cb(map);
    return StructureTemplateUtils.mapToLevelConfiguration(map);
  }

  public static isRatingEnabledOnLevel(
    structureTemplate: StructureTemplate | null,
    level: number
  ): boolean {
    if (!structureTemplate || !structureTemplate.levelConfigurations)
      return false;
    const map = this.levelConfigurationToMap(
      structureTemplate.levelConfigurations
    );
    return map.get(level)?.ratingEnabled ?? false;
  }

  /**
   * Get a more accessible map from an array of level configurations.
   */
  private static levelConfigurationToMap(
    configurations: Array<LevelConfiguration>
  ): LevelConfigurationMap {
    const map: LevelConfigurationMap = new Map();
    for (const config of configurations) {
      map.set(config.level, { ...config });
    }
    return map;
  }

  /**
   * Parse a map of level configurations back to the array that can be stored in a StructureTemplate.
   */
  private static mapToLevelConfiguration(
    map: LevelConfigurationMap
  ): Array<LevelConfiguration> {
    const levelConfiguration: Array<LevelConfiguration> = [];
    for (const [key, value] of map.entries()) {
      levelConfiguration.push({ level: key, ...value });
    }
    return levelConfiguration;
  }
}

/**
 * A more accessible data structure for storing data about levelConfigurations.
 */
type LevelConfigurationMap = Map<number, Omit<LevelConfiguration, 'level'>>;
