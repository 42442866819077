import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { DateUtils } from 'common/DateUtils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { DefectChangeLogEntry } from '../../classes/EntityManager/entities/DefectChangeLogEntry/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { GroupedDefectChangeLogEntries } from '../defect-entry-group/defect-entry-group';

@autoinject()
export class DefectChangeLogEntryGroup {
  @bindable
  public defectChangeLogEntryGroup: GroupedDefectChangeLogEntries | null = null;

  protected defectChangeLogEntryUser: User | null = null;

  private subManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService
  ) {
    this.subManager = subManagerService.create();
  }

  // ////////// LIFECYCLE //////////

  protected attached(): void {
    this.subManager.subscribeToModelChanges(
      EntityName.User,
      this.updateDefectChangeLogEntryUser.bind(this)
    );
  }

  protected detached(): void {
    this.subManager.disposeSubscriptions();
  }

  // ////////// OBSERVABLES //////////

  protected defectChangeLogEntryGroupChanged(): void {
    this.updateDefectChangeLogEntryUser();
  }

  // ////////// UPDATERS //////////

  protected updateDefectChangeLogEntryUser(): void {
    if (!this.defectChangeLogEntryGroup) {
      this.defectChangeLogEntryUser = null;
      return;
    }

    const userId = this.defectChangeLogEntryGroup.senderId;
    this.defectChangeLogEntryUser =
      this.entityManager.userRepository.getById(userId);
  }

  // ////////// HTML GETTERS //////////

  @computedFrom('defectChangeLogEntryGroup')
  protected get prettyChangeLogEntryDate(): string {
    if (!this.defectChangeLogEntryGroup) return '...';
    return DateUtils.formatToDateString(this.defectChangeLogEntryGroup.date);
  }

  // ////////// HTML HELPERS //////////

  protected changeLogEntryTranslationKey(
    entry: DefectChangeLogEntry
  ): string | null {
    return `modelsDetail.DefectChangeLogEntryModel.logAction--${entry.logAction}`;
  }

  protected changeLogEntryTranslationData(
    entry: DefectChangeLogEntry
  ): object | null {
    const group = this.defectChangeLogEntryGroup;
    const user = this.defectChangeLogEntryUser;
    if (!group || !user) return null;
    return {
      username: user.username,
      ...JSON.parse(entry.logDataJson ?? '{}')
    };
  }
}
