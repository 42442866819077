import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { Person } from './types';

export class PersonRepository extends AppEntityRepository<EntityName.Person> {
  public getByCategoryName(categoryName: string): Array<Person> {
    return this.getAll().filter((person) => {
      return person.categoryName === categoryName;
    });
  }

  public getByUserGroupId(userGroupId: string): Array<Person> {
    return this.getAll().filter((person) => {
      return person.ownerUserGroupId === userGroupId;
    });
  }
}
