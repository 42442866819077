import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../AppEntityManager';
import { ApplyPropertiesService } from '../Property/ApplyPropertiesService';
import { ReportType } from '../ReportType/types';
import { Project } from './types';

@autoinject()
export class AssignReportTypeToProjectService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly applyPropertiesService: ApplyPropertiesService
  ) {}

  public assignReportTypeToProject(
    project: Project,
    reportType: ReportType | null
  ): void {
    this.applyPropertiesService.applyRawPropertiesToProject(
      project,
      reportType?.tags ?? []
    );

    const notes =
      reportType?.notes.map((note) => {
        return {
          tag: note[0] ?? '',
          value: note[1] ?? ''
        };
      }) ?? [];

    project.importnotes = notes;
    project.customTagsInEntries = reportType?.custom_tags_in_entries ?? [];
    project.report_type = reportType?.id ?? null;

    this.entityManager.projectRepository.update(project);
  }
}
