import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { PropertyType } from 'common/Types/Entities/Property/PropertyDto';
import { CsvImportUtils } from 'common/Utils/CsvImportUtils';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { Property } from '../EntityManager/entities/Property/types';

export function createPropertyImportHandlersByType({
  entityManager
}: {
  entityManager: AppEntityManager;
}): PropertyImportHandlersByType {
  const doNothingHandler = (): void => {};

  const notSupportedHandler = ({
    property
  }: PropertyImportHandlerOptions): void => {
    console.warn(
      `Property of type "${PropertyHelper.getTypeOrDefault(
        property.type
      )}" can't be imported since it isn't supported`
    );
  };

  const updateProperty = ({
    property,
    newValue,
    newCustomChoice
  }: {
    property: Property;
    newValue: string | null;
    newCustomChoice: string | null;
  }): void => {
    if (
      property.value === newValue &&
      property.custom_choice === newCustomChoice
    ) {
      return;
    }

    property.value = newValue;
    property.custom_choice = newCustomChoice;
    entityManager.propertyRepository.update(property);
  };

  return {
    [PropertyType.BERICHTPARAMETER]: doNothingHandler,
    [PropertyType.CHECKBOX]: notSupportedHandler,
    [PropertyType.COORDINATE]: notSupportedHandler,
    [PropertyType.DATE]: notSupportedHandler,
    [PropertyType.DROPDOWN]: ({ property, importValue }) => {
      let newValue: string | null = null;
      let newCustomChoice: string | null = null;

      if (importValue != null) {
        const stringValue = importValue.toString();

        if (property.choices.includes(stringValue)) {
          newValue = stringValue;
        } else {
          newCustomChoice = stringValue;
        }
      }

      updateProperty({
        property,
        newValue,
        newCustomChoice
      });
    },
    [PropertyType.HEADING]: doNothingHandler,
    [PropertyType.INVISIBLE_TEXT]: doNothingHandler,
    [PropertyType.MULTILINE]: notSupportedHandler,
    [PropertyType.MULTI_DROPDOWN]: notSupportedHandler,
    [PropertyType.NUMBER]: ({ property, importValue, decimalSeparator }) => {
      const newValue =
        CsvImportUtils.parseNumericInput(
          importValue,
          decimalSeparator
        )?.toString() ?? null;
      updateProperty({
        property,
        newValue,
        newCustomChoice: null
      });
    },
    [PropertyType.PERSON]: notSupportedHandler,
    [PropertyType.PICTURE]: notSupportedHandler,
    [PropertyType.POSITION]: notSupportedHandler,
    [PropertyType.PRIORITY]: notSupportedHandler,
    [PropertyType.RADIOBUTTON]: ({ property, importValue }) => {
      let newValue: string | null = null;

      if (importValue != null) {
        const stringValue = importValue.toString();

        if (property.choices.includes(stringValue)) {
          newValue = stringValue;
        }
      }

      updateProperty({
        property,
        newValue,
        newCustomChoice: null
      });
    },
    [PropertyType.SIGNATURE]: notSupportedHandler,
    [PropertyType.TABLE]: notSupportedHandler,
    [PropertyType.TEXT]: ({ property, importValue }) => {
      let newValue: string | null = null;
      if (importValue != null) {
        newValue = importValue.toString();
      }

      updateProperty({
        property,
        newValue,
        newCustomChoice: null
      });
    },
    [PropertyType.TIME]: notSupportedHandler,
    [PropertyType.VISIBLE_TEXT]: doNothingHandler
  };
}

export type PropertyImportHandler = (
  options: PropertyImportHandlerOptions
) => void;

export type PropertyImportHandlerOptions = {
  property: Property;
  importValue: string | number | null;
  decimalSeparator: string;
};

export type PropertyImportHandlersByType = Record<
  PropertyType,
  PropertyImportHandler
>;
