import { AppEntityManager } from '../../EntityManager/entities/AppEntityManager';
import { EntityName } from '../../EntityManager/entities/types';
import { Disposable } from '../DisposableContainer';
import { IUtilsRateLimitedFunction, Utils } from '../Utils';

export class SubscriptionUtils {
  private constructor() {}

  public static subscribeToModelChanges({
    entityManager,
    entityName,
    callback,
    listenToVisibilityChanges,
    rateInterval = 250
  }: {
    entityManager: AppEntityManager;
    entityName: EntityName;
    callback: () => void;
    listenToVisibilityChanges: boolean;
    rateInterval?: number;
  }): Disposable & {
    rateLimitedCallback: IUtilsRateLimitedFunction<() => void>;
  } {
    return this.subscribeToMultipleModelChanges({
      entityManager,
      entityNames: [entityName],
      callback,
      listenToVisibilityChanges,
      rateInterval
    });
  }

  /**
   * will call the callback anytime when one entity of `entityName` got changed
   * be careful: you will miss some events because there is a rateInterval
   * if you need to receive all events you have to unset the rate interval
   */
  public static subscribeToMultipleModelChanges({
    entityManager,
    entityNames,
    callback,
    listenToVisibilityChanges,
    rateInterval = 250
  }: {
    entityManager: AppEntityManager;
    entityNames: Array<EntityName>;
    callback: () => void;
    listenToVisibilityChanges: boolean;
    rateInterval?: number;
  }): Disposable & {
    rateLimitedCallback: IUtilsRateLimitedFunction<() => void>;
  } {
    const rateLimitedCallback = this.createRateLimitedCallback({
      callback: () => {
        callback(); // the callback is wrapped in another callback, so all arguments are stripped;
      },
      rateInterval
    });

    const disposables = entityNames.map((entityName) => {
      const repository =
        entityManager.entityRepositoryContainer.getByEntityName(entityName);

      return repository.registerHooks({
        afterEntityCreated: rateLimitedCallback,
        afterEntityDeleted: rateLimitedCallback,
        afterEntityRemovedLocally: rateLimitedCallback,
        afterEntityUpdated: rateLimitedCallback,
        afterEntityUpdatedLocally: rateLimitedCallback,
        ...(listenToVisibilityChanges
          ? {
              afterEntityVisibilityChanged: rateLimitedCallback
            }
          : {})
      });
    });

    return {
      rateLimitedCallback,
      dispose: () => {
        for (const disposable of disposables) {
          disposable.dispose();
        }
      }
    };
  }

  public static createRateLimitedCallback({
    callback,
    rateInterval
  }: {
    callback: () => void;
    rateInterval: number | undefined;
  }): IUtilsRateLimitedFunction<() => void> {
    if (rateInterval != null && rateInterval > 0) {
      return Utils.rateLimitFunction(callback, rateInterval);
    }

    return this.createRateLimitedFunctionWithoutRateLimit({
      callback
    });
  }

  /**
   * this function creates a rate limited callback without a rate limit, so it can be treated the same as callbacks with a rate limit
   */
  private static createRateLimitedFunctionWithoutRateLimit<
    TCallback extends (...args: Array<any>) => void
  >({
    callback
  }: {
    callback: TCallback;
  }): IUtilsRateLimitedFunction<TCallback> {
    const rateLimited = function (...args: Array<any>): void {
      callback(...args);
    };

    rateLimited.cancel = () => {};

    rateLimited.isPending = () => {
      return false;
    };

    rateLimited.callImmediatelyIfPending = () => {};

    rateLimited.toCancelDisposable = () => {
      return {
        dispose: () => {}
      };
    };

    rateLimited.toCallImmediatelyIfPendingDisposable = () => {
      return {
        dispose: () => {}
      };
    };

    rateLimited._callCallback = () => {
      throw new Error('not implemented');
    };

    rateLimited._timeoutInfo =
      null as IUtilsRateLimitedFunction<TCallback>['_timeoutInfo'];

    rateLimited._lastArguments =
      null as IUtilsRateLimitedFunction<TCallback>['_lastArguments'];

    return rateLimited;
  }
}
