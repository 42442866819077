import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createQuestionCategoryEntityInfo } from 'common/Types/Entities/QuestionCategory/createQuestionCategoryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { QuestionCategory } from './types';

export const questionCategoryEntityInfo =
  createQuestionCategoryEntityInfo<QuestionCategory>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
