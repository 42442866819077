import { computedFrom } from 'aurelia-framework';

import { TDefaultPropertyConfig } from 'common/Types/DefaultPropertyConfig';
import { ProcessConfigurationConfigPositionConfiguration } from 'common/Types/ProcessConfigurationConfigPositionConfiguration';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationStepPosition } from '../../../classes/EntityManager/entities/ProcessConfigurationStepPosition/types';
import { ProcessConfigurationStepPositionProperty } from '../../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { PropertyAdapter, SubscribeOptions } from './PropertyAdapter';
import { PropertyAdapterUtils } from './PropertyAdapterUtils';

export class ProcessConfigurationStepPositionPropertyAdapter
  implements
    PropertyAdapter<
      ProcessConfigurationStepPositionProperty,
      TDefaultPropertyConfig
    >
{
  private readonly entityManager: AppEntityManager;
  private readonly permissionsService: PermissionsService;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly processConfigurationStepPosition: ProcessConfigurationStepPosition;

  constructor(options: ProcessConfigurationStepPositionPropertyAdapterOptions) {
    this.entityManager = options.entityManager;
    this.permissionsService = options.permissionsService;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.processConfigurationStepPosition =
      options.processConfigurationStepPosition;
  }

  public subscribe(
    options: SubscribeOptions<
      ProcessConfigurationStepPositionProperty,
      TDefaultPropertyConfig
    >
  ): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updateProperties = (): void => {
      options.setProperties(
        this.entityManager.propertyRepository.getByProcessConfigurationStepPositionId(
          this.processConfigurationStepPosition.id
        )
      );
    };
    subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      updateProperties.bind(this)
    );
    updateProperties();

    const updateConfigs = (): void => {
      options.setConfigs(this.getConfigs());
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      updateConfigs.bind(this)
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      updateConfigs.bind(this)
    );
    updateConfigs();

    PropertyAdapterUtils.setupCanEditPropertiesSubscription({
      entityName: EntityName.ProcessConfigurationStepPosition,
      entity: this.processConfigurationStepPosition,
      subscriptionManager,
      permissionsService: this.permissionsService,
      setCanUpdateProperties: options.setCanUpdateProperties
    });

    return subscriptionManager.toDisposable();
  }

  public createPropertyFromConfig(
    config: TDefaultPropertyConfig
  ): ProcessConfigurationStepPositionProperty {
    return this.entityManager.propertyRepository.create({
      ...config,
      processConfigurationStepPositionId:
        this.processConfigurationStepPosition.id,
      ownerUserGroupId: this.processConfigurationStepPosition.ownerUserGroupId,
      ownerProcessConfigurationId:
        this.processConfigurationStepPosition.ownerProcessConfigurationId
    }) as ProcessConfigurationStepPositionProperty;
  }

  private getConfigs(): Array<TDefaultPropertyConfig> {
    const processConfiguration = this.processConfigurationStepPosition
      ? this.entityManager.processConfigurationRepository.getById(
          this.processConfigurationStepPosition.ownerProcessConfigurationId
        )
      : null;
    const config: ProcessConfigurationConfigPositionConfiguration | null =
      processConfiguration &&
      processConfiguration.configPositionConfigurationJson
        ? JSON.parse(processConfiguration.configPositionConfigurationJson)
        : null;

    return config ? config.defaultProperties : [];
  }

  @computedFrom()
  public get updateButtonTk(): string {
    return 'aureliaComponents.propertyInputFieldListWithDefaultProperties.PropertyAdapter.PropertyAdapter.updatePropertiesButton';
  }
}

export type ProcessConfigurationStepPositionPropertyAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  permissionsService: PermissionsService;
  processConfigurationStepPosition: ProcessConfigurationStepPosition;
};
