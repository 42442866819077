import { autoinject } from 'aurelia-dependency-injection';
import { Utils } from '../classes/Utils/Utils';

@autoinject()
export class NoZoomBoxDraggingCustomAttribute {
  private static registeredElements: Set<Element> = new Set();

  public static isInsideNoDraggingElement(element: Element): boolean {
    let isInside = false;

    Utils.walkThroughParentElements(element, (currentElement) => {
      if (this.registeredElements.has(currentElement)) {
        isInside = true;
        return false;
      }

      return true;
    });

    return isInside;
  }

  constructor(private readonly element: Element) {}

  protected attached(): void {
    NoZoomBoxDraggingCustomAttribute.registeredElements.add(this.element);
  }

  protected detached(): void {
    NoZoomBoxDraggingCustomAttribute.registeredElements.delete(this.element);
  }
}
