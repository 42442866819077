import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskOfferToProcessTaskPosition } from '../../../classes/EntityManager/entities/ProcessTaskOfferToProcessTaskPosition/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ProcessTaskOfferToProcessTaskPositionMapComputer extends ValueComputer<
  ProcessTaskOfferToProcessTaskPositionMapComputerComputeData,
  ProcessTaskOfferToProcessTaskPositionMapComputerComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTaskPosition,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskOfferToProcessTask,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskOfferToProcessTaskPositionMapComputerComputeResult {
    return {
      activeProcessTaskOfferToProcessTaskPositionByPositionId:
        this.getActiveOfferToPositionByPositionId()
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getActiveOfferToPositionByPositionId(): ProcessTaskOfferToProcessTaskPositionByPositionId {
    const offerIdToProcessTaskIds = this.getOfferIdToProcessTaskIds();
    const offerToPositions =
      this.entityManager.processTaskOfferToProcessTaskPositionRepository.getAll();
    const activeOfferToPositionByPositionId: ProcessTaskOfferToProcessTaskPositionByPositionId =
      new Map();

    for (const offerToPosition of offerToPositions) {
      if (
        !offerIdToProcessTaskIds
          .get(offerToPosition.processTaskOfferId)
          ?.has(offerToPosition.ownerProcessTaskId)
      ) {
        continue;
      }

      const relations = activeOfferToPositionByPositionId.get(
        offerToPosition.processTaskPositionId
      );
      if (relations) {
        relations.push(offerToPosition);
      } else {
        activeOfferToPositionByPositionId.set(
          offerToPosition.processTaskPositionId,
          [offerToPosition]
        );
      }
    }

    return activeOfferToPositionByPositionId;
  }

  private getOfferIdToProcessTaskIds(): Map<string, Set<string>> {
    const offerIdToProcessTaskIds = new Map<string, Set<string>>();

    const relations =
      this.entityManager.processTaskOfferToProcessTaskRepository.getAll();
    for (const relation of relations) {
      const processTaskIds = offerIdToProcessTaskIds.get(
        relation.processTaskOfferId
      );
      if (processTaskIds) {
        processTaskIds.add(relation.ownerProcessTaskId);
      } else {
        offerIdToProcessTaskIds.set(
          relation.processTaskOfferId,
          new Set([relation.ownerProcessTaskId])
        );
      }
    }

    return offerIdToProcessTaskIds;
  }
}

export type ProcessTaskOfferToProcessTaskPositionMapComputerComputeData = {};

export type ProcessTaskOfferToProcessTaskPositionMapComputerComputeResult = {
  activeProcessTaskOfferToProcessTaskPositionByPositionId: ProcessTaskOfferToProcessTaskPositionByPositionId;
};

export type ProcessTaskOfferToProcessTaskPositionByPositionId = Map<
  string,
  Array<ProcessTaskOfferToProcessTaskPosition>
>;
