import { autoinject, observable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  ProcessTaskToProjectRelationInfo,
  ProcessTaskToProjectRelationInfoRequestType
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';

import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { configureHooks } from '../../hooks/configureHooks';
import {
  CurrentPageIndexChangedEvent,
  Pagination
} from '../../aureliaComponents/pagination/pagination';
import { IUtilsDebouncedFunction, Utils } from '../../classes/Utils/Utils';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { AbstractProcessTaskToProjectCrudStrategy } from './strategies/AbstractProcessTaskToProjectCrudStrategy';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ProcessTaskToProjectEntityIds,
  ProcessTaskToProjectRelationInfoListItem
} from '../../operationsComponents/process-task-to-project-relation-info-list-item/process-task-to-project-relation-info-list-item';

@autoinject()
@configureHooks({ mount: 'open', unmount: 'handleDialogClosed' })
export class ManageProcessTaskToProjectRelationsDialog<
  TTargetEntityType extends Project | ProcessTask
> {
  protected dialog: RecordItDialog | null = null;

  private updateProcessTaskToProjectRelationInfos: UpdateProcessTaskToProjectRelationInfosCallback | null =
    null;

  protected availableProcessTaskToProjectRelationInfos: Array<ProcessTaskToProjectRelationInfo> =
    [];

  protected pagination: Pagination<ProcessTaskToProjectRelationInfoListItem> | null =
    null;

  @observable private filterString: string = '';
  private includeArchivedEntities: boolean = false;
  protected entityName: string | null = null;

  protected maxPageIndex = 1;
  @observable protected currentPageSize = 10;
  @observable protected currentPageIndex = 1;

  private activeTargetEntity: TTargetEntityType | null = null;

  private showLoadingAnimation = false;

  private updateProcessTaskToProjectRelationInfosDebounced: IUtilsDebouncedFunction;

  private crudStrategy: AbstractProcessTaskToProjectCrudStrategy | null = null;
  private subscriptionManager: SubscriptionManager;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.updateProcessTaskToProjectRelationInfosDebounced =
      Utils.debounceFunction(() => {
        void this.updateAvailableProcessTaskToProjectRelationInfos({
          showOverlayForLongResponseTimes: false,
          showOverlayImmediately: true
        });
      }, 500);
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public open(
    options: manageProcessTaskToProjectRelationsDialogOptions<TTargetEntityType>
  ): void {
    this.entityName = options.entityName;
    this.crudStrategy = options.crudStrategy;
    this.crudStrategy.setupSubscriptions(
      this.subscriptionManager,
      this.updateAvailableProcessTaskToProjectRelationInfos.bind(this, {
        showOverlayForLongResponseTimes: false,
        showOverlayImmediately: true
      })
    );

    this.activeTargetEntity = options.activeTargetEntity;
    this.updateProcessTaskToProjectRelationInfos =
      options.updateProcessTaskToProjectRelationInfos;

    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
    this.showLoadingAnimation = false;
    this.pagination?.goToFirstPage();
    this.dialog?.open();
    this.currentPageIndex = 1;
  }

  public static async open<T extends Project | ProcessTask>(
    options: manageProcessTaskToProjectRelationsDialogOptions<T>
  ): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  protected async updateAvailableProcessTaskToProjectRelationInfos(
    config: UpdateSettings
  ): Promise<void> {
    if (!this.crudStrategy || !this.activeTargetEntity) return;

    await this.crudStrategy.updateProcessTaskToProjectsAndCandidates(
      {
        activeTargetEntityId: this.activeTargetEntity.id,
        paginationInfo: {
          currentPageNumber: this.currentPageIndex,
          currentPageSize: this.currentPageSize
        },
        processTaskToProjectType:
          ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
        type: ProcessTaskToProjectRelationInfoRequestType.ALL,

        filterString: this.filterString,
        includeArchivedEntities: this.includeArchivedEntities
      },
      {
        setOverlayStatus: (status: boolean) => {
          this.showLoadingAnimation = status;
        },
        setRelationsToDisplay: (
          infos: Array<ProcessTaskToProjectRelationInfo>
        ) => {
          const oldRelationInfos =
            this.availableProcessTaskToProjectRelationInfos;
          this.availableProcessTaskToProjectRelationInfos =
            InstancePreserver.createNewArray({
              originalArray: oldRelationInfos,
              newArray: infos,
              getTrackingValue: (item) =>
                item.processTaskId ?? '' + (item.projectId ?? '')
            });
        },
        setTotalRelationsCount: (count: number) => {
          this.updateMaxPageIndex(count);
        },
        config
      }
    );
  }

  protected handleDialogClosed(): void {
    this.resetFilters();
    this.crudStrategy = null;
    this.activeTargetEntity = null;
    this.includeArchivedEntities = false;
    this.entityName = null;
    this.updateProcessTaskToProjectRelationInfos = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected currentPageSizeChanged(): void {
    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected currentPageIndexChanged(): void {
    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
  }

  protected filterStringChanged(): void {
    this.resetPaginationAndStartNewSearch();
  }

  protected handleIncludeArchivedCheckedChange(): void {
    this.resetPaginationAndStartNewSearch();
  }

  protected handleCurrentPageIndexChanged(
    event: CurrentPageIndexChangedEvent
  ): void {
    this.currentPageIndex = event.detail.page;
  }

  protected updateProcessTaskToProjectRelationInfosOnPageAndInDialog(): void {
    void this.updateAvailableProcessTaskToProjectRelationInfos({
      showOverlayForLongResponseTimes: false,
      showOverlayImmediately: true
    });
    this.updateProcessTaskToProjectRelationInfos?.();
  }

  private updateMaxPageIndex(resultCount: number): void {
    this.maxPageIndex = Math.ceil(resultCount / this.currentPageSize) || 1;
  }

  private resetFilters(): void {
    this.filterString = '';
  }

  private resetPaginationAndStartNewSearch(): void {
    this.pagination?.goToFirstPage();
    if (this.updateProcessTaskToProjectRelationInfosDebounced)
      this.updateProcessTaskToProjectRelationInfosDebounced();
  }

  protected getEntityIds(
    relationInfo: ProcessTaskToProjectRelationInfo
  ): ProcessTaskToProjectEntityIds {
    assertNotNullOrUndefined(
      this.activeTargetEntity,
      'cannot getEntityIds without activeTargetEntity'
    );
    if (relationInfo.processTaskId) {
      return {
        processTaskId: relationInfo.processTaskId,
        projectId: this.activeTargetEntity.id
      };
    } else if (relationInfo.projectId) {
      return {
        processTaskId: this.activeTargetEntity.id,
        projectId: relationInfo.projectId
      };
    } else {
      throw new Error(
        'illegal combination of process task to project entity ids'
      );
    }
  }
}

type manageProcessTaskToProjectRelationsDialogOptions<
  TTargetEntityType extends Project | ProcessTask
> = {
  entityName: string | null;
  activeTargetEntity: TTargetEntityType;
  crudStrategy: AbstractProcessTaskToProjectCrudStrategy;
  updateProcessTaskToProjectRelationInfos: UpdateProcessTaskToProjectRelationInfosCallback | null;
};

type UpdateProcessTaskToProjectRelationInfosCallback = () => void;

type UpdateSettings = {
  showOverlayImmediately: boolean;
  showOverlayForLongResponseTimes: boolean;
};
