import { autoinject, bindable, containerless } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessTask } from '../../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskAppointment } from '../../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessTaskGroup } from '../../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { ProcessConfigurationFromProcessTaskGroupIdComputer } from '../../../computedValues/computers/ProcessConfigurationFromProcessTaskGroupIdComputer';
import { EditThingMainContactBankAccountDataDialog } from '../../../dialogs/edit-thing-main-contact-bank-account-data-dialog/edit-thing-main-contact-bank-account-data-dialog';
import { ProcessTaskDeviceStatusIcon } from '../../../operationsComponents/process-task-device-status-icon/process-task-device-status-icon';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@containerless()
@autoinject()
export class ShowProcessAppointmentDevices {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable()
  public expanded: boolean = false;

  private subscriptionManager: SubscriptionManager;

  private processConfiguration: ProcessConfiguration | null = null;
  private bankAccountDataAvailable: boolean = false;
  private appointmentDevicesCount: number | null = null;

  private ProcessTaskDeviceStatusIcon = ProcessTaskDeviceStatusIcon;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass: ProcessConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTask
            ? { processTaskGroupId: this.processTask.ownerProcessTaskGroupId }
            : null,
        createUpdaters: (updateSubscription) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTask.ownerProcessTaskGroupId',
            updateSubscription
          );
        },
        callback: (processConfiguration) => {
          this.processConfiguration = processConfiguration;
        }
      })
    );

    this.subscriptionManager.subscribeToExpression(
      this,
      'processTask.thingId',
      this.updateBankAccountDataAvailable.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this.updateBankAccountDataAvailable.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingToPerson,
      this.updateBankAccountDataAvailable.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Person,
      this.updateBankAccountDataAvailable.bind(this)
    );
    this.updateBankAccountDataAvailable();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateBankAccountDataAvailable(): void {
    if (this.processTask) {
      this.bankAccountDataAvailable = this.getBankAccountDataAvailable(
        this.processTask
      );
    } else {
      this.bankAccountDataAvailable = false;
    }
  }

  private getBankAccountDataAvailable(processTask: ProcessTask): boolean {
    const mainContact =
      this.entityManager.thingToPersonRepository.getMainContactOrFallbackForThingId(
        processTask.thingId
      );
    if (!mainContact) {
      return false;
    }

    const person = this.entityManager.personRepository.getById(
      mainContact.personId
    );
    if (!person) {
      return false;
    }

    return person.iban != null && person.iban !== '';
  }

  private handleEditBankAccountDataClick(): void {
    assertNotNullOrUndefined(
      this.processTask,
      "can't ShowProcessAppointmentDevices.handleEditBankAccountDataClick without a processTask"
    );
    void EditThingMainContactBankAccountDataDialog.open({
      thingId: this.processTask.thingId
    });
  }

  private handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }
}
