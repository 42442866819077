import { autoinject } from 'aurelia-dependency-injection';

import {
  GetProcessTaskGroupsFilterAddressFilterType,
  TProcessConfigurationActionStatusFilterType
} from 'common/EndpointTypes/OperationsEndpointsTypes';
import { DateUtils } from 'common/DateUtils';
import { ProcessTaskGroupFilterMode } from 'common/Enums/ProcessTaskGroupFilterMode';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { Utils } from '../classes/Utils/Utils';
import { DefaultProcessTaskGroupFilterBarFilterSettingsComputer } from '../computedValues/computers/DefaultProcessTaskGroupFilterBarFilterSettingsComputer/DefaultProcessTaskGroupFilterBarFilterSettingsComputer';
import { ComputedValueService } from '../computedValues/ComputedValueService';

@autoinject()
export class ProcessTaskGroupFilterSettingsService {
  private static readonly SAVE_DATA_KEY = 'processTaskGroupsFilterSaveData';

  private filterSettings: FilterSettings | null = null;

  private subscriberCallbacks: Map<any, ChangeCallback> = new Map();

  private saveFilterSettingsRateLimited = Utils.rateLimitFunction(
    this.saveFilterSettings.bind(this),
    250
  );

  constructor(private readonly computedValueService: ComputedValueService) {}

  public async init(): Promise<void> {
    await this.loadFilterSettings();
  }

  public destroy(): void {}

  public subscribeToChange(scope: any, callback: ChangeCallback): void {
    this.subscriberCallbacks.set(scope, callback);
    if (this.filterSettings) {
      callback(this.filterSettings);
    }
  }

  public unsubscribe(scope: any): void {
    this.subscriberCallbacks.delete(scope);
  }

  public modifyFilterSettings(filterSettings: Partial<FilterSettings>): void {
    this.filterSettings = {
      ...this.getFilterSettings(),
      ...filterSettings
    };

    this.callCallbacks(this.filterSettings);
    this.saveFilterSettingsRateLimited();
  }

  public getFilterSettings(): FilterSettings {
    assertNotNullOrUndefined(
      this.filterSettings,
      'no filterSettings available, is the ProcessTaskGroupFilterSettingsService initialized?'
    );

    return this.filterSettings;
  }

  public getFilterSettingsWithDates(): FilterSettingsWithDates {
    const filterSettings = this.getFilterSettings();

    switch (filterSettings.filterMode) {
      default:
        console.error(`unhandled filter mode ${filterSettings.filterMode}`);

      // eslint-disable-next-line no-fallthrough
      case ProcessTaskGroupFilterMode.NEXT_DAYS:
        const nextDaysFilterValue =
          filterSettings.nextDaysFilterValue ??
          DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE;
        return {
          ...filterSettings,
          dateFromIso:
            DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString(),
          dateToIso: DateUtils.getDateWithDayOffsetWithoutTime(
            nextDaysFilterValue + 1
          ).toISOString()
        };

      case ProcessTaskGroupFilterMode.DATE_RANGE:
        const dateRangeFilterDateFromIso =
          filterSettings.dateRangeFilterDateFromIso ??
          DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString();
        const dateRangeFilterDateToIso =
          filterSettings.dateRangeFilterDateToIso ??
          DateUtils.getDateWithDayOffsetWithoutTime(
            DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE +
              1
          ).toISOString();
        return {
          ...filterSettings,
          dateFromIso: dateRangeFilterDateFromIso,
          dateToIso: dateRangeFilterDateToIso
        };

      case ProcessTaskGroupFilterMode.ALL:
      case ProcessTaskGroupFilterMode.NO_FUTURE_APPOINTMENTS:
        return {
          ...filterSettings,
          dateFromIso:
            DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString(),
          dateToIso: DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString()
        };
    }
  }

  private callCallbacks(filterSettings: FilterSettings): void {
    this.subscriberCallbacks.forEach((cb) => cb(filterSettings));
  }

  private async saveFilterSettings(): Promise<void> {
    const filterSettings = this.getFilterSettings();

    const dateRangeFilterDateFromIso =
      filterSettings.dateRangeFilterDateFromIso !==
      DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString()
        ? filterSettings.dateRangeFilterDateFromIso
        : null;
    const dateRangeFilterDateToIso =
      filterSettings.dateRangeFilterDateToIso !==
      DateUtils.getDateWithDayOffsetWithoutTime(
        DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE +
          1
      ).toISOString()
        ? filterSettings.dateRangeFilterDateToIso
        : null;

    await DataStorageHelper.setItem(
      ProcessTaskGroupFilterSettingsService.SAVE_DATA_KEY,
      {
        ...this.filterSettings,
        dateRangeFilterDateFromIso: dateRangeFilterDateFromIso,
        dateRangeFilterDateToIso: dateRangeFilterDateToIso
      }
    );
  }

  private async loadFilterSettings(): Promise<void> {
    const storedFilterSettings: FilterSettings | null =
      await DataStorageHelper.getItem(
        ProcessTaskGroupFilterSettingsService.SAVE_DATA_KEY
      );

    const defaultFilterBarFilterSettings =
      await this.computedValueService.getComputedValue({
        valueComputerClass:
          DefaultProcessTaskGroupFilterBarFilterSettingsComputer,
        computeData: {}
      });

    const filterSettingsWithDefaultValues: FilterSettings = {
      ...defaultFilterBarFilterSettings,
      selectedProcessTaskGroupFilterId: null,
      searchText: null,
      selectedProcessConfigurationStepIds: [],
      excludedProcessConfigurationStepIds: [],
      ...storedFilterSettings
    };

    this.filterSettings = {
      ...filterSettingsWithDefaultValues,
      nextDaysFilterValue:
        filterSettingsWithDefaultValues.nextDaysFilterValue ??
        DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE,
      dateRangeFilterDateFromIso:
        filterSettingsWithDefaultValues.dateRangeFilterDateFromIso ??
        DateUtils.getDateWithDayOffsetWithoutTime(0).toISOString(),
      dateRangeFilterDateToIso:
        filterSettingsWithDefaultValues.dateRangeFilterDateToIso ??
        DateUtils.getDateWithDayOffsetWithoutTime(
          DefaultProcessTaskGroupFilterBarFilterSettingsComputer.DEFAULT_NEXT_DAYS_FILTER_VALUE +
            1
        ).toISOString()
    };
  }
}

export type FilterSettings = {
  filterMode: ProcessTaskGroupFilterMode;
  addressFilterType: GetProcessTaskGroupsFilterAddressFilterType;
  nextDaysFilterValue: number | null;
  dateRangeFilterDateFromIso: string | null;
  dateRangeFilterDateToIso: string | null;
  includeArchive: boolean;
  assigneeUserId: string | null;
  selectedActionStatusOptionType: TProcessConfigurationActionStatusFilterType;
  selectedActionStatusId: string | null;
  customActionStatusFilterText: string | null;
  selectedProcessTaskGroupFilterId: string | null;
  searchText: string | null;
  selectedProcessConfigurationStepIds: Array<string>;
  excludedProcessConfigurationStepIds: Array<string>;
  lastEditedBeforeDaysEnabled: boolean;
  lastEditedBeforeDays: number;
};

export type FilterSettingsWithDates = Omit<
  FilterSettings,
  'dateRangeFilterDateFromIso' | 'dateRangeFilterDateToIso'
> & {
  dateFromIso: string;
  dateToIso: string;
};

type ChangeCallback = (filterSettings: FilterSettings) => void;
