import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { SorterSortOption } from '../../../../aureliaAttributes/sorter';
import { UrlManager } from '../../../UrlManager';
import { Report } from './types';

export class ReportUtils {
  private constructor() {}

  public static sortOptions: ReportSortOptions = {
    exported: {
      name: 'Exportdatum',
      sortFunction: (a, b) => {
        return a.created - b.created;
      }
    }
  };

  public static getFullOnlineFilePathForReport(
    report: Report,
    token: string
  ): string {
    return this.getFullOnlineFilePath(report.id, token);
  }

  public static getRelativeOnlineFilePathForReport(
    report: Report,
    token: string
  ): string {
    return this.getRelativeOnlineFilePath(report.id, token);
  }

  public static getFullOnlineFilePath(reportId: string, token: string): string {
    const relativePath = this.getRelativeOnlineFilePath(reportId, token);

    return PathUtils.joinPaths(UrlManager.webFolder, relativePath);
  }

  public static getRelativeOnlineFilePath(
    reportId: string,
    token: string
  ): string {
    return (
      PathUtils.joinPaths('files', 'exported-reports', 'download', reportId) +
      `?token=${token}`
    );
  }
}

export type ReportSortOptions = {
  exported: ReportSortOption;
};

export type ReportSortOption = SorterSortOption<Report>;
