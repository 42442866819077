import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper } from '../../classes/DomEventHelper';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

/**
 * @event value-changed
 */
@autoinject()
export class ReportTypeSelect {
  @bindable public selectedReportTypeId: string | null = null;

  @bindable public enabled = false;
  @bindable public label = '';
  @bindable public noLabel = false;

  private domElement: HTMLElement;

  protected availableReportTypes: Array<ReportType> = [];

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ReportType,
      this.updateAvailableReportTypes.bind(this)
    );
    this.updateAvailableReportTypes();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateAvailableReportTypes(): void {
    this.availableReportTypes =
      this.entityManager.reportTypeRepository.getAll();
  }

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
