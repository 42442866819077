import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { TextBrick } from '../../../classes/EntityManager/entities/TextBrick/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class TextBrickWidgetTextBrick {
  @bindable()
  public textBrick: TextBrick | null = null;

  @subscribableLifecycle()
  protected readonly textBrickPermissionsHandle: EntityNameToPermissionsHandle[EntityName.TextBrick];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.textBrickPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.TextBrick,
        context: this as TextBrickWidgetTextBrick,
        propertyName: 'textBrick'
      });
  }

  protected handleTextBrickChanged(): void {
    assertNotNullOrUndefined(
      this.textBrick,
      "can't TextBrickWidgetTextBrick.handleTextBrickDelete without textBrick"
    );

    this.entityManager.textBrickRepository.update(this.textBrick);
  }

  protected handleDeleteClicked(): void {
    const textBrick = this.textBrick;
    assertNotNullOrUndefined(
      textBrick,
      "can't TextBrickWidgetTextBrick.handleTextBrickDelete without textBrick"
    );

    void Dialogs.deleteEntityDialog(textBrick).then(() => {
      this.entityManager.textBrickRepository.delete(textBrick);
    });
  }
}
