import { autoinject } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskDeviceProperty } from '../../classes/EntityManager/entities/Property/types';

@autoinject()
export class PropertiesByProcessTaskDeviceIdComputer extends ValueComputer<
  IComputeData,
  PropertiesByProcessTaskDeviceId
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(data: IComputeData): PropertiesByProcessTaskDeviceId {
    const properties =
      this.entityManager.propertyRepository.getByOwnerProcessTaskId(
        data.ownerProcessTaskId
      );
    const deviceProperties = properties.filter(
      (p): p is ProcessTaskDeviceProperty => !!p.processTaskDeviceId
    );
    return Utils.groupBy(deviceProperties, (p) => p.processTaskDeviceId);
  }
}

export interface IComputeData {
  ownerProcessTaskId: string;
}

export type PropertiesByProcessTaskDeviceId = Map<
  string,
  Array<ProcessTaskDeviceProperty>
>;
