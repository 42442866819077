import { autoinject } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * A component for editing lists of config settings.
 *
 * @slot default
 *
 * @event add-button-clicked triggered when the add button is clicked.
 */
@autoinject()
export class ConfigList {
  private readonly element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected handleAddButtonClicked(): void {
    DomEventHelper.fireEvent<AddButtonClickedEvent>(this.element, {
      name: 'add-button-clicked',
      detail: null
    });
  }
}

export type AddButtonClickedEvent = NamedCustomEvent<'add-button-clicked'>;
