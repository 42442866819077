import { autoinject, bindable } from 'aurelia-framework';
import { CalendarEntry } from '../../CalendarEntryDataSource/CalendarEntry';
import { computed } from '../../../../hooks/computed';
import { arrayChanges } from '../../../../hooks/dependencies';
import { ShowHideAnimator } from '../../../../classes/Animation/ShowHideAnimator';
import { CalendarEntryClickedEvent } from '../../process-task-appointment-calendar-widget';
import { DomEventHelper } from '../../../../classes/DomEventHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class ProcessTaskAppointmentCalendarWidgetMonthMultipleAppointmentsPerDayWidget {
  @bindable public appointments: Array<CalendarEntry> = [];

  @bindable()
  public getIncreasedZIndex: (() => number) | null = null;

  protected allAppointmentsShown = false;
  protected zIndex = 1;

  protected fullCardElement: HTMLElement | null = null;
  private fullCardElementAnimator: ShowHideAnimator | null = null;

  private readonly domElement: HTMLElement;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected bind(): void {
    if (!this.fullCardElement) {
      throw new Error('appointmentDialogElement is not bound');
    }
    this.fullCardElementAnimator = new ShowHideAnimator(this.fullCardElement);
  }

  @computed(arrayChanges('appointments'))
  protected get appointmentsWithValidColors(): Array<CalendarEntry> {
    return this.appointments.filter((e) => !!e.color);
  }

  protected async handleAppointmentCardClicked(
    event: MouseEvent
  ): Promise<void> {
    // We need to make sure overlapped TimeCell elements cannot consume the click event
    event.stopPropagation();
    if (event.defaultPrevented || this.allAppointmentsShown) return;

    assertNotNullOrUndefined(
      this.getIncreasedZIndex,
      'cannot handleAppointmentCardClicked without getIncreasedZIndex callback'
    );
    this.zIndex = this.getIncreasedZIndex();

    void this.fullCardElementAnimator?.scaleToWidth(2);
    this.allAppointmentsShown = true;
  }

  protected async handleCloseIconClicked(event: MouseEvent): Promise<void> {
    event.preventDefault();

    void this.fullCardElementAnimator?.scaleBackToDefaultWidth();
    this.allAppointmentsShown = false;
    this.zIndex = 1;
  }

  protected handleSingleAppointmentCardClicked(entry: CalendarEntry): void {
    DomEventHelper.fireEvent<CalendarEntryClickedEvent>(this.domElement, {
      name: 'calendar-entry-clicked',
      detail: {
        entry
      },
      bubbles: true
    });
  }
}
