import { autoinject, bindable } from 'aurelia-framework';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingAuthorization } from '../../classes/EntityManager/entities/ThingAuthorization/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { InlineCreationSection } from '../../aureliaComponents/inline-creation-section/inline-creation-section';
import { assertNotNullOrUndefined } from 'common/Asserts';

@autoinject()
export class EditThingAuthorizationsWidget {
  @bindable public thing: Thing | null = null;

  protected userIdForNewAuthorization: string | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  protected newAuthorizationInlineCreationSection: InlineCreationSection | null =
    null;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.Thing,
        context: this as EditThingAuthorizationsWidget,
        propertyName: 'thing'
      });
  }

  protected handleCreationSectionClosed(): void {
    this.userIdForNewAuthorization = null;
  }

  protected handleCreateNewAuthorizationClick(): true {
    assertNotNullOrUndefined(
      this.userIdForNewAuthorization,
      "can't EditThingAuthorizationsWidget.handleCreateNewAuthorizationClick without userIdForNewAuthorization"
    );
    assertNotNullOrUndefined(
      this.thing,
      "can't EditThingAuthorizationsWidget.handleCreateNewAuthorizationClick without thing"
    );

    this.entityManager.thingAuthorizationRepository.create({
      thingId: this.thing.id,
      userId: this.userIdForNewAuthorization,
      ownerUserGroupId: this.thing.ownerUserGroupId
    });
    this.userIdForNewAuthorization = null;

    return true; // do not cancel the event
  }

  @computed(expression('thing.id'), model(EntityName.ThingAuthorization))
  protected get thingAuthorizations(): Array<ThingAuthorization> {
    if (!this.thing) return [];

    return this.entityManager.thingAuthorizationRepository.getByThingId(
      this.thing.id
    );
  }
}
