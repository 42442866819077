import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import {
  StructureTemplateDto,
  StructureTemplateStatus
} from './StructureTemplateDto';

export function createStructureTemplateEntityInfo<
  TEntity extends StructureTemplateDto<string, string>
>(
  options: CreateEntityInfoOptions<
    StructureTemplateDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.StructureTemplate,
  TEntity
> {
  return {
    entityName: EntityName.StructureTemplate,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      StructureTemplateDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        description: {
          name: 'description',
          defaultValue: null
        },
        type: {
          name: 'type'
        },
        status: {
          name: 'status',
          defaultValue: StructureTemplateStatus.DRAFT
        },
        levelConfigurations: {
          name: 'levelConfigurations',
          innerInfo: [
            {
              level: {
                name: 'level'
              },
              ratingEnabled: {
                name: 'ratingEnabled',
                defaultValue: false
              }
            }
          ],
          defaultValue: () => []
        },
        ratingEnabledOnStructureListItems: {
          name: 'ratingEnabledOnStructureListItems',
          defaultValue: false
        },
        enableFlawNumbers: {
          name: 'enableFlawNumbers',
          defaultValue: false
        },
        flawNumberMajorPartConfig: {
          name: 'flawNumberMajorPartConfig',
          defaultValue: null
        },
        flawNumberMinorPartConfig: {
          name: 'flawNumberMinorPartConfig',
          defaultValue: null
        },
        minorTextForExcludedEntries: {
          name: 'minorTextForExcludedEntries',
          defaultValue: null
        },
        oldTemplateName: {
          name: 'oldTemplateName',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
