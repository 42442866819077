import { autoinject, bindable, observable } from 'aurelia-framework';
import '../../../libs/msvg/msvg';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';
import { DrawingArea } from '../drawing-area/drawing-area';
import { ArrowTool } from '../drawing-area/tools/ArrowTool';
import { CloudTool } from '../drawing-area/tools/CloudTool';
import { DeleteTool } from '../drawing-area/tools/DeleteTool';
import { EllipseTool } from '../drawing-area/tools/EllipseTool';
import { FreehandTool } from '../drawing-area/tools/FreehandTool';
import { LineTool } from '../drawing-area/tools/LineTool';
import { RectangleTool } from '../drawing-area/tools/RectangleTool';
import { TextTool } from '../drawing-area/tools/TextTool';
import { Tool } from '../drawing-area/tools/Tool';
import { ToolGrid } from '../drawing-area/tools/ToolGrid';
import { DrawingHistoryManager } from '../sketcher-overlay/DrawingHistoryManager';
import { ActionTriggeredEvent } from './sketcher-overlay-tool-bar-action-buttons/sketcher-overlay-tool-bar-action-buttons';

/**
 * @event {import('./sketcher-overlay-tool-bar-action-buttons/sketcher-overlay-tool-bar-action-buttons').ActionTriggeredEvent} action-triggered
 * @event {SketcherOverlayToolBarLoadedEvent} sketcher-overlay-tool-bar-loaded - TODO: maybe hide the toolbar when it's not loaded yet because the user shouldn't use it until then
 */
@autoinject()
export class SketcherOverlayToolBar {
  public static readonly SIZE_NORMALIZATION_FACTOR = 1.92;

  @bindable()
  public drawingArea: DrawingArea | null = null;

  @bindable()
  public historyManager: DrawingHistoryManager | null = null;

  @bindable()
  public grid: ToolGrid | null = null;

  @bindable()
  public toolIsActive: boolean = false;

  @bindable()
  public tools: Array<Tool> = [
    new FreehandTool(),
    new LineTool(),
    new TextTool(),
    new RectangleTool(),
    new EllipseTool(),
    new ArrowTool(),
    new CloudTool(),
    new DeleteTool()
  ];

  private settingsLoaded: boolean = false;
  private domElement: HTMLElement;
  private saveSettingsRateLimited: IUtilsRateLimitedFunction =
    Utils.rateLimitFunction(this.saveSettings.bind(this), 250);
  private activeTool: Tool | null = null;
  private self = this;

  @observable()
  private selectedSize: number = 5;

  @observable()
  private selectedColor: string = '#000';

  @observable()
  private selectedGridSize: number = 0;

  @observable()
  private selectedStrokeDashArray: Array<number> = [0];

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  public attached(): void {
    void this.loadSettings();
    this.reset();
  }

  public reset(): void {
    const fallbackTool = this.tools[0] ?? null;

    if (this.activeTool === fallbackTool) {
      this.activeTool?.cancel();
    } else {
      this.selectTool(fallbackTool);
    }
  }

  public cancelTool(): void {
    this.activeTool?.cancel();
  }

  private selectedSizeChanged(): void {
    this.activeTool?.setSize(this.normalizeSize(this.selectedSize));
    this.saveSettingsRateLimited();
  }

  private selectedColorChanged(): void {
    this.activeTool?.setColor(this.selectedColor);
    this.saveSettingsRateLimited();
  }

  private selectedStrokeDashArrayChanged(): void {
    this.activeTool?.setStrokeDashArray(this.selectedStrokeDashArray);
    this.saveSettingsRateLimited();
  }

  protected drawingAreaChanged(): void {
    if (this.drawingArea && this.activeTool) {
      this.activeTool.activate(this.drawingArea);
    }
  }

  private gridChanged(): void {
    this.updateGrid();
  }

  private selectedGridSizeChanged(): void {
    this.updateGrid();
    this.saveSettingsRateLimited();
  }

  private updateGrid(): void {
    if (this.grid)
      this.grid.setDesiredGridSize(
        this.selectedGridSize > 0
          ? this.normalizeSize(this.selectedGridSize)
          : 0
      );
  }

  private normalizeSize(size: number): number {
    // to make the size simillar to the old canvas style
    return Math.max(SketcherOverlayToolBar.SIZE_NORMALIZATION_FACTOR * size, 1);
  }

  private selectTool(tool: Tool | null): void {
    this.activeTool && this.activeTool.finish();
    if (tool === this.activeTool || tool == null) {
      this.activeTool = null;
      this.toolIsActive = false;
      return; // just cancel the tool
    }

    this.activeTool = tool;
    tool.setColor(this.selectedColor);
    tool.setSize(this.normalizeSize(this.selectedSize));
    tool.setStrokeDashArray(this.selectedStrokeDashArray);

    if (this.drawingArea) tool.activate(this.drawingArea, this.grid);

    this.toolIsActive = true;
  }

  private handleActionTriggered(event: ActionTriggeredEvent): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: event.type,
      detail: event.detail
    });
  }

  private async saveSettings(): Promise<void> {
    if (!this.settingsLoaded) {
      return;
    }

    const settings: ISettingsSaveData = {
      size: this.selectedSize,
      color: this.selectedColor,
      gridSize: this.selectedGridSize,
      strokeDashArray: this.selectedStrokeDashArray
    };

    await DataStorageHelper.setItem(
      'SketcherOverlayToolBar::settings',
      settings
    );
  }

  private async loadSettings(): Promise<void> {
    try {
      const settings = await this.getSettingsFromDb();
      if (settings) {
        this.selectedSize = settings.size;
        this.selectedColor = settings.color;
        this.selectedGridSize = settings.gridSize;
        this.selectedStrokeDashArray = settings.strokeDashArray;
      }
    } catch (e) {
      throw e;
    } finally {
      this.settingsLoaded = true;
      DomEventHelper.fireEvent<SketcherOverlayToolBarLoadedEvent>(
        this.domElement,
        {
          name: 'sketcher-overlay-tool-bar-loaded',
          detail: null
        }
      );
    }
  }

  private async getSettingsFromDb(): Promise<ISettingsSaveData | null> {
    // @deprecated 2020-11-19 - the PictureSketcherToolBar::settings is just here for a smoother transition from the old picture-sketcher to the new sketcher-overlay
    let settings = await DataStorageHelper.getItem(
      'PictureSketcherToolBar::settings'
    );

    if (settings) {
      await DataStorageHelper.setItem(
        'SketcherOverlayToolBar::settings',
        settings
      );
      await DataStorageHelper.removeItem('PictureSketcherToolBar::settings');
    } else {
      settings = await DataStorageHelper.getItem(
        'SketcherOverlayToolBar::settings'
      );
    }

    return settings;
  }
}

export type SketcherOverlayToolBarLoadedEvent = NamedCustomEvent<
  'sketcher-overlay-tool-bar-loaded',
  null
>;

interface ISettingsSaveData {
  size: number;
  color: string;
  gridSize: number;
  strokeDashArray: Array<number>;
}
