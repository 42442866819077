import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { ThingSectionCreationService } from '../../classes/EntityManager/entities/ThingSection/ThingSectionCreationService';
import { ThingSection } from '../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ThingSectionDragData } from './ThingSectionDragData';

@autoinject()
export class EditThingSectionsWidget {
  @bindable()
  public thing: Thing | null = null;

  /**
   * optional/can be null
   *
   * If this is set, the sections (except the project specific properties) won't be editable.
   * Also the the project specific properties will be shown
   */
  @bindable()
  public project: Project | null = null;

  @subscribableLifecycle()
  protected readonly thingPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  private readonly subscriptionManager: SubscriptionManager;
  private isAttached: boolean = false;
  protected sections: Array<ThingSection> = [];

  protected readonly ThingSectionDragData = ThingSectionDragData;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly thingSectionCreationService: ThingSectionCreationService,
    permissionsService: PermissionsService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.thingPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'thing'
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSection,
      this.updateSections.bind(this)
    );
    this.updateSections();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected thingChanged(): void {
    if (this.isAttached) {
      this.updateSections();
    }
  }

  private updateSections(): void {
    if (this.thing) {
      this.sections =
        this.entityManager.thingSectionRepository.getOrderedByThingId(
          this.thing.id
        );
    } else {
      this.sections = [];
    }
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.thing,
      "can't EditThingSectionsWidget.handleAddButtonClick without thing"
    );

    this.thingSectionCreationService.createAtEnd({
      thing: this.thing
    });

    this.updateSections();
  }

  protected handleThingSectionDeleted(): void {
    this.updateSections();
  }

  protected handleDrop(
    dragData: ThingSectionDragData,
    dropIndex: number
  ): void {
    this.entityManager.thingSectionRepository.moveSectionToIndex({
      section: dragData.section,
      index: dropIndex
    });
  }

  protected canActivate(
    dragData: ThingSectionDragData,
    dropIndex: number
  ): boolean {
    const indexInSections = this.sections.indexOf(dragData.section);

    return dropIndex < indexInSections || dropIndex > indexInSections + 1;
  }
}
