import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import { DateUtils } from 'common/DateUtils';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';

@inject(Router, AppEntityManager)
export class OperationsPhoneCallDialog {
  /** @type {import('../../dialogs/record-it-dialog/record-it-dialog').RecordItDialog|null} */
  _dialog = null;

  /** @type {Array<TProcessTaskInfo>} */
  _processTaskInfos = [];

  /**
   * @param {Router} router
   * @param {AppEntityManager} entityManager
   */
  constructor(router, entityManager) {
    this._router = router;

    this._entityManager = entityManager;

    this._DateUtils = DateUtils;
  }

  /**
   * @param {TOpenOptions} options
   */
  open(options) {
    this._processTaskInfos = options.processTaskInfos;
    this._phoneNumber = options.phoneNumber;

    if (this._dialog) this._dialog.open();
  }

  close() {
    if (this._dialog) this._dialog.close();
  }

  /**
   * @param {TOpenOptions} options
   */
  static async open(options) {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  /**
   * @param {string} processTaskId
   */
  _navigateToProcessTask(processTaskId) {
    this.close();
    this._router.navigateToRoute('edit_process_task', {
      process_task_id: processTaskId
    });
  }

  /**
   * @param {string} thingGroupId
   * @returns {string}
   */
  _getAddressOfThingGroupId(thingGroupId) {
    const thingGroup =
      this._entityManager.thingGroupRepository.getById(thingGroupId) || null;
    return thingGroup
      ? ThingGroupHelper.getThingGroupAddressString(
          thingGroup.streetName,
          thingGroup.zip,
          thingGroup.municipality
        )
      : '';
  }
}

/**
 * @typedef {Object} TOpenOptions
 * @property {string} phoneNumber
 * @property {Array<TProcessTaskInfo>} processTaskInfos
 */

/** @typedef {import('../../../../common/src/Types/WildixXmppSocketMessages').TProcessTaskInfo} TProcessTaskInfo */
