import { bindable, autoinject } from 'aurelia-framework';
import chunk from 'lodash/chunk';
import { SubscriptionManager } from 'src/classes/SubscriptionManager';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { SelectedChoiceChangedEvent } from '../selection-switch/selection-switch';

/**
 * @event selected-choice-changed
 */
@autoinject()
export class MultiRowSelectionSwitch<T> {
  @bindable public selectedChoice: T | null = null;

  /**
   * if you pass an array of objects, you will also have to set the choiceLabelPropertyName
   */
  @bindable public choices: Array<T> = [];

  /**
   * Choices that cannot be selected.
   */
  @bindable public disabledChoices: Array<T> = [];

  /**
   * the property of a choice which should be shown
   * set it to null to use the choice itself
   * null is only useful if the choices is a string array
   */
  @bindable public choiceLabelPropertyName: keyof T | null = null;

  /**
   * The property of a choice which contains the translation key.
   * Can not be used with choiceLabelPropertyName (will override it)
   */
  @bindable public choiceLabelTranslationKeyPropertyName: keyof T | null = null;
  @bindable public choiceLabelTranslationKeyParamsPropertyName: keyof T | null =
    null;

  @bindable public choiceValuePropertyName: keyof T | null = null;

  @bindable public desiredChoiceWidth: number = 300;

  @bindable public enabled = false;

  protected choiceRows: Array<Array<T>> = [];
  protected choicesPerRow: number | null = null;
  private isAttached: boolean = false;
  private element: HTMLElement;
  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.element = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToArrayPropertyChanges(
      this,
      'choices',
      this.updateChoiceRows.bind(this)
    );
    this.subscriptionManager.subscribeToResize(
      this.updateChoiceRows.bind(this)
    );
    this.updateChoiceRows();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected desiredChoiceWidthChanged(): void {
    if (this.isAttached) {
      this.updateChoiceRows();
    }
  }

  protected handleSelectedChoiceChanged(
    $event: SelectedChoiceChangedEvent<T>
  ): void {
    DomEventHelper.fireEvent<SelectedChoiceChangedEvent<T>>(this.element, {
      name: 'selected-choice-changed',
      detail: $event.detail
    });
  }

  private updateChoiceRows(): void {
    const computed = window.getComputedStyle(this.element);
    const width = computed.width ? parseFloat(computed.width) : NaN;

    let rowSize = 5;
    if (!isNaN(width)) {
      rowSize = Math.max(Math.round(width / this.desiredChoiceWidth), 1);
    }

    this.choiceRows = chunk(this.choices, rowSize);

    this.choicesPerRow =
      this.choiceRows.length > 1 ? this.choiceRows[0]!.length : null;
  }
}
