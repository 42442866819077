import { computedFrom } from 'aurelia-binding';
import { ProcessConfigurationPositionType } from 'common/Enums/ProcessConfigurationPositionType';
import {
  ProcessTaskPositionCalculator,
  PositionPrices,
  MarkupPositionCalculationOtherPositionInfos
} from 'common/Operations/ProcessTaskPositionCalculator';
import { StringUtils } from 'common/Utils/StringUtils/StringUtils';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer } from '../../computedValues/computers/CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { ProcessTaskPositionDetailEntry } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/types';
import { ProcessTaskPositionDetailEntryUtils } from '../../classes/EntityManager/entities/ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryUtils';
import { ComputedValueService } from '../../computedValues/ComputedValueService';

type TPositionPrices = PositionPrices<
  ProcessTaskPosition,
  ProcessTaskPositionDetailEntry
>;

export class PriceInfo {
  private calculator: ProcessTaskPositionCalculator | null = null;
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private processTaskPosition: ProcessTaskPosition | null,
    private positionPrices: Array<TPositionPrices> | null,
    private detailEntries: Array<ProcessTaskPositionDetailEntry>,
    private readonly computedValueService: ComputedValueService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public init(): void {
    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribeWithSubscriptionUpdating({
        valueComputerClass:
          CustomPositionTypeConfigurationFromProcessTaskGroupIdComputer,
        createComputeData: () =>
          this.processTaskPosition
            ? {
                processTaskGroupId:
                  this.processTaskPosition.ownerProcessTaskGroupId
              }
            : null,
        createUpdaters: (updateSubscriptions) => {
          this.subscriptionManager.subscribeToExpression(
            this,
            'processTaskPosition.ownerProcessTaskId',
            updateSubscriptions
          );
        },
        callback: (config) => {
          this.calculator = new ProcessTaskPositionCalculator(config);
        },
        onNoComputeData: () => {
          this.calculator = null;
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public setProcessTaskPosition(
    processTaskPosition: ProcessTaskPosition | null
  ): void {
    this.processTaskPosition = processTaskPosition;
  }

  public setProcessTaskPositionPrices(
    positionPrices: Array<TPositionPrices> | null
  ): void {
    this.positionPrices = positionPrices;
  }

  public setProcessTaskPositionDetailEntries(
    detailEntries: Array<ProcessTaskPositionDetailEntry>
  ): void {
    this.detailEntries = detailEntries;
  }

  @computedFrom('calculator', 'processTaskPosition.type', 'positionPrices')
  public get markupBaseAmount(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    switch (this.processTaskPosition.type) {
      case ProcessConfigurationPositionType.MARKUP:
        const amount = this.calculator.getMarkupBaseAmount({
          ignoreExcludeFromMarkup:
            this.processTaskPosition.ignoreExcludeFromMarkup,
          ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
          markupPositionCalculationOtherPositionInfos:
            this.getMarkupPositionCalculationOtherPositionInfos(this.calculator)
        });
        return StringUtils.priceToString(amount);
      default:
        return '';
    }
  }

  @computedFrom(
    'calculator',
    'processTaskPosition.type',
    'processTaskPosition.amount',
    'processTaskPosition.detailEnabled',
    'detailEntries'
  )
  public get amount(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    switch (this.processTaskPosition.type) {
      case ProcessConfigurationPositionType.MARKUP:
        return '';

      default:
        const amount = this.calculator.getAmount({
          type: this.processTaskPosition.type,
          amount: this.processTaskPosition.amount,
          detailEnabled: this.processTaskPosition.detailEnabled,
          detailEntryInfos:
            ProcessTaskPositionDetailEntryUtils.getDetailEntryInfos(
              this.detailEntries
            )
        });

        return amount.toString();
    }
  }

  @computedFrom('processTaskPosition.type', 'processTaskPosition.unit')
  public get currencyWithUnit(): string | null {
    if (!this.processTaskPosition) {
      return null;
    }

    switch (this.processTaskPosition.type) {
      case ProcessConfigurationPositionType.MARKUP:
        return '%';

      default:
        return `€${
          this.processTaskPosition.unit
            ? ' / ' + this.processTaskPosition.unit
            : ''
        }`;
    }
  }

  @computedFrom('processTaskPosition.type', 'processTaskPosition.unit')
  public get unit(): string | null {
    if (!this.processTaskPosition) {
      return null;
    }

    switch (this.processTaskPosition.type) {
      case ProcessConfigurationPositionType.MARKUP:
        return null;

      default:
        return this.processTaskPosition.unit;
    }
  }

  @computedFrom(
    'calculator',
    'processTaskPosition.price',
    'processTaskPosition.discount'
  )
  public get discountedPrice(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    return StringUtils.priceToString(
      this.calculator.getDiscountedPrice({
        price: this.processTaskPosition.price,
        discount: this.processTaskPosition.discount
      })
    );
  }

  @computedFrom('processTaskPosition.price', 'processTaskPosition.discount')
  public get invertedDiscountValueWithSign(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    return StringUtils.priceToString(
      this.calculator.getDiscountValue({
        price: this.processTaskPosition.price,
        discount: this.processTaskPosition.discount
      }) * -1,
      { withSign: true, spaceBetweenSign: true }
    );
  }

  @computedFrom('processTaskPosition.price', 'processTaskPosition.discount')
  public get discountValue(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    return StringUtils.priceToString(
      this.calculator.getDiscountValue({
        price: this.processTaskPosition.price,
        discount: this.processTaskPosition.discount
      })
    );
  }

  @computedFrom('processTaskPosition.discount')
  public get invertedDiscountWithSign(): string {
    if (!this.processTaskPosition) {
      return '';
    }

    return StringUtils.numberWithSign(this.processTaskPosition.discount * -1, {
      spaceBetweenSign: true
    });
  }

  /**
   * Returns the full price if the position is not a markup.
   * Returns the percentage value if the position is a markup.
   */
  @computedFrom(
    'fullPrice',
    'processTaskPosition.type',
    'processTaskPosition.price'
  )
  public get fullPriceWithoutMarkupPriceAndWithCurrency(): string {
    if (!this.processTaskPosition) {
      return '';
    }

    switch (this.processTaskPosition.type) {
      case ProcessConfigurationPositionType.MARKUP:
        return this.processTaskPosition.price + ' %';

      default:
        return this.fullPrice + ' €';
    }
  }

  @computedFrom(
    'calculator',
    'processTaskPosition.type',
    'processTaskPosition.customType',
    'processTaskPosition.amount',
    'processTaskPosition.price',
    'processTaskPosition.flatRate',
    'processTaskPosition.discount',
    'processTaskPosition.ignoreExcludeFromMarkup',
    'processTaskPosition.ownerProcessTaskId',
    'processTaskPosition.detailEnabled',
    'detailEntries',
    'positionPrices'
  )
  public get fullPrice(): string {
    if (!this.processTaskPosition || !this.calculator) {
      return '';
    }

    return StringUtils.priceToString(
      this.calculator.getFullPrice({
        type: this.processTaskPosition.type,
        customType: this.processTaskPosition.customType,
        amount: this.processTaskPosition.amount,
        price: this.processTaskPosition.price,
        flatRate: this.processTaskPosition.flatRate,
        discount: this.processTaskPosition.discount,
        ignoreExcludeFromMarkup:
          this.processTaskPosition.ignoreExcludeFromMarkup,
        ownerProcessTaskId: this.processTaskPosition.ownerProcessTaskId,
        detailEnabled: this.processTaskPosition.detailEnabled,
        detailEntryInfos:
          ProcessTaskPositionDetailEntryUtils.getDetailEntryInfos(
            this.detailEntries
          ),
        markupPositionCalculationOtherPositionInfos:
          this.getMarkupPositionCalculationOtherPositionInfos(this.calculator)
      })
    );
  }

  private getMarkupPositionCalculationOtherPositionInfos(
    calculator: ProcessTaskPositionCalculator
  ): MarkupPositionCalculationOtherPositionInfos {
    return this.positionPrices
      ? calculator.createMarkupPositionCalculationOtherPositionInfos(
          this.positionPrices
        )
      : {
          sumOfDefaultPositionsByProcessTaskId: new Map(),
          sumOfDefaultPositionsWhichAreIncludedInMarkupByProcessTaskId:
            new Map()
        };
  }
}
