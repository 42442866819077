import { autoinject, bindable } from 'aurelia-framework';
import { ExpandableListComponent } from '../../classes/ExpandableListComponent';

@autoinject()
export class ExpandableListComponentMultiToggle {
  @bindable public items: Set<ExpandableListComponent> | null = null;

  protected async toggleAll(): Promise<void> {
    if (!this.items) return;
    if (!this.allExpanded) {
      void this.expandSubItems(this.items);
    } else {
      this.collapseSubItems(this.items);
    }
  }

  private async expandSubItems(
    items: Set<ExpandableListComponent>
  ): Promise<void> {
    for (const item of items) {
      await item.expandSubEntriesRecursively(true);
    }
  }

  private collapseSubItems(items: Set<ExpandableListComponent>): void {
    for (const item of items) {
      item.collapseSubEntriesRecursively();
    }
  }

  protected get allExpanded(): boolean {
    if (!this.items) return false;
    return Array.from(this.items).every((item) => item.isExpanded()) ?? false;
  }
}
