import { bindable } from 'aurelia-templating';
import { inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event value-changed
 */
@inject(Element, AppEntityManager, SubscriptionManagerService)
export class ProcessConfigurationCategorySelect {
  /** @type {string|null} */
  @bindable processConfigurationId = null;

  /** @type {string|null} */
  @bindable value = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {Array<import('../../classes/EntityManager/entities/ProcessConfigurationCategory/types').ProcessConfigurationCategory>} */
  _categories = [];
  /** @type {boolean} */
  _attached = false;

  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {HTMLElement} element
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(element, entityManager, subscriptionManagerService) {
    this._domElement = element;
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationCategory,
      this._updateCategories.bind(this)
    );
    this._updateCategories();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  processConfigurationIdChanged() {
    if (this._attached) {
      this._updateCategories();
    }
  }

  _updateCategories() {
    if (this.processConfigurationId) {
      this._categories =
        this._entityManager.processConfigurationCategoryRepository.getSortedCategoriesByProcessConfigurationId(
          this.processConfigurationId
        );
    } else {
      this._categories =
        this._entityManager.processConfigurationCategoryRepository.getAll();
    }
  }

  _handleSelectChanged() {
    if (!this._domElement) {
      return;
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
