import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { DefectMoveToNewThingDialog } from '../../dialogs/defect-move-to-new-thing-dialog/defect-move-to-new-thing-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { computed } from '../../hooks/computed';
import { currentUser, expression, isConnected } from '../../hooks/dependencies';
import { SocketService } from '../../services/SocketService';
import { DefectMoveToExistingThingDialog } from '../../dialogs/defect-move-to-existing-thing-dialog/defect-move-to-existing-thing-dialog';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { ModuleName } from '../../classes/RecordItModuleHelper';

/**
 * Displays a detailed view of a defect: The edit area so the defect can be edited,
 * the comments of the defect, and an area where new comments can be added.
 *
 * Displayed in a widget-overlay in the defect-entry-list.
 *
 * @event {TCloseIconClickedEvent} close-icon-clicked - triggered when the close icon of the widget is clicked.
 */

@autoinject()
export class DefectDetailsWidget {
  @bindable public defect: Defect | null = null;

  private element: HTMLElement;

  @subscribableLifecycle()
  protected defectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Defect];

  @subscribableLifecycle()
  protected thingPermissionHandle: EntityNameToPermissionsHandle[EntityName.Thing];

  constructor(
    element: Element,
    private readonly socketService: SocketService,
    private readonly currentUserService: CurrentUserService,
    permissionsService: PermissionsService
  ) {
    this.element = element as HTMLElement;
    this.defectPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Defect,
        context: this,
        expression: 'defect'
      });
    this.thingPermissionHandle =
      permissionsService.getPermissionsHandleForIdExpressionValue({
        entityName: EntityName.Thing,
        context: this,
        expression: 'defect.ownerThingId'
      });
  }

  protected handleCloseIconClicked(): void {
    DomEventHelper.fireEvent<TCloseIconClickedEvent>(this.element, {
      name: 'close-icon-clicked',
      detail: null
    });
  }

  protected handleMoveToExistingThingClicked(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleMoveToExistingThingClicked without defect'
    );

    void DefectMoveToExistingThingDialog.open({
      defect: this.defect
    });
  }

  protected handleMoveToNewThingClicked(): void {
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleMoveToNewThingClicked without defect'
    );

    void DefectMoveToNewThingDialog.open({
      defect: this.defect
    });
  }

  @computed(isConnected())
  private get isOnline(): boolean {
    return this.socketService.isConnected();
  }

  @computed(currentUser())
  private get canUseKuK(): boolean {
    const user = this.currentUserService.getCurrentUser();
    return PermissionHelper.userHasPermissionForModule(user, ModuleName.KUK);
  }

  @computed(
    expression('thingPermissionHandle.canCreateDefects'),
    expression('isOnline'),
    expression('canUseKuK')
  )
  protected get shouldDisplayHeaderButtons(): boolean {
    return (
      this.thingPermissionHandle.canCreateDefects &&
      this.isOnline &&
      this.canUseKuK
    );
  }
}

export type TCloseIconClickedEvent = NamedCustomEvent<
  'close-icon-clicked',
  null
>;
