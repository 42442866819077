import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskInvoiceToProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskInvoiceToProcessTaskDevice/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import { ValueComputer } from '../ValueComputer';

@autoinject()
export class ProcessTaskInvoiceToProcessTaskDeviceMapComputer extends ValueComputer<
  ProcessTaskInvoiceToProcessTaskDeviceMapComputerComputeData,
  ProcessTaskInvoiceToProcessTaskDeviceMapComputerComputeResult
> {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTaskDevice,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(): ProcessTaskInvoiceToProcessTaskDeviceMapComputerComputeResult {
    return {
      activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId:
        this.getActiveInvoiceToDeviceByDeviceId()
    };
  }

  public computeDataAreEqual(): boolean {
    return true;
  }

  private getActiveInvoiceToDeviceByDeviceId(): ProcessTaskInvoiceToProcessTaskDeviceByDeviceId {
    const invoiceIdToProcessTaskIds = this.getInvoiceIdToProcessTaskIds();
    const invoiceToDevices =
      this.entityManager.processTaskInvoiceToProcessTaskDeviceRepository.getAll();
    const activeInvoiceToDeviceByDeviceId: ProcessTaskInvoiceToProcessTaskDeviceByDeviceId =
      new Map();

    for (const invoiceToDevice of invoiceToDevices) {
      if (
        !invoiceIdToProcessTaskIds
          .get(invoiceToDevice.processTaskInvoiceId)
          ?.has(invoiceToDevice.ownerProcessTaskId)
      ) {
        continue;
      }

      const relations = activeInvoiceToDeviceByDeviceId.get(
        invoiceToDevice.processTaskDeviceId
      );
      if (relations) {
        relations.push(invoiceToDevice);
      } else {
        activeInvoiceToDeviceByDeviceId.set(
          invoiceToDevice.processTaskDeviceId,
          [invoiceToDevice]
        );
      }
    }

    return activeInvoiceToDeviceByDeviceId;
  }

  private getInvoiceIdToProcessTaskIds(): Map<string, Set<string>> {
    const invoiceIdToProcessTaskIds = new Map<string, Set<string>>();

    const relations =
      this.entityManager.processTaskInvoiceToProcessTaskRepository.getAll();
    for (const relation of relations) {
      const processTaskIds = invoiceIdToProcessTaskIds.get(
        relation.processTaskInvoiceId
      );
      if (processTaskIds) {
        processTaskIds.add(relation.ownerProcessTaskId);
      } else {
        invoiceIdToProcessTaskIds.set(
          relation.processTaskInvoiceId,
          new Set([relation.ownerProcessTaskId])
        );
      }
    }

    return invoiceIdToProcessTaskIds;
  }
}

export type ProcessTaskInvoiceToProcessTaskDeviceMapComputerComputeData = {};

export type ProcessTaskInvoiceToProcessTaskDeviceMapComputerComputeResult = {
  activeProcessTaskInvoiceToProcessTaskDeviceByDeviceId: ProcessTaskInvoiceToProcessTaskDeviceByDeviceId;
};

export type ProcessTaskInvoiceToProcessTaskDeviceByDeviceId = Map<
  string,
  Array<ProcessTaskInvoiceToProcessTaskDevice>
>;
