import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { ProcessConfigurationAuthorizationTypeStepRelation } from 'common/Types/Entities/ProcessConfigurationAuthorizationType/ProcessConfigurationAuthorizationTypeDto';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';

@autoinject()
export class ProcessConfigurationAuthorizationTypeRelationsWidgetListItem {
  @bindable()
  public stepRelation: ProcessConfigurationAuthorizationTypeStepRelation | null =
    null;

  @bindable()
  public processConfigurationId: string | null = null;

  @bindable()
  public usedStepIds: Array<string> = [];

  @bindable()
  public enabled: boolean = false;

  constructor(private readonly element: Element) {}

  protected handleStepRelationChanged(): void {
    DomEventHelper.fireEvent<StepRelationChangedEvent>(this.element, {
      name: 'step-relation-changed',
      detail: null
    });
  }

  protected handleDeleteClick(): void {
    DomEventHelper.fireEvent<DeleteButtonClickedEvent>(this.element, {
      name: 'delete-button-clicked',
      detail: null
    });
  }

  @computedFrom('usedStepIds')
  protected get excludedStepIds(): Array<string> {
    return this.usedStepIds.filter((usedStepId) => {
      return usedStepId !== this.stepRelation?.processConfigurationStepId;
    });
  }
}

export type StepRelationChangedEvent = NamedCustomEvent<
  'step-relation-changed',
  null
>;

export type DeleteButtonClickedEvent = NamedCustomEvent<
  'delete-button-clicked',
  null
>;
