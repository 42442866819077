import { Control, Layer, Map as LeafletMap } from 'leaflet';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MapLayer } from '../../classes/EntityManager/entities/MapLayer/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { Utils } from '../../classes/Utils/Utils';
import { GeoDataCacheService } from '../../services/GeoDataCacheService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { LayerStyle, SocketTileVectorGrid } from './SocketTileVectorGrid';

export class MapLayerManager {
  private static STORAGE_NAME = 'MapLayerManager';

  private layerControl: Control.Layers | null = null;

  private vectorLayerInfoMap: Map<string, VectorTileLayerInfo> = new Map();

  private saveMapLayerIdsRateLimited = Utils.rateLimitFunction(
    this.saveMapLayerIds.bind(this),
    500
  );

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly geoDataCacheService: GeoDataCacheService,
    private readonly map: LeafletMap
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.map.on('overlayadd', this.saveMapLayerIdsRateLimited);
    this.map.on('overlayremove', this.saveMapLayerIdsRateLimited);
  }

  public async init(layerControl: Control.Layers): Promise<Disposable> {
    this.layerControl = layerControl;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.MapLayer,
      this.updateMapLayers.bind(this)
    );

    const mapLayerIds = await this.loadMapLayerIds();
    this.updateMapLayers(mapLayerIds);

    return {
      dispose: () => {
        this.subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public getActiveVectorTileLayerInfos(): Array<VectorTileLayerInfo> {
    const infos: Array<VectorTileLayerInfo> = [];

    this.map.eachLayer((layer) => {
      if (layer instanceof SocketTileVectorGrid) {
        const info = this.vectorLayerInfoMap.get(layer.getId());
        if (info) infos.push(info);
      }
    });

    return infos;
  }

  private updateMapLayers(visibleLayerIds?: Array<string>): void {
    const mapLayers = this.entityManager.mapLayerRepository.getAll();
    const mapLayerIds = mapLayers.map((l) => l.id);

    for (const vectorLayerInfo of this.vectorLayerInfoMap.values()) {
      const vectorLayerId = vectorLayerInfo.layer.getId();
      if (!mapLayerIds.includes(vectorLayerId)) {
        this.removeMapLayer(vectorLayerId);
      }
    }

    mapLayers.forEach((mapLayer) => {
      const vectorLayerInfoOfMap = this.vectorLayerInfoMap.get(mapLayer.id);

      if (!vectorLayerInfoOfMap) {
        const addToMap = visibleLayerIds?.includes(mapLayer.id);
        this.addMapLayer(mapLayer, addToMap);
      } else {
        const layer = vectorLayerInfoOfMap.layer;

        this.changeMapLayerName(layer, mapLayer.name ?? '');
        this.changeMapLayerStyle(layer, {
          stroke: mapLayer.strokeEnabled,
          color: mapLayer.strokeColor,
          opacity: mapLayer.strokeOpacity,
          weight: mapLayer.strokeWeight,
          fill: mapLayer.fillEnabled,
          fillColor: mapLayer.fillColor,
          fillOpacity: mapLayer.fillOpacity,
          dashArray: mapLayer.strokeDashArray ?? undefined,
          radius: mapLayer.circleRadius
        });

        layer.setMinZoom(mapLayer.minZoom);
      }
    });
  }

  private addMapLayer(mapLayer: MapLayer, addToMap = true): void {
    assertNotNullOrUndefined(
      this.layerControl,
      'cannot add map layer without layer control'
    );

    this.map.createPane(mapLayer.id);

    const layer = new SocketTileVectorGrid(this.geoDataCacheService, {
      layerId: mapLayer.id,
      minZoom: mapLayer.minZoom,
      annotationPropertyName: mapLayer.annotationPropertyName,
      minZoomAnnotation: mapLayer.minZoomAnnotation,
      interactive: false,
      pane: mapLayer.id
    });

    layer.setStyle({
      stroke: mapLayer.strokeEnabled,
      color: mapLayer.strokeColor,
      opacity: mapLayer.strokeOpacity,
      weight: mapLayer.strokeWeight,
      fill: mapLayer.fillEnabled,
      fillColor: mapLayer.fillColor,
      fillOpacity: mapLayer.fillOpacity,
      dashArray: mapLayer.strokeDashArray ?? undefined,
      radius: mapLayer.circleRadius
    });

    const vectorLayerInfo = new VectorTileLayerInfo(layer);

    this.vectorLayerInfoMap.set(mapLayer.id, vectorLayerInfo);

    if (addToMap) this.map.addLayer(layer as Layer);
    this.layerControl.addOverlay(layer as Layer, mapLayer.name ?? 'no name');
  }

  private removeMapLayer(layerId: string): void {
    assertNotNullOrUndefined(
      this.layerControl,
      'cannot remove map layer without layer control'
    );

    const vectorLayerInfo = this.vectorLayerInfoMap.get(layerId);
    if (!vectorLayerInfo) return;

    const layer = vectorLayerInfo.layer;

    this.map.removeLayer(layer as Layer);
    this.layerControl.removeLayer(layer as Layer);

    this.vectorLayerInfoMap.delete(layerId);
  }

  private changeMapLayerName(layer: SocketTileVectorGrid, name: string): void {
    assertNotNullOrUndefined(
      this.layerControl,
      'cannot change map layer without layer control'
    );

    this.layerControl.removeLayer(layer as Layer);
    this.layerControl.addOverlay(layer as Layer, name);
  }

  private changeMapLayerStyle(
    layer: SocketTileVectorGrid,
    style: LayerStyle
  ): void {
    layer.setStyle(style);
    this.redrawLayer(layer);
  }

  private redrawLayer(layer: SocketTileVectorGrid): void {
    if (this.map.hasLayer(layer as Layer)) {
      this.map.removeLayer(layer as Layer);
      this.map.addLayer(layer as Layer);
    }
  }

  private saveMapLayerIds(): void {
    if (!this.map) return;

    const mapLayerIds: Array<string> = [];
    this.map.eachLayer((layer) => {
      if (layer instanceof SocketTileVectorGrid) {
        mapLayerIds.push(layer.getId());
      }
    });
    void DataStorageHelper.setItem(
      `${MapLayerManager.STORAGE_NAME}::MapLayerIds`,
      mapLayerIds
    );
  }

  private async loadMapLayerIds(): Promise<Array<string> | undefined> {
    return await DataStorageHelper.getItem(
      `${MapLayerManager.STORAGE_NAME}::MapLayerIds`
    );
  }
}

export class VectorTileLayerInfo {
  constructor(
    public layer: SocketTileVectorGrid,
    public opts?: TileLayerInfoOptions
  ) {}
}

type TileLayerInfoOptions = {
  attributionExport: string;
  excludeFromExport?: boolean;
};
