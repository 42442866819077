import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { TooltipContent } from '../tooltip-content/tooltip-content';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { RoleBasedPermissionsComputer } from '../../computedValues/computers/RoleBasedPermissionsComputer/RoleBasedPermissionsComputer';
import { RoleBasedPermissions } from 'common/Permissions/RoleBasedPermissions/RoleBasedPermissions';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';

/**
 * @event create-entity-clicked - fired when the button has been clicked
 * detail: { userGroup: (UserGroup|null) } - userGroup is null if noUserGroupSelection === true
 */
@autoinject()
export class CreateEntityButton {
  @bindable public buttonText: string = '';

  /**
   * disabled the user group selection (e.g. for entities which don't have an user group selection, because the user group is stored in their parent entity)
   */
  @bindable public noUserGroupSelection: boolean = false;

  @bindable public entityName: EntityName | null = null;

  /**
   * if this is true, it will be a floating-label-button
   */
  @bindable public floatingLabelButton: boolean = false;

  /**
   * class for the floating label button
   */
  @bindable public floatingLabelButtonIconClass: string | null = null;

  @bindable public buttonTestSelector: string | null = null;

  @bindable public dropdownTestSelector: string | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  private domElement: HTMLElement;
  protected dropdownArrowButtonElement: HTMLElement | null = null;
  protected floatingLabelButtonElement: HTMLElement | null = null;
  protected userGroupSelectionTooltipContent: TooltipContent | null = null;

  private forcedUserGroupId: string | null = null;
  private roleBasedPermissions: RoleBasedPermissions | null = null;
  private currentUser: User | null = null;
  protected userGroupsWhereUserCanCreateEntity: Array<UserGroup> = [];

  constructor(
    element: Element,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly computedValueService: ComputedValueService,
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
  }

  // Aurelia Lifecycle

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'general.entityCreationUserGroupId',
        (entityCreationUserGroupId) => {
          this.forcedUserGroupId = entityCreationUserGroupId;
          this.updateUserGroupsWhereUserCanCreateEntity();
        }
      )
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.UserGroup, () =>
      this.updateUserGroupsWhereUserCanCreateEntity()
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.bindCurrentUser((currentUser) => {
        this.currentUser = currentUser;
        this.updateUserGroupsWhereUserCanCreateEntity();
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: RoleBasedPermissionsComputer,
        computeData: {},
        callback: (roleBasedPermissions) => {
          this.roleBasedPermissions = roleBasedPermissions;
          this.updateUserGroupsWhereUserCanCreateEntity();
        }
      })
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // Entity Updater

  private updateUserGroupsWhereUserCanCreateEntity(): void {
    const allUserGroups = this.getAllUserGroupsWhereUserCanCreateEntity();

    if (this.forcedUserGroupId) {
      this.userGroupsWhereUserCanCreateEntity = allUserGroups.filter(
        (ug) => ug.id === this.forcedUserGroupId
      );
    } else {
      this.userGroupsWhereUserCanCreateEntity = allUserGroups;
    }
  }

  private getAllUserGroupsWhereUserCanCreateEntity(): Array<UserGroup> {
    const userGroups = this.entityManager.userGroupRepository.getAll();

    return userGroups.filter((userGroup) => {
      if (!this.currentUser || !this.roleBasedPermissions) {
        return false;
      }

      if (!this.entityName) {
        return this.roleBasedPermissions
          .inUserGroupId(userGroup.id)
          .getCanEditInUserGroup();
      }

      return this.roleBasedPermissions
        .inUserGroupId(userGroup.id)
        .getCanCreateEntity(this.entityName);
    });
  }

  // Handlers

  protected handleCreateClick(userGroup: UserGroup | null): void {
    if (this.userGroupSelectionTooltipContent) {
      this.userGroupSelectionTooltipContent.close();
    }

    DomEventHelper.fireEvent<CreateEntityClickedEvent>(this.domElement, {
      name: 'create-entity-clicked',
      detail: {
        userGroup: this.noUserGroupSelection ? null : userGroup
      }
    });
  }
}

export type CreateEntityClickedEvent = NamedCustomEvent<
  'create-entity-clicked',
  { userGroup: UserGroup | null }
>;
