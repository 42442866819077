import { DateUtils } from '../../../common/src/DateUtils';

export class DateRangeValueConverter {
  /**
   * @param {TDateRangeValueConverterDateRange} dateRange
   * @returns {string}
   */
  toView(dateRange) {
    if (!dateRange.fromIso) {
      return '';
    }

    /** @type {Array<string>} */
    const dateParts = [];
    dateParts.push(
      DateUtils.formatToDateWithHourMinuteString(dateRange.fromIso)
    );

    const dateToString = this._getDateToString(dateRange);
    if (dateToString) {
      dateParts.push(dateToString);
    }

    return dateParts.join(' - ');
  }

  /**
   * @param {TDateRangeValueConverterDateRange} dateRange
   * @returns {string|null}
   */
  _getDateToString(dateRange) {
    if (!dateRange.toIso || !dateRange.fromIso) {
      return null;
    }

    const fromDate = new Date(dateRange.fromIso);
    const toDate = new Date(dateRange.toIso);

    if (
      toDate.getDate() === fromDate.getDate() &&
      toDate.getMonth() === fromDate.getMonth() &&
      toDate.getFullYear() === fromDate.getFullYear()
    ) {
      return DateUtils.formatToHourMinuteString(toDate);
    } else {
      return DateUtils.formatToDateWithHourMinuteString(toDate);
    }
  }
}

/**
 * @typedef {Object} TDateRangeValueConverterDateRange
 * @property {string|null} fromIso
 * @property {string|null} toIso
 */
