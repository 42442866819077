import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FileChunkFileInfoResponse } from 'common/EndpointTypes/FileChunkUploadEndpointsHandler';
import { EventAggregatorPromiseHelper } from '../../classes/Promise/EventAggregatorPromiseHelper';
import { SocketService } from '../SocketService';
import { FileChunkUploader } from './FileChunkUploader';

@autoinject()
export class FileChunkUploadService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService
  ) {}

  /**
   * This functions try to use the `chunkedFileId`. If it fails for whatever reason (not found, no permission etc), a new one will be started.
   * So if you pass a chunkedFiledId it's not guaranteed the returned uploader is for that chunked file.
   */
  public async startUpload({
    fileData,
    chunkedFileId
  }: {
    fileData: Uint8Array;
    chunkedFileId: string | null;
  }): Promise<FileChunkUploader> {
    const info = await this.getInfoForUpload({ fileData, chunkedFileId });

    return new FileChunkUploader({
      eventAggregator: this.eventAggregator,
      socketService: this.socketService,
      fileData,
      chunkedFileId: info.fileId,
      missingChunks: info.missingChunks
    });
  }

  private async getInfoForUpload({
    fileData,
    chunkedFileId
  }: {
    fileData: Uint8Array;
    chunkedFileId: string | null;
  }): Promise<FileChunkFileInfoResponse & { success: true }> {
    if (chunkedFileId) {
      const info = await this.tryGettingUploadStatus(chunkedFileId);
      if (info) {
        return info;
      }
    }

    return this.sendStartUploadRequest(fileData);
  }

  /**
   * null will be returned if the status couldn't be retrieved
   */
  private async tryGettingUploadStatus(
    chunkedFileId: string
  ): Promise<(FileChunkFileInfoResponse & { success: true }) | null> {
    const data =
      await EventAggregatorPromiseHelper.createConnectedPromise<FileChunkFileInfoResponse>(
        this.eventAggregator,
        this.socketService.fileChunkUploadStatus({
          fileId: chunkedFileId
        })
      );

    if (data.success) {
      return data;
    }

    console.error(
      `[FileChunkUpload]: couldn't retrieve status for "${chunkedFileId}". reason: ${data.errorType}`
    );
    return null;
  }

  private async sendStartUploadRequest(
    fileData: Uint8Array
  ): Promise<FileChunkFileInfoResponse & { success: true }> {
    const data =
      await EventAggregatorPromiseHelper.createConnectedPromise<FileChunkFileInfoResponse>(
        this.eventAggregator,
        this.socketService.fileChunkUploadStart({
          fileSize: fileData.length
        })
      );

    if (!data.success) {
      throw new Error(
        `failed to start file chunk upload, reason: ${data.errorType}`
      );
    }

    return data;
  }
}
