/*
  THIS FILE IS AUTOMATICALLY CREATED BY A GENERATION SCRIPT.
  Manual changes will not be persisted.
*/

import {
  EntityManager,
  EntityManagerOptions
} from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManagerEntityTypesByEntityName } from './AppEntityManagerEntityTypesByEntityName';
import { EntityName } from './types';
import { EntityNameToRepository } from './EntityNameToRepository';
import { DefectRepository } from './Defect/DefectRepository';
import { DefectChangeLogEntryRepository } from './DefectChangeLogEntry/DefectChangeLogEntryRepository';
import { DefectCommentRepository } from './DefectComment/DefectCommentRepository';
import { EntityDashboardInfoRepository } from './EntityDashboardInfo/EntityDashboardInfoRepository';
import { EntryRepository } from './Entry/EntryRepository';
import { EntryToPersonRepository } from './EntryToPerson/EntryToPersonRepository';
import { EntryUsageStatisticRepository } from './EntryUsageStatistic/EntryUsageStatisticRepository';
import { ExternalUserCredentialRepository } from './ExternalUserCredential/ExternalUserCredentialRepository';
import { GeneralFileRepository } from './GeneralFile/GeneralFileRepository';
import { MapLayerRepository } from './MapLayer/MapLayerRepository';
import { NfcTokenRepository } from './NfcToken/NfcTokenRepository';
import { NfcTokenToPersonRepository } from './NfcTokenToPerson/NfcTokenToPersonRepository';
import { NfcTokenToThingRepository } from './NfcTokenToThing/NfcTokenToThingRepository';
import { PersonRepository } from './Person/PersonRepository';
import { PersonContactRepository } from './PersonContact/PersonContactRepository';
import { PersonToPersonRepository } from './PersonToPerson/PersonToPersonRepository';
import { PictureRepository } from './Picture/PictureRepository';
import { PictureFileRepository } from './PictureFile/PictureFileRepository';
import { PictureRevisionRepository } from './PictureRevision/PictureRevisionRepository';
import { ProcessConfigurationRepository } from './ProcessConfiguration/ProcessConfigurationRepository';
import { ProcessConfigurationActionStatusRepository } from './ProcessConfigurationActionStatus/ProcessConfigurationActionStatusRepository';
import { ProcessConfigurationAuthorizationTypeRepository } from './ProcessConfigurationAuthorizationType/ProcessConfigurationAuthorizationTypeRepository';
import { ProcessConfigurationCategoryRepository } from './ProcessConfigurationCategory/ProcessConfigurationCategoryRepository';
import { ProcessConfigurationDeviceRepository } from './ProcessConfigurationDevice/ProcessConfigurationDeviceRepository';
import { ProcessConfigurationDeviceExportRepository } from './ProcessConfigurationDeviceExport/ProcessConfigurationDeviceExportRepository';
import { ProcessConfigurationFollowUpAppointmentRepository } from './ProcessConfigurationFollowUpAppointment/ProcessConfigurationFollowUpAppointmentRepository';
import { ProcessConfigurationFormRepository } from './ProcessConfigurationForm/ProcessConfigurationFormRepository';
import { ProcessConfigurationFormSendTargetRepository } from './ProcessConfigurationFormSendTarget/ProcessConfigurationFormSendTargetRepository';
import { ProcessConfigurationMeasurePointTypeRepository } from './ProcessConfigurationMeasurePointType/ProcessConfigurationMeasurePointTypeRepository';
import { ProcessConfigurationStepRepository } from './ProcessConfigurationStep/ProcessConfigurationStepRepository';
import { ProcessConfigurationStepAutoAppointmentRepository } from './ProcessConfigurationStepAutoAppointment/ProcessConfigurationStepAutoAppointmentRepository';
import { ProcessConfigurationStepAutoFormRepository } from './ProcessConfigurationStepAutoForm/ProcessConfigurationStepAutoFormRepository';
import { ProcessConfigurationStepPositionRepository } from './ProcessConfigurationStepPosition/ProcessConfigurationStepPositionRepository';
import { ProcessTaskRepository } from './ProcessTask/ProcessTaskRepository';
import { ProcessTaskAppointmentRepository } from './ProcessTaskAppointment/ProcessTaskAppointmentRepository';
import { ProcessTaskAppointmentContactRepository } from './ProcessTaskAppointmentContact/ProcessTaskAppointmentContactRepository';
import { ProcessTaskAppointmentToProcessTaskDeviceRepository } from './ProcessTaskAppointmentToProcessTaskDevice/ProcessTaskAppointmentToProcessTaskDeviceRepository';
import { ProcessTaskAppointmentToProcessTaskPositionRepository } from './ProcessTaskAppointmentToProcessTaskPosition/ProcessTaskAppointmentToProcessTaskPositionRepository';
import { ProcessTaskAppointmentToUserRepository } from './ProcessTaskAppointmentToUser/ProcessTaskAppointmentToUserRepository';
import { ProcessTaskChecklistEntryRepository } from './ProcessTaskChecklistEntry/ProcessTaskChecklistEntryRepository';
import { ProcessTaskCommentRepository } from './ProcessTaskComment/ProcessTaskCommentRepository';
import { ProcessTaskDeviceRepository } from './ProcessTaskDevice/ProcessTaskDeviceRepository';
import { ProcessTaskGroupRepository } from './ProcessTaskGroup/ProcessTaskGroupRepository';
import { ProcessTaskGroupAuthorizationRepository } from './ProcessTaskGroupAuthorization/ProcessTaskGroupAuthorizationRepository';
import { ProcessTaskGroupFilterRepository } from './ProcessTaskGroupFilter/ProcessTaskGroupFilterRepository';
import { ProcessTaskInvoiceRepository } from './ProcessTaskInvoice/ProcessTaskInvoiceRepository';
import { ProcessTaskInvoiceToProcessTaskRepository } from './ProcessTaskInvoiceToProcessTask/ProcessTaskInvoiceToProcessTaskRepository';
import { ProcessTaskInvoiceToProcessTaskDeviceRepository } from './ProcessTaskInvoiceToProcessTaskDevice/ProcessTaskInvoiceToProcessTaskDeviceRepository';
import { ProcessTaskInvoiceToProcessTaskPositionRepository } from './ProcessTaskInvoiceToProcessTaskPosition/ProcessTaskInvoiceToProcessTaskPositionRepository';
import { ProcessTaskLogEntryRepository } from './ProcessTaskLogEntry/ProcessTaskLogEntryRepository';
import { ProcessTaskMeasurePointRepository } from './ProcessTaskMeasurePoint/ProcessTaskMeasurePointRepository';
import { ProcessTaskMeasurePointReadingRepository } from './ProcessTaskMeasurePointReading/ProcessTaskMeasurePointReadingRepository';
import { ProcessTaskMeasurePointToPictureRepository } from './ProcessTaskMeasurePointToPicture/ProcessTaskMeasurePointToPictureRepository';
import { ProcessTaskOfferRepository } from './ProcessTaskOffer/ProcessTaskOfferRepository';
import { ProcessTaskOfferToProcessTaskRepository } from './ProcessTaskOfferToProcessTask/ProcessTaskOfferToProcessTaskRepository';
import { ProcessTaskOfferToProcessTaskDeviceRepository } from './ProcessTaskOfferToProcessTaskDevice/ProcessTaskOfferToProcessTaskDeviceRepository';
import { ProcessTaskOfferToProcessTaskPositionRepository } from './ProcessTaskOfferToProcessTaskPosition/ProcessTaskOfferToProcessTaskPositionRepository';
import { ProcessTaskPositionRepository } from './ProcessTaskPosition/ProcessTaskPositionRepository';
import { ProcessTaskPositionDetailEntryRepository } from './ProcessTaskPositionDetailEntry/ProcessTaskPositionDetailEntryRepository';
import { ProcessTaskRecurringAppointmentRepository } from './ProcessTaskRecurringAppointment/ProcessTaskRecurringAppointmentRepository';
import { ProcessTaskToProjectRepository } from './ProcessTaskToProject/ProcessTaskToProjectRepository';
import { ProjectRepository } from './Project/ProjectRepository';
import { ProjectQuestionRepository } from './ProjectQuestion/ProjectQuestionRepository';
import { ProjectQuestionCatalogueRepository } from './ProjectQuestionCatalogue/ProjectQuestionCatalogueRepository';
import { ProjectQuestionCatalogueToQuestionSetRepository } from './ProjectQuestionCatalogueToQuestionSet/ProjectQuestionCatalogueToQuestionSetRepository';
import { ProjectQuestionCategoryRepository } from './ProjectQuestionCategory/ProjectQuestionCategoryRepository';
import { ProjectQuestionSetRepository } from './ProjectQuestionSet/ProjectQuestionSetRepository';
import { PropertyRepository } from './Property/PropertyRepository';
import { PropertyToPersonRepository } from './PropertyToPerson/PropertyToPersonRepository';
import { PropertyToProcessTaskPositionRepository } from './PropertyToProcessTaskPosition/PropertyToProcessTaskPositionRepository';
import { QuestionRepository } from './Question/QuestionRepository';
import { QuestionCatalogueRepository } from './QuestionCatalogue/QuestionCatalogueRepository';
import { QuestionCatalogueToQuestionSetRepository } from './QuestionCatalogueToQuestionSet/QuestionCatalogueToQuestionSetRepository';
import { QuestionCatalogueToThingRepository } from './QuestionCatalogueToThing/QuestionCatalogueToThingRepository';
import { QuestionCategoryRepository } from './QuestionCategory/QuestionCategoryRepository';
import { QuestionSetRepository } from './QuestionSet/QuestionSetRepository';
import { RegionRepository } from './Region/RegionRepository';
import { ReportRepository } from './Report/ReportRepository';
import { ReportTypeRepository } from './ReportType/ReportTypeRepository';
import { SharepointListColumnToThingMappingItemRepository } from './SharepointListColumnToThingMappingItem/SharepointListColumnToThingMappingItemRepository';
import { StructurePictureAreaRepository } from './StructurePictureArea/StructurePictureAreaRepository';
import { StructureTemplateRepository } from './StructureTemplate/StructureTemplateRepository';
import { StructureTemplateEntryRepository } from './StructureTemplateEntry/StructureTemplateEntryRepository';
import { StructureTemplateEntryGroupRepository } from './StructureTemplateEntryGroup/StructureTemplateEntryGroupRepository';
import { StructureTemplateEntryGroupToStructureTemplateEntryRepository } from './StructureTemplateEntryGroupToStructureTemplateEntry/StructureTemplateEntryGroupToStructureTemplateEntryRepository';
import { StructureTemplateEntryPropertyRepository } from './StructureTemplateEntryProperty/StructureTemplateEntryPropertyRepository';
import { StructureTemplateRatingCategoryRepository } from './StructureTemplateRatingCategory/StructureTemplateRatingCategoryRepository';
import { TagRepository } from './Tag/TagRepository';
import { TextBrickRepository } from './TextBrick/TextBrickRepository';
import { TextBrickTemplateRepository } from './TextBrickTemplate/TextBrickTemplateRepository';
import { ThingRepository } from './Thing/ThingRepository';
import { ThingAuthorizationRepository } from './ThingAuthorization/ThingAuthorizationRepository';
import { ThingGroupRepository } from './ThingGroup/ThingGroupRepository';
import { ThingSectionRepository } from './ThingSection/ThingSectionRepository';
import { ThingSectionConfigPropertyRepository } from './ThingSectionConfigProperty/ThingSectionConfigPropertyRepository';
import { ThingToPersonRepository } from './ThingToPerson/ThingToPersonRepository';
import { ThingTypeRepository } from './ThingType/ThingTypeRepository';
import { UserRepository } from './User/UserRepository';
import { UserCompanySettingRepository } from './UserCompanySetting/UserCompanySettingRepository';
import { UserDefinedEntityRepository } from './UserDefinedEntity/UserDefinedEntityRepository';
import { UserDefinedEntityConfigRepository } from './UserDefinedEntityConfig/UserDefinedEntityConfigRepository';
import { UserDefinedEntityConfigPropertyConfigRepository } from './UserDefinedEntityConfigPropertyConfig/UserDefinedEntityConfigPropertyConfigRepository';
import { UserGroupRepository } from './UserGroup/UserGroupRepository';
import { UserRoleRepository } from './UserRole/UserRoleRepository';
import { UserRoleToUserRepository } from './UserRoleToUser/UserRoleToUserRepository';
import { ValueCalculationConfigRepository } from './ValueCalculationConfig/ValueCalculationConfigRepository';
import { ValueCalculationResultRepository } from './ValueCalculationResult/ValueCalculationResultRepository';

export class AppEntityManagerEntityRepositories extends EntityManager<
  AppSynchronizationEnvironmentTypes,
  AppEntityManagerEntityTypesByEntityName,
  EntityNameToRepository
> {
  constructor(
    options: EntityManagerOptions<
      AppSynchronizationEnvironmentTypes,
      AppEntityManagerEntityTypesByEntityName,
      EntityNameToRepository
    >
  ) {
    super(options);
  }

  public get defectRepository(): DefectRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Defect);
  }

  public get defectChangeLogEntryRepository(): DefectChangeLogEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.DefectChangeLogEntry
    );
  }

  public get defectCommentRepository(): DefectCommentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.DefectComment
    );
  }

  public get entityDashboardInfoRepository(): EntityDashboardInfoRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.EntityDashboardInfo
    );
  }

  public get entryRepository(): EntryRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Entry);
  }

  public get entryToPersonRepository(): EntryToPersonRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.EntryToPerson
    );
  }

  public get entryUsageStatisticRepository(): EntryUsageStatisticRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.EntryUsageStatistic
    );
  }

  public get externalUserCredentialRepository(): ExternalUserCredentialRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ExternalUserCredential
    );
  }

  public get generalFileRepository(): GeneralFileRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.GeneralFile
    );
  }

  public get mapLayerRepository(): MapLayerRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.MapLayer);
  }

  public get nfcTokenRepository(): NfcTokenRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.NfcToken);
  }

  public get nfcTokenToPersonRepository(): NfcTokenToPersonRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.NfcTokenToPerson
    );
  }

  public get nfcTokenToThingRepository(): NfcTokenToThingRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.NfcTokenToThing
    );
  }

  public get personRepository(): PersonRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Person);
  }

  public get personContactRepository(): PersonContactRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PersonContact
    );
  }

  public get personToPersonRepository(): PersonToPersonRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PersonToPerson
    );
  }

  public get pictureRepository(): PictureRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Picture);
  }

  public get pictureFileRepository(): PictureFileRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PictureFile
    );
  }

  public get pictureRevisionRepository(): PictureRevisionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PictureRevision
    );
  }

  public get processConfigurationRepository(): ProcessConfigurationRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfiguration
    );
  }

  public get processConfigurationActionStatusRepository(): ProcessConfigurationActionStatusRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationActionStatus
    );
  }

  public get processConfigurationAuthorizationTypeRepository(): ProcessConfigurationAuthorizationTypeRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationAuthorizationType
    );
  }

  public get processConfigurationCategoryRepository(): ProcessConfigurationCategoryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationCategory
    );
  }

  public get processConfigurationDeviceRepository(): ProcessConfigurationDeviceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationDevice
    );
  }

  public get processConfigurationDeviceExportRepository(): ProcessConfigurationDeviceExportRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationDeviceExport
    );
  }

  public get processConfigurationFollowUpAppointmentRepository(): ProcessConfigurationFollowUpAppointmentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationFollowUpAppointment
    );
  }

  public get processConfigurationFormRepository(): ProcessConfigurationFormRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationForm
    );
  }

  public get processConfigurationFormSendTargetRepository(): ProcessConfigurationFormSendTargetRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationFormSendTarget
    );
  }

  public get processConfigurationMeasurePointTypeRepository(): ProcessConfigurationMeasurePointTypeRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationMeasurePointType
    );
  }

  public get processConfigurationStepRepository(): ProcessConfigurationStepRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationStep
    );
  }

  public get processConfigurationStepAutoAppointmentRepository(): ProcessConfigurationStepAutoAppointmentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationStepAutoAppointment
    );
  }

  public get processConfigurationStepAutoFormRepository(): ProcessConfigurationStepAutoFormRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationStepAutoForm
    );
  }

  public get processConfigurationStepPositionRepository(): ProcessConfigurationStepPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessConfigurationStepPosition
    );
  }

  public get processTaskRepository(): ProcessTaskRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTask
    );
  }

  public get processTaskAppointmentRepository(): ProcessTaskAppointmentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskAppointment
    );
  }

  public get processTaskAppointmentContactRepository(): ProcessTaskAppointmentContactRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskAppointmentContact
    );
  }

  public get processTaskAppointmentToProcessTaskDeviceRepository(): ProcessTaskAppointmentToProcessTaskDeviceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskAppointmentToProcessTaskDevice
    );
  }

  public get processTaskAppointmentToProcessTaskPositionRepository(): ProcessTaskAppointmentToProcessTaskPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskAppointmentToProcessTaskPosition
    );
  }

  public get processTaskAppointmentToUserRepository(): ProcessTaskAppointmentToUserRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskAppointmentToUser
    );
  }

  public get processTaskChecklistEntryRepository(): ProcessTaskChecklistEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskChecklistEntry
    );
  }

  public get processTaskCommentRepository(): ProcessTaskCommentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskComment
    );
  }

  public get processTaskDeviceRepository(): ProcessTaskDeviceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskDevice
    );
  }

  public get processTaskGroupRepository(): ProcessTaskGroupRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskGroup
    );
  }

  public get processTaskGroupAuthorizationRepository(): ProcessTaskGroupAuthorizationRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskGroupAuthorization
    );
  }

  public get processTaskGroupFilterRepository(): ProcessTaskGroupFilterRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskGroupFilter
    );
  }

  public get processTaskInvoiceRepository(): ProcessTaskInvoiceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskInvoice
    );
  }

  public get processTaskInvoiceToProcessTaskRepository(): ProcessTaskInvoiceToProcessTaskRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskInvoiceToProcessTask
    );
  }

  public get processTaskInvoiceToProcessTaskDeviceRepository(): ProcessTaskInvoiceToProcessTaskDeviceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskInvoiceToProcessTaskDevice
    );
  }

  public get processTaskInvoiceToProcessTaskPositionRepository(): ProcessTaskInvoiceToProcessTaskPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskInvoiceToProcessTaskPosition
    );
  }

  public get processTaskLogEntryRepository(): ProcessTaskLogEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskLogEntry
    );
  }

  public get processTaskMeasurePointRepository(): ProcessTaskMeasurePointRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskMeasurePoint
    );
  }

  public get processTaskMeasurePointReadingRepository(): ProcessTaskMeasurePointReadingRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskMeasurePointReading
    );
  }

  public get processTaskMeasurePointToPictureRepository(): ProcessTaskMeasurePointToPictureRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskMeasurePointToPicture
    );
  }

  public get processTaskOfferRepository(): ProcessTaskOfferRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskOffer
    );
  }

  public get processTaskOfferToProcessTaskRepository(): ProcessTaskOfferToProcessTaskRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskOfferToProcessTask
    );
  }

  public get processTaskOfferToProcessTaskDeviceRepository(): ProcessTaskOfferToProcessTaskDeviceRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskOfferToProcessTaskDevice
    );
  }

  public get processTaskOfferToProcessTaskPositionRepository(): ProcessTaskOfferToProcessTaskPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskOfferToProcessTaskPosition
    );
  }

  public get processTaskPositionRepository(): ProcessTaskPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskPosition
    );
  }

  public get processTaskPositionDetailEntryRepository(): ProcessTaskPositionDetailEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskPositionDetailEntry
    );
  }

  public get processTaskRecurringAppointmentRepository(): ProcessTaskRecurringAppointmentRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskRecurringAppointment
    );
  }

  public get processTaskToProjectRepository(): ProcessTaskToProjectRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProcessTaskToProject
    );
  }

  public get projectRepository(): ProjectRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Project);
  }

  public get projectQuestionRepository(): ProjectQuestionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProjectQuestion
    );
  }

  public get projectQuestionCatalogueRepository(): ProjectQuestionCatalogueRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProjectQuestionCatalogue
    );
  }

  public get projectQuestionCatalogueToQuestionSetRepository(): ProjectQuestionCatalogueToQuestionSetRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProjectQuestionCatalogueToQuestionSet
    );
  }

  public get projectQuestionCategoryRepository(): ProjectQuestionCategoryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProjectQuestionCategory
    );
  }

  public get projectQuestionSetRepository(): ProjectQuestionSetRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ProjectQuestionSet
    );
  }

  public get propertyRepository(): PropertyRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Property);
  }

  public get propertyToPersonRepository(): PropertyToPersonRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PropertyToPerson
    );
  }

  public get propertyToProcessTaskPositionRepository(): PropertyToProcessTaskPositionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.PropertyToProcessTaskPosition
    );
  }

  public get questionRepository(): QuestionRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Question);
  }

  public get questionCatalogueRepository(): QuestionCatalogueRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.QuestionCatalogue
    );
  }

  public get questionCatalogueToQuestionSetRepository(): QuestionCatalogueToQuestionSetRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.QuestionCatalogueToQuestionSet
    );
  }

  public get questionCatalogueToThingRepository(): QuestionCatalogueToThingRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.QuestionCatalogueToThing
    );
  }

  public get questionCategoryRepository(): QuestionCategoryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.QuestionCategory
    );
  }

  public get questionSetRepository(): QuestionSetRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.QuestionSet
    );
  }

  public get regionRepository(): RegionRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Region);
  }

  public get reportRepository(): ReportRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Report);
  }

  public get reportTypeRepository(): ReportTypeRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ReportType
    );
  }

  public get sharepointListColumnToThingMappingItemRepository(): SharepointListColumnToThingMappingItemRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.SharepointListColumnToThingMappingItem
    );
  }

  public get structurePictureAreaRepository(): StructurePictureAreaRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructurePictureArea
    );
  }

  public get structureTemplateRepository(): StructureTemplateRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplate
    );
  }

  public get structureTemplateEntryRepository(): StructureTemplateEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplateEntry
    );
  }

  public get structureTemplateEntryGroupRepository(): StructureTemplateEntryGroupRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplateEntryGroup
    );
  }

  public get structureTemplateEntryGroupToStructureTemplateEntryRepository(): StructureTemplateEntryGroupToStructureTemplateEntryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplateEntryGroupToStructureTemplateEntry
    );
  }

  public get structureTemplateEntryPropertyRepository(): StructureTemplateEntryPropertyRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplateEntryProperty
    );
  }

  public get structureTemplateRatingCategoryRepository(): StructureTemplateRatingCategoryRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.StructureTemplateRatingCategory
    );
  }

  public get tagRepository(): TagRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Tag);
  }

  public get textBrickRepository(): TextBrickRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.TextBrick);
  }

  public get textBrickTemplateRepository(): TextBrickTemplateRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.TextBrickTemplate
    );
  }

  public get thingRepository(): ThingRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.Thing);
  }

  public get thingAuthorizationRepository(): ThingAuthorizationRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ThingAuthorization
    );
  }

  public get thingGroupRepository(): ThingGroupRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ThingGroup
    );
  }

  public get thingSectionRepository(): ThingSectionRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ThingSection
    );
  }

  public get thingSectionConfigPropertyRepository(): ThingSectionConfigPropertyRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ThingSectionConfigProperty
    );
  }

  public get thingToPersonRepository(): ThingToPersonRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ThingToPerson
    );
  }

  public get thingTypeRepository(): ThingTypeRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.ThingType);
  }

  public get userRepository(): UserRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.User);
  }

  public get userCompanySettingRepository(): UserCompanySettingRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.UserCompanySetting
    );
  }

  public get userDefinedEntityRepository(): UserDefinedEntityRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.UserDefinedEntity
    );
  }

  public get userDefinedEntityConfigRepository(): UserDefinedEntityConfigRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.UserDefinedEntityConfig
    );
  }

  public get userDefinedEntityConfigPropertyConfigRepository(): UserDefinedEntityConfigPropertyConfigRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.UserDefinedEntityConfigPropertyConfig
    );
  }

  public get userGroupRepository(): UserGroupRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.UserGroup);
  }

  public get userRoleRepository(): UserRoleRepository {
    return this.entityRepositoryContainer.getByEntityName(EntityName.UserRole);
  }

  public get userRoleToUserRepository(): UserRoleToUserRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.UserRoleToUser
    );
  }

  public get valueCalculationConfigRepository(): ValueCalculationConfigRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ValueCalculationConfig
    );
  }

  public get valueCalculationResultRepository(): ValueCalculationResultRepository {
    return this.entityRepositoryContainer.getByEntityName(
      EntityName.ValueCalculationResult
    );
  }
}
