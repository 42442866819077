import _ from 'lodash';
import { Utils } from '../../../Utils/Utils';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { TextBrickTemplate } from './types';

export class TextBrickTemplateRepository extends AppEntityRepository<EntityName.TextBrickTemplate> {
  public getByUserGroupIdAndPath(
    userGroupId: string,
    path: Array<string>
  ): Array<TextBrickTemplate> {
    return this.getAll().filter((tBT) => {
      return tBT.ownerUserGroupId === userGroupId && _.isEqual(tBT.path, path);
    });
  }

  public getByUserGroupIdAndPathWithWildcards(
    userGroupId: string,
    path: Array<string>
  ): Array<TextBrickTemplate> {
    return this.getAll().filter((tBT) => {
      return (
        tBT.ownerUserGroupId === userGroupId &&
        Utils.arraysAreEqualWithWildcards(tBT.path, path)
      );
    });
  }
}
