import { EntityRepositoryHooks } from '@record-it-npm/synchro-client';
import { autoinject } from 'aurelia-dependency-injection';
import { Disposable } from '../../Utils/DisposableContainer';
import { AppEntityManager } from './AppEntityManager';
import { AppEntityManagerEntityTypesByEntityName } from './AppEntityManagerEntityTypesByEntityName';
import { EntityName } from './types';

/**
 * a service which will register listeners for the removal of entities
 */
@autoinject()
export class EntityRemovedRegisterService {
  constructor(private readonly entityManager: AppEntityManager) {}

  /**
   * Callback gets called when either the entity got deleted or a local entity got removed.
   * Callback doesn't get called when a local entity gets removed but still is in the synchronization.
   */
  public registerCascadingListener<TEntityName extends EntityName>(
    entityName: TEntityName,
    callback: (
      entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    ) => void
  ): Disposable {
    const disposable1 = this.entityManager.entityRepositoryContainer
      .getByEntityName(entityName)
      .registerHooks({
        afterEntityDeleted: callback,
        afterEntityRemovedLocally: (entity) => {
          if (
            entity.onlyLocal &&
            !this.entityManager.entitySynchronization.entityIsInTheQueue(
              entityName,
              entity.id
            )
          ) {
            callback(entity);
          }
        }
      } as EntityRepositoryHooks<
        AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
      >);
    const disposable2 = this.entityManager.deletionCascading.registerHooks({
      deletionCascaded: (entityWithEntityName) => {
        if (entityWithEntityName.entityName === entityName) {
          callback(entityWithEntityName.entity);
        }
      }
    });

    return {
      dispose: () => {
        disposable1.dispose();
        disposable2.dispose();
      }
    };
  }
}
