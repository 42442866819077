import { bindable } from 'aurelia-framework';

import { DefectChangeLogEntry } from '../../classes/EntityManager/entities/DefectChangeLogEntry/types';
import { DefectComment } from '../../classes/EntityManager/entities/DefectComment/types';
import { EntityName } from '../../classes/EntityManager/entities/types';

export class DefectEntryGroup {
  @bindable public defectEntryGroup: GroupedDefectEntries | null = null;

  protected EntityName = EntityName;
}

/**
 * A list of comments & changeLogEntries that has been grouped on senderID and date
 * from all comments & changeLogEntries of a defect.
 */
type GroupedDefectEntriesBase<TEntityName extends EntityName, TEntity> = {
  type: TEntityName;
  senderId: string;
  date: string;
  createdAt: number;
  entries: Array<TEntity>;
};

export type GroupedDefectComments = GroupedDefectEntriesBase<
  EntityName.DefectComment,
  DefectComment
>;
export type GroupedDefectChangeLogEntries = GroupedDefectEntriesBase<
  EntityName.DefectChangeLogEntry,
  DefectChangeLogEntry
>;
export type GroupedDefectEntries =
  | GroupedDefectComments
  | GroupedDefectChangeLogEntries;
