import { computedFrom } from 'aurelia-framework';

export class Tab {
  private readonly element: HTMLElement;
  private readonly getScrollPosition: GetScrollPosition;
  private readonly setScrollPosition: SetScrollPosition;
  private readonly internalName: string;
  private readonly internalTitle: string;
  private readonly preserveScrolling: boolean = false;

  private internalDisabled: boolean = false;
  private shown: boolean = true;
  private preservedScrollPosition: number | null = null;

  constructor({
    element,
    getScrollPosition,
    setScrollPosition
  }: {
    element: HTMLElement;
    getScrollPosition: GetScrollPosition;
    setScrollPosition: SetScrollPosition;
  }) {
    this.element = element;
    this.getScrollPosition = getScrollPosition;
    this.setScrollPosition = setScrollPosition;

    this.internalName = element.getAttribute('data-tab') || 'missing name';
    this.internalTitle =
      element.getAttribute('data-tab-title') || 'missing title';
    this.preserveScrolling = element.hasAttribute(
      'data-tab-preserve-scrolling'
    );
  }

  @computedFrom('internalName')
  public get name(): string {
    return this.internalName;
  }

  @computedFrom('internalTitle')
  public get title(): string {
    return this.internalTitle;
  }

  @computedFrom('internalDisabled')
  public get disabled(): boolean {
    return this.internalDisabled;
  }

  /**
   * only use this function in the TabCollection, so the disabled state can persist
   */
  public setDisabled(disabled: boolean): void {
    this.internalDisabled = disabled;
  }

  public show(): void {
    this.element.style.display = '';
    this.shown = true;

    this.setScrollPosition(this.preservedScrollPosition ?? 0);
  }

  public preHide(): void {
    if (this.preserveScrolling) {
      this.preservedScrollPosition = this.getScrollPosition();
    } else {
      this.preservedScrollPosition = null;
    }
  }

  public hide(): void {
    this.element.style.display = 'none';
    this.shown = false;
  }

  public isShown(): boolean {
    return this.shown;
  }
}

export type GetScrollPosition = () => number;
export type SetScrollPosition = (position: number) => void;
