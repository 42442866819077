import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructureTemplateEntryGroupEntityInfo } from 'common/Types/Entities/StructureTemplateEntryGroup/createStructureTemplateEntryGroupEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructureTemplateEntryGroup } from './types';

export const structureTemplateEntryGroupEntityInfo =
  createStructureTemplateEntryGroupEntityInfo<StructureTemplateEntryGroup>({
    additionalFieldInfos:
      EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
  });
