import { DataStorageHelper } from '../../../DataStorageHelper/DataStorageHelper';
import { DisposableContainer } from '../../../Utils/DisposableContainer';
import { Utils } from '../../../Utils/Utils';
import { OriginalIdUtils } from '../../utils/OriginalIdUtils/OriginalIdUtils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { Project } from './types';

export class ProjectMetadataManager {
  private static PROJECTS_METADATA_DATABASE_KEY =
    'ProjectsMetadataManager::projectsMetadata';

  private readonly disposableContainer = new DisposableContainer();
  private projectsMetadata: Array<ProjectMetadata> = [];

  private initialized = false;

  private saveProjectsMetadataToDatabaseRateLimited = Utils.rateLimitFunction(
    this.saveProjectsMetadataToDatabase.bind(this),
    250
  );

  constructor(private readonly entityManager: AppEntityManager) {}

  public async init(): Promise<void> {
    this.disposableContainer.add(
      this.entityManager.entitySynchronization.registerHooks({
        entityIdUpgraded: (entityWithEntityName) => {
          if (entityWithEntityName.entityName === EntityName.Project) {
            this.handleProjectIdUpgraded(
              entityWithEntityName.entity as Project
            );
          }
        }
      })
    );

    await this.loadProjectsMetadataFromDatabase();

    this.initialized = true;
  }

  public destroy(): void {
    this.disposableContainer.disposeAll();
  }

  public setJoinedProjectOn(
    projectId: string,
    joinedProjectOn = Date.now()
  ): void {
    this.assertIsInitialized();

    if (this.getProjectMetadata(projectId)?.joinedProjectOn) return;

    const projectMetadata = this.getOrCreateProjectMetadata(projectId);
    projectMetadata.joinedProjectOn = joinedProjectOn;
    projectMetadata.leftProjectOn = null;
    this.saveProjectsMetadataToDatabaseRateLimited();
  }

  public setLeftProjectOn(projectId: string, leftProjectOn = Date.now()): void {
    this.assertIsInitialized();

    const projectMetadata = this.getOrCreateProjectMetadata(projectId);
    projectMetadata.joinedProjectOn = null;
    projectMetadata.leftProjectOn = leftProjectOn;
    this.saveProjectsMetadataToDatabaseRateLimited();
  }

  public setLastActualizedAt(
    projectId: string,
    lastActualizedAt = Date.now()
  ): void {
    this.assertIsInitialized();

    const projectMetadata = this.getOrCreateProjectMetadata(projectId);
    projectMetadata.lastActualizedAt = lastActualizedAt;
    this.saveProjectsMetadataToDatabaseRateLimited();
  }

  public getProjectIdsLeftBeforeTimestamp(
    leftBeforeTimestamp: number
  ): Array<string> {
    this.assertIsInitialized();
    return this.projectsMetadata
      .filter((projectMetadata) => {
        return (
          projectMetadata.leftProjectOn &&
          projectMetadata.leftProjectOn < leftBeforeTimestamp
        );
      })
      .map((metadata) => metadata.projectId);
  }

  public projectWasActualizedAfterJoining(projectId: string): boolean {
    const metadata = this.getProjectMetadata(projectId);
    if (!metadata || !metadata.joinedProjectOn || !metadata.lastActualizedAt) {
      return false;
    }

    return metadata.lastActualizedAt >= metadata.joinedProjectOn;
  }

  public hasMetadata(projectId: string): boolean {
    this.assertIsInitialized();
    return !!this.projectsMetadata.find((projectMetadataItem) => {
      return projectMetadataItem.projectId === projectId;
    });
  }

  public getProjectMetadata(projectId: string): ProjectMetadata | null {
    this.assertIsInitialized();
    return (
      this.projectsMetadata.find(
        (metadata) => metadata.projectId === projectId
      ) ?? null
    );
  }

  public removeMetadata(projectId: string): void {
    this.assertIsInitialized();
    const index = this.projectsMetadata.findIndex(
      (projectMetadata) => projectMetadata.projectId === projectId
    );
    if (index >= 0) {
      this.projectsMetadata.splice(index, 1);
      this.saveProjectsMetadataToDatabaseRateLimited();
    }
  }

  private handleProjectIdUpgraded(project: Project): void {
    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(project);

    for (const originalId of originalIds) {
      for (const metaData of this.projectsMetadata) {
        if (metaData.projectId !== originalId) {
          continue;
        }

        metaData.projectId = project.id;
        this.saveProjectsMetadataToDatabaseRateLimited();
      }
    }
  }

  private async loadProjectsMetadataFromDatabase(): Promise<void> {
    const projectsMetadata: Array<ProjectMetadata> =
      (await DataStorageHelper.getItem(
        ProjectMetadataManager.PROJECTS_METADATA_DATABASE_KEY
      )) ?? [];

    for (const metaData of projectsMetadata) {
      const originalIdProject =
        this.entityManager.projectRepository.getByOriginalId(
          metaData.projectId
        );

      // if there is an originalIdProject, the id must have been upgraded and we need to track the project by the new id then
      if (originalIdProject) {
        metaData.projectId = originalIdProject.id;
      }
    }

    this.projectsMetadata = projectsMetadata;
  }

  private saveProjectsMetadataToDatabase(): Promise<void> {
    return DataStorageHelper.setItem(
      ProjectMetadataManager.PROJECTS_METADATA_DATABASE_KEY,
      this.projectsMetadata
    );
  }

  private getOrCreateProjectMetadata(projectId: string): ProjectMetadata {
    const projectMetadata = this.getProjectMetadata(projectId);
    if (projectMetadata) {
      return projectMetadata;
    } else {
      return this.createProjectMetadata(projectId);
    }
  }

  private createProjectMetadata(projectId: string): ProjectMetadata {
    const newProjectMetadata = {
      projectId: projectId
    };
    this.projectsMetadata.push(newProjectMetadata);
    this.saveProjectsMetadataToDatabaseRateLimited();
    return newProjectMetadata;
  }

  private assertIsInitialized(): void {
    if (!this.initialized) {
      throw new Error(
        "can't use the ProjectsMetadataManager before it is initialized"
      );
    }
  }
}

/* Attention: project metadata gets deleted when local project files are deleted */

export type ProjectMetadata = {
  projectId: string;
  joinedProjectOn?: number | null;
  leftProjectOn?: number | null;

  /**
   * Last actualized as in the project being used as an relative synchronization entity.
   * If this date is after the joinedProjectOn, you can be sure the project data is locally available.
   */
  lastActualizedAt?: number | null;
};
