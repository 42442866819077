import { z } from 'zod';

import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties, SetPropertiesToType } from '../../utilities';
import { DateType, IdType } from '../Base/types';
import { BaseEntity } from '../Base/BaseEntity';

type ValueCalculationConfigBaseData<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    name: string;
    calculationExpression: string;
    colorCodeConfig: ValueCalculationConfigColorConfig | null;
    showInEntityOverview: boolean;
  };

type ValueCalculationConfigIdFields<TId extends IdType> =
  UserGroupSubEntity<TId> & {
    ownerThingId: TId;
    reportTypeId: TId | null;
    thingTypeId: TId;
  };

type ValueCalculationConfigDiscriminatorFields<
  TType extends ValueCalculationConfigType
> = {
  type: TType;
};

type EntitySpecificValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TEntityIds extends keyof ValueCalculationConfigIdFields<TId>,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  >,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<TId, TDate>,
  TDiscriminatorField extends ValueCalculationConfigType
> = Omit<
  OptionalProperties<
    ValueCalculationConfigBaseData<TId, TDate>,
    TOptionalBaseDataProperties
  >,
  TPropertiesToRemove
> &
  Pick<
    ValueCalculationConfigIdFields<TId>,
    Exclude<TEntityIds | 'ownerUserGroupId', TPropertiesToRemove>
  > &
  Partial<
    SetPropertiesToType<
      Omit<
        ValueCalculationConfigIdFields<TId>,
        TEntityIds | 'ownerUserGroupId' | TPropertiesToRemove
      >,
      null
    >
  > &
  ValueCalculationConfigDiscriminatorFields<TDiscriminatorField>;

export type ProjectValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  > = never,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<
    TId,
    TDate
  > = never
> = EntitySpecificValueCalculationConfigDto<
  TId,
  TDate,
  'ownerThingId' | 'reportTypeId',
  TOptionalBaseDataProperties,
  TPropertiesToRemove,
  ValueCalculationConfigType.PROJECT
>;

export type ThingValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  > = never,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<
    TId,
    TDate
  > = never
> = EntitySpecificValueCalculationConfigDto<
  TId,
  TDate,
  'ownerThingId',
  TOptionalBaseDataProperties,
  TPropertiesToRemove,
  ValueCalculationConfigType.THING
>;

export type ThingTypeProjectValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  > = never,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<
    TId,
    TDate
  > = never
> = EntitySpecificValueCalculationConfigDto<
  TId,
  TDate,
  'thingTypeId' | 'reportTypeId',
  TOptionalBaseDataProperties,
  TPropertiesToRemove,
  ValueCalculationConfigType.PROJECT
>;

export type ThingTypeThingValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  > = never,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<
    TId,
    TDate
  > = never
> = EntitySpecificValueCalculationConfigDto<
  TId,
  TDate,
  'thingTypeId',
  TOptionalBaseDataProperties,
  TPropertiesToRemove,
  ValueCalculationConfigType.THING
>;

export type ValueCalculationConfigDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalBaseDataProperties extends keyof ValueCalculationConfigBaseData<
    TId,
    Date
  > = never,
  TPropertiesToRemove extends keyof ValueCalculationConfigBaseData<
    TId,
    TDate
  > = never
> =
  | ThingValueCalculationConfigDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TPropertiesToRemove
    >
  | ProjectValueCalculationConfigDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TPropertiesToRemove
    >
  | ThingTypeThingValueCalculationConfigDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TPropertiesToRemove
    >
  | ThingTypeProjectValueCalculationConfigDto<
      TId,
      TDate,
      TOptionalBaseDataProperties,
      TPropertiesToRemove
    >;

export enum ValueCalculationConfigType {
  PROJECT = 'project',
  THING = 'thing'
}

export const valueCalculationConfigColorConfigSchema = z.strictObject({
  colorConfigs: z.array(
    z.strictObject({
      color: z.string(),
      condition: z.string()
    })
  )
});

export type ValueCalculationConfigColorConfig = z.infer<
  typeof valueCalculationConfigColorConfigSchema
>;
