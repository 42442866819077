import { autoinject, bindable } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * TODO: make this more beautiful
 * currently it's only used by admins, but this needs a better design if normal users will see this
 *
 * @event value-changed triggered whenever the user changes the color
 */
@autoinject()
export class ColorInput {
  @bindable public label: string | null = null;

  @bindable public value: string | null = null;

  @bindable public enabled = false;

  protected readonly element: HTMLElement;
  protected readonly hiddenColorInput: HTMLElement | null = null;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  private sendValueChangedEvent(): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<ValueChangedEvent>(this.element, {
        name: 'value-changed',
        detail: null
      });
    });
  }

  protected handleTextInputValueChanged(): void {
    this.sendValueChangedEvent();
  }

  protected handleColorInputValueChanged(event: {
    target: HTMLInputElement;
  }): void {
    this.value = event.target.value;
    this.sendValueChangedEvent();
  }
}

export type ValueChangedEvent = NamedCustomEvent<'value-changed', null>;
