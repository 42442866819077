import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

@autoinject()
export class UserGroupSelect {
  @bindable public userGroupId: string | null = null;

  @bindable public enabled = false;

  @bindable public nullOption: string;

  @bindable public label: string | null = null;

  @bindable public doNotAllowNullValue = false;

  protected availableUserGroups: Array<UserGroup> = [];

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    i18n: I18N
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.nullOption = i18n.tr(
      'inputComponents.userGroupSelect.defaultNullOption'
    ) as string;
  }

  // ********** Aurelia Lifecycle **********

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateAvailableUserGroups.bind(this)
    );
    this.updateAvailableUserGroups();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ********** Handlers **********

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent<UserGroupSelectChangedEvent>(this.domElement, {
      name: 'user-group-select-changed',
      detail: {
        userGroupId: this.userGroupId
      }
    });
  }

  // ********** Private Methods **********

  private updateAvailableUserGroups(): void {
    this.availableUserGroups = this.entityManager.userGroupRepository.getAll();
  }
}

export type UserGroupSelectChangedEvent = NamedCustomEvent<
  'user-group-select-changed',
  {
    userGroupId: string | null;
  }
>;
