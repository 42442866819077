import { autoinject } from 'aurelia-framework';
import { ProcessTaskLoggingService } from '../../../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../AppEntityManager';
import { CurrentUserService } from '../User/CurrentUserService';
import { ProcessTaskGroup } from './types';

@autoinject()
export class ProcessTaskGroupAssigneeService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly currentUserService: CurrentUserService
  ) {}

  /**
   * the properties which are edited here have to be kept in sync with ProcessTaskGroupPermissionsHandle.canSetAssignee
   */
  public setAssignee({
    processTaskGroup,
    assigneeData
  }: SetAssigneeOptions): void {
    const dataToSet = this.getProcessTaskGroupDataToSet(assigneeData);

    if (
      processTaskGroup.assigneeUserId === dataToSet.assigneeUserId &&
      processTaskGroup.assigneeHint === dataToSet.assigneeHint &&
      processTaskGroup.assignedFromUserId === dataToSet.assignedFromUserId &&
      processTaskGroup.assignedProcessTaskName ===
        dataToSet.assignedProcessTaskName
    ) {
      return;
    }

    const oldAssigneeUserId = processTaskGroup.assigneeUserId;
    const oldAssigneeHint = processTaskGroup.assigneeHint;
    const oldAssignedProcessTaskName = processTaskGroup.assignedProcessTaskName;
    processTaskGroup.assigneeUserId = dataToSet.assigneeUserId;
    processTaskGroup.assigneeHint = dataToSet.assigneeHint;
    processTaskGroup.assignedFromUserId = dataToSet.assignedFromUserId;
    processTaskGroup.assignedProcessTaskName =
      dataToSet.assignedProcessTaskName;

    if (oldAssigneeHint && oldAssigneeUserId !== dataToSet.assigneeUserId) {
      processTaskGroup.previousAssigneeHint = oldAssigneeHint;
    }

    this.entityManager.processTaskGroupRepository.update(processTaskGroup);

    const processTasks =
      this.entityManager.processTaskRepository.getByProcessTaskGroupId(
        processTaskGroup.id
      );
    for (const processTask of processTasks) {
      void this.processTaskLoggingService.logAssigneeChanged(processTask, {
        before: {
          assigneeHint: oldAssigneeHint,
          assigneeUserId: oldAssigneeUserId,
          assignedProcessTaskName: oldAssignedProcessTaskName
        },
        after: {
          assigneeHint: processTaskGroup.assigneeHint,
          assigneeUserId: processTaskGroup.assigneeUserId,
          assignedProcessTaskName: processTaskGroup.assignedProcessTaskName
        }
      });
    }
  }

  private getProcessTaskGroupDataToSet(
    assigneeData: AssigneeData | null
  ): ProcessTaskGroupDataToSet {
    if (!assigneeData) {
      return {
        assignedFromUserId: null,
        assigneeHint: null,
        assigneeUserId: null,
        assignedProcessTaskName: null
      };
    }

    const user = this.currentUserService.getRequiredCurrentUser();

    return {
      assigneeUserId: assigneeData.userId,
      assigneeHint: assigneeData.hint,
      assignedFromUserId: user.id,
      assignedProcessTaskName: assigneeData.assignedProcessTaskName
    };
  }
}

export type SetAssigneeOptions = {
  processTaskGroup: ProcessTaskGroup;
  assigneeData: AssigneeData | null;
};

export type AssigneeData = {
  userId: string;
  hint: string | null;
  assignedProcessTaskName: string | null;
};

type ProcessTaskGroupDataToSet = {
  assigneeUserId: string | null;
  assigneeHint: string | null;
  assignedFromUserId: string | null;
  assignedProcessTaskName: string | null;
};
