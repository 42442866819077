export class NumberToStringValueConverter {
  /**
   *
   * @param value
   * @returns {(string|null)}
   */
  toView(value) {
    if (value == null) {
      return null;
    }

    return '' + value;
  }

  /**
   *
   * @param {string} value
   * @returns {(number|null)}
   */
  fromView(value) {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? null : numberValue;
  }
}
