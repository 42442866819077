/* global Msvg */

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DrawingArea } from '../drawing-area';
import { ToolEventDelegator } from './ToolEventDelegator';
import { ToolGrid } from './ToolGrid';

/**
 * you shouldn't need to override public functions in the Tool implementations
 *
 * A Tool has to call the _modified function everytime it finished drawing a shape (e.g. a line for the LineTool)!
 *
 * if you externally modify the drawingArea while the tool is active, call the drawingAreaExternallyModified function
 */
export class Tool {
  // ***** these should be overriden!
  protected _name = 'NoName';
  /** class names of the icons */
  protected _icons: Array<string> = [];
  protected _machineName = 'tool';

  /**
   * if this is true then there will be no min distance for dragging (useful for tools which have no click handling)
   */
  protected _instantDragging: boolean = false;

  /**
   * set this to false to disable the grid for this tool
   * you probably need to disable the grid if you have _instantDragging on true
   */
  protected _gridIsEnabled: boolean = true;

  // ***** you shouldn't need to override this
  protected _active = false;
  protected _drawingArea: DrawingArea | null = null;
  protected _msvg: Msvg.Msvg | null = null;

  /* should be used for line thickness etc */
  protected _size: number = 5;
  protected _color: string = '#fff';
  protected _strokeDashArray: Array<number> = [0];

  // ***** Dragging handling properties
  /**
   * only use this value if _isDragging is true
   */
  protected _dragStartPosition: Msvg.Point | null = null;

  /**
   * to store the last position while dragging since the touchEnd Event doesn't deliver any coordinates anymore
   */
  protected _lastDraggingPosition: Msvg.Point | null = null;
  protected _isDragging: boolean = false;

  protected _grid: ToolGrid | null = null;

  /**
   * forward all events into this eventDelegator
   */
  public eventDelegator: ToolEventDelegator;

  constructor() {
    this.eventDelegator = new ToolEventDelegator(this);
  }

  /**
   * call this to start the tool
   */
  public activate(drawingArea: DrawingArea, grid?: ToolGrid | null): void {
    this._active = true;

    this._drawingArea = drawingArea;
    this._msvg = drawingArea.getMsvg();

    this._grid = grid || new ToolGrid(new Msvg.Msvg());
    this._grid.setEnabled(this._gridIsEnabled);

    drawingArea.setActiveTool(this);

    this._reset();
    this._activated();
  }

  /**
   * call this to stop using the tool (some tools will cancel some if it effects)
   */
  public finish(): void {
    this._active = false;
    this._drawingArea?.setActiveTool(null);

    this._cancel();

    this._msvg = null;
    this._drawingArea = null;
    this._finished();
  }

  /**
   * cancel the tool progress
   * e.g. for tools with preview (Ellipse ...) you will need to click once for the first point, and a second time for the second point.
   * to cancel the tool and start from new you need to call this method
   */
  public cancel(): void {
    this._cancel();
  }

  // ********** Event notify functions

  /**
   * get the tools name (meant for display to the user)
   */
  public getName(): string {
    return this._name;
  }

  /**
   * an array of classnames
   */
  public getIcons(): Array<string> {
    return this._icons;
  }

  /**
   * name to reference the tool over css or something
   */
  public getMachineName(): string {
    return this._machineName;
  }

  public isActive(): boolean {
    return this._active;
  }

  public getMsvg(): Msvg.Msvg {
    assertNotNullOrUndefined(
      this._msvg,
      'no msvg available, is the tool activated'
    );
    return this._msvg;
  }

  /**
   * @param {number} size
   */
  public setSize(size: number): void {
    this._size = size;
    this._stylingChanged();
  }

  public setColor(color: string): void {
    this._color = color;
    this._stylingChanged();
  }

  public setStrokeDashArray(strokeDashArray: Array<number>): void {
    this._strokeDashArray = strokeDashArray;
    this._stylingChanged();
  }

  protected _getStrokeDashArrayStyleString(): string {
    return this._strokeDashArray.map((n) => n * this._size).join(',');
  }

  /**
   * call this function everytime the drawing area has been externally modified while this tool is active
   */
  public drawingAreaExternallyModified(): void {}

  /**
   * return true if the tool doesn't have a min dragging distance
   */
  public instantDraggingActivated(): boolean {
    return this._instantDragging;
  }

  // ********** internal functions

  /**
   * remove all of your pending elements in here and reset the element
   */
  protected _cancel(): void {
    this._reset();
  }

  /**
   * reset the current action
   */
  protected _reset(): void {
    this.eventDelegator.reset();
    this._dragStartPosition = null;
    this._isDragging = false;
    this._lastDraggingPosition = null;
    this._grid?.setHighlightedPoint(null);
  }

  protected _modified(): void {
    this._drawingArea?.modified();
  }

  /**
   * override this if you need this
   *
   * this gets called at the end of activate
   */
  protected _activated(): void {}

  /**
   * overrided this if you need this
   *
   * this gets called at the end of finish
   */
  protected _finished(): void {}

  /**
   * gets called after size/color or whatever additional stylings have been changed
   * overwrite this so you can update the preview correctly
   */
  protected _stylingChanged(): void {}

  /**
   * the position is already normalized
   */
  protected _mouseMovedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _mouseClickedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _mouseEnteredHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _mouseLeftHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _touchStartedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _touchMovedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   */
  protected _touchEndedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}

  /**
   * the position is already normalized
   *
   * gets called everytime the user made a dragging action
   *
   * you are probably interested in _dragStartPosition too
   */
  protected _draggingHandler(_event: MouseEvent, _position: Msvg.Point): void {}

  /**
   * the position is already normalized
   *
   * you are probably interested in _dragStartPosition too
   */
  protected _draggingEndedHandler(
    _event: MouseEvent,
    _position: Msvg.Point
  ): void {}
}
