import { autoinject, observable, computedFrom } from 'aurelia-framework';
import { Router, RouteConfig } from 'aurelia-router';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { ProcessTaskToProjectType } from 'common/Types/Entities/ProcessTaskToProject/ProcessTaskToProjectDto';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { PermissionHelper } from '../../classes/PermissionHelper';

import { ActiveEntitiesService } from '../../services/ActiveEntitiesService';
import {
  EntryNavigationButton,
  NavigationDirection
} from '../../aureliaComponents/entry-navigation-button/entry-navigation-button';
import { Utils } from '../../classes/Utils/Utils';
import { GlobalMenu } from '../../aureliaComponents/global-menu/global-menu';
import { EntryRapidFireWidget } from '../../aureliaComponents/entry-rapid-fire-widget/entry-rapid-fire-widget';
import { ImportEntryXmlFileDialog } from '../../dialogs/import-entry-xml-file-dialog/import-entry-xml-file-dialog';
import { EditStructureNavigator } from '../../classes/EditStructureNavigator';
import { ShowMode } from '../../filterComponents/entry-filter/EntryFilterFilterer';
import { ParameterPanel } from '../../aureliaComponents/parameter-panel/parameter-panel';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { StructureListItem } from '../../aureliaComponents/structure-list-item/structure-list-item';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ActionClickedEvent,
  SelectableItemList
} from '../../aureliaComponents/selectable-item-list/selectable-item-list';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Project } from '../../classes/EntityManager/entities/Project/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';
import { User } from '../../classes/EntityManager/entities/User/types';
import { Entry } from '../../classes/EntityManager/entities/Entry/types';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';
import { EntryDeletionService } from '../../classes/EntityManager/entities/Entry/EntryDeletionService';
import { NavigationService } from '../../services/NavigationService';
import { SharepointHelper } from '../../classes/SharepointHelper';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { Thing } from '../../classes/EntityManager/entities/Thing/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { watch } from '../../hooks/watch';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SocketEndpointProcessTaskToProjectCrudStrategy } from '../../dialogs/manage-process-task-to-project-relations-dialog/strategies/SocketEndpointProcessTaskToProjectCrudStrategy';
import { SingleSocketRequestService } from '../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../services/SocketService';

@autoinject()
export class edit_structure {
  protected availableEntries: Array<Entry> = [];
  protected filteredEntries: Array<Entry> = [];
  protected selectedEntries: Array<Entry> = [];

  protected entry: Entry | null = null;

  protected project: Project | null = null;

  protected reportType: ReportType | null = null;

  protected currentUser: User | null = null;

  @observable protected isMobile: boolean;

  protected isAttached = false;

  protected entryToStartEditing: Entry | null = null;

  protected showProjectProperties = false;

  @observable protected entryFilterShowMode: string | null = null;

  protected selectableItemListModel: SelectableItemList<
    Entry,
    StructureListItem
  > | null = null;

  protected parameterPanelOpen = false;
  protected parameterPanelViewModel: ParameterPanel | null = null;

  protected operationsProcessTasksPanelOpen = false;
  protected operationsProcessTasksPanelViewModel: ParameterPanel | null = null;

  protected globalMenuChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'generalPages.editStructure.export',
      name: 'export'
    }
  ];

  protected rapidFireModeMoreButtonChoice: MoreButtonChoice = {
    labelTk: 'generalPages.editStructure.choiceRapidFireMode',
    name: 'rapid-fire-mode'
  };

  protected blowerdoorImportMoreButtonChoice: MoreButtonChoice = {
    labelTk: 'generalPages.editStructure.choiceImport',
    name: 'xml-import-file',
    isFileInput: true,
    fileInputAccept: 'text/xml'
  };

  protected moreButtonChoices: Array<MoreButtonChoice> = [];

  protected filterButtonChoices: Array<MoreButtonChoice> = [
    {
      labelTk: 'general.delete',
      name: 'delete-structure-entries',
      iconClass: 'fal fa-trash-alt',
      disabledContext: this,
      disabledPropertyName: 'cannotDeleteSelectedStructure'
    }
  ];

  protected editableUserGroups: Array<UserGroup> = [];

  protected openedEntry: Entry | null = null;

  protected isWidgetOverlayOpen = false;

  private router: Router;
  private subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected projectPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Project];

  private activeEntitiesService: ActiveEntitiesService;

  private navigator: EditStructureNavigator;

  protected StructureListItem: typeof StructureListItem = StructureListItem;
  protected ShowMode = ShowMode;
  protected crudStrategy: SocketEndpointProcessTaskToProjectCrudStrategy;

  constructor(
    router: Router,
    private readonly entityManager: AppEntityManager,
    private readonly entryDeletionService: EntryDeletionService,
    private readonly currentUserService: CurrentUserService,
    subManagerService: SubscriptionManagerService,
    activeEntitiesService: ActiveEntitiesService,
    private readonly i18n: I18N,
    private readonly navigationService: NavigationService,
    private readonly permissionsService: PermissionsService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    socketService: SocketService,
    singleSocketRequestService: SingleSocketRequestService
  ) {
    this.router = router;
    this.subscriptionManager = subManagerService.create();
    this.activeEntitiesService = activeEntitiesService;

    this.navigator = new EditStructureNavigator(router, null);

    this.isMobile = false;

    this.projectPermissionsHandle =
      permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: null
      });

    this.crudStrategy = new SocketEndpointProcessTaskToProjectCrudStrategy(
      ProcessTaskToProjectType.PROJECT_FROM_OTHER_MODULE,
      socketService,
      singleSocketRequestService
    );
  }

  // ********** lifecycle **********

  protected activate(
    params: { project_id: string; entry_id?: string; edit_entry?: string },
    routeConfig: RouteConfig
  ): void {
    if (this.navigator.consumeIgnoreNavigation()) {
      return;
    }

    const project = this.entityManager.projectRepository.getById(
      params.project_id
    );
    assertNotNullOrUndefined(project, "can't list entries without a project");

    this.project = project;
    void this.entityManager.joinedProjectsManager.joinProject(this.project.id);

    this.projectPermissionsHandle =
      this.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: project
      });

    this.updateReportType();

    const entry = params.entry_id
      ? (this.entityManager.entryRepository.getByIdOrOriginalId(
          params.entry_id
        ) ?? null)
      : null;
    if (entry && params.edit_entry === 'true') {
      // we want the parent to be shown in the background, so the behaviour is the same as in the no direct link variant
      const parentEntry = entry.page_depth_parent
        ? (this.entityManager.entryRepository.getByIdOrOriginalId(
            entry.page_depth_parent
          ) ?? null)
        : null;
      this.loadEntry(parentEntry);
      this.tryStartEditingEntry(entry);
    } else {
      this.loadEntry(entry);
      this.isWidgetOverlayOpen = false;
    }

    this.activeEntitiesService.setActiveProject(this.project);

    if (routeConfig.navModel && this.project?.name)
      routeConfig.navModel.title =
        this.i18n.tr(routeConfig.navModel.title) + ' - ' + this.project.name;
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't list entries without a project"
    );

    this.isAttached = true;

    this.updateMoreButtonChoices();

    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Entry,
      this.updateEntries.bind(this)
    );
    this.updateEntries();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      () => {
        this.updateEditableGroupsForUser();
      }
    );

    this.subscriptionManager.subscribeToModelChanges(EntityName.Project, () => {
      this.updateReportType();
    });
    this.updateReportType();

    this.subscriptionManager.subscribeToPropertyChange(
      this,
      'reportTypeHasStructure',
      () => {
        if (!this.reportTypeHasStructure)
          void this.parameterPanelViewModel?.collapse();
      }
    );

    this.setupEntryNavigationButton();

    if (
      this.router.currentInstruction.queryParams.open_parameter_panel &&
      this.reportTypeHasStructure
    ) {
      this.parameterPanelViewModel?.expand();
    }

    this.tryStartEditingEntry(null);

    GlobalMenu.takeControl(this, {
      visible: this.isMobile,
      choices: this.globalMenuChoices.concat(this.moreButtonChoices),
      selectedCallbacks: {
        export: this.navigateToReportExport.bind(this),
        'rapid-fire-mode': this.handleStartRapidFireModeClicked.bind(this)
      },
      fileChangedCallbacks: {
        'xml-import-file': (event) => {
          void this.handleXmlImportFileChanged(event);
        }
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;
    GlobalMenu.releaseControl(this);
    this.subscriptionManager.disposeSubscriptions();
    EntryNavigationButton.releaseControl(this);
  }

  protected deactivate(): void {
    if (this.navigator.isIgnoringNavigation()) {
      return;
    }

    this.activeEntitiesService.setActiveProject(null);
    this.activeEntitiesService.setActiveEntry(null);
  }

  protected determineActivationStrategy(): string {
    return 'invoke-lifecycle';
  }

  private setupEntryNavigationButton(): void {
    EntryNavigationButton.takeControl(
      this,
      {
        navigateIn: null,
        navigateOut: this.handleNavigateOutClick.bind(this)
      },
      {
        navigateIn: null,
        navigateOut: this.handleNavigateOutDrop.bind(this)
      }
    );

    EntryNavigationButton.setVisible(
      this,
      NavigationDirection.NAVIGATE_OUT,
      !!this.entry
    );
    EntryNavigationButton.setVisible(
      this,
      NavigationDirection.NAVIGATE_IN,
      false
    );
  }

  private handleNavigateOutClick(): void {
    if (this.entry) {
      this.navigateToParent(this.entry);
    }
  }

  private handleNavigateOutDrop(viewModel: StructureListItem): void {
    const entry = viewModel.entry;
    assertNotNullOrUndefined(entry, 'cannot drop item without entry');

    const parentEntryId = this.entry?.page_depth_parent;
    assertNotNullOrUndefined(
      parentEntryId,
      'cannot drop on parent if there is none'
    );

    this.entityManager.entryRepository.setParentIdOfEntry(entry, parentEntryId);
  }

  protected isMobileChanged(): void {
    if (GlobalMenu.hasControl(this)) {
      GlobalMenu.setVisible(this, this.isMobile);
    }
  }

  protected async handleDeleteSelectedEntries(
    event: ActionClickedEvent<Entry>
  ): Promise<void> {
    await this.entryDeletionService.deleteEntriesWithDialog(
      event.detail.selectedItems
    );
  }

  // ********** VIEW FUNCTIONS **********

  private navigateToParent(entry: Entry): void {
    assertNotNullOrUndefined(
      this.project,
      "can't navigate to parent without a project"
    );

    const parent =
      this.entityManager.entryRepository.getById(
        entry.page_depth_parent || ''
      ) || null;
    this.navigator.navigateToEntry(this.project, parent, false);
    this.loadEntry(parent);
  }

  protected navigateToReportExport(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't navigate to report export without a project"
    );
    this.router.navigate(this.navigationService.getExportPageUrl(this.project));
  }

  protected handleNewEntry(
    pageDepthParent: string,
    listPosition: number
  ): void {
    assertNotNullOrUndefined(
      this.project,
      "can't handle new entry without a project"
    );

    const newEntry = this.entityManager.entryRepository.create({
      project: this.project.id,
      ownerProjectId: this.project.id,
      ownerUserGroupId: this.project.usergroup,
      page_depth_parent: pageDepthParent,
      list_position: listPosition
    });

    this.updateEntries();

    setTimeout(() => {
      this.startEditingEntry({ entry: newEntry });
    }, 10);
  }

  protected handleStartRapidFireModeClicked(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't list entries without a project"
    );
    EntryRapidFireWidget.start(this.project.id);
  }

  protected async handleXmlImportFileChanged(
    originalEvent: IHTMLInputEvent
  ): Promise<void> {
    assertNotNullOrUndefined(
      this.project,
      "can't list entries without a project"
    );
    if (originalEvent?.target?.files?.[0]) {
      await ImportEntryXmlFileDialog.open({
        projectId: this.project.id,
        parentEntryId: this.entry ? this.entry.id : null,
        file: originalEvent.target.files[0],
        entriesCreatedCallback: (entries) => {
          if (entries.length) {
            this.updateEntries();
            this.tryStartEditingEntry(entries[0]);
          }
        }
      });

      originalEvent.target.value = '';
    }
  }

  @computedFrom('reportType.features.hasStructure')
  protected get reportTypeHasStructure(): boolean {
    return this.reportType?.features?.hasStructure !== false;
  }

  @computed(model(EntityName.ProcessConfiguration))
  protected get showOperationsProcessTasksPanel(): boolean {
    return this.entityManager.processConfigurationRepository
      .getAll()
      .some((pc) => pc.processTaskToProjectRelations?.enabled);
  }

  // ********** PRIVATE FUNCTIONS **********

  private updateReportType(): void {
    if (this.project && this.project.report_type) {
      this.reportType = this.entityManager.reportTypeRepository.getById(
        this.project.report_type
      );
    } else {
      this.reportType = null;
    }
  }

  @watch(expression('projectPermissionsHandle.canCreateEntries'))
  private updateMoreButtonChoices(): void {
    const choices = [];

    if (this.projectPermissionsHandle.canCreateEntries) {
      choices.push(this.rapidFireModeMoreButtonChoice);
    }

    if (
      this.projectPermissionsHandle.canCreateEntries &&
      PermissionHelper.userHasPermission(
        this.currentUser,
        'canImportBlowerdoorXmlFiles'
      )
    ) {
      choices.push(this.blowerdoorImportMoreButtonChoice);
    }

    this.moreButtonChoices = choices;
    GlobalMenu.setChoices(this, this.globalMenuChoices.concat(choices));
  }

  private updateEntries(): void {
    if (this.entry) {
      this.availableEntries =
        this.entityManager.entryRepository.getSubEntriesOfEntry(this.entry);
    } else {
      assertNotNullOrUndefined(
        this.project,
        "can't get root entries without a project"
      );
      this.availableEntries = this.entityManager.entryRepository.getByParentId(
        this.project.id,
        null,
        null
      );
    }
    // Just set sorted entries to available entries for now - figure out how to do sort in next commit
    this.filteredEntries = this.availableEntries;

    setTimeout(() => {
      this.tryStartEditingEntry(null);
    }, 10);
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableGroupsForUser();
  }

  private updateEditableGroupsForUser(): void {
    this.editableUserGroups = this.currentUser
      ? this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        )
      : [];
  }

  // ********** entry editing **********

  protected handleEntryEditClicked(entry: Entry): void {
    this.startEditingEntry({ entry });
  }

  /**
   * tries to start editing the entry (since it only works when the elements are rendered)
   * if it can't be started yet, the entry will be stored in _entryToStartEditing for a later retry
   * this logic is extracted into this function so you don't have a duplicate implementation in attached and in activate
   */
  private tryStartEditingEntry(entry: Entry | null): void {
    const entryToEdit = entry || this.entryToStartEditing;
    if (!entryToEdit) {
      return;
    }

    if (this.isAttached) {
      setTimeout(() => {
        this.startEditingEntry({ entry: entryToEdit, navigate: false });
        this.entryToStartEditing = null;
      }, 0); // wait until elements have been rendered
    } else {
      this.entryToStartEditing = entryToEdit;
    }
  }

  private startEditingEntry({
    entry,
    navigate = true
  }: {
    entry: Entry;
    navigate?: boolean;
  }): void {
    assertNotNullOrUndefined(
      this.project,
      "can't EditStructure.startEditingEntry without a project"
    );

    this.openedEntry = entry;
    this.isWidgetOverlayOpen = true;
    if (navigate) this.navigator.navigateToEntry(this.project, entry, true);
  }

  protected handleEditEntryWidgetCloseClicked(): void {
    assertNotNullOrUndefined(
      this.project,
      "can't EditStructure.handleEditEntryWidgetCloseClicked without a project"
    );

    // TODO: implement not always highlighting
    // do this after scroll unlocking so in case of an error we only miss out on the highlighting
    const entry = this.openedEntry;
    if (entry) {
      const element = this.getElementForEntry(entry);
      this.isWidgetOverlayOpen = false;
      this.navigator.navigateToEntry(this.project, this.entry, false);
      if (element) {
        const vm: StructureListItem | null =
          Utils.getViewModelOfElement(element);
        if (vm) vm.highlight();
      }
    }
  }

  protected handleWidgetOverlayClosed(): void {
    this.openedEntry = null;
  }

  @computedFrom('openedEntry')
  protected get openedEntryRef(): HTMLElement | null {
    return this.openedEntry ? this.getElementForEntry(this.openedEntry) : null;
  }

  protected handleEditEntryWidgetEntryChanged(): void {
    const parent =
      this.openedEntry && this.openedEntry.page_depth_parent
        ? this.entityManager.entryRepository.getById(
            this.openedEntry.page_depth_parent
          )
        : null;
    this.loadEntry(parent);

    /*
    * Deleting an entry using the edit-entry-widget delete button triggers the following navigation route:
    1. The dialog is closed, UrlParameterService.overlayClosed is called
    2. This method contains a call to AureliaRouter.navigateBack()
    3. Directly afterwards, AureliaRouter.navigateToRoute() is called, which triggers the activate() method of this class
    4. As the route params contain an entry id, we eventually get into this method which calls navigateToEntry.

    The Aurelia router seems to mix up the route params if the route instructions are very close in time.
    Therefore, our makeshift solution is to delay the last route change in this navigation process by 100ms.
    */
    setTimeout(() => {
      assertNotNullOrUndefined(
        this.project,
        "can't navigate to entry without a project"
      );
      this.navigator.navigateToEntry(this.project, this.openedEntry, true);
    }, 100);
  }

  /**
   * does all the things needed to change/set the current entry
   */
  private loadEntry(entry: Entry | null): void {
    this.entry = entry;

    if (EntryNavigationButton.hasControl(this)) {
      EntryNavigationButton.setVisible(
        this,
        NavigationDirection.NAVIGATE_OUT,
        !!this.entry
      );
      EntryNavigationButton.setVisible(
        this,
        NavigationDirection.NAVIGATE_IN,
        false
      );
    }

    this.updateEntries();
    this.activeEntitiesService.setActiveEntry(this.entry);
  }

  private createBoundGetElementForEntryFunction(): (
    entry: Entry
  ) => HTMLElement | null {
    return this.getElementForEntry.bind(this);
  }

  private getSortableElement(): HTMLElement | null {
    return this.selectableItemListModel?.getListWrapperElement() || null;
  }

  protected getElementForEntry(entry: Entry): HTMLElement | null {
    const sortableElement = this.getSortableElement();
    return sortableElement
      ? sortableElement.querySelector(`#entry-${entry.id}`)
      : null;
  }

  protected handleTogglePropertiesClick(): void {
    if (this.parameterPanelViewModel)
      void this.parameterPanelViewModel.toggle();
  }

  protected handleToggleOperationsProcessTasksClick(): void {
    if (this.operationsProcessTasksPanelViewModel)
      void this.operationsProcessTasksPanelViewModel.toggle();
  }

  protected handleEnterEntryClicked(entry: Entry): void {
    this.router.navigateToRoute('project', {
      project_id: entry.project,
      entry_id: entry.id
    });
  }

  protected get cannotDeleteSelectedStructure(): boolean {
    const currentUser = this.currentUser;
    return (
      !currentUser ||
      this.selectedEntries.some(
        (t) =>
          !this.structureIsEditable(t, currentUser, this.editableUserGroups)
      )
    );
  }

  protected structureIsEditable(
    entry: Entry,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return entry && user && editableUserGroups
      ? PermissionHelper.userCanEditOwnerUserGroupIdEntity(
          entry,
          user,
          editableUserGroups
        )
      : false;
  }

  protected handleDropZoneActivated(
    viewModel: StructureListItem,
    entry: Entry
  ): boolean {
    return !!viewModel.entry && viewModel.entry.id !== entry.id;
  }

  protected handleItemDropped(
    viewModel: StructureListItem,
    entryToReplace: Entry | null
  ): void {
    const entry = viewModel.entry;
    assertNotNullOrUndefined(entry, 'cannot drop if there is no entry');

    const newListPosition = entryToReplace?.list_position ?? null;
    this.entityManager.entryRepository.setListPositionOfEntry(
      entry,
      newListPosition
    );
  }

  protected handleOpenInSharepoint(): void {
    assertNotNullOrUndefined(
      this.project,
      'cannot open in sharepoint without a project'
    );

    const url = SharepointHelper.getSharepointExportUrlForThingId({
      entityManager: this.entityManager,
      thingId: this.project.thing
    });

    window.open(url, DeviceInfoHelper.isApp() ? '_self' : '_blank');
  }

  @computed(expression('userGroup.sharepointCredentials'))
  protected get sharepointEnabled(): boolean {
    const sharepointCredentials = this.userGroup?.sharepointCredentials;
    return [
      sharepointCredentials?.applicationId,
      sharepointCredentials?.tenantId,
      sharepointCredentials?.clientCertificate.thumbprint,
      sharepointCredentials?.clientCertificate.privateKey,
      sharepointCredentials?.sharepointExportSite,
      sharepointCredentials?.sharepointExportPath
    ].every(Boolean);
  }

  @computed(expression('thing.ownerUserGroupId'), model(EntityName.UserGroup))
  private get userGroup(): UserGroup | null {
    if (!this.thing) {
      return null;
    }

    return this.entityManager.userGroupRepository.getById(
      this.thing.ownerUserGroupId
    );
  }

  @computed(expression('project.thing'), model(EntityName.Thing))
  private get thing(): Thing | null {
    if (!this.project) return null;
    return this.entityManager.thingRepository.getById(this.project?.thing);
  }
}

interface IHTMLInputEvent {
  target: HTMLInputElement & EventTarget;
}
