import { bindable, inject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';

import { defaultProcessTaskMeasurePointReadingProperties as defaultReadingProperties } from '../../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';
import { Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@inject(AppEntityManager, SubscriptionManagerService)
export class ProcessTaskMeasurePointReadingItem {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types').ProcessTaskMeasurePointReading|null} */
  @bindable measurePointReading = null;

  /** @type {Array<import('../../classes/EntityManager/entities/Property/types').ProcessTaskMeasurePointReadingProperty>} */
  _properties = [];

  @bindable forceDisabled = false;

  @bindable showLabels = true;

  @bindable showDate = true;

  _isMobile = false;

  /**
   * @param {AppEntityManager} entityManager
   * @param {SubscriptionManagerService} subscriptionManagerService
   */
  constructor(entityManager, subscriptionManagerService) {
    this._entityManager = entityManager;
    this._subscriptionManager = subscriptionManagerService.create();
  }

  attached() {
    this._updateProperties();
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this._updateProperties.bind(this)
    );
    this._subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isSmallMobile', (isSmallMobile) => {
        this._isMobile = isSmallMobile;
      })
    );
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  focus() {
    const property = this._getPropertyToFocus();
    if (!property) {
      return;
    }

    const inputField = this._getPropertyInputFieldViewModel(property);
    if (!inputField) {
      return;
    }

    inputField.focus();
  }

  measurePointReadingChanged() {
    this._updateProperties();
  }

  _updateProperties() {
    if (!this.measurePointReading) {
      this._properties = [];
      return;
    }
    const measurePointReading = this.measurePointReading;
    this._properties =
      this._entityManager.propertyRepository.getSortedPropertiesByProcessTaskMeasurePointReadingId(
        measurePointReading.id
      );
  }

  /**
   * @returns {import('../../classes/EntityManager/entities/Property/types').ProcessTaskMeasurePointReadingProperty|null}
   */
  _getPropertyToFocus() {
    const defaultProperty = defaultReadingProperties.find(
      (i) => i.focusProperty
    );
    /** @type {import('../../classes/EntityManager/entities/Property/types').ProcessTaskMeasurePointReadingProperty|null} */
    let propertyToFocus = null;

    if (defaultProperty) {
      propertyToFocus =
        this._properties.find((p) => p.name === defaultProperty.name) || null;
    }

    if (!propertyToFocus) {
      propertyToFocus = this._properties[0] || null; // focus the first one as fallback
    }

    return propertyToFocus;
  }

  /**
   * @param {import('../../classes/EntityManager/entities/Property/types').ProcessTaskMeasurePointReadingProperty} property
   * @returns {import('../../aureliaComponents/property-input-field/property-input-field').PropertyInputField|null}
   */
  _getPropertyInputFieldViewModel(property) {
    const element = document.getElementById(
      this._getPropertyElementId(property.id)
    );
    if (!element) {
      return null;
    }

    return /** @type {import('../../aureliaComponents/property-input-field/property-input-field').PropertyInputField} */ (
      Utils.getViewModelOfElement(element)
    );
  }

  /**
   * @param {string} propertyId
   * @returns {string}
   */
  _getPropertyElementId(propertyId) {
    return 'process-task-measure-point-reading-item--property' + propertyId;
  }
}
