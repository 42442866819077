import { computedFrom } from 'aurelia-framework';
import { ProcessTaskDevice } from '../../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskDeviceAdapter } from '../EntityAdapter/ProcessTaskDeviceAdapter/ProcessTaskDeviceAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessTaskDevicePermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessTaskDeviceAdapter>,
  ProcessTaskDevice
> {
  @computedFrom('combinedRevision')
  public get canEditProperties(): boolean {
    return this.checkAdapterSpecificPermissionByName('canEditProperties');
  }
}
