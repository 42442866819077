import { autoinject, bindable } from 'aurelia-framework';

import { OperationsFieldConfigs } from 'common/ExpressionEditorScope/SpecificExpressionEditorScopes/Operations/OperationsFieldConfigs';
import { assertNotNullOrUndefined } from 'common/Asserts';

import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';

@autoinject()
export class ProcessConfigurationProcessTaskToProjectWidget {
  @bindable public processConfiguration: ProcessConfiguration | null = null;

  protected readonly processTaskFieldConfigs =
    OperationsFieldConfigs.processTaskRequestFieldConfigs;

  @subscribableLifecycle()
  protected processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    permissionsService: PermissionsService,
    private readonly entityManager: AppEntityManager
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  protected handleProcessConfigurationChanged(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'cannot use ProcessConfigurationFinishAppointmentConfigWidget without processConfiguration'
    );
    this.entityManager.processConfigurationRepository.update(
      this.processConfiguration
    );
  }
}
