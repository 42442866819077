import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { GlobalLoadingOverlay } from '../loadingComponents/global-loading-overlay/global-loading-overlay';
import { ProcessTaskGroupsWithSubEntitiesLoadingService } from './ProcessTaskGroupsWithSubEntitiesLoadingService';
import { SocketService } from './SocketService';

@autoinject()
export class LoadProcessTaskGroupWithEntitiesService {
  private readonly processTaskIdByLoadingContext = new Map<string, string>();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskGroupsWithSubEntitiesLoadingService: ProcessTaskGroupsWithSubEntitiesLoadingService,
    private readonly socketService: SocketService
  ) {}

  public async loadByProcessTaskId({
    processTaskId,
    temporaryGroupName,
    onProcessTaskLoaded
  }: {
    processTaskId: string;
    temporaryGroupName: string;
    onProcessTaskLoaded: () => void;
  }): Promise<void> {
    if (!this.socketService.isAuthenticated()) {
      return;
    }

    const loadingContext = `LoadProcessTaskGroupWithEntitiesService_${temporaryGroupName}`;
    this.processTaskIdByLoadingContext.set(loadingContext, processTaskId);
    GlobalLoadingOverlay.setLoadingState(loadingContext, true);

    try {
      const processTask =
        this.entityManager.processTaskRepository.getById(processTaskId);

      if (!processTask) {
        const response =
          await this.socketService.getProcessTaskGroupsWithSubEntitiesForProcessTaskGroup(
            { processTaskId: processTaskId }
          );
        if (response.success) {
          this.processTaskGroupsWithSubEntitiesLoadingService.loadEntitiesTemporarily(
            response,
            temporaryGroupName
          );
        } else {
          throw new Error(
            `failed to get the processTaskGroup for ${processTaskId}`
          );
        }
      } else {
        this.processTaskGroupsWithSubEntitiesLoadingService.allocateProcessTaskGroupIdsAsTemporary(
          [processTask.ownerProcessTaskGroupId],
          processTaskId
        );
      }

      await this.entityManager.waitForFilterUpdateFinished();

      /** since this can be async, a new request to load a processTaskGroup could have been started in the meantime */
      if (
        this.processTaskIdByLoadingContext.get(loadingContext) === processTaskId
      ) {
        onProcessTaskLoaded();
      }
    } finally {
      GlobalLoadingOverlay.setLoadingState(loadingContext, false);
    }
  }
}
