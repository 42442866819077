import { bindable, inject } from 'aurelia-framework';

import { I18N } from 'aurelia-i18n';

import {
  SequenceNumberType,
  SequenceNumberUtils
} from 'common/Utils/SequenceNumberUtils';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Dialogs } from '../../classes/Dialogs';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskChecklistEntryDialogService } from '../../classes/EntityManager/entities/ProcessTaskChecklistEntry/ProcessTaskChecklistEntryDialogService';
import { SocketService } from '../../services/SocketService';
import { FileDownloadService } from '../../services/FileDownloadService';

/**
 * @event edit-button-clicked
 */
@inject(
  I18N,
  FileDownloadService,
  AppEntityManager,
  SocketService,
  SubscriptionManagerService,
  ProcessTaskChecklistEntryDialogService
)
export class ProcessTaskInvoiceListItem {
  /** @type {import('../../classes/EntityManager/entities/ProcessTaskInvoice/types').ProcessTaskInvoice|null} */
  @bindable processTaskInvoice = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;

  /** @type {boolean} */
  _panelOpened = false;
  /** @type {boolean} */
  _attached = false;
  /** @type {Array<import('../../classes/EntityManager/entities/ProcessTask/types').ProcessTask>} */
  _relatedProcessTasks = [];

  /** @type {HTMLElement|null} */
  _domElement = null;
  /** @type {HTMLElement|null} */
  _listItemElement = null;

  _SequenceNumberUtils = SequenceNumberUtils;
  _SequenceNumberType = SequenceNumberType;

  /**
   * @param {I18N} i18n
   * @param {FileDownloadService} fileDownloadService
   * @param {AppEntityManager} entityManager
   * @param {SocketService} socketService
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {ProcessTaskChecklistEntryDialogService} processTaskChecklistEntryDialogService
   */
  constructor(
    i18n,
    fileDownloadService,
    entityManager,
    socketService,
    subscriptionManagerService,
    processTaskChecklistEntryDialogService
  ) {
    this._i18n = i18n;

    this._entityManager = entityManager;

    this._socketService = socketService;

    this._subscriptionManager = subscriptionManagerService.create();
    this._processTaskChecklistEntryDialogService =
      processTaskChecklistEntryDialogService;
    this._fileDownloadService = fileDownloadService;
  }

  attached() {
    this._attached = true;

    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTask,
      this._updateRelatedProcessTasks.bind(this)
    );
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskInvoiceToProcessTask,
      this._updateRelatedProcessTasks.bind(this)
    );
    this._updateRelatedProcessTasks();
  }

  detached() {
    this._attached = false;

    this._subscriptionManager.disposeSubscriptions();
  }

  highlight() {
    if (this._listItemElement) {
      EntityListItemHelper.highlightListItemElement(this._listItemElement);
    }
  }

  processTaskInvoiceChanged() {
    if (this._attached) {
      this._updateRelatedProcessTasks();
    }
  }

  _updateRelatedProcessTasks() {
    if (this.processTaskInvoice) {
      const relations =
        this._entityManager.processTaskInvoiceToProcessTaskRepository.getByProcessTaskInvoiceId(
          this.processTaskInvoice.id
        );
      const ids = relations.map((r) => r.ownerProcessTaskId);
      this._relatedProcessTasks =
        this._entityManager.processTaskRepository.getByIds(ids);
    } else {
      this._relatedProcessTasks = [];
    }
  }

  _handleMoreButtonClick() {
    this._panelOpened = !this._panelOpened;
  }

  _handleEditButtonClick() {
    if (!this._domElement) {
      return;
    }

    DomEventHelper.fireEvent(this._domElement, {
      name: 'edit-button-clicked',
      detail: null
    });
  }

  _handleDeleteButtonClick() {
    Dialogs.deleteEntityDialog(this.processTaskInvoice).then(() => {
      if (this.processTaskInvoice) {
        this._entityManager.processTaskInvoiceRepository.delete(
          this.processTaskInvoice
        );
      }
    });
  }

  async _handleDownloadInvoiceClick() {
    const invoice = this.processTaskInvoice;
    if (!invoice) return;

    await this._processTaskChecklistEntryDialogService.confirmProcessTasksChecklistEntries(
      this._relatedProcessTasks
    );

    Dialogs.waitDialog();
    this._socketService.downloadProcessTaskInvoice(
      invoice.id,
      (response, request) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this._fileDownloadService.downloadFileByToken(response.token);
        } else {
          const errorMessage = this._i18n.tr(
            `serverResponses.${
              response.status ? response.status : 'unspecifiedError'
            }`
          );
          Dialogs.errorDialog('Fehler beim Download!', errorMessage);
        }
      }
    );
  }
}
