import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';

/**
 * Dropdown list for choosing a user role.
 * User role selection can optionally be restricted to a certain user group.
 *
 * @event value-changed
 */
@autoinject()
export class UserRoleSelect {
  /**
   * The userId of the currently selected user.
   */
  @bindable public value: string | null = null;

  @bindable public enabled = false;

  /**
   * Limit the roles to select via the user group.
   */
  @bindable public userGroupId: string | null = null;

  /**
   * The string to display if no value is selected.
   */
  @bindable public nullOption: string | null = null;

  /**
   * The label to display for the dropdown.
   * Setting this to null shows the default label.
   */
  @bindable public label: string | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager
  ) {}

  @computed(expression('userGroupId'), model(EntityName.UserRole))
  protected get options(): Array<UserRoleSelectOption> {
    let userRoles;

    if (this.userGroupId) {
      userRoles = this.entityManager.userRoleRepository.getByUserGroupId(
        this.userGroupId
      );
    } else {
      userRoles = this.entityManager.userRoleRepository.getAll();
    }

    return userRoles.map((userRole) => {
      return {
        value: userRole.id,
        label: userRole.name ?? ''
      };
    });
  }

  protected handleSelectChanged(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'value-changed',
      detail: { value: this.value }
    });
  }
}

export interface UserRoleSelectOption {
  value: string;
  label: string;
}
