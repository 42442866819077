import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { BaseEntityUtils } from '../../BaseEntities/BaseEntityUtils';
import { UserDto } from './UserDto';
import { DateUtils } from '../../../DateUtils';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';

export function createUserEntityInfo<TEntity extends UserDto<string, string>>(
  options: CreateEntityInfoOptions<UserDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.User,
  TEntity
> {
  return {
    entityName: EntityName.User,
    synchronizeRelativeTo: [EntityName.User],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      UserDto<string, string>,
      TEntity
    >(
      {
        ...BaseEntityUtils.createBaseFieldInfos(),
        userCompanySettingId: {
          name: 'userCompanySettingId',
          defaultValue: null,
          ref: EntityName.UserCompanySetting
        },
        username: {
          name: 'username'
        },
        alias: {
          name: 'alias',
          defaultValue: null
        },
        email: {
          name: 'email'
        },
        phoneNumber: {
          name: 'phoneNumber',
          defaultValue: null
        },
        streetName: {
          name: 'streetName',
          defaultValue: null
        },
        zip: {
          name: 'zip',
          defaultValue: null
        },
        municipality: {
          name: 'municipality',
          defaultValue: null
        },
        languageCode: {
          name: 'languageCode',
          defaultValue: 'de'
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        secondaryColor: {
          name: 'secondaryColor',
          defaultValue: null
        },
        admin: {
          name: 'admin',
          defaultValue: false
        },
        active: {
          name: 'active',
          defaultValue: false
        },
        permissions: {
          name: 'permissions',
          innerInfo: {
            canAdministerUserDefinedEntities: {
              name: 'canAdministerUserDefinedEntities',
              defaultValue: false
            },
            canAdministerUsers: {
              name: 'canAdministerUsers',
              defaultValue: false
            },
            canAssignNfcTagIds: {
              name: 'canAssignNfcTagIds',
              defaultValue: false
            },
            canCreateGroups: {
              name: 'canCreateGroups',
              defaultValue: false
            },
            canImpersonateUsers: {
              name: 'canImpersonateUsers',
              defaultValue: false
            },
            canImportBlowerdoorXmlFiles: {
              name: 'canImportBlowerdoorXmlFiles',
              defaultValue: false
            },
            canManageChecklistQuestions: {
              name: 'canManageChecklistQuestions',
              defaultValue: false
            },
            canUseB1300: {
              name: 'canUseB1300',
              defaultValue: null
            },
            canUseBasic: {
              name: 'canUseBasic',
              defaultValue: null
            },
            canUseChecklist: {
              name: 'canUseChecklist',
              defaultValue: null
            },
            canUseDefectManagement: {
              name: 'canUseDefectManagement',
              defaultValue: false
            },
            canUseInspect: {
              name: 'canUseInspect',
              defaultValue: null
            },
            canUseKuK: {
              name: 'canUseKuK',
              defaultValue: null
            },
            canUseMapLayers: {
              name: 'canUseMapLayers',
              defaultValue: false
            },
            canUseOperations: {
              name: 'canUseOperations',
              defaultValue: null
            },
            canUseOperationsFieldUse: {
              name: 'canUseOperationsFieldUse',
              defaultValue: null
            },
            canUsePersonExtension: {
              name: 'canUsePersonExtension',
              defaultValue: null
            },
            canUseRegionExtension: {
              name: 'canUseRegionExtension',
              defaultValue: null
            },
            canUseStructureTemplates: {
              name: 'canUseStructureTemplates',
              defaultValue: false
            },
            canUseVia: {
              name: 'canUseVia',
              defaultValue: null
            },
            canUseViaWildbach: {
              name: 'canUseViaWildbach',
              defaultValue: false
            },
            canAdministerValueCalculations: {
              name: 'canAdministerValueCalculations',
              defaultValue: null
            },
            canViewValueCalculationResults: {
              name: 'canViewValueCalculationResults',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        created: {
          name: 'created',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        usergroup: {
          name: 'usergroup',
          ref: EntityName.UserGroup,
          defaultValue: null
        },
        notificationAcknowledgedAt: {
          name: 'notificationAcknowledgedAt',
          defaultValue: null
        },
        autoRenewSubscriptions: {
          name: 'autoRenewSubscriptions',
          defaultValue: false
        }
      },
      options.additionalFieldInfos
    )
  };
}
