export class GlobalAnimationSettings {
  private static speed: number = 1;

  private constructor() {}

  /**
   * reset all settings
   */
  public static reset(): void {
    this.speed = 1;
  }

  /**
   * set the speed to scale all durations with
   * you can use Number.POSITIVE_INFINITY to set a duration of 0 to all animations
   */
  public static setSpeed(speed: number): void {
    if (speed === 0) {
      throw new Error('a timeScale of 0 is not supported');
    }

    if (speed < 0) {
      throw new Error('negative timeScales are not supported');
    }

    this.speed = speed;
  }

  /**
   * scale a time according to the settings
   * @see setSpeed
   */
  public static scaleTime(time: number): number {
    if (this.speed === Number.POSITIVE_INFINITY) {
      return 0;
    }

    return time / this.speed;
  }
}

GlobalAnimationSettings.reset();
