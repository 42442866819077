import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../../classes/DomEventHelper';
import { Picture } from '../../../classes/EntityManager/entities/Picture/types';

/**
 * A picture element + animation that imitates the "picture taken & saved" effect of digital cameras.
 *
 * @event preview-picture-clicked triggered when the preview picture is clicked.
 */
@autoinject()
export class UrfwPicturePreview {
  @bindable public picture: Picture | null = null;

  @bindable public previewPictureAnimationUrl: string | null = null;

  protected animatingPreview: boolean = false;

  private element: HTMLElement;

  constructor(element: Element) {
    this.element = element as HTMLElement;
  }

  protected pictureChanged(): void {
    /*
      Whenever a picture is taken, the "picture taken" animation should play (flash and movement of picture to gallery)
      The image uses a css animation to achieve that effect - but they can only be replayed if the css class containing
      the animation property is removed and added back the next tick.
    */
    this.animatingPreview = false;
    setTimeout(() => {
      this.animatingPreview = true;
      setTimeout(() => {
        this.animatingPreview = false;
      }, 800);
    }, 0);
  }

  protected handlePreviewPictureClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'preview-picture-clicked',
      detail: null
    });
  }
}
