import { computedFrom } from 'aurelia-framework';
import { ProcessConfigurationStep } from '../../../classes/EntityManager/entities/ProcessConfigurationStep/types';
import { ProcessConfigurationStepAdapter } from '../EntityAdapter/ProcessConfigurationStepAdapter/ProcessConfigurationStepAdapter';
import { EntityAdapterContainer } from '../EntityAdapterContainer/EntityAdapterContainer';
import { EntitySpecificPermissionsHandle } from './EntitySpecificPermissionsHandle';

export class ProcessConfigurationStepPermissionsHandle extends EntitySpecificPermissionsHandle<
  EntityAdapterContainer<ProcessConfigurationStepAdapter>,
  ProcessConfigurationStep
> {
  @computedFrom('combinedRevision')
  public get canSeeSmsNotificationSetting(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canSeeSmsNotificationSetting'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationStepAutoAppointments(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationStepAutoAppointments'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationStepAutoForms(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationStepAutoForms'
    );
  }

  @computedFrom('combinedRevision')
  public get canCreateProcessConfigurationStepPositions(): boolean {
    return this.checkAdapterSpecificPermissionByName(
      'canCreateProcessConfigurationStepPositions'
    );
  }
}
