import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  Disposable,
  DisposableContainer
} from '../classes/Utils/DisposableContainer';
import { setupMount, setupUnmount } from './configureHooks';

/**
 * This decorator can be used to connect a subscribable with the mount/unmount lifecycle.
 * It can only be used to decorate properties which have a nullable value which has a `subscribe` function.
 * It will subscribe/dispose automatically if the value changes or the components enters a different lifecycle state.
 *
 *
 * Example:
 *     @subscribableLifecycle()
 *     private thingPermissions: EntityNameToPermissionsHandle[EntityName.Thing]|null = null
 */
export function subscribableLifecycle() {
  return function (target: any, prop: string) {
    const isMountedPropName = `__subscribableLifecycle_state_${prop}_isMounted`;
    const valuePropName = `__subscribableLifecycle_state_${prop}_value`;
    const disposableContainerPropName = `__subscribableLifecycle_state_${prop}_disposableContainer`;

    function getIsMounted(scope: any): boolean {
      return scope[isMountedPropName] ?? false;
    }

    function getValue(scope: any): Subscribable | null {
      return scope[valuePropName];
    }

    function getRequiredDisposableContainer(scope: any): DisposableContainer {
      const container = getDisposableContainer(scope);
      assertNotNullOrUndefined(
        container,
        'no disposable container available, is the component mounted?'
      );

      return container;
    }

    function getDisposableContainer(scope: any): DisposableContainer | null {
      return scope[disposableContainerPropName];
    }

    const getterFunction = function (this: any): any {
      return getValue(this);
    };

    (getterFunction as any).dependencies = [valuePropName];

    Reflect.defineProperty(target, prop, {
      get: getterFunction,
      set: function (this: any, newValue) {
        getDisposableContainer(this)?.disposeAll();

        assertValue(newValue);

        this[valuePropName] = newValue;

        if (getIsMounted(this) && newValue) {
          getRequiredDisposableContainer(this).add(newValue.subscribe());
        }
      }
    });

    setupMount(target, function (this: any) {
      assertValue(this[prop]);
      const value = (this[valuePropName] = this[prop]);
      this[isMountedPropName] = true;
      this[disposableContainerPropName] = new DisposableContainer();

      if (value) {
        getRequiredDisposableContainer(this).add(value.subscribe());
      }
    });

    setupUnmount(target, function (this: any) {
      this[isMountedPropName] = false;
      getDisposableContainer(this)?.disposeAll();
    });
  };
}

function assertValue(value: any): asserts value is Subscribable | null {
  if (value == null) {
    return;
  }

  if (typeof value.subscribe !== 'function') {
    throw new Error(
      'trying to use subscribableLifecycle with a value which has no subscribe function'
    );
  }
}

type Subscribable = {
  subscribe: () => Disposable;
};
