export class ObjectUtils {
  private constructor() {}

  public static omit<
    T extends Record<any, any>,
    TOmittedProperties extends Array<keyof T>
  >(
    obj: T,
    propertyNames: Readonly<TOmittedProperties>
  ): Omit<T, TOmittedProperties[number]> {
    const copy = { ...obj };

    for (const key of propertyNames) {
      delete (copy as any)[key];
    }

    return copy;
  }

  public static pick<
    T extends PickableRecord | null,
    TKeys extends PickableKeys<T>
  >(obj: T, propertyNames: Readonly<TKeys>): PickReturnValue<T, TKeys> {
    if (obj) {
      const picked: any = {};

      for (const propertyName of propertyNames) {
        picked[propertyName] = obj[propertyName];
      }

      return picked;
    }

    return null as PickReturnValue<T, TKeys>;
  }

  public static copyDeep<T>(toCopy: T): T {
    return JSON.parse(JSON.stringify(toCopy));
  }
}

type PickReturnValue<
  T extends PickableRecord | null,
  TKeys extends PickableKeys<T>
> = T extends PickableRecord
  ? Pick<T, TKeys[number]>
  : T extends null
    ? null
    : never;

type PickableKeys<T extends PickableRecord | null> = T extends null
  ? T extends PickableRecord
    ? Array<keyof T>
    : []
  : T extends PickableRecord
    ? Array<keyof T>
    : [];

type PickableRecord = Record<string | number | symbol, unknown>;
