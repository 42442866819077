import { autoinject, bindable, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { ThingGroupHelper } from 'common/EntityHelper/ThingGroupHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ThingGroup } from '../../classes/EntityManager/entities/ThingGroup/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { EntityName } from '../../classes/EntityManager/entities/types';

/**
 * @event {ValueChangedEvent} value-changed
 */
@autoinject()
export class ThingGroupSelect {
  /**
   * thingGroupId
   */
  @bindable public value: string | null = null;

  /**
   * read-only
   */
  @bindable public selectedThingGroup: ThingGroup | null = null;

  /**
   * the userGroupId to limit the thing groups to select
   */
  @bindable public userGroupId: string | null = null;

  /**
   * possibility to override the default nullOptions (look at the custom-select for further reference on the null option)
   *
   * null will show the default nullOption
   */
  @bindable public nullOption: string | null = null;

  @bindable public enabled: boolean = false;

  @bindable public noLabel: boolean = false;

  private i18n: I18N;
  private subscriptionManager: SubscriptionManager;

  private isAttached: boolean = false;
  private availableThingGroupOptions: Array<TThingGroupOption> = [];
  private thingGroupsFilteredInfoTextVisible: boolean = false;

  @observable private selectedThingGroupOption: TThingGroupOption | null;

  private domElement: HTMLElement;

  constructor(
    element: Element,
    i18n: I18N,
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.i18n = i18n;

    this.selectedThingGroupOption = null;
  }

  protected attached(): void {
    this.isAttached = true;
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ThingGroup,
      this.updateAvailableThingGroups.bind(this)
    );
    this.updateAvailableThingGroups();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected userGroupIdChanged(): void {
    if (this.isAttached) {
      this.updateAvailableThingGroups();
    }
  }

  private selectedThingGroupOptionChanged(): void {
    if (this.selectedThingGroupOption) {
      this.selectedThingGroup = this.selectedThingGroupOption.thingGroup;
    } else {
      this.selectedThingGroup = null;
    }
  }

  private updateAvailableThingGroups(): void {
    let availableThingGroups = [];

    if (this.userGroupId) {
      availableThingGroups =
        this.entityManager.thingGroupRepository.getByUserGroupId(
          this.userGroupId
        );
      const allThingGroups = this.entityManager.thingGroupRepository.getAll();

      if (availableThingGroups.length !== allThingGroups.length) {
        this.thingGroupsFilteredInfoTextVisible = true;
      }
    } else {
      availableThingGroups = this.entityManager.thingGroupRepository.getAll();
      this.thingGroupsFilteredInfoTextVisible = false;
    }

    this.availableThingGroupOptions = availableThingGroups.map((thingGroup) => {
      return {
        thingGroup: thingGroup,
        customSelectSubLabel: ThingGroupHelper.getThingGroupAddressString(
          thingGroup.streetName,
          thingGroup.zip,
          thingGroup.municipality
        )
      };
    });
  }

  private handleSelectChanged(): void {
    DomEventHelper.fireEvent<ValueChangedEvent>(this.domElement, {
      name: 'value-changed',
      detail: {
        value: this.value
      }
    });
  }

  private translate(key: string): string {
    return this.i18n.tr('inputComponents.thingGroupSelect.' + key);
  }
}

type TThingGroupOption = {
  thingGroup: ThingGroup;
  customSelectSubLabel: string;
};

export type ValueChangedEvent = NamedCustomEvent<
  'value-changed',
  { value: string | null }
>;
