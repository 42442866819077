import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { ProcessTaskAppointmentSmsNotification } from '../../../Enums/ProcessTaskAppointmentSmsNotification';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProcessTaskAppointmentDto } from './ProcessTaskAppointmentDto';

export function createProcessTaskAppointmentEntityInfo<
  TEntity extends ProcessTaskAppointmentDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskAppointmentDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskAppointment,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskAppointment,
    synchronizeRelativeTo: [EntityName.ProcessTaskGroup],
    trackGlobalRevision: false,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskAppointmentDto<string, string>,
      TEntity
    >(
      {
        ...ProcessTaskSubEntityUtils.createBaseFieldInfos(),
        coordinatorUserId: {
          name: 'coordinatorUserId',
          ref: EntityName.User
        },
        processConfigurationStepId: {
          name: 'processConfigurationStepId',
          ref: EntityName.ProcessConfigurationStep,
          defaultValue: null
        },
        processConfigurationStepAutoAppointmentId: {
          name: 'processConfigurationStepAutoAppointmentId',
          ref: EntityName.ProcessConfigurationStepAutoAppointment,
          defaultValue: null
        },
        createdFromAppointmentId: {
          name: 'createdFromAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          defaultValue: null
        },
        name: {
          name: 'name',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        workNote: {
          name: 'workNote',
          defaultValue: null
        },
        smsNotification: {
          name: 'smsNotification',
          defaultValue: ProcessTaskAppointmentSmsNotification.DEFAULT
        },
        processConfigurationAutoFormsCreated: {
          name: 'processConfigurationAutoFormsCreated',
          defaultValue: false
        },
        openedAt: {
          name: 'openedAt',
          defaultValue: null
        },
        finishedAt: {
          name: 'finishedAt',
          defaultValue: null
        },
        finishedAsPlanned: {
          name: 'finishedAsPlanned',
          defaultValue: null
        },
        recurringAppointmentId: {
          name: 'recurringAppointmentId',
          defaultValue: null,
          ref: EntityName.ProcessTaskRecurringAppointment
        },
        color: {
          name: 'color',
          defaultValue: null
        },
        secondaryColor: {
          name: 'secondaryColor',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
