import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, RouterEvent } from 'aurelia-router';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { Disposable } from '../../classes/Utils/DisposableContainer';
import { RxjsUtils } from '../../classes/Utils/RxjsUtils';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

@autoinject()
export class HistoryService {
  private readonly subscriptionManager: SubscriptionManager;

  private readonly canNavigateBack$ = new BehaviorSubject<boolean>(false);

  private navigationCounter: number = 0;
  private lastFragment: string | null = null;
  private ignoreNextCounterIncrement = false;
  private previousInstruction: NavigationInstruction | null = null;

  constructor(subscriptionManagerService: SubscriptionManagerService) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public init(): void {
    this.subscriptionManager.subscribeToEvent(
      RouterEvent.Processing,
      (payload: { instruction: NavigationInstruction }) => {
        this.previousInstruction = payload.instruction.previousInstruction;
      }
    );

    this.subscriptionManager.subscribeToEvent(
      RouterEvent.Success,
      (payload: { instruction: NavigationInstruction }) => {
        const currentFragment = payload.instruction.fragment;
        if (this.lastFragment == null) {
          this.lastFragment = currentFragment;
        } else if (this.lastFragment !== currentFragment) {
          this.lastFragment = currentFragment;

          if (!this.ignoreNextCounterIncrement) {
            this.navigationCounter++;
          }
          this.ignoreNextCounterIncrement = false;

          this.updateCanNavigateBack();
        }
      }
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public navigateBack(): void {
    if (this.canNavigateBack$.getValue()) {
      this.navigationCounter--;
      this.updateCanNavigateBack();
      this.ignoreNextCounterIncrement = true;
      history.back();
    }
  }

  public bindCanNavigateBack(
    callback: (canNavigateBack: boolean) => void
  ): Disposable {
    const subscription = this.canNavigateBack$.subscribe(callback);

    return RxjsUtils.subscriptionToDisposable(subscription);
  }

  public getPreviousInstruction(): NavigationInstruction | null {
    return this.previousInstruction;
  }

  private updateCanNavigateBack(): void {
    this.canNavigateBack$.next(this.navigationCounter > 0);
  }
}
