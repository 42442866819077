export class HighlightAnimator {
  /** @type {THighlightAnimatorOptions} */
  static defaultOptions = {
    duration: 1000
  };

  /** @type {THighlightAnimatorOptions} */
  _options;

  /**
   *
   * @param {HTMLElement} element
   * @param {(THighlightAnimatorOptions|null)} [options]
   */
  constructor(element, options) {
    this._element = element;
    this._options = _.assign(
      {},
      HighlightAnimator.defaultOptions,
      options || {}
    );
  }

  highlightBackground(color = '#f67d00') {
    $.Velocity(
      this._element,
      {
        backgroundColor: [color, color],
        backgroundColorAlpha: [1, 0]
      },
      {
        loop: 1,
        duration: this._options.duration / 2
      }
    );
  }
}

/**
 * @typedef {Object} THighlightAnimatorOptions
 * @property {number} [duration]
 */
