import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessConfigurationDto } from './ProcessConfigurationDto';

export function createProcessConfigurationEntityInfo<
  TEntity extends ProcessConfigurationDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessConfigurationDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessConfiguration,
  TEntity
> {
  return {
    entityName: EntityName.ProcessConfiguration,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessConfigurationDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        entityCreationUserGroupId: {
          name: 'entityCreationUserGroupId',
          defaultValue: null
        },
        offerReportTypeId: {
          name: 'offerReportTypeId',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        invoiceReportTypeId: {
          name: 'invoiceReportTypeId',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        measurePointsReportTypeId: {
          name: 'measurePointsReportTypeId',
          defaultValue: null,
          ref: EntityName.ReportType
        },
        fieldUseDevicesNoBankAccountDataWarning: {
          name: 'fieldUseDevicesNoBankAccountDataWarning',
          defaultValue: false
        },
        emailClosing: {
          name: 'emailClosing',
          defaultValue: null
        },
        appointmentSmsClosing: {
          name: 'appointmentSmsClosing',
          defaultValue: null
        },
        useAppointmentConfirmDialog: {
          name: 'useAppointmentConfirmDialog',
          defaultValue: false
        },
        useDetailEnabledForPositionsByDefault: {
          name: 'useDetailEnabledForPositionsByDefault',
          defaultValue: true
        },
        appointmentConfirmConfigurationJson: {
          name: 'appointmentConfirmConfigurationJson',
          defaultValue: null
        },
        devicePropertiesConfigurationJson: {
          name: 'devicePropertiesConfigurationJson',
          defaultValue: null
        },
        deviceGroupingConfigurationJson: {
          name: 'deviceGroupingConfigurationJson',
          defaultValue: null
        },
        processTaskPropertiesConfigurationJson: {
          name: 'processTaskPropertiesConfigurationJson',
          defaultValue: null
        },
        positionPropertiesConfigurationJson: {
          name: 'positionPropertiesConfigurationJson',
          defaultValue: null
        },
        appointmentPropertiesConfigurationJson: {
          name: 'appointmentPropertiesConfigurationJson',
          defaultValue: null
        },
        configPositionConfigurationJson: {
          name: 'configPositionConfigurationJson',
          defaultValue: null
        },
        customPositionTypeConfigurationJson: {
          name: 'customPositionTypeConfigurationJson',
          defaultValue: null
        },
        positionGroupingConfigurationJson: {
          name: 'positionGroupingConfigurationJson',
          defaultValue: null
        },
        offerConfigurationJson: {
          name: 'offerConfigurationJson',
          defaultValue: null
        },
        showProcessTaskFeatures: {
          name: 'showProcessTaskFeatures',
          innerInfo: {
            actionStatus: {
              name: 'actionStatus',
              defaultValue: true
            },
            contactPerson: {
              name: 'contactPerson',
              defaultValue: true
            },
            offersAndInvoices: {
              name: 'offersAndInvoices',
              defaultValue: true
            },
            ownerPerson: {
              name: 'ownerPerson',
              defaultValue: true
            }
          },
          defaultValue: () => ({
            actionStatus: true,
            contactPerson: true,
            offersAndInvoices: true,
            ownerPerson: true
          })
        },
        showProcessTaskInfoSections: {
          name: 'showProcessTaskInfoSections',
          innerInfo: {
            assignee: {
              name: 'assignee',
              defaultValue: true
            },
            followUpButtons: {
              name: 'followUpButtons',
              defaultValue: true
            },
            notes: {
              name: 'notes',
              defaultValue: true
            },
            persons: {
              name: 'persons',
              defaultValue: true
            },
            processTaskList: {
              name: 'processTaskList',
              defaultValue: true
            },
            referenceCode: {
              name: 'referenceCode',
              defaultValue: true
            },
            thingInfo: {
              name: 'thingInfo',
              defaultValue: true
            }
          },
          defaultValue: () => ({
            referenceCode: true,
            assignee: true,
            notes: true,
            followUpButtons: true,
            persons: true,
            thingInfo: true,
            processTaskList: true
          })
        },
        showProcessAppointmentTabs: {
          name: 'showProcessAppointmentTabs',
          innerInfo: {
            address: {
              name: 'address',
              defaultValue: true
            },
            allOfferedPositions: {
              name: 'allOfferedPositions',
              defaultValue: false
            },
            appointmentOverview: {
              name: 'appointmentOverview',
              defaultValue: true
            },
            checklist: {
              name: 'checklist',
              defaultValue: true
            },
            comments: {
              name: 'comments',
              defaultValue: true
            },
            devices: {
              name: 'devices',
              defaultValue: true
            },
            forms: {
              name: 'forms',
              defaultValue: true
            },
            general: {
              name: 'general',
              defaultValue: true
            },
            measurePoints: {
              name: 'measurePoints',
              defaultValue: true
            },
            phone: {
              name: 'phone',
              defaultValue: true
            },
            pictures: {
              name: 'pictures',
              defaultValue: true
            },
            positions: {
              name: 'positions',
              defaultValue: true
            },
            worknote: {
              name: 'worknote',
              defaultValue: true
            }
          },
          defaultValue: () => ({
            address: true,
            phone: true,
            general: true,
            measurePoints: true,
            pictures: true,
            devices: true,
            positions: true,
            forms: true,
            appointmentOverview: true,
            worknote: true,
            comments: true,
            allOfferedPositions: false,
            checklist: true
          })
        },
        processAppointmentTabsToExpand: {
          name: 'processAppointmentTabsToExpand',
          innerInfo: {
            address: {
              name: 'address',
              defaultValue: false
            },
            phone: {
              name: 'phone',
              defaultValue: false
            },
            general: {
              name: 'general',
              defaultValue: false
            },
            measurePoints: {
              name: 'measurePoints',
              defaultValue: false
            },
            pictures: {
              name: 'pictures',
              defaultValue: false
            },
            devices: {
              name: 'devices',
              defaultValue: false
            },
            positions: {
              name: 'positions',
              defaultValue: false
            },
            forms: {
              name: 'forms',
              defaultValue: false
            },
            appointmentOverview: {
              name: 'appointmentOverview',
              defaultValue: false
            },
            worknote: {
              name: 'worknote',
              defaultValue: false
            },
            comments: {
              name: 'comments',
              defaultValue: false
            },
            allOfferedPositions: {
              name: 'allOfferedPositions',
              defaultValue: false
            },
            checklist: {
              name: 'checklist',
              defaultValue: false
            }
          },
          defaultValue: () => ({
            address: false,
            phone: false,
            general: false,
            measurePoints: false,
            pictures: false,
            devices: false,
            positions: false,
            forms: false,
            appointmentOverview: false,
            worknote: false,
            comments: false,
            allOfferedPositions: false,
            checklist: false
          })
        },
        missedAppointmentContactUserId: {
          name: 'missedAppointmentContactUserId',
          defaultValue: null,
          ref: EntityName.User
        },
        configurableDisplayText: {
          name: 'configurableDisplayText',
          innerInfo: {
            processTaskAppointmentDefaultName: {
              name: 'processTaskAppointmentDefaultName',
              defaultValue: null
            },
            processTaskGroupOverviewProcessTaskGroupTitle: {
              name: 'processTaskGroupOverviewProcessTaskGroupTitle',
              defaultValue: null
            },
            processTaskGroupOverviewProcessTaskTitle: {
              name: 'processTaskGroupOverviewProcessTaskTitle',
              defaultValue: null
            },
            processTaskAppointmentsInCalendarView: {
              name: 'processTaskAppointmentsInCalendarView',
              innerInfo: {
                firstLine: {
                  name: 'firstLine',
                  defaultValue: null
                },
                secondLine: {
                  name: 'secondLine',
                  defaultValue: null
                },
                thirdLine: {
                  name: 'thirdLine',
                  defaultValue: null
                }
              },
              defaultValue: () => ({})
            }
          },
          defaultValue: () => ({})
        },
        configurableEmailText: {
          name: 'configurableEmailText',
          innerInfo: {
            appointmentNotification: {
              name: 'appointmentNotification',
              defaultValue: null
            }
          },
          defaultValue: () => ({})
        },
        contactPersonPurposes: {
          name: 'contactPersonPurposes',
          innerInfo: [
            {
              label: {
                name: 'label',
                defaultValue: null
              },
              value: {
                name: 'value',
                defaultValue: null
              },
              restrictToCategory: {
                name: 'restrictToCategory',
                defaultValue: null
              },
              restrictToThingGroupOwnerPersonRelations: {
                name: 'restrictToThingGroupOwnerPersonRelations',
                defaultValue: false
              }
            }
          ],
          defaultValue: () => []
        },
        sendEmailAppointmentNotificationsToWorkers: {
          name: 'sendEmailAppointmentNotificationsToWorkers',
          defaultValue: false
        },
        processTaskGroupRelations: {
          name: 'processTaskGroupRelations',
          innerInfo: {
            enabled: {
              name: 'enabled',
              defaultValue: false
            },
            mainInfoDisplayText: {
              name: 'mainInfoDisplayText',
              defaultValue: null
            },
            subInfoDisplayText: {
              name: 'subInfoDisplayText',
              defaultValue: null
            }
          },
          defaultValue: () => ({
            enabled: false,
            mainInfoDisplayText: null,
            subInfoDisplayText: null
          })
        },
        processTaskToProjectRelations: {
          name: 'processTaskToProjectRelations',
          innerInfo: {
            enabled: {
              name: 'enabled',
              defaultValue: false
            },
            processTaskMainInfoText: {
              name: 'processTaskMainInfoText',
              defaultValue: null
            },
            processTaskSubInfoText: {
              name: 'processTaskSubInfoText',
              defaultValue: null
            }
          },
          defaultValue: () => ({
            enabled: false,
            processTaskMainInfoText: null,
            processTaskSubInfoText: null
          })
        }
      },
      options.additionalFieldInfos
    )
  };
}
