import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { GetProcessTaskGroupsFilterAddressFilterType } from '../../../EndpointTypes/OperationsEndpointsTypes';
import { ProcessTaskGroupFilterMode } from '../../../Enums/ProcessTaskGroupFilterMode';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { UserGroupSubEntityUtils } from '../../BaseEntities/UserGroupSubEntityUtils';
import { ProcessTaskGroupFilterDto } from './ProcessTaskGroupFilterDto';

export function createProcessTaskGroupFilterEntityInfo<
  TEntity extends ProcessTaskGroupFilterDto<string, string>
>(
  options: CreateEntityInfoOptions<
    ProcessTaskGroupFilterDto<string, string>,
    TEntity
  >
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProcessTaskGroupFilter,
  TEntity
> {
  return {
    entityName: EntityName.ProcessTaskGroupFilter,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProcessTaskGroupFilterDto<string, string>,
      TEntity
    >(
      {
        ...UserGroupSubEntityUtils.createBaseFieldInfos(),
        processConfigurationId: {
          name: 'processConfigurationId',
          ref: EntityName.ProcessConfiguration,
          cascadeDeleteLocally: true
        },
        name: {
          name: 'name'
        },
        filterMode: {
          name: 'filterMode',
          defaultValue: ProcessTaskGroupFilterMode.NEXT_DAYS
        },
        addressFilterType: {
          name: 'addressFilterType',
          defaultValue: GetProcessTaskGroupsFilterAddressFilterType.ALL
        },
        selectedProcessConfigurationStepIds: {
          name: 'selectedProcessConfigurationStepIds',
          defaultValue: () => []
        },
        excludedProcessConfigurationStepIds: {
          name: 'excludedProcessConfigurationStepIds',
          defaultValue: () => []
        },
        includeArchive: {
          name: 'includeArchive',
          defaultValue: false
        },
        assigneeUserId: {
          name: 'assigneeUserId',
          defaultValue: null
        },
        selectedActionStatusOptionType: {
          name: 'selectedActionStatusOptionType',
          defaultValue: null
        },
        selectedActionStatusId: {
          name: 'selectedActionStatusId',
          defaultValue: null
        },
        customActionStatusFilterText: {
          name: 'customActionStatusFilterText',
          defaultValue: null
        },
        lastEditedBeforeDaysEnabled: {
          name: 'lastEditedBeforeDaysEnabled',
          defaultValue: null
        },
        lastEditedBeforeDays: {
          name: 'lastEditedBeforeDays',
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
