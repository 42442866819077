import { autoinject } from 'aurelia-framework';
import { projectTypesOnObjectPage } from 'common/Types/Entities/Project/ProjectDto';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProjectActionService } from '../../../classes/EntityManager/entities/Project/ProjectActionService';
import { ThingActionService } from '../../../classes/EntityManager/entities/Thing/ThingActionService';
import { CurrentUserService } from '../../../classes/EntityManager/entities/User/CurrentUserService';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { PermissionHelper } from '../../../classes/PermissionHelper';
import { RecordItModuleHelper } from '../../../classes/RecordItModuleHelper';
import { EntityWidgetAdapter } from '../../../homePageComponents/EntityWidgetAdapter/EntityWidgetAdapter';
import { ProjectWidgetAdapter } from '../../../homePageComponents/EntityWidgetAdapter/ProjectWidgetAdapter';
import { ThingWidgetAdapter } from '../../../homePageComponents/EntityWidgetAdapter/ThingWidgetAdapter';
import { computed } from '../../../hooks/computed';
import { currentUser } from '../../../hooks/dependencies';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';
import { SocketService } from '../../../services/SocketService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';

@autoinject()
export class EntityWidgetHomepage {
  private entityWidgetAdapters: Array<EntityWidgetAdapter> = [];

  private thingAdapter: EntityWidgetAdapter;

  private projectAdapter: EntityWidgetAdapter;

  constructor(
    entityManager: AppEntityManager,
    activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly currentUserService: CurrentUserService,
    socketService: SocketService,
    thingActionService: ThingActionService,
    projectActionService: ProjectActionService,
    permissionsService: PermissionsService
  ) {
    this.thingAdapter = new ThingWidgetAdapter({
      entityManager: entityManager,
      activeUserCompanySettingService: activeUserCompanySettingService,
      subscriptionManagerService: subscriptionManagerService,
      currentUserService: currentUserService,
      thingActionService: thingActionService,
      permissionsService: permissionsService
    });

    this.projectAdapter = new ProjectWidgetAdapter({
      activeUserCompanySettingService: activeUserCompanySettingService,
      currentUserService: currentUserService,
      entityManager: entityManager,
      projectActionService: projectActionService,
      socketService: socketService,
      subscriptionManagerService: subscriptionManagerService,
      permissionsService
    });
  }

  @computed(currentUser())
  protected get displayedAdapters(): Array<EntityWidgetAdapter> {
    const adapters = [];

    adapters.push(this.thingAdapter);
    if (this.includeProjectWidgetAdapter()) adapters.push(this.projectAdapter);

    return adapters;
  }

  private includeProjectWidgetAdapter(): boolean {
    const currentUserEntity = this.currentUserService.getCurrentUser();
    if (!currentUserEntity) return false;

    return PermissionHelper.getAvailableModulesForUser(currentUserEntity)
      .map((moduleName) =>
        RecordItModuleHelper.getProjectTypeForModuleName(moduleName)
      )
      .some(
        (projectType) =>
          projectType && projectTypesOnObjectPage().includes(projectType)
      );
  }
}
