import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  StructureTemplateEntryProperty,
  StructureTemplateEntryStructureTemplateEntryProperty,
  StructureTemplateStructureTemplateEntryProperty
} from './types';

export class StructureTemplateEntryPropertyRepository extends AppEntityRepository<EntityName.StructureTemplateEntryProperty> {
  public getByStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateStructureTemplateEntryProperty> {
    return this.getAll().filter(
      (p): p is StructureTemplateStructureTemplateEntryProperty => {
        return (
          p.ownerStructureTemplateId === structureTemplateId &&
          !p.structureTemplateEntryId
        );
      }
    );
  }

  public getByStructureTemplateEntryId(
    structureTemplateEntryId: string
  ): Array<StructureTemplateEntryStructureTemplateEntryProperty> {
    return this.getAll().filter(
      (p): p is StructureTemplateEntryStructureTemplateEntryProperty => {
        return p.structureTemplateEntryId === structureTemplateEntryId;
      }
    );
  }

  public getByOwnerStructureTemplateId(
    structureTemplateId: string
  ): Array<StructureTemplateEntryProperty> {
    return this.getAll().filter(
      (p): p is StructureTemplateStructureTemplateEntryProperty => {
        return p.ownerStructureTemplateId === structureTemplateId;
      }
    );
  }
}
