import { autoinject, bindable } from 'aurelia-framework';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskToProjectFormCreationService } from '../../classes/EntityManager/entities/ProcessTaskToProject/ProcessTaskToProjectFormCreationService';

@autoinject()
export class CreateProcessTaskFormButton {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public enabled: boolean = false;

  @bindable()
  public processTaskAppointmentId: string | null = null;

  private configurationForms: Array<ProcessConfigurationForm> = [];

  protected tooltipContent: TooltipContent | null = null;

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskToProjectFormCreationService: ProcessTaskToProjectFormCreationService
  ) {}

  protected handleCreateButtonClick(): void {
    if (!this.tooltipContent) {
      return;
    }

    this.updateConfigurationForms();

    this.tooltipContent.open();
  }

  private updateConfigurationForms(): void {
    const processTaskGroup = this.processTask
      ? this.entityManager.processTaskGroupRepository.getById(
          this.processTask.ownerProcessTaskGroupId
        )
      : null;
    this.configurationForms = processTaskGroup
      ? this.entityManager.processConfigurationFormRepository.getByProcessConfigurationId(
          processTaskGroup.processConfigurationId
        )
      : [];
  }

  protected handleConfigurationFormClick(
    configurationForm: ProcessConfigurationForm
  ): void {
    if (!this.processTask) {
      return;
    }

    const processTaskAppointment = this.processTaskAppointmentId
      ? this.entityManager.processTaskAppointmentRepository.getById(
          this.processTaskAppointmentId
        )
      : null;
    this.processTaskToProjectFormCreationService.createForm(
      this.processTask,
      processTaskAppointment,
      configurationForm
    );

    DomEventHelper.fireEvent(this.element, {
      name: 'process-task-form-created',
      detail: null
    });

    if (this.tooltipContent) {
      this.tooltipContent.close();
    }
  }
}
