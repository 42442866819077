import {
  EntityGrouper as CommonEntityGrouper,
  GroupConfiguration,
  EntityGroup
} from 'common/EntityGrouper/EntityGrouper';
import { Property } from './EntityManager/entities/Property/types';

export class EntityGrouper<T extends { id: string }> {
  private commonGrouper: CommonEntityGrouper<T, Property>;

  constructor(options: Options) {
    this.commonGrouper = new CommonEntityGrouper({
      ...options,
      entityIdGetter: (entity) => entity.id,
      propertyInfoGetter: (property) => ({
        referencedEntityId: String(property[options.propertyEntityIdField]),
        customChoice: property.custom_choice,
        name: property.name,
        type: property.type,
        value: property.value
      })
    });
  }

  public setGroupConfigurations(
    groupConfigurations: Array<GroupConfiguration>
  ): void {
    this.commonGrouper.setGroupConfigurations(groupConfigurations);
  }

  public setProperties(properties: Array<Property>): void {
    this.commonGrouper.setProperties(properties);
  }

  public groupEntities(entities: Array<T>): Array<EntityGroup<T>> {
    return this.commonGrouper.groupEntities(entities);
  }
}

export type Options = {
  groupConfigurations: Array<GroupConfiguration>;
  propertyEntityIdField: keyof Property;
  properties: Array<Property>;
};

export { GroupConfigurationType } from 'common/EntityGrouper/EntityGrouper';
export type {
  GroupConfiguration,
  EntityGroup
} from 'common/EntityGrouper/EntityGrouper';
