import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  CreateFollowUpProcessTaskAppointmentDialog,
  RelativeTo
} from '../create-follow-up-process-task-appointment-dialog/create-follow-up-process-task-appointment-dialog';
import { DataStorageHelper } from '../../classes/DataStorageHelper/DataStorageHelper';
import { IUtilsRateLimitedFunction, Utils } from '../../classes/Utils/Utils';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { ProcessConfigurationFollowUpAppointment } from '../../classes/EntityManager/entities/ProcessConfigurationFollowUpAppointment/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskAppointmentFollowUpButtons {
  private static RELATIVE_TO_STORAGE_KEY =
    'ProcessTaskAppointmentFollowUpButtons::relativeTo';

  @bindable()
  public appointment: ProcessTaskAppointment | null = null;

  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processTaskPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTask];

  private isAttached: boolean = false;
  protected followUpAppointments: Array<ProcessConfigurationFollowUpAppointment> =
    [];

  private relativeTo: RelativeTo = RelativeTo.Today;

  private saveRelativeToRateLimited: IUtilsRateLimitedFunction;

  protected RelativeTo = RelativeTo;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.processTaskPermissionsHandle =
      permissionsService.getPermissionsHandleForEntityIdOfPropertyValue({
        entityName: EntityName.ProcessTask,
        context: this as ProcessTaskAppointmentFollowUpButtons,
        propertyName: 'appointment',
        idPropertyName: 'ownerProcessTaskId'
      });

    this.saveRelativeToRateLimited = Utils.rateLimitFunction(
      this.saveRelativeTo.bind(this),
      200
    );
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationFollowUpAppointment,
      this.updateFollowUpButtons.bind(this)
    );
    this.updateFollowUpButtons();

    void DataStorageHelper.getItem(
      ProcessTaskAppointmentFollowUpButtons.RELATIVE_TO_STORAGE_KEY
    ).then((relativeTo) => {
      if (relativeTo) {
        this.relativeTo = relativeTo;
      }
    });
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected appointmentChanged(): void {
    if (this.isAttached) {
      this.updateFollowUpButtons();
    }
  }

  private updateFollowUpButtons(): void {
    const processTaskGroup = this.appointment
      ? this.entityManager.processTaskGroupRepository.getById(
          this.appointment.ownerProcessTaskGroupId
        )
      : null;

    if (processTaskGroup) {
      this.followUpAppointments =
        this.entityManager.processConfigurationFollowUpAppointmentRepository.getByProcessConfigurationId(
          processTaskGroup.processConfigurationId
        );
    } else {
      this.followUpAppointments = [];
    }
  }

  protected handleFollowUpAppointmentButtonClick(
    followUpAppointment: ProcessConfigurationFollowUpAppointment
  ): void {
    if (!this.appointment) {
      throw new Error(
        "can't create a follow up appointment without an appointment"
      );
    }

    void CreateFollowUpProcessTaskAppointmentDialog.open({
      appointment: this.appointment,
      processTask: null,
      relativeTo: this.relativeTo,
      processConfigurationFollowUpAppointment: followUpAppointment
    });
  }

  protected getButtonStyle(
    followUpAppointment: ProcessConfigurationFollowUpAppointment
  ): Record<string, string> {
    const style: Record<string, string> = {};

    if (followUpAppointment.color) {
      style['background-color'] = followUpAppointment.color;
    }

    return style;
  }

  protected handleRelativeToChanged(): void {
    this.saveRelativeToRateLimited();
  }

  private saveRelativeTo(): void {
    void DataStorageHelper.setItem(
      ProcessTaskAppointmentFollowUpButtons.RELATIVE_TO_STORAGE_KEY,
      this.relativeTo
    );
  }
}
