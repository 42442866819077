import { Container } from 'aurelia-framework';
import { SubscriptionManagerService } from '../services/SubscriptionManagerService';
import { setupMount, setupUnmount } from './configureHooks';
import { Dependency } from './dependencies';
import { Opt } from 'common/Opt';
import {
  ChangeProp,
  getChangeProp,
  getHookStateProp,
  HookState,
  HookStateProp
} from './utils';

/**
 * Indicates that the decorated getter is computed from dependencies.
 * The return value of the getter is cached until any of the dependencies change.
 *
 * @example
 * // someFunction() will only recalculate if a Thing entity or the enabled property updates
 * \@computed(model(EntityName.Thing), property('enabled'))
 * protected get someFunction() {
 *   [...]
 * }
 */
export function computed(...deps: Array<Dependency>) {
  return function (target: any, prop: string, descriptor: PropertyDescriptor) {
    // Save the old getter function for later
    const oldFn = descriptor.get;

    if (!oldFn) {
      throw new Error('@computed only supports getters.');
    }

    const changeProp = getChangeProp(prop);
    /*
     * Retrieve the property where internal state is stored
     *
     * State (Cached Value, SubscriptionManager, etc.) cannot be stored locally because
     * one class can have multiple computed
     */
    const stateProp = getHookStateProp(prop);

    // We initialize our dependencies when mounting
    setupMount(target, function (this: This<typeof prop>) {
      // Load necessary services
      const subManagerService = Container.instance.get(
        SubscriptionManagerService
      );

      const subscriptionManager = subManagerService.create();
      this[stateProp] = {
        cachedValue: Opt.none(),
        ...this[stateProp],
        subscriptionManager
      };
      const updateVal = (): void => {
        // Update the cached value
        const state = this[stateProp];
        if (state) state.cachedValue = Opt.some(oldFn.apply(this));

        // Update changeProp & make Aurelia call this getter
        const changeValue = this[changeProp];
        this[changeProp] = changeValue ? ~changeValue : 1;
      };

      // Initialize the dependencies
      for (const dependency of deps) {
        dependency({
          target: this,
          subscriptionManager,
          onChange: updateVal
        });
      }

      // If the value has already been set from the getter,
      // we must call `updateVal()` because it might
      // have changed in the meantime
      // TODO: This results in one call that is *maybe* unnecessary, clean up later
      if (this[stateProp]?.cachedValue?.isSome() ?? false) {
        updateVal();
      }
    });

    /*
     * The subscriptions need to be destroyed when unmounting
     */
    setupUnmount(target, function (this: This<typeof prop>) {
      const subscriptionManager = this[stateProp]?.subscriptionManager;
      subscriptionManager?.disposeSubscriptions();
    });

    // Just return the cached value in our getter
    descriptor.get = function (this: This<typeof prop>) {
      /**
       * TS Caching is disabled for now - Aurelia's ExpressionObserver doesn't update
       * computed getters immediately when dependencies change, causing those getters
       * to return outdated values when used in TS immediately after updating values used
       * in them.
       *
       * This could work if we rewrite the ExpressionObserver to be synchronous like
       * the PropertyObserver. Ask Jan / Alex for more details.
       */
      /*
      let value = this[stateProp]?.cachedValue;
      if(value?.isNone() ?? true) {
        // In the scenario the getter is called before a dependency executes, we execute oldFn here
        value = Opt.some(oldFn.apply(this));
        this[stateProp] = {
          ...this[stateProp] as any,
          cachedValue: value
        };
      }
      return (value as Opt<any, true>|undefined)?.getVal();
      */

      const value = Opt.some(oldFn.apply(this));
      this[stateProp] = {
        ...(this[stateProp] as any),
        cachedValue: value
      };
      return (value as Opt<any, true> | undefined)?.getVal();
    };

    // Now, declare our dependencies - this will make Aurelia only call this getter whenever `changeProp` changes
    (descriptor.get as any).dependencies = [changeProp];

    return descriptor;
  };
}

type ComputedHookState<T = any> = HookState & {
  /**
   * The getter result is cached here
   */
  cachedValue: Opt<T>;
};

type This<Prop extends string, ValueType = any> = {
  [changeProp in `${ChangeProp<Prop>}`]: number;
} & {
  [hookStateProp in `${HookStateProp<Prop>}`]:
    | ComputedHookState<ValueType>
    | undefined;
};
