import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskRecurringAppointment } from './types';

export class ProcessTaskRecurringAppointmentRepository extends AppEntityRepository<EntityName.ProcessTaskRecurringAppointment> {
  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskRecurringAppointment> {
    return this.getAll().filter((a) => a.ownerProcessTaskId === processTaskId);
  }
}
