import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskAppointment } from './types';

export class ProcessTaskAppointmentRepository extends AppEntityRepository<EntityName.ProcessTaskAppointment> {
  public getByProcessTaskId(
    processTaskId: string
  ): Array<ProcessTaskAppointment> {
    return this.getAll().filter((a) => a.ownerProcessTaskId === processTaskId);
  }

  public getByRecurringAppointmentId(
    id: string
  ): Array<ProcessTaskAppointment> {
    return this.getAll().filter((a) => a.recurringAppointmentId === id);
  }
}
