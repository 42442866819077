import { autoinject, bindable } from 'aurelia-framework';

import { ProcessTaskAppointmentPropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProcessTaskAppointmentPropertyAdapter';
import { ProcessTaskAppointment } from '../../classes/EntityManager/entities/ProcessTaskAppointment/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { PropertyInputFieldListWithDefaultProperties } from '../../aureliaComponents/property-input-field-list-with-default-properties/property-input-field-list-with-default-properties';
import { ProcessTaskAppointmentProperty } from '../../classes/EntityManager/entities/Property/types';

@autoinject()
export class ProcessTaskAppointmentProperties {
  @bindable()
  public processTaskAppointment: ProcessTaskAppointment | null = null;

  protected list: PropertyInputFieldListWithDefaultProperties<
    ProcessTaskAppointmentProperty,
    any
  > | null = null;

  protected adapter: ProcessTaskAppointmentPropertyAdapter | null = null;

  private isAttached = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly permissionsService: PermissionsService,
    private readonly subscriptionManagerService: SubscriptionManagerService
  ) {}

  public focusProperty(property: ProcessTaskAppointmentProperty): void {
    this.list?.focusProperty(property);
  }

  protected attached(): void {
    this.isAttached = true;
    this.updateAdapter();
  }

  protected detached(): void {
    this.isAttached = false;
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateAdapter();
    }
  }

  private updateAdapter(): void {
    if (this.processTaskAppointment) {
      this.adapter = new ProcessTaskAppointmentPropertyAdapter({
        appointment: this.processTaskAppointment,
        entityManager: this.entityManager,
        permissionsService: this.permissionsService,
        subscriptionManagerService: this.subscriptionManagerService
      });
    } else {
      this.adapter = null;
    }
  }
}
