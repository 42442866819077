import { autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { EditProcessConfigurationFormDialog } from '../../operationsComponents/edit-process-configuration-form-dialog/edit-process-configuration-form-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import {
  ProcessConfigurationFormSortOption,
  ProcessConfigurationFormUtils
} from '../../classes/EntityManager/entities/ProcessConfigurationForm/ProcessConfigurationFormUtils';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationForm } from '../../classes/EntityManager/entities/ProcessConfigurationForm/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class edit_process_configuration_forms {
  private readonly subscriptionManager: SubscriptionManager;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  protected readonly sortOptions =
    ProcessConfigurationFormUtils.formSortOptions;

  protected currentSortOption: ProcessConfigurationFormSortOption =
    ProcessConfigurationFormUtils.formSortOptions.name;

  private isAttached: boolean = false;
  private processConfiguration: ProcessConfiguration | null = null;
  protected forms: Array<ProcessConfigurationForm> = [];
  protected sortedForms: Array<ProcessConfigurationForm> = [];
  protected isMobile: boolean = false;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService,
    permissionsService: PermissionsService
  ) {
    this.entityManager = entityManager;
    this.subscriptionManager = subscriptionManagerService.create();

    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.ProcessConfiguration,
        context: this,
        expression: 'processConfiguration'
      });
  }

  protected activate(params: { process_configuration_id: string }): void {
    const configuration =
      this.entityManager.processConfigurationRepository.getById(
        params.process_configuration_id
      );
    if (!configuration) {
      throw new Error(
        `ProcessConfiguration "${params.process_configuration_id}" not found`
      );
    }

    this.processConfiguration = configuration;

    if (this.isAttached) {
      this.updateForms();
    }
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationForm,
      this.updateForms.bind(this)
    );
    this.updateForms();

    this.isMobile = DeviceInfoHelper.isMobile();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateForms(): void {
    if (this.processConfiguration) {
      this.forms =
        this.entityManager.processConfigurationFormRepository.getByProcessConfigurationId(
          this.processConfiguration.id
        );
    } else {
      this.forms = [];
    }
  }

  protected handleCreateFormClicked(): void {
    if (!this.processConfiguration) {
      return;
    }

    const form = this.entityManager.processConfigurationFormRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId,
      temporaryGroupName: this.processConfiguration.temporaryGroupName
    });

    this.editForm(form);
  }

  protected handleEditFormClicked(form: ProcessConfigurationForm): void {
    this.editForm(form);
  }

  private editForm(form: ProcessConfigurationForm): void {
    void EditProcessConfigurationFormDialog.open({
      processConfigurationForm: form,
      onDialogClosed: () => {
        this.goToForm(form);
      }
    });
  }

  private goToForm(form: ProcessConfigurationForm): void {
    void ScrollHelper.autoScrollToListItem(
      '#' + this.getFormListItemElementId(form.id),
      null,
      form,
      () => this.isAttached
    );
  }

  private getFormListItemElementId(formId: string): string {
    return 'edit-process-configuration-forms--form-' + formId;
  }
}
