/**
 * a class which queues functions, and calls them as soon as the previous function(s) have been executed
 * useful if you want to prevent overlapping function calls (which could be a problem with async functions)
 */
export class AsyncFunctionQueue {
  private queuedFunctions: Array<Function> = [];

  private inProgress = false;

  public queueFunction(func: () => any | Promise<any>): void {
    this.queuedFunctions.push(func);
    if (!this.inProgress) {
      this.startQueue();
    }
  }

  private startQueue(): void {
    this.inProgress = true;
    void this.handleQueuedFunctions();
  }

  private async handleQueuedFunctions(): Promise<void> {
    let currentItem;
    while ((currentItem = this.queuedFunctions.shift())) {
      await currentItem();
    }

    this.inProgress = false;
  }
}
