import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ProcessTaskAppointmentToProcessTaskPosition } from './types';

export class ProcessTaskAppointmentToProcessTaskPositionRepository extends AppEntityRepository<EntityName.ProcessTaskAppointmentToProcessTaskPosition> {
  public getByProcessTaskAppointmentId(
    processTaskAppointmentId: string
  ): Array<ProcessTaskAppointmentToProcessTaskPosition> {
    return this.getAll().filter(
      (r) => r.processTaskAppointmentId === processTaskAppointmentId
    );
  }

  public getByProcessTaskAppointmentIds(
    processTaskAppointmentIds: Array<string>
  ): Array<ProcessTaskAppointmentToProcessTaskPosition> {
    return this.getAll().filter(
      (r) => processTaskAppointmentIds.indexOf(r.processTaskAppointmentId) >= 0
    );
  }

  public getByProcessTaskPositionId(
    processTaskPositionId: string
  ): Array<ProcessTaskAppointmentToProcessTaskPosition> {
    return this.getAll().filter(
      (r) => r.processTaskPositionId === processTaskPositionId
    );
  }

  public getByProcessTaskPositionIds(
    processTaskPositionIds: Array<string>
  ): Array<ProcessTaskAppointmentToProcessTaskPosition> {
    return this.getAll().filter(
      (r) => processTaskPositionIds.indexOf(r.processTaskPositionId) >= 0
    );
  }

  public getByProcessTaskAppointmentIdAndProcessTaskPositionId(
    processTaskAppointmentId: string,
    processTaskPositionId: string
  ): ProcessTaskAppointmentToProcessTaskPosition | null {
    return (
      this.getAll().find(
        (r) =>
          r.processTaskAppointmentId === processTaskAppointmentId &&
          r.processTaskPositionId === processTaskPositionId
      ) ?? null
    );
  }
}
