import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import {
  PictureEntityIdField,
  PictureSubEntityField
} from '../../classes/EntityManager/entities/Picture/types';

/**
 * Provides a dialog to select, edit and upload pictures.
 */
export class PictureSelectAndEditDialog {
  protected options: Options | null = null;
  protected dialog: RecordItDialog | null = null;

  public static async open(options: Options): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: Options): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open PictureSelectAndEditDialog without RecordItDialog'
    );

    this.options = options;
    this.dialog.open();
  }

  protected handleDialogClosed(): void {
    this.options = null;
  }
}

type Options = {
  canCreatePictures: boolean;
  mainEntityIdField: PictureEntityIdField;
  mainEntityId: string;
  subEntityField?: PictureSubEntityField | null;
  subEntityValue?: string | null;
  ownerUserGroupId?: string | null;
  ownerProjectId?: string | null;
};
