import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { DateUtils } from '../../../DateUtils';
import { GeneralFileTypes } from '../../../Enums/GeneralFileTypes';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProcessTaskSubEntityUtils } from '../../BaseEntities/ProcessTaskSubEntityUtils';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { GeneralFileDto } from './GeneralFileDto';

export function createGeneralFileEntityInfo<
  TEntity extends GeneralFileDto<string, string>
>(
  options: CreateEntityInfoOptions<GeneralFileDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.GeneralFile,
  TEntity
> {
  return {
    entityName: EntityName.GeneralFile,
    synchronizeRelativeTo: [EntityName.Project, EntityName.ProcessTaskGroup],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      GeneralFileDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createOptionalBaseFieldInfos(),
        ...ProcessTaskSubEntityUtils.createOptionalBaseFieldInfos(),
        name: {
          name: 'name',
          defaultValue: null
        },
        fileCreatedAt: {
          name: 'fileCreatedAt',
          defaultValue: DateUtils.getCurrentTimestamp.bind(DateUtils)
        },
        fileUploadedAt: {
          name: 'fileUploadedAt',
          defaultValue: null
        },
        extension: {
          name: 'extension',
          defaultValue: null
        },
        type: {
          name: 'type',
          defaultValue: GeneralFileTypes.GENERAL
        },
        processTaskCommentId: {
          name: 'processTaskCommentId',
          ref: EntityName.ProcessTaskComment,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        processTaskAppointmentId: {
          name: 'processTaskAppointmentId',
          ref: EntityName.ProcessTaskAppointment,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        processTaskChecklistEntryId: {
          name: 'processTaskChecklistEntryId',
          ref: EntityName.ProcessTaskChecklistEntry,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        propertyId: {
          name: 'propertyId',
          ref: EntityName.Property,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        mapLayerId: {
          name: 'mapLayerId',
          ref: EntityName.MapLayer,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        defectCommentId: {
          name: 'defectCommentId',
          ref: EntityName.DefectComment,
          defaultValue: null,
          cascadeDeleteLocally: true
        },
        ownerDefectId: {
          name: 'ownerDefectId',
          ref: EntityName.Defect,
          cascadeDeleteLocally: true,
          defaultValue: null,
          additionalData: {
            ownerId: true
          }
        }
      },
      options.additionalFieldInfos
    )
  };
}
