import { Vector } from 'common/Geometry/Vector';
import { RepositionableElementPositionAndSize } from '../../repositionable-element';
import {
  PositionAndSizeTransformation as PositionAndSizeTransformation,
  RepositionableElementUtils
} from '../../RepositionableElementUtils';
import {
  PostProcessNewPositionAndSizeOptions,
  RepositionableElementFeature,
  SizeConversion
} from '../RepositionableElementFeature';

/**
 * This only works for resizing and moving, but not for rotation.
 *
 * How to use this feature:
 * * Initialize this feature in your scope (= the element which uses the repositionable-element or maybe even a higher level)
 * * pass this feature to the repositionable-element
 * * update the snapping points in your scope via the `setSnappingPoints` function whenever needed
 *
 * Also make sure that the snappingPoints are relative or absolute, based on the relativeUnits
 */
export class RepositionableElementSnappingFeature
  implements RepositionableElementFeature
{
  private static SNAPPING_DISTANCE = 10;

  private snappingPoints: Array<SnappingPoint> = [];

  public setSnappingPoints(snappingPoints: Array<SnappingPoint>): void {
    this.snappingPoints = [...snappingPoints].sort((a, b) => {
      return a.getLength() - b.getLength();
    });
  }

  public postProcessNewPositionAndSize({
    positionAndSize,
    originalTransformation,
    sizeConversion
  }: PostProcessNewPositionAndSizeOptions): RepositionableElementPositionAndSize {
    const descriptions: Array<PointOfPositionAndSizeDescription> = [
      new Vector(0, 0),
      new Vector(1, 0),
      new Vector(1, 1),
      new Vector(0, 1)
    ];

    const snappingPoints = this.getAbsoluteAndOriginalSnappingPoints({
      sizeConversion
    });

    let processedPositionAndSize = positionAndSize;

    for (const description of descriptions) {
      processedPositionAndSize = this.postProcessSpecificPoint({
        absolutePointWithDescription: this.createAbsolutePointWithDescription({
          description,
          positionAndSize: processedPositionAndSize,
          sizeConversion
        }),
        positionAndSize: processedPositionAndSize,
        originalTransformation,
        snappingPoints
      });
    }

    return processedPositionAndSize;
  }

  private getAbsoluteAndOriginalSnappingPoints({
    sizeConversion
  }: {
    sizeConversion: SizeConversion;
  }): Array<AbsoluteAndOriginalSnappingPoint> {
    return this.snappingPoints.map((snappingPoint) => {
      return {
        originalSnappingPoint: snappingPoint,
        absoluteSnappingPoint: snappingPoint
          .clone()
          .multiplyVector(sizeConversion.absoluteConversionVector)
      };
    });
  }

  private createAbsolutePointWithDescription({
    description,
    positionAndSize,
    sizeConversion
  }: {
    description: PointOfPositionAndSizeDescription;
    positionAndSize: RepositionableElementPositionAndSize;
    sizeConversion: SizeConversion;
  }): AbsolutePointWithDescription {
    const originalPoint = new Vector(
      positionAndSize.left + positionAndSize.width * description.getX(),
      positionAndSize.top + positionAndSize.height * description.getY()
    );

    return {
      description,
      originalPoint,
      absolutePoint: originalPoint
        .clone()
        .multiplyVector(sizeConversion.absoluteConversionVector)
    };
  }

  private postProcessSpecificPoint({
    absolutePointWithDescription,
    positionAndSize,
    originalTransformation,
    snappingPoints
  }: {
    absolutePointWithDescription: AbsolutePointWithDescription;
    positionAndSize: RepositionableElementPositionAndSize;
    originalTransformation: PositionAndSizeTransformation;
    snappingPoints: Array<AbsoluteAndOriginalSnappingPoint>;
  }): RepositionableElementPositionAndSize {
    for (const snappingPoint of snappingPoints) {
      if (
        this.isNearSnappingPoint({
          absolutePointWithDescription,
          snappingPoint
        })
      ) {
        return this.snapPointToSnappingPoint({
          absolutePointWithDescription,
          snappingPoint: snappingPoint,
          positionAndSize,
          originalTransformation
        });
      }
    }

    return positionAndSize;
  }

  private isNearSnappingPoint({
    absolutePointWithDescription,
    snappingPoint
  }: {
    absolutePointWithDescription: AbsolutePointWithDescription;
    snappingPoint: AbsoluteAndOriginalSnappingPoint;
  }): boolean {
    return (
      absolutePointWithDescription.absolutePoint
        .clone()
        .substractVector(snappingPoint.absoluteSnappingPoint)
        .getLength() <= RepositionableElementSnappingFeature.SNAPPING_DISTANCE
    );
  }

  private snapPointToSnappingPoint({
    absolutePointWithDescription,
    snappingPoint: snappingPoint,
    positionAndSize,
    originalTransformation
  }: {
    absolutePointWithDescription: AbsolutePointWithDescription;
    snappingPoint: AbsoluteAndOriginalSnappingPoint;
    positionAndSize: RepositionableElementPositionAndSize;
    originalTransformation: PositionAndSizeTransformation;
  }): RepositionableElementPositionAndSize {
    const diff = snappingPoint.originalSnappingPoint
      .clone()
      .substractVector(absolutePointWithDescription.originalPoint);

    if (
      RepositionableElementUtils.isResizeOnlyTransformation(
        originalTransformation
      )
    ) {
      /**
       * Why is this so complicated? Only god knows.
       * jk.
       *
       * If a left point snaps, we have to move the `left` of the positionAndSize accordingly.
       * This is achieved by adding the `diff` only if the point is on the left side.
       * We also only want the left point to snap. That's why we need to adapt the width, or the right points will also move.
       * This is achieved by removing the diff from the width if the point is on the left.
       *
       * If a right point snaps, we don't have to move the positionAndSize at all. Just modifying the width by the `diff` is enought
       *
       * With `(absolutePointWithDescription.description.getX() ? 0 : 1)` we achieve moving the positionAndSize only for left points.
       * With `(absolutePointWithDescription.description.getX() ? 1 : -1)` we achieve compensating the `diff` for left points and adapting the width for right points.
       */
      return {
        left:
          positionAndSize.left +
          diff.getX() *
            (absolutePointWithDescription.description.getX() ? 0 : 1),
        top:
          positionAndSize.top +
          diff.getY() *
            (absolutePointWithDescription.description.getY() ? 0 : 1),
        height:
          positionAndSize.height +
          diff.getY() *
            (absolutePointWithDescription.description.getY() ? 1 : -1),
        width:
          positionAndSize.width +
          diff.getX() *
            (absolutePointWithDescription.description.getX() ? 1 : -1),
        rotation: positionAndSize.rotation
      };
    } else if (
      RepositionableElementUtils.isMovementOnlyTransformation(
        originalTransformation
      )
    ) {
      return {
        left: positionAndSize.left + diff.getX(),
        top: positionAndSize.top + diff.getY(),
        height: positionAndSize.height,
        width: positionAndSize.width,
        rotation: positionAndSize.rotation
      };
    }

    return positionAndSize;
  }
}

/**
 * Must be absolute or relative, based on the repositionable-element configuration (relativeUnits).
 */
export type SnappingPoint = Vector;

/**
 * A vector which can only contain 0 and 1 for x and y.
 * 0 for x describes a point on the left side of the square (topLeft, bottomLeft) and 1 describes a point on the right side (topRight, bottomRight)
 *
 * 0 for y describes a point on the top side of the square (topLeft, topRight) and 1 describes a point on the bottom side (bottomLeft, bottomRight)
 */
type PointOfPositionAndSizeDescription = Vector;

type AbsolutePointWithDescription = {
  absolutePoint: Vector;
  originalPoint: Vector;
  description: PointOfPositionAndSizeDescription;
};

type AbsoluteAndOriginalSnappingPoint = {
  absoluteSnappingPoint: Vector;
  originalSnappingPoint: Vector;
};
