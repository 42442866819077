import { autoinject, bindable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { NFCHelper } from '../../classes/Nfc/NFCHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import {
  PermissionBindingHandle,
  PermissionBindingService
} from '../../services/PermissionBindingService';

/**
 * @event nfc-tag-created triggered when an nfc tag is created
 * @event nfc-tag-changed triggered when an nfc tag changed
 * @event nfc-tag-deleted triggered when an nfc tag is deleted
 */
@autoinject()
export class NfcTagWidget {
  @bindable public nfcTagId: string | null = null;

  private domElement: HTMLElement;

  private subscriptionManager: SubscriptionManager;
  private permissionBindingHandle: PermissionBindingHandle;

  protected isMobile = false;
  protected isNfcEnabled = false;

  constructor(
    element: Element,
    subscriptionManagerService: SubscriptionManagerService,
    permissionBindingService: PermissionBindingService
  ) {
    this.domElement = element as HTMLElement;
    this.subscriptionManager = subscriptionManagerService.create();
    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      permissionProperties: {
        canAssignNfcTagIds: 'canAssignNfcTagIds'
      }
    });
  }

  private scanUID(): Promise<string | null> {
    return new Promise((res, rej) => {
      NFCHelper.scanSingleUID((error, nfcTagId) => {
        if (error) {
          rej(error);
          return false;
        }
        res(nfcTagId);
        return true;
      });
    });
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      }),
      NFCHelper.registerBinding('nfcEnabled', (isNfcEnabled) => {
        this.isNfcEnabled = isNfcEnabled;
      })
    );
    this.permissionBindingHandle.subscribe();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.permissionBindingHandle.unsubscribe();
  }

  protected async handleNfcTagButtonClicked(): Promise<void> {
    if (this.nfcTagId) {
      const oldNfcTagId = this.nfcTagId;
      this.nfcTagId = await this.scanUID();
      this.fireEvent<NfcTagChangedEvent>('nfc-tag-changed', {
        oldNfcTagId,
        newNfcTagId: this.nfcTagId
      });
    } else {
      this.nfcTagId = await this.scanUID();
      this.fireEvent<NfcTagCreatedEvent>('nfc-tag-created', {
        nfcTagId: this.nfcTagId
      });
    }
  }

  protected handleDeleteNfcTagClicked(): void {
    void Dialogs.deleteDialogTk(
      'inputComponents.nfcTagWidget.deleteNfcTag'
    ).then(() => {
      this.fireEvent<NfcTagDeletedEvent>('nfc-tag-deleted', {
        nfcTagId: this.nfcTagId
      });
      this.nfcTagId = null;
    });
  }

  private fireEvent<T extends NamedCustomEvent<string, unknown>>(
    name: T['type'],
    detail: T['detail'] = null
  ): void {
    setTimeout(() => {
      DomEventHelper.fireEvent<T>(this.domElement, {
        name,
        detail
      });
    }, 0);
  }
}

export type NfcTagCreatedEvent = NamedCustomEvent<
  'nfc-tag-created',
  { nfcTagId: string | null }
>;
export type NfcTagChangedEvent = NamedCustomEvent<
  'nfc-tag-changed',
  { oldNfcTagId: string | null; newNfcTagId: string | null }
>;
export type NfcTagDeletedEvent = NamedCustomEvent<
  'nfc-tag-deleted',
  { nfcTagId: string | null }
>;
