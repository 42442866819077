import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { Dialogs } from '../../classes/Dialogs';
import { ReportTypeTemplateService } from '../../classes/EntityManager/entities/ReportType/ReportTypeTemplateService';
import { ReportType } from '../../classes/EntityManager/entities/ReportType/types';

@autoinject()
export class ReportTypeUpDownload {
  @bindable public reportType: ReportType | null = null;
  @bindable public disabled = false;

  private fileInputElement: HTMLInputElement | null = null;

  constructor(
    private readonly reportTypeTemplateService: ReportTypeTemplateService
  ) {}

  protected handleFileInputChange(): void {
    assertNotNullOrUndefined(this.fileInputElement, 'fileInputElement is null');
    assertNotNullOrUndefined(
      this.reportType,
      'trying to upload report without report type'
    );

    const docxType =
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    const file = this.fileInputElement.files?.[0];
    if (file) {
      if (file.type === docxType) {
        if (!this.disabled)
          this.reportTypeTemplateService.uploadTemplate(this.reportType, file);
        this.fileInputElement.value = '';
      } else {
        void Dialogs.errorDialogTk(
          'serverResponses.uploadDocxTemplateFile.uploadError',
          'serverResponses.uploadDocxTemplateFile.wrongFileType'
        );
      }
    }
  }

  protected handleDownloadTemplateClick(): void {
    assertNotNullOrUndefined(
      this.reportType,
      'trying to download report without report type'
    );

    if (!this.disabled)
      void this.reportTypeTemplateService.downloadTemplate(this.reportType);
  }
}
