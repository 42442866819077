export class FileSizeUtils {
  private constructor() {}

  public static formatWithAutomaticUnit(sizeInBytes: number): string {
    const simplifiedSize = this.simplifySize(sizeInBytes);

    if (simplifiedSize.exponent >= Exponent.TERA) {
      return `> 1.0 ${this.getUnitForExponent(Exponent.TERA)}`;
    }

    return `${simplifiedSize.size.toFixed(1)} ${this.getUnitForExponent(simplifiedSize.exponent)}`;
  }

  private static simplifySize(sizeInBytes: number): SimplifiedSize {
    let size = sizeInBytes;
    let exponent = 0;
    while (size >= 1024 && exponent < Exponent.TERA) {
      size /= 1024;
      exponent += 10;
    }

    return {
      size,
      exponent
    };
  }

  private static getUnitForExponent(exponent: Exponent): string {
    switch (exponent) {
      case Exponent.NONE:
        return 'B';
      case Exponent.KILO:
        return 'KiB';
      case Exponent.MEGA:
        return 'MiB';
      case Exponent.GIGA:
        return 'GiB';
      case Exponent.TERA:
        return 'TiB';
      default:
        throw new Error(`unsupported exponent ${exponent}`);
    }
  }
}

/**
 * size * 2^(exponent) = sizeInBytes
 */
type SimplifiedSize = {
  size: number;
  exponent: Exponent;
};

enum Exponent {
  NONE = 0,
  KILO = 10,
  MEGA = 20,
  GIGA = 30,
  TERA = 40
}
