import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureFullScreenOverlay } from '../../../aureliaComponents/picture-full-screen-overlay/picture-full-screen-overlay';
import { Dialogs } from '../../../classes/Dialogs';
import {
  DomEventHelper,
  NamedCustomEvent
} from '../../../classes/DomEventHelper';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ThingSectionPicture } from '../../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

/**
 * @event {PictureDeletedEvent} picture-deleted
 */
@autoinject()
export class ThingSectionPicturesWidgetPicture {
  @bindable()
  public picture: ThingSectionPicture | null = null;

  /**
   * If this is set to false, the user is not allowed to do anything
   * If this is set to true, the user is allowed to do anything that the permissionsService allows
   */
  @bindable()
  public enabled: boolean = false;

  @subscribableLifecycle()
  protected readonly picturePermissionsHandle: EntityNameToPermissionsHandle[EntityName.Picture];

  constructor(
    private readonly element: Element,
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.picturePermissionsHandle =
      permissionsService.getPermissionsHandleForExpressionValue({
        entityName: EntityName.Picture,
        context: this,
        expression: 'picture'
      });
  }

  protected handlePictureClicked(): void {
    assertNotNullOrUndefined(
      this.picture,
      "can't ThingSectionPicturesWidgetPicture.handlePictureClicked without picture"
    );

    void PictureFullScreenOverlay.open({
      picture: this.picture
    });
  }

  protected handleDeleteClick(): void {
    const picture = this.picture;
    assertNotNullOrUndefined(
      picture,
      "can't ThingSectionPicturesWidgetPicture.handleDeleteClicked without picture"
    );

    void Dialogs.deleteEntityDialog(picture, EntityName.Picture).then(() => {
      this.entityManager.pictureRepository.delete(picture);

      DomEventHelper.fireEvent<PictureDeletedEvent>(this.element, {
        name: 'picture-deleted',
        detail: null
      });
    });
  }
}

export type PictureDeletedEvent = NamedCustomEvent<'picture-deleted', null>;
