import { bindable } from 'aurelia-framework';

export class HighlightableText {
  @bindable public text = '';
  @bindable public textToHighlight = '';

  protected textArray: Array<string> = [];

  private generateTextArray(): void {
    if (!this.text) {
      this.textArray = [];
      return;
    }
    if (!this.textToHighlight) {
      this.textArray = [this.text];
      return;
    }

    const upperCaseText = this.text.toUpperCase();
    const upperCaseTextToHighlight = this.textToHighlight.toUpperCase();

    const highlightStartIndex = upperCaseText.indexOf(upperCaseTextToHighlight);

    if (highlightStartIndex < 0) {
      this.textArray = [this.text];
      return;
    }

    const preText = this.text.substring(0, highlightStartIndex);
    const highlightedText = this.text.substr(
      highlightStartIndex,
      this.textToHighlight.length
    );
    const postText = this.text.substr(
      highlightStartIndex + this.textToHighlight.length
    );

    this.textArray = [preText, highlightedText, postText];
  }

  protected textChanged(): void {
    this.generateTextArray();
  }

  protected textToHighlightChanged(): void {
    this.generateTextArray();
  }
}
