import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  GlobalThingPicture,
  Picture,
  ThingSectionPicture
} from '../../../../classes/EntityManager/entities/Picture/types';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { ThingSection } from '../../../../classes/EntityManager/entities/ThingSection/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { DisposableUtils } from '../../../../classes/Utils/DisposableUtils';
import { Utils } from '../../../../classes/Utils/Utils';
import {
  LastUsedPictureForIdService,
  LastUsedThingSectionForIdService
} from '../../../../services/LastUsedEntityService';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { PictureDisplayInfoUtils } from '../PictureDisplayInfoUtils/PictureDisplayInfoUtils';
import {
  AfterPictureSelectedOptions,
  PictureDisplayInfo,
  PictureGroup,
  SelectPictureDialogAdapter,
  SubscribeOptions
} from '../SelectPictureDialogAdapter/SelectPictureDialogAdapter';

export class SelectPictureDialogEntryAdapter extends SelectPictureDialogAdapter {
  private readonly entityManager: AppEntityManager;
  private readonly subscriptionManagerService: SubscriptionManagerService;
  private readonly lastUsedPictureForIdService: LastUsedPictureForIdService;
  private readonly lastUsedThingSectionForIdService: LastUsedThingSectionForIdService;
  private readonly permissionsHandle: EntityNameToPermissionsHandle[EntityName.Project]; // TODO: lifecycle
  private readonly project: Project;
  private readonly filterPicture: FilterPicture;

  private lastUsedPictureId: string | null = null;

  constructor(options: SelectPictureDialogEntryAdapterOptions) {
    super();

    this.entityManager = options.entityManager;
    this.subscriptionManagerService = options.subscriptionManagerService;
    this.lastUsedPictureForIdService = options.lastUsedPictureForIdService;
    this.lastUsedThingSectionForIdService =
      options.lastUsedThingSectionForIdService;
    this.permissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Project,
        entity: options.project
      });
    this.project = options.project;
    this.filterPicture = options.filterPicture;
  }

  public subscribe({ setPictureGroups }: SubscribeOptions): Disposable {
    const subscriptionManager = this.subscriptionManagerService.create();

    const updatePictureGroups = DisposableUtils.disposableCallback((): void => {
      setPictureGroups(this.getPictureGroups());
    });

    subscriptionManager.addDisposable(updatePictureGroups);

    const updateLastUsedPictureId = (): void => {
      void this.lastUsedPictureForIdService
        .getLastUsed(this.project.id)
        .then((lastUsedPictureId) => {
          this.lastUsedPictureId = lastUsedPictureId;
          updatePictureGroups();
        });
    };

    subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      updatePictureGroups
    );
    subscriptionManager.subscribeToModelChanges(
      EntityName.ThingSection,
      updatePictureGroups
    );
    updateLastUsedPictureId();

    subscriptionManager.addDisposable(this.permissionsHandle.subscribe());
    subscriptionManager.subscribeToExpression(
      this,
      'permissionsHandle.canMarkOnThingSections',
      updatePictureGroups
    );

    this.lastUsedPictureForIdService.onLastUsedChange(updateLastUsedPictureId);

    updatePictureGroups();

    return subscriptionManager.toDisposable();
  }

  public async afterPictureSelected({
    picture
  }: AfterPictureSelectedOptions): Promise<void> {
    await this.lastUsedPictureForIdService.setLastUsed(
      this.project.id,
      picture.id
    );
    if (picture.thingSectionId) {
      await this.lastUsedThingSectionForIdService.setLastUsed(
        this.project.id,
        picture.thingSectionId
      );
    }
  }

  private getPictureGroups(): Array<PictureGroup> {
    const projectPictures =
      this.entityManager.pictureRepository.getAllPicturesByProjectId(
        this.project.id
      );
    const globalThingPictures =
      this.entityManager.pictureRepository.getByGlobalThingId(
        this.project.thing
      );
    const thingSectionPictures =
      this.entityManager.pictureRepository.getThingSectionPicturesByThingId(
        this.project.thing
      );
    const orderedThingSections =
      this.entityManager.thingSectionRepository.getOrderedByThingId(
        this.project.thing
      );

    const pictureGroups: Array<PictureGroup> = [];

    const lastUsedPictureGroup = this.getLastUsedPictureGroup({
      projectPictures,
      globalThingPictures,
      thingSectionPictures
    });
    if (lastUsedPictureGroup) {
      pictureGroups.push(lastUsedPictureGroup);
    }

    pictureGroups.push(this.getGlobalProjectPicturesGroup({ projectPictures }));
    pictureGroups.push(
      this.getGlobalThingPicturesGroup({ globalThingPictures })
    );

    const thingSectionPicturesGroup = this.getThingSectionPicturesGroup({
      orderedThingSections,
      thingSectionPictures
    });
    if (thingSectionPicturesGroup) {
      pictureGroups.push(thingSectionPicturesGroup);
    }

    return pictureGroups;
  }

  private getGlobalProjectPicturesGroup({
    projectPictures
  }: {
    projectPictures: Array<Picture>;
  }): PictureGroup {
    const relevantProjectPictures = projectPictures.filter((picture) => {
      if (!picture.is_global_project_picture) {
        return false;
      }

      return this.filterPicture(picture);
    });

    return {
      titleTk: GroupTitleTk.GLOBAL_PROJECT_PICTURES,
      pictureDisplayInfos:
        PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({
          pictures: relevantProjectPictures
        })
    };
  }

  private getGlobalThingPicturesGroup({
    globalThingPictures
  }: {
    globalThingPictures: Array<GlobalThingPicture>;
  }): PictureGroup {
    return {
      titleTk: GroupTitleTk.GLOBAL_THING_PICTURES,
      pictureDisplayInfos:
        PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({
          pictures: globalThingPictures.filter((picture) =>
            this.filterPicture(picture)
          )
        })
    };
  }

  private getLastUsedPictureGroup({
    projectPictures,
    globalThingPictures,
    thingSectionPictures
  }: {
    projectPictures: Array<Picture>;
    globalThingPictures: Array<GlobalThingPicture>;
    thingSectionPictures: Array<ThingSectionPicture>;
  }): PictureGroup | null {
    const pictures = [
      ...projectPictures,
      ...globalThingPictures,
      ...thingSectionPictures
    ].filter((picture) => {
      return picture.id === this.lastUsedPictureId;
    });

    if (pictures.length === 0) {
      return null;
    }

    return {
      titleTk: GroupTitleTk.LAST_USED,
      pictureDisplayInfos:
        PictureDisplayInfoUtils.createDefaultPictureDisplayInfos({ pictures })
    };
  }

  private getThingSectionPicturesGroup({
    orderedThingSections,
    thingSectionPictures
  }: {
    orderedThingSections: Array<ThingSection>;
    thingSectionPictures: Array<ThingSectionPicture>;
  }): PictureGroup | null {
    if (
      orderedThingSections.length === 0 ||
      !this.permissionsHandle.canMarkOnThingSections
    ) {
      return null;
    }

    return {
      titleTk: GroupTitleTk.THING_SECTION_PICTURES,
      pictureDisplayInfos: this.getThingSectionPicturesGroupPictureDisplayInfos(
        {
          orderedThingSections,
          thingSectionPictures: thingSectionPictures.filter((picture) =>
            this.filterPicture(picture)
          )
        }
      )
    };
  }

  private getThingSectionPicturesGroupPictureDisplayInfos({
    orderedThingSections,
    thingSectionPictures
  }: {
    orderedThingSections: Array<ThingSection>;
    thingSectionPictures: Array<ThingSectionPicture>;
  }): Array<PictureDisplayInfo> {
    const displayInfos: Array<PictureDisplayInfo> = [];

    const picturesByThingSectionId = Utils.groupBy(
      thingSectionPictures,
      (picture) => picture.thingSectionId
    );
    for (const thingSection of orderedThingSections) {
      for (const picture of picturesByThingSectionId.get(thingSection.id) ??
        []) {
        displayInfos.push({
          picture,
          enabled: true,
          subText: thingSection.name,
          relatedPictures: thingSectionPictures
        });
      }
    }

    return displayInfos;
  }
}

export enum GroupTitleTk {
  GLOBAL_PROJECT_PICTURES = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogEntryAdapter.globalProjectPictures',
  GLOBAL_THING_PICTURES = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogEntryAdapter.globalThingPictures',
  THING_SECTION_PICTURES = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogEntryAdapter.thingSectionPictures',
  LAST_USED = 'dialogs.selectPictureDialog.adapter.SelectPictureDialogEntryAdapter.lastUsed'
}

export type FilterPicture = (picture: Picture) => boolean;

export type SelectPictureDialogEntryAdapterOptions = {
  entityManager: AppEntityManager;
  subscriptionManagerService: SubscriptionManagerService;
  lastUsedPictureForIdService: LastUsedPictureForIdService;
  lastUsedThingSectionForIdService: LastUsedThingSectionForIdService;
  permissionsService: PermissionsService;
  project: Project;
  filterPicture: FilterPicture;
};
