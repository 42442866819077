import { ArrayMapCache } from '../../../../classes/ArrayMapCache/ArrayMapCache';
import { SubscribableArray } from '../../../../classes/SubscribableArray/SubscribableArray';
import { Disposable } from '../../../../classes/Utils/DisposableContainer';
import { PropertyDefinitionWidgetHandle } from '../../../property-definition-widget/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle/PropertyDefinitionWidgetHandle';
import { ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions } from './ManagePropertyDefinitionsBaseWidgetAdapter';

/**
 * A simple cache for the PropertyDefinitionWidgetHandle to avoid unnecessary rerenders.
 */
export class ManagePropertyDefinitionsWidgetAdapterHandleCache<
  TPropertyDefinition,
  TPropertyDefinitionHandle extends
    PropertyDefinitionWidgetHandle<TPropertyDefinition>
> {
  private readonly cache: ArrayMapCache<
    TPropertyDefinition,
    TPropertyDefinitionHandle
  >;
  private readonly subscribableArray: SubscribableArray<TPropertyDefinitionHandle>;

  constructor(
    options: ManagePropertyDefinitionsWidgetAdapterHandleCacheOptions<
      TPropertyDefinition,
      TPropertyDefinitionHandle
    >
  ) {
    this.cache = new ArrayMapCache({
      createMappedItem: ({ item }) =>
        options.createPropertyDefinitionHandle({ propertyDefinition: item })
    });

    this.subscribableArray = new SubscribableArray({
      getSubscribableFromItem: (item) => item
    });
  }

  public subscribe(): Disposable {
    return this.subscribableArray.subscribe();
  }

  /**
   * Creates an update function for easier reuse across multiple event listeners.
   * If the update function gets called, it will also remove unused cache entries to prevent memory leaks from old unused entries
   */
  public createUpdatePropertyDefinitionsFunction({
    getPropertyDefinitions,
    setPropertyDefinitionHandles
  }: CreateUpdatePropertyDefinitionsFunctionOptions<TPropertyDefinition>): {
    updatePropertyDefinitions: () => void;
  } {
    return {
      updatePropertyDefinitions: () => {
        this.subscribableArray.items = this.cache.mapItems({
          items: getPropertyDefinitions()
        });
        setPropertyDefinitionHandles(this.subscribableArray.items);
      }
    };
  }

  /**
   * Creates a new handle and caches it for later use by e.g. the updatePropertyDefinitions function
   */
  public createCachedPropertyDefinitionHandle({
    propertyDefinition
  }: {
    propertyDefinition: TPropertyDefinition;
  }): TPropertyDefinitionHandle {
    return this.cache.mapItem({ item: propertyDefinition });
  }
}

export type ManagePropertyDefinitionsWidgetAdapterHandleCacheOptions<
  TPropertyDefinition,
  TPropertyDefinitionHandle extends
    PropertyDefinitionWidgetHandle<TPropertyDefinition>
> = {
  createPropertyDefinitionHandle: CreatePropertyDefinitionHandle<
    TPropertyDefinition,
    TPropertyDefinitionHandle
  >;
};

export type CreatePropertyDefinitionHandle<
  TPropertyDefinition,
  TPropertyDefinitionHandle extends
    PropertyDefinitionWidgetHandle<TPropertyDefinition>
> = (options: {
  propertyDefinition: TPropertyDefinition;
}) => TPropertyDefinitionHandle;

export type CreateUpdatePropertyDefinitionsFunctionOptions<
  TPropertyDefinition
> = {
  setPropertyDefinitionHandles: ManagePropertyDefinitionsBaseWidgetAdapterSubscribeOptions<TPropertyDefinition>['setPropertyDefinitionHandles'];
  getPropertyDefinitions: () => Array<TPropertyDefinition>;
};
