import { bindable, computedFrom, autoinject } from 'aurelia-framework';

import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';

/**
 * @event {TCreatePictureClickedEvent} create-picture-clicked
 */
@autoinject()
export class CreatePictureButton {
  @bindable()
  public showCamera: boolean = true;

  @bindable()
  public showUltraRapidFireMode: boolean = false;

  @bindable()
  public buttonTextTk: string | null = null;

  @bindable()
  public floatingLabelButton: boolean = false;

  @bindable()
  public floatingLabelButtonIconClass = '';

  private domElement: HTMLElement;
  private floatingLabelButtonElement: HTMLElement | null = null;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  @computedFrom('showCamera', 'showUltraRapidFireMode')
  protected get pictureSources(): Array<PictureSource> {
    const pictureSources: Array<PictureSource> = [];

    if (this.showCamera) {
      pictureSources.push({
        name: 'camera',
        labelTk: 'aureliaComponents.createPictureButton.cameraOptionLabel',
        iconType: 'fal',
        iconName: 'fa-camera-retro'
      });
    }

    if (this.showUltraRapidFireMode) {
      pictureSources.push({
        name: 'urfm',
        labelTk:
          'aureliaComponents.createPictureButton.ultraRapidFireWidgetOptionLabel',
        iconType: 'fal',
        iconName: 'fa-camera'
      });
    }

    pictureSources.push({
      name: 'sketch',
      labelTk: 'aureliaComponents.createPictureButton.sketchOptionLabel',
      iconType: 'fal',
      iconName: 'fa-edit'
    });

    return pictureSources;
  }

  protected handleCreateClick(pictureSource: PictureSource): void {
    DomEventHelper.fireEvent<CreatePictureClickedEvent>(this.domElement, {
      name: 'create-picture-clicked',
      bubbles: true,
      detail: pictureSource
    });
  }
}

export type PictureSource = {
  name: 'camera' | 'urfm' | 'sketch';
  labelTk: string;
  iconType: string;
  iconName: string;
};

export type CreatePictureClickedEvent = NamedCustomEvent<
  'create-picture-clicked',
  PictureSource
>;
