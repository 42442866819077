import { assertNotNullOrUndefined } from 'common/Asserts';
import { DialogIconType } from 'common/Enums/DialogIconType';
import { Utils } from './Utils/Utils';
import {
  ButtonType,
  GlobalCustomDialog,
  GlobalCustomDialogReturnObject
} from '../dialogs/global-custom-dialog/global-custom-dialog';
import { TestingHelper } from './TestingHelper';
import { EntityName } from './EntityManager/entities/types';

export class Dialogs {
  public static yesNoDialog(
    text: string
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      title: text,
      buttons: [
        {
          textTk: 'general.yes',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.no',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static yesNoDialogTk(
    tk: string | null,
    tkParams: Record<string, any> = {},
    textTk?: string
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: tk,
      titleTkParams: tkParams,
      textTk: textTk,
      buttons: [
        {
          textTk: 'general.yes',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.no',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static deleteDialogTk(
    textTk: string,
    textTkParams: Record<string, any> | null = null,
    deleteButtonTextTk: string = 'general.delete'
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntityTitle',
      textTk: textTk,
      textTkParams: textTkParams,
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: deleteButtonTextTk,
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static cancelDialogTk(
    textTk: string
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.cancelTitle',
      textTk: textTk,
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'general.yes',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.no',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static deleteEntityDialog(
    entity: Record<string, any>,
    entityName?: string
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntityTitle',
      textTk: 'classes.Dialogs.deleteEntityText',
      textTkParams: {
        entityName:
          entityName || (entity.name ? entity.name : entity.entity_name)
      },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'classes.Dialogs.deleteEntityButtonText',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static deleteEntitiesDialog({
    count,
    entityName
  }: {
    count: number;
    entityName: EntityName;
  }): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.deleteEntitiesTitle',
      textTk: 'classes.Dialogs.deleteEntitiesText',
      textTkParams: {
        entityName,
        count
      },
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'classes.Dialogs.deleteEntitiesButtonText',
          className: 'record-it-button-red'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ]
    });
  }

  public static errorDialog(
    title: string,
    text?: string
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      title: title,
      text: text,
      icon: DialogIconType.ERROR,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ]
    });
  }

  public static errorDialogTk(
    titleTk: string,
    textTk?: string,
    textTkParams?: Record<string, any>
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: titleTk,
      textTk: textTk,
      textTkParams: textTkParams,
      icon: DialogIconType.ERROR,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ]
    });
  }

  /**
   * this dialog is meant to be shown while e.g. uploading something to the server
   * it will automatically close itself if the connection is lost
   *
   * @param {string|null} text
   */
  public static waitDialog(text: string | null = null): void {
    void GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.pleaseWait',
      text: text,
      buttons: [],
      autoCloseOnDisconnect: true
    });
  }

  public static waitDialogTk(textTk: string | null = null): void {
    void GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.pleaseWait',
      textTk: textTk,
      buttons: [],
      autoCloseOnDisconnect: true
    });
  }

  public static waitOrCancelDialogTk(
    textTk: string | null = null,
    autoCloseOnDisconnect = true
  ): void {
    void GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.pleaseWait',
      textTk: textTk,
      buttons: [
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      autoCloseOnDisconnect
    });
  }

  public static warningDialog(title: string, text?: string): void {
    void GlobalCustomDialog.open({
      title: title,
      text: text,
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ]
    });
  }

  public static warningDialogTk(titleTk: string, textTk?: string): void {
    void GlobalCustomDialog.open({
      titleTk: titleTk,
      textTk: textTk,
      icon: DialogIconType.WARNING,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ]
    });
  }

  public static successDialog(title: string, text?: string): void {
    void GlobalCustomDialog.open({
      title: title,
      text: text,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ],
      icon: DialogIconType.SUCCESS
    });
  }

  public static successDialogTk(
    titleTk: string,
    textTk: string | null = null
  ): void {
    void GlobalCustomDialog.open({
      titleTk: titleTk,
      textTk: textTk,
      buttons: [
        {
          textTk: 'general.ok',
          className: 'record-it-button-primary'
        }
      ],
      icon: DialogIconType.SUCCESS
    });
  }

  /**
   * this success dialog has no buttons and will be closed in 350 ms
   */
  public static timedSuccessDialog(title: string, text?: string): void {
    void GlobalCustomDialog.open({
      title: title,
      text: text,
      icon: DialogIconType.SUCCESS,
      buttons: [],
      timer: 900
    });
  }

  public static timedSuccessDialogTk(titleTk: string, textTk?: string): void {
    void GlobalCustomDialog.open({
      titleTk: titleTk,
      textTk: textTk,
      icon: DialogIconType.SUCCESS,
      buttons: [],
      timer: 900
    });
  }

  /**
   * this dialog has no buttons and prevents the user from clicking anything
   */
  public static blockingStatusDialog(title: string, text?: string): void {
    void GlobalCustomDialog.open({
      title: title,
      text: text,
      buttons: []
    });
  }

  public static blockingStatusDialogTk(titleTk: string, textTk?: string): void {
    void GlobalCustomDialog.open({
      titleTk: titleTk,
      textTk: textTk,
      buttons: []
    });
  }

  /**
   * this doesn't update the email for the user though, it's just a dialog which delivers a validated email to the callback
   */
  public static emailDialog(
    title: string,
    text: string,
    callback: (email: string) => void
  ): void {
    void GlobalCustomDialog.open({
      title: title,
      text: text,
      buttons: [
        {
          textTk: 'general.ok',
          value: 'ok',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      inputOptions: {
        type: text,
        placeholder: 'E-Mail',
        validator: (value) => {
          if (!value || !Utils.validateEmail(value)) {
            return 'Es muss eine gültige Email Adresse eingeben werden!';
          }

          return true;
        }
      }
    }).then((result) => {
      assertNotNullOrUndefined(
        result.inputValue,
        'dialog resolved without an email, is the validator broken?'
      );
      callback(result.inputValue);
    });
  }

  /**
   * this dialog doesn't create the entry by itself! You have to handle that yourself
   */
  public static addEntryDialogTk(
    textTk?: string | null,
    textTkParams?: Record<string, any>
  ): Promise<GlobalCustomDialogReturnObject> {
    return GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.addEntryDialogTitle',
      textTk: textTk,
      textTkParams: textTkParams,
      buttons: [
        {
          textTk: 'classes.Dialogs.addEntryDialogAcceptButtonText',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      inputOptions: {
        type: 'text',
        placeholder: 'Titel',
        validator: (value) => {
          if (!value || value.trim().length === 0) {
            return 'Ein Titel muss angegeben werden'; // TODO: translate
          }

          return true;
        }
      }
    });
  }

  public static closeAllDialogs(): void {
    GlobalCustomDialog.close();
  }

  public static browserCheckDialog(): void {
    void GlobalCustomDialog.open({
      title: 'Veralteter Browser',
      text: 'Der Microsoft Internet Explorer ist nicht für recordIT zugelassen und wird auch nicht unterstützt.',
      icon: DialogIconType.WARNING,
      buttons: []
    });
  }

  /**
   * this doesn't update the password for the user though, it's just a dialog which delivers a validated password to the callback
   */
  public static changePasswordDialog(
    username: string,
    callback: (password: string) => void
  ): void {
    void GlobalCustomDialog.open({
      title: 'Neues Passwort!',
      text: `Bitte das neue Passwort für "${username}" hier eintragen.`,
      buttons: [
        {
          textTk: 'general.ok',
          value: 'ok',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      inputOptions: {
        type: 'password',
        placeholder: 'Passwort',
        validator: (value) => {
          console.log('validating ' + value);
          if (!value) {
            return 'Es muss ein Passwort eingeben werden!';
          }
          if (!Utils.validatePassword(value)) {
            return 'Das Passwort muss aus mind. 8 Zeichen bestehen und mind. 1 Großbuchstaben enthalten!';
          }

          return true;
        }
      }
    }).then((result) => {
      assertNotNullOrUndefined(
        result.inputValue,
        'dialog resolved without a password, is the validator broken?'
      );
      Dialogs.confirmPasswordDialog(result.inputValue, callback);
    });
  }

  public static todoDialog(message?: string): void {
    void GlobalCustomDialog.open({
      titleTk: 'classes.Dialogs.todo.title',
      textTk: message ? undefined : 'classes.Dialogs.todo.text',
      text: message,
      icon: DialogIconType.WARNING
    });
  }

  /**
   * @param password has to be already validated!!
   * @param callback
   */
  private static confirmPasswordDialog(
    password: string,
    callback: (password: string) => void
  ): void {
    void GlobalCustomDialog.open({
      title: 'Neues Passwort!',
      text: 'Bitte das neue Passwort nochmal eingeben.',
      buttons: [
        {
          textTk: 'general.ok',
          value: 'ok',
          className: 'record-it-button-primary'
        },
        {
          textTk: 'general.cancel',
          type: ButtonType.CANCEL
        }
      ],
      inputOptions: {
        type: 'password',
        placeholder: 'Passwort',
        validator: (value) => {
          if (value !== password) {
            return 'Die eingegebenen Passwörter stimmen nicht überein!';
          }

          return true;
        }
      }
    }).then(() => {
      callback(password);
    });
  }
}

TestingHelper.Dialogs = Dialogs;
