import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { TooltipContent } from '../../aureliaComponents/tooltip-content/tooltip-content';
import { Dialogs } from '../../classes/Dialogs';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { FloatingLabelInput } from '../../inputComponents/floating-label-input/floating-label-input';

@autoinject()
export class StructureThingPathEditWidget {
  @bindable public path: Array<string> = [];

  @bindable public enabled = false;

  protected currentlyEditing: number | null = null;
  private tooltipContentViewModels: Array<TooltipContent> = [];
  private floatingLabelInputViewModels: Array<FloatingLabelInput> = [];

  constructor(private domElement: Element) {}

  protected handleClickOnPathItem(index: number): void {
    const viewModel = this.tooltipContentViewModels[index];
    assertNotNullOrUndefined(
      viewModel,
      `no tooltipContentViewModel found at index ${index}`
    );

    viewModel.open();
  }

  protected handleEditPathItemClicked(index: number): void {
    this.currentlyEditing = index;
    setTimeout(() => {
      const viewModel = this.floatingLabelInputViewModels[index];
      assertNotNullOrUndefined(
        viewModel,
        `no floatingLabelInputViewModel found at index ${index}`
      );

      viewModel.focus();
    }, 0);
  }

  protected handleDeletePathItemClicked(index: number): void {
    void Dialogs.deleteDialogTk(
      'structureThing.structureThingPathEditWidget.deletePathElementText',
      { pathElement: this.path[index] }
    ).then(() => {
      this.path.splice(index, 1);
      this.firePathChangedEvent();
    });
  }

  protected handleAddPathItemClicked(): void {
    this.path.push('*');
    this.firePathChangedEvent();
    this.handleEditPathItemClicked(this.path.length - 1);
  }

  protected handleInputBlur(index: number, pathItem: string): void {
    if (!pathItem) pathItem = '*';

    this.path[index] = pathItem;
    this.firePathChangedEvent();

    this.currentlyEditing = null;
  }

  private firePathChangedEvent(): void {
    DomEventHelper.fireEvent<PathChangedEvent>(this.domElement, {
      name: 'path-changed',
      detail: {
        path: this.path
      }
    });
  }

  protected isInEditingMode(
    currentlyEditing: number | null,
    index: number
  ): boolean {
    return currentlyEditing === index;
  }
}

export type PathChangedEvent = NamedCustomEvent<
  'path-changed',
  {
    path: Array<string>;
  }
>;
