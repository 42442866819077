import { autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PathUtils } from 'common/Utils/PathUtils/PathUtils';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { UrlManager } from '../../classes/UrlManager';
import { FileUtils } from '../../classes/Utils/FileUtils/FileUtils';
import { ImageResizer } from '../../classes/ImageResizer/ImageResizer';
import { Utils } from '../../classes/Utils/Utils';

@autoinject()
export class ThumbnailFileService {
  private readonly subscriptionManager: SubscriptionManager;

  private readonly pictureIdToThumbnailInfosMap: Map<
    string,
    Map<string, ThumbnailInfo>
  > = new Map();

  private pendingThumbnailGenerationMap: Record<string, Promise<string>> = {};

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    if (!DeviceInfoHelper.isApp()) return;

    await this.scanThumbnailsFolder();
    await this.deleteSuperfluousThumbnails();

    this.subscriptionManager.addDisposable(
      this.entityManager.pictureFileRepository.registerHooks({
        afterEntityDeleted: (pictureFile) => {
          void this.deleteThumbnailsForPictureFileId(pictureFile.id);
        },
        afterEntityRemovedLocally: (pictureFile) => {
          void this.deleteThumbnailsForPictureFileId(pictureFile.id);
        },
        afterEntityUpdated: (pictureFile) => {
          void this.deleteThumbnailsForPictureFileId(pictureFile.id);
        },
        afterEntityUpdatedLocally: (pictureFile) => {
          void this.deleteThumbnailsForPictureFileId(pictureFile.id);
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public async getOrCreateThumbnailPath(imagePath: string): Promise<string> {
    let generationPromise = this.pendingThumbnailGenerationMap[imagePath];

    if (!generationPromise) {
      generationPromise = this.pendingThumbnailGenerationMap[imagePath] =
        this.getOrCreateThumbnailPathTask(imagePath);
    }

    try {
      return await generationPromise;
    } finally {
      delete this.pendingThumbnailGenerationMap[imagePath];
    }
  }

  private async getOrCreateThumbnailPathTask(
    imagePath: string
  ): Promise<string> {
    assertNotNullOrUndefined(
      UrlManager.cacheFolder,
      'no cache folder available'
    );

    const imagePathInfo = PathUtils.getPathDetails(imagePath);

    const absoluteThumbnailImagePath = PathUtils.joinPaths(
      UrlManager.cacheFolder,
      'thumbnails',
      imagePathInfo.baseName
    );

    const pictureFileId = Utils.extractIdFromPictureFileName(
      imagePathInfo.baseName
    );
    assertNotNullOrUndefined(
      pictureFileId,
      'cannot extract picture file id from image path'
    );

    this.addThumbnailForPictureFileId(
      pictureFileId,
      absoluteThumbnailImagePath
    );

    if (await FileUtils.fileExists(absoluteThumbnailImagePath)) {
      return absoluteThumbnailImagePath;
    }

    const absoluteOriginalImagePath = PathUtils.joinPaths(
      UrlManager.localFolder,
      imagePath
    );

    return await this.createThumbnail(
      absoluteOriginalImagePath,
      absoluteThumbnailImagePath
    );
  }

  private async createThumbnail(
    absoluteOriginalImagePath: string,
    absoluteThumbnailImagePath: string
  ): Promise<string> {
    assertNotNullOrUndefined(
      UrlManager.cacheFolder,
      'no cache folder available'
    );

    const resizedImage = await ImageResizer.generateThumbnail({
      imageSrc: absoluteOriginalImagePath,
      smallestDimension: 340
    });

    if (!resizedImage) return absoluteOriginalImagePath;

    await FileUtils.writeBlobToLocalFile(
      absoluteThumbnailImagePath,
      resizedImage
    );

    return absoluteThumbnailImagePath;
  }

  private async scanThumbnailsFolder(): Promise<void> {
    assertNotNullOrUndefined(
      UrlManager.cacheFolder,
      'cache folder is not available'
    );

    await FileUtils.ensureDirectory(UrlManager.cacheFolder, 'thumbnails');

    const basePath = PathUtils.joinPaths(UrlManager.cacheFolder, 'thumbnails');
    const fileEntries = await FileUtils.getFilesShallow(basePath);

    for (const fileEntry of fileEntries) {
      const pictureFileId = Utils.extractIdFromPictureFileName(fileEntry.name);
      if (!pictureFileId) continue;

      this.addThumbnailForPictureFileId(pictureFileId, fileEntry.nativeUrl);
    }
  }

  private async deleteSuperfluousThumbnails(): Promise<void> {
    for (const [
      pictureFileId,
      thumbnailInfos
    ] of this.pictureIdToThumbnailInfosMap.entries()) {
      if (this.entityManager.pictureFileRepository.getById(pictureFileId))
        continue;

      await this.deleteThumbnails(thumbnailInfos);
      this.pictureIdToThumbnailInfosMap.delete(pictureFileId);
    }
  }

  private async deleteThumbnailsForPictureFileId(
    pictureFileId: string
  ): Promise<void> {
    const thumbnailInfos = this.pictureIdToThumbnailInfosMap.get(pictureFileId);
    if (!thumbnailInfos) return;

    await this.deleteThumbnails(thumbnailInfos);
    this.pictureIdToThumbnailInfosMap.delete(pictureFileId);
  }

  private async deleteThumbnails(
    thumbnailInfos: Map<string, ThumbnailInfo>
  ): Promise<void> {
    for (const [path, info] of thumbnailInfos.entries()) {
      if (info.isBeingDeleted) continue;

      info.isBeingDeleted = true;
      await FileUtils.deleteEntry(path);
      info.isBeingDeleted = false;
    }
  }

  private addThumbnailForPictureFileId(
    pictureFileId: string,
    thumbnailPath: string
  ): void {
    const thumbnailInfos = this.pictureIdToThumbnailInfosMap.get(pictureFileId);
    if (thumbnailInfos) {
      thumbnailInfos.set(thumbnailPath, {
        isBeingDeleted: false
      });
    } else {
      this.pictureIdToThumbnailInfosMap.set(
        pictureFileId,
        new Map([[thumbnailPath, { isBeingDeleted: false }]])
      );
    }
  }
}

type ThumbnailInfo = {
  isBeingDeleted: boolean;
};
