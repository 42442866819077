import { EntityManagerFieldInfos } from '@record-it-npm/synchro-client';
import { createStructureTemplateRatingCategoryEntityInfo } from 'common/Types/Entities/StructureTemplateRatingCategory/createStructureTemplateRatingCategoryEntityInfo';
import { AppSynchronizationEnvironmentTypes } from '../../AppSynchronizationEnvironmentTypes';
import { StructureTemplateRatingCategory } from './types';

export const structureTemplateRatingCategoryEntityInfo =
  createStructureTemplateRatingCategoryEntityInfo<StructureTemplateRatingCategory>(
    {
      additionalFieldInfos:
        EntityManagerFieldInfos.createAdditionalFieldInfos<AppSynchronizationEnvironmentTypes>()
    }
  );
