import { autoinject, bindable } from 'aurelia-framework';
import { EntityUtils } from '@record-it-npm/synchro-client';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskMeasurePointReading } from '../../classes/EntityManager/entities/ProcessTaskMeasurePointReading/types';
import { ProcessTaskMeasurePointReadingProperty } from '../../classes/EntityManager/entities/Property/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { defaultProcessTaskMeasurePointReadingProperties as defaultReadingProperties } from '../../data/Trockenmax/defaultProcessTaskMeasurePointReadingProperties';
import { Utils } from '../../classes/Utils/Utils';
import { PropertyInputField } from '../../aureliaComponents/property-input-field/property-input-field';
import { DateDisplayFormat } from '../../valueConverters/DateFormatValueConverter';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';

@autoinject()
export class EditProcessTaskMeasurePointReadingTable {
  @bindable()
  public processTaskMeasurePointReading: ProcessTaskMeasurePointReading | null =
    null;

  private readonly subscriptionManager: SubscriptionManager;
  private readonly updateRowsRateLimited = Utils.rateLimitFunction(
    this.updateRows.bind(this),
    0
  );

  private isAttached: boolean = false;
  private readingProperties: Array<ProcessTaskMeasurePointReadingProperty> = [];
  private lastReading: ProcessTaskMeasurePointReading | null = null;
  private lastReadingProperties: Array<ProcessTaskMeasurePointReadingProperty> =
    [];

  private rows: Array<RowData> = [];

  private readonly DateDisplayFormat = DateDisplayFormat;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    const propertyToFocus = defaultReadingProperties.find(
      (p) => p.focusProperty
    );
    if (!propertyToFocus) return;

    const property = this.readingProperties.find(
      (p) => p.name === propertyToFocus.name
    );
    if (!property) return;

    const viewModel = this.getPropertyViewModel(property);
    if (viewModel) viewModel.focus();
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateReadingProperties.bind(this)
    );
    this.updateReadingProperties();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      this.updateLastReadingProperties.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskMeasurePointReading,
      this.updateLastReading.bind(this)
    );
    this.updateLastReading();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskMeasurePointReadingChanged(): void {
    if (this.isAttached) {
      this.updateReadingProperties();
      this.updateLastReading();
    }
  }

  private updateReadingProperties(): void {
    if (this.processTaskMeasurePointReading) {
      this.readingProperties =
        this.entityManager.propertyRepository.getSortedPropertiesByProcessTaskMeasurePointReadingId(
          this.processTaskMeasurePointReading.id
        );
    } else {
      this.readingProperties = [];
    }

    this.updateRowsRateLimited();
  }

  private updateLastReading(): void {
    if (this.processTaskMeasurePointReading) {
      this.lastReading =
        this.entityManager.processTaskMeasurePointReadingRepository.getLastReading(
          this.processTaskMeasurePointReading.processTaskMeasurePointId,
          this.processTaskMeasurePointReading.date
        );
    } else {
      this.lastReading = null;
    }

    this.updateLastReadingProperties();
  }

  private updateLastReadingProperties(): void {
    if (this.lastReading) {
      this.lastReadingProperties =
        this.entityManager.propertyRepository.getSortedPropertiesByProcessTaskMeasurePointReadingId(
          this.lastReading.id
        );
    } else {
      this.lastReadingProperties = [];
    }

    this.updateRowsRateLimited();
  }

  private updateRows(): void {
    const rows = this.readingProperties.map<RowData>((readingProperty) => {
      return {
        trackingKey: EntityUtils.getTrackingKey(readingProperty),
        readingProperty,
        lastReadingProperty:
          this.lastReadingProperties.find(
            (lastReadingProperty) =>
              lastReadingProperty.name === readingProperty.name
          ) ?? null
      };
    });

    this.rows = InstancePreserver.createNewArray({
      originalArray: this.rows,
      newArray: rows,
      getTrackingValue: (item) => item.trackingKey
    });
  }

  private getPropertyViewModel(
    property: ProcessTaskMeasurePointReadingProperty
  ): PropertyInputField | null {
    const elementId =
      'edit-process-task-measure-point-reading-table--ReadingPropertyInputField-' +
      property.id;
    const element = document.getElementById(elementId);
    if (!element) return null;

    return Utils.getViewModelOfElement(/** @type {HTMLElement} */ element);
  }
}

type RowData = {
  trackingKey: string;
  readingProperty: ProcessTaskMeasurePointReadingProperty;
  lastReadingProperty: ProcessTaskMeasurePointReadingProperty | null;
};
