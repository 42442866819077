import { NumberUtils } from '../Utils/NumberUtils';
import { Vector } from './Vector';

export class RectangleHelper {
  /**
   * @param {Vector} point
   * @param {Vector} rectangleSize
   * @param {Vector} rectanglePosition - position of the top left point of the rectangle
   * @returns {boolean}
   */
  static rectangleContainsPoint(point, rectangleSize, rectanglePosition) {
    const bottomRightPoint = rectanglePosition.clone().addVector(rectangleSize);

    return (
      rectanglePosition.getX() < point.getX() &&
      point.getX() < bottomRightPoint.getX() &&
      rectanglePosition.getY() < point.getY() &&
      point.getY() < bottomRightPoint.getY()
    );
  }

  /**
   * @param {Vector} rectangleSize
   * @param {Vector} rectanglePosition - position of the top left point of the rectangle
   * @param {Vector} boundingAreaSize
   * @returns {boolean}
   */
  static rectangleIsInBoundingArea(
    rectangleSize,
    rectanglePosition,
    boundingAreaSize
  ) {
    const bottomRightPoint = rectanglePosition.clone().addVector(rectangleSize);

    return (
      rectanglePosition.getX() >= 0 &&
      bottomRightPoint.getX() <= boundingAreaSize.getX() &&
      rectanglePosition.getY() >= 0 &&
      bottomRightPoint.getY() <= boundingAreaSize.getY()
    );
  }

  /**
   * an overlap of 0 means the rectangle is inside the bounding area
   * a positive overlap means the rectangle is outside of the bounding area by that size (in the positive direction (right, bottom))
   * a negative overlap means the rectangle is outside of the bounding area by that size (in the negative direction (left, top))
   *
   * @param {TGeometryRectangle} rectangle
   * @param {Vector} boundingAreaSize
   * @returns {Vector}
   */
  static getRectangleBoundingAreaOutsideOverlap(rectangle, boundingAreaSize) {
    const bottomRightPoint = rectangle.position
      .clone()
      .addVector(rectangle.size);

    const overlapTopLeft = new Vector(
      this._getSingleDirectionOverlap(
        rectangle.position.getX(),
        0,
        boundingAreaSize.getX()
      ),
      this._getSingleDirectionOverlap(
        rectangle.position.getY(),
        0,
        boundingAreaSize.getY()
      )
    );

    const overlapBottomRight = new Vector(
      this._getSingleDirectionOverlap(
        bottomRightPoint.getX(),
        0,
        boundingAreaSize.getX()
      ),
      this._getSingleDirectionOverlap(
        bottomRightPoint.getY(),
        0,
        boundingAreaSize.getY()
      )
    );

    const overlap = new Vector(0, 0);
    if (Math.abs(overlapTopLeft.getX()) > Math.abs(overlapBottomRight.getX())) {
      overlap.setX(overlapTopLeft.getX());
    } else {
      overlap.setX(overlapBottomRight.getX());
    }

    if (Math.abs(overlapTopLeft.getY()) > Math.abs(overlapBottomRight.getY())) {
      overlap.setY(overlapTopLeft.getY());
    } else {
      overlap.setY(overlapBottomRight.getY());
    }

    return overlap;
  }

  /**
   * @param {number} position
   * @param {number} minPosition
   * @param {number} maxPosition
   * @returns {number}
   * @private
   */
  static _getSingleDirectionOverlap(position, minPosition, maxPosition) {
    if (position < minPosition) {
      return position - minPosition;
    }

    if (position > maxPosition) {
      return position - maxPosition;
    }

    return 0;
  }

  /**
   * stolen from https://stackoverflow.com/a/1879223
   *
   * @param {Vector} rectangleSize
   * @param {Vector} rectanglePosition - position of the top left point of the rectangle
   * @param {number} circleRadius
   * @param {Vector} circlePosition
   * @returns {boolean}
   */
  static rectangleIntersectsWithCircle(
    rectangleSize,
    rectanglePosition,
    circleRadius,
    circlePosition
  ) {
    const bottomRight = rectanglePosition.clone().addVector(rectangleSize);

    const closest = new Vector(
      NumberUtils.clamp({
        value: circlePosition.getX(),
        min: rectanglePosition.getX(),
        max: bottomRight.getX()
      }),
      NumberUtils.clamp({
        value: circlePosition.getY(),
        min: rectanglePosition.getY(),
        max: bottomRight.getY()
      })
    );

    const distance = circlePosition.clone().substractVector(closest);
    const distanceSquared = distance.clone().multiplyVector(distance);

    return (
      distanceSquared.getX() + distanceSquared.getY() <
      circleRadius * circleRadius
    );
  }

  /**
   * @param {number} value
   * @param {number} min
   * @param {number} max
   * @returns {number}
   * @private
   */
  static _clampNumber(value, min, max) {
    return Math.min(Math.max(value, min), max);
  }
}

/**
 * @typedef {Object} TGeometryRectangle
 * @property {Vector} position - top left position of the rectangle
 * @property {Vector} size - height/width of the rectangle
 */
