import Waves from 'node-waves';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';

/**
 * this class helps with detecting drag actions on touch devices (e.g. for drawing something)
 * it also provides an optional pressTimeout with which dragging is only allowed if the user keeps pressing for a certain time
 * this is useful if something is dragable and scrollable for example
 */
export class TouchDraggingDetector {
  private static defaultOptions: TouchDraggingDetectorOptions = {
    pressTimeoutEnabled: false,
    rippleEffect: false,
    passive: false,
    elementCanStartDragging: () => true,
    elementSkipsPressTimeout: () => false
  };

  private enabled: boolean = true;

  private readonly options: TouchDraggingDetectorOptions;
  private draggingEnabled = false;
  private pressTimeoutHandle: number | null = null;
  private initialized: boolean = false;

  private dragCallback: TouchHandler | null = null;
  private dragStartCallback: TouchHandler | null = null;
  private dragEndCallback: (() => void) | null = null;

  private readonly boundHandleTouchstart: TouchHandler;
  private readonly boundHandleTouchmove: TouchHandler;
  private readonly boundHandleTouchend: TouchHandler;

  constructor(
    private readonly element: HTMLElement,
    options?: Partial<TouchDraggingDetectorOptions>
  ) {
    this.options = Object.assign(
      {},
      TouchDraggingDetector.defaultOptions,
      options || {}
    );

    this.boundHandleTouchstart = this.handleTouchstart.bind(this);
    this.boundHandleTouchmove = this.handleTouchmove.bind(this);
    this.boundHandleTouchend = this.handleTouchend.bind(this);

    this.init();
  }

  public init(): void {
    if (this.initialized) {
      return;
    }

    this.element.addEventListener('touchstart', this.boundHandleTouchstart);
    this.element.addEventListener('touchmove', this.boundHandleTouchmove, {
      passive: this.options.passive
    });
    this.element.addEventListener('touchend', this.boundHandleTouchend);

    this.initialized = true;
  }

  public destroy(): void {
    this.element.removeEventListener('touchstart', this.boundHandleTouchstart);
    this.element.removeEventListener('touchmove', this.boundHandleTouchmove);
    this.element.removeEventListener('touchend', this.boundHandleTouchend);

    this.initialized = false;
  }

  public enable(): void {
    this.enabled = true;
  }

  public disable(): void {
    this.enabled = false;
  }

  /**
   * manually cancel the current dragging event, but the dragEnd callback will not be called
   */
  public cancelDragging(): void {
    this.draggingEnabled = false;
  }

  public onDragStart(callback: TouchHandler | null): void {
    this.dragStartCallback = callback;
  }

  public onDrag(callback: TouchHandler | null): void {
    this.dragCallback = callback;
  }

  public onDragEnd(callback: (() => void) | null): void {
    this.dragEndCallback = callback;
  }

  private handleTouchstart(event: TouchEvent): void {
    if (
      event.touches.length > 1 ||
      !this.options.elementCanStartDragging(event.target as Element)
    ) {
      this.clearDraggingState();
      return;
    }

    const startDragging = (): void => {
      this.draggingEnabled = true;
      this.dragStartCallback && this.dragStartCallback(event);

      if (this.options.rippleEffect) {
        const touch = event.touches[0];
        assertNotNullOrUndefined(
          touch,
          "can't TouchDraggingDetector.handleTouchstart when there is no touch"
        );

        const bounding = this.element.getBoundingClientRect();
        this.element.classList.add('waves-effect');
        const duration = 300;
        Waves.ripple(this.element, {
          wait: duration,
          position: {
            x: touch.clientX - bounding.x,
            y: touch.clientY - bounding.y
          }
        });

        setTimeout(() => {
          this.element.classList.remove('waves-effect');
        }, duration);
      }
    };

    const target = event.target as Element;
    if (
      this.options.pressTimeoutEnabled &&
      !this.options.elementSkipsPressTimeout(target)
    ) {
      this.pressTimeoutHandle = window.setTimeout(startDragging, 500);
    } else if (this.enabled) {
      startDragging();
    }
  }

  private handleTouchmove(event: TouchEvent): void {
    if (this.draggingEnabled) {
      this.dragCallback && this.dragCallback(event);
    } else {
      if (this.pressTimeoutHandle) {
        window.clearTimeout(this.pressTimeoutHandle);
      }
    }
  }

  private handleTouchend(): void {
    this.clearDraggingState();
  }

  private clearDraggingState(): void {
    if (this.draggingEnabled) {
      this.dragEndCallback && this.dragEndCallback();
    }

    if (this.pressTimeoutHandle) {
      clearTimeout(this.pressTimeoutHandle);
    }
    this.draggingEnabled = false;
  }
}

export type TouchDraggingDetectorOptions = {
  pressTimeoutEnabled: boolean;
  rippleEffect: boolean;
  passive: boolean;
  elementCanStartDragging: (element: Element) => boolean;
  elementSkipsPressTimeout: (element: Element) => boolean;
};

export type TouchHandler = (event: TouchEvent) => void;
