/* global Msvg */

import { autoinject, bindable } from 'aurelia-framework';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ImageHelper } from '../../classes/ImageHelper';
import { Tool } from './tools/Tool';

/**
 * depends on the msvg library
 *
 * this is the basis for the provided tools, but the tool selection/activation has to be controlled externally
 *
 * @event changed - fired when the content of the svg element has changed, e.g. from a tool
 */
@autoinject()
export class DrawingArea {
  /**
   * height of the resulting picture
   */
  @bindable public height = 300;

  /**
   * width of the resulting image
   */
  @bindable public width = 400;

  /**
   * the attributes which should be removed from the base64 export
   */
  private svgBindingAttributes = [
    'au-target-id',
    'ref',
    'class',
    'click.trigger',
    'mousemove.trigger',
    'mouseenter.trigger',
    'mouseleave.trigger',
    'mousedown.trigger',
    'mouseup.trigger',
    'touchstart.trigger',
    'touchmove.trigger',
    'touchend.trigger'
  ];

  private domElement: HTMLElement;
  private svgElement: SVGSVGElement | null = null;
  private msvg: Msvg.Msvg | null = null;
  private activeTool: Tool | null = null;
  protected ratio = 1;

  constructor(element: Element) {
    this.domElement = element as HTMLElement;
  }

  protected bind(): void {
    this.msvg = new Msvg.Msvg(this.getSvgElement());
    this.updateViewBox();
    this.updateRatio();
  }

  public reset(): void {
    this.empty();
    this.modified();
  }

  public getMsvg(): Msvg.Msvg {
    if (!this.msvg) {
      throw new Error('Msvg is not available!');
    }
    return this.msvg;
  }

  /**
   * if you modify the drawing area msvg, call this function so other usages can be informed about the modification
   */
  public modified(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'changed',
      detail: null
    });
  }

  /**
   * set the active tool to delegate the events to
   */
  public setActiveTool(tool: Tool | null): void {
    this.activeTool = tool;
  }

  public empty(): void {
    if (this.msvg) {
      this.msvg.empty();
    }
  }

  protected heightChanged(): void {
    this.updateViewBox();
    this.updateRatio();
  }

  protected widthChanged(): void {
    this.updateViewBox();
    this.updateRatio();
  }

  /**
   * toBeDrawnOnCanvas:
   *  adds the height and width attribute so firefox actually will draw it on a canvas (https://bugzilla.mozilla.org/show_bug.cgi?id=700533)
   *  use this only for drawing it on the canvas and not for saving it!!
   *
   * @param {boolean} [toBeDrawnOnCanvas]
   * @returns {string}
   */
  public exportSvgAsDataUrl(toBeDrawnOnCanvas: boolean = false): string {
    return ImageHelper.svgElementToDataUrl(
      this.getSvgElementToExport(toBeDrawnOnCanvas)
    );
  }

  private getSvgElement(): SVGSVGElement {
    if (!this.svgElement) {
      throw new Error('SVG element not available!');
    }
    return this.svgElement;
  }

  private updateViewBox(): void {
    if (this.msvg) {
      this.msvg.setViewBoxWithPoints(
        new Msvg.Point(0, 0),
        new Msvg.Point(this.width, this.height)
      );
    }
  }

  private updateRatio(): void {
    this.ratio = this.width / this.height;
  }

  protected getContentWrapperStyle(ratio: number): {
    'padding-bottom': string;
  } {
    return {
      'padding-bottom': (1 / ratio) * 100 + '%'
    };
  }

  /**
   * toBeDrawnOnCanvas:
   *  adds the height and width attribute so firefox actually will draw it on a canvas (https://bugzilla.mozilla.org/show_bug.cgi?id=700533)
   *  use this only for drawing it on the canvas and not for saving it!!
   *
   * @param {boolean} [toBeDrawnOnCanvas]
   * @returns {SVGSVGElement}
   * @private
   */
  private getSvgElementToExport(
    toBeDrawnOnCanvas: boolean = false
  ): SVGSVGElement {
    const svg = this.getSvgElement().cloneNode(true) as SVGSVGElement;
    this.svgBindingAttributes.forEach((attr) => {
      svg.removeAttribute(attr);
    });

    if (toBeDrawnOnCanvas) {
      svg.setAttribute('width', svg.viewBox.baseVal.width + 'px');
      svg.setAttribute('height', svg.viewBox.baseVal.height + 'px'); // needed for firefox https://bugzilla.mozilla.org/show_bug.cgi?id=700533
    }

    svg.setAttribute('xmlns', Msvg.Utils.Definitions.svgNamespace);
    svg.setAttribute('xmlns:xlink', Msvg.Utils.Definitions.xlinkNamespace);

    return svg;
  }

  // ************ Event Handler **************

  protected handleDrawingAreaClick(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseClicked(event);
    return true;
  }

  protected handleDrawingAreaMousemove(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseMoved(event);
    return true;
  }

  protected handleDrawingAreaMouseenter(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseEntered(event);
    return true;
  }

  protected handleDrawingAreaMouseleave(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseLeft(event);
    return true;
  }

  protected handleDrawingAreaMousedown(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseDown(event);
    return true;
  }

  protected handleDrawingAreaMouseup(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.mouseUp(event);
    return true;
  }

  protected handleDrawingAreaTouchstart(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.touchStarted(event);
    return true;
  }

  protected handleDrawingAreaTouchmove(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.touchMoved(event);
    return !this.activeTool; // only prevent the touchmove if there is an active tool
  }

  protected handleDrawingAreaTouchend(event: MouseEvent): boolean {
    this.activeTool?.eventDelegator.touchEnded(event);
    return true;
  }
}
