/* environment gets defined in the webpack.config.js by the DefinePlugin */

import { environment } from '../environment';

export class FeatureControl {
  /**
   * enables the timeout which makes it possible for the browser to animate the color change of e.g. the page-loader
   * can be disabled for faster startup times
   */
  public static NICE_THEME_TRANSITION = true;

  public static applyConfigForCurrentEnvironment(): void {
    switch (environment.env) {
      case 'prod':
        this.NICE_THEME_TRANSITION = true;
        break;

      case 'dev':
      default:
        this.NICE_THEME_TRANSITION = false;
        break;
    }
  }
}

FeatureControl.applyConfigForCurrentEnvironment();
