import { Container } from 'aurelia-dependency-injection';
import { TestingHelper } from '../../TestingHelper';
import { AppEntityManager } from './AppEntityManager';
import { AutoActualizationService } from './generalServices/AutoActualizationService';
import { EntryIdUpdateService } from './Entry/EntryIdUpdateService';
import { EntityIdService } from './generalServices/EntityIdService/EntityIdService';
import { PictureIdUpdateService } from './Picture/PictureIdUpdateService';
import { PictureFileAutoDownloadService } from './PictureFile/PictureFileAutoDownloadService';
import { PictureFileAutoUploadService } from './PictureFile/PictureFileAutoUploadService';
import { PictureFileUploadService } from './PictureFile/PictureFileUploadService';
import { ProjectIdUpdateService } from './Project/ProjectIdUpdateService';
import { ProjectSubEntitiesRemovalService } from './Project/ProjectSubEntitiesRemovalService';
import { ProjectTitlePicturesActualizationsService } from './Project/ProjectTitlePicturesActualizationsService';
import { TextBrickIdUpdateService } from './TextBrick/TextBrickIdUpdateService';
import { ThingChangeService } from './Thing/ThingChangeService';
import { ThingIdUpdateService } from './Thing/ThingIdUpdateService';
import { CurrentUserService } from './User/CurrentUserService';
import { UserGroupIdUpdateService } from './UserGroup/UserGroupIdUpdateService';
import { ThingGroupPropertiesService } from './ThingGroup/ThingGroupPropertiesService';
import { ActiveUserCompanySettingService } from './UserCompanySetting/ActiveUserCompanySettingService';
import { GeneralFileUploadService } from './GeneralFile/GeneralFileUploadService';
import { FileUploadService } from '../../../services/FileUploadService';
import { WaitForInitialActualizationService } from './generalServices/WaitForInitialActualizationService/WaitForInitialActualizationService';
import { ClientDataPatcher } from '../../ClientDataPatcher';

export async function init(
  container: Container,
  initializingStepCallback: (className: string) => void
): Promise<void> {
  for (const info of classInfos) {
    initializingStepCallback(info.className);
    const instance: Initializable = container.get(info.class);
    await instance.init();
  }

  TestingHelper.entityManager = container.get(AppEntityManager);
  TestingHelper.clientDataPatcher = new ClientDataPatcher(
    TestingHelper.entityManager
  );
}

export async function flush(container: Container): Promise<void> {
  for (const info of reverseClassInfos) {
    const instance: Initializable = container.get(info.class);
    await instance.flush?.();
  }
}

export function destroy(container: Container): void {
  for (const info of reverseClassInfos) {
    const instance: Initializable = container.get(info.class);
    instance.destroy();
  }
}

const classInfos: Array<{
  class: InitializableConstructor;
  className: string;
}> = [
  {
    class: EntityIdService,
    className: 'EntityIdService'
  },
  {
    class: ProjectSubEntitiesRemovalService,
    className: 'ProjectSubEntitiesRemovalService'
  },
  {
    class: AppEntityManager,
    className: 'AppEntityManager'
  },
  {
    class: TextBrickIdUpdateService,
    className: 'TextBrickTemplateIdUpdateService'
  },
  {
    class: ThingIdUpdateService,
    className: 'ThingIdUpdateService'
  },
  {
    class: EntryIdUpdateService,
    className: 'EntryIdUpdateService'
  },
  {
    class: PictureIdUpdateService,
    className: 'PictureIdUpdateService'
  },
  {
    class: ProjectIdUpdateService,
    className: 'ProjectIdUpdateService'
  },
  {
    class: UserGroupIdUpdateService,
    className: 'UserGroupIdUpdateService'
  },
  {
    class: CurrentUserService,
    className: 'CurrentUserService'
  },
  {
    class: ActiveUserCompanySettingService,
    className: 'ActiveUserCompanySettingService'
  },
  {
    class: AutoActualizationService,
    className: 'AutoActualizationService'
  },
  {
    class: FileUploadService,
    className: 'FileUploadService'
  },
  {
    class: PictureFileUploadService,
    className: 'PictureFileUploadService'
  },
  {
    class: GeneralFileUploadService,
    className: 'GeneralFileUploadService'
  },
  {
    class: PictureFileAutoDownloadService,
    className: 'PictureFileAutoDownloadService'
  },
  {
    class: PictureFileAutoUploadService,
    className: 'PictureFileAutoUploadService'
  },
  {
    class: ThingChangeService,
    className: 'ThingChangeService'
  },
  {
    class: ProjectTitlePicturesActualizationsService,
    className: 'ProjectTitlePicturesActualizationsService'
  },
  {
    class: ThingGroupPropertiesService,
    className: 'ThingGroupPropertiesService'
  },
  {
    class: WaitForInitialActualizationService,
    className: 'WaitForInitialActualizationService'
  }
];

const reverseClassInfos = [...classInfos].reverse();

type InitializableConstructor = new (...args: Array<any>) => Initializable;

type Initializable = {
  init(): Promise<void>;
  flush?(): Promise<void>;
  destroy(): void;
};
