import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import { ThingSection, ThingSectionCreationEntity } from './types';

export class ThingSectionRepository extends AppEntityRepository<EntityName.ThingSection> {
  public getOrderedByThingId(thingId: string): Array<ThingSection> {
    return this.getUnorderedByThingId(thingId).sort(
      (a, b) => a.order - b.order
    );
  }

  public createAtEnd(
    creationEntity: Omit<ThingSectionCreationEntity, 'order'>
  ): ThingSection {
    const orders = this.getUnorderedByThingId(creationEntity.ownerThingId).map(
      (section) => section.order
    );

    return this.create({
      ...creationEntity,
      order: Math.max(-1, ...orders) + 1
    });
  }

  public deleteAndUpdateOrder(thingSection: ThingSection): void {
    this.delete(thingSection);

    this.reassignOrderBasedOnArrayIndex(
      this.getOrderedByThingId(thingSection.ownerThingId)
    );
  }

  public moveSectionToIndex({
    section,
    index
  }: {
    section: ThingSection;
    index: number;
  }): void {
    const sections = this.getOrderedByThingId(section.ownerThingId);

    const removalIndex = sections.indexOf(section);
    sections.splice(removalIndex, 1);

    sections.splice(index, 0, section);

    this.reassignOrderBasedOnArrayIndex(sections);
  }

  private getUnorderedByThingId(thingId: string): Array<ThingSection> {
    return this.getAll().filter((section) => section.ownerThingId === thingId);
  }

  private reassignOrderBasedOnArrayIndex(sections: Array<ThingSection>): void {
    for (const [index, section] of sections.entries()) {
      if (section.order !== index) {
        section.order = index;
        this.update(section);
      }
    }
  }
}
