export class DomEventHelper {
  /**
   * create an event object to fire
   *
   * shamelessly stolen from:
   * https://ilikekillnerds.com/2015/08/aurelia-custom-elements-custom-callback-events-tutorial/
   *
   */
  public static createEvent<T extends CustomEvent>(
    name: string,
    bubbles: boolean,
    detail: T['detail']
  ): T {
    let event;

    if (window.CustomEvent) {
      event = new CustomEvent(name, {
        cancelable: true,
        detail: detail,
        bubbles: bubbles
      });
    } else {
      event = document.createEvent('CustomEvent');
      event.initCustomEvent(name, bubbles, true, {
        detail: detail
      });
    }

    return event as T;
  }

  public static fireEvent<T extends CustomEvent>(
    eventTarget: EventTarget,
    options: IFireEventOptions<T>
  ): T {
    const event = DomEventHelper.createEvent(
      options.name,
      !!options.bubbles,
      options.detail
    );
    eventTarget.dispatchEvent(event);
    return event;
  }

  public static canUseArrowKeys(event: KeyboardEvent): boolean {
    const target = event.target as HTMLElement;
    const tagName = target.tagName.toLowerCase();

    return !(tagName === 'input' || tagName === 'textarea');
  }
}

export interface IFireEventOptions<T extends CustomEvent> {
  name: T['type'];
  bubbles?: boolean;
  detail: T['detail'];
}

export type NamedCustomEvent<
  TName extends string,
  TDetail = null
> = CustomEvent<TDetail> & { type: TName };
