import { ValueCalculationConfigType } from 'common/Types/Entities/ValueCalculationConfig/ValueCalculationConfigDto';
import { AppEntityRepository } from '../../base/AppEntityRepository';
import { EntityName } from '../types';
import {
  ProjectValueCalculationConfig,
  ThingLevelValueCalculationConfigTypeToEntityType,
  ThingTypeLevelValueCalculationConfigTypeToEntityType,
  ThingTypeProjectValueCalculationConfig,
  ThingTypeThingValueCalculationConfig
} from './types';

export class ValueCalculationConfigRepository extends AppEntityRepository<EntityName.ValueCalculationConfig> {
  public getByThingIdAndType<TType extends ValueCalculationConfigType>(
    thingId: string,
    type: TType
  ): Array<ThingLevelValueCalculationConfigTypeToEntityType<TType>> {
    return this.getAll().filter(
      (x): x is ThingLevelValueCalculationConfigTypeToEntityType<TType> => {
        return x.ownerThingId === thingId && x.type === type;
      }
    );
  }

  public getByThingTypeIdAndType<TType extends ValueCalculationConfigType>(
    thingTypeId: string,
    type: TType
  ): Array<ThingTypeLevelValueCalculationConfigTypeToEntityType<TType>> {
    return this.getAll().filter(
      (x): x is ThingTypeLevelValueCalculationConfigTypeToEntityType<TType> => {
        return x.thingTypeId === thingTypeId && x.type === type;
      }
    );
  }

  public getByThingTypeId(
    thingTypeId: string
  ): Array<
    | ThingTypeThingValueCalculationConfig
    | ThingTypeProjectValueCalculationConfig
  > {
    return this.getAll().filter(
      (
        x
      ): x is
        | ThingTypeThingValueCalculationConfig
        | ThingTypeProjectValueCalculationConfig => {
        return x.thingTypeId === thingTypeId;
      }
    );
  }

  public getByThingAndReportTypeId(
    thingId: string,
    reportTypeId: string
  ): Array<ProjectValueCalculationConfig> {
    return this.getAll().filter((x): x is ProjectValueCalculationConfig => {
      return (
        x.type === ValueCalculationConfigType.PROJECT &&
        x.ownerThingId === thingId &&
        x.reportTypeId === reportTypeId
      );
    });
  }
}
