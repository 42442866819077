import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyMultiDropdownValueItem } from 'common/Types/Entities/Property/PropertyDto';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../inputComponents/clickable-text-input/clickable-text-input';
import { CustomMultiSelect } from '../../inputComponents/custom-multi-select/custom-multi-select';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertyMultiDropdownWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyMultiDropdownWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  private subscriptionManager: SubscriptionManager;

  private choices: Array<MultiDropdownChoice> = [];
  private selectedChoices: Array<MultiDropdownChoice> = [];
  private lastOpenSelectedChoices: Array<MultiDropdownChoice> = [];
  protected showCustomValue: boolean = false;

  protected multiSelect: CustomMultiSelect | null = null;
  protected customChoiceInput: ClickableTextInput | null = null;

  constructor(
    private readonly i18n: I18N,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focus(): void {
    assertNotNullOrUndefined(
      this.multiSelect,
      "can't BasePropertyMultiDropdownWidget.focus without multiselect"
    );
    this.multiSelect.focus();
  }

  protected attached(): void {
    this.subscriptionManager.subscribeToExpression(
      this,
      'configuration.binding.choices',
      this.updateChoices.bind(this)
    );
    this.subscriptionManager.subscribeToExpression(
      this,
      'configuration.binding.value',
      this.updateSelectedChoices.bind(this)
    );

    this.updateChoices();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private updateChoices(): void {
    const propertyChoices = this.configuration?.binding.choices ?? [];

    const dropdownChoices: Array<MultiDropdownChoice> = propertyChoices.map(
      (c) => {
        return {
          value: c,
          label: c
        };
      }
    );

    dropdownChoices.unshift({
      value: null,
      label: this.i18n.tr('general.custom') as string
    });

    this.choices = InstancePreserver.createNewArray({
      originalArray: this.choices,
      newArray: dropdownChoices,
      getTrackingValue: (item) => item.value
    });

    this.updateSelectedChoices();
  }

  private updateSelectedChoices(): void {
    let selectedChoices: Array<MultiDropdownChoice> = [];

    if (this.configuration?.binding.value) {
      let valueItems: Array<PropertyMultiDropdownValueItem> | null = null;

      try {
        valueItems = JSON.parse(this.configuration.binding.value);
      } catch (e) {
        console.error(e);
      }

      if (valueItems) {
        selectedChoices = this.getSelectedChoicesForValueItems(valueItems);
      } else {
        selectedChoices = this.getSelectedChoicesForChoiceValue(
          this.configuration.binding.value
        );
      }
    }

    this.selectedChoices = selectedChoices;
    this.updateShowCustomValue();
  }

  private getSelectedChoicesForValueItems(
    valueItems: Array<PropertyMultiDropdownValueItem>
  ): Array<MultiDropdownChoice> {
    const selectedChoices: Array<MultiDropdownChoice> = [];

    valueItems.forEach((vi) => {
      const choice = this.choices.find((c) => c.value === vi.value);
      if (choice) {
        selectedChoices.push(choice);
      }
    });

    return selectedChoices;
  }

  /**
   * alternative way to retrieve the value
   * this is necessary when e.g. a dropdown property gets converted into a multi-dropdown, but the value hasn't been converted into the multi-dropdown style
   */
  private getSelectedChoicesForChoiceValue(
    choiceValue: string
  ): Array<MultiDropdownChoice> {
    const selectedChoice = this.choices.find((c) => c.value === choiceValue);
    return selectedChoice ? [selectedChoice] : [];
  }

  protected handleSelectedChoicesChanged(): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyMultiDropdownWidget.handleSelectedChoicesChanged without configuration"
    );

    let value: string | null = null;

    if (this.selectedChoices.length) {
      const valueItems: Array<PropertyMultiDropdownValueItem> =
        this.selectedChoices.map((c) => {
          return {
            value: c.value
          };
        });

      value = JSON.stringify(valueItems);
    }

    if (this.configuration.binding.value !== value) {
      this.configuration.binding.setValueData({
        value: value,
        customChoice: this.configuration.binding.customChoice
      });
    }

    this.updateShowCustomValue();
  }

  protected handleDropdownOpened(): void {
    this.lastOpenSelectedChoices = [...this.selectedChoices];
  }

  protected handleDropdownClosing(): void {
    if (
      !this.hasCustomChoice(this.lastOpenSelectedChoices) &&
      this.hasCustomChoice(this.selectedChoices)
    ) {
      this.lastOpenSelectedChoices = [...this.selectedChoices];

      assertNotNullOrUndefined(
        this.customChoiceInput,
        'no customChoiceInput available'
      );
      this.customChoiceInput.focus();
    }
  }

  protected handleCustomChoiceChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyMultiDropdownWidget.handleCustomChoiceChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: this.configuration.binding.value,
      customChoice: event.detail.value as string | null
    });
  }

  private updateShowCustomValue(): void {
    this.showCustomValue = this.hasCustomChoice(this.selectedChoices);
  }

  private hasCustomChoice(
    selectedChoices: Array<MultiDropdownChoice>
  ): boolean {
    return selectedChoices.some((c) => c.value === null);
  }

  @computedFrom('compact', 'compactButton', 'inTable')
  protected get compactInputExtraSpacingNeeded(): boolean {
    if (!this.style) {
      return false;
    }

    return (
      !this.style.compact && this.style.compactButton && !this.style.inTable
    );
  }
}

export const basePropertyMultiDropdownWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });

export type MultiDropdownChoice = {
  value: string | null;
  label: string | null;
};
