import { autoinject } from 'aurelia-framework';
import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { OriginalIdUtils } from '../classes/EntityManager/utils/OriginalIdUtils/OriginalIdUtils';
import { AppEntityManager } from '../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../classes/EntityManager/entities/types';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from './SubscriptionManagerService';
import { BaseEntity } from '../classes/EntityManager/entities/BaseEntity';

/**
 * Simple helper that saves the last used entity of a given type (for a given entity id).
 * Can be used to display the "Last Used" picture of a project, for example.
 */
export class LastUsedEntityService {
  private lastUsedChangeCallbacks: Array<AfterLastUsedChange> = [];

  constructor(
    private readonly entityName: EntityName,
    subscriptionManagerService: SubscriptionManagerService,
    entityManager: AppEntityManager
  ) {
    const subscriptionManager = subscriptionManagerService.create();
    subscriptionManager.addDisposable(
      entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
        entityName,
        this.upgradeEntityIdHook.bind(this)
      )
    );
  }

  /**
   * Marks an entity the `entityId` corresponds to as being the last used one of its type.
   */
  public async setLastUsed(id: string, entityId: string): Promise<void> {
    const map = await this.retrieveMap();
    map.set(id, entityId);
    await this.persistMap(map);
    this.triggerLastUsedChange(entityId);
  }

  /**
   * @returns the id of the last used entity.
   */
  public async getLastUsed(id: string): Promise<string | null> {
    const map = await this.retrieveMap();
    return map.get(id) ?? null;
  }

  /**
   * Helper method for upgrading ids.
   *
   * Should be called if an entity is synchronized & the id changes.
   * @example
   * ```ts
   * this.subscriptionManager.addDisposable(
   *   this.entityManager.entitySynchronization.registerEntitySpecificEntityIdUpgradedHook(
   *     EntityName.Picture,
   *     this.lastUsedPictureService.upgradeEntityIdHook.bind(this.lastUsedPictureService)
   *   )
   * );
   * ```
   */
  public upgradeEntityIdHook(entity: BaseEntity): void {
    const originalIds = OriginalIdUtils.getOriginalIdsForEntity(entity);
    void this.upgradeIds(originalIds, entity.id);
  }

  /**
   * register a callback that triggers when the entity last used changes.
   */
  public onLastUsedChange(hook: AfterLastUsedChange): Disposable {
    this.lastUsedChangeCallbacks.push(hook);
    return {
      dispose: () => {
        const index = this.lastUsedChangeCallbacks.indexOf(hook);
        if (index !== -1) {
          this.lastUsedChangeCallbacks.splice(index, 1);
        }
      }
    };
  }

  private async retrieveMap(): Promise<Map<string, string>> {
    const mapObject = await DataStorageHelper.getItem(this.dataStorageKey);
    return mapObject ? new Map(Object.entries(mapObject)) : new Map();
  }

  private async persistMap(map: Map<string, string>): Promise<void> {
    const mapObject: Record<string, string> = {};
    for (const entry of map.entries()) {
      mapObject[entry[0]] = entry[1];
    }
    await DataStorageHelper.setItem(this.dataStorageKey, mapObject);
  }

  private triggerLastUsedChange(entityId: string): void {
    for (const callback of this.lastUsedChangeCallbacks) {
      callback(entityId);
    }
  }

  private async upgradeIds(
    oldIds: Array<string>,
    newId: string
  ): Promise<void> {
    const map = await this.retrieveMap();
    const newMap = new Map<string, string>();
    for (const [key, value] of map.entries()) {
      if (oldIds.includes(value)) {
        newMap.set(key, newId);
      } else {
        newMap.set(key, value);
      }
    }
    await this.persistMap(newMap);
  }

  private get dataStorageKey(): string {
    return 'LastUsedEntityService.lastUsedEntity.' + this.entityName;
  }
}

type AfterLastUsedChange = (entityId: string) => void;

@autoinject()
export class LastUsedPictureForIdService extends LastUsedEntityService {
  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    entityManager: AppEntityManager
  ) {
    super(EntityName.Picture, subscriptionManagerService, entityManager);
  }
}

@autoinject()
export class LastUsedThingSectionForIdService extends LastUsedEntityService {
  constructor(
    subscriptionManagerService: SubscriptionManagerService,
    entityManager: AppEntityManager
  ) {
    super(EntityName.ThingSection, subscriptionManagerService, entityManager);
  }
}
