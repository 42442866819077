import { autoinject } from 'aurelia-framework';

import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';

import { AppEntityManager } from '../../AppEntityManager';
import { SocketService } from '../../../../../services/SocketService';
import { SubscriptionManagerService } from '../../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../../SubscriptionManager';

@autoinject()
export class WaitForInitialActualizationService {
  private readonly subscriptionManager: SubscriptionManager;
  private readonly promiseContainer = new PromiseContainer<void>();

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  /**
   * Returns a promise which will get resolved as soon as the initial actualization has been finished.
   * If the actualization is already finished, this will return a resolved Promise
   */
  public async waitForInitialActualization(): Promise<void> {
    return this.promiseContainer.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        if (!isConnected) {
          this.promiseContainer.resetPermanentStatus();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.entityManager.entityActualization.registerHooks({
        afterActualization: () => {
          this.promiseContainer.resolvePermanent();
        }
      })
    );
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}
