import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class EditUserGroupSharepointCredentialsWidget {
  @bindable public userGroup: UserGroup | null = null;

  @subscribableLifecycle()
  protected permissionHandle: EntityNameToPermissionsHandle[EntityName.UserGroup];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.permissionHandle = permissionsService.getPermissionsHandleForProperty({
      context: this as EditUserGroupSharepointCredentialsWidget,
      propertyName: 'userGroup',
      entityName: EntityName.UserGroup
    });
  }

  protected handleUserGroupChanged(): void {
    assertNotNullOrUndefined(
      this.userGroup,
      'cannot save user group without a user group'
    );
    this.entityManager.userGroupRepository.update(this.userGroup);
  }
}
