import { QuestionChoice } from 'common/Types/Entities/Question/QuestionDto';

export namespace ProjectQuestionUtils {
  /**
   * Returns the currently selected choice from an array of choices.
   */
  export function getSelectedChoice(
    answer: string,
    choices: Array<QuestionChoice>
  ): QuestionChoice | null {
    return choices.find((choice) => choice.choiceText === answer) ?? null;
  }
}
