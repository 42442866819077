export class FilterHelper {
  public static filterItems<T>(
    items: Array<T>,
    getItemFilterText: (obj: T) => string,
    filterValue: string | null
  ): Array<T> {
    const filteredItems = [];
    for (const item of items) {
      let isIncluded = true;

      if (filterValue) {
        const itemFilterText = getItemFilterText(item);
        if (
          itemFilterText.toUpperCase().indexOf(filterValue.toUpperCase()) === -1
        ) {
          isIncluded = false;
        }
      }

      if (isIncluded) {
        filteredItems.push(item);
      }
    }
    return filteredItems;
  }
}
