import { autoinject, bindable } from 'aurelia-framework';

import { DateUtils } from 'common/DateUtils';
import { Dialogs } from '../../classes/Dialogs';
import { EditMapLayerDialog } from '../../dialogs/edit-map-layer-dialog/edit-map-layer-dialog';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MapLayer } from '../../classes/EntityManager/entities/MapLayer/types';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { EntityName } from 'common/Types/BaseEntities/EntityName';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';

@autoinject()
export class MapLayerListItem {
  @bindable public mapLayer: MapLayer | null = null;

  @subscribableLifecycle()
  protected readonly mapLayerPermissionsHandle: EntityNameToPermissionsHandle[EntityName.MapLayer];

  private listItemElement: HTMLElement | null = null;

  private panelOpen = false;

  protected DateUtils = DateUtils;

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.mapLayerPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.MapLayer,
        context: this as MapLayerListItem,
        propertyName: 'mapLayer'
      });
  }

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  protected handleMoreButtonClicked(): void {
    this.panelOpen = !this.panelOpen;
  }

  protected handleEditMapLayerClicked(): void {
    if (!this.mapLayer) return;

    void EditMapLayerDialog.open({
      mapLayer: this.mapLayer
    });
  }

  protected handleDeleteMapLayerClicked(): void {
    const mapLayer = this.mapLayer;
    if (!mapLayer) return;

    void Dialogs.deleteEntityDialog(mapLayer).then(() => {
      this.entityManager.mapLayerRepository.delete(mapLayer);
    });
  }
}
