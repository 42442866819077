import { autoinject } from 'aurelia-dependency-injection';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../SubscriptionManager';
import {
  EntityIdUpdateService,
  UpdateConfigType
} from '../generalServices/EntityIdUpdateService';
import { EntityName } from '../types';

@autoinject()
export class ProjectIdUpdateService {
  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityIdUpdateService: EntityIdUpdateService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    this.subscriptionManager.addDisposable(
      this.entityIdUpdateService.registerUpdateConfig<
        EntityName.Project,
        EntityName.ReportType
      >({
        type: UpdateConfigType.SET_TO_NULL,
        entityName: EntityName.Project,
        key: 'report_type',
        referencedEntityName: EntityName.ReportType
      })
    );
  }

  public async flush(): Promise<void> {
    this.subscriptionManager.flush();
  }

  public destroy(): void {
    this.subscriptionManager.disposeSubscriptions();
  }
}
