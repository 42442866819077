import { autoinject, bindable } from 'aurelia-framework';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { DefectComment } from '../../classes/EntityManager/entities/DefectComment/types';
import { DefectChangeLogEntry } from '../../classes/EntityManager/entities/DefectChangeLogEntry/types';

/**
 * The report (protocol) of a defect.
 *
 * Contains the headline, an (optional) button for switching to details view,
 * and all comments & state changes regarding the defect.
 *
 * @event detail-view-button-clicked Fired when the DetailView button is clicked.
 */
@autoinject()
export class DefectProtocol {
  @bindable public defect: Defect | null = null;

  /**
   * Display the protocol in an expandable container.
   */
  @bindable public expandable: boolean = false;

  /**
   * If the button for navigating to the DetailView should be displayed.
   */
  @bindable public showDetailViewButton = true;

  protected defectComments: Array<DefectComment> | null = null;

  protected defectChangeLogEntries: Array<DefectChangeLogEntry> | null = null;

  private subscriptionManager: SubscriptionManager;

  private element: HTMLElement;

  constructor(
    defectProtocolRef: Element,
    private readonly entityManager: AppEntityManager,
    subManagerService: SubscriptionManagerService
  ) {
    this.element = defectProtocolRef as HTMLElement;
    this.subscriptionManager = subManagerService.create();
  }

  // ////////// LIFECYCLE //////////

  protected attached(): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.DefectComment,
      this.updateDefectComments.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.DefectChangeLogEntry,
      this.updateDefectChangeLogEntries.bind(this)
    );
    this.updateDefectComments();
    this.updateDefectChangeLogEntries();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  // ////////// ENTITY UPDATERS //////////

  private updateDefectComments(): void {
    if (!this.defect) {
      this.defectComments = [];
      return;
    }
    this.defectComments =
      this.entityManager.defectCommentRepository.getByOwnerDefectId(
        this.defect.id
      );
  }

  private updateDefectChangeLogEntries(): void {
    if (!this.defect) {
      this.defectChangeLogEntries = [];
      return;
    }
    this.defectChangeLogEntries =
      this.entityManager.defectChangeLogEntryRepository.getByOwnerDefectId(
        this.defect.id
      );
  }

  // ////////// OBSERVABLES //////////

  protected defectChanged(): void {
    this.updateDefectComments();
    this.updateDefectChangeLogEntries();
  }

  // ////////// CLICK HANDLERS //////////

  protected handleDetailViewButtonClick(): void {
    DomEventHelper.fireEvent(this.element, {
      name: 'detail-view-button-clicked',
      detail: {}
    });
  }
}
