export class Utils {
  /**
   * stolen from https://stackoverflow.com/a/6969486
   * escapes the text so it doesn't contain any special regex characters
   *
   * @param {string} string
   * @returns {string}
   */
  static escapeStringForRegex(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /**
   * @param {string} string
   * @param {number} desiredLength
   * @param {string} [character]
   * @returns {string}
   */
  static leftPad(string, desiredLength, character = '0') {
    while (string.length < desiredLength) {
      string = character + string;
    }

    return string;
  }

  /**
   *
   * @param {Readonly<Array<TValue>>} values
   * @param {(value: TValue) => TKey} keyGetter
   * @returns {Map<TKey, Array<TValue>>}
   * @template TValue
   * @template TKey
   */
  static groupValues(values, keyGetter) {
    /** @type {Map<TKey, Array<TValue>>} */
    const map = new Map();

    for (const value of values) {
      const key = keyGetter(value);

      let values = map.get(key);
      if (!values) {
        values = [];
        map.set(key, values);
      }

      values.push(value);
    }

    return map;
  }

  /**
   * Maps a key to a single value.
   * This is pretty similar to groupValues, but it only maps to a single value instead of multiple ones.
   * In case of a key collision, the later value will be used.
   *
   * @param {Readonly<Array<TValue>>} values
   * @param {(value: TValue) => TKey} keyGetter
   * @returns {Map<TKey, TValue>}
   * @template TValue
   * @template TKey
   */
  static keyValues(values, keyGetter) {
    /** @type {Map<TKey, TValue>} */
    const map = new Map();

    for (const value of values) {
      map.set(keyGetter(value), value);
    }

    return map;
  }

  /**
   * Make a strict comparison between a and b if they are not null/undefined
   *
   * @param {T|null|undefined} a
   * @param {T|null|undefined} b
   * @returns {boolean}
   * @template T
   */
  static compareOptionalValues(a, b) {
    return a === b || (a == null && b == null);
  }

  /**
   * Wait a specified amount of milliseconds.
   *
   * @param {number} timeMs
   * @returns {Promise<void>}
   */
  static wait(timeMs) {
    return new Promise((res) => {
      setTimeout(() => res(), timeMs);
    });
  }

  /**
   * @param {Map<TKey, TValue>} a - gets modified in place, values of b will be merged into this
   * @param {Map<TKey, TValue>} b
   * @param {(valueA: TValue, valueB: TValue) => TValue} valueMerger
   * @template TKey
   * @template TValue
   */
  static mergeMaps(a, b, valueMerger = (valueA, valueB) => valueB) {
    b.forEach((value, key) => {
      if (a.has(key)) {
        const mergedValue = valueMerger(
          /** @type {TValue} */ (a.get(key)),
          value
        );
        a.set(key, mergedValue);
      } else {
        a.set(key, value);
      }
    });
  }
}
