import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from '../../../../common/src/Asserts';
import { RecordItDialog } from '../record-it-dialog/record-it-dialog';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { Defect } from '../../classes/EntityManager/entities/Defect/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import { DefectCreationService } from '../../classes/EntityManager/entities/Defect/DefectCreationService';

/**
 * Dialog for editing details of a defect (name, description, dueAt, etc.)
 */
@autoinject()
export class CreateDefectDialog {
  private static TEMPORARY_GROUP_NAME = 'CreateDefectDialog';

  protected defect: Defect | null = null;

  protected dialog: RecordItDialog | null = null;

  /**
   * true if a user is currently being invited over email,
   * false otherwise.
   */
  protected isInvitingUser: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  private options: DialogOptions | null = null;

  constructor(
    subManagerService: SubscriptionManagerService,
    private readonly entityManager: AppEntityManager,
    private readonly defectCreationService: DefectCreationService
  ) {
    this.subscriptionManager = subManagerService.create();
  }

  public static async open(options: DialogOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private open(options: DialogOptions): void {
    assertNotNullOrUndefined(
      this.dialog,
      'cannot open EditDefectDialog without a RecordItDialog.'
    );

    this.options = options;
    this.defect = this.createDefect();

    this.dialog.open();
  }

  // ////////// METHODS //////////

  private createDefect(): Defect {
    assertNotNullOrUndefined(
      this.options,
      'cannot create a defect without options'
    );
    return this.defectCreationService.createDefect({
      temporaryGroupName: CreateDefectDialog.TEMPORARY_GROUP_NAME,
      ownerUserGroupId: this.options.ownerUserGroupId,
      ownerThingId: this.options.ownerThingId,
      shadowEntity: true
    });
  }

  // ////////// HANDLERS //////////

  protected handleDialogClosed(): void {
    this.entityManager.entityRepositoryContainer.clearShadowEntitiesWithTemporaryGroupName(
      CreateDefectDialog.TEMPORARY_GROUP_NAME
    );
    this.subscriptionManager.disposeSubscriptions();
  }

  protected handleAcceptButtonClicked(): void {
    assertNotNullOrUndefined(
      this.options,
      'cannot handleAcceptButtonClicked without options'
    );
    assertNotNullOrUndefined(
      this.defect,
      'cannot handleAcceptButtonClicked without a defect'
    );

    this.entityManager.entityRepositoryContainer.createShadowEntitiesWithTemporaryGroupName(
      CreateDefectDialog.TEMPORARY_GROUP_NAME
    );
    this.options.onAcceptButtonClicked?.(this.defect.id);
  }
}

export type DialogOptions = {
  ownerUserGroupId: string;
  ownerThingId: string;
  onAcceptButtonClicked?: (defectId: string) => void;
};
