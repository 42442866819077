export type GetProcessTaskGroupRelationInfoSuccessResponse = {
  success: true;
  processTaskGroupInfos: Array<ProcessTaskGroupRelationInfo>;
  totalResultLength: number;
};

export type GetProcessTaskGroupRelationInfoErrorResponse = {
  success: false;
};

export type GetProcessTaskGroupRelationInfoResponse =
  | GetProcessTaskGroupRelationInfoSuccessResponse
  | GetProcessTaskGroupRelationInfoErrorResponse;

export type SetProcessTaskGroupToProcessTaskGroupRelationSuccessResponse = {
  success: true;
  newRelationStatus: boolean;
};

export type SetProcessTaskGroupToProcessTaskGroupRelationErrorResponse = {
  success: false;
};

export type SetProcessTaskGroupToProcessTaskGroupRelationResponse =
  | SetProcessTaskGroupToProcessTaskGroupRelationSuccessResponse
  | SetProcessTaskGroupToProcessTaskGroupRelationErrorResponse;

export type SetProcessTaskGroupToProcessTaskGroupRelationRequest = {
  processTaskGroup1Id: string;
  processTaskGroup2Id: string;
  areRelated: boolean;
};

export type GetProcessTaskGroupRelationInfoRequest<
  T extends ProcessTaskGroupRelationInfoRequestType
> = {
  activeProcessTaskGroupId: string;
  type: T;
  paginationInfo: T extends ProcessTaskGroupRelationInfoRequestType.ALL
    ? PaginationInfo
    : null;
  filterString?: string;
};

export enum ProcessTaskGroupRelationInfoRequestType {
  WITH_EXISTING_RELATION = 'withExistingRelation',
  ALL = 'all'
}

export type ProcessTaskGroupRelationInfo = {
  processTaskGroupId: string;
  mainInfoText: string;
  subInfoText: string;
  url: string;
  hasActiveRelation: boolean;
  hasSameThingGroup: boolean;
};

export type PaginationInfo = {
  /** one-indexed */
  currentPageNumber: number;
  currentPageSize: number;
};
