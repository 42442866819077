import { autoinject } from 'aurelia-dependency-injection';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import { SubscriptionManagerService } from '../SubscriptionManagerService';
import { GalleryThingPictureFilterHandle } from './GalleryThingPictureFilterHandle';

@autoinject()
export class GalleryThingPictureFilterService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly computedValueService: ComputedValueService
  ) {}

  public createHandle(): GalleryThingPictureFilterHandle {
    return new GalleryThingPictureFilterHandle({
      entityManager: this.entityManager,
      subscriptionManagerService: this.subscriptionManagerService,
      computedValueService: this.computedValueService
    });
  }
}
