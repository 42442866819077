import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

import {
  EntityNameToSearchResultMap,
  ReasonType
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { DateUtils } from 'common/DateUtils';

import { EntityName } from '../../../classes/EntityManager/entities/types';
import { ItemProperty } from '../global-search-result-list-item/global-search-result-list-item';
import { NavigationService } from '../../../services/NavigationService';

@autoinject
export class ThingSearchResultListItem {
  @bindable public searchResult:
    | EntityNameToSearchResultMap[EntityName.Thing]
    | null = null;

  protected itemProperties: Array<ItemProperty> = [];

  constructor(
    private readonly router: Router,
    private readonly navigationService: NavigationService
  ) {}

  // Aurelia Lifecycle

  protected attached(): void {
    this.updateItemProperties();
  }

  // Aurealia Change Handlers

  protected searchResultChanged(): void {
    this.updateItemProperties();
  }

  // Aurelia Handlers

  protected handleNavigateToThingClick(): void {
    const route = this.getRouteForThingSearchResult();
    this.router.navigate(route);
  }

  // Private Methods

  private getRouteForThingSearchResult(): string {
    assertNotNullOrUndefined(this.searchResult, 'cannot navigate to thing');

    switch (this.searchResult.reason.type) {
      case ReasonType.EntityKey:
      case ReasonType.NfcTag:
        return this.navigationService.getThingPageUrl(this.searchResult.id);

      case ReasonType.Property:
        return this.navigationService.getThingPageUrl(this.searchResult.id, {
          open_parameter_panel: true,
          scroll_to_property: this.searchResult.reason.data.propertyId
        });

      case ReasonType.Picture:
      case ReasonType.Tag:
        return this.navigationService.getThingPageUrl(this.searchResult.id, {
          open_parameter_panel: true
        });

      default:
        throw new Error(
          `cannot navigate to thing result with unknown reason ${
            (this.searchResult as EntityNameToSearchResultMap[EntityName.Thing])
              .reason.type
          }`
        );
    }
  }

  private updateItemProperties(): void {
    if (!this.searchResult) {
      this.itemProperties = [];
    } else {
      this.itemProperties = [
        {
          labelTk: 'modelProperties.description',
          value: this.searchResult.description ?? ''
        },
        {
          labelTk: 'modelProperties.latitude',
          value: this.searchResult.latitude?.toString() ?? ''
        },
        {
          labelTk: 'modelProperties.longitude',
          value: this.searchResult.longitude?.toString() ?? ''
        },
        {
          labelTk: 'modelProperties.created',
          value: DateUtils.formatToDateWithHourMinuteString(
            this.searchResult.created
          )
        }
      ];
    }
  }
}
