import { inject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { DomEventHelper } from '../../classes/DomEventHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';

/**
 * @event value-changed
 */
@inject(Element, I18N, SubscriptionManagerService, AppEntityManager)
export class ThingSelect {
  /**
   * thing id
   *
   * @type {(string|null)}
   */
  @bindable value = null;

  /**
   * readonly!
   * @type {import('../../classes/EntityManager/entities/Thing/types').Thing|null}
   */
  @bindable selectedThing = null;

  /** @type {boolean} */
  @bindable enabled = false;

  /**
   * filter the things by the userGroupId (or not if it is null)
   *
   * @type {(string|null)}
   */
  @bindable userGroupId = null;

  /**
   * hide the label
   *
   * @type {boolean}
   */
  @bindable noLabel = false;

  /**
   * thing ids to exclude from the choices
   *
   * @type {Array<string>}
   */
  @bindable excludedThingIds = [];

  /**
   * only show things belonging to the given thingGroupId
   *
   * @type {(string|null)}
   */
  @bindable thingGroupId = null;

  /**
   * possibility to override the default nullOptions (look at the custom-select for further reference on the null option)
   *
   * null will show the default nullOption
   *
   * @type {(string|null)}
   */
  @bindable nullOption = null;

  /** @type {import('../../classes/SubscriptionManager').SubscriptionManager} */
  _subscriptionManager;
  /** @type {I18N} */
  _i18n;

  /** @type {Array<import('../../classes/EntityManager/entities/Thing/types').Thing>} */
  _availableThings = [];

  /** @type {HTMLElement} */
  _domElement;

  /**
   * @param {HTMLElement} element
   * @param {I18N} i18n
   * @param {SubscriptionManagerService} subscriptionManagerService
   * @param {AppEntityManager} entityManager
   */
  constructor(element, i18n, subscriptionManagerService, entityManager) {
    this._domElement = element;
    this._subscriptionManager = subscriptionManagerService.create();
    this._i18n = i18n;

    this._excludedThingIdsSubscriptionManager =
      subscriptionManagerService.create();

    this._entityManager = entityManager;
  }

  attached() {
    this._subscriptionManager.subscribeToModelChanges(
      EntityName.Thing,
      this._updateAvailableThings.bind(this)
    );
    this._updateAvailableThings();
  }

  detached() {
    this._subscriptionManager.disposeSubscriptions();
  }

  userGroupIdChanged() {
    this._updateAvailableThings();
  }

  excludedThingIdsChanged() {
    this._excludedThingIdsSubscriptionManager.disposeSubscriptions();

    if (this.excludedThingIds) {
      this._excludedThingIdsSubscriptionManager.subscribeToArrayChanges(
        this.excludedThingIds,
        () => {
          this._updateAvailableThings();
        }
      );
    }

    this._updateAvailableThings();
  }

  thingGroupIdChanged() {
    this._updateAvailableThings();
  }

  _updateAvailableThings() {
    /** @type {Array<import('../../classes/EntityManager/entities/Thing/types').Thing>} */
    let availableThings = [];

    if (this.userGroupId) {
      availableThings = this._entityManager.thingRepository.getByUserGroupId(
        this.userGroupId
      );
    } else {
      availableThings = this._entityManager.thingRepository.getAll();
    }

    this._availableThings = availableThings.filter((thing) => {
      let filtered = true;

      if (this.excludedThingIds) {
        filtered = filtered && this.excludedThingIds.indexOf(thing.id) === -1;
      }

      if (this.thingGroupId) {
        filtered = filtered && thing.thingGroupId === this.thingGroupId;
      }

      return filtered;
    });
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }

  /**
   * @param {string} key
   * @returns {string}
   * @private
   */
  _translate(key) {
    return this._i18n.tr('inputComponents.thingSelect.' + key);
  }
}
