import { autoinject } from 'aurelia-dependency-injection';
import { DefectChangeLogEntryAction } from '../../../../../../common/src/Types/Entities/DefectChangeLogEntry/DefectChangeLogEntryDto';
import { AppEntityManager } from '../AppEntityManager';
import { DefectChangeLogEntry } from '../DefectChangeLogEntry/types';
import { CurrentUserService } from '../User/CurrentUserService';
import { Defect } from './types';

@autoinject()
export class DefectLoggingService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService
  ) {}

  public async log(
    defect: Defect,
    action: DefectChangeLogEntryAction,
    data?: object
  ): Promise<DefectChangeLogEntry | null> {
    // Logging only needed if the defect is not being created
    if (defect.shadowEntity) return null;
    const user = await this.currentUserService.waitForCurrentUser();
    return this.entityManager.defectChangeLogEntryRepository.create({
      userId: user.id,
      created: new Date().toISOString(),
      logAction: action,
      logDataJson: JSON.stringify(data),
      ownerDefectId: defect.id,
      ownerThingId: defect.ownerThingId,
      ownerUserGroupId: defect.ownerUserGroupId
    });
  }
}
