import { inject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { personSalutations } from 'common/Enums/PersonSalutation';
import { DomEventHelper } from '../../classes/DomEventHelper';

/**
 * @event value-changed
 */
@inject(Element, I18N)
export class PersonSalutationSelect {
  /** @type {number|null} */
  @bindable value = null;

  /**
   * @type {boolean}
   */
  @bindable enabled = false;

  /**
   * override the default label with a custom string
   *
   * @type {string|null}
   */
  @bindable label = null;

  /** @type {HTMLElement} */
  _domElement;
  /** @type {I18N} */
  _i18n;

  /** @type {Array<{label: string, value: number}>} */
  _options = [];

  /**
   * @param {HTMLElement} domElement
   * @param {I18N} i18n
   */
  constructor(domElement, i18n) {
    this._domElement = domElement;
    this._i18n = i18n;
    this._updateOptions();
  }

  _updateOptions() {
    /** @type {Array<{label: string, value: number}>} */
    const choices = [];

    personSalutations.forEach((salutation) => {
      choices.push({
        label: this._i18n.tr(
          'modelsDetail.PersonModel.salutation--' + salutation
        ),
        value: salutation
      });
    });

    this._options = choices;
  }

  _handleSelectChanged() {
    DomEventHelper.fireEvent(this._domElement, {
      name: 'value-changed',
      detail: null
    });
  }
}
