import { autoinject } from 'aurelia-framework';
import { ValueComputer } from './ValueComputer';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';

@autoinject()
export class ProcessConfigurationFromProcessTaskGroupIdComputer extends ValueComputer<
  IComputeData,
  ProcessConfiguration | null
> {
  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.subscriptionManager = subscriptionManagerService.create();
  }

  public initializeEventListeners(invokeCompute: () => void): void {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskGroup,
      invokeCompute
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      invokeCompute
    );
  }

  public removeEventListeners(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  public compute(data: IComputeData): ProcessConfiguration | null {
    const processTaskGroup =
      this.entityManager.processTaskGroupRepository.getById(
        data.processTaskGroupId
      );
    return processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          processTaskGroup.processConfigurationId
        )
      : null;
  }
}

export interface IComputeData {
  processTaskGroupId: string;
}
