import { Vector } from 'common/Geometry/Vector';

export class ZoomBoxResizerHelper {
  /**
   * @param {HTMLElement} element
   * @param {{ width: number, height: number }} dimensions
   * @param {HTMLElement} zoomBoxElement
   * @returns {TZoomBoxResizerHelperPicturePositionInfo|null}
   */
  static updateStyle(element, dimensions, zoomBoxElement) {
    const info = this._getPicturePositionInfo(dimensions, zoomBoxElement);
    if (!info) {
      return null;
    }

    const style = element.style;
    style.width = info.size.getX() + 'px';
    style.height = info.size.getY() + 'px';
    style.marginTop = info.topLeftPosition.getY() + 'px';
    style.marginLeft = info.topLeftPosition.getX() + 'px';

    return info;
  }

  /**
   * @param {{ width: number, height: number }} dimensions
   * @param {HTMLElement} zoomBoxElement
   * @returns {TZoomBoxResizerHelperPicturePositionInfo|null}
   */
  static _getPicturePositionInfo(dimensions, zoomBoxElement) {
    const ratio = dimensions.width / dimensions.height;
    const zoomBoxComputed = window.getComputedStyle(zoomBoxElement);
    const containerWidth = parseInt(zoomBoxComputed.width);
    const containerHeight = parseInt(zoomBoxComputed.height);

    if (isNaN(ratio) || isNaN(containerWidth)) {
      return null;
    }

    const width = Math.min(containerHeight * ratio, containerWidth);
    const height = width / ratio;

    const size = new Vector(width, height);
    const containerSize = new Vector(containerWidth, containerHeight);

    return {
      size: size,
      topLeftPosition: containerSize.clone().substractVector(size).scale(0.5),
      containerSize: containerSize
    };
  }
}

/**
 * @typedef {Object} TZoomBoxResizerHelperPicturePositionInfo
 * @property {Vector} size
 * @property {Vector} topLeftPosition
 * @property {Vector} containerSize
 */
