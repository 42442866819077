import { autoinject, bindable } from 'aurelia-framework';
import { TProcessConfigurationPositionGroupingConfiguration } from 'common/Types/ProcessConfigurationPositionGroupingConfiguration';
import { IProcessConfigurationDeviceGroupingConfiguration } from 'common/Types/ProcessConfigurationDeviceGroupingConfiguration';
import { SortUtils } from 'common/Utils/SortUtils';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  EntityGrouper,
  EntityGroup,
  GroupConfiguration,
  GroupConfigurationType
} from '../../classes/EntityGrouper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationDevice } from '../../classes/EntityManager/entities/ProcessConfigurationDevice/types';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskDevice } from '../../classes/EntityManager/entities/ProcessTaskDevice/types';
import { ProcessTaskGroup } from '../../classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskPosition } from '../../classes/EntityManager/entities/ProcessTaskPosition/types';

@autoinject()
export class ProcessTaskPositionsAndDevicesTodoList {
  @bindable()
  public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public positions: Array<ProcessTaskPosition> = [];

  @bindable()
  public devices: Array<ProcessTaskDevice> = [];

  private positionGrouper: EntityGrouper<ProcessTaskPosition>;
  private deviceGrouper: EntityGrouper<ProcessTaskDevice>;
  private subscriptionManager: SubscriptionManager;

  private processConfigurationDevicesById: Map<
    string,
    ProcessConfigurationDevice
  > | null = null;
  private todoGroups: Array<ITodoGroup> = [];
  private groupedPositions: Array<EntityGroup<ProcessTaskPosition>> = [];
  private groupedDevices: Array<EntityGroup<ProcessTaskDevice>> = [];
  private isAttached: boolean = false;

  constructor(
    private readonly entityManager: AppEntityManager,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.positionGrouper = new EntityGrouper({
      groupConfigurations: [],
      properties: [],
      propertyEntityIdField: 'processTaskPositionId'
    });

    this.deviceGrouper = new EntityGrouper({
      groupConfigurations: [],
      properties: [],
      propertyEntityIdField: 'processTaskDeviceId'
    });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfigurationDevice,
      () => {
        this.processConfigurationDevicesById = null;
      }
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Property,
      () => {
        this.updateProperties();
        this.updateGroupedPositions();
        this.updateGroupedDevices();
        this.updateTodoGroups();
      }
    );
    this.updateProperties();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessConfiguration,
      () => {
        this.updateGroupConfiguration();
        this.updateGroupedPositions();
        this.updateGroupedDevices();
        this.updateTodoGroups();
      }
    );

    this.updateGroupConfiguration();
    this.updateGroupedPositions();
    this.updateGroupedDevices();
    this.updateTodoGroups();
  }

  protected detached(): void {
    this.isAttached = false;
    this.subscriptionManager.disposeSubscriptions();
  }

  private processTaskGroupChanged(): void {
    if (this.isAttached) {
      this.processConfigurationDevicesById = null;
      this.updateGroupConfiguration();
      this.updateGroupedPositions();
      this.updateTodoGroups();
    }
  }

  private processTaskChanged(): void {
    if (this.isAttached) {
      this.updateProperties();
      this.updateGroupedPositions();
      this.updateTodoGroups();
    }
  }

  private positionsChanged(): void {
    if (this.isAttached) {
      this.updateGroupedPositions();
      this.updateTodoGroups();
    }
  }

  private devicesChanged(): void {
    if (this.isAttached) {
      this.updateGroupedDevices();
      this.updateTodoGroups();
    }
  }

  private updateGroupConfiguration(): void {
    const processConfiguration = this.processTaskGroup
      ? this.entityManager.processConfigurationRepository.getById(
          this.processTaskGroup.processConfigurationId
        )
      : null;

    if (processConfiguration) {
      this.positionGrouper.setGroupConfigurations(
        this.getPositionsGroupConfigurations(processConfiguration)
      );

      this.deviceGrouper.setGroupConfigurations(
        this.getDevicesGroupConfigurations(processConfiguration)
      );
    } else {
      this.positionGrouper.setGroupConfigurations([]);
      this.deviceGrouper.setGroupConfigurations([]);
    }
  }

  private getPositionsGroupConfigurations(
    processConfiguration: ProcessConfiguration
  ): Array<GroupConfiguration> {
    const groupingConfigurationJson =
      processConfiguration.positionGroupingConfigurationJson;
    const groupingConfiguration: TProcessConfigurationPositionGroupingConfiguration | null =
      groupingConfigurationJson ? JSON.parse(groupingConfigurationJson) : null;
    const groupConfigurations = groupingConfiguration
      ? groupingConfiguration.groupConfigurations
      : [];

    const configs: Array<GroupConfiguration> = [];
    for (const groupConfig of groupConfigurations) {
      if (groupConfig.type === 'property') {
        configs.push({
          type: GroupConfigurationType.Property,
          value: groupConfig.value
        });
      } else {
        console.error(
          `unsupported groupConfig type "${groupConfig.type}"`,
          groupConfig
        );
      }
    }

    return configs;
  }

  private getDevicesGroupConfigurations(
    processConfiguration: ProcessConfiguration
  ): Array<GroupConfiguration> {
    const groupingConfigurationJson =
      processConfiguration.deviceGroupingConfigurationJson;
    const groupingConfiguration: IProcessConfigurationDeviceGroupingConfiguration | null =
      groupingConfigurationJson ? JSON.parse(groupingConfigurationJson) : null;
    const groupConfigurations = groupingConfiguration
      ? groupingConfiguration.groupConfigurations
      : [];

    const configs: Array<GroupConfiguration> = [];
    for (const groupConfig of groupConfigurations) {
      if (groupConfig.type === 'property') {
        configs.push({
          type: GroupConfigurationType.Property,
          value: groupConfig.value
        });
      } else {
        console.error(
          `unsupported groupConfig type "${groupConfig.type}"`,
          groupConfig
        );
      }
    }

    return configs;
  }

  private updateProperties(): void {
    const properties = this.processTask
      ? this.entityManager.propertyRepository.getByOwnerProcessTaskId(
          this.processTask.id
        )
      : [];
    this.positionGrouper.setProperties(properties);
    this.deviceGrouper.setProperties(properties);
  }

  private updateGroupedPositions(): void {
    this.groupedPositions = this.positionGrouper.groupEntities(this.positions);
  }

  private updateGroupedDevices(): void {
    this.groupedDevices = this.deviceGrouper.groupEntities(this.devices);
  }

  private updateTodoGroups(): void {
    const todoGroups = this.groupedPositions.map<ITodoGroup>((gp) => {
      return {
        name: gp.name,
        positions: gp.entities,
        devices: []
      };
    });

    for (const group of this.groupedDevices) {
      const todoGroup = todoGroups.find((g) => g.name === group.name);
      if (todoGroup) {
        todoGroup.devices = todoGroup.devices.concat(group.entities);
      } else {
        todoGroups.push({
          name: group.name,
          positions: [],
          devices: group.entities
        });
      }
    }

    this.sortTodoGroups(todoGroups);

    this.todoGroups = todoGroups;
  }

  private sortTodoGroups(groups: Array<ITodoGroup>): void {
    groups.sort((a, b) => SortUtils.localeCompareFalsyStrings(a.name, b.name));
  }

  /**
   * @param processConfigurationDeviceId
   * @param _devicesById - just to update the view
   */
  private getProcessConfigurationDeviceName(
    processConfigurationDeviceId: string,
    _devicesById: Map<string, ProcessConfigurationDevice>
  ): string | null {
    const device = this.getProcessConfigurationDevicesById().get(
      processConfigurationDeviceId
    );
    return device ? device.name : null;
  }

  private getProcessConfigurationDevicesById(): Map<
    string,
    ProcessConfigurationDevice
  > {
    if (!this.processConfigurationDevicesById) {
      const configurationDevices = this.processTaskGroup
        ? this.entityManager.processConfigurationDeviceRepository.getByProcessConfigurationId(
            this.processTaskGroup.processConfigurationId
          )
        : [];
      const mapData = configurationDevices.map<
        [string, ProcessConfigurationDevice]
      >((d) => [d.id, d]);
      this.processConfigurationDevicesById = new Map(mapData);
    }

    return this.processConfigurationDevicesById;
  }
}

interface ITodoGroup {
  name: string;
  devices: Array<ProcessTaskDevice>;
  positions: Array<ProcessTaskPosition>;
}
