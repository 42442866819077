import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessConfiguration } from '../../classes/EntityManager/entities/ProcessConfiguration/types';
import { ProcessConfigurationFormSendTarget } from '../../classes/EntityManager/entities/ProcessConfigurationFormSendTarget/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { subscribableLifecycle } from '../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessConfigurationFormSendTargetsWidget {
  @bindable()
  public processConfiguration: ProcessConfiguration | null = null;

  @subscribableLifecycle()
  protected readonly processConfigurationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessConfiguration];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processConfigurationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessConfiguration,
        context: this as ProcessConfigurationFormSendTargetsWidget,
        propertyName: 'processConfiguration'
      });
  }

  @computed(
    expression('processConfiguration'),
    model(EntityName.ProcessConfigurationFormSendTarget)
  )
  protected get formSendTargets(): Array<ProcessConfigurationFormSendTarget> {
    if (!this.processConfiguration) return [];
    return this.entityManager.processConfigurationFormSendTargetRepository.getByProcessConfigurationId(
      this.processConfiguration.id
    );
  }

  protected handleAddButtonClick(): void {
    assertNotNullOrUndefined(
      this.processConfiguration,
      'cannot ProcessConfigurationFormSendTargetsWidget.handleAddButtonClick without processConfiguration bindable'
    );
    this.entityManager.processConfigurationFormSendTargetRepository.create({
      ownerProcessConfigurationId: this.processConfiguration.id,
      ownerUserGroupId: this.processConfiguration.ownerUserGroupId
    });
  }
}
