import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { PropertyStringParser } from 'common/PropertyStringParser/PropertyStringParser';
import {
  ClickableTextInput,
  TTextChangedEvent
} from '../../inputComponents/clickable-text-input/clickable-text-input';
import { StringToNumberValueConverter } from '../../valueConverters/StringToNumberValueConverter';
import { ContextualPropertyWidgetConfigurationForSubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/ContextualPropertyWidgetConfigurationForSubWidgetConfiguration';
import { createPropertySubWidgetConfiguration } from '../base-property-widget/config/PropertySubWidget/createPropertySubWidgetConfiguration';
import { PropertySubWidget } from '../base-property-widget/config/PropertySubWidget/PropertySubWidget';
import { PropertyWidgetStyle } from '../base-property-widget/config/PropertyWidgetStyle/PropertyWidgetStyle';

@autoinject()
export class BasePropertyNumberWidget implements PropertySubWidget {
  @bindable()
  public configuration: ContextualPropertyWidgetConfigurationForSubWidgetConfiguration<
    typeof basePropertyNumberWidgetConfiguration
  > | null = null;

  @bindable()
  public style: PropertyWidgetStyle | null = null;

  protected PropertyStringParser = PropertyStringParser;
  protected clickableTextInput: ClickableTextInput | null = null;

  constructor(
    private readonly stringToNumberValueConverter: StringToNumberValueConverter
  ) {}

  public focus(): void {
    assertNotNullOrUndefined(
      this.clickableTextInput,
      "can't BasePropertyNumberWidget.focus without clickableTextInput"
    );
    this.clickableTextInput.focus();
  }

  protected handleTextChanged(event: TTextChangedEvent): void {
    assertNotNullOrUndefined(
      this.configuration,
      "can't BasePropertyNumberWidget.handleTextChanged without configuration"
    );

    this.configuration.binding.setValueData({
      value: this.stringToNumberValueConverter.fromView(
        event.detail.value as number | null
      ),
      customChoice: null
    });
  }

  @computedFrom('compact', 'compactButton', 'inTable')
  protected get compactInputExtraSpacingNeeded(): boolean {
    if (!this.style) {
      return false;
    }

    return (
      !this.style.compact && this.style.compactButton && !this.style.inTable
    );
  }
}

export const basePropertyNumberWidgetConfiguration =
  createPropertySubWidgetConfiguration({
    features: ['default']
  });
