import deTranslationPath from '../../locales/de/translation.json';
import enTranslationPath from '../../locales/en/translation.json';

export class TranslationUtils {
  private static namespaceMapByLng: NamespaceMapByLng = {
    de: {
      translation: deTranslationPath as unknown as string // sadly I can't manage to convince typescript that deTranslationPath is actually a string, so we have to cast it
    },
    en: {
      translation: enTranslationPath as unknown as string
    }
  };

  private constructor() {}

  public static createLoadPathFunction(): LoadPathFunction {
    return (lngs, namespaces) => {
      if (lngs.length > 1 || namespaces.length > 1) {
        throw new Error(
          `Only one lng and namespace is supported: lngs: ${JSON.stringify(
            lngs
          )} namespaces: ${JSON.stringify(namespaces)}`
        );
      }

      return this.getTranslationFilePathForLngAndNamespace(
        lngs[0],
        namespaces[0]
      );
    };
  }

  public static getTranslationFilePathForLngAndNamespace(
    lng: string,
    namespace: string
  ): string {
    this.assertLng(lng);
    this.assertNamespace(namespace);

    return this.namespaceMapByLng[lng][namespace];
  }

  public static assertLng(lng: string): asserts lng is SupportedLng {
    if (!supportedLngs.includes(lng as any)) {
      throw new Error(`lng "${lng}" is not supported`);
    }
  }

  public static isSupportedLng(lng: string): boolean {
    if (supportedLngs.includes(lng as any)) {
      return true;
    }
    return false;
  }

  public static getSupportedLngOfNavigator(): SupportedLng {
    const navigatorLng = navigator.language;
    if (!TranslationUtils.isSupportedLng(navigatorLng)) {
      return supportedLngs[0];
    } else return navigatorLng as SupportedLng;
  }

  private static assertNamespace(
    namespace: string
  ): asserts namespace is SupportedNamespace {
    if (!supportedNamespaces.includes(namespace as any)) {
      throw new Error(`namespace "${namespace}" is not supported`);
    }
  }
}

export const supportedLngs = ['de', 'en'] as const;
export type SupportedLng = (typeof supportedLngs)[number];

export const supportedNamespaces = ['translation'] as const;
export type SupportedNamespace = (typeof supportedNamespaces)[number];

export type LoadPathFunction = (
  lngs: [string, ...Array<string>],
  namespaces: [string, ...Array<string>]
) => string;

type NamespaceMapByLng = Record<SupportedLng, NamespaceMap>;
type NamespaceMap = Record<SupportedNamespace, string>;
