import { autoinject } from 'aurelia-framework';
import { AppEntityManager } from '../AppEntityManager';
import { Thing } from '../Thing/types';
import { ApplyThingSectionConfigPropertiesService } from '../ThingSectionConfigProperty/ApplyThingSectionConfigPropertiesService';
import { ThingSection } from './types';

@autoinject()
export class ThingSectionCreationService {
  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly applyThingSectionConfigPropertiesService: ApplyThingSectionConfigPropertiesService
  ) {}

  /**
   * creates the thing section at the end
   */
  public createAtEnd({ thing }: { thing: Thing }): ThingSection {
    const thingSection = this.entityManager.thingSectionRepository.createAtEnd({
      ownerThingId: thing.id,
      ownerUserGroupId: thing.ownerUserGroupId
    });

    this.applyThingSectionConfigPropertiesService.createThingThingSectionsProperties(
      {
        thingId: thing.id,
        thingSections: [thingSection]
      }
    );

    return thingSection;
  }
}
