import { Vector } from '../Geometry/Vector';
import { RectangleHelper } from '../Geometry/RectangleHelper';

/**
 * TODO: support side by side alignment
 */
export class RectangleToRectangleAligner {
  static _VERTICAL_ALIGNMENTS = [0, 1];
  static _HORIZONTAL_ALIGNMENTS = [0, 0.5, 1];

  /** @type {TRectangleToRectangleAlignerOptions} */
  _options;

  /**
   * @param {TRectangleToRectangleAlignerOptions} options
   */
  constructor(options) {
    this._options = options;
  }

  /**
   * @param {TGeometryRectangle} alignedRectangle - the position vector gets modified internally
   * @param {TGeometryRectangle} referenceRectangle
   * @returns {TRectangleToRectangleAlignerAlignmentMethod}
   */
  alignRectangle(alignedRectangle, referenceRectangle) {
    const methods = this._findAllAlignmentMethods(
      alignedRectangle,
      referenceRectangle
    );

    let bestMethod = methods[0];
    for (let key = 1; key < methods.length; key++) {
      const method = methods[key];

      if (method.overlapArea < bestMethod.overlapArea) {
        bestMethod = method;
        if (bestMethod.overlapArea === 0) {
          break;
        }
      }
    }

    this._alignRectangle(alignedRectangle, referenceRectangle, bestMethod);

    return bestMethod;
  }

  /**
   * @param {Vector} boundingAreaSize
   */
  setBoundingAreaSize(boundingAreaSize) {
    this._options.boundingAreaSize = boundingAreaSize;
  }

  /**
   * @param {Vector} alignment
   */
  setReferenceRectangleAlignment(alignment) {
    this._options.referenceRectangleAlignment = alignment;
  }

  /**
   * @param {Vector} alignment
   */
  setAlignedRectangleAlignment(alignment) {
    this._options.alignedRectangleAlignment = alignment;
  }

  /**
   * @param {TGeometryRectangle} alignedRectangle
   * @param {TGeometryRectangle} referenceRectangle
   * @returns {Array<TRectangleToRectangleAlignerAlignmentMethod>}
   * @private
   */
  _findAllAlignmentMethods(alignedRectangle, referenceRectangle) {
    const alignment = this._options.alignedRectangleAlignment;
    const refAlignment = this._options.referenceRectangleAlignment;

    const alignments = [
      {
        alignedRectangleAlignment: alignment,
        referenceRectangleAlignment: refAlignment
      }
    ];

    const otherHorizontalAlignments = this._getOtherHorizontalAlignments();
    const otherVerticalAlignments = this._getOtherVerticalAlignments();

    otherHorizontalAlignments.forEach((hAlign) => {
      alignments.push({
        alignedRectangleAlignment: alignment.clone().setX(hAlign),
        referenceRectangleAlignment: refAlignment.clone().setX(hAlign)
      });
    });

    otherVerticalAlignments.forEach((vAlign) => {
      alignments.push({
        alignedRectangleAlignment: alignment.clone().setY(vAlign),
        referenceRectangleAlignment: refAlignment
          .clone()
          .setY(this._invertSingleAlignment(vAlign))
      });
    });

    otherHorizontalAlignments.forEach((hAlign) => {
      otherVerticalAlignments.forEach((vAlign) => {
        alignments.push({
          alignedRectangleAlignment: alignment
            .clone()
            .setX(hAlign)
            .setY(vAlign),
          referenceRectangleAlignment: refAlignment
            .clone()
            .setX(hAlign)
            .setY(this._invertSingleAlignment(vAlign))
        });
      });
    });

    this._calculateAlignmentMethodsOverlapArea(
      alignedRectangle,
      referenceRectangle,
      alignments
    );
    return alignments;
  }

  /**
   * @returns {Array<number>}
   * @private
   */
  _getOtherHorizontalAlignments() {
    return RectangleToRectangleAligner._HORIZONTAL_ALIGNMENTS.filter(
      (hAlign) => {
        return (
          hAlign !== this._options.alignedRectangleAlignment.getX() ||
          hAlign !== this._options.referenceRectangleAlignment.getX()
        );
      }
    );
  }
  /**
   * @returns {Array<number>}
   * @private
   */
  _getOtherVerticalAlignments() {
    return RectangleToRectangleAligner._VERTICAL_ALIGNMENTS.filter((vAlign) => {
      return vAlign !== this._options.alignedRectangleAlignment.getY();
    });
  }

  /**
   * @param {TGeometryRectangle} alignedRectangle
   * @param {TGeometryRectangle} referenceRectangle
   * @param {Array<TRectangleToRectangleAlignerAlignmentMethod>} alignmentMethods
   * @private
   */
  _calculateAlignmentMethodsOverlapArea(
    alignedRectangle,
    referenceRectangle,
    alignmentMethods
  ) {
    for (let key = 0; key < alignmentMethods.length; key++) {
      const method = alignmentMethods[key];

      const clonedRectangle = {
        position: alignedRectangle.position.clone(),
        size: alignedRectangle.size.clone()
      };

      this._alignRectangle(clonedRectangle, referenceRectangle, method);

      const overlap = RectangleHelper.getRectangleBoundingAreaOutsideOverlap(
        clonedRectangle,
        this._options.boundingAreaSize
      );
      const absX = Math.abs(overlap.getX());
      const absY = Math.abs(overlap.getY());

      //overlapArea = (overlapArea in X direction) + (overlapArea in Y direction) - (overlapArea XY overlap)
      method.overlapArea =
        absX * alignedRectangle.size.getY() +
        absY * alignedRectangle.size.getX() -
        absX * absY;

      if (method.overlapArea === 0) {
        break;
      }
    }
  }

  /**
   *
   * @param {TGeometryRectangle} alignedRectangle - the position vector gets modified internally
   * @param {TGeometryRectangle} referenceRectangle
   * @param {TRectangleToRectangleAlignerAlignmentMethod} method
   * @private
   */
  _alignRectangle(alignedRectangle, referenceRectangle, method) {
    const alignedRectangleReferencePoint =
      this._calculateReferencePointForRectangle(
        alignedRectangle,
        method.alignedRectangleAlignment
      );
    const referenceRectangleReferencePoint =
      this._calculateReferencePointForRectangle(
        referenceRectangle,
        method.referenceRectangleAlignment
      );
    const margin = this._calculateMarginVector(
      method.alignedRectangleAlignment,
      method.referenceRectangleAlignment
    );

    alignedRectangle.position
      .addVector(
        referenceRectangleReferencePoint
          .clone()
          .substractVector(alignedRectangleReferencePoint)
      )
      .addVector(margin);
  }

  /**
   * @param {number} alignment
   * @returns {number}
   * @private
   */
  _invertSingleAlignment(alignment) {
    return 0.5 + (alignment - 0.5) * -1;
  }

  /**
   * @param {Vector} alignedRectangleAlignment
   * @param {Vector} referenceRectangleAlignment
   * @private
   */
  _calculateMarginVector(
    alignedRectangleAlignment,
    referenceRectangleAlignment
  ) {
    if (!this._options.margin) {
      return new Vector(0, 0);
    }

    const alignmentDiff = referenceRectangleAlignment
      .clone()
      .substractVector(alignedRectangleAlignment);

    return this._options.margin
      .clone()
      .multiplyXY(
        Math.sign(alignmentDiff.getX()),
        Math.sign(alignmentDiff.getY())
      );
  }

  /**
   * @param {TGeometryRectangle} rectangle
   * @param {Vector} alignment
   * @returns {Vector}
   * @private
   */
  _calculateReferencePointForRectangle(rectangle, alignment) {
    return rectangle.position
      .clone()
      .addVector(rectangle.size.clone().multiplyVector(alignment));
  }
}

/**
 * the reference points are percentual, so 0 on the x coordinate means left, 1 means right
 *
 * @typedef {Object} TRectangleToRectangleAlignerOptions
 * @property {Vector} boundingAreaSize
 * @property {(Vector|null)} [margin]
 * @property {Vector} referenceRectangleAlignment - point to align the alignedRectangleAlignment to
 * @property {Vector} alignedRectangleAlignment - point to align onto the referenceRectangleAlignment
 */

/**
 * @typedef {Object} TRectangleToRectangleAlignerAlignmentMethod
 * @property {Vector} alignedRectangleAlignment
 * @property {Vector} referenceRectangleAlignment
 * @property {(number|null)} [overlapArea]
 */
